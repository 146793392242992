import {ClassicCard} from '@braze/web-sdk'
import React from 'react'
import AnnouncementCard from '~/global/widgets/announcement-card/AnnouncementCard'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/identity/actions'
import styles from './NotificationAnnouncement.scss'

interface Props {
    instrumentId: string
}

const useInstrumentNotificationForAnnouncement = (instrumentId: string) => {
    // Here we filter out any notifications that are not specified to show as an announcement,
    // that are for the instrument question, and are not a content card. We sort by descending
    // date order and only show the most recent notification
    const notifications = useAppSelector(s => s.identity.notifications)
    const notificationCardsForInstrument = (
        notifications.notificationsPageCards.filter(
            c =>
                c.extras?.instrument_id === instrumentId &&
                c.extras?.show_as_announcement === 'true' &&
                c instanceof ClassicCard,
        ) as ClassicCard[]
    ).sort((a, b) => b.created!.getMilliseconds() - a.created!.getMilliseconds())

    if (!notificationCardsForInstrument.length) {
        return null
    }

    return notificationCardsForInstrument[0]
}

const NotificationAnnouncement = ({instrumentId}: Props) => {
    const dispatch = useAppDispatch()
    const notificationCard = useInstrumentNotificationForAnnouncement(instrumentId)

    if (!notificationCard) {
        return null
    }

    const removeCard = () => {
        dispatch(actions.RemoveNotification(notificationCard))
    }

    return <AnnouncementCard className={styles.card} card={notificationCard} onDismiss={removeCard} />
}

export default NotificationAnnouncement
