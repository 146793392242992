import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {reEnterPasswordMessage} from '~/global/utils/error-text/errorText'
import {formatFullName, FullNameType} from '~/global/utils/format-full-name/formatFullName'
import {getTransferInstrumentValueHomeCurrency} from '~/global/utils/share-transfers/shareTransfers'
import {useExchangeRates} from '~/global/utils/use-exchange-rates/useExchangeRates'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useTransferInfo} from '~/global/utils/use-transfer-info/useTransferInfo'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {ErrorBox} from '~/global/widgets/form-controls'
import {TransferOutFees} from '~/global/widgets/help-modals/TransferFees'
import recordStyles from '~/global/widgets/instrument-activity/Record.scss'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {OrderConfirmation} from '~/global/widgets/order-confirmation/OrderConfirmation'
import Page from '~/global/widgets/page/Page'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import instrumentActions from '~/store/instrument/actions'
import actions from '~/store/transfer/actions'
import {NEW_SRN_REQUIRED} from '~/store/transfer/types'

export default function Confirm() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const instrumentsById = useAppSelector(({instrument}) => instrument.instrumentsById)
    const transfersLoadingState = useAppSelector(({transfer}) => transfer.transfersLoadingState)
    const stagedTransferOrder = useAppSelector(({transfer}) => transfer.stagedTransferOrder)
    const homeCurrency = useAppSelector(({identity}) => identity.user!.home_currency)
    const [fullName, setFullName] = React.useState<FullNameType | null>(null)
    const [authenticationError, setAuthenticationError] = React.useState<Error | null>(null)

    const [exchangeRates] = useExchangeRates()
    const transferInfo = useTransferInfo()

    // Save all the Staged instrument entiries via API
    React.useEffect(() => {
        dispatch(actions.UploadTransferInstruments())
        authenticateAndFetchFullName()
    }, [])

    // Load instruments in the transfer
    React.useEffect(() => {
        if (!stagedTransferOrder?.instruments) {
            return
        }
        // Check we've loaded every instrument in this transfer
        const stagedInstrumentIds = stagedTransferOrder?.instruments.map(({instrumentId}) => instrumentId)
        if (stagedInstrumentIds && !stagedInstrumentIds.every(instrument => Boolean(instrumentsById[instrument]))) {
            dispatch(instrumentActions.getInstrumentsByIds(stagedInstrumentIds))
        }
    }, [stagedTransferOrder?.instruments, instrumentsById])

    // Fetch the customer's full name. As this is PII, this is kept outside the redux store
    const authenticateAndFetchFullName = async () => {
        if (fullName?.firstName) {
            return
        }
        const response: Response.FullName | string | any = await dispatch(identityActions.GetFullName())
        if (response.type === 'full_name') {
            setFullName({
                firstName: response.first_name,
                middleName: response.middle_name,
                lastName: response.last_name,
            })
        } else if (response === reEnterPasswordMessage) {
            setAuthenticationError(new Error(reEnterPasswordMessage))
        }
    }

    if (!stagedTransferOrder) {
        navigate(profileUrl('invest/transfer-shares'), {replace: true})
        return <></>
    }

    const {reference, referenceType, address, instruments, direction, pid} = stagedTransferOrder

    const handleSubmit = async () => {
        await dispatch(actions.EmailIssuerTransferForm(stagedTransferOrder.groupId!))
        navigate(
            profileUrl('invest/portfolio-transfer-shares/asx/:groupId/download-transfer-form', {
                groupId: stagedTransferOrder.groupId!,
            }),
        )
    }

    const isLoading = instruments!.length === 0 || Object.keys(instrumentsById).length === 0 || !transferInfo
    const totalFeeValue = `$${
        instruments.length * Number(transferInfo?.fees.ASX.charge_amount)
    } ${homeCurrency.toUpperCase()}`

    return (
        <>
            <Toolbar dataTestId="toolbar--transfer-shares-confirm" leftButton="back" />
            <Page>
                <h1 className={cn(commonStyles.heading, spacing.spaceBelow24)}>
                    Confirm the details to create your Transfer Form
                </h1>
                <h2 className={cn(commonStyles.subHeading, spacing.spaceBelow12)}>Check your personal details</h2>
                {stagedTransferOrder.reference === NEW_SRN_REQUIRED ? (
                    <p className={spacing.spaceBelow24}>
                        Please make sure the below details are correct. See an error? No stress, simply go back and edit
                        to ensure it’s smooth sailing from here.
                    </p>
                ) : (
                    <p className={spacing.spaceBelow24}>
                        Please make sure the below matches what’s on your holding statement exactly. See an error? No
                        stress, simply go back and edit to ensure it’s smooth sailing from here.
                    </p>
                )}
                <div className={spacing.spaceBelow16}>
                    <p>
                        <strong>Account Name</strong>
                    </p>
                    <p>{fullName && formatFullName(fullName)}</p>
                </div>
                <div className={spacing.spaceBelow16}>
                    <p>
                        <strong>Address</strong>
                    </p>
                    {address!.formatted}
                </div>
                {reference !== NEW_SRN_REQUIRED && (
                    <div className={spacing.spaceBelow16}>
                        <p>
                            <strong>{referenceType}</strong>
                        </p>
                        <p>{reference}</p>
                    </div>
                )}
                {referenceType === 'HIN' && pid && (
                    <div className={spacing.spaceBelow32}>
                        <p>
                            <strong>PID</strong>
                        </p>
                        <p>{pid}</p>
                    </div>
                )}
                <ErrorBox message={authenticationError} />
                <h2 className={cn(commonStyles.subHeading, spacing.spaceBelow12)}>Here’s what you’re transferring:</h2>
                {isLoading || transfersLoadingState === 'loading' ? (
                    <Loading />
                ) : (
                    <>
                        {instruments!.map(transferOrderInstrument => {
                            const instrument = instrumentsById[transferOrderInstrument.instrumentId]
                            const shareSum = transferOrderInstrument.records.reduce((sum, record) => {
                                if (record.shares) {
                                    return sum + parseInt(record.shares, 10)
                                }
                                return sum
                            }, 0)

                            return (
                                <OrderConfirmation
                                    key={transferOrderInstrument.instrumentId}
                                    title={instrument.name}
                                    subtitle={
                                        <p className={recordStyles.instrumentSymbol}>
                                            <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                                            <PronounceLetters text={instrument.exchange} />
                                        </p>
                                    }
                                    image={<InstrumentLogo instrument={instrument} noBorder />}
                                    items={[
                                        {
                                            description: `Shares to transfer ${direction}`,
                                            value: shareSum,
                                        },
                                        ...(direction === 'out'
                                            ? [
                                                  {
                                                      description: (
                                                          <p>
                                                              Transfer fee <TransferOutFees exchange="ASX" />
                                                          </p>
                                                      ),
                                                      value: (
                                                          <DollarValue
                                                              value={transferInfo.fees.ASX.charge_amount}
                                                              decimalPlaces={0}
                                                              currency={transferInfo.fees.ASX.charge_currency}
                                                          />
                                                      ),
                                                  },
                                              ]
                                            : []),
                                    ]}
                                    total={{
                                        value: `$${getTransferInstrumentValueHomeCurrency(
                                            instrument,
                                            shareSum,
                                            exchangeRates,
                                            homeCurrency,
                                        )} ${homeCurrency.toUpperCase()}`,
                                        description: 'Transfer amount',
                                    }}
                                />
                            )
                        })}
                        {direction === 'out' ? (
                            <div className={commonStyles.feesTotalRow}>
                                <OrderConfirmation
                                    items={[]}
                                    total={{
                                        value: totalFeeValue,
                                        description: 'Total fee',
                                    }}
                                />
                            </div>
                        ) : null}
                    </>
                )}
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--confirm-transfer"
                    label="Next"
                    onClick={handleSubmit}
                    disabled={transfersLoadingState !== 'ready'}
                />
            </ActionBar>
        </>
    )
}
