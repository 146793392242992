import React from 'react'
import {FARMER_SUPPORT_NUMBER} from '~/sections/moose/constants/constants'

export const FarmerSupportNumber: React.FunctionComponent<{}> = () => {
    return (
        <a href={`tel:${FARMER_SUPPORT_NUMBER.replace(/\s/g, '')}`} data-testid="link--farmer-support-number">
            {FARMER_SUPPORT_NUMBER}
        </a>
    )
}
