import {rakaiaGetFactory} from '~/api/query/rakaia'
import {Currency} from '~/api/rakaia'
import {Response} from '~/api/retail/types'

/**
 * Use the Rakaia portfolio endpoint to determine if an INVEST portfolio has any investment history.
 *
 * @example
 * const hasInvestHistory = useHasInvestHistory(profile.portfolios)
 */
export const useHasInvestHistory = (profiles: Response.Profiles['profiles'][number]['portfolios']) => {
    const portfolioUuid = profiles.find(p => p.product === 'INVEST')?.id

    const {data} = rakaiaGetFactory({
        apiFunctionName: 'readPortfolioApiV1PortfoliosPortfolioUuidGet',
        notFoundResponse: ({portfolioUuid}) => ({
            uuid: portfolioUuid,
            currency: Currency.NZD, // doesn't matter which currency we pick, we throw it away
            portfolio_value: 0,
            portfolio_history: [],
        }),
        options: {enabled: !!portfolioUuid},
    })({portfolioUuid: portfolioUuid!}) // if undef the query is not enabled

    return Boolean(data?.portfolio_history?.length)
}
