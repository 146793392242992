import React from 'react'
import {spacing} from '~/global/scss/helpers'
import Image from '~/global/widgets/image/Image'
import idTypeDark from '~/sections/user/assets/images/id-type-dark.svg'
import idTypeLight from '~/sections/user/assets/images/id-type-light.svg'
import styles from '~/sections/user/sections/sign-up/SignUp.scss'

const ManualSignUpIdentityCheck: React.FunctionComponent = () => {
    return (
        <>
            <Image className={styles.driverslicence} src={idTypeLight} darkSrc={idTypeDark} />
            <h1 className={spacing.spaceBelow12}>Sweet! Now let’s get your ID certified</h1>
            <p className={spacing.spaceBelow16}>
                To finish signing up with this ID, you’ll need to get a copy of it certified and then send it to{' '}
                <a href="mailto:help@sharesies.co.nz">help@sharesies.co.nz</a>.
            </p>
            <p>Take a copy of your ID to one of the following people to get it certified:</p>
            <ul className={spacing.spaceBelow16}>
                <li>lawyer</li>
                <li>teacher</li>
                <li>minister</li>
                <li>justice of the peace (JP)</li>
                <li>police officer</li>
                <li>doctor</li>
                <li>kaumātua.</li>
            </ul>
            <p className={spacing.spaceBelow16}>
                They’ll need to write their full name, occupation, signature, and date with the following statement:
            </p>
            <p className={spacing.spaceBelow16}>
                “This is a certified true copy of the original as sighted by me and is of true likeness to the
                individual.”
            </p>
            <p className={spacing.spaceBelow16}>
                You’ll need to send us your certified copy within 3 months of the certification date—we’ll let you know
                when we’ve verified it, or if we need more info.
            </p>
        </>
    )
}

export default ManualSignUpIdentityCheck
