import {atom} from 'jotai'
import {CreateEstimateResponse} from '~/api/cove/types'

export interface CarAndDriverDetails {
    drivingExperience: string
    licenceType: string
    registration: string
}

export interface CreateEstimateCustomerDetails extends CarAndDriverDetails {
    addressCity: string
    addressStreet: string
    addressSuburb: string
    dateOfBirth: string
    email: string
    firstName: string
    gender: string
    lastName: string
    mobile: string
    postcode: string
}

export const carAndDriverDetailsAtom = atom<CarAndDriverDetails | undefined>(undefined)
export const coveCreateEstimateResponseAtom = atom<CreateEstimateResponse | undefined>(undefined)
export const createEstimateDetailsAtom = atom<CreateEstimateCustomerDetails | undefined>(undefined)
