import {Button} from '@design-system/button'
import {CheckboxInput} from '@design-system/checkbox-input'
import {colour, semanticColour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import cn from 'classnames'
import React from 'react'
import {Navigate, useNavigate} from 'react-router'
import {Link} from 'react-router-dom'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ProductIntroSlideContent} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {ProductIntroSlides} from '~/global/widgets/intro-slides/IntroSlides'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/identity/actions'
import screenOneDark from '../../assets/images/large-sparkes-dark.svg'
import screenOne from '../../assets/images/large-sparkes.svg'
import styles from './AiSearch.scss'

const AiSearchIntro: React.FC = () => {
    const profileUrl = useProfileUrl()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const hasSeenTerms = useAppSelector(s => s.identity.user!.has_seen.ai_search_beta_terms)
    const hasTelescopeFlag = useAppSelector(s => s.identity.flags.ai_search)

    const [showTerms, setShowTerms] = React.useState<boolean>(!hasSeenTerms)

    if (!hasTelescopeFlag) {
        return <Navigate to={profileUrl('invest')} replace />
    }

    if (showTerms) {
        return (
            <TelescopeDisclaimer
                onNext={() => {
                    dispatch(actions.MarkHasSeenFlag('ai_search_beta_terms'))
                    setShowTerms(false)
                }}
            />
        )
    }

    return (
        <AiIntro
            onNext={() => {
                dispatch(actions.MarkHasSeenFlag('ai_search_intro'))
                navigate(profileUrl('invest/ai-search'))
            }}
            onBack={() => navigate(-1)}
        />
    )
}

export const TelescopeDisclaimer: React.FC<{onNext: () => void}> = ({onNext}) => {
    const profileUrl = useProfileUrl()
    const hasSeenTerms = useAppSelector(s => s.identity.user!.has_seen.ai_search_beta_terms)
    const [hasAgreed, setHasAgreed] = React.useState(hasSeenTerms)
    return (
        <>
            <Toolbar
                dataTestId="toolbar--ai-search-disclaimer"
                title="Before you start..."
                leftButton="back"
                hideIntercom
            />
            <Page className={styles.page} overrideDefaultTopPadding="withToolbarTitle">
                <p className={spacing.spaceBelow24}>
                    AI Search is an AI-powered tool. It’s here to help you gain context and discover investments based
                    on topics that you’re interested in. It doesn’t give financial advice.
                </p>
                <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow16)}>About the results</h2>
                <p className={spacing.spaceBelow16}>
                    Results are generated by AI Search based on patterns it recognises related to your search topic, and
                    no human has considered their accuracy or relevancy.
                </p>
                <p className={spacing.spaceBelow16}>
                    Only investments listed on Sharesies are shown. The order of results is not a preference,
                    recommendation, or opinion.
                </p>
                <p className={spacing.spaceBelow16}>
                    If you don’t get the results you expect, try rewording—you may get something different.
                </p>
                <p className={spacing.spaceBelow24}>
                    Keep in mind AI Search’s limitations, such as misunderstanding nuance or lacking the most up-to-date
                    info.
                </p>
                <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow16)}>Acknowledge the risks</h2>
                <p className={spacing.spaceBelow16}>
                    It’s important that you understand the risks associated with investing. You aren’t guaranteed to
                    make money, and you might lose the money you start with.
                </p>
                <p className={spacing.spaceBelow16}>
                    By continuing, you agree to use AI Search as outlined above and as set out in further detail in the
                    terms and conditions below.
                </p>
                <CheckboxInput
                    id="ai-search-disclaimer-agree"
                    dataTestId="checkbox--ai-search-disclaimer-agree"
                    name="ai-search-disclaimer-agree"
                    label={
                        <span>
                            I’ve read and understand the{' '}
                            <Link to={profileUrl('legal/ai-search-terms')}>terms and conditions</Link>.
                        </span>
                    }
                    isTouched={true}
                    value={hasAgreed}
                    onChange={() => setHasAgreed(!hasAgreed)}
                />
                <Button dataTestId="button--review" label="Let’s go" disabled={!hasAgreed} onClick={onNext} />
            </Page>
        </>
    )
}

const AiIntro: React.FC<{onNext: () => void; onBack: () => void}> = ({onNext, onBack}) => {
    const colourMode = useColourMode()
    const [page, setPage] = React.useState<0 | 1>(0)

    if (page === 0) {
        // Just one slide, intentionally to match designs.
        return (
            <ProductIntroSlides
                content={[
                    {
                        backgroundColour: semanticColour('PageBackgroundBase', colourMode),
                        image: screenOne,
                        darkImage: screenOneDark,
                        title: 'AI Search',
                        buttonLabel: 'See how it works',
                        bodyRenderer: () => (
                            <p>
                                Discover new investments and get industry insights. Just search in your own words for
                                themes, topics, or whatever you value most!
                            </p>
                        ),
                    } as ProductIntroSlideContent,
                ]}
                onComplete={() => setPage(1)}
                onBack={onBack}
            />
        )
    }

    return <AiIntroSlides onNext={onNext} onBack={() => setPage(0)} />
}
export const AiIntroSlides: React.FC<{onBack: () => void; onNext: () => void}> = ({onNext, onBack}) => {
    const colourMode = useColourMode()
    const slides: ProductIntroSlideContent[] = [
        {
            backgroundColour: colour('Melon100', colourMode),
            title: 'Search in your own words',
            buttonLabel: 'Next',
            animation: import('~/sections/invest/sections/ai-search/assets/animations/ai-search-intro/screen-1.json'),
            animationDark: import(
                '~/sections/invest/sections/ai-search/assets/animations/ai-search-intro/screen-1-dark.json'
            ),
            bodyRenderer: () => (
                <p>
                    Whether you’re interested in ‘renewable energy stocks’ or ‘companies leading in tech’, just say it
                    your own way to AI Search.
                </p>
            ),
        },
        {
            backgroundColour: colour('Melon100', colourMode),
            title: 'Expand your knowledge',
            buttonLabel: 'Next',
            animation: import('~/sections/invest/sections/ai-search/assets/animations/ai-search-intro/screen-2.json'),
            animationDark: import(
                '~/sections/invest/sections/ai-search/assets/animations/ai-search-intro/screen-2-dark.json'
            ),
            bodyRenderer: () => (
                <p>
                    Don’t just find investments—get a deeper understanding of the factors driving different industries.
                </p>
            ),
        },
        {
            backgroundColour: colour('Melon100', colourMode),
            title: 'No personal info shared',
            buttonLabel: 'Next',
            animation: import('~/sections/invest/sections/ai-search/assets/animations/ai-search-intro/screen-3.json'),
            animationDark: import(
                '~/sections/invest/sections/ai-search/assets/animations/ai-search-intro/screen-3-dark.json'
            ),
            bodyRenderer: () => (
                <p>
                    AI Search only receives what you tell it. Each search is treated confidentially, and we don’t sell
                    your search data.
                </p>
            ),
        },
        {
            backgroundColour: colour('Melon100', colourMode),
            title: 'AI can get things wrong',
            buttonLabel: 'Next',
            animation: import('~/sections/invest/sections/ai-search/assets/animations/ai-search-intro/screen-4.json'),
            animationDark: import(
                '~/sections/invest/sections/ai-search/assets/animations/ai-search-intro/screen-4-dark.json'
            ),
            bodyRenderer: () => (
                <p>
                    Like other kinds of AI, AI Search might misunderstand you, or provide inaccurate or out-of-date
                    info. So remember to …
                </p>
            ),
        },
        {
            backgroundColour: colour('Melon100', colourMode),
            title: 'Stay search savvy',
            buttonLabel: 'Let’s go',
            animation: import('~/sections/invest/sections/ai-search/assets/animations/ai-search-intro/screen-5.json'),
            animationDark: import(
                '~/sections/invest/sections/ai-search/assets/animations/ai-search-intro/screen-5-dark.json'
            ),
            bodyRenderer: ({jurisdiction}) => (
                <p>
                    Results aren’t advice or stock picks, and performance isn’t guaranteed, so do your{' '}
                    <a
                        href={
                            jurisdiction === 'au'
                                ? 'https://sharesies.com.au/learn/due-diligence-a-fancy-word-for-doing-your-homework'
                                : 'https://sharesies.nz/learn/due-diligence-a-fancy-word-for-doing-your-homework'
                        }
                        target="_blank"
                        rel="noopener"
                    >
                        due diligence
                    </a>{' '}
                    before deciding to invest.
                </p>
            ),
        },
    ]

    return <ProductIntroSlides content={slides} onComplete={onNext} onBack={onBack} flag="ai_search_intro" />
}

export default AiSearchIntro
