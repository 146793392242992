import cn from 'classnames'
import React from 'react'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import capitalise from '~/global/utils/capitalise-string/capitaliseString'
import {remainingCurrencyAfterExercise} from '~/global/utils/exercise-cost/exerciseCost'
import {dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import {
    defaultExpiryDate,
    sortTradesByDate,
    sumTradeShareVolumes,
    totalTransactionFee,
} from '~/global/utils/order/order'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {DollarValue, FeeValue, SharePriceValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import {BuySellOrder, SellOrder} from '~/store/accounting/types'
import {Instrument} from '~/store/instrument/types'
import styles from '../Record.scss'
import {RecordRow} from '../common'
import {TradesConcertina} from './TradesConcertina'
import {OrderPlacedRow, PartialFillAveragePriceInfo, SharesBoughtOrSold} from './common'

export const TerminatedOrderDataRow: React.FC<{
    currency: string
    instrument: Instrument
    jurisdiction: Model.User['jurisdiction']
    order: BuySellOrder
    partiallyFilled: boolean
    timeStampRow?: React.ReactNode
}> = React.memo(({currency, instrument, jurisdiction, order, partiallyFilled, timeStampRow}) => {
    const [concertinaIsOpen, setConcertinaIsOpen] = React.useState(false)
    const shares = order.type === 'buy' ? parseFloat(order.order_shares || '0') : parseFloat(order.shares)
    const shareTextByShareLabel = `Number of ${shareLabel({instrument, isPlural: true})}`
    const tradesTotalShares = sumTradeShareVolumes(order)
    const sharesRemainingToSell = (order: SellOrder) => {
        return partiallyFilled ? order.shares_remaining : order.shares
    }

    const CancelledExpiredTradeRow = () => {
        if (partiallyFilled && (order.cancelled_at || ['cancelling', 'expired'].includes(order.state))) {
            const date = () => {
                switch (order.state) {
                    case 'cancelled':
                        return order.cancelled_at
                    case 'cancelling':
                        // TODO. The API response does not currently supplied the date an order
                        //  cancellation was requested; only when it was processed by the back-end.
                        return
                    case 'expired':
                        return order.order_expires || defaultExpiryDate(order)
                    default:
                        return
                }
            }
            const finalDate = date()

            const remainingCurrency = () => {
                if (order.type === 'buy') {
                    // auto exercise buys need to consider the cost to exercise
                    if (order.is_auto_exercise) {
                        return remainingCurrencyAfterExercise(order, instrument)
                    }
                    return order.remaining_currency || '0'
                }

                return '0'
            }

            const amount =
                order.type === 'buy' ? (
                    <DollarValue value={remainingCurrency()} currency={currency} />
                ) : (
                    <>
                        <ShareValue value={sharesRemainingToSell(order)} />{' '}
                        {shareLabel({instrument, isPlural: shares !== 1})}
                    </>
                )
            return (
                <div className={styles.concertinaTitleWrapper}>
                    <div className={styles.titleTop}>
                        <div className={styles.titleTopLeft}>
                            <div className={cn(styles.ellipse, styles.lightEllipse)} />
                            <p>Remaining order {order.state}</p>&nbsp;<p>({amount})</p>
                        </div>
                    </div>
                    <div className={styles.titleBottom}>
                        <p>{finalDate ? finalDate.toFormat(dateFormatWithYear) : ''}</p>
                    </div>
                </div>
            )
        }
        return null
    }

    const handleConcertinaClick = () => {
        if (concertinaIsOpen) {
            setTimeout(() => {
                setConcertinaIsOpen((concertinaIsOpen: boolean) => !concertinaIsOpen)
            }, 200)
        } else {
            setConcertinaIsOpen((concertinaIsOpen: boolean) => !concertinaIsOpen)
        }
    }

    return (
        <div className={styles.recordRowContainer}>
            <OrderPlacedRow date={order.created} />

            {/*  Order cancelled date */}
            {!partiallyFilled && timeStampRow}

            {!partiallyFilled && order.type === 'sell' && (
                <RecordRow left={capitalise(shareTextByShareLabel)} right={sharesRemainingToSell(order)} />
            )}

            {!partiallyFilled && order.price_limit && (
                <RecordRow
                    left={`${order.type === 'buy' ? 'Highest' : 'Lowest'} price to ${
                        order.type === 'buy' ? 'pay' : 'sell'
                    } per ${shareLabel({instrument})}`}
                    right={order.price_limit ? <SharePriceValue value={order.price_limit} currency={currency} /> : '-'}
                />
            )}
            {partiallyFilled && (
                <SharesBoughtOrSold
                    hideBottomBorder={partiallyFilled}
                    instrument={instrument}
                    orderType={order.type}
                    shareValue={tradesTotalShares.toString()}
                />
            )}
            {partiallyFilled && (
                <div className={cn(styles.hasBottomBorder, spacing.spaceAbove12)}>
                    {sortTradesByDate(order).map(trade => (
                        <TradesConcertina
                            key={trade.contract_note_number}
                            concertinaIsOpen={concertinaIsOpen}
                            trade={trade}
                            currency={currency}
                            handleConcertinaClick={handleConcertinaClick}
                            instrument={instrument}
                            hasSubsequentEntry={partiallyFilled}
                            jurisdiction={jurisdiction}
                        />
                    ))}
                    <CancelledExpiredTradeRow />
                </div>
            )}
            {partiallyFilled && order.price_limit && (
                <RecordRow
                    left="Limit price"
                    right={order.price_limit ? <SharePriceValue value={order.price_limit} currency={currency} /> : '-'}
                />
            )}

            {/* Average price per share */}
            {partiallyFilled && (
                <PartialFillAveragePriceInfo currency={currency} instrument={instrument} order={order} />
            )}

            {partiallyFilled && (
                <RecordRow
                    left={<div>{order.trades?.length <= 1 ? 'Transaction fee' : 'Total transaction fee'}</div>}
                    right={<FeeValue value={totalTransactionFee(order)} currency={currency} />}
                />
            )}
        </div>
    )
})
