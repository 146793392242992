/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {Currency, CurrencyFromJSON, CurrencyFromJSONTyped, CurrencyToJSON, CurrencyToJSONTyped} from './'

/**
 *
 * @export
 * @interface ExchangeRate
 */
export interface ExchangeRate {
    /**
     *
     * @type {Currency}
     * @memberof ExchangeRate
     */
    source: Currency
    /**
     *
     * @type {Currency}
     * @memberof ExchangeRate
     */
    target: Currency
    /**
     *
     * @type {Date}
     * @memberof ExchangeRate
     */
    date: DateTime
    /**
     *
     * @type {number}
     * @memberof ExchangeRate
     */
    rate: number
}

export function ExchangeRateFromJSON(json: any): ExchangeRate {
    return ExchangeRateFromJSONTyped(json, false)
}

export function ExchangeRateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExchangeRate {
    if (json === undefined || json === null) {
        return json
    }
    return {
        source: CurrencyFromJSON(json['source']),
        target: CurrencyFromJSON(json['target']),
        date: DateTime.fromISO(json['date'], {setZone: true}),
        rate: json['rate'],
    }
}

export function ExchangeRateToJSON(value?: ExchangeRate | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        source: CurrencyToJSON(value.source),
        target: CurrencyToJSON(value.target),
        date: value.date.toFormat('yyyy-MM-dd'),
        rate: value.rate,
    }
}
