import cn from 'classnames'
import React from 'react'
import {Link} from 'react-router-dom'
import {useRetailGet} from '~/api/query/retail'
import {Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {
    Purpose,
    humaniseNature,
    humaniseOtherPurpose,
    humanisePurposes,
} from '~/global/utils/nature-and-purpose/natureAndPurpose'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import styles from './NatureAndPurpose.scss'

interface NatureAndPurposeProps {
    owner: Response.Owner
    edit_url: 'settings/personal-details/how-you-invest' | 'settings/business-details/how-you-invest'
    section_class: string
}

const NatureAndPurpose: React.FunctionComponent<NatureAndPurposeProps> = ({owner, edit_url, section_class}) => {
    const profileUrl = useProfileUrl()

    const natureAndPurposeResponse = useRetailGet({
        path: 'owner/:owner_id/nature-and-purpose',
        pathParams: {owner_id: owner.id},
    }).data

    const natureAndPurpose =
        natureAndPurposeResponse.type === 'owner_nature_and_purpose' ? natureAndPurposeResponse : null

    const hasNature =
        natureAndPurpose &&
        natureAndPurpose.frequency &&
        (natureAndPurpose.annual_amount || owner.owner_type === 'ORGANISATION')

    const displayPurposes =
        natureAndPurpose &&
        natureAndPurpose.purposes &&
        !(natureAndPurpose.purposes.length === 1 && natureAndPurpose.purposes.includes('other'))

    return (
        <>
            {
                // only display nature and purpose in account if the investor has answered the nature questions in the UI flow previously
                !owner.is_kids_account && natureAndPurpose && hasNature && (
                    <>
                        <div className={section_class}>
                            <h2>How and why you’re investing</h2>
                            <Link data-testid="anchor--edit-nature-and-purpose" to={profileUrl(edit_url)}>
                                Edit
                            </Link>
                        </div>
                        <div>
                            <p className={cn(styles.tertiaryText, spacing.spaceAbove4, spacing.spaceBelow4)}>
                                Last updated on {natureAndPurpose.created.toFormat(dateFormatNoTime)}
                            </p>
                            <p className={spacing.spaceBelow24} data-testid="text--nature">
                                You’ve said you plan to invest:{' '}
                                {humaniseNature(natureAndPurpose.frequency, natureAndPurpose.annual_amount)}
                            </p>
                            <p data-testid="text--purpose">
                                {displayPurposes && (
                                    <>
                                        {humanisePurposes(natureAndPurpose.purposes as Purpose[])}
                                        <br />
                                    </>
                                )}
                                {natureAndPurpose.other_purpose &&
                                    natureAndPurpose.purposes &&
                                    humaniseOtherPurpose(natureAndPurpose.purposes, natureAndPurpose.other_purpose)}
                            </p>
                        </div>
                    </>
                )
            }
        </>
    )
}

export default NatureAndPurpose
