import React from 'react'
import {investmentActivityToInvestmentActivityWithDetail} from '~/global/utils/investment-activity/investmentActivityTypes'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useInvestingActivity} from '~/sections/moose/data/retail'
import {MoosePortfolioPathParams} from '~/sections/moose/routes'
import {PortfolioOrderRecord} from '~/sections/wallet/pages/transaction/widgets/transaction/PortfolioOrderRecord'

interface InvestingActivityDetailsProps extends MoosePortfolioPathParams {
    investmentActivityKey: string
}

const InvestingActivityDetails: React.FunctionComponent<InvestingActivityDetailsProps> = ({
    portfolioId,
    investmentActivityKey,
}) => {
    const portfolioOrder = useInvestingActivity(portfolioId, investmentActivityKey)
    if (!portfolioOrder) {
        throw new Error('Missing portfolio order')
    }
    const portfolioOrderWithDetail = investmentActivityToInvestmentActivityWithDetail(portfolioOrder)
    if (portfolioOrderWithDetail.detailType !== 'portfolio_order') {
        throw new Error('Missing portfolio order')
    }

    return (
        <div>
            <div>
                <Toolbar leftButton="back" dataTestId="toolbar--activity" />
            </div>
            <Page withoutDefaultPadding>
                <PortfolioOrderRecord order={portfolioOrderWithDetail.detail} hidePageBack={true} />
            </Page>
        </div>
    )
}

export default InvestingActivityDetails
