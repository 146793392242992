import {Button} from '@design-system/button'
import {Modal} from '@design-system/modal'
import React from 'react'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {Checkbox} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading/Loading'
import styles from '~/sections/invest/sections/us-sign-up/USSignUp.scss'
import ReviewDetails from '~/sections/invest/sections/us-sign-up/pages/review-details/ReviewDetails'
import CheckboxText from '~/sections/invest/sections/us-sign-up/widgets/checkbox-text/CheckboxText'
import W8BenCertification from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/W8BenCertification'
import {connect} from '~/store/connect'
import {useAppDispatch} from '~/store/hooks'
import signUpActions from '~/store/sign-up/actions'
import {State} from '~/store/sign-up/types'

const SignW8Ben: React.FunctionComponent<SignW8BenProps> = ({
    usStatus,
    isDependent,
    preferredName,
    jurisdiction,
    current_us_tax_treaty_status,
    usSignW8ben,
    setEditingAddress,
    setEditingMailingAddress,
    setEditingTaxResidency,
    setEditingCitizenship,
}) => {
    const dispatch = useAppDispatch()
    const [checkBoxTicked, setCheckBoxTicked] = React.useState(false)
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [modalVisible, setModalVisible] = React.useState(false)
    const [treatyOptInTicked, setTreatyOptInTicked] = React.useState(true)

    const taxCountry = jurisdiction === 'au' ? 'Australian' : 'NZ'
    const eligibleProposedTaxTreatyStatuses = ['au', 'nz']
    const other_tax_status = current_us_tax_treaty_status === 'other' ? true : false

    React.useEffect(() => {
        if (usStatus?.proposed_tax_treaty_status === 'other') {
            // If we're not showing the opt-in box, set it to false.
            setTreatyOptInTicked(false)
        }
    }, [usStatus])

    if (!usStatus) {
        return <Loading isPineapple />
    }

    const validateTreatyOptIn = () => {
        if (!treatyOptInTicked && eligibleProposedTaxTreatyStatuses.includes(usStatus.proposed_tax_treaty_status)) {
            setModalVisible(true)
        } else {
            submitForm()
        }
    }

    async function submitForm() {
        setIsSubmitting(true)
        try {
            await usSignW8ben(true, treatyOptInTicked)
            await dispatch(signUpActions.getUSStatus())
        } catch (error) {
            setIsSubmitting(false)
            throw error
        }
    }

    return (
        <>
            <ReviewDetails
                numberedSections={true}
                usStatus={usStatus}
                isDependent={isDependent}
                jurisdiction={jurisdiction}
                setEditingAddress={setEditingAddress}
                setEditingMailingAddress={setEditingMailingAddress}
                setEditingTaxResidency={setEditingTaxResidency}
                setEditingCitizenship={setEditingCitizenship}
            />

            <div className={styles.title}>
                <h3>Tax treaty benefits</h3>
            </div>
            <p>
                {jurisdiction === 'au' ? 'Australia' : 'New Zealand'} has a tax treaty with the US to avoid taxing{' '}
                {isDependent ? preferredName : 'you'} twice on the same investment.
            </p>
            <br />
            {usStatus.proposed_tax_treaty_status === 'nz' || usStatus.proposed_tax_treaty_status === 'au' ? (
                <>
                    <p>
                        {isDependent ? `${preferredName}` : 'You'} can opt in to the US tax treaty benefit{' '}
                        {other_tax_status ? 'if' : 'because'}
                        {isDependent ? ' they’re' : ' you’re'} an {taxCountry} tax resident with an {taxCountry}{' '}
                        address, and {isDependent ? `they` : 'you'} aren’t a US tax resident.
                    </p>
                    <div className={spacing.spaceAbove16}>
                        <Checkbox
                            dataTestId="checkbox--treaty-opt-in"
                            name="W-8BEN-treaty-opt-in"
                            label="Opt in to the US tax treaty benefit"
                            isTouched
                            value={treatyOptInTicked}
                            onChange={() => setTreatyOptInTicked(!treatyOptInTicked)}
                            helpText="If you opt in, we’ll apply a 15% (rather than 30%) tax rate to your US dividends on behalf of the IRS."
                        />
                    </div>
                </>
            ) : (
                <p>
                    {isDependent ? `${preferredName} doesn’t` : 'You don’t'} have{' '}
                    {jurisdiction === 'au' ? 'an Australian' : 'a New Zealand'} address so{' '}
                    {isDependent ? 'they' : 'you'} haven’t claimed a tax treaty benefit.
                </p>
            )}
            <div className={styles.title}>
                <h3>Partner jurisdictions</h3>
            </div>
            <p>
                If {isDependent ? `${preferredName} is` : 'you’re'} a resident of a FATCA partner jurisdiction (i.e. a
                Model 1 IGA country), which {jurisdiction === 'au' ? 'Australia' : 'New Zealand'} is, certain tax
                account information may be provided to that country.
            </p>

            <div className={styles.formCertificationText}>
                <W8BenCertification />
                <p>
                    The IRS does not require your consent to any provisions of this document other than the
                    certifications required to establish your status as a non-U.S. individual and, if applicable, obtain
                    a reduced rate of withholding.
                </p>
                <div className={styles.formFooter}>
                    <div className={spacing.spaceBelow16}>
                        <Checkbox
                            dataTestId="checkbox--sign-w8ben"
                            name="W-8BEN"
                            label={CheckboxText(isDependent, usStatus.name)}
                            isTouched
                            value={checkBoxTicked}
                            onChange={() => setCheckBoxTicked(!checkBoxTicked)}
                        />
                    </div>
                    <Modal
                        isOpen={modalVisible}
                        setIsOpen={setModalVisible}
                        title="US tax treaty benefit"
                        dataTestId="modal--us-tax-treaty-warning"
                        primaryButton={{
                            label: 'Opt in to the US tax treaty benefit',
                            onClick: () => {
                                setTreatyOptInTicked(true)
                            },
                        }}
                        secondaryButton={{label: 'Continue without opting in', onClick: () => submitForm()}}
                    >
                        <p>
                            {isDependent ? `${preferredName}` : 'You'} can opt in to the US tax treaty benefit{' '}
                            {other_tax_status ? 'if' : 'because'}
                            {isDependent ? ' they’re' : ' you’re'} an {taxCountry} tax resident with an {taxCountry}{' '}
                            address, and {isDependent ? `they` : 'you'} aren’t a US tax resident.
                        </p>
                        <p>
                            By not opting in to the US tax treaty benefit, {isDependent ? `${preferredName}’s` : 'your'}{' '}
                            US dividends will be taxed at 30%, rather than 15%.
                        </p>
                    </Modal>
                    <Button
                        label="Sign W-8BEN form"
                        dataTestId="button--sign-w8ben-form"
                        type="primary"
                        disabled={!checkBoxTicked}
                        processing={isSubmitting}
                        onClick={async () => validateTreatyOptIn()}
                    />
                </div>
            </div>
        </>
    )
}

interface StoreProps {
    usStatus: State['usStatus']
    isDependent: boolean
    preferredName: string
    jurisdiction: Model.User['jurisdiction']
    current_us_tax_treaty_status: Model.User['us_tax_treaty_status']
}

interface DispatchProps {
    usSignW8ben(signed: true, treatyOptInTicked: boolean): Promise<void | null>
}

interface OwnProps {
    setEditingAddress: React.Dispatch<React.SetStateAction<boolean>>
    setEditingMailingAddress: React.Dispatch<React.SetStateAction<boolean>>
    setEditingTaxResidency: React.Dispatch<React.SetStateAction<boolean>>
    setEditingCitizenship: React.Dispatch<React.SetStateAction<boolean>>
}

type SignW8BenProps = StoreProps & DispatchProps & OwnProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    ({signUp, identity}) => ({
        usStatus: signUp.usStatus,
        isDependent: identity.user!.is_dependent,
        preferredName: identity.user!.preferred_name,
        jurisdiction: identity.user!.jurisdiction,
        current_us_tax_treaty_status: identity.user!.us_tax_treaty_status,
    }),
    dispatch => ({
        usSignW8ben: (signed, treaty_opt_in) => dispatch(signUpActions.usSignW8ben(signed, treaty_opt_in)),
    }),
)(SignW8Ben)
