import {ModalLink} from '@design-system/modal'
import React from 'react'
import {Model} from '~/api/retail/types'

interface Props {
    jurisdiction: Model.User['jurisdiction']
}

const TaxResidency = ({jurisdiction}: Props) => (
    <ModalLink
        dataTestId="modal-link--tax-residency"
        label="Tax residency"
        asIcon
        modalTitle="Tax residency"
        bottomBorder
        primaryButton={{label: 'Ok'}}
        helpIconSize={16}
    >
        <p>Tax residency is different from your immigration status. </p>

        <p>
            Generally, you’re considered {jurisdiction === 'nz' && 'a New Zealand'}
            {jurisdiction === 'au' && 'an Australian'} tax resident when:
        </p>
        <ul>
            {jurisdiction === 'nz' && (
                <>
                    <li>you’ve been in NZ for more than 183 days in any 12-month period</li>
                    <li>you have somewhere in NZ you call home.</li>
                </>
            )}
            {jurisdiction === 'au' && (
                <>
                    <li>
                        you’ve been in Australia for more than 183 days in an income year (unless your usual place of
                        abode is outside Australia)
                    </li>
                    <li>
                        you’re studying and living in Australia continuously for a minimum of 183 days in an income
                        year.
                    </li>
                </>
            )}
        </ul>

        <p>
            <strong>Tax residency in other countries</strong>
            <br />
            You may also be a tax resident in countries outside {jurisdiction === 'nz' && 'NZ'}
            {jurisdiction === 'au' && 'Australia'} based on:
        </p>
        <ul>
            <li>the time you spend there</li>
            <li>your ties to that country</li>
            <li>your citizenship.</li>
        </ul>

        <p>
            Tax residency rules are different in every country, so you’ll need to check which rules might apply to your
            specific situation.
        </p>

        {jurisdiction === 'nz' && (
            <p>
                For more information on tax check out the{' '}
                <a
                    href="https://www.ird.govt.nz/international-tax/individuals/tax-residency-status-for-individuals"
                    target="_blank"
                    rel="noopener"
                >
                    Inland Revenue website
                </a>
                .
            </p>
        )}
        {jurisdiction === 'au' && (
            <p>
                To figure out your tax residency, visit the{' '}
                <a
                    href="https://www.ato.gov.au/Calculators-and-tools/Work-out-your-tax-residency/"
                    target="_blank"
                    rel="noopener"
                >
                    Australian Taxation Office website
                </a>
                .
            </p>
        )}
    </ModalLink>
)

export default TaxResidency
