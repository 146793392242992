import cn from 'classnames'
import React from 'react'
import {DistillScope} from '~/api/query/distill'
import styles from '~/sections/invest/sections/order-flow/InvestPanel.scss'
import SellOrderForm from '~/sections/invest/sections/order-flow/sections/sell/pages/sell-order-form/SellOrderForm'
import {useDistillInstrument} from '~/sections/kiwisaver/data/distill'
import {useKSFundHolding} from '~/sections/kiwisaver/state'
import {useDistillInstrumentBySlug} from '~/sections/moose/data/distill'

const KSSellPanel: React.FunctionComponent<{urlSlug: string}> = ({urlSlug}) => {
    const instrument = useDistillInstrumentBySlug(urlSlug)
    if (!instrument.fmsFundId) {
        throw Error('Should not be able to reach SellPanel without a fmsFundId')
    }
    const fmsInstrument = {
        ...useDistillInstrument({instrumentId: instrument.fmsFundId, scope: DistillScope.KIWISAVER_ALL_FUNDS}),
        currency: 'nzd' as const,
    }
    const holding = useKSFundHolding(fmsInstrument.id)

    return (
        <>
            <div className={styles.investPanelBackground} />
            <div className={cn(styles.investPanel, styles.investPanelShown, styles.investPanelAnimated)} aria-modal>
                <div className={styles.investPanelContentAnimateIn}>
                    <SellOrderForm instrument={fmsInstrument} ksFundHolding={holding} />
                </div>
            </div>
        </>
    )
}

export default KSSellPanel
