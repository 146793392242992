import {ClassicCard} from '@braze/web-sdk'
import {Button} from '@design-system/button'
import {DockableModal} from '@design-system/dockable-modal'
import {Close} from '@design-system/icon'
import {RawButton} from '@design-system/raw-button'
import cn from 'classnames'
import React from 'react'
import * as braze from '~/api/braze/braze'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import announcementEmoji from '~/global/widgets/feature-onboarding-pill/assets/images/announcement-emoji.png'
import {useNavigate} from '~/migrate-react-router'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import styles from './FeatureOnboardingPill.scss'

interface FeatureOnboardingPillProps {
    // Show the feature onboarding pill on this product, based on an extra property on the braze campaign.
    viewingOnProduct: 'portfolio' | 'save' | 'kiwisaver'
    card?: ClassicCard
}

export const linkAsRelativeLink = (buttonLink: string) => {
    // Transform non-relative app links from braze to a relative app link.
    // This is how braze users would expect this link to behave, from building other campaigns
    const url = new URL(buttonLink, window.location.href)
    if (
        ['app.sharesies.nz', 'app.sharesies.com', 'app.sharesies.nz'].some(sharesiesDomain =>
            url.host.includes(sharesiesDomain),
        )
    ) {
        return url.pathname
    }
    return buttonLink
}

// Feature onboarding pill, powered by braze campaign.
// See some instructions on how to use on the confluence: https://sharesies.atlassian.net/l/cp/o1u6sY00
const FeatureOnboardingPill: React.FC<FeatureOnboardingPillProps> = props => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const storeCard = useAppSelector(s => s.identity.notifications.featureOnboardingCard) as ClassicCard
    const [modalShown, setModalShown] = React.useState(false)

    const card = props.card ?? storeCard

    // Dynamically show/hide on scroll up/down
    const lastScrollRef = React.useRef(0)
    const [showPill, setShowPill] = React.useState(true)

    React.useLayoutEffect(() => {
        if (!card) {
            return
        }
        const handleScroll = () => {
            const currentScroll = window.scrollY

            if (currentScroll < lastScrollRef.current || currentScroll < 50) {
                setShowPill(true)
            } else {
                setShowPill(false)
            }
            lastScrollRef.current = currentScroll
        }
        window.addEventListener('scroll', handleScroll)
        handleScroll()
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [card])

    React.useEffect(() => {
        const showOnProduct = (card?.extras?.show_card_on_product ?? 'portfolio') as string
        if (card && showOnProduct === props.viewingOnProduct) {
            braze.logContentCardImpressions([card])
        }
    }, [card])

    if (!card) {
        return null
    }

    // Card data
    const headingText = card.title
    const headingLinkText = card.extras?.heading_link
    const bodyHTML = card.extras?.body_html || card.description
    const imageUrl = card.extras?.image_url || card.imageUrl
    const buttonLink = card.extras?.button_link || card.url
    const buttonText = card.extras?.button_text || card.linkText || 'View'
    const imageBackgroundColour = card.extras?.image_background_colour as string | undefined
    const showOnProduct = (card.extras?.show_card_on_product ?? 'portfolio') as string

    const hasCTAButton = !!buttonLink && !!buttonText

    const dismissCard = () => {
        setModalShown(false)
        dispatch(identityActions.RemoveNotification(card))
    }

    if (!card || !headingText || !bodyHTML) {
        return null
    }

    if (showOnProduct !== props.viewingOnProduct) {
        // Show the feature onboarding pill on either kiwisaver, save or invest product, based on an extra property on the braze campaign.
        return null
    }

    const expandPill = () => {
        setModalShown(true)
        braze.logCardClick(card)
        rudderTrack('feature_onboarding_pill', 'notification_opened', {
            type: headingText,
        })
    }

    const dismissPill = () => {
        dispatch(identityActions.RemoveNotification(card))
        rudderTrack('feature_onboarding_pill', 'dismissed')
        setShowPill(false)
    }

    const makeHeadingTextWithLink = () => {
        if (headingLinkText === undefined || headingLinkText.length === 0) {
            return null
        }
        const index = headingText.indexOf(headingLinkText)
        if (index < 0) {
            return null
        }
        const preLink = headingText.substring(0, index)
        const postLink = headingText.substring(index + headingLinkText.length)
        return (
            <>
                {preLink}
                <span className={styles.textAsLink}>{headingLinkText}</span>
                {postLink}
            </>
        )
    }

    const headingTextWithLink = makeHeadingTextWithLink()

    const expandPillContent = (
        <>
            <div className={styles.icon}>
                <img src={announcementEmoji} alt=""></img>
            </div>
            {headingTextWithLink !== null ? (
                <div className={styles.announcementTextWithLink}>{headingTextWithLink}</div>
            ) : (
                <div className={styles.announcementText}>{headingText}</div>
            )}
        </>
    )

    const dismissPillContent = (
        <div className={styles.dismissButton}>
            <Close size={16} />
        </div>
    )

    return (
        <>
            <div className={styles.wrapper}>
                <div className={cn(styles.featureOnboardingPill, showPill && styles.shown)}>
                    <RawButton
                        dataTestId="button--expand-feature-pill--icon"
                        additionalClassName={styles.controlAreaLeft}
                        onClick={expandPill}
                        label={expandPillContent}
                    />
                    <RawButton
                        dataTestId="button--dismiss"
                        additionalClassName={styles.controlAreaRight}
                        onClick={dismissPill}
                        label={dismissPillContent}
                    />
                </div>
            </div>
            <DockableModal
                dataTestId="modal--confirm-cancel-transfer"
                additionalClassName={styles.modal}
                isOpen={modalShown}
                setIsOpen={open => {
                    if (!open) {
                        rudderTrack('feature_onboarding_pill', 'notification_closed')
                    }
                    setModalShown(open)
                }}
                title={
                    <div className={styles.modalTitle}>
                        <div className={styles.icon}>
                            <img src={announcementEmoji} alt="" />
                        </div>
                        {headingText}
                        <div />
                    </div>
                }
                content={
                    <div className={styles.modalBody}>
                        {imageUrl && (
                            <div className={styles.announcementImage} style={{backgroundColor: imageBackgroundColour}}>
                                <img src={imageUrl} alt={headingText} />
                            </div>
                        )}
                        <div dangerouslySetInnerHTML={{__html: bodyHTML}}></div>
                        <div className={styles.modalActions}>
                            <Button
                                dataTestId="button--dismiss"
                                label={hasCTAButton ? 'I’m OK for now' : 'Cool!'}
                                type="secondary"
                                onClick={() => {
                                    rudderTrack('feature_onboarding_pill', 'button_clicked', {
                                        label: "I'm OK for now",
                                        target: 'portfolio',
                                    })
                                    dismissCard()
                                }}
                            />
                            {buttonLink && buttonText && (
                                <Button
                                    dataTestId="button--action"
                                    label={buttonText}
                                    type="primary"
                                    onClick={() => {
                                        const to = linkAsRelativeLink(buttonLink)
                                        rudderTrack('feature_onboarding_pill', 'button_clicked', {
                                            label: buttonText,
                                            target: to,
                                        })
                                        navigate(to)
                                    }}
                                />
                            )}
                        </div>
                    </div>
                }
            />
            <img src={imageUrl} alt={headingText} style={{display: 'none'}} />
        </>
    )
}

export default FeatureOnboardingPill
