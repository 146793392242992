/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {DateTime} from 'luxon'
import * as runtime from '../runtime'
import {
    AppSchemasForAdminPortfolioInstrumentInvestingHistory,
    AppSchemasForAdminPortfolioInstrumentInvestingHistoryFromJSON,
    AppSchemasForAdminPortfolioInstrumentInvestingHistoryToJSON,
    AppSchemasForAdminPortfolioLatestInstrumentReturns,
    AppSchemasForAdminPortfolioLatestInstrumentReturnsFromJSON,
    AppSchemasForAdminPortfolioLatestInstrumentReturnsToJSON,
    AppSchemasForAdminPortfolioPortfolio,
    AppSchemasForAdminPortfolioPortfolioFromJSON,
    AppSchemasForAdminPortfolioPortfolioToJSON,
    AppSchemasForAdminPortfolioRecordList,
    AppSchemasForAdminPortfolioRecordListFromJSON,
    AppSchemasForAdminPortfolioRecordListToJSON,
    AppSchemasForClientPortfolioInstrumentInvestingHistory,
    AppSchemasForClientPortfolioInstrumentInvestingHistoryFromJSON,
    AppSchemasForClientPortfolioInstrumentInvestingHistoryToJSON,
    AppSchemasForClientPortfolioLatestInstrumentReturns,
    AppSchemasForClientPortfolioLatestInstrumentReturnsFromJSON,
    AppSchemasForClientPortfolioLatestInstrumentReturnsToJSON,
    AppSchemasForClientPortfolioPortfolio,
    AppSchemasForClientPortfolioPortfolioFromJSON,
    AppSchemasForClientPortfolioPortfolioToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    PortfolioFlagList,
    PortfolioFlagListFromJSON,
    PortfolioFlagListToJSON,
    PortfolioHistoryByDate,
    PortfolioHistoryByDateFromJSON,
    PortfolioHistoryByDateToJSON,
    PortfolioInvestingHistory,
    PortfolioInvestingHistoryFromJSON,
    PortfolioInvestingHistoryToJSON,
    PortfolioInvestingHistoryList,
    PortfolioInvestingHistoryListFromJSON,
    PortfolioInvestingHistoryListToJSON,
    PortfolioList,
    PortfolioListFromJSON,
    PortfolioListToJSON,
    Portfolios,
    PortfoliosFromJSON,
    PortfoliosToJSON,
    VerifyInstrumentHoldings,
    VerifyInstrumentHoldingsFromJSON,
    VerifyInstrumentHoldingsToJSON,
    VerifyInstrumentHoldingsResponse,
    VerifyInstrumentHoldingsResponseFromJSON,
    VerifyInstrumentHoldingsResponseToJSON,
    VerifyPortfolioReturns,
    VerifyPortfolioReturnsFromJSON,
    VerifyPortfolioReturnsToJSON,
} from '../models'

export interface GetInstrumentInvestingHistoryApiV1AdminPortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGetRequest {
    instrumentUuid: string
    portfolioUuid: string
}

export interface GetInstrumentInvestingHistoryApiV1PortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGetRequest {
    portfolioUuid: string
    instrumentUuid: string
}

export interface GetInstrumentRecordsApiV1AdminPortfoliosPortfolioUuidRecordsInstrumentUuidGetRequest {
    instrumentUuid: string
    portfolioUuid: string
}

export interface GetInvestingHoldingsReportInfoApiV1AdminPortfoliosPortfolioUuidInvestingHoldingsReportStartEndGetRequest {
    start: DateTime
    end: DateTime
    portfolioUuid: string
}

export interface GetLatestInstrumentReturnsApiV1AdminPortfoliosPortfolioUuidInstrumentsGetRequest {
    portfolioUuid: string
}

export interface GetLatestInstrumentReturnsApiV1PortfoliosPortfolioUuidInstrumentsGetRequest {
    portfolioUuid: string
}

export interface GetPortfolioInvestingHistoriesForDateApiV1AdminPortfoliosPortfolioInvestingHistoryForDatePostRequest {
    portfolioHistoryByDate: PortfolioHistoryByDate
}

export interface GetPortfolioInvestingHistoryApiV1AdminPortfoliosPortfolioUuidPortfolioInvestingHistoryGetRequest {
    portfolioUuid: string
}

export interface ReadPortfolioApiV1AdminPortfoliosPortfolioUuidGetRequest {
    portfolioUuid: string
    date?: DateTime
}

export interface ReadPortfolioApiV1PortfoliosPortfolioUuidGetRequest {
    portfolioUuid: string
}

export interface RecalculatePortfolioApiV1AdminPortfoliosRecalculatePortfolioUuidGetRequest {
    portfolioUuid: string
}

export interface RecalculatePortfoliosApiV1AdminPortfoliosRecalculatePortfoliosPostRequest {
    portfolioList: PortfolioList
}

export interface ResetPortfoliosApiV1AdminPortfoliosResetPostRequest {
    portfolioList: PortfolioList
}

export interface SetFailFlagApiV1AdminPortfoliosSetFailFlagPostRequest {
    portfolioFlagList: PortfolioFlagList
}

export interface VerifyInstrumentHoldingsApiV1ValidatorsPortfoliosVerifyInstrumentHoldingsPostRequest {
    verifyInstrumentHoldings: VerifyInstrumentHoldings
}

export interface VerifyPortfolioApiV1ValidatorsPortfoliosVerifyPortfolioPostRequest {
    verifyPortfolioReturns: VerifyPortfolioReturns
}

/**
 *
 */
export class PortfoliosApi extends runtime.BaseAPI {
    /**
     * Get Instrument Investing History
     */
    async getInstrumentInvestingHistoryApiV1AdminPortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGetRaw(
        requestParameters: GetInstrumentInvestingHistoryApiV1AdminPortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGetRequest,
    ): Promise<runtime.ApiResponse<AppSchemasForAdminPortfolioInstrumentInvestingHistory>> {
        if (requestParameters.instrumentUuid === null || requestParameters.instrumentUuid === undefined) {
            throw new runtime.RequiredError(
                'instrumentUuid',
                'Required parameter requestParameters.instrumentUuid was null or undefined when calling getInstrumentInvestingHistoryApiV1AdminPortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGet.',
            )
        }

        if (requestParameters.portfolioUuid === null || requestParameters.portfolioUuid === undefined) {
            throw new runtime.RequiredError(
                'portfolioUuid',
                'Required parameter requestParameters.portfolioUuid was null or undefined when calling getInstrumentInvestingHistoryApiV1AdminPortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/admin/portfolios/{portfolio_uuid}/investing-history/{instrument_uuid}`
                .replace(`{${'instrument_uuid'}}`, encodeURIComponent(String(requestParameters.instrumentUuid)))
                .replace(`{${'portfolio_uuid'}}`, encodeURIComponent(String(requestParameters.portfolioUuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue =>
            AppSchemasForAdminPortfolioInstrumentInvestingHistoryFromJSON(jsonValue),
        )
    }

    /**
     * Get Instrument Investing History
     */
    async getInstrumentInvestingHistoryApiV1AdminPortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGet(
        requestParameters: GetInstrumentInvestingHistoryApiV1AdminPortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGetRequest,
    ): Promise<AppSchemasForAdminPortfolioInstrumentInvestingHistory> {
        const response =
            await this.getInstrumentInvestingHistoryApiV1AdminPortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGetRaw(
                requestParameters,
            )
        return await response.value()
    }

    /**
     * Get Instrument Investing History
     */
    async getInstrumentInvestingHistoryApiV1PortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGetRaw(
        requestParameters: GetInstrumentInvestingHistoryApiV1PortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGetRequest,
    ): Promise<runtime.ApiResponse<AppSchemasForClientPortfolioInstrumentInvestingHistory>> {
        if (requestParameters.portfolioUuid === null || requestParameters.portfolioUuid === undefined) {
            throw new runtime.RequiredError(
                'portfolioUuid',
                'Required parameter requestParameters.portfolioUuid was null or undefined when calling getInstrumentInvestingHistoryApiV1PortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGet.',
            )
        }

        if (requestParameters.instrumentUuid === null || requestParameters.instrumentUuid === undefined) {
            throw new runtime.RequiredError(
                'instrumentUuid',
                'Required parameter requestParameters.instrumentUuid was null or undefined when calling getInstrumentInvestingHistoryApiV1PortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/portfolios/{portfolio_uuid}/investing-history/{instrument_uuid}`
                .replace(`{${'portfolio_uuid'}}`, encodeURIComponent(String(requestParameters.portfolioUuid)))
                .replace(`{${'instrument_uuid'}}`, encodeURIComponent(String(requestParameters.instrumentUuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue =>
            AppSchemasForClientPortfolioInstrumentInvestingHistoryFromJSON(jsonValue),
        )
    }

    /**
     * Get Instrument Investing History
     */
    async getInstrumentInvestingHistoryApiV1PortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGet(
        requestParameters: GetInstrumentInvestingHistoryApiV1PortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGetRequest,
    ): Promise<AppSchemasForClientPortfolioInstrumentInvestingHistory> {
        const response =
            await this.getInstrumentInvestingHistoryApiV1PortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGetRaw(
                requestParameters,
            )
        return await response.value()
    }

    /**
     * Get Instrument Records
     */
    async getInstrumentRecordsApiV1AdminPortfoliosPortfolioUuidRecordsInstrumentUuidGetRaw(
        requestParameters: GetInstrumentRecordsApiV1AdminPortfoliosPortfolioUuidRecordsInstrumentUuidGetRequest,
    ): Promise<runtime.ApiResponse<AppSchemasForAdminPortfolioRecordList>> {
        if (requestParameters.instrumentUuid === null || requestParameters.instrumentUuid === undefined) {
            throw new runtime.RequiredError(
                'instrumentUuid',
                'Required parameter requestParameters.instrumentUuid was null or undefined when calling getInstrumentRecordsApiV1AdminPortfoliosPortfolioUuidRecordsInstrumentUuidGet.',
            )
        }

        if (requestParameters.portfolioUuid === null || requestParameters.portfolioUuid === undefined) {
            throw new runtime.RequiredError(
                'portfolioUuid',
                'Required parameter requestParameters.portfolioUuid was null or undefined when calling getInstrumentRecordsApiV1AdminPortfoliosPortfolioUuidRecordsInstrumentUuidGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/admin/portfolios/{portfolio_uuid}/records/{instrument_uuid}`
                .replace(`{${'instrument_uuid'}}`, encodeURIComponent(String(requestParameters.instrumentUuid)))
                .replace(`{${'portfolio_uuid'}}`, encodeURIComponent(String(requestParameters.portfolioUuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue =>
            AppSchemasForAdminPortfolioRecordListFromJSON(jsonValue),
        )
    }

    /**
     * Get Instrument Records
     */
    async getInstrumentRecordsApiV1AdminPortfoliosPortfolioUuidRecordsInstrumentUuidGet(
        requestParameters: GetInstrumentRecordsApiV1AdminPortfoliosPortfolioUuidRecordsInstrumentUuidGetRequest,
    ): Promise<AppSchemasForAdminPortfolioRecordList> {
        const response =
            await this.getInstrumentRecordsApiV1AdminPortfoliosPortfolioUuidRecordsInstrumentUuidGetRaw(
                requestParameters,
            )
        return await response.value()
    }

    /**
     * Retrieves holdings info required for investing holdings report (downloadable csv) from within the app.
     * Get Investing Holdings Report Info
     */
    async getInvestingHoldingsReportInfoApiV1AdminPortfoliosPortfolioUuidInvestingHoldingsReportStartEndGetRaw(
        requestParameters: GetInvestingHoldingsReportInfoApiV1AdminPortfoliosPortfolioUuidInvestingHoldingsReportStartEndGetRequest,
    ): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.start === null || requestParameters.start === undefined) {
            throw new runtime.RequiredError(
                'start',
                'Required parameter requestParameters.start was null or undefined when calling getInvestingHoldingsReportInfoApiV1AdminPortfoliosPortfolioUuidInvestingHoldingsReportStartEndGet.',
            )
        }

        if (requestParameters.end === null || requestParameters.end === undefined) {
            throw new runtime.RequiredError(
                'end',
                'Required parameter requestParameters.end was null or undefined when calling getInvestingHoldingsReportInfoApiV1AdminPortfoliosPortfolioUuidInvestingHoldingsReportStartEndGet.',
            )
        }

        if (requestParameters.portfolioUuid === null || requestParameters.portfolioUuid === undefined) {
            throw new runtime.RequiredError(
                'portfolioUuid',
                'Required parameter requestParameters.portfolioUuid was null or undefined when calling getInvestingHoldingsReportInfoApiV1AdminPortfoliosPortfolioUuidInvestingHoldingsReportStartEndGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/admin/portfolios/{portfolio_uuid}/investing-holdings-report/{start}/{end}`
                .replace(`{${'start'}}`, encodeURIComponent(String(requestParameters.start)))
                .replace(`{${'end'}}`, encodeURIComponent(String(requestParameters.end)))
                .replace(`{${'portfolio_uuid'}}`, encodeURIComponent(String(requestParameters.portfolioUuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse<any>(response)
    }

    /**
     * Retrieves holdings info required for investing holdings report (downloadable csv) from within the app.
     * Get Investing Holdings Report Info
     */
    async getInvestingHoldingsReportInfoApiV1AdminPortfoliosPortfolioUuidInvestingHoldingsReportStartEndGet(
        requestParameters: GetInvestingHoldingsReportInfoApiV1AdminPortfoliosPortfolioUuidInvestingHoldingsReportStartEndGetRequest,
    ): Promise<object> {
        const response =
            await this.getInvestingHoldingsReportInfoApiV1AdminPortfoliosPortfolioUuidInvestingHoldingsReportStartEndGetRaw(
                requestParameters,
            )
        return await response.value()
    }

    /**
     * Get Latest Instrument Returns
     */
    async getLatestInstrumentReturnsApiV1AdminPortfoliosPortfolioUuidInstrumentsGetRaw(
        requestParameters: GetLatestInstrumentReturnsApiV1AdminPortfoliosPortfolioUuidInstrumentsGetRequest,
    ): Promise<runtime.ApiResponse<AppSchemasForAdminPortfolioLatestInstrumentReturns>> {
        if (requestParameters.portfolioUuid === null || requestParameters.portfolioUuid === undefined) {
            throw new runtime.RequiredError(
                'portfolioUuid',
                'Required parameter requestParameters.portfolioUuid was null or undefined when calling getLatestInstrumentReturnsApiV1AdminPortfoliosPortfolioUuidInstrumentsGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/admin/portfolios/{portfolio_uuid}/instruments`.replace(
                `{${'portfolio_uuid'}}`,
                encodeURIComponent(String(requestParameters.portfolioUuid)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue =>
            AppSchemasForAdminPortfolioLatestInstrumentReturnsFromJSON(jsonValue),
        )
    }

    /**
     * Get Latest Instrument Returns
     */
    async getLatestInstrumentReturnsApiV1AdminPortfoliosPortfolioUuidInstrumentsGet(
        requestParameters: GetLatestInstrumentReturnsApiV1AdminPortfoliosPortfolioUuidInstrumentsGetRequest,
    ): Promise<AppSchemasForAdminPortfolioLatestInstrumentReturns> {
        const response =
            await this.getLatestInstrumentReturnsApiV1AdminPortfoliosPortfolioUuidInstrumentsGetRaw(requestParameters)
        return await response.value()
    }

    /**
     * Get Latest Instrument Returns
     */
    async getLatestInstrumentReturnsApiV1PortfoliosPortfolioUuidInstrumentsGetRaw(
        requestParameters: GetLatestInstrumentReturnsApiV1PortfoliosPortfolioUuidInstrumentsGetRequest,
    ): Promise<runtime.ApiResponse<AppSchemasForClientPortfolioLatestInstrumentReturns>> {
        if (requestParameters.portfolioUuid === null || requestParameters.portfolioUuid === undefined) {
            throw new runtime.RequiredError(
                'portfolioUuid',
                'Required parameter requestParameters.portfolioUuid was null or undefined when calling getLatestInstrumentReturnsApiV1PortfoliosPortfolioUuidInstrumentsGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/portfolios/{portfolio_uuid}/instruments`.replace(
                `{${'portfolio_uuid'}}`,
                encodeURIComponent(String(requestParameters.portfolioUuid)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue =>
            AppSchemasForClientPortfolioLatestInstrumentReturnsFromJSON(jsonValue),
        )
    }

    /**
     * Get Latest Instrument Returns
     */
    async getLatestInstrumentReturnsApiV1PortfoliosPortfolioUuidInstrumentsGet(
        requestParameters: GetLatestInstrumentReturnsApiV1PortfoliosPortfolioUuidInstrumentsGetRequest,
    ): Promise<AppSchemasForClientPortfolioLatestInstrumentReturns> {
        const response =
            await this.getLatestInstrumentReturnsApiV1PortfoliosPortfolioUuidInstrumentsGetRaw(requestParameters)
        return await response.value()
    }

    /**
     * Get Portfolio Investing Histories For Date
     */
    async getPortfolioInvestingHistoriesForDateApiV1AdminPortfoliosPortfolioInvestingHistoryForDatePostRaw(
        requestParameters: GetPortfolioInvestingHistoriesForDateApiV1AdminPortfoliosPortfolioInvestingHistoryForDatePostRequest,
    ): Promise<runtime.ApiResponse<PortfolioInvestingHistoryList>> {
        if (
            requestParameters.portfolioHistoryByDate === null ||
            requestParameters.portfolioHistoryByDate === undefined
        ) {
            throw new runtime.RequiredError(
                'portfolioHistoryByDate',
                'Required parameter requestParameters.portfolioHistoryByDate was null or undefined when calling getPortfolioInvestingHistoriesForDateApiV1AdminPortfoliosPortfolioInvestingHistoryForDatePost.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/admin/portfolios/portfolio-investing-history-for-date`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PortfolioHistoryByDateToJSON(requestParameters.portfolioHistoryByDate),
        })

        return new runtime.JSONApiResponse(response, jsonValue => PortfolioInvestingHistoryListFromJSON(jsonValue))
    }

    /**
     * Get Portfolio Investing Histories For Date
     */
    async getPortfolioInvestingHistoriesForDateApiV1AdminPortfoliosPortfolioInvestingHistoryForDatePost(
        requestParameters: GetPortfolioInvestingHistoriesForDateApiV1AdminPortfoliosPortfolioInvestingHistoryForDatePostRequest,
    ): Promise<PortfolioInvestingHistoryList> {
        const response =
            await this.getPortfolioInvestingHistoriesForDateApiV1AdminPortfoliosPortfolioInvestingHistoryForDatePostRaw(
                requestParameters,
            )
        return await response.value()
    }

    /**
     * Get Portfolio Investing History
     */
    async getPortfolioInvestingHistoryApiV1AdminPortfoliosPortfolioUuidPortfolioInvestingHistoryGetRaw(
        requestParameters: GetPortfolioInvestingHistoryApiV1AdminPortfoliosPortfolioUuidPortfolioInvestingHistoryGetRequest,
    ): Promise<runtime.ApiResponse<PortfolioInvestingHistory>> {
        if (requestParameters.portfolioUuid === null || requestParameters.portfolioUuid === undefined) {
            throw new runtime.RequiredError(
                'portfolioUuid',
                'Required parameter requestParameters.portfolioUuid was null or undefined when calling getPortfolioInvestingHistoryApiV1AdminPortfoliosPortfolioUuidPortfolioInvestingHistoryGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/admin/portfolios/{portfolio_uuid}/portfolio-investing-history`.replace(
                `{${'portfolio_uuid'}}`,
                encodeURIComponent(String(requestParameters.portfolioUuid)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => PortfolioInvestingHistoryFromJSON(jsonValue))
    }

    /**
     * Get Portfolio Investing History
     */
    async getPortfolioInvestingHistoryApiV1AdminPortfoliosPortfolioUuidPortfolioInvestingHistoryGet(
        requestParameters: GetPortfolioInvestingHistoryApiV1AdminPortfoliosPortfolioUuidPortfolioInvestingHistoryGetRequest,
    ): Promise<PortfolioInvestingHistory> {
        const response =
            await this.getPortfolioInvestingHistoryApiV1AdminPortfoliosPortfolioUuidPortfolioInvestingHistoryGetRaw(
                requestParameters,
            )
        return await response.value()
    }

    /**
     * Portfolios Summary
     */
    async portfoliosSummaryApiV1PortfoliosSummaryAllGetRaw(): Promise<runtime.ApiResponse<Portfolios>> {
        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/portfolios/summary/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => PortfoliosFromJSON(jsonValue))
    }

    /**
     * Portfolios Summary
     */
    async portfoliosSummaryApiV1PortfoliosSummaryAllGet(): Promise<Portfolios> {
        const response = await this.portfoliosSummaryApiV1PortfoliosSummaryAllGetRaw()
        return await response.value()
    }

    /**
     * Read Portfolio
     */
    async readPortfolioApiV1AdminPortfoliosPortfolioUuidGetRaw(
        requestParameters: ReadPortfolioApiV1AdminPortfoliosPortfolioUuidGetRequest,
    ): Promise<runtime.ApiResponse<AppSchemasForAdminPortfolioPortfolio>> {
        if (requestParameters.portfolioUuid === null || requestParameters.portfolioUuid === undefined) {
            throw new runtime.RequiredError(
                'portfolioUuid',
                'Required parameter requestParameters.portfolioUuid was null or undefined when calling readPortfolioApiV1AdminPortfoliosPortfolioUuidGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toFormat('yyyy-MM-dd')
        }

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/admin/portfolios/{portfolio_uuid}`.replace(
                `{${'portfolio_uuid'}}`,
                encodeURIComponent(String(requestParameters.portfolioUuid)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue =>
            AppSchemasForAdminPortfolioPortfolioFromJSON(jsonValue),
        )
    }

    /**
     * Read Portfolio
     */
    async readPortfolioApiV1AdminPortfoliosPortfolioUuidGet(
        requestParameters: ReadPortfolioApiV1AdminPortfoliosPortfolioUuidGetRequest,
    ): Promise<AppSchemasForAdminPortfolioPortfolio> {
        const response = await this.readPortfolioApiV1AdminPortfoliosPortfolioUuidGetRaw(requestParameters)
        return await response.value()
    }

    /**
     * Read Portfolio
     */
    async readPortfolioApiV1PortfoliosPortfolioUuidGetRaw(
        requestParameters: ReadPortfolioApiV1PortfoliosPortfolioUuidGetRequest,
    ): Promise<runtime.ApiResponse<AppSchemasForClientPortfolioPortfolio>> {
        if (requestParameters.portfolioUuid === null || requestParameters.portfolioUuid === undefined) {
            throw new runtime.RequiredError(
                'portfolioUuid',
                'Required parameter requestParameters.portfolioUuid was null or undefined when calling readPortfolioApiV1PortfoliosPortfolioUuidGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/portfolios/{portfolio_uuid}`.replace(
                `{${'portfolio_uuid'}}`,
                encodeURIComponent(String(requestParameters.portfolioUuid)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue =>
            AppSchemasForClientPortfolioPortfolioFromJSON(jsonValue),
        )
    }

    /**
     * Read Portfolio
     */
    async readPortfolioApiV1PortfoliosPortfolioUuidGet(
        requestParameters: ReadPortfolioApiV1PortfoliosPortfolioUuidGetRequest,
    ): Promise<AppSchemasForClientPortfolioPortfolio> {
        const response = await this.readPortfolioApiV1PortfoliosPortfolioUuidGetRaw(requestParameters)
        return await response.value()
    }

    /**
     * Recalculate Portfolio
     */
    async recalculatePortfolioApiV1AdminPortfoliosRecalculatePortfolioUuidGetRaw(
        requestParameters: RecalculatePortfolioApiV1AdminPortfoliosRecalculatePortfolioUuidGetRequest,
    ): Promise<runtime.ApiResponse<AppSchemasForAdminPortfolioPortfolio>> {
        if (requestParameters.portfolioUuid === null || requestParameters.portfolioUuid === undefined) {
            throw new runtime.RequiredError(
                'portfolioUuid',
                'Required parameter requestParameters.portfolioUuid was null or undefined when calling recalculatePortfolioApiV1AdminPortfoliosRecalculatePortfolioUuidGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/admin/portfolios/recalculate/{portfolio_uuid}`.replace(
                `{${'portfolio_uuid'}}`,
                encodeURIComponent(String(requestParameters.portfolioUuid)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue =>
            AppSchemasForAdminPortfolioPortfolioFromJSON(jsonValue),
        )
    }

    /**
     * Recalculate Portfolio
     */
    async recalculatePortfolioApiV1AdminPortfoliosRecalculatePortfolioUuidGet(
        requestParameters: RecalculatePortfolioApiV1AdminPortfoliosRecalculatePortfolioUuidGetRequest,
    ): Promise<AppSchemasForAdminPortfolioPortfolio> {
        const response =
            await this.recalculatePortfolioApiV1AdminPortfoliosRecalculatePortfolioUuidGetRaw(requestParameters)
        return await response.value()
    }

    /**
     * Queue portfolios for a full recalculation.
     * Recalculate Portfolios
     */
    async recalculatePortfoliosApiV1AdminPortfoliosRecalculatePortfoliosPostRaw(
        requestParameters: RecalculatePortfoliosApiV1AdminPortfoliosRecalculatePortfoliosPostRequest,
    ): Promise<runtime.ApiResponse<PortfolioList>> {
        if (requestParameters.portfolioList === null || requestParameters.portfolioList === undefined) {
            throw new runtime.RequiredError(
                'portfolioList',
                'Required parameter requestParameters.portfolioList was null or undefined when calling recalculatePortfoliosApiV1AdminPortfoliosRecalculatePortfoliosPost.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/admin/portfolios/recalculate-portfolios`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PortfolioListToJSON(requestParameters.portfolioList),
        })

        return new runtime.JSONApiResponse(response, jsonValue => PortfolioListFromJSON(jsonValue))
    }

    /**
     * Queue portfolios for a full recalculation.
     * Recalculate Portfolios
     */
    async recalculatePortfoliosApiV1AdminPortfoliosRecalculatePortfoliosPost(
        requestParameters: RecalculatePortfoliosApiV1AdminPortfoliosRecalculatePortfoliosPostRequest,
    ): Promise<PortfolioList> {
        const response =
            await this.recalculatePortfoliosApiV1AdminPortfoliosRecalculatePortfoliosPostRaw(requestParameters)
        return await response.value()
    }

    /**
     * Reset a list of portfolios to ensure a full recalculation the next time the portfolio accessed.
     * Reset Portfolios
     */
    async resetPortfoliosApiV1AdminPortfoliosResetPostRaw(
        requestParameters: ResetPortfoliosApiV1AdminPortfoliosResetPostRequest,
    ): Promise<runtime.ApiResponse<PortfolioList>> {
        if (requestParameters.portfolioList === null || requestParameters.portfolioList === undefined) {
            throw new runtime.RequiredError(
                'portfolioList',
                'Required parameter requestParameters.portfolioList was null or undefined when calling resetPortfoliosApiV1AdminPortfoliosResetPost.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/admin/portfolios/reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PortfolioListToJSON(requestParameters.portfolioList),
        })

        return new runtime.JSONApiResponse(response, jsonValue => PortfolioListFromJSON(jsonValue))
    }

    /**
     * Reset a list of portfolios to ensure a full recalculation the next time the portfolio accessed.
     * Reset Portfolios
     */
    async resetPortfoliosApiV1AdminPortfoliosResetPost(
        requestParameters: ResetPortfoliosApiV1AdminPortfoliosResetPostRequest,
    ): Promise<PortfolioList> {
        const response = await this.resetPortfoliosApiV1AdminPortfoliosResetPostRaw(requestParameters)
        return await response.value()
    }

    /**
     * Set Fail Flag
     */
    async setFailFlagApiV1AdminPortfoliosSetFailFlagPostRaw(
        requestParameters: SetFailFlagApiV1AdminPortfoliosSetFailFlagPostRequest,
    ): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.portfolioFlagList === null || requestParameters.portfolioFlagList === undefined) {
            throw new runtime.RequiredError(
                'portfolioFlagList',
                'Required parameter requestParameters.portfolioFlagList was null or undefined when calling setFailFlagApiV1AdminPortfoliosSetFailFlagPost.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/admin/portfolios/set-fail-flag`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PortfolioFlagListToJSON(requestParameters.portfolioFlagList),
        })

        return new runtime.JSONApiResponse<any>(response)
    }

    /**
     * Set Fail Flag
     */
    async setFailFlagApiV1AdminPortfoliosSetFailFlagPost(
        requestParameters: SetFailFlagApiV1AdminPortfoliosSetFailFlagPostRequest,
    ): Promise<object> {
        const response = await this.setFailFlagApiV1AdminPortfoliosSetFailFlagPostRaw(requestParameters)
        return await response.value()
    }

    /**
     * Verify Instrument Holdings
     */
    async verifyInstrumentHoldingsApiV1ValidatorsPortfoliosVerifyInstrumentHoldingsPostRaw(
        requestParameters: VerifyInstrumentHoldingsApiV1ValidatorsPortfoliosVerifyInstrumentHoldingsPostRequest,
    ): Promise<runtime.ApiResponse<VerifyInstrumentHoldingsResponse>> {
        if (
            requestParameters.verifyInstrumentHoldings === null ||
            requestParameters.verifyInstrumentHoldings === undefined
        ) {
            throw new runtime.RequiredError(
                'verifyInstrumentHoldings',
                'Required parameter requestParameters.verifyInstrumentHoldings was null or undefined when calling verifyInstrumentHoldingsApiV1ValidatorsPortfoliosVerifyInstrumentHoldingsPost.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/validators/portfolios/verify-instrument-holdings/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyInstrumentHoldingsToJSON(requestParameters.verifyInstrumentHoldings),
        })

        return new runtime.JSONApiResponse(response, jsonValue => VerifyInstrumentHoldingsResponseFromJSON(jsonValue))
    }

    /**
     * Verify Instrument Holdings
     */
    async verifyInstrumentHoldingsApiV1ValidatorsPortfoliosVerifyInstrumentHoldingsPost(
        requestParameters: VerifyInstrumentHoldingsApiV1ValidatorsPortfoliosVerifyInstrumentHoldingsPostRequest,
    ): Promise<VerifyInstrumentHoldingsResponse> {
        const response =
            await this.verifyInstrumentHoldingsApiV1ValidatorsPortfoliosVerifyInstrumentHoldingsPostRaw(
                requestParameters,
            )
        return await response.value()
    }

    /**
     * This function compares the backend\'s calculations for yesterdays portfolio totals and compares it to Rakaias calcuations. A date can optionally be passed in (portfolio_calculations.date), but if no date is passed in we use yesterday rather than today as prices and exchange rates can drift during a day but will be set at the day end.  We expect the following values to be diffed: - total return - simple return - cost basis - investment_value - dividends per currency (NZD, USD, AUD)
     * Verify Portfolio
     */
    async verifyPortfolioApiV1ValidatorsPortfoliosVerifyPortfolioPostRaw(
        requestParameters: VerifyPortfolioApiV1ValidatorsPortfoliosVerifyPortfolioPostRequest,
    ): Promise<runtime.ApiResponse<VerifyPortfolioReturns>> {
        if (
            requestParameters.verifyPortfolioReturns === null ||
            requestParameters.verifyPortfolioReturns === undefined
        ) {
            throw new runtime.RequiredError(
                'verifyPortfolioReturns',
                'Required parameter requestParameters.verifyPortfolioReturns was null or undefined when calling verifyPortfolioApiV1ValidatorsPortfoliosVerifyPortfolioPost.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/validators/portfolios/verify-portfolio/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyPortfolioReturnsToJSON(requestParameters.verifyPortfolioReturns),
        })

        return new runtime.JSONApiResponse(response, jsonValue => VerifyPortfolioReturnsFromJSON(jsonValue))
    }

    /**
     * This function compares the backend\'s calculations for yesterdays portfolio totals and compares it to Rakaias calcuations. A date can optionally be passed in (portfolio_calculations.date), but if no date is passed in we use yesterday rather than today as prices and exchange rates can drift during a day but will be set at the day end.  We expect the following values to be diffed: - total return - simple return - cost basis - investment_value - dividends per currency (NZD, USD, AUD)
     * Verify Portfolio
     */
    async verifyPortfolioApiV1ValidatorsPortfoliosVerifyPortfolioPost(
        requestParameters: VerifyPortfolioApiV1ValidatorsPortfoliosVerifyPortfolioPostRequest,
    ): Promise<VerifyPortfolioReturns> {
        const response = await this.verifyPortfolioApiV1ValidatorsPortfoliosVerifyPortfolioPostRaw(requestParameters)
        return await response.value()
    }
}
