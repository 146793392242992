import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import AppIconSVG from './assets/images/app-icon.inline.svg'
import AppStoreSVG from './assets/images/app-store.inline.svg'
import GooglePlaySVG from './assets/images/google-play.inline.svg'
import styles from './DownloadAppBanner.scss'

const trackAnchorLink = (label: 'ios' | 'android', url: string) => {
    rudderTrack('navigation', 'link_clicked', {
        component: 'login_page',
        feature: 'download_mobile_app',
        url,
        label,
    })
}

export interface DownloadAppBannerProps {
    appleAppStoreUrl: string
    googlePlayStoreUrl: string
}

export const DownloadAppBanner = ({appleAppStoreUrl, googlePlayStoreUrl}: DownloadAppBannerProps): JSX.Element => {
    const iosRef = React.useRef<HTMLAnchorElement>(null)
    const androidRef = React.useRef<HTMLAnchorElement>(null)

    const trackAnchorLinkForIos = () => {
        trackAnchorLink('ios', appleAppStoreUrl)
    }
    const trackAnchorLinkForAndroid = () => {
        trackAnchorLink('android', googlePlayStoreUrl)
    }

    React.useEffect(() => {
        iosRef.current?.addEventListener('click', trackAnchorLinkForIos)

        return () => {
            iosRef.current?.removeEventListener('click', trackAnchorLinkForIos)
        }
    }, [iosRef])

    React.useEffect(() => {
        androidRef.current?.addEventListener('click', trackAnchorLinkForAndroid)

        return () => {
            androidRef.current?.removeEventListener('click', trackAnchorLinkForAndroid)
        }
    }, [androidRef])

    return (
        <div className={styles.downloadAppBanner} aria-label="Download the Sharesies app">
            <div className={styles.prompt}>
                <div className={styles.iconContainer}>
                    <div className={styles.icon}>
                        <AppIconSVG aria-hidden />
                    </div>
                </div>

                <div className={styles.titleContainer}>
                    <h3 className={styles.title}>Get the sharesies app</h3>
                </div>
            </div>

            <div className={styles.storeIcons}>
                {/* Apple app store */}
                <a
                    target="_blank"
                    rel="noreferrer noopener"
                    className={styles.storeIcon}
                    href={appleAppStoreUrl}
                    aria-label="Download the Sharesies app on the Apple App Store"
                    ref={iosRef}
                >
                    <AppStoreSVG aria-hidden />
                </a>

                {/* Google play store */}
                <a
                    target="_blank"
                    rel="noreferrer noopener"
                    className={styles.storeIcon}
                    href={googlePlayStoreUrl}
                    aria-label="Download the Sharesies app on the Google Play Store"
                    ref={androidRef}
                >
                    <GooglePlaySVG aria-hidden />
                </a>
            </div>
        </div>
    )
}
