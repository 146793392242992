/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum DataProvider {
    Refinitiv = 'Refinitiv',
    DriveWealth = 'DriveWealth',
    IEX = 'IEX',
    SharesiesRetail = 'SharesiesRetail',
    NZX = 'NZX',
    SharesiesFms = 'SharesiesFms',
}

export function DataProviderFromJSON(json: any): DataProvider {
    return DataProviderFromJSONTyped(json, false)
}

export function DataProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataProvider {
    return json as DataProvider
}

export function DataProviderToJSON(value?: DataProvider | null): any {
    return value as any
}
