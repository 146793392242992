import {Button} from '@design-system/button'
import {SelectCards} from '@design-system/select-cards/SelectCards'
import cn from 'classnames'
import Decimal from 'decimal.js'
import {Form, Formik} from 'formik'
import {useAtom} from 'jotai'
import {DateTime} from 'luxon'
import React from 'react'
import {Navigate, useNavigate} from 'react-router'
import {useRetailPost} from '~/api/query/retail'
import {Request} from '~/api/retail/types'
import {page} from '~/global/scss/helpers'
import {dateFormatShortDayFullMonth} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ExchangeHours} from '~/global/widgets/exchange-hours/ExchangeHours'
import {validate} from '~/global/widgets/form-controls'
import {StrongInlineDate} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {MooseOrderPathParams} from '~/sections/moose/routes'
import {TimeOnMarketOptions, buyOrderAtom, sellOrderAtom} from '~/sections/moose/sections/order/state'
import {calculateTimeoutDate} from '~/sections/moose/sections/order/utils/calculate-timeout-date/calculateTimeoutDate'
import {isUnsupportedInstrument} from '~/sections/moose/sections/order/utils/is-unsupported-instrument/isUnsupportedInstrument'
import {OrderError} from '~/sections/moose/sections/order/widgets/order-error/OrderError'
import styles from './TimeOnMarketPage.scss'

type CostOrderResult = CostOrderFailure | CostOrderSuccess

interface CostOrderFailure {
    success: false
    code: string
}

interface CostOrderSuccess {
    success: true
    totalAmount: Decimal
    totalAmountBeforeFees: Decimal
    expectedFee: Decimal
}

interface MooseTimeOnMarketPathParams extends MooseOrderPathParams {
    action: 'buy' | 'sell'
}

interface TimeOnMarketPageFormValues {
    lengthOfTimeOnMarket: TimeOnMarketOptions
    customLengthOfTimeOnMarket: DateTime
    errorCode: string | null
}

const showOrderTimeoutDate = (timeoutDate: DateTime) => {
    return (
        <>
            <div>
                Your order will be on market until close of market on{' '}
                {timeoutDate.toFormat(dateFormatShortDayFullMonth)} (New Zealand time).
            </div>
        </>
    )
}

export const TimeOnMarketPage: React.FunctionComponent<MooseTimeOnMarketPathParams> = ({
    portfolioId,
    urlSlug,
    action,
}: MooseTimeOnMarketPathParams) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const [stagedBuyOrder, updateStagedBuyOrder] = useAtom(buyOrderAtom)
    const [stagedSellOrder, updateStagedSellOrder] = useAtom(sellOrderAtom)

    const costOrderQuery = useRetailPost({
        path:
            action === 'buy'
                ? 'fonterra/portfolios/:portfolio_id/orders/cost-buy'
                : 'fonterra/portfolios/:portfolio_id/orders/cost-sell',
        pathParams: {portfolio_id: portfolioId},
    })

    const stagedOrder = action === 'buy' ? stagedBuyOrder : stagedSellOrder

    if (isUnsupportedInstrument(urlSlug)) {
        return <Navigate to={profileUrl('fonterra/portfolios/:portfolioId', {portfolioId})} replace />
    }

    if (
        !stagedOrder ||
        stagedOrder.instrument.urlSlug !== urlSlug ||
        !stagedOrder.pricePerItem ||
        !stagedOrder.numberOfItems
    ) {
        const url =
            action === 'buy'
                ? 'fonterra/portfolios/:portfolioId/invest/:urlSlug/buy/limit-order'
                : 'fonterra/portfolios/:portfolioId/invest/:urlSlug/sell/limit-order'
        return <Navigate to={profileUrl(url, {portfolioId, urlSlug})} replace />
    }

    const pricePerItem = stagedOrder.pricePerItem
    const numberOfItems = stagedOrder.numberOfItems

    const costOrder = async (
        action: MooseTimeOnMarketPathParams['action'],
        fundId: string,
        expireEndOfDay: DateTime,
        limitVolume: number,
        limitPrice: Decimal,
    ): Promise<CostOrderResult> => {
        try {
            const payload:
                | Request.FonterraPortfolioOrderCostBuyRequest
                | Request.FonterraPortfolioOrderCostSellRequest =
                action === 'buy'
                    ? {
                          fund_id: fundId,
                          expire_end_of_day: expireEndOfDay.toISODate(),
                          use_delayed_settlement: false,
                          order: {
                              type: 'fonterra_portfolio_share_limit',
                              limit_volume: limitVolume,
                              limit_price: limitPrice.toString(),
                          },
                      }
                    : {
                          fund_id: fundId,
                          expire_end_of_day: expireEndOfDay.toISODate(),
                          order: {
                              type: 'fonterra_portfolio_share_limit',
                              limit_volume: limitVolume,
                              limit_price: limitPrice.toString(),
                          },
                      }
            const response = await costOrderQuery.mutateAsync(payload)
            if (response.type !== `fonterra_portfolio_order_cost_${action}`) {
                return {success: false, code: 'error_unexpected_response_payload'}
            }

            return {
                success: true,
                totalAmount: new Decimal(response.total_amount),
                totalAmountBeforeFees: new Decimal(response.total_amount_before_fees),
                expectedFee: new Decimal(response.expected_fee),
            }
        } catch (error) {
            return {success: false, code: (error as {code: string}).code}
        }
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--instrument-select"
                leftButton="back"
                rightSlot={
                    <>
                        <div className={styles.exchangeHours}>
                            <ExchangeHours instrument={stagedOrder.instrument} />
                        </div>
                    </>
                }
            />
            <Page>
                <Formik
                    initialValues={
                        {
                            lengthOfTimeOnMarket: stagedOrder.lengthOfTimeOnMarket ?? null,
                            customLengthOfTimeOnMarket: stagedOrder.customUntilDate ?? null,
                            errorCode: null,
                        } as TimeOnMarketPageFormValues
                    }
                    validate={validate.generate({
                        lengthOfTimeOnMarket: [validate.required()],
                    })}
                    onSubmit={async (values, actions) => {
                        if (
                            !values.lengthOfTimeOnMarket ||
                            (values.lengthOfTimeOnMarket === 'custom' && !values.customLengthOfTimeOnMarket)
                        ) {
                            return
                        }

                        actions.setFieldValue('errorCode', null)

                        const lengthOfTimeOnMarket = values.lengthOfTimeOnMarket
                        const customUntilDate =
                            values.lengthOfTimeOnMarket === 'custom' ? values.customLengthOfTimeOnMarket : undefined
                        const timeoutDateToUse =
                            values.lengthOfTimeOnMarket === 'custom'
                                ? values.customLengthOfTimeOnMarket
                                : calculateTimeoutDate(values.lengthOfTimeOnMarket)

                        const result = await costOrder(
                            action,
                            stagedOrder.instrument.id,
                            timeoutDateToUse,
                            numberOfItems,
                            pricePerItem,
                        )

                        if (result.success) {
                            if (action === 'buy') {
                                updateStagedBuyOrder({
                                    ...stagedOrder,
                                    customUntilDate,
                                    lengthOfTimeOnMarket,
                                    timeoutDateToUse,
                                    totalAmount: result.totalAmount,
                                    totalAmountBeforeFees: result.totalAmountBeforeFees,
                                    expectedFee: result.expectedFee,
                                    useDelayedSettlement: false,
                                })
                            } else {
                                updateStagedSellOrder({
                                    ...stagedOrder,
                                    customUntilDate,
                                    lengthOfTimeOnMarket,
                                    timeoutDateToUse,
                                    totalAmount: result.totalAmount,
                                    totalAmountBeforeFees: result.totalAmountBeforeFees,
                                    expectedFee: result.expectedFee,
                                })
                            }

                            const url =
                                action === 'buy'
                                    ? 'fonterra/portfolios/:portfolioId/invest/:urlSlug/buy/confirm'
                                    : 'fonterra/portfolios/:portfolioId/invest/:urlSlug/sell/confirm'
                            navigate(profileUrl(url, {portfolioId, urlSlug}))
                        } else {
                            actions.setFieldValue('errorCode', result.code)
                        }
                    }}
                >
                    {({isValid, values, isSubmitting, setFieldValue}) => (
                        <Form>
                            <SelectCards
                                additionalClassName={styles.header}
                                dataTestId="select-cards--length-of-time-on-market"
                                title="How long do you want this order to be on market for?"
                                short={true}
                                options={[
                                    {
                                        title: 'One day',
                                        name: 'lengthOfTimeOnMarket',
                                        value: '1d',
                                        isActive: values.lengthOfTimeOnMarket === '1d',
                                    },
                                    {
                                        title: 'Three days',
                                        name: 'lengthOfTimeOnMarket',
                                        value: '3d',
                                        isActive: values.lengthOfTimeOnMarket === '3d',
                                    },
                                    {
                                        title: 'Five days',
                                        name: 'lengthOfTimeOnMarket',
                                        value: '5d',
                                        isActive: values.lengthOfTimeOnMarket === '5d',
                                    },
                                    {
                                        title: 'Custom end date',
                                        name: 'lengthOfTimeOnMarket',
                                        value: 'custom',
                                        isActive: values.lengthOfTimeOnMarket === 'custom',
                                    },
                                ]}
                                onChange={e => {
                                    setFieldValue('lengthOfTimeOnMarket', e.target.value)
                                }}
                            />
                            {values.lengthOfTimeOnMarket === 'custom' && (
                                <StrongInlineDate
                                    name="customLengthOfTimeOnMarket"
                                    dataTestId="order-start-date"
                                    label="Until"
                                    onChange={e => {
                                        setFieldValue(
                                            'customLengthOfTimeOnMarket',
                                            DateTime.fromISO(e.target.value).setZone('Pacific/Auckland'),
                                        )
                                    }}
                                />
                            )}
                            {values.lengthOfTimeOnMarket &&
                                values.lengthOfTimeOnMarket !== 'custom' &&
                                showOrderTimeoutDate(calculateTimeoutDate(values.lengthOfTimeOnMarket))}
                            {values.customLengthOfTimeOnMarket &&
                                values.lengthOfTimeOnMarket === 'custom' &&
                                showOrderTimeoutDate(values.customLengthOfTimeOnMarket)}
                            <ActionBar className={cn(page.flexRow, styles.formFooter)}>
                                <Button
                                    dataTestId="button--next"
                                    label="Next"
                                    disabled={
                                        !isValid ||
                                        !values.lengthOfTimeOnMarket ||
                                        (values.lengthOfTimeOnMarket === 'custom' && !values.customLengthOfTimeOnMarket)
                                    }
                                    isSubmit
                                    processing={isSubmitting}
                                />
                            </ActionBar>
                            {values.errorCode && (
                                <OrderError errorCode={values.errorCode} instrumentName={stagedOrder.instrument.name} />
                            )}
                        </Form>
                    )}
                </Formik>
            </Page>
        </>
    )
}
