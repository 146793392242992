import React from 'react'
import {Request} from '~/api/retail/types'
import learnBackgroundThumbnail from '~/global/assets/images/learn-background-thumbnail.svg'
import {useHasOrderedOrHeldShares} from '~/global/state-hooks/rakaia/useHasOrderedOrHeldShares'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Card from '~/global/widgets/card/Card'
import {Link, useNavigate} from '~/migrate-react-router'
import styles from '~/sections/explore/Explore.scss'
import {essViewsForPortfolio} from '~/store/employeeShareScheme/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'

const LearnCard: React.FunctionComponent = (): JSX.Element | null => {
    const dispatch = useAppDispatch()
    const profile = useProfile()

    type PortfolioCardTypes = 'learn' | 'auto_invest'
    const essViews = useAppSelector(s => essViewsForPortfolio(s))

    const investPortfolio = profile.portfolios.find(p => p.product === 'INVEST')!
    const hasInvested = useHasOrderedOrHeldShares(investPortfolio.id)
    const showLearnCard = useAppSelector(s => s.identity.user!.portfolio_intro_cards.learn_shown)
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const [recentlyClosedPortfolioCards, setRecentlyClosedPortfolioCards] = React.useState<PortfolioCardTypes[]>([])
    const dismissPortfolioIntroCard = (card: Request.CustomerDismissPortfolioIntroCard['card']) =>
        dispatch(identityActions.DismissPortfolioIntroCard(card))

    const hideLearnCard =
        essViews.hasEmployment ||
        (hasInvested && !showLearnCard) ||
        recentlyClosedPortfolioCards.find(x => x === 'learn')

    if (hideLearnCard) {
        return null
    }

    return (
        // Hauled in from src/page/Portfolio/FeaturedCards.tsx
        <>
            <Card
                image={learnBackgroundThumbnail}
                className={styles.learnTheBasics}
                onDismiss={
                    hasInvested
                        ? () => {
                              setRecentlyClosedPortfolioCards(recentlyClosedPortfolioCards.concat('learn'))
                              dismissPortfolioIntroCard('learn_shown')
                          }
                        : undefined
                }
                onClick={() => navigate(profileUrl('explore/learn'))}
            >
                <h3>Learn the basics</h3>

                <p>Get up to speed with investing on Sharesies.</p>
                <p>
                    <Link to={profileUrl('explore/learn')}>Teach me</Link>
                </p>
            </Card>
        </>
    )
}

export default LearnCard
