import React from 'react'
import {useEssViewsForInstrument} from '~/global/state-hooks/mixed-source/useEssViewsForInstrument'
import {useLocation} from '~/migrate-react-router'
import {NavigationRouterState} from '~/sections/invest/sections/view-instrument/widgets/navigation/Navigation'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/instrument/actions'
import {InstrumentDetailsTab} from '~/store/instrument/types'

export function useInstrumentTab(instrumentId: string) {
    const essViews = useEssViewsForInstrument(instrumentId)
    const holding = useAppSelector(s => s.identity.holdings.find(f => f.fund_id === instrumentId))
    const browserHistoryState: NavigationRouterState = useLocation().state as NavigationRouterState
    const activeInstrumentTab = useAppSelector(s => s.instrument.activeInstrumentTab)
    const dispatch = useAppDispatch()

    const browserTab = browserHistoryState?.tab // This is set if the investor is coming to this page directly from another instrument page. (E.g via the arrow buttons on an investors portfolio )
    const savedTab = activeInstrumentTab.instrumentId === instrumentId && activeInstrumentTab.tab // This is set if the investor's last viewed instrument is this instrument.
    const canSeeYourInvestment = essViews.hasEmployment || !!holding

    // If you are a member of an Employee Share Scheme (of any type) we default to Your investment to show the scheme details first
    const defaultTab = canSeeYourInvestment && essViews.hasScheme ? 'Your investment' : 'Overview'

    // Hierarchy: Keep the same browser tab if it exists, then saved tab, then default.
    // Unless an investor can't view the Your Investment page for a given instrument, then their default page
    // will be the overview and there's no need to check anything that might override that
    let currentTab: InstrumentDetailsTab = defaultTab

    if (browserTab && canSeeYourInvestment) {
        currentTab = browserTab
    } else if (savedTab && canSeeYourInvestment) {
        currentTab = savedTab
    }

    React.useEffect(() => {
        dispatch(actions.SetActiveInstrumentTab(currentTab, instrumentId))
    }, [])
    return currentTab
}
