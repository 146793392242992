import {PortfolioHistory} from '~/api/rakaia'
import {Response} from '~/api/retail/types'

/**
 * Combines cash holdings (uninvested funds) and rakaia holdings (invested funds).
 * This is to ensure no dips display on the chart when a customer sells down units.
 * Allows us to display the most accurate holding data to the customer.
 *
 * @returns {number} rakaia history + cash holdings history = totalHistory
 */
export function combinePortfolioAndCashHoldingsHistory(
    rakaiaHistory: PortfolioHistory[],
    cashHoldingsHistory: Response.KSCashHoldingsHistory,
) {
    const totalHistory: PortfolioHistory[] = []

    // When a customer is made active, they won't have history yet, but will have account holdings
    // So we build the returns history from their cash holdings
    if (rakaiaHistory.length === 0 && cashHoldingsHistory.cash_holdings_history.length > 0) {
        return getHistoryFromCashHoldingsHistory(cashHoldingsHistory, totalHistory)
    }

    // We want to use the portfolio history as the main source of data
    rakaiaHistory.forEach(rakaiaHistoryItem => {
        const cashHoldingHistoryForDate = cashHoldingsHistory.cash_holdings_history.find(
            c => c.date === rakaiaHistoryItem.date,
        )
        const nzdHoldingsValue = Number(cashHoldingHistoryForDate?.nzd_holdings ?? 0)
        const totalHistoryItem: PortfolioHistory = {
            date: rakaiaHistoryItem.date,
            portfolio_value: rakaiaHistoryItem.portfolio_value + nzdHoldingsValue,
            cost_basis: rakaiaHistoryItem.cost_basis + nzdHoldingsValue,
            total_return: rakaiaHistoryItem.total_return,
            unrealised_total_return: rakaiaHistoryItem.unrealised_total_return,
        }
        totalHistory.push(totalHistoryItem)
    })

    return totalHistory
}

function getHistoryFromCashHoldingsHistory(
    cashHoldingsHistory: Response.KSCashHoldingsHistory,
    totalHistory: PortfolioHistory[],
) {
    cashHoldingsHistory.cash_holdings_history.forEach(cashHoldingHistoryForDate => {
        const nzdHoldingsValue = Number(cashHoldingHistoryForDate.nzd_holdings)
        const totalHistoryItem: PortfolioHistory = {
            date: cashHoldingHistoryForDate.date,
            portfolio_value: nzdHoldingsValue,
            cost_basis: nzdHoldingsValue,
            total_return: 0,
            unrealised_total_return: 0,
        }
        totalHistory.push(totalHistoryItem)
    })
    return totalHistory
}
