import React from 'react'
import {useBankLinkingAlert, Context as LocationContext} from '~/global/state-hooks/retail/useBankLinkingAlert'
import AlertCard from '~/global/widgets/alert-card/AlertCard'

export const BankLinkingAlert: React.FC<{
    hideTitle?: boolean
    locationContext: LocationContext
    wrapperClassName?: string
}> = ({hideTitle, locationContext, wrapperClassName}) => {
    const alert = useBankLinkingAlert({context: locationContext})

    return alert && alert.content ? (
        <div className={wrapperClassName}>
            <AlertCard type={alert.type} title={!hideTitle ? alert.title : undefined}>
                {alert.content}
            </AlertCard>
        </div>
    ) : null
}
