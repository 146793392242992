import cn from 'classnames'
import React from 'react'
import {useRetailPost} from '~/api/query/retail'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {UserSuitableError} from '~/global/utils/error-handling/errorHandling'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import use3DSecure from '~/global/utils/use3-d-secure/use3DSecure'
import CardForm from '~/global/widgets/credit-card-handling/card-form/CardForm'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import actions from '~/store/accounting/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {actingAsID} from '~/store/identity/selectors'

const AddCard: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const jurisdiction = useAppSelector(({identity}) => identity.user!.jurisdiction)
    const acting_as_id = useAppSelector(actingAsID)
    const saveCard = useRetailPost({path: 'identity/cards/add-payment-method'})

    const {processCardRequiresAction} = use3DSecure(jurisdiction)

    const handleSubmit = async (payment_method_id: string, setup_intent_id?: string) => {
        try {
            const response = await saveCard.mutateAsync({payment_method_id, setup_intent_id, acting_as_id})

            if (response.type === 'identity_cards') {
                dispatch(actions.SetCards(response.cards))
                Toast('Card added')
                navigate(-1)
            } else if (response.type === 'save_payment_method_action_required') {
                // This card requires 3D Secure verification before it can be added. Prompt the user with a stripe popup
                await processCardRequiresAction(response.client_secret)
                await handleSubmit(payment_method_id, response.setup_intent_id)
            } else {
                assertNever(response)
            }
        } catch (err) {
            // It's safe to throw errors here, we’re still in the CardForm.tsx's try/catch context
            if (err instanceof UserSuitableError) {
                throw err
            } else if (saveCard.error?.type === 'error') {
                // It's an error from the mutation, display to the customer
                throw new UserSuitableError(saveCard.error.message)
            } else if (saveCard.error?.type === 'internal_server_error') {
                throw new UserSuitableError(unknownErrorMessage)
            } else {
                throw err
            }
        }
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--add-card" leftButton="back" title="Accounts and cards" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow12)}>Add a card</h2>
                <CardForm
                    submitLabel="Save card"
                    onSubmit={paymentMethodId => handleSubmit(paymentMethodId)}
                    jurisdiction={jurisdiction}
                />
            </Page>
        </>
    )
}

export default AddCard
