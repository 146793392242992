import React from 'react'

const KiwiSaverAnnualFundChargeDetail: React.FC = () => {
    return (
        <div>
            <h1>Annual fund charge</h1>
            <p>The annual fund charge is charged by the fund’s provider to cover the cost of managing the fund.</p>
            <p>
                Each base fund has a single annual fund charge—there are no admin or performance fees, or fees for
                switching between base funds.
            </p>
            <p>
                The amount charged depends on the fund you choose, and is included in the unit price—that means you
                won’t see it directly deducted from your KiwiSaver balance.
            </p>
            <p>
                We do not charge any other fees relating to base funds, however the fund providers pay us to have their
                funds available through Sharesies KiwiSaver Scheme.
            </p>
        </div>
    )
}

export default KiwiSaverAnnualFundChargeDetail
