import {semanticColours} from '@design-system/colour-tokens'
import {DockableModal} from '@design-system/dockable-modal'
import {ChevronDown} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {urlFor} from '~/global/routeGenerator'
import {accessibility} from '~/global/scss/helpers'
import {useProfile, useProfiles} from '~/global/state-hooks/retail/useProfile'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import styles from './ProfileSwitcher.scss'

const ProfileAvatar = ({initial, colour}: {initial: string; colour: string}) => {
    return (
        <span
            className={styles.profileAvatar}
            style={{
                backgroundColor: colour,
            }}
        >
            {initial}
        </span>
    )
}

interface ProfileSwitcherRowProps {
    label: string
    avatarColour: string
    avatarInitials: string
    dataTestId?: string
    selected?: boolean
    onClick?: () => void
}

const ProfileSwitcherRow = ({
    label,
    avatarColour,
    avatarInitials,
    dataTestId,
    selected,
    onClick,
}: ProfileSwitcherRowProps) => {
    const svgColour = selected ? semanticColours.InputSelected : semanticColours.InputBorderDefault

    return (
        <li className={cn(styles.profileSwitcherRow, {[styles.selected]: selected})}>
            <button
                type="button"
                name="account"
                data-testid={dataTestId}
                className={accessibility.button}
                aria-label={selected ? `Currently selected profile: ${label}` : `Switch profile to: ${label}’`}
                onClick={e => {
                    e.preventDefault()
                    if (onClick) {
                        onClick()
                    }
                }}
            >
                <ProfileAvatar initial={avatarInitials} colour={avatarColour} />
                <span className={styles.label}>{label}</span>
                <span className={styles.icon} role="presentation">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" color={svgColour} fill={svgColour}>
                        <circle cx="12" cy="12" r="9" stroke="currentColor" fill="none" strokeWidth="2" />
                        {selected && <circle cx="12" cy="12" r="4" fill="currentColor" />}
                    </svg>
                </span>
            </button>
        </li>
    )
}

export const ProfileSwitcher = ({
    navigationIsVisible,
    switcherVisible,
    setSwitcherVisible,
    closeFlyout,
}: {
    navigationIsVisible: boolean
    switcherVisible: boolean
    setSwitcherVisible: React.Dispatch<React.SetStateAction<boolean>>
    closeFlyout: () => void
}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const profiles = useProfiles()
    const selectedProfile = useProfile()
    const hasMultipleProfiles = profiles.length > 1

    if (!hasMultipleProfiles) {
        return null
    }

    return (
        <>
            <div className={styles.profileSwitcher}>
                <button
                    data-testid="button--profile-switcher"
                    type="button"
                    className={cn(accessibility.button, {[styles.switcherOpen]: switcherVisible})}
                    aria-label={
                        hasMultipleProfiles
                            ? `Switch your profile from ${selectedProfile.name}`
                            : `${selectedProfile.name}'s profile`
                    }
                    onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        if (hasMultipleProfiles) {
                            setSwitcherVisible(true)
                        } else {
                            navigate(profileUrl(''))
                        }
                    }}
                    tabIndex={navigationIsVisible ? 0 : -1}
                >
                    <ProfileAvatar initial={selectedProfile.avatar_initials} colour={selectedProfile.avatar_colour} />
                    <span className={styles.label}>{selectedProfile.name}</span>
                    <span className={cn(styles.icon, {[styles.singleProfile]: !hasMultipleProfiles})}>
                        <ChevronDown size={16} />
                    </span>
                </button>
            </div>
            <DockableModal
                dataTestId="dockable-modal--profile-switcher"
                isOpen={switcherVisible}
                setIsOpen={setSwitcherVisible}
                title="Switch profile"
                content={
                    <ul className={styles.profileList}>
                        {profiles.map((profile, index) => (
                            <ProfileSwitcherRow
                                label={profile.name}
                                avatarColour={profile.avatar_colour}
                                avatarInitials={profile.avatar_initials}
                                onClick={() => {
                                    navigate(urlFor('profile/:profileSlug/', {profileSlug: profile.slug}))
                                    setSwitcherVisible(false)
                                    closeFlyout()
                                }}
                                key={profile.id}
                                dataTestId={`profile-switcher--profile-${index + 1}`}
                                selected={selectedProfile.id === profile.id}
                            />
                        ))}
                    </ul>
                }
                customZIndex={1210} // zindex(account-switcher)
            />
            <div className={styles.flyoutPageOverlay} onClick={() => setSwitcherVisible(false)} />
        </>
    )
}
