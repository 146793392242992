import {useColourMode} from '@design-system/use-colour-mode'
import cn from 'classnames'
import React from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import Image from '~/global/widgets/image/Image'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useTelescope} from '~/sections/invest/sections/ai-search/hooks/useTelescope'
import {AiIntroSlides} from '~/sections/invest/sections/ai-search/pages/intro/AiSearchIntro'
import AiSearchInput from '~/sections/invest/sections/ai-search/widgets/ai-search-input/AiSearchInput'
import AiSuggestions from '~/sections/invest/sections/ai-search/widgets/ai-suggestions/AiSuggestions'
import {useAppSelector} from '~/store/hooks'
import styles from './Landing.scss'

export const AiSearchLanding: React.FunctionComponent = () => {
    const profileUrl = useProfileUrl()
    const navigate = useNavigate()
    const hasTelescopeFlag = useAppSelector(s => s.identity.flags.ai_search)
    const {sendPrompt, lastPrompt} = useTelescope()
    const [prompt, setPrompt] = React.useState('')
    const [showIntro, setShowIntro] = React.useState(false)
    const colourMode = useColourMode()

    if (!hasTelescopeFlag) {
        return <Navigate to={profileUrl('invest')} replace />
    }

    if (lastPrompt) {
        return <Navigate to={profileUrl('invest/ai-search/search')} replace />
    }

    const go = (prompt: string) => {
        if (!prompt) {
            return
        }
        sendPrompt(prompt)
        navigate(profileUrl('invest/ai-search/search'))
    }

    if (showIntro) {
        return <AiIntroSlides onBack={() => setShowIntro(false)} onNext={() => setShowIntro(false)} />
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--ai-search-landing" leftButton="back" isInlineTitle={true} />
            <Page className={styles.page} overrideDefaultTopPadding="none">
                <div className={styles.pinkLandingWrapper}>
                    <div className={styles.centerExpandWrapper}>
                        <Image
                            aria-hidden={true}
                            src={require('../../assets/images/large-sparkes.svg')}
                            darkSrc={require('../../assets/images/large-sparkes-dark.svg')}
                            className={styles.sparkle}
                        />
                        <h1 className={typographyOverrides['as-display-1']}>AI SEARCH</h1>
                        <p className={styles.subheading}>
                            Get industry insights and discover new investments. Just search in your own words for
                            themes, topics, or whatever you value most!
                        </p>
                        <AiSearchInput
                            additionalClassName={cn(spacing.spaceAbove24, spacing.spaceBelow32)}
                            name=""
                            isTouched={false}
                            setQueryValue={newPrompt => setPrompt(newPrompt)}
                            queryValue={prompt}
                            executeQuery={() => go(prompt)}
                            withBorder={colourMode === 'dark'}
                        />
                        <AiSuggestions onChipClick={suggestion => go(suggestion)} animateIn />
                    </div>
                </div>
                <p className={cn(spacing.spaceAbove12, styles.disclaimer)}>
                    Learn more about <ButtonAsLink onClick={() => setShowIntro(true)}>AI Search</ButtonAsLink>
                </p>
            </Page>
        </>
    )
}
