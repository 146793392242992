import {Button} from '@design-system/button'
import {SelectCards} from '@design-system/select-cards'
import cn from 'classnames'
import React from 'react'
import {IDENTITY_VERIFICATION_MODE_KEY} from '~/global/constants/global'
import {spacing} from '~/global/scss/helpers'
import {useAppSelector} from '~/store/hooks'
import {Mode} from '../identity-check/util'
import {getNextStep, ManualIdentityStep} from './util'
import styles from './OtherIdentityDetails.scss'

const IdentitySelector: React.FunctionComponent<IdentitySelectorProps> = ({mode, setMode, step, setStep}) => {
    const preferredName = useAppSelector(({identity}) => identity.user!.preferred_name)
    const isDependent = useAppSelector(({identity}) => identity.user!.is_dependent)
    const jurisdiction = useAppSelector(({identity}) => identity.user!.jurisdiction)

    const onSelectMode = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setMode(e.target.value as Mode)
        window.localStorage.setItem(IDENTITY_VERIFICATION_MODE_KEY, e.target.value)
    }

    const introText = isDependent
        ? `To invest for ${preferredName} you need to prove their identity.`
        : `To use Sharesies, you’ll need to prove you are who you say you are. Provide one of the following forms of ID, and we’ll match it to info held by the ${
              jurisdiction === 'au' ? ' Australian' : ' NZ'
          } Government. `
    return (
        <>
            <h1 className={spacing.spaceBelow16}>Which ID would you like to use to sign up?</h1>
            <p className={cn(styles.p, spacing.spaceBelow16)}>{introText}</p>

            <SelectCards
                dataTestId="select-cards--identity"
                title="Choose an ID type"
                hideTitle
                short
                onChange={onSelectMode}
                options={[
                    {
                        name: 'NZDriversLicense',
                        title: 'NZ drivers licence',
                        value: 'licence',
                        isActive: mode === 'licence',
                    },
                    {
                        name: 'NZPassport',
                        title: 'NZ passport',
                        value: 'passport',
                        isActive: mode === 'passport',
                    },
                    {
                        name: 'OverseasPassport',
                        title: 'Overseas passport',
                        value: 'overseasPassport',
                        isActive: mode === 'overseasPassport',
                    },
                    {
                        name: 'NZFirearmLicense',
                        title: 'NZ firearms license',
                        value: 'NZFirearmLicence',
                        isActive: mode === 'NZFirearmLicence',
                    },
                    {
                        name: 'otherID',
                        title: 'I don’t have any of these IDs',
                        value: 'other',
                        isActive: mode === 'other',
                    },
                ]}
            />
            <Button
                additionalClassName={styles.button}
                dataTestId="button--next"
                label="Next"
                pageButton
                disabled={!mode}
                onClick={() => setStep(getNextStep(step, mode))}
            ></Button>
        </>
    )
}

interface IdentitySelectorProps {
    setStep(step: ManualIdentityStep): void
    mode: Mode
    setMode(mode: Mode): void
    step: ManualIdentityStep
    className?: string
}

export default IdentitySelector
