import cn from 'classnames'
import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Link} from '~/migrate-react-router'
import styles from '~/sections/explore/Explore.scss'
import insureLarge from '~/sections/protect/sections/insure/assets/images/insure-large.png'

export const InsureTile: React.FunctionComponent = () => {
    const profileUrl = useProfileUrl()

    return (
        <Link
            to={profileUrl('protect/insure')}
            onClick={() => rudderTrack('explore', 'whats_next_clicked', {type: 'insure'})}
        >
            <div className={cn(styles.whatNextBox, styles.insure)}>
                <h3>Insure</h3>
                <p>Car insurance at mates rates</p>
                <img src={insureLarge} />
            </div>
        </Link>
    )
}
