import {Button} from '@design-system/button'
import {Modal} from '@design-system/modal'
import Decimal from 'decimal.js'
import React, {useState} from 'react'
import {Navigate} from 'react-router-dom'
import {DistillScope} from '~/api/query/distill'
import {Model} from '~/api/retail/types'
import confirmStyles from '~/global/scss/reused-styles/confirm.scss'
import {feeCoverage} from '~/global/utils/fee-coverage/feeCoverage'
import {howManyShares} from '~/global/utils/how-many-shares/howManyShares'
import {isInstrumentDerivative} from '~/global/utils/is-instrument-derivative/isInstrumentDerivative'
import {isOnNZX, isOnASX} from '~/global/utils/is-on-exchange/isOnExchange'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import useAssetManager from '~/global/utils/use-asset-manager/useAssetManager'
import {useInstrument} from '~/global/utils/use-instruments/useInstruments'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {ExchangeHours} from '~/global/widgets/exchange-hours/ExchangeHours'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {ADR} from '~/global/widgets/help-modals/ADR'
import BuyAndSellFeesListedInstruments from '~/global/widgets/help-modals/BuyAndSellFeesListedInstruments'
import BuyAndSellFeesManagedFund from '~/global/widgets/help-modals/BuyAndSellFeesManagedFund'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {DollarValue, SharePriceValue, FeeValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import {OrderConfirmation, CoverageText, OrderRow} from '~/global/widgets/order-confirmation/OrderConfirmation'
import {OrderPlanBuyMessage} from '~/global/widgets/order-plan-message/OrderPlanMessage'
import ExchangeOrderProcessMessage, {
    ExchangeAutoExerciseOrderProcessMessage,
} from '~/global/widgets/order-process-messages/ExchangeOrderProcessMessage'
import ManagedFundOrderProcessMessage from '~/global/widgets/order-process-messages/ManagedFundOrderProcessMessage'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {Toast} from '~/global/widgets/toast/Toast'
import UpcomingDividendInformation from '~/global/widgets/upcoming-dividend-information/UpcomingDividendInformation'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {useNavigate} from '~/migrate-react-router'
import {
    SHARE_LIMIT,
    DOLLAR_MARKET,
    DOLLAR_LIMIT,
    DOLLAR_TRIGGER,
} from '~/sections/invest/sections/order-flow/constants/orderTypes'
import AutoExerciseOrderConfirmation from '~/sections/invest/sections/order-flow/sections/buy/widgets/auto-exercise/AutoExerciseOrderConfirmation'
import {
    AutoinvestNudgeModal,
    useImmediateState,
    useShouldAutoinvestNudge,
} from '~/sections/invest/sections/order-flow/sections/buy/widgets/auto-invest-nudge/AutoinvestNudge'
import BuyErrors from '~/sections/invest/sections/order-flow/sections/buy/widgets/buy-errors/BuyErrors'
import OrderReinvestDividendsBuyMessage from '~/sections/invest/sections/order-flow/sections/buy/widgets/order-reinvest-dividends-message/OrderReinvestDividendsBuyMessage'
import {getLabelByBuyOrderType} from '~/sections/invest/sections/order-flow/utils/get-label-by-buy-order-type/getLabelByBuyOrderType'
import ConfirmExchange from '~/sections/invest/sections/order-flow/widgets/fx-confirm-exchange/ConfirmExchange'
import {connect} from '~/store/connect'
import {Instrument} from '~/store/instrument/types'
import actions from '~/store/order/actions'
import {StagedBuyOrder} from '~/store/order/types'
import {UnwrapThunkAction} from '~/store/types'

interface HaltedNZXMessageProps {
    onClick(): void
}

export const HaltedNZXMessage: React.FunctionComponent<HaltedNZXMessageProps> = ({onClick}) => (
    <Modal
        isOpen
        isAlert
        title="NZX trading halt"
        setIsOpen={onClick}
        dataTestId="modal--nzx-halt"
        primaryButton={{label: 'Continue', onClick}}
    >
        <p>
            The NZX is currently in halt. A trading halt is when an exchange temporarily stops trading due to technical
            issues or regulatory concerns. If you place an order it will be queued until the market opens again. For
            more information check out our{' '}
            <HelpCentreLink auArticle="4983245-order-processing-times" nzArticle="3098147-order-processing-times" />.
        </p>
    </Modal>
)

const OfferWarning = ({instrument, parentInstrument}: {instrument: Instrument; parentInstrument: Instrument}) => {
    return (
        <>
            <AlertCard className={confirmStyles.rightsWarning} type="warning">
                <>
                    Each {shareLabel({instrument})} can be used to buy {instrument.sharesPerRight}{' '}
                    {parentInstrument.symbol}{' '}
                    {shareLabel({instrument: parentInstrument, isPlural: instrument.sharesPerRight !== 1})} on the{' '}
                    {parentInstrument.exchange}. To turn {shareLabel({instrument, isPlural: true})} into{' '}
                    {shareLabel({instrument: parentInstrument, isPlural: true})}, you’ll need to pay the offer price{' '}
                    {instrument.offerPrice && (
                        <>
                            of <DollarValue value={instrument.offerPrice} currency={instrument.currency} />
                        </>
                    )}{' '}
                    for each {shareLabel({instrument: parentInstrument})}.
                </>
            </AlertCard>
        </>
    )
}

const BuyConfirm: React.FunctionComponent<BuyConfirmProps> = ({
    stagedBuyOrder,
    instrument,
    createBuyOrder,
    clearStagedBuyOrder,
    isFirstOrder,
    isDependent,
    preferredName,
    jurisdiction,
    updateStagedBuyOrder,
}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const notificationContext = React.useContext(NotificationContext)
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [showingHaltedNZXMessage, setShowingHaltedNZXMessage] = React.useState(
        instrument ? isOnNZX(instrument) && instrument.tradingStatus === 'halt' : false,
    )
    const isManagedFund = instrument ? tradingType(instrument) === 'managed' : false
    const isDerivative = instrument ? isInstrumentDerivative(instrument) : false
    const parentInstrument = useInstrument(instrument?.parentInstrumentId)
    const assetManager = useAssetManager(instrument, {scope: DistillScope.INVEST})

    // A/B test prompting after third purchase
    const shouldNudgeAutoinvest = useShouldAutoinvestNudge()
    const [showAutoinvestNudgeModal, setShowAutoinvestNudgeModal] = useState(false)

    // Our AutoinvestNudge modal shows just before we navigate away. We
    // deal with this by putting the navigate call in the setIsClosed handler.
    //
    // HOWEVER when an action button is clicked Modal calls setIsClosed after
    // that button's onClick handler.  How, then, are we to have one of these
    // action buttons perform a navigation?  (The destination will always be
    // usurped by the navigate call in the setIsClosed handler)
    //
    // The answer is this temporary state.  This isn't intended to persist across renders.
    // This allows us to write a button click handler that sets a navigation target then
    // have this used in the navigate call that setIsOpen will make.
    const {set: setExitNavigation, get: getExitNavigation} = useImmediateState()

    const handleExit = () => {
        // If autoinvest nudge set a new navigation target then follow that instead
        // (whilst keeping the above navigate() calls to make sure back behaviour works)
        const exitNavigation = getExitNavigation()
        if (exitNavigation) {
            navigate(exitNavigation)
        } else {
            const pushedHistory = stagedBuyOrder?.pushedHistory

            // Go back to the view instrument page
            pushedHistory ? navigate(-3) : navigate(-2)
        }
    }

    React.useEffect(() => {
        if (stagedBuyOrder && stagedBuyOrder.state === 'placed') {
            if (stagedBuyOrder.dividendReinvest === 'everytime') {
                Toast('Your dividend auto-invest is all set and a buy order has been placed for this one 🎉 ')
            } else if (isFirstOrder) {
                notificationContext.showFirstTimeInvestorNotification()
            } else {
                notificationContext.showRegularInvestorNotification()
            }

            const onTimeout = () => {
                if (shouldNudgeAutoinvest) {
                    setShowAutoinvestNudgeModal(true)
                } else {
                    handleExit()
                }
            }

            setTimeout(onTimeout, 1000)
        }

        if (stagedBuyOrder && stagedBuyOrder.error) {
            setIsSubmitting(false)
        }

        // on unmount, clean up the staged order
        return () => {
            if (stagedBuyOrder && stagedBuyOrder.state === 'placed') {
                clearStagedBuyOrder()
            }
        }
    }, [stagedBuyOrder])

    const handleSubmit = async () => {
        setIsSubmitting(true)
        try {
            await createBuyOrder()
        } catch (error) {
            setIsSubmitting(false)
        }
    }

    if (!stagedBuyOrder || !instrument) {
        return <Navigate to={profileUrl('explore')} />
    }

    const handleBackPressed = () => {
        updateStagedBuyOrder({
            ...stagedBuyOrder!,
            state: 'initialised',
        })
        navigate(-1)
    }

    const {
        orderType,
        orderCurrencyAmount,
        orderPriceLimit,
        orderShareAmount,
        orderTriggerPrice,
        expectedFee,
        paymentBreakdown,
        totalCost,
        error,
        coverageToHold,
        dividendReinvest,
    } = stagedBuyOrder
    const showAutoExerciseConfirmation = stagedBuyOrder.autoExercise && !!parentInstrument

    const gatherItems = (): OrderRow[] => {
        const coverageType = feeCoverage(new Decimal(coverageToHold ?? '0'), expectedFee)

        if (isManagedFund && expectedFee && assetManager) {
            return [
                {
                    description: 'Estimated amount to invest',
                    value: totalCost ? (
                        <DollarValue value={Number(totalCost) - Number(expectedFee)} currency={instrument.currency} />
                    ) : (
                        '-'
                    ),
                },
                {
                    description: (
                        <>
                            Estimated transaction fee charged by{' '}
                            <div>
                                {assetManager.shortName}
                                <BuyAndSellFeesManagedFund />
                            </div>
                        </>
                    ),
                    value: <FeeValue value={expectedFee} currency={instrument.currency} />,
                },
            ]
        }

        if (isManagedFund && !expectedFee) {
            return [
                {
                    description: 'There are no transaction fees charged by this fund',
                },
            ]
        }

        const displayFeeModal = instrument.isAdr ? (
            <ADR jurisdiction={jurisdiction} />
        ) : (
            <BuyAndSellFeesListedInstruments />
        )

        if (!expectedFee) {
            return [
                {
                    description: (
                        <>
                            No transaction fee on this investment
                            {displayFeeModal}
                        </>
                    ),
                },
            ]
        }

        // listed companies and ETFs

        const items = []

        if (orderType === DOLLAR_TRIGGER && orderTriggerPrice) {
            items.push({
                description: 'Share price to trigger buy order',
                value: <DollarValue value={Number(orderTriggerPrice)} currency={instrument.currency} />,
            })
        }

        if (orderType !== DOLLAR_MARKET && orderPriceLimit) {
            items.push({
                description: `Highest price to pay per ${shareLabel({instrument})}`,
                value: <SharePriceValue value={orderPriceLimit} currency={instrument.currency} />,
            })
        }

        if (orderType === DOLLAR_LIMIT && orderPriceLimit && orderCurrencyAmount && expectedFee) {
            items.push({
                description: `Estimated number of ${shareLabel({instrument, isPlural: true})} to buy`,
                value: howManyShares({
                    marketPrice: parseFloat(instrument.marketPrice),
                    fee: parseFloat(expectedFee),
                    priceLimit: parseFloat(orderPriceLimit),
                    amount: orderCurrencyAmount,
                }),
            })
        }

        if (orderType === SHARE_LIMIT && orderPriceLimit && orderShareAmount) {
            items.push({
                description: `Number of ${shareLabel({instrument, isPlural: true})} to buy`,
                value: <ShareValue value={orderShareAmount} showFullValue />,
            })
        }

        items.push({
            description: (
                <span>
                    {orderType === SHARE_LIMIT && orderPriceLimit && orderShareAmount
                        ? `Estimated amount to invest`
                        : `Amount to invest`}
                    {isOnASX(instrument) && jurisdiction === 'au' && ' incl. GST '}
                </span>
            ),
            value: totalCost ? (
                <DollarValue value={Number(totalCost) - Number(expectedFee)} currency={instrument.currency} />
            ) : (
                '-'
            ),
        })

        items.push({
            description: (
                <span>
                    {' '}
                    {orderType === SHARE_LIMIT && orderPriceLimit && orderShareAmount
                        ? `Estimated transaction fee`
                        : `Transaction fee`}
                    {isOnASX(instrument) && jurisdiction === 'au' && ' incl. GST '}
                    {displayFeeModal}
                </span>
            ),
            value: expectedFee ? <FeeValue value={expectedFee} currency={instrument.currency} /> : '-',
            explainerText: isManagedFund || !coverageType ? undefined : <CoverageText feeCoverage={coverageType} />,
        })

        return items
    }

    return (
        <>
            <Page>
                {showingHaltedNZXMessage && (
                    <HaltedNZXMessage
                        onClick={() => {
                            setShowingHaltedNZXMessage(false)
                        }}
                    />
                )}
                {!isManagedFund && <ExchangeHours instrument={instrument} />}
                <div className={confirmStyles.header}>
                    <PageBack onClick={handleBackPressed} />
                    <h1>
                        {isManagedFund
                            ? 'Confirm your buy order'
                            : dividendReinvest
                              ? 'Confirm market buy for the dividend you were paid'
                              : `Confirm your ${getLabelByBuyOrderType(orderType, instrument)}${
                                    showAutoExerciseConfirmation ? ' and auto-exercise' : ''
                                }`}
                    </h1>
                </div>

                {showAutoExerciseConfirmation && (
                    <AutoExerciseOrderConfirmation
                        instrument={instrument}
                        parentInstrument={parentInstrument}
                        stagedBuyOrder={stagedBuyOrder}
                        jurisdiction={jurisdiction}
                    />
                )}

                {!showAutoExerciseConfirmation && (
                    <OrderConfirmation
                        title={instrument.name}
                        subtitle={
                            !isManagedFund && (
                                <p className={confirmStyles.symbol}>
                                    <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                                    <PronounceLetters text={instrument.exchange} />
                                </p>
                            )
                        }
                        image={<InstrumentLogo instrument={instrument} noBorder />}
                        items={gatherItems()}
                        total={{
                            description:
                                orderType === SHARE_LIMIT && instrument.exchangeCountry === 'usa'
                                    ? `Estimated order amount`
                                    : `Order amount`,
                            value: totalCost ? <DollarValue value={totalCost} currency={instrument.currency} /> : '-',
                        }}
                        exchange={
                            paymentBreakdown ? (
                                <ConfirmExchange
                                    displayCurrency={instrument.currency}
                                    paymentBreakdown={paymentBreakdown}
                                    expectedFee={expectedFee}
                                />
                            ) : undefined
                        }
                    />
                )}

                <div>
                    {isDerivative && parentInstrument && !stagedBuyOrder.autoExercise && (
                        <OfferWarning instrument={instrument} parentInstrument={parentInstrument} />
                    )}

                    {!isManagedFund && <UpcomingDividendInformation verb="buy" instrument={instrument} />}

                    <div className={confirmStyles.extraInfo}>
                        <OrderPlanBuyMessage
                            isManagedFund={isManagedFund}
                            isAutoExercise={stagedBuyOrder.autoExercise || false}
                            feeCoverage={feeCoverage(new Decimal(coverageToHold ?? '0'), expectedFee)}
                        />
                        <OrderReinvestDividendsBuyMessage instrument={instrument} />
                        {isManagedFund && <ManagedFundOrderProcessMessage />}
                        {!isManagedFund && (
                            <>
                                {showAutoExerciseConfirmation && (
                                    <p
                                        className={confirmStyles.orderProcessMessageHeading}
                                    >{`About your ${getLabelByBuyOrderType(orderType, instrument)}`}</p>
                                )}
                                <ExchangeOrderProcessMessage
                                    instrument={instrument}
                                    side="buy"
                                    isLimitOrder={!!orderPriceLimit}
                                    isTriggerOrder={!!orderTriggerPrice}
                                    isForExtendedHours={!!stagedBuyOrder.extendedHours}
                                />
                                {showAutoExerciseConfirmation && (
                                    <ExchangeAutoExerciseOrderProcessMessage instrument={instrument} />
                                )}
                            </>
                        )}
                    </div>
                </div>
                {error && (
                    <p className={confirmStyles.submitError}>We couldn’t complete the order, please try again.</p>
                )}
                <AutoinvestNudgeModal
                    isOpen={showAutoinvestNudgeModal}
                    setIsOpen={() => {
                        setShowAutoinvestNudgeModal(false)
                        handleExit()
                    }}
                    setExitNavigation={(target: string) => {
                        setExitNavigation(target)
                    }}
                />
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--buy"
                    label="Buy"
                    processing={isSubmitting || stagedBuyOrder.state === 'placed'}
                    onClick={handleSubmit}
                />
            </ActionBar>
            {error && (
                <BuyErrors
                    jurisdiction={jurisdiction}
                    stagedBuyOrder={stagedBuyOrder}
                    instrument={instrument}
                    isDependent={isDependent}
                    preferredName={preferredName}
                    page="confirm"
                    updateStagedBuyOrder={updateStagedBuyOrder}
                />
            )}
        </>
    )
}

interface StoreProps {
    stagedBuyOrder?: StagedBuyOrder
    instrument?: Instrument
    isFirstOrder: boolean
    isDependent: boolean
    preferredName: string
    jurisdiction: Model.User['jurisdiction']
}

interface DispatchProps {
    createBuyOrder: UnwrapThunkAction<typeof actions.CreateBuyOrder>
    clearStagedBuyOrder(): void
    updateStagedBuyOrder(order: StagedBuyOrder): void
}

type BuyConfirmProps = StoreProps & DispatchProps

export default connect<StoreProps, DispatchProps, {}>(
    state => {
        const {order, instrument, identity} = state
        const stagedBuyOrder = order.stagedBuyOrder
        const currentInstrument = stagedBuyOrder ? instrument.instrumentsById[stagedBuyOrder.fundId] : undefined

        return {
            stagedBuyOrder,
            instrument: currentInstrument,
            isFirstOrder: identity.holdings.length === 0 && identity.orders.length === 0,
            preferredName: state.identity.user!.preferred_name,
            isDependent: state.identity.user!.is_dependent,
            jurisdiction: state.identity.user!.jurisdiction,
        }
    },
    dispatch => ({
        createBuyOrder: () => dispatch(actions.CreateBuyOrder()),
        clearStagedBuyOrder: () => dispatch(actions.ClearStagedBuyOrder()),
        updateStagedBuyOrder: order => dispatch(actions.UpdateStagedBuyOrder(order)),
    }),
)(BuyConfirm)
