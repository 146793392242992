import {Button} from '@design-system/button'
import cn from 'classnames'
import {Formik} from 'formik'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {getASXTransferReferenceType} from '~/global/utils/get-asx-transfer-reference-type/getASXTransferReferenceType'
import {alphanumericOnly} from '~/global/utils/normalize-values/normalizeValues'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {validate, Radio} from '~/global/widgets/form-controls'
import {Text, TFNNumber} from '~/global/widgets/form-controls/formik'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {Toast} from '~/global/widgets/toast/Toast'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import transferActions from '~/store/transfer/actions'
import {TransferOrderRegistryDetails} from '~/store/transfer/types'

interface FormValues {
    reference: TransferOrderRegistryDetails['referenceId']
    hinBroker?: string
    pid?: string
    tfn?: string
}

export const AddRegistryDetailsForm = (props: {nextPage: () => void}) => {
    const notificationContext = React.useContext(NotificationContext)
    const dispatch = useAppDispatch()

    const [transferInType, setTransferInType] = React.useState<'srn' | 'hin' | undefined>()

    const showTfnField = useAppSelector(
        ({identity}) => !!identity.user?.tfn_number !== true && identity.user!.jurisdiction === 'au',
    )
    const {registryDetails, stagedTransferOrder, existingReference, transferType, direction} = useAppSelector(
        ({transfer}) => ({
            registryDetails: transfer.registryDetails,
            stagedTransferOrder: transfer.stagedTransferOrder!,
            existingReference: transfer.stagedTransferOrder!.reference,
            transferType: transfer.stagedTransferOrder!.referenceType,
            direction: transfer.stagedTransferOrder!.direction,
        }),
    )

    const setTfnNumber = (tfnNumber: string) => dispatch(identityActions.UpdateAUFinancialDetails(tfnNumber))
    const setRegistryDetails = (
        reference: TransferOrderRegistryDetails['referenceId'],
        reference_type: TransferOrderRegistryDetails['referenceType'],
        pid?: string,
        hinBroker?: string,
    ) => {
        const baseDetails = {
            referenceId: reference,
            referenceType: reference_type,
            participantId: pid,
            hinBroker,
        }
        return stagedTransferOrder.registryDetailId
            ? dispatch(
                  transferActions.UpdateRegistryDetails({
                      ...baseDetails,
                      registryDetailId: stagedTransferOrder.registryDetailId,
                  }),
              )
            : dispatch(transferActions.CreateRegistryDetails(baseDetails))
    }

    return (
        <Formik
            initialValues={
                {
                    reference: stagedTransferOrder.reference || '',
                } as FormValues
            }
            validate={values => {
                return validate.generate<typeof values>({
                    reference: [
                        validate.required(),
                        ...(transferType === 'HIN' || transferInType === 'hin' ? [validate.hin()] : [validate.srn()]),
                    ],
                    tfn: showTfnField ? [(validate.required(), validate.tfnNumber())] : [],
                })(values)
            }}
            onSubmit={async ({hinBroker, pid, reference, tfn}, {setSubmitting}) => {
                try {
                    const referenceType = transferType ? transferType : getASXTransferReferenceType(reference)
                    const isUpdatingRegistryDetail = existingReference && reference !== existingReference
                    const isUsingExistingRegistryDetail =
                        registryDetails.filter(
                            detail => detail.reference === reference && detail.reference_type === referenceType,
                        ).length > 0
                    // Trigger redux actions
                    const errorTfn = tfn ? await setTfnNumber(tfn) : undefined
                    const errorDetails = await setRegistryDetails(reference, referenceType, pid, hinBroker)

                    if (errorDetails) {
                        notificationContext.showModalError({message: errorDetails})
                        setSubmitting(false)
                    } else if (showTfnField && errorTfn) {
                        // tfn error can only exist if the tfn field has been shown, otherwise set tfn will not be called
                        notificationContext.showModalError({message: errorTfn})
                        setSubmitting(false)
                    } else {
                        if (isUpdatingRegistryDetail) {
                            Toast('Investor details updated')
                        } else if (!isUsingExistingRegistryDetail) {
                            // ensure that this is only shown when a registry detail is added for the first time
                            Toast('Investor details added')
                        }
                        props.nextPage()
                    }
                } catch (e) {
                    notificationContext.showModalError({message: unknownErrorMessage})
                    setSubmitting(false)
                    throw e
                }
            }}
        >
            {({values: {reference}, handleSubmit, isSubmitting, isValid}) => {
                const isUpdatingRegistryDetail = existingReference && reference !== existingReference
                return (
                    <form onSubmit={handleSubmit}>
                        <div className={commonStyles.formWrapper}>
                            <h1 className={cn(commonStyles.heading, spacing.spaceBelow24)}>
                                Add your investor details
                            </h1>

                            {showTfnField && (
                                <div className={spacing.spaceAbove24}>
                                    <TFNNumber
                                        dataTestId="text-input--tfn-number"
                                        label="TFN (Tax File Number)"
                                        name="tfn"
                                        autoFocus
                                    />
                                </div>
                            )}

                            {!transferType && (
                                <Radio
                                    choices={[
                                        {value: 'hin', label: 'Holder Identification Number (HIN)'},
                                        {value: 'srn', label: 'Shareholder Reference Number (SRN)'},
                                    ]}
                                    dataTestId="radio--asx-transfer-type"
                                    isTouched={false}
                                    name="transferInType"
                                    onChange={e => setTransferInType(e.target.value as 'srn' | 'hin')}
                                    value={transferInType}
                                />
                            )}

                            {(transferType === 'SRN' || transferInType === 'srn') && !existingReference && (
                                <>
                                    <Text
                                        dataTestId="text-input--srn"
                                        label="SRN"
                                        name="reference"
                                        normalizeValue={alphanumericOnly}
                                    />

                                    <p>
                                        {direction === 'in'
                                            ? `Your SRN is the number your shares are registered against.`
                                            : `Your SRN number lets us know where you want your shares transferred to.`}{' '}
                                        Don’t know your SRN? You can contact{' '}
                                        <HelpCentreLink
                                            auArticle="6950844-shareholder-reference-number-srn-and-holder-identification-number-hin"
                                            nzArticle="6950819-shareholder-reference-number-srn-and-holder-identification-number-hin"
                                        >
                                            your share registry
                                        </HelpCentreLink>{' '}
                                        to find it.
                                    </p>
                                </>
                            )}

                            {(transferType === 'HIN' || transferInType === 'hin') && (
                                <>
                                    <Text
                                        dataTestId="text-input--hin"
                                        label="HIN"
                                        name="reference"
                                        normalizeValue={alphanumericOnly}
                                        placeholder="X 000 000 0000"
                                    />
                                    <p className={spacing.spaceBelow32}>
                                        Your HIN is the number under which your shares are registered with your broker.
                                        It usually starts with the letter X and is followed by 10 numbers.
                                    </p>
                                    <Text
                                        dataTestId="text-input--pid"
                                        label="Participant identification number (PID)"
                                        name="pid"
                                        normalizeValue={alphanumericOnly}
                                        placeholder="00000"
                                    />
                                    <p className={!showTfnField ? spacing.spaceBelow32 : ''}>
                                        The PID is used to identify the underlying participant that your broker uses to
                                        trade on the market. This may be found on the top right corner of your holding
                                        statement.
                                    </p>
                                    <Text dataTestId="text-input--hin-broker" label="Broker" name="hinBroker" />
                                    <p>
                                        If you don’t know your PID please enter the name of the broker that your HIN is
                                        registered with ie. ASB Securities, CommSec markets.
                                    </p>
                                </>
                            )}
                        </div>
                        <ActionBar>
                            <Button
                                dataTestId="button--add-details"
                                label={isUpdatingRegistryDetail ? 'Update details' : 'Add details'}
                                disabled={!isValid}
                                isSubmit
                                processing={isSubmitting}
                            />
                        </ActionBar>
                    </form>
                )
            }}
        </Formik>
    )
}
