import {ModalLink} from '@design-system/modal'
import Decimal from 'decimal.js'
import React from 'react'
import {Link} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {DollarValue, SharePriceValue} from '~/global/widgets/number-elements/NumberElements'
import styles from '~/sections/invest/sections/order-flow/OrderForm.scss'
import {Instrument} from '~/store/instrument/types'

interface ParentInstrumentPriceComparisonProps {
    parentInstrument: Instrument
    applicationCurrency: Currency
    costPerUnitDecimal: Decimal
}

const ParentInstrumentPriceComparison: React.FunctionComponent<ParentInstrumentPriceComparisonProps> = ({
    parentInstrument,
    applicationCurrency,
    costPerUnitDecimal,
}) => {
    const profileUrl = useProfileUrl()
    const owner = useProfileOwner()

    const costPerUnit = parseFloat(costPerUnitDecimal.toFixed(3))
    const parentInstrumentMarketPrice = parseFloat(parentInstrument.marketPrice)
    const parentInstrumentPriceDifference = Math.abs(parentInstrumentMarketPrice - costPerUnit)

    // null if the price is the same
    const parentInstrumentIsCheaper =
        parentInstrumentMarketPrice !== costPerUnit ? parentInstrumentMarketPrice < costPerUnit : null

    if (applicationCurrency !== parentInstrument.currency) {
        return null
    }

    return (
        <p className={spacing.spaceAbove12}>
            That’s{' '}
            {parentInstrumentIsCheaper === null ? (
                <>the same as</>
            ) : (
                <>
                    <strong>
                        <DollarValue
                            value={parentInstrumentPriceDifference}
                            currency={applicationCurrency}
                            decimalPlaces={3}
                        />{' '}
                        {parentInstrumentIsCheaper ? <>higher</> : <>lower</>} per{' '}
                        {shareLabel({instrument: parentInstrument})}
                    </strong>{' '}
                    than
                </>
            )}{' '}
            {parentInstrument.symbol}’s latest {shareLabel({instrument: parentInstrument})} price of{' '}
            <strong>
                <SharePriceValue value={parentInstrument.marketPrice} currency={parentInstrument.currency} />
            </strong>
            <ModalLink
                dataTestId="modal-link--latest-price"
                additionalClassName={styles.priceComparisonModal}
                label={`Latest ${shareLabel({instrument: parentInstrument})} price`}
                modalTitle={`Latest ${shareLabel({instrument: parentInstrument})} price`}
                primaryButton={{label: 'Ok, got it'}}
                asIcon
                helpIconSize={16}
            >
                <p>
                    The latest {shareLabel({instrument: parentInstrument})} price for exchange-traded investments can go
                    up and down, and is delayed by at least 20 minutes when the market is open.
                </p>
                <p>
                    Orders for exchange-traded investments are charged the 1.9% transaction fee (and per order fee caps)
                    {owner.owner_type !== 'ORGANISATION' && (
                        <>
                            unless covered by <Link to={profileUrl('settings/plan')}>a pricing plan</Link>
                        </>
                    )}
                    .
                </p>
            </ModalLink>
        </p>
    )
}

export default ParentInstrumentPriceComparison
