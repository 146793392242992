import {rakaiaGetFactory} from '~/api/query/rakaia'
import {
    AppSchemasForClientPortfolioLatestInstrumentReturn,
    Currency,
    GetInstrumentInvestingHistoryApiV1PortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGetRequest,
} from '~/api/rakaia'

export const useRakaiaReadPortfolio = (portfolioUuid: string) => {
    const {data} = rakaiaGetFactory({
        apiFunctionName: 'readPortfolioApiV1PortfoliosPortfolioUuidGet',
        notFoundResponse: ({portfolioUuid}) => ({
            uuid: portfolioUuid,
            currency: Currency.NZD,
            simple_return: 0,
            portfolio_value: 0,
            total_return: 0,
            portfolio_history: undefined,
        }),
    })({portfolioUuid})
    return data
}

export const useRakaiaGetLatestInstrumentReturns = (portfolioUuid: string) => {
    const {data} = rakaiaGetFactory({
        apiFunctionName: 'getLatestInstrumentReturnsApiV1PortfoliosPortfolioUuidInstrumentsGet',
        notFoundResponse: (): {
            instrument_returns: {[key: string]: AppSchemasForClientPortfolioLatestInstrumentReturn}
        } => ({
            instrument_returns: {},
        }),
    })({portfolioUuid})
    return data
}

export const useRakaiaGetInstrumentInvestingHistory = (
    args: GetInstrumentInvestingHistoryApiV1PortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGetRequest,
) => {
    const {data} = rakaiaGetFactory({
        apiFunctionName: 'getInstrumentInvestingHistoryApiV1PortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGet',
    })(args)
    return data
}
