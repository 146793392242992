import React from 'react'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {useFonterraPortfolio} from '~/sections/moose/state/local'

interface OrderErrorProps {
    errorCode: string
    instrumentName: string
}

export const OrderError: React.FunctionComponent<OrderErrorProps> = ({errorCode, instrumentName}) => {
    const portfolio = useFonterraPortfolio()
    const notificationContext = React.useContext(NotificationContext)
    const {showModalError, closeModalError} = notificationContext

    React.useEffect(() => {
        if (!errorCode) {
            return
        }
        switch (errorCode) {
            case 'error_too_many_open_orders':
                showModalError({
                    title: 'Too many orders for this investment',
                    message: (
                        <p>
                            Your order for {instrumentName} couldn’t be placed, filling the order would exceed the
                            pending order limit for {portfolio.name} ({portfolio.farm_id}).
                        </p>
                    ),
                    onClose: () => closeModalError,
                    primaryButton: {
                        label: 'Ok',
                        onClick: closeModalError,
                    },
                })
                break
            case 'error_consolidation_type_cannot_place_orders':
            case 'error_too_many_shares_breach_max_guard':
            case 'error_may_breach_max_guard_if_filled':
                showModalError({
                    title: 'We couldn’t complete the order',
                    message: (
                        <p>
                            Your order for {instrumentName} couldn’t be placed, filling the order would breach
                            shareholding limits for {portfolio.name} ({portfolio.farm_id}).
                        </p>
                    ),
                    onClose: () => closeModalError,
                    primaryButton: {
                        label: 'Ok',
                        onClick: closeModalError,
                    },
                })
                break
            case 'error_expire_end_of_day_must_be_at_least_1_day_in_future':
            case 'error_expire_end_of_day_must_be_no_more_than_90_days_in_future':
                showModalError({
                    title: 'We couldn’t complete the order',
                    message: (
                        <p>
                            Your order for {instrumentName} couldn’t be placed, choose an order expiry between 1 and 90
                            days in the future.
                        </p>
                    ),
                    onClose: () => closeModalError,
                    primaryButton: {
                        label: 'Ok',
                        onClick: closeModalError,
                    },
                })
                break
            default:
                showModalError({
                    message: <p>We couldn’t complete the order, please try again.</p>,
                    onClose: () => closeModalError,
                    primaryButton: {
                        label: 'Ok',
                        onClick: closeModalError,
                    },
                })
        }
    }, [errorCode, instrumentName, portfolio.name])

    return <></>
}
