import React from 'react'
import {spacing} from '~/global/scss/helpers'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {FARM_SOURCE_HREF} from '~/sections/moose/constants/constants'
import {FarmerSupportNumber} from '~/sections/moose/widgets/farmer-support-number/FarmerSupportNumber'

/**
 * Fonterra contact page - visible for Fonterra owners only
 */
export const ContactFonterra = () => {
    return (
        <>
            <Toolbar dataTestId="toolbar--contact-fonterra" leftButton="back" title="Contact Fonterra" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <p className={spacing.spaceBelow32}>
                    The Farmer support team can reached on <FarmerSupportNumber /> for general support queries.
                </p>
                <p>
                    You can also update the primary trading contact or mobile phone number by logging into your{' '}
                    <a href={FARM_SOURCE_HREF} target="_blank" rel="noreferrer">
                        Farm Source
                    </a>{' '}
                    account.
                </p>
            </Page>
        </>
    )
}

export default ContactFonterra
