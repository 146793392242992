/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    PortfolioInvestingHistoryDetails,
    PortfolioInvestingHistoryDetailsFromJSON,
    PortfolioInvestingHistoryDetailsFromJSONTyped,
    PortfolioInvestingHistoryDetailsToJSON,
    PortfolioInvestingHistoryDetailsToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface PortfolioInvestingHistory
 */
export interface PortfolioInvestingHistory {
    /**
     *
     * @type {Array<PortfolioInvestingHistoryDetails>}
     * @memberof PortfolioInvestingHistory
     */
    history: Array<PortfolioInvestingHistoryDetails>
}

export function PortfolioInvestingHistoryFromJSON(json: any): PortfolioInvestingHistory {
    return PortfolioInvestingHistoryFromJSONTyped(json, false)
}

export function PortfolioInvestingHistoryFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): PortfolioInvestingHistory {
    if (json === undefined || json === null) {
        return json
    }
    return {
        history: (json['history'] as Array<any>).map(PortfolioInvestingHistoryDetailsFromJSON),
    }
}

export function PortfolioInvestingHistoryToJSON(value?: PortfolioInvestingHistory | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        history: (value.history as Array<any>).map(PortfolioInvestingHistoryDetailsToJSON),
    }
}
