import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {Loading} from '~/global/widgets/loading'
import BaseFundsFeesInfo from './BaseFundsFeesInfo'
import styles from './KSFundFees.scss'

const KSFundFees: React.FunctionComponent<{}> = () => {
    return (
        <React.Suspense fallback={<Loading className={styles.kSFundFeesLoader} />}>
            <div className={styles.kSFundFees}>
                <div className={spacing.spaceBelow16}>
                    <h3 className={styles.modalHeading}>Base fund annual fund charges</h3>
                    Each base fund has a single annual fund charge—there are no admin or performance fees, or fees for
                    switching between base funds.
                </div>
                <BaseFundsFeesInfo />
                <h3 className={styles.modalHeading}>If you have your own picks</h3>
                <h4>Sharesies admin fee</h4>
                0.15% per year
                <h4>Transaction fee</h4>
                0.1% – 1.0% per transaction
                <h4>ETF fees</h4>
                When you invest in ETFs, you’re charged a management fee by the fund provider. This fee is included in
                the share price. For more info on fees, check out our{' '}
                <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.sharesies.nz/pricing#how-we-calculate-transaction-fees"
                >
                    website
                </a>
                .
            </div>
        </React.Suspense>
    )
}

export default KSFundFees
