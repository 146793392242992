import {useQueryClient} from '@tanstack/react-query'
import {useAtom, useAtomValue} from 'jotai'
import React from 'react'
import {Navigate, useNavigate} from 'react-router'
import {logCustomEvent} from '~/api/braze/braze'
import {constructCoveGetUrl, coveGetUrlQueryString} from '~/api/query/cove'
import {useRetailGet} from '~/api/query/retail'
import {Response} from '~/api/retail/types'
import config from '~/configForEnv'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Toast} from '~/global/widgets/toast/Toast'
import {constructCoveEmbedUserLoginUrl} from '~/sections/protect/sections/insure/sections/car/utils/cove-embed/coveEmbed'
import {CoveEmbedPage} from '~/sections/protect/sections/insure/sections/car/widgets/cove-embed-page/CoveEmbedPage'
import {CoveIframe} from '~/sections/protect/sections/insure/sections/car/widgets/cove-iframe/CoveIframe'
import {coveCreateEstimateResponseAtom} from '~/sections/protect/sections/insure/state'
import {reportNoInsureAccountToRollbar} from '~/sections/protect/sections/insure/utils/report-rollbar-error/reportRollbarError'

// Wrapper allows for local loading state and error capture
export const CoveCustomiseEstimateWrapper: React.FunctionComponent = () => {
    return (
        <CoveEmbedPage name="Cove Customise Estimate embed page">
            <CoveCustomiseEstimate />
        </CoveEmbedPage>
    )
}

const CoveCustomiseEstimate: React.FunctionComponent = () => {
    // The other item-specific Cove embed pages (policy, quote) have a path param,
    // but that's not suitable for this page, since we're dealing with a URL.
    const coveCreateEstimateResponse = useAtomValue(coveCreateEstimateResponseAtom)

    const profileUrl = useProfileUrl()

    const {data: insureAccount} = useRetailGet({path: 'insure/get-account'})
    const {data: isCoveCustomer} = useRetailGet({path: 'insure/get-is-cove-customer'})

    if (!coveCreateEstimateResponse?.cove_referral_link) {
        return <Navigate to={profileUrl('protect/insure')} replace />
    }

    if (insureAccount.type !== 'insure_account') {
        // They should have an Insure account at this point

        reportNoInsureAccountToRollbar()
        return <Navigate to={profileUrl('protect/insure')} replace />
    }

    const coveEmbedUrl = new URL(coveCreateEstimateResponse.cove_referral_link)

    if (!isCoveCustomer.is_cove_customer) {
        // Not an existing Cove customer: use supplied coveEmbedUrl in iframe
        return <CoveCustomiseEstimateIframe embedUrl={coveEmbedUrl} insureAccount={insureAccount} />
    }

    // They're a Cove customer: we need to use the login-and-redirect URL in the iframe
    // For that we need the embed login_id, so we need to drop into another wrapper to grab that via a hook
    return <CoveUserCustomiseEstimate embedUrl={coveEmbedUrl} insureAccount={insureAccount} />
}

const CoveUserCustomiseEstimate: React.FunctionComponent<{
    embedUrl: URL
    insureAccount: Response.InsureAccount
}> = ({embedUrl, insureAccount}) => {
    const {data: coveEmbedLogin} = useRetailGet({
        path: 'insure/get-cove-user-embed-login-id',
    })

    const loginEmbedUrl = constructCoveEmbedUserLoginUrl(coveEmbedLogin.login_id, embedUrl.toString())

    return <CoveCustomiseEstimateIframe embedUrl={loginEmbedUrl} insureAccount={insureAccount} />
}

/**
 * Once the user enters the iframe embed, three Cove steps load in quick succession:
 * 1. Retrieving Partner Estimate
 * 2. Creating Cove quote
 * 3. Please check your details for us
 *
 * Once they've passed step 2, the quote has been created and persisted in Cove's system,
 * and can be pulled from the Cove Retrieve Quotes endpoint.
 */
const CoveCustomiseEstimateIframe: React.FunctionComponent<{embedUrl: URL; insureAccount: Response.InsureAccount}> = ({
    embedUrl,
    insureAccount,
}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const queryClient = useQueryClient()

    const [coveCreateEstimateResponse, setCoveCreateEstimateResponse] = useAtom(coveCreateEstimateResponseAtom)

    React.useEffect(() => {
        // Add an event listener that will receive a success message from the iframe
        const handleCoveMsg = (event: {origin: string; type: string; data: {paymentStatus: string}}) => {
            if (event.origin !== config.coveEmbedBaseUrl) {
                // Security check - we don't want to interact with other external sites here
                return
            }

            if (event.data.paymentStatus === 'success') {
                navigate(profileUrl('protect/insure'), {replace: true})
                Toast('Ka rawe! Your policy has been created')
                logCustomEvent('insurance_customer_signup', {
                    product: 'car',
                    quote_id: coveCreateEstimateResponse?.quote_id,
                })
            }
        }
        window.addEventListener('message', handleCoveMsg, false)

        // When navigating away from this component, we want to reset our query caches to avoid stale data
        return () => {
            // The user may create either a quote or a policy in this embed.
            // One of the initial steps creates a quote in Cove's system, and from there the user may choose to
            // proceed through the whole buy policy flow.
            // Reset the policy, quote, and count query caches to ensure that they'll be refetched next time we call the hooks
            const getCovePoliciesUrl = constructCoveGetUrl('coverage/v1/query', insureAccount.cove_customer_id)
            const getCoveQuotesUrl = constructCoveGetUrl('quote/v1/queryByCustomer', insureAccount.cove_customer_id)
            const getCoveCountsUrl = constructCoveGetUrl('customer/v1/counts', insureAccount.cove_customer_id)
            queryClient.resetQueries({queryKey: [coveGetUrlQueryString(getCovePoliciesUrl)]})
            queryClient.resetQueries({queryKey: [coveGetUrlQueryString(getCoveQuotesUrl)]})
            queryClient.resetQueries({queryKey: [coveGetUrlQueryString(getCoveCountsUrl)]})

            setCoveCreateEstimateResponse(undefined)
        }
    }, [])

    return <CoveIframe url={embedUrl} />
}
