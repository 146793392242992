import {Button} from '@design-system/button'
import {withFormik} from 'formik'
import React from 'react'
import * as api from '~/api/retail'
import {Request} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AccountVerificationRequired from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import {validate} from '~/global/widgets/form-controls'
import {CheckboxGroup} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/OLD_wallet/sections/top-up/sections/top-up-instant/TopUpInstant.scss'
import {useAppSelector} from '~/store/hooks'

const choices = [
    {value: 'ASB', label: 'ASB'},
    {value: 'ANZ', label: 'ANZ'},
    {value: 'Kiwibank', label: 'Kiwibank'},
    {value: 'SBS', label: 'SBS'},
    {value: 'Co-op', label: 'Co-op'},
    {value: 'TSB', label: 'TSB'},
    {value: 'Heartland', label: 'Heartland'},
    {value: 'Rabobank', label: 'Rabobank'},
]

interface FormValues {
    banks: Request.BlinkPayBankSupportRequest['banks']
}

interface FormProps {
    customerId: string
    setSubmitted: (submitted: boolean) => void
}

const Form = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => ({
        banks: [],
    }),
    validate: validate.generateWithProps<{}, {}>(() => ({
        banks: [
            (banks: string[]) => {
                if (banks.length === 0) {
                    return 'This field is required'
                }
            },
        ],
    })),
    handleSubmit: async ({banks}, {props: {customerId, setSubmitted}}) => {
        try {
            await api.post('blinkpay/blinkpay-bank-support-request', {
                acting_as_id: customerId,
                banks,
            })
            setSubmitted(true)
        } catch (error) {
            // Errors likely to be rare, low impact if they do happen, so do nothing
        }
    },
    validateOnMount: true,
    validateOnChange: true,
})(({
    // Formik props
    handleSubmit,
    isValid,
    isSubmitting,
}) => {
    return (
        <form onSubmit={handleSubmit}>
            <h1 className={spacing.spaceBelow16}>Can’t see your bank?</h1>
            <p data-testid="p--cant-see-bank-explanation" className={spacing.spaceBelow16}>
                Some NZ banks don’t support instant bank transfers yet.
            </p>
            <p className={spacing.spaceBelow24}>
                If you want us to let you know when your bank(s) becomes available, select it from the list below.
            </p>
            <CheckboxGroup name="banks" choices={choices} />
            <Button
                dataTestId="button--submit"
                label="Submit"
                isSubmit
                processing={isSubmitting}
                disabled={!isValid}
                pageButton
            />
        </form>
    )
})

const Submitted: React.FunctionComponent = () => {
    const profileUrl = useProfileUrl()

    return (
        <>
            <h1 className={spacing.spaceBelow16} aria-live="polite">
                Thanks for sharing!
            </h1>
            <p>We’ll let you know when your bank supports instant bank transfers through Sharesies.</p>
            <Button
                additionalClassName={styles.backToWalletLink}
                dataTestId="button--back-to-wallet"
                pageButton
                label="Done"
                reactRouterTo={profileUrl('wallet/topup')}
            />
        </>
    )
}

const SuggestBanks = () => {
    const customerId = useAppSelector(state => state.identity.user!.id)
    const [submitted, setSubmitted] = React.useState(false)

    return (
        <>
            <Toolbar dataTestId="toolbar--top-up-direct-bank" leftButton="back" />
            <Page>
                <AccountVerificationRequired />
                {!submitted && <Form customerId={customerId} setSubmitted={setSubmitted} />}
                {submitted && <Submitted />}
            </Page>
        </>
    )
}

export default SuggestBanks
