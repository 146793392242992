/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface Price
 */
export interface Price {
    /**
     *
     * @type {string}
     * @memberof Price
     */
    instrument_uuid: string
    /**
     *
     * @type {Date}
     * @memberof Price
     */
    date: DateTime
    /**
     *
     * @type {number}
     * @memberof Price
     */
    price: number
}

export function PriceFromJSON(json: any): Price {
    return PriceFromJSONTyped(json, false)
}

export function PriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Price {
    if (json === undefined || json === null) {
        return json
    }
    return {
        instrument_uuid: json['instrument_uuid'],
        date: DateTime.fromISO(json['date'], {setZone: true}),
        price: json['price'],
    }
}

export function PriceToJSON(value?: Price | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        instrument_uuid: value.instrument_uuid,
        date: value.date.toFormat('yyyy-MM-dd'),
        price: value.price,
    }
}
