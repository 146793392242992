import {Document} from '@contentful/rich-text-types'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {RichText} from '~/sections/explore/sections/learn-articles/widgets/rich-text/RichText'
import PrivacyPolicyModalLink from '~/sections/user/sections/sign-up/pages/about-you/widgets/privacy-policy-modal-link/PrivacyPolicyModalLink'
import Terms from '~/sections/user/sections/terms-and-conditions/assets/contentful-entries/terms-and-conditions.json'
import {legalDoc} from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/LegalDocuments.scss'

export const NZ_TC_VERSION = 3
export const AU_TC_VERSION = 4
export type TC_VERSION_TYPE = typeof NZ_TC_VERSION | typeof AU_TC_VERSION

export const tcVersionForJurisdiction = (jurisdiction: 'nz' | 'au') => {
    return jurisdiction === 'au' ? AU_TC_VERSION : NZ_TC_VERSION
}

interface TermsAndConditionsProps {
    showDisclaimer?: boolean
}

export default ({showDisclaimer}: TermsAndConditionsProps) => (
    <>
        {showDisclaimer && (
            <p className={spacing.spaceBelow16}>
                By accepting Sharesies’ Terms and Conditions you also agree to our <PrivacyPolicyModalLink /> and the{' '}
                <HelpCentreLink
                    linkText="market conduct rules"
                    nzArticle="8482855-market-conduct-rules"
                    auArticle="8482859-market-conduct-rules"
                />
            </p>
        )}
        <div className={legalDoc}>
            <RichText richTextDocument={Terms.fields.content as Document} />
        </div>
    </>
)
