import {GET_MAP, POST_MAP} from '~/api/retail/types'

export const replacePlaceholdersInPath = <T extends keyof POST_MAP | keyof GET_MAP>(
    path: T,
    pathParams: Record<string, string> | undefined,
): T => {
    if (pathParams === undefined) {
        return path
    }

    return path.replace(/:([^/]+)/g, (_, key) => {
        const value = pathParams[key]
        return value
    }) as T
}
