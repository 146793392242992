import {DateTime} from 'luxon'
import {Model} from '~/api/retail/types'
import config from '~/configForEnv'
import {roundDownToTwoDecimalPlaces} from '~/global/utils/calculate-currency-exchange/calculateCurrencyExchange'
import {findExchangeRate} from '~/global/utils/find-exchange-rate/findExchangeRate'
import {dateFormatFullMonth} from '~/global/utils/format-date/formatDate'
import {ExchangeRate} from '~/store/accounting/types'
import {Instrument} from '~/store/instrument/types'
import {
    ResponseGroupTransfer,
    NEW_SRN_REQUIRED,
    StagedTransferOrder,
    NEW_DRS_REQUIRED,
    TransferOrderInstrument,
} from '~/store/transfer/types'

interface AveragePriceData {
    price: number
    total: number
}

export const calculateAverageTransferPrice = (
    order: Model.TransferOrderIn | Model.TransferOrderOut,
): AveragePriceData => {
    if (order.type === 'transfer_out' || !order.records) {
        return {price: 0, total: 0}
    }

    let shares = 0
    let total = 0

    order.records.forEach((record: {shares?: string; price: string}) => {
        if (record.shares) {
            shares += parseFloat(record.shares)
            total += parseFloat(record.shares) * parseFloat(record.price)
        } else {
            shares += 1
            total += parseFloat(record.price)
        }
    })

    return {price: total / shares, total}
}

export const getMostRecentClosePrice = (instrument: Instrument) => {
    if (!instrument.priceHistory) {
        return
    }

    const recentPricesByInstrument = instrument.priceHistory.dayPrices

    if (recentPricesByInstrument) {
        const latestDate = Object.keys(recentPricesByInstrument).reduce<string | undefined>((previous, key) => {
            if (!previous) {
                return key
            }
            if (
                DateTime.fromISO(key).toFormat(dateFormatFullMonth) ===
                DateTime.local().setZone(config.NZTimeZone).toFormat(dateFormatFullMonth)
            ) {
                // Filter out today
                return previous
            }
            return DateTime.fromISO(previous) < DateTime.fromISO(key) ? key : previous
        }, undefined)

        return latestDate ? recentPricesByInstrument[latestDate] : undefined
    }
}

export const getTransferInstrumentValueHomeCurrency = (
    instrument: Instrument,
    totalShares: number,
    exchangeRates: ExchangeRate[],
    homeCurrency: 'aud' | 'nzd' | 'usd',
): string => {
    // Getting the transfer amount from number of shares, based off the most recent close price
    if (!instrument.priceHistory || !instrument.priceHistory.dayPrices) {
        return '-'
    }

    const recentPricesByInstrument = instrument.priceHistory.dayPrices
    const latestDate = Object.keys(recentPricesByInstrument).reduce<string | undefined>((previous, key) => {
        if (!previous) {
            return key
        }
        if (
            DateTime.fromISO(key).toFormat(dateFormatFullMonth) ===
            DateTime.local().setZone(config.NZTimeZone).toFormat(dateFormatFullMonth)
        ) {
            // Filter out today
            return previous
        }
        return DateTime.fromISO(previous) < DateTime.fromISO(key) ? key : previous
    }, undefined)
    if (!latestDate) {
        return '-'
    }
    const sharePrice = recentPricesByInstrument[latestDate]
    const transferAmount = Number(sharePrice) * Number(totalShares)

    if (instrument.currency === homeCurrency) {
        return roundDownToTwoDecimalPlaces(`${transferAmount}`)
    } else {
        const exchangeRate = findExchangeRate({
            sourceCurrency: instrument.currency,
            targetCurrency: homeCurrency,
            exchangeRates,
        })
        if (!exchangeRate) {
            return '-'
        }

        return roundDownToTwoDecimalPlaces((transferAmount * exchangeRate.rate).toString())
    }
}

export const isUSExchange = (
    exchange: Model.TransferOrderIn['exchange'] | undefined,
): exchange is 'US_MARKETS' | 'NYSE' | 'NASDAQ' | 'CBOE' | 'US_OTC' =>
    ['US_MARKETS', 'NYSE', 'NASDAQ', 'CBOE', 'US_OTC'].includes(exchange ?? '')

export const usTransferFee = (
    direction: StagedTransferOrder['direction'],
    usTransferPlatform: StagedTransferOrder['usTransferPlatform'],
) => {
    if (direction === 'in') {
        if (usTransferPlatform === 'INTERNAL') {
            // DriveWealth transfer In cost per request
            return '35'
        } else if (usTransferPlatform === 'DRS') {
            // DRS transfer In cost per request (one instrument per request)
            return '100'
        } else {
            // Non-partner transfer In cost per instrument
            return '50'
        }
    }

    if (direction === 'out') {
        // DriveWealth transfer Out cost per request
        if (usTransferPlatform === 'INTERNAL' || usTransferPlatform === 'DRS') {
            return '100'
        } else {
            // Non-partner transfer Out cost per instrument
            return '50'
        }
    }
}

export const getTransferOrderGroupExchange = (transferOrderGroup: ResponseGroupTransfer) => {
    if (isUSExchange(transferOrderGroup?.exchange) && transferOrderGroup.instruments.length > 1) {
        return transferOrderGroup.instruments.map(i => i.exchange).join(', ')
    }
    return transferOrderGroup.exchange
}

export const isTransferAwaitingDocuments = (transferOrderGroup: ResponseGroupTransfer): boolean => {
    const uploadedTransferDocumentTypes =
        transferOrderGroup.registry_detail_documents &&
        transferOrderGroup.transfer_order_documents.map(document => document.type)
    const hasAuthenticationDocument = uploadedTransferDocumentTypes.includes('AUTHORISATION')
    const hasIdentificationDocument = uploadedTransferDocumentTypes.includes('IDENTIFICATION')
    const hasRegistryDetailDocument =
        (transferOrderGroup.registry_detail_documents && transferOrderGroup.registry_detail_documents.length >= 1) ||
        transferOrderGroup.reference === NEW_SRN_REQUIRED

    // US Transfers:
    // External = Just Brokerage account statement
    // DRS = If existing DRS - Direct Registry Statement and Transfer form, otherwise just Transfer form
    // Internal = Brokerage account statement and Transfer form
    if (transferOrderGroup.us_transfer_broker) {
        if (transferOrderGroup.us_transfer_broker === 'EXTERNAL') {
            return !hasRegistryDetailDocument
        } else if (transferOrderGroup.reference === NEW_DRS_REQUIRED) {
            return !hasAuthenticationDocument
        } else {
            return !(hasAuthenticationDocument && hasRegistryDetailDocument)
        }
    } else {
        // SRN transfers (AU) requires all three
        return !(hasAuthenticationDocument && hasIdentificationDocument && hasRegistryDetailDocument)
    }
}

export const numberOfShares = (instrumentRecords: TransferOrderInstrument['records']) =>
    instrumentRecords
        .reduce((sum, record) => {
            if (record.shares) {
                return sum + parseInt(record.shares, 10)
            }
            return sum
        }, 0)
        .toString()

export const shouldTransferAllShares = (instrumentRecords: TransferOrderInstrument['records']) =>
    instrumentRecords.every(record => record.allShares)

export const isNzxCsn = (csnOrHn: string): boolean => {
    /**
     * Detect if the provided string looks like it is a valid CSN for the NZX
     *
     * There are two types of numbers you can use to trade on the NZX:
     * - Common Shareholder Number (CSN)
     * - Holder Number (HN)
     * both require a FIN to use them, however they are treated differently in
     * important ways for CSN transfers.
     *
     * CSNs are designed to hold many different Instruments and we can look up
     * all the holdings for a CSN using Concierge, but not a HN.
     *
     * HNs are designed to hold only a single Instrument (but they can technically
     * have more than one) and we can't look up the holdings given just a HN - we
     * have to query a specific Instrument to see if it is held.
     */
    const isNumber = /^\d+$/.test(csnOrHn) // Should be a whole number, no preceeding letters
    const isCsnLength = [9, 10].includes(csnOrHn.length) // 9 or 10 digits long
    const isCsnFormat = csnOrHn[0] === '3' // Starting with a 3

    return isCsnLength && isNumber && isCsnFormat
}
