import React from 'react'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {dateFormatNoDay} from '~/global/utils/format-date/formatDate'
import GoogleDriveDocumentViewer from '~/global/widgets/google-drive-document-viewer/GoogleDriveDocumentViewer'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import {Navigate, useNavigate} from '~/migrate-react-router'
import {useAppSelector} from '~/store/hooks'

const ShareSchemeInfoPDF: React.FunctionComponent<Props> = ({instrumentId, shareSchemeId}) => {
    const navigate = useNavigate()
    const detailsById = useAppSelector(s => s.employeeShareScheme.shareSchemeDetailById)
    const detail = detailsById[shareSchemeId]

    if (!detail) {
        // It's expected that the share scheme details have been loaded into state. If they have not been
        // then naviate back to the share scheme detail page
        return <Navigate to={`/employee-share-scheme/${instrumentId}/${shareSchemeId}`} />
    }

    if (!detail?.google_drive_pdf_link) {
        return <WeSlippedUp />
    }

    const pdfLastUpdatedAt = detail.google_drive_pdf_link_updated_at
        ? detail.google_drive_pdf_link_updated_at.toFormat(dateFormatNoDay)
        : undefined

    return (
        <Page withoutDefaultPadding>
            <PageBack />
            <GoogleDriveDocumentViewer
                buttonLabel="Close"
                documentUrl={detail.google_drive_pdf_link}
                title="Your employee share scheme details"
                subTitle={pdfLastUpdatedAt}
                onButtonClick={() => {
                    navigate(-1)
                }}
            />
        </Page>
    )
}

interface Props {
    instrumentId: string
    shareSchemeId: string
}

export default ShareSchemeInfoPDF
