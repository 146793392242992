import {DateTime} from 'luxon'
import {
    containsUnvestedAllocations,
    containsVestedAllocations,
    hasAllocation,
    hasRightAllocation,
    hasAlignmentRightAllocation,
    hasVestedAlignmentRightAllocation,
} from '~/global/utils/check-for-allocation-existence/checkForAllocationExistence'
import {createCachedSelector, createSelector} from '../selectors'

export const essHasShareSchemeVariant = createCachedSelector(
    ({employeeShareScheme}, instrumentId: string, variantName: string) =>
        employeeShareScheme.shareSchemeParticipation
            ? employeeShareScheme.shareSchemeParticipation[instrumentId].filter(p => p.scheme_variant === variantName)
            : undefined,
    (participation): boolean => {
        return !!participation && participation.length > 0
    },
)((_, instrumentId) => instrumentId)

export const possibleESS = createSelector(
    ({identity}) => identity.employmentInstrumentIds,
    employmentInstrumentIds => {
        return employmentInstrumentIds.length > 0
    },
)

export interface EssViews {
    hasEmployment: boolean
    hasScheme: boolean
    hasUnvestedAllocation: boolean
    hasVestedAllocation: boolean
    hasAllocation: boolean
    hasRightAllocation: boolean
    hasMultipleAllocationTypes: boolean
    hasShareScheme: boolean
    hasLtiScheme: boolean
    hasAlignmentRightScheme: boolean
    isActiveMember: boolean // Combo of being employed and having at least one non-forfeited allocation
    hasAlignmentRightAllocation: boolean
    hasVestedAlignmentRightAllocation: boolean
    canBuyInstrument: boolean
    canSellInstrument: boolean
}

export const essViewsForPortfolio = createSelector(
    ({identity}) => identity.employmentInstrumentIds.length > 0,
    ({employeeShareScheme}) => employeeShareScheme.shareSchemeParticipation,
    ({employeeShareScheme}) => employeeShareScheme.shareSchemeAllocationsByInstrument,
    (isEmployed, participation, allocations): EssViews => {
        const essViews: EssViews = {
            hasEmployment: false,
            hasScheme: false,
            hasUnvestedAllocation: false,
            hasVestedAllocation: false,
            hasAllocation: false,
            hasRightAllocation: false,
            hasMultipleAllocationTypes: false,
            isActiveMember: false,
            hasShareScheme: false,
            hasLtiScheme: false,
            hasAlignmentRightScheme: false,
            hasAlignmentRightAllocation: false,
            hasVestedAlignmentRightAllocation: false,
            // Investors who have Alignment Rights can't buy and sell shares in the instrument they have
            // However this logic doesn't apply at the portfolio level, so we default to true
            canBuyInstrument: true,
            canSellInstrument: true,
        }

        if (!isEmployed) {
            return essViews
        }
        essViews.hasEmployment = true
        essViews.hasUnvestedAllocation = containsUnvestedAllocations(allocations)
        essViews.hasVestedAllocation = containsVestedAllocations(allocations)
        essViews.hasAllocation = hasAllocation(allocations)
        essViews.hasRightAllocation = hasRightAllocation(allocations)
        essViews.hasMultipleAllocationTypes = essViews.hasAllocation && essViews.hasRightAllocation
        essViews.hasScheme = Object.values(participation).some(o => o?.some(p => !p.exited_at))
        essViews.isActiveMember =
            essViews.hasEmployment && (essViews.hasUnvestedAllocation || essViews.hasVestedAllocation)

        essViews.hasShareScheme =
            !!participation &&
            Object.values(participation).filter(o => o?.some(p => !p.exited_at && p.scheme_type === 'SHARES')).length >
                0

        essViews.hasLtiScheme =
            !!participation &&
            Object.values(participation).filter(o => o?.some(p => !p.exited_at && p.scheme_type === 'RIGHTS')).length >
                0

        essViews.hasAlignmentRightScheme =
            !!participation &&
            Object.values(participation).filter(o => o?.some(p => !p.exited_at && p.scheme_type === 'ALIGNMENT_RIGHTS'))
                .length > 0

        essViews.hasAlignmentRightScheme =
            !!participation &&
            Object.values(participation).filter(o => o?.some(p => !p.exited_at && p.scheme_type === 'ALIGNMENT_RIGHTS'))
                .length > 0

        essViews.hasAlignmentRightAllocation = hasAlignmentRightAllocation(allocations)
        essViews.hasVestedAlignmentRightAllocation = hasVestedAlignmentRightAllocation(allocations)

        return essViews
    },
)

export const essHasActiveBlackoutPeriodForInstrument = createCachedSelector(
    ({employeeShareScheme}, instrumentId: string) => employeeShareScheme.blackoutPeriodByInstrument?.[instrumentId],
    (blackoutPeriodForInstrument): boolean => {
        if (
            blackoutPeriodForInstrument &&
            blackoutPeriodForInstrument.start_at < DateTime.local() &&
            blackoutPeriodForInstrument.end_at > DateTime.local()
        ) {
            return true
        }
        return false
    },
)((_, instrumentId) => instrumentId)
