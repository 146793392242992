import React from 'react'
import {useRetailGet} from '~/api/query/retail'
import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import recordStyles from '~/global/widgets/instrument-activity/Record.scss'
import {PaymentRequestFeeRow, RecordRow} from '~/global/widgets/instrument-activity/common'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import {useAppSelector} from '~/store/hooks'

interface PaymentRequestViewProps {
    paymentRequestId: string
}

const PaymentRequestView: React.FunctionComponent<PaymentRequestViewProps> = ({paymentRequestId}) => {
    const actingAsId = useAppSelector(s => s.identity.user!.id)
    const {data} = useRetailGet({
        path: 'accounting/payment-requests/payment-request',
        payload: {
            payment_request_id: paymentRequestId,
            acting_as_id: actingAsId,
        },
    })

    const paymentRequest = data.payment_request

    return (
        <>
            <div className={recordStyles.header}>
                <PageBack />
            </div>
            <Page withoutDefaultPadding>
                <div className={recordStyles.viewContainer}>
                    <div className={recordStyles.titleBlock}>
                        <h2>{paymentRequest.description}</h2>
                    </div>
                    <div className={recordStyles.orderContent}>
                        <div className={recordStyles.recordRowContainer}>
                            <RecordRow
                                className={recordStyles.recordRowWithDate}
                                left="Date"
                                right={paymentRequest.timestamp.toFormat(dateFormatWithYear)}
                            />
                            <RecordRow
                                className={recordStyles.recordRowWithDate}
                                left="From"
                                right={paymentRequest.from_bank_account}
                            />
                            <PaymentRequestFeeRow
                                paymentRequest={paymentRequest}
                                currency={paymentRequest.currency as Currency}
                            />
                        </div>

                        {['MONOOVA', 'BASIQ'].includes(paymentRequest.provider) && (
                            <div className={recordStyles.orderCompletion}>
                                <div>
                                    <div className={recordStyles.delayReason}>
                                        Top-ups from a linked bank can take up to three business days to appear in your
                                        Wallet.
                                    </div>
                                </div>
                            </div>
                        )}

                        <p className={recordStyles.orderAmountLabel}>{'Amount'}</p>

                        <div className={recordStyles.orderFooter}>
                            <div />
                            <div>
                                <p className={recordStyles.orderAmount}>
                                    <DollarValue value={paymentRequest.amount} currency={paymentRequest.currency} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        </>
    )
}

export default PaymentRequestView
