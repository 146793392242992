import {Button} from '@design-system/button'
import cn from 'classnames'
import React, {useEffect, useState} from 'react'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {roundDownToTwoDecimalPlaces} from '~/global/utils/calculate-currency-exchange/calculateCurrencyExchange'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import useAutoinvestDividends from '~/global/utils/use-autoinvest-dividends/useAutoinvestDividends'
import {useInstrument} from '~/global/utils/use-instruments/useInstruments'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {ExchangeHours} from '~/global/widgets/exchange-hours/ExchangeHours'
import {ErrorBox} from '~/global/widgets/form-controls'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {Loading} from '~/global/widgets/loading/Loading'
import {OrderConfirmation} from '~/global/widgets/order-confirmation/OrderConfirmation'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {useNavigate} from '~/migrate-react-router'
import actions from '~/store/accounting/actions'
import {selectInstrumentOrders} from '~/store/accounting/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import orderActions from '~/store/order/actions'

const ConfirmReinvest = ({instrumentId, orderId}: {instrumentId: string; orderId: string}) => {
    const instrument = useInstrument(instrumentId)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const {setFundPreference} = useAutoinvestDividends()
    const order = useAppSelector(state =>
        selectInstrumentOrders(state, instrumentId).find(order => order.id === orderId),
    )
    const [processing, setProcessing] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        if (order || !instrumentId || !orderId) {
            return
        }
        dispatch(actions.FetchFundOrders(instrumentId))
    }, [instrumentId, orderId])

    if (!instrument || !order) {
        return <Loading isPineapple />
    }

    if (
        !instrumentId ||
        !orderId ||
        !(
            order.type === 'dividend' ||
            (order.type === 'corporate_action_v2' &&
                order.action_type === 'DIVIDEND' &&
                order.is_terminal &&
                // Ensure there's a cash payout on this dividend order
                order.outcome_records.find(outcome => outcome.currency && parseFloat(outcome.gross_amount) > 0))
        )
    ) {
        return <WeSlippedUp />
    }

    const submitConfirmReinvestDividend = async () => {
        let dividendValue: string
        if (order.type === 'dividend') {
            dividendValue = order.gross_value_total
        } else if (order.type === 'corporate_action_v2') {
            // Get the cash payout from corporate action
            const cashPayout = order.outcome_records.find(
                outcome => outcome.currency && parseFloat(outcome.gross_amount) > 0,
            )
            dividendValue = cashPayout!.gross_amount
        } else {
            setError(unknownErrorMessage)
            return
        }
        // Turn on dividend auto-invest for this instrument
        try {
            setProcessing(true)
            dispatch(
                orderActions.UpdateStagedBuyOrder({
                    fundId: instrumentId,
                    state: 'initialised',
                    pushedHistory: false,
                    orderType: 'dollar_market',
                    needReadPDS: undefined,
                    dividendReinvest: 'everytime',
                    dividendReinvestId: orderId,
                    orderCurrencyAmount: roundDownToTwoDecimalPlaces(dividendValue),
                }),
            )
            await dispatch(orderActions.CostBuyOrder())
            setFundPreference({fund_id: instrumentId, preference: true})
            navigate(profileUrl('buy/confirm'))
        } catch (e) {
            setProcessing(false)
            setError(unknownErrorMessage)
        }
    }

    const instrumentNameStartsWithVowel = instrument.name
        .charAt(0)
        .toLowerCase()
        .match(/[aeiou]/i)

    return (
        <>
            <Page>
                <PageBack />
                {tradingType(instrument) !== 'managed' && <ExchangeHours instrument={instrument} />}
                <h1 className={cn(spacing.spaceAbove20, spacing.spaceBelow32, typographyOverrides['as-h1'])}>
                    Confirm your dividend auto-invest
                </h1>
                <OrderConfirmation
                    image={<InstrumentLogo instrument={instrument} noBorder />}
                    title={instrument.name}
                    subtitle={
                        tradingType(instrument) !== 'managed' &&
                        !isInstrumentInNoTrade(instrument) && (
                            <h4>
                                <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                                <PronounceLetters text={instrument.exchange} />
                            </h4>
                        )
                    }
                    items={[
                        {
                            description: 'Amount to invest',
                            value: 'The whole dividend',
                            isBold: true,
                        },
                        {
                            description: 'Frequency',
                            value: 'Every time a dividend is paid',
                            isBold: true,
                        },
                        {
                            description: 'Transaction fee',
                            value: 'Calculated at time of order',
                            isBold: true,
                        },
                    ]}
                />
                <p className={cn(spacing.spaceAbove12, spacing.spaceBelow12)}>
                    Every time you’re paid {instrumentNameStartsWithVowel ? 'an' : 'a'} {instrument.name} dividend,
                    we'll automatically invest it back into {instrument.name}. The order will include transaction fees.
                </p>
                <p>
                    Just a heads-up! This isn't a dividend reinvestment plan (DRP). We'll let you know if this company
                    offers one so you can opt for that instead.
                </p>
                {error && <ErrorBox className={spacing.spaceAbove12} message={error} />}
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--confirm-reinvest"
                    onClick={submitConfirmReinvestDividend}
                    processing={processing}
                    type="primary"
                    label="Confirm"
                />
            </ActionBar>
        </>
    )
}

export default ConfirmReinvest
