import {Button} from '@design-system/button'
import {Formik} from 'formik'
import {useAtom, useSetAtom} from 'jotai'
import React from 'react'
import {useRetailPost} from '~/api/query/retail'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {spacing} from '~/global/scss/helpers'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {ErrorBox, validate} from '~/global/widgets/form-controls'
import {StrongNumber} from '~/global/widgets/form-controls/formik'
import {entityDetailAtom, stagedSignUpAtom} from '~/sections/moose/sections/sign-up/state'

interface ConfirmTokenProps {
    signupId: string
    maskedPhone: string
    error: string
    setError: (error: string) => void
    progressStep: () => void
}

const ConfirmTokenForm = ({signupId, maskedPhone, error, setError, progressStep}: ConfirmTokenProps) => {
    const [stagedSignUp, updateStagedSignUp] = useAtom(stagedSignUpAtom)
    const setEntityDetail = useSetAtom(entityDetailAtom)

    const postSendMFAToken = useRetailPost({path: 'fonterra/signup/send-mfa-token'})
    const postConfirmMFAToken = useRetailPost({path: 'fonterra/signup/confirm-mfa-token'})

    if (!stagedSignUp.signupAttemptToken) {
        return <WeSlippedUp />
    }

    return (
        <Formik
            initialValues={{token: ''}}
            onSubmit={({token}, {setSubmitting}) => {
                postConfirmMFAToken.mutate(
                    {
                        signup_id: signupId,
                        mfa_token: token,
                        signup_attempt_token: stagedSignUp.signupAttemptToken || '', // let the api handle not having a signup attempt token
                    },
                    {
                        onSuccess: response => {
                            switch (response.type) {
                                case 'fonterra_signup_entity_detail':
                                    updateStagedSignUp({...stagedSignUp, hasMobileAuth: true})
                                    setEntityDetail(response)
                                    progressStep()
                                    break
                                case 'fonterra_signup_mfa_issue':
                                    setError(response.message)
                                    setSubmitting(false)
                                    break
                            }
                        },
                    },
                )
            }}
            validate={values => {
                return validate.generate<typeof values>({
                    token: [validate.required(), validate.minDigits(6), validate.maxDigits(6)],
                })(values)
            }}
        >
            {({values, isSubmitting, isValid, handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <p className={spacing.spaceBelow24}>
                        Enter the 6-digit code we sent to your mobile phone: <strong>{maskedPhone}</strong>
                    </p>
                    <StrongNumber
                        normalisation="numberOnly"
                        maxLength={6}
                        decimalPlaces={0}
                        disabled={isSubmitting}
                        dataTestId="strong-number--fonterra-verification-code"
                        name="token"
                        placeholder="000000"
                        label="6-digit code"
                        helpText={
                            <ButtonAsLink
                                dataTestId="button-as-link--fonterra-resend-code"
                                onClick={() => {
                                    postSendMFAToken.mutate(
                                        {signup_id: signupId},
                                        {
                                            onSuccess: response => {
                                                switch (response.type) {
                                                    case 'fonterra_signup_mfa_sent':
                                                        updateStagedSignUp({
                                                            ...stagedSignUp,
                                                            signupAttemptToken: response.signup_attempt_token,
                                                        })
                                                        break
                                                    case 'fonterra_signup_mfa_issue':
                                                        setError(response.message)
                                                        break
                                                }
                                            },
                                        },
                                    )
                                }}
                            >
                                Resend code
                            </ButtonAsLink>
                        }
                    />
                    <ErrorBox message={error} />
                    <Button
                        label="Next"
                        type="primary"
                        dataTestId="button--fonterra-confirm-code"
                        disabled={values.token === '' || !isValid}
                        processing={isSubmitting}
                        pageButton
                        isSubmit
                    />
                </form>
            )}
        </Formik>
    )
}

export default ConfirmTokenForm
