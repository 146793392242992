import cn from 'classnames'
import React from 'react'
import {accessibility} from '~/global/scss/helpers'
import styles from './ButtonAsLink.scss'

interface ButtonAsLinkProps {
    children: React.ReactChild
    dataTestId?: string
    className?: string
    onClick?: () => void
    inline?: boolean // allow the button to sit inline with other elements & left-align the text
    disabled?: boolean
    noTextDecoration?: boolean // remove text decoration, for use only on Card call to action, Carousel call to action, tabs and numbers
}

// ButtonAsLink is a button component to look like anchor text.
// It should be used when a function needs to be called on link-styled text
// rather than using a function on an anchor element.
export const ButtonAsLink = ({
    children,
    className,
    onClick,
    inline,
    disabled,
    noTextDecoration,
    dataTestId,
}: ButtonAsLinkProps) => (
    <button
        data-testid={dataTestId}
        type="button"
        className={cn(className, styles.buttonAsAnchorText, accessibility.button, {
            [styles.inline]: inline,
            [styles.disabled]: disabled,
            [styles.noTextDecoration]: noTextDecoration,
        })}
        onClick={onClick}
        disabled={disabled}
    >
        {children}
    </button>
)
