import React from 'react'
import {CheckableRoute} from '~/global/utils/routing/routing'
import {SetBaseFund} from '../sign-up/pages/set-base-fund/SetBaseFund'
import {ViewFund} from '../view-fund/ViewFund'
import {Home} from './Home'
import {planEditorHandle} from './hooks/usePlanEditorUrl'
import {AddFunds} from './pages/add-funds/AddFunds'
import {EditAllocations} from './pages/edit-allocations/EditAllocations'
import {Filter} from './pages/filter/Filter'
import {AcceptPDS} from './pages/pds/AcceptPDS'
import {RiskIndication} from './pages/risk-indication/RiskIndication'
import {SelfSelectIntroSlides} from './pages/self-select-slides/SelfSelectSlides'

export const EDIT_INVESTMENT_PLAN_ROUTES = [
    {
        path: 'edit-investment-plan',
        handle: planEditorHandle,
        children: [
            {index: true, Component: Home},
            {path: 'base', Component: SetBaseFund},
            {path: 'tips-for-choosing-picks', Component: SelfSelectIntroSlides},
            {path: 'add-funds', Component: AddFunds},
            {path: 'add-funds/filter', Component: Filter},
            {path: 'edit-allocations', Component: EditAllocations},
            {
                path: 'view-fund/:urlSlug',
                Component: ({urlSlug}: {urlSlug: string}) => (
                    <ViewFund viewFundMode="select" activeTab="Overview" urlSlug={urlSlug} />
                ),
            },
            {path: 'risk-indication', Component: RiskIndication},
            {path: 'pds', Component: AcceptPDS},
        ],
    },
] as const
EDIT_INVESTMENT_PLAN_ROUTES satisfies readonly CheckableRoute[]
