import {Button} from '@design-system/button'
import {Formik} from 'formik'
import React from 'react'
import {useNavigate} from 'react-router'
import {Link} from 'react-router-dom'
import {useRetailPost} from '~/api/query/retail'
import {Request} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {validate as v} from '~/global/widgets/form-controls'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {BasicsIntroSlides} from '~/sections/kiwisaver/sections/sign-up/pages/goals-and-values/slides/BasicsSlides'
import {FundTypesIntroSlides} from '~/sections/kiwisaver/sections/sign-up/pages/goals-and-values/slides/FundTypesSlides'
import {QuestionCardsList} from '~/sections/kiwisaver/sections/sign-up/widgets/question-cards-list/QuestionCardsList'
import {
    SignUpGuardRedirect,
    SignUpPage,
} from '~/sections/kiwisaver/sections/sign-up/widgets/redirect/SignUpGuardRedirect'
import {useAppSelector} from '~/store/hooks'
import {actingAsID as actingAsIDSelector} from '~/store/identity/selectors'
import styles from './GoalsAndValues.scss'

type KSGoalsValues = Request.KSGoalsValues
interface GoalsAndValuesValues {
    purpose?: KSGoalsValues['purpose'] | undefined
    interested_in?: KSGoalsValues['interested_in'] | undefined
    deposit_timeline?: KSGoalsValues['first_home_deposit_timeline'] | undefined
}

export const GoalsAndValues: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const actingAsID = useAppSelector(actingAsIDSelector)
    const setGoalsAndValues = useRetailPost({
        path: 'kiwisaver/set-goals-values',
        queryCacheToUpdate: ['kiwisaver/customer'],
    })
    const setStartOverSignup = useRetailPost({
        path: 'kiwisaver/start-over-sign-up',
        queryCacheToUpdate: ['kiwisaver/customer'],
    })
    const [purpose, setPurpose] = React.useState<KSGoalsValues['purpose'] | undefined>(undefined)
    const [fundType, setFundType] = React.useState<KSGoalsValues['interested_in'] | undefined>(undefined)
    const [houseDepositTimeline, setHouseDepositTimeline] = React.useState<
        KSGoalsValues['first_home_deposit_timeline'] | undefined
    >(undefined)
    const values = [purpose, houseDepositTimeline, fundType]
    const valueFunctions = [setPurpose, setFundType, setHouseDepositTimeline]
    const [activeStep, setActiveStep] = React.useState(0)
    const [moreInfo, setMoreInfo] = React.useState<boolean>(false)
    const isLastStep = activeStep === values.length - 1
    const activeSlide = activeStep === 0 ? 'scheme' : 'fund'

    const handleBack = async () => {
        if (activeStep > 0 && purpose === 'RETIREMENT') {
            valueFunctions.forEach(value => {
                value(undefined)
            })
            setActiveStep(0)
        } else if (activeStep > 0) {
            valueFunctions[activeStep](undefined)
            setActiveStep(activeStep - 1)
        } else {
            try {
                await setStartOverSignup.mutateAsync({
                    acting_as_id: actingAsID,
                })
                navigate(-1)
            } catch (e) {
                Toast('Please try again')
            }
        }
    }

    const advanceFormSteps = () => {
        if (purpose === 'RETIREMENT' && fundType === undefined && houseDepositTimeline === undefined) {
            setActiveStep(activeStep + 2)
        } else {
            setActiveStep(activeStep + 1)
        }
    }
    React.useEffect(() => {
        // Only need to send the purpose event as they move to select a fund type
        if (isLastStep && purpose) {
            rudderTrack('kiwisaver_signup', 'goals_values_clicked', {type: purpose})
        }
    }, [isLastStep, purpose])

    const renderFormQuestions = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <>
                        <QuestionCardsList
                            containerCSSName={styles.displayGrid}
                            titleText="Tell us a bit about yourself"
                            subtext="I’m looking to use my KiwiSaver balance for..."
                            selectCards={[
                                {cardText: 'First home deposit', cardValue: 'HOUSE_DEPOSIT'},
                                {cardText: 'Retirement', cardValue: 'RETIREMENT'},
                            ]}
                            setStateForQuestion={setPurpose}
                            activeValue={purpose!}
                        />
                        <p data-testid="content--education-pages">
                            New to KiwiSaver?{' '}
                            <Link to="" onClick={() => setMoreInfo(true)}>
                                Check out the basics
                            </Link>
                            .
                        </p>
                    </>
                )
            case 1:
                return (
                    <QuestionCardsList
                        containerCSSName={styles.displayGrid}
                        titleText="When are you aiming to buy a house?"
                        selectCards={[
                            {cardText: 'Within a year', cardValue: 'WITHIN_A_YEAR'},
                            {cardText: '1–4 years', cardValue: 'ONE_TO_FOUR_YEARS'},
                            {cardText: '5–10 years', cardValue: 'FIVE_TO_10_YEARS'},
                            {cardText: 'More than 10 years', cardValue: 'MORE_THAN_10_YEARS'},
                        ]}
                        shortCard={true}
                        setStateForQuestion={setHouseDepositTimeline}
                        activeValue={houseDepositTimeline!}
                    />
                )
            case 2:
                return (
                    <>
                        <QuestionCardsList
                            containerCSSName={styles.displayGrid}
                            titleText="I’m interested in ..."
                            selectCards={[
                                {cardText: 'Aggressive funds', cardValue: 'AGGRESSIVE'},
                                {cardText: 'Growth funds', cardValue: 'GROWTH'},
                                {cardText: 'Balanced funds', cardValue: 'BALANCED'},
                                {cardText: 'Conservative funds', cardValue: 'CONSERVATIVE'},
                            ]}
                            cardSupportingText={[
                                'Higher risk, potentially higher returns',
                                'High risk, potentially high returns',
                                'Medium risk, potentially medium returns',
                                'Lower risk, potentially lower returns',
                            ]}
                            setStateForQuestion={setFundType}
                            activeValue={fundType!}
                        />
                        <p data-testid="content--education-pages">
                            <Link to="" onClick={() => setMoreInfo(true)}>
                                Tell me about these funds
                            </Link>
                            .
                        </p>
                    </>
                )
            default:
                return <WeSlippedUp />
        }
    }

    if (moreInfo) {
        return (
            <SignUpGuardRedirect currentPage={SignUpPage.GOALS_AND_VALUES}>
                {activeSlide === 'scheme' && (
                    <BasicsIntroSlides onComplete={() => setMoreInfo(false)} onBack={() => setMoreInfo(false)} />
                )}
                {activeSlide === 'fund' && (
                    <FundTypesIntroSlides onComplete={() => setMoreInfo(false)} onBack={() => setMoreInfo(false)} />
                )}
            </SignUpGuardRedirect>
        )
    }

    return (
        <SignUpGuardRedirect currentPage={SignUpPage.GOALS_AND_VALUES}>
            <Toolbar dataTestId="toolbar--goals-and-values" leftButton="back" onLeftButtonClick={handleBack} />
            <Formik
                enableReinitialize
                initialValues={{
                    purpose,
                    interested_in: fundType,
                    deposit_timeline: houseDepositTimeline,
                }}
                validate={v.generate<GoalsAndValuesValues>({
                    purpose: [v.required('Select a purpose for your KiwiSaver ')],
                    interested_in: [v.required('Select a type of fund you are interested in')],
                })}
                onSubmit={async (_, {setSubmitting}) => {
                    if (purpose === undefined || fundType === undefined) {
                        // we can't actually reach this due to validation but it keeps TS happy
                        return
                    }
                    try {
                        await setGoalsAndValues.mutateAsync({
                            acting_as_id: actingAsID,
                            purpose,
                            interested_in: fundType,
                            first_home_deposit_timeline: houseDepositTimeline,
                            values: [],
                        })
                        rudderTrack('kiwisaver_signup', 'fund_type_clicked', {type: fundType})
                        navigate(profileUrl('kiwisaver/sign-up/edit-investment-plan/base'))
                    } catch (e) {
                        Toast('Please try again')
                    } finally {
                        setSubmitting(false)
                    }
                }}
            >
                {formik => (
                    <form
                        onSubmit={e => {
                            if (formik.isValid) {
                                formik.handleSubmit(e)
                            }
                        }}
                    >
                        <Page>{renderFormQuestions(activeStep)}</Page>
                        <ActionBar>
                            {isLastStep ? (
                                <Button
                                    dataTestId="button--next"
                                    label="Next"
                                    type="primary"
                                    isSubmit
                                    processing={formik.isSubmitting}
                                    disabled={values[activeStep] === undefined}
                                />
                            ) : (
                                <Button
                                    dataTestId="button--next"
                                    label="Next"
                                    type="primary"
                                    onClick={advanceFormSteps}
                                    processing={formik.isSubmitting}
                                    disabled={values[activeStep] === undefined}
                                />
                            )}
                        </ActionBar>
                    </form>
                )}
            </Formik>
        </SignUpGuardRedirect>
    )
}
