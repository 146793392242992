import * as api from '~/api/retail'
import {Response, Request} from '~/api/retail/types'
import * as rollbar from '~/api/rollbar/rollbar'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {
    APINetworkError,
    errorResponseFactory,
    NonRollbarError,
    UserSuitableError,
} from '~/global/utils/error-handling/errorHandling'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {Toast} from '~/global/widgets/toast/Toast'
import {reauthenticateReturn} from '~/global/wrappers/global-wrapper-widgets/reauthenticate/Reauthenticate'
import identityActions from '../identity/actions'
import {actingAsID} from '../identity/selectors'
import {ActionsUnion, createAction} from '../redux-tools'
import {ThunkAction} from '../types'
import {RequestState, State, BasiqReferrer} from './types'

const actions = {
    SetBankLinkingLoadingState: (loadingState: State['bankLinkingLoadingState']) =>
        createAction('bankLinking.SetBankLinkingLoadingState', loadingState),
    SetBankLinkingStatus: (payload: Response.BankLinkingStatus) =>
        createAction('bankLinking.SetBankLinkingStatus', payload),
    SetBankLinkingPending: (payload: Response.BankLinkingPending) =>
        createAction('bankLinking.SetBankLinkingPending', payload),
    SetRoundupsEnabled: (payload: boolean) => createAction('bankLinking.SetRoundupsEnabled', payload),
    SetWholeDollar: (payload: boolean) => createAction('bankLinking.SetWholeDollar', payload),
    SetDepositTarget: (payload: string) => createAction('bankLinking.SetDepositTarget', payload),
    SetBasiqAccountsLoadingState: (loadingState: State['basiqAccountsLoadingState']) =>
        createAction('bankLinking.SetBasiqAccountsLoadingState', loadingState),
    SetBasiqAccounts: (payload: Response.BankLinkingBasiqAccounts) =>
        createAction('bankLinking.SetBasiqAccounts', payload),
    InvalidateBankLinkingState: () => createAction('bankLinking.InvalidateBankLinkingState'),
    InvalidatePendingState: () => createAction('bankLinking.InvalidatePendingState'),
    SetBlinkPayOptions: (payload: Response.BlinkPayOptions) => createAction('bankLinking.SetBlinkPayOptions', payload),
}

const thunkActions = {
    FetchBankLinkingStatus(): ThunkAction<
        Promise<Response.BankLinkingStatus | Response.BankLinkingPending | undefined>
    > {
        return async (dispatch, getState) => {
            try {
                dispatch(actions.SetBankLinkingLoadingState('loading'))
                const response = await api.get('banklinking/status', {
                    acting_as_id: actingAsID(getState()),
                })
                if (response.type === 'bank_linking_status') {
                    dispatch(actions.SetBankLinkingStatus(response))
                } else if (response.type === 'bank_linking_pending') {
                    dispatch(actions.SetBankLinkingLoadingState('error'))
                    dispatch(actions.SetBankLinkingPending(response))
                } else if (response.type === 'error' || response.type === 'internal_server_error') {
                    throw new APINetworkError(unknownErrorMessage)
                }
                return response
            } catch (e) {
                dispatch(actions.SetBankLinkingLoadingState('error'))
                if (
                    e instanceof UserSuitableError ||
                    e instanceof APINetworkError ||
                    e instanceof NonRollbarError ||
                    e instanceof Error
                ) {
                    rollbar.sendError(e.message, {
                        statusText: e.message,
                        method: 'FetchBankLinkingStatus',
                    })
                }
            }
        }
    },
    GetConsentURL({
        internalReferrer,
        action,
    }: {
        internalReferrer?: BasiqReferrer
        action?: 'request-new-consent'
    }): ThunkAction<Promise<string | undefined>> {
        return async (dispatch, getState) => {
            try {
                const response = await api.get('banklinking/consent', {
                    acting_as_id: actingAsID(getState()),
                    request_new_account: action === 'request-new-consent',
                })

                if (response.type in ['error', 'internal_server_error']) {
                    throw new APINetworkError(unknownErrorMessage)
                } else if (response.type === 'consent_url') {
                    const consentUrl = new URL(response.consent_url)

                    if (internalReferrer) {
                        consentUrl.searchParams.set('state', internalReferrer)
                    }
                    return consentUrl.toString()
                }
                return
            } catch (e) {
                dispatch(actions.SetBankLinkingLoadingState('error'))
                if (
                    e instanceof UserSuitableError ||
                    e instanceof APINetworkError ||
                    e instanceof NonRollbarError ||
                    e instanceof Error
                ) {
                    rollbar.sendError(e.message, {
                        statusText: e.message,
                        method: 'GetConsentURL',
                    })
                }
            }
        }
    },
    UpdateRoundupsEnabled(enabled: boolean, debit_agreement?: string): ThunkAction<Promise<Response.Error | void>> {
        return async (dispatch, getState) => {
            try {
                const response = await api.post('banklinking/update-roundups', {
                    acting_as_id: actingAsID(getState()),
                    set_enabled: enabled,
                    debit_agreement,
                })

                if (response.type === 'empty') {
                    dispatch(actions.SetRoundupsEnabled(enabled))
                    return
                } else if (response.type === 'authentication_update_required') {
                    return reauthenticateReturn(
                        () => thunkActions.UpdateRoundupsEnabled(enabled, debit_agreement)(dispatch, getState),
                        () => errorResponseFactory('Please try again'),
                        () => errorResponseFactory('You need to supply your password to continue'),
                    )
                } else if (response.type in ['error', 'internal_server_error']) {
                    throw new APINetworkError(unknownErrorMessage)
                }
            } catch (e) {
                dispatch(actions.SetBankLinkingLoadingState('error'))
                if (
                    e instanceof UserSuitableError ||
                    e instanceof APINetworkError ||
                    e instanceof NonRollbarError ||
                    e instanceof Error
                ) {
                    rollbar.sendError(e.message, {
                        statusText: e.message,
                        method: 'UpdateRoundupsEnabled',
                    })
                }
            }
        }
    },
    FetchBasiqAccounts(): ThunkAction<Promise<Response.Error | void | string>> {
        return async (dispatch, getState) => {
            try {
                dispatch(actions.SetBasiqAccountsLoadingState('loading'))
                const response = await api.get('banklinking/fetch-basiq-accounts', {
                    acting_as_id: actingAsID(getState()),
                })
                if (response.type === 'bank_linking_basiq_accounts') {
                    dispatch(actions.SetBasiqAccounts(response))
                    return response.type
                } else if (response.type === 'error') {
                    return response
                } else if (response.type === 'internal_server_error') {
                    throw new APINetworkError(unknownErrorMessage)
                } else {
                    assertNever(response)
                }
            } catch (e) {
                dispatch(actions.SetBankLinkingLoadingState('error'))
                if (
                    e instanceof UserSuitableError ||
                    e instanceof APINetworkError ||
                    e instanceof NonRollbarError ||
                    e instanceof Error
                ) {
                    rollbar.sendError(e.message, {
                        statusText: e.message,
                        method: 'FetchBasiqAccounts',
                    })
                }
            }
        }
    },
    UpdateDebitBankAccount(
        basiqAccountId: string,
        debit_agreement: string,
    ): ThunkAction<Promise<Response.Error | Response.BankLinkingStatus>> {
        return async (dispatch, getState) => {
            try {
                const response = await api.post('banklinking/update-selected-bank-account', {
                    acting_as_id: actingAsID(getState()),
                    basiq_account_ids: [basiqAccountId],
                    purpose: 'debit',
                    debit_agreement,
                })

                if (response.type === 'bank_linking_status') {
                    dispatch(actions.SetBankLinkingStatus(response))
                    return response
                } else if (response.type === 'authentication_update_required') {
                    return reauthenticateReturn(
                        () => thunkActions.UpdateDebitBankAccount(basiqAccountId, debit_agreement)(dispatch, getState),
                        () => errorResponseFactory('Please try again'),
                        () => errorResponseFactory('You need to supply your password to continue'),
                    )
                } else if (response.type === 'error') {
                    return response
                } else if (response.type === 'internal_server_error') {
                    throw new APINetworkError(unknownErrorMessage)
                } else {
                    assertNever(response)
                }
                return errorResponseFactory(unknownErrorMessage, 'internal_error')
            } catch (e) {
                dispatch(actions.SetBankLinkingLoadingState('error'))
                if (e instanceof APINetworkError || e instanceof NonRollbarError || e instanceof Error) {
                    rollbar.sendError(e.message, {
                        statusText: e.message,
                        method: 'UpdateDebitBankAccount',
                    })
                }
                return errorResponseFactory(unknownErrorMessage, 'internal_error')
            }
        }
    },
    UpdateMonitoringBankAccounts(bankAccountIds: string[]): ThunkAction<Promise<Response.Error | void>> {
        return async (dispatch, getState) => {
            try {
                const response = await api.post('banklinking/update-selected-bank-account', {
                    acting_as_id: actingAsID(getState()),
                    basiq_account_ids: bankAccountIds,
                    purpose: 'monitoring',
                })
                if (response.type === 'authentication_update_required') {
                    return reauthenticateReturn(
                        () => thunkActions.UpdateMonitoringBankAccounts(bankAccountIds)(dispatch, getState),
                        () => errorResponseFactory('Please try again'),
                        () => errorResponseFactory('You need to supply your password to continue'),
                    )
                } else if (response.type === 'bank_linking_status') {
                    dispatch(actions.SetBankLinkingStatus(response))
                    return
                } else if (response.type === 'error') {
                    return {message: response.message, type: 'error', code: 'error'}
                } else if (response.type === 'internal_server_error') {
                    return {message: unknownErrorMessage, type: 'error', code: 'error'}
                } else {
                    assertNever(response)
                }
            } catch (e) {
                dispatch(actions.SetBankLinkingLoadingState('error'))
                if (e instanceof APINetworkError || e instanceof NonRollbarError || e instanceof Error) {
                    rollbar.sendError(e.message, {
                        statusText: e.message,
                        method: 'UpdateMonitoringBankAccount',
                    })
                }
            }
        }
    },
    UnlinkBank(): ThunkAction<Promise<Response.Error | void>> {
        return async (dispatch, getState) => {
            try {
                Toast('Your bank linking has been disconnected.')
                const response = await api.post('banklinking/unlink-bank', {
                    acting_as_id: actingAsID(getState()),
                })
                if (response.type === 'empty') {
                    dispatch(actions.InvalidateBankLinkingState())
                    return
                } else if (response.type === 'authentication_update_required') {
                    return reauthenticateReturn(
                        () => thunkActions.UnlinkBank()(dispatch, getState),
                        () => errorResponseFactory('Please try again'),
                        () => errorResponseFactory('You need to supply your password to continue'),
                    )
                } else if (response.type === 'error' || response.type === 'internal_server_error') {
                    throw new APINetworkError(unknownErrorMessage)
                } else {
                    assertNever(response)
                }
            } catch (e) {
                dispatch(actions.SetBankLinkingLoadingState('error'))
                if (
                    e instanceof UserSuitableError ||
                    e instanceof APINetworkError ||
                    e instanceof NonRollbarError ||
                    e instanceof Error
                ) {
                    rollbar.sendError(e.message, {
                        statusText: e.message,
                        method: 'UnlinkBank',
                    })
                }
            }
        }
    },
    GetBlinkPayOptions(): ThunkAction<Promise<void>> {
        return async (dispatch, _getState) => {
            try {
                const response = await api.get('blinkpay/options', {})
                switch (response.type) {
                    case 'blinkpay_options':
                        dispatch(actions.SetBlinkPayOptions(response))
                        break
                    case 'internal_server_error':
                    case 'access_denied':
                        throw new APINetworkError(unknownErrorMessage)
                    default:
                        assertNever(response)
                }
            } catch (e) {
                // TODO: something
            }
        }
    },
    InitiateBlinkPayPayment(
        amount: string,
        bank: Request.BlinkPayPaymentRequest['bank'],
        fee: Request.BlinkPayPaymentRequest['fee'],
    ): ThunkAction<Promise<Response.Error | Response.BlinkPayPaymentRedirect | void>> {
        return async (dispatch, getState) => {
            try {
                const response = await api.post('blinkpay/payment-request', {
                    acting_as_id: actingAsID(getState()),
                    amount,
                    bank,
                    fee,
                })

                switch (response.type) {
                    case 'blinkpay_payment_redirect':
                        return response
                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () => thunkActions.InitiateBlinkPayPayment(amount, bank, fee)(dispatch, getState),
                            () => errorResponseFactory('Please try again'),
                            () => errorResponseFactory('You need to supply your password to continue'),
                        )
                    case 'error':
                    case 'internal_server_error':
                    case 'access_denied':
                        throw new APINetworkError(unknownErrorMessage)
                    default:
                        assertNever(response)
                }
            } catch (e) {
                // TODO: something
            }
        }
    },
    NotifyBlinkPayResponse(
        requestState: RequestState,
        quickPaymentId: string,
    ): ThunkAction<Promise<Response.Error | Response.Empty | Response.AccessDenied | void>> {
        return async (dispatch, getState) => {
            try {
                const response = await api.post('blinkpay/payment-notify', {
                    acting_as_id: actingAsID(getState()),
                    request_state: requestState,
                    quick_payment_id: quickPaymentId,
                })

                switch (response.type) {
                    case 'identity_authenticated':
                        rudderTrack('topup', 'instant_bank_topped_up')
                        dispatch(identityActions.handleIdentityResponse(response))
                        Toast('You’re all topped up!')
                        break

                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () => thunkActions.NotifyBlinkPayResponse(requestState, quickPaymentId)(dispatch, getState),
                            () => errorResponseFactory('Please try again'),
                            () => errorResponseFactory('You need to supply your password to continue'),
                        )
                    case 'error':
                        return response
                    case 'empty':
                        break
                    case 'internal_server_error':
                    case 'access_denied':
                        throw new APINetworkError(unknownErrorMessage)
                    default:
                        assertNever(response)
                }
            } catch (e) {
                // TODO: something
            }
        }
    },
}

export type ActionsType = ActionsUnion<typeof actions>

export default {...actions, ...thunkActions}
