import React from 'react'
import {Response} from '~/api/retail/types'
import {PriceNotificationModal} from '~/sections/invest/widgets/price-notification-modal/PriceNotificationModal'
import styles from '~/sections/user/sections/settings/sections/notifications/pages/price-notifications/PriceNotifications.scss'
import {Instrument} from '~/store/instrument/types'

interface EditNotificationProps {
    instrument: Instrument
    portfolioId: string
    priceNotification?: Response.PriceNotifications['price_notifications'][0]
}

export const EditNotification: React.FunctionComponent<EditNotificationProps> = ({
    instrument,
    portfolioId,
    priceNotification,
}) => {
    const [showPriceNotificationModal, setShowPriceNotificationModal] = React.useState(false)
    return (
        <>
            <a
                href="#"
                data-testid={`price-notification--${instrument.id}-link`}
                onClick={e => {
                    e.preventDefault()
                    setShowPriceNotificationModal(true)
                }}
                className={styles.modalLink}
            >
                {priceNotification ? 'Edit' : 'Add'}
            </a>
            <PriceNotificationModal
                isOpen={showPriceNotificationModal}
                onClose={() => setShowPriceNotificationModal(false)}
                instrumentId={instrument.id}
                instrumentName={instrument.name}
                instrumentValue={instrument.marketPrice}
                instrumentCurrency={instrument.currency}
                instrumentSymbol={instrument.symbol}
                hasPriceNotification={priceNotification ? true : false}
                initialPriceNotificationAmount={priceNotification?.price_threshold}
                pageSource="instrument_page"
                extendedHoursInEffect={false}
                hideSeeAllLink
                portfolioId={portfolioId}
            />
        </>
    )
}
