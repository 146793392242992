import {Button} from '@design-system/button'
import cn from 'classnames'
import {Formik, FormikValues} from 'formik'
import {useAtom} from 'jotai'
import React from 'react'
import {Request} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {
    NatureAndPurposeStep,
    annualAmountChoices,
    frequencyChoices,
    purposesChoices,
} from '~/global/utils/nature-and-purpose/natureAndPurpose'
import {validate} from '~/global/widgets/form-controls'
import {CheckboxGroup, Radio, Text} from '~/global/widgets/form-controls/formik'
import {stagedFonterraPersonalSignUpAtom} from '~/sections/moose/sections/fonterra-personal-sign-up/state'
import styles from '../../NatureAndPurpose.scss'

interface FormProps {
    step: NatureAndPurposeStep
    setStep(value: NatureAndPurposeStep): void
    getNextStep(value: NatureAndPurposeStep): NatureAndPurposeStep
    onSubmit: (values: FormikValues) => void
}

export interface NatureAndPurposeFormValues {
    frequency: Request.NatureAndPurposeUpdate['frequency']
    annualAmount: Request.NatureAndPurposeUpdate['annual_amount']
    purposes: Request.NatureAndPurposeUpdate['purposes']
    otherPurpose: string
}

const NatureAndPurposeForm = ({step, setStep, getNextStep, onSubmit}: FormProps) => {
    const [stagedFonterraPersonalSignUp] = useAtom(stagedFonterraPersonalSignUpAtom)

    const initialValues = stagedFonterraPersonalSignUp.natureAndPurpose
        ? {
              frequency: stagedFonterraPersonalSignUp.natureAndPurpose.frequency,
              annualAmount: stagedFonterraPersonalSignUp.natureAndPurpose.annualAmount,
              purposes: stagedFonterraPersonalSignUp.natureAndPurpose.purposes,
              otherPurpose: stagedFonterraPersonalSignUp.natureAndPurpose.otherPurpose,
          }
        : {frequency: undefined, annualAmount: undefined, purposes: undefined, otherPurpose: undefined}

    const nextIsDisabled = (values: FormikValues) => {
        switch (step) {
            case 'frequency':
                return values.frequency === undefined
            case 'annualAmount':
                return values.annualAmount === undefined
            case 'purpose':
                // disabled when other purpose is not defined but other is selected
                if (values.purposes && values.purposes.includes('other')) {
                    return values.otherPurpose === undefined || values.otherPurpose === ''
                }
                return !values.purposes?.length
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            initialErrors={
                stagedFonterraPersonalSignUp.natureAndPurpose
                    ? undefined
                    : {frequency: undefined, annualAmount: undefined, purposes: undefined, otherPurpose: ''}
            }
            onSubmit={values => {
                if (onSubmit) {
                    onSubmit(values)
                }
            }}
            validate={values => {
                return validate.generate<typeof values>({
                    frequency: [validate.required()],
                    annualAmount: [validate.required()],
                    purposes: [
                        (purposes: string[]) => {
                            if (purposes?.length === 0) {
                                return 'This field is required'
                            }
                        },
                    ],
                    otherPurpose:
                        values.purposes && values.purposes.includes('other')
                            ? [validate.required(), validate.minLength(5, 'Please provide a full description')]
                            : [],
                })(values)
            }}
        >
            {({values, isSubmitting, handleSubmit}) => {
                return (
                    <form onSubmit={handleSubmit} className={styles.natureAndPurposeForm}>
                        {step === 'frequency' && (
                            <>
                                <h1 className={spacing.spaceBelow24}>
                                    How frequently are you looking to put money into Sharesies?
                                </h1>

                                <Radio
                                    dataTestId="radio--frequency"
                                    name="frequency"
                                    choices={frequencyChoices}
                                    labelClassName={styles.titleLabel}
                                />
                            </>
                        )}
                        {step === 'annualAmount' && (
                            <>
                                <h1 className={spacing.spaceBelow24}>
                                    How much do you expect to put into Sharesies each year?
                                </h1>

                                <Radio
                                    dataTestId="radio--annual-amount"
                                    name="annualAmount"
                                    choices={annualAmountChoices}
                                    labelClassName={styles.titleLabel}
                                />
                            </>
                        )}
                        {step === 'purpose' && (
                            <>
                                <h1 className={spacing.spaceBelow24}>
                                    What are your main reasons for using Sharesies?
                                </h1>

                                <CheckboxGroup name="purposes" choices={purposesChoices} />
                                <p className={cn(styles.info, spacing.spaceBelow8)}>
                                    You'll need to give an appropriate reason for why you’re using Sharesies
                                </p>
                                {values.purposes && (values.purposes as string[]).includes('other') && (
                                    <>
                                        <Text
                                            dataTestId="text-input--description"
                                            name="otherPurpose"
                                            label="I'm using Sharesies because..."
                                            placeholder="Description"
                                        />
                                    </>
                                )}
                            </>
                        )}
                        {step === 'purpose' ? (
                            <Button
                                dataTestId="button--next"
                                label="Next"
                                disabled={nextIsDisabled(values)}
                                isSubmit
                                processing={isSubmitting}
                            />
                        ) : (
                            <Button
                                label="Next"
                                dataTestId="button--next"
                                disabled={nextIsDisabled(values)}
                                pageButton
                                onClick={() => setStep(getNextStep(step))}
                            />
                        )}
                    </form>
                )
            }}
        </Formik>
    )
}

export default NatureAndPurposeForm
