import {formatNumberWithThousandsSeparator} from '~/global/utils/format-number/formatNumber'

/*
Use NumberElements component when we display numbers
Use functions when we calculate numbers
*/

// This formatDollar function is deprecated
// Do not use this function for new code

// To display normal dollar value:
// use the DollarValue component from the NumberElements file
// To display wallet value:
// use the WalletValue component from the NumberElements file
// To display estimated wallet balance:
// use the EstimatedWalletBalance component from the EstimatedWalletBalance file
export const formatDollar = (value: string | number, explicitPlus: boolean, decimalPlaces?: number): string => {
    const v = formatNumberWithThousandsSeparator(Number(value).toFixed(decimalPlaces === undefined ? 2 : decimalPlaces))

    if (v === '0.00') {
        return '$0.00'
    }

    if (+value < 0) {
        return `-$${v.substring(1)}`
    }

    return (explicitPlus ? '+' : '') + '$' + v
}
