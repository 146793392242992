import React from 'react'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {isInstrumentDerivative} from '~/global/utils/is-instrument-derivative/isInstrumentDerivative'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {Instrument} from '~/store/instrument/types'

const FeesUSAShares = () => (
    <p>
        <strong>US shares</strong>—the transaction fee is{' '}
        <strong>
            capped at <DollarValue value={5} currency="usd" decimalPlaces={0} />
        </strong>{' '}
        for amounts invested (or received from selling shares) over{' '}
        <DollarValue value={264} currency="usd" decimalPlaces={0} />.
    </p>
)

const FeesNewZealandShares: React.FunctionComponent = () => (
    <p>
        <strong>NZ shares</strong>—the transaction fee is{' '}
        <strong>
            capped at <DollarValue value={25} currency="nzd" decimalPlaces={0} />
        </strong>{' '}
        for amounts invested (or received from selling shares) over{' '}
        <DollarValue value={1316} currency="nzd" decimalPlaces={0} />.
    </p>
)

interface AustralianSharesProps {
    jurisdiction: Model.User['jurisdiction']
}

const FeesAustralianShares = ({jurisdiction}: AustralianSharesProps) => {
    const feeCap = jurisdiction === 'au' ? 6 : 15
    const capAppliedAtValue = jurisdiction === 'au' ? 316 : 805

    return (
        <p>
            <strong>Australian shares</strong>—the transaction fee is{' '}
            <strong>
                capped at <DollarValue value={feeCap} currency="aud" decimalPlaces={0} />
            </strong>{' '}
            for amounts invested (or received from selling shares) over{' '}
            <DollarValue value={capAppliedAtValue} currency="aud" decimalPlaces={0} />.
        </p>
    )
}

interface Props {
    jurisdiction: Model.User['jurisdiction']
    instrument?: Instrument
}

const FeeModalInfo = ({jurisdiction, instrument}: Props) => (
    <>
        <h2 className={spacing.spaceBelow12}>Transaction fee</h2>
        <p>
            Individual buy, sell, and auto-invest orders for US,{' '}
            {jurisdiction === 'au' ? 'Australian, and NZ' : 'NZ, and Australian'} shares have a 1.9% transaction fee on
            the amount invested up to a capped amount.
        </p>
        <FeesUSAShares />
        {/* Show user's jurisdiction info first */}
        {jurisdiction === 'au' ? (
            <>
                <FeesAustralianShares jurisdiction={jurisdiction} />
                <FeesNewZealandShares />
            </>
        ) : (
            <>
                <FeesNewZealandShares />
                <FeesAustralianShares jurisdiction={jurisdiction} />
            </>
        )}
        <p>
            The transaction fee is charged in the currency of your order—currency exchange fees are charged separately.
        </p>

        {jurisdiction === 'nz' && (
            <p>Orders for managed funds (funds not listed on an exchange) aren’t charged our transaction fee.</p>
        )}
        {instrument && isInstrumentDerivative(instrument) && (
            <p>You won't be charged our transaction fee to exercise {shareLabel({instrument, isPlural: true})}.</p>
        )}
        <p>
            <a
                target="_blank"
                rel="noopener"
                href={
                    jurisdiction === 'au'
                        ? 'https://www.sharesies.com.au/pricing#how-we-calculate-transaction-fees'
                        : 'https://www.sharesies.nz/pricing#how-we-calculate-transaction-fees'
                }
            >
                See how we calculate transaction fees for different order types
            </a>
        </p>
    </>
)

export default FeeModalInfo
