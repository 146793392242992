import {Button} from '@design-system/button'
import {CopyField} from '@design-system/copy-field'
import cn from 'classnames'
import React from 'react'
import {Model} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {useNzStandardInvestor} from '~/global/utils/use-nz-standard-investor/useNzStandardInvestor'
import AccountVerificationRequired from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {connect} from '~/store/connect'

export const TopUpWithBankDeposit: React.FC<StoreProps> = React.memo(
    ({accountReference, isDependent, preferredName, jurisdiction, accountRestricted}) => {
        const navigate = useNavigate()
        const isNzStandardInvestor = useNzStandardInvestor()

        return (
            <>
                <Toolbar dataTestId="toolbar--top-up-with-bank-deposit" leftButton="back" title="Top up Wallet" />
                <Page>
                    <AccountVerificationRequired />
                    <h1 className={spacing.spaceBelow12}>Top up with bank transfer</h1>
                    <p className={spacing.spaceBelow16}>
                        {isNzStandardInvestor
                            ? 'Copy the following details to your online banking to set up a one-off bank transfer or automatic payment.'
                            : `Top up ${isDependent ? `${preferredName}’s` : 'your'} Wallet with money from your bank
                            account. Use the following details to set up a one-off or automatic payment in your online
                            banking. You can only top up ${isDependent ? `${preferredName}’s` : 'your'} Wallet in ${
                                jurisdiction === 'au' ? 'AUD' : 'NZD'
                            }.`}
                    </p>
                    {!accountRestricted && (
                        <>
                            <AlertCard
                                className={spacing.spaceBelow24}
                                type="none"
                                title="Transfer times"
                                text={
                                    isNzStandardInvestor
                                        ? 'A bank transfer can take a few hours on weekdays, or a few days over weekends and public holidays.'
                                        : 'Topping up with a bank transfer can take one to two working days. We’re working to make this faster in the future.'
                                }
                            />

                            <h2 className={cn(spacing.spaceBelow32)}>
                                Copy these details{isNzStandardInvestor && ' to top up in NZD'}:
                            </h2>
                            <Fields accountReference={accountReference} jurisdiction={jurisdiction} />

                            {isNzStandardInvestor && (
                                <p>
                                    For details on how to top up your Wallet in USD or AUD, check out our{' '}
                                    <a
                                        href="https://intercom.help/sharesies/en/articles/756096-top-up-your-wallet"
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        help centre
                                    </a>
                                    .
                                </p>
                            )}
                        </>
                    )}

                    <Button dataTestId="button--go-back" label="Go back" onClick={() => navigate(-1)} pageButton />
                </Page>
            </>
        )
    },
)

interface FieldProps {
    accountReference: string
    jurisdiction: Model.User['jurisdiction']
}

export const Fields: React.FC<FieldProps> = React.memo(({jurisdiction, accountReference}) => {
    return (
        <>
            <CopyField
                additionalClassName={spacing.spaceBelow24}
                id="top-up-account-reference"
                dataTestId="copy-field--top-up-account-reference"
                label="Reference"
                value={accountReference}
                onClick={() => {
                    rudderTrack('topup', 'bank_reference_copied')
                }}
            />
            {jurisdiction === 'nz' && <NZFields />}
            {jurisdiction === 'au' && <AUFields />}
        </>
    )
})

export const NZFields: React.FC = React.memo(() => {
    return (
        <>
            <CopyField
                additionalClassName={spacing.spaceBelow24}
                id="top-up-payee-name"
                dataTestId="copy-field--payee-name"
                label="Payee name"
                value="Sharesies NZ"
            />
            <CopyField
                additionalClassName={spacing.spaceBelow24}
                id="top-up-account-number"
                dataTestId="copy-field--account-number"
                label="Account number"
                value="12-3497-0007278-01"
                onClick={() => {
                    rudderTrack('topup', 'bank_account_copied')
                }}
            />
        </>
    )
})

export const AUFields: React.FC = React.memo(() => {
    return (
        <>
            <CopyField
                additionalClassName={spacing.spaceBelow24}
                id="top-up-account-name"
                dataTestId="copy-field--account-name"
                label="Account name"
                value="Sharesies AU"
            />
            <CopyField
                additionalClassName={spacing.spaceBelow24}
                id="top-up-bsb-number"
                dataTestId="copy-field--bsb-number"
                label="BSB number"
                value="012-003"
            />
            <CopyField
                additionalClassName={spacing.spaceBelow24}
                id="top-up-account-number"
                dataTestId="copy-field--account-number"
                label="Account number"
                value="838291024"
                onClick={() => {
                    rudderTrack('topup', 'bank_account_copied')
                }}
            />
        </>
    )
})

interface StoreProps {
    accountReference: string
    isDependent: boolean
    preferredName: string
    jurisdiction: Model.User['jurisdiction']
    accountRestricted: boolean
}

export default connect<StoreProps, {}>(state => ({
    accountReference: state.identity.user!.account_reference!,
    isDependent: state.identity.user!.is_dependent,
    preferredName: state.identity.user!.preferred_name,
    jurisdiction: state.identity.user!.jurisdiction,
    accountRestricted: state.identity.user!.account_restricted,
}))(TopUpWithBankDeposit)
