import React from 'react'
import {Link} from 'react-router-dom'
import {AppSchemasForClientPortfolioPortfolio} from '~/api/rakaia'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Concertina from '~/global/widgets/concertina/Concertina'
import styles from '~/global/widgets/detail-modals/DetailModalContents.scss'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'

interface TotalReturnModalProps {
    hasADRs: boolean
    hasForeignCurrency: boolean
    hasManagedFunds: boolean
    homeCurrency: Model.User['home_currency']
    includeSoldInvestments: boolean
    isDependent: boolean
    preferredName: string
    portfolioSummary: AppSchemasForClientPortfolioPortfolio
}

const TotalReturnModal: React.FunctionComponent<TotalReturnModalProps> = ({
    hasADRs,
    hasForeignCurrency,
    hasManagedFunds,
    homeCurrency,
    includeSoldInvestments,
    isDependent,
    preferredName,
    portfolioSummary,
}) => {
    const adrFees = portfolioSummary.adr_fees || 0
    const dividends = portfolioSummary.dividends || 0
    const pieTax = portfolioSummary.tax_paid || 0
    const realisedCapitalGain = portfolioSummary.realised_capital_gain || 0
    const realisedCurrencyGain = portfolioSummary.realised_currency_gain || 0
    const managedFundTransactionFees = portfolioSummary.managed_fund_transaction_fees || 0
    const totalReturn = portfolioSummary.total_return || 0
    const transactionFees = portfolioSummary.transaction_fees || 0
    const unrealisedAdrFees = portfolioSummary.unrealised_adr_fees || 0
    const unrealisedCapitalGain = portfolioSummary.unrealised_capital_gain || 0
    const unrealisedCurrencyGain = portfolioSummary.unrealised_currency_gain || 0
    const unrealisedDividends = portfolioSummary.unrealised_dividends || 0
    const unrealisedManagedFundTransactionFees = portfolioSummary.unrealised_managed_fund_transaction_fees || 0
    const unrealisedPieTax = portfolioSummary.unrealised_tax_paid || 0
    const unrealisedTotalReturn = portfolioSummary.unrealised_total_return || 0
    const unrealisedTransactionFees = portfolioSummary.unrealised_total_transaction_fees || 0

    return (
        <div>
            <h1>{includeSoldInvestments ? 'Total return' : 'Return'}</h1>
            <p className={spacing.spaceBelow16}>
                There are many ways to work out returns. These calculations should not be relied upon to make investment
                decisions, they are indicative only and accuracy is not guaranteed.
            </p>
            <p>Past performance does not guarantee future results.</p>
            <p>
                {isDependent ? `${preferredName}’s` : 'Your'}
                {includeSoldInvestments && ' total'} return is a measure of {isDependent ? 'their' : 'your'} Portfolio’s
                performance.
            </p>
            <p>
                This calculation {!includeSoldInvestments && ' only'} includes investments{' '}
                {isDependent ? 'they' : 'you'} currently own
                {includeSoldInvestments &&
                    ` and any
                ${isDependent ? 'they’ve' : 'you’ve'} sold`}
                .
            </p>
            {hasManagedFunds && (
                <p>This doesn’t include management fees charged by managed fund providers and some tax.</p>
            )}
            <Concertina
                title={
                    <div className={styles.concertinaTitle}>
                        <p className={styles.leftText}>Unrealised gains/losses</p>{' '}
                        <DollarValue value={unrealisedCapitalGain} currency={homeCurrency} />
                    </div>
                }
                body={
                    <p className={styles.concertinaBody}>
                        The change in value of {isDependent ? `${preferredName}’s` : 'your'} investments. This is based
                        on the difference between the prices {isDependent ? `${preferredName}` : 'you'} paid and the
                        current prices (delayed by at least 20 mins).
                    </p>
                }
                border
            />
            {includeSoldInvestments && (
                <Concertina
                    title={
                        <div className={styles.concertinaTitle}>
                            <p className={styles.leftText}>Realised gains/losses</p>{' '}
                            <DollarValue value={realisedCapitalGain} currency={homeCurrency} />
                        </div>
                    }
                    body={
                        <p className={styles.concertinaBody}>
                            The gain or loss on any investments {isDependent ? `${preferredName} has` : 'you’ve'} sold.
                            This is the difference between the price {isDependent ? `${preferredName}` : 'you'} paid for
                            investments and the price {isDependent ? 'they' : 'you'} sold them for.
                        </p>
                    }
                    border
                />
            )}
            {hasForeignCurrency && (
                <>
                    <Concertina
                        title={
                            <div className={styles.concertinaTitle}>
                                <p className={styles.leftText}>Unrealised currency gains/losses</p>{' '}
                                <DollarValue value={unrealisedCurrencyGain} currency={homeCurrency} />
                            </div>
                        }
                        body={
                            <p className={styles.concertinaBody}>
                                The currency gain or loss on {isDependent ? `${preferredName}’s` : 'your'} investments.
                            </p>
                        }
                        border
                    />
                    {includeSoldInvestments && (
                        <Concertina
                            title={
                                <div className={styles.concertinaTitle}>
                                    <p className={styles.leftText}>Realised currency gains/losses</p>{' '}
                                    <DollarValue value={realisedCurrencyGain} currency={homeCurrency} />
                                </div>
                            }
                            body={
                                <p className={styles.concertinaBody}>
                                    The currency gain or loss on investments{' '}
                                    {isDependent ? `${preferredName} has` : 'you’ve'} sold.
                                </p>
                            }
                            border
                        />
                    )}
                </>
            )}
            <Concertina
                title={
                    <div className={styles.concertinaTitle}>
                        <p className={styles.leftText}>Transaction fees</p>{' '}
                        <DollarValue
                            value={includeSoldInvestments ? -transactionFees : -unrealisedTransactionFees}
                            currency={homeCurrency}
                        />
                    </div>
                }
                body={
                    <p className={styles.concertinaBody}>
                        The amount {isDependent ? `${preferredName} has` : 'you’ve'} paid in transaction fees when
                        buying{includeSoldInvestments && ' or selling investments'}.
                    </p>
                }
                border
            />
            {hasManagedFunds && (
                <Concertina
                    title={
                        <div className={styles.concertinaTitle}>
                            <p className={styles.leftText}>Managed fund transaction&nbsp;fees</p>{' '}
                            <DollarValue
                                value={
                                    includeSoldInvestments
                                        ? -managedFundTransactionFees
                                        : -unrealisedManagedFundTransactionFees
                                }
                                currency={homeCurrency}
                            />
                        </div>
                    }
                    body={
                        <p className={styles.concertinaBody}>
                            The amount {isDependent ? `${preferredName} has` : 'you’ve'} paid in transaction fees when
                            buying{includeSoldInvestments && ' or selling'} managed funds. These are charged by the
                            funds, not Sharesies. Transaction fees are estimates until they’re confirmed by the fund
                            provider—this can take up to 30 days.
                        </p>
                    }
                    border
                />
            )}
            {hasADRs && (
                <Concertina
                    title={
                        <div className={styles.concertinaTitle}>
                            <p className={styles.leftText}>Depositary fees</p>
                            <DollarValue
                                value={includeSoldInvestments ? -adrFees : -unrealisedAdrFees}
                                currency={homeCurrency}
                            />
                        </div>
                    }
                    body={
                        <p className={styles.concertinaBody}>
                            The amount {isDependent ? `${preferredName} has` : 'you’ve'} paid in depositary fees for
                            American depositary receipts.
                        </p>
                    }
                    border
                />
            )}
            <Concertina
                title={
                    <div className={styles.concertinaTitle}>
                        <p className={styles.leftText}>Dividends</p>{' '}
                        <DollarValue
                            value={includeSoldInvestments ? dividends : unrealisedDividends}
                            currency={homeCurrency}
                        />
                    </div>
                }
                body={
                    <p className={styles.concertinaBody}>
                        The amount of dividends, interest, and distributions{' '}
                        {isDependent ? `${preferredName} has` : 'you’ve'} received from {isDependent ? 'their' : 'your'}{' '}
                        investments.
                    </p>
                }
                border
            />
            {hasManagedFunds && (
                <Concertina
                    title={
                        <div className={styles.concertinaTitle}>
                            <p className={styles.leftText}>PIE tax</p>
                            <DollarValue
                                value={includeSoldInvestments ? -pieTax : -unrealisedPieTax}
                                currency={homeCurrency}
                            />
                        </div>
                    }
                    body={
                        <p className={styles.concertinaBody}>
                            The amount {isDependent ? `${preferredName} has` : 'you’ve'} paid in portfolio investment
                            entity (PIE) tax for managed funds. Tax is deducted when you sell units, and at the end of
                            the financial year (31 March). If {isDependent ? `${preferredName} has` : 'you’ve'} received
                            a tax refund, this may be a positive amount.
                        </p>
                    }
                    border
                />
            )}
            <Concertina
                title={
                    <div className={styles.concertinaTitle}>
                        <p className={styles.leftText}>{includeSoldInvestments ? 'Total return' : 'Return'}</p>{' '}
                        <p className={styles.lastRightText}>
                            <DollarValue
                                value={includeSoldInvestments ? totalReturn : unrealisedTotalReturn}
                                currency={homeCurrency}
                            />
                        </p>
                    </div>
                }
                border
                noChevron
            />
            <ReturnCalculationInfo />
        </div>
    )
}

export const ReturnCalculationInfo: React.FunctionComponent<{}> = () => {
    const profileUrl = useProfileUrl()

    return (
        <div className={spacing.spaceAbove8}>
            <h2 className={spacing.spaceBelow8}>Changing return calculations</h2>
            <p className={spacing.spaceBelow8}>You can choose whether your calculations include sold investments.</p>
            <Link to={profileUrl('invest/investment-settings')}>Change how my returns are calculated</Link>
        </div>
    )
}

export default TotalReturnModal
