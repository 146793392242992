import {ModalLink} from '@design-system/modal'
import React from 'react'
import {HelpContentBodyRenderer} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {ManagedFundPricesContent} from '~/sections/explore/pages/learn-static-content/LearnContent'

export const PricesManagedFund: React.FunctionComponent = () => {
    const content = ManagedFundPricesContent
    return (
        <ModalLink
            dataTestId="modal-link--prices-for-managed-funds"
            label={content.title}
            asIcon
            modalTitle={content.title}
            primaryButton={{label: 'Okay, got it.'}}
            bottomBorder
            helpIconSize={16}
        >
            <HelpContentBodyRenderer page={content} />
        </ModalLink>
    )
}

export default PricesManagedFund
