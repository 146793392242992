import React from 'react'
import {useNavigate, useSearchParams, useLocation} from '~/migrate-react-router'

export interface WithRouterProps {
    router: {
        navigate: ReturnType<typeof useNavigate>
        searchParams: ReturnType<typeof useSearchParams>[0]
        location: ReturnType<typeof useLocation>
    }
}

/**
 * This is a workaround to allow non-function components to use
 * functionality from react router 6.x In the long term we should migrate
 * everything away from class-based components and then this whole file becomes
 * unnecessary
 */
export function withRouter<P extends WithRouterProps>(Component: React.ComponentType<P>) {
    const ComponentWithRouterProp: React.FunctionComponent<Omit<P, 'router'>> = props => {
        const navigate = useNavigate()
        const [searchParams] = useSearchParams()
        const location = useLocation()
        return <Component {...(props as P)} router={{navigate, searchParams, location}} />
    }

    return ComponentWithRouterProp
}
