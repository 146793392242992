import cn from 'classnames'
import React, {useState} from 'react'
import usePWA from 'react-pwa-install-prompt'
import Analytics from '~/api/google-analytics/googleAnalytics'
import pwaIcon from '~/global/assets/images/logos/pwa-icon.svg'
import {isMobile} from '~/global/utils/is-mobile/isMobile'
import {Close} from '~/global/widgets/OLD_icons'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import styles from './AddToHomescreen.scss'

const AddToHomescreen: React.FC<{path: string}> = ({path}) => {
    const {isInstallPromptSupported, promptInstall} = usePWA()
    const [hasInstalledOrPrompted, setHasInstalledOrPrompted] = useState(
        Boolean(localStorage.getItem('hasInstalledOrPrompted')) || false,
    )

    const isTouchDevice = function () {
        return 'ontouchstart' in window || 'onmsgesturechange' in window
    }
    const isDesktop = window.screenX !== 0 && !isTouchDevice()

    const onInstall = async () => {
        const didInstall = await promptInstall()
        if (didInstall) {
            Analytics.event({
                category: 'PWA',
                action: 'Clicked install',
                label: 'Add to Homescreen',
            })
            localStorage.setItem('hasInstalledOrPrompted', 'true')
            setHasInstalledOrPrompted(true)
        }
    }

    const onClose = (): void => {
        localStorage.setItem('hasInstalledOrPrompted', 'true')
        setHasInstalledOrPrompted(true)
        Analytics.event({
            category: 'PWA',
            action: 'Clicked ignore',
            label: 'Close the install prompt',
        })
    }

    return (
        <>
            {isInstallPromptSupported &&
            !isDesktop &&
            isMobile.any() &&
            path === '/portfolio' &&
            !hasInstalledOrPrompted ? (
                <ActionBar>
                    <div className={cn(styles.addToHomescreen)}>
                        <img src={pwaIcon} />
                        <p onClick={onInstall}>
                            Add Sharesies to your home screen <span>Install</span>
                        </p>
                        <Close onClick={onClose} />
                    </div>
                </ActionBar>
            ) : null}
        </>
    )
}

export default AddToHomescreen
