import {TileCard} from '@design-system/tile-card'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {ListingResponseDto, EsInstrumentType} from '~/api/distill'
import {DistillScope} from '~/api/query/distill'
import {AppSchemasForClientPortfolioLatestInstrumentReturn} from '~/api/rakaia'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import config from '~/configForEnv'
import {spacing} from '~/global/scss/helpers'
import useDistillInstrumentInfo from '~/global/state-hooks/distill/useDistillInstrumentInfo'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {generateInFlightText, generateOverlayFlags} from '~/global/utils/tile-flag-helpers/tileFlagHelpers'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {NoInstrumentMessage} from '~/global/widgets/no-instrument-message/NoInstrumentMessage'
import {DollarValue, PercentValue} from '~/global/widgets/number-elements/NumberElements'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {generateInitialFilter, useDistillSearchUnderlyingInstruments} from '~/sections/kiwisaver/data/distill'
import {useRakaiaGetLatestInstrumentReturns} from '~/sections/kiwisaver/data/rakaia'
import {useInFlightPortfolioOrders} from '~/sections/kiwisaver/data/retail/portfolioOrder'
import {useActiveOrOffboardingKSCustomer} from '~/sections/kiwisaver/state'
import styles from './InvestmentsGrid.scss'
export type FundTaxonomyType = 'base_fund' | 'self_select_fund'

const mapInstumentLabelToValue = (instrumentLabel: string) => {
    switch (instrumentLabel) {
        case 'Managed funds':
            return [EsInstrumentType.mf]
        case 'Companies':
            return [EsInstrumentType.equity]
        case 'ETFs':
            return [EsInstrumentType.etf]
        default:
            return []
    }
}

// distill does not have the simpleReturns or the investmentValue so we need to get those values from Rakaia which can be mapped to by the fmsFundId
export const mapDistillInstrumentsToRakaiaWithValue = (
    distillInstruments: ListingResponseDto[],
    rakaiaLatestReturns: {
        [key: string]: AppSchemasForClientPortfolioLatestInstrumentReturn
    },
) => {
    return distillInstruments.map(instrument => {
        return {
            ...instrument,
            simpleReturn: instrument.fmsFundId ? rakaiaLatestReturns[instrument.fmsFundId]?.simple_return || 0 : 0,
            investmentValue: instrument.fmsFundId
                ? rakaiaLatestReturns[instrument.fmsFundId]?.investment_value || 0
                : 0,
        }
    })
}

export const sortInstruments = (
    sortId: string,
    distillInstruments: ListingResponseDto[],
    rakaiaLatestReturns: {
        [key: string]: AppSchemasForClientPortfolioLatestInstrumentReturn
    },
) => {
    const mappedInstrumentList = mapDistillInstrumentsToRakaiaWithValue(distillInstruments, rakaiaLatestReturns)
    switch (sortId) {
        case 'ALPHABETICAL':
            return distillInstruments.sort((a, b) => a.name.localeCompare(b.name))
        case 'HIGHEST_RETURNS':
            return mappedInstrumentList.sort((a, b) => b.simpleReturn - a.simpleReturn)
        case 'LOWEST_RETURNS':
            return mappedInstrumentList.sort((a, b) => a.simpleReturn - b.simpleReturn)
        case 'HIGHEST_VALUE':
            return mappedInstrumentList.sort((a, b) => b.investmentValue - a.investmentValue)
        case 'LOWEST_VALUE':
            return mappedInstrumentList.sort((a, b) => a.investmentValue - b.investmentValue)
        default:
            return distillInstruments
    }
}

export const InvestmentsGrid: React.FunctionComponent<{typeChipLabel: string; filter: string; sort: string}> = ({
    typeChipLabel,
    filter,
    sort,
}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const customer = useActiveOrOffboardingKSCustomer()
    const rakaiaLatestReturns = useRakaiaGetLatestInstrumentReturns(customer.portfolio_id).instrument_returns
    const backendInFlightPortfolioOrders = useInFlightPortfolioOrders()
    const {exchanges} = useDistillInstrumentInfo(DistillScope.KIWISAVER_ALL_FUNDS, {searchFundInvestments: true})
    const rakaiaInstrumentList = Object.values(rakaiaLatestReturns).map(x => x.instrument_uuid)
    const backendInstrumentList = backendInFlightPortfolioOrders.map(x => x.fund_id)
    const wrapInstrumentIds = [...new Set(rakaiaInstrumentList.concat(backendInstrumentList))]
    const initialFilter = generateInitialFilter(DistillScope.KIWISAVER_ALL_FUNDS)

    const updatedFilter = {
        ...initialFilter,
        instrumentTypes: filter && filter === 'TYPE' && typeChipLabel ? mapInstumentLabelToValue(typeChipLabel) : [],
    }

    const distillInstruments = useDistillSearchUnderlyingInstruments({
        // TODO https://sharesies.atlassian.net/browse/SUPER-1348 swap to POST and paginate
        ...updatedFilter,
        instruments: wrapInstrumentIds,
    })

    const exchangeName = (instrument: ListingResponseDto) => {
        const exchange = exchanges.filter(x => x.country === instrument.exchangeCountry)
        if (exchange.length > 0) {
            return exchange[0].name
        }
    }

    const handleTileClick = (instrument: ListingResponseDto) => {
        const fundTaxonomyType: FundTaxonomyType =
            tradingType(instrument) === 'managed' ? 'base_fund' : 'self_select_fund'

        rudderTrack('kiwisaver_account', 'holding_card_clicked', {
            fund_category: fundTaxonomyType,
            instrument_id: instrument.id,
        })

        navigate(profileUrl('kiwisaver/:urlSlug', {urlSlug: instrument.urlSlug}), {
            state: {parentInstrument: instrument},
        })
    }

    const renderNoInstrumentsMessage = () => {
        if (filter === 'TYPE') {
            if (typeChipLabel === 'all') {
                return <NoInstrumentMessage para1="You haven’t invested in anything yet" para2="" />
            }
            return (
                <NoInstrumentMessage
                    para1="You haven’t invested in any"
                    para2={typeChipLabel === 'ETFs' ? 'exchange-traded funds (ETFs)' : typeChipLabel.toLowerCase()}
                />
            )
        }
    }

    const sortedDistillInstrument = sort
        ? sortInstruments(sort, distillInstruments, rakaiaLatestReturns)
        : distillInstruments

    const renderInstruments = () => {
        if (!sortedDistillInstrument.length) {
            return []
        }
        return sortedDistillInstrument
            .map(instrument => {
                if (!instrument.fmsFundId) {
                    throw new Error('We should always have an FMS instrument here.')
                }

                const rakaiaReturnsForInstrument = rakaiaLatestReturns[instrument.fmsFundId]

                const backendInFlightOrdersForInstrument = backendInFlightPortfolioOrders.filter(
                    po => po.fund_id === instrument.fmsFundId,
                )

                const customerHasReturnsOrOrderForInstrument =
                    rakaiaReturnsForInstrument || backendInFlightOrdersForInstrument.length > 0
                // Due to some quirks in how searching in Distill works, we need to make extra sure that we should be displaying this instrument
                if (customerHasReturnsOrOrderForInstrument) {
                    const buySellOrderInFlightText = generateInFlightText({
                        'buy order': backendInFlightOrdersForInstrument.filter(po => po.side === 'BUY').length,
                        'sell order': backendInFlightOrdersForInstrument.filter(po => po.side === 'SELL').length,
                    })

                    const flags = generateOverlayFlags({buySellOrderInFlightText})

                    const returnsToDisplay = () => {
                        if (rakaiaReturnsForInstrument) {
                            return rakaiaReturnsForInstrument.simple_return
                        } else if (backendInFlightOrdersForInstrument.length > 0) {
                            // If we have no holdings, but an in-flight order, we can just display zero
                            return 0
                        }
                        throw new Error('Instrument missing. Cannot calculate returns.')
                    }

                    const valueToDisplay = () => {
                        if (rakaiaReturnsForInstrument) {
                            return rakaiaReturnsForInstrument.investment_value
                        } else if (backendInFlightOrdersForInstrument.length > 0) {
                            // If we have no holdings, but an in-flight order, we can just display zero
                            return 0
                        }
                        throw new Error('Instrument missing. Cannot calculate value to display.')
                    }

                    return (
                        <TileCard
                            dataTestId="tile-card--portfolio-tile"
                            investmentThumbnailPath={
                                instrument.logos.thumb ? config.distillPath + instrument.logos.thumb : undefined
                            }
                            investmentName={instrument.name}
                            investmentSymbol={instrument.symbol}
                            investmentTypeInformation={
                                (tradingType(instrument) === 'listed' || tradingType(instrument) === 'adr') &&
                                !isInstrumentInNoTrade(instrument) && (
                                    <>
                                        <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                                        <PronounceLetters text={exchangeName(instrument)} />
                                    </>
                                )
                            }
                            value={<DollarValue value={valueToDisplay()} currency="NZD" />}
                            key={instrument.id}
                            statsReturnsPercentage={<PercentValue value={returnsToDisplay()} />}
                            onClick={() => handleTileClick(instrument)}
                            overlayFlags={flags}
                        />
                    )
                } else {
                    return null
                }
            })
            .filter(item => item !== null)
    }

    const instrumentsToRender = renderInstruments()
    return (
        <>
            {!instrumentsToRender.length ? (
                renderNoInstrumentsMessage()
            ) : (
                <div className={cn(spacing.spaceAbove16, styles.fundList)}>{instrumentsToRender}</div>
            )}
        </>
    )
}
