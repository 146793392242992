import {Button} from '@design-system/button'
import cn from 'classnames'
import {withFormik} from 'formik'
import React from 'react'
import {Model} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {page} from '~/global/scss/helpers'
import calculateWalletBalance from '~/global/utils/calculate-wallet-balance/calculateWalletBalance'
import {exceedsLowPricedSecurityCap} from '~/global/utils/exceeds-low-priced-security-cap/exceedsLowPricedSecurityCap'
import {autoExerciseBuyCostBreakdown} from '~/global/utils/exercise-cost/exerciseCost'
import {
    isInstrumentEligibleForMarketBuyExtendedHours,
    isNotionalStatusInstrument,
} from '~/global/utils/is-instrument-eligible-for-extended-hours/isInstrumentEligibleForExtendedHours'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import WalletBalanceWrapper from '~/global/widgets/estimated-wallet-balance/WalletBalanceWrapper'
import {StrongCurrency, Checkbox} from '~/global/widgets/form-controls/formik'
import PieFundProcessingTime, {DELAYED_PIE_FUNDS} from '~/global/widgets/help-modals/PieFundProcessingTime'
import {isNavigationDirective, Link} from '~/migrate-react-router'
import styles from '~/sections/invest/sections/order-flow/OrderForm.scss'
import ExerciseCostBreakdown from '~/sections/invest/sections/order-flow/sections/buy/widgets/auto-exercise/ExerciseCostBreakdown'
import {ExtendedHoursModal} from '~/sections/invest/sections/order-flow/widgets/modals/ExtendedHoursModal'
import {canMakeAutoExerciseBuyOrder} from '~/store/accounting/selectors'
import {State} from '~/store/accounting/types'
import {connect} from '~/store/connect'
import {useAppSelector} from '~/store/hooks'
import {selectIsInstrumentInExtendedHours} from '~/store/instrument/selectors'
import {Instrument} from '~/store/instrument/types'
import actions from '~/store/order/actions'
import {StagedBuyOrder} from '~/store/order/types'
import {UnwrapThunkAction} from '~/store/types'

interface MarketBuyInDollarsFormValues {
    amount: string
    extendedHours?: boolean
}

const MarketBuyInDollarsForm = withRouter(
    withFormik<MarketBuyInDollarsFormProps & WithRouterProps, MarketBuyInDollarsFormValues>({
        mapPropsToValues: ({stagedBuyOrder, extendedHoursApplies}) => {
            if (!stagedBuyOrder) {
                return {
                    amount: '',
                    extendedHours: extendedHoursApplies,
                }
            }
            return {
                amount: stagedBuyOrder.orderCurrencyAmount ?? '',
                extendedHours: stagedBuyOrder.idempotencyKey ? !!stagedBuyOrder.extendedHours : extendedHoursApplies, // if we got as far as confirm previously, use the stored value, otherwise default to on if applicable
            }
        },
        mapPropsToErrors: ({stagedBuyOrder}) => {
            // make the button disabled initially if there is an initial error by setting at least one field to have an error
            if (!stagedBuyOrder || !stagedBuyOrder.orderCurrencyAmount) {
                return {amount: undefined}
            }
            // if there is an existing amount that got as far as stagedOrder, assume it is valid
            return {}
        },
        enableReinitialize: true,
        validate: async values => {
            const errors: {amount?: string; paymentMethod?: string} = {}

            if (!values.amount || Number(values.amount) === 0) {
                errors.amount = '' // we don't actually display an error
                // Note: not having enough money in your wallet is *not* an error
            }
            return errors
        },
        handleSubmit: async (
            {amount, extendedHours},
            {
                setSubmitting,
                props: {
                    stagedBuyOrder,
                    updateStagedBuyOrder,
                    costStagedBuyOrder,
                    router: {navigate},
                    profileUrl,
                },
            },
        ) => {
            if (!stagedBuyOrder) {
                return
            }

            rudderTrack('buy', 'order_details_entered', {instrument_id: stagedBuyOrder.fundId})

            setSubmitting(true)

            try {
                updateStagedBuyOrder({
                    ...stagedBuyOrder,
                    orderCurrencyAmount: amount,
                    extendedHours,
                })

                const response = await costStagedBuyOrder()
                if (isNavigationDirective(response)) {
                    response.execute(navigate, profileUrl)
                }
            } catch (error) {
                setSubmitting(false)
            }
        },
    })(
        ({
            values,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
            walletBalances,
            exchangeRates,
            exchangeFeeRate,
            usEquitiesEnabled,
            updateStagedBuyOrder,
            stagedBuyOrder,
            instrument,
            setSubmitting,
            accountRestricted,
            extendedHoursApplies,
            canMakeAutoExerciseBuy,
            instrumentIsInExtendedHours,
            instrumenthasNotionalStatus,
        }) => {
            const profileUrl = useProfileUrl()

            const [showPieFundModal, setShowPieFundModal] = React.useState<boolean>(false)
            const [showExtendedHoursModal, setShowExtendedHoursModal] = React.useState<boolean>(false)

            const jurisdiction = useAppSelector(({identity}) => identity.user!.jurisdiction)

            // exercising of rights, shows a breakdown of the cost to exercise either now or later
            const showExerciseCostBreakdown = canMakeAutoExerciseBuy && stagedBuyOrder && values.amount

            React.useEffect(() => {
                if (stagedBuyOrder && stagedBuyOrder.error) {
                    setSubmitting(false)
                }
            }, [stagedBuyOrder])

            const isRemainingBalanceNegative = React.useMemo(() => {
                if (!walletBalances) {
                    return false
                }

                const walletBalanceInDisplayCurrency = calculateWalletBalance(
                    walletBalances,
                    exchangeRates,
                    usEquitiesEnabled,
                    instrument.currency,
                    exchangeFeeRate,
                )

                return walletBalanceInDisplayCurrency
                    ? parseFloat(walletBalanceInDisplayCurrency) - parseFloat(values.amount) < 0
                    : false
            }, [walletBalances, values, exchangeRates])

            const exceedsLowPriceLimit = React.useMemo(() => {
                const {exceedsLimit, msg} = exceedsLowPricedSecurityCap(
                    instrument,
                    undefined,
                    values.amount,
                    undefined,
                    undefined,
                )

                if (exceedsLimit && msg) {
                    return {exceedsLimit, msg}
                }
            }, [instrument, values])

            // an order amount of e.g. $0.01 will not leave enough funds to auto exercise the purchased rights, ignore potential fees
            const isAutoExerciseAmountInvalid = React.useMemo(() => {
                if (stagedBuyOrder && stagedBuyOrder.autoExercise && parseFloat(values.amount) > 0) {
                    const autoExerciseCostBreakdown = autoExerciseBuyCostBreakdown(
                        instrument,
                        values.amount,
                        jurisdiction,
                    )
                    return !autoExerciseCostBreakdown
                }
                return false
            }, [stagedBuyOrder, values])

            const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault()
                if (values.extendedHours) {
                    // US shares extended hours - if enabled show warning before proceeeding to submit
                    setShowExtendedHoursModal(true)
                } else if (DELAYED_PIE_FUNDS.includes(instrument.symbol)) {
                    // Pie funds processing time is way longer than any other funds normally take.
                    // Every time when a user buy or sells any of the Pie funds, we're displaying a
                    // special help modal before we redirect them to the buy or sell confirm page.
                    // These are managed funds, so no need to do this for limit orders.
                    setShowPieFundModal(true)
                } else {
                    handleSubmit(e)
                }
            }

            const preventSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault()
                }
            }

            const handleOnBlur = (event: string | React.FocusEvent<unknown>) => {
                if (typeof event === 'string') {
                    return
                }
                handleBlur(event)

                if (!stagedBuyOrder) {
                    return
                }

                // TODO: [DS-636] clean up this logic
                // Hack to prevent loss of focus on the input field while the component re-renders
                setTimeout(() => {
                    // store any user entered values immediately so the user can look at
                    // the market depth page without losing data
                    updateStagedBuyOrder({
                        ...stagedBuyOrder,
                        orderCurrencyAmount: values.amount,
                        extendedHours: values.extendedHours,
                    })
                }, 0)
            }

            return (
                <form onSubmit={onSubmit}>
                    {showPieFundModal && (
                        <PieFundProcessingTime
                            instrument={instrument}
                            onClick={() => {
                                setShowPieFundModal(false)
                                handleSubmit()
                            }}
                            onClose={() => setShowPieFundModal(false)}
                        />
                    )}

                    {showExtendedHoursModal && (
                        <ExtendedHoursModal
                            orderType={stagedBuyOrder?.orderType}
                            onContinue={() => {
                                setShowExtendedHoursModal(false)
                                handleSubmit()
                            }}
                            onClose={() => setShowExtendedHoursModal(false)}
                        />
                    )}

                    <StrongCurrency
                        dataTestId="strong-currency--order-amount"
                        name="amount"
                        label="Order amount"
                        disabled={isSubmitting || accountRestricted}
                        autoFocus
                        optionalAttributes={{
                            onKeyPress: preventSubmit,
                            onBlur: handleOnBlur,
                        }}
                        currency={instrument.currency}
                        helpText={
                            <>
                                {isRemainingBalanceNegative && !exceedsLowPriceLimit?.exceedsLimit && (
                                    <div data-testid="insufficient-funds">
                                        There’s not enough money in your Wallet.{' '}
                                        <Link to={profileUrl('wallet')}>Top up</Link>
                                    </div>
                                )}
                            </>
                        }
                    />

                    {/* Display this first in case people top up their wallet then realise they can only purchase up to the set limit. */}
                    {exceedsLowPriceLimit?.exceedsLimit && (
                        <div className={styles.limitWarning} data-testid="exceeds-low-price-limit">
                            {exceedsLowPriceLimit.msg}
                        </div>
                    )}

                    {isAutoExerciseAmountInvalid &&
                        !isRemainingBalanceNegative &&
                        !exceedsLowPriceLimit?.exceedsLimit && (
                            <div className={styles.autoExerciseAmountWarning}>
                                This amount isn’t enough to cover the cost to exercise rights—try entering a higher
                                number.
                            </div>
                        )}

                    {showExerciseCostBreakdown && (
                        <ExerciseCostBreakdown
                            instrument={instrument}
                            orderAmount={values.amount}
                            stagedBuyOrder={stagedBuyOrder}
                            jurisdiction={jurisdiction}
                        />
                    )}

                    {extendedHoursApplies && (
                        <div className={styles.extendedHoursCheckbox}>
                            <Checkbox
                                dataTestId="checkbox--extended-hours"
                                name="extendedHours"
                                label="Allow order to fill during extended hours"
                                helpText="When selected, all or some of your order may fill during extended hours. If unselected, it’ll only fill during regular market hours."
                                additionalClassName={styles.checkbox}
                            />
                        </div>
                    )}

                    {instrumentIsInExtendedHours && !instrumenthasNotionalStatus && (
                        <AlertCard type="info">
                            <p>Market buy orders for this investment only fill during regular market hours.</p>
                        </AlertCard>
                    )}

                    <ActionBar className={cn(page.flexRow, styles.formFooter)}>
                        <WalletBalanceWrapper displayCurrency={instrument.currency} exchangeRates={exchangeRates} />
                        <Button
                            dataTestId="button--review"
                            label="Review"
                            disabled={
                                !isValid ||
                                isRemainingBalanceNegative ||
                                accountRestricted ||
                                isAutoExerciseAmountInvalid ||
                                exceedsLowPriceLimit?.exceedsLimit
                            }
                            processing={isSubmitting}
                            isSubmit
                        />
                    </ActionBar>
                </form>
            )
        },
    ),
)

interface StoreProps {
    walletBalances?: Model.User['wallet_balances']
    usEquitiesEnabled: boolean
    stagedBuyOrder?: StagedBuyOrder
    accountRestricted: boolean
    extendedHoursApplies: boolean
    canMakeAutoExerciseBuy: boolean
    instrumentIsInExtendedHours: boolean
    instrumenthasNotionalStatus: boolean
}

interface DispatchProps {
    costStagedBuyOrder: UnwrapThunkAction<typeof actions.CostBuyOrder>
    updateStagedBuyOrder(order: StagedBuyOrder): void
}

interface OwnProps {
    instrument: Instrument
    exchangeRates: State['exchangeRates']
    exchangeFeeRate: State['exchangeFeeRate']
    profileUrl: ReturnType<typeof useProfileUrl>
}

type MarketBuyInDollarsFormProps = StoreProps & DispatchProps & OwnProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    (state, {instrument}) => {
        const {order, identity} = state

        return {
            usEquitiesEnabled: identity.user ? identity.user.us_equities_enabled : false,
            walletBalances: identity.user!.wallet_balances,
            stagedBuyOrder: order.stagedBuyOrder,
            accountRestricted: identity.user!.account_restricted,
            extendedHoursApplies:
                identity.portfolioExtendedHoursPreference && isInstrumentEligibleForMarketBuyExtendedHours(instrument),
            canMakeAutoExerciseBuy: canMakeAutoExerciseBuyOrder(state, instrument.id),
            instrumentIsInExtendedHours: !!selectIsInstrumentInExtendedHours(state, instrument),
            instrumenthasNotionalStatus: isNotionalStatusInstrument(instrument),
        }
    },
    dispatch => ({
        costStagedBuyOrder: () => dispatch(actions.CostBuyOrder()),
        updateStagedBuyOrder: order => dispatch(actions.UpdateStagedBuyOrder(order)),
    }),
)(MarketBuyInDollarsForm)
