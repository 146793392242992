import React from 'react'
import tooltipStyles from '~/global/scss/reused-styles/tooltip.scss'
import {calculateCurrencyExchangeFromSourceAmount} from '~/global/utils/calculate-currency-exchange/calculateCurrencyExchange'
import {useExchangeFeeRate} from '~/global/utils/exchange-fee-hooks/exchangeFeeHooks'
import {findExchangeRate} from '~/global/utils/find-exchange-rate/findExchangeRate'
import {SharePriceValue} from '~/global/widgets/number-elements/NumberElements'
import {Tooltip} from '~/global/widgets/tooltip/Tooltip'
import {useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'

interface SharePriceWithExchangeTooltipProps {
    price: string
    currency: Instrument['currency']
    shareLabel: string
}

export const SharePriceWithExchangeTooltip: React.FunctionComponent<SharePriceWithExchangeTooltipProps> = ({
    price,
    currency,
    shareLabel,
}) => {
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)
    const exchangeRates = useAppSelector(s => s.accounting.exchangeRates)
    const foundExchangeRate = findExchangeRate({
        sourceCurrency: currency,
        targetCurrency: homeCurrency,
        exchangeRates,
    })
    const exchangeFeeRate = useExchangeFeeRate()

    return (
        <>
            {foundExchangeRate && exchangeFeeRate !== null ? (
                <Tooltip>
                    <span className={tooltipStyles.label}>
                        <SharePriceValue value={price} currency={currency} />
                    </span>
                    <div className={tooltipStyles.tooltip}>
                        <p>
                            Estimated{' '}
                            <SharePriceValue
                                value={
                                    calculateCurrencyExchangeFromSourceAmount(
                                        price,
                                        foundExchangeRate.rate,
                                        exchangeFeeRate,
                                    ).targetAmount
                                }
                                currency={homeCurrency}
                            />{' '}
                            per {shareLabel}
                        </p>
                    </div>
                </Tooltip>
            ) : (
                <SharePriceValue value={price} currency={currency} />
            )}
        </>
    )
}

export default SharePriceWithExchangeTooltip
