import {DateTime} from 'luxon'
import React from 'react'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {
    KiwiSaverChartProps,
    getDecimalPlacesToShowOnChart,
} from '~/sections/kiwisaver/sections/home/utils/chart-helpers/chartHelpers'
import {useAppSelector} from '~/store/hooks'
import styles from './KiwiSaverChartBalance.scss'

const KiwiSaverChartBalance: React.FC<KiwiSaverChartProps> = ({historyItem}) => {
    const currency = useAppSelector(s => s.identity.user!.home_currency)
    const portfolioValue = historyItem ? historyItem.portfolio_value : 0
    const decimalPlaces = getDecimalPlacesToShowOnChart(portfolioValue)

    return (
        <div className={styles.numbersPortfolio}>
            <h1 className={styles.title}>KiwiSaver</h1>
            <div>
                <span className={styles.numbersPortfolioValue}>
                    <DollarValue value={portfolioValue} decimalPlaces={decimalPlaces} />
                </span>
                <span className={styles.numbersPortfolioCurrency}>{currency.toUpperCase()}</span>
            </div>
            {historyItem && (
                <div className={styles.balanceDate}>
                    estimated balance as at {DateTime.fromISO(historyItem.date).toFormat('d MMM yyyy')}
                </div>
            )}
        </div>
    )
}
export default KiwiSaverChartBalance
