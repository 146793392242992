import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {reEnterPasswordMessage} from '~/global/utils/error-text/errorText'
import {formatFullName, FullNameType} from '~/global/utils/format-full-name/formatFullName'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ErrorBox, Radio} from '~/global/widgets/form-controls'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import DrsDetailsForm from '~/sections/invest/sections/transfer-shares/sections/us-transfer-shares/DrsDetailsForm'
import RegistryDetailsForm from '~/sections/invest/sections/transfer-shares/sections/us-transfer-shares/RegistryDetailsForm'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import transferActions from '~/store/transfer/actions'
import {NEW_DRS_REQUIRED} from '~/store/transfer/types'

export const AddRegistryDetails: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const stagedTransferOrder = useAppSelector(({transfer}) => transfer.stagedTransferOrder)

    const [detailsEntered, setDetailsEntered] = React.useState<boolean>(false)
    const [fullName, setFullName] = React.useState<FullNameType | null>(null)
    const [authenticationError, setAuthenticationError] = React.useState<Error | null>(null)
    const [isExistingDrs, setIsExistingDrs] = React.useState<'yes' | 'no'>('no')

    const navigateHome = () => {
        navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})
    }

    React.useEffect(() => {
        if (!stagedTransferOrder) {
            navigateHome()
            return
        }
        authenticateAndFetchFullName()
    }, [])

    // Fetch the customer's full name. As this is PII, this is kept outside the redux store
    const authenticateAndFetchFullName = async () => {
        if (fullName?.firstName) {
            return
        }
        const response = await dispatch(identityActions.GetFullName())
        if (response.type === 'full_name') {
            setFullName({
                firstName: response.first_name,
                middleName: response.middle_name,
                lastName: response.last_name,
            })
        } else if (response === reEnterPasswordMessage) {
            setAuthenticationError(new Error(reEnterPasswordMessage))
        }
    }

    const statementName =
        stagedTransferOrder?.usTransferPlatform === 'DRS' ? 'transfer agent account' : 'brokerage account'

    const radioOptions = [
        {value: 'no', label: 'No'},
        {value: 'yes', label: 'Yes'},
    ]

    if (fullName instanceof Error || !stagedTransferOrder) {
        navigateHome()
    }

    const saveApplication = () => {
        dispatch(transferActions.ResetStagedTransferOrder())
        navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})
        Toast('Your transfer application was saved')
    }

    const nextPage = () => {
        // hasn't selected anything
        if (!isExistingDrs) {
            return
        }

        // does not have an existing DRS and would like one created by the DRS agent
        if (isExistingDrs === 'no') {
            dispatch(transferActions.CreateRegistryDetails({referenceId: NEW_DRS_REQUIRED, referenceType: 'DRS'}))
            navigate(profileUrl('invest/portfolio-transfer-shares/us/select'))
        }
    }

    // Screen 1 - Create / Update their reference number
    // Not needed for transfers out
    if (!detailsEntered) {
        return (
            <>
                <Toolbar dataTestId="toolbar--add-registry-details" leftButton="back" />
                {stagedTransferOrder?.usTransferPlatform === 'DRS' ? (
                    <>
                        <div className={cn(commonStyles.sideMargin, spacing.spaceAbove24)}>
                            <h1 className={cn(commonStyles.heading, spacing.spaceBelow24)}>Your investor details</h1>
                            <p>Are you transferring to an existing account?</p>

                            <Radio
                                dataTestId="radio--existing-drs"
                                name="drs"
                                isTouched
                                choices={radioOptions}
                                onChange={e => setIsExistingDrs(e.target.value as 'yes' | 'no')}
                                value={isExistingDrs}
                            />
                        </div>
                        {isExistingDrs === 'yes' && <DrsDetailsForm nextPage={() => setDetailsEntered(true)} />}

                        {isExistingDrs === 'no' && (
                            <ActionBar>
                                <Button
                                    dataTestId="button--next"
                                    onClick={() => nextPage()}
                                    label="Next"
                                    disabled={isExistingDrs === undefined}
                                />
                            </ActionBar>
                        )}
                    </>
                ) : (
                    <RegistryDetailsForm nextPage={() => setDetailsEntered(true)} />
                )}
            </>
        )
    }

    // Screen 2 - Confirm the name matches the form
    return (
        <>
            <Toolbar
                dataTestId="toolbar--add-us-registry-details"
                leftButton="back"
                onLeftButtonClick={() => setDetailsEntered(false)}
            />
            <Page>
                <h1 className={cn(commonStyles.heading, spacing.spaceBelow24)}>Check your account names match</h1>
                <p className={spacing.spaceBelow16}>
                    The name on your {statementName} statement and the name you use for your Sharesies account must
                    match (including titles, middle names, and hyphens).
                </p>
                <h4 className={cn(spacing.spaceBelow16, typographyOverrides['as-h3'])}>Sharesies account name</h4>
                <p>The name we have for you is:</p>
                <p className={spacing.spaceBelow16}>{fullName && formatFullName(fullName)}</p>
                <p className={spacing.spaceBelow16}>
                    Is it a perfect match? Proceed! If not, update whichever name is incorrect.
                </p>
                <p>
                    You can reach out through the chat bubble in the top-right corner if you need to update your
                    Sharesies account name.
                </p>
                <ErrorBox message={authenticationError} />
            </Page>
            <ActionBar>
                <div className={commonStyles.actionBar}>
                    <Button
                        type="secondary"
                        label="Save application"
                        dataTestId="button--save-application"
                        onClick={saveApplication}
                    />
                    <Button
                        label="It’s a match!"
                        dataTestId="button--name-match"
                        onClick={() => navigate(profileUrl('invest/portfolio-transfer-shares/us/select'))}
                    />
                </div>
            </ActionBar>
        </>
    )
}

export default AddRegistryDetails
