import {rakaiaGetFactory} from '~/api/query/rakaia'
import {Currency} from '~/api/rakaia'
import {Portfolio} from '~/global/state-hooks/retail/useProfile'

/**
 * Use the Rakaia portfolio endpoint to get simple and total returns.
 *
 * @example
 * const portfolioReturns = usePortfolioReturns(portfolio)
 */
export const usePortfolioReturns = (portfolio: Portfolio<'INVEST' | 'KIWISAVER'>) => {
    const {data} = rakaiaGetFactory({
        apiFunctionName: 'readPortfolioApiV1PortfoliosPortfolioUuidGet',
        notFoundResponse: ({portfolioUuid}) => ({
            uuid: portfolioUuid,
            currency: Currency.NZD, // doesn't matter which currency we pick, we throw it away
            portfolio_value: 0,
            portfolio_history: [],
            simple_return: 0.0,
            unrealised_simple_return: 0.0,
            total_return: 0.0,
            unrealised_total_return: 0.0,
        }),
    })({portfolioUuid: portfolio.id})

    return {
        simpleReturn: data.simple_return || 0,
        unRealisedSimpleReturn: data.unrealised_simple_return || 0,
        totalReturns: data.total_return || 0,
        unRealisedTotalReturn: data.unrealised_total_return || 0,
    }
}
