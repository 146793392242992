import {Entry} from 'contentful'
import {ContentfulButton, ArticleButton} from '~/api/contentful/types'
import {isInternalLink, normalizeUrl} from '~/global/utils/normalize-url/normalizeUrl'

export function parseButton(contentfulButton?: Entry<ContentfulButton>): ArticleButton | null {
    if (!contentfulButton || !contentfulButton.fields.url) {
        return null
    }

    const {label, ariaLabel = '', buttonTheme = 'Pink'} = contentfulButton.fields

    const type = buttonTheme === 'Pink' ? 'primary' : 'secondary'

    const cleanUrl = normalizeUrl(contentfulButton.fields.url)

    if (isInternalLink(cleanUrl)) {
        return {
            ariaLabel,
            label,
            type,
            reactRouterTo: cleanUrl,
        }
    }

    return {
        label,
        ariaLabel,
        type,
        linkHref: cleanUrl,
    }
}
