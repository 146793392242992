import React from 'react'
import {AlignmentRightAllocation} from '~/store/employeeShareScheme/types'
interface AlignmentRightsUnitNameProps {
    allocation: AlignmentRightAllocation
}
const AlignmentRightsUnitName: React.FunctionComponent<AlignmentRightsUnitNameProps> = ({allocation}) => {
    switch (allocation.allocation_type) {
        case 'COOP_UNIT':
            return <>Co-op Units</>
        case 'FARM_UNIT':
            return <>Farm Units</>
    }
}
export default AlignmentRightsUnitName
