import Decimal from 'decimal.js'
import React from 'react'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {Units} from '~/global/widgets/OLD_icons'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {ShareValue} from '~/global/widgets/number-elements/NumberElements'
import styles from '~/sections/invest/sections/order-flow/OrderForm.scss'
import {Instrument} from '~/store/instrument/types'

const zero = new Decimal(0)

const SharesRemaining: React.FunctionComponent<SharesRemainingProps> = ({
    instrument,
    available,
    used,
    wholeSharesOnly,
    applyAllLabel,
    applyAll,
}) => {
    const formattedSharesRemaining = React.useMemo(() => {
        if (available.lte(zero)) {
            return '-'
        }

        const sharesRemaining = used.gt(zero) ? calculateSharesRemaining(available, used) : available

        return <ShareValue value={sharesRemaining.toString()} wholeSharesOnly={!!wholeSharesOnly} showFullValue />
    }, [available, used, wholeSharesOnly])

    return (
        <p className={styles.sharesRemaining}>
            <Units /> {shareLabel({instrument, isPlural: true, isCapitalised: true})} remaining:{' '}
            <strong>{formattedSharesRemaining}</strong>
            {applyAll && (
                <ButtonAsLink className={styles.linkButton} onClick={() => applyAll()}>
                    {applyAllLabel ?? 'Use all'}
                </ButtonAsLink>
            )}
        </p>
    )
}

interface SharesRemainingProps {
    instrument: Instrument
    available: Decimal
    used: Decimal
    wholeSharesOnly?: boolean
    applyAllLabel?: string
    applyAll?: () => void
}

export default SharesRemaining

const calculateSharesRemaining = (available: Decimal, used: Decimal) => {
    const remaining = available.minus(used)
    return (remaining.gt(zero) ? remaining : zero).toString()
}
