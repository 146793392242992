import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useNavigate} from '~/migrate-react-router'
import {PortfolioHistoryItem} from '~/store/portfolio/types'
import ChartThumbnail from './ChartThumbnail'

interface ChartPreviewProps {
    portfolioHistory: PortfolioHistoryItem[]
    hasNonHomeCurrencyInvestment: boolean
}

const ChartPreview: React.FunctionComponent<ChartPreviewProps> = ({portfolioHistory}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    return (
        <ChartThumbnail
            // default the start date to their first portfolio return date.
            startDate={portfolioHistory[0].date}
            portfolioHistory={portfolioHistory}
            onClick={() => {
                // google analytics
                Analytics.event({
                    category: 'Portfolio',
                    action: 'Clicked view chart',
                    label: 'View chart',
                })
                navigate(profileUrl('invest/investment-history'))
            }}
        />
    )
}

export default ChartPreview
