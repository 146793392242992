import React from 'react'
import Image from '~/global/widgets/image/Image'
import portfolioValueKeyDark from './assets/images/portfolio-value-key-dark.svg'
import portfolioValueKeyLight from './assets/images/portfolio-value-key-light.svg'
import putInKeyLight from './assets/images/put-in-key-dark.svg'
import putInKeyDark from './assets/images/put-in-key-light.svg'
import styles from './KiwiSaverChartLegend.scss'

export const KiwiSaverChartLegend: React.FunctionComponent<{}> = () => {
    return (
        <dl className={styles.legendRow}>
            <dt className={styles.legendRowValue}>
                <Image className={styles.svg} src={putInKeyLight} darkSrc={putInKeyDark} /> Amount put in
            </dt>
            <dt className={styles.legendRowValue}>
                <Image className={styles.svg} src={portfolioValueKeyLight} darkSrc={portfolioValueKeyDark} /> Estimated
                portfolio value
            </dt>
        </dl>
    )
}
