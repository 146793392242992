import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ListMenuGroup, ListMenuItem} from '~/global/widgets/list-menu/ListMenu'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {PageTitle} from '~/sections/kiwisaver/widgets/page-title/PageTitle'

export const DownloadDocuments: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    return (
        <>
            <Toolbar leftButton="back" dataTestId="toolbar--download-documents" />
            <Page>
                <PageTitle className={spacing.spaceBelow32}>Download documents</PageTitle>
                <ListMenuGroup className={cn(spacing.spaceBelow12, spacing.spaceAbove12)}>
                    <ListMenuItem
                        label="Personalised quarterly reports"
                        onClick={() => navigate(profileUrl('kiwisaver/personalised-quarterly-reports'))}
                    />
                    <ListMenuItem
                        label="Annual member statements"
                        onClick={() => navigate(profileUrl('kiwisaver/annual-member-statements'))}
                    />
                    <ListMenuItem
                        label="Annual tax certificates"
                        onClick={() => navigate(profileUrl('kiwisaver/annual-tax-reports'))}
                    />
                </ListMenuGroup>
            </Page>
        </>
    )
}
