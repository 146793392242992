/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface BreakdownItem
 */
export interface BreakdownItem {
    /**
     *
     * @type {number}
     * @memberof BreakdownItem
     */
    percent?: number
    /**
     *
     * @type {number}
     * @memberof BreakdownItem
     */
    value?: number
}

export function BreakdownItemFromJSON(json: any): BreakdownItem {
    return BreakdownItemFromJSONTyped(json, false)
}

export function BreakdownItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): BreakdownItem {
    if (json === undefined || json === null) {
        return json
    }
    return {
        percent: !exists(json, 'percent') ? undefined : json['percent'],
        value: !exists(json, 'value') ? undefined : json['value'],
    }
}

export function BreakdownItemToJSON(value?: BreakdownItem | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        percent: value.percent,
        value: value.value,
    }
}
