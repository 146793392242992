// All name portions that are not allowed to be used in a preferred name
// Note that if you alter the names here, please also remember to check the bank end validation in the back end repo
// at ~/src/sharesies/retail-backend/app/support/util.py
export const invalidPreferredNames = [
    'trust',
    'beneficiary',
    'beneficiaries',
    'company',
    'limited',
    'ltd',
    'smsf',
    'superfund',
]

// Validation function that checks whether a given string is a valid preferred name
export const isPreferredNameValid = (preferredName: string) =>
    !invalidPreferredNames.some(invalidName => preferredName.toLowerCase().match(new RegExp(invalidName)))
