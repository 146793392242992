import {Modal} from '@design-system/modal'
import React from 'react'
import {spacing} from '~/global/scss/helpers'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const PlanRecurringPaymentsModal: React.FunctionComponent<Props> = ({isOpen, setIsOpen}) => {
    return (
        <>
            <Modal
                dataTestId="modal--recurring-payments"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title="Linked bank top-ups"
                content={
                    <div>
                        <p className={spacing.spaceBelow12}>
                            Once you've linked your bank to Sharesies, you can top up your Wallet straight from your
                            bank.
                        </p>
                        <ul>
                            <li>
                                A recurring top-up lets you automate your Wallet top-ups—just pick the amount,
                                frequency, and start date that suits you.
                            </li>
                            <li>
                                A one-off top-up lets you make a seamless one-time top-up of an amount of your choice.
                            </li>
                        </ul>
                        <p className={spacing.spaceBelow12}>
                            On a plan, your transfer fees on linked bank top-ups are covered.
                        </p>
                        <p>
                            If you're not on a plan, you’ll be charged a 2% transfer fee on each linked bank top-up
                            (capped at $2 AUD per transaction).
                        </p>
                    </div>
                }
            />
        </>
    )
}

export default PlanRecurringPaymentsModal
