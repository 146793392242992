import cn from 'classnames'
import Decimal from 'decimal.js'
import React from 'react'
import {Link} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Image} from '~/global/widgets/image/Image'
import cardIconDark from '~/sections/invest/sections/dividend-reinvestment-plans/assets/images/card-icon-dark.svg'
import cardIconLight from '~/sections/invest/sections/dividend-reinvestment-plans/assets/images/card-icon-light.svg'
import {useAppSelector} from '~/store/hooks'
import {FundHolding} from '~/store/identity/types'
import {Instrument} from '~/store/instrument/types'
import styles from './DividendReinvestmentAvailable.scss'

export const dividendOptInNextPage = (
    instrument: Instrument,
    hasHolding: boolean,
    profileUrl: ReturnType<typeof useProfileUrl>,
    hasSeenDRPLearn?: boolean,
) => {
    if (hasHolding) {
        if (hasSeenDRPLearn) {
            return profileUrl('invest/dividend-reinvestment-confirm/:instrumentSlug', {
                instrumentSlug: instrument.urlSlug,
            })
        }
        return profileUrl('invest/dividend-reinvestment-learn/:instrumentSlug', {
            instrumentSlug: instrument.urlSlug,
        })
    }
    return profileUrl('invest/dividend-reinvestment-learn')
}

interface DividendReinvestmentAvailableProps {
    className?: string
    instrument: Instrument
    holding?: FundHolding
}

const DividendReinvestmentAvailable: React.FunctionComponent<DividendReinvestmentAvailableProps> = ({
    className,
    instrument,
    holding,
}) => {
    const profileUrl = useProfileUrl()
    const hasSeenDRPLearn = useAppSelector(s => s.identity.user?.has_seen.dividend_reinvestment_plan)
    const nextPageUrl = dividendOptInNextPage(
        instrument,
        (holding && new Decimal(holding.shares).greaterThan(0)) ?? false,
        profileUrl,
        hasSeenDRPLearn,
    )

    return (
        <div className={cn(className, styles.cardWrapper)}>
            <div className={cn(styles.row, styles.top)}>
                <div className={styles.imageWrapper}>
                    <Image src={cardIconLight} darkSrc={cardIconDark} />
                </div>
                <div className={styles.col}>
                    <span className={styles.title}>Dividend reinvestment plan available</span>
                    <Link className={cn(styles.subTitle, spacing.spaceAbove8)} to={nextPageUrl}>
                        {holding && hasSeenDRPLearn ? 'Opt in' : 'Learn more'}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default DividendReinvestmentAvailable
