import {Close} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {
    KIDS_RECOMMENDED_LABEL,
    RISK_LEVEL_LABEL,
    UNLISTED_INSTRUMENTS_LABEL,
} from '~/global/constants/categoryAndSearchLabels'
import {accessibility} from '~/global/scss/helpers'
import {useWatchlist} from '~/global/state-hooks/retail/useWatchlist'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Chips from '~/global/widgets/chips/Chips'
import chipsStyles from '~/global/widgets/chips/Chips.scss'
import SortAndFilter from '~/global/widgets/sort-and-filter/SortAndFilter'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/instrument/actions'
import {initialState} from '~/store/instrument/reducer'
import {sortedCurrentFilters} from '~/store/instrument/selectors'
import {TimePeriod} from '~/store/instrument/types'
import styles from './Controls.scss'

const Controls: React.FunctionComponent = () => {
    const [animateTimePeriodMenuClosing, setAnimateTimePeriodMenuClosing] = React.useState(false)
    const [chipOptions, setChipOptions] = React.useState<string[]>([])
    const [timePeriodMenuShown, setTimePeriodMenuShown] = React.useState(false)

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const currentFilters = useAppSelector(s => s.instrument.currentSearchFilters)
    const currentQuery = useAppSelector(s => s.instrument.currentSearchQuery)
    const currentSearchList = useAppSelector(s => s.instrument.currentSearchList)
    const currentSort = useAppSelector(s => s.instrument.currentSearchSort)
    const currentTimePeriod = useAppSelector(s => s.instrument.currentSearchTimePeriod)
    const defaultSortId = initialState.currentSearchSort
    const isAutoInvest = location.pathname.includes('auto-invest')
    const portfolioInstrumentIds = useAppSelector(s => s.instrument.portfolioIndex)
    const sortedFilters = useAppSelector(s => sortedCurrentFilters(s))
    const sortOptions = useAppSelector(s =>
        s.instrument.metadata.sorts ? s.instrument.metadata.sorts.filter(sort => sort.id !== 'fundsThenEquities') : [],
    )
    const timePeriodOptions = useAppSelector(s => s.instrument.metadata.timePeriods || [])
    const {watchlistInstrumentIds} = useWatchlist()

    const hideMenus = () => {
        setTimePeriodMenuShown(false)
        setAnimateTimePeriodMenuClosing(false)
    }

    React.useEffect(() => {
        const options = []

        if (portfolioInstrumentIds.length) {
            options.push('In your Portfolio')
        }

        if (watchlistInstrumentIds.length) {
            options.push('On your Watchlist')
        }

        setChipOptions(options)
    }, [portfolioInstrumentIds.length, watchlistInstrumentIds.length])

    const handleChipClick = (listName: string) => {
        const context = isAutoInvest ? 'auto-invest' : 'invest'

        if (listName === 'all') {
            dispatch(actions.executeSetSearchList('All', [], context))
        } else if (listName === 'On your Watchlist') {
            dispatch(actions.executeSetSearchList(listName, watchlistInstrumentIds, context))
        } else if (listName === 'In your Portfolio') {
            dispatch(actions.executeSetSearchList(listName, portfolioInstrumentIds, context))
        }
    }

    return (
        <>
            {chipOptions.length > 0 && (
                <div className={styles.chips}>
                    <Chips
                        options={chipOptions}
                        hasAllOption
                        isInlineDisplay
                        selected={currentSearchList.listName === 'All' ? [] : [currentSearchList.listName]}
                        onChipClick={listName => handleChipClick(listName)}
                    />
                </div>
            )}
            <div className={styles.controls}>
                {sortOptions.length > 0 && !timePeriodMenuShown && (
                    <SortAndFilter
                        currentSort={
                            sortOptions.filter(sort => sort.id === currentSort).length > 0
                                ? sortOptions.find(sort => sort.id === currentSort)
                                : sortOptions[0]
                        }
                        defaultSortId={defaultSortId}
                        onFilterClick={() =>
                            navigate(profileUrl(isAutoInvest ? 'auto-invest/diy/filter' : 'invest/search/filter'))
                        }
                        onSortHide={hideMenus}
                        onSortMenuClick={newSort => {
                            Analytics.event({
                                category: 'Search',
                                action: 'Applied sort to Invest results',
                                label: newSort,
                            })
                            dispatch(actions.executeSort(newSort))
                        }}
                        sortOptions={sortOptions.filter(
                            option => option.id !== 'relevance' || (currentQuery && currentQuery.length > 0),
                        )}
                    />
                )}

                <div
                    className={cn(styles.period, {
                        [styles.timePeriodHidden]: currentSort !== 'priceChange',
                    })}
                >
                    {!timePeriodMenuShown && (
                        <button
                            className={cn(styles.periodToggle, accessibility.button)}
                            type="button"
                            onClick={() => setTimePeriodMenuShown(true)}
                        >
                            {currentTimePeriod}
                        </button>
                    )}
                    <div
                        className={cn(chipsStyles.inlineChipsWrapper, styles.periodControls, {
                            [styles.shown]: timePeriodMenuShown || animateTimePeriodMenuClosing,
                        })}
                    >
                        <ul className={cn(chipsStyles.chips, chipsStyles.inline, styles.periodControlsList)}>
                            {timePeriodOptions.map((option, index) => (
                                <li
                                    className={cn(chipsStyles.chip, styles.periodControlsChip, {
                                        [styles.shown]: timePeriodMenuShown,
                                        [styles.hidden]: animateTimePeriodMenuClosing,
                                        [styles.selected]: option === currentTimePeriod,
                                    })}
                                    // delays for when the time period menu is opened and closed
                                    style={
                                        timePeriodMenuShown
                                            ? {
                                                  animationDelay: `${
                                                      0.1 + 0.03 * (timePeriodOptions.length - (index + 1))
                                                  }s`,
                                              }
                                            : {animationDelay: `${0.1 + 0.03 * index}s`}
                                    }
                                    key={`highest-price-change-in-${option}`}
                                    onClick={() => {
                                        dispatch(actions.executeTimePeriodChange(option as TimePeriod))
                                        setAnimateTimePeriodMenuClosing(true)
                                    }}
                                    onAnimationEnd={() => {
                                        // when the closing animation ends for the last time period option, close time period menu
                                        if (
                                            option === timePeriodOptions[timePeriodOptions.length - 1] &&
                                            animateTimePeriodMenuClosing
                                        ) {
                                            setTimePeriodMenuShown(false)
                                            setAnimateTimePeriodMenuClosing(false)
                                        }
                                    }}
                                >
                                    <button
                                        className={accessibility.button}
                                        type="button"
                                        onClick={e => e.preventDefault()}
                                    >
                                        {option}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            {sortedFilters.length > 0 && (
                <div className={styles.currentFiltersWrapper}>
                    <Chips
                        options={sortedFilters}
                        onChipClick={filter => {
                            Analytics.event({
                                category: 'Search',
                                action: 'Removed filter from Invest results',
                                label: filter,
                            })
                            if (currentFilters.categories.includes(filter)) {
                                dispatch(actions.executeRemoveCategoryFromFilter(filter))
                            }
                            if (currentFilters.instrumentTypes.includes(filter)) {
                                dispatch(actions.executeRemoveInstrumentTypeFromFilter(filter))
                            }
                            if (filter === KIDS_RECOMMENDED_LABEL) {
                                dispatch(actions.executeRemoveKidsRecommendedFromFilter())
                            }
                            if (currentFilters.exchanges.includes(filter)) {
                                dispatch(actions.executeRemoveExchangeFromFilter(filter))
                            }
                            if (filter === UNLISTED_INSTRUMENTS_LABEL) {
                                dispatch(actions.executeRemoveUnlistedInstrumentsFromFilter())
                            }
                            if (filter.includes(RISK_LEVEL_LABEL)) {
                                dispatch(actions.executeResetRiskLevelFilter())
                            }
                        }}
                        icon={<Close />}
                        isInlineDisplay
                        hasClearAllButton
                        onClearAllButtonClick={() => {
                            dispatch(actions.executeClearAllFilters())
                        }}
                    />
                </div>
            )}
        </>
    )
}

export default Controls
