import React from 'react'
import styles from './CustomImage.scss'

interface Props {
    imageUrl: string
}

const CustomImage = ({imageUrl}: Props) => {
    return (
        <div className={styles.imageWrapper}>
            <img src={imageUrl} alt="" />
        </div>
    )
}

export default CustomImage
