import {Modal} from '@design-system/modal'
import {withFormik} from 'formik'
import {DateTime} from 'luxon'
import React from 'react'
import {Request, Response} from '~/api/retail/types'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {IdentityVerificationStep} from '~/global/utils/identity-verification/identityVerification'
import {SharesiesOmit} from '~/global/utils/type-utilities/typeUtilities'
import {validate} from '~/global/widgets/form-controls'
import {Text} from '~/global/widgets/form-controls/formik'
import Image from '~/global/widgets/image/Image'
import {
    CommonHeader,
    CommonFooter,
    CommonIdFormProps,
    consentMessage,
} from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/IdentityFormCommon'
import driversLicenceDark from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/widgets/nz-forms/assets/images/id-number-dark.svg'
import driversLicenceLight from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/widgets/nz-forms/assets/images/id-number-light.svg'

type NZLicenceFormProps = CommonIdFormProps & {
    givenName?: string
    middleName?: string
    familyName?: string
    licenceNumber?: string
    licenceVersion?: string
    dateOfBirth?: DateTime
    verifyLicence(
        payload: SharesiesOmit<Request.IdentityVerifiNZLicence, Request.ActingAsRequired>,
    ): Promise<null | Response.Error>
}

type LicenceFormValues = SharesiesOmit<
    Request.IdentityVerifiNZLicence,
    Request.ActingAsRequired & {date_of_birth: string; consent: true}
> & {date_of_birth?: DateTime; consent: boolean}

export const LicenceForm = withFormik<NZLicenceFormProps, LicenceFormValues>({
    enableReinitialize: true,
    mapPropsToValues: ({
        givenName,
        middleName,
        familyName,
        dateOfBirth,
        licenceNumber,
        licenceVersion,
    }: NZLicenceFormProps) => {
        return {
            given_name: givenName || '',
            middle_name: middleName || '',
            family_name: familyName || '',
            date_of_birth: dateOfBirth || undefined,
            drivers_licence_number: licenceNumber || '',
            drivers_licence_version: licenceVersion || '',
            consent: false,
        }
    },
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        given_name: undefined,
    }),
    handleSubmit: async (values, {setSubmitting, setStatus, props: {verifyLicence, setIDDuplicateError, setStep}}) => {
        try {
            const error = await verifyLicence({
                ...values,
                // verifyLicence needs strings of the dates
                date_of_birth: values.date_of_birth ? values.date_of_birth.toISODate() : '',
                consent: values.consent as true,
            })
            if (error) {
                if (error.code === 'duplicate_id') {
                    setIDDuplicateError(true)
                    setStatus(undefined)
                } else {
                    setIDDuplicateError(false)
                    setStatus(error.message)
                }
                setSubmitting(false)
                return
            } else {
                setStep('completed' as IdentityVerificationStep)
            }
        } catch (e) {
            setStatus(unknownErrorMessage)
            setSubmitting(false)
            throw e
        }
    },
    validate: validate.generate<LicenceFormValues>({
        given_name: [],
        middle_name: [validate.middleName()],
        family_name: [validate.required()],
        date_of_birth: [validate.required(), validate.date(), validate.dateMinYear(1900), validate.notFutureDate()],
        drivers_licence_number: [validate.required()],
        drivers_licence_version: [validate.required()],
        consent: [validate.isTrue(consentMessage)],
    }),
})(({
    handleSubmit,
    isDependent,
    preferredName,
    isSubmitting,
    isValid,
    status,
    databases,
    isSecondaryId,
    setFieldValue,
}) => {
    const [showVersionHelp, setShowVersionHelp] = React.useState(false)
    return (
        <form onSubmit={handleSubmit}>
            <CommonHeader
                setFieldValue={setFieldValue}
                identityType="NZ drivers licence"
                isSecondaryId={isSecondaryId}
            />
            <Text
                dataTestId="text-input--drivers-licence-number"
                name="drivers_licence_number"
                label="Driver licence number"
            />
            <Text
                dataTestId="text-input--driver-licence-version"
                name="drivers_licence_version"
                label="Driver licence version"
                iconRight="HelpCircle"
                iconRightClick={() => setShowVersionHelp(true)}
                iconRightDataTestId="modal-link--licence-version-number"
            />{' '}
            <Modal
                isOpen={showVersionHelp}
                setIsOpen={setShowVersionHelp}
                title="Licence version number"
                dataTestId="modal--licence-version-number"
                primaryButton={{label: 'Ok'}}
            >
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Image
                        src={driversLicenceLight}
                        darkSrc={driversLicenceDark}
                        alt="Usually 3 digits next to the licence number"
                    />
                </div>
                <p>Your licence version number can be found on the front of your drivers licence.</p>
            </Modal>
            <CommonFooter
                isDependent={isDependent}
                preferredName={preferredName}
                isSubmitting={isSubmitting}
                databases={databases}
                isValid={isValid}
                jurisdiction="nz"
                error={status}
                isSecondaryId={isSecondaryId}
            />
        </form>
    )
})
