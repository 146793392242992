import {Button} from '@design-system/button'
import cn from 'classnames'
import {withFormik, FormikErrors} from 'formik'
import React from 'react'
import {Model, Request} from '~/api/retail/types'
import {page, spacing} from '~/global/scss/helpers'
import {ErrorBox} from '~/global/widgets/form-controls'
import {
    COUNTRIES,
    CountriesType,
    extractCountries,
} from '~/global/widgets/form-controls/country-select/CountrySelectInput'
import {CountrySelectInput} from '~/global/widgets/form-controls/formik'
import Citizenship from '~/global/widgets/help-modals/Citizenship'
import styles from '~/sections/invest/sections/us-sign-up/USSignUp.scss'
import {connect} from '~/store/connect'
import signUpActions from '~/store/sign-up/actions'

interface FormValues {
    isCitizenInJurisdiction?: boolean
    hasForeignCitizenship?: boolean
    countries: CountriesType
}

const ProvideOrEditCitizenship = withFormik<ProvideOrEditCitizenshipProps, FormValues>({
    mapPropsToValues: () => ({
        isCitizenInJurisdiction: undefined,
        hasForeignCitizenship: undefined,
        countries: [{country: '', tax_information_number: ''}],
    }),
    handleSubmit: async (
        {isCitizenInJurisdiction, countries},
        {setSubmitting, setStatus, props: {usSetCitizenShip, setCompletedCitizenship, jurisdiction}},
    ) => {
        const formattedCountries = extractCountries(countries)

        setSubmitting(true)
        try {
            await usSetCitizenShip(
                isCitizenInJurisdiction
                    ? [
                          ...formattedCountries,
                          //extract the country code of the jurisdiction country
                          ...COUNTRIES.filter(country => country[0] === jurisdiction!.toUpperCase()).map(
                              country => country[0],
                          ),
                      ]
                    : formattedCountries,
            )
            setCompletedCitizenship(true)
        } catch (error) {
            setStatus(error)
            setSubmitting(false)
            throw error
        }
    },
    validate: ({countries, hasForeignCitizenship}) => {
        const errors: FormikErrors<FormValues> = {}
        const formattedCountries = extractCountries(countries)

        if (
            hasForeignCitizenship &&
            (formattedCountries.length === 0 || !!countries.find(country => country.country === ''))
        ) {
            errors.countries = 'Oops, you haven’t added a country'
        }

        return errors
    },
})(({
    isSubmitting,
    status,
    values,
    isValid,
    isDependent,
    preferredName,
    jurisdiction,
    handleSubmit,
    validateForm,
    setValues,
}) => {
    const {isCitizenInJurisdiction, hasForeignCitizenship} = values

    const handleChange = (newValues: FormValues) => {
        setValues(newValues)
        // pass new values to validate against
        validateForm(newValues)
    }

    return (
        <form onSubmit={handleSubmit}>
            <p className={cn(spacing.spaceBelow16, styles.subheading)}>
                <strong>
                    {isDependent ? `Is ${preferredName}` : 'Are you'} an {jurisdiction === 'nz' ? 'NZ' : 'Australian'}{' '}
                    citizen?
                </strong>
            </p>
            <p className={cn(spacing.spaceBelow24, page.flexRow)}>
                <span>
                    <Button
                        label="Yes"
                        dataTestId={`button--citizen-of-${jurisdiction}-yes`}
                        type={values.isCitizenInJurisdiction === true ? 'primary' : 'secondary'}
                        onClick={() => handleChange({...values, isCitizenInJurisdiction: true})}
                    />
                </span>
                <span>
                    <Button
                        label="No"
                        dataTestId={`button--citizen-of-${jurisdiction}-no`}
                        type={values.isCitizenInJurisdiction === false ? 'primary' : 'secondary'}
                        onClick={() => handleChange({...values, isCitizenInJurisdiction: false})}
                    />
                </span>
            </p>

            <p className={cn(spacing.spaceBelow16, styles.subheading)}>
                <strong>{isDependent ? `Is ${preferredName}` : 'Are you'} a citizen of any other countries?</strong>
                <Citizenship />
            </p>
            <p className={cn(spacing.spaceBelow24, page.flexRow)}>
                <span>
                    <Button
                        label="Yes"
                        dataTestId="button--citizen-of-other-countries-yes"
                        type={values.hasForeignCitizenship === true ? 'primary' : 'secondary'}
                        onClick={() => {
                            handleChange({
                                ...values,
                                hasForeignCitizenship: true,
                                countries: [{country: '', tax_information_number: ''}],
                            })
                        }}
                    />
                </span>
                <span>
                    <Button
                        label="No"
                        dataTestId="button--citizen-of-other-countries-no"
                        type={values.hasForeignCitizenship === false ? 'primary' : 'secondary'}
                        onClick={() => {
                            handleChange({
                                ...values,
                                hasForeignCitizenship: false,
                                countries: [{country: '', tax_information_number: ''}],
                            })
                        }}
                    />
                </span>
            </p>

            {hasForeignCitizenship && (
                <CountrySelectInput
                    isCitizenshipCountryInput
                    name="countries"
                    countryLabel={`Which country ${isDependent ? `is ${preferredName}` : 'are you'} a citizen of?`}
                    className={styles.inputLabel}
                    jurisdiction={jurisdiction}
                />
            )}
            <ErrorBox message={status} />
            {isCitizenInJurisdiction !== undefined && hasForeignCitizenship !== undefined && (
                <Button
                    label="Next"
                    dataTestId="button--next"
                    isSubmit
                    pageButton
                    disabled={!isValid || (!isCitizenInJurisdiction && !hasForeignCitizenship)}
                    processing={isSubmitting}
                />
            )}
        </form>
    )
})

interface StoreProps {
    isDependent: boolean
    preferredName: string
    jurisdiction: Model.User['jurisdiction']
}

interface DispatchProps {
    usSetCitizenShip(countries: Request.SignUpUSSetCitizenship['countries']): Promise<void | null>
}

interface OwnProps {
    setCompletedCitizenship: React.Dispatch<React.SetStateAction<boolean>>
}

type ProvideOrEditCitizenshipProps = StoreProps & DispatchProps & OwnProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    ({identity}) => ({
        isDependent: identity.user!.is_dependent,
        preferredName: identity.user!.preferred_name,
        jurisdiction: identity.user!.jurisdiction,
    }),
    dispatch => ({
        usSetCitizenShip: countries => dispatch(signUpActions.usSetCitizenShip(countries)),
    }),
)(ProvideOrEditCitizenship)
