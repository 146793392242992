import cn from 'classnames'
import React from 'react'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import EditCitizenshipForm from '~/global/widgets/edit-citizenship-form/EditCitizenshipForm'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'

const CitizenshipDetails = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const owner = useProfileOwner()
    const citizenships = useRetailGet({path: 'owner/:owner_id/citizenships', pathParams: {owner_id: owner.id}}).data
        .countries

    const updateCitizenships = useRetailPost({
        path: 'owner/:owner_id/citizenships',
        pathParams: {owner_id: owner.id},
        queryCacheToUpdate: ['owner/:owner_id/citizenships', {owner_id: owner.id}],
    })

    return (
        <>
            <Toolbar dataTestId="toolbar--citizenship-details" leftButton="back" title="Citizenship details" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <h2 className={cn(spacing.spaceBelow12, typographyOverrides['as-h2'])}>Citizenship</h2>
                <EditCitizenshipForm
                    onSubmit={async countries => {
                        await updateCitizenships.mutateAsync({countries})
                    }}
                    successAction={() => navigate(profileUrl('settings/personal-details'))}
                    isDependent={owner.is_kids_account}
                    preferredName={owner.display_name}
                    jurisdiction={owner.product_jurisdiction}
                    citizenships={citizenships}
                />
            </Page>
        </>
    )
}

export default CitizenshipDetails
