import {TextInput, TextProps} from '@design-system/text-input'
import React from 'react'
import {withFocus, withId} from '../common'

export function normalizeBankBSB(value: string, previousValue: string = ''): string {
    if (value) {
        if (value + ' ' === previousValue) {
            // User backspaced a space, we'll just remove the last character instead
            value = value.replace(/.$/, '')
        }

        // Replace unacceptable characters, including -
        value = value.replace(/\D/g, '')
        // Remove anything too long
        value = value.substring(0, 6)
        value = value.replace(/^(...)?(.*)/, (_match, first, remainder) => {
            let result = ''
            if (first) {
                result += first + ' '
            }
            return result + remainder
        })
    }
    return value
}

const BankBSB = (props: TextProps) => (
    <TextInput
        {...props}
        onChange={e => {
            if (!props.setFieldValue) {
                return
            }
            props.setFieldValue(normalizeBankBSB(e.currentTarget.value, props.value || ''))
        }}
    />
)

const BankAccountWithFocus = withId(withFocus(BankBSB))

export default BankAccountWithFocus
