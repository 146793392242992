const bsbLength = 6
const minAuAccountLength = 4
const maxAuAccountLength = 9

export const splitAccountAndBsb = (accountNumberInput: string) => {
    const strippedAccountNumber = accountNumberInput.replace(/\D/g, '')

    const bsb = strippedAccountNumber.substring(0, 6)
    const accountNumber = strippedAccountNumber.substring(6)

    if (
        bsb.length !== bsbLength ||
        accountNumber.length < minAuAccountLength ||
        accountNumber.length > maxAuAccountLength
    ) {
        return {
            success: false as const,
        }
    }

    return {
        success: true as const,
        accountNumber,
        bsb,
    }
}
