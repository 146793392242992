import {ModalLink} from '@design-system/modal'
import React from 'react'
import {HelpContentBodyRenderer} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {PricesOnMarketContent} from '~/sections/explore/pages/learn-static-content/LearnContent'
import {Instrument} from '~/store/instrument/types'

export const PricesExtendedHours: React.FunctionComponent<{
    instrument: Instrument
    usLivePricing?: boolean | undefined
}> = ({instrument, usLivePricing}) => {
    const title = 'Extended hours prices for exchange-traded investments'

    return (
        <ModalLink
            dataTestId="modal-link--prices-in-extended-hours"
            label={title}
            asIcon
            modalTitle={title}
            primaryButton={{label: 'Ok, got it'}}
            bottomBorder
            helpIconSize={16}
        >
            <HelpContentBodyRenderer
                page={PricesOnMarketContent}
                instrument={instrument}
                usLivePricing={usLivePricing}
                inExtendedHours={true}
            />
        </ModalLink>
    )
}

export default PricesExtendedHours
