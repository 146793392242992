import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {DistillScope} from '~/api/query/distill'
import {getStringFromError, useRetailPost} from '~/api/query/retail'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {convertDecimalToPercentage} from '~/global/utils/convert-decimal-to-percentage/convertDecimalToPercentage'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {PercentValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useDistillInstrument} from '~/sections/kiwisaver/data/distill'
import {
    useExistingKSCustomer,
    useInvestmentPlanOrNullForEditing,
    useMarketingSiteInvestmentPlanOrNullForEditing,
} from '~/sections/kiwisaver/state'
import KSFundFees from '~/sections/kiwisaver/widgets/fees/KSFundFees'
import {
    InvestmentPlanBaseAllocationCard,
    InvestmentPlanSelfSelectAllocationsCard,
} from '~/sections/kiwisaver/widgets/investment-plan-allocation-card/InvestmentPlanAllocationCard'
import {RiskIndicator} from '~/sections/kiwisaver/widgets/risk-indicator/RiskIndicator'
import {useAppSelector} from '~/store/hooks'
import {actingAsID} from '~/store/identity/selectors'
import {SignUpGuardRedirect, SignUpPage} from '../sign-up/widgets/redirect/SignUpGuardRedirect'
import {usePlanEditorUrl} from './hooks/usePlanEditorUrl'
import styles from './index.scss'

export const Home: React.FunctionComponent<{}> = () => {
    const customer = useExistingKSCustomer()
    const acting_as_id = useAppSelector(actingAsID)
    const investmentPlan = useInvestmentPlanOrNullForEditing()
    const marketingSiteInvestmentPlan = useMarketingSiteInvestmentPlanOrNullForEditing()
    const hasSeenSelfSelectEducation = useAppSelector(s => s.identity.user?.has_seen.ks_self_select_education_screens)
    const profileUrl = useProfileUrl()
    const relativeUrlFor = usePlanEditorUrl()

    const setInvestmentPlanActive = useRetailPost({
        path: 'kiwisaver/activate-draft-investment-plan',
        queryCacheToUpdate: ['kiwisaver/customer'],
    })

    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const hasSelfSelectPicksInInvestmentPlan = investmentPlan?.allocations?.some(a => a.fund_category === 'SELF_SELECT')
    const showSelfSelectFeesText = hasSelfSelectPicksInInvestmentPlan
    const marketingSitePlanWithSelfSelects =
        marketingSiteInvestmentPlan &&
        marketingSiteInvestmentPlan.allocations.find(allocation => allocation.fund_category === 'SELF_SELECT')

    const onAccept = async () => {
        setIsSubmitting(true)
        try {
            // Check to see if they actually have a draft to activate
            if (customer.draft_investment_plan) {
                await setInvestmentPlanActive.mutateAsync({
                    acting_as_id,
                })
            }
            rudderTrack('kiwisaver_signup', 'investment_plan_selected', {
                type: customer.customer_state === 'SIGNUP' ? 'signup' : 'edit',
            })

            // nav to the next step in sign-up in case that's the flow we're in.
            // if we're not, the sign up redirect guard will just return them to home
            navigate(profileUrl('kiwisaver/sign-up/tax'))
        } catch (e) {
            if (getStringFromError(e, 'code') === 'activate_draft_investment_plan_incorrect_pds') {
                Toast('You must accept the PDS & IOS first')
                navigate(relativeUrlFor('pds'))
            } else {
                Toast('Could not activate investment plan')
            }
        } finally {
            setIsSubmitting(false)
        }
    }

    const baseAllocations = investmentPlan?.allocations.filter(a => a.fund_category === 'BASE')
    const selfSelectAllocations = investmentPlan?.allocations.filter(a => a.fund_category === 'SELF_SELECT')
    const instrument = useDistillInstrument({
        instrumentId: baseAllocations![0].fund_id,
        scope: DistillScope.KIWISAVER_BASE_FUNDS,
    })

    return (
        <SignUpGuardRedirect currentPage={SignUpPage.EDIT_INVESTMENT_PLAN_HOME}>
            <Toolbar leftButton="back" dataTestId="toolbar--edit-investment-plan-home" title="Your investment plan" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                {baseAllocations &&
                    baseAllocations.map((a, index) => (
                        <InvestmentPlanBaseAllocationCard key={index} allocation={a} className={spacing.spaceBelow16} />
                    ))}
                <div>
                    {investmentPlan && selfSelectAllocations && selfSelectAllocations.length > 0 ? (
                        <>
                            <InvestmentPlanSelfSelectAllocationsCard
                                allocations={selfSelectAllocations}
                                className={spacing.spaceBelow16}
                            />
                            <RiskIndicator rating={investmentPlan.risk_indicator} />
                        </>
                    ) : (
                        <div
                            onClick={() => {
                                rudderTrack('kiwisaver_signup', 'self_select_started', {
                                    is_using_marketing_site_plan: !!marketingSiteInvestmentPlan,
                                })
                                if (hasSeenSelfSelectEducation) {
                                    navigate(relativeUrlFor('add-funds'))
                                } else {
                                    navigate(relativeUrlFor('tips-for-choosing-picks'))
                                }
                            }}
                            className={styles.addPicks}
                            data-testid="button--add-picks"
                        >
                            {marketingSitePlanWithSelfSelects ? (
                                <>Confirm your saved picks, or select new ones</>
                            ) : (
                                <>If you like, you can add some other investments to your plan</>
                            )}
                            <ButtonAsLink>
                                {marketingSitePlanWithSelfSelects ? <>Continue to picks</> : <>Add your own picks</>}
                            </ButtonAsLink>
                        </div>
                    )}
                </div>

                <div className={styles.bottomText}>
                    <p>
                        Fees for this investment plan will be{' '}
                        <PercentValue value={convertDecimalToPercentage(instrument.managementFeePercent!)} /> of your
                        base fund balance per year{showSelfSelectFeesText && ' + fees on your picks'}&mdash;
                        <ModalLink
                            dataTestId="modal-link--ks-self-select-fund-fees"
                            label="see the details"
                            modalTitle="Sharesies KiwiSaver Scheme fees"
                            primaryButton={{label: 'Got it'}}
                            bottomBorder
                            additionalClassName={styles.bottomTextButton}
                        >
                            <KSFundFees />
                        </ModalLink>
                        .
                    </p>
                </div>
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--select"
                    label="Select this investment plan"
                    onClick={onAccept}
                    processing={isSubmitting}
                />
            </ActionBar>
        </SignUpGuardRedirect>
    )
}
