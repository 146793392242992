import {DockableModal} from '@design-system/dockable-modal'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import ExchangeOpenStatusRefetch from '~/global/data-components/ExchangeOpenStatusRefetch'
import {accessibility} from '~/global/scss/helpers'
import {dateFormatFullDay, dateFormatTimeAmPm} from '~/global/utils/format-date/formatDate'
import {
    isInstrumentCloseOnly,
    isInstrumentInHalt,
    isInstrumentInactive,
} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {ExchangeOpen, ExchangeClosed, TradingHalt, SellOnly} from '~/global/widgets/OLD_icons'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {useAppSelector} from '~/store/hooks'
import {selectExchangeForInstrument, selectIsInstrumentInExtendedHours} from '~/store/instrument/selectors'
import {Instrument} from '~/store/instrument/types'
import styles from './ExchangeHours.scss'

interface ExchangeHoursProps {
    instrument: Exclude<Instrument, {instrumentType: 'mf'}>
    suppressExtendedHours?: boolean
}

export const ExchangeHours: React.FunctionComponent<ExchangeHoursProps> = ({instrument, suppressExtendedHours}) => {
    const {exchange, isExchangeInMarketHours} = useAppSelector(s => selectExchangeForInstrument(s, instrument.exchange))
    const isInExtendedHours = useAppSelector(s => selectIsInstrumentInExtendedHours(s, instrument))

    const instrumentInHalt = isInstrumentInHalt(instrument)
    const instrumentCloseOnly = isInstrumentCloseOnly(instrument)
    const instrumentIsInactive = isInstrumentInactive(instrument)
    const instrumentIsInExtendedHours = !!isInExtendedHours && suppressExtendedHours !== true // coerce from object of pre and post market states (for now we treat both the same), plus only show if we're not in e.g. auto-invest
    const instrumentIsInExtendedHoursCloseOnly = instrumentCloseOnly && instrumentIsInExtendedHours

    const [exchangeHoursModalOpen, setExchangeHoursModalOpen] = React.useState(false)

    // when the modal is open & user presses Escape key, this closes the modal
    React.useEffect(() => {
        if (!exchangeHoursModalOpen) {
            return
        }

        const handleEscapeKey = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                setExchangeHoursModalOpen(false)
            }
        }

        window.addEventListener('keyup', handleEscapeKey)

        return () => {
            window.removeEventListener('keyup', handleEscapeKey)
        }
    }, [exchangeHoursModalOpen])

    if (!exchange) {
        return null
    }

    const displayExchangeIcon = instrumentInHalt ? (
        <TradingHalt />
    ) : instrumentCloseOnly || instrumentIsInExtendedHoursCloseOnly ? (
        <SellOnly />
    ) : instrumentIsInExtendedHours || isExchangeInMarketHours ? (
        <ExchangeOpen />
    ) : (
        <ExchangeClosed />
    )

    const displayExchangeStateText = instrumentInHalt
        ? 'Halt'
        : instrumentIsInExtendedHoursCloseOnly
          ? 'Extended\u00A0hours\u00A0sell\u00A0only' // takes precedence over 'Sell only' as that is also true in this case
          : instrumentCloseOnly
            ? 'Sell\u00A0only' // \u00A0 is JSX safe &nbsp;
            : isExchangeInMarketHours
              ? 'Open'
              : instrumentIsInExtendedHours
                ? 'Extended\u00A0hours'
                : 'Closed'

    const exchangeHoursContent = instrumentInHalt ? (
        <div className={styles.haltText}>
            <p>
                A trading halt is when an exchange temporarily stops trading of an investment due to an upcoming news
                announcement, an order imbalance, technical issues or regulatory concerns.
            </p>
            {/* TODO - add help centre link once specific content is ready*/}
        </div>
    ) : instrumentCloseOnly ? (
        <div className={styles.haltText}>
            <p>
                Sell only is when Sharesies, an exchange, or another third party stops buy orders for an investment.
                This may be due to a company announcement, corporate action, order imbalance, technical issues, or
                regulatory concerns. You can still sell any {shareLabel({instrument, isPlural: true})} you hold.
            </p>
        </div>
    ) : (
        <div className={styles.dateTimeRows}>
            {Array(7) // We want to show 7 days in the modal.
                .fill(null)
                .map((_, index) => {
                    //We first check to see if the exchange hours array is ahead of the user's current day to account
                    //for days where the exchange is closed. As we want to show the user's 'today,'
                    //if the exchange hours array is ahead of the first day, we show the user's current day.
                    //If this is not the case we then check the user's current hour + day and see if it is within the opening hours
                    //if so we display that day. If we are not within opening hours or the exchange hours array from Distill
                    // is not ahead we just default to the current day for safety.
                    let firstDayToShow
                    if (exchange.openHours[0].start.toLocal().day > DateTime.local().day) {
                        firstDayToShow = DateTime.local()
                    } else {
                        if (
                            exchange.openHours[0].finish.toLocal().hour >= DateTime.local().hour &&
                            exchange.openHours[0].start.toLocal().hour <= DateTime.local().hour &&
                            exchange.openHours[0].start.toLocal().day === DateTime.local().day
                        ) {
                            firstDayToShow = exchange.openHours[0].start.toLocal()
                        } else {
                            firstDayToShow = DateTime.local()
                        }
                    }
                    const expectedDay = index === 0 ? firstDayToShow : firstDayToShow.plus({days: index})
                    const openDay = exchange.openHours.find(openDay => expectedDay.day === openDay.start.toLocal().day)
                    const localExchangeStartTime = openDay ? openDay.start.toLocal() : undefined
                    const localExchangeEndTime = openDay ? openDay.finish.toLocal() : undefined

                    return (
                        <span key={index}>
                            {expectedDay.toFormat(dateFormatFullDay)}
                            {localExchangeStartTime && localExchangeEndTime ? (
                                <p>
                                    {localExchangeStartTime.toFormat(dateFormatTimeAmPm)} -{' '}
                                    {localExchangeEndTime.toFormat(dateFormatTimeAmPm)}
                                </p>
                            ) : (
                                <p>Closed</p>
                            )}
                        </span>
                    )
                })}
        </div>
    )

    return (
        <>
            {!instrumentIsInactive && (
                <div className={styles.marketIndicatorWrapper}>
                    <button
                        type="button"
                        className={cn(styles.marketIndicator, accessibility.button)}
                        onClick={() => setExchangeHoursModalOpen(true)}
                    >
                        <p>{displayExchangeStateText}</p>
                        {displayExchangeIcon}
                    </button>
                </div>
            )}

            <DockableModal
                dataTestId="modal--exchange-hours"
                isOpen={exchangeHoursModalOpen}
                setIsOpen={setExchangeHoursModalOpen}
                title={
                    instrumentInHalt || instrumentCloseOnly ? (
                        displayExchangeStateText
                    ) : (
                        <>
                            <PronounceLetters text={exchange.name} /> hours
                        </>
                    )
                }
                content={exchangeHoursContent}
                customZIndex={1051} // same as zindex(detail-modal)
            />
            {/* Update exchange open status periodically - 1 hour */}
            <ExchangeOpenStatusRefetch />
        </>
    )
}
