import {Button} from '@design-system/button'
import {InvestFilled} from '@design-system/icon'
import {Form, Formik} from 'formik'
import React from 'react'
import {useNavigate} from 'react-router'
import {useRetailPost} from '~/api/query/retail'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {validate} from '~/global/widgets/form-controls'
import {StrongNumber} from '~/global/widgets/form-controls/formik'
import {ShareValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useDistillInstrumentWithCurrency} from '~/sections/moose/data/distill'
import {useHoldingDetailForSymbol} from '~/sections/moose/data/retail'
import {ShareholdingLimitsModal} from '~/sections/moose/sections/order/widgets/help-for-shareholding-order/HelpForShareholdingOrder'
import {useStagedTransfer} from '~/sections/moose/sections/transfer/hooks/useStagedTransfer'
import {isShareType} from '~/sections/moose/sections/utils/symbol-helpers/symbolHelpers'

interface FormValues {
    units: string
}

export const UnitsSelect = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const [stagedTransfer, setStagedTransfer] = useStagedTransfer()
    const instrument = useDistillInstrumentWithCurrency(
        isShareType(stagedTransfer.instrumentSymbol) ? 'nzx-fcg' : 'fsf',
    )
    const fromPortfolioHoldingDetail = useHoldingDetailForSymbol(
        stagedTransfer.fromPortfolioId!,
        stagedTransfer.instrumentSymbol!,
    )

    const submitTransferQuery = useRetailPost({
        path: 'fonterra/portfolios/:portfolio_id/orders/create-transfer',
        pathParams: {portfolio_id: stagedTransfer.fromPortfolioId!},
    })

    // can transfer either all units permitted to sell or their entire holding (happens when theres no minimum or lien shares)
    const availableUnits = fromPortfolioHoldingDetail.permitted_to_sell ?? fromPortfolioHoldingDetail.holding
    const maxTransferUnits = Math.max(availableUnits, 0)

    const sharesOrUnits = isShareType(stagedTransfer.instrumentSymbol) ? 'shares' : 'units'

    const onError = () => {
        Toast('We couldn’t complete your request')
        navigate(
            profileUrl('fonterra/portfolios/:portfolioId/manage', {portfolioId: stagedTransfer.initiatingPortfolioId}),
        )
    }

    const onNext = async (units: string) => {
        setStagedTransfer({...stagedTransfer, units: Number(units)})
        if (!stagedTransfer.fromPortfolioId || !stagedTransfer.toPortfolioId) {
            onError()
            return
        }

        let response
        try {
            response = await submitTransferQuery.mutateAsync({
                fund_id: instrument.id,
                from_portfolio_id: stagedTransfer.fromPortfolioId,
                to_portfolio_id: stagedTransfer.toPortfolioId,
                shares: Number(units),
            })
        } catch (error) {
            onError()
        }

        if (response && response.request_successful) {
            Toast('Share transfer has been requested')
            navigate(
                profileUrl('fonterra/portfolios/:portfolioId/manage', {
                    portfolioId: stagedTransfer.initiatingPortfolioId,
                }),
            )
        } else {
            onError()
        }
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--transfer-intro"
                leftButton="back"
                title={`How many ${sharesOrUnits} do you want to transfer?`}
            />
            <Formik
                initialValues={{units: stagedTransfer.units ? stagedTransfer.units.toString() : ''} as FormValues}
                initialErrors={{units: ''}}
                validate={validate.generate<FormValues>({
                    units: [
                        validate.required(),
                        validate.minimum(1, 'Must transfer at least 1'),
                        validate.maximum(
                            maxTransferUnits,
                            `The CSN you are transferring from has ${maxTransferUnits} ${sharesOrUnits} to transfer.`,
                        ),
                    ],
                })}
                onSubmit={values => {
                    onNext(values.units!)
                }}
            >
                {({isValid, isSubmitting}) => (
                    <Form>
                        <Page>
                            <StrongNumber
                                normalisation="numberOnly"
                                decimalPlaces={0}
                                dataTestId="strong-number--transfer-units"
                                name="units"
                                label={`Number of ${sharesOrUnits}`}
                                disabled={isSubmitting}
                                helpText={
                                    <>
                                        <InvestFilled size={12} /> <ShareValue value={maxTransferUnits} />{' '}
                                        {sharesOrUnits} available to transfer{' '}
                                        {isShareType(stagedTransfer.instrumentSymbol) && (
                                            <ShareholdingLimitsModal
                                                portfolioId={stagedTransfer.fromPortfolioId!}
                                                symbol="FCG"
                                            />
                                        )}
                                    </>
                                }
                            />
                        </Page>
                        <ActionBar>
                            <Button
                                dataTestId="button--complete-transfer"
                                label="Complete transfer"
                                disabled={!isValid}
                                processing={isSubmitting}
                                isSubmit
                            />
                        </ActionBar>
                    </Form>
                )}
            </Formik>
        </>
    )
}
