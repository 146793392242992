import {ArrowRight} from '@design-system/icon'
import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {useEssViewsForInstrument} from '~/global/state-hooks/mixed-source/useEssViewsForInstrument'
import {Link} from '~/migrate-react-router'
import {Instrument} from '~/store/instrument/types'
import styles from './ShareSchemeBlurb.scss'

interface Props {
    instrument: Instrument
}
const ShareSchemeBlurb = ({instrument}: Props) => {
    const essViews = useEssViewsForInstrument(instrument.id)

    return (
        <>
            <p className={!essViews.isActiveMember ? spacing.spaceBelow24 : ''}>
                You’re part of a {instrument.name} {essViews.hasShareScheme ? 'share ' : ''}scheme.{' '}
            </p>
            {essViews.isActiveMember && (
                <Link
                    className={spacing.spaceBelow24}
                    onClick={() => {
                        rudderTrack('ess_scheme', 'scheme_view_clicked')
                    }}
                    to={`/employee-share-scheme/${instrument.id}/schemes`}
                >
                    <strong>
                        View scheme details <ArrowRight className={styles.icon} />
                    </strong>
                </Link>
            )}
        </>
    )
}

export default ShareSchemeBlurb
