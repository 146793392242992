import {Button} from '@design-system/button'
import {SelectCards} from '@design-system/select-cards'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {Model} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {estimateUserLocation} from '~/global/utils/estimate-user-location/estimateUserLocation'
import Page from '~/global/widgets/page/Page'

type Jurisdiction = Model.User['jurisdiction']
interface CountrySelectProps {
    selectedCountry: Jurisdiction | null
    setSelectedCountry(country: Jurisdiction | null): void
    setCountrySubmitted(value: boolean): void
}

const CountrySelect = ({selectedCountry, setSelectedCountry, setCountrySubmitted}: CountrySelectProps) => {
    const estimatedLocation = estimateUserLocation({useReferrer: true, useBrowserTimezone: true})
    const countries: Jurisdiction[] = ['nz', 'au']
    const defaultCountry = estimatedLocation ? estimatedLocation : 'nz'
    const sortedCountries = countries.sort((a, b) => (a === defaultCountry ? -1 : b === defaultCountry ? 1 : 0))

    // send GA event for each help centre link click
    const registerHelpCentreClick = (linkFor: Jurisdiction) => {
        Analytics.event({
            category: 'Sign Up',
            action: 'Help centre link',
            label: `${linkFor === 'au' ? 'Australia' : 'New Zealand'} identification`,
        })
    }

    const onSubmit = () => {
        setCountrySubmitted(true)
        Analytics.event({
            // send GA event when continuing to about you details page
            category: 'Sign Up',
            action: 'Next button',
            label: 'Jurisdiction selection',
        })

        // make typescript happy with selectedCountry's nulliness
        if (selectedCountry) {
            rudderTrack('signup', 'location_selected', {jurisdiction: selectedCountry})
        }
    }

    return (
        <Page>
            <h1 className={spacing.spaceBelow16}>Where do you live?</h1>
            <p className={spacing.spaceBelow24}>
                Learn about what ID you need to sign up for{' '}
                <a
                    href="https://intercom.help/sharesies/en/articles/1738992-what-id-do-i-need-to-sign-up-to-sharesies-new-zealand"
                    target="_blank"
                    rel="noopener"
                    onClick={() => {
                        registerHelpCentreClick('nz')
                    }}
                >
                    New Zealand
                </a>{' '}
                or{' '}
                <a
                    href="https://intercom.help/sharesies-au/en/articles/5135944-id-you-need-to-sign-up-to-sharesies-australia"
                    target="_blank"
                    rel="noopener"
                    onClick={() => {
                        registerHelpCentreClick('au')
                    }}
                >
                    Australia
                </a>
                .
            </p>

            <SelectCards
                options={sortedCountries.map(country => ({
                    value: country,
                    name: country,
                    title: `I live in ${country === 'au' ? 'Australia' : 'New Zealand'}`,
                    supportingText: `Have ${country === 'au' ? 'an Australian' : 'a New Zealand'} ID handy`,
                    isActive: country === selectedCountry,
                }))}
                title="Where do you live?"
                hideTitle
                dataTestId="select-cards--country"
                onChange={e => setSelectedCountry(e.target.value as 'nz' | 'au')}
            />

            <Button
                dataTestId="button--next"
                label="Next"
                pageButton
                isSubmit
                disabled={selectedCountry === null}
                onClick={onSubmit}
            />
        </Page>
    )
}

export default CountrySelect
