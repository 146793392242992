import {ArrowDown, ArrowUp} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import tooltipStyles from '~/global/scss/reused-styles/tooltip.scss'
import {useEssReturnPercentage} from '~/global/state-hooks/mixed-source/useEssReturnPercentage'
import {useEssTotalUnvestedInvestmentValue} from '~/global/state-hooks/mixed-source/useEssTotalUnvestedInvestmentValue'
import {useEssViewsForInstrument} from '~/global/state-hooks/mixed-source/useEssViewsForInstrument'
import {DollarValue, PercentValue} from '~/global/widgets/number-elements/NumberElements'
import Tooltip from '~/global/widgets/tooltip/Tooltip'
import {useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import {InstrumentReturn} from '~/store/portfolio/types'
import AlignmentRightsTotalValue from './widgets/alignment-rights-total-value/AlignmentRightsTotalValue'
import AllocationsSummary from './widgets/allocations-summary/AllocationsSummary'
import AllocationsVestProgress from './widgets/allocations-vest-progress/AllocationsVestProgress'
import styles from './EmployeeShareScheme.scss'

interface EmployeeShareSchemeOverviewProps {
    instrument: Instrument
    currentReturn?: InstrumentReturn
}

const EmployeeShareSchemeOverview: React.FunctionComponent<EmployeeShareSchemeOverviewProps> = ({
    instrument,
    currentReturn,
}) => {
    const shareSchemeParticipation = useAppSelector(s => s.employeeShareScheme.shareSchemeParticipation)
    const shareSchemeAllocations = useAppSelector(s => s.employeeShareScheme.shareSchemeAllocationsByInstrument)
    const essViews = useEssViewsForInstrument(instrument.id)
    const participationForInstrument = shareSchemeParticipation[instrument.id]
    const allocationsForInstrument = shareSchemeAllocations[instrument.id]
    const returnPercentage = useEssReturnPercentage(instrument.id)
    const totalUnvestedInvestmentValue = useEssTotalUnvestedInvestmentValue(instrument.id)

    // Show this component if there's unvested value or the member is part of an Alignment Rights Scheme
    const showSchemeOverview = totalUnvestedInvestmentValue !== undefined || essViews.hasAlignmentRightScheme
    if (!allocationsForInstrument || !participationForInstrument || !showSchemeOverview) {
        return null
    }

    const hasHolding = !!currentReturn?.investment_value
    const hasRightAllocation = essViews.hasRightAllocation
    const hasUnvestedAllocation = essViews.hasUnvestedAllocation
    const hasAlignmentRightAllocation = essViews.hasAlignmentRightAllocation
    const hasVestedAlignmentRightAllocation = essViews.hasVestedAlignmentRightAllocation

    if (!essViews.isActiveMember) {
        return null
    }

    const title = () => {
        if (hasHolding) {
            return 'Your shares'
        }
        if (hasRightAllocation) {
            return 'Potential value'
        }
        if (hasAlignmentRightAllocation) {
            if (hasVestedAlignmentRightAllocation) {
                return 'Amount received'
            }
            return 'Estimated potential value'
        }
        return 'Shares to receive'
    }

    return (
        <div className={cn(styles.cardContainer, spacing.spaceBelow24)} data-testid="card--ess-overview">
            <h2 className={spacing.spaceBelow16}>{title()}</h2>
            {hasHolding && (
                <>
                    <div className={styles.textAndBadge}>
                        <Tooltip>
                            <h2 className={cn(styles.vestedSharesValue, tooltipStyles.label, spacing.spaceBelow4)}>
                                <DollarValue value={currentReturn.investment_value} currency={instrument.currency} />
                            </h2>
                            <TooltipContent
                                hasUnvestedAllocation={hasUnvestedAllocation}
                                hasRightAllocation={hasRightAllocation}
                                hasHolding={hasHolding}
                            />
                        </Tooltip>
                        {!hasRightAllocation && returnPercentage !== undefined && (
                            <ReturnBadge returnPercentage={returnPercentage} hasHolding={hasHolding} />
                        )}
                    </div>
                    {essViews.hasUnvestedAllocation && totalUnvestedInvestmentValue && (
                        <p className={spacing.spaceAbove4}>
                            <DollarValue value={totalUnvestedInvestmentValue} currency={instrument.currency} />{' '}
                            {hasRightAllocation ? 'potential value' : 'to receive'}
                        </p>
                    )}
                </>
            )}
            {!hasHolding && (essViews.hasUnvestedAllocation || hasAlignmentRightAllocation) && (
                <>
                    {!hasAlignmentRightAllocation && totalUnvestedInvestmentValue && (
                        <div className={styles.textAndBadge}>
                            <Tooltip>
                                <h2 className={tooltipStyles.label}>
                                    <DollarValue value={totalUnvestedInvestmentValue} currency={instrument.currency} />
                                </h2>
                                <TooltipContent
                                    hasUnvestedAllocation={hasUnvestedAllocation}
                                    hasRightAllocation={hasRightAllocation}
                                    hasHolding={hasHolding}
                                />
                            </Tooltip>
                        </div>
                    )}
                    {hasAlignmentRightAllocation && (
                        <AlignmentRightsTotalValue
                            instrument={instrument}
                            allocationsForInstrument={allocationsForInstrument}
                        />
                    )}
                    {!hasRightAllocation && returnPercentage !== undefined && (
                        <div className={styles.textAndBadge}>
                            <ReturnBadge returnPercentage={returnPercentage} hasHolding={hasHolding} />
                        </div>
                    )}
                </>
            )}
            <AllocationsVestProgress
                vestedAllocationYears={allocationsForInstrument.total_vested_allocation_years}
                unvestedAllocationYears={allocationsForInstrument.total_unvested_allocation_years}
            />
            <AllocationsSummary
                instrument={instrument}
                participationForInstrument={participationForInstrument}
                allocationsForInstrument={allocationsForInstrument}
                hasUnvestedShares={essViews.hasUnvestedAllocation}
                hasVestedShares={essViews.hasVestedAllocation}
                essViews={essViews}
            />
        </div>
    )
}

interface ReturnBadgeProps {
    returnPercentage: number
    hasHolding: boolean
}

const ReturnBadge: React.FunctionComponent<ReturnBadgeProps> = ({returnPercentage, hasHolding}) => {
    const hasNegativeReturn = returnPercentage < 0
    const hasPositiveReturn = returnPercentage > 0
    return (
        <div className={cn(styles.badge, hasHolding && styles.vestedReturn)}>
            {hasNegativeReturn && <ArrowDown className={styles.icon} />}
            {hasPositiveReturn && <ArrowUp className={styles.icon} />}
            <span>
                <PercentValue value={returnPercentage} /> <span className={styles.badgeReturn}>return</span>
            </span>
        </div>
    )
}

interface TooltipContentProps {
    hasUnvestedAllocation: boolean
    hasRightAllocation: boolean
    hasHolding: boolean
}

const TooltipContent: React.FunctionComponent<TooltipContentProps> = ({
    hasUnvestedAllocation,
    hasRightAllocation,
    hasHolding,
}) => {
    return (
        <p className={tooltipStyles.tooltip}>
            Delayed price multiplied by the number of
            {hasUnvestedAllocation && ' shares'}
            {hasUnvestedAllocation && hasRightAllocation && ' and'}
            {hasRightAllocation && ' share rights'}
            {hasHolding ? ' you own' : ' you’re set to receive'}
        </p>
    )
}

export default EmployeeShareSchemeOverview
