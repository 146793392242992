/*
Generates a series of date-list labels along with their respective cutoffs. This
can be used to generate "nice" date headings for a time-series list.
*/
import {DateTime} from 'luxon'
function* dateHeadings(): Generator<{label: string; dt: DateTime}, {label: string; dt: DateTime}> {
    yield {label: 'Today', dt: DateTime.local().startOf('day')}
    yield {label: 'Yesterday', dt: DateTime.local().startOf('day').minus({days: 1})}
    let dt = DateTime.local().startOf('month')
    yield {label: 'This month', dt}
    while (true) {
        dt = dt.minus({months: 1})
        yield {label: dt.toFormat('LLLL yyyy'), dt}
    }
}

export const monthlyLabelGenerator = () => {
    const dh = dateHeadings()
    let currentHeading = dh.next().value
    let lastHeading: typeof currentHeading | undefined

    return (dt: DateTime): string | undefined => {
        while (dt < currentHeading.dt) {
            currentHeading = dh.next().value
        }
        const showHeading = lastHeading !== currentHeading
        lastHeading = currentHeading
        return showHeading ? currentHeading.label : undefined
    }
}
