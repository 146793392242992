import RedditPixel, {EventPayload} from 'react-reddit-pixel'
import config from '~/configForEnv'

/**
 * react-reddit-pixel doesn't actually provide any React related functionality
 * It's just a nice API wrapper around the Reddit Pixel script that adds the script to the head element and has logging.
 * https://github.com/gsajith/react-reddit-pixel
 *
 * We've only got a NZ reddit ad account at this stage.
 */
if (config.redditAdAccountIdNZ) {
    RedditPixel.init(config.redditAdAccountIdNZ, {debug: false})
}

export const redditTrackSignUp = () => {
    RedditPixel.track('SignUp')
}

type RedditTrackCustomEvents =
    | 'KiwiSaver Wait List Register'
    | 'KiwiSaver Account Open'
    | 'Save Account Open'
    | 'Kids Account Open'

/**
 * Track custom events that aren't covered by the default Reddit Pixel events
 *
 * @param {RedditTrackCustomEvents} customEventName - CustomEventName
 */
export const redditTrackCustom = (customEventName: RedditTrackCustomEvents, eventPayload: EventPayload = {}) => {
    RedditPixel.rdt('track', 'Custom', {
        customEventName,
        ...eventPayload,
    })
}

export const redditTrackGiftPurchase = (giftAmount: number, currency: string) => {
    RedditPixel.track('Purchase', {
        currency,
        value: giftAmount,
    })
}
