import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {KSCustomer} from '~/sections/kiwisaver/state'

const AGE_OF_RETIREMENT: number = 65

const firstHomeDepositTimeline = (timeline: KSCustomer['first_home_deposit_timeline']) => {
    if (timeline) {
        const timelineMapper = {
            WITHIN_A_YEAR: 'less than a year',
            ONE_TO_FOUR_YEARS: '1-4 years',
            FIVE_TO_10_YEARS: '5-10 years',
            MORE_THAN_10_YEARS: 'more than 10 years',
        }
        return timelineMapper[timeline]
    }
}

const prefixWithdrawalTimelineContent = (customer: KSCustomer) => {
    if (customer.purpose === 'HOUSE_DEPOSIT') {
        return (
            <>
                <strong> {firstHomeDepositTimeline(customer.first_home_deposit_timeline)} </strong>
                until you plan to withdraw your KiwiSaver for a home deposit.
            </>
        )
    } else {
        const retirementYearIn = AGE_OF_RETIREMENT - customer.current_age
        return (
            <>
                <strong>
                    {retirementYearIn} {retirementYearIn > 1 ? 'years ' : 'year '}
                </strong>
                until you can withdraw your KiwiSaver for retirement.
            </>
        )
    }
}

const WithdrawalTimeline: React.FunctionComponent<{customer: KSCustomer}> = ({customer}) => {
    const interestedIn = customer.interested_in ?? 'GROWTH'

    return (
        <p data-testid="paragraph--withdrawal-timeline" className={(spacing.spaceAbove16, spacing.spaceBelow24)}>
            You’re interested in
            <strong> {interestedIn.toLowerCase()} </strong>
            funds, and
            {AGE_OF_RETIREMENT <= customer.current_age && customer.purpose === 'RETIREMENT' ? (
                <> are eligible to withdraw your KiwiSaver for retirement.</>
            ) : (
                <> have {prefixWithdrawalTimelineContent(customer)}</>
            )}
        </p>
    )
}

export default WithdrawalTimeline
