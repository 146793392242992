import {Button} from '@design-system/button'
import {Modal} from '@design-system/modal'
import React from 'react'
import {Model, Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {isPartiallyFilled, isAutoExerciseOrder} from '~/global/utils/order/order'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {setModalError} from '~/store/notification'

interface Props {
    order: Model.BuyOrder | Model.SellOrder
    fundName: string
    displayButton?: boolean
    cancelOrder(order: Model.BuyOrder | Model.SellOrder): Promise<void | Response.Error>
    isDependent: boolean
    preferredName: string
}

export const CancelOrderModal: React.FunctionComponent<Props> = ({
    order,
    isDependent,
    preferredName,
    cancelOrder,
    displayButton,
    fundName,
}) => {
    const [isOpen, setIsOpen] = React.useState(false)

    const onOpen = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setIsOpen(true)
    }

    const onClose = () => {
        setIsOpen(false)
    }

    const onCancel = async () => {
        try {
            const error = await cancelOrder(order)
            if (error) {
                if (error.code === 'cancel_order_already_processing') {
                    setModalError(
                        `This order has been processed and will appear in ${
                            isDependent ? `${preferredName}’s` : 'your'
                        } Portfolio when it’s filled.`,
                        'Sorry this can’t be cancelled',
                    )
                } else {
                    setModalError(error.message, 'We’re sorry, there’s been an error')
                }
            }
            setIsOpen(false)
        } catch (e) {
            setModalError(
                'Something caused it, maybe gremlins? Please try again or let us know if it’s still not working.',
                'We’re sorry, there’s been an error',
            )
        }
    }

    const partiallyFilled = isPartiallyFilled(order)
    const autoExercise = isAutoExerciseOrder(order)

    return (
        <>
            {!displayButton ? (
                <ButtonAsLink onClick={() => onOpen} dataTestId="modal--cancel-order--button">
                    {partiallyFilled ? 'Cancel remaining order' : 'Cancel order'}
                </ButtonAsLink>
            ) : (
                <Button
                    dataTestId="modal--cancel-order--button"
                    label={partiallyFilled ? 'Cancel remaining order' : 'Cancel order'}
                    onClick={onOpen}
                    type="secondary"
                    additionalClassName={spacing.spaceBelow12}
                />
            )}
            <Modal
                isAlert
                isOpen={isOpen}
                setIsOpen={_ => {
                    // The `_` argument will be called as false, but we don't need to use it in this case
                    // Normally we'd just be passing in setModalOpen (setIsOpen={setModalOpen}), but we need to run a
                    // side effect `onClose` rather than setting `isOpen(false)`
                    onClose()
                }}
                title={partiallyFilled ? 'Cancel remaining order' : 'Cancel order'}
                dataTestId="modal--cancel-order"
                primaryButton={{label: partiallyFilled ? 'Cancel remaining order' : 'Cancel order', onClick: onCancel}}
                secondaryButton={{label: "No, don't cancel"}}
            >
                {partiallyFilled ? (
                    <>
                        <p>
                            The order you placed for {fundName} has only partially filled.
                            {!autoExercise && ' Are you sure you want to cancel the rest of it?'}
                        </p>
                        {autoExercise ? (
                            <p>If you cancel it now, we’ll still apply to exercise the rights you’ve already bought.</p>
                        ) : null}
                    </>
                ) : (
                    <>
                        <p>
                            The order you placed for {fundName} hasn’t filled yet.
                            {!autoExercise && ' Are you sure you want to cancel it?'}
                        </p>
                        {autoExercise ? (
                            <p>
                                If you cancel it now, your application to auto-exercise the rights will also be
                                cancelled.
                            </p>
                        ) : null}
                    </>
                )}
            </Modal>
        </>
    )
}

export default CancelOrderModal
