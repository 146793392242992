import cn from 'classnames'
import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Link} from '~/migrate-react-router'
import {useAutoinvestOrders} from '~/sections/invest/sections/auto-invest/hooks/useAutoinvestOrders'
import AutoInvestTile from '~/sections/invest/sections/auto-invest/widgets/auto-invest-tile/AutoInvestTile'
import accountingActions from '~/store/accounting/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {PastActivity} from './PastActivity'
import {PendingInvestingActivityCard} from './PendingInvestingActivityCard'
import styles from './ActivityFeed.scss'

export const ActivityFeed: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    // Data from store
    const diyAIUnavailableInstrumentNames = useAppSelector(s => s.instrument.diyAIUnavailableInstrumentNames)
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)
    const pendingInvestingActivityRecords = useAppSelector(s => s.accounting.pendingInvestingActivityRecords)

    const [currentAutoInvestOrders] = useAutoinvestOrders()

    React.useEffect(() => {
        dispatch(accountingActions.FetchPendingInvestingActivity())
    }, [])

    const sendRudderTracking = () =>
        rudderTrack('autoinvest', 'autoinvest_landing_page_hit', {
            navigated_from: 'activity-feed',
        })

    return (
        <>
            {/* No upcoming activity */}
            {currentAutoInvestOrders.length === 0 && (
                <div className={cn(styles.activityWrapper, spacing.spaceAbove8, spacing.spaceBelow32)}>
                    <h2 className={spacing.spaceBelow16}>Upcoming activity</h2>
                    <p>
                        Chill, you have no upcoming activity. If you{' '}
                        <Link
                            to={profileUrl('auto-invest')}
                            onClick={sendRudderTracking}
                            data-testid="link--create-auto-invest"
                        >
                            create an auto-invest order
                        </Link>
                        , you'll see this here.
                    </p>
                </div>
            )}

            {/* Upcoming activity */}
            {currentAutoInvestOrders.length > 0 && (
                <div className={cn(styles.activityWrapper, spacing.spaceAbove8, spacing.spaceBelow32)}>
                    <div className={styles.headingSpacer}>
                        <h2 className={spacing.spaceBelow16}>Upcoming activity</h2>
                        <Link to={profileUrl('auto-invest')} onClick={sendRudderTracking}>
                            View auto-invest
                        </Link>
                    </div>

                    {currentAutoInvestOrders.map((autoinvestOrder, idx) => {
                        return (
                            <div key={autoinvestOrder.id} className={idx > 0 ? spacing.spaceAbove8 : ''}>
                                <AutoInvestTile
                                    order={autoinvestOrder}
                                    homeCurrency={homeCurrency}
                                    fromPortfolio
                                    dataTestId="current-auto-invest--portfolio"
                                    unavailableInstrumentNames={diyAIUnavailableInstrumentNames}
                                />
                            </div>
                        )
                    })}
                </div>
            )}

            {/* Pending activity */}
            {pendingInvestingActivityRecords.length > 0 && (
                <div className={cn(styles.activityWrapper, spacing.spaceBelow8)}>
                    <h2 className={spacing.spaceBelow16}>Pending activity</h2>
                    <PendingInvestingActivityCard orders={pendingInvestingActivityRecords} />
                </div>
            )}

            {/* Past activity */}
            <PastActivity />
        </>
    )
}
