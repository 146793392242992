import {assertNever} from '~/global/utils/assert-never/assertNever'
import {ActionsType} from './actions'
import {State} from './types'

const initialState: State = {}

function reducer(state: State = initialState, action: ActionsType): State {
    switch (action.type) {
        case 'signup.setUSResignStatus':
            return {...state, usResignStatus: action.payload.status}
        case 'signup.setUSStatus':
            return {...state, usStatus: action.payload.status}
        case 'signup.setUSPreSignUpLocation':
            return {
                ...state,
                usPreSignUpLocation: action.payload,
            }
        case 'signup.locationToReturnTo':
            return {
                ...state,
                locationToReturnTo: action.payload,
            }
        default:
            assertNever(action)
    }
    return state
}

export default reducer
