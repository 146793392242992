import React from 'react'
import {WalletValue} from '~/global/widgets/number-elements/NumberElements'
import {useFonterraPortfolio} from '~/sections/moose/state/local'
import styles from './MooseWalletBalance.scss'

const MooseWalletBalance: React.FunctionComponent<{}> = () => {
    const walletPortfolio = useFonterraPortfolio().wallet

    return (
        <div className={styles.soloWalletView}>
            <p className={styles.walletBalanceTitle}>Wallet balance</p>
            <span className={styles.container}>
                <WalletValue value={walletPortfolio.balance} currency="nzd" />
            </span>
        </div>
    )
}

export default MooseWalletBalance
