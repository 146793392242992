import {DateTime} from 'luxon'

/*
These functions direct when to display the 'This week', 'This month', and calendar month headings
in a list of reverse-chronologically ordered records.

Each heading should be shown when the record is the first entry in the given time period.

These use comparisons between the week/month number, but also date constants, to make sure that
records do not match the logic when, for example, their week numbers may match, but they are of different years.
*/

interface GetTimeStamps {
    currentTimestamp: DateTime
    previousWeekTimestamp: DateTime
    previousMonthTimestamp: DateTime
}

/**
 * Define these values as required by the various showThis*Heading functions. We do it this way rather than declare once to
 * facilitate timestamp mocking in unit tests. Hopefully worth the performance penalty
 *
 * @returns {DateTime[]} An array of DateTime objects
 */
const getTimestamps = (): GetTimeStamps => {
    const currentTimestamp = DateTime.local()
    const previousWeekTimestamp = DateTime.local().minus({week: 1})
    const previousMonthTimestamp = DateTime.local().minus({month: 1})
    return {currentTimestamp, previousWeekTimestamp, previousMonthTimestamp}
}

export const showThisWeekHeading = (currentRecordTimestamp: DateTime, previousRecordTimestamp: DateTime): boolean => {
    /*
    Logic for when the 'This week' heading will show.

    The previous record must be today's date, and current record must be of the same week number as today's date.
    */
    const {currentTimestamp, previousWeekTimestamp} = getTimestamps()
    return (
        currentRecordTimestamp.weekNumber === currentTimestamp.weekNumber &&
        currentRecordTimestamp.toISODate() !== currentTimestamp.toISODate() &&
        (currentRecordTimestamp > previousWeekTimestamp ||
            currentRecordTimestamp.toISODate() === previousWeekTimestamp.toISODate()) &&
        previousRecordTimestamp.toISODate() === currentTimestamp.toISODate()
    )
}

export const showThisMonthHeading = (
    currentRecordTimestamp: DateTime,
    previousRecordTimestamp: DateTime,
    monthTitleDisplayed: boolean,
): boolean => {
    /*
    Logic for when the 'This month' heading will show.

    The previous record must be within the calendar week time period, and the current record must be outside the
    calendar week, but within the calendar month (i.e. same month as today's date).
    */
    const {currentTimestamp, previousWeekTimestamp, previousMonthTimestamp} = getTimestamps()
    return (
        !monthTitleDisplayed &&
        currentRecordTimestamp.month === currentTimestamp.month &&
        (currentRecordTimestamp > previousMonthTimestamp ||
            currentRecordTimestamp.toISODate() === previousMonthTimestamp.toISODate()) &&
        (previousRecordTimestamp > previousWeekTimestamp ||
            previousRecordTimestamp.toISODate() === previousWeekTimestamp.toISODate()) &&
        (currentRecordTimestamp < previousWeekTimestamp ||
            currentRecordTimestamp.weekNumber !== currentTimestamp.weekNumber)
    )
}

export const showCalendarMonthHeading = (
    currentRecordTimestamp: DateTime,
    previousRecordTimestamp: DateTime,
): boolean => {
    /*
    Logic for the when the calendar month headings (e.g. 'March 2020') will show.

    The previous record must have a different month to the current record, and the current record must not have
    either the same week number as the current date, nor the same month as the current date.
    */
    const {currentTimestamp, previousWeekTimestamp, previousMonthTimestamp} = getTimestamps()
    return (
        (currentRecordTimestamp < previousMonthTimestamp &&
            currentRecordTimestamp.month !== previousRecordTimestamp.month) ||
        (currentRecordTimestamp.month !== currentTimestamp.month &&
            previousRecordTimestamp.month === currentTimestamp.month &&
            ((currentRecordTimestamp < previousWeekTimestamp &&
                currentRecordTimestamp.toISODate() !== previousWeekTimestamp.toISODate()) ||
                currentRecordTimestamp.weekNumber !== currentTimestamp.weekNumber))
    )
}
