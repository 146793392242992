import {Duration, DateTime} from 'luxon'

export function humaniseDuration(duration: Duration) {
    const thresholds = {
        s: 45, // seconds to minute
        m: 45, // minutes to hour
        h: 22, // hours to day
        d: 26, // days to month
        M: 11, // months to year
    }

    const seconds = Math.abs(Math.round(duration.as('seconds')))
    const minutes = Math.abs(Math.round(duration.as('minutes')))
    const hours = Math.abs(Math.round(duration.as('hours')))
    const days = Math.abs(Math.round(duration.as('days')))

    const sign = (value: string) => (duration.as('milliseconds') <= 0 ? `${value} ago` : `in ${value}`)

    if (seconds <= thresholds.s) {
        return sign('a few seconds')
    }
    if (minutes === 1) {
        return sign('a minute')
    }
    if (minutes <= thresholds.m) {
        return sign(`${minutes} minutes`)
    }
    if (hours === 1) {
        return sign('an hour')
    }
    if (hours <= thresholds.h) {
        return sign(`${hours} hours`)
    }
    if (days === 1) {
        return sign('a day')
    }
    return sign(`${days} days`)
}

export function humaniseFutureTime(timestamp: DateTime, inverted?: boolean) {
    const timestampStr = timestamp.toFormat('h:mma').toLowerCase()

    if (timestamp.hasSame(DateTime.local(), 'day')) {
        if (inverted) {
            return `${timestampStr} today`
        }

        return `today at ${timestampStr}`
    } else if (timestamp.hasSame(DateTime.local().plus({days: 1}), 'day')) {
        if (inverted) {
            return `${timestampStr} tomorrow`
        }

        return `tomorrow at ${timestampStr}`
    }

    if (inverted) {
        return `${timestampStr} ${timestamp.weekdayLong}`
    }

    return `${timestamp.weekdayLong} at ${timestampStr}`
}
