import Decimal from 'decimal.js'
import {Model} from '~/api/retail/types'
import {sumTradeShareVolumes} from '~/global/utils/order/order'
import {getFeeCap} from '~/sections/invest/sections/view-instrument/sections/overview/widgets/fees/Fees'
import {BuyOrder} from '~/store/accounting/types'
import {Instrument} from '~/store/instrument/types'

const FEE_PERCENTAGE_AS_DECIMAL = 0.019

interface ExerciseLaterBuyCostBreakdown {
    estimatedCostToExercise: string
    estimatedTotal: string
}

export const exerciseLaterBuyCostBreakdown = (
    instrument: Instrument,
    orderAmount: string,
    priceLimit?: string,
): ExerciseLaterBuyCostBreakdown | null => {
    if (!instrument.exercisePrice) {
        return null
    }

    const {exercisePrice, amount, priceToCompare, limitPrice} = getInputValues(instrument, orderAmount, priceLimit)

    if (amount.lessThanOrEqualTo(0)) {
        return null
    }

    if (limitPrice && limitPrice.lessThanOrEqualTo(0)) {
        return null
    }

    const estimatedRightsToBuy = amount.div(priceToCompare)
    const estimatedCostToExercise = estimatedRightsToBuy.mul(exercisePrice)
    const estimatedTotal = amount.plus(estimatedCostToExercise)

    return {
        estimatedCostToExercise: estimatedCostToExercise.toDP(2).toString(),
        estimatedTotal: estimatedTotal.toDP(2).toString(),
    }
}

interface AutoExerciseBuyCostBreakdown {
    estimatedRightsToBuy: string
    estimatedCostToBuy: string
    estimatedCostToExercise: string
    estimatedFee?: string
}

export const autoExerciseBuyCostBreakdown = (
    instrument: Instrument,
    orderAmount: string,
    jurisdiction: Model.User['jurisdiction'],
    priceLimit?: string,
    includesFee?: boolean,
): AutoExerciseBuyCostBreakdown | null => {
    if (!instrument.exercisePrice) {
        return null
    }

    const {exercisePrice, amount, priceToCompare} = getInputValues(instrument, orderAmount, priceLimit)

    if (amount.lessThanOrEqualTo(0)) {
        return null
    }

    // if the order incurs fees, include that fee in our estimated number of rights to buy
    const feeConsideration = includesFee ? priceToCompare.mul(FEE_PERCENTAGE_AS_DECIMAL) : 0
    const estimatedRightsToBuy = amount.div(priceToCompare.plus(exercisePrice).plus(feeConsideration))
    const {feeCap} = getFeeCap(instrument, jurisdiction)
    const cappedFee = Decimal.min(
        estimatedRightsToBuy.mul(priceToCompare).mul(FEE_PERCENTAGE_AS_DECIMAL),
        new Decimal(feeCap),
    )

    const estimatedFee = includesFee ? cappedFee : 0
    const estimatedCostToBuy = estimatedRightsToBuy.mul(priceToCompare)
    const estimatedCostToBuyIncFee = estimatedCostToBuy.plus(estimatedFee)
    const estimatedCostToExercise = amount.minus(estimatedCostToBuyIncFee)

    // order amount is too low to auto exercise
    if (estimatedCostToBuy.toDP(2).lessThanOrEqualTo(0) || estimatedCostToExercise.toDP(2).lessThanOrEqualTo(0)) {
        return null
    }

    return {
        estimatedRightsToBuy: estimatedRightsToBuy.toDP(8).toString(),
        estimatedCostToBuy: estimatedCostToBuyIncFee.toDP(2).toString(),
        estimatedCostToExercise: estimatedCostToExercise.toDP(2).toString(),
        estimatedFee: estimatedFee === 0 ? estimatedFee.toString() : estimatedFee.toDP(8).toString(),
    }
}

const getInputValues = (instrument: Instrument, orderAmount: string, priceLimit?: string) => {
    const marketPrice = new Decimal(instrument.marketPrice)
    const exercisePrice = new Decimal(instrument.exercisePrice!)
    const limitPrice = priceLimit ? new Decimal(priceLimit) : undefined
    const amount = new Decimal(orderAmount)

    // price to compare is limit price if defined and lower than the market price,
    // otherwise compare to the market price because it's a better deal
    const priceToCompare = limitPrice && limitPrice.lessThan(marketPrice) ? limitPrice : marketPrice

    return {exercisePrice, amount, priceToCompare, limitPrice}
}

export const autoExerciseRemainingCostBreakdown = (
    order: BuyOrder,
    instrument: Instrument,
    jurisdiction: Model.User['jurisdiction'],
): AutoExerciseBuyCostBreakdown | null => {
    // calculates the estimated auto exercise cost breakdown of an order
    // taking into account the portion of an order that has already filled
    // and the cost to exercise that
    if (!instrument.exercisePrice || !order.remaining_currency) {
        return null
    }

    const amountRemainingAfterExercise = remainingCurrencyAfterExercise(order, instrument)

    // get the estimated cost breakdown for the remaining order amount
    const remainingCostBreakdown = autoExerciseBuyCostBreakdown(
        instrument,
        amountRemainingAfterExercise,
        jurisdiction,
        order.price_limit,
        !!(order.expected_fee && parseFloat(order.expected_fee) > 0),
    )

    return remainingCostBreakdown || null
}

export const remainingCurrencyAfterExercise = (order: BuyOrder, instrument: Instrument): string => {
    // calculates the estimated dollar amount of an order remaining,
    // accounting for exercising any rights that have been purchased
    if (!order.remaining_currency || !instrument.exercisePrice) {
        return '0'
    }

    const tradeVolume = new Decimal(sumTradeShareVolumes(order))
    const exercisePrice = new Decimal(instrument.exercisePrice)
    const remainingCurrency = new Decimal(order.remaining_currency)

    if (remainingCurrency.lessThanOrEqualTo(0)) {
        return '0'
    }

    const tradeExerciseCost = tradeVolume.mul(exercisePrice)

    // remaining currency has had fees subtracted already
    const remainingCurrencyAfterExercise = remainingCurrency.minus(tradeExerciseCost)
    return remainingCurrencyAfterExercise.toString()
}
