import React from 'react'
import {DistillScope} from '~/api/query/distill'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {isOnNZX} from '~/global/utils/is-on-exchange/isOnExchange'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import InstrumentSubHeader from '~/global/widgets/view-instrument-shared/instrument-header/InstrumentSubHeader'
import WatchlistButton from '~/sections/explore/widgets/watchlist/WatchlistButton'
import {generateEmployeeShareSchemeText} from '~/sections/invest/sections/employee-share-scheme/utils/ess-badge-label/essBadgeLabel'
import PriceInformation from '~/sections/invest/sections/view-instrument/widgets/header/PriceInformation'
import PriceNotification from '~/sections/invest/sections/view-instrument/widgets/header/PriceNotification'
import {useAppSelector} from '~/store/hooks'
import {selectIsInstrumentInExtendedHours} from '~/store/instrument/selectors'
import {Instrument} from '~/store/instrument/types'
import styles from './Header.scss'

export default ({
    instrument,
    portfolioId,
    hasEssEmployment,
    suppressExtendedHours,
}: {
    instrument: Instrument
    portfolioId: string
    hasEssEmployment?: boolean
    suppressExtendedHours: boolean
}) => {
    const isInExtendedHours = useAppSelector(s => selectIsInstrumentInExtendedHours(s, instrument))
    const showExtendedHours = !suppressExtendedHours && !!isInExtendedHours && !!Number(instrument.extendedHoursPrice) // we're on a relevant view (e.g. not autoinvest etc), in extended hours, and we have a price available to show

    const shareSchemeParticipation = useAppSelector(s => s.employeeShareScheme.shareSchemeParticipation)
    const essBadgeLabel = hasEssEmployment
        ? generateEmployeeShareSchemeText(shareSchemeParticipation, instrument.id)
        : ''

    return (
        <>
            {/* Logo */}
            <div className={styles.headerLogo}>
                <InstrumentLogo instrument={instrument} noBorder />
            </div>

            {/* Name and watchlist button */}
            <div className={styles.instrumentName}>
                <h1 className={styles.headerName} data-testid="fund-name">
                    {instrument.name}
                </h1>
                <WatchlistButton instrumentId={instrument.id} instrumentName={instrument.name} />
            </div>

            {/* Ticker and exchange */}
            {['listed', 'adr'].includes(tradingType(instrument)) && !isInstrumentInNoTrade(instrument) && (
                <InstrumentSubHeader
                    instrument={instrument}
                    essBadgeLabel={essBadgeLabel}
                    isOnNZX={isOnNZX(instrument)}
                />
            )}

            <PriceInformation instrument={instrument} showExtendedHours={showExtendedHours} />

            <PriceNotification
                urlSlug={instrument.urlSlug}
                portfolioId={portfolioId}
                showExtendedHours={showExtendedHours}
                distillScope={DistillScope.INVEST}
            />
        </>
    )
}
