import {DateTime} from 'luxon'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {ActionsType} from './actions'
import {InvestingActivityRecord, State, Transaction} from './types'

const initialState: State = {
    walletPageLoadingState: 'uninitialised',
    walletTransactions: [],
    walletTransactionsCurrentFilter: 'all',
    walletTransactionsFetchingOlder: false,
    walletTransactionsFilterOptions: [],
    walletTransactionsHasOlder: false,
    walletTransactionsLoadingState: 'loading',
    walletPaymentRequests: [],
    fundOrdersLoadingState: 'ready',
    fundOrders: {},
    pastInvestingActivityCurrentPage: 1,
    pastInvestingActivityFetchingMore: false,
    pastInvestingActivityHasMore: null,
    pastInvestingActivityLoadingState: 'ready',
    pastInvestingActivityRecords: [],
    pendingInvestingActivityLoadingState: 'ready',
    pendingInvestingActivityRecords: [],
    portfolioPageCurrentTab: 'Investments',
    recentOrders: {
        fetching: false,
        orders: [],
        lastFetched: null,
    },
    recentOrdersLoadingState: 'ready',
    referrals: {
        pendingInvites: 0,
        signUps: 0,
        bonusAmount: '0.00',
        asAt: DateTime.local(),
    },
    cards: [],
    cardsLoadingState: 'loading',
    accounts: [],
    accountsLoadingState: 'loading',
    exchangeRates: [],
    exchangeFeeRate: null,
    topupGoal: null,
    donationAmount: '',
}

const initialFundOrders: State['fundOrders'][0] = {
    fetching: false,
    lastFetched: null,
    orders: [],
}

const initialRecentOrders: State['recentOrders'] = {
    fetching: false,
    lastFetched: null,
    orders: [],
}

function compareTransaction(a: Transaction, b: Transaction): number {
    if (a.transaction_id === b.transaction_id && a.line_number === b.line_number) {
        return 0
    }
    if (a.transaction_id === b.transaction_id) {
        if (a.line_number === b.line_number) {
            return 0
        }
        if (a.line_number < b.line_number) {
            return -1
        }
        return 1
    }
    if (a.transaction_id < b.transaction_id) {
        return -1
    }
    return 1
}

function mergeTransactions(a: Transaction[], b: Transaction[]): Transaction[] {
    const result: Transaction[] = []

    while (a.length || b.length) {
        if (a.length === 0 || (b.length && compareTransaction(b[0], a[0]) > 0)) {
            result.push(b.shift()!)
            continue
        }
        if (b.length === 0 || (a.length && compareTransaction(a[0], b[0]) > 0)) {
            result.push(a.shift()!)
            continue
        }
        result.push(b.shift()!)
        a.shift()
    }

    return result
}

function mergeInvestingActivityRecords(existing: InvestingActivityRecord[], incoming: InvestingActivityRecord[]) {
    const records: InvestingActivityRecord[] = existing

    incoming.forEach(record => {
        const index = records.findIndex(r => r.id === record.id)
        if (index > -1) {
            records[index] = record
        } else {
            records.push(record)
        }
    })

    return records
}

function reducer(state: State = initialState, action: ActionsType): State {
    let fundOrders: State['fundOrders']
    let recentOrders: State['recentOrders']

    switch (action.type) {
        case 'accounting.clear_investing_activity_records':
            return {
                ...state,
                pastInvestingActivityCurrentPage: 1,
                pastInvestingActivityFetchingMore: false,
                pastInvestingActivityHasMore: null,
                pastInvestingActivityLoadingState: 'ready',
                pastInvestingActivityRecords: [],
                pendingInvestingActivityLoadingState: 'ready',
                pendingInvestingActivityRecords: [],
                portfolioPageCurrentTab: 'Investments',
            }
        case 'accounting.clear_pending_investing_activity_records':
            return {
                ...state,
                pendingInvestingActivityRecords: [],
            }
        case 'accounting.clear_staged_exchange_order':
            return {
                ...state,
                stagedExchangeOrder: undefined,
                stagedExchangeOrderRateError: undefined,
            }
        case 'accounting.clear_transactions':
            return {
                ...state,
                fundOrders: {},
                walletPageLoadingState: 'uninitialised',
                walletTransactions: [],
                walletTransactionsCurrentFilter: 'all',
                walletTransactionsFilterOptions: [],
                walletTransactionsLoadingState: 'ready',
            }
        case 'accounting.clear_wallet_transactions':
            return {...state, walletTransactions: [], walletTransactionsHasOlder: false}
        case 'accounting.fetch_fund_orders_end':
            return {
                ...state,
                fundOrdersLoadingState: 'ready',
                fundOrders: {
                    ...state.fundOrders,
                    [action.fundId]: {
                        fetching: false,
                        lastFetched: action.fetched,
                        orders: action.orders,
                        recentTaxInfo: action.recentTaxInfo,
                    },
                },
            }
        case 'accounting.fetch_fund_orders_start':
            fundOrders = {...state.fundOrders}
            fundOrders[action.fundId] = fundOrders[action.fundId] || initialFundOrders
            fundOrders[action.fundId].fetching = true
            return {...state, fundOrdersLoadingState: 'loading', fundOrders}
        case 'accounting.fetch_recent_orders_end':
            return {
                ...state,
                recentOrdersLoadingState: 'ready',
                recentOrders: {
                    fetching: false,
                    lastFetched: action.fetched,
                    orders: action.orders,
                },
            }
        case 'accounting.fetch_recent_orders_start':
            recentOrders = {...state.recentOrders} || initialRecentOrders
            recentOrders.fetching = true
            return {...state, recentOrdersLoadingState: 'loading', recentOrders}
        case 'accounting.reset_accounting_data':
            return initialState
        case 'accounting.set_bank_accounts':
            return {...state, accounts: action.payload.accounts, accountsLoadingState: 'ready'}

        case 'accounting.set_bank_accounts_error':
            return {...state, accountsLoadingState: 'error'}

        case 'accounting.set_cards':
            return {...state, cards: action.payload.cards, cardsLoadingState: 'ready'}

        case 'accounting.set_cards_error':
            return {...state, cardsLoadingState: 'error'}
        case 'accounting.set_current_topup_goal':
            return {...state, topupGoal: action.payload}
        case 'accounting.set_exchange_rate':
            return {
                ...state,
                exchangeRates: state.exchangeRates.find(
                    exchangeRate =>
                        exchangeRate.sourceCurrency === action.payload.sourceCurrency &&
                        exchangeRate.targetCurrency === action.payload.targetCurrency,
                )
                    ? state.exchangeRates
                    : [...state.exchangeRates, action.payload],
            }
        case 'accounting.set_exchange_fee_rate':
            return {
                ...state,
                exchangeFeeRate: action.payload.feeRate,
            }
        case 'accounting.set_fetch_fund_orders_error':
            return {
                ...state,
                fundOrdersLoadingState: 'error',
            }
        case 'accounting.set_fetch_recent_orders_error':
            return {
                ...state,
                recentOrdersLoadingState: 'error',
            }
        case 'accounting.set_wallet_transactions_fetching_older':
            return {...state, walletTransactionsFetchingOlder: action.fetchingOlder}
        case 'accounting.set_past_investing_activity_current_page':
            return {
                ...state,
                pastInvestingActivityCurrentPage: action.payload,
            }
        case 'accounting.set_past_investing_activity_has_more':
            return {
                ...state,
                pastInvestingActivityHasMore: action.payload,
            }
        case 'accounting.set_past_investing_activity_loading_state':
            return {
                ...state,
                pastInvestingActivityLoadingState: action.payload,
            }
        case 'accounting.set_past_investing_activity_records':
            return {
                ...state,
                pastInvestingActivityRecords: mergeInvestingActivityRecords(
                    state.pastInvestingActivityRecords,
                    action.payload,
                ),
            }
        case 'accounting.set_pending_investing_activity_loading_state':
            return {
                ...state,
                pendingInvestingActivityLoadingState: action.payload,
            }
        case 'accounting.set_pending_investing_activity_records':
            return {
                ...state,
                pendingInvestingActivityRecords: action.payload,
            }
        case 'accounting.set_portfolio_page_current_tab':
            return {
                ...state,
                portfolioPageCurrentTab: action.payload,
            }
        case 'accounting.set_referral_information':
            return {
                ...state,
                referrals: {
                    pendingInvites: action.pendingInvites,
                    signUps: action.signUps,
                    bonusAmount: action.bonusAmount,
                    asAt: action.asAt,
                },
            }
        case 'accounting.set_staged_exchange_order':
            return {
                ...state,
                stagedExchangeOrder: action.payload,
            }
        case 'accounting.set_viewing_older_transactions':
            return {
                ...state,
                walletTransactionsViewingOlder: action.payload,
            }
        case 'accounting.set_wallet_transactions_current_filter':
            return {
                ...state,
                walletTransactionsCurrentFilter: action.payload,
            }
        case 'accounting.set_wallet_transactions_filter_options':
            return {
                ...state,
                walletTransactionsFilterOptions: action.payload,
            }
        case 'accounting.set_wallet_transactions_loading_state':
            return {
                ...state,
                walletTransactionsLoadingState: action.payload,
            }
        case 'accounting.set_wallet_page_loading_state':
            return {...state, walletPageLoadingState: action.loadingState}
        case 'accounting.truncate_transactions':
            return {
                ...state,
                walletTransactions: state.walletTransactionsViewingOlder
                    ? state.walletTransactions
                    : state.walletTransactions.slice(0, 50),
            }
        case 'accounting.update_staged_exchange_order_error':
            return {
                ...state,
                stagedExchangeOrderRateError: action.payload,
            }
        case 'accounting.update_transactions':
            return {
                ...state,
                walletTransactions: mergeTransactions(state.walletTransactions, action.transactions),
                walletTransactionsHasOlder: action.hasMore,
            }
        case 'accounting.set_payment_requests':
            return {
                ...state,
                walletPaymentRequests: action.payload,
            }

        default:
            assertNever(action)
    }
    return state
}

export default reducer
