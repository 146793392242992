import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {Link} from '~/migrate-react-router'
import {useAppSelector} from '~/store/hooks'
import {restrictedAccountWarning} from '~/store/identity/selectors'
import styles from './AccountVerificationRequired.scss'

export interface AccountVerificationRequiredProps {
    className?: string
    hideEditAddress?: boolean
}

export interface AccountVerificationStatus {
    addressStatus: 'no-warning' | 'need-upload' | 'need-upload-restricted' | 'pending-approval'
    prescribedApprovalStatus: 'no-warning' | 'pending-approval'
    identityVerificationStatus: 'no-warning' | 'verification-overdue' | 'verification-in-review'
}

interface AccountVerificationActionRequiredProps extends AccountVerificationRequiredProps {
    addressActionRequired: boolean
    identityVerificationActionRequired: boolean
    status: AccountVerificationStatus
}

const AccountVerificationActionRequired: React.FunctionComponent<AccountVerificationActionRequiredProps> = ({
    addressActionRequired,
    identityVerificationActionRequired,
    hideEditAddress,
    className,
}) => {
    const profileUrl = useProfileUrl()
    const addressActionLinks: [{text: string; url: string}] = [
        {text: 'Upload', url: profileUrl('settings/address-verification')},
    ]
    if (!hideEditAddress) {
        addressActionLinks.push({text: 'Edit address', url: profileUrl('settings/personal-details/edit-address')})
    }

    const addressActionTitle = 'Address verification'
    const addressActionText = hideEditAddress
        ? 'Having trouble getting your address verified? Upload a proof of address and our team will take care of the rest.'
        : 'We couldn’t verify your address. Upload a proof of address or edit your address now so you can buy, sell, and withdraw.'

    if (identityVerificationActionRequired) {
        return (
            <>
                <AlertCard
                    title="Identity verification"
                    type="warning"
                    className={cn(
                        className,
                        styles.identityVerification,
                        addressActionRequired ? styles.stackedTopAlert : spacing.spaceBelow16,
                    )}
                >
                    <p className={spacing.spaceBelow4}>
                        Verify your identity to buy, sell, top up, and withdraw.{' '}
                        <HelpCentreLink auArticle="5873378-verify-your-id" nzArticle="5873360-verify-your-id">
                            Tell me more.
                        </HelpCentreLink>
                    </p>
                    <Link to={profileUrl('verify-your-identity')}>Verify your identity</Link>
                </AlertCard>
                {addressActionRequired && (
                    <AlertCard
                        title={addressActionTitle}
                        className={cn(className, styles.stackedBottomAlert, spacing.spaceBelow16)}
                        text={addressActionText}
                        links={addressActionLinks}
                        type="warning"
                    />
                )}
            </>
        )
    }
    if (addressActionRequired) {
        return (
            <AlertCard
                title={addressActionTitle}
                className={cn(className, spacing.spaceBelow16)}
                text={addressActionText}
                links={addressActionLinks}
                type="warning"
            />
        )
    }
    return null
}

export const AccountVerificationRequired: React.FunctionComponent<AccountVerificationRequiredProps> = ({
    hideEditAddress,
    className,
}) => {
    const status = useAppSelector(restrictedAccountWarning)
    const identityVerificationActionRequired = status.identityVerificationStatus === 'verification-overdue'
    const identityVerificationInReview = status.identityVerificationStatus === 'verification-in-review'
    const addressActionRequired = ['need-upload', 'need-upload-restricted'].includes(status.addressStatus)
    const addressApprovalPending = status.addressStatus === 'pending-approval'
    const prescribedApprovalPending = status.prescribedApprovalStatus === 'pending-approval'

    return (
        <>
            {(addressActionRequired || identityVerificationActionRequired) && (
                <AccountVerificationActionRequired
                    addressActionRequired={addressActionRequired}
                    identityVerificationActionRequired={identityVerificationActionRequired}
                    status={status}
                    hideEditAddress={hideEditAddress}
                    className={className}
                />
            )}
            {identityVerificationInReview && (
                <AlertCard
                    title="We’re verifying your info"
                    className={cn(className, spacing.spaceBelow16)}
                    text="Once your info is verified, you’ll be able to buy, sell, and withdraw again."
                    type="warning"
                />
            )}
            {addressApprovalPending && (
                <AlertCard
                    title="We’re verifying your address"
                    className={cn(className, spacing.spaceBelow16)}
                    text="Once your address is verified, you’ll be able to buy, sell, and withdraw again."
                    type="warning"
                />
            )}
            {prescribedApprovalPending && (
                <AlertCard
                    title="We’re verifying your info"
                    className={cn(className, spacing.spaceBelow16)}
                    text="Once your Prescribed Person info is verified, you’ll be able to buy, sell, and withdraw again."
                    type="warning"
                />
            )}
        </>
    )
}

export default AccountVerificationRequired
