import {DateTime} from 'luxon'
import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {dateFormatFullMonth} from '~/global/utils/format-date/formatDate'
import {isLoadingOrUninitialised, isUninitialised} from '~/global/utils/is-loading/isLoading'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {useRecurringPayment} from '~/sections/OLD_wallet/sections/linked-bank-payments/state'
import {UnlinkBankConfirmModal} from '~/sections/invest/sections/bank-linking/widgets/unlink-bank-confirm-modal/UnlinkBankConfirmModal'
import actions from '~/store/bankLinking/actions'
import {BasiqReferrer} from '~/store/bankLinking/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'

export type Context =
    | 'wallet-general' // Wallet page, header
    | 'wallet-roundups' // Wallet page, roundups tally
    | 'wallet/roundups' // Roundup management nested under wallet path
    | 'explore' // Roundup management nested under explore path
    | 'linked-bank-topup'
    | 'settings/bank-accounts-cards' // Account > Bank accounts and cards

interface BankLinkingAlert {
    content: string | React.ReactNode
    type: 'info' | 'warning'
    hideTally?: boolean
    title?: string
}

const contextToBasiqReferrer = (context: Context): BasiqReferrer | undefined => {
    switch (context) {
        case undefined:
        case 'wallet-roundups':
        case 'wallet/roundups':
            // Roundups is the default referrer, no need to instruct basiq to return there
            return undefined
        case 'settings/bank-accounts-cards':
            return 'account'
        case 'wallet-general':
        case 'linked-bank-topup':
            return 'wallet-linked-bank-payments'
        case 'explore':
            return 'explore-linked-bank-payments'
        default:
            assertNever(context)
            return 'wallet-linked-bank-payments'
    }
}

/**
 * Returns content to display in an AlertCard widget, or undefined if no alert is required. A context must be supplied.
 */
export const useBankLinkingAlert = ({context}: {context: Context}): BankLinkingAlert | undefined => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [unlinkModalOpen, setUnlinkModalOpen] = React.useState(false)
    const {
        jurisdiction,
        loadingState,
        isBankLinked,
        hasMonitoringAccountsSet,
        expiryDate,
        debitAccount,
        paymentInProgress,
        isBankLinkingRejected,
        isRoundupsEnabled,
    } = useAppSelector(({bankLinking, identity}) => ({
        jurisdiction: identity.user!.jurisdiction,
        loadingState: bankLinking.bankLinkingLoadingState,
        isBankLinked: bankLinking.isBankLinked,
        hasMonitoringAccountsSet: bankLinking.roundupsMonitoringAccounts!.length > 0,
        expiryDate: bankLinking.expiryDate,
        debitAccount: bankLinking.roundupsDebitAccount,
        paymentInProgress: bankLinking.roundupsPaymentInProgress,
        isBankLinkingRejected: bankLinking.isBankLinkingRejected,
        isRoundupsEnabled: bankLinking.isRoundupsEnabled,
    }))
    const recurringPaymentState = useFailedRecurringPayment()
    const profileUrl = useProfileUrl()

    const trackClick = (
        event: 'no-debit-bank-account' | 'no-monitoring-bank-accounts' | 'expiry-due' | 'expiry-past-due',
    ) => {
        rudderTrack('round-up', 'error_message_clicked', {
            navigated_from:
                context === 'wallet-general' || context === 'wallet-roundups' ? 'wallet' : 'round-up-overview',
            error_type: event,
        })
    }

    React.useEffect(() => {
        if (jurisdiction === 'au' && isUninitialised(loadingState)) {
            dispatch(actions.FetchBankLinkingStatus())
        }
    }, [])

    if (jurisdiction !== 'au' || isLoadingOrUninitialised(loadingState) || !isBankLinked) {
        return
    }

    // Show this alert when a customer hasn't completed their bank linking setup on accounts & cards
    if ((context === 'settings/bank-accounts-cards' || context === 'wallet-general') && !debitAccount) {
        return {
            type: 'warning',
            content: (
                <>
                    <p>You didn’t quite finish linking your bank.</p>
                    <Link
                        style={{display: 'block', marginBottom: '0'}}
                        to={profileUrl('settings/bank-accounts-cards/roundups')}
                    >
                        Complete bank linking setup
                    </Link>
                    <ButtonAsLink onClick={() => setUnlinkModalOpen(true)}>Unlink my bank</ButtonAsLink>
                    <UnlinkBankConfirmModal
                        isOpen={unlinkModalOpen}
                        setIsOpen={setUnlinkModalOpen}
                        handleUnlinkBank={() => {
                            setUnlinkModalOpen(false)
                            dispatch(actions.UnlinkBank())
                            navigate(profileUrl('settings/bank-accounts-cards'))
                        }}
                    />
                </>
            ),
        }
    }

    if (isBankLinkingRejected === true) {
        let actionLink = profileUrl('wallet/roundups/manage/:step', {step: 'rejected'})
        if (context === 'explore') {
            actionLink = profileUrl('explore/roundups/manage/:step', {step: 'rejected'})
        }
        if (context === 'wallet-roundups') {
            // show at wallet top only
            return {type: 'warning', hideTally: true, content: null}
        }
        return {
            type: 'warning',
            hideTally: true,
            title: 'Linked bank name matching issue',
            content: (
                <>
                    The name on the bank account you've linked doesn’t match your Sharesies account name.{' '}
                    <Link to={actionLink}>Manage bank linking</Link>
                </>
            ),
        }
    }

    if (
        !hasMonitoringAccountsSet &&
        !['settings/bank-accounts-cards', 'wallet-general', 'linked-bank-topup'].includes(context)
    ) {
        // No available monitoring accounts
        // Don't show this on the bank accounts and cards screen
        return {
            type: 'info',
            title: 'Choose bank accounts to track',
            content: (
                <>
                    To use round-ups, you’ll need to choose one or more bank accounts to track.{' '}
                    <Link to={profileUrl('explore/roundups')} onClick={() => trackClick('no-monitoring-bank-accounts')}>
                        Choose account(s){context === 'explore' ? ' to track' : ''}
                    </Link>
                    .
                </>
            ),
        }
    }
    const extendConsentRedirect = async (pastDue: boolean) => {
        trackClick(pastDue ? 'expiry-past-due' : 'expiry-due')
        const consentUrl = await dispatch(actions.GetConsentURL({internalReferrer: contextToBasiqReferrer(context)}))
        window.location.href = consentUrl!
    }

    // Roundups payment in progress
    if (paymentInProgress && context !== 'settings/bank-accounts-cards' && context !== 'wallet-general') {
        return {
            type: 'info',
            content: <p>${paymentInProgress.amount} of round-ups are on their way and we’ve reset your tally</p>,
        }
    }

    // A debit account is required
    if (debitAccount && !debitAccount.externallyAvailable && context !== 'wallet-roundups') {
        const features =
            recurringPaymentState.isRecurringPaymentEnabled && isRoundupsEnabled
                ? 'your round-ups or recurring top-up are sent from'
                : recurringPaymentState.isRecurringPaymentEnabled
                  ? 'your recurring top-up is sent from'
                  : isRoundupsEnabled
                    ? 'your round-ups are sent from'
                    : 'top-ups are sent from'

        return {
            type: 'warning',
            hideTally: true, // This no longer makes sense
            title: 'We can’t connect with your linked bank',
            content: (
                <>
                    <p>We can’t connect with the bank account that {features}. Check the account, or choose another.</p>
                    <Link to={profileUrl('explore/roundups')} onClick={() => trackClick('no-debit-bank-account')}>
                        Choose account
                    </Link>
                </>
            ),
        }
    }

    // Consent renawal due within 7 days
    if (DateTime.now().plus({days: 7}) > expiryDate! && DateTime.now() < expiryDate! && context !== 'wallet-roundups') {
        const features =
            recurringPaymentState.isRecurringPaymentEnabled && isRoundupsEnabled
                ? 'round-ups and recurring top-up'
                : recurringPaymentState.isRecurringPaymentEnabled
                  ? 'recurring top-up'
                  : isRoundupsEnabled
                    ? 'round-ups'
                    : null
        return {
            type: 'warning',
            title: 'Renew bank permissions',
            content: (
                <>
                    <p>
                        On {expiryDate!.toFormat('dd MMMM yyyy')}, your bank permissions will expire.{' '}
                        {features && ` Renew permissions before then to keep your ${features} going.`}
                    </p>
                    <ButtonAsLink onClick={() => extendConsentRedirect(false)}>Renew bank permissions</ButtonAsLink>
                </>
            ),
        }
    }

    // Consent renewal due now
    if (DateTime.now() > expiryDate! && context !== 'wallet-roundups') {
        const features =
            recurringPaymentState.isRecurringPaymentEnabled && isRoundupsEnabled
                ? 'round-ups and recurring top-up'
                : recurringPaymentState.isRecurringPaymentEnabled
                  ? 'recurring top-up'
                  : isRoundupsEnabled
                    ? 'round-ups'
                    : null
        return {
            type: 'warning',
            title: 'Bank permissions expired',
            content: (
                <>
                    <p>
                        Your bank permissions have expired
                        {features && `, so we’ve paused your ${features}`}.
                    </p>
                    <ButtonAsLink onClick={() => extendConsentRedirect(true)}>Renew permissions now</ButtonAsLink>.
                </>
            ),
        }
    }

    // Last recurring payment failed
    if (
        recurringPaymentState.isRecurringPaymentEnabled &&
        recurringPaymentState.lastPaymentFailed &&
        context !== 'wallet-roundups' &&
        context !== 'explore' &&
        context !== 'settings/bank-accounts-cards'
    ) {
        return {
            type: 'info',
            title: 'Top-up failed',
            content: (
                <>
                    <p>
                        We were unable to process your recurring top-up. We’ll try again on{' '}
                        {recurringPaymentState.nextPaymentDate!.toFormat(dateFormatFullMonth)}.
                    </p>
                    <Link to={profileUrl('wallet/linked-bank-topup')}>Try again now</Link>
                </>
            ),
        }
    }
}

const useFailedRecurringPayment = () => {
    const recurringPayment = useRecurringPayment()

    if (recurringPayment) {
        return {
            isRecurringPaymentEnabled: true,
            lastPaymentFailed: recurringPayment.error === 'LAST_PAYMENT_FAILED',
            nextPaymentDate: DateTime.fromISO(recurringPayment.next_payment),
        }
    }

    return {
        isRecurringPaymentEnabled: false,
    }
}
