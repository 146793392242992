import React from 'react'
import {useLocation, useNavigate} from 'react-router'
import type {WalletPortfolio} from '~/sections/wallet/types'

export const WalletPortfolioContext = React.createContext<WalletPortfolio | null>(null)

export const useWalletPortfolio = () => {
    const context = React.useContext(WalletPortfolioContext)

    if (context === null) {
        throw new Error('useWalletPortfolio must be used within a WalletPortfolioWrapper')
    }

    return context
}

/**
 * Similar to React.useState except stores values inside the History API.
 * This means if you navigate away and back (or simply refresh the page) the value will be retained.
 *
 * The key allows you to have multiple components on a given page sharing the History API state field
 * (you just need to ensure each has a unique key)
 */
export const useHistoryState = <T>(key: string, defaultValue: T): [value: T, setValue: (value: T) => void] => {
    const navigate = useNavigate()
    const state = useLocation().state ?? {}

    const value = (state[key] as T | undefined) ?? defaultValue

    const setValue = (newValue: T) => {
        navigate(
            {}, // empty Path object indicates that we don't actually want to navigate (we just want to update the state)
            {
                replace: true,
                state: {
                    ...state,
                    [key]: newValue,
                },
            },
        )
    }

    return [value, setValue]
}
