import cn from 'classnames'
import React from 'react'
import {Outlet} from 'react-router-dom'
import {rudderPageView} from '~/api/rudderstack/rudderstack'
import styles from './Website.scss'

const baseWebsiteUrlNZ = 'https://www.sharesies.nz'
const baseWebsiteUrlAU = 'https://www.sharesies.com.au'

const ACCESS_CLASS = 'hasAccess'

const WebsiteWrapper: React.FunctionComponent = () => {
    // define where back button goes to based on current page url
    const location = window.location.pathname.includes('au') ? 'au' : 'nz'
    const baseWebsiteUrl = location === 'au' ? baseWebsiteUrlAU : baseWebsiteUrlNZ

    // Start tab accessibility - This is the same accessibility approach used in GlobalWrapper.tsx
    const handleKeyUp = (e: KeyboardEvent) => {
        const tabKey = e.key === 'Tab'
        if (!tabKey) {
            return
        }

        if (document.body) {
            // add keyboard accessibility features by default, used in scss files like
            // @include hasAccess { // my special rules for keyboard accessibility }
            document.body.classList.add(ACCESS_CLASS)
        }
    }

    const handleMouseClick = () => {
        if (document.body) {
            // remove focus outlines if the investor interacts with the page with a mouse
            document.body.classList.remove(ACCESS_CLASS)
        }
    }

    React.useEffect(() => {
        rudderPageView()

        // remove the left margin which was put in to prevent the page from jumping
        document.documentElement.style.marginLeft = '0'

        window.addEventListener('keyup', handleKeyUp)
        window.addEventListener('mousedown', handleMouseClick)

        // Clean up function
        return () => {
            // restore the left margin
            document.documentElement.style.marginLeft = ''

            window.removeEventListener('keyup', handleKeyUp)
            window.removeEventListener('mousedown', handleMouseClick)
        }
    }, [])
    // End tab accessibility

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.page}>
                <header className={styles.header}>
                    {/* Aria hide this link as the "Back to Sharesies" provides more context */}
                    <a className={styles.overrideLinkUnderline} href={baseWebsiteUrl} aria-hidden>
                        <span className={styles.logo}>Sharesies</span>
                    </a>

                    <nav>
                        <a
                            className={cn(styles.overrideLinkUnderline, styles.backToSharesies)}
                            href={baseWebsiteUrl}
                            aria-label="Back to home page."
                        >
                            Back
                        </a>
                    </nav>
                </header>

                <Outlet />
            </div>
        </div>
    )
}

export default WebsiteWrapper
