import {DockableModal} from '@design-system/dockable-modal'
import {ChevronRight, Wallet} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {Model} from '~/api/retail/types'
import {accessibility} from '~/global/scss/helpers'
import {ErrorBox} from '~/global/widgets/form-controls'
import Image from '~/global/widgets/image/Image'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import saveImage from '~/sections/save/assets/images/save-small.png'
import styles from '~/sections/save/sections/transfer/widgets/account-select/AccountSelect.scss'
import {TransferAccountDetails} from '~/store/save/types'

interface AccountSelectProps {
    accountList: TransferAccountDetails[]
    currency: Model.User['home_currency']
    direction: 'From' | 'To'
    disabled?: boolean
    errorMessage?: string
    handleSelectAccount: (account: TransferAccountDetails, direction: AccountSelectProps['direction']) => void
    selectedAccount?: TransferAccountDetails
}

const AccountSelect: React.FunctionComponent<AccountSelectProps> = ({
    accountList,
    currency,
    direction,
    disabled,
    errorMessage,
    handleSelectAccount,
    selectedAccount,
}) => {
    const [showSelectAccountModal, setShowSelectAccountModal] = React.useState(false)

    const handleSelect = (account: TransferAccountDetails) => {
        handleSelectAccount(account, direction)
        setShowSelectAccountModal(false)
    }

    const buttonContent = () => {
        if (selectedAccount) {
            return (
                <div className={styles.buttonContentWrapper}>
                    {selectedAccount.type === 'wallet' ? <Wallet /> : <Image alt="account image" src={saveImage} />}
                    <div>
                        <h3>{selectedAccount.name}</h3>
                        <DollarValue roundDown value={selectedAccount.balance} currency={currency} />
                    </div>
                </div>
            )
        }

        return <p className={styles.buttonTitle}>Choose</p>
    }

    return (
        <>
            <p className={styles.selectAccountTitle}>{direction}</p>
            <button
                data-testid={`button--account-select-${direction.toLowerCase()}`}
                type="button"
                onClick={() => setShowSelectAccountModal(true)}
                className={cn(styles.selectAccountButton, accessibility.button)}
                disabled={disabled}
            >
                {buttonContent()}
                {!disabled && <ChevronRight />}
            </button>
            <ErrorBox message={errorMessage} />

            <DockableModal
                dataTestId={`dockable-modal--account-select-${direction.toLowerCase()}`}
                title={direction}
                isOpen={showSelectAccountModal}
                setIsOpen={setShowSelectAccountModal}
                content={
                    <ul className={styles.selectAccountList}>
                        {accountList.map((account, i) => (
                            <li key={`${account.portfolioId} ${account.name}`}>
                                <button
                                    data-testid={`button--account-${i}`}
                                    type="button"
                                    className={cn(styles.selectAccountButton, accessibility.button)}
                                    onClick={() => handleSelect(account)}
                                >
                                    <div className={styles.buttonContentWrapper}>
                                        {account.type === 'wallet' ? (
                                            <Wallet />
                                        ) : (
                                            <Image alt="account image" src={saveImage} />
                                        )}
                                        <div>
                                            <h3>{account.name}</h3>
                                            <DollarValue roundDown value={account.balance} currency={currency} />
                                        </div>
                                    </div>
                                </button>
                            </li>
                        ))}
                    </ul>
                }
            />
        </>
    )
}

export default AccountSelect
