/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    BreakdownItem,
    BreakdownItemFromJSON,
    BreakdownItemFromJSONTyped,
    BreakdownItemToJSON,
    BreakdownItemToJSONTyped,
    Currency,
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
    CurrencyToJSONTyped,
    PortfolioHistory,
    PortfolioHistoryFromJSON,
    PortfolioHistoryFromJSONTyped,
    PortfolioHistoryToJSON,
    PortfolioHistoryToJSONTyped,
    PortfolioRiskType,
    PortfolioRiskTypeFromJSON,
    PortfolioRiskTypeFromJSONTyped,
    PortfolioRiskTypeToJSON,
    PortfolioRiskTypeToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface AppSchemasForAdminPortfolioPortfolio
 */
export interface AppSchemasForAdminPortfolioPortfolio {
    /**
     *
     * @type {string}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    uuid: string
    /**
     *
     * @type {Currency}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    currency: Currency
    /**
     *
     * @type {Date}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    date?: DateTime
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    portfolio_value?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    total_return?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    simple_return?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    cost_basis?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    cost_basis_max?: number
    /**
     *
     * @type {PortfolioRiskType}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    portfolio_risk_type?: PortfolioRiskType
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    dividends?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    managed_fund_transaction_fees?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    tax_paid?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    transaction_fees?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    adr_fees?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    realised_capital_gain?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    realised_currency_gain?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    realised_cost_basis?: number
    /**
     *
     * @type {{ [key: string]: BreakdownItem; }}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    portfolio_risk?: {[key: string]: BreakdownItem}
    /**
     *
     * @type {{ [key: string]: BreakdownItem; }}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    portfolio_countries?: {[key: string]: BreakdownItem}
    /**
     *
     * @type {{ [key: string]: BreakdownItem; }}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    portfolio_instrument_types?: {[key: string]: BreakdownItem}
    /**
     *
     * @type {Array<PortfolioHistory>}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    portfolio_history?: Array<PortfolioHistory>
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    unrealised_dividends?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    unrealised_managed_fund_transaction_fees?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    unrealised_total_return?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    unrealised_simple_return?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    unrealised_tax_paid?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    unrealised_total_transaction_fees?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    unrealised_adr_fees?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    unrealised_capital_gain?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    unrealised_currency_gain?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    fail?: number
    /**
     *
     * @type {Date}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    reset?: DateTime
    /**
     *
     * @type {Date}
     * @memberof AppSchemasForAdminPortfolioPortfolio
     */
    recalc_required_from?: DateTime
}

export function AppSchemasForAdminPortfolioPortfolioFromJSON(json: any): AppSchemasForAdminPortfolioPortfolio {
    return AppSchemasForAdminPortfolioPortfolioFromJSONTyped(json, false)
}

export function AppSchemasForAdminPortfolioPortfolioFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AppSchemasForAdminPortfolioPortfolio {
    if (json === undefined || json === null) {
        return json
    }
    return {
        uuid: json['uuid'],
        currency: CurrencyFromJSON(json['currency']),
        date: !exists(json, 'date') ? undefined : DateTime.fromISO(json['date'], {setZone: true}),
        portfolio_value: !exists(json, 'portfolio_value') ? undefined : json['portfolio_value'],
        total_return: !exists(json, 'total_return') ? undefined : json['total_return'],
        simple_return: !exists(json, 'simple_return') ? undefined : json['simple_return'],
        cost_basis: !exists(json, 'cost_basis') ? undefined : json['cost_basis'],
        cost_basis_max: !exists(json, 'cost_basis_max') ? undefined : json['cost_basis_max'],
        portfolio_risk_type: !exists(json, 'portfolio_risk_type')
            ? undefined
            : PortfolioRiskTypeFromJSON(json['portfolio_risk_type']),
        dividends: !exists(json, 'dividends') ? undefined : json['dividends'],
        managed_fund_transaction_fees: !exists(json, 'managed_fund_transaction_fees')
            ? undefined
            : json['managed_fund_transaction_fees'],
        tax_paid: !exists(json, 'tax_paid') ? undefined : json['tax_paid'],
        transaction_fees: !exists(json, 'transaction_fees') ? undefined : json['transaction_fees'],
        adr_fees: !exists(json, 'adr_fees') ? undefined : json['adr_fees'],
        realised_capital_gain: !exists(json, 'realised_capital_gain') ? undefined : json['realised_capital_gain'],
        realised_currency_gain: !exists(json, 'realised_currency_gain') ? undefined : json['realised_currency_gain'],
        realised_cost_basis: !exists(json, 'realised_cost_basis') ? undefined : json['realised_cost_basis'],
        portfolio_risk: !exists(json, 'portfolio_risk')
            ? undefined
            : mapValues(json['portfolio_risk'], BreakdownItemFromJSON),
        portfolio_countries: !exists(json, 'portfolio_countries')
            ? undefined
            : mapValues(json['portfolio_countries'], BreakdownItemFromJSON),
        portfolio_instrument_types: !exists(json, 'portfolio_instrument_types')
            ? undefined
            : mapValues(json['portfolio_instrument_types'], BreakdownItemFromJSON),
        portfolio_history: !exists(json, 'portfolio_history')
            ? undefined
            : (json['portfolio_history'] as Array<any>).map(PortfolioHistoryFromJSON),
        unrealised_dividends: !exists(json, 'unrealised_dividends') ? undefined : json['unrealised_dividends'],
        unrealised_managed_fund_transaction_fees: !exists(json, 'unrealised_managed_fund_transaction_fees')
            ? undefined
            : json['unrealised_managed_fund_transaction_fees'],
        unrealised_total_return: !exists(json, 'unrealised_total_return') ? undefined : json['unrealised_total_return'],
        unrealised_simple_return: !exists(json, 'unrealised_simple_return')
            ? undefined
            : json['unrealised_simple_return'],
        unrealised_tax_paid: !exists(json, 'unrealised_tax_paid') ? undefined : json['unrealised_tax_paid'],
        unrealised_total_transaction_fees: !exists(json, 'unrealised_total_transaction_fees')
            ? undefined
            : json['unrealised_total_transaction_fees'],
        unrealised_adr_fees: !exists(json, 'unrealised_adr_fees') ? undefined : json['unrealised_adr_fees'],
        unrealised_capital_gain: !exists(json, 'unrealised_capital_gain') ? undefined : json['unrealised_capital_gain'],
        unrealised_currency_gain: !exists(json, 'unrealised_currency_gain')
            ? undefined
            : json['unrealised_currency_gain'],
        fail: !exists(json, 'fail') ? undefined : json['fail'],
        reset: !exists(json, 'reset') ? undefined : DateTime.fromISO(json['reset'], {setZone: true}),
        recalc_required_from: !exists(json, 'recalc_required_from')
            ? undefined
            : DateTime.fromISO(json['recalc_required_from'], {setZone: true}),
    }
}

export function AppSchemasForAdminPortfolioPortfolioToJSON(value?: AppSchemasForAdminPortfolioPortfolio | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        uuid: value.uuid,
        currency: CurrencyToJSON(value.currency),
        date: value.date === undefined ? undefined : value.date.toFormat('yyyy-MM-dd'),
        portfolio_value: value.portfolio_value,
        total_return: value.total_return,
        simple_return: value.simple_return,
        cost_basis: value.cost_basis,
        cost_basis_max: value.cost_basis_max,
        portfolio_risk_type: PortfolioRiskTypeToJSON(value.portfolio_risk_type),
        dividends: value.dividends,
        managed_fund_transaction_fees: value.managed_fund_transaction_fees,
        tax_paid: value.tax_paid,
        transaction_fees: value.transaction_fees,
        adr_fees: value.adr_fees,
        realised_capital_gain: value.realised_capital_gain,
        realised_currency_gain: value.realised_currency_gain,
        realised_cost_basis: value.realised_cost_basis,
        portfolio_risk:
            value.portfolio_risk === undefined ? undefined : mapValues(value.portfolio_risk, BreakdownItemToJSON),
        portfolio_countries:
            value.portfolio_countries === undefined
                ? undefined
                : mapValues(value.portfolio_countries, BreakdownItemToJSON),
        portfolio_instrument_types:
            value.portfolio_instrument_types === undefined
                ? undefined
                : mapValues(value.portfolio_instrument_types, BreakdownItemToJSON),
        portfolio_history:
            value.portfolio_history === undefined
                ? undefined
                : (value.portfolio_history as Array<any>).map(PortfolioHistoryToJSON),
        unrealised_dividends: value.unrealised_dividends,
        unrealised_managed_fund_transaction_fees: value.unrealised_managed_fund_transaction_fees,
        unrealised_total_return: value.unrealised_total_return,
        unrealised_simple_return: value.unrealised_simple_return,
        unrealised_tax_paid: value.unrealised_tax_paid,
        unrealised_total_transaction_fees: value.unrealised_total_transaction_fees,
        unrealised_adr_fees: value.unrealised_adr_fees,
        unrealised_capital_gain: value.unrealised_capital_gain,
        unrealised_currency_gain: value.unrealised_currency_gain,
        fail: value.fail,
        reset: value.reset === undefined ? undefined : value.reset.toISO(),
        recalc_required_from: value.recalc_required_from === undefined ? undefined : value.recalc_required_from.toISO(),
    }
}
