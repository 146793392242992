import React from 'react'
import {clearAllQueryParameters} from '~/global/utils/clear-all-query-parameters/clearAllQueryParameters'
import {
    IdentityVerificationStep,
    showVerificationRequiredFlowIntro,
} from '~/global/utils/identity-verification/identityVerification'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {useNavigate} from '~/migrate-react-router'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/identity/actions'
import IdentityVerificationResult from './pages/completed/IdentityVerificationResult'
import ConfirmIdentityDetails from './pages/confirm/ConfirmIdentityDetails'
import IdentityVerificationIdSelector from './pages/id-selection/IdentityVerificationIdSelector'
import IdentityVerificationRequired from './pages/intro/IdentityVerificationRequired'

export const IdentityVerificationFlow: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const [step, setStep] = React.useState<IdentityVerificationStep>('verificationIntro')
    const [loading, setLoading] = React.useState(true)

    const identityVerification = useAppSelector(({identity}) => identity.user!.checks.identity_verification)
    const dispatch = useAppDispatch()
    const latestVerificationDetails = () => dispatch(actions.LatestVerificationDetails())

    // get verification statuses
    const additionalVerificationRequired = identityVerification.additional_verification_required
    const isIdentityLinked = identityVerification.is_identity_linked
    const isBiometricVerified = identityVerification.biometric_verified
    // get details of the latest biometric check
    const latestBiometricVerificationCheck = identityVerification.latest_biometric_verification_check
    const latestBiometricState = latestBiometricVerificationCheck?.state
    const verificationURL = latestBiometricVerificationCheck?.url

    React.useEffect(() => {
        const setLoaded = () => {
            setLoading(false)
        }

        // If you are on this page and an admin has cancelled your biometrics then you should be redirected
        // to the porfolio page so you do not see unexpected requests for details.
        if (latestBiometricState === 'CANCELLED') {
            return navigate(profileUrl(''))
        }

        // we only want to redirect to the confirmation page
        // 1. if the user has a correct redirect reference against BE-DB VC our_reference and
        // 2. there is no more (verifi cloudcheck token URL)

        // when do we regenerate verifi cloudcheck token URL
        // 1. verifiCloudCheckTokenizedURL will recreate automatically if the user quit the Biometric flow without completing (ABANDONED)
        // 2. when user using the worng ID that used in Biometric flow (ID_TYPE_FALIURE)

        if (!verificationURL && latestBiometricState !== 'PENDING') {
            setStep('confirmIdentityDetails' as IdentityVerificationStep)
        }
        // Fetch the verification check details from the backend to populate the form
        // with verify documents(license, passport, etc)
        latestVerificationDetails()
            .then(() => {
                // Verificaion required
                if (additionalVerificationRequired) {
                    // display verificaton intro page
                    if (showVerificationRequiredFlowIntro()) {
                        setStep('verificationIntro' as IdentityVerificationStep)
                    } else {
                        clearAllQueryParameters()
                        if (isIdentityLinked && isBiometricVerified) {
                            // after Verifi Biometric complete show
                            // Secondary Id form
                            setStep('confirmIdentityDetails' as IdentityVerificationStep)
                        } else if (isIdentityLinked) {
                            // Secondary Id flow for NZ and AU customers
                            setStep('verificationIdSelection' as IdentityVerificationStep)
                        } else if (
                            // after completed the Verifi Biometric flow wherther the user SUCCESFUL OR FAILED
                            !verificationURL &&
                            latestBiometricState !== 'PENDING' &&
                            isBiometricVerified
                        ) {
                            setStep('confirmIdentityDetails' as IdentityVerificationStep)
                            // } else {
                            //     setStep('verificationIdSelection' as IdentityVerificationStep)
                        }
                    }
                } else {
                    // successful biometric verification with Document path
                    clearAllQueryParameters()

                    // Safety check to ensure we only go to this screen if we still require Verification
                    if (additionalVerificationRequired) {
                        setStep('confirmIdentityDetails' as IdentityVerificationStep)
                    }
                }
            })
            .then(setLoaded)
    }, [latestBiometricState])

    if (loading) {
        return <Loading isPineapple />
    }

    const identityVerificationFlow = () => {
        switch (step) {
            case 'verificationIntro':
                return <IdentityVerificationRequired setStep={setStep} />
            case 'verificationIdSelection':
                return <IdentityVerificationIdSelector setStep={setStep} />
            case 'confirmIdentityDetails':
                return <ConfirmIdentityDetails setStep={setStep} />
            case 'completed':
                return <IdentityVerificationResult additionalVerificationRequired={additionalVerificationRequired} />
            default:
                return <IdentityVerificationRequired setStep={setStep} />
        }
    }

    return <Page withoutDefaultPadding>{identityVerificationFlow()}</Page>
}

export default IdentityVerificationFlow
