import {ChevronRight} from '@design-system/icon'
import {Thumbnail} from '@design-system/thumbnail'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {ListingResponseDto} from '~/api/distill'
import {distillGetFactory} from '~/api/query/distill'
import {useRetailGet} from '~/api/query/retail'
import {Response} from '~/api/retail/types'
import {spacing, accessibility} from '~/global/scss/helpers'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useAppSelector} from '~/store/hooks'
import styles from './ExploreTopTen.scss'

const useInstruments = (instrumentIds: string[]) => {
    const response = distillGetFactory({
        apiFunctionName: 'apiV1InstrumentsSearchV2Get',
    })({
        query: '',
        instruments: instrumentIds,
        scope: 'invest',
    })

    return response
}

interface InstrumentListProps {
    instruments: ListingResponseDto[]
}

const InstrumentList: React.FunctionComponent<InstrumentListProps> = ({instruments}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const path = useAppSelector(({instrument}) => instrument.metadata.resourcePath)

    return (
        <ol data-testid="explore-top-ten--list" className={styles.topTenInstrumentList}>
            {instruments.map((instrument, key) => {
                const url = profileUrl('invest/search/:instrumentSlug', {
                    instrumentSlug: instrument.urlSlug,
                })
                const imgPath = instrument.logos.micro ? `${path}${instrument.logos.micro}` : undefined
                return (
                    <li key={key} data-testid={`explore-top-ten--list-item-${key + 1}`}>
                        <button
                            type="button"
                            onClick={() => navigate(url)}
                            className={cn(styles.topTenInstrument, accessibility.button)}
                        >
                            <div className={styles.thumbnailWrapper}>
                                <Thumbnail
                                    dataTestId={`${instrument.urlSlug}--logo`}
                                    width="28px"
                                    height="28px"
                                    symbol={instrument.symbol}
                                    path={imgPath}
                                    noBorder
                                />
                            </div>
                            <div>
                                <h2>{instrument.name}</h2>
                                <p>
                                    {instrument.symbol}{' '}
                                    {instrument.exchange && (
                                        <>
                                            <span>|</span> {instrument.exchange}
                                        </>
                                    )}
                                </p>
                            </div>
                            <ChevronRight colour="neutral200" />
                        </button>
                    </li>
                )
            })}
        </ol>
    )
}

const NZDisclaimer: React.FunctionComponent = () => {
    return (
        <>
            <p>
                Our Top Buys lists reflect the number of distinct NZ-based Sharesies investors who executed one or more
                purchases on Sharesies (excluding KiwiSaver) within the specified week. It does not account for the
                total number of shares bought in a transaction or the value of transactions, or any subsequent sell
                orders. Please note that this data may be impacted by ad hoc activity such as Staff Share distributions
                and automated dividend reinvestment via Sharesies.
            </p>
            <p className={spacing.spaceAbove24}>
                Investing involves risk. This information is factual and general in nature. Sharesies does not provide
                financial advice or recommendations. If you are unsure, you should speak with a licensed financial
                adviser.
            </p>
        </>
    )
}

const AUDisclaimer: React.FunctionComponent = () => {
    return (
        <>
            <p>
                Our Top Buys lists reflect the number of orders placed by Australian-based Sharesies investors on our
                platform during the specified week. It does not consider the aggregate value of orders or subsequent
                sales of those investments.
            </p>
            <p className={spacing.spaceAbove24}>
                Investing involves risk. This information is factual and general in nature. Any mention of a financial
                product does not constitute an offer or recommendation to invest in that product. It is important to
                read the relevant disclosure documents before investing in a financial product. This is not financial
                advice and if you require advice specific to your circumstances, you should speak with a licensed
                financial adviser.
            </p>
        </>
    )
}

export interface ExploreTopTenV2PageProps {
    topTen: Response.ExploreTopTenFundsLastWeekDataV2
    instruments: ListingResponseDto[]
}

export const ExploreTopTenV2Page: React.FunctionComponent<ExploreTopTenV2PageProps> = ({topTen, instruments}) => {
    const {jurisdiction} = topTen
    const start = topTen.start.toFormat(dateFormatNoTime)
    const end = topTen.end.toFormat(dateFormatNoTime)
    const title = `Top 10 buys last week: ${topTen.title}`

    return (
        <div data-testid="page--explore-top-ten">
            <Toolbar dataTestId="toolbar--explore-landing" leftButton="back" title={title} />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <p data-testid="explore-top-ten--description">
                    From {start}–{end}, these were the 10 {topTen.description_label} on Sharesies that had the most
                    buys, based on the number of individual investors.
                </p>
                <InstrumentList instruments={instruments} />
                {jurisdiction === 'nz' ? <NZDisclaimer /> : <AUDisclaimer />}
            </Page>
        </div>
    )
}

interface ExploreTopTenV2Props {
    slug: Response.ExploreTopTenFundsLastWeekDataV2['slug']
}

const ExploreTopTenV2: React.FunctionComponent<ExploreTopTenV2Props> = ({slug}) => {
    const {data: topTen} = useRetailGet({
        path: 'explore/weekly-top-ten-funds-v2',
        payload: {slug},
    })

    const {data: results} = useInstruments(topTen.fund_ids)

    return <ExploreTopTenV2Page instruments={results.instruments} topTen={topTen} />
}

export default ExploreTopTenV2
