import {Modal} from '@design-system/modal'
import React from 'react'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useInstrument} from '~/global/utils/use-instruments/useInstruments'
import {Instrument} from '~/store/instrument/types'

interface Props {
    instrument: Instrument
    onCancel: () => void
}

const HighRiskDerivativeModal: React.FunctionComponent<Props> = ({instrument, onCancel}) => {
    const parentInstrument = useInstrument(instrument.parentInstrumentId)
    const [isOpen, setIsOpen] = React.useState(true)

    if (!parentInstrument) {
        return null
    }

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={() => setIsOpen(false)}
            dataTestId="modal--high-risk-derivative-warning"
            title="Just a heads up"
            primaryButton={{
                label: 'Cancel',
                onClick: onCancel,
            }}
            secondaryButton={{label: 'Okay, got it'}}
        >
            <p>
                Make sure to check {parentInstrument.name}’s {shareLabel({instrument: parentInstrument})} price. If the{' '}
                {shareLabel({instrument, isPlural: true})}’ exercise price is higher than the{' '}
                {shareLabel({instrument: parentInstrument})} price, you might pay more for the{' '}
                {shareLabel({instrument: parentInstrument, isPlural: true})} than they're worth at the time.
            </p>
        </Modal>
    )
}

export default HighRiskDerivativeModal
