import config from '~/configForEnv'

/**
 * Cove iframe embed URL that automatically logs the user into Cove.
 * The URL first navigates to Cove's login page, where Cove uses the login ID to authenticate the user,
 * and then redirects to the target path.
 *
 * For this to be applicable/necessary, the user must be a full Cove customer - i.e. they have an active,
 * pending, or cancelled policy.
 * If they have not had a policy before, while they have a Cove customer ID, they are not considered full
 * customers on the Cove side, and it's not relevant/possible for them to log in.
 * Therefore, check whether they're a Cove customer (`insure/get-is-cove-customer`) before
 * calling this function.
 *
 * @param {string} loginId - This login ID is retrieved from Cove via the back-end.
 * It enables Cove to automatically log the user into the embedded Cove website.
 * @param {string} targetPath - This should always be preceded by a slash, e.g. /user/change-payment'.
 * It may have its own params, e.g. /user/policy/?pno=MV-12345
 */
export const constructCoveEmbedUserLoginUrl = (loginId: string, targetPath: string): URL => {
    const params = {login_uuid: loginId, redirect: targetPath}
    const url = new URL(`${config.coveEmbedBaseUrl}/user/login/`) // Needs trailing slash
    url.search = new URLSearchParams(params).toString()

    return url
}
