import React from 'react'
import config from '~/configForEnv'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'
import ReFetch from './ReFetch'

const IdentityRefetch: React.FunctionComponent<IdentityProps> = React.memo(({fetchIdentity}) => (
    <ReFetch
        fetchAction={fetchIdentity}
        timeoutPeriod={config.userCheckInterval} // 5 minutes
    />
))

type IdentityProps = DispatchProps

interface DispatchProps {
    fetchIdentity(): void
}

export default connect<{}, DispatchProps, {}>(undefined, dispatch => ({
    fetchIdentity: () => dispatch(actions.Check()),
}))(IdentityRefetch)
