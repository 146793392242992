import React from 'react'
import votingSlide5Dark from '~/global/assets/images/intro-slides/voting-slides/voting-slide-5-dark.svg'
import votingSlide5Light from '~/global/assets/images/intro-slides/voting-slides/voting-slide-5-light.svg'
import {LearnModuleSlideContent} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {IntroSlides} from '~/global/widgets/intro-slides/IntroSlides'
import fruitbowlDark from '~/sections/kiwisaver/sections/sign-up/assets/images/learn-the-basics/1-fruitbowl-dark.svg'
import fruitbowl from '~/sections/kiwisaver/sections/sign-up/assets/images/learn-the-basics/1-fruitbowl.svg'
import globeDark from '~/sections/kiwisaver/sections/sign-up/assets/images/learn-the-basics/2-globe-dark.svg'
import globe from '~/sections/kiwisaver/sections/sign-up/assets/images/learn-the-basics/2-globe.svg'
import structureDark from '~/sections/kiwisaver/sections/sign-up/assets/images/learn-the-basics/3-structure-dark.svg'
import structure from '~/sections/kiwisaver/sections/sign-up/assets/images/learn-the-basics/3-structure.svg'
import salaryDark from '~/sections/kiwisaver/sections/sign-up/assets/images/learn-the-basics/4-salary-dark.svg'
import salary from '~/sections/kiwisaver/sections/sign-up/assets/images/learn-the-basics/4-salary.svg'
import employerDark from '~/sections/kiwisaver/sections/sign-up/assets/images/learn-the-basics/5-employer-dark.svg'
import employer from '~/sections/kiwisaver/sections/sign-up/assets/images/learn-the-basics/5-employer.svg'
import governmentDark from '~/sections/kiwisaver/sections/sign-up/assets/images/learn-the-basics/6-government-dark.svg'
import government from '~/sections/kiwisaver/sections/sign-up/assets/images/learn-the-basics/6-government.svg'
import withdrawnDark from '~/sections/kiwisaver/sections/sign-up/assets/images/learn-the-basics/7-withdrawn-dark.svg'
import withdrawn from '~/sections/kiwisaver/sections/sign-up/assets/images/learn-the-basics/7-withdrawn.svg'
import styles from './Slides.scss'

const BasicsIntroContent: LearnModuleSlideContent[] = [
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: fruitbowl,
        darkImage: fruitbowlDark,
        bodyRenderer: () => <p>KiwiSaver is a savings scheme designed to set you up for retirement.</p>,
    },
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: globe,
        darkImage: globeDark,
        bodyRenderer: () => <p>To join a KiwiSaver Scheme, you’ll need to be a New Zealand resident or citizen.</p>,
    },
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: structure,
        darkImage: structureDark,
        bodyRenderer: () => <p>You, your employer and the government add money, which is then invested.</p>,
    },
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: salary,
        darkImage: salaryDark,
        bodyRenderer: () => (
            <p>
                You’ll choose the percentage of <strong>your salary</strong> you want to contribute ...
            </p>
        ),
    },
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: employer,
        darkImage: employerDark,
        bodyRenderer: () => (
            <p>
                ... and your <strong>employer</strong> will contribute at least 3% on top of your pay.
            </p>
        ),
    },
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: government,
        darkImage: governmentDark,
        bodyRenderer: () => (
            <p>
                The <strong>government</strong> will also add up to $521 per year if you put in $1,043 or more.
            </p>
        ),
    },
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: withdrawn,
        darkImage: withdrawnDark,
        bodyRenderer: () => <p>Usually, it’s withdrawn for a first home deposit, or for retirement at 65. </p>,
    },
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: votingSlide5Light,
        darkImage: votingSlide5Dark,
        bodyRenderer: () => (
            <p>
                For more info on the basics of KiwiSaver{' '}
                <a href="https://www.sharesies.nz/learn/how-does-kiwisaver-work" target="_blank" rel="noreferrer">
                    check out our explainer
                </a>
                .
            </p>
        ),
    },
]

interface BasicsIntroSlideProps {
    onComplete: () => void
    onBack?: () => void
}

export const BasicsIntroSlides = ({onComplete, onBack}: BasicsIntroSlideProps) => {
    return (
        <IntroSlides content={BasicsIntroContent} onComplete={onComplete} onCompleteLabel="Got it!" onBack={onBack} />
    )
}
