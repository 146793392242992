import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {Request, Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {monthlyLabelGenerator} from '~/global/utils/monthly-label-generator/monthlyLabelGenerator'
import Chips from '~/global/widgets/chips/Chips'
import {Loading} from '~/global/widgets/loading'
import {usePortfolioOrdersInfiniteQuery} from '~/sections/moose/data/retail'
import {MoosePortfolioPathParams} from '~/sections/moose/routes'
import InvestingActivityCard from '~/sections/moose/sections/instrument/widgets/activity-card/InvestingActivityCard'
import styles from './InvestingActivity.scss'

interface InvestingActivityProps extends MoosePortfolioPathParams {
    instrumentId?: string | undefined
}

const filters: Record<string, Request.PortfolioOrders['side_filter']> = {
    All: undefined,
    Buys: 'BUY',
    Sells: 'SELL',
}

const InvestingActivity: React.FunctionComponent<InvestingActivityProps> = ({portfolioId, instrumentId}) => {
    const [selectedFilter, setSelectedFilter] = React.useState<keyof typeof filters>('All')

    return (
        <div className={cn(styles.investingActivity, spacing.spaceAbove32)} data-testid="section--investing-activity">
            <h2 className={spacing.spaceAbove16}>Investing activity</h2>
            <div className={styles.filterControlContainer}>
                <Chips
                    options={Object.entries(filters).map(([name]) => name)}
                    onChipClick={selected => setSelectedFilter(selected)}
                    selected={[selectedFilter]}
                    isInlineDisplay
                />
                <React.Suspense fallback={<Loading />}>
                    <InvestingActivityOrders
                        portfolioId={portfolioId}
                        instrumentId={instrumentId}
                        sideFilter={filters[selectedFilter]}
                    />
                </React.Suspense>
            </div>
        </div>
    )
}

interface InvestingActivityOrdersProps extends InvestingActivityProps {
    instrumentId?: string | undefined
    sideFilter: Request.PortfolioOrders['side_filter']
}

const InvestingActivityOrders: React.FunctionComponent<InvestingActivityOrdersProps> = ({
    portfolioId,
    instrumentId,
    sideFilter,
}) => {
    const {data, fetchNextPage, hasNextPage, isFetchingNextPage} = usePortfolioOrdersInfiniteQuery(portfolioId, {
        fund_id: instrumentId,
        side_filter: sideFilter,
    })
    const labelFor = monthlyLabelGenerator()

    let orders: Response.PortfolioOrders['orders'] = []
    data?.pages.forEach(page => {
        orders = orders.concat(...page.orders)
    })

    return (
        <>
            {orders.length > 0 ? (
                <div className={styles.activityContainer}>
                    {orders.map(order => {
                        const label = labelFor(order.date)
                        return (
                            <div key={order.id}>
                                {label && !instrumentId && <div className={styles.date}>{label}</div>}
                                <InvestingActivityCard key={order.key} activity={order} portfolioId={portfolioId} />
                            </div>
                        )
                    })}
                </div>
            ) : (
                <p className={spacing.spaceAbove32}>No past activity</p>
            )}

            {hasNextPage && (
                <Button
                    dataTestId="button--load-older-portfolio-orders"
                    label="Load older investing activity"
                    onClick={() => fetchNextPage()}
                    processing={isFetchingNextPage}
                    additionalClassName={spacing.spaceAbove24}
                />
            )}
        </>
    )
}

export default InvestingActivity
