import {Button} from '@design-system/button'
import {Flag} from '@design-system/flag/Flag'
import {Formik} from 'formik'
import React from 'react'
import {useNavigate} from 'react-router'
import {Link} from 'react-router-dom'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import NotFound from '~/global/pages/not-found/NotFound'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import humaniseList from '~/global/utils/humanise-list/humaniseList'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AccountVerificationRequired from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import {validate} from '~/global/widgets/form-controls'
import {extractCountryNameFromCode} from '~/global/widgets/form-controls/country-select/CountrySelectInput'
import {Text} from '~/global/widgets/form-controls/formik'
import Citizenship from '~/global/widgets/help-modals/Citizenship'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {settingsScreenVersion} from '~/sections/user/sections/settings/Settings'
import NatureAndPurpose from '~/sections/user/sections/settings/widgets/nature-and-purpose/NatureAndPurpose'
import PrescribedPerson from '~/sections/user/sections/settings/widgets/prescribed-person/PrescribedPerson'
import styles from './PersonalDetails.scss'

const PersonalDetails = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const owner = useProfileOwner()

    const citizenshipCountryCodes = useRetailGet({
        path: 'owner/:owner_id/citizenships',
        pathParams: {owner_id: owner.id},
    }).data.countries

    const {address_state, address} = useRetailGet({
        path: 'owner/:owner_id/address',
        pathParams: {owner_id: owner.id},
    }).data

    const foreignCitizenshipCountryCodes = citizenshipCountryCodes.filter(
        code => code.toLowerCase() !== owner.product_jurisdiction,
    )
    const citizenshipCountryNames = citizenshipCountryCodes.map(extractCountryNameFromCode)
    const foreignCitizenshipCountryNames = foreignCitizenshipCountryCodes.map(extractCountryNameFromCode)
    const jurisdictionName = extractCountryNameFromCode(owner.product_jurisdiction)

    const setDisplayName = useRetailPost({
        path: 'owner/:owner_id/display-name',
        pathParams: {owner_id: owner.id},
        queryCacheToInvalidate: [['actor/self'], ['owner/:owner_id', {owner_id: owner.id}], ['profiles']],
    })

    return (
        <>
            <Toolbar dataTestId="toolbar--personal-details" leftButton="back" title="Personal details" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <AccountVerificationRequired />
                <Formik
                    initialValues={{
                        preferredName: owner.display_name,
                    }}
                    onSubmit={async ({preferredName}, {setFieldError}) => {
                        try {
                            await setDisplayName.mutateAsync({display_name: preferredName})
                        } catch (e: any) {
                            if (e.type === 'form_errors') {
                                setFieldError('preferredName', e.errors.display_name)
                                return
                            }
                            throw e
                        }

                        Toast('Details updated')

                        navigate(profileUrl('settings'))
                    }}
                    validate={validate.generate({
                        preferredName: [
                            validate.required(),
                            validate.maxLength(80, 'Your preferred name can’t be longer than 80 characters'),
                        ],
                    })}
                    validateOnMount={true}
                >
                    {({handleSubmit, isSubmitting, isValid, dirty}) => (
                        <form onSubmit={handleSubmit}>
                            <Text dataTestId="text-input--preferred-name" name="preferredName" label="Preferred name" />

                            <div className={styles.title}>
                                <h2>Address</h2>
                                <Link
                                    data-testid="link--edit-address"
                                    to={profileUrl('settings/personal-details/edit-address')}
                                >
                                    Edit
                                </Link>
                            </div>
                            <p className={styles.address}>{address.formatted}</p>

                            {address_state === 'uploaded' && (
                                <Flag
                                    dataTestId="address-verification-pending-flag"
                                    text="Address verification pending"
                                />
                            )}

                            {['new', 'rejected'].includes(address_state) && (
                                <Flag
                                    dataTestId="address-verification-pending-flag"
                                    type="error"
                                    text="Address not verified"
                                />
                            )}

                            <PrescribedPerson
                                owner={owner}
                                edit_url="settings/personal-details/prescribed-person"
                                section_class={styles.title}
                            />

                            {citizenshipCountryNames.length > 0 && (
                                <>
                                    <div className={styles.title}>
                                        <h2>
                                            Citizenship <Citizenship />
                                        </h2>

                                        <Link
                                            data-testid="anchor--edit-citizenship"
                                            to={profileUrl('settings/personal-details/citizenship-details')}
                                        >
                                            Edit
                                        </Link>
                                    </div>
                                    <p>
                                        {
                                            //Citizenship in jurisdiction is listed first if it exists
                                            citizenshipCountryNames.includes(jurisdictionName)
                                                ? humaniseList([jurisdictionName, ...foreignCitizenshipCountryNames])
                                                : humaniseList(citizenshipCountryNames)
                                        }
                                    </p>
                                </>
                            )}

                            <NatureAndPurpose
                                owner={owner}
                                edit_url="settings/personal-details/how-you-invest"
                                section_class={styles.title}
                            />

                            <Button
                                isSubmit
                                pageButton
                                label="Save changes"
                                dataTestId="button--save-changes"
                                disabled={!isValid || !dirty}
                                processing={isSubmitting}
                            />
                        </form>
                    )}
                </Formik>
            </Page>
        </>
    )
}

const PersonalDetailsWrapper = () => {
    const profile = useProfile()
    return settingsScreenVersion(profile) === 'personal' ? <PersonalDetails /> : <NotFound />
}

export default PersonalDetailsWrapper
