import * as api from '~/api/retail'
import * as rollbar from '~/api/rollbar/rollbar'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {ExchangeRate} from '~/store/accounting/types'

export type XeError = 'xe_unreachable'

export const updateExchangeRates = async (
    actingAsId: string,
    setExchangeRates: (rates: ExchangeRate[]) => void,
    setExchangeRateError: (error: boolean) => void,
) => {
    const ratePairs = getExchangeRates(actingAsId)

    ratePairs.then(exchangeRates => {
        if (exchangeRates === 'xe_unreachable') {
            setExchangeRateError(true)
        } else {
            setExchangeRates(exchangeRates as ExchangeRate[])
        }
    })
}

export const getExchangeRates: (actingAsId: string) => Promise<ExchangeRate[] | XeError | null> = async actingAsId => {
    const payload = {
        acting_as_id: actingAsId,
    }
    const endpoint = 'fx/get-rate-v2'
    const response = await api.get(endpoint, payload)

    switch (response.type) {
        case 'fx_order_current_rate_v2':
            return response.fx_currencies.map(exchange => ({
                sourceCurrency: exchange.source_currency,
                targetCurrency: exchange.target_currency,
                rate: parseFloat(exchange.rate),
            }))
        case 'error':
            rollbar.sendError(`fxGetRate fail: ${response.message}`, {
                method: 'GetExchangeRate',
                endpoint,
                code: response.code,
                message: response.message,
            })
            if (response.code === 'xe_unreachable') {
                return response.code
            }
            return null
        case 'internal_server_error':
            // TODO: handle this properly
            return null
        default:
            assertNever(response)
            return null
    }
}
