import {Button} from '@design-system/button'
import {DockableModal} from '@design-system/dockable-modal'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {Link} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import humaniseList from '~/global/utils/humanise-list/humaniseList'
import {calculateAverageTransferPrice, isUSExchange} from '~/global/utils/share-transfers/shareTransfers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {TransferOutFees, TransferInFees} from '~/global/widgets/help-modals/TransferFees'
import recordStyles from '~/global/widgets/instrument-activity/Record.scss'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {Loading} from '~/global/widgets/loading/Loading'
import {ShareValue, DollarValue, SharePriceValue} from '~/global/widgets/number-elements/NumberElements'
import {OrderConfirmation} from '~/global/widgets/order-confirmation/OrderConfirmation'
import Page from '~/global/widgets/page/Page'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {TransferCompleteStateLabel} from '~/sections/invest/sections/transfer-shares/widgets/completed-transfer/CompletedTransfer'
import {TransferPendingStateLabel} from '~/sections/invest/sections/transfer-shares/widgets/pending-transfer/PendingTransfer'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'
import {setModalError} from '~/store/notification'
import actions from '~/store/transfer/actions'
import {NEW_DRS_REQUIRED, NEW_SRN_REQUIRED} from '~/store/transfer/types'

/**View a transfer group, in non-terminal states.
 *A transfer group represents the transfers which were created at the same time.
 */

export const ViewTransferGroup: React.FunctionComponent<{transferOrderGroupId: string}> = ({transferOrderGroupId}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const transferLoadingState = useAppSelector(({transfer}) => transfer.transfersLoadingState)
    const [isLoading, setIsLoading] = React.useState(transferLoadingState === 'loading')
    const [modalShown, setModalShown] = React.useState(false)
    const [isSubmittingCancel, setIsSubmittingCancel] = React.useState(false)

    const allTransferOrders = useAppSelector(({transfer}) => transfer.transferOrders)
    const groupedTransfers = useAppSelector(({transfer}) => transfer.groupedTransfers)
    const instrumentsById = useAppSelector(({instrument}) => instrument.instrumentsById)
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)

    const getInstrumentsByIds = (instrumentIds: string[]) =>
        dispatch(instrumentActions.getInstrumentsByIds(instrumentIds))
    const transferOrderGroup = groupedTransfers.find(group => group.group_id === transferOrderGroupId)

    const onCancel = async () => {
        setIsSubmittingCancel(true)
        try {
            const error = await dispatch(actions.CancelTransferGroup(transferOrderGroupId))
            if (error) {
                setModalError('We’re sorry, there’s been an error')
            }
            setIsSubmittingCancel(false)
            setModalShown(false)
            navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})
            Toast('Transfer cancelled')
        } catch (e) {
            setModalError(
                'Something caused it, maybe gremlins? Please try again or let us know if it’s still not working.',
                'We’re sorry, there’s been an error',
            )
        }
    }

    const onContinueApplication = () => {
        dispatch(actions.SetStagedTransfer(transferOrderGroupId))
        const urlSuffix = isUSExchange(transferOrderGroup?.exchange) ? 'us' : 'asx'

        if (transferOrderGroup?.us_transfer_broker === 'EXTERNAL') {
            navigate(
                profileUrl(`invest/portfolio-transfer-shares/us/:groupId/upload-documents`, {
                    groupId: transferOrderGroupId,
                }),
            )
        } else {
            navigate(
                profileUrl(`invest/portfolio-transfer-shares/${urlSuffix}/:groupId/download-transfer-form`, {
                    groupId: transferOrderGroupId,
                }),
            )
        }
    }

    React.useEffect(() => {
        const instrumentIdsToLoad = allTransferOrders.reduce<string[]>((instrumentIds, transferOrder) => {
            const transferFundId = transferOrder.fund_id
            if (!instrumentsById[transferFundId]) {
                return [...instrumentIds, transferFundId]
            }
            return instrumentIds
        }, [])

        if (transferLoadingState === 'loading') {
            setIsLoading(true)
            dispatch(actions.FetchTransfers())
        }

        if (transferLoadingState === 'ready' && instrumentIdsToLoad.length > 0) {
            getInstrumentsByIds(instrumentIdsToLoad)
        }

        if (['ready', 'error'].includes(transferLoadingState) && instrumentIdsToLoad.length === 0) {
            setIsLoading(false)
        }
    }, [allTransferOrders, transferLoadingState, instrumentsById])

    if (isLoading) {
        return <Loading isPineapple />
    }

    if (!transferOrderGroup) {
        navigate(profileUrl('invest/portfolio-transfer-shares'))
        return <></>
    }

    const state = transferOrderGroup.state
    const groupInstrumentNames = transferOrderGroup.instruments.map(
        groupInstrument => instrumentsById[groupInstrument.instrument_id]?.name,
    )
    const isUsExternalTransfer =
        transferOrderGroup.us_transfer_broker && transferOrderGroup.us_transfer_broker === 'EXTERNAL'

    return (
        <div>
            <Toolbar dataTestId="toolbar--transfer-group" leftButton="back" hideIntercom />
            <Page>
                <div className={cn(commonStyles.textAndStatusLabel, spacing.spaceBelow16)}>
                    <h1>Transfer {transferOrderGroup.direction} details</h1>
                    <TransferPendingStateLabel transferOrderGroup={transferOrderGroup} />
                    {transferOrderGroup.transfer_entire_portfolio && transferOrderGroup.state === 'cancelled' && (
                        <TransferCompleteStateLabel transferOrderGroup={transferOrderGroup} transferOrders={[]} />
                    )}
                </div>
                {(transferOrderGroup.instruments.length > 0 || transferOrderGroup.transfer_entire_portfolio) && (
                    <>
                        <div className={cn(spacing.spaceAbove32)}>
                            <OrderConfirmation
                                key={transferOrderGroupId}
                                items={[
                                    {
                                        description: 'Transfer requested',
                                        value: (
                                            <strong>{transferOrderGroup.created.toFormat(dateFormatWithYear)}</strong>
                                        ),
                                    },
                                    {
                                        description:
                                            transferOrderGroup.reference_type === 'CSN'
                                                ? 'CSN/HN'
                                                : transferOrderGroup.reference_type,
                                        value:
                                            transferOrderGroup.reference === NEW_SRN_REQUIRED ||
                                            transferOrderGroup.reference === NEW_DRS_REQUIRED ? (
                                                ''
                                            ) : (
                                                <strong>{transferOrderGroup.reference}</strong>
                                            ),
                                    },
                                    ...(transferOrderGroup.transfer_entire_portfolio
                                        ? [
                                              {
                                                  description: 'Shares to transfer',
                                                  value: <strong>All</strong>,
                                              },
                                          ]
                                        : []),
                                ]}
                            />
                        </div>
                        {transferOrderGroup.transfer_entire_portfolio &&
                            (transferOrderGroup.state !== 'cancelled' ? (
                                <>
                                    <h2 className={spacing.spaceBelow16}>
                                        You’ve chosen to transfer all shares on this CSN into Sharesies.
                                    </h2>
                                    <p>
                                        Once the transfer is complete, you can adjust the average price you’ve paid for
                                        your transferred shares by going to{' '}
                                        <strong>Completed transfers &gt; View &gt; Edit price</strong>.
                                    </p>
                                </>
                            ) : (
                                <p className={spacing.spaceAbove8}>
                                    <Link to={profileUrl('invest/portfolio-transfer-shares/transfer-direction')}>
                                        Request another share transfer
                                    </Link>
                                </p>
                            ))}
                        {state === 'incomplete' && (
                            <Button
                                additionalClassName={spacing.spaceAbove24}
                                type="primary"
                                dataTestId="button--continue-application"
                                label={transferOrderGroup.rejected ? 'Check application' : 'Continue application'}
                                onClick={() => onContinueApplication()}
                            />
                        )}
                        {state && ['incomplete', 'new'].includes(state) && (
                            <Button
                                additionalClassName={cn(spacing.spaceAbove24, spacing.spaceBelow8)}
                                type="secondary"
                                label="Cancel transfer"
                                dataTestId="button--cancel-transfer"
                                onClick={() => setModalShown(true)}
                            />
                        )}
                        {!transferOrderGroup.transfer_entire_portfolio && (
                            <h1 className={cn(spacing.spaceAbove8, spacing.spaceBelow16)}>Transfers</h1>
                        )}
                        {transferOrderGroup.instruments!.map(transferOrderInstrument => {
                            const instrument = instrumentsById[transferOrderInstrument.instrument_id]

                            const transfer = allTransferOrders.find(
                                o => o.id === transferOrderInstrument.transfer_order_id,
                            )!
                            const averagePriceData = calculateAverageTransferPrice(transfer)
                            const transferTotal = {
                                description:
                                    transfer.state === 'complete'
                                        ? 'Shares transferred'
                                        : `Shares to transfer ${transferOrderGroup.direction}`,
                                value:
                                    transfer.transfer_all_shares && !transfer.shares ? (
                                        <strong>All</strong>
                                    ) : (
                                        <ShareValue value={transfer.shares || '0'} />
                                    ),
                            }

                            const items = []

                            if (transfer.state === 'cancelled' && transfer.cancelled_at) {
                                items.push({
                                    description: 'Transfer cancelled',
                                    value: transfer.cancelled_at.toFormat(dateFormatWithYear),
                                })
                            } else if (transfer.state === 'rejected' && transfer.rejected_at) {
                                items.push({
                                    description: 'Transfer failed',
                                    value: transfer.rejected_at.toFormat(dateFormatWithYear),
                                })
                            } else if (transfer.state === 'complete' && transfer.complete_at) {
                                items.push({
                                    description: 'Transfer completed',
                                    value: transfer.complete_at.toFormat(dateFormatWithYear),
                                })
                            }

                            // Unknown number of shares, show the cut down display
                            if (transfer.transfer_all_shares && !transfer.shares) {
                                // We only allow Transfer All for transfers in
                                items.push({
                                    description: 'Average price paid per share',
                                    value: (
                                        <SharePriceValue
                                            value={averagePriceData.price}
                                            currency={instrument.currency}
                                        />
                                    ),
                                })
                            } else {
                                if (transfer.type === 'transfer_in') {
                                    items.push({
                                        description: 'Average price paid per share',
                                        value: (
                                            <SharePriceValue
                                                value={averagePriceData.price}
                                                currency={instrument.currency}
                                            />
                                        ),
                                    })
                                } else if (transfer.type === 'transfer_out') {
                                    items.push({
                                        description: ['cancelled', 'rejected', 'complete'].includes(transfer.state)
                                            ? 'Price per share'
                                            : 'Estimated price per share',
                                        value: (
                                            <SharePriceValue
                                                value={
                                                    transfer.estimated_share_price_at_transfer
                                                        ? transfer.estimated_share_price_at_transfer
                                                        : transfer.average_price
                                                          ? transfer.average_price
                                                          : 0
                                                }
                                                currency={instrument.currency}
                                            />
                                        ),
                                    })
                                }

                                items.push({
                                    description: 'Amount',
                                    value: (
                                        <DollarValue
                                            value={transfer.total_transfer_value || '0'}
                                            currency={instrument.currency}
                                            decimalPlaces={2}
                                        />
                                    ),
                                })

                                // FEES
                                if (
                                    ['new', 'processing', 'complete'].includes(transfer.state) &&
                                    transfer.type === 'transfer_out' &&
                                    transfer.us_transfer_broker !== 'INTERNAL'
                                ) {
                                    items.push({
                                        description: (
                                            <>
                                                Transfer fee charged by Sharesies{' '}
                                                <TransferOutFees
                                                    exchange={transfer.exchange}
                                                    platform={transferOrderGroup.us_transfer_broker}
                                                />
                                            </>
                                        ),
                                        value: (
                                            <DollarValue
                                                value={transfer.fee_amount}
                                                currency={transfer.fee_currency}
                                                decimalPlaces={0}
                                            />
                                        ),
                                    })
                                } else if (transfer.type === 'transfer_in' && isUsExternalTransfer) {
                                    items.push({
                                        description: (
                                            <>
                                                Transfer in fee{' '}
                                                <TransferInFees
                                                    exchange={transfer.exchange}
                                                    platform={transferOrderGroup.us_transfer_broker}
                                                />
                                            </>
                                        ),
                                        value: (
                                            <DollarValue
                                                currency={transfer.fee_currency}
                                                decimalPlaces={0}
                                                value={transfer.fee_amount!}
                                            />
                                        ),
                                    })
                                }
                            }

                            return (
                                <>
                                    <OrderConfirmation
                                        key={transfer.id}
                                        title={instrument.name}
                                        subtitle={
                                            <p className={recordStyles.instrumentSymbol}>
                                                <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                                                <PronounceLetters text={instrument.exchange} />
                                            </p>
                                        }
                                        image={<InstrumentLogo instrument={instrument} noBorder />}
                                        total={transferTotal}
                                        items={items}
                                    />
                                </>
                            )
                        })}
                        {(transferOrderGroup.direction === 'out' || isUSExchange(transferOrderGroup.exchange)) &&
                            transferOrderGroup.reference_type !== 'DRS' && (
                                <div className={commonStyles.feesTotalRow}>
                                    <OrderConfirmation
                                        items={[]}
                                        total={{
                                            value: (
                                                <DollarValue
                                                    // currency={transferOrderGroup.total_group_fee_currency} I&S TODO - make fee currency match instrument currency for all markets
                                                    currency={
                                                        transferOrderGroup.us_transfer_broker ? 'USD' : homeCurrency
                                                    }
                                                    decimalPlaces={0}
                                                    value={transferOrderGroup.total_group_fee}
                                                />
                                            ),
                                            description: 'Total fee',
                                        }}
                                    />
                                </div>
                            )}
                    </>
                )}
                <DockableModal
                    dataTestId="modal--confirm-cancel-transfer"
                    isOpen={modalShown}
                    setIsOpen={setModalShown}
                    title="Cancel transfer"
                    content={
                        <div>
                            {groupInstrumentNames && groupInstrumentNames.length > 0 && (
                                <p className={spacing.spaceBelow24}>
                                    You created a transfer for {humaniseList(groupInstrumentNames)}.
                                </p>
                            )}

                            <p className={spacing.spaceBelow24}>Are you sure you want to cancel this transfer?</p>
                            <Button
                                additionalClassName={spacing.spaceBelow12}
                                dataTestId="button--cancel-transfer-modal"
                                label="Cancel transfer"
                                onClick={onCancel}
                                processing={isSubmittingCancel}
                            />
                            <Button
                                dataTestId="button--close-modal"
                                label="No, don't cancel"
                                type="secondary"
                                onClick={() => setModalShown(false)}
                            />
                        </div>
                    }
                />
            </Page>
        </div>
    )
}

export default ViewTransferGroup
