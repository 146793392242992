export const convertToSimpleURL = (url: string) => {
    // split the url by '//' (only valid urls will contain this); we know the part preceeding // will always be 'http'/'https', so the second element of the split must be the domain name
    const splitURL = url.split('//')

    // if the second split element (domain name) contains 'www.', remove it from the string
    const urlWithoutProtocol = splitURL.length === 1 ? splitURL[0] : splitURL[1].replace('www.', '')

    // split the url by '/' to filter out paths
    const splitURLWithoutProtocols = urlWithoutProtocol.split('/')

    // return only domain + top level domain
    return splitURLWithoutProtocols[0]
}
