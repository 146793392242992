import {Modal} from '@design-system/modal'
import {DateTime} from 'luxon'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {Toast} from '~/global/widgets/toast/Toast'
import {useLastDayOfSubscription} from '~/sections/user/sections/settings/sections/plans/utils/subscription/subscription'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {selectHasStaffBenefits} from '~/store/identity/selectors'
import {setModalError} from '~/store/notification'
import actions from '~/store/plan/actions'

interface Props {
    planName: string
    expires: DateTime
}

const PlanCancelModal: React.FunctionComponent<Props> = ({planName, expires}) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const dispatch = useAppDispatch()
    const hasStaffBenefits = useAppSelector(selectHasStaffBenefits)
    const {isDependent, preferredName, jurisdiction} = useAppSelector(s => ({
        isDependent: s.identity.user!.is_dependent,
        preferredName: s.identity.user!.preferred_name,
        jurisdiction: s.identity.user!.jurisdiction,
    }))

    const lastDay = useLastDayOfSubscription(expires)
    const owners = isDependent ? `${preferredName}’s` : 'Your'

    const onCancel = async () => {
        try {
            const error = await dispatch(actions.CancelCurrentPlan())
            if (error) {
                setModalError(error, 'We’re sorry, there’s been an error')
            } else {
                Toast(
                    `${owners} ${planName}${
                        hasStaffBenefits && ' (FREE for Sharesies staff)'
                    } has been cancelled—it’ll stay active until ${lastDay}`,
                )
            }
            setIsOpen(false)
        } catch (e) {
            setModalError(
                'Something caused it, maybe gremlins? Please try again or let us know if it’s still not working.',
                'We’re sorry, there’s been an error',
            )
        }
    }

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'center'}} className={spacing.spaceAbove16}>
                <ButtonAsLink noTextDecoration dataTestId="modal-cancel-plan" onClick={() => setIsOpen(true)}>
                    Cancel plan
                </ButtonAsLink>
            </div>
            <Modal
                dataTestId="modal--confirm-cancel-plan"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={isDependent ? `Cancel ${preferredName}’s plan` : `Cancel plan`}
                primaryButton={{label: 'Cancel plan', onClick: onCancel}}
                secondaryButton={{label: 'Back'}}
                content={
                    <>
                        <p className={spacing.spaceBelow16}>
                            {owners} {planName}
                            {hasStaffBenefits && ' (FREE for Sharesies staff)'} will remain active until {lastDay}.
                        </p>
                        <p className={spacing.spaceBelow16}>
                            {jurisdiction === 'au'
                                ? 'After that, the pay as you go pricing will apply to all orders'
                                : 'After that, the 1.9% transaction fee (and per order fee caps) will apply for orders'}
                            {!isDependent ? ' you place' : ''}.
                        </p>
                        {jurisdiction === 'au' && (
                            <p>
                                {isDependent ? `${preferredName} will` : 'You’ll'} also be charged a 2% linked bank
                                transfer fee (capped at $2 AUD per transaction) on any payments from a linked bank.
                            </p>
                        )}
                    </>
                }
            />
        </>
    )
}

export default PlanCancelModal
