import {Response} from '~/api/retail/types'

export const errorResponseFactory = (message: string, code: string = 'internal_error'): Response.Error => ({
    type: 'error',
    message,
    code,
})

/**
 * This class is used to throw an Error while indicating that the message is
 * ultimately suitable for display to the end-user. You MUST catch the error
 * (e.g. checking if `e instanceof UserErrorMessage` in your try/catch) and
 * handle it appropriately yourself (e.g. using NotificationProvider or a form
 * error) to actually display it to the user.
 */
export class UserSuitableError extends Error {
    constructor(m: string) {
        /*
        This looks slightly weird, if you want to change it make sure to read
        https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        before you do
        */
        super(m)
        Object.setPrototypeOf(this, UserSuitableError.prototype)
    }
}

/**
 * Like a regular error except we prevent reporting it to RollBar
 */
export class APINetworkError extends Error {
    constructor(message: string) {
        /*
        This looks slightly weird, if you want to change it make sure to read
        https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        before you do
        */
        super(message)
        Object.setPrototypeOf(this, APINetworkError.prototype)
    }
}

/**
 * Like a regular error except we prevent reporting it to RollBar
 */
export class NonRollbarError extends Error {
    constructor(message: string) {
        /*
        This looks slightly weird, if you want to change it make sure to read
        https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        before you do
        */
        super(message)
        Object.setPrototypeOf(this, NonRollbarError.prototype)
    }
}
