import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {IdentityVerificationStep} from '~/global/utils/identity-verification/identityVerification'
import {ErrorBox} from '~/global/widgets/form-controls'
import {Text, Checkbox, DateInput} from '~/global/widgets/form-controls/formik'
import styles from '~/sections/user/sections/sign-up/SignUp.scss'
import {useAppSelector} from '~/store/hooks'

export interface CommonIdFormProps {
    setStep(step: IdentityVerificationStep): void
    isDependent: boolean
    preferredName: string
    databases: string[]
    setIDDuplicateError: (value: boolean) => void
    isSecondaryId: boolean
}

export interface CommonHeaderProps<ValueType> {
    setFieldValue: (field: string, value: ValueType, shouldValidate?: boolean) => void
    identityType: string
    isSecondaryId: boolean
}

export interface FooterProps {
    isSecondaryId: boolean
    isDependent: boolean
    preferredName: string
    isSubmitting: boolean
    databases: string[]
    isValid: boolean
    jurisdiction: Model.User['jurisdiction']
    error?: string
}

export const consentMessage = 'You must give consent to proceed'

export const CommonHeader = (props: CommonHeaderProps<unknown>) => {
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)

    return (
        <React.Fragment>
            {!props.isSecondaryId && (
                <h2 className={styles.enterYourDetails}>
                    Enter {isDependent ? `${preferredName}’s` : 'the'} details exactly as they appear on{' '}
                    {isDependent ? `their` : 'your'} {props.identityType}
                </h2>
            )}
            <Text dataTestId="text-input--first-name" name="given_name" key="given_name" label="First name" />
            <Text dataTestId="text-input--middle-name" name="middle_name" key="middle_name" label="Middle name(s)" />
            <Text dataTestId="text-input--last-name" name="family_name" key="family_name" label="Family name" />
            <DateInput
                dataTestId="date-input--date-of-birth"
                name="date_of_birth"
                key="date_of_birth"
                label="Date of birth"
                handleDateChange={props.setFieldValue}
            />
        </React.Fragment>
    )
}

export const CommonFooter: React.FunctionComponent<FooterProps> = ({
    isSecondaryId,
    isDependent,
    preferredName,
    isSubmitting,
    isValid,
    jurisdiction,
    error,
}) => {
    const sharesiesRelationship = useAppSelector(s =>
        // Sharesies Limited, or Sharesies Australia Limited
        // This condition is true for AU users who began signup before the AFSL migration
        s.identity.user!.jurisdiction === 'nz' || !s.identity.user!.checks.afsl_migrated ? 'SL' : 'SAL',
    )

    React.useEffect(() => {
        if (error) {
            Analytics.event({
                category: 'Sign Up',
                action: 'Identity verification error',
                label: error,
            })
        }
    }, [error])
    const registerConsentGiven = (value: boolean) => {
        Analytics.event({
            category: 'Sign Up',
            action: `${jurisdiction === 'au' ? 'Australian' : 'New Zealand'} identity verification consent`,
            label: `${value ? 'Given' : 'Revoked'}`,
        })
    }
    const labelTitle = isDependent ? 'Verifying your identity and address' : `Verifying ${preferredName}’s identity`

    const identityVerificationHelpModalClick = () => {
        Analytics.event({
            category: 'Sign Up',
            action: 'Help modal',
            label: `${jurisdiction === 'au' ? 'Australian' : 'New Zealand'} identity verification`,
        })
    }

    const content = {
        nz: (
            <>
                Yes, you can check {isDependent ? `${preferredName}’s` : 'my'} details with government and third party
                databases, using third party providers. This info will be shared with Sharesies in New Zealand.{' '}
                <ModalLink
                    dataTestId="identity-verification"
                    label={labelTitle}
                    asIcon={true}
                    modalTitle={labelTitle}
                    primaryButton={{label: 'Got it'}}
                    onClick={identityVerificationHelpModalClick}
                >
                    {isDependent ? (
                        <>
                            <p>
                                Sharesies uses Cloudcheck—an electronic identity verification tool from our third party
                                provider, Verifi—to verify {preferredName}’s identity.
                            </p>
                            <p>
                                {preferredName}’s ID will be checked against databases provided by the following
                                organisations:
                            </p>
                            <ul>
                                <li>Te Tari Taiwhenua Department of Internal Affairs</li>
                                <li>Global AML registry</li>
                                <li>Waka Kotahi NZ Transport Agency</li>
                            </ul>
                        </>
                    ) : (
                        <>
                            <p>
                                All financial institutions need to do identity checks before customers can deposit
                                money.
                            </p>
                            <p>
                                Sharesies uses Cloudcheck—an electronic identity verification tool from our third party
                                provider, Verifi—to verify your identity and address.
                            </p>
                            <p>
                                To verify your identity, your ID will be checked against databases provided by the
                                following organisations:
                            </p>
                            <ul>
                                <li>Te Tari Taiwhenua Department of Internal Affairs</li>
                                <li>Global AML registry</li>
                                <li>Waka Kotahi NZ Transport Agency</li>
                            </ul>
                            <p>And your address will be checked against databases provided by these organisations:</p>
                            <ul>
                                <li>Illion</li>
                                <li>Equifax</li>
                                <li>Centrix</li>
                                <li>Waka Kotahi NZ Transport Agency</li>
                            </ul>
                        </>
                    )}
                </ModalLink>
            </>
        ),
        au: (
            <>
                You understand that by sharing this information we will share it with third parties to verify{' '}
                {isDependent ? `${preferredName}’s` : 'your'} identity in accordance with our{' '}
                <a href="https://sharesies.com.au/disclosures/collection-notice" target="_blank" rel="noreferrer">
                    Collection Notice
                </a>{' '}
                and{' '}
                <a href="https://sharesies.com.au/disclosures/privacy-statement" target="_blank" rel="noreferrer">
                    Privacy Statement
                </a>
                .
            </>
        ),
    }

    const contentToShow = sharesiesRelationship === 'SAL' ? 'au' : 'nz'

    return (
        <React.Fragment>
            <Checkbox
                dataTestId={`checkbox--consent-${isSecondaryId ? 'secondary' : 'primary'}`}
                key="checkbox"
                name="consent"
                onClick={e => registerConsentGiven((e.target as any).checked)}
                label={
                    <div className={styles.databaseList}>
                        <p className={cn(spacing.spaceBelow24)}>{content[contentToShow]}</p>
                    </div>
                }
            />
            {error && <ErrorBox key="error" message={error} />}
            <Button
                isSubmit
                pageButton
                dataTestId="button--next"
                key="submit"
                label={isSecondaryId ? 'Confirm' : 'Next'}
                disabled={!isValid}
                processing={isSubmitting}
            />
        </React.Fragment>
    )
}
