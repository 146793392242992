/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface TotalReturnDetail
 */
export interface TotalReturnDetail {
    /**
     *
     * @type {number}
     * @memberof TotalReturnDetail
     */
    dividends: number
    /**
     *
     * @type {number}
     * @memberof TotalReturnDetail
     */
    managed_fund_transaction_fees: number
    /**
     *
     * @type {number}
     * @memberof TotalReturnDetail
     */
    realised_capital_gains: number
    /**
     *
     * @type {number}
     * @memberof TotalReturnDetail
     */
    transaction_fees: number
    /**
     *
     * @type {number}
     * @memberof TotalReturnDetail
     */
    adr_fees: number
    /**
     *
     * @type {number}
     * @memberof TotalReturnDetail
     */
    unrealised_adr_fees: number
    /**
     *
     * @type {number}
     * @memberof TotalReturnDetail
     */
    unrealised_capital_gains: number
    /**
     *
     * @type {number}
     * @memberof TotalReturnDetail
     */
    unrealised_dividends: number
    /**
     *
     * @type {number}
     * @memberof TotalReturnDetail
     */
    unrealised_tax_paid: number
}

export function TotalReturnDetailFromJSON(json: any): TotalReturnDetail {
    return TotalReturnDetailFromJSONTyped(json, false)
}

export function TotalReturnDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): TotalReturnDetail {
    if (json === undefined || json === null) {
        return json
    }
    return {
        dividends: json['dividends'],
        managed_fund_transaction_fees: json['managed_fund_transaction_fees'],
        realised_capital_gains: json['realised_capital_gains'],
        transaction_fees: json['transaction_fees'],
        adr_fees: json['adr_fees'],
        unrealised_adr_fees: json['unrealised_adr_fees'],
        unrealised_capital_gains: json['unrealised_capital_gains'],
        unrealised_dividends: json['unrealised_dividends'],
        unrealised_tax_paid: json['unrealised_tax_paid'],
    }
}

export function TotalReturnDetailToJSON(value?: TotalReturnDetail | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        dividends: value.dividends,
        managed_fund_transaction_fees: value.managed_fund_transaction_fees,
        realised_capital_gains: value.realised_capital_gains,
        transaction_fees: value.transaction_fees,
        adr_fees: value.adr_fees,
        unrealised_adr_fees: value.unrealised_adr_fees,
        unrealised_capital_gains: value.unrealised_capital_gains,
        unrealised_dividends: value.unrealised_dividends,
        unrealised_tax_paid: value.unrealised_tax_paid,
    }
}
