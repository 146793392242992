import {atom} from 'jotai'
import {useRetailGet} from '~/api/query/retail'
import {StagedTransferOrder} from '~/store/save/types'

export const stagedTransferAtom = atom<StagedTransferOrder | undefined>(undefined)

export const useRecurringTransfers = (portfolioId: string) => {
    const {data} = useRetailGet({
        path: 'save/v2/:portfolio_id/get-recurring-transfers',
        pathParams: {portfolio_id: portfolioId},
    })

    return data.recurring_transfers
}
