import cn from 'classnames'
import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import {mod} from 'react-swipeable-views-core'
import {virtualize} from 'react-swipeable-views-utils'
import {investingActivityTitleAndIcon} from '~/global/utils/investing-activity/investingActivity'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {useInstruments} from '~/global/utils/use-instruments/useInstruments'
import {ChevronLeft, ChevronRight, DotBig} from '~/global/widgets/OLD_icons'
import {ExchangeHours} from '~/global/widgets/exchange-hours/ExchangeHours'
import InstrumentActivityRecord from '~/global/widgets/instrument-activity/Record'
import recordStyles from '~/global/widgets/instrument-activity/Record.scss'
import {Loading} from '~/global/widgets/loading'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import {Order} from '~/store/accounting/types'
import {essHasActiveBlackoutPeriodForInstrument} from '~/store/employeeShareScheme/selectors'
import {useAppSelector} from '~/store/hooks'
import styles from './OrderCarousel.scss'

const VirtualizeSwipeableViews = virtualize(SwipeableViews)

interface OrderCardPageProps {
    orders: Order[]
    carousel?: boolean
}

/**
 * OrderCardPage handles rendering one or more order cards. This renders the title & exchange UI, as well as the card
 * itself. OrderCardPage will load the instruments and other metadata for you - just pass in the Orders
 *
 * By default, this won't use a carousel unless there's multiple orders. `carousel` can be set to force using a carousel
 * even if there's only one order
 */
export const OrderCarousel: React.FunctionComponent<OrderCardPageProps> = ({orders, carousel}) => {
    const useCarousel = orders.length > 1 || carousel
    const [currentOrder, setCurrentOrder] = React.useState<number>(0)

    // Store data
    const [instruments, allInstrumentsLoaded] = useInstruments(orders.map(o => o.fund_id))

    const order = orders[mod(currentOrder, orders.length)]
    const instrument = instruments[order.fund_id]

    const essHasActiveBlackoutPeriod = useAppSelector(s =>
        instrument ? essHasActiveBlackoutPeriodForInstrument(s, instrument.id) : false,
    )

    if (!allInstrumentsLoaded || !instrument) {
        return <Loading isPineapple />
    }

    const titleAndIcon = investingActivityTitleAndIcon(order, instrument)

    return (
        <Page withoutDefaultPadding>
            <div className={recordStyles.header}>
                <PageBack />
                {tradingType(instrument) !== 'managed' && !essHasActiveBlackoutPeriod && (
                    <ExchangeHours instrument={instrument} />
                )}
            </div>
            <div className={recordStyles.viewContainer}>
                <div className={recordStyles.titleBlock}>
                    <h2>{titleAndIcon.title}</h2>
                    {orders.length > 1 && <span>{`${mod(currentOrder, orders.length) + 1} of ${orders.length}`}</span>}
                </div>
                {useCarousel ? (
                    <>
                        <VirtualizeSwipeableViews
                            resistance
                            index={currentOrder}
                            onChangeIndex={(index: number) => {
                                setCurrentOrder(index)
                            }}
                            className={styles.swipeableArea}
                            slideRenderer={({key}) => (
                                <InstrumentActivityRecord
                                    key={key}
                                    data-testid={`recent-slide-${key}`}
                                    order={order}
                                    instrument={instrument}
                                />
                            )}
                            disabled={orders.length < 2}
                        />

                        {orders.length > 1 ? (
                            <div className={styles.controls}>
                                <div className={styles.controlLeft}>
                                    <ChevronLeft onClick={() => setCurrentOrder(currentOrder - 1)} />
                                </div>
                                <div className={styles.controlDots}>
                                    {orders.map((_, index) => (
                                        <DotBig
                                            key={index}
                                            className={cn({
                                                [styles.selected]: mod(currentOrder, orders.length) === index,
                                            })}
                                            onClick={() => setCurrentOrder(index)}
                                        />
                                    ))}
                                </div>
                                <div className={styles.controlRight}>
                                    <ChevronRight onClick={() => setCurrentOrder(currentOrder + 1)} />
                                </div>
                            </div>
                        ) : null}
                    </>
                ) : (
                    <InstrumentActivityRecord order={order} instrument={instrument} />
                )}
            </div>
        </Page>
    )
}
