import {QueryKey, UseInfiniteQueryResult, useInfiniteQuery} from '@tanstack/react-query'
import {useRetailGet} from '~/api/query/retail'
import * as api from '~/api/retail'
import {Response, Request, Model} from '~/api/retail/types'

const ONE_MINUTE_IN_MS = 60 * 1000
const TWO_MINUTES_IN_MS = 2 * 60 * 1000

const DEFAULT_CACHE_STALE_OPTION = {
    cacheTime: TWO_MINUTES_IN_MS,
    staleTime: ONE_MINUTE_IN_MS,
}

export const useHoldingSummary = (portfolioId: string): Response.FonterraHoldingSummary => {
    const {data} = useRetailGet({
        path: 'fonterra/portfolios/:portfolio_id/holding/summary',
        pathParams: {portfolio_id: portfolioId},
    })
    if (data.type !== 'fonterra_holding_summary') {
        throw new Error('no holding summary for portfolio')
    }
    return data
}

export const useHoldingDetail = (portfolioId: string): Response.FonterraHoldingDetail => {
    const {data} = useRetailGet({
        path: 'fonterra/portfolios/:portfolio_id/holding/detail',
        pathParams: {portfolio_id: portfolioId},
    })
    if (data.type !== 'fonterra_holding_detail') {
        throw new Error('no holding details for portfolio')
    }
    return data
}

export const useHoldingDetailForSymbol = (portfolioId: string, symbol: string) => {
    const data = useHoldingDetail(portfolioId)
    const detail = data.instruments.find(x => x.symbol === symbol)
    if (!detail) {
        throw new Error('unexpected instrument symbol')
    }

    return detail
}

export const useInvestingActivity = (portfolioId: string, key: string): Model.InvestmentActivity => {
    const {data} = useRetailGet({
        path: 'investment-activity/:portfolio_id/item',
        payload: {key},
        options: DEFAULT_CACHE_STALE_OPTION,
        pathParams: {portfolio_id: portfolioId},
    })

    return data.item
}

export const usePortfolioOrders = (
    portfolioId: string,
    fund_id: string,
    include_order_state?: Request.PortfolioOrders['state_filter'],
): Response.PortfolioOrders => {
    const {data} = useRetailGet({
        path: 'fonterra/portfolios/:portfolio_id/orders',
        payload: {
            fund_id,
            state_filter: include_order_state,
        },
        options: DEFAULT_CACHE_STALE_OPTION,
        pathParams: {portfolio_id: portfolioId},
    })

    return data
}

export interface PortfolioOrdersInfiniteQueryResponse {
    orders: Response.PortfolioOrders['orders']
    has_more: boolean
    type: string
}

export const usePortfolioOrdersInfiniteQuery = (
    portfolioId: string,
    {limit, fund_id, state_filter, side_filter}: Request.PortfolioOrders,
): UseInfiniteQueryResult<PortfolioOrdersInfiniteQueryResponse> => {
    const path = 'fonterra/portfolios/:portfolio_id/orders'
    let queryKey: QueryKey = [path, limit, fund_id, state_filter, side_filter]
    queryKey = queryKey.filter(key => key !== undefined)

    return useInfiniteQuery({
        queryKey,
        ...DEFAULT_CACHE_STALE_OPTION,
        queryFn: async ({pageParam}) => {
            const response = await api.getWithPathParams(
                path,
                {portfolio_id: portfolioId},
                {limit, fund_id, state_filter, side_filter, before: pageParam},
            )

            return response as PortfolioOrdersInfiniteQueryResponse
        },
        getNextPageParam: (currentPage: PortfolioOrdersInfiniteQueryResponse) => {
            const hasNextPage: boolean = currentPage.has_more

            if (!hasNextPage) {
                return undefined
            }

            return currentPage.orders[currentPage.orders.length - 1].date
        },
    })
}

export const useSignUpInitialDetail = (signupId: string): Response.FonterraSignUpInitialDetail => {
    // let the error boundary handle Response.Error by not adding it to the return types
    const {data} = useRetailGet({
        path: 'fonterra/signup/initial-detail',
        payload: {signup_id: signupId},
        options: DEFAULT_CACHE_STALE_OPTION,
    })
    return data
}

export const useSignUpParticipants = (): Response.IdentityParticipants => {
    // let the error boundary handle Response.Error by not adding it to the return types
    const {data} = useRetailGet({
        path: 'identity/participants',
        options: DEFAULT_CACHE_STALE_OPTION,
    })

    return data
}

export const usePortfolioSettings = (portfolioId: string): Response.FonterraPortfolioSettings => {
    const {data} = useRetailGet({
        path: 'fonterra/portfolios/:portfolio_id/settings',
        pathParams: {portfolio_id: portfolioId},
    })

    return data
}
