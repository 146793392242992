import {isIOSWrapperApp} from '~/global/utils/is-wrapper-app/isWrapperApp'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'

const ANDROID_USER_AGENT_REGEX = /nz\.sharesies\.app(\.debug|\.uat)?\/(\d{2})/i

/**
 * Check if we're inside the Android native app and if it supports the sign up complete event
 * Any Android apps below version 14 will not support this
 *
 * @param {string} userAgentValue - navigator.userAgent
 * @returns {boolean} if userAgent is android and supports sign up complete
 */
export function androidSupportsSignUpComplete(userAgentValue: string): boolean {
    const match = userAgentValue.match(ANDROID_USER_AGENT_REGEX)

    if (!match || match.length === 0) {
        return false
    }

    const [, , androidVersion] = match

    // If androidVersion is NaN then the comparison below will return false
    const version = parseFloat(androidVersion)

    return version >= 14
}

/**
 * Let the Native app know that someone has completed Sign up and are heading to the Explore page
 */
export const sendMobileExploreRoute = (): void => {
    if (isIOSWrapperApp() || androidSupportsSignUpComplete(navigator.userAgent)) {
        sendWrapperAppMessage({type: 'signUpComplete', route: '/explore'})
    }
}
