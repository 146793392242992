import {Model} from '~/api/retail/types'

// Find whether wallet balance is an estimate or exact figure
export const isWalletBalanceEstimated = (
    walletBalances: Model.User['wallet_balances'],
    homeCurrency: string,
): boolean => {
    return Object.keys(walletBalances).some(currency => {
        const notHomeCurrency = currency !== homeCurrency
        const parsedWalletBalance = parseFloat(walletBalances[currency])
        // use 'is not equal to zero' to include negative balances
        const hasNonZerowalletBalance = !isNaN(parsedWalletBalance) && parsedWalletBalance !== 0
        return notHomeCurrency && hasNonZerowalletBalance
    })
}
