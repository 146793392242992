import Rollbar from 'rollbar'
import uuid from 'uuid/v4'
import config from '~/configForEnv'
import {NonRollbarError} from '~/global/utils/error-handling/errorHandling'
import {getUatEnvironmentNameFromUrl} from '~/global/utils/get-uat-environment-name-from-url/getUatEnvironmentNameFromUrl'
import {User} from '~/store/identity/types'

export function initRollbar() {
    if (!config.rollbarToken) {
        return
    }

    // we put rollbar on window so it can also be used by the error handler when the main bundle fails, in 'src/onerror.ts'
    // note we *don't* do window.rollbar becasue that clashes with rollbar's internal use
    window.sharesiesRollbar = Rollbar.init({
        accessToken: config.rollbarToken,
        captureUncaught: true,
        captureUnhandledRejections: true,
        enabled: true,
        verbose: true,
        payload: {
            // The `sessionId` below is refreshed on each page load and is useful for when you are checking Rollbar
            // errors and want to know whether errors for an individual person occurred within the same session of
            // the app (where "session" is synonymous with a single long lived page interaction) or whether errors
            // continued to occur via multiple refreshes (where each refresh has a new sessionId)
            sessionId: uuid(),
            environment: config.rollbarEnvironment,
            branch: config.rollbarEnvironment === 'uat' ? getUatEnvironmentNameFromUrl(location.host) : undefined,
            client: {
                javascript: {
                    source_map_enabled: true,
                    code_version: SHARESIES_BUILD_INFORMATION.gitHash,
                    version_number: SHARESIES_BUILD_INFORMATION.version,
                    guess_uncaught_frames: true,
                },
            },
        },
        checkIgnore: (_isUncaught, args) => {
            if (args instanceof Array && args.find(arg => arg instanceof NonRollbarError)) {
                // If any of the args are an instance of a SilentNetworkError
                // we'll prevent this error being sent to Rollbar
                return true
            }

            // For now we'll ignore no messages
            return false
        },
    })
}

export function setUser(user: User): void {
    if (window.sharesiesRollbar) {
        window.sharesiesRollbar.configure({payload: {person: {id: user.id}}})
    }
}

export function clearUser(): void {
    if (window.sharesiesRollbar) {
        window.sharesiesRollbar.configure({payload: {person: undefined}})
    }
}

export function sendError(message: string | Error, extra?: object): void {
    if (window.sharesiesRollbar) {
        window.sharesiesRollbar.error(message, ...(extra ? [extra] : []))
    }
}

export function sendDebug(message: string, extra?: object): void {
    if (window.sharesiesRollbar) {
        window.sharesiesRollbar.debug(message, ...(extra ? [extra] : []))
    }
}

export function checkIfInitialised() {
    if (window.sharesiesRollbar) {
        return true
    }
    return false
}
