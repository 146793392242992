// Fix for 100vh scroll issue [DS-278]
// sets a global style variable to the document root
// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

import {isIOSWrapperApp} from '~/global/utils/is-wrapper-app/isWrapperApp'

export const setGlobalStyleVHVariable = () => {
    if (isIOSWrapperApp()) {
        return
    }
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
}
