import cn from 'classnames'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {accessibility, spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useNavigate} from '~/migrate-react-router'
import InvestingActivityCount from '~/sections/invest/widgets/investing-activity-count/InvestingActivityCount'
import styles from '~/sections/invest/widgets/recent-investing-activity/RecentInvestingActivity.scss'
import {Order} from '~/store/accounting/types'

interface PendingInvestingActivityCardProps {
    orders: Order[]
}

export const PendingInvestingActivityCard: React.FunctionComponent<PendingInvestingActivityCardProps> = ({orders}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    return (
        <button
            className={cn(
                styles.recentOrders,
                styles.portfolioRecentOrders,
                accessibility.button,
                spacing.spaceBelow20,
            )}
            data-testid="button--view-pending-investing-activity"
            aria-label="View pending investing activity"
            type="button"
            onClick={() => {
                Analytics.event({
                    category: 'Portfolio',
                    action: 'Clicked pending investing activity',
                    label: 'Pending investing activity',
                })
                navigate(profileUrl('invest/pending-investing-activity'))
            }}
        >
            <div className={cn(styles.recentOrdersContent, spacing.spaceAbove16, spacing.spaceBelow16)}>
                <InvestingActivityCount orders={orders} />
                <p className={styles.recentOrdersView}>View</p>
            </div>
        </button>
    )
}
