import React from 'react'
import {aggregateOrders} from '~/global/utils/aggregate-orders/aggregateOrders'
import JoinedInSentence from '~/global/widgets/joined-in-sentence/JoinedInSentence'
import {Order} from '~/store/accounting/types'

const InvestingActivityCount: React.FunctionComponent<{orders: Order[]}> = ({orders}) => (
    <p>
        <JoinedInSentence nodes={aggregateOrders(orders)} />
    </p>
)

export default InvestingActivityCount
