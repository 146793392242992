import React from 'react'
import capitalise from '~/global/utils/capitalise-string/capitaliseString'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import BuyOrSellCurrentPriceWithModal from '~/global/widgets/buy-or-sell-current-price-with-modal/BuyOrSellCurrentPriceWithModal'
import MarketDepthLink from '~/global/widgets/nzx-market-depth-link/MarketDepthLink'
import {getLabelByBuyOrderType} from '~/sections/invest/sections/order-flow/utils/get-label-by-buy-order-type/getLabelByBuyOrderType'
import {getLabelBySellOrderType} from '~/sections/invest/sections/order-flow/utils/get-label-by-sell-order-type/getLabelBySellOrderType'
import {Instrument} from '~/store/instrument/types'
import {StagedBuyOrder, StagedSellOrder} from '~/store/order/types'
import styles from './OrderFormHeader.scss'

interface OrderFormHeaderProps {
    instrument: Instrument
    buyOrderType?: StagedBuyOrder['orderType']
    sellOrderType?: StagedSellOrder['orderType']
    buyOrSell: 'buy' | 'sell'
}

export const OrderFormHeader: React.FunctionComponent<OrderFormHeaderProps> = ({
    instrument,
    buyOrderType,
    sellOrderType,
    buyOrSell,
}) => {
    const buyOrderTypeLabel = buyOrderType ? getLabelByBuyOrderType(buyOrderType, instrument) : undefined
    const sellOrderTypeLabel = sellOrderType ? getLabelBySellOrderType(sellOrderType, instrument) : undefined

    return (
        <div className={styles.orderFormHeader}>
            <div className={styles.headerLinks}>
                {buyOrSell === 'buy' && buyOrderTypeLabel && (
                    <span data-testid="order-type">{capitalise(buyOrderTypeLabel)}</span>
                )}
                {buyOrSell === 'sell' && sellOrderTypeLabel && (
                    <span data-testid="order-type">{capitalise(sellOrderTypeLabel)}</span>
                )}
                <MarketDepthLink
                    instrumentSlug={instrument.urlSlug}
                    tradingType={tradingType(instrument)}
                    type="market-or-limit-selector"
                />
            </div>
            <h1>{instrument.name}</h1>
            <BuyOrSellCurrentPriceWithModal instrument={instrument} orderType={buyOrderType || sellOrderType} />
        </div>
    )
}
