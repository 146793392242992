import {semanticColour} from '@design-system/colour-tokens'
import {ChevronRight} from '@design-system/icon'
import {useColourMode} from '@design-system/use-colour-mode'
import cn from 'classnames'
import React from 'react'
import {ListingResponseDto} from '~/api/distill'
import {accessibility, spacing} from '~/global/scss/helpers'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import DetailModal from '~/global/widgets/detail-modals/DetailModal'
import {DollarValue, PercentValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import labelledValuesStyles from '~/sections/invest/sections/view-instrument/LabelledValues.scss'
import {InstrumentReturn} from '~/store/portfolio/types'
import AmountPutInDetail from './AmountPutInDetail'
import SimpleReturnDetail from './SimpleReturnDetail'
import TotalReturnDetail from './TotalReturnDetail'

type DetailTopic = null | 'total-return' | 'contribution' | 'simple-return' | 'shares' | 'amount-put-in'

const InvestmentSummary: React.FunctionComponent<InvestmentSummaryProps> = ({
    instrumentReturns,
    currency,
    instrument,
}) => {
    const [detailTopic, showDetailModal] = React.useState<DetailTopic>(null)
    const setDetailModalClosed = () => {
        showDetailModal(null)
    }
    const handleModalClick = (detailType: DetailTopic) => {
        showDetailModal(detailType)
    }
    const colourMode = useColourMode()

    const NoData = () => <p className={labelledValuesStyles.noData}>No data</p>

    const valueButton = (label: string, value: number | undefined, detailType: DetailTopic, isDollar: boolean) => (
        <div>
            <p data-testid={`label--${detailType}`} className={labelledValuesStyles.label}>
                {label}
            </p>
            <button
                className={cn(labelledValuesStyles.value, accessibility.button)}
                onClick={() => (value! > 0 ? handleModalClick(detailType) : false)}
                role="link"
                type="button"
                data-testid={`button--${detailType}`}
            >
                {value === undefined ? (
                    <NoData />
                ) : (
                    <>{isDollar ? <DollarValue value={value} /> : <PercentValue value={value} />}</>
                )}
                <ChevronRight colour={semanticColour('LinkDefault', colourMode)} />
            </button>
        </div>
    )

    const detailModal = (detailType: DetailTopic, DetailComponent: React.ElementType) => (
        <DetailModal isOpen={detailTopic === detailType} closeHandler={setDetailModalClosed}>
            <DetailComponent currentReturn={instrumentReturns} instrument={instrument} currency={currency} />
        </DetailModal>
    )
    return (
        <section className={labelledValuesStyles.container}>
            <div className={spacing.spaceAbove40}>
                <p className={labelledValuesStyles.label}>Investment value</p>
                <div className={cn(labelledValuesStyles.value, labelledValuesStyles.notClickableValue)}>
                    <DollarValue value={instrumentReturns?.investment_value || 0} />
                </div>
            </div>
            <div />
            {valueButton('Total return', instrumentReturns?.total_return, 'total-return', true)}
            {valueButton('Simple return', instrumentReturns?.simple_return, 'simple-return', false)}
            {valueButton('Amount put in', instrumentReturns?.cost_basis, 'amount-put-in', true)}

            <div>
                <p className={labelledValuesStyles.label}>Your {shareLabel({instrument, isPlural: true})}</p>
                <div className={cn(labelledValuesStyles.value, labelledValuesStyles.notClickableValue)}>
                    <ShareValue value={instrumentReturns?.shares_owned || 0} showFullValue />
                </div>
            </div>

            {detailModal('total-return', TotalReturnDetail)}
            {detailModal('amount-put-in', AmountPutInDetail)}
            {detailModal('simple-return', SimpleReturnDetail)}
        </section>
    )
}

interface InvestmentSummaryProps {
    instrument: ListingResponseDto
    instrumentReturns: InstrumentReturn
    currency: string
}

export default InvestmentSummary
