import React from 'react'
import {isNzxCsn} from '~/global/utils/share-transfers/shareTransfers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {useNavigate} from '~/migrate-react-router'
import {AddCSNForm} from '~/sections/invest/sections/transfer-shares/sections/nzx-transfer-shares/AddCSNForm'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import {PIR} from '~/store/identity/types'
import transferActions from '~/store/transfer/actions'
import {TransferOrderRegistryDetails} from '~/store/transfer/types'

const AddCSN: React.FunctionComponent = () => {
    const notificationContext = React.useContext(NotificationContext)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const {stagedTransferOrder} = useAppSelector(({transfer}) => transfer)

    const jurisdiction = useAppSelector(({identity}) => identity.user!.jurisdiction)

    const showIrdField = useAppSelector(
        ({identity}) => identity.user!.jurisdiction === 'nz' && !identity.user!.ird_number,
    )
    const showTfnField = useAppSelector(
        ({identity}) => identity.user!.jurisdiction === 'au' && !identity.user!.tfn_number,
    )

    if (!stagedTransferOrder || !stagedTransferOrder.direction) {
        navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})
        return <></>
    }

    const setRegistryDetails = (
        csn: TransferOrderRegistryDetails['referenceId'],
        fin: string,
        jointHolderOwnerIds?: string[],
    ) =>
        dispatch(
            transferActions.CreateRegistryDetails({
                referenceId: csn,
                referenceType: 'CSN',
                fasterIdNo: fin,
                jointHolderOwnerIds,
            }),
        )

    const setIrdNumber = (irdNumber: string, pir: PIR) =>
        dispatch(identityActions.UpdateFinancialDetails(irdNumber, pir))
    const setTfnNumber = (tfnNumber: string) => dispatch(identityActions.UpdateAUFinancialDetails(tfnNumber))

    const handleSuccess = async (csn: string) => {
        Toast('Investor details added')
        // If this is the NZX we can continue with the adding flow

        if (stagedTransferOrder?.exchange === 'NZX') {
            if (stagedTransferOrder?.direction === 'in' && isNzxCsn(csn) && jurisdiction === 'nz') {
                // If the reference provided looks like a CSN offer to Transfer All in
                // We don't show the transfer all option for non-NZ jurisdiction due to tax reasons
                navigate(profileUrl('invest/portfolio-transfer-shares/nzx/transfer-all'))
            } else {
                // If the reference looks like a Holder Number, outbound or not NZ jurisdiction, skip Transfer All
                navigate(profileUrl('invest/portfolio-transfer-shares/:exchange/select', {exchange: 'nzx'}))
            }
        } else {
            // Otherwise we exit this flow, and mark transfers state for a re-fetch
            dispatch(transferActions.ResetStagedTransferOrder())
            dispatch(transferActions.InvalidateTransferState())
            navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})
        }
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--add-investor-details" leftButton="back" />
            <AddCSNForm
                setRegistryDetails={setRegistryDetails}
                notificationContext={notificationContext}
                showIrdField={showIrdField}
                showTfnField={showTfnField}
                setIrdNumber={setIrdNumber}
                setTfnNumber={setTfnNumber}
                handleSuccess={handleSuccess}
                maybeStagedTransferOrderDirection={stagedTransferOrder.direction}
            />
        </>
    )
}

export default AddCSN
