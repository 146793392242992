import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import styles from './AUTermsAndConditionsV4.scss'

/* These are modular commonly used AU terms and conditions.
/  While we're undergoing an AFSL grace period, there's lots
/  of variations in different order (see usages).
/ This is likely to get cleaned up after the AFSL grace period ends on 1st April 2024.
*/

export const AU_TC_VERSION = 4

const DocumentGuideModalContent: React.FC = () => (
    <>
        <p>
            <b>IDPS Guide</b>—Explains how the Sharesies platform works, including the features, benefits and key risks
            of using Sharesies to invest. This helps you understand the services we offer, and decide whether they’re
            right for you.
        </p>
        <p>
            <b>Financial Services Guide (FSG)</b>—Provides you with information about Sharesies Australia Limited and
            what services and products we offer. This helps you decide whether to become a customer of ours.
        </p>
        <p>
            <b>Target Market Determination (TMD)</b>—Sets out who is most likely to be suited to Sharesies based on
            their likely needs and objectives, and the product’s key attributes. This helps you to understand whether
            our product has been designed for people like you (the target market) before you make a decision to join.
        </p>
        <p>
            <b>Privacy Statement and Collection Notice</b>—Outlines how and why we collect, use, and handle your
            personal information, and the types of organisations we share your information with (including those located
            overseas). The Privacy Statement also explains how you can request access to, or a correction of, the
            information we hold about you, as well as how you can make a complaint about how we handle your personal
            information.
        </p>
    </>
)

const PrivacyModalContent: React.FC = () => (
    <>
        <p>
            You acknowledge that in using Sharesies we collect and use your personal information to deliver our services
            to you. This includes sharing with third-party providers that may be located overseas.
        </p>
        <p>If you don’t provide your consent, you won’t be able to use Sharesies.</p>
        <p>Our Privacy Statement explains how we collect and handle the information you provide.</p>
        <a
            target="_blank"
            rel="noreferrer"
            href="https://sharesies.com.au/disclosures/collection-notice"
            className={cn([styles.privacyLinks, spacing.spaceBelow8])}
        >
            Read Collection Notice
        </a>
        <a
            target="_blank"
            rel="noreferrer"
            href="https://sharesies.com.au/disclosures/privacy-statement"
            className={styles.privacyLinks}
        >
            Read Privacy Statement
        </a>
    </>
)

export const AUTermsDocumentLinks = () => (
    <>
        <ul className={styles.documentsList}>
            <li>
                <a href="https://sharesies.com.au/disclosures/idps-guide.pdf" target="_blank" rel="noreferrer">
                    Sharesies IDPS Guide
                </a>
            </li>
            <li>
                <a
                    href="https://sharesies.com.au/disclosures/supplementary-idps-guide-july-2024.pdf"
                    target="_blank"
                    rel="noreferrer"
                >
                    Supplementary IDPS Guide
                </a>
            </li>
            <li>
                <a
                    href="https://sharesies.com.au/disclosures/financial-services-guide.pdf"
                    target="_blank"
                    rel="noreferrer"
                >
                    Sharesies Australia Limited Financial Services Guide (FSG)
                </a>
            </li>
            <li>
                <a
                    href="https://sharesies.com.au/disclosures/target-market-determination.pdf"
                    target="_blank"
                    rel="noreferrer"
                >
                    Target Market Determination (TMD) for the Sharesies platform
                </a>
            </li>
            <li>
                <a href="https://sharesies.com.au/disclosures/privacy-statement" target="_blank" rel="noreferrer">
                    Sharesies Australia Privacy Statement
                </a>
            </li>
            <li>
                <a href="https://sharesies.com.au/disclosures/collection-notice" target="_blank" rel="noreferrer">
                    Sharesies Australia Collection Notice
                </a>
            </li>
        </ul>
        <p className={spacing.spaceBelow16}>
            Not sure what these are?{' '}
            <ModalLink
                label="Here’s a quick guide"
                modalTitle="What the doc?"
                dataTestId="modal-link--tc-document-guide"
            >
                <DocumentGuideModalContent />
            </ModalLink>
            .
        </p>
    </>
)

export const AUTermsCheckboxPreamble = () => (
    <>
        <p className={spacing.spaceBelow16}>
            To continue, you agree that you understand the differences between investing via Sharesies and investing
            directly (through a registry, for example). These differences are described on section 2 of the IDPS Guide.
        </p>
        <p className={spacing.spaceBelow16}>
            You also understand that Sharesies Australia does not offer personal advice, and if you need help deciding
            what to invest in or whether to continue using Sharesies, you should speak with a qualified financial
            advisor.{' '}
        </p>
        <p className={spacing.spaceBelow16}>
            You understand we{' '}
            <ModalLink label="collect and use" modalTitle="Your privacy matters" dataTestId="modal--au-tc-privacy">
                <PrivacyModalContent />
            </ModalLink>{' '}
            your personal information in accordance with our Privacy Statement and our Collection Notice.
        </p>
    </>
)
