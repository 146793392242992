import {SuccessFilled} from '@design-system/icon'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {Link} from 'react-router-dom'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import useDividendReinvestmentPlan from '~/global/utils/use-dividend-reinvestment-plan/useDividendReinvestmentPlan'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Image} from '~/global/widgets/image/Image'
import cardIconDark from '~/sections/invest/sections/dividend-reinvestment-plans/assets/images/card-icon-dark.svg'
import cardIconLight from '~/sections/invest/sections/dividend-reinvestment-plans/assets/images/card-icon-light.svg'
import {Instrument} from '~/store/instrument/types'
import styles from './DividendReinvestmentTile.scss'

interface DividendReinvestmentTileProps {
    className?: string
    instrument: Instrument
}

const DividendReinvestmentTile: React.FunctionComponent<DividendReinvestmentTileProps> = ({className, instrument}) => {
    const profileUrl = useProfileUrl()
    const dividendReinvestmentURL = profileUrl('invest/dividend-reinvestment-manage/:instrumentSlug', {
        instrumentSlug: instrument.urlSlug,
    })
    const [drpPlan] = useDividendReinvestmentPlan(instrument)
    const nextExDate = drpPlan?.upcoming_exdividend_date

    return (
        <div className={cn(className, styles.cardWrapper)}>
            <div className={cn(styles.row, styles.top)}>
                <div className={styles.image}>
                    <Image src={cardIconLight} darkSrc={cardIconDark} />
                </div>
                <div className={styles.col}>
                    <span className={styles.title}>Dividend reinvestment plan</span>
                    <Link className={cn(styles.subTitle)} to={dividendReinvestmentURL}>
                        View
                    </Link>
                </div>
            </div>

            <div className={cn(styles.row, styles.bottom)}>
                {nextExDate ? (
                    <span>
                        {nextExDate
                            ? `Upcoming dividend: ${DateTime.fromISO(nextExDate).toFormat(dateFormatNoTime)}`
                            : ''}
                    </span>
                ) : (
                    <span />
                )}

                <div className={styles.status}>
                    On{' '}
                    <div className={styles.successIcon}>
                        <SuccessFilled size={16} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DividendReinvestmentTile
