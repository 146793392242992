import {atom} from 'jotai'

export type InstrumentSymbol = 'FCG' | 'FSF'

export interface StagedTransfer {
    initiatingPortfolioId: string
    instrumentSymbol?: InstrumentSymbol
    fromPortfolioId?: string
    toPortfolioId?: string
    units?: Number
}

export const stagedTransferAtom = atom<StagedTransfer | undefined>(undefined)
