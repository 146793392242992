/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface ExchangeCalendarDto
 */
export interface ExchangeCalendarDto {
    /**
     *
     * @type {Date}
     * @memberof ExchangeCalendarDto
     */
    tradeDate: DateTime
}

export function ExchangeCalendarDtoFromJSON(json: any): ExchangeCalendarDto {
    return ExchangeCalendarDtoFromJSONTyped(json, false)
}

export function ExchangeCalendarDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExchangeCalendarDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        tradeDate: DateTime.fromISO(json['tradeDate'], {setZone: true}),
    }
}

export function ExchangeCalendarDtoToJSON(value?: ExchangeCalendarDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        tradeDate: value.tradeDate.toFormat('yyyy-MM-dd'),
    }
}
