import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {Period} from '~/global/utils/time-period/timePeriod'
import ConditionalWrapper from '~/global/widgets/conditional-wrapper/ConditionalWrapper'
import ContentToggle from '~/global/widgets/content-toggle/ContentToggle'
import styles from '~/sections/invest/sections/view-instrument/ViewInstrument.scss'
import EmployeeShareSchemeOverview from '~/sections/invest/sections/view-instrument/sections/employee-share-scheme'
import SharesAreOnTheirWay from '~/sections/invest/sections/view-instrument/sections/employee-share-scheme/widgets/shares-are-on-their-way/SharesAreOnTheirWay'
import InvestingHistory from '~/sections/invest/sections/view-instrument/sections/your-investment/widgets/investing-history/InvestingHistory'
import InvestingHistorySummary from '~/sections/invest/sections/view-instrument/sections/your-investment/widgets/investing-history-summary/InvestingHistorySummary'
import InvestmentSummary from '~/sections/invest/sections/view-instrument/sections/your-investment/widgets/investment-summary/InvestmentSummary'
import {Order, OrdersState} from '~/store/accounting/types'
import essActions from '~/store/employeeShareScheme/actions'
import {EssViews} from '~/store/employeeShareScheme/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {FundHolding} from '~/store/identity/types'
import {Instrument, InstrumentDetailsTab} from '~/store/instrument/types'
import {InstrumentInvestingHistory, InstrumentReturn} from '~/store/portfolio/types'
import InvestingActivity from './widgets/investing-activity/InvestingActivity'

const YourInvestmentTab = ({
    currentHistory,
    currentReturn,
    holding,
    includeSoldInvestments,
    instrument,
    isDependent,
    isHomeCurrencyInvestment,
    orders,
    ordersState,
    period,
    preferredName,
    setHideFooter,
    setPeriod,
    sharesSoldCount,
    tab,
    essViews,
}: {
    currentHistory?: InstrumentInvestingHistory
    currentReturn?: InstrumentReturn
    holding?: FundHolding
    includeSoldInvestments: boolean
    instrument: Instrument
    isDependent: boolean
    isHomeCurrencyInvestment: boolean
    orders: Order[]
    ordersState: OrdersState
    period: Period
    preferredName: string
    setHideFooter?: (isHidden: boolean) => void
    setPeriod: (period: Period) => void
    sharesSoldCount: number
    tab: InstrumentDetailsTab
    essViews: EssViews
}) => {
    const dispatch = useAppDispatch()
    const hasHadSellOrder = sharesSoldCount > 0
    const initialToggleState = useAppSelector(
        s => s.employeeShareScheme.investmentDetailsToggleStateByInstrument?.[instrument.id],
    )
    const handleToggleStateChange = (isOpen: boolean) => {
        rudderTrack('ess_investment', 'ess_investment_details', {
            visibility: isOpen ? 'show' : 'hide',
        })
        dispatch(essActions.SetInvestmentDetailsToggleStateForInstrument(instrument.id, isOpen))
    }

    const resourcePath = useAppSelector(s => s.instrument.metadata.resourcePath)

    return (
        <section
            id="your-investment"
            style={{display: tab === 'Your investment' ? 'block' : 'none'}}
            role="tabpanel"
            aria-labelledby="your-investment-tab"
        >
            {essViews.isActiveMember && (
                <EmployeeShareSchemeOverview instrument={instrument} currentReturn={currentReturn} />
            )}
            <ConditionalWrapper
                // Wrap the summary and history components in a content toggle for ESS customers
                condition={!!(essViews.isActiveMember && (holding || currentHistory))}
                wrapper={children => (
                    <ContentToggle
                        dataTestId="button--shares-you-own-toggle"
                        label="Show details on shares you own"
                        openLabel="Hide investment details"
                        initialState={initialToggleState}
                        onChange={handleToggleStateChange}
                    >
                        {children}
                    </ContentToggle>
                )}
            >
                <>
                    {essViews.hasEmployment && !essViews.isActiveMember && (
                        <SharesAreOnTheirWay instrument={instrument} resourcePath={resourcePath} essViews={essViews} />
                    )}
                    {currentReturn && holding && (
                        <InvestmentSummary
                            currentReturn={currentReturn}
                            hasHadSellOrder={hasHadSellOrder}
                            holding={holding}
                            includeSoldInvestments={includeSoldInvestments}
                            instrument={instrument}
                            isDependent={isDependent}
                            isHomeCurrencyInvestment={isHomeCurrencyInvestment}
                            preferredName={preferredName}
                            setHideFooter={setHideFooter}
                        />
                    )}
                    {currentHistory && (
                        <>
                            <h2 className={styles.sectionHeader}>Investing history</h2>
                            <InvestingHistory period={period} setPeriod={setPeriod} currentHistory={currentHistory} />
                            <InvestingHistorySummary
                                period={period}
                                purchasePriceInfo={currentHistory?.purchase_price_info}
                            />
                        </>
                    )}
                </>
            </ConditionalWrapper>
            <InvestingActivity
                instrument={instrument}
                orders={orders}
                ordersState={ordersState}
                currentReturn={currentReturn}
                essViews={essViews}
            />
        </section>
    )
}

export default YourInvestmentTab
