import React from 'react'
import {useRetailGet} from '~/api/query/retail'
import {
    EmployeeShareSchemeAllocationsByInstrument,
    EmployeeShareSchemeParticipation,
} from '~/store/employeeShareScheme/types'
import {useAppSelector} from '~/store/hooks'
import {actingAsID} from '~/store/identity/selectors'

export const useEmployeeShareSchemeDetails = () => {
    const acting_as_id = useAppSelector(actingAsID)

    const response = useRetailGet({
        path: 'employee_share_scheme/participating-schemes-v2',
        payload: {acting_as_id},
    }).data

    const shareSchemeDetails = React.useMemo(() => {
        const participation: EmployeeShareSchemeParticipation = {}
        const allocations: EmployeeShareSchemeAllocationsByInstrument = {}

        Object.entries(response.schemes_by_instrument_id!).forEach(([instrumentId, details]) => {
            allocations[instrumentId] = {
                type: 'ess_share_allocations',
                total_unvested_contribution: details.total_unvested_contribution,
                total_unvested_employee_contribution: details.total_unvested_employee_contribution,
                total_unvested_employer_contribution: details.total_unvested_employer_contribution,
                total_unvested_allocations: details.total_unvested_allocations,
                total_unvested_allocation_years: details.total_unvested_allocation_years,
                total_unvested_share_rights: details.total_unvested_share_rights,
                total_vested_allocations: details.total_vested_allocations,
                total_vested_allocation_years: details.total_vested_allocation_years,
                next_vesting_date: details.next_vesting_date,
                next_event_date: details.next_event_date,
                next_event_type: details.next_event_type,
                share_allocations: details.share_allocations,
                share_rights_allocations: details.share_rights_allocations,
                alignment_right_allocations: details.alignment_right_allocations,
            }
            participation[instrumentId] = details.share_schemes
        })

        return {shareSchemeParticipation: participation, shareSchemeAllocationsByInstrument: allocations}
    }, [response])

    return shareSchemeDetails
}
