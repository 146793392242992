import {documentToReactComponents, RenderNode} from '@contentful/rich-text-react-renderer'
import {Document as ContentfulRichText, Block, Inline, BLOCKS, INLINES} from '@contentful/rich-text-types'
import {Button} from '@design-system/button'
import classNames from 'classnames'
import type {Entry, Asset} from 'contentful'
import React from 'react'
import {Link} from 'react-router-dom'
import {parseContentfulAsset} from '~/api/contentful/parseArticle'
import {parseButton} from '~/api/contentful/parseButton'
import {ContentfulButton} from '~/api/contentful/types'
import {convertToKebabCase} from '~/global/utils/convert-to-kebab-case/convertToKebabCase'
import {normalizeUrl, isInternalLink} from '~/global/utils/normalize-url/normalizeUrl'
import styles from './RichText.scss'

interface RichTextProps {
    richTextDocument: ContentfulRichText
    renderNode?: RenderNode
    className?: string
}

// Use renderNodes to customise how elements are handled
// https://www.contentful.com/developers/docs/tutorials/general/getting-started-with-rich-text-field-type/

export const VALUE_TEMPLATE_TARGET = '{value}'

function renderEmbeddedInlineEntry(node: Block | Inline): React.ReactNode {
    const embeddedEntry = node.data.target as Entry<{
        value: string
        paragraphTemplate: string
    }>
    const type = embeddedEntry.sys.contentType.sys.id

    switch (type) {
        case 'globalStats': {
            const {value = '', paragraphTemplate = VALUE_TEMPLATE_TARGET} = embeddedEntry.fields
            const paragraphWithValue = paragraphTemplate.replace(VALUE_TEMPLATE_TARGET, value)
            return paragraphWithValue
        }
        default: {
            return null
        }
    }
}

function renderEmbeddedBlockEntry(node: Block | Inline): React.ReactNode {
    const embeddedEntry = node.data.target as Entry<{}>

    // Guard against unpublished content
    // Unpublished content has no contentType - sys: { id: 'abc', type: 'Link', linkType: 'Entry' }
    if (!embeddedEntry || !embeddedEntry.sys || !embeddedEntry.sys.contentType) {
        // console.warn('Unpublished content type: ', embeddedEntry)
        return null
    }

    const type = embeddedEntry.sys.contentType.sys.id

    switch (type) {
        case 'blogPostLegalDisclaimer': {
            const content = node.data.target.fields.content

            return (
                <div className={styles.legalDisclaimer}>
                    <RichText richTextDocument={content} />
                </div>
            )
        }
        case 'button': {
            const button = embeddedEntry as Entry<ContentfulButton>
            const vanillaButton = parseButton(button)

            if (!vanillaButton) {
                return
            }

            const {ariaLabel, label, type, linkHref, reactRouterTo} = vanillaButton

            return (
                <div className={styles.button}>
                    <Button
                        linkHref={linkHref}
                        reactRouterTo={reactRouterTo}
                        label={label}
                        ariaLabel={ariaLabel}
                        type={type}
                        dataTestId={`button--rich-text-${convertToKebabCase(label)}`}
                    />
                </div>
            )
        }
        // case 'componentStats':
        // case 'embeddedMedia':
        // case 'embeddedImage':
        // case 'componentCard':
        // case 'componentAccordion':
        default: {
            return null
        }
    }
}

/**
 * Render out a hyperlink (anchor tag) for external and internal URLs.
 * This also handles smooth scrolling to hash params on the same page.
 *
 * @param {Block | Inline} node - The hyperlink node to render from Contentful RichText
 * @returns {React.ReactNode} - The React element to render
 */
function renderHyperLink(node: Block | Inline): React.ReactNode {
    const url: string = node.data.uri

    // The first text object in the node.content array is the text node containing the link label when you render an anchor tag
    const contentNode = node.content.find(nodeContent => nodeContent.nodeType === 'text')

    if (!contentNode || contentNode.nodeType !== 'text') {
        return null
    }

    const anchorLabel = contentNode.value

    // Remove the current origin e.g. "https://app.sharesies.com" if it's not
    // for an external link, so react-router link component works and doesn't
    // open the link in a new tab. Fix profile slugs if there are any.
    const cleanUrl = normalizeUrl(url)

    if (isInternalLink(cleanUrl)) {
        const hash = cleanUrl.match(/.+(#.+)/)?.[1]

        // Only make a fake anchor link to smooth scroll if the URL is the same as the current page
        if (cleanUrl.includes(window.location.pathname) && hash) {
            return (
                <a
                    onClick={e => {
                        e.preventDefault()

                        // Handle smooth scrolling using a hash param from outside the page
                        const targetEl = document.querySelector(hash)

                        if (!targetEl) {
                            return
                        }

                        targetEl.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                        })
                    }}
                    href={hash}
                >
                    {anchorLabel}
                </a>
            )
        }

        return <Link to={cleanUrl}>{anchorLabel}</Link>
    }

    return (
        <a href={cleanUrl} target="_blank" rel="noopener noreferrer">
            {anchorLabel}
        </a>
    )
}

function renderEmbeddedAsset(node: Block | Inline): React.ReactNode {
    const asset = node.data.target as Asset
    const vanillaAsset = parseContentfulAsset(asset)

    if (!vanillaAsset) {
        return
    }

    const {src, alt, srcSet} = vanillaAsset

    return <img className={styles.image} src={src} alt={alt} srcSet={srcSet} sizes="(max-width: 1024px) 100vw, 50vw" />
}

// Remove any empty P tags
function renderP(_node: Block | Inline, children: React.ReactNode) {
    return <p>{children}</p>
}

// Remove any table tags
function renderTable(_node: Block | Inline, children: React.ReactNode) {
    return (
        <div className={styles.tableContainer}>
            <table>
                <tbody>{children}</tbody>
            </table>
        </div>
    )
}

function renderHeading2(_node: Block | Inline, children: React.ReactNode): React.ReactNode {
    const id = convertToKebabCase(children)
    return <h2 id={id}>{children}</h2>
}
function renderHeading3(_node: Block | Inline, children: React.ReactNode): React.ReactNode {
    const id = convertToKebabCase(children)
    return <h3 id={id}>{children}</h3>
}

// Contentful doesn't have a "Small" text so using H6 for this
function renderH6(_node: Block | Inline, children: React.ReactNode): React.ReactNode {
    return <small>{children}</small>
}

/**
 * RichText is used to render RichText inputs used in the Contentful CMS (think a Google doc editor in the CMS)
 *
 * RichText makes up the body of an article (learn article or blog post)
 *
 * @param {RichTextProps} [params] - `richTextDocument` the RichText document from Contentful to render. `renderNode` can be used to override how RichText renders nodes. `className` optional className for applying styles
 * @returns {React.JSX.Element | null} - The RichText document with children rendered out as React elements or `null`
 */
export const RichText = ({richTextDocument, renderNode = {}, className}: RichTextProps): JSX.Element | null => {
    if (!richTextDocument) {
        return null
    }

    return (
        <div className={classNames(className, styles.richText)}>
            <>
                {documentToReactComponents(richTextDocument, {
                    renderNode: {
                        // INLINES are inside paragraph or heading tags and cannot be changed
                        [INLINES.EMBEDDED_ENTRY]: renderEmbeddedInlineEntry,
                        [INLINES.HYPERLINK]: renderHyperLink,
                        // [INLINES.ASSET_HYPERLINK]: renderAssetHyperLink,
                        // BLOCKS
                        [BLOCKS.HEADING_2]: renderHeading2,
                        [BLOCKS.HEADING_3]: renderHeading3,
                        [BLOCKS.HEADING_6]: renderH6,
                        [BLOCKS.PARAGRAPH]: renderP,
                        [BLOCKS.TABLE]: renderTable,
                        [BLOCKS.EMBEDDED_ENTRY]: renderEmbeddedBlockEntry,
                        [BLOCKS.EMBEDDED_ASSET]: renderEmbeddedAsset,
                        ...renderNode,
                    },
                })}
            </>
        </div>
    )
}
