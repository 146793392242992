import {Model} from '~/api/retail/types'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {
    calculateInvestorTypeDescription,
    InvestorType,
} from '~/global/utils/calculate-investor-type-description/calculateInvestorTypeDescription'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {getImageForAutoInvestOrder} from '~/sections/invest/sections/auto-invest/utils/map-auto-invest-images/mapAutoInvestImages'
import {State as AutoinvestState} from '~/store/autoinvest/types'
import {State as IdentityState} from '~/store/identity/types'
import {Instrument, State as InstrumentState} from '~/store/instrument/types'
import {createSelector} from '../selectors'
import {AutoinvestPremadeOrder, AutoinvestOrderAllocation} from './types'

type AutoinvestEnhancedAllocations = (Model.AutoinvestOrder['allocations'][0] & {
    instrumentName: string
    instrumentCode: string
    instrumentSlug: string
    instrumentMarketPrice: string
})[]

export interface PremadeOrderEnhanced extends AutoinvestPremadeOrder {
    allocations: AutoinvestEnhancedAllocations
}

const enhanceAllocation = (allocation: AutoinvestOrderAllocation, instrument: Instrument) => {
    return {
        ...allocation,
        instrumentName: instrument.name,
        instrumentCode: instrument.symbol,
        instrumentSlug: instrument.urlSlug,
        instrumentMarketPrice: instrument.marketPrice,
        instrumentRiskRating: instrument.riskRating,
    }
}

export interface EnhancedAutoinvestOrderPremade extends Model.AutoinvestOrder {
    premadeOrder: AutoinvestPremadeOrder
    displayInterval: string
    allocations: AutoinvestEnhancedAllocations
    order_name?: string
    order_image_id?: string
}
interface EnhancedAutoinvestOrderDIY extends Model.AutoinvestOrder {
    diyInvestorType: InvestorType
    displayInterval: string
    allocations: AutoinvestEnhancedAllocations
    order_name?: string
    order_image_id?: string
}

export type EnhancedAutoinvestOrder = EnhancedAutoinvestOrderPremade | EnhancedAutoinvestOrderDIY

export const calculateDisplayInterval = (interval: Model.AutoinvestOrder['interval']): string => {
    switch (interval) {
        case '1week':
            return 'week'
        case '2week':
            return 'two weeks'
        case '4week':
            return 'four weeks'
        case '1month':
            return 'month'
        default:
            assertNever(interval)
            throw new Error(`Invalid interval: ${interval}`)
    }
}

export const selectEnhancedAutoinvestOrder = createSelector(
    ({autoinvest}) => autoinvest.premadeOrders,
    ({autoinvest}) => autoinvest.premadeOrdersState,
    ({instrument}) => instrument.instrumentsById,
    (_state, autoinvestOrder) => autoinvestOrder,
    (
        premadeOrders: AutoinvestPremadeOrder[],
        premadeOrdersState: AutoinvestState['premadeOrdersState'],
        instrumentsById: InstrumentState['instrumentsById'],
        autoinvestOrder: Model.AutoinvestOrder | undefined,
    ): EnhancedAutoinvestOrder | undefined => {
        if (!autoinvestOrder || (autoinvestOrder.premade_order_id && premadeOrdersState !== 'ready')) {
            return
        }

        if (autoinvestOrder.premade_order_id && premadeOrdersState !== 'ready') {
            return
        }

        const premadeOrder: AutoinvestPremadeOrder | undefined = premadeOrders.find(
            o => o.id === autoinvestOrder.premade_order_id,
        )

        if (autoinvestOrder.premade_order_id && !premadeOrder) {
            throw new Error(`Invalid premade_order_id: ${autoinvestOrder.premade_order_id}`)
        }

        if (premadeOrder) {
            return {
                ...baseEnhancedOrder(instrumentsById, autoinvestOrder),
                premadeOrder,
            }
        } else {
            return {
                ...baseEnhancedOrder(instrumentsById, autoinvestOrder),
                diyInvestorType: calculateInvestorTypeDescription(
                    autoinvestOrder.allocations.map((allocation: AutoinvestOrderAllocation) => ({
                        riskRating:
                            instrumentsById[allocation.fund_id] && instrumentsById[allocation.fund_id].riskRating,
                        allocation: allocation.allocation,
                    })),
                ),
            }
        }
    },
)

const baseEnhancedOrder = (
    instrumentsById: InstrumentState['instrumentsById'],
    autoinvestOrder: Model.AutoinvestOrder,
) => {
    return {
        ...autoinvestOrder,
        displayInterval: calculateDisplayInterval(autoinvestOrder.interval),
        allocations: autoinvestOrder.allocations.map((allocation: AutoinvestOrderAllocation) => {
            const instrument = instrumentsById[allocation.fund_id]
            return instrument
                ? enhanceAllocation(allocation, instrument)
                : (allocation as AutoinvestEnhancedAllocations[0])
        }),
    }
}

export const pirRequired = createSelector(
    ({autoinvest}) => autoinvest.stagedOrder,
    ({instrument}) => instrument.instrumentsById,
    (stagedOrder, instrumentsById) => {
        const pirRequiredAllocationsCheck = (
            allocations: AutoinvestOrderAllocation[],
            instrumentsById: InstrumentState['instrumentsById'],
        ): boolean => {
            let pirRequired = false

            allocations.forEach((allocation: AutoinvestOrderAllocation) => {
                if (tradingType(instrumentsById[allocation.fund_id]) === 'managed') {
                    pirRequired = true
                }
            })

            return pirRequired
        }

        let pirRequired = false

        if (stagedOrder) {
            let allocations: AutoinvestOrderAllocation[] = []

            if ('allocations' in stagedOrder.order) {
                allocations = stagedOrder.order.allocations
            } else if ('premadeOrder' in stagedOrder.order && 'allocations' in stagedOrder.order.premadeOrder) {
                allocations = stagedOrder.order.premadeOrder.allocations
            }

            pirRequired = pirRequiredAllocationsCheck(allocations, instrumentsById)
        }

        return pirRequired
    },
)

export const selectStagedOrderImageUrl = createSelector(
    ({autoinvest}) => autoinvest.stagedOrder,
    ({identity}) => identity.customImages,
    (stagedOrder: AutoinvestState['stagedOrder'], customImages: IdentityState['customImages']): string => {
        const customImageUrl = findCustomImageUrl(customImages, stagedOrder?.orderImageId)
        if (customImageUrl) {
            return customImageUrl
        }

        const isPremade = stagedOrder && 'premadeOrder' in stagedOrder.order
        const orderNameForImage = isPremade ? stagedOrder.order.premadeOrder.name : 'DIY'

        // Because we're only getting the thumbnail the 'colourMode' isn't needed
        // Consider this if refactoring this selector function
        return getImageForAutoInvestOrder(orderNameForImage, 'light').thumbnailImage
    },
)

export const selectAutoinvestOrderImageUrl = createSelector(
    ({identity}) => identity.customImages,
    ({identity}) => identity.user?.jurisdiction,
    (_state, autoinvestOrder: EnhancedAutoinvestOrder) => autoinvestOrder,
    (
        customImages: IdentityState['customImages'],
        jurisdiction: Model.User['jurisdiction'] | undefined,
        autoinvestOrder: EnhancedAutoinvestOrder,
    ): string => {
        const customImageUrl = findCustomImageUrl(customImages, autoinvestOrder.order_image_id)
        if (customImageUrl) {
            return customImageUrl
        }

        const isPremade = 'premadeOrder' in autoinvestOrder
        let orderNameForImage = isPremade ? autoinvestOrder.premadeOrder.name : 'DIY'

        // Manual patch for AFSL-migrated AU customers.
        // In 2024-02 we removed autoinvest orders for AU customers. As part of this, we migrated the AU customers' premade to DIY, but kept the order name and the image.
        // This is a bit of a hack to "keep" the premade order image for the new DIY order.
        if (
            !isPremade &&
            jurisdiction === 'au' &&
            autoinvestOrder.order_name &&
            ['Responsible', 'Global'].includes(autoinvestOrder.order_name) &&
            autoinvestOrder.recently_migrated_for_afsl
        ) {
            orderNameForImage = autoinvestOrder.order_name
        }

        // Because we're only getting the thumbnail the 'colourMode' isn't needed
        // Consider this if refactoring this selector function
        return getImageForAutoInvestOrder(orderNameForImage, 'light').thumbnailImage
    },
)

const findCustomImageUrl = (
    customImages: IdentityState['customImages'],
    imageId: string | undefined,
): string | undefined => {
    if (customImages && imageId) {
        const customImage = customImages.find(image => image.image_id === imageId)

        if (customImage) {
            return customImage.image_url
        }
    }
}

export const selectPremadeOrder = createSelector(
    ({autoinvest}) => autoinvest.autoinvestOrders,
    (autoinvestOrders: {[key: string]: Model.AutoinvestOrder}): Model.AutoinvestOrder | undefined => {
        return Object.values(autoinvestOrders).find(order => order.premade_order_id)
    },
)
