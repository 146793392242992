import {Button} from '@design-system/button'
import {Modal} from '@design-system/modal'
import React from 'react'
import {useRetailPost} from '~/api/query/retail'
import {Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import AmountFooter from '~/global/widgets/instrument-activity/BuySellDataRows/AmountFooter'
import recordStyles from '~/global/widgets/instrument-activity/Record.scss'
import {RecordRow} from '~/global/widgets/instrument-activity/common'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {DollarValue, SharePriceValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {Toast} from '~/global/widgets/toast/Toast'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {getSellDownTypeTitle, getTitle} from '~/sections/kiwisaver/widgets/activity-card/InvestingActivityCard'
import {BasicInstrument} from '~/store/instrument/types'
import {SharesBoughtOrSold} from '../BuySellDataRows/common'

interface PortfolioOrderInvestingActivityProps {
    instrument: BasicInstrument
    order: Response.PortfolioOrderDetails
}

const PortfolioOrderInvestingActivity: React.FC<PortfolioOrderInvestingActivityProps> = React.memo(
    ({instrument, order}) => {
        /**
         * Note these methods are similar to methods in src/page/InstrumentActivity/RecordBuySell.tsx
         * but it covers fewer cases. We plan to add to this one as we port order types over to being
         * PortfolioOrders
         */
        const [cancelModalShown, setCancelModalShown] = React.useState(false)
        const cancelOrder = useRetailPost({
            path: 'fonterra/portfolios/:portfolio_id/orders/cancel',
            pathParams: {portfolio_id: order.portfolio_id},
            queryCacheToInvalidate: [
                [`wallet/:portfolio_id/transaction`, {portfolio_id: order.portfolio_id}],
                [`wallet/:portfolio_id/transactions`, {portfolio_id: order.portfolio_id}],
                ['investment-activity/:portfolio_id/activity', {portfolio_id: order.portfolio_id}],
                ['investment-activity/:portfolio_id/item', {portfolio_id: order.portfolio_id}],
            ],
            queryCacheToUpdate: [
                'fonterra/portfolios/:portfolio_id/order',
                {portfolio_id: order.portfolio_id, order_id: order.id},
            ],
        })
        const notificationContext = React.useContext(NotificationContext)
        const doCancelOrder = async () => {
            try {
                await cancelOrder.mutateAsync({
                    order_id: order.id,
                })
                Toast('Order cancelled')
            } catch (e) {
                notificationContext.showModalError({
                    message: 'An error occurred cancelling your order, please try again',
                })
            } finally {
                setCancelModalShown(false)
            }
        }

        const orderHasTrades = order.trades.length > 0
        const orderAmountText = () => {
            if (orderHasTrades) {
                if (order.side === 'SELL') {
                    return 'Amount received'
                }
                if (order.side === 'BUY') {
                    return 'Amount paid'
                }
            } else if (order.side === 'SELL') {
                return `${shareLabel({instrument, isCapitalised: true, isPlural: true})} to sell`
            }
            return 'Amount'
        }

        const orderAmount = () => {
            //Always show value if any has filled
            if (orderHasTrades) {
                return <DollarValue value={order.value_filled} currency={order.cash_currency} />
            }
            switch (order.denomination) {
                case 'CASH':
                    return <DollarValue value={order.requested_amount} currency={order.cash_currency} />
                case 'SHARES':
                    return <ShareValue value={order.requested_amount} />
            }
        }

        const labelText = () => {
            switch (order.state) {
                case 'FULFILLED':
                    return 'Order filled'
                case 'PENDING':
                case 'PROCESSING':
                    return 'Pending'
            }
            return `Order ${order.state.toLowerCase()}`
        }
        const estimatedVolumeLabelText = () => {
            const sideLabel = order.side.toLowerCase()
            switch (order.denomination) {
                case 'SHARES':
                    return `${shareLabel({instrument, isPlural: true, isCapitalised: true})} to ${sideLabel}`
                case 'CASH':
                    return `Estimated ${shareLabel({instrument, isPlural: true})} to ${sideLabel}`
            }
        }

        const renderCancel = () => {
            if (!order.can_cancel) {
                return
            }
            return (
                <div className={spacing.spaceAbove48}>
                    <Button
                        label="Cancel order"
                        type="secondary"
                        dataTestId="button--cancel-order"
                        onClick={() => setCancelModalShown(true)}
                    />
                    <Modal
                        isAlert
                        isOpen={cancelModalShown}
                        setIsOpen={setCancelModalShown}
                        title="Cancel order"
                        dataTestId="modal--cancel-portfolio-order"
                        primaryButton={{
                            label: 'Cancel order',
                            onClick: () => {
                                doCancelOrder()
                            },
                        }}
                        secondaryButton={{label: 'Cancel'}}
                    >
                        <p>
                            <strong>Are you sure you want to cancel this order?</strong>
                        </p>
                    </Modal>
                </div>
            )
        }

        return (
            <div className={recordStyles.viewContainer}>
                <div className={recordStyles.titleBlock}>
                    <h2>{getTitle(order.side) + getSellDownTypeTitle(order.selldown_type!)}</h2>
                </div>
                <div className={recordStyles.orderContent}>
                    <div className={recordStyles.orderTitleBlock}>
                        <div className={recordStyles.orderThumb}>
                            <InstrumentLogo instrument={instrument} noBorder />
                        </div>
                        <div>
                            <h4 data-testid="title--portfolio-order-fund-name" className={recordStyles.instrumentName}>
                                {instrument.name}
                            </h4>
                            {tradingType(instrument) !== 'managed' && (
                                <p className={recordStyles.instrumentSymbol}>
                                    <PronounceLetters text={instrument.symbol} /> <Delimiter />
                                    <PronounceLetters text={instrument.exchange} />
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={recordStyles.recordRowContainer}>
                        {order.placed_at && (
                            <RecordRow
                                className={recordStyles.recordRowWithDate}
                                dataTestId="row--order-placed"
                                left="Order placed"
                                right={order.placed_at.toFormat(dateFormatWithYear)}
                            />
                        )}
                        {order.complete_at && (
                            <RecordRow
                                className={recordStyles.recordRowWithDate}
                                dataTestId="row--order-filled"
                                left="Order filled"
                                right={order.complete_at.toFormat(dateFormatWithYear)}
                            />
                        )}
                        {orderHasTrades && (
                            <SharesBoughtOrSold
                                hideBottomBorder={false}
                                instrument={instrument}
                                orderType={order.side}
                                shareValue={order.volume_filled}
                                dataTestId="row--units-bought-or-sold"
                            />
                        )}
                        {order.volume_weighted_average_price && (
                            <RecordRow
                                className={recordStyles.recordRowWithDate}
                                dataTestId="row--price-per-x"
                                left={`${order.trades.length === 1 ? 'Price per' : 'Average price per'} ${shareLabel({
                                    instrument,
                                })}`}
                                right={
                                    <SharePriceValue
                                        value={order.volume_weighted_average_price}
                                        currency={order.cash_currency}
                                    />
                                }
                            />
                        )}
                        {order.estimated_volume && (
                            <RecordRow
                                className={recordStyles.recordRowWithDate}
                                dataTestId="row--estimated-volume"
                                left={estimatedVolumeLabelText()}
                                right={<ShareValue value={order.estimated_volume} />}
                            />
                        )}
                        {order.estimated_average_price && (
                            <RecordRow
                                className={recordStyles.recordRowWithDate}
                                dataTestId="row--estimated-average-price"
                                left={`Estimated price per ${shareLabel({instrument})}`}
                                right={
                                    <DollarValue value={order.estimated_average_price} currency={order.cash_currency} />
                                }
                            />
                        )}
                    </div>
                    <AmountFooter
                        orderAmount={orderAmount()}
                        orderAmountText={orderAmountText()}
                        labelText={labelText()}
                    />
                    {renderCancel()}
                </div>
            </div>
        )
    },
)

export default PortfolioOrderInvestingActivity
