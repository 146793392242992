import {ClassicCard} from '@braze/web-sdk'
import React from 'react'
import config from '~/configForEnv'
import {useAppSelector} from '~/store/hooks'
import {CarouselCard, brazeCardToCarouselGroup, groupCarouselCards} from './carousel-card/CarouselCard'
import {EXPLORE_BRAZE_CARD_TYPES} from './common'
import {CompanyAnnouncementCard} from './company-announcement-card/CompanyAnnouncementCard'
import {brazeCardToHalfImageCard, HalfImageCard} from './half-image-card/HalfImageCard'
import {HighlightContentCard} from './highlight-content-card/HighlightContentCard'
import {brazeCardToTwoImageGroup, groupTwoImageCard, TwoImageGroup} from './two-image-group/TwoImageGroup'
import {brazeCardToVideoCard, VideoCard} from './video-card/VideoCard'
import styles from './ExploreContentCards.scss'

const groupFunctions: Record<string, (cards: ClassicCard[]) => ClassicCard[]> = {
    [EXPLORE_BRAZE_CARD_TYPES.category.exploreTwoImageGroup]: groupTwoImageCard,
    [EXPLORE_BRAZE_CARD_TYPES.category.exploreCarouselGroup]: groupCarouselCards,
}

const sortCardsByPriority = (a: ClassicCard, b: ClassicCard) => (b.extras?.priority || 0) - (a.extras?.priority || 0)

export function groupExploreContentCards(cards: ClassicCard[]): ClassicCard[] {
    const groupFunctionKeys = Object.keys(groupFunctions)

    if (!groupFunctionKeys) {
        return cards.sort(sortCardsByPriority)
    }

    let groupedCards = [...cards]

    groupFunctionKeys.forEach(groupFunctionKey => {
        groupedCards = groupFunctions[groupFunctionKey](groupedCards)
    }, [])

    return groupedCards.sort(sortCardsByPriority)
}

export const ExploreContentCards: React.FunctionComponent<{}> = () => {
    const exploreCards = useAppSelector(s => s.identity.notifications.exploreCards) as ClassicCard[]

    // Group any cards that need to be grouped and sort them by priority if present
    const groupedExploreCards = groupExploreContentCards(exploreCards)

    return (
        <>
            {groupedExploreCards.map((card, index) => {
                const exploreCardType = card.extras?.category

                switch (exploreCardType) {
                    case EXPLORE_BRAZE_CARD_TYPES.category.exploreContent:
                        return <HighlightContentCard card={card} key={card.id} />
                    case EXPLORE_BRAZE_CARD_TYPES.category.exploreCompanyAnnouncement:
                        return <CompanyAnnouncementCard card={card} key={card.id} />
                    case EXPLORE_BRAZE_CARD_TYPES.category.exploreVideoCard: {
                        const props = brazeCardToVideoCard(card)

                        if (!props) {
                            return null
                        }

                        return <VideoCard {...props} key={card.id} />
                    }

                    case EXPLORE_BRAZE_CARD_TYPES.category.exploreTwoImageGroup: {
                        const props = brazeCardToTwoImageGroup(card)

                        if (!props) {
                            return null
                        }

                        return <TwoImageGroup {...props} key={card.id} />
                    }

                    case EXPLORE_BRAZE_CARD_TYPES.category.exploreHalfImageCard: {
                        const props = brazeCardToHalfImageCard(card)

                        if (!props) {
                            return null
                        }

                        return <HalfImageCard {...props} key={card.id} />
                    }

                    case EXPLORE_BRAZE_CARD_TYPES.category.exploreCarouselGroup: {
                        const props = brazeCardToCarouselGroup(card)

                        if (!props) {
                            return null
                        }

                        return <CarouselCard {...props} key={card.id} />
                    }

                    default: {
                        // If env is not development, don't render the unsupported content card
                        if (config.rollbarEnvironment !== 'development') {
                            return null
                        }

                        // Let the developer know in local dev that their content card is not supported
                        return (
                            <div className={styles.notSupported} key={index}>
                                <p>Content card is not supported.</p>
                                <p>
                                    Please handle rendering <strong>{exploreCardType}</strong> in{' '}
                                    <strong>ExploreContentCards.tsx</strong>
                                </p>
                            </div>
                        )
                    }
                }
            })}
        </>
    )
}
