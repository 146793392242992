import {ModalLink} from '@design-system/modal'
import React from 'react'

export const NZXParticipant = ({participants, label}: {participants: string[]; label: string}) => {
    return (
        <ModalLink
            dataTestId="modal-link--nzx-participants-and-advising-firm"
            label={label}
            modalTitle="NZX Participant and Advising firms"
            primaryButton={{label: 'Ok'}}
        >
            <p>As at 8 January 2021:</p>
            <ul>
                {participants
                    .filter(p => p !== 'Other')
                    .map((p, i) => (
                        <li key={i}>{p}</li>
                    ))}
            </ul>
        </ModalLink>
    )
}

export default NZXParticipant
