import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {CommonProps, withId} from '../common'
import styles from '../form.scss'

interface ButtonDetails {
    label: string
    value: string
    callback?: () => void // use this to perform an action on parent component after selection, ie show additional inputs
}
type ButtonGroupProps = CommonProps<string> & {
    details: ButtonDetails[]
    toggleCustom?: (toggle: boolean) => void
    name: string
}

const ButtonGroup = (props: ButtonGroupProps) => {
    const [selected, setSelected] = React.useState<string | undefined>()

    const handleClick = (value: string, callback: ButtonDetails['callback']) => {
        if (props.setFieldValue) {
            props.setFieldValue(value)
        }

        if (callback) {
            callback()
        }

        setSelected(value)
    }

    return (
        <div className={cn(styles.buttonGroup, spacing.spaceBelow24)}>
            {props.details.map(({label, value, callback}) => (
                <Button
                    key={label}
                    type={selected === value ? 'primary' : 'secondary'}
                    onClick={() => handleClick(value, callback)}
                    dataTestId={`button-group--button-${props.name}-${value || 'custom'}`}
                    label={label}
                    additionalClassName={selected === value ? styles.buttonGroupButtonSelected : undefined}
                />
            ))}
        </div>
    )
}

const ButtonGroupWithId = withId(ButtonGroup)

export default ButtonGroupWithId
