import {Button} from '@design-system/button'
import {CheckSingle} from '@design-system/icon'
import {Modal} from '@design-system/modal'
import cn from 'classnames'
import React, {useState} from 'react'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {usePortfolioItemsById} from '~/global/state-hooks/mixed-source/usePortfolioItemsById'
import {roundDownToTwoDecimalPlaces} from '~/global/utils/calculate-currency-exchange/calculateCurrencyExchange'
import calculateWalletBalance from '~/global/utils/calculate-wallet-balance/calculateWalletBalance'
import {useExchangeFeeRate} from '~/global/utils/exchange-fee-hooks/exchangeFeeHooks'
import useAutoinvestDividends from '~/global/utils/use-autoinvest-dividends/useAutoinvestDividends'
import useDividendReinvestmentPlan from '~/global/utils/use-dividend-reinvestment-plan/useDividendReinvestmentPlan'
import {useExchangeRates} from '~/global/utils/use-exchange-rates/useExchangeRates'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Radio} from '~/global/widgets/form-controls'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {Link, useNavigate} from '~/migrate-react-router'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import orderActions from '~/store/order/actions'
import styles from './Record.scss'

const ReinvestDividendSnippet: React.FC<{instrument: Instrument; dividendAmount: string; orderId: string}> = ({
    instrument,
    dividendAmount,
    orderId,
}) => {
    const navigate = useNavigate()
    const walletBalances = useAppSelector(s => s.identity.user!.wallet_balances)
    const [modalOpen, setModalOpen] = React.useState(false)
    const hasReinvestDividendsFlag = useAppSelector(s => s.identity.flags.customer_dividend_reinvest)
    const profileUrl = useProfileUrl()

    const {preferences, loadingState} = useAutoinvestDividends()
    const isAutoInvestingDividend = preferences.funds.includes(instrument.id)

    const [exchangeRates] = useExchangeRates()
    const exchangeFeeRate = useExchangeFeeRate()
    const estimatedWalletBalance = calculateWalletBalance(
        walletBalances,
        exchangeRates,
        true,
        instrument.currency,
        exchangeFeeRate,
    )
    const walletBalanceSufficientToReinvest = Number(estimatedWalletBalance) > Number(dividendAmount)

    if (!hasReinvestDividendsFlag) {
        return null
    }

    if (loadingState !== 'ready') {
        return null
    }

    if (isAutoInvestingDividend) {
        return (
            <div className={styles.dividendReinvestArea}>
                <div className={styles.reinvestDividendOn}>
                    <div>
                        <h4>Dividend auto-invest</h4>
                        <Link to={profileUrl('invest/dividends')}>Manage dividends</Link>
                    </div>
                    <div className={cn(styles.flag)}>
                        On
                        <CheckSingle />
                    </div>
                </div>
            </div>
        )
    }

    if (walletBalanceSufficientToReinvest) {
        return (
            <div className={styles.dividendReinvestArea}>
                <Button
                    dataTestId="button--reinvest-dividend"
                    label="Invest dividend"
                    type="secondary"
                    onClick={() => {
                        setModalOpen(true)
                    }}
                />
                <ReinvestDividendActionsModal
                    instrument={instrument}
                    dividendAmount={dividendAmount}
                    orderId={orderId}
                    isOpen={modalOpen}
                    setIsOpen={setModalOpen}
                    dividendReinvestId={orderId}
                />
            </div>
        )
    }

    return (
        <div className={styles.dividendReinvestArea}>
            <Button
                dataTestId="button--manage-dividends"
                label="Manage dividends"
                type="secondary"
                onClick={() => navigate(profileUrl('invest/dividends'))}
            />
        </div>
    )
}

export const ReinvestDividendActionsModal: React.FC<{
    instrument: Instrument
    dividendAmount: string
    dividendReinvestId: string
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    orderId: string
}> = ({isOpen, setIsOpen, instrument, dividendAmount, orderId}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const [value, setValue] = useState<'once' | 'everytime'>()
    const [drpPlan] = useDividendReinvestmentPlan(instrument)
    const totalPortfolioItems = usePortfolioItemsById()
    const portfolioItem = totalPortfolioItems[instrument.id]
    const ownsSharesInInstrument = !!portfolioItem && portfolioItem.sharesOwned > 0

    const handleClick = () => {
        if (value === 'once') {
            dispatch(
                orderActions.UpdateStagedBuyOrder({
                    fundId: instrument.id,
                    state: 'initialised',
                    pushedHistory: false,
                    orderType: 'dollar_market',
                    needReadPDS: undefined,
                    dividendReinvest: 'once',
                    dividendReinvestId: orderId,
                    orderCurrencyAmount: roundDownToTwoDecimalPlaces(dividendAmount),
                }),
            )
            navigate(profileUrl(`invest/:instrumentSlug/buy`, {instrumentSlug: instrument.urlSlug}))
        }

        if (value === 'everytime') {
            navigate(
                profileUrl('invest/dividends/confirm-reinvestment/:instrumentId/:orderId', {
                    instrumentId: instrument.id,
                    orderId,
                }),
            )
        }
        setIsOpen(false)
    }

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title="Invest dividend"
            dataTestId="modal--reinvest-dividend"
            primaryButton={{
                label: 'Review',
                disabled: value === undefined,
                onClick: handleClick,
            }}
            content={
                <>
                    <p>
                        Use the{' '}
                        <HelpCentreLink auArticle="4983297-dividends" nzArticle="1236445-dividends">
                            dividend
                        </HelpCentreLink>{' '}
                        you’ve been paid to buy more {instrument.name} shares.
                    </p>
                    <Radio
                        dataTestId="radio--dividend-actions"
                        name="dividend-actions"
                        isTouched={true}
                        additionalClassName={cn(spacing.spaceBelow4, styles.dividendReinvestModalRadio)}
                        value={value}
                        choices={[
                            {
                                label: 'Do this once',
                                value: 'once',
                            },
                            ...(ownsSharesInInstrument && !drpPlan
                                ? [
                                      {
                                          label: 'Do this every time',
                                          value: 'everytime',
                                      },
                                  ]
                                : []),
                        ]}
                        onChange={e => {
                            setValue(e.target.value as 'once' | 'everytime')
                        }}
                    />
                    {drpPlan ? (
                        <p className={cn(typographyOverrides['as-small-text'])}>
                            If you want to do this every time, {instrument.name} offers a dividend reinvestment plan
                            (DRP).{' '}
                            <Link
                                to={profileUrl('invest/dividend-reinvestment-learn/:instrumentSlug', {
                                    instrumentSlug: instrument.urlSlug,
                                })}
                                onClick={() => setIsOpen(false)}
                            >
                                You can opt in here.
                            </Link>
                        </p>
                    ) : (
                        <p className={cn(typographyOverrides['as-small-text'])}>
                            Want to auto-invest all dividends?{' '}
                            <Link to={profileUrl('invest/dividends')} onClick={() => setIsOpen(false)}>
                                Turn on for all of your investments
                            </Link>
                            .
                        </p>
                    )}
                </>
            }
        />
    )
}

export default ReinvestDividendSnippet
