import {DateTime} from 'luxon'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {Model} from '~/api/retail/types'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import RecurringTile from '~/global/widgets/recurring-transaction-tile/RecurringTile'
import {useNavigate} from '~/migrate-react-router'
import {
    DiyInstrumentUnavailableAlert,
    InsufficientFundsAlert,
} from '~/sections/invest/sections/auto-invest/widgets/auto-invest-alerts/AutoInvestAlerts'
import CustomImage from '~/sections/user/widgets/custom-image/CustomImage'
import actions from '~/store/autoinvest/actions'
import {EnhancedAutoinvestOrder, selectAutoinvestOrderImageUrl} from '~/store/autoinvest/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'

interface AutoInvestTileProps {
    order: EnhancedAutoinvestOrder
    homeCurrency: Model.User['home_currency']
    fromPortfolio?: boolean
    dataTestId?: string
    unavailableInstrumentNames?: string[]
}

const AutoInvestTile: React.FunctionComponent<AutoInvestTileProps> = React.memo(
    ({order, homeCurrency, fromPortfolio, dataTestId, unavailableInstrumentNames}) => {
        const navigate = useNavigate()
        const dispatch = useAppDispatch()
        const profileUrl = useProfileUrl()
        const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
        const displayImage = useAppSelector(s => selectAutoinvestOrderImageUrl(s, order))

        React.useEffect(() => {
            if (order.order_image_id && displayImage !== order.order_image_id) {
                dispatch(identityActions.GetOrderImages())
            }
        }, [])

        const failedDate = order.last_failed_date ? DateTime.fromISO(order.last_failed_date) : undefined
        const nextDate = order.next_date ? DateTime.fromISO(order.next_date) : undefined
        const frequency = order.displayInterval

        const isPremadeOrder = 'premadeOrder' in order
        const name = isPremadeOrder
            ? order.premadeOrder.name + ' order'
            : order.order_name
              ? order.order_name
              : {au: 'My auto-invest', nz: 'DIY'}[jurisdiction]
        const autoinvestOrderUrl = isPremadeOrder
            ? profileUrl('auto-invest/:premadeSlug', {premadeSlug: order.premadeOrder.slug})
            : profileUrl('auto-invest/diy')

        // Check if the key is in the response first - this field will be removed after 20/5/24
        const wasRecentlyMigratedForAfsl = 'recently_migrated_for_afsl' in order && order.recently_migrated_for_afsl

        const handleClick = () => {
            dispatch(actions.SetSelectedDiyOrderId(order.id))

            if (fromPortfolio) {
                Analytics.event({
                    category: 'AutoInvest',
                    action: 'View Auto Invest Landing Page',
                    label: 'View Landing Page',
                })
            }
            navigate(autoinvestOrderUrl)
        }

        const insufficientFundsAlert = (failedDate: DateTime, nextDate: DateTime) => (
            <InsufficientFundsAlert
                failedDate={failedDate.toFormat(dateFormatNoTime)}
                nextDate={nextDate.toFormat(dateFormatNoTime)}
            />
        )

        const instrumentsUnavailableAlert = (instrumentNames: string[]) => (
            <DiyInstrumentUnavailableAlert instrumentNames={instrumentNames} order={order} />
        )

        const recentlyMigratedForAfslAlert = () => {
            const premadeOrderName =
                order.order_name === 'Global'
                    ? 'global order'
                    : order.order_name === 'Responsible'
                      ? 'responsible order'
                      : 'autoinvest'
            return (
                <AlertCard
                    type="warning"
                    text={`Your pre-made ${premadeOrderName} has been changed to an auto-invest order that includes the same funds. You can now add or remove investments and change how much is invested in each.`}
                />
            )
        }

        const alerts: JSX.Element[] = []
        if (failedDate && nextDate) {
            alerts.push(insufficientFundsAlert(failedDate, nextDate))
        }

        if (unavailableInstrumentNames && unavailableInstrumentNames.length > 0) {
            alerts.push(instrumentsUnavailableAlert(unavailableInstrumentNames))
        }

        if (wasRecentlyMigratedForAfsl) {
            alerts.push(recentlyMigratedForAfslAlert())
        }

        return (
            <RecurringTile
                actionText="Manage"
                alerts={alerts}
                dataTestId={dataTestId || `button--upcoming-autoinvest-${order.id}`}
                displayImage={<CustomImage imageUrl={displayImage} />}
                handleClick={handleClick}
                title={name.replace(' order', '')}
                subtitle={
                    <span>
                        <DollarValue value={order.amount} currency={homeCurrency} /> every {frequency}
                    </span>
                }
                nextDate={nextDate}
                state={order.state.toUpperCase()}
            />
        )
    },
)

export default AutoInvestTile
