import React from 'react'
import {spacing} from '~/global/scss/helpers'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import ReferralTerms from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/ReferralTermsAndConditions'

const LegalReferralTerms: React.FunctionComponent<{}> = () => (
    <>
        <Toolbar dataTestId="toolbar--legal-referral-terms" leftButton="back" title="Legal" />
        <Page overrideDefaultTopPadding="withToolbarTitle">
            <h2 className={spacing.spaceBelow16}>Referral Terms and Conditions</h2>
            <ReferralTerms />
        </Page>
    </>
)

export default LegalReferralTerms
