import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {useDistillInstrumentHistoricVwap} from '~/sections/invest/sections/employee-share-scheme/data/distill'
import {alignmentRightsUnvestedTotalsForInstrument} from '~/sections/invest/sections/view-instrument/sections/employee-share-scheme/pages/upcoming-allocations/UpcomingAlignmentRightAllocations'
import {EmployeeShareSchemeAllocation} from '~/store/employeeShareScheme/types'
import {useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import styles from '../../EmployeeShareScheme.scss'

interface AlignmentRightsTotalValueProps {
    instrument: Instrument
    allocationsForInstrument: EmployeeShareSchemeAllocation
}
const AlignmentRightsTotalValue: React.FunctionComponent<AlignmentRightsTotalValueProps> = ({
    instrument,
    allocationsForInstrument,
}) => {
    const lastYear = DateTime.now().minus({years: 1}).startOf('day')
    const instrumentVwap = useDistillInstrumentHistoricVwap(
        instrument.id,
        lastYear,
        DateTime.now().startOf('day'),
    ).vwapPrice

    const shareSchemeParticipation = useAppSelector(s => s.employeeShareScheme.shareSchemeParticipation)
    const alignmentRightsTotals = alignmentRightsUnvestedTotalsForInstrument(
        instrument,
        instrumentVwap,
        allocationsForInstrument,
        shareSchemeParticipation,
    )

    return (
        <>
            {alignmentRightsTotals.vestedAlignmentRightsTotalValue > 0 ? (
                <>
                    <h2 className={cn(styles.vestedSharesValue, spacing.spaceBelow4)}>
                        <DollarValue
                            value={alignmentRightsTotals.vestedAlignmentRightsTotalValue}
                            currency={instrument.currency}
                        />
                    </h2>
                    {alignmentRightsTotals.totalPotentialValue > 0 && (
                        <p>
                            <DollarValue
                                value={alignmentRightsTotals.totalPotentialValue}
                                currency={instrument.currency}
                            />{' '}
                            estimated to receive
                        </p>
                    )}
                </>
            ) : (
                <h2>
                    <DollarValue value={alignmentRightsTotals.totalPotentialValue} currency={instrument.currency} />
                </h2>
            )}
        </>
    )
}
export default AlignmentRightsTotalValue
