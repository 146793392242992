/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    AppSchemasForClientPortfolioInstrumentInvestingHistoryDetails,
    AppSchemasForClientPortfolioInstrumentInvestingHistoryDetailsFromJSON,
    AppSchemasForClientPortfolioInstrumentInvestingHistoryDetailsFromJSONTyped,
    AppSchemasForClientPortfolioInstrumentInvestingHistoryDetailsToJSON,
    AppSchemasForClientPortfolioInstrumentInvestingHistoryDetailsToJSONTyped,
    InstrumentPurchasePriceInfo,
    InstrumentPurchasePriceInfoFromJSON,
    InstrumentPurchasePriceInfoFromJSONTyped,
    InstrumentPurchasePriceInfoToJSON,
    InstrumentPurchasePriceInfoToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface AppSchemasForClientPortfolioInstrumentInvestingHistory
 */
export interface AppSchemasForClientPortfolioInstrumentInvestingHistory {
    /**
     *
     * @type {Array<AppSchemasForClientPortfolioInstrumentInvestingHistoryDetails>}
     * @memberof AppSchemasForClientPortfolioInstrumentInvestingHistory
     */
    history: Array<AppSchemasForClientPortfolioInstrumentInvestingHistoryDetails>
    /**
     *
     * @type {InstrumentPurchasePriceInfo}
     * @memberof AppSchemasForClientPortfolioInstrumentInvestingHistory
     */
    purchase_price_info: InstrumentPurchasePriceInfo
}

export function AppSchemasForClientPortfolioInstrumentInvestingHistoryFromJSON(
    json: any,
): AppSchemasForClientPortfolioInstrumentInvestingHistory {
    return AppSchemasForClientPortfolioInstrumentInvestingHistoryFromJSONTyped(json, false)
}

export function AppSchemasForClientPortfolioInstrumentInvestingHistoryFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AppSchemasForClientPortfolioInstrumentInvestingHistory {
    if (json === undefined || json === null) {
        return json
    }
    return {
        history: (json['history'] as Array<any>).map(
            AppSchemasForClientPortfolioInstrumentInvestingHistoryDetailsFromJSON,
        ),
        purchase_price_info: InstrumentPurchasePriceInfoFromJSON(json['purchase_price_info']),
    }
}

export function AppSchemasForClientPortfolioInstrumentInvestingHistoryToJSON(
    value?: AppSchemasForClientPortfolioInstrumentInvestingHistory | null,
): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        history: (value.history as Array<any>).map(AppSchemasForClientPortfolioInstrumentInvestingHistoryDetailsToJSON),
        purchase_price_info: InstrumentPurchasePriceInfoToJSON(value.purchase_price_info),
    }
}
