import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {isUSExchange} from '~/global/utils/share-transfers/shareTransfers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useTransferInfo} from '~/global/utils/use-transfer-info/useTransferInfo'
import AccountVerificationRequired from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {Radio} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import TransferExchangeHelpText from '~/sections/invest/sections/transfer-shares/pages/transfer-exchange/widgets/transfer-exchange-help-text/TransferExchangeHelpText'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {restrictedAccountWarning} from '~/store/identity/selectors'
import actions from '~/store/transfer/actions'
import {ResponseTransferExchange} from '~/store/transfer/types'

export const TransferExchange: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const {stagedTransferOrder} = useAppSelector(({transfer}) => transfer)
    const [exchange, setExchange] = React.useState<ResponseTransferExchange | undefined>(stagedTransferOrder?.exchange)

    const status = useAppSelector(restrictedAccountWarning)

    const transferInfo = useTransferInfo()

    const exchangeOptions = [
        {value: 'ASX', label: 'Australian Securities Exchange (ASX)'},
        {
            value: 'US_MARKETS',
            label: 'New York Stock Exchange (NYSE), NASDAQ, or Chicago Board Options Exchange (CBOE)',
        },
        {value: 'NZX', label: 'New Zealand Stock Exchange (NZX)'},
    ]

    // Account verified status
    const hasWarning = status.addressStatus !== 'no-warning' || status.identityVerificationStatus !== 'no-warning'
    const pendingVerification =
        status.addressStatus === 'pending-approval' || status.identityVerificationStatus === 'verification-in-review'

    const nextPage = () => {
        if (!exchange || !stagedTransferOrder) {
            return
        }
        // set the exchange selection on the staged transfer order
        dispatch(
            actions.SetStagedTransferOrder({
                ...stagedTransferOrder,
                exchange,
            }),
        )

        dispatch(actions.FetchInfo(stagedTransferOrder?.direction))

        if (exchange === 'NZX') {
            navigate(profileUrl('invest/portfolio-transfer-shares/nzx/intro'))
        }

        if (exchange === 'ASX') {
            stagedTransferOrder.direction === 'out'
                ? navigate(profileUrl('invest/portfolio-transfer-shares/asx/reference'))
                : navigate(profileUrl('invest/portfolio-transfer-shares/asx/intro'))
        }

        if (isUSExchange(exchange)) {
            // Don't continue the transfers flow if account is not verified
            if (hasWarning) {
                navigate(profileUrl('invest/portfolio-transfer-shares/us/verify-account'))
            }

            if (pendingVerification) {
                navigate(profileUrl('invest/portfolio-transfer-shares/us/account-verification-pending'))
            } else {
                navigate(profileUrl('invest/portfolio-transfer-shares/us/select-platform'))
            }
        }
    }

    if (!stagedTransferOrder || !stagedTransferOrder.direction) {
        navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})
        return <></>
    }

    if (!transferInfo) {
        return <Loading isPineapple />
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--transfer-exchange" leftButton="back" />
            <Page>
                {isUSExchange(exchange) && <AccountVerificationRequired />}
                <h1 className={cn(commonStyles.heading, spacing.spaceBelow24)}>Select exchange</h1>
                <p className={commonStyles.formLabel}>On which exchange are the shares you want to transfer listed?</p>
                <form className={spacing.spaceAbove16}>
                    <Radio
                        dataTestId="radio--stock-exchange"
                        name="exchange"
                        isTouched
                        choices={exchangeOptions}
                        onChange={e => setExchange(e.target.value as 'ASX' | 'NZX' | 'US_MARKETS')} // Set exchange initially as US_MARKETS for US transfers
                        value={exchange}
                        additionalClassName={spacing.spaceBelow16}
                    />

                    {exchange && (
                        <TransferExchangeHelpText direction={stagedTransferOrder?.direction} exchange={exchange} />
                    )}
                </form>
            </Page>
            <ActionBar>
                <Button
                    label="Next"
                    dataTestId="button--next"
                    type="primary"
                    onClick={() => nextPage()}
                    disabled={!exchange}
                />
            </ActionBar>
        </>
    )
}

export default TransferExchange
