import {DateTime} from 'luxon'
import React from 'react'
import {dateFormatNoTime, dateFormatNoYear} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {useNavigate} from '~/migrate-react-router'
import {useAppSelector} from '~/store/hooks'

interface IdentityVerificationRequiredProps {
    className?: string
}

const IdentityVerificationRequired: React.FunctionComponent<IdentityVerificationRequiredProps> = ({className}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const biometricRestrictAccountAfterDate = useAppSelector(
        s => s.identity.user!.checks.identity_verification.latest_biometric_verification_check?.restrict_account_after,
    )
    const secondaryIdRestrictAccountAfterDate = useAppSelector(
        s => s.identity.user!.checks.identity_verification.secondary_identity_document_check?.restrict_account_after,
    )
    const additionalVerificationRequired = useAppSelector(
        s => s.identity.user!.checks.identity_verification.additional_verification_required,
    )

    const restrictAccountAfter = biometricRestrictAccountAfterDate
        ? biometricRestrictAccountAfterDate
        : secondaryIdRestrictAccountAfterDate
    const pastRestrictAccountAfter = restrictAccountAfter ? restrictAccountAfter < DateTime.local() : true

    const handleVerificationFlow = () => {
        navigate(profileUrl('verify-your-identity'))
    }
    if (additionalVerificationRequired && !pastRestrictAccountAfter && restrictAccountAfter) {
        return (
            <AlertCard
                className={className}
                type="warning"
                title={`Verify your identity by ${restrictAccountAfter.toFormat(dateFormatNoYear)}`}
                text={`After ${restrictAccountAfter.toFormat(
                    dateFormatNoTime,
                )}, you’ll need to verify your identity before you can buy, sell, top up or withdraw.`}
                links={[
                    {
                        text: 'Verify your identity',
                        callback: handleVerificationFlow,
                        dataTestId: 'button-verify-identity',
                    },
                ]}
            />
        )
    } else {
        return null
    }
}

export default IdentityVerificationRequired
