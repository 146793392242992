import {GrowthBook, GrowthBookProvider} from '@growthbook/growthbook-react'
import {QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import React from 'react'
import ReactPixel from 'react-facebook-pixel'
import {Provider} from 'react-redux'
import {RouterProvider, createBrowserRouter} from 'react-router-dom'
import {queryClient} from '~/api/query/client'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import config from '~/configForEnv'
import {IntercomProvider} from '~/global/data-components/Intercom'
import {ROUTES} from '~/global/routes'
import {injectParamsAsProps} from '~/global/utils/routing/routing'
import store from '~/store'
import identityActions from '~/store/identity/actions'

store.dispatch(identityActions.init())

// Disabling facebook pixels automatic tracking for each page
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
if (window && window.fbq) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.fbq.disablePushState = true
}

const facebookPixelOptions = {
    autoConfig: true,
    debug: false,
}
if (config.facebookPixelIdNZ) {
    ReactPixel.init(config.facebookPixelIdNZ, undefined, facebookPixelOptions)
}
if (config.facebookPixelIdAU) {
    ReactPixel.init(config.facebookPixelIdAU, undefined, facebookPixelOptions)
}

const growthbook = new GrowthBook({
    enableDevMode: config.growthBookEnableDevMode, // If true, enables the GrowthBook Chrome Extension
    trackingCallback: (experiment, result) => {
        rudderTrack('growthbook', 'experiment', {
            experimentKey: experiment.key,
            resultInExperiment: result.inExperiment,
            resultValue: result.value,
            resultVariationId: result.variationId,
        })
    },
})

const Router: React.FunctionComponent<{}> = () => {
    const router = createBrowserRouter(injectParamsAsProps(ROUTES))

    return (
        <GrowthBookProvider growthbook={growthbook}>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <IntercomProvider
                        onShow={() => {
                            window.dispatchEvent(new Event('intercomShown')) // used in src/sections/user/sections/settings/pages/intercom-message/IntercomMessage.tsx
                        }}
                        onHide={() => {
                            window.dispatchEvent(new Event('intercomHidden'))
                        }}
                    >
                        <RouterProvider router={router} />
                        <ReactQueryDevtools initialIsOpen={false} />
                    </IntercomProvider>
                </QueryClientProvider>
            </Provider>
        </GrowthBookProvider>
    )
}

export default Router
