import {Button} from '@design-system/button'
import cn from 'classnames'
import {Formik} from 'formik'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {alphanumericOnly} from '~/global/utils/normalize-values/normalizeValues'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {validate} from '~/global/widgets/form-controls'
import {Text, TFNNumber} from '~/global/widgets/form-controls/formik'
import {Toast} from '~/global/widgets/toast/Toast'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import styles from '~/sections/invest/sections/transfer-shares/sections/us-transfer-shares/RegistryDetailsForm.scss'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import transferActions from '~/store/transfer/actions'
import {TransferOrderRegistryDetails} from '~/store/transfer/types'
interface FormValues {
    ird_number?: string
    reference: string
    tfn?: string
}

const RegistryDetailsForm = (props: {nextPage: () => void}) => {
    const notificationContext = React.useContext(NotificationContext)
    const dispatch = useAppDispatch()

    const showTfnField = useAppSelector(
        ({identity}) => !!identity.user?.tfn_number !== true && identity.user!.jurisdiction === 'au',
    )
    const {registryDetails, stagedTransferOrder, existingReference} = useAppSelector(({transfer}) => ({
        registryDetails: transfer.registryDetails,
        stagedTransferOrder: transfer.stagedTransferOrder!,
        existingReference: transfer.stagedTransferOrder!.reference,
        direction: transfer.stagedTransferOrder!.direction,
    }))
    const {jurisdiction, existingIrdNumber} = useAppSelector(({identity}) => ({
        jurisdiction: identity.user!.jurisdiction,
        existingIrdNumber: identity.user!.ird_number,
    }))

    const setTfnNumber = (tfnNumber: string) => dispatch(identityActions.UpdateAUFinancialDetails(tfnNumber))

    const setRegistryDetails = (
        reference: TransferOrderRegistryDetails['referenceId'],
        reference_type: TransferOrderRegistryDetails['referenceType'],
    ) => {
        const baseDetails = {
            referenceId: reference,
            referenceType: reference_type,
        }
        return stagedTransferOrder?.registryDetailId
            ? dispatch(
                  transferActions.UpdateRegistryDetails({
                      ...baseDetails,
                      registryDetailId: stagedTransferOrder?.registryDetailId,
                  }),
              )
            : dispatch(transferActions.CreateRegistryDetails(baseDetails))
    }

    return (
        <Formik
            initialValues={{ird_number: existingIrdNumber || null, reference: ''} as FormValues}
            validate={values => {
                return validate.generate<typeof values>({
                    ird_number: jurisdiction === 'nz' && !existingIrdNumber ? [validate.required()] : [],
                    reference: [validate.required()],
                    tfn: showTfnField ? [(validate.required(), validate.tfnNumber())] : [],
                })(values)
            }}
            onSubmit={async ({ird_number, reference, tfn}, {setSubmitting}) => {
                try {
                    const referenceType = 'BAN'
                    const isUpdatingRegistryDetail = existingReference && reference !== existingReference
                    const isUsingExistingRegistryDetail =
                        registryDetails.filter(
                            detail => detail.reference === reference && detail.reference_type === referenceType,
                        ).length > 0
                    // Trigger redux actions
                    const errorTfn = tfn ? await setTfnNumber(tfn) : undefined
                    const errorDetails = await setRegistryDetails(reference, referenceType)

                    if (ird_number) {
                        dispatch(identityActions.UpdateFinancialDetails(ird_number))
                    }
                    if (errorDetails) {
                        notificationContext.showModalError({message: errorDetails})
                        setSubmitting(false)
                    } else if (showTfnField && errorTfn) {
                        // tfn error can only exist if the tfn field has been shown, otherwise set tfn will not be called
                        notificationContext.showModalError({message: errorTfn})
                        setSubmitting(false)
                    } else {
                        if (isUpdatingRegistryDetail) {
                            Toast('Investor details updated')
                        } else if (!isUsingExistingRegistryDetail) {
                            // ensure that this is only shown when a registry detail is added for the first time
                            Toast('Investor details added')
                        }
                        props.nextPage()
                    }
                } catch (e) {
                    notificationContext.showModalError({message: unknownErrorMessage})
                    setSubmitting(false)
                    throw e
                }
            }}
        >
            {({values: {reference}, handleSubmit, isSubmitting, isValid}) => {
                const isUpdatingRegistryDetail = existingReference && reference !== existingReference
                return (
                    <form onSubmit={handleSubmit}>
                        <div className={styles.registryForm}>
                            <h1 className={cn(styles.heading, spacing.spaceBelow24)}>Your investor details</h1>

                            {showTfnField && (
                                <div className={spacing.spaceAbove24}>
                                    <TFNNumber
                                        dataTestId="text-input--tfn-number"
                                        label="Tax File Number (TFN) (optional)"
                                        name="tfn"
                                        autoFocus
                                    />
                                </div>
                            )}
                            {jurisdiction === 'nz' && !existingIrdNumber && (
                                <Text
                                    autoFocus={!showTfnField}
                                    autoComplete="off"
                                    dataTestId="text-input--ird-number"
                                    label="IRD number"
                                    name="ird_number"
                                    normalizeValue={alphanumericOnly}
                                />
                            )}
                            <Text
                                autoFocus={!showTfnField && !!existingIrdNumber}
                                autoComplete="off"
                                dataTestId="text-input--ban"
                                label="Brokerage account number"
                                name="reference"
                                normalizeValue={alphanumericOnly}
                            />
                            <p className={!showTfnField ? spacing.spaceBelow32 : ''}>
                                You can find this number on your brokerage account statement or by contacting the
                                platform that holds your shares.
                            </p>

                            {showTfnField && <p className={spacing.spaceBelow32}>In the meantime, add your TFN.</p>}
                        </div>

                        <ActionBar>
                            <Button
                                dataTestId="button--add-details"
                                disabled={!isValid}
                                isSubmit
                                label={isUpdatingRegistryDetail ? 'Update details' : 'Add details'}
                                processing={isSubmitting}
                            />
                        </ActionBar>
                    </form>
                )
            }}
        </Formik>
    )
}

export default RegistryDetailsForm
