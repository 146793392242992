import Decimal from 'decimal.js'
import {DateTime} from 'luxon'
import React from 'react'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {dateFormatFullDayWithYear, dateFormatFullMonth} from '~/global/utils/format-date/formatDate'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useInstrument} from '~/global/utils/use-instruments/useInstruments'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {
    isDRPShareCA,
    instrumentName,
    getAllocationBlurb,
    applicationCashPayment,
    getDocumentURL,
    hasApplication,
} from '~/global/widgets/corporate-action-description/corporateActionsV2'
import JoinedInSentence from '~/global/widgets/joined-in-sentence/JoinedInSentence'
import {DollarValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import {VoluntaryCorporateActionV2} from '~/store/accounting/types'
import {useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'

// This file contains widgets for displaying a CorporateActionV2 in a human-readable way.
// See also the utils in corporateActionsV2 which these widgets use heavily.

/**
 * Describes a positive outcome for use in a sentence
 */
const PositiveOutcomeDescription = ({
    action,
    outcome,
}: {
    action: Model.CorporateActionV2
    outcome: Model.CorporateActionV2['outcome_records'][number]
}) => {
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    if (isDRPShareCA(action)) {
        const instrument = instrumentsById[action.eligibility!.must_hold_fund_id]
        if (outcome.alternative_payment_amount) {
            return (
                <>
                    <ShareValue value={outcome.alternative_payment_amount} /> {instrumentName(instrument, true)}{' '}
                    {shareLabel({instrument, isPlural: true})}
                </>
            )
        } else {
            return (
                <>
                    <DollarValue value={outcome.net_amount} currency={outcome.currency} /> worth of{' '}
                    {instrumentName(instrument, true)} {shareLabel({instrument, isPlural: true})}
                </>
            )
        }
    }

    if (outcome.fund_id) {
        const instrument = instrumentsById[outcome.fund_id]

        // if they're receiving shares because they already hold the shares, it must be additional
        const isAdditional = action.eligibility?.must_hold_fund_id === instrument.id

        return (
            <>
                <ShareValue value={outcome.net_amount} /> {isAdditional && 'additional '}
                {instrumentName(instrument, true)} {shareLabel({instrument, isPlural: true})}
            </>
        )
    } else {
        return <DollarValue value={outcome.net_amount} currency={outcome.currency} />
    }
}

/**
 * Describes a set of outcomes, based on one holding
 * (as in, describes receiving cash and shares based on holding a specific share)
 */
export const EligibilityDescription = ({action}: {action: Model.CorporateActionV2}) => {
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)

    if (!action.eligibility) {
        return null
    }

    const instrument = instrumentsById[action.eligibility.must_hold_fund_id]
    const outcomes = action.outcome_records
        .filter(o => o.eligibility_record_id === action.eligibility!.record_id) // grab outcomes for this eligibility
        .filter(o => parseFloat(o.amount_per_input_unit) > 0) // only show positive outcomes in the main description

    const wereSharesRemoved = action.outcome_records.some(o => o.amount_per_input_unit.startsWith('-'))
    const showEligibilityName = !outcomes.some(o => o.fund_id === instrument.id)

    const isDRPShareDividend = isDRPShareCA(action)

    if (!outcomes.length && isDRPShareDividend) {
        return <>Details available soon</>
    }

    if (!outcomes.length) {
        return null
    }

    return (
        <>
            {action.is_terminal ? "You've received" : "You'll receive"}
            <JoinedInSentence
                nodes={outcomes.map(outcome => (
                    <PositiveOutcomeDescription action={action} outcome={outcome} key={outcome.id} />
                ))}
            />
            {isDRPShareDividend && ` through ${instrument.symbol}'s dividend reinvestment plan,`}
            {wereSharesRemoved ? ' for the ' : ' based on the number of '}
            {!isDRPShareDividend && instrumentName(instrument, showEligibilityName)}{' '}
            {shareLabel({instrument, isPlural: true})} you held on{' '}
            {DateTime.fromISO(action.ex_date).toFormat(dateFormatFullMonth)}.
        </>
    )
}

export const ApplicationDescription = ({action}: {action: Model.CorporateActionV2}) => {
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)

    if (!action.application) {
        return null
    }

    const lostShares = action.outcome_records.find(o => o.fund_id && o.amount_per_input_unit.startsWith('-'))
    const lostInstrument = lostShares ? instrumentsById[lostShares.fund_id!] : undefined
    const gainedFunds = action.outcome_records.filter(o => o.fund_id && !o.amount_per_input_unit.startsWith('-'))

    const gainedShares = gainedFunds.find(o => {
        if (o.fund_id) {
            const instrument = instrumentsById[o.fund_id]
            if (!['rights', 'warrants', 'options'].includes(instrument.instrumentType)) {
                return o
            }
        }
    })
    const gainedInstrument = gainedShares ? instrumentsById[gainedShares.fund_id!] : undefined
    const primaryInstrument = instrumentsById[action.fund_id]
    const isSettled = action.is_terminal

    if (lostShares && lostInstrument && gainedShares && gainedInstrument) {
        const loss = new Decimal(lostShares.gross_amount).negated()
        const gain = new Decimal(gainedShares.gross_amount)
        const applicationBlurb = (
            <>
                You have {isSettled ? 'exercised' : 'applied to exercise'} <ShareValue value={loss.toString()} />{' '}
                {shareLabel({instrument: lostInstrument, isPlural: !loss.eq(1)})} into{' '}
                <ShareValue value={gain.toString()} />{' '}
                {shareLabel({instrument: gainedInstrument, isPlural: !gain.eq(1)})}.
            </>
        )

        return (
            <>
                {applicationBlurb} {getAllocationBlurb(action, gainedInstrument)}
            </>
        )
    }

    const [currency, cashAmount] = applicationCashPayment(action) ?? []

    if (
        action.action_type === 'PURCHASE' &&
        primaryInstrument &&
        cashAmount &&
        currency &&
        action.application.requested_application_amount
    ) {
        const requestedApplicationAmount = new Decimal(action.application.requested_application_amount)

        return requestedApplicationAmount.eq(cashAmount.neg()) ? (
            <>
                You have applied for up to <DollarValue value={cashAmount.neg().toString()} currency={currency} /> of{' '}
                {shareLabel({instrument: primaryInstrument, isPlural: true})}.{' '}
                {getAllocationBlurb(action, primaryInstrument)}
            </>
        ) : (
            <>
                Your application has been scaled. You have been allocated{' '}
                <DollarValue value={cashAmount.neg().toString()} currency={currency} /> of your{' '}
                <DollarValue value={requestedApplicationAmount.toString()} currency={currency} /> application amount.
            </>
        )
    }

    return null
}

/**
 * If shares are removed, this is shown at the end of the description
 *
 * @example
 * Your TSLA shares have been removed from your Portfolio
 */
const ShareRemovedDescription = ({
    action,
    outcome,
}: {
    action: Model.CorporateActionV2
    outcome: Model.CorporateActionV2['outcome_records'][number]
}) => {
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const instrument = instrumentsById[outcome.fund_id!]

    const isOnlyOutcome = action.outcome_records.every(o => o.amount_per_input_unit === '-1')

    if (!action.is_terminal) {
        return null
    }

    return (
        <>
            Your {instrumentName(instrument, isOnlyOutcome)} {shareLabel({instrument, isPlural: true})} have been
            removed from your Portfolio.
        </>
    )
}

/**
 * Renders the base sentence for describing a vote - props are low level to handle both available applications, or
 * cast applications
 *
 * @example
 * Me Today Limited (NZX: MEE) will hold a vote at its Vote Title on Wednesday 03 Aug
 */
export const VoteIntroBlurb: React.FC<{
    instrument: Instrument
    title?: string
    settled?: boolean
    settlementDate?: string
}> = ({instrument, title, settled, settlementDate}) => (
    <>
        {instrument.name} ({instrument.exchange}:{instrument.symbol}){settled ? ' held' : ' will hold'} a vote
        {title && ` at its ${title}`}
        {settlementDate && ` on ${DateTime.fromISO(settlementDate).toFormat(dateFormatFullDayWithYear)}`}.
    </>
)

export const VoteEligibilityBlurb: React.FC<{
    instrument: Instrument
    finalTradingDate: string
    hasClosed?: boolean
}> = ({instrument, finalTradingDate, hasClosed}) => {
    return (
        <>
            Because you held {instrument.symbol} shares at market close on{' '}
            {DateTime.fromISO(finalTradingDate).toFormat(dateFormatFullDayWithYear)} through Sharesies,{' '}
            {hasClosed ? 'you were' : 'you’re'} eligible to cast your votes in advance.
        </>
    )
}

export const VoteResultsCard: React.FC<{
    instrument: Instrument
    order: VoluntaryCorporateActionV2
    className?: string
    title?: string
}> = ({instrument, order, className, title = 'Voting complete'}) => {
    const recentVoteResultsDoc = order?.documents.find(d => d.purpose === 'RESULTS')

    if (recentVoteResultsDoc) {
        return (
            <AlertCard
                className={className}
                type="info"
                title={title}
                text={`Check out how shareholders voted at ${instrument.name}ʻs ${order.application.investment_option_title}.`}
                links={[
                    {
                        text: 'View vote results',
                        href: getDocumentURL(recentVoteResultsDoc),
                    },
                ]}
            />
        )
    }

    return null
}

/**
 * Render a long form description for a cast vote
 */
const VoteDescription: React.FC<{action: VoluntaryCorporateActionV2}> = ({action}) => {
    const instrument = useInstrument(action.fund_id)
    if (!instrument) {
        return null
    }

    const {application} = action
    const hasClosed =
        action.is_terminal || (application.applications_close_at && application.applications_close_at < DateTime.now())
    const hasResults = action.documents.some(doc => doc.purpose === 'RESULTS')

    return (
        <>
            <p>
                <VoteIntroBlurb
                    instrument={instrument}
                    title={application.investment_option_title}
                    settled={action.is_terminal}
                    settlementDate={action.settlement_date}
                />
            </p>
            <p>
                <VoteEligibilityBlurb
                    instrument={instrument}
                    finalTradingDate={action.final_trading_date}
                    hasClosed={hasClosed}
                />
            </p>
            {!application.is_cancelled && hasClosed && (
                <p>Your votes were submitted on {application.applications_close_at!.toFormat(dateFormatFullMonth)}.</p>
            )}
            {hasResults && (
                <VoteResultsCard instrument={instrument} order={action} className={spacing.spaceAbove16} title="" />
            )}
        </>
    )
}

/**
 * Renders a fancy pants formulaic paragraph of text to describe what happened in this CA
 *
 * You've received {positive outcomes} for the {eligibility.fund} shares you held on {ex_date}. Your {remove outcome funds} shares have been removed from your Portfolio.
 *
 * @example
 * <>
 * You've received 0.0308 Canadian Pacific Railway Ltd shares, and $0.96 USD
 * for the Kansas City Southern shares you held on 02 December 2021.
 * Your XYZ shares have been removed from your Portfolio
 * </>
 */
export const CorporateActionDescription = ({action}: {action: Model.CorporateActionV2}) => {
    return (
        <>
            <p>
                <ApplicationDescription action={action} />
                <EligibilityDescription action={action} />
                {action.outcome_records
                    .filter(o => o.fund_id && parseFloat(o.amount_per_input_unit) < 0)
                    .map(o => (
                        <>
                            {' '}
                            <ShareRemovedDescription action={action} outcome={o} key={o.id} />
                        </>
                    ))}
            </p>
            {action.action_type === 'VOTE' && hasApplication(action) && <VoteDescription action={action} />}
        </>
    )
}

/**
 * Share splits and consolidations are a special case and get their own special description!
 *
 * @example
 * <>
 * The NZME shares you held on 30 June 2020 have gone through a 1 to 2 share split. You now hold 28.3177 NZME shares.
 * </>
 */
export const ShareSplitOrConsolidationDescription = ({action}: {action: Model.CorporateActionV2}) => {
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const outcome = action.outcome_records.find(o => !!o.fund_id)!
    const instrument = instrumentsById[action.eligibility!.must_hold_fund_id]

    const ratio = new Decimal(action.outcome_records.find(o => !!o.fund_id)!.amount_per_input_unit).add(1)
    let ratioParts

    if (action.action_type === 'SHARE_SPLIT') {
        ratioParts = ['1', ratio.toDecimalPlaces(8, Decimal.ROUND_UP).toString()]
    } else {
        ratioParts = [new Decimal(1).div(ratio).toDecimalPlaces(8, Decimal.ROUND_UP).toString(), '1']
    }

    return (
        <>
            The {instrumentName(instrument, true)} shares you held on{' '}
            {DateTime.fromISO(action.ex_date).toFormat(dateFormatFullMonth)} have gone through a {ratioParts[0]} to{' '}
            {ratioParts[1]} share {action.action_type === 'SHARE_SPLIT' ? 'split' : 'consolidation'}. You now hold{' '}
            <ShareValue
                value={new Decimal(action.eligibility!.shares_held)
                    .mul(new Decimal(outcome.amount_per_input_unit).add(1))
                    .toString()}
            />{' '}
            {instrument.symbol} shares.
        </>
    )
}
