import React from 'react'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {Loading} from '~/global/widgets/loading/Loading'
import {useNavigate} from '~/migrate-react-router'
import {OrderCarousel} from '~/sections/invest/widgets/order-carousel/OrderCarousel'
import actions from '~/store/accounting/actions'
import {selectInstrumentOrders, instrumentRecentOrders} from '~/store/accounting/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'

interface RecentOrdersProps {
    instrumentId: string
    orderId?: string
}

/**
 * Page with a slider of recent orders for a given instrument, using the
 * OrderCarousel widget. Accessible from an investment page's 'Your investment'
 * tab and the wallet transaction history.
 */
export const RecentOrders: React.FunctionComponent<RecentOrdersProps> = ({instrumentId, orderId}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const orders = useAppSelector(state =>
        orderId
            ? selectInstrumentOrders(state, instrumentId).filter(
                  order =>
                      order.id === orderId ||
                      (order.type === 'corporate_action_v2' && order.outcome_records.some(o => o.id === orderId)),
              )
            : instrumentRecentOrders(state, instrumentId),
    )

    // `hasLoaded` needs to be a thing, because `fundOrdersLoadingState` will (misleadingly) be `ready` for one frame before the `FetchFundOrders` action runs
    const hasLoaded = useAppSelector(({accounting}) => instrumentId in accounting.fundOrders)
    const fundOrdersLoadingState = useAppSelector(({accounting}) => accounting.fundOrdersLoadingState)

    React.useEffect(() => {
        if (orders.length === 0) {
            dispatch(actions.FetchFundOrders(instrumentId))
        }
    }, [instrumentId])

    React.useEffect(() => {
        // Handles the case where we load the recent orders, but get nothing.
        if (orders.length === 0 && hasLoaded && fundOrdersLoadingState === 'ready' && !orderId) {
            navigate(-1)
        }
    }, [orders.length, hasLoaded, fundOrdersLoadingState, orderId])

    if (fundOrdersLoadingState === 'error') {
        return <WeSlippedUp />
    }

    // If it's loading, or hasn't started loading
    if (fundOrdersLoadingState === 'loading' || !hasLoaded) {
        return <Loading isPineapple />
    }

    if (fundOrdersLoadingState !== 'ready') {
        assertNever(fundOrdersLoadingState)
    }

    // We've completed loading. If there's no results, banana or redirect
    if (orders.length === 0) {
        if (orderId) {
            return <WeSlippedUp />
        } else {
            // Don't navigate in a render, as this could get called multiple times. This case is handled in a useEffect
            return <Loading isPineapple />
        }
    }

    return <OrderCarousel orders={orders} carousel={!orderId} />
}

export default RecentOrders
