import {Button} from '@design-system/button'
import {Modal} from '@design-system/modal'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {Toast} from '~/global/widgets/toast/Toast'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {selectHasStaffBenefits} from '~/store/identity/selectors'
import {setModalError} from '~/store/notification'
import actions from '~/store/plan/actions'

interface Props {
    planName: string
    paymentAmount: string
    paymentDate: string
    paymentMethod: string
}

const PlanReactivateModal: React.FunctionComponent<Props> = ({planName, paymentAmount, paymentDate, paymentMethod}) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const dispatch = useAppDispatch()
    const hasStaffBenefits = useAppSelector(selectHasStaffBenefits)
    const {isDependent, preferredName: preferredName} = useAppSelector(s => ({
        isDependent: s.identity.user?.is_dependent,
        preferredName: s.identity.user?.preferred_name,
    }))

    const owners = isDependent ? `${preferredName}’s` : 'Your'
    const ownersLower = isDependent ? `${preferredName}’s` : 'your'

    const onReactivate = async () => {
        const error = await dispatch(actions.ReactivateCurrentPlan())
        if (error) {
            setModalError(error, 'We’re sorry, there’s been an error')
        } else {
            Toast(
                `It’s alive! ${owners} ${planName}${
                    hasStaffBenefits && ' (FREE for Sharesies staff)'
                } has been reactivated`,
            )
        }
    }

    return (
        <>
            <Button
                dataTestId="modal-reactivate-plan"
                label="Reactivate plan"
                onClick={() => setIsOpen(true)}
                type="secondary"
            />
            <Modal
                dataTestId="modal--confirm-reactivate-plan"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={`Reactivate ${ownersLower} plan?`}
                primaryButton={{label: 'Confirm', onClick: onReactivate}}
                secondaryButton={{label: 'Cancel'}}
                content={
                    hasStaffBenefits ? (
                        <p>Reactivate your free monthly subscription plan</p>
                    ) : (
                        <p className={spacing.spaceBelow16}>
                            <DollarValue
                                value={paymentAmount}
                                decimalPlaces={Number.isInteger(Number(paymentAmount)) ? 0 : 2}
                            />{' '}
                            will be deducted from {ownersLower} {paymentMethod} when {isDependent ? 'their' : 'your'}{' '}
                            plan renews on {paymentDate}.
                        </p>
                    )
                }
            />
        </>
    )
}

export default PlanReactivateModal
