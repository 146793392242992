import {TextInput, TextProps} from '@design-system/text-input'
import React from 'react'
import {withFocus, withId} from '../common'

export function normalizeIRDNumber(value: string, previousValue: string = ''): string {
    if (value) {
        if (value + ' ' === previousValue) {
            // User backspaced a space, we'll just remove the last character instead
            value = value.replace(/.$/, '')
        }

        if (value.length === 3 && (previousValue + ' ' === value || previousValue + '-' === value)) {
            value = '0' + value
            value = value.replace(/.$/, ' ')
        }

        // Replace unacceptable characters, including -
        value = value.replace(/\D/g, '')

        // Remove anything too long
        value = value.substring(0, 9)

        if (value.length === 8) {
            value = value.replace(/^(..)?(...)?(...)$/, (_match, first, second, third) => {
                return `${first} ${second} ${third}`
            })
        } else {
            // Put spaces back in the right places
            value = value.replace(/^(...)?(...)?(.*)/, (_match, first, second, remainder) => {
                let result = ''
                if (first) {
                    result += first + ' '
                }
                if (second) {
                    result += second + ' '
                }

                return result + remainder
            })
        }
    }

    return value
}

const IRDNumber = (props: TextProps) => (
    <TextInput
        {...props}
        onChange={e => {
            if (!props.setFieldValue) {
                return
            }
            props.setFieldValue(normalizeIRDNumber(e.currentTarget.value, props.value || ''))
        }}
        inputProps={({setFieldValue, value, setFieldTouched, name}) => ({
            onBlur: e => {
                if (!setFieldValue) {
                    return
                }

                if (e.currentTarget.value.match(/^\d{2}\s\d{3}\s\d{3}$/)) {
                    setFieldValue(normalizeIRDNumber('0' + e.currentTarget.value, value || ''))
                } else {
                    setFieldValue(normalizeIRDNumber(e.currentTarget.value, value || ''))
                }

                if (!setFieldTouched) {
                    return
                }

                setFieldTouched(name)
            },
        })}
        placeholder={props.placeholder || '000 000 000'}
    />
)

const IRDNumberWithFocus = withId(withFocus(IRDNumber))

export default IRDNumberWithFocus
