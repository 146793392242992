import Decimal from 'decimal.js' // Using Decimal library to match front-end calculation with the back-end calculation and rounding rules precisely

export const roundDownToTwoDecimalPlaces = (value: string) => (Math.floor(Number(`${value}e2`)) / 100).toFixed(2)
export const roundUpToTwoDecimalPlaces = (value: string) => (Math.ceil(parseFloat(value) * 100) / 100).toFixed(2)
export const parseRawBalance = (value: string) => parseFloat(value).toFixed(8)

/*
Redux store:
{
   exchange fee: 8 dp - round down
   other amount: 8 dp - round half up
}
Display:
{
   exchange fee: 5 dp - round half up
   other amount: 2 dp - round down
}
*/

export const calculateCurrencyExchangeFromSourceAmount = (
    sourceAmount: string,
    exchangeRate: number,
    feeRate: number,
) => {
    const decimalSourceAmount = new Decimal(sourceAmount)
    const decimalExchangeRate = new Decimal(exchangeRate)
    const decimalFeeRate = new Decimal(feeRate)

    // fee = gross - gross / (1 + FEE_RATE)
    const calculatedSourceFee = decimalSourceAmount.minus(decimalSourceAmount.div(new Decimal(1).plus(decimalFeeRate)))
    const netSourceAmount = decimalSourceAmount.minus(calculatedSourceFee)
    const targetAmount = netSourceAmount.mul(decimalExchangeRate)

    return {
        exchangeFee: calculatedSourceFee.toDP(8, Decimal.ROUND_DOWN).toString(),
        amountToExchange: netSourceAmount.toDP(8, Decimal.ROUND_HALF_UP).toString(),
        targetAmount: targetAmount.toDP(8, Decimal.ROUND_HALF_UP).toString(),
    }
}

export const calculateCurrencyExchangeFromTargetAmount = (
    targetAmount: string,
    exchangeRate: number,
    feeRate: number,
) => {
    const decimalTargetAmount = new Decimal(targetAmount)
    const decimalExchangeRate = new Decimal(exchangeRate)
    const decimalFeeRate = new Decimal(feeRate)

    // gross = targetAmount / exchangeRate * (1 + FEE_RATE)
    // fee = gross - gross / (1 + FEE_RATE)
    const sourceAmount = decimalTargetAmount.div(decimalExchangeRate).mul(new Decimal(1).plus(decimalFeeRate))
    const calculatedSourceFee = sourceAmount.minus(sourceAmount.div(new Decimal(1).plus(decimalFeeRate)))
    const netSourceAmount = sourceAmount.minus(calculatedSourceFee)

    return {
        sourceAmount: sourceAmount.toDP(8, Decimal.ROUND_HALF_UP).toString(),
        exchangeFee: calculatedSourceFee.toDP(8, Decimal.ROUND_DOWN).toString(),
        amountToExchange: netSourceAmount.toDP(8, Decimal.ROUND_HALF_UP).toString(),
    }
}
