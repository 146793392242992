import {ChevronRight} from '@design-system/icon'
import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {useNavigate} from 'react-router'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {convertToKebabCase} from '~/global/utils/convert-to-kebab-case/convertToKebabCase'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {SwitchField} from '~/global/widgets/form-controls'
import {DollarValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import {OrderConfirmation} from '~/global/widgets/order-confirmation/OrderConfirmation'
import {useDistillInstrumentHistoricVwap} from '~/sections/invest/sections/employee-share-scheme/data/distill'
import AlignmentRightsUnitName from '~/sections/invest/sections/view-instrument/sections/employee-share-scheme/widgets/alignment-rights-unit-name/AlignmentRightsUnitName'
import essActions from '~/store/employeeShareScheme/actions'
import {
    EmployeeShareSchemeAllocation,
    AlignmentRightAllocation,
    EmployeeShareSchemeParticipation,
} from '~/store/employeeShareScheme/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import styles from './UpcomingAllocations.scss'

interface Props {
    instrument: Instrument
    allocationsForInstrument: EmployeeShareSchemeAllocation
}

export const alignmentRightsUnvestedTotalsForInstrument = (
    instrument: Instrument,
    instrumentVwap: string,
    allocationsForInstrument: EmployeeShareSchemeAllocation,
    shareSchemeParticipation: EmployeeShareSchemeParticipation,
) => {
    const participationForInstrument = shareSchemeParticipation[instrument.id]

    const alignmentRightSchemeParticipation = participationForInstrument.find(
        participation => participation.fund_id === instrument.id && participation.scheme_type === 'ALIGNMENT_RIGHTS',
    )

    const coopUnitPrice = parseFloat(instrumentVwap)
    const farmUnitPrice = alignmentRightSchemeParticipation?.alternative_unit_price
        ? parseFloat(alignmentRightSchemeParticipation.alternative_unit_price)
        : 0

    // Total up the number of Coop and Farm units
    const unvestedAlignmentRightsTotals = allocationsForInstrument.alignment_right_allocations
        .filter(alloc => !!alloc.vesting_due_year)
        .reduce(
            (accumulator, allocation) => {
                accumulator[allocation.allocation_type!] =
                    (accumulator[allocation.allocation_type!] || 0) +
                    parseFloat(allocation.lines[0].number_of_alignment_rights)
                return accumulator
            },
            {} as {[key: string]: number},
        )

    const totalPotentialValue =
        unvestedAlignmentRightsTotals.COOP_UNIT * coopUnitPrice +
        unvestedAlignmentRightsTotals.FARM_UNIT * farmUnitPrice

    const vestedAlignmentRightsTotalValue = allocationsForInstrument.alignment_right_allocations
        .map(alloc => alloc.lines[0])
        .reduce((accumulator, line) => {
            return line.vesting_date ? accumulator + parseFloat(line.total_value) : accumulator
        }, 0 as number)

    return {
        totalPotentialValue,
        vestedAlignmentRightsTotalValue,
        unvestedAlignmentRightsTotals,
        coopUnitPrice,
        farmUnitPrice,
    }
}

const UpcomingAlignmentRightAllocations: React.FunctionComponent<Props> = ({instrument, allocationsForInstrument}) => {
    const dispatch = useAppDispatch()
    const showCurrentDollarValue = useAppSelector(s => s.employeeShareScheme.showAlignmentRightAllocationCurrentValue)

    const shareSchemeParticipation = useAppSelector(s => s.employeeShareScheme.shareSchemeParticipation)
    const participationForInstrument = shareSchemeParticipation[instrument.id]

    // Fetch the VWAP for the last 12 months
    const lastYear = DateTime.now().minus({years: 1}).startOf('day')
    const instrumentVwap = useDistillInstrumentHistoricVwap(
        instrument.id,
        lastYear,
        DateTime.now().startOf('day'),
    ).vwapPrice

    const alignmentRightSchemeParticipation = participationForInstrument.find(
        participation => participation.fund_id === instrument.id && participation.scheme_type === 'ALIGNMENT_RIGHTS',
    )

    const {totalPotentialValue, unvestedAlignmentRightsTotals, coopUnitPrice, farmUnitPrice} =
        alignmentRightsUnvestedTotalsForInstrument(
            instrument,
            instrumentVwap,
            allocationsForInstrument,
            shareSchemeParticipation,
        )

    // group the allocations by year
    const yearGroupedAllocations = allocationsForInstrument.alignment_right_allocations
        .filter(alloc => !!alloc.vesting_due_year)
        .sort((a, b) => parseFloat(a.vesting_due_year!) - parseFloat(b.vesting_due_year!))
        .reduce(
            function (groups, alloc) {
                ;(groups[alloc.vesting_due_year!] = groups[alloc.vesting_due_year!] || []).push(alloc)
                return groups
            },
            {} as {[key: string]: AlignmentRightAllocation[]},
        )

    return (
        <>
            <div className={spacing.spaceBelow8}>
                <OrderConfirmation
                    items={[
                        {
                            description: <div>Total Co-op Units</div>,
                            value: (
                                <span className={styles.orderConfirmationValue}>
                                    <ShareValue value={unvestedAlignmentRightsTotals.COOP_UNIT} />
                                </span>
                            ),
                        },
                        {
                            description: (
                                <div>
                                    Current VWAP FCG share price{' '}
                                    <ModalLink
                                        dataTestId="modal-link--total-number-of-alignment-rights"
                                        label="VWAP FCG share price"
                                        asIcon
                                        modalTitle="VWAP FCG share price"
                                        primaryButton={{label: 'Close'}}
                                        helpIconSize={16}
                                    >
                                        <p>
                                            This is the 12-month volume weighted average price of Fonterra Co-operative
                                            Group shares. This is an updated figure using the current FCG share price
                                            (delayed by 20 minutes).
                                        </p>
                                        <p>
                                            The price of a Co-operative share reflects our farmers’ views on the
                                            Co-operative’s ability to generate appropriate risk adjusted returns on its
                                            capital over the long term, and the capacity to distribute a portion of the
                                            returns as dividends.
                                        </p>
                                    </ModalLink>
                                </div>
                            ),
                            value: (
                                <span className={styles.orderConfirmationValue}>
                                    <DollarValue value={instrumentVwap} currency={instrument.currency} />
                                </span>
                            ),
                        },
                        {
                            description: <div>Total Farm Units</div>,
                            value: (
                                <span className={styles.orderConfirmationValue}>
                                    <ShareValue value={unvestedAlignmentRightsTotals.FARM_UNIT} />
                                </span>
                            ),
                        },
                        {
                            description: (
                                <>
                                    <div>
                                        Current Owner Operator Operating profit per/ha{' '}
                                        <ModalLink
                                            dataTestId="modal-link--total-number-of-alignment-rights"
                                            label="Operating profit per/ha"
                                            asIcon
                                            modalTitle="Operating profit per/ha"
                                            primaryButton={{label: 'Close'}}
                                            helpIconSize={16}
                                        >
                                            <p>
                                                The value of the Farm Unit goes up or down in line with the three year
                                                average Dairy Operating Profit per hectare over the relevant period.
                                            </p>
                                            <p>
                                                Dairy Operating Profit is a similar calculation to earnings before
                                                interest and tax (EBIT), and when expressed on a per hectare basis, it
                                                is one of the most important financial performance measures used in the
                                                dairy sector.
                                            </p>
                                            <p>
                                                Dairy Operating Profit is calculated as Gross Farm Revenue Less
                                                Operating Expenses. This figure is updated annually from the DairyNZ
                                                Economic Survey.
                                            </p>
                                        </ModalLink>
                                    </div>
                                    {alignmentRightSchemeParticipation?.alternative_unit_price_updated_at && (
                                        <div className={styles.lastUpdated}>
                                            Updated{' '}
                                            {alignmentRightSchemeParticipation?.alternative_unit_price_updated_at?.toFormat(
                                                'MMMM yyyy',
                                            )}
                                        </div>
                                    )}
                                </>
                            ),
                            value: (
                                <span className={styles.orderConfirmationValue}>
                                    <DollarValue
                                        value={alignmentRightSchemeParticipation?.alternative_unit_price || 0}
                                        currency={instrument.currency}
                                    />
                                </span>
                            ),
                        },
                    ]}
                    total={{
                        description: 'Estimated total potential value',
                        value: <DollarValue value={totalPotentialValue} currency={instrument.currency} />,
                    }}
                />
            </div>

            <div className={styles.timelineWrapper}>
                <h2 className={styles.timeline}>Timeline</h2>
                <SwitchField
                    additionalClassName={spacing.spaceAbove4}
                    dataTestId="switch--potential-value"
                    label="Show potential dollar value"
                    name="show potential dollar value"
                    onChange={() => dispatch(essActions.ToggleShowAlignmentRightAllocationCurrentValue())}
                    value={showCurrentDollarValue}
                    isTouched={false}
                />

                {Object.entries(yearGroupedAllocations).map(([year, allocations]) => {
                    // TODO - ECM-324 needs to support having more than one allocation line
                    // Show Co-op units before other allocations
                    const orderedAllocations = allocations.sort((a, _b) => (a.allocation_type === 'COOP_UNIT' ? -1 : 1))

                    return (
                        <React.Fragment key={year}>
                            <h2
                                data-testid={`heading--vesting-in-${year}`}
                                className={cn(styles.vestingInHeading, spacing.spaceAbove24)}
                            >
                                Vesting in {year}
                            </h2>
                            {orderedAllocations.map(allocation => {
                                return (
                                    <UpcomingAlignmentRightAllocationLine
                                        key={allocation.id}
                                        allocation={allocation}
                                        instrument={instrument}
                                        showCurrentDollarValue={showCurrentDollarValue}
                                        coopUnitPrice={coopUnitPrice}
                                        farmUnitPrice={farmUnitPrice}
                                    />
                                )
                            })}
                        </React.Fragment>
                    )
                })}
            </div>
        </>
    )
}

interface UpcomingAlignmentRightAllocationLineProps {
    allocation: AlignmentRightAllocation
    instrument: Instrument
    showCurrentDollarValue: boolean
    coopUnitPrice: number
    farmUnitPrice: number
}

const UpcomingAlignmentRightAllocationLine = React.forwardRef(
    (
        {
            allocation,
            instrument,
            showCurrentDollarValue,
            coopUnitPrice,
            farmUnitPrice,
        }: UpcomingAlignmentRightAllocationLineProps,
        ref: React.ForwardedRef<HTMLButtonElement>,
    ) => {
        const navigate = useNavigate()
        const profileUrl = useProfileUrl()
        const vestingDueDate = allocation.lines[0].vesting_due_date
        const allocationLineId = allocation.lines[0].id
        const alignmentRightsTotal = allocation.lines
            .map(line => line.number_of_alignment_rights)
            .reduce((total, current) => total + parseFloat(current))

        const getValue = () => {
            if (showCurrentDollarValue) {
                if (allocation.allocation_type === 'COOP_UNIT') {
                    return parseFloat(alignmentRightsTotal) * coopUnitPrice
                } else {
                    return parseFloat(alignmentRightsTotal) * farmUnitPrice
                }
            }
            return alignmentRightsTotal
        }

        return (
            <button
                data-testid={`allocation--${convertToKebabCase(allocation.group_name.toLowerCase())}`}
                ref={ref}
                className={cn(styles.upcomingAllocationCard, spacing.spaceAbove8)}
                onClick={() => {
                    rudderTrack('ess_allocation', 'allocation_clicked', {
                        allocation_type: 'allocated',
                    })
                    navigate(
                        profileUrl('employee-share-scheme/allocation/:instrumentId/:allocationLineId', {
                            instrumentId: instrument.id,
                            allocationLineId,
                        }),
                    )
                }}
                type="button"
            >
                <div className={styles.iconAndTitle}>
                    <h4 className={cn(styles.title, spacing.spaceBelow8)}>{allocation.group_name}</h4>
                    <ChevronRight className={styles.iconRight} size={12} />
                </div>
                <div className={styles.descriptionAndAmount}>
                    <p>Vests on {vestingDueDate.toFormat(dateFormatNoTime)}</p>
                    <p>
                        {showCurrentDollarValue && <DollarValue value={getValue()} currency={instrument.currency} />}
                        {!showCurrentDollarValue && (
                            <>
                                <ShareValue value={getValue()} /> <AlignmentRightsUnitName allocation={allocation} />
                            </>
                        )}
                    </p>
                </div>
            </button>
        )
    },
)

export default UpcomingAlignmentRightAllocations
