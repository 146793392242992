import React from 'react'
import {ListingResponseDto} from '~/api/distill'
import {DistillScope} from '~/api/query/distill'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import useAssetManager from '~/global/utils/use-asset-manager/useAssetManager'
import Concertina from '~/global/widgets/concertina/Concertina'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {InstrumentReturn} from '~/store/portfolio/types'
import styles from './Details.scss'

const TotalReturnDetail = ({
    currentReturn,
    instrument,
    currency,
}: {
    currentReturn: InstrumentReturn
    instrument: ListingResponseDto
    currency: string
}) => {
    const assetManager = useAssetManager(instrument, {
        scope: DistillScope.KIWISAVER_ALL_FUNDS,
        searchFundInvestments: true,
    })

    return (
        <div>
            <h1>Return</h1>
            <p>
                Your return is a measure of the performance of your investment in {instrument.name}. Here it’s
                calculated on units you currently own.
            </p>

            {assetManager && (
                <p>
                    This doesn’t include management fees charged by {assetManager.name} and the funds that are included
                    in the unit price.
                </p>
            )}

            <Concertina
                title={
                    <div className={styles.concertinaTitle}>
                        <p className={styles.leftText}>Unrealised gains/losses</p>{' '}
                        <DollarValue
                            value={currentReturn.total_return_detail.unrealised_capital_gains}
                            currency={currency}
                        />
                    </div>
                }
                body={
                    <p className={styles.concertinaBody}>
                        The change in value of your units. This is based on the difference between the price you paid
                        and the current unit price (delayed by at least one day).
                    </p>
                }
                border
            />

            {tradingType(instrument) === 'managed' && assetManager && (
                <Concertina
                    title={
                        <div className={styles.concertinaTitle}>
                            <p className={styles.leftText}>Managed fund transaction fees</p>{' '}
                            <DollarValue
                                value={-currentReturn.amount_put_in_detail.unrealised_managed_fund_transaction_fees}
                                currency={currency}
                            />
                        </div>
                    }
                    body={
                        <p className={styles.concertinaBody}>
                            The amount you’ve paid in transaction fees when buying units. These are charged by the fund,
                            not Sharesies. Transaction fees are estimates until they’re confirmed by {assetManager.name}{' '}
                            – this can take up to 30 days.
                        </p>
                    }
                    border
                />
            )}

            {tradingType(instrument) !== 'managed' && (
                <Concertina
                    title={
                        <div className={styles.concertinaTitle}>
                            <p className={styles.leftText}>Transaction fees</p>{' '}
                            <DollarValue
                                value={-currentReturn.amount_put_in_detail.transaction_fees_buys}
                                currency={currency}
                            />
                        </div>
                    }
                    body={
                        <p className={styles.concertinaBody}>
                            The amount you’ve paid in transaction fees when buying units.
                        </p>
                    }
                    border
                />
            )}

            {tradingType(instrument) !== 'managed' && (
                <Concertina
                    title={
                        <div className={styles.concertinaTitle}>
                            <p className={styles.leftText}>Administration fees</p>{' '}
                            <DollarValue
                                value={-currentReturn.amount_put_in_detail.unrealised_managed_fund_transaction_fees}
                                currency={currency}
                            />
                        </div>
                    }
                    body={
                        <p className={styles.concertinaBody}>
                            The amount you’ve paid in administration fees when holding units.
                        </p>
                    }
                    border
                />
            )}

            <Concertina
                title={
                    <div className={styles.concertinaTitle}>
                        <p className={styles.leftText}>Dividends</p>{' '}
                        <DollarValue value={currentReturn.unrealised_dividends} currency={currency} />
                    </div>
                }
                body={
                    <p className={styles.concertinaBody}>
                        The amount of dividends, interest and distributions you've received.
                    </p>
                }
                border
            />

            {tradingType(instrument) === 'managed' && (
                <Concertina
                    title={
                        <div className={styles.concertinaTitle}>
                            <p className={styles.leftText}>PIE tax</p>{' '}
                            <DollarValue
                                value={-currentReturn.total_return_detail.unrealised_tax_paid}
                                currency={currency}
                            />
                        </div>
                    }
                    body={
                        <p className={styles.concertinaBody}>
                            The amount you’ve paid in portfolio investment entity (PIE) tax. Tax is deducted when you
                            sell units, and at the end of the financial year (31 March). If you’ve received a tax
                            refund, this may be a positive amount.
                        </p>
                    }
                    border
                />
            )}

            <Concertina
                title={
                    <div className={styles.concertinaTitle}>
                        <p className={styles.leftText}>Return</p>{' '}
                        <p className={styles.lastRightText}>
                            <DollarValue value={currentReturn.unrealised_total_return} currency={currency} />
                        </p>
                    </div>
                }
                border
                noChevron
            />
        </div>
    )
}

export default TotalReturnDetail
