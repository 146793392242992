import {Model} from '~/api/retail/types'
import {PortfolioFilterOptions} from '~/store/identity/types'
import {PortfolioBreakdown} from '~/store/portfolio/types'

export interface LabelToPercentageAndValue {
    label: string
    percentage: number
    value: number
}

interface SubtitleRow {
    subtitle: string
    percentage: number
    value: number
}

export interface CompletePortfolioBreakdown {
    title: string
    subtitles: SubtitleRow[]
}

const sortByPercentage = (labelToPercentage: LabelToPercentageAndValue[]): LabelToPercentageAndValue[] => {
    // generic function for first sorting by percentages/values and then defaulting to alphabetical order
    // in the circumstance two obj's percentages are the same.
    labelToPercentage.sort((a, b) => {
        // sort by name if percentages are equal.
        if (a.percentage === b.percentage) {
            return a.label.localeCompare(b.label)
        }
        return b.percentage - a.percentage
    })
    return labelToPercentage
}

const lessThanOnePercentSubtitle = (breakdownTitleType: string, value: number) => {
    return value === 1 ? `Less than 1% ${breakdownTitleType}` : `${value}% ${breakdownTitleType}`
}

export const percentAndValueBreakdown = (
    breakdown: PortfolioBreakdown,
    filter: PortfolioFilterOptions,
    jurisdiction: Model.User['jurisdiction'],
): LabelToPercentageAndValue[] => {
    switch (filter) {
        case 'COUNTRY':
            const countryLabelToPercentage: LabelToPercentageAndValue[] = [
                {label: 'New Zealand', percentage: breakdown.nzl.percent!, value: breakdown.nzl.value!},
                {label: 'Australia', percentage: breakdown.aus.percent!, value: breakdown.aus.value!},
                {label: 'United States', percentage: breakdown.usa.percent!, value: breakdown.usa.value!},
            ]
            return sortByPercentage(countryLabelToPercentage)
        case 'TYPE':
            // TODO: test
            const typeLabelToPercentage: LabelToPercentageAndValue[] = [
                {label: 'companies', percentage: breakdown.company.percent!, value: breakdown.company.value!},
                {label: 'ETFs', percentage: breakdown.etf.percent!, value: breakdown.etf.value!},
            ]
            if (jurisdiction === 'nz') {
                typeLabelToPercentage.push({
                    label: 'managed funds',
                    percentage: breakdown.mutual.percent!,
                    value: breakdown.mutual.value!,
                })
            }
            return sortByPercentage(typeLabelToPercentage)
        default:
            // Default filter preference at database level is 'RISK'
            return [
                {label: 'lower risk', percentage: breakdown.lower.percent!, value: breakdown.lower.value!},
                {label: 'medium risk', percentage: breakdown.medium.percent!, value: breakdown.medium.value!},
                {label: 'higher risk', percentage: breakdown.higher.percent!, value: breakdown.higher.value!},
            ]
    }
}

export const overallBreakdown = (
    breakdown: PortfolioBreakdown,
    filter: PortfolioFilterOptions,
    jurisdiction: Model.User['jurisdiction'],
    preferredName?: string,
) => {
    const percentAndValues = percentAndValueBreakdown(breakdown, filter, jurisdiction)
    const breakdownSubtitles: SubtitleRow[] = percentAndValues.map((x): SubtitleRow => {
        return {
            subtitle: lessThanOnePercentSubtitle(x.label, x.percentage),
            percentage: x.percentage,
            value: x.value,
        }
    })

    // Note: risk is the only filter where we do not display the first breakdown subtitle as the title; here, we always want the subtitle order to be
    // lower, medium, higher, BUT we want the title to show the largest % risk breakdown, hence why we are resorting the values in order of highest -> lowest percentage.
    const breakdownSummary: CompletePortfolioBreakdown = {
        title: breakdownTitle(
            filter,
            filter === 'RISK' ? sortByPercentage(percentAndValues)[0] : percentAndValues[0],
            preferredName,
        ),
        subtitles: breakdownSubtitles,
    }

    return breakdownSummary
}

const breakdownTitle = (
    filter: PortfolioFilterOptions,
    largestValue: LabelToPercentageAndValue,
    preferredName?: string,
): string => {
    const countryPrefix = largestValue.label === 'United States' ? 'listed in the' : 'listed in'
    return `${largestValue.percentage}% of ${preferredName ? `${preferredName}'s` : 'your'} investments are ${
        filter === 'COUNTRY' ? countryPrefix : ''
    } ${filter === 'COUNTRY' || largestValue.label === 'ETFs' ? largestValue.label : largestValue.label.toLowerCase()}`
}
