import {DateTime} from 'luxon'
import {IDENTITY_VERIFICATION_INTRO_SHOW_AFTER} from '~/global/constants/global'

export type IdentityVerificationStep =
    | 'verificationIntro'
    | 'skipIntro'
    | 'verificationIdSelection'
    | 'confirmIdentityDetails'
    | 'confirmDetails'
    | 'completed'
    | 'verificationFails'
    | 'ignored'
    | null

// if the user has seen the intro and skipped it, they should have LocalStorage value
// with the date and time of when we should show the intro again
// return a boolean value to indicate whether the user should be shown
// the beginning of the verification requried flow

export const showVerificationRequiredFlowIntro = (): boolean => {
    const lastSeen = window.localStorage.getItem(IDENTITY_VERIFICATION_INTRO_SHOW_AFTER)
    return lastSeen ? Date.parse(lastSeen) < DateTime.local().toMillis() : true
}
