import cn from 'classnames'
import React from 'react'
import styles from './Page.scss'

export interface PageProps {
    className?: string
    withoutDefaultPadding?: boolean
    topPaddingOnly?: boolean
    overrideDefaultTopPadding?: 'none' | 'withToolbarTitle'
    children: React.ReactNode
    dataTestId?: string
}

const Page: React.FunctionComponent<PageProps> = ({
    className,
    withoutDefaultPadding,
    topPaddingOnly,
    overrideDefaultTopPadding,
    children,
    dataTestId,
}) => {
    return (
        <div
            className={cn(styles.container, className, {
                [styles.withoutDefaultPadding]: withoutDefaultPadding,
                [styles.topPaddingOnly]: topPaddingOnly,
                [styles.noTopPadding]: overrideDefaultTopPadding === 'none',
                [styles.withToolbarTitle]: overrideDefaultTopPadding === 'withToolbarTitle',
            })}
            data-testid={dataTestId}
        >
            {children}
        </div>
    )
}

export default Page
