import {withFormik} from 'formik'
import {DateTime} from 'luxon'
import React from 'react'
import {Request, Response} from '~/api/retail/types'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {IdentityVerificationStep} from '~/global/utils/identity-verification/identityVerification'
import {SharesiesOmit} from '~/global/utils/type-utilities/typeUtilities'
import {validate} from '~/global/widgets/form-controls'
import {Text, DateInput, Select} from '~/global/widgets/form-controls/formik'
import {
    CommonIdFormProps,
    consentMessage,
    CommonFooter,
    CommonHeader,
} from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/IdentityFormCommon'

type AUMedicareCardFormProps = CommonIdFormProps & {
    verifyAustralianMedicareCard(
        payload: SharesiesOmit<Request.IdentityVerifiAustralianMedicareCard, Request.ActingAsRequired>,
    ): Promise<null | Response.Error>
}

type AUMedicareCardFormValues = SharesiesOmit<
    Request.IdentityVerifiAustralianMedicareCard,
    Request.ActingAsRequired & {
        medicare_card_type: string
        medicare_card_expiry: string
        consent: true
    }
> & {medicare_card_type: string; medicare_card_expiry?: DateTime; consent: boolean}

export const AUMedicareCardForm = withFormik<AUMedicareCardFormProps, AUMedicareCardFormValues>({
    enableReinitialize: true,
    mapPropsToValues: () => ({
        medicare_card_type: '',
        medicare_card_number: '',
        medicare_card_individual_number: '',
        medicare_card_name_on_card: '',
        medicare_card_expiry: undefined,
        consent: false,
    }),
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        medicare_card_type: undefined,
    }),
    handleSubmit: async (
        values,
        {setSubmitting, setStatus, props: {verifyAustralianMedicareCard, setIDDuplicateError, setStep}},
    ) => {
        try {
            const error = await verifyAustralianMedicareCard({
                ...values,
                consent: values.consent as true,
                // verifyAustralianMedicareCard needs strings of the dates
                medicare_card_expiry: values.medicare_card_expiry ? values.medicare_card_expiry.toISODate() : '',
                medicare_card_type:
                    values.medicare_card_type as Request.IdentityVerifiAustralianMedicareCard['medicare_card_type'],
            })
            if (error) {
                if (error.code === 'duplicate_id') {
                    setIDDuplicateError(true)
                    setStatus(undefined)
                } else {
                    setIDDuplicateError(false)
                    setStatus(error.message)
                }
                setSubmitting(false)
                return
            } else {
                setStep('completed' as IdentityVerificationStep)
            }
        } catch (e) {
            setStatus(unknownErrorMessage)
            setSubmitting(false)
            throw e
        }
    },
    validate: validate.generate<AUMedicareCardFormValues>({
        medicare_card_type: [validate.required()],
        medicare_card_number: [
            validate.required(),
            validate.regexp(/^\d{10}$/, 'The card number should have 10 numeric digits'),
        ],
        medicare_card_individual_number: [
            validate.required(),
            validate.regexp(/^\d{1}$/, 'The individual number should be a single digit'),
        ],
        medicare_card_name_on_card: [validate.required()],
        medicare_card_expiry: [validate.required(), validate.date()],
        consent: [validate.isTrue(consentMessage)],
    }),
})(({
    handleSubmit,
    status,
    isValid,
    isSubmitting,
    values,
    isDependent,
    preferredName,
    databases,
    isSecondaryId,
    setFieldValue,
}) => {
    return (
        <form onSubmit={handleSubmit}>
            <CommonHeader
                setFieldValue={setFieldValue}
                isSecondaryId={isSecondaryId}
                identityType="Australian medicare card"
            />
            <Select
                dataTestId="select--medicare-card-type"
                name="medicare_card_type"
                label="Card type"
                choices={[{value: '', label: ''}, ...cardTypeChoices]}
            />
            <Text dataTestId="text-input--card-number" name="medicare_card_number" label="Card number" />
            <Text
                dataTestId="text-input--irn"
                name="medicare_card_individual_number"
                label="Individual reference number (IRN)"
            />
            <Text dataTestId="text-input--name-on-card" name="medicare_card_name_on_card" label="Name on Card" />
            {
                // green medicare cards have an expiry date consisting of only a month and year
                values.medicare_card_type === 'G' ? (
                    <DateInput
                        dataTestId="date-input--expiry-date"
                        name="medicare_card_expiry"
                        label="Expiry date"
                        handleDateChange={setFieldValue}
                        monthYearOnly
                    />
                ) : (
                    <DateInput
                        dataTestId="date-input--expiry-date"
                        name="medicare_card_expiry"
                        label="Expiry date"
                        handleDateChange={setFieldValue}
                    />
                )
            }
            <CommonFooter
                isDependent={isDependent}
                preferredName={preferredName}
                isSubmitting={isSubmitting}
                databases={databases}
                isValid={isValid}
                jurisdiction="au"
                error={status}
                isSecondaryId={isSecondaryId}
            />
        </form>
    )
})

interface MedicareCardType {
    value: Request.IdentityVerifiAustralianMedicareCard['medicare_card_type']
    label: string
}

const cardTypeChoices: MedicareCardType[] = [
    {value: 'B', label: 'Blue'},
    {value: 'G', label: 'Green'},
    {value: 'Y', label: 'Yellow'},
]
