import rawCreateCachedSelector, {
    ParametricSelector as RawParametricSelector,
    OutputParametricCachedSelector as RawOutputParametricCachedSelector,
} from 're-reselect'
import {createSelector as rawCreateSelector, Selector as RawSelector} from 'reselect'
import {RootState} from './types'

// reselect typing
export type Selector<R> = RawSelector<RootState, R>

interface CreateSelector {
    <R, T1>(s1: Selector<T1>, r: (v1: T1) => R): Selector<R>

    <R, T1, T2>(s1: Selector<T1>, s2: Selector<T2>, r: (v1: T1, v2: T2) => R): Selector<R>

    <R, T1, T2, T3>(s1: Selector<T1>, s2: Selector<T2>, s3: Selector<T3>, r: (v1: T1, v2: T2, v3: T3) => R): Selector<R>

    <R, T1, T2, T3, T4>(
        s1: Selector<T1>,
        s2: Selector<T2>,
        s3: Selector<T3>,
        s4: Selector<T4>,
        r: (v1: T1, v2: T2, v3: T3, v4: T4) => R,
    ): Selector<R>

    <R, T1, T2, T3, T4, T5>(
        s1: Selector<T1>,
        s2: Selector<T2>,
        s3: Selector<T3>,
        s4: Selector<T4>,
        s5: Selector<T5>,
        r: (v1: T1, v2: T2, v3: T3, v4: T4, v5: T5) => R,
    ): Selector<R>

    <R, T1, T2, T3, T4, T5, T6>(
        s1: Selector<T1>,
        s2: Selector<T2>,
        s3: Selector<T3>,
        s4: Selector<T4>,
        s5: Selector<T5>,
        s6: Selector<T6>,
        r: (v1: T1, v2: T2, v3: T3, v4: T4, v5: T5, v6: T6) => R,
    ): Selector<R>

    <R, T1, T2, T3, T4, T5, T6, T7>(
        s1: Selector<T1>,
        s2: Selector<T2>,
        s3: Selector<T3>,
        s4: Selector<T4>,
        s5: Selector<T5>,
        s6: Selector<T6>,
        s7: Selector<T7>,
        r: (v1: T1, v2: T2, v3: T3, v4: T4, v5: T5, v6: T6, v7: T7) => R,
    ): Selector<R>
}

export const createSelector: CreateSelector = rawCreateSelector as any // Typescript 4.9 was causing an issue with the types. Rather that retyping anything, as we are moving away from redux, we just have an as any here as a stopgap. Our typing still works when using createSelector

// re-reselect typing
type ParametricSelector<P, R> = RawParametricSelector<RootState, P, R>
type OutputParametricCachedSelector<P, T, C> = RawOutputParametricCachedSelector<RootState, P, T, C, unknown>

interface CreateCachedSelector {
    <P, R1, T>(s1: ParametricSelector<P, R1>, r: (r1: R1) => T): OutputParametricCachedSelector<P, T, (r1: R1) => T>

    <P, R1, R2, T>(
        s1: ParametricSelector<P, R1>,
        s2: ParametricSelector<P, R2>,
        r: (r1: R1, r2: R2) => T,
    ): OutputParametricCachedSelector<P, T, (r1: R1, r2: R2) => T>

    <P, R1, R2, R3, T>(
        s1: ParametricSelector<P, R1>,
        s2: ParametricSelector<P, R2>,
        s3: ParametricSelector<P, R3>,
        r: (r1: R1, r2: R2, r3: R3) => T,
    ): OutputParametricCachedSelector<P, T, (r1: R1, r2: R2, r3: R3) => T>

    <P, R1, R2, R3, R4, T>(
        s1: ParametricSelector<P, R1>,
        s2: ParametricSelector<P, R2>,
        s3: ParametricSelector<P, R3>,
        s4: ParametricSelector<P, R4>,
        r: (r1: R1, r2: R2, r3: R3, r4: R4) => T,
    ): OutputParametricCachedSelector<P, T, (r1: R1, r2: R2, r3: R3, r4: R4) => T>
}

export const createCachedSelector: CreateCachedSelector = rawCreateCachedSelector
