import {Button} from '@design-system/button'
import React from 'react'
import emailDark from '~/global/assets/images/envelope/envelope-dark.svg'
import emailLight from '~/global/assets/images/envelope/envelope-light.svg'
import Image from '~/global/widgets/image/Image'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {Link} from '~/migrate-react-router'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/identity/actions'
import UpdateEmail from './UpdateEmail'
import styles from './EmailVerification.scss'

const EmailVerification: React.FunctionComponent<{}> = () => {
    const dispatch = useAppDispatch()
    const [resendDisabled, setResendDisabled] = React.useState<boolean>(false)
    const [showEmailUpdate, setShowEmailUpdate] = React.useState<boolean>(false)

    const [email] = useAppSelector(({identity}) => [identity.user!.email || ''])

    // Run once on component load
    React.useEffect(() => {
        dispatch(actions.VerifyEmail())
    }, [])

    const handleClick = () => {
        // Temporarily disabled button on click to prevent spam
        setResendDisabled(true)
        setTimeout(() => {
            setResendDisabled(false)
        }, 5000)

        if (!resendDisabled) {
            dispatch(actions.VerifyEmail())
            Toast('Nice! Now hit the verification link we just emailed you to verify your email address.')
        }
    }

    if (showEmailUpdate) {
        return <UpdateEmail setShowEmailUpdate={setShowEmailUpdate} />
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--sign-up-email-verification" />
            <Page className={styles.container}>
                <Image src={emailLight} darkSrc={emailDark} />
                <h1>You've got mail!</h1>
                <p>To verify your email address, hit the verification link we're emailing to {email}</p>
                <strong>Wrong email address?</strong>
                <Link
                    to="#"
                    onClick={() => {
                        setShowEmailUpdate(true)
                    }}
                    data-test-id="link--email-verification-change-email"
                >
                    Update it now
                </Link>
                <p>
                    Didn't get an email?{' '}
                    <Link to="#" onClick={handleClick} data-test-id="link--email-verification-resend">
                        Resend it
                    </Link>
                </p>
                <Button
                    type="primary"
                    label="Continue"
                    pageButton
                    dataTestId="button--email-verification-next"
                    onClick={() => {
                        dispatch(actions.Check())
                    }}
                />
            </Page>
        </>
    )
}

export default EmailVerification
