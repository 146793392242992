import {Model} from '~/api/retail/types'

/*
Note that all of these types below are primarily to work around this issue:

https://github.com/microsoft/TypeScript/issues/18758

Broadly we're copying `transaction.detail.type` up to `transaction.detailType` so that type narrowing works nicely
in the code below.
*/

/**
 * A TransactionV2 with no detail on it
 */
type TransactionNoDetail = Omit<Model.TransactionV2, 'detail'> & {detailType: undefined; detail: undefined}

/**
 * A map of detail.type to a TransactionV2 with a detailType field and detail matching that type
 */
export type TransactionDetailTypes = {
    [T in NonNullable<Model.TransactionV2['detail']>['type']]: Omit<Model.TransactionV2, 'detail'> & {
        detailType: T
        detail: Extract<Model.TransactionV2['detail'], {type: T}>
    }
}

/**
 * A union of all of the above suitable for switching on to control rendering
 */
type TransactionWithDetail = TransactionNoDetail | TransactionDetailTypes[keyof TransactionDetailTypes]

export function transactionToTransactionWithDetail(t: Model.TransactionV2): TransactionWithDetail {
    // This is the only place we need to have type coercion, and we can do it because we know that
    // TransactionWithDetail always has obj.detailType === obj.detail?.type
    return {...t, detailType: t.detail?.type} as TransactionWithDetail
}
