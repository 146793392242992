import {ChevronUp, ChevronDown} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {useCurrentSubscriptionPlan} from '~/global/utils/subscription-hooks/subscriptionHooks'
import {subscriptionName} from '~/global/utils/subscription-name/subscriptionName'
import {useAppSelector} from '~/store/hooks'
import styles from './OrderConfirmation.scss'
export interface OrderRow {
    description: string | React.ReactNode
    value?: string | React.ReactNode
    borderBottom?: boolean
    isBold?: boolean
    explainerText?: string | React.ReactNode
}

type FeeCoverage = 'full' | 'partial' | 'maybe'

interface CoverageTextProps {
    feeCoverage: FeeCoverage
}

export const CoverageText: React.FunctionComponent<CoverageTextProps> = ({feeCoverage}) => {
    const [currentSubscription, currentSubscriptionLoaded] = useCurrentSubscriptionPlan()

    const {preferredName, isDependent} = useAppSelector(s => ({
        isDependent: s.identity.user!.is_dependent,
        preferredName: s.identity.user!.preferred_name,
    }))

    if (!currentSubscription || !currentSubscriptionLoaded) {
        // We shouldn't be thinking about coverage if you don't have a subscription.
        // If it hasn't loaded yet, it will pop in when it does.
        // This would be a perfect candidate for tanstack query
        return null
    }

    const endText = `${isDependent ? `${preferredName}'s` : 'your'} ${subscriptionName(currentSubscription.plan)}`

    switch (feeCoverage) {
        case 'maybe':
            return <>All (or some) of this transaction fee may be covered by {endText}</>
        case 'full':
            return <>Transaction fees are covered by {endText} </>
        case 'partial':
            return <>Transaction fees are partially covered by {endText}</>
        default:
            assertNever(feeCoverage)
            return null
    }
}

interface OrderConfirmationProps {
    title?: string
    subtitle?: string | React.ReactNode
    image?: React.ReactNode
    dropdown?: {
        text: string
        content: OrderRow[]
    }
    items: OrderRow[]
    total?: OrderRow
    exchange?: React.ReactNode
}

export const OrderConfirmation: React.FunctionComponent<OrderConfirmationProps> = ({
    title,
    subtitle,
    image,
    dropdown,
    items,
    total,
    exchange,
}) => {
    const [showDropdown, toggleDropdown] = React.useState(false)

    return (
        <div className={styles.orderContainer}>
            {title && (
                <>
                    <div className={styles.header}>
                        {image && <div className={styles.imageContainer}>{image}</div>}
                        <div className={styles.titleWrapper}>
                            <div className={styles.titleContainer}>
                                <h2>{title}</h2>
                                {subtitle && <span>{subtitle}</span>}
                                {!subtitle && dropdown && (
                                    <span
                                        className={styles.dropdownHeader}
                                        onClick={() => toggleDropdown(!showDropdown)}
                                    >
                                        {dropdown.text} {showDropdown ? <ChevronUp /> : <ChevronDown />}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    {dropdown && showDropdown && (
                        <div className={styles.dropdown}>
                            {dropdown?.content.map((item, i) => (
                                <div key={i} className={styles.orderRow}>
                                    <span className={styles.description}>{item.description}</span>
                                    <span>{item.value}</span>
                                </div>
                            ))}
                        </div>
                    )}
                    <hr />
                </>
            )}
            {items.map((item, i) => (
                <div key={i}>
                    <div>
                        <div className={styles.orderRow}>
                            <span className={styles.description}>{item.description}</span>
                            <span
                                className={cn({
                                    [styles.boldItem]: item.value === 'Wallet' || item.isBold,
                                })}
                            >
                                {item.value}
                            </span>
                        </div>
                        {item.borderBottom && <hr className={styles.hrBorderBottom} />}
                    </div>
                    {item.explainerText && <div className={styles.orderRowExplainer}>{item.explainerText}</div>}
                </div>
            ))}
            {total && (
                <div className={styles.orderTotal}>
                    <span>{total.description}</span>
                    <span>{total.value}</span>
                </div>
            )}
            {exchange && <div className={styles.exchange}>{exchange}</div>}
        </div>
    )
}

export default OrderConfirmation
