import {Modal} from '@design-system/modal'
import React from 'react'
import {FARM_SOURCE_HREF} from '~/sections/moose/constants/constants'
import {FarmerSupportNumber} from '~/sections/moose/widgets/farmer-support-number/FarmerSupportNumber'

interface IncorrectDetailsModalProps {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    isIncorrectPhone?: boolean
}

const IncorrectDetailsModal = ({isOpen, setIsOpen, isIncorrectPhone}: IncorrectDetailsModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={() => setIsOpen(false)}
            title="Something’s not right"
            dataTestId="modal--fonterra-incorrect-details"
            primaryButton={{label: 'OK', onClick: () => setIsOpen(false)}}
        >
            {isIncorrectPhone && (
                <p>
                    Update your phone number on the{' '}
                    <a href={FARM_SOURCE_HREF} target="_blank" rel="noreferrer">
                        Farm Source website
                    </a>
                    , or call the Farmer Support team on <FarmerSupportNumber />.
                </p>
            )}

            {!isIncorrectPhone && (
                <p>
                    To correct your details, call the Farmer Support team on <FarmerSupportNumber />.
                </p>
            )}

            <p>Once your details are sorted, you’ll be sent another link to continue sign-up.</p>
        </Modal>
    )
}

export default IncorrectDetailsModal
