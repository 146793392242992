import React from 'react'
import {ArrayElement} from '~/global/utils/type-utilities/typeUtilities'
import {useLocation, useNavigate} from '~/migrate-react-router'

// The bank linking flow is quite complex, with multiple entrypoints ('bank-accounts-cards', 'roundups', 'wallet') and different flows for each.
// This state machine is a wrapper for typechecking,
// and keeping entrypoint & route navigation logic relatively tidy.
export const RoutingStateMachine = <T extends string, Steps extends T[]>({
    steps,
    currentStep,
    entrypoint,
    children,
    getUrlForStep,
    trackStepChange,
}: {
    steps: Steps
    currentStep?: ArrayElement<typeof steps>
    entrypoint: () => ArrayElement<typeof steps>
    getUrlForStep: (newStep: ArrayElement<typeof steps>) => string
    trackStepChange?: (prevStep?: ArrayElement<typeof steps>, nextStep?: ArrayElement<typeof steps>) => void
    children: (step: ArrayElement<typeof steps>, setStep: (step: ArrayElement<typeof steps>) => void) => JSX.Element
}): JSX.Element => {
    const navigate = useNavigate()
    const {pathname} = useLocation()

    const setStep = (step: ArrayElement<typeof steps>, replace = false) => {
        const url = getUrlForStep(step)
        if (url === pathname) {
            return
        }
        if (trackStepChange) {
            trackStepChange(currentStep!, step)
        }
        navigate(url, {replace})
    }

    React.useEffect(() => {
        if (!currentStep || !steps.includes(currentStep)) {
            setStep(entrypoint(), true)
        }
    }, [currentStep])

    if (!currentStep) {
        return <></>
    }

    return children(currentStep, setStep)
}
