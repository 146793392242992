import React from 'react'
import {useRetailGet} from '~/api/query/retail'
import {PortfolioHistory} from '~/api/rakaia'
import {Period} from '~/global/utils/time-period/timePeriod'
import PortfolioHistoryChart from '~/global/widgets/chart/PortfolioHistoryChart'
import {useRakaiaReadPortfolio} from '~/sections/kiwisaver/data/rakaia'
import {combinePortfolioAndCashHoldingsHistory} from '~/sections/kiwisaver/sections/home/widgets/kiwisaver-chart/utils/combine-holdings-history/combineHoldingsHistory'
import KiwiSaverChartBalance from '~/sections/kiwisaver/sections/home/widgets/kiwisaver-chart-balance/KiwiSaverChartBalance'
import {KiwiSaverChartLegend} from '~/sections/kiwisaver/sections/home/widgets/kiwisaver-chart-legend/KiwiSaverChartLegend'
import KiwiSaverChartValues from '~/sections/kiwisaver/sections/home/widgets/kiwisaver-chart-values/KiwiSaverChartValues'
import {DEFAULT_CACHE_STALE_OPTION, useActiveOrOffboardingKSCustomer} from '~/sections/kiwisaver/state'
import {PortfolioHistoryItem} from '~/store/portfolio/types'
import styles from './KiwiSaverChart.scss'

export const KiwiSaverChart: React.FunctionComponent<{}> = () => {
    const customer = useActiveOrOffboardingKSCustomer()
    const returns = useRakaiaReadPortfolio(customer.portfolio_id)
    const rakaiaHistory = returns.portfolio_history || []
    const [period, setPeriod] = React.useState<Period>('All')
    const [portfolioValues, setPortfolioValues] = React.useState<PortfolioHistoryItem>()

    const {data: cashHoldingsHistory} = useRetailGet({
        path: 'kiwisaver/customer-holdings-history',
        options: DEFAULT_CACHE_STALE_OPTION,
    })

    const totalHistory: PortfolioHistory[] = React.useMemo(
        () => combinePortfolioAndCashHoldingsHistory(rakaiaHistory, cashHoldingsHistory),
        [rakaiaHistory, cashHoldingsHistory],
    )

    return (
        <>
            <KiwiSaverChartBalance historyItem={portfolioValues} period="All" onPeriodChange={setPeriod} />
            {totalHistory.length > 0 && (
                <>
                    <KiwiSaverChartValues historyItem={portfolioValues} period="All" onPeriodChange={setPeriod} />
                    <div className={styles.chartWrapper}>
                        <PortfolioHistoryChart
                            period={period}
                            portfolioHistory={totalHistory}
                            onDayHover={values => setPortfolioValues(values)}
                        />
                    </div>
                    <KiwiSaverChartLegend />
                </>
            )}
        </>
    )
}
