export const howManyShares = ({
    marketPrice,
    priceLimit,
    fee,
    amount,
}: {
    marketPrice: number
    fee: number
    priceLimit?: number
    amount: string
}): number => {
    // If it has a price limit, and the price limit is lower than the market price, use that rather than the market price. If the price limit is higher than the market price, still use the market price to estimate because it's a better deal.
    const priceToCompare = priceLimit && priceLimit < marketPrice ? priceLimit : marketPrice
    // as this is an estimate, and some managed funds only allow up to 2 DP of fractional,
    // round the number to 2 places.
    return parseFloat(((parseFloat(amount) - fee) / priceToCompare).toFixed(2))
}

export default howManyShares
