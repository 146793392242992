import cn from 'classnames'
import React from 'react'
import {LearnModuleSlideContent} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {IntroSlides} from '~/global/widgets/intro-slides/IntroSlides'
import roundupsAccumulate from '~/sections/invest/sections/bank-linking/assets/images/roundups-accumulate.svg'
import roundupsCard from '~/sections/invest/sections/bank-linking/assets/images/roundups-card.svg'
import roundupsCoffee from '~/sections/invest/sections/bank-linking/assets/images/roundups-coffee.svg'
import roundupsIntoWalletDark from '~/sections/invest/sections/bank-linking/assets/images/roundups-into-wallet-dark.svg'
import roundupsIntoWalletLight from '~/sections/invest/sections/bank-linking/assets/images/roundups-into-wallet-light.svg'
import styles from './RoundupsHowTo.scss'

export const RoundupIntroContent: LearnModuleSlideContent[] = [
    {
        additionalClassName: styles.roundupsSlide,
        image: roundupsCard,
        imageAdditionalClassName: styles.roundupsSlideImage,
        bodyRenderer: () => (
            <p>
                Each time you spend, we’ll note the difference in cents up to the nearest dollar—that’s the ‘round-up’!
            </p>
        ),
    },
    {
        additionalClassName: styles.roundupsSlide,
        image: roundupsAccumulate,
        imageAdditionalClassName: cn(styles.roundupsSlideImage, styles.anchorImageTop),
        bodyRenderer: () => (
            <p>
                We’ll keep track of all those rounded-up amounts—like the 88¢ from your groceries, or the 50¢ from your
                coffee.
            </p>
        ),
    },
    {
        additionalClassName: styles.roundupsSlide,
        image: roundupsIntoWalletLight,
        darkImage: roundupsIntoWalletDark,
        imageAdditionalClassName: styles.roundupsSlideImage,
        bodyRenderer: () => (
            <p>
                Once they add up to an amount you choose, we’ll automatically send the lot to your Wallet as a single
                top-up, ready to invest.
            </p>
        ),
    },
    {
        additionalClassName: styles.roundupsSlide,
        image: roundupsCoffee,
        imageAdditionalClassName: styles.roundupsSlideImage,
        bodyRenderer: () => (
            <p>Round-ups can be tracked across your day-to-day spending accounts, credit cards, and more! </p>
        ),
    },
]

interface RoundupIntroSlideProps {
    onComplete: () => void
    onCompleteLabel: string
    onBack?: () => void
}

const RoundupsHowTo = ({onComplete, onCompleteLabel, onBack}: RoundupIntroSlideProps) => {
    return (
        <IntroSlides
            content={RoundupIntroContent}
            flag="plink"
            onComplete={onComplete}
            onCompleteLabel={onCompleteLabel}
            onBack={onBack}
        />
    )
}

export default RoundupsHowTo
