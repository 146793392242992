import {ClassicCard, logContentCardClick} from '@braze/web-sdk'
import {ArrowRight} from '@design-system/icon'
import {Thumbnail} from '@design-system/thumbnail'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {accessibility} from '~/global/scss/helpers'
import {isInternalLink, normalizeUrl} from '~/global/utils/normalize-url/normalizeUrl'
import {useInstruments} from '~/global/utils/use-instruments/useInstruments'
import {useAppSelector} from '~/store/hooks'
import styles from './CompanyAnnouncementCard.scss'

interface CompanyAnnouncementCard extends ClassicCard {
    extras?: {
        instrument_id?: string
        explore_content_label?: string
    }
}

interface CompanyAnnouncementCardProps {
    card: CompanyAnnouncementCard
}

export const CompanyAnnouncementCard: React.FunctionComponent<CompanyAnnouncementCardProps> = ({card}) => {
    const {linkText, title, url, extras} = card

    const path = useAppSelector(({instrument}) => instrument.metadata.resourcePath)
    const [instruments, allInstrumentsLoaded] = useInstruments([extras?.instrument_id])

    const navigate = useNavigate()

    if (!allInstrumentsLoaded || !extras?.instrument_id || !(extras?.instrument_id in instruments) || !url) {
        return null
    }

    const cleanUrl = normalizeUrl(url)
    const instrument = instruments[extras?.instrument_id]

    if (!instrument) {
        return null
    }

    const handleOnClick = () => {
        logContentCardClick(card)
        if (isInternalLink(cleanUrl)) {
            navigate(cleanUrl)
        } else {
            window.open(cleanUrl, '_blank', 'noopener')
        }
    }

    const imgPath = instrument.logos.micro ? `${path}${instrument.logos.micro}` : undefined

    return (
        <section className={styles.companyAnnouncement} data-testid={`company-announcement--${instrument.id}`}>
            {extras?.explore_content_label && <h2>{extras?.explore_content_label}</h2>}

            <button
                data-testid={`button--company-announcement-${instrument.id}`}
                type="button"
                className={cn(styles.companyAnnouncementCard, accessibility.button)}
                onClick={handleOnClick}
            >
                <Thumbnail
                    dataTestId={`${instrument.urlSlug}--logo`}
                    width="100px"
                    height="100px"
                    symbol={instrument.symbol}
                    path={imgPath}
                    borderRadius={10}
                    noBorder
                />
                <div className={styles.content}>
                    <h3>{title}</h3>

                    {linkText && (
                        <a href="#" className={styles.action}>
                            <span>{linkText}</span> <ArrowRight />
                        </a>
                    )}
                </div>
            </button>
        </section>
    )
}
