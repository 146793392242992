import {ItemType} from '~/sections/moose/sections/order/state'

interface ShareLabel {
    itemType: ItemType
    isCapitalised?: boolean
    isPlural?: boolean
}

export const shareLabel = ({itemType, isCapitalised = false, isPlural = false}: ShareLabel): string => {
    let result
    switch (itemType) {
        case 'SHARE':
            result = isCapitalised ? 'Share' : 'share'
            break

        case 'UNIT':
            result = isCapitalised ? 'Unit' : 'unit'
            break
    }

    result = isPlural ? result + 's' : result

    return result
}
