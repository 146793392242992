import {Button} from '@design-system/button'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useLinkedBankPaymentsUrl} from '~/sections/OLD_wallet/sections/linked-bank-payments/hooks/useLinkedBankPaymentsUrl'
import recurringTopUpCard from '~/sections/OLD_wallet/sections/linked-bank-payments/pages/intro/assets/images/recurring-top-up-card.png'
import {useAppSelector} from '~/store/hooks'
import styles from './Intro.scss'

export const Intro: React.FunctionComponent = () => {
    const isBankLinked = useAppSelector(s => s.bankLinking.isBankLinked)
    const navigate = useNavigate()
    const relativeUrlFor = useLinkedBankPaymentsUrl()

    return (
        <>
            <div className={styles.heroToolbar}>
                <Toolbar dataTestId="toolbar--roundups-tracking" leftButton="back" />
            </div>
            <div className={styles.pageHero}>
                <img role="presentation" alt="linked bank payments intro" src={recurringTopUpCard} />
            </div>
            <Page>
                <h1 className={spacing.spaceAbove16}>Top up using your linked bank</h1>
                <p className={spacing.spaceAbove12}>
                    Link your bank with Sharesies to make seamless one-off top-ups or to set up a recurring top-up—all
                    from within the Sharesies app.
                </p>
            </Page>
            <ActionBar>
                <Button
                    label="Get started"
                    type="primary"
                    dataTestId="button--linked-bank-top-ups-intro"
                    onClick={() =>
                        !isBankLinked
                            ? navigate(relativeUrlFor('link-your-bank'))
                            : navigate(relativeUrlFor('select-debit-account'))
                    }
                />
            </ActionBar>
        </>
    )
}
