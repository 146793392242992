import React from 'react'

const FundChargeDetail: React.FunctionComponent = () => {
    return (
        <div>
            <h1>Fund charges</h1>

            <p>
                When you invest in Base Funds, you’re charged fund charges which pay for the fund’s management and
                operational costs. These are charged by the fund provider, not Sharesies.
            </p>
            <p>
                Fund charges are included in the unit price of the fund, and may therefore indirectly affect your
                returns.
            </p>
            <p>We do not charge any other fees on Base Funds, however, we are paid by the underlying fund managers.</p>

            <p>
                For more info about fees check out our{' '}
                <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.sharesies.nz/kiwisaver?scroll=fees-that-flex-to-your-plan"
                >
                    website
                </a>
                .
            </p>
        </div>
    )
}

export default FundChargeDetail
