import cn from 'classnames'
import React from 'react'
import {Country} from '~/api/distill'
import {Model} from '~/api/retail/types'
import {accessibility, spacing} from '~/global/scss/helpers'
import {convertDecimalToPercentage} from '~/global/utils/convert-decimal-to-percentage/convertDecimalToPercentage'
import {isInstrumentDerivative} from '~/global/utils/is-instrument-derivative/isInstrumentDerivative'
import {useCurrentSubscriptionPlan} from '~/global/utils/subscription-hooks/subscriptionHooks'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {ChevronRight} from '~/global/widgets/OLD_icons'
import ADRDetail from '~/global/widgets/detail-modals/ADRDetail'
import DetailModal from '~/global/widgets/detail-modals/DetailModal'
import ManagementFeesDetail from '~/global/widgets/detail-modals/ManagementFeesDetail'
import TransactionFeesDetail from '~/global/widgets/detail-modals/TransactionFeesDetail'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue, PercentValue} from '~/global/widgets/number-elements/NumberElements'
import labelledValuesStyles from '~/sections/invest/sections/view-instrument/LabelledValues.scss'
import {useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'

type DetailTopic = null | 'transaction fees' | 'management fees' | 'ADR'

export const getFeeCap = (instrument: Instrument, jurisdiction: Model.User['jurisdiction']) => {
    switch (instrument.exchangeCountry) {
        case Country.aus:
            return {feeCap: jurisdiction === 'au' ? 6 : 15, feeCurrency: 'aud'}
        case Country.nzl:
            return {feeCap: 25, feeCurrency: 'nzd'}
        case Country.usa:
            return {feeCap: 5, feeCurrency: 'usd'}
        case Country.can:
        case Country.gbr:
        case Country.zaf:
        default:
            throw new Error('Trying to get feeCap for an invalid country')
    }
}

const Fees: React.FunctionComponent<FeesProps> = ({instrument, jurisdiction, setHideFooter}) => {
    const [detailTopic, showDetailModal] = React.useState<DetailTopic>(null)
    const [currentSubscription, currentPlanLoaded] = useCurrentSubscriptionPlan()
    const {isDependent, preferredName} = useAppSelector(s => ({
        isDependent: s.identity.user?.is_dependent,
        preferredName: s.identity.user?.preferred_name,
    }))

    const handleDetailClick = (detail: DetailTopic) => {
        showDetailModal(detail)
        if (setHideFooter) {
            setHideFooter(true)
        }
    }
    const transactionFees = () => {
        const {feeCap, feeCurrency} = getFeeCap(instrument, jurisdiction)
        if (tradingType(instrument) === 'managed') {
            return instrument.fixedFeeSpread ? (
                <p className={labelledValuesStyles.value}>
                    <PercentValue value={instrument.fixedFeeSpread} />
                </p>
            ) : (
                <p className={labelledValuesStyles.noData}>No data</p>
            )
        }

        return (
            <p className={labelledValuesStyles.value}>
                <span>
                    1.9%, capped at <DollarValue value={feeCap} currency={feeCurrency} decimalPlaces={0} />
                </span>
            </p>
        )
    }

    const NoData = () => (
        <p className={labelledValuesStyles.noData}>
            No data
            <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
        </p>
    )

    if (!currentPlanLoaded) {
        return <Loading isPineapple />
    }

    return (
        <>
            <section
                className={cn(labelledValuesStyles.verticalContainer, spacing.spaceAbove16, {
                    [labelledValuesStyles.border]: !instrument.isAdr,
                })}
            >
                <div>
                    <p className={labelledValuesStyles.label}>
                        {isInstrumentDerivative(instrument)
                            ? 'Transaction fee to buy or sell'
                            : tradingType(instrument) === 'managed'
                              ? 'Managed fund transaction fees'
                              : 'Transaction fees'}
                    </p>
                    <button
                        className={cn(labelledValuesStyles.value, accessibility.button)}
                        onClick={() => handleDetailClick('transaction fees')}
                        role="link"
                        type="button"
                    >
                        {transactionFees()}
                        <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                    </button>
                    {tradingType(instrument) !== 'managed' && currentSubscription && (
                        <p className={labelledValuesStyles.afterValue}>
                            Unless covered by {isDependent ? `${preferredName}’s` : 'your'} plan
                        </p>
                    )}
                </div>
                {isInstrumentDerivative(instrument) && (
                    <div>
                        <p className={labelledValuesStyles.label}>Transaction fee to exercise</p>
                        <p className={labelledValuesStyles.value}>0.0%</p>
                    </div>
                )}
                {instrument.isAdr && (
                    <div>
                        <p className={labelledValuesStyles.label}>Depositary fees</p>
                        <button
                            className={cn(labelledValuesStyles.value, accessibility.button)}
                            onClick={() => handleDetailClick('ADR')}
                            role="link"
                            type="button"
                        >
                            <p className={labelledValuesStyles.value}>$0.01 to $0.10</p>
                            <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                        </button>
                    </div>
                )}
                {instrument.instrumentType !== 'equity' && !isInstrumentDerivative(instrument) && !instrument.isAdr && (
                    <div>
                        <p className={labelledValuesStyles.label}>Management fees</p>
                        <button
                            className={cn(labelledValuesStyles.value, accessibility.button)}
                            onClick={() => handleDetailClick('management fees')}
                            role="link"
                            type="button"
                        >
                            {instrument.managementFeePercent ? (
                                <p>
                                    <PercentValue value={convertDecimalToPercentage(instrument.managementFeePercent)} />
                                    <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                                </p>
                            ) : (
                                <NoData />
                            )}
                        </button>
                    </div>
                )}
            </section>

            <DetailModal
                isOpen={detailTopic != null}
                closeHandler={() => {
                    showDetailModal(null)
                    if (setHideFooter) {
                        setHideFooter(false)
                    }
                }}
            >
                {detailTopic === 'transaction fees' && (
                    <TransactionFeesDetail instrument={instrument} jurisdiction={jurisdiction} />
                )}

                {detailTopic === 'management fees' && <ManagementFeesDetail jurisdiction={jurisdiction} />}
                {detailTopic === 'ADR' && <ADRDetail jurisdiction={jurisdiction} />}
            </DetailModal>
        </>
    )
}

interface FeesProps {
    instrument: Instrument
    jurisdiction: Model.User['jurisdiction']
    setHideFooter?: (isHidden: boolean) => void
}

export default Fees
