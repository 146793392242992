/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface SortDto
 */
export interface SortDto {
    /**
     *
     * @type {string}
     * @memberof SortDto
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof SortDto
     */
    name: string
}

export function SortDtoFromJSON(json: any): SortDto {
    return SortDtoFromJSONTyped(json, false)
}

export function SortDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        id: json['id'],
        name: json['name'],
    }
}

export function SortDtoToJSON(value?: SortDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        id: value.id,
        name: value.name,
    }
}
