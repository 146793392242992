import cn from 'classnames'
import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import {mod} from 'react-swipeable-views-core'
import {virtualize} from 'react-swipeable-views-utils'
import {ExchangeDto, ListingResponseDto} from '~/api/distill'
import {DistillScope} from '~/api/query/distill'
import {Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import useDistillInstrumentInfo from '~/global/state-hooks/distill/useDistillInstrumentInfo'
import {investmentActivityToInvestmentActivityWithDetail} from '~/global/utils/investment-activity/investmentActivityTypes'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {ChevronLeft, ChevronRight, DotBig} from '~/global/widgets/OLD_icons'
import recordStyles from '~/global/widgets/instrument-activity/Record.scss'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import {ExchangeHours} from '~/global/widgets/view-instrument-shared/exchange-hours/ExchangeHours'
import styles from '~/sections/invest/widgets/order-carousel/OrderCarousel.scss'
import {useInvestingActivity} from '~/sections/moose/data/retail'
import {PortfolioOrderRecord} from '~/sections/wallet/pages/transaction/widgets/transaction/PortfolioOrderRecord'

const VirtualizeSwipeableViews = virtualize(SwipeableViews)

interface MoosePortfolioOrderCarouselProps {
    portfolioId: string
    instrument: ListingResponseDto
    orders: Response.PortfolioOrders['orders']
    carousel?: boolean
}

export const MoosePortfolioOrderCarousel: React.FunctionComponent<MoosePortfolioOrderCarouselProps> = ({
    portfolioId,
    instrument,
    orders,
    carousel,
}) => {
    const useCarousel = orders.length > 1 || carousel
    const [currentOrder, setCurrentOrder] = React.useState<number>(0)
    const currentOrderkey = orders[mod(currentOrder, orders.length)].key

    const order = useInvestingActivity(portfolioId, currentOrderkey)
    const orderWithDetail = investmentActivityToInvestmentActivityWithDetail(order)
    if (orderWithDetail.detailType !== 'portfolio_order') {
        throw new Error('Missing portfolio order')
    }

    const instrumentsMetadata = useDistillInstrumentInfo(DistillScope.FONTERRA)
    const exchangeInfo = instrumentsMetadata.exchanges.find(
        (exchange: ExchangeDto) => instrument && instrument.exchange === exchange.name,
    )

    return (
        <Page>
            <PageBack />
            {tradingType(instrument) !== 'managed' && (
                <ExchangeHours exchange={exchangeInfo} instrumentTradingStatus={instrument.tradingStatus} />
            )}
            <div className={spacing.spaceAbove32}>
                <div>
                    <div className={recordStyles.titleBlock}>
                        {orders.length > 1 && (
                            <span>{`${mod(currentOrder, orders.length) + 1} of ${orders.length}`}</span>
                        )}
                    </div>
                    {useCarousel ? (
                        <>
                            <VirtualizeSwipeableViews
                                resistance
                                index={currentOrder}
                                onChangeIndex={(index: number) => {
                                    setCurrentOrder(index)
                                }}
                                className={styles.swipeableArea}
                                slideRenderer={({key}) => (
                                    <PortfolioOrderRecord
                                        key={key}
                                        order={orderWithDetail.detail}
                                        hidePageBack={true}
                                    />
                                )}
                                disabled={orders.length < 2}
                            />

                            {orders.length > 1 ? (
                                <div className={styles.controls}>
                                    <div className={styles.controlLeft}>
                                        <ChevronLeft onClick={() => setCurrentOrder(currentOrder - 1)} />
                                    </div>
                                    <div className={styles.controlDots}>
                                        {orders.map((_, index) => (
                                            <DotBig
                                                key={index}
                                                className={cn({
                                                    [styles.selected]: mod(currentOrder, orders.length) === index,
                                                })}
                                                onClick={() => setCurrentOrder(index)}
                                            />
                                        ))}
                                    </div>
                                    <div className={styles.controlRight}>
                                        <ChevronRight onClick={() => setCurrentOrder(currentOrder + 1)} />
                                    </div>
                                </div>
                            ) : null}
                        </>
                    ) : (
                        <PortfolioOrderRecord order={orderWithDetail.detail} hidePageBack={true} />
                    )}
                </div>
            </div>
        </Page>
    )
}
