import React from 'react'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import type {FormErrors} from '~/api/retail'
import {spacing} from '~/global/scss/helpers'
import {ErrorBox} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {useSearchParams} from '~/migrate-react-router'
import {SurveyForm, SurveyHeader, SurveyValues} from '~/sections/invest/sections/survey/widgets/survey-form/SurveyForm'
import {useAppSelector} from '~/store/hooks'
import {actingAsID} from '~/store/identity/selectors'

export const Survey: React.FunctionComponent<{surveySlug: string}> = ({surveySlug}) => {
    const [searchParams] = useSearchParams()

    // optional url param used for employee share scheme onboarding
    const optionalEmployeeReference = searchParams.get('employee_reference')

    const actingAsId = useAppSelector(actingAsID)
    const {data: surveyResp, status} = useRetailGet({
        path: 'survey/fetch',
        payload: {
            acting_as_id: actingAsId,
            survey_slug: surveySlug,
        },
        options: {
            suspense: false,
        },
    })

    const submitMutation = useRetailPost({
        path: 'survey/submit',
    })

    async function onSubmit(values: SurveyValues): Promise<undefined | string | FormErrors> {
        // This function is a crime and should be tidied up
        try {
            // an optional employee reference to be stored alongside the survey response
            if (optionalEmployeeReference) {
                values = {
                    ...values,
                    employee_reference: optionalEmployeeReference,
                }
            }

            await submitMutation.mutateAsync({
                acting_as_id: actingAsId,
                survey_slug: surveySlug,
                values,
            })
        } catch (e: unknown) {
            const isFormError = (e: any): e is FormErrors => e?.type === 'form_errors'
            if (isFormError(e)) {
                return e.errors
            }
            return 'An unexpected error occurred, please try again!'
        }
    }

    if (
        status === 'error' ||
        status === 'loading' ||
        surveyResp.type === 'resource_not_found' ||
        surveyResp.type === 'survey_disabled'
    ) {
        return (
            <Page>
                <SurveyHeader />
                {status === 'loading' && <Loading isPineapple />}
                {status !== 'loading' && (
                    <>
                        {/** Handle the case we want to display a more helpful error on a disabled survey */}
                        {status === 'success' && surveyResp.type === 'survey_disabled' ? (
                            <p className={spacing.spaceAbove24}>{surveyResp.message}</p>
                        ) : (
                            <ErrorBox message="An error occurred loading this survey" />
                        )}
                    </>
                )}
            </Page>
        )
    }

    const blocks = submitMutation.data?.blocks || surveyResp.blocks

    return <SurveyForm blocks={blocks} onSubmit={v => onSubmit(v)} />
}

export default Survey
