import cn from 'classnames'
import React from 'react'
import styles from './ActionBar.scss'

interface Props {
    className?: string
}

const ActionBar: React.FunctionComponent<Props> = ({className, children}) => (
    <div className={styles.footerGap}>
        <div className={cn(styles.footer, className)}>{children}</div>
    </div>
)

export default ActionBar
