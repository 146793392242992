import cn from 'classnames'
import React from 'react'
import {ExchangeDto, ListingResponseDto} from '~/api/distill'
import {accessibility, spacing} from '~/global/scss/helpers'
import {useDistillPriceHistory} from '~/global/state-hooks/distill/useDistillPriceHistory'
import {Period} from '~/global/utils/time-period/timePeriod'
import {ChevronRight} from '~/global/widgets/OLD_icons'
import DetailModal from '~/global/widgets/detail-modals/DetailModal'
import TransactionFeesDetail from '~/global/widgets/detail-modals/TransactionFeesDetail'
import PriceHistory from '~/global/widgets/view-instrument-shared/overview/price-history/PriceHistory'
import Stats, {Stat} from '~/global/widgets/view-instrument-shared/overview/stats/Stats'
import labelledValuesStyles from '~/sections/invest/sections/view-instrument/LabelledValues.scss'
import PriceHistorySummary from '~/sections/invest/sections/view-instrument/sections/overview/widgets/price-history-summary/PriceHistorySummary'
import {MoosePortfolioPathParams} from '~/sections/moose/routes'
import {ComparisonPrices, Instrument, InstrumentDetailsTab} from '~/store/instrument/types'
import styles from './MooseInstrumentOverviewTab.scss'

const MooseInstrumentFees: React.FunctionComponent<{instrument: ListingResponseDto}> = ({instrument}) => {
    const [modalOpen, setModalOpen] = React.useState<boolean>(false)
    const hasFees = instrument.symbol !== 'FCG'

    return (
        <div>
            <DetailModal
                isOpen={modalOpen}
                closeHandler={() => {
                    setModalOpen(isOpen => !isOpen)
                }}
            >
                {hasFees && (
                    <TransactionFeesDetail instrument={instrument as Instrument} jurisdiction="nz" forBaseFund={true} />
                )}
                {!hasFees && <p>Transaction fees are covered by Fonterra.</p>}
            </DetailModal>

            <h2 className={cn(styles.sectionHeader, spacing.spaceAbove32)}>Fees</h2>
            <p className={cn(labelledValuesStyles.label, spacing.spaceAbove16)}>Transaction fees</p>
            <button
                className={cn(labelledValuesStyles.value, accessibility.button)}
                onClick={() => {
                    setModalOpen(isOpen => !isOpen)
                }}
                role="link"
                type="button"
            >
                {hasFees ? (
                    <p>
                        1.9%, capped at $25 NZD
                        <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                    </p>
                ) : (
                    <p>
                        0.0%
                        <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                    </p>
                )}
            </button>
            {!hasFees && <p className={cn(labelledValuesStyles.label)}>Transaction fees are covered by Fonterra</p>}
        </div>
    )
}

interface MooseInstrumentOverviewTabProps extends MoosePortfolioPathParams {
    instrument: ListingResponseDto
    exchange: ExchangeDto | undefined
    period: Period
    setPeriod: (period: Period) => void
    setHideFooter?: (isHidden: boolean) => void
    tab: InstrumentDetailsTab
}

export const MooseInstrumentOverviewTab: React.FunctionComponent<MooseInstrumentOverviewTabProps> = ({
    instrument,
    exchange,
    period,
    setPeriod,
    setHideFooter,
    tab,
}) => {
    const priceHistory = useDistillPriceHistory(instrument.id)
    const comparisonPrice = instrument.comparisonPrices[`_${period}` as keyof ComparisonPrices]

    return (
        <section
            id="overview"
            style={{display: tab === 'Overview' ? 'block' : 'none'}}
            role="tabpanel"
            aria-labelledby="overview-tab"
        >
            <h2 className={cn(styles.sectionHeader, spacing.spaceAbove12)}>Prices</h2>
            <PriceHistory
                period={period}
                setPeriod={setPeriod}
                instrument={instrument}
                exchange={exchange}
                priceHistory={priceHistory}
            />
            <PriceHistorySummary period={period} comparisonPrices={comparisonPrice} />

            <h2 className={cn(styles.sectionHeader, spacing.spaceAbove32)}>Stats</h2>
            <Stats
                instrument={instrument}
                jurisdiction="nz"
                setHideFooter={setHideFooter}
                excludedStats={[Stat.DividendYield, Stat.Fees]}
                showExpandedDividends={true}
            />
            <MooseInstrumentFees instrument={instrument} />
            <hr className={styles.divider} />
            <p className={styles.dataDisclaimer}>
                Data displayed is from the NZX, Refinifiv, IEX Cloud and XE. It’s indicative only and its accuracy or
                completeness is not guaranteed.
            </p>
        </section>
    )
}
