/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ManagementType {
    Active = 'Active',
    Passive = 'Passive',
}

export function ManagementTypeFromJSON(json: any): ManagementType {
    return ManagementTypeFromJSONTyped(json, false)
}

export function ManagementTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementType {
    return json as ManagementType
}

export function ManagementTypeToJSON(value?: ManagementType | null): any {
    return value as any
}
