import {SelectCards} from '@design-system/select-cards'
import {useAtom} from 'jotai'
import React from 'react'
import {useNavigate} from 'react-router'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useDistillInstrumentWithCurrency} from '~/sections/moose/data/distill'
import {LimitBuyOrder, LimitSellOrder, buyOrderAtom, sellOrderAtom} from '~/sections/moose/sections/order/state'
import {itemTypeFromSymbol} from '~/sections/moose/sections/utils/symbol-helpers/symbolHelpers'
import {Instrument} from '~/store/instrument/types'

export const InstrumentSelect: React.FunctionComponent<{action: 'buy' | 'sell'; portfolioId: string}> = ({
    action,
    portfolioId,
}) => {
    const profileUrl = useProfileUrl()
    const [stagedBuyOrder, updateStagedBuyOrder] = useAtom(buyOrderAtom)
    const [stagedSellOrder, updateStagedSellOrder] = useAtom(sellOrderAtom)
    const navigate = useNavigate()
    const instruments = [useDistillInstrumentWithCurrency('nzx-fcg'), useDistillInstrumentWithCurrency('fsf')]

    const updateBuyOrder = (
        updateOrderFunction: typeof updateStagedBuyOrder,
        instrument: Instrument,
        newSymbol: string,
        stagedOrder?: LimitBuyOrder,
    ) => {
        if (stagedOrder && stagedOrder.instrument.urlSlug === instrument.urlSlug) {
            // Use existing order as base, and refresh holding detail and instrument
            updateOrderFunction({
                ...stagedOrder,
                instrument,
                useDelayedSettlement: false,
                pushedHistory: 1,
            })
        } else {
            updateOrderFunction({
                instrument,
                itemType: itemTypeFromSymbol(newSymbol),
                useDelayedSettlement: false,
                pushedHistory: 1,
            })
        }
    }

    const updateSellOrder = (
        updateOrderFunction: typeof updateStagedSellOrder,
        instrument: Instrument,
        newSymbol: string,
        stagedOrder?: LimitSellOrder,
    ) => {
        if (stagedOrder && stagedOrder.instrument.urlSlug === instrument.urlSlug) {
            // Use existing order as base, and refresh holding detail and instrument
            updateOrderFunction({
                ...stagedOrder,
                instrument,
                pushedHistory: 1,
            })
        } else {
            updateOrderFunction({
                instrument,
                itemType: itemTypeFromSymbol(newSymbol),
                pushedHistory: 1,
            })
        }
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--instrument-select"
                title={`What are you looking to ${action}?`}
                leftButton="back"
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <SelectCards
                    dataTestId={`select-cards--${action}-instrument-select`}
                    title={`What are you looking to ${action}?`}
                    hideTitle
                    options={[
                        {
                            title: 'Shares (Farmer only)',
                            supportingText: 'Fonterra Co-operative Group ',
                            name: 'symbol',
                            value: 'FCG',
                        },
                        {
                            title: 'Units',
                            supportingText: 'Fonterra Shareholder Fund',
                            name: 'symbol',
                            value: 'FSF',
                        },
                    ]}
                    onChange={e => {
                        const instrument = instruments.find(instrument => instrument.symbol === e.target.value)
                        if (instrument) {
                            if (action === 'buy') {
                                updateBuyOrder(updateStagedBuyOrder, instrument, e.target.value, stagedBuyOrder)
                            }
                            if (action === 'sell') {
                                updateSellOrder(updateStagedSellOrder, instrument, e.target.value, stagedSellOrder)
                            }
                            navigate(
                                profileUrl(`fonterra/portfolios/:portfolioId/invest/:urlSlug/${action}/limit-order`, {
                                    portfolioId,
                                    urlSlug: instrument.urlSlug,
                                }),
                            )
                        }
                    }}
                />
            </Page>
        </>
    )
}
