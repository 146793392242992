import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {isLoading, isUninitialised} from '~/global/utils/is-loading/isLoading'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {ErrorBox, Select} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/invest/sections/bank-linking/BankLinking.scss'
import {
    BASIQ_DDRSA_URL,
    MOONOVA_DDRSA_ROUTE,
    MOONOOVA_DDRSA_VERSION,
} from '~/sections/invest/sections/bank-linking/constants/ddrsa'
import {useBankLinkingReferrer} from '~/sections/invest/sections/bank-linking/hooks/useBankLinkingRererrer'
import {UnlinkBankConfirmModal} from '~/sections/invest/sections/bank-linking/widgets/unlink-bank-confirm-modal/UnlinkBankConfirmModal'
import actions from '~/store/bankLinking/actions'
import {useAppSelector, useAppDispatch} from '~/store/hooks'

interface SelectDebitBankAccountProps {
    onBack: () => void
    onNext: () => void
}

const SelectDebitBankAccount: React.FC<SelectDebitBankAccountProps> = ({onBack, onNext}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const referrer = useBankLinkingReferrer()
    const profileUrl = useProfileUrl()
    const hasMonoovaFlag = useAppSelector(s => s.identity.flags.monoova)

    const {basiqAccountsLoadingState, basiqAccounts, roundupsDebitAccount, institutionName, depositTarget} =
        useAppSelector(({bankLinking}) => ({
            basiqAccountsLoadingState: bankLinking.basiqAccountsLoadingState,
            basiqAccounts:
                bankLinking.basiqAccountsResponse?.accounts.filter(account => account.valid_for_debiting) ?? [],
            roundupsDebitAccount: bankLinking.roundupsDebitAccount,
            institutionName: bankLinking.basiqAccountsResponse?.institution_name ?? '',
            depositTarget: bankLinking.depositTarget,
        }))
    const feeForTarget = (+depositTarget * 0.02).toFixed(2)
    const [selectedAccount, setSelectedAccount] = React.useState<string | undefined>(
        roundupsDebitAccount?.basiqAccountId,
    )
    const [submitting, setSubmitting] = React.useState(false)
    const [error, setError] = React.useState<string>()
    const [unlinkModalOpen, setUnlinkModalOpen] = React.useState(false)

    React.useEffect(() => {
        if (isUninitialised(basiqAccountsLoadingState)) {
            dispatch(actions.FetchBasiqAccounts())
        }
    }, [])

    if (isLoading(basiqAccountsLoadingState)) {
        return (
            <>
                <Toolbar
                    leftButton="back"
                    dataTestId="toolbar--banklinking-select-debit-account"
                    onLeftButtonClick={onBack}
                />
                <Page>
                    <Loading />
                </Page>
                <ActionBar>
                    <Button dataTestId="button--save-select-account" label="Next" disabled />
                </ActionBar>
            </>
        )
    }

    const submit = async () => {
        setSubmitting(true)
        if (selectedAccount !== roundupsDebitAccount?.basiqAccountId) {
            let response

            if (hasMonoovaFlag) {
                response = await dispatch(actions.UpdateDebitBankAccount(selectedAccount!, MOONOOVA_DDRSA_VERSION))
            } else {
                response = await dispatch(actions.UpdateDebitBankAccount(selectedAccount!, BASIQ_DDRSA_URL))
            }
            setSubmitting(false)
            if (response.type === 'error') {
                setError(response.message)
                return
            }
        }
        onNext()
        setSubmitting(false)
    }

    const bodyCopy = () => {
        switch (referrer) {
            case 'settings/bank-accounts-cards':
                return (
                    <>
                        <p className={spacing.spaceBelow16}>
                            Choose the default bank account you’d like to send money from. This will be used for things
                            like round-ups or linked bank top-ups. There is a 2% transfer fee on top.
                        </p>
                        <p className={spacing.spaceBelow16}>
                            Money can’t be sent from a credit card, and you’ll only be able to choose from the accounts
                            shown.
                        </p>
                        <p className={spacing.spaceBelow40}>
                            By choosing an account, you agree to the{' '}
                            {hasMonoovaFlag ? (
                                <Link to={profileUrl(MOONOVA_DDRSA_ROUTE)}>Direct Debit Request Service Agreement</Link>
                            ) : (
                                <a target="_blank" rel="noreferrer" href={BASIQ_DDRSA_URL}>
                                    Direct Debit Request Service Agreement
                                </a>
                            )}{' '}
                            and provide instructions to process your recurring top-up in accordance with the Direct
                            Debit Request.
                        </p>
                    </>
                )
            case 'linked-bank-topup':
                return (
                    <>
                        <p className={spacing.spaceBelow16}>
                            Choose the default bank account you’d like to send money from. This will be used for things
                            like round-ups or linked bank top-ups. There is a 2% transfer fee on top.
                        </p>
                        <p className={spacing.spaceBelow16}>
                            Money can’t be sent from a credit card, and you’ll only be able to choose from the accounts
                            shown.
                        </p>
                        <p className={spacing.spaceBelow40}>
                            By choosing an account, you agree to the{' '}
                            {hasMonoovaFlag ? (
                                <Link to={profileUrl(MOONOVA_DDRSA_ROUTE)}>Direct Debit Request Service Agreement</Link>
                            ) : (
                                <a target="_blank" rel="noreferrer" href={BASIQ_DDRSA_URL}>
                                    Direct Debit Request Service Agreement
                                </a>
                            )}{' '}
                            and provide instructions to process your recurring top-up in accordance with the Direct
                            Debit Request.
                        </p>
                    </>
                )
            default:
                return (
                    <>
                        <p className={spacing.spaceBelow16}>
                            When your round-ups total ${depositTarget}, we’ll send them from the account you choose.
                            There’s a 2% transfer fee on top—in this case, it’s ${feeForTarget}
                        </p>
                        <p className={spacing.spaceBelow16}>
                            Your round-ups can’t be sent from a credit card, and you’ll only be able to choose from the
                            accounts shown.
                        </p>
                        <p className={spacing.spaceBelow40}>
                            By choosing an account, you agree to the{' '}
                            {hasMonoovaFlag ? (
                                <Link to={profileUrl(MOONOVA_DDRSA_ROUTE)}>Direct Debit Request Service Agreement</Link>
                            ) : (
                                <a target="_blank" rel="noreferrer" href={BASIQ_DDRSA_URL}>
                                    Direct Debit Request Service Agreement
                                </a>
                            )}{' '}
                            and provide instructions to process direct debits in accordance with the Direct Debit
                            Request.
                        </p>
                    </>
                )
        }
    }

    const header = () => {
        switch (referrer) {
            case 'settings/bank-accounts-cards':
                return 'Choose account to send money from'
            case 'linked-bank-topup':
                return 'Choose account to send money from'
            default:
                return 'Choose account to send automatic top-up from'
        }
    }

    const selectDebitBankAccountContent = () => {
        return (
            <>
                <Page>
                    <h1 className={cn(styles.h1, spacing.spaceBelow12)}>{header()}</h1>
                    {roundupsDebitAccount && !roundupsDebitAccount.externallyAvailable ? (
                        <AlertCard type="warning" className={spacing.spaceBelow20}>
                            <h2>Unable to make transfers</h2>
                            <p>
                                We can’t connect with the bank account that round-up top-ups are sent from. Check the
                                account, or choose another.
                            </p>
                        </AlertCard>
                    ) : null}
                    {bodyCopy()}
                    <Select
                        isTouched
                        dataTestId="select--debit-account"
                        name="select--debit-account"
                        label="Choose your account"
                        choices={[
                            {value: '', label: ''},
                            ...basiqAccounts.map(account => {
                                return {
                                    value: account.basiq_account_id,
                                    label: `${account.account_name} - ${account.account_number_masked}`,
                                }
                            }),
                        ]}
                        value={selectedAccount}
                        onChange={e => {
                            setError(undefined)
                            setSelectedAccount(e.target.value)
                        }}
                        placeholder="Select account"
                    />
                    <ErrorBox className={error ? spacing.spaceAbove8 : ''} message={error} />
                    <UnlinkBankConfirmModal
                        isOpen={unlinkModalOpen}
                        setIsOpen={setUnlinkModalOpen}
                        handleUnlinkBank={() => {
                            setUnlinkModalOpen(false)
                            dispatch(actions.UnlinkBank())
                            navigate(profileUrl('explore'))
                        }}
                    />
                </Page>
                <ActionBar>
                    <Button
                        disabled={selectedAccount ? false : true}
                        dataTestId="button--save-select-account"
                        label="Next"
                        processing={submitting}
                        onClick={submit}
                    />
                </ActionBar>
            </>
        )
    }

    const noDebitAccountsAvailableContent = () => {
        const unlinkBank = async () => {
            setSubmitting(true)
            await dispatch(actions.UnlinkBank())
        }
        return (
            <>
                <Page>
                    <h1 className={cn(styles.h1, spacing.spaceBelow16)}>
                        No accounts available to use for debiting Round-ups
                    </h1>
                    <p>
                        We’ve linked to {institutionName} bank but unfortunately we couldn’t find any accounts to either
                        track or debit for Round-ups. This may be due to:
                    </p>
                    <ul>
                        <li>Only holding a credit card account.</li>
                        <li>
                            Only holding accounts you can’t draw on such as mortgage, term deposit or savings accounts.
                        </li>
                    </ul>
                    {(submitting && <Loading />) || null}
                </Page>
                <ActionBar>
                    <div>
                        <Button
                            additionalClassName={spacing.spaceBelow8}
                            dataTestId="button--link-another"
                            label="Unlink bank and link a different bank"
                            disabled={submitting}
                            onClick={async () => {
                                await unlinkBank()
                                navigate(profileUrl('explore/roundups'))
                            }}
                        />
                        <Button
                            dataTestId="button--unlink-bank"
                            label="Unlink bank"
                            type="secondary"
                            disabled={submitting}
                            onClick={async () => {
                                await unlinkBank()
                                navigate(profileUrl('explore'))
                            }}
                        />
                    </div>
                </ActionBar>
            </>
        )
    }

    return (
        <>
            <Toolbar
                leftButton="back"
                dataTestId="toolbar--banklinking-select-debit-account"
                onLeftButtonClick={onBack}
            />
            {basiqAccounts.length > 0 ? selectDebitBankAccountContent() : noDebitAccountsAvailableContent()}
        </>
    )
}
export default SelectDebitBankAccount
