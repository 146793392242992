/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    Country,
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
    CountryToJSONTyped,
    TimeRangeDto,
    TimeRangeDtoFromJSON,
    TimeRangeDtoFromJSONTyped,
    TimeRangeDtoToJSON,
    TimeRangeDtoToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface ExchangeDto
 */
export interface ExchangeDto {
    /**
     *
     * @type {string}
     * @memberof ExchangeDto
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof ExchangeDto
     */
    name: string
    /**
     *
     * @type {Country}
     * @memberof ExchangeDto
     */
    country: Country
    /**
     *
     * @type {string}
     * @memberof ExchangeDto
     */
    currency: string
    /**
     *
     * @type {string}
     * @memberof ExchangeDto
     */
    timeZone: string
    /**
     *
     * @type {Array<TimeRangeDto>}
     * @memberof ExchangeDto
     */
    openHours: Array<TimeRangeDto>
}

export function ExchangeDtoFromJSON(json: any): ExchangeDto {
    return ExchangeDtoFromJSONTyped(json, false)
}

export function ExchangeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExchangeDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        id: json['id'],
        name: json['name'],
        country: CountryFromJSON(json['country']),
        currency: json['currency'],
        timeZone: json['timeZone'],
        openHours: (json['openHours'] as Array<any>).map(TimeRangeDtoFromJSON),
    }
}

export function ExchangeDtoToJSON(value?: ExchangeDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        id: value.id,
        name: value.name,
        country: CountryToJSON(value.country),
        currency: value.currency,
        timeZone: value.timeZone,
        openHours: (value.openHours as Array<any>).map(TimeRangeDtoToJSON),
    }
}
