import {Button} from '@design-system/button'
import path from 'path-browserify'
import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {useEssViewsForInstrument} from '~/global/state-hooks/mixed-source/useEssViewsForInstrument'
import {
    isInstrumentCloseOnly,
    isInstrumentInNoTrade,
} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {isUninitialised} from '~/global/utils/is-loading/isLoading'
import {isOnASX, isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import WalletBalanceWrapper from '~/global/widgets/estimated-wallet-balance/WalletBalanceWrapper'
import {useLocation, useNavigate} from '~/migrate-react-router'
import {applicationsToShowOnPicker} from '~/sections/invest/sections/order-flow/pages/type-picker/OrderTypePicker'
import essActions from '~/store/employeeShareScheme/actions'
import {essHasActiveBlackoutPeriodForInstrument} from '~/store/employeeShareScheme/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import {FundHolding} from '~/store/identity/types'
import {Instrument} from '~/store/instrument/types'
import orderActions from '~/store/order/actions'
import signupActions from '~/store/sign-up/actions'
import styles from './OrderFooter.scss'

const doesUrlEndInPath = (url: string, page: string): boolean => {
    return url.split('/').pop() === page
}

interface OrderFooterProps {
    holding?: FundHolding
    instrument: Instrument
}

const OrderFooter: React.FunctionComponent<OrderFooterProps> = ({holding, instrument}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const currentPath = useLocation().pathname
    const profileUrl = useProfileUrl()

    const restrictedAccount = useAppSelector(({identity}) => (identity.user ? identity.user.account_restricted : false))
    const hasSeenAuSharesIntro = useAppSelector(({identity}) => identity.user!.has_seen.au_shares_intro)
    const needsToSignUpToUsShares = useAppSelector(({identity}) => !identity.user!.us_equities_enabled)
    const unfilteredApplications = useAppSelector(
        ({order}) => order.applicationsByInstrument[instrument.id]?.applications,
    )

    // ESS
    const essViews = useEssViewsForInstrument(instrument.id)
    const essBlackoutPeriodLoadingState = useAppSelector(s => s.employeeShareScheme.blackoutPeriodLoadingState)
    const essHasActiveBlackoutPeriod = useAppSelector(s => essHasActiveBlackoutPeriodForInstrument(s, instrument.id))

    React.useEffect(() => {
        if (instrument && essViews.hasEmployment && isUninitialised(essBlackoutPeriodLoadingState)) {
            dispatch(essActions.FetchBlackoutPeriods())
        }
    }, [instrument.urlSlug, essViews, essBlackoutPeriodLoadingState])

    const applications = applicationsToShowOnPicker(unfilteredApplications ?? [])

    const hasExerciseApplications = applications.some(application => application.type === 'EXERCISE')
    const hasOtherApplications = applications.some(application => application.type !== 'EXERCISE')

    return (
        <div>
            <ActionBar>
                <WalletBalanceWrapper displayCurrency={instrument.currency} />
                {holding && hasExerciseApplications ? (
                    <Button
                        dataTestId="button--pick"
                        additionalClassName={styles.wideFooterButton}
                        label={isInstrumentInNoTrade(instrument) ? 'Exercise' : 'Exercise, buy or sell'}
                        type="primary"
                        disabled={restrictedAccount}
                        onClick={() => {
                            // use browser history as we do not know what flavour of the url we are at right now
                            if (!doesUrlEndInPath(currentPath, 'pick')) {
                                navigate(path.join(currentPath, 'pick'))
                            }
                        }}
                    />
                ) : (
                    <>
                        {holding && (
                            <Button
                                dataTestId="button--sell"
                                additionalClassName={styles.footerButton}
                                label="Sell"
                                type="secondary"
                                disabled={
                                    restrictedAccount || isInstrumentInNoTrade(instrument) || essHasActiveBlackoutPeriod
                                }
                                onClick={() => {
                                    rudderTrack('sell', 'order_started', {})

                                    // this is the only way of entering the sell flow. ensure we start with a blank order
                                    dispatch(orderActions.ClearStagedSellOrder())

                                    // use browser history as we do not know what flavour of the url we are at right now
                                    if (!doesUrlEndInPath(currentPath, 'sell')) {
                                        navigate(path.join(currentPath, 'sell'))
                                    }
                                }}
                                width="auto"
                            />
                        )}
                        {!isInstrumentCloseOnly(instrument) && (
                            <Button
                                dataTestId="button--buy"
                                additionalClassName={styles.footerButton}
                                label="Buy"
                                type="primary"
                                disabled={
                                    restrictedAccount ||
                                    (isInstrumentInNoTrade(instrument) && !hasOtherApplications) ||
                                    essHasActiveBlackoutPeriod
                                }
                                onClick={() => {
                                    rudderTrack('buy', 'order_started', {})

                                    if (hasOtherApplications) {
                                        if (!doesUrlEndInPath(currentPath, 'pick')) {
                                            navigate(path.join(currentPath, 'pick'))
                                        }
                                        return
                                    }

                                    // We're about to enter the buy flow, so ensure we start with a blank order
                                    dispatch(orderActions.ClearStagedBuyOrder())

                                    if (needsToSignUpToUsShares && isOnMainUsExchange(instrument)) {
                                        dispatch(signupActions.setUSPreSignUpLocation(currentPath))

                                        rudderTrack('signup', 'us_shares_signup_started', {
                                            source: 'buy_flow',
                                        })

                                        return navigate(profileUrl('us-sign-up'), {replace: true})
                                    } else if (!hasSeenAuSharesIntro && isOnASX(instrument)) {
                                        dispatch(signupActions.setLocationToReturnTo(currentPath))
                                        dispatch(identityActions.MarkHasSeenFlag('au_shares_intro'))
                                        navigate(profileUrl('invest-in-australian-shares'), {replace: true})
                                    } else {
                                        // use browser history as we do not know what flavour of the url we are at right now
                                        if (!doesUrlEndInPath(currentPath, 'buy')) {
                                            navigate(path.join(currentPath, 'buy'))
                                        }
                                    }
                                }}
                                width="auto"
                            />
                        )}
                    </>
                )}
            </ActionBar>
        </div>
    )
}

export default OrderFooter
