import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {PageButtonGroup} from '~/global/widgets/button-page-group/PageButtonGroup'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/invest/sections/bank-linking/BankLinking.scss'
import roundupsAction from '~/sections/invest/sections/bank-linking/assets/images/roundups-action.svg'
import {OnNextProps} from '../RoundupsSetup'

interface HowToChoiceProps extends OnNextProps {
    onHowTo: () => void
}

const RoundupsIntro = ({onBack, onHowTo, onNext}: HowToChoiceProps) => {
    return (
        <>
            <div className={styles.heroToolbar}>
                <Toolbar dataTestId="toolbar--roundups-tracking" leftButton="back" onLeftButtonClick={onBack} />
            </div>
            <div className={styles.pageHero}>
                <img src={roundupsAction} />
            </div>

            <Page>
                <h1 className={styles.h1}>Top up automatically with round-ups</h1>
                <p className={cn(spacing.spaceAbove20, spacing.spaceBelow32)}>
                    Want to reach your investing goals faster? Give your Wallet a boost using the leftover cents from
                    your day-to-day spending.
                </p>
                <PageButtonGroup>
                    <Button
                        label="Get started"
                        type="primary"
                        dataTestId="button--roundups-intro-how-it-works"
                        onClick={onNext}
                    />
                    <Button
                        label="Show me how it works"
                        type="secondary"
                        dataTestId="button--roundups-intro-next"
                        onClick={onHowTo}
                    />
                </PageButtonGroup>
            </Page>
        </>
    )
}

export default RoundupsIntro
