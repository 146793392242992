import {DateTime} from 'luxon'
import {distillApi, distillApiNewClientToken} from '~/api/distill/apis'
import {DistillScope} from '~/api/query/distill'
import * as api from '~/api/retail'
import {Model, Request} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {isUSExchange} from '~/global/utils/share-transfers/shareTransfers'
import {actingAsID} from '../identity/selectors'
import {createAction, ActionsUnion} from '../redux-tools'
import {ResponseTransferExchange, UsExchanges} from '../transfer/types'
import {ThunkAction} from '../types'
import {initialState} from './reducer'
import {
    SearchResponse,
    SearchParams,
    InfoResponse,
    PriceHistoryResponse,
    BasicInstrument,
    TimePeriod,
    State,
    CountResponse,
    InstrumentTypes,
    DetailInstrument,
    InstrumentDetailsTab,
    SearchPostParams,
    searchListName,
} from './types'

const actions = {
    AppendPortfolioResults: (instrumentIds: string[], page: number) =>
        createAction('instrument.AppendPortfolioResults', {instrumentIds, page}),
    AppendResults: (instrumentIds: string[], page: number) =>
        createAction('instrument.AppendResults', {instrumentIds, page}),
    ApplyOnlyKidsRecommendedToFilter: () => createAction('instrument.ApplyOnlyKidsRecommendedToFilter'),
    ApplyOnlySingleCategoryToFilter: (category: string) =>
        createAction('instrument.ApplyOnlySingleCategoryToFilter', category),
    ApplyOnlySingleExchangeToFilter: (exchange: string) =>
        createAction('instrument.ApplyOnlySingleExchangeToFilter', exchange),
    ApplyOnlySingleInstrumentTypeToFilter: (instrumentType: string) =>
        createAction('instrument.ApplyOnlySingleInstrumentTypeToFilter', instrumentType),
    ClearAllPortfolioInstrumentSubfilters: () => createAction('instrument.ClearAllPortfolioInstrumentSubfilters'),
    ClearAllSearchFilters: () => createAction('instrument.ClearAllSearchFilters'),
    ClearAllSearchFiltersSorts: () => createAction('instrument.ClearAllSearchFiltersSorts'),
    ClearIndexes: () => createAction('instrument.ClearIndexes'),
    IndexInstruments: (instruments: BasicInstrument[]) => createAction('instrument.IndexInstruments', instruments),
    IndexDetailInstrument: (instrument: DetailInstrument) =>
        createAction('instrument.IndexDetailInstrument', instrument),
    RemoveCategoryFromFilter: (category: string) => createAction('instrument.RemoveCategoryFromFilter', category),
    RemoveExchangeFromFilter: (exchange: string) => createAction('instrument.RemoveExchangeFromFilter', exchange),
    RemoveInstrumentTypeFromFilter: (instrumentType: string) =>
        createAction('instrument.RemoveInstrumentTypeFromFilter', instrumentType),
    RemoveKidsRecommendedFromFilter: () => createAction('instrument.RemoveKidsRecommendedFromFilter'),
    RemoveUnlistedInstrumentsFromFilter: () => createAction('instrument.RemoveUnlistedInstrumentsFromFilter'),
    ResetRiskLevelFilter: () => createAction('instrument.ResetRiskLevelFilter'),
    SetChildInstrumentIds: (instrumentId: string, childInstrumentIds: string[]) =>
        createAction('instrument.SetChildInstrumentIds', {instrumentId, childInstrumentIds}),
    SetCountResultsLoadingState: (state: State['countResultsLoadingState']) =>
        createAction('instrument.SetCountResultsLoadingState', state),
    SetCurrentPortfolioSortedInstrumentIds: (sortedIds: string[]) =>
        createAction('instrument.SetCurrentPortfolioSortedInstrumentIds', sortedIds),
    SetCurrentSearchQuery: (query: string) => createAction('instrument.SetCurrentSearchQuery', query),
    SetCurrentSearchInput: (input: string) => createAction('instrument.SetCurrentSearchInput', input),
    SetCurrentSearchList: (listName: searchListName, instrumentIds: string[]) =>
        createAction('instrument.SetCurrentSearchList', {listName, instrumentIds}),
    SetCurrentSearchSort: (newSort: string) => createAction('instrument.SetCurrentSearchSort', newSort),
    SetCurrentSearchTimePeriod: (timePeriod: TimePeriod) =>
        createAction('instrument.SetCurrentSearchTimePeriod', timePeriod),
    SetDiyAIInstruments: (instrumentIds: string[]) => createAction('instrument.SetDiyAIInstruments', {instrumentIds}),
    SetDiyAILoadingState: (state: State['diyAILoadingState']) => createAction('instrument.SetDiyAILoadingState', state),
    SetdiyAIUnavailableInstrumentNames: (instrumentIds: string[]) =>
        createAction('instrument.SetdiyAIUnavailableInstrumentNames', instrumentIds),
    SetMetadata: (metadata: InfoResponse) => createAction('instrument.SetMetadata', metadata),
    SetNextPageLoadingState: (state: State['nextPageLoadingState']) =>
        createAction('instrument.SetNextPageLoadingState', state),
    SetPortfolioBreakdownIsOpen: (state: boolean) => createAction('instrument.SetPortfolioBreakdownIsOpen', state),
    SetPortfolioInstrumentRiskLevel: (riskLevel: [number, number] | null) =>
        createAction('instrument.SetPortfolioInstrumentRiskLevel', riskLevel),
    SetPortfolioInstruments: (instrumentIds: string[], page: number, numberOfPages: number) =>
        createAction('instrument.SetPortfolioInstruments', {instrumentIds, page, numberOfPages}),
    SetPortfolioInstrumentIndex: (instrumentIds: string[]) =>
        createAction('instrument.SetPortfolioInstrumentIndex', instrumentIds),
    SetPortfolioInstrumentExchangeCountry: (instrumentExchangeCountry: string[]) =>
        createAction('instrument.SetPortfolioInstrumentExchangeCountry', instrumentExchangeCountry),
    SetPortfolioInstrumentTypes: (instrumentTypes: string[]) =>
        createAction('instrument.SetPortfolioInstrumentTypes', instrumentTypes),
    SetPortfolioLoadingState: (state: State['portfolioLoadingState']) =>
        createAction('instrument.SetPortfolioLoadingState', state),
    SetPortfolioNextPageLoadingState: (state: State['portfolioNextPageLoadingState']) =>
        createAction('instrument.SetPortfolioNextPageLoadingState', state),
    SetPortfolioRiskLevelLabel: (riskLabel: string) => createAction('instrument.SetPortfolioRiskLevelLabel', riskLabel),
    SetPotentialResultsTotal: (potentialResultsTotal: number) =>
        createAction('instrument.SetPotentialResultsTotal', potentialResultsTotal),
    SetPremadeAILoadingState: (state: State['premadeAILoadingState']) =>
        createAction('instrument.SetPremadeAILoadingState', state),
    SetPremadeGlobalAIInstruments: (instrumentIds: string[]) =>
        createAction('instrument.SetPremadeGlobalAIInstruments', {instrumentIds}),
    SetPremadeKidsAIInstruments: (instrumentIds: string[]) =>
        createAction('instrument.SetPremadeKidsAIInstruments', {instrumentIds}),
    SetPremadeResponsibleAIInstruments: (instrumentIds: string[]) =>
        createAction('instrument.SetPremadeResponsibleAIInstruments', {instrumentIds}),
    SetPriceHistory: (priceHistory: PriceHistoryResponse) => createAction('instrument.SetPriceHistory', priceHistory),
    SetPriceHistoryLoadingState: (state: State['priceHistoryLoadingState']) =>
        createAction('instrument.SetPriceHistoryLoadingState', state),
    SetRecentlyViewedInstruments: (instrumentIds: string[]) =>
        createAction('instrument.SetRecentlyViewedInstruments', instrumentIds),
    SetRecentlyViewedLoadingState: (state: State['recentlyViewedLoadingState']) =>
        createAction('instrument.SetRecentlyViewedLoadingState', state),
    SetResults: (instrumentIds: string[], page: number, numberOfPages: number) =>
        createAction('instrument.SetResults', {instrumentIds, page, numberOfPages}),
    SetResultsLoadingState: (state: State['resultsLoadingState']) =>
        createAction('instrument.SetResultsLoading', state),
    SetSearchAutofocus: (state: boolean) => createAction('instrument.SetSearchAutofocus', state),
    SetSearchInitialised: (type: State['searchInitialisedFor']) => createAction('instrument.SearchInitialise', type),
    SetSearchScrollItemIndex: (itemIndex: number) => createAction('instrument.SetSearchScrollItemIndex', itemIndex),
    SetTransferableIndex: (instrumentIds: string[]) => createAction('instrument.SetTransferableIndex', instrumentIds),
    SetTransferableLoadingState: (state: State['transferableLoadingState']) =>
        createAction('instrument.SetTransferableLoadingState', state),
    SetWatchlistInstruments: (instrumentIds: string[]) =>
        createAction('instrument.SetWatchlistInstruments', instrumentIds),
    SetWatchlistLoadingState: (state: State['watchlistLoadingState']) =>
        createAction('instrument.SetWatchlistLoadingState', state),
    UpdateSearchFilters: (filters: State['currentSearchFilters']) =>
        createAction('instrument.UpdateSearchFilters', filters),
    SetActiveInstrumentTab: (tab: InstrumentDetailsTab, instrumentId: string | undefined) =>
        createAction('instrument.SetActiveInstrumentTab', {tab, instrumentId}),
    // SetSearch combines SetCurrentSearchQuery and UpdateSearchFilters - could probably remove those
    SetSearch: (query: string, filters: State['currentSearchFilters']) =>
        createAction('instrument.SetSearch', {query, filters}),
    SetAutoinvestDividendsLoadingState: (state: State['autoinvestDividendsLoadingState']) =>
        createAction('instrument.SetAutoinvestDividendsLoadingState', state),
    SetAutoinvestDividends: (state: State['autoinvestDividends']) =>
        createAction('instrument.SetAutoinvestDividends', state),
    ClearDividendReinvestPreferences: () => createAction('instrument.ClearDividendReinvestPreferences'),
}

const getInstrumentTypes = (instrumentTypes: string[]) => {
    if (instrumentTypes.includes('etf')) {
        return [...instrumentTypes, 'etp']
    }

    return instrumentTypes
}

// Find instrument type values by label
const findInstrumentTypeValues = (instrumentTypes: string[], instrumentTypeValuesByLabel: InstrumentTypes) =>
    instrumentTypeValuesByLabel.reduce<string[]>((previous, current) => {
        if (instrumentTypes.includes(current.name)) {
            return [...previous, current.id]
        }
        return previous
    }, [])

const buildSearchParameters = ({
    page,
    sort,
    instruments,
    priceChangeTime,
    categories,
    exchanges,
    unlistedInstruments,
    riskLevel,
    instrumentTypes,
    kidsRecommended,
    query,
    perPage,
    jurisdiction,
    exchangeCountry,
    tradingStatuses,
    parentInstrument,
}: {
    page?: number
    sort?: State['currentSearchSort']
    instruments?: string[]
    priceChangeTime?: State['currentSearchTimePeriod']
    categories?: State['currentSearchFilters']['categories']
    exchanges?: State['currentSearchFilters']['exchanges']
    unlistedInstruments?: boolean
    riskLevel?: State['currentSearchFilters']['riskLevel']
    instrumentTypes?: State['currentSearchFilters']['instrumentTypes']
    kidsRecommended?: boolean
    query?: State['currentSearchQuery']
    perPage?: number
    jurisdiction?: Model.User['jurisdiction']
    exchangeCountry?: State['portfolioInstrumentExchangeCountry']
    tradingStatuses?: string[]
    parentInstrument?: string
}): SearchParams => {
    return {
        page: page || 1,
        sort: sort || initialState.currentSearchSort,
        instruments: instruments && instruments.length > 0 ? instruments : undefined,
        priceChangeTime: priceChangeTime || initialState.currentSearchTimePeriod,
        categories: categories && categories.length > 0 ? categories : undefined,
        exchanges: exchanges && exchanges.length > 0 ? exchanges : undefined,
        unlistedInstruments: jurisdiction === 'au' ? false : unlistedInstruments, // no managed funds for AU investors, temporarily
        maxRisk: riskLevel ? riskLevel[1] : undefined,
        minRisk: riskLevel ? riskLevel[0] : undefined,
        exchangeCountries: exchangeCountry ? exchangeCountry : undefined,
        tradingStatuses: tradingStatuses ? tradingStatuses : undefined,
        instrumentTypes:
            instrumentTypes && instrumentTypes.length > 0 ? getInstrumentTypes(instrumentTypes) : undefined,
        kidsRecommended,
        query: query ? query.trim() : '',
        perPage: perPage ? perPage : 60,
        parentInstrument: parentInstrument ? parentInstrument : undefined,
    }
}

const thunkActions = {
    loadMetadata(): ThunkAction<Promise<void>> {
        return dispatch => {
            return distillApi.instrumentsApi.apiV1InstrumentsInfoGet().then((data: InfoResponse) => {
                dispatch(actions.SetMetadata(data))
            })
        }
    },
    loadSearch(context: 'invest' | 'auto-invest'): ThunkAction<void> {
        return async dispatch => {
            switch (context) {
                case 'auto-invest':
                    await dispatch(this.loadAutoInvestSearchResults())
                    break
                case 'invest':
                    await dispatch(this.loadSearchResults()) // this will use the defaults from initialState
                    break
                default:
                    assertNever(context)
            }
        }
    },
    initSearchResults(context: 'invest' | 'auto-invest'): ThunkAction<void> {
        return async dispatch => {
            switch (context) {
                case 'auto-invest':
                    await dispatch(this.loadAutoInvestSearchResults())
                    dispatch(actions.SetSearchInitialised('auto-invest'))
                    break
                case 'invest':
                    await dispatch(this.loadSearchResults()) // this will use the defaults from initialState
                    dispatch(actions.SetSearchInitialised('invest'))
                    break
                default:
                    assertNever(context)
            }
        }
    },
    loadSearchResults(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const {instrument, identity} = getState()
            try {
                const {categories, exchanges, unlistedInstruments, riskLevel, instrumentTypes, kidsRecommended} =
                    instrument.currentSearchFilters

                const requestParameters: SearchParams = buildSearchParameters({
                    sort: instrument.currentSearchSort,
                    query: instrument.currentSearchQuery,
                    priceChangeTime: instrument.currentSearchTimePeriod,
                    instrumentTypes: findInstrumentTypeValues(instrumentTypes, instrument.metadata.instrumentTypes),
                    categories,
                    exchanges,
                    unlistedInstruments,
                    riskLevel,
                    kidsRecommended,
                    jurisdiction: identity.user!.jurisdiction,
                    instruments: instrument.currentSearchList.instrumentIds,
                })

                // To avoid the skeleton results view for every time the text input changes
                const loadingStateDelay = setTimeout(() => {
                    dispatch(actions.SetResultsLoadingState('loading'))
                }, 500)

                const has_filter =
                    JSON.stringify(instrument.currentSearchFilters) !==
                    JSON.stringify(initialState.currentSearchFilters)
                // We compare against 'relevance' because on input into the search field we switch
                // to this filter instead of the apparent default value 'marketCap'
                const has_sort = instrument.currentSearchSort !== 'relevance'

                // If we aren't filtering by watchlist/portfolio use the search
                // GET endpoint for caching efficiency, otherwise use the POST endpoint
                const distillEndpoint =
                    instrument.currentSearchList.listName === 'All'
                        ? distillApi.instrumentsApi.apiV1InstrumentsGet(requestParameters)
                        : distillApi.instrumentsApi.apiV1InstrumentsPost({searchRequestDto: requestParameters})

                return distillEndpoint.then((data: SearchResponse) => {
                    // Track searchs of 3 characters or more
                    if (instrument.currentSearchQuery.length >= 3) {
                        rudderTrack('browse', 'search_completed', {
                            query: instrument.currentSearchQuery,
                            result_count: data.instruments.length,
                            has_filter,
                            has_sort,
                        })
                    }
                    // prevent the recently fetched search results from being set IF the user's search input has changed (i.e. prevent rendering of old results)
                    // note: using getState() for recent input, as value may have changed since instrument data has been stored above.
                    if (getState().instrument.currentSearchInput.trim() === instrument.currentSearchQuery.trim()) {
                        dispatch(actions.IndexInstruments(data.instruments))
                        dispatch(
                            actions.SetResults(
                                data.instruments.map(instrument => instrument.id),
                                data.currentPage,
                                data.numberOfPages,
                            ),
                        )
                        dispatch(actions.SetResultsLoadingState('ready'))
                    }
                    clearTimeout(loadingStateDelay)
                })
            } catch (e) {
                dispatch(actions.SetResultsLoadingState('error'))
            }
        }
    },
    loadNextPageSearchResults(): ThunkAction<void> {
        return (dispatch, getState) => {
            const {instrument, identity} = getState()
            const {categories, exchanges, unlistedInstruments, riskLevel, instrumentTypes, kidsRecommended} =
                instrument.currentSearchFilters
            const requestParameters: SearchParams = buildSearchParameters({
                sort: instrument.currentSearchSort,
                query: instrument.currentSearchQuery,
                priceChangeTime: instrument.currentSearchTimePeriod,
                instrumentTypes: findInstrumentTypeValues(instrumentTypes, instrument.metadata.instrumentTypes),
                categories,
                exchanges,
                unlistedInstruments,
                riskLevel,
                kidsRecommended,
                page: instrument.currentResultsPage ? instrument.currentResultsPage + 1 : 1,
                jurisdiction: identity.user!.jurisdiction,
                instruments: instrument.currentSearchList.instrumentIds,
            })

            if (instrument.nextPageLoadingState === 'loading') {
                // Only load one page of new results at a time
                return
            }

            dispatch(actions.SetNextPageLoadingState('loading'))

            // If we aren't filtering by watchlist/portfolio use the search
            // GET endpoint for caching efficiency, otherwise use the POST endpoint
            const distillEndpoint =
                instrument.currentSearchList.listName === 'All'
                    ? distillApi.instrumentsApi.apiV1InstrumentsGet(requestParameters)
                    : distillApi.instrumentsApi.apiV1InstrumentsPost({searchRequestDto: requestParameters})

            return distillEndpoint.then((data: SearchResponse) => {
                dispatch(actions.IndexInstruments(data.instruments))
                dispatch(
                    actions.AppendResults(
                        data.instruments.map(instrument => instrument.id),
                        data.currentPage,
                    ),
                )
                dispatch(actions.SetNextPageLoadingState('ready'))
            })
        }
    },
    loadAutoInvestSearchResults(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const {instrument} = getState()
            try {
                const {categories, exchanges, unlistedInstruments, riskLevel, instrumentTypes, kidsRecommended} =
                    instrument.currentSearchFilters
                const requestParameters: SearchParams = buildSearchParameters({
                    sort: instrument.currentSearchSort,
                    query: instrument.currentSearchQuery,
                    priceChangeTime: instrument.currentSearchTimePeriod,
                    instrumentTypes: findInstrumentTypeValues(instrumentTypes, instrument.metadata.instrumentTypes),
                    categories,
                    exchanges,
                    unlistedInstruments,
                    riskLevel,
                    kidsRecommended,
                    instruments: instrument.currentSearchList.instrumentIds,
                })

                // To avoid the skeleton results view for every time the text input changes
                const loadingStateDelay = setTimeout(() => {
                    dispatch(actions.SetResultsLoadingState('loading'))
                }, 500)

                // If we aren't filtering by watchlist/portfolio use the search
                // GET endpoint for caching efficiency, otherwise use the POST endpoint
                const distillEndpoint =
                    instrument.currentSearchList.listName === 'All'
                        ? distillApiNewClientToken.instrumentsApiNewClientToken.apiV1InstrumentsSearchV2Get({
                              ...requestParameters,
                              scope: 'autoinvest-diy',
                          })
                        : distillApiNewClientToken.instrumentsApiNewClientToken.apiV1InstrumentsPortfoliosearchV2Post({
                              searchRequestDto: requestParameters,
                              scope: 'autoinvest-diy',
                          })

                return distillEndpoint.then((data: SearchResponse) => {
                    // prevent the recently fetched search results from being set IF the user's search input has changed (i.e. prevent rendering of old results)
                    // note: using getState() for recent input, as value may have changed since instrument data has been stored above.
                    if (getState().instrument.currentSearchInput.trim() === instrument.currentSearchQuery.trim()) {
                        if (instrument.currentSearchQuery.length) {
                            rudderTrack('autoinvest', 'diy_autoinvest_searched', {
                                search_query: getState().instrument.currentSearchInput.trim(),
                            })
                        }
                        dispatch(actions.IndexInstruments(data.instruments))
                        dispatch(
                            actions.SetResults(
                                data.instruments.map(instrument => instrument.id),
                                data.currentPage,
                                data.numberOfPages,
                            ),
                        )
                        dispatch(actions.SetResultsLoadingState('ready'))
                    }
                    clearTimeout(loadingStateDelay)
                })
            } catch (e) {
                dispatch(actions.SetResultsLoadingState('error'))
            }
        }
    },
    loadNextPageAutoInvestSearchResults(): ThunkAction<void> {
        return (dispatch, getState) => {
            const {instrument, identity} = getState()
            const {categories, exchanges, unlistedInstruments, riskLevel, instrumentTypes, kidsRecommended} =
                instrument.currentSearchFilters
            const requestParameters: SearchParams = buildSearchParameters({
                sort: instrument.currentSearchSort,
                query: instrument.currentSearchQuery,
                priceChangeTime: instrument.currentSearchTimePeriod,
                instrumentTypes: findInstrumentTypeValues(instrumentTypes, instrument.metadata.instrumentTypes),
                categories,
                exchanges,
                unlistedInstruments,
                riskLevel,
                kidsRecommended,
                page: instrument.currentResultsPage ? instrument.currentResultsPage + 1 : 1,
                jurisdiction: identity.user!.jurisdiction,
                instruments: instrument.currentSearchList.instrumentIds,
            })

            if (instrument.nextPageLoadingState === 'loading') {
                // Only load one page of new results at a time
                return
            }

            dispatch(actions.SetNextPageLoadingState('loading'))

            // If we aren't filtering by watchlist/portfolio use the search
            // GET endpoint for caching efficiency, otherwise use the POST endpoint
            const distillEndpoint =
                instrument.currentSearchList.listName === 'All'
                    ? distillApiNewClientToken.instrumentsApiNewClientToken.apiV1InstrumentsSearchV2Get({
                          ...requestParameters,
                          scope: 'autoinvest-diy',
                      })
                    : distillApiNewClientToken.instrumentsApiNewClientToken.apiV1InstrumentsPortfoliosearchV2Post({
                          searchRequestDto: requestParameters,
                          scope: 'autoinvest-diy',
                      })

            return distillEndpoint.then((data: SearchResponse) => {
                dispatch(actions.IndexInstruments(data.instruments))
                dispatch(
                    actions.AppendResults(
                        data.instruments.map(instrument => instrument.id),
                        data.currentPage,
                    ),
                )
                dispatch(actions.SetNextPageLoadingState('ready'))
            })
        }
    },
    loadInstrumentRecentlyViewed(ids: string[]): ThunkAction<Promise<void>> {
        return async dispatch => {
            try {
                dispatch(actions.SetRecentlyViewedLoadingState('loading'))
                return distillApi.instrumentsApi
                    .apiV1InstrumentsGet({instruments: ids, query: ''})
                    .then((data: SearchResponse) => {
                        dispatch(actions.IndexInstruments(data.instruments))
                        dispatch(actions.SetRecentlyViewedInstruments(ids))
                        dispatch(actions.SetRecentlyViewedLoadingState('ready'))
                    })
            } catch (e) {
                dispatch(actions.SetRecentlyViewedLoadingState('error'))
            }
        }
    },
    loadWatchlistInstruments(ids: string[]): ThunkAction<Promise<void>> {
        return async dispatch => {
            try {
                const requestParameters: SearchParams = buildSearchParameters({
                    instruments: ids,
                    sort: 'name',
                    perPage: ids.length,
                })
                dispatch(actions.SetWatchlistLoadingState('loading'))

                return distillApi.instrumentsApi
                    .apiV1InstrumentsPost({searchRequestDto: requestParameters})
                    .then((data: SearchResponse) => {
                        dispatch(actions.IndexInstruments(data.instruments))
                        dispatch(actions.SetWatchlistInstruments(ids))
                        dispatch(actions.SetWatchlistLoadingState('ready'))
                    })
            } catch (e) {
                dispatch(actions.SetWatchlistLoadingState('error'))
            }
        }
    },
    loadPortfolioInstruments(instrumentIds: string[], allPages: boolean): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const {instrument, identity} = getState()

            const instrumentTypeValues = findInstrumentTypeValues(
                instrument.portfolioInstrumentTypes,
                instrument.metadata.instrumentTypes,
            )

            try {
                const requestParameters: SearchParams = buildSearchParameters({
                    sort: identity.portfolioSortPreference === 'ALPHABETICAL' ? 'name' : 'maintainIdOrder',
                    instruments: instrument.currentPortfolioSortedInstrumentIds || instrumentIds,
                    instrumentTypes: instrumentTypeValues.length > 0 ? instrumentTypeValues : undefined,
                    jurisdiction: identity.user!.jurisdiction,
                    riskLevel: instrument.portfolioInstrumentRiskLevel,
                    exchangeCountry:
                        instrument.portfolioInstrumentExchangeCountry.length > 0
                            ? instrument.portfolioInstrumentExchangeCountry
                            : undefined,
                    tradingStatuses: ['active', 'halt', 'closeonly', 'notrade'], // tradingStatuses affects whether an instrument is displayed in the app.
                    // If an instrument's trading status is 'notrade', it means that it is displayed in the an investor's portfolio but cannot be searched within the app.
                })

                if (allPages) {
                    requestParameters.perPage = instrumentIds.length
                }

                dispatch(actions.SetPortfolioLoadingState('loading'))

                return distillApi.instrumentsApi
                    .apiV1InstrumentsPost({searchRequestDto: requestParameters})
                    .then((data: SearchResponse) => {
                        dispatch(actions.IndexInstruments(data.instruments))
                        dispatch(
                            actions.SetPortfolioInstruments(
                                data.instruments.map(instrument => instrument.id),
                                data.currentPage,
                                data.numberOfPages,
                            ),
                        )
                        dispatch(actions.SetPortfolioLoadingState('ready'))
                    })
            } catch (e) {
                dispatch(actions.SetPortfolioLoadingState('error'))
            }
        }
    },
    loadNextPagePortfolioInstruments(instrumentIds: string[]): ThunkAction<void> {
        return (dispatch, getState) => {
            const {instrument, identity} = getState()
            const instrumentTypeValues = findInstrumentTypeValues(
                instrument.portfolioInstrumentTypes,
                instrument.metadata.instrumentTypes,
            )
            const requestParameters: SearchParams = buildSearchParameters({
                sort: identity.portfolioSortPreference === 'ALPHABETICAL' ? 'name' : 'maintainIdOrder',
                instruments: instrument.currentPortfolioSortedInstrumentIds || instrumentIds,
                instrumentTypes: instrumentTypeValues.length > 0 ? instrumentTypeValues : undefined,
                jurisdiction: identity.user!.jurisdiction,
                riskLevel: instrument.portfolioInstrumentRiskLevel,
                exchangeCountry:
                    instrument.portfolioInstrumentExchangeCountry.length > 0
                        ? instrument.portfolioInstrumentExchangeCountry
                        : undefined,
                tradingStatuses: ['active', 'halt', 'closeonly', 'notrade'], // tradingStatuses affects whether an instrument is displayed in the app.
                // If an instrument's trading status is 'notrade', it means that it is displayed in the an investor's portfolio but cannot be searched within the app.
                page: instrument.currentPortfolioPage ? instrument.currentPortfolioPage + 1 : 1,
            })

            if (instrument.portfolioNextPageLoadingState === 'loading') {
                // Only load one page of new results at a time
                return
            }

            dispatch(actions.SetPortfolioNextPageLoadingState('loading'))

            return distillApi.instrumentsApi
                .apiV1InstrumentsPost({searchRequestDto: requestParameters})
                .then((data: SearchResponse) => {
                    dispatch(actions.IndexInstruments(data.instruments))
                    dispatch(
                        actions.AppendPortfolioResults(
                            data.instruments.map(instrument => instrument.id),
                            data.currentPage,
                        ),
                    )
                    dispatch(actions.SetPortfolioNextPageLoadingState('ready'))
                })
        }
    },
    loadTransferableInInstruments(exchange: ResponseTransferExchange, searchTerm?: string): ThunkAction<Promise<void>> {
        return async dispatch => {
            try {
                dispatch(actions.SetTransferableLoadingState('loading'))

                const updatedExchanges = isUSExchange(exchange) ? UsExchanges : [exchange]

                const getTotalTransferableInstruments = distillApi.instrumentsApi.apiV1InstrumentsCountGet({
                    exchanges: updatedExchanges,
                    tradingStatuses: ['active', 'halt'],
                    query: '',
                })
                const totalTransferableInstruments = await getTotalTransferableInstruments
                // Distill limits max instrument count to 500
                const maxPerPage = totalTransferableInstruments.total < 500 ? totalTransferableInstruments.total : 500

                const requestParameters: SearchParams = buildSearchParameters({
                    sort: 'name',
                    exchanges: updatedExchanges,
                    perPage: maxPerPage,
                    query: searchTerm,
                    tradingStatuses: ['active', 'halt'],
                })

                return distillApi.instrumentsApi.apiV1InstrumentsGet(requestParameters).then((data: SearchResponse) => {
                    dispatch(actions.IndexInstruments(data.instruments))
                    dispatch(actions.SetTransferableIndex(data.instruments.map(instrument => instrument.id)))
                    dispatch(actions.SetTransferableLoadingState('ready'))
                })
            } catch (e) {
                dispatch(actions.SetTransferableLoadingState('error'))
            }
        }
    },
    loadTransferableOutInstruments(exchange: ResponseTransferExchange): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            try {
                dispatch(actions.SetTransferableLoadingState('loading'))

                const instrumentIdsWithHolding = getState().identity.holdings.reduce<string[]>(
                    (previous, instrumentWithHolding) => {
                        let holdingTypes: string[] = []
                        if (exchange === 'NZX') {
                            holdingTypes = ['nz-etf', 'nz-company']
                        } else if (exchange === 'ASX') {
                            holdingTypes = ['au-etf', 'au-company']
                        } else if (isUSExchange(exchange)) {
                            holdingTypes = ['us-etf', 'us-company']
                        }

                        // Only include instruments with a holding of at least 1 share.  Only whole shares can be transferred out.
                        return holdingTypes.includes(instrumentWithHolding.holding_type) &&
                            parseFloat(instrumentWithHolding.shares) >= 1
                            ? [...previous, instrumentWithHolding.fund_id]
                            : previous
                    },
                    [],
                )

                if (instrumentIdsWithHolding.length === 0) {
                    dispatch(actions.IndexInstruments([]))
                    dispatch(actions.SetTransferableIndex([]))
                    dispatch(actions.SetTransferableLoadingState('ready'))
                    return
                }
                return distillApi.instrumentsApi
                    .apiV1InstrumentsGet({
                        instruments: instrumentIdsWithHolding,
                        perPage: instrumentIdsWithHolding.length,
                        query: '',
                    })
                    .then((data: SearchResponse) => {
                        dispatch(actions.IndexInstruments(data.instruments))
                        dispatch(actions.SetTransferableIndex(data.instruments.map(({id}) => id)))
                        dispatch(actions.SetTransferableLoadingState('ready'))
                    })
            } catch (e) {
                dispatch(actions.SetTransferableLoadingState('error'))
            }
        }
    },
    loadPremadeAIInstruments(instrumentIds: string[], slug: string): ThunkAction<Promise<void>> {
        return async dispatch => {
            try {
                dispatch(actions.SetPremadeAILoadingState('loading'))

                const requestParameters: SearchParams = buildSearchParameters({
                    sort: 'name',
                    instruments: instrumentIds,
                })
                return distillApi.instrumentsApi.apiV1InstrumentsGet(requestParameters).then((data: SearchResponse) => {
                    const premadeInstrumentIds = data.instruments.map(instrument => instrument.id) || undefined

                    dispatch(actions.IndexInstruments(data.instruments))

                    if (slug === 'global') {
                        dispatch(actions.SetPremadeGlobalAIInstruments(premadeInstrumentIds))
                    }
                    if (slug === 'responsible') {
                        dispatch(actions.SetPremadeResponsibleAIInstruments(premadeInstrumentIds))
                    }
                    if (slug === 'kids') {
                        dispatch(actions.SetPremadeKidsAIInstruments(premadeInstrumentIds))
                    }

                    dispatch(actions.SetPremadeAILoadingState('ready'))
                })
            } catch (e) {
                dispatch(actions.SetPremadeAILoadingState('error'))
            }
        }
    },

    loadDiyAIInstruments(instrumentIds?: string[]): ThunkAction<Promise<void>> {
        return async dispatch => {
            try {
                dispatch(actions.SetDiyAILoadingState('loading'))

                const requestParameters: SearchPostParams = buildSearchParameters({
                    sort: 'marketCap',
                    instruments: instrumentIds,
                    perPage: 100, // this number needs to match the maximum number of instruments you can have in an auto-invest
                })

                return distillApiNewClientToken.instrumentsApiNewClientToken
                    .apiV1InstrumentsPortfoliosearchV2Post({
                        searchRequestDto: requestParameters,
                        scope: 'autoinvest-diy',
                    })
                    .then((data: SearchResponse) => {
                        const diyInstrumentIds = data.instruments.map(instrument => instrument.id) || undefined

                        dispatch(actions.IndexInstruments(data.instruments))
                        dispatch(actions.SetDiyAIInstruments(diyInstrumentIds))

                        return diyInstrumentIds
                    })
                    .then((diyInstrumentIds: string[]) => {
                        if (instrumentIds && diyInstrumentIds.length !== instrumentIds.length) {
                            const unavailableIds = instrumentIds.filter(
                                instrumentId => !diyInstrumentIds.includes(instrumentId),
                            )

                            if (unavailableIds.length) {
                                // We have to call this one at a time as this is the only api that returns otherwise inaccessible instruments.
                                // It's highly unlikely that there's more than one unavailableId in any case
                                const instruments = unavailableIds.map(id =>
                                    distillApi.instrumentsApi.apiV1InstrumentsIdGet({id}),
                                )

                                Promise.all(instruments).then(data => {
                                    const instrumentNames = data.reduce<string[]>((acc, instrument) => {
                                        if (instrument.tradingStatus === 'inactive') {
                                            return [...acc, instrument.name]
                                        }

                                        return acc
                                    }, [])

                                    dispatch(actions.SetdiyAIUnavailableInstrumentNames(instrumentNames))
                                })
                            }
                        }
                    })
                    .finally(() => {
                        dispatch(actions.SetDiyAILoadingState('ready'))
                    })
            } catch (e) {
                dispatch(actions.SetDiyAILoadingState('error'))
            }
        }
    },

    getInstrumentsByIds(instrumentIds: string[]): ThunkAction<void> {
        return async dispatch => {
            try {
                dispatch(actions.SetResultsLoadingState('loading'))
                const requestParameters: SearchParams = buildSearchParameters({
                    instruments: instrumentIds,
                    perPage: instrumentIds.length,
                    tradingStatuses: ['active', 'halt', 'closeonly', 'notrade', 'inactive', 'unknown'],
                })
                const data = await distillApi.instrumentsApi.apiV1InstrumentsPost({searchRequestDto: requestParameters})
                dispatch(actions.IndexInstruments(data.instruments))
                dispatch(actions.SetResultsLoadingState('ready'))
            } catch (e) {
                // silent fail if we weren't able to fetch the instrument via this method
                dispatch(actions.SetResultsLoadingState('error'))
            }
        }
    },

    getSingleInstrumentBySlug(instrumentSlug: string): ThunkAction<Promise<void>> {
        return async dispatch => {
            try {
                dispatch(actions.SetResultsLoadingState('loading'))
                return distillApi.instrumentsApi
                    .apiV1InstrumentsUrlslugUrlSlugGet({urlSlug: instrumentSlug})
                    .then((data: DetailInstrument) => {
                        dispatch(actions.IndexDetailInstrument(data))
                        dispatch(actions.SetResultsLoadingState('ready'))
                    })
            } catch (e) {
                // silent fail if we weren't able to fetch the instrument via this method
                dispatch(actions.SetResultsLoadingState('ready'))
            }
        }
    },
    getSingleInstrumentById(instrumentId: string): ThunkAction<Promise<void>> {
        return async dispatch => {
            try {
                dispatch(actions.SetResultsLoadingState('loading'))
                return distillApi.instrumentsApi
                    .apiV1InstrumentsIdGet({id: instrumentId})
                    .then((data: DetailInstrument) => {
                        dispatch(actions.IndexDetailInstrument(data))
                        dispatch(actions.SetResultsLoadingState('ready'))
                    })
            } catch (e) {
                // silent fail if we weren't able to fetch the instrument via this method
                dispatch(actions.SetResultsLoadingState('ready'))
            }
        }
    },
    // KiwiSaver instruments are only available in distill production V2 endpoint
    getV2SingleInstrumentById(
        instrumentId: string,
        scope: DistillScope,
        isUnderlyingInstrument: boolean = false,
    ): ThunkAction<Promise<void>> {
        return async dispatch => {
            try {
                dispatch(actions.SetResultsLoadingState('loading'))
                return distillApiNewClientToken.instrumentsApiNewClientToken
                    .apiV1InstrumentsSearchV2Get({
                        instruments: [instrumentId],
                        scope,
                        searchFundInvestments: isUnderlyingInstrument,
                        query: '',
                    })
                    .then(data => {
                        if (data.instruments.length === 0) {
                            throw new Error(`Distill instrument with ID ${instrumentId} was not found in Distill`)
                        }
                        dispatch(actions.IndexDetailInstrument(data.instruments[0] as DetailInstrument))
                        dispatch(actions.SetResultsLoadingState('ready'))
                    })
            } catch (e) {
                // silent fail if we weren't able to fetch the instrument via this method
                dispatch(actions.SetResultsLoadingState('ready'))
            }
        }
    },
    getPriceHistoryByInstrumentId(instrumentId: string, startDate: DateTime): ThunkAction<Promise<void>> {
        return async dispatch => {
            try {
                dispatch(actions.SetPriceHistoryLoadingState('loading'))
                return distillApi.instrumentsApi
                    .apiV1InstrumentsIdPricehistoryGet({id: instrumentId, startDate})
                    .then((data: PriceHistoryResponse) => {
                        dispatch(actions.SetPriceHistory(data))
                        dispatch(actions.SetPriceHistoryLoadingState('ready'))
                    })
            } catch (e) {
                // silent fail if we weren't able to fetch the price history via this method
            }
        }
    },
    getChildInstrumentsByParentId(instrumentId: string): ThunkAction<Promise<void>> {
        return async dispatch => {
            try {
                const requestParameters: SearchParams = buildSearchParameters({
                    parentInstrument: instrumentId,
                    tradingStatuses: ['active', 'notrade', 'closeonly'],
                })
                return distillApi.instrumentsApi.apiV1InstrumentsGet(requestParameters).then((data: SearchResponse) => {
                    let childInstrumentIds: string[] = []
                    if (data.total > 0) {
                        childInstrumentIds = data.instruments.map(instrument => instrument.id)
                    }
                    dispatch(actions.SetChildInstrumentIds(instrumentId, childInstrumentIds))
                })
            } catch (e) {
                // silent fail if we weren't able to fetch the instrument via this method
            }
        }
    },
    getPotentialResultsTotal({
        categories,
        exchanges,
        unlistedInstruments,
        riskLevel,
        instrumentTypes,
        kidsRecommended,
    }: State['currentSearchFilters']): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            try {
                const {instrument} = getState()
                dispatch(actions.SetCountResultsLoadingState('loading'))
                const currentSearchQuery = instrument.currentSearchQuery.trim()
                const instrumentTypeValues = findInstrumentTypeValues(
                    instrumentTypes,
                    instrument.metadata.instrumentTypes,
                )
                const instrumentList = instrument.currentSearchList.instrumentIds

                const countRequestDto = {
                    categories: categories.length > 0 ? categories : undefined,
                    exchanges: exchanges.length > 0 ? exchanges : undefined,
                    unlistedInstruments: unlistedInstruments ? unlistedInstruments : undefined,
                    maxRisk: riskLevel ? riskLevel[1] : undefined,
                    minRisk: riskLevel ? riskLevel[0] : undefined,
                    instrumentTypes: instrumentTypeValues.length > 0 ? instrumentTypeValues : undefined,
                    kidsRecommended: kidsRecommended ? kidsRecommended : undefined,
                    query: currentSearchQuery ? currentSearchQuery : '',
                    instruments: instrumentList.length > 0 ? instrumentList : undefined,
                }

                // If we aren't filtering by watchlist/portfolio use the count
                // GET endpoint for caching efficiency, otherwise use the POST endpoint
                const distillEndpoint =
                    instrument.currentSearchList.listName === 'All'
                        ? distillApi.instrumentsApi.apiV1InstrumentsCountGet(countRequestDto)
                        : distillApi.instrumentsApi.apiV1InstrumentsPortfoliocountPost({
                              countRequestDto,
                          })

                return distillEndpoint.then((data: CountResponse) => {
                    dispatch(actions.SetPotentialResultsTotal(data.total))
                    dispatch(actions.SetCountResultsLoadingState('ready'))
                })
            } catch (e) {
                dispatch(actions.SetCountResultsLoadingState('error'))
            }
        }
    },
    getPotentialAutoInvestResultsTotal({
        categories,
        exchanges,
        unlistedInstruments,
        riskLevel,
        instrumentTypes,
        kidsRecommended,
    }: State['currentSearchFilters']): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            try {
                dispatch(actions.SetCountResultsLoadingState('loading'))
                const {instrument} = getState()
                const currentSearchQuery = instrument.currentSearchQuery.trim()
                const instrumentTypeValues = findInstrumentTypeValues(
                    instrumentTypes,
                    instrument.metadata.instrumentTypes,
                )
                const instrumentList = instrument.currentSearchList.instrumentIds

                const countParams = {
                    categories: categories.length > 0 ? categories : undefined,
                    exchanges: exchanges.length > 0 ? exchanges : undefined,
                    unlistedInstruments: unlistedInstruments ? unlistedInstruments : undefined,
                    maxRisk: riskLevel ? riskLevel[1] : undefined,
                    minRisk: riskLevel ? riskLevel[0] : undefined,
                    instrumentTypes: instrumentTypeValues.length > 0 ? instrumentTypeValues : undefined,
                    kidsRecommended: kidsRecommended ? kidsRecommended : undefined,
                    query: currentSearchQuery ? currentSearchQuery : '',
                    instruments: instrumentList.length > 0 ? instrumentList : undefined,
                }
                // If we aren't filtering by watchlist/portfolio use the count
                // GET endpoint for caching efficiency, otherwise use the POST endpoint
                const distillEndpoint =
                    instrument.currentSearchList.listName === 'All'
                        ? distillApiNewClientToken.instrumentsApiNewClientToken.apiV1InstrumentsCountV2Get({
                              ...countParams,
                              scope: 'autoinvest-diy',
                          })
                        : distillApiNewClientToken.instrumentsApiNewClientToken.apiV1InstrumentsPortfoliocountV2Post({
                              countRequestDto: countParams,
                              scope: 'autoinvest-diy',
                          })

                return distillEndpoint.then((data: CountResponse) => {
                    dispatch(actions.SetPotentialResultsTotal(data.total))
                    dispatch(actions.SetCountResultsLoadingState('ready'))
                })
            } catch (e) {
                dispatch(actions.SetCountResultsLoadingState('error'))
            }
        }
    },
    clearAllPortfolioInstrumentSubfilters(instrumentIds: string[]): ThunkAction<void> {
        return (dispatch, getState) => {
            const {instrument, identity} = getState()
            const allInstrumentTypes = instrument.metadata.instrumentTypes.map(type => type.id)

            try {
                const requestParameters: SearchParams = buildSearchParameters({
                    sort: identity.portfolioSortPreference === 'ALPHABETICAL' ? 'name' : 'maintainIdOrder',
                    instruments: instrument.currentPortfolioSortedInstrumentIds || instrumentIds,
                    instrumentTypes: allInstrumentTypes,
                    jurisdiction: identity.user!.jurisdiction,
                    riskLevel: null,
                    exchangeCountry: undefined,
                    tradingStatuses: ['active', 'halt', 'closeonly', 'notrade'],
                })
                dispatch(actions.SetPortfolioLoadingState('loading'))

                return distillApi.instrumentsApi
                    .apiV1InstrumentsPost({searchRequestDto: requestParameters})
                    .then((data: SearchResponse) => {
                        dispatch(actions.IndexInstruments(data.instruments))
                        dispatch(
                            actions.SetPortfolioInstruments(
                                data.instruments.map(instrument => instrument.id),
                                data.currentPage,
                                data.numberOfPages,
                            ),
                        )
                        dispatch(actions.SetPortfolioLoadingState('ready'))
                        dispatch(actions.ClearAllPortfolioInstrumentSubfilters())
                    })
            } catch (e) {
                dispatch(actions.SetPortfolioLoadingState('error'))
            }
        }
    },
    executeQuery(query: string): ThunkAction<void> {
        return (dispatch, getState) => {
            const relevanceSort = getState().instrument.metadata.sorts.filter(sort => sort.id === 'relevance')

            // every time the user query changes, change the sort to 'relevance' (if it's an available option)
            if (query.trim().length > 0 && relevanceSort.length > 0) {
                dispatch(actions.SetCurrentSearchSort(relevanceSort[0].id))
            } else {
                // if it became empty, switch away from the relevance sort
                dispatch(actions.SetCurrentSearchSort(initialState.currentSearchSort))
            }

            // update query and load results
            dispatch(actions.SetCurrentSearchQuery(query.trim()))
        }
    },
    executeSort(newSort: string): ThunkAction<void> {
        return dispatch => {
            dispatch(actions.SetCurrentSearchSort(newSort))
        }
    },
    executeTimePeriodChange(newTimePeriod: string): ThunkAction<void> {
        return dispatch => {
            dispatch(actions.SetCurrentSearchTimePeriod(newTimePeriod as TimePeriod))
        }
    },
    executeRemoveCategoryFromFilter(category: string): ThunkAction<void> {
        return dispatch => {
            dispatch(actions.RemoveCategoryFromFilter(category))
        }
    },
    executeRemoveInstrumentTypeFromFilter(instrumentType: string): ThunkAction<void> {
        return dispatch => {
            dispatch(actions.RemoveInstrumentTypeFromFilter(instrumentType))
        }
    },
    executeRemoveKidsRecommendedFromFilter(): ThunkAction<void> {
        return dispatch => {
            dispatch(actions.RemoveKidsRecommendedFromFilter())
        }
    },
    executeRemoveExchangeFromFilter(exchange: string): ThunkAction<void> {
        return dispatch => {
            dispatch(actions.RemoveExchangeFromFilter(exchange))
        }
    },
    executeRemoveUnlistedInstrumentsFromFilter(): ThunkAction<void> {
        return dispatch => {
            dispatch(actions.RemoveUnlistedInstrumentsFromFilter())
        }
    },
    executeResetRiskLevelFilter(): ThunkAction<void> {
        return dispatch => {
            dispatch(actions.ResetRiskLevelFilter())
        }
    },
    executeClearAllFilters(): ThunkAction<void> {
        return dispatch => {
            dispatch(actions.ClearAllSearchFilters())
        }
    },
    executeClearSearchAndFilters(): ThunkAction<void> {
        return dispatch => {
            dispatch(actions.ClearAllSearchFiltersSorts())
            dispatch(this.executeQuery(''))
        }
    },
    executeSetPortfolioInstrumentExchangeCountry(
        instrumentExchangeCountry: string[],
        instrumentIds: string[],
    ): ThunkAction<void> {
        return dispatch => {
            dispatch(actions.SetPortfolioInstrumentExchangeCountry(instrumentExchangeCountry))
            if (instrumentIds.length > 0) {
                dispatch(this.loadPortfolioInstruments(instrumentIds, false))
            }
        }
    },
    executeSetPortfolioInstrumentTypes(instrumentTypes: string[], instrumentIds: string[]): ThunkAction<void> {
        return dispatch => {
            dispatch(actions.SetPortfolioInstrumentTypes(instrumentTypes))
            if (instrumentIds.length > 0) {
                dispatch(this.loadPortfolioInstruments(instrumentIds, false))
            }
        }
    },
    executeSetPortfolioInstrumentRiskLevel(riskLevel: [number, number], instrumentIds: string[]): ThunkAction<void> {
        return dispatch => {
            dispatch(actions.SetPortfolioInstrumentRiskLevel(riskLevel))
            if (instrumentIds.length > 0) {
                dispatch(this.loadPortfolioInstruments(instrumentIds, false))
            }
        }
    },
    executeSetSearchList(
        listName: searchListName,
        instrumentIds: string[],
        context: 'invest' | 'auto-invest',
    ): ThunkAction<void> {
        return dispatch => {
            dispatch(actions.SetCurrentSearchList(listName, instrumentIds))
            dispatch(this.loadSearch(context))
        }
    },
    initialiseSearchWithCategory(category: string): ThunkAction<void> {
        return dispatch => {
            // Setting a loading state early to prevent any of the previous results to be shown
            dispatch(actions.SetResultsLoadingState('loading'))
            dispatch(actions.ApplyOnlySingleCategoryToFilter(category))
        }
    },
    initialiseSearchWithInstrumentType(instrumentType: string): ThunkAction<void> {
        return dispatch => {
            // Setting a loading state early to prevent any of the previous results to be shown
            dispatch(actions.SetResultsLoadingState('loading'))
            dispatch(actions.ApplyOnlySingleInstrumentTypeToFilter(instrumentType))
        }
    },
    initialiseSearchWithExchange(exchange: string): ThunkAction<void> {
        return dispatch => {
            // Setting a loading state early to prevent any of the previous results to be shown
            dispatch(actions.SetResultsLoadingState('loading'))
            dispatch(actions.ApplyOnlySingleExchangeToFilter(exchange))
        }
    },
    initialiseSearchWithKidsRecommended(): ThunkAction<void> {
        return dispatch => {
            // Setting a loading state early to prevent any of the previous results to be shown
            dispatch(actions.SetResultsLoadingState('loading'))
            dispatch(actions.ApplyOnlyKidsRecommendedToFilter())
        }
    },
    fetchAutoinvestDividends(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            dispatch(actions.SetAutoinvestDividendsLoadingState('loading'))
            try {
                const response = await api.get('autoinvest-dividend/preferences', {
                    acting_as_id: actingAsID(getState()),
                })
                if (response.type === 'autoinvest_dividend_preferences') {
                    dispatch(actions.SetAutoinvestDividendsLoadingState('ready'))
                    dispatch(actions.SetAutoinvestDividends(response))
                    return
                }
                throw new Error()
            } catch (e) {
                dispatch(actions.SetAutoinvestDividendsLoadingState('error'))
            }
        }
    },
    setAutoinvestDividendsPrimaryPreference(
        payload: Omit<Request.AutoinvestDividendDefaultPreference, 'acting_as_id'>,
    ): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            dispatch(actions.SetAutoinvestDividendsLoadingState('loading'))
            try {
                const response = await api.post('autoinvest-dividend/set-primary-preference', {
                    ...payload,
                    acting_as_id: actingAsID(getState()),
                })
                if (response.type === 'autoinvest_dividend_preferences') {
                    dispatch(actions.SetAutoinvestDividendsLoadingState('ready'))
                    dispatch(actions.SetAutoinvestDividends(response))
                    return
                }
                throw new Error()
            } catch (e) {
                dispatch(actions.SetAutoinvestDividendsLoadingState('error'))
            }
        }
    },
    setFundPreference(payload: Omit<Request.AutoinvestDividendPreference, 'acting_as_id'>): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            try {
                const state = getState().instrument.autoinvestDividends!
                const isAddingFund = payload.preference === true
                dispatch(
                    actions.SetAutoinvestDividends({
                        ...state!,
                        funds: isAddingFund
                            ? [...state!.funds, payload.fund_id]
                            : state!.funds.filter(fundId => fundId !== payload.fund_id),
                    }),
                )

                await api.post('autoinvest-dividend/set-preference', {...payload, acting_as_id: actingAsID(getState())})
            } catch (e) {
                //
            }
        }
    },
}

export type ActionsType = ActionsUnion<typeof actions>
export default {...actions, ...thunkActions}
