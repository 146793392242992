import {useInfiniteQuery, UseInfiniteQueryResult, useQueryClient} from '@tanstack/react-query'
import isEqual from 'lodash.isequal'
import React from 'react'
import {useSearchParams} from 'react-router-dom'
import {
    distillApiNewClientToken,
    InstrumentsInfoResponseDto,
    ListingResponseDto,
    SearchResponseDto,
} from '~/api/distill'
import {ApiV1InstrumentsSearchV2GetRequest} from '~/api/distill/apis'
import {cacheUpdateOrSet} from '~/api/query/client'
import {distillGetFactory, DistillScope} from '~/api/query/distill'
import {BasicInstrument} from '~/store/instrument/types'

// set some of the endpoints as consts so we can use them to perform exact match cache updates via queryClient.setQueryData or cacheUpdateOrSet
const apiEndpointForUrlSlug = 'apiV1InstrumentsGetbyurlslugV2UrlSlugGet'
const apiEndpointForInstrumentSearch = 'apiV1InstrumentsSearchV2Get'

const DEFAULT_TRADING_STATUSES = ['active', 'closeonly', 'halt', 'notrade']

type CommonKeys =
    | 'scope'
    | 'query'
    | 'instrumentTypes'
    | 'categories'
    | 'exchanges'
    | 'unlistedInstruments'
    | 'minRisk'
    | 'maxRisk'
    | 'sort'
    | 'searchFundInvestments'
    | 'instruments'
    | 'tradingStatuses'

export type DistillSearchFilter = {
    [K in CommonKeys]: NonNullable<ApiV1InstrumentsSearchV2GetRequest[K]>
}

export interface DistillSearchFilterParam {
    scope: string
    query: string
    sort: string | undefined
    instrumentTypes: string[] | undefined
    categories: string[] | undefined
    exchanges: string[] | undefined
    unlistedInstruments: boolean | undefined
    minRisk: number
    maxRisk: number
    searchFundInvestments: boolean
    instruments: string[] | undefined
    tradingStatuses: string[] | undefined
}

// list of [queryFieldName, filterFieldName] for all filter fields that are of type string[]
const filterListFields = [
    ['t', 'instrumentTypes'],
    ['c', 'categories'],
    ['e', 'exchanges'],
] as const

export const generateInitialFilter = (scope: DistillScope): DistillSearchFilter => ({
    scope,
    query: '',
    sort: '',
    instrumentTypes: [],
    categories: [],
    exchanges: [],
    unlistedInstruments: false,
    minRisk: 1,
    maxRisk: 7,
    searchFundInvestments: false,
    instruments: [],
    tradingStatuses: DEFAULT_TRADING_STATUSES,
})

interface RemoverMap {
    [label: string]: (setter: React.Dispatch<React.SetStateAction<DistillSearchFilter>>) => void
}

export const generateFilterRemoveChips = (
    filter: DistillSearchFilter,
    info: InstrumentsInfoResponseDto,
): [labels: string[], remover: RemoverMap] => {
    const labels: string[] = []
    const removers: RemoverMap = {}

    for (const [, field] of filterListFields) {
        for (const id of filter[field]) {
            let label = id
            if (field === 'instrumentTypes') {
                label = info.instrumentTypes.find(t => t.id === id)!.name
            }
            labels.push(label)
            removers[label] = setter => {
                setter(filter => ({...filter, [field]: filter[field].filter(v => v !== id)}))
            }
        }
    }
    labels.sort()

    return [labels, removers]
}

export const useSearchFilterFromURL = (
    scope: DistillScope,
): [DistillSearchFilter, React.Dispatch<React.SetStateAction<DistillSearchFilter>>] => {
    const params = useSearchParams({query: ''})[0]

    const initialFilter = generateInitialFilter(scope)

    for (const [k, v] of params) {
        switch (k) {
            case 'q':
                initialFilter.query = v
                break
            case 't':
                initialFilter.instrumentTypes.push(v)
                break
            case 'c':
                initialFilter.categories.push(v)
                break
            case 'e':
                initialFilter.exchanges.push(v)
                break
            case 'r': {
                const match = v.match(/^([1-7])-([1-7])$/)
                if (match) {
                    initialFilter.minRisk = parseInt(match[1], 10)
                    initialFilter.maxRisk = parseInt(match[2], 10)
                }
                break
            }
        }
    }

    return React.useState(initialFilter)
}

export const searchFilterToSearchString = (filter: DistillSearchFilter): string => {
    const search = new URLSearchParams()

    if (filter.query) {
        search.append('q', filter.query)
    }

    for (const [param, field] of filterListFields) {
        for (const value of filter[field]) {
            search.append(param, value)
        }
    }

    search.append('r', `${filter.minRisk}-${filter.maxRisk}`)

    return search.toString()
}

const filterToParams = (filter: DistillSearchFilter): DistillSearchFilterParam => {
    return {
        scope: filter.scope,
        query: filter.query,
        sort: filter.sort || undefined,
        instrumentTypes: filter.instrumentTypes.length === 0 ? undefined : filter.instrumentTypes,
        categories: filter.categories.length === 0 ? undefined : filter.categories,
        exchanges: filter.exchanges.length === 0 ? undefined : filter.exchanges,
        unlistedInstruments: filter.unlistedInstruments || undefined,
        minRisk: filter.minRisk,
        maxRisk: filter.maxRisk,
        searchFundInvestments: filter.searchFundInvestments,
        instruments: filter.instruments.length === 0 ? undefined : filter.instruments,
        tradingStatuses: filter.tradingStatuses.length === 0 ? undefined : filter.tradingStatuses,
    }
}

export const useDistillSearch = (filter: DistillSearchFilter) => {
    const queryClient = useQueryClient()
    const normalisedFilter = filterToParams(filter)
    const {data} = distillGetFactory({
        apiFunctionName: apiEndpointForInstrumentSearch,
    })({...normalisedFilter, perPage: 200}) // TODO https://sharesies.atlassian.net/browse/SUPER-1348 unpick the hardcoded pagination

    // update the query caches for useDistillInstrument and useDistillInstrumentBySlug (which have a custom cache keys to facilitate this) with fresh data if they already exist and there has been a change, and set the data if it doesn't exist
    for (const instrument of data.instruments) {
        // because the shape of SearchDto is quite nested we can't use the normal cacheUpdateOrSet function
        queryClient.setQueryData<SearchResponseDto>([apiEndpointForInstrumentSearch, instrument.id], cachedData => {
            // if the cache already has identical data, we should skip updating to avoid rerender loops
            if (cachedData && isEqual(cachedData.instruments[0], instrument)) {
                return // if the updater function returns undefined, the query data will not be updated
            }

            // if it doesn't have any data at all, or if the data didn't match, set it
            return {
                total: 1,
                currentPage: 1,
                resultsPerPage: 1,
                numberOfPages: 1,
                ...cachedData,
                instruments: [instrument],
            }
        })
        cacheUpdateOrSet<ListingResponseDto>([apiEndpointForUrlSlug, instrument.urlSlug], instrument)
    }

    return data.instruments
}

/**
 * Gets underlying instruments from Distill, based on an initial filter
 *
 * Note this will only return underlying instruments if they exist based on the initial
 * filter
 *
 * @returns {BasicInstrument[]} the list of underlying instruments
 */
export const useDistillSearchUnderlyingInstruments = (filter: DistillSearchFilter): BasicInstrument[] => {
    const instruments = useDistillSearch(filter)
    const underlyingInstrumentIds = Array<string>()

    instruments.map(i => {
        if (i.underlyingInstrumentId) {
            underlyingInstrumentIds.push(i.underlyingInstrumentId)
        }
    })
    const updatedFilter = filter
    updatedFilter.instruments = underlyingInstrumentIds

    // We have to make sure this is true, or we might not get the instruments we want if they're in
    // a scope that the original filter didn't use
    updatedFilter.searchFundInvestments = true
    const underlyingInstruments = useDistillSearch(updatedFilter)

    return underlyingInstruments
}

export const useDistillCount = (filter: DistillSearchFilter) => {
    const normalisedFilter = filterToParams(filter)
    const {data} = distillGetFactory({
        apiFunctionName: 'apiV1InstrumentsCountV2Get',
    })(normalisedFilter)
    return data.total
}

export const useDistillInstrument = ({
    instrumentId,
    scope,
    isUnderlyingInstrument,
}: {
    instrumentId: string
    scope: DistillScope
    isUnderlyingInstrument?: boolean
}) => {
    const {data: searchResponse} = distillGetFactory({
        apiFunctionName: apiEndpointForInstrumentSearch, // note we use the search API not /instruments/{id} because that ignores permissions
        options: {
            queryKey: [apiEndpointForInstrumentSearch, instrumentId], // simplified cache key for this to make it easier for other queries to pre-seed it - the scope and searchFundInvestments params don't affect the data
        },
    })({
        instruments: [instrumentId],
        scope,
        searchFundInvestments: !!isUnderlyingInstrument,
        tradingStatuses: DEFAULT_TRADING_STATUSES,
        query: '',
    })

    if (searchResponse.instruments.length === 0) {
        throw new Error(`Distill instrument with ID ${instrumentId} was not found in Distill`)
    }
    const instrument = searchResponse.instruments[0]
    // update the query cache for useDistillInstrumentBySlug with fresh data if it already exists and has changed, and set the data if it doesn't exist
    cacheUpdateOrSet<ListingResponseDto>([apiEndpointForUrlSlug, instrument.urlSlug], instrument)

    return instrument
}

export const useDistillInstrumentBySlug = (urlSlug: string) => {
    const {data} = distillGetFactory({
        apiFunctionName: apiEndpointForUrlSlug,
        options: {
            queryKey: [apiEndpointForUrlSlug, urlSlug], // simplified cache key for this to make it easier for other queries to pre-seed it
        },
    })({urlSlug, scope: DistillScope.KIWISAVER_ALL_FUNDS})

    return data
}

export const useDistillInfiniteSearch = (
    filter: DistillSearchFilter,
    pageSize: number = 15,
): UseInfiniteQueryResult<SearchResponseDto, unknown> => {
    const normalisedFilter = filterToParams(filter)
    return useInfiniteQuery({
        queryKey: ['useDistillInfiniteSearch', normalisedFilter],
        queryFn: async ({pageParam = 1}) => {
            const response = await distillApiNewClientToken.instrumentsApiNewClientToken.apiV1InstrumentsSearchV2Get({
                ...normalisedFilter,
                page: pageParam,
                perPage: pageSize,
            })
            return response
        },
        getNextPageParam: (lastPage: SearchResponseDto) => {
            const currentPageIndex: number = lastPage.currentPage
            const nextPageIndex: number = currentPageIndex + 1

            if (nextPageIndex > lastPage.numberOfPages) {
                return undefined
            }

            return nextPageIndex
        },
    })
}
