import {Button} from '@design-system/button'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Model, Request, Response} from '~/api/retail/types'
import * as rollbar from '~/api/rollbar/rollbar'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import confirmStyles from '~/global/scss/reused-styles/confirm.scss'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import ExchangeMoneyFees from '~/global/widgets/help-modals/ExchangeMoneyFees'
import {FeeValue, DollarValue, ExchangeRateValue} from '~/global/widgets/number-elements/NumberElements'
import {OrderConfirmation, OrderRow} from '~/global/widgets/order-confirmation/OrderConfirmation'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import actions from '~/store/accounting/actions'
import {StagedExchangeOrder} from '~/store/accounting/types'
import {connect} from '~/store/connect'
import identityActions from '~/store/identity/actions'

const ExchangeMoneyConfirm: React.FunctionComponent<BuyConfirmProps> = ({
    clearStagedExchangeOrder,
    stagedExchangeOrder,
    createExchangeMoneyOrder,
    updateStagedExchangeOrderError,
    markHasSeenFlag,
    jurisdiction,
}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [submitError, setSubmitError] = React.useState(false)

    const handleSubmit = async () => {
        if (!stagedExchangeOrder) {
            return
        }

        setIsSubmitting(true)

        const error = await createExchangeMoneyOrder()
        setIsSubmitting(false)

        if (error) {
            if (error.code === 'fx_us_shares_exchange_but_not_enabled') {
                throw new Error("Sorry, you can't exchange US dollars until you fill out a US tax form.")
            }

            //  When the rate has moved more than 0.2% (20 basis points)
            if (error.code === 'fx_quote_rate_threshold_exceeded') {
                updateStagedExchangeOrderError(true)
                navigate(profileUrl('wallet/exchange-money'))
            } else if (error.code !== 'internal_error') {
                // any error other than reauthentication errors, we want to know about
                rollbar.sendError(`Failed to do FX exchange: ${error.code}`)
            }
            setSubmitError(true)
        } else {
            clearStagedExchangeOrder()
            if (stagedExchangeOrder.targetCurrency === 'aud') {
                markHasSeenFlag('show_au_currency')
            }
            Toast(`Your money has been exchanged. ${jurisdiction === 'nz' ? 'Mīharo!' : 'Amazing!'}`)
            navigate(profileUrl('wallet'))
        }
    }

    if (
        !stagedExchangeOrder ||
        !stagedExchangeOrder.sourceAmount ||
        !stagedExchangeOrder.exchangeFee ||
        !stagedExchangeOrder.amountToExchange ||
        !stagedExchangeOrder.targetAmount
    ) {
        return <WeSlippedUp />
    }

    const {sourceCurrency, sourceAmount, amountToExchange, targetCurrency, targetAmount, exchangeRate, exchangeFee} =
        stagedExchangeOrder

    const gatherItems = (): OrderRow[] => [
        {description: 'From', value: <DollarValue value={sourceAmount} currency={sourceCurrency} roundDown />},
        {
            description: 'Exchange rate',
            value: exchangeRate ? (
                <ExchangeRateValue
                    sourceCurrency={sourceCurrency}
                    targetCurrency={targetCurrency}
                    exchangeRate={parseFloat(exchangeRate)}
                />
            ) : (
                '-'
            ),
        },
        {
            description: (
                <>
                    Exchange fee <ExchangeMoneyFees />
                </>
            ),
            value: <FeeValue value={exchangeFee} currency={sourceCurrency} />,
        },
        {
            description: `${sourceCurrency.toLocaleUpperCase()} to exchange`,
            value: <DollarValue value={amountToExchange} currency={sourceCurrency} />,
        },
    ]

    return (
        <>
            <Toolbar dataTestId="toolbar--exchange-money-confirm" leftButton="back" title="Confirm your exchange" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <OrderConfirmation
                    title={`${sourceCurrency.toLocaleUpperCase()} to ${targetCurrency.toLocaleUpperCase()}`}
                    items={gatherItems()}
                    total={{
                        description: `${targetCurrency.toLocaleUpperCase()} to receive`,
                        value: <DollarValue value={targetAmount} currency={targetCurrency} roundDown />,
                    }}
                />
                {submitError && (
                    <p className={confirmStyles.submitError}>We couldn’t complete the order, please try again.</p>
                )}
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--confirm-exchange"
                    label="Confirm exchange"
                    processing={isSubmitting}
                    onClick={handleSubmit}
                />
            </ActionBar>
        </>
    )
}

interface StoreProps {
    stagedExchangeOrder?: StagedExchangeOrder
    jurisdiction: Model.User['jurisdiction']
}

interface DispatchProps {
    createExchangeMoneyOrder(): Promise<void | Response.Error>
    clearStagedExchangeOrder(): void
    updateStagedExchangeOrderError(errorState: boolean): void
    markHasSeenFlag(flag: Request.CustomerMarkHasSeenFlag['flag']): void
}

type BuyConfirmProps = StoreProps & DispatchProps

export default connect<StoreProps, DispatchProps, {}>(
    ({accounting, identity}) => ({
        stagedExchangeOrder: accounting.stagedExchangeOrder,
        jurisdiction: identity.user!.jurisdiction,
    }),
    dispatch => ({
        createExchangeMoneyOrder: () => dispatch(actions.CreateExchangeMoneyOrder()),
        clearStagedExchangeOrder: () => dispatch(actions.ClearStagedExchangeOrder()),
        updateStagedExchangeOrderError: errorState => dispatch(actions.UpdateStagedExchangeOrderError(errorState)),
        markHasSeenFlag: flag => dispatch(identityActions.MarkHasSeenFlag(flag)),
    }),
)(ExchangeMoneyConfirm)
