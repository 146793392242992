import {Button} from '@design-system/button'
import React from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import {useRetailPost} from '~/api/query/retail'
import * as rollbar from '~/api/rollbar/rollbar'
import {spacing} from '~/global/scss/helpers'
import {useActor} from '~/global/state-hooks/retail/useActor'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {humaniseBankAccountWithHyphens} from '~/global/utils/humanise-bank-account/humaniseBankAccount'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import OrderConfirmation from '~/global/widgets/order-confirmation/OrderConfirmation'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {WithdrawalOrder} from '~/sections/wallet/sections/withdrawals/Withdrawals'
import {useFormattedFastFee} from '~/sections/wallet/sections/withdrawals/utils/use-formatted-fast-fee/useFormattedFastFee'
import FastFeeModal from '~/sections/wallet/sections/withdrawals/widgets/fast-fee-modal/FastFeeModal'
import {WithdrawDollarValue} from '~/sections/wallet/sections/withdrawals/widgets/withdraw-dollar-value/WithdrawDollarValue'
import {useHistoryState, useWalletPortfolio} from '~/sections/wallet/state/local'
import {useLegacyUserData} from '~/sections/wallet/state/retail'
import styles from './Confirm.scss'

export const WITHDRAW_MESSAGE = 'Nice! We’ve got your withdrawal request 📬'

export const WithdrawalConfirm = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const {default_display_currency: currency} = useActor()
    const {id: walletPortfolioId} = useWalletPortfolio()
    const [withdrawalOrder, setWithdrawalOrder] = useHistoryState<WithdrawalOrder | undefined>(
        'withdrawalOrder',
        undefined,
    )
    const {jurisdiction} = useLegacyUserData()
    const [submitProcessing, setSubmitProcessing] = React.useState(false)
    const feeRate = useFormattedFastFee()
    const submitWithdrawal = useRetailPost({
        path: 'withdrawals/:portfolio_id/create',
        pathParams: {
            portfolio_id: walletPortfolioId,
        },
        queryCacheToInvalidate: ['profiles'], // big balance number on wallet screen to return to
    })

    if (!withdrawalOrder) {
        return <Navigate to={profileUrl('wallet/:portfolioId/withdrawal', {portfolioId: walletPortfolioId})} replace />
    }

    const {amount, bank_account, bank_account_name, bank_bsb, withdrawal_cost, method} = withdrawalOrder
    const isFast = Boolean(method && method === 'fast') // must have selected the fast method in the options step

    const handleSubmit = async () => {
        setSubmitProcessing(true)

        try {
            await submitWithdrawal.mutateAsync(
                {
                    amount,
                    bank_account,
                    bank_account_name,
                    bank_bsb,
                    jurisdiction,
                    expected_fee: isFast ? withdrawal_cost?.expected_fee : undefined,
                    fast: isFast,
                },
                {
                    onSuccess: response => {
                        // withdrawals has a few 'success' cases that we need to handle
                        switch (response.type) {
                            case 'withdrawal_success_response':
                                // First we ensure they can't accidentally resubmit this order
                                setWithdrawalOrder(undefined)
                                Toast(WITHDRAW_MESSAGE)
                                navigate(profileUrl('wallet/:portfolioId', {portfolioId: walletPortfolioId}))
                                break
                            case 'withdrawal_verification_required':
                                navigate(
                                    profileUrl('wallet/:portfolioId/withdrawal/verify/:communicationType', {
                                        communicationType: response.communication_type ?? 'not_sent', // default to not_sent if the backend doesn't send us a type
                                        portfolioId: walletPortfolioId,
                                    }),
                                    {
                                        state: {
                                            withdrawalOrder,
                                        },
                                    },
                                )
                                break
                            case 'withdrawal_invalid_fee':
                                // invalid fee can be from fast withdrawals only, so only need to go back to the start to try to validate the fee again
                                Toast('There was an error submitting your withdrawal request')
                                navigate(-2)
                                break
                            default:
                                assertNever(response)
                        }
                    },
                    onError(error) {
                        switch (error.type) {
                            case 'account_restricted':
                            case 'internal_server_error':
                                throw new Error(error.type)
                            case 'error':
                            case 'validation_error':
                                Toast('There was an error submitting your withdrawal request')
                                // form validation errors shouldn't occur here, as the form should have been validated
                                // against the backend before reaching this point - but send them back to the form
                                // anyway. we don't immediately display these verification errors to the user on display of
                                // the form, but when they submit there they will get them
                                if (isFast) {
                                    navigate(-2)
                                } else {
                                    navigate(-1)
                                }
                                break
                            default:
                                assertNever(error)
                        }
                    },
                },
            )
        } catch (error) {
            // We don't know how to handle these errors, so just show a generic error message.
            Toast('There was an error submitting your withdrawal request')
            // Make sure we log the exception to Rollbar, as we've caught it here and not rethrown it
            if (error instanceof Error && error.message) {
                rollbar.sendError(`Withdrawals confirm: unexpected error`, {
                    message: error.message,
                })
            }
        } finally {
            setSubmitProcessing(false)
        }
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--withdraw-confirm" leftButton="back" title="Confirm your withdrawal" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <OrderConfirmation
                    title="Cash"
                    subtitle={`${currency.toUpperCase()} cash withdrawal`}
                    items={[
                        {
                            description: 'Amount',
                            value: <DollarValue value={amount} currency={currency} />,
                            isBold: true,
                        },
                        {
                            description: 'Bank account',
                            value: `${
                                bank_bsb && `${humaniseBankAccountWithHyphens(bank_bsb, jurisdiction)} `
                            }${humaniseBankAccountWithHyphens(bank_account, jurisdiction)}`,
                            isBold: true,
                        },
                        ...(isFast && withdrawal_cost
                            ? [
                                  {
                                      description: (
                                          <>
                                              Estimated fast withdrawal fee <FastFeeModal isEstimate />
                                          </>
                                      ),
                                      value: <DollarValue value={withdrawal_cost.expected_fee} currency={currency} />,
                                      explainerText: (
                                          <span className={styles.feeExplainer}>
                                              {feeRate} on the{' '}
                                              <WithdrawDollarValue value={withdrawal_cost.uncleared_amount} /> that is
                                              unsettled
                                          </span>
                                      ),
                                      isBold: true,
                                  },
                              ]
                            : []),
                    ]}
                    total={{
                        description: isFast ? 'Estimated to receive' : 'Amount to receive',
                        value: (
                            <DollarValue
                                value={isFast && withdrawal_cost ? withdrawal_cost.payment_amount : amount}
                                currency={currency}
                            />
                        ),
                    }}
                />
                {isFast && (
                    <div className={styles.terms}>
                        <p>
                            Withdrawals made after 3 PM on Fridays, or on Saturdays or Sundays, will arrive in your
                            account the following business day.
                        </p>
                        <p className={spacing.spaceAbove24}>
                            Your full withdrawal amount will arrive at the same time.
                        </p>
                        <p className={spacing.spaceAbove24}>
                            If your money settles before the faster withdrawal is processed, you won’t be charged the
                            faster withdrawal fee.
                        </p>
                        <p className={spacing.spaceAbove24}>You agree that:</p>
                        <ul className={spacing.spaceAbove24}>
                            <li className={spacing.spaceAbove4}>
                                we’ll pre-pay you the unsettled money due in your trade, minus our faster withdrawal fee
                            </li>
                            <li className={spacing.spaceAbove4}>
                                we’ll then take over completing the trade (we’ll replace you in the settlement process)
                                and will keep the final trade amount when it settles
                            </li>
                            <li className={spacing.spaceAbove4}>
                                if settlement doesn’t occur, we’ll own the shares from the trade as we’ve already
                                pre-paid you the unsettled money, and may sell them to cover our costs.
                            </li>
                        </ul>
                    </div>
                )}
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--fast-withdraw-confirm"
                    label="Confirm"
                    onClick={() => handleSubmit()}
                    processing={submitProcessing}
                />
            </ActionBar>
        </>
    )
}
