import cn from 'classnames'
import React from 'react'
import {accessibility, spacing} from '~/global/scss/helpers'
import {useHistoricDividends, useNextDividend} from '~/global/state-hooks/retail/useAnnouncedCorporateActions'
import {AnnouncedCACard} from '~/global/widgets/corporate-actions/AnnouncedCACard'
import AnnouncedCAList from '~/global/widgets/corporate-actions/AnnouncedCAList'
import detailModalStyles from '~/global/widgets/detail-modals/DetailModal.scss'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {BasicInstrument} from '~/store/instrument/types'

interface DividendDetailProps {
    instrument: BasicInstrument
    showKiwiSaverDetail?: boolean
}

const DividendDetail: React.FC<DividendDetailProps> = ({instrument, showKiwiSaverDetail}) => {
    const [contentExpanded, setContentExpanded] = React.useState(false)
    const nextDividend = useNextDividend(instrument)
    const historicDividends = useHistoricDividends(instrument)

    return (
        <div>
            <h1>Dividends {showKiwiSaverDetail ? ' from your picks' : ''}</h1>
            {showKiwiSaverDetail && (
                <>
                    <div className={detailModalStyles.kiwiSaverDividendWrapper}>
                        <p>
                            When you select companies and ETFs for your investment plan, you invest into a Portfolio
                            Investment Entity (PIE) fund managed by Sharesies—this PIE fund then invests in the specific
                            company or ETF you’ve chosen.
                        </p>
                        <p>
                            This means that if one of your picks announces and issues a dividend, the dividend will be
                            issued directly to the PIE fund, increasing the unit price of the PIE fund and increasing
                            your KiwiSaver balance.
                        </p>
                        <p>
                            Because the dividend is automatically reinvested into the PIE fund, you won’t see the
                            dividend transaction in your transaction history, it’s just reflected in your unit price.
                        </p>
                        {/* ToDo - Make this bold */}
                        <p>
                            The dividend information below relates to the underlying investment, not the Sharesies fund.
                        </p>
                    </div>
                    <div className={detailModalStyles.subHeading}>Dividends</div>
                </>
            )}
            <div className={detailModalStyles.toggleContentWrapper}>
                <p>
                    When an investment makes a profit, it may give some of that profit back to its owners in the form of
                    dividend payments. Dividends are taxed at your income tax rate.
                </p>
                <div
                    className={cn(detailModalStyles.hiddenContentWrapper, {
                        [detailModalStyles.contentShown]: contentExpanded,
                    })}
                >
                    <p>
                        Often, dividends are paid out on a regular basis, for example on a quarterly, semi-annual or
                        annual basis.
                    </p>
                    <p>
                        In addition to regular dividends, investments will sometimes pay out special dividends as a
                        one-off payment.
                    </p>
                    <p>
                        Investments don’t always pay dividends — they could reinvest the profits instead. Dividends are
                        typically paid by mature companies and funds.
                    </p>
                    <p>
                        For more information check out our{' '}
                        <HelpCentreLink auArticle="4983297-dividends" nzArticle="1236445-dividends" />.
                    </p>
                    <div className={detailModalStyles.subHeading}>Gross Dividend Yield (GDY)</div>
                    <p>
                        The GDY is all of the dividend payments from the past year divided by the current share price
                        before tax is deducted. This is shown as a percentage so you can more easily compare the GDY for
                        different investments.
                    </p>
                    <div className={detailModalStyles.subHeading}>Ex dividend date</div>
                    <p>
                        The date when someone is no longer eligible to receive a dividend. You’ll need to own an
                        investment a day or two before this date to be eligible to get the dividend.
                    </p>
                    <div className={detailModalStyles.subHeading}>Record date</div>
                    <p>
                        The date when the number of shareholders and shares is recorded to calculate the dividend
                        payment.
                    </p>
                    <div className={detailModalStyles.subHeading}>Payment date</div>
                    <p>The date the investment has said it will pay the dividend.</p>
                </div>
                <button
                    className={cn(detailModalStyles.expandContentLink, accessibility.button)}
                    role="presentation"
                    type="button"
                    onClick={() => setContentExpanded(!contentExpanded)}
                >
                    {contentExpanded ? 'Show less' : 'Show more'}
                </button>
            </div>
            <div className={cn(detailModalStyles.subHeading, spacing.spaceBelow16)}>Upcoming dividends</div>
            {nextDividend ? (
                <AnnouncedCACard corporateAction={nextDividend} />
            ) : (
                <div className={detailModalStyles.calcBox}>
                    <p>There are no upcoming dividends for {instrument.name}</p>
                </div>
            )}
            {historicDividends.length > 0 && (
                <div className={detailModalStyles.list}>
                    <div className={detailModalStyles.subHeading}>Past dividends</div>
                    <AnnouncedCAList corporateActions={historicDividends} instrument={instrument} />
                </div>
            )}
        </div>
    )
}

export default DividendDetail
