import {Modal} from '@design-system/modal'
import React from 'react'
import {urlFor} from '~/global/routeGenerator'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {Toolbar, ToolbarButton} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import signupStyles from '~/sections/user/sections/sign-up/SignUp.scss'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/identity/actions'

const SignupToolbar: React.FunctionComponent = () => {
    const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false)
    const isDependent = useAppSelector(s => s.identity.user?.is_dependent)
    const preferredName = useAppSelector(s => s.identity.user?.preferred_name)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const exitSignUp = async () => {
        await dispatch(actions.ExitSignUp())
        if (isDependent) {
            sendWrapperAppMessage({type: 'goBack'}) // native kids sign up screens use a 'top sheet' pattern that can be dismissed
        }
        navigate(urlFor('')) // no profile so we switch back to the default
    }

    const exitSignUpAndDeleteKidsAccount = async () => {
        await dispatch(actions.ExitSignUpAndDeleteKidsAccount())
        sendWrapperAppMessage({type: 'goBack'}) // native kids sign up screens use a 'top sheet' pattern that can be dismissed
        navigate(urlFor('')) // no profile so we switch back to the default
    }

    return (
        <Toolbar
            dataTestId="toolbar--signup"
            leftSlot={
                isDependent ? (
                    <div className={signupStyles.exitControl}>
                        <ToolbarButton
                            dataTestId="toolbar--signup-close-button"
                            icon="close"
                            iconTitle="Cancel kid's account signup"
                            onClick={() => setModalIsOpen(true)}
                        />
                        <Modal
                            dataTestId="modal--create-kids-account"
                            isAlert
                            title="Create a Kids Account"
                            isOpen={modalIsOpen}
                            setIsOpen={setModalIsOpen}
                            primaryButton={{
                                label: 'Continue later',
                                onClick: exitSignUp,
                            }}
                            secondaryButton={{
                                label: 'Cancel and delete account',
                                onClick: exitSignUpAndDeleteKidsAccount,
                            }}
                        >
                            <p>You haven't finished creating a Kids Account for {preferredName}.</p>

                            <p>What would you like to do?</p>
                        </Modal>
                    </div>
                ) : (
                    <ToolbarButton
                        dataTestId="toolbar--signup-close-button"
                        icon="close"
                        iconTitle="Cancel signup"
                        onClick={exitSignUp}
                    />
                )
            }
        />
    )
}

export default SignupToolbar
