import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import {Form, Formik} from 'formik'
import {useAtom} from 'jotai'
import React from 'react'
import {page, spacing} from '~/global/scss/helpers'
import {ErrorBox, validate} from '~/global/widgets/form-controls'
import {Email, Select} from '~/global/widgets/form-controls/formik'
import NZXParticipant from '~/global/widgets/help-modals/NZXParticipant'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useSignUpParticipants} from '~/sections/moose/data/retail'
import {FonterraPersonalSignUpCommonProps} from '~/sections/moose/sections/fonterra-personal-sign-up/FonterraPersonalSignUp'
import {
    StagedFonterraPersonalSignUp,
    stagedFonterraPersonalSignUpAtom,
} from '~/sections/moose/sections/fonterra-personal-sign-up/state'
import styles from '~/sections/user/sections/sign-up/pages/prescribed-person/PrescribedPerson.scss'

// NOTE: Similar versions of this component exist elsewhere.
export const PrescribedPerson = ({progressStep, regressStep}: FonterraPersonalSignUpCommonProps) => {
    const participants = useSignUpParticipants().participants
    const [stagedFonterraPersonalSignUp, updateStagedFonterraPersonalSignUp] = useAtom(stagedFonterraPersonalSignUpAtom)

    const prescirbedPersonsModal = (
        <ModalLink
            dataTestId="modal-link--prescribed-persons"
            label="Prescribed Person"
            modalTitle="Prescribed Person"
            primaryButton={{label: 'Ok'}}
        >
            <p>A Prescribed Person is:</p>
            <ul>
                <li>
                    an employee of an NZX Market Participant (which includes a Trading Participant or NZX Advising Firm)
                </li>
                <li>the immediate family of an employee of an NZX Market Participant (partner or dependant child)</li>
                <li>a family company or family trust of a person referred to above; and</li>
                <li>a company, body corporate or other entity controlled by any of the persons referred to above.</li>
            </ul>
        </ModalLink>
    )
    const NZXParticipantModal = (
        <NZXParticipant participants={participants} label="NZX Participant and Advising firms" />
    )

    return (
        <>
            <Toolbar
                dataTestId="toolbar--fonterra-personal-signup-prescribed-person"
                leftButton="back"
                onLeftButtonClick={regressStep}
                title="Prescribed Person"
            />
            <Page>
                <p className={spacing.spaceBelow24}>
                    If you’re an employee of an {NZXParticipantModal} (or an employee’s spouse or dependant kid) you’ll
                    be a {prescirbedPersonsModal}. The employer will let you know if you are one.
                </p>

                <Formik
                    initialValues={{
                        answers: {
                            prescribedPerson: stagedFonterraPersonalSignUp.prescribedPerson?.prescribedPerson,
                            participant: stagedFonterraPersonalSignUp.prescribedPerson?.participant,
                            prescribedEmail: stagedFonterraPersonalSignUp.prescribedPerson?.prescribedEmail,
                        },
                    }}
                    onSubmit={({answers}) => {
                        const newStagedSignUp: StagedFonterraPersonalSignUp = {
                            ...stagedFonterraPersonalSignUp,
                            prescribedPerson: answers,
                        }
                        updateStagedFonterraPersonalSignUp(newStagedSignUp)
                        progressStep()
                    }}
                    validate={values => {
                        return validate.generate({
                            prescribedPerson: [validate.required()],
                            participant: values.answers.prescribedPerson ? [validate.required()] : [],
                            prescribedEmail:
                                values.answers.prescribedPerson && values.answers.participant !== 'Sharesies Limited'
                                    ? [validate.required(), validate.email()]
                                    : [],
                        })({
                            prescribedPerson: values.answers.prescribedPerson,
                            participant: values.answers.participant,
                            prescribedEmail: values.answers.prescribedEmail,
                        })
                    }}
                >
                    {({isValid, values, isSubmitting, setValues}) => (
                        <Form>
                            <p className={spacing.spaceBelow16}>
                                <strong className={styles.heading}>Are you a Prescribed Person?</strong>
                            </p>
                            <p className={cn(spacing.spaceBelow24, page.flexRow)}>
                                <span>
                                    <Button
                                        label="Yes"
                                        dataTestId="button--yes"
                                        type={values.answers.prescribedPerson === true ? 'primary' : 'secondary'}
                                        onClick={() => {
                                            values.answers.prescribedPerson = true
                                            setValues(values)
                                        }}
                                    />
                                </span>
                                <span>
                                    <Button
                                        label="No"
                                        dataTestId="button--no"
                                        type={values.answers.prescribedPerson === false ? 'primary' : 'secondary'}
                                        onClick={() => {
                                            values.answers.prescribedPerson = false
                                            values.answers.participant = undefined
                                            setValues(values)
                                        }}
                                    />
                                </span>
                            </p>
                            {values.answers.prescribedPerson && (
                                <Select
                                    dataTestId="select-participant"
                                    name="answers.participant"
                                    label="Which NZX Participant or Advising firm do you (or your partner or parent) work at?"
                                    placeholder="Select a company"
                                    choices={[{value: '', label: ''}].concat(
                                        participants.map(p => ({value: p, label: p})),
                                    )}
                                    onChange={event => {
                                        values.answers.participant = event.target
                                            .value as typeof values.answers.participant
                                        setValues(values)
                                    }}
                                />
                            )}
                            {values.answers.prescribedPerson &&
                                values.answers.participant &&
                                !['Sharesies', 'Sharesies Limited'].includes(values.answers.participant) && ( // TODO: remove check for 'Sharesies' when we are sure the FE has the new participants list
                                    <>
                                        <p>
                                            <strong className={styles.heading}>Your investment activity</strong>
                                        </p>
                                        <p className={cn(spacing.spaceBelow24)}>
                                            If you’re a Prescribed Person we'll need to email your investing activity
                                            (and the investing activity for any Kids Accounts you manage) to{' '}
                                            {values.answers.participant}.
                                        </p>

                                        <Email
                                            dataTestId="send-email-to-input"
                                            name="answers.prescribedEmail"
                                            label="Email my investing activity to"
                                            placeholder={`Email address for ${values.answers.participant}`}
                                            onChange={event => {
                                                values.answers.prescribedEmail = event.target.value
                                                setValues(values)
                                            }}
                                        />
                                    </>
                                )}
                            <div className={spacing.spaceAbove16}>
                                <ErrorBox message={status} />
                            </div>
                            {values.answers.prescribedPerson !== undefined && (
                                <Button
                                    dataTestId="button--next"
                                    label="Next"
                                    isSubmit
                                    pageButton
                                    disabled={values.answers.prescribedPerson ? !isValid : false}
                                    processing={isSubmitting}
                                />
                            )}
                        </Form>
                    )}
                </Formik>
            </Page>
        </>
    )
}
