import React from 'react'
import {usePortfolioItemsById} from '~/global/state-hooks/mixed-source/usePortfolioItemsById'
import {useReadPortfolio} from '~/global/state-hooks/rakaia/useReadPortfolio'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {NoInstrumentMessage} from '~/global/widgets/no-instrument-message/NoInstrumentMessage'
import portfolioStyles from '~/sections/invest/sections/portfolio/Portfolio.scss'
import PortfolioInvestmentsTile from '~/sections/invest/sections/portfolio/widgets/investments-tile/PortfolioInvestmentsTile'
import PortfolioNextSteps from '~/sections/invest/sections/portfolio/widgets/next-steps/PortfolioNextSteps'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'

const PortfolioInvestmentsGrid: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const profile = useProfile()
    const setRecentlyViewedInstrument = (instrumentId: string) =>
        dispatch(identityActions.SetSearchedFund(instrumentId))

    // From distill
    const currentInstrumentExchangeCountry = useAppSelector(s => s.instrument.portfolioInstrumentExchangeCountry)
    const currentInstrumentTypes = useAppSelector(s => s.instrument.portfolioInstrumentTypes)
    const currentRiskLevelLabel = useAppSelector(s => s.instrument.portfolioRiskLevelLabel)
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const loadedPortfolioItemsIndex = useAppSelector(s => s.instrument.portfolioIndex)

    // From retail-backend
    const investPortfolio = profile.portfolios.find(p => p.product === 'INVEST')!
    const includeSoldInvestments = useAppSelector(s => s.identity.includeSoldInvestments)
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const portfolioFilterPreference = useAppSelector(s => s.identity.portfolioFilterPreference)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)
    const resourcePath = useAppSelector(s => s.instrument.metadata.resourcePath)

    // From rakaia
    const portfolioSummary = useReadPortfolio(investPortfolio.id)
    const hasInvested = portfolioSummary?.cost_basis_max && portfolioSummary.cost_basis_max > 0

    // From mixed sources
    const portfolioItemDetails = usePortfolioItemsById()

    if (!loadedPortfolioItemsIndex.length) {
        const noInvestmentParagraphOne = isDependent ? `${preferredName} doesn’t have any` : 'You don’t have any'

        // Has never invested
        if (!hasInvested) {
            return <PortfolioNextSteps />
        }

        // No RISK instruments message
        if (
            hasInvested &&
            portfolioFilterPreference === 'RISK' &&
            currentRiskLevelLabel &&
            currentRiskLevelLabel !== 'all'
        ) {
            return (
                <NoInstrumentMessage
                    para1={noInvestmentParagraphOne}
                    para2={currentRiskLevelLabel.toLowerCase() + ' investments'}
                />
            )
        }

        // No COUNTRY instruments message
        if (hasInvested && portfolioFilterPreference === 'COUNTRY' && currentInstrumentExchangeCountry) {
            return (
                <NoInstrumentMessage
                    para1={noInvestmentParagraphOne}
                    para2={
                        currentInstrumentExchangeCountry[0] === 'aus'
                            ? 'Australian investments'
                            : currentInstrumentExchangeCountry[0] === 'usa'
                              ? 'US investments'
                              : 'NZ investments'
                    }
                />
            )
        }

        // No TYPES instruments message
        if (hasInvested && portfolioFilterPreference === 'TYPE' && currentInstrumentTypes.length > 0) {
            return (
                <NoInstrumentMessage
                    para1={`${isDependent ? `${preferredName} hasn’t` : `You haven’t`} invested in any`}
                    para2={
                        currentInstrumentTypes[0] === 'ETFs'
                            ? 'exchange-traded funds (ETFs)'
                            : currentInstrumentTypes[0].toLowerCase()
                    }
                />
            )
        }

        // Has invested in the past but currently holds no investments
        if (hasInvested) {
            return (
                <NoInstrumentMessage
                    para1={noInvestmentParagraphOne}
                    para2="investments"
                    linkText="Explore investments"
                />
            )
        }
    }

    return (
        <div className={portfolioStyles.fundList}>
            {loadedPortfolioItemsIndex.map(instrumentId => {
                return portfolioItemDetails[instrumentId] && instrumentsById[instrumentId] ? (
                    <PortfolioInvestmentsTile
                        includeSoldInvestments={includeSoldInvestments}
                        instrument={instrumentsById[instrumentId]}
                        key={instrumentId}
                        portfolioItemDetails={portfolioItemDetails[instrumentId]}
                        resourcePath={resourcePath}
                        setRecentlyViewedInstrument={setRecentlyViewedInstrument}
                    />
                ) : null
            })}
        </div>
    )
}

export default PortfolioInvestmentsGrid
