import React from 'react'
import {Model} from '~/api/retail/types'
import {dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import {RecordPage, RecordRow} from '~/sections/wallet/pages/transaction/widgets/record/Record'

interface TransactionLineDetailsProps {
    transaction: Model.TransactionV2
}
export const GenericRecord: React.FunctionComponent<TransactionLineDetailsProps> = ({transaction}) => {
    return (
        <RecordPage
            title={transaction.description}
            rows={<RecordRow left="Date" right={transaction.timestamp.toFormat(dateFormatWithYear)} />}
            amount={transaction.amount}
            amountCurrency={transaction.currency}
        />
    )
    // TODO - figure out all the special cases in this file by working through the comments below

    // const memoTransform = (memo?: string) => (memo ? ' from ' + memo.replace(/^\s*(?:tfr\s*)?from\s*/i, '') : '')
    /*
    return (
        <>
            <div className={recordStyles.header}>
                <PageBack />
            </div>
            <Page withoutDefaultPadding>
                <div className={recordStyles.viewContainer}>
                    <div className={recordStyles.titleBlock}>
                        <h2>{transaction.description}</h2>
                    </div>
                    <div className={recordStyles.orderContent}>
                        <div className={recordStyles.recordRowContainer}>
                            <RecordRow
                                className={recordStyles.recordRowWithDate}
                                left="Date"
                                right={transaction.timestamp.toFormat(dateFormatWithYear)}
                            />
                            {/*
                            {transaction.withdrawal_order && (
                                <RecordRow left="To account" right={transaction.withdrawal_order.bank_account} />
                            )}
                            {transaction.reason === 'customer deposit' && (
                                <RecordRow left="From" right={memoTransform(transaction.memo).replace('from ', '')} />
                            )}
                            {transaction.reason.includes('subscription') && transaction.memo && (
                                <RecordRow left="From" right={memoTransform(transaction.memo).replace('from ', '')} />
                            )}
                            {transaction.payment_request && transaction.payment_request.fee && (
                                <PaymentRequestFeeRow
                                    fee={transaction.payment_request.fee}
                                    currency={transaction.currency as Currency}
                                    paymentDescription={transaction.payment_request.description}
                                />
                            )}
                            {transaction.charge && transaction.charge.fee && (
                                <RecordRow
                                    left="Card processing fee"
                                    right={
                                        <DollarValue value={transaction.charge.fee} currency={transaction.currency} />
                                    }
                                />
                            )}
                        </div>

                        <p className={recordStyles.orderAmountLabel}>Amount</p>

                        <div className={recordStyles.orderFooter}>
                            <div />
                            <div>
                                <p className={recordStyles.orderAmount}>
                                    <DollarValue value={transaction.amount} currency={transaction.currency} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        </>
    )
    */
}
