import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useIntercom} from 'react-use-intercom'
import LoadingScreen from '~/global/pages/loading-screen/LoadingScreen'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'

/**
 * Displays the Intercom messenger when the user navigates here from a native
 * app Account menu. Navigates back when the messenger is closed. Not used by
 * webapp normally.
 */
export const IntercomMessage = () => {
    const {show: showIntercom, hide: hideIntercom} = useIntercom() // note we'd usually use the useShowIntercom hook here, but we don't want to in this special case. Don't copy this pattern!
    const [messengerShown, setMessengerShown] = React.useState(false)
    const navigate = useNavigate()

    const onCloseMessenger = () => {
        setMessengerShown(false)
        sendWrapperAppMessage({type: 'goBack'})
        navigate(-1)
    }

    const onMessengerShown = () => {
        // intercom is pretty laggy to load, wait a bit!
        setTimeout(() => {
            setMessengerShown(true)
        }, 5000)
    }

    React.useEffect(() => {
        showIntercom()
        window.addEventListener('intercomShown', onMessengerShown)
        window.addEventListener('intercomHidden', onCloseMessenger)

        return () => {
            window.removeEventListener('intercomShown', onMessengerShown)
            window.removeEventListener('intercomHidden', onCloseMessenger)
            hideIntercom()
        }
    }, [])

    return messengerShown ? <LoadingScreen /> : null
}

export default IntercomMessage
