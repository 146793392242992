/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface AmountPutInDetail
 */
export interface AmountPutInDetail {
    /**
     *
     * @type {number}
     * @memberof AmountPutInDetail
     */
    transaction_fees_buys: number
    /**
     *
     * @type {number}
     * @memberof AmountPutInDetail
     */
    unrealised_managed_fund_transaction_fees: number
}

export function AmountPutInDetailFromJSON(json: any): AmountPutInDetail {
    return AmountPutInDetailFromJSONTyped(json, false)
}

export function AmountPutInDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): AmountPutInDetail {
    if (json === undefined || json === null) {
        return json
    }
    return {
        transaction_fees_buys: json['transaction_fees_buys'],
        unrealised_managed_fund_transaction_fees: json['unrealised_managed_fund_transaction_fees'],
    }
}

export function AmountPutInDetailToJSON(value?: AmountPutInDetail | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        transaction_fees_buys: value.transaction_fees_buys,
        unrealised_managed_fund_transaction_fees: value.unrealised_managed_fund_transaction_fees,
    }
}
