import {Button} from '@design-system/button'
import {CheckboxInput} from '@design-system/checkbox-input'
import {useAtom} from 'jotai'
import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {SignUpStepCommonProps} from '~/sections/moose/sections/sign-up/SignUp'
import {stagedSignUpAtom} from '~/sections/moose/sections/sign-up/state'
import {NZ_TC_VERSION} from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/TermsAndConditions'
import styles from './TermsAndConditions.scss'

const TermsAndConditions = ({progressStep, regressStep}: SignUpStepCommonProps) => {
    const [stagedSignUp, updateStagedSignUp] = useAtom(stagedSignUpAtom)
    const [acceptTerms, setAcceptTerms] = React.useState(stagedSignUp.tcVersion !== undefined)

    return (
        <>
            <Toolbar
                dataTestId="toolbar--fonterra-signup-terms-and-conditions"
                leftButton="back"
                onLeftButtonClick={regressStep}
                title="Agree to the terms and conditions"
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <CheckboxInput
                    dataTestId="checkbox--accept-terms"
                    id="accept-terms"
                    name="accept-terms"
                    isTouched={true}
                    value={acceptTerms}
                    label={
                        <>
                            <p>I’ve read and agree to:</p>
                            <ul className={styles.checkboxList}>
                                <li className={styles.checkboxListItem}>
                                    <a
                                        href="https://www.sharesies.nz/terms-and-conditions"
                                        target="_blank"
                                        rel="noopener"
                                        data-testid="link--sharesies-terms-and-conditions"
                                    >
                                        Sharesies General Terms and Conditions
                                    </a>
                                </li>
                                <li className={styles.checkboxListItem}>
                                    <a
                                        href="https://www.sharesies.nz/farmer-trading-service-terms-and-conditions"
                                        target="_blank"
                                        rel="noopener"
                                        data-testid="link--farmer-terms-and-conditions"
                                    >
                                        Farmer Trading Service Terms and Conditions
                                    </a>
                                </li>
                                <li className={styles.checkboxListItem}>
                                    <a
                                        href="https://www.sharesies.nz/farmer-trading-service-privacy-policy"
                                        target="_blank"
                                        rel="noopener"
                                        data-testid="link--privacy-policy"
                                    >
                                        Farmer Trading Service Privacy Policy
                                    </a>
                                </li>
                                <li className={styles.checkboxListItem}>
                                    and{' '}
                                    <a
                                        href="https://intercom.help/sharesies/en/articles/8482855-market-conduct-rules"
                                        target="_blank"
                                        rel="noopener"
                                        data-testid="link--market-conduct"
                                    >
                                        Market Conduct Rules
                                    </a>
                                </li>
                            </ul>
                        </>
                    }
                    onChange={e => {
                        const isChecked = (e.currentTarget as HTMLInputElement).checked
                        setAcceptTerms(isChecked)
                    }}
                />
                <Button
                    dataTestId="button--next"
                    onClick={() => {
                        updateStagedSignUp({...stagedSignUp, tcVersion: NZ_TC_VERSION})
                        progressStep()
                        rudderTrack('fonterra_onboarding', 'terms_and_conditions_agreed_next_clicked')
                    }}
                    label="Next"
                    disabled={!acceptTerms}
                    pageButton
                    isSubmit
                />
            </Page>
        </>
    )
}

export default TermsAndConditions
