import cn from 'classnames'
import React from 'react'
import {CheckSingle} from '~/global/widgets/OLD_icons'
import {CommonProps, commonClassnames, withId} from '../common'
import styles from '../form.scss'
import checkboxStyles from './CheckboxGroup.scss'

export interface Choice<T> {
    value: T
    label: string
}

type CheckboxGroupProps<ValueType extends string> = CommonProps<ValueType[]> & {
    choices: Choice<ValueType>[]
    placeholder?: string
}

const CheckboxGroup = <ValueType extends string>(props: CheckboxGroupProps<ValueType>) => {
    const value: ValueType[] = props.value || []
    return (
        <div className={commonClassnames(props, checkboxStyles.checkbox, checkboxStyles.checkboxGroup)}>
            <h2>{props.label}</h2>
            {props.choices.map((choice: Choice<ValueType>, key: number) => {
                const checked = value.indexOf(choice.value) !== -1
                const id = `${props.id}-${key}`

                return (
                    <label className={checkboxStyles.control} htmlFor={id} key={key}>
                        <input
                            id={id}
                            type="checkbox"
                            name={props.name}
                            disabled={props.disabled}
                            checked={checked}
                            onChange={e => {
                                const newValues = [...value]

                                if (!props.setFieldValue) {
                                    return
                                }

                                const position = newValues.indexOf(choice.value)
                                const present = position !== -1
                                const thisChecked = e.currentTarget.checked

                                if (!present && thisChecked) {
                                    newValues.push(choice.value)
                                }

                                if (present && !thisChecked) {
                                    newValues.splice(position, 1)
                                }

                                props.setFieldValue(newValues.sort())

                                // TODO: need to sort out 'Other' and 'None'
                            }}
                            value={choice.value}
                            onBlur={props.onBlur}
                        />
                        <div
                            className={cn(checkboxStyles.check, {
                                [checkboxStyles.checked]: checked,
                                [checkboxStyles.disabled]: props.disabled,
                            })}
                        >
                            {choice && <CheckSingle />}
                        </div>
                        <div className={checkboxStyles.checkboxLabel}>{choice.label}</div>
                    </label>
                )
            })}
            {(props.helpText || (props.isTouched && props.error)) && (
                <div className={cn(styles.helpSpace, checkboxStyles.helpSpace)}>
                    {props.helpText && <span className={styles.helpText}>{props.helpText}</span>}
                    {props.isTouched && props.error && <span className={styles.error}>{props.error}</span>}
                </div>
            )}
        </div>
    )
}

export const CheckboxGroupWithId = withId(CheckboxGroup)

export default CheckboxGroupWithId
