import {Modal} from '@design-system/modal'
import {useSetAtom} from 'jotai'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useRetailPost} from '~/api/query/retail'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useWalletBreakdown} from '~/global/state-hooks/retail/useWalletBreakdown'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Toast} from '~/global/widgets/toast/Toast'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {WalletPortfolio} from '~/sections/wallet/types'
import {TransferAccountDetails} from '~/store/save/types'
import {stagedTransferAtom} from '../../state/transferState'

interface CloseAccountModalProps {
    isDependent: boolean
    isOpen: boolean
    preferredName: string
    selectedSaveAccount: any
    setIsOpen: (showModal: boolean) => void
}

export const CloseAccountModal: React.FunctionComponent<CloseAccountModalProps> = ({
    isDependent,
    isOpen,
    preferredName,
    selectedSaveAccount,
    setIsOpen,
}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const profile = useProfile()
    const setStagedTransfer = useSetAtom(stagedTransferAtom)
    const {showModalError} = React.useContext(NotificationContext)

    const walletPortfolio = profile.portfolios.find(p => p.product === 'WALLET') as WalletPortfolio // We can do this because currently there is only ONE wallet portfolio per profile
    const walletBreakdown = useWalletBreakdown(walletPortfolio.id)

    const closeAccount = useRetailPost({
        path: 'save/v2/:portfolio_id/close-account',
        pathParams: {portfolio_id: selectedSaveAccount.portfolio_id},
        queryCacheToUpdate: ['profiles'],
        queryCacheToInvalidate: [
            ['save/v2/:portfolio_id', {portfolio_id: selectedSaveAccount.portfolio_id}],
            ['save/v2/:portfolio_id/transaction-history', {portfolio_id: selectedSaveAccount.portfolio_id}],
        ],
    })

    const hasBalance = Number(selectedSaveAccount.balance) > 0

    const hasPositiveBalanceContent = (
        <p>
            Make sure {isDependent ? `${preferredName}’s'` : 'your'} account balance is $0.00 so we can close{' '}
            {isDependent ? 'their' : 'your'} account.
        </p>
    )
    const hasZeroBalanceContent = (
        <>
            <p>There’s no fee to keep a Save account open—even with a $0 balance.</p>
            <p>
                If you’d still rather close, all good! Any remaining interest {isDependent ? 'they' : 'you'} earn will
                be paid into {isDependent ? 'their' : 'your'} Wallet at the end of the month.
            </p>
        </>
    )

    const dependentModalTitle = `Close ${preferredName}’s Save account?`
    const modalTitle = 'Close your Save account?'

    const handleConfirmClose = async () => {
        await closeAccount.mutateAsync(undefined, {
            onSuccess: () => {
                rudderTrack('save', 'close_save_confirm_clicked')
                setIsOpen(false)
                Toast('Save account closed successfully')
                navigate(profileUrl(''))
            },
            onError: response => {
                if (response.type === 'error') {
                    showModalError({message: response.message})
                } else {
                    showModalError({message: 'Your request failed, please try again.'})
                }
            },
        })
    }

    const handleTransferClick = () => {
        const originAccount: TransferAccountDetails = {
            saveAccountId: selectedSaveAccount.id,
            portfolioId: selectedSaveAccount.portfolio_id,
            name: selectedSaveAccount.name,
            balance: selectedSaveAccount.balance,
            type: 'save',
        }

        const targetAccount: TransferAccountDetails = {
            portfolioId: walletPortfolio.id,
            name: 'Wallet',
            type: 'wallet',
            balance: walletBreakdown.instructable_balance,
        }

        setStagedTransfer({
            originAccount,
            targetAccount,
            amount: selectedSaveAccount.balance,
            instruction: 'WITHDRAW',
        })

        navigate(profileUrl('wallet/transfer'))
    }

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={_ => setIsOpen(false)} // The argument `_` will be called as false, but we don't need to use it in this case
            title={hasBalance ? 'Remaining balance' : isDependent ? dependentModalTitle : modalTitle}
            dataTestId="modal--close-save-account"
            primaryButton={{
                label: hasBalance ? 'Withdraw money' : 'Yes, close it now',
                onClick: hasBalance ? () => handleTransferClick() : () => handleConfirmClose(),
            }}
        >
            {hasBalance ? hasPositiveBalanceContent : hasZeroBalanceContent}
        </Modal>
    )
}
