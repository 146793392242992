import {Button} from '@design-system/button'
import {colour} from '@design-system/colour-tokens'
import {HorizontalGauge} from '@design-system/horizontal-gauge'
import {ChevronRight} from '@design-system/icon'
import {useColourMode} from '@design-system/use-colour-mode'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {Link, Navigate, useNavigate} from 'react-router-dom'
import {Response} from '~/api/retail/types'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {spacing} from '~/global/scss/helpers'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {amountIncludingCardFee} from '~/global/utils/card-fee-calculation/cardFeeCalculation'
import {dateFormatShortDayFullMonth} from '~/global/utils/format-date/formatDate'
import {
    useAllSubscriptionPlans,
    useRefreshedSubscriptionPlan,
} from '~/global/utils/subscription-hooks/subscriptionHooks'
import {subscriptionName} from '~/global/utils/subscription-name/subscriptionName'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {formatPaymentMethod} from '~/sections/user/sections/settings/sections/plans/pages/payment-method/PlanPaymentMethod'
import {useLastDayOfSubscription} from '~/sections/user/sections/settings/sections/plans/utils/subscription/subscription'
import {FreeForStaffFlag} from '~/sections/user/sections/settings/sections/plans/widgets/free-for-staff-flag/FreeForStaffFlag'
import {useAppSelector} from '~/store/hooks'
import {selectHasStaffBenefits} from '~/store/identity/selectors'
import PlanCancelModal from '../../widgets/modals/PlanCancelModal'
import PlanReactivateModal from '../../widgets/modals/PlanReactivateModal'
import {PlanInclusions} from '../select/PlanSelect'
import styles from './PlanView.scss'

interface PaymentFrequencyNoticeProps {
    currentSubscription: Response.SubscriptionCurrentV2 | undefined
    isDependent: boolean
    preferredName: string
}

export const PaymentFrequencyNotice: React.FC<PaymentFrequencyNoticeProps> = ({
    currentSubscription,
    isDependent,
    preferredName,
}) => {
    if (
        currentSubscription &&
        currentSubscription.auto_renew &&
        currentSubscription.auto_renew_into_billing_cycle !== currentSubscription.billing_cycle
    ) {
        return (
            <div className={styles.planAlert}>
                <strong>Payment frequency changed</strong>
                <p>
                    {isDependent ? `${preferredName} will` : 'You’ll'} start paying{' '}
                    {currentSubscription.auto_renew_into_billing_cycle === 'ANNUAL' ? 'annually' : 'monthly'} from{' '}
                    {currentSubscription.expires.toFormat(dateFormatShortDayFullMonth)}.
                </p>
            </div>
        )
    }

    return null
}

const PlanView: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const colourMode = useColourMode()

    const allPlans = useAllSubscriptionPlans({includeUnselectablePlans: false})
    const [currentSubscription, currentSubscriptionLoaded] = useRefreshedSubscriptionPlan()
    const hasStaffBenefits = useAppSelector(selectHasStaffBenefits)
    const {jurisdiction, homeCurrency, isDependent, preferredName, hasAnnualPlansFlag} = useAppSelector(s => ({
        isDependent: s.identity.user!.is_dependent,
        jurisdiction: s.identity.user!.jurisdiction,
        homeCurrency: s.identity.user!.home_currency,
        preferredName: s.identity.user!.preferred_name,
        hasAnnualPlansFlag: s.identity!.flags.annual_plans,
    }))
    const lastDayOfSubscription = useLastDayOfSubscription(currentSubscription?.expires)
    const owner = useProfileOwner()

    if (owner.owner_type === 'ORGANISATION') {
        // No plans for organisations
        return <Navigate to={profileUrl('settings')} replace />
    }

    if (!currentSubscriptionLoaded || !allPlans) {
        return <Loading isPineapple />
    }

    if (!currentSubscription) {
        return <Navigate to={profileUrl('settings/plan/select')} replace />
    }

    const investCoverageData = currentSubscription.coverage.find(c => c.type === 'INVEST')
    const autoInvestCoverageData = currentSubscription.coverage.find(c => c.type === 'AUTO_INVEST')

    if (!investCoverageData || !autoInvestCoverageData) {
        return <WeSlippedUp />
    }

    const {has_market_depth, includes_free_roundups, includes_recurring_payments, includes_us_live_data} =
        currentSubscription.plan
    const renewalDate = currentSubscription.expires.toFormat(dateFormatShortDayFullMonth)

    const daysLeft = (toDate: DateTime) => {
        const diff = toDate.diff(DateTime.now(), 'days')

        return diff.toObject().days ? Math.ceil(diff.toObject().days!) : 0
    }

    const renewalInfoText =
        currentSubscription.coverage_resets < currentSubscription.expires || currentSubscription.auto_renew
            ? `${isDependent ? `${preferredName}’s` : 'Your'} plan coverage renews in ${daysLeft(
                  currentSubscription.coverage_resets,
              )} days`
            : `Ends in ${daysLeft(currentSubscription.expires)} days`

    const planName = subscriptionName(currentSubscription.plan)

    const nextBillingCycle = currentSubscription?.auto_renew_into_billing_cycle ?? currentSubscription?.billing_cycle
    const nextBillingInfo =
        currentSubscription.auto_renew_into_plan?.billing_options[nextBillingCycle] ??
        currentSubscription.plan.billing_options[nextBillingCycle]

    const bottomText = () => {
        if (hasStaffBenefits) {
            return 'Don’t worry about payment details – monthly subscription plans are FREE for Sharesies staff.'
        }

        if (currentSubscription.auto_renew) {
            const priceWithFee =
                currentSubscription.payment_method === 'CARD'
                    ? amountIncludingCardFee(
                          nextBillingInfo.price_on_renewal!,
                          currentSubscription.plan.currency as 'nzd' | 'aud',
                      )
                    : nextBillingInfo.price_on_renewal

            return `Your next payment will be on ${renewalDate}, and $${priceWithFee} ${homeCurrency.toUpperCase()} will be deducted from your ${formatPaymentMethod(
                currentSubscription.payment_method,
            ).toLowerCase()}.`
        }

        return ''
    }

    const yourOrKidsName = isDependent ? `${preferredName}’s` : 'your'

    const AUConverageInfo = (
        <>
            For orders (or parts of an order) over {yourOrKidsName} coverage,{' '}
            <Link
                to={jurisdiction === 'nz' ? 'https://sharesies.nz/pricing' : 'https://sharesies.com.au/pricing'}
                target="_blank"
                rel="noreferrer"
            >
                pay as you go pricing
            </Link>{' '}
            applies.
        </>
    )
    const NZCoverageInfo = (
        <>
            For orders (or parts of an order) over {yourOrKidsName} coverage, the 1.9% transaction fee (and per order
            fee caps) apply.
        </>
    )

    return (
        <>
            <Toolbar
                leftButton="settings"
                dataTestId="toolbar--subscription-select"
                title={isDependent ? `${preferredName}’s plan` : 'Your plan'}
            />
            <Page overrideDefaultTopPadding="none">
                <>
                    <PaymentFrequencyNotice
                        currentSubscription={currentSubscription}
                        isDependent={isDependent}
                        preferredName={preferredName}
                    />
                    {!currentSubscription.auto_renew && (
                        <div className={styles.planAlert}>
                            <strong>Plan cancelled</strong>
                            <p>
                                You’ve cancelled {yourOrKidsName} {planName}
                                {hasStaffBenefits && ' (FREE for Sharesies staff)'}. It’ll stay active until{' '}
                                {lastDayOfSubscription}.
                            </p>
                        </div>
                    )}
                    {currentSubscription.auto_renew && currentSubscription.auto_renew_into_plan && (
                        <div className={styles.planAlert}>
                            <strong>Plan changed</strong>
                            <p>
                                You’ll change to the {subscriptionName(currentSubscription.auto_renew_into_plan)} on{' '}
                                {renewalDate}.
                            </p>
                        </div>
                    )}
                    <div className={styles.planInfoCard}>
                        <div className={styles.header}>
                            <h2>
                                {planName}{' '}
                                {hasStaffBenefits && <FreeForStaffFlag testIdSuffix={currentSubscription.plan.id} />}
                            </h2>
                            <p className={styles.subHeader}>{renewalInfoText}</p>
                        </div>
                        <div className={styles.body}>
                            <h3 className={cn([spacing.spaceBelow12])}>
                                Buy and sell orders covered ({homeCurrency.toUpperCase()})
                            </h3>
                            <HorizontalGauge
                                dataTestId="gauge--buy-and-sell-coverage-used"
                                gaugeColour={colour('Blueberry600', colourMode)}
                                gaugeValue={Number(investCoverageData.used_percentage) * 100}
                            />
                            <div className={cn([spacing.spaceBelow16, styles.progressBarLabel])}>
                                <p>{<DollarValue value={investCoverageData.used} />} used</p>
                                <p>{<DollarValue value={investCoverageData.remaining} />} remaining</p>
                            </div>
                            <h3 className={cn([spacing.spaceBelow12])}>
                                Auto-invest orders covered ({homeCurrency.toUpperCase()})
                            </h3>
                            <HorizontalGauge
                                dataTestId="gauge--auto-invest-coverage-used"
                                gaugeColour={colour('Blueberry600', colourMode)}
                                gaugeValue={Number(autoInvestCoverageData.used_percentage) * 100}
                            />
                            <div className={cn([spacing.spaceBelow16, styles.progressBarLabel])}>
                                <p>{<DollarValue value={autoInvestCoverageData.used} />} used</p>
                                <p>{<DollarValue value={autoInvestCoverageData.remaining} />} remaining</p>
                            </div>
                            <p>{jurisdiction === 'au' ? AUConverageInfo : NZCoverageInfo}</p>
                            <PlanInclusions
                                hasMarketDepth={has_market_depth}
                                hasUsLivePricing={includes_us_live_data}
                                includesFreeRoundups={includes_free_roundups}
                                includesRecurringPayments={includes_recurring_payments}
                                jurisdiction={jurisdiction}
                            />
                        </div>
                    </div>
                </>

                {currentSubscription.auto_renew && (
                    <>
                        <h2 className={cn([spacing.spaceAbove24, styles.paymentDetails])}>Payment details</h2>
                        <p className={cn([styles.subText, spacing.spaceAbove16])}>{bottomText()}</p>

                        {/* Monthly plans are free for those with staff benefits - hide payment information */}
                        {hasAnnualPlansFlag && !hasStaffBenefits && (
                            <>
                                <div
                                    onClick={() => navigate(profileUrl('settings/plan/payment-method'))}
                                    role="button"
                                    className={cn([styles.billingAndPaymentActions, spacing.spaceAbove32])}
                                >
                                    <span>Payment method</span>
                                    <div className={styles.cta}>
                                        <span>{formatPaymentMethod(currentSubscription.payment_method)}</span>
                                        <ChevronRight />
                                    </div>
                                </div>
                                <div
                                    onClick={() => navigate(profileUrl('settings/plan/billing-frequency'))}
                                    role="button"
                                    className={cn([styles.billingAndPaymentActions, spacing.spaceAbove12])}
                                >
                                    <span>Payment frequency</span>
                                    <div className={styles.cta}>
                                        <span>{nextBillingCycle === 'MONTHLY' ? 'Monthly' : 'Annual'}</span>
                                        <ChevronRight />
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}

                <div className={spacing.spaceAbove24}>
                    {allPlans.length > 1 && currentSubscription.auto_renew && (
                        <Button
                            label="Change plan"
                            type="secondary"
                            dataTestId="button--change-subscription"
                            onClick={() => {
                                navigate(profileUrl('settings/plan/select'))
                            }}
                        />
                    )}

                    {currentSubscription.auto_renew && (
                        <PlanCancelModal planName={planName} expires={currentSubscription.expires} />
                    )}

                    {!currentSubscription.auto_renew && (
                        <PlanReactivateModal
                            planName={planName}
                            paymentDate={renewalDate}
                            paymentAmount={nextBillingInfo?.price_on_renewal}
                            paymentMethod={formatPaymentMethod(currentSubscription.payment_method)}
                        />
                    )}
                </div>
            </Page>
        </>
    )
}

export default PlanView
