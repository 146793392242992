import cn from 'classnames'
import React from 'react'
import {useDraggable} from 'react-use-draggable-scroll'
import {accessibility} from '~/global/scss/helpers'
import {convertToKebabCase} from '~/global/utils/convert-to-kebab-case/convertToKebabCase'
import styles from './AiSuggestions.scss'

export const SUGGESTIONS = [
    '📈 Growth companies',
    '💵 Companies who paid dividends',
    '💡 Innovation',
    '🔋 Investing in electric vehicles',
    '🌿 Environmentally friendly',
    '📈 Newly listed companies',
    '💡 Disruptive technology',
    '🧬 Biotech research',
    '👩‍💼 Companies with Female CEOS',
    '⚖️ Equal opportunity employers',
    '📈 Consistently strong performing companies',
    '🍍 Pineapples grow on mars',
    '🐶 Adorable dog is elected president',
    '🍬 Candy replaces money',
]

export const stripEmojiFromSuggestion = (suggestion: string) => {
    return suggestion.substring(suggestion.indexOf(' ') + 1)
}

const AiSuggestions: React.FC<{onChipClick: (value: string) => void; animateIn?: boolean}> = ({
    onChipClick,
    animateIn,
}) => {
    const dragRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>
    const {events: dragEvents} = useDraggable(dragRef)

    // Split suggestions into two rows
    const suggestionsSplit = SUGGESTIONS.reduce<[string[], string[]]>(
        (acc, curr, index) => {
            acc[index % 2].push(curr)
            return acc
        },
        [[], []],
    )

    return (
        <div ref={dragRef} className={cn(styles.suggestions, animateIn && styles.animateInFromRight)} {...dragEvents}>
            {suggestionsSplit.map((suggestions, index) => (
                <ul className={cn(styles.chips, styles.suggestionsInner)} key={index}>
                    {suggestions.map(suggestion => (
                        <li key={suggestion}>
                            <button
                                className={accessibility.button}
                                type="button"
                                data-testid={`button--${convertToKebabCase(suggestion.toLowerCase())}`}
                                onClick={() => onChipClick(stripEmojiFromSuggestion(suggestion))}
                            >
                                {suggestion}
                            </button>
                        </li>
                    ))}
                </ul>
            ))}
        </div>
    )
}

export const PromptSuggestions: React.FC<{promptSuggestions: string[]; onChipClick: (value: string) => void}> = ({
    onChipClick,
    promptSuggestions,
}) => {
    return (
        <div className={styles.suggestions}>
            <ul className={cn(styles.chips, styles.suggestionsInner)}>
                {promptSuggestions.map(suggestion => (
                    <li key={suggestion}>
                        <button
                            className={accessibility.button}
                            type="button"
                            data-testid={`button--${convertToKebabCase(suggestion.toLowerCase())}`}
                            onClick={() => onChipClick(suggestion)}
                        >
                            {suggestion}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default AiSuggestions
