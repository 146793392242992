import {Button} from '@design-system/button'
import {Formik} from 'formik'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {alphanumericOnly} from '~/global/utils/normalize-values/normalizeValues'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {validate} from '~/global/widgets/form-controls'
import {Text} from '~/global/widgets/form-controls/formik'
import {Toast} from '~/global/widgets/toast/Toast'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import transferActions from '~/store/transfer/actions'
import {TransferOrderRegistryDetails} from '~/store/transfer/types'

interface FormValues {
    reference: string
}

const DrsDetailsForm = (props: {nextPage: () => void}) => {
    const notificationContext = React.useContext(NotificationContext)
    const dispatch = useAppDispatch()

    const {stagedTransferOrder, existingReference, registryDetails} = useAppSelector(({transfer}) => ({
        stagedTransferOrder: transfer.stagedTransferOrder!,
        existingReference: transfer.stagedTransferOrder!.reference,
        registryDetails: transfer.registryDetails,
    }))
    const setDrsDetails = (
        reference: TransferOrderRegistryDetails['referenceId'],
        reference_type: TransferOrderRegistryDetails['referenceType'],
    ) => {
        const baseDetails = {
            referenceId: reference,
            referenceType: reference_type,
        }
        return stagedTransferOrder?.registryDetailId
            ? dispatch(
                  transferActions.UpdateRegistryDetails({
                      ...baseDetails,
                      registryDetailId: stagedTransferOrder?.registryDetailId,
                  }),
              )
            : dispatch(transferActions.CreateRegistryDetails(baseDetails))
    }

    return (
        <Formik
            initialValues={{reference: ''} as FormValues}
            validate={values => {
                return validate.generate<typeof values>({
                    reference: [validate.required()],
                })(values)
            }}
            onSubmit={async ({reference}, {setSubmitting}) => {
                try {
                    const isUpdatingRegistryDetail = existingReference && reference !== existingReference
                    const isUsingExistingRegistryDetail =
                        registryDetails.filter(
                            detail => detail.reference === reference && detail.reference_type === 'DRS',
                        ).length > 0
                    // Trigger redux actions
                    const errorDetails = await setDrsDetails(reference, 'DRS')

                    if (errorDetails) {
                        notificationContext.showModalError({message: errorDetails})
                        setSubmitting(false)
                    } else {
                        if (isUpdatingRegistryDetail) {
                            Toast('Investor details updated')
                        } else if (!isUsingExistingRegistryDetail) {
                            // ensure that this is only shown when a registry detail is added for the first time
                            Toast('Investor details added')
                        }
                        props.nextPage()
                    }
                } catch (e) {
                    notificationContext.showModalError({message: unknownErrorMessage})
                    setSubmitting(false)
                    throw e
                }
            }}
        >
            {({values: {reference}, handleSubmit, isSubmitting, isValid}) => {
                const isUpdatingRegistryDetail = existingReference && reference !== existingReference

                return (
                    <form onSubmit={handleSubmit}>
                        <div className={commonStyles.sideMargin}>
                            <Text
                                autoFocus={true}
                                autoComplete="off"
                                dataTestId="text-input--drs"
                                label="Transfer agent account number"
                                name="reference"
                                normalizeValue={alphanumericOnly}
                            />
                            <p className={spacing.spaceBelow32}>
                                You can find this number on your transfer agent account statement or by contacting the
                                platform you’re transferring shares to.
                            </p>
                        </div>

                        <ActionBar>
                            <Button
                                dataTestId="button--add-details"
                                disabled={!isValid}
                                isSubmit
                                label={isUpdatingRegistryDetail ? 'Update details' : 'Add details'}
                                processing={isSubmitting}
                            />
                        </ActionBar>
                    </form>
                )
            }}
        </Formik>
    )
}

export default DrsDetailsForm
