import {TextInput, TextProps} from '@design-system/text-input'
import React from 'react'
import {withFocus, withId} from '../common'

const EmailField = (props: TextProps) => (
    <TextInput
        {...props}
        onChange={e => {
            if (props.setFieldValue) {
                props.setFieldValue(e.currentTarget.value)
            }

            if (props.onChange) {
                props.onChange(e)
            }
        }}
        inputProps={() => ({
            type: 'email',
        })}
    />
)

const EmailWithFocus = withId(withFocus(EmailField))
export default EmailWithFocus
