import {Button} from '@design-system/button'
import {Loader} from '@design-system/loader'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import styles from '~/sections/invest/sections/bank-linking/BankLinking.scss'
import {useBankLinkingReferrer} from '~/sections/invest/sections/bank-linking/hooks/useBankLinkingRererrer'
import actions from '~/store/bankLinking/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'

/**
 * BankLinkingPending is a screen that displays when basiq hasn't loaded the
 * connection, either because
 * - Basiq is processing establishing connection
 * - User action is required
 * - Basiq just can't connect to the bank
 */
const BankLinkingPending: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const referrer = useBankLinkingReferrer()

    const {code} = useAppSelector(({bankLinking}) => bankLinking.bankLinkingPending)!
    const [submitting, setSubmitting] = React.useState(false)

    const back = () => {
        if (referrer === 'linked-bank-topup') {
            return navigate(profileUrl('wallet'))
        }
        navigate(profileUrl(referrer))
    }

    const naviateToIntro = () => {
        if (referrer === 'linked-bank-topup') {
            return navigate(profileUrl(`wallet/linked-bank-topup`), {replace: true})
        }
        return navigate(profileUrl(`${referrer}/roundups`), {replace: true})
    }

    const unlinkBank = async () => {
        setSubmitting(true)
        await dispatch(actions.UnlinkBank())
    }

    const action = async (action: 'retry-linking' | 'exit-flow' | 'unlink-and-exit') => {
        if (action === 'unlink-and-exit') {
            await unlinkBank()
            back()
        } else if (action === 'exit-flow') {
            back()
        } else if (action === 'retry-linking') {
            unlinkBank()
            naviateToIntro()
        }
    }

    const error = {
        content: <></>,
        actionBar: undefined as
            | undefined
            | {
                  primaryActionText?: string
                  primaryAction?: 'retry-linking' | 'exit-flow' | 'unlink-and-exit'
                  secondaryActionText?: string
                  secondaryAction?: 'retry-linking' | 'exit-flow' | 'unlink-and-exit'
              },
    }

    // Poll backend if connection is loading
    const [polling, setPolling] = React.useState<'polling' | 'polling-longer-than-2-minutes'>('polling')
    const [pollingStartTime] = React.useState(DateTime.now())
    React.useEffect(() => {
        if (code === 'loading-connection') {
            setPolling('polling')
            // Poll the bank linking status every 5 seconds
            const interval = setInterval(async () => {
                const response = await dispatch(actions.FetchBankLinkingStatus())
                if (response === undefined) {
                    return
                } else if (response.type === 'bank_linking_pending') {
                    if (pollingStartTime.diffNow('minutes').minutes <= -1) {
                        setPolling('polling-longer-than-2-minutes')
                    }
                } else if (response.type === 'bank_linking_status') {
                    dispatch(actions.InvalidatePendingState()) // Will unmount this page
                }
            }, 5000)
            return () => clearInterval(interval)
        }
        return
    }, [code])

    switch (code) {
        case 'account-not-accessible-requires-user-action':
            error.content = (
                // basiq reported the bank requires action from the user
                <>
                    <h1 className={spacing.spaceBelow16}>Action needed</h1>
                    <p className={spacing.spaceBelow12}>Your bank requires you to take action before we can connect.</p>

                    <h2 className={cn(spacing.spaceAbove12, spacing.spaceBelow4)}>You need to:</h2>
                    <ol className={cn(spacing.spaceAbove4, styles.listPadding)}>
                        <li>Log in to your internet banking</li>
                        <li>Navigate to accounts and transactions</li>
                        <li>Address any requests or popups requiring action from the bank</li>
                        <li>For further assistance, please contact your bank</li>
                    </ol>
                    <p>You can exit this setup and come back once you’ve resolved any issues with your bank.</p>
                </>
            )
            error.actionBar = {
                primaryActionText: 'Exit and come back later',
                primaryAction: 'unlink-and-exit',
            }
            break
        case 'invalid-credentials':
        case 'mfa-challenge-failed':
        case 'no-credentials-supplied':
            error.content = (
                <>
                    <h1 className={spacing.spaceBelow16}>Linking failed</h1>
                    <p>
                        The credentials you supplied were incorrect, or no credentials were supplied. Please try linking
                        again.
                    </p>
                </>
            )
            error.actionBar = {
                primaryActionText: 'Try linking your bank again',
                primaryAction: 'retry-linking',
                secondaryActionText: 'Exit bank linking',
                secondaryAction: 'exit-flow',
            }
            break
        case 'invalid-credentials-locked':
            error.content = (
                <>
                    <h1 className={spacing.spaceBelow16}>Linking failed</h1>
                    <p>
                        We can't connect to your bank because your bank account is locked. Please contact your bank
                        directly.
                    </p>
                </>
            )
            error.actionBar = {
                primaryActionText: 'Link with another bank',
                primaryAction: 'retry-linking',
                secondaryActionText: 'Exit bank linking',
                secondaryAction: 'exit-flow',
            }
            break
        case 'loading-connection':
            if (polling === 'polling') {
                error.content = (
                    <>
                        <Loader
                            isPineapple
                            additionalClassName={cn(styles.loading, spacing.spaceAbove80, spacing.spaceBelow80)}
                            size={68}
                        />
                        <h1 className={spacing.spaceBelow16}>We’re connecting to your bank</h1>
                        <p>
                            Some bank data takes a while to load, and this may take a few minutes. If it’s taking too
                            long, you can try again or cancel bank linking.
                        </p>
                    </>
                )
            } else if (polling === 'polling-longer-than-2-minutes') {
                error.content = (
                    <>
                        <h1 className={spacing.spaceBelow16}>
                            It’s taking a little longer to connect to your bank than expected
                        </h1>
                        <p>You can exit and come back to what you were doing later. Things should be fine by then!</p>
                    </>
                )
            }
            error.actionBar = {
                primaryAction: 'retry-linking',
                primaryActionText: 'Try linking your bank again',
                secondaryActionText: 'Exit and come back later',
                secondaryAction: 'exit-flow',
            }
            break
        case 'loading-connection-more-than-1-hour':
            error.content = (
                <>
                    <h1 className={spacing.spaceBelow16}>Linking failed</h1>
                    <p>
                        Unfortunately we couldn’t connect to your bank. You can either try link your bank again, try
                        link a different bank or cancel bank linking.
                    </p>
                </>
            )
            error.actionBar = {
                primaryActionText: 'Try linking your bank again',
                primaryAction: 'retry-linking',
                secondaryActionText: 'Cancel bank linking',
                secondaryAction: 'unlink-and-exit',
            }
            break
        case 'service-unavailable':
        case 'unknown-error':
        case 'temporary-unavailable':
            error.content = (
                <>
                    <h1 className={spacing.spaceBelow16}>Linking failed</h1>
                    <p>Unfortunately we couldn’t connect to your bank. Please come back later, or unlink your bank</p>
                    <p className={spacing.spaceAbove12}>If this problem persists, please contact your bank</p>
                </>
            )
            error.actionBar = {
                primaryActionText: 'Unlink your bank',
                primaryAction: 'retry-linking',
                secondaryActionText: 'I’ll come back later',
                secondaryAction: 'exit-flow',
            }
            break
        default:
            assertNever(code)
    }

    return (
        <>
            <Toolbar leftButton="back" onLeftButtonClick={back} dataTestId="toolbar--banklinking-pending" />
            <Page className={styles.bankLinkingPending}>{error.content}</Page>
            {error.actionBar && (
                <ActionBar className={styles.stackedButtonGroup}>
                    {error.actionBar.primaryAction && (
                        <Button
                            dataTestId="button--next"
                            label={error.actionBar.primaryActionText}
                            type="primary"
                            onClick={() => action(error.actionBar!.primaryAction!)}
                            disabled={submitting}
                        />
                    )}
                    {error.actionBar.secondaryAction && (
                        <Button
                            dataTestId="button--next"
                            label={error.actionBar.secondaryActionText}
                            type="secondary"
                            onClick={() => action(error.actionBar!.secondaryAction!)}
                            disabled={submitting}
                        />
                    )}
                </ActionBar>
            )}
        </>
    )
}

export default BankLinkingPending
