import {useIntercom} from 'react-use-intercom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {useHasFonterraPortfolio} from '~/global/utils/use-has-fonterra-portfolio/useHasFonterraPortfolio'

/**
 * Returns a function that can be called to open the Intercom messenger. If the
 * user has a Fonterra portfolio in the currently selected profile, open the
 * Fonterra Intercom help page instead. Tracks the event in Rudderstack.
 *
 * @example
 * const showIntercom = useShowIntercom()
 * @returns {Function} function that opens the Intercom messenger when executed
 * (except for Fonterra users)
 */
export const useShowIntercom = () => {
    const {show: showIntercom} = useIntercom()
    const hasFonterraPortfolio = useHasFonterraPortfolio()

    return () => {
        if (hasFonterraPortfolio) {
            // do not use intercom messenger for Fonterra users
            window.open('https://intercom.help/fonterra_help/en/', '_blank', 'noopener,noreferrer')
        } else {
            showIntercom()
            rudderTrack('intercom', 'messenger_opened')
        }
    }
}
