import {ClassicCard} from '@braze/web-sdk'
import {Button} from '@design-system/button'
import React from 'react'
import Card from '../card/Card'
import Detail from './AnnouncementDetail'
import styles from './AnnouncementCard.scss'

interface Props {
    className?: string
    card: ClassicCard
    onDismiss?: () => void
}

const AnnouncementCard = ({className, card, onDismiss}: Props) => {
    const [detailVisible, setDetailVisible] = React.useState(false)

    const headingText = card.title
    const imageUrl = card.extras?.image_url || card.imageUrl

    const toggleDetail = () => {
        setDetailVisible(!detailVisible)
    }

    return (
        <>
            <Card image={imageUrl} className={className} contentClassName={styles.card} onDismiss={onDismiss}>
                <strong>{headingText}</strong>
                <Button
                    additionalClassName={styles.button}
                    label="View message"
                    type="primary"
                    isConstantWhite
                    dataTestId="button--announcement-card"
                    onClick={toggleDetail}
                    width="auto"
                />
            </Card>
            <Detail onClose={toggleDetail} isOpen={detailVisible} card={card} />
        </>
    )
}

export default AnnouncementCard
