import {formatNumberWithThousandsSeparator} from '@design-system/format-number-with-thousands-separator'
import {Modal} from '@design-system/modal'
import {withFormik} from 'formik'
import React from 'react'
import {Link} from 'react-router-dom'
import {useRetailPost} from '~/api/query/retail'
import {Model} from '~/api/retail/types'
import type {EventDataTaxonomy} from '~/api/rudderstack/event-taxonomy/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {useWatchlist} from '~/global/state-hooks/retail/useWatchlist'
import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {isMobile} from '~/global/utils/is-mobile/isMobile'
import {isWrapperApp} from '~/global/utils/is-wrapper-app/isWrapperApp'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {validate} from '~/global/widgets/form-controls'
import {StrongCurrency} from '~/global/widgets/form-controls/formik'
import {Toast} from '~/global/widgets/toast/Toast'
import styles from '~/sections/invest/widgets/price-notification-modal/PriceNotificationModal.scss'
import {useAppSelector} from '~/store/hooks'

const showDownloadNativeAppAction = !!(isMobile.Android() || isMobile.iOS())

type PriceChangeNotificationTaxonomy = EventDataTaxonomy['price_change_notification']

interface PriceNotificationModalFormValues {
    priceNotificationAmount: string
}

interface PriceNotificationModalProps {
    isOpen: boolean
    onClose: () => void

    instrumentId: string
    instrumentName: string
    instrumentValue: string
    instrumentCurrency: Currency
    instrumentSymbol: string

    initialPriceNotificationAmount?: string
    hasPriceNotification?: boolean

    extendedHoursInEffect?: boolean

    // Used for analytics tracking to determine where the PriceNotificationModal was initiated
    pageSource:
        | PriceChangeNotificationTaxonomy['notification_set']['page_source']
        | PriceChangeNotificationTaxonomy['notification_modal_opened']['page_source']
        | PriceChangeNotificationTaxonomy['notification_deleted']['page_source']
    portfolioId: string
    hideSeeAllLink?: boolean
    seeAllLinkFonterra?: boolean
}

interface PriceNotificationModalInnerProps extends PriceNotificationModalProps {
    hasPriceNotification?: boolean
    setPriceNotificationAmount: (value: string) => Promise<void>
    removePriceNotification: () => void

    downloadTheAppUrl: string
    customerJurisdiction: 'nz' | 'au' | undefined
}

export const PriceNotificationModalInner = withFormik<
    PriceNotificationModalInnerProps,
    PriceNotificationModalFormValues
>({
    mapPropsToValues: ({initialPriceNotificationAmount}) => ({
        priceNotificationAmount: initialPriceNotificationAmount || '',
    }),
    validateOnChange: true,
    handleSubmit: async ({priceNotificationAmount}, {props}) => {
        await props.setPriceNotificationAmount(priceNotificationAmount)
        if (props.onClose) {
            props.onClose()
        }
    },
    validate: validate.generateWithProps<PriceNotificationModalFormValues, PriceNotificationModalProps>(props => ({
        priceNotificationAmount: [
            validate.required(),
            validate.money({maxDecimalPlaces: 3}),
            validate.notSameValue<PriceNotificationModalFormValues['priceNotificationAmount']>(
                props.instrumentValue,
                `The amount needs to be more or less than $${
                    props.instrumentValue
                } ${props.instrumentCurrency.toUpperCase()}`,
            ),
        ],
    })),
})(({
    // Formik props
    handleSubmit,
    isValid,
    isSubmitting,

    // Passed in props
    isOpen,
    onClose,

    instrumentName,
    instrumentValue,
    instrumentCurrency,
    instrumentSymbol,

    hasPriceNotification,
    removePriceNotification,

    extendedHoursInEffect,

    downloadTheAppUrl,
    customerJurisdiction,
    hideSeeAllLink,
    seeAllLinkFonterra,
    portfolioId,
}) => {
    const profileUrl = useProfileUrl()

    const learnMoreLink =
        customerJurisdiction === 'au'
            ? 'https://intercom.help/sharesies-au/en/articles/7935620-price-notifications'
            : 'https://intercom.help/sharesies/en/articles/7935600-price-notifications'

    const seeAllPath = seeAllLinkFonterra
        ? profileUrl('fonterra/portfolios/:portfolioId/manage/price-notifications', {portfolioId})
        : profileUrl('settings/notifications/price')

    return (
        <Modal
            title={`${hasPriceNotification ? 'Edit your' : 'Set a'} price notification`}
            isOpen={isOpen}
            setIsOpen={v => !v && onClose()}
            onFormSubmit={handleSubmit}
            customZIndex={1051}
            primaryButton={{
                label: hasPriceNotification ? 'Update notification' : 'Set notification',
                disabled: !isValid || isSubmitting,
                onClick: () => handleSubmit(),
            }}
            additionalClassName={styles.modal}
            secondaryButton={
                hasPriceNotification
                    ? {
                          label: 'Delete',
                          onClick: () => removePriceNotification(),
                      }
                    : undefined
            }
            dataTestId="modal--price-notification"
        >
            <p>
                {/* {`We’ll send you a notification when ${instrumentName} reaches this price.`} */}
                {`Get notified when ${instrumentName} reaches a price you set.`}

                {!isWrapperApp() && showDownloadNativeAppAction && (
                    <span className={styles.downloadNativeApp}>
                        &nbsp;Want to get push notifications?&nbsp;
                        <a href={downloadTheAppUrl}>Download the app.</a>
                    </span>
                )}

                {isWrapperApp() && window.nativeAppControls?.isPushNotificationsEnabled() === false && (
                    <span className={styles.downloadNativeApp}>
                        &nbsp;To get push notifications,&nbsp;
                        <a href={profileUrl('settings/notifications')}>turn them on in Settings.</a>
                    </span>
                )}
            </p>

            <StrongCurrency
                dataTestId="price-notification-amount"
                name="priceNotificationAmount"
                label="Price"
                currency={instrumentCurrency}
                decimalPlaces={3}
                helpText={`${instrumentSymbol} price: $${formatNumberWithThousandsSeparator(instrumentValue)}
                    ${instrumentCurrency.toUpperCase()}`}
                additionalClassName={styles.priceWrapper}
            />

            <div className={styles.disclaimer}>
                Price notifications use delayed price info, and prices are checked only once an hour while the market is
                open. You’ll only receive one price notification per investment per day.
                <div className={spacing.spaceAbove16}>
                    <a href={learnMoreLink} target="_blank" rel="noreferrer">
                        Learn more
                    </a>
                    {!hideSeeAllLink && (
                        <span>
                            {' '}
                            or <Link to={seeAllPath}>see all price notifications</Link>
                        </span>
                    )}
                </div>
            </div>

            {extendedHoursInEffect && (
                <AlertCard type="info">
                    <p>Price notifications only monitor regular market hours.</p>
                </AlertCard>
            )}
        </Modal>
    )
})

const DOWNLOAD_THE_APP_URLS: Record<Model.User['jurisdiction'], string> = {
    au: 'https://sharesies.page.link/price-notify-au',
    nz: 'https://sharesies.page.link/price-notify',
}

export const PriceNotificationModal = (props: PriceNotificationModalProps) => {
    const addNotification = useRetailPost({
        path: 'price-notification/:portfolio_id/add',
        pathParams: {portfolio_id: props.portfolioId},
        queryCacheToUpdate: ['price-notification/:portfolio_id/get', {portfolio_id: props.portfolioId}],
        queryCacheToInvalidate: ['price-notification/for-portfolios', {portfolio_ids: props.portfolioId}],
    })

    const deleteNotification = useRetailPost({
        path: 'price-notification/:portfolio_id/delete',
        pathParams: {portfolio_id: props.portfolioId},
        queryCacheToUpdate: ['price-notification/:portfolio_id/get', {portfolio_id: props.portfolioId}],
        queryCacheToInvalidate: ['price-notification/for-portfolios', {portfolio_ids: props.portfolioId}],
    })

    const {jurisdiction, customerId} = useAppSelector(s => ({
        jurisdiction: s.identity.user!.jurisdiction,
        customerId: s.identity.user!.id,
    }))

    const downloadTheAppUrl = DOWNLOAD_THE_APP_URLS[jurisdiction]
    const {instrumentId, instrumentValue} = props

    React.useEffect(() => {
        if (props.isOpen) {
            rudderTrack('price_change_notification', 'notification_modal_opened', {
                instrument_id: instrumentId,
                page_source: props.pageSource,
                link_clicked: props.hasPriceNotification ? 'edit' : 'set',
            })
        }
    }, [props.isOpen])

    const holdings = useAppSelector(s => s.identity.holdings)
    const {watchlistInstrumentIds, addToWatchlist} = useWatchlist()
    const instrumentsInPortfolio = holdings.map(({fund_id}) => fund_id)
    const customerJurisdiction = useAppSelector(s => s.identity.user?.jurisdiction)

    async function setPriceNotification(priceNotificationAmount: string): Promise<void> {
        try {
            await addNotification.mutateAsync({
                acting_as_id: customerId,
                fund_id: instrumentId,
                price_threshold: priceNotificationAmount,
                current_price: instrumentValue,
            })

            const notificationAmount = parseFloat(priceNotificationAmount)
            const isAbove = notificationAmount > parseFloat(instrumentValue)

            rudderTrack('price_change_notification', 'notification_set', {
                instrument_id: instrumentId,
                page_source: props.pageSource,
                price_threshold: notificationAmount,
                viewed_instrument_price: parseFloat(instrumentValue),
                notify_when: isAbove ? 'above' : 'below',
            })

            let message = 'Your price notification is set'

            const instrumentIsInPortfolio = instrumentsInPortfolio.includes(instrumentId)
            const instrumentIsInWatchlist = watchlistInstrumentIds.includes(instrumentId)

            // If instrument isn't in the portfolio and isn't in the watchlist, then add it to the watchlist
            if (!instrumentIsInPortfolio && !instrumentIsInWatchlist) {
                addToWatchlist(instrumentId)

                message += ` and we've added ${props.instrumentSymbol} to your Watchlist`
            }

            Toast(message)
        } catch (error) {
            Toast('Please try again')
        }
    }

    async function removePriceNotification() {
        try {
            await deleteNotification.mutateAsync({
                acting_as_id: customerId,
                fund_id: instrumentId,
            })

            rudderTrack('price_change_notification', 'notification_deleted', {
                instrument_id: instrumentId,
                page_source: props.pageSource,
            })

            Toast('Your price notification has been deleted')
        } catch (error) {
            Toast('Please try again')
        }
    }

    return (
        <PriceNotificationModalInner
            {...props}
            setPriceNotificationAmount={priceNotificationAmount => setPriceNotification(priceNotificationAmount)}
            removePriceNotification={() => removePriceNotification()}
            // Clear out the original price_threshold if someones deletes, then sets a new one, the previous amount won't stick around
            key={props.initialPriceNotificationAmount}
            downloadTheAppUrl={downloadTheAppUrl}
            customerJurisdiction={customerJurisdiction}
        />
    )
}
