import cn from 'classnames'
import React from 'react'
import {useLocation} from 'react-router-dom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {SwitchField} from '~/global/widgets/form-controls'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'

export const AccountPortfolioReturnCalculationContent: React.FC<{withHeading: boolean}> = ({withHeading}) => {
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const includeSoldInvestments = useAppSelector(s => s.identity.includeSoldInvestments)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)
    const dispatch = useAppDispatch()
    const updateIncludeSoldInvestments = (includeSoldInvestments: boolean) =>
        dispatch(identityActions.UpdateIncludeSoldInvestments(includeSoldInvestments))
    const [localIncludeSoldInvestments, setLocalIncludeSoldInvestments] =
        React.useState<boolean>(includeSoldInvestments)
    const postError = 'Sorry, something went wrong, please try again.'
    const path = useLocation().pathname

    const handleIncludeSoldInvestmentsChange = async () => {
        rudderTrack('navigation', 'toggle_clicked', {
            page_path: path,
            label: 'Include sold investments',
            status: !localIncludeSoldInvestments,
        })
        setLocalIncludeSoldInvestments(!localIncludeSoldInvestments)
        const potentialError = await updateIncludeSoldInvestments(!localIncludeSoldInvestments)

        if (potentialError) {
            Toast(postError)
        }
    }

    return (
        <>
            {withHeading && <h2>Calculating your returns</h2>}
            <SwitchField
                additionalClassName={cn(spacing.spaceBelow12, spacing.spaceAbove12)}
                dataTestId="switch--include-sold-investments"
                label="Include sold investments"
                name="include-sold-investments"
                onChange={handleIncludeSoldInvestmentsChange}
                value={localIncludeSoldInvestments}
                isTouched={false}
            />
            <p className={cn(typographyOverrides['as-small-text'], spacing.spaceBelow48)}>
                The money {isDependent ? `${preferredName} has` : 'you’ve'} made or lost from selling investments
                (realised gains or losses) is included when calculating {isDependent ? 'their' : 'your'} returns. This
                applies to {isDependent ? 'their' : 'your'} Portfolio and individual investments.
            </p>
        </>
    )
}

export const PortfolioReturnCalculation: React.FC<{}> = () => {
    return (
        <>
            <Toolbar dataTestId="toolbar--returns-calculation" leftButton="back" title="Returns calculation" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <AccountPortfolioReturnCalculationContent withHeading={false} />
            </Page>
        </>
    )
}
