import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {Request} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import styles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import AddRegistryDetailAddressForm from '~/sections/invest/sections/transfer-shares/sections/asx-transfer-shares/sections/add-registry-details-address/pages/add-registry-details-address-form/AddRegistryDetailsAddressForm'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import transferActions from '~/store/transfer/actions'
import {NEW_SRN_REQUIRED} from '~/store/transfer/types'

const AddRegistryDetailAddress: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const address = useAppSelector(({identity}) => identity.user!.address as Request.RegistryDetailsUpsert['address'])
    const jurisdiction = useAppSelector(({identity}) => identity.user!.jurisdiction)
    const stagedTransferOrder = useAppSelector(({transfer}) => transfer.stagedTransferOrder!)

    // set editing manual address to true if we've entered this component via the back button,
    // and the address set for the registry details is different to the account address
    const initialIsEditingManualAddress =
        stagedTransferOrder?.address && JSON.stringify(address) !== JSON.stringify(stagedTransferOrder.address)
    const [isEditingManualAddress, setIsEditingManualAddress] = React.useState(initialIsEditingManualAddress)

    React.useEffect(() => {
        if (!stagedTransferOrder) {
            navigate(profileUrl('invest/transfer-shares'), {replace: true})
        }
    }, [])

    const submitAddress = async (address: Request.RegistryDetailsUpsert['address']) => {
        // ensure we dont update the address if its already in the staged transfer order
        if (JSON.stringify(address) !== JSON.stringify(stagedTransferOrder?.address)) {
            await dispatch(transferActions.UpdateRegistryDetailAddress(address))
        }
        navigate(profileUrl('invest/portfolio-transfer-shares/:exchange/select', {exchange: 'asx'}))
    }

    if (isEditingManualAddress) {
        return (
            <>
                <Toolbar
                    dataTestId="toolbar--add-registry-details-address"
                    leftButton="back"
                    onLeftButtonClick={() => setIsEditingManualAddress(!isEditingManualAddress)}
                />
                <Page>
                    <h1 className={cn(styles.heading, spacing.spaceBelow24)}>Edit your address</h1>
                    <AddRegistryDetailAddressForm
                        handleSubmit={submitAddress}
                        address={stagedTransferOrder?.address ? stagedTransferOrder.address : address}
                        jurisdiction={jurisdiction}
                        addressChangeRequired={!initialIsEditingManualAddress}
                    />
                </Page>
            </>
        )
    }

    const hasSrnPageContent = () => {
        return (
            <>
                <Page>
                    <h1 className={cn(styles.heading, spacing.spaceBelow24)}>The address on your holding statement</h1>
                    <p>
                        <strong>This is the address we have on file for you:</strong>
                    </p>
                    <p>{address!.formatted}</p>
                    <p className={spacing.spaceAbove16}>
                        Does the above address match what is on your holding statement?
                    </p>
                    <p className={spacing.spaceAbove16}>
                        If yes, happy days. If not, you can easily edit it here to match. Editing your address here will
                        only be used for this transfer. It will not update the address on your Sharesies account.
                    </p>
                </Page>
                <ActionBar>
                    <div className={styles.actionBar}>
                        <Button
                            label="Edit address"
                            type="secondary"
                            onClick={() => setIsEditingManualAddress(true)}
                            dataTestId="button--edit-address"
                        />
                        <Button
                            label="It’s a match"
                            onClick={() => submitAddress(address)}
                            dataTestId="button--confirm-matches"
                        />
                    </div>
                </ActionBar>
            </>
        )
    }

    const noSrnPageContent = () => {
        return (
            <>
                <Page>
                    <h1 className={cn(styles.heading, spacing.spaceBelow24)}>The address we have on file for you</h1>
                    <p className={spacing.spaceBelow16}>Does the below address match where you live now?</p>
                    <p className={spacing.spaceBelow16}>
                        If yes, happy days. If not, you can easily edit it here to match. Editing your address here will
                        only be used for this transfer. It will not update the address on your Sharesies account.
                    </p>
                    <p>
                        <strong>This is the address we have on file for you:</strong>
                    </p>

                    <p>{address!.formatted}</p>
                </Page>
                <ActionBar>
                    <div className={styles.actionBar}>
                        <Button
                            label="Edit address"
                            type="secondary"
                            onClick={() => setIsEditingManualAddress(true)}
                            dataTestId="button--edit-address"
                        />
                        <Button
                            label="Use this address"
                            onClick={() => submitAddress(address)}
                            dataTestId="button--confirm-matches"
                        />
                    </div>
                </ActionBar>
            </>
        )
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--add-registry-details-address" leftButton="back" />
            {stagedTransferOrder?.reference === NEW_SRN_REQUIRED ? noSrnPageContent() : hasSrnPageContent()}
        </>
    )
}

export default AddRegistryDetailAddress
