import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import spacing from '~/global/scss/helpers/spacing.scss'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {PageButtonGroup} from '~/global/widgets/button-page-group/PageButtonGroup'
import {Image} from '~/global/widgets/image/Image'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {MAX_DIY_ORDERS} from '~/sections/invest/sections/auto-invest/constants/constants'
import actions from '~/store/autoinvest/actions'
import {useAppSelector, useAppDispatch} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import styles from './Splash.scss'

/**
 * A 'splash' page shown as the empty state for AU investors and just once for NZ investors.
 */
export const AutoInvestSplash: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const hasSeen = useAppSelector(s => s.identity.user!.has_seen.autoinvest_splash)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const profileUrl = useProfileUrl()

    const onStart = () => {
        if (!hasSeen) {
            dispatch(identityActions.MarkHasSeenFlag('autoinvest_splash'))
        }
        if (jurisdiction === 'nz') {
            navigate(profileUrl('auto-invest/add-order'))
        } else if (jurisdiction === 'au') {
            dispatch(actions.StageNewDIY())
            navigate(profileUrl('auto-invest/diy/search'))
        } else {
            assertNever(jurisdiction)
        }
    }

    const onHowItWorks = () => {
        if (!hasSeen) {
            dispatch(identityActions.MarkHasSeenFlag('autoinvest_splash'))
        }
        navigate('/auto-invest/intro')
    }

    const bodyPara2 = {
        nz: `You’ve got the choice to create up to ${MAX_DIY_ORDERS.nz.word} DIY orders, with up to 100 investments in each, or pick a pre-made responsible or global order.`,
        au: `With the choice to create up to ${MAX_DIY_ORDERS.au.word} auto-invest orders, with up to 100 investments in each, you can tailor each order to your investing goals.`,
    }[jurisdiction]

    return (
        <>
            <Toolbar
                dataTestId="toolbar--autoinvest-landing"
                leftButton="back"
                onLeftButtonClick={() => navigate(profileUrl('invest/manage'))}
            />

            <div className={styles.pageHero}>
                <Image
                    src={require('../../assets/images/gradient-icon-auto-invest-light.svg')}
                    darkSrc={require('../../assets/images/gradient-icon-auto-invest-dark.svg')}
                />
            </div>
            <Page className={styles.page}>
                <h1 className={cn(styles.title, spacing.spaceBelow16)}>Auto-invest</h1>
                <p className={spacing.spaceBelow24}>
                    Put your investing on repeat. Just choose your investments, then set how much and how often you’d
                    like to invest.
                </p>
                <p className={styles.grow}>{bodyPara2}</p>
                <PageButtonGroup>
                    <Button
                        dataTestId="button--set-up-order"
                        label="Set up auto-invest order"
                        onClick={e => {
                            e.preventDefault()
                            onStart()
                        }}
                    />
                    <Button
                        dataTestId="button--how-it-works"
                        label="See how it works"
                        type="secondary"
                        onClick={e => {
                            e.preventDefault()
                            onHowItWorks()
                        }}
                    />
                </PageButtonGroup>
            </Page>
        </>
    )
}
