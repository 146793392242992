/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    PortfolioCreate,
    PortfolioCreateFromJSON,
    PortfolioCreateFromJSONTyped,
    PortfolioCreateToJSON,
    PortfolioCreateToJSONTyped,
    Record,
    RecordFromJSON,
    RecordFromJSONTyped,
    RecordToJSON,
    RecordToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface AppSchemasForDataFeedRecordsRecordList
 */
export interface AppSchemasForDataFeedRecordsRecordList {
    /**
     *
     * @type {Array<Record>}
     * @memberof AppSchemasForDataFeedRecordsRecordList
     */
    records: Array<Record>
    /**
     *
     * @type {Array<PortfolioCreate>}
     * @memberof AppSchemasForDataFeedRecordsRecordList
     */
    portfolios: Array<PortfolioCreate>
}

export function AppSchemasForDataFeedRecordsRecordListFromJSON(json: any): AppSchemasForDataFeedRecordsRecordList {
    return AppSchemasForDataFeedRecordsRecordListFromJSONTyped(json, false)
}

export function AppSchemasForDataFeedRecordsRecordListFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AppSchemasForDataFeedRecordsRecordList {
    if (json === undefined || json === null) {
        return json
    }
    return {
        records: (json['records'] as Array<any>).map(RecordFromJSON),
        portfolios: (json['portfolios'] as Array<any>).map(PortfolioCreateFromJSON),
    }
}

export function AppSchemasForDataFeedRecordsRecordListToJSON(
    value?: AppSchemasForDataFeedRecordsRecordList | null,
): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        records: (value.records as Array<any>).map(RecordToJSON),
        portfolios: (value.portfolios as Array<any>).map(PortfolioCreateToJSON),
    }
}
