/**
 * This component is largely a duplicate of wallet/pages/overview/widgets/transaction-history/TransactionHistory.tsx
 * In future we should combine them into a global widget and replace both instances
 */

import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {useRetailGet} from '~/api/query/retail'
import {Request, Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import calculateHistoryHeading from '~/global/utils/calculate-history-heading/calculateHistoryHeading'
import Chips from '~/global/widgets/chips/Chips'
import {Loading} from '~/global/widgets/loading/Loading'
import {TransactionRowSkeleton} from '~/global/widgets/transaction-row/TransactionRow'
import commonStyles from '~/sections/save/common.scss'
import activityStyles from '~/sections/save/sections/activity/Activity.scss'
import TransactionLine from './TransactionLine'
import styles from './TransactionHistory.scss'

interface TransactionHistoryProps {
    portfolioId: string
}

type FilterOption = Response.SaveTransactionHistory['filter_options'][number]

const filterDisplayLabel = (option: FilterOption): string => {
    switch (option) {
        case 'DEPOSIT':
            return 'Deposit'
        case 'WITHDRAW':
            return 'Withdraw'
        case 'INTEREST':
            return 'Interest'
        case 'TAX':
            return 'Tax'
        case 'RECURRING':
            return 'Recurring'
    }
}

const filterOrder = (option: FilterOption): number => {
    const index = ['DEPOSIT', 'WITHDRAW', 'INTEREST', 'TAX'].indexOf(option)

    if (index === -1) {
        return Infinity
    }
    return index
}

const TransactionHistory: React.FunctionComponent<TransactionHistoryProps> = ({portfolioId}) => {
    const [selectedFilter, setSelectedFilter] = React.useState<FilterOption | undefined>()
    const [payload, setPayload] = React.useState<Request.SaveTransactionHistoryV2>({
        filter: selectedFilter,
        page: 1,
    })
    const [isLoadMorePending, startLoadMoreTransition] = React.useTransition()
    const [isFilterPending, startFilterTransition] = React.useTransition()

    const {data: transactionsPage} = useRetailGet({
        path: 'save/v2/:portfolio_id/transaction-history',
        payload,
        pathParams: {portfolio_id: portfolioId},
    })

    const [transactions, setTransactions] = React.useState<Response.SaveTransactionHistory['transaction_history']>(
        transactionsPage.transaction_history,
    )
    React.useEffect(() => {
        // Joins incoming transactions with already loaded ones, filtering out
        // any duplicates and ensuring they stay in the correct order
        const seenTransactionKeys = new Set<string>()
        const loadedPlusIncoming = transactions
            .concat(transactionsPage.transaction_history)
            .filter(t => {
                const key = `${t.transaction_id}-${t.key}`
                if (!seenTransactionKeys.has(key)) {
                    seenTransactionKeys.add(key)
                    return true
                }
                return false
            })
            .sort((a, b) => b.timestamp.toMillis() - a.timestamp.toMillis())
        setTransactions(loadedPlusIncoming)
    }, [transactionsPage])

    const filterOptions = [...transactionsPage.filter_options].sort((a, b) => filterOrder(a) - filterOrder(b))

    const handleShowMoreClick = () => {
        startLoadMoreTransition(() => {
            setPayload(payload => ({
                ...payload,
                page: transactionsPage.page + 1,
            }))
        })
    }

    const handleFilterClick = (value: FilterOption | undefined) => {
        setSelectedFilter(value)
        setTransactions([])
        startFilterTransition(() => {
            setPayload(payload => ({...payload, filter: value, page: 1}))
        })
    }

    const seenHeadings = new Set<string>()

    const filters = filterOptions.length > 0 && (
        <div className={cn(spacing.spaceAbove8, spacing.spaceBelow8)}>
            <Chips
                options={filterOptions.map(filterDisplayLabel)}
                onChipClick={value => {
                    const filter =
                        value === 'all'
                            ? undefined
                            : filterOptions[filterOptions.map(filterDisplayLabel).indexOf(value)]
                    handleFilterClick(filter)
                }}
                selected={selectedFilter ? [filterDisplayLabel(selectedFilter)] : []}
                isInlineDisplay
                hasAllOption
            />
        </div>
    )

    if (isFilterPending) {
        return (
            <>
                {filters}
                <div className={styles.emptySection}>
                    <Loading />
                </div>
            </>
        )
    }

    if (transactions.length === 0) {
        return (
            <>
                <p className={cn(commonStyles.noTransactions, spacing.spaceAbove64)}>No transactions to display</p>
            </>
        )
    }

    return (
        <>
            {filters}
            {transactions.map((txn, index) => {
                const heading = calculateHistoryHeading(txn.timestamp)
                const row = (
                    <React.Suspense key={index} fallback={<TransactionRowSkeleton />}>
                        <TransactionLine currency="nzd" txn={txn} portfolioId={portfolioId} />
                    </React.Suspense>
                )

                if (seenHeadings.has(heading)) {
                    return row
                } else {
                    seenHeadings.add(heading)
                    return (
                        <div key={index}>
                            <p className={activityStyles.timeUnitHeading}>{heading}</p>
                            {row}
                        </div>
                    )
                }
            })}

            {transactionsPage.has_more && (
                <Button
                    dataTestId="button--load-older-save-transactions"
                    label="Load older Save transactions"
                    onClick={handleShowMoreClick}
                    processing={isLoadMorePending}
                    additionalClassName={spacing.spaceAbove24}
                />
            )}
        </>
    )
}

export default TransactionHistory
