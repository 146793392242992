import React from 'react'
import {useActorHasSeen} from '~/global/state-hooks/retail/useActor'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {IntroSlides} from '~/global/widgets/intro-slides/IntroSlides'
import {useNavigate} from '~/migrate-react-router'
import {
    ExchangeFeesContent,
    ExchangeMoneyContent,
    ExchangeRateContent,
} from '~/sections/explore/pages/learn-static-content/LearnContent'
import {useWalletPortfolio} from '~/sections/wallet/state/local'

export const ExchangeMoneyIntro: React.FC = () => {
    const walletPortfolio = useWalletPortfolio()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const [_, setHasSeen] = useActorHasSeen('exchange_investor')

    const nextPage = () => {
        // Although the IntroSlides component can mark a flag seen for us, this hook correctly updates
        // the tanstack cache so we don't get stuck in a loop
        setHasSeen()
        navigate(profileUrl('wallet/:portfolioId/exchange-money', {portfolioId: walletPortfolio.id}), {replace: true})
    }

    return (
        <IntroSlides
            content={[ExchangeMoneyContent, ExchangeRateContent, ExchangeFeesContent]}
            onComplete={nextPage}
            layout="classicIntro"
        />
    )
}

export default ExchangeMoneyIntro
