import React from 'react'
import {ListingResponseDto} from '~/api/distill'
import {convertToSimpleURL} from '~/global/utils/convert-to-simple-ur-l/convertToSimpleURL'
import {ItemValue} from '~/global/widgets/number-elements/NumberElements'
import TruncatedText, {AnchorPreventTabbingWhenClipped} from '~/global/widgets/truncated-text/TruncatedText'
import styles from './Description.scss'

const Description: React.FunctionComponent<{instrument: ListingResponseDto}> = ({instrument: instrument}) => {
    return (
        <TruncatedText className={styles.instrumentDescription}>
            <p className={styles.description}>{instrument.description}</p>
            {instrument.ceo && (
                <span className={styles.extraInfo}>
                    <p>CEO: </p>
                    <p>{instrument.ceo}</p>
                </span>
            )}
            {instrument.employees !== 0 && instrument.employees && (
                <div className={styles.extraInfo}>
                    <p>Employees: </p>
                    <p>
                        <ItemValue value={instrument.employees} />
                    </p>
                </div>
            )}
            {instrument.websiteUrl && (
                <div className={styles.extraInfo}>
                    <p>Website: </p>
                    <AnchorPreventTabbingWhenClipped
                        href={
                            instrument.websiteUrl.includes('://')
                                ? instrument.websiteUrl
                                : 'https://' + instrument.websiteUrl
                        }
                        target="_blank"
                        rel="noopener"
                    >
                        {convertToSimpleURL(instrument.websiteUrl)}
                    </AnchorPreventTabbingWhenClipped>
                </div>
            )}
            {instrument.websiteUrl && (
                <div className={styles.extraInfo}>
                    <p>Provider: </p>
                    <AnchorPreventTabbingWhenClipped href={instrument.websiteUrl} target="_blank" rel="noopener">
                        {convertToSimpleURL(instrument.websiteUrl)}
                    </AnchorPreventTabbingWhenClipped>
                </div>
            )}
            {instrument.pdsDriveId && (
                <div className={styles.extraInfo}>
                    <p>View: </p>
                    <AnchorPreventTabbingWhenClipped
                        href={`https://drive.google.com/file/d/${instrument.pdsDriveId}/preview`}
                        target="_blank"
                        rel="noopener"
                    >
                        Product Disclosure Statement
                    </AnchorPreventTabbingWhenClipped>
                </div>
            )}
        </TruncatedText>
    )
}

export default Description
