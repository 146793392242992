import {DateTimeFormatOptions} from 'luxon'

export const dateFormatWithYear = "dd MMM yyyy 'at' HH:mm" // 30 Mar 2020 at 11:45
export const dateFormatNoYearShortMonth = 'dd MMM' // 30 Mar
export const dateFormatDayAndMonthWithTime = "h:mm a 'on' dd MMMM yyyy" // 11:00 AM on 30 March 2022
export const dateFormatNoYear = 'dd MMMM' // 30 March
export const dateFormatNoDay = 'MMMM yyyy' // March 2020
export const dateFormatNoTime = 'dd MMM yyyy' // 30 Mar 2020
export const dateFormatFullMonth = 'dd MMMM yyyy' // 30 March 2020 || 05 March 2020
export const dateFormatShortDayFullMonth = 'd MMMM yyyy' // 30 March 2020 || 5 March 2020
export const dateFormatWithYearFirst = 'yyyy-MM-dd' // 2020-03-30
export const dateFormatFullDay = 'cccc dd MMM' // Monday 30 Mar
export const dateFormatFullDayWithYear = 'cccc dd MMMM yyyy' // Monday 30 March 2020
export const dateFormatTime = 'HH:mm' // 11:45
export const dateFormatTimeAmPm = 'h:mm a' // 10:00 AM
export const dateFormatWithSecond = 'hh:mm:ssa, dd MMM yyyy' // 11:45:23, 30 Mar 2020
export const dateFormatShortDayMonthNoYear = 'd MMMM' // 30 March || 5 March
export const dateFormatMonthShortDayWithYear = 'MMM d, yyyy' // Mar 30, 2024

/** Format: 13 Aug 2020 */
export const dateFormatShortMonth: DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
}

/** Format: 13 August 2020 */
export const dateFormatLongMonth: DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
}
