import {Button} from '@design-system/button'
import {SelectCard} from '@design-system/select-cards' // TODO refactor to use SelectCards
import cn from 'classnames'
import React, {useState} from 'react'
import {spacing} from '~/global/scss/helpers'
import {
    isInstrumentCloseOnly,
    isInstrumentInNoTrade,
} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {isInstrumentDerivative} from '~/global/utils/is-instrument-derivative/isInstrumentDerivative'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import {Navigate, useNavigate} from '~/migrate-react-router'
import panelStyles from '~/sections/invest/sections/order-flow/InvestPanel.scss'
import styles from '~/sections/invest/sections/order-flow/OrderForm.scss'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import actions from '~/store/order/actions'
import {Application} from '~/store/order/types'

export const applicationsToShowOnPicker = (applications: Application[]) =>
    applications.filter(application => application.type !== 'VOTE')

const ApplicationOptionCard: React.FC<{
    active: boolean
    onClick(): unknown
    application: Application
    instrument: Instrument
}> = ({active, onClick, application, instrument}) => {
    const defaultTitle =
        application.type === 'EXERCISE'
            ? `Exercise your ${shareLabel({instrument, isPlural: true})}`
            : `Apply for ${shareLabel({instrument, isPlural: true})}`
    const defaultSubtitle =
        application.type === 'EXERCISE'
            ? `Use ${shareLabel({instrument, isPlural: true})} to buy shares at the offer price`
            : `Apply to purchase ${shareLabel({instrument, isPlural: true})}`

    return (
        <SelectCard
            dataTestId={`select-card-${application.application_rule_id}`}
            name="orderType"
            value={application.application_rule_id}
            isActive={active}
            onChange={onClick}
            title={application.investment_option_title ?? defaultTitle}
            supportingText={application.investment_option_subtitle ?? defaultSubtitle}
        />
    )
}

const OrderTypePicker: React.FC<{instrument: Instrument}> = ({instrument}) => {
    const instrumentApplicationState = useAppSelector(state => state.order.applicationsByInstrument[instrument.id])
    const hasHolding = useAppSelector(store => store.identity.holdings.find(f => f.fund_id === instrument.id) != null)
    const orderStore = useAppSelector(({order}) => order)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [orderType, setOrderType] = useState<string>()

    const {applications: unfilteredApplications, ...intro} = instrumentApplicationState || {applications: []}
    const applications = applicationsToShowOnPicker(unfilteredApplications)
    const exerciseApplications = applications.filter(application => application.type === 'EXERCISE')
    const otherApplications = applications.filter(application => !exerciseApplications.includes(application))

    const [inDetailedExercisePicker, setInDetailedExercisePicker] = useState<boolean>(
        isInstrumentInNoTrade(instrument) && exerciseApplications.length > 1 && otherApplications.length === 0,
    )

    const submit = () => {
        if (!orderType) {
            return // Maybe error?
        }

        // Before navigating, ensure we wipe any staged (mid-creation) orders
        if (orderStore.stagedBuyOrder) {
            dispatch(actions.ClearStagedBuyOrder())
        }
        if (orderStore.stagedSellOrder) {
            dispatch(actions.ClearStagedSellOrder())
        }
        if (orderStore.stagedApplication) {
            dispatch(actions.ClearStagedApplication())
        }

        let next = orderType

        if (!['buy', 'sell'].includes(orderType)) {
            next = `apply/${orderType}`
        }

        const currentPath = location.pathname
        navigate(currentPath.replace('pick', next), {replace: true})
    }

    // If there's only one option, and that option is an exercise application, skip the picker and go directly to apply instead
    if (isInstrumentInNoTrade(instrument) && exerciseApplications.length === 1 && otherApplications.length === 0) {
        const currentPath = location.pathname
        const exerciseApplication = exerciseApplications[0]
        return <Navigate to={currentPath.replace('pick', `apply/${exerciseApplication.application_rule_id}`)} replace />
    }

    return (
        <form
            onSubmit={e => {
                e.preventDefault()
                submit()
            }}
        >
            <Page className={panelStyles.investPanelAnimated}>
                <PageBack />

                <div className={cn(spacing.spaceAbove24, spacing.spaceBelow24, styles.intro)}>
                    {inDetailedExercisePicker ? (
                        <>
                            <h1>
                                Exercise your {shareLabel({instrument, isPlural: true})} into Australian or NZ shares?
                            </h1>
                            <p>
                                Generally, it’s easier and cheaper to buy local shares than it is to buy overseas
                                shares.
                            </p>
                        </>
                    ) : intro.introHeaderHTML ? (
                        <div dangerouslySetInnerHTML={{__html: intro.introHeaderHTML}} />
                    ) : (
                        <h1>What would you like to do?</h1>
                    )}
                </div>

                {inDetailedExercisePicker ? (
                    exerciseApplications.map(application => (
                        <ApplicationOptionCard
                            application={application}
                            key={application.application_rule_id}
                            instrument={instrument}
                            onClick={() => setOrderType(application.application_rule_id)}
                            active={orderType === application.application_rule_id}
                        />
                    ))
                ) : (
                    <>
                        {/* If there's multiple ways to exercise, show a link to a detailed picker. Otherwise, just show the one/none on the main screen */}
                        {exerciseApplications.length > 1 ? (
                            <SelectCard
                                dataTestId="select-exercise"
                                title={`Exercise your ${shareLabel({instrument, isPlural: true})}`}
                                value="exercise"
                                name="orderType"
                                isActive={orderType === 'exercise'}
                                onChange={() => {
                                    setInDetailedExercisePicker(true)
                                }}
                                supportingText={`Use ${shareLabel({
                                    instrument,
                                    isPlural: true,
                                })} to buy shares at the offer price`}
                            />
                        ) : (
                            exerciseApplications.map(application => (
                                <ApplicationOptionCard
                                    application={application}
                                    key={application.application_rule_id}
                                    instrument={instrument}
                                    onClick={() => setOrderType(application.application_rule_id)}
                                    active={orderType === application.application_rule_id}
                                />
                            ))
                        )}

                        {!isInstrumentInNoTrade(instrument) && !isInstrumentCloseOnly(instrument) && (
                            <SelectCard
                                dataTestId="select-card-buy"
                                title="Buy"
                                value="buy"
                                name="orderType"
                                isActive={orderType === 'buy'}
                                onChange={() => setOrderType('buy')}
                                supportingText={
                                    isInstrumentDerivative(instrument)
                                        ? `Buy ${shareLabel({instrument, isPlural: true})} being sold by others`
                                        : 'Place a buy order as usual'
                                }
                            />
                        )}

                        {!isInstrumentInNoTrade(instrument) && exerciseApplications.length > 0 && hasHolding && (
                            <SelectCard
                                dataTestId="select-card-sell"
                                title="Sell"
                                value="sell"
                                name="orderType"
                                isActive={orderType === 'sell'}
                                onChange={() => setOrderType('sell')}
                                supportingText={`Sell your ${shareLabel({instrument, isPlural: true})} to someone else`}
                            />
                        )}

                        {/* Other applications (ie purchase) live on the main screen, never the detailed exercise picker*/}
                        {otherApplications.map(application => (
                            <ApplicationOptionCard
                                application={application}
                                key={application.application_rule_id}
                                instrument={instrument}
                                onClick={() => setOrderType(application.application_rule_id)}
                                active={orderType === application.application_rule_id}
                            />
                        ))}
                    </>
                )}

                <div className={cn(spacing.spaceAbove8, styles.intro, styles.footer)}>
                    {intro.introFooterHTML && <div dangerouslySetInnerHTML={{__html: intro.introFooterHTML}} />}
                </div>
            </Page>
            <ActionBar>
                <Button label="Next" disabled={!orderType} isSubmit dataTestId="button--pick-order" />
            </ActionBar>
        </form>
    )
}

export default OrderTypePicker
