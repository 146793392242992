import React from 'react'
import {useRefSize} from '~/global/utils/use-ref-size/useRefSize'
import styles from './ScrollingTicker.scss'

interface ScrollingTickerProps {
    'aria-hidden'?: boolean
    pixelsPerSecond?: number
}
const ScrollingTicker: React.FC<ScrollingTickerProps> = ({
    children,
    'aria-hidden': ariaHidden = true,
    pixelsPerSecond = 50,
}) => {
    const contentTestingRef = React.useRef<HTMLDivElement>(null)
    const containerRef = React.useRef<HTMLDivElement>(null)

    const containerSize = useRefSize(containerRef)
    const contentSize = useRefSize(contentTestingRef)

    const tilingCount = contentSize && containerSize ? Math.ceil(containerSize.width / contentSize.width) : 0
    // If we want to scroll 100px per second, and the content is 200px, duration should be 2s
    const animationSpeed = contentSize ? (contentSize.width * tilingCount) / pixelsPerSecond + 's' : '0s'
    return (
        <div className={styles.tickerContainer} ref={containerRef} aria-hidden={ariaHidden}>
            {/* Create an invisible testing element that we can use to determine the size of one segment, before rendering anything on the screen */}
            <div ref={contentTestingRef} className={styles.tickerSizingTest}>
                {children}
            </div>
            <div className={styles.tickerContent} style={{animationDuration: animationSpeed}}>
                {Array(tilingCount * 2)
                    .fill(null)
                    .map((_, index) => (
                        <React.Fragment key={index}>{children}</React.Fragment>
                    ))}
            </div>
        </div>
    )
}

export default ScrollingTicker
