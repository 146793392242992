import React from 'react'
import {Loading} from '~/global/widgets/loading/Loading'
import {usePortfolioSortFilterPreference} from '~/sections/kiwisaver/data/retail/portfolioSettings'
import {InvestmentsFilterAndSort} from '~/sections/kiwisaver/sections/home/widgets/filter-and-sort/InvestmentsFilterAndSort'
import InvestmentsFilterChips from '~/sections/kiwisaver/sections/home/widgets/filter-and-sort/InvestmentsFilterChips'
import {InvestmentsGrid} from '~/sections/kiwisaver/sections/home/widgets/investments-grid/InvestmentsGrid'
import {useActiveOrOffboardingKSCustomer} from '~/sections/kiwisaver/state'
import {PortfolioFilterOptions, PortfolioSortOptions} from '~/store/identity/types'
import styles from './InvestmentsGridFilterWrapper.scss'

export interface FilterType {
    sort: string
    filter: string
    typeChipLabel: string
}

export const InvestmentsGridFilterWrapper: React.FunctionComponent = () => {
    const [typeChipLabel, setTypeChipLabel] = React.useState<string>('all')
    const ksCustomer = useActiveOrOffboardingKSCustomer()
    const {filterPreference, sortPreference, setFilter, setSort} = usePortfolioSortFilterPreference(
        ksCustomer.portfolio_id,
    )

    const handleSetSort = (sort: PortfolioSortOptions) => {
        setSort(sort)
    }

    const handleSetFilter = (filter: PortfolioFilterOptions) => {
        setFilter(filter)
    }

    const handleSetChips = (value: string) => {
        setTypeChipLabel(value)
    }

    return (
        <div className={styles.investmentsWrapper}>
            <InvestmentsFilterAndSort
                filter={filterPreference.filter_preference}
                sort={sortPreference.sort_preference}
                setSort={handleSetSort}
                setFilter={handleSetFilter}
            />
            <InvestmentsFilterChips setFilters={handleSetChips} typeChipLabel={typeChipLabel} />
            <React.Suspense fallback={<Loading className={styles.loadingInvestments} />}>
                <InvestmentsGrid
                    typeChipLabel={typeChipLabel}
                    filter={filterPreference.filter_preference}
                    sort={sortPreference.sort_preference}
                />
            </React.Suspense>
        </div>
    )
}
