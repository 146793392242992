import store from '..'
import actions from './actions'

export function setModalError(error: string, title: string | null = null): void {
    store.dispatch(actions.SetModalError(error, title))
}

export function clearModalError(): void {
    store.dispatch(actions.ClearModalError())
}
