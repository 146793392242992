import {Button} from '@design-system/button'
import React from 'react'
import {Navigate, useNavigate} from 'react-router'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Image from '~/global/widgets/image/Image'
import Page from '~/global/widgets/page/Page'
import {sendMobileExploreRoute} from '~/sections/explore/utils/util'
import topupDark from '~/sections/user/sections/sign-up/sections/post-activation/pages/deposit-prompt/assets/images/topup-dark.svg'
import topupLight from '~/sections/user/sections/sign-up/sections/post-activation/pages/deposit-prompt/assets/images/topup-light.svg'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/identity/actions'
import navActions from '~/store/nav/actions'
import styles from './DepositPrompt.scss'

// The key in the user.has_seen list to track if we've already shown this customer this page
const hasSeenFlagKey = 'sign_up_deposit_prompt'

const DepositPrompt: React.FunctionComponent<{}> = () => {
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)
    const hasSeen = useAppSelector(s => s.identity.user!.has_seen[hasSeenFlagKey])
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const topUpClick = () => {
        dispatch(actions.MarkHasSeenFlag(hasSeenFlagKey))
        dispatch(navActions.ShowFlyoutMenu())
        navigate(profileUrl('wallet/topup'))
    }

    const skipClick = () => {
        dispatch(actions.MarkHasSeenFlag(hasSeenFlagKey))
        dispatch(navActions.ShowFlyoutMenu())
        sendMobileExploreRoute()
        navigate(profileUrl(''))
    }

    if (hasSeen) {
        // These investors shouldn't see this page again, just send them to the portfolio directly
        return <Navigate to={profileUrl('')} replace />
    }

    return (
        <Page className={styles.container}>
            <Image src={topupLight} darkSrc={topupDark} height={120} />
            <h1>Ready to go, {preferredName}?</h1>
            <p>To buy shares or start saving, top up your Wallet now with a card or bank transfer.</p>
            <div className={styles.register}>
                <Button
                    type="primary"
                    label="Top up now"
                    dataTestId="button--top-up-now"
                    onClick={() => topUpClick()}
                />
                <Button
                    type="secondary"
                    dataTestId="button--later"
                    label="I'll do it later"
                    onClick={() => skipClick()}
                />
            </div>
        </Page>
    )
}

export default DepositPrompt
