import {useQueryClient} from '@tanstack/react-query'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import {Response} from '~/api/retail/types'

/**
 * Use the actor endpoint to get the current actor's details. The actor is
 * determined by the current session, so no request details are needed.
 *
 * @example
 * const actor = useActor()
 */
export const useActor = () => {
    return useRetailGet({
        path: 'actor/self',
        options: {
            staleTime: 4.5 * 60 * 1000, // four and a half minutes - half a minute before cache expires
        },
    }).data
}

/**
 * Trigger a cache invalidation for the actor endpoint. Useful for when we want to force a refetch of the actor,
 * particularly after a non-tanstack-query API POST has updated something that might result in updated data. Returns a
 * function that can be excuted later.
 *
 * @example
 * const invalidateActorCache = useInvalidateActorCache()
 * invalidateActorCache()
 */
export const useInvalidateActorCache = () => {
    const queryClient = useQueryClient()

    return () => queryClient.invalidateQueries(['actor/self'])
}

type HasSeenKey = keyof Response.ActorHasSeen['has_seen']

/**
 * Mechanism for tracking/marking if a particular actor has seen a particular section.
 * Note that while this uses the same server-side data as the identity redux module, it doesn't
 * explicitly keep the two in sync client-side. If you're mixing your implementation between them
 * you'll need to consider how to do that (or preferably stick to just using this hook)
 *
 * @example
 * const [hasSeen, setHasSeen] = useActorHasSeen('section_name')
 */
export const useActorHasSeen = (hasSeenKey: HasSeenKey): [boolean, () => Promise<Response.ActorHasSeen>] => {
    const queryClient = useQueryClient()
    const response = useRetailGet({
        path: 'actor/has-seen',
    }).data

    const hasSeen = response.has_seen[hasSeenKey]

    const setHasSeen = useRetailPost({path: 'actor/set-has-seen', queryCacheToUpdate: ['actor/has-seen']})

    return [
        hasSeen,
        () => {
            // We optimistically update the cache because the typical pattern here would be to set a key as
            // seen and immediately navigate back to the underlying page (and if it's not marked as seen there,
            // there's a chance we'll wind up back on the intro slides or similar)
            queryClient.setQueryData(['actor/has-seen'], {
                ...response,
                has_seen: {...response.has_seen, [hasSeenKey]: true},
            })

            return setHasSeen.mutateAsync({key: hasSeenKey})
        },
    ]
}
