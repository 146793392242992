import {Button} from '@design-system/button'
import {Modal} from '@design-system/modal'
import {SelectCard} from '@design-system/select-cards' // TODO refactor to use SelectCards
import cn from 'classnames'
import React from 'react'
import {Navigate} from 'react-router-dom'
import * as rollbar from '~/api/rollbar/rollbar'
import {spacing} from '~/global/scss/helpers'
import {dateFormatDayAndMonthWithTime} from '~/global/utils/format-date/formatDate'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useInstrument} from '~/global/utils/use-instruments/useInstruments'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {ExchangeHours} from '~/global/widgets/exchange-hours/ExchangeHours'
import {Loading} from '~/global/widgets/loading/Loading'
import {SharePriceValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import styles from '~/sections/invest/sections/order-flow/OrderForm.scss'
import {canMakeAutoExerciseBuyOrder} from '~/store/accounting/selectors'
import {essHasActiveBlackoutPeriodForInstrument} from '~/store/employeeShareScheme/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import actions from '~/store/order/actions'

interface AutoExerciseOptionsProps {
    instrument: Instrument
    autoExercise?: boolean
    onContinue(autoExercise: boolean): void
}

const AutoExerciseOptions: React.FunctionComponent<AutoExerciseOptionsProps> = ({
    instrument,
    autoExercise: initialAutoExercise,
    onContinue,
}) => {
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const [autoExercise, setAutoExercise] = React.useState<boolean | null>(
        initialAutoExercise === undefined ? null : initialAutoExercise,
    )
    const [isModalOpen, setIsModalOpen] = React.useState(false)

    const userId = useAppSelector(s => s.identity.user?.id)
    const canMakeAutoExerciseBuy = useAppSelector(s => canMakeAutoExerciseBuyOrder(s, instrument.id))
    const essHasActiveBlackoutPeriod = useAppSelector(s => essHasActiveBlackoutPeriodForInstrument(s, instrument.id))

    const applications = useAppSelector(s => s.order.applicationsByInstrument[instrument.id]?.applications)
    const exerciseApplication = applications?.find(a => a.fund_id === instrument.id && a.type === 'EXERCISE')
    const parentInstrument = useInstrument(instrument.parentInstrumentId)

    React.useEffect(() => {
        if (!exerciseApplication) {
            dispatch(actions.LoadInstrumentApplications(instrument.id))
        }
    }, [exerciseApplication])

    if (!canMakeAutoExerciseBuy || !parentInstrument) {
        rollbar.sendError('AutoExerciseOptions: user cannot make auto exercise buy, redirecting to /', {
            userId,
        })
        return <Navigate to={profileUrl('')} replace />
    }

    if (!exerciseApplication) {
        return <Loading />
    }

    const shareLabelPlural = shareLabel({instrument, isPlural: true})
    const parentShareLabelPlural = shareLabel({instrument: parentInstrument, isPlural: true})
    const applicationsCloseAt = exerciseApplication.applications_close_at!.toFormat(dateFormatDayAndMonthWithTime)

    return (
        <>
            <Page>
                <PageBack />
                {!essHasActiveBlackoutPeriod && <ExchangeHours instrument={instrument} />}
                <div className={cn(spacing.spaceAbove24, spacing.spaceBelow16, styles.intro)}>
                    <h1>Auto-exercise the rights you buy?</h1>
                </div>
                <p className={spacing.spaceBelow32}>
                    Most people who buy {shareLabelPlural} go on to{' '}
                    <ButtonAsLink onClick={() => setIsModalOpen(true)}>exercise</ButtonAsLink> them into{' '}
                    {parentShareLabelPlural}. If you like, you can choose to auto-exercise any {shareLabelPlural} you
                    buy, so you don’t have to exercise them later on.
                </p>
                <SelectCard
                    dataTestId="button--buy-with-auto-exercise"
                    title="Buy and auto-exercise"
                    value="buy_and_exercise_rights"
                    name="orderOption"
                    isActive={autoExercise === true}
                    onChange={() => setAutoExercise(true)}
                    supportingText={`Apply to turn ${shareLabelPlural} into ${parentShareLabelPlural} automatically after your buy order fills.`}
                />

                <SelectCard
                    dataTestId="button--buy-only"
                    title="Buy and exercise (or sell) later"
                    value="buy_rights"
                    name="orderOption"
                    isActive={autoExercise === false}
                    onChange={() => setAutoExercise(false)}
                    supportingText={`You have until ${applicationsCloseAt} to exercise ${shareLabelPlural}. Unexercised ${shareLabelPlural} left in your Portfolio after this date will be removed, and you won’t be able to turn them into ${parentShareLabelPlural}.`}
                />
            </Page>
            <ActionBar>
                <Button
                    label="Next"
                    disabled={autoExercise === null}
                    onClick={() => onContinue(autoExercise!)}
                    dataTestId="button--pick-order-exercise-options"
                />
            </ActionBar>
            <ExercisingRightsModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                instrument={instrument}
                parentInstrument={parentInstrument}
                applicationsCloseAt={applicationsCloseAt}
            />
        </>
    )
}

interface ModalProps {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    instrument: Instrument
    parentInstrument: Instrument
    applicationsCloseAt: string
}

const ExercisingRightsModal = ({isOpen, setIsOpen, instrument, parentInstrument, applicationsCloseAt}: ModalProps) => {
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)

    return (
        <Modal
            dataTestId="modal--exercising-rights"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title="Exercising rights"
            primaryButton={{label: 'Ok'}}
            content={
                <div>
                    <p className={spacing.spaceBelow24}>
                        Just buying {shareLabel({instrument, isPlural: true})} doesn’t mean you’ll automatically get{' '}
                        {shareLabel({instrument: parentInstrument, isPlural: true})}—you’ll also need to exercise them.
                        You do this by paying the offer price for each {shareLabel({instrument})} you want to turn into
                        a {shareLabel({instrument: parentInstrument})} before the offer closing date.
                    </p>
                    <p className={spacing.spaceBelow24}>
                        For example, in this offer it costs{' '}
                        <strong>
                            <SharePriceValue value={instrument.offerPrice!} currency={instrument.currency} />
                        </strong>{' '}
                        to turn 1 {shareLabel({instrument})} ({instrument.symbol}) into {instrument.sharesPerRight}{' '}
                        {shareLabel({instrument: parentInstrument, isPlural: instrument.sharesPerRight !== 1})} (
                        {parentInstrument?.symbol}), which you can apply for any time up until{' '}
                        <strong>{applicationsCloseAt}</strong>.
                    </p>
                    <p className={spacing.spaceBelow24}>
                        If you don’t exercise your {shareLabel({instrument, isPlural: true})} by that date, they won’t
                        be turned into {shareLabel({instrument: parentInstrument, isPlural: true})} and will eventually
                        be removed from your Portfolio. Your existing {parentInstrument.symbol}{' '}
                        {shareLabel({instrument: parentInstrument, isPlural: true})} will not be removed.
                    </p>
                    <p>
                        Check out our website for{' '}
                        <a
                            href={
                                jurisdiction === 'au'
                                    ? 'https://sharesies.com.au/learn/rights-offers-what-you-need-to-know'
                                    : 'https://www.sharesies.nz/learn/rights-offers-what-you-need-to-know'
                            }
                            target="_blank"
                            rel="noopener"
                            data-testid="link--learn-rights-offers"
                        >
                            more info on how {shareLabel({instrument, isPlural: true})} offers work
                        </a>
                        .
                    </p>
                </div>
            }
        />
    )
}

export default AutoExerciseOptions
