import {useAtom} from 'jotai'
import React from 'react'
import {Navigate} from 'react-router'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Intro from '~/sections/moose/sections/fonterra-personal-sign-up/pages/intro/Intro'
import {IRDNumber} from '~/sections/moose/sections/fonterra-personal-sign-up/pages/ird-number/IRDNumber'
import NatureAndPurpose from '~/sections/moose/sections/fonterra-personal-sign-up/pages/nature-and-purpose/NatureAndPurpose'
import {PrescribedPerson} from '~/sections/moose/sections/fonterra-personal-sign-up/pages/prescribed-person/PrescribedPerson'
import {TaxDeclaration} from '~/sections/moose/sections/fonterra-personal-sign-up/pages/tax-declaration/TaxDeclaration'
import TermsAndConditions from '~/sections/moose/sections/fonterra-personal-sign-up/pages/terms-and-conditions/TermsAndConditions'
import {stagedFonterraPersonalSignUpAtom} from '~/sections/moose/sections/fonterra-personal-sign-up/state'
import Welcome from '~/sections/user/sections/sign-up/pages/welcome/Welcome'

type SignUpStep =
    | 'intro'
    | 'ird-number'
    | 'tax-declaration'
    | 'prescribed-person'
    | 'nature-and-purpose'
    | 'terms-and-conditions'
    | 'complete'
    | 'explore'

const getNextStep = (step: SignUpStep): SignUpStep => {
    switch (step) {
        case 'intro':
            return 'ird-number'
        case 'ird-number':
            return 'tax-declaration'
        case 'tax-declaration':
            return 'prescribed-person'
        case 'prescribed-person':
            return 'nature-and-purpose'
        case 'nature-and-purpose':
            return 'terms-and-conditions'
        case 'terms-and-conditions':
            return 'complete'
        default:
            return 'intro'
    }
}

const getPreviousStep = (step: SignUpStep): SignUpStep => {
    switch (step) {
        case 'terms-and-conditions':
            return 'nature-and-purpose'
        case 'nature-and-purpose':
            return 'prescribed-person'
        case 'prescribed-person':
            return 'tax-declaration'
        case 'tax-declaration':
            return 'ird-number'
        case 'ird-number':
            return 'intro'
        case 'intro':
            return 'explore'
        default:
            return 'intro'
    }
}

export interface FonterraPersonalSignUpCommonProps {
    progressStep: () => void
    regressStep?: () => void
}

const FonterraPersonalSignUp = () => {
    const profileUrl = useProfileUrl()

    // handle the case where we've already started the signup process
    // hide the intro screen and go straight to the ird number screen with the existing data
    const [stagedFonterraPersonalSignUp] = useAtom(stagedFonterraPersonalSignUpAtom)
    const alreadyStartedSignup = stagedFonterraPersonalSignUp?.irdNumber

    const [step, setStep] = React.useState<SignUpStep>(alreadyStartedSignup ? 'ird-number' : 'intro')

    const progressStep = () => {
        const nextStep = getNextStep(step)
        // If we've completed sign up in a native context then we need to let the app know
        if (nextStep === 'complete') {
            sendWrapperAppMessage({type: 'personalSignUpComplete'})
        }
        setStep(nextStep)
    }

    const regressStep = () => setStep(getPreviousStep)

    const commonProps = {
        progressStep,
        regressStep,
    }

    switch (step) {
        case 'intro':
            return <Intro {...commonProps} />
        case 'ird-number':
            return <IRDNumber {...commonProps} />
        case 'tax-declaration':
            return <TaxDeclaration {...commonProps} />
        case 'prescribed-person':
            return <PrescribedPerson {...commonProps} />
        case 'nature-and-purpose':
            return <NatureAndPurpose {...commonProps} />
        case 'terms-and-conditions':
            return <TermsAndConditions {...commonProps} />
        case 'explore':
            return <Navigate to={profileUrl('explore')} />
        case 'complete':
            return <Welcome />
        default:
            return <WeSlippedUp />
    }
}

export default FonterraPersonalSignUp
