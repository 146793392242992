import React from 'react'
import {Model, Request} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import Page from '~/global/widgets/page/Page'
import TaxDeclarationForm from '~/sections/user/sections/sign-up/pages/tax-questions/widgets/tax-declaration-form/TaxDeclarationForm'
import SignupToolbar from '~/sections/user/sections/sign-up/widgets/signup-toolbar/SignupToolbar'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'

export class TaxQuestions extends React.PureComponent<StateProps & DispatchProps & OwnProps, {}> {
    public render() {
        const {isDependent, preferredName, jurisdiction} = this.props

        return (
            <>
                <SignupToolbar />
                <Page>
                    <h1 className={spacing.spaceBelow16}>
                        Countries {isDependent ? `${preferredName} pays` : 'you pay'} tax in
                    </h1>
                    <TaxDeclarationForm
                        signUpTaxQuestions={this.props.signUpTaxQuestions}
                        isDependent={isDependent}
                        preferredName={preferredName}
                        onSuccess={this.props.onSuccess}
                        jurisdiction={jurisdiction}
                    />
                </Page>
            </>
        )
    }
}

interface StateProps {
    isDependent: boolean
    preferredName: string
    jurisdiction: Model.User['jurisdiction']
}

interface DispatchProps {
    signUpTaxQuestions(
        foreign_tax_resident: boolean,
        countries: Request.IdentitySignUpTaxQuestions['countries'],
    ): Promise<string | undefined>
}

interface OwnProps {
    onSuccess(): void
}

export default connect<StateProps, DispatchProps, OwnProps>(
    ({identity}) => ({
        isDependent: identity.user!.is_dependent,
        preferredName: identity.user!.preferred_name,
        jurisdiction: identity.user!.jurisdiction,
    }),
    dispatch => ({
        signUpTaxQuestions: (foreign_tax_resident, countries) =>
            dispatch(actions.SignUpTaxQuestions(foreign_tax_resident, countries)),
    }),
)(TaxQuestions)
