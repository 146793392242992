/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum Country {
    nzl = 'nzl',
    usa = 'usa',
    aus = 'aus',
    can = 'can',
    gbr = 'gbr',
    zaf = 'zaf',
}

export function CountryFromJSON(json: any): Country {
    return CountryFromJSONTyped(json, false)
}

export function CountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Country {
    return json as Country
}

export function CountryToJSON(value?: Country | null): any {
    return value as any
}
