import React from 'react'
import {useAppSelector} from '~/store/hooks'

interface HelpCentreLinkProps {
    linkText?: string
    children?: string | React.ReactChildren
    nzArticle?: string
    auArticle?: string
    overrideJurisdiction?: 'nz' | 'au'
}

/**
 * Intercom help link that opens in a new tab.
 *
 * Defaults to the root of the help centre when a link is not supplied
 * for the appropriate jurisdiction, and to 'Help Centre' if text for the
 * link is not supplied either via a prop or via children. Opens in a new tab.
 *
 * ```
 *  <HelpCentreLink
 *    auArticle="4983278-some-topic"
 *    nzArticle="3146262-some-topic"
 *  >
 *    your link text
 *  </HelpCentreLink>
 * ```
 *
 * If the auArticle and nzArticle are identical, you must still supply both.
 *
 * @param {HelpCentreLinkProps} [props] - React props
 * @param {string} [props.linkText="help centre"] - text for the link text (or use children instead)
 * @param {string|React.ReactChildren} [props.children="help centre"] - text or JSX for the link text
 * @param {string} [props.nzArticle="root help centre link"] - NZ help centre URL fragment
 * @param {string} [props.auArticle="root AU help centre link"] - AU help centre URL fragment
 * @param {string} [props.overrideJurisdiction="jurisdiction override value"] - Use this jurisdiction value when customer jurisdiction not yet defined
 * @returns {React.ReactComponentElement} - component
 */
export const HelpCentreLink: React.FunctionComponent<HelpCentreLinkProps> = ({
    linkText,
    children,
    nzArticle,
    auArticle,
    overrideJurisdiction,
}) => {
    let jurisdiction = useAppSelector(s => (s.identity && s.identity.user ? s.identity.user.jurisdiction : null))

    if (overrideJurisdiction) {
        jurisdiction = overrideJurisdiction
    }

    const defaultLink =
        jurisdiction === 'au' ? 'https://intercom.help/sharesies-au/en/' : 'https://intercom.help/sharesies/en/'
    const link =
        jurisdiction === 'au' && auArticle
            ? `${defaultLink}articles/${auArticle}`
            : jurisdiction !== 'au' && nzArticle
              ? `${defaultLink}articles/${nzArticle}`
              : defaultLink
    const defaultText = 'help centre'
    const text = children ? children : linkText ? linkText : defaultText

    return (
        <a href={link} target="_blank" rel="noopener" data-testid="link--help-centre">
            {text}
        </a>
    )
}

export default HelpCentreLink
