import {Button} from '@design-system/button'
import {DockableModal} from '@design-system/dockable-modal'
import cn from 'classnames'
import {Form, Formik} from 'formik'
import React, {useState} from 'react'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import {spacing} from '~/global/scss/helpers'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {validate} from '~/global/widgets/form-controls'
import {IRDNumber, Radio} from '~/global/widgets/form-controls/formik'
import {normalizeIRDNumber} from '~/global/widgets/form-controls/ird-number/IRDNumber'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import PIRCalculator from '~/global/widgets/pir-calculator/PIRCalculator'
import RWTCalculator from '~/global/widgets/rwt-calculator/RWTCalculator'
import {PIR, RWT} from '~/store/identity/types'

interface Props {
    onSuccess(): void
    pirRequired?: boolean
    rwtRequired?: boolean
}

interface FormValues {
    ird_number: string
    pir?: PIR
    rwt?: RWT
}

const PIR_VALUES = ['10.5', '17.5', '28']
const RWT_VALUES = ['10.5', '17.5', '28', '30', '33', '39']

const NZTaxDetailsForm = ({onSuccess, pirRequired = true, rwtRequired = false}: Props) => {
    const profile = useProfile()
    const [pirCalculatorVisible, setPirCalculatorVisible] = useState(false)
    const [rwtCalculatorVisible, setRwtCalculatorVisible] = useState(false)
    const [companyTaxModalVisable, setCompanyTaxModalVisable] = useState(false)

    const owner = useProfileOwner()
    const taxDetails = useRetailGet({
        path: 'owner/:owner_id/tax-details',
        pathParams: {owner_id: owner.id},
    }).data

    const irdNumber = taxDetails.ird_number
    const pir = taxDetails.pir
    const rwt = taxDetails.rwt_rate
    const hasSaveAccount = !!profile.portfolios.find(p => p.product === 'SAVE')
    const hasFonterraProduct = profile.portfolios.some(p => p.product === 'FONTERRA')

    const updateNZTaxDetails = useRetailPost({
        path: 'owner/:owner_id/nz-tax-details',
        pathParams: {owner_id: owner.id},
        queryCacheToUpdate: [`owner/:owner_id/tax-details`, {owner_id: owner.id}],
    })

    return (
        <Formik
            initialValues={{
                ird_number: normalizeIRDNumber(irdNumber || '', ''),
                pir,
                rwt,
            }}
            onSubmit={async (values, {setStatus}) => {
                try {
                    await updateNZTaxDetails.mutateAsync({
                        ird_number: values.ird_number!,
                        pir: values.pir!,
                        rwt_rate: values.rwt!,
                    })
                    onSuccess()
                } catch (e) {
                    setStatus(unknownErrorMessage)
                    throw e
                }
            }}
            validate={validate.generate<FormValues>({
                ird_number: [validate.required(), validate.irdNumber()],
                pir: pirRequired ? [validate.choices('10.5', '17.5', '28'), validate.required()] : [],
                rwt: rwtRequired ? [validate.choices('10.5', '17.5', '28', '30', '33', '39'), validate.required()] : [],
            })}
        >
            {({isValid, isSubmitting, setFieldValue, dirty}) => (
                <Form>
                    <IRDNumber dataTestId="text-input--ird-number" name="ird_number" label="IRD Number" />

                    {pirRequired && (
                        <>
                            <h2 className={spacing.spaceBelow16}>Prescribed Investor Rate (PIR)</h2>
                            <p className={spacing.spaceBelow24}>
                                Sharesies calculates tax on managed funds based on{' '}
                                {owner.is_self ? `${owner.display_name}’s` : 'your'} Prescribed Investor Rate (PIR). We
                                deduct tax (or pay a refund to {owner.is_self ? 'your' : `${owner.display_name}’s`}{' '}
                                Wallet) when {owner.is_self ? 'you sell' : `${owner.display_name} sells`} any units, and
                                at the end of the financial year (31 March). For more details check out the{' '}
                                <HelpCentreLink nzArticle="1399438-tax" />.
                            </p>

                            <Radio
                                dataTestId="radio--select-your-pir"
                                label="Select your PIR"
                                name="pir"
                                choices={PIR_VALUES.map(x => ({value: x, label: `${x}%`}))}
                            />
                            <p className={spacing.spaceBelow24}>
                                If you don’t know {owner.is_self ? 'your' : `${owner.display_name}’s`} PIR, just{' '}
                                <ButtonAsLink
                                    dataTestId="anchor--pir-calculator"
                                    onClick={() => {
                                        setPirCalculatorVisible(true)
                                    }}
                                >
                                    answer these questions
                                </ButtonAsLink>
                                .
                                <PIRCalculator
                                    isVisible={pirCalculatorVisible}
                                    onSelect={pir => {
                                        setPirCalculatorVisible(false)
                                        setFieldValue('pir', pir)
                                    }}
                                    onClose={() => setPirCalculatorVisible(false)}
                                    isDependent={!owner.is_self}
                                    preferredName={owner.display_name}
                                />
                            </p>
                        </>
                    )}

                    {(rwt || hasSaveAccount || rwtRequired) && (
                        <>
                            <h2 className={spacing.spaceBelow16}>Resident Witholding Tax (RWT)</h2>

                            <p className={spacing.spaceBelow24}>
                                We pay tax on the interest you earn for you. To make sure we pay the right amount to the
                                right place, we need some info. For more details check out the{' '}
                                <HelpCentreLink nzArticle="1399438-tax" />.
                            </p>

                            <Radio
                                dataTestId="radio--select-rwt"
                                label={owner.is_self ? 'Select your RWT' : `Select ${owner.display_name}’s RWT`}
                                name="rwt"
                                choices={RWT_VALUES.map(x => ({value: x, label: `${x}%`}))}
                            />
                            <p className={spacing.spaceBelow24}>
                                <a
                                    onClick={e => {
                                        e.preventDefault()
                                        if (hasFonterraProduct) {
                                            setCompanyTaxModalVisable(true)
                                        } else {
                                            setRwtCalculatorVisible(true)
                                        }
                                    }}
                                >
                                    Which RWT rate should I choose?
                                </a>
                                <RWTCalculator
                                    isVisible={rwtCalculatorVisible}
                                    onSelect={rwt => {
                                        setRwtCalculatorVisible(false)
                                        setFieldValue('rwt', rwt)
                                    }}
                                    onClose={() => setRwtCalculatorVisible(false)}
                                    isDependent={!owner.is_self}
                                    preferredName={owner.display_name}
                                />
                                <DockableModal
                                    dataTestId="dockable-modal--company"
                                    isOpen={companyTaxModalVisable}
                                    setIsOpen={setCompanyTaxModalVisable}
                                    title="RWT rate"
                                    content={
                                        <>
                                            <p>
                                                Most companies are 28%, 33% or 39%. We recommend chatting to a tax
                                                specialist if you’re unsure.
                                            </p>
                                            <Button
                                                dataTestId="button--okay"
                                                onClick={() => setCompanyTaxModalVisable(false)}
                                                label="Okay"
                                                additionalClassName={spacing.spaceAbove24}
                                            />
                                        </>
                                    }
                                />
                            </p>
                        </>
                    )}

                    <Button
                        isSubmit
                        dataTestId="button--save"
                        label="Save changes"
                        disabled={!isValid || !dirty}
                        processing={isSubmitting}
                        additionalClassName={cn(spacing.spaceBelow32, spacing.spaceAbove32)}
                    />
                </Form>
            )}
        </Formik>
    )
}

export default NZTaxDetailsForm
