import {Button} from '@design-system/button'
import Decimal from 'decimal.js'
import {Form, Formik} from 'formik'
import {useAtom} from 'jotai'
import {DateTime} from 'luxon'
import React from 'react'
import {Navigate, useNavigate} from 'react-router'
import {useRetailPost} from '~/api/query/retail'
import {Request} from '~/api/retail/types'
import {page} from '~/global/scss/helpers'
import {dateFormatShortDayFullMonth} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {ExchangeHours} from '~/global/widgets/exchange-hours/ExchangeHours'
import BuyAndSellFeesListedInstruments from '~/global/widgets/help-modals/BuyAndSellFeesListedInstruments'
import recordStyles from '~/global/widgets/instrument-activity/Record.scss'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {DollarValue, ItemValue} from '~/global/widgets/number-elements/NumberElements'
import OrderConfirmation, {OrderRow} from '~/global/widgets/order-confirmation/OrderConfirmation'
import ExchangeOrderProcessMessage from '~/global/widgets/order-process-messages/ExchangeOrderProcessMessage'
import Page from '~/global/widgets/page/Page'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import UpcomingDividendInformation from '~/global/widgets/upcoming-dividend-information/UpcomingDividendInformation'
import {useHoldingSummary} from '~/sections/moose/data/retail'
import {MooseOrderPathParams} from '~/sections/moose/routes'
import {buyOrderAtom} from '~/sections/moose/sections/order/state'
import {isUnsupportedInstrument} from '~/sections/moose/sections/order/utils/is-unsupported-instrument/isUnsupportedInstrument'
import {shareLabel} from '~/sections/moose/sections/order/utils/share-label/shareLabel'
import {BuyAndSellFeesForFcgInstrument} from '~/sections/moose/sections/order/widgets/buy-and-sell-fees-for-fcg-instrument/BuyAndSellFeesForFcgInstrument'
import {OrderError} from '~/sections/moose/sections/order/widgets/order-error/OrderError'
import {Instrument} from '~/store/instrument/types'
import styles from './LimitBuyConfirmPage.scss'

type CreateBuyOrderResult = CreateBuyOrderFailure | CreateBuyOrderSuccess
interface CreateBuyOrderSuccess {
    success: true
}
interface CreateBuyOrderFailure {
    success: false
    code: string
}

interface ValidLimitBuyOrder {
    instrument: Instrument
    highestPricePerItem: Decimal
    numberOfItemsToBuy: number
    timeoutDateToUse: DateTime
    totalAmount: Decimal
    totalAmountBeforeFees: Decimal
    expectedFee: Decimal
}

export const LimitBuyConfirmPage: React.FunctionComponent<MooseOrderPathParams> = ({
    portfolioId,
    urlSlug,
}: MooseOrderPathParams) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const holdingSummary = useHoldingSummary(portfolioId)
    const [stagedBuyOrder, updateStagedBuyOrder] = useAtom(buyOrderAtom)
    const createBuyOrderQuery = useRetailPost({
        path: 'fonterra/portfolios/:portfolio_id/orders/create-buy',
        pathParams: {portfolio_id: portfolioId},
    })

    if (isUnsupportedInstrument(urlSlug)) {
        return <Navigate to={profileUrl('fonterra/portfolios/:portfolioId', {portfolioId})} replace />
    }

    if (
        !stagedBuyOrder ||
        stagedBuyOrder.instrument.urlSlug !== urlSlug ||
        !stagedBuyOrder.pricePerItem ||
        !stagedBuyOrder.numberOfItems
    ) {
        return (
            <Navigate
                to={profileUrl('fonterra/portfolios/:portfolioId/invest/:urlSlug/buy/limit-order', {
                    portfolioId,
                    urlSlug,
                })}
                replace
            />
        )
    }

    if (
        !stagedBuyOrder.timeoutDateToUse ||
        !stagedBuyOrder.expectedFee ||
        !stagedBuyOrder.totalAmount ||
        !stagedBuyOrder.totalAmountBeforeFees
    ) {
        return (
            <Navigate
                to={profileUrl('fonterra/portfolios/:portfolioId/invest/:urlSlug/:action/time-limit', {
                    portfolioId,
                    urlSlug,
                    action: 'buy',
                })}
                replace
            />
        )
    }

    const createBuyOrder = async (stagedBuyOrder: ValidLimitBuyOrder): Promise<CreateBuyOrderResult> => {
        try {
            const payload: Request.FonterraPortfolioOrderCreateBuyRequest = {
                fund_id: stagedBuyOrder.instrument.id,
                expire_end_of_day: stagedBuyOrder.timeoutDateToUse.toISODate(),
                expected_fee: stagedBuyOrder.expectedFee.toString(),
                use_delayed_settlement: false,
                order: {
                    type: 'fonterra_portfolio_share_limit',
                    limit_volume: stagedBuyOrder.numberOfItemsToBuy,
                    limit_price: stagedBuyOrder.highestPricePerItem.toString(),
                },
            }

            await createBuyOrderQuery.mutateAsync(payload)
            return {success: true}
        } catch (error) {
            return {success: false, code: (error as any).code ?? 'unknown_error'}
        }
    }

    const validLimitBuyOrder: ValidLimitBuyOrder = {
        instrument: stagedBuyOrder.instrument,
        highestPricePerItem: stagedBuyOrder.pricePerItem,
        numberOfItemsToBuy: stagedBuyOrder.numberOfItems,
        timeoutDateToUse: stagedBuyOrder.timeoutDateToUse,
        totalAmount: stagedBuyOrder.totalAmount,
        totalAmountBeforeFees: stagedBuyOrder.totalAmountBeforeFees,
        expectedFee: stagedBuyOrder.expectedFee,
    }

    const itemType = stagedBuyOrder.itemType
    const orderCurrency = validLimitBuyOrder.instrument.currency
    const orderTimeOutdate = stagedBuyOrder.timeoutDateToUse.toFormat(dateFormatShortDayFullMonth)

    const orderItems: OrderRow[] = [
        {
            description: <>Highest price to pay per {shareLabel({itemType})}</>,
            value: <DollarValue value={validLimitBuyOrder.highestPricePerItem.toString()} currency={orderCurrency} />,
        },
        {
            description: <>Number of {shareLabel({itemType, isPlural: true})} to buy</>,
            value: <ItemValue value={stagedBuyOrder.numberOfItems} />,
        },
    ]

    if (itemType === 'SHARE') {
        orderItems.push({
            description: <BuyAndSellFeesForFcgInstrument />,
            value: 'Free',
        })
    }

    if (itemType === 'UNIT') {
        orderItems.push(
            {
                description: 'Estimated amount to invest',
                value: <DollarValue value={validLimitBuyOrder.totalAmountBeforeFees.toString()} />,
            },
            {
                description: (
                    <>
                        Estimated transaction fee <BuyAndSellFeesListedInstruments />
                    </>
                ),
                value: <DollarValue value={validLimitBuyOrder.expectedFee.toString()} />,
            },
        )
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--instrument-select"
                leftButton="back"
                title={`Confirm your limit buy in ${shareLabel({itemType, isPlural: true})}`}
                rightSlot={
                    <>
                        <div className={styles.exchangeHours}>
                            <ExchangeHours instrument={stagedBuyOrder.instrument} />
                        </div>
                    </>
                }
            />
            <Page>
                <Formik
                    initialValues={{errorCode: null}}
                    onSubmit={async (_, actions) => {
                        actions.setFieldValue('errorCode', null)
                        const result = await createBuyOrder(validLimitBuyOrder)
                        if (result.success) {
                            const pagesInFlow = 3 + (stagedBuyOrder.pushedHistory || 0)
                            updateStagedBuyOrder(undefined)
                            Toast('We’re placing your order')
                            navigate(-pagesInFlow)
                        } else {
                            actions.setFieldValue('errorCode', result.code)
                        }
                    }}
                >
                    {({values, isSubmitting}) => (
                        <Form>
                            <div className={styles.content}>
                                <div className={styles.orderForInfo}>
                                    For{' '}
                                    <span>
                                        {holdingSummary.entity_name} ({holdingSummary.farm_id})
                                    </span>
                                    , on market until close of market on {orderTimeOutdate} (New Zealand time).
                                </div>

                                <div className={styles.recieptContent}>
                                    <OrderConfirmation
                                        title={validLimitBuyOrder.instrument.name}
                                        subtitle={
                                            <p className={recordStyles.instrumentSymbol}>
                                                <PronounceLetters text={validLimitBuyOrder.instrument.symbol} />{' '}
                                                <Delimiter />{' '}
                                                <PronounceLetters text={validLimitBuyOrder.instrument.exchange} />
                                            </p>
                                        }
                                        image={<InstrumentLogo instrument={validLimitBuyOrder.instrument} noBorder />}
                                        items={orderItems}
                                        total={{
                                            description: 'Order amount',
                                            value: (
                                                <DollarValue
                                                    value={validLimitBuyOrder.totalAmount.toString()}
                                                    currency={orderCurrency}
                                                />
                                            ),
                                        }}
                                    />
                                </div>

                                <UpcomingDividendInformation verb="buy" instrument={validLimitBuyOrder.instrument} />

                                <ExchangeOrderProcessMessage
                                    instrument={validLimitBuyOrder.instrument}
                                    side="buy"
                                    isLimitOrder={true}
                                    isTriggerOrder={false}
                                    isForExtendedHours={false}
                                    cancelledAfterDays="90"
                                />
                            </div>

                            <ActionBar className={page.flexRow}>
                                <Button dataTestId="button--buy" label="Buy" isSubmit processing={isSubmitting} />
                            </ActionBar>
                            {values.errorCode && (
                                <OrderError
                                    errorCode={values.errorCode}
                                    instrumentName={stagedBuyOrder.instrument.name}
                                />
                            )}
                        </Form>
                    )}
                </Formik>
            </Page>
        </>
    )
}
