import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import NotFound from '~/global/pages/not-found/NotFound'
import {useHasInsureAccess} from '~/global/state-hooks/retail/useHasInsureAccess'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {CoveCustomiseEstimateWrapper} from '~/sections/protect/sections/insure/sections/car/pages/cove-customise-estimate/CoveCustomiseEstimate'
import {CoveManageContact} from '~/sections/protect/sections/insure/sections/car/pages/cove-manage-contact/CoveManageContact'
import {CoveManagePayment} from '~/sections/protect/sections/insure/sections/car/pages/cove-manage-payment/CoveManagePayment'
import {CovePolicy} from '~/sections/protect/sections/insure/sections/car/pages/cove-policy/CovePolicy'
import {CoveQuoteWrapper} from '~/sections/protect/sections/insure/sections/car/pages/cove-quote/CoveQuote'
import CreateCarEstimate from '~/sections/protect/sections/insure/sections/car/pages/create-car-estimate/CreateCarEstimate'
import {Estimate} from '~/sections/protect/sections/insure/sections/car/pages/estimate/Estimate'
import IntroSlides from '~/sections/protect/sections/insure/sections/car/pages/intro-slides/IntroSlides'
import {LandingErrorBoundary, LandingWrapper} from '~/sections/protect/sections/insure/sections/landing/Landing'

const InsureWrapper: React.FunctionComponent<{}> = () => {
    const profileUrl = useProfileUrl()
    const hasInsureAccess = useHasInsureAccess()

    if (hasInsureAccess) {
        return <Outlet />
    }

    // No Insure access: redirect to explore
    return <Navigate to={profileUrl('explore')} replace />
}

export const PROTECT_ROUTES = [
    {
        children: [
            {
                path: 'insure',
                Component: () => <InsureWrapper />,
                children: [
                    {
                        element: <LandingErrorBoundary />,
                        children: [{index: true, element: <LandingWrapper />}],
                    },
                    {path: 'car/intro', Component: IntroSlides},
                    {path: 'car/details', Component: CreateCarEstimate},
                    {path: 'car/estimate', Component: Estimate},
                    {path: 'car/cove-customise-quote', Component: CoveCustomiseEstimateWrapper},
                    {path: 'car/cove-manage-contact', Component: CoveManageContact},
                    {path: 'car/cove-manage-payment', Component: CoveManagePayment},
                    {path: 'car/cove-policy/:policyId', Component: CovePolicy},
                    {path: 'car/cove-quote/:quoteId', Component: CoveQuoteWrapper},
                    {path: '*', Component: NotFound},
                ],
            },
        ],
    },
] as const
