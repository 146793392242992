/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    ExchangeRate,
    ExchangeRateFromJSON,
    ExchangeRateFromJSONTyped,
    ExchangeRateToJSON,
    ExchangeRateToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface ExchangeRates
 */
export interface ExchangeRates {
    /**
     *
     * @type {Array<ExchangeRate>}
     * @memberof ExchangeRates
     */
    exchange_rates: Array<ExchangeRate>
}

export function ExchangeRatesFromJSON(json: any): ExchangeRates {
    return ExchangeRatesFromJSONTyped(json, false)
}

export function ExchangeRatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExchangeRates {
    if (json === undefined || json === null) {
        return json
    }
    return {
        exchange_rates: (json['exchange_rates'] as Array<any>).map(ExchangeRateFromJSON),
    }
}

export function ExchangeRatesToJSON(value?: ExchangeRates | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        exchange_rates: (value.exchange_rates as Array<any>).map(ExchangeRateToJSON),
    }
}
