import cn from 'classnames'
import React from 'react'
import {DistillScope} from '~/api/query/distill'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {dateFormatNoTime, dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import {amountOfManagedFundFee} from '~/global/utils/instrument-transaction-fee/instrumentTransactionFee'
import {hasTrades, isAutoInvestOrder, sortTradesByDate, totalTransactionFee} from '~/global/utils/order/order'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import useAssetManager from '~/global/utils/use-asset-manager/useAssetManager'
import {ADR} from '~/global/widgets/help-modals/ADR'
import BuyAndSellFeesListedInstruments from '~/global/widgets/help-modals/BuyAndSellFeesListedInstruments'
import BuyAndSellFeesRights from '~/global/widgets/help-modals/BuyAndSellFeesRights'
import SettlementDueDate from '~/global/widgets/help-modals/SettlementDueDate'
import {DollarValue, FeeValue, SharePriceValue} from '~/global/widgets/number-elements/NumberElements'
import {BuySellOrder} from '~/store/accounting/types'
import {Instrument} from '~/store/instrument/types'
import styles from '../Record.scss'
import {RecordRow, TransactionFeeRecordRow} from '../common'
import {TradesConcertina} from './TradesConcertina'
import {OrderPlacedRow, PartialFillAveragePriceInfo, SharesBoughtOrSold, TransactionFeeInfo} from './common'

export const FulfilledOrdersDataRow: React.FC<{
    currency: string
    instrument: Instrument
    jurisdiction: Model.User['jurisdiction']
    order: BuySellOrder
}> = React.memo(({currency, instrument, jurisdiction, order}) => {
    const [concertinaIsOpen, setConcertinaIsOpen] = React.useState(false)

    const hasSingleTrade = order.trades.length === 1
    const filledAllAtOnce = order.trades.length <= 1 // MF orders/autoinvests may be completed without there being a trade
    const hasMultipleTrades = !filledAllAtOnce
    const isAutoinvest = isAutoInvestOrder(order)
    const assetManager = useAssetManager(instrument, {scope: DistillScope.INVEST})

    const getPricePerShare = () => {
        if (order.order_unit_price) {
            return order.order_unit_price
        }
        return hasTrades(order) ? order.trades[0].share_price : undefined
    }
    const pricePerShare = getPricePerShare() // only used if order filled in 0 or 1 trades

    const usPartnershipTax = hasTrades(order) ? order.trades[0].us_partnership_tax_value : '0'

    const displayFeeModal = () => {
        if (instrument.isAdr) {
            return <ADR jurisdiction={jurisdiction} />
        }
        if (order.type === 'buy' && order.is_auto_exercise) {
            return <BuyAndSellFeesRights asIcon />
        }
        return <BuyAndSellFeesListedInstruments />
    }

    const sharesValue = () => {
        const defaultValue = '0' // Will never be used in practice
        if (order.type === 'buy') {
            return order.order_shares || defaultValue
        } else {
            return order.shares || defaultValue
        }
    }
    const sharesTotalValue = sharesValue()

    const handleConcertinaClick = () => {
        if (concertinaIsOpen) {
            setTimeout(() => {
                setConcertinaIsOpen((concertinaIsOpen: boolean) => !concertinaIsOpen)
            }, 200)
        } else {
            setConcertinaIsOpen((concertinaIsOpen: boolean) => !concertinaIsOpen)
        }
    }

    const orderFilledAtTimestamp = () => {
        // Only relevant for orders filled in 0 or 1 trades
        if (hasSingleTrade) {
            return order.trades[0].trade_datetime.toFormat(dateFormatWithYear)
        }

        if (order.fulfilled_at) {
            return order.fulfilled_at.toFormat(dateFormatWithYear)
        }

        return '-'
    }

    return (
        <div className={styles.recordRowContainer}>
            {/* Autoinvest orders use the create_transaction_id as the contract note ID */}
            {isAutoinvest && order.create_transaction_id && (
                <RecordRow left="Contract note" right={`N${order.create_transaction_id}`} />
            )}

            {/* If an order was filled in multiple trades, the contract note number for each will
                be included in the concertina rows below. */}
            {hasSingleTrade && <RecordRow left="Contract note" right={order.trades[0].contract_note_number} />}

            <OrderPlacedRow date={order.created} />

            {filledAllAtOnce && (
                <RecordRow className={styles.recordRowWithDate} left="Order filled" right={orderFilledAtTimestamp()} />
            )}

            {order.trigger_price && (
                <RecordRow
                    left="Trigger price"
                    right={<SharePriceValue value={order.trigger_price} currency={currency} />}
                />
            )}

            {/* Shares bought or sold */}
            <SharesBoughtOrSold
                hideBottomBorder={hasMultipleTrades}
                instrument={instrument}
                orderType={order.type}
                shareValue={sharesTotalValue}
            />

            {/* Breakdown of trades */}
            {hasMultipleTrades && (
                <div className={cn(styles.hasBottomBorder, spacing.spaceAbove12)}>
                    {sortTradesByDate(order).map((trade, idx) => {
                        return (
                            <TradesConcertina
                                key={trade.contract_note_number}
                                concertinaIsOpen={concertinaIsOpen}
                                currency={currency}
                                handleConcertinaClick={handleConcertinaClick}
                                hasSubsequentEntry={order.trades.length > idx + 1}
                                instrument={instrument}
                                jurisdiction={jurisdiction}
                                trade={trade}
                            />
                        )
                    })}
                </div>
            )}

            {/* Price per share */}
            {filledAllAtOnce && (
                <RecordRow
                    left={`Price per ${shareLabel({instrument})}`}
                    right={pricePerShare ? <SharePriceValue value={pricePerShare} currency={currency} /> : '-'}
                />
            )}
            {/* US Partnership Tax if any */}
            {filledAllAtOnce && Number(usPartnershipTax) > 0 && (
                <RecordRow
                    left="US Withholding Tax"
                    right={<DollarValue value={usPartnershipTax} currency={currency} />}
                />
            )}

            {order.price_limit && (
                <RecordRow
                    left="Limit price"
                    right={order.price_limit ? <SharePriceValue value={order.price_limit} currency={currency} /> : '-'}
                />
            )}

            <RecordRow
                left={<SettlementDueDate jurisdiction={jurisdiction} />}
                right={order.will_settle_on ? order.will_settle_on.toFormat(dateFormatNoTime) : 'pending'}
            />

            {/* Average price per share */}
            {hasMultipleTrades && (
                <PartialFillAveragePriceInfo currency={currency} instrument={instrument} order={order} />
            )}

            {tradingType(instrument) === 'managed' && order.order_provider_fee != null && assetManager && (
                <RecordRow
                    left={<TransactionFeeInfo assetManagerName={assetManager.shortName} isEstimated={false} />}
                    right={<FeeValue value={order.order_provider_fee} currency={currency} />}
                />
            )}

            {tradingType(instrument) === 'managed' && order.order_provider_fee == null && assetManager && (
                <RecordRow
                    left={<TransactionFeeInfo assetManagerName={assetManager.shortName} isEstimated={true} />}
                    right={
                        <FeeValue
                            value={amountOfManagedFundFee(
                                order.type === 'buy' ? order.requested_currency || '0' : order.order_total || '0',
                                instrument.fixedFeeSpread || '0',
                            )}
                            currency={currency}
                        />
                    }
                />
            )}

            {(tradingType(instrument) === 'listed' || instrument.isAdr) && (
                <TransactionFeeRecordRow
                    left={
                        <div>
                            {filledAllAtOnce ? 'Transaction fee' : 'Total transaction fee'}
                            {displayFeeModal()}
                        </div>
                    }
                    right={<FeeValue value={totalTransactionFee(order)} currency={currency} />}
                    order={order}
                />
            )}
        </div>
    )
})
