import cn from 'classnames'
import React from 'react'
import {Model} from '~/api/retail/types'
import {spacing, typographyOverrides} from '~/global/scss/helpers'

interface Props {
    jurisdiction: Model.User['jurisdiction']
    taxTreatyStatus: Model.User['us_tax_treaty_status']
}

const TaxTreatyBenefit = ({jurisdiction, taxTreatyStatus}: Props) => {
    const country = jurisdiction === 'nz' ? 'New Zealand' : jurisdiction === 'au' ? 'Australia' : ''
    const countryAbrev = jurisdiction === 'nz' ? 'NZ' : jurisdiction === 'au' ? 'Australian' : ''
    const mainTaxTreatyCountry = jurisdiction === 'nz' ? 'Australian' : jurisdiction === 'au' ? 'NZ' : ''

    const hasUSTaxTreaty =
        (jurisdiction === 'nz' && taxTreatyStatus === 'nz') || (jurisdiction === 'au' && taxTreatyStatus === 'au')
    const taxTreatyCountries =
        jurisdiction === 'nz'
            ? `Australia ${hasUSTaxTreaty ? 'and the US' : ''}`
            : jurisdiction === 'au'
              ? `New Zealand ${hasUSTaxTreaty ? 'and the US' : ''}`
              : ''
    return (
        <>
            <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow12, spacing.spaceAbove32)}>
                Tax treaty benefits
            </h2>
            <p>
                {country} has tax treaties with {taxTreatyCountries} to avoid taxing you twice on the same investment.
            </p>
            <br />
            <p>
                You’re an {countryAbrev} tax resident so you get the {mainTaxTreatyCountry} tax treaty benefit.{' '}
                {hasUSTaxTreaty &&
                    `You also get the US tax treaty benefit as you have an ${countryAbrev} address and aren’t a US tax resident.`}
            </p>
        </>
    )
}

export default TaxTreatyBenefit
