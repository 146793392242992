import {Button} from '@design-system/button'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import ReactPixel from 'react-facebook-pixel'
import {useNavigate} from 'react-router'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {getStringFromError, useRetailPost} from '~/api/query/retail'
import {redditTrackCustom} from '~/api/reddit/redditPixel'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import config from '~/configForEnv'
import NumberOneDark from '~/global/assets/images/numbers/number-one-dark.svg'
import NumberOneLight from '~/global/assets/images/numbers/number-one.svg'
import NumberThreeDark from '~/global/assets/images/numbers/number-three-dark.svg'
import NumberThreeLight from '~/global/assets/images/numbers/number-three.svg'
import NumberTwoDark from '~/global/assets/images/numbers/number-two-dark.svg'
import NumberTwoLight from '~/global/assets/images/numbers/number-two.svg'
import {spacing} from '~/global/scss/helpers'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {Checkbox} from '~/global/widgets/form-controls'
import {Image} from '~/global/widgets/image/Image'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/kiwisaver/sections/sign-up/index.scss'
import {
    SignUpGuardRedirect,
    SignUpPage,
} from '~/sections/kiwisaver/sections/sign-up/widgets/redirect/SignUpGuardRedirect'
import {useKSCustomerOrNull} from '~/sections/kiwisaver/state'
import {InvestmentPlanSummaryCard} from '~/sections/kiwisaver/widgets/investment-plan-summary-card/InvestmentPlanSummaryCard'
import {PageTitle} from '~/sections/kiwisaver/widgets/page-title/PageTitle'
import {useAppSelector} from '~/store/hooks'
import {actingAsID as actingAsIDSelector} from '~/store/identity/selectors'

export const Confirm: React.FunctionComponent<{}> = () => {
    const profileUrl = useProfileUrl()
    const actingAsID = useAppSelector(actingAsIDSelector)
    const customer = useKSCustomerOrNull()
    const queryClient = useQueryClient()
    const setConfirmSignup = useRetailPost({
        path: 'kiwisaver/confirm-signup',
        queryCacheToUpdate: ['kiwisaver/customer'],
        queryCacheToInvalidate: ['profiles'],
    })
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [checked, setChecked] = React.useState(false)
    const navigate = useNavigate()

    const onConfirm = async () => {
        setIsSubmitting(true)
        try {
            rudderTrack('kiwisaver_signup', 'join_scheme_confirmed')

            ReactPixel.trackSingle(config.facebookPixelIdNZ, 'kiwisaver account open', {
                successful: true,
            })
            redditTrackCustom('KiwiSaver Account Open')
            Analytics.event({category: 'kiwisaver', action: 'account-open', label: ''})
            Analytics.kiwisaverSignUpFloodlightEvent()

            await setConfirmSignup.mutateAsync({
                acting_as_id: actingAsID,
            })

            // we created a new portfolio, we need an updated Rakaia JWT token to be able to access it
            // note we remove the cache altogether, as we don't want to keep the old token around
            queryClient.removeQueries({queryKey: ['identity/rakaia-token']})
            // update rakaia summary endpoint so it can include the new portfolio
            queryClient.invalidateQueries({queryKey: ['portfoliosSummaryApiV1PortfoliosSummaryAllGet']})

            sendWrapperAppMessage({type: 'identityUpdated'})
        } catch (e: any) {
            // Customer signup and accept the previous PDS and IOS and did not complete the signup flow
            // After awhile, they come back to complete the signup flow but we have updated new PDS and IOS
            // We need to redirect them to the risk indication page to accept the new PDS and IOS
            if (getStringFromError(e, 'message') === `Customer ${actingAsID} has not accepted the PDS`) {
                Toast('You must accept the PDS & IOS first')
                navigate(profileUrl('kiwisaver/sign-up/edit-investment-plan/risk-indication'))
            } else {
                Toast(getStringFromError(e, 'message') || 'Could not confirm signing you up')
            }
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <SignUpGuardRedirect currentPage={SignUpPage.CONFIRM}>
            <Toolbar leftButton="back" dataTestId="toolbar--sign-up-confirm" />{' '}
            <Page className={styles.confirmPage}>
                <PageTitle className={spacing.spaceBelow16}>Confirmation</PageTitle>
                {customer?.investment_plan && (
                    <InvestmentPlanSummaryCard
                        plan={customer.investment_plan}
                        className={spacing.spaceBelow24}
                        editUrl={profileUrl('kiwisaver/sign-up/edit-investment-plan')}
                    />
                )}
                <h3>What to expect</h3>
                <ol className={spacing.spaceAbove16}>
                    <li>
                        <Image src={NumberOneLight} darkSrc={NumberOneDark} />
                        <span>
                            Your part is done! We’ll contact IRD and get your KiwiSaver account set up—this can take up
                            to 10 working days.
                        </span>
                    </li>
                    <li>
                        <Image src={NumberTwoLight} darkSrc={NumberTwoDark} />
                        <span>
                            You’ll get an email summarising your investment plan order and have a day to make changes.
                        </span>
                    </li>
                    <li>
                        <Image src={NumberThreeLight} darkSrc={NumberThreeDark} />
                        <span>
                            Your KiwiSaver balance and any future contributions will be used to place an order using
                            your plan.
                        </span>
                    </li>
                </ol>
                <label className={styles.confirmTerms}>
                    <Checkbox
                        dataTestId="checkbox--accept-terms"
                        name="accept"
                        isTouched={true}
                        value={checked}
                        onChange={() => setChecked(c => !c)}
                    />
                    <strong>I confirm the following:</strong>
                </label>
                <ul>
                    <li>I’m a New Zealand citizen or entitled to be in New Zealand indefinitely.</li>
                </ul>
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--join"
                    label="Join the Sharesies KiwiSaver Scheme"
                    onClick={onConfirm}
                    processing={isSubmitting}
                    disabled={!checked}
                />
            </ActionBar>
        </SignUpGuardRedirect>
    )
}
