import {Model} from '~/api/retail/types'
import {createAction, ActionsUnion} from '../redux-tools'

const actions = {
    SetCreateGiftAmount: (amount: string | null) => createAction('gift.SetCreateGiftAmount', amount),
    SetPurchasedGift: (gift: Model.Gift | null) => createAction('gift.SetPurchasedGift', gift),
}

const thunkActions = {}

export type ActionsType = ActionsUnion<typeof actions>

export default {...actions, ...thunkActions}
