/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum PortfolioRiskType {
    DEFENSIVE = 'DEFENSIVE',
    CONSERVATIVE = 'CONSERVATIVE',
    BALANCED = 'BALANCED',
    GROWTH = 'GROWTH',
    AGGRESSIVE = 'AGGRESSIVE',
}

export function PortfolioRiskTypeFromJSON(json: any): PortfolioRiskType {
    return PortfolioRiskTypeFromJSONTyped(json, false)
}

export function PortfolioRiskTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioRiskType {
    return json as PortfolioRiskType
}

export function PortfolioRiskTypeToJSON(value?: PortfolioRiskType | null): any {
    return value as any
}
