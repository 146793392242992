import {ArrowRight} from '@design-system/icon'
import React from 'react'
import {Link} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import styles from './ArrowLink.scss'

interface ArrowLinkProps {
    url: string
    label: string
    dataTestId: string
}

export const ArrowLink: React.FunctionComponent<ArrowLinkProps> = ({url, label, dataTestId}) => {
    return (
        <>
            <div className={spacing.spaceAbove16}>
                <Link data-testid={dataTestId} to={url} className={styles.activityLink}>
                    {label}
                    <ArrowRight data-testid={`${dataTestId}-arrow`} />
                </Link>
            </div>
        </>
    )
}
