import {Model} from '~/api/retail/types'

/*
Note that all of these types below are primarily to work around this issue:

https://github.com/microsoft/TypeScript/issues/18758

Broadly we're copying `investmentActivity.detail.type` up to `investmentActivity.detailType` so that type narrowing works nicely
in the code below.
*/

/**
 * A InvestmentActivity with no detail on it
 */
type InvestmentActivityNoDetail = Omit<Model.InvestmentActivity, 'detail'> & {detailType: undefined; detail: undefined}

/**
 * A map of detail.type to a InvestmentActivity with a detailType field and detail matching that type
 */
export type InvestmentActivityDetailTypes = {
    [T in NonNullable<Model.InvestmentActivity['detail']>['type']]: Omit<Model.InvestmentActivity, 'detail'> & {
        detailType: T
        detail: Extract<Model.InvestmentActivity['detail'], {type: T}>
    }
}

/**
 * A union of all of the above suitable for switching on to control rendering
 */
type InvestmentActivityWithDetail =
    | InvestmentActivityNoDetail
    | InvestmentActivityDetailTypes[keyof InvestmentActivityDetailTypes]

export function investmentActivityToInvestmentActivityWithDetail(
    t: Model.InvestmentActivity,
): InvestmentActivityWithDetail {
    // This is the only place we need to have type coercion, and we can do it because we know that
    // InvestmentActivityWithDetail always has obj.detailType === obj.detail?.type
    return {...t, detailType: t.detail?.type} as InvestmentActivityWithDetail
}
