import Decimal from 'decimal.js'
import React from 'react'
import {Link} from 'react-router-dom'
import usWelcome1 from '~/global/assets/images/intro-slides/us-welcome-1.png'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useCurrentSubscriptionPlan} from '~/global/utils/subscription-hooks/subscriptionHooks'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Concertina from '~/global/widgets/concertina/Concertina'
import concertinaStyles from '~/global/widgets/concertina/Concertina.scss'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import type {
    LearnPageContent,
    ClassicIntroSlideContent,
} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import Image from '~/global/widgets/image/Image'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {RiskScaleRanges} from '~/global/widgets/risk-scale/RiskScale'
import auExchange from '~/sections/explore/pages/learn-static-content/assets/images/au-exchange.svg'
import autoInvest from '~/sections/explore/pages/learn-static-content/assets/images/auto-invest.svg'
import etfs from '~/sections/explore/pages/learn-static-content/assets/images/etfs.svg'
import exchangeFees from '~/sections/explore/pages/learn-static-content/assets/images/exchange-fees.svg'
import exchangeMoney from '~/sections/explore/pages/learn-static-content/assets/images/exchange-money.svg'
import exchangeRate from '~/sections/explore/pages/learn-static-content/assets/images/exchange-rate.svg'
import exchangesMarkets from '~/sections/explore/pages/learn-static-content/assets/images/exchanges-markets.svg'
import funds from '~/sections/explore/pages/learn-static-content/assets/images/funds.svg'
import investmentRiskLevels from '~/sections/explore/pages/learn-static-content/assets/images/investment-risk-levels.svg'
import limitOrders from '~/sections/explore/pages/learn-static-content/assets/images/limit-orders.svg'
import managedFundPrices from '~/sections/explore/pages/learn-static-content/assets/images/managed-fund-prices.svg'
import managedFundTimes from '~/sections/explore/pages/learn-static-content/assets/images/managed-fund-times.svg'
import managedFunds from '~/sections/explore/pages/learn-static-content/assets/images/managed-funds.svg'
import marketOrders from '~/sections/explore/pages/learn-static-content/assets/images/market-orders.svg'
import marketTimes from '~/sections/explore/pages/learn-static-content/assets/images/market-times.svg'
import nzExchange from '~/sections/explore/pages/learn-static-content/assets/images/nz-exchange.svg'
import onMarketPricesDark from '~/sections/explore/pages/learn-static-content/assets/images/on-market-prices-dark.svg'
import onMarketPricesLight from '~/sections/explore/pages/learn-static-content/assets/images/on-market-prices-light.svg'
import portfolioTypeAggressiveDark from '~/sections/explore/pages/learn-static-content/assets/images/portfolio-type-aggressive-dark.svg'
import portfolioTypeAggressiveLight from '~/sections/explore/pages/learn-static-content/assets/images/portfolio-type-aggressive-light.svg'
import portfolioTypeBalancedDark from '~/sections/explore/pages/learn-static-content/assets/images/portfolio-type-balanced-dark.svg'
import portfolioTypeBalancedLight from '~/sections/explore/pages/learn-static-content/assets/images/portfolio-type-balanced-light.svg'
import portfolioTypeConservativeDark from '~/sections/explore/pages/learn-static-content/assets/images/portfolio-type-conservative-dark.svg'
import portfolioTypeConservativeLight from '~/sections/explore/pages/learn-static-content/assets/images/portfolio-type-conservative-light.svg'
import portfolioTypeDefensiveDark from '~/sections/explore/pages/learn-static-content/assets/images/portfolio-type-defensive-dark.svg'
import portfolioTypeDefensiveLight from '~/sections/explore/pages/learn-static-content/assets/images/portfolio-type-defensive-light.svg'
import portfolioTypeGrowthDark from '~/sections/explore/pages/learn-static-content/assets/images/portfolio-type-growth-dark.svg'
import portfolioTypeGrowthLight from '~/sections/explore/pages/learn-static-content/assets/images/portfolio-type-growth-light.svg'
import portfolioTypes from '~/sections/explore/pages/learn-static-content/assets/images/portfolio-types.svg'
import publiclyListedCompanies from '~/sections/explore/pages/learn-static-content/assets/images/publicly-listed-companies.svg'
import voting from '~/sections/explore/pages/learn-static-content/assets/images/voting.svg'
import {MAX_DIY_ORDERS} from '~/sections/invest/sections/auto-invest/constants/constants'
import {Instrument} from '~/store/instrument/types'
import styles from './LearnContent.scss'

// some of the content on this page is used by both Learn and IntroSlides (and also help modals but those don't care about the types they only need the bodyRenderer property)
// this is the superset of these two types so it can be used interchangeably for either
type LearnPageAndClassicIntroSlideContent<ExtraProps extends {} = any> = LearnPageContent<ExtraProps> &
    ClassicIntroSlideContent<ExtraProps>

const autoInvestBody = {
    au: (profileUrl: ReturnType<typeof useProfileUrl>) => (
        <>
            <p>
                Put your investing on repeat with auto-invest. Just set how much, how often, and what you’d like to
                invest in.
            </p>
            <p>
                You can create up to {MAX_DIY_ORDERS.au} separate auto-invest orders, from a range of over 8,000
                investments listed on Australian, New Zealand, and US exchanges.
            </p>
            <p>
                Whether you want to include multiple investments, or just one—an auto-invest order can do the legwork.
            </p>
            <p>
                For more info,{' '}
                <Link data-testid="link--checkout-autoinvest" to={profileUrl('auto-invest')}>
                    check out auto-invest.
                </Link>
            </p>
        </>
    ),
    nz: (profileUrl: ReturnType<typeof useProfileUrl>) => (
        <>
            <p>
                Put your investing on repeat. Just choose your order, then set how much and how often you’d like to
                invest. Orders come in 4 flavours: DIY, responsible, global, and kids.
            </p>
            <p className={styles.subheading}>DIY order</p>
            <p>A DIY order lets you pick the investments to regularly invest in.</p>
            <p>
                You’ve got the choice of over 8,000 investments across US, Aussie, and NZ exchanges, and you decide the
                percentage to invest in each.
            </p>
            <p>
                You can create up to five DIY orders, with up to 100 investments in each, letting you tailor each order
                to your investing goals.
            </p>
            <p className={styles.subheading}>Responsible order</p>
            <p>
                The funds in this order invest with an environmental, social, and governance (ESG) approach, and exclude
                companies involved with gambling, weapons, and tobacco.
            </p>
            <p className={styles.subheading}>Global order</p>
            <p>
                The funds in this order invest in developed markets like the US, Japan, Europe, Australia, and New
                Zealand—and emerging markets like Brazil, India, and China.
            </p>
            <p className={styles.subheading}>Kids order</p>
            <p>
                The funds in this order invest in medium and high-risk investments, and tax is based on the kid’s
                prescribed investor rate (PIR) rather than a standard tax rate.
            </p>
            <p>
                To set up an order,{' '}
                <Link data-testid="link--checkout-autoinvest" to={profileUrl('auto-invest')}>
                    go to auto-invest.
                </Link>
            </p>
        </>
    ),
}

export const AutoInvestContent: LearnPageContent = {
    image: autoInvest,
    title: 'Auto-invest',
    slug: 'auto-invest',
    bodyRenderer: ({jurisdiction, profileUrl}) => autoInvestBody[jurisdiction](profileUrl),
}

export const ETFContent: LearnPageContent = {
    image: etfs,
    title: 'Exchange-traded funds (ETFs)',
    slug: 'exchange-traded-funds',
    bodyRenderer: () => (
        <p>
            Much like a managed fund, you invest in a range of investments. These are selected by a set of rules, or a
            manager and are available to be bought or sold on an exchange.
        </p>
    ),
}

const ExchangeFeesRender: LearnPageContent['bodyRenderer'] = ({jurisdiction, homeCurrency}) => {
    const feeRate = jurisdiction === 'au' ? new Decimal(0.006) : new Decimal(0.005)
    const spotRate = jurisdiction === 'au' ? new Decimal(0.7) : new Decimal(0.66)
    const totalAmount = new Decimal(1000)
    const valueAmount = totalAmount.div(feeRate.add(new Decimal(1))).toDecimalPlaces(2)
    const feeAmount = totalAmount.minus(valueAmount)

    const feePercentage = feeRate.mul(new Decimal(100)).toDecimalPlaces(1).toString()

    const total = <DollarValue value={totalAmount.toString()} currency={homeCurrency} decimalPlaces={0} />
    const fee = <DollarValue value={feeAmount.toString()} currency={homeCurrency} decimalPlaces={2} />
    const valueExchanged = <DollarValue value={valueAmount.toString()} currency={homeCurrency} decimalPlaces={2} />

    return (
        <>
            <p>When you exchange money, you’re charged a {feePercentage}% currency exchange fee.</p>
            <p>The fee is calculated on the amount to exchange, and deducted from the amount you enter.</p>
            <p>Exchange rate (order amount – (amount to exchange ✕ {feePercentage}%)) = how much you’ll get</p>
            <p>
                For example, a {total} order is made up of a {fee} exchange fee and a {valueExchanged} amount to
                exchange:
            </p>
            <p>
                {valueExchanged} × {feePercentage}% = {fee}
            </p>
            <p>
                {total} – {fee} = {valueExchanged}
            </p>
            <p>
                We’ll then exchange the remaining {valueExchanged} at the exchange rate. If this was 1{' '}
                {homeCurrency.toUpperCase()} = {spotRate.toString()} USD, you’d get{' '}
                <DollarValue value={valueAmount.mul(spotRate).toString()} currency="usd" decimalPlaces={2} />.
            </p>
            <p>
                For a full run-down of our fees,{' '}
                <a
                    target="_blank"
                    rel="noopener"
                    href={
                        jurisdiction === 'au'
                            ? 'https://www.sharesies.com.au/pricing'
                            : 'https://www.sharesies.nz/pricing'
                    }
                >
                    check out our website
                </a>
                .
            </p>
        </>
    )
}

export const ExchangeFeesContent: LearnPageAndClassicIntroSlideContent = {
    image: exchangeFees,
    title: 'Fees for exchanging money',
    slug: 'exchange-fees',
    bodyRenderer: ExchangeFeesRender,
}

export const ExchangeMoneyContent: LearnPageAndClassicIntroSlideContent = {
    image: exchangeMoney,
    title: 'Choose when to exchange money',
    slug: 'exchange-money',
    bodyRenderer: ({homeCurrency}) => (
        <>
            <p>
                With Sharesies you’re in control of when you exchange money. You can exchange money almost instantly
                when you buy. Or alternatively exchange in advance and hold it. If you want to withdraw you’ll need to
                exchange back into {homeCurrency === 'aud' ? 'AUD' : 'NZD'}.
            </p>
        </>
    ),
}

export const ExchangeRateContent: LearnPageAndClassicIntroSlideContent = {
    image: exchangeRate,
    title: 'Wholesale exchange rate',
    slug: 'interbank-rate',
    bodyRenderer: () => (
        <>
            <p>
                Sharesies offers a wholesale exchange rate—typically this is only offered to banks and large companies
                for large amounts of money. This means the rates you see in Sharesies will be very close to rates on
                Google, XE.com and other financial sites.
            </p>
        </>
    ),
}

export const ExchangesMarketsContent: LearnPageContent = {
    image: exchangesMarkets,
    title: 'Exchanges and markets',
    slug: 'markets-and-exchanges',
    bodyRenderer: () => (
        <>
            <p>
                An exchange is a market where shares in companies, bonds, options, futures, and commodities are bought
                and sold. Just like other market places, buyers and sellers come together to trade during specific hours
                on business days.
            </p>
            <p>
                Exchanges are regulated by the Government and only approved market participants can buy and sell on your
                behalf.
            </p>
            <p>Generally when people talk about ‘the market’ or ‘markets’, they’re talking group of exchanges.</p>
        </>
    ),
}

export const FundsContent: LearnPageContent = {
    image: funds,
    title: 'Funds',
    slug: 'funds',
    bodyRenderer: () => (
        <>
            <p>
                Instead of investing in one company at a time, you join a pool of people and spread your money across a
                range of investments. This means you don’t directly own the shares, you own a share of the fund.
            </p>
            <p>
                For more information check out our{' '}
                <HelpCentreLink nzArticle="3146288-what-s-the-difference-between-a-fund-and-a-company" />.
            </p>
        </>
    ),
}

export const LimitOrdersContent: LearnPageAndClassicIntroSlideContent<{instrument: Instrument}> = {
    image: limitOrders,
    title: 'Limit orders',
    slug: 'limit-orders',
    bodyRenderer: ({instrument, profileUrl}) => (
        <>
            <p>
                Limit orders give you more control to buy or sell{' '}
                {instrument ? shareLabel({instrument, isPlural: true}) : 'shares'} at a specific price, or better in
                line with our{' '}
                <a href={profileUrl('legal/best-price')} target="_blank" rel="noopener">
                    best price policy
                </a>
                .
            </p>
            <ul>
                <li>A limit buy lets you set the highest price you’re willing to pay per share.</li>
                <li>A limit sell lets you set the minimum price you want to sell at per share.</li>
            </ul>
            <p>
                For more information check out our{' '}
                <HelpCentreLink auArticle="4983230-how-to-buy-investments" nzArticle="3509896-limit-order-faq" />.
            </p>
        </>
    ),
}

export const KiwiSaverBuySellFlowPricesContent: LearnPageContent = {
    title: 'Prices for Sharesies KiwiSaver Scheme funds',
    slug: 'kiwisaver-buy-sell-prices',
    bodyRenderer: () => (
        <>
            <p>
                When you invest in companies or funds as part of the Sharesies KiwiSaver Scheme you are buying units in
                a Sharesies fund. This fund then buys shares or units in your chosen investment.
            </p>
            <p>
                When you buy or sell within Sharesies KiwiSaver Scheme, the price you see is the{' '}
                <b>unit price of the Sharesies fund </b>rather than the price of the underlying investment.
            </p>
            <p>
                If you want to learn more about how our Scheme is structured{' '}
                <a target="_blank" rel="noopener" href="https://www.sharesies.nz/kiwisaver?scroll=how-our-scheme-works">
                    check out our website
                </a>
                .
            </p>
        </>
    ),
}

export const KiwiSaverPricesContent: LearnPageContent = {
    title: 'Prices for investments on Sharesies KiwiSaver Scheme',
    slug: 'kiwisaver-prices',
    bodyRenderer: () => (
        <>
            <p>
                When you invest in companies or funds as part of the Sharesies KiwiSaver Scheme you are buying units in{' '}
                a Sharesies fund. This fund then buys shares or units in your chosen investment.
            </p>
            <p>
                When you look at investments within the Sharesies KiwiSaver Scheme, the price you see is the{' '}
                <b>price of the underlying investment</b> rather than the unit price of the Sharesies fund. You can see
                the unit price of the Sharesies fund in the Your investment tab.
            </p>
            <p>
                The price you see for exchange-traded investments is delayed by at least 20 minutes when the market is{' '}
                open. This means the price you see is likely to be different to the price you buy or sell at.
            </p>
            <p>
                If you want to learn more about how our Scheme is structured{' '}
                <a target="_blank" rel="noopener" href="https://www.sharesies.nz/kiwisaver?scroll=how-our-scheme-works">
                    check out our website
                </a>
                .
            </p>
        </>
    ),
}

export const ManagedFundPricesContent: LearnPageAndClassicIntroSlideContent = {
    image: managedFundPrices,
    title: 'Prices for managed funds',
    slug: 'managed-fund-prices',
    bodyRenderer: () => (
        <>
            <p>The price you see for managed funds will be different than the price you buy or sell at.</p>
            <p>
                The price you see is the most recent mid-price we’ve received from the fund provider and will always be
                at least one day old. The mid-price is adjusted by the fund’s transaction fee to create the buy and sell
                price. Fees are also set by the fund provider.
            </p>
            <div className={styles.block}>
                Mid-price + transaction fee = Buy price
                <br />
                <br />
                Mid-price - transaction fee = Sell price
            </div>
            <p>
                For more information, check out our{' '}
                <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.sharesies.nz/learn/behind-the-scenes-unit-prices-for-managed-funds"
                >
                    blog
                </a>
                .
            </p>
        </>
    ),
}

export const ManagedFundsContent: LearnPageContent = {
    image: managedFunds,
    title: 'Managed funds',
    slug: 'managed-funds',
    bodyRenderer: () => (
        <>
            <p>
                A fund manager chooses the investments based on their investing strategy. Buying and selling goes
                through the fund manager and usually takes a couple of days.
            </p>
            {/* TODO - add help centre link once specific content is ready*/}
        </>
    ),
}

export const ManagedFundProcessingTimesContent: LearnPageAndClassicIntroSlideContent = {
    image: managedFundTimes,
    title: 'Processing times for managed funds',
    slug: 'managed-fund-processing-times',
    bodyRenderer: () => (
        <>
            <p>
                Each fund has different processing times to fill your order and confirm the price you get. This means
                there’s a delay between when you place an order, and when it shows in your Portfolio.
            </p>
            <p>This can range from 1 to 2 trading days, but some managed funds can take up to 23 trading days.</p>
            <p>
                We start processing orders at around 12:30pm NZ time each trading day and send these to the relevant
                fund provider. Once your order is processing you can’t cancel it anymore.
            </p>
            <p>
                For more information check out our{' '}
                <HelpCentreLink nzArticle="2545798-t-1-and-t-2-settlement-managed-funds" />.
            </p>
        </>
    ),
}

export const MarketOrdersContent: LearnPageAndClassicIntroSlideContent<{instrument: Instrument}> = {
    image: marketOrders,
    title: 'Market orders',
    slug: 'market-orders',
    bodyRenderer: ({instrument, profileUrl}) => (
        <>
            <p>
                Market orders are typically used when investors want an order to be processed quickly and are willing to
                let the market decide the price.
            </p>
            <p>
                When the market is open and there are {instrument ? shareLabel({instrument, isPlural: true}) : 'shares'}{' '}
                available, your order will get placed and will almost always get filled, in line with our{' '}
                <a href={profileUrl('legal/best-price')} target="_blank" rel="noopener">
                    best price policy
                </a>
                .
            </p>
            <p>
                For more information check out our{' '}
                <HelpCentreLink
                    auArticle="5139127-differences-between-a-market-and-limit-order"
                    nzArticle="3509885-what-s-the-difference-between-a-market-order-and-a-limit-order"
                />
                .
            </p>
        </>
    ),
}

export const MarketProcessingTimesContent: LearnPageAndClassicIntroSlideContent = {
    image: marketTimes,
    title: 'Processing times for exchange-traded investments',
    slug: 'processing-times-for-on-market-investments',
    bodyRenderer: () => (
        <>
            <p>Processing times will vary and orders might not fill depending on:</p>
            <ul>
                <li>if the market is open or closed</li>
                <li>if the market has a trading halt</li>
                <li>where you are in the queue and the value of orders ahead of you</li>
                <li>if the investment doesn’t hit the limit price you’ve set</li>
            </ul>
            <p>
                Orders may be filled bit-by-bit and can stay open for up to 30 days on the market before some, or all of
                it is cancelled and the money returned to your Wallet.
            </p>
            <p>
                For more information check out our{' '}
                <HelpCentreLink auArticle="4983245-order-processing-times" nzArticle="3098147-order-processing-times" />
                .
            </p>
        </>
    ),
}

export const NZExchangeContent: LearnPageContent = {
    image: nzExchange,
    title: 'NZ Stock Exchange (NZX)',
    slug: 'new-zealand-stock-exchange',
    bodyRenderer: () => (
        <>
            <p>
                Over 200 companies and funds are traded on the New Zealand Stock Exchange Main Board, the NZX. You’ll
                find Kiwi companies like Air New Zealand, a2 Milk, Fisher & Paykel Healthcare, Spark and Pushpay
                available.
            </p>
            <p>
                Sharesies places orders during the core trading hours from 10am to 4:45pm. You can queue up an order any
                time, but your order won’t be placed until the exchange is open. The NZX is open weekdays, except for NZ
                public holidays.
            </p>
        </>
    ),
}

export const AUExchangeContent: LearnPageContent = {
    image: auExchange,
    title: 'Australian Securities Exchange (ASX)',
    slug: 'australian-securities-exchange',
    bodyRenderer: ({jurisdiction}) => (
        <>
            <p>
                Access over 2,000 companies and exchange-traded funds (ETFs) on the Australian Securities Exchange
                (ASX). You’ll find brands like Breville, Xero, Qantas, Afterpay, and Seek.
            </p>
            <p>
                The ASX is open on weekdays, except for Australian public holidays. See exact trading hours for the week
                ahead by looking at an ASX-listed investment in Sharesies. You can place an order at any time, but it
                won’t go to market until the ASX opens.
            </p>
            {/* TODO - add AU blog post link once specific content is ready */}
            {jurisdiction === 'nz' && (
                <p>
                    For more information,{' '}
                    <a
                        target="_blank"
                        rel="noopener"
                        href="https://www.sharesies.nz/blog/what-australian-shares-are-available-on-sharesies"
                    >
                        read our blog post about Australian shares
                    </a>
                    .
                </p>
            )}
        </>
    ),
}

const PricesOnMarketRender: (typeof PricesOnMarketContent)['bodyRenderer'] = ({
    jurisdiction,
    instrument,
    usLivePricing,
    inExtendedHours,
    profileUrl,
}) => {
    const [currentSubscription, loaded] = useCurrentSubscriptionPlan()
    const owner = useProfileOwner()
    const planHasLivePricing =
        currentSubscription?.plan.includes_us_live_data || currentSubscription?.plan.has_market_depth
    const showLivePricingInformation = loaded && !planHasLivePricing && owner.owner_type !== 'ORGANISATION'

    const usLivePricingText =
        'When the market is open, the share price you see for exchange-traded investments is the live price, updated every five seconds'

    const extendedHoursUsLivePricingText =
        'During US extended hours, the share price you see for exchange-traded investments is the live price, updated every five seconds'

    const extendedHoursPricingText =
        'During US extended hours, the share price you see for exchange-traded investments is delayed by at least 20 minutes'

    const generalPricingText =
        'When the market is open, the share price you see for exchange-traded investments is delayed by at least 20 minutes'

    const pricingText =
        usLivePricing && inExtendedHours
            ? extendedHoursUsLivePricingText
            : usLivePricing
              ? usLivePricingText
              : inExtendedHours
                ? extendedHoursPricingText
                : generalPricingText

    return (
        <>
            {/* Share price timing */}
            <p>
                {pricingText}. This means the price you buy or sell at is likely to be different to the price you see.
            </p>

            {/* Extended hours disclaimer */}
            {inExtendedHours && (
                <p>
                    You might also see greater ups and downs in the price compared to regular market hours. This is due
                    to fewer people choosing to buy and sell during this time.
                </p>
            )}

            {/* Ordering */}
            <p>All orders go into a queue in the order they were placed. The price you get will vary depending on:</p>
            <ul>
                <li>where you are in the queue</li>
                <li>the value of orders ahead of you</li>
                <li>the number of {instrument ? shareLabel({instrument, isPlural: true}) : 'shares'} available</li>
                <li>whether you’ve placed a market or limit order.</li>
            </ul>
            <p>
                We’ll try to buy or sell as many {instrument ? shareLabel({instrument, isPlural: true}) : 'shares'} (or
                fractions of a {instrument ? shareLabel({instrument}) : 'share'}), at the best price we can get, in line
                with our{' '}
                <a href={profileUrl('legal/best-price')} target="_blank" rel="noopener">
                    best price policy
                </a>
                .
            </p>

            {/* Live data & market depth on top level plan */}
            {showLivePricingInformation && (
                <>
                    <p>
                        To see live prices for US and NZ investments, as well as market depth for NZX-listed
                        investments, get started on the{' '}
                        <a href={profileUrl('settings/plan')} target="_blank" rel="noopener">
                            {jurisdiction === 'nz' ? '$15' : '$20'} plan
                        </a>
                        .
                    </p>
                    <p>
                        Live prices aren’t currently available for investments listed on the Australian Securities
                        Exchange (ASX).
                    </p>
                </>
            )}

            {/* Shares link */}
            <p>
                For more info,{' '}
                <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.sharesies.nz/learn/behind-the-scenes-share-prices-for-companies-and-exchange-traded-funds-etfs"
                >
                    check out our blog
                </a>
                .
            </p>
        </>
    )
}

export const PricesOnMarketContent: LearnPageAndClassicIntroSlideContent<{
    instrument?: Instrument
    usLivePricing?: boolean
    inExtendedHours?: boolean
}> = {
    image: onMarketPricesLight,
    darkImage: onMarketPricesDark,
    title: 'Prices for exchange-traded investments',
    slug: 'prices-for-on-market-investments',
    bodyRenderer: PricesOnMarketRender,
}

export const PubliclyListedCompaniesContent: LearnPageContent = {
    image: publiclyListedCompanies,
    title: 'Publicly listed companies',
    slug: 'publicly-listed-companies',
    bodyRenderer: () => (
        <p>
            All companies have owners, these are ’shareholders’. With publicly listed companies, this ownership is
            available to be bought or sold through a registered stock exchange. For a company to have these shares
            available it has to meet regulatory requirements and publish key details including its financial statements.
        </p>
    ),
}

export const USExchangesContent: LearnPageContent = {
    image: usWelcome1,
    title: 'US exchanges',
    slug: 'us-exchanges',
    bodyRenderer: () => (
        <>
            <p>
                Access over 3,000 US companies and exchange-traded funds (ETFs) across the New York Stock Exchange
                (NYSE), Nasdaq, and Chicago Board Options Exchange (CBOE). You’ll find brands like Apple, Amazon,
                Microsoft, Facebook, Disney, and Tesla.
            </p>
            <p>
                US share markets are open on weekdays during the US daytime. See exact opening hours for the week ahead
                when you look at an investment. You can place an order at any time, but it won’t go to market until the
                US exchanges open.
            </p>
            <p>
                For more information,{' '}
                <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.sharesies.nz/learn/what-us-shares-are-available-on-sharesies"
                >
                    read our blog post about US shares
                </a>
                .
            </p>
        </>
    ),
}

export const PortfolioTypesContent: LearnPageContent = {
    image: portfolioTypes,
    title: 'Portfolio types',
    slug: 'portfolio-types',
    bodyRenderer: () => (
        <>
            <p className={styles.introText}>
                Your Portfolio type is based on the risk level of the investments you own and what percentage they make
                up of your total Portfolio.
            </p>
            <div>
                <Concertina
                    title={<div className={concertinaStyles.customTitle}>Aggressive</div>}
                    body={
                        <div className={concertinaStyles.customBody}>
                            <div className={concertinaStyles.imageContainer}>
                                <Image src={portfolioTypeAggressiveLight} darkSrc={portfolioTypeAggressiveDark} />
                            </div>
                            <p>
                                An aggressive Portfolio is made up of at least 90% medium or higher risk investments,
                                and can experience extreme ups and downs in value.
                            </p>
                            <p>
                                This Portfolio type has large growth potential when invested for the long term (13 years
                                or more) but also potential for large losses.
                            </p>
                        </div>
                    }
                    border
                />
                <Concertina
                    title={<div className={concertinaStyles.customTitle}>Growth</div>}
                    body={
                        <div className={concertinaStyles.customBody}>
                            <div className={concertinaStyles.imageContainer}>
                                <Image src={portfolioTypeGrowthLight} darkSrc={portfolioTypeGrowthDark} />
                            </div>
                            <p>
                                A growth Portfolio is made up of at least 70% medium or higher risk investments, and can
                                experience significant ups and downs in value.
                            </p>
                            <p>
                                This Portfolio type has large growth potential when invested for the long term (9 to 12
                                years) but also potential for large losses.
                            </p>
                        </div>
                    }
                    border
                />
                <Concertina
                    title={<div className={concertinaStyles.customTitle}>Balanced</div>}
                    body={
                        <div className={concertinaStyles.customBody}>
                            <div className={concertinaStyles.imageContainer}>
                                <Image src={portfolioTypeBalancedLight} darkSrc={portfolioTypeBalancedDark} />
                            </div>
                            <p>
                                A balanced Portfolio is made up of at least 50% medium or higher risk investments, and
                                can experience some ups and downs in value.
                            </p>
                            <p>
                                This Portfolio type has some growth potential when invested for the medium term (6 to 8
                                years).
                            </p>
                        </div>
                    }
                    border
                />
                <Concertina
                    title={<div className={concertinaStyles.customTitle}>Conservative</div>}
                    body={
                        <div className={concertinaStyles.customBody}>
                            <div className={concertinaStyles.imageContainer}>
                                <Image src={portfolioTypeConservativeLight} darkSrc={portfolioTypeConservativeDark} />
                            </div>
                            <p>
                                A conservative Portfolio is made up of at least 70% lower risk investments, and can
                                experience minimal ups and downs in value.
                            </p>
                            <p>This Portfolio type has limited potential for growth or losses.</p>
                        </div>
                    }
                    border
                />
                <Concertina
                    title={<div className={concertinaStyles.customTitle}>Defensive</div>}
                    body={
                        <div className={concertinaStyles.customBody}>
                            <div className={concertinaStyles.imageContainer}>
                                <Image src={portfolioTypeDefensiveLight} darkSrc={portfolioTypeDefensiveDark} />
                            </div>
                            <p>
                                A defensive Portfolio is made up of at least 90% lower risk investments, and can
                                experience minimal ups and downs in value.
                            </p>
                            <p>This Portfolio type has limited potential for growth or losses.</p>
                        </div>
                    }
                    border
                />
            </div>
        </>
    ),
}

export const InvestmentRiskLevelsContent: LearnPageContent = {
    image: investmentRiskLevels,
    title: 'Investment risk levels',
    slug: 'investment-risk-levels',
    bodyRenderer: () => (
        <>
            <p>We set investment risk levels in a few different ways.</p>
            <p>
                For companies and exchange-traded funds (ETFs), we base risk levels on how much the price has previously
                gone up and down in value (known as volatility).
            </p>
            <p>
                Managed fund risk levels are set by the fund provider, and found in the fund’s product disclosure
                statement (PDS).
            </p>
            <p className={styles.subheading}>Lower-risk investments</p>
            <RiskScaleRanges ratingRange={[1, 2]} />
            <p>
                Lower-risk investments tend to have minimal ups and downs in value—losses may be up to -2% and gains up
                to 2%. They often include cash, or government bonds.
            </p>
            <p className={styles.subheading}>Medium-risk investments</p>
            <RiskScaleRanges ratingRange={[3, 4]} />
            <p>
                Medium-risk investments tend to have some ups and downs in value—losses may be up to -10% and gains up
                to 10%. They often include bonds, or property.
            </p>
            <p className={styles.subheading}>Higher-risk investments</p>
            <RiskScaleRanges ratingRange={[5, 7]} />
            <p>
                Higher-risk investments tend to have significant ups and downs in value—losses may be over -10% and
                gains may be over 10%. They often include companies and funds.
            </p>
            <p className={styles.outroText}>
                The 1 to 7 scale is based on{' '}
                <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.esma.europa.eu/sites/default/files/library/2015/11/10_673.pdf"
                >
                    guidance from the Committee of European Securities Regulators
                </a>
                .
            </p>
        </>
    ),
}
export const VoteContent: LearnPageContent = {
    image: voting,
    title: 'Voting',
    slug: 'voting',
    bodyRenderer: () => (
        <>
            <p>
                Voting is a powerful way to have your voice heard as an investor, and help influence the future of a
                company. As an investor, you might vote on things like the company’s board of directors, executive pay
                packages, and future corporate actions.
            </p>
            <p>
                You’re eligible to vote in a shareholder meeting if you own shares in the company on a specific date.
                You can either vote in person at the shareholder meeting, or cast your vote in advance (called ‘proxy
                voting’). At Sharesies, we facilitate proxy voting.
            </p>

            <p>
                For more info about how voting works at Sharesies,{' '}
                <HelpCentreLink auArticle="6543871-voting" nzArticle="5302413-voting">
                    check out our help centre
                </HelpCentreLink>
                .
            </p>
        </>
    ),
}
