import {Button} from '@design-system/button'
import {Flag} from '@design-system/flag'
import cn from 'classnames'
import React from 'react'
import {Response} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {PageButtonGroup} from '~/global/widgets/button-page-group/PageButtonGroup'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {COMPUTERSHARE_HREF} from '~/sections/moose/constants/constants'
import {SignUpStepCommonProps} from '~/sections/moose/sections/sign-up/SignUp'
import signupStyles from '~/sections/moose/sections/sign-up/SignUp.scss'
import {useSignupEntityDetail} from '~/sections/moose/sections/sign-up/utils/sign-up-detail-hooks/signUpDetailHooks'
import IncorrectDetailsModal from '~/sections/moose/sections/sign-up/widgets/incorrect-details-modal/IncorrectDetailsModal'
import {SignUpAddress} from '~/sections/moose/types'

type Entity = Response.FonterraSignUpEntityDetail['entities'][number]
type Farms = Entity['farms']

const EntityDetails = ({progressStep, regressStep}: SignUpStepCommonProps) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const signupEntityDetail = useSignupEntityDetail(true)

    const orderAddressComponents = (address: SignUpAddress) => {
        return [
            address.address_line_one,
            address.address_line_two,
            address.address_line_three,
            address.suburb,
            address.city,
            address.postcode,
            address.country,
        ]
    }

    const renderAddressComponent = (component?: string) => {
        if (component) {
            return (
                <span key={`address-${component}`}>
                    {component} <br />
                </span>
            )
        }
    }

    const renderFarmSupplyNumbers = (farms: Farms) => {
        const label = farms.length === 1 ? 'Farm supply number' : 'Farm supply numbers'
        return (
            <>
                <h3 className={cn(signupStyles.cardSubHeading, spacing.spaceAbove16)}>{label}</h3>
                {farms.sort().map(farm => (
                    <p key={farm.farm_id} data-testid="paragraph--farm-supply-number">
                        {farm.fonterra_farm_id}
                    </p>
                ))}
            </>
        )
    }

    const renderEntityHeading = (entity: Entity) => {
        return (
            <>
                <h2 className={signupStyles.cardHeading}>{entity.name}</h2>
                {entity.onboarded_state === 'ACTIVATED' && (
                    <Flag dataTestId="flag--onboard-status" text="Activated" type="success" />
                )}
                {entity.onboarded_state === 'UPDATE_REQUIRED' && (
                    <Flag dataTestId="flag--onboard-status" text="Update Required" type="info" />
                )}
            </>
        )
    }

    const hasEntitiesNeedingUpdate =
        signupEntityDetail.entities.filter(entity => entity.onboarded_state !== 'ACTIVATED').length !== 0

    return (
        <>
            <Toolbar
                dataTestId="toolbar--fonterra-signup-entity-details"
                leftButton="back"
                onLeftButtonClick={regressStep}
                title="Confirm your business details"
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                {signupEntityDetail.entities.map((entity, i) => (
                    <div className={cn(signupStyles.card, spacing.spaceBelow24)} key={i}>
                        {renderEntityHeading(entity)}
                        <h3 className={cn(signupStyles.cardSubHeading, spacing.spaceAbove12)}>Address</h3>
                        {orderAddressComponents(entity.address).map(component => renderAddressComponent(component))}
                        {renderFarmSupplyNumbers(entity.farms)}
                    </div>
                ))}

                <AlertCard type="info" title="Incorrect address?">
                    <p>
                        This address is managed for Fonterra by Computershare. If it’s incorrect, you’ll need to{' '}
                        <a href={COMPUTERSHARE_HREF} target="_blank" rel="noreferrer">
                            update your address on Computershare
                        </a>
                        .
                    </p>
                    <p className={spacing.spaceAbove16}>
                        If you like, you can continue activating your Sharesies Account and update it later by going to{' '}
                        <strong>Settings &gt; Business details</strong>.
                    </p>
                </AlertCard>

                <AlertCard type="info" title="Missing a business?" className={spacing.spaceAbove16}>
                    <p>
                        For now, you’ll only see the businesses where Fonterra has you listed as the Primary Trading
                        Contact.
                    </p>
                </AlertCard>
                <PageButtonGroup>
                    <Button
                        label="Next"
                        type="primary"
                        dataTestId="button--fonterra-confirm-entity-details"
                        onClick={progressStep}
                        disabled={!hasEntitiesNeedingUpdate}
                    />
                    <Button
                        label="Something’s not right"
                        type="secondary"
                        dataTestId="button--fonterra-incorrect-entity-details"
                        onClick={() => {
                            setIsModalOpen(true)
                            rudderTrack('fonterra_onboarding', 'somethings_not_right_clicked', {type: 'entity_details'})
                        }}
                    />
                </PageButtonGroup>
                <IncorrectDetailsModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
            </Page>
        </>
    )
}

export default EntityDetails
