import {Button} from '@design-system/button'
import cn from 'classnames'
import {withFormik} from 'formik'
import React from 'react'
import config from '~/configForEnv'
import {spacing} from '~/global/scss/helpers'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {validate} from '~/global/widgets/form-controls'
import {Select, Checkbox} from '~/global/widgets/form-controls/formik'
import {Toast} from '~/global/widgets/toast/Toast'
import identityActions from '~/store/identity/actions'
import {Dispatch} from '~/store/types'
import styles from './Sharesight.scss'

interface SharesightPortfolioValues {
    value: string
    label: string
}

interface FormValues {
    sharesightPortfolioId: number
    isExtractingHistoricalTrades: boolean
}

interface SharesightPortfolioFormProps {
    isDependent: boolean | undefined
    portfolioId: string
    preferredName: string | undefined
    sharesightPortfolioChoices: SharesightPortfolioValues[]
    dispatch: Dispatch
}

const SharesightPortfolioForm = withFormik<SharesightPortfolioFormProps, FormValues>({
    mapPropsToErrors: props => {
        if (props.sharesightPortfolioChoices.length === 0) {
            return {sharesightPortfolioId: undefined}
        }
        return {}
    },
    mapPropsToValues: (props: SharesightPortfolioFormProps) => {
        return {
            isExtractingHistoricalTrades: true,
            sharesightPortfolioId:
                props.sharesightPortfolioChoices.length && parseInt(props.sharesightPortfolioChoices[0].value, 10),
        }
    },
    handleSubmit: async (
        {sharesightPortfolioId, isExtractingHistoricalTrades},
        {setSubmitting, props: {portfolioId, dispatch, isDependent, preferredName}},
    ) => {
        setSubmitting(true)
        const error = await dispatch(
            identityActions.AddSharesightPortfolio(portfolioId, sharesightPortfolioId, isExtractingHistoricalTrades),
        )

        if (!error) {
            Toast(`Sweet! ${isDependent ? preferredName + '’s' : 'Your'} Portfolio is connected to Sharesight.`)
        }
    },
    validate: validate.generate<FormValues>({
        sharesightPortfolioId: [validate.required()],
        isExtractingHistoricalTrades: [],
    }),
})(({handleSubmit, isSubmitting, isValid, sharesightPortfolioChoices}) => {
    return (
        <form onSubmit={handleSubmit}>
            <p className={spacing.spaceBelow24}>
                When you connect to a Sharesight Portfolio, we’ll send details of your current investments, and any
                future buy and sell transactions.
            </p>
            {sharesightPortfolioChoices.length ? (
                <>
                    <Select
                        dataTestId="select--sharesight-portfolio-id"
                        name="sharesightPortfolioId"
                        label="Choose the Sharesight Portfolio to connect with"
                        choices={sharesightPortfolioChoices}
                    />
                    <Checkbox
                        dataTestId="checkbox--send-all-past-buy-and-sell-transactions"
                        name="isExtractingHistoricalTrades"
                        label="Send all past buy and sell transactions"
                    />

                    <p className={cn(styles.description, spacing.spaceAbove12)}>
                        The more transactions you have, the longer they might take to all show in Sharesight.
                    </p>
                </>
            ) : (
                <AlertCard
                    type="warning"
                    title="Create a Sharesight Portfolio to connect to"
                    className={spacing.spaceBelow16}
                    text="Create a new one in Sharesight to start tracking your Sharesies Portfolio."
                    links={[
                        {
                            text: 'Go to Sharesight',
                            href: config.sharesightPortfolioUrl,
                        },
                    ]}
                />
            )}

            <Button
                isSubmit
                pageButton
                label="Connect"
                disabled={!isValid}
                processing={isSubmitting}
                dataTestId="button--connect-sharesight"
                width="auto"
            />
        </form>
    )
})

export default SharesightPortfolioForm
