import {ModalLink} from '@design-system/modal'
import React from 'react'
import {HelpContentBodyRenderer} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {ManagedFundProcessingTimesContent} from '~/sections/explore/pages/learn-static-content/LearnContent'

export const HowManagedFundProcessed = () => (
    <ModalLink
        dataTestId="modal-link--managed-funds-processing-times"
        label={ManagedFundProcessingTimesContent.title}
        asIcon
        modalTitle={ManagedFundProcessingTimesContent.title}
        primaryButton={{label: 'Got it'}}
    >
        <HelpContentBodyRenderer page={ManagedFundProcessingTimesContent} />
    </ModalLink>
)

export default HowManagedFundProcessed
