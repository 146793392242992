import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import {withFormik} from 'formik'
import React from 'react'
import {useNavigate} from 'react-router'
import * as api from '~/api/retail'
import {Model, Request} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {amountIncludingCardFee, amountOfCardFee} from '~/global/utils/card-fee-calculation/cardFeeCalculation'
import {UserSuitableError} from '~/global/utils/error-handling/errorHandling'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import use3DSecure from '~/global/utils/use3-d-secure/use3DSecure'
import CardForm from '~/global/widgets/credit-card-handling/card-form/CardForm'
import EstimatedWalletBalance from '~/global/widgets/estimated-wallet-balance/EstimatedWalletBalance'
import {validate, ErrorBox, SwitchField as RawSwitchField} from '~/global/widgets/form-controls'
import {DecimalInput, SwitchField} from '~/global/widgets/form-controls/formik'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue, FeeValue} from '~/global/widgets/number-elements/NumberElements'
import {OrderConfirmation, OrderRow} from '~/global/widgets/order-confirmation/OrderConfirmation'
import Page from '~/global/widgets/page/Page'
import SavedCardForm from '~/global/widgets/saved-card-form/SavedCardForm'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {reauthenticateReturn} from '~/global/wrappers/global-wrapper-widgets/reauthenticate/Reauthenticate'
import {
    DuplicateTopupModal,
    DuplicateTopupModalHandle,
} from '~/sections/OLD_wallet/sections/top-up/widgets/duplicate-top-up-modal/DuplicateTopupModal'
import store from '~/store'
import accountingActions from '~/store/accounting/actions'
import {State as AccountingState} from '~/store/accounting/types'
import {connect} from '~/store/connect'
import identityActions from '~/store/identity/actions'
import {StagedBuyOrder} from '~/store/order/types'

/**
 * Get the amount of the 'We got it—card processing fee' discount
 * On the customer's first deposit, the card fee is free on us *up to* $100 (either NZD or AUD)
 */
const amountOfDiscount = (
    amount: string,
    elegibleForFreeFee: boolean,
    homeCurrency: Model.User['home_currency'],
): number => {
    if (elegibleForFreeFee) {
        // *up to* $100
        const maxDiscountAmount = amountOfCardFee('100', homeCurrency)

        return Math.min(Number(amountOfCardFee(amount, homeCurrency)), Number(maxDiscountAmount))
    }

    return 0
}

const amountIncludingCardFeeAndDiscount = (
    amount: string,
    homeCurrency: Model.User['home_currency'],
    elegibleForFreeFee: boolean,
): string => {
    return elegibleForFreeFee
        ? (
              Number(amountIncludingCardFee(amount, homeCurrency)) -
              amountOfDiscount(amount, elegibleForFreeFee, homeCurrency)
          ).toFixed(2)
        : amountIncludingCardFee(amount, homeCurrency)
}

type TopUpRoute = 'accounting/card-top-up/payment-intent-saved-card' | 'accounting/card-top-up/payment-intent-new-card'

type TopUpParams = Request.AccountingCardTopUpNewCard | Request.AccountingCardTopUpSavedCard

type CardCurrency = Request.AccountingCardTopUpNewCard['currency']

interface AmountFormValues {
    amount: string
}

interface AmountFormProps {
    onSubmit(amount: string): void
    accountRestricted: boolean
}

const AmountForm = withFormik<AmountFormProps, AmountFormValues>({
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        amount: undefined,
    }),
    handleSubmit: async ({amount}, {props: {onSubmit}}) => {
        onSubmit(amount)
    },
    validate: validate.generate<AmountFormValues>({
        amount: [
            validate.required(),
            validate.money(),
            validate.minimum(10, 'The minimum top up amount is $10'),
            validate.maximum(1000, 'The maximum top up amount is $1,000'),
        ],
    }),
})(({handleSubmit, isSubmitting, isValid, accountRestricted}) => (
    <form onSubmit={handleSubmit}>
        <DecimalInput
            dataTestId="decimal-input--amount-to-top-up"
            name="amount"
            label="Amount to top up"
            disabled={isSubmitting || accountRestricted}
        />
        <Button
            dataTestId="button--next"
            label="Next"
            disabled={!isValid || accountRestricted}
            processing={isSubmitting}
            isSubmit
            pageButton
        />
    </form>
))

interface HelpLinkModalProps {
    isDependent: boolean
    preferredName: string
}

const HelpLinkModal = ({isDependent, preferredName}: HelpLinkModalProps) => (
    <ModalLink
        dataTestId="modal-link--card-processing-fee"
        label="Card processing fee"
        asIcon
        modalTitle="Card processing fee"
        primaryButton={{label: 'Ok'}}
        helpIconSize={16}
    >
        <p>When you use a debit or credit card it comes with a card processing fee.</p>
        <p>
            Bank deposits don’t have this processing fee—but may take a little longer for the money to show in{' '}
            {isDependent ? `${preferredName}'s` : 'your'} Wallet.
        </p>
    </ModalLink>
)

export const CardTopUp: React.FC<CardTopUpProps> = ({
    preferredName,
    jurisdiction,
    homeCurrency,
    isDependent,
    savedCard,
    accountRestricted,
    actingAsId,
    cardState,
    elegibleForFreeFee,
    initCards,
}) => {
    const notificationContext = React.useContext(NotificationContext)
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const duplicatePaymentRef = React.useRef<DuplicateTopupModalHandle>(null)
    const [useSavedCard, setUseSavedCard] = React.useState(true)
    const [amount, setAmount] = React.useState<string | undefined>(undefined)

    const {processCardRequiresAction} = use3DSecure(jurisdiction)

    React.useEffect(() => {
        if (cardState === 'loading') {
            initCards()
        }
    }, [])

    const trackPaymentConfirmation = () => {
        rudderTrack('topup', 'credit_card_confirmed')
    }

    async function doRequest(endpoint: TopUpRoute, requestParams: TopUpParams): Promise<void> {
        const response = await api.post(endpoint, requestParams)

        switch (response.type) {
            case 'account_restricted':
                throw new UserSuitableError(response.error)
            case 'authentication_update_required':
                return reauthenticateReturn(
                    () => doRequest(endpoint, requestParams),
                    () => {
                        throw new UserSuitableError('Please try again')
                    },
                    () => {
                        throw new UserSuitableError('You need to supply your password to continue')
                    },
                )
            case 'identity_authenticated':
                Toast(`Choice! ${isDependent ? `${preferredName}’s` : 'You’re'} all topped up.`)
                store.dispatch(identityActions.handleIdentityResponse(response))
                store.dispatch(accountingActions.FetchNewTransactions())
                store.dispatch(accountingActions.RefreshCards())
                store.dispatch(accountingActions.FetchCurrentTopupGoal())

                navigate(profileUrl('wallet'))

                return
            case 'error':
                if (response.code === 'stripe_update_card_error') {
                    Toast(
                        `Choice! ${
                            isDependent ? `${preferredName}’s` : 'You’re'
                        } all topped up, but your card didn’t save so you’ll need to update it manually.`,
                    )
                    store.dispatch(accountingActions.FetchNewTransactions())
                    store.dispatch(accountingActions.RefreshCards())
                    store.dispatch(accountingActions.FetchCurrentTopupGoal())
                    navigate(profileUrl('wallet'))
                    return
                } else if (response.code === 'duplicate_card_top_up') {
                    try {
                        await duplicatePaymentRef.current!.confirmDuplicatePayment()
                        return doRequest(endpoint, {
                            ...requestParams,
                            confirmed_card_top_up: true,
                        })
                    } catch {
                        setAmount(undefined)
                        navigate(profileUrl('wallet/card-top-up'))
                    }
                }
                throw new UserSuitableError(response.message)
            case 'card_topup_action_required':
                const paymentIntentId = await processCardRequiresAction(response.client_secret)
                return doRequest(endpoint, {
                    ...requestParams,
                    payment_intent_id: paymentIntentId,
                })
            case 'internal_server_error':
                notificationContext.showModalError({
                    message:
                        'An error occurred while topping up your account. Your card may have been charged. Please feel free to contact our team at help@sharesies.co.nz for more information.',
                })
                return
            default:
                assertNever(response)
        }
    }

    const renderManualCardPayment = (amount: string) => {
        const onSubmit = async (paymentMethodId: string, additionalFields?: {save_card: boolean}): Promise<void> => {
            const requestParams = {
                top_up_amount: amount,
                charge_amount: amountIncludingCardFeeAndDiscount(amount, homeCurrency, elegibleForFreeFee),
                acting_as_id: actingAsId,
                payment_method_id: paymentMethodId,
                currency: homeCurrency,
                save_card: additionalFields!.save_card,
            }
            trackPaymentConfirmation()

            return doRequest('accounting/card-top-up/payment-intent-new-card', requestParams)
        }

        return (
            <CardForm
                jurisdiction={jurisdiction}
                submitLabel="Top up Wallet"
                childrenDefaultValues={{save_card: !!savedCard}}
                onSubmit={onSubmit}
            >
                <SwitchField
                    additionalClassName={cn(spacing.spaceAbove16, spacing.spaceBelow12)}
                    label={savedCard ? 'Replace saved card' : 'Save card'}
                    name="save_card"
                    dataTestId="switch--replace-saved-card"
                />
            </CardForm>
        )
    }

    const renderSavedCardPayment = (amount: string) => {
        const onSubmit = async (): Promise<void> => {
            const requestParams = {
                top_up_amount: amount,
                currency: homeCurrency === 'aud' ? homeCurrency : ('nzd' as CardCurrency),
                charge_amount: amountIncludingCardFeeAndDiscount(amount, homeCurrency, elegibleForFreeFee),
                acting_as_id: actingAsId,
            }
            trackPaymentConfirmation()

            return doRequest('accounting/card-top-up/payment-intent-saved-card', requestParams)
        }
        return (
            <div>
                <SavedCardForm submitLabel="Top up Wallet" card={savedCard!} onSubmit={onSubmit} />
            </div>
        )
    }

    const renderPayment = (amount: string) => {
        const discount = amountOfDiscount(amount, elegibleForFreeFee, homeCurrency)

        const receiptItems: OrderRow[] = [
            {description: 'Amount to top up', value: <DollarValue value={amount} currency={homeCurrency} />},
            {
                description: `Card processing fee ${jurisdiction === 'au' ? ' incl. GST' : ''}`,
                value: <FeeValue value={amountOfCardFee(amount, homeCurrency)} currency={homeCurrency} />,
            },
        ]
        if (discount !== 0) {
            receiptItems.push({
                description: 'We got it—card processing fee',
                value: <DollarValue value={String(-discount)} currency={homeCurrency} />,
            })
        }

        return (
            <div>
                <h1 className={spacing.spaceBelow24}>Confirm your card top-up</h1>
                <OrderConfirmation
                    items={receiptItems}
                    total={{
                        description: 'Total',
                        value: (
                            <DollarValue
                                value={amountIncludingCardFeeAndDiscount(amount, homeCurrency, elegibleForFreeFee)}
                                currency={homeCurrency}
                            />
                        ),
                    }}
                />
                {savedCard && (
                    <div className={cn(spacing.spaceBelow32)}>
                        <RawSwitchField
                            dataTestId="switch--use-saved-card"
                            additionalClassName={cn(spacing.spaceAbove12, spacing.spaceBelow12)}
                            name="Use saved card"
                            label="Use saved card"
                            isTouched
                            value={useSavedCard}
                            onChange={() => setUseSavedCard(!useSavedCard)}
                        />
                    </div>
                )}
                {useSavedCard && savedCard ? renderSavedCardPayment(amount) : renderManualCardPayment(amount)}
            </div>
        )
    }

    if (cardState === 'loading') {
        return <Loading isPineapple />
    }

    if (cardState === 'error') {
        return (
            <ErrorBox
                message={
                    isDependent
                        ? `An error occurred loading ${preferredName}’s details`
                        : `An error occurred loading your details`
                }
            />
        )
    }

    return (
        <>
            {amount && <DuplicateTopupModal ref={duplicatePaymentRef} amount={amount} />}
            <Toolbar
                dataTestId="toolbar--card-top-up"
                leftButton="back"
                onLeftButtonClick={() => {
                    navigate(-1)
                }}
            />
            <Page>
                {amount ? (
                    renderPayment(amount)
                ) : (
                    <div>
                        <h1 className={spacing.spaceBelow12}>
                            Wallet balance <EstimatedWalletBalance />
                        </h1>
                        <p className={spacing.spaceBelow32}>
                            To give {isDependent ? `${preferredName}’s` : 'your'} Wallet an instant boost, you can top
                            up with a card—this has an additional card processing fee. You can only top up with{' '}
                            {homeCurrency.toUpperCase()}, then you can convert to other currencies.
                            <HelpLinkModal isDependent={isDependent} preferredName={preferredName} />
                        </p>
                        {elegibleForFreeFee && homeCurrency !== 'aud' && (
                            <p className={spacing.spaceBelow32}>
                                We’ll cover this fee for {isDependent ? `${preferredName}’s` : 'your'} first top up (of
                                up to $100).
                            </p>
                        )}
                        <AmountForm onSubmit={setAmount} accountRestricted={accountRestricted} />
                    </div>
                )}
            </Page>
        </>
    )
}

interface StoreProps {
    isNegative: boolean
    elegibleForFreeFee: boolean
    savedCard?: Model.StripeSource
    cardState: AccountingState['cardsLoadingState']
    isDependent: boolean
    preferredName: string
    homeCurrency: 'nzd' | 'aud'
    jurisdiction: Model.User['jurisdiction']
    accountRestricted: boolean
    actingAsId: string
    stagedBuyOrder?: StagedBuyOrder
}

interface DispatchProps {
    initCards(): void
}

type CardTopUpProps = StoreProps & DispatchProps

export default connect<StoreProps, DispatchProps>(
    ({identity, accounting, order}) => ({
        actingAsId: identity.user!.id,
        isNegative: Number(identity.user!.wallet_balances.nzd) < 0,
        elegibleForFreeFee: !identity.user!.checks.made_deposit,
        savedCard: accounting.cardsLoadingState === 'ready' ? accounting.cards[0] : undefined,
        cardState: accounting.cardsLoadingState,
        isDependent: identity.user!.is_dependent,
        preferredName: identity.user!.preferred_name,
        homeCurrency: identity.user!.home_currency as 'nzd' | 'aud',
        jurisdiction: identity.user!.jurisdiction,
        accountRestricted: identity.user!.account_restricted,
        stagedBuyOrder: order.stagedBuyOrder,
    }),
    dispatch => ({
        initCards: () => dispatch(accountingActions.RefreshCards()),
    }),
)(CardTopUp)
