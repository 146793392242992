import {Document} from '@contentful/rich-text-types'
import React from 'react'
import {RichText} from '~/sections/explore/sections/learn-articles/widgets/rich-text/RichText'
import AUTerms from '~/sections/user/sections/terms-and-conditions/assets/contentful-entries/au-gift-terms-and-conditions.json'
import NZTerms from '~/sections/user/sections/terms-and-conditions/assets/contentful-entries/gift-terms-and-conditions.json'
import {legalDoc} from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/LegalDocuments.scss'
import {useAppSelector} from '~/store/hooks'

export default () => {
    const jurisdiction = useAppSelector(state => state.identity.user?.jurisdiction)

    return (
        <div className={legalDoc}>
            {jurisdiction === 'au' ? (
                <RichText richTextDocument={AUTerms.fields.content as Document} />
            ) : (
                <RichText richTextDocument={NZTerms.fields.content as Document} />
            )}
        </div>
    )
}
