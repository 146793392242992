import React from 'react'
import {ListingResponseDto} from '~/api/distill'
import {DistillScope} from '~/api/query/distill'
import {spacing} from '~/global/scss/helpers'
import {Period} from '~/global/utils/time-period/timePeriod'
import {SharePriceValue} from '~/global/widgets/number-elements/NumberElements'
import {useDistillInstrument} from '~/sections/kiwisaver/data/distill'
import {
    useRakaiaGetInstrumentInvestingHistory,
    useRakaiaGetLatestInstrumentReturns,
} from '~/sections/kiwisaver/data/rakaia'
import styles from '~/sections/kiwisaver/sections/view-fund/ViewFund.scss'
import {useActiveOrOffboardingKSCustomer} from '~/sections/kiwisaver/state'
import {InstrumentDetailsTab} from '~/store/instrument/types'
import InvestingActivity from './investing-activity/InvestingActivity'
import InvestingHistory from './investing-history/InvestingHistory'
import InvestingHistorySummary from './investing-history/InvestingHistorySummary'
import InvestmentSummary from './investment-summary/index'

const YourInvestmentTab = ({
    tab,
    instrument,
    currency,
    period,
    setPeriod,
}: {
    tab: InstrumentDetailsTab
    instrument: ListingResponseDto
    currency: string
    period: Period
    setPeriod: (period: Period) => void
}) => {
    const customer = useActiveOrOffboardingKSCustomer()
    const fmsInstrument = useDistillInstrument({
        instrumentId: instrument.fmsFundId!,
        scope: DistillScope.KIWISAVER_ALL_FUNDS,
    })
    const currentHistory = useRakaiaGetInstrumentInvestingHistory({
        portfolioUuid: customer.portfolio_id,
        instrumentUuid: fmsInstrument.id,
    })
    const lastestReturns = useRakaiaGetLatestInstrumentReturns(customer.portfolio_id)
    const instrumentLatestReturns = lastestReturns.instrument_returns[fmsInstrument.id]

    return (
        <section
            id="your-investment"
            style={{display: tab === 'Your investment' ? 'block' : 'none'}}
            role="tabpanel"
            aria-labelledby="your-investment-tab"
        >
            <p>
                You own units in a fund managed by Sharesies. The unit price for this fund is{' '}
                <b>
                    <SharePriceValue value={fmsInstrument.marketPrice} currency={currency} />
                </b>
            </p>
            <InvestmentSummary
                instrument={instrument}
                instrumentReturns={instrumentLatestReturns}
                currency={currency}
            />
            <h2 className={styles.sectionHeader}>Investing history</h2>
            <InvestingHistory period={period} currentHistory={currentHistory} setPeriod={setPeriod} />
            <InvestingHistorySummary period={period} purchasePriceInfo={currentHistory?.purchase_price_info} />

            <div className={styles.investingActivity}>
                <h2 className={spacing.spaceBelow16}>Investing activity</h2>
                <InvestingActivity instrumentId={instrument.fmsFundId!} />
            </div>
        </section>
    )
}

export default YourInvestmentTab
