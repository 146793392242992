import {Button} from '@design-system/button'
import cn from 'classnames'
import {withFormik} from 'formik'
import React from 'react'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {ErrorBox, validate} from '~/global/widgets/form-controls'
import {TFNNumber} from '~/global/widgets/form-controls/formik'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'

class FormValues {
    tfn_number?: string
}

interface FormOwnProps {
    updateAUFinancialDetails: DispatchProps['updateAUFinancialDetails']
    tfnNumber?: string
    isDependent: boolean
    preferredName: string
    onSuccess(): void
}

const AUTaxDetailsForm = withFormik<FormOwnProps, FormValues>({
    mapPropsToValues: () => ({
        tfn_number: '',
    }),
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        tfn_number: undefined,
    }),
    handleSubmit: async ({tfn_number}, {setSubmitting, setStatus, props: {updateAUFinancialDetails, onSuccess}}) => {
        try {
            const error = await updateAUFinancialDetails(tfn_number!)
            if (error) {
                setStatus(error)
                return
            }
            onSuccess()
        } catch (e) {
            setStatus(unknownErrorMessage)
            throw e
        } finally {
            setSubmitting(false)
        }
    },
    validate: validate.generate<FormValues>({
        tfn_number: [validate.required(), validate.tfnNumber()],
    }),
})(({handleSubmit, isValid, values, isSubmitting, tfnNumber}) => {
    return (
        <form onSubmit={handleSubmit}>
            <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow12)}>We need some tax info</h2>
            <TFNNumber dataTestId="text-input--tfn-number" name="tfn_number" label="Tax File Number (TFN)" />

            <ErrorBox message={status} />
            <Button
                isSubmit
                dataTestId="button--save"
                label="Save changes"
                disabled={!isValid || values.tfn_number === tfnNumber}
                processing={isSubmitting}
                additionalClassName={cn(spacing.spaceBelow32, spacing.spaceAbove32)}
            />
        </form>
    )
})

interface StoreProps {
    tfnNumber?: string
}

interface DispatchProps {
    updateAUFinancialDetails(tfnNumber: string): Promise<null | string>
}

interface OwnProps {
    isDependent: boolean
    preferredName: string
    onSuccess(): void
}

const ConnectedAUTaxDetails = connect<StoreProps, DispatchProps, OwnProps>(
    (state, {isDependent, preferredName, onSuccess}) => {
        return {
            isDependent,
            preferredName,
            tfnNumber: state.identity.user!.tfn_number,
            onSuccess,
        }
    },
    dispatch => ({
        updateAUFinancialDetails: tfnNumber => dispatch(actions.UpdateAUFinancialDetails(tfnNumber)),
    }),
)(AUTaxDetailsForm)

export default ConnectedAUTaxDetails
