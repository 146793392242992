import {Modal} from '@design-system/modal'
import React from 'react'
import {dateFormatDayAndMonthWithTime} from '~/global/utils/format-date/formatDate'
import {pluralisedVote} from '~/global/widgets/corporate-action-description/corporateActionsV2'
import actions from '~/store/accounting/actions'
import {VoluntaryCorporateActionV2} from '~/store/accounting/types'
import {useAppDispatch} from '~/store/hooks'
import {setModalError} from '~/store/notification'
import orderActions from '~/store/order/actions'

interface Props {
    existingApplication: VoluntaryCorporateActionV2
    onClose: (didCancel: boolean) => void
}

const MaxOneApplicationModal: React.FunctionComponent<Props> = ({existingApplication, onClose}) => {
    const dispatch = useAppDispatch()

    const [isOpen, setIsOpen] = React.useState(true)

    const cancelOrder = async () => {
        const error = await dispatch(actions.CancelApplication(existingApplication))
        if (error) {
            return error
        }
        await dispatch(orderActions.LoadInstrumentApplications(existingApplication.fund_id))
    }

    const onCancel = async () => {
        try {
            const error = await cancelOrder()
            if (error) {
                setModalError(error.message, 'We’re sorry, there’s been an error')
            } else {
                onClose(true)
            }
        } catch (e) {
            setModalError(
                'Something caused it, maybe gremlins? Please try again or let us know if it’s still not working.',
                'We’re sorry, there’s been an error',
            )
            onClose(false)
        }
    }

    const onDismiss = () => {
        setIsOpen(false)
        onClose(false)
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                setIsOpen={onDismiss}
                title={
                    existingApplication.action_type === 'VOTE'
                        ? 'You’ve already cast your votes'
                        : 'You have an application in progress'
                }
                dataTestId="modal--max-one-application"
                primaryButton={{
                    label:
                        existingApplication.action_type === 'VOTE'
                            ? 'Withdraw my ' + pluralisedVote(existingApplication.application)
                            : 'Cancel application',
                    onClick: () => onCancel(),
                }}
                secondaryButton={{
                    label:
                        existingApplication.action_type === 'VOTE'
                            ? 'Keep my ' + pluralisedVote(existingApplication.application)
                            : 'Keep it',
                    onClick: () => onClose(false),
                }}
            >
                <p>
                    {existingApplication.action_type === 'VOTE'
                        ? `If you want to change any of your votes, you’ll need to withdraw and recast them before voting closes${
                              existingApplication.application.applications_close_at
                                  ? ` at ${existingApplication.application.applications_close_at.toFormat(
                                        dateFormatDayAndMonthWithTime,
                                    )}`
                                  : ''
                          }.`
                        : 'If you want to change your existing application you’ll need to cancel it and start again.'}
                </p>
            </Modal>
        </>
    )
}

export default MaxOneApplicationModal
