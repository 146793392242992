import {DateTime} from 'luxon'
import {useRetailGet} from '~/api/query/retail'
import {Response} from '~/api/retail/types'
import {DIVIDEND_LIKE_TYPES} from '~/global/widgets/corporate-action-description/corporateActionsV2'
import {BasicInstrument} from '~/store/instrument/types'

export type AnnouncedCorporateAction = Response.AnnouncedCorporateActions['corporate_actions'][number]

/**
 * Returns all corporate actions for a given instrument, regardless of whether the investor was eligible
 */
function useAnnouncedCorporateActions(instrument: BasicInstrument): AnnouncedCorporateAction[] {
    const result = useRetailGet({
        path: 'corporate-actions/fund/:fund_id/announced',
        pathParams: {
            fund_id: instrument.id,
        },
    })

    return result.data.corporate_actions
}

/**
 * Return the next dividend this instrument is paying
 *
 * TODO handle multiple future dividends better
 */
export function useNextDividend(instrument: BasicInstrument): AnnouncedCorporateAction | undefined {
    const corporateActions = useAnnouncedCorporateActions(instrument)
    const futureDividends = corporateActions
        .filter(ca => DIVIDEND_LIKE_TYPES.includes(ca.action_type))
        .filter(d => DateTime.fromISO(d.settlement_date) > DateTime.now())
        .reverse()
    return futureDividends[0]
}

export function useHistoricDividends(instrument: BasicInstrument): AnnouncedCorporateAction[] {
    const corporateActions = useAnnouncedCorporateActions(instrument)
    return corporateActions
        .filter(ca => DIVIDEND_LIKE_TYPES.includes(ca.action_type))
        .filter(d => DateTime.fromISO(d.settlement_date) <= DateTime.now())
        .reverse()
}

export default useAnnouncedCorporateActions
