import React from 'react'
import {Period} from '~/global/utils/time-period/timePeriod'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {useAppSelector} from '~/store/hooks'
import {PortfolioHistoryItem} from '~/store/portfolio/types'
import styles from './PortfolioChartBalance.scss'

interface PortfolioChartBalanceProps {
    values: PortfolioHistoryItem | undefined
    period: Period
    onPeriodChange: (period: Period) => void
}

const PortfolioChartBalance: React.FC<PortfolioChartBalanceProps> = ({values}) => {
    const currency = useAppSelector(s => s.identity.user!.home_currency)
    const portfolioValue = values ? values.portfolio_value : 0

    return (
        <div className={styles.numbersPortfolio}>
            <h2>Estimated Portfolio value</h2>
            <div>
                <span className={styles.numbersPortfolioValue}>
                    <DollarValue value={portfolioValue} decimalPlaces={portfolioValue > 1e6 ? 0 : 2} />
                </span>{' '}
                <span className={styles.numbersPortfolioCurrency}>{currency.toUpperCase()}</span>
            </div>
        </div>
    )
}
export default PortfolioChartBalance
