import {formatNumberWithThousandsSeparator} from '@design-system/format-number-with-thousands-separator'
import {isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {Instrument} from '~/store/instrument/types'

/**
 * We now have a buy/sell order cap of 5,000 for US shares that are low priced (less than $1).
 * This is due to our pricing structure with Drivewealth.
 * This isn't a hard rule, rather it's a cunning cost saving measure on our part.
 * For this reason, it's ok if prices flutuate around the $1 mark, as this doesn't need
 * to be perfect.
 *
 * @param {Instrument} instrument - instrument
 * @param {string | undefined} numShares - number of shares requested used for limit buy/sells, market & trigger sells (this can be explicitly undefined)
 * @param {string | undefined} orderValue - dollar value of order for market & trigger buys (this can be explicitly undefined)
 * @param {string | undefined} limitPricePerShare - used for limit buys/sells (this can be explicitly undefined)
 * @param {string | undefined} triggerPricePerShare - used fortrigger buys/sells (this can be explicitly undefined)
 * @returns {object} an object with a boolean representing if the limit has been exceeded and an error message to be displayed.
 */
export const exceedsLowPricedSecurityCap = (
    instrument: Instrument,
    numShares: string | undefined,
    orderValue: string | undefined,
    limitPricePerShare: string | undefined,
    triggerPricePerShare: string | undefined,
): {exceedsLimit: boolean; msg: string | undefined} => {
    const minPrice = 1 // We only care about instruments that have a price of less than $1
    const shareCap = 2000 // The max number of low priced shares that can be purchased/sold
    const msg = `This exceeds the limit of ${formatNumberWithThousandsSeparator(
        String(shareCap),
    )} shares for US investments that have a share price of less than $${minPrice}`

    const marketPrice = Number(instrument.marketPrice)
    const shares = Number(numShares)
    const value = Number(orderValue)
    const limitPrice = Number(limitPricePerShare)
    const triggerPrice = Number(triggerPricePerShare)
    const isLowPriceSecurity = marketPrice < minPrice

    if (isOnMainUsExchange(instrument) && isLowPriceSecurity) {
        if (shares > shareCap) {
            return {exceedsLimit: true, msg}
        } else if (triggerPrice > 0 && value / triggerPrice > shareCap) {
            return {exceedsLimit: true, msg}
        } else if (limitPrice > 0 && value / limitPrice > shareCap) {
            return {exceedsLimit: true, msg}
        } else if (!triggerPrice && value / marketPrice > shareCap) {
            return {exceedsLimit: true, msg}
        }
    }

    return {exceedsLimit: false, msg: undefined}
}
