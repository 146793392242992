import {Button} from '@design-system/button'
import cn from 'classnames'
import {useAtom} from 'jotai'
import React from 'react'
import {useRetailPost} from '~/api/query/retail'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {ErrorBox} from '~/global/widgets/form-controls'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useSignUpInitialDetail} from '~/sections/moose/data/retail'
import {SignUpStepCommonProps} from '~/sections/moose/sections/sign-up/SignUp'
import signupStyles from '~/sections/moose/sections/sign-up/SignUp.scss'
import ConfirmTokenForm from '~/sections/moose/sections/sign-up/sections/mobile-auth/widgets/confirm-token-form/ConfirmTokenForm'
import {stagedSignUpAtom} from '~/sections/moose/sections/sign-up/state'
import IncorrectDetailsModal from '~/sections/moose/sections/sign-up/widgets/incorrect-details-modal/IncorrectDetailsModal'

const MobileAuthRequired = ({progressStep, regressStep, signupId}: SignUpStepCommonProps) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const [mobileConfirmed, setMobileConfirmed] = React.useState(false)
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [error, setError] = React.useState('')

    const [stagedSignUp, updateStagedSignUp] = useAtom(stagedSignUpAtom)
    const signupInitialDetail = useSignUpInitialDetail(signupId)
    const title = mobileConfirmed
        ? 'We’ve sent you a code'
        : 'For your security, we’ll send a code to your mobile phone'

    const postSendMFAToken = useRetailPost({path: 'fonterra/signup/send-mfa-token'})

    return (
        <>
            <Toolbar
                dataTestId="toolbar--fonterra-signup-mobile-auth"
                leftButton="back"
                onLeftButtonClick={() => (mobileConfirmed ? setMobileConfirmed(false) : regressStep())}
                title={title}
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                {mobileConfirmed ? (
                    <ConfirmTokenForm
                        signupId={signupId}
                        maskedPhone={signupInitialDetail.masked_phone}
                        error={error}
                        setError={setError}
                        progressStep={progressStep}
                    />
                ) : (
                    <>
                        <p>We’ve received this number from Fonterra.</p>
                        <div className={cn(signupStyles.card, spacing.spaceAbove16)}>
                            <p>
                                <strong>Mobile phone</strong>
                            </p>
                            <p className={spacing.spaceAbove8}>{signupInitialDetail.masked_phone}</p>
                        </div>
                        <ButtonAsLink
                            className={spacing.spaceAbove16}
                            inline
                            onClick={() => {
                                setIsModalOpen(true)
                                rudderTrack('fonterra_onboarding', 'somethings_not_right_clicked', {
                                    type: 'confirm_phone',
                                })
                            }}
                        >
                            This isn’t my number
                        </ButtonAsLink>
                        <ErrorBox message={error} />
                        <Button
                            label="Send code"
                            type="primary"
                            dataTestId="button--fonterra-send-code"
                            processing={isSubmitting}
                            onClick={() => {
                                setIsSubmitting(true)
                                postSendMFAToken.mutate(
                                    {signup_id: signupId},
                                    {
                                        onSuccess: response => {
                                            switch (response.type) {
                                                case 'fonterra_signup_mfa_sent':
                                                    updateStagedSignUp({
                                                        ...stagedSignUp,
                                                        signupAttemptToken: response.signup_attempt_token,
                                                    })
                                                    setMobileConfirmed(true)
                                                    break
                                                case 'fonterra_signup_mfa_issue':
                                                    setError(response.message)
                                                    break
                                            }
                                        },
                                        onSettled: () => {
                                            setIsSubmitting(false)
                                        },
                                    },
                                )
                            }}
                            pageButton
                            isSubmit
                        />
                        <IncorrectDetailsModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} isIncorrectPhone />
                    </>
                )}
            </Page>
        </>
    )
}

export default MobileAuthRequired
