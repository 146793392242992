import {Model} from '~/api/retail/types'
import {
    calculateCurrencyExchangeFromSourceAmount,
    roundDownToTwoDecimalPlaces,
    parseRawBalance,
} from '~/global/utils/calculate-currency-exchange/calculateCurrencyExchange'
import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {findExchangeRate} from '~/global/utils/find-exchange-rate/findExchangeRate'
import {State} from '~/store/accounting/types'

const calculateAmount = (
    sourceCurrency: Currency,
    targetCurrency: Currency,
    balance: string,
    exchangeRates: State['exchangeRates'],
    exchangeFeeRate: number | null,
) => {
    const exchangeRate = findExchangeRate({sourceCurrency, targetCurrency, exchangeRates})

    return exchangeRate && exchangeFeeRate !== null
        ? Number(
              roundDownToTwoDecimalPlaces(
                  calculateCurrencyExchangeFromSourceAmount(
                      roundDownToTwoDecimalPlaces(parseRawBalance(balance)),
                      exchangeRate.rate,
                      exchangeFeeRate,
                  ).targetAmount,
              ),
          )
        : 0
}

/*
When we convert non-display currency in display currency, we round down to 2 dp
first, then convert to the display currency.

In this way, we can keep consistency with
1) Backend calculation
2) Exchange money flow - we don't allow users to exchange less than $0.01 USD or $0.01 NZD
*/
export const calculateWalletBalance = (
    walletBalances: Model.User['wallet_balances'],
    exchangeRates: State['exchangeRates'],
    usEquitiesEnabled: boolean,
    displayCurrency: Currency,
    exchangeFeeRate: number | null,
): string => {
    switch (displayCurrency) {
        case 'usd': {
            const usdAmount = usEquitiesEnabled
                ? Number(roundDownToTwoDecimalPlaces(parseRawBalance(walletBalances.usd)))
                : 0
            const nzdAmount = calculateAmount('nzd', 'usd', walletBalances.nzd, exchangeRates, exchangeFeeRate)
            const audAmount = calculateAmount('aud', 'usd', walletBalances.aud, exchangeRates, exchangeFeeRate)
            return (usdAmount + nzdAmount + audAmount).toString()
        }
        case 'aud': {
            const audAmount = Number(roundDownToTwoDecimalPlaces(parseRawBalance(walletBalances.aud)))
            const nzdAmount = calculateAmount('nzd', 'aud', walletBalances.nzd, exchangeRates, exchangeFeeRate)
            const usdAmount = usEquitiesEnabled
                ? calculateAmount('usd', 'aud', walletBalances.usd, exchangeRates, exchangeFeeRate)
                : 0
            return (usdAmount + nzdAmount + audAmount).toString()
        }
        case 'nzd':
        default:
            const nzdAmount = Number(roundDownToTwoDecimalPlaces(parseRawBalance(walletBalances.nzd)))
            const usdAmount = usEquitiesEnabled
                ? calculateAmount('usd', 'nzd', walletBalances.usd, exchangeRates, exchangeFeeRate)
                : 0
            const audAmount = calculateAmount('aud', 'nzd', walletBalances.aud, exchangeRates, exchangeFeeRate)
            return (usdAmount + nzdAmount + audAmount).toString()
    }
}

export const isAnyWalletBalanceNegative = (walletBalances: Model.User['wallet_balances']): boolean => {
    let hasNegative = false
    for (const walletBalance of Object.entries(walletBalances)) {
        // walletBalance is in the format [key, value]
        if (Number(walletBalance[1]) < 0) {
            hasNegative = true
        }
    }
    return hasNegative
}

export const areAllWalletBalancesZeroOrNegative = (walletBalance: Model.User['wallet_balances']): boolean => {
    let allWalletBalancesAreZeroOrNegative = true
    Object.keys(walletBalance).forEach(currency => {
        if (parseFloat(walletBalance[currency]) > 0) {
            allWalletBalancesAreZeroOrNegative = false
        }
    })
    return allWalletBalancesAreZeroOrNegative
}

export default calculateWalletBalance
