import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal/ModalLink'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {Navigate} from 'react-router-dom'
import {DistillScope} from '~/api/query/distill'
import confirmStyles from '~/global/scss/reused-styles/confirm.scss'
import {
    amountOfManagedFundFee,
    valueOfSellOrder,
    useCorporateFeeForStagedSellOrder,
} from '~/global/utils/instrument-transaction-fee/instrumentTransactionFee'
import {isOnNZX, isOnASX, isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import useAssetManager from '~/global/utils/use-asset-manager/useAssetManager'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {ExchangeHours} from '~/global/widgets/exchange-hours/ExchangeHours'
import {ADR} from '~/global/widgets/help-modals/ADR'
import BuyAndSellFeesListedInstruments from '~/global/widgets/help-modals/BuyAndSellFeesListedInstruments'
import BuyAndSellFeesManagedFund from '~/global/widgets/help-modals/BuyAndSellFeesManagedFund'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {DollarValue, SharePriceValue, FeeValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import {OrderConfirmation, CoverageText, OrderRow} from '~/global/widgets/order-confirmation/OrderConfirmation'
import {OrderPlanSellMessage} from '~/global/widgets/order-plan-message/OrderPlanMessage'
import ExchangeOrderProcessMessage from '~/global/widgets/order-process-messages/ExchangeOrderProcessMessage'
import KiwiSaverOrderProcessMessage from '~/global/widgets/order-process-messages/KiwiSaverOrderProcessMessage'
import ManagedFundOrderProcessMessage from '~/global/widgets/order-process-messages/ManagedFundOrderProcessMessage'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {Toast} from '~/global/widgets/toast/Toast'
import UpcomingDividendInformation from '~/global/widgets/upcoming-dividend-information/UpcomingDividendInformation'
import {useNavigate} from '~/migrate-react-router'
import {SHARE_LIMIT, SHARE_MARKET, SHARE_TRIGGER} from '~/sections/invest/sections/order-flow/constants/orderTypes'
import {HaltedNZXMessage} from '~/sections/invest/sections/order-flow/sections/buy/pages/buy-confirm/BuyConfirm'
import SellErrors from '~/sections/invest/sections/order-flow/sections/sell/widgets/sell-errors/SellErrors'
import {getLabelBySellOrderType} from '~/sections/invest/sections/order-flow/utils/get-label-by-sell-order-type/getLabelBySellOrderType'
import KSFundFees from '~/sections/kiwisaver/widgets/fees/KSFundFees'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/order/actions'
// redirect page count
// on KS sell flow has 3 steps to get to this confrim page from the insturment view page.
// but invest flow only require 2 steps
const KIWISAVER_PAGE_COUNT = 3
const INVEST_PAGE_COUNT = 2

const SellConfirm: React.FunctionComponent<{urlSlug?: string}> = ({urlSlug}) => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const instruments = useAppSelector(s => s.instrument)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const stagedSellOrder = useAppSelector(s => s.order.stagedSellOrder)

    if (!stagedSellOrder) {
        throw Error('Should not be able to reach Sell confirmation page')
    }
    const inKiwiSaverContext = !!stagedSellOrder.ksFundHolding
    const instrument = inKiwiSaverContext
        ? instruments.underlyingInstrumentById[stagedSellOrder.fundId]
        : instruments.instrumentsById[stagedSellOrder.fundId]

    const assetManager = useAssetManager(instrument, {scope: DistillScope.INVEST})
    const [showingHaltedNZXMessage, setShowingHaltedNZXMessage] = React.useState(
        instrument ? isOnNZX(instrument) && instrument.tradingStatus === 'halt' : false,
    )
    const isManagedFund = instrument ? tradingType(instrument) === 'managed' : false

    const redirectPageCount = inKiwiSaverContext && urlSlug ? KIWISAVER_PAGE_COUNT : INVEST_PAGE_COUNT

    React.useEffect(() => {
        if (stagedSellOrder && stagedSellOrder.state === 'placed') {
            if (stagedSellOrder.orderTriggerPrice) {
                Toast('Stop loss sell order created')
            } else {
                Toast('Sell order placed')
            }

            setTimeout(() => {
                // clearing the cache for portfolio order
                queryClient.invalidateQueries({queryKey: ['kiwisaver/portfolio-orders-v2']})
                // Go back to the view instrument page after success animation
                navigate(-redirectPageCount)
            }, 1000)
        }

        if (stagedSellOrder && stagedSellOrder.error) {
            setIsSubmitting(false)
        }

        // on unmount, clean up the staged order
        return () => {
            if (stagedSellOrder && stagedSellOrder.state === 'placed') {
                dispatch(actions.ClearStagedSellOrder())
            }
        }
    }, [stagedSellOrder])

    const expectedMarketSellFee = useCorporateFeeForStagedSellOrder({
        stagedSellOrder,
        instrument,
    })

    if (!instrument) {
        return <Navigate to={profileUrl('explore')} />
    }

    const expectedMarketSellFeeIsLoading = expectedMarketSellFee === undefined

    const handleSubmit = async () => {
        setIsSubmitting(true)
        try {
            await dispatch(actions.CreateSellOrder())
            queryClient.invalidateQueries({queryKey: ['kiwisaver/fund-holding', {fund_id: instrument.id}]})
        } catch (error) {
            setIsSubmitting(false)
        }
    }

    const {orderType, orderPriceLimit, orderShareAmount, error, orderTriggerPrice} = stagedSellOrder
    const {fixedFeeSpread, marketPrice} = instrument

    const expectedManagedFundFee =
        orderShareAmount && fixedFeeSpread
            ? amountOfManagedFundFee(`${parseFloat(marketPrice) * parseFloat(orderShareAmount)}`, fixedFeeSpread)
            : undefined

    const gatherItems = (): OrderRow[] => {
        const gstDisclaimer = isOnASX(instrument) && jurisdiction === 'au' ? ' incl. GST ' : ''

        if (isManagedFund && fixedFeeSpread && assetManager && orderShareAmount) {
            const estimatedTransactionFees = {
                description: (
                    <span>
                        {`Estimated transaction fees charged by ${assetManager.shortName}`}
                        <BuyAndSellFeesManagedFund />
                    </span>
                ),
                value: (
                    <FeeValue
                        value={amountOfManagedFundFee(
                            `${parseFloat(marketPrice) * parseFloat(orderShareAmount)}`,
                            fixedFeeSpread,
                        )}
                        currency={instrument.currency}
                    />
                ),
            }
            const orderRowItems: OrderRow[] = [
                {
                    description: 'Estimated unit price',
                    value: <SharePriceValue value={marketPrice} currency={instrument.currency} />,
                },
                {
                    description: 'Number of units to sell',
                    value: <ShareValue value={orderShareAmount} showFullValue />,
                },
                {
                    description: 'Estimated order amount',
                    value:
                        expectedMarketSellFee !== undefined ? (
                            <DollarValue
                                value={Number(marketPrice) * Number(orderShareAmount)}
                                currency={instrument.currency}
                            />
                        ) : expectedMarketSellFeeIsLoading ? (
                            '…'
                        ) : (
                            '-'
                        ),
                },
            ]

            // Estimated transaction fees is only required to display for Invest
            if (!stagedSellOrder?.ksFundHolding) {
                orderRowItems.push(estimatedTransactionFees)
            }
            return orderRowItems
        }

        if (isManagedFund && !fixedFeeSpread) {
            return [{description: 'There are no transaction fees charged by this fund'}]
        }

        const displayFeeModal = instrument.isAdr ? (
            <ADR jurisdiction={jurisdiction} />
        ) : (
            <BuyAndSellFeesListedInstruments />
        )

        if (!expectedMarketSellFeeIsLoading && !expectedMarketSellFee) {
            return [
                {
                    description: <>No transaction fee on this investment {displayFeeModal}</>,
                },
            ]
        }

        // listed companies and ETFs

        const items = []

        if (orderType === SHARE_TRIGGER && orderTriggerPrice) {
            items.push({
                description: 'Share price to trigger sell order',
                value: <DollarValue value={Number(orderTriggerPrice)} currency={instrument.currency} />,
            })
        }

        if (orderPriceLimit) {
            items.push({
                description: `Lowest price to sell per ${shareLabel({instrument})}`,
                value: <SharePriceValue value={orderPriceLimit} currency={instrument.currency} />,
            })
        }

        if (orderType === SHARE_MARKET && orderShareAmount) {
            items.push({
                description: `Estimated ${shareLabel({instrument})} price`,
                value: <DollarValue value={marketPrice} currency={instrument.currency} />,
            })
        }

        if (orderShareAmount) {
            items.push({
                description: `Number of ${shareLabel({
                    instrument,
                    isPlural: true,
                    isWhole: isOnMainUsExchange(instrument) && orderType === SHARE_LIMIT,
                })} to sell`,
                value: <ShareValue value={orderShareAmount} showFullValue />,
            })
        }

        items.push({
            description: `Estimated order amount`,
            value: orderShareAmount ? (
                <DollarValue
                    value={valueOfSellOrder({
                        numberToSell: parseFloat(orderShareAmount),
                        marketPrice: parseFloat(instrument.marketPrice),
                        priceLimit: orderPriceLimit ? parseFloat(orderPriceLimit) : undefined,
                        triggerPrice: orderTriggerPrice ? parseFloat(orderTriggerPrice) : undefined,
                    })}
                    currency={instrument.currency}
                />
            ) : (
                '-'
            ),
        })

        const ksSelfSelectFeeModal = (
            <ModalLink
                dataTestId="modal-link--ks-self-select-fund-fees"
                label="KiwiSaver self-select fund fees"
                asIcon
                modalTitle="Sharesies KiwiSaver Scheme fees"
                primaryButton={{label: 'Got it'}}
                helpIconSize={16}
                bottomBorder
            >
                <KSFundFees />
            </ModalLink>
        )
        items.push({
            description: (
                <>
                    Estimated transaction fee {gstDisclaimer}
                    {inKiwiSaverContext ? ksSelfSelectFeeModal : displayFeeModal}
                </>
            ),
            value: expectedMarketSellFee ? (
                <FeeValue value={expectedMarketSellFee} currency={instrument.currency} />
            ) : expectedMarketSellFeeIsLoading ? (
                '…'
            ) : (
                '-'
            ),
            explainerText: !isManagedFund && !inKiwiSaverContext ? <CoverageText feeCoverage="maybe" /> : undefined,
        })

        return items
    }

    const extraInfo = () => {
        if (inKiwiSaverContext) {
            return <KiwiSaverOrderProcessMessage instrument={instrument} />
        }
        return (
            <>
                {<OrderPlanSellMessage isManagedFund={isManagedFund} />}
                {isManagedFund && <ManagedFundOrderProcessMessage />}
                {!isManagedFund && (
                    <ExchangeOrderProcessMessage
                        instrument={instrument}
                        side="sell"
                        isLimitOrder={!!orderPriceLimit}
                        isTriggerOrder={!!orderTriggerPrice}
                        isForExtendedHours={!!stagedSellOrder.extendedHours}
                    />
                )}
            </>
        )
    }

    return (
        <>
            <Page>
                {showingHaltedNZXMessage && (
                    <HaltedNZXMessage
                        onClick={() => {
                            setShowingHaltedNZXMessage(false)
                        }}
                    />
                )}
                {!isManagedFund && <ExchangeHours instrument={instrument} />}
                <div className={confirmStyles.header}>
                    <PageBack
                        onClick={() => {
                            dispatch(actions.UpdateStagedSellOrder({...stagedSellOrder, state: 'initialised'}))
                            navigate(-1)
                        }}
                    />
                    <h1>
                        {isManagedFund
                            ? 'Confirm your sell order'
                            : orderType === SHARE_TRIGGER
                              ? `Confirm your stop loss sell order in shares`
                              : `Confirm your ${getLabelBySellOrderType(orderType, instrument)}`}
                    </h1>
                </div>
                <OrderConfirmation
                    title={instrument.name}
                    subtitle={
                        !isManagedFund ? (
                            <p className={confirmStyles.symbol}>
                                <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                                <PronounceLetters text={instrument.exchange} />
                            </p>
                        ) : undefined
                    }
                    image={<InstrumentLogo instrument={instrument} noBorder />}
                    items={gatherItems()}
                    total={{
                        description: orderType === 'share_trigger' ? "Maximum you'll receive" : 'Estimated to receive',
                        value: (
                            <span>
                                {expectedMarketSellFeeIsLoading ? (
                                    '…'
                                ) : (
                                    <>
                                        {orderShareAmount && !isManagedFund && (
                                            <DollarValue
                                                value={
                                                    valueOfSellOrder({
                                                        numberToSell: parseFloat(orderShareAmount),
                                                        marketPrice: parseFloat(instrument.marketPrice),
                                                        priceLimit: orderPriceLimit
                                                            ? parseFloat(orderPriceLimit)
                                                            : undefined,
                                                        triggerPrice: orderTriggerPrice
                                                            ? parseFloat(orderTriggerPrice)
                                                            : undefined,
                                                    }) - expectedMarketSellFee
                                                }
                                                currency={instrument.currency}
                                            />
                                        )}
                                        {orderShareAmount && isManagedFund && (
                                            <DollarValue
                                                value={
                                                    Number(marketPrice) * Number(orderShareAmount) -
                                                    (fixedFeeSpread
                                                        ? Number(expectedManagedFundFee)
                                                        : Number(expectedMarketSellFee))
                                                }
                                                currency={instrument.currency}
                                            />
                                        )}
                                    </>
                                )}
                            </span>
                        ),
                    }}
                />
                <div>
                    {!isManagedFund && <UpcomingDividendInformation verb="sell" instrument={instrument} />}

                    <div className={confirmStyles.extraInfo}>{extraInfo()}</div>
                </div>
                {error && (
                    <p className={confirmStyles.submitError}>We couldn’t complete the order, please try again.</p>
                )}
            </Page>

            <ActionBar>
                <Button
                    label="Sell"
                    dataTestId="button--sell"
                    processing={isSubmitting || stagedSellOrder.state === 'placed'}
                    onClick={handleSubmit}
                    disabled={expectedMarketSellFeeIsLoading}
                />
            </ActionBar>

            {error && (
                <SellErrors
                    stagedSellOrder={stagedSellOrder}
                    instrumentName={instrument.name}
                    isDependent={isDependent}
                    preferredName={preferredName}
                    updateStagedSellOrder={() => dispatch(actions.UpdateStagedSellOrder(stagedSellOrder))}
                />
            )}
        </>
    )
}

export default SellConfirm
