import AUStateSelect from './au-state-select/AUStateSelectInput'
import {formikWrap} from './common'
import CountrySelect from './country-select/CountrySelectInput'
import * as widgets from '.'

export const Address = formikWrap(widgets.Address)
export const AUBankAccount = formikWrap(widgets.AUBankAccount)
export const AUStateSelectInput = formikWrap(AUStateSelect) //direct import instead of through form/index to avoid circular dependency
export const BankBSB = formikWrap(widgets.BankBSB)
export const ButtonGroup = formikWrap(widgets.ButtonGroup)
export const CountrySelectInput = formikWrap(CountrySelect) //direct import instead of through form/index to avoid circular dependency
export const Checkbox = formikWrap(widgets.Checkbox)
export const CheckboxGroup = formikWrap(widgets.CheckboxGroup)
export const DateInput = formikWrap(widgets.DateInput)
export const Email = formikWrap(widgets.Email)
export const ExchangeMoneyInput = formikWrap(widgets.StrongCurrencyExchange)
export const PercentageInput = formikWrap(widgets.PercentageInput)
export const GiftCode = formikWrap(widgets.GiftCode)
export const IRDNumber = formikWrap(widgets.IRDNumber)
export const Number = formikWrap(widgets.Number)
export const NZBankAccount = formikWrap(widgets.NZBankAccount)
export const Password = formikWrap(widgets.Password)
export const Phone = formikWrap(widgets.Phone)
export const PromoCode = formikWrap(widgets.PromoCode)
export const Radio = formikWrap(widgets.Radio)
export const ResetCodeField = formikWrap(widgets.ResetCodeField)
export const Select = formikWrap(widgets.Select)
export const DecimalInput = formikWrap(widgets.DecimalInput)
export const StripeCardNumber = formikWrap(widgets.StripeCardNumber)
export const StripeExpiryDate = formikWrap(widgets.StripeExpiryDate)
export const StripeCVC = formikWrap(widgets.StripeCVC)
export const StrongNumber = formikWrap(widgets.StrongNumber)
export const StrongCurrency = formikWrap(widgets.StrongCurrency)
export const StrongInlineCurrency = formikWrap(widgets.StrongInlineCurrency)
export const StrongInlineSelect = formikWrap(widgets.StrongInlineSelect)
export const StrongInlineDate = formikWrap(widgets.StrongInlineDate)
export const SwitchField = formikWrap(widgets.SwitchField)
export const Text = formikWrap(widgets.Text)
export const TFNNumber = formikWrap(widgets.TFNNumber)
