import {Button} from '@design-system/button'
import {Form, Formik} from 'formik'
import React from 'react'
import {useNavigate} from 'react-router'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import {Request} from '~/api/retail/types'
import NotFound from '~/global/pages/not-found/NotFound'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {validate} from '~/global/widgets/form-controls'
import {Radio} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'

type AccountHolderType = Request.SetOwnerAccountHolderType['account_holder_type']

export const ACCOUNT_HOLDER_TYPE_TO_LABEL: Record<AccountHolderType, string> = {
    ACTIVE_NON_FINANCIAL: 'Active non-financial entity (NFE)',
    PASSIVE_NON_FINANCIAL: 'Passive non-financial entity (NFE)',
    FINANCIAL: 'Financial institution',
    OTHER: 'Other FATCA/CRS classification',
} as const

const ACCOUNT_HOLDER_TYPES: AccountHolderType[] = Object.keys(ACCOUNT_HOLDER_TYPE_TO_LABEL) as AccountHolderType[]

const ACCOUNT_HOLDER_TYPE_TO_HELP_TEXT: Record<AccountHolderType, string | null> = {
    ACTIVE_NON_FINANCIAL:
        'Mostly earning income through regular business activities like selling products or services.',
    PASSIVE_NON_FINANCIAL: 'Mostly earning money from passive investments or assets.',
    FINANCIAL:
        'Mostly engaging in financial activities. Typically applies to banks, investment firms, insurance companies and the like.',
    OTHER: null,
}

const ACCOUNT_HOLDER_TYPE_OPTIONS = ACCOUNT_HOLDER_TYPES.map(value => ({
    value,
    label: ACCOUNT_HOLDER_TYPE_TO_LABEL[value],
    helpText: ACCOUNT_HOLDER_TYPE_TO_HELP_TEXT[value],
}))

const AccountHolderType = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const owner = useProfileOwner()

    const taxDetails = useRetailGet({
        path: 'owner/:owner_id/tax-details',
        pathParams: {owner_id: owner.id},
    }).data

    const updateAccountHolderType = useRetailPost({
        path: 'owner/:owner_id/account-holder-type',
        pathParams: {owner_id: owner.id},
        queryCacheToUpdate: [`owner/:owner_id/tax-details`, {owner_id: owner.id}],
    })

    return (
        <>
            <Toolbar dataTestId="toolbar--account-holder-type" leftButton="back" title="Account holder type" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <Formik
                    initialValues={{
                        account_holder_type: taxDetails.account_holder_type,
                    }}
                    onSubmit={async values => {
                        await updateAccountHolderType.mutateAsync({account_holder_type: values.account_holder_type!})
                        Toast('Details updated')
                        navigate(profileUrl('settings/tax-details'))
                    }}
                    validate={validate.generate<Partial<Request.SetOwnerAccountHolderType>>({
                        account_holder_type: [validate.required()],
                    })}
                >
                    {({isSubmitting, isValid, dirty}) => {
                        return (
                            <Form>
                                <h2>Which account holder type applies to {owner.display_name}?</h2>

                                <Radio
                                    name="account_holder_type"
                                    dataTestId="radio--account-holder-type"
                                    choices={ACCOUNT_HOLDER_TYPE_OPTIONS}
                                />

                                <p>
                                    This is your classification under the Foreign Account Tax Compliance Act (FATCA) and
                                    the OECD Automatic Exchange of Information Common Reporting Standard (CRS).
                                </p>

                                <Button
                                    isSubmit
                                    pageButton
                                    label="Save changes"
                                    dataTestId="button--save-changes"
                                    disabled={!isValid || !dirty}
                                    processing={isSubmitting}
                                />
                            </Form>
                        )
                    }}
                </Formik>
            </Page>
        </>
    )
}

const AccountHolderTypeWrapper = () => {
    const owner = useProfileOwner()
    return owner.owner_type === 'ORGANISATION' ? <AccountHolderType /> : <NotFound />
}

export default AccountHolderTypeWrapper
