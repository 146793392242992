import {Button} from '@design-system/button'
import {Flag} from '@design-system/flag'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {dateFormatShortMonth} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {PolicyQuoteCommonProps} from '~/sections/protect/sections/insure/sections/landing/utils/car/car'
import commonStyles from '~/sections/protect/sections/insure/sections/landing/utils/common-styles/commonStyles.scss'
import {Row} from '~/sections/protect/sections/insure/sections/landing/widgets/row/Row'
import {PolicyQuoteCommonRows} from '~/sections/protect/sections/insure/widgets/policy-quote-common-rows/PolicyQuoteCommonRows'

interface PolicyProps extends PolicyQuoteCommonProps {
    cardEnding?: string
    code: string
    effectiveFromDate: Date
    hasRoadSideAssistance: boolean
    renewalDate?: Date
    state: string // Pending, Paid
}

export const Policy: React.FunctionComponent<PolicyProps> = ({
    cardEnding,
    carValue,
    code,
    coverage,
    effectiveFromDate,
    excess,
    hasRoadSideAssistance,
    name,
    premiumCost,
    premiumFrequency,
    renewalDate,
    state,
}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const policyState = state === 'Pending' ? 'Pending' : state === 'Cancelled' ? 'Cancelled' : 'Active'

    return (
        <div className={commonStyles.card} data-testid={`cove-policy-${code}`}>
            <div className={commonStyles.cardMainContent}>
                <h3 className={commonStyles.cardTitle}>{name}</h3>
                <p className={cn(commonStyles.cardSubtitle, spacing.spaceAbove8, spacing.spaceBelow16)}>
                    Policy: {code}
                </p>

                <PolicyQuoteCommonRows carValue={carValue} coverage={coverage} excess={excess} />
                <Row
                    label="Effective from"
                    value={effectiveFromDate.toLocaleDateString('en-NZ', dateFormatShortMonth)}
                />
                {renewalDate && (
                    <Row label="Renews" value={renewalDate.toLocaleDateString('en-NZ', dateFormatShortMonth)} />
                )}
                <Row label="Payment" value={`Credit card ending in ${cardEnding}`} />

                <div
                    data-testid="footer--premium-summary"
                    className={cn(commonStyles.premiumWrapper, spacing.spaceAbove8)}
                >
                    <p className={commonStyles.premiumLabel}>{premiumFrequency} premium</p>
                    <div className={spacing.spaceBelow24}>
                        <div className={commonStyles.premiumFooter}>
                            <Flag
                                type={
                                    policyState === 'Pending'
                                        ? 'info'
                                        : policyState === 'Active'
                                          ? 'success'
                                          : 'neutral'
                                }
                                isSubtle
                                dataTestId="flag--insure"
                            >
                                {policyState}
                            </Flag>

                            <p className={commonStyles.premiumAmount}>${parseFloat(premiumCost).toFixed(2)} NZD</p>
                        </div>
                    </div>
                </div>

                <Button
                    label="View, edit or claim"
                    dataTestId="button--cove-policy-manage"
                    type="secondary"
                    additionalClassName={spacing.spaceAbove24}
                    onClick={() => {
                        if (policyState === 'Pending') {
                            // Until they become active, pending (future-dated) policies are still officially quotes in Cove's system
                            navigate(profileUrl('protect/insure/car/cove-quote/:quoteId', {quoteId: code}))
                        } else {
                            navigate(profileUrl('protect/insure/car/cove-policy/:policyId', {policyId: code}))
                        }
                    }}
                />
            </div>

            {hasRoadSideAssistance && (
                <div className={commonStyles.cardFooter}>
                    Roadside assistance: <a href="tel:0800268347">0800 268 347</a>
                </div>
            )}
        </div>
    )
}

export const CancelledPolicy: React.FunctionComponent<{
    code: string
    name: string
    premiumFrequency: string
    premiumCost: string
}> = ({code, name, premiumFrequency, premiumCost}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    return (
        <div className={commonStyles.card}>
            <div className={commonStyles.cardMainContent}>
                <h3 className={commonStyles.cardTitle}>{name}</h3>
                <p className={cn(commonStyles.cardSubtitle, spacing.spaceAbove8, spacing.spaceBelow16)}>
                    Policy {code}
                </p>

                <div data-testid="footer--premium-summary" className={commonStyles.premiumWrapper}>
                    <p className={cn(commonStyles.premiumLabel, spacing.spaceAbove16)}>{premiumFrequency} premium</p>
                    <div>
                        <div className={commonStyles.premiumFooter}>
                            <Flag type="neutral" isSubtle dataTestId="flag--insure">
                                Cancelled
                            </Flag>
                            <p className={commonStyles.premiumAmount}>${premiumCost} NZD</p>
                        </div>
                    </div>
                </div>

                <Button
                    label="View, edit or claim"
                    dataTestId="button--cove-cancelled-policy-manage"
                    type="secondary"
                    additionalClassName={spacing.spaceAbove24}
                    onClick={() => navigate(profileUrl('protect/insure/car/cove-policy/:policyId', {policyId: code}))}
                />
            </div>
        </div>
    )
}
