import {ModalLink} from '@design-system/modal'
import React from 'react'
import {Model} from '~/api/retail/types'
import PrivacyPolicy from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/PrivacyPolicy'

export const getMarketConductUrl = (jurisdiction: Model.User['jurisdiction']) => {
    if (jurisdiction === 'au') {
        return 'https://intercom.help/sharesies-au/en/articles/8482859-market-conduct-rules'
    }

    return 'https://intercom.help/sharesies/en/articles/8482855-market-conduct-rules'
}

const PrivacyPolicyModalLink: React.FunctionComponent = () => (
    <ModalLink dataTestId="modal-link--privacy-policy" label="Privacy Policy" modalTitle="Privacy Policy" bottomBorder>
        <PrivacyPolicy />
    </ModalLink>
)

export default PrivacyPolicyModalLink
