import * as api from '~/api/retail'
import {Response} from '~/api/retail/types'
import * as rollbar from '~/api/rollbar/rollbar'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {APINetworkError, NonRollbarError, UserSuitableError} from '~/global/utils/error-handling/errorHandling'
import {actingAsID} from '../identity/selectors'
import {createAction, ActionsUnion} from '../redux-tools'
import {ThunkAction} from '../types'
import {State} from './types'

const actions = {
    SetParticipationLoadingState: (loadingState: State['participationLoadingState']) =>
        createAction('employeeShareScheme.SetParticipationLoadingState', loadingState),
    SetAllocationsLoadingState: (loadingState: State['allocationsLoadingState']) =>
        createAction('employeeShareScheme.SetAllocationsLoadingState', loadingState),
    SetDetailLoadingState: (loadingState: State['detailLoadingState']) =>
        createAction('employeeShareScheme.SetDetailLoadingState', loadingState),
    SetBlackoutPeriodLoadingState: (loadingState: State['blackoutPeriodLoadingState']) =>
        createAction('employeeShareScheme.SetBlackoutPeriodLoadingState', loadingState),
    SetShareSchemeDetail: (detail: Response.ESSShareSchemeDetail) =>
        createAction('employeeShareScheme.SetShareSchemeDetail', detail),
    SetInvestmentDetailsToggleStateForInstrument: (instrumentId: string, isToggleOpen: boolean) =>
        createAction('employeeShareScheme.SetInvestmentDetailsToggleStateForInstrument', {
            instrumentId,
            isToggleOpen,
        }),
    SetAllShareSchemeDetails: (details: Response.ESSParticipatingSchemesV2) =>
        createAction('employeeShareScheme.SetAllShareSchemeDetails', {
            details,
        }),
    ClearEmployeeShareScheme: () => createAction('employeeShareScheme.ClearEmployeeShareScheme'),
    SetSchemesPreventingAccountClosureLoadingState: (
        loadingState: State['schemesPreventingAccountClosureLoadingState'],
    ) => createAction('employeeShareScheme.SetSchemesPreventingAccountClosureLoadingState', loadingState),
    SetSchemesPreventingAccountClosure: ({share_schemes}: Response.ESSSchemesPreventingAccountClosure) =>
        createAction('employeeShareScheme.SetSchemesPreventingAccountClosure', share_schemes),
    SetBlackoutPeriodByInstrument: ({blackout_period_by_instrument_id}: Response.ESSBlackoutPeriods) =>
        createAction('employeeShareScheme.SetBlackoutPeriodByInstrument', blackout_period_by_instrument_id),
    SetUpcomingAllocationsPageCurrentTab: (newTab: State['upcomingAllocationsPageCurrentTab']) =>
        createAction('employeeShareScheme.SetUpcomingAllocationsPageCurrentTab', newTab),
    ToggleShowShareAllocationCurrentValue: () =>
        createAction('employeeShareScheme.ToggleShowShareAllocationCurrentValue'),
    ToggleShowShareRightAllocationCurrentValue: () =>
        createAction('employeeShareScheme.ToggleShowShareRightAllocationCurrentValue'),
    ToggleShowAlignmentRightAllocationCurrentValue: () =>
        createAction('employeeShareScheme.ToggleShowAlignmentRightAllocationCurrentValue'),
}

const thunkActions = {
    FetchShareSchemeDetail(shareSchemeId: string): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            try {
                dispatch(actions.SetDetailLoadingState('loading'))
                const data = await api.get('employee_share_scheme/share_scheme_detail', {
                    share_scheme_id: shareSchemeId,
                    acting_as_id: actingAsID(getState()),
                })
                switch (data.type) {
                    case 'employee_share_scheme_detail':
                        dispatch(actions.SetShareSchemeDetail(data))
                        dispatch(actions.SetDetailLoadingState('ready'))
                        return
                    case 'internal_server_error':
                        // TODO: handle this properly'
                        break
                    default:
                        assertNever(data)
                }
                return
            } catch (e) {
                dispatch(actions.SetDetailLoadingState('error'))
                if (
                    e instanceof UserSuitableError ||
                    e instanceof APINetworkError ||
                    e instanceof NonRollbarError ||
                    e instanceof Error
                ) {
                    rollbar.sendError(e.message, {
                        statusText: e.message,
                        method: 'FetchShareSchemeDetail',
                    })
                }
            }
        }
    },
    FetchParticipatingShareSchemes(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const data = {
                acting_as_id: actingAsID(getState()),
            }
            try {
                dispatch(actions.SetParticipationLoadingState('loading'))
                const response = await api.get('employee_share_scheme/participating-schemes-v2', data)
                switch (response.type) {
                    case 'ess_participating_schemes_v2':
                        dispatch(actions.SetAllShareSchemeDetails(response))
                        dispatch(actions.SetParticipationLoadingState('ready'))
                        dispatch(actions.SetAllocationsLoadingState('ready'))
                        return
                    case 'internal_server_error':
                        // TODO: handle this properly'
                        break
                    default:
                        assertNever(response)
                }
                return
            } catch (e) {
                dispatch(actions.SetParticipationLoadingState('error'))
                if (
                    e instanceof UserSuitableError ||
                    e instanceof APINetworkError ||
                    e instanceof NonRollbarError ||
                    e instanceof Error
                ) {
                    rollbar.sendError(e.message, {
                        statusText: e.message,
                        method: 'FetchParticipatingShareSchemes',
                    })
                }
            }
        }
    },
    FetchSchemesPreventingAccountClosure(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const data = {
                acting_as_id: actingAsID(getState()),
            }
            try {
                dispatch(actions.SetSchemesPreventingAccountClosureLoadingState('loading'))
                const response = await api.get('employee_share_scheme/schemes-preventing-account-closure', data)
                switch (response.type) {
                    case 'ess_schemes_preventing_account_closure':
                        dispatch(actions.SetSchemesPreventingAccountClosure(response))
                        dispatch(actions.SetSchemesPreventingAccountClosureLoadingState('ready'))
                        return
                    case 'internal_server_error':
                        // TODO: handle this properly'
                        break
                    default:
                        assertNever(response)
                }
                return
            } catch (e) {
                dispatch(actions.SetSchemesPreventingAccountClosureLoadingState('error'))
                if (
                    e instanceof UserSuitableError ||
                    e instanceof APINetworkError ||
                    e instanceof NonRollbarError ||
                    e instanceof Error
                ) {
                    rollbar.sendError(e.message, {
                        statusText: e.message,
                        method: 'FetchSchemesPreventingAccountClosure',
                    })
                }
            }
        }
    },
    FetchBlackoutPeriods(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const data = {
                acting_as_id: actingAsID(getState()),
            }
            try {
                dispatch(actions.SetBlackoutPeriodLoadingState('loading'))
                const response = await api.get('employee_share_scheme/blackout-periods', data)
                switch (response.type) {
                    case 'ess_blackout_periods':
                        dispatch(actions.SetBlackoutPeriodByInstrument(response))
                        dispatch(actions.SetBlackoutPeriodLoadingState('ready'))
                        return
                    case 'internal_server_error':
                        // TODO: handle this properly'
                        break
                    default:
                        assertNever(response)
                }
                return
            } catch (e) {
                dispatch(actions.SetBlackoutPeriodLoadingState('error'))
                if (
                    e instanceof UserSuitableError ||
                    e instanceof APINetworkError ||
                    e instanceof NonRollbarError ||
                    e instanceof Error
                ) {
                    rollbar.sendError(e.message, {
                        statusText: e.message,
                        method: 'FetchBlackoutPeriods',
                    })
                }
            }
        }
    },
}

export type ActionsType = ActionsUnion<typeof actions>

export default {...actions, ...thunkActions}
