import {SuccessFilled} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import styles from './CustomSelectCard.scss'

interface PaymentMethodOptionProps {
    icon?: JSX.Element
    onClickIfNotActive: () => void
    title: string
    subText: React.ReactNode
    active: boolean
    dataTestId: string
}

// A modified version of @design-system/SelectCards, that supports a green active check.
// This is only used for a special pattern in subscriptions, and can be properly merged
// into the design system if the pattern gains traction.
export const CustomSelectCard: React.FunctionComponent<PaymentMethodOptionProps> = ({
    icon,
    onClickIfNotActive,
    title,
    subText,
    active,
    dataTestId,
}) => {
    return (
        <button
            type="button"
            className={cn([styles.selectBox, spacing.spaceBelow12, active && styles.active])}
            onClick={() => !active && onClickIfNotActive()}
            data-testid={dataTestId}
        >
            {icon}
            <div className={styles.body}>
                <div className={styles.title}>
                    <strong>{title}</strong>
                    {active && (
                        <div className={styles.activeTag}>
                            Active{' '}
                            <div className={styles.greenCheck}>
                                <SuccessFilled size={12} />
                            </div>
                        </div>
                    )}
                </div>
                <span>{subText}</span>
            </div>
        </button>
    )
}
