/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {DateTime} from 'luxon'
import * as runtime from '../runtime'
import {
    ExchangeCalendarResponseDto,
    ExchangeCalendarResponseDtoFromJSON,
    ExchangeCalendarResponseDtoToJSON,
} from '../models'

export interface ApiV1ExchangecalendarInfoGetRequest {
    exchangeName: string
}

/**
 *
 */
export class ExchangeCalendarApi extends runtime.BaseAPI {
    /**
     */
    async apiV1ExchangecalendarInfoGetRaw(
        requestParameters: ApiV1ExchangecalendarInfoGetRequest,
    ): Promise<runtime.ApiResponse<ExchangeCalendarResponseDto>> {
        if (requestParameters.exchangeName === null || requestParameters.exchangeName === undefined) {
            throw new runtime.RequiredError(
                'exchangeName',
                'Required parameter requestParameters.exchangeName was null or undefined when calling apiV1ExchangecalendarInfoGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.exchangeName !== undefined) {
            queryParameters['ExchangeName'] = requestParameters.exchangeName
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/exchangecalendar/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => ExchangeCalendarResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1ExchangecalendarInfoGet(
        requestParameters: ApiV1ExchangecalendarInfoGetRequest,
    ): Promise<ExchangeCalendarResponseDto> {
        const response = await this.apiV1ExchangecalendarInfoGetRaw(requestParameters)
        return await response.value()
    }
}
