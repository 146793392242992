import {useRetailGet} from '~/api/query/retail'
import {useWalletPortfolio} from '~/sections/wallet/state/local'
import {useAppSelector} from '~/store/hooks'
import {actingAsID as actingAsIDSelector} from '~/store/identity/selectors'

export const useBalances = () => {
    const walletPortfolio = useWalletPortfolio()
    return useRetailGet({
        path: 'wallet/:portfolio_id/balances',
        pathParams: {portfolio_id: walletPortfolio.id},
        options: {
            staleTime: 0, // we want the latest data whenever we're looking at customer balances, refresh in the background whenever load a screen that uses it
        },
    }).data.balances
}

/**
 * Identity items we can usually expect to be present without an explicit API
 * load, for use in is_legacy_portfolio wallets.
 *
 * As we move towards broader shared money patterns these should be unpacked
 * into their own tanstack retail hooks, which is why this is in retail.ts not
 * local.ts for now
 */
export const useLegacyUserData = () => {
    // all identity data can be assumed to be initialiased already (no need to make API call)
    const actingAsId = useAppSelector(actingAsIDSelector)
    const isRestrictedAccount = useAppSelector(s => s.identity.user!.account_restricted)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const identityVerification = useAppSelector(s => s.identity.user!.checks.identity_verification)
    const hasFastWithdrawFlag = useAppSelector(s => s.identity.flags.fast_withdrawals)
    const hasTopupGoal = !!useAppSelector(s => s.accounting.topupGoal) // this is always initialised by handleIdentityResponse, don't need to worry about making the call
    const hasPayIdFlag = !!useAppSelector(s => 'pay_id' in s.identity.flags)
    const hasBlinkpayFlag = !!useAppSelector(s => 'blinkpay_enabled' in s.identity.flags)

    return {
        actingAsId,
        isRestrictedAccount,
        hasTopupGoal,
        jurisdiction,
        canFastWithdraw: jurisdiction === 'nz' || hasFastWithdrawFlag, // TODO confirm Fonterra is allowed to fast withdraw
        identityVerification,
        hasPayIdFlag,
        hasBlinkpayFlag,
    }
}
