import {Button} from '@design-system/button'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {Checkbox} from '~/global/widgets/form-controls'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {
    AUTermsDocumentLinks,
    AUTermsCheckboxPreamble,
} from '~/sections/user/sections/sign-up/widgets/au-terms-and-conditions-v4/AUTermsAndConditionsV4'
import {AU_TC_VERSION} from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/TermsAndConditions'
import {useAppDispatch} from '~/store/hooks'
import actions from '~/store/identity/actions'
import styles from './AUTermsAndConditions.scss'

const AUTermsAndConditions: React.FC = () => {
    const dispatch = useAppDispatch()
    const [tcAccepted, setTcAccepted] = React.useState(false)
    const [processing, setProcessing] = React.useState(false)

    const handleNext = async () => {
        setProcessing(true)
        await dispatch(actions.acceptTermsAndConditions(AU_TC_VERSION))
        setProcessing(false)
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--signup-au-terms-and-conditions" />
            <Page>
                <h1 className={spacing.spaceBelow16}>Legal stuff</h1>
                <AUTermsCheckboxPreamble />
                <Checkbox
                    additionalClassName={styles.checkboxNoHelpTextSpace}
                    name="au-terms-and-conditions"
                    dataTestId="checkbox--au-terms-and-conditions"
                    value={tcAccepted}
                    onChange={() => setTcAccepted(!tcAccepted)}
                    label="I’ve read and agree to the:"
                    isTouched={true}
                />
                <AUTermsDocumentLinks />
            </Page>
            <ActionBar className={styles.signUpActionBar}>
                <Button
                    dataTestId="button--au-terms-conditions-next"
                    label="Next"
                    onClick={handleNext}
                    processing={processing}
                    disabled={!tcAccepted}
                />
            </ActionBar>
        </>
    )
}

export {AUTermsAndConditions}
