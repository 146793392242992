import cn from 'classnames'
import React from 'react'
import {Request} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {ListMenuGroup, ListMenuItem} from '~/global/widgets/list-menu/ListMenu'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useKSCustomerFiles} from '~/sections/kiwisaver/state'
import {PageTitle} from '~/sections/kiwisaver/widgets/page-title/PageTitle'

const titleText = (purpose: Request.KSCustomerFiles['customer_file_purpose']): string => {
    switch (purpose) {
        case 'KIWISAVER_ANNUAL_MEMBER_STATEMENT':
            return 'Annual member statements'
        case 'KIWISAVER_ANNUAL_TAX_REPORT':
            return 'Annual tax certificates'
        case 'KIWISAVER_QUARTERLY_REPORT':
            return 'Personalised quarterly reports'
    }
}

const definitionText = (purpose: Request.KSCustomerFiles['customer_file_purpose']): string => {
    switch (purpose) {
        case 'KIWISAVER_ANNUAL_MEMBER_STATEMENT':
            return 'A yearly summary of your KiwiSaver account including your total savings, contributions, investment performance, and fees.'
        case 'KIWISAVER_ANNUAL_TAX_REPORT':
            return 'A yearly summary of tax on your KiwiSaver investment. Sharesies pays or claims this on your behalf.'
        case 'KIWISAVER_QUARTERLY_REPORT':
            return 'A quarterly snapshot of your KiwiSaver and its performance.'
    }
}

export const MemberReports: React.FunctionComponent<{
    purpose: Request.KSCustomerFiles['customer_file_purpose']
}> = ({purpose}) => {
    const reports = useKSCustomerFiles(purpose)
    const customerHasReports = reports.years.length > 0

    return (
        <>
            <Toolbar leftButton="back" dataTestId="toolbar--personalised-quarterly-reports" />
            <Page>
                <PageTitle className={spacing.spaceBelow12}>{titleText(purpose)}</PageTitle>
                <p className={cn(spacing.spaceBelow12)}>{definitionText(purpose)}</p>
                {!customerHasReports && (
                    <AlertCard type="warning">
                        You don’t have any of these reports yet. We’ll let you know when one’s been issued.
                    </AlertCard>
                )}
                {customerHasReports &&
                    reports.years.map(year => (
                        <React.Fragment key={year.year}>
                            <h2 className={spacing.spaceAbove16}>{year.year}</h2>
                            <ListMenuGroup className={cn(spacing.spaceBelow16)}>
                                {year.files.map(report => (
                                    <ListMenuItem
                                        label={report.display_name}
                                        key={report.id}
                                        onClick={() =>
                                            window.open(
                                                `/api/kiwisaver/customer-file/${report.id}`,
                                                '_blank',
                                                'noopener',
                                            )
                                        }
                                    />
                                ))}
                            </ListMenuGroup>
                        </React.Fragment>
                    ))}
            </Page>
        </>
    )
}
