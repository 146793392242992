import {Search, Close} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {accessibility} from '~/global/scss/helpers'
import {
    CommonProps,
    commonClassnames,
    withFocus,
    FocusInjectedProps,
    withId,
} from '~/global/widgets/form-controls/common'
import styles from './AiSearchInput.scss'

type AiSearchProps = CommonProps<string> &
    FocusInjectedProps & {
        additionalClassName?: string
        executeQuery: () => void
        queryValue: string
        lastExecutedQuery?: string
        autoFocus?: boolean
        setQueryValue: (value: string) => void
        withBorder?: boolean
    }

export const AiSearchInput = (props: AiSearchProps) => {
    const handleOnChange = (value: string) => {
        props.setQueryValue(value)
    }

    const inputRef = React.createRef<HTMLInputElement>()

    const executeQuery = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.blur()
        }
        props.executeQuery()
    }

    let rightButton: React.ReactNode
    if (props.queryValue === props.lastExecutedQuery) {
        // Clear search button
        rightButton = (
            <button
                type="button"
                className={cn(accessibility.button, styles.rightButton)}
                aria-label="Clear search input"
                onClick={() => {
                    props.setQueryValue('')
                    if (inputRef && inputRef.current) {
                        inputRef.current.focus()
                    }
                }}
            >
                <Close size={14} />
            </button>
        )
    } else {
        // "Submit search" button
        rightButton = (
            <button
                type="submit"
                className={cn(accessibility.button, styles.rightButton)}
                aria-label="Search"
                disabled={!props.queryValue.length}
                onClick={() => {
                    executeQuery()
                }}
            >
                <Search size={16} />
            </button>
        )
    }

    return (
        <div className={cn(styles.searchInput, props.additionalClassName)}>
            <div
                className={commonClassnames(props, styles.text, {
                    [styles.focus]: props.isFocused,
                })}
            >
                <div className={cn(styles.control)}>
                    <form
                        onSubmit={event => {
                            event.preventDefault()
                            executeQuery()
                        }}
                        data-testid="search-input"
                    >
                        <input
                            ref={inputRef}
                            id="search"
                            className={cn(styles.input, {
                                [styles.isTouched]: !!props.queryValue,
                                [styles.withBorder]: !!props.withBorder,
                            })}
                            name={props.name}
                            autoComplete="off"
                            autoFocus={props.autoFocus}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            value={props.queryValue}
                            onChange={e => handleOnChange(e.currentTarget.value)}
                            placeholder="Search"
                        />
                    </form>
                    {rightButton}
                </div>
            </div>
        </div>
    )
}

const AiSearchInputWithFocus = withId(withFocus(AiSearchInput))

export default AiSearchInputWithFocus
