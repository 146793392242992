import {formatNumberWithThousandsSeparator} from '@design-system/format-number-with-thousands-separator'
import cn from 'classnames'
import React from 'react'
import {Link, Navigate} from 'react-router-dom'
import {distillGetFactory} from '~/api/query/distill'
import {useRetailGet} from '~/api/query/retail'
import {Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {InstrumentRowCompact} from '~/global/widgets/instrument-row/InstrumentRowCompact'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {DeleteNotifications} from '~/sections/user/sections/settings/sections/notifications/pages/price-notifications/widgets/delete-notifications/DeleteNotifications'
import {EditNotification} from '~/sections/user/sections/settings/sections/notifications/pages/price-notifications/widgets/edit-notification/EditNotification'
import {useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import styles from './PriceNotifications.scss'

const useInstruments = (instrumentIds: string[]): Instrument[] => {
    const response = distillGetFactory({
        apiFunctionName: 'apiV1InstrumentsSearchV2Get',
    })({
        query: '',
        instruments: instrumentIds,
        scope: 'invest',
    })

    return response.data.instruments.map(instrument => {
        const currency =
            instrument.exchangeCountry === 'usa'
                ? 'usd'
                : instrument.exchangeCountry === 'aus'
                  ? 'aud'
                  : ('nzd' as Currency)
        return {
            currency,
            ...instrument,
        } as Instrument
    })
}

type PortfoliosType = Response.Profiles['profiles'][0]['portfolios']

const getPortfolioIds = (portfolios: PortfoliosType): string[] => {
    const filteredPortfolios = portfolios.filter(portfolio => ['INVEST', 'FONTERRA'].find(p => p === portfolio.product))
    return filteredPortfolios.map(portfolio => portfolio.id)
}

const getInstrumentIds = (portfolios: Response.PriceNotificationsForPortfolios['portfolios']): string[] => {
    const instrumentIds: string[] = []

    portfolios.forEach(portfolio => {
        // Price notifications are keyed by instrument id
        portfolio.price_notifications.forEach(priceNotification => {
            if (!instrumentIds.includes(priceNotification.fund_id)) {
                instrumentIds.push(priceNotification.fund_id)
            }
        })
    })

    return instrumentIds
}

const getInstrumentsById = (instruments: Instrument[]) => {
    const instrumentsById: {[key: string]: Instrument} = {}

    instruments.forEach(instrument => {
        instrumentsById[instrument.id] = instrument
    })

    return instrumentsById
}

interface PortfolioDataProps {
    portfolio: Response.PriceNotificationsForPortfolios['portfolios'][0]
    portfolioIds: string
}

const PortfolioPriceNotifications: React.FC<PortfolioDataProps> = ({portfolio, portfolioIds}) => {
    const priceNotificationInstrumentIds = getInstrumentIds([portfolio])
    const priceNotificationInstruments = useInstruments(priceNotificationInstrumentIds)
    const instrumentsById = getInstrumentsById(priceNotificationInstruments)

    return (
        <div className={spacing.spaceAbove40}>
            <h2 className={styles.portfolioHeading}>{portfolio.name}</h2>
            <div>
                {portfolio.price_notifications.map((priceNotification, key) => {
                    const instrument = instrumentsById[priceNotification.fund_id]
                    return (
                        <InstrumentRowCompact
                            key={key}
                            instrument={instrument}
                            dataTestId={`price-notification--${instrument.id}`}
                            mainTitleOverride={
                                <>
                                    {instrument.name}{' '}
                                    {instrument.symbol && instrument.exchange && (
                                        <>
                                            ({instrument.symbol}:{instrument.exchange})
                                        </>
                                    )}
                                </>
                            }
                            subTitleOverride={
                                <>
                                    Set to $
                                    {formatNumberWithThousandsSeparator(priceNotification.price_threshold || '0')}{' '}
                                    {instrument.currency.toUpperCase()} on{' '}
                                    {priceNotification.created.toFormat(dateFormatNoTime)}
                                </>
                            }
                            rowTopBorder
                            rightSlot={
                                <EditNotification
                                    priceNotification={priceNotification}
                                    portfolioId={portfolio.id}
                                    instrument={instrument}
                                />
                            }
                        />
                    )
                })}
            </div>
            <DeleteNotifications portfolioId={portfolio.id} forPortfoliosIds={portfolioIds} />
        </div>
    )
}

const PriceNotifications: React.FC = () => {
    const profileUrl = useProfileUrl()
    const profile = useProfile()
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)
    const portfolioIds = getPortfolioIds(profile.portfolios).join(',')

    const portfolios = useRetailGet({
        path: 'price-notification/for-portfolios',
        payload: {
            portfolio_ids: portfolioIds,
        },
    }).data.portfolios

    const priceNotificationInstrumentIds = getInstrumentIds(portfolios)
    const notificationsToDisplay = priceNotificationInstrumentIds.length > 0

    const yourOrDependents = isDependent ? `${preferredName}’s` : 'your'
    const intro =
        priceNotificationInstrumentIds.length > 0
            ? `You can receive a notification when an investment on ${yourOrDependents} Watchlist or in your portfolio reaches a price you set.`
            : `Receive a notification when an investment on ${yourOrDependents} Watchlist or portfolio reaches a price you set.`

    return (
        <>
            <Toolbar dataTestId="toolbar--price-notification-settings" leftButton="back" title="Price notifications" />
            <Page>
                <p>{intro}</p>
                {portfolios.map((portfolio, key) => {
                    if (portfolio.price_notifications.length === 0) {
                        return null
                    }
                    return <PortfolioPriceNotifications key={key} portfolio={portfolio} portfolioIds={portfolioIds} />
                })}

                {!notificationsToDisplay && (
                    <div
                        className={cn(styles.tryPriceNotifications, spacing.spaceAbove24)}
                        data-testid="price-notification--try-price-notifications"
                    >
                        <p className={spacing.spaceBelow12}>
                            Try these out using the <b>Set a price notification</b> option on an investment.
                        </p>

                        <p>
                            <Link to={profileUrl('invest/search')}>Search for an investment</Link>
                        </p>
                    </div>
                )}
            </Page>
        </>
    )
}

export default () => {
    const profileUrl = useProfileUrl()
    const profile = useProfile()

    if (profile.legacy_profile_type === undefined) {
        return <Navigate to={profileUrl('')} replace />
    }

    return <PriceNotifications />
}
