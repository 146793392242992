import {SelectCard} from '@design-system/select-cards'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'

export const AccountNeedsVerification: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const [activeCard, setActiveCard] = React.useState('')

    const handleChange = (card: string) => {
        setActiveCard(card)

        if (card === 'topup') {
            navigate(profileUrl('wallet/bank-transfer'))
        } else {
            navigate(profileUrl('invest/portfolio-transfer-shares/us/address-verification'))
        }
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--transfer-verify-account" leftButton="back" />
            <Page>
                <h1 className={cn(commonStyles.heading, spacing.spaceBelow24)}>Your account needs to be verified</h1>
                <p className={spacing.spaceBelow24}>
                    We need to verify your identity before you can request a share transfer. You can do this by doing
                    one of the following.
                </p>
                <SelectCard
                    dataTestId="select-card--topup"
                    isActive={activeCard === 'topup'}
                    name="topup"
                    onChange={() => handleChange('topup')}
                    title="Top up Wallet (any amount from 1c) using bank transfer"
                    value="topup"
                />
                <SelectCard
                    dataTestId="select-card--address"
                    isActive={activeCard === 'address'}
                    name="address"
                    onChange={() => handleChange('address')}
                    title="Upload proof of address"
                    value="address"
                />
            </Page>
        </>
    )
}

export default AccountNeedsVerification
