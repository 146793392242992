import React from 'react'
import {Model} from '~/api/retail/types'
import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {useExchangeFeeRate} from '~/global/utils/exchange-fee-hooks/exchangeFeeHooks'
import {WalletBalanceWithIconWidget} from '~/global/widgets/wallet-balances/WalletBalances'
import styles from '~/sections/OLD_wallet/Wallet.scss'
import {RootState} from '~/store/types'

export const sortCurrencies = (homeCurrency: Model.User['home_currency'], currencies: Currency[]) => [
    homeCurrency,
    ...currencies.filter(currency => currency !== homeCurrency).sort(),
]

interface WalletBreakdownProps {
    homeCurrency: Model.User['home_currency']
    walletBalances: Model.User['wallet_balances']
    exchangeRates: RootState['accounting']['exchangeRates']
    usEquitiesEnabled: boolean
    showAuCurrency: boolean
}

export const WalletBreakdown: React.FunctionComponent<WalletBreakdownProps> = ({
    homeCurrency,
    walletBalances,
    exchangeRates,
    usEquitiesEnabled,
    showAuCurrency,
}) => {
    const currencies = sortCurrencies(homeCurrency, Object.keys(walletBalances) as Currency[])
    const exchangeFeeRate = useExchangeFeeRate()

    const shouldShowAuCurrencyRow = () => {
        return showAuCurrency || Number(walletBalances.aud) > 0 ? true : false
    }

    return (
        <ol className={styles.walletList}>
            {currencies.map(currency => {
                if (currency === 'aud' && !shouldShowAuCurrencyRow()) {
                    // this is temporary, when ASX is launched we will remove this flag
                    return null
                }
                if (currency === 'usd' && !usEquitiesEnabled) {
                    return null
                }
                return (
                    <WalletBalanceWithIconWidget
                        key={`${currency}`}
                        sourceCurrency={currency}
                        targetCurrency={homeCurrency}
                        walletBalances={walletBalances}
                        exchangeRates={exchangeRates}
                        exchangeFeeRate={exchangeFeeRate}
                    />
                )
            })}
        </ol>
    )
}

export default WalletBreakdown
