import Decimal from 'decimal.js'

export const feeCoverage = (coverageUsed: Decimal, expectedFee?: string) => {
    // If the expected fee is zero, fully covered
    if (expectedFee && new Decimal(expectedFee).equals(0)) {
        return 'full'
    }

    // If no coverage used, not covered at all
    if (coverageUsed.lessThanOrEqualTo(0)) {
        return undefined
    }

    // If there was some coverage, but still a fee, partially covered
    return 'partial'
}
