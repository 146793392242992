import {CheckSingle} from '@design-system/icon'
import React from 'react'
import {formatNumber} from '~/global/utils/format-number/formatNumber'
import styles from './EstimatePremiumRows.scss'

export const EstimatePremiumRows: React.FunctionComponent<{
    isAnnual: boolean
    value: string
}> = ({isAnnual, value}) => {
    return (
        <div className={styles.rowWrapper}>
            <div className={styles.row}>
                <p>{isAnnual ? 'Annual premium' : 'Monthly premium'}</p>
                <p className={styles.value}>${formatNumber({number: value, decimalPlaces: 2})}</p>
            </div>

            <div className={styles.smallRow}>
                <CheckSingle size={16} />
                <p className={styles.smallText}>5% Sharesies discount</p>
            </div>
            <div className={styles.smallRow}>
                <CheckSingle size={16} />
                <p className={styles.smallText}>First month free (up to $100)</p>
            </div>
            {isAnnual && (
                <div className={styles.smallRow}>
                    <CheckSingle size={16} />
                    <p className={styles.smallText}>Annual policy discount</p>
                </div>
            )}
        </div>
    )
}
