import {ModalLink} from '@design-system/modal'
import React from 'react'

const Citizenship = () => (
    <ModalLink
        dataTestId="modal-link--citizenship"
        label="Citizenship"
        asIcon
        modalTitle="Citizenship"
        primaryButton={{label: 'Ok'}}
        helpIconSize={16}
    >
        <p>
            We need to know your countries of citizenship to complete your US tax form. If you’re a ‘US person’ you'll
            complete a W-9 form instead of a W-8BEN form.
        </p>

        <p>
            <h3>Who is a US person?</h3>
            Generally, anyone born in the US, who is a naturalised citizen, or anyone who is a US Green Card holder is
            considered a US person (unless you’ve renounced your US citizenship).
        </p>

        <p>
            The rules of US citizenship are complex, so if you’re unsure we recommend you check out the{' '}
            <a
                href="https://www.irs.gov/individuals/international-taxpayers/classification-of-taxpayers-for-us-tax-purposes"
                target="_blank"
                rel="noopener"
            >
                IRS website
            </a>
            .
        </p>
    </ModalLink>
)

export default Citizenship
