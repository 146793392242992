import React from 'react'
import {ArticleType} from '~/api/contentful/types'
import {useArticle} from '~/api/query/contentful'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {RichText} from '~/sections/explore/sections/learn-articles/widgets/rich-text/RichText'
import styles from './ArticlePage.scss'

const CLICK_TRACK_KEY = 'click-track'

/**
 * `setupLinkTracking` adds click listeners to all anchor links in the article body so we can track what people are clicking on
 * Using addEventListener means the event will fire reliable as using React onClick isn't guaranteed to fire
 */
function setupLinkTracking(): void {
    // Get articleBody
    const articleBodyElement = document.getElementById('body')

    if (!articleBodyElement) {
        return
    }

    const handleClick = (mouseEvent: MouseEvent) => {
        const anchorElement = mouseEvent.currentTarget as HTMLAnchorElement

        const url = anchorElement.href
        const label = anchorElement.innerText

        if (!url) {
            return
        }

        rudderTrack('articles', 'action', {
            url,
            label,
        })
    }

    const anchorElements = articleBodyElement.querySelectorAll('a')

    anchorElements.forEach(anchorElement => {
        // If anchor element already has our data attribute then early exit
        if (anchorElement.getAttribute(CLICK_TRACK_KEY)) {
            return
        }

        // Set CLICK_TRACK_KEY to 't' so we don't add more click listeners
        anchorElement.setAttribute(CLICK_TRACK_KEY, 't')
        anchorElement.addEventListener('click', handleClick)
    })
}

interface ArticlePageProps {
    article?: ArticleType
}

export const ArticlePageInner = ({article}: ArticlePageProps): JSX.Element | null => {
    React.useEffect(() => {
        if (!article) {
            return
        }

        setupLinkTracking()
    }, [article])

    React.useEffect(() => {
        const hash = window.location.hash

        if (!hash) {
            return
        }

        // Handle smooth scrolling using a hash param from outside the page
        const targetEl = document.querySelector(hash)

        if (!targetEl) {
            return
        }

        // Put the scroll into the next run loop so React has finished updating
        // the DOM
        const timeoutId = setTimeout(() => {
            targetEl.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }, 0)

        return () => {
            clearTimeout(timeoutId)
        }
    }, [])

    if (!article) {
        // Loading and errors are handled in suspense boundaries
        return null
    }

    return (
        <article data-cms-id={article.id}>
            <div className={styles.headerContainer}>
                <Toolbar leftButton="back" dataTestId="toolbar--article-back" />

                <div className={styles.header}>
                    {/* Content */}
                    <div className={styles.headerContent}>
                        <h1 className={styles.title}>{article.title}</h1>

                        {/* <div className={styles.categories}>
                                <CategoryLabels categories={tags} />
                            </div> */}

                        {/* Intro content */}
                        {article.intro && (
                            <div className={styles.intro}>
                                <RichText richTextDocument={article.intro} />
                            </div>
                        )}

                        {/* {!!authors.length && (
                                <div className={styles.authors}>
                                    <Authors authors={authors} />
                                </div>
                            )} */}

                        {/* Meta content */}
                        {/* <div className={styles.meta}>
                                <ArticleMeta
                                    publishedDate={publishDate}
                                    timeToReadMins={timeToReadMins}
                                />
                            </div> */}
                    </div>

                    {/* Image */}
                    <div className={styles.imageContainer}>
                        <img className={styles.image} src={article.image?.src} />
                    </div>
                </div>
            </div>

            {/* Body */}
            <div className={styles.content} id="body">
                <div className={styles.contentContainer}>
                    {/* Body content */}
                    <div className={styles.body}>
                        <RichText richTextDocument={article.body} />
                    </div>
                </div>
            </div>
        </article>
    )
}

export const ArticlePage = ({slug}: {slug: string}): JSX.Element => {
    const article = useArticle(slug)
    return <ArticlePageInner article={article} />
}
