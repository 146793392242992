import * as api from '~/api/retail'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {getJurisdiction} from '../identity/selectors'
import {createAction, ActionsUnion} from '../redux-tools'
import {ThunkAction} from '../types'

const actions = {
    SetInterestRate: (rate: string) => createAction('save.SetInterestRate', {rate}),
}

const thunkActions = {
    getInterestRate(): ThunkAction<void> {
        return async (dispatch, getState) => {
            const response = await api.get('save/get-rate', {jurisdiction: getJurisdiction(getState())})
            switch (response.type) {
                case 'save_interest_rate':
                    dispatch(actions.SetInterestRate(response.interest_rate))
                    break
                case 'mobile_save_rate_error':
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
}

export type ActionsType = ActionsUnion<typeof actions>

export default {...actions, ...thunkActions}
