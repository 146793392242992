/**
 * This file was used during the transition to react-router-dom v6.x
 *
 * Now that the migration has happened, this file is just proxying a bunch of
 * imports from 'react-router-dom' and providing the transitional navigation
 * directive stuff below.
 */
import {NavigateFunction, NavigateOptions} from 'react-router'
import {ValidProfilePaths, useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {PathParamKeys, PathParams} from './global/utils/routing/routing'

/**
 * @deprecated import from `react-router-dom` instead
 */
export {Navigate, Link, useNavigate, useLocation, useSearchParams} from 'react-router-dom'

/**
 * @deprecated avoid making new Thunk actions that perform navigation
 *
 * Navigation directive is a migration strategy for moving navigation requests out
 * of thunk actions.
 *
 * The basic idea is that because we can't access the necessary router bits inside
 * redux actions any more but we want to make absolutely minimal changes to do the
 * router upgrade, we create the notion of a "navigation directive" which thunk
 * actions can return, and then the caller is responsible for executing (since they
 * have access to the router).
 *
 * Longer term we shouldn't need this as we're gradually phasing out the Redux store.
 */
const navigationDirective = Symbol('navigationDirective')

export interface NavigationDirective {
    [navigationDirective]: true
    execute(
        navigateFunction: NavigateFunction,
        profileUrl: ReturnType<typeof useProfileUrl>,
        optionOverrides?: NavigateOptions,
    ): void
}

/**
 * @deprecated avoid making new Thunk actions that perform navigation
 *
 * Migration strategy for allowing existing navigate hooks to function inside Thunk actions.
 *
 * Inside your thunk action:
 *
 * ```
 * return generateNavigationDirective(urlFor('somepage'))
 * ```
 *
 * Then later when you're using the action:
 *
 * ```
 * const navigate = useNavigate()
 * const response = await actions.myThunkAction()
 * if (isNavigationDirective(response)) {
 *     response.execute(navigate)
 * }
 * ```
 */
export const generateNavigationDirective = <P extends ValidProfilePaths>(
    path: P,
    options?: NavigateOptions,
    ...args: PathParamKeys<P> extends undefined ? [] : [PathParams<P>]
): NavigationDirective => {
    return {
        [navigationDirective]: true,
        execute: (navigate, profileUrl, optionOverrides) =>
            navigate(profileUrl(path, ...args), {...(options ?? {}), ...(optionOverrides ?? {})}),
    }
}

/**
 * @deprecated avoid making new Thunk actions that perform navigation
 *
 * Migration strategy for allowing existing navigate hooks to function inside Thunk actions.
 *
 * Inside your thunk action:
 *
 * ```
 * return generateNavigationDirective(urlFor('somepage'))
 * ```
 *
 * Then later when you're using the action:
 *
 * ```
 * const navigate = useNavigate()
 * const response = await actions.myThunkAction()
 * if (isNavigationDirective(response)) {
 *     response.execute(navigate)
 * }
 * ```
 */
export const isNavigationDirective = (obj: unknown): obj is NavigationDirective => {
    if (!obj || typeof obj !== 'object') {
        return false
    }
    return navigationDirective in obj
}
