import {Button} from '@design-system/button'
import React from 'react'
import {Loading} from '~/global/widgets/loading'
import Animation, {getAnimationData} from '~/global/widgets/lottie-animation/Animation'
import Page from '~/global/widgets/page/Page'
import styles from '~/sections/user/sections/sign-up/SignUp.scss'
import SignupToolbar from '~/sections/user/sections/sign-up/widgets/signup-toolbar/SignupToolbar'
import {connect} from '~/store/connect'

const IdentityVerificationSuccess: React.FunctionComponent<IdentityVerificationProps> = ({
    isDependent,
    preferredName,
    onNext,
}) => {
    const [animData, setAnimData] = React.useState<object>()

    React.useEffect(() => {
        getAnimationData('confirmation').then(setAnimData)
    }, [])

    if (!animData) {
        return <Loading isPineapple />
    }

    return (
        <>
            <SignupToolbar />
            <Page>
                <div className={styles.splashCenterVertically}>
                    <Animation className={styles.centered} animationData={animData} loop={false} />
                    <h2>We’ve verified {isDependent ? preferredName : `your identity, ${preferredName}`}</h2>
                </div>
                <Button pageButton label="Next" onClick={onNext} dataTestId="button--identity-success-next" />
            </Page>
        </>
    )
}

interface StateProps {
    preferredName: string
    isDependent: boolean
}

interface OwnProps {
    onNext(): void
}

type IdentityVerificationProps = StateProps & OwnProps

export default connect<StateProps, {}, OwnProps>(({identity}) => ({
    isDependent: identity.user ? identity.user.is_dependent : false,
    preferredName: identity.user ? identity.user.preferred_name : '',
}))(IdentityVerificationSuccess)
