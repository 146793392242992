import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {distillApiNewClientToken, Middleware} from '~/api/distill'
import * as rollbar from '~/api/rollbar/rollbar'

export enum DistillScope {
    FONTERRA = 'fonterra',
    KIWISAVER_SELF_SELECT_FUNDS = 'kiwisaver-self-select',
    KIWISAVER_BASE_FUNDS = 'kiwisaver-base',
    KIWISAVER_ALL_FUNDS = 'kiwisaver',
    INVEST = 'invest',
}

/**
 * Factory for Tanstack Query's {@link https://tanstack.com/query/latest/docs/react/reference/useQuery useQuery}
 * for use with Distill (with the V2 token), with sensible default options and type safety.
 *
 * This factory should be called from a custom hook which you then call in the top level of a component.
 * Set it up as below and you can access the data returned immediately as loading and error states
 * are handled by the suspense context and error boundary.
 *
 * First set up the custom hook:
 * ```
 * export const useDistillInstrumentInfo = distillGetFactory({
 *   apiFunctionName: 'apiV1InstrumentsInfoV2Get',
 * })
 * ```
 *
 * Then use it in your component:
 * ```
 * const {data: myVariableName} = useDistillInstrumentInfo({scope: KiwiSaverScope.ALL_FUNDS, searchFundInvestments: true})
 * ```
 *
 * Alternatively you can use a pattern where you do some post-processing on the data in your
 * custom hook, or pre-processing on the passed in values:
 *
 * ```
 * export const useDistillInstrumentInfo = ({isUnderlyingInstrument}: {isUnderlyingInstrument?: boolean}) => { // typing must be manually added
 *     const {data} = distillGetFactory({
 *         apiFunctionName: 'apiV1InstrumentsInfoV2Get',
 *     })({
 *         scope: KiwiSaverScope.ALL_FUNDS, // hardcoded value, not configurable by consumer
 *         searchFundInvestments: !!isUnderlyingInstrument, // dynamic value per passed args
 *     })
 *     // post processing on data could be added here before returning the mutated data
 *     return data
 * }
 * ```
 *
 * When the data is returned directly like this (without other tanstack query returns) you use it
 * directly in your component like so:
 *
 * ```
 * const distillMetadata = useDistillInstrumentInfo({isUnderlyingInstrument: true})
 * ```
 *
 * You can also pass an `options` object if you want to customise any of the underlying useQuery's
 * behaviour, for example if your get request need custom error handling you can set `useErrorBoundary`
 * to false, wrap the distillGetFactory in a try catch and use the returned `error` as you need.
 */
export const distillGetFactory = <
    MethodName extends keyof typeof distillApiNewClientToken.instrumentsApiNewClientToken,
    Params extends Exclude<
        Parameters<(typeof distillApiNewClientToken.instrumentsApiNewClientToken)[MethodName]>[0],
        Middleware
    >,
    Response extends Awaited<ReturnType<(typeof distillApiNewClientToken.instrumentsApiNewClientToken)[MethodName]>>,
    ApiFunction extends (params: Params) => Promise<Response>, // manually type this so we don't get interference from the BaseAPI type
    Options extends Omit<UseQueryOptions<Response>, 'queryFn'>, // exclude options that shouldn't be set
>(config: {
    apiFunctionName: MethodName
    options?: Options
}) => {
    return (params: Params) => {
        // the below breaks the rules of hooks as useQuery can't normally be called in a callback, but as this factory ALWAYS returns a hook we're safe to break the rules
        const useQueryResult = useQuery({
            suspense: true, // default to true but allow override if passed
            networkMode: 'offlineFirst', // we want to ensure tanstack always tries to make a query even if the browser says it's offline, this will cause a 'proper' fail and error message so our assertion that data always exists is true, see https://github.com/TanStack/query/discussions/4858
            queryKey: [config.apiFunctionName, params],
            cacheTime: 6 * 60 * 1000, // 6 minutes
            staleTime: 5 * 60 * 1000, // 5 minutes
            ...config.options,
            queryFn: () => {
                const apiFunction = distillApiNewClientToken.instrumentsApiNewClientToken[config.apiFunctionName].bind(
                    distillApiNewClientToken.instrumentsApiNewClientToken,
                ) as ApiFunction

                return apiFunction(params).catch((error: Error | Response) => {
                    if (error instanceof Response && error.status === 500) {
                        // for 500s, record to Rollbar
                        rollbar.sendError(`Failed XHR request: 'internal_server_error'`, {
                            url: config.apiFunctionName,
                            method: 'GET',
                        })
                    }
                    throw error // will fall to the error boundary unless options.useErrorBoundary is set to false, in which case you'll need to wrap your useDisillQuery in a try catch
                })
            },
        })
        return {
            ...useQueryResult,
            // because we use suspense, we can assert that the data isn't undefined.
            // see the discussion at https://github.com/TanStack/query/issues/1297 -
            // this wouldn't be true for some edge cases like calling queryClient.cancelQueries
            // but we can just avoid that edge case
            data: useQueryResult.data!,
        }
    }
}
