import React from 'react'
import {useNavigate} from 'react-router'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Loading} from '~/global/widgets/loading/Loading'
import {OrderCarousel} from '~/sections/invest/widgets/order-carousel/OrderCarousel'
import accountingActions from '~/store/accounting/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'

export const PendingInvestingActivityView: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const pendingInvestingActivityLoadingState = useAppSelector(s => s.accounting.pendingInvestingActivityLoadingState)
    const pendingInvestingActivityRecords = useAppSelector(s => s.accounting.pendingInvestingActivityRecords)

    // Load the pending orders if they're not already present in the store
    React.useEffect(() => {
        if (pendingInvestingActivityRecords.length === 0) {
            dispatch(accountingActions.FetchPendingInvestingActivity())
        }
    }, [])

    // Loading state
    if (pendingInvestingActivityLoadingState === 'loading') {
        return <Loading />
    }

    // Error state
    if (pendingInvestingActivityLoadingState === 'error') {
        return <WeSlippedUp />
    }

    // Redirect if customer has navigated here directly but has no pending activity
    if (pendingInvestingActivityRecords.length === 0) {
        navigate(profileUrl('invest'))
        return null
    }

    // Success! Render the content!
    return <OrderCarousel orders={pendingInvestingActivityRecords} carousel />
}
