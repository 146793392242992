import {ClassicCard} from '@braze/web-sdk'
import cn from 'classnames'
import React from 'react'
import {Navigate, Link, useNavigate, useLocation} from 'react-router-dom'
import {requestContentCardsRefresh, getCachedContentCards, logCardDismissal} from '~/api/braze/braze'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import headerStyles from '~/global/scss/reused-styles/pageHeader.scss'
import {usePortfolioItemsById} from '~/global/state-hooks/mixed-source/usePortfolioItemsById'
import {useHasOrderedOrHeldShares} from '~/global/state-hooks/rakaia/useHasOrderedOrHeldShares'
import {useReadPortfolio} from '~/global/state-hooks/rakaia/useReadPortfolio'
import {useActor} from '~/global/state-hooks/retail/useActor'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AccountVerificationRequired from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import AnnouncementDetail from '~/global/widgets/announcement-card/AnnouncementDetail'
import {ButtonCircle} from '~/global/widgets/button-circle/ButtonCircle'
import FeatureOnboardingPill from '~/global/widgets/feature-onboarding-pill/FeatureOnboardingPill'
import InfiniteLoadTrigger from '~/global/widgets/infinite-load-trigger/InfiniteLoadTrigger'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import TabControls from '~/global/widgets/tab-controls/TabControls'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import investmentsLarge from '~/sections/invest/sections/portfolio/assets/images/investments-large.png'
import PortfolioBreakdown from '~/sections/invest/sections/portfolio/widgets/breakdown/PortfolioBreakdown'
import PortfolioCorporateActionsCard from '~/sections/invest/sections/portfolio/widgets/corporate-actions-card/PortfolioCorporateActionsCard'
import FeaturedCards from '~/sections/invest/sections/portfolio/widgets/featured-cards/FeaturedCards'
import {
    PortfolioFilterAndSort,
    portfolioSortSwitch,
} from '~/sections/invest/sections/portfolio/widgets/filter-and-sort/PortfolioFilterAndSort'
import PortfolioFilterChips from '~/sections/invest/sections/portfolio/widgets/filter-and-sort/PortfolioFilterChips'
import PortfolioInvestmentsGrid from '~/sections/invest/sections/portfolio/widgets/investments-grid/PortfolioInvestmentsGrid'
import {PortfolioOverview} from '~/sections/invest/sections/portfolio/widgets/overview/PortfolioOverview'
import {PortfolioAiSearch} from '~/sections/invest/sections/portfolio/widgets/portfolio-ai-search'
import PortfolioWallet from '~/sections/invest/sections/portfolio/widgets/wallet-summary/PortfolioWallet'
import IdentityVerificationRequired from '~/sections/user/widgets/identity-verification-required/AlertIdentityVerificationRequired'
import {WalletPortfolio} from '~/sections/wallet/types'
import accountingActions from '~/store/accounting/actions'
import {State} from '~/store/accounting/types'
import {essViewsForPortfolio} from '~/store/employeeShareScheme/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import {applicationPointers} from '~/store/identity/selectors'
import instrumentActions from '~/store/instrument/actions'
import signUpActions from '~/store/sign-up/actions'
import {ActivityFeed} from './widgets/activity-feed'
import styles from './Portfolio.scss'

const PortfolioManagementControls: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const profileUrl = useProfileUrl()
    const profile = useProfile()

    const investPortfolio = profile.portfolios.find(p => p.product === 'INVEST')!
    const hasInvested = useHasOrderedOrHeldShares(investPortfolio.id)
    const essViews = useAppSelector(s => essViewsForPortfolio(s))

    // Include controls when the user hasEmployment so that ESS users that see
    // the basic version of the page still have a way to access the manage page.
    if (!essViews.hasEmployment && !hasInvested) {
        return null
    }

    return (
        <div className={styles.managementControls}>
            <ButtonCircle
                label="Buy"
                dataTestId="button--portfolio-buy"
                onClick={() => {
                    rudderTrack('navigation', 'feature_management_button_clicked', {
                        label: 'buy',
                        url: location.pathname,
                    })
                    navigate(profileUrl('invest/search'))
                }}
                icon="OrderBuy"
            />
            <ButtonCircle
                label="Manage"
                dataTestId="button--portfolio-manage"
                onClick={() => {
                    rudderTrack('navigation', 'feature_management_button_clicked', {
                        label: 'manage',
                        url: location.pathname,
                    })
                    navigate(profileUrl('invest/manage'))
                }}
                icon="MoreHorizontal"
            />
        </div>
    )
}

const Portfolio: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const profile = useProfile()
    const actor = useActor()

    const currentTab = useAppSelector(s => s.accounting.portfolioPageCurrentTab)

    const setPortfolioSortedInstrumentIds = (portfolioInstrumentIds: string[]) =>
        dispatch(instrumentActions.SetCurrentPortfolioSortedInstrumentIds(portfolioInstrumentIds))

    // From distill
    const currentPortfolioSortedInstrumentIds = useAppSelector(s => s.instrument.currentPortfolioSortedInstrumentIds)
    const instrumentLoadingState = useAppSelector(s => s.instrument.portfolioLoadingState)
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const instrumentListHasNextPage = useAppSelector(s =>
        s.instrument.currentPortfolioPage && s.instrument.numberOfPortfolioPages
            ? s.instrument.currentPortfolioPage !== s.instrument.numberOfPortfolioPages
            : false,
    )
    const loadedPortfolioItemsIndex = useAppSelector(s => s.instrument.portfolioIndex)
    const metadataInitialised = useAppSelector(s => s.instrument.isMetadataInitialised)

    // From retail-backend
    const investPortfolio = profile.portfolios.find(p => p.product === 'INVEST')!
    const includeSoldInvestments = useAppSelector(s => s.identity.includeSoldInvestments)
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const sortPreference = useAppSelector(s => s.identity.portfolioSortPreference)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)
    const userId = useAppSelector(s => s.identity.user?.id)
    const additionalVerificationRequiredReason = useAppSelector(
        s => s.identity.user!.checks.identity_verification.additional_verification_required_reason,
    )
    const applications = useAppSelector(applicationPointers)
    const usResignStatus = useAppSelector(s => s.signUp.usResignStatus)

    // From rakaia
    const portfolioSummary = useReadPortfolio(investPortfolio.id)
    const portfolioValue = portfolioSummary?.portfolio_value || 0

    // From mixed sources
    const totalPortfolioItems = usePortfolioItemsById()

    // For: Variables used for multiple child components or conditional rendering
    const portfolioInstrumentIds = Object.keys(totalPortfolioItems)
    const hasInvestments = portfolioInstrumentIds.length > 0
    const hasFilterAndSort = portfolioInstrumentIds.length > 1
    const hasOrderedOrHeld = useHasOrderedOrHeldShares(investPortfolio.id)
    const hasInvestingHistory = hasOrderedOrHeld && portfolioSummary.portfolio_history

    // For: sort related variables & functions
    const [hasSorted, setHasSorted] = React.useState<boolean>(false)
    const [sortedPortfolioItemIds, setSortedPortfolioItemIds] = React.useState<string[]>(
        currentPortfolioSortedInstrumentIds || portfolioInstrumentIds,
    )

    // For: Removing left button of toolbar when flyout menu locked by the native apps
    const hasLockedNavDrawer = useAppSelector(s => s.nav.drawerVisibilityLocked)

    const toolbarHiddenForMobile = useAppSelector(s => s.nav.toolbarHidden)

    const announcementCard = useAppSelector(s => s.identity.notifications.announcementCard) as ClassicCard
    const essViews = useAppSelector(s => essViewsForPortfolio(s))

    const hasSaveAccount = useAppSelector(s => Object.keys(s.identity.saveAccounts).length > 0)
    const hasKiwisaverAccount = useAppSelector(s => {
        const state = s.identity.user?.kiwisaver_customer_state
        return !!state && state !== 'INACTIVE'
    })
    const canViewLanding = !!hasSaveAccount || !!hasKiwisaverAccount

    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)
    const hasForeignCurrency =
        portfolioSummary.realised_currency_gain !== 0 || portfolioSummary.unrealised_currency_gain !== 0
    const jurisdictionNZ = useAppSelector(s => s.identity.user?.jurisdiction === 'nz')
    const greeting = jurisdictionNZ ? 'Kia ora' : 'Hey'

    const wallet = profile.portfolios.find(portfolio => portfolio.product === 'WALLET') as WalletPortfolio

    const handleChangeTab = (event: State['portfolioPageCurrentTab']) => {
        if (event === 'Activity') {
            rudderTrack('activity_feed', 'activity_feed_tab_selected')
        }
        if (event !== currentTab) {
            dispatch(accountingActions.SetPortfolioPageCurrentTab(event))
        }
    }

    const sortInstrumentTiles = () => {
        if (metadataInitialised) {
            const numExpectedInstruments = portfolioInstrumentIds.length
            const numLoadedInstruments = currentPortfolioSortedInstrumentIds?.length
            let sortedIds = portfolioInstrumentIds
            // For the initial load get the sorted ids from distill.
            // Conduct thorough testing of sorting - including kids accounts if you change this code!
            if (!hasSorted && sortPreference === 'ALPHABETICAL' && numExpectedInstruments !== numLoadedInstruments) {
                setPortfolioSortedInstrumentIds(portfolioInstrumentIds)
            } else {
                sortedIds = portfolioSortSwitch(
                    sortPreference,
                    instrumentsById,
                    sortedIds,
                    totalPortfolioItems,
                    includeSoldInvestments,
                )
            }
            setSortedPortfolioItemIds(sortedIds)
            setPortfolioSortedInstrumentIds(sortedIds)
            setHasSorted(true)
        }
    }

    const refreshContentCards = () =>
        requestContentCardsRefresh(
            () => {
                const cards = getCachedContentCards()

                if (cards) {
                    dispatch(identityActions.SetNotifications(cards))
                }
            },
            () => dispatch(identityActions.SetNotificationsLoadFail()),
        )

    // BEGIN useEffect ---------------------------------------------------------------------------------------------
    React.useEffect(() => {
        sortInstrumentTiles()
        dispatch(accountingActions.FetchRecentOrders())
        dispatch(signUpActions.getUSResignStatus())

        refreshContentCards()
    }, [profile.id])

    React.useEffect(() => {
        sortInstrumentTiles() // Re-apply sort when changing return values
    }, [includeSoldInvestments, sortPreference, userId])

    React.useEffect(() => {
        // don't try doing the load until after instrument metadata has loaded
        if (metadataInitialised) {
            // compare the IDs we know about from retail against the IDs we have loaded from the data service
            // we should only load portfolio instruments from distill when one / multiple retail ids are not
            // contained in `instrumentsById` (prev fetched instruments from distill)
            // otherwise we should use the existing instrument objects in the store, as to reduce complexity.
            const totalPortfolioHash = portfolioInstrumentIds.slice(0).sort().toString()
            const loadedInstrumentsHash = loadedPortfolioItemsIndex.slice(0).sort().toString()
            if (hasInvestments && totalPortfolioHash !== loadedInstrumentsHash) {
                dispatch(instrumentActions.loadPortfolioInstruments(portfolioInstrumentIds, false))
            } else if (totalPortfolioHash === loadedInstrumentsHash && hasSorted) {
                // only change instrument ids if we have performed a manual sort - otherwise ids will be in a random order (i.e. if we used distill to sort)
                dispatch(instrumentActions.SetPortfolioInstrumentIndex(sortedPortfolioItemIds))
            }
        }
    }, [hasInvestments, metadataInitialised, sortedPortfolioItemIds, sortPreference, userId])

    // END useEffect -------------------------------------------------------------------------------------------------

    // Force the investor to resign the W8 tax form if they're overdue.
    if (usResignStatus?.resign_overdue) {
        return <Navigate to={profileUrl('us-sign-up')} replace />
    }

    const leftToolbarButton = () => {
        // We want to show a 'back' arrow if customers can view the Landing page
        // even when using the mobile app.
        if (canViewLanding) {
            return 'back'
        }
        // We don't want to show a left button if customers canont view the Landing
        // and are using the mobile app
        if (!canViewLanding && hasLockedNavDrawer) {
            return undefined
        }
        // We want to show the menu button when customers cannot view the Landing
        // and are not using mobile app
        if (!canViewLanding && !hasLockedNavDrawer) {
            return 'menu'
        }
    }

    return (
        <>
            <div className={styles.background}>
                <Toolbar
                    dataTestId="toolbar--portfolio"
                    leftButton={leftToolbarButton()}
                    onLeftButtonClick={() => navigate(profileUrl(''))}
                    showNotifications
                />
            </div>
            <Page className={styles.hidePageScroll} withoutDefaultPadding>
                <div
                    className={cn(headerStyles.landingImageWrapper, styles.background)}
                    style={{paddingTop: toolbarHiddenForMobile ? '25px' : '0px'}}
                >
                    <img
                        src={investmentsLarge}
                        alt="investments account"
                        className={headerStyles.investmentsLandingImage}
                    />
                </div>
                <div className={styles.background}>
                    <div className={headerStyles.landingContentWrapper}>
                        <h1 data-testid="investments-title" className={headerStyles.title}>
                            Investments
                        </h1>
                        {hasInvestingHistory ? (
                            <div>
                                <p className={headerStyles.accountBalance}>
                                    <DollarValue value={portfolioValue} decimalPlaces={2} />
                                    <span className={headerStyles.accountBalanceCurrency}>
                                        {homeCurrency.toUpperCase()}
                                    </span>
                                </p>
                                {hasForeignCurrency && <p className={headerStyles.subTitle}>estimated value</p>}
                            </div>
                        ) : (
                            <>
                                {essViews.hasEmployment ? (
                                    <div className={styles.noInvestmentText}>
                                        {greeting}! Welcome to {isDependent ? `${preferredName}’s` : 'your'} investment
                                        portfolio
                                        <>
                                            . Check out {isDependent ? 'their' : 'your'} employee shares below, or{' '}
                                            <Link to={profileUrl('invest/search')}>
                                                explore the other investments on Sharesies
                                            </Link>
                                            .
                                        </>
                                    </div>
                                ) : (
                                    <div className={styles.noInvestmentTextVariant}>
                                        Hi {isDependent ? `${preferredName}’s` : preferredName},{' '}
                                        {parseFloat(wallet.balance) === 0 ? (
                                            <>
                                                {isDependent ? 'their' : 'your'} Wallet is empty. To start investing,{' '}
                                                <Link to={profileUrl('wallet')}>top up your Wallet</Link>
                                            </>
                                        ) : (
                                            <>
                                                you’ve got{' '}
                                                <strong>
                                                    <DollarValue
                                                        value={wallet.balance}
                                                        currency={actor.default_display_currency}
                                                    />
                                                </strong>{' '}
                                                in your Wallet ready to invest.{' '}
                                                <Link to={profileUrl('wallet')}>View Wallet</Link>
                                            </>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>

                {usResignStatus?.resign_due && (
                    <AlertCard className={styles.alertCard} type="warning" title="Action required">
                        <p>
                            Confirm {isDependent ? `${preferredName}’s` : 'your'} tax details for US investments, or{' '}
                            {isDependent ? 'they' : 'you'} may get taxed at a higher rate. <br />
                            <Link to={profileUrl('us-sign-up')}>Confirm your tax details</Link>
                        </p>
                    </AlertCard>
                )}

                <AccountVerificationRequired className={styles.addressVerification} />
                {additionalVerificationRequiredReason !== 'threshold_exceeded_restricted' && (
                    <IdentityVerificationRequired className={styles.identityVerification} />
                )}
                <PortfolioManagementControls />
                <PortfolioOverview />
                {applications.length > 0 && <PortfolioCorporateActionsCard />}
                <PortfolioWallet />
                <PortfolioAiSearch />

                {announcementCard && !canViewLanding && (
                    <AnnouncementDetail
                        isOpen={true}
                        onClose={() => {
                            logCardDismissal(announcementCard)
                            refreshContentCards()
                        }}
                        card={announcementCard}
                    />
                )}

                <FeatureOnboardingPill viewingOnProduct="portfolio" />

                <FeaturedCards />
                {!hasInvestments ? (
                    <></>
                ) : (
                    <>
                        <div className={styles.portfolioTabsWrapper}>
                            <TabControls
                                tabs={[
                                    {label: 'Investments', value: 'Investments'},
                                    {label: 'Activity', value: 'Activity'},
                                ]}
                                currentTab={currentTab}
                                onChangeTab={handleChangeTab}
                            />
                        </div>

                        {currentTab === 'Investments' && (
                            <div className={styles.investmentsWrapper}>
                                {hasFilterAndSort && (
                                    <>
                                        <PortfolioFilterAndSort />
                                        <PortfolioBreakdown />
                                        <PortfolioFilterChips />
                                    </>
                                )}
                                <PortfolioInvestmentsGrid />
                                {instrumentLoadingState === 'ready' && instrumentListHasNextPage && (
                                    <InfiniteLoadTrigger
                                        trigger={() =>
                                            dispatch(
                                                instrumentActions.loadNextPagePortfolioInstruments(
                                                    portfolioInstrumentIds,
                                                ),
                                            )
                                        }
                                    >
                                        <Loading />
                                    </InfiniteLoadTrigger>
                                )}
                            </div>
                        )}

                        {currentTab === 'Activity' && <ActivityFeed />}
                    </>
                )}
            </Page>
        </>
    )
}

export default Portfolio
