import cn from 'classnames'
import React from 'react'
import {AnnouncedCorporateAction} from '~/global/state-hooks/retail/useAnnouncedCorporateActions'
import {convertDateTime} from '~/global/utils/convert-date-time/convertDateTime'
import detailModalStyles from '~/global/widgets/detail-modals/DetailModal.scss'
import {DividendValue} from '~/global/widgets/number-elements/NumberElements'

interface UpcomingCorporateActionProps {
    corporateAction: AnnouncedCorporateAction
    className?: string
}
export const AnnouncedCACard: React.FC<UpcomingCorporateActionProps> = ({corporateAction, className}) => {
    const cashOutcome = corporateAction.outcomes.find(o => o.currency && parseFloat(o.amount_per_input_unit) > 0)
    return (
        <div className={cn(detailModalStyles.calcBox, className)}>
            <table>
                <tbody>
                    <tr>
                        <th>Ex dividend date</th>
                        <td>{convertDateTime(corporateAction.ex_date)} </td>
                    </tr>
                    <tr>
                        <th>Record date</th>
                        <td>{convertDateTime(corporateAction.record_date)}</td>
                    </tr>
                    <tr>
                        <th>Payment date</th>
                        <td>{convertDateTime(corporateAction.settlement_date)}</td>
                    </tr>
                    {cashOutcome && (
                        <tr>
                            <th>Dividend per share</th>
                            <td>
                                <DividendValue
                                    value={cashOutcome.amount_per_input_unit}
                                    currency={cashOutcome.currency!}
                                />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}
