import {
    Toolbar as DSToolbar,
    ToolbarButton as DSToolbarButton,
    ToolbarProps as DSToolbarProps,
    ToolbarButton,
} from '@design-system/toolbar'
import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {urlFor} from '~/global/routeGenerator'
import {Profile, useProfileOrNull} from '~/global/state-hooks/retail/useProfile'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {useShowIntercom} from '~/global/utils/use-show-intercom/useShowIntercom'
import {NotificationButton} from '~/global/widgets/notification-bell/NotificationButton'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import navActions from '~/store/nav/actions'
import styles from './Toolbar.scss'

export {ToolbarButton} // centralise all toolbar functionality through this one widget file

interface LeftButtonProps {
    leftButton: ToolbarProps['leftButton']
    onLeftButtonClick?: ToolbarProps['onLeftButtonClick']
    isConstantWhite?: ToolbarProps['isConstantWhite']
    openMenu: () => void
}

interface ToolbarProps {
    dataTestId: DSToolbarProps['dataTestId']
    additionalClassName?: DSToolbarProps['additionalClassName']
    isConstantWhite?: DSToolbarProps['isConstantWhite']
    leftButton?: 'menu' | 'back' | 'splash' | 'settings' | 'close'
    onLeftButtonClick?: () => void
    title?: DSToolbarProps['title']
    isInlineTitle?: DSToolbarProps['isInlineTitle']
    leftSlot?: DSToolbarProps['leftSlot']
    rightSlot?: DSToolbarProps['rightSlot']
    hideIntercom?: boolean
    showNotifications?: boolean
}

const LeftButton = ({leftButton, isConstantWhite, openMenu, onLeftButtonClick}: LeftButtonProps) => {
    const navigate = useNavigate()
    const location = useLocation()
    const profile = useProfileOrNull()
    const dataTestId = 'toolbar-left-button'

    switch (leftButton) {
        case 'menu':
            return (
                <DSToolbarButton
                    dataTestId={dataTestId}
                    isConstantWhite={isConstantWhite}
                    icon="menu"
                    onClick={openMenu}
                    additionalClassName={styles.hideOnDesktop}
                />
            )
        case 'back':
            return (
                <DSToolbarButton
                    dataTestId={dataTestId}
                    isConstantWhite={isConstantWhite}
                    icon="back"
                    onClick={
                        onLeftButtonClick
                            ? onLeftButtonClick
                            : () => {
                                  if (location.key !== 'default') {
                                      // if there's a browser history https://stackoverflow.com/a/70532858
                                      navigate(-1)
                                  } else {
                                      // fall back if no browser history
                                      navigate('..')
                                  }
                              }
                    }
                />
            )
        case 'splash':
            return (
                <DSToolbarButton
                    dataTestId={dataTestId}
                    isConstantWhite={isConstantWhite}
                    icon="back"
                    iconTitle="Go back to Splash"
                    onClick={() => navigate('/')}
                />
            )
        case 'settings':
            assertProfileNotNull(profile, leftButton)
            return (
                <DSToolbarButton
                    dataTestId={dataTestId}
                    isConstantWhite={isConstantWhite}
                    icon="back"
                    iconTitle="Go back to Settings"
                    onClick={() => navigate(urlFor('profile/:profileSlug/settings', {profileSlug: profile.slug}))}
                />
            )
        case 'close':
            return (
                <DSToolbarButton
                    dataTestId={dataTestId}
                    isConstantWhite={isConstantWhite}
                    icon="close"
                    onClick={onLeftButtonClick}
                />
            )
        case undefined:
            return null
        default:
            assertNever(leftButton)
            return null
    }
}

/**
 * Toolbar that sits at the top of the page - use this instead of the Design
 * System toolbar directly, you can still override behaviour if you need but it
 * gives baked in Native App functionality and convenience shortcuts as standard.
 *
 * If you need to customise buttons, provide your own leftSlot or rightSlot args
 * that contain ToolbarButton components.
 *
 * @param {ToolbarProps} props - props
 * @param {ToolbarProps['additionalClassName']} props.additionalClassName - add additional CSS styling overrides
 * @param {ToolbarProps['dataTestId']} props.dataTestId - data test id for testing
 * @param {ToolbarProps['isConstantWhite']} props.isConstantWhite - if the toolbar will be displayed over a non neutral background
 * @param {ToolbarProps['leftButton']} props.leftButton - what type of button goes in the left side
 * @param {ToolbarProps['onLeftButtonClick']} props.onLeftButtonClick - optional callback for standard left button
 * @param {ToolbarProps['title']} props.title - optional title for the toolbar
 * @param {ToolbarProps['isInlineTitle']} props.isInlineTitle - optional flag to make the title be a small inline one in the toolbar itself. generally this styl is deprecated.
 * @param {ToolbarProps['leftSlot']} props.leftSlot - optional override for the left side of the toolbar for custom ToolbarButton components
 * @param {ToolbarProps['rightSlot']} props.rightSlot - optional override for the right side of the toolbar for custom ToolbarButton components
 * @param {ToolbarProps['hideIntercom']} props.hideIntercom - flag to hide the intercom button
 * @param {ToolbarProps['showNotifications']} props.showNotifications - flag to show the notifications indicator
 * @returns {React.Component} <Toolbar> component
 */
export const Toolbar = ({
    dataTestId,
    additionalClassName,
    leftButton,
    onLeftButtonClick,
    isConstantWhite,
    title,
    isInlineTitle,
    leftSlot,
    rightSlot,
    hideIntercom,
    showNotifications,
}: ToolbarProps) => {
    const showIntercom = useShowIntercom()
    const dispatch = useAppDispatch()
    const toolbarHiddenForMobile = useAppSelector(s => s.nav.toolbarHidden)

    React.useEffect(() => {
        if (title && toolbarHiddenForMobile) {
            // the native app will display the title in their toolbar
            sendWrapperAppMessage({type: 'setNativeToolbarTitle', title})
        }

        // reset on unmount
        return () => {
            if (title && toolbarHiddenForMobile) {
                sendWrapperAppMessage({type: 'setNativeToolbarTitle', title: ''})
            }
        }
    }, [title, toolbarHiddenForMobile])

    const openMenu = () => {
        dispatch(navActions.OpenDrawer())
    }

    if (toolbarHiddenForMobile) {
        // Do not show toolbar when mobile has set toolbar to be hidden
        return null
    }

    return (
        <DSToolbar
            dataTestId={dataTestId}
            additionalClassName={additionalClassName}
            isConstantWhite={isConstantWhite}
            title={title}
            isInlineTitle={isInlineTitle}
            leftSlot={
                leftSlot ? (
                    leftSlot
                ) : (
                    <LeftButton
                        leftButton={leftButton}
                        isConstantWhite={isConstantWhite}
                        openMenu={openMenu}
                        onLeftButtonClick={onLeftButtonClick}
                    />
                )
            }
            rightSlot={
                rightSlot ? (
                    rightSlot
                ) : (
                    <>
                        {showNotifications && <NotificationButton />}
                        {!hideIntercom && (
                            <DSToolbarButton
                                dataTestId={`${dataTestId}-right-button`}
                                isConstantWhite={isConstantWhite}
                                icon="help"
                                onClick={showIntercom}
                            />
                        )}
                    </>
                )
            }
        />
    )
}

function assertProfileNotNull(profile: Profile | null, leftButton: string): asserts profile is Profile {
    if (!profile) {
        throw new Error(
            `Cannot render <Toolbar leftButton="${leftButton}" /> outside of the profile route. The profile route ` +
                'is needed to determine which profile the left button links to.',
        )
    }
}
