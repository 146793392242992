import {Modal} from '@design-system/modal'
import cn from 'classnames'
import React from 'react'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import {Model, Response} from '~/api/retail/types'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {useBankLinkingAlert} from '~/global/state-hooks/retail/useBankLinkingAlert'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {humaniseBankAccount} from '~/global/utils/humanise-bank-account/humaniseBankAccount'
import {isUninitialised} from '~/global/utils/is-loading/isLoading'
import {useCurrentSubscriptionPlan} from '~/global/utils/subscription-hooks/subscriptionHooks'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {TrashOutline} from '~/global/widgets/OLD_icons'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import AccountsCardList from '~/global/widgets/credit-card-handling/accepted-cards-list/AccountsCardList'
import {ErrorBox} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {Link} from '~/migrate-react-router'
import roundupStyle from '~/sections/OLD_wallet/widgets/roundup-status/RoundupStatus.scss'
import actions from '~/store/accounting/actions'
import {State} from '~/store/accounting/types'
import bankLinkingActions from '~/store/bankLinking/actions'
import {State as BankLinkingState} from '~/store/bankLinking/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import planActions from '~/store/plan/actions'
import styles from './BankAccountsAndCards.scss'

interface LinkedBankRenderProps {
    bankLinkingState: BankLinkingState
}

const LinkedBankRender = ({bankLinkingState}: LinkedBankRenderProps) => {
    const profileUrl = useProfileUrl()
    const {bankLinkingLoadingState, roundupsDebitAccount, bankLinkingPending, isBankLinked} = bankLinkingState
    const alert = useBankLinkingAlert({context: 'settings/bank-accounts-cards'})

    const setUpBankLinking = () => (
        <>
            <div className={cn(spacing.spaceBelow8, spacing.spaceAbove12)}>
                <div>
                    <strong>
                        <Link
                            data-testid="link--add-linking-bank"
                            to={profileUrl('settings/bank-accounts-cards/roundups')}
                        >
                            Link your bank
                        </Link>
                    </strong>
                </div>
            </div>
        </>
    )

    const incompleteBankLinking = () => <></>

    const completedBankLinking = () => (
        <div className={styles.bankLinkingAccount}>
            <div className={styles.nameAndLogo}>
                <img src={roundupsDebitAccount!.bankLogo} />
                <p>{roundupsDebitAccount!.bankName}</p>
            </div>
            <strong>
                <Link
                    data-testid="link--edit-bank-linking"
                    to={profileUrl('settings/bank-accounts-cards/bank-linking/view')}
                >
                    Edit
                </Link>
            </strong>
        </div>
    )

    return (
        <div className={spacing.spaceBelow32}>
            <h2 className={cn(spacing.spaceBelow12, typographyOverrides['as-h2'])}>Linked Bank</h2>
            <p className={spacing.spaceBelow16}>
                Linking with your bank lets you access automated features in Sharesies such as round-ups or a recurring
                top-up.
            </p>
            {!alert ? null : (
                <AlertCard className={roundupStyle.alert} type={alert.type}>
                    {alert.title && <h2>{alert.title}</h2>}
                    {alert.content}
                </AlertCard>
            )}
            {bankLinkingLoadingState === 'loading' || bankLinkingLoadingState === 'uninitialised' ? (
                <Loading />
            ) : bankLinkingLoadingState === 'error' && !bankLinkingPending ? (
                <ErrorBox message="Could not load linked bank accounts" />
            ) : !isBankLinked ? (
                setUpBankLinking()
            ) : roundupsDebitAccount ? (
                completedBankLinking()
            ) : (
                incompleteBankLinking()
            )}
        </div>
    )
}

const BankAccountsRender = () => {
    const profileUrl = useProfileUrl()
    const owner = useProfileOwner()

    const [deleteModalShown, setDeleteModalShown] = React.useState(false)

    const accountData = useRetailGet({
        path: 'owner/:owner_id/bank-accounts',
        pathParams: {owner_id: owner.id},
    }).data
    const accounts: (
        | Response.OwnerBankAccounts['au_accounts'][number]
        | Response.OwnerBankAccounts['nz_accounts'][number]
    )[] = [...accountData.au_accounts, ...accountData.nz_accounts]

    const deleteBankAccount = useRetailPost({
        path: 'owner/:owner_id/bank-accounts/delete',
        pathParams: {owner_id: owner.id},
        queryCacheToUpdate: [`owner/:owner_id/bank-accounts`, {owner_id: owner.id}],
    })

    // information that needs to be stored and used in the delete modal
    const [deleteBankAccountNumber, setDeleteBankAccountNumber] = React.useState<string>()
    const [deleteBankAccountName, setDeleteBankAccountName] = React.useState<string>()
    const [deleteBankAccountId, setDeleteBankAccountId] = React.useState<string>()

    const notificationContext = React.useContext(NotificationContext)

    const doDeleteAccount = async (id: string) => {
        try {
            await deleteBankAccount.mutateAsync({
                bank_account_id: id,
            })
            Toast('Bank account deleted')
        } catch (e) {
            notificationContext.showModalError({message: 'An error occurred deleting your account, please try again'})
        } finally {
            setDeleteModalShown(false)
        }
    }

    return (
        <>
            <div>
                {owner.owner_type === 'PERSON' && (
                    <h2 className={cn(spacing.spaceBelow12, typographyOverrides['as-h2'])}>Bank accounts</h2>
                )}
                <p className={spacing.spaceBelow20}>
                    These are bank accounts you’ve manually transferred money from, or withdrawn to.
                </p>

                {accounts && (
                    <>
                        {accounts.map((account, key) => (
                            <div key={key}>
                                <div className={styles.account}>
                                    <div>
                                        {owner.product_jurisdiction === 'au' &&
                                            'bsb' in account &&
                                            humaniseBankAccount(account.bsb!, owner.product_jurisdiction) +
                                                ' ' +
                                                humaniseBankAccount(account.number, owner.product_jurisdiction)}
                                        {owner.product_jurisdiction === 'nz' &&
                                            humaniseBankAccount(account.number, owner.product_jurisdiction)}
                                    </div>
                                    <div data-testid="div--delete-bank-account" className={styles.deleteLink}>
                                        <TrashOutline
                                            onClick={() => {
                                                setDeleteBankAccountId(account.id)
                                                setDeleteBankAccountName(account.name)
                                                setDeleteBankAccountNumber(
                                                    humaniseBankAccount(account.number, owner.product_jurisdiction),
                                                )
                                                setDeleteModalShown(true)
                                            }}
                                        />
                                    </div>
                                    <div className={spacing.spaceAbove4}>{account.name}</div>
                                </div>
                                <div
                                    className={cn(
                                        key === accounts.length - 1 ? spacing.spaceBelow12 : spacing.spaceBelow32,
                                        spacing.spaceAbove12,
                                    )}
                                >
                                    <strong>
                                        <Link
                                            data-testid="link--edit-bank-account"
                                            to={profileUrl(
                                                'settings/bank-accounts-cards/edit-bank-account/:bank_account_id',
                                                {bank_account_id: account.id},
                                            )}
                                        >
                                            Edit
                                        </Link>
                                    </strong>
                                </div>
                            </div>
                        ))}
                        <div className={spacing.spaceBelow8}>
                            <div>
                                <strong>
                                    <Link
                                        data-testid="link--add-bank-account"
                                        to={profileUrl('settings/bank-accounts-cards/add-bank-account')}
                                    >
                                        {accounts.length > 0 ? 'Add another bank account' : 'Add bank account'}
                                    </Link>
                                </strong>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <Modal
                isAlert
                isOpen={deleteModalShown}
                setIsOpen={setDeleteModalShown}
                title="Delete bank account"
                dataTestId="modal--delete-bank-account"
                primaryButton={{
                    label: 'Delete bank account',
                    onClick: () => {
                        if (deleteBankAccountId) {
                            doDeleteAccount(deleteBankAccountId).then()
                        }
                    },
                }}
                secondaryButton={{label: 'Cancel'}}
            >
                {accounts.length > 0 && (
                    <div className={styles.modalContainerAccount}>
                        <p>{deleteBankAccountNumber}</p>
                        <p>{deleteBankAccountName}</p>
                    </div>
                )}
                <p>
                    <strong>Are you sure you want to delete this bank account?</strong>
                </p>
            </Modal>
        </>
    )
}

interface CardsRenderProps {
    cards: State['cards']
    cardsLoadingState: State['cardsLoadingState']
    deleteCard(): Promise<string | void>
    jurisdiction: Model.User['jurisdiction']
}

const CardsRender = (props: CardsRenderProps) => {
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const {cards, cardsLoadingState, deleteCard} = props
    const [currentSubscription] = useCurrentSubscriptionPlan()
    const [deleteModalShown, setDeleteModalShown] = React.useState(false)

    const notificationContext = React.useContext(NotificationContext)

    const doDeleteCard = async () => {
        try {
            const error = await deleteCard()

            if (!error) {
                Toast('Card deleted')

                if (currentSubscription?.payment_method === 'CARD') {
                    // Refetch current plan, it's payment method will have been switched to wallet.
                    dispatch(planActions.FetchCurrentPlan())
                }
            } else {
                notificationContext.showModalError({message: error})
            }
        } catch (e) {
            notificationContext.showModalError({message: 'An error occurred deleting your card, please try again'})
        } finally {
            setDeleteModalShown(false)
        }
    }

    return (
        <>
            <div>
                <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceAbove32, spacing.spaceBelow12)}>Cards</h2>

                {cardsLoadingState === 'loading' && <Loading />}

                {cardsLoadingState === 'error' && <ErrorBox message="Could not load cards" />}

                {cards && cards.length > 0 && (
                    <div className={styles.card}>
                        <AccountsCardList source={cards[0]} />
                        <div className={styles.deleteLink}>
                            <TrashOutline data-testid="icon--delete-card" onClick={() => setDeleteModalShown(true)} />
                        </div>
                    </div>
                )}

                {cards && cards.length === 0 && (
                    <>
                        <div className={spacing.spaceBelow8}>
                            <strong>
                                <Link to={profileUrl('settings/bank-accounts-cards/add-card')}>+ Add a card</Link>
                            </strong>
                        </div>
                        <p>Cards can be used to deposit to your Wallet.</p>
                    </>
                )}
            </div>
            <Modal
                isAlert
                isOpen={deleteModalShown}
                setIsOpen={setDeleteModalShown}
                title="Delete card"
                dataTestId="modal--delete-card"
                primaryButton={{
                    label: 'Delete card',
                    onClick: () => doDeleteCard(),
                }}
                secondaryButton={{label: 'Cancel'}}
            >
                {cards.length > 0 && (
                    <div className={styles.modalContainerCard}>
                        <AccountsCardList source={cards[0]} />
                    </div>
                )}

                {currentSubscription?.payment_method === 'CARD' && (
                    <p>
                        <strong>Pricing plan payments:</strong> This card is currently the payment method for your
                        pricing plan. Deleting this card will mean your plan payment method will change from credit card
                        to your Wallet.{' '}
                    </p>
                )}

                <p>Are you sure you want to delete these card details?</p>
            </Modal>
        </>
    )
}

export const BankAccountsCards: React.FunctionComponent = () => {
    const {cards, cardsLoadingState, bankLinkingState} = useAppSelector(({accounting, bankLinking}) => ({
        cards: accounting.cards,
        cardsLoadingState: accounting.cardsLoadingState,
        bankLinkingState: bankLinking,
    }))
    const owner = useProfileOwner()
    const dispatch = useAppDispatch()

    React.useEffect(() => {
        dispatch(actions.RefreshCards())

        if (isUninitialised(bankLinkingState.bankLinkingLoadingState) && owner.product_jurisdiction === 'au') {
            dispatch(bankLinkingActions.FetchBankLinkingStatus())
        }
    }, [])

    return (
        <>
            <Toolbar
                dataTestId="toolbar--bank-account-cards"
                leftButton="settings"
                title={owner.owner_type === 'PERSON' ? 'Bank accounts and cards' : 'Bank accounts'}
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                {owner.product_jurisdiction === 'au' && <LinkedBankRender bankLinkingState={bankLinkingState} />}
                <BankAccountsRender />
                {owner.owner_type !== 'ORGANISATION' && (
                    <CardsRender
                        jurisdiction={owner.product_jurisdiction}
                        cards={cards}
                        cardsLoadingState={cardsLoadingState}
                        deleteCard={() => dispatch(actions.DeleteCard())}
                    />
                )}
            </Page>
        </>
    )
}

export default BankAccountsCards
