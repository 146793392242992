import {Button} from '@design-system/button'
import {withFormik} from 'formik'
import {DateTime} from 'luxon'
import React from 'react'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {validate} from '~/global/widgets/form-controls'
import {Checkbox, Select} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import {WalletAmountInput} from '~/global/widgets/wallet-amount-input/WalletAmountInput'
import AccountSelect from '~/sections/save/sections/transfer/widgets/account-select/AccountSelect'
import styles from '~/sections/save/sections/transfer/widgets/transfer-form/TransferForm.scss'
import {intervalToString} from '~/sections/save/utils/interval-to-string/intervalToString'
import {StagedTransferOrder, TransferAccountDetails} from '~/store/save/types'

export interface FormProps {
    accounts: TransferAccountDetails[]
    additionalActionButton?: React.ReactNode
    defaultTargetAccount: TransferAccountDetails | undefined
    onSubmit: (values: FormValues) => void
    recurringByDefault: boolean
    stagedTransfer: StagedTransferOrder | undefined
}

interface initFormValues {
    amount: string
    interval?: StagedTransferOrder['interval']
    isRecurring: boolean
    originAccount?: TransferAccountDetails
    startDate?: string
    targetAccount?: TransferAccountDetails
}

export interface FormValues {
    amount: string
    interval?: StagedTransferOrder['interval']
    isRecurring: boolean
    originAccount: TransferAccountDetails
    startDate?: string
    targetAccount: TransferAccountDetails
}

const TransferForm = withFormik<FormProps, initFormValues>({
    mapPropsToValues: ({recurringByDefault, stagedTransfer, defaultTargetAccount}) => ({
        amount: stagedTransfer?.amount || '',
        interval: stagedTransfer?.interval,
        isRecurring: !!stagedTransfer?.interval || recurringByDefault,
        originAccount: stagedTransfer?.originAccount,
        startDate: stagedTransfer?.nextDate || DateTime.now().toISODate(),
        targetAccount: stagedTransfer?.targetAccount || defaultTargetAccount,
    }),
    handleSubmit: async (values, {props}) => {
        if (!values.isRecurring) {
            values.startDate = undefined
        }
        if (values.originAccount && values.targetAccount && values.amount) {
            props.onSubmit(values as FormValues)
        }
    },
    validate: values => {
        const formValues = values as FormValues
        const maxTransferAmount = values.originAccount?.balance || 0

        const schema = {
            amount: [validate.required(), validate.money({allowZero: false})],
            isRecurring:
                formValues.originAccount?.type === 'save' && formValues.targetAccount?.type === 'save'
                    ? [validate.isFalse('Recurring transfers are only available between Save account and Wallet')]
                    : [],
            interval: formValues.isRecurring ? [validate.required()] : [],
            originAccount: [
                validate.required(),
                validate.notSameValue(formValues.targetAccount, 'You must select two different accounts'),
            ],
            startDate: formValues.isRecurring ? [validate.required()] : [],
            targetAccount: [
                validate.required(),
                validate.notSameValue(formValues.originAccount, "You can't transfer to the same account"),
            ],
        }

        if (!values.startDate || values.startDate === DateTime.now().toISODate()) {
            schema.amount.push(
                validate.maximum(Number(maxTransferAmount), "You don't have enough money in your account"),
            )
        }

        return validate.generate<FormValues>(schema)(formValues)
    },
})(({
    accounts,
    additionalActionButton,
    defaultTargetAccount,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    setFieldTouched,
    setFieldValue,
    stagedTransfer,
    touched,
    values,
}) => {
    const handleSelectAccount = (selectedAccount: TransferAccountDetails, direction: 'To' | 'From') => {
        if (direction === 'To') {
            setFieldTouched('targetAccount', true)
            setFieldValue('targetAccount', selectedAccount)
        } else {
            setFieldTouched('originAccount', true)
            setFieldValue('originAccount', selectedAccount)
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Page>
                <AccountSelect
                    accountList={accounts}
                    currency="nzd"
                    direction="From"
                    handleSelectAccount={handleSelectAccount}
                    selectedAccount={values.originAccount}
                />
                <AccountSelect
                    accountList={accounts}
                    currency="nzd"
                    direction="To"
                    disabled={!!defaultTargetAccount}
                    errorMessage={touched.targetAccount ? errors.targetAccount : ''}
                    handleSelectAccount={handleSelectAccount}
                    selectedAccount={values.targetAccount}
                />
                <WalletAmountInput
                    dataTestId="input--save-transfer"
                    walletPortfolioId={accounts.find(account => account.type === 'wallet')!.portfolioId}
                    hideAvailable={values.originAccount?.type !== 'wallet'}
                    isForSave
                />
                <Checkbox
                    dataTestId="checkbox--save-make-recurring"
                    label="Make recurring"
                    name="isRecurring"
                    disabled={!!stagedTransfer?.meta?.recurringTransferId}
                    helpText={errors.isRecurring}
                />

                {values.isRecurring && (
                    <>
                        <Select
                            dataTestId="select--save-recurring-interval"
                            name="interval"
                            choices={[
                                {value: '', label: ''},
                                ...Object.entries(intervalToString).map(entry => ({
                                    value: entry[0],
                                    label: entry[1],
                                })),
                            ]}
                            label="Repeat"
                            placeholder="Select"
                        />
                        <label className={styles.copyFieldLabel}>Start date</label>
                        <input
                            value={values.startDate}
                            type="date"
                            className={styles.datePicker}
                            onChange={handleChange}
                            name="startDate"
                            id="startDate"
                            min={DateTime.now().toISODate()}
                        />
                    </>
                )}
            </Page>

            <ActionBar className={styles.actionBar}>
                <Button
                    dataTestId="button--transfer-review"
                    label="Review"
                    isSubmit
                    disabled={!isValid}
                    processing={isSubmitting}
                />
                {additionalActionButton}
            </ActionBar>
        </form>
    )
})

export default TransferForm
