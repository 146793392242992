import {CombinedColours} from '@design-system/colour-tokens'
import {Loader} from '@design-system/loader'
import cn from 'classnames'
import React from 'react'
import styles from './Loading.scss'

interface LoadingProps {
    className?: string
    colour?: CombinedColours
    isPineapple?: boolean
    useAvailableSpace?: boolean
    title?: string
    size?: number
}

/**
 * Loader spinner for use within a page. There is an optional isPineapple variant.
 *
 * @param {object} options - options
 * @param {string} options.className - optional additional CSS class to be added
 * @param {CombinedColours} options.colour - optional specific colour
 * @param {boolean} options.isPineapple - render the animated SHaresies pineapple if true
 * @param {boolean} options.useAvailableSpace - if set (or if isPineapple is set), the loader will try and use as much vertical space as is available underneath. if using this option, don't place elements underneath.
 * @param {string} options.title - optional accessible title. defaults to 'Loading' if not supplied
 * @param {number} options.size - optional size override - defaults to 28px for regular loader and 68px for isPineapple
 */
export const Loading = ({className, colour, isPineapple, useAvailableSpace, title, size}: LoadingProps) => {
    const containerRef = React.useRef<HTMLDivElement | null>(null)
    const [computedMinHeight, setComputedMinHeight] = React.useState<number | undefined>(undefined)
    const applyHeight = (isPineapple && useAvailableSpace !== false) || useAvailableSpace
    const pageElementPaddingBottom = 24 // if Loading is inside a Page element this ensures we don't push the page out

    React.useLayoutEffect(() => {
        if (containerRef.current && applyHeight) {
            const yPos = containerRef.current.getBoundingClientRect().top
            const viewportHeight = window.innerHeight

            setComputedMinHeight(viewportHeight - yPos - pageElementPaddingBottom)
        }
    }, [containerRef])

    return (
        <div
            ref={containerRef}
            data-testid="loading-spinner"
            aria-live="polite"
            className={cn(
                {
                    [styles.useAvailableSpace]: applyHeight,
                    [styles.isPineapple]: isPineapple,
                },
                styles.container,
                className,
            )}
            style={{
                minHeight: computedMinHeight,
            }}
        >
            <Loader isPineapple={isPineapple} colour={colour} title={title} size={size} />
        </div>
    )
}
