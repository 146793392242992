/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    PortfolioInvestingHistoryDetails,
    PortfolioInvestingHistoryDetailsFromJSON,
    PortfolioInvestingHistoryDetailsFromJSONTyped,
    PortfolioInvestingHistoryDetailsToJSON,
    PortfolioInvestingHistoryDetailsToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface PortfolioInvestingHistoryList
 */
export interface PortfolioInvestingHistoryList {
    /**
     *
     * @type {{ [key: string]: PortfolioInvestingHistoryDetails; }}
     * @memberof PortfolioInvestingHistoryList
     */
    history: {[key: string]: PortfolioInvestingHistoryDetails}
}

export function PortfolioInvestingHistoryListFromJSON(json: any): PortfolioInvestingHistoryList {
    return PortfolioInvestingHistoryListFromJSONTyped(json, false)
}

export function PortfolioInvestingHistoryListFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): PortfolioInvestingHistoryList {
    if (json === undefined || json === null) {
        return json
    }
    return {
        history: mapValues(json['history'], PortfolioInvestingHistoryDetailsFromJSON),
    }
}

export function PortfolioInvestingHistoryListToJSON(value?: PortfolioInvestingHistoryList | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        history: mapValues(value.history, PortfolioInvestingHistoryDetailsToJSON),
    }
}
