import {assertNever} from '~/global/utils/assert-never/assertNever'
import {ActionsType} from './actions'
import {State} from './types'

const initialState: State = {
    drawerOpen: false,
    flyoutVisible: true,
    drawerVisibilityLocked: false,
    toolbarHidden: false,
}

function reducer(state: State = initialState, action: ActionsType): State {
    switch (action.type) {
        case 'nav.OpenDrawer':
            return {...state, drawerOpen: true}
        case 'nav.CloseDrawer':
            return {...state, drawerOpen: false}
        case 'nav.ShowFlyoutMenu':
            // Don't update state when locked
            if (state.drawerVisibilityLocked) {
                return state
            } else {
                return {...state, flyoutVisible: true}
            }
        case 'nav.HideFlyoutMenu':
            if (state.drawerVisibilityLocked) {
                return state
            } else {
                return {...state, flyoutVisible: false}
            }
        case 'nav.LockFlyoutVisibility':
            return {...state, drawerVisibilityLocked: true}
        case 'nav.HideToolbar':
            return {...state, toolbarHidden: true}
        case 'nav.ShowToolbar':
            return {...state, toolbarHidden: false}
        default:
            assertNever(action)
    }
    return state
}

export default reducer
