import React from 'react'
import {useInstruments} from '~/global/utils/use-instruments/useInstruments'
import actions from '~/store/autoinvest/actions'
import {EnhancedAutoinvestOrder, selectEnhancedAutoinvestOrder} from '~/store/autoinvest/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'

export const useAutoinvestOrders = (): [EnhancedAutoinvestOrder[], boolean] => {
    const {autoinvestOrders, autoinvestLoadingState, premadeOrdersState} = useAppSelector(s => ({
        autoinvestOrders: s.autoinvest.autoinvestOrders,
        autoinvestLoadingState: s.autoinvest.autoinvestLoadingState,
        premadeOrdersState: s.autoinvest.premadeOrdersState,
    }))
    const dispatch = useAppDispatch()

    // Fetch instruments for all current autoinvest orders. This is a little wasteful, but hey. less fetching in components.
    const instrumentIds = Object.values(autoinvestOrders)
        .map(order => order.allocations.map(a => a.fund_id))
        .flat()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, instrumentsLoaded] = useInstruments(instrumentIds)

    const enhancedAutoinvestOrders = useAppSelector(s =>
        Object.values(s.autoinvest.autoinvestOrders)
            .map(order => selectEnhancedAutoinvestOrder(s, order))
            .filter((order): order is EnhancedAutoinvestOrder => Boolean(order))
            .sort((a, b) => Date.parse(a.next_date) - Date.parse(b.next_date)),
    )

    React.useEffect(() => {
        if (autoinvestLoadingState === 'uninitialised') {
            dispatch(actions.FetchCurrentOrders())
        }
        if (premadeOrdersState === 'uninitialised') {
            dispatch(actions.Initialise())
        }
    }, [autoinvestLoadingState, premadeOrdersState])

    return [
        enhancedAutoinvestOrders,
        autoinvestLoadingState === 'ready' && premadeOrdersState === 'ready' && instrumentsLoaded,
    ]
}

export const useCurrentDIYOrder = (): [EnhancedAutoinvestOrder | undefined, boolean] => {
    const [autoinvestOrders, allLoaded] = useAutoinvestOrders()
    const selectedDIYOrderId = useAppSelector(s => s.autoinvest.selectedDIYOrderId)

    const currentDIYOrder = autoinvestOrders?.find(order => order.id === selectedDIYOrderId)

    return [currentDIYOrder, allLoaded]
}

export const useCurrentPremadeOrder = (): [EnhancedAutoinvestOrder | undefined, boolean] => {
    const [autoinvestOrders, allLoaded] = useAutoinvestOrders()

    return [
        Object.values(autoinvestOrders).find(order => order.premade_order_id), // NB: customers can only have a single premade order
        allLoaded,
    ]
}
