import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryKey,
    UseMutationOptions,
    UseQueryOptions,
} from '@tanstack/react-query'
import * as api from '~/api/retail'
import {GET_MAP, POST_MAP, Response} from '~/api/retail/types'
import {replacePlaceholdersInPath} from '~/api/retail/util'
import * as rollbar from '~/api/rollbar/rollbar'
import {PathParams} from '~/global/utils/routing/routing'
import {reauthenticateReturn} from '~/global/wrappers/global-wrapper-widgets/reauthenticate/Reauthenticate'

// TODO in the long term we'll need to handle `client_upgrade_required` and `authentication_required` and `account_frozen` too - but right now it's still handled by api triggering actions in the Redux store
// TODO other global errors we might need to handle now are `access_denied` and `account_restricted` - these are normally dealt with at a per page level right now, not globally

type UseRetailPostMutationOptions<TData, TError, TVariables, TContext = unknown> = Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationFn' | 'onSuccess' | 'onError' | 'onSettled' // omit the things we don't want overriden
>
type UseRetailPostData<T extends keyof POST_MAP> = POST_MAP[T]['response']
type UseRetailPostErrorsUnion =
    // manual list of responses that have 'error' in the type - if you have made a new POST response type that matches `response.type.includes('error')`, add it here
    | Response.AuthenticationUpdateRequired
    | Response.InternalServerError
    | Response.Error
    | Response.FormErrors
    | Response.ValidationError
    | Response.OrderCreateBuyError
    | Response.OrderCreateSellError
    | Response.OrderCreateApplicationError
    | Response.InvalidAuthentication
    | Response.InvalidRegistration
    | Response.AccountRestricted
type UseRetailPostDataSuccess<T extends keyof POST_MAP> = Exclude<UseRetailPostData<T>, UseRetailPostErrorsUnion>
type UseRetailPostError<T extends keyof POST_MAP> = Exclude<
    Response.Error | Response.InternalServerError | Extract<UseRetailPostData<T>, UseRetailPostErrorsUnion>, // we manually make on the client side something that looks like a Response.Error so it's always a possiblity (as is internal error)
    Response.AuthenticationUpdateRequired // exclude this as it's handled by the hook
>
type UseRetailPostPayload<T extends keyof POST_MAP> = POST_MAP[T]['request']

// The combined pathParams and payload for each GET request
type QueryCacheKeyOptions<GetRoute extends keyof GET_MAP> =
    | NonNullable<UseRetailGetParams<GetRoute>['pathParams']>
    | NonNullable<UseRetailGetParams<GetRoute>['payload']>

// The all possible query cache keys for a GET route
type QueryCacheKey<GetRoute extends keyof GET_MAP> = {
    [K in keyof GET_MAP]: QueryCacheKeyOptions<K> extends undefined ? [K] : [K, QueryCacheKeyOptions<K>]
}[GetRoute]

// Given a response type Resp, resolves to a union GET endpoints that have a response type Resp. Note that if an
// endpoint has multiple non-error response types, Resp must be the union of all of them for the route to be included.
type GetRoutesByResponseType<Resp> = {
    [Path in keyof GET_MAP]: Resp extends UseRetailGetDataSuccess<Path> ? Path : never
}[keyof GET_MAP]

// Given a POST route, finds the success response for that route, then finds all GET routes that return the same
// response type, then resolves to the query cache keys that could therefore be updated using the POST route's response.
// This can be used to check queryCacheToUpdate is used in a type-safe way, causing a type error if the reponse types
// don't match.
type QueryCacheKeysForPostResp<PostRoute extends keyof POST_MAP> = {
    [GetRoute in keyof GET_MAP]: QueryCacheKeyOptions<GetRoute> extends undefined
        ? [GetRoute]
        : [GetRoute, QueryCacheKeyOptions<GetRoute>]
}[GetRoutesByResponseType<UseRetailPostDataSuccess<PostRoute>>]

interface UseRetailPostParams<PostRoute extends keyof POST_MAP> {
    path: PostRoute
    options?: UseRetailPostMutationOptions<
        UseRetailPostDataSuccess<PostRoute>,
        UseRetailPostError<PostRoute>,
        UseRetailPostPayload<PostRoute>
    >
    queryCacheToUpdate?: QueryCacheKeysForPostResp<PostRoute>
    queryCacheToInvalidate?: QueryCacheKey<keyof GET_MAP> | QueryCacheKey<keyof GET_MAP>[]
    pathParams: {} extends PathParams<PostRoute> ? undefined : PathParams<PostRoute>
}

/**
 * Wrapper for Tanstack Query's {@link https://tanstack.com/query/v4/docs/react/guides/mutations useMutation} that handles internal server error reporting and reauthentication.
 *
 * This hook should be instantiated before use, at the top level of a component.
 * Set it up like `const setMyThing = useRetailPost({path: 'retail/api/path'})` and then you can call it with the payload to be posted via the `mutateAsync` function. For example,
 *
 * ```
 * try {
 *     // call the mutateAsync method - note if there is no payload you must pass in `undefined`
 *     const response = await setMyThing.mutateAsync(myPayload)
 *     // success action(s) using response (or just directly call the function above without setting the const if you don't need to use the response)
 * } catch (e) {
 *     // error handling - note that e is unknown, but you can get a typed response of an error POST_MAP[T]['response'] like so:
 *     const typedErrorResponse = setMyThing.error
 * } finally {
 *    // optional - always happens afterwards
 * }
 *```
 *
 * Optionally you can also set a query cache to be updated with the reponse by passing in the query's cache key, and customise any of the behaviour of useMutation according to its API options.
 *
 * @param {object} configuration - configuration object
 * @param {keyof POST_MAP} configuration.path - retail API POST endpoint
 * @param {UseMutationOptions} [configuration.options] - useMutation options object
 * @param {QueryCacheKeysForPostResp<T>} [configuration.queryCacheToUpdate] - a cache key for a corresponding useRetailGet that should be updated with this response on success
 * @param {QueryCacheKey | QueryCacheKey[]} [configuration.queryCacheToInvalidate] - a cache key or array of cache keys for a corresponding useRetailGet that should be invalidated on success
 */
export const useRetailPost = <T extends keyof POST_MAP>({
    path,
    options,
    queryCacheToUpdate,
    queryCacheToInvalidate,
    pathParams,
}: UndefinedToOptional<UseRetailPostParams<T>>) => {
    const realPath = replacePlaceholdersInPath(path!, pathParams)

    const queryClient = useQueryClient()

    return useMutation({
        useErrorBoundary: false, // default to false - we normally want to handle POST errors specifically rather than just defaulting to the error boundary
        ...options, // override properties passed in via options
        mutationFn: async payload => {
            // we can't use the conditional type api.post expects as it breaks mutationFn (it requires at least one arg). if POST_MAP[T]['request'] is undefined you must pass in undefined
            const argsTuple = [payload] as UseRetailPostPayload<T> extends undefined ? [] : [UseRetailPostPayload<T>] // set the types per the .post function

            // mutationFn expects a promise to be returned, but we need to handle reauthenticationReturn
            // so we await in here and later return a promise that resolves immediately
            let response = await api.post(realPath as T, ...argsTuple)

            if (response.type === 'authentication_update_required') {
                response = await reauthenticateReturn(
                    () => api.post(realPath, ...argsTuple),
                    () => {
                        // triggers the onError case
                        throw response
                    },
                    () => {
                        // triggers the onError case
                        throw response
                    },
                )
            }

            // note the string matching for types containing the word error is likely imperfect
            if (
                response.type.includes('error') ||
                response.type === 'account_restricted' ||
                response.type === 'internal_server_error' // TODO consider adding special handling for internal_server_error at the top level and filtering the types out of the return
            ) {
                // triggers the onError case
                return Promise.reject(response)
            }

            return response as UseRetailPostDataSuccess<T>
        },
        onSuccess: (response: UseRetailPostDataSuccess<T>) => {
            // if we we passed in a connected GET request cache key, update the cache with the response
            if (queryCacheToUpdate) {
                queryClient.setQueryData(queryCacheToUpdate, response)
            }
            if (queryCacheToInvalidate) {
                // if we got an array of CacheKeys, invalidate them all
                if (typeof queryCacheToInvalidate[0] !== 'string') {
                    queryCacheToInvalidate.forEach(queryCacheKey => {
                        queryClient.invalidateQueries(queryCacheKey)
                    })
                } else {
                    queryClient.invalidateQueries(queryCacheToInvalidate)
                }
            }
        },
        onError: (error: UseRetailPostError<T> | Error) => {
            if (error instanceof Error) {
                return {
                    // for other unhandled errors, make a client side error message that fits the same shape as a Response.Error
                    type: 'error',
                    message: error.message,
                    code: 'client_error',
                }
            }
            // for 500s, record to Rollbar
            if (error.type === 'internal_server_error') {
                rollbar.sendError(`Failed XHR request: 'internal_server_error'`, {
                    url: realPath,
                    method: 'POST',
                })
            }
            // pass errors through for the component to handle, because generic errors aren't helpful!
            return error
        },
    })
}

type UseRetailGetOptions<TQueryFnData, TError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey> = Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn' | 'onSuccess' | 'onError' | 'onSettled' // omit the things we don't want overriden
>
type UseRetailGetData<T extends keyof GET_MAP> = GET_MAP[T]['response']
type UseRetailGetPayload<T extends keyof GET_MAP> = {} extends Required<
    Omit<GET_MAP[T]['request'], keyof PathParams<T>>
>
    ? undefined
    : Omit<GET_MAP[T]['request'], keyof PathParams<T>>
type UseRetailGetErrorsUnion = Response.AuthenticationUpdateRequired | Response.InternalServerError | Response.Error // manual list of responses that have 'error' in the type - if you have made a new GET response type that matches `response.type.includes('error')`, add it here
type UseRetailGetDataSuccess<T extends keyof GET_MAP> = Exclude<GET_MAP[T]['response'], UseRetailGetErrorsUnion>
type UseRetailGetError<T extends keyof GET_MAP> = Exclude<
    Response.Error | Response.InternalServerError | Extract<UseRetailGetData<T>, UseRetailGetErrorsUnion>,
    Response.AuthenticationUpdateRequired // exclude this as it's handled by the hook
>

export interface UseRetailGetParams<T extends keyof GET_MAP> {
    path: T
    options?: UseRetailGetOptions<UseRetailGetDataSuccess<T>, UseRetailGetError<T>>
    payload: UseRetailGetPayload<T>
    pathParams: {} extends PathParams<T> ? undefined : PathParams<T>
}

/**
 * Converts any keys that are a union with `undefined` into optional keys
 *
 * For example if you have:
 *
 * {a: number | undefined}
 *
 * Then this will convert to:
 * {a?: number}
 *
 */
type UndefinedToOptional<T> = Pick<
    T,
    NonNullable<
        {
            [P in keyof T]: undefined extends T[P] ? never : P
        }[keyof T]
    >
> &
    Partial<T>

/**
 * Wrapper for Tanstack Query's {@link https://tanstack.com/query/v4/docs/react/guides/queries useQuery} that handles internal server error reporting and reauthentication.
 *
 * This hook should be used in the top level of a component.
 * Set it up like `const getMyThing = useRetailGet('retail/api/path', {path, payload})` and then you can access the data returned immediately as loading and error states are handled by the suspense context and error boundary. For example,
 *
 * ```
 * const {data: myVariableName} = useRetailGet({path: 'retail/api/path'})
 *```
 *
 * @param {object} configuration - configuration object
 * @param {keyof GET_MAP} configuration.path - retail API GET endpoint
 * @param {UseRetailGetPayload} configuration.payload - GET parameters to send with the request
 * @param {UseQueryOptions} [configuration.options] - useQuery options object
 */
export const useRetailGet = <T extends keyof GET_MAP>({
    path,
    payload,
    pathParams,
    options,
}: UndefinedToOptional<UseRetailGetParams<T>>) => {
    const realPath = replacePlaceholdersInPath(path!, pathParams)

    const queryKey: QueryKey = payload || pathParams ? [path, {...(payload ?? {}), ...(pathParams ?? {})}] : [path]

    const {data, ...queryResults} = useQuery({
        suspense: true,
        useErrorBoundary: true, // the vast majority of GET requests don't need bespoke error handling and just need the generic 'try again' page when the normal retry behaviour fails. this is overridable via options.
        networkMode: 'offlineFirst', // we want to ensure tanstack always tries to make a query even if the browser says it's offline, this will cause a 'proper' fail and error message so our assertion that data always exists is true, see https://github.com/TanStack/query/discussions/4858
        cacheTime: 6 * 60 * 1000, // 6 minutes
        staleTime: 5 * 60 * 1000, // 5 minutes
        ...options,
        queryKey,
        queryFn: async (): Promise<UseRetailGetDataSuccess<T>> => {
            const argsTuple = [payload] as UseRetailGetPayload<T> extends undefined ? [] : [UseRetailGetPayload<T>] // we have to assert the type here to match the way api.get expects the types (normally via rest params)

            // queryFn expects a promise to be returned, but we need to handle reauthenticationReturn
            // so we await in here and later return a promise that resolves immediately
            let response = await api.get(realPath, ...argsTuple)

            // we have to be defensive in the following checks - not all GET responses have a type property
            if (response.type === 'authentication_update_required') {
                response = await reauthenticateReturn(
                    () => api.get(realPath, ...argsTuple),
                    () => {
                        // triggers the onError case
                        throw response
                    },
                    () => {
                        // triggers the onError case
                        throw response
                    },
                )
            }

            if (response.type === 'internal_server_error') {
                // triggers the onError case
                throw response
            }

            // note the string matching for types containing the word error is likely imperfect
            if (response.type.includes('error')) {
                // triggers the onError case
                return Promise.reject(response)
            }

            return response as UseRetailGetDataSuccess<T>
        },
        onError: (error: UseRetailGetError<T> | Error) => {
            if (error instanceof Error) {
                return {
                    // for other unhandled errors, make a client side error message that fits the same shape as a Response.Error
                    type: 'error',
                    message: error.message,
                    code: 'client_error',
                }
            }
            // for 500s, record to Rollbar
            if (error.type === 'internal_server_error') {
                rollbar.sendError(`Failed XHR request: 'internal_server_error'`, {
                    url: realPath,
                    method: 'GET',
                })
            }
            // pass errors through for the component to handle, because generic errors aren't helpful!
            return error
        },
    })

    return {
        // because we use suspense, we can assert that the data isn't undefined
        // see discussion at https://github.com/TanStack/query/issues/1297
        // this wouldn't be true for some edge cases like calling queryClient.cancelQueries
        // but we can just avoid that edge case
        data: data!,
        ...queryResults,
    }
}

/**
 * Use for getting error strings when the type is unknown. Useful for querying errors in the
 * catch block of a useRetailPost's mutateAsync.
 *
 * ```
 * try {
 *    await myPostHook.mutateAsync({})
 * } catch (e) {
 *    if (getStringFromError(e, 'code') === 'my_error_code') {
 *        // some specific error handling action
 *    } else {
 *        // some fallback
 *    }
 * }
 * ```
 *
 * @param {unknown} obj - error object of unknown type
 * @param {string} key - property key to read from
 * @returns {string | undefined} the message or nothing if the key didn't exist or the type wasn't string
 */
export const getStringFromError = (obj: unknown, key: string): string | undefined => {
    try {
        const p = (obj as any)[key]
        if (typeof p === 'string') {
            return p
        }
    } catch {
        // ignore
    }
    return undefined
}
