import cn from 'classnames'
import React from 'react'
import * as rollbar from '~/api/rollbar/rollbar'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {useTelescope} from '~/sections/invest/sections/ai-search/hooks/useTelescope'
import {PromptSuggestions} from '~/sections/invest/sections/ai-search/widgets/ai-suggestions/AiSuggestions'
import styles from './AiError.scss'

const genericCopy = 'An error occurred processing your query, please try another.'
const ERROR_COPY: {[id: string]: string} = {
    ripple_personal_advice_check_failed:
        'Your prompt failed personal advice checks. Please remove any requests for personal insights and anything related to your personal situation or circumstances. For optimal results, stick to more thematic ideas.',
    ripple_support_inquiry_check_failed:
        'The prompt appears to be a support question. Please try an investment theme or idea.',
    ripple_moderation_check_failed:
        "The prompt failed safety and moderation checks. Please try a topic that's more suitable.",
    ripple_too_vague_check_failed:
        'The prompt is too vague to generate an investment thesis. Please provide a more specific theme or idea.',
    ripple_general_knowledge_check_failed:
        'The prompt seems like a general knowledge question. Please phrase it as a thematic investment statement instead.',
    ripple_no_instruments_matched:
        'No matches found for the prompt. Please provide a broader investment theme or idea.',
    missing_argument: genericCopy,
    invalid_argument: genericCopy,
    json_body_error: genericCopy,
    internal_error: genericCopy,
}

export const AiError: React.FunctionComponent = () => {
    const {telescopeData, sendPrompt} = useTelescope()

    const errors = telescopeData.errors ?? []

    React.useEffect(() => {
        if (errors.length > 2) {
            rollbar.sendError(`Unhandled multiple errors from Telescope Ripple`, {errors})
        }
    }, [errors.length > 2])

    React.useEffect(() => {
        if (!errors.length) {
            return
        }
        const unknownErrorCodes = errors.filter(error => !(error.error_code in ERROR_COPY))
        if (unknownErrorCodes.length > 0) {
            rollbar.sendError(`Unknown errors from Telescope Ripple`, {unknownErrorCodes})
        }
    }, [errors.length])

    if (!errors.length) {
        return null
    }

    const error = errors[0]
    const body = ERROR_COPY[error.error_code] ?? 'An error occurred processing your query, please try another.'

    return (
        <div className={styles.errorBox}>
            <div aria-hidden>🧐</div>
            <h2 className={spacing.spaceBelow4}>No results for your search</h2>
            <p className={spacing.spaceBelow16}>{body}</p>
            {error.prompt_suggestions && (
                <>
                    <h3 className={cn(typographyOverrides['as-h3'], spacing.spaceBelow8)}>Alternative suggestions:</h3>
                    <PromptSuggestions
                        promptSuggestions={[...error.prompt_suggestions]}
                        onChipClick={value => {
                            sendPrompt(value)
                        }}
                    />
                </>
            )}
        </div>
    )
}
