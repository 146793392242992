/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {Currency, CurrencyFromJSON, CurrencyFromJSONTyped, CurrencyToJSON, CurrencyToJSONTyped} from './'

/**
 *
 * @export
 * @interface PortfolioCreate
 */
export interface PortfolioCreate {
    /**
     *
     * @type {string}
     * @memberof PortfolioCreate
     */
    uuid: string
    /**
     *
     * @type {Currency}
     * @memberof PortfolioCreate
     */
    currency: Currency
}

export function PortfolioCreateFromJSON(json: any): PortfolioCreate {
    return PortfolioCreateFromJSONTyped(json, false)
}

export function PortfolioCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioCreate {
    if (json === undefined || json === null) {
        return json
    }
    return {
        uuid: json['uuid'],
        currency: CurrencyFromJSON(json['currency']),
    }
}

export function PortfolioCreateToJSON(value?: PortfolioCreate | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        uuid: value.uuid,
        currency: CurrencyToJSON(value.currency),
    }
}
