import React from 'react'
import {useRetailPost} from '~/api/query/retail'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {NatureAndPurposeStep, getNextStep, getPreviousStep} from '~/global/utils/nature-and-purpose/natureAndPurpose'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import NatureAndPurposeForm from '~/global/widgets/nature-and-purpose-form/NatureAndPurpose'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'

export const NatureAndPurpose = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const owner = useProfileOwner()
    const [step, setStep] = React.useState<NatureAndPurposeStep>(getNextStep('intro', true))

    const profile = useProfile()
    const setNatureAndPurpose = useRetailPost({
        path: 'owner/:owner_id/nature-and-purpose',
        pathParams: {owner_id: profile.owner_id},
        queryCacheToUpdate: ['owner/:owner_id/nature-and-purpose', {owner_id: profile.owner_id}],
    })

    const ownerIsOrganisation = owner.owner_type === 'ORGANISATION'
    const returnUrl = ownerIsOrganisation ? 'settings/business-details' : 'settings/personal-details'

    return (
        <>
            <Toolbar
                dataTestId="toolbar--nature-and-purpose"
                leftButton="back"
                onLeftButtonClick={() => {
                    step === 'intro' || step === 'frequency' ? navigate(-1) : setStep(getPreviousStep(step, true))
                }}
                title="How you invest"
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <NatureAndPurposeForm
                    step={step}
                    setStep={setStep}
                    getNextStep={getNextStep}
                    setNatureAndPurpose={async (
                        frequency,
                        annual_amount,
                        purposes,
                        other_purpose: string | undefined,
                    ) => {
                        if (!purposes.includes('other')) {
                            other_purpose = undefined
                        }
                        await setNatureAndPurpose.mutateAsync({frequency, annual_amount, purposes, other_purpose})
                        return undefined
                    }}
                    onSubmit={() => navigate(profileUrl(returnUrl))}
                    isExistingUser={true}
                    forOrganisation={ownerIsOrganisation}
                />
            </Page>
        </>
    )
}

export default NatureAndPurpose
