/* eslint-disable no-console */
import React from 'react'
import {useNavigate} from 'react-router'
import {useCoveGet} from '~/api/query/cove'
import {useRetailGet} from '~/api/query/retail'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {PortfolioSlice} from '~/sections/landing/widgets/portfolio-slice/PortfolioSlice'
import insureSmall from '~/sections/protect/sections/insure/assets/images/insure-small.png'

const DEFAULT_TITLE = 'Insure your car'
const DEFAULT_SUBTITLE = "Get comprehensive car insurance at mates' rates"

interface GetStartedInsureProps {
    title?: string
    subtitle?: string
}

/** This component assumes that the user has insure access, and that this has been checked prior to render. */
export const GetStartedInsureWrapper: React.FunctionComponent<GetStartedInsureProps> = () => {
    const {data: insureAccount} = useRetailGet({
        path: 'insure/get-account',
        options: {retry: false}, // No retry - we don't want to hold up the Landing page pageload
    })

    if (insureAccount.type !== 'insure_account') {
        // No Insure account: show the generic case

        return <GetStartedInsureSlice title={DEFAULT_TITLE} subtitle={DEFAULT_SUBTITLE} />
    }

    // We have an Insure account: let's drop to into another component to get their Cove quote count
    return <GetStartedInsure />
}

const GetStartedInsure: React.FunctionComponent<GetStartedInsureProps> = () => {
    const combinedCountResponse = useCoveGet('customer/v1/counts', {retry: false})
    const quoteCount = combinedCountResponse.quote_count

    const title =
        quoteCount > 1 ? 'Finish insuring a car' : quoteCount === 1 ? 'Finish insuring your car' : DEFAULT_TITLE

    const subtitle =
        quoteCount > 1
            ? 'Continue with a quote to get your car covered'
            : quoteCount === 1
              ? 'Continue with your quote to get your car covered'
              : DEFAULT_SUBTITLE

    return <GetStartedInsureSlice title={title} subtitle={subtitle} />
}

const GetStartedInsureSlice: React.FunctionComponent<GetStartedInsureProps> = ({title, subtitle}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const handleClick = () => {
        rudderTrack('navigation', 'new_product_button_clicked', {
            feature: 'insure',
            type: 'get_started',
        })

        // The landing page will redirect to the Insure Intro flow if they don't already have an account
        navigate(profileUrl('protect/insure'))
    }

    return (
        <PortfolioSlice
            dataTestId="button--landing-open-insure-account"
            image={insureSmall}
            imageBackgroundColour="Kiwi100"
            onClick={handleClick}
        >
            <h3>{title}</h3>
            <p>{subtitle}</p>
        </PortfolioSlice>
    )
}
