/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    DataProvider,
    DataProviderFromJSON,
    DataProviderFromJSONTyped,
    DataProviderToJSON,
    DataProviderToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface CashDividendDto
 */
export interface CashDividendDto {
    /**
     *
     * @type {string}
     * @memberof CashDividendDto
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof CashDividendDto
     */
    instrumentId: string
    /**
     *
     * @type {Date}
     * @memberof CashDividendDto
     */
    createdUtc: DateTime
    /**
     *
     * @type {Date}
     * @memberof CashDividendDto
     */
    updatedUtc: DateTime
    /**
     *
     * @type {Date}
     * @memberof CashDividendDto
     */
    exDate: DateTime
    /**
     *
     * @type {Date}
     * @memberof CashDividendDto
     */
    paymentDate: DateTime
    /**
     *
     * @type {Date}
     * @memberof CashDividendDto
     */
    recordDate?: DateTime | null
    /**
     *
     * @type {Date}
     * @memberof CashDividendDto
     */
    declaredDate?: DateTime | null
    /**
     *
     * @type {string}
     * @memberof CashDividendDto
     */
    description?: string | null
    /**
     *
     * @type {string}
     * @memberof CashDividendDto
     */
    frequency?: string | null
    /**
     *
     * @type {string}
     * @memberof CashDividendDto
     */
    dataProviderExternalId?: string | null
    /**
     *
     * @type {DataProvider}
     * @memberof CashDividendDto
     */
    dataProvider?: DataProvider
    /**
     *
     * @type {string}
     * @memberof CashDividendDto
     */
    refinitivCorporateActionId?: string | null
    /**
     *
     * @type {string}
     * @memberof CashDividendDto
     */
    adrFee?: string | null
    /**
     *
     * @type {string}
     * @memberof CashDividendDto
     */
    netAmount?: string | null
    /**
     *
     * @type {string}
     * @memberof CashDividendDto
     */
    taxRate?: string | null
    /**
     *
     * @type {string}
     * @memberof CashDividendDto
     */
    currency: string
    /**
     *
     * @type {string}
     * @memberof CashDividendDto
     */
    cashPerShare: string
    /**
     *
     * @type {string}
     * @memberof CashDividendDto
     */
    frankingPercent?: string | null
}

export function CashDividendDtoFromJSON(json: any): CashDividendDto {
    return CashDividendDtoFromJSONTyped(json, false)
}

export function CashDividendDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CashDividendDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        id: json['id'],
        instrumentId: json['instrumentId'],
        createdUtc: DateTime.fromISO(json['createdUtc'], {setZone: true}),
        updatedUtc: DateTime.fromISO(json['updatedUtc'], {setZone: true}),
        exDate: DateTime.fromISO(json['exDate'], {setZone: true}),
        paymentDate: DateTime.fromISO(json['paymentDate'], {setZone: true}),
        recordDate: !exists(json, 'recordDate')
            ? undefined
            : json['recordDate'] === null
              ? null
              : DateTime.fromISO(json['recordDate'], {setZone: true}),
        declaredDate: !exists(json, 'declaredDate')
            ? undefined
            : json['declaredDate'] === null
              ? null
              : DateTime.fromISO(json['declaredDate'], {setZone: true}),
        description: !exists(json, 'description') ? undefined : json['description'],
        frequency: !exists(json, 'frequency') ? undefined : json['frequency'],
        dataProviderExternalId: !exists(json, 'dataProviderExternalId') ? undefined : json['dataProviderExternalId'],
        dataProvider: !exists(json, 'dataProvider') ? undefined : DataProviderFromJSON(json['dataProvider']),
        refinitivCorporateActionId: !exists(json, 'refinitivCorporateActionId')
            ? undefined
            : json['refinitivCorporateActionId'],
        adrFee: !exists(json, 'adrFee') ? undefined : json['adrFee'],
        netAmount: !exists(json, 'netAmount') ? undefined : json['netAmount'],
        taxRate: !exists(json, 'taxRate') ? undefined : json['taxRate'],
        currency: json['currency'],
        cashPerShare: json['cashPerShare'],
        frankingPercent: !exists(json, 'frankingPercent') ? undefined : json['frankingPercent'],
    }
}

export function CashDividendDtoToJSON(value?: CashDividendDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        id: value.id,
        instrumentId: value.instrumentId,
        createdUtc: value.createdUtc.toISO(),
        updatedUtc: value.updatedUtc.toISO(),
        exDate: value.exDate.toFormat('yyyy-MM-dd'),
        paymentDate: value.paymentDate.toFormat('yyyy-MM-dd'),
        recordDate:
            value.recordDate === undefined
                ? undefined
                : value.recordDate === null
                  ? null
                  : value.recordDate.toFormat('yyyy-MM-dd'),
        declaredDate:
            value.declaredDate === undefined
                ? undefined
                : value.declaredDate === null
                  ? null
                  : value.declaredDate.toFormat('yyyy-MM-dd'),
        description: value.description,
        frequency: value.frequency,
        dataProviderExternalId: value.dataProviderExternalId,
        dataProvider: DataProviderToJSON(value.dataProvider),
        refinitivCorporateActionId: value.refinitivCorporateActionId,
        adrFee: value.adrFee,
        netAmount: value.netAmount,
        taxRate: value.taxRate,
        currency: value.currency,
        cashPerShare: value.cashPerShare,
        frankingPercent: value.frankingPercent,
    }
}
