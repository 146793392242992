import {ModalLink} from '@design-system/modal'
import React from 'react'

interface SettlementDueDateProps {
    contentType?: 'buy' | 'sell'
    jurisdiction: 'au' | 'nz'
}

export const SettlementDueDate: React.FunctionComponent<SettlementDueDateProps> = ({contentType, jurisdiction}) => {
    let content

    const corpActionsLink =
        jurisdiction === 'au'
            ? 'https://sharesies.com.au/learn/an-introduction-to-corporate-actions'
            : 'https://www.sharesies.nz/learn/an-introduction-to-corporate-actions'

    switch (contentType) {
        case 'buy':
            content = (
                <>
                    <p>
                        The settlement date is when ownership of the shares you're buying is legally transferred from
                        the seller to us (or our related third parties) to hold for you.
                    </p>
                    <p>
                        From the settlement date onward, you'll be able to receive dividends, and take part in
                        shareholder votes (and other{' '}
                        <a href={corpActionsLink} target="_blank" rel="noreferrer noopener">
                            voluntary corporate actions
                        </a>
                        , where they're available) for the investments you hold through Sharesies.
                    </p>
                    <p>
                        Settlement dates are commonly described as T+1, T+2, T+3, and so on. 'T' stands for 'trade date'
                        (the date your order fills), and the number stands for the number of business days the order
                        will take to settle. Most orders for listed investments settle 2 business days after they're
                        filled, but can take longer depending on the investment.
                    </p>
                    <p>
                        For example, under T+2 settlement, an order that fills on a Monday would typically settle two
                        business days later on Wednesday.
                    </p>
                </>
            )

            break
        case 'sell':
            content = (
                <>
                    <p>
                        The settlement date is when ownership of the shares you're selling is legally transferred to the
                        buyer.
                    </p>
                    <p>
                        From the time a sell order fills until the settlement date (and after), you'll still receive any
                        dividends due to be paid to you. But, you might not be able to take part in shareholder votes
                        (and other{' '}
                        <a href={corpActionsLink} target="_blank" rel="noreferrer noopener">
                            voluntary corporate actions
                        </a>
                        , where they're available) for the investments you hold through Sharesies.
                    </p>
                    <p>
                        Settlement dates are commonly described as T+1, T+2, T+3, and so on. 'T' stands for 'trade date'
                        (the date your order fills), and the number stands for the number of business days the order
                        will take to settle. Most orders for listed investments settle 2 business days after they're
                        filled, but can take longer depending on the investment.
                    </p>
                    <p>
                        For example, under T+2 settlement, an order that fills on a Monday would typically settle two
                        business days later on Wednesday.
                    </p>
                </>
            )
            break
        default:
            content = (
                <>
                    <p>
                        The settlement date is when share ownership is legally transferred from one shareholder to
                        another. It determines who's eligible to get the benefit of owning the shares—like being able to
                        receive dividends, or take part in shareholder votes (and other{' '}
                        <a href={corpActionsLink} target="_blank" rel="noreferrer noopener">
                            voluntary corporate actions
                        </a>
                        , where they’re available).
                    </p>
                    <p>
                        Settlement dates are commonly described as T+1, T+2, T+3, and so on. 'T' stands for 'trade date'
                        (the date your order fills), and the number stands for the number of business days the order
                        will take to settle. Most orders for listed investments settle 2 business days after they're
                        filled, but can take longer depending on the investment.
                    </p>
                    <p>
                        For example, under T+2 settlement, an order that fills on a Monday would typically settle two
                        business days later on Wednesday.
                    </p>
                </>
            )
            break
    }

    const contentTitle =
        contentType === 'buy'
            ? 'Settlement date for buy orders'
            : contentType === 'sell'
              ? 'Settlement date for sell orders'
              : 'Settlement date'
    return (
        <>
            Settlement due date
            <ModalLink
                dataTestId="modal-link--estimated-settlement-date"
                label="Settlement due date"
                asIcon
                modalTitle={contentTitle}
                primaryButton={{label: 'Ok, got it'}}
                bottomBorder
                helpIconSize={16}
            >
                {content}
            </ModalLink>
        </>
    )
}

export default SettlementDueDate
