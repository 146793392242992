import React from 'react'
import {dateFormatNoYearShortMonth} from '~/global/utils/format-date/formatDate'
import {shareLabel as getShareLabel} from '~/global/utils/share-label/shareLabel'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import buySellStyles from '~/global/widgets/buy-or-sell-current-price-with-modal//BuyOrSellCurrentPriceWithModal.scss'
import PricesCompanyEtfModal from '~/global/widgets/help-modals/PricesCompanyEtf'
import PricesExtendedHoursModal from '~/global/widgets/help-modals/PricesExtendedHours'
import PricesManagedFundModal from '~/global/widgets/help-modals/PricesManagedFund'
import LiveUsSharePriceWrapper from '~/global/widgets/live-share-price-with-exchange-tooltip/LiveSharePriceWithExchangeTooltip'
import LiveUsSharePriceChangeWrapper from '~/global/widgets/share-price-change/SharePriceChange'
import {SharePriceWithExchangeTooltip} from '~/global/widgets/share-price-with-exchange-tooltip/SharePriceWithExchangeTooltip'
import {useUsLivePricing} from '~/sections/invest/sections/view-instrument/hooks/usLivePricing'
import {Instrument} from '~/store/instrument/types'
import styles from './Header.scss'

interface PriceInformationProps {
    instrument: Instrument
    showExtendedHours: boolean
}

const PriceInformation: React.FunctionComponent<PriceInformationProps> = ({instrument, showExtendedHours}) => {
    const usLivePricing = useUsLivePricing(instrument)

    const shareLabel = getShareLabel({instrument})

    return (
        <div className={styles.priceRow}>
            <div className={styles.headerPrice}>
                {/* Extended hours enabled */}
                {showExtendedHours && instrument.extendedHoursPrice && (
                    <>
                        {/* Extended hours price - live or delayed, with modal */}
                        <div className={styles.extendedHoursPrice}>
                            <p>
                                {usLivePricing ? 'Live extended hours price' : 'Extended hours price'}
                                <PricesExtendedHoursModal instrument={instrument} usLivePricing={!!usLivePricing} />
                            </p>

                            {usLivePricing ? (
                                <LiveUsSharePriceWrapper instrument={instrument} shareLabel={shareLabel} />
                            ) : (
                                <SharePriceWithExchangeTooltip
                                    price={instrument.extendedHoursPrice}
                                    currency={instrument.currency}
                                    shareLabel={shareLabel}
                                />
                            )}
                        </div>

                        {/* Close price label + modal */}
                        <div className={buySellStyles.currentPrice}>
                            {/*
                                TODO I&S: change hardcoded value of 17h to the correct time
                                https://sharesies.atlassian.net/browse/CONF-2419
                            */}
                            Price at close {instrument.marketLastCheck.toFormat(dateFormatNoYearShortMonth)} at 17:00
                            <PricesCompanyEtfModal instrument={instrument} isClosePrice={true} />
                        </div>
                        {/* Close price */}
                        <div className={buySellStyles.bigPrice}>
                            <SharePriceWithExchangeTooltip
                                price={instrument.marketPrice}
                                currency={instrument.currency}
                                shareLabel={shareLabel}
                            />
                        </div>
                    </>
                )}

                {/* Regular hours, also default view when extended hours is not available */}
                {!showExtendedHours && (
                    <>
                        {/* Price - live or delayed */}
                        {usLivePricing ? (
                            <LiveUsSharePriceWrapper instrument={instrument} shareLabel={shareLabel} />
                        ) : (
                            <SharePriceWithExchangeTooltip
                                price={instrument.marketPrice}
                                currency={instrument.currency}
                                shareLabel={shareLabel}
                            />
                        )}
                        {/* Modal relating to price */}
                        {!instrument.isIlliquid &&
                            (tradingType(instrument) === 'managed' ? (
                                <PricesManagedFundModal />
                            ) : (
                                <PricesCompanyEtfModal instrument={instrument} />
                            ))}
                    </>
                )}
            </div>

            {/* Relavant in both regular & extended hours - will only be shown if they have US live pricing */}
            <div className={styles.sharePriceChange}>
                <LiveUsSharePriceChangeWrapper instrument={instrument} />
            </div>
        </div>
    )
}

export default PriceInformation
