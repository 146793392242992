import React from 'react'
import {Request} from '~/api/retail/types'
import {isUninitialised} from '~/global/utils/is-loading/isLoading'
import {Toast} from '~/global/widgets/toast/Toast'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/instrument/actions'

// Simple wrapper for redux state fetching and actions
const useAutoinvestDividends = () => {
    const {state, loadingState} = useAppSelector(({instrument}) => ({
        state: instrument.autoinvestDividends,
        loadingState: instrument.autoinvestDividendsLoadingState,
    }))
    const dispatch = useAppDispatch()

    React.useEffect(() => {
        if (isUninitialised(loadingState)) {
            dispatch(actions.fetchAutoinvestDividends())
        }
    }, [])

    const setPrimaryPreference = async (
        payload: Omit<Request.AutoinvestDividendDefaultPreference, 'acting_as_id'>,
        notify = true,
    ) => {
        if (notify) {
            const toastMessage =
                payload.preference === true
                    ? 'Dividends for all future investment will now be automatically invested'
                    : 'Dividends due today may still be reinvested. Future dividends will be paid into your Wallet, but won’t be invested. '
            Toast(toastMessage)
        }
        dispatch(actions.setAutoinvestDividendsPrimaryPreference(payload))
    }

    const setFundPreference = (...args: Parameters<typeof actions.setFundPreference>) =>
        dispatch(actions.setFundPreference(...args))

    return {
        preferences: state,
        loadingState,
        setPrimaryPreference,
        setFundPreference,
    }
}

export default useAutoinvestDividends
