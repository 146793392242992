import 'whatwg-fetch'
import React from 'react'
// Note that these exception occur primarily because we're using React 17.x
// types with React 18.x. Using `createRoot` is the "correct" way to instantiate
// a react app now and eventually these exceptions should be able to be removed.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {createRoot} from 'react-dom/client'
import {ErrorBoundary, FallbackProps} from 'react-error-boundary'
import analytics from '~/api/google-analytics/googleAnalytics'
import * as rollbar from '~/api/rollbar/rollbar'
import Router from '~/global/Router'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import '@stripe/stripe-js' // import as a side effect to ensure Stripe is inserted ASAP

const appElement = document.getElementById('app')

// the main error boundary is in GlobalWrapper, but this catches lower level errors as a stopgap
// we prefer to use the one in GlobalWrapper first in preference as it leaves nav sidebar stuff intact
const TopLevelError = ({resetErrorBoundary}: FallbackProps) => <WeSlippedUp retryAction={resetErrorBoundary} />
const errorHandler = (error: Error, info: {componentStack: string}) => {
    rollbar.sendError(error, info)
}

if (process.env.NODE_ENV === 'development' && MSW_MOCKS_ENABLED) {
    import('../test/mocks/developmentMocks')
        .then(({devMockServiceWorker}) => {
            devMockServiceWorker.start({
                onUnhandledRequest(_req) {
                    // do nothing, we only want to mock some very specific bits
                },
            })
        })
        .catch(error => {
            // eslint-disable-next-line no-console
            console.error('Failed to load development mode MSW mocks', error)
        })
}

rollbar.initRollbar()
analytics.init()

createRoot(appElement).render(
    <ErrorBoundary FallbackComponent={TopLevelError} key={location.pathname} onError={errorHandler}>
        <Router />
    </ErrorBoundary>,
)
