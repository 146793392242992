import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import thunkMiddleware, {ThunkMiddleware} from 'redux-thunk'
import accountingReducer from './accounting/reducer'
import autoinvestReducer from './autoinvest/reducer'
import bankLinkingReducer from './bankLinking/reducer'
import employeeShareSchemeReducer from './employeeShareScheme/reducer'
import giftReducer from './gift/reducer'
import identityReducer from './identity/reducer'
import instrumentReducer from './instrument/reducer'
import marketReducer from './market/reducer'
import navReducer from './nav/reducer'
import notificationReducer from './notification/reducer'
import orderReducer from './order/reducer'
import planReducer from './plan/reducer'
import portfolioReducer from './portfolio/reducer'
import saveReducer from './save/reducer'
import signUpReducer from './sign-up/reducer'
import transferReducer from './transfer/reducer'
import {RootState, RootActionsType} from './types'

const reduxDevtoolsExtension =
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()

const store = createStore(
    combineReducers({
        identity: identityReducer,
        instrument: instrumentReducer,
        nav: navReducer,
        gift: giftReducer,
        notification: notificationReducer,
        accounting: accountingReducer,
        autoinvest: autoinvestReducer,
        market: marketReducer,
        order: orderReducer,
        signUp: signUpReducer,
        transfer: transferReducer,
        portfolio: portfolioReducer,
        employeeShareScheme: employeeShareSchemeReducer,
        bankLinking: bankLinkingReducer,
        plan: planReducer,
        save: saveReducer,
    }),
    reduxDevtoolsExtension
        ? compose(
              applyMiddleware(thunkMiddleware as ThunkMiddleware<RootState, RootActionsType>),
              reduxDevtoolsExtension,
          )
        : compose(applyMiddleware(thunkMiddleware as ThunkMiddleware<RootState, RootActionsType>)),
)

export default store
