import {DateTime} from 'luxon'
import React from 'react'
import ReactPixel from 'react-facebook-pixel'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {queryClient} from '~/api/query/client'
import {redditTrackCustom, redditTrackSignUp} from '~/api/reddit/redditPixel'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import config from '~/configForEnv'
import LoadingComponent from '~/global/pages/loading-screen/LoadingScreen'
import {urlFor} from '~/global/routeGenerator'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import {Navigate} from '~/migrate-react-router'
import AddressUploaded, {
    AUCustomerAddressUploadStillRequired,
    AUCustomerUnderReview,
} from '~/sections/user/pages/address-uploaded/AddressUploaded'
import Welcome from '~/sections/user/sections/sign-up/pages/welcome/Welcome'
import WelcomeDependent from '~/sections/user/sections/sign-up/sections/post-activation/pages/welcome-dependent/WelcomeDependent'
import {connect} from '~/store/connect'
import identityActions from '~/store/identity/actions'
import {User} from '~/store/identity/types'
import actions from '~/store/nav/actions'
import AboutYou from './pages/about-you/AboutYou'
import Address from './pages/address/Address'
import AddressSuccess from './pages/address-success/AddressSuccess'
import AddressVerification from './pages/address-verification/SignUpAddressVerification'
import {AUTermsAndConditions} from './pages/au-terms-and-conditions/AUTermsAndConditions'
import DependentDeclaration from './pages/dependent-declaration/DependentDeclaration'
import IRDNumber from './pages/ird-number/IRDNumber'
import NatureAndPurpose from './pages/nature-and-purpose/NatureAndPurpose'
import PrescribedPerson from './pages/prescribed-person/PrescribedPerson'
import TaxQuestions from './pages/tax-questions/TaxQuestions'
import IdentityVerificationSuccess from './sections/identity/pages/identity-success/Success'
import DependentIdentityDetails from './sections/identity/sections/identity-check/DependentIdentityDetails'
import IdentityDetails from './sections/identity/sections/identity-check/IdentityDetails'
import EmailVerification from './sections/post-activation/pages/email-verification'
import ProductPreferenceSelector from './sections/post-activation/pages/product-preference/ProductPreferenceSelector'
import TermsAndConditionsUpdate from './sections/post-activation/pages/terms-and-conditions-update/TermsAndConditionsUpdate'

type CustomerSignupState = Exclude<User['customer_signup_state'], undefined>

interface SignUpState {
    step:
        | 'about-you'
        | 'email-verification'
        | 'au-terms-and-conditions'
        | 'terms-and-conditions-update'
        | 'dependent-declaration'
        | 'product-preference'
        | 'identity-details'
        | 'identity-success'
        | 'address'
        | 'address-success'
        | 'ird-number'
        | 'tax-questions'
        | 'make-active'
        | 'logged-out'
        | 'proof-of-address-upload'
        | 'manual-address-upload-au'
        | 'manual-address-review'
        | 'address-upload'
        | 'return-to-address-upload'
        | 'prescribed-person'
        | 'nature-and-purpose'
        | 'welcome'
        | 'completed'
    pinState:
        | null
        | 'identity-details'
        | 'identity-success'
        | 'address-auto'
        | 'address-upload'
        | 'return-to-address-upload'
        | 'prescribed-person'
        | 'nature-and-purpose'
    uploadSkipped: boolean
    existingUser: boolean
    tcUpdate: boolean
}

/*
 * Route existing active users that have additional sign-up steps to complete
 * back into some sign-up steps. Note this logic is also present in the native apps.
 */
export const additionalSignUpStepsCheck = (user: User) => {
    if (
        (!user.is_dependent && !user.checks.tc_accepted) || // non-dependent user who hasn't accepted new t&c
        (!user.checks.prescribed_answered && user.jurisdiction === 'nz') || // user who hasn't answered if they are prescribed
        (!user.is_dependent && !user.checks.nature_and_purpose_answered) // user who hasn't answered nature & purpose questions
    ) {
        return true
    }
    return false
}

export const stateMachineComponentMapping = (userSignupState: CustomerSignupState) => {
    if (userSignupState) {
        const componentMapper: {[S in CustomerSignupState]: SignUpState['step'] | ''} = {
            INITIAL: 'about-you',
            TERMS_AND_CONDITIONS: 'au-terms-and-conditions',
            EMAIL_VERIFICATION: 'email-verification',
            IDENTITY_DETAILS: 'identity-details',
            ADDRESS_VERIFICATION: 'address',
            ADDRESS_UPLOAD: 'proof-of-address-upload',
            TAX_QUESTIONS: 'tax-questions',
            NZX_PARTICIPANT: 'prescribed-person',
            NATURE_AND_PURPOSE: 'nature-and-purpose',
            AWAITING_ADDRESS_UPLOAD: 'manual-address-upload-au',
            AWAITING_ADDRESS_REVIEW: 'manual-address-review',
            WELCOME: 'welcome',
            COMPLETED: 'completed',
            DEPENDENT_DECLARATION: 'dependent-declaration',
            IRD_NUMBER: 'ird-number',
            CITIZENSHIP: '', // Note: Refactor in backend to split different ENUM those are not use
            CERTIFICATION: '',
            MAKE_ACTIVE: 'make-active',
            RESIGN_REQUIRED: '', // US only,
            RESIGN_CERTIFICATION: '', // US only
            RESIGN_WELCOME: '', // US only
        }
        return componentMapper[userSignupState] as SignUpState['step']
    }
}

export const getStepFromStateMachine = (
    userSignUpState: User['customer_signup_state'],
    state: SignUpState,
    user: User,
) => {
    const {pinState, uploadSkipped} = state
    if (userSignUpState) {
        if (pinState) {
            return {step: pinState}
        }

        if (
            (user.checks.identity_verification.address_verified || user.checks.fallback_dob_verified) &&
            state.step === 'address'
        ) {
            return {step: 'address-success'}
        }

        if (uploadSkipped && userSignUpState === 'ADDRESS_UPLOAD') {
            return {step: 'tax-questions'}
        }

        // This allow us to map the state machine state to components if there is no `pinState` for
        return {step: stateMachineComponentMapping(userSignUpState)}
    }
}

class SignUp extends React.Component<SignUpProps, SignUpState> {
    public state: SignUpState = {
        step: 'about-you',
        pinState: null,
        uploadSkipped: false,
        existingUser: this.props.user ? this.props.user.state === 'active' : false,
        tcUpdate: this.props.user ? !this.props.user.checks.tc_accepted : false,
    }

    private sendFakeAnalyticsPage(step: SignUpState['step']) {
        const {
            router: {location},
        } = this.props
        Analytics.pageNav(`${location.pathname}/${step}`)
    }

    public componentDidMount() {
        if (
            !this.props.isSwitching &&
            this.props.user &&
            this.props.user.state === 'active' &&
            this.props.user.has_seen.welcome_screens && // someone can be active but not have seen welcome yet
            !additionalSignUpStepsCheck(this.props.user) &&
            !this.props.router.searchParams.get('transfer_token') // not a logged in user whose kid is following a link to transfer their account
        ) {
            // user should be at a step of 'completed' or handled by special logic
            // for the transfer_token. navigation happens in the case statement in render.
        } else {
            this.sendFakeAnalyticsPage(this.state.step)
            // we need to show either the signup, update or welcome pages - hide the sidebar
            // UNLESS it's a new kid's account
            if (
                !this.props.isSwitching &&
                (!this.props.user || // new sign up
                    !this.props.user.is_dependent || // existing account
                    (this.props.user.is_dependent && this.props.user.state === 'active')) // existing kid's account
            ) {
                this.props.hideFlyoutMenu()
            }
        }
    }

    public componentDidUpdate(_prevProps: SignUpProps, prevState: SignUpState) {
        const {step} = this.state
        if (step !== prevState.step && step !== 'welcome') {
            this.sendFakeAnalyticsPage(step)
        }
    }

    public static getDerivedStateFromProps(props: SignUpProps, state: SignUpState): Partial<SignUpState> | null {
        const {user} = props

        /**
         * Anonymous users get the "About you" page first up
         * The "About you" step is made up of 2 screens, the Country Selector & About You details form.
         * The country selected in the first screen is stored with the user when the details form is completed.
         */
        if (!user) {
            if (state.step === 'about-you') {
                // Leave them on this page
                return null
            }
            // They've logged out, send them to the home page
            return {step: 'logged-out'}
        } else {
            const userSignupState = user.customer_signup_state

            // handle logged in existing users who are trying to get one of their dependant accounts
            // to sign up for a transferred account, via the email we sent the dependant (on the same device
            // while they are still signed in)
            if (props.router.searchParams.get('transfer_token') && state.existingUser && !state.tcUpdate) {
                // log them out
                props.logout()

                // leave them on the about you step
                return null
            }

            // handle users that predate the state machine, or that have additionalSignUpStepsCheck steps to complete
            if (!userSignupState && state.existingUser && !additionalSignUpStepsCheck(user)) {
                // skip them straight out of sign up
                return {step: 'completed'}
            } else if (state.existingUser && !user.checks.tc_accepted) {
                return {step: 'terms-and-conditions-update'}
            } else if (state.existingUser && !user.checks.prescribed_answered && user.jurisdiction === 'nz') {
                // ^ per check in additionalSignUpStepsCheck
                return {step: 'prescribed-person'}
            } else if (state.existingUser && !user.is_dependent && !user.checks.nature_and_purpose_answered) {
                // ^ per check in additionalSignUpStepsCheck
                return {step: 'nature-and-purpose'}
            }

            // do State Machine component mapping
            return getStepFromStateMachine(userSignupState, state, user) as SignUpState
        }
    }

    clearPinState = () => {
        this.setState(state => {
            state = {...state, pinState: null}
            return {
                ...state,
                ...SignUp.getDerivedStateFromProps(this.props, state),
            }
        })
    }

    public render(): React.ReactNode {
        const {
            user,
            showFlyoutMenu,
            makeActive,
            router: {navigate, searchParams},
        } = this.props
        const {step, existingUser} = this.state

        if (this.props.isSwitching) {
            return <LoadingComponent />
        }

        switch (step) {
            case 'about-you':
                return (
                    <AboutYou
                        referral_code={searchParams.get('referral_code') || undefined}
                        promo_code={searchParams.get('promo_code') || undefined}
                        kidsAccountTransferToken={searchParams.get('transfer_token') || undefined}
                    />
                )
            case 'au-terms-and-conditions':
                return <AUTermsAndConditions />
            case 'terms-and-conditions-update':
                return <TermsAndConditionsUpdate />
            case 'dependent-declaration':
                return <DependentDeclaration />
            case 'product-preference':
                return <ProductPreferenceSelector />
            case 'email-verification':
                return <EmailVerification />
            case 'identity-details':
                if (user!.is_dependent) {
                    return <DependentIdentityDetails setStep={this.clearPinState} />
                }
                return <IdentityDetails setStep={() => this.setState({pinState: 'identity-success'})} />
            case 'identity-success':
                return <IdentityVerificationSuccess onNext={this.clearPinState} />
            case 'address':
                return <Address />
            case 'address-success':
                return <AddressSuccess onNext={this.clearPinState} />
            case 'proof-of-address-upload':
                return (
                    <AddressVerification
                        onSkip={() => {
                            this.setState({uploadSkipped: true})
                            rudderTrack('signup', 'address_verification_skipped')
                        }}
                        onSuccess={() => this.setState({pinState: 'address-upload'})}
                    />
                )
            case 'manual-address-upload-au':
                // Manual proof of address is required but nothing has been uploaded yet
                return (
                    <AUCustomerAddressUploadStillRequired
                        onNext={() => this.setState({pinState: 'return-to-address-upload'})}
                    />
                )
            case 'manual-address-review':
                // A manual address is has been uploaded and is awaiting review
                return <AUCustomerUnderReview />
            case 'return-to-address-upload':
                return (
                    <AddressVerification
                        onSkip={() => {
                            this.setState({uploadSkipped: true})
                            rudderTrack('signup', 'address_verification_skipped')
                        }}
                        onSuccess={() => this.setState({pinState: 'address-upload'})}
                        hideSkipButton
                    />
                )
            case 'address-upload':
                return (
                    <AddressUploaded
                        onNext={() => {
                            this.clearPinState()
                            this.setState({uploadSkipped: true})
                        }}
                    />
                )
            case 'ird-number':
                return <IRDNumber />
            case 'tax-questions':
                return <TaxQuestions onSuccess={this.clearPinState} />
            case 'prescribed-person':
                return <PrescribedPerson />
            case 'nature-and-purpose':
                return <NatureAndPurpose isExistingUser={existingUser} />
            case 'make-active':
                makeActive()
                return <LoadingComponent />
            case 'welcome':
                if (user && !existingUser) {
                    if (user.is_dependent) {
                        // Kids account sign up
                        if (user.jurisdiction === 'nz') {
                            redditTrackCustom('Kids Account Open')
                            ReactPixel.trackSingle(config.facebookPixelIdNZ, 'kids account open', {successful: true})
                            Analytics.event({category: 'kids', action: 'account-open', label: 'nz'})
                        }
                        if (user.jurisdiction === 'au') {
                            ReactPixel.trackSingle(config.facebookPixelIdAU, 'kids account open', {successful: true})
                        }
                    } else {
                        // Regular sign up
                        if (user.jurisdiction === 'nz') {
                            ReactPixel.trackSingle(config.facebookPixelIdNZ, 'signup complete', {successful: true})
                            redditTrackSignUp()
                        }
                        if (user.jurisdiction === 'au') {
                            ReactPixel.trackSingle(config.facebookPixelIdAU, 'signup complete', {successful: true})
                        }
                        Analytics.event({category: 'Sign Up', action: 'Signup complete', label: user.jurisdiction})
                        Analytics.signUpFloodlightEvent(user.jurisdiction)
                    }
                }

                if (user?.is_dependent) {
                    queryClient.invalidateQueries(['profiles']) // make sure we have a fresh list of profiles for Welcome
                    return <WelcomeDependent />
                }
                return <Welcome />
            case 'completed':
                showFlyoutMenu() // some update steps like nature and purpose being missing need flyout re-showing
                return <Navigate to={urlFor('')} />
            case 'logged-out':
                showFlyoutMenu()
                navigate('/')
                return <div />
            default:
                assertNever(step)
        }

        throw new Error("This should never happen, user that has finished sign up hasn't been made active")
    }
}

interface StoreProps {
    isSwitching: boolean
    user: User | null
    registeredPreferenceAt: DateTime | undefined
    canSeePreferenceSelection: boolean
}

interface DispatchProps {
    hideFlyoutMenu(): void
    showFlyoutMenu(): void
    makeActive(): void
    logout(): void
}

type SignUpProps = StoreProps & DispatchProps & WithRouterProps

export default connect<StoreProps, DispatchProps, {}>(
    state => {
        return {
            isSwitching: !!state.identity.switchingTo,
            user: state.identity.user,
            registeredPreferenceAt: state.identity.user?.registered_preference_at,
            canSeePreferenceSelection:
                'product_preference_selection' in state.identity.flags && state.identity.user?.jurisdiction === 'nz',
        }
    },
    dispatch => ({
        hideFlyoutMenu: () => dispatch(actions.HideFlyoutMenu()),
        showFlyoutMenu: () => dispatch(actions.ShowFlyoutMenu()),
        makeActive: () => dispatch(identityActions.makeActive()),
        logout: () => dispatch(identityActions.Logout()),
    }),
)(withRouter(SignUp))
