import {Mode} from '../identity-check/util'

export type ManualIdentityStep =
    | 'identitySelection'
    | 'identityForms'
    | 'NZfirearmsLicence'
    | 'OverseasPassport'
    | 'manualIDSelection'
    | 'nzAccesscard'
    | 'Complete'
    | null

export const getNextStep = (step: ManualIdentityStep, mode: Mode): ManualIdentityStep => {
    switch (step) {
        case 'identitySelection':
            return mode === 'other' ? 'manualIDSelection' : 'identityForms'
        case 'OverseasPassport':
            return 'Complete'
        case 'NZfirearmsLicence':
            return 'Complete'
        case 'manualIDSelection':
            return 'nzAccesscard'
        case 'nzAccesscard':
            return 'Complete'
    }
    return step ? 'identitySelection' : 'Complete'
}

export const getPreviousStep = (step: ManualIdentityStep): ManualIdentityStep => {
    switch (step) {
        case 'manualIDSelection':
            return 'identitySelection'
        case 'nzAccesscard':
            return 'manualIDSelection'
        case 'Complete':
            return 'nzAccesscard'
    }
    return step ? 'identitySelection' : 'Complete'
}
