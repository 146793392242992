/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface InstrumentHolding
 */
export interface InstrumentHolding {
    /**
     *
     * @type {string}
     * @memberof InstrumentHolding
     */
    instrument_uuid: string
    /**
     *
     * @type {number}
     * @memberof InstrumentHolding
     */
    holding: number
    /**
     *
     * @type {number}
     * @memberof InstrumentHolding
     */
    rakaia_holding?: number
}

export function InstrumentHoldingFromJSON(json: any): InstrumentHolding {
    return InstrumentHoldingFromJSONTyped(json, false)
}

export function InstrumentHoldingFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstrumentHolding {
    if (json === undefined || json === null) {
        return json
    }
    return {
        instrument_uuid: json['instrument_uuid'],
        holding: json['holding'],
        rakaia_holding: !exists(json, 'rakaia_holding') ? undefined : json['rakaia_holding'],
    }
}

export function InstrumentHoldingToJSON(value?: InstrumentHolding | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        instrument_uuid: value.instrument_uuid,
        holding: value.holding,
        rakaia_holding: value.rakaia_holding,
    }
}
