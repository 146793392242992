import {Button} from '@design-system/button'
import {Switch} from '@design-system/switch'
import cn from 'classnames'
import React from 'react'
import {debounce} from 'throttle-debounce'
import {spacing} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {isLoadingOrUninitialised, isUninitialised} from '~/global/utils/is-loading/isLoading'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ErrorBox} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import styles from '~/sections/invest/sections/bank-linking/BankLinking.scss'
import actions from '~/store/bankLinking/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'

// FYI - This is under a feature flag and designs are not confirmed.
// Bank linking is currently only seen by Sharesies AU staff beta testing the feature

interface SelectMonitoringBankAccountsProps {
    onBack: () => void
    onNext?: () => void // If no 'onNext' then redux actions fire on every change
}

const SelectMonitoringBankAccounts = ({onBack, onNext}: SelectMonitoringBankAccountsProps) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const {basiqAccountsLoadingState, roundupsMonitoringAccounts, basiqAccountsResponse, institutionName} =
        useAppSelector(({bankLinking}) => ({
            basiqAccountsLoadingState: bankLinking.basiqAccountsLoadingState,
            basiqAccountsResponse: bankLinking.basiqAccountsResponse,
            roundupsMonitoringAccounts: bankLinking.roundupsMonitoringAccounts,
            institutionName: bankLinking.basiqAccountsResponse?.institution_name ?? '',
        }))

    const [selectedAccountIds, setSelectedAccounts] = React.useState<string[]>(
        roundupsMonitoringAccounts.map(acc => acc.basiqAccountId),
    )
    const [submitting, setSubmitting] = React.useState(false)

    const [error, setError] = React.useState<string>()

    const isLoading = isLoadingOrUninitialised(basiqAccountsLoadingState)

    React.useEffect(() => {
        if (isUninitialised(basiqAccountsLoadingState)) {
            dispatch(actions.FetchBasiqAccounts())
        }
        if (!isLoading && roundupsMonitoringAccounts) {
            // retrieve accounts from redux and update local state on load
            setSelectedAccounts(roundupsMonitoringAccounts.map(account => account.basiqAccountId))
        }
    }, [basiqAccountsLoadingState])

    const save = React.useCallback(
        async (newSelectedIds: string[], whenDone?: () => void) => {
            setSubmitting(true)

            // Check for no account updates
            if (
                newSelectedIds.length === roundupsMonitoringAccounts.length &&
                roundupsMonitoringAccounts.every(acc => newSelectedIds.includes(acc.basiqAccountId))
            ) {
                whenDone?.()
            } else {
                await dispatch(actions.UpdateMonitoringBankAccounts(newSelectedIds))
                    .then(res => {
                        if (res?.type === 'error') {
                            setError(res.message)

                            // revert displayed selection to last server state
                            setSelectedAccounts(roundupsMonitoringAccounts.map(account => account.basiqAccountId))
                        } else {
                            setError(undefined)
                            whenDone?.()
                        }
                    })
                    .catch(() => {
                        setError(unknownErrorMessage)
                    })
            }
            setSubmitting(false)
        },
        [roundupsMonitoringAccounts],
    )

    const throttledSave = React.useCallback(debounce(1500, save, {atBegin: false}), [save])

    const handleSubmit = async () => {
        throttledSave.cancel()
        await save(selectedAccountIds, onNext)
    }

    const handleOnChange = (id: string, toggledOn: boolean) => {
        const selectedIdSet = new Set(selectedAccountIds)

        if (toggledOn) {
            selectedIdSet.add(id)
        } else {
            selectedIdSet.delete(id)
        }

        const selectedIds = Array.from(selectedIdSet)
        setSelectedAccounts(selectedIds)

        if (!onNext) {
            throttledSave(selectedIds)
        }
    }

    if (isLoading) {
        return (
            <>
                <Toolbar
                    leftButton="back"
                    dataTestId="toolbar--banklinking-select-account"
                    onLeftButtonClick={onBack}
                />
                <Page>
                    <Loading />
                </Page>
            </>
        )
    }

    const selectPageContent = () => {
        const {institution_name, accounts} = basiqAccountsResponse!

        return (
            <>
                <Page>
                    <div className={styles.monitoringAccountSelect}>
                        <h1 className={cn(spacing.spaceBelow12)}>Choose accounts to track for Round-ups</h1>
                        <div>
                            <p>
                                We’ll round up the purchases in the accounts you choose. You can choose one or as many
                                as you like from the accounts shown.
                            </p>
                            <div className={cn(styles.institution, spacing.spaceBelow16, spacing.spaceAbove16)}>
                                {accounts[0] ? (
                                    <img src={accounts[0].bank_logo} alt="Bank logo" role="presentation" />
                                ) : null}
                                <h2>Choose {institution_name ? institution_name + ' ' : ''}account(s)</h2>
                            </div>
                            <ErrorBox className={error ? spacing.spaceAbove8 : ''} message={error} />
                            {accounts!.map(account => {
                                const isSwitchedOn = selectedAccountIds.includes(account.basiq_account_id)
                                const label = (
                                    <>
                                        <p>{account.account_name}</p>
                                        <p>
                                            Bal: <DollarValue value={account.balance} />
                                        </p>
                                    </>
                                )
                                return (
                                    <div
                                        key={account.basiq_account_id}
                                        className={cn(styles.accountRow, spacing.spaceBelow16)}
                                    >
                                        <Switch
                                            isTouched
                                            value={isSwitchedOn}
                                            onChange={() => handleOnChange(account.basiq_account_id, !isSwitchedOn)}
                                            dataTestId={`switch--select-account-${account
                                                .account_name!.split(' ')
                                                .join('-')}`}
                                            name={account.account_name!}
                                            id={account.basiq_account_id}
                                            label={label}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Page>
                {onNext && (
                    <ActionBar className={styles.splitActionBar}>
                        <Button
                            dataTestId="button--save-select-account"
                            label="Next"
                            disabled={selectedAccountIds.length === 0}
                            processing={submitting}
                            onClick={handleSubmit}
                        />
                    </ActionBar>
                )}
            </>
        )
    }

    const noAccountsAvailableContent = () => {
        const unlinkBank = async () => {
            setSubmitting(true)
            await dispatch(actions.UnlinkBank())
        }
        return (
            <>
                <Page>
                    <h1 className={cn(styles.h1, spacing.spaceBelow16)}>No accounts available to use for Round-ups</h1>
                    <p>
                        We’ve linked to {institutionName} bank but unfortunately we couldn’t find any accounts to either
                        track or debit for Round-ups. This may be due to:
                    </p>
                    <ul className={styles.listPadding}>
                        <li>Only holding a credit card account.</li>
                        <li>
                            Only holding accounts you can’t draw on such as mortgage, term deposit or savings accounts.
                        </li>
                        <li>Your bank isn’t supplying the data we require for round-ups</li>
                    </ul>
                    {(submitting && <Loading />) || null}
                </Page>
                <ActionBar>
                    <div>
                        <Button
                            additionalClassName={spacing.spaceBelow8}
                            dataTestId="button--link-another"
                            label="Unlink bank and link a different bank"
                            disabled={submitting}
                            onClick={async () => {
                                await unlinkBank()
                                navigate(profileUrl('explore/roundups'))
                            }}
                        />
                        <Button
                            dataTestId="button--unlink-bank"
                            label="Unlink bank"
                            type="secondary"
                            disabled={submitting}
                            onClick={async () => {
                                await unlinkBank()
                                navigate(profileUrl('explore'))
                            }}
                        />
                    </div>
                </ActionBar>
            </>
        )
    }

    return (
        <>
            <Toolbar leftButton="back" dataTestId="toolbar--banklinking-select-account" onLeftButtonClick={onBack} />
            {basiqAccountsResponse!.accounts.length > 0 ? selectPageContent() : noAccountsAvailableContent()}
        </>
    )
}

export default SelectMonitoringBankAccounts
