import {useAtomValue} from 'jotai'
import React from 'react'
import {SignUpStepCommonProps} from '~/sections/moose/sections/sign-up/SignUp'
import MobileAuthComplete from '~/sections/moose/sections/sign-up/sections/mobile-auth/pages/mobile-auth-complete/MobileAuthComplete'
import MobileAuthRequired from '~/sections/moose/sections/sign-up/sections/mobile-auth/pages/mobile-auth-required/MobileAuthRequired'
import {stagedSignUpAtom} from '~/sections/moose/sections/sign-up/state'

const MobileAuth = ({progressStep, regressStep, signupId}: SignUpStepCommonProps) => {
    const stagedSignUp = useAtomValue(stagedSignUpAtom)

    if (stagedSignUp?.hasMobileAuth && stagedSignUp?.signupAttemptToken) {
        return <MobileAuthComplete signupId={signupId} progressStep={progressStep} regressStep={regressStep} />
    }

    return <MobileAuthRequired signupId={signupId} progressStep={progressStep} regressStep={regressStep} />
}

export default MobileAuth
