import React from 'react'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Link} from '~/migrate-react-router'
import styles from './NoInstrumentMessage.scss'

interface NoInstrumentMessageProps {
    para1: string
    para2: string
    linkText?: string
}

export const NoInstrumentMessage = ({para1, para2, linkText}: NoInstrumentMessageProps) => {
    const profileUrl = useProfileUrl()

    return (
        <div className={styles.noInstrument}>
            <p>
                {para1}
                <br />
                {para2}
            </p>
            {linkText && (
                <p>
                    <Link to={profileUrl('explore')}>{linkText}</Link>
                </p>
            )}
        </div>
    )
}
