import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {useActor} from '~/global/state-hooks/retail/useActor'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {AlertCard} from '~/global/widgets/alert-card/AlertCard'
import {JoinedInSentence} from '~/global/widgets/joined-in-sentence/JoinedInSentence'
import {WalletValue} from '~/global/widgets/number-elements/NumberElements'
import {Tooltip} from '~/global/widgets/tooltip/Tooltip'
import {BalanceItem} from '~/sections/wallet/pages/overview/widgets/balance-item/BalanceItem'
import {useWalletPortfolio} from '~/sections/wallet/state/local'
import {useBalances} from '~/sections/wallet/state/retail'
import styles from './WalletSummary.scss'

export const WalletSummary = () => {
    const walletPortfolio = useWalletPortfolio()
    const profile = useProfile()
    const actor = useActor()
    const balances = useBalances()
    const walletLabel = profile.is_personal ? 'Your Wallet’s' : `${profile.name}’s Wallet`

    const walletsNegative: (typeof balances)[number]['currency'][] = []
    const walletsActive: (typeof balances)[number]['currency'][] = []
    balances.forEach(({balance, currency}) => {
        if (Number(balance) < 0) {
            walletsNegative.push(currency)
        }

        if (Number(balance) !== 0 || currency === actor.default_display_currency) {
            walletsActive.push(currency)
        }
    })

    const showWalletBalancesList = walletsActive.length > 1 // only show the list if there are multiple wallets with a balance (plus the wallet currency)

    return (
        <div className={styles.walletSummary}>
            <div className={styles.walletHomeCurrencyCard}>
                <span className={styles.bigValue}>
                    <WalletValue value={walletPortfolio.balance} currency={actor.default_display_currency} />
                </span>
                <div className={styles.message}>
                    <Tooltip>
                        <Tooltip.Label>Estimated</Tooltip.Label>
                        <Tooltip.Body>Estimate based on the most recent exchange rates</Tooltip.Body>
                    </Tooltip>{' '}
                    available to invest
                </div>
            </div>
            {showWalletBalancesList && (
                <ol className={styles.walletBalancesList}>
                    {balances.map(({currency, balance, estimated_balance_as_display_currency}) => (
                        <BalanceItem
                            key={currency}
                            currency={currency}
                            balance={balance}
                            // Estimated balance is shown in a tooltip on the row, we intentionally don't want to
                            // display it for the portfolio's native currency.
                            estimatedBalance={
                                currency !== actor.default_display_currency
                                    ? estimated_balance_as_display_currency
                                    : undefined
                            }
                            estimatedCurrency={
                                currency !== actor.default_display_currency ? actor.default_display_currency : undefined
                            }
                        />
                    ))}
                </ol>
            )}

            {walletsNegative.length > 0 && (
                <AlertCard type="warning" className={spacing.spaceAbove16}>
                    <p>
                        {walletLabel}{' '}
                        {walletsNegative.length === 1 && (
                            <>
                                {walletsNegative[0].toUpperCase()} balance is currently negative. To bring it back into
                                the positive, {walletsNegative[0] === actor.default_display_currency ? 'top up or' : ''}{' '}
                                exchange money from another currency.
                            </>
                        )}
                        {walletsNegative.length > 1 && (
                            <>
                                <JoinedInSentence
                                    nodes={walletsNegative.map(currency => (
                                        <>{currency.toUpperCase()}</>
                                    ))}
                                />{' '}
                                balances are currently negative. To bring them back into the positive, top up, then
                                exchange money.
                            </>
                        )}
                    </p>
                </AlertCard>
            )}
        </div>
    )
}
