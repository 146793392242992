import React from 'react'
import {Model} from '~/api/retail/types'
import {selectInstrumentOrders} from '~/store/accounting/selectors'
import {useAppSelector} from '~/store/hooks'
import {usePortfolioItemsById} from './usePortfolioItemsById'

export const useRemainingRightsOnChildInstrument = (instrumentId: string) => {
    const portfolioItemsById = usePortfolioItemsById()
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const orders = useAppSelector(s => selectInstrumentOrders(s, instrumentId))
    const instrument = instrumentsById[instrumentId]

    return React.useMemo(() => {
        let childInstrumentWithRemainingRights = null
        const childInstrumentIds = instrument.childInstrumentIds
        if (!childInstrumentIds) {
            return
        }

        // filter orders for exercise applications which have not been cancelled
        const exerciseOrders = orders.filter(
            o =>
                o.type === 'corporate_action_v2' &&
                o.action_type === 'EXERCISE' &&
                // ACCRUED CAs aren't terminal but impact sharesOwned, like terminal CAs do. This is counterintuitive
                // but the real problem is that we're trying to calculate your active account balance with frontend math
                !(o.is_terminal || o.flow_state === 'ACCRUED') &&
                o.application?.is_cancelled === false &&
                o.application?.application_amount,
        ) as Model.CorporateActionV2[]

        // look for a child rights instrument whose pending exercise order amount is less than the quantity of shares owned
        childInstrumentIds.forEach(childInstrumentId => {
            const childInstrument = instrumentsById[childInstrumentId]
            const holding = portfolioItemsById[childInstrumentId]

            if (childInstrument && childInstrument.instrumentType === 'rights' && holding && holding.sharesOwned > 0) {
                const pendingOrderAmount = exerciseOrders
                    .filter(o => o.fund_id === childInstrumentId)
                    .reduce((total, order) => total + parseFloat(order.application!.application_amount!), 0)

                if (holding.sharesOwned > pendingOrderAmount) {
                    childInstrumentWithRemainingRights = childInstrumentId
                }
            }
        })

        return childInstrumentWithRemainingRights
    }, [portfolioItemsById, instrumentsById, orders, instrument])
}
