import {useNavigate} from 'react-router-dom'
import * as rollbar from '~/api/rollbar/rollbar'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Toast} from '~/global/widgets/toast/Toast'
import {
    NotificationContextProps,
    NotificationModalProps,
} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {AutoinvestProcessNextStep} from '~/store/autoinvest/actions'

export const handleProcessResponse = async (
    responsePromise: Promise<AutoinvestProcessNextStep>,
    navigate: ReturnType<typeof useNavigate>,
    profileUrl: ReturnType<typeof useProfileUrl>,
    {showAutoinvestNotification, showModalError, showModalInfo}: NotificationContextProps,
    modalInfo?: NotificationModalProps,
): Promise<void> => {
    try {
        const response = await responsePromise
        if ('navigationDirective' in response) {
            if (response.notification === 'new') {
                await showAutoinvestNotification()
                if (modalInfo) {
                    setTimeout(() => {
                        showModalInfo(modalInfo)
                    }, 3500)
                }
            }
            if (response.notification === 'edit') {
                await Toast('Your auto-invest has been updated')
            }
            response.navigationDirective.execute(navigate, profileUrl, {replace: true})
        }
        if ('error' in response) {
            showModalError({message: response.error})
        }
    } catch (e) {
        rollbar.sendError(String(e))
        showModalError({message: unknownErrorMessage})
    }
}
