/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    EsInstrumentType,
    EsInstrumentTypeFromJSON,
    EsInstrumentTypeFromJSONTyped,
    EsInstrumentTypeToJSON,
    EsInstrumentTypeToJSONTyped,
    Explanation,
    ExplanationFromJSON,
    ExplanationFromJSONTyped,
    ExplanationToJSON,
    ExplanationToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface SearchDebugResponseDto
 */
export interface SearchDebugResponseDto {
    /**
     *
     * @type {string}
     * @memberof SearchDebugResponseDto
     */
    id: string
    /**
     *
     * @type {EsInstrumentType}
     * @memberof SearchDebugResponseDto
     */
    instrumentType: EsInstrumentType
    /**
     *
     * @type {string}
     * @memberof SearchDebugResponseDto
     */
    symbol: string
    /**
     *
     * @type {boolean}
     * @memberof SearchDebugResponseDto
     */
    kidsRecommended: boolean
    /**
     *
     * @type {string}
     * @memberof SearchDebugResponseDto
     */
    urlSlug: string
    /**
     *
     * @type {string}
     * @memberof SearchDebugResponseDto
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof SearchDebugResponseDto
     */
    websiteUrl: string
    /**
     *
     * @type {string}
     * @memberof SearchDebugResponseDto
     */
    country: string
    /**
     *
     * @type {Array<string>}
     * @memberof SearchDebugResponseDto
     */
    categories: Array<string>
    /**
     *
     * @type {string}
     * @memberof SearchDebugResponseDto
     */
    ceo: string
    /**
     *
     * @type {string}
     * @memberof SearchDebugResponseDto
     */
    exchange: string
    /**
     *
     * @type {number}
     * @memberof SearchDebugResponseDto
     */
    employees: number
    /**
     *
     * @type {number}
     * @memberof SearchDebugResponseDto
     */
    bucket?: number | null
    /**
     *
     * @type {Explanation}
     * @memberof SearchDebugResponseDto
     */
    explanation?: Explanation
    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof SearchDebugResponseDto
     */
    highlight: {[key: string]: Array<string>}
    /**
     *
     * @type {boolean}
     * @memberof SearchDebugResponseDto
     */
    notable?: boolean | null
    /**
     *
     * @type {boolean}
     * @memberof SearchDebugResponseDto
     */
    topHit?: boolean | null
}

export function SearchDebugResponseDtoFromJSON(json: any): SearchDebugResponseDto {
    return SearchDebugResponseDtoFromJSONTyped(json, false)
}

export function SearchDebugResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchDebugResponseDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        id: json['id'],
        instrumentType: EsInstrumentTypeFromJSON(json['instrumentType']),
        symbol: json['symbol'],
        kidsRecommended: json['kidsRecommended'],
        urlSlug: json['urlSlug'],
        name: json['name'],
        websiteUrl: json['websiteUrl'],
        country: json['country'],
        categories: json['categories'],
        ceo: json['ceo'],
        exchange: json['exchange'],
        employees: json['employees'],
        bucket: !exists(json, 'bucket') ? undefined : json['bucket'],
        explanation: !exists(json, 'explanation') ? undefined : ExplanationFromJSON(json['explanation']),
        highlight: json['highlight'],
        notable: !exists(json, 'notable') ? undefined : json['notable'],
        topHit: !exists(json, 'topHit') ? undefined : json['topHit'],
    }
}

export function SearchDebugResponseDtoToJSON(value?: SearchDebugResponseDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        id: value.id,
        instrumentType: EsInstrumentTypeToJSON(value.instrumentType),
        symbol: value.symbol,
        kidsRecommended: value.kidsRecommended,
        urlSlug: value.urlSlug,
        name: value.name,
        websiteUrl: value.websiteUrl,
        country: value.country,
        categories: value.categories,
        ceo: value.ceo,
        exchange: value.exchange,
        employees: value.employees,
        bucket: value.bucket,
        explanation: ExplanationToJSON(value.explanation),
        highlight: value.highlight,
        notable: value.notable,
        topHit: value.topHit,
    }
}
