/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface AppSchemasForClientPortfolioInstrumentInvestingHistoryDetails
 */
export interface AppSchemasForClientPortfolioInstrumentInvestingHistoryDetails {
    /**
     *
     * @type {string}
     * @memberof AppSchemasForClientPortfolioInstrumentInvestingHistoryDetails
     */
    date: string
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioInstrumentInvestingHistoryDetails
     */
    cost_basis: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioInstrumentInvestingHistoryDetails
     */
    total_return: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioInstrumentInvestingHistoryDetails
     */
    unrealised_total_return: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioInstrumentInvestingHistoryDetails
     */
    investment_value: number
}

export function AppSchemasForClientPortfolioInstrumentInvestingHistoryDetailsFromJSON(
    json: any,
): AppSchemasForClientPortfolioInstrumentInvestingHistoryDetails {
    return AppSchemasForClientPortfolioInstrumentInvestingHistoryDetailsFromJSONTyped(json, false)
}

export function AppSchemasForClientPortfolioInstrumentInvestingHistoryDetailsFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AppSchemasForClientPortfolioInstrumentInvestingHistoryDetails {
    if (json === undefined || json === null) {
        return json
    }
    return {
        date: json['date'],
        cost_basis: json['cost_basis'],
        total_return: json['total_return'],
        unrealised_total_return: json['unrealised_total_return'],
        investment_value: json['investment_value'],
    }
}

export function AppSchemasForClientPortfolioInstrumentInvestingHistoryDetailsToJSON(
    value?: AppSchemasForClientPortfolioInstrumentInvestingHistoryDetails | null,
): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        date: value.date,
        cost_basis: value.cost_basis,
        total_return: value.total_return,
        unrealised_total_return: value.unrealised_total_return,
        investment_value: value.investment_value,
    }
}
