import {useQuery} from '@tanstack/react-query'
import {contentfulClient} from '~/api/contentful/contentfulClient'
import {parseArticle} from '~/api/contentful/parseArticle'
import {ArticleEntry, ArticleType} from '~/api/contentful/types'
import {useAppSelector} from '~/store/hooks'

/**
 * useArticle takes a slug and returns a single ArticleType object
 *
 * @param {string} slug - The article url
 * @returns {ArticleType} ArticleType
 */
export function useArticle(slug: string): ArticleType | undefined {
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)

    const {data} = useQuery({
        cacheTime: 6 * 60 * 1000,
        staleTime: 5 * 60 * 1000,
        // Use jurisdiction to prevent an edge case where someone in a different jurisdiction would get cached content
        // as the article URLs are the same between jurisdictions
        queryKey: ['useArticle', `${jurisdiction}${slug}`],
        queryFn: async () => {
            // Contentful SDK v10+ `withoutUnresolvableLinks` needs to be added
            const entries = await contentfulClient.getEntries<ArticleEntry>({
                content_type: 'learnArticle', // @NOTE This will change to article in the coming weeks
                ['fields.url']: slug,
                include: 5,
                'metadata.tags.sys.id[all]': [jurisdiction, 'destinationProduct'].join(','),
            })

            if (entries.errors || entries.items.length === 0) {
                throw new Error(`No article found for ${slug}`)
            }

            const entry = entries.items[0]
            return parseArticle(entry)
        },
    })

    return data
}
