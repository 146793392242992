import {ChevronRight} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import FocusLock from 'react-focus-lock'
import {useNavigate} from 'react-router'
import {accessibility} from '~/global/scss/helpers'
import {useReadPortfolio} from '~/global/state-hooks/rakaia/useReadPortfolio'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {findExchangeRate} from '~/global/utils/find-exchange-rate/findExchangeRate'
import {useExchangeRates} from '~/global/utils/use-exchange-rates/useExchangeRates'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {FeedbackWithIntroText} from '~/global/widgets/feedback/Feedback'
import {InstrumentRowCompact} from '~/global/widgets/instrument-row/InstrumentRowCompact'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue, PercentValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import PreventScroll from '~/global/widgets/prevent-scroll/PreventScroll'
import sortMenuStyles from '~/global/widgets/sort-and-filter/SortAndFilter.scss'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {BreakdownGauge} from '~/sections/invest/sections/portfolio/sections/history/widgets/breakdown/PortfolioBreakdownV2'
import styles from '~/sections/invest/sections/portfolio/sections/history/widgets/breakdown/PortfolioBreakdownV2.scss'
import {NavigationRouterState} from '~/sections/invest/sections/view-instrument/widgets/navigation/Navigation'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'

export const BreakdownDetailV2Page = () => {
    const profile = useProfile()

    const investPortfolio = profile.portfolios.find(p => p.product === 'INVEST')!

    const instrumentBreakdown = useReadPortfolio(investPortfolio.id).portfolio_instrument_types
    const showMutualFund = useAppSelector(s => s.identity.user!.jurisdiction === 'nz')

    // Dropdown config
    const [displayAs, setDisplayAs] = React.useState<'category' | 'value'>('category')
    const [displayAsMenuShown, setDisplayAsMenuShown] = React.useState(false)
    React.useEffect(() => {
        if (!displayAsMenuShown) {
            return
        }

        const handleEscapeKey = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                if (displayAsMenuShown) {
                    setDisplayAsMenuShown(false)
                }
            }
        }
        window.addEventListener('keyup', handleEscapeKey)
        return () => {
            window.removeEventListener('keyup', handleEscapeKey)
        }
    }, [displayAsMenuShown])

    return (
        <>
            <Toolbar dataTestId="toolbar-portfolio-breakdown-detail" leftButton="back" hideIntercom />
            <Page className={styles.pageNoBottomPadding}>
                {!instrumentBreakdown ? (
                    <Loading />
                ) : (
                    <>
                        <BreakdownGauge isBigTitle />
                        <DisplayChangeFilter
                            onChange={newOption => setDisplayAs(newOption.id as 'category' | 'value')}
                            sortOptions={[
                                {id: 'category', name: 'Percentage'},
                                {id: 'value', name: 'Dollar'},
                            ]}
                            currentOptionId={displayAs}
                        />
                        <BreakdownForType
                            friendlyName="Companies"
                            typeName="equity"
                            className={styles.company}
                            percent={instrumentBreakdown.company?.percent}
                            displayAs={displayAs}
                        />
                        <BreakdownForType
                            friendlyName="ETFs"
                            typeName="etf"
                            className={styles.etf}
                            percent={instrumentBreakdown.etf?.percent}
                            displayAs={displayAs}
                        />
                        {showMutualFund ? (
                            <BreakdownForType
                                friendlyName="Managed funds"
                                typeName="mf"
                                className={styles.managedFund}
                                percent={instrumentBreakdown.mutual?.percent}
                                displayAs={displayAs}
                            />
                        ) : null}
                    </>
                )}
            </Page>
            {instrumentBreakdown ? (
                <FeedbackWithIntroText
                    actionIdentifier="/portfolio-history"
                    category="Portfolio"
                    askForComment="always"
                    title="To start with, how do you feel about the changes?"
                    commentLabel="We’d love to hear any thoughts you’ve got. Your feedback is always appreciated!"
                    introText="We're trying something new with our charts. We'd love to hear what you think and how we could make it better."
                />
            ) : null}
        </>
    )
}

interface ByCategoryProps {
    friendlyName: string
    typeName: string
    className: string
    percent?: number
    displayAs: 'category' | 'value'
}

const BreakdownForType: React.FC<ByCategoryProps> = ({friendlyName, typeName, className, percent, displayAs}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const profile = useProfile()

    const investPortfolio = profile.portfolios.find(p => p.product === 'INVEST')!
    const fullHoldings = useAppSelector(s => s.identity.holdings)
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)

    const {
        portfolioLoadingState,
        instrumentsById,
        portfolioIndex: loadedPortfolioItemsIndex,
        isMetadataInitialised,
    } = useAppSelector(s => s.instrument)

    const portfolioSummary = useReadPortfolio(investPortfolio.id)

    const holdingById = Object.fromEntries(fullHoldings.map(h => [h.fund_id, parseFloat(h.shares)]))
    const fullPortfolioInstrumentIds = fullHoldings.map(x => x.fund_id)
    const instrumentIds = fullPortfolioInstrumentIds.filter(
        id => id in instrumentsById && instrumentsById[id].instrumentType === typeName,
    )
    const hasInvestments = fullPortfolioInstrumentIds.length > 0

    const [exchangeRates] = useExchangeRates()
    const holdingValueInHomeCurrency = (id: string) => {
        const instrument = instrumentsById[id]
        if (!instrument) {
            return 0
        }
        const marketValue = parseFloat(instrument.marketPrice) * holdingById[id]
        if (instrument.currency === homeCurrency) {
            return marketValue
        }
        const exchangeRate = findExchangeRate({
            sourceCurrency: instrument.currency,
            targetCurrency: homeCurrency,
            exchangeRates,
        })
        if (!exchangeRate) {
            return marketValue // No exchange rates is handled by hiding this field
        }
        return marketValue * exchangeRate.rate
    }
    const holdingValueById_homeCurrency = Object.fromEntries(
        fullHoldings.map(h => [h.fund_id, holdingValueInHomeCurrency(h.fund_id)]),
    )
    const totalValueHomeCurrency = instrumentIds
        .map(x => holdingValueById_homeCurrency[x])
        .reduce((a: number, b: number) => a + b, 0)

    const instrumentIdsSorted = instrumentIds.sort(
        (a: string, b: string) => holdingValueById_homeCurrency[b] - holdingValueById_homeCurrency[a],
    )

    React.useEffect(() => {
        // don't try doing the load until after instrument metadata has loaded
        if (isMetadataInitialised) {
            // compare the IDs we know about from retail against the IDs we have loaded from the data service
            // we should only load portfolio instruments from distill when one / multiple retail ids are not
            // contained in `instrumentsById` (prev fetched instruments from distill)
            // otherwise we should use the existing instrument objects in the store, as to reduce complexity.
            const totalPortfolioHash = fullPortfolioInstrumentIds.slice(0).sort().toString()
            const loadedInstrumentsHash = loadedPortfolioItemsIndex.slice(0).sort().toString()
            if (hasInvestments && totalPortfolioHash !== loadedInstrumentsHash && portfolioLoadingState !== 'loading') {
                dispatch(instrumentActions.loadPortfolioInstruments(fullPortfolioInstrumentIds, true))
            }
        }
    }, [hasInvestments, isMetadataInitialised])

    if (!isMetadataInitialised || !portfolioSummary || portfolioLoadingState === 'loading') {
        return <Loading />
    }

    const instrumentNavState: NavigationRouterState = {
        tab: 'Your investment',
    }

    const exchangeRatesLoaded = !!exchangeRates.length

    const renderInstrumentLine = (id: string) => {
        const instrument = instrumentsById[id]
        const holdingValueHomeCurrency = holdingValueById_homeCurrency[id]
        const holdingValue = parseFloat(instrument.marketPrice) * holdingById[id]
        const holdingPercent = (holdingValueHomeCurrency * 100) / totalValueHomeCurrency

        return (
            <InstrumentRowCompact
                key={id}
                instrument={instrument}
                rowLinkTarget={profileUrl('invest/:instrumentSlug', {
                    instrumentSlug: instrument.urlSlug,
                })}
                rowTopBorder
                rightSlot={
                    <button
                        className={accessibility.button}
                        type="button"
                        onClick={() => {
                            navigate(
                                profileUrl('invest/:instrumentSlug', {
                                    instrumentSlug: instrument.urlSlug,
                                }),
                                {
                                    state: instrumentNavState,
                                },
                            )
                        }}
                    >
                        <div className={cn(styles.chevron, styles.withValueNumber)}>
                            {instrument.exchange && exchangeRatesLoaded && displayAs === 'category' ? (
                                <>
                                    <PercentValue value={holdingPercent} />
                                    <span className={styles.screenreaderOnly}> holding for {instrument.name}</span>
                                </>
                            ) : displayAs === 'value' ? (
                                <>
                                    <DollarValue value={holdingValue} currency={instrument.currency} />
                                    <span className={styles.screenreaderOnly}> holding for {instrument.name}</span>
                                </>
                            ) : (
                                <span className={styles.screenreaderOnly}>View holding for {instrument.name}</span>
                            )}
                            <ChevronRight colour="neutral200" />
                        </div>
                    </button>
                }
            />
        )
    }

    return (
        <div key={`by-type--${typeName}`} className={styles.category}>
            <h2>
                <span className={className}></span>
                {friendlyName} <Delimiter /> {percent ?? 0}%
            </h2>
            {exchangeRatesLoaded ? (
                <div className={styles.categorySummary}>
                    <DollarValue value={totalValueHomeCurrency} currency={homeCurrency} /> in {instrumentIds.length}{' '}
                    investments
                </div>
            ) : null}
            {instrumentIdsSorted.map(renderInstrumentLine)}
        </div>
    )
}

interface Option {
    id: string
    name: string
}
interface SortAndFilterProps {
    currentOptionId: string
    onSortHide?(): void
    onChange(newOption: Option): void
    sortOptions: Option[]
}

const DisplayChangeFilter: React.FunctionComponent<SortAndFilterProps> = ({
    currentOptionId,
    onSortHide,
    onChange,
    sortOptions,
}) => {
    const [menuShown, setMenuShown] = React.useState(false)

    React.useEffect(() => {
        if (!menuShown) {
            return
        }

        const handleEscapeKey = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                if (menuShown) {
                    setMenuShown(false)
                }
            }
        }

        window.addEventListener('keyup', handleEscapeKey)

        return () => {
            window.removeEventListener('keyup', handleEscapeKey)
        }
    }, [menuShown])

    const currentOption = sortOptions.find(option => option.id === currentOptionId) as Option

    return (
        <>
            <PreventScroll condition={menuShown} onlyPreventMobile />
            <div className={sortMenuStyles.controlsWrapper}>
                <button
                    type="button"
                    className={cn(sortMenuStyles.controls, styles.displayControl, accessibility.button)}
                    aria-expanded={menuShown}
                    onClick={() => {
                        window.scrollTo({top: 0})
                        setMenuShown(true)
                    }}
                >
                    Show holding by: <span>{currentOption.name}</span>
                </button>

                <div>
                    <div
                        className={cn(sortMenuStyles.menuOverlay, styles.menuOverlay, {[styles.visible]: menuShown})}
                        onClick={() => setMenuShown(false)}
                    >
                        {menuShown && (
                            <FocusLock autoFocus returnFocus>
                                <div className={cn(sortMenuStyles.menu, styles.menu, styles.filterDropdownMenu)}>
                                    <ul>
                                        {sortOptions.map(option => (
                                            <li
                                                key={option.id}
                                                onClick={() => {
                                                    onChange(option)
                                                    setMenuShown(false)
                                                    if (onSortHide) {
                                                        onSortHide()
                                                    }
                                                }}
                                                className={cn({
                                                    [sortMenuStyles.selected]: currentOption.id === option.id,
                                                })}
                                            >
                                                <button
                                                    type="button"
                                                    className={accessibility.button}
                                                    aria-label={`Select ${option.name} option`}
                                                >
                                                    {option.name}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </FocusLock>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
