import {loadStripe, Stripe, StripeCardNumberElement} from '@stripe/stripe-js'
import {Model} from '~/api/retail/types'
import * as rollbar from '~/api/rollbar/rollbar'
import config from '~/configForEnv'
import {UserSuitableError} from '~/global/utils/error-handling/errorHandling'

// Hook to wrap common stripe functions.
export default function use3DSecure(jurisdiction: Model.User['jurisdiction'], stripe?: Stripe) {
    async function createPaymentMethod(cardElement: StripeCardNumberElement): Promise<string> {
        if (!stripe) {
            throw Error('No stripe object')
        }
        const {paymentMethod, error} = await stripe.createPaymentMethod({type: 'card', card: cardElement})

        if (error) {
            if (error.type === 'card_error' || error.type === 'validation_error') {
                throw new UserSuitableError(error.message!)
            }
            throw Error(error.message)
        }

        return paymentMethod.id
    }

    // For cards that need additional 3D Secure authentication
    async function processCardRequiresAction(clientSecret: string): Promise<string> {
        // `handleNextAction` can be called within a different stripe context, so it's safe to load in
        const stripeLocal =
            stripe ?? (await loadStripe(jurisdiction === 'au' ? config.stripeAuKey : config.stripeNzKey))

        if (!stripeLocal) {
            throw Error('No stripe object')
        }

        const {paymentIntent, setupIntent, error} = await stripeLocal.handleNextAction({clientSecret})

        if (error) {
            if (
                error.type === 'card_error' ||
                error.type === 'validation_error' ||
                error.code === 'payment_intent_authentication_failure' ||
                error.code === 'setup_intent_authentication_failure'
            ) {
                throw new UserSuitableError(error.message!)
            }
            rollbar.sendError('Error handling stripe 3D Secure action', error)
            throw error
        }

        if (!paymentIntent?.id && !setupIntent?.id) {
            throw Error('No payment intent, setup intent or error after stripe.handleNextAction')
        }

        return paymentIntent?.id || setupIntent?.id || ''
    }

    return {createPaymentMethod, processCardRequiresAction}
}
