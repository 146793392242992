import {isOnMainUsExchange, isOnASX, isOnNZX} from '~/global/utils/is-on-exchange/isOnExchange'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {StagedOrder, isDIY} from '~/store/autoinvest/types'
import {Instrument} from '~/store/instrument/types'

export const hasForeignInstrument = (
    stagedOrder: StagedOrder | undefined,
    instrumentsById: {[key: string]: Instrument},
    jurisdiction: string,
) => {
    const isDIYOrder = stagedOrder && isDIY(stagedOrder.order)
    const isUsInstrument = (fund_id: string) => isOnMainUsExchange(instrumentsById[fund_id])
    const isAsxInstrument = (fund_id: string) => isOnASX(instrumentsById[fund_id])
    const isNzxInstrument = (fund_id: string) => isOnNZX(instrumentsById[fund_id])

    if (isDIYOrder) {
        if (
            stagedOrder.order.allocations.find(a => isUsInstrument(a.fund_id)) ||
            (jurisdiction === 'nz' && stagedOrder.order.allocations.find(a => isAsxInstrument(a.fund_id))) ||
            (jurisdiction === 'au' && stagedOrder.order.allocations.find(a => isNzxInstrument(a.fund_id)))
        ) {
            return true
        }
    }
    return false
}

export const hasManagedFund = (stagedOrder: StagedOrder | undefined, instrumentsById: {[key: string]: Instrument}) => {
    const isManagedFund = (fund_id: string) => tradingType(instrumentsById[fund_id]) === 'managed'

    if (!stagedOrder) {
        return false
    }

    if (isDIY(stagedOrder.order)) {
        return !!stagedOrder.order.allocations.find(a => isManagedFund(a.fund_id))
    } else {
        return !!stagedOrder.order.premadeOrder.allocations.find(a => isManagedFund(a.fund_id))
    }
}
