import {fetchEventSource} from '@microsoft/fetch-event-source'
import {useAtom} from 'jotai'
import * as api from '~/api/retail'
import {Response} from '~/api/retail/types'
import * as rollbar from '~/api/rollbar/rollbar'
import config from '~/configForEnv'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {
    lastPromptAtom,
    telescopeResponseAtom,
    telescopeLoadingStateAtom,
} from '~/sections/invest/sections/ai-search/state/telescopeResponseState'
import {parseTelescopeEventStream} from '~/sections/invest/sections/ai-search/utils/parse-telescope-stream/parseTelescopeStream'

let abortController = new AbortController()

/**
 * Hook to interact with the Telescope API and data.
 * https://telescope.co/
 */
export const useTelescope = () => {
    const [lastPrompt, setLastPrompt] = useAtom(lastPromptAtom)
    const [telescopeData, setTelescopeData] = useAtom(telescopeResponseAtom)
    const [loadingState, setLoadingState] = useAtom(telescopeLoadingStateAtom)

    const fetchToken = async (prompt: string): Promise<Response.TelescopeToken | Response.TelescopeRateLimited> => {
        // Fetch the token to perform this request.
        // Along with fetching the token, this endpoint stores the prompt in the database.
        const response = await api.post('ai-search/ai-search-token', {prompt})
        if (response.type === 'access_denied') {
            // The customer doesn't have the feature flag
            throw new Error(unknownErrorMessage)
        } else if (response.type === 'internal_server_error') {
            rollbar.sendError('method fetchToken failed', {prompt})
            throw new Error(unknownErrorMessage)
        }
        return response
    }

    const abortRequest = () => {
        abortController.abort()
        abortController = new AbortController()
        setLoadingState('idle')
        setTelescopeData({})
    }

    const sendPrompt = async (prompt: string) => {
        if (prompt === lastPrompt) {
            return
        }

        if (loadingState === 'processing') {
            // Cancel other telescope requests currently processing
            abortRequest()
        }
        setLoadingState('processing')
        setLastPrompt(prompt)
        setTelescopeData({})

        // Obtaining token is where any rate limit kicks in, so try this
        // first
        const encodedPrompt = encodeURIComponent(prompt)
        const aiSearchTokenResponse = await fetchToken(prompt)
        if (aiSearchTokenResponse.type === 'telescope_rate_limited') {
            setLoadingState('rate_limited')
            return
        }

        const aiSearchToken = aiSearchTokenResponse as Response.TelescopeToken

        fetchEventSource(config.telescopePath + `?query=${encodedPrompt}`, {
            headers: {
                Authorization: `Bearer ${aiSearchToken.token}`,
            },
            openWhenHidden: true,
            signal: abortController.signal,
            async onopen(response) {
                if (response.ok) {
                    setTelescopeData({})
                    return
                }
                rollbar.sendError('failed to send prompt to telescope-proxy', {response})
                throw response
            },
            onmessage(event) {
                setTelescopeData(data => parseTelescopeEventStream(data, event as any))
            },
            onclose() {
                // Absolute hack to get the latest telescopeData useState value.
                // This JS context's reference to telescopeData is from when this request started.
                setTelescopeData(data => {
                    api.post('ai-search/record-response', {
                        ai_search_request_id: aiSearchToken.ai_search_request_id,
                        response: JSON.stringify(data),
                    })
                    return data
                })
                setLoadingState('idle')
            },
            onerror(error) {
                // Throw an error, so fetchEventSource won't retry this.
                // If nothing is thrown in onerror, it will auto-retry the request
                setLoadingState('idle')
                throw error
            },
        })
    }

    return {telescopeData, loadingState, lastPrompt, sendPrompt, abortRequest}
}
