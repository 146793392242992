import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import fonterraSignupWide from '~/global/assets/images/fonterra-signup.png'
import {spacing} from '~/global/scss/helpers'
import {titleCase} from '~/global/utils/convert-to-title-case/convertToTitleCase'
import Page from '~/global/widgets/page/Page'
import {SharesiesLogo} from '~/global/widgets/sharesies-logo/SharesiesLogo'
import {useSignUpInitialDetail} from '~/sections/moose/data/retail'
import {SignUpStepCommonProps} from '~/sections/moose/sections/sign-up/SignUp'
import styles from './Landing.scss'

const Landing = ({progressStep, signupId}: SignUpStepCommonProps) => {
    const signupInitialDetail = useSignUpInitialDetail(signupId)

    return (
        <Page className={styles.pageContainer}>
            <div className={cn(styles.titleContainer, spacing.spaceAbove40)}>
                <SharesiesLogo />
                <h2 className={cn(styles.title, spacing.spaceAbove32)}>
                    Welcome to <br /> Sharesies
                </h2>
            </div>
            <div className={cn(spacing.spaceAbove32, styles.midBlock)}>
                <div className={styles.imageOverlayContainer}>
                    <img src={fonterraSignupWide} alt="Fonterra sign up" />
                    <div className={styles.imageText}>
                        <p>
                            {titleCase(signupInitialDetail.first_name)}{' '}
                            {signupInitialDetail?.last_name && titleCase(signupInitialDetail.last_name)}
                        </p>
                        <span>Fonterra Co-op shares</span>
                    </div>
                </div>
            </div>
            <Button
                label="Get started"
                additionalClassName={cn(styles.getStartedBtn)}
                type="primary"
                dataTestId="button--fonterra-signup"
                onClick={() => {
                    progressStep()
                    rudderTrack('fonterra_onboarding', 'get_started_clicked')
                }}
            />
        </Page>
    )
}

export default Landing
