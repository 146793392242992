import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import * as api from '~/api/retail'
import {spacing} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ErrorBox, Radio} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/invest/sections/bank-linking/BankLinking.scss'
import actions from '~/store/bankLinking/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'

export interface OnNextProps {
    onBack: () => void
    onNext?: () => void
    fromWholeDollar: boolean | undefined
}

const availableTargetAmounts = ['5', '10', '15', '25', '50'] as const
type TargetAmountValue = (typeof availableTargetAmounts)[number]

const ChooseDepositTargetRoundups = ({
    onBack,
    onNext,
    isInSetupFlow,
    fromWholeDollar,
}: OnNextProps & {isInSetupFlow?: boolean}) => {
    const dispatch = useAppDispatch()
    const isLoading = useAppSelector(({bankLinking}) => bankLinking.bankLinkingLoadingState === 'loading')
    const storeDepositTarget = useAppSelector(({bankLinking}) => bankLinking.depositTarget)
    const [targetAmount, setTargetAmount] = React.useState<TargetAmountValue | undefined>(
        // Remove any decimals
        isInSetupFlow ? undefined : (parseFloat(storeDepositTarget).toString() as TargetAmountValue),
    )
    const [processing, setProcessing] = React.useState(false)
    const [status, setStatus] = React.useState('')

    const save = async (submitValue: TargetAmountValue, next?: () => void) => {
        setProcessing(true)
        if (submitValue === storeDepositTarget) {
            next?.()
        } else {
            await api
                .post('banklinking/set-option-debit-target', {target_amount: submitValue!.toString()})
                .then(res => {
                    if (res.type === 'empty') {
                        // success
                        setStatus('')
                        dispatch(actions.SetDepositTarget(submitValue))
                        if (next) {
                            next()
                        }
                        if (!isInSetupFlow) {
                            Toast(`Nice! Your round-ups transfer amount is now $${submitValue}. 💸`)
                        }
                    } else if (res.type === 'error') {
                        setStatus(res.message)
                    } else if (res.type === 'internal_server_error') {
                        setStatus(unknownErrorMessage)
                    } else {
                        assertNever(res)
                    }
                })
                .catch(() => {
                    setStatus(unknownErrorMessage)
                })
        }
        setProcessing(false)
    }

    const handleSubmit = async () => {
        if (!targetAmount) {
            return
        }
        await save(targetAmount, onNext!)
    }

    const handleOnChange = async (value: TargetAmountValue) => {
        setTargetAmount(value)
        await save(value)
    }

    const actionBar = onNext ? (
        <ActionBar>
            <Button
                dataTestId="button--target-amount-roundups-next"
                label={fromWholeDollar ? "Let's get rounding" : 'Next'}
                disabled={!targetAmount}
                processing={processing}
                onClick={handleSubmit}
            />
        </ActionBar>
    ) : null

    return (
        <>
            <Toolbar leftButton="back" onLeftButtonClick={onBack} dataTestId="toolbar--banklinking-deposit-target" />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Page>
                        <h1 className={cn(styles.h1, spacing.spaceBelow12)}>
                            Send my round-ups to me when they total…
                        </h1>
                        <p className={spacing.spaceBelow16}>
                            When the total value of your round-ups reaches the amount you choose, we'll automatically
                            send them in one lump sum to your Wallet—rather than in bits and pieces from all the
                            accounts we're tracking.
                        </p>
                        <Radio
                            value={targetAmount}
                            label="Choose an amount"
                            choices={availableTargetAmounts.map(amount => ({
                                value: amount,
                                label: <DollarValue value={amount} />,
                            }))}
                            dataTestId="radio--roundups-target-amount"
                            name="roundups-target-amount"
                            isTouched={true}
                            onChange={e => handleOnChange(e.target.value as TargetAmountValue)}
                        />
                        <ErrorBox message={status} />
                    </Page>
                    {actionBar}
                </>
            )}
        </>
    )
}

export default ChooseDepositTargetRoundups
