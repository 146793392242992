import {Button} from '@design-system/button/Button'
import React, {useEffect} from 'react'
import {Link, useSearchParams} from 'react-router-dom'
import emailDark from '~/global/assets/images/envelope/envelope-dark.svg'
import emailLight from '~/global/assets/images/envelope/envelope-light.svg'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import LoadingScreen from '~/global/pages/loading-screen/LoadingScreen'
import Image from '~/global/widgets/image/Image'
import Page from '~/global/widgets/page/Page'
import {SharesiesLogo} from '~/global/widgets/sharesies-logo/SharesiesLogo'
import {Toast} from '~/global/widgets/toast/Toast'
import {
    ALREADY_VERIFIED_CODE,
    EXPIRED_CODE,
    GENERATED_CODE,
    SUCCESS_CODE,
} from '~/sections/user/sections/settings/constants/emailVerification'
import emailVerifiedDark from '~/sections/user/sections/sign-up/sections/post-activation/assets/images/email-verified-dark.svg'
import emailVerifiedLight from '~/sections/user/sections/sign-up/sections/post-activation/assets/images/email-verified-light.svg'
import {useAppDispatch} from '~/store/hooks'
import actions from '~/store/identity/actions'
import navActions from '~/store/nav/actions'
import styles from './VerifyEmail.scss'

export const VerifyEmail: React.FunctionComponent = () => {
    const [searchParams] = useSearchParams()
    const dispatch = useAppDispatch()

    const [tokenStatus, setTokenStatus] = React.useState<string | null>(null)
    const [resend, setResend] = React.useState<boolean>(false)
    const [resendDisabled, setResendDisabled] = React.useState<boolean>(false)

    const verificationToken = searchParams.get('token')

    const verifyToken = async (verificationToken: string): Promise<void> => {
        const response = await dispatch(actions.VerifyEmailToken(verificationToken))

        setTokenStatus(response)
    }

    const generateTokenFromExpired = async (): Promise<void> => {
        if (!verificationToken) {
            throw new Error(`Shouldn't be able to reach this page without a token`)
        }
        const response = await dispatch(actions.GenerateEmailVerifyTokenFromExpired(verificationToken))

        if (response === GENERATED_CODE) {
            setResend(true)
        } else {
            setTokenStatus(response)
        }
    }

    useEffect(() => {
        dispatch(navActions.HideFlyoutMenu())
    }, [])

    useEffect(() => {
        if (verificationToken) {
            verifyToken(verificationToken)
        }
    }, [verificationToken])

    const handleResend = () => {
        // Temporarily disabled button on click to prevent spam
        setResendDisabled(true)
        setTimeout(() => {
            setResendDisabled(false)
        }, 5000)

        if (!resendDisabled) {
            generateTokenFromExpired()
        }
    }

    if (!verificationToken) {
        return <WeSlippedUp />
    }

    if (!tokenStatus) {
        return <LoadingScreen />
    }

    if ([SUCCESS_CODE, ALREADY_VERIFIED_CODE].includes(tokenStatus)) {
        return (
            <VerifyEmailScreen>
                <h1>Nice! Your email address has been verified</h1>
                <p>
                    Feel free to close this tab—or{' '}
                    <a href="https://www.sharesies.nz/learn/investing-basics">check out some investing basics</a>.
                </p>
            </VerifyEmailScreen>
        )
    }

    if (tokenStatus === EXPIRED_CODE) {
        if (!resend) {
            return (
                <VerifyEmailScreen verified={false}>
                    <h1>Oh stink, your verification link has expired</h1>
                    <Button
                        type="primary"
                        label="Resend verification link"
                        pageButton
                        dataTestId="button--email-verification-resend"
                        onClick={handleResend}
                    />
                </VerifyEmailScreen>
            )
        } else {
            return (
                <VerifyEmailScreen verified={false}>
                    <p className={styles.mt16}>Cool, we've emailed you another verification link</p>
                    <p>
                        Didn't get an email?{' '}
                        <Link
                            to="#"
                            onClick={() => {
                                handleResend()
                                if (!resendDisabled) {
                                    Toast(
                                        'Nice! Now hit the verification link we just emailed you to verify your email address.',
                                    )
                                }
                            }}
                        >
                            Resend it
                        </Link>
                    </p>
                    <p>Feel free to close this tab</p>
                </VerifyEmailScreen>
            )
        }
    }

    // Invalid tokens get this
    return <WeSlippedUp />
}

const VerifyEmailScreen: React.FunctionComponent<{verified?: boolean}> = ({children, verified = true}) => {
    let img
    if (verified) {
        img = <Image src={emailVerifiedLight} darkSrc={emailVerifiedDark} />
    } else {
        img = <Image src={emailLight} darkSrc={emailDark} />
    }
    return (
        <Page className={styles.page}>
            <div className={styles.container}>
                <div className={styles.logo}>
                    <SharesiesLogo />
                </div>
                {img}
            </div>
            <div className={styles.container}>{children}</div>
        </Page>
    )
}

export default VerifyEmail
