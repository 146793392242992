import React from 'react'
import {DistillScope} from '~/api/query/distill'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import useAssetManager from '~/global/utils/use-asset-manager/useAssetManager'
import Concertina from '~/global/widgets/concertina/Concertina'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {Instrument} from '~/store/instrument/types'
import {InstrumentReturn} from '~/store/portfolio/types'
import styles from './DetailModalContents.scss'

const TotalReturnDetail = ({
    currentReturn,
    instrument,
    isDependent,
    preferredName,
    includeSoldInvestments,
}: {
    currentReturn: InstrumentReturn
    instrument: Instrument
    isDependent: boolean
    preferredName: string
    includeSoldInvestments: boolean
}) => {
    const assetManager = useAssetManager(instrument, {scope: DistillScope.INVEST})
    return (
        <div>
            <h1>{includeSoldInvestments ? 'Total return' : 'Return'}</h1>
            <p>
                There are many ways to work out returns. These calculations should not be relied upon to make investment
                decisions, they are indicative only and accuracy is not guaranteed.
            </p>
            <p>Past performance does not guarantee future results.</p>
            <p>
                {isDependent ? `${preferredName}’s` : 'Your'}
                {includeSoldInvestments && ' total'} return is a measure of the performance of{' '}
                {isDependent ? 'their' : 'your'} investment in {instrument.name}.
            </p>
            <p>
                This calculation {!includeSoldInvestments && ' only'} includes{' '}
                {shareLabel({instrument, isPlural: true})} {isDependent ? 'they' : 'you'} currently own
                {includeSoldInvestments && ` and any ${isDependent ? 'they’ve' : 'you’ve'} sold`}.
            </p>
            {tradingType(instrument) === 'managed' && assetManager && (
                <p>
                    This doesn’t include management fees charged by {assetManager.name} and the fund that are included
                    in the unit price.
                </p>
            )}

            <Concertina
                title={
                    <div className={styles.concertinaTitle}>
                        <p className={styles.leftText}>Unrealised gains/losses</p>{' '}
                        <DollarValue
                            value={currentReturn.total_return_detail.unrealised_capital_gains}
                            currency={instrument.currency}
                        />
                    </div>
                }
                body={
                    <p className={styles.concertinaBody}>
                        The change in value of {isDependent ? `${preferredName}’s` : 'your'}{' '}
                        {shareLabel({instrument, isPlural: true})}. This is based on the difference between the price{' '}
                        {isDependent ? `${preferredName}` : 'you'} paid and the current {shareLabel({instrument})} price
                        (delayed by at least {tradingType(instrument) === 'managed' ? 'one day' : '20 mins'}).
                    </p>
                }
                border
            />
            {includeSoldInvestments && (
                <Concertina
                    title={
                        <div className={styles.concertinaTitle}>
                            <p className={styles.leftText}>Realised gains/losses</p>{' '}
                            <DollarValue
                                value={currentReturn.total_return_detail.realised_capital_gains}
                                currency={instrument.currency}
                            />
                        </div>
                    }
                    body={
                        <p className={styles.concertinaBody}>
                            The gain or loss on any {shareLabel({instrument, isPlural: true})}{' '}
                            {isDependent ? `${preferredName} has` : 'you’ve'} sold. This is the difference between the
                            price {isDependent ? 'they' : 'you'} paid for {shareLabel({instrument, isPlural: true})} and
                            the price {isDependent ? 'they' : 'you'} sold them for.
                        </p>
                    }
                    border
                />
            )}
            {tradingType(instrument) !== 'managed' && (
                <Concertina
                    title={
                        <div className={styles.concertinaTitle}>
                            <p className={styles.leftText}>Transaction fees</p>{' '}
                            <DollarValue
                                value={
                                    includeSoldInvestments
                                        ? -currentReturn.total_return_detail.transaction_fees
                                        : -currentReturn.amount_put_in_detail.transaction_fees_buys
                                }
                                currency={instrument.currency}
                            />
                        </div>
                    }
                    body={
                        <p className={styles.concertinaBody}>
                            The amount {isDependent ? `${preferredName} has` : 'you’ve'} paid in transaction fees when
                            buying{includeSoldInvestments && ' or selling'} {shareLabel({instrument, isPlural: true})}.
                        </p>
                    }
                    border
                />
            )}
            {tradingType(instrument) === 'managed' && assetManager && (
                <Concertina
                    title={
                        <div className={styles.concertinaTitle}>
                            <p className={styles.leftText}>Managed fund transaction fees</p>{' '}
                            <DollarValue
                                value={
                                    includeSoldInvestments
                                        ? -currentReturn.total_return_detail.managed_fund_transaction_fees
                                        : -currentReturn.amount_put_in_detail.unrealised_managed_fund_transaction_fees
                                }
                                currency={instrument.currency}
                            />
                        </div>
                    }
                    body={
                        <p className={styles.concertinaBody}>
                            The amount {isDependent ? `${preferredName} has` : 'you’ve'} paid in transaction fees when
                            buying{includeSoldInvestments && ' or selling'} units. These are charged by the fund, not
                            Sharesies. Transaction fees are estimates until they’re confirmed by {assetManager.name}
                            —this can take up to 30 days.
                        </p>
                    }
                    border
                />
            )}
            {tradingType(instrument) === 'adr' && (
                <Concertina
                    title={
                        <div className={styles.concertinaTitle}>
                            <p className={styles.leftText}>Depositary fees</p>{' '}
                            <DollarValue
                                value={
                                    includeSoldInvestments
                                        ? -currentReturn.total_return_detail.adr_fees
                                        : -currentReturn.total_return_detail.unrealised_adr_fees
                                }
                                currency={instrument.currency}
                            />
                        </div>
                    }
                    body={
                        <p className={styles.concertinaBody}>
                            The amount {isDependent ? `${preferredName} has` : 'you’ve'} paid in depositary fees.
                        </p>
                    }
                    border
                />
            )}
            <Concertina
                title={
                    <div className={styles.concertinaTitle}>
                        <p className={styles.leftText}>Dividends</p>{' '}
                        <DollarValue
                            value={
                                includeSoldInvestments
                                    ? currentReturn.total_return_detail.dividends
                                    : currentReturn.unrealised_dividends
                            }
                            currency={instrument.currency}
                        />
                    </div>
                }
                body={
                    <p className={styles.concertinaBody}>
                        The amount of dividends, interest and distributions{' '}
                        {isDependent ? `${preferredName} has` : 'you’ve'} received.
                    </p>
                }
                border
            />
            {tradingType(instrument) === 'managed' && (
                <Concertina
                    title={
                        <div className={styles.concertinaTitle}>
                            <p className={styles.leftText}>PIE tax</p>{' '}
                            <DollarValue
                                value={
                                    includeSoldInvestments
                                        ? currentReturn.tax_paid
                                        : currentReturn.total_return_detail.unrealised_tax_paid
                                }
                                currency={instrument.currency}
                            />
                        </div>
                    }
                    body={
                        <p className={styles.concertinaBody}>
                            The amount {isDependent ? `${preferredName} has` : 'you’ve'} paid in portfolio investment
                            entity (PIE) tax. Tax is deducted when you sell units, and at the end of the financial year
                            (31 March). If {isDependent ? `${preferredName} has` : 'you’ve'} received a tax refund, this
                            may be a positive amount.
                        </p>
                    }
                    border
                />
            )}

            <Concertina
                title={
                    <div className={styles.concertinaTitle}>
                        <p className={styles.leftText}>{includeSoldInvestments ? 'Total return' : 'Return'}</p>{' '}
                        <p className={styles.lastRightText}>
                            <DollarValue
                                value={
                                    includeSoldInvestments
                                        ? currentReturn.total_return
                                        : currentReturn.unrealised_total_return
                                }
                                currency={instrument.currency}
                            />
                        </p>
                    </div>
                }
                border
                noChevron
            />
        </div>
    )
}

export default TotalReturnDetail
