/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface CountRequestDto
 */
export interface CountRequestDto {
    /**
     *
     * @type {string}
     * @memberof CountRequestDto
     */
    query: string
    /**
     *
     * @type {Array<string>}
     * @memberof CountRequestDto
     */
    instruments?: Array<string> | null
    /**
     *
     * @type {string}
     * @memberof CountRequestDto
     */
    parentInstrument?: string | null
    /**
     *
     * @type {Array<string>}
     * @memberof CountRequestDto
     */
    categories?: Array<string> | null
    /**
     *
     * @type {Array<string>}
     * @memberof CountRequestDto
     */
    exchanges?: Array<string> | null
    /**
     *
     * @type {Array<string>}
     * @memberof CountRequestDto
     */
    instrumentTypes?: Array<string> | null
    /**
     *
     * @type {boolean}
     * @memberof CountRequestDto
     */
    nz?: boolean | null
    /**
     *
     * @type {boolean}
     * @memberof CountRequestDto
     */
    unlistedInstruments?: boolean | null
    /**
     *
     * @type {number}
     * @memberof CountRequestDto
     */
    minRisk?: number | null
    /**
     *
     * @type {number}
     * @memberof CountRequestDto
     */
    maxRisk?: number | null
    /**
     *
     * @type {boolean}
     * @memberof CountRequestDto
     */
    kidsRecommended?: boolean | null
    /**
     *
     * @type {Array<string>}
     * @memberof CountRequestDto
     */
    tradingStatuses?: Array<string> | null
    /**
     *
     * @type {string}
     * @memberof CountRequestDto
     */
    strategy?: string | null
    /**
     *
     * @type {Array<string>}
     * @memberof CountRequestDto
     */
    exchangeCountries?: Array<string> | null
    /**
     *
     * @type {boolean}
     * @memberof CountRequestDto
     */
    searchFundInvestments?: boolean
}

export function CountRequestDtoFromJSON(json: any): CountRequestDto {
    return CountRequestDtoFromJSONTyped(json, false)
}

export function CountRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountRequestDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        query: json['query'],
        instruments: !exists(json, 'instruments') ? undefined : json['instruments'],
        parentInstrument: !exists(json, 'parentInstrument') ? undefined : json['parentInstrument'],
        categories: !exists(json, 'categories') ? undefined : json['categories'],
        exchanges: !exists(json, 'exchanges') ? undefined : json['exchanges'],
        instrumentTypes: !exists(json, 'instrumentTypes') ? undefined : json['instrumentTypes'],
        nz: !exists(json, 'nz') ? undefined : json['nz'],
        unlistedInstruments: !exists(json, 'unlistedInstruments') ? undefined : json['unlistedInstruments'],
        minRisk: !exists(json, 'minRisk') ? undefined : json['minRisk'],
        maxRisk: !exists(json, 'maxRisk') ? undefined : json['maxRisk'],
        kidsRecommended: !exists(json, 'kidsRecommended') ? undefined : json['kidsRecommended'],
        tradingStatuses: !exists(json, 'tradingStatuses') ? undefined : json['tradingStatuses'],
        strategy: !exists(json, 'strategy') ? undefined : json['strategy'],
        exchangeCountries: !exists(json, 'exchangeCountries') ? undefined : json['exchangeCountries'],
        searchFundInvestments: !exists(json, 'searchFundInvestments') ? undefined : json['searchFundInvestments'],
    }
}

export function CountRequestDtoToJSON(value?: CountRequestDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        query: value.query,
        instruments: value.instruments,
        parentInstrument: value.parentInstrument,
        categories: value.categories,
        exchanges: value.exchanges,
        instrumentTypes: value.instrumentTypes,
        nz: value.nz,
        unlistedInstruments: value.unlistedInstruments,
        minRisk: value.minRisk,
        maxRisk: value.maxRisk,
        kidsRecommended: value.kidsRecommended,
        tradingStatuses: value.tradingStatuses,
        strategy: value.strategy,
        exchangeCountries: value.exchangeCountries,
        searchFundInvestments: value.searchFundInvestments,
    }
}
