import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {SignUpStepCommonProps} from '~/sections/moose/sections/sign-up/SignUp'
import CreateAccount from '~/sections/moose/sections/sign-up/sections/account-linking/pages/create-account/CreateAccount'
import ExistingAccountCheck from '~/sections/moose/sections/sign-up/sections/account-linking/pages/existing-account-check/ExistingAccountCheck'
import Login from '~/sections/moose/sections/sign-up/sections/account-linking/pages/login/Login'
import {useSignupEntityDetail} from '~/sections/moose/sections/sign-up/utils/sign-up-detail-hooks/signUpDetailHooks'

type AccountLinkingPage = 'login' | 'existing-account-check' | 'new-account'

/**
 * Data and navigation wrapper for account linking pages.
 *
 * The Login, ExistingAccountCheck, and CreateAccount pages do not have a linear progression
 * and require specific handling of the navigation between the pages.
 *
 * Permutations are as follows:
 * - Login -> Done
 * - Login -> CreateAccount -> Done
 * - ExistingAccountCheck -> Login -> Done
 * - ExistingAccountCheck -> CreateAccount -> Done
 * - ExistingAccountCheck -> Login -> CreateAccount -> Done
 */
const AccountLinking = (commonProps: SignUpStepCommonProps) => {
    const {existing_user_email: initialEmail, previously_onboarded: previouslyOnboarded} = useSignupEntityDetail()
    const [currentPage, setCurrentPage] = React.useState<AccountLinkingPage>(
        initialEmail || previouslyOnboarded ? 'login' : 'existing-account-check',
    )

    const navTo = (to: AccountLinkingPage) => setCurrentPage(to)

    const accountLinkingProps = {
        initialEmail,
        navTo,
    }

    switch (currentPage) {
        case 'login':
            if (initialEmail || previouslyOnboarded) {
                rudderTrack('fonterra_onboarding', 'existing_sharesies_account_continue_clicked', {
                    label: 'automatic_email_link',
                })
            }
            return <Login {...commonProps} {...accountLinkingProps} />
        case 'existing-account-check':
            return <ExistingAccountCheck {...commonProps} {...accountLinkingProps} />
        case 'new-account':
            return <CreateAccount {...commonProps} {...accountLinkingProps} />
    }
}

export interface AccountLinkingCommonProps extends SignUpStepCommonProps {
    initialEmail?: string
    navTo: (to: AccountLinkingPage) => void
}

export default AccountLinking
