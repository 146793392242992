import {ModalLink} from '@design-system/modal'
import React from 'react'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {useAppSelector} from '~/store/hooks'

interface Props {
    exchange: 'NZX' | 'ASX'
}

const TransfersOutNzxAsxHelpTextModal: React.FunctionComponent<Props> = ({exchange}) => {
    const title = 'Share transfer fee'
    const homeCurrency = useAppSelector(state => state.identity.user!.home_currency)

    return (
        <ModalLink
            label={title}
            modalTitle={title}
            dataTestId="modal-link--transfer-fees-explanation"
            primaryButton={{label: 'Got it'}}
            helpIconSize={16}
            asIcon
        >
            <p>There’s a fee for transferring shares listed on the {exchange} out of Sharesies.</p>
            {exchange === 'NZX' && (
                <p>
                    It’s <DollarValue value="15" currency={homeCurrency} /> for each investment you transfer within a
                    request.
                </p>
            )}
            {exchange === 'ASX' && (
                <p>
                    It’s <DollarValue value="50" currency={homeCurrency} /> for each investment you transfer within a
                    request.
                </p>
            )}
            <p>The money needs to be in your Wallet at the time you submit your transfer request.</p>
        </ModalLink>
    )
}

export default TransfersOutNzxAsxHelpTextModal
