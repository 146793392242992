import {Button} from '@design-system/button'
import React from 'react'
import {Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {monthlyLabelGenerator} from '~/global/utils/monthly-label-generator/monthlyLabelGenerator'
import {usePortfolioOrdersInfiniteQuery} from '~/sections/kiwisaver/data/retail/portfolioOrder'
import InvestingActivityCard from '~/sections/kiwisaver/widgets/activity-card/InvestingActivityCard'
import styles from './InvestingActivity.scss'

const InvestingActivity: React.FunctionComponent<{instrumentId?: string | undefined}> = instrumentId => {
    const {data, fetchNextPage, hasNextPage, isFetchingNextPage} = usePortfolioOrdersInfiniteQuery({
        fund_id: instrumentId.instrumentId!,
    })
    const labelFor = monthlyLabelGenerator()

    let orders: Response.PortfolioOrders['orders'] = []
    data?.pages.forEach(page => {
        orders = orders.concat(...page.orders)
    })

    return (
        <div data-testid="tab--investing-activity">
            <React.Fragment>
                {orders.length > 0 && (
                    <div className={styles.activityContainer}>
                        {orders.map(order => {
                            const label = labelFor(order.date)
                            return (
                                <div key={order.id}>
                                    {label && !instrumentId.instrumentId && <div className={styles.date}>{label}</div>}
                                    <InvestingActivityCard key={order.id} activity={order} />
                                </div>
                            )
                        })}
                    </div>
                )}
            </React.Fragment>

            {hasNextPage && (
                <Button
                    dataTestId="button--load-older-portfolio-orders"
                    label="Load older investing activity"
                    onClick={() => fetchNextPage()}
                    processing={isFetchingNextPage}
                    additionalClassName={spacing.spaceAbove24}
                />
            )}
        </div>
    )
}

export default InvestingActivity
