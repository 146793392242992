import {Button} from '@design-system/button'
import {CopyField} from '@design-system/copy-field'
import cn from 'classnames'
import {withFormik} from 'formik'
import React from 'react'
import QRCode from 'react-qr-code'
import * as api from '~/api/retail'
import {spacing} from '~/global/scss/helpers'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {StrongNumber} from '~/global/widgets/form-controls/formik'
import * as validate from '~/global/widgets/form-controls/validate'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {reauthenticateReturn} from '~/global/wrappers/global-wrapper-widgets/reauthenticate/Reauthenticate'
import {useNavigate} from '~/migrate-react-router'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/identity/actions'
import styles from './AccountMFA.scss'

interface FormValues {
    mfaToken: string
}

interface TOTPFormProps {
    doSubmit(token: string): Promise<string | void>
    onComplete: Function
    buttonLabel: string
    belowTextContent?: any
}

const TOTPForm = withFormik<TOTPFormProps, FormValues>({
    mapPropsToValues: () => ({
        mfaToken: '',
    }),
    mapPropsToErrors: () => ({
        mfaToken: undefined,
    }),
    handleSubmit: async ({mfaToken}, {setSubmitting, setErrors, setStatus, props: {doSubmit, onComplete}}) => {
        try {
            const error = await doSubmit(mfaToken)
            if (error) {
                setErrors({mfaToken: error})
                setSubmitting(false)
                return
            }
            onComplete()
        } catch (e) {
            setStatus('Unknown error')
            setSubmitting(false)
            throw e
        }
    },
    validate: validate.generate<FormValues>({
        mfaToken: [validate.required()],
    }),
})(({handleSubmit, isValid, isSubmitting, buttonLabel, belowTextContent}) => (
    <form onSubmit={handleSubmit}>
        <StrongNumber
            dataTestId="strong-number--mfa-token"
            name="mfaToken"
            label="Code"
            placeholder="000000"
            normalisation="numberOnly"
            maxLength={6}
            decimalPlaces={0}
        />
        {belowTextContent}
        <Button
            isSubmit
            label={buttonLabel}
            disabled={!isValid}
            processing={isSubmitting}
            additionalClassName={spacing.spaceAbove8}
            dataTestId="button--turn-on-mfa"
        />
    </form>
))

interface StepProps {
    step: string
    title: string
    className?: string
}

const Step: React.FC<StepProps> = ({step, title, className}) => (
    <div className={cn(className, spacing.spaceBelow12)}>
        <p className={styles.miniText}>{step}</p>
        <p className={styles.boldText}>{title}</p>
    </div>
)

const AccountMFADisabled: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate()
    const [state, setState] = React.useState<'init' | 'loading' | 'ready'>('init')
    const [secret, setSecret] = React.useState<string>('')
    const [showQR, setShowQR] = React.useState<boolean>(false)
    const dispatch = useAppDispatch()
    const email = useAppSelector(s => s.identity.user!.email!)
    const doSubmit = (mfaToken: string) => dispatch(actions.mfaEnableConfirm(mfaToken))
    const totpURL =
        'otpauth://totp/Sharesies:' +
        encodeURIComponent(email) +
        '?secret=' +
        encodeURIComponent(secret) +
        '&issuer=Sharesies'

    const generateSecret = async () => {
        setState('loading')
        let response = await api.post('identity/mfa/enable')
        if (response.type === 'authentication_update_required') {
            const newResponse = await reauthenticateReturn(
                () => api.post('identity/mfa/enable'),
                () => api.post('identity/mfa/enable'),
                () => 'cancel' as const,
            )
            if (newResponse === 'cancel') {
                setState('init')
                return
            }
            if (newResponse.type === 'authentication_update_required') {
                throw new Error('Re-authentication failed')
            }
            response = newResponse
        }

        if (response.type === 'internal_server_error') {
            throw new Error('Internal server error while enabling MFA')
        }
        setState('ready')
        setSecret(response.mfa_totp_secret)
    }

    React.useEffect(() => {
        generateSecret()
    }, [])

    const qrsvg = <QRCode size={126} value={totpURL} />

    const qrStepText = (
        <>
            <Step step="Step 2" title="Scan the QR code into authenticator app" />
            <p className={spacing.spaceBelow12}>
                Open your authenticator app on your phone or tablet and scan this QR code to generate a 6-digit
                verification code.
            </p>
        </>
    )

    interface QRStepProps {
        desktopView?: boolean
    }

    const QRStep: React.FC<QRStepProps> = ({desktopView}) =>
        desktopView ? (
            <>
                <div>
                    {qrStepText}
                    <p>
                        Not working? Try{' '}
                        <ButtonAsLink dataTestId="button--use-key" onClick={() => setShowQR(!showQR)}>
                            pasting a key
                        </ButtonAsLink>{' '}
                        instead.
                    </p>
                </div>
                <div className={styles.qrcode}>{qrsvg}</div>
            </>
        ) : (
            <>
                {qrStepText}
                <div className={cn(styles.qrcode, spacing.spaceBelow12)}>{qrsvg}</div>
                <p className={styles.miniText}>
                    Not working? Try{' '}
                    <ButtonAsLink dataTestId="button--use-key" onClick={() => setShowQR(!showQR)}>
                        pasting a key
                    </ButtonAsLink>{' '}
                    instead.
                </p>
            </>
        )

    const KeyStep: React.FC = () => (
        <>
            <Step step="Step 2" title="Paste key into authenticator app" />
            <p className={spacing.spaceBelow12}>
                Open your authenticator app and paste in the following key to generate a 6-digit verification code.
            </p>
            <CopyField id="mfa-key" dataTestId="mfa-key" label="" value={secret} copyFilled />
            <p className={spacing.spaceBelow12}>Give it an account name that’ll remind you that it’s for Sharesies.</p>
            <p>
                Not working? Try scanning a{' '}
                <ButtonAsLink dataTestId="button--use-qr-code" onClick={() => setShowQR(!showQR)}>
                    QR code
                </ButtonAsLink>
                .
            </p>
        </>
    )

    interface viewProps {
        showQR: boolean
    }

    const DesktopView: React.FC<viewProps> = ({showQR}) => (!showQR ? <QRStep desktopView /> : <KeyStep />)

    const MobileView: React.FC<viewProps> = ({showQR}) => (showQR ? <QRStep /> : <KeyStep />)

    return (
        <>
            <Step step="Step 1" title="Download an authenticator app" />
            <p>
                Download an authenticator app to your phone or tablet, like Google Authenticator, Authy, or Microsoft
                Authenticator.
            </p>
            {state === 'loading' ? (
                <>
                    <Loading className={styles.loading} />
                </>
            ) : (
                <>
                    {/* Desktop view */}
                    <div className={styles.desktopOnly}>
                        <DesktopView showQR={showQR} />
                    </div>
                    {/* Mobile view */}
                    <div className={styles.mobileOnly}>
                        <MobileView showQR={showQR} />
                    </div>
                </>
            )}
            <Step step="Step 3" title="Enter the verification code" />
            <p className={spacing.spaceBelow12}>
                Enter the 6-digit verification code generated by your authenticator app.
            </p>
            <TOTPForm
                doSubmit={doSubmit}
                onComplete={() => {
                    Toast('2FA is turned on.')
                    navigate(-1)
                }}
                buttonLabel="Turn on 2FA"
            />
        </>
    )
}

const AccountMFAEnabled: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const doSubmit = (mfaToken: string) => dispatch(actions.mfaDisable(mfaToken))
    const jurisdiction = useAppSelector(s => s.identity.user?.jurisdiction)

    return (
        <>
            <h2 className={spacing.spaceBelow12}>Enter the verification code</h2>
            <p className={spacing.spaceBelow12}>
                Enter the 6-digit verification code generated by your authenticator app.
            </p>
            <TOTPForm
                doSubmit={doSubmit}
                onComplete={() => {
                    Toast('2FA has been turned off.')
                    navigate(-1)
                }}
                buttonLabel="Turn off 2FA"
                belowTextContent={
                    <p className={spacing.spaceBelow24}>
                        Locked out? Check out the{' '}
                        <HelpCentreLink
                            auArticle="5427746-reset-two-factor-authentication-2fa"
                            nzArticle="5427741-reset-two-factor-authentication-2fa"
                        />
                        or{' '}
                        <a
                            href={`mailto:help@sharesies${
                                jurisdiction === 'au' ? '.com.au' : jurisdiction === 'nz' ? 'co.nz' : 'com'
                            }`}
                            target="_blank"
                            rel="noopener"
                        >
                            get in touch
                        </a>
                        .
                    </p>
                }
            />
        </>
    )
}

const AccountMFA: React.FC<{}> = () => {
    const mfaEnabled = useAppSelector(({identity}) => identity.user!.mfa_enabled)

    const pageTitle = mfaEnabled ? 'Turn off 2FA' : 'Set up 2FA'

    return (
        <>
            <Toolbar dataTestId="toolbar--mfa" leftButton="back" title={pageTitle} />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                {mfaEnabled ? <AccountMFAEnabled /> : <AccountMFADisabled />}
            </Page>
        </>
    )
}

export default AccountMFA
