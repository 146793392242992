import {TradingStatus} from '~/api/distill'
import {BasicInstrument, Instrument} from '~/store/instrument/types'

export const isInstrumentActive = (instrument: Instrument) => instrument.tradingStatus === TradingStatus.active

export const isInstrumentCloseOnly = (instrument: Instrument) => instrument.tradingStatus === TradingStatus.closeonly

export const isInstrumentInactive = (instrument: Instrument) => instrument.tradingStatus === TradingStatus.inactive

export const isInstrumentInHalt = (instrument: Instrument) => instrument.tradingStatus === TradingStatus.halt

export const isInstrumentInNoTrade = (instrument: BasicInstrument) => instrument.tradingStatus === TradingStatus.notrade
