import React from 'react'
import styles from '~/sections/kiwisaver/sections/view-fund/widgets/fund-disclosure/FundDisclosure.scss'

const KiwiSaverStructureModal: React.FunctionComponent = () => (
    <div className={styles.modelText}>
        <h1>How the Sharesies KiwiSaver Scheme is structured</h1>
        <p>
            When you invest in companies or funds as part of the Sharesies KiwiSaver Scheme you are buying units in a
            Sharesies fund. This fund then buys shares or units in your chosen investment.
        </p>
        <p>This structure means you can have more choice about where your KiwiSaver balance is invested.</p>
        <p>
            It also means when you buy or sell you will see the unit price of the Sharesies fund, rather than the price
            of the underlying investment.
        </p>
        <p>
            If you want to learn more about how our KiwiSaver Scheme is structured{' '}
            <a href="https://www.sharesies.nz/kiwisaver?scroll=how-our-scheme-works" target="_blank" rel="noreferrer">
                check out our website
            </a>
            .
        </p>
    </div>
)

export default KiwiSaverStructureModal
