/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    InstrumentPriceChangeResponseDto,
    InstrumentPriceChangeResponseDtoFromJSON,
    InstrumentPriceChangeResponseDtoFromJSONTyped,
    InstrumentPriceChangeResponseDtoToJSON,
    InstrumentPriceChangeResponseDtoToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface PriceChangesResponseDto
 */
export interface PriceChangesResponseDto {
    /**
     *
     * @type {Array<InstrumentPriceChangeResponseDto>}
     * @memberof PriceChangesResponseDto
     */
    priceChanges?: Array<InstrumentPriceChangeResponseDto> | null
}

export function PriceChangesResponseDtoFromJSON(json: any): PriceChangesResponseDto {
    return PriceChangesResponseDtoFromJSONTyped(json, false)
}

export function PriceChangesResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceChangesResponseDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        priceChanges: !exists(json, 'priceChanges')
            ? undefined
            : json['priceChanges'] === null
              ? null
              : (json['priceChanges'] as Array<any>).map(InstrumentPriceChangeResponseDtoFromJSON),
    }
}

export function PriceChangesResponseDtoToJSON(value?: PriceChangesResponseDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        priceChanges:
            value.priceChanges === undefined
                ? undefined
                : value.priceChanges === null
                  ? null
                  : (value.priceChanges as Array<any>).map(InstrumentPriceChangeResponseDtoToJSON),
    }
}
