import {Button} from '@design-system/button'
import {SelectCard} from '@design-system/select-cards'
import React from 'react'
import {useNavigate} from 'react-router'
import {Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {formatNumberWithThousandsSeparator} from '~/global/utils/format-number/formatNumber'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import farmSupplyIcon from '~/sections/moose/assets/images/farm-supply-icon.svg'
import {useHoldingDetailForSymbol, useHoldingSummary} from '~/sections/moose/data/retail'
import {useStagedTransfer} from '~/sections/moose/sections/transfer/hooks/useStagedTransfer'
import {InstrumentSymbol} from '~/sections/moose/sections/transfer/state'
import {isShareType} from '~/sections/moose/sections/utils/symbol-helpers/symbolHelpers'
import styles from './PortfolioSelect.scss'

interface PortfolioSelectParams {
    direction: 'out' | 'in'
}

export type FonterraPortfolio = Extract<
    Response.Profiles['profiles'][number]['portfolios'][number],
    {product: 'FONTERRA'}
>

export const PortfolioSelect = ({direction}: PortfolioSelectParams) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const profile = useProfile()
    const [stagedTransfer, setStagedTransfer] = useStagedTransfer()

    const initialPortfolioId = direction === 'out' ? stagedTransfer?.fromPortfolioId : stagedTransfer?.toPortfolioId
    const [selectedPortfolioId, setSelectedPortfolioId] = React.useState(initialPortfolioId || '')

    const portfolios = profile.portfolios.filter(
        (portfolio): portfolio is FonterraPortfolio => portfolio.product === 'FONTERRA',
    )

    const shouldFilterSelected = direction === 'in'
    const availablePortfolios = shouldFilterSelected
        ? portfolios.filter(p => p.id !== stagedTransfer?.fromPortfolioId)
        : portfolios

    const onNext = () => {
        if (direction === 'out') {
            setStagedTransfer({...stagedTransfer, fromPortfolioId: selectedPortfolioId})
            navigate(profileUrl('fonterra/transfer/select/:direction', {direction: 'in'}))
            return
        }

        setStagedTransfer({...stagedTransfer, toPortfolioId: selectedPortfolioId})
        navigate(profileUrl('fonterra/transfer/select/units'))
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--transfer-portfolio-select"
                leftButton="back"
                title={`Transfer ${direction === 'out' ? 'from' : 'to'}`}
            />
            <Page overrideDefaultTopPadding="none">
                <div className={spacing.spaceAbove4}>
                    {availablePortfolios.map(p => {
                        return (
                            <PortfolioSelectCard
                                key={p.id}
                                portfolioId={p.id}
                                selectedPortfolioId={selectedPortfolioId}
                                instrumentSymbol={stagedTransfer.instrumentSymbol!}
                                setSelectedPortfolioId={setSelectedPortfolioId}
                            />
                        )
                    })}
                </div>
            </Page>
            <ActionBar>
                <Button
                    label="Next"
                    type="primary"
                    dataTestId="button--next"
                    onClick={onNext}
                    disabled={!selectedPortfolioId}
                />
            </ActionBar>
        </>
    )
}

interface PortfolioSelectCardProps {
    portfolioId: string
    selectedPortfolioId?: string
    instrumentSymbol: InstrumentSymbol
    setSelectedPortfolioId: (portfolioId: string) => void
}

const PortfolioSelectCard = ({
    portfolioId,
    selectedPortfolioId,
    instrumentSymbol,
    setSelectedPortfolioId,
}: PortfolioSelectCardProps) => {
    const holdingSummary = useHoldingSummary(portfolioId)
    const holdingDetail = useHoldingDetailForSymbol(portfolioId, instrumentSymbol)
    const sharesOrUnits = isShareType(instrumentSymbol) ? 'shares' : 'units'

    return (
        <div className={styles.portfolioSelectCard}>
            <h3>
                <span className={styles.fonterraFarmId}>{holdingSummary.farm_id}</span>
            </h3>
            <SelectCard
                dataTestId={`select-card--transfer-portfolio-${portfolioId}`}
                title={`CSN: ${holdingSummary.csn}`}
                supportingText={`${formatNumberWithThousandsSeparator(
                    holdingDetail.holding.toString(),
                )} ${instrumentSymbol} ${sharesOrUnits}`}
                image={farmSupplyIcon}
                value={portfolioId}
                name="symbol"
                isActive={portfolioId === selectedPortfolioId}
                onChange={e => setSelectedPortfolioId(e.target.value)}
            />
        </div>
    )
}
