import {Button} from '@design-system/button'
import {ArrowUp} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {useInView} from 'react-intersection-observer'
import {Link, useNavigate} from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'
import {ListingResponseDto} from '~/api/distill'
import {DistillScope} from '~/api/query/distill'
import {useRetailGet} from '~/api/query/retail'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {accessibility, spacing} from '~/global/scss/helpers'
import {useWatchlist} from '~/global/state-hooks/retail/useWatchlist'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {CheckSingle} from '~/global/widgets/OLD_icons'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Chips from '~/global/widgets/chips/Chips'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import SearchInput from '~/global/widgets/form-controls/search/SearchInput'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import instrumentStyles from '~/global/widgets/instrument-row/InstrumentRow.scss'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import SortAndFilter from '~/global/widgets/sort-and-filter/SortAndFilter'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import autoinvestSelectButtonStyles from '~/sections/invest/sections/auto-invest/widgets/auto-invest-select-button/AutoInvestSelectButton.scss'
import searchStyles from '~/sections/invest/sections/search/widgets/search-results/SearchResults.scss'
import {
    generateInitialFilter,
    searchFilterToSearchString,
    useDistillInfiniteSearch,
    useSearchFilterFromURL,
} from '~/sections/kiwisaver/data/distill'
import {usePlanEditorUrl} from '~/sections/kiwisaver/sections/edit-investment-plan/hooks/usePlanEditorUrl'
import {setSelfSelectFunds} from '~/sections/kiwisaver/sections/edit-investment-plan/mutate'
import {
    SignUpGuardRedirect,
    SignUpPage,
} from '~/sections/kiwisaver/sections/sign-up/widgets/redirect/SignUpGuardRedirect'
import {
    useExistingKSCustomer,
    useInvestmentPlanForEditing,
    useSetKSInvestmentPlanDraft,
} from '~/sections/kiwisaver/state'
import {useAppSelector} from '~/store/hooks'
import {actingAsID as actingAsIDSelector} from '~/store/identity/selectors'
import {SortOptions} from '~/store/instrument/types'
import styles from './AddFunds.scss'

declare module 'react' {
    export function useTransition(): [boolean, (callback: () => void) => void]
}

enum PillFilterOptions {
    all = 'All',
    portfolio = 'In your Portfolio',
    watchlist = 'On your Watchlist',
}

export const AddFunds: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate()
    const relativeUrlFor = usePlanEditorUrl()
    const customer = useExistingKSCustomer()

    const {ref: topRef, inView: isTopInView} = useInView()
    const {ref: bottomLoaderRef, inView: isBottomLoaderInView} = useInView()

    const [input, setInput] = React.useState('')
    const [query, setQuery] = React.useState('')
    const [submitting, setSubmitting] = React.useState(false)
    const [filterPill, setFilterPill] = React.useState<PillFilterOptions>(PillFilterOptions.all)

    const [isPending, startTransition] = React.useTransition()

    const actingAsID = useAppSelector(actingAsIDSelector) // if the identity is anonymous, this will throw
    const investmentPlan = useInvestmentPlanForEditing()

    const setInvestmentPlan = useSetKSInvestmentPlanDraft()
    const {data: identity} = useRetailGet({path: 'identity/check', payload: {acting_as_id: actingAsID}})

    const {watchlistInstrumentIds: watchlistIDs} = useWatchlist()

    const [selected, setSelected] = React.useState<Set<string>>(
        new Set(investmentPlan.allocations.filter(a => a.fund_category === 'SELF_SELECT').map(a => a.fund_id)),
    )

    const sortOptions: SortOptions = [
        {
            id: 'name',
            name: 'A-Z',
        },
        {
            id: 'marketCap',
            name: 'Market cap',
        },
    ]

    const initialSort = sortOptions[1]
    const [sort, setCurrentSort] = React.useState(initialSort)
    const [showScrollToTopButton, setShowScrollToTopButton] = React.useState<boolean | null>(false)
    const initialFilter = generateInitialFilter(DistillScope.KIWISAVER_SELF_SELECT_FUNDS)
    const [filter, setFilter] = useSearchFilterFromURL(DistillScope.KIWISAVER_SELF_SELECT_FUNDS)

    const {data, fetchNextPage, isFetchingNextPage} = useDistillInfiniteSearch({
        ...filter,
        searchFundInvestments: true,
        query,
        sort: sort.id,
        exchanges: ['NZX'],
    })

    React.useEffect(() => {
        if (isBottomLoaderInView) {
            fetchNextPage()
        }
    }, [isBottomLoaderInView])

    React.useEffect(() => {
        if (isTopInView) {
            setShowScrollToTopButton(false)
        } else {
            setShowScrollToTopButton(true)
        }
    }, [isTopInView])

    let instruments: ListingResponseDto[] = []
    data?.pages.forEach(group => {
        instruments = instruments.concat(group.instruments)
    })

    const setQueryTransition = React.useCallback((query: string) => startTransition(() => setQuery(query)), [])

    const saveCurrentDraft = async () => {
        const allocations = setSelfSelectFunds(investmentPlan.allocations, [...selected])

        await setInvestmentPlan.mutateAsync({
            acting_as_id: actingAsID,
            allocations,
        })
    }
    const onAdd = async () => {
        setSubmitting(true)

        try {
            await saveCurrentDraft()
            rudderTrack('kiwisaver_self_select', 'self_select_funds_added', {
                has_filter:
                    filterPill !== PillFilterOptions.all || JSON.stringify(filter) !== JSON.stringify(initialFilter),
                has_sort: sort.id !== 'marketCap',
                search_query: query,
                type: customer.customer_state === 'SIGNUP' ? 'signup' : 'edit',
            })
            navigate(relativeUrlFor('edit-allocations'))
        } catch (e) {
            Toast('Could not add fund')
        } finally {
            setSubmitting(false)
        }
    }

    // we shouldn't be able to reach this page as identity_anonymous so the ternary checks below just keep TS happy
    const portfolioIDs = identity.type !== 'identity_anonymous' ? identity.holdings.map(x => x.fund_id) : []

    switch (filterPill) {
        case PillFilterOptions.all:
            break
        case PillFilterOptions.portfolio:
            instruments = instruments.filter(x => portfolioIDs.includes(x.id))
            break
        case PillFilterOptions.watchlist:
            instruments = instruments.filter(x => watchlistIDs.includes(x.id))
            break
    }

    return (
        <SignUpGuardRedirect currentPage={SignUpPage.EDIT_INVESTMENT_PLAN_SELF_SELECT}>
            <Toolbar
                leftButton="back"
                dataTestId="toolbar--add-funds"
                title="What would you like to add to your investment plan?"
            />
            <Page withoutDefaultPadding>
                <p className={styles.header}>
                    Not sure? Read our{' '}
                    <Link to={relativeUrlFor('tips-for-choosing-picks')}>tips for choosing picks</Link>.
                </p>

                <div className={styles.search} ref={topRef}>
                    <SearchInput
                        name="search-term"
                        isLoading={isPending}
                        isTouched
                        onQueryChange={setQueryTransition}
                        setCurrentSearchInput={setInput}
                        initialValue={input}
                    />
                </div>
                <div className={styles.pills}>
                    <div className={cn(spacing.spaceAbove8, spacing.spaceBelow8)}>
                        <Chips
                            options={Object.values(PillFilterOptions)}
                            onChipClick={selected => {
                                const selectedPill = selected as PillFilterOptions
                                setFilterPill(selectedPill)
                            }}
                            selected={Object.values(PillFilterOptions).filter(key => {
                                return key === filterPill
                            })}
                        />
                    </div>
                </div>
                <div className={styles.filterOptions}>
                    <SortAndFilter
                        defaultSortId={sort.id}
                        onFilterClick={() =>
                            navigate({
                                pathname: relativeUrlFor('add-funds/filter'),
                                search: searchFilterToSearchString(filter),
                            })
                        }
                        currentSort={sort}
                        onSortMenuClick={(sortId: string) => {
                            setCurrentSort(sortOptions[sortOptions.findIndex(x => x.id === sortId)])
                            filter.sort = sortId
                            setFilter(filter)
                        }}
                        sortOptions={sortOptions}
                    />
                </div>
                {instruments.map(instrument => (
                    <FundRow
                        key={instrument.id}
                        instrument={instrument}
                        selected={selected}
                        setSelected={setSelected}
                        saveCurrentDraft={saveCurrentDraft}
                    />
                ))}
                {isFetchingNextPage && (
                    <div
                        className={cn(searchStyles.instrumentRowWrapper, searchStyles.nextPageLoader)}
                        key="loading-spinner"
                    >
                        <Loading />
                    </div>
                )}
                <div ref={bottomLoaderRef}></div>
                <Button
                    additionalClassName={cn(styles.scrollToTopButton, {
                        [styles.buttonFadeIn]: showScrollToTopButton,
                        [styles.buttonFadeOut]: showScrollToTopButton === false,
                    })}
                    dataTestId="search-results-button"
                    label={<ArrowUp />}
                    width="auto"
                    ariaLabel="Scroll back up"
                    onClick={e => {
                        e.preventDefault()
                        smoothscroll.polyfill() // kick off the polyfill to make smooth scroll work on Safari
                        window.scrollTo({top: 0, behavior: 'smooth'})
                    }}
                />
                <ActionBar>
                    <Button
                        dataTestId=""
                        disabled={selected.size === 0}
                        processing={submitting}
                        label="Add to my investment plan"
                        onClick={onAdd}
                    />
                </ActionBar>
            </Page>
        </SignUpGuardRedirect>
    )
}

export interface FundRowData {
    instruments: ListingResponseDto[]
}

export const FundRow: React.FunctionComponent<{
    instrument: ListingResponseDto
    setSelected: (selected: Set<string>) => void
    selected: Set<string>
    saveCurrentDraft: () => Promise<void>
}> = ({instrument, selected, setSelected, saveCurrentDraft}) => {
    const navigate = useNavigate()
    const relativeUrlFor = usePlanEditorUrl()
    const onFundClick = async () => {
        await saveCurrentDraft()
        navigate(relativeUrlFor('view-fund/:urlSlug', {urlSlug: instrument.urlSlug}))
    }
    const isSelected = selected.has(instrument.fmsFundId!)

    return (
        <div>
            <div key={instrument.id} data-testid="search-result" className={searchStyles.instrumentRowWrapper}>
                <div className={instrumentStyles.instrumentWrapper}>
                    <div
                        className={cn(instrumentStyles.listing, {
                            [instrumentStyles.autoInvestSelected]: isSelected,
                            [instrumentStyles.oldLayout]: true,
                        })}
                        data-testid={`instrument-row--${instrument.symbol}`}
                    >
                        <div className={instrumentStyles.imageWrapper} onClick={onFundClick}>
                            <InstrumentLogo instrument={instrument} noBorder />
                        </div>
                        <button
                            type="button"
                            className={cn(instrumentStyles.title, accessibility.button)}
                            onClick={onFundClick}
                        >
                            <h3>{instrument.name}</h3>
                            {tradingType(instrument) !== 'managed' && !isInstrumentInNoTrade(instrument) && (
                                <h4>
                                    <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                                    <PronounceLetters text={instrument.exchange} />
                                </h4>
                            )}
                        </button>
                        <button
                            className={cn(autoinvestSelectButtonStyles.addFundButton, accessibility.button, {
                                [autoinvestSelectButtonStyles.selected]: isSelected,
                            })}
                            type="button"
                            onClick={e => {
                                e.stopPropagation()
                                const newSelected = new Set([...selected])
                                if (isSelected) {
                                    newSelected.delete(instrument.fmsFundId!)
                                } else {
                                    newSelected.add(instrument.fmsFundId!)
                                }
                                setSelected(newSelected)
                            }}
                        >
                            {isSelected ? <CheckSingle /> : 'Add'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
