import classNames from 'classnames'
import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {useBankLinkingAlert} from '~/global/state-hooks/retail/useBankLinkingAlert'
import {isLoading, isUninitialised} from '~/global/utils/is-loading/isLoading'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import roundupStyle from '~/sections/OLD_wallet/widgets/roundup-status/RoundupStatus.scss'
import {BASIQ_DDRSA_URL, MOONOVA_DDRSA_ROUTE} from '~/sections/invest/sections/bank-linking/constants/ddrsa'
import {useBankLinkingReferrer} from '~/sections/invest/sections/bank-linking/hooks/useBankLinkingRererrer'
import {UnlinkBankConfirmModal} from '~/sections/invest/sections/bank-linking/widgets/unlink-bank-confirm-modal/UnlinkBankConfirmModal'
import actions from '~/store/bankLinking/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import styles from './BankLinkingView.scss'

export interface OnNextProps {
    onBack?: () => void
    onEditDebitAccount?: () => void
    onUnlink?: () => void
}

export const BankLinkingView: React.FunctionComponent<OnNextProps> = props => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const [unlinkModalOpen, setUnlinkModalOpen] = React.useState(false)
    const isLinked = useAppSelector(s => s.bankLinking.isBankLinked)
    const hasMonoovaFlag = useAppSelector(s => s.identity.flags.monoova)
    const bankLinkingLoadingState = useAppSelector(s => s.bankLinking.bankLinkingLoadingState)
    const basiqAccountsLoadingState = useAppSelector(s => s.bankLinking.basiqAccountsLoadingState)
    const bankLinkingDebitAccount = useAppSelector(s => s.bankLinking.roundupsDebitAccount)
    const accounts = useAppSelector(s => s.bankLinking.basiqAccountsResponse?.accounts)
    const referrer = useBankLinkingReferrer()
    const alert = useBankLinkingAlert({
        context:
            referrer === 'wallet'
                ? 'wallet/roundups'
                : referrer === 'linked-bank-topup'
                  ? 'linked-bank-topup'
                  : referrer,
    })

    const onBack = () => (props.onBack ? props.onBack() : navigate(profileUrl('settings/bank-accounts-cards')))

    const getDebitAccountBalance = () => {
        const debitAccountFromBasiq = accounts?.find(
            account => account.basiq_account_id === bankLinkingDebitAccount?.basiqAccountId,
        )

        return debitAccountFromBasiq ? debitAccountFromBasiq.balance : '0'
    }

    React.useEffect(() => {
        if (isUninitialised(bankLinkingLoadingState)) {
            dispatch(actions.FetchBankLinkingStatus())
        }

        if (isUninitialised(basiqAccountsLoadingState)) {
            dispatch(actions.FetchBasiqAccounts())
        }
    }, [])

    if (!isLinked) {
        navigate(profileUrl('settings/bank-accounts-cards'))
    }

    if (isLoading(basiqAccountsLoadingState)) {
        return (
            <>
                <Toolbar
                    leftButton="back"
                    dataTestId="toolbar--banklinking-select-debit-account"
                    onLeftButtonClick={onBack}
                />
                <Page>
                    <Loading />
                </Page>
            </>
        )
    }

    return (
        <>
            <Toolbar
                leftButton="back"
                dataTestId="toolbar--banklinking-edit-debit-account"
                onLeftButtonClick={onBack}
            />
            <Page>
                <h1 className={spacing.spaceBelow16}>Account to send money from</h1>
                {!alert ? null : (
                    <AlertCard className={roundupStyle.alert} type={alert.type}>
                        {alert.content}
                    </AlertCard>
                )}

                <p className={spacing.spaceBelow16}>
                    Choose the default bank account you’d like to send money from. This will be used for things like
                    round-ups or linked bank top-ups.
                </p>
                <p className={spacing.spaceBelow16}>
                    Money can’t be sent from a credit card, and you’ll only be able to choose from the accounts shown.
                </p>

                <h2 className={spacing.spaceBelow16}>Your linked Bank</h2>
                {bankLinkingDebitAccount && (
                    <div>
                        <div className={styles.bankLinkingAccount}>
                            <div className={styles.nameAndLogo}>
                                <img src={bankLinkingDebitAccount.bankLogo} />
                                <div>
                                    <p>{bankLinkingDebitAccount.bankName}</p>
                                    <p>{<DollarValue value={getDebitAccountBalance()} />}</p>
                                </div>
                            </div>
                            <strong>
                                <ButtonAsLink
                                    data-testid="link--edit-bank-linking-debit"
                                    onClick={() => {
                                        if (props.onEditDebitAccount) {
                                            props.onEditDebitAccount()
                                            return
                                        }
                                        navigate(profileUrl('settings/bank-accounts-cards/bank-linking/edit'))
                                    }}
                                >
                                    Change
                                </ButtonAsLink>
                            </strong>
                        </div>
                        <ButtonAsLink className={spacing.spaceAbove16} onClick={() => setUnlinkModalOpen(true)}>
                            Unlink with bank
                        </ButtonAsLink>
                        <UnlinkBankConfirmModal
                            isOpen={unlinkModalOpen}
                            setIsOpen={setUnlinkModalOpen}
                            handleUnlinkBank={() => {
                                setUnlinkModalOpen(false)
                                dispatch(actions.UnlinkBank())
                                if (props.onUnlink) {
                                    props.onUnlink()
                                } else {
                                    navigate(-1)
                                }
                            }}
                        />
                    </div>
                )}
                <h2 className={classNames(spacing.spaceAbove24, spacing.spaceBelow16)}>Your Direct Debit requests</h2>
                <p className={spacing.spaceBelow16}>
                    These are the authorised documents you have agreed to in relation to direct debits between your bank
                    and Sharesies through our payments service provider.
                </p>
                <div className={styles.directDebitLinks}>
                    {hasMonoovaFlag ? (
                        <Link to={profileUrl(MOONOVA_DDRSA_ROUTE)}>Direct Debit Request Service Agreement</Link>
                    ) : (
                        <a target="_blank" rel="noreferrer" href={BASIQ_DDRSA_URL}>
                            Direct Debit Request Service Agreement
                        </a>
                    )}
                </div>
            </Page>
        </>
    )
}
