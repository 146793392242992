import React from 'react'
import {useNavigate} from 'react-router'
import {Navigate} from 'react-router-dom'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import InlineAUTaxForm from '~/global/widgets/inline-tax-form/InlineAUTaxForm'
import NZTaxDetailsForm from '~/global/widgets/nz-tax-details-form/NZTaxDetailsForm'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useAppSelector} from '~/store/hooks'

export const Tax: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const hasSaveFlag = useAppSelector(s => s.identity.flags.savings) // Flag for AU residents only
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)

    if (jurisdiction !== 'nz' && !hasSaveFlag) {
        return <Navigate to={profileUrl('')} replace />
    }

    if (jurisdiction === 'nz') {
        return (
            <>
                <Toolbar dataTestId="toolbar--inline-tax-nz" leftButton="back" title="We need some tax info" />
                <Page>
                    <NZTaxDetailsForm
                        onSuccess={() => navigate(profileUrl('save/confirm-account'))}
                        rwtRequired
                        pirRequired={false}
                    />
                </Page>
            </>
        )
    } else {
        return (
            <InlineAUTaxForm
                title="Your tax info"
                description={
                    <p>
                        You have the option to provide us with your tax file number (TFN). If you do, we won’t deduct
                        tax from your Save interest. If you don’t, we’re required to deduct 47% of your earned interest
                        to pay to the Australian Taxation Office.{' '}
                        <a
                            href="https://intercom.help/sharesies-au/en/articles/4983208-tax"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Learn more
                        </a>
                    </p>
                }
                buttonText="Next"
                externalSubmitHandler={() => navigate(profileUrl('save/confirm-account'))}
                externalBackHandler={() => navigate(profileUrl('save/personalise-account'))}
                isRequired={false}
            />
        )
    }
}

export default Tax
