import cn from 'classnames'
import Decimal from 'decimal.js'
import React from 'react'
import {usePortfolioValues} from '~/global/state-hooks/rakaia/usePortfolioValues'
import {useActor} from '~/global/state-hooks/retail/useActor'
import {useProfile, Profile} from '~/global/state-hooks/retail/useProfile'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import styles from './TotalBalance.scss'

export const useTotalBalance = (profile: Profile) => {
    let valueMissing = false // we should supress the balance if we have any missing data

    const portfoliosToGetFromRakaia = React.useMemo(() => {
        return profile.portfolios.reduce<string[]>((acc, portfolio) => {
            if (portfolio.product === 'INVEST' || portfolio.product === 'KIWISAVER') {
                acc.push(portfolio.id)
            }
            return acc
        }, [])
    }, [profile.portfolios])

    const portfolioBalances = React.useMemo(() => {
        return profile.portfolios.reduce(
            (acc, portfolio) => {
                switch (portfolio.product) {
                    case 'WALLET':
                        acc.WALLET = acc.WALLET.add(new Decimal(portfolio.balance)).add(
                            new Decimal(portfolio.holding_balance),
                        )
                        break
                    case 'SAVE':
                        acc.SAVE = acc.SAVE.add(new Decimal(portfolio.balance))
                        break
                    case 'FONTERRA':
                        if (portfolio.estimated_balance === undefined) {
                            // possibility Fonterra balance might be missing
                            valueMissing = true
                        }
                        acc.FONTERRA = acc.FONTERRA.add(new Decimal(portfolio.estimated_balance || '0'))
                        break
                    case 'KIWISAVER':
                        // just cash holdings, the main number comes separately - see portfoliosToGetFromRakaia
                        acc.KIWISAVER_CASH_HOLDINGS = acc.KIWISAVER_CASH_HOLDINGS.add(
                            new Decimal(portfolio.cash_balance),
                        )
                        break
                    case 'INVEST':
                        // we need to get this separately - see portfoliosToGetFromRakaia
                        break
                    default:
                        assertNever(portfolio)
                }
                return acc
            },
            {
                SAVE: new Decimal(0),
                WALLET: new Decimal(0),
                FONTERRA: new Decimal(0),
                KIWISAVER_CASH_HOLDINGS: new Decimal(0),
            },
        )
    }, [profile.portfolios])

    // Rakaia portfolios - note we **assume** their currency is the same as the actor's default_display_currency
    const rawRakaiaPortfolioValues = usePortfolioValues(portfoliosToGetFromRakaia)
    if (rawRakaiaPortfolioValues.length !== portfoliosToGetFromRakaia.length) {
        // possibility Rakaia balance might be missing
        valueMissing = true
    }
    const rakaiaPortfolioValues = rawRakaiaPortfolioValues.reduce((acc, value) => {
        return acc.plus(value)
    }, new Decimal(0))

    const totalBalance = Object.values(portfolioBalances)
        .reduce((acc, balance) => acc.add(balance), new Decimal(0))
        .plus(rakaiaPortfolioValues)

    return {
        totalBalance,
        valueMissing,
    }
}

export const TotalBalance = () => {
    const headingRef = React.useRef<HTMLHeadingElement>(null)
    const actor = useActor()
    const profile = useProfile()
    const {totalBalance, valueMissing} = useTotalBalance(profile)

    React.useEffect(() => {
        // The usages of tabIndex={-1} on the H1 and this code is to allow us to
        // `focus()` on the Heading tag so screen readers can read the title
        // on dynamically rendered pages.
        // https://a11y-guidelines.orange.com/en/articles/single-page-app/#notify-user-of-page-changes
        if (headingRef && headingRef.current) {
            headingRef.current.focus()
        }
    }, [headingRef])

    return (
        <h1 className={styles.totalBalance} tabIndex={-1} ref={headingRef}>
            <span className={styles.name}>{profile.name}</span>
            <span className={cn(styles.screenreaderOnly, {[styles.balanceIncomplete]: valueMissing})}> – </span>
            <span className={cn(styles.balance, {[styles.balanceIncomplete]: valueMissing})}>
                <DollarValue value={totalBalance.toNumber()} sayNumericWords={false} />
                {'\u00a0'}
                {/* non-breaking space */}
                <span className={styles.currency}>{actor.default_display_currency.toLocaleUpperCase()}</span>
            </span>
            <span className={cn(styles.estimated, {[styles.balanceIncomplete]: valueMissing})}>
                estimated total balance
            </span>
        </h1>
    )
}
