import {useGrowthBook} from '@growthbook/growthbook-react'
import {nanoid} from 'nanoid'
import React from 'react'
import {shallowEqual} from 'react-redux'
import config from '~/configForEnv'
import {useAppSelector} from '~/store/hooks'

export const GrowthBookConfig: React.FunctionComponent<{}> = () => {
    let anonymousId = localStorage.getItem('sharesies_signup_anonymous_id')
    if (!anonymousId) {
        anonymousId = nanoid()
        localStorage.setItem('sharesies_signup_anonymous_id', anonymousId)
    }
    const growthbook = useGrowthBook()
    const attributes = useAppSelector(
        s => ({
            customer_id: s.identity.user?.id,
            anonymous_id: anonymousId,
            is_dependent: s.identity.user?.is_dependent,
            jurisdiction: s.identity.user?.jurisdiction,
            has_topped_up: s.identity.user?.checks.made_deposit,
        }),
        shallowEqual,
    )
    React.useEffect(() => {
        if (!growthbook) {
            return
        }
        growthbook.setAttributes({
            ...growthbook.getAttributes(),
            ...attributes,
        })
    }, [!!growthbook, attributes])

    React.useEffect(() => {
        if (!growthbook) {
            return
        }
        fetch(config.growthBookUrl)
            .then(res => res.json())
            .then(json => {
                growthbook.setFeatures(json.features)
            })
            .catch(() => {
                // TODO - throw to rollbar?
                // eslint-disable-next-line no-console
                console.error('Failed to fetch feature definitions from GrowthBook')
            })
    }, [!!growthbook])

    return null
}
