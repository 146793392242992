import {Button} from '@design-system/button'
import {semanticColour} from '@design-system/colour-tokens'
import {Wallet} from '@design-system/icon'
import {ModalLink} from '@design-system/modal'
import {useColourMode} from '@design-system/use-colour-mode'
import React from 'react'
import {Navigate, useNavigate} from 'react-router'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {spacing} from '~/global/scss/helpers'
import {formatNumber} from '~/global/utils/format-number/formatNumber'
import {useCurrentSubscriptionPlan} from '~/global/utils/subscription-hooks/subscriptionHooks'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {cardImages} from '~/global/widgets/credit-card-handling/accepted-cards-list/AccountsCardList'
import {Loading} from '~/global/widgets/loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {CustomSelectCard} from '~/sections/user/sections/settings/sections/plans/widgets/custom-select-cards/CustomSelectCard'
import accountingActions from '~/store/accounting/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {selectHasStaffBenefits} from '~/store/identity/selectors'
import {BillingCycle, PaymentMethods, SubPlanId} from '~/store/plan/types'
import styles from './PlanPaymentMethod.scss'

export const formatPaymentMethod = (paymentMethod?: PaymentMethods) => {
    // If payment method has been passed in via URL, use that
    // Otherwise, current subscription or fall back to WALLET

    const formattedOptions = {
        CARD: 'Card',
        WALLET: 'Wallet',
        LINKED_BANK: 'Linked bank account',
    }

    if (paymentMethod) {
        return formattedOptions[paymentMethod]
    } else {
        return formattedOptions.WALLET
    }
}

interface Props {
    plan?: SubPlanId
    billingCycle?: BillingCycle
}

export const PlanPaymentMethod: React.FunctionComponent<Props> = ({plan: planId, billingCycle: billingCycle}) => {
    const colourMode = useColourMode()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const [currentSubscription, currentSubscriptionLoaded] = useCurrentSubscriptionPlan()
    const [continueButtonDisabled, setContinueButton] = React.useState(true)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<PaymentMethods | 'NEW CARD' | undefined>()
    const hasStaffBenefits = useAppSelector(selectHasStaffBenefits)
    const {hasAnnualPlansFlag, jurisdiction, walletBalances, savedCard, cardState, isDependent, preferredName} =
        useAppSelector(s => ({
            jurisdiction: s.identity.user!.jurisdiction,
            hasAnnualPlansFlag: s.identity!.flags.annual_plans,
            walletBalances: s.identity!.user?.wallet_balances,
            savedCard: s.accounting.cardsLoadingState === 'ready' ? s.accounting.cards[0] : undefined,
            cardState: s.accounting.cardsLoadingState,
            isDependent: s.identity.user!.is_dependent,
            preferredName: s.identity.user!.preferred_name,
        }))

    React.useEffect(() => {
        if (cardState === 'loading') {
            dispatch(accountingActions.RefreshCards())
        }
    }, [])

    if (!currentSubscriptionLoaded || cardState === 'loading') {
        return <Loading isPineapple />
    }

    if (!currentSubscription && !(planId && billingCycle)) {
        // minimum required information
        return <WeSlippedUp />
    }

    if (!hasAnnualPlansFlag || hasStaffBenefits) {
        return <Navigate to={`/account/plan/confirm/${planId}`} />
    }

    const nextStep = (paymentMethod: PaymentMethods | 'NEW CARD') => {
        if (paymentMethod === 'NEW CARD') {
            return navigate(profileUrl('settings/bank-accounts-cards/add-card'))
        }

        return currentSubscription
            ? navigate(
                  profileUrl('settings/plan/payment-method/confirm/:paymentMethod', {
                      paymentMethod,
                  }),
              )
            : navigate(
                  profileUrl('settings/plan/confirm/:plan/:billingCycle/:paymentMethod', {
                      plan: planId!,
                      billingCycle: billingCycle!,
                      paymentMethod,
                  }),
              )
    }

    const setMethod = (paymentMethod: PaymentMethods | 'NEW CARD') => {
        setSelectedPaymentMethod(paymentMethod)
        setContinueButton(false)
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--subscription-confirm"
                leftButton="back"
                title={currentSubscription ? 'Change payment method' : 'Choose a payment method'}
                onLeftButtonClick={() => {
                    navigate(-1)
                }}
            />
            {/* TODO show current state when we’re changing */}
            {/* TODO show message the currently selected billing method is different from what was previously used */}
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <div className={spacing.spaceBelow16}>
                    <h2 className={spacing.spaceBelow16}>No additional fees</h2>
                    <CustomSelectCard
                        icon={<Wallet size={20} />}
                        onClickIfNotActive={() => {
                            currentSubscription ? nextStep('WALLET') : setMethod('WALLET')
                        }}
                        title="Wallet"
                        subText={`$${formatNumber({
                            number: walletBalances ? walletBalances[jurisdiction === 'nz' ? 'nzd' : 'aud'] : '0',
                        })}`}
                        active={currentSubscription?.payment_method === 'WALLET'}
                        dataTestId="custom-select-card--wallet"
                    />
                </div>
                <div className={styles.flexRowSpaceBetween}>
                    <h2 className={spacing.spaceBelow16}>Card processing fee applies</h2>
                    <ModalLink
                        dataTestId="modal-link--card-processing-fee"
                        label=""
                        asIcon
                        modalTitle="Card processing fee"
                        primaryButton={{label: 'Ok'}}
                        helpIconColour={semanticColour('IconSubtle', colourMode)}
                    >
                        <p>When you use a debit or credit card it comes with a card processing fee.</p>
                        <p>
                            Bank deposits don’t have this processing fee—but may take a little longer for the money to
                            show in {isDependent ? `${preferredName}'s` : 'your'} Wallet.
                        </p>
                    </ModalLink>
                </div>
                {/* TODO add a modal with the InfoCircle icon, same content as HelpLinkModal from Wallet/CardTopUp.tsx */}
                {savedCard ? (
                    <CustomSelectCard
                        icon={<img src={cardImages[savedCard.brand]} alt="credit-card-image" />}
                        onClickIfNotActive={() => {
                            currentSubscription ? nextStep('CARD') : setMethod('CARD')
                        }}
                        title={`**** **** **** ${savedCard.last4}`}
                        subText={
                            <span>
                                Exp. {savedCard.exp_month}/{savedCard.exp_year}
                            </span>
                        }
                        active={currentSubscription?.payment_method === 'CARD'}
                        dataTestId="custom-select-card--card"
                    />
                ) : (
                    <CustomSelectCard
                        icon={<img src={cardImages.Visa} alt="credit-card-image" />}
                        onClickIfNotActive={() => {
                            currentSubscription ? nextStep('CARD') : setMethod('NEW CARD')
                        }}
                        title="Credit card"
                        subText="Add new card"
                        active={currentSubscription?.payment_method === 'CARD'}
                        dataTestId="custom-select-card--card"
                    />
                )}
            </Page>
            {!currentSubscription && (
                <ActionBar>
                    <Button
                        disabled={continueButtonDisabled}
                        dataTestId="button--payment-method-continue"
                        label="Continue"
                        onClick={() => nextStep(selectedPaymentMethod!)}
                    />
                </ActionBar>
            )}
        </>
    )
}

export default PlanPaymentMethod
