import React from 'react'
import {dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import {DollarValue, ExchangeRateValue, FeeValue} from '~/global/widgets/number-elements/NumberElements'
import {TransactionDetailTypes} from '~/sections/wallet/pages/transaction/utils/transaction-types/transactionTypes'
import {RecordPage, RecordRow} from '~/sections/wallet/pages/transaction/widgets/record/Record'

export const FxRecord: React.FunctionComponent<{transaction: TransactionDetailTypes['fx_order']}> = ({transaction}) => {
    return (
        <RecordPage
            title={transaction.description}
            rows={
                <>
                    <RecordRow left="Order placed" right={transaction.timestamp.toFormat(dateFormatWithYear)} />
                    <RecordRow
                        left="From"
                        right={
                            <DollarValue
                                value={transaction.detail.source_amount}
                                currency={transaction.detail.source_currency}
                            />
                        }
                    />
                    <RecordRow
                        left="Exchange rate"
                        right={
                            <ExchangeRateValue
                                sourceCurrency={transaction.detail.source_currency}
                                targetCurrency={transaction.detail.target_currency}
                                exchangeRate={Number(transaction.detail.exchange_rate)}
                            />
                        }
                    />
                    <RecordRow
                        left="Exchange fee"
                        right={
                            <FeeValue
                                value={transaction.detail.source_fee}
                                currency={transaction.detail.source_currency}
                            />
                        }
                    />
                    <RecordRow
                        left="Amount to exchange"
                        right={
                            <DollarValue
                                value={transaction.detail.net_source_amount}
                                currency={transaction.detail.source_currency}
                            />
                        }
                    />
                </>
            }
            amountLabel="To amount"
            amount={transaction.amount}
            amountCurrency={transaction.currency}
            statusLabel="Filled"
        />
    )
}
