import {Button} from '@design-system/button'
import cn from 'classnames'
import {Formik} from 'formik'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {carNumberPlate} from '~/global/utils/normalize-values/normalizeValues'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {Select, validate} from '~/global/widgets/form-controls'
import {Text} from '~/global/widgets/form-controls/formik'
import styles from '~/sections/protect/sections/insure/sections/car/pages/create-car-estimate/CarDetailsForm.scss'
interface FormValues {
    drivingExperience: string
    licenceType: string
    registration: string
}

const CarDetailsForm = (props: {
    experience?: string | null
    licence?: string | null
    rego?: string | null
    nextPage: (drivingExperience: string, licenceType: string, registration: string) => void
}) => {
    const [licenceType, setLicenceType] = React.useState<string | null | undefined>(props.licence)
    const [drivingExperience, setDrivingExperience] = React.useState<string | null | undefined>(props.experience)
    const initialRego = props.rego ?? ''
    return (
        <Formik
            initialValues={
                {
                    drivingExperience,
                    licenceType,
                    registration: initialRego,
                } as FormValues
            }
            validate={values => {
                return validate.generate<typeof values>({
                    drivingExperience: [validate.required()],
                    licenceType: [validate.required()],
                    registration: [validate.required()],
                })(values)
            }}
            onSubmit={async ({drivingExperience, licenceType, registration}) => {
                props.nextPage(drivingExperience, licenceType, registration)
            }}
        >
            {({handleSubmit, isSubmitting, isValid, setFieldValue}) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <div className={styles.carDetailsForm}>
                            <h1 data-testid="heading--car-details" className={cn(styles.heading, spacing.spaceBelow24)}>
                                Tell us about the car and your driving experience
                            </h1>
                            <Text
                                autoFocus={true}
                                autoComplete="off"
                                dataTestId="text-input--plate"
                                label="Number plate"
                                name="registration"
                                placeholder="ABC123"
                                normalizeValue={carNumberPlate}
                            />

                            <Select
                                dataTestId="select--licence-type"
                                name="licenceType"
                                label="Licence type"
                                choices={[
                                    {value: '', label: ''},
                                    {value: 'Full', label: 'Full'},
                                    {value: 'Restricted', label: 'Restricted'},
                                    {value: 'Learner', label: 'Learner'},
                                ]}
                                isTouched={false}
                                placeholder="Select type"
                                value={licenceType || ''}
                                onChange={e => {
                                    setLicenceType(e.target.value)
                                    setFieldValue('licenceType', e.target.value)
                                }}
                            />
                            <Select
                                dataTestId="select--driving-experience"
                                name="drivingExperience"
                                label="How many years have you held a New Zealand driving licence?"
                                choices={[
                                    {value: '', label: ''},
                                    {value: '5', label: 'over 5 years'},
                                    {value: '4', label: '4 - 5 years'},
                                    {value: '3', label: '3 - 4 years'},
                                    {value: '2', label: '2 - 3 years'},
                                    {value: '1', label: '1 - 2 years'},
                                    {value: '0', label: 'under 1 year'},
                                ]}
                                isTouched={false}
                                placeholder="Select experience"
                                helpText="Include all years on a learner, restricted, and full licence."
                                value={drivingExperience || ''}
                                onChange={e => {
                                    setDrivingExperience(e.target.value)
                                    setFieldValue('drivingExperience', e.target.value)
                                }}
                            />
                        </div>

                        <ActionBar>
                            <Button
                                dataTestId="button--next"
                                disabled={!isValid}
                                isSubmit
                                label="Next"
                                processing={isSubmitting}
                            />
                        </ActionBar>
                    </form>
                )
            }}
        </Formik>
    )
}

export default CarDetailsForm
