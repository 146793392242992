/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum TradingStatus {
    unknown = 'unknown',
    active = 'active',
    halt = 'halt',
    inactive = 'inactive',
    closeonly = 'closeonly',
    notrade = 'notrade',
}

export function TradingStatusFromJSON(json: any): TradingStatus {
    return TradingStatusFromJSONTyped(json, false)
}

export function TradingStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradingStatus {
    return json as TradingStatus
}

export function TradingStatusToJSON(value?: TradingStatus | null): any {
    return value as any
}
