import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {dateFormatDayAndMonthWithTime} from '~/global/utils/format-date/formatDate'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {useInstrument} from '~/global/utils/use-instruments/useInstruments'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {
    VoteEligibilityBlurb,
    VoteIntroBlurb,
} from '~/global/widgets/corporate-action-description/CorporateActionDescription'
import mdStyles from '~/global/widgets/corporate-action-markdown/CAMarkdown.scss'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import LoadingCard from '~/global/widgets/instrument-activity/LoadingCard'
import styles from '~/global/widgets/instrument-activity/Record.scss'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {useNavigate} from '~/migrate-react-router'
import {Application} from '~/store/order/types'

const AvailableApplicationCard: React.FC<{application: Application}> = ({application}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const instrument = useInstrument(application.fund_id)

    if (!instrument) {
        // This should hopefully never happen, as the carousel handles loading
        return <LoadingCard />
    }

    return (
        <div className={cn(styles.orderContent, styles.orderCorporateAction)}>
            <div className={styles.orderTitleBlock}>
                <div className={styles.orderThumb}>
                    <InstrumentLogo instrument={instrument} noBorder />
                </div>
                <div>
                    <h4 className={styles.instrumentName}>{instrument.name}</h4>
                    {tradingType(instrument) !== 'managed' && !isInstrumentInNoTrade(instrument) && (
                        <p className={styles.instrumentSymbol}>
                            <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                            <PronounceLetters text={instrument.exchange} />
                        </p>
                    )}
                    {application.applications_close_at && (
                        <p className={styles.orderTime}>
                            Vote by {application.applications_close_at.toFormat(dateFormatDayAndMonthWithTime)}
                        </p>
                    )}
                </div>
            </div>
            <div className={cn(styles.orderDescription, mdStyles.markdown)}>
                <p>
                    <VoteIntroBlurb
                        title={application.investment_option_title}
                        instrument={instrument}
                        settlementDate={application.settlement_date}
                    />
                </p>
                <p>
                    <VoteEligibilityBlurb instrument={instrument} finalTradingDate={application.final_trading_date} />
                </p>
                <p>
                    This is an opportunity to have your voice heard as an investor, and help influence the future of{' '}
                    {instrument.name}.
                </p>
            </div>

            <Button
                dataTestId="application-apply-button"
                label={application.type === 'VOTE' ? 'Vote now' : 'Apply'}
                onClick={() => {
                    navigate(
                        profileUrl('invest/:instrumentSlug/apply/:applicationRuleId', {
                            instrumentSlug: instrument.urlSlug,
                            applicationRuleId: application.application_rule_id,
                        }),
                        {state: {suppressAnimation: true}},
                    )
                    if (application.type === 'VOTE') {
                        rudderTrack('voting', 'vote_now_link_clicked', {
                            referrer: 'available_application_card',
                            ca_application_rule_id: application.application_rule_id,
                        })
                    }
                }}
                additionalClassName={spacing.spaceBelow12}
            />
        </div>
    )
}

export default AvailableApplicationCard
