import {ChevronDown, ChevronUp} from '@design-system/icon'
import cn from 'classnames'
import React, {useState} from 'react'
import {accessibility} from '~/global/scss/helpers'
import styles from './ExpandLink.scss'

interface ExpandLink {
    title: string
    titleClassName?: string
    children: React.ReactNode
    additionalClassName?: string
    linkAtBottom?: boolean
}
const ExpandLink: React.FC<ExpandLink> = ({title, children, titleClassName, additionalClassName, linkAtBottom}) => {
    const [isOpen, setIsOpen] = useState(false)

    React.useEffect(() => {
        if (!isOpen && linkAtBottom) {
            window.scrollTo({top: 0, behavior: 'smooth'})
        }
    }, [isOpen])

    return (
        <>
            {isOpen && linkAtBottom && <div className={cn(styles.body)}>{children}</div>}
            <details
                className={cn(styles.expandLink, additionalClassName)}
                onToggle={() => {
                    setIsOpen(!isOpen)
                }}
            >
                <summary className={cn(accessibility.button, titleClassName)}>
                    {title}
                    {isOpen ? <ChevronUp /> : <ChevronDown />}
                </summary>
                {!linkAtBottom && <div className={cn(styles.body)}>{children}</div>}
            </details>
        </>
    )
}

export {ExpandLink}
