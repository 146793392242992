import {QueryKey, UseInfiniteQueryResult, useInfiniteQuery} from '@tanstack/react-query'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import * as api from '~/api/retail'
import {Request, Response} from '~/api/retail/types'
import {FundHolding} from '~/store/identity/types'

const ONE_MINUTE_IN_MS = 60 * 1000
const TWO_MINUTES_IN_MS = 2 * 60 * 1000

export const DEFAULT_CACHE_STALE_OPTION = {
    cacheTime: TWO_MINUTES_IN_MS,
    staleTime: ONE_MINUTE_IN_MS,
}
export type InvestmentPlan = NonNullable<Response.KSCustomer['investment_plan']>
export type Allocation = InvestmentPlan['allocations'][number]
export type KSCashMovementActivity = Response.KSCashMovementActivity['activity'][number]
export type PortfolioOrder = Response.PortfolioOrders['orders'][number] //ToDo - Move this out of KiwiSaver
export type KSCustomer = Omit<Response.KSCustomer, 'type'>

interface KSActiveCustomer extends KSCustomer {
    investment_plan: NonNullable<KSCustomer['investment_plan']>
    portfolio_id: NonNullable<KSCustomer['portfolio_id']>
}
export interface KSInfinitQueryParams {
    limit?: number
}

export interface KSInfiniteQueryResponse {
    has_more: boolean
    type: string
}
interface KSCashActivityResponse extends KSInfiniteQueryResponse {
    activity: Response.KSCashMovementActivity['activity'][]
}

/**
 * Get a KiwiSaver customer if they exist (in any state) or null if they
 * haven't yet started sign-up for KiwiSaver.
 *
 * @returns {(KSCustomer|null)} KiwiSaver customer or null
 */
export const useKSCustomerOrNull = (): KSCustomer | null => {
    // TODO - deal with 403 (global user is logged out)
    const {data} = useRetailGet({
        path: 'kiwisaver/customer',
    })

    switch (data.type) {
        case 'kiwisaver_customer': {
            const {type, ...customer} = data
            return customer
        }
        default:
            return null
    }
}

/**
 * Get a KiwiSaver customer when you expect them to have been created.
 *
 * MAKE SURE YOU ONLY USE THIS WHEN YOU KNOW THERE'S A CUSTOMER
 *
 * @returns {KSCustomer} KiwiSaver customer that might be in any state but does exist
 */
export const useExistingKSCustomer = (): KSCustomer => {
    const customer = useKSCustomerOrNull()

    if (!customer) {
        throw new Error(`Expected valid customer but none exists`)
    }
    return customer
}

/**
 * Get a KiwiSaver customer when you expect them to be active. If the customer isn't active (or doesn't
 * exist) this will throw an error.
 *
 * MAKE SURE YOU ONLY USE THIS WHEN YOU KNOW THERE'S A CUSTOMER
 *
 * @returns {KSActiveCustomer} active KiwiSaver customer
 */
export const useActiveKSCustomer = (): KSActiveCustomer => {
    const customer = useExistingKSCustomer()
    if (customer.customer_state !== 'ACTIVE') {
        throw new Error(`Expected active customer but got one in ${customer.customer_state}`)
    }
    if (!customer.investment_plan) {
        throw new Error(`Active customer should have an investment plan`)
    }
    if (!customer.portfolio_id) {
        throw new Error(`Active customer should have an portfolio`)
    }

    return {...customer, investment_plan: customer.investment_plan, portfolio_id: customer.portfolio_id} // make TS happy that our checks above make this a valid KSActiveCustomer
}

/**
 * Get a KiwiSaver customer when you expect them to have ever been active. If the customer was never active (or doesn't
 * exist) this will throw an error.
 *
 * MAKE SURE YOU ONLY USE THIS WHEN YOU KNOW THERE'S A CUSTOMER
 *
 * @returns {KSActiveCustomer} active KiwiSaver customer
 */
export const useActiveOrOffboardingKSCustomer = (): KSActiveCustomer => {
    const customer = useExistingKSCustomer()
    if (!customer.investment_plan) {
        throw new Error(`Previously active customer should have an investment plan`)
    }
    if (!customer.portfolio_id) {
        throw new Error(`Previously active customer should have a portfolio`)
    }
    return {...customer, investment_plan: customer.investment_plan, portfolio_id: customer.portfolio_id} // make TS happy that our checks above make this a valid KSActiveCustomer
}

/**
 * Get a KiwiSaver customer's investment plan.
 *
 * @returns {InvestmentPlan} investment plan
 */
export const useInvestmentPlanForEditing = (): InvestmentPlan => {
    const investmentPlan = useInvestmentPlanOrNullForEditing()

    if (!investmentPlan) {
        throw new Error('Customer has no investment plan yet')
    }

    return investmentPlan
}

/**
 * Get a KiwiSaver customer's investment plan or null if there is no customer or no plan yet.
 *
 * @returns {InvestmentPlan | null} investment plan or null
 */
export const useInvestmentPlanOrNullForEditing = (): InvestmentPlan | null => {
    const customer = useKSCustomerOrNull()

    if (!customer) {
        return null
    }

    if (customer.draft_investment_plan) {
        return customer.draft_investment_plan
    }

    if (customer.investment_plan) {
        return customer.investment_plan
    }

    return null
}

/**
 * Get a KiwiSaver customer's MARKETING SITE TOOL investment plan or null if there is no customer or no plan yet.
 *
 * @returns {InvestmentPlan | null} investment plan or null
 */
export const useMarketingSiteInvestmentPlanOrNullForEditing = (): InvestmentPlan | null => {
    const customer = useKSCustomerOrNull()

    if (!customer) {
        return null
    }

    if (customer.marketing_site_investment_plan) {
        return customer.marketing_site_investment_plan
    }

    return null
}

export const useKSCashActivityDetails = (cm_id: string): Response.KSCashMovementActivityDetails['activity'] => {
    const {data} = useRetailGet({
        path: 'kiwisaver/cash-activity-details',
        payload: {cm_id},
        options: DEFAULT_CACHE_STALE_OPTION,
    })
    return data.activity
}

export const useKSFundHolding = (fund_id: string): FundHolding | undefined => {
    const {data} = useRetailGet({
        path: 'kiwisaver/fund-holding',
        payload: {fund_id},
    })

    if (data.type === 'kiwisaver_portfolio_holding') {
        // change the data shape to match a regular FundHolding instead of KSFundHolding
        return {
            fund_id: data.fund_id,
            holding_type: data.holding_type,
            // shares is actually shares active as well...
            shares: data.shares,
            shares_active: data.shares,
        }
    }

    return undefined
}

/**
 * Hook to set up being able to POST to set a draft investment plan.
 *
 * Call the returned function's mutateAsync method with the payload your want to POST in a try catch block.
 *
 * @returns {useRetailPost} Tanstack Query useMutation function that handles reauthentication and internal server errors automatically
 */
export const useSetKSInvestmentPlanDraft = () => {
    return useRetailPost({
        path: 'kiwisaver/set-investment-plan-draft',
        queryCacheToUpdate: ['kiwisaver/customer'],
    })
}

/**
 * Hook to set up being able to POST to set a marketing site draft investment plan when one has been passed in via URL params (see preprocessing in PrePopulate).
 *
 * Call the returned function's mutateAsync method with the payload your want to POST in a try catch block.
 *
 * @returns {useRetailPost} Tanstack Query useMutation function that handles reauthentication and internal server errors automatically
 */
export const useSetKSMarketingPlanDraft = () => {
    return useRetailPost({
        path: 'kiwisaver/set-investment-plan-marketing-site',
        queryCacheToUpdate: ['kiwisaver/customer'],
    })
}

export const useKSCashActivityInfiniteQuery = ({
    limit,
}: KSInfinitQueryParams): UseInfiniteQueryResult<KSCashActivityResponse> => {
    const path = 'kiwisaver/cash-activity'
    const queryKey: QueryKey = limit ? [path, limit] : [path]

    return useInfiniteQuery({
        queryKey,
        ...DEFAULT_CACHE_STALE_OPTION,
        queryFn: async ({pageParam}) => {
            const response = await api.get(path, {limit, before: pageParam})

            return response as Response.KSCashMovementActivity
        },
        getNextPageParam: (currentPage: Response.KSCashMovementActivity) => {
            const hasNextPage: boolean = currentPage.has_more

            if (!hasNextPage) {
                return undefined
            }

            return currentPage.activity[currentPage.activity.length - 1].date
        },
    })
}

export const useKSCustomerFiles = (
    customer_file_purpose: Request.KSCustomerFiles['customer_file_purpose'],
): Response.KSCustomerFiles => {
    const {data} = useRetailGet({
        path: 'kiwisaver/customer-files',
        payload: {customer_file_purpose},
    })

    return data
}

export const useKSAccountActivitySummary = (): Response.KSAccountActivitySummary => {
    const {data} = useRetailGet({
        path: 'kiwisaver/account-activity-summary',
    })

    return data
}

export const useKSAccountActivityBreakdown = (): Response.KSAccountActivityBreakdown => {
    const {data} = useRetailGet({
        path: 'kiwisaver/account-activity-breakdown',
    })

    return data
}
