import React from 'react'
import {Model} from '~/api/retail/types'

interface ADRProps {
    jurisdiction: Model.User['jurisdiction']
}

const ADRDetail: React.FunctionComponent<ADRProps> = ({jurisdiction}) => {
    return (
        <div>
            <h1>Depositary fees</h1>
            <p>
                When you invest in an American depositary receipt (ADR), you may be charged depositary fees. These are
                charged by the depositary bank that issued the ADR, not Sharesies.
            </p>
            <p>
                The fees cover the depositary bank’s costs for managing the ADR, and are based on how many receipts you
                hold. Generally, the fee is less than $0.10 USD per receipt annually.
            </p>
            <p>The depositary fee can be charged a couple of ways:</p>
            <ul>
                <li>
                    if the ADR pays dividends, the fee will be deducted from any dividends on the ex-dividend date
                    before they’re paid into your Wallet
                </li>
                <li>
                    if the ADR doesn’t pay dividends, the fee will usually be deducted from your Wallet balance during
                    the year.
                </li>
            </ul>
            <p>
                Sometimes, it could be a mix of both—part of the fee deducted from your dividends and the rest deducted
                from your Wallet during the year.
            </p>
            <p>
                For a full run-down of our fees,{' '}
                <a
                    target="_blank"
                    rel="noopener"
                    href={
                        jurisdiction === 'au'
                            ? 'https://www.sharesies.com.au/pricing'
                            : 'https://www.sharesies.nz/pricing'
                    }
                >
                    check out our website
                </a>
                .
            </p>
        </div>
    )
}

export default ADRDetail
