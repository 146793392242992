import {Button} from '@design-system/button'
import {Loader} from '@design-system/loader'
import {choices} from '@design-system/strong-currency-exchange'
import cn from 'classnames'
import {withFormik} from 'formik'
import {DateTime} from 'luxon'
import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Model} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {accessibility, spacing} from '~/global/scss/helpers'
import tooltipStyles from '~/global/scss/reused-styles/tooltip.scss'
import {
    roundDownToTwoDecimalPlaces,
    roundUpToTwoDecimalPlaces,
    calculateCurrencyExchangeFromSourceAmount,
    calculateCurrencyExchangeFromTargetAmount,
} from '~/global/utils/calculate-currency-exchange/calculateCurrencyExchange'
import {Currency, currencyDetails} from '~/global/utils/currency-details/currencyDetails'
import {useExchangeFeeRate} from '~/global/utils/exchange-fee-hooks/exchangeFeeHooks'
import {dateFormatWithSecond} from '~/global/utils/format-date/formatDate'
import {getExchangeRates, XeError} from '~/global/utils/get-exchange-rate/getExchangeRate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import {ChevronDown, ChevronUp} from '~/global/widgets/OLD_icons'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {ExchangeMoneyInput} from '~/global/widgets/form-controls/formik'
import {required} from '~/global/widgets/form-controls/validate'
import ExchangeMoneyFees from '~/global/widgets/help-modals/ExchangeMoneyFees'
import {Loading} from '~/global/widgets/loading/Loading'
import {WalletValue, FeeValue, DollarValue, ExchangeRateValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import Tooltip from '~/global/widgets/tooltip/Tooltip'
import {ExchangeRateXeUnreachable} from '~/sections/invest/sections/order-flow/widgets/modals/ExchangeRateErrorModals'
import actions from '~/store/accounting/actions'
import {StagedExchangeOrder, ExchangeRate} from '~/store/accounting/types'
import {useAppSelector, useAppDispatch} from '~/store/hooks'
import {getAvailableCurrencies, actingAsID as actingAsIDSelector} from '~/store/identity/selectors'
import signUpActions from '~/store/sign-up/actions'
import styles from './ExchangeMoney.scss'

export const exchangeRateCheckInterval = 1000 * 15 // 15 seconds

export const MINIMUM_ERROR = 'MINIMUM_ERROR'

interface ExchangeDetails {
    exchangeFee?: string
    amountToExchange?: string
}

interface ExchangeRateWithExpiryDate {
    rate: number
    expiryDate: DateTime
}

interface ExchangeMoneyFormProps {
    actingAsId: string
    walletBalances: Model.User['wallet_balances']
    stagedExchangeOrder?: StagedExchangeOrder
    getExchangeRates(actingAsId: string): Promise<ExchangeRate[] | XeError | null>
    setStagedExchangeOrder(order: StagedExchangeOrder): void
    stagedExchangeOrderRateError: boolean
    homeCurrency: Model.User['home_currency']
    availableCurrencies: Currency[]
    accountRestricted: boolean
    hideToolbar: boolean
    usEquitiesEnabled: boolean
    onUsSharesSignUpClick: () => void
    profileUrl: ReturnType<typeof useProfileUrl>
}

interface ExchangeMoneyFormValues {
    sourceCurrencyValue: string
    targetCurrencyValue: string
}

interface ExchangeMoneyFormErrors {
    sourceCurrencyValue?: string
    targetCurrencyValue?: string
}

const ExchangeMoneyForm = withRouter(
    withFormik<ExchangeMoneyFormProps & WithRouterProps, ExchangeMoneyFormValues>({
        mapPropsToValues: ({stagedExchangeOrder}) => ({
            sourceCurrencyValue:
                stagedExchangeOrder && stagedExchangeOrder.sourceAmount
                    ? roundDownToTwoDecimalPlaces(stagedExchangeOrder.sourceAmount)
                    : '',
            targetCurrencyValue:
                stagedExchangeOrder && stagedExchangeOrder.targetAmount
                    ? roundDownToTwoDecimalPlaces(stagedExchangeOrder.targetAmount)
                    : '',
        }),
        mapPropsToErrors: ({stagedExchangeOrder, walletBalances}) => {
            const errors: ExchangeMoneyFormErrors = {}

            // make the button disabled initially by setting at least one field to have an error
            if (!stagedExchangeOrder) {
                errors.sourceCurrencyValue = undefined
            }

            // Validating staged exchange order
            if (
                stagedExchangeOrder &&
                (!stagedExchangeOrder.sourceAmount || parseFloat(stagedExchangeOrder.sourceAmount) === 0)
            ) {
                errors.sourceCurrencyValue = '' // if a field was required, we don't show that as a displayed error, but it still affects ability to submit
            }

            if (
                stagedExchangeOrder &&
                stagedExchangeOrder.sourceAmount &&
                parseFloat(stagedExchangeOrder.sourceAmount) >
                    parseFloat(roundDownToTwoDecimalPlaces(walletBalances[stagedExchangeOrder.sourceCurrency]))
            ) {
                errors.sourceCurrencyValue = `You do not have enough ${
                    currencyDetails[stagedExchangeOrder.sourceCurrency].longName
                }`
            }

            if (
                stagedExchangeOrder &&
                stagedExchangeOrder.targetAmount &&
                parseFloat(stagedExchangeOrder.targetAmount) < 0.01
            ) {
                errors.sourceCurrencyValue = MINIMUM_ERROR
                errors.targetCurrencyValue = 'The minimum you can exchange is $0.01'
            }

            return errors
        },

        validate: async (values, {walletBalances, stagedExchangeOrder}) => {
            const errors: ExchangeMoneyFormErrors = {}

            const sourceCurrencyValueRequiredError = await required()(values.sourceCurrencyValue, values)

            if (sourceCurrencyValueRequiredError || parseFloat(values.sourceCurrencyValue) === 0) {
                errors.sourceCurrencyValue = '' // if a field was required, we don't show that as a displayed error, but it still affects ability to submit
            }

            if (
                stagedExchangeOrder &&
                parseFloat(values.sourceCurrencyValue) >
                    parseFloat(roundDownToTwoDecimalPlaces(walletBalances[stagedExchangeOrder.sourceCurrency]))
            ) {
                errors.sourceCurrencyValue = `You do not have enough ${
                    currencyDetails[stagedExchangeOrder.sourceCurrency].longName
                }`
            }

            if (stagedExchangeOrder && parseFloat(values.targetCurrencyValue) < 0.01) {
                errors.sourceCurrencyValue = MINIMUM_ERROR
                errors.targetCurrencyValue = 'The minimum you can exchange is $0.01'
            }
            return errors
        },

        handleSubmit: async (
            _,
            {
                setSubmitting,
                props: {
                    stagedExchangeOrder,
                    setStagedExchangeOrder,
                    router: {navigate},
                    profileUrl,
                },
            },
        ) => {
            if (!stagedExchangeOrder) {
                return
            }

            setStagedExchangeOrder(stagedExchangeOrder)

            setSubmitting(false)
            navigate(profileUrl('wallet/exchange-money/confirm'))
        },
    })(
        ({
            isValid,
            isSubmitting,
            handleSubmit,
            getExchangeRates,
            setStagedExchangeOrder,
            walletBalances,
            stagedExchangeOrder,
            setFieldValue,
            stagedExchangeOrderRateError,
            values,
            errors,
            homeCurrency,
            availableCurrencies,
            accountRestricted,
            hideToolbar,
            actingAsId,
            usEquitiesEnabled,
            onUsSharesSignUpClick,
            profileUrl,
        }) => {
            const navigate = useNavigate()
            const [exchangeRate, setExchangeRate] = React.useState<ExchangeRateWithExpiryDate | XeError | null>(null)
            const exchangeFeeRate = useExchangeFeeRate()
            const [updatingExchangeRate, setUpdatingExchangeRate] = React.useState(false)
            const [newRateError, setNewRateError] = React.useState(false)
            const [sourceCurrency, setSourceCurrency] = React.useState<Currency>(
                stagedExchangeOrder ? stagedExchangeOrder.sourceCurrency : homeCurrency,
            )
            const [triggerSourceAmountUpdate, setTriggerSourceAmountUpdate] = React.useState(false)
            const [targetCurrency, setTargetCurrency] = React.useState<Currency>(
                stagedExchangeOrder
                    ? stagedExchangeOrder.targetCurrency
                    : availableCurrencies.find(c => c !== homeCurrency)!,
            )
            const [triggerTargetAmountUpdate, setTriggerTargetAmountUpdate] = React.useState(false)
            const [buyOrSell, setBuyOrSell] = React.useState<StagedExchangeOrder['buyOrSell']>('sell')

            const [showDetails, setShowDetails] = React.useState(
                stagedExchangeOrder && stagedExchangeOrder.showDetails ? stagedExchangeOrder.showDetails : false,
            )
            const [details, setDetails] = React.useState<ExchangeDetails>(
                stagedExchangeOrder && stagedExchangeOrder.exchangeFee && stagedExchangeOrder.amountToExchange
                    ? {
                          exchangeFee: stagedExchangeOrder.exchangeFee,
                          amountToExchange: stagedExchangeOrder.amountToExchange,
                      }
                    : {},
            )

            const {sourceCurrencyValue, targetCurrencyValue} = values

            // Show US Shares prompt if someone has selected USD but hasn't signed up to US shares/equities.
            // There's no technical reason we can't let them exchange to USD.
            // But we prevent someone from exchanging USD, as they can't buy any US shares, and would incur FX transaction fees to exchange the money
            const usdSelectedButUsEquitiesDisabled =
                !usEquitiesEnabled && (sourceCurrency === 'usd' || targetCurrency === 'usd')

            React.useEffect(() => {
                const updateExchangeRates = async () => {
                    setUpdatingExchangeRate(true)
                    const result = await getExchangeRates(actingAsId)
                    if (result && result !== 'xe_unreachable') {
                        const ratePair = result.find(
                            r => r.sourceCurrency === sourceCurrency && r.targetCurrency === targetCurrency,
                        )
                        if (ratePair) {
                            setExchangeRate({
                                rate: ratePair.rate,
                                expiryDate: DateTime.local().plus({milliseconds: exchangeRateCheckInterval}),
                            })
                        }
                    }
                    setUpdatingExchangeRate(false)
                }
                updateExchangeRates()
                const updateExchangeRateTimer = setInterval(() => {
                    // Update exchange rate every 15 seconds
                    updateExchangeRates()
                }, exchangeRateCheckInterval)

                return () => {
                    clearInterval(updateExchangeRateTimer)
                }
            }, [sourceCurrency, targetCurrency])

            React.useEffect(() => {
                // When user typed a new source amount and that triggered target
                // amount update, we calculate the estimated target amount and
                // update it.
                if (
                    triggerTargetAmountUpdate &&
                    sourceCurrencyValue &&
                    exchangeRate &&
                    exchangeFeeRate !== null &&
                    exchangeRate !== 'xe_unreachable'
                ) {
                    const {exchangeFee, amountToExchange, targetAmount} = calculateCurrencyExchangeFromSourceAmount(
                        sourceCurrencyValue,
                        exchangeRate.rate,
                        exchangeFeeRate,
                    )
                    setDetails({exchangeFee, amountToExchange})

                    setTriggerSourceAmountUpdate(false)
                    setFieldValue('targetCurrencyValue', roundDownToTwoDecimalPlaces(targetAmount))
                }
                if (triggerTargetAmountUpdate && !sourceCurrencyValue) {
                    setDetails({exchangeFee: undefined, amountToExchange: undefined})
                    setTriggerSourceAmountUpdate(false)
                    setFieldValue('targetCurrencyValue', '')
                }
            }, [triggerTargetAmountUpdate, sourceCurrencyValue, exchangeRate])

            React.useEffect(() => {
                // When user typed a new target amount and that triggered source
                // amount update, we calculate the estimated source amount and
                // update it.
                if (
                    triggerSourceAmountUpdate &&
                    targetCurrencyValue &&
                    exchangeRate &&
                    exchangeFeeRate !== null &&
                    exchangeRate !== 'xe_unreachable'
                ) {
                    const {exchangeFee, amountToExchange, sourceAmount} = calculateCurrencyExchangeFromTargetAmount(
                        targetCurrencyValue,
                        exchangeRate.rate,
                        exchangeFeeRate,
                    )
                    setDetails({exchangeFee, amountToExchange})

                    setTriggerTargetAmountUpdate(false)
                    setFieldValue('sourceCurrencyValue', roundUpToTwoDecimalPlaces(sourceAmount))
                }

                if (triggerSourceAmountUpdate && !targetCurrencyValue) {
                    setDetails({exchangeFee: undefined, amountToExchange: undefined})

                    setTriggerTargetAmountUpdate(false)
                    setFieldValue('sourceCurrencyValue', '')
                }
            }, [triggerSourceAmountUpdate, targetCurrencyValue, exchangeRate])

            React.useEffect(() => {
                if (!exchangeRate || exchangeRate === 'xe_unreachable') {
                    return
                }
                // store any user entered values immediately so the user can naviagate away without losing data
                setStagedExchangeOrder({
                    sourceCurrency,
                    sourceAmount: sourceCurrencyValue || undefined,
                    amountToExchange: details.amountToExchange,
                    targetCurrency,
                    targetAmount: targetCurrencyValue || undefined,
                    exchangeRate: exchangeRate.rate.toString(),
                    exchangeFee: details.exchangeFee,
                    showDetails,
                    buyOrSell,
                })
            }, [
                sourceCurrency,
                sourceCurrencyValue,
                targetCurrency,
                targetCurrencyValue,
                showDetails,
                details,
                exchangeRate,
                buyOrSell,
            ])

            React.useEffect(() => {
                if (stagedExchangeOrderRateError && !newRateError) {
                    recalcDependingOnBuyOrSell()
                }
                setNewRateError(stagedExchangeOrderRateError)
            }, [stagedExchangeOrderRateError])

            React.useEffect(() => {
                recalcDependingOnBuyOrSell()
            }, [sourceCurrency, targetCurrency])

            const recalcDependingOnBuyOrSell = () => {
                if (buyOrSell === 'buy') {
                    // buys are when an exact target value is set, therefore recalc source
                    setTriggerSourceAmountUpdate(true)
                } else {
                    // sells are when an exact target source is set, therefore recalc target
                    setTriggerTargetAmountUpdate(true)
                }
            }

            const handleSourceCurrencyChange = (currency: Currency) => {
                setSourceCurrency(currency)
                if (currency === targetCurrency) {
                    setTargetCurrency(sourceCurrency)
                }
            }

            const handleTargetCurrencyChange = (currency: Currency) => {
                setTargetCurrency(currency)
                if (currency === sourceCurrency) {
                    setSourceCurrency(targetCurrency)
                }
            }

            // if we weren't able to get the exchange rates, return early with a compact version of the page and an error modal
            if (exchangeRate === 'xe_unreachable') {
                return (
                    <>
                        {!hideToolbar && <h2>Exchange money</h2>}
                        <ExchangeRateXeUnreachable onClick={() => navigate(-1)} />
                    </>
                )
            }

            const getErrorMessage = (sourceCurrencyError: string | undefined) => {
                if (!sourceCurrencyError || sourceCurrencyError === MINIMUM_ERROR) {
                    return undefined
                }
                return sourceCurrencyError
            }

            const sourceCurrencySupplementText = (
                <>
                    <div className={styles.supplementTextContainer}>
                        <div aria-live="polite" className={styles.errorMessageWrapper}>
                            {getErrorMessage(errors.sourceCurrencyValue)}
                        </div>
                        <div className={styles.exchangeDetailsWrapper}>
                            <div>
                                {updatingExchangeRate && (
                                    <div className={styles.updatingExchangeRate}>
                                        <span className={styles.loader}>
                                            <Loader size={16} />
                                        </span>
                                        <p className={styles.exchangeRate}>Refreshing rate</p>
                                    </div>
                                )}

                                {!updatingExchangeRate && exchangeRate && (
                                    <div className={styles.exchangeRate}>
                                        <Tooltip>
                                            <p className={tooltipStyles.label}>
                                                <ExchangeRateValue
                                                    sourceCurrency={sourceCurrency}
                                                    targetCurrency={targetCurrency}
                                                    exchangeRate={exchangeRate.rate}
                                                />
                                            </p>
                                            <div className={tooltipStyles.tooltip}>
                                                {exchangeRate && (
                                                    <p className={styles.tooltip}>
                                                        This is the exchange rate as of{' '}
                                                        {exchangeRate.expiryDate.toFormat(dateFormatWithSecond)}
                                                    </p>
                                                )}
                                            </div>
                                        </Tooltip>
                                    </div>
                                )}
                                <button
                                    className={cn(styles.showDetailsButton, accessibility.button)}
                                    type="button"
                                    onClick={() => setShowDetails(!showDetails)}
                                >
                                    {showDetails ? 'Hide' : 'Show'} details
                                    {showDetails ? <ChevronUp /> : <ChevronDown />}
                                </button>
                            </div>
                        </div>
                    </div>
                    {showDetails && (
                        <div className={styles.details}>
                            <div className={spacing.spaceBelow12}>
                                <p className={styles.exchangeFee}>
                                    Exchange fee
                                    <ExchangeMoneyFees />
                                </p>

                                <p className={styles.amount}>
                                    {details.exchangeFee ? (
                                        <FeeValue value={details.exchangeFee} currency={sourceCurrency} />
                                    ) : (
                                        '-'
                                    )}
                                </p>
                            </div>
                            <div>
                                <p>Amount to exchange</p>
                                <p className={styles.amount}>
                                    {details.amountToExchange ? (
                                        <DollarValue value={details.amountToExchange} currency={sourceCurrency} />
                                    ) : (
                                        '-'
                                    )}
                                </p>
                            </div>
                        </div>
                    )}
                </>
            )

            return (
                <>
                    <p className={spacing.spaceBelow20}>
                        <WalletValue value={walletBalances[sourceCurrency]} currency={sourceCurrency} />
                        {roundDownToTwoDecimalPlaces(walletBalances[sourceCurrency]) !== '0.00' && (
                            <>
                                {' '}
                                <ButtonAsLink
                                    onClick={() => {
                                        setBuyOrSell('sell')
                                        setTriggerTargetAmountUpdate(true)
                                        setFieldValue(
                                            'sourceCurrencyValue',
                                            roundDownToTwoDecimalPlaces(walletBalances[sourceCurrency]),
                                        )
                                    }}
                                    disabled={accountRestricted}
                                >
                                    Exchange all
                                </ButtonAsLink>
                            </>
                        )}
                    </p>
                    <ExchangeMoneyInput
                        dataTestId="exchange-money-input--source"
                        name="sourceCurrencyValue"
                        label="From"
                        currency={sourceCurrency}
                        choices={choices}
                        autoFocus
                        optionalAttributes={{
                            disabled: accountRestricted,
                            onKeyDown: () => {
                                setBuyOrSell('sell') // sells are when an exact source value is set
                                setTriggerTargetAmountUpdate(true)
                            },
                        }}
                        handleCurrencyChange={handleSourceCurrencyChange}
                        helpText={sourceCurrencySupplementText}
                        suppressValidation
                    />

                    <ExchangeMoneyInput
                        dataTestId="exchange-money-input--target"
                        name="targetCurrencyValue"
                        label="To"
                        currency={targetCurrency}
                        choices={choices}
                        optionalAttributes={{
                            disabled: accountRestricted,
                            onKeyDown: () => {
                                setBuyOrSell('buy') // buys are when an exact target value is set
                                setTriggerSourceAmountUpdate(true)
                            },
                        }}
                        handleCurrencyChange={handleTargetCurrencyChange}
                    />

                    {usdSelectedButUsEquitiesDisabled && (
                        <div className={spacing.spaceBelow24}>
                            <AlertCard type="none" aria-live="polite">
                                <>
                                    <p className={spacing.spaceBelow4}>
                                        To invest in US shares or exchange money to US dollars, you must fill out a US
                                        tax form.
                                    </p>
                                    <Link
                                        to={profileUrl('us-sign-up')}
                                        type="primary"
                                        onClick={() => onUsSharesSignUpClick()}
                                    >
                                        Fill out form
                                    </Link>
                                </>
                            </AlertCard>
                        </div>
                    )}

                    <Button
                        type="primary"
                        dataTestId="button--exchange-currency"
                        label="Exchange"
                        disabled={
                            !isValid ||
                            !exchangeRate ||
                            stagedExchangeOrderRateError ||
                            accountRestricted ||
                            usdSelectedButUsEquitiesDisabled
                        }
                        processing={isSubmitting || updatingExchangeRate} // prevent submit while rate is changing
                        onClick={() => handleSubmit()}
                        aria-label={updatingExchangeRate ? 'Updating exchange rate' : 'Exchange'}
                    />
                </>
            )
        },
    ),
)
export const ExchangeMoney = () => {
    const dispatch = useAppDispatch()
    const walletBalances = useAppSelector(({identity}) => identity.user?.wallet_balances)
    const stagedExchangeOrder = useAppSelector(({accounting}) => accounting.stagedExchangeOrder)
    const stagedExchangeOrderRateError = useAppSelector(({accounting}) => !!accounting.stagedExchangeOrderRateError)
    const homeCurrency = useAppSelector(({identity}) => identity.user!.home_currency)
    const availableCurrencies = useAppSelector(state => getAvailableCurrencies(state))
    const accountRestricted = useAppSelector(({identity}) => identity.user!.account_restricted)
    const hideToolbar = useAppSelector(({nav}) => nav.toolbarHidden)
    const actingAsId = useAppSelector(state => actingAsIDSelector(state))
    const usEquitiesEnabled = useAppSelector(s => s.identity.user!.us_equities_enabled)
    const profileUrl = useProfileUrl()

    const onUsSharesSignUpClick = () => {
        dispatch(signUpActions.initUSStateMachine())
        dispatch(signUpActions.getUSStatus())
        dispatch(signUpActions.setUSPreSignUpLocation(profileUrl('wallet/exchange-money')))
        rudderTrack('signup', 'us_shares_signup_started', {
            source: 'currency_exchange',
        })
    }

    if (!walletBalances) {
        return <Loading isPineapple />
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--exchange-money" leftButton="back" title="Exchange money" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                {stagedExchangeOrderRateError && (
                    <AlertCard className={spacing.spaceBelow24} type="warning">
                        <p>The exchange rate has changed since you were last active.</p>
                        <ButtonAsLink onClick={() => dispatch(actions.UpdateStagedExchangeOrderError(false))}>
                            Ok, thanks
                        </ButtonAsLink>
                    </AlertCard>
                )}

                <ExchangeMoneyForm
                    getExchangeRates={getExchangeRates}
                    walletBalances={walletBalances}
                    stagedExchangeOrder={stagedExchangeOrder}
                    setStagedExchangeOrder={order => dispatch(actions.SetStagedExchangeOrder(order))}
                    stagedExchangeOrderRateError={stagedExchangeOrderRateError}
                    homeCurrency={homeCurrency}
                    availableCurrencies={availableCurrencies}
                    accountRestricted={accountRestricted}
                    hideToolbar={hideToolbar}
                    actingAsId={actingAsId}
                    usEquitiesEnabled={usEquitiesEnabled}
                    onUsSharesSignUpClick={onUsSharesSignUpClick}
                    profileUrl={profileUrl}
                />
            </Page>
        </>
    )
}

export default ExchangeMoney
