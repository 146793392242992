import {distillGetFactory, DistillScope} from '~/api/query/distill'

interface DistillInstrumentInfoOptions {
    searchFundInvestments: boolean
}
const useDistillInstrumentInfo = (
    scope: DistillScope,
    {searchFundInvestments}: DistillInstrumentInfoOptions = {searchFundInvestments: false},
) => {
    const {data} = distillGetFactory({
        apiFunctionName: 'apiV1InstrumentsInfoV2Get',
        options: {
            // this endpoint very rarely changes, and can be aggressively cached
            cacheTime: 6 * 60 * 60 * 1000, // 6 hours
            staleTime: 60 * 60 * 1000, // 1 hour
        },
    })({
        scope,
        searchFundInvestments,
    })
    return data
}

export default useDistillInstrumentInfo
