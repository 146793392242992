import {DateTime} from 'luxon'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {Period, periods, periodInformation} from '~/global/utils/time-period/timePeriod'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import Chips from '~/global/widgets/chips/Chips'
import InstrumentPerformanceChart from '~/sections/invest/sections/view-instrument/widgets/charts/InstrumentPerformanceChart'
import {InstrumentHistoryItem} from '~/store/accounting/selectors'
import {Exchange, Instrument} from '~/store/instrument/types'
import styles from './PriceHistory.scss'

interface PriceHistoryProps {
    instrument: Instrument
    exchange?: Exchange
    instrumentHistory: InstrumentHistoryItem[]
    setPeriod: (period: Period) => void
    period: Period
}

const PriceHistory: React.FunctionComponent<PriceHistoryProps> = React.memo(
    ({instrument, exchange, instrumentHistory, setPeriod, period}) => {
        const startDateString = DateTime.local().minus(periodInformation[period].duration).toISODate()
        // We don't need the "All" period option in the price chips (yet).
        const pricePeriods = periods.slice(0, 6)

        return (
            <div className={spacing.spaceBelow40}>
                <div className={styles.periodControlContainer}>
                    <Chips
                        options={pricePeriods}
                        onChipClick={periodOptions => {
                            setPeriod(periodOptions)
                        }}
                        selected={[period]}
                        additionalAriaLabel="Show price history over"
                        isInlineDisplay
                    />
                    <InstrumentPerformanceChart
                        startDate={startDateString}
                        exchange={exchange}
                        instrumentHistory={instrumentHistory}
                        period={period}
                        isListedInstrument={tradingType(instrument) === 'listed'}
                    />
                </div>
            </div>
        )
    },
)

export default PriceHistory
