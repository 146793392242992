import React from 'react'

const TimeframeDetail: React.FunctionComponent = () => (
    <div>
        <h1>Investment timeframe</h1>
        <p>An investment timeframe is the length of time you plan to leave your money invested for.</p>
        <p>Fund managers recommend a timeframe when they set up a fund.</p>
    </div>
)

export default TimeframeDetail
