import {Button} from '@design-system/button'
import {colour, Colour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import React from 'react'
import {Model} from '~/api/retail/types'
import asx from '~/global/pages/info-pages/assets/images/asx.svg'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {connect} from '~/store/connect'
import {useAppSelector} from '~/store/hooks'
import actions from '~/store/instrument/actions'
import styles from './InfoPages.scss'

const useCurrentFxFee = () => {
    const {jurisdiction} = useAppSelector(s => ({
        jurisdiction: s.identity.user!.jurisdiction,
    }))

    return jurisdiction === 'au' ? '0.6' : '0.5'
}

export const SvgBackgroundOdd = ({colour}: {colour: string}) => (
    <svg
        width="320px"
        height="220px"
        viewBox="0 0 100% 100%"
        preserveAspectRatio="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g stroke="none" strokeWidth="1" fill={colour} fillRule="evenodd">
            <path d="M0,0 L320,0 L320,144.094727 C271.403646,144.424805 221.166992,157.158203 169.290039,182.294922 C117.413086,207.431641 60.9830729,220 0,220 L0,0 Z" />
        </g>
    </svg>
)
export const SvgBackgroundEven = ({colour}: {colour: string}) => (
    <svg
        width="320px"
        height="220px"
        viewBox="0 0 100% 100%"
        preserveAspectRatio="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g stroke="none" strokeWidth="1" fill={colour} fillRule="evenodd">
            <path
                d="M0,0 L320,0 L320,144.094727 C271.403646,144.424805 221.166992,157.158203 169.290039,182.294922 C117.413086,207.431641 60.9830729,220 0,220 L0,0 Z"
                transform="translate(160.000000, 110.000000) scale(-1, 1) translate(-160.000000, -110.000000)"
            />
        </g>
    </svg>
)

interface InfoPagesProps {
    content: {
        img: string
        text: JSX.Element
        backgroundImageIndex: number
        backgroundColour: string
        pageBackColour?: Colour
    }
}
const InfoPages: React.FunctionComponent<InfoPagesProps> = ({content}) => {
    return (
        <>
            <Toolbar dataTestId="toolbar--info-pages" leftButton="back" />
            <Page withoutDefaultPadding>
                <div className={styles.infoPage}>
                    <div className={styles.background}>
                        <div
                            className={styles.image}
                            style={{
                                backgroundImage: `url(${content.img})`,
                            }}
                        />
                    </div>
                    <div className={styles.content}>{content.text}</div>
                </div>
            </Page>
        </>
    )
}

interface StoreProps {
    jurisdiction: Model.User['jurisdiction']
}

export const AUShares: React.FunctionComponent<AUSharesProps> = ({
    jurisdiction,
    locationToReturnTo,
    initialiseSearchWithExchange,
}) => {
    const colourMode = useColourMode()
    const navigate = useNavigate()
    const fee = useCurrentFxFee()
    const profileUrl = useProfileUrl()
    return (
        <InfoPages
            content={{
                text: (
                    <>
                        <h1>Invest in Australian shares</h1>
                        <p>
                            You can now access over 2,000 companies and exchange-traded funds (ETFs) from the Australian
                            Securities Exchange (ASX).
                        </p>
                        <h2>Australian dollars</h2>
                        <p>
                            To invest in Australian shares, you’ll need to use Australian dollars (AUD). You can
                            exchange another currency in your Wallet to AUD in advance, or convert it automatically when
                            you buy Australian shares. There’s a{fee ? ` ${fee}%` : 'n'} exchange fee—
                            <HelpCentreLink
                                auArticle="4983259-exchange-currencies"
                                nzArticle="4239948-how-to-exchange-currency"
                            >
                                read how currency exchange works
                            </HelpCentreLink>
                            .
                        </p>
                        <p>
                            The Sharesies transaction fee applies to both Australian companies and
                            {jurisdiction === 'nz' ? ' funds—' : ' ETFs—'}
                            <HelpCentreLink nzArticle="813634-fees">
                                read about fees for Australian shares
                            </HelpCentreLink>
                            .
                        </p>
                        <h2>Tax</h2>
                        {jurisdiction === 'nz' && (
                            <p>
                                As an NZ tax resident, we’ll pay tax on dividends from Australian shares for you—
                                <HelpCentreLink nzArticle="1399438-tax">
                                    read about tax on Australian shares
                                </HelpCentreLink>
                                .
                            </p>
                        )}
                        {jurisdiction === 'au' && (
                            <p>
                                If you’re an Australian tax resident with a valid tax file number (TFN), you’ll need to
                                pay your own tax on Australian shares to the Australian Taxation Office (ATO)—
                                <HelpCentreLink auArticle="4983208-tax">
                                    read about tax on Australian shares
                                </HelpCentreLink>
                                .
                            </p>
                        )}
                        <h2>ASX trading hours</h2>
                        <p>
                            The ASX is open on weekdays, except for Australian public holidays. See exact trading hours
                            for the week ahead by looking at an ASX-listed investment in Sharesies. You can place an
                            order at any time, but it’ll only go to market when the ASX is open.
                        </p>
                        <h2>Got questions or feedback?</h2>
                        {jurisdiction === 'nz' && (
                            <p>
                                We’re always keen to hear your feedback! Send us your thoughts, the good and the not so
                                good. Get in touch at <a href="mailto:help@sharesies.co.nz">help@sharesies.co.nz</a>
                            </p>
                        )}
                        {jurisdiction === 'au' && (
                            <p>
                                We’re always keen to hear your feedback! Send us your thoughts, the good and the not so
                                good. Get in touch at <a href="mailto:help@sharesies.com.au">help@sharesies.com.au</a>
                            </p>
                        )}
                        <p>Happy investing!</p>
                        <div className={spacing.spaceAbove48}>
                            <Button
                                label="Got it"
                                dataTestId="button--got-it"
                                type="secondary"
                                onClick={() => {
                                    if (!locationToReturnTo || locationToReturnTo === '/invest/search') {
                                        initialiseSearchWithExchange('ASX')
                                        navigate(profileUrl('invest/search'))
                                    } else {
                                        navigate(locationToReturnTo)
                                    }
                                }}
                            />
                        </div>
                    </>
                ),
                img: asx,
                backgroundColour: colour('white', colourMode),
                backgroundImageIndex: 1,
                pageBackColour: 'grey-2',
            }}
        />
    )
}

interface AUSharesDispatchProps {
    initialiseSearchWithExchange(exchange: string): void
}

interface AUStoreProps {
    locationToReturnTo?: string
}

type AUSharesProps = StoreProps & AUStoreProps & AUSharesDispatchProps

export const ConnectedAUShares = connect<StoreProps, AUSharesDispatchProps, {}>(
    state => ({
        jurisdiction: state.identity.user!.jurisdiction,
        locationToReturnTo: state.signUp.locationToReturnTo,
    }),
    dispatch => ({
        initialiseSearchWithExchange: exchange => dispatch(actions.initialiseSearchWithExchange(exchange)),
    }),
)(AUShares)
