import {Button} from '@design-system/button'
import {DockableModal} from '@design-system/dockable-modal'
import React from 'react'
import {typographyOverrides} from '~/global/scss/helpers'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {DollarValue, FeeValue} from '~/global/widgets/number-elements/NumberElements'
import OrderConfirmation, {CoverageText} from '~/global/widgets/order-confirmation/OrderConfirmation'
import {frequencySelect} from '~/sections/OLD_wallet/sections/linked-bank-payments/utils/frequency-select-values/frequencySelectValues'
import {getTopupBreakdown} from '~/sections/OLD_wallet/sections/linked-bank-payments/utils/get-topup-breakdown/getTopupBreakdown'
import {useAppSelector} from '~/store/hooks'
import styles from './Confirmation.scss'

interface ConfirmationProps {
    topupBreakdown: ReturnType<typeof getTopupBreakdown>
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    onSubmitHandler: () => void
    recurring?: boolean
    isSubmitting: boolean
}

export const Confirmation: React.FC<ConfirmationProps> = ({
    topupBreakdown,
    isOpen,
    setIsOpen,
    onSubmitHandler,
    recurring,
    isSubmitting,
}) => {
    const debitAccount = useAppSelector(({bankLinking}) => bankLinking.roundupsDebitAccount!)

    if (!topupBreakdown) {
        return null
    }

    const items = [
        {
            description: 'Top-up amount',
            value: <DollarValue value={topupBreakdown.topupAmount} currency="AUD" />,
        },
        {
            description: 'From',
            value: `${debitAccount.accountName || debitAccount.bankName} ${debitAccount.accountNumber}`,
        },
        {
            description: 'Transfer fee',
            value: <FeeValue value={topupBreakdown.feeAmount} currency="AUD" />,
            explainerText: topupBreakdown.feeAmount === 0 ? <CoverageText feeCoverage="full" /> : undefined,
        },
    ]

    if (topupBreakdown.frequency) {
        items.splice(1, 0, {
            description: 'Repeats',
            value: frequencySelect.find(o => o.value === topupBreakdown.frequency)!.label,
        })
    }

    return (
        <>
            <DockableModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={
                    <>
                        <h3 className={typographyOverrides['as-h2']}>
                            {recurring ? 'Confirm your recurring top-up' : 'Confirm your one-off top-up'}
                        </h3>
                        {recurring && (
                            <p className={styles.repeats}>
                                Starting {topupBreakdown.nextPayment?.toFormat(dateFormatNoTime)}
                            </p>
                        )}
                    </>
                }
                dataTestId="modal--confirm-linked-bank-topup"
            >
                <OrderConfirmation
                    items={items}
                    total={{
                        description: 'Total',
                        value: <DollarValue value={topupBreakdown.totalAmount} currency="AUD" />,
                    }}
                />
                <Button
                    label={recurring ? 'Confirm' : 'Top up Wallet'}
                    disabled={false}
                    processing={isSubmitting}
                    onClick={onSubmitHandler}
                    dataTestId="button--linked-bank-topup-submit"
                />
            </DockableModal>
        </>
    )
}
