import React from 'react'
import rightsSlide0 from '~/global/assets/images/intro-slides/rights-slides/rights-slide-0.svg'
import rightsSlide1 from '~/global/assets/images/intro-slides/rights-slides/rights-slide-1.svg'
import rightsSlide2 from '~/global/assets/images/intro-slides/rights-slides/rights-slide-2.svg'
import rightsSlide3Dark from '~/global/assets/images/intro-slides/rights-slides/rights-slide-3-dark.svg'
import rightsSlide3Light from '~/global/assets/images/intro-slides/rights-slides/rights-slide-3-light.svg'
import rightsSlide4Dark from '~/global/assets/images/intro-slides/rights-slides/rights-slide-4-dark.svg'
import rightsSlide4Light from '~/global/assets/images/intro-slides/rights-slides/rights-slide-4-light.svg'
import rightsSlide5 from '~/global/assets/images/intro-slides/rights-slides/rights-slide-5.svg'
import rightsSlide6 from '~/global/assets/images/intro-slides/rights-slides/rights-slide-6.svg'
import rightsSlide7Dark from '~/global/assets/images/intro-slides/rights-slides/rights-slide-7-dark.svg'
import rightsSlide7Light from '~/global/assets/images/intro-slides/rights-slides/rights-slide-7-light.svg'
import votingSlide1 from '~/global/assets/images/intro-slides/voting-slides/voting-slide-1.png'
import votingSlide2 from '~/global/assets/images/intro-slides/voting-slides/voting-slide-2.png'
import votingSlide3 from '~/global/assets/images/intro-slides/voting-slides/voting-slide-3.png'
import votingSlide4Dark from '~/global/assets/images/intro-slides/voting-slides/voting-slide-4-dark.svg'
import votingSlide4Light from '~/global/assets/images/intro-slides/voting-slides/voting-slide-4-light.svg'
import votingSlide5Dark from '~/global/assets/images/intro-slides/voting-slides/voting-slide-5-dark.svg'
import votingSlide5Light from '~/global/assets/images/intro-slides/voting-slides/voting-slide-5-light.svg'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {
    ClassicIntroSlideContent,
    LearnModuleSlideContent,
} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {
    LimitOrdersContent,
    ManagedFundPricesContent,
    ManagedFundProcessingTimesContent,
    MarketOrdersContent,
    MarketProcessingTimesContent,
    PricesOnMarketContent,
} from '~/sections/explore/pages/learn-static-content/LearnContent'

export const managedFundIntroContent: ClassicIntroSlideContent[] = [
    ManagedFundPricesContent,
    ManagedFundProcessingTimesContent,
]

export const exchangeFundIntroContent: ClassicIntroSlideContent[] = [
    MarketOrdersContent,
    LimitOrdersContent,
    PricesOnMarketContent,
    MarketProcessingTimesContent,
]

export const voteIntroContent: LearnModuleSlideContent[] = [
    {
        image: votingSlide1,
        bodyRenderer: () => (
            <>
                <p>Voting is a powerful way to have your say as an investor, and help influence a company’s future.</p>
            </>
        ),
    },
    {
        image: votingSlide2,
        bodyRenderer: () => (
            <>
                <p>
                    You might be able to vote on things like a company’s structure, board of directors, or dividend
                    payments.
                </p>
            </>
        ),
    },
    {
        image: votingSlide3,
        bodyRenderer: () => (
            <>
                <p>Usually, you’ll receive votes if you own a company’s shares on a specific date.</p>
            </>
        ),
    },
    {
        image: votingSlide4Light,
        darkImage: votingSlide4Dark,
        bodyRenderer: () => (
            <>
                <p>Before you vote, check out any documents the company might’ve released—like their annual report.</p>
            </>
        ),
    },
    {
        image: votingSlide5Light,
        darkImage: votingSlide5Dark,
        bodyRenderer: () => (
            <>
                <p>
                    For more info about how voting works on Sharesies,{' '}
                    <HelpCentreLink auArticle="6543871-voting" nzArticle="5302413-voting">
                        check out our help centre
                    </HelpCentreLink>
                    .
                </p>
            </>
        ),
    },
]

export const rightsOfferContent: LearnModuleSlideContent[] = [
    {
        image: rightsSlide0,
        bodyRenderer: () => <p>A rights offer is a way for a company to raise money.</p>,
    },
    {
        image: rightsSlide1,
        bodyRenderer: () => <p>During a rights offer, a company allocates rights to its shareholders.</p>,
    },
    {
        image: rightsSlide2,
        bodyRenderer: () => (
            <p>Each right gives shareholders the chance to buy more shares—usually at a discounted price…</p>
        ),
    },
    {
        image: rightsSlide3Light,
        darkImage: rightsSlide3Dark,
        bodyRenderer: () => <p>…and in proportion to the amount of shares they already own.</p>,
    },
    {
        image: rightsSlide4Light,
        darkImage: rightsSlide4Dark,
        bodyRenderer: () => <p>If you receive rights, you can choose to…</p>,
    },
    {
        image: rightsSlide5,
        bodyRenderer: () => (
            <p>
                <strong>Exercise them</strong>—this means paying the offer price for each right you want to turn into a
                share.
            </p>
        ),
    },
    {
        image: rightsSlide6,
        bodyRenderer: () => (
            <p>
                <strong>Do nothing</strong>—you don’t have to exercise your rights, but your existing shares might lose
                value.
            </p>
        ),
    },
    {
        image: rightsSlide7Light,
        darkImage: rightsSlide7Dark,
        bodyRenderer: () => (
            <p>
                Sometimes a rights offer will be <strong>renounceable</strong>, which gives you the option to sell your
                rights—or buy more.
            </p>
        ),
    },
    {
        image: votingSlide4Light, // pinch the voting slide
        darkImage: votingSlide4Dark,
        bodyRenderer: () => <p>Before you do anything with your rights, check out the company’s offer document.</p>,
    },
    {
        image: votingSlide5Light, // pinch the voting slide
        darkImage: votingSlide5Dark,
        bodyRenderer: () => (
            <p>
                For more info about how rights work on Sharesies,{' '}
                <HelpCentreLink
                    auArticle="7881317-take-part-in-a-rights-offer"
                    nzArticle="7881312-take-part-in-a-rights-offer"
                >
                    check out our help centre
                </HelpCentreLink>
                .
            </p>
        ),
    },
]
