import type {Asset, Entry} from 'contentful'
import {ArticleEntry, ArticleImage, ArticleType} from '~/api/contentful/types'
import config from '~/configForEnv'

export function createSrcSetForImgixImages(url: string): string {
    return [256, 320, 384, 400, 768, 1024, 1440, 1920]
        .map(width => `${url}?auto=format,compress&cs=srgb&w=${width}&q=75 ${width}w`)
        .join(', ')
}

// Asset<'WITHOUT_UNRESOLVABLE_LINKS'> in SDK v10+
export function parseContentfulAsset(asset?: Asset): ArticleImage | undefined {
    if (!asset) {
        return undefined
    }

    const {description = '', file} = asset.fields

    if (!file) {
        return undefined
    }

    const {url, details, contentType} = file

    if (contentType === 'image/gif') {
        // gifs can't go through imgix
        return {
            src: `https:${url}`,
            alt: description,
            width: details.image?.width,
            height: details.image?.height,
        }
    }

    const imgixUrl = url.replace('//images.ctfassets.net/eg3voq9njjf7', 'https://sharesies.imgix.net')
    const srcSet = createSrcSetForImgixImages(imgixUrl)

    return {
        src: imgixUrl,
        srcSet,
        alt: description,
        width: details.image?.width,
        height: details.image?.height,
    }
}

export function contentfulEntryLink(entryId: string): string {
    return `https://app.contentful.com/spaces/${config.contentfulSpaceId}/entries/${entryId}`
}

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

export function dateMonthYear(iso: string): string {
    const date = new Date(iso)

    const year = date.getFullYear()
    const month = date.getMonth()
    const dt = date.getDate()

    return `${dt} ${months[month]} ${year}`
}

// Entry<LearnArticleEntry, 'WITHOUT_UNRESOLVABLE_LINKS'> in SDK v10+
export function parseArticle(item: Entry<ArticleEntry>): ArticleType {
    const id = item.sys.id

    try {
        const publishedDate = dateMonthYear(item.fields.publishDate)

        const tags = item.fields.tags || [] // AKA categories
        const title = item.fields.title

        // @NOTE Will need to include `/blog/articles` once those are supported
        const url = `/learn/articles/${item.fields.url}`

        return {
            id,
            title,
            url,
            image: parseContentfulAsset(item.fields.image),
            feedImage: parseContentfulAsset(item.fields.feedImage),
            tags: tags || [],
            publishDate: publishedDate || '',
            body: item.fields.body,
            intro: item.fields.intro,
            // authors: parseAuthors(item.fields.authors),
            // numberOfWords,
            // timeToReadMins,
        }
    } catch (error) {
        throw new Error(
            `Something went wrong parsing an article from Contentful.\n ${contentfulEntryLink(id)}\n ${error}`,
        )
    }
}
