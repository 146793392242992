import {Button} from '@design-system/button'
import cn from 'classnames'
import {withFormik} from 'formik'
import React from 'react'
import {Request} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {
    NatureAndPurposeStep,
    annualAmountChoices,
    frequencyChoices,
    purposesChoices as NZPurposesChoices,
    AUPurposesChoices,
    purposesChoicesForOrganisation,
} from '~/global/utils/nature-and-purpose/natureAndPurpose'
import {validate} from '~/global/widgets/form-controls'
import {Radio, CheckboxGroup, Text} from '~/global/widgets/form-controls/formik'
import {useAppSelector} from '~/store/hooks'
import styles from './NatureAndPurpose.scss'

interface FormProps {
    step: NatureAndPurposeStep
    setStep(value: NatureAndPurposeStep): void
    getNextStep(value: NatureAndPurposeStep): NatureAndPurposeStep
    setNatureAndPurpose(
        frequency: Request.NatureAndPurposeUpdate['frequency'],
        annual_amount: Request.NatureAndPurposeUpdate['annual_amount'],
        purpose: Request.NatureAndPurposeUpdate['purposes'],
        other_purpose: string,
    ): Promise<string | undefined>
    onSubmit?(): void
    isExistingUser: boolean
    forOrganisation: boolean
}

interface FormValues {
    frequency: Request.NatureAndPurposeUpdate['frequency'] | undefined
    annualAmount: Request.NatureAndPurposeUpdate['annual_amount'] | undefined
    purposes: Request.NatureAndPurposeUpdate['purposes'] | undefined
    otherPurpose: string
}

// NOTE: This component is used in various contexts such as personal signup,
// pre and post Fonterra signup. Similar versions of this component exist elsewhere.
// src/sections/fonterra/sections/sign-up/pages/nature-and-purpose/NatureAndPurpose.tsx
// src/sections/fonterra/sections/fonterra-personal-sign-up/pages/nature-and-purpose/NatureAndPurpose.tsx
const NatureAndPurposeForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => ({
        frequency: undefined,
        annualAmount: undefined,
        purposes: undefined,
        otherPurpose: '',
    }),
    mapPropsToErrors: () => ({
        frequency: undefined,
    }),
    handleSubmit: async (
        {frequency, annualAmount, purposes, otherPurpose},
        {setSubmitting, setStatus, props: {setNatureAndPurpose, onSubmit}},
    ) => {
        if (frequency && annualAmount && purposes) {
            try {
                const error = await setNatureAndPurpose(frequency, annualAmount, purposes, otherPurpose)
                if (error) {
                    setStatus(error)
                    setSubmitting(false)
                    return
                }
                if (onSubmit) {
                    onSubmit()
                }
            } catch (e) {
                setStatus(unknownErrorMessage)
                setSubmitting(false)
                throw e
            } finally {
                setSubmitting(false)
            }
        }
    },
    validate: values => {
        const schema = {
            frequency: [validate.required()],
            annualAmount: [validate.required()],
            purposes: [validate.required()],
            otherPurpose:
                values.purposes && values.purposes.includes('other')
                    ? [validate.required(), validate.minLength(5, 'Please provide a full description')]
                    : [],
        }
        return validate.generate<FormValues>(schema)(values)
    },
})(({step, setStep, getNextStep, values, isSubmitting, handleSubmit, isExistingUser, forOrganisation}) => {
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const purposeChoicesForPerson = jurisdiction === 'au' ? AUPurposesChoices : NZPurposesChoices
    const purposesChoices = forOrganisation ? purposesChoicesForOrganisation : purposeChoicesForPerson

    const nextIsDisabled = () => {
        switch (step) {
            case 'frequency':
                return values.frequency === undefined
            case 'annualAmount':
                return values.annualAmount === undefined
            case 'purpose':
                // disabled when other purpose is not defined but other is selected
                if (values.purposes && values.purposes.includes('other')) {
                    return values.otherPurpose === undefined || values.otherPurpose === ''
                }
                return !values.purposes?.length
        }
    }
    return (
        <form onSubmit={handleSubmit}>
            {step === 'frequency' && (
                <>
                    {isExistingUser ? (
                        <h1 className={spacing.spaceBelow24}>How frequently do you invest?</h1>
                    ) : (
                        <h1 className={spacing.spaceBelow24}>
                            How frequently are you looking to put money into Sharesies?
                        </h1>
                    )}
                    <Radio
                        dataTestId="radio--frequency"
                        name="frequency"
                        choices={frequencyChoices}
                        labelClassName={styles.titleLabel}
                    />
                </>
            )}
            {step === 'annualAmount' && (
                <>
                    {isExistingUser ? (
                        <h1 className={spacing.spaceBelow24}>How much do you invest each year?</h1>
                    ) : (
                        <h1 className={spacing.spaceBelow24}>
                            How much do you expect to put into Sharesies each year?
                        </h1>
                    )}
                    <Radio
                        dataTestId="radio--annual-amount"
                        name="annualAmount"
                        choices={annualAmountChoices}
                        labelClassName={styles.titleLabel}
                    />
                </>
            )}
            {step === 'purpose' && (
                <>
                    <h1 className={spacing.spaceBelow24}>What are your main reasons for using Sharesies?</h1>

                    <CheckboxGroup name="purposes" choices={purposesChoices} />
                    <p className={cn(styles.info, spacing.spaceBelow8)}>
                        You'll need to give an appropriate reason for why you’re using Sharesies
                    </p>
                    {values.purposes && values.purposes.includes('other') && (
                        <>
                            <Text
                                dataTestId="text-input--description"
                                name="otherPurpose"
                                label="I'm using Sharesies because..."
                                placeholder="Description"
                            />
                        </>
                    )}
                </>
            )}
            {step === 'purpose' ? (
                <Button
                    dataTestId="button--done"
                    label="Done!"
                    disabled={nextIsDisabled()}
                    isSubmit
                    pageButton
                    processing={isSubmitting}
                />
            ) : (
                <Button
                    label="Next"
                    dataTestId="button--next"
                    disabled={nextIsDisabled()}
                    pageButton
                    onClick={() => setStep(getNextStep(step))}
                />
            )}
        </form>
    )
})

export default NatureAndPurposeForm
