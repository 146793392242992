import {AddressInput as DSAddressInput} from '@design-system/address-input'
import {CheckboxInput as DSCheckbox} from '@design-system/checkbox-input'
import {DateInput as DSDateInput} from '@design-system/date-input'
import {Password as DSPassword} from '@design-system/password'
import {RadioInput as DSRadioInput} from '@design-system/radio-input'
import {Select as DSSelect} from '@design-system/select'
import {StrongCurrency as DSStrongCurrency} from '@design-system/strong-currency'
import {StrongCurrencyExchange as DSStrongCurrencyExchange} from '@design-system/strong-currency-exchange'
import {StrongInlineCurrency as DSStrongInlineCurrency} from '@design-system/strong-inline-currency'
import {StrongInlineDate as DSStrongInlineDate} from '@design-system/strong-inline-date'
import {StrongInlineSelect as DSStrongInlineSelect} from '@design-system/strong-inline-select'
import {StrongNumber as DSStrongNumber} from '@design-system/strong-number'
import {Switch as DSSwitch} from '@design-system/switch'
import {TextInput as DSTextInput} from '@design-system/text-input'
import {withFocus, withId} from './common'
import * as validate from './validate'
export {validate}

export {default as AUBankAccount} from '~/global/widgets/form-controls/bank-account-au/AUBankAccount'
export {default as BankBSB} from './bank-bsb/BankBSB'
export {default as ButtonGroup} from './button-group/ButtonGroup'
export {default as CheckboxGroup} from './checkbox-group/CheckboxGroup'
export {default as Email} from './email/Email'
export {default as ErrorBox} from './error-box/ErrorBox'
export {default as GiftCode} from './gift-code/GiftCode'
export {default as IRDNumber} from './ird-number/IRDNumber'
export {default as Number} from './number/Number'
export {default as NZBankAccount} from '~/global/widgets/form-controls/bank-account-nz/NZBankAccount'
export {default as Phone} from './phone-number/Phone'
export {default as PromoCode} from './promo-code/PromoCode'
export {default as ResetCodeField} from './reset-code/ResetCodeField'
export {PercentageInput} from '~/global/widgets/form-controls/percentage/PercentageInput'
export {default as SearchInput} from './search/SearchInput'
export {default as DecimalInput} from './decimal/DecimalInput'
export {default as ReportDateRange} from '~/global/widgets/form-controls/report-date-range/ReportDateRange'
export {default as ReportType} from '~/global/widgets/form-controls/report-type/ReportType'
export {StripeCardNumber, StripeExpiryDate, StripeCVC} from '~/global/widgets/form-controls/stripe/StripeElements'
export {default as TFNNumber} from './tfn-number/TFNNumber'
export const Address = withId(DSAddressInput)
export const Checkbox = withId(DSCheckbox)
export const DateInput = withFocus(withId(DSDateInput))
export const Password = withFocus(withId(DSPassword))
export const Select = withFocus(withId(DSSelect))
export const Radio = withFocus(withId(DSRadioInput))
export const StrongNumber = withFocus(withId(DSStrongNumber))
export const StrongCurrency = withFocus(withId(DSStrongCurrency))
export const StrongCurrencyExchange = withFocus(withId(DSStrongCurrencyExchange))
export const StrongInlineCurrency = withFocus(withId(DSStrongInlineCurrency))
export const StrongInlineSelect = withFocus(withId(DSStrongInlineSelect))
export const StrongInlineDate = withFocus(withId(DSStrongInlineDate))
export const SwitchField = withId(DSSwitch)
export const Text = withFocus(withId(DSTextInput))
