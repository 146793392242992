import React from 'react'
import {DistillScope} from '~/api/query/distill'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import useAssetManager from '~/global/utils/use-asset-manager/useAssetManager'
import Concertina from '~/global/widgets/concertina/Concertina'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {Instrument} from '~/store/instrument/types'
import {InstrumentReturn} from '~/store/portfolio/types'
import styles from './DetailModalContents.scss'

const AmountPutInDetail = ({
    currentReturn,
    instrument,
    isDependent,
    isHomeCurrencyInvestment,
    preferredName,
}: {
    currentReturn: InstrumentReturn
    instrument: Instrument
    isDependent: boolean
    isHomeCurrencyInvestment: boolean
    preferredName: string
}) => {
    const assetManager = useAssetManager(instrument, {scope: DistillScope.INVEST})
    const pronoun = isDependent ? 'they' : 'you'
    const possessive = isDependent ? `${preferredName}’s` : 'your'
    const instrumentTypePlural = shareLabel({instrument, isPlural: true})

    const totalAmountText = `The total amount spent on ${instrumentTypePlural} currently in ${possessive} Portfolio, including transaction fees`
    const corporateActionsLink = (
        <a href="https://www.sharesies.nz/learn/an-introduction-to-corporate-actions" target="_blank" rel="noopener">
            corporate actions
        </a>
    )
    const notIncludedText = isHomeCurrencyInvestment ? (
        <>
            This doesn’t include the amount paid for {instrumentTypePlural} {pronoun} no longer own, and may be adjusted
            for mergers, acquisitions, or other {corporateActionsLink}
        </>
    ) : (
        <>
            This doesn’t include foreign exchange fees or the amount paid for {instrumentTypePlural} {pronoun} no longer
            own. It may also be adjusted for mergers, acquisitions, or other {corporateActionsLink}
        </>
    )

    return (
        <div>
            <h1>Amount put in</h1>
            <p>
                {totalAmountText}. {notIncludedText}.
            </p>

            <Concertina
                title={
                    <div className={styles.concertinaTitle}>
                        <p className={styles.leftText}>Cost of {instrumentTypePlural}</p>{' '}
                        <DollarValue
                            value={currentReturn.cost_basis - currentReturn.amount_put_in_detail.transaction_fees_buys}
                            currency={instrument.currency}
                        />
                    </div>
                }
                body={
                    <p className={styles.concertinaBody}>
                        The total amount spent on {instrumentTypePlural} currently in {possessive} Portfolio.
                    </p>
                }
                border
            />

            {tradingType(instrument) !== 'managed' && (
                <Concertina
                    title={
                        <div className={styles.concertinaTitle}>
                            <p className={styles.leftText}>Transaction fees</p>{' '}
                            <DollarValue
                                value={currentReturn.amount_put_in_detail.transaction_fees_buys}
                                currency={instrument.currency}
                            />
                        </div>
                    }
                    body={
                        <p className={styles.concertinaBody}>
                            The total transaction fees paid to buy {instrumentTypePlural} currently in {possessive}{' '}
                            Portfolio.
                        </p>
                    }
                    border
                />
            )}

            {tradingType(instrument) === 'managed' && assetManager && (
                <Concertina
                    title={
                        <div className={styles.concertinaTitle}>
                            <p className={styles.leftText}>Managed fund transaction fees</p>{' '}
                            <DollarValue
                                value={currentReturn.amount_put_in_detail.unrealised_managed_fund_transaction_fees || 0}
                                currency={instrument.currency}
                            />
                        </div>
                    }
                    body={
                        <p className={styles.concertinaBody}>
                            The amount you’ve paid in transaction fees when buying{' '}
                            {isDependent && `${preferredName}’s `}
                            units. These are charged by the fund, not Sharesies. Transaction fees are estimates until
                            they’re confirmed by {assetManager.name}—this can take up to 30 days.
                        </p>
                    }
                    border
                />
            )}

            <Concertina
                title={
                    <div className={styles.concertinaTitle}>
                        <p className={styles.leftText}>Amount put in</p>{' '}
                        <p className={styles.lastRightText}>
                            <DollarValue value={currentReturn.cost_basis} currency={instrument.currency} />
                        </p>
                    </div>
                }
                border
                noChevron
            />
        </div>
    )
}

export default AmountPutInDetail
