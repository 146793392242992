import {DockableModal} from '@design-system/dockable-modal'
import {Edit, SpareChange} from '@design-system/icon'
import cn from 'classnames'
import {useSetAtom} from 'jotai'
import React from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {Navigate, useNavigate} from 'react-router-dom'
import {useCoveGet} from '~/api/query/cove'
import {useRetailGet} from '~/api/query/retail'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import headerStyles from '~/global/scss/reused-styles/pageHeader.scss'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ButtonCircle} from '~/global/widgets/button-circle/ButtonCircle'
import {ListMenuItem} from '~/global/widgets/list-menu/ListMenu'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import insureLarge from '~/sections/protect/sections/insure/assets/images/insure-large.png'
import {CoveDown} from '~/sections/protect/sections/insure/pages/cove-down/CoveDown'
import styles from '~/sections/protect/sections/insure/sections/landing/Landing.scss'
import {Policies} from '~/sections/protect/sections/insure/sections/landing/widgets/policies/Policies'
import {Quotes} from '~/sections/protect/sections/insure/sections/landing/widgets/quotes/Quotes'
import {createEstimateDetailsAtom} from '~/sections/protect/sections/insure/state'
import {coveClientErrorFallbackRender} from '~/sections/protect/sections/insure/utils/cove-client-error/coveClientError'
import {CoveConnectionErrorAlertCard} from '~/sections/protect/sections/insure/widgets/cove-connection-error-alert-card/CoveConnectionErrorAlertCard'
import {useAppSelector} from '~/store/hooks'

/**
 * The Retail GET in <LandingWrapper /> may entail an API call to Cove to refresh the customer's Cove token.
 * If Cove is down and the GET fails because of this, we know that they do have an Insure account,
 * so we want to render a Cove error message page.
 */
export const LandingErrorBoundary: React.FunctionComponent = () => {
    const fallbackRender = ({error}: {error: Error}) => {
        return coveClientErrorFallbackRender(error, <CoveDown />)
    }

    return (
        <ErrorBoundary key={location.pathname} fallbackRender={fallbackRender}>
            <LandingWrapper />
        </ErrorBoundary>
    )
}

export const LandingWrapper: React.FunctionComponent = () => {
    const profileUrl = useProfileUrl()

    const {data: insureAccount} = useRetailGet({path: 'insure/get-account'})

    if (insureAccount.type === 'insure_account') {
        // Has Insure account: go to Landing
        return <Landing />
    }

    // No Insure account: go to the intro screen
    return <Navigate to={profileUrl('protect/insure/car/intro')} replace />
}

/**
 * The Retail GET in this component is a proxy API call to Cove.
 * Therefore, if Cove is down and that GET fails, we want to hit the error boundary that this
 * component is wrapped in - that's the reason why this is a separate component.
 */
const ManageButton: React.FunctionComponent = () => {
    const [manageModalVisible, setManageModalVisible] = React.useState<boolean>(false)

    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    // Being an official Cove customer means that they have a login to the site
    const {data: isCoveCustomer} = useRetailGet({path: 'insure/get-is-cove-customer'})

    if (!isCoveCustomer.is_cove_customer) {
        return null
    }

    return (
        <>
            <ButtonCircle
                label="Manage"
                dataTestId="button--insure-manage"
                onClick={() => setManageModalVisible(true)}
                icon="MoreHorizontal"
            />
            <DockableModal
                dataTestId="dockable-modal--insure-manage"
                isOpen={manageModalVisible}
                setIsOpen={setManageModalVisible}
                title="Manage insurance"
                content={
                    <>
                        <ListMenuItem
                            className={styles.manageListItem}
                            label="Edit insurance contact details"
                            leftIcon={<Edit />}
                            onClick={() => {
                                navigate(profileUrl('protect/insure/car/cove-manage-contact'))
                            }}
                        />
                        <ListMenuItem
                            className={styles.manageListItem}
                            label="Manage insurance payment"
                            leftIcon={<SpareChange />}
                            onClick={() => {
                                navigate(profileUrl('protect/insure/car/cove-manage-payment'))
                            }}
                        />
                    </>
                }
            />
        </>
    )
}

const Landing: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const setCreateEstimateDetails = useSetAtom(createEstimateDetailsAtom)

    const combinedCountData = useCoveGet('customer/v1/counts')

    // Has no active/pending policy and no quote: redirect to the intro screen
    const shouldRedirect = combinedCountData.active_policy_count === 0 && combinedCountData.total_quote_count === 0

    if (shouldRedirect) {
        return <Navigate to={profileUrl('protect/insure/car/intro')} replace />
    }

    return (
        <LandingPage>
            <div className={cn(spacing.spaceBelow24, styles.actionsWrapper)}>
                <ButtonCircle
                    label="New Estimate"
                    dataTestId="button--insure-create-estimate"
                    onClick={() => {
                        rudderTrack('insurance_signup', 'generate_quote_clicked', {source: 'landing'})
                        setCreateEstimateDetails(undefined)
                        navigate(profileUrl('protect/insure/car/details'))
                    }}
                    icon="OrderBuy"
                />

                <ManageButton />
            </div>

            <Policies />
            <Quotes />
        </LandingPage>
    )
}

const LandingPage: React.FunctionComponent<{children: React.ReactNode}> = ({children}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const setCreateEstimateDetails = useSetAtom(createEstimateDetailsAtom)

    const toolbarHiddenForMobile = useAppSelector(s => s.nav.toolbarHidden)

    const handleBack = () => {
        setCreateEstimateDetails(undefined)
        navigate(profileUrl(''), {replace: true})
    }

    return (
        <div className={styles.background}>
            <Toolbar
                dataTestId="toolbar--insure-landing"
                leftButton="back"
                onLeftButtonClick={handleBack}
                showNotifications
            />
            <Page withoutDefaultPadding>
                <div className={styles.background}>
                    <div
                        className={headerStyles.landingImageWrapper}
                        style={{paddingTop: toolbarHiddenForMobile ? '25px' : '0px'}}
                    >
                        <img src={insureLarge} alt="" className={headerStyles.landingImage} />
                    </div>

                    <div className={headerStyles.landingContentWrapper}>
                        <h1
                            data-testid="title--insure-landing"
                            className={cn(headerStyles.title, spacing.spaceBelow32)}
                        >
                            Insurance
                        </h1>

                        <ErrorBoundary key={location.pathname} FallbackComponent={CoveConnectionErrorAlertCard}>
                            <React.Suspense fallback={<Loading />}>{children}</React.Suspense>
                        </ErrorBoundary>
                    </div>
                </div>
            </Page>
        </div>
    )
}
