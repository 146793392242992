import {getMainSchemeTypeForInstrument} from '~/sections/invest/sections/employee-share-scheme/utils/main-scheme-type/mainSchemeType'
import {EmployeeShareSchemeParticipation} from '~/store/employeeShareScheme/types'

export function generateEmployeeShareSchemeText(
    shareSchemeParticipation: EmployeeShareSchemeParticipation,
    instrumentId: string,
) {
    const mainSchemeType = getMainSchemeTypeForInstrument(shareSchemeParticipation, instrumentId)

    switch (mainSchemeType) {
        case 'ALIGNMENT_RIGHTS':
            return 'Alignment Rights'
        case 'RIGHTS':
            return 'LTI'
        case 'SHARES':
            return 'Employee shares'
    }
    return 'Company Employee'
}
