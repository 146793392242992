import React from 'react'
import {spacing} from '~/global/scss/helpers'
import useAutoinvestDividends from '~/global/utils/use-autoinvest-dividends/useAutoinvestDividends'
import {Instrument} from '~/store/instrument/types'

interface OrderReinvestDividendsBuyMessageProps {
    instrument: Instrument
}
const OrderReinvestDividendsBuyMessage: React.FC<OrderReinvestDividendsBuyMessageProps> = ({instrument}) => {
    const {loadingState, preferences} = useAutoinvestDividends()

    if (loadingState === 'ready' && preferences.primary === true && preferences.funds.includes(instrument.id)) {
        return (
            <p className={spacing.spaceBelow12}>
                You have auto-invest dividends turned on for all of your investments. If {instrument.name} dividends are
                paid, they’ll be invested automatically.
            </p>
        )
    }

    return null
}

export default OrderReinvestDividendsBuyMessage
