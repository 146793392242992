import {Button} from '@design-system/button'
import {Modal} from '@design-system/modal'
import Decimal from 'decimal.js'
import React from 'react'
import {useNavigate} from 'react-router'
import {useRetailPost} from '~/api/query/retail'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import capitalise from '~/global/utils/capitalise-string/capitaliseString'
import {dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import HelpCentreLink from '~/global/widgets/help-centre-link/HelpCentreLink'
import recordStyles from '~/global/widgets/instrument-activity/Record.scss'
import {RecordRow} from '~/global/widgets/instrument-activity/common'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import FastFeeModal from '~/sections/OLD_wallet/sections/withdrawals/widgets/fast-fee-modal/FastFeeModal'

const displayText = (state: Model.WithdrawalOrder['display_status']) => {
    switch (state) {
        case 'processed':
            return 'Your withdrawal has been processed and transferred to your bank account.'
        case 'cancelled':
            return "We've cancelled your withdrawal request."
        case 'processing':
            return 'Your withdrawal is being transferred to your bank account.'
        case 'settling':
            return (
                <>
                    We’ve received your withdrawal request and are waiting for the money to{' '}
                    <HelpCentreLink
                        nzArticle="813843-withdraw-money-from-your-wallet"
                        auArticle="4983255-withdraw-money-from-your-wallet"
                    >
                        settle
                    </HelpCentreLink>
                    . Once this happens, the money will be transferred to you.
                </>
            )
        default:
            assertNever(state)
    }
}

interface RowItem {
    description: React.ReactNode
    value: string | React.ReactNode
}

interface WithdrawalStatusProps {
    transaction: Model.Transaction & Required<Pick<Model.Transaction, 'withdrawal_order'>>
}
export const WithdrawalStatus: React.FunctionComponent<WithdrawalStatusProps> = ({transaction}) => {
    const [showModal, setShowModal] = React.useState<boolean>(false)
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const cancelWithdrawalMutation = useRetailPost({path: 'withdrawals/cancel'})
    const isEstimate = Boolean(
        !['cancelled', 'reconciled'].includes(transaction.withdrawal_order.state) && transaction.withdrawal_order.fee,
    )

    const gatherItems = () => {
        const items: RowItem[] = [
            {
                description: displayText(transaction.withdrawal_order.display_status),
                value: '',
            },
            {description: 'Date requested', value: transaction.timestamp.toFormat(dateFormatWithYear)},
            {
                description: 'Bank account',
                value: transaction.withdrawal_order.bank_account,
            },
        ]

        if (transaction.withdrawal_order.fee) {
            items.push({
                description: (
                    <>
                        {isEstimate ? 'Estimated fast' : 'Fast'} withdrawal fee <FastFeeModal isEstimate={isEstimate} />
                    </>
                ),
                value: (
                    <DollarValue value={transaction.withdrawal_order.fee} currency={transaction.currency} roundDown />
                ),
            })
        } else if (transaction.withdrawal_order.state !== 'cancelled') {
            // Only show un/cleared info if withdrawal hasn't been cancelled
            if (transaction.withdrawal_order.cleared_at) {
                items.push({
                    description: 'Settled on',
                    value: transaction.withdrawal_order.cleared_at.toFormat(dateFormatWithYear),
                })
            } else {
                items.push({
                    description: 'Amount settled',
                    value: (
                        <DollarValue
                            value={transaction.withdrawal_order.cleared_amount}
                            currency={transaction.currency}
                            roundDown
                        />
                    ),
                })
                items.push({
                    description: 'Waiting to settle',
                    value: (
                        <DollarValue
                            value={transaction.withdrawal_order.uncleared_amount}
                            currency={transaction.currency}
                            roundDown
                        />
                    ),
                })
            }
        }
        return items
    }
    // These states are hard to share from the backend nicely. Sorry
    const withdrawalCanBeCancelled = ['pending', 'pending_preprocessing', 'pending_dynamic_checks', 'ready'].includes(
        transaction.withdrawal_order.state,
    )

    const cancelWithdrawal = async (e: React.FormEvent) => {
        e.preventDefault()

        const resp = await cancelWithdrawalMutation.mutateAsync({
            withdrawal_id: transaction.withdrawal_order.id,
        })

        if (resp.type === 'withdrawal_cancel_success_response') {
            Toast('Withdrawal cancelled')
            navigate(profileUrl('wallet'))
        } else {
            // TODO: handle this properly
            Toast(`Sorry, something went wrong`)
        }
    }

    const amountToReceive = new Decimal(transaction.withdrawal_order.amount)
        .minus(transaction.withdrawal_order.fee ?? 0)
        .toDP(2)
        .toString()

    return (
        <>
            <Modal
                content={<p>Your withdrawal request hasn't been processed yet. Are you sure you want to cancel it?</p>}
                dataTestId="modal--withdraw-cancel"
                isOpen={showModal}
                setIsOpen={setShowModal}
                title="Cancel withdrawal"
                // Pretty sure the design system's typings are wrong here - e is always provided
                onFormSubmit={cancelWithdrawal as (e?: React.FormEvent) => unknown}
                primaryButton={{label: 'Cancel'}}
            />
            <Page withoutDefaultPadding>
                <Toolbar dataTestId="toolbar--withdrawal-status" leftButton="back" hideIntercom />
                <div className={recordStyles.viewContainer}>
                    <div data-testid="withdrawal-status-title" className={recordStyles.titleBlock}>
                        <h2>Withdrawal - {transaction.withdrawal_order.display_status}</h2>
                    </div>
                    <div data-testid="withdrawal-data-section">
                        <div className={recordStyles.orderContent}>
                            <div className={recordStyles.orderTitleBlock}>
                                <div>
                                    <h4 className={recordStyles.instrumentName}>Cash</h4>
                                    <p
                                        className={recordStyles.instrumentSymbol}
                                    >{`${transaction.currency.toUpperCase()} cash withdrawal`}</p>
                                </div>
                            </div>

                            <div className={recordStyles.recordRowContainer}>
                                {gatherItems().map((item, i) => (
                                    <RecordRow
                                        key={i}
                                        className={recordStyles.recordRowWithDate}
                                        left={item.description}
                                        right={item.value}
                                    />
                                ))}
                            </div>
                            <p className={recordStyles.orderAmountLabel}>
                                {isEstimate ? 'Estimated to receive' : 'Amount'}
                            </p>
                            <div className={recordStyles.orderFooter}>
                                <div>
                                    <p className={recordStyles.statusLabel} data-testid="orderStatusLabel">
                                        {capitalise(transaction.withdrawal_order.display_status)}
                                    </p>
                                </div>

                                <div>
                                    <p className={recordStyles.orderAmount}>
                                        <DollarValue
                                            value={amountToReceive}
                                            currency={transaction.currency}
                                            roundDown
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {withdrawalCanBeCancelled && (
                        <div className={spacing.spaceBelow12}>
                            <Button
                                label="Cancel"
                                dataTestId="button--withdrawal-cancel"
                                type="secondary"
                                pageButton
                                onClick={() => setShowModal(true)}
                            />
                        </div>
                    )}
                </div>
            </Page>
        </>
    )
}

export default WithdrawalStatus
