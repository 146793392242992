import {Button} from '@design-system/button'
import {withFormik} from 'formik'
import React from 'react'
import {Navigate} from 'react-router-dom'
import {Model} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import InstrumentRow from '~/global/widgets/instrument-row/InstrumentRow'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {
    NotificationContext,
    NotificationContextProps,
} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import commonStyles from '~/sections/invest/sections/auto-invest/Common.scss'
import {useCurrentDIYOrder} from '~/sections/invest/sections/auto-invest/hooks/useAutoinvestOrders'
import actions from '~/store/autoinvest/actions'
import {PartialDIYOrderDetails, isDIY, AutoinvestOrderAllocation} from '~/store/autoinvest/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {State} from '~/store/instrument/types'
import styles from './DIYPercentages.scss'

interface FormProps {
    allocations: PartialDIYOrderDetails['allocations']
    currentOrderAllocations: Model.AutoinvestOrder['allocations']
    hasSeenAICompanyRiskWarning: boolean
    instrumentsById: State['instrumentsById']
    isEdit: boolean
    notificationContext: NotificationContextProps
    setDIYAllocations(allocations: PartialDIYOrderDetails['allocations']): void
    profileUrl: ReturnType<typeof useProfileUrl>
}

interface FormValues {
    [key: string]: string
}

const initialError = (props: FormProps): boolean => {
    // this function now returns false if no initial error - true if error
    if (!props.isEdit || (props.isEdit && props.allocations !== props.currentOrderAllocations)) {
        // percentages will be redistributed, form should be valid
        return false
    }
    for (const fund of props.allocations) {
        if (fund.allocation === '0') {
            return true
        }
    }
    return false
}

const formValuesToAllocations = (formValues: FormValues): AutoinvestOrderAllocation[] => {
    const allocations: AutoinvestOrderAllocation[] = []

    Object.entries(formValues).forEach(([key, value]) => {
        allocations.push({fund_id: key, allocation: value})
    })

    return allocations
}

const clearAllocationPercentages = (formValues: FormValues): FormValues => {
    const newFormValues: FormValues = {}

    Object.keys(formValues).forEach((fundId: string) => {
        newFormValues[fundId] = '0'
    })

    return newFormValues
}

const DIYPercentagesForm = withRouter(
    withFormik<FormProps & WithRouterProps, FormValues>({
        mapPropsToValues: ({allocations: selectedAllocations}) => {
            const formValues: FormValues = {}

            selectedAllocations.forEach(allocation => {
                formValues[allocation.fund_id] = allocation.allocation
            })

            return formValues
        },
        mapPropsToErrors: (props: FormProps) => {
            // make the button disabled initially if there is an initial error by setting at least one field to have an error
            const errorExists: boolean = initialError(props)
            if (errorExists) {
                return {key: undefined}
            }
            return {}
        },
        validate: values => {
            const errors: FormValues = {}

            for (const fund in values) {
                if (!values[fund] || values[fund] === '0') {
                    errors[fund] = 'Enter a percentage'
                }
            }

            return errors
        },
        handleSubmit: async (
            values,
            {
                setSubmitting,
                props: {
                    hasSeenAICompanyRiskWarning,
                    instrumentsById,
                    router: {navigate},
                    setDIYAllocations,
                    profileUrl,
                },
            },
        ) => {
            const allocations: PartialDIYOrderDetails['allocations'] = []
            for (const fund in values) {
                allocations.push({
                    fund_id: fund,
                    allocation: values[fund],
                })
            }
            setDIYAllocations(allocations)
            rudderTrack('autoinvest', 'diy_percentage_set')

            const showWarning =
                !hasSeenAICompanyRiskWarning &&
                Object.keys(values).some(id => instrumentsById[id].instrumentType === 'equity')

            if (showWarning) {
                navigate(profileUrl('auto-invest/diy/order-includes-companies'))
            } else {
                navigate(profileUrl('auto-invest/diy/how-much'))
            }

            setSubmitting(false)
        },
    })(
        ({
            allocations,
            errors,
            handleSubmit,
            instrumentsById,
            isEdit,
            isSubmitting,
            isValid,
            resetForm,
            router: {navigate},
            setDIYAllocations,
            touched,
            values,
            profileUrl,
        }) => {
            let totalAllocation = 0
            for (const fund in values) {
                totalAllocation = totalAllocation + (values[fund] ? parseInt(values[fund], 10) : 0)
            }
            const dispatch = useAppDispatch()

            const backUrl = isEdit ? 'auto-invest/diy' : 'auto-invest/diy/search'

            const removeInstrument = (instrumentId: string) => {
                dispatch(actions.RemoveDIYFund(instrumentId))
                if (Object.keys(values).length === 1) {
                    navigate(profileUrl(backUrl), {replace: true})
                } else {
                    delete values[instrumentId]
                }
            }

            const handleAddMoreInstruments = () => {
                const newAllocations = formValuesToAllocations(values)
                setDIYAllocations(newAllocations)

                navigate(profileUrl('auto-invest/diy/search'), {replace: true})
            }

            const handleClearAllocationPercentages = () => {
                dispatch(actions.ClearStagedOrderAllocationPercentages())

                resetForm({values: clearAllocationPercentages(values)})
            }

            return (
                <form onSubmit={handleSubmit}>
                    <Toolbar
                        dataTestId="toolbar--diy-percentage"
                        leftButton="back"
                        onLeftButtonClick={() => navigate(profileUrl(backUrl), {replace: true})}
                        title={isEdit ? 'Edit your auto-invest order' : 'What percentage in each investment?'}
                    />

                    <Page withoutDefaultPadding>
                        <div className={commonStyles.pageContainer}>
                            {Object.keys(values).map(key => (
                                <div className={styles.percentageWrapper} key={key}>
                                    {/* Note that even though this instrumentRow exists within the context of auto-invest, we don't pass this prop as this causes the component to render search specific features */}
                                    <InstrumentRow
                                        fullCardNotClickable
                                        instrument={instrumentsById[key]}
                                        onClick={() => null}
                                        percentageInputId={instrumentsById[key].id}
                                        errors={touched[key] ? errors[key] : undefined}
                                        hidePrice
                                        footerButton={{
                                            label: 'Remove',
                                            onClick: () => removeInstrument(key),
                                        }}
                                        oldLayout
                                    />
                                </div>
                            ))}
                        </div>
                        <ButtonAsLink
                            noTextDecoration
                            className={styles.addMore}
                            onClick={handleAddMoreInstruments}
                            disabled={allocations.length >= 100}
                        >
                            + Add more investments
                        </ButtonAsLink>
                    </Page>
                    <ActionBar className={commonStyles.footer}>
                        <div>
                            Total: {totalAllocation}%<span>|</span>{' '}
                            <ButtonAsLink
                                noTextDecoration
                                className={commonStyles.tinyButton}
                                onClick={handleClearAllocationPercentages}
                                dataTestId="button--clear"
                            >
                                Clear
                            </ButtonAsLink>
                        </div>
                        <Button
                            additionalClassName={commonStyles.bigButton}
                            processing={isSubmitting}
                            disabled={totalAllocation !== 100 || !isValid}
                            label="Next"
                            dataTestId="button--invest"
                            isSubmit
                        />
                    </ActionBar>
                </form>
            )
        },
    ),
)

const DIYPercentages: React.FunctionComponent = () => {
    const notificationContext = React.useContext(NotificationContext)
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const [currentOrder] = useCurrentDIYOrder()
    const stagedOrder = useAppSelector(s => s.autoinvest.stagedOrder)

    const currentOrderAllocations =
        !currentOrder || (currentOrder && 'premadeOrder' in currentOrder) ? [] : currentOrder.allocations

    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const hasSeenAICompanyRiskWarning = useAppSelector(s => s.identity.user!.has_seen.autoinvest_companies_warning)

    const setDIYAllocations = (allocations: AutoinvestOrderAllocation[]) =>
        dispatch(actions.SetDIYAllocations(allocations))

    const stagedOrderAllocations = (stagedOrder && isDIY(stagedOrder.order) && stagedOrder.order.allocations) || []

    // if stagedOrder is not available, redirect so we can set one up
    if (!stagedOrder || stagedOrder.state === 'completed') {
        return <Navigate to={profileUrl('auto-invest')} replace />
    }

    return (
        <DIYPercentagesForm
            allocations={stagedOrderAllocations}
            currentOrderAllocations={currentOrderAllocations}
            hasSeenAICompanyRiskWarning={hasSeenAICompanyRiskWarning}
            instrumentsById={instrumentsById}
            isEdit={stagedOrder ? stagedOrder.isEdit : false}
            notificationContext={notificationContext}
            setDIYAllocations={setDIYAllocations}
            profileUrl={profileUrl}
        />
    )
}

export default DIYPercentages
