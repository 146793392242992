import React from 'react'
import {Model} from '~/api/retail/types'
import {IdentityVerificationStep} from '~/global/utils/identity-verification/identityVerification'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import AUIdentityForms from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/widgets/au-forms/AUIdentityForms'
import NZIdentityForms from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/widgets/nz-forms/NZIdentityForms'
import {connect} from '~/store/connect'

const ConfirmIdentityDetails: React.FunctionComponent<ConfirmIdentityDetailsProps> = ({
    isDependent,
    preferredName,
    jurisdiction,
    setStep,
}) => {
    const navigate = useNavigate()
    return (
        <>
            <Toolbar
                dataTestId="toolbar--confirm-identity-details"
                title="Identity details"
                leftButton="close"
                onLeftButtonClick={() => navigate('/')}
            />
            <Page>
                {jurisdiction === 'nz' ? (
                    <NZIdentityForms
                        isDependent={isDependent}
                        preferredName={preferredName}
                        isSecondaryId
                        setStep={setStep}
                    />
                ) : (
                    <AUIdentityForms
                        isDependent={isDependent}
                        preferredName={preferredName}
                        isSecondaryId
                        setStep={setStep}
                    />
                )}
            </Page>
        </>
    )
}

interface OwnProps {
    setStep(step: IdentityVerificationStep): void
}

interface StoreProps {
    isDependent: boolean
    preferredName: string
    jurisdiction: Model.User['jurisdiction']
}

type ConfirmIdentityDetailsProps = StoreProps & OwnProps

export default connect<StoreProps, {}, OwnProps>(({identity}) => ({
    isDependent: identity.user!.is_dependent,
    preferredName: identity.user!.preferred_name,
    jurisdiction: identity.user!.jurisdiction,
}))(ConfirmIdentityDetails)
