import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {Checkbox} from '~/global/widgets/form-controls'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'

const TransferSharesTermsAndConditions: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const [acceptTerms, setAcceptTerms] = React.useState(false)

    return (
        <>
            <Toolbar dataTestId="toolbar--transfer-shares-terms-and-conditions" leftButton="back" />
            <Page>
                <h1 className={cn(spacing.spaceBelow24, commonStyles.heading)}>Accept Terms and Conditions</h1>
                <p className={spacing.spaceBelow32}>
                    You must read and agree to these{' '}
                    <a
                        className={commonStyles.topupLink}
                        href={profileUrl('legal/asx-share-transfers-terms')}
                        target="_blank"
                        rel="noopener"
                    >
                        Terms and Conditions
                    </a>{' '}
                    for ASX transfers before you continue.
                </p>
                <div>
                    <Checkbox
                        dataTestId="checkbox--accept-terms"
                        name="accept-terms"
                        isTouched={true}
                        value={acceptTerms}
                        label="I accept these Terms and Conditions"
                        onChange={e => setAcceptTerms((e.currentTarget as HTMLInputElement).checked)}
                    />
                </div>
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--next"
                    onClick={() => navigate(profileUrl('invest/portfolio-transfer-shares/asx/add-investor-details'))}
                    label="Next"
                    disabled={!acceptTerms}
                />
            </ActionBar>
        </>
    )
}

export default TransferSharesTermsAndConditions
