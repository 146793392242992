import {ClassicCard} from '@braze/web-sdk'
import React from 'react'
import {logCardClick} from '~/api/braze/braze'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {isLoadingOrUninitialised} from '~/global/utils/is-loading/isLoading'
import useAutoinvestDividends from '~/global/utils/use-autoinvest-dividends/useAutoinvestDividends'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {ReinvestDividendActionsModal} from '~/global/widgets/instrument-activity/ReinvestDividendSnippet'
import {Link, useNavigate} from '~/migrate-react-router'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import orderActions from '~/store/order/actions'
import {CardExtras} from './NotificationCardLink'
import styles from './NotificationClassicCard.scss'

export interface DividendExtras extends CardExtras {
    dividend_order_id: string
    dividend_amount: string
    instrument_slug: string
    dividend_payout_date: string
}

const DividendLink = ({card}: {card: ClassicCard}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const dividendExtras: DividendExtras = card.extras as DividendExtras
    const dispatch = useAppDispatch()
    const [modalOpen, setModalOpen] = React.useState(false)
    const walletBalances = useAppSelector(s => s.identity.user?.wallet_balances)
    const instrument = useAppSelector(s => s.instrument.instrumentsById[dividendExtras.instrument_id])
    const walletBalance = instrument && walletBalances ? walletBalances[instrument.currency] : 0
    const walletBalanceSufficientToReinvest = Number(walletBalance) > Number(dividendExtras.dividend_amount)

    const canReinvest = dividendExtras?.dividend_amount && parseFloat(dividendExtras?.dividend_amount) >= 0.01

    const canAutoinvestDividend = useAppSelector(s => s.identity.flags.customer_dividend_reinvest)
    const {preferences: reinvestDivideindsPreferences, loadingState: reinvestDividendsLoadingState} =
        useAutoinvestDividends()
    const isReinvestingDividend = reinvestDivideindsPreferences.funds.includes(dividendExtras.instrument_id)

    const onReinvestClick = () => {
        if (canAutoinvestDividend) {
            setModalOpen(true)
            return
        }

        logCardClick(card)
        rudderTrack('notifications', 'notification_link_clicked', {
            link: 'dividend_reinvest',
            instrument_id: instrument.id,
            reinvest_amount: dividendExtras.dividend_amount,
        })
        dispatch(
            orderActions.UpdateStagedBuyOrder({
                fundId: dividendExtras.instrument_id,
                state: 'initialised',
                pushedHistory: false,
                orderType: 'dollar_market',
                needReadPDS: undefined,
                dividendReinvest: 'once',
                dividendReinvestId: card.extras?.dividend_order_id,
                orderCurrencyAmount: dividendExtras.dividend_amount,
            }),
        )
        navigate(profileUrl('invest/:instrumentSlug/buy', {instrumentSlug: dividendExtras.instrument_slug}))
    }

    return (
        <div className={styles.cardLink}>
            <Link
                to={profileUrl('invest/investing-activity/:instrumentId/:orderId', {
                    instrumentId: dividendExtras.instrument_id,
                    orderId: dividendExtras.dividend_order_id,
                })}
                onClick={() => {
                    logCardClick(card)
                    rudderTrack('notifications', 'notification_link_clicked', {
                        link: 'view_dividend',
                        instrument_id: instrument.id,
                        reinvest_amount: '',
                    })
                }}
            >
                Take a look
            </Link>
            {walletBalanceSufficientToReinvest &&
                canReinvest &&
                !isReinvestingDividend &&
                !isLoadingOrUninitialised(reinvestDividendsLoadingState) && (
                    <>
                        {' '}
                        or <ButtonAsLink onClick={onReinvestClick}>invest it</ButtonAsLink>
                        <ReinvestDividendActionsModal
                            instrument={instrument}
                            dividendAmount={dividendExtras.dividend_amount}
                            dividendReinvestId={dividendExtras.dividend_order_id}
                            isOpen={modalOpen}
                            setIsOpen={setModalOpen}
                            orderId={dividendExtras.dividend_order_id}
                        />
                    </>
                )}
        </div>
    )
}

export default DividendLink
