/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface SharesDetail
 */
export interface SharesDetail {
    /**
     *
     * @type {number}
     * @memberof SharesDetail
     */
    shares_bought: number
    /**
     *
     * @type {number}
     * @memberof SharesDetail
     */
    shares_sold: number
    /**
     *
     * @type {number}
     * @memberof SharesDetail
     */
    shares_transferred_in: number
    /**
     *
     * @type {number}
     * @memberof SharesDetail
     */
    shares_transferred_out: number
}

export function SharesDetailFromJSON(json: any): SharesDetail {
    return SharesDetailFromJSONTyped(json, false)
}

export function SharesDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): SharesDetail {
    if (json === undefined || json === null) {
        return json
    }
    return {
        shares_bought: json['shares_bought'],
        shares_sold: json['shares_sold'],
        shares_transferred_in: json['shares_transferred_in'],
        shares_transferred_out: json['shares_transferred_out'],
    }
}

export function SharesDetailToJSON(value?: SharesDetail | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        shares_bought: value.shares_bought,
        shares_sold: value.shares_sold,
        shares_transferred_in: value.shares_transferred_in,
        shares_transferred_out: value.shares_transferred_out,
    }
}
