import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {spacing} from '~/global/scss/helpers'
import {useReadPortfolio} from '~/global/state-hooks/rakaia/useReadPortfolio'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {Period, periods} from '~/global/utils/time-period/timePeriod'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import PortfolioHistoryChart from '~/global/widgets/chart/PortfolioHistoryChart'
import Chips from '~/global/widgets/chips/Chips'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import PortfolioChartBalance from '~/sections/invest/sections/portfolio/sections/history/widgets/portfolio-chart-balance/PortfolioChartBalance'
import PortfolioChartValues from '~/sections/invest/sections/portfolio/sections/history/widgets/portfolio-chart-values/PortfolioChartValues'
import {PortfolioHistoryItem} from '~/store/portfolio/types'
import PortfolioBreakdown from './widgets/breakdown/PortfolioBreakdownV2'
import styles from './PortfolioHistory.scss'

const PortfolioHistory = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const profile = useProfile()

    const investPortfolio = profile.portfolios.find(p => p.product === 'INVEST')!
    const portfolioHistory = useReadPortfolio(investPortfolio.id).portfolio_history

    const [period, setPeriod] = React.useState<Period>('All')
    const [portfolioValues, setPortfolioValues] = React.useState<PortfolioHistoryItem>()

    if (!portfolioHistory) {
        return (
            <>
                <Toolbar dataTestId="toolbar--no-portfolio-history" leftButton="back" />
                <Page>
                    <Loading />
                </Page>
            </>
        )
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--portfolio-history" leftButton="back" />
            <Page overrideDefaultTopPadding="none">
                <div className={styles.portfolioHistoryPage}>
                    <PortfolioChartBalance values={portfolioValues} period="1w" onPeriodChange={setPeriod} />
                    <div className={cn(spacing.spaceAbove8, styles.pills)}>
                        <Chips
                            options={periods}
                            onChipClick={setPeriod}
                            selected={[period]}
                            additionalAriaLabel="Show portfolio history over"
                            isInlineDisplay
                        />
                    </div>
                    <PortfolioChartValues values={portfolioValues} period="1w" onPeriodChange={setPeriod} />
                    <div style={{overflowX: 'hidden'}}>
                        <div className={styles.chartWrapper}>
                            <PortfolioHistoryChart
                                period={period}
                                portfolioHistory={portfolioHistory!}
                                onDayHover={values => setPortfolioValues(values)}
                            />
                        </div>
                        <PortfolioBreakdown
                            onOpenDetail={() => navigate(profileUrl('invest/investment-history/breakdown'))}
                        />
                    </div>
                </div>
            </Page>
        </>
    )
}

export default PortfolioHistory
