import cn from 'classnames'
import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {autoExerciseRemainingCostBreakdown} from '~/global/utils/exercise-cost/exerciseCost'
import {amountFilled, sumTradeShareVolumes} from '~/global/utils/order/order'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {BuyOrder} from '~/store/accounting/types'
import {Instrument} from '~/store/instrument/types'
import styles from './Record.scss'

interface AutoExerciseBuyDetailProps {
    instrument: Instrument
    order: BuyOrder
    jurisdiction: Model.User['jurisdiction']
}

const AutoExerciseBuyDetail: React.FunctionComponent<AutoExerciseBuyDetailProps> = ({
    instrument,
    order,
    jurisdiction,
}) => {
    const {pathname} = useLocation()
    const profileUrl = useProfileUrl()

    if (!instrument.exercisePrice) {
        return null
    }
    const fee = order.expected_fee ? Number(order.expected_fee) : 0
    const hasTrades = order.trades.length > 0
    const orderAmount = order.order_total || order.created_hold_amount

    // cost of exercising purchased rights
    const tradeVolume = sumTradeShareVolumes(order)
    const tradeExerciseCost = tradeVolume * parseFloat(instrument.exercisePrice)

    // estimated cost of exercising rights yet to be purchased
    const estimatedRemainingCostBreakdown = autoExerciseRemainingCostBreakdown(order, instrument, jurisdiction)

    // total cost of exercising (trades + estimated from remaining order amount)
    const totalEstimatedExerciseCost = estimatedRemainingCostBreakdown
        ? tradeExerciseCost + parseFloat(estimatedRemainingCostBreakdown.estimatedCostToExercise)
        : tradeExerciseCost

    // estimated total order amount after exercising (partially filled in cancelling state)
    const buyAmountFilled = amountFilled(order)
    const estimatedTotalOrderAmountAfterExercise = buyAmountFilled + tradeExerciseCost

    // total order amount (amount filled + exercise cost)
    const totalOrderAmountAfterExercise = parseFloat(orderAmount) + tradeExerciseCost

    const shareLabelPlural = shareLabel({instrument, isPlural: true})
    const orderType = order.price_limit ? 'limit' : 'market'

    // determine the url prefix based on where the user is currently
    const orderUrlPrefix = pathname.includes('/wallet') ? 'wallet' : 'invest'
    const totalOrderAmount = (
        <DollarValue
            value={
                order.state === 'cancelling' ? estimatedTotalOrderAmountAfterExercise : totalOrderAmountAfterExercise
            }
            currency={instrument.currency}
        />
    )
    const applicationLink = order.auto_exercise_application_record_id ? (
        <>
            <Link
                data-testid="link--see-exercise-application"
                to={profileUrl(`${orderUrlPrefix}/investing-activity/:instrumentId/:orderId`, {
                    instrumentId: instrument.id,
                    orderId: order.auto_exercise_application_record_id,
                })}
            >
                See your exercise application
            </Link>
            .
        </>
    ) : null

    return (
        <>
            <p className={cn(styles.autoExerciseDetailHeading, spacing.spaceAbove24)}>Auto-exercise</p>
            <div className={styles.autoExerciseDetail}>
                {['pending', 'processing'].includes(order.state) && (
                    <>
                        <p>
                            After your buy order fills, we’ll apply to auto-exercise your {shareLabelPlural} using the
                            remaining (estimated){' '}
                            <DollarValue value={totalEstimatedExerciseCost} currency={instrument.currency} /> of your{' '}
                            <DollarValue value={orderAmount} currency={instrument.currency} /> order amount.
                        </p>
                        {fee !== 0 && (
                            <p className={spacing.spaceAbove24}>
                                The transaction fee is just an estimate until the order completes.
                            </p>
                        )}
                    </>
                )}

                {!hasTrades && (
                    <>
                        {order.state === 'cancelling' && (
                            <p>We’re also cancelling the application to auto-exercise these {shareLabelPlural}.</p>
                        )}
                        {order.state === 'cancelled' && (
                            <p>Your application to auto-exercise these {shareLabelPlural} has also been cancelled.</p>
                        )}
                        {order.state === 'expired' && (
                            <p>
                                Since your {orderType} buy order for {instrument.symbol} {shareLabelPlural} has expired,
                                your application to auto-exercise these {shareLabelPlural} has been cancelled.
                            </p>
                        )}
                    </>
                )}

                {hasTrades && (
                    <>
                        {order.state === 'cancelling' && (
                            <p>
                                You’re cancelling the remaining part of your {orderType} buy order for{' '}
                                {instrument.symbol} {shareLabelPlural}. The estimated total amount of your buy and
                                auto-exercise order is {totalOrderAmount}.
                            </p>
                        )}
                        {order.state === 'cancelled' && (
                            <p>
                                You’ve cancelled the unfilled part of your {orderType} buy order for {instrument.symbol}{' '}
                                {shareLabelPlural}—we’ve applied to exercise the {shareLabelPlural} that were bought.
                                The total amount of your buy and auto-exercise order is now {totalOrderAmount}.{' '}
                                {applicationLink}
                            </p>
                        )}
                        {order.state === 'expired' && (
                            <p>
                                Your {orderType} buy order for {instrument.symbol} {shareLabelPlural} only partially
                                filled, and the remaining part has expired—we’ve applied to exercise the{' '}
                                {shareLabelPlural} that were bought. The total amount of your buy and auto-exercise
                                order is now {totalOrderAmount}. {applicationLink}
                            </p>
                        )}
                    </>
                )}

                {order.state === 'rejected' && (
                    <p>
                        Since your {orderType} buy order for {instrument.symbol} {shareLabelPlural} has been rejected,
                        your application to auto-exercise these {shareLabelPlural} has been cancelled.
                    </p>
                )}

                {order.state === 'fulfilled' && (
                    <p>
                        This is part of an application to auto-exercise the {shareLabel({instrument, isPlural: true})}{' '}
                        you’re buying, with a total order amount of {totalOrderAmount}. {applicationLink}
                    </p>
                )}
            </div>
        </>
    )
}

export default AutoExerciseBuyDetail
