import {Loader} from '@design-system/loader'
import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Toast} from '~/global/widgets/toast/Toast'
import {useNavigate} from '~/migrate-react-router'
import {ERROR_MESSAGE} from '~/sections/OLD_wallet/sections/top-up/sections/top-up-instant/constants/errorMessage'
import actions from '~/store/bankLinking/actions'
import {RequestState} from '~/store/bankLinking/types'
import {useAppDispatch} from '~/store/hooks'

interface ResponseData {
    quickPaymentId?: string
    requestState: RequestState
}

const TopUpInstantBankReturn = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const getResponseData = (): ResponseData => {
        const params = new URLSearchParams(window.location.search)
        const cid = params.get('cid')
        const error = params.get('error')

        let requestState: RequestState = 'OK'

        if (error) {
            Toast(error)

            switch (error) {
                case 'Payment cancelled':
                    requestState = 'CANCELLED'
                    rudderTrack('topup', 'bank_link_cancel_clicked', {url: window.location.href})
                    break

                default:
                    requestState = 'ERROR'
                    break
            }
        } else if (!cid) {
            requestState = 'UNSURE'
        }

        return {requestState, quickPaymentId: cid || undefined}
    }

    async function notifyBlinkPayPayment(): Promise<void> {
        const {requestState, quickPaymentId} = getResponseData()

        if (quickPaymentId) {
            try {
                await dispatch(actions.NotifyBlinkPayResponse(requestState, quickPaymentId))
                // Note Toast occurs in action function
            } catch (error) {
                Toast(ERROR_MESSAGE)
            }
        }

        navigate(profileUrl('wallet'))
    }

    React.useEffect(() => {
        notifyBlinkPayPayment()
    }, [])

    return <Loader />
}

export default TopUpInstantBankReturn
