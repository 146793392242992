import {DateTime} from 'luxon'
import React from 'react'
import {useNavigate} from 'react-router'
import {Link} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {dateFormatFullMonth} from '~/global/utils/format-date/formatDate'
import {formatDollar} from '~/global/utils/format-dollar/formatDollar'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import actions from '~/store/autoinvest/actions'
import {EnhancedAutoinvestOrder, EnhancedAutoinvestOrderPremade} from '~/store/autoinvest/selectors'
import {useAppDispatch} from '~/store/hooks'

interface DiyInstrumentUnavailableProps {
    className?: string
    instrumentNames: string[]
    order: EnhancedAutoinvestOrder
}

export const DiyInstrumentUnavailableAlert: React.FunctionComponent<DiyInstrumentUnavailableProps> = ({
    className,
    instrumentNames,
    order,
}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    return (
        <AlertCard
            type="warning"
            className={className}
            text={`${
                instrumentNames.length === 1
                    ? `${instrumentNames[0]} is`
                    : instrumentNames.length === 2
                      ? `${instrumentNames[0]} and ${instrumentNames[1]} are`
                      : `${instrumentNames[0]} and ${instrumentNames.length - 1} more investments are`
            } no longer available for auto-invest.  Money allocated to ${
                instrumentNames.length > 1 ? 'these investments' : 'this investment'
            } will be refunded to your Wallet and the remainder of your order will continue to auto-invest. You can edit your order to add more investments or reset the percentages.`}
            links={[
                {
                    text: 'Edit your order',
                    callback: async () => {
                        await dispatch(actions.StageExistingOrder(order))
                        navigate(profileUrl('auto-invest/diy/percentage'))
                    },
                },
            ]}
        />
    )
}

export const DiyInstrumentUnavailableVerboseAlert: React.FunctionComponent<DiyInstrumentUnavailableProps> = ({
    className,
    instrumentNames,
    order,
}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    return (
        <AlertCard
            type="warning"
            className={className}
            links={[
                {
                    text: 'Edit your order',
                    callback: async () => {
                        await dispatch(actions.StageExistingOrder(order))
                        navigate(profileUrl('auto-invest/diy/percentage'))
                    },
                },
            ]}
        >
            <div>
                <p>The following investments are no longer available for auto-invest.</p>
                <ul>
                    {instrumentNames.map((name, i) => (
                        <li key={i}>{name}</li>
                    ))}
                </ul>
                <p>{`Money allocated to ${
                    instrumentNames.length > 1 ? 'these investments' : 'this investment'
                } will be refunded to your Wallet and the remainder of your order will continue to auto-invest. You can edit your order to add more investments or reset the percentages.`}</p>
            </div>
        </AlertCard>
    )
}

interface InsufficientFundsProps {
    className?: string
    failedDate: string
    nextDate: string
}

export const InsufficientFundsAlert: React.FunctionComponent<InsufficientFundsProps> = ({
    className,
    failedDate,
    nextDate,
}) => (
    <AlertCard
        text={`There wasn’t enough money for your auto-invest on ${failedDate}, we’ll try again on ${nextDate}.`}
        type="warning"
        className={className}
    />
)

interface FullAlertProps {
    order: EnhancedAutoinvestOrder | EnhancedAutoinvestOrderPremade
}

export const OrderFailedFullAlert: React.FunctionComponent<FullAlertProps> = ({order}) => {
    const profileUrl = useProfileUrl()
    const lastFailedDate = order.last_failed_date ? DateTime.fromISO(order.last_failed_date) : DateTime.now()

    return (
        <AlertCard type="warning" title="Order failed" className={spacing.spaceBelow16}>
            <p>
                There wasn't enough money for your {formatDollar(order.amount, false)} Auto-invest on{' '}
                {lastFailedDate.toFormat(dateFormatFullMonth)}
                .Top up your wallet and we'll try the payment again on the{' '}
                {DateTime.fromISO(order.next_date).toFormat(dateFormatFullMonth)}.
            </p>
            <Link to={profileUrl('wallet')}>Top up your wallet</Link>
        </AlertCard>
    )
}
