import React from 'react'
import {
    AccountRouteRedirect,
    CsnTransferDirectionRedirect,
    CsnTransferRedirect,
    ExploreRecentlyViewedRedirect,
    ExploreSearchRedirect,
    ExploreWatchlistRedirect,
    InvestmentsRouteRedirect,
    PortfolioRouteRedirect,
    SaveRouteRedirect,
    SaveTransferRedirect,
    TransferRouteRedirect,
    USSignUpRedirect,
    WalletTopupRedirect,
} from '~/global/RouteRedirects'
import DebugPage from '~/global/pages/debug-page/DebugPage'
import FloodlightDebugPage from '~/global/pages/floodlight-debug-page/FloodlightDebugPage'
import {ConnectedAUShares as AUShares} from '~/global/pages/info-pages/InfoPages'
import NotFound from '~/global/pages/not-found/NotFound'
import {CheckableRoute} from '~/global/utils/routing/routing'
import {profileHandle} from '~/global/utils/use-profile-url/useProfileUrl'
import AnonymousWrapper from '~/global/wrappers/AnonymousWrapper'
import AuthenticatedWrapper from '~/global/wrappers/AuthenticatedWrapper'
import GlobalWrapper from '~/global/wrappers/GlobalWrapper'
import {GrowthBookConfig} from '~/global/wrappers/GrowthBookConfig'
import {LogPageView} from '~/global/wrappers/LogPageView'
import {NativeControls} from '~/global/wrappers/NativeControls'
import {ProfileWrapper, ProfileRedirect} from '~/global/wrappers/ProfileWrapper'
import {Root, RootWrapper} from '~/global/wrappers/Root'
import {Wallet} from '~/sections/OLD_wallet/Wallet'
import PaymentRequestView from '~/sections/OLD_wallet/pages/payment-request/PaymentRequestView'
import ExchangeMoney from '~/sections/OLD_wallet/sections/exchange-money/ExchangeMoney'
import ExchangeMoneyConfirm from '~/sections/OLD_wallet/sections/exchange-money/pages/exchange-money-confirm/ExchangeMoneyConfirm'
import ExchangeMoneyIntro from '~/sections/OLD_wallet/sections/exchange-money/pages/exchange-money-intro/ExchangeMoneyIntro'
import {LINKED_BANK_PAYMENTS_ROUTES} from '~/sections/OLD_wallet/sections/linked-bank-payments/routes'
import TopupGoalPlaceholder from '~/sections/OLD_wallet/sections/top-up/pages/top-up-goal'
import WalletTopUpWithBankDeposit from '~/sections/OLD_wallet/sections/top-up/pages/top-up-with-bank-deposit/TopUpWithBankDeposit'
import WalletCardTopUp from '~/sections/OLD_wallet/sections/top-up/pages/top-up-with-card/CardTopUp'
import WalletTopUpInstant from '~/sections/OLD_wallet/sections/top-up/sections/top-up-instant/TopUpInstant'
import WalletTopUpInstantReturn from '~/sections/OLD_wallet/sections/top-up/sections/top-up-instant/pages/return-page/ReturnPage'
import WalletTopUpSuggestBanks from '~/sections/OLD_wallet/sections/top-up/sections/top-up-instant/pages/suggest-banks/SuggestBanksForm'
import WalletTransactionLineView from '~/sections/OLD_wallet/widgets/transaction-line/TransactionLineView'
import {ExploreWatchlist, ExplorePage} from '~/sections/explore'
import Learn from '~/sections/explore/pages/learn-static-content'
import LearnContentPage from '~/sections/explore/pages/learn-static-content/LearnContentPage'
import ExploreTopTen from '~/sections/explore/pages/top-ten/ExploreTopTen'
import ExploreTopTenV2 from '~/sections/explore/pages/top-ten/ExploreTopTenV2'
import {ArticlePage} from '~/sections/explore/sections/learn-articles/ArticlePage'
import {ExploreHowSharesiesWorks} from '~/sections/explore/widgets/carousels/ExploreCarousels'
import {
    FonterraExploreRedirect,
    FonterraExploreExternalLink,
} from '~/sections/explore/widgets/helpful-links/HelpfulLinks'
import NatureAndPurposeUpdate from '~/sections/invest/pages/nature-and-purpose-update/NatureAndPurposeUpdate'
import {AiSearch} from '~/sections/invest/sections/ai-search'
import AiSearchIntro from '~/sections/invest/sections/ai-search/pages/intro/AiSearchIntro'
import {AiSearchLanding} from '~/sections/invest/sections/ai-search/pages/landing/Landing'
import AILanding from '~/sections/invest/sections/auto-invest/Landing'
import AIAddOrder from '~/sections/invest/sections/auto-invest/pages/add-order/AddOrder'
import AIHowMuch from '~/sections/invest/sections/auto-invest/pages/how-much/HowMuch'
import {AutoInvestIntroSlides as AIIntroSlides} from '~/sections/invest/sections/auto-invest/pages/intro/IntroSlides'
import AIPDS from '~/sections/invest/sections/auto-invest/pages/pds/PDS'
import AIPremade from '~/sections/invest/sections/auto-invest/pages/premade/Premade'
import FeeReview from '~/sections/invest/sections/auto-invest/pages/review/FeeReview'
import {AutoInvestSplash as AISplash} from '~/sections/invest/sections/auto-invest/pages/splash/Splash'
import AITax from '~/sections/invest/sections/auto-invest/pages/tax-info/Tax'
import AIDIY from '~/sections/invest/sections/auto-invest/sections/diy/DIY'
import CompanyRiskWarning from '~/sections/invest/sections/auto-invest/sections/diy/pages/company-risk-warning/CompanyRiskWarning'
import AIDIYPercentages from '~/sections/invest/sections/auto-invest/sections/diy/pages/percentage/DIYPercentages'
import RoundupsHome from '~/sections/invest/sections/bank-linking/RoundupsHome'
import {BankLinkingEdit} from '~/sections/invest/sections/bank-linking/pages/edit/BankLinkingEdit'
import {BankLinkingView} from '~/sections/invest/sections/bank-linking/pages/view/BankLinkingView'
import {RoundupsSetupStateMachine as BankLinkingAndRoundupsSetup} from '~/sections/invest/sections/bank-linking/sections/roundups-setup/RoundupsSetup'
import DividendReinvestmentConfirmation from '~/sections/invest/sections/dividend-reinvestment-plans/pages/confirmation/DividendReinvestmentConfirmation'
import DividendReinvestmentLearn from '~/sections/invest/sections/dividend-reinvestment-plans/pages/learn/DividendReinvestmentLearn'
import DividendReinvestmentManage from '~/sections/invest/sections/dividend-reinvestment-plans/pages/manage/DividendReinvestmentManage'
import ShareSchemeDetails from '~/sections/invest/sections/employee-share-scheme/ShareSchemeDetails'
import ShareSchemeDetailMenu from '~/sections/invest/sections/employee-share-scheme/pages/ShareSchemeDetailMenu'
import ShareSchemeInfoPDF from '~/sections/invest/sections/employee-share-scheme/pages/ShareSchemeInfoPDF'
import NzxMarketDepth from '~/sections/invest/sections/nzx-market-depth'
import BuyAcceptPDS from '~/sections/invest/sections/order-flow/pages/accept-pds/AcceptPDS'
import ApplyConfirm from '~/sections/invest/sections/order-flow/sections/apply/pages/apply-confirm/ApplyConfirm'
import ApplyNextDetail from '~/sections/invest/sections/order-flow/sections/apply/pages/apply-next/ApplyNextDetail'
import BuyConfirm from '~/sections/invest/sections/order-flow/sections/buy/pages/buy-confirm/BuyConfirm'
import BuyTaxForm from '~/sections/invest/sections/order-flow/sections/buy/pages/buy-tax/BuyTaxForm'
import SellConfirm from '~/sections/invest/sections/order-flow/sections/sell/pages/sell-confirm/SellConfirm'
import Portfolio from '~/sections/invest/sections/portfolio'
import PortfolioCorporateActionsCarousel from '~/sections/invest/sections/portfolio/pages/corporate-actions-carousel/PortfolioCorporateActionsCarousel'
import {PendingInvestingActivityView} from '~/sections/invest/sections/portfolio/pages/pending-activity/PendingInvestingActivityView'
import PortfolioHistory from '~/sections/invest/sections/portfolio/sections/history/PortfolioHistory'
import {BreakdownDetailV2Page} from '~/sections/invest/sections/portfolio/sections/history/pages/breakdown-detail/PortfolioBreakdownV2Detail'
import {Manage as PortfolioManage} from '~/sections/invest/sections/portfolio/sections/manage/Manage'
import InvestReports from '~/sections/invest/sections/portfolio/sections/manage/pages/reports/InvestReports'
import {AutoInvestSearch, InvestSearch, InvestFilter, AutoInvestFilter} from '~/sections/invest/sections/search'
import Survey from '~/sections/invest/sections/survey/Survey'
import {TRANSFER_SHARES_ROUTES} from '~/sections/invest/sections/transfer-shares/routes'
import USSignUp from '~/sections/invest/sections/us-sign-up'
import USWelcome from '~/sections/invest/sections/us-sign-up/pages/welcome/USWelcome'
import {
    DIYViewAdd,
    DIYView,
    InvestPortfolioViewApply,
    InvestPortfolioViewBuy,
    InvestPortfolioViewPick,
    InvestPortfolioViewSell,
    InvestPortfolioViewView,
    InvestPortfolioSearchView,
    RecentlyViewedPick,
    RecentlyViewedBuy,
    RecentlyViewedSell,
    RecentlyViewedApply,
    WatchlistPick,
    WatchlistBuy,
    WatchlistSell,
    WatchlistApply,
    PremadeView,
    RecentlyViewedView,
    WatchlistView,
} from '~/sections/invest/sections/view-instrument'
import RecentOrders from '~/sections/invest/sections/view-instrument/pages/recent-orders/RecentOrders'
import ShareSchemeAllocation from '~/sections/invest/sections/view-instrument/sections/employee-share-scheme/pages/share-scheme-allocation/ShareSchemeAllocation'
import UpcomingAllocations from '~/sections/invest/sections/view-instrument/sections/employee-share-scheme/pages/upcoming-allocations/UpcomingAllocations'
import {KIWISAVER_ROUTES} from '~/sections/kiwisaver/routes'
import {Landing} from '~/sections/landing/Landing'
import {FONTERRA_ROUTES} from '~/sections/moose/routes'
import PersonalSignUp from '~/sections/moose/sections/fonterra-personal-sign-up/FonterraPersonalSignUp'
import MooseSignUp from '~/sections/moose/sections/sign-up/SignUp'
import {PROTECT_ROUTES} from '~/sections/protect/routes'
import {
    DownloadDocuments,
    Manage as SaveManage,
    OnboardingIntro,
    OnboardingTax,
    OnboardingConfirmation,
    PersonaliseAccount as SavePersonaliseAccount,
    Save,
    SaveWaitlist,
    TransferConfirm,
    TransactionLineView as SaveTransactionLineView,
} from '~/sections/save'
import RecurringTransfersView from '~/sections/save/sections/manage/pages/recurring-transfers/RecurringTransfersView'
import {SaveOnboardingTransfer, SaveRecurringTransfer, TransferMoney} from '~/sections/save/sections/transfer'
import {AccountAddressUploaded} from '~/sections/user/pages/address-uploaded/AddressUploaded'
import ForgotPassword from '~/sections/user/pages/forgot-password/ForgotPassword'
import ForgotPasswordMessage from '~/sections/user/pages/forgot-password-message/ForgotPasswordMessage'
import ForgotPasswordReset from '~/sections/user/pages/forgot-password-reset/ForgotPasswordReset'
import Login from '~/sections/user/pages/login/Login'
import MoneyGoal from '~/sections/user/pages/money-goal/MoneyGoal'
import {MoneyGoalIntro} from '~/sections/user/pages/money-goal/MoneyGoalIntro'
import {MoneyGoalResult} from '~/sections/user/pages/money-goal/MoneyGoalResult'
import {MoneyGoalSetup} from '~/sections/user/pages/money-goal/MoneyGoalSetup'
import {PersonaliseAutoinvest} from '~/sections/user/pages/personalise-product/index'
import AccountPersonalDetails from '~/sections/user/sections/account/sections/personal-details/AccountPersonalDetails'
import IdentityVerificationFlow from '~/sections/user/sections/identity-verification'
import Notifications from '~/sections/user/sections/notifications/Notifications'
import Settings from '~/sections/user/sections/settings/Settings'
import AddressVerification, {
    EditAddressVerification,
} from '~/sections/user/sections/settings/pages/address-verification/AddressVerification'
import VerificationEmailSent from '~/sections/user/sections/settings/pages/address-verification-email-sent/VerificationEmailSent'
import ChangePassword from '~/sections/user/sections/settings/pages/change-password/ChangePassword'
import ContactDetails from '~/sections/user/sections/settings/pages/contact-details/ContactDetails'
import ContactFonterra from '~/sections/user/sections/settings/pages/contact-fonterra/ContactFonterra'
import {CreateKidsAccount} from '~/sections/user/sections/settings/pages/create-kids-account/CreateKidsAccount'
import ConfirmReinvest from '~/sections/user/sections/settings/pages/dividends/ConfirmReinvest'
import Dividends from '~/sections/user/sections/settings/pages/dividends/Dividends'
import VerifyEmail from '~/sections/user/sections/settings/pages/email-verification/VerifyEmail'
import IntercomMessage from '~/sections/user/sections/settings/pages/intercom-message/IntercomMessage'
import AccountTransferAge from '~/sections/user/sections/settings/pages/kids-transfer-age/TransferAge'
import LegalInformation from '~/sections/user/sections/settings/pages/legal-information/LegalInformation'
import AccountMFA from '~/sections/user/sections/settings/pages/mfa/AccountMFA'
import NatureAndPurpose from '~/sections/user/sections/settings/pages/nature-and-purpose/NatureAndPurpose'
import {PersonalisedAnnualStatements} from '~/sections/user/sections/settings/pages/personalised-annual-statements/PersonalisedAnnualStatements'
import PrescribedPerson from '~/sections/user/sections/settings/pages/prescribed-person/PrescribedPerson'
import RequestFeature from '~/sections/user/sections/settings/pages/request-feature/RequestFeature'
import AccountSecurity from '~/sections/user/sections/settings/pages/security/AccountSecurity'
import AccountBankAccountsAndCards from '~/sections/user/sections/settings/sections/bank-accounts-and-cards/BankAccountsAndCards'
import EditBankAccount from '~/sections/user/sections/settings/sections/bank-accounts-and-cards/pages/add-bank-account/EditBankAccount'
import AddCard from '~/sections/user/sections/settings/sections/bank-accounts-and-cards/pages/add-card/AddCard'
import BusinessDetails from '~/sections/user/sections/settings/sections/business-details/BusinessDetails'
import CloseAccount from '~/sections/user/sections/settings/sections/close-account/CloseAccount'
import CloseAccountCancel from '~/sections/user/sections/settings/sections/close-account/pages/close-account-cancel/CloseAccountCancel'
import CloseAccountDependentSuccess from '~/sections/user/sections/settings/sections/close-account/pages/close-account-success/CloseAccountDependentSuccess'
import CloseAccountSuccess from '~/sections/user/sections/settings/sections/close-account/pages/close-account-success/CloseAccountSuccess'
import {AUDownloadDocuments} from '~/sections/user/sections/settings/sections/download-documents/DownloadDocuments'
import NotificationSettings from '~/sections/user/sections/settings/sections/notifications/Notifications'
import PriceNotifications from '~/sections/user/sections/settings/sections/notifications/pages/price-notifications/PriceNotifications'
import ShareholderUpdates from '~/sections/user/sections/settings/sections/notifications/pages/shareholder-updates/ShareholderUpdates'
import PersonalDetails from '~/sections/user/sections/settings/sections/personal-details/PersonalDetails'
import CitizenshipDetails from '~/sections/user/sections/settings/sections/personal-details/pages/citizenship/CitizenshipDetails'
import EditAddress from '~/sections/user/sections/settings/sections/personal-details/pages/edit-address/EditAddress'
import PlanBillingCycle from '~/sections/user/sections/settings/sections/plans/pages/billing-cycle/PlanBillingCycle'
import PlanBillingCycleConfirm from '~/sections/user/sections/settings/sections/plans/pages/billing-cycle/PlanBillingCycleConfirm'
import PlanConfirm from '~/sections/user/sections/settings/sections/plans/pages/confirm/PlanConfirm'
import PlanPaymentMethod from '~/sections/user/sections/settings/sections/plans/pages/payment-method/PlanPaymentMethod'
import PlanPaymentMethodConfirm from '~/sections/user/sections/settings/sections/plans/pages/payment-method/PlanPaymentMethodConfirm'
import PlanSelect from '~/sections/user/sections/settings/sections/plans/pages/select/PlanSelect'
import PlanSuccess from '~/sections/user/sections/settings/sections/plans/pages/success/PlanSuccess'
import PlanView from '~/sections/user/sections/settings/sections/plans/pages/view/PlanView'
import PortfolioSettings from '~/sections/user/sections/settings/sections/portfolio/Portfolio'
import {PortfolioReturnCalculation} from '~/sections/user/sections/settings/sections/portfolio/pages/return-calculations/PortfolioReturnCalculation'
import {Sharesight} from '~/sections/user/sections/settings/sections/portfolio/pages/sharesight/Sharesight'
import {PortfolioUsExtendedHours} from '~/sections/user/sections/settings/sections/portfolio/pages/us-extended-hours/PortfolioUsExtendedHours'
import TaxDetails from '~/sections/user/sections/settings/sections/tax-details/TaxDetails'
import AccountHolderType from '~/sections/user/sections/settings/sections/tax-details/pages/account-holder-type/AccountHolderType'
import EditTaxDetails from '~/sections/user/sections/settings/sections/tax-details/pages/edit-tax-details/EditTaxDetails'
import TaxDeclaration, {
    ForeignTaxResidentAccountTaxDeclaration,
} from '~/sections/user/sections/settings/sections/tax-details/pages/tax-declaration/TaxDeclaration'
import ShareTheLove from '~/sections/user/sections/share-the-love/ShareTheLove'
import Referrals from '~/sections/user/sections/share-the-love/pages/referrals/Referrals'
import Gifts from '~/sections/user/sections/share-the-love/sections/gifts/Gifts'
import GiftPurchase from '~/sections/user/sections/share-the-love/sections/gifts/pages/purchase-gift/GiftPurchase'
import GiftPay from '~/sections/user/sections/share-the-love/sections/gifts/pages/purchase-gift-pay/GiftPay'
import GiftPurchaseSuccess from '~/sections/user/sections/share-the-love/sections/gifts/pages/purchase-gift-success/GiftPurchaseSuccess'
import RedeemGift from '~/sections/user/sections/share-the-love/sections/gifts/pages/redeem-gift/RedeemGift'
import SignUp from '~/sections/user/sections/sign-up/SignUp'
import CreateDependent from '~/sections/user/sections/sign-up/pages/about-your-kid/CreateDependent'
import DepositPrompt from '~/sections/user/sections/sign-up/sections/post-activation/pages/deposit-prompt/DepositPrompt'
import LegalAiSearchTerms from '~/sections/user/sections/terms-and-conditions/pages/legal/LegalAiSearchTerms'
import LegalAsxTransfersTerms from '~/sections/user/sections/terms-and-conditions/pages/legal/LegalAsxTransfersTerms'
import LegalBankLinkingTerms from '~/sections/user/sections/terms-and-conditions/pages/legal/LegalBankLinkingTerms'
import LegalBestPricePolicy from '~/sections/user/sections/terms-and-conditions/pages/legal/LegalBestPricePolicy'
import LegalCollectionNotice from '~/sections/user/sections/terms-and-conditions/pages/legal/LegalCollectionNotice'
import LegalCommunityCodeOfConduct from '~/sections/user/sections/terms-and-conditions/pages/legal/LegalCommunityCodeOfConduct'
import LegalFarmerTradingServiceTermsAndConditions from '~/sections/user/sections/terms-and-conditions/pages/legal/LegalFarmerTermsAndConditions'
import LegalGeneralTermsAndConditions from '~/sections/user/sections/terms-and-conditions/pages/legal/LegalGeneralTermsAndConditions'
import LegalGiftingTerms from '~/sections/user/sections/terms-and-conditions/pages/legal/LegalGiftingTerms'
import LegalInvestTermsAndConditions from '~/sections/user/sections/terms-and-conditions/pages/legal/LegalInvestTermsAndConditions'
import LegalKidsTerms from '~/sections/user/sections/terms-and-conditions/pages/legal/LegalKidsTerms'
import LegalNzxTransfersTerms from '~/sections/user/sections/terms-and-conditions/pages/legal/LegalNzxTransfersTerms'
import LegalPrivacyPolicy from '~/sections/user/sections/terms-and-conditions/pages/legal/LegalPrivacyPolicy'
import LegalPrivacyStatement from '~/sections/user/sections/terms-and-conditions/pages/legal/LegalPrivacyStatement'
import LegalReferralTerms from '~/sections/user/sections/terms-and-conditions/pages/legal/LegalReferralTerms'
import LegalSaveTerms from '~/sections/user/sections/terms-and-conditions/pages/legal/LegalSaveTerms'
import LegalSharesiesCoveTerms from '~/sections/user/sections/terms-and-conditions/pages/legal/LegalSharesiesCoveTerms'
import LegalUSTransfersTerms from '~/sections/user/sections/terms-and-conditions/pages/legal/LegalUSTransfersTerms'
import MonoovaDDRSA from '~/sections/user/sections/terms-and-conditions/pages/legal/MonoovaDDRSA'
import {WALLET_ROUTES} from '~/sections/wallet/routes'
import WalletTopUpWithPayId from '~/sections/wallet/sections/top-up/pages/top-up-with-pay-id/TopUpWithPayId'
import WebsiteWrapper from '~/sections/website/Website'
import CreateGift from '~/sections/website/pages/create-gift/CreateGift'

export const PROFILE_ROUTE = {
    path: 'profile/:profileSlug',
    Component: ProfileWrapper,
    handle: profileHandle,
    children: [
        {path: '__debug__', Component: DebugPage},

        // SETTINGS
        {path: 'settings', Component: Settings},

        // SETTINGS - TOP LEVEL ALERTS
        {path: 'settings/address-verification', Component: AddressVerification},
        {path: 'settings/email-verification', Component: VerificationEmailSent},

        // SETTINGS - PORTFOLIO
        {path: 'settings/personal-details', Component: PersonalDetails},
        {path: 'settings/personal-details/prescribed-person', Component: PrescribedPerson},
        {path: 'settings/personal-details/how-you-invest', Component: NatureAndPurpose},
        {
            path: 'settings/personal-details/citizenship-details',
            Component: CitizenshipDetails,
        },
        {path: 'settings/personal-details/edit-address', Component: EditAddress},
        {
            path: 'settings/personal-details/edit-address/address-verification',
            Component: EditAddressVerification,
        },
        {
            path: 'settings/personal-details/edit-address/address-uploaded',
            Component: AccountAddressUploaded,
        },

        {path: 'settings/bank-accounts-cards', Component: AccountBankAccountsAndCards},
        {
            path: 'settings/bank-accounts-cards/edit-bank-account/:bank_account_id',
            Component: EditBankAccount,
        },
        {path: 'settings/bank-accounts-cards/bank-linking/view', Component: BankLinkingView},
        {path: 'settings/bank-accounts-cards/bank-linking/edit', Component: BankLinkingEdit},
        {path: 'settings/bank-accounts-cards/add-bank-account', Component: EditBankAccount},
        {path: 'settings/bank-accounts-cards/add-card', Component: AddCard},

        {path: 'settings/business-details', Component: BusinessDetails},
        {path: 'settings/business-details/how-you-invest', Component: NatureAndPurpose},
        {path: 'settings/business-details/prescribed-person', Component: PrescribedPerson},

        {path: 'settings/download-documents', Component: AUDownloadDocuments},
        {path: 'settings/download-documents/personalised-annual-statements', Component: PersonalisedAnnualStatements},

        {path: 'settings/tax-details', Component: TaxDetails},
        {path: 'settings/tax-details/tax-declaration', Component: TaxDeclaration},
        {path: 'settings/tax-details/edit', Component: EditTaxDetails},
        {
            path: 'settings/tax-details/tax-declaration/foreign-tax-resident',
            Component: ForeignTaxResidentAccountTaxDeclaration,
        },
        {path: 'settings/tax-details/account-holder-type', Component: AccountHolderType},

        {path: 'settings/plan', Component: PlanView},
        {path: 'settings/plan/select', Component: PlanSelect},
        {path: 'settings/plan/billing-frequency', Component: PlanBillingCycle},
        {path: 'settings/plan/billing-frequency/:plan', Component: PlanBillingCycle},
        {path: 'settings/plan/success', Component: PlanSuccess},
        {
            path: 'settings/plan/payment-method',
            Component: PlanPaymentMethod,
        },
        {
            path: 'settings/plan/payment-method/:plan/:billingCycle',
            Component: PlanPaymentMethod,
        },
        {
            path: 'settings/plan/billing-frequency/confirm/:billingCycle',
            Component: PlanBillingCycleConfirm,
        },
        {
            path: 'settings/plan/payment-method/confirm/:paymentMethod',
            Component: PlanPaymentMethodConfirm,
        },
        {
            path: 'settings/plan/confirm/:plan',
            Component: PlanConfirm,
        },
        {
            path: 'settings/plan/confirm/:plan/:billingCycle/:paymentMethod',
            Component: PlanConfirm,
        },

        {path: 'settings/transfer-age', Component: AccountTransferAge},

        // SETTINGS - ACCOUNT
        {path: 'settings/contact-details', Component: ContactDetails},

        {path: 'settings/notifications', Component: NotificationSettings},
        {path: 'settings/notifications/price', Component: PriceNotifications},
        {path: 'settings/notifications/shareholder-updates', Component: ShareholderUpdates},

        {path: 'settings/change-password', Component: ChangePassword},

        {path: 'settings/security', Component: AccountSecurity},
        {path: 'settings/security/mfa', Component: AccountMFA},

        // SETTINGS - SHARE THE AROHA
        {path: 'referrals', Component: Referrals},
        {path: 'share-the-love', Component: ShareTheLove},
        {path: 'gifts', Component: Gifts},
        {path: 'gifts/purchase', Component: GiftPurchase},
        {path: 'gifts/pay', Component: GiftPay},
        {path: 'gifts/purchase-success', Component: GiftPurchaseSuccess},
        {path: 'gifts/redeem-gift', Component: RedeemGift},
        {path: 'settings/create-kids-account', Component: CreateKidsAccount},

        // SETTINGS - SUPPORT
        {path: 'settings/send-us-a-message', Component: IntercomMessage},

        {path: 'settings/request-feature', Component: RequestFeature},

        {path: 'invest/learn', Component: Learn},
        {path: 'invest/learn/:slug', Component: LearnContentPage},
        {path: 'learn/articles/top-ten', Component: ExploreTopTen},

        {path: 'settings/close-account', Component: CloseAccount},
        {path: 'settings/close-account/cancel', Component: CloseAccountCancel},
        {path: 'close-account/dependent-success', Component: CloseAccountDependentSuccess},
        {
            path: 'close-account/dependent-success/:preferred_name',
            Component: CloseAccountDependentSuccess,
        },

        {path: 'settings/fonterra-contact-details', Component: ContactFonterra},

        {path: 'legal', Component: LegalInformation},
        {path: 'legal/general-terms-and-conditions', Component: LegalGeneralTermsAndConditions},
        {
            path: 'legal/farmer-trading-service-terms-and-conditions',
            Component: LegalFarmerTradingServiceTermsAndConditions,
        },
        {path: 'legal/invest-terms-and-conditions', Component: LegalInvestTermsAndConditions},
        {path: 'legal/gifting-terms', Component: LegalGiftingTerms},
        {path: 'legal/referral-terms', Component: LegalReferralTerms},
        {path: 'legal/kids-terms', Component: LegalKidsTerms},
        {path: 'legal/best-price', Component: LegalBestPricePolicy},
        {path: 'legal/community-code-of-conduct', Component: LegalCommunityCodeOfConduct},
        {path: 'legal/privacy-policy', Component: LegalPrivacyPolicy},
        {path: 'legal/privacy-statement', Component: LegalPrivacyStatement},
        {path: 'legal/sharesies-cove-promotional-terms', Component: LegalSharesiesCoveTerms},
        {path: 'legal/save-terms', Component: LegalSaveTerms},
        {path: 'legal/us-share-transfers-terms', Component: LegalUSTransfersTerms},
        {path: 'legal/asx-share-transfers-terms', Component: LegalAsxTransfersTerms},
        {path: 'legal/nzx-share-transfers-terms', Component: LegalNzxTransfersTerms},
        {path: 'legal/ai-search-terms', Component: LegalAiSearchTerms},
        {path: 'legal/ai-scout-terms', Component: LegalAiSearchTerms},
        {path: 'legal/monoova-ddrsa', Component: MonoovaDDRSA},
        {path: 'legal/bank-linking-terms-and-conditions', Component: LegalBankLinkingTerms},
        {path: 'legal/collection-notice', Component: LegalCollectionNotice},

        // NATIVE BACKCOMPAT: These routes are only used by old version of the native apps
        // These fours are all removable once minimum native version is >= 24.0
        {path: 'learn/articles/us-sign-up', element: <USSignUpRedirect />},
        {path: 'learn/articles/:slug', Component: ArticlePage},
        {path: 'settings/dividends', Component: Dividends},
        {
            path: 'settings/dividends/confirm-reinvestment/:instrumentId/:orderId',
            Component: ConfirmReinvest,
        },
        // Removable once minimum native version is >= 25.0
        {path: 'account/personal-details', Component: AccountPersonalDetails},

        // INVEST SETTINGS
        {path: 'settings/portfolio', Component: PortfolioSettings},
        {
            path: 'settings/portfolio-return-calculation',
            Component: PortfolioReturnCalculation,
        },
        {path: 'settings/portfolio-us-extended-hours', Component: PortfolioUsExtendedHours},
        {path: 'settings/portfolio-sharesight', Component: Sharesight},
        {path: 'settings/reports', Component: InvestReports}, // TODO nothing links to this any more? check with mobile before removing

        // MISC TOP LEVEL PAGES
        {path: 'notifications', Component: Notifications},
        {path: 'nature-and-purpose-update', Component: NatureAndPurposeUpdate},
        {path: 'verify-your-identity', Component: IdentityVerificationFlow},

        // SIGN UP
        {path: 'sign-up/dependent', Component: CreateDependent},
        {path: 'sign-up/deposit', Component: DepositPrompt},

        // US SIGN UP
        {path: 'us-sign-up', Component: USSignUp},
        {path: 'us-sign-up/intro', Component: USWelcome},

        // BUY/SELL/APPLY/CORPORATE ACTION
        {path: 'survey/:surveySlug', Component: Survey},
        {path: 'buy/tax-info', Component: BuyTaxForm},
        {path: 'buy/accept-pds', Component: BuyAcceptPDS},
        {path: 'buy/confirm', Component: BuyConfirm},
        {path: 'sell/confirm', Component: SellConfirm},
        {path: 'apply/confirm', Component: ApplyConfirm},

        // PATH REDIRECTS
        {path: 'portfolio/*', element: <PortfolioRouteRedirect />},
        {path: 'save/transfer', Component: SaveTransferRedirect},
        {path: 'account/*', element: <AccountRouteRedirect />},
        {path: 'settings/transfer-shares/*', element: <TransferRouteRedirect />},
        {path: 'investments/*', element: <InvestmentsRouteRedirect />},
        {path: 'invest/watchlist/*', element: <ExploreWatchlistRedirect />},
        {path: 'invest/recently-viewed/*', element: <ExploreRecentlyViewedRedirect />},

        // EXPLORE
        {path: 'explore', Component: ExplorePage},
        {path: 'explore/learn', Component: Learn},
        {path: 'explore/sign-up', Component: PersonalSignUp},
        {path: 'explore/learn/:slug', Component: LearnContentPage},
        {path: 'explore/learn/top-ten', Component: ExploreTopTen},
        {path: 'explore/learn/top-ten/:slug', Component: ExploreTopTenV2},

        // We had explore/search as well as invest/search, which was a bit confusing. We've decided to defer to /invest/search
        // Redirects in case a customer ends up on any of these paths
        {path: 'explore/search/filter', Component: ExploreSearchRedirect},
        {path: 'explore/search/:instrumentSlug', Component: ExploreSearchRedirect},
        {path: 'explore/search/:instrumentSlug/pick', Component: ExploreSearchRedirect},
        {path: 'explore/search/:instrumentSlug/buy', Component: ExploreSearchRedirect},
        {path: 'explore/search/:instrumentSlug/sell', Component: ExploreSearchRedirect},
        {
            path: 'explore/search/:instrumentSlug/apply/:applicationRuleId',
            Component: ExploreSearchRedirect,
        },
        {path: 'explore/watchlist', Component: ExploreWatchlist},
        {path: 'explore/watchlist/:instrumentSlug', Component: WatchlistView},
        {path: 'explore/watchlist/:instrumentSlug/pick', Component: WatchlistPick},
        {path: 'explore/watchlist/:instrumentSlug/buy', Component: WatchlistBuy},
        {path: 'explore/watchlist/:instrumentSlug/sell', Component: WatchlistSell},
        {
            path: 'explore/watchlist/:instrumentSlug/apply/:applicationRuleId',
            Component: WatchlistApply,
        },
        {path: 'explore/top-ten', Component: ExploreTopTen},
        {path: 'explore/recently-viewed/:instrumentSlug', Component: RecentlyViewedView},
        {path: 'explore/recently-viewed/:instrumentSlug/pick', Component: RecentlyViewedPick},
        {path: 'explore/recently-viewed/:instrumentSlug/buy', Component: RecentlyViewedBuy},
        {path: 'explore/recently-viewed/:instrumentSlug/sell', Component: RecentlyViewedSell},
        {
            path: 'explore/recently-viewed/:instrumentSlug/apply/:applicationRuleId',
            Component: RecentlyViewedApply,
        },

        // A/B TEST CONTENT
        {path: 'explore/how-sharesies-works', Component: ExploreHowSharesiesWorks},

        //  AI SEARCH
        {path: 'invest/ai-search', Component: AiSearchLanding},
        {path: 'invest/ai-search/search', Component: AiSearch},
        {path: 'invest/ai-search/intro', Component: AiSearchIntro},

        // MOOSE PROJECT
        {path: 'fonterra', children: FONTERRA_ROUTES},

        // INVEST
        {path: 'invest-in-australian-shares', Component: AUShares},
        {path: 'invest', Component: Portfolio},
        {path: 'invest/:instrumentSlug', Component: InvestPortfolioViewView},
        {path: 'invest/:instrumentSlug/pick', Component: InvestPortfolioViewPick},
        {path: 'invest/:instrumentSlug/buy', Component: InvestPortfolioViewBuy},
        {path: 'invest/:instrumentSlug/sell', Component: InvestPortfolioViewSell},
        {
            path: 'invest/:instrumentSlug/apply/:applicationRuleId',
            Component: InvestPortfolioViewApply,
        },
        {path: 'invest/:instrumentSlug/apply/:applicationRuleId/next', Component: ApplyNextDetail},
        {path: 'invest/corporate-actions', Component: PortfolioCorporateActionsCarousel},
        {path: 'invest/dividends', Component: Dividends},
        {
            path: 'invest/dividends/confirm-reinvestment/:instrumentId/:orderId',
            Component: ConfirmReinvest,
        },
        {
            path: 'invest/dividend-reinvestment-confirm/:instrumentSlug',
            Component: DividendReinvestmentConfirmation,
        },
        {path: 'invest/dividend-reinvestment-learn', Component: DividendReinvestmentLearn},
        {
            path: 'invest/dividend-reinvestment-learn/:instrumentSlug',
            Component: DividendReinvestmentLearn,
        },
        {
            path: 'invest/dividend-reinvestment-manage/:instrumentSlug',
            Component: DividendReinvestmentManage,
        },
        {path: 'invest/investing-activity/:instrumentId/:orderId', Component: RecentOrders},
        {path: 'invest/investment-history', Component: PortfolioHistory},
        {path: 'invest/investment-history/breakdown', Component: BreakdownDetailV2Page},
        {path: 'invest/investment-settings', Component: PortfolioReturnCalculation},
        {path: 'invest/live-market-data/:instrumentSlug', Component: NzxMarketDepth},
        {path: 'portfolio/:portfolioId/invest/live-market-data/:instrumentSlug', Component: NzxMarketDepth},
        {path: 'invest/manage', Component: PortfolioManage},
        {path: 'invest/pending-investing-activity', Component: PendingInvestingActivityView},

        /** As of Feb 2024, this route is the entrypoint for Explore in the mobile apps */
        {path: 'invest/search', Component: InvestSearch},
        {path: 'invest/search/filter', Component: InvestFilter},
        {path: 'invest/search/:instrumentSlug', Component: InvestPortfolioSearchView},
        {path: 'invest/search/:instrumentSlug/pick', Component: InvestPortfolioViewPick},
        {path: 'invest/search/:instrumentSlug/buy', Component: InvestPortfolioViewBuy},
        {path: 'invest/search/:instrumentSlug/sell', Component: InvestPortfolioViewSell},
        {
            path: 'invest/search/:instrumentSlug/apply/:applicationRuleId',
            Component: InvestPortfolioViewApply,
        },

        // MANAGE LINKS
        {path: 'invest/portfolio-return-calculation', Component: PortfolioReturnCalculation},
        {path: 'invest/portfolio-us-extended-hours', Component: PortfolioUsExtendedHours},
        {path: 'invest/portfolio-sharesight', Component: Sharesight},
        {path: 'invest/reports', Component: InvestReports},
        {path: 'invest/notifications/price', Component: PriceNotifications},

        // PROTECT
        {path: 'protect', children: PROTECT_ROUTES},

        // TRANSFER SHARES
        {path: 'invest/transfer-shares', Component: CsnTransferRedirect},
        {path: 'invest/transfer-shares/transfer-direction', Component: CsnTransferDirectionRedirect},
        {path: 'invest/portfolio-transfer-shares', children: TRANSFER_SHARES_ROUTES},

        // RECENT ORDERS
        {path: 'recent-orders/:instrumentId', Component: RecentOrders},

        // AUTO-INVEST
        {path: 'auto-invest', Component: AILanding},
        {path: 'auto-invest/:premadeSlug', Component: AIPremade},
        {path: 'auto-invest/:premadeSlug/:instrumentSlug', Component: PremadeView},
        {path: 'auto-invest/:premadeSlug/how-much', Component: AIHowMuch},
        {path: 'auto-invest/:premadeSlug/tax-info', Component: AITax},
        {path: 'auto-invest/diy', Component: AIDIY},
        {path: 'auto-invest/diy/:instrumentSlug/view', Component: DIYView},
        {path: 'auto-invest/diy/filter', Component: AutoInvestFilter},
        {path: 'auto-invest/diy/how-much', Component: AIHowMuch},
        {path: 'auto-invest/diy/order-includes-companies', Component: CompanyRiskWarning},
        {path: 'auto-invest/diy/percentage', Component: AIDIYPercentages},
        {path: 'auto-invest/diy/personalise-order', Component: PersonaliseAutoinvest},
        {path: 'auto-invest/diy/search', Component: AutoInvestSearch},
        {path: 'auto-invest/diy/search/:instrumentSlug', Component: DIYViewAdd},
        {path: 'auto-invest/diy/tax-info', Component: AITax},
        {path: 'auto-invest/intro', Component: AIIntroSlides},
        {path: 'auto-invest/splash', Component: AISplash},
        {path: 'auto-invest/pds', Component: AIPDS},
        {path: 'auto-invest/review', Component: FeeReview},
        {path: 'auto-invest/add-order', Component: AIAddOrder},

        // EMPLOYEE SHARE SCHEME
        {
            path: 'employee-share-scheme/upcoming-allocations/:instrumentId',
            Component: UpcomingAllocations,
        },
        {
            path: 'employee-share-scheme/allocation/:instrumentId/:allocationLineId',
            Component: ShareSchemeAllocation,
        },
        {path: 'employee-share-scheme/:instrumentId/schemes', Component: ShareSchemeDetailMenu},
        {path: 'employee-share-scheme/:instrumentId/:shareSchemeId', Component: ShareSchemeDetails},
        {
            path: 'employee-share-scheme/:instrumentId/:shareSchemeId/pdf',
            Component: ShareSchemeInfoPDF,
        },

        // KIWISAVER
        {path: 'kiwisaver', children: KIWISAVER_ROUTES},

        // RECURRING AND ONE-OFF TOP-UPS USING BANK LINKING
        {
            path: 'wallet/linked-bank-topup',
            children: LINKED_BANK_PAYMENTS_ROUTES,
        },
        {
            path: 'explore/linked-bank-topup',
            children: LINKED_BANK_PAYMENTS_ROUTES,
        },

        // ROUNDUPS
        {path: 'invest/roundups', Component: BankLinkingAndRoundupsSetup},
        {path: 'explore/roundups', Component: BankLinkingAndRoundupsSetup},
        {path: 'explore/roundups/manage', Component: () => <RoundupsHome homeLocation="explore" />},
        {
            path: 'explore/roundups/manage/:step',
            Component: (params: Record<string, string>) => <RoundupsHome homeLocation="explore" {...params} />,
        },
        {path: 'explore/roundups/:step', Component: BankLinkingAndRoundupsSetup},
        {path: 'wallet/roundups', Component: BankLinkingAndRoundupsSetup},
        {
            path: 'wallet/roundups/manage',
            Component: () => <RoundupsHome homeLocation="wallet/roundups" />,
        },
        {
            path: 'wallet/roundups/manage/:step',
            Component: (params: Record<string, string>) => <RoundupsHome homeLocation="wallet/roundups" {...params} />,
        },
        {path: 'wallet/roundups/:step', Component: BankLinkingAndRoundupsSetup},
        {path: 'settings/bank-accounts-cards/roundups', Component: BankLinkingAndRoundupsSetup},
        {
            path: 'settings/bank-accounts-cards/roundups/:step',
            Component: BankLinkingAndRoundupsSetup,
        },

        // SAVE
        {path: 'save', Component: SaveRouteRedirect},
        {path: 'save/:portfolioId', Component: Save},
        {path: 'save/kickstart', Component: SaveOnboardingTransfer},
        {path: 'save/confirm-account', Component: OnboardingConfirmation},
        {path: 'save/confirm-transfer/:portfolioId', Component: TransferConfirm},
        {path: 'save/download-reports/:portfolioId', Component: DownloadDocuments},
        {path: 'save/intro', Component: OnboardingIntro},
        {path: 'save/manage/:portfolioId', Component: SaveManage},
        {path: 'save/personalise-account', Component: SavePersonaliseAccount},
        {path: 'save/personalise-account/:portfolioId', Component: SavePersonaliseAccount},
        {path: 'save/recurring-transfers/:portfolioId', Component: RecurringTransfersView},
        {path: 'save/recurring-transfer/:portfolioId', Component: SaveRecurringTransfer},
        {
            path: 'save/recurring-transfer/:portfolioId/:recurringTransferId',
            Component: SaveRecurringTransfer,
        },
        {path: 'save/tax', Component: OnboardingTax},
        {
            path: 'save/transaction/:portfolioId/:transactionId/:transactionKey',
            Component: SaveTransactionLineView,
        },
        {path: 'save/transfer/:portfolioId', Component: SaveTransferRedirect},
        {path: 'save/waitlist', Component: SaveWaitlist},

        // WALLET
        {path: 'wallet', Component: Wallet},
        {path: 'wallet/bank-transfer', Component: WalletTopUpWithBankDeposit},
        {path: 'wallet/pay-id', Component: WalletTopUpWithPayId},
        {path: 'wallet/instant-transfer', Component: WalletTopUpInstant},
        {path: 'wallet/instant-transfer/suggest-banks', Component: WalletTopUpSuggestBanks},
        {path: 'wallet/instant-transfer/return', Component: WalletTopUpInstantReturn},
        {path: 'wallet/card-top-up', Component: WalletCardTopUp},
        {path: 'wallet/confirm-transfer/:saveAccountId', Component: TransferConfirm},
        {path: 'wallet/exchange-money', Component: ExchangeMoney},
        {path: 'wallet/exchange-money/intro', Component: ExchangeMoneyIntro},
        {path: 'wallet/exchange-money/confirm', Component: ExchangeMoneyConfirm},
        {path: 'wallet/investing-activity/:instrumentId/:orderId', Component: RecentOrders},
        {path: 'wallet/topup', Component: WalletTopupRedirect},
        {path: 'wallet/topup-goal', Component: TopupGoalPlaceholder},
        {path: 'wallet/topup-goal/how-much', Component: TopupGoalPlaceholder},
        {path: 'wallet/topup-goal/:depositId', Component: TopupGoalPlaceholder},
        {path: 'wallet/transfer', Component: TransferMoney},
        {path: 'wallet/transaction/:transactionId', Component: WalletTransactionLineView},
        {
            path: 'wallet/transaction/:transactionId/:lineNumber',
            Component: WalletTransactionLineView,
        },
        {path: 'wallet/payment-request/:paymentRequestId', Component: PaymentRequestView},
        {path: 'wallet/withdrawal/:transactionId', Component: WalletTransactionLineView},
        {
            path: 'wallet/withdrawal/:transactionId/:lineNumber',
            Component: WalletTransactionLineView,
        },
        {path: 'wallet/money-goal', Component: MoneyGoal},
        {path: 'wallet/money-goal/setup', Component: MoneyGoalSetup},
        {path: 'wallet/money-goal/confirm/:investGoal/:frequency', Component: MoneyGoalResult},
        {path: 'wallet/money-goal-intro', Component: MoneyGoalIntro},
        {path: 'wallet/confirm-transfer', Component: TransferConfirm},
        // NOTE: the below is the new shared money wallet routes, it shouldn't go before the old routes
        {path: 'wallet', children: WALLET_ROUTES},

        {path: '', Component: Landing},
    ],
} as const
PROFILE_ROUTE satisfies CheckableRoute

export const ROUTES = [
    {
        element: (
            <>
                <LogPageView />
                <GlobalWrapper />
                <NativeControls />
                <GrowthBookConfig />
            </>
        ),
        children: [
            {
                element: <AuthenticatedWrapper />,
                children: [
                    PROFILE_ROUTE,

                    // If the user's authenticated but no paths matched, add the profile prefix
                    {path: '*', Component: ProfileRedirect},
                ],
            },
            {path: '__floodlight_debug__', Component: FloodlightDebugPage},
            {path: 'forgot-password', Component: ForgotPassword},
            {path: 'forgot-password/message', Component: ForgotPasswordMessage},
            {path: 'forgot-password/reset', Component: ForgotPasswordReset},
            {path: 'email-verification', Component: VerifyEmail},
            {path: 'close-account/success/:jurisdiction', Component: CloseAccountSuccess},
            {path: 'sign-up', Component: SignUp},
            {path: 'update', Component: SignUp},
            {path: 'fonterra-signup/:signupId', Component: MooseSignUp},
            {element: <AnonymousWrapper />, children: [{path: 'login', Component: Login}]},
            {element: <RootWrapper />, children: [{path: '', Component: Root}]},
            // FONTERRA SPECIFIC EXPLORE CONTENT
            // These are used by the native mobile clients to redirect to
            // Fonterra supplied urls without needing to hard-code
            // links to external domains.
            // They do not require authentication.
            {
                path: 'link/fonterra/results-and-reporting',
                element: <FonterraExploreRedirect link={FonterraExploreExternalLink.ResultsAndReporting} />,
            },
            {
                path: 'link/fonterra/farmer-support-number',
                element: <FonterraExploreRedirect link={FonterraExploreExternalLink.FarmerSupportNumber} />,
            },
            {
                path: 'link/fonterra/price-and-earnings',
                element: <FonterraExploreRedirect link={FonterraExploreExternalLink.MilkPriceAndEarnings} />,
            },
        ],
    },
    {
        element: <WebsiteWrapper />,
        children: [
            {path: 'create-gift', Component: CreateGift},
            {path: 'create-gift-au', Component: CreateGift},
        ],
    },
    {path: '*', Component: NotFound},
] as const

ROUTES satisfies readonly CheckableRoute[]
