import {Colour, colours} from '@design-system/colour-tokens'
import React from 'react'

interface IconProps extends React.SVGProps<SVGSVGElement> {
    colour?: Colour
}

export const iconWrapper = (Icon: any) =>
    React.memo(({colour, ...otherProps}: IconProps) => {
        const props: React.SVGProps<SVGSVGElement> = {
            ...otherProps,
        }

        if (colour) {
            props.fill = colours[colour]
            props.color = colours[colour]
        }

        return <Icon {...props} />
    })
