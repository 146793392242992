import {Period} from '~/global/utils/time-period/timePeriod'
import {PortfolioHistoryItem} from '~/store/portfolio/types'

export interface KiwiSaverChartProps {
    historyItem?: PortfolioHistoryItem
    period: Period
    onPeriodChange: (period: Period) => void
}

/**
 * Determines how many decimal places to show depending on how many figures are in the input
 *
 * @returns {number} decimal places to show
 */
export function getDecimalPlacesToShowOnChart(input: number) {
    return input > 1e6 ? 0 : 2
}
