import {colour} from '@design-system/colour-tokens'
import {ColourMode} from '@design-system/use-colour-mode'
import diyThumbnail from '~/sections/invest/sections/auto-invest/assets/images/diy-thumbnail.png'
import diy from '~/sections/invest/sections/auto-invest/assets/images/diy.png'
import globalThumbnail from '~/sections/invest/sections/auto-invest/assets/images/global-thumbnail.png'
import global from '~/sections/invest/sections/auto-invest/assets/images/global.png'
import kidsThumbnail from '~/sections/invest/sections/auto-invest/assets/images/kids-thumbnail.png'
import kids from '~/sections/invest/sections/auto-invest/assets/images/kids.png'
import responsibleThumbnail from '~/sections/invest/sections/auto-invest/assets/images/responsible-thumbnail.png'
import responsible from '~/sections/invest/sections/auto-invest/assets/images/responsible.png'
interface AutoInvestImage {
    fullImage: string
    thumbnailImage: string
    backgroundColour: string
    altText?: string
}

export const getImageForAutoInvestOrder = (orderName: string, colourMode: ColourMode): AutoInvestImage => {
    switch (orderName.toLocaleLowerCase()) {
        case 'global':
            return {
                fullImage: global,
                thumbnailImage: globalThumbnail,
                altText: 'A paper mache globe',
                backgroundColour: colour('Pool100', colourMode),
            }
        case 'responsible':
            return {
                fullImage: responsible,
                thumbnailImage: responsibleThumbnail,
                backgroundColour: colour('Mint100', colourMode),
            }
        case 'kids':
            return {
                fullImage: kids,
                thumbnailImage: kidsThumbnail,
                altText: "Painted wooden kids' toys balanced into a tower",
                backgroundColour: colour('Melon100', colourMode),
            }
        default:
            return {
                fullImage: diy,
                thumbnailImage: diyThumbnail,
                backgroundColour: colour('Strawberry100', colourMode),
            }
    }
}
