import cn from 'classnames'
import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {selectInstrumentOrders} from '~/store/accounting/selectors'
import {BuyOrder, CorporateActionV2Order} from '~/store/accounting/types'
import {useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import styles from './Record.scss'

interface AutoExerciseApplicationDetailProps {
    instrument: Instrument
    order: CorporateActionV2Order
    applicationStatus: string
}

const AutoExerciseApplicationDetail: React.FunctionComponent<AutoExerciseApplicationDetailProps> = ({
    instrument,
    order,
    applicationStatus,
}) => {
    const {pathname} = useLocation()
    const profileUrl = useProfileUrl()

    // get the order amount of the associated buy order
    const buyOrder = useAppSelector(s =>
        selectInstrumentOrders(s, instrument.id).find(
            o => o.type === 'buy' && o.id === order.application!.portfolio_order_id,
        ),
    ) as BuyOrder
    const buyOrderAmount = buyOrder.order_total || buyOrder.created_hold_amount

    if (!instrument.exercisePrice) {
        return null
    }

    // determine the url prefix based on where the user is currently
    const orderUrlPrefix = pathname.includes('/wallet') ? 'wallet' : 'invest'

    const buyOrderLink = (
        <Link
            data-testid="link--see-buy-order"
            to={profileUrl(`${orderUrlPrefix}/investing-activity/:instrumentId/:orderId`, {
                instrumentId: instrument.id,
                orderId: order.application!.portfolio_order_id!,
            })}
        >
            See your buy order
        </Link>
    )

    return (
        <>
            <p className={cn(styles.autoExerciseDetailHeading, spacing.spaceAbove24)}>Auto-exercise</p>
            <div className={styles.autoExerciseDetail}>
                <p>
                    This {applicationStatus === 'pending' ? 'is' : 'was'} part of an application to auto-exercise the{' '}
                    {shareLabel({instrument, isPlural: true})}{' '}
                    {applicationStatus === 'pending' ? 'you bought' : 'you were buying'}, with a total order amount of{' '}
                    <DollarValue value={buyOrderAmount} currency={instrument.currency} />.
                </p>
                <p>{buyOrderLink}.</p>
            </div>
        </>
    )
}

export default AutoExerciseApplicationDetail
