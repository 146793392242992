import {Modal} from '@design-system/modal'
import React from 'react'
import {Navigate, useNavigate} from 'react-router'
import {useRetailPost} from '~/api/query/retail'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Toast} from '~/global/widgets/toast/Toast'
import selfSelectTiles from '~/sections/kiwisaver/sections/sign-up/assets/images/self-select-tiles.png'
import {PrePopulate} from '~/sections/kiwisaver/sections/sign-up/widgets/marketing-pre-populate/PrePopulate'
import {KSCustomer, useKSCustomerOrNull} from '~/sections/kiwisaver/state'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/identity/actions'
import {actingAsID as actingAsIDSelector} from '~/store/identity/selectors'

const hasCustomerSubmittedGoalsAndValues: (customer: KSCustomer) => boolean = customer => {
    return Boolean(customer.goals_and_values_selected)
}

const hasCustomerSelectedBaseFund: (customer: KSCustomer) => boolean = customer => {
    return Boolean(customer.draft_investment_plan) || Boolean(customer.investment_plan)
}

const hasCustomerSetInvestmentPlan: (customer: KSCustomer) => boolean = customer => {
    return Boolean(customer.investment_plan)
}

/**
 * If we're in sign-up we only care if the PDS is accepted or not, not which version -
 * there is a a check in the PDS screen itself that looks at specific versions, for use
 * in the edit investment plan flow.
 *
 * We also don't need to check `customer.ios_revision_id` as currently the PDS and IOS are
 * a combined doc - this may change in future.
 */
const hasCustomerAcceptedPDS: (customer: KSCustomer) => boolean = customer => {
    return Boolean(customer.pds_revision_id)
}

const hasCustomerCompletedTaxForm: (customer: KSCustomer) => boolean = customer => {
    // Tax info can already be completed before the customer has even entered the KS Sign Up flow
    return hasCustomerSetInvestmentPlan(customer) && Boolean(customer.ird_number) && Boolean(customer.pir)
}

/**
 * IMPORTANT: the numeric order of these values IS USED in checks so make sure if you're adding
 * a new value you put it in the right order.
 */
export enum SignUpPage {
    CANNOT_JOIN,
    INTRO,
    GOALS_AND_VALUES,
    EDIT_INVESTMENT_PLAN_BASE,
    EDIT_INVESTMENT_PLAN_ACCEPT_PDS,
    EDIT_INVESTMENT_PLAN_HOME,
    EDIT_INVESTMENT_PLAN_SELF_SELECT,
    GET_TAX,
    CONFIRM,
}

const signUpPageRoutes = {
    [SignUpPage.CANNOT_JOIN]: 'kiwisaver/sign-up/cannot-join',
    [SignUpPage.INTRO]: 'kiwisaver/sign-up',
    [SignUpPage.GOALS_AND_VALUES]: 'kiwisaver/sign-up/goals-and-values',
    [SignUpPage.EDIT_INVESTMENT_PLAN_BASE]: 'kiwisaver/sign-up/edit-investment-plan/base',
    [SignUpPage.EDIT_INVESTMENT_PLAN_ACCEPT_PDS]: 'kiwisaver/sign-up/edit-investment-plan/pds',
    [SignUpPage.EDIT_INVESTMENT_PLAN_HOME]: 'kiwisaver/sign-up/edit-investment-plan',
    [SignUpPage.EDIT_INVESTMENT_PLAN_SELF_SELECT]: 'kiwisaver/sign-up/edit-investment-plan/add-funds',
    [SignUpPage.GET_TAX]: 'kiwisaver/sign-up/tax',
    [SignUpPage.CONFIRM]: 'kiwisaver/sign-up/confirm',
} as const

/**
 * Used to move someone forwards in the flow depending on what info we already have filled in.
 */
export const getPageToContinueSignUp = (customer: KSCustomer): SignUpPage => {
    let pageToContinueOn = SignUpPage.GOALS_AND_VALUES

    if (hasCustomerSubmittedGoalsAndValues(customer)) {
        pageToContinueOn = SignUpPage.EDIT_INVESTMENT_PLAN_BASE
    }

    if (hasCustomerAcceptedPDS(customer)) {
        pageToContinueOn = SignUpPage.EDIT_INVESTMENT_PLAN_HOME
    }

    if (hasCustomerSelectedBaseFund(customer)) {
        pageToContinueOn = SignUpPage.EDIT_INVESTMENT_PLAN_HOME
    }

    if (hasCustomerCompletedTaxForm(customer)) {
        pageToContinueOn = SignUpPage.CONFIRM
    }

    return pageToContinueOn
}

/**
 * Used to determine if we need to enforce moving someone back in the flow because they are missing info or
 * are trying to look at something they shouldn't.
 * Be careful to avoid writing checks that prevent someone using back navigation in the flow.
 */
export const getPageToRedirect = (
    customer: KSCustomer | null,
    canJoinKiwiSaver: boolean,
    currentPage: SignUpPage,
): SignUpPage | undefined => {
    let redirectedSignUpPage: SignUpPage | undefined

    if (!canJoinKiwiSaver) {
        return SignUpPage.CANNOT_JOIN
    }

    // If customer is beyond Goals & Values and hasn't selected any, creating their KSCustomer, push them back to the start
    if (currentPage >= SignUpPage.GOALS_AND_VALUES && !customer) {
        return SignUpPage.INTRO
    }

    // If customer hasn't submitted goals and values, push them to it
    if (currentPage > SignUpPage.GOALS_AND_VALUES && !hasCustomerSubmittedGoalsAndValues(customer!)) {
        return SignUpPage.GOALS_AND_VALUES
    }

    // If customer is at PDS or beyond, and hasn't selected a draft investment plan/base fund, push them back to base select
    if (currentPage >= SignUpPage.EDIT_INVESTMENT_PLAN_ACCEPT_PDS && !hasCustomerSelectedBaseFund(customer!)) {
        return SignUpPage.EDIT_INVESTMENT_PLAN_BASE
    }

    // If customer is at investment plan home or beyond, and hasn't yet accepted the PDS, push them back
    if (currentPage >= SignUpPage.EDIT_INVESTMENT_PLAN_HOME && !hasCustomerAcceptedPDS(customer!)) {
        return SignUpPage.EDIT_INVESTMENT_PLAN_ACCEPT_PDS
    }

    // Ensure tax completed
    if (currentPage > SignUpPage.GET_TAX && !hasCustomerCompletedTaxForm(customer!)) {
        return SignUpPage.GET_TAX
    }

    // Tax completed already, move next
    if (currentPage === SignUpPage.GET_TAX && hasCustomerCompletedTaxForm(customer!)) {
        return SignUpPage.CONFIRM
    }

    return redirectedSignUpPage
}

export const SignUpGuardRedirect: React.FunctionComponent<{
    currentPage: SignUpPage
}> = ({currentPage, children}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const dispatch = useAppDispatch()
    const customer = useKSCustomerOrNull()
    const actingAsID = useAppSelector(actingAsIDSelector)
    const canJoinKiwiSaver = useAppSelector(s => s.identity.canJoinKiwiSaver)

    const setStartOverSignup = useRetailPost({
        path: 'kiwisaver/start-over-sign-up',
        queryCacheToUpdate: ['kiwisaver/customer'], // deliberately overwrite the cache with an empty object
    })
    const [showContinueSignupModal, setShowContinueSignupModal] = React.useState(false)
    const [continueSignupModalRedirect, setContinueSignupModalRedirect] = React.useState('')
    const [showSignUpBlockedModal, setShowSignUpBlockedModal] = React.useState(false)

    const validSignUpStates = [undefined, 'SIGNUP', 'SIGNUP_RESTART']

    React.useEffect(() => {
        // If customer has returned to the flow, but has already made it through some steps, pop up modal
        if (currentPage === SignUpPage.INTRO && customer && !setStartOverSignup.isLoading) {
            setContinueSignupModalRedirect(profileUrl(signUpPageRoutes[getPageToContinueSignUp(customer)]))
            setShowContinueSignupModal(true)
        }
    }, [customer, currentPage])

    // If the customer is outside of Sign Up, don't apply any redirects
    if (!validSignUpStates.includes(customer?.customer_state)) {
        return <>{children}</>
    }

    const pageToRedirect = getPageToRedirect(customer, canJoinKiwiSaver, currentPage)

    if (pageToRedirect) {
        return <Navigate to={profileUrl(signUpPageRoutes[pageToRedirect])} replace />
    }

    return (
        <>
            <Modal
                content={<p>You can continue from where you left off, or start over.</p>}
                dataTestId="modal--pick-up-from-last-time"
                isOpen={showContinueSignupModal}
                setIsOpen={setShowContinueSignupModal}
                title="Pick up from last time?"
                noClose
                secondaryButton={{
                    label: 'Start over',
                    onClick: async () => {
                        try {
                            await setStartOverSignup.mutateAsync({
                                acting_as_id: actingAsID,
                            })
                            // Important to check the current redux state of kiwisaver_customer_state
                            // to get the routing correct
                            // Example: if we don't update the redux store for the `kiwisaver_customer_state`
                            // `null || 'SIGNUP'`
                            // the routing pulls in unrelevant components that cause an error.
                            dispatch(actions.Check())
                            setShowContinueSignupModal(false)
                        } catch (e) {
                            Toast('Please try again')
                        }
                    },
                }}
                primaryButton={{
                    label: 'Continue',
                    onClick: () => {
                        navigate(continueSignupModalRedirect)
                    },
                }}
            />
            {/* TEMP for SUPER-1347 - remove the below when we unblock sign up */}
            <Modal
                content={
                    <>
                        <div
                            style={{
                                width: '100%',
                                height: '180px',
                                backgroundColor: '#D1E9D8',
                                borderRadius: '12px',
                                overflow: 'hidden',
                                position: 'relative',
                                backgroundImage: `url(${selfSelectTiles})`, // TODO delete this image
                                backgroundPosition: '-80px -45px',
                                backgroundSize: 'auto 300px',
                                backgroundRepeat: 'no-repeat',
                            }}
                        />
                        <p>
                            We’ve put a <strong>temporary pause</strong> on sign ups to the Sharesies KiwiSaver Scheme
                            while we nail down the final functions of self-select.
                        </p>
                        <p>Check back soon to sign up for a KiwiSaver Scheme that’s more you!</p>
                    </>
                }
                dataTestId="modal--sign-up-blocked"
                isOpen={showSignUpBlockedModal}
                setIsOpen={setShowSignUpBlockedModal}
                noClose
                bottomBorder
                title="We’re pausing sign ups to get self-select sorted"
                secondaryButton={{
                    label: 'Go back',
                    onClick: () => {
                        navigate(profileUrl('explore'))
                    },
                }}
                primaryButton={{
                    label: 'Notify me when sign up opens',
                    onClick: () => {
                        navigate(profileUrl('explore'))
                    },
                }}
            />
            <PrePopulate currentPage={currentPage} />
            {children}
        </>
    )
}
