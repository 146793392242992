import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {reEnterPasswordMessage} from '~/global/utils/error-text/errorText'
import {formatFullName, FullNameType} from '~/global/utils/format-full-name/formatFullName'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ErrorBox} from '~/global/widgets/form-controls'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {AddRegistryDetailsForm} from '~/sections/invest/sections/transfer-shares/sections/asx-transfer-shares/widgets/add-registry-details-form/AddRegistryDetailsForm'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import transferActions from '~/store/transfer/actions'
import {NEW_SRN_REQUIRED} from '~/store/transfer/types'

export const AddInvestorDetails: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const stagedTransferOrder = useAppSelector(({transfer}) => transfer.stagedTransferOrder)

    const srnTransferNoExistingReference =
        stagedTransferOrder?.referenceType === 'SRN' &&
        stagedTransferOrder?.reference &&
        stagedTransferOrder?.reference === NEW_SRN_REQUIRED

    const [detailsEntered, setDetailsEntered] = React.useState(!!stagedTransferOrder?.reference)
    const [fullName, setFullName] = React.useState<FullNameType | null>(null)
    const [authenticationError, setAuthenticationError] = React.useState<Error | null>(null)

    React.useEffect(() => {
        if (!stagedTransferOrder) {
            return navigate(profileUrl('invest/transfer-shares'), {replace: true})
        }
        authenticateAndFetchFullName()
    }, [])

    // Fetch the customer's full name. As this is PII, this is kept outside the redux store
    const authenticateAndFetchFullName = async () => {
        if (fullName?.firstName) {
            return
        }
        const response = await dispatch(identityActions.GetFullName())
        if (response.type === 'full_name') {
            setFullName({
                firstName: response.first_name,
                middleName: response.middle_name,
                lastName: response.last_name,
            })
        } else if (response === reEnterPasswordMessage) {
            setAuthenticationError(new Error(reEnterPasswordMessage))
        }
    }

    const onBackClick = () => {
        setDetailsEntered(false)
        dispatch(transferActions.ClearTransferState())
        navigate(profileUrl('invest/portfolio-transfer-shares/transfer-direction'))
    }

    if (fullName instanceof Error) {
        return <WeSlippedUp />
    }

    if (!detailsEntered && !srnTransferNoExistingReference) {
        // Screen 1 - Create / Update their reference number
        return (
            <>
                <Toolbar dataTestId="toolbar--add-registry-details" leftButton="back" />
                <AddRegistryDetailsForm nextPage={() => setDetailsEntered(true)} />
            </>
        )
    }

    if (!fullName) {
        return <Loading isPineapple />
    }

    // Screen 2 - Confirm the name matches the form
    return (
        <>
            <Toolbar
                dataTestId="toolbar--add-registry-details"
                leftButton="back"
                onLeftButtonClick={() => onBackClick()}
            />
            <Page>
                <h1 className={cn(commonStyles.heading, spacing.spaceBelow24)}>The name on your holding statement</h1>

                <p className={spacing.spaceBelow16}>
                    To complete a transfer, the name we have for you must match exactly what’s on your holding
                    statement. This is sometimes referred to as a CHESS statement.
                </p>
                <h3 className={typographyOverrides['as-h3']}>The name we have on file for you is:</h3>
                <p className={spacing.spaceBelow16}>{formatFullName(fullName)}</p>
                <p className={spacing.spaceBelow16}>Is it a perfect match? Proceed!</p>
                <p>
                    If it doesn’t match your holding statement (including titles, middle names, hyphens etc.), update
                    your name with{' '}
                    {stagedTransferOrder?.referenceType === 'HIN' ? (
                        'your broker.'
                    ) : (
                        <>
                            <HelpCentreLink
                                auArticle="6950844-shareholder-reference-number-srn-and-holder-identification-number-hin"
                                nzArticle="6950819-shareholder-reference-number-srn-and-holder-identification-number-hin"
                            >
                                your share registry
                            </HelpCentreLink>{' '}
                            to reflect the above.
                        </>
                    )}
                </p>

                <ErrorBox message={authenticationError} />
            </Page>
            <ActionBar>
                <Button
                    label="This name matches my statement"
                    dataTestId="button--name-match"
                    onClick={() => navigate(profileUrl('invest/portfolio-transfer-shares/asx/add-investor-address'))}
                />
            </ActionBar>
        </>
    )
}

export default AddInvestorDetails
