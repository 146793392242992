import cn from 'classnames'
import React from 'react'
import styles from './RiskScale.scss'

interface RiskProps {
    rating: number
    withLabels?: boolean
}

export const RiskScale = React.memo(({rating, withLabels}: RiskProps) => {
    const numbers: number[] = [1, 2, 3, 4, 5, 6, 7]

    return (
        <>
            {withLabels && (
                <div className={styles.scaleLabels} aria-hidden="true">
                    <span>Lower risk</span>
                    <span>Higher risk</span>
                </div>
            )}
            <div className={styles.riskScale} aria-hidden="true">
                {numbers.map(number => (
                    <span
                        key={`risk-scale-${number}`}
                        className={cn(styles.ordinal, {[styles.rating]: number === rating})}
                    >
                        {number}
                    </span>
                ))}
            </div>
            {withLabels && (
                <div className={styles.scaleLabels} aria-hidden="true">
                    <span>Potentially lower returns</span>
                    <span>Potentially higher returns</span>
                </div>
            )}

            <div className={styles.screenReaderCaption}>{rating} out of 7.</div>
        </>
    )
})

interface RiskScaleRangesProps {
    ratingRange: [number, number]
}

/**
 * RiskScaleRanges is very similar to RiskScale (style and structure-wise) with some key differences in styling and logic.
 * The key difference is that RiskScale will highlight a 'single' ordinal value in the range, whereas RiskScaleRange will highlight a range on the scale (i.e. highlighted between two ordinal bounds), with colour differing on the risk range.
 */
export const RiskScaleRanges = React.memo(({ratingRange}: RiskScaleRangesProps) => {
    const numbers: number[] = [1, 2, 3, 4, 5, 6, 7]
    const lowerBound = ratingRange[0]
    const upperBound = ratingRange[1]

    // determine the colour of the scale range based on the risk rating range provided as input.
    let colourStyle = styles.highRisk
    if (upperBound < 3) {
        colourStyle = styles.lowRisk
    } else if (upperBound < 5) {
        colourStyle = styles.mediumRisk
    }

    return (
        <>
            <div className={styles.riskScaleRange}>
                <div className={styles.riskScale} aria-hidden="true">
                    {numbers.slice(0, numbers.length - 1).map(number => {
                        return (
                            <span
                                key={number}
                                className={cn(
                                    styles.ordinal,
                                    styles.rangeScaleOrdinal,
                                    colourStyle,
                                    // highlight the risk scale for this ordinal range, and style the border as pink as accordining to the risk type
                                    {
                                        [styles.range]: number >= lowerBound && number < upperBound,
                                    },
                                    {
                                        [styles.finalCell]:
                                            number === numbers[numbers.length - 2] &&
                                            number >= lowerBound &&
                                            number < upperBound,
                                    },
                                    // we have to round the linen border at the start and end of the risk range, which requires applying a pseudo-element to get the correct elliptical shape for the border bottom.
                                    {
                                        [styles.rangeScaleRoundedBorder]:
                                            (!(number >= lowerBound && number < upperBound) &&
                                                number === numbers[numbers.length - 2]) ||
                                            number === numbers[0],
                                    },
                                    {
                                        [styles.start]:
                                            !(number >= lowerBound && number < upperBound) && number === numbers[0],
                                    },
                                    {
                                        [styles.end]:
                                            !(number >= lowerBound && number < upperBound) &&
                                            numbers[numbers.length - 2],
                                    },
                                )}
                            >
                                {upperBound - lowerBound !== 1 && number >= lowerBound && number < upperBound - 1 && (
                                    <span className={cn(styles.borderFilling, colourStyle)} />
                                )}
                                {number}
                            </span>
                        )
                    })}
                </div>
            </div>
            <div className={styles.scaleLabels} aria-hidden="true">
                <span>Lower risk</span>
                <span>Higher risk</span>
            </div>
            <div className={styles.screenReaderCaption}>
                Between {lowerBound} to {upperBound}, out of 7.
            </div>
        </>
    )
})

interface InstrumentRiskDescriptionProps {
    rating: number
    learnMore?: React.ReactNode
}

export const InstrumentRiskDescription = React.memo(({rating, learnMore}: InstrumentRiskDescriptionProps) => {
    const ratingToRiskString = (rating: number): string => {
        if (rating < 3) {
            return 'Lower risk and potentially lower return'
        }

        if (rating > 4) {
            return 'Higher risk and potentially higher return'
        }

        return 'Medium risk and potentially medium return'
    }
    const riskString: string = ratingToRiskString(rating)

    return (
        <div className={styles.riskDescription}>
            {riskString}. {learnMore}
        </div>
    )
})
