import React from 'react'
import {Model} from '~/api/retail/types'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import EditTaxResidenciesForm from '~/global/widgets/edit-tax-residencies-form/EditTaxResidenciesForm'
import {CountriesType} from '~/global/widgets/form-controls/country-select/CountrySelectInput'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useLocation, useNavigate} from '~/migrate-react-router'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'

export const AccountTaxDeclaration: React.FunctionComponent<OwnProps & StoreProps & DispatchProps> = props => {
    const navigate = useNavigate()
    const {state} = useLocation()
    const profileUrl = useProfileUrl()

    return (
        <>
            <Toolbar dataTestId="toolbar--tax-declaration" leftButton="back" title="Tax details" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <EditTaxResidenciesForm
                    {...props}
                    successAction={() => {
                        navigate(state?.successRoute || profileUrl('settings/tax-details'))
                    }}
                />
            </Page>
        </>
    )
}

interface OwnProps {
    foreignTaxResident?: boolean
}

interface StoreProps {
    initialFTRYes?: boolean

    taxResidencies: Model.User['tax_residencies']
    isDependent: boolean
    preferredName: string
    jurisdiction: Model.User['jurisdiction']
}

interface DispatchProps {
    taxDeclareYes(countries: CountriesType): Promise<string | void | null>
    taxDeclareNo(): Promise<string | void | null>
}

const ConnectedAccountTaxDeclaration = connect<StoreProps, DispatchProps, OwnProps>(
    ({identity}, {foreignTaxResident}) => ({
        initialFTRYes: foreignTaxResident,
        taxResidencies: identity.user!.tax_residencies,
        isDependent: identity.user!.is_dependent,
        preferredName: identity.user!.preferred_name,
        jurisdiction: identity.user!.jurisdiction,
    }),
    dispatch => ({
        taxDeclareYes: countries => dispatch(actions.TaxDeclaration(true, countries)),
        taxDeclareNo: () => dispatch(actions.TaxDeclaration(false, [])),
    }),
)(AccountTaxDeclaration)

export const ForeignTaxResidentAccountTaxDeclaration = () => <ConnectedAccountTaxDeclaration foreignTaxResident />

export default ConnectedAccountTaxDeclaration
