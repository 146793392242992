import {interpolatePath} from 'd3-interpolate-path'
import * as d3select from 'd3-selection'
import {DateTime} from 'luxon'
import React from 'react'

interface AnimatedSvgPathProps {
    id: string
    d: string
    className?: string
    fill?: string
}

/**
 * AnimatedSvgPath is a drop-in replacement for the <path> element.
 * It uses a library called 'd3-interpolate-path' to interpolate between line changes.
 */
const AnimatedSvgPath: React.FC<AnimatedSvgPathProps & React.HTMLProps<SVGElement>> = ({
    id,
    d: path,
    className,
    fill,
}) => {
    const [firstRenderTime] = React.useState(DateTime.now())
    const [oldPathLength, setOldPathLength] = React.useState(path.split('L').length)
    const [oldPath, setOldPath] = React.useState(path)

    const stretchSvgPath = React.useCallback((path, amount) => stretchPath(path, amount, !!fill), [path, fill])

    const drawOrTransition = () => {
        const lineElement = d3select.select(`#${id}`)
        if (!lineElement) {
            return
        }

        const pathLength = getPointsFromPath(path).length

        if (!oldPath || firstRenderTime.diffNow().toMillis() > -500) {
            // Force the component to not transition on first render
            // Set the "d" attribute without a transition.
            lineElement.attr('d', path)
        } else if (oldPath !== path) {
            // Transition to the new path

            const stretchedOldPath = stretchSvgPath(oldPath, Math.max(pathLength - oldPathLength, 0))
            const stretchedNewPath = stretchSvgPath(path, Math.max(oldPathLength - pathLength, 0))

            lineElement
                .transition()
                .duration(500)
                .attrTween('d', () => interpolatePath(stretchedOldPath, stretchedNewPath))
        }
        setOldPathLength(pathLength)
        setOldPath(path)
    }

    React.useEffect(() => {
        drawOrTransition()
    }, [path])

    return <path id={id} d="" className={className} fill={fill} />
}

export default AnimatedSvgPath

const getPointsFromPath = (path: string): string[] => {
    // Match the pattern x,y with decimals
    return path.match(/\d*\.?\d+,\d*\.?\d+/g) || []
}

/**
 * Helper fundtion to duplicate an item at index x amount of times.
 * splice mutates the array, so no need to return
 */
const duplicatePointAtIndex = (arr: string[], index: number, amount: number) => {
    arr.splice(index, 0, ...Array(amount).fill(arr[index]))
}

/*
Stretch path: duplicates a point on the path.
This makes the transitions animate nicely in proportion to the graph's horizontal scale.

d3-interpolate-path handles lerping all svg points to new points.
Instead of allowing d3-interpolate-path to insert the new points where it wants,
this does some pre-work of where the new points are.
eg.

                            o
             o----o        /
            /      \      /
           /        o----o
   o------o
   ^
   Duplicate this point at the same coord x times.

Because the gradient path is an area and needs two points duplicated,
this function scans for all minimum X values and duplicates them.

              o----o
             /      \
            /        o----o
--> o------o              |
    |                     |
    |                     |
--> o------o--o----o-o----o

 * @param {string} path - SVG path
 * @param {amount} amount - amount of points to insert
 * @param {boolean} closePath - For area paths, draws a line between the start and end points
 */
const stretchPath = (path: string, amount: number, closePath: boolean): string => {
    if (amount === 0) {
        return path
    }
    const points = getPointsFromPath(path)

    const indexesToDuplicate = []
    for (const [index, point] of points.entries()) {
        if (point.startsWith('0,')) {
            indexesToDuplicate.push(index)
        }
    }

    for (const index of indexesToDuplicate.reverse()) {
        duplicatePointAtIndex(points, index, amount)
    }
    if (closePath) {
        return 'M' + points.join('L') + 'Z'
    }
    return 'M' + points.join('L')
}
