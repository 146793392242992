import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {urlFor} from '~/global/routeGenerator'
import {PageButtonGroup} from '~/global/widgets/button-page-group/PageButtonGroup'
import SharesiesLogo from '~/global/widgets/sharesies-logo/SharesiesLogo'
import {useNavigate} from '~/migrate-react-router'
import handImage from '~/sections/user/pages/anonymous-splash/assets/images/splash.png'
import {useAppDispatch} from '~/store/hooks'
import actions from '~/store/nav/actions'
import styles from './AnonymousSplash.scss'

const LandingPage = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    React.useEffect(() => {
        // make sure if the user is returning from the signup flow, that we reset the hidden flyout to visible
        dispatch(actions.ShowFlyoutMenu())
    }, [])

    return (
        <div className={styles.splash} data-testid="page--splash">
            <h1 className={styles.logo}>
                <SharesiesLogo />
            </h1>
            <h2 className={styles.tagline}>For the money you’ve got big plans for</h2>
            <img src={handImage} alt="" className={styles.splashImage} />
            <PageButtonGroup className={styles.pageButtonGroup}>
                <Button
                    dataTestId="login"
                    type="secondary"
                    label="Log in"
                    onClick={() => navigate(urlFor('login'))}
                    additionalClassName={cn(styles.loginButton, styles.splashButtons)}
                />
                <Button
                    dataTestId="button--sign-up"
                    label="Sign up"
                    onClick={() => navigate(urlFor('sign-up'))}
                    additionalClassName={styles.splashButtons}
                />
            </PageButtonGroup>
        </div>
    )
}

export default LandingPage
