import cn from 'classnames'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {accessibility} from '~/global/scss/helpers'
import tooltipStyles from '~/global/scss/reused-styles/tooltip.scss'
import capitalise from '~/global/utils/capitalise-string/capitaliseString'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {ChevronRight} from '~/global/widgets/OLD_icons'
import AmountPutInDetail from '~/global/widgets/detail-modals/AmountPutInDetail'
import DetailModal from '~/global/widgets/detail-modals/DetailModal'
import SimpleReturnDetail from '~/global/widgets/detail-modals/SimpleReturnDetail'
import TotalReturnDetail from '~/global/widgets/detail-modals/TotalReturnDetail'
import {DollarValue, PercentValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import Tooltip from '~/global/widgets/tooltip/Tooltip'
import labelledValuesStyles from '~/sections/invest/sections/view-instrument/LabelledValues.scss'
import {FundHolding} from '~/store/identity/types'
import {Instrument} from '~/store/instrument/types'
import {InstrumentReturn} from '~/store/portfolio/types'

type DetailTopic = null | 'total-return' | 'contribution' | 'simple-return' | 'shares' | 'amount-put-in'

export default ({
    currentReturn,
    hasHadSellOrder,
    holding,
    includeSoldInvestments,
    instrument,
    isDependent,
    isHomeCurrencyInvestment,
    preferredName,
    setHideFooter,
}: {
    currentReturn: InstrumentReturn
    hasHadSellOrder: boolean
    holding?: FundHolding
    includeSoldInvestments: boolean
    instrument: Instrument
    isDependent: boolean
    isHomeCurrencyInvestment: boolean
    preferredName: string
    setHideFooter?: (isHidden: boolean) => void
}) => {
    const [detailTopic, showDetailModal] = React.useState<DetailTopic>(null)
    const setDetailModalClosed = () => {
        showDetailModal(null)
        if (setHideFooter) {
            setHideFooter(false)
        }
    }
    const handleModalClick = (gaLabel: string, detailType: DetailTopic) => {
        Analytics.event({
            category: 'Your investment',
            action: `Clicked ${gaLabel} modal`,
            label: `${capitalise(gaLabel)} modal`,
        })
        showDetailModal(detailType)
        if (setHideFooter) {
            setHideFooter(true)
        }
    }

    if (!holding) {
        return <WeSlippedUp />
    }

    return (
        <div>
            <div className={labelledValuesStyles.container}>
                <div>
                    <Tooltip>
                        <p className={cn(tooltipStyles.label, labelledValuesStyles.label)}>Investment value</p>
                        <div className={tooltipStyles.tooltip}>
                            Delayed price multiplied by the number of {shareLabel({instrument, isPlural: true})} you own
                        </div>
                    </Tooltip>
                    <div className={cn(labelledValuesStyles.value, labelledValuesStyles.notClickableValue)}>
                        <DollarValue value={currentReturn.investment_value} />
                    </div>
                </div>
            </div>
            <div className={labelledValuesStyles.container}>
                <div>
                    <p className={labelledValuesStyles.label}>{includeSoldInvestments ? 'Total return' : 'Return'}</p>
                    <button
                        className={cn(labelledValuesStyles.value, accessibility.button)}
                        onClick={() => handleModalClick('total return', 'total-return')}
                        role="link"
                        type="button"
                        data-testid="button--detail-modal-total-return"
                    >
                        <DollarValue
                            value={
                                includeSoldInvestments
                                    ? currentReturn.total_return
                                    : currentReturn.unrealised_total_return
                            }
                        />
                        <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                    </button>
                </div>
                <div>
                    <p className={labelledValuesStyles.label}>Simple return</p>
                    <button
                        className={cn(labelledValuesStyles.value, accessibility.button)}
                        onClick={() => handleModalClick('simple return', 'simple-return')}
                        role="link"
                        type="button"
                    >
                        <PercentValue
                            value={
                                includeSoldInvestments
                                    ? currentReturn.simple_return
                                    : currentReturn.unrealised_simple_return
                            }
                        />{' '}
                        <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                    </button>
                </div>
                <div>
                    <p className={labelledValuesStyles.label}>Amount put in</p>
                    <button
                        className={cn(labelledValuesStyles.value, accessibility.button)}
                        onClick={() => handleModalClick('you’ve put in', 'amount-put-in')} // For GA, we'll keep the old "you’ve put in" wording
                        data-testid="button--detail-modal-amount-put-in"
                        role="link"
                        type="button"
                    >
                        <DollarValue value={currentReturn.cost_basis} />{' '}
                        <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                    </button>
                </div>
                <div>
                    <p className={labelledValuesStyles.label}>
                        {isDependent ? `${preferredName}’s` : 'Your'}
                        &nbsp;{shareLabel({instrument, isPlural: true})}
                    </p>
                    <div className={cn(labelledValuesStyles.value, labelledValuesStyles.notClickableValue)}>
                        <ShareValue value={holding!.shares} showFullValue />
                    </div>
                </div>
            </div>
            {detailTopic === 'total-return' && (
                <DetailModal isOpen={detailTopic === 'total-return'} closeHandler={() => setDetailModalClosed()}>
                    <TotalReturnDetail
                        currentReturn={currentReturn}
                        instrument={instrument}
                        includeSoldInvestments={includeSoldInvestments}
                        isDependent={isDependent}
                        preferredName={preferredName}
                    />
                </DetailModal>
            )}
            {detailTopic === 'simple-return' && (
                <DetailModal isOpen={detailTopic === 'simple-return'} closeHandler={() => setDetailModalClosed()}>
                    <SimpleReturnDetail
                        currentReturn={currentReturn}
                        hasHadSellOrder={hasHadSellOrder}
                        includeSoldInvestments={includeSoldInvestments}
                        instrumentName={instrument.name}
                        instrumentTypeLabel={shareLabel({instrument, isPlural: true})}
                        isDependent={isDependent}
                        preferredName={preferredName}
                    />
                </DetailModal>
            )}
            {detailTopic === 'amount-put-in' && (
                <DetailModal isOpen={detailTopic === 'amount-put-in'} closeHandler={() => setDetailModalClosed()}>
                    <AmountPutInDetail
                        currentReturn={currentReturn}
                        instrument={instrument}
                        isDependent={isDependent}
                        isHomeCurrencyInvestment={isHomeCurrencyInvestment}
                        preferredName={preferredName}
                    />
                </DetailModal>
            )}
        </div>
    )
}
