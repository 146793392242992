import {Button} from '@design-system/button'
import {CopyField} from '@design-system/copy-field'
import {Clock, Love} from '@design-system/icon'
import {Modal} from '@design-system/modal'
import cn from 'classnames'
import {withFormik} from 'formik'
import {DateTime} from 'luxon'
import React from 'react'
import {TransitionMotion, spring, TransitionPlainStyle} from 'react-motion'
import * as api from '~/api/retail'
import {Model} from '~/api/retail/types'
import darkModeEnvelope from '~/global/assets/images/envelope/envelope-dark.svg'
import lightModeEnvelope from '~/global/assets/images/envelope/envelope-light.svg'
import {REFERRAL_INFORMATION_KEY} from '~/global/constants/global'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {UserSuitableError} from '~/global/utils/error-handling/errorHandling'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {validate} from '~/global/widgets/form-controls'
import {Text, Email} from '~/global/widgets/form-controls/formik'
import {Image} from '~/global/widgets/image/Image'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import store from '~/store'
import actions from '~/store/accounting/actions'
import {connect} from '~/store/connect'
import {RootState} from '~/store/types'
import styles from './Referrals.scss'

interface FormValues {
    creatorName: string
    targetName: string
    targetEmail: string
}

interface FormProps {
    defaultCreatorName: string
    setReferralInformation: DispatchProps['setReferralInformation']
    showModal(): void
}

const ReferralForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: ({defaultCreatorName}) => ({
        creatorName: defaultCreatorName,
        targetName: '',
        targetEmail: '',
    }),
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        creatorName: undefined,
    }),
    handleSubmit: async (
        {creatorName, targetName, targetEmail},
        {setSubmitting, setStatus, resetForm, props: {setReferralInformation, showModal}},
    ) => {
        try {
            const response = await api.post('gifting/refer-friend', {
                creator_name: creatorName,
                target_name: targetName,
                target_email: targetEmail,
            })
            switch (response.type) {
                case 'gifting_referral_information':
                    setReferralInformation(
                        response.pending_invites,
                        response.sign_ups,
                        response.bonus_amount,
                        response.as_at,
                    )
                    showModal()
                    resetForm()
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        } catch (e) {
            if (e instanceof UserSuitableError) {
                setStatus(e.message)
                return
            }
            setStatus(unknownErrorMessage)
            throw e
        } finally {
            setSubmitting(false)
        }
    },
    validate: validate.generate<FormValues>({
        creatorName: [validate.required()],
        targetName: [validate.required()],
        targetEmail: [validate.required(), validate.email()],
    }),
})(({isSubmitting, handleSubmit, isValid}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    return (
        <form onSubmit={handleSubmit}>
            <Text dataTestId="text-input--your-name" name="creatorName" label="Your name (shown on friend’s invite)" />
            <Text
                dataTestId="text-input--their-first-name"
                name="targetName"
                label="Their first name"
                placeholder="Their first name"
            />
            <Email
                dataTestId="refer-a-friend-email-input"
                name="targetEmail"
                label="Their email address"
                placeholder="Their email address"
            />
            <p className={styles.terms} data-testid="paragraph--referral-terms-and-conditions">
                By referring a friend you agree to the{' '}
                <ButtonAsLink onClick={() => navigate(profileUrl('legal/referral-terms'))}>
                    Sharesies Referral terms and conditions
                </ButtonAsLink>
            </p>
            <Button
                dataTestId="button--send-invite"
                isSubmit
                label="Send invite"
                disabled={!isValid}
                processing={isSubmitting}
            />
        </form>
    )
})

interface FloatProps {
    value: React.ReactNode
    valueKey: string | number
}

class FloatValue extends React.PureComponent<FloatProps, {}> {
    private motionOut() {
        return {translateY: spring(-20, {stiffness: 300, damping: 30}), opacity: spring(0)}
    }

    private motionIn() {
        return {translateY: 20, opacity: 0}
    }

    public render() {
        const baseStyles = [
            {
                key: `${this.props.valueKey}`,
                style: {translateY: spring(0, {stiffness: 300, damping: 30}), opacity: spring(1)},
                data: this.props.value,
            },
        ]

        return (
            <TransitionMotion
                willLeave={this.motionOut}
                willEnter={this.motionIn}
                styles={baseStyles}
                style={{position: 'relative'}}
            >
                {(interpolatedStyles: TransitionPlainStyle[]) => (
                    <div>
                        {interpolatedStyles.map(config => {
                            return (
                                <div
                                    key={config.key}
                                    style={{
                                        transform: 'translateY(' + config.style.translateY + 'px)',
                                        opacity: config.style.opacity,
                                        position: 'absolute',
                                    }}
                                >
                                    {config.data}
                                </div>
                            )
                        })}
                    </div>
                )}
            </TransitionMotion>
        )
    }
}

interface State {
    modalVisible: boolean
}

export class Referrals extends React.PureComponent<StateProps & DispatchProps, State> {
    public state: State = {
        modalVisible: false,
    }

    public async componentDidMount() {
        const referralInformation = await store.dispatch(actions.FetchNewReferralInformation())
        if (referralInformation) {
            try {
                localStorage.setItem(REFERRAL_INFORMATION_KEY, referralInformation.as_at.toISO())
            } catch (ignore) {
                // Ignore failures when setting or removing item, worst case the
                // page will always animate from 0 on first visit
            }
        }
    }

    public render() {
        const {referrals, defaultCreatorName, homeCurrency, referralCode, setReferralInformation} = this.props

        let referralLink = `${location.protocol}//${location.host}/sign-up?referral_code=${referralCode}`
        if (location.host.includes('app.sharesies')) {
            referralLink = `https://sharesies.com/r/${referralCode}`
        }

        return (
            <>
                <Toolbar dataTestId="toolbar--referrals" leftButton="back" title="Refer a friend" />
                <Page overrideDefaultTopPadding="withToolbarTitle">
                    <div className={styles.header}>
                        <div className={styles.paidToWallet}>
                            <FloatValue
                                value={
                                    <>
                                        <DollarValue value={referrals.bonusAmount} /> {homeCurrency.toUpperCase()}
                                    </>
                                }
                                valueKey={referrals.bonusAmount}
                            />
                            <p>Paid to your Wallet</p>
                        </div>
                    </div>
                    <div className={styles.stats}>
                        <div className={styles.counter}>
                            <Clock />
                            <FloatValue value={referrals.pendingInvites} valueKey={referrals.pendingInvites} />
                            <p>Pending invites</p>
                        </div>
                        <div className={styles.counter}>
                            <Love />
                            <FloatValue value={referrals.signUps} valueKey={referrals.signUps} />
                            <p>Sign ups</p>
                        </div>
                    </div>
                    <p className={cn(spacing.spaceBelow32, spacing.spaceAbove24)}>
                        You and your mate will each get a referral bonus of $5 or more when they sign up using your link
                        within 30 days.
                    </p>
                    <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow12, spacing.spaceAbove32)}>
                        Share your referral link
                    </h2>
                    {referralCode && (
                        <CopyField
                            additionalClassName={spacing.spaceBelow24}
                            id="copy-referral-link"
                            dataTestId="copy-field--referral-link"
                            label="Copy your referral link"
                            value={referralLink}
                        />
                    )}
                    <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow12, spacing.spaceAbove32)}>
                        Or, email them a link
                    </h2>
                    <ReferralForm
                        defaultCreatorName={defaultCreatorName}
                        setReferralInformation={setReferralInformation}
                        showModal={() => this.setState({modalVisible: true})}
                    />
                    <Modal
                        isOpen={this.state.modalVisible}
                        setIsOpen={_ => this.setState({modalVisible: false})} // The argument `_` will be called as false, but we don't need to use it in this case
                        title="Your invite has been sent!"
                        dataTestId="modal--referral-invite-sent"
                        primaryButton={{label: 'Ok'}}
                    >
                        <Image
                            src={lightModeEnvelope}
                            darkSrc={darkModeEnvelope}
                            className={styles.modalImage}
                            alt=""
                        />
                        <p>
                            Only email addresses that aren't attached to a Sharesies account or an existing referral
                            will be sent an invite.
                        </p>
                    </Modal>
                </Page>
            </>
        )
    }
}

interface StateProps {
    referrals: RootState['accounting']['referrals']
    defaultCreatorName: string
    homeCurrency: Model.User['home_currency']
    referralCode?: string
}

interface DispatchProps {
    setReferralInformation(pendingInvites: number, signUps: number, bonusAmount: string, asAt: DateTime): void
}

export default connect<StateProps, DispatchProps, {}>(
    ({accounting: {referrals}, identity: {referralCode, user}}) => ({
        referrals,
        defaultCreatorName: user ? user.preferred_name : '',
        homeCurrency: user!.home_currency,
        referralCode,
    }),
    dispatch => ({
        setReferralInformation: (...args) => dispatch(actions.SetReferralInformation(...args)),
    }),
)(Referrals)
