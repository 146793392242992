import {Modal} from '@design-system/modal'
import React from 'react'
import styles from '~/sections/invest/sections/order-flow/widgets/modals/Modals.scss'
import {StagedSellOrder, StagedBuyOrder} from '~/store/order/types'

interface Props {
    orderType?: StagedSellOrder['orderType'] | StagedBuyOrder['orderType']
    onContinue(): void
    onClose(): void
}

export const ExtendedHoursModal: React.FunctionComponent<Props> = ({orderType, onContinue, onClose}) => {
    return (
        <Modal
            isOpen // hardcode to on, parent component handles logic
            setIsOpen={_ => {
                // The `_` argument will be called as false, but we don't need to use it in this case
                // Normally we'd just be passing in setModalOpen (setIsOpen={setModalOpen}), but we need to run a
                // side effect `onClose` rather than setting `isOpen(false)`
                onClose()
            }}
            dataTestId="modal--extended-hours"
            bottomBorder
            title="Extended-hours orders"
            primaryButton={{
                label: 'Okay, got it',
                onClick: onContinue,
            }}
        >
            <p>
                Extended hours orders only stay on the market until close of extended hours on the following trading
                day.
            </p>
            <p>
                Prices may see greater ups and downs during extended hours compared to regular market hours. This is due
                to fewer people choosing to buy and sell during this time.
            </p>
            {orderType && !orderType.includes('limit') && (
                <p className={styles.boldText}>
                    If you’d like more control over the price your order fills at, consider placing a limit order.
                </p>
            )}
        </Modal>
    )
}

export default ExtendedHoursModal
