import {DateTime} from 'luxon'
import {Model} from '~/api/retail/types'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {isOnNZX, isOnASX} from '~/global/utils/is-on-exchange/isOnExchange'
import {sortTradesByDate} from '~/global/utils/order/order'
import {OrderType, RecentOrder} from '~/store/accounting/types'
import {instrumentPrices} from '../instrument/selectors'
import {createCachedSelector} from '../selectors'
import {Order} from './types'

export type Trade = Extract<Order, {type: 'buy'}>['trades'][number]

function firstDate<T extends {date: string}>(list?: T[]): string | undefined {
    if (!list || list.length === 0) {
        return
    }
    return list[0].date
}

interface InstrumentEvent {
    stringDate: string
    type: OrderType
}

interface DateListItem {
    date: string
    isWeekend: boolean
}

export interface InstrumentHistoryItem {
    date: string
    hasOrderEvent: boolean
    isWeekend: boolean
    price?: number
    priceIsEstimate: boolean
}

export interface InstrumentHistoryItemWithIndex extends InstrumentHistoryItem {
    index: number
}

export const sliceInstrumentHistory = (
    fundHistory: InstrumentHistoryItem[],
    firstDate: string,
): InstrumentHistoryItemWithIndex[] => {
    const sliceIndex = fundHistory.findIndex(f => f.date === firstDate)
    if (sliceIndex !== -1) {
        fundHistory = fundHistory.slice(sliceIndex)
    }

    // TODO: remove isWeekend, or make 'isWeekend' dyanmic for different exchanges (i.e. varies based on what days a particular exchange is closed)
    return fundHistory.filter(f => !f.isWeekend || (f.isWeekend && f.hasOrderEvent)).map((f, i) => ({...f, index: i}))
}

/**
 * Returns all orders for a given instrument - from the order-history-v5 endpoint.
 *
 * Will default to [] if the orders haven't been loaded
 */
export const selectInstrumentOrders = createCachedSelector(
    ({accounting}, instrumentId: string) => accounting.fundOrders[instrumentId],
    (orders): Order[] => orders?.orders ?? [],
)((_, instrumentId) => instrumentId)

/**
 * Determines if an order should show up under recent orders
 */
const isRecentOrder = (order: Order): order is RecentOrder => {
    const threshold = DateTime.local().minus({days: 1})

    switch (order.type) {
        case 'ess_share_allocation_line':
        case 'ess_share_right_allocation_line':
            if (!order.vest_transaction_id) {
                return false
            }
            return order.vesting_date! > threshold
        case 'ess_alignment_right_allocation_line':
            if (!order.vesting_date) {
                return false
            }
            return order.vesting_date! > threshold
        case 'transfer_in':
        case 'transfer_out': {
            if (order.complete_at) {
                return order.complete_at > threshold
            }
            if (order.rejected_at) {
                return order.rejected_at > threshold
            }
            if (order.cancelled_at) {
                return order.cancelled_at > threshold
            }
            // Order will be in states 'new', 'processing' - display automatically
            return true
        }
        case 'corporate_action_v2': {
            if (order.application) {
                if (order.action_type === 'VOTE' && order.application.is_cancelled) {
                    return false
                }
                if (order.application.fulfilled_at) {
                    return order.application.fulfilled_at > threshold
                }
                if (['EXERCISE', 'PURCHASE'].includes(order.action_type) && order.application.cancelled_at) {
                    return order.application.cancelled_at > threshold
                }
                // Application will be pending
                return true
            }
            break
        }
        case 'buy':
        case 'sell': {
            if (order.trades.length > 0) {
                const sortedTrades = sortTradesByDate(order)
                const lastTrade = sortedTrades[sortedTrades.length - 1]
                return lastTrade.created > threshold
            } else {
                if (order.fulfilled_at) {
                    return order.fulfilled_at > threshold
                }
                if (order.cancelled_at) {
                    return order.cancelled_at > threshold
                }
                if (order.rejected_at) {
                    return order.rejected_at > threshold
                }
                if (order.state === 'expired') {
                    if (order.order_expires) {
                        return order.order_expires > threshold
                    }
                    return false
                }
                if (order.state === 'failed') {
                    // Failed auto invest orders - auto invest orders fails as it's created
                    return order.created > threshold
                }
                return true
            }
        }
        case 'dividend':
        case 'corporate_action':
            return false

        default:
            assertNever(order)
    }

    return false
}

/**
 * Returns recent orders for a given instrument - from the order-history-v5 API. "recent" is defined by isRecentOrder
 */
export const instrumentRecentOrders = createCachedSelector(selectInstrumentOrders, (orders): RecentOrder[] =>
    orders.filter(isRecentOrder),
)((_, instrumentId) => instrumentId)

/**
 * Generates a date range from the earliest relevant instrument date up until today.
 *
 * The earliest relevant date is the earliest of:
 * - the first date for which we have a price, or
 * - the first date on which something happened with the investment - trade, CA, etc.
 *
 * @returns a list of dates as strings, in YYYY-MM-DD format
 */
const selectInstrumentDateList = createCachedSelector(
    () => DateTime.local(),
    (state, fundId: string) => selectInstrumentEvents(state, fundId)[0]?.stringDate,
    (state, fundId: string) => firstDate(instrumentPrices(state, fundId)),
    (
        currentDate: DateTime,
        earliestContributionDate: string | undefined,
        earliestPriceDate: string | undefined,
    ): DateListItem[] => {
        // If we have neither date, bail out
        if (!earliestContributionDate && !earliestPriceDate) {
            return []
        }

        const actualDates = [earliestContributionDate, earliestPriceDate].filter(x => x) as string[]
        const earliestDate = actualDates.sort()[0]

        const dateList: DateListItem[] = []
        let runningDate: DateTime = DateTime.fromISO(earliestDate) // incremented in loop

        do {
            dateList.push({
                date: runningDate.toISODate(),
                isWeekend: runningDate.weekday === 6 || runningDate.weekday === 7,
            })
            runningDate = runningDate.plus({days: 1})
        } while (runningDate < currentDate)

        return dateList
    },
)((_, fundId) => fundId)

/**
 * This gives a list of what/when something happened for this customer's investment,
 * e.g. a CA, dividend, trade, order fulfilment
 *
 * @returns a chronological list of InstrumentEvents.
 */
export const selectInstrumentEvents = createCachedSelector(
    selectInstrumentOrders,
    (orders: Order[]): InstrumentEvent[] => {
        const instrumentEvents: {type: OrderType; datetime: DateTime}[] = []

        orders.forEach(order => {
            switch (order.type) {
                case 'buy':
                case 'sell':
                    if (order.trades && order.trades.length > 0) {
                        instrumentEvents.push(
                            ...order.trades.map(trade => {
                                return {type: order.type, datetime: trade.created}
                            }),
                        )
                    } else if (order.fulfilled_at) {
                        instrumentEvents.push({type: order.type, datetime: order.fulfilled_at})
                    }
                    break
                case 'corporate_action':
                case 'corporate_action_v2':
                    instrumentEvents.push({type: order.type, datetime: order.action_timestamp})
                    break
                case 'dividend':
                    if (order.settled) {
                        instrumentEvents.push({type: order.type, datetime: order.settled})
                    }
                    break
                case 'ess_share_allocation_line':
                case 'ess_share_right_allocation_line':
                    instrumentEvents.push({type: order.type, datetime: order.vesting_date!})
                    break
                case 'transfer_in':
                    if (order.state === 'complete' && order.records.length > 0) {
                        instrumentEvents.push(
                            ...order.records.map(record => {
                                return {type: order.type, datetime: DateTime.fromISO(record.date)}
                            }),
                        )
                    }
                    break
                case 'transfer_out':
                    const date = order.complete_at ?? order.created
                    instrumentEvents.push({type: order.type, datetime: date})
                    break
                default:
                    break
            }
        })

        const sortedInstrumentEvents = instrumentEvents.sort((a, b) => a.datetime.toMillis() - b.datetime.toMillis())
        return sortedInstrumentEvents.map(event => {
            return {type: event.type, stringDate: event.datetime.toISODate()}
        })
    },
)((_, fundId) => fundId)

/**
 * Generates a list of all relevant dates covering the instrument history.
 *
 * Also embedded are prices, and whether something happened for this investment/customer on a given day.
 *
 * Note that CAs are excluded - this selector is currently used by the Price history graph, on which we don't want to display CAs
 * This may change in future, see https://sharesies.atlassian.net/browse/CONF-2324
 */
export const selectInstrumentHistory = createCachedSelector(
    selectInstrumentDateList,
    selectInstrumentEvents,
    instrumentPrices,
    (dateRange, instrumentEvents, prices) => {
        // CAs and divs excluded. We may want to show them in future, see https://sharesies.atlassian.net/browse/CONF-2324
        const releventEventTypes: OrderType[] = [
            'buy',
            'sell',
            'transfer_in',
            'transfer_out',
            'ess_share_allocation_line',
            'ess_share_right_allocation_line',
        ]

        const relevantOrderEvents = instrumentEvents.filter(event => releventEventTypes.includes(event.type))
        const instrumentEventDates = relevantOrderEvents.map(event => event.stringDate)
        let priceIndex = 0

        return dateRange.map<InstrumentHistoryItem>((dateListItem: DateListItem) => {
            const date = dateListItem.date

            while (prices[priceIndex] && prices[priceIndex].date <= date) {
                priceIndex++
            }

            const price = prices[priceIndex - 1] || {date, price: undefined}

            return {
                date,
                hasOrderEvent: instrumentEventDates.includes(date),
                isWeekend: dateListItem.isWeekend,
                price: price.price,
                priceIsEstimate: date !== price.date,
            }
        })
    },
)((_, instrumentId) => instrumentId)

export const shareSplitCausingPriceIssues = createCachedSelector(
    selectInstrumentOrders,
    ({instrument}, instrumentId: string) => instrument.instrumentsById[instrumentId],
    (orders, instrument) => {
        // The backend sorts orders in descending timestamp order, so the first order is the most recent
        const lastSplit = orders.find(
            order =>
                order.type === 'corporate_action_v2' &&
                (order.action_type === 'SHARE_SPLIT' || order.action_type === 'SHARE_CONSOLIDATION'),
        ) as (Model.CorporateActionV2 & {action_type: 'SHARE_SPLIT' | 'SHARE_CONSOLIDATION'}) | undefined

        // marketLastCheck refers to the last price we got from our data provider
        // We want the alert to stay around until we get healthy data from them, even if that's later than expected
        // Ops overrides won't affect this time
        const lastPrice = instrument.marketLastCheck

        if (lastSplit && lastSplit.action_timestamp > lastPrice) {
            return lastSplit
        }
        return null
    },
)((_, instrumentId) => instrumentId)

export const canMakeAutoExerciseBuyOrder = createCachedSelector(
    ({instrument}, instrumentId: string) => instrument.instrumentsById[instrumentId],
    ({identity}) => identity.flags,
    ({identity}) => identity.applicationPointers,
    (instrument, flags, applicationPointers) => {
        if (instrument === undefined) {
            return false
        }

        const exerciseApplication = applicationPointers.find(a => a.fund_id === instrument.id && a.type === 'EXERCISE')

        // TODO - ECM-490 remove auto exercise flag
        if (
            flags.auto_exercise &&
            instrument.instrumentType === 'rights' &&
            exerciseApplication &&
            (isOnNZX(instrument) || isOnASX(instrument))
        ) {
            return true
        }
        return false
    },
)((_, instrumentId) => instrumentId)
