import cn from 'classnames'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {accessibility} from '~/global/scss/helpers'
import {useNavigate} from '~/migrate-react-router'
import InvestingActivityCount from '~/sections/invest/widgets/investing-activity-count/InvestingActivityCount'
import {Order} from '~/store/accounting/types'
import styles from './RecentInvestingActivity.scss'

interface OwnProps {
    orders: Order[]
    url: string
}
const RecentInvestingActivity: React.FunctionComponent<OwnProps> = ({orders, url}) => {
    const navigate = useNavigate()
    return (
        <button
            className={cn(
                styles.recentOrders,
                {[styles.portfolioRecentOrders]: url.includes('invest')},
                accessibility.button,
            )}
            data-testid="button--view-recent-investing-activity"
            aria-label="View recent investing activity"
            type="button"
            onClick={() => {
                Analytics.event({
                    category: url.includes('invest') ? 'Portfolio' : 'Instrument',
                    action: 'Clicked investing activity',
                    label: 'Investing activity',
                })
                navigate(url)
            }}
        >
            <div className={styles.recentOrdersContent}>
                <p className={styles.recentOrdersHeading}>Investing activity</p>
                <InvestingActivityCount orders={orders} />
                <p className={styles.recentOrdersView}>View</p>
            </div>
        </button>
    )
}

export default RecentInvestingActivity
