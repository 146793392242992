import cn from 'classnames'
import React from 'react'
import {Navigate} from 'react-router-dom'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {SwitchField} from '~/global/widgets/form-controls'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'

export const AccountPortfolioUsExtendedHoursContent: React.FC<{withHeading: boolean}> = ({withHeading = true}) => {
    const enableExtendedHours = useAppSelector(s => s.identity.portfolioExtendedHoursPreference)
    const signedUpToUS = useAppSelector(s => s.identity.user!.us_equities_enabled)

    const dispatch = useAppDispatch()
    const updateEnableExtendedHours = (enable: boolean) => dispatch(identityActions.UpdateEnableExtendedHours(enable))

    const [localEnableExtendedHours, setEnableExtendedHours] = React.useState<boolean>(enableExtendedHours)
    const postError = 'Sorry, something went wrong, please try again.'

    const handleEnableExtendedHoursChange = async () => {
        setEnableExtendedHours(!localEnableExtendedHours)
        const potentialError = await updateEnableExtendedHours(!localEnableExtendedHours)

        if (potentialError) {
            Toast(postError)
        }
    }

    if (!signedUpToUS) {
        return <Navigate to="/portfolio" />
    }

    return (
        <>
            {withHeading && <h2>Extended US trading hours</h2>}
            <SwitchField
                additionalClassName={cn(spacing.spaceBelow12, spacing.spaceAbove12)}
                dataTestId="switch--enable-extended-hours"
                label="Enable extended hours"
                name="enable-extended-hours"
                onChange={handleEnableExtendedHoursChange}
                value={localEnableExtendedHours}
                isTouched={false}
            />
            <p className={cn(typographyOverrides['as-small-text'], spacing.spaceBelow48)}>
                Allow orders to fill before and after regular US market hours for a select number of US investments.{' '}
                <HelpCentreLink
                    auArticle="7890245-extended-us-trading-hours"
                    nzArticle="7890244-extended-us-trading-hours"
                >
                    Learn more about extended hours
                </HelpCentreLink>
                .
            </p>
        </>
    )
}

export const PortfolioUsExtendedHours: React.FC<{}> = () => {
    return (
        <>
            <Toolbar dataTestId="toolbar--returns-calculation" leftButton="back" title="Extended US trading hours" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <AccountPortfolioUsExtendedHoursContent withHeading={false} />
            </Page>
        </>
    )
}
