import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import NotFound from '~/global/pages/not-found/NotFound'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import SellConfirm from '~/sections/invest/sections/order-flow/sections/sell/pages/sell-confirm/SellConfirm'
import {RegistrationPendingIntro} from '~/sections/kiwisaver/pages/registration-pending/RegistrationPendingIntro'
import {AccountActivityBreakdown} from '~/sections/kiwisaver/sections/activity/pages/account-activity-breakdown/AccountActivityBreakdown'
import {Activity} from '~/sections/kiwisaver/sections/activity/pages/activity/Activity'
import CashActivityDetails from '~/sections/kiwisaver/sections/activity/pages/cash-activity-details/CashActivityDetails'
import InvestingActivityDetails from '~/sections/kiwisaver/sections/activity/pages/investing-activity-details/InvestingActivityDetails'
import {EDIT_INVESTMENT_PLAN_ROUTES} from '~/sections/kiwisaver/sections/edit-investment-plan/routes'
import {Home} from '~/sections/kiwisaver/sections/home/Home'
import {DownloadDocuments} from '~/sections/kiwisaver/sections/manage/pages/download-documents/DownloadDocuments'
import {MemberReports} from '~/sections/kiwisaver/sections/manage/pages/member-reports/MemberReports'
import {CantJoin} from '~/sections/kiwisaver/sections/sign-up/pages/cant-join/CantJoin'
import {Confirm} from '~/sections/kiwisaver/sections/sign-up/pages/confirm/Confirm'
import {GoalsAndValues} from '~/sections/kiwisaver/sections/sign-up/pages/goals-and-values/GoalsAndValues'
import {SignUpSlides} from '~/sections/kiwisaver/sections/sign-up/pages/sign-up-slides/SignUpSlides'
import {TaxInformationForm} from '~/sections/kiwisaver/sections/sign-up/pages/tax-information/TaxInformationForm'
import {ViewFund} from '~/sections/kiwisaver/sections/view-fund/ViewFund'
import SellIntro from '~/sections/kiwisaver/sections/view-fund/widgets/sell-order/SellIntro'
import SellPanel from '~/sections/kiwisaver/sections/view-fund/widgets/sell-order/SellPanel'
import {useAppSelector} from '~/store/hooks'
import {useKSCustomerOrNull} from './state'

const KiwiSaverWrapper: React.FunctionComponent<{}> = () => {
    const profileUrl = useProfileUrl()

    // If customer is null, this means they haven't started any of the KS flow
    const customer = useKSCustomerOrNull()

    // @NOTE canJoinKiwiSaver flips to false once someone has started the KS sign up process, or has KS
    const canJoinKiwiSaver = useAppSelector(s => s.identity.canJoinKiwiSaver)

    if (!canJoinKiwiSaver && customer === null) {
        // If a customer is not eligible for KiwiSaver (they're a dependent or have no DOB or fall outside the age range)
        // Then send them back to explore (this is a edge case as KS tiles shouldn't be visible for customers who fall into this group)
        return <Navigate to={profileUrl('explore')} replace />
    }

    // Stay in KiwiSaver as they can join KS
    return <Outlet />
}

/**
 * This component is intended to be used in routers as wrapper component to take
 * any customer not in the expected state for its children and redirect it to
 * the appropriate place.
 *
 * signup = either the customer doesn't yet exist, or their state is SIGNUP
 * pending = the customer exists and their state is one of: PENDING_CO_ADMIN_CONFIRMATION, PENDING_ACTIVATION, or TRANSFERRING_IN
 * active = the customer exists and their state is ACTIVE
 * offboarding = the customer exists and their state is OFFBOARDING
 */
const CustomerStateRedirectorWrapper: React.FunctionComponent<{
    acceptableStates: ('signup' | 'pending' | 'active' | 'offboarding')[]
}> = ({acceptableStates}) => {
    const customer = useKSCustomerOrNull()
    const profileUrl = useProfileUrl()

    if (!acceptableStates.includes('signup') && (!customer || customer.customer_state === 'SIGNUP')) {
        return <Navigate to={profileUrl('kiwisaver/sign-up')} replace />
    }
    if (
        !acceptableStates.includes('pending') &&
        customer &&
        ['PENDING_CO_ADMIN_CONFIRMATION', 'TRANSFERRING_IN', 'PENDING_ACTIVATION'].includes(customer.customer_state)
    ) {
        return <Navigate to={profileUrl('kiwisaver/pending')} replace />
    }
    if (!acceptableStates.includes('active') && customer && customer.customer_state === 'ACTIVE') {
        return <Navigate to={profileUrl('kiwisaver')} replace />
    }

    if (!acceptableStates.includes('offboarding') && customer && customer.customer_state === 'OFFBOARDING') {
        return <Navigate to={profileUrl('kiwisaver')} replace />
    }

    return <Outlet />
}
export const KIWISAVER_ROUTES = [
    {
        element: <KiwiSaverWrapper />,
        children: [
            {
                path: 'sign-up',
                Component: () => <CustomerStateRedirectorWrapper acceptableStates={['signup']} />,
                children: [
                    {index: true, element: <SignUpSlides />},
                    {path: 'goals-and-values', Component: GoalsAndValues},
                    {path: 'tax', Component: TaxInformationForm},
                    {path: 'confirm', Component: Confirm},
                    {path: 'cannot-join', Component: CantJoin},
                    ...EDIT_INVESTMENT_PLAN_ROUTES,
                    {path: '*', Component: NotFound},
                ],
            },
            {
                path: 'pending',
                Component: () => <CustomerStateRedirectorWrapper acceptableStates={['pending']} />,
                children: [{index: true, Component: RegistrationPendingIntro}, ...EDIT_INVESTMENT_PLAN_ROUTES],
            },
            {
                Component: () => <CustomerStateRedirectorWrapper acceptableStates={['active']} />,
                children: EDIT_INVESTMENT_PLAN_ROUTES,
            },
            {
                Component: () => <CustomerStateRedirectorWrapper acceptableStates={['active', 'offboarding']} />,
                children: [
                    {index: true, element: <Home />},
                    {path: 'activity', Component: () => <Activity activeTab="transactions" />},
                    {path: 'activity/breakdown', Component: () => <AccountActivityBreakdown />},
                    {path: 'investing-activity', Component: () => <Activity activeTab="investing-activity" />},
                    {path: 'transaction/:transactionId', Component: CashActivityDetails},
                    {path: 'investing-activity/:investingId', Component: InvestingActivityDetails},
                    {
                        path: ':urlSlug',
                        Component: ({urlSlug}: {urlSlug: string}) => (
                            <ViewFund viewFundMode="view" activeTab="Overview" urlSlug={urlSlug} />
                        ),
                    },
                    {
                        path: ':urlSlug/sell/intro',
                        Component: ({urlSlug}: {urlSlug: string}) => <SellIntro urlSlug={urlSlug} />,
                    },
                    {
                        path: ':urlSlug/sell/form',
                        Component: ({urlSlug}: {urlSlug: string}) => <SellPanel urlSlug={urlSlug} />,
                    },
                    {
                        path: ':urlSlug/sell/confirm',
                        Component: ({urlSlug}: {urlSlug: string}) => <SellConfirm urlSlug={urlSlug} />,
                    },
                    {
                        path: ':urlSlug/investment',
                        Component: ({urlSlug}: {urlSlug: string}) => (
                            <ViewFund viewFundMode="view" activeTab="Your investment" urlSlug={urlSlug} />
                        ),
                    },
                    {path: 'download-documents', Component: () => <DownloadDocuments />},
                    {
                        path: 'personalised-quarterly-reports',
                        Component: () => <MemberReports purpose="KIWISAVER_QUARTERLY_REPORT" />,
                    },
                    {
                        path: 'annual-member-statements',
                        Component: () => <MemberReports purpose="KIWISAVER_ANNUAL_MEMBER_STATEMENT" />,
                    },
                    {
                        path: 'annual-tax-reports',
                        Component: () => <MemberReports purpose="KIWISAVER_ANNUAL_TAX_REPORT" />,
                    },
                    {path: '*', Component: NotFound},
                ],
            },
        ],
    },
] as const
