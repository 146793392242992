import React, {useState} from 'react'
import Loading from '~/global/pages/loading-screen/LoadingScreen'
import {spacing} from '~/global/scss/helpers'
import {clearAllQueryParameters} from '~/global/utils/clear-all-query-parameters/clearAllQueryParameters'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import styles from '~/sections/user/sections/sign-up/SignUp.scss'

/**
 * What is this, I hear you ask? Well well, settle in for a long read.
 * When a user signs up with Sharesies, they can either start the signup process with the .nz domain, or the .com domain.
 * Usually this would be fine, but we have now introduced a third party (Verifi) into the signup flow where we redirect the
 * user to a Verifi domain during signup, which then redirects back to signup after copleting the Verifi flow. The issue
 * begins here where we need to tell Verifi where to redirect the user back to.
 * When we create a `verification_check` record, this record contains the Verifi callback url. We create this via the backend
 * which has no knowledge of which domain the user actually started the signup process on. We _could_ figure this out
 * by sending the host details back with the signup API call that ultimately ends up creating the `verification_check`
 * record, but this doesn't solve all cases. There is a case where we create this record via Admin for a user. In this case we
 * have no idea which URL the user will use to login to and start the Verifi process. It's an operator creating the check and
 * not the user. We have no control over where they decide to login from.
 * So what to do? This page here will only render if we have determined that it is after being redirected from Veriri
 * (which we can tell via a URL parameter) and if there is no user session. There are two reasons this will happen. The first
 * is where the user starts the Verifi flow on their desktop but finishes on their phone. In this case the desktop will automatically
 * refresh via some Verifi websocket magic, but the phone will be redirected to signup as well but won't have any user
 * session. The second case is where the user starts the signup flow on the .nz domain but is redirected to the .com domain.
 * In the wrong domain case, we need to redirect the user back to the .nz domain. We do that by acknowledging that if they get here
 * then we know there is no user session. By checking if they are on the .com here, we can redirect them to .nz for them to
 * continue on with their signup journey. If they started on the .com domain then this page will never show because there will
 * be a user session.
 * But what about the phone case? Well, in this case we don't know whether they were redirected on their phone or whether they are
 * part of the .nz /.com problem. There isn't enough info available to tell. This means that, because of the redirect mentioned
 * above, we also redirect these people to the .nz domain even though they never started on that domain - they simply started
 * at the Verifi URL and the phone had no knowledge of signup at all. Once they are redirected to the .nz domain, this page
 * will be rendered again but because they aren't on the .com domain, we don't do any redirects and show the 'Nice photos'
 * image.
 * The outcome of this is that anyone who sees this page will be redirected to the .nz domain. For the cases where the domain
 * isn't relevant and they should legit see this page, they will still see it but they will be unecessarily redirected first.
 * But it also means that no matter where a user starts signup, they will always be able to continue and that's the goal
 * here.
 * Ultimately it would be rad if we only had one domain, but for other more frustrating technical reasons we're going to be stuck
 * with two for the time being.
 */
const redirectToNz = (redirectPage: string) => {
    window.location.href = `https://app.sharesies.nz/${redirectPage}${window.location.search}`
}

interface OwnProps {
    isVerificationAtThreshold?: boolean
}

const IdentityVerificationCompleteOnAnotherComputer: React.FunctionComponent<OwnProps> = ({
    isVerificationAtThreshold,
}) => {
    const navigate = useNavigate()
    const requiredRedirect = window.location.host === 'app.sharesies.com'
    const redirectToSignup = window.location.pathname.includes('/sign-up')
    const redirectPage = redirectToSignup ? 'sign-up' : 'verify-your-identity'
    const [loading, setLoading] = useState(requiredRedirect)
    React.useEffect(() => {
        if (requiredRedirect) {
            return redirectToNz(redirectPage)
        }

        clearAllQueryParameters()
        setLoading(false)
    }, [])

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--identify-on-another-computer"
                leftButton="close"
                onLeftButtonClick={() => {
                    navigate('/')
                }}
            />
            <Page className={styles.splashContainer}>
                <h2>Nice photos!</h2>

                <p className={spacing.spaceBelow24}>
                    To continue {isVerificationAtThreshold ? 'ID verification' : 'sign-up'}, switch back to your
                    desktop.
                </p>
            </Page>
        </>
    )
}

export default IdentityVerificationCompleteOnAnotherComputer
