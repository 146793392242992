/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface InstrumentPriceChangeRequestDto
 */
export interface InstrumentPriceChangeRequestDto {
    /**
     *
     * @type {string}
     * @memberof InstrumentPriceChangeRequestDto
     */
    instrumentId: string
    /**
     *
     * @type {Date}
     * @memberof InstrumentPriceChangeRequestDto
     */
    initialDate: DateTime
}

export function InstrumentPriceChangeRequestDtoFromJSON(json: any): InstrumentPriceChangeRequestDto {
    return InstrumentPriceChangeRequestDtoFromJSONTyped(json, false)
}

export function InstrumentPriceChangeRequestDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): InstrumentPriceChangeRequestDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        instrumentId: json['instrumentId'],
        initialDate: DateTime.fromISO(json['initialDate'], {setZone: true}),
    }
}

export function InstrumentPriceChangeRequestDtoToJSON(value?: InstrumentPriceChangeRequestDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        instrumentId: value.instrumentId,
        initialDate: value.initialDate.toFormat('yyyy-MM-dd'),
    }
}
