import {PoliciesResponse, QuotesResponse} from '~/api/cove/types'

export interface PolicyQuoteCommonProps {
    carValue: string
    code: string
    coverage: string
    excess: string | undefined
    name: string
    premiumCost: string
    premiumFrequency: string
}

export const policyFrequency = (term: number) => {
    if (term === 1) {
        return 'Monthly'
    }
    if (term === 12) {
        return 'Annual'
    }
    return 'unknown'
}

export const vehicleDisplayName = (
    risk: PoliciesResponse['coverages'][0]['risk'] | QuotesResponse['quotes'][0]['coverages'][0]['risk'],
) => `${risk.vehicle.year} ${risk.vehicle.make} ${risk.vehicle.model}, ${risk.registration}`

export const findExcess = (
    excesses: PoliciesResponse['coverages'][0]['excesses'] | QuotesResponse['quotes'][0]['coverages'][0]['excesses'],
) => {
    const relevantExcess = excesses.find(excess => excess.selected)

    return relevantExcess ? relevantExcess.amount.toString() : undefined
}

/** Roadside assistance (Cove code: COMPRA) is an an optional add-on to a policy. */
export const hasRoadSideAssistance = (coverages: PoliciesResponse['coverages'][0]['coverages']): boolean =>
    coverages.find(c => c.code === 'COMPRA')?.selected === true
