import {useColourMode} from '@design-system/use-colour-mode/useColourMode'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {getImageForAutoInvestOrder} from '~/sections/invest/sections/auto-invest/utils/map-auto-invest-images/mapAutoInvestImages'
import {EnhancedAutoinvestOrder, PremadeOrderEnhanced} from '~/store/autoinvest/selectors'
import styles from './AutoInvestHeader.scss'

interface AutoInvestHeaderProps {
    orderType: string
    onPageBack?: () => void
    order: EnhancedAutoinvestOrder | PremadeOrderEnhanced
    name?: string
    currentInvestInfo?: string
}

const AutoInvestHeader: React.FunctionComponent<AutoInvestHeaderProps> = ({
    orderType,
    onPageBack,
    order,
    name,
    currentInvestInfo,
}) => {
    const colourMode = useColourMode()
    const image = getImageForAutoInvestOrder(orderType, colourMode)

    return (
        <div
            style={{
                backgroundColor: image.backgroundColour,
            }}
        >
            <Toolbar dataTestId="toolbar--auto-invest-header" leftButton="back" onLeftButtonClick={onPageBack} />
            <div className={styles.graphicHeader}>
                <div className={styles.imageWrapper}>
                    <img src={image.thumbnailImage} alt={image.altText ?? ''} />
                </div>
            </div>
            <div className={styles.extraPadding}></div>

            {order && (
                <div className={styles.headlineInfo}>
                    <span>Auto-invest</span>
                    <h1 data-testid="h1--order-name" className={spacing.spaceBelow8}>
                        {name} order
                    </h1>
                    {currentInvestInfo && <p>{currentInvestInfo}</p>}
                </div>
            )}
        </div>
    )
}

export default AutoInvestHeader
