import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Response} from '~/api/retail/types'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {useCurrentSubscriptionPlan} from '~/global/utils/subscription-hooks/subscriptionHooks'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Loading} from '~/global/widgets/loading/Loading'
import RecurringTile from '~/global/widgets/recurring-transaction-tile/RecurringTile'
import {useRecurringPayment} from '~/sections/OLD_wallet/sections/linked-bank-payments/state'
import {RecurringTopupTile} from '~/sections/OLD_wallet/sections/linked-bank-payments/widgets/recurring-topup-tile/RecurringTopupTile'
import {useAutoinvestOrders} from '~/sections/invest/sections/auto-invest/hooks/useAutoinvestOrders'
import AutoInvestTile from '~/sections/invest/sections/auto-invest/widgets/auto-invest-tile/AutoInvestTile'
import {useAppSelector} from '~/store/hooks'
import styles from './WalletUpcoming.scss'

export const WalletUpcoming: React.FunctionComponent = () => {
    const owner = useProfileOwner()
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)
    const recurringPayment = useRecurringPayment()
    const [currentSubscription] = useCurrentSubscriptionPlan()
    const [autoinvestOrders] = useAutoinvestOrders()

    const tiles: React.ReactNode[] = [
        recurringPayment && <RecurringTopupTile recurringPayment={recurringPayment} />,
        currentSubscription && owner.owner_type !== 'ORGANISATION' && (
            <SubscriptionTile currentSubscription={currentSubscription} />
        ),
        ...autoinvestOrders.map(order => <AutoInvestTile key={order.id} order={order} homeCurrency={homeCurrency} />),
    ].filter(Boolean)

    if (!tiles.length) {
        return (
            <div className={styles.noUpcoming}>
                <p>You don’t have any upcoming transactions</p>
            </div>
        )
    }

    return (
        <div className={styles.tiles}>
            {tiles.map((tile, index) => (
                <React.Fragment key={index}>{tile}</React.Fragment>
            ))}
        </div>
    )
}

export default () => (
    <React.Suspense fallback={<Loading />}>
        <WalletUpcoming />
    </React.Suspense>
)

const SubscriptionTile: React.FunctionComponent<{currentSubscription: Response.SubscriptionCurrentV2}> = ({
    currentSubscription,
}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    return (
        <RecurringTile
            dataTestId="recurring-tile-subscription"
            displayImage={<div className={styles.planDisplayImage}>{currentSubscription.plan.name}</div>}
            handleClick={() => navigate(profileUrl('settings/plan'))}
            title="Pricing plan"
            actionText="View"
            subtitle={currentSubscription.plan.name}
            nextDate={currentSubscription.expires}
            state="ACTIVE"
        />
    )
}
