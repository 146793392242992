import {Modal} from '@design-system/modal'
import React from 'react'

interface ExchangeRateChangeWalletErrorProps {
    onClick(): void
}

interface ExchangeRateChangeContinueError {
    onContinueClick(): void
    onChangeClick(): void
}

interface ExchangeRateXeUnreachableProps {
    onClick(): void
}

export const ExchangeRateChangeWalletError: React.FunctionComponent<ExchangeRateChangeWalletErrorProps> = ({
    onClick,
}) => (
    <Modal
        isAlert
        isOpen
        setIsOpen={onClick}
        title="Exchange rate changed"
        dataTestId="modal--exchange-rate-changed"
        primaryButton={{label: 'Change amount', onClick}}
    >
        <p>
            The exchange rate has changed and there isn’t enough money in your Wallet to place this order. You need to
            change the amount to be able to invest.
        </p>
    </Modal>
)

export const ExchangeRateChangeContinueError: React.FunctionComponent<ExchangeRateChangeContinueError> = ({
    onChangeClick,
    onContinueClick,
}) => (
    <Modal
        isOpen
        setIsOpen={onChangeClick}
        title="Exchange rate changed"
        dataTestId="modal--exchange-rate-changed-continue"
        primaryButton={{label: 'Continue', onClick: onContinueClick}}
        secondaryButton={{label: 'Change amount'}}
    >
        <p>
            The exchange rate has changed and the amount to invest has been updated. You can change the amount to invest
            or continue to review your order with the new rate.
        </p>
    </Modal>
)

export const ExchangeRateXeUnreachable: React.FunctionComponent<ExchangeRateXeUnreachableProps> = ({onClick}) => (
    <Modal
        isAlert
        isOpen
        setIsOpen={onClick}
        title="XE is down"
        dataTestId="modal--xe-down"
        primaryButton={{label: 'Okay', onClick}}
    >
        <p>
            XE, our currency exchange partner is down. This means we can’t exchange money right now and we can’t place
            your order. You'll have to try again later. Aroha mai.
        </p>
    </Modal>
)
