import {ContentCards, Card} from '@braze/web-sdk'
import {DateTime} from 'luxon'
import {Model, Request, Response} from '~/api/retail/types'

export interface State {
    readonly actor: User | null
    readonly applicationPointers: ApplicationPointer[]
    readonly canEnterAddressToken: boolean
    readonly canJoinKiwiSaver: NonNullable<Response.IdentityAuthenticated['can_join_kiwisaver']>
    readonly canSeeSaveWaitlist: Response.IdentityAuthenticated['can_see_save_waitlist']
    readonly canWriteUntil?: DateTime
    readonly citizenships: Response.SettingsCitizenship['countries']
    readonly customImages: {
        readonly image_id: string
        readonly image_url: string
    }[]
    readonly closeAccountCheck?: Response.IdentityCloseAccountCheck
    readonly distillToken: string | null
    readonly distillTokenV2: string | null
    readonly employmentInstrumentIds: string[]
    readonly feedback: {
        loadingState: 'uninitialised' | 'ready' | 'error'
        submittedActionIdentifiers: string[]
    }
    readonly flags: {
        readonly [flag: string]: boolean
    }
    readonly holdings: FundHolding[]
    readonly includeSoldInvestments: boolean
    readonly isEligibleForKiwisaver: NonNullable<Response.IdentityAuthenticated['is_eligible_for_kiwisaver']>
    readonly isInitialised: boolean
    readonly kidsAccountTransferTokenData?: Response.IdentityTokenData
    readonly kidsAccountTransferTokenError?: boolean
    readonly natureAndPurpose: Response.IdentityNatureAndPurpose | null
    readonly notificationPreferences: Response.IdentityNotificationPreferencesV2['categories']
    readonly notifications: {
        readonly notificationsLoaded: boolean
        readonly notificationsPageCards: ContentCards['cards']
        readonly notificationsPageUnviewedCount: number
        readonly loadFail: boolean
        readonly exploreCards: ContentCards['cards']
        readonly announcementCard?: Card
        readonly featureOnboardingCard?: Card
        readonly propensitiesCard?: Card
    }
    readonly onKiwiSaverWaitlist: NonNullable<Response.IdentityAuthenticated['on_kiwisaver_waitlist']>
    readonly orders: Order[]
    readonly otherParticipant?: string
    readonly participants: string[]
    readonly pendingOrdersNextCheck: number
    readonly pendingOrdersCurrentTimeoutId: NodeJS.Timeout | null
    readonly portfolioExtendedHoursPreference: Response.IdentityAuthenticated['portfolio_enable_extended_hours']
    readonly portfolioFilterPreference: Response.PortfolioFilterPreference['filter_preference']
    readonly portfolioSortPreference: Response.PortfolioSortPreference['sort_preference']
    readonly rakaiaToken: string | null
    readonly rakaiaTokenExpiry?: DateTime | null
    readonly referralCode?: string
    readonly saveAccounts: Response.IdentityAuthenticated['save_accounts']
    readonly searchResults: string[]
    readonly sharesightClientCredentials?: SharesightClientCredentials
    readonly sharesightGetIntegrationLoadingState: SharesightLoadingState
    readonly sharesightGetPortfoliosLoadingState: SharesightLoadingState
    readonly sharesightIntegrations: Response.SharesightIntegration['sharesight_integrations']
    readonly sharesightPortfolios?: Response.SharesightPortfolios['sharesight_portfolios']
    readonly switchingTo: Model.CustomerSummary | null
    readonly user: User | null
    readonly userList: Model.CustomerSummary[]
}

export type ApplicationPointer = Response.IdentityAuthenticated['application_pointers'][number]
export type FundHolding = Response.IdentityAuthenticated['holdings'][0]
export type Order = Response.IdentityAuthenticated['orders'][0]
export type PIR = Request.IdentityUpdateFinancialDetails['pir']
export type RWT = Request.IdentityUpdateFinancialDetails['rwt']
export type SharesightIntegrations = Response.SharesightIntegration['sharesight_integrations']
export type SharesightLoadingState = 'ready' | 'loading' | 'error'
export type SharesightPortfolios = Response.SharesightPortfolios['sharesight_portfolios']
export type User = Model.User

export interface SharesightClientCredentials {
    clientId: string
    redirectUri: string
}

export interface PortfolioItem {
    instrumentId: string
    holding?: FundHolding
    buyCurrencyTotal: number
    buySharesTotal: number
    sellTotal: number
    transferTotal: number
    sharesNotAvailableForSell: number
}
export interface PortfolioItemsMap {
    [instrumentId: string]: PortfolioItem
}

export interface AddressComponents {
    readonly street_number: string
    readonly route: string
    sublocality?: string
    readonly locality: string
    state_code?: string
    readonly postal_code: string
    readonly country_code: string
}

export enum SourceOfInvalidPreferredName {
    ACTIVE_CUSTOMER = 'active_customer',
    SIGN_UP = 'sign_up',
}

export interface PortfolioFilters {
    readonly COUNTRY: 'COUNTRY'
    readonly RISK: 'RISK'
    readonly TYPE: 'TYPE'
}
export type PortfolioFilterOptions = PortfolioFilters['COUNTRY'] | PortfolioFilters['RISK'] | PortfolioFilters['TYPE']

export interface PortfolioSorts {
    readonly ALPHABETICAL: 'ALPHABETICAL'
    readonly HIGHEST_RETURNS: 'HIGHEST_RETURNS'
    readonly LOWEST_RETURNS: 'LOWEST_RETURNS'
    readonly HIGHEST_VALUE: 'HIGHEST_VALUE'
    readonly LOWEST_VALUE: 'LOWEST_VALUE'
}
export type PortfolioSortOptions =
    | PortfolioSorts['ALPHABETICAL']
    | PortfolioSorts['HIGHEST_RETURNS']
    | PortfolioSorts['LOWEST_RETURNS']
    | PortfolioSorts['HIGHEST_VALUE']
    | PortfolioSorts['LOWEST_VALUE']

export interface ImageUpload {
    readonly image_id: string
    readonly image_url: string
}

// Because we now have content card types that aren't displayed on notifications page, we need
// to calculate unviewedCount ourselves, excluding
export const notificationPageContentCardCategories = [
    'ad_hoc', // Any that might come out of Customer Lifecycle as required (ie not api-triggered from Retail)
    'corporate_action_dividend_cash_payout',
    'dividend_reinvestment_plan_available',
    'price_notification',
    'stoploss_expiry_reminder',
    'stoploss_order_triggered',
    'trigger_buy_expired',
    'account_updates',
]
