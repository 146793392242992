import React from 'react'
import {useNavigate} from 'react-router'
import {Response} from '~/api/retail/types'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ActivityCard} from '~/sections/moose/sections/instrument/widgets/activity-card/ActivityCard'

const getIcon = (title: string) => (title === 'BUY' ? 'OrderBuy' : 'OrderSell')

export const stateSuffix = (state: string) => {
    return ['PENDING', 'PROCESSING'].includes(state) ? ' - pending' : ''
}

export const getTitle = (side: string) => {
    return side === 'BUY' ? 'Buy order' : 'Sell order'
}
const InvestingActivityCard: React.FunctionComponent<{
    portfolioId: string
    activity: Response.PortfolioOrders['orders'][0]
}> = ({portfolioId, activity}) => {
    const profileUrl = useProfileUrl()
    const navigate = useNavigate()
    const title = getTitle(activity.side) + stateSuffix(activity.state)

    return (
        <ActivityCard
            key={activity.id}
            icon={getIcon(activity.side)}
            title={title}
            description=""
            date={activity.date}
            amount={activity.amount}
            onClick={() =>
                navigate(
                    profileUrl('fonterra/portfolios/:portfolioId/investing-activity/:investmentActivityKey', {
                        portfolioId,
                        investmentActivityKey: activity.key,
                    }),
                )
            }
        />
    )
}

export default InvestingActivityCard
