import cn from 'classnames'
import React from 'react'
import detailModalStyles from '~/global/widgets/detail-modals/DetailModal.scss'
import {DollarValue, PercentValue} from '~/global/widgets/number-elements/NumberElements'
import {ReturnCalculationInfo} from './TotalReturnModal'

interface SimpleReturnModalProps {
    costBasis: number
    costBasisMax: number
    includeSoldInvestments: boolean
    isDependent: boolean
    preferredName: string
    realisedCostBasis: number
    simpleReturn: number
    totalReturn: number
    unrealisedTotalReturn: number
    unrealisedSimpleReturn: number
}

const SimpleReturnModal: React.FunctionComponent<SimpleReturnModalProps> = ({
    costBasis,
    costBasisMax,
    includeSoldInvestments,
    isDependent,
    preferredName,
    realisedCostBasis,
    simpleReturn,
    totalReturn,
    unrealisedTotalReturn,
    unrealisedSimpleReturn,
}) => {
    // If the includeSoldInvestments flag is true, and there has been a sell order,
    // we should display the text referring to investments having been sold.
    // Otherwise, we should show the regular/default wording.

    const hasHadSellOrder = realisedCostBasis !== 0
    const possessive = isDependent ? `${preferredName}’s` : 'your'
    const possessivePronoun = isDependent ? 'their' : 'your'
    const customerHas = isDependent ? 'they’ve' : 'you’ve'

    return (
        <div>
            <h1>Simple return</h1>
            <p>
                There are many ways to work out returns. These calculations should not be relied upon to make investment
                decisions, they are indicative only and accuracy is not guaranteed.
            </p>
            <p>Past performance does not guarantee future results.</p>
            <p>
                {isDependent ? `${preferredName}’s` : 'Your'} simple return is a measure of {possessivePronoun}{' '}
                Portfolio’s performance over time as a percentage.
            </p>
            <p>
                This calculation {!includeSoldInvestments && ' only'} includes investments{' '}
                {isDependent ? 'they' : 'you'} currently own
                {includeSoldInvestments && ` and any ${customerHas} sold`}.
            </p>
            {includeSoldInvestments && hasHadSellOrder ? (
                <p>
                    As {customerHas} sold some investments, we calculate {possessivePronoun} simple return by dividing{' '}
                    {possessivePronoun} Portfolio’s total return by the largest amount {customerHas} previously had
                    invested.
                </p>
            ) : (
                <p>
                    To calculate {possessive} simple return, we divide {possessivePronoun} investments’{' '}
                    {includeSoldInvestments && ' total'} return by the amount put in.
                </p>
            )}
            <p>The result is multiplied by 100 to give {possessive} Portfolio’s simple return as a percentage.</p>
            <div className={cn(detailModalStyles.calcBox, detailModalStyles.boldTextBox)}>
                ({includeSoldInvestments ? 'Total return' : 'Return'} ÷{' '}
                {includeSoldInvestments && hasHadSellOrder
                    ? `largest amount ${customerHas} had invested`
                    : 'amount put in'}
                ) x 100 = Simple return %
            </div>

            <p>The simple return calculation for {possessive} Portfolio is:</p>
            <div className={cn(detailModalStyles.calcBox, detailModalStyles.boldTextBox)}>
                (<DollarValue value={includeSoldInvestments ? totalReturn : unrealisedTotalReturn} /> &divide;{' '}
                <DollarValue value={includeSoldInvestments ? costBasisMax : costBasis} />) x 100 ={' '}
                <PercentValue value={includeSoldInvestments ? simpleReturn : unrealisedSimpleReturn} />
            </div>
            <ReturnCalculationInfo />
        </div>
    )
}

export default SimpleReturnModal
