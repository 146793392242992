import {Request, Response} from '~/api/retail/types'
import {StagedSignUp} from '~/sections/moose/sections/sign-up/state'

export const constructIdentityDetail = (
    email: string,
    stagedSignUp: StagedSignUp,
    signupEntityDetail: Response.FonterraSignUpEntityDetail,
): Request.FonterraSignUpLinkAccount['identity'] => {
    const {first_name, middle_name, last_name, date_of_birth, address, phone} = signupEntityDetail

    return {
        email,
        first_name,
        middle_name,
        last_name,
        date_of_birth,
        address,
        phone,
        tc_version: stagedSignUp.tcVersion as number,
    }
}
