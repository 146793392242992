import React from 'react'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import transferActions from '~/store/transfer/actions'
import {TransferAllForm} from './widgets/transfer-all-form/TransferAllForm'

export type transferAllChoice = 'yes' | 'no'

export const TransferAll: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const {stagedTransferOrder} = useAppSelector(({transfer}) => transfer)

    if (!stagedTransferOrder || !stagedTransferOrder.direction) {
        navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})
        return <></>
    }

    const handleSuccess = (transferAll: transferAllChoice) => {
        switch (transferAll) {
            case 'yes':
                // End of the flow for NZX: if they want to transfer everything in
                // then send them to the confirmation page
                dispatch(
                    transferActions.SetStagedTransferOrder({
                        ...stagedTransferOrder,
                        transferEntirePortfolio: true,
                        instruments: [],
                    }),
                )
                navigate(profileUrl('invest/portfolio-transfer-shares/nzx/confirm'))
                break
            case 'no':
                // If they don't want to transfer in all their holdings then we send them
                // to the instrument selection
                dispatch(
                    transferActions.SetStagedTransferOrder({
                        ...stagedTransferOrder,
                        transferEntirePortfolio: false,
                    }),
                )
                navigate(profileUrl('invest/portfolio-transfer-shares/:exchange/select', {exchange: 'nzx'}))
                break
            default:
                assertNever(transferAll)
        }
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--transfer-all" leftButton="back" />
            <TransferAllForm handleSuccess={handleSuccess} />
        </>
    )
}

export default TransferAll
