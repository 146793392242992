import {useProfileOrNull} from '~/global/state-hooks/retail/useProfile'

/**
 * Check if the currently selected profile has a Fonterra portfolio. Only the
 * current portfolio is checked. Must be used within a profile context (i.e.
 * when authenticated) to determine an answer, otherwise will be undefined.
 *
 * @example
 * const hasFonterraPortfolio = useHasFonterraPortfolio()
 * @returns {boolean | undefined} True if the current profile has a Fonterra
 * portfolio.
 */
export const useHasFonterraPortfolio = () => {
    const profile = useProfileOrNull()
    return profile?.portfolios.some(portfolio => portfolio.product === 'FONTERRA')
}
