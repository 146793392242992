import {Button} from '@design-system/button'
import cn from 'classnames'
import {FormikErrors, withFormik} from 'formik'
import React from 'react'
import {DistillScope} from '~/api/query/distill'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {page} from '~/global/scss/helpers'
import {exceedsLowPricedSecurityCap} from '~/global/utils/exceeds-low-priced-security-cap/exceedsLowPricedSecurityCap'
import {
    isInstrumentEligibleForMarketSellExtendedHours,
    isNotionalStatusInstrument,
} from '~/global/utils/is-instrument-eligible-for-extended-hours/isInstrumentEligibleForExtendedHours'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import {Units} from '~/global/widgets/OLD_icons'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import WalletBalanceWrapper from '~/global/widgets/estimated-wallet-balance/WalletBalanceWrapper'
import {Checkbox, StrongNumber} from '~/global/widgets/form-controls/formik'
import PieFundProcessingTime, {DELAYED_PIE_FUNDS} from '~/global/widgets/help-modals/PieFundProcessingTime'
import {Loading} from '~/global/widgets/loading/Loading'
import {ShareValue} from '~/global/widgets/number-elements/NumberElements'
import {isNavigationDirective} from '~/migrate-react-router'
import styles from '~/sections/invest/sections/order-flow/OrderForm.scss'
import {calculateSharesRemaining} from '~/sections/invest/sections/order-flow/sections/sell/utils/calculate-shares-remaining/calculateSharesRemaining'
import {ExtendedHoursModal} from '~/sections/invest/sections/order-flow/widgets/modals/ExtendedHoursModal'
import {State as AccountingState} from '~/store/accounting/types'
import {connect} from '~/store/connect'
import {useAppDispatch} from '~/store/hooks'
import {FundHolding} from '~/store/identity/types'
import instrumentActions from '~/store/instrument/actions'
import {selectIsInstrumentInExtendedHours} from '~/store/instrument/selectors'
import {Instrument} from '~/store/instrument/types'
import actions from '~/store/order/actions'
import {StagedSellOrder, State as OrderState} from '~/store/order/types'
import {Dispatch, UnwrapThunkAction} from '~/store/types'

type MarketSellInSharesFormErrors = FormikErrors<MarketSellInSharesFormValues>

interface MarketSellInSharesFormValues {
    shareAmount: string
    extendedHours?: boolean
}

const MarketSellInSharesForm = withRouter(
    withFormik<MarketSellInSharesFormProps & WithRouterProps, MarketSellInSharesFormValues>({
        mapPropsToValues: ({stagedSellOrder, extendedHoursApplies}) => {
            if (!stagedSellOrder) {
                return {
                    shareAmount: '',
                    extendedHours: extendedHoursApplies,
                }
            }
            return {
                shareAmount:
                    stagedSellOrder.orderShareAmount && parseFloat(stagedSellOrder.orderShareAmount) > 0
                        ? stagedSellOrder.orderShareAmount
                        : '',
                extendedHours: stagedSellOrder.idempotencyKey ? !!stagedSellOrder.extendedHours : extendedHoursApplies, // if we got as far as confirm previously, use the stored value, otherwise default to on if applicable
            }
        },
        mapPropsToErrors: ({stagedSellOrder}) => {
            // make the button disabled initially if there is an initial error by setting at least one field to have an error
            if (
                !stagedSellOrder ||
                !stagedSellOrder.orderShareAmount ||
                parseFloat(stagedSellOrder.orderShareAmount) === 0
            ) {
                return {shareAmount: undefined}
            }
            return {}
        },
        validate: (values, {instrument, holding, stagedSellOrder}) => {
            const errors: MarketSellInSharesFormErrors = {}
            const ksOrInvestHolding = stagedSellOrder?.ksFundHolding ?? holding
            // ksFundHolding is active number of shares so there are no shares not available for sale for KiwiSaver
            const sharesNotAvailableForSell = holding
                ? Math.max(Number(holding?.shares) - Number(holding?.shares_active), 0)
                : 0

            if (!values.shareAmount || Number(values.shareAmount) === 0) {
                errors.shareAmount = '' // we don't actually display an error
            } else if (
                ksOrInvestHolding &&
                parseFloat(values.shareAmount) > parseFloat(ksOrInvestHolding.shares) - sharesNotAvailableForSell
            ) {
                // test if the supplied value is more than the amount available to sell
                {
                    errors.shareAmount = `You’ve entered more ${shareLabel({
                        instrument,
                        isPlural: true,
                    })} than you own. Try again with a lower number.`
                }
            }

            const {exceedsLimit, msg} = exceedsLowPricedSecurityCap(
                instrument,
                values.shareAmount,
                undefined,
                undefined,
                undefined,
            )

            if (exceedsLimit && msg) {
                errors.shareAmount = msg
            }

            return errors
        },
        handleSubmit: async (
            {shareAmount, extendedHours},
            {
                setSubmitting,
                props: {
                    stagedSellOrder,
                    updateStagedSellOrder,
                    costStagedSellOrder,
                    router: {navigate},
                    profileUrl,
                },
            },
        ) => {
            if (!stagedSellOrder) {
                return
            }

            rudderTrack('sell', 'order_details_entered', {instrument_id: stagedSellOrder.fundId})

            setSubmitting(true)
            try {
                updateStagedSellOrder({
                    ...stagedSellOrder,
                    orderShareAmount: shareAmount,
                    extendedHours,
                })

                const response = await costStagedSellOrder()
                if (isNavigationDirective(response)) {
                    response.execute(navigate, profileUrl)
                }
            } catch (error) {
                setSubmitting(false)
            }
        },
    })(
        ({
            values,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            instrument,
            holding,
            updateStagedSellOrder,
            stagedSellOrder,
            exchangeRates,
            setFieldValue,
            setSubmitting,
            sellOrderAcceptableDP,
            getSellOrderAcceptableDP,
            sellOrderAcceptableDPLoadingState,
            accountRestricted,
            extendedHoursApplies,
            instrumentIsInExtendedHours,
            instrumenthasNotionalStatus,
        }) => {
            const [showPieFundModal, setShowPieFundModal] = React.useState<boolean>(false)
            const [showExtendedHoursModal, setShowExtendedHoursModal] = React.useState<boolean>(false)
            const ksOrInvestHolding = stagedSellOrder?.ksFundHolding ?? holding
            const dispatch = useAppDispatch()

            // ksFundHolding is active number of shares so there are no shares not available for sale for KiwiSaver
            const sharesNotAvailableForSell = holding
                ? Math.max(Number(holding?.shares) - Number(holding?.shares_active), 0)
                : 0

            React.useEffect(() => {
                if (instrument && stagedSellOrder) {
                    // storing kiwisaver wrap fund and underlying fund to store
                    upliftKiwiSaverUnderlyingFundData(stagedSellOrder.fundId, instrument, dispatch)
                }
            }, [instrument, stagedSellOrder])

            const totalSharesAvailableToSell = ksOrInvestHolding
                ? parseFloat(calculateSharesRemaining('0', ksOrInvestHolding.shares, sharesNotAvailableForSell))
                : 0

            React.useEffect(() => {
                if (sellOrderAcceptableDPLoadingState === 'ready') {
                    getSellOrderAcceptableDP()
                }
            }, [])

            React.useEffect(() => {
                if (stagedSellOrder && stagedSellOrder.error) {
                    setSubmitting(false)
                }
            }, [stagedSellOrder])

            const formattedSharesRemaining = React.useMemo(() => {
                if (!ksOrInvestHolding) {
                    return '-'
                }

                const sharesRemaining = values.shareAmount
                    ? calculateSharesRemaining(values.shareAmount, ksOrInvestHolding.shares, sharesNotAvailableForSell)
                    : calculateSharesRemaining('0', ksOrInvestHolding.shares, sharesNotAvailableForSell)

                return <ShareValue value={sharesRemaining} showFullValue />
            }, [holding, stagedSellOrder?.ksFundHolding, values, instrument])

            const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault()
                if (values.extendedHours) {
                    // US shares extended hours - if enabled show warning before proceeeding to submit
                    setShowExtendedHoursModal(true)
                } else if (DELAYED_PIE_FUNDS.includes(instrument.symbol)) {
                    // Pie funds processing time is way longer than any other funds normally take.
                    // Every time when a user buy or sells any of the Pie funds, we're displaying a
                    // special help modal before we redirect them to the buy or sell confirm page.
                    // These are managed funds, so no need to do this for limit orders.
                    setShowPieFundModal(true)
                } else {
                    handleSubmit(e)
                }
            }

            const preventSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault()
                }
            }

            const handleOnBlur = (event: string | React.FocusEvent<unknown>) => {
                if (typeof event === 'string') {
                    return
                }
                handleBlur(event)

                if (!stagedSellOrder) {
                    return
                }

                // TODO: [DS-636] clean up this logic
                // Hack to prevent loss of focus on the input field while the component re-renders
                setTimeout(() => {
                    // store any user entered values immediately so the user can look at
                    // the market depth page without losing data
                    updateStagedSellOrder({
                        ...stagedSellOrder,
                        orderShareAmount: values.shareAmount,
                        extendedHours: values.extendedHours,
                    })
                }, 0)
            }

            if (sellOrderAcceptableDPLoadingState === 'loading') {
                return <Loading isPineapple />
            }

            if (sellOrderAcceptableDPLoadingState === 'error') {
                return <WeSlippedUp />
            }

            const reviewButton = (
                <Button
                    label="Review"
                    disabled={!isValid || accountRestricted}
                    dataTestId="button--review"
                    isSubmit
                    processing={isSubmitting}
                />
            )
            return (
                <form onSubmit={onSubmit}>
                    {showPieFundModal && (
                        <PieFundProcessingTime
                            instrument={instrument}
                            onClick={() => {
                                setShowPieFundModal(false)
                                handleSubmit()
                            }}
                            onClose={() => setShowPieFundModal(false)}
                        />
                    )}

                    {showExtendedHoursModal && (
                        <ExtendedHoursModal
                            orderType={stagedSellOrder?.orderType}
                            onContinue={() => {
                                setShowExtendedHoursModal(false)
                                handleSubmit()
                            }}
                            onClose={() => setShowExtendedHoursModal(false)}
                        />
                    )}

                    <StrongNumber
                        dataTestId="strong-number--share-amount"
                        name="shareAmount"
                        label={`Number of ${shareLabel({instrument, isPlural: true})} to sell`}
                        disabled={isSubmitting || accountRestricted}
                        autoFocus
                        optionalAttributes={{
                            onKeyPress: preventSubmit,
                            onBlur: handleOnBlur,
                        }}
                        placeholder={shareLabel({instrument, isPlural: true, isCapitalised: true})}
                        normalisation="decimalOnly"
                        decimalPlaces={
                            sellOrderAcceptableDP ? parseFloat(sellOrderAcceptableDP.acceptableDP) : undefined
                        }
                        helpText={
                            <p className={styles.sharesRemaining}>
                                <Units /> {shareLabel({instrument, isPlural: true, isCapitalised: true})} remaining:{' '}
                                <strong>{formattedSharesRemaining}</strong>
                                {ksOrInvestHolding &&
                                    (!values.shareAmount ||
                                        parseFloat(values.shareAmount) < totalSharesAvailableToSell) && (
                                        <ButtonAsLink
                                            className={styles.linkButton}
                                            onClick={() =>
                                                setFieldValue('shareAmount', totalSharesAvailableToSell.toString())
                                            }
                                        >
                                            Sell all
                                        </ButtonAsLink>
                                    )}
                            </p>
                        }
                    />

                    {extendedHoursApplies && (
                        <div className={styles.extendedHoursCheckbox}>
                            <Checkbox
                                dataTestId="checkbox--extended-hours"
                                name="extendedHours"
                                label="Allow order to fill during extended hours"
                                helpText="When selected, all or some of your order may fill during extended hours. If unselected, it’ll only fill during regular market hours."
                                additionalClassName={styles.checkbox}
                            />
                        </div>
                    )}

                    {instrumentIsInExtendedHours && !instrumenthasNotionalStatus && (
                        <AlertCard type="info">
                            <p>Market sell orders for this investment only fill during regular market hours.</p>
                        </AlertCard>
                    )}

                    {stagedSellOrder?.ksFundHolding ? (
                        <div className={styles.ksReviewButtonRow}>
                            {/*
                                KS sell flow with slightly different design
                                it's not required to display wallet balance and action bar
                             */}
                            {reviewButton}
                        </div>
                    ) : (
                        <ActionBar className={cn(page.flexRow, styles.formFooter)}>
                            <WalletBalanceWrapper displayCurrency={instrument.currency} exchangeRates={exchangeRates} />
                            {reviewButton}
                        </ActionBar>
                    )}
                </form>
            )
        },
    ),
)

interface StoreProps {
    holding?: FundHolding
    stagedSellOrder?: StagedSellOrder
    sellOrderAcceptableDP?: OrderState['sellOrderAcceptableDP']
    sellOrderAcceptableDPLoadingState: OrderState['sellOrderAcceptableDPLoadingState']
    accountRestricted: boolean
    extendedHoursApplies: boolean
    instrumentIsInExtendedHours: boolean
    instrumenthasNotionalStatus: boolean
}

interface OwnProps {
    instrument: Instrument
    exchangeRates: AccountingState['exchangeRates']
    profileUrl: ReturnType<typeof useProfileUrl>
}

interface DispatchProps {
    updateStagedSellOrder(order: StagedSellOrder): void
    costStagedSellOrder: UnwrapThunkAction<typeof actions.CostSellOrder>
    getSellOrderAcceptableDP(): void
}

type MarketSellInSharesFormProps = StoreProps & DispatchProps & OwnProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    (state, {instrument}) => {
        const {order, identity} = state

        return {
            stagedSellOrder: order.stagedSellOrder,
            holding: identity.holdings.find(holding => holding.fund_id === instrument.id),
            sellOrderAcceptableDP: order.sellOrderAcceptableDP,
            sellOrderAcceptableDPLoadingState: order.sellOrderAcceptableDPLoadingState,
            accountRestricted: identity.user!.account_restricted,
            extendedHoursApplies:
                identity.portfolioExtendedHoursPreference && isInstrumentEligibleForMarketSellExtendedHours(instrument),
            instrumentIsInExtendedHours: !!selectIsInstrumentInExtendedHours(state, instrument),
            instrumenthasNotionalStatus: isNotionalStatusInstrument(instrument),
        }
    },
    dispatch => ({
        updateStagedSellOrder: order => dispatch(actions.UpdateStagedSellOrder(order)),
        costStagedSellOrder: () => dispatch(actions.CostSellOrder()),
        getSellOrderAcceptableDP: () => dispatch(actions.GetSellOrderAcceptableDP()),
    }),
)(MarketSellInSharesForm)

const upliftKiwiSaverUnderlyingFundData = (fundId: string, instrument: Instrument, dispatch: Dispatch) => {
    // Firstly we store the wrapper fund into instrument reducer
    // Once we got it then we store the underlying instrument
    // then we swap out the ID of this instrument to its wrapper fund.
    if (instrument && instrument.underlyingInstrumentId) {
        // set wrapper fund
        dispatch(instrumentActions.getV2SingleInstrumentById(fundId, DistillScope.KIWISAVER_ALL_FUNDS))
        // set underlying fund
        dispatch(
            instrumentActions.getV2SingleInstrumentById(
                instrument.underlyingInstrumentId,
                DistillScope.KIWISAVER_ALL_FUNDS,
                true,
            ),
        )
    }
}
