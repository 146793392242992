import {colour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import * as d3interpolate from 'd3-interpolate'
import * as d3scale from 'd3-scale'
import * as d3shape from 'd3-shape'
import {DateTime} from 'luxon'
import React from 'react'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {
    sliceInstrumentHistory as sliceHistory,
    InstrumentInvestingHistoryWithIndex,
} from '~/global/utils/slice-history/sliceHistory'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import withMeasure, {MeasureProps} from '~/global/widgets/with-measure/WithMeasure'
import {InstrumentInvestingHistoryDetails, InstrumentInvestingHistory} from '~/store/portfolio/types'
import {getValueRange, useHover} from './instrumentChartCommon'
import styles from './InstrumentContributionChart.scss'

interface Props {
    startDate: string
    currentHistory: InstrumentInvestingHistory
}

const formatNumber = (value: number, maxValue: number) =>
    +maxValue.toFixed(3) >= 100 ? Math.round(value).toLocaleString() : value.toFixed(3)

// Calculate a set of values and scales for rendering the chart. The input is
// the width/height in pixels and the instrumentHistory object.
const calculateChartDimensions = (
    currentHistory: InstrumentInvestingHistoryDetails[],
    width: number,
    height: number,
    startDate: string,
) => {
    const {minValue, maxValue} = getValueRange(currentHistory)

    const maxValueDollarFormat = formatNumber(maxValue, maxValue)
    const minValueDollarFormat = formatNumber(minValue, maxValue)

    const axisLabelWidth = 50

    // These scales are used to map values from the instrumentHistory list to actual
    // pixels on the screen.

    // Conceptually there are 2 coordinate systems while rendering the charts.
    // (a) There's the actual x/y coordinates of pixels on the screen (which is what the SVG is rendering)
    // (b) There's the currentHistory range. The Y value ranges from minValue to
    // maxValue, the X coordinate ranges from 0 (the first currentHistory item) to
    // currentHistory.length - 1 (the last currentHistory item)
    let xMinValue = 0
    if (currentHistory.length && currentHistory[0].date !== startDate) {
        xMinValue = -Math.round(
            (DateTime.fromISO(currentHistory[0].date).diff(DateTime.fromISO(startDate)).as('days') * 5) / 7,
        )
    }
    const xScale = d3scale
        .scaleLinear()
        .domain([xMinValue, currentHistory.length - 1])
        .range([axisLabelWidth, width])
    const xHoverScale = d3scale
        .scaleLinear()
        .domain([xMinValue, currentHistory.length - 1])
        .range([axisLabelWidth, width])
        .interpolate(d3interpolate.interpolateRound)

    const yScale = d3scale
        .scaleLinear()
        .domain([minValue, maxValue])
        .range([height - 20, 90])

    return {xScale, xHoverScale, yScale, minValue, maxValue, axisLabelWidth, maxValueDollarFormat, minValueDollarFormat}
}

const InstrumentContributionChart: React.FC<Props & MeasureProps> = React.memo(
    ({measuredWidth, measuredHeight, currentHistory, startDate, onMeasureRef, measuredLeft}) => {
        const colourMode = useColourMode()
        // We only want the portion of instrumentHistory we need to render the selected period
        const instrumentHistory = React.useMemo(
            () => sliceHistory(currentHistory.history, startDate),
            [currentHistory, startDate],
        )

        // width/height are supplied by the withMeasure() higher order component
        // which measures the on-screen size of the <div /> container.
        const width = measuredWidth || 0
        const height = measuredHeight || 0
        const viewBox = `0 0 ${width} ${height}`

        const {
            xScale,
            xHoverScale,
            yScale,
            minValue,
            maxValue,
            axisLabelWidth,
            minValueDollarFormat,
            maxValueDollarFormat,
        } = React.useMemo(
            () => calculateChartDimensions(instrumentHistory, width, height, startDate),
            [instrumentHistory, width, height, startDate],
        )

        // These path functions are mechanisms for d3 that can map a set of
        // instrumentHistory to a particular line/shape on the chart
        const contributionLinePath = React.useMemo(
            () =>
                d3shape
                    .line<InstrumentInvestingHistoryWithIndex>()
                    .x(history => xScale(history.index))
                    .y(history => yScale(history.cost_basis))
                    .curve(d3shape.curveStep),
            [xScale, yScale],
        )

        const marketValueLinePath = React.useMemo(
            () =>
                d3shape
                    .line<InstrumentInvestingHistoryWithIndex>()
                    .x(history => xScale(history.index))
                    .y(history => yScale(history.investment_value || 0))
                    .curve(d3shape.curveStep),
            [xScale, yScale],
        )

        const areaPath = React.useMemo(
            () =>
                d3shape
                    .area<InstrumentInvestingHistoryWithIndex>()
                    .x(history => xScale(history.index))
                    .y1(history => yScale(history.investment_value || 0))
                    .y0(height)
                    .curve(d3shape.curveStep),
            [xScale, yScale],
        )

        const minValueLine = yScale(minValue)
        const midValue = minValue + (maxValue - minValue) / 2
        const midValueLine = yScale(midValue)
        const maxValueLine = yScale(maxValue)

        const leftLabel = DateTime.fromISO(startDate).toFormat(dateFormatNoTime)

        const {hoverDay, handlerProps} = useHover(measuredLeft || 0, xScale, instrumentHistory.length - 1)

        const hoverDayValue = instrumentHistory[hoverDay]

        const renderedAreaPath = React.useMemo(() => areaPath(instrumentHistory), [instrumentHistory, areaPath])
        const renderedMarketValueLinePath = React.useMemo(
            () => marketValueLinePath(instrumentHistory),
            [instrumentHistory, marketValueLinePath],
        )
        const renderedContributionLinePath = React.useMemo(
            () => contributionLinePath(instrumentHistory),
            [instrumentHistory, contributionLinePath],
        )

        const renderHover = (): React.ReactNode => {
            if (!hoverDayValue) {
                return
            }

            const x = xHoverScale(hoverDay)

            const labelWidth = 84
            const labelOverrun = 8
            let labelX = x - labelWidth / 2

            if (labelX < -labelOverrun) {
                labelX = -labelOverrun
            }

            if (labelX + labelWidth > (measuredWidth || 0) + labelOverrun) {
                labelX = (measuredWidth || 0) - labelWidth + labelOverrun
            }

            const hoverClass = styles.box

            const arrowY = 17

            const dateString = DateTime.fromISO(hoverDayValue.date).toFormat(dateFormatNoTime)

            return (
                <div className={styles.hover} style={{height: measuredHeight}}>
                    <svg
                        className={styles.line}
                        style={{
                            width: 1,
                            height: (measuredHeight || 0) - 14 - arrowY - 37,
                            transform: `translate(${x}px, ${arrowY + 37}px)`,
                        }}
                    />

                    {hoverDayValue.total_return >= 0 ? (
                        <div className={hoverClass} style={{transform: `translateX(${labelX}px)`}}>
                            <div>
                                <DollarValue value={hoverDayValue.total_return} explicitPlus color />
                            </div>
                            <div>
                                <DollarValue value={hoverDayValue.cost_basis} />
                            </div>
                            <div>{dateString}</div>
                        </div>
                    ) : (
                        <div className={hoverClass} style={{transform: `translateX(${labelX}px)`}}>
                            <div>
                                <DollarValue value={hoverDayValue.cost_basis} />
                            </div>
                            <div>
                                <DollarValue value={hoverDayValue.total_return} explicitPlus color />
                            </div>
                            <div>{dateString}</div>
                        </div>
                    )}

                    <svg
                        className={styles.arrow}
                        style={{width: 9, height: 9, transform: `translate(${x - 4}px, ${arrowY}px)`}}
                    >
                        <path d="M 0 0 V 4.5 L 4.5 8.5 L 8.5 4.5 V 0 Z" />
                        <path d="M 0 4.5 L 4.5 8.5 L 8.5 4.5" />
                    </svg>
                </div>
            )
        }

        return (
            <div className={styles.container} ref={onMeasureRef} {...handlerProps}>
                <svg viewBox={viewBox}>
                    <linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="1">
                        <stop offset="10%" stopColor={colour('Melon400', colourMode)} stopOpacity="0.7" />
                        <stop offset="100%" stopColor={colour('Melon50', colourMode)} stopOpacity="0.7" />
                    </linearGradient>

                    {isFinite(minValue) && isFinite(maxValue) && (
                        <>
                            <line
                                className={styles.centerLine}
                                x1={axisLabelWidth}
                                y1={minValueLine}
                                x2={width}
                                y2={minValueLine}
                            />
                            <text x="0" y={minValueLine} className={styles.price}>
                                ${minValueDollarFormat}
                            </text>
                            <line
                                className={styles.centerLine}
                                x1={axisLabelWidth}
                                y1={midValueLine}
                                x2={width}
                                y2={midValueLine}
                            />
                            <text x="0" y={midValueLine} className={styles.price}>
                                ${formatNumber(midValue, maxValue)}
                            </text>
                            <line
                                className={styles.centerLine}
                                x1={axisLabelWidth}
                                y1={maxValueLine}
                                x2={width}
                                y2={maxValueLine}
                            />
                            <text x="0" y={maxValueLine} className={styles.price}>
                                ${maxValueDollarFormat}
                            </text>

                            <path d={renderedAreaPath || ''} fill="url(#Gradient2)" />
                            <path className={styles.marketLine} d={renderedMarketValueLinePath || undefined} />
                            <path className={styles.contributionLine} d={renderedContributionLinePath || ''} />
                        </>
                    )}
                </svg>
                <div className={styles.leftDate}>{leftLabel}</div>
                <div className={styles.rightDate}>Today</div>
                {renderHover()}
            </div>
        )
    },
)

export default withMeasure<Props & MeasureProps>(InstrumentContributionChart)
