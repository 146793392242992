import cn from 'classnames'
import Decimal from 'decimal.js'
import {DateTime} from 'luxon'
import React from 'react'
import {useNavigate} from 'react-router'
import config from '~/configForEnv'
import {getMostRecentClosePrice} from '~/global/utils/share-transfers/shareTransfers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {Loading} from '~/global/widgets/loading/Loading'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/invest/sections/transfer-shares/pages/transfer-shares-details/TransferSharesDetails.scss'
import AddTransferDetailsForm from '~/sections/invest/sections/transfer-shares/pages/transfer-shares-details/widgets/add-transfer-details-form/AddTransferDetailsForm'
import AddTransferInParcelsForm from '~/sections/invest/sections/transfer-shares/pages/transfer-shares-details/widgets/add-transfer-in-parcels-form/AddTransferInParcelsForm'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {portfolioItems} from '~/store/identity/selectors'
import instrumentActions from '~/store/instrument/actions'
import {getInstrumentFromSlug} from '~/store/instrument/selectors'
import actions from '~/store/transfer/actions'
import {TransferOrderInstrument} from '~/store/transfer/types'

interface OwnProps {
    instrumentSlug: string
}

export const TransferSharesDetails: React.FunctionComponent<OwnProps> = ({instrumentSlug}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const addStagedTransferOrderDetails = (details: TransferOrderInstrument) =>
        dispatch(actions.AddStagedTransferOrderDetails(details))

    const getPriceHistoryByInstrumentId = (instrumentId: string, startDate: DateTime) =>
        dispatch(instrumentActions.getPriceHistoryByInstrumentId(instrumentId, startDate))

    const auCustomer = useAppSelector(({identity}) => identity.user!.jurisdiction === 'au')

    const instrument = useAppSelector(state => getInstrumentFromSlug(state, instrumentSlug))
    const {
        direction,
        exchange,
        instruments: stagedOrderInstruments,
    } = useAppSelector(({transfer}) => transfer.stagedTransferOrder!)
    const portfolioItemsMap = useAppSelector(s => portfolioItems(s))
    const {priceHistoryLoadingState, resultsLoadingState} = useAppSelector(({instrument}) => instrument)

    const isLoading = priceHistoryLoadingState === 'loading' || resultsLoadingState === 'loading'

    React.useEffect(() => {
        if (!instrument) {
            dispatch(instrumentActions.getSingleInstrumentBySlug(instrumentSlug))
        }
        if (instrument && !instrument.priceHistory && priceHistoryLoadingState === 'ready') {
            getPriceHistoryByInstrumentId(instrument.id, DateTime.local().setZone(config.NZTimeZone).minus({days: 7}))
        }
    }, [instrument])

    if (isLoading) {
        return <Loading isPineapple />
    }
    if (!instrument) {
        navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})
        return <></>
    }

    const currentInstrument = stagedOrderInstruments.find(
        stagedOrderInstrument => stagedOrderInstrument.instrumentSlug === instrument.urlSlug,
    )
    const currentIndexInStagedOrder = currentInstrument ? currentInstrument.index + 1 : undefined
    const totalInstrumentsInStagedOrder = stagedOrderInstruments.length

    const portfolioItem = portfolioItemsMap[instrument.id]

    let holdingShares: Decimal
    if (direction === 'out' && portfolioItem) {
        const sharesNotAvailableForSell = new Decimal(portfolioItem.sharesNotAvailableForSell)
        holdingShares = new Decimal(portfolioItem.holding ? portfolioItem.holding.shares : 0).minus(
            sharesNotAvailableForSell,
        )
    }

    const renderTransferForm = () => {
        if (direction === 'in' && auCustomer) {
            return (
                <AddTransferInParcelsForm
                    addStagedTransferOrderDetails={addStagedTransferOrderDetails}
                    currentInstrument={currentInstrument}
                    exchange={exchange!}
                    instrumentId={instrument.id}
                    instrumentCurrency={instrument.currency}
                    stagedOrderInstruments={stagedOrderInstruments}
                />
            )
        }

        return (
            <AddTransferDetailsForm
                instrumentId={instrument.id}
                addStagedTransferOrderDetails={details => {
                    addStagedTransferOrderDetails(details)
                }}
                currentInstrument={currentInstrument}
                stagedOrderInstruments={stagedOrderInstruments}
                closePrice={getMostRecentClosePrice(instrument)}
                direction={direction!}
                exchange={exchange}
                instrumentCurrency={instrument.currency}
                holdingShares={holdingShares?.toString()}
                profileUrl={profileUrl}
            />
        )
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--transfer-shares-details" leftButton="back" />
            <div className={cn(styles.nzxHeaderBlock)}>
                <h1>
                    How many {instrument.symbol} shares would you like to transfer {direction}?
                </h1>
            </div>
            {totalInstrumentsInStagedOrder > 1 && (
                <div className={styles.nzxHeaderBlock}>
                    <h2>
                        {currentIndexInStagedOrder} of {totalInstrumentsInStagedOrder}
                    </h2>
                </div>
            )}
            <div className={styles.instrumentDetailsWrapper}>
                <div>
                    <InstrumentLogo instrument={instrument} noBorder />
                </div>
                <div className={styles.instrumentContent}>
                    <h2>{instrument.name}</h2>
                    <p>
                        <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                        <PronounceLetters text={instrument.exchange} />
                    </p>
                </div>
            </div>
            <div>{renderTransferForm()}</div>
        </>
    )
}

export default TransferSharesDetails
