import {Button} from '@design-system/button'
import {DockableModal} from '@design-system/dockable-modal'
import React from 'react'
import {Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {dateFormatDayAndMonthWithTime} from '~/global/utils/format-date/formatDate'
import {pluralisedVote} from '~/global/widgets/corporate-action-description/corporateActionsV2'
import {VoluntaryCorporateActionV2} from '~/store/accounting/types'
import {useAppSelector} from '~/store/hooks'
import {setModalError} from '~/store/notification'

interface Props {
    order: VoluntaryCorporateActionV2
    cancelOrder(order: VoluntaryCorporateActionV2): Promise<void | Response.Error>
}

export const CancelApplicationModal: React.FunctionComponent<Props> = ({order, cancelOrder}) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [isSubmitting, setIsSubmitting] = React.useState(false)

    const onOpen = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setIsOpen(true)
    }

    const onCancel = async () => {
        setIsSubmitting(true)

        try {
            const error = await cancelOrder(order)
            if (error) {
                setModalError(error.message, 'We’re sorry, there’s been an error')
            }
            setIsSubmitting(false)
            setIsOpen(false)
        } catch (e) {
            setModalError(
                'Something caused it, maybe gremlins? Please try again or let us know if it’s still not working.',
                'We’re sorry, there’s been an error',
            )
        }
    }

    const hasAutoExerciseFlag = useAppSelector(s => s.identity.flags.auto_exercise)
    const applicationsCloseAt = order.application?.applications_close_at?.toFormat(dateFormatDayAndMonthWithTime)

    const title = () => {
        if (order.action_type === 'VOTE') {
            return `Withdraw your ${pluralisedVote(order.application)}?`
        }
        if (hasAutoExerciseFlag) {
            return 'Cancel application to exercise your rights?'
        }
        return 'You have an application in progress'
    }

    return (
        <>
            <Button
                dataTestId="modal-cancel-order"
                label={
                    order.action_type === 'VOTE'
                        ? 'Withdraw ' + pluralisedVote(order.application)
                        : 'Cancel application'
                }
                onClick={onOpen}
                processing={isOpen || isSubmitting}
                type="secondary"
                additionalClassName={spacing.spaceBelow12}
            />
            <DockableModal
                dataTestId="modal--confirm-delete-registry-detail"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={title()}
                content={
                    <div>
                        {order.action_type === 'VOTE' ? (
                            <>
                                <p className={spacing.spaceBelow24}>
                                    If you withdraw your votes, they won’t be counted
                                    {order.application.investment_option_title &&
                                        ` at the ${order.application.investment_option_title}`}
                                    .
                                </p>
                            </>
                        ) : hasAutoExerciseFlag ? (
                            <>
                                <p className={spacing.spaceBelow24}>
                                    If you cancel your exercise application, you’ll need to re-apply to exercise your
                                    rights by <strong>{applicationsCloseAt}</strong> to take up the offer.
                                </p>
                                <p className={spacing.spaceBelow24}>
                                    If you haven’t exercised your rights when the offer closes, they’ll be removed from
                                    your Portfolio—you may not receive any value for them.
                                </p>
                            </>
                        ) : (
                            <p className={spacing.spaceBelow24}>
                                If you want to change the size of your application you'll need to cancel your existing
                                application and start again.
                            </p>
                        )}
                        <Button
                            dataTestId="modal-cancel-order"
                            processing={isSubmitting}
                            label={
                                order.action_type === 'VOTE'
                                    ? 'Withdraw my ' + pluralisedVote(order.application)
                                    : 'Cancel application'
                            }
                            onClick={() => {
                                setIsOpen(false)
                                onCancel().then()
                            }}
                            additionalClassName={spacing.spaceBelow12}
                        />
                        <Button
                            label={order.action_type === 'VOTE' ? 'Don’t withdraw' : 'Keep application'}
                            onClick={() => setIsOpen(false)}
                            type="secondary"
                            dataTestId="button--do-not-cancel"
                        />
                    </div>
                }
                customZIndex={1051} // same as zindex(detail-modal)
            />
        </>
    )
}

export default CancelApplicationModal
