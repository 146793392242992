import {Modal} from '@design-system/modal'
import React from 'react'
import {Model} from '~/api/retail/types'
import {MAX_DIY_ORDERS} from '~/sections/invest/sections/auto-invest/constants/constants'

// Alert modal if customer has max number of DIY orders
const LimitReachedModal = ({
    isOpen,
    setIsOpen,
    jurisdiction,
}: {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    jurisdiction: Model.User['jurisdiction']
}) => {
    return (
        <Modal
            isAlert
            isOpen={isOpen}
            title="Limit reached"
            content={
                <p>
                    We love a keen bean! But you can only have up to {MAX_DIY_ORDERS[jurisdiction].word}{' '}
                    {
                        {
                            nz: 'DIY',
                            au: 'auto-invest',
                        }[jurisdiction]
                    }{' '}
                    orders set up at a time.
                </p>
            }
            dataTestId="modal--autoinvest-max-reached"
            setIsOpen={setIsOpen}
            primaryButton={{
                label: 'Got it',
                onClick: () => {
                    setIsOpen(false)
                },
            }}
        />
    )
}

export default LimitReachedModal
