import {useMutation, useQuery} from '@tanstack/react-query'
import {
    CombinedCountResponse,
    CreateEstimateRequest,
    CreateEstimateResponse,
    CustomerResponse,
    DeleteQuoteRequest,
    DeleteQuoteResponse,
    PoliciesResponse,
    QuotesResponse,
} from '~/api/cove/types'
import {useRetailGet} from '~/api/query/retail'
import * as api from '~/api/retail'
import * as rollbar from '~/api/rollbar/rollbar'
import config from '~/configForEnv'

const isAuthError = (response: Response) => response.status === 401 || response.status === 403

const BASE_URL = config.coveApiBaseUrl
const CACHE_TIME = 6 * 60 * 1000 // 6 minutes
const STALE_TIME = 5 * 60 * 1000 // 5 minutes

export interface COVE_GET_MAP {
    'customer/v1/counts': {response: CombinedCountResponse}
    'customer/v1/retrieve': {response: CustomerResponse}
    'coverage/v1/query': {response: PoliciesResponse}
    'quote/v1/queryByCustomer': {response: QuotesResponse}
}

export type GetEndpoint = keyof COVE_GET_MAP

export const useCoveGet = <T extends GetEndpoint>(endpoint: T, queryOptions?: object): COVE_GET_MAP[T]['response'] => {
    const cacheTime = 6 * 60 * 1000 // 6 minutes
    const {data: insureAccount} = useRetailGet({path: 'insure/get-account', options: {cacheTime}})

    if (insureAccount.type !== 'insure_account') {
        // This should never happen - we should never use this hook if they don't have an Insure account

        rollbar.sendError('Attempted Cove GET request without a Sharesies Insure account', {endpoint})
        throw new Error('No Insure account found when trying to retrieve information from Cove')
    }

    /**
     * We expect this token to be valid: the GET Insure account endpoint refreshes the token if it has fewer
     * than 10 minutes to expiry time, and we're only caching the response for 6 minutes.
     */
    const coveAccessToken = insureAccount.cove_access_token

    const url = constructCoveGetUrl(endpoint, insureAccount.cove_customer_id)

    const getOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${coveAccessToken}`,
        },
    }

    const {data} = useQuery<COVE_GET_MAP[T]['response']>({
        queryKey: [coveGetUrlQueryString(url)],
        queryFn: async () => {
            const response = await fetch(url, getOptions)

            // TODO I&S: verify that the response conforms to the relevant type.
            // This is low priority, but would be nice to have in future.
            // Martyn suggests using `zod` or `io-ts`.

            if (!response.ok) {
                // See comment above re: token.
                // Since we don't expect to encounter 403s, we're not explicitly handling them at this time.
                // If we change this in future, please also update the Tech Implementation document,
                // https://sharesies.atlassian.net/wiki/spaces/WHEK/pages/524386326/Car+Insurance+Technical+Implementation#Lack-of-403-handling

                if (isAuthError(response)) {
                    rollbar.sendError('Received authorisation error response from Cove endpoint', {
                        endpoint,
                        statusCode: response.status,
                        personId: insureAccount.person_id,
                    })
                }

                throw response
            }

            return response.json()
        },
        cacheTime: CACHE_TIME,
        staleTime: STALE_TIME,
        useErrorBoundary: true,
        suspense: true,
        ...queryOptions,
    })

    if (!data) {
        throw new Error('Data should always be defined when using Suspense')
    }

    return data
}

export const constructCoveGetUrl = (endpoint: GetEndpoint, coveCustomerId: string): URL => {
    const params = {customer_id: coveCustomerId}
    const url = new URL(`${BASE_URL}/${endpoint}`)
    url.search = new URLSearchParams(params).toString()

    return url
}

export const coveGetUrlQueryString = (url: URL): string => {
    return url.toString()
}

export interface COVE_POST_ANONYMOUS_MAP {
    'sharesies/quote/motor/comprehensive/v1/create': {
        // Despite the URL, this endpoint actually returns an estimate.
        // It will become a quote at the next step, when the user enters the embed and Cove then creates an actual
        // quote in their system - see CoveCustomiseEstimate.
        request: CreateEstimateRequest
        response: CreateEstimateResponse
    }
}
type UseCovePostAnonymousPayload<T extends keyof COVE_POST_ANONYMOUS_MAP> = COVE_POST_ANONYMOUS_MAP[T]['request']
type UseCovePostAnonymousData<T extends keyof COVE_POST_ANONYMOUS_MAP> = COVE_POST_ANONYMOUS_MAP[T]['response']
type UseCovePostAnonymousEndpoint = keyof COVE_POST_ANONYMOUS_MAP

export interface COVE_POST_CUSTOMER_MAP {
    'quote/v1/remove': {
        request: DeleteQuoteRequest
        response: DeleteQuoteResponse
    }
}
type UseCovePostCustomerPayload<T extends keyof COVE_POST_CUSTOMER_MAP> = COVE_POST_CUSTOMER_MAP[T]['request']
type UseCovePostCustomerData<T extends keyof COVE_POST_CUSTOMER_MAP> = COVE_POST_CUSTOMER_MAP[T]['response']
type UseCovePostCustomerEndpoint = keyof COVE_POST_CUSTOMER_MAP

/**
 * Cove POST hook for endpoints which are not tied to a specific Cove customer.
 * These requests use an anonymous Cove access token with a 10 minute lifetime (retrieved inside hook).
 */
export const useCoveAnonymousPost = <T extends UseCovePostAnonymousEndpoint>(endpoint: T) => {
    return useMutation<UseCovePostAnonymousData<T>, unknown, UseCovePostAnonymousPayload<T>, unknown>({
        mutationFn: async (payload: UseCovePostAnonymousPayload<T>) => {
            const initialTokenResponse = await api.get('insure/get-initial-token') // can't use useRetailGet() inside a mutation function

            if (initialTokenResponse.type !== 'cove_initial_token') {
                throw initialTokenResponse
            }

            const response = await makeCovePostRequest(endpoint, initialTokenResponse.token, payload)

            return response as UseCovePostAnonymousData<T>
        },
        onError: async (e: unknown) => handleError(e, endpoint),
    })
}

/**
 * Cove POST hook for endpoints which are tied to a specific Cove customer.
 * These requests use a customer-specific Cove Cognito token with a one hour lifetime (retrieved inside hook).
 */
export const useCoveCustomerPost = <T extends UseCovePostCustomerEndpoint>(endpoint: T) => {
    return useMutation<UseCovePostCustomerData<T>, unknown, UseCovePostCustomerPayload<T>, unknown>({
        mutationFn: async (payload: UseCovePostCustomerPayload<T>) => {
            const insureAccountResponse = await api.get('insure/get-account') // can't use useRetailGet() inside a mutation function

            if (insureAccountResponse.type !== 'insure_account') {
                rollbar.sendError('Attempted Cove POST request without a Sharesies Insure account', {endpoint})
                throw insureAccountResponse
            }

            const response = await makeCovePostRequest(endpoint, insureAccountResponse.cove_access_token, payload)

            return response as UseCovePostCustomerData<T>
        },
        onError: async (e: unknown) => handleError(e, endpoint),
    })
}

const makeCovePostRequest = async <T extends UseCovePostAnonymousEndpoint, U extends UseCovePostCustomerEndpoint>(
    endpoint: UseCovePostAnonymousEndpoint | UseCovePostCustomerEndpoint,
    token: string,
    payload: UseCovePostAnonymousPayload<T> | UseCovePostCustomerPayload<U>,
) => {
    const baseUrl = `${BASE_URL}/${endpoint}`
    const postOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    }

    // Cove's POST endpoints receive data as query parameters, not in the request body.
    // Attempting to send them in the request body results in a "No query parameters provided" error.
    const queryParams = new URLSearchParams()
    for (const [key, value] of Object.entries(payload)) {
        queryParams.append(key, value)
    }
    const urlWithParams = `${baseUrl}?${queryParams.toString()}`

    const response = await fetch(urlWithParams, postOptions)

    if (!response.ok) {
        throw response // triggers the onError() case
    }

    const responseData = await response.json()

    return responseData
}

export interface CoveResponseError {
    type: 'cove_response_error'
    statusCode: number
    message: string // from Cove
}

export const isCoveResponseError = (value: unknown) =>
    !!value && typeof value === 'object' && 'type' in value && value.type === 'cove_response_error'

const handleError = async (error: unknown, endpoint: string) => {
    if (error instanceof Response) {
        const errorDetails = await error.json()

        if (isAuthError(error)) {
            // Due to the setup of each POST hook, we expect our requests to have been created with valid tokens.
            // Since we don't expect to encounter 403s, we're not explicitly handling them at this time.
            // If we change this in future, please also update the Tech Implementation document,
            // https://sharesies.atlassian.net/wiki/spaces/WHEK/pages/524386326/Car+Insurance+Technical+Implementation#Lack-of-403-handling

            rollbar.sendError('Received authorisation error response from Cove endpoint', {
                endpoint,
                statusCode: error.status,
            })
        }

        // Let's package this up in a user-friendly way
        const coveResponseError = {
            type: 'cove_response_error',
            statusCode: error.status,
            message: errorDetails.error,
        }

        // Rethrow for the component to handle
        throw coveResponseError
    }

    // We don't know what this is! Shouldn't happen. Rethrow for the component to handle
    throw error
}
