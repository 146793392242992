import {QueryKey, UseInfiniteQueryResult, useInfiniteQuery} from '@tanstack/react-query'
import {useRetailGet} from '~/api/query/retail'
import * as api from '~/api/retail'
import {Response, Request} from '~/api/retail/types'
import {DEFAULT_CACHE_STALE_OPTION, KSInfiniteQueryResponse} from '~/sections/kiwisaver/state'

interface PortfolioOrdersResponse extends KSInfiniteQueryResponse {
    orders: Response.PortfolioOrders['orders']
}

export const usePortfolioOrder = (id: string): Response.PortfolioOrderDetails => {
    const {data} = useRetailGet({
        path: 'kiwisaver/portfolio-order-v2',
        payload: {id},
        options: DEFAULT_CACHE_STALE_OPTION,
    })
    return data
}

export const usePortfolioOrdersInfiniteQuery = ({
    limit,
    fund_id,
    state_filter,
}: Request.KSPortfolioOrders): UseInfiniteQueryResult<PortfolioOrdersResponse> => {
    const path = 'kiwisaver/portfolio-orders-v2'
    let queryKey: QueryKey = [path, limit, fund_id, state_filter]
    queryKey = queryKey.filter(key => key !== undefined)

    return useInfiniteQuery({
        queryKey,
        ...DEFAULT_CACHE_STALE_OPTION,
        queryFn: async ({pageParam}) => {
            const response = await api.get(path, {limit, fund_id, state_filter, before: pageParam})

            return response as PortfolioOrdersResponse
        },
        getNextPageParam: (currentPage: PortfolioOrdersResponse) => {
            const hasNextPage: boolean = currentPage.has_more

            if (!hasNextPage) {
                return undefined
            }

            return currentPage.orders[currentPage.orders.length - 1].date
        },
    })
}

export const useInFlightPortfolioOrders = (): Response.PortfolioOrders['orders'] => {
    // For KiwiSaver, orders move directly to PROCESSING so we can take a shortcut here and skip looking at the PENDING state
    const allPortfolioOrderResults = usePortfolioOrdersInfiniteQuery({state_filter: 'PROCESSING'})

    let portfolioOrders: Response.PortfolioOrders['orders'] = []
    allPortfolioOrderResults.data?.pages.forEach(page => {
        portfolioOrders = portfolioOrders.concat(...page.orders)
    })
    return portfolioOrders
}
