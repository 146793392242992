import React from 'react'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import InlineTaxForm from '~/global/widgets/inline-tax-form/InlineNZTaxForm'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {useNavigate} from '~/migrate-react-router'
import {handleProcessResponse} from '~/sections/invest/sections/auto-invest/handleProcessResponse'
import {usePremadeOrder} from '~/sections/invest/sections/auto-invest/hooks/usePremadeOrders'
import actions from '~/store/autoinvest/actions'
import {pirRequired as pirRequireSelector} from '~/store/autoinvest/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'

interface Props {
    premadeSlug?: string
}

const AutoinvestTax: React.FunctionComponent<Props> = ({premadeSlug}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const notificationContext = React.useContext(NotificationContext)

    const [premadeOrder] = usePremadeOrder(premadeSlug ?? '')
    const stagedOrder = useAppSelector(s => s.autoinvest.stagedOrder)
    const pirRequired = useAppSelector(pirRequireSelector)

    const processStagedOrder = () => dispatch(actions.ProcessStagedOrder())

    if (!stagedOrder) {
        return <WeSlippedUp />
    }

    return (
        <InlineTaxForm
            pirRequired={pirRequired}
            externalSubmitHandler={async () => {
                await handleProcessResponse(processStagedOrder(), navigate, profileUrl, notificationContext)
                return undefined
            }}
            externalBackHandler={() => {
                navigate(
                    premadeOrder
                        ? profileUrl('auto-invest/:premadeSlug/how-much', {premadeSlug: premadeOrder.slug})
                        : profileUrl('auto-invest/diy/percentage'),
                    {replace: true},
                )
            }}
        />
    )
}

export default AutoinvestTax
