import cn from 'classnames'
import React from 'react'
import detailModalStyles from '~/global/widgets/detail-modals/DetailModal.scss'
import {DollarValue, PercentValue} from '~/global/widgets/number-elements/NumberElements'
import {InstrumentReturn} from '~/store/portfolio/types'

const SimpleReturnDetail = ({
    currentReturn,
    hasHadSellOrder,
    includeSoldInvestments,
    instrumentName,
    instrumentTypeLabel,
    isDependent,
    preferredName,
}: {
    currentReturn: InstrumentReturn
    hasHadSellOrder: boolean
    includeSoldInvestments: boolean
    instrumentName: string
    instrumentTypeLabel: string
    isDependent: boolean
    preferredName: string
}) => {
    // If the includeSoldInvestments flag is true, and there has been a sell order,
    //   we should display the text referring to investments having been sold.
    // Otherwise, we should show the regular/default wording.

    const possessive = isDependent ? `${preferredName}’s` : 'your'
    const possessivePronoun = isDependent ? 'their' : 'your'
    const customerHas = isDependent ? 'they’ve' : 'you’ve'

    return (
        <div>
            <h1>Simple return</h1>
            <p>
                There are many ways to work out returns. These calculations should not be relied upon to make investment
                decisions, they are indicative only and accuracy is not guaranteed.
            </p>
            <p>Past performance does not guarantee future results.</p>
            <p>
                {isDependent ? `${preferredName}’s` : 'Your'} simple return is a measure of the performance of{' '}
                {possessivePronoun} investment in {instrumentName} as a percentage.
            </p>
            <p>
                This calculation {!includeSoldInvestments && ' only'} includes investments {instrumentTypeLabel}{' '}
                {isDependent ? 'they' : 'you'} currently own
                {includeSoldInvestments ? ` and any ${customerHas} sold.` : '.'}
            </p>
            {includeSoldInvestments && hasHadSellOrder ? (
                <p>
                    As {customerHas} sold some {instrumentTypeLabel}, we calculate {possessivePronoun} simple return by
                    dividing {possessivePronoun} investment’s total return by the largest amount {customerHas}{' '}
                    previously had invested.
                </p>
            ) : (
                <p>
                    To calculate {possessive} simple return, we divide {possessivePronoun} investment’s
                    {includeSoldInvestments && ' total'} return by the amount put in.
                </p>
            )}
            <p>The result is multiplied by 100 to give {possessive} investment’s simple return as a percentage.</p>
            <div className={cn(detailModalStyles.calcBox, detailModalStyles.boldTextBox)}>
                ({includeSoldInvestments ? 'Total return' : 'Return'} &divide;{' '}
                {includeSoldInvestments && hasHadSellOrder
                    ? `largest amount ${customerHas} had invested`
                    : 'amount put in'}
                ) x 100 = Simple return %
            </div>
            <p>
                The simple return calculation for {possessive} {instrumentName} investment is:
            </p>
            <div className={cn(detailModalStyles.calcBox, detailModalStyles.boldTextBox)}>
                (
                <DollarValue
                    value={includeSoldInvestments ? currentReturn.total_return : currentReturn.unrealised_total_return}
                />{' '}
                &divide;{' '}
                <DollarValue value={includeSoldInvestments ? currentReturn.cost_basis_max : currentReturn.cost_basis} />
                ) x 100 ={' '}
                <PercentValue
                    value={
                        includeSoldInvestments ? currentReturn.simple_return : currentReturn.unrealised_simple_return
                    }
                />
            </div>

            {/* TODO - add help centre link once specific content is ready*/}
        </div>
    )
}

export default SimpleReturnDetail
