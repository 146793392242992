import {Button} from '@design-system/button'
import {DateTime} from 'luxon'
import React from 'react'
import {Navigate} from 'react-router-dom'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {EventDataTaxonomy} from '~/api/rudderstack/event-taxonomy/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {hasForeignInstrument} from '~/global/utils/autoinvest-order/autoinvestOrder'
import {dateFormatNoTime, dateFormatWithYearFirst} from '~/global/utils/format-date/formatDate'
import {isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {OrderConfirmation, CoverageText, OrderRow} from '~/global/widgets/order-confirmation/OrderConfirmation'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {
    NotificationContext,
    NotificationContextProps,
} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {useNavigate, useLocation} from '~/migrate-react-router'
import {handleProcessResponse} from '~/sections/invest/sections/auto-invest/handleProcessResponse'
import CoverageExplainer from '~/sections/invest/sections/auto-invest/widgets/coverage-explainer/CoverageExplainer'
import CustomImage from '~/sections/user/widgets/custom-image/CustomImage'
import actions from '~/store/autoinvest/actions'
import {calculateDisplayInterval, selectStagedOrderImageUrl} from '~/store/autoinvest/selectors'
import {isDIY, StagedOrder} from '~/store/autoinvest/types'
import {useAppSelector, useAppDispatch} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import signupActions from '~/store/sign-up/actions'

interface DropdownDetails {
    description: string
    value: string
}

const AutomaticPaymentModal = (
    notificationContext: NotificationContextProps,
    navigate: ReturnType<typeof useNavigate>,
    profileUrl: ReturnType<typeof useProfileUrl>,
) => {
    return {
        title: 'Your auto-invest is set!',
        message: (
            <>
                <p className={spacing.spaceBelow16}>
                    Make sure you have money in your Wallet before your auto-invest order date.
                </p>
                <p className={spacing.spaceBelow40}>
                    Consider creating an automatic payment from your bank account to your Wallet before the auto-invest
                    order is due.
                </p>
            </>
        ),
        primaryButton: {
            label: 'Got it',
            onClick: () => {
                notificationContext.closeModalInfo()
            },
        },
        secondaryButton: {
            label: 'View top-up details',
            onClick: () => {
                navigate(profileUrl('wallet/bank-transfer'))
                notificationContext.closeModalInfo()
            },
        },
    }
}

export const FeeReview: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const currentPath = useLocation().pathname
    const profileUrl = useProfileUrl()

    const notificationContext = React.useContext(NotificationContext)

    // Store data
    const displayImage = useAppSelector(s => selectStagedOrderImageUrl(s))
    const hasSeenAutoinvestAutomaticPayment = useAppSelector(
        s => s.identity.user!.has_seen.autoinvest_automatic_payment,
    )
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const stagedOrder = useAppSelector(s => s.autoinvest.stagedOrder)
    const usEquitiesEnabled = useAppSelector(s => s.identity.user!.us_equities_enabled)

    // Derived
    const allocations = stagedOrder
        ? isDIY(stagedOrder.order)
            ? stagedOrder.order.allocations
            : stagedOrder.order.premadeOrder.allocations
        : []
    const dropdownContent: DropdownDetails[] = allocations.map(allocation => {
        return {description: instrumentsById[allocation.fund_id].name, value: `${allocation.allocation}%`}
    })
    const dropdownHeader: string = `${dropdownContent.length} investment${dropdownContent.length > 1 ? 's' : ''}`
    const isDIYOrder = stagedOrder && isDIY(stagedOrder.order)
    const isPremadeOrder = !isDIYOrder
    const hasUsInstrument =
        isDIYOrder && stagedOrder.order.allocations.find(a => isOnMainUsExchange(instrumentsById[a.fund_id]))

    /******** BEGIN useEffects ********/

    React.useEffect(() => {
        // do a refresh of identity just in case it has been updated
        dispatch(identityActions.Check())

        // Redirect to index.tsx in USSignUp if have not completed US tax declaration
        if (hasUsInstrument && !usEquitiesEnabled) {
            dispatch(signupActions.setUSPreSignUpLocation(currentPath))
            navigate(profileUrl('us-sign-up'), {replace: true})
        }
    }, [usEquitiesEnabled, hasUsInstrument])

    /******** END useEffects ********/

    // Must have a staged order to be able to get to review
    if (!stagedOrder || !stagedOrder.order) {
        return <Navigate to={profileUrl('auto-invest')} replace />
    }

    /***** BEGIN helper functions *****/

    const createOrderRows = (): OrderRow[] => {
        const hasNoForeignInstrument = !hasForeignInstrument(stagedOrder, instrumentsById, jurisdiction)
        const feeCoverageType =
            (isPremadeOrder || !hasForeignInstrument(stagedOrder, instrumentsById, jurisdiction)) &&
            'transactionFee' in stagedOrder &&
            stagedOrder.transactionFee
                ? 'maybe'
                : undefined
        const feeExplainer = feeCoverageType && <CoverageText feeCoverage={feeCoverageType} />

        // The order of the order rows is important: we build up the list.
        const orderRows: OrderRow[] = []

        // 1. Amount to invest
        if (hasNoForeignInstrument) {
            orderRows.push({
                description: 'Amount to invest',
                value:
                    'amountToInvest' in stagedOrder ? (
                        <DollarValue value={stagedOrder.amountToInvest!} currency={homeCurrency} decimalPlaces={2} />
                    ) : (
                        <DollarValue value={stagedOrder.order.amount!} currency={homeCurrency} />
                    ),
            })
        }
        // 2. Frequency
        orderRows.push({
            description: 'Frequency',
            value: `Every ${calculateDisplayInterval(stagedOrder.order.interval!)}`,
        })
        // 3. Estimated transaction fee
        if (
            (isPremadeOrder || hasNoForeignInstrument) &&
            'transactionFee' in stagedOrder &&
            stagedOrder.transactionFee
        ) {
            orderRows.push({
                description: 'Estimated transaction fee',
                value: <DollarValue value={stagedOrder.transactionFee} currency={homeCurrency} decimalPlaces={5} />,
                explainerText: feeExplainer,
            })
        }

        return orderRows
    }

    const orderTitle = (stagedOrder: StagedOrder): string => {
        if (stagedOrder.orderName) {
            return stagedOrder.orderName
        } else if (isDIY(stagedOrder.order)) {
            return jurisdiction === 'au' ? 'My auto-invest' : 'DIY auto-invest'
        } else {
            return stagedOrder.order.premadeOrder.name + ' auto-invest'
        }
    }

    /****** END helper functions ******/

    /***** BEGIN event handlers *****/

    const confirmOrder = async () => {
        if ('premadeOrder' in stagedOrder.order) {
            rudderTrack('autoinvest', 'autoinvest_confirmed', {
                autoinvest_type: stagedOrder.order.premadeOrder
                    .slug as EventDataTaxonomy['autoinvest']['autoinvest_confirmed']['autoinvest_type'],
            })
        } else {
            rudderTrack('autoinvest', 'autoinvest_confirmed', {autoinvest_type: 'diy'})
        }

        Analytics.event({
            category: 'AutoInvest',
            action: 'Create AutoInvest',
            label: 'premadeOrder' in stagedOrder.order ? stagedOrder.order.premadeOrder.name : 'DIY',
        })

        const modalInfo = !hasSeenAutoinvestAutomaticPayment
            ? AutomaticPaymentModal(notificationContext, navigate, profileUrl)
            : undefined

        await handleProcessResponse(
            dispatch(actions.ProcessStagedOrder()),
            navigate,
            profileUrl,
            notificationContext,
            modalInfo,
        )

        if (!hasSeenAutoinvestAutomaticPayment) {
            await dispatch(identityActions.MarkHasSeenFlag('autoinvest_automatic_payment'))
        }
    }

    /****** END event handlers ******/

    return (
        <>
            <Toolbar dataTestId="toolbar--fee-review" leftButton="back" title="Confirm your auto-invest" />
            {stagedOrder.order.amount && stagedOrder.order.interval && stagedOrder.state === 'staged' && (
                <>
                    <Page overrideDefaultTopPadding="withToolbarTitle">
                        {stagedOrder.order.startDate && (
                            <h2 className={spacing.spaceBelow16}>{`Auto-invest starting ${DateTime.fromFormat(
                                stagedOrder.order.startDate,
                                dateFormatWithYearFirst,
                                {},
                            ).toFormat(dateFormatNoTime)}`}</h2>
                        )}

                        <OrderConfirmation
                            title={orderTitle(stagedOrder)}
                            items={createOrderRows()}
                            total={{
                                description: 'Order Amount',
                                value: <DollarValue value={stagedOrder.order.amount!} currency={homeCurrency} />,
                            }}
                            dropdown={{
                                text: dropdownHeader,
                                content: dropdownContent,
                            }}
                            image={<CustomImage imageUrl={displayImage} />}
                        />
                        <CoverageExplainer />
                        <p className={spacing.spaceBelow16}>
                            Processing times will vary and orders might not fill depending on:
                        </p>
                        <ul className={spacing.spaceBelow16}>
                            <li>if the market is open or closed</li>
                            <li>if the market has a trading halt</li>
                            <li>where you are in the queue and the value of orders ahead of you.</li>
                        </ul>
                        <p className={spacing.spaceBelow16}>
                            Orders may fill bit-by-bit. Any unfilled parts of an order may be cancelled if they remain
                            unfilled for 30 days or more. If cancelled, the money is returned to your Wallet.
                        </p>
                        {isDIYOrder && hasForeignInstrument(stagedOrder, instrumentsById, jurisdiction) ? (
                            <p data-testid="fee-review-foreign" className={spacing.spaceBelow16}>
                                Your auto-invest order includes transaction fees, and may include currency exchange
                                fees. Fees are charged on the order for each individual investment.
                            </p>
                        ) : (
                            <p data-testid="fee-review-home" className={spacing.spaceBelow16}>
                                All pending order and transaction fee amounts are estimates until your order fills.
                            </p>
                        )}
                    </Page>

                    {stagedOrder.order.amount && stagedOrder.order.interval && (
                        <ActionBar>
                            <Button
                                onClick={confirmOrder}
                                label="Confirm your auto-invest order"
                                dataTestId="button--confirm-auto-invest-order"
                            />
                        </ActionBar>
                    )}
                </>
            )}
        </>
    )
}

export default FeeReview
