import * as Icons from '@design-system/icon'
import React from 'react'
import styles from './CircleIcon.scss'

interface Props {
    icon: Icons.Icons
}

/**
 * CircleIcon Component
 *
 * This functional component renders an icon inside a styled circular container.
 * The specific icon to be displayed is determined by the `icon` prop, which is
 * used to dynamically select the corresponding icon component from the `Icons` object.
 *
 * @param {string} icon - The name of the icon which need to be rendered form Icons.
 * @returns {JSX.Element} A React element representing the icon with circular background.
 */
export const CircleIcon: React.FunctionComponent<Props> = ({icon}) => {
    const Icon = Icons[icon]
    return (
        <div className={styles.circleIcon}>
            <div className={styles.icon}>{Icon && <Icon size={16} />}</div>
        </div>
    )
}
