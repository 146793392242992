import cn from 'classnames'
import React from 'react'
import {Model} from '~/api/retail/types'
import tooltipStyles from '~/global/scss/reused-styles/tooltip.scss'
import {calculateCurrencyExchangeFromSourceAmount} from '~/global/utils/calculate-currency-exchange/calculateCurrencyExchange'
import {Currency, currencyDetails, walletTitleByCurrency} from '~/global/utils/currency-details/currencyDetails'
import {findExchangeRate} from '~/global/utils/find-exchange-rate/findExchangeRate'
import {WalletValue} from '~/global/widgets/number-elements/NumberElements'
import Tooltip from '~/global/widgets/tooltip/Tooltip'
import {ExchangeRate} from '~/store/accounting/types'
import styles from './WalletBalances.scss'

export const exchangeRate = (exchangeRates: ExchangeRate[], source: Currency, target: Currency): number => {
    if (source === target) {
        return 1
    }

    const exRate = findExchangeRate({
        sourceCurrency: source,
        targetCurrency: target,
        exchangeRates,
    })

    return exRate ? exRate.rate : 0
}

export const WalletBalanceTextWidget = (currency: Currency, walletBalances: Model.User['wallet_balances']) => {
    const walletBalance = walletBalances[currency]

    if (!walletBalance) {
        return null
    }

    return (
        <div key={currency.toString()}>
            <p>{walletTitleByCurrency[currency]}</p>
            <p>
                <WalletValue value={walletBalance} currency={currency} />
            </p>
        </div>
    )
}

interface CurrencyWalletBalanceTooltipProps {
    sourceCurrency: Currency
    targetCurrency: Currency
    walletBalance: string
    exchangeRate: number
    exchangeFeeRate: number
    label: string
    trailingLabel?: string
}

const WalletBalanceExchangeRateTooltip = ({
    sourceCurrency,
    targetCurrency,
    walletBalance,
    exchangeRate,
    exchangeFeeRate,
    label,
    trailingLabel = '',
}: CurrencyWalletBalanceTooltipProps) => {
    return (
        <Tooltip>
            <span className={cn(tooltipStyles.label)}>
                <WalletValue value={walletBalance} currency={sourceCurrency} />
            </span>
            <div className={tooltipStyles.tooltip}>
                <p>
                    {label}{' '}
                    <WalletValue
                        value={
                            calculateCurrencyExchangeFromSourceAmount(walletBalance, exchangeRate, exchangeFeeRate)
                                .targetAmount
                        }
                        currency={targetCurrency}
                    />
                    {trailingLabel ? ` ${trailingLabel}` : ''}
                </p>
            </div>
        </Tooltip>
    )
}

interface EstimatedWalletBalanceProps {
    walletBalances: Model.User['wallet_balances']
    sourceCurrency: Currency
    targetCurrency: Currency
    exchangeRates: ExchangeRate[]
    exchangeFeeRate: number | null
}

export const WalletBalanceWithIconWidget = ({
    sourceCurrency,
    targetCurrency,
    walletBalances,
    exchangeRates,
    exchangeFeeRate,
}: EstimatedWalletBalanceProps) => {
    const {icon} = currencyDetails[sourceCurrency]
    const sourceWalletBalance = walletBalances[sourceCurrency]
    // If the source is the same as target (NZD->NZD), the exchange rate is 1
    const rate = exchangeRate(exchangeRates, sourceCurrency, targetCurrency)

    return (
        <li>
            <div className={styles.iconAndLabel}>
                {icon} <p className={styles.currencyLabel}>{sourceCurrency.toUpperCase()}</p>
            </div>
            <div
                className={cn({
                    [styles.negativeBalance]: Number(sourceWalletBalance) < 0,
                })}
            >
                {sourceCurrency !== targetCurrency &&
                Number(sourceWalletBalance) !== 0 &&
                rate !== 0 &&
                exchangeFeeRate !== null ? (
                    <WalletBalanceExchangeRateTooltip
                        sourceCurrency={sourceCurrency}
                        targetCurrency={targetCurrency}
                        walletBalance={sourceWalletBalance}
                        exchangeRate={rate}
                        exchangeFeeRate={exchangeFeeRate}
                        label="Estimated"
                    />
                ) : (
                    <WalletValue value={sourceWalletBalance} currency={sourceCurrency} />
                )}
            </div>
        </li>
    )
}
