import {useEffect} from 'react'
import {Response} from '~/api/retail/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/plan/actions'

export const useCurrentSubscriptionPlan: () => [Response.SubscriptionCurrentV2 | undefined, boolean] = () => {
    const {currentPlan, currentPlanLoaded} = useAppSelector(s => ({
        currentPlan: s.plan.currentPlan,
        currentPlanLoaded: s.plan.currentPlanLoaded,
    }))

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!currentPlanLoaded) {
            dispatch(actions.FetchCurrentPlan())
        }
    }, [currentPlan, currentPlanLoaded])

    return [currentPlan, currentPlanLoaded]
}

export const useRefreshedSubscriptionPlan: () => [Response.SubscriptionCurrentV2 | undefined, boolean] = () => {
    const {currentPlan, currentPlanLoaded, user} = useAppSelector(s => ({
        currentPlan: s.plan.currentPlan,
        currentPlanLoaded: s.plan.currentPlanLoaded,
        user: s.identity.user!,
    }))

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(actions.FetchCurrentPlan())
    }, [user])

    return [currentPlan, currentPlanLoaded]
}

export const useAllSubscriptionPlans = (
    {includeUnselectablePlans}: {includeUnselectablePlans: boolean} = {includeUnselectablePlans: true},
) => {
    const allPlans = useAppSelector(s => s.plan.allPlans)
    const dispatch = useAppDispatch()
    const currentPlan = useAppSelector(s => s.plan.currentPlan)

    useEffect(() => {
        if (!allPlans) {
            dispatch(actions.FetchAvailablePlans())
        }
    }, [allPlans])

    if (includeUnselectablePlans) {
        return allPlans ?? []
    }

    // filter out unselectable plans (the one they previously selected)
    const notThisOne = currentPlan?.auto_renew_into_plan ? currentPlan.auto_renew_into_plan.id : currentPlan?.plan.id
    return allPlans?.filter(plan => plan.id !== notThisOne) ?? []
}
