import {DockableModal} from '@design-system/dockable-modal'
import cn from 'classnames'
import React from 'react'
import {Model} from '~/api/retail/types'
import {accessibility} from '~/global/scss/helpers'
import {Currency, nonAbbreviatedTitleByCurrency, currencyDetails} from '~/global/utils/currency-details/currencyDetails'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ChevronRight} from '~/global/widgets/OLD_icons'
import {WalletValue, ExchangeRateValue} from '~/global/widgets/number-elements/NumberElements'
import {exchangeRate} from '~/global/widgets/wallet-balances/WalletBalances'
import {Link} from '~/migrate-react-router'
import {sortCurrencies} from '~/sections/OLD_wallet/widgets/wallet-breakdown/WalletBreakdown'
import {State} from '~/store/accounting/types'
import {useAppSelector} from '~/store/hooks'
import EstimatedWalletBalance from './EstimatedWalletBalance'
import styles from './EstimatedWalletBalanceWithModal.scss'

interface EstimatedWalletBalanceWithModalProps {
    displayCurrency: Currency
    exchangeRates?: State['exchangeRates']
}

const EstimatedWalletBalanceWithModal = ({exchangeRates, displayCurrency}: EstimatedWalletBalanceWithModalProps) => {
    const profileUrl = useProfileUrl()
    const [openDocakbleModal, setOpenDockableModal] = React.useState(false)

    const walletBalances = useAppSelector(s => s.identity.user!.wallet_balances)
    const storeExchangeRates = useAppSelector(s => s.accounting.exchangeRates)
    const usEquitiesEnabled = useAppSelector(s => s.identity.user!.us_equities_enabled)
    const showAuCurrency = useAppSelector(s => s.identity.user!.has_seen.show_au_currency)
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)

    exchangeRates ??= storeExchangeRates

    // default wallet title if only NZD
    let walletTitle = 'Wallet'

    if (!walletBalances) {
        return <></>
    }

    // check if there are other currencies, and if so then the total wallet value is an 'estimate'
    for (const currency in walletBalances) {
        // if display currency is not home currency
        if (currency !== displayCurrency && Number(walletBalances[currency]) > 0) {
            walletTitle = 'Estimated Wallet'
        }
    }

    const showExchangeRate = (source: Currency, target: Currency) => {
        const rate = exchangeRate(exchangeRates!, source, target)

        if (rate === 1) {
            return null
        }

        return (
            <div>
                <p className={styles.exchangeRate}>
                    <ExchangeRateValue sourceCurrency={source} targetCurrency={target} exchangeRate={rate} />
                </p>
            </div>
        )
    }

    const walletBalanceRow = (currency: Currency, walletBalances: Model.User['wallet_balances']) => {
        const walletBalance = walletBalances[currency]

        if (!Number(walletBalance)) {
            return null
        }

        return (
            <div key={currency} className={styles.currencyRowWrapper}>
                <div className={styles.currencyRow} key={currency.toString()}>
                    <div className={styles.labelAndExchangeRate}>
                        <p className={styles.icon}>{currencyDetails[currency].icon}</p>
                        <div>
                            <div>{nonAbbreviatedTitleByCurrency[currency]}</div>
                            <div>{showExchangeRate(currency, displayCurrency)}</div>
                        </div>
                    </div>
                    <WalletValue value={walletBalance} currency={currency} />
                </div>
            </div>
        )
    }

    const walletBalancesContent = (walletBalances: Model.User['wallet_balances']) => {
        const currencies = sortCurrencies(displayCurrency, Object.keys(walletBalances) as Currency[])
        return (
            <div className={styles.wallets}>
                {currencies.map(currency => {
                    if (currency === 'aud') {
                        if (Number(walletBalances.aud) > 0) {
                            return walletBalanceRow(currency, walletBalances)
                        }
                        if (showAuCurrency) {
                            // if the user has ever exchanged AUD we want to show AUD currency (even if they don't have any balance).
                            return walletBalanceRow(currency, walletBalances)
                        }
                        // if the user has never interacted with their AUD wallet don't show it.
                        return null
                    }
                    if (!usEquitiesEnabled && currency === 'usd') {
                        return null
                    }
                    return walletBalanceRow(currency, walletBalances)
                })}
            </div>
        )
    }

    const shouldDisplayModal = () => {
        const activeWallets: string[] = [homeCurrency]

        // always show modal if a user has used exchange money for AUD
        if (showAuCurrency && homeCurrency !== 'aud') {
            return true
        }

        for (const currency in walletBalances) {
            // if display currency is not home currency
            if (currency !== homeCurrency && Number(walletBalances[currency]) > 0) {
                activeWallets.push(currency)
            }
        }

        return activeWallets.length > 1 && true
    }

    const walletBalancesFooter = <Link to={profileUrl('wallet')}>Go to Wallet</Link>

    return shouldDisplayModal() ? (
        <div>
            <>
                <p className={styles.walletBalanceTitle}>{walletTitle}</p>
                <button
                    type="button"
                    onClick={() => setOpenDockableModal(!openDocakbleModal)}
                    className={cn(styles.estimatedBalance, accessibility.button)}
                >
                    <EstimatedWalletBalance displayCurrency={displayCurrency} exchangeRates={exchangeRates} />
                    <span className={styles.chevron}>
                        <ChevronRight />
                    </span>
                </button>
                <DockableModal
                    dataTestId="estimated-wallet-balance-modal"
                    isOpen={openDocakbleModal}
                    setIsOpen={setOpenDockableModal}
                    title="Available to invest"
                    content={walletBalancesContent(walletBalances)}
                    footerLink={walletBalancesFooter}
                    customZIndex={1051} // same as zindex(detail-modal)
                />
            </>
        </div>
    ) : (
        <div className={styles.soloWalletView}>
            <p className={styles.walletBalanceTitle}>{walletTitle}</p>
            <EstimatedWalletBalance displayCurrency={displayCurrency} exchangeRates={exchangeRates} />
        </div>
    )
}

export default EstimatedWalletBalanceWithModal
