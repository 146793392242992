import React from 'react'
import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {InstrumentOrderContext} from '~/sections/invest/sections/view-instrument/state/OrderContext'
import MooseWalletBalance from '~/sections/moose/widgets/moose-wallet-balance/MooseWalletBalance'
import {State} from '~/store/accounting/types'
import EstimatedWalletBalanceWithModal from './EstimatedWalletBalanceWithModal'

interface WalletBalanceWrapperProps {
    portfolioId?: string
    displayCurrency: Currency
    exchangeRates?: State['exchangeRates']
}

/**
 * Provides a Wallet Balance widget, based on context from ViewInstrument
 */
const WalletBalanceWrapper = ({displayCurrency, exchangeRates, portfolioId}: WalletBalanceWrapperProps) => {
    const instrumentOrderContext = React.useContext(InstrumentOrderContext)
    switch (instrumentOrderContext) {
        case 'fonterra':
            if (portfolioId === undefined) {
                return null
            }
            return <MooseWalletBalance />
        default:
            return <EstimatedWalletBalanceWithModal displayCurrency={displayCurrency} exchangeRates={exchangeRates} />
    }
}

export default WalletBalanceWrapper
