import React from 'react'
import {spacing} from '~/global/scss/helpers'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import GiftingTermsAndConditions from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/GiftingTermsAndConditions'
import {useAppSelector} from '~/store/hooks'

const LegalGiftingTerms: React.FunctionComponent<{}> = () => {
    // NOTE: this asserts user is present. This is fine now, as this page is only accessed from 'legal/gifting-terms'
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)

    return (
        <>
            <Toolbar dataTestId="toolbar--gifting-terms" leftButton="back" title="Legal" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <h2 className={spacing.spaceBelow16}>
                    {jurisdiction === 'au'
                        ? 'AUD Gifts Terms & Conditions'
                        : 'Terms and Conditions for the purchase and use of Sharesies Gift Codes'}
                </h2>

                <GiftingTermsAndConditions />
            </Page>
        </>
    )
}

export default LegalGiftingTerms
