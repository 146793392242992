import {Wallet} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {accessibility} from '~/global/scss/helpers'
import {usePortfolioItemsById} from '~/global/state-hooks/mixed-source/usePortfolioItemsById'
import {
    areAllWalletBalancesZeroOrNegative,
    calculateWalletBalance,
} from '~/global/utils/calculate-wallet-balance/calculateWalletBalance'
import {useExchangeFeeRate} from '~/global/utils/exchange-fee-hooks/exchangeFeeHooks'
import {isWalletBalanceEstimated} from '~/global/utils/is-wallet-balance-estimated/isWalletBalanceEstimated'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import EstimatedWalletBalance from '~/global/widgets/estimated-wallet-balance/EstimatedWalletBalance'
import {ListMenuItem} from '~/global/widgets/list-menu/ListMenu'
import {Loading} from '~/global/widgets/loading'
import {useNavigate} from '~/migrate-react-router'
import {ExchangeRate} from '~/store/accounting/types'
import {essViewsForPortfolio} from '~/store/employeeShareScheme/selectors'
import {useAppSelector} from '~/store/hooks'
import styles from './PortfolioWallet.scss'

const WalletContent = (
    isEstimate: boolean,
    homeWalletValue: string,
    isDependent: boolean,
    preferredName?: string,
    exchangeRates?: ExchangeRate[],
) => {
    // Math.sign returns 1, -1 or 0
    const walletStatus = Math.sign(parseFloat(homeWalletValue))

    if (walletStatus !== 0) {
        return (
            <div className={styles.walletContent}>
                {isDependent ? `${preferredName} has ` : 'You’ve got '}
                {isEstimate && 'an estimated '}
                <span className={styles.walletBalance}>
                    <EstimatedWalletBalance exchangeRates={exchangeRates} />
                </span>{' '}
                in {isDependent ? 'their' : 'your'} Wallet
                {walletStatus !== -1 ? ' ready to invest.' : '.'}
                {walletStatus === -1 && <p className={styles.walletTopup}>Top up</p>}
            </div>
        )
    }
    return (
        <div className={styles.walletContent}>
            Your Wallet is empty. <p className={styles.walletTopup}>Top up</p>
        </div>
    )
}

/**
 * this component is used to display the wallet balance
 */

const PortfolioWallet: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const displayCurrency = useAppSelector(s => s.identity.user!.home_currency)
    const exchangeRates = useAppSelector(s => s.accounting.exchangeRates)
    const hasRecentOrders = useAppSelector(
        s => s.accounting.recentOrders && s.accounting.recentOrders.orders.length > 0,
    )
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const isTopUpCardShown = areAllWalletBalancesZeroOrNegative(useAppSelector(s => s.identity.user!.wallet_balances))
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)
    const usEquitiesEnabled = useAppSelector(s => s.identity.user!.us_equities_enabled)
    const walletBalances = useAppSelector(s => s.identity.user!.wallet_balances)
    const totalPortfolioItems = usePortfolioItemsById()
    const hasInvested = Object.keys(totalPortfolioItems).length > 0
    const essViews = useAppSelector(s => essViewsForPortfolio(s))
    // TODO shared money - use wallet balance from profile
    const exchangeFeeRate = useExchangeFeeRate()
    const profileUrl = useProfileUrl()

    const trackWalletNavigate = () => {
        rudderTrack('topup', 'wallet_navigated', {linked_from: 'portfolio_card'})
    }

    // Wait for the wallet balances to load before proceeding.
    if (!walletBalances) {
        return (
            <span className={styles.container}>
                <Loading />
            </span>
        )
    }

    const isEstimate = isWalletBalanceEstimated(walletBalances, displayCurrency)
    const homeWalletValue = calculateWalletBalance(
        walletBalances,
        exchangeRates,
        usEquitiesEnabled,
        displayCurrency,
        exchangeFeeRate,
    )

    if (essViews.hasEmployment && homeWalletValue === '0') {
        return null
    }
    if (!essViews.hasEmployment && !hasInvested && homeWalletValue !== '0') {
        return null
    }
    if (hasInvested || hasRecentOrders || !isTopUpCardShown) {
        return (
            <div className={styles.walletWrapper}>
                <div className={styles.wallet}>
                    <button
                        className={cn(styles.walletHeader, accessibility.button)}
                        type="button"
                        aria-label="View your wallet."
                        onClick={() => {
                            Analytics.event({
                                category: 'Portfolio',
                                action: 'Clicked wallet',
                                label: 'wallet',
                            })
                            trackWalletNavigate()
                            navigate(profileUrl('wallet'))
                        }}
                    >
                        {WalletContent(isEstimate, homeWalletValue, isDependent, preferredName, exchangeRates)}
                    </button>
                    <ListMenuItem
                        label={
                            <>
                                <Wallet className={styles.listMenuIcon} />
                                View Wallet
                            </>
                        }
                        onClick={() => {
                            navigate(profileUrl('wallet'))
                        }}
                    />
                </div>
            </div>
        )
    }
    return null
}

export default PortfolioWallet
