import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {useNavigate} from 'react-router'
import {constructCoveGetUrl, coveGetUrlQueryString} from '~/api/query/cove'
import {useRetailGet} from '~/api/query/retail'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import config from '~/configForEnv'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Toast} from '~/global/widgets/toast/Toast'
import {constructCoveEmbedUserLoginUrl} from '~/sections/protect/sections/insure/sections/car/utils/cove-embed/coveEmbed'
import {CoveEmbedPage} from '~/sections/protect/sections/insure/sections/car/widgets/cove-embed-page/CoveEmbedPage'
import {CoveIframe} from '~/sections/protect/sections/insure/sections/car/widgets/cove-iframe/CoveIframe'
import {reportNoInsureAccountToRollbar} from '~/sections/protect/sections/insure/utils/report-rollbar-error/reportRollbarError'

export const CovePolicy: React.FunctionComponent<{policyId: string}> = ({policyId}) => {
    return (
        <CoveEmbedPage name="Cove View Policy embed page">
            <CovePolicyIframe policyId={policyId} />
        </CoveEmbedPage>
    )
}

const CovePolicyIframe: React.FunctionComponent<{policyId: string}> = ({policyId}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const queryClient = useQueryClient()

    const {data: insureAccount} = useRetailGet({path: 'insure/get-account'})

    const {data: coveEmbedLogin} = useRetailGet({
        path: 'insure/get-cove-user-embed-login-id',
    })

    if (insureAccount.type !== 'insure_account') {
        // If they have a Cove policy then they must have an Insure account

        reportNoInsureAccountToRollbar()
        throw new Error('No Insure account found when trying to access Cove policy')
    }

    const policyPath = `/user/policy/?pno=${policyId}` // Needs the trailing slash before the query string
    const embedUrl = constructCoveEmbedUserLoginUrl(coveEmbedLogin.login_id, policyPath)

    React.useEffect(() => {
        // Add an event listener that will receive a success message from the iframe
        const handleCoveMsg = (event: {origin: string; type: string; data: {[key: string]: string}}) => {
            if (event.origin !== config.coveEmbedBaseUrl) {
                // Security check - we don't want to interact with other external sites here
                return
            }

            if (event.data.updateStatus === 'success') {
                rudderTrack('insurance_signup', 'payment_completed')
                navigate(profileUrl('protect/insure'), {replace: true})
                Toast('Your policy has been updated')
            }

            if (event.data.cancelStatus === 'success') {
                navigate(profileUrl('protect/insure'), {replace: true})
                Toast('Your policy has been cancelled')
            }
        }
        window.addEventListener('message', handleCoveMsg, false)

        return () => {
            // On the embed page, we can cancel a policy.
            // Therefore, when navigating away from this embed page, we want to pick up this potential change
            // Reset the policy & count query caches to ensure that they'll be refetched next time we call the hook
            const getCovePoliciesUrl = constructCoveGetUrl('coverage/v1/query', insureAccount.cove_customer_id)
            const getCoveCountsUrl = constructCoveGetUrl('customer/v1/counts', insureAccount.cove_customer_id)
            queryClient.resetQueries({queryKey: [coveGetUrlQueryString(getCovePoliciesUrl)]})
            queryClient.resetQueries({queryKey: [coveGetUrlQueryString(getCoveCountsUrl)]})
        }
    }, [])

    return <CoveIframe url={embedUrl} />
}
