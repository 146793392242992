import {Button} from '@design-system/button'
import {DockableModal} from '@design-system/dockable-modal'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {useNavigate} from 'react-router'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {spacing} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import HighlightBlock from '~/global/widgets/highlight-block/HighlightBlock'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {RegistryDetailStateLabel} from '~/sections/invest/sections/transfer-shares/widgets/registry-detail/RegistryDetail'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/transfer/actions'
import {ResponseRegistryDetail} from '~/store/transfer/types'

interface TimestampAndLabel {
    timestamp: DateTime
    label: string
}

const getTimestampAndLabel = (registryDetail: ResponseRegistryDetail): TimestampAndLabel => {
    switch (registryDetail.state) {
        case 'approved':
            return {timestamp: registryDetail.approved_at!, label: 'Verified'}
        case 'rejected':
            return {timestamp: registryDetail.rejected_at!, label: 'Failed'}
        default:
            return {timestamp: registryDetail.created!, label: 'Created'}
    }
}

export const ViewRegistryDetail: React.FunctionComponent<{registryDetailId: string}> = ({registryDetailId}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const notificationContext = React.useContext(NotificationContext)

    const transferLoadingState = useAppSelector(({transfer}) => transfer.transfersLoadingState)
    const [isLoading, setIsLoading] = React.useState(transferLoadingState === 'loading')
    const [modalShown, setModalShown] = React.useState(false)
    const [deleteButtonProcessing, setDeleteButtonProcessing] = React.useState(false)

    const allRegistryDetails = useAppSelector(({transfer}) => transfer.registryDetails)

    React.useEffect(() => {
        if (transferLoadingState === 'loading') {
            setIsLoading(true)
            dispatch(actions.FetchTransfers())
        }

        if (['ready', 'error'].includes(transferLoadingState)) {
            setIsLoading(false)
        }
    }, [transferLoadingState])

    if (isLoading) {
        return <Loading isPineapple />
    }

    const registryDetail = allRegistryDetails.find(registryDetail => registryDetail.id === registryDetailId)!
    const {timestamp, label: timestampLabel} = getTimestampAndLabel(registryDetail)

    const deleteRegistryDetail = async () => {
        try {
            setDeleteButtonProcessing(true)
            const error = await dispatch(actions.DeleteRegistryDetail(registryDetail.id))
            if (error) {
                notificationContext.showModalError({message: error})
                setDeleteButtonProcessing(false)
                setModalShown(false)
                return
            }
            setDeleteButtonProcessing(false)
            navigate(profileUrl('invest/portfolio-transfer-shares'))
            Toast('Investor details deleted')
            setModalShown(false)
        } catch (e) {
            notificationContext.showModalError({message: unknownErrorMessage})
        }
    }

    if (registryDetail.reference_type !== 'CSN') {
        return <WeSlippedUp />
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--registry-details" leftButton="back" hideIntercom />
            <Page>
                <div className={cn(commonStyles.textAndStatusLabel, spacing.spaceBelow16)}>
                    <h1>CSN/HN {registryDetail.reference}</h1>
                    <RegistryDetailStateLabel registryDetail={registryDetail} />
                </div>
                <p className={spacing.spaceBelow4}>NZX registry details</p>
                <p>
                    {timestampLabel} {timestamp.toFormat('MMMM dd, yyyy')}
                </p>
                {registryDetail.state === 'pending' && registryDetail.reference_type === 'CSN' && (
                    <HighlightBlock className={commonStyles.pendingInformationBox}>
                        It takes up to 2 trading days to verify these details.
                    </HighlightBlock>
                )}
                <Button
                    label="Delete registry details"
                    dataTestId="button--delete-registry-detail"
                    type="secondary"
                    additionalClassName={cn(
                        registryDetail.state === 'approved' ? spacing.spaceAbove16 : spacing.spaceAbove32,
                    )}
                    onClick={() => setModalShown(true)}
                />
                <DockableModal
                    dataTestId="modal--confirm-delete-registry-detail"
                    isOpen={modalShown}
                    setIsOpen={setModalShown}
                    title="Delete registry details"
                    content={
                        <div>
                            <p>
                                <strong>CSN/HN: </strong>
                                {registryDetail.reference}
                            </p>
                            <p className={spacing.spaceBelow24}>FIN: ****</p>
                            <p className={spacing.spaceBelow24}>
                                If you delete these details and you add them again later, you’ll need to have them
                                verified again. This takes around two trading days.
                            </p>
                            <p className={spacing.spaceBelow32}>
                                Are you sure you want to delete these investor details?
                            </p>
                            <Button
                                label="Cancel"
                                dataTestId="button--cancel-modal"
                                type="secondary"
                                additionalClassName={spacing.spaceBelow12}
                                onClick={() => setModalShown(false)}
                            />
                            <Button
                                label="Delete registry details"
                                dataTestId="button--delete-registry-detail-modal"
                                onClick={() => deleteRegistryDetail()}
                                processing={deleteButtonProcessing}
                            />
                        </div>
                    }
                    customZIndex={1051} // same as zindex(detail-modal)
                />
            </Page>
        </>
    )
}

export default ViewRegistryDetail
