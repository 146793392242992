import cn from 'classnames'
import React from 'react'
import {Link} from 'react-router-dom'
import {DistillScope} from '~/api/query/distill'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {useEssViewsForInstrument} from '~/global/state-hooks/mixed-source/useEssViewsForInstrument'
import {convertToSimpleURL} from '~/global/utils/convert-to-simple-ur-l/convertToSimpleURL'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {isInstrumentDerivative} from '~/global/utils/is-instrument-derivative/isInstrumentDerivative'
import {Period} from '~/global/utils/time-period/timePeriod'
import useAssetManager from '~/global/utils/use-asset-manager/useAssetManager'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Chips from '~/global/widgets/chips/Chips'
import Concertina from '~/global/widgets/concertina/Concertina'
import {AnimatedDonut} from '~/global/widgets/donut-chart/PieCharts'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {ItemValue, PercentValue} from '~/global/widgets/number-elements/NumberElements'
import {InstrumentRiskDescription, RiskScale} from '~/global/widgets/risk-scale/RiskScale'
import TruncatedText, {AnchorPreventTabbingWhenClipped} from '~/global/widgets/truncated-text/TruncatedText'
import DividendReinvestmentInstrument from '~/sections/invest/sections/dividend-reinvestment-plans/DividendReinvestmentInstrument'
import layoutStyles from '~/sections/invest/sections/view-instrument/ViewInstrument.scss'
import BidAndOfferWrapper from '~/sections/invest/sections/view-instrument/sections/overview/widgets/bid-and-offer/BidAndOffer'
import Fees from '~/sections/invest/sections/view-instrument/sections/overview/widgets/fees/Fees'
import PriceHistory from '~/sections/invest/sections/view-instrument/sections/overview/widgets/price-history/PriceHistory'
import PriceHistorySummary from '~/sections/invest/sections/view-instrument/sections/overview/widgets/price-history-summary/PriceHistorySummary'
import RightsOfferDetails from '~/sections/invest/sections/view-instrument/sections/overview/widgets/rights-offer-details/RightsOfferDetails'
import Stats from '~/sections/invest/sections/view-instrument/sections/overview/widgets/stats/Stats'
import {InstrumentHistoryItem} from '~/store/accounting/selectors'
import {ComparisonPrices, Exchange, Instrument, InstrumentDetailsTab} from '~/store/instrument/types'
import styles from './OverviewTab.scss'

interface EtfHoldings {
    name: string
    value: string
}

export default ({
    tab,
    instrument,
    exchange,
    categoryLabels,
    period,
    jurisdiction,
    setPeriod,
    setHideFooter,
    instrumentHistory,
}: {
    tab: InstrumentDetailsTab
    instrument: Instrument
    exchange?: Exchange
    categoryLabels: string[]
    setPeriod: (period: Period) => void
    period: Period
    jurisdiction: Model.User['jurisdiction']
    setHideFooter?: (isHidden: boolean) => void
    instrumentHistory?: InstrumentHistoryItem[]
}) => {
    const profileUrl = useProfileUrl()

    const [etfHoldings, setEftHoldings] = React.useState<EtfHoldings[] | undefined>()
    const [etfPercent, setEtfPercent] = React.useState<number>(0)
    const assetManager = useAssetManager(instrument, {scope: DistillScope.INVEST})

    const comparisonPrice = instrument.comparisonPrices[`_${period}` as keyof ComparisonPrices]

    React.useEffect(() => {
        if (instrument.etfHoldings) {
            const holdingsAsObj = JSON.parse(instrument.etfHoldings)
            const holdingsAsArr = Object.keys(holdingsAsObj).map(k => ({name: k, value: holdingsAsObj[k]}))

            let totalPercent = 0
            holdingsAsArr.forEach(i => (totalPercent += parseFloat(i.value)))

            setEftHoldings(holdingsAsArr)
            setEtfPercent(totalPercent)
        }
    }, [instrument.etfHoldings])

    /*
     * Investors looking at an Alignment Right Allocation Instrumemnt
     * do not have the ability to buy and sell the underlying instrument
     * and so the Fees and Risk rating are not relevant information for them
     */
    const essViews = useEssViewsForInstrument(instrument.id)
    const showFees = !essViews.hasAlignmentRightAllocation
    const showRisk = !essViews.hasAlignmentRightAllocation

    return (
        <section
            id="overview"
            style={{display: tab === 'Overview' ? 'block' : 'none'}}
            role="tabpanel"
            aria-labelledby="overview-tab"
        >
            <TruncatedText className={layoutStyles.instrumentDescription}>
                {/* TODO - add ellipises to the end of the given cut off sentence*/}
                <p className={styles.description}>{instrument.description}</p>
                {instrument.ceo && (
                    <span className={layoutStyles.extraInfo}>
                        <p>CEO: </p>
                        <p>{instrument.ceo}</p>
                    </span>
                )}
                {instrument.employees !== 0 && instrument.employees && (
                    <div className={layoutStyles.extraInfo}>
                        <p>Employees: </p>
                        <p>
                            <ItemValue value={instrument.employees} />
                        </p>
                    </div>
                )}
                {instrument.websiteUrl && (
                    <div className={layoutStyles.extraInfo}>
                        <p>Website: </p>
                        <AnchorPreventTabbingWhenClipped
                            href={
                                instrument.websiteUrl.includes('://')
                                    ? instrument.websiteUrl
                                    : 'https://' + instrument.websiteUrl
                            }
                            target="_blank"
                            rel="noopener"
                        >
                            {convertToSimpleURL(instrument.websiteUrl)}
                        </AnchorPreventTabbingWhenClipped>
                    </div>
                )}
                {/* See MR for PMG exclusion here: https://gitlab.com/sharesies/retail-frontend/-/merge_requests/1734 */}
                {assetManager?.websiteUrl && instrument.symbol !== 'PMGGEN' && (
                    <div className={layoutStyles.extraInfo}>
                        <p>Provider: </p>
                        <AnchorPreventTabbingWhenClipped href={assetManager.websiteUrl} target="_blank" rel="noopener">
                            {convertToSimpleURL(assetManager.websiteUrl)}
                        </AnchorPreventTabbingWhenClipped>
                    </div>
                )}
                {instrument.pdsDriveId && (
                    <div className={layoutStyles.extraInfo}>
                        <p>View: </p>
                        <AnchorPreventTabbingWhenClipped
                            href={`https://drive.google.com/file/d/${instrument.pdsDriveId}/preview`}
                            target="_blank"
                            rel="noopener"
                        >
                            Product Disclosure Statement
                        </AnchorPreventTabbingWhenClipped>
                    </div>
                )}
            </TruncatedText>
            {categoryLabels.length > 0 && (
                <div>
                    <Chips options={categoryLabels} />
                </div>
            )}
            {isInstrumentDerivative(instrument) && <RightsOfferDetails instrument={instrument} />}
            {etfHoldings && etfPercent > 0 && (
                <div className={cn(styles.container, spacing.spaceAbove16)}>
                    <Concertina
                        largeChevron
                        title={
                            <div className={styles.titleContent}>
                                <AnimatedDonut values={[etfPercent, 100 - etfPercent]} filter="ETF" />
                                <span className={styles.toggleLabel}>
                                    {`${etfHoldings[0].name} ${
                                        etfHoldings.length > 1
                                            ? `and ${etfHoldings.length - 1} other investment${
                                                  etfHoldings.length > 2 ? 's' : ''
                                              }`
                                            : ''
                                    } make${etfHoldings.length === 1 ? 's' : ''} up `}
                                    <PercentValue value={etfPercent} />
                                    {` of this fund`}
                                </span>
                            </div>
                        }
                        body={
                            <div className={styles.bodyContent}>
                                {etfHoldings.map((holding, i) => (
                                    <div className={styles.row} key={i}>
                                        <span className={styles.name}>{holding.name}</span>
                                        <span className={styles.value}>
                                            <PercentValue value={holding.value} />
                                        </span>
                                    </div>
                                ))}
                            </div>
                        }
                    />
                </div>
            )}
            <DividendReinvestmentInstrument instrument={instrument} />

            {/* TODO: Return from distill whether an instrument has ever been tradable */}
            {(!isInstrumentDerivative(instrument) || !isInstrumentInNoTrade(instrument)) && (
                <>
                    <BidAndOfferWrapper instrument={instrument} />

                    <h2 className={cn(layoutStyles.sectionHeader, spacing.spaceAbove32)}>Prices</h2>

                    {instrumentHistory && instrumentHistory.length > 0 && (
                        <PriceHistory
                            period={period}
                            setPeriod={setPeriod}
                            instrument={instrument}
                            exchange={exchange}
                            instrumentHistory={instrumentHistory}
                        />
                    )}
                    <PriceHistorySummary period={period} comparisonPrices={comparisonPrice} />

                    {/* Instrument derivatives have no stats */}
                    {!isInstrumentDerivative(instrument) && (
                        <>
                            <h2 className={cn(layoutStyles.sectionHeader, spacing.spaceAbove32)}>Stats</h2>
                            <Stats instrument={instrument} setHideFooter={setHideFooter} />
                        </>
                    )}
                    {showFees && (
                        <>
                            <h2 className={cn(layoutStyles.sectionHeader, spacing.spaceAbove32)}>Fees</h2>
                            <Fees instrument={instrument} jurisdiction={jurisdiction} setHideFooter={setHideFooter} />
                        </>
                    )}
                </>
            )}

            {instrument.isAdr && (
                <div className={styles.adrDisclaimer}>
                    American depositary receipts (ADRs) represent the shares of a company based outside of the US. ADRs
                    trade on US exchanges like other investments, but have different fees and taxes. For more info,
                    check out our{' '}
                    <HelpCentreLink
                        auArticle="5410557-american-depositary-receipts-adrs"
                        nzArticle="5410563-american-depositary-receipts-adrs"
                    />
                    .
                </div>
            )}
            {showRisk && (
                <>
                    <div className={styles.riskLevel}>
                        <h2
                            className={cn(layoutStyles.sectionHeader, spacing.spaceAbove32)}
                            aria-label={`Risk level: This investment has a risk level of ${instrument.riskRating}`}
                        >
                            Risk level
                        </h2>

                        <RiskScale rating={instrument.riskRating} />
                        <InstrumentRiskDescription
                            rating={instrument.riskRating}
                            learnMore={
                                <>
                                    Learn more about{' '}
                                    <Link
                                        to={profileUrl('invest/learn/:slug', {
                                            slug: 'investment-risk-levels',
                                        })}
                                    >
                                        investment risk levels
                                    </Link>
                                    .
                                </>
                            }
                        />

                        {/*Required to meet our Design & Distribution Obligations (DDO)*/}
                        {jurisdiction === 'au' &&
                            instrument.instrumentType === 'etf' &&
                            instrument.exchange === 'ASX' && (
                                <p className={spacing.spaceAbove16}>
                                    For more information on this exchange-traded fund (ETF), its factsheet and its
                                    Target Market Determination (TMD), visit the ETF provider’s website.
                                </p>
                            )}
                    </div>
                    <hr className={styles.divider} />
                </>
            )}
            <p className={cn(styles.dataDisclaimer, spacing.spaceAbove12)}>
                Data displayed is from the NZX, LSEG, fund providers, and XE. It’s indicative only and its accuracy or
                completeness is not guaranteed.
            </p>
        </section>
    )
}
