import {CombinedColours} from '@design-system/colour-tokens'
import {ArrowLeft} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {accessibility} from '~/global/scss/helpers'
import {useAppSelector} from '~/store/hooks'
import styles from './PageBack.scss'

interface PageBackProps {
    buttonColour?: CombinedColours
    onClick?: () => void
    positionStatic?: boolean
}

const PageBack: React.FunctionComponent<PageBackProps> = ({
    buttonColour = 'IconDefault',
    onClick,
    positionStatic = false,
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const toolbarHidden = useAppSelector(s => s.nav.toolbarHidden)

    if (toolbarHidden) {
        return <div />
    }
    return (
        <button
            type="button"
            data-testid="button--arrow-back"
            className={cn(styles.button, {[styles.positionStatic]: positionStatic}, accessibility.button)}
            onClick={
                onClick ||
                (() => {
                    if (location.key !== 'default') {
                        // if there's a browser history https://stackoverflow.com/a/70532858
                        navigate(-1)
                    } else {
                        // fall back if no browser history
                        navigate('..')
                    }
                })
            }
        >
            <ArrowLeft aria-label="Go back" colour={buttonColour} />
        </button>
    )
}

export default PageBack
