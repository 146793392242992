import {Thumbnail} from '@design-system/thumbnail'
import cn from 'classnames'
import React from 'react'
import {useAppSelector} from '~/store/hooks'
import {BasicInstrument} from '~/store/instrument/types'
import styles from './InstrumentLogo.scss'

interface InstrumentLogoProps {
    instrument: BasicInstrument
    noBorder?: boolean
    additionalClassName?: string
    height?: string
    width?: string
}

const InstrumentLogo: React.FunctionComponent<InstrumentLogoProps> = ({
    instrument,
    noBorder,
    additionalClassName,
    height,
    width,
}) => {
    const path = useAppSelector(({instrument}) => instrument.metadata.resourcePath)

    return (
        <div className={styles.logo}>
            <Thumbnail
                width={width || '48px'}
                height={height || width || '48px'}
                dataTestId="thumbnail--logo"
                symbol={instrument.symbol}
                path={instrument.logos.micro ? `${path}${instrument.logos.micro}` : undefined}
                noBorder={noBorder}
                additionalClassName={cn(styles.instrumentImage, additionalClassName)}
            />
        </div>
    )
}

export default InstrumentLogo
