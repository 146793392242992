import React from 'react'
import {useExchangeFeeRate} from '~/global/utils/exchange-fee-hooks/exchangeFeeHooks'
import {isInstrumentHighRiskDerivative} from '~/global/utils/is-instrument-high-risk-derivative/isInstrumentHighRiskDerivative'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {useExchangeRates} from '~/global/utils/use-exchange-rates/useExchangeRates'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ExchangeHours} from '~/global/widgets/exchange-hours/ExchangeHours'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import {useNavigate} from '~/migrate-react-router'
import {
    DOLLAR_MARKET,
    DOLLAR_LIMIT,
    SHARE_LIMIT,
    DOLLAR_TRIGGER,
} from '~/sections/invest/sections/order-flow/constants/orderTypes'
import BuyOrderType from '~/sections/invest/sections/order-flow/sections/buy/pages/buy-order-type/BuyOrderType'
import AutoExerciseOptions from '~/sections/invest/sections/order-flow/sections/buy/widgets/auto-exercise/AutoExerciseOptions'
import BuyErrors from '~/sections/invest/sections/order-flow/sections/buy/widgets/buy-errors/BuyErrors'
import {ExchangeRateXeUnreachable} from '~/sections/invest/sections/order-flow/widgets/modals/ExchangeRateErrorModals'
import HighRiskDerivativeModal from '~/sections/invest/sections/order-flow/widgets/modals/HighRiskDerivativeModal'
import OrderFormHeader from '~/sections/invest/sections/order-flow/widgets/order-form-header/OrderFormHeader'
import {canMakeAutoExerciseBuyOrder} from '~/store/accounting/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import actions from '~/store/order/actions'
import {StagedBuyOrder} from '~/store/order/types'
import LimitBuyInDollars from './forms/LimitBuyInDollars'
import LimitBuyInShares from './forms/LimitBuyInShares'
import MarketBuyInDollars from './forms/MarketBuyInDollars'
import TriggerBuyInDollars from './forms/TriggerBuyInDollars'

const ORDER_TYPE = 'order-type'
const ORDER_FORM = 'order-form'
const ORDER_EXERCISE_OPTIONS = 'order-exercise-options'
type Step = typeof ORDER_TYPE | typeof ORDER_FORM | typeof ORDER_EXERCISE_OPTIONS

interface BuyOrderFormProps {
    instrument: Instrument
}

const BuyOrderForm: React.FunctionComponent<BuyOrderFormProps> = ({instrument}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const stagedBuyOrder = useAppSelector(s => s.order.stagedBuyOrder)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)

    const initialiseStagedBuyOrder = (fundId: string, orderType: StagedBuyOrder['orderType']) =>
        dispatch(actions.InitialiseStagedBuyOrder(fundId, orderType))
    const updateStagedBuyOrder = (order: StagedBuyOrder) => dispatch(actions.UpdateStagedBuyOrder(order))
    const clearStagedBuyOrder = () => dispatch(actions.ClearStagedBuyOrder())

    const hasStagedBuyOrderType = stagedBuyOrder && stagedBuyOrder.orderType
    // if auto exercising rights is an option for this buy order, make it the initial step
    const canMakeAutoExerciseBuy = useAppSelector(s => canMakeAutoExerciseBuyOrder(s, instrument.id))
    const initialStep = (): Step => {
        if (hasStagedBuyOrderType) {
            return ORDER_FORM
        }
        return canMakeAutoExerciseBuy ? ORDER_EXERCISE_OPTIONS : ORDER_TYPE
    }
    const [step, setStep] = React.useState<Step>(initialStep())
    const [autoExercise, setAutoExercise] = React.useState<boolean | undefined>(stagedBuyOrder?.autoExercise)
    const [exchangeRates, exchangeRateError] = useExchangeRates()
    const isManagedFund = tradingType(instrument) === 'managed'
    const orderType = stagedBuyOrder ? stagedBuyOrder.orderType : undefined
    const exchangeFeeRate = useExchangeFeeRate()

    React.useEffect(() => {
        if (isManagedFund) {
            // Don't need to have an order type
            setStep(ORDER_FORM)
            initialiseStagedBuyOrder(instrument.id, DOLLAR_MARKET)
        }
    }, [instrument])

    // non-managed fund (ETF, companies) and the order type is selected
    const renderBuyOrderForm = React.useMemo(() => {
        if (exchangeRateError) {
            return <ExchangeRateXeUnreachable onClick={() => navigate(-1)} />
        }
        if (isManagedFund) {
            return (
                <MarketBuyInDollars
                    instrument={instrument}
                    exchangeRates={exchangeRates}
                    exchangeFeeRate={exchangeFeeRate}
                    profileUrl={profileUrl}
                />
            )
        }
        switch (orderType) {
            case DOLLAR_MARKET:
                return (
                    <MarketBuyInDollars
                        instrument={instrument}
                        exchangeRates={exchangeRates}
                        exchangeFeeRate={exchangeFeeRate}
                        profileUrl={profileUrl}
                    />
                )
            case DOLLAR_LIMIT:
                return (
                    <LimitBuyInDollars
                        instrument={instrument}
                        exchangeRates={exchangeRates}
                        exchangeFeeRate={exchangeFeeRate}
                        profileUrl={profileUrl}
                    />
                )
            case DOLLAR_TRIGGER:
                return (
                    <TriggerBuyInDollars
                        instrument={instrument}
                        exchangeRates={exchangeRates}
                        exchangeFeeRate={exchangeFeeRate}
                        profileUrl={profileUrl}
                    />
                )
            case SHARE_LIMIT:
                return (
                    <LimitBuyInShares
                        instrument={instrument}
                        exchangeRates={exchangeRates}
                        exchangeFeeRate={exchangeFeeRate}
                        profileUrl={profileUrl}
                    />
                )
        }
    }, [orderType, instrument, exchangeRates, exchangeRateError])

    if (step === 'order-exercise-options' && canMakeAutoExerciseBuy) {
        return (
            <AutoExerciseOptions
                instrument={instrument}
                autoExercise={autoExercise}
                onContinue={(autoExercise: boolean) => {
                    setAutoExercise(autoExercise)
                    setStep(ORDER_TYPE)
                }}
            />
        )
    }

    if (step === 'order-type') {
        // Should select an order type first
        return (
            <BuyOrderType
                instrument={instrument}
                onContinue={() => setStep(ORDER_FORM)}
                // go back a step if there is one, otherwise default behaviour
                onPageBack={() => {
                    canMakeAutoExerciseBuy ? setStep(ORDER_EXERCISE_OPTIONS) : navigate(-1)
                }}
                autoExercise={autoExercise}
            />
        )
    }

    return (
        <>
            <Page>
                <PageBack
                    onClick={() => {
                        if (stagedBuyOrder?.dividendReinvestId) {
                            // Go back to notifications page
                            navigate(-1)
                            return
                        }

                        clearStagedBuyOrder()
                        isManagedFund ? navigate(-1) : setStep(ORDER_TYPE)
                    }}
                />

                {!isManagedFund && <ExchangeHours instrument={instrument} />}

                <OrderFormHeader instrument={instrument} buyOrSell="buy" onOrderTypeClick={() => setStep(ORDER_TYPE)} />

                {step === 'order-form' && <>{renderBuyOrderForm}</>}

                {stagedBuyOrder && stagedBuyOrder.error && (
                    <BuyErrors
                        jurisdiction={jurisdiction}
                        stagedBuyOrder={stagedBuyOrder}
                        instrument={instrument}
                        isDependent={isDependent}
                        preferredName={preferredName}
                        page="form"
                        updateStagedBuyOrder={updateStagedBuyOrder}
                    />
                )}

                {isInstrumentHighRiskDerivative(instrument) && (
                    <HighRiskDerivativeModal instrument={instrument} onCancel={() => navigate(-1)} />
                )}
            </Page>
        </>
    )
}

export default BuyOrderForm
