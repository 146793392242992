import {useQueryClient} from '@tanstack/react-query'
import {DateTime} from 'luxon'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import {Response} from '~/api/retail/types'
import {useAppSelector} from '~/store/hooks'
import {actingAsID} from '~/store/identity/selectors'

export const useWatchlist = () => {
    const acting_as_id = useAppSelector(actingAsID)
    const queryClient = useQueryClient()

    const {data} = useRetailGet({
        path: 'identity/watchlist-v3',
        payload: {acting_as_id},
    })
    const addToWatchlistMutation = useRetailPost({path: 'identity/watchlist-v3-add'})
    const removeFromWatchlistMutation = useRetailPost({path: 'identity/watchlist-v3-remove'})
    // NOTE: These purposefully doesn't use 'queryCacheToUpdate'.
    // The behaviour is unexpeced when a customer mutates watchlist instruments in quick succession.

    const updateCache = (newResponse: Response.WatchlistV3) => {
        // Optimistically update query cache before the POST resolves
        const queryKey = ['identity/watchlist-v3', {acting_as_id}]
        queryClient.setQueryData(queryKey, newResponse)
    }

    const addToWatchlist = (fund_id: string) => {
        const lastResponse = data
        updateCache({
            ...lastResponse,
            watchlist: [...lastResponse.watchlist, {fund_id, created: DateTime.now()}],
        })

        addToWatchlistMutation.mutateAsync({acting_as_id, fund_id})
    }

    const removeFromWatchlist = (fund_id: string) => {
        const lastResponse = data
        updateCache({
            ...lastResponse,
            watchlist: lastResponse.watchlist.filter(item => item.fund_id !== fund_id),
        })

        removeFromWatchlistMutation.mutateAsync({acting_as_id, fund_id})
    }

    return {
        watchlistInstrumentIds: data.watchlist.map(item => item.fund_id),
        addToWatchlist,
        removeFromWatchlist,
        watchlistItemsWithCreated: data.watchlist,
    }
}
