import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal'
import {Thumbnail} from '@design-system/thumbnail'
import React from 'react'
import {useNavigate} from 'react-router'
import {ListingResponseDto} from '~/api/distill'
import {DistillScope} from '~/api/query/distill'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import useDistillInstrumentInfo from '~/global/state-hooks/distill/useDistillInstrumentInfo'
import capitalise from '~/global/utils/capitalise-string/capitaliseString'
import Chips from '~/global/widgets/chips/Chips'
import {PercentValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {generateInitialFilter, useDistillSearch} from '~/sections/kiwisaver/data/distill'
import {usePlanEditorUrl} from '~/sections/kiwisaver/sections/edit-investment-plan/hooks/usePlanEditorUrl'
import styles from '~/sections/kiwisaver/sections/sign-up/index.scss'
import {
    SignUpGuardRedirect,
    SignUpPage,
} from '~/sections/kiwisaver/sections/sign-up/widgets/redirect/SignUpGuardRedirect'
import {useExistingKSCustomer} from '~/sections/kiwisaver/state'
import {PageTitle} from '~/sections/kiwisaver/widgets/page-title/PageTitle'
import {BasicInstrument} from '~/store/instrument/types'
import WithdrawalTimeline from './withdrawal-timeline/WithdrawalTimeline'

const FundSelect: React.FunctionComponent<{
    instrument: BasicInstrument
    wrapperInstrument: BasicInstrument
}> = ({instrument, wrapperInstrument}) => {
    const [submitting, setSubmitting] = React.useState<string | undefined>(undefined)
    const isSubmitting = !!(submitting && submitting === instrument.id)
    const isDisabled = !!(submitting && submitting !== instrument.id)
    const customer = useExistingKSCustomer()
    const relativeUrlFor = usePlanEditorUrl()
    const resourcePath = useDistillInstrumentInfo(DistillScope.KIWISAVER_ALL_FUNDS, {
        searchFundInvestments: true,
    }).filesHostAddress

    const navigate = useNavigate()

    const select = async (instrumentId: string) => {
        setSubmitting(instrumentId)
        rudderTrack('kiwisaver_signup', 'fund_selection_clicked', {
            instrument_id: instrumentId,
            type: customer.customer_state === 'SIGNUP' ? 'signup' : 'edit',
        })

        navigate(relativeUrlFor('view-fund/:urlSlug', {urlSlug: instrument.urlSlug}))
    }

    return (
        <div className={styles.baseFundSelect}>
            <Thumbnail
                dataTestId={`${instrument.urlSlug}--logo`}
                width="78px"
                height="78px"
                symbol={instrument.symbol}
                path={instrument.logos.thumb ? `${resourcePath}${instrument.logos.thumb}` : undefined}
            />
            <h2 className={spacing.spaceAbove8}>{instrument.name}</h2>
            <ul>
                <li>Timeframe of {wrapperInstrument.timeHorizonMin}+ years</li>
                <li className={styles.managementHelpButton}>
                    {wrapperInstrument.managementType}ly managed
                    <span className={styles.iconSpan}>
                        <ModalLink
                            label="Active and passive management"
                            modalTitle="Active and passive management"
                            dataTestId="modal-link--fund-management-explanation"
                            primaryButton={{label: 'Okay'}}
                            helpIconSize={16}
                            asIcon
                        >
                            <p>
                                Actively managed funds have fund managers who pick specific investments. This can result
                                in these funds having higher fees than passively managed funds. Passively managed funds
                                follow a set of rules. They tend to track the movements of the overall market and can
                                have lower fees.
                            </p>
                        </ModalLink>
                    </span>
                </li>
                <li>
                    <PercentValue value={parseFloat(wrapperInstrument.managementFeePercent!) * 100} /> annual fund
                    charge
                </li>
            </ul>
            <Button
                dataTestId="button--select"
                type="secondary"
                processing={isSubmitting}
                disabled={isDisabled}
                label="Check it out"
                onClick={() => select(instrument.id)}
            />
        </div>
    )
}
export const SetBaseFund: React.FunctionComponent<{}> = () => {
    const customer = useExistingKSCustomer()
    const interestedIn = customer.interested_in ?? 'GROWTH'
    const [selected, setSelected] = React.useState<typeof interestedIn>(interestedIn)
    const initialFilter = generateInitialFilter(DistillScope.KIWISAVER_BASE_FUNDS)

    const instruments = useDistillSearch({
        ...initialFilter,
        searchFundInvestments: true,
    })

    /*
        We have to randomise the order of our base funds, and Distill doesn't have a shuffle function.
        So we're just shuffling here in the front end.
        Putting the instruments into a memo makes sure the page doesn't render while shuffling
    */
    const shuffle = (array: ListingResponseDto[]) => {
        return array.sort(() => Math.random() - 0.5)
    }
    const shuffledInstruments = React.useMemo(() => {
        return shuffle(instruments)
    }, [])

    const wrapperInstruments = useDistillSearch({
        ...initialFilter,
        searchFundInvestments: false,
    })

    const filterNames: (typeof interestedIn)[] = ['AGGRESSIVE', 'GROWTH', 'BALANCED', 'CONSERVATIVE']

    return (
        <SignUpGuardRedirect currentPage={SignUpPage.EDIT_INVESTMENT_PLAN_BASE}>
            <Toolbar leftButton="back" dataTestId="toolbar--set-base-fund" />
            <Page>
                <PageTitle className={spacing.spaceBelow16}>Funds</PageTitle>
                <div className={(spacing.spaceAbove16, spacing.spaceBelow16)}>
                    <Chips
                        options={filterNames.map(fundType => capitalise(fundType.toLowerCase()))}
                        onChipClick={selected => setSelected(selected as typeof interestedIn)}
                        selected={[capitalise(selected.toLowerCase())]}
                        isInlineDisplay
                    />
                </div>
                <WithdrawalTimeline customer={customer} />

                {shuffledInstruments.map(instrument => (
                    <div key={instrument.id}>
                        {/*
                           NOTE: Only display the instruments that the selected filter has included in the name
                           Apparently, we don't have the category as `GROWTH ,BALANCED, CONSERVATIVE` yet.
                           Here is the slack conversation: https://sharesiesteam.slack.com/archives/C022L7RSPTQ/p1684722438939819
                        */}
                        {instrument.name.includes(capitalise(selected.toLowerCase())) && (
                            <FundSelect
                                instrument={instrument}
                                wrapperInstrument={wrapperInstruments.filter(x => x.id === instrument.fmsFundId)[0]}
                            />
                        )}
                    </div>
                ))}
            </Page>
        </SignUpGuardRedirect>
    )
}
