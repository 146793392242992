import React from 'react'
import {setGlobalStyleVHVariable} from '~/global/utils/set-global-style-vh-variable/setGlobalStyleVHVariable'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {useExchangeRates} from '~/global/utils/use-exchange-rates/useExchangeRates'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ExchangeHours} from '~/global/widgets/exchange-hours/ExchangeHours'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import {useNavigate} from '~/migrate-react-router'
import {SHARE_LIMIT, SHARE_MARKET, SHARE_TRIGGER} from '~/sections/invest/sections/order-flow/constants/orderTypes'
import SellOrderType from '~/sections/invest/sections/order-flow/sections/sell/pages/sell-order-type/SellOrderType'
import SellErrors from '~/sections/invest/sections/order-flow/sections/sell/widgets/sell-errors/SellErrors'
import {ExchangeRateXeUnreachable} from '~/sections/invest/sections/order-flow/widgets/modals/ExchangeRateErrorModals'
import OrderFormHeader from '~/sections/invest/sections/order-flow/widgets/order-form-header/OrderFormHeader'
import {connect} from '~/store/connect'
import {FundHolding} from '~/store/identity/types'
import {Instrument} from '~/store/instrument/types'
import actions from '~/store/order/actions'
import {StagedSellOrder} from '~/store/order/types'
import LimitSellInShares from './forms/LimitSellInShares'
import MarketSellInShares from './forms/MarketSellInShares'
import TriggerSellInShares from './forms/TriggerSellInShares'
import styles from './SellOrderForm.scss'

const ORDER_TYPE = 'order-type'
const ORDER_FORM = 'order-form'
type Step = typeof ORDER_TYPE | typeof ORDER_FORM

const SellOrderForm: React.FunctionComponent<SellOrderFormProps> = ({
    instrument,
    ksFundHolding,
    initialiseStagedSellOrder,
    stagedSellOrder,
    isDependent,
    preferredName,
    updateStagedSellOrder,
}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const [step, setStep] = React.useState<Step>(stagedSellOrder && stagedSellOrder.orderType ? ORDER_FORM : ORDER_TYPE)
    const [exchangeRates, exchangeRateError] = useExchangeRates()
    const isManagedFund = tradingType(instrument) === 'managed'
    const orderType = stagedSellOrder ? stagedSellOrder.orderType : undefined

    React.useEffect(() => {
        if (isManagedFund) {
            // Don't need to have an order type
            setStep(ORDER_FORM)
        }
        initialiseStagedSellOrder(instrument.id, SHARE_MARKET, ksFundHolding)
    }, [instrument])

    React.useEffect(() => {
        setGlobalStyleVHVariable()
        // update on browser resize
        window.addEventListener('resize', setGlobalStyleVHVariable)

        return () => {
            // remove listener
            window.removeEventListener('resize', setGlobalStyleVHVariable)

            // remove custom property from document root
            document.documentElement.style.removeProperty('--vh')
        }
    })

    // non-managed fund (ETF, companies) and the order type is selected
    const renderSellOrderForm = React.useMemo(() => {
        if (exchangeRateError) {
            return <ExchangeRateXeUnreachable onClick={() => navigate(-1)} />
        }

        if (isManagedFund) {
            return <MarketSellInShares instrument={instrument} exchangeRates={exchangeRates} profileUrl={profileUrl} />
        }
        switch (orderType) {
            case SHARE_MARKET:
                return (
                    <MarketSellInShares instrument={instrument} exchangeRates={exchangeRates} profileUrl={profileUrl} />
                )
            case SHARE_LIMIT:
                return (
                    <LimitSellInShares instrument={instrument} exchangeRates={exchangeRates} profileUrl={profileUrl} />
                )
            case SHARE_TRIGGER:
                return (
                    <TriggerSellInShares
                        instrument={instrument}
                        exchangeRates={exchangeRates}
                        profileUrl={profileUrl}
                    />
                )
        }
    }, [orderType, instrument, exchangeRateError])

    if (step === 'order-type') {
        // Should select an order type first
        return <SellOrderType instrument={instrument} onContinue={() => setStep(ORDER_FORM)} />
    }

    return (
        <Page className={styles.sellOrderFormPage}>
            <PageBack />
            {!isManagedFund && <ExchangeHours instrument={instrument} />}
            <OrderFormHeader instrument={instrument} buyOrSell="sell" onOrderTypeClick={() => setStep(ORDER_TYPE)} />

            {step === 'order-form' && <>{renderSellOrderForm}</>}
            {stagedSellOrder && stagedSellOrder.error && (
                <SellErrors
                    stagedSellOrder={stagedSellOrder}
                    instrumentName={instrument.name}
                    isDependent={isDependent}
                    preferredName={preferredName}
                    updateStagedSellOrder={updateStagedSellOrder}
                />
            )}
        </Page>
    )
}

interface OwnProps {
    instrument: Instrument
    ksFundHolding?: FundHolding
}

interface StoreProps {
    stagedSellOrder?: StagedSellOrder
    isDependent: boolean
    preferredName: string
}

interface DispatchProps {
    initialiseStagedSellOrder(
        fundId: string,
        sellOrderType: StagedSellOrder['orderType'],
        ksFundHolding?: FundHolding,
    ): void
    updateStagedSellOrder(order: StagedSellOrder): void
}

type SellOrderFormProps = StoreProps & OwnProps & DispatchProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    state => ({
        stagedSellOrder: state.order.stagedSellOrder,
        preferredName: state.identity.user!.preferred_name,
        isDependent: state.identity.user!.is_dependent,
    }),
    dispatch => ({
        initialiseStagedSellOrder: (fundId, orderType, ksFundHolding) =>
            dispatch(actions.InitialiseStagedSellOrder(fundId, orderType, ksFundHolding)),
        updateStagedSellOrder: order => dispatch(actions.UpdateStagedSellOrder(order)),
    }),
)(SellOrderForm)
