import {Button} from '@design-system/button'
import {DockableModal} from '@design-system/dockable-modal'
import {Wallet, ChevronDown} from '@design-system/icon'
import {Loader} from '@design-system/loader'
import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import Decimal from 'decimal.js'
import {withFormik} from 'formik'
import React from 'react'
import {Request} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {accessibility, spacing} from '~/global/scss/helpers'
import {validate} from '~/global/widgets/form-controls'
import {StrongCurrency} from '~/global/widgets/form-controls/formik'
import {DollarValue, FeeValue} from '~/global/widgets/number-elements/NumberElements'
import {OrderConfirmation, OrderRow} from '~/global/widgets/order-confirmation/OrderConfirmation'
import {Toast} from '~/global/widgets/toast/Toast'
import {ERROR_MESSAGE} from '~/sections/OLD_wallet/sections/top-up/sections/top-up-instant/constants/errorMessage'
import {
    AsbLogo,
    BnzLogo,
    WestpacLogo,
} from '~/sections/OLD_wallet/sections/top-up/sections/top-up-instant/widgets/bank-logos/Logos'
import CantSeeBank from '~/sections/OLD_wallet/sections/top-up/sections/top-up-instant/widgets/cant-see-bank/CantSeeBank'
import actions from '~/store/bankLinking/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import styles from './Form.scss'

type Bank = Request.BlinkPayPaymentRequest['bank']

interface TopUpInstantFormValues {
    topUpAmount: string
    bank?: Bank
}

interface TopUpInstantFormProps {
    setShowForm: (value: boolean) => void
    setTopUpAmount: (amount: string) => void
    setBank: (bank: Bank) => void
    bankOptions: string[]
    walletBalance: string
}

interface BankModalContentProps {
    bankOptions: string[]
    handleSelectBank: (bank: string) => void
}

const BankModalContent: React.FunctionComponent<BankModalContentProps> = ({handleSelectBank, bankOptions}) => {
    return (
        <ul className={styles.selectBankList}>
            {bankOptions.map(bank => (
                <li key={bank}>
                    <button
                        data-testid={`button--select-bank-${bank}`}
                        type="button"
                        className={cn(styles.selectBankButton, accessibility.button)}
                        onClick={() => handleSelectBank(bank)}
                    >
                        <div className={styles.buttonContentWrapper}>
                            <div className={styles.buttonWrapper}>
                                {bank === 'ASB' && <AsbLogo />}
                                {bank === 'BNZ' && <BnzLogo />}
                                {bank === 'Westpac' && <WestpacLogo />}
                            </div>
                            <div>
                                <h3>{bank}</h3>
                            </div>
                        </div>
                        <div />
                    </button>
                </li>
            ))}
        </ul>
    )
}

const Form = withFormik<TopUpInstantFormProps, TopUpInstantFormValues>({
    mapPropsToValues: () => ({
        topUpAmount: '0.00',
        bank: undefined,
    }),
    validateOnChange: true,
    handleSubmit: async ({topUpAmount, bank}, {props: {setTopUpAmount, setBank, setShowForm}}) => {
        setTopUpAmount(topUpAmount)

        if (bank) {
            setBank(bank)
        }

        setShowForm(false)
    },
    validate: validate.generateWithProps<{}, {}>(() => ({
        topUpAmount: [
            validate.required(),
            validate.money({maxDecimalPlaces: 2}),
            validate.maximum(49999, 'The maximum top up amount is $49,999'),
        ],
        bank: [validate.required()],
    })),
    validateOnMount: true,
})(({
    // Formik props
    handleSubmit,
    setFieldValue,
    isValid,
    isSubmitting,

    // Passed in props
    values: {bank},
    bankOptions,
    walletBalance,
}) => {
    const choices = [{value: '', label: 'Choose'}]
    bankOptions.forEach(element => {
        choices.push({value: element, label: element})
    })

    const [bankPickerDisplay, setBankPickerDisplay] = React.useState(false)

    return (
        <form onSubmit={handleSubmit}>
            <h1>Instant bank transfer</h1>
            <div className={styles.walletBalance}>
                <Wallet />
                <div className={styles.strong}>
                    <DollarValue value={walletBalance} /> NZD
                </div>
                <div className={styles.normal}>&nbsp;in your wallet.</div>
            </div>
            <StrongCurrency
                dataTestId="top-up-amount"
                name="topUpAmount"
                label="Amount to top up"
                currency="nzd"
                decimalPlaces={2}
                additionalClassName={styles.topUpAmount}
            />
            <label htmlFor="bank" className={styles.bankButtonLabel}>
                Bank
            </label>
            {bank ? (
                <button
                    id="bank"
                    data-testid="button--blinkpay-bank-selected"
                    type="button"
                    className={cn(styles.selectBankButton, accessibility.button, spacing.spaceBelow8)}
                    onClick={() => {
                        rudderTrack('topup', 'select_bank_clicked')
                        setBankPickerDisplay(true)
                    }}
                >
                    <div className={styles.buttonContentWrapper}>
                        <div className={styles.buttonWrapper}>
                            {bank === 'BNZ' && <BnzLogo />}
                            {bank === 'Westpac' && <WestpacLogo />}
                        </div>
                        <h3>{bank}</h3>
                    </div>
                    <ChevronDown />
                </button>
            ) : (
                <>
                    <button
                        id="bank"
                        data-testid="button--blinkpay-bank-select"
                        type="button"
                        className={cn(styles.bankButton, accessibility.button)}
                        onClick={() => {
                            rudderTrack('topup', 'select_bank_clicked')
                            setBankPickerDisplay(true)
                        }}
                    >
                        <div className={styles.placeholder}>Select your bank</div>
                        <ChevronDown />
                    </button>
                </>
            )}
            <CantSeeBank />
            <Button
                dataTestId="button--next"
                label="Next"
                isSubmit
                processing={isSubmitting}
                disabled={!isValid}
                pageButton
            />
            <DockableModal
                dataTestId="dockable-modal--blinkpay-select-bank"
                title="Bank"
                isOpen={bankPickerDisplay}
                setIsOpen={setBankPickerDisplay}
                content={
                    <BankModalContent
                        handleSelectBank={bank => {
                            setBankPickerDisplay(false)
                            setFieldValue('bank', bank, true)
                        }}
                        bankOptions={bankOptions}
                    />
                }
            />
        </form>
    )
})

interface ConfirmationProps {
    topUpAmount: string
    fee: string
    discount: string
    bank: Bank
    initiateBlinkPayPayment: () => void
}

export const BlinkpayTransferFeeModalLink = () => {
    return (
        <div>
            Transfer fee{' '}
            <ModalLink
                dataTestId="modal-link--transfer-fee"
                modalTitle="Instant bank transfer fee"
                label="Transfer fee"
                primaryButton={{label: 'Ok'}}
                asIcon
                helpIconSize={16}
            >
                <p>Each time you make an instant bank transfer, you’re charged a $1 NZD transfer fee.</p>
                <p>
                    You’re not charged this transfer fee for regular bank transfers, but the money might take a bit
                    longer to appear in your Wallet.
                </p>
            </ModalLink>
        </div>
    )
}

const Confirmation = ({topUpAmount, fee, discount, bank, initiateBlinkPayPayment}: ConfirmationProps) => {
    const total = new Decimal(topUpAmount).plus(new Decimal(fee)).minus(new Decimal(discount)).toFixed(2)

    const [isSubmitting, setIsSubmitting] = React.useState(false)

    const receiptItems: OrderRow[] = [
        {description: 'Amount to top up', value: <DollarValue value={topUpAmount} currency="nzd" />},
        {
            description: <BlinkpayTransferFeeModalLink />,
            value: <FeeValue value={fee} currency="nzd" />,
        },
    ]

    if (Number(discount) !== 0) {
        receiptItems.push({
            description: 'We got it—processing fee',
            value: <DollarValue value={String(-Number(discount))} currency="nzd" />,
        })
    }

    if (isSubmitting) {
        return <Loader />
    }

    return (
        <>
            <h1 className={spacing.spaceBelow32}>Confirm instant top-up</h1>
            <OrderConfirmation
                items={receiptItems}
                total={{
                    description: 'Total',
                    value: <DollarValue value={total} currency="nzd" />,
                }}
            />
            <p className={spacing.spaceAbove12}>
                You’ll need to log in to your online banking, so make sure you have your login details handy.{' '}
                <a
                    href="https://intercom.help/sharesies/en/articles/8393032-about-blinkpay"
                    target="_blank"
                    rel="noopener"
                >
                    Learn more
                </a>
            </p>
            <Button
                dataTestId="button--confirm"
                label="Continue to BlinkPay"
                isSubmit
                pageButton
                onClick={() => {
                    setIsSubmitting(true)
                    initiateBlinkPayPayment()
                    rudderTrack('topup', 'continue_to_my_bank_clicked', {
                        amount: Number(total),
                        bank_selected: bank,
                    })
                }}
            />
        </>
    )
}

const TopUpInstantForm = () => {
    const dispatch = useAppDispatch()
    const nzWalletBalance = useAppSelector(s => s.identity.user?.wallet_balances.nzd) || '0'
    const hasMadeDeposit = useAppSelector(s => s.identity.user?.checks.made_deposit) || false
    const options = useAppSelector(s => s.bankLinking.blinkPay.options)

    async function initiateBlinkPayPayment(topUpAmount: string, bank: Bank, fee: string): Promise<void> {
        try {
            const response = await dispatch(actions.InitiateBlinkPayPayment(topUpAmount, bank, fee))

            if (response && response.type === 'blinkpay_payment_redirect') {
                window.location.href = response.redirect_uri
            }
        } catch (error) {
            Toast(ERROR_MESSAGE)
        }
    }

    const blinkPayFee = options?.fee_amount || '0'
    const hasUsedBlinkPay = options?.has_used_blinkpay || false
    const banks = options?.available_banks.map(bank => bank.name) || []

    let discount = '0'

    if (!hasMadeDeposit && !hasUsedBlinkPay) {
        discount = blinkPayFee
    }

    React.useEffect(() => {
        // This should have been loaded in Intro.tsx, but include here in case customer refreshes page
        if (!options) {
            dispatch(actions.GetBlinkPayOptions())
        }
    }, [])

    const [showForm, setShowForm] = React.useState(true)
    const [amount, setTopUpAmount] = React.useState('0.00')
    const [bank, setBank] = React.useState('')

    const blinkPayFeeDecimal = new Decimal(blinkPayFee)
    const discountDecimal = new Decimal(discount)

    return (
        <>
            {showForm ? (
                <Form
                    setShowForm={setShowForm}
                    setTopUpAmount={setTopUpAmount}
                    setBank={setBank}
                    bankOptions={banks}
                    walletBalance={nzWalletBalance}
                />
            ) : (
                <Confirmation
                    topUpAmount={amount}
                    fee={blinkPayFee}
                    discount={discount}
                    bank={bank as Bank}
                    initiateBlinkPayPayment={() =>
                        initiateBlinkPayPayment(
                            amount,
                            bank as Bank,
                            blinkPayFeeDecimal.minus(discountDecimal).toFixed(2),
                        )
                    }
                />
            )}
        </>
    )
}

export default TopUpInstantForm
