import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import Decimal from 'decimal.js'
import {Formik, FormikErrors, useFormikContext} from 'formik'
import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import calculateWalletBalance from '~/global/utils/calculate-wallet-balance/calculateWalletBalance'
import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {useExchangeFeeRate} from '~/global/utils/exchange-fee-hooks/exchangeFeeHooks'
import {dateFormatDayAndMonthWithTime} from '~/global/utils/format-date/formatDate'
import {formatNumber} from '~/global/utils/format-number/formatNumber'
import {isInstrumentHighRiskDerivative} from '~/global/utils/is-instrument-high-risk-derivative/isInstrumentHighRiskDerivative'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useExchangeRates} from '~/global/utils/use-exchange-rates/useExchangeRates'
import {useInstrument, useInstruments} from '~/global/utils/use-instruments/useInstruments'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Calendar} from '~/global/widgets/OLD_icons'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import CAMarkdown from '~/global/widgets/corporate-action-markdown/CAMarkdown'
import EstimatedWalletBalanceWithModal from '~/global/widgets/estimated-wallet-balance/EstimatedWalletBalanceWithModal'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {Loading} from '~/global/widgets/loading'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import ScrollingTickerHeader from '~/global/widgets/scrolling-ticker-header/ScrollingTickerHeader'
import {isNavigationDirective, Link, useNavigate} from '~/migrate-react-router'
import panelStyles from '~/sections/invest/sections/order-flow/InvestPanel.scss'
import styles from '~/sections/invest/sections/order-flow/OrderForm.scss'
import ApplicationErrors from '~/sections/invest/sections/order-flow/sections/apply/widgets/apply-errors/ApplicationErrors'
import ParentInstrumentPriceComparison from '~/sections/invest/sections/order-flow/sections/apply/widgets/parent-instrument-price-comparison/ParentInstrumentPriceComparison'
import SharesRemaining from '~/sections/invest/sections/order-flow/sections/apply/widgets/shares-remaining/SharesRemaining'
import {ExchangeRateXeUnreachable} from '~/sections/invest/sections/order-flow/widgets/modals/ExchangeRateErrorModals'
import ExerciseRightsBeforeShortfallModal from '~/sections/invest/sections/order-flow/widgets/modals/ExerciseRightsBeforeShortfallModal'
import HighRiskDerivativeModal from '~/sections/invest/sections/order-flow/widgets/modals/HighRiskDerivativeModal'
import MaxOneApplicationModal from '~/sections/invest/sections/order-flow/widgets/modals/MaxOneApplicationModal'
import {Block} from '~/sections/invest/sections/survey/widgets/survey-form/SurveyForm'
import {selectInstrumentOrders} from '~/store/accounting/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import actions from '~/store/order/actions'
import {Application, StagedApplication} from '~/store/order/types'

function getRemoveSharesOutcomes(outcomes: StagedApplication['applicationRule']['outcomes']) {
    return outcomes
        .filter(o => o.fund_id && parseFloat(o.amount_per_input_unit) < 0 && o.available_shares != null)
        .map(outcome => ({
            outcomeRuleId: outcome.outcome_rule_id,
            lossPerUnit: new Decimal(outcome.amount_per_input_unit).neg(),
            available: new Decimal(outcome.available_shares!),
        }))
}

interface FormValues {
    [name: string]: string | boolean | undefined
}
interface UnderlyingApplicationForm {
    stagedApplication: StagedApplication
    instrument: Instrument
}
const UnderlyingApplicationForm: React.FC<UnderlyingApplicationForm> = ({stagedApplication, instrument}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const {values, handleSubmit, isSubmitting, setFieldValue, isValid} = useFormikContext<FormValues>()

    const walletBalances = useAppSelector(store => store.identity.user!.wallet_balances)
    const accountRestricted = useAppSelector(store => store.identity.user!.account_restricted)
    const usEquitiesEnabled = useAppSelector(store => store.identity.user!.us_equities_enabled)

    const [exchangeRates, exchangeRateError] = useExchangeRates()

    const paymentOutcome = stagedApplication.applicationRule.outcomes.find(
        outcome => outcome.currency && parseFloat(outcome.amount_per_input_unit) < 0,
    )
    const zero = new Decimal(0)
    const costPerUnitDecimal = paymentOutcome ? new Decimal(paymentOutcome.amount_per_input_unit).neg() : zero
    const applicationAmountDecimal =
        values.application_amount && typeof values.application_amount === 'string'
            ? new Decimal(values.application_amount)
            : zero
    const removeSharesOutcomes = getRemoveSharesOutcomes(stagedApplication.applicationRule.outcomes)
    const cost = costPerUnitDecimal.mul(applicationAmountDecimal).toString()
    const hasOutcomes = stagedApplication.applicationRule.outcomes.length > 0

    const parentInstrument = useInstrument(instrument.parentInstrumentId)

    // Coercing to the supported wallet currencies here because we're not expecting voluntary corporate actions
    // for funds with any other currency.
    const applicationCurrency = (paymentOutcome?.currency ?? instrument.currency) as Currency

    const exchangeFeeRate = useExchangeFeeRate()

    const isRemainingBalanceNegative = React.useMemo(() => {
        if (!walletBalances || costPerUnitDecimal <= zero) {
            return false
        }

        const walletBalanceInDisplayCurrency = calculateWalletBalance(
            walletBalances,
            exchangeRates,
            usEquitiesEnabled,
            applicationCurrency,
            exchangeFeeRate,
        )

        return walletBalanceInDisplayCurrency && costPerUnitDecimal
            ? new Decimal(walletBalanceInDisplayCurrency)
                  .sub(applicationAmountDecimal.mul(costPerUnitDecimal))
                  .lessThan(zero)
            : false
    }, [
        walletBalances,
        costPerUnitDecimal.toString(),
        applicationAmountDecimal.toString(),
        exchangeRates,
        usEquitiesEnabled,
        applicationCurrency,
    ])

    const allSharesToAddOutcomes = stagedApplication.applicationRule.outcomes.filter(
        o => o.fund_id && parseFloat(o.amount_per_input_unit) > 0,
    )

    const sharesToAddOutcome = allSharesToAddOutcomes.find(o => o)
    const [outcomeInstruments, allLoaded] = useInstruments([...allSharesToAddOutcomes.map(o => o.fund_id)])

    const sharesToBuy =
        sharesToAddOutcome && applicationAmountDecimal.greaterThan(zero)
            ? new Decimal(sharesToAddOutcome.amount_per_input_unit).mul(applicationAmountDecimal).toString()
            : null

    const [warrantsToBuy, setWarrantsToBuy] = React.useState(0.0)
    const [optionsToBuy, setOptionsToBuy] = React.useState(0.0)

    const showParentInstrumentPriceComparison =
        stagedApplication.applicationRule.type === 'EXERCISE' &&
        stagedApplication.applicationRule.description &&
        paymentOutcome &&
        parentInstrument

    /*
     * Calculate the number of warrants and options the Investor will be entitled to if they exercise their rights
     *
     * If the Outcome Rules of an Exercise offer includes shares in a warrant or option instrument type then we
     * calculate how many options and/or warrants the investor will be entitled to.
     *
     * Note: We are currently not identifying which instrument(s) the Investor gets the options/warrants in, just
     * the total. If there are two warrant allocations, they will see a single count for the total
     */
    React.useEffect(() => {
        if (
            stagedApplication.applicationRule.type === 'EXERCISE' &&
            applicationAmountDecimal.greaterThan(0) &&
            allLoaded &&
            outcomeInstruments
        ) {
            const warrantOrOptionInstruments: any = {}

            // Build up a mapping of Instrument ID to Instrument Type
            Object.values(outcomeInstruments).forEach(instrument => {
                if (instrument && ['warrants', 'options'].includes(instrument.instrumentType)) {
                    warrantOrOptionInstruments[instrument.id] = instrument.instrumentType
                }
            })

            const toBuy = allSharesToAddOutcomes.reduce(
                (acc, o) => {
                    if (o.fund_id) {
                        if (warrantOrOptionInstruments[o.fund_id] === 'warrants') {
                            const warrantsToBuy = new Decimal(o.amount_per_input_unit).mul(applicationAmountDecimal)
                            acc.warrants = acc.warrants.add(warrantsToBuy)
                        } else if (warrantOrOptionInstruments[o.fund_id] === 'options') {
                            const optionsToBuy = new Decimal(o.amount_per_input_unit).mul(applicationAmountDecimal)
                            acc.options = acc.options.add(optionsToBuy)
                        }
                    }
                    return acc
                },
                {warrants: new Decimal(0), options: new Decimal(0)},
            )

            setOptionsToBuy(toBuy.options.toNumber())
            setWarrantsToBuy(toBuy.warrants.toNumber())
        }
    }, [outcomeInstruments, allLoaded, allSharesToAddOutcomes])

    return (
        <form onSubmit={handleSubmit}>
            {exchangeRateError && <ExchangeRateXeUnreachable onClick={() => navigate(-1)} />}
            {showParentInstrumentPriceComparison && (
                <ParentInstrumentPriceComparison
                    parentInstrument={parentInstrument}
                    applicationCurrency={applicationCurrency}
                    costPerUnitDecimal={costPerUnitDecimal}
                />
            )}
            {stagedApplication.applicationRule.question_blocks.map((block, index) => {
                const name = 'name' in block ? block.name : null
                return (
                    <div key={index} className={cn(index === 0 && spacing.spaceAbove40)}>
                        <Block
                            block={block}
                            isSubmitting={isSubmitting}
                            decimalInputHelpText={
                                name === 'application_amount' ? (
                                    <>
                                        {removeSharesOutcomes.map(({outcomeRuleId, lossPerUnit, available}) => (
                                            <SharesRemaining
                                                key={outcomeRuleId}
                                                instrument={instrument}
                                                available={available}
                                                used={applicationAmountDecimal.times(lossPerUnit)}
                                                applyAll={() =>
                                                    setFieldValue('application_amount', available.toString())
                                                }
                                                applyAllLabel="Exercise all"
                                            />
                                        ))}
                                    </>
                                ) : undefined
                            }
                        />
                        {name === 'application_amount' &&
                            stagedApplication.applicationRule.type === 'EXERCISE' &&
                            sharesToBuy &&
                            paymentOutcome &&
                            cost &&
                            isValid && (
                                <AlertCard type="none" className={cn(spacing.spaceAbove12, spacing.spaceBelow16)}>
                                    <p className={styles.outcome}>
                                        This will buy {sharesToBuy} shares and cost{' '}
                                        <strong>
                                            <DollarValue
                                                value={new Decimal(cost).toFixed(2, Decimal.ROUND_UP).toString()}
                                                currency={paymentOutcome.currency}
                                            />
                                        </strong>
                                        .{' '}
                                        {(warrantsToBuy > 0 || optionsToBuy > 0) && (
                                            <>
                                                You’ll also receive{' '}
                                                {optionsToBuy > 0 && (
                                                    <>
                                                        {optionsToBuy} options
                                                        {warrantsToBuy > 0 ? <> and </> : ''}
                                                    </>
                                                )}
                                                {warrantsToBuy > 0 && <> {warrantsToBuy} warrants</>}.{' '}
                                                <ModalLink
                                                    dataTestId="modal-link--what-are-options-and-warrants"
                                                    label="What are these?"
                                                    modalTitle="Options and warrants"
                                                    primaryButton={{label: 'OK, got it'}}
                                                >
                                                    <p>
                                                        Sometimes, you might receive more than just shares when you
                                                        exercise your rights.
                                                    </p>
                                                    <p>
                                                        A company might also give you other investments (such as options
                                                        or warrants) depending on how many rights you exercise.
                                                    </p>
                                                    <p>
                                                        These investments are a bit different to shares, so if you’re
                                                        unsure about what you can do with them,{' '}
                                                        <HelpCentreLink
                                                            auArticle="7869557-warrants-and-options"
                                                            nzArticle="5325990-warrant-offers"
                                                        >
                                                            check out our help centre article
                                                        </HelpCentreLink>
                                                        .
                                                    </p>
                                                </ModalLink>
                                            </>
                                        )}
                                    </p>
                                </AlertCard>
                            )}
                    </div>
                )
            })}

            {isRemainingBalanceNegative && (
                <div className={cn(styles.balanceWarning, spacing.spaceAbove16)} data-testid="insufficient-funds">
                    There’s not enough money in your Wallet. <Link to={profileUrl('wallet')}>Top up</Link>
                </div>
            )}

            <div className={styles.formFooter}>
                {hasOutcomes && (
                    <EstimatedWalletBalanceWithModal
                        displayCurrency={applicationCurrency}
                        exchangeRates={exchangeRates}
                    />
                )}
                <Button
                    dataTestId="button--review"
                    isSubmit
                    label="Review"
                    disabled={!isValid || isRemainingBalanceNegative || accountRestricted}
                    processing={isSubmitting}
                />
            </div>
        </form>
    )
}

const ApplicationForm: React.FunctionComponent<ApplicationFormProps> = ({application}) => {
    const navigate = useNavigate()
    const instrument = useInstrument(application.fund_id)
    const stagedApplication = useAppSelector(store => store.order.stagedApplication)
    const orders = useAppSelector(s => selectInstrumentOrders(s, application.fund_id))
    const existingApplication = orders.find(
        order =>
            order.type === 'corporate_action_v2' &&
            order.application?.rule_id === application.application_rule_id &&
            order.application?.is_cancelled === false,
    ) as Extract<(typeof orders)[number], {type: 'corporate_action_v2'; application: any}>

    const isDependent = useAppSelector(store => store.identity.user!.is_dependent)
    const preferredName = useAppSelector(store => store.identity.user!.preferred_name)

    // If you can auto-exercise you can submit multiple applications
    const hasAutoExerciseFlag = useAppSelector(s => s.identity.flags.auto_exercise)

    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    // If initialValues changes, the form is reset and Bad Things happen. Only change initialValues when we want to
    // reset the form - ie, when the application rule changes
    const initialValues = React.useMemo(
        () => stagedApplication?.answers,
        [stagedApplication?.applicationRule?.application_rule_id],
    )

    React.useEffect(() => {
        if (history.length === 1) {
            rudderTrack('voting', 'vote_now_link_clicked', {
                referrer: 'email',
                ca_application_rule_id: application.application_rule_id,
            })
        } else if (application.type === 'VOTE') {
            rudderTrack('voting', 'application_form_displayed')
        }
    }, [])

    React.useEffect(() => {
        // Only reset the staged application if it's changed - otherwise, we should let you resume editing
        if (
            !stagedApplication ||
            stagedApplication.applicationRule?.application_rule_id !== application.application_rule_id
        ) {
            dispatch(actions.InitialiseStagedApplication(application))
        }
    }, [application])

    const resetError = () => {
        if (stagedApplication) {
            dispatch(
                actions.UpdateStagedApplication({
                    ...stagedApplication!,
                    error: undefined,
                }),
            )
        }
    }

    const onExistingCancelled = (didCancel: boolean) => {
        if (!didCancel) {
            // be explicit about going back to the instrument page, in most cases this can be achieved
            // by nagivate(-1) in other cases, e.g. applying for a shortfall before exercising all rights,
            // it is possible to get stuck in a loop between two pages
            const currentPath = location.pathname
            navigate(currentPath.replace(`/apply/${application.application_rule_id}`, ''))
        }
    }

    if (!instrument) {
        return <Loading isPineapple />
    }

    return (
        <Page className={panelStyles.investPanelAnimated}>
            {/*
            Page is `display: flex` because... reasons, which prevents margin collapsing.
            Wrap in a div to get back into a normal block layout
            */}
            <div>
                {application.type === 'VOTE' ? (
                    <>
                        <ScrollingTickerHeader instrument={instrument} />
                        <h1 className={cn([styles.orderTitle, spacing.spaceAbove24, spacing.spaceBelow12])}>
                            Vote at {instrument.name}
                            {application.investment_option_title ? `’s ${application.investment_option_title}` : ''}
                        </h1>
                        {application.applications_close_at && (
                            <div className={cn(styles.orderAttributeRow, spacing.spaceAbove16, spacing.spaceBelow16)}>
                                <Calendar className={styles.orderAttributeIcon} />
                                <p>
                                    Vote by {application.applications_close_at.toFormat(dateFormatDayAndMonthWithTime)}
                                </p>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <PageBack />
                        <h1 className={cn([styles.orderTitle, spacing.spaceAbove32, spacing.spaceBelow16])}>
                            {instrument.name}
                            {application.type === 'PURCHASE' && ' share application'}
                        </h1>
                    </>
                )}

                {application.description && (
                    <CAMarkdown className={spacing.spaceBelow8}>{application.description}</CAMarkdown>
                )}

                {stagedApplication && initialValues ? (
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validateOnMount
                        validate={values => {
                            const errors: {[fieldName: string]: string} = {}

                            const zero = new Decimal(0)
                            const applicationAmountDecimal =
                                values.application_amount && typeof values.application_amount === 'string'
                                    ? new Decimal(values.application_amount)
                                    : zero
                            const removeSharesOutcomes = getRemoveSharesOutcomes(
                                stagedApplication.applicationRule.outcomes,
                            )
                            const isRemainingSharesNegative = removeSharesOutcomes.some(({lossPerUnit, available}) =>
                                available.lessThan(applicationAmountDecimal.times(lossPerUnit)),
                            )

                            for (const block of stagedApplication.applicationRule.question_blocks) {
                                if (block.type === 'decimal_input') {
                                    if ((!values[block.name] && block.required) || Number(values[block.name]) === 0) {
                                        errors[block.name] = '' // we don't actually display an error, just disable the submit button
                                    }

                                    const value = values[block.name] as string | undefined
                                    if (
                                        block.max_value &&
                                        value &&
                                        new Decimal(value).greaterThan(new Decimal(block.max_value))
                                    ) {
                                        const maxHasCents = new Decimal(block.max_value).mod(1).gt(0)
                                        const max = formatNumber({
                                            number: block.max_value,
                                            roundDown: true,
                                            decimalPlaces: maxHasCents ? 2 : 0,
                                        })
                                        errors[block.name] =
                                            `The most you can apply for as part of this offer is $${max}.`
                                    }
                                }
                            }

                            if (isRemainingSharesNegative) {
                                const units = shareLabel({instrument, isPlural: true})
                                errors.application_amount = `You’ve entered more ${units} than you own. Try again with a lower number.`
                            }

                            return errors
                        }}
                        onSubmit={async (values, {setSubmitting, setErrors}) => {
                            try {
                                // If we submit empty stings, we get bad errors like "'' is not a Decimal", so filter them out
                                const valuesWithoutEmpties = {...values}
                                for (const [key, value] of Object.entries(values)) {
                                    if (value === '') {
                                        delete valuesWithoutEmpties[key]
                                    }
                                }
                                if (stagedApplication) {
                                    dispatch(
                                        actions.UpdateStagedApplication({
                                            ...stagedApplication,
                                            answers: valuesWithoutEmpties,
                                        }),
                                    )
                                }
                                setSubmitting(true)

                                // The arrow functions are required to keep `this` correct inside the Thunks
                                const action = application.outcomes.length
                                    ? () => actions.CostApplication()
                                    : () => actions.ValidateApplication()
                                const errors = await dispatch(action())
                                if (isNavigationDirective(errors)) {
                                    errors.execute(navigate, profileUrl)
                                } else if (errors) {
                                    setErrors(errors as FormikErrors<FormValues>)
                                }
                            } catch (error) {
                                setSubmitting(false)
                            }
                        }}
                    >
                        <UnderlyingApplicationForm instrument={instrument} stagedApplication={stagedApplication} />
                    </Formik>
                ) : null}

                {stagedApplication?.error && (
                    <ApplicationErrors
                        stagedApplication={stagedApplication}
                        instrumentName={instrument.name}
                        isDependent={isDependent}
                        preferredName={preferredName}
                        page="form"
                        resetError={resetError}
                    />
                )}

                {!existingApplication && isInstrumentHighRiskDerivative(instrument) && (
                    <HighRiskDerivativeModal instrument={instrument} onCancel={() => navigate(-1)} />
                )}

                {existingApplication && (application.type !== 'EXERCISE' || !hasAutoExerciseFlag) && (
                    <MaxOneApplicationModal existingApplication={existingApplication} onClose={onExistingCancelled} />
                )}

                {application.type === 'PURCHASE' && (
                    <ExerciseRightsBeforeShortfallModal
                        instrument={instrument}
                        applicationRuleId={application.application_rule_id}
                        onClose={() => navigate(-1)}
                    />
                )}
            </div>
        </Page>
    )
}

interface ApplicationFormProps {
    application: Application
}

export default ApplicationForm
