import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {Navigate} from 'react-router-dom'
import {Model} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import PriceHistoryRefetch from '~/global/data-components/PriceHistoryRefetch'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {spacing} from '~/global/scss/helpers'
import {useEssViewsForInstrument} from '~/global/state-hooks/mixed-source/useEssViewsForInstrument'
import {usePortfolioItemsById} from '~/global/state-hooks/mixed-source/usePortfolioItemsById'
import {useDefaultReturnPeriod} from '~/global/state-hooks/rakaia/useDefaultReturnPeriod'
import {useInstrumentInvestingHistory} from '~/global/state-hooks/rakaia/useInstrumentInvestingHistory'
import {useLatestInstrumentReturns} from '~/global/state-hooks/rakaia/useLatestInstrumentReturns'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {
    isInstrumentActive,
    isInstrumentCloseOnly,
    isInstrumentInNoTrade,
} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {isInstrumentDerivative} from '~/global/utils/is-instrument-derivative/isInstrumentDerivative'
import {isInstrumentHighRiskDerivative} from '~/global/utils/is-instrument-high-risk-derivative/isInstrumentHighRiskDerivative'
import {
    isAnyLoadingOrUninitialised,
    isUninitialised,
    isLoadingOrUninitialised,
    isLoading,
} from '~/global/utils/is-loading/isLoading'
import {isOnNZX, isUsOtc} from '~/global/utils/is-on-exchange/isOnExchange'
import {getScrollPosition} from '~/global/utils/scroll-utils/scrollUtils'
import {Period, periodInformation} from '~/global/utils/time-period/timePeriod'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {useInstrumentTab} from '~/global/utils/use-instrument-tab/useInstrumentTab'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AccountVerificationRequired from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import {VoteResultsCard} from '~/global/widgets/corporate-action-description/CorporateActionDescription'
import {ExchangeHours} from '~/global/widgets/exchange-hours/ExchangeHours'
import Feedback from '~/global/widgets/feedback/Feedback'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import TabControls from '~/global/widgets/tab-controls/TabControls'
import {useLocation} from '~/migrate-react-router'
import AutoInvestFooter from '~/sections/invest/sections/auto-invest/widgets/footer/AutoInvestFooter'
import InvestPanel from '~/sections/invest/sections/order-flow/InvestPanel'
import OrderFooter from '~/sections/invest/sections/order-flow/widgets/order-footer/OrderFooter'
import TransferSharesViewInstrumentFooter from '~/sections/invest/sections/transfer-shares/widgets/transfer-shares-view-instrument-footer/TransferSharesViewInstrumentFooter'
import ShareSchemeBlurb from '~/sections/invest/sections/view-instrument/sections/employee-share-scheme/widgets/share-scheme-blurb/ShareSchemeBlurb'
import OverviewTab from '~/sections/invest/sections/view-instrument/sections/overview/OverviewTab'
import YourInvestmentTab from '~/sections/invest/sections/view-instrument/sections/your-investment/YourInvestmentTab'
import {LocationContext} from '~/sections/invest/sections/view-instrument/state/LocationContext'
import {InstrumentOrderContext} from '~/sections/invest/sections/view-instrument/state/OrderContext'
import Header from '~/sections/invest/sections/view-instrument/widgets/header/Header'
import IlliquidNotification from '~/sections/invest/sections/view-instrument/widgets/illiquid-notification/Illiquid'
import {
    ApplicationAlertCard,
    CloseOnlyInstrumentAlertCard,
    ESSBlackoutPeriodAlertCard,
    HighRiskDerivativeAlertCard,
    OtcInstrumentActiveAlertCard,
    OtcInstrumentCloseOnlyAlertCard,
    OtcInstrumentNoTradeAlertCard,
    RightsIssueAlert,
    ShareSplitPriceWarning,
    TradeWithCautionAlertCard,
    UsLivePricingUnavailableAlertCard,
    VolatileInstrumentAlertCard,
    VoteAvailableCard,
} from '~/sections/invest/sections/view-instrument/widgets/instrument-alert-cards/InstrumentAlertCards'
import Navigation, {
    NavigationRouterState,
} from '~/sections/invest/sections/view-instrument/widgets/navigation/Navigation'
import NotificationAnnouncement from '~/sections/invest/sections/view-instrument/widgets/notification-announcement/NotificationAnnouncement'
import USPartnershipNotification from '~/sections/invest/sections/view-instrument/widgets/us-partnership/USPartnership'
import RecentInvestingActivity from '~/sections/invest/widgets/recent-investing-activity/RecentInvestingActivity'
import accountingActions from '~/store/accounting/actions'
import {
    shareSplitCausingPriceIssues,
    selectInstrumentHistory,
    instrumentRecentOrders,
    selectInstrumentOrders,
} from '~/store/accounting/selectors'
import {VoluntaryCorporateActionV2} from '~/store/accounting/types'
import essActions from '~/store/employeeShareScheme/actions'
import {essHasActiveBlackoutPeriodForInstrument, possibleESS} from '~/store/employeeShareScheme/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/instrument/actions'
import {getInstrumentFromList, instrumentCategoryLabels} from '~/store/instrument/selectors'
import {CycleMode, Instrument, DIYAutoInvestType, PremadeSlugType, InstrumentDetailsTab} from '~/store/instrument/types'
import orderActions from '~/store/order/actions'
import signUpActions from '~/store/sign-up/actions'
import styles from './ViewInstrument.scss'

/**
 * Welcome to the rather gnarly Instrument View page. There's a coupe of things here:
 * - It uses app selector & dispatcher to get state and alter the store
 * - Plus there's a wrapper component to deal with loading and error states.
 *
 * The wrapper component:
 * In an earlier version of this component, the render function took care of loading states,
 * error states, and the rendering of the Instrument contents. With so many concerns all being taken care of inside the single
 * render function, it was getting rather complex. Here's a couple of examples this was getting messy:
 * - If you ever used the `instrument` prop, you never knew if the instrument existed or not (it might have been `undefined`
 * if the loading of that instument data had not completed)
 * - Setting default component state (like the default selected tab) was difficult if that state depended on the loaded data.
 * Quite simply, if you don't have the data on the first render then you cannot be confident in the setting of the detault component
 * state
 *
 * The wrapper component extracts the loading of the data that is always necessary to load the page into one place. Then, once
 * the data has all been loaded, it renders the page. The page component, then, can be sure that it will always render with
 * actual data because the wrapper component passes this information down.
 * While the data is loading, the wrapper will show the loader.
 * If there are any errors, the wrapper will show these too
 * The page component still does _some_ data loading, but this data is not necessary to get the initial render complete. This
 * data can be loaded after the first render and update the page asyncronously. The wrapper only takes care of loading the data
 * which, if any of it doesn't exist, we show the loader component.
 */

const ViewInstrumentDataWrapper: React.FunctionComponent<InstrumentProps> = React.memo(props => {
    const dispatch = useAppDispatch()
    const {instrumentSlug, instrumentListType} = props
    const {instrumentId, relativeIds, lastResultBeforeNewPage} = useAppSelector(s =>
        getInstrumentFromList(s, instrumentListType, instrumentSlug),
    )

    const instrument = useAppSelector(s => (instrumentId ? s.instrument.instrumentsById[instrumentId] : undefined))
    const metadataInitialised = useAppSelector(s => s.instrument.isMetadataInitialised)

    const isPossiblyESS = useAppSelector(s => possibleESS(s))
    const allocationsLoadingState = useAppSelector(s => s.employeeShareScheme.allocationsLoadingState)
    const participationLoadingState = useAppSelector(s => s.employeeShareScheme.participationLoadingState)
    const resultsLoadingState = useAppSelector(s => s.instrument.resultsLoadingState)
    const prevSlug = useAppSelector(s =>
        relativeIds.prev && s.instrument.instrumentsById[relativeIds.prev]
            ? s.instrument.instrumentsById[relativeIds.prev].urlSlug
            : undefined,
    )
    const nextSlug = useAppSelector(s =>
        relativeIds.next && s.instrument.instrumentsById[relativeIds.next]
            ? s.instrument.instrumentsById[relativeIds.next].urlSlug
            : undefined,
    )
    const applications = useAppSelector(s =>
        instrumentId ? s.order.applicationsByInstrument[instrumentId]?.applications : undefined,
    )
    const applicationsState = useAppSelector(s => s.order.applicationsByInstrumentLoadingState)

    React.useEffect(() => {
        if (
            (isPossiblyESS &&
                // fetch share scheme details if they're uninitialised or not loaded for this investor
                isUninitialised(allocationsLoadingState)) ||
            isUninitialised(participationLoadingState)
        ) {
            dispatch(essActions.FetchParticipatingShareSchemes())
        }
    }, [isPossiblyESS])

    React.useEffect(() => {
        if (instrumentId) {
            dispatch(orderActions.LoadInstrumentApplications(instrumentId))
        }
    }, [instrumentId])

    React.useEffect(() => {
        // try loading instrument directly in case of customer landing on this page (when metadata is present)
        if (metadataInitialised && !instrument) {
            dispatch(actions.getSingleInstrumentBySlug(instrumentSlug))
        }
    }, [instrumentSlug, instrument, metadataInitialised])

    // Check if the data we MUST load for the page is still loading
    if (
        isAnyLoadingOrUninitialised([resultsLoadingState, allocationsLoadingState, participationLoadingState]) ||
        !metadataInitialised
    ) {
        return <Loading isPineapple />
    }

    // Check if the optional data for the page is still loading - we use the booleans set earlier on to determine
    // whether we need to care about the loading/uninititalised state at all
    if (
        (isPossiblyESS && isLoadingOrUninitialised(allocationsLoadingState)) ||
        (!applications && isLoading(applicationsState))
    ) {
        return <Loading isPineapple />
    }

    if (!instrument) {
        return <WeSlippedUp />
    }

    return (
        <ViewInstrument
            {...props}
            instrument={instrument}
            prevLink={prevSlug && `../${prevSlug}`}
            nextLink={nextSlug && `../${nextSlug}`}
            lastResultBeforeNewPage={lastResultBeforeNewPage}
        />
    )
})

export const ViewInstrument: React.FunctionComponent<InstrumentLoadedProps> = ({
    instrument,
    instrumentListType,
    lastResultBeforeNewPage,
    nextLink,
    prevLink,
    premadeSlug,
    diyAIType,
}) => {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const profileUrl = useProfileUrl()
    const profile = useProfile()
    const browserHistoryState: NavigationRouterState = location.state as any

    // Investor information

    // TODO: Shared Money - needs to work for currently selected portfolio when there are multiple invest portfolios
    const portfolioId = profile.portfolios.find(p => p.product === 'INVEST')!.id

    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const includeSoldInvestments = useAppSelector(s => s.identity.includeSoldInvestments)
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)

    // Corporate actions
    const badShareSplit = useAppSelector(s => shareSplitCausingPriceIssues(s, instrument.id))
    const instrumentAvailableApplications = useAppSelector(s => s.order.applicationsByInstrument[instrument.id])
    const applications = instrumentAvailableApplications?.applications ?? []
    const applicationAlert = instrumentAvailableApplications?.alert
    const exerciseApplication = applications.find(application => application.type === 'EXERCISE')
    const voteApplications = applications.filter(application => application.type === 'VOTE')
    const hasNonExerciseApplications = applications.some(
        application => !['EXERCISE', 'VOTE'].includes(application.type),
    )

    const exchange = useAppSelector(s =>
        s.instrument.metadata.exchanges.find(exchange => instrument && instrument.exchange === exchange.name),
    )

    // Currency information
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)

    // Investor holdings
    const holding = useAppSelector(s => s.identity.holdings.find(f => f.fund_id === instrument.id))

    // Employee Share Scheme Information for this Instrument
    const essViews = useEssViewsForInstrument(instrument.id)

    // Instrument details and variables that alter UI interactions
    const history = useAppSelector(s => selectInstrumentHistory(s, instrument.id))
    const isAutoInvest = !!(premadeSlug || diyAIType)
    const canBuy = !isInstrumentCloseOnly(instrument) && !instrument.isClientReadOnly && essViews.canBuyInstrument
    const canSell = !!holding && !instrument.isClientReadOnly && essViews.canSellInstrument
    const categoryLabels = useAppSelector(s => instrumentCategoryLabels(s, instrument))

    // Instrument orders, returns, and share count information
    const fundOrders = useAppSelector(s => s.accounting.fundOrders[instrument.id])
    const ordersState =
        fundOrders && !fundOrders.fetching ? 'ready' : fundOrders && fundOrders.lastFetched ? 'refreshing' : 'loading'

    const instrumentReturns = useLatestInstrumentReturns(portfolioId)
    const currentReturn = instrumentReturns ? instrumentReturns.instrument_returns[instrument.id] : undefined

    const currentHistory = useInstrumentInvestingHistory({
        portfolioUuid: portfolioId,
        instrumentUuid: instrument.id,
    })

    const sharesSoldCount = (currentReturn && currentReturn.shares_detail.shares_sold) || 0
    const portfolioItemsIds = Object.keys(usePortfolioItemsById())
    const essBlackoutPeriodLoadingState = useAppSelector(s => s.employeeShareScheme.blackoutPeriodLoadingState)
    const essHasActiveBlackoutPeriod = useAppSelector(s => essHasActiveBlackoutPeriodForInstrument(s, instrument.id))

    // UI state
    const resultsLoadingState = useAppSelector(s => s.instrument.resultsLoadingState)
    const nextPageLoadingState = useAppSelector(s => s.instrument.nextPageLoadingState)
    const defaultReturnPeriod = useDefaultReturnPeriod(portfolioId)
    const showRecentOrders = instrumentListType !== 'transferShares'
    const recentOrders = useAppSelector(s => instrumentRecentOrders(s, instrument.id))
    const orders = useAppSelector(s => selectInstrumentOrders(s, instrument.id))
    const currentTab: InstrumentDetailsTab = useInstrumentTab(instrument.id)

    const [tab, setTab] = React.useState<InstrumentDetailsTab>(currentTab)
    const [period, setPeriod] = React.useState<Period>(
        browserHistoryState && browserHistoryState.period ? browserHistoryState.period : defaultReturnPeriod,
    )
    const [hideFooter, setHideFooter] = React.useState(false)

    const usResignStatus = useAppSelector(s => s.signUp.usResignStatus)

    React.useEffect(() => {
        // look up price history
        if (instrument) {
            dispatch(
                actions.getPriceHistoryByInstrumentId(
                    instrument.id,
                    DateTime.local().minus(periodInformation[period].duration),
                ),
            )
            dispatch(accountingActions.FetchFundOrders(instrument.id))
        }
    }, [period, instrument.urlSlug, resultsLoadingState]) // period changes, slug changes, we actually loaded an instrument so we have its ID to use

    React.useEffect(() => {
        // if it's the last result and there are more pages, load the next page
        if (lastResultBeforeNewPage) {
            instrumentListType === 'investPortfolio'
                ? actions.loadNextPagePortfolioInstruments(portfolioItemsIds)
                : dispatch(actions.loadNextPageSearchResults())
        }
    }, [lastResultBeforeNewPage])

    React.useEffect(() => {
        if (instrument && essViews.hasEmployment && isUninitialised(essBlackoutPeriodLoadingState)) {
            dispatch(essActions.FetchBlackoutPeriods())
        }
    }, [instrument.urlSlug, essViews, essBlackoutPeriodLoadingState])

    React.useEffect(() => {
        dispatch(signUpActions.getUSResignStatus())
    }, [])

    // Force the investor to resign the W8 tax form.
    if (usResignStatus?.resign_overdue) {
        return <Navigate to={profileUrl('us-sign-up')} replace />
    }

    const recentOrdersUrl = instrument ? profileUrl('recent-orders/:instrumentId', {instrumentId: instrument.id}) : ''

    const identifierToIncludeInstrumentType = ['etf', 'mf'].includes(instrument.instrumentType)
    const actionIdentifier = identifierToIncludeInstrumentType
        ? `/${instrument.urlSlug} (${instrument.instrumentType.toUpperCase()})`
        : `/${instrument.urlSlug}`

    const hasRecentOrders = recentOrders.length > 0
    const rightsIssueOrder =
        instrument && isInstrumentDerivative(instrument) && orders
            ? (orders.find(
                  order =>
                      order.type === 'corporate_action_v2' &&
                      order.is_terminal &&
                      ['SHARE_DIVIDEND', 'SHARE_ISSUE'].includes(order.action_type),
              ) as Model.CorporateActionV2 | undefined)
            : undefined
    const showInvestmentTab = (currentReturn && holding) || essViews.hasEmployment

    const recentVoteResults = orders.find(
        (order): order is VoluntaryCorporateActionV2 =>
            order.type === 'corporate_action_v2' &&
            order.action_type === 'VOTE' &&
            !!order.settlement_date &&
            // Hide results a week after the meeting
            DateTime.fromISO(order.settlement_date).plus({weeks: 1}) > DateTime.now() &&
            !!order.documents.find(d => d.purpose === 'RESULTS'),
    )

    // It's a derivative, with an active exercise corporate action. It's not in closeonly with you
    // having not recieved units, and you either have no orders (or you just have the issue order)
    const showRightsIssueAlert =
        isInstrumentDerivative(instrument) &&
        exerciseApplication &&
        !(isInstrumentCloseOnly(instrument) && !rightsIssueOrder) &&
        (!hasRecentOrders ||
            (recentOrders.length === 1 && rightsIssueOrder && recentOrders[0].id === rightsIssueOrder.id))

    const changeTab = (tab: InstrumentDetailsTab) => {
        dispatch(actions.SetActiveInstrumentTab(tab, instrument.id))
        setTab(tab)
        rudderTrack('instrument', 'tab_selected', {tab_name: tab === 'Overview' ? 'overview' : 'your_investment'})
    }
    const footerContent = () => {
        if (instrumentListType === 'transferShares') {
            return (
                <TransferSharesViewInstrumentFooter instrumentId={instrument.id} instrumentSlug={instrument.urlSlug} />
            )
        } else if (isAutoInvest) {
            return (
                <AutoInvestFooter
                    instrumentSlug={instrument.urlSlug}
                    premadeSlug={premadeSlug}
                    diyAIType={diyAIType}
                    instrumentId={instrument.id}
                    instrumentName={instrument.name}
                />
            )
        } else if ((canBuy || canSell) && applications) {
            // wait for applications to load to prevent flash of wrong buttons
            return <OrderFooter instrument={instrument} holding={holding} />
        }
        return null
    }

    const viewsToSuppressExtendedHoursOn: CycleMode[] = [
        'premadeResponsibleAutoInvest',
        'premadeGlobalAutoInvest',
        'premadeKidsAutoInvest',
        'diyAutoInvest',
        'transferShares',
    ]
    const showExchangeHoursIndicator =
        tradingType(instrument) !== 'managed' && !isInstrumentInNoTrade(instrument) && !essHasActiveBlackoutPeriod

    return (
        <>
            <InstrumentOrderContext.Provider value={instrumentListType}>
                <Page>
                    <LocationContext.Provider value={{getScrollPosition, tab, period}}>
                        <PageBack />
                        {showExchangeHoursIndicator && (
                            <ExchangeHours
                                instrument={instrument}
                                suppressExtendedHours={viewsToSuppressExtendedHoursOn.includes(instrumentListType)}
                            />
                        )}

                        <div className={styles.verificationNotificationWrapper}>
                            <AccountVerificationRequired />
                        </div>

                        {essHasActiveBlackoutPeriod && !essViews.hasAlignmentRightScheme && (
                            <ESSBlackoutPeriodAlertCard className={spacing.spaceAbove16} instrument={instrument} />
                        )}

                        {!isLoading(ordersState) ? (
                            showRightsIssueAlert ? (
                                <RightsIssueAlert
                                    className={spacing.spaceAbove16}
                                    instrument={instrument}
                                    rightsIssue={orders.length === 1 && rightsIssueOrder ? rightsIssueOrder : undefined}
                                    application={exerciseApplication}
                                />
                            ) : (
                                showRecentOrders &&
                                hasRecentOrders && (
                                    <div className={spacing.spaceAbove16}>
                                        <RecentInvestingActivity orders={recentOrders} url={recentOrdersUrl} />
                                    </div>
                                )
                            )
                        ) : null}

                        {voteApplications.map(a => (
                            <VoteAvailableCard
                                instrument={instrument}
                                application={a}
                                key={a.corporate_action_id}
                                className={spacing.spaceAbove16}
                            />
                        ))}

                        {recentVoteResults && (
                            <VoteResultsCard
                                instrument={instrument}
                                order={recentVoteResults}
                                className={spacing.spaceAbove16}
                            />
                        )}

                        {!hasRecentOrders && hasNonExerciseApplications && applicationAlert && (
                            <ApplicationAlertCard alert={applicationAlert} className={spacing.spaceAbove16} />
                        )}

                        {isInstrumentCloseOnly(instrument) &&
                            !isInstrumentDerivative(instrument) &&
                            !isUsOtc(instrument) && (
                                <CloseOnlyInstrumentAlertCard
                                    className={spacing.spaceAbove16}
                                    instrument={instrument}
                                />
                            )}

                        {isInstrumentHighRiskDerivative(instrument) && (
                            <HighRiskDerivativeAlertCard className={spacing.spaceAbove16} instrument={instrument} />
                        )}

                        {instrument.isVolatile && !isOnNZX(instrument) && !isInstrumentCloseOnly(instrument) && (
                            <VolatileInstrumentAlertCard className={spacing.spaceAbove16} instrument={instrument} />
                        )}

                        {instrument.isVolatile && isOnNZX(instrument) && !isInstrumentCloseOnly(instrument) && (
                            <TradeWithCautionAlertCard className={spacing.spaceAbove16} instrument={instrument} />
                        )}

                        {isUsOtc(instrument) && isInstrumentActive(instrument) && (
                            <OtcInstrumentActiveAlertCard className={spacing.spaceAbove16} />
                        )}

                        {isUsOtc(instrument) && isInstrumentCloseOnly(instrument) && (
                            <OtcInstrumentCloseOnlyAlertCard className={spacing.spaceAbove16} />
                        )}

                        {isUsOtc(instrument) && isInstrumentInNoTrade(instrument) && (
                            <OtcInstrumentNoTradeAlertCard className={spacing.spaceAbove16} />
                        )}

                        <UsLivePricingUnavailableAlertCard className={spacing.spaceAbove16} instrument={instrument} />

                        {badShareSplit && (
                            <ShareSplitPriceWarning
                                className={spacing.spaceAbove16}
                                instrument={instrument}
                                order={badShareSplit}
                            />
                        )}

                        <div
                            className={cn(styles.headerAndNav, {
                                [styles.noHoldings]: !holding,
                                [styles.shareSchemeBlurb]: essViews.hasEmployment,
                            })}
                        >
                            <Navigation
                                prevLink={prevLink}
                                nextLink={nextLink}
                                tab={tab}
                                period={period}
                                nextPageLoading={nextPageLoadingState === 'loading'}
                            />
                            <Header
                                instrument={instrument}
                                portfolioId={portfolioId}
                                suppressExtendedHours={viewsToSuppressExtendedHoursOn.includes(instrumentListType)}
                                hasEssEmployment={essViews.hasEmployment}
                            />

                            {instrument.isIlliquid && <IlliquidNotification />}
                            {instrument.isUsPartnership && <USPartnershipNotification />}
                        </div>

                        {essViews.hasEmployment && <ShareSchemeBlurb instrument={instrument} />}
                        <NotificationAnnouncement instrumentId={instrument.id} />
                        {showInvestmentTab && (
                            <TabControls
                                tabs={[
                                    {label: 'Overview', value: 'Overview'},
                                    {label: 'Your investment', value: 'Your investment'},
                                ]}
                                currentTab={tab}
                                onChangeTab={changeTab}
                            />
                        )}

                        <OverviewTab
                            tab={tab}
                            instrument={instrument}
                            instrumentHistory={history}
                            exchange={exchange}
                            categoryLabels={categoryLabels}
                            period={period}
                            jurisdiction={jurisdiction}
                            setPeriod={setPeriod}
                            setHideFooter={setHideFooter}
                        />
                        {showInvestmentTab && (
                            <YourInvestmentTab
                                currentHistory={currentHistory}
                                currentReturn={currentReturn}
                                holding={holding}
                                includeSoldInvestments={includeSoldInvestments}
                                instrument={instrument}
                                isDependent={isDependent}
                                isHomeCurrencyInvestment={instrument.currency === homeCurrency}
                                orders={orders}
                                ordersState={ordersState}
                                period={period}
                                preferredName={preferredName}
                                setHideFooter={setHideFooter}
                                setPeriod={setPeriod}
                                sharesSoldCount={sharesSoldCount}
                                tab={tab}
                                essViews={essViews}
                            />
                        )}
                    </LocationContext.Provider>
                </Page>
                {tab === 'Overview' && !isInstrumentDerivative(instrument) && (
                    <Feedback category="Investment" actionIdentifier={actionIdentifier} />
                )}

                {!hideFooter && footerContent()}

                <InvestPanel instrument={instrument} />
                <PriceHistoryRefetch instrument={instrument} />
            </InstrumentOrderContext.Provider>
        </>
    )
}

interface StoreProps {
    instrument?: Instrument
    instrumentId?: string
    instrumentSlug: string
}

interface StoreLoadedFromWrapperProps {
    instrument: Instrument
    lastResultBeforeNewPage: boolean
    nextLink?: string
    prevLink?: string
}

export interface ViewInstrumentProps {
    diyAIType?: DIYAutoInvestType
    instrumentListType: CycleMode
    instrumentSlug: string
    params?: object
    premadeSlug?: PremadeSlugType
    showLegacyData?: boolean
}

type InstrumentProps = StoreProps & ViewInstrumentProps
type InstrumentLoadedProps = StoreLoadedFromWrapperProps & ViewInstrumentProps

export default ViewInstrumentDataWrapper
