import {Button} from '@design-system/button'
import {SelectCards} from '@design-system/select-cards'
import React from 'react'
import {useNavigate} from 'react-router'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useStagedTransfer} from '~/sections/moose/sections/transfer/hooks/useStagedTransfer'
import {InstrumentSymbol} from '~/sections/moose/sections/transfer/state'
import {isShareType, isUnitType} from '~/sections/moose/sections/utils/symbol-helpers/symbolHelpers'

export const InstrumentSelect = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const [stagedTransfer, setStagedTransfer] = useStagedTransfer()

    const [instrumentSymbol, setInstrumentSymbol] = React.useState<InstrumentSymbol | undefined>(
        stagedTransfer.instrumentSymbol || undefined,
    )

    const onNext = () => {
        setStagedTransfer({...stagedTransfer, instrumentSymbol})
        navigate(
            profileUrl('fonterra/transfer/select/:direction', {
                direction: 'out',
            }),
        )
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--transfer-instrument-select" leftButton="back" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <SelectCards
                    dataTestId="select-cards--transfer-instrument-select"
                    title="What are you wanting to transfer?"
                    options={[
                        {
                            title: 'Fonterra Co-operative Group',
                            supportingText: 'Shares',
                            name: 'symbol',
                            value: 'FCG' as InstrumentSymbol,
                            isActive: isShareType(instrumentSymbol),
                        },
                        {
                            title: 'Fonterra Shareholders’ Fund',
                            supportingText: 'Units',
                            name: 'symbol',
                            value: 'FSF' as InstrumentSymbol,
                            isActive: isUnitType(instrumentSymbol),
                        },
                    ]}
                    onChange={e => setInstrumentSymbol(e.target.value as InstrumentSymbol)}
                />
            </Page>
            <ActionBar>
                <Button
                    label="Next"
                    type="primary"
                    dataTestId="button--next"
                    onClick={onNext}
                    disabled={!instrumentSymbol}
                />
            </ActionBar>
        </>
    )
}
