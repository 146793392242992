import {ModalLink} from '@design-system/modal'
import React from 'react'

const AveragePricePaidPerShare = () => (
    <ModalLink
        dataTestId="modal-link--average-price-paid-per-share"
        label="Average price paid per share"
        asIcon
        modalTitle="Average price paid per share when transferring shares"
        primaryButton={{label: 'Ok'}}
        helpIconSize={16}
    >
        <p>
            By default, we’ll use the latest close price per share to calculate your returns when you transfer shares.
            You can adjust this price if you want your returns to more closely reflect the price you actually paid.
        </p>
        <p>
            To calculate the average price paid per share, you’ll need to add together the amounts you’ve previously
            paid to buy shares, then divide this by the number of shares you own.
        </p>
        <h2>For example</h2>
        <p>
            If the total amount you’ve paid for shares is $1,314, and you own 520 shares, you’d work out your average
            price paid per share by dividing $1,314 by 520, which equals $2.526923.
        </p>
        <p>
            If you didn’t pay anything for your shares (for example, if you received them as a gift or as part of an
            employee share scheme), you might want to enter $0.00.
        </p>
        <p>
            Be aware that this can result in large (but accurate) percentage increases in your investment’s (and
            Portfolio’s) returns.
        </p>
        <p>
            You can edit the average price paid per share at any time, but it might take a few hours for the updated
            price to be reflected in your Portfolio’s returns.
        </p>
    </ModalLink>
)

export default AveragePricePaidPerShare
