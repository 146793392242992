import * as rollbar from '~/api/rollbar/rollbar'

// @TODO Stuzza & Kazza - preliminary Explore cards classification, likely to change
export const EXPLORE_BRAZE_CARD_TYPES = {
    category: {
        exploreContent: 'explore_content',
        exploreCompanyAnnouncement: 'explore_company_announcement',
        exploreCarouselGroup: 'explore_carousel_group',
        exploreCarouselItem: 'explore_carousel_item',
        exploreImageCard: 'explore_image_card',
        exploreTwoImageGroup: 'explore_two_image_group',
        exploreTwoImageItem: 'explore_two_image_item',
        exploreChecklistCardGroup: 'explore_checklist_group',
        exploreChecklistCardItem: 'explore_checklist_item',
        exploreChecklistCardVideoItem: 'explore_checklist_video_item',
        exploreVideoCard: 'explore_video_card',
        exploreHalfImageCard: 'explore_half_image_card',
    },
    displayType: {
        announcement: 'announcement',
    },
} as const

// Adding a EXPLORE_BRAZE_CARD_TYPES.category above will automatically be passed through to the explore page
const EXPLORE_CONTENT_CARD_CATEGORIES: string[] = Object.keys(EXPLORE_BRAZE_CARD_TYPES.category).map(key => {
    return EXPLORE_BRAZE_CARD_TYPES.category[key as keyof typeof EXPLORE_BRAZE_CARD_TYPES.category]
})

export function isExploreContentCard(category?: string): boolean {
    if (!category) {
        return false
    }

    return EXPLORE_CONTENT_CARD_CATEGORIES.includes(category)
}

// https://sharesies.atlassian.net/wiki/spaces/MOB/pages/283410496/Content+Card+delivery+Braze
export interface ExploreContentCardCommon {
    priority?: number
}

type ExploreBrazeCardTypes = keyof typeof EXPLORE_BRAZE_CARD_TYPES.category

interface ExploreContentCardsRollbarErrorProps {
    exploreCardType: ExploreBrazeCardTypes
    missingValues: Record<string, string | undefined | number>
    id?: string
}

export const exploreContentCardsRollbarError = ({
    exploreCardType,
    missingValues = {},
    id,
}: ExploreContentCardsRollbarErrorProps) => {
    rollbar.sendError(`ExploreContentCards: Braze card is missing required values.`, {
        card_type: EXPLORE_BRAZE_CARD_TYPES.category[exploreCardType],
        // Only send the keys that are missing to rollbar (falsy values)
        [`${EXPLORE_BRAZE_CARD_TYPES.category[exploreCardType]}.missing_values`]: Object.keys(missingValues).reduce<
            string[]
        >((accumulator, key) => {
            if (!missingValues[key]) {
                return [...accumulator, key]
            }

            return accumulator
        }, []),
        braze_card_id: id,
    })
}
