import {Modal} from '@design-system/modal'
import React from 'react'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import styles from '~/sections/invest/sections/transfer-shares/widgets/transfer-insufficient-funds-modal/TransferInsufficientFundsModal.scss'

const TransferInsufficientFundsModal = ({
    feeCurrency,
    feeValue,
    isOpen,
    setIsOpen,
}: {
    feeCurrency: string
    feeValue: string
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const profileUrl = useProfileUrl()

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title="Top up or exchange money"
            dataTestId="modal--insufficient-funds"
            primaryButton={{label: 'Got it'}}
        >
            <p>
                You don't have enough money ({feeCurrency}) in your Wallet to cover the{' '}
                <DollarValue value={feeValue} currency={feeCurrency} /> transfer fee.
            </p>
            <p>
                To submit your transfer request,{' '}
                <a className={styles.topupLink} href={profileUrl('wallet/topup')} target="_blank" rel="noopener">
                    top up your Wallet
                </a>{' '}
                or exchange money so that you have enough {feeCurrency} to cover the fee.
            </p>
        </Modal>
    )
}

export {TransferInsufficientFundsModal}
