import React from 'react'
import {Select} from '..'
import {FormikFieldInjectedProps} from '../common'

interface Props extends FormikFieldInjectedProps<string> {
    longValues?: boolean
}

const AUStateSelect: React.FunctionComponent<Props> = ({longValues, name, value, setFieldValue}) => {
    return (
        <Select
            dataTestId={`select--${name}`}
            name={name}
            label="State"
            placeholder="Select your state"
            isTouched={false}
            onChange={v => {
                if (setFieldValue) {
                    setFieldValue(v.currentTarget.value)
                }
            }}
            value={value || ''}
            choices={[
                {value: '', label: ''},
                ...AUSTRALIAN_STATES.map(s => ({
                    value: longValues ? s.longName : s.abbreviation,
                    label: s.longName,
                })),
            ]}
        />
    )
}

export default AUStateSelect

interface AddressState {
    longName: string
    abbreviation: string
}

const AUSTRALIAN_STATES: AddressState[] = [
    {longName: 'New South Wales', abbreviation: 'NSW'},
    {longName: 'Queensland', abbreviation: 'QLD'},
    {longName: 'Northern Territory', abbreviation: 'NT'},
    {longName: 'Western Australia', abbreviation: 'WA'},
    {longName: 'South Australia', abbreviation: 'SA'},
    {longName: 'Victoria', abbreviation: 'VIC'},
    {longName: 'Australian Capital Territory', abbreviation: 'ACT'},
    {longName: 'Tasmania', abbreviation: 'TAS'},
]

export const getAuStateAbbreviation = (longName: String): string | null => {
    const state = AUSTRALIAN_STATES.find(s => s.longName === longName)
    return state ? state.abbreviation : null
}
