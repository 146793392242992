import {ClassicCard, logContentCardClick} from '@braze/web-sdk'
import {DateTime} from 'luxon'
import React from 'react'
import {Link} from 'react-router-dom'
import Slider from 'react-slick'
import {useIntersectionObserver} from 'usehooks-ts'
import {logContentCardImpressions} from '~/api/braze/braze'
import {dateFormatShortDayMonthNoYear} from '~/global/utils/format-date/formatDate'
import {isInternalLink, normalizeUrl} from '~/global/utils/normalize-url/normalizeUrl'
import {Arrow} from '~/sections/save/pages/waitlist/SaveWaitlist'
import {EXPLORE_BRAZE_CARD_TYPES, ExploreContentCardCommon, exploreContentCardsRollbarError} from '../common'
import styles from './CarouselCard.scss'

interface CarouselItemProps extends ExploreContentCardCommon {
    type: typeof EXPLORE_BRAZE_CARD_TYPES.category.exploreCarouselItem
    title: string
    linkText: string
    url: string
    imageUrl: string
    groupId: string
    card: ClassicCard
}

interface CarouselGroupProps extends ExploreContentCardCommon {
    type: typeof EXPLORE_BRAZE_CARD_TYPES.category.exploreCarouselGroup
    groupId: string
    items: CarouselItemProps[]
    contentLabel: string
}

export function groupCarouselCards(cards: ClassicCard[]): ClassicCard[] {
    // Filter out all exploreCarouselItem cards
    const childCards = cards.filter(
        card => card.extras?.category === EXPLORE_BRAZE_CARD_TYPES.category.exploreCarouselItem,
    )

    return cards.reduce((accumulator: ClassicCard[], card) => {
        if (card.extras?.category === EXPLORE_BRAZE_CARD_TYPES.category.exploreCarouselItem) {
            // Remove any exploreCarouselItem cards
            return accumulator
        }

        if (card.extras?.category === EXPLORE_BRAZE_CARD_TYPES.category.exploreCarouselGroup) {
            const cardWithChildren: ClassicCard = card

            // Assign childItems to extras, assuming this doesn't get blitzed by something coming out of Braze
            card.extras.childItems = childCards.filter(
                childCard => childCard.extras?.group_id === card.extras?.group_id,
            )

            return [...accumulator, cardWithChildren]
        }

        return [...accumulator, card]
    }, [])
}

export function brazeCardToCarouselItem(card: ClassicCard): CarouselItemProps | null {
    const {title, linkText, imageUrl, extras = {}, id} = card
    const {url} = card
    const {group_id, priority} = extras

    if (!url || !imageUrl) {
        exploreContentCardsRollbarError({
            exploreCardType: 'exploreCarouselItem',
            id,
            missingValues: {url, imageUrl},
        })
        return null
    }

    return {
        type: EXPLORE_BRAZE_CARD_TYPES.category.exploreCarouselItem,
        title,
        linkText: linkText || '',
        url,
        imageUrl,
        groupId: group_id,
        priority,
        card,
    }
}

export function brazeCardToCarouselGroup({extras = {}}: ClassicCard): CarouselGroupProps {
    const {priority, group_id, explore_content_label, childItems = []} = extras

    const items = (childItems as ClassicCard[]).map(child => brazeCardToCarouselItem(child))
    // Filter out any null TwoImageItemProps and sort by priority
    const sortedItems = (items.filter(childCard => childCard !== null) as CarouselItemProps[]).sort(
        (a, b) => (b.priority || 0) - (a.priority || 0),
    )

    return {
        type: EXPLORE_BRAZE_CARD_TYPES.category.exploreCarouselGroup,
        groupId: group_id,
        items: sortedItems,
        priority,
        contentLabel: explore_content_label,
    }
}

export const CarouselCard = ({items, contentLabel, priority = 0, groupId}: CarouselGroupProps) => {
    const [isLastSlide, setIsLastSlide] = React.useState(false)
    const [isFirstSlide, setIsFirstSlide] = React.useState(true)
    const now = DateTime.now()
    const start = now.minus({days: 7}).startOf('week')
    const end = start.endOf('week')

    if (!items) {
        return null
    }

    return (
        <div className={styles.carouselGroup} data-priority={priority}>
            <h2>{contentLabel}</h2>

            {groupId === 'explore_top_ten' && (
                <p>
                    See what Sharesies investors bought in the week of {start.toFormat(dateFormatShortDayMonthNoYear)}–
                    {end.toFormat(dateFormatShortDayMonthNoYear)}.
                </p>
            )}

            <Slider
                arrows
                nextArrow={
                    // If it's the last slide, hide the next arrow
                    isLastSlide ? <></> : <Arrow direction="next" />
                }
                prevArrow={
                    // If it's the first slide, hide the prev arrow
                    isFirstSlide ? <></> : <Arrow direction="prev" />
                }
                beforeChange={(_, nextSlide) => {
                    if (nextSlide === 0) {
                        // The first slide
                        setIsFirstSlide(true)
                    } else {
                        setIsFirstSlide(false)
                    }

                    if (Math.ceil(nextSlide) === items.length - 1) {
                        // The last slide
                        setIsLastSlide(true)
                    } else {
                        setIsLastSlide(false)
                    }
                }}
                slidesToShow={2.3} // 834px +
                className={styles.slider}
                infinite={false}
                draggable
                touchMove
                accessibility
                responsive={[
                    {
                        // 540px - 834px
                        breakpoint: 834,
                        settings: {
                            slidesToShow: 3.3,
                            arrows: false,
                        },
                    },
                    {
                        // 320px - 540px
                        breakpoint: 540,
                        settings: {
                            slidesToShow: 1.5,
                            arrows: false,
                        },
                    },
                ]}
            >
                {items.map((props, index) => (
                    <Card key={index} {...props} />
                ))}
            </Slider>
        </div>
    )
}

const Card = ({title, linkText, url, imageUrl, card}: CarouselItemProps) => {
    const ref = React.useRef<HTMLDivElement>(null)
    const entry = useIntersectionObserver(ref, {})
    const isVisible = !!entry?.isIntersecting
    const notificationCategory = card.extras?.category ? card.extras?.category : ''
    const instrumentId = card.extras?.instrument_id ? card.extras?.instrument_id : ''
    const {viewed} = card
    const [impressionLogged, setImpressionLogged] = React.useState(false)
    const safeUrl = normalizeUrl(url)
    const isExternalLink = !isInternalLink(safeUrl)

    React.useEffect(() => {
        if (isVisible && !impressionLogged) {
            setImpressionLogged(true)

            // Mark viewed in brazeland
            logContentCardImpressions([card])
        }
    }, [viewed, isVisible, logContentCardImpressions, notificationCategory, instrumentId])

    const logClick = () => {
        logContentCardClick(card)
    }

    // Remove the current origin e.g. "https://app.sharesies.com" so react-router
    // link component works and doesn't open the link in a new tab
    return (
        <div key={url} className={styles.slide} ref={ref}>
            {isExternalLink ? (
                <a target="_blank" rel="noopener noreferrer" onClick={logClick} href={safeUrl}>
                    <img className={styles.image} src={imageUrl} alt="" />
                    <div className={styles.content}>
                        {title && <h3>{title}</h3>}
                        <p>{linkText}</p>
                    </div>
                </a>
            ) : (
                <Link to={safeUrl} onClick={logClick}>
                    <img className={styles.image} src={imageUrl} alt="" />

                    <div className={styles.content}>
                        {title && <h3>{title}</h3>}
                        <p>{linkText}</p>
                    </div>
                </Link>
            )}
        </div>
    )
}
