import cn from 'classnames'
import React from 'react'
import headerStyles from '~/global/scss/reused-styles/pageHeader.scss'
import kiwisaverLarge from '~/sections/kiwisaver/assets/images/kiwisaver-large.png'
import styles from '~/sections/kiwisaver/sections/home/Home.scss'
import {useAppSelector} from '~/store/hooks'
import {supportMP4Alpha} from './supportMP4Alpha'
import loveableMomentStyles from './LoveableMoment.scss'

const LOVEABLE_MOMENT_WEBM =
    'https://videos.ctfassets.net/eg3voq9njjf7/heXu3KOwPLEHAMhAD7VIv/a28f5c8cf7179565e363d2cea139864b/ks-sign-up-transition.webm'
const LOVEABLE_MOMENT_MP4 =
    'https://videos.ctfassets.net/eg3voq9njjf7/1ognuAI9Sr4nRvgoJPpPus/33dde5096c36cdec1290da5f5d3f1ce5/ks-sign-up-transition.mp4'

export const LoveableMoment: React.FunctionComponent<{displayAnimation: boolean}> = displayAnimation => {
    const toolbarHiddenForMobile = useAppSelector(s => s.nav.toolbarHidden)

    return (
        <>
            {displayAnimation.displayAnimation ? (
                <div
                    data-test-id="video--loveable-movement"
                    className={cn(loveableMomentStyles.imageContainer, {
                        [loveableMomentStyles.slideUpRow]: displayAnimation,
                    })}
                    style={{paddingTop: toolbarHiddenForMobile ? '25px' : '0px'}}
                >
                    {/* poster is required when the video can't load, for those devices that were restricted by
                        1. lower battery mode and
                        2. prefers-reduced-motion mode
                        so that we can still deliver the animation but not our loveable 3d video :-(
                    */}
                    <video id="video" preload="auto" playsInline muted poster={kiwisaverLarge}>
                        {supportMP4Alpha() && <source src={LOVEABLE_MOMENT_MP4} type="video/mp4" />}
                        <source src={LOVEABLE_MOMENT_WEBM} type="video/webm" />
                    </video>
                </div>
            ) : (
                <div
                    className={cn(headerStyles.landingImageWrapper, styles.background)}
                    style={{paddingTop: toolbarHiddenForMobile ? '25px' : '0px'}}
                >
                    <img
                        data-test-id="image--loveable-moment"
                        src={kiwisaverLarge}
                        alt="A bowl with fruit in it"
                        className={headerStyles.landingImage}
                    />
                </div>
            )}
        </>
    )
}
