import React from 'react'
import {Model} from '~/api/retail/types'
import {connect} from '~/store/connect'

export const helpEmailString = (jurisdiction?: Model.User['jurisdiction']) => {
    switch (jurisdiction) {
        case 'nz':
            return 'help@sharesies.co.nz'
        case 'au':
            return 'help@sharesies.com.au'
        default:
            return 'help@sharesies.com'
    }
}

/**
 * Use the unconnected version of this component only for points in the flow
 * where we know what the investors jurisdiction is locally but we haven't gotten
 * that info back from the backend yet, e.g. in the About You step
 */
export const HelpEmail: React.FC<StoreProps> = ({jurisdiction}) => {
    return <a href={`mailto:${helpEmailString(jurisdiction)}`}>{helpEmailString(jurisdiction)}</a>
}

interface StoreProps {
    jurisdiction?: Model.User['jurisdiction']
}

export default connect<StoreProps, {}, {}>(state => ({
    jurisdiction: state.identity.user ? state.identity.user.jurisdiction : undefined,
}))(HelpEmail)
