export const linkWithMailToIfNeeded = (link: string) => {
    /**
     * Basic helper that renders text as a mailto: link if the text is an email address.
     * The regex used here is not so comprehensive that it catches every possible email
     * address (a regex that can do that is frickin' huge). If we want this function to be
     * more accurate then we'd have to change this
     */
    const re = new RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
    return link.match(re) ? `mailto:${link}` : link
}
