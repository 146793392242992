import {colour, semanticColour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import cn from 'classnames'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import giftCard from '~/global/assets/images/gifts/gift-card.svg'
import {spacing} from '~/global/scss/helpers'
import {convertToKebabCase} from '~/global/utils/convert-to-kebab-case/convertToKebabCase'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AccountVerificationRequired from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {useAppSelector} from '~/store/hooks'
import styles from './ShareTheLove.scss'

export interface Content {
    shouldDisplay: boolean
    className?: string
    title: string
    description: string
    image: string
    overlayText?: string
    overlayTextColour?: string
    illustrationStyle?: string
    onClick(): void
    action: string
    dataTestId?: string
}

const ShareTheLove: React.FunctionComponent<{}> = () => {
    const colourMode = useColourMode()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const jurisdiction = useAppSelector(({identity}) => identity.user?.jurisdiction)
    const accountRestricted = useAppSelector(({identity}) => identity.user!.account_restricted)

    const tileContents: Content[] = [
        {
            shouldDisplay: true,
            title: '',
            className: styles.illustrationGiftingShareTheLove,
            description: `Help someone special build their portfolio with a Sharesies Gift—or redeem one for yourself!${
                accountRestricted ? ' Once your identity is verified, you can get started.' : ''
            }`,
            image: giftCard,
            overlayText: `SHARESIES\nGIFTS`,
            overlayTextColour: semanticColour('BrandText', colourMode),
            onClick: () => {
                Analytics.event({
                    category: 'Gifts',
                    action: 'Tile Nav',
                    label: 'Gifts',
                })
                navigate(profileUrl('gifts'))
            },
            action: 'Buy or redeem a Gift',
            dataTestId: 'tile--buy-or-redeem-a-gift',
        },
        {
            shouldDisplay: true,
            title: '',
            className: styles.illustrationReferAFriend,
            description: 'Get a sweet bonus for you and your mate when they sign up using your referral link.',
            image: '',
            overlayText: `REFER A\nFRIEND`,
            overlayTextColour: colour('Pool600', colourMode),
            onClick: () => {
                Analytics.event({
                    category: 'Refer a Friend',
                    action: 'Tile Nav',
                    label: 'Refer a Friend',
                })
                navigate(profileUrl('referrals'))
            },
            action: 'Refer a friend',
            dataTestId: 'tile--refer-a-friend',
        },
    ]

    const customisedContents = tileContents.filter(t => t.shouldDisplay)

    return (
        <>
            <Toolbar
                dataTestId="toolbar--share-the-aroha"
                leftButton="menu"
                title={jurisdiction === 'nz' ? 'Share the aroha' : 'Share the love'}
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <AccountVerificationRequired />
                {customisedContents.map((content, index) => (
                    <div
                        key={index}
                        className={cn(styles.tile, spacing.spaceBelow16)}
                        onClick={content.onClick}
                        role="link"
                        data-testid={content.dataTestId}
                        style={{height: 'auto'}}
                    >
                        <div className={cn(styles.illustration, content.className)}>
                            <span className={styles.overlayContainer}>
                                <p className={styles.overlayText} style={{color: content.overlayTextColour}}>
                                    {content.overlayText}
                                </p>
                            </span>
                            <img src={content.image} />
                        </div>

                        <div className={styles.tileContent}>
                            <p className={styles.tileTitle}>{content.title}</p>
                            <p className={styles.tileDescription}>{content.description}</p>

                            {!accountRestricted || content.action === 'Refer a friend' ? (
                                <ButtonAsLink
                                    inline
                                    className={styles.tileAction}
                                    dataTestId={`button-as-link--${convertToKebabCase(content.action.toLowerCase())}`}
                                >
                                    {content.action}
                                </ButtonAsLink>
                            ) : null}
                        </div>
                    </div>
                ))}
            </Page>
        </>
    )
}

export default ShareTheLove
