import React from 'react'
import {useAppSelector} from '~/store/hooks'

interface RefetchProps {
    timeoutPeriod: number
    fetchAction(): void
    refetchDependsOn?: any[]
    doInitialFetch?: boolean
}

/**
 * A timer that runs every timeoutPeriod to re-fetch data.
 *
 * If the tab isn't visible (using document.visibilityState) then we don't
 * fetch, we do however track how many times this happens consecutively.
 *
 * If the page becomes visible again _and_ we've skipped at least 1 fetch,
 * we'll fetch immediately.
 */
export const ReFetch: React.FunctionComponent<RefetchProps> = React.memo(
    ({timeoutPeriod, fetchAction, refetchDependsOn, doInitialFetch}) => {
        const isLoggedIn = useAppSelector(s => !!s.identity.user)

        // The timer reference
        const timerContainer = React.useRef<NodeJS.Timeout | null>(null)

        // How many consecutive times we've skipped fetching because the page wasn't visible
        const skippedCountContainer = React.useRef(0)

        const isVisible = () => document.visibilityState !== 'hidden'

        const visiblityChangeHandler = () => {
            if (isLoggedIn && isVisible() && skippedCountContainer.current > 0) {
                fetchAction()
                skippedCountContainer.current = 0
            }
        }

        React.useEffect(
            () => {
                if (timerContainer.current) {
                    // Stop any existing timer and reset skip count
                    clearInterval(timerContainer.current)
                    timerContainer.current = null
                    skippedCountContainer.current = 0
                } else if (isLoggedIn && doInitialFetch) {
                    // If there is no timer, this is on mount or log in. Do the initial fetch.
                    fetchAction()
                }

                if (isLoggedIn) {
                    // If user is logged in, start up the timer
                    timerContainer.current = setInterval(() => {
                        if (isVisible()) {
                            fetchAction()
                            skippedCountContainer.current = 0
                        } else {
                            skippedCountContainer.current++
                        }
                    }, timeoutPeriod)
                }

                document.addEventListener('visibilitychange', visiblityChangeHandler)

                return () => {
                    document.removeEventListener('visibilitychange', visiblityChangeHandler)
                    if (timerContainer.current) {
                        clearInterval(timerContainer.current)
                    }
                }
            },
            refetchDependsOn ? [isLoggedIn, ...refetchDependsOn] : [isLoggedIn],
        )

        return null
    },
)

export default ReFetch
