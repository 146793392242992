import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useHasOrderedOrHeldShares} from '~/global/state-hooks/rakaia/useHasOrderedOrHeldShares'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Image from '~/global/widgets/image/Image'
import {useAppSelector} from '~/store/hooks'
import aiSearchIconDark from './assets/images/ai-search-icon-dark.svg'
import aiSearchIconLight from './assets/images/ai-search-icon-light.svg'
import styles from './PortfolioAiSearch.scss'

export const PortfolioAiSearch = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const profile = useProfile()

    const hasAiSearchFlag = useAppSelector(s => s.identity.flags.ai_search)
    const investPortfolio = profile.portfolios.find(p => p.product === 'INVEST')!
    const hasInvested = useHasOrderedOrHeldShares(investPortfolio.id)

    if (!hasAiSearchFlag || !hasInvested) {
        return <></>
    }

    return (
        <div role="button" className={styles.aiSearchCta} onClick={() => navigate(profileUrl('invest/ai-search'))}>
            <div className={styles.body}>
                <Image src={aiSearchIconLight} darkSrc={aiSearchIconDark} alt="" />
                <div>
                    <h3>AI Search</h3>
                    <p>Discover investments and gain insight using our AI-powered search tool.</p>
                </div>
            </div>
        </div>
    )
}
