/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    Currency,
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
    CurrencyToJSONTyped,
    InstrumentType,
    InstrumentTypeFromJSON,
    InstrumentTypeFromJSONTyped,
    InstrumentTypeToJSON,
    InstrumentTypeToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface Instrument
 */
export interface Instrument {
    /**
     *
     * @type {string}
     * @memberof Instrument
     */
    uuid: string
    /**
     *
     * @type {string}
     * @memberof Instrument
     */
    code: string
    /**
     *
     * @type {Currency}
     * @memberof Instrument
     */
    currency: Currency
    /**
     *
     * @type {number}
     * @memberof Instrument
     */
    risk_rating?: number
    /**
     *
     * @type {InstrumentType}
     * @memberof Instrument
     */
    type: InstrumentType
}

export function InstrumentFromJSON(json: any): Instrument {
    return InstrumentFromJSONTyped(json, false)
}

export function InstrumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Instrument {
    if (json === undefined || json === null) {
        return json
    }
    return {
        uuid: json['uuid'],
        code: json['code'],
        currency: CurrencyFromJSON(json['currency']),
        risk_rating: !exists(json, 'risk_rating') ? undefined : json['risk_rating'],
        type: InstrumentTypeFromJSON(json['type']),
    }
}

export function InstrumentToJSON(value?: Instrument | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        uuid: value.uuid,
        code: value.code,
        currency: CurrencyToJSON(value.currency),
        risk_rating: value.risk_rating,
        type: InstrumentTypeToJSON(value.type),
    }
}
