import {DateTime} from 'luxon'
import uuid4 from 'uuid/v4'
import {replacePlaceholdersInPath} from '~/api/retail/util'
import * as rollbar from '~/api/rollbar/rollbar'
import {NonRollbarError, APINetworkError} from '~/global/utils/error-handling/errorHandling'
import {PathParams} from '~/global/utils/routing/routing'
import store from '~/store'
import actions from '~/store/identity/actions'
import * as notification from '~/store/notification'
import notificationActions from '~/store/notification/actions'
import {Response, POST_MAP, GET_MAP, FORM_POST_MAP, FILE_MAP} from './types'

function reviver(_key: any, value: any): any {
    if (value instanceof Object && '$quantum' in value) {
        return DateTime.fromMillis(value.$quantum)
    }
    return value
}

const processRequest = async (request: Request, options?: Partial<RequestInit>) => {
    const headers = new Headers()
    for (const [key, value] of request.headers.entries()) {
        headers.append(key, value)
    }
    headers.set('X-API-Version', String(SHARESIES_BUILD_INFORMATION.apiVersion))
    headers.set('X-Version', String(SHARESIES_BUILD_INFORMATION.version))
    headers.set('X-Git-Hash', SHARESIES_BUILD_INFORMATION.gitHash)

    try {
        let json
        const response = await fetch(request, {
            credentials: 'same-origin',
            headers,
            ...options,
        })
        const text = await response.text()

        try {
            json = JSON.parse(text, reviver)
        } catch (e) {
            // if there was an error with the json parse, just proceed to the error handling in the next block
        }

        if (!response.ok || !json) {
            if (response.status === 501 && json && json.type === 'client_upgrade_required') {
                store.dispatch(notificationActions.ClientUpgradeRequired())
                throw new NonRollbarError('Client upgrade required')
            }

            // When we're undergoing scheduled maintenance, a cloudflare worker is activated that
            // returns a 503 for every API request
            if (response.status === 503 && json && json.type === 'scheduled_maintenance') {
                store.dispatch(notificationActions.ScheduledMaintenance())
                throw new NonRollbarError('Undergoing scheduled maintenance')
            }

            if (json && json.type === 'authentication_required') {
                store.dispatch(actions.SetLoggedOut())
                throw new NonRollbarError('Authentication required (used logged out)')
            }

            if (json && json.type === 'account_frozen') {
                store.dispatch(actions.SetFrozen())
                throw new NonRollbarError('Account frozen')
            }

            if (response.status === 429) {
                notification.setModalError(
                    'For the security of all our customers we limit the number of attempts over a short period of time. Please try again in a few minutes.',
                    'Whoa, slow down',
                )
                throw new APINetworkError('429: throttled')
            }

            if (json && json.type === 'authentication_update_required') {
                // Prevent this logging an error (but still pass it through to handling code)
                return json
            }

            if ((response.status === 502 || response.status === 504) && navigator.onLine) {
                // TODO handle error for timeout when the user is online
                // we already handle this in individual flows like buy and sell, but we
                // should handle it during the app load too (in globalWrapper's loading state)
                // https://www.notion.so/sharesies/Better-timeout-handling-when-the-backend-is-down-ee325f3d3db846a4a61a36b60effecda
            }

            rollbar.sendError(`Failed XHR request: ${response.status}`, {
                url: request.url,
                method: request.method,
                status: response.status,
                statusText: response.statusText,
            })

            if (json && json.type === 'internal_server_error') {
                return json
            }

            if (!json) {
                throw new NonRollbarError(`Network error: ${response.status}`)
            }
        }
        return json
    } catch (e) {
        if (e instanceof APINetworkError) {
            rollbar.sendError('Network error', {
                url: request.url,
                method: request.method,
            })
            // Already reported to rollbar in detail so doesn't need to be reported by the regular catcher
            throw new NonRollbarError(e.message)
        }
        throw e
    }
}

function getKnownDeviceKey(): string {
    /**
     * Get the currently stored Known Device Key for this device. If there isn't one, we'll try and generate a new
     * one and store it. If that doesn't work it's fine, the objective is to help identify a known device in the event
     * that a login is failing or a password reset is occurring, we're just extra suspicious without this.
     */
    const KNOWN_DEVICE_KEY = 'known_device_key'

    const deviceKey: string = localStorage.getItem(KNOWN_DEVICE_KEY) || ''

    if (deviceKey) {
        return deviceKey
    }

    try {
        localStorage.setItem(KNOWN_DEVICE_KEY, uuid4())
    } catch (ignore) {
        // If this fails they're probably in private mode, it's fine.
    }

    return localStorage.getItem(KNOWN_DEVICE_KEY) || ''
}

export async function get<T extends keyof GET_MAP>(
    path: T,
    ...args: GET_MAP[T]['request'] extends undefined ? [] : [GET_MAP[T]['request']]
): Promise<GET_MAP[T]['response'] | Response.InternalServerError> {
    const url = new URL(`/api/${path}`, window.location.href)

    const params = args[0] as {[k: string]: any} | undefined
    if (params) {
        Object.keys(params).forEach(k => {
            let value = params[k]
            if (value instanceof DateTime) {
                value = value.setZone('UTC').toISO()
            }
            if (value === null || value === undefined) {
                return
            }
            url.searchParams.append(k, value)
        })
    }

    return processRequest(
        new Request(String(url), {
            method: 'GET',
            headers: {
                'x-known-device-key': getKnownDeviceKey(),
            },
        }),
        path === 'identity/check' ? {cache: 'no-store'} : undefined,
    )
}

export async function getWithPathParams<T extends keyof GET_MAP>(
    path: T,
    pathParams: {} extends PathParams<T> ? undefined : PathParams<T>,
    ...args: GET_MAP[T]['request'] extends undefined ? [] : [GET_MAP[T]['request']]
): Promise<GET_MAP[T]['response'] | Response.InternalServerError> {
    const realPath = replacePlaceholdersInPath(path!, pathParams)
    const url = new URL(`/api/${realPath}`, window.location.href)

    const params = args[0] as {[k: string]: any} | undefined
    if (params) {
        Object.keys(params).forEach(k => {
            let value = params[k]
            if (value instanceof DateTime) {
                value = value.setZone('UTC').toISO()
            }
            if (value === null || value === undefined) {
                return
            }
            url.searchParams.append(k, value)
        })
    }

    return processRequest(
        new Request(String(url), {
            method: 'GET',
            headers: {
                'x-known-device-key': getKnownDeviceKey(),
            },
        }),
        realPath === 'identity/check' ? {cache: 'no-store'} : undefined,
    )
}

export async function post<T extends keyof POST_MAP>(
    path: T,
    ...args: POST_MAP[T]['request'] extends undefined ? [] : [POST_MAP[T]['request']]
): Promise<POST_MAP[T]['response'] | Response.InternalServerError> {
    const body = args[0]
    return processRequest(
        new Request(`/api/${path}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-known-device-key': getKnownDeviceKey(),
            },
            body: body ? JSON.stringify(body) : '',
        }),
        path === 'identity/login' ? {cache: 'no-store'} : undefined,
    )
}

export function formPost<T extends keyof FORM_POST_MAP>(
    path: T,
    request: FORM_POST_MAP[T]['request'],
): Promise<FORM_POST_MAP[T]['response'] | Response.InternalServerError> {
    const body = new FormData()
    for (const key in request) {
        const requestValue = request[key]

        if (Array.isArray(requestValue)) {
            for (const value of requestValue) {
                body.append(key, value)
            }
        } else {
            body.append(key, requestValue as any)
        }
    }
    return processRequest(
        new Request(`/api/${path}`, {
            method: 'POST',
            body,
        }),
    )
}

/**
 * Generates a string URL given a URL/queryParams/pathParams for a valid API endpoint that returns some sort of file
 */
export function fileUrl<T extends keyof FILE_MAP>(
    path: T,
    queryParams: FILE_MAP[T]['request'],
    ...pathParams: {} extends PathParams<T> ? [] : [PathParams<T>]
): string {
    const templatedPath = pathParams.length
        ? path.replace(/:([^/]+)/g, (_, key: keyof PathParams<T>) => {
              const value = encodeURIComponent(pathParams[0][key])
              return value
          })
        : path

    const url = new URL(`/api/${templatedPath}`, window.location.href)
    if (queryParams) {
        for (const [key, value] of Object.entries(queryParams)) {
            url.searchParams.set(key, value)
        }
    }
    return url.toString()
}

export type FormErrors = Response.FormErrors['errors']
