// TODO(SM) - have a think about the failure case when there's no current rate (we need to figure out how to deal with errors more generally before fixing this)
// TODO(SM) - have a think about the USD/US equities enables functionality and how that'll work
import {Button} from '@design-system/button'
import {AvailableCurrency, choices} from '@design-system/strong-currency-exchange'
import Decimal from 'decimal.js'
import {Form, Formik, useFormikContext} from 'formik'
import React from 'react'
import {Navigate, useNavigate} from 'react-router'
import {useRetailGet} from '~/api/query/retail'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {useActor, useActorHasSeen} from '~/global/state-hooks/retail/useActor'
import {roundDownToTwoDecimalPlaces} from '~/global/utils/calculate-currency-exchange/calculateCurrencyExchange'
import {currencyDetails} from '~/global/utils/currency-details/currencyDetails'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {ExchangeMoneyInput} from '~/global/widgets/form-controls/formik'
import {WalletValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {calculateExchange} from '~/sections/wallet/pages/exchange-money/utils/calculate-exchange/calculateExchange'
import {SourceAmountHelp} from '~/sections/wallet/pages/exchange-money/widgets/source-amount-help/SourceAmountHelp'
import {useHistoryState, useWalletPortfolio} from '~/sections/wallet/state/local'
import {useBalances, useLegacyUserData} from '~/sections/wallet/state/retail'

export const exchangeRateCheckInterval = 1000 * 15 // 15 seconds

export interface FormValues {
    buyOrSell: 'buy' | 'sell'
    sourceAmount: string
    targetAmount: string
    netSourceAmount: string
    sourceCurrency: AvailableCurrency
    targetCurrency: AvailableCurrency
    quotedRate: string
    sourceFee: string
}

/**
 * Component to render the help text (and potential error) under the source currency input
 */

const ExchangeMoneyForm = () => {
    const walletPortfolio = useWalletPortfolio()
    const balances = useBalances()
    const actor = useActor()
    const {setFieldValue, values, isValid, errors, isSubmitting} = useFormikContext<FormValues>()
    const {isRestrictedAccount} = useLegacyUserData()

    const [sourceCurrency, setSourceCurrencyDirect] = React.useState<AvailableCurrency>(actor.default_display_currency)
    const [targetCurrency, setTargetCurrencyDirect] = React.useState<AvailableCurrency>('usd')

    /**
     * Mode for FX trade.
     * Buy means the target amount is the goal and source will adjust to fit.
     * Sell means the source amount is exact and the target amount will adjust to fit.
     */
    const [mode, setMode] = React.useState<'buy' | 'sell'>('buy')

    /**
     * Wrapper for setting source currency that swaps the target currency if you select source == target
     */
    const setSourceCurrency = React.useCallback(
        (currency: AvailableCurrency) => {
            setSourceCurrencyDirect(currency)
            if (currency === targetCurrency) {
                setTargetCurrencyDirect(sourceCurrency)
            }
        },
        [sourceCurrency, targetCurrency, setSourceCurrencyDirect, setTargetCurrencyDirect],
    )

    /**
     * Wrapper for setting target currency that swaps the source currency if you select source == target
     */
    const setTargetCurrency = React.useCallback(
        (currency: AvailableCurrency) => {
            setTargetCurrencyDirect(currency)
            if (currency === sourceCurrency) {
                setSourceCurrencyDirect(targetCurrency)
            }
        },
        [sourceCurrency, targetCurrency, setSourceCurrencyDirect, setTargetCurrencyDirect],
    )

    const {data: rates, isFetching: isFetchingRates} = useRetailGet({
        path: 'wallet/:portfolio_id/fx-rates',
        pathParams: {portfolio_id: walletPortfolio.id},
        options: {
            refetchInterval: exchangeRateCheckInterval,
        },
    })

    const rate = rates.fx_currencies.find(
        r => r.source_currency === sourceCurrency && r.target_currency === targetCurrency,
    )!

    const exchange = calculateExchange(mode, rate.rate, rates.fx_fee_rate, values.sourceAmount, values.targetAmount)

    // Ensure we update the "other" value when you change something
    React.useEffect(() => {
        if (exchange.sourceAmount !== undefined) {
            // The setTimeout is weird here, but it's necessary to ensure the validation behaves correctly
            setTimeout(() => {
                setFieldValue('sourceAmount', exchange.sourceAmount)
            }, 0)
        }
        if (exchange.targetAmount !== undefined) {
            // The setTimeout is weird here, but it's necessary to ensure the validation behaves correctly
            setTimeout(() => {
                setFieldValue('targetAmount', exchange.targetAmount)
            }, 0)
        }
    }, [exchange.sourceAmount, exchange.targetAmount])

    const sourceBalance = balances.find(b => b.currency === sourceCurrency)!.balance

    // This just sets the equivalent of hidden fields on the form for data that we need at submit-time to proceed
    // with the order
    React.useEffect(() => {
        setFieldValue('buyOrSell', mode)
    }, [mode])
    React.useEffect(() => {
        setFieldValue('netSourceAmount', exchange.netSourceAmount)
    }, [exchange.netSourceAmount])
    React.useEffect(() => {
        setFieldValue('sourceCurrency', sourceCurrency)
    }, [sourceCurrency])
    React.useEffect(() => {
        setFieldValue('targetCurrency', targetCurrency)
    }, [targetCurrency])
    React.useEffect(() => {
        setFieldValue('quotedRate', rate.rate)
    }, [rate.rate])
    React.useEffect(() => {
        setFieldValue('sourceFee', exchange.feeTotal)
    }, [exchange.feeTotal])
    return (
        <Form>
            <p className={spacing.spaceBelow20}>
                <WalletValue value={sourceBalance} currency={sourceCurrency} />
                {new Decimal(sourceBalance).greaterThanOrEqualTo(new Decimal('0.01')) && (
                    <>
                        {' '}
                        <ButtonAsLink
                            onClick={() => {
                                setMode('sell')
                                setFieldValue('sourceAmount', roundDownToTwoDecimalPlaces(sourceBalance))
                            }}
                            disabled={isRestrictedAccount}
                        >
                            Exchange all
                        </ButtonAsLink>
                    </>
                )}
            </p>
            <ExchangeMoneyInput
                dataTestId="exchange-money-input--source"
                name="sourceAmount"
                label="From"
                currency={sourceCurrency}
                choices={choices}
                autoFocus
                optionalAttributes={{
                    disabled: isRestrictedAccount,
                    onKeyDown: () => {
                        setMode('sell')
                    },
                }}
                validate={value => {
                    if (parseFloat(value) > parseFloat(sourceBalance)) {
                        return `You do not have enough ${currencyDetails[sourceCurrency].longName}`
                    }
                    if (parseFloat(value) < 0.01) {
                        return 'The minimum you can exchange is $0.01'
                    }
                }}
                handleCurrencyChange={setSourceCurrency}
                helpText={
                    <SourceAmountHelp
                        isFetchingRates={isFetchingRates}
                        rate={rate.rate}
                        rateUpdated={rate.updated}
                        sourceCurrency={sourceCurrency}
                        targetCurrency={targetCurrency}
                        netSourceAmount={exchange.netSourceAmount}
                        feeTotal={exchange.feeTotal}
                        error={errors.sourceAmount}
                    />
                }
                suppressValidation
            />

            <ExchangeMoneyInput
                dataTestId="exchange-money-input--target"
                name="targetAmount"
                label="To"
                currency={targetCurrency}
                choices={choices}
                validate={value => {
                    if (parseFloat(value) < 0.01) {
                        return 'The minimum you can exchange is $0.01'
                    }
                }}
                optionalAttributes={{
                    disabled: isRestrictedAccount,
                    onKeyDown: () => {
                        setMode('buy')
                    },
                }}
                handleCurrencyChange={setTargetCurrency}
            />
            <Button
                type="primary"
                dataTestId="button--exchange-currency"
                label="Exchange"
                isSubmit
                disabled={
                    !isValid || isRestrictedAccount
                    // TODO(SM) - usdSelectedButUsEquitiesDisabled
                }
                processing={isSubmitting} // prevent submit while rate is changing
                aria-label={isFetchingRates ? 'Updating exchange rate' : 'Exchange'}
            />
        </Form>
    )
}

export const ExchangeMoney: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const actor = useActor()
    const walletPortfolio = useWalletPortfolio()
    const balances = useBalances()
    const [hasSeenIntro] = useActorHasSeen('exchange_investor')

    // We default the source currency to whichever has the highest balance
    const sourceCurrency = [...balances].sort((a, b) => parseFloat(a.balance) - parseFloat(b.balance))[
        balances.length - 1
    ]!.currency

    // We default the target currency to the actor's default display currency
    // (except when it's the same as the source currency in which case we pick at random)
    const targetCurrency =
        sourceCurrency === actor.default_display_currency
            ? balances.filter(b => b.currency !== actor.default_display_currency)[0]!.currency
            : actor.default_display_currency

    const [fxOrder, setFxOrder] = useHistoryState<FormValues>('fxOrder', {
        buyOrSell: 'sell',
        sourceAmount: '',
        targetAmount: '',
        netSourceAmount: '',
        sourceCurrency,
        targetCurrency,
        quotedRate: '',
        sourceFee: '',
    })
    const [showRateWarning, setShowRateWarning] = useHistoryState<boolean>('showRateWarning', false)

    React.useEffect(() => {
        rudderTrack('exchange_money', 'page_view')
    }, [])

    if (!hasSeenIntro) {
        return (
            <Navigate
                to={profileUrl('wallet/:portfolioId/exchange-money/intro', {portfolioId: walletPortfolio.id})}
                replace
            />
        )
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--exchange-money" leftButton="back" hideIntercom title="Exchange money" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                {showRateWarning && (
                    <AlertCard type="warning" className={spacing.spaceBelow16}>
                        <p>The exchange rate has changed since you were last active.</p>
                        <ButtonAsLink onClick={() => setShowRateWarning(false)}>Ok, thanks</ButtonAsLink>
                    </AlertCard>
                )}

                <Formik
                    initialValues={fxOrder}
                    onSubmit={async values => {
                        setFxOrder(values)
                        navigate(
                            profileUrl('wallet/:portfolioId/exchange-money/confirm', {portfolioId: walletPortfolio.id}),
                            {
                                state: {
                                    fxOrder: values,
                                },
                            },
                        )
                    }}
                >
                    <ExchangeMoneyForm />
                </Formik>
            </Page>
        </>
    )
}
