import React from 'react'

/**
 * useRefScrollable returns true if the element is larger than the window's y-axis.
 */
export function useRefScrollable(ref: React.MutableRefObject<HTMLElement | null | undefined>): boolean {
    const [isScrollable, setIsScrollable] = React.useState<boolean>(false)

    React.useEffect(() => {
        // It's important to read the ref _inside_ the hook, as ref.current won't be defined during the first render,
        // but is guaranteed to be defined when hooks are evaluated
        const element = ref.current
        if (!element) {
            return
        }

        // Showcase runs the script in the root document, but renders into an iframe.
        // That means window.document refers to the root document, which messes the sizing logic up.
        // Luckily, we have `element`, an HTMLElement in the iframe's document, and we can get the document from that
        const html = element.ownerDocument.documentElement

        const recalcIsScrollable = () => {
            setIsScrollable(element.offsetHeight > html.clientHeight)
        }

        // React to changes in the window's size
        window.addEventListener('resize', recalcIsScrollable)

        // React to changes in the element's size
        let observer: ResizeObserver | null = null
        if ('ResizeObserver' in window) {
            // Use ResizeObserver directly, rather than useRefSize, to avoid unnecessary rerenders
            observer = new ResizeObserver(recalcIsScrollable)

            observer.observe(element)
        }

        recalcIsScrollable()

        return () => {
            window.removeEventListener('resize', recalcIsScrollable)
            observer?.disconnect()
        }
    }, [ref.current])

    return isScrollable
}
