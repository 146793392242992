import React from 'react'
import {formatNumber} from '~/global/utils/format-number/formatNumber'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'

const decimalPlaces = (value: string) => {
    return Number.isInteger(Number(value)) ? 0 : 2
}

export const withdrawDollarValue = (value: string) => {
    return formatNumber({number: value, roundDown: false, decimalPlaces: decimalPlaces(value)})
}

export const WithdrawDollarValue = ({value}: {value: string}) => {
    return <DollarValue value={value} decimalPlaces={decimalPlaces(value)} />
}
