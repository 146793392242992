import {assertNever} from '~/global/utils/assert-never/assertNever'
import {ActionsType} from './actions'
import {State} from './types'

const initialState: State = {
    currentPlan: undefined,
    allPlans: undefined,
    currentPlanLoaded: false,
}

function reducer(state: State = initialState, action: ActionsType): State {
    switch (action.type) {
        case 'plan.SetCurrentPlanV2':
            return {
                ...state,
                currentPlan: action.payload.plan,
                currentPlanLoaded: true,
            }
        case 'plan.SetAllPlans':
            return {
                ...state,
                allPlans: action.payload.plans,
            }
        case 'plan.ClearAllPlans':
            return {
                ...state,
                allPlans: undefined,
            }
        case 'plan.ClearCurrentPlan':
            return {
                ...state,
                currentPlan: undefined,
                currentPlanLoaded: false,
            }
        default:
            assertNever(action)
    }
    return state
}

export default reducer
