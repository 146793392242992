import {Button} from '@design-system/button'
import cn from 'classnames'
import {withFormik} from 'formik'
import React from 'react'
import {Model, Response} from '~/api/retail/types'
// TODO: Move useDistillInstrumentBySlug out of /kiwisaver once useQuery is properly supported. We can't do it yet as
//   useQuery is still incompatible with showcase and unit tests. This page isn't in showcase and doesn't have unit
//   tests, so we get early access to the future.
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {StrongCurrency} from '~/global/widgets/form-controls/formik'
import AveragePricePaidPerShare from '~/global/widgets/help-modals/AveragePricePaidPerShare'
import {Loading} from '~/global/widgets/loading/Loading'
import {ShareValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {Toast} from '~/global/widgets/toast/Toast'
import {
    NotificationContext,
    NotificationContextProps,
} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import styles from '~/sections/invest/sections/transfer-shares/pages/transfer-shares-details/TransferSharesDetails.scss'
import {useDistillInstrumentBySlug} from '~/sections/kiwisaver/data/distill'
import accountingActions from '~/store/accounting/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {DetailInstrument} from '~/store/instrument/types'
import actions from '~/store/transfer/actions'

interface UpdateTransferPriceFormValuesProps {
    price: string
}

interface UpdateTransferPriceFormProps {
    instrument: DetailInstrument
    transfer: Model.TransferOrderIn
    updateTransferPrice(price: string, transfer: Model.TransferOrderIn): Promise<void | Response.Error>
    fetchFundOrders(fundId: string): Promise<void>
    notificationContext: NotificationContextProps
}

const UpdateTransferPriceForm = withRouter(
    withFormik<UpdateTransferPriceFormProps & WithRouterProps, UpdateTransferPriceFormValuesProps>({
        enableReinitialize: true,
        mapPropsToValues: ({transfer}) =>
            transfer ? {price: parseFloat(transfer.records[0].price).toFixed(3)} : {price: ''},
        mapPropsToErrors: () => {
            return {
                // make the button disabled initially by setting price to have an error
                price: undefined,
            }
        },
        handleSubmit: async (
            {price},
            {
                props: {
                    instrument,
                    transfer,
                    updateTransferPrice,
                    notificationContext,
                    fetchFundOrders,
                    router: {navigate},
                },
            },
        ) => {
            if (!transfer || !instrument) {
                return
            }
            try {
                const response = await updateTransferPrice(price, transfer)
                if (response && response.type === 'error') {
                    notificationContext.showModalError({message: response.message})
                } else {
                    Toast('Sweet as, the average price paid has been changed')
                    fetchFundOrders(instrument.id)
                    // redirect to the original page - could be a couple of different places!
                    navigate(-1)
                }
            } catch (e) {
                notificationContext.showModalError({message: unknownErrorMessage})
            }
        },
        validate: values => {
            const errors: {price?: string} = {}

            if (values.price === '') {
                errors.price = 'Please enter a price'
            }

            return errors
        },
    })(({handleBlur, handleSubmit, isValid, isSubmitting}) => {
        return (
            <form onSubmit={handleSubmit}>
                <>
                    <StrongCurrency
                        dataTestId="strong-currency--price"
                        name="price"
                        label="Average price paid per share"
                        disabled={isSubmitting}
                        autoFocus
                        optionalAttributes={{
                            onBlur: handleBlur,
                        }}
                        decimalPlaces={3}
                        currency="nzd"
                        helpText={
                            <p className={styles.walletRemaining}>
                                Update the price to adjust your returns
                                <AveragePricePaidPerShare />
                            </p>
                        }
                    />
                </>
                <div className={styles.actions}>
                    <Button
                        dataTestId="button--save-price"
                        label="Save price"
                        disabled={!isValid}
                        processing={isSubmitting}
                        onClick={() => handleSubmit()}
                    />
                </div>
            </form>
        )
    }),
)

interface TransferSharesPriceUpdateProps {
    instrumentSlug: string
    orderId: string
}

const TransferSharesPriceUpdate: React.FunctionComponent<TransferSharesPriceUpdateProps> = ({
    orderId,
    instrumentSlug,
}) => {
    const dispatch = useAppDispatch()
    const notificationContext = React.useContext(NotificationContext)
    const instrument = useDistillInstrumentBySlug(instrumentSlug)
    const transfer = useAppSelector(
        ({transfer}) =>
            transfer.transferOrders.find(
                order => order.type === 'transfer_in' && order.id === orderId,
            ) as Model.TransferOrderIn,
    )

    const updateTransferPrice = (price: string, transfer: Model.TransferOrderIn) =>
        dispatch(actions.UpdateTransferPrice(price, transfer))
    const getTransferOrders = () => dispatch(actions.FetchTransfers())
    const fetchFundOrders = (fundId: string) => dispatch(accountingActions.FetchFundOrders(fundId))

    React.useEffect(() => {
        if (!transfer) {
            getTransferOrders()
        }
    }, [transfer])

    if (!instrument) {
        return <WeSlippedUp />
    }

    if (!transfer) {
        return <Loading isPineapple />
    }

    return (
        <Page>
            <PageBack />
            <div className={cn(styles.headerBlock, styles.titleBlock, styles.transferOutTitleBlock)}>
                <div>
                    <p className={styles.code}>
                        <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                        <PronounceLetters text={instrument.exchange} />
                    </p>
                    <h2>{instrument.name}</h2>
                    <p className={styles.sharesList}>
                        Transfer in <ShareValue value={transfer.shares || '0'} showFullValue /> shares
                    </p>
                </div>
            </div>
            <UpdateTransferPriceForm
                instrument={instrument}
                transfer={transfer}
                updateTransferPrice={updateTransferPrice}
                notificationContext={notificationContext}
                fetchFundOrders={fetchFundOrders}
            />
        </Page>
    )
}

export default TransferSharesPriceUpdate
