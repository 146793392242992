import cn from 'classnames'
import React from 'react'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import * as rollbar from '~/api/rollbar/rollbar'
import {spacing} from '~/global/scss/helpers'
import headerStyles from '~/global/scss/reused-styles/pageHeader.scss'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import FeatureOnboardingPill from '~/global/widgets/feature-onboarding-pill/FeatureOnboardingPill'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {ArrowLink} from '~/sections/kiwisaver/sections/home/widgets/arrow-link/ArrowLink'
import {KiwiSaverChart} from '~/sections/kiwisaver/sections/home/widgets/kiwisaver-chart/KiwiSaverChart'
import loveableMomentStyles from '~/sections/kiwisaver/sections/home/widgets/loveable-moment-animation/LoveableMoment.scss'
import {RecentActivities} from '~/sections/kiwisaver/sections/home/widgets/recent-activity/RecentActivities'
import {useActiveOrOffboardingKSCustomer as useActiveOrOffboardingKSCustomer} from '~/sections/kiwisaver/state'
import {InvestmentPlanSummaryCard} from '~/sections/kiwisaver/widgets/investment-plan-summary-card/InvestmentPlanSummaryCard'
import {useAppSelector} from '~/store/hooks'
import {actingAsID as actingAsIDSelector} from '~/store/identity/selectors'
import {InvestmentsGridFilterWrapper} from './widgets/investments-grid/InvestmentsGridFilterWrapper'
import {LoveableMoment} from './widgets/loveable-moment-animation/LoveableMoment'
import styles from './Home.scss'

const EIGHT_SECONDS = 8 * 1000
const FOUR_AND_HALF_SECONDS = 4.5 * 1000

export const Home: React.FunctionComponent<{}> = () => {
    const customer = useActiveOrOffboardingKSCustomer()
    const actingAsID = useAppSelector(actingAsIDSelector)
    const {data: identity} = useRetailGet({path: 'identity/check', payload: {acting_as_id: actingAsID}})
    const hasSeenWelcomeScreen =
        identity.type === 'identity_authenticated' ? identity.user.has_seen.ks_active_welcome_screen : false
    const displayAnimation = !hasSeenWelcomeScreen && customer.customer_state === 'ACTIVE'
    const customerIsOffboarding = customer.customer_state === 'OFFBOARDING'
    const profileUrl = useProfileUrl()

    const setCustomerHasSeen = useRetailPost({
        path: 'identity/identity/mark-has-seen-flag',
    })
    const setHasSeenWelcome = async () => {
        await setCustomerHasSeen.mutateAsync({
            acting_as_id: actingAsID,
            flag: 'ks_active_welcome_screen',
        })
    }

    React.useEffect(() => {
        if (displayAnimation) {
            const video = document.querySelector('video') as HTMLVideoElement
            // Video preload attribute is just telling the browser to load the full video in the background but
            // not actually downloading to be ready
            // so when the network latency issue occure, we require to force play so the animation video will download
            // and ready for it and display `Idle` and wait for another 3s - 4s to let the user to read the content
            if (video) {
                video.play() // force to play to get working on iOS safari
                video.pause()

                setTimeout(() => {
                    // after 4.5s delay full animation experience kick off
                    const promise = video.play()
                    if (promise !== undefined) {
                        promise.then().catch(error => {
                            // Autoplay was prevented and error happen
                            // sending rollbar to capture the issue to see which browser/device has experience
                            rollbar.sendError(error)
                        })
                    }
                }, FOUR_AND_HALF_SECONDS)

                setTimeout(() => {
                    setHasSeenWelcome()
                }, EIGHT_SECONDS) // set the seen flag after the full animation experience has done (duration: 8.0s)
            }
        }
    }, []) // run only 1 time

    return (
        <div className={cn({[loveableMomentStyles.page]: displayAnimation})}>
            <div
                className={cn(loveableMomentStyles.animationContentRow, {
                    [loveableMomentStyles.fadeOut]: displayAnimation,
                })}
            >
                <h2 aria-describedby="welcome-kiwisaver" data-testid="title--welcome">
                    WELCOME
                </h2>
                {/* this below ID is reference by accessibility support for aria-describedby */}
                <p id="welcome-kiwisaver" className={spacing.spaceAbove16}>
                    to the Sharesies KiwiSaver Scheme, we’re so happy you’ve joined us.
                </p>
            </div>
            <div className={cn(styles.background, loveableMomentStyles.pageMainContainer)}>
                <div className={cn({[loveableMomentStyles.fadeIn]: displayAnimation})}>
                    <Toolbar dataTestId="toolbar--kiwisaver-home" leftButton="back" showNotifications />
                </div>
                <Page className={styles.home} withoutDefaultPadding>
                    <div className={cn(loveableMomentStyles.animationPageGrid, styles.background)}>
                        <LoveableMoment displayAnimation={displayAnimation} />
                        <div
                            className={cn(
                                headerStyles.landingContentWrapper,
                                loveableMomentStyles.landingContentWrapper,
                                {[loveableMomentStyles.slideUpFadeIn]: displayAnimation},
                            )}
                        >
                            {customerIsOffboarding && (
                                <AlertCard className={spacing.spaceBelow24} type="info">
                                    <h2>We're processing your transfer request</h2>
                                    <p>Things might look a bit different to usual while we get that sorted</p>
                                </AlertCard>
                            )}

                            <KiwiSaverChart />

                            <RecentActivities />

                            <div className={styles.linksRow}>
                                <ArrowLink
                                    label="View all activity"
                                    url={profileUrl('kiwisaver/activity')}
                                    dataTestId="link--all-activity"
                                />
                                <ArrowLink
                                    label="View documents"
                                    url={profileUrl('kiwisaver/download-documents')}
                                    dataTestId="link--documents"
                                />
                            </div>

                            <hr className={cn(styles.divider, spacing.spaceAbove20)} />
                            <h1 className={spacing.spaceAbove16}>Investments</h1>
                            {!customerIsOffboarding && (
                                <InvestmentPlanSummaryCard
                                    editUrl={profileUrl('kiwisaver/edit-investment-plan')}
                                    plan={customer.investment_plan}
                                    className={spacing.spaceAbove16}
                                />
                            )}
                            <InvestmentsGridFilterWrapper />
                        </div>
                    </div>
                    <FeatureOnboardingPill viewingOnProduct="kiwisaver" />
                </Page>
            </div>
        </div>
    )
}
