import React from 'react'
import calculateWalletBalance from '~/global/utils/calculate-wallet-balance/calculateWalletBalance'
import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {useExchangeFeeRate} from '~/global/utils/exchange-fee-hooks/exchangeFeeHooks'
import {isWalletBalanceEstimated} from '~/global/utils/is-wallet-balance-estimated/isWalletBalanceEstimated'
import {Loading} from '~/global/widgets/loading'
import {WalletValue} from '~/global/widgets/number-elements/NumberElements'
import {State} from '~/store/accounting/types'
import {useAppSelector} from '~/store/hooks'
import styles from './EstimatedWalletBalance.scss'

interface EstimatedWalletBalanceProps {
    displayCurrency?: Currency
    exchangeRates?: State['exchangeRates']
}

const EstimatedWalletBalance = ({displayCurrency, exchangeRates}: EstimatedWalletBalanceProps) => {
    const exchangeFeeRate = useExchangeFeeRate()

    const walletBalances = useAppSelector(s => s.identity.user!.wallet_balances)
    const storeExchangeRates = useAppSelector(s => s.accounting.exchangeRates)
    const usEquitiesEnabled = useAppSelector(s => s.identity.user!.us_equities_enabled)
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)

    exchangeRates ??= storeExchangeRates

    // if the currency display is not overidden via 'displayCurrency', default to the homeCurrency
    if (!displayCurrency) {
        displayCurrency = homeCurrency
    }

    // no balance, return nothing
    if (!walletBalances) {
        return (
            <span className={styles.container}>
                <Loading />
            </span>
        )
    }

    // Got money in non display currency wallets?
    if (isWalletBalanceEstimated(walletBalances, displayCurrency)) {
        // No exchange rates available, wait...
        if (!exchangeRates) {
            return (
                <span className={styles.container}>
                    <Loading />
                </span>
            )
        } else {
            return (
                <span className={styles.container}>
                    <WalletValue
                        value={calculateWalletBalance(
                            walletBalances,
                            exchangeRates,
                            usEquitiesEnabled,
                            displayCurrency,
                            exchangeFeeRate,
                        )}
                        currency={displayCurrency}
                        className={styles.walletValue}
                    />
                </span>
            )
        }
    } else {
        return (
            <span className={styles.container}>
                <WalletValue
                    value={walletBalances[displayCurrency]}
                    currency={displayCurrency}
                    className={styles.walletValue}
                />
            </span>
        )
    }
}

export default EstimatedWalletBalance
