import {Modal} from '@design-system/modal'
import React from 'react'
import {spacing} from '~/global/scss/helpers'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const PlanRoundupsModal: React.FunctionComponent<Props> = ({isOpen, setIsOpen}) => {
    return (
        <>
            <Modal
                dataTestId="modal--round-ups"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title="Round-ups"
                content={
                    <div>
                        <p className={spacing.spaceBelow12}>
                            With round-ups, you can give your Wallet a boost using the leftover cents from your
                            day-to-day spending.
                        </p>
                        <p className={spacing.spaceBelow12}>
                            Round-ups are transferred from a linked bank account to your Wallet in lump sum amounts. You
                            have the choice to transfer them once they tally up to $5, $10, $15, $25, or $50.
                        </p>
                        <p className={spacing.spaceBelow12}>On a plan, your transfer fees on round-ups are covered.</p>
                        <p className={spacing.spaceBelow12}>
                            If you’re not on a plan, you’ll be charged a 2% transfer fee on each round-up transfer
                            (capped at $2 AUD per transaction).
                        </p>
                    </div>
                }
            />
        </>
    )
}

export default PlanRoundupsModal
