import {Button} from '@design-system/button'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Page from '~/global/widgets/page/Page'
import {RiskScale} from '~/global/widgets/risk-scale/RiskScale'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {usePlanEditorUrl} from '~/sections/kiwisaver/sections/edit-investment-plan/hooks/usePlanEditorUrl'
import {useExistingKSCustomer, useInvestmentPlanForEditing} from '~/sections/kiwisaver/state'
import {InvestmentPlanIndicatorHelpModal} from '~/sections/kiwisaver/widgets/help-modals/InvestmentPlanRiskIndicatorHelpModal'
import {PlanThumbnailsRow} from '~/sections/kiwisaver/widgets/plan-thumbnails-row/PlanThumbnailsRow'
import styles from './RiskIndication.scss'

export const RiskIndication: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const relativeUrlFor = usePlanEditorUrl()
    const investmentPlan = useInvestmentPlanForEditing()
    const allocations = investmentPlan.allocations
    const selfSelectAllocations = allocations?.filter(a => a.fund_category === 'SELF_SELECT')
    const customer = useExistingKSCustomer()

    // this page should only be reachable once there are self select allocations
    if (!selfSelectAllocations || selfSelectAllocations.length === 0) {
        navigate('..')
    }

    if (!investmentPlan.risk_indicator) {
        throw new Error("Can't view risk indication with investmentPlan.risk_indication not defined")
    }

    const onNext = () => {
        rudderTrack('kiwisaver_self_select', 'risk_indicator_next_clicked', {
            type: customer.customer_state === 'SIGNUP' ? 'signup' : 'edit',
        })
        navigate(relativeUrlFor('pds'))
    }

    return (
        <>
            <Toolbar
                leftButton="back"
                dataTestId="toolbar--risk-indicator"
                title="Your investment plan risk indicator"
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                {allocations && allocations.length > 0 && <PlanThumbnailsRow allocations={allocations} />}

                <RiskScale rating={investmentPlan.risk_indicator} withLabels />

                <p className={spacing.spaceAbove24}>
                    Your current investment plan scores a combined {investmentPlan.risk_indicator} out of 7 on the risk
                    scale. <InvestmentPlanIndicatorHelpModal />
                </p>

                <hr className={styles.divider} />

                <p className={spacing.spaceBelow16}>
                    The risk indicator is rated from 1 (low) to 7 (high). The rating reflects how much the value of your
                    investment portfolio's assets goes up and down. A higher risk generally means higher potential
                    returns over time, but more ups and downs along the way.
                </p>
                <p className={spacing.spaceBelow16}>
                    To help clarify your own attitude to risk, you can seek financial advice or work out your risk
                    profile at{' '}
                    <a
                        href="https://sorted.org.nz/tools/investor-profiler"
                        target="_blank"
                        rel="noopener"
                        data-testid="link--sorted"
                    >
                        sorted.org.nz
                    </a>
                    .
                </p>
                <p className={spacing.spaceBelow16}>
                    Note that even the lowest category does not mean a risk-free investment, and there are other risks
                    that are not captured by this rating.
                </p>
                <p className={spacing.spaceBelow16}>
                    The risk indicator is not a guarantee of your investment portfolio's future performance. The risk
                    indicator is based on historical returns data. While risk indicators are usually relatively stable,
                    they do shift from time to time. The risk indicator will continue to be updated via the Sharesies
                    Platform.
                </p>
            </Page>
            <ActionBar>
                <div className={styles.buttons}>
                    <Button
                        type="secondary"
                        dataTestId="button--edit-plan"
                        label="Edit plan"
                        onClick={() => navigate(-1)}
                        width="auto"
                    />
                    <Button dataTestId="button--next" label="Next" onClick={onNext} width="auto" />
                </div>
            </ActionBar>
        </>
    )
}
