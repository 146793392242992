import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {ExchangeDto} from '~/api/distill/models'
import {DistillScope} from '~/api/query/distill'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import useDistillInstrumentInfo from '~/global/state-hooks/distill/useDistillInstrumentInfo'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {Period} from '~/global/utils/time-period/timePeriod'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import TabControls from '~/global/widgets/tab-controls/TabControls'
import {Toast} from '~/global/widgets/toast/Toast'
import {ExchangeHours} from '~/global/widgets/view-instrument-shared/exchange-hours/ExchangeHours'
import {InstrumentHeader} from '~/global/widgets/view-instrument-shared/instrument-header/InstrumentHeader'
import {useDistillInstrument, useDistillInstrumentBySlug} from '~/sections/kiwisaver/data/distill'
import {
    useExistingKSCustomer,
    useInvestmentPlanOrNullForEditing,
    useKSFundHolding,
    useSetKSInvestmentPlanDraft,
} from '~/sections/kiwisaver/state'
import {LoadingSkeleton} from '~/sections/landing/widgets/loading-skeleton/LoadingSkeleton'
import {useAppSelector} from '~/store/hooks'
import {actingAsID as actingAsIDSelector} from '~/store/identity/selectors'
import {InstrumentDetailsTab} from '~/store/instrument/types'
import {addSingleSelfSelectFundToExistingAllocations, setBaseAllocation} from '../edit-investment-plan/mutate'
import FundDisclosure from './widgets/fund-disclosure/FundDisclosure'
import {OverviewTab} from './widgets/overview-tab/OverviewTab'
import TrioInfo from './widgets/trio-info/TrioInfo'
import YourInvestmentTab from './widgets/your-investment-tab/YourInvestmentTab'
import styles from './ViewFund.scss'
// To be provided on navigation
export type ViewFundType = 'base_fund' | 'self_select_fund'

interface ViewFundProps {
    viewFundMode: 'select' | 'view'
    activeTab: InstrumentDetailsTab
    urlSlug: string
}

export const ViewFund: React.FunctionComponent<ViewFundProps> = ({viewFundMode, activeTab, urlSlug}) => {
    const profileUrl = useProfileUrl()
    const customer = useExistingKSCustomer()
    const instrument = useDistillInstrumentBySlug(urlSlug)
    const instrumentsMetadata = useDistillInstrumentInfo(DistillScope.KIWISAVER_ALL_FUNDS, {
        searchFundInvestments: true,
    })
    const fmsInstrument = useDistillInstrument({
        instrumentId: instrument.fmsFundId!,
        scope: DistillScope.KIWISAVER_ALL_FUNDS,
    })

    const holding = useKSFundHolding(fmsInstrument.id)

    const navigate = useNavigate()

    const [submitting, setSubmitting] = React.useState(false)
    const [period, setPeriod] = React.useState<Period>('1y')

    const exchangeInfo = instrumentsMetadata.exchanges.find(
        (exchange: ExchangeDto) => instrument && instrument.exchange === exchange.name,
    )

    const viewFundType: ViewFundType = tradingType(instrument) === 'managed' ? 'base_fund' : 'self_select_fund'

    // Set the time horizon from the fms fund for base funds
    if (viewFundType === 'base_fund') {
        instrument.timeHorizonMin = fmsInstrument.timeHorizonMin
    }

    const actingAsID = useAppSelector(actingAsIDSelector)
    const currentInvestmentPlan = useInvestmentPlanOrNullForEditing()
    const setInvestmentPlanDraft = useSetKSInvestmentPlanDraft()

    const jurisdiction = 'nz'
    const currency = 'nzd'

    const restrictedAccount = useAppSelector(({identity}) => (identity.user ? identity.user.account_restricted : false))

    const selectBaseFund = async (fmsFundId: string) => {
        let allocations: {
            allocation_percent: string
            fund_category: 'BASE' | 'SELF_SELECT'
            fund_id: string
        }[]

        if (currentInvestmentPlan) {
            allocations = setBaseAllocation(currentInvestmentPlan.allocations, fmsFundId)
        } else {
            allocations = [
                {
                    allocation_percent: '100',
                    fund_category: 'BASE',
                    fund_id: fmsFundId,
                },
            ]
        }

        setSubmitting(true)
        try {
            await setInvestmentPlanDraft.mutateAsync({
                acting_as_id: actingAsID,
                allocations,
            })
            rudderTrack('kiwisaver_signup', 'fund_details_selected', {
                instrument_id: fmsFundId,
                type: customer.customer_state === 'SIGNUP' ? 'signup' : 'edit',
            })
            // If the plan includes more than a single allocation (just a base fund), we will have calculated
            // a new Risk Indication score and will need to present that information to the customer next.
            if (customer.customer_state === 'SIGNUP') {
                if (allocations.length > 1) {
                    navigate(profileUrl('kiwisaver/sign-up/edit-investment-plan/risk-indication'))
                } else {
                    navigate(profileUrl('kiwisaver/sign-up/edit-investment-plan/pds'))
                }
            } else if (customer.customer_state === 'ACTIVE') {
                if (allocations.length > 1) {
                    navigate(profileUrl('kiwisaver/edit-investment-plan/risk-indication'))
                } else {
                    navigate(profileUrl('kiwisaver/edit-investment-plan/pds'))
                }
            } else {
                // Customer is awaiting activation and editing their plan.
                if (allocations.length > 1) {
                    navigate(profileUrl('kiwisaver/pending/edit-investment-plan/risk-indication'))
                } else {
                    navigate(profileUrl('kiwisaver/pending/edit-investment-plan/pds'))
                }
            }
        } catch (e) {
            Toast('Could not choose base plan')
        } finally {
            setSubmitting(false)
        }
    }

    const selectSelfSelectFund = async (fmsFundId: string) => {
        setSubmitting(true)
        try {
            await setInvestmentPlanDraft.mutateAsync({
                acting_as_id: actingAsID,
                allocations: addSingleSelfSelectFundToExistingAllocations(
                    currentInvestmentPlan!.allocations,
                    fmsFundId,
                ),
            })
            if (customer.customer_state === 'SIGNUP') {
                navigate(profileUrl('kiwisaver/sign-up/edit-investment-plan/add-funds'))
            } else {
                // if trying to select and not in signup, we must be in edit
                navigate(profileUrl('kiwisaver/edit-investment-plan/add-funds'))
            }
        } catch (e) {
            Toast('Could not add fund')
        } finally {
            setSubmitting(false)
        }
    }

    const changeTab = (tab: InstrumentDetailsTab) => {
        tab === 'Overview'
            ? navigate(profileUrl('kiwisaver/:urlSlug', {urlSlug}), {replace: true})
            : navigate(profileUrl('kiwisaver/:urlSlug/investment', {urlSlug}), {replace: true})
    }

    return (
        <>
            <Page>
                <div className={cn(styles.disclosureHeader)}>
                    <PageBack />
                    {tradingType(instrument) !== 'managed' && !isInstrumentInNoTrade(instrument) && (
                        <ExchangeHours exchange={exchangeInfo} instrumentTradingStatus={instrument.tradingStatus} />
                    )}
                    <FundDisclosure />
                </div>

                <div className={cn(styles.headerAndNav)}>
                    <InstrumentHeader
                        instrument={instrument}
                        showWatchlistButton={viewFundType === 'self_select_fund'}
                        distillScope={DistillScope.KIWISAVER_ALL_FUNDS}
                    />
                    {viewFundType === 'base_fund' && <TrioInfo instrument={instrument} />}
                </div>
                {viewFundMode === 'view' && (
                    <TabControls
                        tabs={[
                            {label: 'Overview', value: 'Overview'},
                            {label: 'Your investment', value: 'Your investment'},
                        ]}
                        currentTab={activeTab}
                        onChangeTab={changeTab}
                    />
                )}
                <React.Suspense fallback={<LoadingSkeleton />}>
                    {viewFundMode === 'view' && activeTab === 'Your investment' ? (
                        <YourInvestmentTab
                            instrument={instrument}
                            tab={activeTab}
                            currency={currency}
                            period={period}
                            setPeriod={setPeriod}
                        />
                    ) : (
                        <OverviewTab
                            instrument={instrument}
                            jurisdiction={jurisdiction}
                            setHideFooter={undefined}
                            period={period}
                            setPeriod={setPeriod}
                            tab={activeTab}
                        />
                    )}
                </React.Suspense>
            </Page>
            {viewFundMode === 'select' && (
                <ActionBar>
                    <Button
                        dataTestId="button--accept"
                        type="primary"
                        processing={submitting}
                        label="Select this fund"
                        onClick={() => {
                            viewFundType === 'base_fund'
                                ? selectBaseFund(instrument.fmsFundId!)
                                : selectSelfSelectFund(instrument.fmsFundId!)
                        }}
                    />
                </ActionBar>
            )}

            {viewFundMode === 'view' && (
                <ActionBar className={styles.actionBar}>
                    <Button
                        dataTestId="button--sell"
                        additionalClassName={styles.footerButton}
                        width="auto"
                        label="Sell"
                        type="secondary"
                        disabled={
                            restrictedAccount ||
                            isInstrumentInNoTrade(instrument) ||
                            isInstrumentInNoTrade(fmsInstrument) ||
                            Number(holding?.shares) <= 0
                        }
                        onClick={() => {
                            rudderTrack('kiwisaver_account', 'sell_button_clicked', {
                                fund_category: viewFundType,
                                instrument_id: instrument.id,
                            })
                            navigate(profileUrl('kiwisaver/:urlSlug/sell/intro', {urlSlug}))
                        }}
                    />
                </ActionBar>
            )}
        </>
    )
}
