import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {spacing} from '~/global/scss/helpers'
import {usTransferFee} from '~/global/utils/share-transfers/shareTransfers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {useAppSelector} from '~/store/hooks'

const Intro: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const platform = useAppSelector(s => s.transfer.stagedTransferOrder?.usTransferPlatform)
    const direction = useAppSelector(s => s.transfer.stagedTransferOrder?.direction)
    const jurisdiction = useAppSelector(({identity}) => identity.user!.jurisdiction)

    if (!platform || !direction) {
        navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})
    }

    return (
        <>
            <Toolbar leftButton="back" dataTestId="toolbar--us-transfer-intro" />
            <Page>
                <h1 className={cn(spacing.spaceBelow32, commonStyles.heading)}>What you’ll need</h1>
                <p>To submit your transfer request, you’ll need:</p>
                {platform === 'DRS' ? (
                    <ul className={commonStyles.checkboxList}>
                        <li className={commonStyles.checkboxListItem}>
                            if transferring to an existing account, a copy of your transfer agent account statement, (or
                            any document from the platform you’re transferring to that shows your name and your
                            brokerage account number)
                        </li>
                        <li className={commonStyles.checkboxListItem}>the number of shares you want to transfer</li>
                        <li className={commonStyles.checkboxListItem}>
                            enough money (USD) in your Wallet to cover the share transfer fee ($100 USD). You can only
                            transfer one investment within a request.
                            <a
                                className={commonStyles.topupLink}
                                href={profileUrl('wallet')}
                                target="_blank"
                                rel="noopener"
                            >
                                {' '}
                                Top up or exchange money
                            </a>
                        </li>
                    </ul>
                ) : (
                    <ul className={commonStyles.checkboxList}>
                        <li className={commonStyles.checkboxListItem}>
                            a copy of your brokerage account statement (or any document that shows your name, your
                            brokerage account number, and a summary of your shareholding)
                        </li>
                        {jurisdiction === 'au' && (
                            <>
                                <li className={commonStyles.checkboxListItem}>the date(s) you acquired your shares</li>
                                <li className={commonStyles.checkboxListItem}>the price you paid per share</li>
                            </>
                        )}
                        <li className={commonStyles.checkboxListItem}>the number of shares you want to transfer</li>
                        <li className={commonStyles.checkboxListItem}>
                            {platform === 'EXTERNAL'
                                ? 'enough money (USD) in your Sharesies Wallet to cover the share transfer fee ($50 USD per investment you’re transferring).'
                                : `$${usTransferFee(
                                      direction,
                                      platform,
                                  )} USD in your Sharesies Wallet to cover the share transfer fee.`}
                            <a
                                className={commonStyles.topupLink}
                                href={profileUrl('wallet')}
                                target="_blank"
                                rel="noopener"
                            >
                                {' '}
                                Top up or exchange money
                            </a>
                        </li>
                        {direction === 'in' && platform === 'INTERNAL' && (
                            <li>
                                $65 USD in the wallet of the brokerage account you are transferring the shares from to
                                cover their transfer fee
                            </li>
                        )}
                    </ul>
                )}
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--next"
                    label="Next"
                    onClick={() => navigate(profileUrl('invest/portfolio-transfer-shares/us/terms-and-conditions'))}
                />
            </ActionBar>
        </>
    )
}

export default Intro
