import {Button} from '@design-system/button'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {dateFormatNoDay} from '~/global/utils/format-date/formatDate'
import {getInvestingActivityData} from '~/global/utils/investing-activity/investingActivity'
import {
    showCalendarMonthHeading,
    showThisMonthHeading,
    showThisWeekHeading,
} from '~/global/utils/time-unit-headings/timeUnitHeadings'
import {useInstruments} from '~/global/utils/use-instruments/useInstruments'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Loading} from '~/global/widgets/loading/Loading'
import TransactionRow from '~/global/widgets/transaction-row/TransactionRow'
import {Link} from '~/migrate-react-router'
import portfolioStyles from '~/sections/invest/sections/portfolio/Portfolio.scss'
import accountingActions from '~/store/accounting/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import styles from './ActivityFeed.scss'

export const PastActivity: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const pastInvestingActivityCurrentPage = useAppSelector(s => s.accounting.pastInvestingActivityCurrentPage)
    const pastInvestingActivityFetchingMore = useAppSelector(s => s.accounting.pastInvestingActivityFetchingMore)
    const pastInvestingActivityHasMore = useAppSelector(s => s.accounting.pastInvestingActivityHasMore)
    const pastInvestingActivityLoadingState = useAppSelector(s => s.accounting.pastInvestingActivityLoadingState)
    const pastInvestingActivityRecords = useAppSelector(s => s.accounting.pastInvestingActivityRecords)

    // Ensure that all instruments are loaded
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [instrumentsById, _allInstrumentsLoaded] = useInstruments([
        ...new Set(pastInvestingActivityRecords.map(record => record.fund_id)),
    ])

    // For: displaying time unit headings in the Activity feed. Both let-variables are used in the loop
    const currentTimestamp = DateTime.local()
    let previousRecordTimestamp = DateTime.local()
    let monthTitleDisplayed = false

    React.useEffect(() => {
        dispatch(accountingActions.FetchPastInvestingActivity(pastInvestingActivityCurrentPage))
    }, [])

    const handleShowMoreClick = () => {
        rudderTrack('activity_feed', 'activity_feed_show_more_selected')
        dispatch(accountingActions.FetchPastInvestingActivity(pastInvestingActivityCurrentPage + 1))
    }

    // Loading
    if (pastInvestingActivityRecords.length === 0 && pastInvestingActivityLoadingState === 'loading') {
        return (
            <div className={styles.emptySection}>
                <Loading />
            </div>
        )
    }

    // No past activity
    if (pastInvestingActivityRecords.length === 0 && pastInvestingActivityLoadingState === 'ready') {
        return (
            <div className={styles.emptySection}>
                <p className={styles.pastActivityWrapper}>
                    You’ll see past changes to your investments like buys and sells here.{' '}
                    <Link to={profileUrl('wallet')}>Your Wallet</Link> is where you’ll see top ups to and withdrawals
                    from your Sharesies account.
                </p>
            </div>
        )
    }

    return (
        <div className={styles.pastActivityWrapper}>
            <h2 className={cn(spacing.spaceBelow16, typographyOverrides['as-h3'])}>Past activity</h2>
            {pastInvestingActivityRecords.map((record, index) => {
                const instrument = instrumentsById[record.fund_id]

                if (!instrument) {
                    /*
                    We may have investing activity records that involve rights offers
                    instruments. These instruments cannot currently be retrieved easily
                    from Distill, so for the MVP Activity Feed, we will omit these records.
                    */
                    return
                } else {
                    const investingActivityData = getInvestingActivityData(record, instrument, profileUrl)
                    const recordComponent = (
                        <TransactionRow
                            key={`past-investing-activity--${index}`}
                            amount={investingActivityData.amount}
                            currency={instrument.currency}
                            date={investingActivityData.displayDate}
                            description={investingActivityData.title}
                            icon={investingActivityData.icon}
                            title={instrument.name}
                            url={investingActivityData.url}
                        />
                    )

                    // 'Today' heading
                    if (index === 0 && investingActivityData.displayDate.toISODate() === currentTimestamp.toISODate()) {
                        return (
                            <div key="past-investing-activity--today">
                                <p className={portfolioStyles.timeUnitHeading}>Today</p>
                                {recordComponent}
                            </div>
                        )
                    }

                    // 'This week' heading
                    if (showThisWeekHeading(investingActivityData.displayDate, previousRecordTimestamp)) {
                        previousRecordTimestamp = investingActivityData.displayDate

                        return (
                            <div key="past-investing-activity--this-week">
                                <p className={portfolioStyles.timeUnitHeading}>This week</p>
                                {recordComponent}
                            </div>
                        )
                    }

                    // 'This month' heading
                    if (
                        showThisMonthHeading(
                            investingActivityData.displayDate,
                            previousRecordTimestamp,
                            monthTitleDisplayed,
                        )
                    ) {
                        monthTitleDisplayed = true
                        previousRecordTimestamp = investingActivityData.displayDate

                        return (
                            <div key="past-investing-activity--this-month">
                                <p className={portfolioStyles.timeUnitHeading}>This month</p>
                                {recordComponent}
                            </div>
                        )
                    }

                    // Month headings (e.g. 'July 2022')
                    if (showCalendarMonthHeading(investingActivityData.displayDate, previousRecordTimestamp)) {
                        monthTitleDisplayed = true
                        previousRecordTimestamp = investingActivityData.displayDate

                        return (
                            <div
                                key={`past-investing-activity--${investingActivityData.displayDate.year}-${investingActivityData.displayDate.month}`}
                            >
                                <p className={portfolioStyles.timeUnitHeading}>
                                    {investingActivityData.displayDate.toFormat(dateFormatNoDay)}
                                </p>
                                {recordComponent}
                            </div>
                        )
                    }

                    previousRecordTimestamp = investingActivityData.displayDate

                    return recordComponent
                }
            })}
            {pastInvestingActivityHasMore && (
                <Button
                    dataTestId="button--load-older-investing-activity"
                    label="Load older investing activity"
                    onClick={handleShowMoreClick}
                    processing={pastInvestingActivityFetchingMore}
                    additionalClassName={spacing.spaceAbove24}
                />
            )}
        </div>
    )
}
