import React from 'react'
import {useCoveGet} from '~/api/query/cove'
import {
    findExcess,
    hasRoadSideAssistance,
    policyFrequency,
    vehicleDisplayName,
} from '~/sections/protect/sections/insure/sections/landing/utils/car/car'
import {NoPolicies} from '~/sections/protect/sections/insure/sections/landing/widgets/no-policies/NoPolicies'
import {CancelledPolicy, Policy} from '~/sections/protect/sections/insure/sections/landing/widgets/policy/Policy'

export const Policies: React.FunctionComponent = () => {
    const customerData = useCoveGet('customer/v1/retrieve')
    const policyData = useCoveGet('coverage/v1/query')
    const quoteData = useCoveGet('quote/v1/queryByCustomer')

    /** 'Pending' quotes are future-dated policies - we want to show these here, as policies rather than quotes */
    const futureDatedPolicies = quoteData.quotes.filter(quote => quote.quote_status === 'Pending')
    const activePolicies = policyData.coverages.filter(policy => policy.status !== 'Cancelled')
    const cancelledPolicies = policyData.coverages.filter(policy => policy.status === 'Cancelled')

    // The payment method is at a customer level. Cove only allows one payment method per
    // account, so the same one is used for all payments.
    const creditCard = customerData.customer.payment_method?.credit_card

    // This string is in the format xxxx-xxxx-xxxx-1234 - it's not the actual credit card
    const creditCardEnding = creditCard?.card_number.slice(-4)

    if (futureDatedPolicies.length === 0 && activePolicies.length === 0 && cancelledPolicies.length === 0) {
        return <NoPolicies />
    }

    return (
        <>
            {futureDatedPolicies.map(futureDatedPolicy => {
                const futurePolicyDetails = futureDatedPolicy.coverages[0]

                return (
                    <React.Fragment key={futureDatedPolicy.quote_id}>
                        <Policy
                            cardEnding={creditCardEnding}
                            carValue={futurePolicyDetails.risk.sum_insured.current_value.toString()}
                            code={futureDatedPolicy.quote_id}
                            coverage={futurePolicyDetails.risk.coverage_type}
                            effectiveFromDate={new Date(futurePolicyDetails.start_date)}
                            excess={findExcess(futurePolicyDetails.excesses)}
                            hasRoadSideAssistance={false} // Not relevant to show the roadside assistance phone # when the policy isn't active yet
                            name={vehicleDisplayName(futurePolicyDetails.risk)}
                            premiumCost={futurePolicyDetails.premium.toString()}
                            premiumFrequency={policyFrequency(futureDatedPolicy.terms_duration)}
                            state={futureDatedPolicy.quote_status}
                        />
                        <br />
                    </React.Fragment>
                )
            })}

            {activePolicies.map(policy => {
                return (
                    <React.Fragment key={policy.coverage_id}>
                        <Policy
                            cardEnding={creditCardEnding}
                            carValue={policy.risk.sum_insured.current_value.toString()}
                            code={policy.coverage_id}
                            coverage={policy.coverage_type}
                            effectiveFromDate={new Date(policy.start_date)}
                            excess={findExcess(policy.excesses)}
                            hasRoadSideAssistance={hasRoadSideAssistance(policy.coverages)}
                            name={vehicleDisplayName(policy.risk)}
                            premiumCost={policy.premium.toString()}
                            premiumFrequency={policyFrequency(policy.terms_duration)}
                            renewalDate={new Date(policy.expiry_date)}
                            state={policy.status}
                        />
                        <br />
                    </React.Fragment>
                )
            })}

            {cancelledPolicies.map(cancelledPolicy => {
                return (
                    <React.Fragment key={cancelledPolicy.coverage_id}>
                        <CancelledPolicy
                            code={cancelledPolicy.coverage_id}
                            name={vehicleDisplayName(cancelledPolicy.risk)}
                            premiumCost={cancelledPolicy.premium.toString()}
                            premiumFrequency={policyFrequency(cancelledPolicy.terms_duration)}
                        />
                        <br />
                    </React.Fragment>
                )
            })}
        </>
    )
}
