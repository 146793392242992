import cn from 'classnames'
import React from 'react'
import {areEqual} from 'react-window'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import InstrumentRow from '~/global/widgets/instrument-row/InstrumentRow'
import {SkeletonInstrumentRow} from '~/global/widgets/instrument-row/SkeletonInstrumentRow'
import {Loading} from '~/global/widgets/loading/Loading'
import Header from '~/sections/invest/sections/search/widgets/header/Header'
import noSearchResults from '~/sections/invest/sections/search/widgets/search-results/assets/images/no-search-results.svg'
import searchResultsSharesies from '~/sections/invest/sections/search/widgets/search-results/assets/images/search-results-sharesies.svg'
import {State} from '~/store/instrument/types'
import styles from './SearchResults.scss'

export interface SearchResultRowData {
    results: State['resultsIndex']
    instrumentsById: State['instrumentsById']
    currentQuery: State['currentSearchQuery']
    currentTimePeriod: State['currentSearchTimePeriod']
    currentSort: string
    isItemLoaded(index: number): boolean
    resultsLoadingState: State['resultsLoadingState']
    measuredWidth?: number
    instrumentInitialised: boolean
    onSearchResultItemClick(itemIndex: number, instrumentId: string): void
    isAutoInvest?: boolean
    autoInvestButtonRef?: React.RefObject<HTMLButtonElement>
}

const SearchResultsRow = React.memo(
    ({index, style, data}: {index: number; style: React.CSSProperties; data: SearchResultRowData}) => {
        const {
            instrumentsById,
            results,
            currentTimePeriod,
            currentSort,
            currentQuery,
            isItemLoaded,
            onSearchResultItemClick,
            resultsLoadingState,
            measuredWidth,
            instrumentInitialised,
            isAutoInvest,
            autoInvestButtonRef,
        } = data
        if (!isItemLoaded(index)) {
            return (
                <div
                    className={cn(styles.instrumentRowWrapper, styles.nextPageLoader)}
                    style={style}
                    key="loading-spinner"
                >
                    <Loading />
                </div>
            )
        }

        if (index === 0) {
            /*
            There are two Header components on this page
            1. Header that is considered as the first item in the List component
              - so the user can scroll the entire page but not just the search results

            2. Header that slides up and down on top of the page interactively depends on the user's scroll direction

            This is the 1. Header
            */
            return <Header isNestedInResultsRow isAutoInvest={isAutoInvest} isOnTop={true} />
        }

        if ((resultsLoadingState === 'loading' || !instrumentInitialised) && measuredWidth) {
            return (
                <div
                    className={cn(styles.instrumentRowWrapper, styles.skeletonInstrumentRowWrapper)}
                    style={style}
                    key={`placeholder-${index - 1}`}
                >
                    <SkeletonInstrumentRow measuredWidth={measuredWidth} />
                </div>
            )
        }

        if (resultsLoadingState === 'ready' && results.length === 0) {
            return currentQuery.toLowerCase().trim() === 'sharesies' ? (
                <div className={styles.noResult}>
                    <p>
                        Soz, Sharesies isn’t listed <span className={styles.lineBreakPoint}>on the market</span>
                    </p>
                    <img src={searchResultsSharesies} alt="Search results Sharesies" />
                    <small>But lovely to see you here.</small>
                </div>
            ) : (
                <div className={styles.noResult}>
                    {isAutoInvest ? (
                        <p>
                            {' '}
                            {currentQuery.trim().length > 0 && (
                                <span className={styles.searchTerm}>{`"${currentQuery}"`}</span>
                            )}{' '}
                            isn't available for DIY auto-invest
                        </p>
                    ) : (
                        <p>
                            Stink, we can’t find anything{' '}
                            {currentQuery.trim().length > 0 && (
                                <span>
                                    for <br />
                                    <span className={styles.searchTerm}>{`"${currentQuery}"`}</span>
                                </span>
                            )}
                        </p>
                    )}
                    <img src={noSearchResults} alt="no search results" />

                    {isAutoInvest && (
                        <small>
                            Not all investments on Sharesies are available for DIY auto-invest—read more in our{' '}
                            <HelpCentreLink
                                auArticle="5815752-create-an-auto-invest-order"
                                nzArticle="2966240-create-an-auto-invest-order"
                            />
                            .
                        </small>
                    )}

                    {!isAutoInvest && (
                        <small>
                            {currentQuery.trim().length > 0 ? <span>Keep typing or t</span> : <span>T</span>}
                            ry searching for something else.
                        </small>
                    )}
                </div>
            )
        }

        if (resultsLoadingState === 'ready' && results.length > 0) {
            const instrumentId = results[index - 1]
            return (
                <div
                    data-testid="search-result"
                    className={styles.instrumentRowWrapper}
                    style={style}
                    key={`result-${index - 1}`}
                >
                    <InstrumentRow
                        instrument={instrumentsById[instrumentId]}
                        currentTimePeriod={currentTimePeriod}
                        currentSort={currentSort}
                        measuredWidth={measuredWidth}
                        showWatchlistButton={true}
                        onClick={() => {
                            onSearchResultItemClick(index, instrumentId)
                        }}
                        isAutoInvest={isAutoInvest}
                        autoInvestButtonRef={autoInvestButtonRef}
                    />
                </div>
            )
        }

        return <></>
    },
    areEqual,
)

export default SearchResultsRow
