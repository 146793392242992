import {Settings} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {accessibility} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useNavigate} from '~/migrate-react-router'
import styles from './NotificationSettingsButton.scss'

export const NotificationSettingsButton: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    return (
        <button
            type="button"
            data-testid="toolbar--notifications-settings-button"
            className={cn(styles.notificationSettings, accessibility.button)}
            onClick={() => {
                navigate(profileUrl('settings/notifications'))
            }}
            title="Notification settings"
        >
            <Settings />
        </button>
    )
}
