import GA4React from 'ga-4-react'
import {Model} from '~/api/retail/types'
import {rudderPageView} from '~/api/rudderstack/rudderstack'
import config from '~/configForEnv'

const ga4react = new GA4React(config.GA4Token, {debug_mode: !location.host.includes('app.sharesies')})
let ga4initialised = false

export default {
    pageNav: (path: string) => {
        if (ga4initialised) {
            ga4react.pageview(path)
        }

        rudderPageView()
    },
    updatePageview: (path: string) => {
        // only used for specific within-page updates, e.g. query string updates for search
        if (ga4initialised) {
            ga4react.pageview(path)
        }

        rudderPageView()
    },
    event: (eventObject: {action: string; label: string; category: string; nonInteraction?: boolean}) => {
        if (ga4initialised) {
            ga4react.event(eventObject.action, eventObject.label, eventObject.category, !!eventObject.nonInteraction)
        }
    },
    /**
     * `customEvent` can be used to send an additional `value` to GA
     */
    customEvent: (eventObject: {action: string; label: string; category: string; value?: number}) => {
        if (ga4initialised) {
            // Matches the payload ga4react.event sends
            ga4react.gtag('event', eventObject.action, {
                event_category: eventObject.category,
                event_label: eventObject.label,
                event_value: eventObject.value,
            })
        }
    },
    setUserId: (gaId: string | null) => {
        if (ga4initialised) {
            ga4react.gtag('config', config.GA4Token, {
                user_id: gaId,
            })
        } else {
            // try again after a small timeout - because the init may not have finished the first time
            setTimeout(() => {
                if (ga4initialised) {
                    ga4react.gtag('config', config.GA4Token, {
                        user_id: gaId,
                    })
                }
            }, 500)
        }
    },
    giftsFloodlightEvent: (jurisdiction: Model.User['jurisdiction']) => {
        // Google floodlight event that goes to our marketing firm
        if (ga4initialised && location.host.includes('app.sharesies')) {
            // NZ
            if (jurisdiction === 'nz') {
                ga4react.gtag('event', 'conversion', {
                    allow_custom_scripts: false,
                    send_to: 'DC-9748890/conve0/share00+standard',
                })
            }
            // AU
            if (jurisdiction === 'au') {
                ga4react.gtag('event', 'conversion', {
                    allow_custom_scripts: false,
                    send_to: 'DC-10744377/conve0/share0+standard',
                })
            }
        }
    },
    signUpFloodlightEvent: (jurisdiction: Model.User['jurisdiction']) => {
        // Google floodlight events that go to our marketing firm
        if (ga4initialised && location.host.includes('app.sharesies')) {
            // NZ
            if (jurisdiction === 'nz') {
                ga4react.gtag('event', 'conversion', {
                    allow_custom_scripts: false,
                    send_to: 'DC-9748890/conve0/share000+standard',
                })
            }
            // AU
            if (jurisdiction === 'au') {
                ga4react.gtag('event', 'conversion', {
                    allow_custom_scripts: false,
                    send_to: 'DC-10744377/conve0/share0+standard',
                })
                ga4react.gtag('event', 'conversion', {
                    allow_custom_scripts: false,
                    send_to: 'AW-380794457/1B4ACOn85b4CENnsybUB', // youtube event for AU
                })

                // Floodlight event for Boho in AU
                ga4react.gtag('event', 'conversion', {
                    allow_custom_scripts: false,
                    send_to: 'DC-12257542/ausharcs/share0+standard',
                })
            }
        }
    },
    kiwisaverSignUpFloodlightEvent: () => {
        if (ga4initialised && location.host.includes('app.sharesies')) {
            ga4react.gtag('event', 'conversion', {
                allow_custom_scripts: false,
                send_to: 'DC-9748890/conve0/share001+standard',
            })
        }
    },
    init: async () => {
        // ga4 init
        try {
            await ga4react.initialize()
            if (typeof window.gtag === 'function') {
                ga4initialised = true
            }
        } catch (_) {
            // fail silently - probably adblocker
        }
    },
}
