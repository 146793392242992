/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface HistoricVwapResultModel
 */
export interface HistoricVwapResultModel {
    /**
     *
     * @type {string}
     * @memberof HistoricVwapResultModel
     */
    readonly instrumentId: string
    /**
     *
     * @type {Date}
     * @memberof HistoricVwapResultModel
     */
    readonly firstDate: DateTime
    /**
     *
     * @type {Date}
     * @memberof HistoricVwapResultModel
     */
    readonly lastDate: DateTime
    /**
     *
     * @type {string}
     * @memberof HistoricVwapResultModel
     */
    readonly vwapPrice: string
    /**
     *
     * @type {string}
     * @memberof HistoricVwapResultModel
     */
    readonly totalValue: string
    /**
     *
     * @type {number}
     * @memberof HistoricVwapResultModel
     */
    readonly totalVolume: number
}

export function HistoricVwapResultModelFromJSON(json: any): HistoricVwapResultModel {
    return HistoricVwapResultModelFromJSONTyped(json, false)
}

export function HistoricVwapResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoricVwapResultModel {
    if (json === undefined || json === null) {
        return json
    }
    return {
        instrumentId: json['instrumentId'],
        firstDate: DateTime.fromISO(json['firstDate'], {setZone: true}),
        lastDate: DateTime.fromISO(json['lastDate'], {setZone: true}),
        vwapPrice: json['vwapPrice'],
        totalValue: json['totalValue'],
        totalVolume: json['totalVolume'],
    }
}

export function HistoricVwapResultModelToJSON(value?: HistoricVwapResultModel | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {}
}
