import {Model} from '~/api/retail/types'

/**
 * Humanises a bank account number for display, splitting it into groups of digits for NZ accounts and removing and non-digits for AU accounts.
 *
 * @param {string} accountNumber - The bank account number to humanise
 * @param {Model.User['jurisdiction']} jurisdiction - The jurisdiction of the bank account (AU or NZ)
 * @param {string} [separator] - The separator to use between the parts of the bank account number . Defaults to a space.
 * @returns {string} - The humanised bank account number
 */
export const humaniseBankAccount = (
    accountNumber: string,
    jurisdiction: Model.User['jurisdiction'],
    separator?: string,
): string => {
    if (jurisdiction === 'au') {
        return accountNumber.replace(/\D/g, '')
    }
    const s = separator ?? ' '
    return accountNumber.replace(/^(\d{2})(\d{4})(\d{7})(\d{2,3})$/, `$1${s}$2${s}$3${s}$4`)
}

export const humaniseBankAccountWithHyphens = (
    accountNumber: string,
    jurisdiction: Model.User['jurisdiction'],
): string => {
    return humaniseBankAccount(accountNumber, jurisdiction, '-')
}
