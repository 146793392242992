import {Button} from '@design-system/button'
import {withFormik} from 'formik'
import React from 'react'
import {Response} from '~/api/retail/types'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {validate} from '~/global/widgets/form-controls'
import {Email} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'

interface FormValues {
    newEmail: string
}

export const UpdateEmail: React.FunctionComponent<UpdateEmailProps> = props => {
    return (
        <>
            <Toolbar dataTestId="toolbar--change-email" leftButton="back" title="Update your email address" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <ChangeEmailForm {...props} />
            </Page>
        </>
    )
}

const ChangeEmailForm = withFormik<UpdateEmailProps, FormValues>({
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        newEmail: undefined,
    }),
    handleSubmit: async (
        {newEmail},
        {setSubmitting, setStatus, resetForm, props: {UpdateEmail, setShowEmailUpdate}},
    ) => {
        try {
            const error = await UpdateEmail(newEmail)

            if (error) {
                setStatus(error)
                setSubmitting(false)

                return
            }
            Toast('Email updated successfully')
            resetForm()
            setShowEmailUpdate(false)
        } catch (e) {
            setStatus(unknownErrorMessage)
            setSubmitting(false)
            throw e
        }
    },
    validate: validate.generate<FormValues>({
        newEmail: [validate.required(), validate.email()],
    }),
})(({handleSubmit, isValid}) => (
    <>
        <form onSubmit={handleSubmit}>
            <Email dataTestId="email--new" label="Email" name="newEmail" autoFocus />
            <Button dataTestId="button--change-email" label="Update email" disabled={!isValid} isSubmit pageButton />
        </form>
    </>
))

type UpdateEmailProps = OwnProps & StoreProps & DispatchProps

interface OwnProps {
    setShowEmailUpdate: React.Dispatch<React.SetStateAction<boolean>>
}

interface StoreProps {
    preferredName: string
    phone: string
}

interface DispatchProps {
    UpdateEmail(email: string): Promise<Response.Error | undefined>
}

export default connect<StoreProps, DispatchProps, OwnProps>(
    state => {
        const {
            identity: {user},
        } = state

        return {
            phone: user!.phone || '',
            preferredName: user!.preferred_name,
        }
    },
    dispatch => ({
        UpdateEmail: (email: string) => dispatch(actions.UpdateEmail(email)),
    }),
)(UpdateEmail)
