import {ModalLink} from '@design-system/modal'
import React from 'react'

export const BuyAndSellFeesForFcgInstrument: React.FunctionComponent<{}> = () => {
    return (
        <>
            Transaction fee{' '}
            <ModalLink
                dataTestId="modal-link--free-transactions-for-fcg"
                label="Free transactions for FCG"
                modalTitle="Free transactions for FCG"
                primaryButton={{label: 'Ok'}}
                bottomBorder
                asIcon
                helpIconSize={16}
            >
                <p>Fonterra covers any transaction fees incurred when trading FCG shares.</p>
            </ModalLink>
        </>
    )
}
