import {Button} from '@design-system/button'
import {SelectCards} from '@design-system/select-cards'
import cn from 'classnames'
import React, {FunctionComponent, useState} from 'react'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {useAppDispatch} from '~/store/hooks'
import transferActions from '~/store/transfer/actions'

const DoYouHaveACsnPage: FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const dispatch = useAppDispatch()
    const [hasCsn, setHasCsn] = useState<boolean | undefined>()
    const [isSaving, setIsSaving] = useState(false)

    return (
        <>
            <Toolbar dataTestId="toolbar--transfer-shares-do-you-have-a-csn" leftButton="back" />
            <Page>
                <h1 className={cn(spacing.spaceBelow24, commonStyles.heading)}>Do you have a CSN?</h1>
                <p className={spacing.spaceBelow32}>
                    A CSN is usually 9 characters long, and usually starts with the number 3.
                </p>
                <div>
                    <SelectCards
                        dataTestId="select-cards--do-you-have-a-csn"
                        title="Do you have a CSN?"
                        hideTitle
                        options={[
                            {
                                title: 'Yes, I have a CSN',
                                supportingText: `You'll need to add it here along with your FIN`,
                                name: 'has-csn',
                                value: 'true',
                            },
                            {
                                title: `No, I don't have a CSN`,
                                supportingText: `We can generate a CSN for you`,
                                name: 'has-csn',
                                value: 'false',
                            },
                        ]}
                        onChange={e => {
                            setHasCsn(e.target.value === 'true')
                        }}
                    />
                </div>
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--next"
                    onClick={async () => {
                        if (hasCsn) {
                            navigate(profileUrl('invest/portfolio-transfer-shares/nzx/add-csn'))
                        } else {
                            setIsSaving(true)
                            try {
                                await dispatch(
                                    transferActions.CreateRegistryDetails({
                                        referenceType: 'REQUEST_CSN',
                                        referenceId: '',
                                    }),
                                )
                                navigate(
                                    profileUrl('invest/portfolio-transfer-shares/:exchange/select', {exchange: 'nzx'}),
                                )
                            } finally {
                                setIsSaving(false)
                            }
                        }
                    }}
                    label="Add details"
                    disabled={hasCsn === undefined}
                    processing={isSaving}
                />
            </ActionBar>
        </>
    )
}

export default DoYouHaveACsnPage
