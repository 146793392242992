import React from 'react'
import {useNavigate} from 'react-router'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import TabControls from '~/global/widgets/tab-controls/TabControls'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {AccountActivitySummary} from '~/sections/kiwisaver/sections/activity/pages/account-activity-summary/AccountActivitySummary'
import CashMovementsActivity from '~/sections/kiwisaver/sections/activity/pages/activity/widgets/cash-movements-activity/CashMovementsActivity'
import InvestingActivity from '~/sections/kiwisaver/sections/view-fund/widgets/your-investment-tab/investing-activity/InvestingActivity'
import {PageTitle} from '~/sections/kiwisaver/widgets/page-title/PageTitle'
export type KSActivityTab = 'transactions' | 'investing-activity'

export const Activity: React.FunctionComponent<{activeTab: KSActivityTab}> = ({activeTab}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const changeTab = (tab: KSActivityTab) => {
        tab === 'transactions'
            ? navigate(profileUrl('kiwisaver/activity'), {replace: true})
            : navigate(profileUrl('kiwisaver/investing-activity'), {replace: true})
    }

    return (
        <>
            <Toolbar leftButton="back" dataTestId="toolbar--activity" />
            <Page>
                <PageTitle>Activity</PageTitle>
                <AccountActivitySummary />
                <TabControls
                    tabs={[
                        {label: 'Transactions', value: 'transactions'},
                        {label: 'Investing activity', value: 'investing-activity'},
                    ]}
                    currentTab={activeTab}
                    onChangeTab={changeTab}
                />
                {activeTab === 'transactions' ? <CashMovementsActivity /> : <InvestingActivity />}
            </Page>
        </>
    )
}
