import {Button} from '@design-system/button'
import {Pause, Success} from '@design-system/icon'
import {Modal} from '@design-system/modal'
import {useFeature} from '@growthbook/growthbook-react'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {formatDollar} from '~/global/utils/format-dollar/formatDollar'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AccountVerificationRequired from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Feedback from '~/global/widgets/feedback/Feedback'
import InstrumentRow from '~/global/widgets/instrument-row/InstrumentRow'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Link, useNavigate} from '~/migrate-react-router'
import styles from '~/sections/invest/sections/auto-invest/Common.scss'
import {usePremadeOrder} from '~/sections/invest/sections/auto-invest/hooks/usePremadeOrders'
import {OrderFailedFullAlert} from '~/sections/invest/sections/auto-invest/widgets/auto-invest-alerts/AutoInvestAlerts'
import EditControl from '~/sections/invest/sections/auto-invest/widgets/edit-control/EditControl'
import AutoInvestHeader from '~/sections/invest/sections/auto-invest/widgets/header/AutoInvestHeader'
import actions from '~/store/autoinvest/actions'
import {
    PremadeOrderEnhanced,
    EnhancedAutoinvestOrderPremade,
    selectEnhancedAutoinvestOrder,
    selectPremadeOrder,
} from '~/store/autoinvest/selectors'
import {AutoinvestOrderType} from '~/store/autoinvest/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import instrumentActions from '~/store/instrument/actions'

interface PremadeProps {
    premadeSlug: string
}

const Premade: React.FunctionComponent<PremadeProps> = ({premadeSlug}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const oneOffAutoinvestEnabled = useFeature('one-off-autoinvest').on

    const [showSwitchModal, setShowSwitchModal] = React.useState<boolean>(false)

    const accountRestricted = useAppSelector(s => s.identity.user!.account_restricted)
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const instrumentLoadingState = useAppSelector(s => s.instrument.premadeAILoadingState)
    const [currentPremadeOrder] = usePremadeOrder(premadeSlug)
    // Get any existing premade order for customer
    const customerExistingPremadeOrder: EnhancedAutoinvestOrderPremade | undefined = useAppSelector(s => {
        const order = selectPremadeOrder(s)
        const enhancedOrder = selectEnhancedAutoinvestOrder(s, order)
        return enhancedOrder as EnhancedAutoinvestOrderPremade | undefined
    })

    // Customer has an existing premade order of this premade type
    const displayCustomerAutoinvest =
        customerExistingPremadeOrder && currentPremadeOrder?.id === customerExistingPremadeOrder?.premade_order_id

    const orderIds = currentPremadeOrder ? currentPremadeOrder.allocations.map(order => order.fund_id) : []

    React.useEffect(() => {
        if (orderIds.length > 0) {
            // Ensure we have details for all the funds in the order
            dispatch(instrumentActions.loadPremadeAIInstruments(orderIds, premadeSlug))
        }
    }, [orderIds.length])

    if (!currentPremadeOrder || instrumentLoadingState === 'loading') {
        return <Loading isPineapple />
    }

    const handleRowClick = (instrumentSlug: string, instrumentId: string, orderSlug: string) => {
        dispatch(identityActions.SetSearchedFund(instrumentId))
        navigate(
            profileUrl('auto-invest/:premadeSlug/:instrumentSlug', {
                premadeSlug: orderSlug,
                instrumentSlug,
            }),
        )
    }

    const handleFooterClick = (order: PremadeOrderEnhanced, premadeSlug: string) => {
        if (customerExistingPremadeOrder) {
            setShowSwitchModal(true)
        } else {
            dispatch(actions.StageNewPremade(order))
            navigate(profileUrl('auto-invest/:premadeSlug/how-much', {premadeSlug}), {replace: true})
        }
    }

    const handlePremadeSwitchConfirmed = () => {
        dispatch(actions.StageNewPremade(currentPremadeOrder, customerExistingPremadeOrder?.id))
        navigate(profileUrl('auto-invest/:premadeSlug/how-much', {premadeSlug}), {replace: true})
    }

    // The Kids order is made up of medium and higher risk funds.
    const medium_risk_copy = currentPremadeOrder.name === 'Kids' ? 'medium and ' : ''

    const onPageBack = () => {
        navigate(profileUrl('auto-invest'))
    }

    const currentInvestInfo = displayCustomerAutoinvest
        ? `${formatDollar(customerExistingPremadeOrder.amount, false)} every ${
              customerExistingPremadeOrder.displayInterval
          }, next:
                            ${DateTime.fromISO(customerExistingPremadeOrder.next_date).toFormat(dateFormatNoTime)}`
        : undefined

    return (
        <>
            <Page withoutDefaultPadding>
                <AutoInvestHeader
                    orderType={currentPremadeOrder.name}
                    onPageBack={onPageBack}
                    order={currentPremadeOrder}
                    name={currentPremadeOrder.name}
                    currentInvestInfo={currentInvestInfo}
                />
                <div className={styles.pageContainer}>
                    {displayCustomerAutoinvest && customerExistingPremadeOrder.last_failed_date && (
                        <OrderFailedFullAlert order={customerExistingPremadeOrder} />
                    )}
                    <AccountVerificationRequired />
                    <p className={cn(spacing.spaceAbove8, styles.description)}>{currentPremadeOrder.description}</p>
                    <div className={spacing.spaceBelow32}>
                        <div className={cn([spacing.spaceBelow12, styles.subTitleandStatus])}>
                            <h2>What’s in the order</h2>
                            {displayCustomerAutoinvest && (
                                <div className={styles.status}>
                                    {customerExistingPremadeOrder.state === 'paused' ? (
                                        <>
                                            <span>Paused</span>
                                            <div className={styles.paused}>
                                                <Pause />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <span>Active</span>
                                            <div className={styles.active}>
                                                <Success />
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                        {currentPremadeOrder.allocations.map(a => {
                            if (instrumentsById[a.fund_id]) {
                                return (
                                    <div className={styles.instrumentRowWrapper} key={`premade-${a.fund_id}`}>
                                        <InstrumentRow
                                            instrument={instrumentsById[a.fund_id]}
                                            onClick={() => {
                                                handleRowClick(a.instrumentSlug, a.fund_id, currentPremadeOrder.slug)
                                            }}
                                            allocation={a.allocation}
                                            oldLayout
                                        />
                                    </div>
                                )
                            }
                        })}
                    </div>
                    <h2 className={styles.subHeader}>Risk level</h2>
                    <p className={spacing.spaceBelow32}>
                        This order is made up of {medium_risk_copy} higher risk investments. Learn more about{' '}
                        <Link
                            to={profileUrl('explore/learn/:slug', {
                                slug: 'investment-risk-levels',
                            })}
                        >
                            investment risk levels
                        </Link>
                        .
                    </p>
                    <div className={styles.divider}></div>
                    <p className={styles.disclaimer}>
                        It’s important that you do your own research or seek financial advice when considering whether
                        an investment is appropriate for your objectives, financial situation or needs.{' '}
                    </p>
                </div>
            </Page>

            <Feedback category="Auto-invest" actionIdentifier={`/${premadeSlug}`} />

            {displayCustomerAutoinvest ? (
                <ActionBar className={cn(styles.footer, styles.footerNoOverflow)}>
                    {oneOffAutoinvestEnabled && (
                        <Button
                            additionalClassName={styles.oneOffCta}
                            type="primary"
                            dataTestId="button--one-off-buy"
                            label="Place one-off buy"
                        />
                    )}
                    <div /> {/* Empty div for right aligning edit - can remove when we turn on one-off auto-invest */}
                    <EditControl accountRestricted={accountRestricted} />
                </ActionBar>
            ) : (
                <ActionBar>
                    {oneOffAutoinvestEnabled && (
                        <Button
                            additionalClassName={styles.oneOffCta}
                            type="primary"
                            dataTestId="button--one-off-buy"
                            label="Place one-off buy"
                        />
                    )}
                    <Button
                        disabled={accountRestricted}
                        onClick={() => {
                            rudderTrack('autoinvest', 'autoinvest_type_selected', {
                                autoinvest_type: currentPremadeOrder.slug as AutoinvestOrderType,
                            })
                            handleFooterClick(currentPremadeOrder, premadeSlug)
                        }}
                        label="Auto-invest in this order"
                        dataTestId="button--auto-invest-in-premade-order"
                    />
                </ActionBar>
            )}
            {/* Alert modal if customer has existing premade order */}
            {customerExistingPremadeOrder && (
                <Modal
                    isAlert
                    isOpen={showSwitchModal}
                    title="Switch your pre-made order?"
                    content={
                        <>
                            <p>You can only have one pre-made order set up at a time.</p>

                            <p>
                                Right now, you’re set up to auto-invest in the{' '}
                                {customerExistingPremadeOrder.premadeOrder.name} order. Do you want to cancel your{' '}
                                {customerExistingPremadeOrder.premadeOrder.name} order and switch to the{' '}
                                {currentPremadeOrder.name} order?
                            </p>
                        </>
                    }
                    dataTestId="modal--autoinvest-switch-order"
                    setIsOpen={setShowSwitchModal}
                    primaryButton={{
                        label: 'Switch me',
                        onClick: () => {
                            setShowSwitchModal(false)
                            handlePremadeSwitchConfirmed()
                        },
                    }}
                    secondaryButton={{
                        label: 'Don’t switch',
                        onClick: () => {
                            setShowSwitchModal(false)
                        },
                    }}
                />
            )}
        </>
    )
}

export default Premade
