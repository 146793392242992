import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'

export const OtherLicenceDetails = (jurisdiction: Model.User['jurisdiction'], registerLinkClick: () => void) => (
    <p>
        If you have another form of ID we can still verify your identity—it’s just a little more manual.
        <br />
        <a
            target="_blank"
            rel="noopener"
            href={
                jurisdiction === 'au'
                    ? 'https://intercom.help/sharesies-au/en/articles/5135944-id-you-need-to-sign-up-to-sharesies'
                    : 'https://intercom.help/sharesies/en/articles/1738992-how-do-i-sign-up-to-sharesies'
            }
            onClick={registerLinkClick}
        >
            Find out how in our help centre
        </a>
    </p>
)

export const OtherLicenceRegisterLinkClick = (jurisdiction: Model.User['jurisdiction']) => {
    Analytics.event({
        category: 'Sign Up',
        action: 'Help centre link',
        label: `Other ${jurisdiction === 'au' ? 'Australian' : 'New Zealand'} identification`,
    })
}
export const OtherInformation = ({
    jurisdiction,
}: {
    jurisdiction: Model.User['jurisdiction']
    isSecondaryId?: boolean
}) => {
    return (
        <div>
            <h2 className={spacing.spaceBelow16}>Details on your other ID</h2>
            {OtherLicenceDetails(jurisdiction, () => OtherLicenceRegisterLinkClick(jurisdiction))}
        </div>
    )
}
