import {Button} from '@design-system/button'
import {Close} from '@design-system/icon'
import {Form, Formik} from 'formik'
import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {formatNumber} from '~/global/utils/format-number/formatNumber'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {PageButtonGroup} from '~/global/widgets/button-page-group/PageButtonGroup'
import {validate} from '~/global/widgets/form-controls'
import {Select, StrongCurrency} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import accountingActions from '~/store/accounting/actions'
import {TopupGoalInterval} from '~/store/accounting/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import styles from './MoneyGoal.scss'

export interface MoneyGoalFormValues {
    investGoal: string
    frequency: 'weekly' | 'fortnightly' | 'monthly'
}

export const frequencyMap = {
    weekly: 'week',
    fortnightly: 'fortnight',
    monthly: 'month',
}

export const topUpGoalMap = {
    weekly: 'WEEK',
    fortnightly: 'FORTNIGHT',
    monthly: 'MONTH',
}

const moneyGoalToFormValues = (moneyGoalFrequency: TopupGoalInterval): MoneyGoalFormValues['frequency'] => {
    switch (moneyGoalFrequency) {
        case 'WEEK':
            return 'weekly'
        case 'FORTNIGHT':
            return 'fortnightly'
        case 'MONTH':
            return 'monthly'
    }
}

export const calculateGoalBreakdown = (value: number, frequency: MoneyGoalFormValues['frequency']) => {
    if (frequency === 'weekly') {
        return value / 52
    }
    if (frequency === 'fortnightly') {
        return value / 26
    }
    if (frequency === 'monthly') {
        return value / 12
    }
    return 0
}

const MoneyGoalSetup: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)
    const moneyGoal = useAppSelector(s => s.accounting.topupGoal)

    const autoInvestGoalContent = (investGoal: string, frequency: MoneyGoalFormValues['frequency']) => {
        const moneyGoal = calculateGoalBreakdown(Number(investGoal), frequency)

        if (!investGoal || investGoal === '0') {
            return <></>
        }

        return (
            <div className={styles.goalCalcContent}>
                <p>
                    You'll need to add{' '}
                    <strong>${formatNumber({number: moneyGoal.toString(), decimalPlaces: 2})}</strong> each{' '}
                    <strong>{frequencyMap[frequency]}</strong> to hit your goal in 2024. Let's do this!
                </p>
            </div>
        )
    }

    return (
        <>
            <Toolbar
                title="Money goal"
                dataTestId="peeled-toolbar--goal"
                leftButton="back"
                rightSlot={
                    <Link to={profileUrl('')}>
                        <Close colour="grey-1" size={18} />
                    </Link>
                }
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <Formik
                    initialValues={
                        {
                            investGoal: moneyGoal ? moneyGoal.target_amount.toString() : '',
                            frequency: moneyGoal ? moneyGoalToFormValues(moneyGoal.interval) : 'weekly',
                        } as MoneyGoalFormValues
                    }
                    validate={validate.generate({
                        investGoal: [validate.required(), validate.money(), validate.minimum(1, 'Must be at least $1')],
                        frequency: [validate.required()],
                    })}
                    onSubmit={values => {
                        navigate(profileUrl('wallet/money-goal/confirm/:investGoal/:frequency', values))
                    }}
                >
                    {({isValid, touched, values}) => (
                        <Form>
                            <StrongCurrency
                                name="investGoal"
                                label="How much would you like to contribute in 2024?"
                                dataTestId=""
                                currency={homeCurrency}
                            />
                            <Select
                                name="frequency"
                                label="How often would you like to do it?"
                                dataTestId=""
                                choices={[
                                    {value: 'weekly', label: 'Weekly'},
                                    {value: 'fortnightly', label: 'Fortnightly'},
                                    {value: 'monthly', label: 'Monthly'},
                                ]}
                            />
                            {autoInvestGoalContent(values.investGoal, values.frequency)}
                            <PageButtonGroup>
                                <Button
                                    dataTestId="button--next"
                                    label={moneyGoal ? 'Update my goal' : 'Confirm my goal'}
                                    disabled={!isValid || !(touched.investGoal || touched.frequency)}
                                    isSubmit
                                />
                                {moneyGoal && (
                                    <Button
                                        onClick={() => {
                                            dispatch(accountingActions.DeleteCurrentTopupGoal(moneyGoal.id))
                                            navigate(profileUrl('wallet/money-goal'))
                                        }}
                                        dataTestId="button--delete"
                                        type="secondary"
                                        label="Delete my goal"
                                    />
                                )}
                            </PageButtonGroup>
                        </Form>
                    )}
                </Formik>
            </Page>
        </>
    )
}

export {MoneyGoalSetup}
