import {assertNever} from '~/global/utils/assert-never/assertNever'
import {ActionsType} from './actions'
import {State} from './types'

const initialState: State = {
    modalError: null,
    modalErrorTitle: null,
    clientUpgradeRequired: false,
    scheduledMaintenance: false,
    addressValidationModalOpen: false,
}

function reducer(state: State = initialState, action: ActionsType): State {
    switch (action.type) {
        case 'notification.SetModalError':
            return {...state, modalError: action.payload.message, modalErrorTitle: action.payload.title}
        case 'notification.ClearModalError':
            return {...state, modalError: null}
        case 'notification.ClientUpgradeRequired':
            return {...state, clientUpgradeRequired: true}
        case 'notification.ScheduledMaintenance':
            return {...state, scheduledMaintenance: action.payload}
        case 'notification.showAddressValidationRejectionReason':
            return {...state, addressValidationModalOpen: true}
        case 'notification.hideAddressValidationRejectionReason':
            return {...state, addressValidationModalOpen: false}
        default:
            assertNever(action)
    }
    return state
}

export default reducer
