import cn from 'classnames'
import React from 'react'
import {accessibility} from '~/global/scss/helpers'
import {convertToKebabCase} from '~/global/utils/convert-to-kebab-case/convertToKebabCase'
import {getScreenReaderFriendlyTimePeriod} from '~/global/utils/time-period/timePeriod'
import styles from './Chips.scss'

interface Props<T, AllowAll> {
    options: readonly T[]
    isInlineDisplay?: boolean
    selected?: readonly T[]
    hasAllOption?: AllowAll
    onChipClick?(parameters: AllowAll extends false | undefined ? T : T | 'all'): void
    icon?: JSX.Element
    hasClearAllButton?: boolean
    onClearAllButtonClick?(): void
    additionalAriaLabel?: string
    fixedHeight?: boolean
    singleLine?: boolean
}

const Chips = <T extends string, AllowAll extends boolean = false>({
    options,
    isInlineDisplay,
    hasAllOption,
    selected,
    onChipClick,
    icon,
    hasClearAllButton,
    onClearAllButtonClick,
    additionalAriaLabel,
    fixedHeight,
    singleLine,
}: Props<T, AllowAll>) => {
    return (
        <div className={cn({[styles.inlineChipsWrapper]: !!isInlineDisplay, [styles.singleLine]: !!singleLine})}>
            <ul
                className={cn(styles.chips, {
                    [styles.inline]: !!isInlineDisplay,
                    [styles.fixedHeight]: !!fixedHeight,
                })}
            >
                {hasAllOption && (
                    <li
                        className={cn(styles.chip, {
                            [styles.selected]:
                                selected && (selected.length === 0 || (hasAllOption && selected.includes('all' as T))),
                        })}
                    >
                        {onChipClick ? (
                            <button
                                className={accessibility.button}
                                type="button"
                                data-testid="button--all"
                                // This small hint is required to make typescript happy
                                onClick={() => onChipClick('all' as T)}
                            >
                                All
                            </button>
                        ) : (
                            <span className={styles.notClickableChip}>All</span>
                        )}
                    </li>
                )}
                {options.map(option => (
                    <li
                        key={option}
                        className={cn(styles.chip, {
                            [styles.selected]: selected && selected.includes(option),
                        })}
                    >
                        {onChipClick ? (
                            <button
                                className={accessibility.button}
                                type="button"
                                data-testid={`button--${convertToKebabCase(option.toLowerCase())}`}
                                onClick={() => onChipClick(option)}
                                aria-label={`${
                                    additionalAriaLabel ? additionalAriaLabel + ' ' : ''
                                }${getScreenReaderFriendlyTimePeriod(option)}`}
                                aria-selected={selected && selected.includes(option)}
                            >
                                {option}
                                {icon && <span>{icon}</span>}
                            </button>
                        ) : (
                            <span
                                className={styles.notClickableChip}
                                aria-label={getScreenReaderFriendlyTimePeriod(option)}
                            >
                                {option}
                                {icon && <span>{icon}</span>}
                            </span>
                        )}
                    </li>
                ))}
                {hasClearAllButton && onClearAllButtonClick && (
                    <li className={styles.clearAllButton}>
                        <button
                            className={accessibility.button}
                            type="button"
                            data-testid="button--clear-all"
                            onClick={() => onClearAllButtonClick()}
                        >
                            Clear all
                        </button>
                    </li>
                )}
            </ul>
        </div>
    )
}

export default Chips
