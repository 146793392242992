import {TextInput, TextProps} from '@design-system/text-input'
import React from 'react'
import {withFocus, withId} from '../common'

function normalizeCode(value: string, previousValue: string): string {
    /*
    TODO: There's a problem with this, in that hitting backspace at a - doesn't end up removing the -. Need some way
    of handling end and mid-text backspace.
     */
    if (value) {
        if (value + '-' === previousValue) {
            // User backspaced a hyphen, we'll just remove the last character instead
            value = value.replace(/.$/, '')
        }

        value = value.toUpperCase()
        // Replace unacceptable characters, including -
        value = value.replace(/[^0-9A-Z]/g, '')
        // Remove anything too long
        value = value.substring(0, 12)
        // Put - back in the right places
        value = value.replace(/^(...)(...)?(...)?(.*)/, (_match, first, second, third, remainder) => {
            let result = ''
            if (first) {
                result += first + '-'
            }
            if (second) {
                result += second + '-'
            }
            if (third) {
                result += third + '-'
            }
            return result + remainder
        })
    }
    return value
}

const GiftCode = (props: TextProps) => (
    <TextInput
        {...props}
        onChange={e => {
            if (!props.setFieldValue) {
                return
            }
            props.setFieldValue(normalizeCode(e.currentTarget.value, props.value || ''))
        }}
        inputProps={() => ({
            type: 'email',
        })}
    />
)

const GiftCodeWithFocus = withId(withFocus(GiftCode))

export default GiftCodeWithFocus
