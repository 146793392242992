import {withFormik} from 'formik'
import {DateTime} from 'luxon'
import React from 'react'
import {Request, Response} from '~/api/retail/types'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {IdentityVerificationStep} from '~/global/utils/identity-verification/identityVerification'
import {SharesiesOmit} from '~/global/utils/type-utilities/typeUtilities'
import {validate} from '~/global/widgets/form-controls'
import {Text, AUStateSelectInput} from '~/global/widgets/form-controls/formik'
import {
    CommonHeader,
    CommonFooter,
    CommonIdFormProps,
    consentMessage,
} from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/IdentityFormCommon'

type AULicenceFormProps = CommonIdFormProps & {
    givenName?: string
    middleName?: string
    familyName?: string
    licenceNumber?: string
    licenceVersion?: string
    licenceCardNumber?: string
    dateOfBirth?: DateTime
    verifyAustralianLicence(
        payload: SharesiesOmit<Request.IdentityVerifiAustralianLicence, Request.ActingAsRequired>,
    ): Promise<null | Response.Error>
}

type AULicenceFormValues = SharesiesOmit<
    Request.IdentityVerifiAustralianLicence,
    Request.ActingAsRequired & {date_of_birth: string; drivers_licence_state: string; consent: true}
> & {date_of_birth?: DateTime; drivers_licence_state: string; consent: boolean}

export const AULicenceForm = withFormik<AULicenceFormProps, AULicenceFormValues>({
    enableReinitialize: true,
    mapPropsToValues: ({
        givenName,
        middleName,
        familyName,
        dateOfBirth,
        licenceNumber,
        licenceCardNumber,
    }: AULicenceFormProps) => ({
        given_name: givenName || '',
        middle_name: middleName || '',
        family_name: familyName || '',
        date_of_birth: dateOfBirth || undefined,
        drivers_licence_number: licenceNumber || '',
        drivers_licence_state: '',
        drivers_licence_card_number: licenceCardNumber || '',
        consent: false,
    }),
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        given_name: undefined,
    }),
    handleSubmit: async (
        values,
        {setSubmitting, setStatus, props: {verifyAustralianLicence, setIDDuplicateError, setStep}},
    ) => {
        try {
            const error = await verifyAustralianLicence({
                ...values,
                // verifyAustralianLicence needs strings of the dates
                date_of_birth: values.date_of_birth ? values.date_of_birth.toISODate() : '',
                consent: values.consent as true,
                drivers_licence_state:
                    values.drivers_licence_state as Request.IdentityVerifiAustralianLicence['drivers_licence_state'],
            })
            if (error) {
                if (error.code === 'duplicate_id') {
                    setIDDuplicateError(true)
                    setStatus(undefined)
                } else {
                    setIDDuplicateError(false)
                    setStatus(error.message)
                }
                setSubmitting(false)
                return
            } else {
                setStep('completed' as IdentityVerificationStep)
            }
        } catch (e) {
            setStatus(unknownErrorMessage)
            setSubmitting(false)
            throw e
        }
    },
    validate: values => {
        const schema = {
            given_name: [],
            middle_name: [validate.middleName()],
            family_name: [validate.required()],
            date_of_birth: [validate.required(), validate.date(), validate.dateMinYear(1900), validate.notFutureDate()],
            drivers_licence_number: [validate.required()],
            drivers_licence_state: [validate.required()],
            drivers_licence_card_number: [validate.required()],
            consent: [validate.isTrue(consentMessage)],
        }
        return validate.generate<AULicenceFormValues>(schema)(values)
    },
})(({handleSubmit, isDependent, preferredName, isSubmitting, isValid, status, isSecondaryId, setFieldValue}) => {
    return (
        <form onSubmit={handleSubmit}>
            <CommonHeader
                setFieldValue={setFieldValue}
                isSecondaryId={isSecondaryId}
                identityType="Australian driver licence"
            />
            <AUStateSelectInput name="drivers_licence_state" />
            <Text
                dataTestId="text-input--drivers-licence-number"
                name="drivers_licence_number"
                label="Driver licence number"
            />
            <Text
                dataTestId="text-input--drivers-licence-card-number"
                name="drivers_licence_card_number"
                label="Driver licence card number"
            />
            <CommonFooter
                isDependent={isDependent}
                preferredName={preferredName}
                isSubmitting={isSubmitting}
                databases={[]}
                isValid={isValid}
                jurisdiction="au"
                error={status}
                isSecondaryId={isSecondaryId}
            />
        </form>
    )
})
