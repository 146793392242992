import React from 'react'
import {Navigate, useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {isError, isLoadingOrUninitialised, isUninitialised} from '~/global/utils/is-loading/isLoading'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useBankLinkingReferrer} from '~/sections/invest/sections/bank-linking/hooks/useBankLinkingRererrer'
import BankLinkingPending from '~/sections/invest/sections/bank-linking/pages/pending/BankLinkingPending'
import {RoutingStateMachine} from '~/sections/invest/sections/bank-linking/widgets/bank-linking-routing-state/RoutingStateMachine'
import actions from '~/store/bankLinking/actions'
import {BasiqReferrer} from '~/store/bankLinking/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {
    BankLinkingConsent,
    ChooseDepositTarget,
    ChooseNotification,
    ChooseWholeDollarRoundups,
    DebitAccountConfirmed,
    NextSteps,
    RoundupsIntro,
    RoundupsReady,
    SelectDebitBankAccount,
    SelectMonitoringBankAccounts,
    RoundupsHowTo,
    ReadyFromAccountsAndCards,
} from './pages'

export interface OnNextProps {
    onBack: () => void
    onNext: () => void
}

// All roundups screens are routed to this component.
// This manages
// - Entering the flow if setup is requried
// - Changing routes for each page in the flow
//
// It handles logic for the routes:
// - 'explore/roundups'
// - 'explore/roundups/:step'
// - 'wallet/roundups'
// - 'wallet/roundups/:step'
// - 'settings/bank-accounts-cards/roundups'
// - 'settings/bank-accounts-cards/roundups/:step'
export const RoundupsSetupStateMachine = ({step: stepParam}: {step?: string}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const {pathname} = useLocation()
    const [urlParams] = useSearchParams()

    // Upon returing from basiq url, this 'state' queryparam is given back to us.
    // This codebase is using 'state' to remember where the user was before they left to basiq.
    const searchParams = urlParams.get('state') as BasiqReferrer | null

    const {
        isBankLinked,
        hasMonitoringAccountsSet,
        roundupsDebitAccount,
        loadingState,
        bankLinkingPending,
        jurisdiction,
    } = useAppSelector(({bankLinking, identity}) => ({
        jurisdiction: identity.user!.jurisdiction,
        isBankLinked: bankLinking.isBankLinked,
        roundupsDebitAccount: bankLinking.roundupsDebitAccount,
        hasMonitoringAccountsSet: bankLinking.roundupsMonitoringAccounts.length > 0,
        loadingState: bankLinking.bankLinkingLoadingState,
        bankLinkingPending: bankLinking.bankLinkingPending,
    }))

    // Values for rudderstack tracking
    const {wholeDollarRoundups} = useAppSelector(({bankLinking}) => ({
        wholeDollarRoundups: bankLinking.optionWholeDollar,
    }))

    const referrer = useBankLinkingReferrer() as 'wallet' | 'explore' | 'settings/bank-accounts-cards'

    React.useEffect(() => {
        if (isUninitialised(loadingState)) {
            dispatch(actions.FetchBankLinkingStatus())
        }

        if (searchParams === 'account') {
            if (!pathname.includes('settings/bank-accounts-cards')) {
                // Set referrer to bank-accounts-cards
                navigate(profileUrl('settings/bank-accounts-cards/roundups'))
            }
        } else if (searchParams === 'wallet-linked-bank-payments') {
            navigate(profileUrl('wallet/linked-bank-topup/select-debit-account'), {replace: true})
        } else if (searchParams === 'explore-linked-bank-payments') {
            navigate(profileUrl('explore/linked-bank-topup/select-debit-account'), {replace: true})
        }
    }, [])

    if (bankLinkingPending && bankLinkingPending.code) {
        return <BankLinkingPending />
    }

    if (jurisdiction !== 'au' || isError(loadingState)) {
        return <WeSlippedUp />
    }

    if (isLoadingOrUninitialised(loadingState)) {
        return (
            <>
                <Toolbar leftButton="back" dataTestId="toolbar--banklinking-loading" />
                <Page>
                    <Loading />
                </Page>
            </>
        )
    }

    const exitRoundups = () => {
        navigate(profileUrl(referrer))
    }

    return (
        <RoutingStateMachine
            steps={[
                'intro',
                'howto',
                'consent',
                'monitor-accounts',
                'deposit-target',
                'deposit-target-from-whole-dollar',
                'debit-account',
                'notify',
                'option-whole-dollar',
                'ready',
                'ready-from-accounts-and-cards',
                'ready',
                'account-confirmed',
                'next-steps',
                'manage',
            ]}
            currentStep={stepParam as any}
            getUrlForStep={newStep => {
                return profileUrl(`${referrer}/roundups/:step`, {step: newStep})
            }}
            entrypoint={() => {
                // Bank linking only for users coming from bank accounts (no roundups)
                if (referrer === 'settings/bank-accounts-cards') {
                    if (!isBankLinked) {
                        return 'consent'
                    } else if (!roundupsDebitAccount || !roundupsDebitAccount.externallyAvailable) {
                        return 'debit-account'
                    } else {
                        return 'account-confirmed'
                    }
                } else {
                    // Full bank linking and roundups flow
                    if (!isBankLinked) {
                        return 'intro'
                    } else if (!hasMonitoringAccountsSet) {
                        // When user returns from Basiq flow
                        return 'monitor-accounts'
                    } else if (!roundupsDebitAccount || !roundupsDebitAccount.externallyAvailable) {
                        return 'debit-account'
                    } else {
                        return 'manage'
                    }
                }
            }}
            trackStepChange={(prevStep, nextStep) => {
                if (prevStep === 'intro') {
                    rudderTrack('round-up', 'round-up-intro-clicked', {
                        'button-clicked': nextStep === 'consent' ? 'get-started' : 'how-it-works',
                        navigated_from: referrer === 'explore' ? 'invest-tile' : 'bank-linking',
                    })
                } else if (prevStep === 'monitor-accounts') {
                    rudderTrack('round-up', 'account-tracked-selected')
                } else if (prevStep === 'debit-account') {
                    rudderTrack('round-up', 'account-withdraw-from-selected')
                } else if (nextStep === 'ready') {
                    rudderTrack('round-up', 'round-up-setup-completed', {
                        'whole-dollar-round-ups': !!wholeDollarRoundups,
                        notification_category: 'roundup_activity', // TODO: send notification category
                    })
                    Analytics.event({
                        action: 'start',
                        category: 'round-up',
                        label: '',
                    })
                } else if (referrer === 'settings/bank-accounts-cards' && !prevStep && nextStep === 'consent') {
                    rudderTrack('bank-link', 'bank-account-link-started')
                } else if (prevStep === 'ready-from-accounts-and-cards') {
                    rudderTrack('bank-link', 'bank-account-link-completed')
                }
            }}
        >
            {(currentStep, setStep) => {
                if (referrer === 'settings/bank-accounts-cards') {
                    if (currentStep === 'consent') {
                        return <BankLinkingConsent />
                    } else if (currentStep === 'debit-account') {
                        return (
                            <SelectDebitBankAccount onBack={exitRoundups} onNext={() => setStep('account-confirmed')} />
                        )
                    } else if (currentStep === 'account-confirmed') {
                        return (
                            <DebitAccountConfirmed
                                onBack={() => setStep('debit-account')}
                                onNext={() => setStep('next-steps')}
                            />
                        )
                    } else if (currentStep === 'next-steps') {
                        return <NextSteps onNext={exitRoundups} onBack={exitRoundups} />
                    } else if (currentStep === 'intro') {
                        return (
                            <RoundupsIntro
                                onBack={exitRoundups}
                                onNext={() => {
                                    setStep('monitor-accounts')
                                }}
                                onHowTo={() => setStep('howto')}
                            />
                        )
                    } else if (currentStep === 'howto') {
                        return (
                            <RoundupsHowTo
                                onComplete={() => setStep('monitor-accounts')}
                                onCompleteLabel="Let's get started"
                            />
                        )
                    } else if (currentStep === 'monitor-accounts') {
                        return (
                            <SelectMonitoringBankAccounts
                                onBack={() => setStep('intro')}
                                onNext={() => setStep('deposit-target')}
                            />
                        )
                    } else if (currentStep === 'deposit-target') {
                        return (
                            <ChooseDepositTarget
                                onBack={() => setStep('monitor-accounts')}
                                onNext={() => setStep('ready-from-accounts-and-cards')}
                                isInSetupFlow={true}
                                fromWholeDollar={false}
                            />
                        )
                    } else if (currentStep === 'ready-from-accounts-and-cards') {
                        return (
                            <ReadyFromAccountsAndCards
                                onBack={() => setStep('monitor-accounts')}
                                onNext={() => navigate(profileUrl('explore/roundups/manage'))}
                            />
                        )
                    }
                }
                switch (currentStep) {
                    case 'intro':
                        return (
                            <RoundupsIntro
                                onBack={exitRoundups}
                                onNext={() => {
                                    setStep('consent')
                                }}
                                onHowTo={() => setStep('howto')}
                            />
                        )
                    case 'howto':
                        return (
                            <RoundupsHowTo onComplete={() => setStep('consent')} onCompleteLabel="Let's get started" />
                        )
                    case 'consent':
                        return <BankLinkingConsent />
                    case 'monitor-accounts':
                        return (
                            <SelectMonitoringBankAccounts
                                onBack={exitRoundups}
                                onNext={() => setStep('deposit-target')}
                            />
                        )
                    case 'deposit-target':
                        return (
                            <ChooseDepositTarget
                                onBack={() => setStep('monitor-accounts')}
                                onNext={() => setStep('debit-account')}
                                isInSetupFlow={true}
                                fromWholeDollar={false}
                            />
                        )
                    case 'debit-account':
                        return (
                            <SelectDebitBankAccount
                                onBack={() => setStep('deposit-target')}
                                onNext={() => setStep('notify')}
                            />
                        )
                    case 'notify':
                        return (
                            <ChooseNotification
                                onBack={() => setStep('debit-account')}
                                onNext={() => setStep('ready')}
                            />
                        )
                    case 'ready':
                        return (
                            <RoundupsReady
                                onBack={() => setStep('notify')}
                                onNext={() => setStep('manage')}
                                onNextWholeDollar={() => setStep('option-whole-dollar')}
                            />
                        )
                    case 'option-whole-dollar':
                        return (
                            <ChooseWholeDollarRoundups
                                onBack={() => setStep('ready')}
                                onNext={() => setStep('manage')}
                                onChangeTransferAmount={() => setStep('deposit-target-from-whole-dollar')}
                            />
                        )
                    case 'deposit-target-from-whole-dollar':
                        return (
                            <ChooseDepositTarget
                                onBack={() => setStep('option-whole-dollar')}
                                onNext={() => setStep('manage')}
                                isInSetupFlow={true}
                                fromWholeDollar={true}
                            />
                        )
                    case 'manage':
                        return <Navigate to={profileUrl(`${referrer as 'wallet' | 'explore'}/roundups/manage`)} />
                    default:
                        break
                }
                // Something went wrong. Return to referrer to avoid a white screen
                return <Navigate to={profileUrl(referrer as '')} />
            }}
        </RoutingStateMachine>
    )
}
