import {assertNever} from '~/global/utils/assert-never/assertNever'
import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {
    restrictedAddressStatus,
    restrictedPrescribedApprovalStatus,
    restrictedIdentityVerificationStatus,
} from '~/global/utils/get-restricted-statuses/getRestrictedStatuses'
import {ApplicationPointer, PortfolioItemsMap} from '~/store/identity/types'
import {createSelector, Selector} from '../selectors'

export const optionalActingAsID = createSelector(
    ({identity}) => identity.user,
    user => (user ? user.id : undefined),
)

export const getCurrentOrders = createSelector(
    ({identity}) => identity.orders,
    orders => orders,
)

export const actingAsID = createSelector(
    ({identity}) => identity.user,
    user => {
        if (!user) {
            throw new Error('Not logged in')
        }
        return user.id
    },
)

export const getJurisdiction = createSelector(
    ({identity}) => identity.user,
    user => {
        if (!user) {
            throw new Error('Not logged in')
        }
        return user.jurisdiction
    },
)

export const getAvailableCurrencies = createSelector(
    ({identity}) => identity.user && identity.user.us_equities_enabled,
    usdEnabled => {
        const availableCurrencies: Currency[] = ['nzd', 'aud']

        if (usdEnabled) {
            availableCurrencies.push('usd')
        }
        return availableCurrencies
    },
)

export const restrictedAccountWarning = createSelector(
    ({identity}) => identity,
    ({identity}) => (identity.user ? identity.user.account_restricted : false),
    ({identity}) => (identity.user ? identity.user.prescribed_approved : false),
    ({identity}) => (identity.user ? identity.user.prescribed_participant : false),
    (identity, accountRestricted, prescribedApproved, prescribedParticipant) => {
        const addressState = identity.user!.address_state
        const identityVerification = identity.user!.checks.identity_verification
        return {
            addressStatus: restrictedAddressStatus(identity.user, addressState, accountRestricted),
            prescribedApprovalStatus: restrictedPrescribedApprovalStatus(
                prescribedApproved,
                prescribedParticipant,
                accountRestricted,
            ),
            identityVerificationStatus: restrictedIdentityVerificationStatus(accountRestricted, identityVerification),
        }
    },
)

/**
 * @deprecated use global/state-hooks/mixed-source/usePortfolioItemsById instead if possible
 * @description Note this selector uses only retail data, nothing from Rakaia, and doesn't
 * include some of the progressive improvements that that version does (like counts
 * of votes). This version does include some newer functionality such as
 * `sharesNotAvailableForSell` which should be migrated to the
 * global/state-hooks/mixed-source/usePortfolioItemsById version before this can
 * be fully removed.
 */
export const portfolioItems = createSelector(
    ({identity}) => identity.holdings,
    ({identity}) => identity.orders,
    ({identity}) => identity.employmentInstrumentIds,
    (holdings, orders, employmentInstrumentIds) => {
        // create the list from holdings and/or the presence of orders
        const items: PortfolioItemsMap = {}

        holdings.forEach(fh => {
            items[fh.fund_id] = {
                instrumentId: fh.fund_id,
                holding: fh,
                buyCurrencyTotal: 0,
                buySharesTotal: 0,
                sellTotal: 0,
                transferTotal: 0,
                sharesNotAvailableForSell: 0,
            }
        })

        // there might not be a holding yet if there is a pending order, so enumerate all the orders
        orders.forEach(order => {
            if (order.type === 'withdrawal') {
                return
            }

            const fund_id = order.fund_id

            // if it's not a withdrawl order (withdrawals aren't associated with an instument) and we don't yet have an object for this order in our map yet
            if (!items[fund_id]) {
                items[fund_id] = {
                    instrumentId: fund_id,
                    buyCurrencyTotal: 0,
                    buySharesTotal: 0,
                    sellTotal: 0,
                    transferTotal: 0,
                    sharesNotAvailableForSell: 0, // Shares in pending sell orders or pending transfer-out orders
                }
            }
            switch (order.type) {
                case 'buy':
                    if (order.requested_currency) {
                        items[fund_id].buyCurrencyTotal =
                            items[fund_id].buyCurrencyTotal + Number(order.requested_currency)
                    }
                    if (order.requested_shares) {
                        items[fund_id].buySharesTotal = items[fund_id].buySharesTotal + Number(order.requested_shares)
                    }
                    break
                case 'sell':
                    items[fund_id].sellTotal =
                        // for part-filled orders, only total currently outstanding sells by subtracting those that fulfilled
                        items[fund_id].sellTotal + Number(order.shares) - Number(order.filled)

                    // Shares we shouldn't allow selling
                    items[fund_id].sharesNotAvailableForSell += Number(order.shares)
                    break
                case 'csn_transfer_order':
                    if (['processing', 'submitted'].includes(order.state) && Number(order.shares) < 0) {
                        // Shares in review we shouldn't allow selling
                        // state='proccessing' shares are being reviewed by an operator
                        // state='submitted' shares have been submitted to CMC for review
                        items[fund_id].sharesNotAvailableForSell += -Number(order.shares)
                    }
                    items[fund_id].transferTotal = items[fund_id].transferTotal + Number(order.shares)
                    break
                case 'corporate_action_v2':
                    for (const outcome of order.outcome_records) {
                        if (Number(outcome.gross_amount) >= 0) {
                            continue
                        }

                        if (outcome.currency) {
                            items[fund_id].buyCurrencyTotal += -Number(outcome.gross_amount)
                        } else {
                            items[fund_id].sellTotal += -Number(outcome.gross_amount)
                            items[fund_id].sharesNotAvailableForSell += -Number(outcome.gross_amount)
                        }
                    }
                    break
                default:
                    assertNever(order)
            }
        })

        employmentInstrumentIds.forEach(shareSchemeInstrumentId => {
            if (!items[shareSchemeInstrumentId]) {
                items[shareSchemeInstrumentId] = {
                    instrumentId: shareSchemeInstrumentId,
                    buyCurrencyTotal: 0,
                    buySharesTotal: 0,
                    sellTotal: 0,
                    transferTotal: 0,
                    sharesNotAvailableForSell: 0, // Shares in pending sell orders or pending transfer-out orders
                }
            }
        })

        return items
    },
)

/**
 * Get a list of available application pointers. These are barebones pointers to load a full application. Applications
 * that the customer has already applied for are filtered out - and this currently only returns votes
 */
export const applicationPointers: Selector<ApplicationPointer[]> = ({identity}) => {
    // This corresponds to m.Customer.get_pending_applications() which filters out cancelled applications
    const pendingOrders = identity.orders

    return identity.applicationPointers
        .filter(pointer => ['VOTE', 'EXERCISE'].includes(pointer.type))
        .filter(
            pointer =>
                !pendingOrders.some(
                    order =>
                        order.type === 'corporate_action_v2' &&
                        order.application?.rule_id === pointer.application_rule_id,
                ), // no matching pending order
        )
}

export const selectHasUsLivePricing = createSelector(
    ({identity}) => 'us_live_data' in identity.flags,
    ({plan}) => plan.currentPlan && plan.currentPlan.plan.includes_us_live_data,
    (hasUsLivePricingFlag, planIncludesUsLivePricing) => {
        return hasUsLivePricingFlag || !!planIncludesUsLivePricing
    },
)

/**
 * Currently, having the `staff_benefits` flag means that fees will be waived for any monthly
 * subscription plan.
 *
 * If a person has access to the free monthly subscription, then they can ONLY sign up for a
 * monthly subscription plan - we hide the annual plan option.
 * This is not enforced at API level, but if a person with this flag does somehow try to create
 * an annual subscription, in RBE we silently switch it to be a monthly subscription.
 */
export const selectHasStaffBenefits = createSelector(
    ({identity}) => 'staff_benefits' in identity.flags,
    hasStaffBenefits => hasStaffBenefits,
)
