import {Modal} from '@design-system/modal'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useRemainingRightsOnChildInstrument} from '~/global/state-hooks/mixed-source/useRemainingRightsOnChildInstrument'
import {isLoading} from '~/global/utils/is-loading/isLoading'
import {useInstrument} from '~/global/utils/use-instruments/useInstruments'
import accountingActions from '~/store/accounting/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'
import {Instrument} from '~/store/instrument/types'
import orderActions from '~/store/order/actions'

interface Props {
    instrument: Instrument
    applicationRuleId: string
    onClose: () => void
}

const ExerciseRightsBeforeShortfallModal: React.FunctionComponent<Props> = ({
    instrument,
    applicationRuleId,
    onClose,
}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [isOpen, setIsOpen] = React.useState(false)

    const currentPath = location.pathname
    const fundOrdersLoadingState = useAppSelector(s => s.accounting.fundOrdersLoadingState)

    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const childInstrumentIdWithRemainingRights = useRemainingRightsOnChildInstrument(instrument.id)
    const childInstrument = useInstrument(childInstrumentIdWithRemainingRights)
    const childInstrumentApplications = useAppSelector(state =>
        childInstrument ? state.order.applicationsByInstrument[childInstrument.id] : null,
    )
    // assumes max one exercise application available on the child instrument
    const exerciseApplication = childInstrumentApplications?.applications?.find(a => a.type === 'EXERCISE')

    // fetch the child instrument ids, and load their applications
    React.useEffect(() => {
        if (!instrument.childInstrumentIds) {
            dispatch(instrumentActions.getChildInstrumentsByParentId(instrument.id))
            return
        }
        instrument.childInstrumentIds.forEach(childInstrumentId => {
            dispatch(orderActions.LoadInstrumentApplications(childInstrumentId))
            if (!instrumentsById[childInstrumentId]) {
                dispatch(instrumentActions.getInstrumentsByIds([childInstrumentId]))
            }
        })
    }, [instrument.childInstrumentIds, instrumentsById])

    // update the instruments orders
    React.useEffect(() => {
        dispatch(accountingActions.FetchFundOrders(instrument.id))
    }, [])

    // open the modal if there's a child instrument with unexercised rights
    React.useEffect(() => {
        if (
            !isLoading(fundOrdersLoadingState) && // wait for the orders to be loaded
            !isOpen &&
            childInstrumentIdWithRemainingRights &&
            exerciseApplication
        ) {
            setIsOpen(true)
        }
    }, [childInstrumentIdWithRemainingRights, fundOrdersLoadingState])

    if (!childInstrument || !exerciseApplication) {
        return null
    }

    const next = currentPath.replace(
        `${instrument.urlSlug}/apply/${applicationRuleId}`,
        `${childInstrument.urlSlug}/apply/${exerciseApplication.application_rule_id}`,
    )

    return (
        <>
            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                noClose
                title="Hold up, you’ll need to exercise your rights first"
                dataTestId="modal--exercise-rights-first"
                primaryButton={{
                    label: 'Exercise my rights',
                    onClick: () => navigate(next),
                }}
                secondaryButton={{
                    label: 'Go back',
                    onClick: () => onClose(),
                }}
            >
                <p>
                    Before you can apply for shares in the shortfall, you’ll need to exercise all of the rights you
                    received (including any you’ve bought) into shares.
                </p>
            </Modal>
        </>
    )
}

export default ExerciseRightsBeforeShortfallModal
