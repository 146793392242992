import React from 'react'
import {Model} from '~/api/retail/types'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {useEssViewsForInstrument} from '~/global/state-hooks/mixed-source/useEssViewsForInstrument'
import {isUninitialised} from '~/global/utils/is-loading/isLoading'
import InstrumentActivityRecordShareSchemeAlignmentRightAllocation from '~/global/widgets/instrument-activity/RecordShareSchemeAlignmentRightAllocation'
import InstrumentActivityRecordShareSchemeAllocation from '~/global/widgets/instrument-activity/RecordShareSchemeAllocation'
import InstrumentActivityRecordShareSchemeRightAllocation from '~/global/widgets/instrument-activity/RecordShareSchemeRightAllocation'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import essActions from '~/store/employeeShareScheme/actions'
import {possibleESS} from '~/store/employeeShareScheme/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'
import styles from './ShareSchemeAllocation.scss'

interface ShareSchemeAllocationProps {
    instrumentId: string
    allocationLineId: string
}

const ShareSchemeAllocation: React.FunctionComponent<ShareSchemeAllocationProps> = ({
    instrumentId,
    allocationLineId,
}) => {
    const dispatch = useAppDispatch()
    // fetch the instrument
    const instrument = useAppSelector(s => s.instrument.instrumentsById[instrumentId])
    const metadataInitialised = useAppSelector(s => s.instrument.isMetadataInitialised)
    const isPossiblyESS = useAppSelector(s => possibleESS(s))
    const essViews = useEssViewsForInstrument(instrumentId)

    // fetch the allocations
    const shareSchemeAllocationsByInstrument = useAppSelector(
        s => s.employeeShareScheme.shareSchemeAllocationsByInstrument,
    )
    const allocationsLoadingState = useAppSelector(s => s.employeeShareScheme.allocationsLoadingState)
    const allocationsForInstrument = shareSchemeAllocationsByInstrument[instrumentId]

    React.useEffect(() => {
        // try loading instrument directly in case of customer landing on this page - wait for metadata first
        if (metadataInitialised && !instrument) {
            dispatch(instrumentActions.getSingleInstrumentById(instrumentId))
        }
    }, [instrumentId, metadataInitialised])

    React.useEffect(() => {
        if (
            isPossiblyESS &&
            // fetch allocations if they're uninitialised
            isUninitialised(allocationsLoadingState)
        ) {
            dispatch(essActions.FetchParticipatingShareSchemes())
        }
    }, [isPossiblyESS, instrumentId])

    if (!isUninitialised(allocationsLoadingState) && !essViews.isActiveMember) {
        return <WeSlippedUp />
    }

    if (!essViews.isActiveMember || !allocationsForInstrument || !instrument) {
        return <Loading isPineapple />
    }

    let allocation
    let allocationLine

    const allAllocations = [
        ...allocationsForInstrument.share_allocations,
        ...allocationsForInstrument.share_rights_allocations,
        ...allocationsForInstrument.alignment_right_allocations,
    ]

    for (const currentAllocation of allAllocations) {
        const line = currentAllocation.lines.find(line => line.id === allocationLineId)
        if (line) {
            allocation = currentAllocation
            allocationLine = line
            break
        }
    }

    if (!allocation || !allocationLine) {
        return <WeSlippedUp />
    }

    return (
        <>
            <Toolbar
                title={`${allocation.group_name} allocation`}
                dataTestId="toolbar--share-scheme-allocation"
                leftButton="back"
            />
            <Page withoutDefaultPadding>
                <div className={styles.viewContainer}>
                    {allocation.type === 'ess_share_allocation' && (
                        <InstrumentActivityRecordShareSchemeAllocation
                            instrument={instrument}
                            allocationLine={allocationLine as Model.ESSAllocationLine}
                        />
                    )}
                    {allocation.type === 'ess_share_right_allocation' && (
                        <InstrumentActivityRecordShareSchemeRightAllocation
                            instrument={instrument}
                            allocationLine={allocationLine as Model.ESSRightAllocationLine}
                        />
                    )}
                    {allocation.type === 'ess_alignment_right_allocation' && (
                        <InstrumentActivityRecordShareSchemeAlignmentRightAllocation
                            instrument={instrument}
                            allocationLine={allocationLine as Model.ESSAlignmentRightAllocationLine}
                        />
                    )}
                </div>
            </Page>
        </>
    )
}

export default ShareSchemeAllocation
