import React from 'react'
import {useLocation} from 'react-router-dom'
import {useIntercom} from 'react-use-intercom'
import Analytics from '~/api/google-analytics/googleAnalytics'

export const LogPageView = () => {
    const {pathname} = useLocation()
    const {update: updateIntercom} = useIntercom()

    React.useEffect(() => {
        Analytics.pageNav(pathname)
        updateIntercom()
    }, [pathname])

    return null
}
