import {Button} from '@design-system/button'
import {ArrowUp} from '@design-system/icon'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {Response} from '~/api/retail/types'
import config from '~/configForEnv'
import errorBanana from '~/global/assets/images/error-banana.svg'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import notFoundStyles from '~/global/pages/not-found/NotFound.scss'
import {spacing} from '~/global/scss/helpers'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {scrollToTop, getScrollPosition} from '~/global/utils/scroll-utils/scrollUtils'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import HelpCentreLink from '~/global/widgets/help-centre-link/HelpCentreLink'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'
import {getInstrumentFromSlug} from '~/store/instrument/selectors'
import actions from '~/store/market/actions'
import Header from './widgets/header/Header'
import OrderBook from './widgets/order-book/OrderBook'
import TodaysTrades from './widgets/todays-trades/TodaysTrades'
import styles from './NzxMarketDepth.scss'

interface NzxMarketDepthProps {
    instrumentSlug: string
    portfolioId?: string
}

/**
 * NZX Market Depth page. Note this feature used to be called LiveMarketDepth in code.
 */
const NzxMarketDepth: React.FunctionComponent<NzxMarketDepthProps> = React.memo(({instrumentSlug, portfolioId}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [todaysTrades, setTodaysTrades] = React.useState<Response.LiveDataFetch['live_data']['recent_trades']>()
    const [showLoadingPage, setShowLoadingPage] = React.useState<boolean>(false)
    const [showScrollToTopButton, setShowScrollToTopButton] = React.useState(false)

    const instrument = useAppSelector(s => getInstrumentFromSlug(s, instrumentSlug))
    const isSubscribedLiveData = useAppSelector(s => s.market.liveDataSubscriptionDetails.is_active)
    const liveDataSubscriptionDetailsInitialised = useAppSelector(s => s.market.liveDataSubscriptionDetailsInitialised)
    const liveMarketData = useAppSelector(s =>
        instrument && s.market.liveMarketDataLoadingState === 'ready' && s.market.liveMarketData
            ? s.market.liveMarketData[instrument.id]
            : undefined,
    )
    const liveMarketDataLoadingState = useAppSelector(s => s.market.liveMarketDataLoadingState)
    const metadataInitialised = useAppSelector(s => s.instrument.isMetadataInitialised)
    const defaultPortfolioId = useAppSelector(s => s.identity.user!.portfolio_id)

    const NZ_TIME_ZONE = config.NZTimeZone
    const isWeekend =
        DateTime.local().setZone(NZ_TIME_ZONE).weekday === 6 || DateTime.local().setZone(NZ_TIME_ZONE).weekday === 7

    React.useEffect(() => {
        // try loading instrument directly in case of customer landing on this page (when metadata is present)
        if (metadataInitialised && !instrument) {
            dispatch(instrumentActions.getSingleInstrumentBySlug(instrumentSlug))
        }
    }, [instrument, metadataInitialised])

    React.useEffect(() => {
        if (instrument && liveDataSubscriptionDetailsInitialised && isSubscribedLiveData) {
            dispatch(actions.FetchLiveMarketData(instrument.id, portfolioId || defaultPortfolioId))
        }
    }, [instrument])

    React.useEffect(() => {
        if (liveMarketData) {
            filterTodaysTrades()
        }
    }, [liveMarketData])

    React.useEffect(() => {
        if (!isSubscribedLiveData) {
            // Their subscription expired, we'll send them back to resubscribe
            navigate(-1)
        }
    }, [isSubscribedLiveData])

    React.useLayoutEffect(() => {
        const checkIfShouldShowButton = () => {
            if (getScrollPosition() < window.innerHeight / 2 && showScrollToTopButton) {
                setShowScrollToTopButton(false)
            }
            if (getScrollPosition() >= window.innerHeight / 2 && !showScrollToTopButton) {
                setShowScrollToTopButton(true)
            }
        }

        document.addEventListener('scroll', checkIfShouldShowButton)

        return () => {
            document.removeEventListener('scroll', checkIfShouldShowButton)
        }
    }, [])

    const handleFetchLiveMarketData = React.useCallback(() => {
        // This is purely for user experience. Currently, the page is refreshed
        // in a very short time. We're waiting 500 milliseconds before we
        // refresh the page, so make users feel like it's actually refreshing.
        setShowLoadingPage(true)
        setTimeout(() => {
            if (instrument) {
                dispatch(actions.FetchLiveMarketData(instrument.id, portfolioId || defaultPortfolioId))
                setShowLoadingPage(false)
            }
        }, 300)
    }, [instrument ? instrument.id : null])

    if (liveMarketDataLoadingState === 'unavailable') {
        return (
            <>
                <Toolbar
                    dataTestId="toolbar--nzx-market-data-not-found"
                    title="Sorry, NZX market depth is not available right now..."
                />
                <Page overrideDefaultTopPadding="withToolbarTitle" className={notFoundStyles.notFound}>
                    {isWeekend && <p className={spacing.spaceAbove16}>Expected to be back on Monday morning.</p>}
                    <img src={errorBanana} alt="" />
                    <p>
                        Thanks for your patience while we suss it out. Get in touch with our <HelpCentreLink /> or just{' '}
                        <ButtonAsLink
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            go back
                        </ButtonAsLink>
                        .
                    </p>
                </Page>
            </>
        )
    }

    if (
        !instrument ||
        (liveMarketDataLoadingState === 'ready' && !liveMarketData) ||
        liveMarketDataLoadingState === 'error' ||
        (liveDataSubscriptionDetailsInitialised && !isSubscribedLiveData)
    ) {
        return <WeSlippedUp />
    }

    if (liveMarketDataLoadingState === 'loading') {
        return <Loading isPineapple />
    }

    const renderMatchPriceAndVolume = liveMarketData
        ? (liveMarketData.market_state === 'preopen' ||
              liveMarketData.market_state === 'preclose' ||
              liveMarketData.market_state === 'halt') &&
          liveMarketData.price_depth.bids.length > 0 &&
          liveMarketData.price_depth.offers.length > 0
        : false

    const isFromMidnightToOpen = liveMarketData
        ? (liveMarketData.market_state === 'closed' &&
              liveMarketData.server_time <
                  DateTime.local().setZone(NZ_TIME_ZONE).set({hour: 9, minute: 0, second: 0, millisecond: 0})) ||
          liveMarketData.market_state === 'preopen'
        : false

    const filterTodaysTrades = () => {
        if (!liveMarketData || !liveMarketData.recent_trades) {
            return
        }
        if (liveMarketData) {
            const todaysTrades = liveMarketData.recent_trades.filter(
                trade =>
                    trade.timestamp.toFormat(dateFormatNoTime) ===
                    (isFromMidnightToOpen
                        ? DateTime.local().setZone(NZ_TIME_ZONE).minus({days: 1}).toFormat(dateFormatNoTime)
                        : DateTime.local().setZone(NZ_TIME_ZONE).toFormat(dateFormatNoTime)),
            )
            setTodaysTrades(todaysTrades)
        }
    }

    const renderMarketState = () => {
        if (!liveMarketData) {
            return
        }
        switch (liveMarketData.market_state) {
            case 'preopen':
                return <p className={styles.marketState}>Pre-Open</p>
            case 'preclose':
                return <p className={styles.marketState}>Pre-Close</p>
            case 'halt':
                return <p className={styles.marketState}>Halt</p>
            case 'closed':
                return <p className={styles.marketState}>Closed</p>
            default:
                return
        }
    }

    return showLoadingPage ? (
        <Loading isPineapple />
    ) : (
        <>
            <Toolbar
                leftButton="back"
                dataTestId="toolbar--market-depth"
                title={instrument.name}
                rightSlot={renderMarketState()}
            />
            <Page withoutDefaultPadding className={styles.page}>
                {liveMarketData && (
                    <Header
                        instrument={instrument}
                        liveMarketData={liveMarketData}
                        fetchLiveMarketData={handleFetchLiveMarketData}
                        isFromMidnightToOpen={isFromMidnightToOpen}
                    />
                )}
                {liveMarketData && (
                    <OrderBook
                        buyArray={liveMarketData.price_depth.bids}
                        sellArray={liveMarketData.price_depth.offers}
                        matchPrice={renderMatchPriceAndVolume ? liveMarketData.match_price : undefined}
                        matchVolume={renderMatchPriceAndVolume ? liveMarketData.match_volume : undefined}
                    />
                )}
                {liveMarketData && todaysTrades && todaysTrades.length > 0 && (
                    <TodaysTrades
                        todaysTrades={todaysTrades}
                        vwapPrice={liveMarketData.vwap_price}
                        isFromMidnightToOpen={isFromMidnightToOpen}
                        todaysTotalAmount={liveMarketData.total_value}
                        todaysTotalShares={liveMarketData.total_volume}
                    />
                )}
            </Page>
            <Button
                onClick={e => {
                    e.preventDefault()
                    scrollToTop()
                }}
                dataTestId="button--scroll-to-top"
                label={<ArrowUp />}
                width="auto"
                ariaLabel="Scroll back up"
                additionalClassName={cn(styles.scrollToTopButton, {
                    [styles.buttonFadeIn]: showScrollToTopButton,
                    [styles.buttonFadeOut]: !showScrollToTopButton,
                })}
            />
        </>
    )
})

export default NzxMarketDepth
