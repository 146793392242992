import {DateTime} from 'luxon'
import {TimeOnMarketOptions} from '~/sections/moose/sections/order/state'

export const calculateTimeoutDate = (lengthOfTimeOnMarket: Omit<TimeOnMarketOptions, 'custom'>): DateTime => {
    let numberOfWorkingDaysRemaining = parseInt(lengthOfTimeOnMarket[0], 10)
    let timeoutDate = DateTime.now().setZone('Pacific/Auckland').endOf('day')
    do {
        switch (timeoutDate.weekday) {
            case 5: // Friday
                timeoutDate = timeoutDate.plus({days: 3})
                break
            case 6: // Saturday
                timeoutDate = timeoutDate.plus({days: 2})
                break
            default:
                timeoutDate = timeoutDate.plus({days: 1})
        }
        numberOfWorkingDaysRemaining -= 1
    } while (numberOfWorkingDaysRemaining > 0)

    return timeoutDate
}
