import React from 'react'
import {Link} from 'react-router-dom'
import {useRetailGet} from '~/api/query/retail'
import {Response} from '~/api/retail/types'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'

interface PrescribedPersonProps {
    owner: Response.Owner
    edit_url: 'settings/personal-details/prescribed-person' | 'settings/business-details/prescribed-person'
    section_class: string
}

const PrescribedPerson: React.FunctionComponent<PrescribedPersonProps> = ({owner, edit_url, section_class}) => {
    const profileUrl = useProfileUrl()
    const actingAsOther = owner.is_kids_account || !owner.is_self

    const {prescribed_participant, parent_is_prescribed} = useRetailGet({
        path: 'owner/:owner_id/prescribed-person-status',
        pathParams: {owner_id: owner.id},
    }).data

    return (
        <>
            <div className={section_class}>
                <h2>Prescribed Person</h2>

                {((!!prescribed_participant && !parent_is_prescribed) || !prescribed_participant) && (
                    <Link data-testid="link--prescribed-person" to={profileUrl(edit_url)}>
                        Edit
                    </Link>
                )}
            </div>
            {prescribed_participant ? (
                <p>
                    {actingAsOther
                        ? `${owner.display_name} is a Prescribed Person.`
                        : 'You’ve said you’re a Prescribed Person.'}
                </p>
            ) : (
                <p>
                    {actingAsOther
                        ? `${owner.display_name} is not a Prescribed Person.`
                        : 'You’ve said you’re not a Prescribed Person.'}
                </p>
            )}
        </>
    )
}

export default PrescribedPerson
