import {Modal} from '@design-system/modal'
import Decimal from 'decimal.js'
import React, {Dispatch} from 'react'
import {useActor} from '~/global/state-hooks/retail/useActor'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import HelpCentreLink from '~/global/widgets/help-centre-link/HelpCentreLink'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'

interface UnsettledFundsModalProps {
    modalOpen: boolean
    isSubmitting?: boolean
    availableBalanceRounded: Decimal
    unsettledRequested: Decimal
    setModalOpen: Dispatch<React.SetStateAction<boolean>>
    handleSubmit: () => void
    setFieldValue: (field: string, value: string) => void
}

export const UnsettledFundsModal = ({
    modalOpen,
    isSubmitting,
    availableBalanceRounded,
    unsettledRequested,
    setModalOpen,
    handleSubmit,
    setFieldValue,
}: UnsettledFundsModalProps) => {
    const {default_display_currency: displayCurrency} = useActor()

    return (
        <Modal
            title="Your money could take up to 5 business days to arrive"
            isOpen={modalOpen}
            setIsOpen={setModalOpen}
            dataTestId="unsettled-funds-withdrawal-modal"
            primaryButton={{label: 'Ok', disabled: isSubmitting, onClick: handleSubmit}}
        >
            <p>
                Your withdrawal request includes{' '}
                <DollarValue value={unsettledRequested.toString()} currency={displayCurrency} roundDown /> of{' '}
                <HelpCentreLink
                    nzArticle="813843-withdraw-money-from-your-wallet"
                    auArticle="4983255-withdraw-money-from-your-wallet"
                >
                    unsettled money
                </HelpCentreLink>
                . Once this settles, the full withdrawal amount will be on its way to you. 💸
            </p>
            {availableBalanceRounded.greaterThan(0) && (
                <p>
                    If you’d like to receive the settled money faster,{' '}
                    <ButtonAsLink
                        inline
                        onClick={() => {
                            setFieldValue('amount', availableBalanceRounded.toString())
                            setModalOpen(false)
                        }}
                    >
                        <>
                            change your withdrawal amount to{' '}
                            <DollarValue value={availableBalanceRounded.toString()} currency={displayCurrency} />
                        </>
                    </ButtonAsLink>{' '}
                    or under.
                </p>
            )}
        </Modal>
    )
}
