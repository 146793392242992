import React from 'react'
import config from '~/configForEnv'
import accountingActions from '~/store/accounting/actions'
import {useAppDispatch} from '~/store/hooks'
import ReFetch from './ReFetch'

const ExchangeRate = React.memo(() => {
    const dispatch = useAppDispatch()
    const getAllExchangeRates = () => dispatch(accountingActions.GetAllExchangeRates())

    return (
        <ReFetch
            fetchAction={getAllExchangeRates}
            timeoutPeriod={config.fxExchangeInterval} // 15 minutes
            doInitialFetch
        />
    )
})

export default ExchangeRate
