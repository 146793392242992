import {Button} from '@design-system/button'
import {SelectCards} from '@design-system/select-cards'
import Decimal from 'decimal.js'
import React from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {WalletBreakdownModal} from '~/global/widgets/wallet-breakdown-modal/WalletBreakdownModal'
import {WithdrawalOrder} from '~/sections/wallet/sections/withdrawals/Withdrawals'
import styles from '~/sections/wallet/sections/withdrawals/pages/select-withdrawal-method/SelectWithdrawalMethod.scss'
import {useFormattedFastFee} from '~/sections/wallet/sections/withdrawals/utils/use-formatted-fast-fee/useFormattedFastFee'
import {
    withdrawDollarValue,
    WithdrawDollarValue,
} from '~/sections/wallet/sections/withdrawals/widgets/withdraw-dollar-value/WithdrawDollarValue'
import {useHistoryState, useWalletPortfolio} from '~/sections/wallet/state/local'

export const SelectWithdrawalMethod = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const {id: walletPortfolioId} = useWalletPortfolio()
    const feeRate = useFormattedFastFee()
    const [withdrawalOrder, setWithdrawalOrder] = useHistoryState<WithdrawalOrder | undefined>(
        'withdrawalOrder',
        undefined,
    )
    const [showModal, setShowModal] = React.useState<boolean>(false)
    const [submitProcessing, setSubmitProcessing] = React.useState(false)
    const [withdrawalMethod, setWithdrawalMethod] = React.useState<WithdrawalOrder['method']>(withdrawalOrder?.method)

    if (!withdrawalOrder || !withdrawalOrder.withdrawal_cost) {
        return <Navigate to={profileUrl('wallet/:portfolioId/withdrawal', {portfolioId: walletPortfolioId})} replace />
    }

    const {amount, withdrawal_cost} = withdrawalOrder
    const amountUnsettled = new Decimal(withdrawal_cost.uncleared_amount).toDP(2, Decimal.ROUND_UP)
    const amountSettled = new Decimal(amount).minus(amountUnsettled).toDP(2, Decimal.ROUND_DOWN)

    const handleSubmit = () => {
        const updatedWithdrawalOrder = {
            ...withdrawalOrder,
            method: withdrawalMethod,
        }

        setSubmitProcessing(true)
        setWithdrawalOrder(updatedWithdrawalOrder)
        navigate(
            profileUrl('wallet/:portfolioId/withdrawal/confirm', {
                portfolioId: walletPortfolioId,
            }),
            {
                state: {
                    withdrawalOrder: updatedWithdrawalOrder,
                },
            },
        )
        setSubmitProcessing(false)
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--withdraw-method" leftButton="back" title="Want to get your money faster?" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <WalletBreakdownModal
                    isOpen={showModal}
                    setIsOpen={setShowModal}
                    walletPortfolioId={walletPortfolioId}
                />
                <div className={styles.explainerText}>
                    <p>
                        <WithdrawDollarValue value={amountUnsettled.toString()} /> of your{' '}
                        <WithdrawDollarValue value={amount} /> withdrawal needs to{' '}
                        <ButtonAsLink onClick={() => setShowModal(true)}>settle</ButtonAsLink> before we can send you
                        the money.
                    </p>
                    <p className={spacing.spaceAbove24}>
                        If you need the money sooner, for a fee we can pre-pay you the unsettled amount, and you’ll
                        receive your withdrawal in one business day.
                    </p>
                    {amountSettled.greaterThan(0) && (
                        <p className={spacing.spaceAbove24}>
                            Or, <ButtonAsLink onClick={() => navigate(-1)}>change your withdrawal</ButtonAsLink> to only
                            include settled money (<WithdrawDollarValue value={amountSettled.toString()} /> or under).
                        </p>
                    )}
                </div>

                <SelectCards
                    additionalClassName={spacing.spaceAbove24}
                    options={[
                        {
                            name: 'wait',
                            title: 'Wait 2–5 business days',
                            value: 'wait',
                            isActive: withdrawalMethod === 'wait',
                            supportingText: `No fee and the $${withdrawDollarValue(
                                amount.toString(),
                            )} will arrive in your bank account after settlement`,
                        },
                        {
                            name: 'fast',
                            title: 'Get your money in 1 business day',
                            value: 'fast',
                            isActive: withdrawalMethod === 'fast',
                            supportingText: `Pay a ${feeRate} fee on the $${withdrawDollarValue(
                                amountUnsettled.toString(),
                            )} that is unsettled and get your money faster`,
                        },
                    ]}
                    title="Choose a withdrawal timeframe"
                    hideTitle
                    dataTestId="select-cards--withdrawal-type"
                    onChange={e => setWithdrawalMethod(e.target.value as WithdrawalOrder['method'])}
                />
                <Button
                    dataTestId="button--fast-withdraw-confirm"
                    label="Review"
                    disabled={!withdrawalMethod}
                    processing={submitProcessing}
                    onClick={handleSubmit}
                    pageButton
                />
            </Page>
        </>
    )
}
