import {ModalLink} from '@design-system/modal'
import React from 'react'

const Illiquid: React.FunctionComponent = () => (
    <ModalLink
        dataTestId="modal-link--illiquid"
        label="Price updates for this investment might be limited"
        asIcon
        modalTitle="Price updates for this investment might be limited"
    >
        <p>The price you see is the most recent price we've received from the investment provider.</p>
        <p>
            To determine the price, the investment must be reviewed by an independent valuer. This process takes time,
            it could take as long as a year for the price to be updated (depending on the investment type and provider).
        </p>
    </ModalLink>
)

export default Illiquid
