import {colour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import cn from 'classnames'
import * as d3scale from 'd3-scale'
import * as d3shape from 'd3-shape'
import {DateTime} from 'luxon'
import React from 'react'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {Period} from '~/global/utils/time-period/timePeriod'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import withMeasure, {MeasureProps} from '~/global/widgets/with-measure/WithMeasure'
import {
    InstrumentHistoryItem,
    InstrumentHistoryItemWithIndex,
    sliceInstrumentHistory,
} from '~/store/accounting/selectors'
import {Exchange} from '~/store/instrument/types'
import {getPriceRange, useHover} from './instrumentChartCommon'
import styles from './InstrumentPerformanceChart.scss'

const AXIS_LABEL_WIDTH = 50
interface Props {
    startDate: string
    instrumentHistory: InstrumentHistoryItem[]
    exchange?: Exchange
    period: Period
    isListedInstrument: boolean
}

const calculateChartDimensions = (
    instrumentHistory: InstrumentHistoryItem[],
    startDate: string,
    width: number,
    height: number,
) => {
    let xMinValue = 0

    if (instrumentHistory[0] && instrumentHistory[0].date !== startDate) {
        xMinValue = -Math.round(
            (DateTime.fromISO(instrumentHistory[0].date).diff(DateTime.fromISO(startDate)).as('days') * 5) / 7,
        )
    }

    const xScale = d3scale
        .scaleLinear()
        .domain([xMinValue, instrumentHistory.length - 1])
        .range([AXIS_LABEL_WIDTH, width])
    const xHoverScale = d3scale
        .scaleLinear()
        .domain([xMinValue, instrumentHistory.length - 1])
        .range([AXIS_LABEL_WIDTH, width])

    const {minPrice, maxPrice} = getPriceRange(instrumentHistory)

    const yScale = d3scale
        .scaleLinear()
        .domain([maxPrice, minPrice])
        .range([80, height - 20])

    return {xScale, xHoverScale, yScale, minPrice, maxPrice}
}

const isAfterMarketClosed = (exchange: Exchange) => {
    const matchingDay = exchange.openHours.find(day => DateTime.local().day === day.start.toLocal().day)
    const exchangeFinishTimeRelative = matchingDay ? matchingDay.finish.toLocal() : undefined
    if (exchangeFinishTimeRelative) {
        // intentional 2 hours of padding added here to combat showing the close price prior to actually having it
        return DateTime.local() >= exchangeFinishTimeRelative!.plus({hours: 2})
    }
    return false
}

const InstrumentPerformanceChart: React.FC<Props & MeasureProps> = React.memo(
    ({
        measuredWidth,
        measuredHeight,
        instrumentHistory: fullInstrumentHistory,
        exchange,
        startDate,
        onMeasureRef,
        measuredLeft,
        isListedInstrument,
    }) => {
        const colourMode = useColourMode()
        // We only want the portion of instrumentHistory we need to render the selected period
        const instrumentHistory = React.useMemo(
            () => sliceInstrumentHistory(fullInstrumentHistory, startDate),
            [fullInstrumentHistory, startDate],
        )

        const firstOrderIndex = React.useMemo(() => {
            const index = instrumentHistory.findIndex(history => history.hasOrderEvent)
            const fullIndex = fullInstrumentHistory.findIndex(history => history.hasOrderEvent)
            if (index === -1 || fullIndex === -1) {
                // We couldn't find a first order
                return 0
            }
            if (instrumentHistory[index].date === fullInstrumentHistory[fullIndex].date) {
                // If the first visible order is really the first order, then that's
                // the first order index
                return index
            }
            // Otherwise we'll just use 0
            return 0
        }, [instrumentHistory, fullInstrumentHistory])

        const preHistory = React.useMemo(
            () => (firstOrderIndex === -1 ? instrumentHistory : instrumentHistory.slice(0, firstOrderIndex + 1)),
            [instrumentHistory, firstOrderIndex],
        )
        const postHistory = React.useMemo(
            () => (firstOrderIndex === -1 ? [] : instrumentHistory.slice(firstOrderIndex)),
            [instrumentHistory, firstOrderIndex],
        )

        // A simple algorithm for determining if the data has finished loading
        const isLoading = React.useMemo(
            () =>
                instrumentHistory.length === 0 ||
                instrumentHistory.filter(h => h.price === undefined).length / instrumentHistory.length > 0.2,
            [instrumentHistory],
        )

        // width/height are supplied by the withMeasure() higher order component
        // which measures the on-screen size of the <div /> container.
        const width = measuredWidth || 0
        const height = measuredHeight || 0
        const viewBox = `0 0 ${width} ${height}`
        const {minPrice, maxPrice, xScale, xHoverScale, yScale} = React.useMemo(
            () => calculateChartDimensions(instrumentHistory, startDate, width, height),
            [instrumentHistory, startDate, width, height],
        )

        // If we add .curve(d3shape.curveStep) we get a stepped version
        const areaPath = React.useMemo(
            () =>
                d3shape
                    .area<InstrumentHistoryItemWithIndex>()
                    .x(history => xScale(history.index))
                    .y1(history => yScale(history.price || 0))
                    .y0(height),
            [xScale, yScale],
        )

        const linePath = React.useMemo(
            () =>
                d3shape
                    .line<InstrumentHistoryItemWithIndex>()
                    .x(history => xScale(history.index))
                    .y(history => yScale(history.price || 0))
                    .defined(history => !history.priceIsEstimate),
            [xScale, yScale],
        )

        const unLinePath = React.useMemo(
            () =>
                d3shape
                    .line<InstrumentHistoryItemWithIndex>()
                    .x(history => xScale(history.index))
                    .y(history => yScale(history.price || 0))
                    .defined(history => history.priceIsEstimate),
            [xScale, yScale],
        )

        let minPriceLine = 0
        let midPrice = 0
        let midPriceLine = 0
        let maxPriceLine = 0

        if (!isLoading) {
            minPriceLine = yScale(minPrice)
            midPrice = minPrice + (maxPrice - minPrice) / 2
            midPriceLine = yScale(midPrice)
            maxPriceLine = yScale(maxPrice)
        }

        const leftLabel = DateTime.fromISO(startDate).toFormat(dateFormatNoTime)

        const preArea = React.useMemo(() => areaPath(preHistory) || '', [preHistory, areaPath])
        const preLine = React.useMemo(() => linePath(preHistory) || '', [preHistory, linePath])
        const preUnline = React.useMemo(() => unLinePath(preHistory) || '', [preHistory, unLinePath])
        const postArea = React.useMemo(() => areaPath(postHistory) || '', [preHistory, areaPath])
        const postLine = React.useMemo(() => linePath(postHistory) || '', [postHistory, linePath])
        const postUnline = React.useMemo(() => unLinePath(postHistory) || '', [postHistory, unLinePath])

        const {hoverDay, handlerProps} = useHover(measuredLeft || 0, xScale, instrumentHistory.length - 1)
        const hoverDayValue = instrumentHistory[hoverDay]

        const renderHover = (): React.ReactNode => {
            if (!hoverDay || !hoverDayValue) {
                return
            }

            const x = xHoverScale(hoverDay)
            const labelWidth = 84

            const labelOverrun = 8
            let labelX = x - labelWidth / 2

            if (labelX < -labelOverrun) {
                labelX = -labelOverrun
            }

            if (labelX + labelWidth > width + labelOverrun) {
                labelX = width - labelWidth + labelOverrun
            }

            const afterMarketClosed = exchange ? isAfterMarketClosed(exchange) : false
            const dateString = DateTime.fromISO(hoverDayValue.date).toFormat(dateFormatNoTime)
            const arrowY = 17
            const isToday = DateTime.local().hasSame(DateTime.fromISO(hoverDayValue.date), 'day')

            const showLabel = isListedInstrument && (!isToday || afterMarketClosed)
            const boxStyles = showLabel ? styles.box : cn(styles.box, styles.todayUnClosed)

            return (
                <div className={styles.hover} style={{height}}>
                    <div
                        className={cn(styles.line, {[styles.managedFund]: !isListedInstrument})}
                        style={{
                            width: 1,
                            height: height - (isListedInstrument ? 14 : 8) - arrowY - 37,
                            transform: `translate(${x}px, ${arrowY + 37}px)`,
                        }}
                    />

                    <div className={boxStyles} style={{transform: `translateX(${labelX}px)`}}>
                        {showLabel && <div>Close price</div>}
                        <div>
                            {hoverDayValue.price ? <DollarValue value={hoverDayValue.price} decimalPlaces={3} /> : '-'}
                        </div>
                        <div>{dateString}</div>
                    </div>

                    {hoverDayValue.hasOrderEvent ? (
                        <svg
                            className={styles.circle}
                            style={{
                                width: 33,
                                height: 33,
                                transform: `translate(${x - 15.5}px, ${yScale(hoverDayValue.price!) - 31}px)`,
                            }}
                        >
                            <circle cx="16" cy="16" r="15.5" />
                            <circle cx="16" cy="16" r="7.5" />
                        </svg>
                    ) : null}
                    <svg
                        className={styles.arrow}
                        style={{width: 9, height: 9, transform: `translate(${x - 4}px, ${arrowY}px)`}}
                    >
                        <path d="M 0 0 V 4.5 L 4.5 8.5 L 8.5 4.5 V 0 Z" />
                        <path d="M 0 4.5 L 4.5 8.5 L 8.5 4.5" />
                    </svg>
                </div>
            )
        }

        if (isLoading) {
            return (
                <div className={styles.loadingContainer}>
                    <Loading />
                </div>
            )
        }

        return (
            <div className={styles.outerContainer}>
                <div className={styles.container} ref={onMeasureRef} {...handlerProps}>
                    <svg viewBox={viewBox}>
                        <linearGradient id="pre-gradient" x1="0" x2="0" y1="0" y2="1">
                            <stop offset="0%" stopColor={colour('NeutralAlpha100', colourMode)} stopOpacity="0.4" />
                            <stop offset="100%" stopColor={colour('NeutralAlpha50', colourMode)} stopOpacity="0.4" />
                        </linearGradient>
                        <linearGradient id="post-gradient" x1="0" x2="0" y1="0" y2="1">
                            <stop offset="10%" stopColor={colour('Melon500', colourMode)} stopOpacity="0.4" />
                            <stop offset="100%" stopColor={colour('Melon50', colourMode)} stopOpacity="0.4" />
                        </linearGradient>

                        {!isLoading && (
                            <>
                                <line
                                    className={styles.centerLine}
                                    x1={AXIS_LABEL_WIDTH}
                                    y1={minPriceLine}
                                    x2={width}
                                    y2={minPriceLine}
                                />
                                <text x="0" y={minPriceLine} className={styles.price}>
                                    ${minPrice.toFixed(3)}
                                </text>
                                <line
                                    className={styles.centerLine}
                                    x1={AXIS_LABEL_WIDTH}
                                    y1={midPriceLine}
                                    x2={width}
                                    y2={midPriceLine}
                                />
                                <text x="0" y={midPriceLine} className={styles.price}>
                                    ${midPrice.toFixed(3)}
                                </text>
                                <line
                                    className={styles.centerLine}
                                    x1={AXIS_LABEL_WIDTH}
                                    y1={maxPriceLine}
                                    x2={width}
                                    y2={maxPriceLine}
                                />
                                <text x="0" y={maxPriceLine} className={styles.price}>
                                    ${maxPrice.toFixed(3)}
                                </text>

                                <path d={preArea} fill="url(#pre-gradient)" />
                                <path className={styles.preUnline} d={preUnline} />
                                <path className={styles.preLine} d={preLine} />

                                <path d={postArea} fill="url(#post-gradient)" />
                                <path className={styles.postUnline} d={postUnline} />
                                <path className={styles.postLine} d={postLine} />
                            </>
                        )}
                    </svg>
                    <div className={styles.leftDate}>{leftLabel}</div>
                    <div className={styles.rightDate}>Today</div>
                    <svg viewBox={viewBox} className={styles.circleContainer}>
                        {instrumentHistory
                            .filter(f => f.hasOrderEvent)
                            .map(history => (
                                <circle
                                    key={history.index}
                                    className={cn(styles.order, {
                                        [styles.hidden]: history.index === hoverDay,
                                    })}
                                    cx={xScale(history.index)}
                                    cy={yScale(history.price!)}
                                    r={4.5}
                                />
                            ))}
                    </svg>
                    {renderHover()}
                </div>
            </div>
        )
    },
)

export default withMeasure<Props & MeasureProps>(InstrumentPerformanceChart)
