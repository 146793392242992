import {Response} from '~/api/retail/types'
import humaniseList from '~/global/utils/humanise-list/humaniseList'

export type NatureAndPurposeStep = 'intro' | 'frequency' | 'annualAmount' | 'purpose' | null

export const getNextStep = (step: NatureAndPurposeStep, skipIntro?: boolean): NatureAndPurposeStep => {
    switch (step) {
        case 'intro':
            return 'frequency'
        case 'frequency':
            return 'annualAmount'
        case 'annualAmount':
            return 'purpose'
    }
    return skipIntro ? 'frequency' : 'intro'
}

export const getPreviousStep = (step: NatureAndPurposeStep, skipIntro?: boolean): NatureAndPurposeStep => {
    switch (step) {
        case 'frequency':
            if (!skipIntro) {
                return 'intro'
            }
            return step
        case 'annualAmount':
            return 'frequency'
        case 'purpose':
            return 'annualAmount'
    }
    return skipIntro ? 'frequency' : 'intro'
}

export const humaniseNature = (
    frequency: Response.OwnerNatureAndPurpose['frequency'] | undefined,
    annualAmount: Response.OwnerNatureAndPurpose['annual_amount'] | undefined,
) => {
    const frequencyText = frequency
    const amountLabel = annualAmountChoices.find(c => c.value === annualAmount)?.label

    let annualAmountText
    // don't display if they're undecided on an annual amount
    if (annualAmount && amountLabel && annualAmount !== 'undecided') {
        const amountIsRange = amountLabel.includes('–')
        annualAmountText = `${amountIsRange ? 'between' : ''} ${amountLabel}`
    }

    return [frequencyText, annualAmountText].filter(text => text !== undefined).join(', ') + '.'
}

const mapPurposesToLabels = (purposes: Purpose[]) => {
    return purposes
        .filter(purpose => purpose !== 'other' && purpose !== 'fun') // don't display 'other' or 'fun'
        .map(purpose => purposesChoicesForOrganisation.find(p => p.value === purpose)!.label.toLowerCase())
}

export const humanisePurposes = (purposes: Purpose[]) => {
    const purposeLabels = mapPurposesToLabels(purposes)
    if (purposeLabels.length > 0) {
        return `You’ve told us you’re: ${humaniseList(purposeLabels)}.`
    }
    return ''
}

export const humaniseOtherPurpose = (purposes: Purpose[], otherPurpose?: string) => {
    const purposeLabels = mapPurposesToLabels(purposes)

    if (purposeLabels.length > 0) {
        return `Other investing reason: ${otherPurpose}`
    }
    // the only purpose in puposes list is 'other'
    return `You’ve told us you’re investing for this reason: ${otherPurpose}`
}

export const frequencyChoices: {
    value: Response.OwnerNatureAndPurpose['frequency']
    label: string
    helpText: string
}[] = [
    {value: 'regularly', label: 'Regularly', helpText: 'Weekly to fortnightly'},
    {value: 'often', label: 'Often', helpText: 'Monthly or a few times a year'},
    {value: 'occasionally', label: 'Occasionally', helpText: 'Once or twice a year'},
]

export const annualAmountChoices: {
    value: Response.OwnerNatureAndPurpose['annual_amount']
    label: string
}[] = [
    {value: '0-3000', label: 'Up to $3,000'},
    {value: '3000-25000', label: '$3,000 – $25,000'},
    {value: '25000-100000', label: '$25,000 – $100,000'},
    {value: '100000-', label: 'More than $100,000'},
    {value: 'undecided', label: 'I don’t know yet'},
]

export type Purpose =
    | 'building_portfolio'
    | 'diversify'
    | 'retirement'
    | 'property'
    | 'learning'
    | 'savings'
    | 'kiwisaver_account'
    | 'insurance'
    | 'fun'
    | 'kids_account'
    | 'savings'
    | 'kiwisaver_account'
    | 'insurance'
    | 'monitor_share_price'
    | 'manage_holdings'
    | 'other'

export const purposesChoices: {
    value: Purpose
    label: string
}[] = [
    {value: 'building_portfolio', label: 'Building an investment portfolio'},
    {value: 'savings', label: 'Growing my savings'},
    {value: 'kiwisaver_account', label: 'Joining the Sharesies KiwiSaver Scheme'},
    {value: 'retirement', label: 'Prepping for retirement'},
    {value: 'property', label: 'Saving for my first home'},
    {value: 'kids_account', label: 'Building wealth for a kid or dependent'},
    {value: 'diversify', label: 'Diversifying my wealth'},
    {value: 'learning', label: 'Learning how to build wealth'},
    {value: 'insurance', label: 'Insuring my assets'},
    {value: 'other', label: "I've got another reason"},
]

export const AUPurposesChoices: {
    value: Purpose
    label: string
}[] = [
    {value: 'building_portfolio', label: 'Building an investment portfolio'},
    {value: 'retirement', label: 'Prepping for retirement'},
    {value: 'property', label: 'Saving for my first home'},
    {value: 'diversify', label: 'Diversifying my wealth'},
    {value: 'kids_account', label: 'Building wealth for a kid or dependent'},
    {value: 'learning', label: 'Learning how to build wealth'},
    {value: 'other', label: "I've got another reason"},
]

export const purposesChoicesForOrganisation: {
    value: Purpose
    label: string
}[] = purposesChoices.slice(0, -1).concat([
    {value: 'monitor_share_price', label: 'Monitoring share prices'},
    {value: 'manage_holdings', label: 'Manage holdings for compliance reasons'},
    {value: 'other', label: "I've got another reason"},
])
