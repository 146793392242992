import {Button} from '@design-system/button'
import cn from 'classnames'
import {Formik} from 'formik'
import {useAtom} from 'jotai'
import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing, page} from '~/global/scss/helpers'
import {Email, Select, validate} from '~/global/widgets/form-controls'
import signupStyles from '~/sections/moose/sections/sign-up/SignUp.scss'
import {PrescribedPersonForEntity, StagedSignUp, stagedSignUpAtom} from '~/sections/moose/sections/sign-up/state'
import {useSignupEntityDetail} from '~/sections/moose/sections/sign-up/utils/sign-up-detail-hooks/signUpDetailHooks'
import styles from '~/sections/user/sections/sign-up/pages/prescribed-person/PrescribedPerson.scss'

const PrescribedPersonForm = ({
    progressStep,
    participants,
}: {
    signupId: string
    progressStep: () => void
    participants: string[]
}) => {
    const signupEntityDetail = useSignupEntityDetail()
    const hasMultipleEntities = signupEntityDetail.entities.length > 1

    const [stagedSignUp, updateStagedSignUp] = useAtom(stagedSignUpAtom)

    // prefill form with existing state if there is one
    const initialValues = stagedSignUp.prescribedPerson
        ? stagedSignUp.prescribedPerson
        : [
              ...signupEntityDetail.entities.map(e => {
                  return {partyId: e.party_id} as PrescribedPersonForEntity
              }),
          ]

    const showNext = (answers: PrescribedPersonForEntity[]) => {
        const prescribedPersonAnswers = answers.map(a => a.prescribedPerson)
        return prescribedPersonAnswers.indexOf(undefined) < 0
    }

    const rudderTrackLabel = (answers: PrescribedPersonForEntity[]) => {
        if (answers.every(answer => answer.participant !== undefined)) {
            return 'yes'
        }

        if (answers.every(answer => answer.participant === undefined)) {
            return 'no'
        }

        return 'multiple'
    }

    return (
        <Formik
            initialValues={{answers: initialValues}}
            initialErrors={stagedSignUp.prescribedPerson ? undefined : {answers: []}}
            onSubmit={({answers}) => {
                const newStagedSignUp: StagedSignUp = {...stagedSignUp, prescribedPerson: answers}
                updateStagedSignUp(newStagedSignUp)
                progressStep()
                rudderTrack('fonterra_onboarding', 'prescribed_person_clicked', {
                    label: rudderTrackLabel(answers),
                })
            }}
            validate={async values => {
                const errors = {
                    answers: Array(values.answers.length),
                }

                let hasError = false
                // validate all fields for all entities
                for (const index in values.answers) {
                    const entityValues = values.answers[index]
                    const error = await validate.generate({
                        prescribedPerson: [validate.required()],
                        participant: entityValues.prescribedPerson ? [validate.required()] : [],
                        prescribedEmail:
                            entityValues.prescribedPerson && entityValues.participant !== 'Sharesies Limited'
                                ? [validate.required(), validate.email()]
                                : [],
                    })({
                        prescribedPerson: entityValues.prescribedPerson,
                        participant: entityValues.participant,
                        prescribedEmail: entityValues.prescribedEmail,
                    })

                    if (Object.keys(error).length > 0) {
                        errors.answers[index] = error
                        hasError = true
                    }
                }

                return hasError ? errors : undefined
            }}
        >
            {({values, isValid, isSubmitting, handleSubmit, setValues}) => (
                <div className={spacing.spaceAbove24}>
                    <form onSubmit={handleSubmit}>
                        {signupEntityDetail.entities.map(entity => {
                            const entityValues = values.answers.find(v => v.partyId === entity.party_id)!
                            const showParticipant = entityValues.prescribedPerson
                            const showPrescribedEmail =
                                showParticipant &&
                                entityValues.participant &&
                                entityValues.participant !== 'Sharesies Limited'

                            return (
                                <div
                                    key={entity.party_id}
                                    className={cn(hasMultipleEntities && signupStyles.card, spacing.spaceAbove24)}
                                >
                                    {hasMultipleEntities && (
                                        <h2 className={signupStyles.cardSubHeading}>{entity.name}</h2>
                                    )}

                                    {/** PARTICIPANT YES/NO */}
                                    <div className={cn(spacing.spaceAbove16, page.flexRow)}>
                                        <span>
                                            <Button
                                                label="Yes"
                                                dataTestId={`button--yes-${entity.party_id}`}
                                                type={entityValues.prescribedPerson === true ? 'primary' : 'secondary'}
                                                onClick={() => {
                                                    entityValues.prescribedPerson = true
                                                    setValues(values)
                                                }}
                                            />
                                        </span>
                                        <span>
                                            <Button
                                                label="No"
                                                dataTestId={`button--no-${entity.party_id}`}
                                                type={entityValues.prescribedPerson === false ? 'primary' : 'secondary'}
                                                onClick={() => {
                                                    entityValues.prescribedPerson = false

                                                    setValues(values)
                                                }}
                                            />
                                        </span>
                                    </div>

                                    {/** WHICH PARTICIPANT */}
                                    {showParticipant && (
                                        <Select
                                            dataTestId={`select--participant-${entity.party_id}`}
                                            additionalClassName={spacing.spaceAbove24}
                                            name="participant"
                                            label="Which NZX Trading and Advising Firm?"
                                            placeholder="Select a company"
                                            choices={[{value: '', label: ''}].concat(
                                                participants.map(p => ({value: p, label: p})),
                                            )}
                                            value={entityValues.participant}
                                            onChange={event => {
                                                entityValues.participant = event.target.value
                                                setValues(values)
                                            }}
                                            isTouched
                                        />
                                    )}

                                    {/** PRESCRIBED EMAIL */}
                                    {showPrescribedEmail && (
                                        <>
                                            <p>
                                                <strong className={styles.heading}>Your investment activity</strong>
                                            </p>
                                            <p className={spacing.spaceBelow16}>
                                                If {entity.name} is a Prescribed Person, we’ll need to email the
                                                business’s investing activity to {entityValues.participant}.
                                            </p>
                                            <Email
                                                dataTestId={`input--participant-email-${entity.party_id}`}
                                                isTouched
                                                name="prescribedEmail"
                                                label="Email investing activity to"
                                                helpText={`Email address for ${entityValues.participant}.`}
                                                value={entityValues.prescribedEmail}
                                                onChange={event => {
                                                    entityValues.prescribedEmail = event.target.value
                                                    setValues(values)
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                            )
                        })}
                        {showNext(values.answers) && (
                            <Button
                                dataTestId="button--fonterra-confirm-prescribed-person"
                                label="Next"
                                disabled={!isValid}
                                processing={isSubmitting}
                                pageButton
                                isSubmit
                            />
                        )}
                    </form>
                </div>
            )}
        </Formik>
    )
}

export default PrescribedPersonForm
