/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    ListingResponseDto,
    ListingResponseDtoFromJSON,
    ListingResponseDtoFromJSONTyped,
    ListingResponseDtoToJSON,
    ListingResponseDtoToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface SearchResponseDto
 */
export interface SearchResponseDto {
    /**
     *
     * @type {number}
     * @memberof SearchResponseDto
     */
    total: number
    /**
     *
     * @type {number}
     * @memberof SearchResponseDto
     */
    currentPage: number
    /**
     *
     * @type {number}
     * @memberof SearchResponseDto
     */
    resultsPerPage: number
    /**
     *
     * @type {number}
     * @memberof SearchResponseDto
     */
    numberOfPages: number
    /**
     *
     * @type {Array<ListingResponseDto>}
     * @memberof SearchResponseDto
     */
    instruments: Array<ListingResponseDto>
}

export function SearchResponseDtoFromJSON(json: any): SearchResponseDto {
    return SearchResponseDtoFromJSONTyped(json, false)
}

export function SearchResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResponseDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        total: json['total'],
        currentPage: json['currentPage'],
        resultsPerPage: json['resultsPerPage'],
        numberOfPages: json['numberOfPages'],
        instruments: (json['instruments'] as Array<any>).map(ListingResponseDtoFromJSON),
    }
}

export function SearchResponseDtoToJSON(value?: SearchResponseDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        total: value.total,
        currentPage: value.currentPage,
        resultsPerPage: value.resultsPerPage,
        numberOfPages: value.numberOfPages,
        instruments: (value.instruments as Array<any>).map(ListingResponseDtoToJSON),
    }
}
