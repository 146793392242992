import {Button} from '@design-system/button'
import cn from 'classnames'
import {withFormik} from 'formik'
import React from 'react'
import {urlFor} from '~/global/routeGenerator'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {APINetworkError, NonRollbarError, UserSuitableError} from '~/global/utils/error-handling/errorHandling'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {validate, ErrorBox} from '~/global/widgets/form-controls'
import {Email, Password, Checkbox, Number} from '~/global/widgets/form-controls/formik'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {Link} from '~/migrate-react-router'
import {DispatchProps} from '~/sections/user/pages/login/Login'
import {DownloadAppBanner} from '~/sections/user/widgets/download-app-banner/DownloadAppBanner'

export const REMEMBER_EMAIL_KEY = 'rememberedLoginEmail'
const MFA_STATUS = '__mfa_required__'
const EMAIL_MFA_STATUS = '__email_mfa_required__'
export const IOS_APP_STORE_URL = 'https://apps.apple.com/au/app/sharesies-easy-investing/id1571805487'
export const ANDROID_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=nz.sharesies.app'

interface FormProps extends DispatchProps {
    initialEmail?: string
    showSignUpCallToAction: boolean
    hideDownloadAppBanner?: boolean
    disablePageButtonStyles?: boolean
}

interface FormValues {
    email: string
    password: string
    remember: boolean
    mfa_token: string
    email_mfa_token: string
}

export const LoginForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: ({initialEmail}) => ({
        email: initialEmail || '',
        password: '',
        remember: !!initialEmail,
        mfa_token: '',
        email_mfa_token: '',
    }),
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        email: undefined,
    }),
    handleSubmit: async (
        {email, password, remember, mfa_token, email_mfa_token},
        {setSubmitting, setStatus, setErrors, props: {doLogin}},
    ) => {
        if (remember) {
            try {
                localStorage.setItem(REMEMBER_EMAIL_KEY, email)
            } catch (ignore) {
                // Ignore failures when setting or removing item, worst case they don't
                // get remember
            }
        } else {
            localStorage.removeItem(REMEMBER_EMAIL_KEY)
        }
        try {
            const user = await doLogin(email, password, remember, mfa_token, email_mfa_token)
            if (user === 'mfa_required') {
                setStatus(MFA_STATUS)
                setSubmitting(false)
                return
            }
            if (user === 'mfa_invalid_token') {
                setStatus(MFA_STATUS)
                setErrors({mfa_token: 'Your code does not match, or has expired'})
                setSubmitting(false)
                return
            }
            if (user === 'email_mfa_required') {
                setStatus(EMAIL_MFA_STATUS)
                setSubmitting(false)
                return
            }
            if (user === 'email_mfa_invalid_token') {
                setStatus(EMAIL_MFA_STATUS)
                setErrors({email_mfa_token: 'Your code does not match, or has expired'})
                setSubmitting(false)
                return
            }

            if (!user) {
                setStatus('Yeah, nah. That’s not a valid email or password.')
                setSubmitting(false)
                return
            }
        } catch (e: unknown) {
            if (
                e instanceof Error ||
                e instanceof UserSuitableError ||
                e instanceof APINetworkError ||
                e instanceof NonRollbarError
            ) {
                if (e.message === 'account_restricted') {
                    setStatus(
                        "You’ve put in the wrong password too many times. Select 'forgot your password' to reset it.",
                    )
                } else {
                    setStatus(unknownErrorMessage)
                }
                setSubmitting(false)
            }
            throw e
        }
    },
    validate: validate.generate<FormValues>({
        email: [validate.required(), validate.email()],
        password: [validate.required()],
        remember: [],
        mfa_token: [],
        email_mfa_token: [],
    }),
})(({
    status,
    handleSubmit,
    initialEmail,
    isValid,
    isSubmitting,
    showSignUpCallToAction,
    hideDownloadAppBanner,
    disablePageButtonStyles,
}) => {
    return (
        <>
            <form onSubmit={handleSubmit}>
                {status === MFA_STATUS ? (
                    <>
                        <h2 className={cn(spacing.spaceBelow16, typographyOverrides['as-h2'])}>
                            Enter the 6-digit verification code found in your authenticator app
                        </h2>
                        <Number
                            dataTestId="number-input--mfa-token"
                            name="mfa_token"
                            label="Code"
                            autoFocus
                            placeholder="000000"
                            helpText={
                                <p className={spacing.spaceBelow8}>
                                    Locked out? Check out the{' '}
                                    <HelpCentreLink nzArticle="5427741-reset-two-factor-authentication-2fa" /> or{' '}
                                    <a href="mailto:help@sharesies.com" target="_blank" rel="noopener">
                                        get in touch
                                    </a>
                                    .
                                </p>
                            }
                        />
                        <Button
                            additionalClassName={spacing.spaceAbove32}
                            label="Confirm"
                            dataTestId="login"
                            isSubmit
                            disabled={!isValid}
                            processing={isSubmitting}
                        />
                    </>
                ) : status === EMAIL_MFA_STATUS ? (
                    <>
                        <h2 className={cn(spacing.spaceBelow16, typographyOverrides['as-h2'])}>
                            Enter the 6-digit code we have emailed you
                        </h2>
                        <p className={cn(spacing.spaceBelow8)}>
                            We haven't seen you login from this browser or location before. To keep your account safe
                            we've emailed you a 6 digit code to enter below.
                        </p>
                        <Number
                            dataTestId="number-input--email-mfa-token"
                            name="email_mfa_token"
                            label="Code"
                            autoFocus
                            placeholder="000000"
                            helpText={<div>Resend code</div>}
                        />
                        <p className={cn(spacing.spaceBelow8)}>
                            Still having trouble? Try the <HelpCentreLink nzArticle="#" /> or{' '}
                            <a href="mailto:help@sharesies.com" target="_blank" rel="noopener">
                                get in touch
                            </a>
                            .
                        </p>
                        <Button
                            additionalClassName={spacing.spaceAbove32}
                            label="Confirm"
                            dataTestId="login"
                            isSubmit
                            disabled={!isValid}
                            processing={isSubmitting}
                        />
                    </>
                ) : (
                    <>
                        <h2 className={cn(spacing.spaceBelow16, typographyOverrides['as-h2'])}>Log in to Sharesies</h2>
                        <Email dataTestId="login-email-input" name="email" label="Email" autoFocus={!initialEmail} />
                        <Password
                            dataTestId="password"
                            name="password"
                            label="Password"
                            helpText={
                                <Link data-testid="forgot-password" to={urlFor('forgot-password')}>
                                    Forgot your password?
                                </Link>
                            }
                        />
                        <div className={spacing.spaceAbove24}>
                            <ErrorBox message={status} />
                            <Checkbox
                                dataTestId="checkbox--keep-me-logged-in"
                                name="remember"
                                label="Keep me logged in"
                            />
                        </div>

                        {showSignUpCallToAction && (
                            <p>
                                Don’t have an account?{' '}
                                <Link data-testid="link--sign-up" to={urlFor('sign-up')}>
                                    Sign up
                                </Link>
                            </p>
                        )}

                        {!hideDownloadAppBanner && (
                            <DownloadAppBanner
                                appleAppStoreUrl={IOS_APP_STORE_URL}
                                googlePlayStoreUrl={ANDROID_PLAY_STORE_URL}
                            />
                        )}

                        <Button
                            label="Log in"
                            dataTestId="login"
                            pageButton={!disablePageButtonStyles}
                            isSubmit
                            disabled={!isValid}
                            processing={isSubmitting}
                        />
                    </>
                )}
            </form>
        </>
    )
})
