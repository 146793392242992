import {semanticColour} from '@design-system/colour-tokens'
import {HelpCircle} from '@design-system/icon'
import {ModalLink} from '@design-system/modal'
import {RawButton} from '@design-system/raw-button'
import {useColourMode} from '@design-system/use-colour-mode'
import Decimal from 'decimal.js'
import {useField} from 'formik'
import React from 'react'
import {Link} from 'react-router-dom'
import {useActor} from '~/global/state-hooks/retail/useActor'
import {useWalletBreakdown} from '~/global/state-hooks/retail/useWalletBreakdown'
import {roundWithdrawalValue} from '~/global/utils/round-withdrawal-value/roundWithdrawalValue'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {StrongCurrency} from '~/global/widgets/form-controls/formik'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {DollarValue, WalletValue} from '~/global/widgets/number-elements/NumberElements'
import {WalletBreakdownModal} from '~/global/widgets/wallet-breakdown-modal/WalletBreakdownModal'
import {useLegacyUserData} from '~/sections/wallet/state/retail'
import styles from './WalletAmountInput.scss'

interface WhyDifferentModalLinkProps {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    foreignCurrencyBalance: Decimal
    restrictedBalance: Decimal
    handleWithdrawAll?: () => void
}

const WhyDifferentModalLink: React.FunctionComponent<WhyDifferentModalLinkProps> = ({
    isOpen,
    setIsOpen,
    foreignCurrencyBalance,
    restrictedBalance,
    handleWithdrawAll,
}) => {
    const showFullText = !(foreignCurrencyBalance.isZero() && restrictedBalance.isZero())

    return showFullText ? (
        <ButtonAsLink className={styles.helpTextLink} onClick={() => setIsOpen(!isOpen)}>
            Why is this less than my Wallet balance?
        </ButtonAsLink>
    ) : (
        <>
            <RawButton
                additionalClassName={styles.helpTextButton}
                label={<HelpCircle size={14} />}
                title="Show breakdown"
                dataTestId="wallet-breakdown-modal"
                onClick={() => setIsOpen(!isOpen)}
            />
            {handleWithdrawAll && (
                <ButtonAsLink className={styles.helpTextLink} onClick={handleWithdrawAll}>
                    Withdraw all
                </ButtonAsLink>
            )}
        </>
    )
}

interface WhyDifferentModalLinkSaveProps {
    amountUnusedGiftOrPromo: number
    handleTransferAll: () => void
    hasFX: boolean
    hasUnsettled: boolean
    hasUnusedGiftOrPromo: boolean
    jurisdiction: string
}

const WhyDifferentModalLinkSave: React.FunctionComponent<WhyDifferentModalLinkSaveProps> = ({
    amountUnusedGiftOrPromo,
    handleTransferAll,
    hasFX,
    hasUnsettled,
    hasUnusedGiftOrPromo,
    jurisdiction,
}) => {
    const profileUrl = useProfileUrl()
    const colourMode = useColourMode()

    if (!(hasFX || hasUnsettled || hasUnusedGiftOrPromo)) {
        return (
            <ButtonAsLink onClick={() => handleTransferAll()} className={styles.helpTextLink}>
                Transfer all
            </ButtonAsLink>
        )
    }
    const currency = jurisdiction === 'nz' ? 'NZD' : 'AUD'
    const hasMultiple = [hasUnusedGiftOrPromo, hasUnsettled, hasFX].filter(Boolean).length > 1

    let modalTitle = ''
    if (hasMultiple) {
        // If it has two or more, fall back to the generic case.
        modalTitle = `Some of your money can’t be transferred just yet`
    } else if (hasUnsettled) {
        modalTitle = `Some of your money hasn’t settled`
    } else if (hasFX) {
        modalTitle = `You can only transfer ${currency} from your Wallet`
    } else if (hasUnusedGiftOrPromo) {
        modalTitle = `Some of your money is from a Gift, referral, or promo`
    }

    const helpLink = (
        <p>
            For more info,{' '}
            <HelpCentreLink nzArticle="7834824-top-up-transfer-or-withdraw-money-from-your-save-account">
                check out our help centre
            </HelpCentreLink>
            .
        </p>
    )

    const currencyExchangeLink = <Link to={profileUrl('wallet/exchange-money')}>exchanged to {currency}</Link>

    const multipleText = (
        <>
            <p>
                If you’ve recently sold shares or topped up by card, your money needs to settle before you can transfer
                it to Save.
            </p>
            <p>
                Money from a sale usually takes two business days to settle. Money from a card top-up can take between
                one hour and two business days to settle.
            </p>
            {hasUnusedGiftOrPromo && (
                <p>
                    Money you’ve received from a Gift, referral, or promo (currently{' '}
                    <DollarValue value={amountUnusedGiftOrPromo} />) needs to be invested then sold before it can be
                    transferred.
                </p>
            )}
            {hasFX && <p>Other currencies need to be {currencyExchangeLink} before being transferred.</p>}
            {helpLink}
        </>
    )

    const modalTextUnsettled = (
        <>
            <p>
                If you’ve recently sold shares or topped up by card, your money needs to settle before you can transfer
                it to Save.
            </p>
            <p>
                Money from a sale usually takes two business days to settle. Money from a card top-up can take between
                one hour and two business days to settle.
            </p>
            {helpLink}
        </>
    )

    const modalTextUnusedGiftOrPromo = (
        <p>
            The <DollarValue value={amountUnusedGiftOrPromo} /> you’ve received from a Gift, referral, or promo needs to
            be invested then sold before being transferred to Save.
        </p>
    )
    const modalTextFX = <p>Other currencies need to be {currencyExchangeLink} before being transferred to Save.</p>

    const textToShow = () => {
        if (hasMultiple) {
            return multipleText
        }
        if (hasUnsettled) {
            return modalTextUnsettled
        }
        if (hasFX) {
            return modalTextFX
        }
        if (hasUnusedGiftOrPromo) {
            return modalTextUnusedGiftOrPromo
        }
    }

    return (
        <ModalLink
            dataTestId="modal-link--estimated-settlement-date"
            label="Why is this different to my Wallet?"
            modalTitle={modalTitle}
            primaryButton={{label: 'Ok, got it'}}
            asIcon={false}
            helpIconSize={14}
            helpIconColour={semanticColour('IconSubtle', colourMode)}
            bottomBorder
            additionalClassName={styles.helpTextLinkModal}
        >
            {textToShow()}
        </ModalLink>
    )
}

interface WalletAmountInputProps {
    walletPortfolioId: string
    dataTestId: string
    hideAvailable?: boolean
    isDisabled?: boolean
    isForSave?: boolean
}

export const WalletAmountInput = ({
    dataTestId,
    hideAvailable,
    isDisabled,
    isForSave,
    walletPortfolioId,
}: WalletAmountInputProps) => {
    const [showModal, setShowModal] = React.useState<boolean>(false)

    const {jurisdiction} = useLegacyUserData()
    const withdrawalAmounts = useWalletBreakdown(walletPortfolioId)
    const {default_display_currency: displayCurrency} = useActor()

    const availableBalance = isForSave ? withdrawalAmounts.available_balance : withdrawalAmounts.withdrawable_balance
    const amountUnusedGiftsOrPromos = Number(withdrawalAmounts.restricted_balance)
    const hasUnusedGiftsOrPromos = amountUnusedGiftsOrPromos > 0
    const amountUnsettled = withdrawalAmounts.uncleared_balance
    const hasUnsettled = Number(amountUnsettled) !== 0

    // Check if customer has some accessible foreign currency
    // and the amount is at least 0.01
    const hasFX = Number(withdrawalAmounts.foreign_currency_balance) >= 0.01

    const [{value}, _, {setValue}] = useField('amount')

    const transferAll = () => setValue(roundWithdrawalValue(availableBalance).toString())

    const withdrawAll =
        value && roundWithdrawalValue(value).equals(roundWithdrawalValue(availableBalance)) ? undefined : transferAll

    const totalBalanceIsZero =
        new Decimal(withdrawalAmounts.withdrawable_balance).toDP(2).isZero() &&
        new Decimal(withdrawalAmounts.foreign_currency_balance).toDP(2).isZero() &&
        new Decimal(withdrawalAmounts.restricted_balance).toDP(2).isZero()

    return (
        <>
            <WalletBreakdownModal isOpen={showModal} setIsOpen={setShowModal} walletPortfolioId={walletPortfolioId} />
            <StrongCurrency
                currency={displayCurrency}
                dataTestId={dataTestId}
                name="amount"
                label="Amount"
                helpText={
                    availableBalance &&
                    !hideAvailable && (
                        <p className={styles.helpText}>
                            <span>
                                {displayCurrency.toUpperCase()} available:{' '}
                                <strong>
                                    <WalletValue value={availableBalance} />
                                </strong>
                            </span>
                            {!totalBalanceIsZero &&
                                (isForSave ? (
                                    <WhyDifferentModalLinkSave
                                        amountUnusedGiftOrPromo={amountUnusedGiftsOrPromos}
                                        hasUnsettled={hasUnsettled}
                                        hasFX={hasFX}
                                        hasUnusedGiftOrPromo={hasUnusedGiftsOrPromos}
                                        jurisdiction={jurisdiction}
                                        handleTransferAll={transferAll}
                                    />
                                ) : (
                                    <WhyDifferentModalLink
                                        setIsOpen={setShowModal}
                                        isOpen={showModal}
                                        foreignCurrencyBalance={new Decimal(withdrawalAmounts.foreign_currency_balance)}
                                        restrictedBalance={new Decimal(withdrawalAmounts.restricted_balance)}
                                        handleWithdrawAll={withdrawAll}
                                    />
                                ))}
                        </p>
                    )
                }
                disabled={isDisabled}
                placeholder="0.00"
            />
        </>
    )
}
