import {Button} from '@design-system/button'
import {Formik} from 'formik'
import React from 'react'
import {useRetailPost} from '~/api/query/retail'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {validate as v} from '~/global/widgets/form-controls'
import {IRDNumber, Radio} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {
    SignUpGuardRedirect,
    SignUpPage,
} from '~/sections/kiwisaver/sections/sign-up/widgets/redirect/SignUpGuardRedirect'
import {useKSCustomerOrNull} from '~/sections/kiwisaver/state'
import {PageTitle} from '~/sections/kiwisaver/widgets/page-title/PageTitle'
import {useAppSelector} from '~/store/hooks'
import {actingAsID as actingAsIDSelector} from '~/store/identity/selectors'

interface TaxValues {
    pir: '10.5' | '17.5' | '28'
    ird_number: string
}

const pirChoices: {value: TaxValues['pir']; label: string}[] = [
    {value: '10.5', label: '10.5%'},
    {value: '17.5', label: '17.5%'},
    {value: '28', label: '28%'},
]

export const TaxInformationForm: React.FunctionComponent = () => {
    const customer = useKSCustomerOrNull()
    const setUpdateTaxDetails = useRetailPost({
        path: 'kiwisaver/update-tax-details',
        queryCacheToUpdate: ['kiwisaver/customer'],
    })
    const existingPir = customer?.pir
    const existingIrdNumber = customer?.ird_number
    const actingAsID = useAppSelector(actingAsIDSelector)

    return (
        <SignUpGuardRedirect currentPage={SignUpPage.GET_TAX}>
            <Toolbar dataTestId="toolbar--get-tax-information" leftButton="back" />
            <Formik
                initialValues={{
                    pir: (existingPir || '') as TaxValues['pir'],
                    ird_number: existingIrdNumber || '',
                }}
                validate={v.generate<TaxValues>({
                    pir: [v.required(), v.choices('10.5', '17.5', '28')],
                    ird_number: [v.required(), v.irdNumber()],
                })}
                onSubmit={async ({pir, ird_number}, {setSubmitting}) => {
                    try {
                        rudderTrack('kiwisaver_signup', 'tax_details_confirmed')
                        await setUpdateTaxDetails.mutateAsync({
                            acting_as_id: actingAsID,
                            pir,
                            ird_number,
                        })
                    } catch (e) {
                        Toast('Please try again')
                    } finally {
                        setSubmitting(false)
                    }
                }}
            >
                {formik => (
                    <form onSubmit={formik.handleSubmit}>
                        <Page>
                            <PageTitle>We need some tax info</PageTitle>
                            <p className={spacing.spaceBelow16}>
                                Sharesies calculates NZ tax for you so we need some information to make sure we pay it
                                to the right account. For more details check out the help centre.
                            </p>
                            <Radio dataTestId="radio--pir" name="pir" label="Select your PIR" choices={pirChoices} />
                            <IRDNumber dataTestId="text-input--ird-number" name="ird_number" label="IRD number" />
                        </Page>
                        <ActionBar>
                            <Button
                                dataTestId="button--next"
                                label="Next"
                                type="primary"
                                isSubmit
                                processing={formik.isSubmitting}
                                disabled={!formik.isValid}
                            />
                        </ActionBar>
                    </form>
                )}
            </Formik>
        </SignUpGuardRedirect>
    )
}
