import {colour, semanticColour} from '@design-system/colour-tokens'
import {StarEmpty, StarFilled} from '@design-system/icon'
import {useColourMode} from '@design-system/use-colour-mode'
import cn from 'classnames'
import React from 'react'
import {useWatchlist} from '~/global/state-hooks/retail/useWatchlist'
import {isIOSWrapperApp} from '~/global/utils/is-wrapper-app/isWrapperApp'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Toast} from '~/global/widgets/toast/Toast'
import {useNavigate} from '~/migrate-react-router'
import {InvestTab} from '~/sections/explore/widgets/watchlist/state/WatchlistContext'
import {
    trackRemoveFromWatchlist,
    trackAddToWatchList,
} from '~/sections/explore/widgets/watchlist/utils/track-watchlist-actions/trackWatchlistActions'
import styles from './WatchlistButton.scss'

interface WatchlistButtonProps {
    instrumentId: string
    instrumentName: string
    setTabName?(tabName: InvestTab): void
    onWatchlisted?: () => void
}

export default ({instrumentId, instrumentName, setTabName, onWatchlisted}: WatchlistButtonProps) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const {watchlistInstrumentIds, addToWatchlist, removeFromWatchlist} = useWatchlist()
    // local state to handle the success animation
    const [isInWatchlist, setIsInWatchlist] = React.useState(watchlistInstrumentIds.includes(instrumentId))

    // handling the watchlist update locally to change icons before the API response
    const [isFilledStar, setIsFilledStar] = React.useState(false)
    const watchlistLimit = 500

    React.useEffect(() => {
        // Initial render, instrument ID changes, and watchlist length
        // changing when a price notification is set
        if (watchlistInstrumentIds.includes(instrumentId)) {
            setIsFilledStar(true)
        } else {
            setIsFilledStar(false)
        }
    }, [instrumentId, watchlistInstrumentIds.length])

    const onSuccessMessageClick = () => {
        navigate(profileUrl('explore/watchlist'))

        // changing tab if it's from the recently viewed tab
        if (setTabName) {
            setTabName('Watchlist')
        }
    }

    const colourMode = useColourMode()

    return (
        <button
            className={styles.watchlistButton}
            type="button"
            role="checkbox"
            aria-checked={isFilledStar}
            title={isFilledStar ? 'Remove from Watchlist' : 'Add to Watchlist'}
            onClick={() => {
                if (isFilledStar) {
                    setIsInWatchlist(false)
                    setIsFilledStar(false)
                    Toast('Removed from Watchlist')

                    removeFromWatchlist(instrumentId)
                    trackRemoveFromWatchlist(instrumentName)
                }

                if (!isFilledStar && watchlistInstrumentIds.length >= watchlistLimit) {
                    // watchlist limit
                    Toast('You’ve maxed out your Watchlist! 👀 Remove some investments before you add more.')
                }

                if (!isFilledStar && watchlistInstrumentIds.length < watchlistLimit) {
                    setIsInWatchlist(true)
                    setIsFilledStar(true)
                    if (isIOSWrapperApp()) {
                        Toast('Added to Watchlist')
                    } else {
                        Toast('Added to Watchlist', onSuccessMessageClick)
                    }

                    addToWatchlist(instrumentId)
                    trackAddToWatchList(instrumentName)
                    onWatchlisted?.()
                }
            }}
        >
            <StarFilled
                colour={colour('Pineapple500', colourMode)}
                className={cn(styles.icon, {
                    [styles.show]: isFilledStar,
                    [styles.isInWatchlist]: isInWatchlist,
                })}
            />
            <StarEmpty
                colour={semanticColour('IconDefault', colourMode)}
                className={cn(styles.icon, {
                    [styles.show]: !isFilledStar,
                })}
            />
        </button>
    )
}
