import cn from 'classnames'
import React from 'react'
import withMeasure, {MeasureProps} from '~/global/widgets/with-measure/WithMeasure'
import styles from './OrderTable.scss'

interface Order {
    price: string
    size: string
    num_of_orders: string
}

interface ShareBarProps {
    type: 'buy' | 'sell'
    highestNumberOfShares: number
    order: Order
}
const ShareBar = ({type, order, highestNumberOfShares, onMeasureRef, measuredWidth}: MeasureProps & ShareBarProps) => {
    return (
        <td ref={onMeasureRef}>
            <div
                className={cn(styles.numberOfSharesBar, {
                    [styles.offersNumberOfSharesBar]: type === 'sell',
                    [styles.bidsNumberOfSharesBar]: type === 'buy',
                })}
                style={{
                    width:
                        measuredWidth && (parseFloat(order.size) / highestNumberOfShares) * measuredWidth < 2
                            ? '2px' // Set a minimum width
                            : `${(parseFloat(order.size) / highestNumberOfShares) * 100}%`,
                }}
            />
        </td>
    )
}

const MeasuredShareBar = withMeasure(ShareBar)

export default MeasuredShareBar
