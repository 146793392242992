import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {spacing} from '~/global/scss/helpers'
import headerStyles from '~/global/scss/reused-styles/pageHeader.scss'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import insureLarge from '~/sections/protect/sections/insure/assets/images/insure-large.png'
import styles from '~/sections/protect/sections/insure/sections/landing/Landing.scss'
import {CoveConnectionErrorAlertCard} from '~/sections/protect/sections/insure/widgets/cove-connection-error-alert-card/CoveConnectionErrorAlertCard'
import {useAppSelector} from '~/store/hooks'

export const CoveDown: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const toolbarHiddenForMobile = useAppSelector(s => s.nav.toolbarHidden)

    return (
        <div className={styles.background}>
            <Toolbar
                dataTestId="toolbar--insure-landing"
                leftButton="back"
                onLeftButtonClick={() => navigate(profileUrl(''))}
                showNotifications
            />
            <Page withoutDefaultPadding>
                <div className={styles.background}>
                    <div
                        className={headerStyles.landingImageWrapper}
                        style={{paddingTop: toolbarHiddenForMobile ? '25px' : '0px'}}
                    >
                        <img src={insureLarge} alt="" className={headerStyles.landingImage} />
                    </div>

                    <div className={headerStyles.landingContentWrapper}>
                        <h1
                            data-testid="title--insure-landing"
                            className={cn(headerStyles.title, spacing.spaceBelow32)}
                        >
                            Insurance
                        </h1>

                        <CoveConnectionErrorAlertCard />
                    </div>
                </div>
            </Page>
        </div>
    )
}
