import {colour, semanticColour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import React from 'react'
import {DollarValue, PercentValue} from '~/global/widgets/number-elements/NumberElements'
import {
    KiwiSaverChartProps,
    getDecimalPlacesToShowOnChart,
} from '~/sections/kiwisaver/sections/home/utils/chart-helpers/chartHelpers'
import styles from './KiwiSaverChartValues.scss'

const KiwiSaverChartValues: React.FC<KiwiSaverChartProps> = ({historyItem}) => {
    const colourMode = useColourMode()
    const totalReturn = (): number => {
        if (!historyItem) {
            return 0
        }
        return historyItem.total_return
    }
    const costBasis = historyItem ? historyItem.cost_basis : 0
    const percentageReturn = totalReturn() && costBasis ? (totalReturn() / costBasis) * 100 : 0
    const totalReturnDecimalPlaces = getDecimalPlacesToShowOnChart(totalReturn())
    const costBasisDecimalPlaces = getDecimalPlacesToShowOnChart(costBasis)
    const ariaText = (): string => {
        if (totalReturn() > 0) {
            return 'Arrow showing a positive return'
        } else if (totalReturn() < 0) {
            return 'Arrow showing a negative return'
        } else {
            return 'Arrow showing no change in return'
        }
    }

    return (
        <>
            <div className={styles.numbersRow}>
                <div>
                    <div className={styles.amountNumberAlign}>
                        <label>Amount put in</label>
                        <div className={styles.numbersRowValue}>
                            <DollarValue value={costBasis} decimalPlaces={costBasisDecimalPlaces} />
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.amountNumberAlign}>
                        <label>Return after fees &amp; tax</label>
                        <div className={styles.numbersRowValue}>
                            {/* The following svg controls the positive/negative return indicator*/}
                            <svg
                                role="img"
                                aria-label={ariaText()}
                                width="9"
                                height="8"
                                viewBox="0 0 9 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{transform: totalReturn() < 0 ? 'rotate(180deg)' : ''}}
                            >
                                <path
                                    d="M4.5 0L9 8H0L4.5 0Z"
                                    fill={
                                        totalReturn() > 0
                                            ? semanticColour('SuccessPrimary', colourMode)
                                            : totalReturn() < 0
                                              ? colour('Orange500', colourMode)
                                              : colour('Neutral300', colourMode)
                                    }
                                />
                            </svg>
                            <DollarValue value={totalReturn()} decimalPlaces={totalReturnDecimalPlaces} />
                            <span className={styles.partition}>|</span>
                            <PercentValue value={percentageReturn} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default KiwiSaverChartValues
