import Decimal from 'decimal.js'
import {rakaiaGetFactory} from '~/api/query/rakaia'
import {Portfolios} from '~/api/rakaia'

/**
 * Use the Rakaia portfolio endpoint to get a single portfolio's value as a
 * Decimal. If the portfolioUuid is not supplied, a Decimal of 0 will be returned.
 *
 * @example
 * const portfolioValue = usePortfolioValues('1234')
 */
export const usePortfolioValue = (portfolioUuid?: string) => {
    const {data: allPortfolioSummaries} = rakaiaGetFactory({
        apiFunctionName: 'portfoliosSummaryApiV1PortfoliosSummaryAllGet',
        options: {enabled: !!portfolioUuid},
    })(undefined) // no params needed

    // if the query wasn't enabled, return undefined
    if (!portfolioUuid) {
        return undefined
    }

    // if we couldn't find the right portfolio or the value isn't present (note 0 is a valid value), return undefined
    const portfolioValue = allPortfolioSummaries.portfolios[portfolioUuid]?.portfolio_value
    if (portfolioValue === undefined) {
        return undefined
    }

    return new Decimal(portfolioValue)
}

/**
 * Use the Rakaia portfolio endpoint to get multiple portfolio's values as an
 * array. If no portfolioUuid are provided, [] will be returned.
 *
 * @example
 * const portfolioValues = usePortfolioValues(['1234', '4567'])
 */
export const usePortfolioValues = (portfolioUuids: string[]) => {
    const values: Decimal[] = []
    const {data: allPortfolioSummaries} = rakaiaGetFactory({
        apiFunctionName: 'portfoliosSummaryApiV1PortfoliosSummaryAllGet',
        options: {enabled: portfolioUuids.length > 0},
        notFoundResponse: () => ({portfolios: {}}) as Portfolios,
    })(undefined) // no params needed

    // if the query wasn't enabled, return an empty array early to prevent error
    if (!allPortfolioSummaries) {
        return []
    }

    // the returned portfolio summary might include those from another profile, wee need to narrow it down
    for (const key in allPortfolioSummaries.portfolios) {
        if (portfolioUuids.includes(allPortfolioSummaries.portfolios[key].uuid)) {
            values.push(new Decimal(allPortfolioSummaries.portfolios[key].portfolio_value || 0))
        }
    }

    return values
}
