import cn from 'classnames'
import React from 'react'
import {accessibility, spacing} from '~/global/scss/helpers'
import {convertDecimalToPercentage} from '~/global/utils/convert-decimal-to-percentage/convertDecimalToPercentage'
import {isInstrumentDerivative} from '~/global/utils/is-instrument-derivative/isInstrumentDerivative'
import {ChevronRight} from '~/global/widgets/OLD_icons'
import DetailModal from '~/global/widgets/detail-modals/DetailModal'
import DividendDetail from '~/global/widgets/detail-modals/DividendDetail'
import MarketCapitalisationDetail from '~/global/widgets/detail-modals/MarketCapitalisationDetail'
import PERatioDetail from '~/global/widgets/detail-modals/PERatioDetail'
import {Loading} from '~/global/widgets/loading'
import {PercentValue, DollarValue} from '~/global/widgets/number-elements/NumberElements'
import labelledValuesStyles from '~/sections/invest/sections/view-instrument/LabelledValues.scss'
import {Instrument} from '~/store/instrument/types'

type DetailTopic = null | 'dividends' | 'p/e ratio' | 'market cap'

const Stats: React.FunctionComponent<StatsProps> = ({instrument, setHideFooter}) => {
    const [detailTopic, showDetailModal] = React.useState<DetailTopic>(null)
    const handleDetailClick = (detail: DetailTopic) => {
        showDetailModal(detail)
        if (setHideFooter) {
            setHideFooter(true)
        }
    }

    const NoData = () => (
        <p className={labelledValuesStyles.noData}>
            No data
            <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
        </p>
    )

    return (
        <>
            <section className={cn(labelledValuesStyles.container, spacing.spaceAbove16, labelledValuesStyles.border)}>
                {!isInstrumentDerivative(instrument) && (
                    <>
                        <div>
                            <p className={labelledValuesStyles.label}>Gross dividend yield</p>
                            <button
                                className={cn(labelledValuesStyles.value, accessibility.button)}
                                onClick={() => handleDetailClick('dividends')}
                                role="link"
                                type="button"
                                data-testid="button--dividends-detail-modal"
                            >
                                {instrument.grossDividendYieldPercent ? (
                                    <p>
                                        <PercentValue
                                            value={convertDecimalToPercentage(instrument.grossDividendYieldPercent)}
                                        />
                                        <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                                    </p>
                                ) : (
                                    <NoData />
                                )}
                            </button>
                        </div>
                        <div>
                            <p className={labelledValuesStyles.label}>P/E ratio</p>
                            <button
                                className={cn(labelledValuesStyles.value, accessibility.button)}
                                onClick={() => handleDetailClick('p/e ratio')}
                                role="link"
                                type="button"
                            >
                                {instrument.peRatio && Number(instrument.peRatio) !== 0 ? (
                                    <p>
                                        {instrument.peRatio}
                                        <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                                    </p>
                                ) : (
                                    <NoData />
                                )}
                            </button>
                        </div>
                        <div>
                            <p className={labelledValuesStyles.label}>Market cap</p>
                            <button
                                className={cn(labelledValuesStyles.value, accessibility.button)}
                                onClick={() => handleDetailClick('market cap')}
                                role="link"
                                type="button"
                            >
                                {instrument.marketCap ? (
                                    <p>
                                        <DollarValue value={instrument.marketCap} />
                                        <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                                    </p>
                                ) : (
                                    <NoData />
                                )}
                            </button>
                        </div>
                    </>
                )}
            </section>

            <DetailModal
                isOpen={detailTopic != null}
                closeHandler={() => {
                    showDetailModal(null)
                    if (setHideFooter) {
                        setHideFooter(false)
                    }
                }}
            >
                <React.Suspense fallback={<Loading />}>
                    {detailTopic === 'dividends' && <DividendDetail instrument={instrument} />}
                    {detailTopic === 'p/e ratio' && <PERatioDetail />}
                    {detailTopic === 'market cap' && <MarketCapitalisationDetail />}
                </React.Suspense>
            </DetailModal>
        </>
    )
}

interface StatsProps {
    instrument: Instrument
    setHideFooter?: (isHidden: boolean) => void
}

export default Stats
