import {Modal} from '@design-system/modal'
import React from 'react'
import {useAppSelector} from '~/store/hooks'
import styles from './OrderCoverageModal.scss'

interface ModalProps {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const BuyOrderCoverageModal: React.FunctionComponent<ModalProps> = ({isOpen, setIsOpen}) => {
    const homeCurrency = useAppSelector(({identity}) => identity.user!.home_currency)
    const nonHomeCurrencies = ['usd', 'nzd', 'aud'].filter(currency => currency !== homeCurrency)

    // String like "USD or AUD" or "USD or NZD"
    const otherCurrencies = nonHomeCurrencies.map(c => c.toUpperCase()).join(' or ')

    return (
        <Modal
            dataTestId="modal--how-buy-coverage-works"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title="How buy order coverage works"
            primaryButton={{label: 'Got it'}}
            content={
                <div className={styles.modalContent}>
                    <p>
                        When you place a buy or auto-invest order, we’ll calculate the highest amount it could fill at,
                        and look to deduct the equivalent amount from your available coverage. We might deduct less if
                        your order amount is higher than the amount of coverage you have left.
                    </p>
                    <p>
                        Once your coverage is used up, the 1.9% transaction fee (and per order fee caps) apply to all
                        orders until your plan renews.
                    </p>

                    <p>
                        Coverage is deducted from the plan that’s active at the time an order was placed. While your
                        order is pending, the deducted coverage amount won’t be used for any other orders.
                    </p>

                    <p>
                        If you’ve already got an order on-market when you start a plan, it won’t be covered by your
                        plan. Instead, the 1.9% transaction fee (and per order fee caps) will apply.
                    </p>
                    <h2>Orders in {otherCurrencies}</h2>

                    <p>
                        We work out how much to deduct from your coverage by converting your order amount to{' '}
                        {homeCurrency.toUpperCase()}, and deduct the equivalent {homeCurrency.toUpperCase()} amount of{' '}
                        coverage. This happens at the time you place your order.
                    </p>

                    <h2>Returning Coverage</h2>
                    <p>We might return some (or all) of the deducted coverage amount when your order:</p>
                    <ul>
                        <li>
                            fills—if the amount we deducted isn’t fully used when your order fills, we’ll return the
                            leftover amount to your coverage
                        </li>
                        <li>
                            expires—the amount we deducted for the expired part of your order will be returned to your
                            coverage
                        </li>
                        <li>
                            is cancelled—the amount we deducted for the cancelled part of your order will be returned to
                            your coverage.
                        </li>
                    </ul>
                    <p>
                        Any returned amounts will go back to the coverage for the month they originally came from. If
                        that month has passed, you won’t receive that coverage amount back.
                    </p>
                    <p>
                        If we’re returning coverage from an order in {otherCurrencies} to you, we’ll convert the
                        coverage amount at the time we make the return.
                    </p>
                    <p>
                        If you have coverage returned to you during the month of your plan, you’ll be able to use it
                        until your plan renews.
                    </p>

                    <h2>Partial coverage order</h2>

                    <p>
                        A partial coverage order is when some of your order uses your remaining available coverage, and
                        the rest is charged the 1.9% transaction fee (up to the per order fee caps). This can happen
                        when your order amount is higher than your remaining coverage.
                    </p>
                    <p>
                        If the part of your partial order charged the 1.9% transaction fee reaches a fee cap, we’ll
                        return any coverage that would’ve been used.
                    </p>
                </div>
            }
        />
    )
}

export default BuyOrderCoverageModal
