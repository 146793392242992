import {ModalLink} from '@design-system/modal'
import React from 'react'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {isUSExchange} from '~/global/utils/share-transfers/shareTransfers'
import {Loading} from '~/global/widgets/loading'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/transfer/actions'
import {ResponseUsTransferPlatform, StagedTransferOrder} from '~/store/transfer/types'
import {DollarValue} from '../number-elements/NumberElements'

interface TransferOutProps {
    exchange: Model.TransferOrderIn['exchange']
    platform?: ResponseUsTransferPlatform
}

export const TransferOutFees = ({exchange, platform}: TransferOutProps) => {
    const dispatch = useAppDispatch()

    React.useEffect(() => {
        dispatch(actions.FetchInfo('out', platform))
    }, [])

    const transferInfo = useAppSelector(s => s.transfer.transferInfo)

    if (!transferInfo) {
        return <Loading />
    }

    const feeDescription = () => {
        if (!isUSExchange(exchange)) {
            return (
                <>
                    <p className={spacing.spaceBelow12}>
                        There’s a fee for transferring shares listed on the {exchange} out of Sharesies.
                    </p>
                    <p className={spacing.spaceBelow12}>
                        It’s{' '}
                        <DollarValue
                            value={transferInfo.fees[exchange].charge_amount}
                            decimalPlaces={0}
                            currency={transferInfo.fees[exchange].charge_currency}
                        />{' '}
                        for each investment you transfer within a request.
                    </p>
                    <p className={spacing.spaceBelow12}>
                        The money needs to be in your Wallet at the time you submit your transfer request.
                    </p>
                </>
            )
        }

        if (platform === 'EXTERNAL') {
            // Charge amount per instrument
            return (
                <p>
                    US transfers out cost{' '}
                    <DollarValue value={transferInfo.fees[exchange].charge_amount} decimalPlaces={0} currency="USD" />{' '}
                    for each investment.
                </p>
            )
        } else {
            // Charge amount for the whole transfer group (not per instrument)
            return (
                <p>
                    US transfers out cost{' '}
                    <DollarValue value={transferInfo.fees[exchange].charge_amount} decimalPlaces={0} currency="USD" />{' '}
                    for each transfer.
                </p>
            )
        }
    }

    return (
        <ModalLink
            dataTestId="modal-link--transfer-out-fees"
            label="transfer fees"
            asIcon
            modalTitle="Share transfer fee"
            primaryButton={{label: 'Got it'}}
            helpIconSize={16}
        >
            <p>{feeDescription()}</p>
        </ModalLink>
    )
}

interface TransferInProps {
    exchange: NonNullable<StagedTransferOrder['exchange']>
    platform?: ResponseUsTransferPlatform
}

export const TransferInFees = ({platform, exchange}: TransferInProps) => {
    const dispatch = useAppDispatch()

    React.useEffect(() => {
        dispatch(actions.FetchInfo('in', platform))
    }, [])

    const transferInfo = useAppSelector(s => s.transfer.transferInfo)

    if (!transferInfo) {
        return <Loading />
    }

    const feeDescription = () => {
        if (!isUSExchange(exchange)) {
            return <p> It’s free to transfer in. </p>
        }

        if (platform === 'EXTERNAL') {
            // Charge amount per instrument
            return (
                <p>
                    US transfers in cost{' '}
                    <DollarValue value={transferInfo.fees[exchange].charge_amount} decimalPlaces={0} currency="USD" />{' '}
                    for each investment.
                </p>
            )
        } else {
            // Charge amount for the whole transfer group (not per instrument)
            return (
                <p>
                    US transfers in cost{' '}
                    <DollarValue value={transferInfo.fees[exchange].charge_amount} decimalPlaces={0} currency="USD" />{' '}
                    for each transfer.
                </p>
            )
        }
    }

    return (
        <ModalLink
            dataTestId="modal-link--transfer-in-fees"
            label="transfer fees"
            asIcon
            modalTitle="Transfer fees"
            primaryButton={{label: 'Got it'}}
            helpIconSize={16}
        >
            {feeDescription()}
        </ModalLink>
    )
}
