import {assertNever} from '~/global/utils/assert-never/assertNever'
import {ActionsType} from './actions'
import {State} from './types'

const initialState: State = {
    createGiftAmount: null,
    purchasedGift: null,
}

function reducer(state: State = initialState, action: ActionsType): State {
    switch (action.type) {
        case 'gift.SetCreateGiftAmount':
            return {...state, createGiftAmount: action.payload}
        case 'gift.SetPurchasedGift':
            return {...state, purchasedGift: action.payload}
        default:
            assertNever(action)
    }
    return state
}

export default reducer
