import {Button} from '@design-system/button'
import {Elements, ElementsConsumer, CardNumberElement} from '@stripe/react-stripe-js'
import {loadStripe, Stripe, StripeElements} from '@stripe/stripe-js'
import {Formik} from 'formik'
import React from 'react'
import * as rollbar from '~/api/rollbar/rollbar'
import config from '~/configForEnv'
import stripeFont from '~/global/assets/fonts/stripe-font'
import {spacing} from '~/global/scss/helpers'
import {UserSuitableError} from '~/global/utils/error-handling/errorHandling'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import use3DSecure from '~/global/utils/use3-d-secure/use3DSecure'
import amex from '~/global/widgets/credit-card-handling/assets/images/amex.svg'
import dinersclub from '~/global/widgets/credit-card-handling/assets/images/dinersclub.svg'
import discover from '~/global/widgets/credit-card-handling/assets/images/discover.svg'
import jcb from '~/global/widgets/credit-card-handling/assets/images/jcb.svg'
import mastercard from '~/global/widgets/credit-card-handling/assets/images/mastercard.svg'
import unionpay from '~/global/widgets/credit-card-handling/assets/images/unionpay.svg'
import visa from '~/global/widgets/credit-card-handling/assets/images/visa.svg'
import {ErrorBox, validate} from '~/global/widgets/form-controls'
import formStyles from '~/global/widgets/form-controls/form.scss'
import {Text, StripeCardNumber, StripeExpiryDate, StripeCVC} from '~/global/widgets/form-controls/formik'
import {StripeFieldValue, stripeFieldValueDefault} from '~/global/widgets/form-controls/stripe/StripeElements'
import styles from './CardForm.scss'

const cardImages = [visa, amex, mastercard, discover, jcb, dinersclub, unionpay]

interface InjectedStripeProps {
    stripe: Stripe | undefined
    elements: StripeElements | undefined
    currency: 'nzd' | 'aud'
}

interface CardFormProps<T> {
    jurisdiction: 'au' | 'nz'
    preChildren?: React.ReactNode
    children?: React.ReactNode
    childrenDefaultValues?: T
    submitLabel: string
    onSubmit: (paymentMethodId: string, resetPaymentMethodIUdvalues?: T) => Promise<void>
}
interface CardFormValues {
    number: StripeFieldValue
    name: string
    expiry: StripeFieldValue
    cvc: StripeFieldValue
    [key: string]: any
}
function CardForm({
    jurisdiction,
    children,
    preChildren,
    childrenDefaultValues,
    submitLabel,
    stripe,
    elements,
    onSubmit,
}: CardFormProps<any> & InjectedStripeProps) {
    const {createPaymentMethod} = use3DSecure(jurisdiction, stripe)

    return (
        <Formik
            data-testid="form--credit-card"
            initialValues={{
                ...childrenDefaultValues,
                number: {...stripeFieldValueDefault},
                name: '',
                expiry: {...stripeFieldValueDefault},
                cvc: {...stripeFieldValueDefault},
            }}
            validate={validate.generate<CardFormValues>({
                number: [validate.required(), validate.stripeField()],
                name: [validate.required()],
                cvc: [validate.required(), validate.stripeField()],
                expiry: [validate.required(), validate.stripeField()],
            })}
            onSubmit={async ({number, name, expiry, cvc, ...childValues}, {setStatus, setSubmitting}) => {
                setStatus()
                if (!stripe || !elements) {
                    throw new Error('No stripe object')
                }

                try {
                    const cardElement = elements.getElement(CardNumberElement)
                    if (!cardElement) {
                        throw new Error('No stripe object')
                    }
                    setSubmitting(true)

                    const paymentMethodId = await createPaymentMethod(cardElement)
                    await onSubmit(paymentMethodId, childValues)
                } catch (err) {
                    setSubmitting(false)
                    if (err instanceof UserSuitableError) {
                        setStatus(err.message)
                        return
                    } else if (err instanceof Error) {
                        setStatus(unknownErrorMessage)
                        rollbar.sendError(err, {
                            statusText: err.message,
                            method: 'CardTopupError',
                        })
                    }
                    setStatus(unknownErrorMessage)
                    throw err
                }
            }}
        >
            {({isValid, isSubmitting, status, handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    {preChildren}
                    <p className={spacing.spaceBelow16}>We accept:</p>
                    <div className={styles.acceptedCardList}>
                        {cardImages.map((ci, i) => (
                            <img key={i} src={ci} />
                        ))}
                    </div>
                    <StripeCardNumber name="number" label="Card number" dataTestId="input--stripe-card-number" />
                    <Text
                        dataTestId="text-input--name-on-card"
                        label="Name on card"
                        name="name"
                        additionalClassName={styles.nameOnCardInput}
                    />
                    <div className={formStyles.row}>
                        <StripeExpiryDate label="Expiry date" name="expiry" dataTestId="input--stripe-expiry" />
                        <StripeCVC label="CVC" name="cvc" dataTestId="input--stripe-cvc" />
                    </div>
                    {children}
                    <ErrorBox message={status} />
                    <Button
                        label={submitLabel}
                        isSubmit
                        pageButton
                        disabled={!isValid}
                        processing={isSubmitting}
                        dataTestId="button--card-form-submit"
                    />
                </form>
            )}
        </Formik>
    )
}

// This code makes sure we don't use the font in Edge (because it doesn't deal
// with the data: URL). We need to alter this to use an https:// URL first
// We also set the font to undefined in the test (jsdom) environment
const fonts =
    navigator.userAgent.match(/\bEdge\/\d/) || navigator.userAgent.match(/\bjsdom\/\d/)
        ? undefined
        : [{family: 'centra', src: `url(${stripeFont}) format('woff2')`}]

export default function WrappedCardForm(cardFormProps: CardFormProps<any>) {
    const [stripePromise, setStripePromise] = React.useState<Promise<Stripe | null> | null>(null)

    const currency = cardFormProps.jurisdiction === 'au' ? 'aud' : 'nzd'

    React.useEffect(() => {
        setStripePromise(loadStripe(cardFormProps.jurisdiction === 'au' ? config.stripeAuKey : config.stripeNzKey))
    }, [location])

    return (
        <Elements stripe={stripePromise} options={{fonts}}>
            <ElementsConsumer>
                {({elements, stripe}) => (
                    <CardForm
                        elements={elements ?? undefined}
                        stripe={stripe ?? undefined}
                        currency={currency}
                        {...cardFormProps}
                    />
                )}
            </ElementsConsumer>
        </Elements>
    )
}
