import {Button} from '@design-system/button'
import {withFormik} from 'formik'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {titleCase} from '~/global/utils/convert-to-title-case/convertToTitleCase'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {validate} from '~/global/widgets/form-controls'
import {Radio, Select, Checkbox} from '~/global/widgets/form-controls/formik'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {csnChoice} from '~/sections/invest/sections/transfer-shares/sections/nzx-transfer-shares/pages/choose-csn/ChooseCSN'
import {ResponseRegistryDetail} from '~/store/transfer/types'

interface FormValues {
    newOrExistingCsn?: csnChoice
    csn: string
    acceptTerms: boolean
}

interface ChooseCSNFormProps {
    handleSuccess: (newOrExistingCsn: csnChoice, csn: string) => void
    availableCsns: ResponseRegistryDetail[]
    maybeStagedTransferOrderDirection?: 'in' | 'out'
}

export const ChooseCSNForm = withFormik<ChooseCSNFormProps, FormValues>({
    mapPropsToValues: () => ({
        acceptTerms: false,
        csn: '',
    }),
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        acceptTerms: undefined,
    }),
    handleSubmit: async ({newOrExistingCsn, csn}, {setSubmitting, props: {handleSuccess}}) => {
        setSubmitting(true)
        try {
            if (newOrExistingCsn) {
                handleSuccess(newOrExistingCsn, csn)
            }
        } catch (e) {
            setSubmitting(false)
            throw e
        }
    },
    validate: values => {
        const schema = {
            csn: values.newOrExistingCsn === 'existing' ? [validate.required()] : [],
            newOrExistingCsn: [validate.required()],
            acceptTerms: [validate.isTrue('You must accept the Terms and Conditions')],
        }
        return validate.generate<FormValues>(schema)(values)
    },
})(({values, handleSubmit, isValid, isSubmitting, availableCsns, maybeStagedTransferOrderDirection}) => {
    const profileUrl = useProfileUrl()

    const csnOptions = availableCsns.map(csn => ({
        label: csn.reference_type === 'REQUEST_CSN' ? 'New CSN' : `${csn.reference} (${titleCase(csn.state)})`,
        value: csn.reference || csn.reference_type,
    }))
    // Put an empty row at the front
    csnOptions.unshift({label: '', value: ''})

    // You can't transfer out to an HN
    const csnOrHnString = maybeStagedTransferOrderDirection === 'in' ? 'CSN or HN' : 'CSN'

    return (
        <form onSubmit={handleSubmit}>
            <div className={commonStyles.formWrapper}>
                <h1 className={spacing.spaceBelow24}>Use previous {csnOrHnString}?</h1>
                <Radio
                    dataTestId="radio--new-or-existing-csn"
                    name="newOrExistingCsn"
                    label={`If you like, you can use a ${csnOrHnString} you’ve previously used to transfer shares on Sharesies. Or, you can enter a new one.`}
                    choices={[
                        {
                            label: `Use previous ${csnOrHnString}`,
                            value: 'existing',
                        },
                        {
                            label: `Enter new ${csnOrHnString}`,
                            value: 'new',
                        },
                    ]}
                />
                {values.newOrExistingCsn === 'existing' && (
                    <Select
                        name="csn"
                        label={`Which ${csnOrHnString} would you like to use?`}
                        dataTestId="select--csn"
                        placeholder={`Select a ${csnOrHnString}`}
                        choices={csnOptions}
                    />
                )}
                <Checkbox
                    dataTestId="checkbox--accept-terms"
                    name="acceptTerms"
                    label={
                        <>
                            I’ve read and accept the{' '}
                            <a
                                className={commonStyles.topupLink}
                                href={profileUrl('legal/nzx-share-transfers-terms')}
                                target="_blank"
                                rel="noopener"
                            >
                                NZX Transfer Terms and Conditions
                            </a>
                        </>
                    }
                />
            </div>

            <ActionBar>
                <Button dataTestId="button--next" label="Next" disabled={!isValid} isSubmit processing={isSubmitting} />
            </ActionBar>
        </form>
    )
})
