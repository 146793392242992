import {Button} from '@design-system/button'
import Decimal from 'decimal.js'
import {Formik, useFormikContext} from 'formik'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useRetailPost} from '~/api/query/retail'
import {Model, Response} from '~/api/retail/types'
import * as rollbar from '~/api/rollbar/rollbar'
import {spacing} from '~/global/scss/helpers'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useWalletBreakdown} from '~/global/state-hooks/retail/useWalletBreakdown'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {
    bankAccountNameError,
    bankAccountNameRegex,
    bankNumberError,
    bankNumberRegex,
    bsbError,
    bsbRegex,
} from '~/global/utils/bank-account-validators/bankAccountValidators'
import {humaniseBankAccount} from '~/global/utils/humanise-bank-account/humaniseBankAccount'
import {roundWithdrawalValue} from '~/global/utils/round-withdrawal-value/roundWithdrawalValue'
import {ArrayElement} from '~/global/utils/type-utilities/typeUtilities'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {validate} from '~/global/widgets/form-controls'
import {NZBankAccount, AUBankAccount, BankBSB, Text, Select} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {WalletAmountInput} from '~/global/widgets/wallet-amount-input/WalletAmountInput'
import {WithdrawSaveInfoCard} from '~/sections/OLD_wallet/sections/withdrawals/widgets/withdraw-save-info-card/WithdrawSaveInfoCard'
import styles from '~/sections/wallet/sections/withdrawals/Withdrawals.scss'
import {useBankAccounts} from '~/sections/wallet/sections/withdrawals/state/use-bank-accounts/useBankAccounts'
import {useCheckIfWithdrawalRestrictedToSource} from '~/sections/wallet/sections/withdrawals/state/useCheckIfWithdrawalRestrictedToSource'
import {IdentityRequiredAlert} from '~/sections/wallet/sections/withdrawals/widgets/identity-required-alert/IdentityRequiredAlert'
import {UnsettledFundsModal} from '~/sections/wallet/sections/withdrawals/widgets/unsettled-funds-modal/UnsettledFundsModal'
import {useHistoryState, useWalletPortfolio} from '~/sections/wallet/state/local'
import {useLegacyUserData} from '~/sections/wallet/state/retail'

export interface WithdrawalOrder {
    // underscores to match backend value naming directly as we pass this back and forth
    // used both for the form types and the values we store in history state
    amount: string
    bank_account: string
    bank_account_name: string
    bank_bsb: string
    mode: 'auto' | 'manual'
    withdrawal_cost?: Omit<Response.WithdrawalCost, 'type'>
    method?: 'wait' | 'fast'
}

type Accounts = (
    | Response.IdentityAUBankAccounts['accounts'][number]
    | Response.IdentityBankAccounts['accounts'][number]
)[]

const defaultValues = (accounts: Accounts, mode: WithdrawalOrder['mode']) => {
    const firstAccount = accounts.at(0)

    if (mode !== 'manual' && firstAccount) {
        return {
            amount: '',
            bank_account: firstAccount.number,
            bank_account_name: '', // Leave bank account name blank in auto until submit
            bank_bsb: 'bsb' in firstAccount ? firstAccount.bsb : '',
            mode,
        }
    }

    return {amount: '', bank_account: '', bank_account_name: '', bank_bsb: '', mode}
}

const validateAmount = (withdrawableBalance: string, ownershipLabel: string) => {
    return validate.maximum(
        new Decimal(withdrawableBalance).toNumber(),
        `Amount exceeds ${ownershipLabel} available balance`,
    )
}

export const accountLabel = (account: ArrayElement<Accounts>, jurisdiction: Model.User['jurisdiction']): string => {
    if (jurisdiction === 'au' && 'bsb' in account) {
        return `${humaniseBankAccount(account.bsb, jurisdiction)} ${humaniseBankAccount(
            account.number,
            jurisdiction,
        )} — ${account.name}`
    }
    return `${humaniseBankAccount(account.number, jurisdiction)} — ${account.name}`
}

const WithdrawalsForm = () => {
    const [unsettledBalanceModalOpen, setUnsettledBalanceModalOpen] = React.useState<boolean>(false)

    const {values, handleSubmit, isSubmitting, setFieldValue, isValid, setValues, setTouched, touched} =
        useFormikContext<WithdrawalOrder>()
    const profile = useProfile()
    const {id: walletPortfolioId} = useWalletPortfolio()
    const {canFastWithdraw, jurisdiction, isRestrictedAccount} = useLegacyUserData()
    const hasSaveAccount = profile.portfolios.some(p => p.product === 'SAVE')
    const accounts = useBankAccounts()
    const {available_balance: availableBalance} = useWalletBreakdown(walletPortfolioId)
    const {withdrawalRestrictedToSource} = useCheckIfWithdrawalRestrictedToSource()

    const availableBalanceRounded = roundWithdrawalValue(availableBalance)
    const amountRequested = values.amount ? new Decimal(values.amount) : new Decimal('0')

    const onSwitchForm = () => {
        const newMode = values.mode === 'manual' ? 'auto' : 'manual'
        setValues({...defaultValues(accounts, newMode), amount: values.amount}, true)
        if (newMode === 'manual') {
            // Clear validation errors from any previous times you looked at the "manual" inputs
            setTouched({
                ...touched,
                bank_account: false,
                bank_account_name: false,
                bank_bsb: false,
            })
        }
    }

    const handleSubmitClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()

        // If the amount to withdraw is greater than settled balance (and fast withdrawals is not allowed), present a confirmation modal first
        if (amountRequested.greaterThan(availableBalance) && !canFastWithdraw) {
            // Confirming the modal will call handleSubmit
            setUnsettledBalanceModalOpen(true)
        } else {
            // Handle submit will take care of validating inputs and showing fast withdrawals or confirm as appropriate
            handleSubmit()
        }
    }

    return (
        <form>
            <UnsettledFundsModal
                // Nested inside the form so it can call handleSubmit
                modalOpen={unsettledBalanceModalOpen}
                isSubmitting={isSubmitting}
                availableBalanceRounded={availableBalanceRounded}
                unsettledRequested={amountRequested.minus(availableBalance)}
                setModalOpen={setUnsettledBalanceModalOpen}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
            />
            <WalletAmountInput
                walletPortfolioId={walletPortfolioId}
                dataTestId="wallet--withdraw-amount"
                isDisabled={isSubmitting || isRestrictedAccount}
            />
            {values.mode === 'auto' ? ( // bank account inputs for either auto or manual forms
                <>
                    {accounts.length > 0 && (
                        <Select
                            dataTestId="select--bank-account"
                            name="bank_account"
                            label="Bank account"
                            choices={accounts.map(a => ({
                                value: a.number,
                                label: accountLabel(a, jurisdiction),
                            }))}
                            disabled={isRestrictedAccount}
                        />
                    )}
                    {accounts.length > 0 && (!withdrawalRestrictedToSource || jurisdiction === 'au') && (
                        <p>
                            <ButtonAsLink
                                onClick={() => {
                                    onSwitchForm()
                                }}
                                data-testid="anchor--withdraw-to-another-bank-account"
                            >
                                Withdraw to another bank account
                            </ButtonAsLink>
                        </p>
                    )}
                </>
            ) : (
                <>
                    {accounts.length > 0 && <h2 className={styles.newAccount}>Withdraw to new bank account</h2>}
                    <Text
                        dataTestId="text-input--bank-account-name"
                        name="bank_account_name"
                        label="Bank account name"
                        disabled={isSubmitting || isRestrictedAccount}
                    />
                    {jurisdiction === 'au' && (
                        <>
                            <BankBSB
                                dataTestId="text-input--bank-bsb-number"
                                name="bank_bsb"
                                label="BSB number"
                                disabled={isSubmitting}
                            />
                            <AUBankAccount
                                dataTestId="text-input--au-bank-account-number"
                                name="bank_account"
                                label="Bank account number"
                                disabled={isSubmitting || isRestrictedAccount}
                            />
                        </>
                    )}
                    {jurisdiction === 'nz' && (
                        <NZBankAccount
                            dataTestId="text-input--nz-bank-account-number"
                            name="bank_account"
                            label="Bank account number"
                            disabled={isSubmitting || isRestrictedAccount}
                        />
                    )}

                    {accounts.length > 0 && (
                        <p>
                            <ButtonAsLink
                                onClick={() => {
                                    onSwitchForm()
                                }}
                            >
                                Cancel
                            </ButtonAsLink>
                        </p>
                    )}
                    {jurisdiction === 'nz' && (
                        <p className={spacing.spaceAbove24}>
                            You can only withdraw NZD to NZ bank accounts. If you have money in other currencies you
                            want to withdraw you’ll need to exchange it to NZD first.
                        </p>
                    )}
                    {jurisdiction === 'au' && (
                        <p className={spacing.spaceAbove24}>
                            You can only withdraw AUD to AU bank accounts. If you have money in other currencies you
                            want to withdraw you’ll need to exchange it to AUD first.
                        </p>
                    )}
                </>
            )}
            <p className={spacing.spaceAbove24}>
                Withdrawals usually take 1–2 business days. It can take longer if you’ve sold shares recently, or if we
                need to confirm your details.
            </p>
            {/* TODO shared money sensitive version of WithdrawSaveInfoCard.tsx here - temporarily disabled for non-legacy profiles */}
            {jurisdiction === 'nz' && !hasSaveAccount && profile.legacy_profile_type && <WithdrawSaveInfoCard />}{' '}
            <Button
                isSubmit
                dataTestId="button--withdraw"
                label="Withdraw"
                disabled={!isValid || isRestrictedAccount}
                processing={isSubmitting}
                pageButton
                onClick={handleSubmitClick}
            />
        </form>
    )
}

export const Withdrawals = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const {id: walletPortfolioId} = useWalletPortfolio()
    const profile = useProfile()
    const {canFastWithdraw, jurisdiction} = useLegacyUserData()
    const accounts = useBankAccounts()
    const {available_balance: availableBalance, withdrawable_balance: withdrawableBalance} =
        useWalletBreakdown(walletPortfolioId)
    const [withdrawalOrder, setWithdrawalOrder] = useHistoryState<WithdrawalOrder>(
        'withdrawalOrder',
        defaultValues(accounts, accounts.length === 0 ? 'manual' : 'auto'),
    )

    const ownershipLabel = profile.is_personal ? 'your' : `${profile.name}’s`

    const checkWithdrawalForm = useRetailPost({
        path: 'withdrawals/:portfolio_id/check',
        pathParams: {portfolio_id: walletPortfolioId},
    })

    const costFastWithdrawal = useRetailPost({
        path: 'withdrawals/:portfolio_id/cost',
        pathParams: {portfolio_id: walletPortfolioId},
    })

    return (
        <>
            <Toolbar dataTestId="toolbar--withdraw" leftButton="back" title="Withdraw" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <IdentityRequiredAlert />

                <Formik
                    initialValues={withdrawalOrder}
                    initialErrors={
                        withdrawalOrder.amount // if returning from confirm page, don't set an error
                            ? undefined
                            : {
                                  amount: undefined, // if starting fresh, ensure the submit button is disabled to begin
                              }
                    }
                    initialTouched={{
                        amount: Boolean(withdrawalOrder.amount),
                        bank_account: Boolean(withdrawalOrder.bank_account),
                        bank_account_name: Boolean(withdrawalOrder.bank_account_name),
                        bank_bsb: Boolean(withdrawalOrder.bank_bsb),
                        mode: false,
                    }}
                    validate={values => {
                        const bankAccountVerifications = [validate.required()]

                        if (values.mode === 'manual') {
                            bankAccountVerifications.push(
                                validate.regexp(bankNumberRegex(jurisdiction), bankNumberError(jurisdiction)),
                            )
                        }

                        const nzSchema = {
                            amount: [
                                validate.required(),
                                validate.money(),
                                validateAmount(withdrawableBalance, ownershipLabel),
                            ],
                            bank_account: bankAccountVerifications,
                            bank_account_name:
                                values.mode === 'manual'
                                    ? [
                                          validate.required(),
                                          validate.regexp(bankAccountNameRegex(), bankAccountNameError()),
                                          validate.nonWhitespace(),
                                      ]
                                    : [],
                            bank_bsb: [],
                            mode: [],
                            fee: [],
                        }
                        const auSchema = {
                            ...nzSchema,
                            bank_bsb:
                                values.mode === 'manual'
                                    ? [validate.required(), validate.regexp(bsbRegex, bsbError)]
                                    : [],
                        }
                        return validate.generate<WithdrawalOrder>(jurisdiction === 'au' ? auSchema : nzSchema)(values)
                    }}
                    onSubmit={async (values, {setSubmitting, setFieldError, setStatus}) => {
                        const amountRequested = values.amount ? new Decimal(values.amount) : new Decimal('0')
                        const unsettledRequested = amountRequested.minus(availableBalance)
                        const couldBeFast = canFastWithdraw && unsettledRequested.greaterThan(0) // only offer fast withdrawals if they are trying to withdraw some unsettled funds (otherwise normal withdrawal will be as fast)

                        let bank_account_name = values.bank_account_name
                        let bank_bsb = values.bank_bsb

                        if (values.mode === 'auto') {
                            const account = accounts.find(a => a.number === values.bank_account)
                            if (bank_account_name === '') {
                                bank_account_name = account?.name || ''
                            }
                            bank_bsb = account && account.bsb ? account.bsb : values.bank_bsb
                        }

                        try {
                            await checkWithdrawalForm.mutateAsync(
                                {
                                    amount: values.amount,
                                    bank_account: values.bank_account,
                                    bank_account_name,
                                    jurisdiction,
                                    bank_bsb,
                                },
                                {
                                    onSuccess: async () => {
                                        const withdrawalOrder = {
                                            ...values,
                                            bank_account_name,
                                            bank_bsb,
                                        }

                                        // if we're going to the fast withdrawal screen, get the cost information first
                                        if (couldBeFast) {
                                            // no need to wrap in an additional try/catch as the outer one will catch
                                            await costFastWithdrawal.mutateAsync(
                                                {
                                                    jurisdiction,
                                                    amount: values.amount,
                                                    fast: true,
                                                },
                                                {
                                                    onSuccess: response => {
                                                        // only one type of success response is expected
                                                        withdrawalOrder.withdrawal_cost = {
                                                            expected_fee: response.expected_fee,
                                                            uncleared_amount: response.uncleared_amount,
                                                            payment_amount: response.payment_amount,
                                                        }
                                                    },
                                                    onError: error => {
                                                        switch (error.type) {
                                                            case 'validation_error':
                                                            case 'error':
                                                            case 'internal_server_error':
                                                                // we don't really expect an error from this endpoint, as everything we've just sent is consistent with the prior API call we just validated
                                                                // so just throw for the try/catch to handle
                                                                throw new Error(
                                                                    `Unexpected fast withdrawal cost API error from backend: ${error.type}`,
                                                                )
                                                            default:
                                                                assertNever(error)
                                                        }
                                                    },
                                                },
                                            )
                                        } else {
                                            // not eligible for fast withdrawals, reset any previously set fast withdrawal cost and method (might be present if we did back nav and changed amount)
                                            withdrawalOrder.withdrawal_cost = undefined
                                            withdrawalOrder.method = undefined
                                        }

                                        // save into history state so we can retrieve it on the next page
                                        setWithdrawalOrder(withdrawalOrder)

                                        navigate(
                                            couldBeFast
                                                ? profileUrl('wallet/:portfolioId/withdrawal/select', {
                                                      portfolioId: walletPortfolioId,
                                                  })
                                                : profileUrl('wallet/:portfolioId/withdrawal/confirm', {
                                                      portfolioId: walletPortfolioId,
                                                  }),
                                            {
                                                state: {
                                                    withdrawalOrder,
                                                },
                                            },
                                        )
                                    },
                                    onError(error) {
                                        switch (error.type) {
                                            case 'validation_error':
                                                Object.entries(error.errors).forEach(([field, messages]) => {
                                                    messages.forEach(message => {
                                                        // if there are multiple, the last one wins
                                                        setFieldError(field, message)
                                                    })
                                                })
                                                // beyond the normal field -> error mapping, if we're in auto mode set the bank_account error to the bank_account_name error
                                                if (values.mode === 'auto' && error.errors.bank_account_name) {
                                                    setFieldError('bank_account', error.errors.bank_account_name[0])
                                                }
                                                break
                                            case 'error':
                                                // handle the set of special errors the backend might return. these are unfortunately not typed.
                                                switch (error.code) {
                                                    case 'invalid_portfolio':
                                                        // we shouldn't be able to reach this error in normal use, so just throw
                                                        throw new Error('Invalid portfolio in withdrawal form')
                                                    case 'wallet_balance':
                                                        setFieldError('amount', error.message)
                                                        break
                                                    case 'minimum_balance':
                                                        setStatus({message: error.message, type: 'error'})
                                                        break
                                                    case 'invalid_source_account':
                                                        setFieldError('bank_account', error.message)
                                                        break
                                                    case 'bank_account_number_blacklisted':
                                                        setFieldError('bank_account', error.message)
                                                        break
                                                    default:
                                                        throw new Error(error.code)
                                                }
                                                break
                                            case 'internal_server_error':
                                                // unexpected error we don't know how to handle - throw and handle in the catch block
                                                throw new Error(error.type)
                                            default:
                                                assertNever(error)
                                        }
                                    },
                                    onSettled: () => {
                                        setSubmitting(false)
                                    },
                                },
                            )
                        } catch (error) {
                            // Formik will catch unhandled errors so the error boundary won't be displayed.
                            // We don't know how to handle these errors, so just show a generic error message.
                            Toast('There was an error submitting your withdrawal request')
                            // Internal server errors will have already have been logged to rollbar but otherwise
                            // we should report here (as Formik will otherwise suppress the error)
                            if (error instanceof Error && error.message !== 'internal_server_error') {
                                rollbar.sendError(`Withdrawals form: unexpected error`, {
                                    message: error.message,
                                })
                            }
                        }
                    }}
                >
                    <WithdrawalsForm />
                </Formik>
            </Page>
        </>
    )
}
