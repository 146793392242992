import {ModalLink} from '@design-system/modal'
import React from 'react'
import {HelpContentBodyRenderer} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {ExchangeFeesContent} from '~/sections/explore/pages/learn-static-content/LearnContent'

const ExchangeMoneyFees: React.FunctionComponent<{}> = () => {
    return (
        <ModalLink
            dataTestId="modal-link--exchange-fees"
            label={ExchangeFeesContent.title}
            asIcon
            helpIconSize={14}
            modalTitle={ExchangeFeesContent.title}
            primaryButton={{label: 'Ok'}}
        >
            <HelpContentBodyRenderer page={ExchangeFeesContent} />
        </ModalLink>
    )
}

export default ExchangeMoneyFees
