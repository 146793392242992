import {Button} from '@design-system/button'
import cn from 'classnames'
import {withFormik} from 'formik'
import {DateTime} from 'luxon'
import React from 'react'
import {Navigate} from 'react-router-dom'
import {Model} from '~/api/retail/types'
import {EventDataTaxonomy} from '~/api/rudderstack/event-taxonomy/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {formatNumber} from '~/global/utils/format-number/formatNumber'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {StrongInlineCurrency, StrongInlineSelect, StrongInlineDate} from '~/global/widgets/form-controls/formik'
import * as v from '~/global/widgets/form-controls/validate'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import commonStyles from '~/sections/invest/sections/auto-invest/Common.scss'
import {useCurrentPremadeOrder} from '~/sections/invest/sections/auto-invest/hooks/useAutoinvestOrders'
import {usePremadeOrder} from '~/sections/invest/sections/auto-invest/hooks/usePremadeOrders'
import actions from '~/store/autoinvest/actions'
import {EnhancedAutoinvestOrder} from '~/store/autoinvest/selectors'
import {isDIY, StagedOrder} from '~/store/autoinvest/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'

type IntervalType = EventDataTaxonomy['autoinvest']['autoinvest_amount_set']['interval']

interface FormValues {
    amount: string
    interval?: Model.AutoinvestOrder['interval']
    startDate?: DateTime
}

interface FormProps {
    backUrl: string
    costStagedOrder: () => void
    currentOrder: EnhancedAutoinvestOrder | undefined
    homeCurrency: Model.User['home_currency']
    jurisdiction: Model.User['jurisdiction']
    setHowMuch: (amount: string, interval: Model.AutoinvestOrder['interval'], startDate: string) => void
    stagedOrder: StagedOrder
    profileUrl: ReturnType<typeof useProfileUrl>
}

const HowMuchForm = withRouter(
    withFormik<FormProps & WithRouterProps, FormValues>({
        mapPropsToValues: ({stagedOrder}) => ({
            amount: stagedOrder.order.amount ? stagedOrder.order.amount : '',
            interval: stagedOrder.order.interval || '1week',
            startDate: stagedOrder.order.startDate
                ? DateTime.fromISO(stagedOrder.order.startDate)
                : DateTime.local().plus({days: 1}), // default to tomorrow,
        }),
        mapPropsToErrors: (props: FormProps) => {
            const isValid = props.stagedOrder.isEdit || props.stagedOrder.state === 'staged'
            if (!isValid) {
                // make the button disabled initially if there is an intial error by setting at least one field to have an error
                return {
                    amount: undefined,
                }
            }
            return {}
        },
        handleSubmit: async (
            {amount, interval, startDate},
            {
                setSubmitting,
                props: {
                    setHowMuch,
                    costStagedOrder,
                    stagedOrder,
                    router: {navigate},
                    profileUrl,
                },
            },
        ) => {
            await setHowMuch(amount, interval!, startDate!.toISODate())
            await costStagedOrder()

            // interval may be undefined
            rudderTrack('autoinvest', 'autoinvest_amount_set', {
                amount: parseFloat(amount),
                interval: interval as IntervalType,
            })

            // Can only personalise DIY orders
            if (isDIY(stagedOrder.order)) {
                navigate(profileUrl('auto-invest/diy/personalise-order'))
            } else {
                navigate(profileUrl('auto-invest/review'))
            }

            setSubmitting(false)
        },
        validate: v.generate<FormValues>({
            amount: [v.required(), v.money({allowZero: true}), v.minimum(5, 'The minimum auto-invest amount is $5')],
            interval: [v.required()],
            startDate: [v.required(), v.date()],
        }),
    })(({handleSubmit, isSubmitting, isValid, homeCurrency, backUrl}) => {
        const navigate = useNavigate()
        const moneyGoal = useAppSelector(s => s.accounting.topupGoal) // Money goal will be replacing Topup goal but use the existing BE

        const frequencyFormat = {
            WEEK: 'week',
            FORTNIGHT: 'fortnight',
            MONTH: 'month',
        }

        return (
            <>
                <Toolbar
                    dataTestId="toolbar--how-much"
                    leftButton="back"
                    onLeftButtonClick={() => navigate(backUrl, {replace: true})}
                    title="How much and when?"
                />
                <form onSubmit={handleSubmit}>
                    <Page withoutDefaultPadding>
                        {moneyGoal && (
                            <div
                                className={cn([
                                    spacing.spaceBelow16,
                                    spacing.spaceAbove16,
                                    commonStyles.moneyGoalAlert,
                                ])}
                            >
                                <h3>Your 2024 goal</h3>
                                <p>
                                    To reach your goal of $
                                    {formatNumber({number: moneyGoal.target_amount, decimalPlaces: 2})} you've said you
                                    want to contribute $
                                    {formatNumber({number: moneyGoal.amount_per_interval.toString(), decimalPlaces: 2})}{' '}
                                    every {frequencyFormat[moneyGoal.interval]}.
                                </p>
                            </div>
                        )}

                        <div className={commonStyles.pageContainer}>
                            <StrongInlineCurrency
                                name="amount"
                                label="Invest"
                                dataTestId="order-amount"
                                currency={homeCurrency}
                            />
                            <StrongInlineSelect
                                name="interval"
                                label="Every"
                                dataTestId="order-interval"
                                choices={[
                                    {label: 'Week', value: '1week'},
                                    {label: 'Fortnight', value: '2week'},
                                    {label: 'Four weeks', value: '4week'},
                                    {label: 'Month', value: '1month'},
                                ]}
                            />
                            <StrongInlineDate
                                name="startDate"
                                dataTestId="order-start-date"
                                label="Starting"
                                customZIndex={114}
                            />
                        </div>
                    </Page>
                    <ActionBar className={commonStyles.footer}>
                        <Button
                            processing={isSubmitting}
                            disabled={!isValid}
                            label="Next"
                            dataTestId="button--how-much-confirm"
                            onClick={() => handleSubmit()}
                        />
                    </ActionBar>
                </form>
            </>
        )
    }),
)

interface HowMuchProps {
    premadeSlug?: string
}

const HowMuch: React.FunctionComponent<HowMuchProps> = ({premadeSlug}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const [premadeOrder, premadeLoaded] = usePremadeOrder(premadeSlug ?? '')
    const stagedOrder = useAppSelector(s => s.autoinvest.stagedOrder)

    const [currentPremadeOrder, currentOrderLoaded] = useCurrentPremadeOrder()

    const setHowMuch = (amount: string, interval: Model.AutoinvestOrder['interval'], startDate: string) =>
        dispatch(actions.SetHowMuch(amount, interval, startDate))
    const costStagedOrder = () => dispatch(actions.CostStagedOrder())

    const backUrl = premadeOrder
        ? profileUrl('auto-invest/:premadeSlug', {premadeSlug: premadeOrder.slug})
        : profileUrl('auto-invest/diy/percentage')

    React.useEffect(() => {
        if (!stagedOrder || stagedOrder.state === 'completed') {
            navigate(backUrl, {replace: true})
        }
    }, [])

    if (!stagedOrder || stagedOrder.state === 'completed') {
        return <Navigate to={profileUrl('auto-invest')} replace />
    }

    if (!currentOrderLoaded || !premadeLoaded) {
        return <Loading isPineapple />
    }

    return (
        <HowMuchForm
            backUrl={backUrl}
            costStagedOrder={costStagedOrder}
            currentOrder={currentPremadeOrder}
            homeCurrency={homeCurrency}
            jurisdiction={jurisdiction}
            setHowMuch={(amount: string, interval: Model.AutoinvestOrder['interval'], startDate: string) =>
                setHowMuch(amount, interval, startDate)
            }
            stagedOrder={stagedOrder}
            profileUrl={profileUrl}
        />
    )
}

export default HowMuch
