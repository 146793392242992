import * as rollbar from '~/api/rollbar/rollbar'

export interface TelescopeInstrument {
    id: string
    ticker: string
    title: string
    reference_id1: string
    reference_id2?: string
    reason: string
    instrument_group_id: string
}

interface TelescopeError {
    error_code: string
    error_message: string
    prompt_suggestions: string[]
    reason?: string
}

export interface TelescopeResponse {
    title?: string
    thesis?: string
    emoji?: string
    instruments?: TelescopeInstrument[]
    errors?: TelescopeError[]
}

export const parseTelescopeEventStream = (currentState: TelescopeResponse, event: any): TelescopeResponse => {
    const event_type = event.event
    const message = JSON.parse(event.data) as Record<string, any>
    const newState = {...currentState}

    if (!message) {
        return newState
    }

    if (event_type === 'Chunk') {
        if (message.field === 'emoji' && message.chunk) {
            newState.emoji = message.chunk
        }

        if (message.field === 'title' && message.chunk) {
            newState.title = newState.title ? newState.title + message.chunk : message.chunk
        }

        if (message.field === 'thesis' && message.chunk) {
            newState.thesis = newState.thesis ? newState.thesis + message.chunk : message.chunk
        }
    } else if (event_type === 'FieldComplete') {
        // ignored for now
    } else if (event_type === 'Complete') {
        // ignored for now
    } else if (event_type === 'Row') {
        if (message.field === 'instruments' && message.value) {
            const {id, ticker, title, reason, instrument_group_id, reference_id1, reference_id2} = message.value

            if (newState.instruments) {
                const existingInstrument = newState.instruments.find(i => i.id === id)
                if (existingInstrument) {
                    return newState
                }
            }

            newState.instruments = newState.instruments
                ? [
                      ...newState.instruments,
                      {
                          id,
                          ticker,
                          title,
                          reason,
                          instrument_group_id,
                          reference_id1,
                          reference_id2,
                      },
                  ]
                : [
                      {
                          id,
                          ticker,
                          title,
                          reason,
                          instrument_group_id,
                          reference_id1,
                          reference_id2,
                      },
                  ]
        }
    } else if (event_type === 'Error') {
        const prompt_suggestions = message.additional_data?.prompt_suggestions
        const newError: TelescopeError = {
            error_code: message.code ?? 'unknown',
            error_message: message.message,
            prompt_suggestions: prompt_suggestions && Array.isArray(prompt_suggestions) ? prompt_suggestions : [],
            reason: message.additional_data?.reason,
        }

        newState.errors = newState.errors ? [...newState.errors, newError] : [newError]

        if (newState.errors && newState.instruments && newState.errors.length > 0 && newState.instruments.length > 0) {
            // One of the 6 groups had no instrumets found. Don't display this as an error.
            newState.errors = newState.errors.filter(error => error.error_code !== 'ripple_no_instruments_matched')
        }
    } else {
        rollbar.sendError(`Unhandled telescope event type ${event_type}`)
    }

    return newState
}
