import {Modal} from '@design-system/modal'
import React from 'react'

interface Props {
    instrumentName: string
    isOpen: boolean
    orderName?: string
    setModalOpen: () => void
}

const DuplicateInstrumentWarningModal: React.FunctionComponent<Props> = ({
    instrumentName,
    isOpen,
    orderName,
    setModalOpen,
}) => (
    <Modal
        content={
            orderName ? (
                <p>
                    {instrumentName} can only be in one order at a time. To include it in this order, you’ll need to
                    remove it from your ‘{orderName}’ order first.
                </p>
            ) : (
                <p>
                    {instrumentName} can only be in one order at a time. To include it in this order, you’ll need to
                    delete your Premade order first.
                </p>
            )
        }
        dataTestId="modal--auto-invest-select"
        isOpen={isOpen}
        setIsOpen={setModalOpen}
        title="Already in an order"
    />
)

export default DuplicateInstrumentWarningModal
