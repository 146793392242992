import React from 'react'
import tooltipStyles from '~/global/scss/reused-styles/tooltip.scss'
import {calculateCurrencyExchangeFromSourceAmount} from '~/global/utils/calculate-currency-exchange/calculateCurrencyExchange'
import {useExchangeFeeRate} from '~/global/utils/exchange-fee-hooks/exchangeFeeHooks'
import {findExchangeRate} from '~/global/utils/find-exchange-rate/findExchangeRate'
import {SharePriceValue} from '~/global/widgets/number-elements/NumberElements'
import {Tooltip} from '~/global/widgets/tooltip/Tooltip'
import {useUsLivePricing} from '~/sections/invest/sections/view-instrument/hooks/usLivePricing'
import {useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import SharePriceWithExchangeTooltip from '../share-price-with-exchange-tooltip/SharePriceWithExchangeTooltip'

interface LiveSharePriceWithExchangeTooltipProps {
    instrument: Instrument
    shareLabel: string
}

const LiveSharePriceWithExchangeTooltip: React.FunctionComponent<LiveSharePriceWithExchangeTooltipProps> = ({
    instrument,
    shareLabel,
}) => {
    const usLivePricing = useUsLivePricing(instrument)
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)
    const exchangeRates = useAppSelector(s => s.accounting.exchangeRates)
    const foundExchangeRate = findExchangeRate({
        sourceCurrency: instrument.currency,
        targetCurrency: homeCurrency,
        exchangeRates,
    })
    const exchangeFeeRate = useExchangeFeeRate()

    if (!usLivePricing) {
        return (
            <SharePriceWithExchangeTooltip
                price={instrument.marketPrice}
                currency={instrument.currency}
                shareLabel={shareLabel}
            />
        )
    }

    return (
        <>
            {foundExchangeRate && exchangeFeeRate !== null ? (
                <Tooltip>
                    <span className={tooltipStyles.label}>
                        <SharePriceValue value={usLivePricing!.tradePrice} currency={instrument.currency} />
                    </span>
                    <div className={tooltipStyles.tooltip}>
                        <p>
                            Estimated{' '}
                            <SharePriceValue
                                value={
                                    calculateCurrencyExchangeFromSourceAmount(
                                        usLivePricing.tradePrice,
                                        foundExchangeRate.rate,
                                        exchangeFeeRate,
                                    ).targetAmount
                                }
                                currency={homeCurrency}
                            />{' '}
                            per {shareLabel}
                        </p>
                    </div>
                </Tooltip>
            ) : (
                <SharePriceValue value={usLivePricing.tradePrice} currency={instrument.currency} />
            )}
        </>
    )
}

const LiveSharePriceWrapper: React.FunctionComponent<LiveSharePriceWithExchangeTooltipProps> = ({
    instrument,
    shareLabel,
}) => {
    return (
        <React.Suspense
            fallback={
                <SharePriceWithExchangeTooltip
                    price={instrument.marketPrice}
                    currency={instrument.currency}
                    shareLabel={shareLabel}
                />
            }
        >
            <LiveSharePriceWithExchangeTooltip instrument={instrument} shareLabel={shareLabel} />
        </React.Suspense>
    )
}

export default LiveSharePriceWrapper
