/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    ComparisonPricesDto,
    ComparisonPricesDtoFromJSON,
    ComparisonPricesDtoFromJSONTyped,
    ComparisonPricesDtoToJSON,
    ComparisonPricesDtoToJSONTyped,
    Country,
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
    CountryToJSONTyped,
    EsInstrumentType,
    EsInstrumentTypeFromJSON,
    EsInstrumentTypeFromJSONTyped,
    EsInstrumentTypeToJSON,
    EsInstrumentTypeToJSONTyped,
    LogoUrlsDto,
    LogoUrlsDtoFromJSON,
    LogoUrlsDtoFromJSONTyped,
    LogoUrlsDtoToJSON,
    LogoUrlsDtoToJSONTyped,
    ManagementType,
    ManagementTypeFromJSON,
    ManagementTypeFromJSONTyped,
    ManagementTypeToJSON,
    ManagementTypeToJSONTyped,
    TradingStatus,
    TradingStatusFromJSON,
    TradingStatusFromJSONTyped,
    TradingStatusToJSON,
    TradingStatusToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface ListingResponseDto
 */
export interface ListingResponseDto {
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    urlSlug: string
    /**
     *
     * @type {boolean}
     * @memberof ListingResponseDto
     */
    isClientReadOnly: boolean
    /**
     *
     * @type {EsInstrumentType}
     * @memberof ListingResponseDto
     */
    instrumentType: EsInstrumentType
    /**
     *
     * @type {boolean}
     * @memberof ListingResponseDto
     */
    isAdr: boolean
    /**
     *
     * @type {boolean}
     * @memberof ListingResponseDto
     */
    isUsPartnership: boolean
    /**
     *
     * @type {boolean}
     * @memberof ListingResponseDto
     */
    isIlliquid: boolean
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    symbol: string
    /**
     *
     * @type {boolean}
     * @memberof ListingResponseDto
     */
    kidsRecommended: boolean
    /**
     *
     * @type {boolean}
     * @memberof ListingResponseDto
     */
    isVolatile?: boolean
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    description?: string | null
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    drivewealthId?: string | null
    /**
     *
     * @type {Array<string>}
     * @memberof ListingResponseDto
     */
    categories: Array<string>
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    logoIdentifier?: string | null
    /**
     *
     * @type {LogoUrlsDto}
     * @memberof ListingResponseDto
     */
    logos: LogoUrlsDto
    /**
     *
     * @type {number}
     * @memberof ListingResponseDto
     */
    riskRating: number
    /**
     *
     * @type {number}
     * @memberof ListingResponseDto
     */
    timeHorizonMin?: number | null
    /**
     *
     * @type {ComparisonPricesDto}
     * @memberof ListingResponseDto
     */
    comparisonPrices: ComparisonPricesDto
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    marketPrice: string
    /**
     *
     * @type {Date}
     * @memberof ListingResponseDto
     */
    marketLastCheck: DateTime
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    extendedHoursPrice?: string | null
    /**
     *
     * @type {Date}
     * @memberof ListingResponseDto
     */
    extendedHoursLastCheck?: DateTime | null
    /**
     *
     * @type {TradingStatus}
     * @memberof ListingResponseDto
     */
    tradingStatus: TradingStatus
    /**
     *
     * @type {TradingStatus}
     * @memberof ListingResponseDto
     */
    extendedHoursTradingStatus: TradingStatus
    /**
     *
     * @type {TradingStatus}
     * @memberof ListingResponseDto
     */
    extendedHoursNotionalStatus: TradingStatus
    /**
     *
     * @type {Country}
     * @memberof ListingResponseDto
     */
    exchangeCountry: Country
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    peRatio?: string | null
    /**
     *
     * @type {number}
     * @memberof ListingResponseDto
     */
    marketCap?: number
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    parentInstrumentId?: string | null
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    fmsFundId?: string | null
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    underlyingInstrumentId?: string | null
    /**
     *
     * @type {number}
     * @memberof ListingResponseDto
     */
    sharesPerRight?: number | null
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    offerPrice?: string | null
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    exercisePrice?: string | null
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    websiteUrl?: string | null
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    exchange?: string | null
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    legacyImageUrl?: string | null
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    dominantColour?: string | null
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    pdsDriveId?: string | null
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    assetManager?: string | null
    /**
     *
     * @type {ManagementType}
     * @memberof ListingResponseDto
     */
    managementType?: ManagementType
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    fixedFeeSpread?: string | null
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    managementFeePercent?: string | null
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    grossDividendYieldPercent?: string | null
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    annualisedReturnPercent?: string | null
    /**
     *
     * @type {Date}
     * @memberof ListingResponseDto
     */
    firstTradingDateTimeUtc?: DateTime | null
    /**
     *
     * @type {Date}
     * @memberof ListingResponseDto
     */
    lastTradingDateTimeUtc?: DateTime | null
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    ceo?: string | null
    /**
     *
     * @type {number}
     * @memberof ListingResponseDto
     */
    employees?: number
    /**
     *
     * @type {string}
     * @memberof ListingResponseDto
     */
    etfHoldings?: string | null
}

export function ListingResponseDtoFromJSON(json: any): ListingResponseDto {
    return ListingResponseDtoFromJSONTyped(json, false)
}

export function ListingResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListingResponseDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        id: json['id'],
        urlSlug: json['urlSlug'],
        isClientReadOnly: json['isClientReadOnly'],
        instrumentType: EsInstrumentTypeFromJSON(json['instrumentType']),
        isAdr: json['isAdr'],
        isUsPartnership: json['isUsPartnership'],
        isIlliquid: json['isIlliquid'],
        symbol: json['symbol'],
        kidsRecommended: json['kidsRecommended'],
        isVolatile: !exists(json, 'isVolatile') ? undefined : json['isVolatile'],
        name: json['name'],
        description: !exists(json, 'description') ? undefined : json['description'],
        drivewealthId: !exists(json, 'drivewealthId') ? undefined : json['drivewealthId'],
        categories: json['categories'],
        logoIdentifier: !exists(json, 'logoIdentifier') ? undefined : json['logoIdentifier'],
        logos: LogoUrlsDtoFromJSON(json['logos']),
        riskRating: json['riskRating'],
        timeHorizonMin: !exists(json, 'timeHorizonMin') ? undefined : json['timeHorizonMin'],
        comparisonPrices: ComparisonPricesDtoFromJSON(json['comparisonPrices']),
        marketPrice: json['marketPrice'],
        marketLastCheck: DateTime.fromISO(json['marketLastCheck'], {setZone: true}),
        extendedHoursPrice: !exists(json, 'extendedHoursPrice') ? undefined : json['extendedHoursPrice'],
        extendedHoursLastCheck: !exists(json, 'extendedHoursLastCheck')
            ? undefined
            : json['extendedHoursLastCheck'] === null
              ? null
              : DateTime.fromISO(json['extendedHoursLastCheck'], {setZone: true}),
        tradingStatus: TradingStatusFromJSON(json['tradingStatus']),
        extendedHoursTradingStatus: TradingStatusFromJSON(json['extendedHoursTradingStatus']),
        extendedHoursNotionalStatus: TradingStatusFromJSON(json['extendedHoursNotionalStatus']),
        exchangeCountry: CountryFromJSON(json['exchangeCountry']),
        peRatio: !exists(json, 'peRatio') ? undefined : json['peRatio'],
        marketCap: !exists(json, 'marketCap') ? undefined : json['marketCap'],
        parentInstrumentId: !exists(json, 'parentInstrumentId') ? undefined : json['parentInstrumentId'],
        fmsFundId: !exists(json, 'fmsFundId') ? undefined : json['fmsFundId'],
        underlyingInstrumentId: !exists(json, 'underlyingInstrumentId') ? undefined : json['underlyingInstrumentId'],
        sharesPerRight: !exists(json, 'sharesPerRight') ? undefined : json['sharesPerRight'],
        offerPrice: !exists(json, 'offerPrice') ? undefined : json['offerPrice'],
        exercisePrice: !exists(json, 'exercisePrice') ? undefined : json['exercisePrice'],
        websiteUrl: !exists(json, 'websiteUrl') ? undefined : json['websiteUrl'],
        exchange: !exists(json, 'exchange') ? undefined : json['exchange'],
        legacyImageUrl: !exists(json, 'legacyImageUrl') ? undefined : json['legacyImageUrl'],
        dominantColour: !exists(json, 'dominantColour') ? undefined : json['dominantColour'],
        pdsDriveId: !exists(json, 'pdsDriveId') ? undefined : json['pdsDriveId'],
        assetManager: !exists(json, 'assetManager') ? undefined : json['assetManager'],
        managementType: !exists(json, 'managementType') ? undefined : ManagementTypeFromJSON(json['managementType']),
        fixedFeeSpread: !exists(json, 'fixedFeeSpread') ? undefined : json['fixedFeeSpread'],
        managementFeePercent: !exists(json, 'managementFeePercent') ? undefined : json['managementFeePercent'],
        grossDividendYieldPercent: !exists(json, 'grossDividendYieldPercent')
            ? undefined
            : json['grossDividendYieldPercent'],
        annualisedReturnPercent: !exists(json, 'annualisedReturnPercent') ? undefined : json['annualisedReturnPercent'],
        firstTradingDateTimeUtc: !exists(json, 'firstTradingDateTimeUtc')
            ? undefined
            : json['firstTradingDateTimeUtc'] === null
              ? null
              : DateTime.fromISO(json['firstTradingDateTimeUtc'], {setZone: true}),
        lastTradingDateTimeUtc: !exists(json, 'lastTradingDateTimeUtc')
            ? undefined
            : json['lastTradingDateTimeUtc'] === null
              ? null
              : DateTime.fromISO(json['lastTradingDateTimeUtc'], {setZone: true}),
        ceo: !exists(json, 'ceo') ? undefined : json['ceo'],
        employees: !exists(json, 'employees') ? undefined : json['employees'],
        etfHoldings: !exists(json, 'etfHoldings') ? undefined : json['etfHoldings'],
    }
}

export function ListingResponseDtoToJSON(value?: ListingResponseDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        id: value.id,
        urlSlug: value.urlSlug,
        isClientReadOnly: value.isClientReadOnly,
        instrumentType: EsInstrumentTypeToJSON(value.instrumentType),
        isAdr: value.isAdr,
        isUsPartnership: value.isUsPartnership,
        isIlliquid: value.isIlliquid,
        symbol: value.symbol,
        kidsRecommended: value.kidsRecommended,
        isVolatile: value.isVolatile,
        name: value.name,
        description: value.description,
        drivewealthId: value.drivewealthId,
        categories: value.categories,
        logoIdentifier: value.logoIdentifier,
        logos: LogoUrlsDtoToJSON(value.logos),
        riskRating: value.riskRating,
        timeHorizonMin: value.timeHorizonMin,
        comparisonPrices: ComparisonPricesDtoToJSON(value.comparisonPrices),
        marketPrice: value.marketPrice,
        marketLastCheck: value.marketLastCheck.toISO(),
        extendedHoursPrice: value.extendedHoursPrice,
        extendedHoursLastCheck:
            value.extendedHoursLastCheck === undefined
                ? undefined
                : value.extendedHoursLastCheck === null
                  ? null
                  : value.extendedHoursLastCheck.toISO(),
        tradingStatus: TradingStatusToJSON(value.tradingStatus),
        extendedHoursTradingStatus: TradingStatusToJSON(value.extendedHoursTradingStatus),
        extendedHoursNotionalStatus: TradingStatusToJSON(value.extendedHoursNotionalStatus),
        exchangeCountry: CountryToJSON(value.exchangeCountry),
        peRatio: value.peRatio,
        marketCap: value.marketCap,
        parentInstrumentId: value.parentInstrumentId,
        fmsFundId: value.fmsFundId,
        underlyingInstrumentId: value.underlyingInstrumentId,
        sharesPerRight: value.sharesPerRight,
        offerPrice: value.offerPrice,
        exercisePrice: value.exercisePrice,
        websiteUrl: value.websiteUrl,
        exchange: value.exchange,
        legacyImageUrl: value.legacyImageUrl,
        dominantColour: value.dominantColour,
        pdsDriveId: value.pdsDriveId,
        assetManager: value.assetManager,
        managementType: ManagementTypeToJSON(value.managementType),
        fixedFeeSpread: value.fixedFeeSpread,
        managementFeePercent: value.managementFeePercent,
        grossDividendYieldPercent: value.grossDividendYieldPercent,
        annualisedReturnPercent: value.annualisedReturnPercent,
        firstTradingDateTimeUtc:
            value.firstTradingDateTimeUtc === undefined
                ? undefined
                : value.firstTradingDateTimeUtc === null
                  ? null
                  : value.firstTradingDateTimeUtc.toISO(),
        lastTradingDateTimeUtc:
            value.lastTradingDateTimeUtc === undefined
                ? undefined
                : value.lastTradingDateTimeUtc === null
                  ? null
                  : value.lastTradingDateTimeUtc.toISO(),
        ceo: value.ceo,
        employees: value.employees,
        etfHoldings: value.etfHoldings,
    }
}
