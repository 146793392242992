/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface PortfolioInvestingHistoryDetails
 */
export interface PortfolioInvestingHistoryDetails {
    /**
     *
     * @type {string}
     * @memberof PortfolioInvestingHistoryDetails
     */
    date: string
    /**
     *
     * @type {number}
     * @memberof PortfolioInvestingHistoryDetails
     */
    cost_basis: number
    /**
     *
     * @type {number}
     * @memberof PortfolioInvestingHistoryDetails
     */
    cost_basis_max: number
    /**
     *
     * @type {number}
     * @memberof PortfolioInvestingHistoryDetails
     */
    simple_return: number
    /**
     *
     * @type {number}
     * @memberof PortfolioInvestingHistoryDetails
     */
    total_return: number
    /**
     *
     * @type {number}
     * @memberof PortfolioInvestingHistoryDetails
     */
    unrealised_total_return: number
    /**
     *
     * @type {number}
     * @memberof PortfolioInvestingHistoryDetails
     */
    realised_capital_gain: number
    /**
     *
     * @type {number}
     * @memberof PortfolioInvestingHistoryDetails
     */
    portfolio_value: number
}

export function PortfolioInvestingHistoryDetailsFromJSON(json: any): PortfolioInvestingHistoryDetails {
    return PortfolioInvestingHistoryDetailsFromJSONTyped(json, false)
}

export function PortfolioInvestingHistoryDetailsFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): PortfolioInvestingHistoryDetails {
    if (json === undefined || json === null) {
        return json
    }
    return {
        date: json['date'],
        cost_basis: json['cost_basis'],
        cost_basis_max: json['cost_basis_max'],
        simple_return: json['simple_return'],
        total_return: json['total_return'],
        unrealised_total_return: json['unrealised_total_return'],
        realised_capital_gain: json['realised_capital_gain'],
        portfolio_value: json['portfolio_value'],
    }
}

export function PortfolioInvestingHistoryDetailsToJSON(value?: PortfolioInvestingHistoryDetails | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        date: value.date,
        cost_basis: value.cost_basis,
        cost_basis_max: value.cost_basis_max,
        simple_return: value.simple_return,
        total_return: value.total_return,
        unrealised_total_return: value.unrealised_total_return,
        realised_capital_gain: value.realised_capital_gain,
        portfolio_value: value.portfolio_value,
    }
}
