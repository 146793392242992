import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import {weightedAverageSharePrice} from '~/global/utils/order/order'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import BuyAndSellFeesManagedFund from '~/global/widgets/help-modals/BuyAndSellFeesManagedFund'
import {PriceInfoModal} from '~/global/widgets/help-modals/contract-notes/PriceInfo'
import {SharePriceValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import {BuySellOrder} from '~/store/accounting/types'
import {BasicInstrument, Instrument} from '~/store/instrument/types'
import styles from '../Record.scss'
import {RecordRow} from '../common'

export const OrderPlacedRow: React.FunctionComponent<{date: DateTime}> = ({date}) => {
    return (
        <RecordRow className={styles.recordRowWithDate} left="Order placed" right={date.toFormat(dateFormatWithYear)} />
    )
}

export const PartialFillAveragePriceInfo: React.FunctionComponent<{
    currency: string
    instrument: Instrument
    order: BuySellOrder
}> = ({currency, instrument, order}) => {
    const averageSharePrice = order.order_unit_price ? order.order_unit_price : weightedAverageSharePrice(order)
    const priceModalTitle =
        order.trades?.length <= 1
            ? `Price per ${shareLabel({instrument})}`
            : `Average price per ${shareLabel({instrument})}`

    return (
        <RecordRow
            left={
                <div>
                    {priceModalTitle}
                    <PriceInfoModal type={order.type} instrument={instrument} title={priceModalTitle} />
                </div>
            }
            right={<SharePriceValue value={averageSharePrice} currency={currency} />}
        />
    )
}

export const SharesBoughtOrSold: React.FunctionComponent<{
    hideBottomBorder: boolean
    instrument: BasicInstrument
    orderType: 'buy' | 'sell' | 'BUY' | 'SELL'
    shareValue: string
    dataTestId?: string
}> = ({hideBottomBorder, instrument, orderType, shareValue, dataTestId}) => {
    return (
        <RecordRow
            className={cn({[styles.recordRowNoBorder]: hideBottomBorder, [styles.recordRow]: !hideBottomBorder})}
            left={
                orderType.toLowerCase() === 'buy'
                    ? `${shareLabel({instrument, isPlural: true, isCapitalised: true})} bought`
                    : `${shareLabel({instrument, isPlural: true, isCapitalised: true})} sold`
            }
            right={<ShareValue value={shareValue} showFullValue />}
            dataTestId={dataTestId}
        />
    )
}

export const TransactionFeeInfo: React.FunctionComponent<{assetManagerName: string; isEstimated: boolean}> = ({
    assetManagerName,
    isEstimated,
}) => {
    const feeText = isEstimated
        ? `Estimated transaction fee charged by ${assetManagerName}`
        : `Transaction fee charged by ${assetManagerName}`

    return (
        <div>
            {feeText} <BuyAndSellFeesManagedFund />
        </div>
    )
}
