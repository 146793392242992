import {Button} from '@design-system/button'
import {withFormik} from 'formik'
import React from 'react'
import {Model, Request} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {ErrorBox, validate} from '~/global/widgets/form-controls'
import {
    CountriesType,
    extractCountryNameFromCode,
} from '~/global/widgets/form-controls/country-select/CountrySelectInput'
import {ButtonGroup, CountrySelectInput} from '~/global/widgets/form-controls/formik'
import TaxResidency from '~/global/widgets/help-modals/TaxResidency'

interface DeclarationFormValues {
    foreign_tax_resident?: 'yes' | 'no'
    countries: CountriesType
}

interface DeclarationFormProps {
    isDependent: boolean
    jurisdiction: Model.User['jurisdiction']
    preferredName: string
    onSuccess: () => void
    signUpTaxQuestions: (
        foreign_tax_resident: boolean,
        countries: Request.IdentitySignUpTaxQuestions['countries'],
    ) => Promise<string | undefined>
}

const TaxDeclarationForm = withFormik<DeclarationFormProps, DeclarationFormValues>({
    mapPropsToValues: () => ({
        foreign_tax_resident: undefined,
        countries: [{country: '', tax_information_number: ''}],
    }),
    handleSubmit: async (
        {foreign_tax_resident, countries},
        {setSubmitting, setStatus, props: {signUpTaxQuestions, onSuccess}},
    ) => {
        type ValidCountry = Request.IdentitySignUpTaxQuestions['countries'][0]
        const validCountries = countries.filter((c): c is ValidCountry => c.country !== '')

        try {
            const error = await signUpTaxQuestions(foreign_tax_resident === 'yes', validCountries)
            if (error) {
                setStatus(error)
                setSubmitting(false)
                return
            }
            onSuccess()
        } catch (e) {
            setStatus(unknownErrorMessage)
            setSubmitting(false)
            throw e
        }
    },
    validate: validate.generate<DeclarationFormValues>({
        foreign_tax_resident: [validate.required('You must answer yes or no')],
        countries: [
            (value, values) => {
                type ValidCountry = Request.IdentitySignUpTaxQuestions['countries'][0]
                const validCountries = value.filter((c): c is ValidCountry => c.country !== '')
                if (values.foreign_tax_resident === 'yes' && validCountries.length === 0) {
                    return 'You must enter at least one country'
                }
            },
        ],
    }),
})(({isSubmitting, handleSubmit, status, errors, values, isValid, isDependent, preferredName, jurisdiction}) => {
    const defaultTaxResidency = extractCountryNameFromCode(jurisdiction!.toUpperCase())

    return (
        <form onSubmit={handleSubmit}>
            <p className={spacing.spaceBelow16}>
                <strong>
                    {isDependent ? `Is ${preferredName}` : 'Are you'} a tax resident of another country outside{' '}
                    {jurisdiction === 'nz' ? 'NZ' : defaultTaxResidency} <TaxResidency jurisdiction={jurisdiction} />
                </strong>
            </p>
            <ButtonGroup
                name="foreign_tax_resident"
                details={[
                    {
                        label: 'Yes',
                        value: 'yes',
                    },
                    {
                        label: 'No',
                        value: 'no',
                    },
                ]}
            />
            {values.foreign_tax_resident === 'yes' && (
                <CountrySelectInput
                    isTaxCountryInput
                    name="countries"
                    countryLabel={
                        isDependent
                            ? `Which country is ${preferredName} a tax resident of?`
                            : 'Which country are you a tax resident of?'
                    }
                    jurisdiction={jurisdiction}
                />
            )}
            <ErrorBox message={status || errors.foreign_tax_resident} />
            {values.foreign_tax_resident !== undefined && (
                <Button
                    dataTestId="button--next"
                    label="Next"
                    isSubmit
                    pageButton
                    disabled={!isValid}
                    processing={isSubmitting}
                />
            )}
        </form>
    )
})

export default TaxDeclarationForm
