import {Button} from '@design-system/button'
import cn from 'classnames'
import {withFormik, FormikErrors} from 'formik'
import React from 'react'
import {Model, Request} from '~/api/retail/types'
import {page, spacing} from '~/global/scss/helpers'
import {ErrorBox} from '~/global/widgets/form-controls'
import {
    COUNTRIES,
    CountriesType,
    extractCountries,
} from '~/global/widgets/form-controls/country-select/CountrySelectInput'
import {CountrySelectInput} from '~/global/widgets/form-controls/formik'
import Citizenship from '~/global/widgets/help-modals/Citizenship'
import {State} from '~/store/identity/types'
import styles from './EditCitizenshipForm.scss'

interface FormValues {
    isCitizenInJurisdiction?: boolean
    hasForeignCitizenship?: boolean
    countries: CountriesType
}

const EditCitizenshipForm = withFormik<EditCitizenshipProps, FormValues>({
    mapPropsToValues: ({citizenships, jurisdiction}) => {
        const jurisdictionCode = jurisdiction!.toUpperCase()

        if (!citizenships || !citizenships.length) {
            return {
                isCitizenInJurisdiction: undefined,
                hasForeignCitizenship: undefined,
                countries: [{country: '', tax_information_number: ''}],
            }
        }

        const foreignCitizenships = citizenships.filter(citizenship => citizenship !== jurisdictionCode)
        return {
            isCitizenInJurisdiction: citizenships.filter(citizenship => citizenship === jurisdictionCode).length > 0,
            hasForeignCitizenship: foreignCitizenships.length > 0,
            countries: foreignCitizenships.map(foreignCitizenship => ({
                country: foreignCitizenship,
                tax_information_number: '',
            })),
        }
    },
    handleSubmit: async (
        {isCitizenInJurisdiction, countries},
        {setSubmitting, setStatus, props: {successAction, onSubmit, jurisdiction}},
    ) => {
        const formattedCountries = extractCountries(countries)

        setSubmitting(true)
        try {
            const errorMessage = await onSubmit(
                isCitizenInJurisdiction
                    ? [
                          ...formattedCountries,
                          //extract the country code of the jurisdiction country
                          ...COUNTRIES.filter(country => country[0] === jurisdiction!.toUpperCase()).map(
                              country => country[0],
                          ),
                      ]
                    : formattedCountries,
            )
            if (errorMessage) {
                setStatus(errorMessage)
                setSubmitting(false)
            } else {
                successAction()
            }
        } catch (error) {
            setStatus(error)
            setSubmitting(false)
            throw error
        }
    },
    validate: ({countries, hasForeignCitizenship}) => {
        const errors: FormikErrors<FormValues> = {}
        const formattedCountries = extractCountries(countries)

        if (
            hasForeignCitizenship &&
            (formattedCountries.length === 0 || !!countries.find(country => country.country === ''))
        ) {
            errors.countries = 'Oops, you haven’t added a country'
        }
        return errors
    },
})(({
    isSubmitting,
    handleSubmit,
    status,
    values,
    setValues,
    isValid,
    isDependent,
    preferredName,
    jurisdiction,
    citizenships,
    validateForm,
}) => {
    const {isCitizenInJurisdiction, hasForeignCitizenship, countries} = values
    const formattedCountries = countries.map(country => country.country)
    const jurisdictionCode = jurisdiction!.toUpperCase()

    // checks whether the user has made changes to citizenship details - if not, the save button will be disabled
    const hasCitizenshipChanged = React.useMemo((): boolean => {
        // user hasn't selected anything before - button will always be enabled
        if (!citizenships || !citizenships.length) {
            return true
        }

        if (hasForeignCitizenship === false) {
            return true
        }

        // determining whether the user has added or removed a country from their citizenship details
        return (
            citizenships.sort().toString() !==
            (isCitizenInJurisdiction ? [jurisdictionCode, ...formattedCountries] : formattedCountries).sort().toString()
        )
    }, [citizenships, values])

    const handleChange = (newValues: FormValues) => {
        setValues(newValues)
        // pass new values to validate against
        validateForm(newValues)
    }

    return (
        <form onSubmit={handleSubmit}>
            <p className={spacing.spaceBelow16}>
                <strong id="citizen-question" className={styles.subheading}>
                    {isDependent ? `Is ${preferredName}` : 'Are you'} an {jurisdiction === 'nz' ? 'NZ' : 'Australian'}{' '}
                    citizen?
                </strong>
            </p>
            <p className={cn(spacing.spaceBelow24, page.flexRow)} aria-labelledby="citizen-question">
                <span>
                    <Button
                        label="Yes"
                        type={values.isCitizenInJurisdiction === true ? 'primary' : 'secondary'}
                        onClick={() => handleChange({...values, isCitizenInJurisdiction: true})}
                        dataTestId="button--yes-citizen"
                    />
                </span>
                <span>
                    <Button
                        label="No"
                        type={values.isCitizenInJurisdiction === false ? 'primary' : 'secondary'}
                        onClick={() => handleChange({...values, isCitizenInJurisdiction: false})}
                        dataTestId="button--no-citizen"
                    />
                </span>
            </p>

            <p className={spacing.spaceBelow16} id="other-countries-question">
                <strong className={styles.subheading}>
                    {isDependent ? `Is ${preferredName}` : 'Are you'} a citizen of any other countries?
                </strong>
                <Citizenship />
            </p>
            <p className={cn(spacing.spaceBelow24, page.flexRow)} aria-labelledby="other-countries-question">
                <span>
                    <Button
                        label="Yes"
                        type={values.hasForeignCitizenship === true ? 'primary' : 'secondary'}
                        onClick={() => {
                            handleChange({
                                ...values,
                                hasForeignCitizenship: true,
                                countries: [{country: '', tax_information_number: ''}],
                            })
                        }}
                        dataTestId="button--yes-other-countries"
                    />
                </span>
                <span>
                    <Button
                        label="No"
                        type={values.hasForeignCitizenship === false ? 'primary' : 'secondary'}
                        onClick={() => {
                            handleChange({
                                ...values,
                                hasForeignCitizenship: false,
                                countries: [{country: '', tax_information_number: ''}],
                            })
                        }}
                        dataTestId="button--no-other-countries"
                    />
                </span>
            </p>

            {hasForeignCitizenship && (
                <CountrySelectInput
                    isCitizenshipCountryInput
                    name="countries"
                    countryLabel={`Which country ${isDependent ? `is ${preferredName}` : 'are you'} a citizen of?`}
                    jurisdiction={jurisdiction}
                />
            )}
            <ErrorBox message={status} />
            {isCitizenInJurisdiction !== undefined && hasForeignCitizenship !== undefined && (
                <Button
                    label="Save"
                    isSubmit
                    pageButton
                    disabled={
                        !isValid || !hasCitizenshipChanged || (!isCitizenInJurisdiction && !hasForeignCitizenship)
                    }
                    processing={isSubmitting}
                    dataTestId="button--save-citizenship"
                />
            )}
        </form>
    )
})

interface EditCitizenshipProps {
    isDependent: boolean
    preferredName: string
    jurisdiction: Model.User['jurisdiction']
    citizenships: State['citizenships']
    onSubmit(countries: Request.SignUpUSSetCitizenship['countries']): Promise<void | null | string>
    successAction(): void
}

export default EditCitizenshipForm
