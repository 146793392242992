import cn from 'classnames'
import React from 'react'
import {EventDataTaxonomy} from '~/api/rudderstack/event-taxonomy/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {useHasInsureAccess} from '~/global/state-hooks/retail/useHasInsureAccess'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Link} from '~/migrate-react-router'
import styles from '~/sections/explore/Explore.scss'
import {InsureTile} from '~/sections/explore/widgets/what-is-next/InsureTile'
import invest from '~/sections/explore/widgets/what-is-next/assets/images/invest.png'
import kids from '~/sections/explore/widgets/what-is-next/assets/images/kids.png'
import kiwisaver from '~/sections/explore/widgets/what-is-next/assets/images/kiwisaver.png'
import save from '~/sections/explore/widgets/what-is-next/assets/images/save.png'
import {useAppSelector, useAppDispatch} from '~/store/hooks'
import saveActions from '~/store/save/actions'

type TrackWhatsNextType = EventDataTaxonomy['explore']['whats_next_clicked']['type']

const WhatsNext: React.FunctionComponent = (): JSX.Element | null => {
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const hasInsureAccess = useHasInsureAccess()
    const interestRate = useAppSelector(s => s.save.interestRate)
    const isDependent = useAppSelector(s => s.identity.user?.is_dependent)
    const isEligibleForKiwisaver = useAppSelector(s => s.identity.isEligibleForKiwisaver)
    const jurisdiction = useAppSelector(s => s.identity.user?.jurisdiction)

    const track = (type: TrackWhatsNextType) => {
        rudderTrack('explore', 'whats_next_clicked', {type})
    }

    React.useEffect(() => {
        // Get interest rate if missing
        if (!interestRate) {
            dispatch(saveActions.getInterestRate())
        }
    }, [])

    return (
        <>
            <h2>What's next?</h2>

            <div className={styles.whatsNext}>
                <Link to={profileUrl('invest/search')} onClick={() => track('invest')}>
                    <div className={cn(styles.whatNextBox, styles.invest)}>
                        <h3>Invest</h3>
                        {jurisdiction === 'nz' ? (
                            <p>Buy NZ, US, and Australian shares</p>
                        ) : (
                            <p>Buy Australian, NZ, and US shares</p>
                        )}

                        <img src={invest} />
                    </div>
                </Link>

                <Link to={profileUrl('save/intro')} onClick={() => track('save')}>
                    <div className={cn(styles.whatNextBox, styles.save)}>
                        <h3>Save</h3>

                        <p>{interestRate ? `Save with no limits at ${interestRate}% p.a.` : 'Save with no limits.'}</p>
                        <img src={save} />
                    </div>
                </Link>

                {isEligibleForKiwisaver && (
                    <Link to={profileUrl('kiwisaver')} onClick={() => track('kiwisaver')}>
                        <div className={cn(styles.whatNextBox, styles.kiwisaver)}>
                            <h3>KiwiSaver</h3>
                            <p>Prep for the future with more choice</p>
                            <img src={kiwisaver} />
                        </div>
                    </Link>
                )}

                {!isDependent && jurisdiction === 'nz' && (
                    <Link to={profileUrl('settings/create-kids-account')} onClick={() => track('kids')}>
                        <div className={cn(styles.whatNextBox, styles.kids)}>
                            <h3>Kids</h3>
                            <p>Kick-start a kid’s financial journey</p>
                            <img src={kids} />
                        </div>
                    </Link>
                )}

                {hasInsureAccess && <InsureTile />}
            </div>

            <div className={styles.saveInterest}>Save interest rate is subject to change.</div>
        </>
    )
}

export default WhatsNext
