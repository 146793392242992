import React from 'react'
import {Model, Request} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import EditCitizenshipForm from '~/global/widgets/edit-citizenship-form/EditCitizenshipForm'
import {Loading} from '~/global/widgets/loading/Loading'
import {connect} from '~/store/connect'
import {State as IdentityState} from '~/store/identity/types'
import signUpActions from '~/store/sign-up/actions'
import {State as SignupState} from '~/store/sign-up/types'

const EditCitizenship: React.FunctionComponent<EditCitizenshipProps> = ({
    usStatus,
    preferredName,
    isDependent,
    jurisdiction,
    isEditing,
    updateCitizenships,
}) => {
    if (!usStatus) {
        return <Loading isPineapple />
    }

    return (
        <EditCitizenshipForm
            onSubmit={updateCitizenships}
            citizenships={usStatus.citizenship as IdentityState['citizenships']}
            isDependent={isDependent}
            preferredName={preferredName}
            jurisdiction={jurisdiction}
            successAction={() => {
                rudderTrack('signup', 'us_shares_citizenship_clicked')
                isEditing(false)
            }}
        />
    )
}

interface StoreProps {
    usStatus: SignupState['usStatus']
    preferredName: string
    isDependent: boolean
    jurisdiction: Model.User['jurisdiction']
}

interface DispatchProps {
    updateCitizenships(countries: Request.SignUpUSSetCitizenship['countries']): Promise<void | null | string>
}

interface OwnProps {
    isEditing: React.Dispatch<React.SetStateAction<boolean>>
}

type EditCitizenshipProps = StoreProps & DispatchProps & OwnProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    ({identity, signUp}) => ({
        usStatus: signUp.usStatus,
        isDependent: identity.user!.is_dependent,
        preferredName: identity.user!.preferred_name,
        jurisdiction: identity.user!.jurisdiction,
    }),
    dispatch => ({
        updateCitizenships: countries => dispatch(signUpActions.usSetCitizenShip(countries)),
    }),
)(EditCitizenship)
