import Decimal from 'decimal.js'
import {isOnASX, isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {Instrument} from '~/store/instrument/types'

export const getPriceLimitDecimalPlaces = (instrument: Instrument): number => {
    const marketPrice = new Decimal(instrument.marketPrice)
    // not all ASX shares trades in partial cent values
    if (isOnASX(instrument)) {
        // Market price up to $2, set to 3dp to meet price stepping guidelines (https://www2.asx.com.au/markets/trade-our-cash-market/asx-equities-trading)
        if (marketPrice.lessThanOrEqualTo(2)) {
            return 3
        }
        return 2
    }
    // US shares do not trade in partial cent values
    if (isOnMainUsExchange(instrument)) {
        return 2
    }
    // default
    return 3
}
