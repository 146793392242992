import cn from 'classnames'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {Response} from '~/api/retail/types'
import {accessibility} from '~/global/scss/helpers'
import JoinedInSentence from '~/global/widgets/joined-in-sentence/JoinedInSentence'
import {useNavigate} from '~/migrate-react-router'
import recentActivityStyles from '~/sections/invest/widgets/recent-investing-activity/RecentInvestingActivity.scss'

const PortfolioOrderRecentInvestingActivityCount: React.FunctionComponent<OwnProps> = ({recentOrders, url}) => {
    const navigate = useNavigate()

    const description = (count: number, displayText: string) => {
        return (
            <span>
                {count}&nbsp;{displayText}
                {count > 1 ? 's' : ''}
            </span>
        )
    }

    return (
        <button
            className={cn(
                recentActivityStyles.recentOrders,
                {[recentActivityStyles.portfolioRecentOrders]: url.includes('invest')},
                accessibility.button,
            )}
            data-testid="button--view-recent-investing-activity"
            aria-label="View recent investing activity"
            type="button"
            onClick={() => {
                Analytics.event({
                    category: url.includes('invest') ? 'Portfolio' : 'Instrument',
                    action: 'Clicked portfolio investing activity',
                    label: 'Portfolio investing activity',
                })
                navigate(url)
            }}
        >
            <div className={recentActivityStyles.recentOrdersContent}>
                <p className={recentActivityStyles.recentOrdersHeading}>Investing activity</p>
                <p>
                    <JoinedInSentence nodes={[description(recentOrders.orders.length, 'order')]} />
                </p>
                <p className={recentActivityStyles.recentOrdersView}>View</p>
            </div>
        </button>
    )
}

interface OwnProps {
    recentOrders: Response.PortfolioOrders
    url: string
}

export default PortfolioOrderRecentInvestingActivityCount
