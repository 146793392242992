import {Thumbnail} from '@design-system/thumbnail'
import cn from 'classnames'
import React from 'react'
import {Link} from 'react-router-dom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Image from '~/global/widgets/image/Image'
import shadowDark from '~/sections/invest/sections/view-instrument/sections/employee-share-scheme/widgets/shares-are-on-their-way/assets/images/shadow-dark.svg'
import shadow from '~/sections/invest/sections/view-instrument/sections/employee-share-scheme/widgets/shares-are-on-their-way/assets/images/shadow.svg'
import {EssViews} from '~/store/employeeShareScheme/selectors'
import {Instrument} from '~/store/instrument/types'
import styles from './SharesAreOnTheirWay.scss'

interface SharesAreOnTheirWayProps {
    instrument: Instrument
    resourcePath: string
    essViews: EssViews
}

const SharesAreOnTheirWay: React.FunctionComponent<SharesAreOnTheirWayProps> = React.memo(
    ({instrument, resourcePath, essViews}) => {
        const profileUrl = useProfileUrl()

        return (
            <div className={cn(styles.cardContainer, spacing.spaceBelow24)} data-testid="card--ess-shares-on-their-way">
                <h2 className={spacing.spaceBelow16}>Shares to receive</h2>
                <div className={styles.thumbnailWrapper}>
                    <Thumbnail
                        additionalClassName={styles.thumbnail}
                        dataTestId="card--ess-shares-on-their-way-logo"
                        symbol={instrument.symbol}
                        path={instrument.logos.thumb ? `${resourcePath}${instrument.logos.thumb}` : undefined}
                        width="52px"
                        height="52px"
                    />
                    <Image src={shadow} darkSrc={shadowDark} alt="" />
                </div>
                <p className={spacing.spaceAbove16}>
                    You will see your allocations here soon. <br />
                    {essViews.isActiveMember ? (
                        <>
                            In the meantime,{' '}
                            <Link
                                data-testid="link--learn-more-about-your-share-scheme"
                                onClick={() => {
                                    rudderTrack('ess_scheme', 'scheme_view_clicked')
                                }}
                                to={profileUrl('employee-share-scheme/:instrumentId/schemes', {
                                    instrumentId: instrument.id,
                                })}
                            >
                                learn more about your Share Scheme
                            </Link>
                            .
                        </>
                    ) : (
                        'Hang tight!'
                    )}
                </p>
            </div>
        )
    },
)

export default SharesAreOnTheirWay
