import React from 'react'
import {ListingResponseDto} from '~/api/distill'
import {DistillScope} from '~/api/query/distill'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import useAssetManager from '~/global/utils/use-asset-manager/useAssetManager'
import Concertina from '~/global/widgets/concertina/Concertina'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {InstrumentReturn} from '~/store/portfolio/types'
import styles from './Details.scss'

const AmountPutInDetail = ({
    currentReturn,
    instrument,
    currency,
}: {
    currentReturn: InstrumentReturn
    instrument: ListingResponseDto
    currency: string
}) => {
    const assetManager = useAssetManager(instrument, {
        scope: DistillScope.KIWISAVER_ALL_FUNDS,
        searchFundInvestments: true,
    })

    const instrumentTypePlural = shareLabel({instrument, isPlural: true})

    let managedFundCostOfUnitsDetailText = ''

    if (assetManager) {
        managedFundCostOfUnitsDetailText = ` This includes the management fee charged by ${assetManager.name}.`
    }

    return (
        <div>
            <h1>Amount put in</h1>
            <p>
                The amount you’ve spent on this investment. This doesn’t include foreign exchange fees you’ve paid when
                buying units.
            </p>

            <Concertina
                title={
                    <div className={styles.concertinaTitle}>
                        <p className={styles.leftText}>Cost of {instrumentTypePlural}</p>{' '}
                        <DollarValue
                            value={currentReturn.cost_basis - currentReturn.amount_put_in_detail.transaction_fees_buys}
                            currency={currency}
                        />
                    </div>
                }
                body={
                    <p className={styles.concertinaBody}>
                        The amount you've paid when buying this investment.{managedFundCostOfUnitsDetailText}
                    </p>
                }
                border
            />

            {tradingType(instrument) !== 'managed' && (
                <Concertina
                    title={
                        <div className={styles.concertinaTitle}>
                            <p className={styles.leftText}>Transaction fees</p>{' '}
                            <DollarValue
                                value={currentReturn.amount_put_in_detail.transaction_fees_buys}
                                currency={currency}
                            />
                        </div>
                    }
                    body={
                        <p className={styles.concertinaBody}>
                            The transaction fees you've paid when buying this investment.
                        </p>
                    }
                    border
                />
            )}

            {tradingType(instrument) === 'managed' && assetManager && (
                <Concertina
                    title={
                        <div className={styles.concertinaTitle}>
                            <p className={styles.leftText}>Managed fund transaction fees</p>{' '}
                            <DollarValue
                                value={currentReturn.amount_put_in_detail.unrealised_managed_fund_transaction_fees || 0}
                                currency={currency}
                            />
                        </div>
                    }
                    body={
                        <p className={styles.concertinaBody}>
                            The transaction fees you've paid when buying this investment. These are charged by the fund,
                            not Sharesies. Transaction fees are estimates until they’re confirmed by {assetManager.name}
                            —this can take up to 30 days.
                        </p>
                    }
                    border
                />
            )}

            <Concertina
                title={
                    <div className={styles.concertinaTitle}>
                        <p className={styles.leftText}>Amount put in</p>{' '}
                        <p className={styles.lastRightText}>
                            <DollarValue value={currentReturn.cost_basis} currency={currency} />
                        </p>
                    </div>
                }
                border
                noChevron
            />
        </div>
    )
}

export default AmountPutInDetail
