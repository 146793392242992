export const coveClientErrorFallbackRender = (error: Error, component: JSX.Element) => {
    const isCoveClientError = 'code' in error && error.code === 'cove_client_error'

    if (isCoveClientError) {
        return component
    }

    // If it's not Cove's fault, then rethrow the error - it falls back to the next error boundary up
    throw error
}
