import {Button} from '@design-system/button'
import {useQueryClient} from '@tanstack/react-query'
import {Form, Formik, FormikHelpers} from 'formik'
import React from 'react'
import {useRetailPost} from '~/api/query/retail'
import {page, spacing} from '~/global/scss/helpers'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {validate} from '~/global/widgets/form-controls'
import {StrongNumber} from '~/global/widgets/form-controls/formik'
import {usePortfolioSettings} from '~/sections/moose/data/retail'
import {FinDescription} from '~/sections/moose/widgets/fin-description/FinDescription'

interface AddFinFormProps {
    portfolioId: string
    submitButtonLabel: string
    onSuccess: () => void
    onQueueForLater?: () => void
}

interface AddFinFormValues {
    fin: string
}

export const AddFinForm: React.FunctionComponent<AddFinFormProps> = ({
    portfolioId,
    submitButtonLabel,
    onSuccess,
    onQueueForLater,
}) => {
    const queryClient = useQueryClient()
    const portfolioSettings = usePortfolioSettings(portfolioId)
    const updateSettingsQuery = useRetailPost({
        path: 'fonterra/portfolios/:portfolio_id/settings/update-fin',
        pathParams: {portfolio_id: portfolioId},
    })

    const invaldatePortfolioSettings = () =>
        queryClient.invalidateQueries([`fonterra/portfolios/${portfolioId}/settings`])

    const onSubmit = async (values: AddFinFormValues, actions: FormikHelpers<AddFinFormValues>): Promise<void> => {
        actions.setErrors({}) // reset errors

        try {
            const response = await updateSettingsQuery.mutateAsync({fin: values.fin})
            switch (response.fin_updated) {
                case 'UPDATED':
                    await invaldatePortfolioSettings()
                    onSuccess()
                    break
                case 'INVALID_NO_UPDATE':
                    actions.setErrors({
                        fin: 'The FIN you’ve entered is invalid. After multiple wrong attempts, your CSN will be locked.',
                    })
                    break
                case 'UNKNOWN_NO_UPDATE':
                    actions.setErrors({
                        fin: 'Sorry, we were unable to validate your FIN, please try again later. For more help, contact Computershare.',
                    })
                    break
                case 'LOCKED_NO_UPDATE':
                    actions.setErrors({
                        fin: 'Your CSN is locked. You’ll need to contact Computershare to have your CSN unlocked. You can’t sell any shares until then.',
                    })
                    break
                case 'EXTERNAL_TIMEOUT_NO_UPDATE':
                    await invaldatePortfolioSettings()
                    if (onQueueForLater) {
                        onQueueForLater()
                    }
                    break
            }
        } catch (error) {
            actions.setErrors({fin: 'We were unable to verify your FIN.'})
        } finally {
            actions.setSubmitting(false)
        }
    }

    return (
        <Formik
            initialValues={{fin: ''} as AddFinFormValues}
            validate={validate.generate({
                fin: [validate.required(), validate.minDigits(4), validate.maxDigits(4)],
            })}
            onSubmit={async (values, actions) => {
                await onSubmit(values, actions)
            }}
        >
            {({isValid, values, isSubmitting}) => (
                <>
                    <FinDescription />
                    <Form className={spacing.spaceAbove40}>
                        <StrongNumber
                            normalisation="numberOnly"
                            maxLength={4}
                            decimalPlaces={0}
                            dataTestId="strong-number--fin"
                            name="fin"
                            label="FIN"
                            helpText={`For CSN: ${portfolioSettings.default_csn.csn}`}
                            disabled={isSubmitting}
                        />
                        <AlertCard type="info" className={spacing.spaceAbove24}>
                            <p>Your FIN will be saved so you don’t have to enter it again.</p>
                        </AlertCard>
                        <ActionBar className={page.flexRow}>
                            <Button
                                dataTestId="button--save-fin"
                                label={submitButtonLabel}
                                disabled={!isValid || values.fin === ''}
                                isSubmit
                                processing={isSubmitting}
                            />
                        </ActionBar>
                    </Form>
                </>
            )}
        </Formik>
    )
}
