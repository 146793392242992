import {Button} from '@design-system/button'
import {Flag} from '@design-system/flag'
import {useQueryClient} from '@tanstack/react-query'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {DeleteQuoteRequest} from '~/api/cove/types'
import {constructCoveGetUrl, coveGetUrlQueryString, useCoveCustomerPost} from '~/api/query/cove'
import {useRetailGet} from '~/api/query/retail'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {dateFormatShortMonth} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Toast} from '~/global/widgets/toast/Toast'
import {PolicyQuoteCommonProps} from '~/sections/protect/sections/insure/sections/landing/utils/car/car'
import commonStyles from '~/sections/protect/sections/insure/sections/landing/utils/common-styles/commonStyles.scss'
import {Row} from '~/sections/protect/sections/insure/sections/landing/widgets/row/Row'
import {reportNoInsureAccountToRollbar} from '~/sections/protect/sections/insure/utils/report-rollbar-error/reportRollbarError'
import {PolicyQuoteCommonRows} from '~/sections/protect/sections/insure/widgets/policy-quote-common-rows/PolicyQuoteCommonRows'

interface QuoteProps extends PolicyQuoteCommonProps {
    validUntilDate: Date
}

export const Quote: React.FunctionComponent<QuoteProps> = ({
    carValue,
    code,
    coverage,
    excess,
    name,
    premiumCost,
    premiumFrequency,
    validUntilDate,
}) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const queryClient = useQueryClient()

    const {data: insureAccount} = useRetailGet({path: 'insure/get-account'})

    if (insureAccount.type !== 'insure_account') {
        // If they have a Cove quote then they must have an Insure account

        reportNoInsureAccountToRollbar()
        throw new Error('No Insure account found when trying to delete Cove quote')
    }

    // Delete a quote
    // 1. Instantiate the hook
    const deleteCoveQuote = useCoveCustomerPost('quote/v1/remove')

    const deleteQuote = async () => {
        // 2. Construct the params
        const deleteQuoteParams: DeleteQuoteRequest = {
            quote_id: code,
        }

        // 3. Call the mutate function
        const response = await deleteCoveQuote.mutateAsync(deleteQuoteParams)

        // 4. Handle the response
        if (response.success) {
            // Reset the quote & counts query caches to ensure that they'll be refetched
            const getCoveQuotesUrl = constructCoveGetUrl('quote/v1/queryByCustomer', insureAccount.cove_customer_id)
            const getCoveCountsUrl = constructCoveGetUrl('customer/v1/counts', insureAccount.cove_customer_id)
            queryClient.resetQueries({queryKey: [coveGetUrlQueryString(getCoveQuotesUrl)]})
            queryClient.resetQueries({queryKey: [coveGetUrlQueryString(getCoveCountsUrl)]})
        }
    }

    const handleDeleteQuote = async () => {
        setIsLoading(true)
        try {
            await deleteQuote()
            Toast('Your quote has been deleted.')
        } catch (error) {
            Toast('An error has occurred! Please try again')
            throw error
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={cn(commonStyles.card, commonStyles.isQuote)} data-testid={`cove-quote-${code}`}>
            <div className={commonStyles.cardMainContent}>
                <h3 className={commonStyles.cardTitle}>{name}</h3>
                <p className={cn(commonStyles.cardSubtitle, spacing.spaceAbove8, spacing.spaceBelow16)}>
                    Quote: {code}
                </p>

                <PolicyQuoteCommonRows carValue={carValue} coverage={coverage} excess={excess} />
                <Row label="Valid until" value={validUntilDate.toLocaleDateString('en-NZ', dateFormatShortMonth)} />
                <div data-testid="footer--premium-summary" className={commonStyles.premiumWrapper}>
                    <p className={cn(commonStyles.premiumLabel, spacing.spaceAbove16)}>{premiumFrequency} premium</p>
                    <div className={spacing.spaceBelow24}>
                        <div className={commonStyles.premiumFooter}>
                            <Flag type="neutral" isSubtle dataTestId="flag--insure">
                                Quote
                            </Flag>
                            <p className={commonStyles.premiumAmount}>${parseFloat(premiumCost).toFixed(2)} NZD</p>
                        </div>
                    </div>
                </div>
                <Button
                    label="Continue with quote"
                    type="primary"
                    onClick={() => {
                        rudderTrack('insurance_signup', 'quote_continue_clicked', {source: 'landing'})
                        navigate(profileUrl('protect/insure/car/cove-quote/:quoteId', {quoteId: code}))
                    }}
                    dataTestId="button--continue-quote"
                    additionalClassName={cn(spacing.spaceAbove16, spacing.spaceBelow16)}
                />
                <Button
                    label="Delete quote"
                    type="secondary"
                    onClick={handleDeleteQuote}
                    dataTestId="button--delete-quote"
                    processing={isLoading}
                    additionalClassName={spacing.spaceAbove16}
                />
            </div>
        </div>
    )
}
