/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The order of these types is deliberate.  Records with identical timestamps will be run in the following order. This is crucial for any records involved in a merger, acquisition or spinoff.
 * @export
 * @enum {string}
 */
export enum RecordType {
    BUY = 'BUY',
    SELL = 'SELL',
    SPLIT = 'SPLIT',
    BONUS = 'BONUS',
    CONSOLIDATION = 'CONSOLIDATION',
    CANCELLATION = 'CANCELLATION',
    CAPITAL_RETURN = 'CAPITAL_RETURN',
    ADJUST_COST_BASE = 'ADJUST_COST_BASE',
    OPENING_BALANCE = 'OPENING_BALANCE',
    MERGER_ACQUISITION_ADD_CASH = 'MERGER_ACQUISITION_ADD_CASH',
    MERGER_ACQUISITION_ADD_SHARES = 'MERGER_ACQUISITION_ADD_SHARES',
    MERGER_ACQUISITION_REMOVE_SHARES = 'MERGER_ACQUISITION_REMOVE_SHARES',
    SPINOFF = 'SPINOFF',
    SPINOFF_REMOVE_COST_BASIS = 'SPINOFF_REMOVE_COST_BASIS',
    DIVIDEND = 'DIVIDEND',
    CSN_TRANSFER_IN = 'CSN_TRANSFER_IN',
    CSN_TRANSFER_OUT = 'CSN_TRANSFER_OUT',
    MUTUAL_TAX_EVENT = 'MUTUAL_TAX_EVENT',
    CA_ADD_SHARES = 'CA_ADD_SHARES',
    CA_COPY_COST_BASIS = 'CA_COPY_COST_BASIS',
    CA_ADJUST_COST_BASIS = 'CA_ADJUST_COST_BASIS',
    CA_REMOVE_SHARES = 'CA_REMOVE_SHARES',
    ESS_ALLOCATE_SHARES = 'ESS_ALLOCATE_SHARES',
    ESS_VEST_SHARES = 'ESS_VEST_SHARES',
    ESS_FORFEIT_SHARES = 'ESS_FORFEIT_SHARES',
}

export function RecordTypeFromJSON(json: any): RecordType {
    return RecordTypeFromJSONTyped(json, false)
}

export function RecordTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordType {
    return json as RecordType
}

export function RecordTypeToJSON(value?: RecordType | null): any {
    return value as any
}
