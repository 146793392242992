import {Modal} from '@design-system/modal'
import {useFeature} from '@growthbook/growthbook-react'
import cn from 'classnames'
import * as React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useAutoinvestOrders} from '~/sections/invest/sections/auto-invest/hooks/useAutoinvestOrders'
import landing from '~/sections/invest/sections/order-flow/sections/buy/widgets/auto-invest-nudge/assets/images/landing.svg'
import accounting_actions from '~/store/accounting/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import styles from './AutoinvestNudge.scss'

export const useShouldAutoinvestNudge = () => {
    const dispatch = useAppDispatch()
    const gbTestVariant = useFeature<number>('third-purchase-prompt').value
    const {buyCount, nextPageToLoad} = useAppSelector(({accounting}) => {
        const shouldLoadMoreHistory =
            accounting.pastInvestingActivityLoadingState === 'ready' &&
            accounting.pastInvestingActivityFetchingMore &&
            accounting.pastInvestingActivityHasMore
        return {
            buyCount:
                accounting.pastInvestingActivityRecords.filter(x => x.type === 'buy').length +
                accounting.pendingInvestingActivityRecords.filter(x => x.type === 'buy').length,
            nextPageToLoad: shouldLoadMoreHistory ? accounting.pastInvestingActivityCurrentPage + 1 : undefined,
        }
    })
    const [currentAutoInvestOrders] = useAutoinvestOrders()

    // Load this data early on so that we're sure they newly placed order is NOT
    // included
    React.useEffect(() => {
        dispatch(accounting_actions.FetchPendingInvestingActivity())
        dispatch(accounting_actions.FetchPastInvestingActivity(1))
    }, [])

    // Keep loading history, we need to keep going back until we're sure we haven't
    // already passed the 2 historical BUY threshold
    React.useEffect(() => {
        if (nextPageToLoad && buyCount < 3) {
            dispatch(accounting_actions.FetchPastInvestingActivity(nextPageToLoad))
        }
    }, [nextPageToLoad])

    return gbTestVariant !== null && gbTestVariant > 0 && buyCount === 2 && currentAutoInvestOrders.length === 0
}

// Provides an immediate update of a variable.  We don't need variable updates to persist but we
// want immediate value update (in the same render) and getter and setting functions to be persistent
// so that they can be captured in other useEffect/etc functions.
export const useImmediateState: () => {set: (value: string) => void; get: () => string} = () => {
    let theVariable: string
    const setter = React.useCallback((value: string) => {
        theVariable = value
    }, [])
    const getter = React.useCallback(() => {
        return theVariable
    }, [])
    return {set: setter, get: getter}
}

export const AutoinvestNudgeModal: React.FC<{
    isOpen: boolean
    setIsOpen: (open: React.SetStateAction<boolean>) => void
    setExitNavigation: (target: string) => void
}> = ({isOpen, setIsOpen, setExitNavigation}) => {
    const profileUrl = useProfileUrl()
    const gbTestVariant = useFeature<number>('third-purchase-prompt').value
    const rudderTrackModalName = 'autoinvest prompt after three orders'
    const primaryButtonLabel = gbTestVariant === 1 ? 'Learn more' : 'Set up an Auto-invest'

    React.useEffect(() => {
        if (isOpen) {
            rudderTrack('info_modal', 'modal_viewed', {type: rudderTrackModalName})
        }
    }, [isOpen])

    return (
        <Modal
            dataTestId="modal--autoinvest-nudge"
            title="Third time’s a charm"
            isOpen={isOpen}
            setIsOpen={open => {
                rudderTrack('info_modal', 'dismissed', {type: rudderTrackModalName})
                setIsOpen(open)
            }}
            secondaryButton={{
                label: 'Not now',
                onClick: () => {
                    rudderTrack('info_modal', 'button_clicked', {type: rudderTrackModalName, label: 'Not now'})
                },
            }}
            primaryButton={{
                label: primaryButtonLabel,
                onClick: () => {
                    rudderTrack('info_modal', 'button_clicked', {type: rudderTrackModalName, label: primaryButtonLabel})
                    setExitNavigation(profileUrl('auto-invest'))
                },
            }}
        >
            <div className={styles.graphic}>
                <img
                    src={landing}
                    alt="Smiling person relaxing in a lounge chair, holding a piece of fruit"
                    role="presentation"
                />
            </div>
            <h1 className={cn(spacing.spaceBelow24, typographyOverrides['as-h1'])}>Perhaps you’d like Auto-invest?</h1>
            <p className={spacing.spaceBelow16}>
                With three orders in the last 28 days, it looks like you’re well on your way to regular investing. Nice
                one!
            </p>
            <p className={spacing.spaceBelow16}>Want to make it even easier?</p>
            <p>
                Create a DIY Auto-invest order or pick from one of our pre-made orders, and put your investing on
                repeat.
            </p>
        </Modal>
    )
}
