import {Button} from '@design-system/button'
import {Form, Formik} from 'formik'
import React from 'react'
import {FormErrors} from '~/api/retail'
import {Response} from '~/api/retail/types'
import {urlFor} from '~/global/routeGenerator'
import {spacing} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {convertToKebabCase} from '~/global/utils/convert-to-kebab-case/convertToKebabCase'
import {ErrorBox} from '~/global/widgets/form-controls'
import {Checkbox, Radio, Select, StrongCurrency, StrongNumber, Text} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import PageClose from '~/global/widgets/page-back-or-close/PageClose'
import TruncatedText from '~/global/widgets/truncated-text/TruncatedText'
import wrapperStyles from '~/global/wrappers/GlobalWrapper.scss'
import {useNavigate} from '~/migrate-react-router'
import styles from './SurveyForm.scss'

export type SurveyBlock = Response.Survey['blocks'][number]
export type SurveyValues = Record<string, string>

interface FormProps {
    blocks: SurveyBlock[]

    onSubmit(values: SurveyValues): Promise<FormErrors | string | undefined>
}

export const SurveyHeader: React.FC<{}> = () => {
    const navigate = useNavigate()
    return <PageClose onClick={() => navigate(urlFor(''))} />
}
type Choice = Extract<SurveyBlock, {choices: any[]}>['choices'][number]

function convertChoice(choice: Choice): Omit<Choice, 'help_text'> & {helpText?: string} {
    if ('help_text' in choice) {
        const {help_text, ...other} = choice
        return {
            ...other,
            helpText: help_text,
        }
    }
    return choice
}

export const Block: React.FC<{
    block: SurveyBlock
    isSubmitting: boolean
    decimalInputHelpText?: JSX.Element
    preventAutoFocus?: boolean
}> = React.memo(({block, isSubmitting, decimalInputHelpText, preventAutoFocus}) => {
    switch (block.type) {
        case 'markdown':
            return <div dangerouslySetInnerHTML={{__html: block.content}} />
        case 'radio_input':
            return (
                <Radio
                    dataTestId={`radio--${convertToKebabCase(block.label)}`}
                    name={block.name}
                    label={
                        <>
                            <h3 className={styles.inputLabel}>{block.label}</h3>
                            {block.help && (
                                <TruncatedText className={spacing.spaceAbove16}>
                                    <p className={styles.inputHelpText}>{block.help}</p>
                                </TruncatedText>
                            )}
                        </>
                    }
                    choices={[...block.choices].map(convertChoice)}
                />
            )
        case 'decimal_input':
            const decimalInputCommonProps: React.ComponentProps<typeof StrongCurrency | typeof StrongNumber> = {
                dataTestId: `strong-currency--${block.name}`,
                name: block.name,
                label: block.label,
                disabled: isSubmitting,
                autoFocus: !preventAutoFocus,
                placeholder: block.placeholder,
                decimalPlaces: block.max_dp,
                optional: !block.required,
                helpText: decimalInputHelpText ? (
                    <>
                        {decimalInputHelpText}
                        {block.help}
                    </>
                ) : (
                    block.help
                ),
            }

            if (block.currency) {
                return <StrongCurrency {...decimalInputCommonProps} currency={block.currency as any} />
            } else if (block.max_dp && block.max_dp > 0) {
                return <StrongNumber {...decimalInputCommonProps} normalisation="decimalOnly" />
            } else {
                return <StrongNumber {...decimalInputCommonProps} normalisation={undefined} />
            }
        case 'string_input':
            return (
                <Text
                    autoComplete="off"
                    dataTestId={`text--${block.name}`}
                    label={block.label}
                    helpText={block.help}
                    name={block.name}
                    placeholder={block.placeholder}
                    maxLength={block.max_length}
                />
            )
        case 'select_input':
            return (
                <Select
                    dataTestId={`select--${block.name}`}
                    name={block.name}
                    label={
                        <>
                            <h3 className={styles.inputLabel}>{block.label}</h3>
                            {block.help && (
                                <TruncatedText className={spacing.spaceAbove16}>
                                    <p className={styles.inputHelpText}>{block.help}</p>
                                </TruncatedText>
                            )}
                        </>
                    }
                    choices={[{label: '', value: ''}, ...block.choices]}
                />
            )
        case 'checkbox_input':
            return <Checkbox dataTestId={`checkbox--${block.name}`} name={block.name} label={block.label} />
        case 'form_submit_button':
            return (
                <Button label={block.content} isSubmit processing={isSubmitting} dataTestId="button--survey-submit" />
            )
        default:
            assertNever(block)
            return null
    }
})

export const SurveyForm: React.FunctionComponent<FormProps> = ({blocks, onSubmit}) => (
    <Formik<SurveyValues>
        initialValues={blocks.reduce((initialCheckboxValues, block) => {
            if (block.type === 'checkbox_input') {
                return {
                    ...initialCheckboxValues,
                    [`${block.name}`]: false,
                }
            }
            return initialCheckboxValues
        }, {})}
        onSubmit={async (values, {setErrors, setTouched, setStatus}) => {
            // make sure checkbox values get replaced with strings, to match with the backend schematics type
            const reformattedValues = Object.entries(values).reduce((reformattedValuesAccumulator, [key, value]) => {
                if (key.includes('checkbox-')) {
                    return {
                        ...reformattedValuesAccumulator,
                        [key]: value ? 'true' : 'false',
                    }
                }
                return {
                    ...reformattedValuesAccumulator,
                    [key]: value,
                }
            }, {})

            const errors = await onSubmit(reformattedValues)
            if (typeof errors === 'string') {
                setStatus(errors)
            } else if (errors) {
                setErrors(errors)
                setTouched(Object.keys(errors).reduce((touched, field) => ({...touched, [field]: true}), {}))
                setStatus(undefined)
            }
        }}
        validateOnBlur={false}
    >
        {({status, isSubmitting}) => (
            <Form className={wrapperStyles.fullScreen}>
                <Page>
                    <SurveyHeader />
                    <div className={styles.survey}>
                        {blocks.map((block, index) => (
                            <Block key={index} block={block} isSubmitting={isSubmitting} preventAutoFocus />
                        ))}
                    </div>
                    {status && <ErrorBox message={status} />}
                </Page>
            </Form>
        )}
    </Formik>
)
