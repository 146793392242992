export function calculateInvestorTypeDescription(allocations: {allocation: string; riskRating: number}[]) {
    let conservativeValue = 0
    let totalValue = 0

    for (const fund of allocations) {
        totalValue += parseInt(fund.allocation, 10)
        if (fund.riskRating < 4) {
            conservativeValue += parseInt(fund.allocation, 10)
        }
    }

    const conservativePercentage = (100 / totalValue) * conservativeValue

    if (totalValue === 0) {
        return
    }

    if (conservativePercentage >= 90) {
        return 'Defensive' as const
    }
    if (conservativePercentage >= 70) {
        return 'Conservative' as const
    }
    if (conservativePercentage > 30) {
        return 'Balanced' as const
    }
    if (conservativePercentage > 10) {
        return 'Growth' as const
    }
    return 'Aggressive' as const
}

export type InvestorType = ReturnType<typeof calculateInvestorTypeDescription>
