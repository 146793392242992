/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    InstrumentHolding,
    InstrumentHoldingFromJSON,
    InstrumentHoldingFromJSONTyped,
    InstrumentHoldingToJSON,
    InstrumentHoldingToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface VerifyInstrumentHoldings
 */
export interface VerifyInstrumentHoldings {
    /**
     *
     * @type {string}
     * @memberof VerifyInstrumentHoldings
     */
    portfolio_uuid: string
    /**
     *
     * @type {Array<InstrumentHolding>}
     * @memberof VerifyInstrumentHoldings
     */
    instrument_holdings: Array<InstrumentHolding>
}

export function VerifyInstrumentHoldingsFromJSON(json: any): VerifyInstrumentHoldings {
    return VerifyInstrumentHoldingsFromJSONTyped(json, false)
}

export function VerifyInstrumentHoldingsFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): VerifyInstrumentHoldings {
    if (json === undefined || json === null) {
        return json
    }
    return {
        portfolio_uuid: json['portfolio_uuid'],
        instrument_holdings: (json['instrument_holdings'] as Array<any>).map(InstrumentHoldingFromJSON),
    }
}

export function VerifyInstrumentHoldingsToJSON(value?: VerifyInstrumentHoldings | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        portfolio_uuid: value.portfolio_uuid,
        instrument_holdings: (value.instrument_holdings as Array<any>).map(InstrumentHoldingToJSON),
    }
}
