import {Modal} from '@design-system/modal'
import React, {Dispatch, SetStateAction} from 'react'

export const UnlinkBankConfirmModal = ({
    isOpen,
    setIsOpen,
    handleUnlinkBank,
}: {
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
    handleUnlinkBank: () => void
}) => {
    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title="Unlink with your bank?"
            dataTestId="modal--disconnect-bank"
            primaryButton={{label: 'Unlink bank', onClick: handleUnlinkBank}}
            secondaryButton={{label: 'Cancel', onClick: () => setIsOpen(false)}}
        >
            <p>
                Unlinking with your bank will turn off any automated features you’ve set up (such as round-ups or linked
                bank top-ups) between Sharesies and your linked accounts.
            </p>
            <p>
                This will cancel all of your Direct Debit Request instructions and we will notify our service providers
                of these changes. It may take up to 5 business days for your request to take affect.
            </p>
            <p>To access these features again, you’ll need to relink with your bank.</p>
        </Modal>
    )
}
