import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal'
import {withFormik} from 'formik'
import React, {useState, useEffect} from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {FormErrors} from '~/api/retail'
import {Model, Request, Response} from '~/api/retail/types'
import {CUSTOMER_SIGNUP_VERSION} from '~/global/constants/global'
import {spacing} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {isWrapperApp} from '~/global/utils/is-wrapper-app/isWrapperApp'
import {isPreferredNameValid} from '~/global/utils/preferred-name-validation/preferredNameValidation'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {validate} from '~/global/widgets/form-controls'
import styles from '~/global/widgets/form-controls/form.scss'
import {Text, Email, Password, Checkbox, Phone, PromoCode} from '~/global/widgets/form-controls/formik'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useLocation, useNavigate} from '~/migrate-react-router'
import PrivacyPolicyModalLink from '~/sections/user/sections/sign-up/pages/about-you/widgets/privacy-policy-modal-link/PrivacyPolicyModalLink'
import InvestTermsAndConditions from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/InvestTermsAndConditions'
import TermsAndConditions, {
    NZ_TC_VERSION,
} from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/TermsAndConditions'
import InvalidPreferredNameModal from '~/sections/user/widgets/invalid-preferred-name-modal/InvalidPreferredNameModal'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'
import CountrySelect from '../country-select/CountrySelect'

type FormProps = FormOwnProps & FormDispatchProps

interface FormDispatchProps {
    signUp: (payload: Request.IdentitySignUpV2) => Promise<string | null | FormErrors>
}

interface FormOwnProps extends OwnProps {
    selectedCountry: Model.User['jurisdiction']
}

interface FormValues extends Request.IdentitySignUpV2 {
    confirm_password: string
    terms: boolean
    invalidPreferredNames: string[]
}

const Form = withFormik<FormProps, FormValues>({
    mapPropsToValues: ({referral_code, promo_code, selectedCountry}) => ({
        preferred_name: '',
        password: '',
        email: '',
        phone: '',
        confirm_password: '',
        referral_code,
        promo_code,
        terms: selectedCountry === 'au' ? true : false, // AU has a seperate page for terms and conditions
        jurisdiction: selectedCountry,
        invalidPreferredNames: [],
    }),
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        email: undefined,
    }),
    handleSubmit: async (
        {preferred_name, password, email, phone, referral_code, promo_code, jurisdiction, invalidPreferredNames},
        {setSubmitting, setStatus, setFieldError, setErrors, props: {signUp, kidsAccountTransferToken}},
    ) => {
        if (!isPreferredNameValid(preferred_name)) {
            // The name that the investor used is not valid. Set that name as the error message for the
            // `useEffect` block to pickup and store for sending to the server later when the signup
            // succeeds
            setFieldError('invalidPreferredNames', preferred_name)
            return
        }

        try {
            const errors = await signUp({
                preferred_name,
                password,
                email,
                phone,
                referral_code,
                promo_code,
                dependent_id_token: kidsAccountTransferToken,
                tc_version: jurisdiction === 'nz' ? NZ_TC_VERSION : undefined, // AU requries a seperate page for terms and conditions
                jurisdiction,
                invalid_preferred_names: invalidPreferredNames,
                is_wrapper_app: isWrapperApp(),
                customer_signup_version: CUSTOMER_SIGNUP_VERSION,
            })
            if (errors) {
                // String will mean token error
                if (typeof errors === 'string') {
                    setStatus(errors)
                } else {
                    setErrors(errors)
                }
                setSubmitting(false)
                return
            }
        } catch (e) {
            setStatus(unknownErrorMessage)
            setSubmitting(false)
            throw e
        }
    },
    validate: validate.generate<FormValues>({
        email: [validate.required(), validate.email()],
        password: [validate.required(), validate.password()],
        confirm_password: [validate.required(), validate.sameAs('password', "Passwords don't match")],
        phone: [validate.required()],
        preferred_name: [validate.required()],
        terms: [validate.isTrue('You must accept the terms and privacy policy')],
        jurisdiction: [], // validation not required as value is passed from parent component
        invalidPreferredNames: [],
    }),
})(({selectedCountry, referral_code, isSubmitting, handleSubmit, isValid, status, errors, values, setFieldValue}) => {
    const [failedNameValidationModalOpen, setFailedNameValidationModalOpen] = useState<boolean>(false)
    const [currentNameValidationFailure, setCurrentNameValidationFailure] = useState<string | null>(null)

    useEffect(() => {
        if (!errors.invalidPreferredNames) {
            // No names have failed validation. Return
            return
        }

        // Was an invalid preferred name added into the errors object?
        const newFailedName = errors.invalidPreferredNames as string
        const hasNewFailedName = values.invalidPreferredNames.indexOf(newFailedName) === -1

        if (hasNewFailedName) {
            //Open the modal to alert the new investor that they can't be a trust/company/etc
            setFailedNameValidationModalOpen(true)
            // Save the name that triggered this warning
            setCurrentNameValidationFailure(values.preferred_name)
            // And add the newly failed name to the array that gets
            setFieldValue('invalidPreferredNames', values.invalidPreferredNames.concat(newFailedName))

            Analytics.event({
                category: 'Sign Up',
                action: 'Invalid preferred name',
                label: 'Trust or company name supplied',
            })
        }
    }, [errors.invalidPreferredNames])

    useEffect(() => {
        // This effect is triggered whenever the failed name validation modal opens or the text in
        // the preferred name field changes. The `currentNameValidationFailure` value is used, in
        // conjunction with the validity of the form, to enable/disable the submit button

        if (currentNameValidationFailure !== values.preferred_name) {
            // The preferred name has been altered from the one that caused the modal to open. Set the
            // currentNameValidationFailure to null so that the submit button becomes active again (provided
            // the form passes the rest of the validation)
            setCurrentNameValidationFailure(null)
        }
    }, [currentNameValidationFailure, values.preferred_name])

    const closeModal = () => setFailedNameValidationModalOpen(false)

    const showTermsAndConditions = selectedCountry === 'nz'

    return (
        <>
            <InvalidPreferredNameModal
                isOpen={failedNameValidationModalOpen}
                setIsOpen={closeModal}
                jurisdiction={selectedCountry}
            />
            <form onSubmit={handleSubmit}>
                <Email dataTestId="user-signup-email-input" name="email" label="Email" />
                <Password
                    dataTestId="password"
                    name="password"
                    label="Password"
                    helpText="Choose a nice strong one."
                    strengthMeter
                />
                <Password dataTestId="password--confirm" name="confirm_password" label="Confirm password" />
                <Phone dataTestId="text-input--phone" name="phone" label="Phone number" />
                <Text dataTestId="text-input--preferred-name" name="preferred_name" label="Preferred name" />
                <PromoCode
                    name="promo_code"
                    isReferred={!!referral_code}
                    label="Promo code"
                    type="regular"
                    jurisdiction={selectedCountry}
                    selectedCountry={selectedCountry}
                />
                {showTermsAndConditions && (
                    <Checkbox
                        dataTestId="checkbox--terms-and-conditions"
                        name="terms"
                        label={
                            <span>
                                I've read and agree to the Sharesies{' '}
                                <ModalLink
                                    dataTestId="modal-link--general-terms-and-and-conditions"
                                    label="General Terms and Conditions"
                                    modalTitle="General Terms and Conditions"
                                    bottomBorder
                                >
                                    <TermsAndConditions />
                                </ModalLink>
                                ,{' '}
                                <ModalLink
                                    dataTestId="modal-link--invest-terms-and-conditions"
                                    label="Invest Terms and Conditions"
                                    modalTitle="Invest Terms and Conditions"
                                    bottomBorder
                                >
                                    <InvestTermsAndConditions />
                                </ModalLink>
                                , <PrivacyPolicyModalLink />, and{' '}
                                <HelpCentreLink
                                    linkText="market conduct rules"
                                    nzArticle="8482855-market-conduct-rules"
                                    auArticle="8482859-market-conduct-rules"
                                />
                                .
                            </span>
                        }
                    />
                )}
                <Button
                    isSubmit
                    pageButton
                    dataTestId="button--next"
                    label="Next"
                    disabled={!isValid || !!currentNameValidationFailure}
                    processing={isSubmitting}
                    additionalClassName={spacing.spaceBelow48}
                />
                {status && (
                    <div className={styles.errorBox}>
                        <TokenErrorMessage />
                    </div>
                )}
            </form>
        </>
    )
})

const TokenErrorMessage = () => {
    return (
        <div>
            This token has expired or is invalid. <a href="mailto:help@sharesies.co.nz">Email Sharesies</a> to get a new
            token.
        </div>
    )
}

const TokenError = () => {
    return (
        <>
            <Toolbar dataTestId="toolbar--token error" leftButton="back" title="Token Error" isInlineTitle />
            <Page>
                <TokenErrorMessage />
            </Page>
        </>
    )
}

const AboutYou: React.FunctionComponent<AboutYouProps> = ({
    kidsAccountTransferToken,
    fetchKidsAccountTransferTokenData,
    tokenData,
    tokenError,
    referral_code,
    promo_code,
    signUp,
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [countrySubmitted, setCountrySubmitted] = React.useState(false)
    const [selectedCountry, setSelectedCountry] = React.useState<Model.User['jurisdiction'] | null>(null)

    React.useEffect(() => {
        if (kidsAccountTransferToken) {
            fetchKidsAccountTransferTokenData(kidsAccountTransferToken)
        }
    }, [])

    // component depends on token data which is not immediately available when the component mounts
    if (tokenData && !countrySubmitted) {
        setSelectedCountry(tokenData.jurisdiction)
        setCountrySubmitted(true)
    }

    React.useEffect(() => {
        if (selectedCountry) {
            registerJurisdictionSelected(selectedCountry === 'au' ? 'Australia' : 'New Zealand', countrySubmitted)
        }
    }, [selectedCountry, countrySubmitted])

    // send GA event each time a country is selected, exclude kids accounts transfers
    // note that there will be a duplicate record of the final jurisdiction selected event,
    // one with the final label and one without
    const registerJurisdictionSelected = (label: string, isSubmit: boolean) => {
        if (!kidsAccountTransferToken) {
            Analytics.event({
                category: 'Sign Up',
                action: `Jurisdiction selected${isSubmit ? ' final' : ''}`,
                label,
            })
        }
    }

    if (tokenError) {
        return <TokenError />
    }

    if (!countrySubmitted) {
        return (
            <>
                <Toolbar
                    dataTestId="toolbar--about-you"
                    leftButton="back"
                    onLeftButtonClick={() => {
                        sendWrapperAppMessage({type: 'goBack'})

                        if (kidsAccountTransferToken) {
                            navigate(-1)
                        } else {
                            // Only the public landing page exists before this one
                            navigate('/')
                        }

                        Analytics.event({
                            category: 'Sign Up',
                            action: 'Previous button',
                            label: 'Jurisdiction selection',
                        })
                    }}
                />
                {kidsAccountTransferToken ? ( // when waiting for the token data, display a loading page
                    <Loading />
                ) : (
                    <CountrySelect
                        setCountrySubmitted={setCountrySubmitted}
                        selectedCountry={selectedCountry}
                        setSelectedCountry={setSelectedCountry}
                    />
                )}
            </>
        )
    } else {
        // virtual additional page nav for the sake of analytics
        Analytics.pageNav(`${location.pathname}/about-you-details`)
        return (
            <>
                <Toolbar
                    dataTestId="toolbar--about-you"
                    leftButton="back"
                    onLeftButtonClick={() => {
                        kidsAccountTransferToken ? navigate(-1) : setCountrySubmitted(false)
                    }}
                />
                <Page>
                    <h1 className={spacing.spaceBelow16}>Tell us more about you</h1>
                    {kidsAccountTransferToken && tokenData && (
                        <div>
                            Haere mai {tokenData.kids_name}! Let’s get this account switched over from{' '}
                            {tokenData.owner_name} to you.
                        </div>
                    )}
                    <Form
                        referral_code={referral_code}
                        promo_code={promo_code}
                        signUp={signUp}
                        kidsAccountTransferToken={kidsAccountTransferToken}
                        selectedCountry={selectedCountry as Model.User['jurisdiction']}
                    />
                </Page>
            </>
        )
    }
}

interface StoreProps {
    tokenData?: Response.IdentityTokenData
    tokenError?: boolean
}

interface OwnProps {
    referral_code?: string
    promo_code?: string
    kidsAccountTransferToken?: string
}

interface DispatchProps {
    signUp: (payload: Request.IdentitySignUpV2) => Promise<string | null | FormErrors>
    fetchKidsAccountTransferTokenData: (token: string) => void
}

type AboutYouProps = StoreProps & OwnProps & DispatchProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    ({identity}) => ({
        tokenData: identity.kidsAccountTransferTokenData,
        tokenError: identity.kidsAccountTransferTokenError,
    }),
    dispatch => ({
        signUp: (payload: Request.IdentitySignUpV2) => dispatch(actions.SignUp(payload)),
        fetchKidsAccountTransferTokenData: (token: string) =>
            dispatch(actions.FetchKidsAccountTransferTokenData(token)),
    }),
)(AboutYou)
