import React from 'react'
import {Link} from 'react-router-dom'
import {Model} from '~/api/retail/types'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {isUninitialised} from '~/global/utils/is-loading/isLoading'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue, SharePriceValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import allocationStyles from '~/sections/invest/sections/view-instrument/sections/employee-share-scheme/pages/share-scheme-allocation/ShareSchemeAllocation.scss'
import essActions from '~/store/employeeShareScheme/actions'
import {ShareRightAllocation} from '~/store/employeeShareScheme/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import {RecordRow} from './common'
import styles from './Record.scss'

interface InstrumentActivityRecordShareSchemeRightAllocationProps {
    instrument: Instrument
    allocationLine: Model.ESSRightAllocationLine
}

const InstrumentActivityRecordShareSchemeRightAllocation: React.FunctionComponent<InstrumentActivityRecordShareSchemeRightAllocationProps> =
    React.memo(({instrument, allocationLine}) => {
        const dispatch = useAppDispatch()

        let allocation: ShareRightAllocation | undefined

        // fetch the allocations
        const shareSchemeAllocationsByInstrument = useAppSelector(
            s => s.employeeShareScheme.shareSchemeAllocationsByInstrument,
        )
        const allocationsLoadingState = useAppSelector(s => s.employeeShareScheme.allocationsLoadingState)
        const allocationsForInstrument = shareSchemeAllocationsByInstrument[instrument.id]
        const allocationVested = !!allocationLine.vest_transaction_id

        React.useEffect(() => {
            if (isUninitialised(allocationsLoadingState)) {
                dispatch(essActions.FetchParticipatingShareSchemes())
            }
        }, [instrument.id])

        if (!allocationsForInstrument || !instrument) {
            return <Loading isPineapple />
        }

        for (const currentAllocation of allocationsForInstrument.share_rights_allocations) {
            const line = currentAllocation.lines.find(line => line.id === allocationLine.id)
            if (line) {
                allocation = currentAllocation
                break
            }
        }

        if (!allocation) {
            return <WeSlippedUp />
        }

        return (
            <>
                <div className={styles.orderContent}>
                    <div className={styles.orderTitleBlock}>
                        <div className={styles.orderThumb}>
                            <InstrumentLogo instrument={instrument} noBorder />
                        </div>
                        <div>
                            <h4 className={styles.instrumentName}>{instrument.name}</h4>
                            {tradingType(instrument) !== 'managed' && !isInstrumentInNoTrade(instrument) && (
                                <p className={styles.instrumentSymbol}>
                                    <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                                    <PronounceLetters text={instrument.exchange} />
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={styles.recordRowContainer} data-testid="contract-note--record-row-container">
                        {allocationVested && (
                            <RecordRow left="Contract note" right={allocationLine.vest_transaction_id} />
                        )}
                        <RecordRow
                            left="Date awarded"
                            right={allocationLine.allocation_date?.toFormat(dateFormatNoTime)}
                        />
                        {allocationVested ? (
                            <RecordRow
                                left="Date received"
                                right={allocationLine.vesting_date?.toFormat(dateFormatNoTime)}
                            />
                        ) : (
                            <RecordRow
                                left="Tested on"
                                right={allocationLine.testing_date.toFormat(dateFormatNoTime)}
                            />
                        )}
                        {allocationVested && allocationLine.number_of_shares_vested && (
                            <RecordRow
                                left="Shares received"
                                right={<ShareValue value={allocationLine.number_of_shares_vested} />}
                            />
                        )}
                        {allocationVested && allocationLine.vesting_price_per_share && (
                            <RecordRow
                                left="Vesting share price"
                                right={
                                    <SharePriceValue
                                        value={allocationLine.vesting_price_per_share}
                                        currency={instrument.currency}
                                    />
                                }
                            />
                        )}
                    </div>
                    <p className={styles.orderAmountLabel}>{allocationVested ? 'Amount' : 'Total share rights'}</p>
                    <div className={styles.orderFooter}>
                        <div data-testid="status-label--allocation-state">
                            <p className={styles.statusLabel}>{allocationVested ? 'Vested' : 'Unvested '}</p>
                        </div>
                        <div>
                            <p className={styles.orderAmount}>
                                {allocationVested ? (
                                    <DollarValue value={allocationLine.total_value} currency={instrument.currency} />
                                ) : (
                                    <ShareValue value={allocationLine.number_of_share_rights} />
                                )}
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <AllocationDescription
                        allocation={allocation}
                        allocationLine={allocationLine}
                        instrumentId={instrument.id}
                    />
                </div>
            </>
        )
    })

const AllocationDescription: React.FunctionComponent<{
    allocation: ShareRightAllocation
    allocationLine: Model.ESSRightAllocationLine
    instrumentId: string
}> = ({allocation, allocationLine, instrumentId}) => {
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    // fetch scheme details
    const schemeDetail = useAppSelector(s =>
        s.employeeShareScheme.shareSchemeDetailById
            ? s.employeeShareScheme.shareSchemeDetailById[allocation.share_scheme_id]
            : undefined,
    )

    React.useEffect(() => {
        if (!schemeDetail) {
            dispatch(essActions.FetchShareSchemeDetail(allocation.share_scheme_id))
        }
    }, [schemeDetail])

    if (!schemeDetail) {
        return <Loading isPineapple />
    }

    const getAllocationTestingText = () => {
        switch (allocation.allocation_type) {
            case 'DEFERRED':
                return schemeDetail.share_rights_deferred_explanation_text
            case 'PERFORMANCE':
                return schemeDetail.share_rights_performance_explanation_text
            default:
                return ''
        }
    }
    return (
        <div>
            {allocationLine.vesting_date && allocationLine.number_of_shares_vested ? (
                <div className={allocationStyles.container}>
                    <p>
                        Of <ShareValue value={allocationLine.number_of_share_rights} /> share rights allocated, you
                        received{' '}
                        <span className={allocationStyles.sharesReceived}>
                            <ShareValue value={allocationLine.number_of_shares_vested} />
                        </span>{' '}
                        in shares.
                    </p>
                </div>
            ) : (
                <div className={allocationStyles.container}>
                    <p>
                        {getAllocationTestingText()} View{' '}
                        <Link
                            to={profileUrl('employee-share-scheme/:instrumentId/:shareSchemeId', {
                                instrumentId,
                                shareSchemeId: allocation.share_scheme_id,
                            })}
                        >
                            the scheme details
                        </Link>{' '}
                        for more information on the testing criteria for your share right allocations.
                    </p>
                </div>
            )}
        </div>
    )
}

export default InstrumentActivityRecordShareSchemeRightAllocation
