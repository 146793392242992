import React from 'react'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {convertDateTime} from '~/global/utils/convert-date-time/convertDateTime'
import {dateFormatNoTime, dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import {isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {ADR} from '~/global/widgets/help-modals/ADR'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {DollarValue, DividendValue} from '~/global/widgets/number-elements/NumberElements'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {DividendOrder} from '~/store/accounting/types'
import {useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import ReinvestDividendSnippet from './ReinvestDividendSnippet'
import {RecordRow} from './common'
import styles from './Record.scss'

interface ShowLines {
    imputations: boolean
    gross: boolean
    declared: boolean
    rwt: boolean
    uswt: boolean
    auwt: boolean
    nzwt: boolean
    fwt: boolean
    perShareAfterTax: boolean
    perShareAfterNzTax: boolean
    adrFee: boolean
}
interface InstrumentActivityRecordDividendProps {
    instrument: Instrument
    order: DividendOrder
}

const InstrumentActivityRecordDividend: React.FC<InstrumentActivityRecordDividendProps> = React.memo(
    ({order, instrument}) => {
        const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)

        const showLines: ShowLines = (() => {
            if (instrument.isAdr && Boolean(order.settled)) {
                return {
                    imputations: false,
                    gross: true,
                    declared: false,
                    rwt: jurisdiction === 'nz',
                    uswt: false,
                    auwt: jurisdiction === 'au',
                    nzwt: false,
                    fwt: true,
                    perShareAfterTax: true,
                    perShareAfterNzTax: false,
                    adrFee: true,
                }
            }
            if (order.jurisdiction === 'nz') {
                // AU Customers don't have imputation credits applied.
                // Hide the Imputation & Gross Dividend columns.
                const auCustomerNZXDividend = jurisdiction === 'au' && instrument.exchange === 'NZX'
                return {
                    imputations: !auCustomerNZXDividend,
                    gross: !auCustomerNZXDividend,
                    declared: true,
                    rwt: !auCustomerNZXDividend,
                    uswt: false,
                    auwt: false,
                    nzwt: auCustomerNZXDividend,
                    fwt: false,
                    perShareAfterTax: !auCustomerNZXDividend,
                    perShareAfterNzTax: auCustomerNZXDividend,
                    adrFee: false,
                }
            }
            if (order.jurisdiction === 'us') {
                return {
                    imputations: false,
                    gross: true,
                    declared: false,
                    rwt: Boolean(order.settled),
                    uswt: Boolean(order.settled),
                    auwt: false,
                    nzwt: false,
                    fwt: false,
                    perShareAfterTax: Boolean(order.settled),
                    perShareAfterNzTax: false,
                    adrFee: false,
                }
            }
            if (order.jurisdiction === 'au') {
                return {
                    imputations: false,
                    gross: true,
                    declared: false,
                    rwt: jurisdiction === 'nz' && Boolean(order.settled),
                    uswt: false,
                    auwt: jurisdiction === 'nz' && Number(order.au_tax_value_total) > 0,
                    nzwt: true,
                    fwt: false,
                    perShareAfterTax: Boolean(order.settled),
                    perShareAfterNzTax: false,
                    adrFee: false,
                }
            }
            assertNever(order.jurisdiction)
            return {
                imputations: true,
                gross: true,
                declared: true,
                rwt: true,
                uswt: true,
                auwt: true,
                nzwt: false,
                fwt: false,
                perShareAfterTax: true,
                perShareAfterNzTax: false,
                adrFee: false,
            }
        })()

        return (
            <div className={styles.orderContent}>
                <div className={styles.orderTitleBlock}>
                    <div className={styles.orderThumb}>
                        <InstrumentLogo instrument={instrument} noBorder />
                    </div>
                    <div>
                        <h4 className={styles.instrumentName}>{instrument.name}</h4>
                        {tradingType(instrument) !== 'managed' && (
                            <p className={styles.instrumentSymbol}>
                                <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                                <PronounceLetters text={instrument.exchange} />
                            </p>
                        )}
                        <p className={styles.orderTime}>{order.created.toFormat(dateFormatWithYear)}</p>
                    </div>
                </div>

                <div className={styles.recordRowContainer}>
                    <RecordRow
                        left={`Number of ${shareLabel({
                            instrument,
                            isPlural: true,
                        })} on ex dividend date—${convertDateTime(order.ex_date)}`}
                        right={order.shares_held}
                    />
                    {showLines.gross && (
                        <RecordRow
                            left={<p>Gross dividend per {shareLabel({instrument})}</p>}
                            right={
                                <DividendValue value={order.gross_value_per_share} currency={order.payment_currency} />
                            }
                        />
                    )}
                    {showLines.gross &&
                        (order.franked_amount || order.unfranked_amount ? (
                            <>
                                <RecordRow
                                    left="Franked amount"
                                    right={
                                        <DividendValue
                                            value={order.franked_amount || '0'}
                                            currency={order.payment_currency}
                                        />
                                    }
                                />
                                <RecordRow
                                    left="Unfranked amount"
                                    right={
                                        <DividendValue
                                            value={order.unfranked_amount || '0'}
                                            currency={order.payment_currency}
                                        />
                                    }
                                />
                            </>
                        ) : (
                            <RecordRow
                                left="Gross dividend"
                                right={
                                    <DividendValue value={order.gross_value_total} currency={order.payment_currency} />
                                }
                            />
                        ))}
                    {showLines.imputations && (
                        <RecordRow
                            left="Imputation credit"
                            right={
                                <DividendValue
                                    value={`-${order.imputation_value_total}`}
                                    currency={order.payment_currency}
                                />
                            }
                        />
                    )}
                    {showLines.declared && (
                        <RecordRow
                            left="Declared dividend"
                            right={
                                <DividendValue value={order.declared_value_total} currency={order.payment_currency} />
                            }
                        />
                    )}
                    {showLines.rwt && (
                        <RecordRow
                            left="Resident Withholding Tax (RWT)"
                            right={<DividendValue value={`-${order.rwt_value_total}`} currency="nzd" />}
                        />
                    )}
                    {showLines.auwt && (
                        <RecordRow
                            left="AU Withholding Tax"
                            right={<DividendValue value={`-${order.au_tax_value_total}`} currency="aud" />}
                        />
                    )}
                    {showLines.uswt && (
                        <RecordRow
                            left="US Withholding Tax"
                            right={<DividendValue value={`-${order.uswt_value_total}`} currency="usd" />}
                        />
                    )}
                    {showLines.nzwt && (
                        <RecordRow
                            left="NZ Withholding Tax"
                            right={<DividendValue value={`-${order.nz_tax_value_total}`} currency="nzd" />}
                        />
                    )}
                    {showLines.fwt && (
                        <RecordRow
                            left="Foreign Withholding Tax"
                            right={
                                <DividendValue
                                    value={`-${order.fwt_value_total == null ? 0 : order.fwt_value_total}`}
                                    currency="usd"
                                />
                            }
                        />
                    )}
                    {showLines.adrFee && (
                        <RecordRow
                            left={
                                <div>
                                    Depository fee <ADR jurisdiction={jurisdiction} />
                                </div>
                            }
                            right={
                                <DividendValue
                                    value={`-${order.adr_fee == null ? 0 : order.adr_fee}`}
                                    currency={order.payment_currency}
                                />
                            }
                        />
                    )}
                    {showLines.perShareAfterTax && (
                        <RecordRow
                            left={<p>Dividend per {shareLabel({instrument})} after tax</p>}
                            right={
                                <DividendValue value={order.net_value_per_share} currency={order.payment_currency} />
                            }
                        />
                    )}
                    {showLines.perShareAfterNzTax && (
                        <RecordRow
                            left={<p>Dividend per {shareLabel({instrument})} after NZ tax</p>}
                            right={
                                <DividendValue value={order.net_value_per_share} currency={order.payment_currency} />
                            }
                        />
                    )}
                </div>

                {/* TODO may remove date from Aussie pending dividends in future. For now just US pending Dividends show no date. */}
                <p className={styles.orderAmountLabel}>
                    {order.settled
                        ? `Amount paid on ${order.settled.toFormat(dateFormatNoTime)}`
                        : !isOnMainUsExchange(instrument) && order.pay_date
                          ? `Amount to be paid on ${convertDateTime(order.pay_date)}`
                          : 'Amount'}
                </p>

                <div className={styles.orderFooter}>
                    <div>
                        <p className={styles.statusLabel} data-testid="orderStatusLabel">
                            {order.settled ? 'Paid to Wallet' : 'Pending'}
                        </p>
                    </div>
                    <div>
                        <p className={styles.orderAmount}>
                            <DollarValue
                                value={order.net_value_total}
                                currency={order.payment_currency}
                                sayLessThanOneCent
                            />
                        </p>
                    </div>
                </div>
                {order.settled ? (
                    <ReinvestDividendSnippet
                        instrument={instrument}
                        dividendAmount={order.net_value_total}
                        orderId={order.id}
                    />
                ) : null}
            </div>
        )
    },
)

export default InstrumentActivityRecordDividend
