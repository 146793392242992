import {assertNever} from '~/global/utils/assert-never/assertNever'
import {ActionsType} from './actions'
import {State, Instrument} from './types'

export const initialState: State = {
    activeInstrumentTab: {tab: 'Overview', instrumentId: undefined},
    autofocusSearch: false,
    childInstrumentIds: [],
    countResultsLoadingState: 'ready',
    currentSearchInput: '',
    currentSearchList: {
        listName: 'All',
        instrumentIds: [],
    },
    currentSearchQuery: '',
    currentSearchSort: 'marketCap',
    currentSearchTimePeriod: '1y',
    currentSearchFilters: {
        categories: [],
        exchanges: [],
        unlistedInstruments: undefined,
        riskLevel: null,
        instrumentTypes: [],
        kidsRecommended: undefined,
    },
    diyAIIndex: [],
    diyAILoadingState: 'ready',
    diyAIUnavailableInstrumentNames: [],
    instrumentsById: {},
    isMetadataInitialised: false,
    underlyingInstrumentById: {},
    metadata: {
        filters: [],
        categories: [],
        instrumentTypes: [],
        exchanges: [],
        assetManagers: [],
        sorts: [],
        timePeriods: [],
        resourcePath: '',
    },
    nextPageLoadingState: 'ready',
    portfolioBreakdownIsOpen: false,
    portfolioIndex: [],
    portfolioInstrumentExchangeCountry: [],
    portfolioInstrumentTypes: [],
    portfolioInstrumentRiskLevel: null,
    portfolioLoadingState: 'ready',
    portfolioNextPageLoadingState: 'ready',
    portfolioRiskLevelLabel: undefined,
    premadeAILoadingState: 'ready',
    premadeGlobalAIIndex: [],
    premadeKidsAIIndex: [],
    premadeResponsibleAIIndex: [],
    priceHistoryLoadingState: 'ready',
    recentlyViewedIndex: [],
    recentlyViewedLoadingState: 'ready',
    resultsIndex: [],
    resultsLoadingState: 'ready',
    searchInitialisedFor: undefined,
    slugToIdMap: {},
    transferableIndex: [],
    transferableLoadingState: 'ready',
    watchlistIndex: [],
    watchlistLoadingState: 'ready',
    autoinvestDividendsLoadingState: 'uninitialised',
    autoinvestDividends: {
        type: 'autoinvest_dividend_preferences',
        primary: false,
        funds: [],
    },
}

function reducer(state: State = initialState, action: ActionsType): State {
    switch (action.type) {
        case 'instrument.ApplyOnlyKidsRecommendedToFilter':
            return {
                ...state,
                currentSearchFilters: {
                    categories: [],
                    exchanges: [],
                    unlistedInstruments: undefined,
                    riskLevel: null,
                    instrumentTypes: [],
                    kidsRecommended: true,
                },
                currentSearchQuery: '',
                currentSearchSort: initialState.currentSearchSort,
                currentSearchTimePeriod: initialState.currentSearchTimePeriod,
            }
        case 'instrument.ApplyOnlySingleCategoryToFilter':
            return {
                ...state,
                currentSearchFilters: {
                    categories: [action.payload],
                    exchanges: [],
                    unlistedInstruments: undefined,
                    riskLevel: null,
                    instrumentTypes: [],
                    kidsRecommended: undefined,
                },
                currentSearchQuery: '',
                currentSearchSort: initialState.currentSearchSort,
                currentSearchTimePeriod: initialState.currentSearchTimePeriod,
            }

        case 'instrument.ApplyOnlySingleInstrumentTypeToFilter':
            return {
                ...state,
                currentSearchFilters: {
                    categories: [],
                    exchanges: [],
                    unlistedInstruments: undefined,
                    riskLevel: null,
                    instrumentTypes: [action.payload],
                    kidsRecommended: undefined,
                },
                currentSearchQuery: '',
                currentSearchSort: initialState.currentSearchSort,
                currentSearchTimePeriod: initialState.currentSearchTimePeriod,
            }
        case 'instrument.ApplyOnlySingleExchangeToFilter':
            return {
                ...state,
                currentSearchFilters: {
                    categories: [],
                    exchanges: [action.payload],
                    unlistedInstruments: undefined,
                    riskLevel: null,
                    instrumentTypes: [],
                    kidsRecommended: undefined,
                },
                currentSearchQuery: '',
                currentSearchSort: initialState.currentSearchSort,
                currentSearchTimePeriod: initialState.currentSearchTimePeriod,
            }

        case 'instrument.AppendResults':
            return {
                ...state,
                resultsIndex: [...state.resultsIndex, ...action.payload.instrumentIds],
                currentResultsPage: action.payload.page,
            }
        case 'instrument.AppendPortfolioResults':
            return {
                ...state,
                portfolioIndex: [...state.portfolioIndex, ...action.payload.instrumentIds],
                currentPortfolioPage: action.payload.page,
            }
        case 'instrument.ClearAllPortfolioInstrumentSubfilters':
            return {
                ...state,
                portfolioInstrumentExchangeCountry: [],
                portfolioInstrumentRiskLevel: null,
                portfolioInstrumentTypes: [],
            }
        case 'instrument.ClearAllSearchFilters':
            return {
                ...state,
                currentSearchFilters: initialState.currentSearchFilters,
            }

        case 'instrument.ClearAllSearchFiltersSorts':
            return {
                ...state,
                currentSearchFilters: initialState.currentSearchFilters,
                currentSearchSort: initialState.currentSearchSort,
                currentSearchTimePeriod: initialState.currentSearchTimePeriod,
            }
        case 'instrument.ClearIndexes':
            return {
                ...state,
                portfolioIndex: initialState.portfolioIndex,
                recentlyViewedIndex: initialState.recentlyViewedIndex,
                transferableIndex: initialState.transferableIndex,
                resultsIndex: initialState.resultsIndex,
                premadeGlobalAIIndex: initialState.premadeGlobalAIIndex,
                premadeResponsibleAIIndex: initialState.premadeResponsibleAIIndex,
                premadeKidsAIIndex: initialState.premadeKidsAIIndex,
                diyAIIndex: initialState.diyAIIndex,
                autofocusSearch: initialState.autofocusSearch,
                currentResultsPage: undefined,
                numberOfResultsPages: undefined,
                currentPortfolioPage: undefined,
                numberOfPortfolioPages: undefined,
                potentialResultsTotal: undefined,
            }
        case 'instrument.IndexInstruments':
            const newInstruments: State['instrumentsById'] = {}
            const newSlugs: State['slugToIdMap'] = {}
            action.payload.forEach(instrument => {
                const exchange = instrument.exchange
                    ? state.metadata.exchanges.find(exchange => exchange.name === instrument.exchange)
                    : undefined

                newInstruments[instrument.id] = {
                    ...instrument,
                    currency: exchange
                        ? (exchange.currency.toLowerCase() as Instrument['currency']) // TODO get the types for this from Distill as an enum?
                        : 'nzd', // TODO if there is no exchange, get it from the asset manager instead once that data exists in Distill
                }
                newSlugs[instrument.urlSlug] = instrument.id
            })
            return {
                ...state,
                instrumentsById: {
                    ...state.instrumentsById,
                    ...newInstruments,
                },
                slugToIdMap: {
                    ...state.slugToIdMap,
                    ...newSlugs,
                },
            }
        case 'instrument.IndexDetailInstrument':
            const newInstrument: State['instrumentsById'] = {}
            const underlyingInstument: State['instrumentsById'] = {}
            const newSlug: State['slugToIdMap'] = {}

            const instrument = action.payload
            const exchange = instrument.exchange
                ? state.metadata.exchanges.find(exchange => exchange.name === instrument.exchange)
                : undefined

            newInstrument[instrument.id] = {
                ...instrument,
                currency: exchange
                    ? (exchange.currency.toLowerCase() as Instrument['currency']) // TODO get the types for this from Distill as an enum?
                    : 'nzd', // TODO if there is no exchange, get it from the asset manager instead once that data exists in Distill
            }

            // If the instrument has an `fmsFundId`, it's a KiwiSaver underlying instrument, so we swap
            // out the ID of this instrument to its wrapper fund. We can now use it as a single 'instrument'.
            // only require to store for underlying instrument from the KiwiSaver sell flow form page
            if (instrument.fmsFundId || instrument.underlyingInstrumentId) {
                const newId = instrument.fmsFundId ?? instrument.id
                const currentInstrument = state.instrumentsById[newId]
                let newMarketPrice = instrument.marketPrice
                if (currentInstrument !== undefined) {
                    newMarketPrice = currentInstrument.marketPrice
                }

                underlyingInstument[newId] = {
                    ...instrument,
                    id: newId,
                    marketPrice: newMarketPrice,
                    currency: exchange
                        ? (exchange.currency.toLowerCase() as Instrument['currency']) // TODO get the types for this from Distill as an enum?
                        : 'nzd', // TODO if there is no exchange, get it from the asset manager instead once that data exists in Distill
                }
            }
            newSlug[instrument.urlSlug] = instrument.id

            return {
                ...state,
                instrumentsById: {
                    ...state.instrumentsById,
                    ...newInstrument,
                },
                slugToIdMap: {
                    ...state.slugToIdMap,
                    ...newSlug,
                },
                underlyingInstrumentById: {
                    ...underlyingInstument,
                },
            }
        case 'instrument.RemoveCategoryFromFilter':
            return {
                ...state,
                currentSearchFilters: {
                    ...state.currentSearchFilters,
                    categories: state.currentSearchFilters.categories.filter(category => category !== action.payload),
                },
            }
        case 'instrument.RemoveExchangeFromFilter':
            return {
                ...state,
                currentSearchFilters: {
                    ...state.currentSearchFilters,
                    exchanges: state.currentSearchFilters.exchanges.filter(exchange => exchange !== action.payload),
                },
            }
        case 'instrument.RemoveInstrumentTypeFromFilter':
            return {
                ...state,
                currentSearchFilters: {
                    ...state.currentSearchFilters,
                    instrumentTypes: state.currentSearchFilters.instrumentTypes.filter(
                        instrumentType => instrumentType !== action.payload,
                    ),
                },
            }
        case 'instrument.RemoveKidsRecommendedFromFilter':
            return {
                ...state,
                currentSearchFilters: {
                    ...state.currentSearchFilters,
                    kidsRecommended: undefined,
                },
            }
        case 'instrument.RemoveUnlistedInstrumentsFromFilter':
            return {
                ...state,
                currentSearchFilters: {
                    ...state.currentSearchFilters,
                    unlistedInstruments: undefined,
                },
            }
        case 'instrument.ResetRiskLevelFilter':
            return {
                ...state,
                currentSearchFilters: {
                    ...state.currentSearchFilters,
                    riskLevel: null,
                },
            }

        case 'instrument.SearchInitialise':
            return {...state, searchInitialisedFor: action.payload}
        case 'instrument.SetChildInstrumentIds':
            return {
                ...state,
                instrumentsById: {
                    ...state.instrumentsById,
                    [action.payload.instrumentId]: {
                        ...state.instrumentsById[action.payload.instrumentId],
                        childInstrumentIds: action.payload.childInstrumentIds,
                    },
                },
            }

        case 'instrument.SetCurrentSearchQuery':
            return {
                ...state,
                currentSearchQuery: action.payload,
            }
        case 'instrument.SetCountResultsLoadingState':
            return {
                ...state,
                countResultsLoadingState: action.payload,
            }
        case 'instrument.SetCurrentSearchInput':
            return {
                ...state,
                currentSearchInput: action.payload,
            }

        case 'instrument.SetCurrentSearchList':
            return {
                ...state,
                currentSearchList: action.payload,
            }

        case 'instrument.SetCurrentSearchSort':
            return {
                ...state,
                currentSearchSort: action.payload,
            }
        case 'instrument.SetCurrentSearchTimePeriod':
            return {
                ...state,
                currentSearchTimePeriod: action.payload,
            }
        case 'instrument.SetDiyAIInstruments':
            return {
                ...state,
                diyAIIndex: action.payload.instrumentIds,
            }
        case 'instrument.SetDiyAILoadingState':
            return {
                ...state,
                diyAILoadingState: action.payload,
            }
        case 'instrument.SetdiyAIUnavailableInstrumentNames':
            return {
                ...state,
                diyAIUnavailableInstrumentNames: action.payload,
            }
        case 'instrument.SetMetadata':
            return {
                ...state,
                isMetadataInitialised: true,
                metadata: {
                    ...state.metadata,
                    filters: action.payload.filterOptions,
                    categories: action.payload.categories,
                    instrumentTypes: action.payload.instrumentTypes,
                    exchanges: action.payload.exchanges,
                    assetManagers: action.payload.assetManagers,
                    sorts: action.payload.sortOptions,
                    resourcePath: action.payload.filesHostAddress,
                    timePeriods: action.payload.priceChangeTimeOptions,
                },
            }
        case 'instrument.SetNextPageLoadingState':
            return {
                ...state,
                nextPageLoadingState: action.payload,
            }
        case 'instrument.SetPortfolioBreakdownIsOpen':
            return {
                ...state,
                portfolioBreakdownIsOpen: action.payload,
            }
        case 'instrument.SetPortfolioInstruments':
            return {
                ...state,
                portfolioIndex: action.payload.instrumentIds,
                currentPortfolioPage: action.payload.page,
                numberOfPortfolioPages: action.payload.numberOfPages,
            }
        case 'instrument.SetPortfolioInstrumentIndex':
            return {
                ...state,
                portfolioIndex: action.payload,
            }
        case 'instrument.SetPortfolioInstrumentExchangeCountry':
            return {
                ...state,
                portfolioInstrumentExchangeCountry: action.payload,
            }
        case 'instrument.SetPortfolioInstrumentRiskLevel':
            return {
                ...state,
                portfolioInstrumentRiskLevel: action.payload,
            }
        case 'instrument.SetPortfolioInstrumentTypes':
            return {
                ...state,
                portfolioInstrumentTypes: action.payload,
            }
        case 'instrument.SetPortfolioLoadingState':
            return {
                ...state,
                portfolioLoadingState: action.payload,
            }
        case 'instrument.SetPortfolioNextPageLoadingState':
            return {
                ...state,
                portfolioNextPageLoadingState: action.payload,
            }
        case 'instrument.SetPortfolioRiskLevelLabel':
            return {
                ...state,
                portfolioRiskLevelLabel: action.payload,
            }

        case 'instrument.SetPotentialResultsTotal':
            return {
                ...state,
                potentialResultsTotal: action.payload,
            }

        case 'instrument.SetPremadeGlobalAIInstruments':
            return {
                ...state,
                premadeGlobalAIIndex: action.payload.instrumentIds,
            }
        case 'instrument.SetPremadeResponsibleAIInstruments':
            return {
                ...state,
                premadeResponsibleAIIndex: action.payload.instrumentIds,
            }
        case 'instrument.SetPremadeKidsAIInstruments':
            return {
                ...state,
                premadeKidsAIIndex: action.payload.instrumentIds,
            }

        case 'instrument.SetPremadeAILoadingState':
            return {
                ...state,
                premadeAILoadingState: action.payload,
            }

        case 'instrument.SetPriceHistory':
            const currentPriceHistory = state.instrumentsById[action.payload.instrumentId].priceHistory
            const currentDayPrices = currentPriceHistory && currentPriceHistory.dayPrices
            // compare the currentPriceHistory.dayPrices and the incoming action.payload.dayPrices
            // if the date already exists, replace/update the date/price element, otherwise add the element to the dayPrices object.
            const updatedDayPrices = currentDayPrices && Object.assign(currentDayPrices, action.payload.dayPrices)
            return {
                ...state,
                instrumentsById: {
                    ...state.instrumentsById,
                    [action.payload.instrumentId]: {
                        ...state.instrumentsById[action.payload.instrumentId],
                        priceHistory: updatedDayPrices
                            ? {...action.payload, dayPrices: {...updatedDayPrices}}
                            : action.payload,
                    },
                },
            }

        case 'instrument.SetPriceHistoryLoadingState':
            return {
                ...state,
                priceHistoryLoadingState: action.payload,
            }
        case 'instrument.SetRecentlyViewedInstruments':
            return {
                ...state,
                recentlyViewedIndex: action.payload,
            }
        case 'instrument.SetRecentlyViewedLoadingState':
            return {
                ...state,
                recentlyViewedLoadingState: action.payload,
            }
        case 'instrument.SetResults':
            return {
                ...state,
                resultsIndex: action.payload.instrumentIds,
                currentResultsPage: action.payload.page,
                numberOfResultsPages: action.payload.numberOfPages,
            }

        case 'instrument.SetResultsLoading':
            return {
                ...state,
                resultsLoadingState: action.payload,
            }

        case 'instrument.SetSearchAutofocus':
            return {
                ...state,
                autofocusSearch: action.payload,
            }

        case 'instrument.SetSearchScrollItemIndex':
            return {
                ...state,
                searchScrollItemIndex: action.payload,
            }

        case 'instrument.SetTransferableLoadingState':
            return {
                ...state,
                transferableLoadingState: action.payload,
            }
        case 'instrument.SetTransferableIndex':
            return {
                ...state,
                transferableIndex: action.payload,
            }
        case 'instrument.SetWatchlistInstruments':
            return {
                ...state,
                watchlistIndex: action.payload,
            }
        case 'instrument.SetWatchlistLoadingState':
            return {
                ...state,
                watchlistLoadingState: action.payload,
            }
        case 'instrument.UpdateSearchFilters':
            return {
                ...state,
                currentSearchFilters: action.payload,
            }
        case 'instrument.SetCurrentPortfolioSortedInstrumentIds':
            return {
                ...state,
                currentPortfolioSortedInstrumentIds: action.payload,
            }
        case 'instrument.SetActiveInstrumentTab':
            return {
                ...state,
                activeInstrumentTab: action.payload,
            }
        case 'instrument.SetSearch':
            // Set all search state in one go instead of two, lessoning the chance of race conditions with dispatching actions and getState being out of sync
            return {
                ...state,
                currentSearchQuery: action.payload.query,
                currentSearchFilters: action.payload.filters,
                // These appear to get set automatically during the API action but may need to set these
                // currentSearchSort: initialState.currentSearchSort,
                // currentSearchTimePeriod: initialState.currentSearchTimePeriod,
            }
        case 'instrument.SetAutoinvestDividendsLoadingState':
            return {
                ...state,
                autoinvestDividendsLoadingState: action.payload,
            }
        case 'instrument.SetAutoinvestDividends':
            return {
                ...state,
                autoinvestDividends: action.payload,
            }
        case 'instrument.ClearDividendReinvestPreferences':
            return {
                ...state,
                autoinvestDividends: initialState.autoinvestDividends,
                autoinvestDividendsLoadingState: initialState.autoinvestDividendsLoadingState,
            }
        default:
            assertNever(action)
    }
    return state
}

export default reducer
