/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {DateTime} from 'luxon'
import * as runtime from '../runtime'
import {
    CountRequestDto,
    CountRequestDtoFromJSON,
    CountRequestDtoToJSON,
    CountResponseDto,
    CountResponseDtoFromJSON,
    CountResponseDtoToJSON,
    CurrentPricesResponseDto,
    CurrentPricesResponseDtoFromJSON,
    CurrentPricesResponseDtoToJSON,
    DetailResponseDto,
    DetailResponseDtoFromJSON,
    DetailResponseDtoToJSON,
    DividendDateField,
    DividendDateFieldFromJSON,
    DividendDateFieldToJSON,
    HistoricVwapResultModel,
    HistoricVwapResultModelFromJSON,
    HistoricVwapResultModelToJSON,
    InstrumentsInfoResponseDto,
    InstrumentsInfoResponseDtoFromJSON,
    InstrumentsInfoResponseDtoToJSON,
    PriceChangesRequestDto,
    PriceChangesRequestDtoFromJSON,
    PriceChangesRequestDtoToJSON,
    PriceChangesResponseDto,
    PriceChangesResponseDtoFromJSON,
    PriceChangesResponseDtoToJSON,
    PriceHistoryResponseDto,
    PriceHistoryResponseDtoFromJSON,
    PriceHistoryResponseDtoToJSON,
    SearchDebugResponseDto,
    SearchDebugResponseDtoFromJSON,
    SearchDebugResponseDtoToJSON,
    SearchRequestDto,
    SearchRequestDtoFromJSON,
    SearchRequestDtoToJSON,
    SearchResponseDto,
    SearchResponseDtoFromJSON,
    SearchResponseDtoToJSON,
    SingleInstrumentDividendResponseDto,
    SingleInstrumentDividendResponseDtoFromJSON,
    SingleInstrumentDividendResponseDtoToJSON,
    TopOfBookPriceDataResponseDto,
    TopOfBookPriceDataResponseDtoFromJSON,
    TopOfBookPriceDataResponseDtoToJSON,
} from '../models'

export interface ApiV1InstrumentsCountGetRequest {
    query: string
    searchFundInvestments?: boolean
    instruments?: Array<string>
    parentInstrument?: string
    categories?: Array<string>
    exchanges?: Array<string>
    instrumentTypes?: Array<string>
    nz?: boolean
    unlistedInstruments?: boolean
    minRisk?: number
    maxRisk?: number
    kidsRecommended?: boolean
    tradingStatuses?: Array<string>
    strategy?: string
    exchangeCountries?: Array<string>
}

export interface ApiV1InstrumentsCountV2GetRequest {
    query: string
    scope: string
    searchFundInvestments?: boolean
    instruments?: Array<string>
    parentInstrument?: string
    categories?: Array<string>
    exchanges?: Array<string>
    instrumentTypes?: Array<string>
    nz?: boolean
    unlistedInstruments?: boolean
    minRisk?: number
    maxRisk?: number
    kidsRecommended?: boolean
    tradingStatuses?: Array<string>
    strategy?: string
    exchangeCountries?: Array<string>
}

export interface ApiV1InstrumentsCurrentpricesV2GetRequest {
    scope: string
}

export interface ApiV1InstrumentsDebugGetRequest {
    strategy: string
    query: string
    testSuiteId: string
}

export interface ApiV1InstrumentsDividendsV2IdGetRequest {
    id: string
    startDate?: DateTime
    endDate?: DateTime
    filteredDateField?: DividendDateField
}

export interface ApiV1InstrumentsGetRequest {
    query: string
    page?: number
    perPage?: number
    sort?: string
    priceChangeTime?: string
    searchFundInvestments?: boolean
    instruments?: Array<string>
    parentInstrument?: string
    categories?: Array<string>
    exchanges?: Array<string>
    instrumentTypes?: Array<string>
    nz?: boolean
    unlistedInstruments?: boolean
    minRisk?: number
    maxRisk?: number
    kidsRecommended?: boolean
    tradingStatuses?: Array<string>
    strategy?: string
    exchangeCountries?: Array<string>
}

export interface ApiV1InstrumentsGetV2IdGetRequest {
    id: string
    scope: string
}

export interface ApiV1InstrumentsGetbyurlslugV2UrlSlugGetRequest {
    urlSlug: string
    scope: string
}

export interface ApiV1InstrumentsHistoricVwapIdGetRequest {
    id: string
    startDate: DateTime
    endDate: DateTime
    scope: string
}

export interface ApiV1InstrumentsIdDividendsGetRequest {
    id: string
    startDate?: DateTime
    endDate?: DateTime
    filteredDateField?: DividendDateField
}

export interface ApiV1InstrumentsIdGetRequest {
    id: string
}

export interface ApiV1InstrumentsIdPricehistoryGetRequest {
    id: string
    startDate?: DateTime
    endDate?: DateTime
}

export interface ApiV1InstrumentsIdPricehistoryV2GetRequest {
    id: string
    startDate?: DateTime
    endDate?: DateTime
}

export interface ApiV1InstrumentsInfoV2GetRequest {
    scope: string
    searchFundInvestments?: boolean
}

export interface ApiV1InstrumentsPortfoliocountPostRequest {
    countRequestDto?: CountRequestDto
}

export interface ApiV1InstrumentsPortfoliocountV2PostRequest {
    scope: string
    countRequestDto?: CountRequestDto
}

export interface ApiV1InstrumentsPortfoliosearchV2PostRequest {
    scope: string
    searchRequestDto?: SearchRequestDto
}

export interface ApiV1InstrumentsPostRequest {
    searchRequestDto: SearchRequestDto
}

export interface ApiV1InstrumentsPriceChangesPostRequest {
    scope: string
    priceChangesRequestDto?: PriceChangesRequestDto
}

export interface ApiV1InstrumentsSearchV2GetRequest {
    query: string
    scope: string
    page?: number
    perPage?: number
    sort?: string
    priceChangeTime?: string
    searchFundInvestments?: boolean
    instruments?: Array<string>
    parentInstrument?: string
    categories?: Array<string>
    exchanges?: Array<string>
    instrumentTypes?: Array<string>
    nz?: boolean
    unlistedInstruments?: boolean
    minRisk?: number
    maxRisk?: number
    kidsRecommended?: boolean
    tradingStatuses?: Array<string>
    strategy?: string
    exchangeCountries?: Array<string>
}

export interface ApiV1InstrumentsTopOfBookIdGetRequest {
    id: string
    scope: string
}

export interface ApiV1InstrumentsUnadjustedpricehistoryIdGetRequest {
    id: string
    scope: string
    startDate?: DateTime
    endDate?: DateTime
}

export interface ApiV1InstrumentsUrlslugUrlSlugGetRequest {
    urlSlug: string
}

export interface PricehistoryV2IdGetRequest {
    id: string
    startDate?: DateTime
    endDate?: DateTime
}

/**
 *
 */
export class InstrumentsApi extends runtime.BaseAPI {
    /**
     */
    async apiV1InstrumentsCountGetRaw(
        requestParameters: ApiV1InstrumentsCountGetRequest,
    ): Promise<runtime.ApiResponse<CountResponseDto>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError(
                'query',
                'Required parameter requestParameters.query was null or undefined when calling apiV1InstrumentsCountGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.searchFundInvestments !== undefined) {
            queryParameters['SearchFundInvestments'] = requestParameters.searchFundInvestments
        }

        if (requestParameters.query !== undefined) {
            queryParameters['Query'] = requestParameters.query
        }

        if (requestParameters.instruments) {
            queryParameters['Instruments'] = requestParameters.instruments
        }

        if (requestParameters.parentInstrument !== undefined) {
            queryParameters['ParentInstrument'] = requestParameters.parentInstrument
        }

        if (requestParameters.categories) {
            queryParameters['Categories'] = requestParameters.categories
        }

        if (requestParameters.exchanges) {
            queryParameters['Exchanges'] = requestParameters.exchanges
        }

        if (requestParameters.instrumentTypes) {
            queryParameters['InstrumentTypes'] = requestParameters.instrumentTypes
        }

        if (requestParameters.nz !== undefined) {
            queryParameters['Nz'] = requestParameters.nz
        }

        if (requestParameters.unlistedInstruments !== undefined) {
            queryParameters['UnlistedInstruments'] = requestParameters.unlistedInstruments
        }

        if (requestParameters.minRisk !== undefined) {
            queryParameters['MinRisk'] = requestParameters.minRisk
        }

        if (requestParameters.maxRisk !== undefined) {
            queryParameters['MaxRisk'] = requestParameters.maxRisk
        }

        if (requestParameters.kidsRecommended !== undefined) {
            queryParameters['KidsRecommended'] = requestParameters.kidsRecommended
        }

        if (requestParameters.tradingStatuses) {
            queryParameters['TradingStatuses'] = requestParameters.tradingStatuses
        }

        if (requestParameters.strategy !== undefined) {
            queryParameters['Strategy'] = requestParameters.strategy
        }

        if (requestParameters.exchangeCountries) {
            queryParameters['ExchangeCountries'] = requestParameters.exchangeCountries
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => CountResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1InstrumentsCountGet(requestParameters: ApiV1InstrumentsCountGetRequest): Promise<CountResponseDto> {
        const response = await this.apiV1InstrumentsCountGetRaw(requestParameters)
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsCountV2GetRaw(
        requestParameters: ApiV1InstrumentsCountV2GetRequest,
    ): Promise<runtime.ApiResponse<CountResponseDto>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError(
                'query',
                'Required parameter requestParameters.query was null or undefined when calling apiV1InstrumentsCountV2Get.',
            )
        }

        if (requestParameters.scope === null || requestParameters.scope === undefined) {
            throw new runtime.RequiredError(
                'scope',
                'Required parameter requestParameters.scope was null or undefined when calling apiV1InstrumentsCountV2Get.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.searchFundInvestments !== undefined) {
            queryParameters['SearchFundInvestments'] = requestParameters.searchFundInvestments
        }

        if (requestParameters.query !== undefined) {
            queryParameters['Query'] = requestParameters.query
        }

        if (requestParameters.instruments) {
            queryParameters['Instruments'] = requestParameters.instruments
        }

        if (requestParameters.parentInstrument !== undefined) {
            queryParameters['ParentInstrument'] = requestParameters.parentInstrument
        }

        if (requestParameters.categories) {
            queryParameters['Categories'] = requestParameters.categories
        }

        if (requestParameters.exchanges) {
            queryParameters['Exchanges'] = requestParameters.exchanges
        }

        if (requestParameters.instrumentTypes) {
            queryParameters['InstrumentTypes'] = requestParameters.instrumentTypes
        }

        if (requestParameters.nz !== undefined) {
            queryParameters['Nz'] = requestParameters.nz
        }

        if (requestParameters.unlistedInstruments !== undefined) {
            queryParameters['UnlistedInstruments'] = requestParameters.unlistedInstruments
        }

        if (requestParameters.minRisk !== undefined) {
            queryParameters['MinRisk'] = requestParameters.minRisk
        }

        if (requestParameters.maxRisk !== undefined) {
            queryParameters['MaxRisk'] = requestParameters.maxRisk
        }

        if (requestParameters.kidsRecommended !== undefined) {
            queryParameters['KidsRecommended'] = requestParameters.kidsRecommended
        }

        if (requestParameters.tradingStatuses) {
            queryParameters['TradingStatuses'] = requestParameters.tradingStatuses
        }

        if (requestParameters.strategy !== undefined) {
            queryParameters['Strategy'] = requestParameters.strategy
        }

        if (requestParameters.exchangeCountries) {
            queryParameters['ExchangeCountries'] = requestParameters.exchangeCountries
        }

        if (requestParameters.scope !== undefined) {
            queryParameters['Scope'] = requestParameters.scope
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/count-v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => CountResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1InstrumentsCountV2Get(requestParameters: ApiV1InstrumentsCountV2GetRequest): Promise<CountResponseDto> {
        const response = await this.apiV1InstrumentsCountV2GetRaw(requestParameters)
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsCurrentpricesGetRaw(): Promise<runtime.ApiResponse<CurrentPricesResponseDto>> {
        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/currentprices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => CurrentPricesResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1InstrumentsCurrentpricesGet(): Promise<CurrentPricesResponseDto> {
        const response = await this.apiV1InstrumentsCurrentpricesGetRaw()
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsCurrentpricesV2GetRaw(
        requestParameters: ApiV1InstrumentsCurrentpricesV2GetRequest,
    ): Promise<runtime.ApiResponse<CurrentPricesResponseDto>> {
        if (requestParameters.scope === null || requestParameters.scope === undefined) {
            throw new runtime.RequiredError(
                'scope',
                'Required parameter requestParameters.scope was null or undefined when calling apiV1InstrumentsCurrentpricesV2Get.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.scope !== undefined) {
            queryParameters['Scope'] = requestParameters.scope
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/currentprices-v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => CurrentPricesResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1InstrumentsCurrentpricesV2Get(
        requestParameters: ApiV1InstrumentsCurrentpricesV2GetRequest,
    ): Promise<CurrentPricesResponseDto> {
        const response = await this.apiV1InstrumentsCurrentpricesV2GetRaw(requestParameters)
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsDebugGetRaw(
        requestParameters: ApiV1InstrumentsDebugGetRequest,
    ): Promise<runtime.ApiResponse<Array<SearchDebugResponseDto>>> {
        if (requestParameters.strategy === null || requestParameters.strategy === undefined) {
            throw new runtime.RequiredError(
                'strategy',
                'Required parameter requestParameters.strategy was null or undefined when calling apiV1InstrumentsDebugGet.',
            )
        }

        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError(
                'query',
                'Required parameter requestParameters.query was null or undefined when calling apiV1InstrumentsDebugGet.',
            )
        }

        if (requestParameters.testSuiteId === null || requestParameters.testSuiteId === undefined) {
            throw new runtime.RequiredError(
                'testSuiteId',
                'Required parameter requestParameters.testSuiteId was null or undefined when calling apiV1InstrumentsDebugGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.strategy !== undefined) {
            queryParameters['Strategy'] = requestParameters.strategy
        }

        if (requestParameters.query !== undefined) {
            queryParameters['Query'] = requestParameters.query
        }

        if (requestParameters.testSuiteId !== undefined) {
            queryParameters['TestSuiteId'] = requestParameters.testSuiteId
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/__debug__`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SearchDebugResponseDtoFromJSON))
    }

    /**
     */
    async apiV1InstrumentsDebugGet(
        requestParameters: ApiV1InstrumentsDebugGetRequest,
    ): Promise<Array<SearchDebugResponseDto>> {
        const response = await this.apiV1InstrumentsDebugGetRaw(requestParameters)
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsDividendsV2IdGetRaw(
        requestParameters: ApiV1InstrumentsDividendsV2IdGetRequest,
    ): Promise<runtime.ApiResponse<SingleInstrumentDividendResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter requestParameters.id was null or undefined when calling apiV1InstrumentsDividendsV2IdGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toFormat('yyyy-MM-dd')
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toFormat('yyyy-MM-dd')
        }

        if (requestParameters.filteredDateField !== undefined) {
            queryParameters['FilteredDateField'] = requestParameters.filteredDateField
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/dividends-v2/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(requestParameters.id)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue =>
            SingleInstrumentDividendResponseDtoFromJSON(jsonValue),
        )
    }

    /**
     */
    async apiV1InstrumentsDividendsV2IdGet(
        requestParameters: ApiV1InstrumentsDividendsV2IdGetRequest,
    ): Promise<SingleInstrumentDividendResponseDto> {
        const response = await this.apiV1InstrumentsDividendsV2IdGetRaw(requestParameters)
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsGetRaw(
        requestParameters: ApiV1InstrumentsGetRequest,
    ): Promise<runtime.ApiResponse<SearchResponseDto>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError(
                'query',
                'Required parameter requestParameters.query was null or undefined when calling apiV1InstrumentsGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['PerPage'] = requestParameters.perPage
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['Sort'] = requestParameters.sort
        }

        if (requestParameters.priceChangeTime !== undefined) {
            queryParameters['PriceChangeTime'] = requestParameters.priceChangeTime
        }

        if (requestParameters.searchFundInvestments !== undefined) {
            queryParameters['SearchFundInvestments'] = requestParameters.searchFundInvestments
        }

        if (requestParameters.query !== undefined) {
            queryParameters['Query'] = requestParameters.query
        }

        if (requestParameters.instruments) {
            queryParameters['Instruments'] = requestParameters.instruments
        }

        if (requestParameters.parentInstrument !== undefined) {
            queryParameters['ParentInstrument'] = requestParameters.parentInstrument
        }

        if (requestParameters.categories) {
            queryParameters['Categories'] = requestParameters.categories
        }

        if (requestParameters.exchanges) {
            queryParameters['Exchanges'] = requestParameters.exchanges
        }

        if (requestParameters.instrumentTypes) {
            queryParameters['InstrumentTypes'] = requestParameters.instrumentTypes
        }

        if (requestParameters.nz !== undefined) {
            queryParameters['Nz'] = requestParameters.nz
        }

        if (requestParameters.unlistedInstruments !== undefined) {
            queryParameters['UnlistedInstruments'] = requestParameters.unlistedInstruments
        }

        if (requestParameters.minRisk !== undefined) {
            queryParameters['MinRisk'] = requestParameters.minRisk
        }

        if (requestParameters.maxRisk !== undefined) {
            queryParameters['MaxRisk'] = requestParameters.maxRisk
        }

        if (requestParameters.kidsRecommended !== undefined) {
            queryParameters['KidsRecommended'] = requestParameters.kidsRecommended
        }

        if (requestParameters.tradingStatuses) {
            queryParameters['TradingStatuses'] = requestParameters.tradingStatuses
        }

        if (requestParameters.strategy !== undefined) {
            queryParameters['Strategy'] = requestParameters.strategy
        }

        if (requestParameters.exchangeCountries) {
            queryParameters['ExchangeCountries'] = requestParameters.exchangeCountries
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => SearchResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1InstrumentsGet(requestParameters: ApiV1InstrumentsGetRequest): Promise<SearchResponseDto> {
        const response = await this.apiV1InstrumentsGetRaw(requestParameters)
        return await response.value()
    }

    /**
     * Gets an Instrument by its ID
     */
    async apiV1InstrumentsGetV2IdGetRaw(
        requestParameters: ApiV1InstrumentsGetV2IdGetRequest,
    ): Promise<runtime.ApiResponse<DetailResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter requestParameters.id was null or undefined when calling apiV1InstrumentsGetV2IdGet.',
            )
        }

        if (requestParameters.scope === null || requestParameters.scope === undefined) {
            throw new runtime.RequiredError(
                'scope',
                'Required parameter requestParameters.scope was null or undefined when calling apiV1InstrumentsGetV2IdGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.scope !== undefined) {
            queryParameters['Scope'] = requestParameters.scope
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/get-v2/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(requestParameters.id)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => DetailResponseDtoFromJSON(jsonValue))
    }

    /**
     * Gets an Instrument by its ID
     */
    async apiV1InstrumentsGetV2IdGet(requestParameters: ApiV1InstrumentsGetV2IdGetRequest): Promise<DetailResponseDto> {
        const response = await this.apiV1InstrumentsGetV2IdGetRaw(requestParameters)
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsGetbyurlslugV2UrlSlugGetRaw(
        requestParameters: ApiV1InstrumentsGetbyurlslugV2UrlSlugGetRequest,
    ): Promise<runtime.ApiResponse<DetailResponseDto>> {
        if (requestParameters.urlSlug === null || requestParameters.urlSlug === undefined) {
            throw new runtime.RequiredError(
                'urlSlug',
                'Required parameter requestParameters.urlSlug was null or undefined when calling apiV1InstrumentsGetbyurlslugV2UrlSlugGet.',
            )
        }

        if (requestParameters.scope === null || requestParameters.scope === undefined) {
            throw new runtime.RequiredError(
                'scope',
                'Required parameter requestParameters.scope was null or undefined when calling apiV1InstrumentsGetbyurlslugV2UrlSlugGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.scope !== undefined) {
            queryParameters['Scope'] = requestParameters.scope
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/getbyurlslug-v2/{urlSlug}`.replace(
                `{${'urlSlug'}}`,
                encodeURIComponent(String(requestParameters.urlSlug)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => DetailResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1InstrumentsGetbyurlslugV2UrlSlugGet(
        requestParameters: ApiV1InstrumentsGetbyurlslugV2UrlSlugGetRequest,
    ): Promise<DetailResponseDto> {
        const response = await this.apiV1InstrumentsGetbyurlslugV2UrlSlugGetRaw(requestParameters)
        return await response.value()
    }

    /**
     * This will return the Volume Weighted Average Price (VWAP) for an instrument over a selected period.
     */
    async apiV1InstrumentsHistoricVwapIdGetRaw(
        requestParameters: ApiV1InstrumentsHistoricVwapIdGetRequest,
    ): Promise<runtime.ApiResponse<HistoricVwapResultModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter requestParameters.id was null or undefined when calling apiV1InstrumentsHistoricVwapIdGet.',
            )
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter requestParameters.startDate was null or undefined when calling apiV1InstrumentsHistoricVwapIdGet.',
            )
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter requestParameters.endDate was null or undefined when calling apiV1InstrumentsHistoricVwapIdGet.',
            )
        }

        if (requestParameters.scope === null || requestParameters.scope === undefined) {
            throw new runtime.RequiredError(
                'scope',
                'Required parameter requestParameters.scope was null or undefined when calling apiV1InstrumentsHistoricVwapIdGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toFormat('yyyy-MM-dd')
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toFormat('yyyy-MM-dd')
        }

        if (requestParameters.scope !== undefined) {
            queryParameters['Scope'] = requestParameters.scope
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/historic-vwap/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(requestParameters.id)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => HistoricVwapResultModelFromJSON(jsonValue))
    }

    /**
     * This will return the Volume Weighted Average Price (VWAP) for an instrument over a selected period.
     */
    async apiV1InstrumentsHistoricVwapIdGet(
        requestParameters: ApiV1InstrumentsHistoricVwapIdGetRequest,
    ): Promise<HistoricVwapResultModel> {
        const response = await this.apiV1InstrumentsHistoricVwapIdGetRaw(requestParameters)
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsIdDividendsGetRaw(
        requestParameters: ApiV1InstrumentsIdDividendsGetRequest,
    ): Promise<runtime.ApiResponse<SingleInstrumentDividendResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter requestParameters.id was null or undefined when calling apiV1InstrumentsIdDividendsGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toFormat('yyyy-MM-dd')
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toFormat('yyyy-MM-dd')
        }

        if (requestParameters.filteredDateField !== undefined) {
            queryParameters['FilteredDateField'] = requestParameters.filteredDateField
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/{id}/dividends`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(requestParameters.id)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue =>
            SingleInstrumentDividendResponseDtoFromJSON(jsonValue),
        )
    }

    /**
     */
    async apiV1InstrumentsIdDividendsGet(
        requestParameters: ApiV1InstrumentsIdDividendsGetRequest,
    ): Promise<SingleInstrumentDividendResponseDto> {
        const response = await this.apiV1InstrumentsIdDividendsGetRaw(requestParameters)
        return await response.value()
    }

    /**
     * Gets an Instrument by its ID                Note: this will return instruments which may normally be hidden, use with care.  This is to ensure we can display basic info about instruments which may be in an investors\' history
     */
    async apiV1InstrumentsIdGetRaw(
        requestParameters: ApiV1InstrumentsIdGetRequest,
    ): Promise<runtime.ApiResponse<DetailResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter requestParameters.id was null or undefined when calling apiV1InstrumentsIdGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => DetailResponseDtoFromJSON(jsonValue))
    }

    /**
     * Gets an Instrument by its ID                Note: this will return instruments which may normally be hidden, use with care.  This is to ensure we can display basic info about instruments which may be in an investors\' history
     */
    async apiV1InstrumentsIdGet(requestParameters: ApiV1InstrumentsIdGetRequest): Promise<DetailResponseDto> {
        const response = await this.apiV1InstrumentsIdGetRaw(requestParameters)
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsIdPricehistoryGetRaw(
        requestParameters: ApiV1InstrumentsIdPricehistoryGetRequest,
    ): Promise<runtime.ApiResponse<PriceHistoryResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter requestParameters.id was null or undefined when calling apiV1InstrumentsIdPricehistoryGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toFormat('yyyy-MM-dd')
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toFormat('yyyy-MM-dd')
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/{id}/pricehistory`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(requestParameters.id)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => PriceHistoryResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1InstrumentsIdPricehistoryGet(
        requestParameters: ApiV1InstrumentsIdPricehistoryGetRequest,
    ): Promise<PriceHistoryResponseDto> {
        const response = await this.apiV1InstrumentsIdPricehistoryGetRaw(requestParameters)
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsIdPricehistoryV2GetRaw(
        requestParameters: ApiV1InstrumentsIdPricehistoryV2GetRequest,
    ): Promise<runtime.ApiResponse<PriceHistoryResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter requestParameters.id was null or undefined when calling apiV1InstrumentsIdPricehistoryV2Get.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toFormat('yyyy-MM-dd')
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toFormat('yyyy-MM-dd')
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/{id}/pricehistory-v2`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(requestParameters.id)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => PriceHistoryResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1InstrumentsIdPricehistoryV2Get(
        requestParameters: ApiV1InstrumentsIdPricehistoryV2GetRequest,
    ): Promise<PriceHistoryResponseDto> {
        const response = await this.apiV1InstrumentsIdPricehistoryV2GetRaw(requestParameters)
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsInfoGetRaw(): Promise<runtime.ApiResponse<InstrumentsInfoResponseDto>> {
        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => InstrumentsInfoResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1InstrumentsInfoGet(): Promise<InstrumentsInfoResponseDto> {
        const response = await this.apiV1InstrumentsInfoGetRaw()
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsInfoV2GetRaw(
        requestParameters: ApiV1InstrumentsInfoV2GetRequest,
    ): Promise<runtime.ApiResponse<InstrumentsInfoResponseDto>> {
        if (requestParameters.scope === null || requestParameters.scope === undefined) {
            throw new runtime.RequiredError(
                'scope',
                'Required parameter requestParameters.scope was null or undefined when calling apiV1InstrumentsInfoV2Get.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.scope !== undefined) {
            queryParameters['Scope'] = requestParameters.scope
        }

        if (requestParameters.searchFundInvestments !== undefined) {
            queryParameters['searchFundInvestments'] = requestParameters.searchFundInvestments
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/info-v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => InstrumentsInfoResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1InstrumentsInfoV2Get(
        requestParameters: ApiV1InstrumentsInfoV2GetRequest,
    ): Promise<InstrumentsInfoResponseDto> {
        const response = await this.apiV1InstrumentsInfoV2GetRaw(requestParameters)
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsPortfoliocountPostRaw(
        requestParameters: ApiV1InstrumentsPortfoliocountPostRequest,
    ): Promise<runtime.ApiResponse<CountResponseDto>> {
        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request({
            path: `/api/v1/instruments/portfoliocount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CountRequestDtoToJSON(requestParameters.countRequestDto),
        })

        return new runtime.JSONApiResponse(response, jsonValue => CountResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1InstrumentsPortfoliocountPost(
        requestParameters: ApiV1InstrumentsPortfoliocountPostRequest,
    ): Promise<CountResponseDto> {
        const response = await this.apiV1InstrumentsPortfoliocountPostRaw(requestParameters)
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsPortfoliocountV2PostRaw(
        requestParameters: ApiV1InstrumentsPortfoliocountV2PostRequest,
    ): Promise<runtime.ApiResponse<CountResponseDto>> {
        if (requestParameters.scope === null || requestParameters.scope === undefined) {
            throw new runtime.RequiredError(
                'scope',
                'Required parameter requestParameters.scope was null or undefined when calling apiV1InstrumentsPortfoliocountV2Post.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.scope !== undefined) {
            queryParameters['Scope'] = requestParameters.scope
        }

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request({
            path: `/api/v1/instruments/portfoliocount-v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CountRequestDtoToJSON(requestParameters.countRequestDto),
        })

        return new runtime.JSONApiResponse(response, jsonValue => CountResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1InstrumentsPortfoliocountV2Post(
        requestParameters: ApiV1InstrumentsPortfoliocountV2PostRequest,
    ): Promise<CountResponseDto> {
        const response = await this.apiV1InstrumentsPortfoliocountV2PostRaw(requestParameters)
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsPortfoliosearchV2PostRaw(
        requestParameters: ApiV1InstrumentsPortfoliosearchV2PostRequest,
    ): Promise<runtime.ApiResponse<SearchResponseDto>> {
        if (requestParameters.scope === null || requestParameters.scope === undefined) {
            throw new runtime.RequiredError(
                'scope',
                'Required parameter requestParameters.scope was null or undefined when calling apiV1InstrumentsPortfoliosearchV2Post.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.scope !== undefined) {
            queryParameters['Scope'] = requestParameters.scope
        }

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request({
            path: `/api/v1/instruments/portfoliosearch-v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestDtoToJSON(requestParameters.searchRequestDto),
        })

        return new runtime.JSONApiResponse(response, jsonValue => SearchResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1InstrumentsPortfoliosearchV2Post(
        requestParameters: ApiV1InstrumentsPortfoliosearchV2PostRequest,
    ): Promise<SearchResponseDto> {
        const response = await this.apiV1InstrumentsPortfoliosearchV2PostRaw(requestParameters)
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsPostRaw(
        requestParameters: ApiV1InstrumentsPostRequest,
    ): Promise<runtime.ApiResponse<SearchResponseDto>> {
        if (requestParameters.searchRequestDto === null || requestParameters.searchRequestDto === undefined) {
            throw new runtime.RequiredError(
                'searchRequestDto',
                'Required parameter requestParameters.searchRequestDto was null or undefined when calling apiV1InstrumentsPost.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request({
            path: `/api/v1/instruments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestDtoToJSON(requestParameters.searchRequestDto),
        })

        return new runtime.JSONApiResponse(response, jsonValue => SearchResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1InstrumentsPost(requestParameters: ApiV1InstrumentsPostRequest): Promise<SearchResponseDto> {
        const response = await this.apiV1InstrumentsPostRaw(requestParameters)
        return await response.value()
    }

    /**
     * Gives the price changes for a set of instruments from the specified date to today
     */
    async apiV1InstrumentsPriceChangesPostRaw(
        requestParameters: ApiV1InstrumentsPriceChangesPostRequest,
    ): Promise<runtime.ApiResponse<PriceChangesResponseDto>> {
        if (requestParameters.scope === null || requestParameters.scope === undefined) {
            throw new runtime.RequiredError(
                'scope',
                'Required parameter requestParameters.scope was null or undefined when calling apiV1InstrumentsPriceChangesPost.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.scope !== undefined) {
            queryParameters['Scope'] = requestParameters.scope
        }

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request({
            path: `/api/v1/instruments/price-changes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PriceChangesRequestDtoToJSON(requestParameters.priceChangesRequestDto),
        })

        return new runtime.JSONApiResponse(response, jsonValue => PriceChangesResponseDtoFromJSON(jsonValue))
    }

    /**
     * Gives the price changes for a set of instruments from the specified date to today
     */
    async apiV1InstrumentsPriceChangesPost(
        requestParameters: ApiV1InstrumentsPriceChangesPostRequest,
    ): Promise<PriceChangesResponseDto> {
        const response = await this.apiV1InstrumentsPriceChangesPostRaw(requestParameters)
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsSearchV2GetRaw(
        requestParameters: ApiV1InstrumentsSearchV2GetRequest,
    ): Promise<runtime.ApiResponse<SearchResponseDto>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError(
                'query',
                'Required parameter requestParameters.query was null or undefined when calling apiV1InstrumentsSearchV2Get.',
            )
        }

        if (requestParameters.scope === null || requestParameters.scope === undefined) {
            throw new runtime.RequiredError(
                'scope',
                'Required parameter requestParameters.scope was null or undefined when calling apiV1InstrumentsSearchV2Get.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['PerPage'] = requestParameters.perPage
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['Sort'] = requestParameters.sort
        }

        if (requestParameters.priceChangeTime !== undefined) {
            queryParameters['PriceChangeTime'] = requestParameters.priceChangeTime
        }

        if (requestParameters.searchFundInvestments !== undefined) {
            queryParameters['SearchFundInvestments'] = requestParameters.searchFundInvestments
        }

        if (requestParameters.query !== undefined) {
            queryParameters['Query'] = requestParameters.query
        }

        if (requestParameters.instruments) {
            queryParameters['Instruments'] = requestParameters.instruments
        }

        if (requestParameters.parentInstrument !== undefined) {
            queryParameters['ParentInstrument'] = requestParameters.parentInstrument
        }

        if (requestParameters.categories) {
            queryParameters['Categories'] = requestParameters.categories
        }

        if (requestParameters.exchanges) {
            queryParameters['Exchanges'] = requestParameters.exchanges
        }

        if (requestParameters.instrumentTypes) {
            queryParameters['InstrumentTypes'] = requestParameters.instrumentTypes
        }

        if (requestParameters.nz !== undefined) {
            queryParameters['Nz'] = requestParameters.nz
        }

        if (requestParameters.unlistedInstruments !== undefined) {
            queryParameters['UnlistedInstruments'] = requestParameters.unlistedInstruments
        }

        if (requestParameters.minRisk !== undefined) {
            queryParameters['MinRisk'] = requestParameters.minRisk
        }

        if (requestParameters.maxRisk !== undefined) {
            queryParameters['MaxRisk'] = requestParameters.maxRisk
        }

        if (requestParameters.kidsRecommended !== undefined) {
            queryParameters['KidsRecommended'] = requestParameters.kidsRecommended
        }

        if (requestParameters.tradingStatuses) {
            queryParameters['TradingStatuses'] = requestParameters.tradingStatuses
        }

        if (requestParameters.strategy !== undefined) {
            queryParameters['Strategy'] = requestParameters.strategy
        }

        if (requestParameters.exchangeCountries) {
            queryParameters['ExchangeCountries'] = requestParameters.exchangeCountries
        }

        if (requestParameters.scope !== undefined) {
            queryParameters['Scope'] = requestParameters.scope
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/search-v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => SearchResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1InstrumentsSearchV2Get(
        requestParameters: ApiV1InstrumentsSearchV2GetRequest,
    ): Promise<SearchResponseDto> {
        const response = await this.apiV1InstrumentsSearchV2GetRaw(requestParameters)
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsTopOfBookIdGetRaw(
        requestParameters: ApiV1InstrumentsTopOfBookIdGetRequest,
    ): Promise<runtime.ApiResponse<TopOfBookPriceDataResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter requestParameters.id was null or undefined when calling apiV1InstrumentsTopOfBookIdGet.',
            )
        }

        if (requestParameters.scope === null || requestParameters.scope === undefined) {
            throw new runtime.RequiredError(
                'scope',
                'Required parameter requestParameters.scope was null or undefined when calling apiV1InstrumentsTopOfBookIdGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.scope !== undefined) {
            queryParameters['Scope'] = requestParameters.scope
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/top-of-book/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(requestParameters.id)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => TopOfBookPriceDataResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1InstrumentsTopOfBookIdGet(
        requestParameters: ApiV1InstrumentsTopOfBookIdGetRequest,
    ): Promise<TopOfBookPriceDataResponseDto> {
        const response = await this.apiV1InstrumentsTopOfBookIdGetRaw(requestParameters)
        return await response.value()
    }

    /**
     * Unadjusted prices are required in portfolio calculations by Rakaia.
     */
    async apiV1InstrumentsUnadjustedpricehistoryIdGetRaw(
        requestParameters: ApiV1InstrumentsUnadjustedpricehistoryIdGetRequest,
    ): Promise<runtime.ApiResponse<PriceHistoryResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter requestParameters.id was null or undefined when calling apiV1InstrumentsUnadjustedpricehistoryIdGet.',
            )
        }

        if (requestParameters.scope === null || requestParameters.scope === undefined) {
            throw new runtime.RequiredError(
                'scope',
                'Required parameter requestParameters.scope was null or undefined when calling apiV1InstrumentsUnadjustedpricehistoryIdGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toFormat('yyyy-MM-dd')
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toFormat('yyyy-MM-dd')
        }

        if (requestParameters.scope !== undefined) {
            queryParameters['Scope'] = requestParameters.scope
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/unadjustedpricehistory/{id}`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(requestParameters.id)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => PriceHistoryResponseDtoFromJSON(jsonValue))
    }

    /**
     * Unadjusted prices are required in portfolio calculations by Rakaia.
     */
    async apiV1InstrumentsUnadjustedpricehistoryIdGet(
        requestParameters: ApiV1InstrumentsUnadjustedpricehistoryIdGetRequest,
    ): Promise<PriceHistoryResponseDto> {
        const response = await this.apiV1InstrumentsUnadjustedpricehistoryIdGetRaw(requestParameters)
        return await response.value()
    }

    /**
     */
    async apiV1InstrumentsUrlslugUrlSlugGetRaw(
        requestParameters: ApiV1InstrumentsUrlslugUrlSlugGetRequest,
    ): Promise<runtime.ApiResponse<DetailResponseDto>> {
        if (requestParameters.urlSlug === null || requestParameters.urlSlug === undefined) {
            throw new runtime.RequiredError(
                'urlSlug',
                'Required parameter requestParameters.urlSlug was null or undefined when calling apiV1InstrumentsUrlslugUrlSlugGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/instruments/urlslug/{urlSlug}`.replace(
                `{${'urlSlug'}}`,
                encodeURIComponent(String(requestParameters.urlSlug)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => DetailResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1InstrumentsUrlslugUrlSlugGet(
        requestParameters: ApiV1InstrumentsUrlslugUrlSlugGetRequest,
    ): Promise<DetailResponseDto> {
        const response = await this.apiV1InstrumentsUrlslugUrlSlugGetRaw(requestParameters)
        return await response.value()
    }

    /**
     */
    async pricehistoryV2IdGetRaw(
        requestParameters: PricehistoryV2IdGetRequest,
    ): Promise<runtime.ApiResponse<PriceHistoryResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter requestParameters.id was null or undefined when calling pricehistoryV2IdGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toFormat('yyyy-MM-dd')
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toFormat('yyyy-MM-dd')
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/pricehistory-v2/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => PriceHistoryResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async pricehistoryV2IdGet(requestParameters: PricehistoryV2IdGetRequest): Promise<PriceHistoryResponseDto> {
        const response = await this.pricehistoryV2IdGetRaw(requestParameters)
        return await response.value()
    }
}
