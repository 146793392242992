import {Button} from '@design-system/button'
import React from 'react'
import {useNavigate} from 'react-router'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import CompletedTransfer from '~/sections/invest/sections/transfer-shares/widgets/completed-transfer/CompletedTransfer'
import PendingTransfer from '~/sections/invest/sections/transfer-shares/widgets/pending-transfer/PendingTransfer'
import {useAppSelector, useAppDispatch} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'
import actions from '~/store/transfer/actions'

export const TransferSharesLanding: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const getInstrumentsByIds = (instrumentIds: string[]) =>
        dispatch(instrumentActions.getInstrumentsByIds(instrumentIds))

    const [isLoading, setIsLoading] = React.useState(false)
    const {transferOrders, groupTransfers, transferLoadingState, registryDetails} = useAppSelector(({transfer}) => ({
        transferOrders: transfer.transferOrders,
        groupTransfers: transfer.groupedTransfers,
        transferLoadingState: transfer.transfersLoadingState,
        registryDetails: transfer.registryDetails,
    }))
    const instruments = useAppSelector(({instrument}) => instrument.instrumentsById)
    const filteredRegistryDetails = registryDetails.filter(
        registryDetail =>
            registryDetail.reference_type === 'CSN' &&
            ['pending', 'approved', 'rejected'].includes(registryDetail.state),
    )
    groupTransfers.sort((a, b) => b.created.toMillis() - a.created.toMillis())
    filteredRegistryDetails.sort((a, b) => b.created.toMillis() - a.created.toMillis())

    const pendingGroupTransfers = groupTransfers.filter(transfer =>
        ['incomplete', 'new', 'processing', 'submitted'].includes(transfer.state),
    )
    const completedGroupTransfers = groupTransfers.filter(transfer =>
        ['complete', 'rejected', 'cancelled'].includes(transfer.state),
    )

    React.useEffect(() => {
        if (transferLoadingState === 'loading') {
            setIsLoading(true)
            dispatch(actions.FetchTransfers())
            return
        }

        const instrumentIdsToLoad = transferOrders.reduce<string[]>((instrumentIds, transferOrder) => {
            const transferFundId = transferOrder.fund_id
            if (!instruments[transferFundId]) {
                return [...instrumentIds, transferFundId]
            }
            return instrumentIds
        }, [])

        if (transferLoadingState === 'ready' && instrumentIdsToLoad.length > 0) {
            getInstrumentsByIds(instrumentIdsToLoad)
        }

        if (['ready', 'error'].includes(transferLoadingState) && instrumentIdsToLoad.length === 0) {
            setIsLoading(false)
            // if there aren't any details which the customer can view/act on
            // skip the landing page and go straight to starting a new transfer
            const nzxRegistryDetails = registryDetails.filter(detail => detail.reference_type === 'CSN')
            if (nzxRegistryDetails.length === 0 && groupTransfers.length === 0 && transferOrders.length === 0) {
                startNewTransfer(true)
            }
        }
    }, [transferOrders, transferLoadingState, instruments])

    const startNewTransfer = (replaceUrl = false) => {
        // Clear the NZX stagedTransferOrder. Some components use accounting.transfer stagedTransferOrder to determine which actions to use
        dispatch(actions.ResetStagedTransferOrder())
        navigate(profileUrl('invest/portfolio-transfer-shares/transfer-direction'), {replace: replaceUrl})
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--transfer-landing"
                leftButton="back"
                onLeftButtonClick={() => navigate(profileUrl('invest/manage'))}
                title="Transfer shares"
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <div className={styles.verifiedAndPendingSection}>
                    {isLoading && <Loading />}
                    {!isLoading && (
                        <>
                            <h1>Pending transfers</h1>
                            {pendingGroupTransfers.length > 0 ? (
                                pendingGroupTransfers.map(group => {
                                    return <PendingTransfer transferOrderGroup={group} key={group.group_id} />
                                })
                            ) : (
                                <p className={spacing.spaceAbove16}>You don’t have any pending transfers.</p>
                            )}
                            <h1 className={spacing.spaceAbove16}>Completed transfers</h1>
                            {completedGroupTransfers.length > 0 ? (
                                <>
                                    {completedGroupTransfers.map(group => {
                                        return (
                                            <CompletedTransfer
                                                transferOrderGroup={group}
                                                transferOrders={transferOrders.filter(
                                                    transferOrder => transferOrder.group_id === group.group_id,
                                                )}
                                                key={group.group_id}
                                            />
                                        )
                                    })}
                                </>
                            ) : (
                                <p className={spacing.spaceAbove16}>You don’t have any completed transfers.</p>
                            )}
                        </>
                    )}
                </div>
            </Page>
            {!isLoading && (
                <ActionBar>
                    <Button
                        type="primary"
                        label="Start a new transfer"
                        dataTestId="button--start-new-transfer"
                        onClick={() => startNewTransfer()}
                    />
                </ActionBar>
            )}
        </>
    )
}

export default TransferSharesLanding
