import * as React from 'react'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import PDSDisplay from '~/global/widgets/pds-display/PDSDisplay'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {isNavigationDirective, useNavigate} from '~/migrate-react-router'
import {connect} from '~/store/connect'
import actions from '~/store/order/actions'
import {StagedBuyOrder} from '~/store/order/types'
import {UnwrapThunkAction} from '~/store/types'

const BuyPDS: React.FunctionComponent<BuyAcceptPDSProps> = ({stagedBuyOrder, submitPDSAgreement}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const notificationContext = React.useContext(NotificationContext)

    if (!stagedBuyOrder || !stagedBuyOrder.needReadPDS) {
        return <WeSlippedUp />
    }

    return (
        <Page withoutDefaultPadding>
            <PageBack />
            <PDSDisplay
                submitPDSAgreement={async () => {
                    const error = await submitPDSAgreement(stagedBuyOrder)

                    if (isNavigationDirective(error)) {
                        error.execute(navigate, profileUrl)
                    } else if (error) {
                        notificationContext.showModalError({message: unknownErrorMessage})
                        return Error('Unable to submit PDS agreement')
                    }
                }}
                pdsData={stagedBuyOrder.needReadPDS}
            />
        </Page>
    )
}

interface StoreProps {
    stagedBuyOrder?: StagedBuyOrder
}

interface DispatchProps {
    submitPDSAgreement: UnwrapThunkAction<typeof actions.SubmitPDSAgreement>
}

type BuyAcceptPDSProps = StoreProps & DispatchProps

export default connect<StoreProps, DispatchProps, {}>(
    state => ({
        stagedBuyOrder: state.order.stagedBuyOrder,
    }),
    dispatch => ({
        submitPDSAgreement: stagedBuyOrder => dispatch(actions.SubmitPDSAgreement(stagedBuyOrder)),
    }),
)(BuyPDS)
