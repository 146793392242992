import {assertNever} from '~/global/utils/assert-never/assertNever'
import {TransferOrderInstrument} from '~/store/transfer/types'
import {ActionsType} from './actions'
import {State} from './types'

const initialState: State = {
    transfersLoadingState: 'loading',
    registryDetails: [],
    transferOrders: [],
    groupedTransfers: [],
}

function reducer(state: State = initialState, action: ActionsType): State {
    switch (action.type) {
        case 'transfer.set_transfers': {
            let stagedTransferOrder = state.stagedTransferOrder

            if (stagedTransferOrder) {
                const groupedTransfers = action.payload.grouped_transfers

                // Reconstruct stagedTransferOrder from request
                let transfer = groupedTransfers.find(({group_id}) => group_id === stagedTransferOrder!.groupId)

                if (!transfer && stagedTransferOrder.instruments.length > 0) {
                    // The group_id has just been created, select the most recent groupedTransfer
                    transfer = [...groupedTransfers]
                        .sort((a, b) => b.created.toMillis() - a.created.toMillis())
                        .find(
                            ({reference, reference_type}) =>
                                stagedTransferOrder?.reference === reference &&
                                stagedTransferOrder.referenceType === reference_type,
                        )
                }

                if (!transfer) {
                    // Get the relevant registry detail to populate the staged transfer order
                    // Use the methods to get this information in order from most to least certain.

                    let registryDetail
                    // A newly created registry detail can be found via the new_registry_detail_id field on the API response
                    if (action.payload.new_registry_detail_id) {
                        registryDetail = action.payload.registry_details.find(
                            ({id}) => action.payload.new_registry_detail_id === id,
                        )
                        // If the stagedTransferOrder knows which registry detail it is for, use this
                    } else if (stagedTransferOrder?.registryDetailId) {
                        registryDetail = action.payload.registry_details.find(
                            ({id}) => stagedTransferOrder?.registryDetailId === id,
                        )
                        // Default to the latest (created) registry detail which matches the reference and reference type
                        // of the stagedTransferOrder, excluding deleted and rejected registry details
                    } else {
                        registryDetail = action.payload.registry_details
                            .filter(detail => !['deleted', 'rejected'].includes(detail.state))
                            .sort((a, b) => b.created.toMillis() - a.created.toMillis())
                            .find(
                                ({reference, reference_type}) =>
                                    stagedTransferOrder?.reference === reference &&
                                    stagedTransferOrder.referenceType === reference_type,
                            )
                    }

                    if (!registryDetail) {
                        return {
                            ...state,
                            transfersLoadingState: 'error',
                        }
                    }
                    stagedTransferOrder = {
                        ...stagedTransferOrder,
                        address: registryDetail.address,
                        direction: stagedTransferOrder.direction,
                        exchange: stagedTransferOrder.exchange,
                        instruments: stagedTransferOrder.instruments,
                        usTransferPlatform: stagedTransferOrder.usTransferPlatform,
                        registryDetailId: registryDetail.id,
                        pid: registryDetail.pid,
                        reference: registryDetail.reference,
                        referenceType: registryDetail.reference_type,
                        registryDetailDocuments: stagedTransferOrder.registryDetailDocuments,
                        transferOrderDocuments: stagedTransferOrder.transferOrderDocuments,
                        transferEntirePortfolio: stagedTransferOrder.transferEntirePortfolio,
                    }
                } else {
                    // Get the StagedTransferInstruments from API
                    const instruments = transfer.instruments.map(
                        (instrument, indexFromApi): TransferOrderInstrument => {
                            // Find the index of the instrument in the existing StagedTransferOrder
                            // This is usefull for stepping through instruments and updating the shares, estimated share price
                            let index = stagedTransferOrder?.instruments?.find(
                                oldInstrument => oldInstrument.instrumentId === instrument.instrument_id,
                            )?.index

                            // Use the index from the API.
                            // By default, transfers are ordered by created date
                            if (!index && index !== 0) {
                                index = indexFromApi
                            }

                            return {
                                index,
                                instrumentId: instrument.instrument_id,
                                instrumentSlug: instrument.instrument_slug,
                                records: instrument.records,
                            }
                        },
                    )

                    instruments.sort((a, b) => a.index - b.index)

                    stagedTransferOrder = {
                        ...stagedTransferOrder,
                        address: transfer.address,
                        direction: transfer.direction,
                        exchange: transfer.exchange,
                        groupId: transfer.group_id,
                        instruments,
                        usTransferPlatform: stagedTransferOrder.usTransferPlatform,
                        reference: transfer.reference,
                        referenceType: transfer.reference_type,
                        registryDetailDocuments: transfer.registry_detail_documents,
                        registryDetailId: transfer.customer_registry_detail_id,
                        searchTerm: stagedTransferOrder.searchTerm || '',
                        transferOrderDocuments: transfer.transfer_order_documents,
                    }
                }
            }

            return {
                ...state,
                transfersLoadingState: 'ready',
                transferOrders: action.payload.transfer_orders,
                registryDetails: action.payload.registry_details,
                groupedTransfers: action.payload.grouped_transfers.map(aGroup => ({
                    ...aGroup,
                    rejected:
                        action.payload.transfer_orders.filter(to => to.group_id === aGroup.group_id && to.rejected_at)
                            .length > 0,
                })),
                stagedTransferOrder,
            }
        }
        case 'transfer.set_transfers_loading_state':
            return {...state, transfersLoadingState: action.payload}
        case 'transfer.set_staged_transfer_order':
            return {...state, stagedTransferOrder: action.payload}
        case 'transfer.reset_staged_transfer_order':
            return {...state, stagedTransferOrder: undefined}
        case 'transfer.add_instrument_to_staged_transfer_order':
            return {
                ...state,
                stagedTransferOrder: {
                    ...state.stagedTransferOrder!,
                    instruments: [
                        ...state.stagedTransferOrder!.instruments,
                        {
                            index: state.stagedTransferOrder!.instruments.length,
                            instrumentId: action.payload.instrumentId,
                            instrumentSlug: action.payload.instrumentSlug,
                            records: [],
                        },
                    ],
                },
            }
        case 'transfer.remove_instrument_from_staged_transfer_order':
            // Remove instrument from stagedTransferOrder, and reset the indexes
            return {
                ...state,
                stagedTransferOrder: {
                    ...state.stagedTransferOrder!,
                    instruments: state
                        .stagedTransferOrder!.instruments.filter(
                            instrument => instrument.instrumentId !== action.payload,
                        )
                        .map((instrument, index) => ({...instrument, index})),
                },
            }
        case 'transfer.reset_staged_transfer_order_instruments':
            return {
                ...state,
                stagedTransferOrder: {
                    ...state.stagedTransferOrder!,
                    instruments: [],
                },
            }
        case 'transfer.set_transfer_order_search_term':
            return {
                ...state,
                stagedTransferOrder: {
                    ...state.stagedTransferOrder!,
                    searchTerm: action.payload.searchTerm,
                },
            }
        case 'transfer.add_staged_transfer_order_details':
            return {
                ...state,
                stagedTransferOrder: {
                    ...state.stagedTransferOrder!,
                    instruments: state
                        .stagedTransferOrder!.instruments.filter(
                            instrument => action.payload.instrumentId !== instrument.instrumentId,
                        ) // get rid of the existing entry if it exists
                        .concat({
                            index: action.payload.index,
                            instrumentId: action.payload.instrumentId,
                            instrumentSlug: action.payload.instrumentSlug,
                            records: action.payload.records,
                        }),
                },
            }

        case 'transfer.invalidate_transfer_state':
            return {
                ...state,
                transfersLoadingState: 'loading',
            }
        case 'transfer.set_transfer_info':
            return {
                ...state,
                transferInfo: action.payload,
            }
        case 'transfer.clear_state':
            return initialState
        default:
            assertNever(action)
    }
    return state
}

export default reducer
