import {TileCard} from '@design-system/tile-card'
import React from 'react'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {isLoading} from '~/global/utils/is-loading/isLoading'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {
    generateExerciseOfferInFlightText,
    generateInFlightText,
    generateOverlayFlags,
    generateTransferOrderInFlightText,
} from '~/global/utils/tile-flag-helpers/tileFlagHelpers'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {DollarValue, PercentValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {useNavigate} from '~/migrate-react-router'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import orderActions from '~/store/order/actions'
import {PortfolioItemDetails} from '~/store/portfolio/types'
interface PortfolioInvestmentsTileProps {
    includeSoldInvestments: boolean
    instrument: Instrument
    portfolioItemDetails: PortfolioItemDetails
    resourcePath: string
    setRecentlyViewedInstrument: (id: string) => void
}

const PortfolioInvestmentsTile: React.FunctionComponent<PortfolioInvestmentsTileProps> = ({
    includeSoldInvestments,
    instrument,
    portfolioItemDetails,
    resourcePath,
    setRecentlyViewedInstrument,
}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const applicationsByInstrumentId = useAppSelector(s => s.order.applicationsByInstrument)
    const applicationsByInstrumentIdLoadingState = useAppSelector(s => s.order.applicationsByInstrumentLoadingState)

    const canShowExerciseOfferInFlight =
        instrument.instrumentType === 'rights' && ['active', 'notrade', 'closeonly'].includes(instrument.tradingStatus)

    // Ensure we have application data for instruments which need to show an exercise offer
    React.useEffect(() => {
        if (
            canShowExerciseOfferInFlight &&
            !applicationsByInstrumentId[instrument.id] &&
            !isLoading(applicationsByInstrumentIdLoadingState)
        ) {
            dispatch(orderActions.LoadInstrumentApplications(instrument.id))
        }
    }, [applicationsByInstrumentId, applicationsByInstrumentIdLoadingState, canShowExerciseOfferInFlight])

    const buySellOrderInFlightText = generateInFlightText({
        'buy order': portfolioItemDetails.buyOrderCount,
        'sell order': portfolioItemDetails.sellOrderCount,
        application: portfolioItemDetails.applicationCount,
        vote: portfolioItemDetails.voteCount,
    })

    const transferOrderInFlightText = generateTransferOrderInFlightText(
        portfolioItemDetails.transferOrderCount,
        portfolioItemDetails.transferTotal,
    )

    const rightsOfferInProgressText = generateExerciseOfferInFlightText(
        portfolioItemDetails.sharesOwned,
        portfolioItemDetails.exerciseApplicationCount,
        canShowExerciseOfferInFlight,
        portfolioItemDetails.exerciseDaysRemaining,
    )

    const flags = generateOverlayFlags({
        essBadgeLabel: portfolioItemDetails.essBadgeLabel,
        upcomingVoteCount: portfolioItemDetails.upcomingVoteCount,
        transferOrderInFlightText,
        buySellOrderInFlightText,
        rightsOfferInProgressText,
    })

    const simplifiedView =
        portfolioItemDetails.hasEssEmployment &&
        !portfolioItemDetails.buyOrderCount &&
        !portfolioItemDetails.sharesOwned

    const shareTypeOrScheme = !portfolioItemDetails.essBadgeLabel
        ? shareLabel({instrument, isPlural: true, isCapitalised: true})
        : 'scheme'

    return (
        <TileCard
            dataTestId="tile-card--portfolio-tile"
            investmentThumbnailPath={instrument.logos.thumb ? `${resourcePath}${instrument.logos.thumb}` : undefined}
            investmentName={instrument.name}
            investmentSymbol={instrument.symbol}
            investmentTypeInformation={
                (tradingType(instrument) === 'listed' || tradingType(instrument) === 'adr') &&
                !isInstrumentInNoTrade(instrument) && (
                    <>
                        <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                        <PronounceLetters text={instrument.exchange} />
                    </>
                )
            }
            value={
                !simplifiedView && (
                    <DollarValue value={portfolioItemDetails.investmentValue} currency={instrument.currency} />
                )
            }
            statsFooter={!simplifiedView}
            shareLabel={shareTypeOrScheme}
            statsSharesHeld={<ShareValue value={portfolioItemDetails.sharesOwned} />}
            statsReturnsPercentage={
                <PercentValue
                    value={
                        includeSoldInvestments
                            ? portfolioItemDetails.simpleReturn
                            : portfolioItemDetails.unrealisedSimpleReturn
                    }
                />
            }
            onClick={() => {
                setRecentlyViewedInstrument(instrument.id)
                navigate(
                    profileUrl('invest/:instrumentSlug', {
                        instrumentSlug: instrument.urlSlug,
                    }),
                )
            }}
            overlayFlags={flags}
        />
    )
}

export default PortfolioInvestmentsTile
