import React from 'react'
import {Model} from '~/api/retail/types'
import {IdentityVerificationStep} from '~/global/utils/identity-verification/identityVerification'
import Page from '~/global/widgets/page/Page'
import SignupToolbar from '~/sections/user/sections/sign-up/widgets/signup-toolbar/SignupToolbar'
import {connect} from '~/store/connect'
import AUIdentityForms from './widgets/au-forms/AUIdentityForms'
import NZIdentityForms from './widgets/nz-forms/NZIdentityForms'

export const DependentIdentityDetails: React.FunctionComponent<IdentityDetailsProps> = ({
    preferredName,
    jurisdiction,
    setStep,
}) => (
    <>
        <SignupToolbar />
        <Page>
            <div>
                {jurisdiction === 'au' ? (
                    <AUIdentityForms isDependent={true} preferredName={preferredName} setStep={setStep} />
                ) : (
                    <NZIdentityForms isDependent={true} preferredName={preferredName} setStep={setStep} />
                )}
            </div>
        </Page>
    </>
)

interface OwnProps {
    setStep: (step: IdentityVerificationStep) => void
}
interface StoreProps {
    preferredName: string
    jurisdiction: Model.User['jurisdiction']
}

type IdentityDetailsProps = StoreProps & OwnProps

export default connect<StoreProps, {}, OwnProps>(({identity}) => ({
    preferredName: identity.user!.preferred_name,
    jurisdiction: identity.user!.jurisdiction,
}))(props => <DependentIdentityDetails {...props} />)
