import cn from 'classnames'
import React from 'react'
import {Link} from 'react-router-dom'
import {WithArrowRight} from '~/sections/explore/widgets/with-arrow-right/WithArrowRight'
import styles from './ContentCard.scss'

export interface ContentCard {
    title: string
    linkText: string
    url: string
    colourPreset?: 'melon' | 'blueberry' | 'blueberry2' | 'pineapple' | 'pool' | 'mint' | 'grape'
    extras?: {
        explore_content_label?: string
        eyebrow_label: string
        priority?: number
    }
}

export interface ContentCardProps {
    card: ContentCard
    className?: string
}

export const ContentCard: React.FunctionComponent<ContentCardProps> = ({card, className}) => {
    if (!card) {
        return null
    }

    const {linkText, title, url, colourPreset = 'blueberry', extras} = card as ContentCard

    let href: URL | string
    interface ExternalLinkAttributes {
        target?: string
        rel?: string
    }

    let externalLinkAttributes: ExternalLinkAttributes = {target: '_blank', rel: 'noopener noreferrer'}

    try {
        const parsedUrl = new URL(url)

        if (['app.sharesies.com', 'app.sharesies.nz'].includes(parsedUrl.hostname)) {
            href = parsedUrl.pathname
            externalLinkAttributes = {}
        } else {
            href = parsedUrl.href
        }
    } catch (error) {
        href = url
    }

    return (
        <section className={cn(styles.contentCard, className)} data-priority={extras?.priority || 0}>
            <h2>{extras?.explore_content_label}</h2>
            <Link className={cn(styles.announcement, styles[colourPreset])} to={href} {...externalLinkAttributes}>
                {extras?.eyebrow_label && <div className={styles.eyebrowLabel}>{extras?.eyebrow_label}</div>}
                <h3>{title}</h3>

                {linkText && <WithArrowRight>{linkText}</WithArrowRight>}
            </Link>
        </section>
    )
}
