import {useLocation} from 'react-router'

// Roundups and linked bank topups can be accessed from the wallet, explore, and account pages.
// This is a helper to check where the user came from.
export const useBankLinkingReferrer = ():
    | 'wallet'
    | 'explore'
    | 'settings/bank-accounts-cards'
    | 'linked-bank-topup' => {
    const {pathname} = useLocation()

    if (pathname.includes('linked-bank-topup')) {
        return 'linked-bank-topup'
    } else if (pathname.includes('wallet')) {
        return 'wallet'
    } else if (pathname.includes('settings/bank-accounts-cards')) {
        return 'settings/bank-accounts-cards'
    }
    return 'explore'
}
