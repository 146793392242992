import React from 'react'
import {useNavigate} from 'react-router'
import {EventDataTaxonomy} from '~/api/rudderstack/event-taxonomy/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {PortfolioSlice} from '~/sections/landing/widgets/portfolio-slice/PortfolioSlice'
import saveSmall from '~/sections/save/assets/images/save-small.png'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import saveActions from '~/store/save/actions'
import styles from './GetStarted.scss'

type TrackNewProductFeatureType = EventDataTaxonomy['navigation']['new_product_button_clicked']['feature']

const track = (feature: TrackNewProductFeatureType) => {
    rudderTrack('navigation', 'new_product_button_clicked', {
        feature,
        type: 'get_started',
    })
}

export const GetStartedFonterra = () => {
    const dispatch = useAppDispatch()
    const profile = useProfile()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const interestRate = useAppSelector(s => s.save.interestRate)
    const hasSaveAccount = profile.portfolios.some(p => p.product === 'SAVE')

    React.useEffect(() => {
        // Get interest rate if missing
        if (!interestRate) {
            dispatch(saveActions.getInterestRate())
        }
    }, [])

    const title = hasSaveAccount ? 'More ways to build wealth' : 'What would you like to do first?'

    const handleSaveClick = () => {
        track('save')
        navigate(profileUrl(hasSaveAccount ? 'save/personalise-account' : 'save/intro'))
    }
    return (
        <div className={styles.getStarted}>
            <h2 className={spacing.spaceBelow16}>{title}</h2>
            {/* Save */}
            <PortfolioSlice
                dataTestId="button--landing-open-save"
                image={saveSmall}
                imageBackgroundColour="Orange100"
                onClick={handleSaveClick}
            >
                <h3>{hasSaveAccount ? 'Save toward a goal' : 'Open a Save account'}</h3>
                <p>
                    {hasSaveAccount
                        ? 'Open another Save account just for a purchase or special event'
                        : `Earn ${interestRate}% on your savings—with no limits`}
                </p>
            </PortfolioSlice>
        </div>
    )
}
