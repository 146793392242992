import {useAtomValue} from 'jotai'
import React from 'react'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useWalletBreakdown} from '~/global/state-hooks/retail/useWalletBreakdown'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import TransferForm, {FormValues} from '~/sections/save/sections/transfer/widgets/transfer-form/TransferForm'
import {SavePortfolio} from '~/sections/save/types'
import {WalletPortfolio} from '~/sections/wallet/types'
import {TransferAccountDetails} from '~/store/save/types'
import {stagedTransferAtom} from '../../state/transferState'

interface TransferProps {
    additionalActionButton?: React.ReactNode
    handleSubmit: (values: FormValues) => void
    recurringByDefault?: boolean
    defaultTargetAccount?: TransferAccountDetails
    title?: string
}

const Transfer: React.FunctionComponent<TransferProps> = ({
    additionalActionButton,
    handleSubmit,
    recurringByDefault,
    defaultTargetAccount,
    title,
}) => {
    const stagedTransferOrder = useAtomValue(stagedTransferAtom)
    const profile = useProfile()

    const walletPortfolio = profile.portfolios.find(p => p.product === 'WALLET') as WalletPortfolio // We can do this because currently there is only ONE wallet portfolio per profile
    const walletBreakdown = useWalletBreakdown(walletPortfolio.id)

    const wallet: TransferAccountDetails = {
        portfolioId: walletPortfolio.id,
        name: 'Wallet',
        type: 'wallet',
        balance: walletBreakdown.instructable_balance,
    }
    const saveAccounts = profile.portfolios.filter(p => p.product === 'SAVE') as SavePortfolio[]
    const saveAccountDetails: TransferAccountDetails[] = saveAccounts.map(account => ({
        saveAccountId: account.save_account_id,
        portfolioId: account.id,
        name: account.name || 'Save',
        type: 'save',
        balance: account.balance,
    }))

    return (
        <>
            <Toolbar dataTestId="toolbar--save-transfer" leftButton="back" title={title || 'Transfer'} />
            <TransferForm
                additionalActionButton={additionalActionButton}
                accounts={[wallet, ...saveAccountDetails]}
                onSubmit={handleSubmit}
                recurringByDefault={!!recurringByDefault}
                stagedTransfer={stagedTransferOrder}
                defaultTargetAccount={defaultTargetAccount}
            />
        </>
    )
}

export default Transfer
