import Analytics from '~/api/google-analytics/googleAnalytics'

export const trackRemoveFromWatchlist = (instrumentName: string) => {
    Analytics.event({
        category: 'Invest',
        action: 'Clicked remove from watchlist',
        label: instrumentName,
    })
}

export const trackAddToWatchList = (instrumentName: string) => {
    Analytics.event({
        category: 'Invest',
        action: 'Clicked add to watchlist',
        label: instrumentName,
    })
}
