import {Button} from '@design-system/button'
import React from 'react'
import {Request} from '~/api/retail/types'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {spacing} from '~/global/scss/helpers'
import {UserSuitableError} from '~/global/utils/error-handling/errorHandling'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {
    numberOfShares,
    getMostRecentClosePrice,
    shouldTransferAllShares,
} from '~/global/utils/share-transfers/shareTransfers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useTransferInfo} from '~/global/utils/use-transfer-info/useTransferInfo'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ErrorBox} from '~/global/widgets/form-controls'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import {Toast} from '~/global/widgets/toast/Toast'
import {useNavigate} from '~/migrate-react-router'
import styles from '~/sections/invest/sections/transfer-shares/sections/nzx-transfer-shares/TransferSharesConfirm.scss'
import {TransferInsufficientFundsModal} from '~/sections/invest/sections/transfer-shares/widgets/transfer-insufficient-funds-modal/TransferInsufficientFundsModal'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/transfer/actions'
import {TransferSharesInLine, TransferSharesOutLine} from './TransferSharesConfirmLine'

const TransferSharesConfirm = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const [submitting, setSubmitting] = React.useState(false)
    const [submitError, setSubmitError] = React.useState('')
    const [insufficientFundsModalOpen, setInsufficientFundsModalOpen] = React.useState(false)

    const isAuCustomer = useAppSelector(s => s.identity.user!.jurisdiction === 'au')

    // Save all the Staged instrument entries via API
    React.useEffect(() => {
        // Only need to do for AU customers transferring IN (who create Parcels) to ensure we create a record for each parcel
        // TODO - we should make this CSN flow more consistent with other transfer types
        if (isAuCustomer && stagedTransferOrder?.direction === 'in') {
            dispatch(actions.UploadTransferInstruments())
        }
    }, [])

    const {stagedTransferOrder, instrumentsById} = useAppSelector(({transfer, instrument}) => ({
        stagedTransferOrder: transfer.stagedTransferOrder,
        instrumentsById: instrument.instrumentsById,
    }))
    const transferInfo = useTransferInfo(stagedTransferOrder?.direction, stagedTransferOrder?.usTransferPlatform)

    if (!stagedTransferOrder) {
        return <WeSlippedUp />
    }
    const {reference, referenceType, registryDetailId, direction, instruments, transferEntirePortfolio} =
        stagedTransferOrder

    const csnTransferOrders = instruments.reduce<Request.TransferOrderBulkCreate['transfer_orders']>(
        (previous, current) => {
            const numberOfShares = current.records[0].shares ?? undefined
            const averagePricePaidPerShare = current.records[0].price
            const allShares = stagedTransferOrder.direction === 'in' ? current.records[0].allShares : false
            if (!current.records[0].shares && !allShares) {
                return [] // Empty the array
            }

            // Map transfer orders to match with the API request type
            return [
                ...previous,
                {
                    fund_id: current.instrumentId,
                    shares: !allShares ? numberOfShares : undefined,
                    price: stagedTransferOrder.direction === 'in' ? averagePricePaidPerShare : undefined,
                    transfer_all_shares: allShares,
                },
            ]
        },
        [],
    )

    const instrumentCount = stagedTransferOrder?.instruments.length ?? 0
    const feeValue = (instrumentCount * Number(transferInfo?.fees.NZX.charge_amount)).toString()
    const feeCurrency = transferInfo?.fees.NZX.charge_currency.toUpperCase() ?? 'NZD'

    const handleSubmit = async () => {
        setSubmitting(true)

        try {
            const error = await dispatch(
                actions.CreateBulkNZXTransferOrder({
                    registryDetailId: registryDetailId!,
                    direction: direction!,
                    csnTransferOrders,
                    transferEntirePortfolio,
                }),
            )
            if (error) {
                throw new UserSuitableError(error)
            }

            dispatch(actions.ResetStagedTransferOrder())
            dispatch(actions.InvalidateTransferState())
            Toast('Thanks! We’ve got your transfer request')
            return navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})
        } catch (error) {
            setSubmitting(false)
            if (error instanceof UserSuitableError) {
                if (error.message === 'Wallet balance is insufficient to cover transfer fees') {
                    setInsufficientFundsModalOpen(true)
                    return
                }
                setSubmitError(error.message)
                return
            }
            setSubmitError(unknownErrorMessage)
        }
    }

    return (
        <>
            <Page data-testid="page--transfer-shares-confirm">
                <div className={styles.header}>
                    <PageBack />
                    <h1>Confirm your transfer {stagedTransferOrder.direction}</h1>

                    <p>
                        {referenceType === 'CSN' && stagedTransferOrder.direction === 'in'
                            ? 'CSN/HN'
                            : referenceType?.replace('_', ' ')}{' '}
                        {reference}
                    </p>
                </div>

                {instruments.length > 0 &&
                    instruments.map(instrument => {
                        const averagePricePaidPerShare = () => {
                            // If this instrument has more than one record (only AU customers) we don't want to provide a price in the summary
                            if (instrument.records.length > 1) {
                                return
                            }
                            // If this has just one record we can use that price
                            if (instrument.records[0].price) {
                                return instrument.records[0].price
                            }
                            // Every instrument should have a record, but it may not have a price so let's find the most recent close price
                            return getMostRecentClosePrice(instrumentsById[instrument.instrumentId])
                        }
                        // AU customers can enter multiple parcels per instrument - we don't want to display price/amount info in this case
                        const hasParcels = instrument.records && instrument.records.length > 1
                        return (
                            <React.Fragment key={instrument.instrumentId}>
                                {stagedTransferOrder.direction === 'in' ? (
                                    <TransferSharesInLine
                                        numberOfShares={numberOfShares(instrument.records)}
                                        averagePricePaidPerShare={averagePricePaidPerShare()}
                                        instrument={instrumentsById[instrument.instrumentId]}
                                        allShares={shouldTransferAllShares(instrument.records)}
                                        hasParcels={hasParcels}
                                    />
                                ) : (
                                    <TransferSharesOutLine
                                        numberOfShares={instrument.records[0].shares ?? ''}
                                        instrument={instrumentsById[instrument.instrumentId]}
                                    />
                                )}
                            </React.Fragment>
                        )
                    })}
                {stagedTransferOrder.transferEntirePortfolio && (
                    <h2 className={spacing.spaceBelow20}>
                        You’ve chosen to transfer all shares on this CSN into Sharesies.
                    </h2>
                )}

                <div className={styles.extraInfo}>
                    {stagedTransferOrder.direction === 'in' && (
                        <p>There are no transfer fees when transferring NZX-listed shares into Sharesies.</p>
                    )}
                    <p>
                        It can take up to 2 business days to complete your transfer and for your shares to show in your
                        Portfolio.
                    </p>
                    {stagedTransferOrder.direction === 'in' && (
                        <p>
                            Once the transfer is complete, you can adjust the average price you’ve paid for your
                            transferred shares by going to{' '}
                            <strong>Completed transfers &gt; View &gt; Edit price</strong>.
                        </p>
                    )}
                    <p>
                        For more information, {/* IS-2297 TODO update the Helpcentre link for AU customers */}
                        <HelpCentreLink nzArticle="3683578-transfer-shares-processing-times">
                            check out our help centre
                        </HelpCentreLink>
                        .
                    </p>
                </div>
                <ErrorBox message={submitError} />

                <TransferInsufficientFundsModal
                    feeCurrency={feeCurrency}
                    feeValue={feeValue}
                    isOpen={insufficientFundsModalOpen}
                    setIsOpen={setInsufficientFundsModalOpen}
                />
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--confirm-transfer"
                    label="Confirm transfer"
                    onClick={handleSubmit}
                    processing={submitting}
                />
            </ActionBar>
        </>
    )
}

export default TransferSharesConfirm
