import Decimal from 'decimal.js'
import React from 'react'
import {Model} from '~/api/retail/types'
import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import recordStyles from '~/global/widgets/instrument-activity/Record.scss'
import {PaymentRequestFeeRow, RecordRow} from '~/global/widgets/instrument-activity/common'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'

const paymentRequestTypeLabel = (paymentRequest: Model.PaymentRequest): string => {
    if (paymentRequest.reason === 'RECURRING' || paymentRequest.reason === 'ONEOFF') {
        return paymentRequest.description
    } else if (paymentRequest.provider === 'BLINKPAY') {
        return 'Wallet top-up'
    }
    return 'Wallet top-up'
}

const PaymentRequestLineView: React.FunctionComponent<{transaction: Model.Transaction}> = ({transaction}) => {
    const paymentRequest = transaction.payment_request

    if (!paymentRequest) {
        throw new Error('Payment request contract note must have a payment request')
    }

    const amountBeforeFees = new Decimal(paymentRequest.amount).plus(paymentRequest.fee)

    return (
        <>
            <div className={recordStyles.header}>
                <PageBack />
            </div>
            <Page withoutDefaultPadding>
                <div className={recordStyles.viewContainer} data-testid={`payment-request--${transaction.line_number}`}>
                    <div className={recordStyles.titleBlock}>
                        <h2>{paymentRequestTypeLabel(paymentRequest)}</h2>
                    </div>
                    <div className={recordStyles.orderContent}>
                        {/* <p className={recordStyles.donationMessage}>{donation.message}</p> */}
                        <div className={recordStyles.recordRowContainer}>
                            <RecordRow
                                left="Method"
                                right={
                                    paymentRequest.provider === 'BLINKPAY'
                                        ? 'Instant bank top-up'
                                        : paymentRequest.reason === 'PAY_ID'
                                          ? 'PayID'
                                          : 'Linked bank account'
                                }
                            />
                            <RecordRow left="From" right={paymentRequest.from_bank_account} />
                            <RecordRow left="Date" right={transaction.timestamp.toFormat(dateFormatWithYear)} />
                            <RecordRow
                                left="Top-up amount"
                                right={
                                    <DollarValue
                                        value={amountBeforeFees.toString()}
                                        currency={paymentRequest.currency}
                                    />
                                }
                            />

                            {paymentRequest.fee && (
                                <PaymentRequestFeeRow
                                    currency={paymentRequest.currency as Currency}
                                    paymentRequest={paymentRequest}
                                />
                            )}
                        </div>

                        <p className={recordStyles.orderAmountLabel}>Wallet top-up</p>
                        <div className={recordStyles.orderFooter}>
                            <div>
                                {paymentRequest.state === 'processing' && !paymentRequest.has_deposit && (
                                    <div>
                                        <p className={recordStyles.statusLabel} data-testid="orderStatusLabel">
                                            Processing
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div>
                                <p className={recordStyles.orderAmount}>
                                    <DollarValue value={transaction.amount} currency={transaction.currency} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        </>
    )
}

export default PaymentRequestLineView
