import cn from 'classnames'
import React from 'react'
import {Response} from '~/api/retail/types'
import tooltipStyles from '~/global/scss/reused-styles/tooltip.scss'
import {formatNumberWithThousandsSeparator} from '~/global/utils/format-number/formatNumber'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {DollarValue, SharePriceValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import {Tooltip} from '~/global/widgets/tooltip/Tooltip'
import NumberOfSharesBar from './NumberOfSharesBar'
import styles from './OrderTable.scss'

interface Order {
    price: string
    size: string
    num_of_orders: string
}

interface BuyArray {
    array: Response.LiveDataFetch['live_data']['price_depth']['bids']
    highestPrice: string
}

interface SellArray {
    array: Response.LiveDataFetch['live_data']['price_depth']['offers']
    lowestPrice: string
}

interface OrderTableProps {
    orderArray: BuyArray['array'] | SellArray['array']
    type: 'buy' | 'sell'
    matchPrice?: string
    matchVolume?: string
    highestNumberOfShares: number
}

const OrderTable: React.FunctionComponent<OrderTableProps> = ({
    orderArray,
    type,
    matchPrice,
    matchVolume,
    highestNumberOfShares,
}) => {
    const hasMoreThanThreeOrders = orderArray.length > 3
    const [showAll, setShowAll] = React.useState(false)

    const toggleShowAll = React.useCallback(() => {
        setShowAll(!showAll)
    }, [showAll])

    const whereToShowMatchPrice = React.useMemo(() => {
        let orderIndex = 3

        if (!matchPrice || !matchVolume || orderArray.length === 0) {
            return orderIndex
        }

        orderArray.forEach((_, index) => {
            if (index >= orderArray.length - 1) {
                return
            }
            if (type === 'buy' && orderArray[index + 1].price < matchPrice && matchPrice <= orderArray[index].price) {
                orderIndex = index + 1
            }
            if (type === 'sell' && orderArray[index].price <= matchPrice && matchPrice < orderArray[index + 1].price) {
                orderIndex = index + 1
            }
        })

        return orderIndex
    }, [orderArray, matchPrice, matchVolume])

    const getAccumulatedValues = (individualBuySell: Order) => {
        const defaultValues = {
            shares: 0,
            orders: 0,
            amount: 0,
            averagePrice: 0,
        }

        if (orderArray.length === 0) {
            return defaultValues
        }
        const filteredArray = orderArray.filter(buySell =>
            type === 'buy'
                ? parseFloat(buySell.price) >= parseFloat(individualBuySell.price)
                : parseFloat(buySell.price) <= parseFloat(individualBuySell.price),
        )
        return filteredArray.reduce((accumulator, buySell) => {
            const accumulatedShares = accumulator.shares + parseFloat(buySell.size)
            const accumulatedOrders = accumulator.orders + parseFloat(buySell.num_of_orders)
            const accumulatedAmount = accumulator.amount + parseFloat(buySell.price) * parseFloat(buySell.size)

            return {
                shares: accumulatedShares,
                orders: accumulatedOrders,
                amount: accumulatedAmount,
                averagePrice: accumulatedAmount / accumulatedShares,
            }
        }, defaultValues)
    }

    return orderArray.length > 0 ? (
        <div
            className={cn(styles.orderTable, {
                [styles.buyOrderTable]: type === 'buy',
                [styles.buyOrderTableShowAll]: type === 'buy' && (showAll || !hasMoreThanThreeOrders),
                [styles.sellOrderTableShowAll]: type === 'sell' && (showAll || !hasMoreThanThreeOrders),
            })}
        >
            <div>
                {type === 'buy' && (
                    <Tooltip>
                        <h2 className={tooltipStyles.label}>Bids</h2>
                        <div className={tooltipStyles.tooltip}>
                            <p>The highest price buyers are willing to pay and how many shares they want to buy</p>
                        </div>
                    </Tooltip>
                )}
                {type === 'sell' && (
                    <Tooltip>
                        <h2 className={tooltipStyles.label}>Offers</h2>
                        <div className={tooltipStyles.tooltip}>
                            <p>The lowest price sellers are willing to receive and how many shares are up for sale</p>
                        </div>
                    </Tooltip>
                )}
                {hasMoreThanThreeOrders && (
                    <ButtonAsLink onClick={toggleShowAll}>{showAll ? 'Show less' : 'Show all'}</ButtonAsLink>
                )}
            </div>
            <table>
                <thead
                    className={cn({
                        [styles.buyTableHeadShowAll]: type === 'buy' && (showAll || !hasMoreThanThreeOrders),
                        [styles.sellTableHead]: type === 'sell',
                    })}
                >
                    {type === 'sell' && (
                        <tr>
                            <th>Price</th>
                            <th>Shares</th>
                            <th />
                            <th>Orders</th>
                        </tr>
                    )}
                </thead>
                <tbody
                    className={cn({
                        [styles.buyTableBody]: type === 'buy',
                        [styles.sellTableBody]: type === 'sell',
                        [styles.sellTableBodyShowAll]: type === 'sell' && (showAll || !hasMoreThanThreeOrders),
                    })}
                >
                    {orderArray
                        .filter((_, index) => (showAll ? true : index <= whereToShowMatchPrice))
                        .map((order, index) => {
                            const {shares, orders, amount, averagePrice} = getAccumulatedValues(order)

                            return (
                                <React.Fragment key={`${type}-order-${index}`}>
                                    <tr
                                        className={cn({
                                            [styles.firstRow]: index === 0,
                                            [styles.bidsFirstRow]: index === 0 && type === 'buy',
                                            [styles.offersFirstRow]: index === 0 && type === 'sell',
                                        })}
                                    >
                                        <td>
                                            <SharePriceValue value={order.price} />
                                        </td>
                                        <td>
                                            {index !== 0 && (
                                                <Tooltip>
                                                    <div className={tooltipStyles.label}>
                                                        <ShareValue value={order.size} />
                                                    </div>
                                                    <div
                                                        className={cn(
                                                            tooltipStyles.tooltip,
                                                            tooltipStyles.largeContentTooltip,
                                                        )}
                                                    >
                                                        <h2>Shares ahead</h2>
                                                        <div>
                                                            <p>Shares</p>
                                                            <p>
                                                                <ShareValue value={shares} />
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p>{type === 'buy' ? 'Buyers' : 'Sellers'}</p>
                                                            <p>{orders}</p>
                                                        </div>
                                                        <div>
                                                            <p>Amount</p>
                                                            <p>
                                                                <DollarValue value={amount} />
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p>Av. price</p>
                                                            <p>
                                                                <SharePriceValue value={averagePrice} />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            )}
                                            {index === 0 && (
                                                <span>
                                                    <ShareValue value={order.size} />
                                                </span>
                                            )}
                                        </td>

                                        <NumberOfSharesBar
                                            type={type}
                                            order={order}
                                            highestNumberOfShares={highestNumberOfShares}
                                        />

                                        <td>{formatNumberWithThousandsSeparator(order.num_of_orders)}</td>
                                    </tr>
                                    {matchPrice && matchVolume && index === whereToShowMatchPrice - 1 && (
                                        // It's within the match price range
                                        <tr className={styles.matchPrice}>
                                            <td>
                                                <p>
                                                    Match price: <SharePriceValue value={matchPrice} />
                                                </p>
                                                <p>
                                                    Match quantity: <ShareValue value={matchVolume} />
                                                </p>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            )
                        })}
                </tbody>
            </table>
        </div>
    ) : (
        <div
            className={cn(styles.orderTable, {
                [styles.buyOrderTableShowAll]: type === 'buy',
                [styles.buyOrderTableNoOrders]: type === 'buy' && orderArray.length === 0,
                [styles.sellOrderTableShowAll]: type === 'sell',
                [styles.sellOrderTableNoOrders]: type === 'sell' && orderArray.length === 0,
            })}
        >
            <div>
                <h2>{type === 'buy' ? 'Bids' : 'Offers'}</h2>
            </div>
            <table>
                <thead
                    className={cn({
                        [styles.buyTableHeadShowAll]: type === 'buy',
                        [styles.sellTableHead]: type === 'sell',
                    })}
                >
                    {type === 'buy' ? (
                        <tr />
                    ) : (
                        <tr>
                            <th>Price</th>
                            <th>Shares</th>
                            <th />
                            <th>Orders</th>
                        </tr>
                    )}
                </thead>
            </table>
            <p>{type === 'buy' ? 'No buyers right now' : 'No sellers right now'}</p>
        </div>
    )
}

export default OrderTable
