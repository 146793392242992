import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Image from '~/global/widgets/image/Image'
import blinkpayLogoDark from '~/sections/OLD_wallet/sections/top-up/sections/top-up-instant/assets/public_blinkpay/blinkpay_logo_darkmode.png'
import blinkpayLogoLight from '~/sections/OLD_wallet/sections/top-up/sections/top-up-instant/assets/public_blinkpay/blinkpay_logo_lightmode.png'
import {
    AsbLogo,
    BnzLogo,
    WestpacLogo,
} from '~/sections/OLD_wallet/sections/top-up/sections/top-up-instant/widgets/bank-logos/Logos'
import CantSeeBank from '~/sections/OLD_wallet/sections/top-up/sections/top-up-instant/widgets/cant-see-bank/CantSeeBank'
import actions from '~/store/bankLinking/actions'
import {useAppSelector, useAppDispatch} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import styles from './Intro.scss'

interface IntroProps {
    setStep: (step: 'intro' | 'form') => void
}

const Intro: React.FC<IntroProps> = React.memo(({setStep}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const blinkPayEnabled = useAppSelector(s => 'blinkpay_enabled' in s.identity.flags)

    const dispatch = useAppDispatch()

    const options = useAppSelector(s => s.bankLinking.blinkPay.options)

    React.useEffect(() => {
        if (!blinkPayEnabled) {
            navigate(profileUrl('wallet'), {})
            return
        }

        dispatch(actions.GetBlinkPayOptions())
        dispatch(identityActions.MarkHasSeenFlag('blinkpay'))
    }, [])

    const feeAmount = options?.fee_amount

    return (
        <>
            <h1 className={spacing.spaceBelow12}>Instant bank transfer</h1>
            <p className={spacing.spaceBelow24}>
                Enabled by{' '}
                <Image
                    className={styles.blinkPayLogo}
                    src={blinkpayLogoLight} // files in this folder are also shipped to external apps via build config
                    darkSrc={blinkpayLogoDark}
                    alt="BlinkPay logo"
                />
            </p>
            <p className={spacing.spaceBelow24}>
                We’ve teamed up with BlinkPay to let you instantly and securely transfer from your bank account.{' '}
                <a
                    href="https://intercom.help/sharesies/en/articles/8393032-about-blinkpay"
                    target="_blank"
                    rel="noopener"
                >
                    Learn more
                </a>
            </p>
            <h2>Easy and instant</h2>
            <p className={spacing.spaceBelow24}>
                No need to enter reference codes. And your money will appear in your Wallet instantly.
            </p>
            <h2>Safe and secure</h2>
            <p className={spacing.spaceBelow24}>
                Each top-up is a one-off—we don’t save your data or share it with third parties.
            </p>
            {feeAmount && (
                <>
                    <h2>One flat fee</h2>
                    <p className={spacing.spaceBelow24}>
                        For each transfer, there’s a flat ${feeAmount} NZD transfer fee. Your bank’s transfer limits
                        will apply.
                    </p>
                </>
            )}
            <hr />
            <p className={cn(spacing.spaceAbove16, styles.textPrimary)}>
                Currently, we support instant transfers from the following banks:
            </p>
            <ul className={styles.introBanksList}>
                {options?.available_banks.map(bank => {
                    return (
                        <li key={bank.name}>
                            <span>
                                {bank.name === 'ASB' && <AsbLogo />}
                                {bank.name === 'BNZ' && <BnzLogo />}
                                {bank.name === 'Westpac' && <WestpacLogo />}
                            </span>
                            {bank.name}
                        </li>
                    )
                })}
            </ul>

            <CantSeeBank />

            <Button
                dataTestId="button--continue"
                label="Continue"
                onClick={() => setStep('form')}
                pageButton
                type="primary"
            />
        </>
    )
})

export default Intro
