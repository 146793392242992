import {Button} from '@design-system/button'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {isUSExchange} from '~/global/utils/share-transfers/shareTransfers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useTransferInfo} from '~/global/utils/use-transfer-info/useTransferInfo'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {Radio} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/transfer/actions'
import {StagedTransferOrder} from '~/store/transfer/types'

export const TransferDirection: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const transferInfo = useTransferInfo()
    const stagedTransferOrder = useAppSelector(({transfer}) => transfer.stagedTransferOrder)

    const [choice, setChoice] = React.useState<'in' | 'out' | undefined>(stagedTransferOrder?.direction || undefined)

    const setupStagedTransferOrder = (exchange?: StagedTransferOrder['exchange']) => {
        if (!choice) {
            return
        }
        const direction = choice

        // Create a new stagedTransferOrder
        dispatch(
            actions.SetStagedTransferOrder({
                direction,
                exchange,
                reference: '',
                instruments: [],
                transferOrderDocuments: [],
                registryDetailDocuments: [],
            }),
        )
    }

    const nextPage = () => {
        if (!choice) {
            return
        }
        const direction = choice

        if (stagedTransferOrder?.registryDetailId) {
            let exchange = stagedTransferOrder.exchange

            if (stagedTransferOrder.referenceType === 'CSN') {
                exchange = 'NZX'
            }

            if (['HIN', 'SRN'].includes(stagedTransferOrder.referenceType!)) {
                exchange = 'ASX'
            }

            if (['BAN', 'DRS'].includes(stagedTransferOrder.referenceType!)) {
                exchange = 'US_MARKETS'
            }

            dispatch(
                actions.SetStagedTransferOrder({
                    ...stagedTransferOrder,
                    direction,
                    exchange,
                }),
            )

            // Skip exchange selection
            if (exchange === 'NZX') {
                navigate(profileUrl('invest/portfolio-transfer-shares/nzx/terms-and-conditions'))
            }
            if (exchange === 'ASX') {
                navigate(profileUrl('invest/portfolio-transfer-shares/asx/intro'))
            }
            if (isUSExchange(exchange)) {
                navigate(profileUrl('invest/portfolio-transfer-shares/us/intro'))
            }
            return
        }

        setupStagedTransferOrder()
        navigate(profileUrl('invest/portfolio-transfer-shares/select-exchange'))
    }

    if (!transferInfo) {
        return (
            <>
                <Toolbar dataTestId="toolbar--transfer-direction" leftButton="back" title="Transfer shares" />
                <Page overrideDefaultTopPadding="withToolbarTitle">
                    <Loading />
                </Page>
            </>
        )
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--transfer-direction" leftButton="back" title="Transfer shares" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <p>Transfer NZ, US, and Australian shares you own into or out of Sharesies.</p>
                <form>
                    <Radio
                        dataTestId="radio--transfer-direction"
                        additionalClassName={spacing.spaceBelow16}
                        name="direction"
                        label=""
                        isTouched
                        choices={[
                            {
                                label: 'Transfer in',
                                helpText: 'Transfer shares you hold elsewhere into Sharesies',
                                value: 'in',
                            },
                            {
                                label: 'Transfer out',
                                helpText: 'Transfer shares you hold out of Sharesies',
                                value: 'out',
                            },
                        ]}
                        onChange={e => {
                            setChoice(e.target.value as 'in' | 'out')
                        }}
                        value={choice}
                    />
                    {choice && choice === 'out' && (
                        <p>
                            You can only transfer out whole shares. Any fractions of a share will remain in your
                            Portfolio.
                        </p>
                    )}
                </form>
            </Page>
            <ActionBar>
                <Button
                    label="Next"
                    dataTestId="button--next"
                    type="primary"
                    onClick={nextPage}
                    disabled={choice === undefined}
                />
            </ActionBar>
        </>
    )
}

export default TransferDirection
