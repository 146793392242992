import cn from 'classnames'
import React from 'react'
import {useCoveGet} from '~/api/query/cove'
import {spacing} from '~/global/scss/helpers'
import {
    findExcess,
    vehicleDisplayName,
    policyFrequency,
} from '~/sections/protect/sections/insure/sections/landing/utils/car/car'
import {Quote} from '~/sections/protect/sections/insure/sections/landing/widgets/quote/Quote'

export const Quotes: React.FunctionComponent = () => {
    const quoteData = useCoveGet('quote/v1/queryByCustomer')

    // 'Pending' quotes are future-dated policies - we don't want to show these here.
    // Regular quotes have a status of 'Incomplete'
    const quotes = quoteData.quotes.filter(quote => quote.quote_status !== 'Pending')

    if (!quotes.length) {
        return null
    }

    return (
        <>
            <h2 className={cn(spacing.spaceAbove32, spacing.spaceBelow16)}>Quotes</h2>

            {quotes.map(quote => {
                const coverageDetails = quote.coverages[0]
                // Premium cost - use the 'premium' value vs 'prorated_premium'
                // Prorated is an adjusted value for a specific period of time
                const premiumCost = coverageDetails.premium.toString()

                return (
                    <React.Fragment key={`quote-${quote.quote_id}`}>
                        <Quote
                            carValue={coverageDetails.risk.sum_insured.current_value.toString()}
                            code={quote.quote_id}
                            coverage={coverageDetails.risk.coverage_type}
                            excess={findExcess(coverageDetails.excesses)}
                            name={vehicleDisplayName(coverageDetails.risk)}
                            premiumCost={premiumCost}
                            premiumFrequency={policyFrequency(quote.terms_duration)}
                            validUntilDate={new Date(quote.expiry_date)}
                        />
                        <br />
                    </React.Fragment>
                )
            })}
        </>
    )
}
