import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/invest/sections/bank-linking/BankLinking.scss'
import roundupsAreReady from '~/sections/invest/sections/bank-linking/assets/images/roundups-are-ready.svg'
import actions from '~/store/bankLinking/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'

interface RoundupsReadyProps {
    onBack: () => void
    onNext: () => void
    onNextWholeDollar: () => void
}

const RoundupsReady = ({onBack, onNext, onNextWholeDollar}: RoundupsReadyProps) => {
    const dispatch = useAppDispatch()
    const {depositTarget, isRoundupsEnabled} = useAppSelector(({bankLinking}) => ({
        depositTarget: bankLinking.depositTarget,
        isRoundupsEnabled: bankLinking.isRoundupsEnabled,
    }))

    React.useEffect(() => {
        if (!isRoundupsEnabled) {
            dispatch(actions.UpdateRoundupsEnabled(true, undefined))
        }
    })
    return (
        <>
            <Toolbar dataTestId="toolbar--roundups-tracking" leftButton="back" onLeftButtonClick={onBack} />
            <Page className={styles.roundupsReadyWrapper}>
                <div className={styles.roundupsReadyImage}>
                    <img role="presentation" src={roundupsAreReady} alt="roundups are ready" />
                </div>
                <div>
                    <h1 className={cn(styles.h1, spacing.spaceBelow20)}>Your classic round-ups are ready!</h1>
                    <p className={spacing.spaceBelow16}>
                        As soon as you reach your ${depositTarget} round-up goal, your round-ups will be sent
                        automatically to your Wallet in one lump sum (this can take up to 4 business days).
                    </p>
                    <p className={spacing.spaceBelow16}>
                        To see how close you are to your next automatic top-up, check out the round-ups tally in your
                        Wallet.
                    </p>
                    <h2 className={spacing.spaceBelow8}>Reach your goals faster</h2>
                    <p>Add on whole dollar round-ups to add $1 to your tally for every whole dollar transaction.</p>
                </div>
            </Page>
            <ActionBar className={styles.stackedButtonGroup}>
                <Button
                    additionalClassName={spacing.spaceBelow12}
                    type="primary"
                    dataTestId="button--whole-dollar-roundups"
                    label="More on whole dollar round-ups"
                    onClick={onNextWholeDollar}
                />
                <Button
                    type="secondary"
                    dataTestId="button--next"
                    label="Keep round-ups classic for now"
                    onClick={onNext}
                />
            </ActionBar>
        </>
    )
}

export default RoundupsReady
