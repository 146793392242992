import {Modal} from '@design-system/modal'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {Link} from 'react-router-dom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {Context as LocationContext} from '~/global/state-hooks/retail/useBankLinkingAlert'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {isLoadingOrUninitialised, isUninitialised} from '~/global/utils/is-loading/isLoading'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {SwitchField} from '~/global/widgets/form-controls'
import {ListMenuGroup, ListMenuItem} from '~/global/widgets/list-menu/ListMenu'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {RoundupStatus} from '~/sections/OLD_wallet/widgets/roundup-status/RoundupStatus'
import {
    BASIQ_DDRSA_URL,
    MOONOVA_DDRSA_ROUTE,
    MOONOOVA_DDRSA_VERSION,
} from '~/sections/invest/sections/bank-linking/constants/ddrsa'
import actions from '~/store/bankLinking/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {useBankLinkingReferrer} from './hooks/useBankLinkingRererrer'
import {BankLinkingEdit} from './pages/edit/BankLinkingEdit'
import {BankLinkingView} from './pages/view/BankLinkingView'
import {
    ChooseDepositTarget,
    ChooseWholeDollarRoundups,
    RoundupsHowTo,
    SelectMonitoringBankAccounts,
} from './sections/roundups-setup/pages'
import BankLinkingRejected from './sections/roundups-setup/pages/BankLinkingRejected'
import {RoutingStateMachine} from './widgets/bank-linking-routing-state/RoutingStateMachine'
import style from './BankLinking.scss'

// Handle logic for roundups manage screen on routes:
// - 'explore/roundups/manage'
// - 'explore/roundups/manage/:step'
// - 'wallet/roundups/manage'
// - 'wallet/roundups/manage/:step'
export const RoundupsHomeStateMachine = ({
    step: stepParam,
    homeLocation,
}: {
    step?: string
    homeLocation?: LocationContext
}): JSX.Element => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const loadingState = useAppSelector(({bankLinking}) => bankLinking.bankLinkingLoadingState)
    const basiqAccountsLoadingState = useAppSelector(({bankLinking}) => bankLinking.basiqAccountsLoadingState)
    const referrer = useBankLinkingReferrer() as 'wallet' | 'explore'

    React.useEffect(() => {
        if (isUninitialised(loadingState)) {
            dispatch(actions.FetchBankLinkingStatus())
        }
        if (isUninitialised(basiqAccountsLoadingState)) {
            // Prefetch basiq accounts
            dispatch(actions.FetchBasiqAccounts())
        }
    }, [])

    const exitRoundups = () => {
        navigate(-1)
    }

    const step = stepParam || 'home'

    if (isLoadingOrUninitialised(loadingState)) {
        return (
            <>
                <Toolbar leftButton="back" dataTestId="toolbar--banklinking-loading" />
                <Page>
                    <Loading />
                </Page>
            </>
        )
    }

    return (
        <RoutingStateMachine
            currentStep={step as any}
            steps={[
                'home',
                'monitoring',
                'debiting',
                'edit-debiting',
                'whole-dollar',
                'deposit-target',
                'howto',
                'rejected',
            ]}
            getUrlForStep={newStep => {
                if (newStep === 'home') {
                    return profileUrl(`${referrer}/roundups/manage`)
                }
                return profileUrl(`${referrer}/roundups/manage/:step`, {step: newStep})
            }}
            entrypoint={() => 'home'}
            trackStepChange={(prevStep, nextStep) => {
                if (nextStep !== 'home' || !prevStep) {
                    return
                }
                if (prevStep === 'monitoring') {
                    rudderTrack('round-up', 'round-up-managed', {
                        navigated_from: referrer === 'wallet' ? 'wallet-tally' : 'invest-tile',
                        'selection-managed': 'account-tracked',
                    })
                } else if (prevStep === 'debiting') {
                    rudderTrack('round-up', 'round-up-managed', {
                        navigated_from: referrer === 'wallet' ? 'wallet-tally' : 'invest-tile',
                        'selection-managed': 'account-send-from',
                    })
                } else if (prevStep === 'whole-dollar') {
                    rudderTrack('round-up', 'round-up-managed', {
                        navigated_from: referrer === 'wallet' ? 'wallet-tally' : 'invest-tile',
                        'selection-managed': 'whole-dollar',
                    })
                }
            }}
        >
            {(step, setStep) => {
                const back = () => {
                    navigate(-1)
                }
                switch (step) {
                    case 'home':
                        return (
                            <RoundupsHome
                                homeLocation={homeLocation || 'explore'} // default to explore if not set
                                onBack={exitRoundups}
                                navigateToPage={nextPage => setStep(nextPage)}
                            />
                        )
                    case 'monitoring':
                        return <SelectMonitoringBankAccounts onBack={back} />
                    case 'debiting':
                        return (
                            <BankLinkingView
                                onBack={back}
                                onEditDebitAccount={() => setStep('edit-debiting')}
                                onUnlink={() => navigate(profileUrl('explore'))}
                            />
                        )
                    case 'edit-debiting':
                        return <BankLinkingEdit onBack={back} />
                    case 'whole-dollar':
                        return (
                            <ChooseWholeDollarRoundups
                                onBack={back}
                                onChangeTransferAmount={() => setStep('deposit-target')}
                            />
                        )
                    case 'deposit-target':
                        return <ChooseDepositTarget onBack={back} isInSetupFlow={false} fromWholeDollar={false} />
                    case 'howto':
                        return <RoundupsHowTo onBack={back} onComplete={back} onCompleteLabel="Got it" />
                    case 'rejected':
                        return <BankLinkingRejected onBack={back} />

                    default:
                        assertNever(step)
                }
                return <></>
            }}
        </RoutingStateMachine>
    )
}

export interface OnNextProps {
    onBack: () => void
}

const DeactivationModalContent = (
    <>
        <p>
            Turning off round-ups will reset your tally but will retain your bank account details, should you want to
            turn it on again.
        </p>
        <p>If you already have a round-up transfer on it’s way, this will still be processed.</p>
        <p>
            This will cancel your Direct Debit Request instructions and we will notify our service providers of these
            changes. It may take up to 5 business days for your request to take affect.
        </p>
    </>
)

type NextPage = 'monitoring' | 'debiting' | 'whole-dollar' | 'deposit-target' | 'howto'
const RoundupsHome = ({
    onBack,
    navigateToPage,
    homeLocation,
}: OnNextProps & {navigateToPage: (nextPage: NextPage) => void; homeLocation: LocationContext}) => {
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const isRoundupsEnabled = useAppSelector(({bankLinking}) => bankLinking.isRoundupsEnabled!)
    const optionWholeDollar = useAppSelector(({bankLinking}) => bankLinking.optionWholeDollar)
    const optionDepositTarget = useAppSelector(({bankLinking}) => bankLinking.depositTarget)
    const isBankLinkingRejected = useAppSelector(({bankLinking}) => bankLinking.isBankLinkingRejected)
    const hasMonoovaFlag = useAppSelector(s => s.identity.flags.monoova)

    const [roundupsToggleLoading, setRoundupsToggleLoading] = React.useState(false)
    const [showDeactivateModal, setShowDeactivateModal] = React.useState(false)

    const updateRoundups = async (enabled: boolean) => {
        if (roundupsToggleLoading) {
            return
        }
        setRoundupsToggleLoading(true)
        if (hasMonoovaFlag) {
            await dispatch(actions.UpdateRoundupsEnabled(enabled, enabled ? MOONOOVA_DDRSA_VERSION : undefined))
        } else {
            await dispatch(actions.UpdateRoundupsEnabled(enabled, enabled ? BASIQ_DDRSA_URL : undefined))
        }
        setRoundupsToggleLoading(false)
    }

    return (
        <>
            <Toolbar leftButton="back" onLeftButtonClick={onBack} dataTestId="toolbar--roundups" />

            <Modal
                content={DeactivationModalContent}
                dataTestId="modal--deactivate-roundups"
                isOpen={showDeactivateModal}
                setIsOpen={setShowDeactivateModal}
                title="Turning off round-ups"
                primaryButton={{label: 'Turn off round-ups', onClick: () => updateRoundups(false)}}
                secondaryButton={{label: 'Cancel'}}
            />
            <Page>
                <h1 className={style.h1}>Round-ups</h1>
                <RoundupStatus locationContext={homeLocation} />
                <h1 className={cn(spacing.spaceAbove16, spacing.spaceBelow16)}>Manage round-ups</h1>
                <ListMenuGroup>
                    <SwitchField
                        additionalClassName={style.enableRoundupsListMenu}
                        dataTestId="switch--toggle-roundups"
                        name="toggle-roundups"
                        onChange={() => {
                            if (isRoundupsEnabled) {
                                setShowDeactivateModal(true)
                            } else {
                                updateRoundups(true)
                            }
                        }}
                        value={isRoundupsEnabled}
                        disabled={isBankLinkingRejected || roundupsToggleLoading}
                        isTouched={false}
                        label="Round up my spending"
                    />
                    {isRoundupsEnabled && (
                        <>
                            <ListMenuItem label="Accounts to track" onClick={() => navigateToPage('monitoring')} />
                            <ListMenuItem
                                label="Account to send money from"
                                onClick={() => navigateToPage('debiting')}
                            />
                            <ListMenuItem
                                label="Whole dollar round-ups"
                                rightLabel={optionWholeDollar ? 'On' : 'Off'}
                                onClick={() => navigateToPage('whole-dollar')}
                            />
                            <ListMenuItem
                                label="Round-ups transfer amount"
                                rightLabel={<DollarValue value={optionDepositTarget} decimalPlaces={0} />}
                                onClick={() => navigateToPage('deposit-target')}
                            />
                        </>
                    )}
                </ListMenuGroup>
                <p className={cn(spacing.spaceAbove24, spacing.spaceBelow16)}>
                    <ButtonAsLink onClick={() => navigateToPage('howto')}>How round-ups work</ButtonAsLink>
                </p>
                <p>
                    Round-ups will be processed in accordance with our{' '}
                    {hasMonoovaFlag ? (
                        <Link to={profileUrl(MOONOVA_DDRSA_ROUTE)}>Direct Debit Request Service Agreement</Link>
                    ) : (
                        <a target="_blank" rel="noreferrer" href={BASIQ_DDRSA_URL}>
                            Direct Debit Request Service Agreement
                        </a>
                    )}
                </p>
            </Page>
        </>
    )
}

export default RoundupsHomeStateMachine
