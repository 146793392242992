import React from 'react'

interface OwnProps {
    condition: boolean
    onlyPreventMobile?: boolean
}

const mobileScreenWidthRange = 768 // if we are specifically preventing scrolling for mobile, we want to prevent only for screens with widths SMALLER than this value.

// Component for preventing the ENTIRE page from being scrollable by the user
const PreventScroll: React.FunctionComponent<OwnProps> = ({condition, onlyPreventMobile}) => {
    React.useEffect(() => {
        const bodyTag = document.querySelector('body')
        if (bodyTag && condition && (!onlyPreventMobile || window.innerWidth < mobileScreenWidthRange)) {
            bodyTag.style.overflow = 'hidden'
            bodyTag.style.height = '100vh'
            bodyTag.style.overscrollBehaviorY = 'none'
        }

        return () => {
            if (bodyTag && condition && (!onlyPreventMobile || window.innerWidth < mobileScreenWidthRange)) {
                bodyTag.style.overflow = ''
                bodyTag.style.height = ''
                bodyTag.style.overscrollBehaviorY = ''
            }
        }
    })

    return null
}

export default PreventScroll
