import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {dateFormatNoYear} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AlertCard, {AlertCardProps} from '~/global/widgets/alert-card/AlertCard'
import {useBankAccounts} from '~/sections/wallet/sections/withdrawals/state/use-bank-accounts/useBankAccounts'
import {useCheckIfWithdrawalRestrictedToSource} from '~/sections/wallet/sections/withdrawals/state/useCheckIfWithdrawalRestrictedToSource'
import {useLegacyUserData} from '~/sections/wallet/state/retail'

export const IdentityRequiredAlert = () => {
    const profileUrl = useProfileUrl()
    const {jurisdiction} = useLegacyUserData()
    const {withdrawalRestrictedToSource, restrictAccountAfter} = useCheckIfWithdrawalRestrictedToSource()
    const accounts = useBankAccounts()

    if (!withdrawalRestrictedToSource || !restrictAccountAfter) {
        return null
    }

    const restrictAccountAfterString = restrictAccountAfter.toFormat(dateFormatNoYear)
    const links: AlertCardProps['links'] = [{text: 'Verify your identity', url: profileUrl('verify-your-identity')}]

    let identityText: string
    if (jurisdiction === 'au') {
        identityText =
            'Until we verify your identity you can only withdraw to the last bank account you deposited from.'
    } else {
        switch (accounts.length) {
            case 0:
                identityText = `After ${restrictAccountAfterString}, you’ll need to verify your identity before you can buy, sell, top up, or withdraw.`
                break
            case 1:
                identityText =
                    'Until we verify your identity you can only withdraw to the last bank account you deposited from.'
                break
            default:
                identityText =
                    'Until we verify your identity you can only withdraw to bank accounts you’ve deposited from.'
                break
        }
    }

    return (
        <AlertCard
            title={`Verify your identity by ${restrictAccountAfterString}`}
            className={spacing.spaceBelow16}
            text={identityText!}
            links={links}
            type="warning"
        />
    )
}
