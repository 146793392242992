import cn from 'classnames'
import React from 'react'
import {useRetailGet} from '~/api/query/retail'
import NotFound from '~/global/pages/not-found/NotFound'
import {spacing} from '~/global/scss/helpers'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {COMPUTERSHARE_HREF} from '~/sections/moose/constants/constants'
import {settingsScreenVersion} from '~/sections/user/sections/settings/Settings'
import NatureAndPurpose from '~/sections/user/sections/settings/widgets/nature-and-purpose/NatureAndPurpose'
import PrescribedPerson from '~/sections/user/sections/settings/widgets/prescribed-person/PrescribedPerson'
import styles from './BusinessDetails.scss'

const BusinessDetails = () => {
    const owner = useProfileOwner()
    const {address} = useRetailGet({
        path: 'owner/:owner_id/address',
        pathParams: {owner_id: owner.id},
    }).data

    return (
        <>
            <Toolbar dataTestId="toolbar--business-details" leftButton="back" title="Business details" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <div>
                    <h2>Business name</h2>
                    <p>{owner.display_name}</p>
                </div>

                <div className={spacing.spaceAbove32}>
                    <h2>Address</h2>
                    <p className={styles.address}>{address.formatted}</p>
                </div>

                {owner.is_fonterra_customer && (
                    <AlertCard type="info" title="Incorrect address?" className={spacing.spaceAbove16}>
                        <p>
                            This address is managed for Fonterra by Computershare. If it’s incorrect, you’ll need to{' '}
                            <a href={COMPUTERSHARE_HREF} target="_blank" rel="noreferrer">
                                update your address on Computershare
                            </a>
                            .
                        </p>
                    </AlertCard>
                )}

                <h1 className={spacing.spaceAbove64}>Investing details</h1>
                <PrescribedPerson
                    owner={owner}
                    edit_url="settings/business-details/prescribed-person"
                    section_class={cn(styles.titleWithEdit, spacing.spaceAbove32)}
                />
                <NatureAndPurpose
                    owner={owner}
                    edit_url="settings/business-details/how-you-invest"
                    section_class={cn(styles.titleWithEdit, spacing.spaceAbove32)}
                />
            </Page>
        </>
    )
}

const BusinessDetailsWrapper = () => {
    const profile = useProfile()
    return settingsScreenVersion(profile) === 'business' ? <BusinessDetails /> : <NotFound />
}

export default BusinessDetailsWrapper
