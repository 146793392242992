import {assertNever} from '~/global/utils/assert-never/assertNever'
import {ActionsType} from './actions'
import {State, isDIY, DIYOrderDetails, PartialDIYOrderDetails} from './types'

const initialState: State = {
    autoinvestOrders: {},
    autoinvestLoadingState: 'uninitialised',
    premadeOrdersState: 'uninitialised',
    premadeOrders: [],
    diyAuEtfOptions: [],
}

const mutateDIYOrder = (
    state: State,
    mutator: <T extends DIYOrderDetails | PartialDIYOrderDetails>(order: T) => T,
): State => {
    if (!state.stagedOrder) {
        throw new Error("Can't mutate DIY order when no staged order is present")
    }
    if (state.stagedOrder.state !== 'building' && state.stagedOrder.state !== 'staged') {
        throw new Error('Must be building or staged order to be able to mutate DIY orders')
    }

    if (state.stagedOrder.state === 'building') {
        const order = state.stagedOrder.order
        if (!isDIY(order)) {
            throw new Error('Must be DIY order to add funds')
        }
        return {
            ...state,
            stagedOrder: {
                ...state.stagedOrder,
                order: mutator(order),
            },
        }
    }
    // This is an exact duplicate of the above return statement, it's here
    // because Typescript can't figure out although stagedOrder.state can be two
    // values and stagedOrder.order can also be two values, that there's only 2
    // combinations they can be in (not 4)
    const order = state.stagedOrder.order
    if (!isDIY(order)) {
        throw new Error('Must be DIY order to add funds')
    }
    return {
        ...state,
        stagedOrder: {
            ...state.stagedOrder,
            order: mutator(order),
        },
    }
}

function reducer(state: State = initialState, action: ActionsType): State {
    switch (action.type) {
        case 'autoinvest.SetAutoinvestOrder':
            return {
                ...state,
                autoinvestOrders: {
                    ...state.autoinvestOrders,
                    [action.payload.id]: action.payload,
                },
            }
        case 'autoinvest.RemoveAutoinvestOrder': {
            const {[action.payload]: _, ...newAutoinvestOrders} = state.autoinvestOrders

            return {
                ...state,
                autoinvestOrders: newAutoinvestOrders,
            }
        }
        case 'autoinvest.SetLoadingState': {
            return {
                ...state,
                autoinvestLoadingState: action.payload,
            }
        }
        case 'autoinvest.ClearSelectedDiyOrderId':
            return {
                ...state,
                selectedDIYOrderId: undefined,
            }
        case 'autoinvest.ClearState': {
            return initialState
        }
        case 'autoinvest.ClearStagedOrder': {
            return {
                ...state,
                stagedOrder: undefined,
            }
        }
        case 'autoinvest.ClearStagedOrderAllocationPercentages':
            return mutateDIYOrder(state, order => ({
                ...order,
                allocations: order.allocations.map(a => {
                    return {
                        ...a,
                        allocation: '0',
                    }
                }),
            }))
        case 'autoinvest.RemoveDIYFund':
            return mutateDIYOrder(state, order => ({
                ...order,
                allocations: order.allocations.filter(f => action.payload !== f.fund_id),
            }))
        case 'autoinvest.ResetDIYFunds':
            return mutateDIYOrder(state, order => ({
                ...order,
                allocations: [],
            }))
        case 'autoinvest.SetAutoInvestOptions':
            return {
                ...state,
                premadeOrdersState: 'ready',
                premadeOrders: action.payload.premadeOrders,
                diyAuEtfOptions: action.payload.diyAuOrders,
            }
        case 'autoinvest.SetSelectedDiyOrderId':
            return {
                ...state,
                selectedDIYOrderId: action.payload,
            }
        case 'autoinvest.SetDIYAllocations':
            return mutateDIYOrder(state, order => ({
                ...order,
                allocations: action.payload,
            }))
        case 'autoinvest.SetDIYFund':
            return mutateDIYOrder(state, order => ({
                ...order,
                allocations: order.allocations
                    .filter(f => action.payload.id !== f.fund_id) // get rid of the existing entry in case we're updating not adding
                    .concat({fund_id: action.payload.id, allocation: action.payload.allocation}),
            }))
        case 'autoinvest.SetHowMuch':
            if (!state.stagedOrder) {
                throw new Error("Can't set how much when no staged order is present")
            }
            return {
                ...state,
                stagedOrder: {
                    ...state.stagedOrder,
                    state: 'staged',
                    order: {
                        ...state.stagedOrder.order,
                        amount: action.payload.amount,
                        interval: action.payload.interval,
                        startDate: action.payload.startDate,
                    },
                },
            }
        case 'autoinvest.SetLoadingPremadeOrders':
            return {...state, premadeOrdersState: 'loading'}
        case 'autoinvest.SetUninitialisedPremadeOrders':
            return {...state, premadeOrdersState: 'uninitialised'}
        case 'autoinvest.SetNeedReadPDSes':
            if (!state.stagedOrder) {
                throw new Error("Can't set PDSes on stagedOrder: no stagedOrder present")
            }

            return {
                ...state,
                stagedOrder: {
                    ...state.stagedOrder,
                    pdsesToRead: action.payload.pdses,
                },
            }
        case 'autoinvest.SetOrderCost': {
            if (!state.stagedOrder) {
                throw new Error("Can't update transactionFee on stagedOrder: no stagedOrder present")
            }

            if (state.stagedOrder.state !== 'staged') {
                throw new Error("Can't update transactionFee on stagedOrder: order is not ready")
            }

            return {
                ...state,
                stagedOrder: {
                    ...state.stagedOrder,
                    transactionFee: action.payload.transaction_fee,
                    amountToInvest: action.payload.amount_to_invest,
                },
            }
        }
        case 'autoinvest.SetOrderImage': {
            if (!state.stagedOrder) {
                throw new Error("Can't set image ID when no staged order is present")
            }
            return {
                ...state,
                stagedOrder: {
                    ...state.stagedOrder,
                    orderImageId: action.payload.imageId,
                },
            }
        }
        case 'autoinvest.SetOrderName': {
            if (!state.stagedOrder) {
                throw new Error("Can't set name when no staged order is present")
            }
            return {
                ...state,
                stagedOrder: {
                    ...state.stagedOrder,
                    orderName: action.payload.orderName,
                },
            }
        }
        case 'autoinvest.SetStagedState':
            if (!state.stagedOrder) {
                throw new Error("Can't set staged order state when no staged order is present")
            }
            if (state.stagedOrder.state === 'building') {
                throw new Error("Can't set staged order state when state is building")
            }
            return {
                ...state,
                stagedOrder: {
                    ...state.stagedOrder,
                    state: action.payload,
                },
            }
        case 'autoinvest.StageExistingOrder': {
            const order = action.payload // note this is the 'enhanced' version with extra data

            // strip out delisted instruments
            order.allocations = order.allocations.filter(allocation => allocation.instrumentName !== undefined)

            return {
                ...state,
                stagedOrder: {
                    state: 'staged',
                    isEdit: true,
                    orderName: action.payload.order_name,
                    orderImageId: action.payload.order_image_id,
                    existingOrderId: action.payload.id,
                    order:
                        'premadeOrder' in order
                            ? {
                                  premadeOrder: order.premadeOrder,
                                  amount: order.amount,
                                  interval: order.interval,
                                  startDate: order.next_date,
                              }
                            : {
                                  allocations: order.allocations.map(({allocation, fund_id}) => ({
                                      // strip out enhanced info
                                      allocation,
                                      fund_id,
                                  })),
                                  amount: order.amount,
                                  interval: order.interval,
                                  startDate: order.next_date,
                              },
                },
            }
        }
        case 'autoinvest.StageNewDIY':
            return {
                ...state,
                stagedOrder: {
                    state: 'building',
                    isEdit: false,
                    order: {
                        allocations: [],
                    },
                },
            }
        case 'autoinvest.StageNewPremade':
            return {
                ...state,
                stagedOrder: {
                    existingOrderId: action.payload.existingPremadeOrderId,
                    isEdit: false,
                    order: {
                        premadeOrder: action.payload.premadeOrder,
                    },
                    state: 'building',
                },
            }

        case 'autoinvest.UpdatePDSesToRead': {
            if (!state.stagedOrder || !state.stagedOrder.pdsesToRead) {
                throw new Error("Can't update PDSes on stagedOrder: no stagedOrder present")
            }

            return {
                ...state,
                stagedOrder: {
                    ...state.stagedOrder,
                    pdsesToRead: state.stagedOrder.pdsesToRead.filter(
                        pds => pds.pds_data.pds_file_revision_id !== action.payload.pdsFundRevisionId,
                    ),
                },
            }
        }
        default:
            assertNever(action)
    }
    return state
}

export default reducer
