import React from 'react'
import {formatNumber} from '~/global/utils/format-number/formatNumber'
import {Row} from '~/sections/protect/sections/insure/sections/landing/widgets/row/Row'

export const PolicyQuoteCommonRows: React.FunctionComponent<{
    carValue: string
    coverage?: string
    excess: string | undefined
}> = ({carValue, coverage, excess}) => {
    return (
        <>
            {excess && <Row label="Excess" value={`$${formatNumber({number: excess, decimalPlaces: 0})}`} />}

            {coverage && <Row label="Coverage" value={coverage} />}

            <Row label="Car value" value={`$${formatNumber({number: carValue, decimalPlaces: 0})}`} />
        </>
    )
}
