import {Button} from '@design-system/button'
import cn from 'classnames'
import {Field, withFormik} from 'formik'
import React from 'react'
import {Request} from '~/api/retail/types'
import {urlFor} from '~/global/routeGenerator'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {useProfile, useProfiles} from '~/global/state-hooks/retail/useProfile'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {isLoadingOrUninitialised, isUninitialised} from '~/global/utils/is-loading/isLoading'
import {linkWithMailToIfNeeded} from '~/global/utils/link/link'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import {PageButtonGroup} from '~/global/widgets/button-page-group/PageButtonGroup'
import {ErrorBox, validate} from '~/global/widgets/form-controls'
import {Radio} from '~/global/widgets/form-controls/formik'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {Link, Navigate, useNavigate} from '~/migrate-react-router'
import essActions from '~/store/employeeShareScheme/actions'
import {possibleESS} from '~/store/employeeShareScheme/selectors'
import {ESSSchemesPreventingAccountClosure} from '~/store/employeeShareScheme/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions, {CloseAccountResponse} from '~/store/identity/actions'
import instrumentActions from '~/store/instrument/actions'
import {State} from '~/store/instrument/types'
import {RootState} from '~/store/types'
import styles from './CloseAccount.scss'

const BALANCE_CLOSE_THRESHOLD = 0.01

interface FooterProps {
    isDependent: boolean
    preferredName: string
    canProceed?: boolean
    onClick?: () => void
}

const Footer: React.FunctionComponent<FooterProps> = ({isDependent, preferredName, canProceed, onClick}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const cancelPath = profileUrl('settings/close-account/cancel')
    return (
        <div>
            <p>
                <strong>Please note, when closing an account:</strong>
            </p>
            <ul>
                <li>it can’t be undone—if you create an account in future, it will be a fresh start</li>
                <li>we’d love to hear why—and would appreciate any feedback you share</li>
                <li>we’ll miss {isDependent ? `${preferredName}` : 'you'}</li>
            </ul>
            {canProceed ? (
                <PageButtonGroup>
                    <Button
                        dataTestId="button--cancel-close-account"
                        label="Cancel closing"
                        onClick={() => navigate(cancelPath)}
                    />
                    <Button
                        dataTestId="button--close-account"
                        type="secondary"
                        label="Close account"
                        onClick={onClick}
                    />
                </PageButtonGroup>
            ) : (
                <PageButtonGroup>
                    <Button
                        label="Cancel"
                        onClick={() => navigate(cancelPath)}
                        dataTestId="button--cancel-close-account"
                    />
                    <Button type="secondary" label="Close account" disabled dataTestId="button--close-account" />
                </PageButtonGroup>
            )}
        </div>
    )
}

interface ESSMessageProps {
    isDependent: boolean
    preferredName: string
    schemesPreventingAccountClosure: ESSSchemesPreventingAccountClosure
}

const ESSMessage = ({isDependent, preferredName, schemesPreventingAccountClosure}: ESSMessageProps) => {
    return (
        <div data-testid="div--ess-message">
            {schemesPreventingAccountClosure?.map(scheme => {
                const contactLinkHref = scheme.contact_link ? linkWithMailToIfNeeded(scheme.contact_link) : undefined
                return (
                    <div key={scheme.id}>
                        <p className={spacing.spaceBelow16}>
                            You are a member of {scheme.name} with allocations you’re still due to receive—to close your
                            account you’ll first need to leave the scheme.
                        </p>
                        <p className={spacing.spaceBelow16}>
                            {scheme.contact_text && scheme.contact_link ? (
                                <>
                                    To leave {scheme.name} please get in touch with {scheme.contact_text} at{' '}
                                    <a href={contactLinkHref}>{scheme.contact_link}</a>.
                                </>
                            ) : (
                                <>Please get in contact with your employer.</>
                            )}{' '}
                            They’ll confirm with us your withdrawal from the employee share scheme.
                        </p>
                    </div>
                )
            })}
            <p className={spacing.spaceBelow32}>
                If you own any investments you’ll need to sell these, and withdraw any money so your Wallet is empty.
            </p>
            <Footer isDependent={isDependent} preferredName={preferredName} />
        </div>
    )
}

interface DependsMessageProps {
    isDependent: boolean
    preferredName: string
}
const DependentsMessage = ({isDependent, preferredName}: DependsMessageProps) => (
    <div data-testid="div--dependents-message">
        <p className={spacing.spaceBelow24}>
            You can’t close your account if you have an open Kids Account. To close your account you’ll need to close
            any Kids Accounts you manage first.
        </p>
        <Footer isDependent={isDependent} preferredName={preferredName} />
    </div>
)

interface PortfolioMessageProps {
    isDependent: boolean
    preferredName: string
    holdings: RootState['identity']['holdings']
    instruments: State['instrumentsById']
    hasPendingDividends?: boolean
    pendingDividends?: string[]
}

// use instruments in the portfolio map to get instrument names
const PortfolioMessage = ({
    holdings,
    instruments,
    isDependent,
    preferredName,
    hasPendingDividends,
    pendingDividends,
}: PortfolioMessageProps) => {
    return (
        <div data-testid="div--portfolio-message">
            <p className={spacing.spaceBelow16}>
                Before you can close {isDependent ? `${preferredName}’s` : 'your'} account, you’ll need to sell any
                investments in {isDependent ? `${preferredName}’s` : 'your'} Portfolio{', '}
                {hasPendingDividends ? 'wait for any outstanding dividends to be paid,' : ''} and withdraw the money
                from {isDependent ? 'their' : 'your'} Wallet.
            </p>
            {holdings.length > 0 && <p>{isDependent ? `${preferredName} still owns` : 'You still own'}:</p>}
            {holdings.length && (
                <ul>
                    {holdings.map(holding => {
                        if (instruments[holding.fund_id]) {
                            return (
                                <li key={holding.fund_id}>
                                    <strong>{instruments[holding.fund_id].name}</strong> ({holding.shares} shares)
                                </li>
                            )
                        }
                    })}
                </ul>
            )}
            <p>{hasPendingDividends ? 'You’re still waiting on dividends from:' : ``}</p>
            <ul>
                {pendingDividends &&
                    pendingDividends
                        .filter(i => instruments[i])
                        .map(d => (
                            <li key={d}>
                                <strong>{instruments[d].name}</strong>
                            </li>
                        ))}
            </ul>
            <p className={cn(spacing.spaceAbove16, spacing.spaceBelow32)}>
                Selling investments can take 2-5 working days.
            </p>
            <Footer isDependent={isDependent} preferredName={preferredName} />
        </div>
    )
}

interface WalletMessageProps {
    isDependent: boolean
    preferredName: string
}
const WalletMessage = ({isDependent, preferredName}: WalletMessageProps) => {
    const profileUrl = useProfileUrl()
    const profile = useProfile()
    const walletPortfolio = profile.portfolios.find(p => p.product === 'WALLET')!

    return (
        <div data-testid="div--wallet-message">
            <p className={spacing.spaceBelow16}>
                {isDependent ? `${preferredName} has` : 'You have'} money in {isDependent ? 'their' : 'your'}{' '}
                Wallet—you’ll need to{' '}
                <Link to={profileUrl('wallet/:portfolioId/withdrawal', {portfolioId: walletPortfolio.id})}>
                    Withdraw
                </Link>{' '}
                it before you can close {isDependent ? 'their' : 'your'} account.
            </p>

            <Footer isDependent={isDependent} preferredName={preferredName} />
        </div>
    )
}

interface SaveMessageProps {
    isDependent: boolean
    preferredName: string
    multipleSaveAccounts: boolean
}

const SaveMessage = ({isDependent, preferredName, multipleSaveAccounts}: SaveMessageProps) => (
    <div data-testid="div--save-message">
        <p className={spacing.spaceBelow16}>
            {isDependent ? `${preferredName} has` : 'You have'} money in {isDependent ? 'their' : 'your'} Save account
            {multipleSaveAccounts ? 's' : ''}—you’ll need to withdraw it before you can close{' '}
            {isDependent ? 'their' : 'your'} account.
        </p>

        <Footer isDependent={isDependent} preferredName={preferredName} />
    </div>
)

interface CloseAccountConfirmationProps {
    isDependent: boolean
    preferredName: string
    onClick: () => void
}
const CloseAccountConfirmation = ({onClick, isDependent, preferredName}: CloseAccountConfirmationProps) => (
    <div data-testid="div--close-account-confirmation">
        <p className={spacing.spaceBelow16}>
            We’ll be sad to see {isDependent ? preferredName : 'you'} leave. Once you’ve closed{' '}
            {isDependent ? `${preferredName}’s` : 'your'} account we’ll email you an investment report of{' '}
            {isDependent ? `${preferredName}’s` : 'your'} history with us, in case you need it later.
        </p>
        <Footer isDependent={isDependent} preferredName={preferredName} canProceed onClick={onClick} />
    </div>
)

interface FormValues {
    reason: Request.IdentityCloseAccount['reason']
    comments: string
}

interface FormProps {
    jurisdiction: string
    closeAccount(reason: Request.IdentityCloseAccount['reason'], comments: string): Promise<CloseAccountResponse>
    closeAccountCheck(): void
    profileUrl: ReturnType<typeof useProfileUrl>
}

const closeReasons: {value: Request.IdentityCloseAccount['reason']; label: string}[] = [
    {value: 'dont_want_to_invest', label: 'I don’t want to invest anymore'},
    {value: 'too_expensive', label: 'Sharesies is too expensive'},
    {value: 'want_broader_range', label: 'I want a broader range of investments'},
    {value: 'want_to_invest_elsewhere', label: 'I want to invest somewhere else'},
    {value: 'none_of_these', label: 'None of these (I’d prefer not to say)'},
]

const FeedbackForm = withRouter(
    withFormik<FormProps & WithRouterProps, FormValues>({
        mapPropsToValues: () =>
            ({
                comments: '',
            }) as FormValues,
        handleSubmit: async (
            {reason, comments},
            {
                setSubmitting,
                setStatus,
                props: {
                    closeAccount,
                    jurisdiction,
                    router: {navigate},
                    profileUrl,
                },
            },
        ) => {
            try {
                const response = await closeAccount(reason, comments)
                if ('error' in response) {
                    setStatus(response.error)
                    return
                }

                if (response.isDependent) {
                    navigate(
                        profileUrl('close-account/dependent-success/:preferred_name', {
                            preferred_name: encodeURIComponent(response.preferredName),
                        }),
                    )
                } else {
                    navigate(
                        urlFor('close-account/success/:jurisdiction', {
                            jurisdiction: encodeURIComponent(jurisdiction),
                        }),
                    )
                }
            } catch (e) {
                setStatus(unknownErrorMessage)
                throw e
            } finally {
                setSubmitting(false)
            }
        },
        validate: validate.generate<FormValues>({
            reason: [validate.required()],
            comments: [],
        }),
    })(({handleSubmit, status, isSubmitting, router: {navigate}, profileUrl}) => (
        <form onSubmit={handleSubmit}>
            <Radio dataTestId="radio--reason-for-closing" name="reason" label="" choices={closeReasons} />
            <p>We’d love to hear any other thoughts you’d care to share:</p>
            <Field
                name="comments"
                component={undefined}
                validate={undefined}
                render={({form}: any) => {
                    return <textarea name="comments" value={form.values.comments} onChange={form.handleChange} />
                }}
            />
            <ErrorBox message={status} />
            <PageButtonGroup>
                <Button
                    dataTestId="button--cancel-close-account"
                    label="Cancel closing"
                    onClick={() => navigate(profileUrl('settings/close-account/cancel'))}
                />
                <Button
                    dataTestId="button--close-account"
                    isSubmit
                    type="secondary"
                    label="Close account"
                    processing={isSubmitting}
                />
            </PageButtonGroup>
        </form>
    )),
)

export const CloseAccount: React.FunctionComponent = () => {
    const profileUrl = useProfileUrl()
    const profiles = useProfiles()

    const [step, setStep] = React.useState<'initial' | 'feedback'>('initial')

    // Shared money
    // TODO: Shared Money: close account needs TLC before any multi-owner account can be closed
    const anyProfileHasFonterra = profiles.some(profile =>
        profile.portfolios.some(portfolio => portfolio.product === 'FONTERRA'),
    )

    // Portfolio / Wallet / Holding
    const holdings = useAppSelector(s => s.identity.holdings)
    const walletBalances = useAppSelector(s => s.identity.user!.wallet_balances)
    const holdingBalance = useAppSelector(s => s.identity.user!.holding_balance)
    const saveAccounts = useAppSelector(s => s.identity.saveAccounts)
    const saveBalance = Object.keys(saveAccounts).reduce((previous, key) => {
        return previous + Number(saveAccounts[key].balance)
    }, 0)

    // Identity
    const dependentCount = useAppSelector(s =>
        s.identity.user!.is_dependent
            ? 0
            : s.identity.userList.filter(u => u.state === 'active' && u.id !== s.identity.user!.id).length,
    )
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)
    const closeAccountCheckData = useAppSelector(s => s.identity.closeAccountCheck)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)

    // Instrument
    const instrumentMetadataInitialised = useAppSelector(s => s.instrument.isMetadataInitialised)
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const resultsLoadingState = useAppSelector(s => s.instrument.resultsLoadingState)

    // ESS
    const isPossiblyESS = useAppSelector(s => possibleESS(s))
    const schemesPreventingAccountClosureLoadingState = useAppSelector(
        s => s.employeeShareScheme.schemesPreventingAccountClosureLoadingState,
    )
    const schemesPreventingAccountClosure = useAppSelector(s => s.employeeShareScheme.schemesPreventingAccountClosure)

    const dispatch = useAppDispatch()
    const closeAccount = (reason: Request.IdentityCloseAccount['reason'], comments: string) =>
        dispatch(actions.CloseAccount(reason, comments))
    const closeAccountCheck = () => dispatch(actions.GetIdentityCloseAccountCheck())
    const getInstrumentsByIds = (instrumentIds: string[]) =>
        dispatch(instrumentActions.getInstrumentsByIds(instrumentIds))

    React.useEffect(() => {
        if (isPossiblyESS && isUninitialised(schemesPreventingAccountClosureLoadingState)) {
            dispatch(essActions.FetchSchemesPreventingAccountClosure())
        }
    }, [isPossiblyESS, schemesPreventingAccountClosureLoadingState])

    const moneyInWallet = (): boolean => {
        const anyMoneyInWallet = Object.values(walletBalances).some(
            balance => parseFloat(balance) > BALANCE_CLOSE_THRESHOLD,
        )
        const anyMoneyInHolding = parseFloat(holdingBalance) > 0
        return anyMoneyInWallet || anyMoneyInHolding
    }
    const hasPositiveWalletBalance = moneyInWallet()

    const getPortfolio = (): RootState['identity']['holdings'] => {
        // TODO - need to include holding shares
        return holdings.filter(p => parseFloat(p.shares))
    }
    const filteredPortfolio = getPortfolio()
    const hasPendingDividends = closeAccountCheckData?.has_pending_dividends
    const pendingDividends = closeAccountCheckData?.pending_dividends

    let heading: string = ''
    switch (step) {
        case 'initial':
            heading = isDependent ? `How to close ${preferredName}’s account` : 'How to close your account'
            break
        case 'feedback':
            heading = 'Do you have any feedback?'
            break
        default:
            assertNever(step)
    }

    React.useEffect(() => {
        closeAccountCheck()
    }, [])

    React.useEffect(() => {
        // Get a list of instruments which we need to render a name for, but we don't have loaded
        const unloadedInstruments = [...holdings.map(p => p.fund_id), ...(pendingDividends || [])]
            .filter(i => i && !instrumentsById[i]) // Filter out ones we've already loaded
            .filter((instrument, index, arr) => arr.indexOf(instrument) === index) // Dedupe it

        if (instrumentMetadataInitialised && unloadedInstruments.length && resultsLoadingState === 'ready') {
            getInstrumentsByIds(unloadedInstruments)
        }
    }, [
        resultsLoadingState,
        getInstrumentsByIds,
        instrumentMetadataInitialised,
        instrumentsById,
        holdings,
        pendingDividends,
    ])

    const renderContent = (): React.ReactNode => {
        if (isPossiblyESS && isLoadingOrUninitialised(schemesPreventingAccountClosureLoadingState)) {
            return <Loading isPineapple />
        }

        if (schemesPreventingAccountClosure?.length) {
            return (
                <ESSMessage
                    isDependent={isDependent}
                    preferredName={preferredName}
                    schemesPreventingAccountClosure={schemesPreventingAccountClosure}
                />
            )
        }

        if (dependentCount > 0) {
            return <DependentsMessage isDependent={isDependent} preferredName={preferredName} />
        }

        if (filteredPortfolio.length || hasPendingDividends) {
            return (
                <PortfolioMessage
                    holdings={filteredPortfolio}
                    instruments={instrumentsById}
                    isDependent={isDependent}
                    preferredName={preferredName}
                    hasPendingDividends={hasPendingDividends}
                    pendingDividends={pendingDividends}
                />
            )
        }

        if (hasPositiveWalletBalance) {
            return <WalletMessage isDependent={isDependent} preferredName={preferredName} />
        }

        if (saveBalance > 0) {
            return (
                <SaveMessage
                    isDependent={isDependent}
                    preferredName={preferredName}
                    multipleSaveAccounts={Object.keys(saveAccounts).length > 1}
                />
            )
        }

        switch (step) {
            case 'initial':
                return (
                    <CloseAccountConfirmation
                        onClick={() => setStep('feedback')}
                        isDependent={isDependent}
                        preferredName={preferredName}
                    />
                )
            case 'feedback':
                return (
                    <>
                        <p className={spacing.spaceBelow16}>
                            Sharesies’ vision is that people have equal investment opportunities—no matter how much they
                            have to invest.
                        </p>
                        <p className={spacing.spaceBelow16}>
                            Before you go, would you mind please letting us know why you’re leaving?
                        </p>
                        <FeedbackForm
                            closeAccount={closeAccount}
                            closeAccountCheck={closeAccountCheck}
                            jurisdiction={jurisdiction}
                            profileUrl={profileUrl}
                        />
                    </>
                )
            default:
                assertNever(step)
        }
    }

    if (anyProfileHasFonterra) {
        // TODO: Shared Money: close account needs TLC before any multi-owner account can be closed
        sendWrapperAppMessage({type: 'goBack'})
        return <Navigate to={profileUrl('settings')} replace />
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--close-account" leftButton="back" title="Close account" />
            <Page className={styles.container} overrideDefaultTopPadding="withToolbarTitle">
                <h2 className={cn(spacing.spaceBelow12, typographyOverrides['as-h2'])}>{heading}</h2>
                {renderContent()}
            </Page>
        </>
    )
}

export default CloseAccount
