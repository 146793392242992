import {
    AiScout,
    AutoInvest,
    Closed,
    DividendReinvest,
    NotificationBell,
    RecurringTopUp,
    RoundUps,
    ShareTransfer,
    ReturnsCalculation,
} from '@design-system/icon'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {isUninitialised} from '~/global/utils/is-loading/isLoading'
import useAutoinvestDividends from '~/global/utils/use-autoinvest-dividends/useAutoinvestDividends'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ListMenuGroup, ListMenuItem} from '~/global/widgets/list-menu/ListMenu'
import {ListMenuExpanded} from '~/global/widgets/list-menu-expanded/ListMenuExpanded'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useRecurringPayment} from '~/sections/OLD_wallet/sections/linked-bank-payments/state'
import {useAutoinvestOrders} from '~/sections/invest/sections/auto-invest/hooks/useAutoinvestOrders'
import bankLinkingActions from '~/store/bankLinking/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'

const useAutoinvestState = (): 'On' | 'Off' => {
    const [autoinvestOrders] = useAutoinvestOrders()
    return autoinvestOrders.length > 0 ? 'On' : 'Off'
}

const useAutoinvestDividendsState = () => {
    const {preferences} = useAutoinvestDividends()
    return preferences === undefined ? '' : preferences.primary || preferences.funds.length > 0 ? 'On' : 'Off'
}

const useUsExtendedHoursState = () => {
    return useAppSelector(s =>
        s.identity.user!.us_equities_enabled && s.identity.portfolioExtendedHoursPreference ? 'On' : 'Off',
    )
}

const useRoundupsState = (ensureLoaded: boolean): string => {
    const dispatch = useAppDispatch()
    const {loadingState, isRoundupsEnabled} = useAppSelector(({bankLinking}) => {
        return {
            loadingState: bankLinking.bankLinkingLoadingState,
            isRoundupsEnabled: bankLinking.isBankLinked && bankLinking.isRoundupsEnabled,
        }
    })
    React.useEffect(() => {
        if (ensureLoaded && isUninitialised(loadingState)) {
            dispatch(bankLinkingActions.FetchBankLinkingStatus())
        }
    }, [])
    return isUninitialised(loadingState) ? '' : isRoundupsEnabled ? 'On' : 'Off'
}

const useRecurringPaymentState = () => {
    const config = useRecurringPayment()
    return config === undefined ? '' : config.next_payment ? 'On' : 'Off'
}

export const Manage: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const hasAiSearchFlag = useAppSelector(s => s.identity.flags.ai_search)
    const autoInvestState = useAutoinvestState()
    const autoInvestDividendsState = useAutoinvestDividendsState()
    const usExtendedHoursState = useUsExtendedHoursState()
    const roundupsState = useRoundupsState(jurisdiction === 'au')
    const recurringLinkedBankTopupsState = useRecurringPaymentState()

    const autoInvestDescription = {
        nz: 'Choose a pre-made or DIY order and we’ll place it for you regularly.',
        au: 'Set up a repeating order for your choice of investments.',
    }[jurisdiction]

    return (
        <>
            <Toolbar
                dataTestId="toolbar--invest-manage"
                title={isDependent ? `Manage ${preferredName}’s invest` : 'Manage invest'}
                leftButton="back"
                onLeftButtonClick={() => navigate(profileUrl('invest'))}
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <div style={{marginTop: '-10px'}}>
                    {/* FeatureLink is top padded, leaving too much vertical space where we have no intro text */}
                </div>
                <ListMenuExpanded
                    dataTestId="option--auto-invest"
                    title="Auto-invest"
                    description={autoInvestDescription}
                    state={autoInvestState}
                    icon={<AutoInvest />}
                    onClick={() => navigate(profileUrl('auto-invest'))}
                />
                {hasAiSearchFlag && (
                    <ListMenuExpanded
                        dataTestId="option--ai-search"
                        title="AI Search"
                        description="Discover investments and gain insight using our AI-powered search tool."
                        icon={<AiScout />}
                        onClick={() => navigate(profileUrl('invest/ai-search'))}
                    />
                )}
                {jurisdiction === 'au' && (
                    <>
                        <ListMenuExpanded
                            dataTestId="option--roundups"
                            title="Round-ups"
                            description="Automatically top up your Wallet using the leftover cents from your day-to-day spending."
                            state={roundupsState}
                            icon={<RoundUps />}
                            onClick={() => navigate(profileUrl('wallet/roundups'))}
                        />
                        <ListMenuExpanded
                            dataTestId="option--recurring-topup"
                            title="Recurring linked bank top-ups"
                            description="Seamlessly transfer money on a recurring basis from your linked bank."
                            state={recurringLinkedBankTopupsState}
                            icon={<RecurringTopUp />}
                            onClick={() => navigate(profileUrl('wallet/linked-bank-topup/recurring'))}
                        />
                    </>
                )}
                <ListMenuExpanded
                    dataTestId="option--dividend-reinvest"
                    title="Auto-invest dividends"
                    description="Automatically invest dividends in the company or fund it came from."
                    state={autoInvestDividendsState}
                    icon={<DividendReinvest />}
                    onClick={() => navigate(profileUrl('invest/dividends'))}
                />
                <ListMenuExpanded
                    dataTestId="option--us-extended-hours"
                    title="US extended hours"
                    description="Allow orders to fill before and after regular US market hours."
                    state={usExtendedHoursState}
                    icon={<Closed />}
                    onClick={() => navigate(profileUrl('invest/portfolio-us-extended-hours'))}
                />
                <ListMenuExpanded
                    dataTestId="option--price-notification"
                    title="Price notifications"
                    description="See and manage all price notifications set on investments."
                    state=""
                    icon={<NotificationBell />}
                    onClick={() => navigate(profileUrl('invest/notifications/price'))}
                />
                <ListMenuExpanded
                    dataTestId="option--returns-calculation"
                    title="Returns calculation"
                    description="Include gains or losses from sold investments when calculating returns."
                    state=""
                    icon={<ReturnsCalculation />}
                    onClick={() => navigate(profileUrl('invest/portfolio-return-calculation'))}
                />
                {!isDependent && (
                    <ListMenuExpanded
                        dataTestId="option--transfer-shares"
                        title="Transfer shares"
                        description="Transfer NZ, US and Australian shares you hold into or out of Sharesies."
                        state=""
                        icon={<ShareTransfer />}
                        onClick={() => navigate(profileUrl('invest/portfolio-transfer-shares'))}
                    />
                )}

                <ListMenuGroup heading="More actions" className={spacing.spaceAbove40}>
                    <ListMenuItem label="Download reports" onClick={() => navigate(profileUrl('invest/reports'))} />
                    {!isDependent && (
                        <ListMenuItem
                            label="Connect to Sharesight"
                            onClick={() => navigate(profileUrl('invest/portfolio-sharesight'))}
                        />
                    )}
                    {!isDependent && jurisdiction === 'nz' && (
                        <ListMenuItem
                            label="Create Kids Account"
                            onClick={() => navigate(profileUrl('settings/create-kids-account'))}
                        />
                    )}
                </ListMenuGroup>
            </Page>
        </>
    )
}
