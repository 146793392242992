import React from 'react'
import styles from './Row.scss'

export const Row: React.FunctionComponent<{label: string; value: string}> = ({label, value}) => {
    return (
        <div className={styles.row}>
            <div className={styles.label}>{label}</div>
            <div className={styles.value}>{value}</div>
        </div>
    )
}
