import {TransferOrderRegistryDetails} from '~/store/transfer/types'

export const getASXTransferReferenceType = (reference: string): TransferOrderRegistryDetails['referenceType'] => {
    const alphaPrefix = reference.substring(0, 1).toUpperCase()
    switch (alphaPrefix) {
        case 'I':
            return 'SRN'
        case 'C':
            return 'SRN'
        case 'X':
            return 'HIN'
        default:
            return 'SRN'
    }
}
