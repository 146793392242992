import cn from 'classnames'
import React from 'react'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {ChevronDown, ChevronUp} from '~/global/widgets/OLD_icons'
import ExchangeMoneyFees from '~/global/widgets/help-modals/ExchangeMoneyFees'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {Instrument} from '~/store/instrument/types'
import styles from './FXOrdersDetail.scss'

type FXOrders = Model.BuyOrder['fx_orders'] | Required<Model.CorporateActionV2>['application']['fx_orders']
interface FXOrdersDetailProps {
    fxOrders: FXOrders
    holdAmount: number
    instrument: Instrument
}

const getWalletTotal = (fxOrders: FXOrders, holdAmount: number): number | false => {
    const exchangeTotal = fxOrders.reduce((value, fxo) => parseFloat(fxo.target_amount) + value, 0)
    return holdAmount > exchangeTotal ? holdAmount - exchangeTotal : false
}

export const FxDetailRow = ({left, right}: {left: string | React.ReactNode; right: string | React.ReactNode}) => (
    <div className={styles.fxDetailsRow}>
        <div>{left}</div>
        <div className={styles.right}>{right}</div>
    </div>
)

const FXOrdersDetail = React.memo(({fxOrders, holdAmount, instrument}: FXOrdersDetailProps) => {
    const walletTotal = getWalletTotal(fxOrders, holdAmount)
    const [openDetails, setOpenDetails] = React.useState(false)

    return (
        <>
            <div className={cn(spacing.spaceAbove32, styles.fxExchangeList)}>
                {walletTotal && (
                    <div className={cn(styles.fxExchangeItem, spacing.spaceBelow4)}>
                        <DollarValue value={walletTotal} currency={instrument.currency} />
                    </div>
                )}
                {fxOrders.map((fxo, key) => {
                    return (
                        <div key={key} className={cn(styles.fxExchangeItem, spacing.spaceBelow4)}>
                            <DollarValue value={fxo.net_source_amount} currency={fxo.source_currency} /> exchanged to{' '}
                            <DollarValue value={fxo.target_amount} currency={fxo.target_currency} />
                        </div>
                    )
                })}
            </div>
            <div className={styles.fxExchangeDetailList}>
                <div className={cn(styles.fxExchangeItem, spacing.spaceBelow4)}>
                    <div className={styles.label} onClick={() => setOpenDetails(!openDetails)}>
                        {'Currency exchange details'} {!openDetails && <ChevronDown />}
                        {openDetails && <ChevronUp />}
                    </div>
                    {fxOrders.map(fxo => {
                        return (
                            openDetails && (
                                <>
                                    <p className={styles.fxDetailsRowHeader}>
                                        Exchanged from {fxo.source_currency.toUpperCase()}
                                    </p>
                                    <FxDetailRow
                                        left="Exchange rate"
                                        right={
                                            <>
                                                1 {fxo.source_currency.toUpperCase()} = {fxo.exchange_rate}{' '}
                                                {fxo.target_currency.toUpperCase()}
                                            </>
                                        }
                                    />
                                    <FxDetailRow
                                        left={
                                            <>
                                                Exchange {!walletTotal && 'transaction'} fee <ExchangeMoneyFees />
                                            </>
                                        }
                                        right={<DollarValue value={fxo.source_fee} currency={fxo.source_currency} />}
                                    />
                                    <FxDetailRow
                                        left={<>{fxo.source_currency.toUpperCase()} to exchange</>}
                                        right={
                                            <DollarValue value={fxo.net_source_amount} currency={fxo.source_currency} />
                                        }
                                    />
                                    <FxDetailRow
                                        left={<>{fxo.target_currency.toUpperCase()} to receive</>}
                                        right={<DollarValue value={fxo.target_amount} currency={fxo.target_currency} />}
                                    />
                                </>
                            )
                        )
                    })}
                </div>
            </div>
        </>
    )
})

export default FXOrdersDetail
