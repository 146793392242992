import React from 'react'
import {urlFor} from '~/global/routeGenerator'
import {Navigate} from '~/migrate-react-router'
import {useAppSelector} from '~/store/hooks'

interface LoginRedirecterProps {
    redirectWhen: 'authenticated' | 'anonymous'
    redirectTo: string
}

const LoginRedirecter: React.FunctionComponent<LoginRedirecterProps> = ({redirectWhen, redirectTo}) => {
    const authenticated = useAppSelector(s => s.identity.user !== null)
    const stillInSignup = useAppSelector(
        s => s.identity.user && !s.identity.user.is_dependent && s.identity.user.state === 'in_signup',
    )
    if (redirectWhen === 'authenticated' && authenticated) {
        if (stillInSignup) {
            // prevents flash of another route for investors that exited and resumed sign up
            return <Navigate to={urlFor('sign-up')} />
        }
        return <Navigate to={redirectTo} />
    }
    if (redirectWhen === 'anonymous' && !authenticated) {
        return <Navigate to={redirectTo} />
    }

    return null
}

export default LoginRedirecter
