import {ModalLink} from '@design-system/modal'
import React from 'react'

const FastFeeModal: React.FunctionComponent<{isEstimate?: boolean}> = ({isEstimate}) => {
    const title = `${isEstimate ? 'Estimated faster' : 'Faster'} withdrawal fee`
    return (
        <ModalLink
            dataTestId="modal-link--fast-withdrawal-fee"
            label={title.toLowerCase()}
            asIcon
            modalTitle={title}
            primaryButton={{label: 'Ok'}}
            helpIconSize={16}
        >
            <p>
                This fee covers the cost of us pre-paying you the unsettled money due in your trade while it goes
                through the settlement process. Settlement is when share ownership is legally transferred from one
                shareholder to another.
            </p>
        </ModalLink>
    )
}

export default FastFeeModal
