import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal'
import {useQueryClient} from '@tanstack/react-query'
import cn from 'classnames'
import {useAtom, useSetAtom} from 'jotai'
import {DateTime} from 'luxon'
import React from 'react'
import {useNavigate} from 'react-router'
import {Link} from 'react-router-dom'
import {useCoveAnonymousPost, CoveResponseError, isCoveResponseError} from '~/api/query/cove'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import {Request} from '~/api/retail/types'
import * as rollbar from '~/api/rollbar/rollbar'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {useActor} from '~/global/state-hooks/retail/useActor'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {reEnterPasswordMessage} from '~/global/utils/error-text/errorText'
import {dateFormatLongMonth} from '~/global/utils/format-date/formatDate'
import {FullNameType} from '~/global/utils/format-full-name/formatFullName'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ErrorBox} from '~/global/widgets/form-controls'
import HelpCentreLink from '~/global/widgets/help-centre-link/HelpCentreLink'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import CarDetailsForm from '~/sections/protect/sections/insure/sections/car/pages/create-car-estimate/CarDetailsForm'
import styles from '~/sections/protect/sections/insure/sections/car/pages/create-car-estimate/CreateCarEstimate.scss'
import {
    CoveCreateEstimateError,
    CreateEstimateError,
} from '~/sections/protect/sections/insure/sections/car/pages/create-car-estimate/CreateEstimateError'
import {
    constructCustomerDetails,
    constructEstimateQueryParams,
    getCoveCreateEstimateErrorType,
} from '~/sections/protect/sections/insure/sections/car/utils/create-car-estimate/createCarEstimate'
import {
    carAndDriverDetailsAtom,
    createEstimateDetailsAtom,
    coveCreateEstimateResponseAtom,
} from '~/sections/protect/sections/insure/state'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import {actingAsID as actingAsIDSelector} from '~/store/identity/selectors'

export const CreateEstimate: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const profileUrl = useProfileUrl()
    const queryClient = useQueryClient()
    const owner = useProfileOwner()
    const actor = useActor()

    const [fullName, setFullName] = React.useState<FullNameType | null>(null)
    const [authenticationError, setAuthenticationError] = React.useState<Error | null>(null)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [coveCreateEstimateError, setCoveCreateEstimateError] = React.useState<CoveCreateEstimateError | undefined>(
        undefined,
    )

    const [createEstimateDetails, setCreateEstimateDetails] = useAtom(createEstimateDetailsAtom)
    const [carAndDriverDetails, setCarAndDriverDetails] = useAtom(carAndDriverDetailsAtom)
    const setCoveCreateEstimateResponse = useSetAtom(coveCreateEstimateResponseAtom)

    const actingAsID = useAppSelector(actingAsIDSelector)

    // Address can be edited from this page so make sure we get it on render
    const {data: address} = useRetailGet({
        path: 'owner/:owner_id/address',
        pathParams: {owner_id: owner.id},
    })

    // email, mobile, date of birth
    const email = actor.email
    const mobile = actor.phone
    const dateOfBirth = useAppSelector(s => s.identity.actor?.date_of_birth)

    // Construct the customer details to send to Cove
    const customerDetails =
        dateOfBirth &&
        fullName &&
        carAndDriverDetails &&
        constructCustomerDetails(address.address, carAndDriverDetails, dateOfBirth, email, fullName, mobile)

    // Make sure to explicitly set the timezone otherwise customers using the app in different timezones will not see the correct date of birth
    const dateObj = dateOfBirth && DateTime.fromISO(dateOfBirth, {zone: 'Pacific/Auckland'})
    const formattedDob = dateObj && dateObj.setLocale('en-NZ').toLocaleString(dateFormatLongMonth)

    React.useEffect(() => {
        // Fetch the customer's full name. As this is PII, this is kept outside the redux store
        const authenticateAndFetchFullName = async () => {
            if (fullName?.firstName) {
                return
            }
            const response = await dispatch(identityActions.GetFullName())
            if (response.type === 'full_name') {
                setFullName({
                    firstName: response.first_name,
                    middleName: response.middle_name,
                    lastName: response.last_name,
                })
            } else if (response === reEnterPasswordMessage) {
                setAuthenticationError(new Error(reEnterPasswordMessage))
            }
        }

        authenticateAndFetchFullName()
    }, [])

    // Check for an existing Insure account.
    // If the user doesn't have one already, we'll create one after we get the Create Estimate response from Cove
    const {data: insureAccount} = useRetailGet({path: 'insure/get-account'})
    const personId = insureAccount.person_id
    const hasInsureAccount = insureAccount.type === 'insure_account'
    const createInsureAccount = useRetailPost({path: 'insure/create-account'}) // If they already have an account, this won't be called

    /*** CREATE AN ESTIMATE ***/
    // 1. Instantiate the hook
    const createCoveEstimate = useCoveAnonymousPost('sharesies/quote/motor/comprehensive/v1/create')

    const createEstimate = async () => {
        // 2. Construct the params
        const estimateParams = customerDetails && constructEstimateQueryParams(customerDetails, personId)

        // 3. Call the mutate function
        const response = estimateParams && (await createCoveEstimate.mutateAsync(estimateParams))

        // 4. Handle the response
        if (response) {
            setCoveCreateEstimateResponse(response)

            // Double-check that the Sharesies ID we receive back from Cove matches our person ID.
            // I can't imagine how a mismatch would happen, but it's definitely a problem if it does!
            if (response.customer.web_id !== personId) {
                rollbar.sendError('Identity mismatch in Cove Create Estimate response', {
                    personId,
                    coveSharesiesId: response.customer.web_id,
                })

                throw new Error(
                    'Received an estimate response from Cove where there is an identity mismatch between the caller and the response',
                )
            }

            // Create an Insure account if they don't already have one
            if (!hasInsureAccount) {
                const createInsureAccountParams: Request.CreateInsureAccount = {
                    acting_as_id: actingAsID,
                    cove_customer_id: response.customer.customer_id_,
                    cove_access_token: response.customer.access_token_,
                }

                const newAccount = await createInsureAccount.mutateAsync(createInsureAccountParams)
                if (newAccount.type !== 'create_insure_account_success') {
                    const errorMessage = 'Error creating Insure account'

                    rollbar.sendError(errorMessage)
                    throw new Error(errorMessage)
                }

                // Now that we've created an Insure account, we want to reset the Get Account query cache,
                // so that any navigation or routing that involves an account check will pick up the newly created account
                queryClient.resetQueries({queryKey: ['insure/get-account']})
            }
        }
    }
    /*** END CREATE ESTIMATE ***/

    if (fullName instanceof Error) {
        navigate(profileUrl('protect/insure'), {replace: true})
    }

    const handleNextPage = (drivingExperience: string, licenceType: string, registration: string) => {
        setCarAndDriverDetails({drivingExperience, licenceType, registration})
    }

    React.useEffect(() => {
        if (customerDetails) {
            setCreateEstimateDetails(customerDetails)
        }
    }, [carAndDriverDetails])

    const handleConfirm = async () => {
        setIsLoading(true)
        try {
            await createEstimate()

            navigate(profileUrl('protect/insure/car/estimate'))
        } catch (error) {
            if (isCoveResponseError(error)) {
                const coveResponseError = error as CoveResponseError
                const errorType = getCoveCreateEstimateErrorType(coveResponseError)

                setCoveCreateEstimateError(errorType)
            } else {
                setCoveCreateEstimateError('Unknown error')
            }
        } finally {
            setIsLoading(false)
        }
    }

    // Error screen
    if (coveCreateEstimateError) {
        return <CreateEstimateError error={coveCreateEstimateError} />
    }

    // Screen 1 - Enter Plate number, Licence type and Years experience (also Date of Birth if not existing)
    if (!createEstimateDetails) {
        return (
            <>
                <Toolbar
                    dataTestId="toolbar--estimate-add-car-details"
                    leftButton="back"
                    onLeftButtonClick={() => navigate(profileUrl('protect/insure'), {replace: true})}
                />

                <CarDetailsForm
                    experience={customerDetails && customerDetails.drivingExperience}
                    licence={customerDetails && customerDetails.licenceType}
                    rego={customerDetails && customerDetails.registration}
                    nextPage={(drivingExperience: string, licenceType: string, registration: string) =>
                        handleNextPage(drivingExperience, licenceType, registration)
                    }
                />
            </>
        )
    }

    const trackEditDetailsClicked = () => rudderTrack('insurance_signup', 'confirm_details_edit_clicked')

    // Screen 2 - Confirm details
    return (
        <>
            <Toolbar
                dataTestId="toolbar--insure-estimate-confirm-details"
                leftButton="back"
                onLeftButtonClick={() => setCreateEstimateDetails(undefined)}
            />
            <Page>
                <h1 className={cn(styles.heading, spacing.spaceBelow24)}>Confirm your details</h1>
                <p className={spacing.spaceBelow16}>
                    Double-check that your details are correct. They’ll be securely sent to Cove to generate your
                    estimate.{' '}
                </p>
                <div className={styles.detailsWrapper}>
                    {/* Full name */}
                    <p className={styles.subheading}>Name</p>
                    <div className={styles.editableRow}>
                        <p>
                            {fullName?.firstName} {fullName?.lastName}
                        </p>
                        <ModalLink
                            dataTestId="modal-link--updating-your-details"
                            label="Updating your details"
                            modalTitle="Updating your details"
                            asIcon
                            helpIconColour="IconSubtle"
                        >
                            If your name is incorrect,{' '}
                            <HelpCentreLink nzArticle="1393734-update-your-personal-details">
                                find out how to update it in the help centre
                            </HelpCentreLink>
                            .
                        </ModalLink>{' '}
                    </div>

                    {/* Date of birth */}
                    <p className={cn(styles.subheading, spacing.spaceAbove16)}>Date of birth</p>
                    <p>{formattedDob}</p>

                    {/* Email address */}
                    <p className={cn(styles.subheading, spacing.spaceAbove16)}>Email</p>
                    <p>{actor?.email}</p>

                    {/* Phone number */}
                    <div className={styles.editableRow}>
                        <p className={cn(styles.subheading, spacing.spaceAbove16)}>Phone number</p>
                        <Link
                            data-testid="link--edit-phone"
                            to={profileUrl('settings/contact-details')}
                            onClick={trackEditDetailsClicked}
                        >
                            Edit
                        </Link>
                    </div>
                    <p className={cn({[styles.noPhone]: customerDetails && !customerDetails?.mobile})}>
                        {actor?.phone || 'None provided'}
                    </p>

                    {/* Address */}
                    <div className={styles.editableRow}>
                        <p className={cn(styles.subheading, spacing.spaceAbove16)}>Address</p>
                        <Link
                            data-testid="link--edit-address"
                            to={profileUrl('settings/personal-details')}
                            onClick={trackEditDetailsClicked}
                        >
                            Edit
                        </Link>
                    </div>
                    <p>{address.address.formatted}</p>

                    <p className={cn(styles.helpText, spacing.spaceAbove24)}>
                        By continuing, you agree that Sharesies and Cove may share information to provide you with, and
                        email you about, your estimate and any resulting cover. To see how we collect, hold, and use
                        your personal information,{' '}
                        <Link data-testid="link--edit-address" to={profileUrl('legal/privacy-policy')}>
                            check out our privacy policy
                        </Link>
                        .
                    </p>
                    <p className={cn(styles.helpText, spacing.spaceAbove16)}>
                        Estimates are not final, indicative only, and calculated on limited information. You’ll be able
                        to adjust your details before you get your final quote.{' '}
                    </p>
                </div>

                <ErrorBox message={authenticationError} />
            </Page>
            <ActionBar>
                <Button
                    label="Get estimate"
                    dataTestId="button--get-estimate"
                    onClick={handleConfirm}
                    processing={isLoading}
                />
            </ActionBar>
        </>
    )
}

export default CreateEstimate
