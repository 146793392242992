import {useQueryClient} from '@tanstack/react-query'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import {Request} from '~/api/retail/types'
import {DEFAULT_CACHE_STALE_OPTION} from '~/sections/kiwisaver/state'
import {PortfolioFilterOptions, PortfolioSortOptions} from '~/store/identity/types'

export const usePortfolioSortFilterPreference = (portfolioId: string) => {
    const queryClient = useQueryClient()

    const {data: filterPreference} = useRetailGet({
        path: 'portfolio/:portfolio_id/get-filter-preference-v2',
        pathParams: {portfolio_id: portfolioId},
        options: DEFAULT_CACHE_STALE_OPTION,
    })

    const {data: sortPreference} = useRetailGet({
        path: 'portfolio/:portfolio_id/get-sort-preference-v2',
        pathParams: {portfolio_id: portfolioId},
        options: DEFAULT_CACHE_STALE_OPTION,
    })

    const filterMutation = useRetailPost({
        path: 'portfolio/:portfolio_id/set-filter-preference-v2',
        pathParams: {portfolio_id: portfolioId},
    })

    const sortMutation = useRetailPost({
        path: 'portfolio/:portfolio_id/set-sort-preference-v2',
        pathParams: {portfolio_id: portfolioId},
    })

    const setFilterPreference = async (filterPreference: PortfolioFilterOptions) => {
        const payload: Request.PortfolioFilterPreferenceV2 = {
            filter_preference: filterPreference,
        }

        queryClient.setQueryData(['portfolio/:portfolio_id/get-filter-preference-v2', {portfolio_id: portfolioId}], {
            ...payload,
        })

        await filterMutation.mutateAsync(payload)
        await queryClient.invalidateQueries([
            'portfolio/:portfolio_id/get-filter-preference-v2',
            {portfolio_id: portfolioId},
        ])
    }

    const setSortPreference = async (sortPreference: PortfolioSortOptions) => {
        const payload: Request.PortfolioSortPreferenceV2 = {
            sort_preference: sortPreference,
        }
        queryClient.setQueryData(['portfolio/:portfolio_id/get-sort-preference-v2', {portfolio_id: portfolioId}], {
            ...payload,
        })

        await sortMutation.mutateAsync(payload)
        await queryClient.invalidateQueries([
            'portfolio/:portfolio_id/get-sort-preference-v2',
            {portfolio_id: portfolioId},
        ])
    }

    return {filterPreference, setFilter: setFilterPreference, sortPreference, setSort: setSortPreference}
}
