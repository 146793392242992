import {TextInput, TextProps} from '@design-system/text-input'
import React from 'react'
import {withFocus, withId} from '~/global/widgets/form-controls/common'

export function normalizeAUBankAccount(value: string, previousValue: string = ''): string {
    if (value) {
        if (value + ' ' === previousValue) {
            // User backspaced a space, we'll just remove the last character instead
            value = value.replace(/.$/, '')
        }

        // Replace unacceptable characters, including -
        value = value.replace(/\D/g, '')
        // Remove anything too long
        value = value.substring(0, 9)
    }
    return value
}

const AUBankAccount = (props: TextProps) => (
    <TextInput
        {...props}
        onChange={e => {
            if (!props.setFieldValue) {
                return
            }
            props.setFieldValue(normalizeAUBankAccount(e.currentTarget.value, props.value || ''))
        }}
    />
)

const BankAccountWithFocus = withId(withFocus(AUBankAccount))

export default BankAccountWithFocus
