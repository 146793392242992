import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {Model} from '~/api/retail/types'
import {dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {calculateAverageTransferPrice, isUSExchange} from '~/global/utils/share-transfers/shareTransfers'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {TransferOutFees, TransferInFees} from '~/global/widgets/help-modals/TransferFees'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {ShareValue, DollarValue, SharePriceValue} from '~/global/widgets/number-elements/NumberElements'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {useNavigate} from '~/migrate-react-router'
import CancelTransferOrderModal from '~/sections/invest/sections/transfer-shares/widgets/cancel-transfer-order-modal/CancelTransferOrderModal'
import {Instrument} from '~/store/instrument/types'
import {NEW_DRS_REQUIRED, NEW_SRN_REQUIRED} from '~/store/transfer/types'
import {RecordRow} from './common'
import styles from './Record.scss'
interface OwnProps {
    instrument: Instrument
    transfer: Model.TransferOrderIn | Model.TransferOrderOut
    hideStateBadge?: boolean
    hideCancelButton?: boolean
    useStateBadgeColours?: boolean
}

const InstrumentActivityRecordTransfer: React.FunctionComponent<OwnProps> = React.memo(
    ({transfer, instrument, hideStateBadge, hideCancelButton, useStateBadgeColours}) => {
        const currency = instrument.currency
        const navigate = useNavigate()
        const orderAmount = <DollarValue value={transfer.total_transfer_value || '0'} currency={currency} />
        const averagePriceData = calculateAverageTransferPrice(transfer)
        const profileUrl = useProfileUrl()

        const updatePriceUrl = 'invest/portfolio-transfer-shares/orders/:instrumentSlug/:orderId/update-price'
        return (
            <div className={styles.orderContent}>
                <div className={styles.orderTitleBlock}>
                    <div className={styles.orderThumb}>
                        <InstrumentLogo instrument={instrument} noBorder />
                    </div>
                    <div>
                        <h4 className={styles.instrumentName}>{instrument.name}</h4>
                        {tradingType(instrument) !== 'managed' && !isInstrumentInNoTrade(instrument) && (
                            <p className={styles.instrumentSymbol}>
                                <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                                <PronounceLetters text={instrument.exchange} />
                            </p>
                        )}
                    </div>
                </div>
                <div className={styles.recordRowContainer}>
                    <RecordRow left="Transfer requested" right={transfer.created.toFormat(dateFormatWithYear)} />
                    {transfer.state === 'cancelled' && transfer.cancelled_at && (
                        <RecordRow
                            left="Transfer cancelled"
                            right={transfer.cancelled_at.toFormat(dateFormatWithYear)}
                        />
                    )}
                    {transfer.state === 'rejected' && transfer.rejected_at && (
                        <RecordRow left="Transfer failed" right={transfer.rejected_at.toFormat(dateFormatWithYear)} />
                    )}
                    {transfer.state === 'complete' && transfer.complete_at && (
                        <RecordRow
                            left="Transfer completed"
                            right={transfer.complete_at.toFormat(dateFormatWithYear)}
                        />
                    )}
                    {transfer.reference_type && (
                        <RecordRow
                            left={transfer.reference_type === 'CSN' ? 'CSN/HN' : transfer.reference_type}
                            right={
                                transfer.reference === NEW_SRN_REQUIRED || transfer.reference === NEW_DRS_REQUIRED
                                    ? ''
                                    : transfer.reference
                            }
                        />
                    )}
                    {transfer.reference_type && transfer.reference_type === 'HIN' && (
                        <RecordRow left="PID" right={transfer.pid} />
                    )}
                    <RecordRow
                        left={transfer.state === 'complete' ? 'Shares transferred' : 'Shares to transfer'}
                        right={
                            transfer.transfer_all_shares ? (
                                'All'
                            ) : (
                                <ShareValue value={transfer.shares || '0'} showFullValue />
                            )
                        }
                    />
                    {transfer.type === 'transfer_in' && (
                        <>
                            <RecordRow
                                left="Average price paid per share"
                                right={<SharePriceValue value={averagePriceData.price} currency={currency} />}
                            />
                        </>
                    )}
                    {transfer.type === 'transfer_out' && (
                        <>
                            <RecordRow
                                left={
                                    ['cancelled', 'rejected', 'complete'].includes(transfer.state)
                                        ? 'Price per share'
                                        : 'Estimated price per share'
                                }
                                right={
                                    <SharePriceValue
                                        value={
                                            transfer.share_price_at_transfer
                                                ? transfer.share_price_at_transfer
                                                : transfer.estimated_share_price_at_transfer
                                                  ? transfer.estimated_share_price_at_transfer
                                                  : 0
                                        }
                                        currency={currency}
                                    />
                                }
                            />
                        </>
                    )}
                    {['new', 'processing', 'complete'].includes(transfer.state) && (
                        <>
                            {transfer.type === 'transfer_out' && !isUSExchange(transfer.exchange) && (
                                <RecordRow
                                    left={
                                        <>
                                            Transfer fees charged by Sharesies{' '}
                                            <TransferOutFees exchange={transfer.exchange} />
                                        </>
                                    }
                                    right={<DollarValue value={transfer.fee_amount} currency={transfer.fee_currency} />}
                                />
                            )}
                            {transfer.type === 'transfer_out' &&
                                isUSExchange(transfer.exchange) &&
                                transfer.us_transfer_broker === 'EXTERNAL' && (
                                    <RecordRow
                                        left={
                                            <>
                                                Transfer fees charged by Sharesies{' '}
                                                <TransferOutFees
                                                    exchange={transfer.exchange}
                                                    platform={transfer.us_transfer_broker}
                                                />
                                            </>
                                        }
                                        right={
                                            <DollarValue value={transfer.fee_amount} currency={transfer.fee_currency} />
                                        }
                                    />
                                )}
                            {transfer.type === 'transfer_in' && !isUSExchange(transfer.exchange) && (
                                <div className={styles.recordRowSingleCol}>
                                    There are no transfer fees when transferring shares in
                                </div>
                            )}
                            {transfer.type === 'transfer_in' &&
                                isUSExchange(transfer.exchange) &&
                                transfer.us_transfer_broker === 'EXTERNAL' && (
                                    <RecordRow
                                        left={
                                            <>
                                                Transfer fees charged by Sharesies{' '}
                                                <TransferInFees
                                                    exchange={transfer.exchange}
                                                    platform={transfer.us_transfer_broker}
                                                />
                                            </>
                                        }
                                        right={
                                            <DollarValue
                                                value={transfer.fee_amount || 0}
                                                currency={transfer.fee_currency}
                                            />
                                        }
                                    />
                                )}
                        </>
                    )}
                </div>
                {transfer.transfer_all_shares === false && (
                    <>
                        <p className={styles.orderAmountLabel}>
                            {transfer.state === 'complete' ? 'Amount' : 'Estimated amount'}
                        </p>
                        <div className={styles.orderFooter}>
                            <div>
                                {!hideStateBadge && (
                                    <p
                                        className={cn(
                                            styles.statusLabel,
                                            transfer.state === 'complete' && useStateBadgeColours && styles.complete,
                                            transfer.state === 'rejected' && useStateBadgeColours && styles.failed,
                                        )}
                                        data-testid="transferStatusLabel"
                                    >
                                        {transfer.state in ['new', 'processing', 'submitted']
                                            ? 'Pending'
                                            : transfer.state === 'rejected'
                                              ? 'Failed'
                                              : transfer.state[0].toUpperCase() + transfer.state.slice(1)}
                                    </p>
                                )}
                            </div>
                            <div>
                                <p className={styles.orderAmount}>{orderAmount}</p>
                            </div>
                        </div>
                    </>
                )}
                <div className={styles.buttonContainer}>
                    {transfer.type === 'transfer_in' &&
                        !['cancelled', 'rejected'].includes(transfer.state) &&
                        transfer.exchange === 'NZX' && (
                            <div>
                                <Button
                                    type="secondary"
                                    dataTestId="button--edit-price"
                                    label="Edit price"
                                    onClick={() => {
                                        navigate(
                                            profileUrl(updatePriceUrl, {
                                                instrumentSlug: instrument.urlSlug,
                                                orderId: transfer.id,
                                            }),
                                        )
                                    }}
                                />
                            </div>
                        )}
                    {['new', 'incomplete'].includes(transfer.state) && !hideCancelButton && (
                        <div>
                            <CancelTransferOrderModal
                                orderId={transfer.id}
                                groupId={transfer.group_id}
                                fundId={instrument.id}
                                fundName={instrument.name}
                            />
                        </div>
                    )}
                </div>
            </div>
        )
    },
)

export default InstrumentActivityRecordTransfer
