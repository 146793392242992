import {Button} from '@design-system/button'
import {Form, Formik} from 'formik'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import {Response} from '~/api/retail/types'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {
    bankAccountNameError,
    bankAccountNameRegex,
    bankNumberError,
    bankNumberRegex,
    bsbError,
    bsbRegex,
} from '~/global/utils/bank-account-validators/bankAccountValidators'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {validate, ErrorBox} from '~/global/widgets/form-controls'
import {normalizeAUBankAccount} from '~/global/widgets/form-controls/bank-account-au/AUBankAccount'
import {normalizeNZBankAccount} from '~/global/widgets/form-controls/bank-account-nz/NZBankAccount'
import {normalizeBankBSB} from '~/global/widgets/form-controls/bank-bsb/BankBSB'
import {NZBankAccount, AUBankAccount, Text, BankBSB} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'

interface EditBankAccountFormValues {
    bank_account_number: string
    bank_account_name: string
    bank_account_bsb: string
}

interface EditBankAccountProps {
    bank_account_id?: string
}

const EditBankAccount = (props: EditBankAccountProps) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const owner = useProfileOwner()

    const accountData = useRetailGet({
        path: 'owner/:owner_id/bank-accounts',
        pathParams: {owner_id: owner.id},
    }).data

    const accounts: (
        | Response.OwnerBankAccounts['au_accounts'][number]
        | Response.OwnerBankAccounts['nz_accounts'][number]
    )[] = [...accountData.au_accounts, ...accountData.nz_accounts]
    const account = accounts.find(acc => acc.id === props.bank_account_id)

    const cleanedAccountNumber = (values: EditBankAccountFormValues) => {
        if (owner.product_jurisdiction !== 'nz') {
            return values.bank_account_number.replace(/\D/g, '')
        }
        return values.bank_account_number.replace(/\s*/g, '')
    }

    const addNzBankAccount = useRetailPost({
        path: 'owner/:owner_id/bank-accounts/add',
        pathParams: {owner_id: owner.id},
        queryCacheToUpdate: [`owner/:owner_id/bank-accounts`, {owner_id: owner.id}],
    })

    const addAuBankAccount = useRetailPost({
        path: 'owner/:owner_id/au-bank-accounts/add',
        pathParams: {owner_id: owner.id},
        queryCacheToUpdate: [`owner/:owner_id/bank-accounts`, {owner_id: owner.id}],
    })

    const updateNzBankAccount = useRetailPost({
        path: 'owner/:owner_id/bank-accounts/update',
        pathParams: {owner_id: owner.id},
        queryCacheToUpdate: [`owner/:owner_id/bank-accounts`, {owner_id: owner.id}],
    })

    const updateAuBankAccount = useRetailPost({
        path: 'owner/:owner_id/au-bank-accounts/update',
        pathParams: {owner_id: owner.id},
        queryCacheToUpdate: [`owner/:owner_id/bank-accounts`, {owner_id: owner.id}],
    })

    return (
        <>
            <Toolbar
                dataTestId="toolbar--edit-bank-account"
                leftButton="back"
                title={`${account ? 'Edit' : 'Add a'} bank account`}
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <Formik
                    initialValues={{
                        bank_account_number: account
                            ? owner.product_jurisdiction === 'nz'
                                ? normalizeNZBankAccount(account.number)
                                : normalizeAUBankAccount(account.number)
                            : '',
                        bank_account_name: account?.name ?? '',
                        bank_account_bsb: account && 'bsb' in account ? normalizeBankBSB(account.bsb) : '',
                    }}
                    validate={values => {
                        const nzSchema = {
                            bank_account_number: [
                                validate.required(),
                                validate.regexp(
                                    bankNumberRegex(owner.product_jurisdiction),
                                    bankNumberError(owner.product_jurisdiction),
                                ),
                            ],
                            bank_account_name: [
                                validate.required(),
                                validate.regexp(bankAccountNameRegex(), bankAccountNameError()),
                            ],
                            bank_account_bsb: [],
                        }
                        const auSchema = {
                            ...nzSchema,
                            bank_account_bsb: [validate.required(), validate.regexp(bsbRegex, bsbError)],
                        }

                        return validate.generate<EditBankAccountFormValues>(
                            owner.product_jurisdiction === 'au' ? auSchema : nzSchema,
                        )(values)
                    }}
                    onSubmit={async (values, {setFieldError}) => {
                        try {
                            if (!account) {
                                if (owner.product_jurisdiction === 'nz') {
                                    await addNzBankAccount.mutateAsync({
                                        bank_account_name: values.bank_account_name,
                                        bank_account_number: values.bank_account_number,
                                    })
                                } else {
                                    await addAuBankAccount.mutateAsync({
                                        bank_account_name: values.bank_account_name,
                                        bank_account_number: values.bank_account_number,
                                        bank_account_bsb: values.bank_account_bsb,
                                    })
                                }
                            } else {
                                if (owner.product_jurisdiction === 'nz') {
                                    await updateNzBankAccount.mutateAsync({
                                        bank_account_id: account.id,
                                        bank_account_name: values.bank_account_name,
                                        bank_account_number: values.bank_account_number,
                                    })
                                } else {
                                    await updateAuBankAccount.mutateAsync({
                                        bank_account_id: account.id,
                                        bank_account_name: values.bank_account_name,
                                        bank_account_number: values.bank_account_number,
                                        bank_account_bsb: values.bank_account_bsb,
                                    })
                                }
                            }
                        } catch (e: any) {
                            if (e.type === 'form_errors') {
                                setFieldError('bank_account_number', e.errors.bank_account_number)
                                return
                            }
                            throw e
                        }

                        Toast(`Bank account ${account ? 'updated' : 'added'}`)
                        navigate(profileUrl('settings/bank-accounts-cards'))
                    }}
                >
                    {({isSubmitting, isValid, values, status}) => (
                        <Form>
                            <Text
                                dataTestId="text-input--name-on-the-account"
                                name="bank_account_name"
                                label="Name on the account"
                                disabled={isSubmitting}
                            />
                            {owner.product_jurisdiction === 'au' && (
                                <BankBSB
                                    dataTestId="text-input--bank-bsb-number"
                                    name="bank_account_bsb"
                                    label="BSB number"
                                    disabled={isSubmitting}
                                />
                            )}
                            {owner.product_jurisdiction === 'nz' && (
                                <NZBankAccount
                                    dataTestId="text-input--nz-bank-account-number"
                                    name="bank_account_number"
                                    label="Account number"
                                    disabled={isSubmitting}
                                />
                            )}
                            {owner.product_jurisdiction === 'au' && (
                                <AUBankAccount
                                    dataTestId="text-input--au-bank-account-number"
                                    name="bank_account_number"
                                    label="Account number"
                                    disabled={isSubmitting}
                                />
                            )}

                            <ErrorBox message={status} />
                            <Button
                                label="Save"
                                disabled={
                                    // if the values haven't changed for either bank number or name, don't allow user to submit.
                                    !isValid ||
                                    (account &&
                                        cleanedAccountNumber(values) === account.number &&
                                        values.bank_account_name === account.name)
                                }
                                processing={isSubmitting}
                                isSubmit
                                pageButton
                                dataTestId="button--save-bank-account"
                            />
                        </Form>
                    )}
                </Formik>
            </Page>
        </>
    )
}

export default EditBankAccount
