import {Button} from '@design-system/button'
import * as React from 'react'
import {setGlobalStyleVHVariable} from '~/global/utils/set-global-style-vh-variable/setGlobalStyleVHVariable'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import styles from './GoogleDriveDocumentViewer.scss'

interface Props {
    title: string
    subTitle?: string | React.ReactFragment
    buttonLabel?: string
    isButtonLoading?: boolean
    onButtonClick?: () => void
    documentUrl: string
    dataTestId?: string
}

/**
 * This renders a full screen embedded Google Drive document. In order to not have any funky looking
 * padding issues, render this component inside `<Page withoutDefaultPadding>` and use a `PageBack` for any
 * back action intead of Toolbar (to keep the height contained properly).
 */
const GoogleDriveDocumentViewer: React.FunctionComponent<Props> = ({
    title,
    subTitle,
    buttonLabel,
    isButtonLoading,
    onButtonClick,
    documentUrl,
    dataTestId,
}) => {
    React.useEffect(() => {
        setGlobalStyleVHVariable()
        // update on browser resize
        window.addEventListener('resize', setGlobalStyleVHVariable)

        return () => {
            window.removeEventListener('resize', setGlobalStyleVHVariable)
            // remove custom property from document root
            document.documentElement.style.removeProperty('--vh')
        }
    }, [])

    return (
        <div className={styles.pdsWrapper} data-testid="pds">
            <h1>{title}</h1>
            {subTitle && <h4>{subTitle}</h4>}

            <iframe src={documentUrl} />
            {buttonLabel && (
                <ActionBar>
                    <Button
                        dataTestId={dataTestId || 'button--google-drive-document'}
                        label={buttonLabel}
                        onClick={onButtonClick}
                        processing={isButtonLoading}
                    />
                </ActionBar>
            )}
        </div>
    )
}

export default GoogleDriveDocumentViewer
