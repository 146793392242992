import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/invest/sections/bank-linking/BankLinking.scss'
import actions from '~/store/bankLinking/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {OnNextProps} from '../RoundupsSetup'

const ReadyFromAccountsAndCards = ({onBack, onNext}: OnNextProps) => {
    const dispatch = useAppDispatch()
    const {depositTarget, isRoundupsEnabled, roundupsDebitAccount} = useAppSelector(({bankLinking}) => ({
        depositTarget: bankLinking.depositTarget,
        isRoundupsEnabled: bankLinking.isRoundupsEnabled,
        roundupsDebitAccount: bankLinking.roundupsDebitAccount,
    }))
    const feeForTarget = (+depositTarget * 0.02).toFixed(2)

    React.useEffect(() => {
        if (!isRoundupsEnabled) {
            dispatch(actions.UpdateRoundupsEnabled(true))
        }
    })
    return (
        <>
            <div>
                <Toolbar
                    dataTestId="toolbar--setup-from-bankaccounts-cards-complete"
                    leftButton="back"
                    onLeftButtonClick={onBack}
                />
            </div>
            <Page>
                <h1 className={cn(styles.h1, spacing.spaceBelow20)}>Where we’ll take the money from</h1>
                <p className={spacing.spaceBelow24}>
                    When your rounded-up amounts reach ${depositTarget}, it’ll automatically be sent as a single top-up
                    from just one account (instead of coming out in bits and pieces from all the accounts we’re
                    tracking). Each transfer will incur a 2% fee, or ${feeForTarget}!
                </p>
                <p className={spacing.spaceBelow24}>
                    We’ll deduct the money from your {roundupsDebitAccount?.accountName}.
                </p>
                <p>
                    You can change this at any time in <b>Settings {'>'} Accounts and cards</b>.
                </p>
            </Page>
            <ActionBar>
                <Button dataTestId="button--next" label="Next" onClick={onNext} />
            </ActionBar>
        </>
    )
}

export default ReadyFromAccountsAndCards
