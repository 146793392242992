import {Button} from '@design-system/button'
import {HelpCircle, Success, Transfer} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import HelpCentreLink from '~/global/widgets/help-centre-link/HelpCentreLink'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {useAppSelector} from '~/store/hooks'

const Intro: React.FunctionComponent = () => {
    const profileUrl = useProfileUrl()

    const {stagedTransferOrder, registryDetails} = useAppSelector(({transfer}) => transfer)

    const navigate = useNavigate()

    const missingIrdDetails = useAppSelector(
        ({identity}) => identity.user!.jurisdiction === 'nz' && !identity.user!.ird_number,
    )

    if (!stagedTransferOrder || !stagedTransferOrder.direction) {
        navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})
        return <></>
    }

    const inboundTransfer = stagedTransferOrder.direction === 'in'

    const hasPreviousCsn =
        registryDetails.filter(
            registryDetail =>
                ['CSN', 'REQUEST_CSN'].includes(registryDetail.reference_type) &&
                ['approved', 'pending', 'incomplete'].includes(registryDetail.state),
        ).length > 0

    const navigateToNextPage = () => {
        if (hasPreviousCsn) {
            // If the Investor has one or more linked CSNs already, show them the Choose/New CSN screen
            navigate(profileUrl('invest/portfolio-transfer-shares/nzx/choose-csn'))
        } else {
            // If they don't have a linked CSN, take them to the T&Cs
            navigate(profileUrl('invest/portfolio-transfer-shares/nzx/terms-and-conditions'))
        }
    }

    return (
        <>
            <Toolbar leftButton="back" dataTestId="transfers--intro" />
            <Page>
                <h1 className={cn(spacing.spaceBelow32, commonStyles.heading)}>
                    Some things to know about transferring shares
                </h1>
                <p>
                    You’ll need a couple of unique identifiers to transfer shares {inboundTransfer ? 'into' : 'out of'}{' '}
                    Sharesies:
                </p>
                <ul>
                    <li>
                        a Common Shareholder Number (CSN)
                        {inboundTransfer && ' or Holder Number (HN)'}, and
                    </li>
                    <li>a Faster Identification Number (FIN).</li>
                </ul>
                {!inboundTransfer && <p>Transfers can’t be made to a Holder Number (HN).</p>}
                {missingIrdDetails && (
                    <p>
                        You’ll also need to add your IRD number as we manage resident withholding tax (RWT) and
                        imputation credits for your investments.
                    </p>
                )}
                <div className={commonStyles.infoRow}>
                    <div className={commonStyles.infoRowImage}>
                        <Success size={16} />
                    </div>
                    <div>
                        <h2>Joint CSN{inboundTransfer && ' or HN'} holders</h2>
                        <p>
                            Transfers {inboundTransfer ? 'from' : 'to'} a jointly held CSN{inboundTransfer && ' or HN'}{' '}
                            need to be approved by all joint holders. To approve the transfer, they’ll need a Sharesies
                            account.
                        </p>
                    </div>
                </div>
                <div className={commonStyles.infoRow}>
                    <div className={commonStyles.infoRowImage}>
                        <Transfer size={16} />
                    </div>
                    <div>
                        <h2>Transfer processing times</h2>
                        <p>
                            Transfers can take up to 2 business days. Once a transfer has been completed, we’ll send you
                            an email.
                        </p>
                    </div>
                </div>
                <div className={commonStyles.infoRow}>
                    <div className={commonStyles.infoRowImage}>
                        <HelpCircle size={16} />
                    </div>
                    <div>
                        <h2>Missing an identifier?</h2>
                        <p className={spacing.spaceBelow16}>
                            If you don’t have a CSN{inboundTransfer && '/HN '} or FIN, or if you’re having trouble
                            finding these identifiers,{' '}
                            <HelpCentreLink
                                nzArticle="3683575-transfer-shares-info-you-need-to-provide"
                                auArticle="6950839-info-needed-to-transfer-shares"
                            >
                                check out our help centre
                            </HelpCentreLink>
                            .
                        </p>
                    </div>
                </div>
            </Page>
            <ActionBar>
                <Button dataTestId="button--next" onClick={() => navigateToNextPage()} label="Continue" />
            </ActionBar>
        </>
    )
}

export default Intro
