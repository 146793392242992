import {Button} from '@design-system/button'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import Image from '~/global/widgets/image/Image'
import styles from '~/sections/user/sections/sign-up/SignUp.scss'
import idDark from '~/sections/user/sections/sign-up/sections/identity/sections/other-identity-details/assets/images/id-dark.svg'
import idLight from '~/sections/user/sections/sign-up/sections/identity/sections/other-identity-details/assets/images/id-light.svg'
import {Mode} from '../identity-check/util'
import {getNextStep, ManualIdentityStep} from './util'

const NZAccesscard: React.FunctionComponent<NZAccessCardProps> = ({mode, step, setStep}) => {
    return (
        <>
            <Image src={idLight} darkSrc={idDark} className={styles.driverslicence} />
            <h1 className={spacing.spaceBelow16}>Got your Kiwi Access Card?</h1>
            <p>
                You’ll need to send us a copy of your Kiwi Access Card along with your certified ID. You don’t need to
                get the copy of your Kiwi Access Card certified.
            </p>
            <Button
                additionalClassName={styles.accessButton}
                dataTestId=""
                label="Got it"
                onClick={() => setStep(getNextStep(step, mode))}
                pageButton
            />
        </>
    )
}

interface NZAccessCardProps {
    setStep(step: ManualIdentityStep): void
    mode: Mode
    setMode(mode: Mode): void
    step: ManualIdentityStep
    className?: string
}

export default NZAccesscard
