import {ArrowRight} from '@design-system/icon'
import React from 'react'
import styles from './WithArrowRight.scss'

export const WithArrowRight = ({children}: {children: React.ReactChild}) => (
    <p className={styles.withArrowRight}>
        <span>{children}</span>
        <ArrowRight />
    </p>
)
