import {Modal} from '@design-system/modal'
import cn from 'classnames'
import React from 'react'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {SharesiesOmit} from '~/global/utils/type-utilities/typeUtilities'
import Animation, {getAnimationData} from '~/global/widgets/lottie-animation/Animation'
import styles from './NotificationProvider.scss'

export interface NotificationModalProps {
    title?: string
    message: React.ReactNode
    primaryButton?: {
        label: string
        onClick: () => void
    }
    secondaryButton?: {
        label: string
        onClick: () => void
    }
    onClose?: () => void
}

export interface NotificationContextProps {
    showRegularInvestorNotification(): Promise<void>
    showFirstTimeInvestorNotification(): Promise<void>
    showAutoinvestNotification(): Promise<void>
    showFullscreenNotification(message: React.ReactNode, opt?: {duration?: number}): Promise<void>
    showTopupGoalSuccessScreen(): Promise<void>
    showDonationThanksScreen(): Promise<void>
    showModalInfo(props: NotificationModalProps): void
    showModalAlert(props: NotificationModalProps): void
    showModalError(props: NotificationModalProps): void
    closeModalInfo(): void
    closeModalAlert(): void
    closeModalError(): void
}

const defaultProviderFunction = () => {
    throw new Error("NotificationProvider doesn't exist")
}

export const NotificationContext = React.createContext<NotificationContextProps>({
    showRegularInvestorNotification: defaultProviderFunction,
    showFirstTimeInvestorNotification: defaultProviderFunction,
    showAutoinvestNotification: defaultProviderFunction,
    showFullscreenNotification: defaultProviderFunction,
    showTopupGoalSuccessScreen: defaultProviderFunction,
    showDonationThanksScreen: defaultProviderFunction,
    showModalInfo: defaultProviderFunction,
    closeModalInfo: defaultProviderFunction,
    showModalAlert: defaultProviderFunction,
    closeModalAlert: defaultProviderFunction,
    showModalError: defaultProviderFunction,
    closeModalError: defaultProviderFunction,
})

export const withNotification =
    <P extends NotificationContextProps>(
        Component: React.ComponentType<P>,
    ): React.ComponentType<SharesiesOmit<P, NotificationContextProps>> =>
    props => (
        <NotificationContext.Consumer>
            {context => <Component {...(props as any)} {...context} />}
        </NotificationContext.Consumer>
    )

interface AnimatedNotificationData {
    type: 'generic'
    text: React.ReactNode
    data: object
    outAfter: number
}

interface LegacyNotificationData {
    type: 'legacy'
    text: React.ReactNode
    outAfter: number
}

type NotificationData = AnimatedNotificationData | LegacyNotificationData

const NotificationProvider: React.FunctionComponent = ({children}) => {
    const [animate, setAnimate] = React.useState(false)
    const [animateOut, setAnimateOut] = React.useState(false)
    const [notificationData, setNotificationData] = React.useState<NotificationData | undefined>(undefined)
    const [modalInfo, setModalInfo] = React.useState<React.ReactNode>(null)
    const [modalInfoTitle, setModalInfoTitle] = React.useState<string | null>(null)
    const [modalAlert, setModalAlert] = React.useState<React.ReactNode>(null)
    const [modalAlertTitle, setModalAlertTitle] = React.useState<string | null>(null)
    const [modalError, setModalError] = React.useState<React.ReactNode>(null)
    const [modalErrorTitle, setModalErrorTitle] = React.useState<string | null>(null)
    const [optionalCloseFunction, setOptionalCloseFunction] = React.useState<(() => void) | null>(null)
    const [primaryButton, setPrimaryButton] = React.useState<{label: string; onClick: () => void} | null>(null)
    const [secondaryButton, setSecondaryButton] = React.useState<{label: string; onClick: () => void} | null>(null)

    // define body tag
    const bodyTag = document.querySelector('body')

    const context: NotificationContextProps = {
        async showRegularInvestorNotification() {
            const data = await getAnimationData('regularInvestor')
            setNotificationData({
                type: 'generic',
                text: <strong className={styles.centered}>Placing your order</strong>,
                outAfter: 3000,
                data,
            })
            await new Promise(resolve => setTimeout(resolve, 1000)) // Allow time for the animation to start
        },
        async showFirstTimeInvestorNotification() {
            const data = await getAnimationData('firstTimeInvestor')
            setNotificationData({
                type: 'generic',
                text: <strong className={styles.centered}>Placing your first order</strong>,
                outAfter: 3500,
                data,
            })
            await new Promise(resolve => setTimeout(resolve, 1000)) // Allow time for the animation to start
        },
        async showAutoinvestNotification() {
            const data = await getAnimationData('autoInvestor')
            setNotificationData({
                type: 'generic',
                text: <strong className={styles.centered}>Setting up your auto-invest order</strong>,
                outAfter: 3500,
                data,
            })
            await new Promise(resolve => setTimeout(resolve, 1000)) // Allow time for the animation to start
        },
        async showTopupGoalSuccessScreen() {
            const data = await getAnimationData('topupGoal')
            setNotificationData({
                type: 'generic',
                text: <strong className={styles.centered}>Creating your money goal</strong>,
                outAfter: 3500,
                data,
            })
            await new Promise(resolve => setTimeout(resolve, 1000)) // Allow time for the animation to start
        },
        async showDonationThanksScreen() {
            const data = await getAnimationData('donation')
            setNotificationData({
                type: 'generic',
                text: null,
                outAfter: 2500,
                data,
            })
            await new Promise(resolve => setTimeout(resolve, 1000)) // Allow time for the animation to start
        },
        async showFullscreenNotification(text, userOpt) {
            const opt = {
                duration: 2000,
                ...userOpt,
            }
            setNotificationData({
                type: 'legacy',
                text,
                outAfter: opt.duration,
            })
            await new Promise(resolve => setTimeout(resolve, 1000)) // Allow time for the animation to start
        },
        showModalInfo({message, onClose, primaryButton, secondaryButton, title}) {
            setModalInfo(message)
            if (title) {
                setModalInfoTitle(title)
            }
            if (primaryButton) {
                setPrimaryButton(primaryButton)
            }
            if (secondaryButton) {
                setSecondaryButton(secondaryButton)
            }
            if (onClose) {
                setOptionalCloseFunction(onClose)
            } else {
                setOptionalCloseFunction(null)
            }
        },
        closeModalInfo() {
            setModalInfo(null)
        },
        showModalAlert({message, onClose, primaryButton, secondaryButton, title}) {
            setModalAlert(message)
            if (title) {
                setModalAlertTitle(title)
            }
            if (primaryButton) {
                setPrimaryButton(primaryButton)
            }
            if (secondaryButton) {
                setSecondaryButton(secondaryButton)
            }
            if (onClose) {
                setOptionalCloseFunction(onClose)
            } else {
                setOptionalCloseFunction(null)
            }
        },
        closeModalAlert() {
            setModalAlert(null)
        },
        showModalError({message, onClose, primaryButton, secondaryButton, title}) {
            setModalError(message)
            if (title) {
                setModalErrorTitle(title)
            }
            if (primaryButton) {
                setPrimaryButton(primaryButton)
            }
            if (secondaryButton) {
                setSecondaryButton(secondaryButton)
            }
            // onClose function is given, assign it or set it back to null
            if (onClose) {
                setOptionalCloseFunction(onClose)
            } else {
                setOptionalCloseFunction(null)
            }
        },
        closeModalError() {
            setModalError(null)
        },
    }

    React.useEffect(() => {
        if (!notificationData) {
            return
        }
        setAnimate(true)
        // removes scrollbars when animating
        if (bodyTag) {
            bodyTag.style.overflow = 'hidden'
            bodyTag.style.height = '100vh'
        }
        const outTimer = setTimeout(() => {
            setAnimateOut(true)
        }, notificationData.outAfter)
        const removeTimer = setTimeout(() => {
            setNotificationData(undefined)
            setAnimate(false)
            setAnimateOut(false)
            // shows scrollbars when animation has finished
            if (bodyTag) {
                bodyTag.style.overflow = ''
                bodyTag.style.height = ''
            }
        }, notificationData.outAfter + 300) // Remove the DOM node after it's completely faded out
        return () => {
            clearTimeout(outTimer)
            clearTimeout(removeTimer)
        }
    }, [notificationData])

    let animationClass: string = ''
    let topClass: string | undefined

    if (notificationData) {
        switch (notificationData.type) {
            case 'generic':
                animationClass = styles.animationGeneric
                break
            case 'legacy':
                break
            default:
                assertNever(notificationData)
        }
    }

    return (
        <NotificationContext.Provider value={context}>
            {notificationData && notificationData.type === 'legacy' ? (
                <div
                    className={cn(styles.legacy, {
                        [styles.animate]: animate,
                        [styles.animateOut]: animateOut,
                    })}
                >
                    {notificationData.text}
                </div>
            ) : notificationData ? (
                <div
                    className={cn(styles.background, topClass, {
                        [styles.animate]: animate,
                        [styles.animateOut]: animateOut,
                    })}
                >
                    <div className={styles.ripple} />
                    <div className={styles.text}>{notificationData.text}</div>
                    {notificationData.data && (
                        <Animation animationData={notificationData.data} className={animationClass} />
                    )}
                </div>
            ) : undefined}
            {/* Info modal.  Used to display information only.  No data changes should result from user input. */}
            <Modal
                isOpen={!!modalInfo}
                setIsOpen={_ => {
                    // The `_` argument will be called as false, but we don't need to use it in this case
                    // Normally we'd just be passing in setModalOpen (setIsOpen={setModalOpen}), but because we need to run a side effect
                    // we need to directly set the value to false, and set the alert value to `null`
                    setModalInfo(null)
                    if (optionalCloseFunction) {
                        optionalCloseFunction()
                    }
                }}
                title={modalInfoTitle ? modalInfoTitle : 'Info'}
                dataTestId="modal--generic-alert"
                primaryButton={primaryButton ? {label: primaryButton.label, onClick: primaryButton.onClick} : undefined}
                secondaryButton={
                    secondaryButton ? {label: secondaryButton.label, onClick: secondaryButton.onClick} : undefined
                }
            >
                {!!modalInfo && modalInfo}
            </Modal>
            {/* Alert modal. Used for confirming potentially destructive data changes */}
            <Modal
                isAlert
                isOpen={!!modalAlert}
                setIsOpen={_ => {
                    // The `_` argument will be called as false, but we don't need to use it in this case
                    // Normally we'd just be passing in setModalOpen (setIsOpen={setModalOpen}), but because we need to run a side effect
                    // we need to directly set the value to false, and set the alert value to `null`
                    setModalAlert(null)
                    if (optionalCloseFunction) {
                        optionalCloseFunction()
                    }
                }}
                title={modalAlertTitle ? modalAlertTitle : 'Alert'}
                dataTestId="modal--generic-alert"
                primaryButton={primaryButton ? {label: primaryButton.label, onClick: primaryButton.onClick} : undefined}
                secondaryButton={
                    secondaryButton ? {label: secondaryButton.label, onClick: secondaryButton.onClick} : undefined
                }
            >
                {!!modalAlert && modalAlert}
            </Modal>
            {/* Error modal.  Used when bad things happen */}
            <Modal
                isAlert
                isOpen={!!modalError}
                setIsOpen={_ => {
                    // The `_` argument will be called as false, but we don't need to use it in this case
                    // Normally we'd just be passing in setModalOpen (setIsOpen={setModalOpen}), but because we need to run a side effect
                    // we need to directly set the value to false, and set the alert value to `null`
                    setModalError(null)
                    if (optionalCloseFunction) {
                        optionalCloseFunction()
                    }
                }}
                title={modalErrorTitle ? modalErrorTitle : 'Error'}
                dataTestId="modal--generic-error"
                primaryButton={primaryButton ? {label: primaryButton.label, onClick: primaryButton.onClick} : undefined}
                secondaryButton={
                    secondaryButton ? {label: secondaryButton.label, onClick: secondaryButton.onClick} : undefined
                }
            >
                {!!modalError && modalError}
            </Modal>
            {children}
        </NotificationContext.Provider>
    )
}

export default NotificationProvider
