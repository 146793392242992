import {DockableModal} from '@design-system/dockable-modal'
import {CheckSingle, Search} from '@design-system/icon'
import cn from 'classnames'
import Decimal from 'decimal.js'
import {DateTime} from 'luxon'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {useRetailGet} from '~/api/query/retail'
import {Response} from '~/api/retail/types'
import * as rollbar from '~/api/rollbar/rollbar'
import {accessibility} from '~/global/scss/helpers'
import {useWatchlist} from '~/global/state-hooks/retail/useWatchlist'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import InstrumentRow from '~/global/widgets/instrument-row/InstrumentRow'
import {SkeletonInstrumentRow} from '~/global/widgets/instrument-row/SkeletonInstrumentRow'
import TabControls from '~/global/widgets/tab-controls/TabControls'
import {Toast} from '~/global/widgets/toast/Toast'
import withMeasure, {MeasureProps} from '~/global/widgets/with-measure/WithMeasure'
import {Link, useNavigate} from '~/migrate-react-router'
import {getPriceChangePercentages} from '~/sections/explore/utils/get-watchlist-movements/getWatchlistMovements'
import {WatchlistContext, InvestTab} from '~/sections/explore/widgets/watchlist/state/WatchlistContext'
import {trackRemoveFromWatchlist} from '~/sections/explore/widgets/watchlist/utils/track-watchlist-actions/trackWatchlistActions'
import {PriceNotificationModal} from '~/sections/invest/widgets/price-notification-modal/PriceNotificationModal'
import {getPriceNotificationsQueryArgs} from '~/sections/user/sections/settings/sections/notifications/pages/price-notifications/utils/get-price-notifications-query-args/getPriceNotificationsQueryArgs'
import {useAppSelector, useAppDispatch} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import instrumentActions from '~/store/instrument/actions'
import styles from './Watchlist.scss'
export type ShowWatchlistBy = 'percentageChange' | 'priceNotification'

interface GetPrecentageChangeForProps {
    id: string
    watchlist: Response.WatchlistV3['watchlist']
    now: DateTime
}

export function getWatchlistMovementDetailsFor({watchlist, id, now}: GetPrecentageChangeForProps): string {
    const percentageMovement = watchlist.find(({fund_id}) => fund_id === id)

    if (!percentageMovement) {
        return 'No data available'
    }

    const created = percentageMovement.created

    if (!created) {
        return 'in the past year'
    }

    // "since June 23 2023"
    const monthsDiff = now.diff(created, 'months')
    if (monthsDiff.months > 1) {
        return `since added ${created.day} ${created.monthShort} ${created.year}`
    }

    // "since added 3d ago"
    const daysDiff = now.diff(created, 'days')
    if (daysDiff.days > 1) {
        return `since added ${Math.ceil(daysDiff.days)}d ago`
    }

    return 'since added today'
}

interface WatchlistProps extends MeasureProps {
    tabName: 'Watchlist' | 'Recently viewed'
}

const WATCHLIST_SHOW_LABELS: Record<ShowWatchlistBy, string> = {
    percentageChange: 'Price change since added',
    priceNotification: 'Price notifications set',
}

const Watchlist: React.FunctionComponent<WatchlistProps> = ({onMeasureRef, measuredWidth}) => {
    const [tabName, setTabName] = React.useState<InvestTab>('Watchlist')
    const [showWatchlistBy, setShowWatchlistBy] = React.useState<ShowWatchlistBy>('percentageChange')
    const [showWatchlistByModal, setShowWatchlistByModal] = React.useState(false)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const {watchlistInstrumentIds, removeFromWatchlist, watchlistItemsWithCreated} = useWatchlist()
    const [percentageMovementValues, setPercentageMovementValues] = React.useState<
        {instrumentId: string; percentageChange: Decimal}[]
    >([])

    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const portfolioId = useAppSelector(s => s.identity.user!.portfolio_id)

    const {data} = useRetailGet(getPriceNotificationsQueryArgs(portfolioId))
    const priceNotifications = data.price_notifications

    const currentTimePeriod = useAppSelector(s => s.instrument.currentSearchTimePeriod)
    const currentSort = useAppSelector(s => s.instrument.currentSearchSort)
    const recentlyViewedIndexFromRetail = useAppSelector(s => s.identity.user!.recent_searches)
    const recentlyViewedFunds = useAppSelector(s => s.instrument.recentlyViewedIndex)
    const recentlyViewedLoadingState = useAppSelector(s => s.instrument.recentlyViewedLoadingState)
    const userId = useAppSelector(s => s.identity.user?.id)

    const setRecentlyViewedInstrument = (instrumentId: string) =>
        dispatch(identityActions.SetSearchedFund(instrumentId))
    const setSearchAutofocus = (state: boolean) => dispatch(instrumentActions.SetSearchAutofocus(state))
    const executeClearSearchAndFilters = () => dispatch(instrumentActions.executeClearSearchAndFilters())
    const loadRecentlyViewedInstruments = (ids: string[]) =>
        dispatch(instrumentActions.loadInstrumentRecentlyViewed(ids))
    const loadWatchlistInstruments = (ids: string[]) => dispatch(instrumentActions.loadWatchlistInstruments(ids))

    const [showModalFor, setShowModalFor] = React.useState<{
        modal: 'instrumentMenu' | 'priceNotification' | null
        instrumentId: string | null
    }>({
        modal: null,
        instrumentId: null,
    })

    const editPriceNotification = (id: string | null) => {
        if (!id) {
            return
        }

        setShowModalFor({instrumentId: id, modal: 'priceNotification'})
    }

    const removeFromWatchList = (id: string | null) => {
        if (!id) {
            return
        }

        removeFromWatchlist(id)

        setShowModalFor({instrumentId: null, modal: null})

        Toast('Removed from Watchlist')

        trackRemoveFromWatchlist(instrumentsById[id].name)
    }

    const onShowWatchlistBy = (val: ShowWatchlistBy) => {
        setShowWatchlistBy(val)
        setShowWatchlistByModal(false)
    }

    const getPriceChangePercentagesWrapper = async () => {
        try {
            const priceChangePercentages = await getPriceChangePercentages(watchlistItemsWithCreated)
            setPercentageMovementValues(priceChangePercentages)
        } catch (error) {
            rollbar.sendError(`Watchlist: percentageMovementValuesById error - ${error}`, {
                userId,
            })
        }
    }

    React.useEffect(() => {
        // load all of the recently viewed instruments (when metadata is present)
        if (recentlyViewedIndexFromRetail && recentlyViewedIndexFromRetail.length > 0) {
            loadRecentlyViewedInstruments(recentlyViewedIndexFromRetail)
        }

        // load all of the watchlist instruments (when metadata is present)
        if (watchlistInstrumentIds.length > 0) {
            loadWatchlistInstruments(watchlistInstrumentIds)
            getPriceChangePercentagesWrapper()
        }
    }, [])

    const instrumentForPriceNotification = showModalFor.instrumentId
        ? instrumentsById[showModalFor.instrumentId]
        : undefined

    const priceNotification = instrumentForPriceNotification
        ? priceNotifications?.[instrumentForPriceNotification.id]
        : undefined

    const hasPriceNotification = !!priceNotification

    const hasRecentlyViewedInstruments = recentlyViewedIndexFromRetail && recentlyViewedIndexFromRetail.length > 0

    const now = DateTime.now().startOf('day')

    return (
        <WatchlistContext.Provider value={{setTabName}}>
            <TabControls
                tabs={[
                    {label: 'Watchlist', value: 'Watchlist'},
                    {label: 'Recently viewed', value: 'Recently viewed'},
                ]}
                currentTab={tabName}
                onChangeTab={setTabName}
            />
            {tabName === 'Watchlist' && (
                <>
                    {watchlistInstrumentIds.length > 0 ? (
                        <>
                            <div className={styles.watchlistControl}>
                                <span aria-label="Sort watchlist by">Show: </span>
                                <button
                                    onClick={() => setShowWatchlistByModal(true)}
                                    type="button"
                                    className={cn(accessibility.button, styles.buttonAsLink)}
                                >
                                    {WATCHLIST_SHOW_LABELS[showWatchlistBy]}
                                </button>
                            </div>

                            <div className={styles.tabSection} ref={onMeasureRef}>
                                <>
                                    {watchlistInstrumentIds.length > 0 && (
                                        <>
                                            {watchlistInstrumentIds.map((id: string, index: number) => {
                                                if (!(id in instrumentsById)) {
                                                    return
                                                }

                                                const priceNotificationForInstrumentRow = priceNotifications?.[id]

                                                const label = getWatchlistMovementDetailsFor({
                                                    watchlist: watchlistItemsWithCreated,
                                                    id,
                                                    now,
                                                })

                                                const percentageChange = percentageMovementValues.find(
                                                    percentageMovementValue =>
                                                        percentageMovementValue.instrumentId === id,
                                                )?.percentageChange

                                                return (
                                                    <InstrumentRow
                                                        key={`watchlist-${index}`}
                                                        instrument={instrumentsById[id]}
                                                        hideSortDisplay
                                                        currentTimePeriod={currentTimePeriod}
                                                        currentSort={currentSort}
                                                        priceNotification={priceNotificationForInstrumentRow}
                                                        showWatchlistButton={true}
                                                        watchlistMovementDetails={{
                                                            label,
                                                            percentageChange: percentageChange
                                                                ? percentageChange.toNumber()
                                                                : undefined,
                                                        }}
                                                        watchlistIsShowing={showWatchlistBy}
                                                        onClick={() => {
                                                            setRecentlyViewedInstrument(id)

                                                            Analytics.event({
                                                                category: 'Search',
                                                                action: 'Clicked watchlist results',
                                                                label: instrumentsById[id].name,
                                                                nonInteraction: true,
                                                            })

                                                            navigate(
                                                                profileUrl('explore/watchlist/:instrumentSlug', {
                                                                    instrumentSlug: instrumentsById[id].urlSlug,
                                                                }),
                                                            )
                                                        }}
                                                        onMenuPress={() => {
                                                            setShowModalFor({
                                                                instrumentId: id,
                                                                modal: 'instrumentMenu',
                                                            })
                                                        }}
                                                    />
                                                )
                                            })}
                                        </>
                                    )}
                                </>
                            </div>

                            <DockableModal
                                dataTestId="modal--instrument-menu"
                                isOpen={showModalFor.modal === 'instrumentMenu'}
                                setIsOpen={() => setShowModalFor({instrumentId: null, modal: null})}
                                title="Options"
                                content={
                                    <>
                                        <button
                                            type="button"
                                            className={cn(accessibility.button, styles.fullWidthButton)}
                                            onClick={() => removeFromWatchList(showModalFor.instrumentId)}
                                        >
                                            Remove from Watchlist
                                        </button>

                                        <button
                                            type="button"
                                            className={cn(accessibility.button, styles.fullWidthButton)}
                                            onClick={() => editPriceNotification(showModalFor.instrumentId)}
                                        >
                                            {hasPriceNotification ? 'Edit' : 'Set'} price notification
                                        </button>
                                    </>
                                }
                                customZIndex={1051} // same as zindex(detail-modal)
                            />

                            <DockableModal
                                dataTestId="modal--show-watchlist-by"
                                isOpen={showWatchlistByModal}
                                setIsOpen={() => setShowWatchlistByModal(false)}
                                title="Watchlist shows"
                                content={
                                    <div className={styles.showWatchlistByModal}>
                                        <button
                                            className={cn(
                                                accessibility.button,
                                                styles.showButton,
                                                showWatchlistBy === 'percentageChange' && styles.isActive,
                                            )}
                                            type="button"
                                            onClick={() => onShowWatchlistBy('percentageChange')}
                                        >
                                            <span className={styles.showButtonContent}>
                                                {WATCHLIST_SHOW_LABELS.percentageChange}
                                                {showWatchlistBy === 'percentageChange' && <CheckSingle />}
                                            </span>
                                        </button>
                                        <button
                                            className={cn(
                                                accessibility.button,
                                                styles.showButton,
                                                showWatchlistBy === 'priceNotification' && styles.isActive,
                                            )}
                                            type="button"
                                            onClick={() => onShowWatchlistBy('priceNotification')}
                                        >
                                            <span className={styles.showButtonContent}>
                                                {WATCHLIST_SHOW_LABELS.priceNotification}
                                                {showWatchlistBy === 'priceNotification' && <CheckSingle />}
                                            </span>
                                        </button>
                                    </div>
                                }
                                customZIndex={1051} // same as zindex(detail-modal)
                            />

                            {!!instrumentForPriceNotification && (
                                <PriceNotificationModal
                                    isOpen={showModalFor.modal === 'priceNotification'}
                                    onClose={() => setShowModalFor({instrumentId: null, modal: null})}
                                    instrumentId={instrumentForPriceNotification.id}
                                    instrumentName={instrumentForPriceNotification.name}
                                    instrumentValue={instrumentForPriceNotification.marketPrice}
                                    instrumentCurrency={instrumentForPriceNotification.currency}
                                    instrumentSymbol={instrumentForPriceNotification.symbol}
                                    hasPriceNotification={hasPriceNotification}
                                    initialPriceNotificationAmount={priceNotification?.price_threshold}
                                    pageSource="watchlist"
                                    extendedHoursInEffect={false}
                                    portfolioId={portfolioId}
                                />
                            )}
                        </>
                    ) : (
                        <div className={styles.emptySection}>
                            <p>
                                Save a company or fund to your watchlist to keep track of investments you’re interested
                                in.
                            </p>
                            <Link
                                to={profileUrl('invest/search')}
                                onClick={() => {
                                    executeClearSearchAndFilters()
                                    setSearchAutofocus(true)
                                }}
                            >
                                <Search size={16} /> Search all investments
                            </Link>
                        </div>
                    )}
                </>
            )}
            {tabName === 'Recently viewed' && (
                <>
                    {hasRecentlyViewedInstruments ? (
                        <div className={styles.tabSection} ref={onMeasureRef}>
                            <>
                                {recentlyViewedLoadingState === 'loading' && measuredWidth && (
                                    <>
                                        {[...Array(recentlyViewedIndexFromRetail.length)].map((_, index) => (
                                            <SkeletonInstrumentRow
                                                measuredWidth={measuredWidth}
                                                key={`placeholder-${index}`}
                                            />
                                        ))}
                                    </>
                                )}
                                {recentlyViewedLoadingState === 'ready' &&
                                    recentlyViewedFunds &&
                                    recentlyViewedFunds.length > 0 && (
                                        <>
                                            {recentlyViewedFunds.map((id: string, index: number) => {
                                                if (!(id in instrumentsById)) {
                                                    return
                                                }
                                                return (
                                                    <InstrumentRow
                                                        key={`recently-viewed-${index}`}
                                                        instrument={instrumentsById[id]}
                                                        hideSortDisplay
                                                        currentTimePeriod={currentTimePeriod}
                                                        currentSort={currentSort}
                                                        onClick={() => {
                                                            setRecentlyViewedInstrument(id)

                                                            // google analytics search event
                                                            Analytics.event({
                                                                category: 'Search',
                                                                action: 'Clicked recent search results',
                                                                label: instrumentsById[id].name,
                                                                nonInteraction: true,
                                                            })

                                                            navigate(
                                                                profileUrl('explore/recently-viewed/:instrumentSlug', {
                                                                    instrumentSlug: instrumentsById[id].urlSlug,
                                                                }),
                                                            )
                                                        }}
                                                    />
                                                )
                                            })}
                                        </>
                                    )}
                            </>
                        </div>
                    ) : (
                        <div className={styles.emptySection}>
                            <p>Investments that you’ve recently viewed will appear here.</p>
                        </div>
                    )}
                </>
            )}
        </WatchlistContext.Provider>
    )
}

const MeasuredWatchlist = withMeasure(Watchlist)

export default MeasuredWatchlist
