import {ItemType} from '~/sections/moose/sections/order/state'

/**
 * Determines if the provided symbol is one of the supported fonterra symbols, and if it is, returns
 * the ItemType
 *
 * @returns {ItemType} the fonterra item type this symbol represents
 * @throws if the symbol is not a supported fonterra symbol
 */
export const itemTypeFromSymbol = (symbol: string | undefined): ItemType => {
    switch (symbol) {
        case 'FCG':
            return 'SHARE'
        case 'FSF':
            return 'UNIT'
        default:
            throw new Error('unsupported symbol')
    }
}

/**
 * Determines if the provided symbol is a Fonterra Share type.
 *
 * I.E. is it the FCG symbol or not
 *
 * @returns {boolean} if the provided symbol represents an {ItemType.SHARE}
 */
export const isShareType = (symbol: string | undefined): boolean => {
    try {
        const item = itemTypeFromSymbol(symbol)
        return item === 'SHARE'
    } catch (e) {
        return false
    }
}

/**
 * Determines if the provided symbol is a Fonterra Unit type.
 *
 * I.E. is it the FSF symbol or not
 *
 * @returns {boolean} if the provided symbol represents an {ItemType.UNIT}
 */
export const isUnitType = (symbol: string | undefined): boolean => {
    try {
        const item = itemTypeFromSymbol(symbol)
        return item === 'UNIT'
    } catch (e) {
        return false
    }
}
