import React from 'react'
import {DistillScope} from '~/api/query/distill'
import useDistillInstrumentInfo from '~/global/state-hooks/distill/useDistillInstrumentInfo'
import {getInstrumentTypeLabels} from '~/global/utils/filter-and-sort/filterAndSort'
import Chips from '~/global/widgets/chips/Chips'

const InvestmentsFilterChips: React.FunctionComponent<{
    setFilters: Function
    typeChipLabel: string
}> = ({setFilters, typeChipLabel}) => {
    const distillMetadata = useDistillInstrumentInfo(DistillScope.KIWISAVER_ALL_FUNDS, {searchFundInvestments: true})
    const filterPreference = 'TYPE'

    const instrumentTypeLabels = getInstrumentTypeLabels(distillMetadata.instrumentTypes, 'nz')

    return (
        <div>
            {filterPreference === 'TYPE' && (
                <Chips
                    options={instrumentTypeLabels}
                    hasAllOption
                    isInlineDisplay
                    selected={[typeChipLabel]}
                    onChipClick={instrumentTypeLabel => {
                        setFilters(instrumentTypeLabel)
                    }}
                />
            )}
        </div>
    )
}

export default InvestmentsFilterChips
