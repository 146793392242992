import cn from 'classnames'
import React from 'react'
import {Model, Request} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {FormikFieldInjectedProps} from '~/global/widgets/form-controls/common'
import TaxIdentificationNumbers from '~/global/widgets/help-modals/TaxIdentificationNumbers'
import {Select, Text} from '..'
import ErrorBox from '../error-box/ErrorBox'
import styles from './CountrySelectInput.scss'

export type CountryCode =
    | Request.IdentitySignUpTaxQuestions['countries'][0]['country']
    | Request.SignUpUSSetCitizenship['countries'][number]

export const COUNTRIES: [CountryCode, string][] = [
    ['AF', 'Afghanistan'],
    ['AL', 'Albania'],
    ['DZ', 'Algeria'],
    ['AS', 'American Samoa'],
    ['AD', 'Andorra'],
    ['AO', 'Angola'],
    ['AI', 'Anguilla'],
    ['AG', 'Antigua and Barbuda'],
    ['AR', 'Argentina'],
    ['AM', 'Armenia'],
    ['AW', 'Aruba'],
    ['AU', 'Australia'],
    ['AT', 'Austria'],
    ['AZ', 'Azerbaijan'],
    ['BS', 'Bahamas'],
    ['BH', 'Bahrain'],
    ['BD', 'Bangladesh'],
    ['BB', 'Barbados'],
    ['BY', 'Belarus'],
    ['BE', 'Belgium'],
    ['BZ', 'Belize'],
    ['BJ', 'Benin'],
    ['BM', 'Bermuda'],
    ['BT', 'Bhutan'],
    ['BO', 'Bolivia'],
    ['BA', 'Bosnia-Herzegovina'],
    ['BW', 'Botswana'],
    ['BR', 'Brazil'],
    ['VG', 'British Virgin Islands'],
    ['BN', 'Brunei'],
    ['BG', 'Bulgaria'],
    ['BF', 'Burkina Faso'],
    ['BI', 'Burundi'],
    ['KH', 'Cambodia'],
    ['CM', 'Cameroon'],
    ['CA', 'Canada'],
    ['CV', 'Cape Verde'],
    ['KY', 'Cayman Islands'],
    ['CF', 'Central African Rep'],
    ['TD', 'Chad'],
    ['CL', 'Chile'],
    ['CN', 'China'],
    ['CO', 'Colombia'],
    ['KM', 'Comoros'],
    ['CG', 'Congo (Congo-Brazzaville)'],
    ['CK', 'Cook Islands'],
    ['CR', 'Costa Rica'],
    ['CI', "Cote D'Ivoire"],
    ['HR', 'Croatia'],
    ['CU', 'Cuba'],
    ['CW', 'Curacao'],
    ['CY', 'Cyprus'],
    ['CZ', 'Czech Republic'],
    ['CD', 'Democratic Republic of the Congo'],
    ['DK', 'Denmark'],
    ['DJ', 'Djibouti'],
    ['DM', 'Dominica'],
    ['DO', 'Dominican Republic'],
    ['EC', 'Ecuador'],
    ['EG', 'Egypt'],
    ['SV', 'El Salvador'],
    ['GQ', 'Equatorial Guinea'],
    ['ER', 'Eritrea'],
    ['EE', 'Estonia'],
    ['SZ', 'Eswatini (fmr. Swaziland)'],
    ['ET', 'Ethiopia'],
    ['FJ', 'Fiji'],
    ['FI', 'Finland'],
    ['FR', 'France'],
    ['PF', 'French Polynesia'],
    ['GA', 'Gabon'],
    ['GM', 'Gambia'],
    ['PS-WBK', 'Gaza Strip'],
    ['GE', 'Georgia'],
    ['DE', 'Germany'],
    ['GH', 'Ghana'],
    ['GI', 'Gibraltar'],
    ['GR', 'Greece'],
    ['GL', 'Greenland'],
    ['GD', 'Grenada'],
    ['GU', 'Guam'],
    ['GT', 'Guatemala'],
    ['GG', 'Guernsey'],
    ['GN', 'Guinea'],
    ['GW', 'Guinea Bissau'],
    ['GY', 'Guyana'],
    ['HT', 'Haiti'],
    ['HN', 'Honduras'],
    ['HK', 'Hong Kong'],
    ['HU', 'Hungary'],
    ['IS', 'Iceland'],
    ['IN', 'India'],
    ['ID', 'Indonesia'],
    ['IR', 'Iran'],
    ['IQ', 'Iraq'],
    ['IE', 'Ireland'],
    ['IM', 'Isle Of Man'],
    ['IL', 'Israel'],
    ['IT', 'Italy'],
    ['JM', 'Jamaica'],
    ['JP', 'Japan'],
    ['JE', 'Jersey'],
    ['JO', 'Jordan'],
    ['KZ', 'Kazakhstan'],
    ['KE', 'Kenya'],
    ['KI', 'Kiribati'],
    ['XK', 'Kosovo'],
    ['KW', 'Kuwait'],
    ['KG', 'Kyrgyzstan'],
    ['LA', "Lao People's Democratic Republic"],
    ['LV', 'Latvia'],
    ['LB', 'Lebanon'],
    ['LS', 'Lesotho'],
    ['LR', 'Liberia'],
    ['LY', 'Libya'],
    ['LI', 'Liechtenstein'],
    ['LT', 'Lithuania'],
    ['LU', 'Luxembourg'],
    ['MO', 'Macau'],
    ['MG', 'Madagascar'],
    ['MW', 'Malawi'],
    ['MY', 'Malaysia'],
    ['MV', 'Maldives'],
    ['ML', 'Mali'],
    ['MT', 'Malta'],
    ['MH', 'Marshall Islands'],
    ['MR', 'Mauritania'],
    ['MU', 'Mauritius'],
    ['MX', 'Mexico'],
    ['FM', 'Micronesia'],
    ['MD', 'Moldova'],
    ['MC', 'Monaco'],
    ['MN', 'Mongolia'],
    ['ME', 'Montenegro'],
    ['MS', 'Montserrat'],
    ['MA', 'Morocco'],
    ['MZ', 'Mozambique'],
    ['MM', 'Myanmar'],
    ['NA', 'Namibia'],
    ['NR', 'Nauru'],
    ['NP', 'Nepal'],
    ['NL', 'Netherlands'],
    ['NC', 'New Caledonia'],
    ['NZ', 'New Zealand'],
    ['NI', 'Nicaragua'],
    ['NE', 'Niger'],
    ['NG', 'Nigeria'],
    ['NU', 'Niue'],
    ['KP', 'North Korea'],
    ['MK', 'North Macedonia'],
    ['NO', 'Norway'],
    ['OM', 'Oman'],
    ['PK', 'Pakistan'],
    ['PW', 'Palau'],
    ['PS', 'Palestine'],
    ['PA', 'Panama'],
    ['PG', 'Papua New Guinea'],
    ['PY', 'Paraguay'],
    ['PE', 'Peru'],
    ['PH', 'Philippines'],
    ['PL', 'Poland'],
    ['PT', 'Portugal'],
    ['PR', 'Puerto Rico'],
    ['QA', 'Qatar'],
    ['RO', 'Romania'],
    ['RU', 'Russian Federation'],
    ['RW', 'Rwanda'],
    ['KN', 'Saint Kitts and Nevis'],
    ['VC', 'Saint Vincent and the Grenadines'],
    ['WS', 'Samoa'],
    ['SM', 'San Marino'],
    ['ST', 'Sao Tome and Principe'],
    ['SA', 'Saudi Arabia'],
    ['SN', 'Senegal'],
    ['RS', 'Serbia'],
    ['SC', 'Seychelles'],
    ['SL', 'Sierra Leone'],
    ['SG', 'Singapore'],
    ['SK', 'Slovakia'],
    ['SI', 'Slovenia'],
    ['SB', 'Solomon Islands'],
    ['SO', 'Somalia'],
    ['ZA', 'South Africa'],
    ['KR', 'South Korea'],
    ['SS', 'South Sudan'],
    ['ES', 'Spain'],
    ['LK', 'Sri Lanka'],
    ['LC', 'St Lucia'],
    ['SX', 'St Maarten'],
    ['SD', 'Sudan'],
    ['SR', 'Suriname'],
    ['SE', 'Sweden'],
    ['CH', 'Switzerland'],
    ['SY', 'Syria'],
    ['TW', 'Taiwan'],
    ['TJ', 'Tajikistan'],
    ['TZ', 'Tanzania'],
    ['TH', 'Thailand'],
    ['TL', 'Timor-Leste'],
    ['TG', 'Togo'],
    ['TO', 'Tonga'],
    ['TT', 'Trinidad and Tobago'],
    ['TN', 'Tunisia'],
    ['TR', 'Turkey'],
    ['TM', 'Turkmenistan'],
    ['TC', 'Turks and Caicos Islands'],
    ['TV', 'Tuvalu'],
    ['UG', 'Uganda'],
    ['UA', 'Ukraine'],
    ['AE', 'United Arab Emirates'],
    ['GB', 'United Kingdom'],
    ['US', 'United States'],
    ['VI', 'United States Virgin Islands'],
    ['UY', 'Uruguay'],
    ['UZ', 'Uzbekistan'],
    ['VU', 'Vanuatu'],
    ['VA', 'Vatican City State (Holy See)'],
    ['VE', 'Venezuela'],
    ['VN', 'Vietnam'],
    ['PS-GZZ', 'West Bank'],
    ['EH', 'Western Sahara'],
    ['YE', 'Yemen'],
    ['ZM', 'Zambia'],
    ['ZW', 'Zimbabwe'],
]

export type CountriesType = (
    | {
          country: ''
          tax_information_number: string
      }
    | {
          country: CountryCode
          tax_information_number: string
      }
)[]

export const extractCountries = (countries: CountriesType) =>
    countries
        .filter(country => {
            if (country.country !== '') {
                return country
            }
        })
        .map(country => country.country) as Request.SignUpUSSetCitizenship['countries']

export const extractCountryNameFromCode = (code: string): string => {
    return COUNTRIES.find(country => country[0] === code.toUpperCase())![1]
}

export const extractCountryCodeFromName = (name: string | undefined): string | undefined => {
    if (!name) {
        return undefined
    }
    const country = COUNTRIES.find(country => country[1].toUpperCase() === name.toUpperCase())
    return country && country[0]
}

interface Props extends FormikFieldInjectedProps<CountriesType> {
    error?: string
    className?: string
    countryLabel?: string
    isTaxCountryInput?: boolean
    isCitizenshipCountryInput?: boolean
    isDependent?: boolean
    preferredName?: string
    jurisdiction?: Model.User['jurisdiction']
}

class CountrySelectInput extends React.Component<Props> {
    protected inputType = 'number'

    private getCurrentValue(): CountriesType {
        // TODO SOME VALIDATION
        return this.props.value || []
    }

    private updateValue(mutator: (value: CountriesType) => CountriesType): void {
        if (this.props.setFieldValue) {
            this.props.setFieldValue(mutator(this.getCurrentValue()))
        }
    }

    private doAddCountry = () => {
        this.updateValue(value => value.concat({country: '', tax_information_number: ''}))
    }

    private doRemoveCountry(index: number) {
        this.updateValue(value => [...value.slice(0, index), ...value.slice(index + 1)])
    }

    private onSelectCountry(country: CountryCode, index: number) {
        this.updateValue(value => [...value.slice(0, index), {...value[index], country}, ...value.slice(index + 1)])
    }

    private onChangeTIN(tax_information_number: string, index: number) {
        this.updateValue(value => [
            ...value.slice(0, index),
            {...value[index], tax_information_number},
            ...value.slice(index + 1),
        ])
    }

    render(): React.ReactNode {
        const value = this.getCurrentValue()
        const {
            countryLabel,
            isDependent,
            preferredName,
            jurisdiction,
            className,
            isCitizenshipCountryInput,
            isTaxCountryInput,
        } = this.props

        const filteredCountries = jurisdiction
            ? COUNTRIES.filter(country => country[0] !== jurisdiction!.toUpperCase())
            : COUNTRIES

        return (
            <div className={cn(className)}>
                {value.map((ec, index) => (
                    <div
                        key={index}
                        className={cn(styles.selectWrapper, {[spacing.spaceBelow48]: ec.country === 'US'})}
                    >
                        <Select
                            dataTestId="select--country"
                            name="country"
                            isTouched={false}
                            value={ec.country}
                            additionalClassName={styles.noMarginBottom}
                            label={
                                countryLabel ||
                                `Which country ${isDependent ? `is ${preferredName}` : 'are you'} a tax resident of?`
                            }
                            placeholder="Choose a country"
                            onChange={e => this.onSelectCountry(e.target.value as CountryCode, index)}
                            choices={[['', ''], ...filteredCountries].map(([value, label]) =>
                                isCitizenshipCountryInput && value === 'US'
                                    ? {value, label: 'US citizen or Green Card holder'}
                                    : {value, label},
                            )}
                        />
                        {isTaxCountryInput && (
                            <Text
                                dataTestId="text-input--tax-information-number"
                                name="tax_information_number"
                                label={
                                    <p>
                                        What is {isDependent ? `${preferredName}’s` : 'your'} tax identification
                                        number?&nbsp;&nbsp;
                                        <TaxIdentificationNumbers />
                                    </p>
                                }
                                value={ec.tax_information_number}
                                placeholder="Tax identification number"
                                onChange={e => this.onChangeTIN(e.target.value, index)}
                                isTouched={false}
                                helpText={
                                    ec.country === 'US'
                                        ? 'You might be taxed at a higher rate if you don’t enter a tax identification number'
                                        : undefined
                                }
                            />
                        )}
                        {value.length > 0 && (
                            <p className={spacing.spaceBelow24}>
                                <ButtonAsLink
                                    dataTestId="button--remove-county"
                                    onClick={() => this.doRemoveCountry(index)}
                                >
                                    Remove country
                                </ButtonAsLink>
                            </p>
                        )}
                    </div>
                ))}
                <ErrorBox message={this.props.isTouched && this.props.error} />
                <p className={spacing.spaceBelow48}>
                    <strong>
                        <ButtonAsLink dataTestId="button--add-another-country" onClick={this.doAddCountry}>
                            Add another country
                        </ButtonAsLink>
                    </strong>
                </p>
            </div>
        )
    }
}

export default CountrySelectInput
