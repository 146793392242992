import {checkMode as isDarkmode} from '@design-system/colour-tokens'
import * as rudderanalytics from 'rudder-sdk-js'
import {EventDataTaxonomy, EventCategoryVersion} from '~/api/rudderstack/event-taxonomy/types'
import config from '~/configForEnv'
import {stripProfile} from '~/global/utils/strip-profile/stripProfile'

// IMPORTANT: Make sure your ad-blocker is off as it can block any calls to RudderStack

/*
    Sign up links from the website have the website user's rudderstack anonymous id appended in the paramater "r_anonymous_id"
    for continuity of analytics, we want to set the rudderstack user here to this anonymous id so we can associate their signup
    activity with their website activity

    Do this at the global level so it's always available via the global variable anonId
    as the URL params get wiped after the first page load
*/
const params = new URLSearchParams(window.location.search)
const anonymousIdFromMarketingWebsite = params.get('r_anonymous_id') || undefined

// Initialise rudderanalytics
rudderanalytics.load(config.rudderstackWriteKey, config.rudderstackDataPlaneURL, {
    configUrl: config.rudderstackConfigUrl,
})

// Immediately set anonymousId if we have anonymousIdFromMarketingWebsite
// So the Rudder SDK doesn't generate a new one, set it again
// in the ready function if for some reason the SDK isn't ready right now
if (anonymousIdFromMarketingWebsite && rudderanalytics) {
    rudderanalytics.setAnonymousId(anonymousIdFromMarketingWebsite)
}

let rudderIsInitialised = false

const rudderIsInitialisedPromise = new Promise<void>((resolve, reject) => {
    try {
        rudderanalytics.ready(() => {
            // We know rudderanalytics has loaded, set rudderIsInitialised to true
            rudderIsInitialised = true

            if (anonymousIdFromMarketingWebsite) {
                rudderanalytics.setAnonymousId(anonymousIdFromMarketingWebsite)
            }

            // Resolve the promise
            resolve()
        })
    } catch (error) {
        reject(error)
    }
})

export function isRudderInitialised(): Promise<void> {
    return rudderIsInitialisedPromise
}

/**
 * rudderPageView is called instantly when a page is loaded or navigated to,
 * need to ensure that Rudderstack is initalised correctly before calling the page view function
 * otherwise we will potentially miss the very first pages someone lands on.
 *
 * This is a fire and forget function - no need to return a promise here
 */
export function rudderPageView(): void {
    isRudderInitialised().then(() => {
        const pathWithoutProfile = stripProfile(window.location.pathname)
        // @NOTE / and /login and (now deprecated) /portfolio are some of the most visited pages.
        // We want to ignore sending this to Rudderstack so we don't burn through our "sent events" quota.
        // Exactly matching since we still want to capture child routes e.g. /*
        if (['/', '/login', '/portfolio', '/sign-up/welcome', '/invest'].includes(pathWithoutProfile)) {
            return
        }

        rudderanalytics.page()
    })
}

/**
 * `rudderIdentify` is used to set the RudderStack userId and any associated traits for that identity
 *
 * @param {string} customerId - the Sharesies customerId
 * @param {string} gaId - generated ID unique to the Sharesies customer - used for sending to third party platforms without exposing the Sharesies customerId
 */
export function rudderIdentify(customerId: string, gaId: string): void {
    if (rudderIsInitialised) {
        rudderanalytics.identify(customerId, {
            externalId: [
                {
                    id: gaId,
                    type: 'ga4ClientId',
                },
            ],
            dark_mode_enabled: isDarkmode(),
        })
    }
}

/*
Send rudderanalytics.track() call to rudderstack. Eg: rudderTrack('sign up', 'primary id entered')
*/
export function rudderTrack<C extends keyof EventDataTaxonomy, N extends keyof EventDataTaxonomy[C]>(
    category: C,
    event: N,
    properties?: EventDataTaxonomy[C][N],
): void {
    // concat category and eventName
    if (rudderIsInitialised) {
        rudderanalytics.track(String(event), {
            ...properties,
            category,
            version: EventCategoryVersion[category],
        } as rudderanalytics.apiObject)
    }
}

export function trackFactory<C extends keyof EventDataTaxonomy>(category: C) {
    return <N extends keyof EventDataTaxonomy[C]>(event: N, properties?: EventDataTaxonomy[C][N]) =>
        rudderTrack(category, event, properties)
}

/**
 * The reset method resets the ID and traits of both the user and the group.
 *
 * Don't pass `true` to `.reset` as we don't want to remove or create a new anonymous ID.
 */
export function resetRudder() {
    if (rudderIsInitialised) {
        rudderanalytics.reset()
    }
}
