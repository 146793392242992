import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import FarmerTradingServiceTermsAndConditions from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/FarmerTradingServiceTermsAndConditions'

const FarmerLegalTerms: React.FunctionComponent<{}> = () => {
    return (
        <>
            <Toolbar dataTestId="toolbar--legal-farmer-terms-and-conditions" leftButton="back" title="Legal" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <h2 className={cn(spacing.spaceBelow16)}>Farmer Trading Service Terms and Conditions</h2>
                <FarmerTradingServiceTermsAndConditions />
            </Page>
        </>
    )
}

export default FarmerLegalTerms
