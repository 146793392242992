import cn from 'classnames'
import React from 'react'
import {ListingResponseDto} from '~/api/distill'
import {Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {useHoldingDetail} from '~/sections/moose/data/retail'
import {MoosePortfolioPathParams} from '~/sections/moose/routes'
import InvestingActivity from '~/sections/moose/sections/instrument/widgets/investing-activity/InvestingActivity'
import {ShareholdingLimitsWidget} from '~/sections/moose/sections/order/widgets/shareholding-limits/ShareholdingLimitsWidget'
import {isShareType} from '~/sections/moose/sections/utils/symbol-helpers/symbolHelpers'
import {InstrumentDetailsTab} from '~/store/instrument/types'
import styles from './MooseInstrumentYourInvestmentTab.scss'

interface MooseInstrumentYourInvestmentTabProps extends MoosePortfolioPathParams {
    instrument: ListingResponseDto
    tab: InstrumentDetailsTab
}

const findSharesHeld = (
    holdingDetails: Response.FonterraHoldingDetail | Response.Empty,
    instrument: ListingResponseDto,
): number | undefined => {
    if (holdingDetails.type !== 'fonterra_holding_detail') {
        return undefined
    }

    const holdingDetail = holdingDetails.instruments.find(x => x.symbol === instrument.symbol)
    if (!holdingDetail) {
        return undefined
    }

    return holdingDetail.holding
}

export const MooseInstrumentYourInvestmentTab: React.FunctionComponent<MooseInstrumentYourInvestmentTabProps> = ({
    portfolioId,
    instrument,
    tab,
}) => {
    const holdingData = useHoldingDetail(portfolioId)
    const sharesHeld = findSharesHeld(holdingData, instrument) ?? 0
    const holdingValue: number = sharesHeld * parseFloat(instrument.marketPrice)

    return (
        <section
            id="your-investment"
            style={{display: tab === 'Your investment' ? 'block' : 'none'}}
            role="tabpanel"
            aria-labelledby="your-investment-tab"
        >
            <div>
                <p className={cn(styles.sectionHeader, spacing.spaceAbove24)}>Investment value</p>
                <div className={cn(styles.holdingValue, spacing.spaceAbove4)}>
                    <DollarValue value={holdingValue} currency="nzd" />
                </div>
            </div>
            {isShareType(instrument.symbol) && (
                <div className={cn(styles.sectionHeader, spacing.spaceAbove24)}>
                    <ShareholdingLimitsWidget portfolioId={portfolioId} symbol={instrument.symbol} />
                </div>
            )}
            <InvestingActivity instrumentId={instrument.id} portfolioId={portfolioId} />
        </section>
    )
}
