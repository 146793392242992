import {SetStateAction, useAtom} from 'jotai'
import {StagedTransfer, stagedTransferAtom} from '~/sections/moose/sections/transfer/state'

type SetAtom<Args extends any[], Result> = (...args: Args) => Result

export const useStagedTransfer = (): [StagedTransfer, SetAtom<[SetStateAction<StagedTransfer | undefined>], void>] => {
    const [stagedTransfer, setStagedTransfer] = useAtom(stagedTransferAtom)

    if (stagedTransfer === undefined) {
        throw new Error('Fonterra staged transfer is undefined')
    }

    return [stagedTransfer, setStagedTransfer]
}
