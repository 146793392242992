import ReactPixel from 'react-facebook-pixel'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {redditTrackCustom} from '~/api/reddit/redditPixel'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import config from '~/configForEnv'

export const createAccountTracking = (is_dependent: boolean, jurisdiction: 'nz' | 'au') => {
    const accountType = is_dependent ? 'dependent' : 'adult'

    rudderTrack('save', 'onboarding_confirm_clicked', {
        account_type: accountType,
    })

    ReactPixel.trackSingleCustom(
        jurisdiction === 'nz' ? config.facebookPixelIdNZ : config.facebookPixelIdAU,
        'save account open',
        {
            successful: true,
            account_type: accountType,
        },
    )

    if (jurisdiction === 'nz') {
        redditTrackCustom('Save Account Open')
    }

    Analytics.event({category: 'save', action: 'account-open', label: jurisdiction})
}
