import React from 'react'
import {requestContentCardsRefresh, getCachedContentCards} from '~/api/braze/braze'
import config from '~/configForEnv'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import ReFetch from './ReFetch'

const NotificationsFeedFetch: React.FunctionComponent = React.memo(() => {
    const dispatch = useAppDispatch()
    const customer = useAppSelector(s => s.identity.user)

    const updateNotificationsFeed = () => {
        if (!customer) {
            return
        }

        requestContentCardsRefresh(
            () => {
                const cards = getCachedContentCards()

                if (cards) {
                    dispatch(identityActions.SetNotifications(cards))
                }
            },
            () => {
                dispatch(identityActions.SetNotificationsLoadFail())
            },
        )
    }

    return (
        <ReFetch
            fetchAction={updateNotificationsFeed}
            timeoutPeriod={config.userCheckInterval} // 5 minutes
        />
    )
})

export default NotificationsFeedFetch
