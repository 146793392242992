import cn from 'classnames'
import Decimal from 'decimal.js'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import {isOnASX} from '~/global/utils/is-on-exchange/isOnExchange'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import Concertina from '~/global/widgets/concertina/Concertina'
import {DollarValue, FeeValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import {Trade} from '~/store/accounting/selectors'
import {Instrument} from '~/store/instrument/types'
import styles from '../Record.scss'

// hasSubsequentEntry will be true if there are other, subsequent trades for the order,
//  OR if the order has reached an unsuccessful terminal state (e.g. partially filled
//  and then cancelled). In the latter case, we display that status in a row underneath,
//  which requires the connective styling (left bar) that is part of the concertina.
interface TradesConcertinaProps {
    concertinaIsOpen: boolean
    currency: string
    handleConcertinaClick: any
    hasSubsequentEntry: boolean
    instrument: Instrument
    jurisdiction: string
    trade: Trade
}

export const TradesConcertina: React.FC<TradesConcertinaProps> = ({
    concertinaIsOpen,
    currency,
    handleConcertinaClick,
    hasSubsequentEntry,
    instrument,
    jurisdiction,
    trade,
}) => {
    return (
        <Concertina
            key={trade.contract_note_number}
            title={
                <div className={styles.concertinaTitleWrapper}>
                    <div className={styles.titleTop}>
                        <div className={styles.titleTopLeft}>
                            <div className={cn(styles.ellipse, styles.darkEllipse)} />
                            <ShareValue value={trade.volume} showFullValue />
                            &nbsp;
                            {shareLabel({instrument, isPlural: parseFloat(trade.volume) > 1})}
                        </div>

                        <div className={styles.titleTopRight}>
                            {' '}
                            <DollarValue value={trade.gross_value} currency={currency} />
                        </div>
                    </div>

                    <div
                        className={cn(styles.titleBottom, {
                            [styles.titleBottomBorder]: hasSubsequentEntry,
                            [styles.addPadding]: concertinaIsOpen,
                        })}
                    >
                        <p className={cn({[spacing.spaceBelow4]: hasSubsequentEntry})}>
                            {trade.trade_datetime.toFormat(dateFormatWithYear)}
                        </p>
                    </div>
                </div>
            }
            body={
                <div className={cn(styles.concertinaBody, {[styles.addLeftMargin]: !hasSubsequentEntry})}>
                    <div className={cn(styles.bodyRow, styles.hasTopBorder)}>
                        <p>Contract note</p>
                        <p className={styles.addStrong}>{trade.contract_note_number}</p>
                    </div>
                    <div className={styles.bodyRow}>
                        <p>Price per {shareLabel({instrument})}</p>
                        <p className={styles.addStrong}>
                            <FeeValue
                                value={new Decimal(trade.share_price).toDecimalPlaces(3, Decimal.ROUND_UP).toString()}
                                currency={currency}
                            />
                        </p>
                    </div>
                    {Number(trade.us_partnership_tax_value) > 0 && (
                        <div className={styles.bodyRow}>
                            <p>US Withholding Tax</p>
                            <p className={styles.addStrong}>
                                <DollarValue value={trade.us_partnership_tax_value} currency={currency} />
                            </p>
                        </div>
                    )}
                    <div
                        className={cn(styles.bodyRow, {
                            [styles.noBottomBorder]: !hasSubsequentEntry,
                            [spacing.spaceBelow12]: hasSubsequentEntry,
                        })}
                    >
                        <p>
                            Transaction fee
                            {isOnASX(instrument) && jurisdiction === 'au' && ' incl. GST '}
                        </p>
                        <p className={styles.addStrong}>
                            <FeeValue value={trade.corporate_fee} currency={currency} />
                        </p>
                    </div>
                </div>
            }
            handleClick={handleConcertinaClick}
            borderLeft={hasSubsequentEntry}
            alignIconTop
        />
    )
}
