import {Modal} from '@design-system/modal'
import React from 'react'
import {useNavigate} from 'react-router'
import {spacing} from '~/global/scss/helpers'
import {subscriptionName} from '~/global/utils/subscription-name/subscriptionName'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useAppSelector} from '~/store/hooks'
import {SubPlanV2} from '~/store/plan/types'

interface Props {
    subscriptionPlan: SubPlanV2
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const PlanTopUpModal: React.FunctionComponent<Props> = ({subscriptionPlan, isOpen, setIsOpen}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const {isDependent, preferredName} = useAppSelector(s => ({
        preferredName: s.identity.user?.preferred_name,
        isDependent: s.identity.user?.is_dependent,
    }))

    return (
        <>
            <Modal
                dataTestId="modal--top-up-for-plan"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title="Top up to start plan"
                secondaryButton={{label: 'Cancel'}}
                primaryButton={{
                    label: 'Top-up',
                    onClick: () => navigate(profileUrl('wallet/topup')),
                }}
                content={
                    <div>
                        <p>
                            {isDependent
                                ? `Looks like ${preferredName} doesn’t have enough ${subscriptionPlan.currency.toLocaleUpperCase()} in their Wallet to start the ${subscriptionName(
                                      subscriptionPlan,
                                  )}.`
                                : `Looks like you don’t have enough ${subscriptionPlan.currency.toLocaleUpperCase()} in your
                            Wallet to start the ${subscriptionName(subscriptionPlan)}.`}
                        </p>
                        <p className={spacing.spaceAbove16}>
                            Top up {isDependent && 'their Wallet'} (or exchange money from another currency), and try
                            starting the plan again.
                        </p>
                    </div>
                }
            />
        </>
    )
}

export default PlanTopUpModal
