import {Button} from '@design-system/button'
import {Modal} from '@design-system/modal'
import React from 'react'
import {Response} from '~/api/retail/types'
import {Toast} from '~/global/widgets/toast/Toast'
import {useAppDispatch} from '~/store/hooks'
import {setModalError} from '~/store/notification'
import actions from '~/store/transfer/actions'

const CancelTransferOrderModal: React.FunctionComponent<OwnProps> = ({orderId, groupId, fundId, fundName}) => {
    const dispatch = useAppDispatch()
    const [isOpen, setIsOpen] = React.useState(false)

    const onOpen = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setIsOpen(true)
    }

    const onClose = () => {
        setIsOpen(false)
    }

    const onCancel = async () => {
        try {
            let error: string | void
            if (groupId) {
                const groupCancelResult = await dispatch(actions.CancelTransferGroup(groupId))
                const errorResponse = groupCancelResult as Response.Error
                if (errorResponse?.message) {
                    error = errorResponse.message
                } else {
                    error = groupCancelResult as string | void
                }
            } else {
                error = await dispatch(actions.CancelTransferOrder(orderId, fundId))
            }
            if (error) {
                setModalError(error, 'We’re sorry, there’s been an error')
            } else {
                Toast('Transfer cancelled')
            }
            setIsOpen(false)
        } catch (e) {
            setModalError(
                'Something caused it, maybe gremlins? Please try again or let us know if it’s still not working.',
                'We’re sorry, there’s been an error',
            )
        }
    }
    return (
        <>
            <Button label="Cancel transfer" dataTestId="button--cancel-transfer" onClick={onOpen} type="secondary" />

            <Modal
                isAlert
                isOpen={isOpen}
                setIsOpen={onClose}
                dataTestId="modal--nzx-transfer-shares-cancel"
                title="Cancel transfer"
                primaryButton={{label: 'Cancel transfer', onClick: onCancel}}
                secondaryButton={{label: "No, don't cancel"}}
            >
                <p>You created a transfer for the {fundName}.</p>
                <p>
                    <strong>Are you sure you want to cancel this transfer?</strong>
                </p>
            </Modal>
        </>
    )
}

interface OwnProps {
    orderId: string
    fundId: string
    fundName: string
    groupId?: string
}

export default CancelTransferOrderModal
