import React from 'react'
import {Model} from '~/api/retail/types'
import {useInstruments} from '~/global/utils/use-instruments/useInstruments'
import actions from '~/store/autoinvest/actions'
import {AutoinvestOrderAllocation, AutoinvestPremadeOrder} from '~/store/autoinvest/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'

type AutoinvestEnhancedAllocations = (Model.AutoinvestOrder['allocations'][0] & {
    instrumentName: string
    instrumentCode: string
    instrumentSlug: string
    instrumentMarketPrice: string
})[]

interface PremadeOrderEnhanced extends AutoinvestPremadeOrder {
    allocations: AutoinvestEnhancedAllocations
}

const enhanceAllocation = (allocation: AutoinvestOrderAllocation, instrument: Instrument) => {
    return {
        ...allocation,
        instrumentName: instrument.name,
        instrumentCode: instrument.symbol,
        instrumentSlug: instrument.urlSlug,
        instrumentMarketPrice: instrument.marketPrice,
        instrumentRiskRating: instrument.riskRating,
    }
}

export const usePremadeOrders = (): [PremadeOrderEnhanced[], boolean] => {
    const {premadeOrdersState} = useAppSelector(s => ({
        premadeOrdersState: s.autoinvest.premadeOrdersState,
    }))
    const dispatch = useAppDispatch()
    const premadeOrders = useAppSelector(s => s.autoinvest.premadeOrders)
    // Fetch instruments for all current autoinvest orders. This is a little wasteful, but hey. less code.
    const instrumentIds = premadeOrders.map(premade => premade.allocations.map(a => a.fund_id)).flat()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [instrumentsById, instrumentsLoaded] = useInstruments(instrumentIds)

    React.useEffect(() => {
        if (premadeOrdersState === 'uninitialised') {
            dispatch(actions.Initialise())
        }
    }, [premadeOrdersState])

    let enhancedPremadeOrders: PremadeOrderEnhanced[] = []

    if (premadeOrders && premadeOrders.length > 0) {
        enhancedPremadeOrders = premadeOrders.map<PremadeOrderEnhanced>(premadeOrder => ({
            ...premadeOrder,
            allocations: premadeOrder.allocations.map((allocation: AutoinvestOrderAllocation) => {
                const instrument = instrumentsById[allocation.fund_id]
                return instrument
                    ? enhanceAllocation(allocation, instrument)
                    : (allocation as AutoinvestEnhancedAllocations[0])
            }),
        }))
    }

    return [enhancedPremadeOrders, instrumentsLoaded]
}

export const usePremadeOrder = (slug: string): [PremadeOrderEnhanced | undefined, boolean] => {
    const [premadeOrders, allLoaded] = usePremadeOrders()
    return [premadeOrders.find(o => o.slug === slug), allLoaded]
}
