import {Button} from '@design-system/button'
import {CopyField} from '@design-system/copy-field'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useRetailGet} from '~/api/query/retail'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useNzStandardInvestor} from '~/global/utils/use-nz-standard-investor/useNzStandardInvestor'
import {AccountVerificationRequired} from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useWalletPortfolio} from '~/sections/wallet/state/local'
import {useLegacyUserData} from '~/sections/wallet/state/retail'

const useWalletAccountReferences = () => {
    const walletPortfolio = useWalletPortfolio()
    const {data} = useRetailGet({path: 'wallet/:portfolio_id/details', pathParams: {portfolio_id: walletPortfolio.id}})

    return data.references
}

export const TopUpWithBankDeposit = () => {
    const navigate = useNavigate()
    const profile = useProfile()
    const walletAccountReferences = useWalletAccountReferences()
    const {isRestrictedAccount} = useLegacyUserData()
    const isNzStandardInvestor = useNzStandardInvestor()

    const subject = profile.legacy_profile_type === 'DEPENDENT' ? `${profile.name}’s` : 'your'

    return (
        <>
            <Toolbar dataTestId="toolbar--top-up-with-bank-deposit" leftButton="back" title="Top up Wallet" />
            <Page>
                {/* TODO - account verification is currently tied to identity.user, needs updating for new models */}
                <AccountVerificationRequired />

                {!isRestrictedAccount && (
                    <>
                        <h2 className={cn(spacing.spaceBelow12, typographyOverrides['as-h2'])}>
                            Top up with bank transfer
                        </h2>

                        <p className={spacing.spaceBelow16}>
                            {isNzStandardInvestor
                                ? 'Copy the following details to your online banking to set up a one-off bank transfer or automatic payment.'
                                : `Top up ${subject} Wallet with money from your bank account. Use the following details to
                            set up a one-off or automatic payment in your online banking.`}
                        </p>

                        {walletAccountReferences.map((accountReference, i) => {
                            return (
                                <div key={`reference-${i}`}>
                                    {walletAccountReferences.length > 1 && (
                                        <h3
                                            data-testid="title--currency-section"
                                            className={cn(spacing.spaceBelow12, typographyOverrides['as-h3'])}
                                        >
                                            Top up in {accountReference.currency.toLocaleUpperCase()}
                                        </h3>
                                    )}

                                    <AlertCard
                                        className={spacing.spaceBelow24}
                                        type="info"
                                        title="Transfer times"
                                        text={
                                            isNzStandardInvestor
                                                ? 'A bank transfer can take a few hours on weekdays, or a few days over weekends and public holidays.'
                                                : 'Topping up with a bank transfer can take one to two working days. We’re working to make this faster in the future.'
                                        }
                                    />

                                    <p className={spacing.spaceBelow16}>
                                        You can only top up in {accountReference.currency.toLocaleUpperCase()} using
                                        these details.
                                    </p>

                                    <h4
                                        data-testid="title--copy-section"
                                        className={cn(spacing.spaceBelow24, typographyOverrides['as-h4'])}
                                    >
                                        Copy these details{isNzStandardInvestor && ' to top up in NZD'}:
                                    </h4>

                                    <CopyField
                                        additionalClassName={spacing.spaceBelow24}
                                        id="top-up-account-reference"
                                        dataTestId="copy-field--top-up-account-reference"
                                        label="Reference"
                                        value={accountReference.reference}
                                        onClick={() => {
                                            rudderTrack('topup', 'bank_reference_copied')
                                        }}
                                    />

                                    <CopyField
                                        additionalClassName={spacing.spaceBelow24}
                                        id="top-up-payee-name"
                                        dataTestId="copy-field--payee-name"
                                        label="Payee name"
                                        value={accountReference.payee_name}
                                    />

                                    {accountReference.bank_bsb && (
                                        <CopyField
                                            additionalClassName={spacing.spaceBelow24}
                                            id="top-up-bsb-number"
                                            dataTestId="copy-field--bsb-number"
                                            label="BSB number"
                                            value={accountReference.bank_bsb}
                                        />
                                    )}

                                    <CopyField
                                        additionalClassName={spacing.spaceBelow24}
                                        id="top-up-account-number"
                                        dataTestId="copy-field--account-number"
                                        label="Account number"
                                        value={accountReference.account_number}
                                        onClick={() => {
                                            rudderTrack('topup', 'bank_account_copied')
                                        }}
                                    />
                                </div>
                            )
                        })}
                        {isNzStandardInvestor && (
                            <p>
                                For details on how to top up your Wallet in USD or AUD, check out our{' '}
                                <a
                                    href="https://intercom.help/sharesies/en/articles/756096-top-up-your-wallet"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    help centre
                                </a>
                                .
                            </p>
                        )}
                    </>
                )}
                <Button dataTestId="button--go-back" label="Go back" onClick={() => navigate(-1)} pageButton />
            </Page>
        </>
    )
}
