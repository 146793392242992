/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum Currency {
    NZD = 'NZD',
    USD = 'USD',
    AUD = 'AUD',
    GBP = 'GBP',
    CAD = 'CAD',
    HKD = 'HKD',
    ZAR = 'ZAR',
}

export function CurrencyFromJSON(json: any): Currency {
    return CurrencyFromJSONTyped(json, false)
}

export function CurrencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Currency {
    return json as Currency
}

export function CurrencyToJSON(value?: Currency | null): any {
    return value as any
}
