import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {useRetailGet} from '~/api/query/retail'
import type {GET_MAP} from '~/api/retail/types'

export type Profile = GET_MAP['profiles']['response']['profiles'][number]
type RawPortfolio = Profile['portfolios'][number]

/**
 * Represents a portfolio in a profile. This is a generic that defaults to any type of portfolio, but you can
 * specify a specific portfolio product if you want to narrow it.
 *
 * @example
 * const portfolio: Portfolio
 * const walletPortfolio: Portfolio<'WALLET'>
 */
export type Portfolio<T extends RawPortfolio['product'] = RawPortfolio['product']> = Extract<RawPortfolio, {product: T}>

export const ProfileContext = React.createContext<Profile | null>(null)

/**
 * Use the ProfileContext to get the current profile. It will have been loaded
 * in by the ProfileWrapper component. Should only be used within Authenticated
 * routes.
 *
 * @example
 * const profile = useProfile()
 */
export const useProfile = () => {
    const context = React.useContext(ProfileContext)

    if (context === null) {
        throw new Error('useProfile must be used within a ProfileWrapper')
    }

    return context
}

/**
 * Use the ProfileContext to get the current profile, if one has been loaded in by the ProfileWrapper component.
 *
 * @example
 * const profile = useProfileOrNull()
 */
export const useProfileOrNull = () => {
    return React.useContext(ProfileContext)
}

/**
 * Use the profiles endpoint to get the current actor's profiles.
 *
 * @example
 * const profiles = useProfiles()
 */
export const useProfiles = () => {
    return useRetailGet({
        path: 'profiles',
        options: {
            staleTime: 4.5 * 60 * 1000, // four and a half minutes - half a minute before cache expires
        },
    }).data.profiles
}

/**
 * Trigger a cache invalidation for the profiles endpoint. Useful for when we
 * want to force a refetch of the profiles, particularly after a
 * non-tanstack-query API POST has updated something that might result in
 * updated data. Returns a function that can be excuted later.
 *
 * @example
 * const invalidateProfilesCache = useInvalidateProfilesCache()
 * invalidateProfilesCache()
 */
export const useInvalidateProfilesCache = () => {
    const queryClient = useQueryClient()

    return () => queryClient.invalidateQueries(['profiles'])
}
