import {ArrowRight} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import commonStyles from '~/sections/protect/sections/insure/sections/landing/utils/common-styles/commonStyles.scss'

export const NoPolicies: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    return (
        <div className={cn(commonStyles.card, commonStyles.isQuote, spacing.spaceBelow16)}>
            <div className={commonStyles.cardMainContent}>
                <p className={spacing.spaceBelow4}>You don't have any policies.</p>

                <ButtonAsLink
                    noTextDecoration
                    onClick={() => {
                        rudderTrack('insurance_signup', 'generate_quote_clicked', {source: 'landing'})
                        navigate(profileUrl('protect/insure/car/details'))
                    }}
                    dataTestId="link--insure-create-new-estimate"
                >
                    <strong>
                        Get a new estimate <ArrowRight size={12} />
                    </strong>
                </ButtonAsLink>
            </div>
        </div>
    )
}
