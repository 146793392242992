import {Colour, colourLight} from '@design-system/colour-tokens'
import {HorizontalGauge} from '@design-system/horizontal-gauge'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {useReadPortfolio} from '~/global/state-hooks/rakaia/useReadPortfolio'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {overallBreakdown} from '~/global/utils/portfolio-breakdown/portfolioBreakdown'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Concertina from '~/global/widgets/concertina/Concertina'
import {AnimatedDonut} from '~/global/widgets/donut-chart/PieCharts'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {Link} from '~/migrate-react-router'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'
import styles from './PortfolioBreakdown.scss'

const PortfolioBreakdown: React.FunctionComponent = React.memo(() => {
    const dispatch = useAppDispatch()
    const handleBreakdownClick = (isOpen: boolean) => dispatch(instrumentActions.SetPortfolioBreakdownIsOpen(isOpen))

    const profileUrl = useProfileUrl()
    const profile = useProfile()

    // From retail backend
    const investPortfolio = profile.portfolios.find(p => p.product === 'INVEST')!
    const currentFilter = useAppSelector(s => s.identity.portfolioFilterPreference)
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)

    // From distill
    const isConcertinaOpen = useAppSelector(s => s.instrument.portfolioBreakdownIsOpen)

    // From rakaia
    const portfolioSummary = useReadPortfolio(investPortfolio.id)
    const countryBreakdown = portfolioSummary.portfolio_countries
    const typeBreakdown = portfolioSummary.portfolio_instrument_types
    const riskBreakdown = portfolioSummary.portfolio_risk

    const handleClick = () => {
        Analytics.event({
            category: 'Portfolio',
            action: 'Breakdown expanded',
            label: currentFilter,
        })
    }

    React.useEffect(() => {
        if (completePortfolioBreakdown?.subtitles.length < 3) {
            // AU investors do not have Managed Funds so we need a placeholder third value for the array
            valuesForAnimatedDonut.push(0)
        }
    }, [currentFilter])

    if (!portfolioSummary) {
        return <Loading />
    } else if (!countryBreakdown || !typeBreakdown || !riskBreakdown) {
        // Safety check.
        return null
    } else if (!portfolioSummary.portfolio_value || portfolioSummary.portfolio_value === 0) {
        // No investment value.
        return null
    }

    // The current breakdown should match the current filter selected
    const currentBreakdown =
        currentFilter === 'RISK' && riskBreakdown
            ? riskBreakdown
            : currentFilter === 'COUNTRY' && countryBreakdown
              ? countryBreakdown
              : typeBreakdown

    const completePortfolioBreakdown = overallBreakdown(
        currentBreakdown,
        currentFilter,
        jurisdiction,
        isDependent ? preferredName : undefined,
    )

    const valuesForAnimatedDonut = Object.values(completePortfolioBreakdown.subtitles).map(breakdown => {
        if (breakdown.percentage === 0) {
            return 0
        }
        if (breakdown.percentage < 3) {
            // Give the donut slice a minimum width so visually clearer
            return 3
        }
        return breakdown.percentage
    })

    const colour = (index: number) => {
        const riskColours: Colour[] = ['Blueberry200', 'Blueberry400', 'Blueberry600']
        const otherFilterColours: Colour[] = ['Blueberry200', 'Blueberry400', 'Blueberry600']
        if (currentFilter === 'RISK') {
            return colourLight(riskColours[index])
        }
        return colourLight(otherFilterColours[index])
    }

    const breakdownSummary = (): React.ReactNode => {
        switch (currentFilter) {
            case 'RISK':
                return (
                    <p>
                        {isDependent ? `${preferredName} has` : `You have`}{' '}
                        {portfolioSummary && portfolioSummary.portfolio_risk_type === 'AGGRESSIVE' ? 'an' : 'a'}{' '}
                        {portfolioSummary &&
                            portfolioSummary.portfolio_risk_type &&
                            portfolioSummary.portfolio_risk_type.toLowerCase()}{' '}
                        Portfolio. Learn more about{' '}
                        <Link to={profileUrl('invest/learn/:slug', {slug: 'portfolio-types'})}>Portfolio types</Link>{' '}
                        and{' '}
                        <Link to={profileUrl('invest/learn/:slug', {slug: 'investment-risk-levels'})}>
                            investment risk levels
                        </Link>
                        .
                    </p>
                )
            case 'COUNTRY':
                return (
                    <p>
                        Learn more about the{' '}
                        <Link to={profileUrl('invest/learn/:slug', {slug: 'new-zealand-stock-exchange'})}>
                            NZ Stock Exchange
                        </Link>
                        ,{' '}
                        <Link to={profileUrl('invest/learn/:slug', {slug: 'australian-securities-exchange'})}>
                            Australian Securities Exchange
                        </Link>{' '}
                        and <Link to={profileUrl('invest/learn/:slug', {slug: 'us-exchanges'})}>US exchanges</Link>.
                    </p>
                )
            case 'TYPE':
                return jurisdiction === 'au' ? (
                    <p>
                        Learn more about{' '}
                        <Link
                            to={profileUrl('invest/learn/:slug', {
                                slug: 'publicly-listed-companies',
                            })}
                        >
                            publicly listed companies
                        </Link>{' '}
                        and{' '}
                        <Link to={profileUrl('invest/learn/:slug', {slug: 'australian-securities-exchange'})}>
                            exchange traded funds
                        </Link>
                        .
                    </p>
                ) : (
                    <p>
                        Learn more about{' '}
                        <Link
                            to={profileUrl('invest/learn/:slug', {
                                slug: 'publicly-listed-companies',
                            })}
                        >
                            publicly listed companies
                        </Link>
                        ,{' '}
                        <Link
                            to={profileUrl('invest/learn/:slug', {
                                slug: 'exchange-traded-funds',
                            })}
                        >
                            exchange traded funds (ETFs)
                        </Link>{' '}
                        and{' '}
                        <Link
                            to={profileUrl('invest/learn/:slug', {
                                slug: 'managed-funds',
                            })}
                        >
                            managed funds
                        </Link>
                        .
                    </p>
                )
        }
    }

    return (
        <div className={styles.breakdownWrapper}>
            <div className={styles.container} onClick={handleClick}>
                <Concertina
                    largeChevron
                    title={
                        <div className={styles.titleContent}>
                            <AnimatedDonut values={valuesForAnimatedDonut} filter={currentFilter} />
                            <span className={styles.labelToggle}>{completePortfolioBreakdown.title}</span>
                        </div>
                    }
                    body={
                        <div className={styles.bodyContent}>
                            {completePortfolioBreakdown.subtitles.map((subtitle, index) => (
                                <div className={styles.row} key={index}>
                                    <HorizontalGauge
                                        dataTestId="portfolio-progress-gauge"
                                        startLabel={subtitle.subtitle}
                                        endLabel={<DollarValue value={subtitle.value} currency={homeCurrency} />}
                                        gaugeColour={colour(index)}
                                        gaugeValue={subtitle.percentage}
                                    />
                                </div>
                            ))}
                            <div className={styles.summary}>{breakdownSummary()}</div>
                        </div>
                    }
                    handleClick={handleBreakdownClick}
                    isOpen={isConcertinaOpen}
                />
            </div>
        </div>
    )
})

export default PortfolioBreakdown
