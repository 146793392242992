import {ClassicCard} from '@braze/web-sdk'
import {Button} from '@design-system/button'
import {Thumbnail} from '@design-system/thumbnail'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {logCardClick, logContentCardImpressions} from '~/api/braze/braze'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spaceBelow16} from '~/global/scss/helpers/spacing.scss'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import DetailModal from '~/global/widgets/detail-modals/DetailModal'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'
import styles from './AnnouncementDetail.scss'

interface Props {
    isOpen: boolean
    onClose: () => void
    card: ClassicCard
}

const AnnouncementDetail = ({isOpen, onClose, card}: Props) => {
    const headingText = card.title
    const bodyHTML = card.extras?.body_html || card.description
    const imageUrl = card.extras?.image_url || card.imageUrl
    const instrumentId = card.extras?.instrument_id
    const buttonLink = card.extras?.button_link || card.url
    const buttonText = card.extras?.button_text || card.linkText || 'View'

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const instrument = useAppSelector(s => (instrumentId ? s.instrument.instrumentsById[instrumentId] : undefined))
    const metadataInitialised = useAppSelector(s => (instrumentId ? s.instrument.isMetadataInitialised : false))
    const path = useAppSelector(({instrument}) => instrument.metadata.resourcePath)

    React.useEffect(() => {
        logContentCardImpressions([card])
    }, [])

    React.useEffect(() => {
        if (!instrumentId) {
            return
        }

        if (metadataInitialised && !instrument) {
            dispatch(instrumentActions.getSingleInstrumentById(instrumentId))
        }
    }, [instrumentId, metadataInitialised])

    return (
        <DetailModal className={cn(styles.container)} isOpen={isOpen} closeHandler={onClose} closeColourWhite>
            <div className={styles.imageContainer}>
                <div
                    data-testid="image--announcement-detail"
                    className={styles.image}
                    style={{backgroundImage: `url(${imageUrl})`}}
                />
                {instrument && (
                    <div className={styles.fundLogoContainer}>
                        <Thumbnail
                            dataTestId="thumbnail--logo"
                            symbol={instrument.symbol}
                            width="60px"
                            height="60px"
                            path={instrument.logos.micro ? `${path}${instrument.logos.micro}` : undefined}
                            noBorder
                        />
                    </div>
                )}
            </div>
            <div>
                <div className={styles.content}>
                    <h1 data-testid="heading--announcement-detail" className={cn(styles.heading, spaceBelow16)}>
                        {headingText}
                    </h1>
                    <div
                        data-testid="body--announcement-detail"
                        className={styles.body}
                        dangerouslySetInnerHTML={{__html: bodyHTML}}
                    ></div>
                </div>

                <ActionBar className={styles.actionBar}>
                    {buttonLink && buttonText && (
                        <Button
                            label={buttonText}
                            dataTestId="button--announcement-detail"
                            onClick={() => {
                                logCardClick(card)

                                if (buttonLink) {
                                    const url = new URL(buttonLink)
                                    rudderTrack('announcement_splash_notification', 'button_clicked', {
                                        target: url.pathname,
                                        label: headingText,
                                    })
                                    navigate(url.pathname)
                                }
                                onClose()
                            }}
                        />
                    )}
                    {!(buttonLink && buttonText) && (
                        <Button
                            label="Close"
                            dataTestId="button--announcement-detail"
                            onClick={() => {
                                onClose()
                            }}
                        />
                    )}
                </ActionBar>
            </div>
        </DetailModal>
    )
}
export default AnnouncementDetail
