import {withFormik} from 'formik'
import {DateTime} from 'luxon'
import React from 'react'
import {Request} from '~/api/retail/types'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {IdentityVerificationStep} from '~/global/utils/identity-verification/identityVerification'
import {SharesiesOmit} from '~/global/utils/type-utilities/typeUtilities'
import {validate} from '~/global/widgets/form-controls'
import {Text} from '~/global/widgets/form-controls/formik'
import {
    CommonHeader,
    CommonFooter,
    CommonIdFormProps,
    consentMessage,
} from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/IdentityFormCommon'

type NZBirthCertificateFormProps = CommonIdFormProps & {
    verifyBirthCertificate(
        payload: SharesiesOmit<Request.IdentityVerifiBirthCertificate, Request.ActingAsRequired>,
    ): Promise<null | string>
}

type BirthCertificateFormValues = SharesiesOmit<
    Request.IdentityVerifiBirthCertificate,
    Request.ActingAsRequired & {date_of_birth: string; consent: true}
> & {date_of_birth?: DateTime; consent: boolean}

export const BirthCertificateForm = withFormik<NZBirthCertificateFormProps, BirthCertificateFormValues>({
    enableReinitialize: true,
    mapPropsToValues: () => ({
        given_name: '',
        middle_name: '',
        family_name: '',
        date_of_birth: undefined,
        registration_number: '',
        consent: false,
    }),
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        given_name: undefined,
    }),
    handleSubmit: async (values, {setSubmitting, setStatus, props: {verifyBirthCertificate, setStep}}) => {
        try {
            const error = await verifyBirthCertificate({
                ...values,
                // verifyPassport needs strings of the dates
                date_of_birth: values.date_of_birth ? values.date_of_birth.toISODate() : '',
                consent: values.consent as true,
            })
            if (error) {
                setStatus(error)
                setSubmitting(false)
                return
            } else {
                setStep('completed' as IdentityVerificationStep)
            }
        } catch (e) {
            setStatus(unknownErrorMessage)
            setSubmitting(false)
            throw e
        }
    },
    validate: validate.generate<BirthCertificateFormValues>({
        given_name: [],
        middle_name: [validate.middleName()],
        family_name: [validate.required()],
        date_of_birth: [validate.required(), validate.date(), validate.dateMinYear(1900), validate.notFutureDate()],
        registration_number: [validate.required()],
        consent: [validate.isTrue(consentMessage)],
    }),
})(({
    handleSubmit,
    isDependent,
    preferredName,
    isSubmitting,
    isValid,
    status,
    databases,
    isSecondaryId,
    setFieldValue,
}) => {
    return (
        <form onSubmit={handleSubmit}>
            <CommonHeader
                setFieldValue={setFieldValue}
                isSecondaryId={isSecondaryId}
                identityType="NZ birth certificate"
            />
            <Text dataTestId="text-input--registration-number" name="registration_number" label="Registration number" />
            <CommonFooter
                isDependent={isDependent}
                preferredName={preferredName}
                isSubmitting={isSubmitting}
                databases={databases}
                isValid={isValid}
                jurisdiction="nz"
                error={status}
                isSecondaryId={isSecondaryId}
            />
        </form>
    )
})
