import {Button} from '@design-system/button'
import {CheckboxInput} from '@design-system/checkbox-input'
import cn from 'classnames'
import React from 'react'
import * as api from '~/api/retail'
import {spacing} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ErrorBox} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/invest/sections/bank-linking/BankLinking.scss'
import actions from '~/store/bankLinking/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'

export interface OnNextProps {
    onBack: () => void
    onNext?: () => void
    onChangeTransferAmount: () => void
}

const ChooseWholeDollarRoundups = ({onBack, onNext, onChangeTransferAmount}: OnNextProps) => {
    const dispatch = useAppDispatch()
    const isLoading = useAppSelector(({bankLinking}) => bankLinking.bankLinkingLoadingState === 'loading')
    const optionWholeDollar = useAppSelector(({bankLinking}) => bankLinking.optionWholeDollar)
    const [wholeDollarRoundupsChecked, setWholeDollarRoundupsChecked] = React.useState(!!optionWholeDollar)
    const [processing, setProcessing] = React.useState(false)
    const [status, setStatus] = React.useState('')

    const save = async (wholeDollarEnabled: boolean, whenDone?: () => void) => {
        setProcessing(true)
        if (wholeDollarEnabled === optionWholeDollar) {
            whenDone?.()
        } else {
            await api
                .post('banklinking/set-option-whole-dollar', {set_enabled: wholeDollarEnabled})
                .then(res => {
                    if (res.type === 'empty') {
                        // success
                        setStatus('')
                        dispatch(actions.SetWholeDollar(wholeDollarEnabled))
                        whenDone?.()
                    } else if (res.type === 'error') {
                        setStatus(res.message)
                    } else if (res.type === 'internal_server_error') {
                        setStatus(unknownErrorMessage)
                    } else {
                        assertNever(res)
                    }
                })
                .catch(() => {
                    setStatus(unknownErrorMessage)
                })
        }
        setProcessing(false)
    }

    const handleSubmit = async () => {
        await save(wholeDollarRoundupsChecked, onNext!)
    }

    const handleOnChange = async () => {
        setWholeDollarRoundupsChecked(!wholeDollarRoundupsChecked)
        await save(!wholeDollarRoundupsChecked)
    }

    const actionBar = onNext ? (
        <ActionBar className={styles.stackedButtonGroup}>
            <Button
                additionalClassName={spacing.spaceBelow8}
                dataTestId="button--whole-dollar-roundups-next"
                label="Let's get rounding"
                disabled={false}
                processing={processing}
                onClick={handleSubmit}
            />
            <Button
                type="secondary"
                dataTestId="button--whole-dollar-change-lump-sum"
                label="Change your lump sum transfer amount"
                disabled={false}
                processing={processing}
                onClick={onChangeTransferAmount}
            />
        </ActionBar>
    ) : null

    return (
        <>
            <Toolbar leftButton="back" onLeftButtonClick={onBack} dataTestId="toolbar--banklinking-whole-dollar" />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Page>
                        <h1 className={cn(styles.h1, spacing.spaceBelow12)}>
                            Add whole dollar round ups to reach your goals faster
                        </h1>
                        <p className={spacing.spaceBelow16}>
                            By supercharging your spend, you'll have more to invest before you know it! For every whole
                            dollar transaction, $1 will automatically be added to your tally.
                        </p>
                        <p className={spacing.spaceBelow16}>
                            For example, if you spent exactly $5, you’d get a $1 round-up.
                        </p>
                        <h2 className={spacing.spaceBelow16}>Heads up!</h2>
                        <p className={spacing.spaceBelow16}>
                            If you’re using whole dollar round-ups, and your round-ups goal is $5 or $10, it's likely
                            you'll reach it every few days. You can increase it anytime if you find your spending style
                            means you're hitting your goal more frequently than you’d like.
                        </p>
                        <CheckboxInput
                            id="whole-dollar-roundups"
                            name="whole-dollar-roundups"
                            value={wholeDollarRoundupsChecked}
                            label="Round up whole dollars"
                            onChange={handleOnChange}
                            isTouched={true}
                            dataTestId="checkbox--whole-dollar-roundups"
                        />
                        <ErrorBox message={status} />
                    </Page>
                    {actionBar}
                </>
            )}
        </>
    )
}

export default ChooseWholeDollarRoundups
