import {Button} from '@design-system/button'
import React from 'react'
import styles from './InfiniteLoadTrigger.scss'

interface Props {
    trigger(): void
}

const InfiniteLoadTrigger: React.FunctionComponent<Props> = ({children, trigger}) => {
    const triggerElement = React.useRef<HTMLDivElement>(null)
    const noIntersectionObserver =
        !('IntersectionObserver' in window) ||
        !('IntersectionObserverEntry' in window) ||
        !('intersectionRatio' in window.IntersectionObserverEntry.prototype)

    // set up the intersectionObserver options
    const options = {
        root: null,
        // use 200px from bottom to trigger the callback before it appears on screen
        rootMargin: '0px 0px 200px 0px',
        threshold: 0,
    }

    // when the trigger reaches the margin & threshold, exec. trigger()
    const callback = (entries: IntersectionObserverEntry[]) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                trigger()
            }
        })
    }

    let observer: null | IntersectionObserver

    // check if the browser has intersection observer
    if (noIntersectionObserver) {
        observer = null
    } else {
        observer = new IntersectionObserver(callback, options)
    }

    React.useEffect(() => {
        if (noIntersectionObserver) {
            return
        } else {
            if (!triggerElement.current) {
                return
            }
            if (observer) {
                observer.observe(triggerElement.current)
            }
            return () => {
                if (!triggerElement.current) {
                    return
                }
                if (observer) {
                    observer.unobserve(triggerElement.current)
                }
            }
        }
    })

    // If the browser doesn't have intersection observer: show a loading button
    // If it does, show the infinite loader trigger
    return noIntersectionObserver ? (
        <div className={styles.infiniteLoadTrigger}>
            <Button type="primary" label="Load more" onClick={trigger} dataTestId="button--load-more" />
        </div>
    ) : (
        <div className={styles.infiniteLoadTrigger} ref={triggerElement}>
            {children}
        </div>
    )
}

export default InfiniteLoadTrigger
