import {colour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import cn from 'classnames'
import * as d3scale from 'd3-scale'
import * as d3shape from 'd3-shape'
import {DateTime} from 'luxon'
import React from 'react'
import {AutoFocusInside} from 'react-focus-lock'
import {accessibility} from '~/global/scss/helpers'
import {slicePortfolioHistory, PortfolioHistoryItemWithIndex} from '~/global/utils/slice-history/sliceHistory'
import withMeasure, {MeasureProps} from '~/global/widgets/with-measure/WithMeasure'
import {PortfolioHistoryItem} from '~/store/portfolio/types'
import styles from './ChartThumbnail.scss'

interface Props {
    startDate: string
    portfolioHistory: PortfolioHistoryItem[]
    onClick(): void
}

const graphScaleFactor = 0.2
const topYBound = 10

const ChartThumbnail: React.FC<Props & MeasureProps> = ({
    measuredWidth,
    portfolioHistory,
    startDate,
    onClick,
    onMeasureRef,
}) => {
    const colourMode = useColourMode()
    const width = measuredWidth || 0
    const height = measuredWidth ? measuredWidth * graphScaleFactor : 0

    const portfolioHistorySlice = React.useMemo(
        () => slicePortfolioHistory(portfolioHistory, startDate),
        [portfolioHistory, startDate],
    )
    const maxPortfolioValue = portfolioHistorySlice.reduce((p, c) => Math.max(p, c.portfolio_value, c.cost_basis), 0)

    let xMinValue = 0
    if (portfolioHistory.length && portfolioHistory[0].date !== startDate) {
        xMinValue = -Math.round(
            (DateTime.fromISO(portfolioHistory[0].date).diff(DateTime.fromISO(startDate)).as('days') * 5) / 7,
        )
    }

    const {xScale, yScale} = React.useMemo(
        () => ({
            xScale: d3scale
                .scaleLinear()
                .domain([xMinValue, portfolioHistory.length - 1])
                .range([0, width]),
            yScale: d3scale
                .scaleLinear()
                .domain([0, maxPortfolioValue])
                .range([height - 20, topYBound]),
        }),
        [portfolioHistory.length, width],
    )

    const viewBox = `0 0 ${width} ${height}`

    const contributionLinePath = d3shape
        .line<PortfolioHistoryItemWithIndex>()
        .x(d => xScale(d.index) || 0)
        .y(d => yScale(d.cost_basis) || 0)
        .curve(d3shape.curveStep)

    const marketValueLinePath = d3shape
        .line<PortfolioHistoryItemWithIndex>()
        .x(d => xScale(d.index) || 0)
        .y(d => yScale(d.portfolio_value) || 0)
        .curve(d3shape.curveStep)

    const areaPath = d3shape
        .area<PortfolioHistoryItemWithIndex>()
        .x((_, i) => xScale(i) || 0)
        .y1(history => yScale(history.portfolio_value || 0) || 0)
        .y0(height)
        .curve(d3shape.curveStep)

    return (
        <AutoFocusInside>
            <button
                type="button"
                className={cn(accessibility.button, styles.chart)}
                aria-label="Portfolio history chart"
                onClick={onClick}
            >
                <div data-testid="div--chart-thumbnail" className={styles.container} ref={onMeasureRef}>
                    <svg viewBox={viewBox}>
                        <linearGradient id="thumbnail-gradient" x1="0" x2="0" y1="0" y2="1">
                            <stop offset="0" stopColor={colour('Melon500', colourMode)} stopOpacity="0.4" />
                            <stop
                                offset={height === 0 ? 100 : (height - 25) / height}
                                stopColor={colour('Melon50', colourMode)}
                                stopOpacity="0.4"
                            />
                        </linearGradient>
                        <path d={areaPath(portfolioHistorySlice) || ''} fill="url(#thumbnail-gradient)" />
                        <path className={styles.marketLine} d={marketValueLinePath(portfolioHistorySlice) || ''} />
                        <path
                            className={styles.contributionLine}
                            d={contributionLinePath(portfolioHistorySlice) || ''}
                        />
                    </svg>
                    <span>View chart</span>
                </div>
            </button>
        </AutoFocusInside>
    )
}

export default withMeasure<Props & MeasureProps>(ChartThumbnail)
