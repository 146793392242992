import React from 'react'
import {IntercomProvider as UpstreamIntercomProvider, useIntercom} from 'react-use-intercom'
import {Model} from '~/api/retail/types'
import config from '~/configForEnv'
import {estimateUserLocation} from '~/global/utils/estimate-user-location/estimateUserLocation'
import {useAppSelector} from '~/store/hooks'

/*
    This works in two parts:

    IntercomProvider wraps the react-use-intercom provider and passes the
    correct appId (NZ or AU)

    IntercomBooter "boots" intercom for the currently logged in user
    (automatically shutddown and and rebooting if it changes)
*/

/**
 * Intercom's own shutdown method works, but it seems to have some sort of async delay where we can't be 100% sure
 * the shutdown has occurred before we want to boot again. With that in mind, we're instead going to just delete the
 * cookies ourselves.
 * See https://developers.intercom.com/installing-intercom/web/methods/#deleting-cookies
 */
const shutdownIntercom = () => {
    // First we get a list of all the cookie names we want to delete
    const cookieNames = document.cookie
        .split(/\s*;\s*/)
        .map(raw => raw.split(/\s*=\s*/)[0])
        .filter(cookieName => cookieName.startsWith('intercom-'))

    // Next we construct a list of domains/subdomains that cookies might be set on (i.e. we want to avoid the case where we
    // delete the cookie from app.sharesies.com but not fom sharesies.com)
    const domainParts = location.hostname.split('.')
    const domains = domainParts
        .map((_, index) => domainParts.slice(index).join('.'))
        .filter(domain => !['com', 'co.nz'].includes(domain)) // exclude domains we know it can't be set on (i.e. TLDs)

    // Then we use this weird calling convention to "delete" the cookies
    for (const cookieName of cookieNames) {
        for (const domain of domains) {
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${domain}`
        }
    }
}

const IntercomBooter: React.FunctionComponent<{user: Model.User | null}> = ({user}) => {
    const {boot} = useIntercom()

    React.useEffect(function ensureCleanStartingPoint() {
        // Exactly once when we first mount this component we call shutdownIntercom to clear any lingering sessions
        shutdownIntercom()
    }, [])

    React.useEffect(
        function bootIntercom() {
            // In the case the user is null we boot intercom in an anonymous mode here
            boot(
                user
                    ? {
                          hideDefaultLauncher: true,
                          userId: user.id,
                          email: user.email,
                          name: user.preferred_name,
                          userHash: user.intercom,
                      }
                    : {
                          hideDefaultLauncher: true,
                      },
            )
            return shutdownIntercom
        },
        [user && user.id, user && user.email, user && user.preferred_name],
    )

    return null
}

/**
 * Determine the correct Intercom appId based on the user's location
 */
const guessIntercomAppId = () => {
    return estimateUserLocation({useReferrer: true, useStoredJurisdiction: true, useBrowserTimezone: true}) === 'au'
        ? config.intercomAUAppID
        : config.intercomNZAppID
}

/**
 * Provides react context for useIntercom() hooks by wrapping react-use-intercom's own Provider
 * and passing the appropriate Intercom appId
 */
export const IntercomProvider = ({
    children,
    onHide,
    onShow,
}: {
    children: React.ReactNode
    onShow?: () => void
    onHide?: () => void
}) => {
    const user = useAppSelector(s => s.identity.actor)

    // In the case of user === null, we use intercomRouter() to take a guess at their
    // jurisdiction then "boot" an anonymous user in that Intercom instance
    const appId = user
        ? user.jurisdiction === 'au'
            ? config.intercomAUAppID
            : config.intercomNZAppID
        : guessIntercomAppId()

    return (
        <UpstreamIntercomProvider appId={appId} onShow={onShow} onHide={onHide}>
            <IntercomBooter user={user} />
            {children}
        </UpstreamIntercomProvider>
    )
}
