/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {Currency, CurrencyFromJSON, CurrencyFromJSONTyped, CurrencyToJSON, CurrencyToJSONTyped} from './'

/**
 *
 * @export
 * @interface VerifyPortfolioReturns
 */
export interface VerifyPortfolioReturns {
    /**
     *
     * @type {string}
     * @memberof VerifyPortfolioReturns
     */
    portfolio_id: string
    /**
     *
     * @type {Date}
     * @memberof VerifyPortfolioReturns
     */
    date: DateTime
    /**
     *
     * @type {Currency}
     * @memberof VerifyPortfolioReturns
     */
    currency: Currency
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    cost_basis_rakaia: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    cost_basis_backend: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    cost_basis_diff: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    dividends_aud_rakaia: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    dividends_aud_backend: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    dividends_aud_diff: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    dividends_nzd_rakaia: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    dividends_nzd_backend: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    dividends_nzd_diff: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    dividends_usd_rakaia: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    dividends_usd_backend: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    dividends_usd_diff: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    portfolio_value_rakaia: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    portfolio_value_backend: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    portfolio_value_diff: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    total_return_rakaia: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    total_return_backend: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    total_return_diff: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    simple_return_rakaia: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    simple_return_backend: number
    /**
     *
     * @type {number}
     * @memberof VerifyPortfolioReturns
     */
    simple_return_diff: number
}

export function VerifyPortfolioReturnsFromJSON(json: any): VerifyPortfolioReturns {
    return VerifyPortfolioReturnsFromJSONTyped(json, false)
}

export function VerifyPortfolioReturnsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyPortfolioReturns {
    if (json === undefined || json === null) {
        return json
    }
    return {
        portfolio_id: json['portfolio_id'],
        date: DateTime.fromISO(json['date'], {setZone: true}),
        currency: CurrencyFromJSON(json['currency']),
        cost_basis_rakaia: json['cost_basis_rakaia'],
        cost_basis_backend: json['cost_basis_backend'],
        cost_basis_diff: json['cost_basis_diff'],
        dividends_aud_rakaia: json['dividends_aud_rakaia'],
        dividends_aud_backend: json['dividends_aud_backend'],
        dividends_aud_diff: json['dividends_aud_diff'],
        dividends_nzd_rakaia: json['dividends_nzd_rakaia'],
        dividends_nzd_backend: json['dividends_nzd_backend'],
        dividends_nzd_diff: json['dividends_nzd_diff'],
        dividends_usd_rakaia: json['dividends_usd_rakaia'],
        dividends_usd_backend: json['dividends_usd_backend'],
        dividends_usd_diff: json['dividends_usd_diff'],
        portfolio_value_rakaia: json['portfolio_value_rakaia'],
        portfolio_value_backend: json['portfolio_value_backend'],
        portfolio_value_diff: json['portfolio_value_diff'],
        total_return_rakaia: json['total_return_rakaia'],
        total_return_backend: json['total_return_backend'],
        total_return_diff: json['total_return_diff'],
        simple_return_rakaia: json['simple_return_rakaia'],
        simple_return_backend: json['simple_return_backend'],
        simple_return_diff: json['simple_return_diff'],
    }
}

export function VerifyPortfolioReturnsToJSON(value?: VerifyPortfolioReturns | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        portfolio_id: value.portfolio_id,
        date: value.date.toFormat('yyyy-MM-dd'),
        currency: CurrencyToJSON(value.currency),
        cost_basis_rakaia: value.cost_basis_rakaia,
        cost_basis_backend: value.cost_basis_backend,
        cost_basis_diff: value.cost_basis_diff,
        dividends_aud_rakaia: value.dividends_aud_rakaia,
        dividends_aud_backend: value.dividends_aud_backend,
        dividends_aud_diff: value.dividends_aud_diff,
        dividends_nzd_rakaia: value.dividends_nzd_rakaia,
        dividends_nzd_backend: value.dividends_nzd_backend,
        dividends_nzd_diff: value.dividends_nzd_diff,
        dividends_usd_rakaia: value.dividends_usd_rakaia,
        dividends_usd_backend: value.dividends_usd_backend,
        dividends_usd_diff: value.dividends_usd_diff,
        portfolio_value_rakaia: value.portfolio_value_rakaia,
        portfolio_value_backend: value.portfolio_value_backend,
        portfolio_value_diff: value.portfolio_value_diff,
        total_return_rakaia: value.total_return_rakaia,
        total_return_backend: value.total_return_backend,
        total_return_diff: value.total_return_diff,
        simple_return_rakaia: value.simple_return_rakaia,
        simple_return_backend: value.simple_return_backend,
        simple_return_diff: value.simple_return_diff,
    }
}
