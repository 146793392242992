import {CheckSingle} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {accessibility} from '~/global/scss/helpers'
import {useAutoinvestOrders} from '~/sections/invest/sections/auto-invest/hooks/useAutoinvestOrders'
import DuplicateInstrumentWarningModal from '~/sections/invest/sections/auto-invest/widgets/duplicate-instrument-warning/DuplicateInstrumentWarningModal'
import actions from '~/store/autoinvest/actions'
import {useAppDispatch} from '~/store/hooks'
import styles from './AutoInvestSelectButton.scss'

interface ButtonProps {
    accountRestricted: boolean
    instrumentId: string
    instrumentName: string
    selected: boolean
    autoInvestButtonRef?: React.RefObject<HTMLButtonElement>
}

const AutoInvestSelectButton: React.FunctionComponent<ButtonProps> = ({
    accountRestricted,
    instrumentId,
    instrumentName,
    selected,
    autoInvestButtonRef,
}) => {
    const dispatch = useAppDispatch()

    const [modalOpen, setModalOpen] = React.useState<boolean>(false)

    const [currentOrders] = useAutoinvestOrders()

    const orderWithDuplicateInstrument = currentOrders.find(order =>
        order.allocations.find(a => a.fund_id === instrumentId),
    )

    const handleClick = () => {
        // Show a warning modal if the instrument is in an existing auto-invest order
        if (orderWithDuplicateInstrument) {
            setModalOpen(true)
        } else if (selected) {
            dispatch(actions.RemoveDIYFund(instrumentId))
        } else {
            dispatch(actions.SetDIYFund(instrumentId, '0'))
        }
    }

    const handleFocusOnSelectButton = () => {
        if (autoInvestButtonRef && autoInvestButtonRef.current) {
            autoInvestButtonRef.current.focus()
        }
    }

    return (
        <>
            <button
                className={cn(styles.addFundButton, accessibility.button, {
                    [styles.selected]: selected,
                    [styles.disabled]: accountRestricted,
                })}
                type="button"
                onClick={e => {
                    e.stopPropagation()
                    handleClick()
                }}
                disabled={accountRestricted}
                role="checkbox"
                aria-checked={selected}
            >
                {selected ? <CheckSingle /> : 'Add'}
            </button>

            {selected && (
                <button
                    type="button"
                    className={cn(accessibility.button, styles.accessibleSkipLink)}
                    onClick={e => {
                        e.stopPropagation()
                        handleFocusOnSelectButton()
                    }}
                >
                    Skip to "Select added investments for your DIY order"
                </button>
            )}

            <DuplicateInstrumentWarningModal
                instrumentName={instrumentName}
                isOpen={modalOpen}
                orderName={orderWithDuplicateInstrument?.order_name}
                setModalOpen={() => setModalOpen(false)}
            />
        </>
    )
}

export default AutoInvestSelectButton
