import React from 'react'
import {useNavigate} from 'react-router'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {KSCashMovementActivity} from '~/sections/kiwisaver/state'
import {ActivityCard} from '~/sections/kiwisaver/widgets/activity-card/ActivityCard'

const getIcon = (title: string) => {
    switch (title) {
        case 'Transfer in kiwisaver':
            return 'Transfer'
        case 'Voluntary contributions':
            return 'StarEmpty'
        case 'Member tax credit':
            return 'AddCircle'
        default:
            return 'Deposit'
    }
}
const CashActivityCard: React.FunctionComponent<{activity: KSCashMovementActivity}> = ({activity}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    return (
        <ActivityCard
            key={activity.id}
            icon={getIcon(activity.title)}
            title={activity.title}
            description={activity.description}
            date={activity.date}
            amount={activity.amount}
            onClick={() =>
                navigate(profileUrl('kiwisaver/transaction/:transactionId', {transactionId: activity.id.toString()}))
            }
        />
    )
}

export default CashActivityCard
