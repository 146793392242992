import {Button} from '@design-system/button'
import {DockableModal} from '@design-system/dockable-modal'
import cn from 'classnames'
import React from 'react'
import {Link} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Radio} from '~/global/widgets/form-controls'
import {RWT} from '~/store/identity/types'
import styles from './RWTCalculator.scss'

interface CalculatorStep {
    yes: CalculatorStep | CompleteStep
    no: CalculatorStep | CompleteStep
    back?: CalculatorStep
    stepNumber: number
    question(isDependent: boolean, preferredName: string): React.ReactNode
}

interface CompleteStep {
    rwt: RWT
    back: CalculatorStep
}

const rwt105: RWT = '10.5'
const rwt175: RWT = '17.5'
const rwt30: RWT = '30'
const rwt33: RWT = '33'
const rwt39: RWT = '39'

const Start: CalculatorStep = {
    get yes() {
        return {rwt: rwt105, back: this}
    },
    get no() {
        return Step2
    },
    stepNumber: 1,
    question: (isDependent, preferredName) =>
        `${isDependent ? `Is ${preferredName}’s` : 'Is your'} total taxable income less than $15,600?`,
}

const Step2: CalculatorStep = {
    get yes() {
        return {rwt: rwt175, back: this}
    },
    get no() {
        return Step3
    },
    back: Start,
    stepNumber: 2,
    question: (isDependent, preferredName) =>
        `Is ${isDependent ? `${preferredName}’s` : 'your'} taxable income $15,601 to $53,500?`,
}

const Step3: CalculatorStep = {
    get yes() {
        return {rwt: rwt30, back: this}
    },
    get no() {
        return Step4
    },
    back: Step2,
    stepNumber: 3,
    question: (isDependent, preferredName) =>
        `Is ${isDependent ? `${preferredName}’s` : 'your'} taxable income $53,501 to $78,100?`,
}

const Step4: CalculatorStep = {
    get yes() {
        return {rwt: rwt33, back: this}
    },
    get no() {
        return Step5
    },
    back: Step3,
    stepNumber: 4,
    question: (isDependent, preferredName) =>
        `Is ${isDependent ? `${preferredName}’s` : 'your'} taxable income $78,101 to $180,000?`,
}

const Step5: CalculatorStep = {
    get yes() {
        return {rwt: rwt39, back: this}
    },
    get no() {
        return {rwt: rwt33, back: this}
    },
    back: Step4,
    stepNumber: 5,
    question: (isDependent, preferredName) =>
        `Is ${isDependent ? `${preferredName}’s` : 'your'} taxable income over $180,000?`,
}

interface Props {
    isVisible: boolean
    onSelect: (rwt: RWT) => void
    onClose: () => void
    isDependent: boolean
    preferredName: string
}

const RWTCalculator: React.FunctionComponent<Props> = props => {
    const {preferredName, isDependent, isVisible, onClose, onSelect} = props
    const profileUrl = useProfileUrl()
    const [step, setStep] = React.useState<CalculatorStep | CompleteStep>(Start)
    React.useEffect(() => {
        if (isVisible) {
            setStep(Start)
        }
    }, [isVisible])

    const onSuccess = (rwt: RWT) => {
        onSelect(rwt)
    }

    const renderCalculatorStep = (step: CalculatorStep) => {
        const choices = [
            {
                value: 'yes',
                label: 'Yes',
            },
            {
                value: 'no',
                label: 'No',
            },
        ]

        const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
            const value = e.currentTarget.value
            value === 'yes' ? setStep(step.yes) : setStep(step.no)
        }

        return (
            <div key={step.stepNumber}>
                <div className={styles.stepNumber}>{step.stepNumber}.</div>
                <div className={styles.question}>{step.question(isDependent, preferredName)}</div>
                <Radio
                    name={`step-${step.stepNumber}`}
                    isTouched={false}
                    dataTestId={`radio--${step.stepNumber}`}
                    onChange={handleChange}
                    choices={choices}
                />
                {step.back && (
                    <Button
                        type="secondary"
                        additionalClassName={spacing.spaceAbove24}
                        onClick={() => setStep(step.back!)}
                        label="Back"
                        dataTestId="button--rwt-back"
                    />
                )}
            </div>
        )
    }

    const renderCompleteStep = (step: CompleteStep) => {
        return (
            <>
                <p className={cn(spacing.spaceBelow24, styles.centered)}>
                    Easy as. {isDependent ? `${preferredName}’s` : 'Your'} RWT is:
                </p>
                <div className={styles.rwtValue}>{step.rwt}%</div>
                <p className={styles.footer}>
                    {isDependent ? `${preferredName}’s` : 'Your'} IRD and RWT can be found anytime in{' '}
                    <Link to={profileUrl('settings/tax-details')}>Account &gt; Tax details</Link>.
                </p>
                <Button
                    additionalClassName={spacing.spaceAbove24}
                    dataTestId="button--rwt-done"
                    label="Sweet"
                    onClick={() => onSuccess(step.rwt as RWT)}
                />
                <Button
                    type="secondary"
                    additionalClassName={spacing.spaceAbove24}
                    onClick={() => setStep(step.back)}
                    label="Back"
                    dataTestId="button--rwt-back"
                />
            </>
        )
    }

    function isCalculatorStep(step: CalculatorStep | CompleteStep): step is CalculatorStep {
        return !!(step as CalculatorStep).question
    }

    return (
        <DockableModal
            isOpen={isVisible}
            setIsOpen={_ => {
                // The `_` argument will be called as false, but we don't need to use it in this case
                // Normally we'd just be passing in setModalOpen (setIsOpen={setModalOpen}), but we need to run a
                // side effect `onClose` rather than setting `isOpen(false)`
                onClose()
            }}
            title="RWT calculator"
            dataTestId="dockable-modal--rwt-calculator"
        >
            {isCalculatorStep(step) ? renderCalculatorStep(step) : renderCompleteStep(step)}
        </DockableModal>
    )
}

export default RWTCalculator
