import {useColourMode} from '@design-system/use-colour-mode'
import cn from 'classnames'
import React from 'react'
import type {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Feedback from '~/global/widgets/feedback/Feedback'
import Image from '~/global/widgets/image/Image'
import Animation from '~/global/widgets/lottie-animation/Animation'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useAppSelector} from '~/store/hooks'
import styles from './HelpContentRenderer.scss'

// base help content type - extend this into a new named type if you need to add special case keys,
// and make sure you update the type guard in the appropriate renderer if needed
interface BaseHelpContent<ExtraProps extends {} = any> {
    additionalClassName?: string
    image?: string
    darkImage?: string
    imageAdditionalClassName?: string
    bodyRenderer: React.FunctionComponent<
        {
            homeCurrency: Model.User['home_currency']
            jurisdiction: Model.User['jurisdiction']
            profileUrl: ReturnType<typeof useProfileUrl>
        } & Partial<ExtraProps>
    >
}

// invest learn pages as used in src/page/Invest/Learn/LearnContentPage.tsx
export interface LearnPageContent<ExtraProps extends {} = any> extends BaseHelpContent<ExtraProps> {
    slug: string
    title: string
}

// older intro slide pages as used in src/global/widgets/IntroSlides.tsx
export interface ClassicIntroSlideContent<ExtraProps extends {} = any> extends BaseHelpContent<ExtraProps> {
    title: string
    slug?: string // we don't need slug but keep it optional so that LearnPageContent type can be a superset
}

// newer intro slide pages as used in src/global/widgets/IntroSlides.tsx (no differences compared to the base type)
export type LearnModuleSlideContent<ExtraProps extends {} = any> = BaseHelpContent<ExtraProps>

// animated product intro slide pages as used in src/global/widgets/IntroSlides.tsx
export interface ProductIntroSlideContent<ExtraProps extends {} = any> extends BaseHelpContent<ExtraProps> {
    animation?: Promise<any> // Lottie animation
    animationDark?: Promise<any> // Lottie animation, dark mode
    backgroundColour: string
    buttonLabel: string
    onButtonClick?: () => void
    title: string
}

export type HelpContent<ExtraProps extends {} = any> =
    | LearnPageContent<ExtraProps>
    | ClassicIntroSlideContent<ExtraProps>
    | LearnModuleSlideContent<ExtraProps>
    | ProductIntroSlideContent<ExtraProps>

/**
 * Executes the contentRenderer function on a given help content type, pulling in commonly used data
 * from the Redux store (jurisdiction and homeCurrency) and optionally any other params you want.
 */
export const HelpContentBodyRenderer = <ExtraProps extends {}>(props: {page: HelpContent<ExtraProps>} & ExtraProps) => {
    const {page: Page} = props
    const jurisdiction = useAppSelector(state => state.identity.user!.jurisdiction)
    const homeCurrency = useAppSelector(state => state.identity.user!.home_currency)
    const profileUrl = useProfileUrl()

    return (
        <Page.bodyRenderer jurisdiction={jurisdiction} homeCurrency={homeCurrency} profileUrl={profileUrl} {...props} />
    )
}

/**
 * Help content renderers: these are bundled as they share many styles. Used for:
 *
 * - LearnPage (single Invest Learn pages, many styles are shared with ClassicIntroSlide)
 * - ClassicIntroSlide (older intro 'education' slides, many styles are shared with LearnContent)
 * - LearnModuleSlide (newer intro slide style)
 * - ProductIntroSlide (animated backgrounds and support for Lottie animations or images)
 *
 * Note that these "officially" accept any type of HelpContent but they narrow their types
 * as appropriate using a custom type guard per content type.
 */
export const HelpContentRenderer = {
    LearnPage: ({content}: {content: HelpContent}) => {
        const colourMode = useColourMode()
        // narrow the types to confirm we have the right properties to render
        const learnPageTypeGuard = (content: HelpContent) => {
            if ('slug' in content) {
                return content
            }
            throw new Error('Wrong renderer type for the supplied content')
        }
        const {image, darkImage, slug, title, additionalClassName, imageAdditionalClassName} =
            learnPageTypeGuard(content)

        return (
            <>
                <Toolbar dataTestId="toolbar--learn-page" leftButton="back" />
                <Page>
                    <div className={cn(styles.learnPage, spacing.spaceBelow64, additionalClassName)}>
                        <div className={styles.background}>
                            <div
                                className={cn(
                                    styles.image,
                                    styles.animation,
                                    styles.learnPageImage,
                                    imageAdditionalClassName,
                                )}
                                style={{
                                    backgroundImage: `url(${colourMode === 'dark' && darkImage ? darkImage : image})`,
                                }}
                            />
                        </div>
                        <h2 className={styles.title}>{title}</h2>
                        <div className={styles.body}>
                            <HelpContentBodyRenderer page={content} />
                        </div>
                    </div>
                </Page>
                <div className={styles.feedback}>
                    <Feedback category="Learn page" actionIdentifier={`/${slug}`} />
                </div>
            </>
        )
    },

    ClassicIntroSlide: ({content, active}: {content: HelpContent; active: boolean}) => {
        const colourMode = useColourMode()
        // narrow the types to confirm we have the right properties to render
        const classicIntroSlideTypeGuard = (content: HelpContent) => {
            if ('title' in content) {
                return content
            }
            throw new Error('Wrong renderer type for the supplied content')
        }
        const {additionalClassName, title, image, darkImage, imageAdditionalClassName} =
            classicIntroSlideTypeGuard(content)

        return (
            <div className={cn(styles.classicIntroSlide, additionalClassName)}>
                <div className={styles.background}>
                    <div
                        className={cn(
                            styles.image,
                            {
                                [styles.animation]: active,
                            },
                            imageAdditionalClassName,
                        )}
                        style={{
                            backgroundImage: `url(${colourMode === 'dark' && darkImage ? darkImage : image})`,
                        }}
                    />
                </div>
                <h2 className={styles.title}>{title}</h2>
                <div className={styles.body}>
                    <HelpContentBodyRenderer page={content} />
                </div>
            </div>
        )
    },

    LearnModuleSlide: ({content, active}: {content: HelpContent; active: boolean}) => {
        // the LearnModuleSlideContent type has the same base info as all the other types so we don't need to narrow to confirm we have the right properties
        const {additionalClassName, image, darkImage, imageAdditionalClassName} = content
        return (
            <div className={cn(styles.learnModuleSlide, additionalClassName)}>
                <div
                    className={cn(
                        styles.learnModuleSlideImage,
                        {
                            [styles.animation]: active,
                        },
                        imageAdditionalClassName,
                    )}
                >
                    {image && <Image src={image} darkSrc={darkImage} />}
                </div>
                <div className={styles.body}>
                    <HelpContentBodyRenderer page={content} />
                </div>
            </div>
        )
    },

    ProductIntroSlide: ({content, active}: {content: HelpContent; active: boolean}) => {
        const colourMode = useColourMode()
        // narrow the types to confirm we have the right properties to render
        const productIntroSlideTypeGuard = (content: HelpContent) => {
            if ('backgroundColour' in content) {
                return content
            }
            throw new Error('Wrong renderer type for the supplied content')
        }
        const {animation, animationDark, title, image, darkImage, imageAdditionalClassName, additionalClassName} =
            productIntroSlideTypeGuard(content)
        const [animationData, setAnimationData] = React.useState()

        React.useEffect(() => {
            if (animation) {
                if (colourMode === 'dark' && animationDark) {
                    animationDark.then(setAnimationData)
                } else {
                    animation.then(setAnimationData)
                }
            }
        }, [animation, animationDark, colourMode])

        return (
            <div className={cn(styles.productIntroSlide, additionalClassName)}>
                {animationData && (
                    <Animation
                        className={cn(styles.header, imageAdditionalClassName)}
                        autoplay={false}
                        loop={false}
                        playing={active}
                        animationData={animationData}
                    />
                )}
                {!animationData && image && (
                    <div
                        className={cn(styles.header, imageAdditionalClassName)}
                        style={{
                            backgroundImage: `url(${colourMode === 'dark' && darkImage ? darkImage : image})`,
                        }}
                    />
                )}
                <div>
                    <h2 className={styles.title}>{title}</h2>
                    <div className={styles.body}>
                        <HelpContentBodyRenderer page={content} />
                    </div>
                </div>
            </div>
        )
    },
}
