import * as braze from '@braze/web-sdk'
import {Model} from '~/api/retail/types'
import config from '~/configForEnv'

// Braze SDK doco at https://js.appboycdn.com/web-sdk/latest/doc/modules/appboy.html

// See https://js.appboycdn.com/web-sdk/latest/doc/modules/appboy.html#initialize for more info
const brazeIsInitialised = braze.initialize(config.brazeApiKey, {
    baseUrl: config.brazeBaseUrl,
})

if (brazeIsInitialised) {
    // We need to call subscribeToContentCardUpdates() here to ensure content cards are wiped correctly on logout.
    braze.subscribeToContentCardsUpdates((): undefined => undefined)
    braze.openSession()
}

export function changeUser(user: Model.User): void {
    if (brazeIsInitialised) {
        braze.changeUser(user.id)
    }
}

export function showContentCards(div: HTMLElement): void {
    if (brazeIsInitialised) {
        braze.showContentCards(div)
    }
}

export function requestContentCardsRefresh(successCallback?: () => void, failCallback?: () => void): void {
    if (brazeIsInitialised) {
        braze.requestContentCardsRefresh(successCallback, failCallback)
    }
}

export function getCachedContentCards(): braze.ContentCards | undefined {
    if (brazeIsInitialised) {
        const cards = braze.getCachedContentCards()

        // log impression for any control cards
        cards.cards.forEach(card => card.isControl && !card.viewed && logContentCardImpressions([card]))

        return cards
    }
}

export function logContentCardImpressions(contentCards: braze.Card[]): boolean {
    if (brazeIsInitialised) {
        return braze.logContentCardImpressions(contentCards)
    }

    return false
}

export function logCardDismissal(card: braze.Card): void {
    if (brazeIsInitialised) {
        braze.logCardDismissal(card)
    }
}

export function logCardClick(card: braze.Card): void {
    if (brazeIsInitialised) {
        braze.logCardClick(card, true)
    }
}

export function logCustomEvent(eventName: string, eventProperties: any | undefined): void {
    if (brazeIsInitialised) {
        braze.logCustomEvent(eventName, eventProperties)
    }
}
