import {Button} from '@design-system/button'
import React from 'react'
import {useNavigate} from 'react-router'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {spacing} from '~/global/scss/helpers'
import {dateFormatShortDayFullMonth} from '~/global/utils/format-date/formatDate'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {useCurrentSubscriptionPlan} from '~/global/utils/subscription-hooks/subscriptionHooks'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ErrorBox} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {PlanDetail} from '~/sections/user/sections/settings/sections/plans/pages/confirm/PlanConfirm'
import {useAppDispatch} from '~/store/hooks'
import actions from '~/store/plan/actions'
import {BillingCycle} from '~/store/plan/types'

interface OwnProps {
    billingCycle?: BillingCycle
}

const AccountPlanChangeBillingCycleConfirm: React.FunctionComponent<OwnProps> = ({
    billingCycle: billingCycle = 'MONTHLY',
}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const [currentSubscription, currentSubscriptionLoaded] = useCurrentSubscriptionPlan()
    const renewalDate = currentSubscription?.expires.toFormat(dateFormatShortDayFullMonth)

    const [isProcessing, setIsProcessing] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')

    if (!currentSubscriptionLoaded) {
        return <Loading isPineapple />
    }

    if (!currentSubscription) {
        return <WeSlippedUp />
    }

    const nextPlan = currentSubscription.auto_renew_into_plan ?? currentSubscription.plan
    const nextBillingCycleData = nextPlan.billing_options[billingCycle]

    return (
        <>
            <Toolbar
                dataTestId="toolbar--subscription-confirm"
                leftButton="back"
                title="Confirm payment frequency"
                onLeftButtonClick={() => {
                    navigate(-1)
                }}
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <p className={spacing.spaceBelow16}>
                    Your {billingCycle.toLowerCase()} fee will be charged at the end of your current payment period, on{' '}
                    {renewalDate}
                    {billingCycle === 'ANNUAL' && ', and your plan coverage will renew every month'}.
                </p>
                <p className={spacing.spaceBelow16}>
                    If we’re unable to process your payment,{' '}
                    {billingCycle === 'ANNUAL'
                        ? 'your plan will be cancelled'
                        : 'we’ll try one more time before cancelling your plan'}
                    .
                </p>
                <p className={spacing.spaceBelow16}>
                    You can manage your payment method and payment frequency from your plan page.
                </p>
                <PlanDetail
                    billingCycle={nextBillingCycleData}
                    startDate={currentSubscription.expires}
                    planData={nextPlan}
                    paymentMethod={currentSubscription.payment_method}
                />
                <ErrorBox message={errorMessage} />
            </Page>
            <ActionBar>
                <Button
                    label="Confirm"
                    dataTestId="button--confirm-subscription"
                    disabled={!!errorMessage}
                    processing={isProcessing}
                    onClick={async () => {
                        setIsProcessing(true)
                        const error = await dispatch(
                            actions.ChangePlan(
                                undefined,
                                billingCycle,
                                undefined,
                                currentSubscription.billing_cycle,
                                undefined,
                            ),
                        )
                        setIsProcessing(false)

                        if (error) {
                            setErrorMessage(error.message)
                            return
                        }

                        sendWrapperAppMessage({type: 'identityUpdated'})
                        sendWrapperAppMessage({type: 'goBack'})
                        navigate(profileUrl('settings/plan'))
                        Toast('You’ve changed your payment frequency—nice one!')
                    }}
                />
            </ActionBar>
        </>
    )
}

export default AccountPlanChangeBillingCycleConfirm
