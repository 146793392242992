import {Button} from '@design-system/button'
import {CheckboxInput} from '@design-system/checkbox-input'
import {useQueryClient} from '@tanstack/react-query'
import {useAtom} from 'jotai'
import React from 'react'
import {useRetailPost} from '~/api/query/retail'
import {Request} from '~/api/retail/types'
import * as rollbar from '~/api/rollbar/rollbar'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import HelpCentreLink from '~/global/widgets/help-centre-link/HelpCentreLink'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {FonterraPersonalSignUpCommonProps} from '~/sections/moose/sections/fonterra-personal-sign-up/FonterraPersonalSignUp'
import {stagedFonterraPersonalSignUpAtom} from '~/sections/moose/sections/fonterra-personal-sign-up/state'
import PrivacyPolicyModalLink from '~/sections/user/sections/sign-up/pages/about-you/widgets/privacy-policy-modal-link/PrivacyPolicyModalLink'
import {NZ_TC_VERSION} from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/TermsAndConditions'

// NOTE: Similar versions of this component exist elsewhere.
const TermsAndConditions = ({progressStep, regressStep}: FonterraPersonalSignUpCommonProps) => {
    const [stagedFonterraPersonalSignUp, updateStagedFonterraPersonalSignUp] = useAtom(stagedFonterraPersonalSignUpAtom)
    const [acceptTerms, setAcceptTerms] = React.useState(stagedFonterraPersonalSignUp?.tcVersion !== undefined)
    const [isProcessing, setIsProcessing] = React.useState(false)
    const queryClient = useQueryClient()

    const fonterraEnabledMutation = useRetailPost({path: 'fonterra/signup/enable-personal-account'})

    const onSubmit = async () => {
        const featureRequestPayload: Request.FonterraEnablePersonalAccount = {
            type: 'fonterra_enable_personal_account',
            prescribed_participant: stagedFonterraPersonalSignUp.prescribedPerson?.participant,
            prescribed_email: stagedFonterraPersonalSignUp.prescribedPerson?.prescribedEmail,
            annual_amount: stagedFonterraPersonalSignUp.natureAndPurpose?.annualAmount,
            frequency: stagedFonterraPersonalSignUp.natureAndPurpose?.frequency,
            other_purpose: stagedFonterraPersonalSignUp.natureAndPurpose?.otherPurpose,
            tc_version: NZ_TC_VERSION,
            ird_number: stagedFonterraPersonalSignUp.irdNumber,
            purposes: stagedFonterraPersonalSignUp.natureAndPurpose?.purposes,
            foreign_tax_resident: stagedFonterraPersonalSignUp.foreignTaxResident,
            foreign_tax_countries: stagedFonterraPersonalSignUp.foreignTaxCountries,
        }

        const response = await fonterraEnabledMutation.mutateAsync(featureRequestPayload)
        switch (response.type) {
            case 'fonterra_signup_issue':
                rollbar.sendError(`Failed to create fonterra enabled sharesies account = ${response.type}`)
                break
        }
        if (response.type === 'identity_authenticated') {
            queryClient.clear()
            progressStep()
        }
        setIsProcessing(false)
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--fonterra-signup-terms-and-conditions"
                leftButton="back"
                onLeftButtonClick={regressStep}
                title="Agree to the terms and conditions"
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <CheckboxInput
                    dataTestId="checkbox--accept-terms"
                    id="accept-terms"
                    name="accept-terms"
                    isTouched={true}
                    value={acceptTerms}
                    label={
                        <span>
                            I’ve read and agree to the Sharesies{' '}
                            <ButtonAsLink dataTestId="button-as-link--terms-of-service">
                                <a target="_blank" href="https://www.sharesies.nz/terms-and-conditions" rel="noopener">
                                    General Terms and Conditions
                                </a>
                            </ButtonAsLink>
                            ,{' '}
                            <ButtonAsLink dataTestId="button-as-link--invest-supplement">
                                <a
                                    target="_blank"
                                    href="https://www.sharesies.nz/legal-documents/invest-terms-and-conditions"
                                    rel="noopener"
                                >
                                    Invest Terms and Conditions
                                </a>
                            </ButtonAsLink>
                            , <PrivacyPolicyModalLink />, and{' '}
                            <HelpCentreLink
                                linkText="Market Conduct Rules"
                                nzArticle="8482855-market-conduct-rules"
                                auArticle="8482859-market-conduct-rules"
                            />
                            .
                        </span>
                    }
                    onChange={e => {
                        const isChecked = (e.currentTarget as HTMLInputElement).checked
                        setAcceptTerms(isChecked)
                    }}
                />
                <Button
                    dataTestId="button--next"
                    onClick={() => {
                        setIsProcessing(true)
                        updateStagedFonterraPersonalSignUp({...stagedFonterraPersonalSignUp, tcVersion: NZ_TC_VERSION})
                        onSubmit()
                    }}
                    label="Next"
                    disabled={!acceptTerms || isProcessing}
                    processing={isProcessing}
                    pageButton
                    isSubmit
                />
            </Page>
        </>
    )
}

export default TermsAndConditions
