import {createClient} from 'contentful'
import config from '~/configForEnv'
const {contentfulAccessToken, contentfulEnvironment, contentfulHost, contentfulSpaceId} = config

export const contentfulClient = createClient({
    space: contentfulSpaceId,
    accessToken: contentfulAccessToken,
    host: contentfulHost,
    environment: contentfulEnvironment,
})
