import {DateTime} from 'luxon'
import {isDisorderlyLimit} from '~/global/utils/is-disorderly-limit/isDisorderlyLimit'
import {StripeFieldValue} from '~/global/widgets/form-controls/stripe/StripeElements'
import {Instrument} from '~/store/instrument/types'

export type ValidationRule<T, FormValues = {[field: string]: any}> = (
    value: T,
    values: FormValues,
) => Promise<string | void> | string | void

type FormErrors<FormValues> = Partial<{[K in keyof FormValues]: string}>

type ValidationFunction<FormValues> = (values: FormValues) => Promise<FormErrors<FormValues>>

export const generate = <FormValues>(formRules: {
    [Field in keyof FormValues]: ValidationRule<FormValues[Field], FormValues>[]
}): ValidationFunction<FormValues> => {
    return async (values: FormValues) => {
        const errors: FormErrors<FormValues> = {}

        for (const field in formRules) {
            const fieldRules = formRules[field]
            for (const rule of fieldRules) {
                const error = await rule(values[field], values)
                if (error) {
                    errors[field] = error
                    break
                }
            }
        }

        if (Object.keys(errors).length) {
            return errors
        }

        return {}
    }
}

/**
 * Generate a validation function based on the provided form props
 *
 * @param {Function} generateFn - Factory function to return the field validation rules
 * @returns {Function} Returns a configured formik validation function
 */
export function generateWithProps<FormValues, Props>(
    generateFn: (props: Props) => {
        [Field in keyof FormValues]: ValidationRule<FormValues[Field], FormValues>[]
    },
) {
    return async (values: FormValues, props: Props) => {
        const formRules = generateFn(props)
        return generate<FormValues>(formRules)(values)
    }
}

export function required(message: string = 'This field is required'): ValidationRule<any> {
    return value => (value || value === false ? undefined : message)
}

export function isTrue(message: string): ValidationRule<boolean> {
    return value => (value ? undefined : message)
}

export function isFalse(message: string): ValidationRule<boolean> {
    return value => (value ? message : undefined)
}

export function orderlyLimit(instrument: Instrument): ValidationRule<string | undefined> {
    return value => {
        if (!value) {
            return // Nothing to validate
        }
        const {isDisorderly, message} = isDisorderlyLimit(value, instrument)
        if (isDisorderly) {
            return message
        }
        return
    }
}

export function email(): ValidationRule<string | undefined> {
    return value => {
        if (!value) {
            return // Nothing to validate
        }
        if (value.indexOf('@') === -1) {
            return 'Email addresses must contain an @'
        }
        if (value.indexOf(' ') !== -1) {
            return 'Email addresses cannot contain a space'
        }
        return
    }
}

export function choices<T extends string>(...values: T[]): ValidationRule<T | undefined> {
    return value => {
        if (!value) {
            return // Nothing to validate
        }
        if (values.indexOf(value) === -1) {
            return 'Invalid value'
        }
    }
}

export function irdNumber(): ValidationRule<string | undefined> {
    const check1 = [3, 2, 7, 6, 5, 4, 3, 2]
    const check2 = [7, 4, 3, 2, 5, 2, 7, 6]
    const invalidMessage = 'Invalid IRD number'

    return value => {
        if (!value) {
            return // Nothing to validate
        }
        if (typeof value !== 'string') {
            return 'IRD number must be a string'
        }

        const cleanValue = value.replace(/ /g, '')

        if (cleanValue.match(/\D/)) {
            return 'IRD number should only contain digits and spaces'
        }

        const intValue = parseInt(cleanValue, 10)

        if (intValue < 10000000 || intValue > 150000000) {
            return invalidMessage
        }

        let baseNumber = cleanValue.replace(/\d$/, '')
        const checkDigit = parseInt(cleanValue[cleanValue.length - 1], 10)

        if (baseNumber.length === 7) {
            baseNumber = `0${baseNumber}`
        }

        const checksum1 =
            baseNumber
                .split('')
                .map((c, i) => parseInt(c, 10) * check1[i])
                .reduce((acc, v) => acc + v, 0) % 11

        if (checksum1 === 0) {
            if (checkDigit === 0) {
                // Passes validation
                return
            } else {
                return invalidMessage
            }
        }

        const checksum2 = 11 - checksum1

        if (checksum2 >= 0 && checksum2 <= 9) {
            // step 5
            if (checkDigit === checksum2) {
                // Passes validation
                return
            } else {
                return invalidMessage
            }
        }

        // step 4
        const checksum3 =
            baseNumber
                .split('')
                .map((c, i) => parseInt(c, 10) * check2[i])
                .reduce((acc, v) => acc + v, 0) % 11

        if (checksum3 === 0) {
            if (checkDigit === 0) {
                // Passes validation
                return
            } else {
                return invalidMessage
            }
        }

        const checksum4 = 11 - checksum3

        if (checksum4 === 10) {
            return invalidMessage
        }

        if (checkDigit === checksum4) {
            // Passes validation
            return
        } else {
            return invalidMessage
        }
    }
}

export function tfnNumber(): ValidationRule<string | undefined> {
    // 8 and 9 digit tfn numbers use different check digit sequences
    const check8digit = [0, 10, 7, 8, 4, 6, 3, 5, 1]
    const check9digit = [1, 4, 3, 7, 5, 8, 6, 9, 10]
    const invalidMessage = 'Invalid TFN'

    return value => {
        if (!value) {
            return // Nothing to validate
        }
        if (typeof value !== 'string') {
            return 'TFN must be a string'
        }
        const cleanValue = value.replace(/ /g, '')

        if (cleanValue.match(/\D/)) {
            return 'TFN should only contain digits and spaces'
        }

        let checkset: number[]

        if (cleanValue.length === 9) {
            if (cleanValue.startsWith('0')) {
                checkset = check8digit
            } else {
                checkset = check9digit
            }
        } else {
            return 'TFN length must be 9 digits. If your individual TFN is 8 digits, please add a zero at the start.'
        }

        const baseNumber = cleanValue

        const checksum =
            baseNumber
                .split('')
                .map((c, i) => parseInt(c, 10) * checkset[i])
                .reduce((acc, v) => acc + v, 0) % 11

        if (checksum === 0) {
            return //passes validation iff remainder is 0
        }

        return invalidMessage
    }
}

export function password(): ValidationRule<string | undefined> {
    return async value => {
        const {estimateStrength} = await import('@design-system/password/estimateStrength')

        if (!value) {
            return
        }
        if (value.length > 256) {
            return 'Your password is too long'
        }
        const result = estimateStrength(value)
        if (result.score < 3) {
            return 'Your password is too weak'
        }
        return
    }
}

export function sameAs(field: string, message: string): ValidationRule<any> {
    return (value, data) => (value === data[field] ? undefined : message)
}

export function regexp(re: RegExp, message: string = 'Invalid valid'): ValidationRule<string> {
    return value => (!value || re.test(value) ? undefined : message)
}

interface MoneyOptions {
    maxDecimalPlaces?: number
    allowNegative: boolean
    allowZero: boolean
}
const moneyDefaultOptions: MoneyOptions = {
    maxDecimalPlaces: 2,
    allowNegative: false,
    allowZero: false,
}

export function money(userOpts?: Partial<MoneyOptions>): ValidationRule<string> {
    const opt: MoneyOptions = {...moneyDefaultOptions, ...userOpts}

    return value => {
        if (!value) {
            return // Nothing to validate
        }
        const match = /^-?(?:[0-9]+(?:\.([0-9]*))?)$/.exec(value)
        if (!match) {
            return 'Must be a valid number'
        }

        if (opt.maxDecimalPlaces === 0 && match[1]) {
            return "Can't have any decimal places"
        }

        if (opt.maxDecimalPlaces && match[1] && match[1].length > opt.maxDecimalPlaces) {
            return `Can have at most ${opt.maxDecimalPlaces} decimal places`
        }

        const amount = parseFloat(value)
        if (isNaN(amount) || !isFinite(amount)) {
            return 'Must be a valid number'
        }

        if (!opt.allowNegative && amount < 0) {
            return "Can't be negative"
        }

        if (!opt.allowZero && amount === 0) {
            return "Can't be zero"
        }

        return // No error
    }
}

export function greaterThan(threshold: number, message: string): ValidationRule<string> {
    return value => {
        if (!value) {
            return // Nothing to validate
        }
        const amount = parseFloat(value)
        if (amount <= threshold) {
            return message
        }
        return
    }
}

export function minimum(min: number, message: string): ValidationRule<string> {
    return value => {
        if (!value) {
            return // Nothing to validate
        }
        const amount = parseFloat(value)
        if (amount < min) {
            return message
        }
        return
    }
}

export function maximum(max: number, message: string): ValidationRule<string> {
    return value => {
        if (!value) {
            return // Nothing to validate
        }
        const amount = parseFloat(value)
        if (amount > max) {
            return message
        }
        return
    }
}

export function stripeField(): ValidationRule<StripeFieldValue> {
    return value => {
        if (value.empty) {
            return 'This field is required'
        }
        if (value.error && value.error.message) {
            return value.error.message
        }
    }
}

export function date(): ValidationRule<DateTime | undefined> {
    return value => {
        if (value && !value.isValid) {
            return value.invalidReason || undefined
        }
    }
}

export function dateMinYear(minYear: number): ValidationRule<DateTime | undefined> {
    return value => {
        if (value && value.year <= minYear) {
            return `Please enter a date after ${minYear}`
        }
        return undefined
    }
}

export function notFutureDate(): ValidationRule<DateTime | undefined> {
    return value => {
        // checks if date entered is not in the future
        if (value && DateTime.now() < value) {
            return "This date shouldn't be in the future"
        }
        return undefined
    }
}

export function minLength(min: number, message: string): ValidationRule<string> {
    return value => {
        if (!value) {
            return // Nothing to validate
        }
        // valid other field is more than min alphabet (english) characters
        const characters = value.replace(/[^A-Za-z]/g, '')
        if (characters.length <= min) {
            return message
        }
    }
}

export function maxLength(max: number, message: string): ValidationRule<string | undefined> {
    return value => {
        if (!value) {
            return // Nothing to validate
        }

        message.trim()

        // valid field is less than max characters
        if (value.length > max) {
            return message
        }
    }
}

export function hin(): ValidationRule<string | undefined> {
    /**
     * They can be UP TO 11 characters, starting with an X
     */
    return value => {
        const re = /^[xX](\d{1,10})$/

        if (value && !re.test(value)) {
            return `Please enter a valid HIN number, it should start with an X and be followed by 10 numbers`
        }

        return undefined
    }
}
export function srn(): ValidationRule<string | undefined> {
    /**
     * They can be UP TO 12 characters, starting with anything other than X (HIN)
     */
    return value => {
        const re = /^[^xX](\d{1,11})$/

        if (value && !re.test(value)) {
            return `Please enter a valid SRN number, it usually starts with an I or C`
        }

        return undefined
    }
}
export function csnOrHn(): ValidationRule<string | undefined> {
    /**
     * They can be up to 10 characters
     */
    return value => {
        const re = /^[\w-](\d{1,9})$/

        if (value && !re.test(value)) {
            return `Please enter a valid CSN or HN number`
        }

        return undefined
    }
}

export function notSameValue<T>(amount: T, message: string): ValidationRule<T> {
    return value => {
        if (!value) {
            return // Nothing to validate
        }

        // field is not the same as value
        if (value === amount) {
            return message
        }

        return undefined
    }
}

export function middleName(): ValidationRule<string | undefined> {
    return value => {
        const re = /[a-z]/i
        if (!value) {
            return // Nothing to validate
        }
        if (!re.test(value)) {
            return 'Your middle name must have at least one letter'
        }
        return undefined
    }
}

export function nonWhitespace(message: string = 'This field must not be blank'): ValidationRule<string | undefined> {
    return value => (value && value.trim().length > 0 ? undefined : message)
}

export function minDigits(min: number, message: string = 'Number is too short'): ValidationRule<string | undefined> {
    return value => {
        if (!value) {
            return // Nothing to validate
        }
        // field is less than min digits
        const digits = value.replace(/\D/g, '')

        if (digits.length < min) {
            return message
        }
    }
}

export function maxDigits(max: number, message: string = 'Number is too long'): ValidationRule<string | undefined> {
    return value => {
        if (!value) {
            return // Nothing to validate
        }
        // field is more than max digits
        const digits = value.replace(/\D/g, '')

        if (digits.length > max) {
            return message
        }
    }
}
