import React from 'react'
import votingSlide5Dark from '~/global/assets/images/intro-slides/voting-slides/voting-slide-5-dark.svg'
import votingSlide5Light from '~/global/assets/images/intro-slides/voting-slides/voting-slide-5-light.svg'
import {LearnModuleSlideContent} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {IntroSlides} from '~/global/widgets/intro-slides/IntroSlides'
import funds from '~/sections/kiwisaver/sections/sign-up/assets/images/fund-types/1-funds.svg'
import upsAndDowns from '~/sections/kiwisaver/sections/sign-up/assets/images/fund-types/10-ups-and-downs.svg'
import investmentMixesDark from '~/sections/kiwisaver/sections/sign-up/assets/images/fund-types/2-investment-mixes-dark.svg'
import investmentMixesLight from '~/sections/kiwisaver/sections/sign-up/assets/images/fund-types/2-investment-mixes-light.svg'
import higherRiskDark from '~/sections/kiwisaver/sections/sign-up/assets/images/fund-types/3-higher-risk-dark.svg'
import higherRiskLight from '~/sections/kiwisaver/sections/sign-up/assets/images/fund-types/3-higher-risk-light.svg'
import lowerRiskDark from '~/sections/kiwisaver/sections/sign-up/assets/images/fund-types/4-lower-risk-dark.svg'
import lowerRiskLight from '~/sections/kiwisaver/sections/sign-up/assets/images/fund-types/4-lower-risk-light.svg'
import fruitBowlDark from '~/sections/kiwisaver/sections/sign-up/assets/images/fund-types/5-fruit-bowl-dark.svg'
import fruitBowlLight from '~/sections/kiwisaver/sections/sign-up/assets/images/fund-types/5-fruit-bowl-light.svg'
import growthReturns from '~/sections/kiwisaver/sections/sign-up/assets/images/fund-types/6-growth-returns.svg'
import growthVolatilityDark from '~/sections/kiwisaver/sections/sign-up/assets/images/fund-types/7-growth-volatility-dark.svg'
import growthVolatilityLight from '~/sections/kiwisaver/sections/sign-up/assets/images/fund-types/7-growth-volatility-light.svg'
import conservativeReturns from '~/sections/kiwisaver/sections/sign-up/assets/images/fund-types/8-conservative-returns.svg'
import conservativeVolatilityDark from '~/sections/kiwisaver/sections/sign-up/assets/images/fund-types/9-conservative-volatility-dark.svg'
import conservativeVolatilityLight from '~/sections/kiwisaver/sections/sign-up/assets/images/fund-types/9-conservative-volatility-light.svg'
import styles from './Slides.scss'

const FundTypesIntroContent: LearnModuleSlideContent[] = [
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: funds,
        bodyRenderer: () => <p>Different KiwiSaver funds have different levels of risk and return.</p>,
    },
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: investmentMixesLight,
        darkImage: investmentMixesDark,
        bodyRenderer: () => <p>Funds achieve this by investing in different types of investments.</p>,
    },
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: higherRiskLight,
        darkImage: higherRiskDark,
        bodyRenderer: () => <p>Some are higher risk, and have the potential for higher returns or losses.</p>,
    },
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: lowerRiskLight,
        darkImage: lowerRiskDark,
        bodyRenderer: () => <p>Some are lower risk, and have the potential for lower returns or losses.</p>,
    },
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: fruitBowlLight,
        darkImage: fruitBowlDark,
        bodyRenderer: () => <p>Fund providers decide which mix of investments align with the fund aims.</p>,
    },
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: growthReturns,
        bodyRenderer: () => <p>Growth funds have the potential for higher returns or losses ...</p>,
    },
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: growthVolatilityLight,
        darkImage: growthVolatilityDark,
        bodyRenderer: () => <p>... and the balance tends to go up and down more (higher volatility).</p>,
    },
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: conservativeReturns,
        bodyRenderer: () => <p>Conservative funds have the potential for lower returns or losses ...</p>,
    },
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: conservativeVolatilityLight,
        darkImage: conservativeVolatilityDark,
        bodyRenderer: () => <p>... and the balance tends to go up and down less (lower volatility).</p>,
    },
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: upsAndDowns,
        bodyRenderer: () => <p>Investing for the long term can help you ride out the market’s ups and downs.</p>,
    },
    {
        additionalClassName: styles.ksSlides,
        imageAdditionalClassName: styles.slideImage,
        image: votingSlide5Light,
        darkImage: votingSlide5Dark,
        bodyRenderer: () => (
            <p>
                Understand your attitude to risk by{' '}
                <a href="https://sorted.org.nz/tools/investor-profiler/" target="_blank" rel="noreferrer">
                    working out your risk profile
                </a>
                .
            </p>
        ),
    },
]

interface FundTypesIntroSlideProps {
    onComplete: () => void
    onBack?: () => void
}

export const FundTypesIntroSlides = ({onComplete, onBack}: FundTypesIntroSlideProps) => {
    return (
        <IntroSlides
            content={FundTypesIntroContent}
            onComplete={onComplete}
            onCompleteLabel="Got it!"
            onBack={onBack}
        />
    )
}
