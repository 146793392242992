import {TextInput, TextProps} from '@design-system/text-input'
import React from 'react'
import {withFocus, withId} from '../common'

const Phone = (props: TextProps) => (
    <TextInput
        {...props}
        inputProps={() => ({
            type: 'tel',
        })}
    />
)

const PhoneWithFocus = withId(withFocus(Phone))

export default PhoneWithFocus
