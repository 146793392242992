import * as api from '~/api/retail'
import {Model, Request, Response} from '~/api/retail/types'
import * as rollbar from '~/api/rollbar/rollbar'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {
    APINetworkError,
    errorResponseFactory,
    NonRollbarError,
    UserSuitableError,
} from '~/global/utils/error-handling/errorHandling'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {reauthenticateReturn} from '~/global/wrappers/global-wrapper-widgets/reauthenticate/Reauthenticate'
import {ThunkAction} from '~/store/types'
import accountingActions from '../accounting/actions'
import identityActions from '../identity/actions'
import {actingAsID} from '../identity/selectors'
import {createAction, ActionsUnion} from '../redux-tools'
import {
    ResponseUsTransferPlatform,
    State,
    StagedTransferOrder,
    TransferDirection,
    TransferOrderInstrument,
    TransferOrderRegistryDetails,
} from './types'

const actions = {
    ResetStagedTransferOrder: () => createAction('transfer.reset_staged_transfer_order'),
    SetTransfers: (payload: Response.Transfers) => createAction('transfer.set_transfers', payload),
    SetTransfersLoadingState: (loadingState: State['transfersLoadingState']) =>
        createAction('transfer.set_transfers_loading_state', loadingState),
    SetStagedTransferOrder: (order: StagedTransferOrder) => createAction('transfer.set_staged_transfer_order', order),
    AddInstrumentToStagedTransferOrder: (instrumentId: string, instrumentSlug: string) =>
        createAction('transfer.add_instrument_to_staged_transfer_order', {instrumentId, instrumentSlug}),
    RemoveInstrumentFromStagedTransferOrder: (instrumentId: string) =>
        createAction('transfer.remove_instrument_from_staged_transfer_order', instrumentId),
    ResetStagedTransferOrderInstruments: () => createAction('transfer.reset_staged_transfer_order_instruments'),
    SetTransferOrderSearchTerm: (searchTerm: string) =>
        createAction('transfer.set_transfer_order_search_term', {searchTerm}),
    AddStagedTransferOrderDetails: (details: TransferOrderInstrument) =>
        createAction('transfer.add_staged_transfer_order_details', details),
    InvalidateTransferState: () => createAction('transfer.invalidate_transfer_state'),
    SetTransferInfo: (payload?: Response.TransferInfo) => createAction('transfer.set_transfer_info', payload),
    ClearTransferState: () => createAction('transfer.clear_state'),
}

const thunkActions = {
    FetchTransfers(): ThunkAction<Promise<void | Error>> {
        return async dispatch => {
            try {
                dispatch(actions.SetTransfersLoadingState('loading'))
                const data = await api.get('transfer/transfers')
                switch (data.type) {
                    case 'transfers':
                        dispatch(actions.SetTransfers(data))
                        return
                    case 'error':
                        dispatch(actions.SetTransfersLoadingState('error'))
                        rollbar.sendError(data.code, {
                            statusText: data.message,
                            method: 'FetchTransfers',
                        })
                        return
                    case 'internal_server_error':
                        dispatch(actions.SetTransfersLoadingState('error'))
                        return new Error(unknownErrorMessage)
                    default:
                        assertNever(data)
                }
            } catch (e: unknown) {
                dispatch(actions.SetTransfersLoadingState('error'))
                if (
                    e instanceof UserSuitableError ||
                    e instanceof APINetworkError ||
                    e instanceof NonRollbarError ||
                    e instanceof Error
                ) {
                    rollbar.sendError(e.message, {
                        statusText: e.message,
                        method: 'FetchTransfers',
                    })
                }
            }
        }
    },
    CreateRegistryDetails(registryDetails: TransferOrderRegistryDetails): ThunkAction<Promise<void | string>> {
        return async (dispatch, getState) => {
            // Initialise the redux StagedTransferOrder
            const stagedTransferOrder = getState().transfer.stagedTransferOrder

            // Set the variables
            const referenceId = registryDetails.referenceId
            const referenceType = registryDetails.referenceType
            const participantId = 'participantId' in registryDetails ? registryDetails.participantId : undefined
            const hinBroker = 'hinBroker' in registryDetails ? registryDetails.hinBroker : undefined
            const fasterIdNo = 'fasterIdNo' in registryDetails ? registryDetails.fasterIdNo : undefined
            const jointHolderOwnerIds =
                'jointHolderOwnerIds' in registryDetails ? registryDetails.jointHolderOwnerIds : undefined

            dispatch(
                actions.SetStagedTransferOrder({
                    direction: stagedTransferOrder!.direction,
                    exchange: stagedTransferOrder!.exchange,
                    instruments: [],
                    usTransferPlatform: stagedTransferOrder!.usTransferPlatform,
                    reference: referenceId,
                    referenceType,
                    pid: participantId,
                    registryDetailDocuments: [],
                    searchTerm: '',
                    transferOrderDocuments: [],
                }),
            )

            const payload: Request.RegistryDetailsUpsert = {
                acting_as_id: actingAsID(getState()),
                reference: referenceId,
                reference_type: referenceType,
                pid: participantId,
                hin_broker: hinBroker,
                fin: fasterIdNo,
                joint_holder_owner_ids: jointHolderOwnerIds,
            }

            const endpoint = 'transfer/registry-details/upsert'
            let response

            try {
                response = await api.post(endpoint, payload)
            } catch (error) {
                return 'We couldn’t update your investor details, please try again.'
            }
            switch (response.type) {
                case 'transfers':
                    dispatch(actions.SetTransfers(response))
                    return
                case 'error':
                    rollbar.sendError(response.code, {
                        statusText: response.message,
                        method: 'CreateRegistryDetails',
                        payload,
                        endpoint,
                    })
                    if (response.code === 'registry_details_already_exists') {
                        return response.message
                    }
                    return 'We couldn’t update your investor details, please try again.'
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => this.CreateRegistryDetails(registryDetails)(dispatch, getState),
                        () => 'Please resubmit your request.',
                        () => 'You must enter your password before you can alter your investor details.',
                    )
                case 'internal_server_error':
                    // TODO: handle this properly
                    break

                default:
                    assertNever(response)
            }
        }
    },
    UpdateRegistryDetails(registryDetails: TransferOrderRegistryDetails): ThunkAction<Promise<void | string>> {
        return async (dispatch, getState) => {
            // Set the variables
            const referenceId = registryDetails.referenceId
            const referenceType = registryDetails.referenceType
            const registryDetailId =
                'registryDetailId' in registryDetails ? registryDetails.registryDetailId : undefined
            const participantId = 'participantId' in registryDetails ? registryDetails.participantId : undefined
            const hinBroker = 'hinBroker' in registryDetails ? registryDetails.hinBroker : undefined

            const payload: Request.RegistryDetailsUpsert = {
                acting_as_id: actingAsID(getState()),
                customer_registry_detail_id: registryDetailId,
                reference: referenceId,
                reference_type: referenceType,
                pid: participantId,
                hin_broker: hinBroker,
            }

            const endpoint = 'transfer/registry-details/upsert'
            let response
            try {
                response = await api.post(endpoint, payload)
            } catch (error) {
                return 'We couldn’t update your investor details, please try again.'
            }

            switch (response.type) {
                case 'transfers':
                    dispatch(actions.SetTransfers(response))
                    return
                case 'error':
                    rollbar.sendError(response.code, {
                        statusText: response.message,
                        method: 'UpdateRegistryDetail',
                        payload,
                        endpoint,
                    })
                    return 'We couldn’t update your investor details, please try again.'
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => this.UpdateRegistryDetails(registryDetails)(dispatch, getState),
                        () => 'Please resubmit your request.',
                        () => 'You must enter your password before you can alter your investor details.',
                    )
                case 'internal_server_error':
                    // TODO: handle this properly
                    break

                default:
                    assertNever(response)
            }
        }
    },
    UpdateRegistryDetailAddress(
        address: Request.RegistryDetailsUpsert['address'],
    ): ThunkAction<Promise<void | string>> {
        return async (dispatch, getState) => {
            const stagedTransferOrder = getState().transfer.stagedTransferOrder

            const payload: Request.RegistryDetailsUpsert = {
                customer_registry_detail_id: stagedTransferOrder!.registryDetailId!,
                acting_as_id: actingAsID(getState()),
                address,
            }
            const endpoint = 'transfer/registry-details/upsert'
            let response
            try {
                response = await api.post(endpoint, payload)
            } catch (error) {
                return 'We couldn’t update your investor details, please try again.'
            }

            switch (response.type) {
                case 'transfers':
                    dispatch(actions.SetTransfers(response))
                    return
                case 'error':
                    rollbar.sendError(response.code, {
                        statusText: response.message,
                        method: 'UpdateRegistryDetailAddress',
                        payload,
                        endpoint,
                    })
                    return 'We couldn’t update your investor details, please try again.'
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => this.UpdateRegistryDetailAddress(address)(dispatch, getState),
                        () => 'Please resubmit your request.',
                        () => 'You must enter your password before you can alter your investor details.',
                    )
                case 'internal_server_error':
                    // TODO: handle this properly
                    break

                default:
                    assertNever(response)
            }
        }
    },
    DeleteRegistryDetail(registryDetailId: string): ThunkAction<Promise<void | string>> {
        return async (dispatch, getState) => {
            const payload: Request.RegistryDetailDelete = {
                acting_as_id: actingAsID(getState()),
                registry_detail_id: registryDetailId,
            }
            const endpoint = 'transfer/registry-detail/delete'
            let response
            try {
                response = await api.post(endpoint, payload)
            } catch (error) {
                return 'We couldn’t delete your investor details, please try again.'
            }
            switch (response.type) {
                case 'transfers':
                    dispatch(actions.SetTransfers(response))
                    return
                case 'error':
                    rollbar.sendError(response.code, {
                        statusText: response.message,
                        method: 'DeleteRegistryDetail',
                        payload,
                        endpoint,
                    })
                    return 'We couldn’t delete your investor details, please try again.'
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => this.DeleteRegistryDetail(registryDetailId)(dispatch, getState),
                        () => 'Please resubmit your request.',
                        () => 'You must enter your password before you can delete your investor details.',
                    )
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    SetStagedTransfer: (groupId: string): ThunkAction<Promise<void | string>> => {
        return async (dispatch, getState) => {
            const groupedTransfers = getState().transfer.groupedTransfers
            const transferGroup = groupedTransfers.find(groupedTransfer => groupedTransfer.group_id === groupId)

            if (!transferGroup && getState().transfer.transfersLoadingState === 'loading') {
                // The customer gave use the groupId via URL.
                // Fetch the transfers before setting the transfer
                await dispatch(thunkActions.FetchTransfers())
                dispatch(thunkActions.SetStagedTransfer(groupId))
                return
            }

            if (!transferGroup) {
                // User arrived to a URL with an invalid groupId
                actions.SetTransfersLoadingState('error')
                return
            }

            dispatch(
                actions.SetStagedTransferOrder({
                    address: transferGroup.address,
                    direction: transferGroup.direction,
                    exchange: transferGroup.exchange,
                    groupId: transferGroup.group_id,
                    instruments: transferGroup.instruments.map(() => ({
                        ...transferGroup.instruments,
                        index: 0,
                        instrumentId: '',
                        instrumentSlug: '',
                        records: [],
                    })),
                    usTransferPlatform: transferGroup.us_transfer_broker,
                    reference: transferGroup.reference,
                    referenceType: transferGroup.reference_type,
                    registryDetailId: transferGroup.customer_registry_detail_id,
                    registryDetailDocuments: transferGroup.registry_detail_documents,
                    rejected: transferGroup.rejected,
                    searchTerm: '',
                    transferOrderDocuments: transferGroup.transfer_order_documents,
                }),
            )
        }
    },
    UploadTransferInstruments: (): ThunkAction<Promise<void | string>> => {
        return async (dispatch, getState) => {
            dispatch(actions.SetTransfersLoadingState('loading'))
            const stagedTransferOrder = getState().transfer.stagedTransferOrder!

            const payload: Request.TransfersUpdateInstruments = {
                customer_registry_detail_id: stagedTransferOrder.registryDetailId!,
                acting_as_id: actingAsID(getState()),
                transfer_direction: stagedTransferOrder.direction!,
                instruments: stagedTransferOrder.instruments.map((instrument: TransferOrderInstrument) => ({
                    fund_id: instrument.instrumentId,
                    transfer_order_id: instrument.orderId,
                    records: instrument.records.map(({purchaseDate, shares, price}) => ({
                        shares,
                        price,
                        purchase_date: purchaseDate?.toISODate(),
                    })),
                })),
                group_id: stagedTransferOrder.groupId,
                us_transfer_broker: stagedTransferOrder.usTransferPlatform,
            }
            const endpoint = 'transfer/update-instruments'
            let response
            try {
                response = await api.post(endpoint, payload)
            } catch (error) {
                dispatch(actions.SetTransfersLoadingState('error'))
                return 'We couldn’t update your investor details, please try again.'
            }

            switch (response.type) {
                case 'transfers':
                    dispatch(actions.SetTransfers(response))
                    return
                case 'error':
                    rollbar.sendError(response.code, {
                        statusText: response.message,
                        method: 'UpdateRegistryDetailAddress',
                        payload,
                        endpoint,
                    })
                    return 'We couldn’t update your investor details, please try again.'
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    EmailIssuerTransferForm(groupId: string): ThunkAction<Promise<'error' | void>> {
        return async (_, getState) => {
            const response = await api.post('transfer/email-issuer-transfer-form', {
                transfer_order_group_id: groupId,
                acting_as_id: actingAsID(getState()),
            })

            switch (response.type) {
                case 'empty': {
                    return
                }
                case 'error':
                    return response.type
                case 'internal_server_error':
                    throw new Error('Something went wrong')
                default:
                    assertNever(response)
            }
        }
    },
    SubmitTransfer(
        groupId: string,
    ): ThunkAction<Promise<'asx_transfer_invalid_transfer_state' | 'insufficient_funds_for_transfer' | string | void>> {
        return async (dispatch, getState) => {
            const response = await api.post('transfer/submit-transfer', {
                transfer_order_group_id: groupId,
                acting_as_id: actingAsID(getState()),
            })

            switch (response.type) {
                case 'transfers':
                    dispatch(actions.SetTransfers(response))
                    break
                case 'error':
                    if (response.code === 'insufficient_funds_for_transfer') {
                        return 'insufficient_funds_for_transfer'
                    } else if (response.code === 'asx_transfer_invalid_transfer_state') {
                        return 'An error occurred submitting the transfer. Please contact Sharesies.'
                    }
                    return unknownErrorMessage
                case 'internal_server_error':
                    return unknownErrorMessage
                default:
                    assertNever(response)
            }
        }
    },
    CancelTransferGroup(groupId: string): ThunkAction<Promise<string | void | Response.Error>> {
        return async (dispatch, getState) => {
            const payload: Request.TransferOrderCancelGroup = {
                acting_as_id: actingAsID(getState()),
                group_id: groupId,
            }
            const endpoint = 'transfer/cancel-group'
            const response = await api.post(endpoint, payload)

            switch (response.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => thunkActions.CancelTransferGroup(groupId)(dispatch, getState),
                        () => 'Please try again',
                        () => 'You need to supply your password to continue',
                    )
                case 'error':
                    return response.message
                case 'transfers':
                    dispatch(actions.SetTransfers(response))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    throw new Error('internal_server_error')
                default:
                    assertNever(response)
                    break
            }
        }
    },
    UploadIssuerHoldingStatement(files: File[], registryDetailId: string): ThunkAction<Promise<void | string>> {
        const data = {
            files,
            customer_registry_detail_id: registryDetailId,
        }

        return async dispatch => {
            const response = await api.formPost('transfer/registry-detail/upload', data)

            switch (response.type) {
                case 'transfers':
                    dispatch(actions.SetTransfers(response))
                    return
                case 'error':
                    return unknownErrorMessage
                case 'internal_server_error':
                    throw new Error('Something went wrong') // TODO - get error from design
                default:
                    assertNever(response)
            }
        }
    },
    ArchiveIssuerHoldingStatement(
        documentId: string,
        transferGroupId: string,
    ): ThunkAction<Promise<Response.Error | void | string>> {
        return async (dispatch, getState) => {
            const data = {
                customer_registry_detail_document_id: documentId,
                transfer_order_group_id: transferGroupId,
                acting_as_id: actingAsID(getState()),
            }
            const response = await api.post('transfer/registry-detail/archive-upload', data)

            switch (response.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () =>
                            thunkActions.ArchiveIssuerHoldingStatement(documentId, transferGroupId)(dispatch, getState),
                        () => errorResponseFactory('Please try again'),
                        () => errorResponseFactory('You need to supply your password to continue'),
                    )
                case 'transfers':
                    dispatch(actions.SetTransfers(response))
                    return
                case 'error':
                    return unknownErrorMessage
                case 'internal_server_error':
                    throw new Error('Something went wrong') // TODO - get error from design
                default:
                    assertNever(response)
            }
        }
    },
    UploadIdOrTransferForm(files: File[], groupId: string, type: string): ThunkAction<Promise<void | string>> {
        const data = {
            files,
            group_id: groupId,
            type,
        }

        return async dispatch => {
            const response = await api.formPost('transfer/transfer-order/upload', data)

            switch (response.type) {
                case 'transfers':
                    dispatch(actions.SetTransfers(response))
                    return
                case 'error':
                    return unknownErrorMessage
                case 'internal_server_error':
                    throw new Error('Something went wrong') // TODO - get error from design
                default:
                    assertNever(response)
            }
        }
    },
    ArchiveIdOrTransferForm(groupId: string, documentId: string): ThunkAction<Promise<Response.Error | void | string>> {
        return async (dispatch, getState) => {
            const data = {
                group_id: groupId,
                transfer_order_document_id: documentId,
                acting_as_id: actingAsID(getState()),
            }
            const response = await api.post('transfer/transfer-order/archive-upload', data)

            switch (response.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => thunkActions.ArchiveIdOrTransferForm(groupId, documentId)(dispatch, getState),
                        () => errorResponseFactory('Please try again'),
                        () => errorResponseFactory('You need to supply your password to continue'),
                    )
                case 'transfers':
                    dispatch(actions.SetTransfers(response))
                    return
                case 'error':
                    return unknownErrorMessage
                case 'internal_server_error':
                    throw new Error('Something went wrong') // TODO - get error from design
                default:
                    assertNever(response)
            }
        }
    },
    UpdateTransferPrice(
        price: string,
        transfer: Model.TransferOrderIn | Model.TransferOrderOut,
    ): ThunkAction<Promise<Response.Error | void>> {
        return async (dispatch, getState) => {
            dispatch(actions.SetTransfersLoadingState('loading'))
            const response = await api.post('transfer/update-price', {
                price,
                transfer_order_id: transfer.id,
                acting_as_id: actingAsID(getState()),
            })

            switch (response.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => thunkActions.UpdateTransferPrice(price, transfer)(dispatch, getState),
                        () => errorResponseFactory('Please try again'),
                        () => errorResponseFactory('You need to supply your password to continue'),
                    )
                case 'error':
                    return response
                case 'transfers':
                    dispatch(actions.SetTransfers(response))
                    // make sure we update the portfolio too (we also update the order history in the submit method in the component)
                    dispatch(identityActions.Check())
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    FetchInfo(
        transferDirection: TransferDirection | undefined,
        usTransferPlatform?: ResponseUsTransferPlatform | undefined,
    ): ThunkAction<Promise<void | Error>> {
        return async (dispatch, getState) => {
            const payload = {
                acting_as_id: actingAsID(getState()),
                transfer_direction: transferDirection,
                us_transfer_broker: usTransferPlatform,
            }
            const data = await api.get('transfer/info', payload)
            switch (data.type) {
                case 'internal_server_error':
                    dispatch(actions.SetTransferInfo())
                    return
                case 'transfer_info':
                    dispatch(actions.SetTransferInfo(data))
                    return
                default:
                    assertNever(data)
            }
        }
    },
    CreateBulkNZXTransferOrder({
        registryDetailId,
        direction,
        csnTransferOrders,
        transferEntirePortfolio,
    }: {
        registryDetailId: Request.TransferOrderBulkCreate['csn_id']
        direction: Request.TransferOrderBulkCreate['transfer_direction']
        csnTransferOrders: Request.TransferOrderBulkCreate['transfer_orders']
        transferEntirePortfolio?: Request.TransferOrderBulkCreate['transfer_entire_portfolio']
    }): ThunkAction<Promise<void | string>> {
        return async (dispatch, getState) => {
            const payload: Request.TransferOrderBulkCreate = {
                acting_as_id: actingAsID(getState()),
                csn_id: registryDetailId,
                transfer_direction: direction,
                transfer_orders: csnTransferOrders,
                transfer_entire_portfolio: transferEntirePortfolio,
            }
            const response = await api.post('transfer/bulk-create', payload)

            switch (response.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () =>
                            thunkActions.CreateBulkNZXTransferOrder({
                                registryDetailId,
                                direction,
                                csnTransferOrders,
                                transferEntirePortfolio,
                            })(dispatch, getState),
                        () => 'Please try again',
                        () => 'You need to supply your password to continue',
                    )
                case 'error':
                    if (response.code === 'insufficient_funds_for_transfer_out') {
                        return response.code
                    }
                    return response.message
                case 'transfer_orders_bulk_create_response':
                    // Happy days, carry on
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
                    break
            }
        }
    },
    CancelTransferOrder(transferOrderId: string, fundId: string): ThunkAction<Promise<string | void>> {
        return async (dispatch, getState) => {
            const payload = {
                acting_as_id: actingAsID(getState()),
                transfer_order_id: transferOrderId,
            }

            const response = await api.post('transfer/cancel', payload)

            switch (response.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => thunkActions.CancelTransferOrder(transferOrderId, fundId)(dispatch, getState),
                        () => 'Please try again',
                        () => 'You need to supply your password to continue',
                    )
                case 'error':
                    return response.message
                case 'transfers':
                    dispatch(actions.SetTransfers(response))
                    dispatch(accountingActions.FetchFundOrders(fundId))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
                    break
            }
        }
    },
    // Only keeping this for customers with older code
    // I&S TODO - remove this and just use CreateRegistryDetails
    SubmitCSNForReview(csn: string, fin: string): ThunkAction<Promise<void | string>> {
        return async (dispatch, getState) => {
            const payload: Request.RegistryDetailsCreate = {
                acting_as_id: actingAsID(getState()),
                reference_type: 'CSN',
                reference: csn,
                fin,
            }
            const endpoint = 'transfer/create'
            let response
            try {
                response = await api.post('transfer/submit-csn', payload)
            } catch (error) {
                return 'We couldn’t update your investor details, please try again.'
            }
            switch (response.type) {
                case 'transfers':
                    dispatch(actions.SetTransfers(response))
                    return
                case 'error':
                    if (response?.type === 'error' && response.code === 'duplicate_registry_details') {
                        return response.message
                    }
                    rollbar.sendError(response.code, {
                        statusText: response.message,
                        method: 'SetNZXInvestorDetails',
                        payload,
                        endpoint,
                    })
                    return 'We couldn’t update your investor details, please try again.'
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => thunkActions.SubmitCSNForReview(csn, fin)(dispatch, getState),
                        () => 'Please resubmit your request.',
                        () => 'You must enter your password before you can alter your investor details.',
                    )
                case 'internal_server_error':
                    // TODO: handle this properly
                    break

                default:
                    assertNever(response)
            }
        }
    },
}

export type ActionsType = ActionsUnion<typeof actions>

export default {...actions, ...thunkActions}
