import {colour, semanticColour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import * as d3shape from 'd3-shape'
import React from 'react'

// colour={semanticColour('IconDefault', colourMode)}
export const AllocationCircle = ({allocation}: {allocation: string}) => {
    const colourMode = useColourMode()
    return (
        <svg viewBox="0 0 100 100" shapeRendering="geometricPrecision">
            <circle
                cx="50"
                cy="50"
                r="43"
                stroke={semanticColour('FillPrimary', colourMode)}
                fill="transparent"
                strokeWidth="11"
            />
            <g transform="translate(50, 50)">
                <path
                    stroke={colour('Melon500', colourMode)}
                    strokeWidth="14"
                    fill="transparent"
                    strokeLinecap="round"
                    d={
                        d3shape.arc()({
                            innerRadius: 43,
                            outerRadius: 43,
                            startAngle: Math.PI,
                            endAngle: Math.PI - (parseInt(allocation, 10) / 50) * Math.PI,
                        })!
                    }
                />
            </g>
        </svg>
    )
}
