import {rakaiaGetFactory} from '~/api/query/rakaia'
import {GetInstrumentInvestingHistoryApiV1PortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGetRequest} from '~/api/rakaia'

/**
 * This is a duplicate of useRakaiaGetInstrumentInvestingHistory in kiwisaver/data/rakaia.tsi
 * They will likely be consolidated at some point in the future, but making a seperate copy
 * for now as it will likely be extended with specific error handling logic that I don't want
 * to automatically apply to the kiwisaver context
 */
export const useInstrumentInvestingHistory = (
    args: GetInstrumentInvestingHistoryApiV1PortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGetRequest,
) => {
    const {data} = rakaiaGetFactory({
        apiFunctionName: 'getInstrumentInvestingHistoryApiV1PortfoliosPortfolioUuidInvestingHistoryInstrumentUuidGet',
    })(args)
    return data
}
