import React from 'react'
import ScrollingTicker from '~/global/widgets/scrolling-ticker/ScrollingTicker'
import type {Instrument} from '~/store/instrument/types'
import styles from './ScrollingInstrumentTicker.scss'

const ScrollingInstrumentTicker: React.FC<{instrument: Instrument; textColour: string; backgroundColour: string}> = ({
    instrument,
    textColour,
    backgroundColour,
}) => {
    return (
        <ScrollingTicker>
            <div
                className={styles.scrollyBitSection}
                style={{'--ticker-contrast-colour': textColour} as React.CSSProperties}
            >
                <div className={styles.scrollyBitSymbol}>{instrument.symbol}</div>
                <div className={styles.scrollyBitExchange} style={{color: backgroundColour}}>
                    {instrument.exchange}
                </div>
            </div>
        </ScrollingTicker>
    )
}

export default ScrollingInstrumentTicker
