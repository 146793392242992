import {DateTime} from 'luxon'
import {Period, periodInformation} from '~/global/utils/time-period/timePeriod'
import {useReadPortfolio} from './useReadPortfolio'

export const useDefaultReturnPeriod = (portfolioId: string) => {
    const portfolioHistory = useReadPortfolio(portfolioId).portfolio_history

    if (!portfolioHistory || portfolioHistory.length === 0) {
        return '1m'
    }

    const earliestDate = DateTime.fromISO(portfolioHistory[0].date)
    const now = DateTime.local()
    let shortest: Period = '1y' // We never want to default to anything longer than 1 year

    for (const period in periodInformation) {
        const {duration} = periodInformation[period as Period]
        if (period === '1w') {
            // We don't want '1w' to be an option because it's too short
            continue
        }
        if (duration.as('seconds') > periodInformation[shortest].duration.as('seconds')) {
            // This would be a larger period, don't want that
            continue
        }
        if (now.minus(duration) < earliestDate) {
            shortest = period as Period
        }
    }

    return shortest
}
