import React from 'react'
import {Response} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import usWelcome1 from '~/global/assets/images/intro-slides/us-welcome-1.png'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ClassicIntroSlideContent} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {IntroSlides} from '~/global/widgets/intro-slides/IntroSlides'
import {useNavigate} from '~/migrate-react-router'
import {sendMobileExploreRoute} from '~/sections/explore/utils/util'
import {useAppSelector, useAppDispatch} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import actions from '~/store/nav/actions'
import signUpActions from '~/store/sign-up/actions'

const USWelcomeContent: React.FC<{jurisdiction: 'au' | 'nz'}> = ({jurisdiction}) => {
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)
    const usStatus = useAppSelector(s => s.signUp.usStatus)

    return (
        <>
            {usStatus?.customer_signup_state === 'RESIGN_WELCOME' ? (
                <p>There's a few things to remember when buying or selling US shares:</p>
            ) : (
                <p>Investing in US shares is very similar to NZ shares, but with a few small differences:</p>
            )}
            <ul>
                <li>
                    US exchanges are open overnight, {jurisdiction === 'au' ? 'Australian' : 'NZ'} time. You can opt-in
                    to extended hours trading for some US investments.
                </li>
                <li>
                    You’ll buy US shares in USD. You can either exchange {isDependent ? `${preferredName}’s` : 'your'}{' '}
                    money in advance and hold it in {isDependent ? `${preferredName}’s` : 'your'} Wallet until you want
                    to buy, or exchange it when you’re ready to purchase.
                </li>
                <li>
                    If {isDependent ? `${preferredName} holds` : 'you hold'} US shares elsewhere, you can transfer these
                    into Sharesies (fees apply).
                </li>
            </ul>
        </>
    )
}

const usWelcomeSlides: ClassicIntroSlideContent[] = [
    {
        image: usWelcome1,
        title: 'Investing in US shares',
        bodyRenderer: USWelcomeContent,
    },
]

const USWelcome: React.FunctionComponent = React.memo(() => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const usPreSignUpLocation = useAppSelector(s => s.signUp.usPreSignUpLocation)
    const usStatus = useAppSelector(s => s.signUp.usStatus)

    // Prevent a race condition we where end up being redirected back to the signup flow again
    // because the db takes a little longer to update.
    // They've signed now so we can safely clear their due and overdue status.
    const resignStatus: Response.SignUpUSResignStatus = {
        type: 'sign_up_us_resign_status',
        resign_due: false,
        resign_overdue: false,
    }
    dispatch(signUpActions.setUSResignStatus(resignStatus))

    const nextPage = () => {
        // do a refresh of identity so the usPreSignUpLocation has the new info
        dispatch(identityActions.Check())

        const userSignupState = usStatus?.customer_signup_state
        if (userSignupState === 'RESIGN_WELCOME') {
            // Mark this as complete and send 'em on their way.
            dispatch(signUpActions.MarkHasSeenFlag('us_shares_resign_welcome_screen'))
            navigate(profileUrl('invest'), {replace: true})
        } else if (usPreSignUpLocation) {
            navigate(usPreSignUpLocation, {replace: true})
        } else if (isDependent) {
            sendMobileExploreRoute()
            navigate(profileUrl('explore'), {replace: true})
        } else {
            navigate(profileUrl('sign-up/deposit'), {replace: true})
        }
    }

    // update WELCOME -> COMPLETED state on welcome page load
    React.useEffect(() => {
        const userSignupState = usStatus?.customer_signup_state

        if (userSignupState === 'WELCOME') {
            rudderTrack('signup', 'us_shares_completed')
            dispatch(identityActions.MarkHasSeenFlag('us_shares_welcome_screen'))
        }
    }, [])

    React.useEffect(() => {
        dispatch(actions.HideFlyoutMenu())
        dispatch(actions.ShowFlyoutMenu())
        dispatch(signUpActions.getUSStatus())
    }, [])

    return <IntroSlides content={usWelcomeSlides} onComplete={nextPage} disableClose layout="classicIntro" />
})

export default USWelcome
