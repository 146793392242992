import {FormikValues} from 'formik'
import {useAtom} from 'jotai'
import React from 'react'
import {NatureAndPurposeStep, getNextStep} from '~/global/utils/nature-and-purpose/natureAndPurpose'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {FonterraPersonalSignUpCommonProps} from '~/sections/moose/sections/fonterra-personal-sign-up/FonterraPersonalSignUp'
import NatureAndPurposeForm from '~/sections/moose/sections/fonterra-personal-sign-up/pages/nature-and-purpose/widgets/nature-and-purpose-form/NatureAndPurposeForm'
import {
    NatureAndPurpose,
    stagedFonterraPersonalSignUpAtom,
} from '~/sections/moose/sections/fonterra-personal-sign-up/state'

// NOTE: Identical copies of this component exist in multiple files.
const NatureAndPurpose = ({progressStep, regressStep}: FonterraPersonalSignUpCommonProps) => {
    const [step, setStep] = React.useState<NatureAndPurposeStep>('frequency')
    const [stagedFonterraPersonalSignUp, updateStagedFonterraPersonalSignUp] = useAtom(stagedFonterraPersonalSignUpAtom)

    const onSubmit = (formValues: FormikValues) => {
        addOrUpdateStagedNatureAndPurpose(formValues)
        progressStep()
    }

    const addOrUpdateStagedNatureAndPurpose = (formValues: FormikValues) => {
        const newNpForEntity: NatureAndPurpose = {
            frequency: formValues.frequency,
            annualAmount: formValues.annualAmount,
            purposes: formValues.purposes,
            otherPurpose: formValues.otherPurpose,
        }

        updateStagedFonterraPersonalSignUp({
            ...stagedFonterraPersonalSignUp,
            natureAndPurpose: {...stagedFonterraPersonalSignUp.natureAndPurpose, ...newNpForEntity},
        })
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--fonterra-signup-nature-and-purpose"
                leftButton="back"
                onLeftButtonClick={regressStep}
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <NatureAndPurposeForm step={step} setStep={setStep} getNextStep={getNextStep} onSubmit={onSubmit} />
            </Page>
        </>
    )
}

export default NatureAndPurpose
