import {Button} from '@design-system/button'
import React from 'react'
import giftCard from '~/global/assets/images/gifts/gift-card.svg'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {PageButtonGroup} from '~/global/widgets/button-page-group/PageButtonGroup'
import {Image} from '~/global/widgets/image/Image'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {useAppSelector} from '~/store/hooks'
import styles from './Gifts.scss'

const content = {
    headerTitle: 'SHARESIES GIFTS',
    message:
        'Help someone special build their portfolio with a Sharesies Gift. You can gift any amount from $5 up to $2,000.',
}

export const Gifts: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const accountRestricted = useAppSelector(s => s.identity.user!.account_restricted)

    return (
        <>
            <Toolbar dataTestId="toolbar--gifts" leftButton="back" title="Sharesies gifts" />
            <Page topPaddingOnly overrideDefaultTopPadding="withToolbarTitle">
                <div className={styles.header}>
                    <Image src={giftCard} />
                </div>

                <div className={styles.whiteContainer}>
                    <p className={styles.message}>{content.message}</p>
                    <PageButtonGroup>
                        <Button
                            dataTestId="button--buy-a-gift"
                            label="Buy a Gift"
                            type="primary"
                            onClick={() => navigate(profileUrl('gifts/purchase'))}
                            disabled={accountRestricted}
                        />
                        <Button
                            label="Redeem a Gift—lucky you!"
                            dataTestId="button--redeem-a-gift"
                            type="secondary"
                            onClick={() => navigate(profileUrl('gifts/redeem-gift'))}
                            disabled={accountRestricted}
                        />
                    </PageButtonGroup>
                </div>
            </Page>
        </>
    )
}

export default Gifts
