import cn from 'classnames'
import React from 'react'
import {Response} from '~/api/retail/types'
import {SharePriceValue} from '~/global/widgets/number-elements/NumberElements'
import OrderTable from '../order-table/OrderTable'
import styles from './OrderBook.scss'

interface BuyArray {
    array: Response.LiveDataFetch['live_data']['price_depth']['bids']
    highestPrice: string
}

interface SellArray {
    array: Response.LiveDataFetch['live_data']['price_depth']['offers']
    lowestPrice: string
}

interface Props {
    buyArray: Response.LiveDataFetch['live_data']['price_depth']['bids']
    sellArray: Response.LiveDataFetch['live_data']['price_depth']['offers']
    matchPrice?: string
    matchVolume?: string
}

const OrderBook: React.FunctionComponent<Props> = ({buyArray, sellArray, matchPrice, matchVolume}) => {
    const [sortedBuyArray, setSortedBuyArray] = React.useState<BuyArray>({array: [], highestPrice: '0'})
    const [sortedSellArray, setSortedSellArray] = React.useState<SellArray>({array: [], lowestPrice: '0'})

    React.useEffect(() => {
        sortSellArray()
        sortBuyArray()
    }, [buyArray, sellArray])

    const sortSellArray = () => {
        // Offers = Sell
        // Ascending order
        if (sellArray.length === 0) {
            setSortedSellArray({...sortedSellArray, array: []})
        } else {
            const sellArraySorted = sellArray.sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
            setSortedSellArray({
                array: sellArraySorted,
                lowestPrice: sellArraySorted[0].price,
            })
        }
    }

    const sortBuyArray = () => {
        // Bids = Buy
        // Descending order
        if (buyArray.length === 0) {
            setSortedBuyArray({...sortedBuyArray, array: []})
        } else {
            const buyArraySorted = buyArray.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))
            setSortedBuyArray({array: buyArraySorted, highestPrice: buyArraySorted[0].price})
        }
    }

    const spread = parseFloat(sortedSellArray.lowestPrice) - parseFloat(sortedBuyArray.highestPrice)

    const highestNumberOfShares = React.useMemo(() => {
        return [...buyArray, ...sellArray].reduce((accumulator, currentValue) => {
            const currentNumberOfShares = parseFloat(currentValue.size)
            return accumulator > currentNumberOfShares ? accumulator : currentNumberOfShares
        }, 0)
    }, [buyArray, sellArray])

    return (
        <div className={styles.orderBook}>
            <OrderTable
                orderArray={sortedSellArray.array}
                type="sell"
                matchPrice={matchPrice}
                matchVolume={matchVolume}
                highestNumberOfShares={highestNumberOfShares}
            />
            {sortedSellArray.array.length > 0 &&
                sortedBuyArray.array.length > 0 &&
                (spread > 0 ? (
                    <div className={styles.spread}>
                        <p>
                            Spread: <SharePriceValue value={spread} />
                        </p>
                    </div>
                ) : (
                    <div className={styles.negativeSpread} />
                ))}

            {(sortedSellArray.array.length === 0 || sortedBuyArray.array.length === 0) && (
                <div
                    className={cn(styles.spread, {
                        [styles.noSpreadNoSellArray]: sortedSellArray.array.length === 0,
                    })}
                >
                    <p>Spread: $ -</p>
                </div>
            )}
            <OrderTable
                orderArray={sortedBuyArray.array}
                type="buy"
                matchPrice={matchPrice}
                matchVolume={matchVolume}
                highestNumberOfShares={highestNumberOfShares}
            />
        </div>
    )
}

export default React.memo(OrderBook)
