// based off https://stackoverflow.com/questions/50428910/get-text-content-from-node-in-react
export const getReactNodeText = (node: any): string => {
    if (['string', 'number'].includes(typeof node)) {
        return node
    }
    if (node instanceof Array) {
        return node.map(getReactNodeText).join(' ')
    }
    if (typeof node === 'object' && node) {
        return getReactNodeText(node.props.children).trimRight()
    }
    return ''
}
