import {isAndroidWrapperApp, isIOSWrapperApp} from '~/global/utils/is-wrapper-app/isWrapperApp'

interface GoBack {
    type: 'goBack'
}

interface IdentityUpdated {
    type: 'identityUpdated'
}

interface SignUpComplete {
    type: 'signUpComplete'
    route: string
}

interface PasswordResetComplete {
    type: 'passwordResetComplete'
}

interface SetNativeToolbarTitle {
    type: 'setNativeToolbarTitle'
    title: string
}

interface PersonalSignUpComplete {
    type: 'personalSignUpComplete'
}

interface KidsSignUpComplete {
    type: 'kidsSignUpComplete'
    profileSlug: string
}

type MessageTypes =
    | GoBack
    | IdentityUpdated
    | SignUpComplete
    | PasswordResetComplete
    | SetNativeToolbarTitle
    | PersonalSignUpComplete
    | KidsSignUpComplete

/**
 * Send a message to the native wrapper apps
 *
 * An optional payload can be added and decoded in the native apps
 *
 * EXAMPLES
 * sendWrapperAppMessage({type: 'goBack'})
 * sendWrapperAppMessage({type: 'signUpComplete'})
 * sendWrapperAppMessage({type: 'passwordResetComplete'})
 */
export const sendWrapperAppMessage = (message: MessageTypes) => {
    const payload = JSON.stringify(message)

    // check if we are in iOS wrapper app and iOS javascript webkit app interface exists
    if (isIOSWrapperApp() && window.webkit?.messageHandlers?.iOSNativeAppInterface !== undefined) {
        // post message to iOS native app
        window.webkit.messageHandlers.iOSNativeAppInterface.postMessage(payload)
    }

    // check if we are in the android wrapper app and android javascript app interface exists
    if (isAndroidWrapperApp() && window.androidNativeAppInterface !== undefined) {
        // post message to android native app
        window.androidNativeAppInterface.postMessage(payload)
    }
}
