import cn from 'classnames'
import React from 'react'
import FocusLock from 'react-focus-lock'
import {accessibility} from '~/global/scss/helpers'
import {Filter} from '~/global/widgets/OLD_icons'
import PreventScroll from '~/global/widgets/prevent-scroll/PreventScroll'
import {SortOptions} from '~/store/instrument/types'
import styles from './SortAndFilter.scss'

interface SortAndFilterProps {
    currentFilter?: SortOptions[number]
    currentSort?: SortOptions[number]
    defaultFilterId?: string
    defaultSortId: string
    filterOptions?: SortOptions
    onFilterClick?(): void
    onFilterHide?(): void
    onFilterMenuClick?(newFilter: string): void
    onSortHide?(): void
    onSortMenuClick(newSort: string): void
    sortOptions: SortOptions
}

const SortAndFilter: React.FunctionComponent<SortAndFilterProps> = ({
    currentFilter,
    currentSort,
    defaultFilterId,
    defaultSortId,
    filterOptions,
    onFilterClick,
    onFilterMenuClick,
    onSortHide,
    onSortMenuClick,
    sortOptions,
}) => {
    const [filterMenuShown, setFilterMenuShown] = React.useState(false)
    const [sortMenuShown, setSortMenuShown] = React.useState(false)

    const defaultFilterMenu = filterOptions ? filterOptions.find(option => option.id === defaultFilterId) : undefined
    const defaultSortMenu = sortOptions.find(option => option.id === defaultSortId)

    React.useEffect(() => {
        if (!sortMenuShown && !filterMenuShown) {
            return
        }

        const handleEscapeKey = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                if (filterMenuShown) {
                    setFilterMenuShown(false)
                }
                if (sortMenuShown) {
                    setSortMenuShown(false)
                }
            }
        }

        window.addEventListener('keyup', handleEscapeKey)

        return () => {
            window.removeEventListener('keyup', handleEscapeKey)
        }
    }, [filterMenuShown, sortMenuShown])

    return (
        <>
            <PreventScroll condition={filterMenuShown || sortMenuShown} onlyPreventMobile />
            <div className={styles.controlsWrapper}>
                <div className={styles.controls}>
                    <div className={styles.filter}>
                        <button
                            data-testid="button--filter-by"
                            type="button"
                            className={cn(styles.filterButton, accessibility.button)}
                            aria-expanded={filterMenuShown}
                            onClick={() => {
                                if (onFilterClick) {
                                    onFilterClick()
                                }
                                if (filterOptions) {
                                    setFilterMenuShown(true)
                                }
                            }}
                        >
                            <Filter />

                            {filterOptions ? (
                                <div className={cn(styles.filterButtonText, styles.filterButtonTextWithOptions)}>
                                    <p>Filter by</p>
                                    <span>
                                        {(currentFilter
                                            ? currentFilter.name
                                            : defaultFilterMenu
                                              ? defaultFilterMenu.name
                                              : filterOptions[0].name
                                        ).toLowerCase()}
                                    </span>
                                </div>
                            ) : (
                                <p className={cn(styles.filterButtonText, styles.filterButtonTextWithoutOptions)}>
                                    Filter
                                </p>
                            )}
                        </button>
                    </div>
                    {sortOptions.length > 0 && (
                        <button
                            type="button"
                            className={cn(styles.sortControl, accessibility.button)}
                            aria-expanded={sortMenuShown}
                            onClick={() => {
                                setSortMenuShown(true)
                            }}
                        >
                            <p className={styles.sortTitle}>Sort: </p>
                            <span>
                                {currentSort
                                    ? currentSort.name
                                    : defaultSortMenu
                                      ? defaultSortMenu.name
                                      : sortOptions[0].name}
                            </span>
                        </button>
                    )}
                </div>

                <div>
                    <div
                        className={cn(styles.menuOverlay, {[styles.visible]: sortMenuShown || filterMenuShown})}
                        onClick={() => {
                            setFilterMenuShown(false)
                            setSortMenuShown(false)
                        }}
                    />
                    {filterMenuShown && filterOptions && (
                        <FocusLock autoFocus returnFocus>
                            <div className={cn(styles.menu, styles.filterMenu)}>
                                <ul>
                                    {defaultFilterMenu && (
                                        <li
                                            key={defaultFilterMenu.id}
                                            className={cn({
                                                [styles.selected]: currentFilter
                                                    ? currentFilter.id === defaultFilterMenu.id
                                                    : defaultFilterMenu.id,
                                            })}
                                        >
                                            <button
                                                type="button"
                                                className={accessibility.button}
                                                aria-label="Default filter option"
                                                onClick={() => {
                                                    if (onFilterMenuClick) {
                                                        onFilterMenuClick(defaultFilterMenu.id)
                                                    }
                                                    setFilterMenuShown(false)
                                                }}
                                            >
                                                {defaultFilterMenu.name}
                                            </button>
                                        </li>
                                    )}
                                    {filterOptions.map(option => (
                                        <li
                                            key={option.id}
                                            className={cn({
                                                [styles.selected]: currentFilter && currentFilter.id === option.id,
                                            })}
                                        >
                                            <button
                                                data-testid={`button--${option.name.toLowerCase()}`}
                                                type="button"
                                                className={accessibility.button}
                                                aria-label={`Select ${option.name} filter`}
                                                onClick={() => {
                                                    if (onFilterMenuClick) {
                                                        onFilterMenuClick(option.id)
                                                        setFilterMenuShown(false)
                                                    }
                                                }}
                                            >
                                                {option.name}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </FocusLock>
                    )}

                    {sortMenuShown && (
                        <FocusLock autoFocus returnFocus>
                            <div className={cn(styles.menu, styles.sortMenu)}>
                                <ul>
                                    {defaultSortMenu && (
                                        <li
                                            key={defaultSortMenu.id}
                                            className={cn({
                                                [styles.selected]: currentSort
                                                    ? currentSort.id === defaultSortMenu.id
                                                    : defaultSortMenu.id,
                                            })}
                                        >
                                            <button
                                                type="button"
                                                className={accessibility.button}
                                                aria-label="Default sort option"
                                                onClick={() => {
                                                    onSortMenuClick(defaultSortMenu.id)
                                                    setSortMenuShown(false)
                                                    if (onSortHide) {
                                                        onSortHide()
                                                    }
                                                }}
                                            >
                                                {defaultSortMenu.name}
                                            </button>
                                        </li>
                                    )}
                                    {sortOptions
                                        .filter(option => option.id !== defaultSortId)
                                        .map(option => (
                                            <li
                                                key={option.id}
                                                onClick={() => {
                                                    onSortMenuClick(option.id)
                                                    setSortMenuShown(false)
                                                    if (onSortHide) {
                                                        onSortHide()
                                                    }
                                                }}
                                                className={cn({
                                                    [styles.selected]: currentSort && currentSort.id === option.id,
                                                })}
                                            >
                                                <button
                                                    type="button"
                                                    className={accessibility.button}
                                                    aria-label={`Select ${option.name} sort`}
                                                >
                                                    {option.name}
                                                </button>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </FocusLock>
                    )}
                </div>
            </div>
        </>
    )
}

export default SortAndFilter
