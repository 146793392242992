import {
    wrapperAppAndroidProductionConfig,
    wrapperAppIOSProductionConfig,
    productionConfig,
    stagingConfig,
    uatConfig,
    defaultConfig,
} from '~/config'
import {isAndroidWrapperApp, isIOSWrapperApp} from '~/global/utils/is-wrapper-app/isWrapperApp'

const configForEnv = (() => {
    if (location.host.includes('app.sharesies')) {
        // check if we're in the wrapper app
        if (isAndroidWrapperApp()) {
            return wrapperAppAndroidProductionConfig
        }
        if (isIOSWrapperApp()) {
            return wrapperAppIOSProductionConfig
        }
        return productionConfig
    }
    if (location.host.includes('staging.sharesies')) {
        return stagingConfig
    }
    if (location.host.includes('uat.opsies') || location.host.includes('uat.sharesies')) {
        return uatConfig
    }
    return defaultConfig
})()

export default configForEnv
