import React from 'react'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import NZTaxDetails from '~/global/widgets/nz-tax-details-form/NZTaxDetailsForm'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {useAppSelector} from '~/store/hooks'
import ConnectedAUTaxDetails from './AUTaxDetailsForm'

export const AccountEditTaxDetails: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const {
        is_dependent: isDependent,
        jurisdiction,
        preferred_name: preferredName,
    } = useAppSelector(s => s.identity.user!)
    const connectedDetailProps = {
        isDependent,
        preferredName,
        onSuccess: () => {
            navigate(profileUrl('settings/tax-details'))
        },
    }
    return (
        <>
            <Toolbar dataTestId="toolbar--edit-tax-details" leftButton="back" title="Tax information" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                {jurisdiction === 'nz' && <NZTaxDetails {...connectedDetailProps} />}
                {jurisdiction === 'au' && <ConnectedAUTaxDetails {...connectedDetailProps} />}
            </Page>
        </>
    )
}

export default AccountEditTaxDetails
