import {Button} from '@design-system/button'
import cn from 'classnames'
import {withFormik} from 'formik'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {validate, ErrorBox} from '~/global/widgets/form-controls'
import {TFNNumber} from '~/global/widgets/form-controls/formik'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import globalWrapperStyles from '~/global/wrappers/GlobalWrapper.scss'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'

class FormValues {
    tfn_number?: string
}

interface FormOwnProps {
    updateFinancialDetails: DispatchProps['updateFinancialDetails']
    externalSubmitHandler: OwnProps['externalSubmitHandler']
    externalBackHandler: OwnProps['externalBackHandler']
    tfnNumber?: string
    title?: OwnProps['title']
    description?: OwnProps['description']
    helpText?: OwnProps['helpText']
    buttonText?: OwnProps['buttonText']
    isRequired: OwnProps['isRequired']
}

const InlineTaxForm = withFormik<FormOwnProps, FormValues>({
    mapPropsToValues: ({tfnNumber}) => ({
        tfn_number: tfnNumber || '',
    }),
    mapPropsToErrors: ({tfnNumber}) => {
        // make the button disabled initially if there is an initial error by setting at least one field to have an error
        if (!tfnNumber) {
            return {tfn_number: undefined}
        }
        // if there is an existing TFN number, assume it is valid
        return {}
    },
    handleSubmit: async (
        {tfn_number},
        {setSubmitting, setStatus, props: {updateFinancialDetails: updateFinancialDetails, externalSubmitHandler}},
    ) => {
        if (tfn_number?.match(/^\*\*\* \*\*\* \*\d\d$/)) {
            // TFN number has not been changed.  Don't submit
            await externalSubmitHandler()
            return
        }

        try {
            const updateError = await updateFinancialDetails(tfn_number!)
            if (updateError) {
                setStatus(updateError)
                return
            }
            const externalError = await externalSubmitHandler()
            if (externalError) {
                setStatus(externalError)
                return
            }
        } catch (e) {
            setStatus(unknownErrorMessage)
            throw e
        } finally {
            setSubmitting(false)
        }
    },
    validate: async (values, {isRequired}) => {
        // Our validation lib doesn't have a props context so we roll our own validation function here
        const validateTfnNumber = validate.tfnNumber()
        const errors: {tfn_number?: string} = {}

        if (!isRequired && !values.tfn_number) {
            return {}
        }

        if (!values.tfn_number && isRequired) {
            errors.tfn_number = 'This field is required'
        } else {
            if (values.tfn_number?.match(/^\*\*\* \*\*\* \*\d\d$/)) {
                // TFN number has not been changed.  Don't validate
                return
            }

            const tfnNumberValidError = await validateTfnNumber(values.tfn_number, values)

            if (tfnNumberValidError) {
                errors.tfn_number = tfnNumberValidError
            }
        }

        if (Object.keys(errors).length) {
            return errors
        }
    },
})(({
    isSubmitting,
    handleSubmit,
    isValid,
    status,
    externalBackHandler,
    title,
    description,
    helpText,
    buttonText,
    isRequired,
}) => {
    return (
        <>
            <Toolbar
                dataTestId="toolbar--inline-tax-nz"
                leftButton="back"
                title={title || 'We need some tax info'}
                onLeftButtonClick={externalBackHandler}
            />
            <form onSubmit={handleSubmit} className={globalWrapperStyles.fullScreen}>
                <Page>
                    {description || (
                        <p>
                            We need your tax file number (TFN) to send tax info about your investments to the Australian
                            Taxation Office (ATO). For more details check out the{' '}
                            <HelpCentreLink auArticle="4983208-tax" />.
                        </p>
                    )}
                    <div className={cn(spacing.spaceAbove24, spacing.spaceBelow24)}>
                        <TFNNumber
                            dataTestId="text-input--tfn"
                            name="tfn_number"
                            label={isRequired ? 'Tax File Number (TFN)' : 'TFN (optional)'}
                            helpText={helpText || ''}
                        />
                    </div>

                    <ErrorBox message={status} />
                </Page>
                <ActionBar>
                    <Button
                        dataTestId="button--save-tax-info"
                        isSubmit
                        label={buttonText || 'Save tax info'}
                        disabled={!isValid && isRequired}
                        processing={isSubmitting}
                    />
                </ActionBar>
            </form>
        </>
    )
})

export class BuyTaxInfo extends React.PureComponent<Props, {}> {
    render() {
        const {
            updateFinancialDetails,
            externalSubmitHandler,
            externalBackHandler,
            tfnNumber,
            title,
            description,
            helpText,
            buttonText,
            isRequired = true,
        } = this.props

        return (
            <InlineTaxForm
                title={title}
                description={description}
                helpText={helpText}
                buttonText={buttonText}
                updateFinancialDetails={updateFinancialDetails}
                externalSubmitHandler={externalSubmitHandler}
                externalBackHandler={externalBackHandler}
                tfnNumber={tfnNumber}
                isRequired={isRequired}
            />
        )
    }
}

interface StoreProps {
    tfnNumber?: string
}

interface DispatchProps {
    updateFinancialDetails(tfnNumber: string): Promise<string | null>
}

interface OwnProps {
    title?: string
    description?: React.ReactNode
    helpText?: string
    buttonText?: string
    externalSubmitHandler(): Promise<string | undefined> | void
    externalBackHandler?(): void
    isRequired?: boolean
}

type Props = StoreProps & DispatchProps & OwnProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    state => ({
        tfnNumber: state.identity.user!.tfn_number,
    }),
    dispatch => ({
        updateFinancialDetails: (tfnNumber: string) => dispatch(actions.UpdateAUFinancialDetails(tfnNumber)),
    }),
)(BuyTaxInfo)
