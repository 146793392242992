import {Button} from '@design-system/button'
import {CopyField} from '@design-system/copy-field'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import styles from '~/sections/user/sections/share-the-love/sections/gifts/Gifts.scss'
import {useAppSelector} from '~/store/hooks'

export const GiftPurchaseSuccess: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const gift = useAppSelector(s => s.gift.purchasedGift)
    const email = useAppSelector(s => s.identity.user!.email!)
    const currency = useAppSelector(s => s.identity.user!.home_currency)

    if (!gift) {
        setTimeout(() => navigate(profileUrl('gifts')))
        return null
    }

    const value = gift.gift_amount && (
        <DollarValue value={gift.gift_amount} decimalPlaces={gift.gift_amount.includes('.00') ? 0 : 2} />
    )

    return (
        <>
            <Toolbar dataTestId="toolbar--gift-purchase-success" />
            <Page>
                <div className={styles.giftCard}>{value}</div>
                <h1 className={cn(styles.giftHeader, spacing.spaceBelow24)}>
                    Your {value} {currency.toUpperCase()} Gift is good to go
                </h1>
                <span className={spacing.spaceBelow32}>
                    We’ve emailed your Gift and receipt to <span className={styles.boldText}>{email}</span>—it’s valid
                    until {gift.gift_expires.toFormat(dateFormatNoTime)}. Share it with love!
                </span>

                <CopyField
                    dataTestId="copy-field--gift-purchase-code"
                    id="copy-gift-purchase-code"
                    label="Copy your Gift code"
                    value={gift.gift_code.replace(/(...)(...)(...)(...)/, '$1-$2-$3-$4')}
                    additionalClassName={spacing.spaceBelow32}
                    helpText="If you’d prefer, you can copy the Gift code above and share it with your giftee in whatever way you like."
                />

                <Button
                    pageButton
                    label="Done"
                    dataTestId="button--done"
                    type="primary"
                    onClick={() => navigate(profileUrl(''))}
                />
            </Page>
        </>
    )
}

export default GiftPurchaseSuccess
