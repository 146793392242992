/* eslint-disable react/jsx-no-bind */
import cn from 'classnames'
import {Field, FormikProps, getIn} from 'formik'
import React from 'react'
import {useIsFirstRender} from 'usehooks-ts'
import uuid4 from 'uuid/v4'
import {SharesiesOmit} from '~/global/utils/type-utilities/typeUtilities'
import styles from './form.scss'

// Formik wrapped field higher-order-component
export interface FormikFieldInjectedProps<ValueType> {
    name: string
    isTouched: boolean
    error?: any
    value?: ValueType
    setFieldValue?: (value: ValueType, shouldValidate?: boolean) => void
    setFieldTouched?: (value: string) => void
}

export interface CommonProps<ValueType> extends FormikFieldInjectedProps<ValueType> {
    disabled?: boolean
    dataTestId?: string
    helpText?: React.ReactNode
    id: string
    inline?: boolean
    label?: React.ReactNode
    onChange?: (e: React.ChangeEvent<any>) => void
    onBlur?: (e: React.FocusEvent<any> | string) => void
    onClick?: (e: React.MouseEvent<any>) => void
    testId?: string
    value?: ValueType
}

type ClassValue = undefined | string | {[className: string]: boolean | undefined}

export const commonClassnames = (props: CommonProps<any>, ...classes: ClassValue[]) =>
    cn(
        styles.field,
        {
            [styles.hasError]: props.isTouched && !!props.error,
            [styles.touched]: props.isTouched,
            [styles.filled]: !!props.value,
            [styles.disabled]: props.disabled,
        },
        ...classes,
    )

// Focus higher-order-component
export interface FocusInjectedProps {
    isFocused: boolean
    onFocus: (e: React.FocusEvent<unknown> | string) => void
    onBlur: (e: React.FocusEvent<unknown> | string) => void
}

export const withFocus =
    <ChildProps extends FocusInjectedProps>(Component: React.ComponentType<ChildProps>) =>
    (
        props: SharesiesOmit<ChildProps, FocusInjectedProps> &
            Partial<SharesiesOmit<FocusInjectedProps, {isFocused: boolean}>>,
    ) => {
        const [isFocused, setIsFocused] = React.useState(false)
        const isFirstRender = useIsFirstRender()
        const handleFocus = (e: React.FocusEvent<unknown> | string) => {
            setIsFocused(true)
            if (props.onFocus) {
                props.onFocus(e)
            }
        }
        const handleBlur = (e: React.FocusEvent<unknown> | string) => {
            setIsFocused(false)
            // isFirstRender makes autoFocus inputs not display an error on first render.
            // There's a bug where autoFocus causes onBlur (lose focus) to be called immediately.
            // https://github.com/facebook/react/issues/11062
            if (props.onBlur && !isFirstRender) {
                props.onBlur(e)
            }
        }
        return <Component {...(props as any)} isFocused={isFocused} onFocus={handleFocus} onBlur={handleBlur} />
    }

// Generate ID wrapper
interface IdInjectedProps {
    id: string
}

export const withId =
    <ChildProps extends IdInjectedProps>(Component: React.ComponentType<ChildProps>) =>
    (props: SharesiesOmit<ChildProps, IdInjectedProps>) => {
        const [id] = React.useState(uuid4())
        return <Component {...(props as any)} id={id} />
    }

// formik wrapper - all the props consumed by the formikWrap below, all others are passed on
interface FormikWrapProps {
    name: string
    validate?: (value: any) => string | (() => void) | Promise<void | string> | undefined
    normalizeValue?: (value: string) => string
}

export const formikWrap =
    <ChildProps extends FormikFieldInjectedProps<any>>(Component: React.ComponentType<ChildProps>) =>
    (props: FormikWrapProps & SharesiesOmit<ChildProps, FormikFieldInjectedProps<any>>) => {
        return (
            <Field component={undefined} validate={props.validate} name={props.name}>
                {({form}: {form: FormikProps<any>}) => {
                    return (
                        <Component
                            {...(props as any)}
                            isTouched={!!getIn(form.touched, props.name)}
                            error={getIn(form.errors, props.name)}
                            value={getIn(form.values, props.name)}
                            onChange={(e?: React.ChangeEvent<any>) => {
                                if (e) {
                                    if (props.normalizeValue) {
                                        e.target.value = props.normalizeValue(e.target.value)
                                    }
                                    form.handleChange(e)
                                }
                            }}
                            setFieldValue={form.setFieldValue.bind(form, props.name)}
                            setFieldTouched={form.setFieldTouched}
                            onBlur={form.handleBlur}
                        />
                    )
                }}
            </Field>
        )
    }
