/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface LogoUrlsDto
 */
export interface LogoUrlsDto {
    /**
     *
     * @type {string}
     * @memberof LogoUrlsDto
     */
    wide?: string | null
    /**
     *
     * @type {string}
     * @memberof LogoUrlsDto
     */
    thumb?: string | null
    /**
     *
     * @type {string}
     * @memberof LogoUrlsDto
     */
    micro?: string | null
}

export function LogoUrlsDtoFromJSON(json: any): LogoUrlsDto {
    return LogoUrlsDtoFromJSONTyped(json, false)
}

export function LogoUrlsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogoUrlsDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        wide: !exists(json, 'wide') ? undefined : json['wide'],
        thumb: !exists(json, 'thumb') ? undefined : json['thumb'],
        micro: !exists(json, 'micro') ? undefined : json['micro'],
    }
}

export function LogoUrlsDtoToJSON(value?: LogoUrlsDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        wide: value.wide,
        thumb: value.thumb,
        micro: value.micro,
    }
}
