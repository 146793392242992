import {ArrowRight} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {accessibility} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Image from '~/global/widgets/image/Image'
import saveCrop from '~/sections/save/assets/images/save-crop.svg'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import saveActions from '~/store/save/actions'
import styles from './WithdrawSaveInfoCard.scss'

export const WithdrawSaveInfoCard: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const dispatch = useAppDispatch()
    const interestRate = useAppSelector(s => s.save.interestRate)

    React.useEffect(() => {
        if (!interestRate) {
            dispatch(saveActions.getInterestRate())
        }
    }, [])

    return (
        <>
            <button
                data-testid="button--withdraw-save-info"
                type="button"
                className={cn(styles.saveCard, accessibility.button)}
                onClick={() => navigate(profileUrl('save/intro'))}
            >
                <Image src={saveCrop} alt="" height={56} width={56} />

                <div className={styles.text}>
                    <h3>Save with no limits</h3>
                    <p>
                        Put your savings to work at {interestRate}% p.a. with no fees, fixed terms, or minimum deposits.
                    </p>
                    <p className={styles.actionText}>
                        Check out Save <ArrowRight />
                    </p>
                </div>
            </button>
            <p className={styles.smallText}>Save interest rate is subject to change.</p>
        </>
    )
}
