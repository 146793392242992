import {SubPlanV2} from '~/store/plan/types'

export const subscriptionName = (plan: SubPlanV2) => `${plan.name} plan`

/**
 * Attempt to split plan name into a dollar figure then everything else.
 *
 * Example:
 * - subscriptionNameParts('$1 kids') === ['$1', 'kids']
 * - subscriptionNameParts('$3') === ['$3', '']
 * - subscriptionNameParts('Sharesies Kiwifruit') === [undefined, 'Sharesies Kiwifruit']
 */
export const subscriptionNameParts = (name: string) => {
    const nameParts = name.split(' ', 2)
    const isPart0Pricey = nameParts[0].match('^\\$[0-9]+')
    const nameLeader = isPart0Pricey ? nameParts[0] : undefined
    const nameRemainder = !nameLeader ? name : nameParts.length > 1 ? nameParts[1] : ''
    return [nameLeader, nameRemainder]
}
