import {HelpSupport, ChevronDown} from '@design-system/icon'
import React from 'react'
import {Model} from '~/api/retail/types'
import {spacing, accessibility} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useShowIntercom} from '~/global/utils/use-show-intercom/useShowIntercom'
import {ListMenuGroup, ListMenuItem} from '~/global/widgets/list-menu/ListMenu'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import {useNavigate} from '~/migrate-react-router'
import learnHeader from '~/sections/explore/pages/learn-static-content/assets/images/learn-header.svg'
import {connect} from '~/store/connect'
import {
    ExchangesMarketsContent,
    NZExchangeContent,
    USExchangesContent,
    PubliclyListedCompaniesContent,
    FundsContent,
    ETFContent,
    ManagedFundsContent,
    AutoInvestContent,
    ManagedFundPricesContent,
    ManagedFundProcessingTimesContent,
    ExchangeRateContent,
    ExchangeFeesContent,
    PortfolioTypesContent,
    InvestmentRiskLevelsContent,
    AUExchangeContent,
    MarketOrdersContent,
    LimitOrdersContent,
    PricesOnMarketContent,
    MarketProcessingTimesContent,
    ExchangeMoneyContent,
    VoteContent,
} from './LearnContent'
import styles from './Learn.scss'

const Learn: React.FunctionComponent<StoreProps> = ({jurisdiction}) => {
    const navigate = useNavigate()
    const showIntercom = useShowIntercom()
    const pageLinksRef = React.createRef<HTMLDivElement>()
    const profileUrl = useProfileUrl()

    const learnContentURL = (slug: string) => profileUrl('explore/learn/:slug', {slug})

    const NZXLearnContent: React.FC = () => (
        <ListMenuItem
            label={NZExchangeContent.title}
            onClick={() => {
                navigate(learnContentURL(NZExchangeContent.slug))
            }}
        />
    )

    const ASXLearnContent: React.FC = () => {
        return (
            <ListMenuItem
                label={AUExchangeContent.title}
                onClick={() => {
                    navigate(learnContentURL(AUExchangeContent.slug))
                }}
            />
        )
    }

    return (
        <Page>
            <div
                className={styles.graphicHeader}
                style={{
                    backgroundImage: `url(${learnHeader})`,
                }}
            >
                <div className={styles.toolbar}>
                    <PageBack onClick={() => navigate(-1)} />
                    <button
                        type="button"
                        className={accessibility.button}
                        aria-label="Show help"
                        onClick={showIntercom}
                    >
                        <HelpSupport />
                    </button>
                </div>
            </div>
            <div className={styles.contentWrapper}>
                <div className={styles.headerContent}>
                    <h2 className={spacing.spaceBelow12}>Learn</h2>
                    <p>Check out our quick guides to investing on Sharesies.</p>
                    <div className={styles.ovalWrapper}>
                        <ChevronDown
                            onClick={() => {
                                if (pageLinksRef.current) {
                                    pageLinksRef.current.scrollIntoView({behavior: 'smooth'})
                                }
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.pageLinks} ref={pageLinksRef}>
                <h2>The basics</h2>
                <ListMenuGroup className={styles.listGroup}>
                    <ListMenuItem
                        label={ExchangesMarketsContent.title}
                        onClick={() => {
                            navigate(learnContentURL(ExchangesMarketsContent.slug))
                        }}
                    />
                    {jurisdiction === 'au' ? (
                        <>
                            <ASXLearnContent />
                            <NZXLearnContent />
                        </>
                    ) : (
                        <>
                            <NZXLearnContent />
                            <ASXLearnContent />
                        </>
                    )}
                    <ListMenuItem
                        label={USExchangesContent.title}
                        onClick={() => {
                            navigate(learnContentURL(USExchangesContent.slug))
                        }}
                    />
                    <ListMenuItem
                        label={PubliclyListedCompaniesContent.title}
                        onClick={() => {
                            navigate(learnContentURL(PubliclyListedCompaniesContent.slug))
                        }}
                    />
                    <ListMenuItem
                        label={FundsContent.title}
                        onClick={() => {
                            navigate(learnContentURL(FundsContent.slug))
                        }}
                    />
                    <ListMenuItem
                        label={ETFContent.title}
                        onClick={() => {
                            navigate(learnContentURL(ETFContent.slug))
                        }}
                    />
                    {jurisdiction === 'nz' && (
                        <ListMenuItem
                            label={ManagedFundsContent.title}
                            onClick={() => {
                                navigate(learnContentURL(ManagedFundsContent.slug))
                            }}
                        />
                    )}
                    <ListMenuItem
                        label={PortfolioTypesContent.title}
                        onClick={() => {
                            navigate(learnContentURL(PortfolioTypesContent.slug))
                        }}
                    />
                    <ListMenuItem
                        label={InvestmentRiskLevelsContent.title}
                        onClick={() => {
                            navigate(learnContentURL(InvestmentRiskLevelsContent.slug))
                        }}
                    />
                    <ListMenuItem
                        label={VoteContent.title}
                        onClick={() => {
                            navigate(learnContentURL(VoteContent.slug))
                        }}
                    />
                </ListMenuGroup>
                <h2>Order types</h2>
                <ListMenuGroup className={styles.listGroup}>
                    <ListMenuItem
                        label={MarketOrdersContent.title}
                        onClick={() => {
                            navigate(learnContentURL(MarketOrdersContent.slug))
                        }}
                    />
                    <ListMenuItem
                        label={LimitOrdersContent.title}
                        onClick={() => {
                            navigate(learnContentURL(LimitOrdersContent.slug))
                        }}
                    />

                    <ListMenuItem
                        label={AutoInvestContent.title}
                        onClick={() => {
                            navigate(learnContentURL(AutoInvestContent.slug))
                        }}
                    />
                </ListMenuGroup>
                <h2>Prices and processing times</h2>
                <ListMenuGroup className={styles.listGroup}>
                    <ListMenuItem
                        label={PricesOnMarketContent.title}
                        onClick={() => {
                            navigate(learnContentURL(PricesOnMarketContent.slug))
                        }}
                    />
                    <ListMenuItem
                        label={MarketProcessingTimesContent.title}
                        onClick={() => {
                            navigate(learnContentURL(MarketProcessingTimesContent.slug))
                        }}
                    />
                    {jurisdiction === 'nz' && (
                        <>
                            <ListMenuItem
                                label={ManagedFundPricesContent.title}
                                onClick={() => {
                                    navigate(learnContentURL(ManagedFundPricesContent.slug))
                                }}
                            />
                            <ListMenuItem
                                label={ManagedFundProcessingTimesContent.title}
                                onClick={() => {
                                    navigate(learnContentURL(ManagedFundProcessingTimesContent.slug))
                                }}
                            />
                        </>
                    )}
                </ListMenuGroup>
                <h2>Wallets and currencies</h2>
                <ListMenuGroup className={styles.listGroup}>
                    <ListMenuItem
                        label={ExchangeMoneyContent.title}
                        onClick={() => {
                            navigate(learnContentURL(ExchangeMoneyContent.slug))
                        }}
                    />
                    <ListMenuItem
                        label={ExchangeRateContent.title}
                        onClick={() => {
                            navigate(learnContentURL(ExchangeRateContent.slug))
                        }}
                    />
                    <ListMenuItem
                        label={ExchangeFeesContent.title}
                        onClick={() => {
                            navigate(learnContentURL(ExchangeFeesContent.slug))
                        }}
                    />
                </ListMenuGroup>
            </div>
        </Page>
    )
}

interface StoreProps {
    jurisdiction: Model.User['jurisdiction']
}
const ConnectedLearn = connect<StoreProps, {}, {}>(state => ({
    jurisdiction: state.identity.user!.jurisdiction,
}))(Learn)

export default ConnectedLearn
