/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {DateTime} from 'luxon'
import * as runtime from '../runtime'
import {
    DividendDateField,
    DividendDateFieldFromJSON,
    DividendDateFieldToJSON,
    DividendsResponseDto,
    DividendsResponseDtoFromJSON,
    DividendsResponseDtoToJSON,
} from '../models'

export interface ApiV1DividendsGetRequest {
    startDate?: DateTime
    endDate?: DateTime
    filteredDateField?: DividendDateField
}

/**
 *
 */
export class DividendsApi extends runtime.BaseAPI {
    /**
     */
    async apiV1DividendsGetRaw(
        requestParameters: ApiV1DividendsGetRequest,
    ): Promise<runtime.ApiResponse<DividendsResponseDto>> {
        const queryParameters: runtime.HTTPQuery = {}

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toFormat('yyyy-MM-dd')
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toFormat('yyyy-MM-dd')
        }

        if (requestParameters.filteredDateField !== undefined) {
            queryParameters['FilteredDateField'] = requestParameters.filteredDateField
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/dividends`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => DividendsResponseDtoFromJSON(jsonValue))
    }

    /**
     */
    async apiV1DividendsGet(requestParameters: ApiV1DividendsGetRequest): Promise<DividendsResponseDto> {
        const response = await this.apiV1DividendsGetRaw(requestParameters)
        return await response.value()
    }
}
