import cn from 'classnames'
import React from 'react'
import styles from './HighlightBlock.scss'

export interface HighlightBlockProps {
    className?: string
    dataTestId?: string
}

const HighlightBlock: React.FunctionComponent<HighlightBlockProps> = ({children, className, dataTestId}) => {
    return (
        <div className={cn(className, styles.highlightBlock)} data-testid={dataTestId}>
            {children}
        </div>
    )
}

export default HighlightBlock
