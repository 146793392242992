import React from 'react'
import {getTransferInstrumentValueHomeCurrency} from '~/global/utils/share-transfers/shareTransfers'
import {useExchangeRates} from '~/global/utils/use-exchange-rates/useExchangeRates'
import {useTransferInfo} from '~/global/utils/use-transfer-info/useTransferInfo'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {TransferOutFees} from '~/global/widgets/help-modals/TransferFees'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue, SharePriceValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import OrderConfirmation from '~/global/widgets/order-confirmation/OrderConfirmation'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import styles from '~/sections/invest/sections/transfer-shares/sections/nzx-transfer-shares/TransferSharesConfirmSingle.scss'
import {useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'

interface TransferSharesInLineProps {
    instrument: Instrument
    numberOfShares: string
    averagePricePaidPerShare: string | undefined
    allShares: boolean
    hasParcels: boolean
}
const TransferSharesInLine: React.FunctionComponent<TransferSharesInLineProps> = ({
    instrument,
    averagePricePaidPerShare,
    numberOfShares,
    allShares,
    hasParcels,
}) => {
    const homeCurrency = useAppSelector(({identity}) => identity.user!.home_currency)
    const [exchangeRates] = useExchangeRates()

    const totalAmount = () => {
        if (averagePricePaidPerShare) {
            return (parseFloat(averagePricePaidPerShare) * parseFloat(numberOfShares)).toFixed(2)
        }
        return getTransferInstrumentValueHomeCurrency(instrument, Number(numberOfShares), exchangeRates, homeCurrency)
    }

    return (
        <div className={styles.fundSingle}>
            <OrderConfirmation
                title={instrument.name}
                subtitle={
                    <p>
                        <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                        <PronounceLetters text={instrument.exchange} />
                    </p>
                }
                image={<InstrumentLogo instrument={instrument} noBorder />}
                items={[
                    ...(averagePricePaidPerShare
                        ? [
                              {
                                  description: 'Average price paid per share',
                                  value: (
                                      <SharePriceValue
                                          value={averagePricePaidPerShare}
                                          currency={instrument.currency}
                                      />
                                  ),
                              },
                          ]
                        : []),
                    // Don't display price and amount if has multiple parcels (AU customers only)
                    ...(!allShares && !hasParcels
                        ? [
                              {
                                  description: 'Amount',
                                  value: <DollarValue value={totalAmount()} currency={instrument.currency} />,
                              },
                          ]
                        : []),
                ]}
                total={{
                    description: 'Shares to transfer in',
                    value: allShares ? 'All' : <ShareValue value={numberOfShares} showFullValue />,
                }}
            />
        </div>
    )
}

interface TransferSharesOutLineProps {
    instrument: Instrument
    numberOfShares: string
}
const TransferSharesOutLine: React.FunctionComponent<TransferSharesOutLineProps> = ({instrument, numberOfShares}) => {
    const transferInfo = useTransferInfo()

    if (!transferInfo) {
        return <Loading isPineapple />
    }
    return (
        <div className={styles.fundSingle}>
            <OrderConfirmation
                title={instrument.name}
                subtitle={
                    <p>
                        <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                        <PronounceLetters text={instrument.exchange} />
                    </p>
                }
                image={<InstrumentLogo instrument={instrument} noBorder />}
                items={[
                    {
                        description: 'Estimated price per share',
                        value: <SharePriceValue value={instrument.marketPrice} />,
                    },
                    {
                        description: (
                            <>
                                Share transfer fee charged by Sharesies{' '}
                                <TransferOutFees exchange={instrument.exchange as 'NZX' | 'ASX'} />
                            </>
                        ),
                        value: instrument.exchange && instrument.exchange in transferInfo.fees && (
                            <DollarValue value={transferInfo.fees[instrument.exchange].charge_amount} />
                        ),
                    },
                ]}
                total={{
                    description: 'Shares to transfer out',
                    value: <ShareValue value={numberOfShares} showFullValue />,
                }}
            />
        </div>
    )
}

export {TransferSharesInLine, TransferSharesOutLine}
