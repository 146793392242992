import {useGrowthBook} from '@growthbook/growthbook-react'
import React from 'react'
import {useDispatch} from 'react-redux'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {urlFor} from '~/global/routeGenerator'
import {useProfiles} from '~/global/state-hooks/retail/useProfile'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import Page from '~/global/widgets/page/Page'
import SharesiesPineapple from '~/global/widgets/sharesies-pineapple/SharesiesPineapple'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {useNavigate} from '~/migrate-react-router'
import {useAppSelector} from '~/store/hooks'
import actions from '~/store/nav/actions'
import styles from './Welcome.scss'

interface WelcomeProps {
    existingUser?: boolean
}

const Welcome: React.FunctionComponent<WelcomeProps> = React.memo(({existingUser}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const profiles = useProfiles()
    const notificationContext = React.useContext(NotificationContext)
    const user = useAppSelector(s => s.identity.user!)

    const profile = profiles.find(p => p.legacy_customer_id === user.id)!

    const growthbook = useGrowthBook()

    const welcomeMessage = () => {
        if (user.is_dependent) {
            return <>Thanks—{user.preferred_name} is good to go</>
        }

        return (
            <>
                Thanks {user.preferred_name}—<br />
                welcome to Sharesies!
            </>
        )
    }

    React.useEffect(() => {
        // 1. Hide flyout menu and make rudderstack call
        dispatch(actions.HideFlyoutMenu())

        // Make rudderstack tracking call on initial welcome page render
        rudderTrack('signup', 'welcome_page_hit')

        // 2. Display welcome notification
        const animationDuration = 2000

        notificationContext.showFullscreenNotification(
            <div className={styles.welcome} data-testid="notification--welcome">
                <SharesiesPineapple colour="ConstantWhite" className={styles.welcomeImage} />
                <p>{welcomeMessage()}</p>
            </div>,
            {duration: animationDuration},
        )

        // 3. Redirect user to next url
        const waitThenRedirect = async () => {
            await new Promise(resolve => setTimeout(resolve, animationDuration)).then(() => {
                let url = urlFor('')

                // Tell native apps that sign up was completed on a dependent account so they can refresh the profile list and do the profile switch
                if (profile.legacy_profile_type === 'DEPENDENT') {
                    sendWrapperAppMessage({type: 'kidsSignUpComplete', profileSlug: profile.slug})
                }

                if (!existingUser) {
                    // Navigate to the deposit page for the new user
                    url = urlFor('profile/:profileSlug/sign-up/deposit', {profileSlug: profile.slug})
                }
                navigate(url)
            })
        }

        waitThenRedirect()

        // 4. Show flyout menu again
        return () => {
            dispatch(actions.ShowFlyoutMenu())
        }
    }, [profile])

    /** Temporary A/B test for portfolio overview page listing other products to try out */
    React.useEffect(() => {
        if (!growthbook) {
            return
        }

        growthbook.setAttributes({
            ...growthbook.getAttributes(),
            new_user_for_portfolio_overview_with_products_test: true,
        })
    }, [growthbook])

    return (
        <>
            <Toolbar dataTestId="toolbar--welcome" isConstantWhite />
            <Page>
                {/* display empty page upon which to overlay showFullscreenNotification() for a couple of seconds before redirecting user */}
                <></>
            </Page>
        </>
    )
})

export default Welcome
