import {Button} from '@design-system/button'
import {useFeature} from '@growthbook/growthbook-react'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {humaniseDuration} from '~/global/utils/humanise-datetime/humaniseDatetime'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import notificationActions from '~/store/notification/actions'

const DebugPage: React.FunctionComponent<{}> = () => {
    const dispatch = useAppDispatch()
    const canWriteUntil = useAppSelector(s => s.identity.canWriteUntil)
    const debugFeature = useFeature('debug')

    const [processingDeauthenticate, setProcessingDeauthenticate] = React.useState(false)

    const onDeauthenticate = async () => {
        setProcessingDeauthenticate(true)
        try {
            await dispatch(identityActions.Deauthenticate())
        } finally {
            setProcessingDeauthenticate(false)
        }
    }

    const onRefresh = () => {
        dispatch(notificationActions.ClientUpgradeRequired())
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--debug" leftButton="menu" title="Debug" isInlineTitle />
            <Page>
                <p>
                    Hello! You’ve found our secret screen! This is used by our support staff to help customers diagnose
                    particularly tricky problems. None of this information is private or sensitive, it’s just helpful.
                </p>
                <h2 className={spacing.spaceAbove16}>Load version</h2>
                <pre>{JSON.stringify(SHARESIES_BUILD_INFORMATION, null, 2)}</pre>
                <h2 className={spacing.spaceAbove16}>Session status</h2>
                <p className={spacing.spaceAbove16}>
                    {canWriteUntil
                        ? `Write expires ${humaniseDuration(canWriteUntil.diffNow())}`
                        : 'Not authorised for writes'}
                </p>
                <Button
                    dataTestId="deauthenticate"
                    label="Deauthenticate"
                    onClick={onDeauthenticate}
                    disabled={!canWriteUntil}
                    processing={processingDeauthenticate}
                    additionalClassName={spacing.spaceAbove16}
                />
                <Button
                    label="Refresh"
                    onClick={onRefresh}
                    additionalClassName={spacing.spaceAbove16}
                    dataTestId="button--reload"
                />
                <h2 className={spacing.spaceAbove16}>GrowthBook feature flag</h2>
                <p>Debug flag: {JSON.stringify(debugFeature.value)}</p>
            </Page>
        </>
    )
}

export default DebugPage
