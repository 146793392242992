import {CreateEstimateRequest} from '~/api/cove/types'
import {CoveResponseError} from '~/api/query/cove'
import {Response} from '~/api/retail/types'
import {FullNameType} from '~/global/utils/format-full-name/formatFullName'
import {CoveCreateEstimateError} from '~/sections/protect/sections/insure/sections/car/pages/create-car-estimate/CreateEstimateError'
import {CarAndDriverDetails, CreateEstimateCustomerDetails} from '~/sections/protect/sections/insure/state'

export const constructCustomerDetails = (
    address: Response.OwnerAddress['address'],
    carAndDriverDetails: CarAndDriverDetails,
    dateOfBirth: string,
    email: string,
    fullName: FullNameType,
    mobile: string,
) => {
    // Mandatory data
    const firstName = fullName.firstName
    const gender = 'O' // 'Other' is the default for now as required for Cove's endpoint. Sharesies doesn't store customer gender.
    const lastName = fullName.lastName
    const postcode = address.components.postal_code
    // Optional data
    const addressCity = address.components.locality
    const addressNumber = address.components.street_number
    const addressStreetName = address.components.route
    const addressStreet = addressNumber + ' ' + addressStreetName
    const addressSuburb = address.components.sublocality
    const drivingExperience = carAndDriverDetails.drivingExperience
    const licenceType = carAndDriverDetails.licenceType
    const registration = carAndDriverDetails.registration

    const customerDetails: CreateEstimateCustomerDetails = {
        addressCity,
        addressStreet,
        addressSuburb,
        dateOfBirth,
        drivingExperience,
        email,
        firstName,
        gender,
        lastName,
        licenceType,
        mobile,
        postcode,
        registration,
    }
    return customerDetails
}

export const constructEstimateQueryParams = (customerDetails: CreateEstimateCustomerDetails, personId: string) => {
    const params: CreateEstimateRequest = {
        address_city: customerDetails.addressCity,
        address_street: customerDetails.addressStreet,
        address_suburb: customerDetails.addressSuburb,
        dob: customerDetails.dateOfBirth,
        email: customerDetails.email,
        first_name: customerDetails.firstName,
        gender: customerDetails.gender,
        last_name: customerDetails.lastName,
        licence_type: customerDetails.licenceType,
        mobile: customerDetails.mobile,
        nz_years_experience: customerDetails.drivingExperience,
        postcode: customerDetails.postcode,
        registration: customerDetails.registration,
        sharesies_customer_id: personId,
    }
    return params
}

export const getCoveCreateEstimateErrorType = (error: CoveResponseError): CoveCreateEstimateError => {
    if (error.statusCode === 400) {
        return 'Existing customer'
    }

    if (error.statusCode === 406) {
        if (error.message.includes('Unable to match')) {
            // Cove cannot find the car in the NZTA database
            return 'Cannot find car'
        }

        if (error.message.includes('ride sharing or similar commercial purposes')) {
            // Example for testing - GLG452
            // Sorry, but we don’t insure vehicles used for ride sharing or similar commercial purposes. (Has a COF)
            return 'Commercial cars'
        }

        if (error.message.includes('All drivers must be over 21 years old')) {
            return 'Driver under 21'
        }

        if (error.message.includes('current WOF and Rego')) {
            // Example for testing - search Trademe for 'expired rego'
            // Rego expired ${licenceExpiryDate}. Please try again when the vehicle has a current WOF and Rego.
            return 'No wof or rego'
        }

        // Cove can find the car in the NZTA database, but cannot insure this type of car

        // example for testing - Tesla: LOLGAS
        // Cove response error message: "Sorry, we recognized that your [car model] is a special make model. We're not set up to insure such cars at present"

        // example for testing - non-car: A7HZQ,B4JFM
        // Sorry, we have determined your ${vehicleShort} is not a car, van, or SUV. We’re not set up to insure such vehicles at present.

        // example for testing: QHC105
        // Sorry, we have determined your ${vehicleShort} is over 3,500kg. We’re not set up to insure such cars at present.

        // Example for testing: QCG209
        // Excluded model such as Toyota Aqua

        // Sorry, but we don't insure cars (like your ${vehicleShort}) that were manufactured before 1980.
        // Sorry, but we have determined the value of your ${vehicleShort} to be greater than $${selectedVehicle.maximum_value}. We're not set up to insure such cars at present.
        // Sorry, but we’ve classified your ${vehicleDescriptionLong} as a high performance vehicle. We’re not set up to insure such cars at present.
        // Sorry, we recognized that your ${vehicleDescriptionLong} is a special make model. We're not set up to insure such cars at present. (Aqua, Teslas, etc.)
        // Looks like we can't cover your class of vehicle, if you'd like to discuss this with us, please click the live chat icon below (Vans with <= 3 seats)

        return 'Cannot insure car'
    }

    if (error.statusCode === 422 && error.message.includes('Licence plate not found')) {
        // example for testing: ZZZ111
        return 'Cannot find licence plate'
    }

    if (error.statusCode === 503) {
        if (error.message.includes('Government')) {
            return 'NZTA server unavailable'
        }

        return 'Cove server unavailable'
    }

    return 'Unknown error'
}
