import Decimal from 'decimal.js'
import {DateTime} from 'luxon'
import {Response} from '~/api/retail/types'
import {Frequency} from '~/sections/OLD_wallet/sections/linked-bank-payments/utils/frequency-select-values/frequencySelectValues'

const paymentRequestFeeRate = new Decimal('0.02')
const paymentRequestFeeCap = new Decimal('2')

export const getTopupBreakdown = (
    topupAmount?: string,
    currentSubscription?: Response.SubscriptionCurrent | Response.SubscriptionCurrentV2,
    frequency?: Frequency,
    nextPayment?: DateTime,
) => {
    if (!topupAmount) {
        return undefined
    }
    try {
        // Validate it's a number
        new Decimal(topupAmount)
    } catch (e) {
        return undefined
    }

    let feeAmount: number | undefined

    if (currentSubscription && currentSubscription.plan.includes_recurring_payments) {
        feeAmount = 0
    } else {
        const amountDecimal = new Decimal(topupAmount)
        feeAmount = amountDecimal.mul(paymentRequestFeeRate).clamp(0, paymentRequestFeeCap).toNumber()
    }

    const totalAmount = new Decimal(topupAmount).add(new Decimal(feeAmount ?? '0')).toNumber()
    return {
        feeAmount,
        topupAmount: new Decimal(topupAmount).toNumber(),
        totalAmount,
        frequency,
        nextPayment,
    }
}
