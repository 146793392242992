import {useAtom} from 'jotai'
import React from 'react'
import {EsInstrumentType} from '~/api/distill'
import type {Model} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {dateFormatNoYear} from '~/global/utils/format-date/formatDate'
import {isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {isInternalLink, normalizeUrl} from '~/global/utils/normalize-url/normalizeUrl'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useInstrument} from '~/global/utils/use-instruments/useInstruments'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {VoteIntroBlurb} from '~/global/widgets/corporate-action-description/CorporateActionDescription'
import {hasUsLiveDataErrorAtom} from '~/sections/invest/sections/view-instrument/hooks/usLivePricing'
import {VoluntaryCorporateActionV2} from '~/store/accounting/types'
import {useAppSelector} from '~/store/hooks'
import {selectHasUsLivePricing} from '~/store/identity/selectors'
import {selectIsExchangeInMarketHours, selectIsInstrumentInExtendedHours} from '~/store/instrument/selectors'
import {Instrument} from '~/store/instrument/types'
import {Application, ApplicationAlert} from '~/store/order/types'

export interface InstrumentAlertCardProps {
    className?: string
    instrument: Instrument
}

export const CloseOnlyInstrumentAlertCard: React.FunctionComponent<InstrumentAlertCardProps> = ({
    className,
    instrument,
}) => (
    <AlertCard
        className={className}
        type="warning"
        title={`You can’t buy shares in ${instrument.name} right now`}
        text="You’ll be able to place a buy order when the investment’s status is no longer ‘Sell only’.
              To see if the status has changed, you’ll need to refresh the page. We can’t confirm when
              this might happen."
    />
)

export const VolatileInstrumentAlertCard: React.FunctionComponent<InstrumentAlertCardProps> = ({
    className,
    instrument,
}) => (
    <AlertCard
        className={className}
        type="warning"
        title={`${instrument.name} is a volatile investment`}
        text="A volatile investment has a share price that frequently changes by large amounts—it could
               go up one moment, and down the next."
    />
)

export const TradeWithCautionAlertCard: React.FunctionComponent<InstrumentAlertCardProps> = ({
    className,
    instrument,
}) => {
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const websiteUrl =
        jurisdiction === 'nz'
            ? 'https://intercom.help/sharesies/en/articles/9165824-nz-regco-trade-with-caution-alert'
            : 'https://intercom.help/sharesies-au/en/articles/9165840-nz-regco-trade-with-caution-alert'
    return (
        <AlertCard className={className} type="warning" title="Trade with Caution">
            NZ RegCo has advised that there are additional risks related to {instrument.name}.{' '}
            <a target="_blank" rel="noopener" href={websiteUrl}>
                Learn more
            </a>
        </AlertCard>
    )
}

interface RightsIssueAlertProps {
    instrument: Instrument
    rightsIssue?: Model.CorporateActionV2
    application: Application
    className?: string
}

export const RightsIssueAlert = ({instrument, rightsIssue, application, className}: RightsIssueAlertProps) => {
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const parentInstrument = useInstrument(instrument.parentInstrumentId)
    const instrumentSymbol = parentInstrument?.symbol ?? instrument.symbol

    const urlForCustomer = (info_url: string) => {
        if (info_url.startsWith('http')) {
            return info_url
        }

        const websiteUrl = jurisdiction === 'au' ? 'https://www.sharesies.com.au' : 'https://www.sharesies.nz'
        return `${websiteUrl}/${info_url}`
    }

    const closeTime = application.applications_close_at && application.applications_close_at.toFormat(dateFormatNoYear)
    const links = application.info_url
        ? [{href: urlForCustomer(application.info_url), text: 'Find out more about the offer'}]
        : []

    const title = () => {
        const unitArticle = instrument.instrumentType === 'options' ? 'an' : 'a'
        if (!rightsIssue) {
            return `${instrumentSymbol} have announced ${unitArticle} ${shareLabel({instrument, isPlural: true})} offer`
        }

        // Truncate the rights issued to 4 dp and remove any trailing zeros
        const rightsIssued = rightsIssue.outcome_records[0].gross_amount
        const bitToKeep = rightsIssued.match(/\d+(\.\d{4})?/)
        const rightsIssuedRounded = bitToKeep ? bitToKeep[0].replace(/0+$/, '').replace(/\.$/, '') : rightsIssued

        return `You’ve received ${rightsIssuedRounded} ${shareLabel({
            instrument,
            isPlural: rightsIssuedRounded !== '1',
        })}`
    }

    return (
        <AlertCard
            className={className}
            type="info"
            title={title()}
            text={
                closeTime &&
                `You’ve got until ${closeTime} if you want to exercise your rights into ${instrumentSymbol} shares.`
            }
            links={links}
        />
    )
}

export const ShareSplitPriceWarning = ({
    instrument,
    order,
    className,
}: {
    instrument: Instrument
    order: Model.CorporateActionV2 & {action_type: 'SHARE_SPLIT' | 'SHARE_CONSOLIDATION'}
    className?: string
}) => {
    return (
        <AlertCard
            className={className}
            type="warning"
            title="Your returns might appear incorrect"
            text={`${instrument.name} is undergoing a share ${
                order.action_type === 'SHARE_SPLIT' ? 'split' : 'consolidation'
            }. As a result, its share price (and your returns) might appear incorrect. These will update with the correct info when it’s available.`}
        />
    )
}

export const ApplicationAlertCard = ({alert, className}: {alert: ApplicationAlert; className?: string}) => {
    const linkDestination = alert.link_href ? normalizeUrl(alert.link_href) : undefined
    return (
        <AlertCard
            className={className}
            type="info"
            title={alert.title}
            text={alert.body}
            links={
                alert.link_text && linkDestination
                    ? [
                          {
                              text: alert.link_text,
                              url: isInternalLink(linkDestination) ? linkDestination : undefined,
                              href: isInternalLink(linkDestination) ? undefined : linkDestination,
                          },
                      ]
                    : []
            }
        />
    )
}

export const VoteAvailableCard: React.FC<{
    instrument: Instrument
    application: Application
    className?: string
}> = ({instrument, application, className}) => {
    const profileUrl = useProfileUrl()
    const pendingOrders = useAppSelector(({identity}) => identity.orders)

    const pendingVote = pendingOrders.find(
        (order): order is VoluntaryCorporateActionV2 =>
            order.type === 'corporate_action_v2' &&
            order.application?.rule_id === application.application_rule_id &&
            order.application?.is_cancelled === false,
    )

    if (pendingVote) {
        return null
    }
    return (
        <AlertCard
            className={className}
            type="info"
            title="Upcoming vote"
            text={
                <VoteIntroBlurb
                    title={application.investment_option_title}
                    instrument={instrument}
                    settlementDate={application.settlement_date}
                />
            }
            links={[
                {
                    text: 'Vote now',
                    url: profileUrl('invest/:instrumentSlug/apply/:applicationRuleId', {
                        instrumentSlug: instrument.urlSlug,
                        applicationRuleId: application.application_rule_id,
                    }),
                    callback: () =>
                        rudderTrack('voting', 'vote_now_link_clicked', {
                            referrer: 'instrument_alert_card',
                            ca_application_rule_id: application.application_rule_id,
                        }),
                },
            ]}
        />
    )
}

export const HighRiskDerivativeAlertCard: React.FunctionComponent<InstrumentAlertCardProps> = ({
    className,
    instrument,
}) => {
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const parentInstrument = useInstrument(instrument.parentInstrumentId)
    if (!parentInstrument) {
        return null
    }

    const unitArticle = instrument.instrumentType === EsInstrumentType.options ? 'An' : 'A'

    return (
        <AlertCard
            className={className}
            type="warning"
            title={`${parentInstrument.name} ${shareLabel({
                instrument,
                isPlural: true,
            })} aren’t the same as ${shareLabel({instrument: parentInstrument, isPlural: true})} in ${
                parentInstrument.name
            }`}
            links={
                instrument.instrumentType === EsInstrumentType.warrants
                    ? [
                          {
                              text: 'Learn more',
                              href:
                                  jurisdiction === 'au'
                                      ? 'https://intercom.help/sharesies-au/en/articles/7869557-warrants-and-options'
                                      : 'https://intercom.help/sharesies/en/articles/5325990-warrant-offers',
                          },
                      ]
                    : []
            }
        >
            <p>
                {unitArticle} {shareLabel({instrument})} gives investors the right to buy additional{' '}
                {shareLabel({instrument: parentInstrument, isPlural: true})} at a set price (called ‘the exercise
                price’).
            </p>
            <p className={spacing.spaceAbove16}>
                If the exercise price is higher than {parentInstrument.name}’s{' '}
                {shareLabel({instrument: parentInstrument})} price, you might pay more for the{' '}
                {shareLabel({instrument: parentInstrument, isPlural: true})} than they’re worth at the time.
            </p>
        </AlertCard>
    )
}

export const ESSBlackoutPeriodAlertCard: React.FunctionComponent<InstrumentAlertCardProps> = ({
    className,
    instrument,
}) => {
    return (
        <AlertCard className={className} type="warning" title={`${instrument.name} is currently in a blackout period`}>
            <p>Buying and selling of {instrument.name} shares is currently paused.</p>
        </AlertCard>
    )
}

export const OtcInstrumentActiveAlertCard: React.FunctionComponent<{className: string}> = ({className}) => (
    <AlertCard
        className={className}
        type="warning"
        text="This investment is currently trading  over-the-counter (OTC), which means it’s not listed on a major US exchange and has additional risks. Pricing info is likely to be delayed and orders can take longer to fill."
        links={[
            {
                text: 'Learn more',
                href: 'https://intercom.help/sharesies/en/articles/8529187-us-over-the-counter-otc-investments',
                sameWindow: false,
            },
        ]}
    />
)

export const OtcInstrumentCloseOnlyAlertCard: React.FunctionComponent<{className: string}> = ({className}) => (
    <AlertCard
        className={className}
        type="warning"
        text="This investment is currently trading over-the-counter (OTC), which means it’s not listed on a major US exchange and has additional risks. Pricing info is likely to be delayed and orders can take longer to fill. For now, only sell orders can be placed for this investment."
        links={[
            {
                text: 'Learn more',
                href: 'https://intercom.help/sharesies/en/articles/8529187-us-over-the-counter-otc-investments',
                sameWindow: false,
            },
        ]}
    />
)

export const OtcInstrumentNoTradeAlertCard: React.FunctionComponent<{className: string}> = ({className}) => (
    // OTC instruments with a notrade status are classed as Expert Market Securities.
    <AlertCard
        className={className}
        type="warning"
        text="This investment is currently trading on the Expert Market (a sub-tier of the over-the-counter market), where buying and selling is limited to eligible investors. Orders are likely to take longer to fill and price info is likely to be delayed. Reach out to us if you want to sell this investment."
        links={[
            {
                text: 'Learn more',
                href: 'https://intercom.help/sharesies/en/articles/8529187-us-over-the-counter-otc-investments',
                sameWindow: false,
            },
        ]}
    />
)

export const UsLivePricingUnavailableAlertCard: React.FunctionComponent<{
    className: string
    instrument: Instrument
}> = ({className, instrument}) => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [hasUsLiveDataError, _] = useAtom(hasUsLiveDataErrorAtom)

    const hasUsLivePricing = useAppSelector(s => selectHasUsLivePricing(s))

    const inMarketHours = useAppSelector(s => selectIsExchangeInMarketHours(s, 'CBOE'))
    const inExtendedHours = useAppSelector(s => selectIsInstrumentInExtendedHours(s, instrument)) // This selector checks if they've enabled extended hours
    const inOpeningHours = inMarketHours || !!inExtendedHours

    if (!hasUsLivePricing || !isOnMainUsExchange(instrument) || !hasUsLiveDataError || !inOpeningHours) {
        return null
    }

    return (
        <AlertCard
            className={className}
            type="warning"
            text="Live prices aren’t currently available, so we’re showing prices that are delayed by at least 20 minutes."
        />
    )
}
