import {useEffect} from 'react'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'
import {Instrument} from '~/store/instrument/types'

export function useInstruments(
    instrumentIds: (string | null | undefined)[],
): [{[id: string]: Instrument | null}, boolean] {
    const ids = instrumentIds.filter(id => id != null) as string[]
    const {instrumentsById, resultsLoadingState} = useAppSelector(s => s.instrument)
    const instruments = ids.reduce((map, id) => ({...map, [id]: instrumentsById[id] ?? null}), {})
    const dispatch = useAppDispatch()

    const unloadedInstruments = ids.filter(id => !instrumentsById[id])
    const allLoaded = unloadedInstruments.length === 0

    useEffect(() => {
        if (resultsLoadingState === 'ready' && unloadedInstruments.length) {
            dispatch(instrumentActions.getInstrumentsByIds(unloadedInstruments))
        }
    }, [ids.sort().join(), resultsLoadingState])

    return [instruments, allLoaded]
}

export function useInstrument(instrumentId: string | null | undefined) {
    const [instruments] = useInstruments([instrumentId])
    return instrumentId ? instruments[instrumentId] : null
}
