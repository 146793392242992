import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {useNavigate} from '~/migrate-react-router'
import {useAppSelector, useAppDispatch} from '~/store/hooks'
import actions from '~/store/identity/actions'

const EmailVerificationRequired: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const [email, emailVerified, emailVerificationToken] = useAppSelector(({identity}) => [
        identity.user!.email,
        identity.user!.email_verified,
        identity.user!.has_current_email_verification_token,
    ])
    const showBanner = email && !emailVerified && !emailVerificationToken

    React.useEffect(() => {
        dispatch(actions.Check())
    }, [emailVerified])

    if (!showBanner) {
        return null
    }

    return (
        <AlertCard
            className={spacing.spaceBelow16}
            title="Verify your email address"
            type="none"
            links={[
                {
                    text: 'Verify now',
                    callback: () => navigate(profileUrl('settings/email-verification')),
                    dataTestId: 'alert-link--verify-email',
                },
            ]}
        >
            <p className={spacing.spaceBelow4}>
                To keep your account safe, verify your email address—it takes just two ticks.
            </p>
        </AlertCard>
    )
}

export default EmailVerificationRequired
