import React from 'react'
import {useAppSelector} from '~/store/hooks'
import styles from './OrderProcessMessage.scss'

const ManagedFundOrderProcessMessage: React.FunctionComponent = () => {
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)

    return (
        <div className={styles.orderProcessMessage}>
            <>
                <p>
                    Each fund has different processing times to fill your order and confirm the price you get. This
                    means there’s a delay between when you place an order, and when it shows in{' '}
                    {isDependent ? `${preferredName}’s` : 'your'} Portfolio.
                </p>
                <p>This can range from 1 to 2 trading days, but some managed funds can take up to 23 trading days.</p>
                <p>
                    We start processing orders at around 12:30pm each trading day and send these to the relevant fund
                    provider. Once your order is processing you can’t cancel it anymore.
                </p>
            </>
        </div>
    )
}

export default ManagedFundOrderProcessMessage
