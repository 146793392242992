/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface UnvestedDetail
 */
export interface UnvestedDetail {
    /**
     *
     * @type {number}
     * @memberof UnvestedDetail
     */
    shares_unvested: number
    /**
     *
     * @type {number}
     * @memberof UnvestedDetail
     */
    unvested_capital_gains: number
    /**
     *
     * @type {number}
     * @memberof UnvestedDetail
     */
    unvested_investment_value: number
    /**
     *
     * @type {number}
     * @memberof UnvestedDetail
     */
    unvested_simple_return: number
}

export function UnvestedDetailFromJSON(json: any): UnvestedDetail {
    return UnvestedDetailFromJSONTyped(json, false)
}

export function UnvestedDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnvestedDetail {
    if (json === undefined || json === null) {
        return json
    }
    return {
        shares_unvested: json['shares_unvested'],
        unvested_capital_gains: json['unvested_capital_gains'],
        unvested_investment_value: json['unvested_investment_value'],
        unvested_simple_return: json['unvested_simple_return'],
    }
}

export function UnvestedDetailToJSON(value?: UnvestedDetail | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        shares_unvested: value.shares_unvested,
        unvested_capital_gains: value.unvested_capital_gains,
        unvested_investment_value: value.unvested_investment_value,
        unvested_simple_return: value.unvested_simple_return,
    }
}
