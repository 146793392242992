import {semanticColour} from '@design-system/colour-tokens'
import {AddCircle, RecurringTopUp} from '@design-system/icon'
import {useColourMode} from '@design-system/use-colour-mode'
import {useSetAtom} from 'jotai'
import {DateTime} from 'luxon'
import React from 'react'
import {useNavigate} from 'react-router'
import {spacing} from '~/global/scss/helpers'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useWalletBreakdown} from '~/global/state-hooks/retail/useWalletBreakdown'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import RecurringTile from '~/global/widgets/recurring-transaction-tile/RecurringTile'
import styles from '~/sections/save/sections/activity/Activity.scss'
import {useSelectedSaveAccount} from '~/sections/save/state/selectedSaveAccountState'
import {stagedTransferAtom, useRecurringTransfers} from '~/sections/save/state/transferState'
import {intervalToString} from '~/sections/save/utils/interval-to-string/intervalToString'
import {WalletPortfolio} from '~/sections/wallet/types'
import {useAppSelector} from '~/store/hooks'
import {TransferAccountDetails} from '~/store/save/types'

interface RecurringTransferProps {
    portfolioId: string
}

const RecurringTransfers: React.FunctionComponent<RecurringTransferProps> = ({portfolioId}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const profile = useProfile()
    const colourMode = useColourMode()
    const setStagedTransfer = useSetAtom(stagedTransferAtom)
    const selectedSaveAccount = useSelectedSaveAccount(portfolioId)
    const walletPortfolio = profile.portfolios.find(p => p.product === 'WALLET') as WalletPortfolio // We can do this because currently there is only ONE wallet portfolio per profile
    const walletBreakdown = useWalletBreakdown(walletPortfolio.id)
    const recurringTransfers = useRecurringTransfers(portfolioId)

    const currency = useAppSelector(s => s.identity.user!.home_currency)

    const editTransfer = (transferId: string, portfolioId: string) => {
        const recurringTransfer = recurringTransfers.find(transfer => transfer.id === transferId)

        if (!recurringTransfer) {
            return
        }

        if (!recurringTransfer.direction) {
            recurringTransfer.direction = 'DEPOSIT'
        }

        const walletAccount: TransferAccountDetails = {
            portfolioId: walletPortfolio.id,
            name: 'Wallet',
            type: 'wallet',
            balance: walletBreakdown.instructable_balance,
        }

        const saveTransferAccount: TransferAccountDetails = {
            saveAccountId: selectedSaveAccount.id,
            portfolioId: selectedSaveAccount.portfolio_id,
            name: selectedSaveAccount.name || 'Save',
            type: 'save',
            balance: selectedSaveAccount.balance,
        }

        setStagedTransfer({
            originAccount: recurringTransfer.direction === 'DEPOSIT' ? walletAccount : saveTransferAccount,
            targetAccount: recurringTransfer.direction === 'WITHDRAW' ? walletAccount : saveTransferAccount,
            amount: recurringTransfer.value,
            instruction: recurringTransfer.direction,
            interval: recurringTransfer?.interval,
            nextDate: recurringTransfer?.next_run,
            meta: {recurringTransferId: recurringTransfer.id},
        })

        navigate(
            profileUrl('save/recurring-transfer/:portfolioId/:recurringTransferId', {
                portfolioId,
                recurringTransferId: transferId,
            }),
        )
    }

    if (recurringTransfers.length === 0) {
        return (
            <div className={styles.createRecurringBox}>
                <p className={spacing.spaceBelow12}>Watch your savings grow in the background.</p>
                <span>
                    <AddCircle color={semanticColour('link-default', colourMode)} size={16} />
                    <ButtonAsLink
                        noTextDecoration
                        onClick={() => {
                            navigate(profileUrl('save/recurring-transfer/:portfolioId', {portfolioId}))
                        }}
                    >
                        Create recurring transfer
                    </ButtonAsLink>
                </span>
            </div>
        )
    }

    return (
        <div>
            {recurringTransfers.map((txn, index) => (
                <RecurringTile
                    additionalClassName={spacing.spaceBelow12}
                    dataTestId={`button--recurring-transfer-${index}`}
                    displayImage={<RecurringTopUp />}
                    handleClick={() => editTransfer(txn.id, portfolioId)}
                    key={index}
                    title={
                        intervalToString[txn.interval] +
                        ' transfer' +
                        (txn.direction === 'DEPOSIT' ? ' from Wallet' : ' to Wallet')
                    }
                    subtitle={<DollarValue value={txn.value} currency={currency} />}
                    nextDate={DateTime.fromISO(txn.next_run)}
                    state={txn.state}
                />
            ))}
        </div>
    )
}

export default RecurringTransfers
