/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface AssetManagerDto
 */
export interface AssetManagerDto {
    /**
     *
     * @type {string}
     * @memberof AssetManagerDto
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof AssetManagerDto
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof AssetManagerDto
     */
    websiteUrl: string
    /**
     *
     * @type {string}
     * @memberof AssetManagerDto
     */
    shortName: string
    /**
     *
     * @type {string}
     * @memberof AssetManagerDto
     */
    timeZone: string
}

export function AssetManagerDtoFromJSON(json: any): AssetManagerDto {
    return AssetManagerDtoFromJSONTyped(json, false)
}

export function AssetManagerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetManagerDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        id: json['id'],
        name: json['name'],
        websiteUrl: json['websiteUrl'],
        shortName: json['shortName'],
        timeZone: json['timeZone'],
    }
}

export function AssetManagerDtoToJSON(value?: AssetManagerDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        id: value.id,
        name: value.name,
        websiteUrl: value.websiteUrl,
        shortName: value.shortName,
        timeZone: value.timeZone,
    }
}
