import {DockableModal} from '@design-system/dockable-modal'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {ExchangeDto, TimeRangeDto, TradingStatus} from '~/api/distill'
import ExchangeOpenStatusRefetch from '~/global/data-components/ExchangeOpenStatusRefetch'
import {accessibility} from '~/global/scss/helpers'
import {dateFormatFullDay, dateFormatTimeAmPm} from '~/global/utils/format-date/formatDate'
import {ExchangeOpen, ExchangeClosed, TradingHalt, SellOnly} from '~/global/widgets/OLD_icons'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import styles from './ExchangeHours.scss'

const IsExchangeOpen = (exchange: ExchangeDto | undefined): boolean => {
    const matchingDate = exchange
        ? exchange.openHours.find(
              (openDay: TimeRangeDto) =>
                  DateTime.local() >= openDay.start.toLocal() && DateTime.local() <= openDay.finish.toLocal(),
          )
        : undefined

    return !!matchingDate
}

interface ExchangeHoursProps {
    exchange: ExchangeDto | undefined
    instrumentTradingStatus: TradingStatus
}

/**
 * Displays an informational widget for the exchange hours and trading status.
 *
 * Note: does not currently support US extended trading.
 */
export const ExchangeHours: React.FunctionComponent<ExchangeHoursProps> = ({exchange, instrumentTradingStatus}) => {
    const isExchangeOpen = IsExchangeOpen(exchange)
    const instrumentInHalt = instrumentTradingStatus === 'halt'
    const instrumentCloseOnly = instrumentTradingStatus === TradingStatus.closeonly

    const [exchangeHoursModalOpen, setExchangeHoursModalOpen] = React.useState(false)

    // when the modal is open & user presses Escape key, this closes the modal
    React.useEffect(() => {
        if (!exchangeHoursModalOpen) {
            return
        }

        const handleEscapeKey = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                setExchangeHoursModalOpen(false)
            }
        }

        window.addEventListener('keyup', handleEscapeKey)

        return () => {
            window.removeEventListener('keyup', handleEscapeKey)
        }
    }, [exchangeHoursModalOpen])

    if (!exchange) {
        return null
    }

    // if exchange hours is 'out of date' (first 'open day' is before user's current date), set expected day to the first 'open day', otherwise set it to current date
    let expectedDay =
        exchange.openHours.length > 0 && exchange.openHours[0].start.toLocal().day < DateTime.local().day
            ? exchange.openHours[0].start.toLocal()
            : DateTime.local()

    const displayExchangeIcon = instrumentInHalt ? (
        <TradingHalt />
    ) : instrumentCloseOnly ? (
        <SellOnly />
    ) : isExchangeOpen ? (
        <ExchangeOpen />
    ) : (
        <ExchangeClosed />
    )

    const displayExchangeStateText = instrumentInHalt
        ? 'Halt'
        : instrumentCloseOnly
          ? 'Sell only'
          : isExchangeOpen
            ? 'Open'
            : 'Closed'

    const exchangeHoursContent = instrumentInHalt ? (
        <div className={styles.haltText}>
            <p>
                A trading halt is when an exchange temporarily stops trading of an investment due to an upcoming news
                announcement, an order imbalance, technical issues or regulatory concerns.
            </p>
            {/* TODO - add help centre link once specific content is ready*/}
        </div>
    ) : instrumentCloseOnly ? (
        <div className={styles.haltText}>
            <p>
                While in ‘Sell only’, you can only place sell orders for an investment. You can’t place buy orders
                because the investment might have a high trading volume, or a rapidly changing share price.
            </p>
        </div>
    ) : (
        <div className={styles.dateTimeRows}>
            {Array(7) // using array for the purpose of iterating seven times (0-6), need to incl. 'closed' days not in the openHours array
                .fill(null)
                .map((_, index) => {
                    expectedDay = expectedDay.plus({days: 1}) // increment to the next expected day
                    // check whether the 'expected day' exists inside the open hours array, to determine whether to show open/closed exchange
                    const openDay = exchange.openHours.find(openDay => expectedDay.day === openDay.start.toLocal().day)
                    const exchangeStartTimeRelative = openDay ? openDay.start.toLocal() : undefined
                    const exchangeFinishTimeRelative = openDay ? openDay.finish.toLocal() : undefined

                    return (
                        <span key={index}>
                            {exchangeStartTimeRelative ? (
                                <p>{exchangeStartTimeRelative.toFormat(dateFormatFullDay)}</p>
                            ) : (
                                <p>{expectedDay ? expectedDay.toFormat(dateFormatFullDay) : ''}</p>
                            )}
                            {exchangeStartTimeRelative && exchangeFinishTimeRelative ? (
                                <p>
                                    {exchangeStartTimeRelative.toFormat(dateFormatTimeAmPm)} -{' '}
                                    {exchangeFinishTimeRelative.toFormat(dateFormatTimeAmPm)}
                                </p>
                            ) : (
                                <p>Closed</p>
                            )}
                        </span>
                    )
                })}
        </div>
    )

    return (
        <>
            <div className={styles.marketIndicatorWrapper}>
                <button
                    type="button"
                    className={cn(styles.marketIndicator, accessibility.button)}
                    onClick={() => setExchangeHoursModalOpen(true)}
                >
                    <p>{displayExchangeStateText}</p>
                    {displayExchangeIcon}
                </button>
            </div>

            <DockableModal
                dataTestId="estimated-wallet-balance-modal"
                isOpen={exchangeHoursModalOpen}
                setIsOpen={setExchangeHoursModalOpen}
                title={
                    <h2>
                        <PronounceLetters text={exchange.name} /> hours
                    </h2>
                }
                content={exchangeHoursContent}
                customZIndex={1051} // same as zindex(detail-modal)
            />
            {/* Update exchange open status periodically - 1 hour */}
            <ExchangeOpenStatusRefetch />
        </>
    )
}
