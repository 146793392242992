import {Button} from '@design-system/button'
import {withFormik, InjectedFormikProps} from 'formik'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {validate} from '~/global/widgets/form-controls'
import {IRDNumber as IRDTextInput} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import SignupToolbar from '~/sections/user/sections/sign-up/widgets/signup-toolbar/SignupToolbar'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'

type FormProps = StateProps & {
    setIRDNumber(irdNumber: string): Promise<string | undefined> | void // US Sign up just returns void
}

interface FormValues {
    ird_number: string
}

export const IrdNumberForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => ({
        ird_number: '',
    }),
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        ird_number: undefined,
    }),
    handleSubmit: async ({ird_number}, {setSubmitting, setStatus, props: {setIRDNumber}}) => {
        try {
            const error = await setIRDNumber(ird_number)
            if (error) {
                setStatus(error)
                setSubmitting(false)
                return
            }
        } catch (e) {
            setStatus(unknownErrorMessage)
            setSubmitting(false)
            throw e
        }
    },
    validate: validate.generate<FormValues>({
        ird_number: [validate.required(), validate.irdNumber()],
    }),
})(
    class Form extends React.Component<InjectedFormikProps<FormProps, FormValues>, {}> {
        render() {
            const {isSubmitting, handleSubmit, isValid, isDependent, preferredName} = this.props

            return (
                <form onSubmit={handleSubmit}>
                    <IRDTextInput
                        dataTestId="text-input--ird-number"
                        name="ird_number"
                        label={isDependent ? `${preferredName}’s IRD number` : 'IRD Number'}
                    />
                    <Button
                        isSubmit
                        pageButton
                        dataTestId="button--next"
                        label="Next"
                        disabled={!isValid}
                        processing={isSubmitting}
                        additionalClassName={spacing.spaceBelow48}
                    />
                </form>
            )
        }
    },
)

export class IRDNumber extends React.PureComponent<StateProps & DispatchProps, {}> {
    public render() {
        const {isDependent, preferredName} = this.props

        return (
            <>
                <SignupToolbar />
                <Page>
                    {isDependent ? (
                        <div className={spacing.spaceBelow24}>
                            <h1 className={spacing.spaceBelow16}>We need some tax info</h1>
                            <p>
                                Sharesies calculates NZ tax for {preferredName} so we need some information to make sure
                                we pay it to the right account.
                            </p>
                        </div>
                    ) : (
                        <h1 className={spacing.spaceBelow16}>NZ tax information</h1>
                    )}
                    <IrdNumberForm
                        setIRDNumber={this.props.setIRDNumber}
                        isDependent={isDependent}
                        preferredName={preferredName}
                    />
                </Page>
            </>
        )
    }
}

interface StateProps {
    isDependent: boolean
    preferredName: string
}

interface DispatchProps {
    setIRDNumber: (irdNumber: string) => Promise<string | undefined>
}

export default connect<StateProps, DispatchProps>(
    ({identity}) => ({
        isDependent: identity.user!.is_dependent,
        preferredName: identity.user!.preferred_name,
    }),
    dispatch => ({
        setIRDNumber: irdNumber => dispatch(actions.setIRDNumber(irdNumber)),
    }),
)(IRDNumber)
