/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    AppSchemasForAdminPortfolioLatestInstrumentReturn,
    AppSchemasForAdminPortfolioLatestInstrumentReturnFromJSON,
    AppSchemasForAdminPortfolioLatestInstrumentReturnFromJSONTyped,
    AppSchemasForAdminPortfolioLatestInstrumentReturnToJSON,
    AppSchemasForAdminPortfolioLatestInstrumentReturnToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface AppSchemasForAdminPortfolioLatestInstrumentReturns
 */
export interface AppSchemasForAdminPortfolioLatestInstrumentReturns {
    /**
     *
     * @type {{ [key: string]: AppSchemasForAdminPortfolioLatestInstrumentReturn; }}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturns
     */
    instrument_returns: {[key: string]: AppSchemasForAdminPortfolioLatestInstrumentReturn}
}

export function AppSchemasForAdminPortfolioLatestInstrumentReturnsFromJSON(
    json: any,
): AppSchemasForAdminPortfolioLatestInstrumentReturns {
    return AppSchemasForAdminPortfolioLatestInstrumentReturnsFromJSONTyped(json, false)
}

export function AppSchemasForAdminPortfolioLatestInstrumentReturnsFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AppSchemasForAdminPortfolioLatestInstrumentReturns {
    if (json === undefined || json === null) {
        return json
    }
    return {
        instrument_returns: mapValues(
            json['instrument_returns'],
            AppSchemasForAdminPortfolioLatestInstrumentReturnFromJSON,
        ),
    }
}

export function AppSchemasForAdminPortfolioLatestInstrumentReturnsToJSON(
    value?: AppSchemasForAdminPortfolioLatestInstrumentReturns | null,
): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        instrument_returns: mapValues(
            value.instrument_returns,
            AppSchemasForAdminPortfolioLatestInstrumentReturnToJSON,
        ),
    }
}
