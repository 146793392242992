import {AssetManagerDto} from '~/api/distill'
import {DistillScope} from '~/api/query/distill'
import useDistillInstrumentInfo from '~/global/state-hooks/distill/useDistillInstrumentInfo'
import {BasicInstrument} from '~/store/instrument/types'

interface UseAssetManagerOptions {
    scope: DistillScope
    searchFundInvestments?: boolean
}
function useAssetManager(
    instrument: BasicInstrument | undefined,
    {scope, searchFundInvestments = false}: UseAssetManagerOptions,
): AssetManagerDto | undefined {
    const distillMetadata = useDistillInstrumentInfo(scope, {searchFundInvestments})
    return distillMetadata.assetManagers.find(x => x.id === instrument?.assetManager)
}

export default useAssetManager
