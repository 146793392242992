import {useAppSelector} from '~/store/hooks'
import {useReadPortfolio} from './useReadPortfolio'

export const useHasOrderedOrHeldShares = (portfolioId: string) => {
    const {cost_basis_max} = useReadPortfolio(portfolioId)
    const orders = useAppSelector(s => s.identity.orders)

    const hasBoughtTransferredOrVested = !!cost_basis_max && cost_basis_max > 0
    if (hasBoughtTransferredOrVested) {
        return true
    }

    const hasPendingOrders = orders.some(order => ['csn_transfer_order', 'buy'].includes(order.type))
    return hasPendingOrders
}
