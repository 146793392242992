import React from 'react'
import {spacing} from '~/global/scss/helpers'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import PrivacyStatement from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/PrivacyStatement'

/**
 * In AU, this document is referred to as a "Privacy Statement" rather than a "Privacy Policy".
 */
const LegalPrivacyStatement: React.FunctionComponent<{}> = () => {
    return (
        <>
            <Toolbar dataTestId="toolbar--legal-privacy-statement" leftButton="back" title="Legal" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <h2 className={spacing.spaceBelow16}>Privacy Statement</h2>
                <PrivacyStatement />
            </Page>
        </>
    )
}

export default LegalPrivacyStatement
