import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {Loading} from '~/global/widgets/loading'
import styles from './Record.scss'

const LoadingCard: React.FC = () => (
    <div className={styles.orderContent}>
        <Loading className={spacing.spaceBelow32} />
    </div>
)

export default LoadingCard
