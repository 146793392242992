import React from 'react'
import Image from '~/global/widgets/image/Image'
import asbLightLarge from '~/sections/OLD_wallet/sections/top-up/sections/top-up-instant/assets/public_blinkpay/asb_lightmode_large.png'
import bnzDarkLarge from '~/sections/OLD_wallet/sections/top-up/sections/top-up-instant/assets/public_blinkpay/bnz_darkmode_large.png'
import bnzLightLarge from '~/sections/OLD_wallet/sections/top-up/sections/top-up-instant/assets/public_blinkpay/bnz_lightmode_large.png'
import westpacLightLarge from '~/sections/OLD_wallet/sections/top-up/sections/top-up-instant/assets/public_blinkpay/westpac_lightmode_large.png'
import styles from './Logos.scss'

export const AsbLogo = () => (
    <Image
        className={styles.asbLogo}
        src={asbLightLarge} // files in this folder are also shipped to external apps via build config
        alt="ASB logo"
    />
)

export const BnzLogo = () => (
    <Image
        className={styles.bnzLogo}
        src={bnzLightLarge} // files in this folder are also shipped to external apps via build config
        darkSrc={bnzDarkLarge}
        alt="BNZ logo"
    />
)

export const WestpacLogo = () => (
    <Image
        className={styles.westpacLogo}
        src={westpacLightLarge} // files in this folder are also shipped to external apps via build config
        alt="Westpac logo"
    />
)
