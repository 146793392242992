import Decimal from 'decimal.js'
import {DateTime} from 'luxon'
import {BuyOrder, BuySellOrder} from '~/store/accounting/types'

export const amountFilled = (order: BuyOrder): number => {
    if (order.remaining_currency) {
        return parseFloat(order.created_hold_amount) - parseFloat(order.remaining_currency)
    } else {
        return sumTradeGrossValues(order)
    }
}

export const defaultExpiryDate = (order: BuySellOrder): DateTime => {
    return order.created.plus({days: 30})
}

export const hasTrades = (order: BuySellOrder): boolean => {
    return order.trades.length > 0
}

export const isAutoInvestOrder = (order: BuySellOrder): boolean => {
    if (order.type === 'sell') {
        return false
    }

    return order.autoinvest
}

export const isAutoExerciseOrder = (order: BuySellOrder): boolean => {
    return order.type === 'buy' && order.is_auto_exercise === true
}

export const isPartiallyFilled = (order: BuySellOrder): boolean => {
    return hasTrades(order) && order.state !== 'fulfilled'
}

export const sortTradesByDate = (order: BuySellOrder) => {
    return order.trades.sort((a, b) => a.trade_datetime.toSeconds() - b.trade_datetime.toSeconds())
}

export const sumTradeGrossValues = (order: BuySellOrder): number => {
    // Note that `gross_value` is inclusive of the corporate fee
    return order.trades.reduce((acc, trade) => {
        return acc + parseFloat(trade.gross_value)
    }, 0)
}

export const sumTradeNetValues = (order: BuySellOrder): number => {
    return order.trades.reduce((acc, trade) => {
        return acc + (parseFloat(trade.gross_value) - parseFloat(trade.corporate_fee))
    }, 0)
}

export const sumTradeSharePrices = (order: BuySellOrder): number => {
    return order.trades.reduce((acc, trade) => {
        return acc + parseFloat(trade.share_price)
    }, 0)
}

export const sumTradeShareVolumes = (order: BuySellOrder): number => {
    return order.trades.reduce((acc, trade) => {
        return acc + parseFloat(trade.volume)
    }, 0)
}

export const sumTradePriceTimesVolume = (order: BuySellOrder): number => {
    return order.trades.reduce((acc, trade) => {
        return acc + parseFloat(trade.volume) * parseFloat(trade.share_price)
    }, 0)
}

export const totalTransactionFee = (order: BuySellOrder): string => {
    if (order.order_provider_fee) {
        // Managed fund
        return order.order_provider_fee
    }

    if (order.type === 'buy' && order.order_brokerage) {
        // Autoinvest
        return order.order_brokerage
    }

    return new Decimal(order.total_transaction_fee).toDecimalPlaces(5, Decimal.ROUND_UP).toString()
}

export const weightedAverageSharePrice = (order: BuySellOrder): number => {
    // 1. Find the sum of (trade price x no. shares)
    const sumTotalOrder = sumTradePriceTimesVolume(order)
    // 2. Find the sum of all the trade shares
    const sumTotalShares = sumTradeShareVolumes(order)
    return sumTotalOrder / sumTotalShares
}
