import cn from 'classnames'
import React from 'react'
import {FocusOn} from 'react-focus-on'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {useTelescope} from '~/sections/invest/sections/ai-search/hooks/useTelescope'
import AiSearchInput from '~/sections/invest/sections/ai-search/widgets/ai-search-input/AiSearchInput'
import AiSuggestions from '~/sections/invest/sections/ai-search/widgets/ai-suggestions/AiSuggestions'
import styles from './AiSearchHeader.scss'

const Suggestions: React.FC<{onSelect: (prompt: string) => void}> = ({onSelect}) => {
    return (
        <div className={cn(styles.pageSideMargin, spacing.spaceBelow8)}>
            <p className={cn(typographyOverrides['as-small-text'], spacing.spaceAbove24, spacing.spaceBelow4)}>
                You could try:
            </p>
            <AiSuggestions onChipClick={onSelect} />
        </div>
    )
}

export const AiSearchHeader: React.FunctionComponent = () => {
    const {loadingState, sendPrompt, lastPrompt} = useTelescope()
    const [prompt, setPrompt] = React.useState<string>(lastPrompt ?? '')
    const [hasFocus, setHasFocus] = React.useState(loadingState === 'idle' && !lastPrompt)
    const [scrollAtTop, setScrollAtTop] = React.useState(true)

    // Searchbar focus handling
    const parentRef = React.createRef<HTMLDivElement>()
    const handleBlur = (event: string | React.FocusEvent<unknown, Element>) => {
        // careful with onBlur - we don't want to hide our chips
        // if the click was on a chip!
        if (
            typeof event === 'string' ||
            event.relatedTarget === null ||
            (parentRef && !parentRef.current?.contains(event.relatedTarget))
        ) {
            setHasFocus(false)
        }
    }

    React.useEffect(() => {
        // Update the search input if a new prompt was queried (maybe from the alterantive suggestions error page)
        if (lastPrompt) {
            setPrompt(lastPrompt)
        }
    }, [lastPrompt])

    // Overlay styles handling
    React.useEffect(() => {
        const scrollTopOffset = 112 // height of the toolbar, the sticky search will kick in after 112px
        const handleScroll = () => {
            if (scrollAtTop !== window.scrollY < scrollTopOffset) {
                setScrollAtTop(window.scrollY < scrollTopOffset)
            }
        }
        window.addEventListener('scroll', handleScroll, {passive: true})
        return () => window.removeEventListener('scroll', handleScroll)
    }, [scrollAtTop])

    const headerBody = ({autoFocus}: {autoFocus: boolean}) => (
        <div className={cn(styles.pageSideMargin, spacing.spaceAbove8, spacing.spaceBelow8)}>
            <AiSearchInput
                name=""
                isTouched={true}
                queryValue={prompt}
                setQueryValue={newPrompt => setPrompt(newPrompt)}
                executeQuery={() => prompt && sendPrompt(prompt)}
                lastExecutedQuery={lastPrompt}
                onBlur={handleBlur}
                onFocus={() => setHasFocus(true)}
                autoFocus={autoFocus}
                withBorder
            />
        </div>
    )

    const showOverlay = hasFocus || !lastPrompt

    return (
        <div
            className={cn(styles.aiHeader, !scrollAtTop && styles.aiHeaderScrolled)}
            ref={parentRef}
            onFocus={() => setHasFocus(true)}
            onBlur={handleBlur}
        >
            {!showOverlay ? (
                headerBody({autoFocus: false})
            ) : (
                <SearchModal>
                    <>
                        {headerBody({autoFocus: true})}
                        <Suggestions
                            onSelect={term => {
                                sendPrompt(term)
                                setPrompt(term)
                                setHasFocus(false)
                            }}
                        />
                    </>
                </SearchModal>
            )}
        </div>
    )
}

const SearchModal: React.FC<{children: React.ReactNode}> = ({children}) => {
    return (
        <div className={styles.overlay}>
            <FocusOn scrollLock={false}>
                <div
                    aria-modal={true}
                    role="dialog"
                    className={cn(styles.rawModal)}
                    onClick={event => {
                        // if a user clicks inside the modal, keep modal open
                        event.stopPropagation()
                    }}
                    tabIndex={-1}
                >
                    {children}
                </div>
            </FocusOn>
        </div>
    )
}
