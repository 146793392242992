import * as api from '~/api/retail'
import {Model, Response} from '~/api/retail/types'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {reauthenticateReturn} from '~/global/wrappers/global-wrapper-widgets/reauthenticate/Reauthenticate'
import {generateNavigationDirective, NavigationDirective} from '~/migrate-react-router'
import {actingAsID} from '../identity/selectors'
import instrumentActions from '../instrument/actions'
import {createAction, ActionsUnion} from '../redux-tools'
import {ThunkAction} from '../types'
import {EnhancedAutoinvestOrder} from './selectors'
import {AutoinvestPremadeOrder, PartialDIYOrderDetails, State} from './types'

const actions = {
    SetLoadingState: (loadingState: State['autoinvestLoadingState']) =>
        createAction('autoinvest.SetLoadingState', loadingState),
    RemoveAutoinvestOrder: (orderId: string) => createAction('autoinvest.RemoveAutoinvestOrder', orderId),
    SetAutoinvestOrder: (order: Model.AutoinvestOrder) => createAction('autoinvest.SetAutoinvestOrder', order),
    ClearSelectedDiyOrderId: () => createAction('autoinvest.ClearSelectedDiyOrderId'),
    ClearStagedOrder: () => createAction('autoinvest.ClearStagedOrder'),
    ClearStagedOrderAllocationPercentages: () => createAction('autoinvest.ClearStagedOrderAllocationPercentages'),
    ClearState: () => createAction('autoinvest.ClearState'),
    RemoveDIYFund: (id: string) => createAction('autoinvest.RemoveDIYFund', id),
    ResetDIYFunds: () => createAction('autoinvest.ResetDIYFunds'),
    SetAutoInvestOptions: (premadeOrders: AutoinvestPremadeOrder[], diyAuOrders: string[]) =>
        createAction('autoinvest.SetAutoInvestOptions', {premadeOrders, diyAuOrders}),
    SetSelectedDiyOrderId: (id: string) => createAction('autoinvest.SetSelectedDiyOrderId', id),
    SetDIYAllocations: (allocations: PartialDIYOrderDetails['allocations']) =>
        createAction('autoinvest.SetDIYAllocations', allocations),
    SetDIYFund: (id: string, allocation: string) => createAction('autoinvest.SetDIYFund', {id, allocation}),
    SetHowMuch: (amount: string, interval: Model.AutoinvestOrder['interval'], startDate: string) =>
        createAction('autoinvest.SetHowMuch', {amount, interval, startDate}),
    SetLoadingPremadeOrders: () => createAction('autoinvest.SetLoadingPremadeOrders'),
    SetUninitialisedPremadeOrders: () => createAction('autoinvest.SetUninitialisedPremadeOrders'),
    SetNeedReadPDSes: (pdses: Response.AutoinvestOrderNeedReadPDS['pdses']) =>
        createAction('autoinvest.SetNeedReadPDSes', {pdses}),
    SetOrderCost: (transaction_fee: string, amount_to_invest: string) =>
        createAction('autoinvest.SetOrderCost', {transaction_fee, amount_to_invest}),
    SetOrderImage: (imageId?: string) => createAction('autoinvest.SetOrderImage', {imageId}),
    SetOrderName: (orderName?: string) => createAction('autoinvest.SetOrderName', {orderName}),
    SetStagedState: (state: 'submitting' | 'staged' | 'completed') => createAction('autoinvest.SetStagedState', state),
    StageExistingOrder: (order: EnhancedAutoinvestOrder) => createAction('autoinvest.StageExistingOrder', order),
    StageNewDIY: () => createAction('autoinvest.StageNewDIY'),
    StageNewPremade: (premadeOrder: AutoinvestPremadeOrder, existingPremadeOrderId?: string) =>
        createAction('autoinvest.StageNewPremade', {premadeOrder, existingPremadeOrderId}),
    UpdatePDSesToRead: (pdsFundRevisionId: string) => createAction('autoinvest.UpdatePDSesToRead', {pdsFundRevisionId}),
}

export type AutoinvestProcessNextStep =
    | {navigationDirective: NavigationDirective; notification?: 'new' | 'edit'}
    | {error: string}

const thunkActions = {
    Initialise(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const {autoinvest} = getState()
            const acting_as_id = actingAsID(getState())
            if (autoinvest.premadeOrdersState === 'uninitialised') {
                dispatch(actions.SetLoadingPremadeOrders())
                const response = await api.get('autoinvest/premade-orders-v2', {
                    acting_as_id,
                })
                switch (response.type) {
                    case 'autoinvest_premade_order_list':
                        dispatch(actions.SetAutoInvestOptions(response.orders, response.diy_au_orders))
                        break
                    case 'internal_server_error':
                    default:
                        dispatch(actions.SetUninitialisedPremadeOrders())
                        break
                }
            }
        }
    },
    FetchCurrentOrders(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())
            dispatch(actions.SetLoadingState('loading'))

            const response = await api.get('autoinvest/current-orders', {acting_as_id})
            switch (response.type) {
                case 'autoinvest_orders':
                    dispatch(actions.SetLoadingState('ready'))
                    Object.values(response.orders).forEach(order => dispatch(actions.SetAutoinvestOrder(order)))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    CostStagedOrder(): ThunkAction<Promise<string | undefined>> {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())
            const {
                autoinvest: {stagedOrder},
            } = getState()

            if (!stagedOrder) {
                throw new Error("Can't process staged order because none exists")
            }
            if (stagedOrder.state !== 'staged') {
                throw new Error(`Can't process staged order from state ${stagedOrder.state}`)
            }

            try {
                const response = await ('premadeOrder' in stagedOrder.order
                    ? api.post('autoinvest/cost-order', {
                          acting_as_id,
                          premade_order_id: stagedOrder.order.premadeOrder.id,
                          amount: stagedOrder.order.amount,
                      })
                    : api.post('autoinvest/cost-order', {
                          acting_as_id,
                          amount: stagedOrder.order.amount,
                          allocations: stagedOrder.order.allocations,
                      }))
                switch (response.type) {
                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () => this.CostStagedOrder()(dispatch, getState),
                            () => 'Please resubmit your request',
                            () => 'You must enter your password before you can setup auto-invest',
                        )
                    case 'autoinvest_cost':
                        dispatch(actions.SetOrderCost(response.expected_fee, response.amount_to_invest))
                        break
                    case 'internal_server_error':
                        break
                }
            } catch (e) {
                return unknownErrorMessage
            }
        }
    },
    DeleteCurrentOrder(order_id: string, acting_as_id?: string): ThunkAction<Promise<string | undefined>> {
        return async (dispatch, getState) => {
            if (!acting_as_id) {
                acting_as_id = actingAsID(getState())
            }
            try {
                const response = await api.post('autoinvest/delete-order', {acting_as_id, order_id})

                switch (response.type) {
                    case 'authentication_update_required':
                        // Handled via API error handler
                        return reauthenticateReturn(
                            () => this.DeleteCurrentOrder(order_id, acting_as_id)(dispatch, getState),
                            () => 'Please resubmit your request',
                            () => 'You must enter your password before you can delete your auto-invest',
                        )
                    case 'empty':
                        dispatch(actions.RemoveAutoinvestOrder(order_id))
                        dispatch(actions.ClearSelectedDiyOrderId())
                        return
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(response)
                        return unknownErrorMessage
                }
            } catch (e) {
                return unknownErrorMessage
            }
        }
    },
    PauseCurrentOrder(
        setPaused: boolean,
        order_id: string,
        acting_as_id?: string,
    ): ThunkAction<Promise<string | undefined>> {
        return async (dispatch, getState) => {
            if (!acting_as_id) {
                acting_as_id = actingAsID(getState())
            }
            try {
                const response = await api.post(setPaused ? 'autoinvest/pause-order' : 'autoinvest/unpause-order', {
                    acting_as_id,
                    order_id,
                })

                switch (response.type) {
                    case 'authentication_update_required':
                        // Handled via API error handler
                        return reauthenticateReturn(
                            () => this.PauseCurrentOrder(setPaused, order_id, acting_as_id)(dispatch, getState),
                            () => 'Please resubmit your request',
                            () => 'You must enter your password before you can set up auto-invest',
                        )
                    case 'autoinvest_order':
                        dispatch(actions.SetAutoinvestOrder(response.order))
                        return
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(response)
                        return unknownErrorMessage
                }
            } catch (e) {
                return unknownErrorMessage
            }
        }
    },
    ProcessStagedOrder(acting_as_id?: string): ThunkAction<Promise<AutoinvestProcessNextStep>> {
        return async (dispatch, getState) => {
            if (!acting_as_id) {
                acting_as_id = actingAsID(getState())
            }
            const {
                autoinvest: {stagedOrder},
            } = getState()
            if (!stagedOrder) {
                throw new Error("Can't process staged order because none exists")
            }
            if (stagedOrder.state !== 'staged') {
                throw new Error(`Can't process staged order from state ${stagedOrder.state}`)
            }
            try {
                dispatch(actions.SetStagedState('submitting'))
                const response = await ('premadeOrder' in stagedOrder.order
                    ? api.post('autoinvest/set-premade-order', {
                          acting_as_id,
                          premade_order_id: stagedOrder.order.premadeOrder.id,
                          amount: stagedOrder.order.amount,
                          interval: stagedOrder.order.interval,
                          start: stagedOrder.order.startDate,
                          order_name: stagedOrder.orderName,
                          order_image_id: stagedOrder.orderImageId,
                      })
                    : api.post('autoinvest/set-diy-order', {
                          acting_as_id,
                          amount: stagedOrder.order.amount,
                          interval: stagedOrder.order.interval,
                          start: stagedOrder.order.startDate,
                          allocations: stagedOrder.order.allocations,
                          order_name: stagedOrder.orderName,
                          order_image_id: stagedOrder.orderImageId,
                          order_id: stagedOrder.existingOrderId,
                      }))
                switch (response.type) {
                    case 'account_restricted':
                        throw new Error('Restricted accounts should not be able to place an order')
                    case 'authentication_update_required':
                        // Handled via API error handler
                        return reauthenticateReturn(
                            () => this.ProcessStagedOrder(acting_as_id)(dispatch, getState),
                            () => ({error: 'Please resubmit your request'}),
                            () => ({error: 'You must enter your password before you can setup auto-invest'}),
                        )
                    case 'cart_need_tax_details':
                        if ('premadeOrder' in stagedOrder.order) {
                            return {
                                navigationDirective: generateNavigationDirective(
                                    'auto-invest/:premadeSlug/tax-info',
                                    {},
                                    {premadeSlug: stagedOrder.order.premadeOrder.slug},
                                ),
                            }
                        } else {
                            return {navigationDirective: generateNavigationDirective('auto-invest/diy/tax-info')}
                        }
                    case 'autoinvest_need_read_fund_pds':
                        dispatch(actions.SetNeedReadPDSes(response.pdses))
                        return {navigationDirective: generateNavigationDirective('auto-invest/pds')}
                    case 'autoinvest_order':
                        dispatch(actions.SetAutoinvestOrder(response.order))
                        dispatch(actions.SetStagedState('completed'))
                        if (stagedOrder.existingOrderId) {
                            dispatch(actions.RemoveAutoinvestOrder(stagedOrder.existingOrderId))
                        }
                        dispatch(actions.ClearStagedOrder())
                        dispatch(actions.SetSelectedDiyOrderId(response.order.id))

                        const notificationType = stagedOrder.isEdit ? 'edit' : 'new'

                        if ('premadeOrder' in stagedOrder.order) {
                            const slug = stagedOrder.order.premadeOrder.slug

                            const ids = stagedOrder.order.premadeOrder.allocations.map(a => a.fund_id)

                            // set the premades index
                            dispatch(instrumentActions.loadPremadeAIInstruments(ids, slug))

                            return {
                                notification: notificationType,
                                navigationDirective: generateNavigationDirective(
                                    'auto-invest/:premadeSlug',
                                    {},
                                    {premadeSlug: slug},
                                ),
                            }
                        } else {
                            const ids = stagedOrder.order.allocations.map(a => a.fund_id)

                            // set the diyAIIndex
                            dispatch(instrumentActions.loadDiyAIInstruments(ids))

                            return {
                                notification: notificationType,
                                navigationDirective: generateNavigationDirective('auto-invest/diy'),
                            }
                        }

                    case 'error':
                        return {error: response.message}
                    case 'internal_server_error':
                        // TODO: handle this properly
                        return {error: unknownErrorMessage}
                    default:
                        assertNever(response)
                        return {error: unknownErrorMessage}
                }
            } finally {
                const stagedOrder = getState().autoinvest.stagedOrder
                // Ensure that the stagedOrder is returned to the "staged" state
                // in any case where we haven't explicitly set a new state (or
                // cleared the order).
                if (stagedOrder && stagedOrder.state === 'submitting') {
                    dispatch(actions.SetStagedState('staged'))
                }
            }
        }
    },
    SubmitPDSAgreement(pdsFundRevisionId: string, fundIds: string[]): ThunkAction<Promise<string | null>> {
        return async (dispatch, getState) => {
            try {
                const response = await api.post('autoinvest/mark-pds-read', {
                    fund_ids: fundIds,
                    acting_as_id: actingAsID(getState()),
                    pds_file_revision_id: pdsFundRevisionId,
                })

                switch (response.type) {
                    case 'empty':
                        dispatch(actions.UpdatePDSesToRead(pdsFundRevisionId))
                        break
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(response)
                }
            } catch (e) {
                return unknownErrorMessage
            }

            return null
        }
    },
}

export type ActionsType = ActionsUnion<typeof actions>

export default {...actions, ...thunkActions}
