import {ModalLink} from '@design-system/modal'
import React from 'react'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'

interface FrankedDividendProps {
    isFranked?: boolean
}

export const FrankedDividend: React.FunctionComponent<FrankedDividendProps> = ({isFranked}) => {
    return (
        <ModalLink
            dataTestId="modal-link--franked-dividend"
            label={`${isFranked ? 'Franked' : 'Unfranked'} amount`}
            asIcon
            modalTitle={`${isFranked ? 'Franked' : 'Unfranked'} amount`}
            primaryButton={{label: 'Ok'}}
            helpIconSize={16}
        >
            {isFranked ? (
                <React.Fragment>
                    <p>This portion of the dividend carries a franking credit.</p>
                    <p>
                        If you’re an Australian resident, franking credits may entitle you to a franking tax offset. The
                        franking tax offset will cover (or partly cover) the tax you’d usually have to pay on the amount
                        you’ve received.
                    </p>
                </React.Fragment>
            ) : (
                <p>
                    This portion of the dividend doesn’t carry a franking credit. This means you’ll have tax to pay on
                    the unfranked amount you’ve received.
                </p>
            )}

            <p>
                For more info, check out our <HelpCentreLink auArticle="4983208-tax" />.
            </p>
        </ModalLink>
    )
}
