import React from 'react'
import DetailModal from '~/global/widgets/detail-modals/DetailModal'
import KiwiSaverStructureModal from '~/sections/kiwisaver/sections/view-fund/widgets/structure-modal/KiwiSaverStructureModal'
import styles from './FundDisclosure.scss'

const FundDisclosure: React.FunctionComponent = () => {
    const [modalOpen, setModalOpen] = React.useState(false)
    return (
        <div>
            <p className={styles.text}>
                To offer this in the Sharesies KiwiSaver Scheme we’ve set up a fund that invests 100% of the money it
                holds in the investment below.{' '}
                <span
                    className={styles.link}
                    onClick={() => {
                        setModalOpen(true)
                    }}
                >
                    Find out more.
                </span>
            </p>
            <DetailModal
                isOpen={modalOpen}
                closeHandler={() => {
                    setModalOpen(false)
                }}
            >
                <KiwiSaverStructureModal />
            </DetailModal>
        </div>
    )
}

export default FundDisclosure
