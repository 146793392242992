import {useColourMode} from '@design-system/use-colour-mode/useColourMode'
import React from 'react'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {getImageForAutoInvestOrder} from '~/sections/invest/sections/auto-invest/utils/map-auto-invest-images/mapAutoInvestImages'
import {isDIY} from '~/store/autoinvest/types'
import {useAppSelector} from '~/store/hooks'
import PersonaliseProduct from './PersonaliseProduct'

export type PersonaliseProductContext = 'autoinvest' | 'save' | 'topup'

// presetName and presetImageId are used to populate fields the investor
// previously set so they are displayed when editing.  They need to be set in
// this way as formik's mapPropsToValues function is only called on the first
// render

export const PersonaliseAutoinvest = () => {
    const profileUrl = useProfileUrl()
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const colourMode = useColourMode()
    const orderType = useAppSelector(s =>
        !s.autoinvest.stagedOrder
            ? ''
            : !isDIY(s.autoinvest.stagedOrder.order)
              ? s.autoinvest.stagedOrder!.order.premadeOrder.name
              : 'DIY',
    )

    // 'DIY' has per-jurisdiction default
    const defaultName = orderType !== 'DIY' ? orderType : {au: 'My auto-invest', nz: 'DIY order'}[jurisdiction]

    const defaultImage = getImageForAutoInvestOrder(orderType, colourMode).thumbnailImage

    return (
        <PersonaliseProduct
            context="autoinvest"
            defaultName={defaultName}
            defaultImage={defaultImage}
            defaultSuccessUrl={profileUrl('auto-invest/review')}
            presetName={useAppSelector(s => s.autoinvest.stagedOrder?.orderName)}
            presetImageId={useAppSelector(s => s.autoinvest.stagedOrder?.orderImageId)}
        />
    )
}
