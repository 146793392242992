import {Button} from '@design-system/button'
import {useSetAtom} from 'jotai'
import React from 'react'
import {useNavigate} from 'react-router'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import RecurringTransfers from '~/sections/save/sections/activity/widgets/recurring-transfers/RecurringTransfers'
import {stagedTransferAtom, useRecurringTransfers} from '~/sections/save/state/transferState'

interface RecurringTransfersViewProps {
    portfolioId: string
}

const RecurringTransfersView: React.FunctionComponent<RecurringTransfersViewProps> = ({portfolioId}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const recurringTransfers = useRecurringTransfers(portfolioId)
    const setStagedTransfer = useSetAtom(stagedTransferAtom)

    React.useEffect(() => {
        setStagedTransfer(undefined)
    }, [])

    return (
        <>
            <Toolbar dataTestId="toolbar--recurring-transfers" title="Your recurring transfers" leftButton="back" />
            <Page>
                <RecurringTransfers portfolioId={portfolioId} />
            </Page>
            {recurringTransfers.length > 0 && (
                <ActionBar>
                    <Button
                        dataTestId="button--create-recurring-transfer"
                        label="Create a new recurring transfer"
                        onClick={() => navigate(profileUrl('save/recurring-transfer/:portfolioId', {portfolioId}))}
                    />
                </ActionBar>
            )}
        </>
    )
}

export default RecurringTransfersView
