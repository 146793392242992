import {Thumbnail} from '@design-system/thumbnail'
import React from 'react'
import {DistillScope} from '~/api/query/distill'
import useDistillInstrumentInfo from '~/global/state-hooks/distill/useDistillInstrumentInfo'
import {BasicInstrument} from '~/store/instrument/types'
import styles from './StackedImage.scss'

interface StackedImageProps {
    instrument: BasicInstrument
    size?: number
}

export const StackedImage: React.FunctionComponent<StackedImageProps> = ({instrument, size}) => {
    const resourcePath = useDistillInstrumentInfo(DistillScope.KIWISAVER_ALL_FUNDS, {
        searchFundInvestments: true,
    }).filesHostAddress

    return (
        <div className={styles.stackedImage} style={{width: `${size ?? '44'}px`, height: `${size ?? '44'}px`}}>
            <Thumbnail
                dataTestId={`${instrument.urlSlug}--logo`}
                width={`${size ?? '44'}px`}
                height={`${size ?? '44'}px`}
                symbol={instrument.symbol}
                path={instrument.logos.micro ? `${resourcePath}${instrument.logos.micro}` : undefined}
            />
        </div>
    )
}
