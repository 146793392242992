import cn from 'classnames'
import React from 'react'
import {accessibility} from '~/global/scss/helpers'
import {convertToKebabCase} from '~/global/utils/convert-to-kebab-case/convertToKebabCase'
import styles from './TabControls.scss'

interface TabControlsProps<Value extends string> {
    tabs: {label: string; value: Value}[]
    currentTab: Value
    onChangeTab(tab: React.SetStateAction<Value>): void
}

const TabControls = <Value extends string>({tabs, currentTab, onChangeTab}: TabControlsProps<Value>) => {
    return (
        <div className={styles.tabs} role="tablist">
            {tabs.map(tab => (
                <button
                    key={tab.value}
                    type="button"
                    data-testid={`button--${convertToKebabCase(tab.value.toLowerCase())}`}
                    role="tab"
                    aria-controls={tab.label}
                    aria-selected={tab.value === currentTab}
                    className={cn({[styles.active]: tab.value === currentTab}, accessibility.button)}
                    onClick={() => {
                        onChangeTab(tab.value)
                    }}
                >
                    {tab.label}
                </button>
            ))}
        </div>
    )
}

export default TabControls
