import React from 'react'
import {Link} from 'react-router-dom'
import {Period} from '~/global/utils/time-period/timePeriod'
import {ChevronLeft, ChevronRight} from '~/global/widgets/OLD_icons'
import {InstrumentDetailsTab} from '~/store/instrument/types'
import styles from './Navigation.scss'

export interface NavigationRouterState {
    tab: InstrumentDetailsTab
    period?: Period
}

const Navigation = ({
    prevLink,
    nextLink,
    tab,
    period,
    nextPageLoading,
}: {
    prevLink?: string
    nextLink?: string
    tab: InstrumentDetailsTab
    period?: Period
    nextPageLoading: boolean
}) => {
    const state: NavigationRouterState = {tab, period}
    return (
        <div className={styles.navigation}>
            {prevLink && (
                <Link to={prevLink} className={styles.prev} replace={true} state={state} relative="path">
                    <ChevronLeft />
                </Link>
            )}
            {nextLink && !nextPageLoading && (
                <Link to={nextLink} className={styles.next} replace={true} state={state} relative="path">
                    <ChevronRight />
                </Link>
            )}
        </div>
    )
}

export default Navigation
