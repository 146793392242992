import Decimal from 'decimal.js'
import {useFastWithdrawalFee} from '~/sections/wallet/sections/withdrawals/state/useFastWithdrawalFee'

export const useFormattedFastFee = () => {
    const fee = useFastWithdrawalFee()

    if (!fee) {
        return 'unknown'
    }
    return `${new Decimal(fee).times(100).toDP(1).toString()}%`
}
