import {Button} from '@design-system/button'
import {SuccessFilled} from '@design-system/icon'
import {DateTime} from 'luxon'
import React from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {spacing} from '~/global/scss/helpers'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import useDividendReinvestmentPlan from '~/global/utils/use-dividend-reinvestment-plan/useDividendReinvestmentPlan'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'
import {getInstrumentFromSlug} from '~/store/instrument/selectors'
import {Instrument} from '~/store/instrument/types'
import styles from './DividendReinvestmentManage.scss'

const DividendReinvestmentManageWithInstrument: React.FunctionComponent<{instrument: Instrument}> = ({instrument}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const [dividendReinvestmentPlan, setDrpEnrolment] = useDividendReinvestmentPlan(instrument)
    const [buttonLoading, setButtonLoading] = React.useState(false)

    const instrumentPage = profileUrl('invest/:instrumentSlug', {instrumentSlug: instrument.urlSlug})

    if (!dividendReinvestmentPlan || !dividendReinvestmentPlan.is_enrolled) {
        return <Navigate to={instrumentPage} />
    }

    const {offer_document_url: offerDocumentUrl, upcoming_exdividend_date: upcomingExDate} = dividendReinvestmentPlan

    const onClick = async () => {
        setButtonLoading(true)

        try {
            await setDrpEnrolment(false)
            navigate(-1)
            Toast(`You’ve opted out of ${instrument.name}’s dividend reinvestment plan`)
        } catch (e) {
            Toast('Something went wrong, please try again.')
        }
        setButtonLoading(false)
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--drp-manage" leftButton="back" />
            <div className={styles.upcomingDividend}>
                <div>
                    {upcomingExDate
                        ? `Upcoming dividend: ${DateTime.fromISO(upcomingExDate).toFormat(dateFormatNoTime)}`
                        : 'Upcoming dividend: None'}
                </div>

                <div className={styles.status}>
                    On
                    <div className={styles.successIcon}>
                        <SuccessFilled size={16} />
                    </div>
                </div>
            </div>
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <h1 className={styles.header}>{instrument.name}’s dividend reinvestment plan</h1>

                <p className={spacing.spaceAbove24}>
                    You’ve chosen to opt in to {instrument.name}’s dividend reinvestment plan.
                </p>
                <p className={spacing.spaceAbove24}>
                    Now whenever {instrument.name} pays a dividend, you’ll receive more of its shares or part of a share
                    instead of a cash payment.
                </p>
                <p className={spacing.spaceAbove24}>
                    You can opt out whenever you like. If you opt out before an ex-dividend date, that dividend (and any
                    following) will be paid in cash. If you opt out after the ex-dividend date, you’ll receive shares or
                    part of a share for the upcoming dividend, but any following dividends will be paid in cash.
                </p>

                <p className={spacing.spaceAbove24}>
                    For more info, check out {instrument.name}’s{' '}
                    <a href={offerDocumentUrl} rel="noopener" target="_blank">
                        offer document
                    </a>
                    .
                </p>
                <div className={spacing.spaceAbove40}>
                    <Button
                        processing={buttonLoading}
                        label="Opt out of plan"
                        type="secondary"
                        dataTestId="button--opt-out-drp"
                        onClick={onClick}
                    />
                </div>
            </Page>
        </>
    )
}

interface DividendReinvestmentManageProps {
    instrumentSlug: string
}

const DividendReinvestmentManage: React.FunctionComponent<DividendReinvestmentManageProps> = ({instrumentSlug}) => {
    const dispatch = useAppDispatch()
    const instrument = useAppSelector(s => getInstrumentFromSlug(s, instrumentSlug))
    const resultsLoadingState = useAppSelector(s => s.instrument.resultsLoadingState)

    React.useEffect(() => {
        if (!instrument) {
            dispatch(instrumentActions.getSingleInstrumentBySlug(instrumentSlug))
        }
    }, [instrument])

    if (!instrument) {
        if (resultsLoadingState === 'loading') {
            return <Loading isPineapple />
        }

        return <WeSlippedUp />
    }

    return <DividendReinvestmentManageWithInstrument instrument={instrument} />
}

export default DividendReinvestmentManage
