import {useRetailGet} from '~/api/query/retail'
import {useActor} from '~/global/state-hooks/retail/useActor'
import {useWalletPortfolio} from '~/sections/wallet/state/local'

export const useFastWithdrawalFee = () => {
    const {id: walletPortfolioId} = useWalletPortfolio()
    const actor = useActor()
    const currency = actor?.default_display_currency

    const result = useRetailGet({
        path: 'withdrawals/:portfolio_id/fee-rates',
        pathParams: {
            portfolio_id: walletPortfolioId,
        },
    })

    return result.data.rates.find(f => f.currency === currency)?.fee_rate
}
