import {DateTime} from 'luxon'
import React from 'react'
import {ListingResponseDto} from '~/api/distill'
import {spacing} from '~/global/scss/helpers'
import {Period, periods, periodInformation} from '~/global/utils/time-period/timePeriod'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import Chips from '~/global/widgets/chips/Chips'
import InstrumentPerformanceChart from '~/sections/invest/sections/view-instrument/widgets/charts/InstrumentPerformanceChart'
import {InstrumentHistoryItem} from '~/store/accounting/selectors'
import {InstrumentPrice} from '~/store/instrument/selectors'
import {Exchange, PriceHistoryResponse} from '~/store/instrument/types'
import styles from './PriceHistory.scss'

interface PriceHistoryProps {
    instrument: ListingResponseDto
    exchange?: Exchange
    priceHistory: PriceHistoryResponse
    priceAlertMessage?: string
    setPeriod: (period: Period) => void
    period: Period
}

interface DateListItem {
    date: string
    isWeekend: boolean
}

function firstDate<T extends {date: string}>(list?: T[]): string | undefined {
    if (!list || list.length === 0) {
        return
    }
    return list[0].date
}

// Slimmed down version of src/store/accounting/selectors.ts

const instrumentMarketPrice = (instrument: ListingResponseDto): InstrumentPrice => {
    const marketLastCheck = instrument.marketLastCheck
    const marketPrice = instrument.marketPrice

    return {
        date: marketLastCheck.toISODate(),
        price: marketPrice ? parseFloat(marketPrice) : 0,
    }
}

const instrumentPriceHistory = (prices: PriceHistoryResponse, instrument: ListingResponseDto): InstrumentPrice[] => {
    const fundPrices: InstrumentPrice[] = []
    const dayPrices = prices.dayPrices
    const marketPrice = instrumentMarketPrice(instrument)
    if (!dayPrices) {
        return fundPrices
    }
    const dates = Object.keys(dayPrices)
    dates.sort()
    for (const date of dates) {
        fundPrices.push({
            date,
            price: parseFloat(dayPrices[date]),
        })
    }

    if (marketPrice && fundPrices[fundPrices.length - 1] && marketPrice.date > fundPrices[fundPrices.length - 1].date) {
        fundPrices.push(marketPrice)
    }
    return fundPrices
}

const instrumentDates = (prices: PriceHistoryResponse, instrument: ListingResponseDto): DateListItem[] => {
    const currentDate = DateTime.local().toISODate()
    const dates = [firstDate(instrumentPriceHistory(prices, instrument))]
    const validDates = [currentDate].concat(dates.filter(x => x) as string[])
    validDates.sort()
    const dateList: DateListItem[] = []
    let date = DateTime.fromISO(validDates[0])
    let dateString: string
    do {
        dateString = date.toISODate()

        dateList.push({
            date: dateString,
            isWeekend: date.weekday === 6 || date.weekday === 7,
        })
        date = date.plus({days: 1})
    } while (dateString < currentDate)

    return dateList
}

const buildInstrumentHistory = (dates: DateListItem[], prices: InstrumentPrice[]): InstrumentHistoryItem[] => {
    let priceIndex = 0

    return dates.map<InstrumentHistoryItem>(({date, isWeekend}) => {
        while (prices[priceIndex] && prices[priceIndex].date <= date) {
            priceIndex++
        }

        const price = prices[priceIndex - 1] || {date, price: undefined}

        return {
            date,
            hasOrderEvent: false,
            isWeekend,
            price: price.price,
            priceIsEstimate: date !== price.date,
        }
    })
}

const PriceHistory: React.FunctionComponent<PriceHistoryProps> = React.memo(
    ({instrument, exchange, priceHistory, priceAlertMessage, period, setPeriod}) => {
        const startDateString = DateTime.local().minus(periodInformation[period].duration).toISODate()
        // We don't need the "All" period option in the price chips (yet).
        const pricePeriods = periods.slice(0, 6)

        return (
            <div>
                {priceHistory && (
                    <div className={spacing.spaceBelow40}>
                        {priceAlertMessage && (
                            <AlertCard className={spacing.spaceBelow24} type="info">
                                {priceAlertMessage}
                            </AlertCard>
                        )}
                        <div className={styles.periodControlContainer}>
                            <Chips
                                options={pricePeriods}
                                onChipClick={periodOptions => {
                                    setPeriod(periodOptions)
                                }}
                                selected={[period]}
                                additionalAriaLabel="Show price history over"
                                isInlineDisplay
                            />
                            <InstrumentPerformanceChart
                                startDate={startDateString}
                                exchange={exchange}
                                instrumentHistory={buildInstrumentHistory(
                                    instrumentDates(priceHistory, instrument),
                                    instrumentPriceHistory(priceHistory, instrument),
                                )}
                                period={period}
                                isListedInstrument={tradingType(instrument) === 'listed'}
                            />
                        </div>
                    </div>
                )}
            </div>
        )
    },
)

export default PriceHistory
