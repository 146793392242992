import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {useNavigate, Navigate} from 'react-router'
import {constructCoveGetUrl, coveGetUrlQueryString, useCoveGet} from '~/api/query/cove'
import {useRetailGet} from '~/api/query/retail'
import {Response} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import config from '~/configForEnv'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Toast} from '~/global/widgets/toast/Toast'
import {constructCoveEmbedUserLoginUrl} from '~/sections/protect/sections/insure/sections/car/utils/cove-embed/coveEmbed'
import {CoveEmbedPage} from '~/sections/protect/sections/insure/sections/car/widgets/cove-embed-page/CoveEmbedPage'
import {CoveIframe} from '~/sections/protect/sections/insure/sections/car/widgets/cove-iframe/CoveIframe'
import {reportNoInsureAccountToRollbar} from '~/sections/protect/sections/insure/utils/report-rollbar-error/reportRollbarError'

// Wrapper allows for local loading state and error capture
export const CoveQuoteWrapper: React.FunctionComponent<{quoteId: string}> = ({quoteId}) => {
    return (
        <CoveEmbedPage name="Cove View Quote embed page">
            <CoveQuote quoteId={quoteId} />
        </CoveEmbedPage>
    )
}

const constructCoveQuotePath = (quoteId: string, insureAccount: Response.InsureAccount, isPendingPolicy: boolean) => {
    if (isPendingPolicy) {
        return `/user/quote?qno=${quoteId}&token=${insureAccount.cove_access_token}`
    }

    return `/quote/return-to-quote/?quote_id=${quoteId}&token=${insureAccount.cove_access_token}`
}

export const CoveQuote: React.FunctionComponent<{quoteId: string}> = ({quoteId}) => {
    const profileUrl = useProfileUrl()

    const {data: insureAccount} = useRetailGet({path: 'insure/get-account'})
    const {data: isCoveCustomer} = useRetailGet({path: 'insure/get-is-cove-customer'})
    const quoteData = useCoveGet('quote/v1/queryByCustomer')
    const quote = quoteData.quotes.find(quote => quote.quote_id === quoteId)

    /** 'Pending' quotes are future-dated policies  */
    const isPendingPolicy = !!quote && quote.quote_status === 'Pending'

    if (insureAccount.type !== 'insure_account') {
        // Impossible: if they have a Cove quote then they must have an Insure account

        reportNoInsureAccountToRollbar()
        return <Navigate to={profileUrl('protect/insure')} replace />
    }

    if (!isCoveCustomer.is_cove_customer) {
        // Not an existing Cove customer: use regular quote URL in the iframe
        const quotePath = constructCoveQuotePath(quoteId, insureAccount, isPendingPolicy)
        const embedUrl = new URL(`${config.coveEmbedBaseUrl}${quotePath}`)

        return <CoveQuoteIframe embedUrl={embedUrl} insureAccount={insureAccount} />
    }

    // They're a Cove customer: we need to use the login-and-redirect URL in the iframe
    // For that we need the embed login_id, so we need to drop into another wrapper to grab that via a hook
    return <CoveUserQuote insureAccount={insureAccount} isPendingPolicy={isPendingPolicy} quoteId={quoteId} />
}

const CoveUserQuote: React.FunctionComponent<{
    insureAccount: Response.InsureAccount
    quoteId: string
    isPendingPolicy: boolean
}> = ({insureAccount, isPendingPolicy, quoteId}) => {
    const {data: coveEmbedLogin} = useRetailGet({
        path: 'insure/get-cove-user-embed-login-id',
    })

    const quotePath = constructCoveQuotePath(quoteId, insureAccount, isPendingPolicy)
    const embedUrl = constructCoveEmbedUserLoginUrl(coveEmbedLogin.login_id, quotePath)

    return <CoveQuoteIframe embedUrl={embedUrl} insureAccount={insureAccount} />
}

const CoveQuoteIframe: React.FunctionComponent<{embedUrl: URL; insureAccount: Response.InsureAccount}> = ({
    embedUrl,
    insureAccount,
}) => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    React.useEffect(() => {
        // Add an event listener that will receive a success message from the iframe
        const handleCoveMsg = (event: {origin: string; type: string; data: {paymentStatus: string}}) => {
            if (event.origin !== config.coveEmbedBaseUrl) {
                // Security check - we don't want to interact with other external sites here
                return
            }

            if (event.data.paymentStatus === 'success') {
                rudderTrack('insurance_signup', 'payment_completed')
                navigate(profileUrl('protect/insure'), {replace: true})
                Toast('Ka rawe! Your policy has been created')
            }
        }
        window.addEventListener('message', handleCoveMsg, false)

        return () => {
            // In the embed, you can proceed through the customise quote & buy policy flow, which converts the quote to a policy.
            // Therefore, when navigating away from this embed page, we want to pick up these potential changes
            // Reset the policy, quote, and count query caches to ensure that they'll be refetched next time we call the hooks
            const getCovePoliciesUrl = constructCoveGetUrl('coverage/v1/query', insureAccount.cove_customer_id)
            const getCoveQuotesUrl = constructCoveGetUrl('quote/v1/queryByCustomer', insureAccount.cove_customer_id)
            const getCoveCountsUrl = constructCoveGetUrl('customer/v1/counts', insureAccount.cove_customer_id)
            queryClient.resetQueries({queryKey: [coveGetUrlQueryString(getCovePoliciesUrl)]})
            queryClient.resetQueries({queryKey: [coveGetUrlQueryString(getCoveQuotesUrl)]})
            queryClient.resetQueries({queryKey: [coveGetUrlQueryString(getCoveCountsUrl)]})
        }
    }, [])

    /**
     * NB: this embed is relatively slow to load, and passes through several screens:
     * 0. (if applicable) Login screen will flash briefly
     * 1. Fetching your customer information
     * 2. Retrieving your quote
     * 3. Redirecting you to the quote summary screen
     * 4. (Quote page) Great. Let's personalise your comprehensive policy
     *
     * The Cove URL for the quote summary screen page is /quote/vehicle-summary/, with no quote-specific param,
     * so we can't link to it directly here.
     */
    return <CoveIframe url={embedUrl} />
}
