import {Button} from '@design-system/button'
import {withFormik, FormikProps} from 'formik'
import React from 'react'
import {Model, Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {processAddressComponents} from '~/global/utils/format-address/formatAddress'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {extractCountryCodeFromName} from '~/global/widgets/form-controls/country-select/CountrySelectInput'
import {AddressValidation, ManualAddressFields} from '~/global/widgets/manual-address-fields/ManualAddressFields'
import {connect} from '~/store/connect'
import {AddressComponents} from '~/store/identity/types'
import signUpActions from '~/store/sign-up/actions'

const EditForm: React.FunctionComponent<FormProps & FormikProps<AddressComponents>> = ({
    isSubmitting,
    handleSubmit,
    jurisdiction,
    isValid,
    values,
}) => {
    return (
        <form onSubmit={handleSubmit}>
            <ManualAddressFields selectedCountry={values.country_code} jurisdiction={jurisdiction} />
            <Button
                isSubmit
                pageButton
                dataTestId="button--save-changes"
                label="Save changes"
                disabled={!isValid}
                processing={isSubmitting}
                additionalClassName={spacing.spaceBelow48}
            />
        </form>
    )
}

const Form = withFormik<FormProps, AddressComponents>({
    mapPropsToValues: ({address}) => {
        return {
            address,
            sublocality: address?.components!.sublocality || '',
            street_number: address?.components!.street_number || '',
            route: address?.components!.route || '',
            locality: address?.components!.locality || '',
            state_code: address?.components!.state_code || '',
            postal_code: address?.components!.postal_code || '',
            country_code:
                address?.components!.country_code || extractCountryCodeFromName(address?.components!.country) || '',
        }
    },
    handleSubmit: async (
        {sublocality, street_number, route, locality, state_code, postal_code, country_code},
        {setSubmitting, props: {usEditMailingAddress, isEditing}},
    ) => {
        const address = processAddressComponents({
            street_number,
            route,
            locality,
            postal_code,
            country_code,
            state_code,
            sublocality,
        })
        setSubmitting(true)
        try {
            await usEditMailingAddress(address)
            isEditing(false)
        } catch (error) {
            setSubmitting(false)
            throw error
        }
    },
    validate: values => {
        return AddressValidation(values)
    },
})(EditForm)

const EditMailingAddress: React.FunctionComponent<EditMailingAddressProps> = ({
    address,
    jurisdiction,
    usEditMailingAddress,
    isEditing,
}) => {
    return (
        <>
            <AlertCard className={spacing.spaceBelow32} type="none">
                <p>Adding your mailing address is optional and only relevant for US tax purposes.</p>
            </AlertCard>
            <div className={spacing.spaceAbove16}>
                <Form
                    address={address}
                    usEditMailingAddress={usEditMailingAddress}
                    isEditing={isEditing}
                    jurisdiction={jurisdiction}
                />
            </div>
        </>
    )
}

interface StoreProps {
    address: Response.SignUpUSStatus['us_tax_mailing_address']
    jurisdiction: Model.User['jurisdiction']
}

interface DispatchProps {
    usEditMailingAddress(address: Response.SignUpUSStatus['us_tax_mailing_address']): Promise<void | null>
}

interface OwnProps {
    isEditing: React.Dispatch<React.SetStateAction<boolean>>
}

type EditMailingAddressProps = StoreProps & DispatchProps & OwnProps

type FormProps = Pick<StoreProps, 'address'> & Pick<StoreProps, 'jurisdiction'> & DispatchProps & OwnProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    state => ({
        address: state.signUp.usStatus!.us_tax_mailing_address,
        jurisdiction: state.identity.user!.jurisdiction,
    }),
    dispatch => ({
        usEditMailingAddress: address => dispatch(signUpActions.usEditMailingAddress(address)),
    }),
)(EditMailingAddress)
