import * as api from '~/api/retail'
import {Response} from '~/api/retail/types'
import * as rollbar from '~/api/rollbar/rollbar'
import {ThunkAction} from '~/store/types'
import {createAction, ActionsUnion} from '../redux-tools'
import {State} from './types'

const actions = {
    SetLiveMarketData: ({fundId, data}: {fundId: string; data: Response.LiveDataFetch['live_data']}) =>
        createAction('market.SetLiveMarketData', {fundId, data}),
    SetLiveMarketDataLoadingState: (state: State['liveMarketDataLoadingState']) =>
        createAction('market.SetLiveMarketDataLoadingState', state),
    SetLiveDataSubscriptionDetails: (payload: Response.IdentityAuthenticated['live_data']) =>
        createAction('market.SetLiveDataSubscriptionDetails', payload),
}

const thunkActions = {
    FetchLiveMarketData(instrumentId: string, portfolioId: string): ThunkAction<Promise<void | string>> {
        return async dispatch => {
            const payload = {fund_id: instrumentId}
            const endpoint = 'live-data/:portfolio_id/fetch'

            dispatch(actions.SetLiveMarketDataLoadingState('loading'))
            const response = await api.getWithPathParams(endpoint, {portfolio_id: portfolioId}, payload)

            switch (response.type) {
                case 'live_data_fetch':
                    dispatch(actions.SetLiveMarketData({fundId: instrumentId, data: response.live_data}))
                    dispatch(actions.SetLiveMarketDataLoadingState('ready'))
                    return
                case 'error':
                    rollbar.sendError(response.code, {
                        statusText: response.message,
                        method: 'FetchLiveMarketData',
                        payload,
                        endpoint,
                    })
                    if (response.code === 'market_data_not_available') {
                        dispatch(actions.SetLiveMarketDataLoadingState('unavailable'))
                    } else if (response.code === 'not_subscribed') {
                        // update the users subscribed state to blank so they can view the
                        // subscribe splash screen again without having to reload the page
                        dispatch(
                            actions.SetLiveDataSubscriptionDetails({
                                is_active: false,
                                eligible_for_free_month: false,
                            }),
                        )
                    } else {
                        dispatch(actions.SetLiveMarketDataLoadingState('error'))
                    }

                    return
            }
        }
    },
}

export type ActionsType = ActionsUnion<typeof actions>

export default {...actions, ...thunkActions}
