import cn from 'classnames'
import React from 'react'
import {useDispatch} from 'react-redux'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import CardForm from '~/global/widgets/credit-card-handling/card-form/CardForm'
import {SwitchField} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import SavedCardForm from '~/global/widgets/saved-card-form/SavedCardForm'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import styles from '~/sections/user/sections/share-the-love/sections/gifts/Gifts.scss'
import accountingActions from '~/store/accounting/actions'
import {useAppSelector} from '~/store/hooks'
import GiftConfirmation from './GiftConfirmationForm'

export const GiftPay: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const profileUrl = useProfileUrl()
    const cardLoadingState = useAppSelector(({accounting}) => accounting.cardsLoadingState)
    const savedCard = useAppSelector(({accounting}) =>
        accounting.cardsLoadingState === 'ready' ? accounting.cards[0] : undefined,
    )
    const giftAmount = useAppSelector(({gift}) => gift.createGiftAmount)
    const {home_currency, jurisdiction} = useAppSelector(({identity}) => identity.user!)
    const [useSavedCard, setUseSavedCard] = React.useState(Boolean(savedCard))
    const [showConfirmation, setShowConfirmation] = React.useState(false)
    const [paymentMethodId, setPaymentMethodId] = React.useState<string>()

    React.useEffect(() => {
        dispatch(accountingActions.RefreshCards())
    }, [])

    React.useEffect(() => {
        setUseSavedCard(Boolean(savedCard))
    }, [cardLoadingState])

    if (!giftAmount) {
        navigate(profileUrl('gifts/purchase'))
        return null
    }

    const handleBack = () => {
        if (showConfirmation) {
            setShowConfirmation(false)
        } else {
            navigate(profileUrl('gifts/purchase'), {replace: true})
        }
    }

    if (cardLoadingState === 'loading' || !giftAmount) {
        return (
            <>
                <Toolbar dataTestId="toolbar--gift-pay" leftButton="back" onLeftButtonClick={handleBack} />
                <Page>
                    <Loading />
                </Page>
            </>
        )
    }

    const onSavedCardSubmit = () => {
        setShowConfirmation(true)
    }

    const onCardFormSubmit = async (payment_method_id: string) => {
        setPaymentMethodId(payment_method_id)
        setShowConfirmation(true)
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--gift-pay" leftButton="back" onLeftButtonClick={handleBack} />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                {!showConfirmation && (
                    <div>
                        <h1 className={cn(styles.title, spacing.spaceBelow24)}>What are your card details?</h1>
                        {savedCard && (
                            <div className={spacing.spaceBelow32}>
                                <SwitchField
                                    additionalClassName={spacing.spaceBelow12}
                                    dataTestId="switch--use-saved-card"
                                    name="Use saved card"
                                    label="Use saved card"
                                    isTouched
                                    value={useSavedCard}
                                    onChange={() => setUseSavedCard(!useSavedCard)}
                                />
                                {useSavedCard && (
                                    <SavedCardForm
                                        submitLabel="Review Gift order"
                                        card={savedCard}
                                        onSubmit={onSavedCardSubmit}
                                        border
                                    />
                                )}
                            </div>
                        )}

                        {!useSavedCard && (
                            <CardForm
                                jurisdiction={jurisdiction}
                                submitLabel="Review Gift order"
                                onSubmit={onCardFormSubmit}
                            />
                        )}
                    </div>
                )}
                {showConfirmation && (
                    <GiftConfirmation
                        giftAmount={giftAmount}
                        useSavedCard={useSavedCard}
                        changeCard={handleBack}
                        jurisdiction={jurisdiction}
                        amount={giftAmount}
                        currency={home_currency}
                        paymentMethodId={paymentMethodId}
                        allowReturnToCardFormOnFailure={true}
                        returnToCardForm={() => setShowConfirmation(false)}
                    />
                )}
            </Page>
        </>
    )
}

export default GiftPay
