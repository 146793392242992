import {assertNever} from '~/global/utils/assert-never/assertNever'
import {Currency, homeCurrencyList} from '~/global/utils/currency-details/currencyDetails'
import {ActionsType} from './actions'
import {State} from './types'

const initialState: State = {
    summaryLoadingState: 'uninitialised',
    instrumentReturnsLoadingState: 'uninitialised',
    instrumentInvestingHistoryLoadingState: 'uninitialised',
}

function reducer(state: State = initialState, action: ActionsType): State {
    switch (action.type) {
        case 'portfolio.SetPortfolio':
            return {
                ...state,
                summary: {
                    ...action.payload,
                    // converting the currency types from Rakaia to the proper currency types that we use in frontend
                    currency:
                        action.payload.currency.toLowerCase() in homeCurrencyList
                            ? (action.payload.currency.toLowerCase() as Currency)
                            : 'nzd',
                },
            }
        case 'portfolio.SetLatestInstrumentReturns':
            return {
                ...state,
                instrumentReturns: action.payload,
            }
        case 'portfolio.SetInstrumentInvestingHistory':
            return {
                ...state,
                instrumentInvestingHistory: action.payload,
            }
        case 'portfolio.SetSummaryLoadingState':
            return {
                ...state,
                summaryLoadingState: action.payload,
            }
        case 'portfolio.SetInstrumentReturnsLoadingState':
            return {
                ...state,
                instrumentReturnsLoadingState: action.payload,
            }
        case 'portfolio.SetInstrumentInvestingHistoryLoadingState':
            return {
                ...state,
                instrumentInvestingHistoryLoadingState: action.payload,
            }
        case 'portfolio.ClearPortfolio':
            return {
                ...initialState,
            }
        default:
            assertNever(action)
    }
    return state
}

export default reducer
