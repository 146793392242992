import {Button} from '@design-system/button'
import {File, Invest, Clock, Halt, Profile} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {linkWithMailToIfNeeded} from '~/global/utils/link/link'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {useNavigate} from '~/migrate-react-router'
import essActions from '~/store/employeeShareScheme/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'
import ShareSchemeDetailHeader from './widgets/ShareSchemeDetailHeader'
import styles from './widgets/ShareSchemeDetailHeader.scss'

/**
 * This page is used to render information specific to a given share scheme
 * To render this page we need:
 * - The fund that the share scheme is for
 * - The details of this share scheme
 */

const ShareSchemeDetails: React.FunctionComponent<Props> = ({instrumentId, shareSchemeId}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const metadataInitialised = useAppSelector(s => s.instrument.isMetadataInitialised)
    const instrument = useAppSelector(s => (instrumentId ? s.instrument.instrumentsById[instrumentId] : undefined))
    const detailsById = useAppSelector(s => s.employeeShareScheme.shareSchemeDetailById)
    const detail = detailsById[shareSchemeId]
    React.useEffect(() => {
        if (metadataInitialised && !instrument) {
            dispatch(instrumentActions.getSingleInstrumentById(instrumentId))
        }
    }, [instrumentId, instrument, metadataInitialised])

    React.useEffect(() => {
        rudderTrack('ess_scheme', 'scheme_page_viewed')
    }, [])

    React.useEffect(() => {
        // We don't yet have the share scheme detail, go and grab it
        if (!detail) {
            dispatch(essActions.FetchShareSchemeDetail(shareSchemeId))
        }
    }, [detail])

    if (!instrument || !detail) {
        return <Loading isPineapple />
    }

    const contactLinkHref = detail.contact_link ? linkWithMailToIfNeeded(detail.contact_link) : undefined

    return (
        <Page>
            <ShareSchemeDetailHeader instrument={instrument} detail={detail} />
            {detail.summary_text && (
                <div className={cn(styles.iconListItem, spacing.spaceAbove32)}>
                    <Invest className={styles.iconListIcon} />
                    <p className={styles.includeNewLine}>{detail.summary_text}</p>
                </div>
            )}
            {detail.schedule_text && (
                <div className={cn(styles.iconListItem, spacing.spaceAbove32)}>
                    <Clock className={styles.iconListIcon} />
                    <p className={styles.includeNewLine}>{detail.schedule_text}</p>
                </div>
            )}
            {detail.conditions_text && (
                <div className={cn(styles.iconListItem, spacing.spaceAbove32)}>
                    <Profile className={styles.iconListIcon} />
                    <p className={styles.includeNewLine}>{detail.conditions_text}</p>
                </div>
            )}
            {detail.compliance_text && (
                <div className={cn(styles.iconListItem, spacing.spaceAbove32)}>
                    <Halt className={styles.iconListIcon} />
                    <p className={styles.includeNewLine}>{detail.compliance_text}</p>
                </div>
            )}
            {detail.legal_text && (
                <div className={cn(styles.iconListItem, spacing.spaceAbove32)}>
                    <File className={styles.iconListIcon} />
                    <p className={styles.includeNewLine}>{detail.legal_text}</p>
                </div>
            )}
            {detail.google_drive_pdf_link && (
                <div className={spacing.spaceAbove32}>
                    <Button
                        dataTestId="button--ess-view-pdf"
                        type="secondary"
                        icon="File"
                        label="More about your scheme (PDF)"
                        onClick={() => {
                            rudderTrack('ess_scheme', 'scheme_pdf_clicked')
                            navigate('pdf')
                        }}
                    />
                </div>
            )}
            {detail.contact_text && (
                <AlertCard
                    type="none"
                    title="Got more questions?"
                    className={cn(spacing.spaceAbove32, spacing.spaceBelow32)}
                    text={`Get in touch with ${detail.contact_text}`}
                    links={detail.contact_link ? [{text: detail.contact_link, href: contactLinkHref}] : []}
                />
            )}
        </Page>
    )
}

interface Props {
    instrumentId: string
    shareSchemeId: string
}

export default ShareSchemeDetails
