import {Button} from '@design-system/button'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {spacing} from '~/global/scss/helpers'
import {amountIncludingCardFee, amountOfCardFee} from '~/global/utils/card-fee-calculation/cardFeeCalculation'
import {dateFormatShortDayFullMonth} from '~/global/utils/format-date/formatDate'
import {formatNumber} from '~/global/utils/format-number/formatNumber'
import {isWrapperApp} from '~/global/utils/is-wrapper-app/isWrapperApp'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {useAllSubscriptionPlans, useCurrentSubscriptionPlan} from '~/global/utils/subscription-hooks/subscriptionHooks'
import {subscriptionName} from '~/global/utils/subscription-name/subscriptionName'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {wouldPlanIncurNegativeBalance} from '~/global/utils/would-plan-incur-negative-balance/wouldPlanIncurNegativeBalance'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ErrorBox} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {formatPaymentMethod} from '~/sections/user/sections/settings/sections/plans/pages/payment-method/PlanPaymentMethod'
import AccountPlanTopUpModal from '~/sections/user/sections/settings/sections/plans/widgets/modals/PlanTopUpModal'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {selectHasStaffBenefits} from '~/store/identity/selectors'
import actions from '~/store/plan/actions'
import {BillingCycle, PaymentMethods, SubPlanIdV2, SubPlanV2} from '~/store/plan/types'
import styles from './PlanConfirm.scss'

// Type for billing cycle data
type BillingOptions = SubPlanV2['billing_options']['ANNUAL'] | SubPlanV2['billing_options']['MONTHLY']

// TODO adapt for dependents - not relevant until there’s multiple kids plans
const ImmediateChangeContent = ({
    newPlan,
    oldPlan,
    thisBillingCycle,
    nextBillingCycle,
    paymentMethod,
}: {
    newPlan: SubPlanV2
    oldPlan: SubPlanV2
    thisBillingCycle: BillingOptions
    nextBillingCycle: BillingOptions
    paymentMethod: PaymentMethods
}) => {
    const hasStaffBenefits = useAppSelector(selectHasStaffBenefits)

    if (!thisBillingCycle || !nextBillingCycle) {
        return <></>
    }

    // Adding in card fee to this immediate-change-info.  The card fee displayed in the table that follows will
    // relate to the billing cycle _renewal_ price+fee, we need to show the _on_change_ card fee too (so do that in this copy)
    const costDifference = Number(
        paymentMethod === 'CARD'
            ? amountIncludingCardFee(thisBillingCycle.price_on_change!, newPlan.currency as 'nzd' | 'aud')
            : thisBillingCycle.price_on_change,
    )

    return (
        <>
            <p>
                You’re changing from the {subscriptionName(oldPlan)} to the {subscriptionName(newPlan)}.
            </p>

            {/* Monthly plans are free for those with staff benefits - hide payment information */}
            {!hasStaffBenefits && (
                <>
                    <p className={spacing.spaceAbove20}>
                        <DollarValue
                            value={costDifference}
                            currency={newPlan.currency}
                            decimalPlaces={Number.isInteger(Number(costDifference)) ? 0 : 2}
                        />{' '}
                        (the difference in plan fees{paymentMethod === 'CARD' && ' and a card processing fee'}) will be
                        deducted from your {paymentMethod.toLocaleLowerCase()}, and your new plan will start straight
                        away.
                        {thisBillingCycle.id === 'ANNUAL' && ' Your plan coverage will continue to renew every month.'}
                    </p>
                    <p className={spacing.spaceAbove20}>
                        {nextBillingCycle.id === 'ANNUAL' && (
                            <>
                                Your next payment of ${nextBillingCycle.price_on_renewal} will be changed on{' '}
                                {nextBillingCycle.would_expire.toFormat(dateFormatShortDayFullMonth)}. If we are unable
                                to process your payment on this date, your plan will be cancelled.
                            </>
                        )}
                        {nextBillingCycle.id === 'MONTHLY' && (
                            <>
                                If we are unable to process your payment we’ll try one more time before cancelling your
                                plan.
                            </>
                        )}
                    </p>
                    <p className={spacing.spaceAbove20}>
                        You can manage your payment method and payment frequency from your plan page.
                    </p>
                </>
            )}
        </>
    )
}

interface DeferredPlanChangeProps {
    newPlan: SubPlanV2
    oldPlan: SubPlanV2
    billingCycle: BillingOptions
    startDate: DateTime
    paymentMethod: PaymentMethods
}

// TODO adapt for dependents - not relevant until there’s multiple kids plans
const DeferredChangeContent = ({newPlan, oldPlan, startDate, billingCycle, paymentMethod}: DeferredPlanChangeProps) => {
    const hasStaffBenefits = useAppSelector(selectHasStaffBenefits)

    if (!billingCycle) {
        return <></>
    }

    return (
        <>
            <p>
                You’re changing from the {subscriptionName(oldPlan)} to the {subscriptionName(newPlan)}.
            </p>

            {/* Monthly plans are free for those with staff benefits - hide payment information */}
            {!hasStaffBenefits && (
                <>
                    <p className={spacing.spaceAbove20}>
                        On {startDate.toFormat(dateFormatShortDayFullMonth)}, we’ll deduct{' '}
                        <DollarValue value={billingCycle.price_on_renewal} currency={newPlan.currency} /> from your{' '}
                        {formatPaymentMethod(paymentMethod)} and your new plan will begin.{' '}
                        {billingCycle.id === 'ANNUAL'
                            ? 'If we are unable to process your payment on this date, your plan will be cancelled. '
                            : 'If we’re unable to process your payment, we’ll try one more time before cancelling your plan. '}
                    </p>
                    {billingCycle.id === 'ANNUAL' && (
                        <p className={spacing.spaceAbove20}>Your plan coverage will continue to renew every month.</p>
                    )}
                    <p className={spacing.spaceAbove20}>
                        You can manage your payment method and payment frequency from your plan page.
                    </p>
                </>
            )}
        </>
    )
}

// TODO adapt for dependents - not relevant until there’s multiple kids plans
const RevertPlanContent = ({newPlan, oldPlan, startDate, billingCycle, paymentMethod}: DeferredPlanChangeProps) => {
    const hasStaffBenefits = useAppSelector(selectHasStaffBenefits)

    if (!billingCycle) {
        return <></>
    }

    return (
        <>
            <p>
                Currently, you’re set to change to the {subscriptionName(oldPlan)} on{' '}
                {startDate.toFormat(dateFormatShortDayFullMonth)}.
            </p>

            {hasStaffBenefits && (
                <p className={spacing.spaceAbove20}>
                    By confirming, your {subscriptionName(newPlan)} will renew as usual instead.
                </p>
            )}

            {/* Monthly plans are free for those with staff benefits - hide payment information */}
            {!hasStaffBenefits && (
                <p className={spacing.spaceAbove20}>
                    By confirming, your {subscriptionName(newPlan)} will renew as usual instead, and{' '}
                    <DollarValue
                        value={billingCycle.price_on_renewal}
                        currency={newPlan.currency}
                        decimalPlaces={Number.isInteger(Number(billingCycle.price_on_renewal)) ? 0 : 2}
                    />{' '}
                    will be deducted from your {formatPaymentMethod(paymentMethod)} on{' '}
                    {startDate.toFormat(dateFormatShortDayFullMonth)}.
                </p>
            )}
        </>
    )
}

interface NewPlanProps {
    preferredName?: string
    billingCycle: BillingOptions
}

const NewPlanContent = ({preferredName, billingCycle}: NewPlanProps) => {
    const hasStaffBenefits = useAppSelector(selectHasStaffBenefits)

    if (!billingCycle) {
        return <></>
    }

    const cycleLower = billingCycle.id.toLowerCase()
    const cycleAdverb = billingCycle.id === 'ANNUAL' ? 'annually' : 'monthly'
    return (
        <>
            <p>
                {preferredName ? `${preferredName}’s` : 'Your'} plan will start right away, and{' '}
                {preferredName ? 'their' : 'your'} coverage will renew every month.
            </p>

            {/* Monthly plans are free for those with staff benefits - hide payment information */}
            {!hasStaffBenefits && (
                <>
                    <p className={spacing.spaceAbove20}>
                        Because {preferredName ? 'they' : 'you'}’re paying {cycleAdverb}, your next {cycleLower} fee
                        payment will be on {billingCycle.would_expire.toFormat(dateFormatShortDayFullMonth)}.
                    </p>
                    <p className={spacing.spaceAbove20}>
                        If we’re unable to process your payment,{' '}
                        {billingCycle.id === 'ANNUAL'
                            ? 'your plan will be cancelled'
                            : 'we’ll try one more time before cancelling your plan'}
                        .
                    </p>
                </>
            )}
        </>
    )
}

interface PlanDetailProps {
    planData: SubPlanV2
    startDate: DateTime
    billingCycle: BillingOptions
    paymentMethod: PaymentMethods
}

export const PlanDetail = ({planData, startDate, billingCycle, paymentMethod}: PlanDetailProps) => {
    const hasStaffBenefits = useAppSelector(selectHasStaffBenefits)
    const planDataAnnualPrice = planData.billing_options.ANNUAL?.price_on_renewal
    const planDataMonthlyPrice = planData.billing_options.MONTHLY.price_on_renewal

    // calculate discount from annual price
    const annualDiscount =
        planData.billing_options.ANNUAL?.price_on_renewal &&
        Number(planDataMonthlyPrice) * 12 - Number(planDataAnnualPrice || 0)
    const annualDiscount2dp =
        annualDiscount && formatNumber({number: annualDiscount.toString(), roundDown: true, decimalPlaces: 2})

    const billingCycleName = billingCycle.id.charAt(0) + billingCycle.id.slice(1).toLowerCase()
    const planRenewalPrice = billingCycle.price_on_renewal

    // processing fee
    const feeAmount =
        paymentMethod === 'CARD' ? amountOfCardFee(planRenewalPrice, planData.currency as 'nzd' | 'aud') : null
    const totalAmount =
        paymentMethod === 'CARD'
            ? amountIncludingCardFee(planRenewalPrice, planData.currency as 'nzd' | 'aud')
            : planRenewalPrice

    // Design note: slightly different design here from OrderConfirmation, though otherwise
    // very similar

    return (
        <>
            <h2 className={spacing.spaceAbove16}>Plan details</h2>
            <div className={cn(styles.planDetailContainer, spacing.spaceAbove16)}>
                <div className={styles.planDetailRow}>
                    <span>Start date</span>
                    <span className={styles.boldItem} data-testid="text--plan-detail-start">
                        {startDate.toFormat(dateFormatShortDayFullMonth)}
                    </span>
                </div>
                <div className={styles.planDetailRow}>
                    <span>{hasStaffBenefits ? 'Frequency' : 'Billing cycle'}</span>
                    <span className={styles.boldItem}>{billingCycleName}</span>
                </div>

                {feeAmount && (
                    <>
                        <div className={styles.planDetailRow}>
                            <span>Card processing fee{planData.currency === 'aud' ? ' incl. GST' : ''}</span>
                            <span className={styles.boldItem}>
                                <DollarValue value={feeAmount} decimalPlaces={2} currency={planData.currency} />
                            </span>
                        </div>
                    </>
                )}

                {/* Monthly plans are free for those with staff benefits - hide payment information */}
                {!hasStaffBenefits && (
                    <div className={styles.planDetailRow}>
                        <span>Payment method</span>
                        <span className={styles.boldItem}>{formatPaymentMethod(paymentMethod)}</span>
                    </div>
                )}

                <div className={styles.planDetailTotalAnnual}>
                    <div className={styles.totals}>
                        <span>{billingCycleName} fee</span>
                        <span data-testid="text--plan-detail-price">
                            <span className={cn({[styles.isStaffPrice]: hasStaffBenefits})}>
                                <DollarValue value={totalAmount} decimalPlaces={2} currency={planData.currency} />
                            </span>
                            {hasStaffBenefits && <div>FREE for Sharesies staff 💗</div>}
                        </span>
                    </div>
                    {billingCycle.id === 'ANNUAL' && <p>Includes ${annualDiscount2dp} annual billing discount</p>}
                </div>
            </div>
        </>
    )
}

interface OwnProps {
    plan: SubPlanIdV2
    billingCycle?: BillingCycle
    paymentMethod?: PaymentMethods
}

const PlanConfirm: React.FunctionComponent<OwnProps> = ({plan: planId, billingCycle, paymentMethod}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const [needToTopupModalOpen, setNeedToTopupModalOpen] = React.useState(false)
    const [isProcessing, setIsProcessing] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')

    const [currentSubscription, currentSubscriptionLoaded] = useCurrentSubscriptionPlan()
    const allPlans = useAllSubscriptionPlans()
    const planData = allPlans?.find(p => p.id === planId)

    const hasStaffBenefits = useAppSelector(selectHasStaffBenefits)
    const {
        isDependent,
        preferredName: preferredName,
        hasAnnualPlansFlag,
        walletBalance,
    } = useAppSelector(s => ({
        isDependent: s.identity.user?.is_dependent,
        preferredName: s.identity.user?.preferred_name,
        hasAnnualPlansFlag: s.identity.flags.annual_plans,
        walletBalance: s.identity.user!.wallet_balances[(allPlans ?? []).find(p => p.id === planId)?.currency ?? 'nzd'],
    }))

    if (!currentSubscriptionLoaded || !allPlans) {
        return <Loading isPineapple />
    }

    if (!planData || (billingCycle === 'ANNUAL' && !hasAnnualPlansFlag)) {
        return <WeSlippedUp />
    }

    if (planId === currentSubscription?.auto_renew_into_plan?.id) {
        return <Navigate to={profileUrl('settings/plan/select')} replace />
    }

    // You cannot change your plan if it's currently cancelled
    if (currentSubscription && !currentSubscription.auto_renew) {
        return <Navigate to={profileUrl('settings/plan')} replace />
    }

    // Billing info that applies for any instant costs of this plan change
    const immediateChangeBillingCycle = currentSubscription
        ? planData.billing_options[currentSubscription.billing_cycle]
        : undefined

    // Billing info that applies for next renewal
    const deferredChangeBillingCycle =
        planData.billing_options[
            billingCycle ??
                currentSubscription?.auto_renew_into_billing_cycle ??
                currentSubscription?.billing_cycle ??
                'MONTHLY'
        ]

    // Billing info for a new plan (it happens to be the same as deferred change billing info)
    const newPlanBillingCycle = deferredChangeBillingCycle

    const hasSelectedCurrent = currentSubscription?.plan.id === planData.id

    // extract logic of what kind of action we’re showing
    const action: 'new' | 'change-immediate' | 'change-deferred' | 'revert-change' | 'unknown' =
        currentSubscription && !hasSelectedCurrent && immediateChangeBillingCycle!.would_start_immediately
            ? 'change-immediate'
            : currentSubscription && !hasSelectedCurrent && !deferredChangeBillingCycle!.would_start_immediately
              ? 'change-deferred'
              : currentSubscription && hasSelectedCurrent && currentSubscription.auto_renew_into_plan
                ? 'revert-change'
                : !currentSubscription
                  ? 'new'
                  : 'unknown'

    const toastText = () => {
        switch (action) {
            case 'change-immediate':
                return `You’ve changed to the ${subscriptionName(planData)}—nice one!`
            case 'change-deferred':
                return `You’ll change to the ${subscriptionName(planData)} on ${currentSubscription!.expires.toFormat(
                    dateFormatShortDayFullMonth,
                )}—nice one!`
            case 'revert-change':
                return `Got it—your ${subscriptionName(
                    planData,
                )} will renew as usual on ${currentSubscription!.expires.toFormat(dateFormatShortDayFullMonth)}`
        }
    }

    const pageTitle = !currentSubscription
        ? `Confirm ${isDependent ? `${preferredName}’s` : 'your'} plan`
        : `Confirm change to ${subscriptionName(planData)}`

    const nextPath = currentSubscription ? 'settings/plan' : 'settings/plan/success'

    const paymentMethodOrDefault = paymentMethod || currentSubscription?.payment_method || 'WALLET'

    const handleSubmit = async () => {
        if (
            !hasStaffBenefits && // Monthly plans are free for those with staff benefits, so we don't need to check the wallet
            wouldPlanIncurNegativeBalance(
                planData,
                walletBalance,
                billingCycle ?? 'MONTHLY',
                paymentMethodOrDefault,
                currentSubscription,
            )
        ) {
            setNeedToTopupModalOpen(true)
        } else {
            setIsProcessing(true)
            const error = await dispatch(
                actions.ChangePlan(
                    planData.id,
                    billingCycle,
                    currentSubscription?.plan.id,
                    currentSubscription?.billing_cycle,
                    paymentMethod,
                ),
            )
            setIsProcessing(false)

            if (error) {
                setErrorMessage(error.message)
                return
            }

            rudderTrack('plans', 'plan_confirmed')
            Analytics.event({
                action: 'start',
                category: 'plans',
                label: isDependent ? 'kids' : 'adult',
            })

            if (isWrapperApp() && currentSubscription) {
                sendWrapperAppMessage({type: 'identityUpdated'})
                sendWrapperAppMessage({type: 'goBack'})
            }

            navigate(profileUrl(nextPath))

            const toastMessage = toastText()

            if (toastMessage) {
                Toast(toastMessage)
            }
        }
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--subscription-confirm"
                leftButton="back"
                title={pageTitle}
                onLeftButtonClick={() => {
                    rudderTrack('plans', 'plan_confirmation_abandoned', {abandoned_plan_choice: planData.id as any})
                    navigate(-1)
                }}
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                {action === 'change-immediate' && (
                    <>
                        <ImmediateChangeContent
                            newPlan={planData}
                            oldPlan={currentSubscription!.plan}
                            thisBillingCycle={immediateChangeBillingCycle!}
                            nextBillingCycle={deferredChangeBillingCycle!}
                            paymentMethod={paymentMethodOrDefault}
                        />
                        <PlanDetail
                            planData={planData}
                            billingCycle={immediateChangeBillingCycle!}
                            startDate={DateTime.local()} /* TODO "Immediate" */
                            paymentMethod={paymentMethodOrDefault}
                        />
                    </>
                )}
                {action === 'change-deferred' && (
                    <>
                        <DeferredChangeContent
                            newPlan={planData}
                            oldPlan={currentSubscription!.plan}
                            billingCycle={deferredChangeBillingCycle!}
                            startDate={currentSubscription!.expires}
                            paymentMethod={paymentMethodOrDefault}
                        />
                        <PlanDetail
                            planData={planData}
                            billingCycle={deferredChangeBillingCycle!}
                            startDate={currentSubscription!.expires}
                            paymentMethod={paymentMethodOrDefault}
                        />
                    </>
                )}
                {action === 'revert-change' && (
                    <>
                        <RevertPlanContent
                            newPlan={planData}
                            oldPlan={currentSubscription!.auto_renew_into_plan!}
                            startDate={currentSubscription!.expires}
                            billingCycle={deferredChangeBillingCycle!}
                            paymentMethod={paymentMethodOrDefault}
                        />
                        <PlanDetail
                            billingCycle={deferredChangeBillingCycle!}
                            planData={planData}
                            startDate={currentSubscription!.expires}
                            paymentMethod={paymentMethodOrDefault}
                        />
                    </>
                )}
                {action === 'new' && (
                    <>
                        <NewPlanContent
                            preferredName={isDependent ? preferredName : undefined}
                            billingCycle={newPlanBillingCycle!}
                        />
                        <PlanDetail
                            billingCycle={newPlanBillingCycle!}
                            planData={planData}
                            startDate={DateTime.local()}
                            paymentMethod={paymentMethodOrDefault}
                        />
                    </>
                )}
                <ErrorBox message={errorMessage} />
            </Page>
            <ActionBar>
                <Button
                    label={currentSubscription ? 'Change plan' : 'Start plan'}
                    dataTestId="button--confirm-subscription"
                    processing={isProcessing}
                    disabled={!!errorMessage}
                    onClick={handleSubmit}
                />
            </ActionBar>
            <AccountPlanTopUpModal
                isOpen={needToTopupModalOpen}
                setIsOpen={setNeedToTopupModalOpen}
                subscriptionPlan={planData}
            />
        </>
    )
}

export default PlanConfirm
