import {Model} from '~/api/retail/types'
import {AccountVerificationStatus} from '~/global/widgets/account-verification-required/AccountVerificationRequired'

export const restrictedAddressStatus = (
    identity: Model.User | null,
    addressState: Model.User['address_state'] | null,
    accountRestricted: Model.User['account_restricted'],
): AccountVerificationStatus['addressStatus'] => {
    // Restriction logic around address differs between jurisdictions
    //
    // We block AU signups until we are satisfied - and no further warnings would normally
    // be necessary here.  FYI AU rules are:
    //   - address obtained AND
    //   - address verified OR name+dob check from a secondary source
    //
    // However, some users were already on the platform
    // when those signup rules came in.  These users might not meet the requirements above, and
    // warrant an alert box.  For now that's just an address prompt (though secondary source DOB check
    // would technically suffice).

    const jurisdiction = identity?.jurisdiction
    const addressIsRequired =
        jurisdiction === undefined ||
        jurisdiction === 'nz' ||
        (jurisdiction === 'au' && !identity!.checks.identity_verification.secondary_id_verified)

    if (!addressIsRequired) {
        return 'no-warning'
    }

    if (addressState === 'uploaded') {
        return 'pending-approval'
    }

    if (addressState === 'new' || addressState === 'rejected') {
        return accountRestricted ? 'need-upload-restricted' : 'need-upload'
    }

    return 'no-warning'
}

export const restrictedPrescribedApprovalStatus = (
    prescribedApproved: Model.User['prescribed_approved'],
    prescribedParticipant: Model.User['prescribed_participant'] | false,
    accountRestricted: Model.User['account_restricted'],
): AccountVerificationStatus['prescribedApprovalStatus'] => {
    if (prescribedParticipant && !prescribedApproved && accountRestricted) {
        return 'pending-approval'
    }
    return 'no-warning'
}

export const restrictedIdentityVerificationStatus = (
    accountRestricted: Model.User['account_restricted'],
    identityVerification: Model.User['checks']['identity_verification'],
): AccountVerificationStatus['identityVerificationStatus'] => {
    const additionalVerificationRequired = identityVerification.additional_verification_required
    const additionalVerificationRequiredReason = identityVerification.additional_verification_required_reason

    const latestBiometricCheck = identityVerification.latest_biometric_verification_check
    const inReviewBiometricCheck =
        latestBiometricCheck?.state === 'IN_REVIEW' || latestBiometricCheck?.state === 'FAILURE'

    if (accountRestricted) {
        // Account is restricted because the user has not completed the
        // required verification within their grace period
        if (
            additionalVerificationRequired &&
            additionalVerificationRequiredReason === 'threshold_exceeded_restricted'
        ) {
            return 'verification-overdue'
        }

        // Account is restricted because the user has completed biometrics,
        // but the check required manual review by an operator
        if (inReviewBiometricCheck) {
            return 'verification-in-review'
        }
    }

    return 'no-warning'
}
