import {Button} from '@design-system/button'
import {CheckboxInput} from '@design-system/checkbox-input'
import cn from 'classnames'
import {useAtom} from 'jotai'
import React from 'react'
import {useNavigate} from 'react-router'
import {Link, Navigate} from 'react-router-dom'
import {useRetailPost} from '~/api/query/retail'
import {Request} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Image from '~/global/widgets/image/Image'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import saveImage from '~/sections/save/assets/images/save-small.png'
import commonStyles from '~/sections/save/common.scss'
import {stagedNameAtom} from '~/sections/save/state/createAccountState'
import {createAccountTracking} from '~/sections/save/utils/create-account-tracking/createAccountTracking'
import {useAppSelector} from '~/store/hooks'
import styles from './Confirmation.scss'

export const Confirmation: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profile = useProfile()
    const profileUrl = useProfileUrl()
    const createAccount = useRetailPost({path: 'save/v2/open-account', queryCacheToInvalidate: ['profiles']})
    const [stagedName, setStagedName] = useAtom(stagedNameAtom)

    const [loading, setLoading] = React.useState<boolean>(false)
    const [termsChecked, setTermsChecked] = React.useState<boolean>(false)
    const hasSaveFlag = useAppSelector(s => s.identity.flags.savings) // Flag for AU residents only
    const interestRate = useAppSelector(s => s.save.interestRate)
    const {is_dependent, tfn_number, jurisdiction} = useAppSelector(s => s.identity.user!)

    if (jurisdiction !== 'nz' && !hasSaveFlag) {
        return <Navigate to={profileUrl('')} replace />
    }

    const handleCreateButtonClick = async (): Promise<string | void> => {
        setLoading(true)

        const payload: Request.OpenSaveAccountV2 = {type_: 'SAVE', name: stagedName, owner_id: profile.owner_id}
        await createAccount.mutateAsync(payload, {
            onSuccess: response => {
                createAccountTracking(is_dependent, 'nz')

                setStagedName(undefined)
                Toast('Save account created! 💌')
                navigate(profileUrl('save/:portfolioId', {portfolioId: response.account.portfolio_id}))
            },
            onError: () => {
                Toast('Please try again')
                setLoading(false)
            },
        })
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--save-confirm" leftButton="back" title="Confirmation" />
            <Page className={commonStyles.save}>
                <div className={cn(styles.confirmRow, spacing.spaceBelow32)}>
                    <Image src={saveImage} className={styles.confirmImage} />
                    <div className={styles.confirmRowContent}>
                        <p className={styles.confirmDescription}>{stagedName || 'Save'}</p>
                        <p className={styles.confirmRate}>{interestRate}% p.a.</p>
                    </div>
                </div>
                <CheckboxInput
                    dataTestId="save-terms-conditions"
                    id="save"
                    isTouched
                    value={termsChecked}
                    label={
                        <>
                            <div className={styles.checkboxTitle}>I confirm that:</div>
                            <ul className={styles.checkboxList}>
                                <li className={styles.checkboxListItem}>
                                    I’m aware this interest rate is subject to change
                                </li>
                                <li className={styles.checkboxListItem}>
                                    I can transfer my money out of my Save account at any time
                                </li>
                                {jurisdiction === 'au' && !tfn_number && (
                                    <li className={styles.checkboxListItem}>
                                        I haven't <Link to={profileUrl('save/tax')}>provided my TFN</Link> and my earned
                                        interest will be taxed at 47%
                                    </li>
                                )}
                                <li className={styles.checkboxListItem}>
                                    I’ve read and agree to the{' '}
                                    <Link to={profileUrl('legal/save-terms')}>Save terms and conditions</Link>
                                </li>
                            </ul>
                        </>
                    }
                    name="terms and conditions confirm"
                    onChange={() => {
                        setTermsChecked(!termsChecked)
                    }}
                />
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--open-save-confirm"
                    label="Open a Save account"
                    processing={loading}
                    onClick={handleCreateButtonClick}
                    disabled={!termsChecked}
                />
            </ActionBar>
        </>
    )
}

export default Confirmation
