import {createAction, ActionsUnion} from '../redux-tools'

const actions = {
    SetModalError: (message: string, title: string | null) =>
        createAction('notification.SetModalError', {message, title}),
    ClearModalError: () => createAction('notification.ClearModalError'),
    ClientUpgradeRequired: () => createAction('notification.ClientUpgradeRequired'),
    ScheduledMaintenance: (enabled = true) => createAction('notification.ScheduledMaintenance', enabled),
    HideAddressValidationRejectionReason: () => createAction('notification.hideAddressValidationRejectionReason'),
    ShowAddressValidationRejectionReason: () => createAction('notification.showAddressValidationRejectionReason'),
}

export type ActionsType = ActionsUnion<typeof actions>

export default actions
