import {SpareChange} from '@design-system/icon'
import React from 'react'
import {GET_MAP} from '~/api/retail/types'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import TransactionRow from '~/global/widgets/transaction-row/TransactionRow'

type PaymentRequest = GET_MAP['wallet/:portfolio_id/pending-payment-requests']['response']['payment_requests'][number]

export const WalletPaymentRequest: React.FunctionComponent<{paymentRequest: PaymentRequest}> = ({paymentRequest}) => {
    const profileUrl = useProfileUrl()

    return (
        <TransactionRow
            amount={paymentRequest.amount}
            currency={paymentRequest.currency}
            date={paymentRequest.timestamp}
            title={paymentRequest.description}
            icon={<SpareChange />}
            url={profileUrl('wallet/payment-request/:paymentRequestId', {
                paymentRequestId: paymentRequest.id,
            })}
        />
    )
}
