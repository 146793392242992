import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {useAppSelector} from '~/store/hooks'
import FeeModalInfo from './FeeModalInfo'

const BuyAndSellFeesListedInstruments: React.FunctionComponent<{}> = () => {
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)

    return (
        <ModalLink
            dataTestId="modal-link--buy-sell-fees-listed-instruments"
            label="buy and sell fees on listed investments"
            asIcon
            modalTitle="Fees on investments"
            primaryButton={{label: 'Ok'}}
            bottomBorder
            helpIconSize={16}
        >
            <FeeModalInfo jurisdiction={jurisdiction} />

            <h2 className={cn(spacing.spaceBelow12, spacing.spaceAbove32)}>Management fee</h2>

            {jurisdiction === 'au' ? (
                <>
                    <p>
                        When you invest in an ETF, you’re charged a management fee. This is charged by the fund
                        provider—not Sharesies—and included in the unit price.
                    </p>
                    <p>
                        The management fee pays for the fund’s management, distribution, and operational costs. It’s
                        different for each fund, so check the fund info before you invest.
                    </p>
                </>
            ) : (
                <>
                    <p>
                        When you invest in an ETF or managed fund, you’re charged a management fee. This is charged by
                        the fund provider—not Sharesies—and included in the unit price.
                    </p>
                    <p>
                        The management fee pays for the fund’s management, distribution, and operational costs. It’s
                        different for each fund, so check the fund info before you invest.
                    </p>
                    <p>
                        We charge managed fund providers a fee, which you can expect to make up some part of the
                        management fee they charge.
                    </p>
                </>
            )}
            <p>
                For a full run-down of our fees,{' '}
                <a
                    target="_blank"
                    rel="noopener"
                    href={
                        jurisdiction === 'au'
                            ? 'https://www.sharesies.com.au/pricing#how-we-calculate-transaction-fees'
                            : 'https://www.sharesies.nz/pricing#how-we-calculate-transaction-fees'
                    }
                >
                    check out our website
                </a>
                .
            </p>
        </ModalLink>
    )
}

export default BuyAndSellFeesListedInstruments
