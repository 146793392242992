import {Button} from '@design-system/button'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import signUpActions from '~/store/sign-up/actions'

const FormInformation: React.FunctionComponent<{
    isDependent: boolean
    preferredName: string
    setCompletedFormInfo: React.Dispatch<React.SetStateAction<boolean>>
}> = ({isDependent: isDependant, preferredName, setCompletedFormInfo: setCompletedIntro}) => {
    const dispatch = useAppDispatch()
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
    const usStatus = useAppSelector(s => s.signUp.usStatus)
    const signup_state = usStatus?.customer_signup_state
    const hasSeenFlagKey = signup_state === 'INITIAL' ? 'us_shares_intro' : 'us_shares_resign_intro'

    return (
        <>
            {signup_state === 'RESIGN_REQUIRED' || signup_state === 'RESIGN_CERTIFICATION' ? (
                <p className={spacing.spaceBelow16}>
                    {`You need to confirm the details you have previously provided for ${
                        isDependant ? `${preferredName}’s` : 'your'
                    } W-8BEN or W-9 form for the Internal Revenue Service (IRS), then you’ll need to electronically sign the form.`}
                </p>
            ) : (
                <>
                    <p className={spacing.spaceBelow16}>
                        {`Before you can invest in US shares or exchange money to US dollars${
                            isDependant ? ` for ${preferredName},` : ','
                        } you need to complete a W-8BEN or W-9 tax form for the
                        Internal Revenue Service (IRS).`}
                    </p>

                    <p className={spacing.spaceBelow16}>
                        We need to confirm some of the info you’ve previously given us. Then you’ll need to
                        electronically sign the form.
                    </p>
                </>
            )}

            <p className={spacing.spaceBelow48}>
                This should only take a minute or so. Once you’ve signed the form you can view and edit this info later
                in {isDependant ? `${preferredName}’s` : 'your'} Account.
            </p>

            <p className={spacing.spaceBelow16}>
                <Button
                    label="Let’s get to it"
                    dataTestId="button--complete-us-tax-form"
                    processing={isSubmitting}
                    onClick={async () => {
                        setCompletedIntro(true)
                        setIsSubmitting(true)
                        try {
                            if (hasSeenFlagKey === 'us_shares_intro') {
                                await dispatch(identityActions.MarkHasSeenFlag(hasSeenFlagKey))
                            } else {
                                await dispatch(signUpActions.MarkHasSeenFlag(hasSeenFlagKey))
                            }

                            await dispatch(signUpActions.getUSStatus())
                        } catch (error) {
                            setIsSubmitting(false)
                            throw error
                        }
                    }}
                />
            </p>
        </>
    )
}

export default FormInformation
