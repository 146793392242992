import {DateTime} from 'luxon'
import {dateFormatShortDayFullMonth} from '~/global/utils/format-date/formatDate'
import {useAppSelector} from '~/store/hooks'

// Canonical backend timezone for the user
function useJurisdictionTimezone() {
    const jurisdiction = useAppSelector(s => s.identity.user?.jurisdiction)
    return {
        au: 'Australia/NSW',
        nz: 'Pacific/Auckland',
    }[jurisdiction!]
}

/**
 * 'Ends on' should be the last usable day, 'renews on' the day that next payment occurs...
 * ...these could in theory be the same day, but if expiry close enough to midnight then
 * they should be different (consecutive) days.  In such cases the last usable day the day prior.
 */
export function useLastDayOfSubscription(expires?: DateTime) {
    // If we use browser time then Australians not on the east coast
    // will not get the benefit of our midnight check.  So don't do that.
    const zone = useJurisdictionTimezone()
    if (!expires) {
        return
    }
    const expiresInZone = expires.setZone(zone)
    const isMidnightExpiry = expiresInZone.hour === 0 && expiresInZone.minute === 0
    return expires.minus({days: isMidnightExpiry ? 1 : 0}).toFormat(dateFormatShortDayFullMonth)
}
