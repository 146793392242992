import {colour, semanticColour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import cn from 'classnames'
import React from 'react'
import {SliderItem} from 'react-compound-slider'
import {accessibility} from '~/global/scss/helpers'
import styles from './CompoundSlider.scss'

interface TooltipRailProps {
    activeHandleID: string
    getRailProps: (props: object) => object
    getEventData: (e: Event) => object
    values: number[]
    currentRiskRange: number[] | null
}

export const TooltipRail: React.FC<TooltipRailProps> = props => {
    const colourMode = useColourMode()

    const StopColor = ({index}: {index: number}) => {
        return (
            <>
                <stop
                    offset={`${(100 / 6) * index}%`}
                    stopColor={
                        props.values[0] - 1 <= index && index < props.values[1] - 1
                            ? colour('MelonAlpha150', colourMode)
                            : semanticColour('FillSecondary', colourMode)
                    }
                />
                <stop
                    offset={`${(100 / 6) * (index + 1)}%`}
                    stopColor={
                        props.values[0] - 1 <= index && index < props.values[1] - 1
                            ? colour('MelonAlpha150', colourMode)
                            : semanticColour('FillSecondary', colourMode)
                    }
                />
            </>
        )
    }

    const RiskBackground = () => {
        return (
            <div className={styles.riskBackground}>
                <svg viewBox="0 0 264 36" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                    <defs>
                        <linearGradient id="partiallyFilled">
                            {[...Array(6)].map((_, index: number) => (
                                <StopColor index={index} key={index} />
                            ))}
                        </linearGradient>
                    </defs>
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g
                            id="funds-/-filters-risk-3"
                            transform="translate(-28.000000, -245.000000)"
                            fill="url(#partiallyFilled)"
                        >
                            <path
                                d="M77.7722221,261 C172.994361,258.814813 244.40362,253.481479 292,245 L292,265 L292,281 C244.40362,272.518521 172.994361,267.185187 77.7722221,265 L28,265 L28,264.167809 L28,261 L77.7722221,261 Z"
                                id="Combined-Shape"
                            />
                        </g>
                    </g>
                </svg>
            </div>
        )
    }

    return (
        <>
            <div className={styles.rail} />
            <RiskBackground />
            <div className={styles.railCentre} />
        </>
    )
}

interface HandleProps {
    key: string
    handle: SliderItem
    isActive: boolean
    disabled?: boolean
    domain: number[]
    getHandleProps: (id: string, config: object) => object
}

export class Handle extends React.Component<HandleProps> {
    static defaultProps = {
        disabled: false,
    }

    state = {
        mouseOver: false,
    }

    onMouseEnter = () => {
        this.setState({mouseOver: true})
    }

    onMouseLeave = () => {
        this.setState({mouseOver: false})
    }

    render() {
        const {
            handle: {id, percent},
            getHandleProps,
        } = this.props

        return (
            <>
                <button
                    type="button"
                    role="slider"
                    className={cn(styles.handle, accessibility.button)}
                    style={{
                        left: `${percent}%`,
                    }}
                    {...getHandleProps(id, {
                        onMouseEnter: this.onMouseEnter,
                        onMouseLeave: this.onMouseLeave,
                    })}
                />
            </>
        )
    }
}

interface TrackProps {
    source: SliderItem
    target: SliderItem
    disabled?: boolean
    getTrackProps: () => object
}

export const Track = ({source, target, getTrackProps}: TrackProps) => {
    return (
        <div
            className={styles.track}
            style={{
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,
            }}
            {...getTrackProps()}
        />
    )
}

interface TickProps {
    tick: SliderItem
    count: number
    values: number[]
}

export const Tick = ({values, tick, count}: TickProps) => {
    return (
        <div>
            <div
                className={cn(styles.tick, {
                    [styles.pinkTick]: values[0] <= tick.value && tick.value <= values[1],
                })}
                style={{
                    left: `${tick.percent}%`,
                }}
            />
            <div
                className={styles.number}
                style={{
                    marginLeft: `${-(100 / count) / 2}%`,
                    width: `${100 / count}%`,
                    left: `${tick.percent}%`,
                }}
            >
                {tick.value}
            </div>
        </div>
    )
}
