import {Thumbnail} from '@design-system/thumbnail'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {accessibility} from '~/global/scss/helpers'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import styles from './InstrumentRowCompact.scss'

interface InstrumentRowCompactProps {
    instrument: Instrument
    mainTitleOverride?: React.ReactNode
    subTitleOverride?: React.ReactNode
    rowLinkTarget?: string
    onLinkNavigated?: () => void
    rowTopBorder?: boolean
    rightSlot?: React.ReactNode
    dataTestId?: string
    additionalClassName?: string
}

export const InstrumentRowCompact: React.FunctionComponent<InstrumentRowCompactProps> = ({
    instrument,
    mainTitleOverride,
    subTitleOverride,
    rowLinkTarget,
    onLinkNavigated,
    rowTopBorder,
    rightSlot,
    additionalClassName,
    dataTestId,
}) => {
    const navigate = useNavigate()
    const resourcePath = useAppSelector(({instrument}) => instrument.metadata.resourcePath)

    const thumbAndMainContent = (
        <>
            <Thumbnail
                dataTestId={dataTestId ? `${dataTestId}-logo` : 'instrument-row-compact--logo'}
                symbol={instrument.symbol}
                path={instrument.logos.micro ? `${resourcePath}${instrument.logos.micro}` : undefined}
                width="30px"
                height="30px"
                noBorder
            />
            <div className={styles.mainContent}>
                <h3>{mainTitleOverride ? mainTitleOverride : instrument.name}</h3>
                {subTitleOverride
                    ? subTitleOverride
                    : instrument.instrumentType !== 'mf' && (
                          <div>
                              {instrument.symbol} <Delimiter /> {instrument.exchange}
                          </div>
                      )}
            </div>
        </>
    )

    return (
        <div
            className={cn(
                styles.instrumentRowCompact,
                {
                    [styles.instrumentRowBorderTop]: rowTopBorder,
                    [styles.withRowLink]: rowLinkTarget,
                },
                additionalClassName,
            )}
            data-testid={dataTestId}
        >
            {rowLinkTarget ? (
                <button
                    className={accessibility.button}
                    type="button"
                    onClick={() => {
                        onLinkNavigated?.()
                        navigate(rowLinkTarget)
                    }}
                >
                    {thumbAndMainContent}
                </button>
            ) : (
                thumbAndMainContent
            )}
            <div className={styles.rightSlot}>{rightSlot}</div>
        </div>
    )
}

export default InstrumentRowCompact
