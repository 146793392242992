import {Button} from '@design-system/button'
import {DockableModal} from '@design-system/dockable-modal'
import {LongTextInput, LongTextInputProps} from '@design-system/long-text-input'
import cn from 'classnames'
import {withFormik} from 'formik'
import React from 'react'
import * as api from '~/api/retail'
import {accessibility, spacing} from '~/global/scss/helpers'
import {Close} from '~/global/widgets/OLD_icons'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {validate} from '~/global/widgets/form-controls'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/identity/actions'
import {actingAsID} from '~/store/identity/selectors'
import styles from './Feedback.scss'

export const useFeedbackSubmissions = () => {
    const state = useAppSelector(state => state.identity.feedback)
    const dispatch = useAppDispatch()
    React.useEffect(() => {
        if (state.loadingState === 'uninitialised') {
            dispatch(actions.FetchFeedbackSubmissions())
        }
    }, [state.loadingState])

    return {submissions: state.submittedActionIdentifiers, isLoading: state.loadingState !== 'ready'}
}

interface Props {
    actionIdentifier: string
    category: string
    onDismiss?: () => void
    title?: string
    commentLabel?: string
    askForComment?: 'always' | 'optional'
}

interface FormValues {
    feedback: string
}

interface FormProps {
    submitFeedback: (comment?: string, setSubmitting?: (submitting: boolean) => void) => void
}

const THUMBS_UP_CHARACTER_CODEPOINT = 128077
const THUMBS_DOWN_CHARACTER_CODEPOINT = 128078
const MAX_LENGTH = 3000

const TellMeMoreForm = withFormik<FormProps, FormValues & LongTextInputProps>({
    handleSubmit: ({feedback}, {setSubmitting, props: {submitFeedback}}) => {
        setSubmitting(true)
        submitFeedback(feedback, setSubmitting)
    },
    validate: validate.generate<FormValues>({
        feedback: [
            validate.required(),
            validate.maxLength(MAX_LENGTH, `You've exceeded the maximum character length of ${MAX_LENGTH} characters`),
        ],
    }),
})(({handleSubmit, handleChange, isSubmitting, isValid, submitFeedback}, props) => {
    return (
        <form onSubmit={handleSubmit}>
            <LongTextInput
                {...props}
                additionalClassName={styles.feedbackContainer}
                name="feedback"
                dataTestId="long-text-input--feedback"
                onChange={handleChange}
                maxLength={MAX_LENGTH}
                maxLengthAlertThreshold={2500}
            />
            <div className={styles.buttonRow}>
                <Button
                    dataTestId="button--cancel-feedback"
                    label="Cancel"
                    type="secondary"
                    onClick={() => submitFeedback()}
                />
                <Button
                    dataTestId="button--submit-feedback"
                    label="Submit"
                    isSubmit
                    processing={isSubmitting}
                    disabled={!isValid}
                />
            </div>
        </form>
    )
})

export const Feedback: React.FunctionComponent<Props> = ({
    actionIdentifier,
    category,
    onDismiss,
    title,
    askForComment = 'optional',
    commentLabel,
}) => {
    const [hasResponded, setHasResponded] = React.useState<boolean>(false)
    const [response, setResponse] = React.useState<boolean>()
    const [showMore, setShowMore] = React.useState<boolean>(false)
    const [showMorePrompt, setShowMorePrompt] = React.useState<boolean>(askForComment === 'optional')
    const [feedbackId, setFeedbackId] = React.useState<string | undefined>()
    const actingAsId = useAppSelector(actingAsID)

    const {submissions, isLoading} = useFeedbackSubmissions()
    if (isLoading || submissions.includes(actionIdentifier)) {
        return null
    }

    const questionText = title || 'Is this page helpful?'
    const responseText = 'Thanks for your feedback'

    const handleClick = (isPositive: boolean) => {
        setResponse(isPositive)
        setHasResponded(true)
        submitFeedback(isPositive)
        setShowMore(askForComment === 'always')
    }

    const submitFeedback = async (
        thumbs = response,
        comment?: string,
        setSubmitting?: (submitting: boolean) => void,
    ) => {
        const res = await api.post('feedback/create', {
            id: feedbackId,
            category,
            action_identifier: actionIdentifier,
            thumbs: thumbs ? 'POSITIVE' : 'NEGATIVE',
            comment,
            acting_as_id: actingAsId,
        })

        if (res.type === 'create_feedback') {
            setFeedbackId(res.feedback_id)
        }

        if (comment) {
            setShowMorePrompt(false)
            // Only dismiss if submitting a comment (not just a thumbs up or down)
            if (onDismiss) {
                onDismiss()
            }
        }

        if (setSubmitting) {
            setSubmitting(false)
        }
    }

    return (
        <>
            <div className={styles.feedback}>
                {onDismiss && (
                    <button
                        className={cn(styles.dismiss, accessibility.button)}
                        type="button"
                        onClick={e => {
                            e.stopPropagation()
                            onDismiss()
                        }}
                    >
                        <Close colour="grey-1" />
                    </button>
                )}
                <p className={cn(hasResponded && styles.hide)}>{questionText}</p>
                <div className={styles.icons}>
                    <button
                        type="button"
                        className={cn(
                            accessibility.button,
                            styles.icon,
                            hasResponded && !response && styles.invisible,
                            hasResponded && response && styles.animateIcon,
                        )}
                        aria-label="Respond Yes"
                        onClick={() => handleClick(true)}
                    >
                        <span role="img">{String.fromCodePoint(THUMBS_UP_CHARACTER_CODEPOINT)}</span>
                    </button>
                    <button
                        type="button"
                        className={cn(
                            accessibility.button,
                            styles.icon,
                            hasResponded && response && styles.invisible,
                            hasResponded && !response && styles.animateIcon,
                        )}
                        aria-label="Respond No"
                        onClick={() => handleClick(false)}
                    >
                        <span role="img">{String.fromCodePoint(THUMBS_DOWN_CHARACTER_CODEPOINT)}</span>
                    </button>
                </div>
                <div className={cn(styles.response, hasResponded && styles.animateResponse)}>
                    <p>{responseText}</p>
                    {showMorePrompt && <ButtonAsLink onClick={() => setShowMore(true)}>Tell us more</ButtonAsLink>}
                </div>
            </div>

            <DockableModal
                dataTestId="feedback-modal"
                isOpen={showMore}
                setIsOpen={setShowMore}
                title={
                    commentLabel ||
                    `Tell us why you gave a '${
                        response
                            ? String.fromCodePoint(THUMBS_UP_CHARACTER_CODEPOINT)
                            : String.fromCodePoint(THUMBS_DOWN_CHARACTER_CODEPOINT)
                    }'`
                }
                content={
                    <TellMeMoreForm
                        submitFeedback={(comment?: string) => {
                            submitFeedback(response, comment)
                            setShowMore(false)
                        }}
                    />
                }
                customZIndex={1051} // same as zindex(detail-modal)
            />
        </>
    )
}

export const FeedbackWithIntroText: React.FC<Props & {introText: string}> = props => {
    const [clicked, setClicked] = React.useState(false)

    const {introText, ...feedbackProps} = props

    const {submissions, isLoading} = useFeedbackSubmissions()
    if (isLoading || submissions.includes(props.actionIdentifier)) {
        return null
    }

    return (
        <div className={styles.feedbackWithIntro}>
            {clicked ? (
                <Feedback {...feedbackProps} />
            ) : (
                <div>
                    <p className={spacing.spaceBelow4}>{introText}</p>
                    <ButtonAsLink onClick={() => setClicked(true)}>Provide feedback</ButtonAsLink>
                </div>
            )}
        </div>
    )
}

export default Feedback
