import {Button} from '@design-system/button'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {dateFormatNoDay} from '~/global/utils/format-date/formatDate'
import {
    showCalendarMonthHeading,
    showThisMonthHeading,
    showThisWeekHeading,
} from '~/global/utils/time-unit-headings/timeUnitHeadings'
import Chips from '~/global/widgets/chips/Chips'
import {Loading} from '~/global/widgets/loading/Loading'
import actions from '~/store/accounting/actions'
import {Transaction, PaymentRequest, WalletTransactionsFilter} from '~/store/accounting/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import WalletTransaction, {WalletPaymentRequest} from './WalletTransaction'
import styles from './WalletTransactionHistory.scss'

type WalletLine = Transaction | PaymentRequest

const WalletTransactionHistory: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const lastTransactionCard = React.useRef<HTMLButtonElement>(null)

    const fetchingOlderTransactions = useAppSelector(s => s.accounting.walletTransactionsFetchingOlder)
    const hasOlderTransactions = useAppSelector(s => s.accounting.walletTransactionsHasOlder)
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const transactions = useAppSelector(s => s.accounting.walletTransactions)
    const paymentRequests = useAppSelector(s => s.accounting.walletPaymentRequests)
    const walletTransactionsCurrentFilter = useAppSelector(s => s.accounting.walletTransactionsCurrentFilter)
    const walletTransactionsFilterOptions = useAppSelector(s => s.accounting.walletTransactionsFilterOptions)
    const walletTransactionsLoadingState = useAppSelector(s => s.accounting.walletTransactionsLoadingState)

    const lines = React.useMemo(() => {
        if (walletTransactionsLoadingState !== 'ready') {
            return transactions
        }

        if (['all', 'money_in'].includes(walletTransactionsCurrentFilter)) {
            // Prepend in-flight payment requests to the top of transaction history.
            // These are payment requests that are on the way from a customer's bank.
            return (paymentRequests as WalletLine[]).concat(transactions)
        }

        return transactions
    }, [transactions, paymentRequests, walletTransactionsCurrentFilter, walletTransactionsLoadingState])

    // BEGIN filter data ------------------------------------------------------------------------------------------------

    const filterDisplayNameToParamMapping: {[key: string]: WalletTransactionsFilter} = {
        All: 'all',
        Buys: 'buy',
        Sells: 'sell',
        Dividends: 'dividend',
        'Money in': 'money_in',
        'Money out': 'money_out',
        'Money exchange': 'money_exchange',
        // TODO(Stuart): re-enable when we have support for donations in mobile wallet transaction display
        // Donations: 'donation',
    }
    type FilterDisplayName = keyof typeof filterDisplayNameToParamMapping & string
    const filterOptions: WalletTransactionsFilter[] = ['all', ...walletTransactionsFilterOptions]

    /*
    We only want to display filters for transaction types that are present in the customer's history.
    The list of filter options is provided via the wallet transaction history API response, and stored in state as
    accounting.walletTransactionsFilterOptions.
    These filter options are stored as constants - see the WalletTransactionsFilter type -
    but for the chips we need nicer, human-readable strings.
    filterNames maps the filter options constants to their human-readable strings.
    */
    const filterNames = Object.entries(filterDisplayNameToParamMapping)
        .filter(([displayName, paramName]) => {
            if (filterOptions.includes(paramName)) {
                return [displayName, paramName]
            }
        })
        .map(([displayName, _]) => {
            return displayName
        })

    // END filter data --------------------------------------------------------------------------------------------------

    // BEGIN handler functions ------------------------------------------------------------------------------------------

    const handleFetchTransactions = () => {
        if (lastTransactionCard && lastTransactionCard.current) {
            lastTransactionCard.current.focus()
        }

        dispatch(actions.FetchOldTransactions())
    }

    const setSelectedFilter = (newFilter: FilterDisplayName) => {
        const paramName: WalletTransactionsFilter = filterDisplayNameToParamMapping[newFilter]

        if (newFilter !== walletTransactionsCurrentFilter) {
            dispatch(actions.FetchTransactionHistory(paramName))
        }
    }

    // END handler functions --------------------------------------------------------------------------------------------

    // For: displaying time unit headings in the Activity feed. Both let-variables are used in the loop
    const currentTimestamp = DateTime.local()
    let previousTransactionTimestamp = DateTime.local()
    let monthTitleDisplayed = false

    return (
        <>
            {/* Heading */}
            {(lines.length > 0 || walletTransactionsLoadingState === 'loading') && (
                <h2 className={cn(styles.transactionsHeading, spacing.spaceAbove16)}>Transaction history</h2>
            )}

            {/* Filters */}
            {walletTransactionsFilterOptions.length > 0 && (
                <div className={cn(spacing.spaceAbove8, spacing.spaceBelow8)}>
                    <Chips
                        options={filterNames}
                        onChipClick={selected => setSelectedFilter(selected)}
                        selected={Object.keys(filterDisplayNameToParamMapping).filter(
                            key => filterDisplayNameToParamMapping[key] === walletTransactionsCurrentFilter,
                        )}
                        isInlineDisplay
                    />
                </div>
            )}

            {/* Loading filtered results */}
            {walletTransactionsLoadingState === 'loading' && (
                <div className={styles.emptySection}>
                    <Loading />
                </div>
            )}

            {/* List of transactions */}
            {lines.map((txn, index) => {
                const isLastResult = index === lines.length - 1

                const transactionComponent =
                    'type' in txn && txn.type === 'payment_request' ? (
                        <WalletPaymentRequest paymentRequest={txn} />
                    ) : (
                        <WalletTransaction
                            ref={isLastResult ? lastTransactionCard : undefined}
                            txn={txn as Transaction}
                            instrumentsById={instrumentsById}
                        />
                    )

                // 'Today' heading
                if (index === 0 && txn.timestamp.toISODate() === currentTimestamp.toISODate()) {
                    return (
                        <div key="past-investing-activity--today">
                            <p className={styles.timeUnitHeading}>Today</p>
                            {transactionComponent}
                        </div>
                    )
                }

                // 'This week' heading
                if (showThisWeekHeading(txn.timestamp, previousTransactionTimestamp)) {
                    previousTransactionTimestamp = txn.timestamp

                    return (
                        <div key="past-investing-activity--this-week">
                            <p className={styles.timeUnitHeading}>This week</p>
                            {transactionComponent}
                        </div>
                    )
                }

                // 'This month' heading
                if (showThisMonthHeading(txn.timestamp, previousTransactionTimestamp, monthTitleDisplayed)) {
                    monthTitleDisplayed = true
                    previousTransactionTimestamp = txn.timestamp

                    return (
                        <div key="past-investing-activity--this-month">
                            <p className={styles.timeUnitHeading}>This month</p>
                            {transactionComponent}
                        </div>
                    )
                }

                // Month headings (e.g. 'July 2022')
                if (showCalendarMonthHeading(txn.timestamp, previousTransactionTimestamp)) {
                    monthTitleDisplayed = true
                    previousTransactionTimestamp = txn.timestamp

                    return (
                        <div key={`past-investing-activity--${txn.timestamp.year}-${txn.timestamp.month}`}>
                            <p className={styles.timeUnitHeading}>{txn.timestamp.toFormat(dateFormatNoDay)}</p>
                            {transactionComponent}
                        </div>
                    )
                }

                previousTransactionTimestamp = txn.timestamp

                return <React.Fragment key={lines.indexOf(txn)}>{transactionComponent}</React.Fragment>
            })}

            {/* 'Load more' button */}
            {hasOlderTransactions && (
                <Button
                    dataTestId="button--load-older-transactions"
                    label="Load older transactions"
                    onClick={handleFetchTransactions}
                    processing={fetchingOlderTransactions}
                    additionalClassName={spacing.spaceAbove24}
                />
            )}
        </>
    )
}

export default WalletTransactionHistory
