import {Button} from '@design-system/button'
import React from 'react'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import {CheckSingle} from '~/global/widgets/OLD_icons'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import PDSDisplay from '~/global/widgets/pds-display/PDSDisplay'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {isNavigationDirective} from '~/migrate-react-router'
import {handleProcessResponse} from '~/sections/invest/sections/auto-invest/handleProcessResponse'
import actions, {AutoinvestProcessNextStep} from '~/store/autoinvest/actions'
import {StagedOrder} from '~/store/autoinvest/types'
import {connect} from '~/store/connect'
import styles from './PDS.scss'

const getBackPath = (stagedOrder: StagedOrder | undefined, profileUrl: ReturnType<typeof useProfileUrl>): string => {
    if (!stagedOrder) {
        return profileUrl('auto-invest')
    }

    if (stagedOrder.order && 'premadeOrder' in stagedOrder.order) {
        return profileUrl('auto-invest/:premadeSlug/how-much', {premadeSlug: stagedOrder.order.premadeOrder.slug})
    }

    return profileUrl('auto-invest/diy/percentage')
}

interface PDSState {
    submittingOrder: boolean
    currentMode: 'intro' | 'view' | 'done'
}

class PDS extends React.PureComponent<PDSProps & WithRouterProps, PDSState> {
    static contextType = NotificationContext
    context!: React.ContextType<typeof NotificationContext>

    state: PDSState = {
        submittingOrder: false,
        currentMode: 'intro',
    }

    private displayPDS = () => {
        this.setState({currentMode: 'view'})
    }

    private submitPDS = async () => {
        const {
            submitPDSAgreement,
            processStagedOrder,
            router: {navigate},
        } = this.props
        const pdsesLeft = this.props.stagedOrder ? this.props.stagedOrder.pdsesToRead : null

        if (!pdsesLeft) {
            throw new Error('No PDSes left to view')
        }

        const pdsData = pdsesLeft[0]
        const fundIds = pdsData.funds.map(fund => fund.fund_id)

        const error = await submitPDSAgreement(pdsData.pds_data.pds_file_revision_id, fundIds)

        if (isNavigationDirective(error)) {
            error.execute(navigate, this.props.profileUrl)
        } else if (error) {
            this.context.showModalError({message: error})
            return Error('Unable to submit PDS agreement')
        }

        // If we're on the last pds, try to submit order
        if (pdsesLeft.length === 1) {
            this.setState({submittingOrder: true}, async () => {
                await handleProcessResponse(processStagedOrder(), navigate, this.props.profileUrl, this.context)
                this.setState({submittingOrder: false, currentMode: 'done'})
            })
        } else {
            this.setState({currentMode: 'intro'})
        }
    }

    private renderPDSIntro = () => {
        const {stagedOrder} = this.props
        const pdsToView = stagedOrder && stagedOrder.pdsesToRead ? stagedOrder.pdsesToRead[0] : null

        if (!pdsToView) {
            throw new Error('No PDS to view')
        }

        return (
            <>
                <Toolbar dataTestId="tool-bar--pds" leftButton="back" title="Product Disclosure Statements (PDS)" />
                <Page overrideDefaultTopPadding="withToolbarTitle">
                    <p>
                        Before investing you’ll need to read and accept the{' '}
                        <b>{pdsToView.pds_data.document_title} PDS</b>. This document contains all the important
                        information to help you decide whether to invest in the following fund
                        {pdsToView.funds.length > 1 ? 's' : ''}:
                    </p>
                    <ul className={styles.fundList}>
                        {pdsToView.funds.map((fund, key) => {
                            return (
                                <li key={key}>
                                    <CheckSingle colour="grey-3" /> {fund.name}
                                </li>
                            )
                        })}
                    </ul>
                    <Button dataTestId="button--view-pds" label="View the PDS" pageButton onClick={this.displayPDS} />
                </Page>
            </>
        )
    }

    private renderPDSDisplay = () => {
        const backPath = getBackPath(this.props.stagedOrder, this.props.profileUrl)
        const {
            stagedOrder,
            router: {navigate},
        } = this.props
        const pdsToView = stagedOrder && stagedOrder.pdsesToRead ? stagedOrder.pdsesToRead[0] : null

        if (!pdsToView) {
            throw new Error('No PDSes left to view')
        }

        return (
            <Page withoutDefaultPadding>
                <PageBack
                    onClick={() => {
                        navigate(backPath, {replace: true})
                    }}
                />
                <PDSDisplay pdsData={pdsToView.pds_data} submitPDSAgreement={this.submitPDS} />
            </Page>
        )
    }

    public render() {
        const {currentMode, submittingOrder} = this.state
        const {stagedOrder} = this.props

        // If we're submittingOrder (PDSes are agreed and we're waiting for stagedOrder to be
        // processed)  or 'done' (we're waiting for animation to load and display and redirect
        // to be performed) we show loading icon because PDSes have been removed from state and
        // there's nothing else to display.
        // This will be visible for users with slow connections
        if (submittingOrder || currentMode === 'done') {
            return <Loading isPineapple />
        }

        if (!stagedOrder || (stagedOrder && stagedOrder.pdsesToRead && stagedOrder.pdsesToRead.length === 0)) {
            return <WeSlippedUp />
        }

        if (currentMode === 'view') {
            return this.renderPDSDisplay()
        }

        if (currentMode === 'intro') {
            return this.renderPDSIntro()
        }
    }
}

interface OwnProps {
    profileUrl: ReturnType<typeof useProfileUrl>
}

interface StoreProps {
    stagedOrder?: StagedOrder
}

interface DispatchProps {
    submitPDSAgreement: (pdsFileRevisionId: string, fundIds: string[]) => Promise<string | null>
    processStagedOrder: () => Promise<AutoinvestProcessNextStep>
}

type PDSProps = StoreProps & DispatchProps & OwnProps

const ConnectedPDS = connect<StoreProps, DispatchProps, OwnProps>(
    state => ({
        stagedOrder: state.autoinvest.stagedOrder,
    }),
    dispatch => ({
        submitPDSAgreement: async (pdsFileRevisionId, fundIds) => {
            return dispatch(actions.SubmitPDSAgreement(pdsFileRevisionId, fundIds))
        },
        processStagedOrder: () => dispatch(actions.ProcessStagedOrder()),
    }),
)(withRouter(PDS))

export default () => {
    const profileUrl = useProfileUrl()
    return <ConnectedPDS profileUrl={profileUrl} />
}
