import {Button} from '@design-system/button'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {formatNumber} from '~/global/utils/format-number/formatNumber'
import {isLoadingOrUninitialised, isUninitialised} from '~/global/utils/is-loading/isLoading'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {ErrorBox, Radio} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {Navigate, useNavigate} from '~/migrate-react-router'
import roundupStyle from '~/sections/OLD_wallet/widgets/roundup-status/RoundupStatus.scss'
import {BASIQ_DDRSA_URL, MOONOOVA_DDRSA_VERSION} from '~/sections/invest/sections/bank-linking/constants/ddrsa'
import styles from '~/sections/invest/sections/bank-linking/pages/view/BankLinkingView.scss'
import {UnlinkBankConfirmModal} from '~/sections/invest/sections/bank-linking/widgets/unlink-bank-confirm-modal/UnlinkBankConfirmModal'
import actions from '~/store/bankLinking/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'

export interface OnNextProps {
    onBack?: () => void
}

export const BankLinkingEdit: React.FunctionComponent<OnNextProps> = ({onBack}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const isLinked = useAppSelector(s => s.bankLinking.isBankLinked)
    const [unlinkModalOpen, setUnlinkModalOpen] = React.useState(false)
    const hasMonoovaFlag = useAppSelector(s => s.identity.flags.monoova)
    const bankLinkingLoadingState = useAppSelector(s => s.bankLinking.bankLinkingLoadingState)
    const basiqAccountsLoadingState = useAppSelector(s => s.bankLinking.basiqAccountsLoadingState)
    const bankLinkingDebitAccount = useAppSelector(s => s.bankLinking.roundupsDebitAccount)
    const accounts = useAppSelector(s => s.bankLinking.basiqAccountsResponse?.accounts)
    const [debitAccountSelection, changeDebitAccountSelection] = React.useState<string | undefined>(
        bankLinkingDebitAccount?.basiqAccountId ?? undefined,
    )
    const [submitting, setSubmitting] = React.useState<boolean>(false)
    const [error, setError] = React.useState<string | undefined>()

    React.useEffect(() => {
        if (isUninitialised(bankLinkingLoadingState)) {
            dispatch(actions.FetchBankLinkingStatus())
        }

        if (isUninitialised(basiqAccountsLoadingState)) {
            dispatch(actions.FetchBasiqAccounts())
        }
    }, [])

    if (isLoadingOrUninitialised(bankLinkingLoadingState) || !accounts) {
        return (
            <>
                <Toolbar leftButton="back" dataTestId="toolbar--banklinking-select-debit-account" />
                <Page>
                    <Loading />
                </Page>
            </>
        )
    }

    if (!isLinked) {
        return <Navigate to={profileUrl('settings/bank-accounts-cards')} />
    }

    const availableDebitAccounts = accounts?.filter(account => account.valid_for_debiting)

    const debitAccountChoices = availableDebitAccounts.map(account => {
        return {
            value: account.basiq_account_id,
            helpText: `Balance: $${formatNumber({number: account.balance})}`,
            label: account.account_name,
        }
    })

    const onSubmit = async () => {
        setError(undefined)
        if (debitAccountSelection !== bankLinkingDebitAccount?.basiqAccountId) {
            setSubmitting(true)
            let response

            if (hasMonoovaFlag) {
                response = await dispatch(
                    actions.UpdateDebitBankAccount(debitAccountSelection!, MOONOOVA_DDRSA_VERSION),
                )
            } else {
                response = await dispatch(actions.UpdateDebitBankAccount(debitAccountSelection!, BASIQ_DDRSA_URL))
            }
            if (response.type === 'error') {
                setSubmitting(false)
                setError(response.message)
                return
            }
        }
        Toast('We’ve sent the request to change the account money is sent from. This may take up to 5 days.')
        if (onBack) {
            onBack()
            return
        }

        navigate(profileUrl('settings/bank-accounts-cards/bank-linking/view'))
    }

    const onCancel = () => {
        if (onBack) {
            onBack()
            return
        }
        navigate(profileUrl('settings/bank-accounts-cards/bank-linking/view'))
    }

    return (
        <>
            <Toolbar leftButton="back" dataTestId="toolbar--banklinking-edit-debit-account" />
            <Page>
                <h1 className={spacing.spaceBelow16}>Choose account to send money from</h1>
                <p className={spacing.spaceBelow20}>
                    If you change your direct debit account, we’ll pass that information to our payment services
                    provider to reflect your new account details. It may take up to 5 business days for these changes to
                    take affect.
                </p>
                <h2 className={spacing.spaceBelow16}>
                    Choose {bankLinkingDebitAccount?.bankName} account for sending money
                </h2>
                {availableDebitAccounts.length > 0 ? (
                    <Radio
                        onChange={e => changeDebitAccountSelection(e.target.value as string)}
                        value={debitAccountSelection || bankLinkingDebitAccount?.basiqAccountId}
                        name="selectDebitAccount"
                        isTouched
                        dataTestId="bank-linking-edit-debit-account"
                        choices={debitAccountChoices}
                    />
                ) : (
                    <AlertCard className={roundupStyle.alert} type="warning">
                        <p>
                            unfortunately we couldn’t find any accounts to either track or debit for Round-ups. This may
                            be due to:
                        </p>
                        <ul>
                            <li>Only holding a credit card account.</li>
                            <li>
                                Only holding accounts you can’t draw on such as mortgage, term deposit or savings
                                accounts.
                            </li>
                        </ul>
                        <ButtonAsLink onClick={() => setUnlinkModalOpen(true)}>Unlink with bank</ButtonAsLink>
                        <UnlinkBankConfirmModal
                            isOpen={unlinkModalOpen}
                            setIsOpen={setUnlinkModalOpen}
                            handleUnlinkBank={() => {
                                setUnlinkModalOpen(false)
                                dispatch(actions.UnlinkBank())
                                navigate(-2)
                            }}
                        />
                    </AlertCard>
                )}
                <ErrorBox message={error} />
            </Page>
            <ActionBar className={styles.actions}>
                <Button dataTestId="button--go-back" type="secondary" label="Cancel" onClick={onCancel} />
                <Button
                    dataTestId="button--save-debit-account"
                    label="Confirm"
                    processing={submitting}
                    onClick={onSubmit}
                />
            </ActionBar>
        </>
    )
}
