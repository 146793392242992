import React from 'react'
import {spacing} from '~/global/scss/helpers'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import CollectionNotice from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/CollectionNotice'

const LegalCollectionNotice: React.FunctionComponent<{}> = () => (
    <>
        <Toolbar dataTestId="toolbar--collection-notice" leftButton="back" title="Legal" />
        <Page overrideDefaultTopPadding="withToolbarTitle">
            <h2 className={spacing.spaceBelow16}>Collection Notice</h2>
            <CollectionNotice />
        </Page>
    </>
)

export default LegalCollectionNotice
