import {Button} from '@design-system/button'
import React, {useState} from 'react'
import {Navigate, useNavigate} from 'react-router'
import {useRetailPost} from '~/api/query/retail'
import confirmStyles from '~/global/scss/reused-styles/confirm.scss'
import {useActor} from '~/global/state-hooks/retail/useActor'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import ExchangeMoneyFees from '~/global/widgets/help-modals/ExchangeMoneyFees'
import {FeeValue, DollarValue, ExchangeRateValue} from '~/global/widgets/number-elements/NumberElements'
import {OrderConfirmation} from '~/global/widgets/order-confirmation/OrderConfirmation'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {FormValues} from '~/sections/wallet/pages/exchange-money/ExchangeMoney'
import {useHistoryState, useWalletPortfolio} from '~/sections/wallet/state/local'

export const ExchangeMoneyConfirm: React.FunctionComponent<{}> = () => {
    const actor = useActor()
    const walletPortfolio = useWalletPortfolio()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const [fxOrder, setFxOrder] = useHistoryState<FormValues | undefined>('fxOrder', undefined)
    const [submitError, setSubmitError] = useState(false)

    const postFxOrder = useRetailPost({
        path: 'wallet/:portfolio_id/fx-order',
        pathParams: {portfolio_id: walletPortfolio.id},
    })

    if (!fxOrder) {
        // If we don't have an order in state, then just send the user to the exchange money form
        return (
            <Navigate
                to={profileUrl('wallet/:portfolioId/exchange-money', {portfolioId: walletPortfolio.id})}
                replace
            />
        )
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--exchange-money-confirm"
                leftButton="back"
                hideIntercom
                title="Confirm your exchange"
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <OrderConfirmation
                    title={`${fxOrder.sourceCurrency.toLocaleUpperCase()} to ${fxOrder.targetCurrency.toLocaleUpperCase()}`}
                    items={[
                        {
                            description: 'From',
                            value: (
                                <DollarValue value={fxOrder.sourceAmount} currency={fxOrder.sourceCurrency} roundDown />
                            ),
                        },
                        {
                            description: 'Exchange rate',
                            value: (
                                <ExchangeRateValue
                                    sourceCurrency={fxOrder.sourceCurrency}
                                    targetCurrency={fxOrder.targetCurrency}
                                    exchangeRate={parseFloat(fxOrder.quotedRate)}
                                />
                            ),
                        },
                        {
                            description: (
                                <>
                                    Exchange fee <ExchangeMoneyFees />
                                </>
                            ),
                            value: <FeeValue value={fxOrder.sourceFee} currency={fxOrder.sourceCurrency} />,
                        },
                        {
                            description: `${fxOrder.sourceCurrency.toLocaleUpperCase()} to exchange`,
                            value: <DollarValue value={fxOrder.netSourceAmount} currency={fxOrder.sourceCurrency} />,
                        },
                    ]}
                    total={{
                        description: `${fxOrder.targetCurrency.toLocaleUpperCase()} to receive`,
                        value: <DollarValue value={fxOrder.targetAmount} currency={fxOrder.targetCurrency} roundDown />,
                    }}
                />
                {submitError && (
                    <p className={confirmStyles.submitError}>We couldn’t complete the order, please try again.</p>
                )}
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--confirm-exchange"
                    label="Confirm exchange"
                    processing={postFxOrder.isLoading}
                    onClick={() =>
                        postFxOrder.mutate(
                            {
                                buy_or_sell: fxOrder.buyOrSell,
                                source_amount: fxOrder.sourceAmount,
                                source_currency: fxOrder.sourceCurrency,
                                target_amount: fxOrder.targetAmount,
                                target_currency: fxOrder.targetCurrency,
                                quoted_rate: fxOrder.quotedRate,
                                source_fee: fxOrder.sourceFee,
                            },
                            {
                                onSuccess: response => {
                                    switch (response.type) {
                                        case 'wallet_fx_order_issue':
                                            switch (response.code) {
                                                case 'rate_threshold_exceeded':
                                                    // This is the only case we handle in a very specific way (by taking the user back to
                                                    // the previouis page with a notice saying the rate has changed)
                                                    navigate(
                                                        profileUrl('wallet/:portfolioId/exchange-money', {
                                                            portfolioId: walletPortfolio.id,
                                                        }),
                                                        {
                                                            state: {
                                                                fxOrder,
                                                                showRateWarning: true,
                                                            },
                                                            replace: true,
                                                        },
                                                    )

                                                    break
                                                case 'order_details_invalid':
                                                case 'source_wallet_exceeded':
                                                    setSubmitError(true)
                                                    break
                                                default:
                                                    assertNever(response.code)
                                            }
                                            break
                                        case 'wallet_fx_order_success':
                                            // First we ensure they can't accidentally resubmit this order
                                            setFxOrder(undefined)
                                            Toast(
                                                `Your money has been exchanged. ${
                                                    // TODO(SM) - this should use actor.jurisdiction or similar rather than currency like this
                                                    actor.default_display_currency === 'nzd' ? 'Mīharo!' : 'Amazing!'
                                                }`,
                                            )
                                            navigate(
                                                profileUrl('wallet/:portfolioId', {portfolioId: walletPortfolio.id}),
                                            )
                                            break
                                        default:
                                            assertNever(response)
                                    }
                                },
                                onError: () => {
                                    setSubmitError(true)
                                },
                            },
                        )
                    }
                />
            </ActionBar>
        </>
    )
}
