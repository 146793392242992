/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface RecordIDList
 */
export interface RecordIDList {
    /**
     *
     * @type {Array<string>}
     * @memberof RecordIDList
     */
    record_uuids: Array<string>
    /**
     *
     * @type {boolean}
     * @memberof RecordIDList
     */
    queue_recalc?: boolean
    /**
     *
     * @type {boolean}
     * @memberof RecordIDList
     */
    all_or_nothing?: boolean
}

export function RecordIDListFromJSON(json: any): RecordIDList {
    return RecordIDListFromJSONTyped(json, false)
}

export function RecordIDListFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordIDList {
    if (json === undefined || json === null) {
        return json
    }
    return {
        record_uuids: json['record_uuids'],
        queue_recalc: !exists(json, 'queue_recalc') ? undefined : json['queue_recalc'],
        all_or_nothing: !exists(json, 'all_or_nothing') ? undefined : json['all_or_nothing'],
    }
}

export function RecordIDListToJSON(value?: RecordIDList | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        record_uuids: value.record_uuids,
        queue_recalc: value.queue_recalc,
        all_or_nothing: value.all_or_nothing,
    }
}
