import {isNotionalStatusInstrument} from '~/global/utils/is-instrument-eligible-for-extended-hours/isInstrumentEligibleForExtendedHours'
import {Instrument} from '~/store/instrument/types'
import {StagedSellOrder, StagedBuyOrder} from '~/store/order/types'

export const isOrderTypeEligibleForExtendedHours = (
    orderType: StagedSellOrder['orderType'] | StagedBuyOrder['orderType'],
    instrument: Instrument,
) => {
    const instrumentHasNotionalStatus = isNotionalStatusInstrument(instrument)
    if (instrumentHasNotionalStatus) {
        // Instruments with an Extended Hours Notional Status of active or close only may also place market orders in EH.
        const allowedNotionalOrderTypes: (StagedSellOrder['orderType'] | StagedBuyOrder['orderType'])[] = [
            'dollar_market',
            'share_limit',
            'share_market',
        ]
        return allowedNotionalOrderTypes.includes(orderType)
    } else {
        const allowedOrderTypes: (StagedSellOrder['orderType'] | StagedBuyOrder['orderType'])[] = ['share_limit']

        return allowedOrderTypes.includes(orderType)
    }
}
