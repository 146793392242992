import {ModalLink} from '@design-system/modal'
import React from 'react'
import {isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {Instrument} from '~/store/instrument/types'

export const OrderTypesModal: React.FC<{instrument: Instrument}> = ({instrument}) => {
    const profileUrl = useProfileUrl()
    const title = 'Types of orders'
    return (
        <ModalLink
            dataTestId="modal-link--market-limit-orders"
            label={title}
            asIcon
            modalTitle={title}
            primaryButton={{label: 'Got it'}}
            helpIconSize={16}
            helpIconColour="IconSubtle"
        >
            <h3>Market orders</h3>
            <p>
                Market orders are typically used when investors want an order to be processed quickly and are willing to
                let the market decide the price.
            </p>
            <p>
                When the market is open and there are{' '}
                {instrument
                    ? shareLabel({
                          instrument,
                          isPlural: true,
                      })
                    : 'shares'}{' '}
                available, your order will get placed and will almost always get filled, in line with our{' '}
                <a href={profileUrl('legal/best-price')} target="_blank" rel="noopener">
                    best price policy
                </a>
                .
            </p>
            <h3>Limit orders</h3>
            <p>
                Limit orders give you more control to buy or sell{' '}
                {instrument ? shareLabel({instrument, isPlural: true}) : 'shares'} at a specific price, or better in
                line with our{' '}
                <a href={profileUrl('legal/best-price')} target="_blank" rel="noopener">
                    best price policy
                </a>
                .
            </p>
            <ul>
                <li>Limit buy order—set the highest price you’re willing to pay per share.</li>
                <li>Limit sell order—set the lowest price you want to sell at per share.</li>
            </ul>
            {isOnMainUsExchange(instrument) && (
                <>
                    <h3>Trigger orders</h3>
                    <p>
                        Trigger orders let you set a share price that, when reached, automatically triggers an order to
                        be sent to market.
                    </p>
                    <ul>
                        <li>
                            Trigger buy order—automatically place a market buy order when a share price rises to a price
                            you set.
                        </li>
                        <li>
                            Stop loss order—automatically place a market sell order when a share price drops to a price
                            you set.
                        </li>
                    </ul>
                    <p>Once the order is sent to market, usual market order processing applies.</p>
                    <p>
                        To learn more about our different order types, check out our{' '}
                        <HelpCentreLink
                            auArticle="5139127-differences-between-a-market-and-limit-order"
                            nzArticle="3509885-what-s-the-difference-between-a-market-order-and-a-limit-order"
                        />
                        .
                    </p>
                </>
            )}
        </ModalLink>
    )
}
