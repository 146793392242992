import cn from 'classnames'
import React from 'react'
import {Link} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import recordStyles from '~/global/widgets/instrument-activity/Record.scss'
import {RecordRow} from '~/global/widgets/instrument-activity/common'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {useRakaiaReadPortfolio} from '~/sections/kiwisaver/data/rakaia'
import {useActiveOrOffboardingKSCustomer, useKSAccountActivitySummary} from '~/sections/kiwisaver/state'
import styles from './AccountActivitySummary.scss'

export const AccountActivitySummary: React.FunctionComponent = () => {
    const profileUrl = useProfileUrl()

    const accountActivitySummary = useKSAccountActivitySummary()

    const customer = useActiveOrOffboardingKSCustomer()
    const returns = useRakaiaReadPortfolio(customer.portfolio_id ?? '')

    return (
        <>
            {accountActivitySummary && (
                <div className={cn(spacing.spaceBelow32, spacing.spaceAbove16, styles.summaryWrapper)}>
                    <h3>Summary</h3>
                    <div className={cn(recordStyles.recordRowContainer, styles.summaryRowContainer)}>
                        <RecordRow
                            className={styles.summaryRow}
                            left={<p>Contributions</p>}
                            right={<DollarValue value={accountActivitySummary.contributions_total} />}
                        />
                        <RecordRow
                            className={styles.summaryRow}
                            left={<p>Investment return</p>}
                            right={<DollarValue value={returns.total_return ? returns.total_return : 0.0} />}
                        />
                        <RecordRow
                            className={styles.summaryRow}
                            left={<p data-testid="tooltip--summary-estimated">Estimated fees</p>}
                            right={<DollarValue value={-accountActivitySummary.fees} />}
                        />
                        <div className={spacing.spaceAbove12}>
                            <Link
                                to={profileUrl('kiwisaver/activity/breakdown')}
                                className={styles.accountActivityBreakdownLink}
                            >
                                See a detailed breakdown
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
