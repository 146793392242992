import {colour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import classNames from 'classnames'
import React from 'react'
import {urlFor} from '~/global/routeGenerator'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {ExtendedProductIntroSlideContent, ProductIntroSlides} from '~/global/widgets/intro-slides/IntroSlides'
import {ANDROID_PLAY_STORE_URL, IOS_APP_STORE_URL} from '~/global/widgets/login-form/LoginForm'
import SharesiesPineapple from '~/global/widgets/sharesies-pineapple/SharesiesPineapple'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {useNavigate} from '~/migrate-react-router'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import farmSupply from './assets/images/farm-supply.png'
import mobileDownload from './assets/images/intro-fonterra-1.png'
import saveInterestRate from './assets/images/intro-fonterra-2.png'
import styles from './WelcomeFonterra.scss'

const WelcomeFonterra: React.FunctionComponent = React.memo(() => {
    const navigate = useNavigate()
    const profile = useProfile()
    const dispatch = useAppDispatch()
    const colourMode = useColourMode()

    const notificationContext = React.useContext(NotificationContext)
    const user = useAppSelector(s => s.identity.user!)
    const hasSeenFonterraWelcomeScreen = useAppSelector(s => s.identity.user?.has_seen.fonterra_welcome_screen)

    const slides: ExtendedProductIntroSlideContent[] = [
        {
            backgroundColour: colour('Pool100', colourMode),
            image: farmSupply,
            imageAdditionalClassName: styles.backgroundSize,
            title: 'View and manage your Fonterra Co-op shares',
            buttonLabel: 'Next',
            bodyRenderer: () => (
                <span className={styles.secondaryText}>
                    Your investments in Fonterra are shown according to farm supply numbers.
                </span>
            ),
        },
        {
            backgroundColour: colour('Blueberry100', colourMode),
            image: mobileDownload,
            imageAdditionalClassName: styles.backgroundSize,
            title: 'Get the Sharesies app',
            buttonLabel: 'Next',
            bodyRenderer: () => (
                <span className={styles.secondaryText}>
                    Download the Sharesies app from the{' '}
                    <a
                        target="_blank"
                        href={IOS_APP_STORE_URL}
                        rel="noreferrer noopener"
                        aria-label="Download the Sharesies app on the Apple App Store"
                    >
                        App Store
                    </a>{' '}
                    or{' '}
                    <a
                        target="_blank"
                        href={ANDROID_PLAY_STORE_URL}
                        rel="noreferrer noopener"
                        aria-label="Download the Sharesies app on the Google Play Store"
                    >
                        Google Play Store
                    </a>
                    , so you’ve always got access to your Fonterra Co-op investments.
                </span>
            ),
        },
        {
            backgroundColour: colour('Orange100', colourMode),
            image: saveInterestRate,
            imageAdditionalClassName: classNames(styles.backgroundSize),
            title: 'Earn 4.60% p.a. in interest and pay no fees',
            secondaryButtonLabel: 'Maybe later',
            onClickSecondaryButton: () => {
                dispatch(identityActions.MarkHasSeenFlag('fonterra_onboarding_screen'))
                navigate(urlFor('profile/:profileSlug/', {profileSlug: profile.slug}))
            },
            buttonLabel: 'Open now',
            onButtonClick: () => {
                dispatch(identityActions.MarkHasSeenFlag('fonterra_onboarding_screen'))
                navigate(urlFor('profile/:profileSlug/save/intro', {profileSlug: profile.slug}))
            },
            bodyRenderer: () => (
                <span className={styles.secondaryText}>
                    Open a Save account and your money will be working for you—whether it’s set aside, or set to invest.
                </span>
            ),
        },
    ]

    const welcomeMessage = () => {
        return (
            <>
                Thanks {user.preferred_name}—<br />
                welcome to Sharesies!
            </>
        )
    }

    React.useEffect(() => {
        if (!hasSeenFonterraWelcomeScreen) {
            const animationDuration = 2000

            notificationContext.showFullscreenNotification(
                <div className={styles.welcome} data-testid="notification--welcome-fonterra">
                    <SharesiesPineapple colour="ConstantWhite" className={styles.welcomeImage} />
                    <p>{welcomeMessage()}</p>
                </div>,
                {duration: animationDuration},
            )
            dispatch(identityActions.MarkHasSeenFlag('fonterra_welcome_screen'))

            const waitThenSlider = async () => {
                await new Promise(resolve => setTimeout(resolve, animationDuration))
            }

            waitThenSlider()
        }
    }, [hasSeenFonterraWelcomeScreen])

    return (
        <>
            <ProductIntroSlides content={slides} />
        </>
    )
})

export default WelcomeFonterra
