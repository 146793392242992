import Decimal from 'decimal.js'
import {Model} from '~/api/retail/types'

// Decimal helper for readablility
const d = (value?: number | string) => (value ? new Decimal(value) : new Decimal('0'))

interface Fee {
    fixed: Decimal
    variable: Decimal
}

// Fee constants
// NZ: 2.65% + $0.18
const NZ_FEES: Fee = {fixed: d('0.18'), variable: d('0.0265')}
// AU < 15: 2.5% + $0.05
const AU_FEES_LESS_THAN_15: Fee = {fixed: d('0.05'), variable: d('0.025')}
// AU >= 15: 1.22% + $0.18
const AU_FEES_15_OR_OVER: Fee = {fixed: d('0.18'), variable: d('0.0122')}

/**
 * Get the charge amount + card fee for the topup.
 * This matches backend's amount_including_card_fee in app/support/stripe/card_fee_calculation.py
 *
 * @param {string} amount - The desired topup amount
 * @param {Model.User['home_currency']} homeCurrency - Customer's home currency
 */
export const amountIncludingCardFee = (
    amount: string | undefined,
    homeCurrency: Model.User['home_currency'],
): string => {
    let fees: Fee

    if (homeCurrency === 'nzd') {
        fees = NZ_FEES
    } else if (homeCurrency === 'aud') {
        // Yes, 14.57 is a weird number. See function "amount_including_card_fee" in backend
        if (d(amount).lessThan(14.57)) {
            fees = AU_FEES_LESS_THAN_15
        } else {
            fees = AU_FEES_15_OR_OVER
        }
    } else {
        throw new Error(`Can't calculate card fee for this currency: ${homeCurrency}`)
    }

    if (d(amount).greaterThan(0)) {
        return d(amount).plus(fees.fixed).dividedBy(d(1).minus(fees.variable)).toFixed(2, Decimal.ROUND_CEIL)
    }

    return '0.00'
}

export const amountOfCardFee = (amount: string, homeCurrency: Model.User['home_currency']): string =>
    amount ? d(amountIncludingCardFee(amount, homeCurrency)).minus(d(amount)).toFixed(2) : '0.00'
