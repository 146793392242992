import {ScaleLinear} from 'd3-scale'
import React from 'react'
import {PortfolioHistoryItemWithIndex} from '~/global/utils/slice-history/sliceHistory'

const useTouchHover = (
    measuredLeft: number,
    xScale: ScaleLinear<number, number>,
    portfolioHistory: PortfolioHistoryItemWithIndex[],
) => {
    const [hovering, setHovering] = React.useState(false)
    const [isTouchingChart, setIsTouchingChart] = React.useState(false)
    const [unboundedInvertedChartIndex, setUnboundedInvertedChartIndex] = React.useState<number | undefined>(undefined)

    const hoverDayMaximum = portfolioHistory.length - 1

    const hoverDay = React.useMemo(() => {
        // Un-invert and clamp the cursor position.
        // It is originally inverted to keep context of distance from the current day.
        if (unboundedInvertedChartIndex === undefined) {
            return hoverDayMaximum
        }
        const chartIndex = hoverDayMaximum - unboundedInvertedChartIndex
        if (chartIndex < 0) {
            return 0
        }
        return Math.min(chartIndex, hoverDayMaximum)
    }, [unboundedInvertedChartIndex, hoverDayMaximum])

    const onMouseOver = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault()
            const x = e.pageX - (measuredLeft || 0)
            const day = Math.round(xScale.invert(x))
            setHovering(true)
            setUnboundedInvertedChartIndex(hoverDayMaximum - day)
        },
        [measuredLeft, xScale],
    )

    const onMouseOut = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        setHovering(false)
    }, [])

    const onMouseMove = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault()
            if (!hovering) {
                return
            }
            const x = e.pageX - (measuredLeft || 0)
            const day = Math.round(xScale.invert(x))
            setUnboundedInvertedChartIndex(hoverDayMaximum - day)
        },
        [hovering, measuredLeft, xScale],
    )

    const onTouchStart = React.useCallback(
        (e: React.TouchEvent<HTMLDivElement>) => {
            const pageX = e.touches[0].pageX
            const x = pageX - (measuredLeft || 0)
            const day = Math.round(xScale.invert(x))
            setHovering(true)
            setUnboundedInvertedChartIndex(hoverDayMaximum - day)
            setIsTouchingChart(true)
        },
        [measuredLeft, xScale],
    )

    const onTouchMove = React.useCallback(
        (e: React.TouchEvent<HTMLDivElement>) => {
            if (!hovering) {
                return
            }
            const pageX = e.touches[0].pageX
            const x = pageX - (measuredLeft || 0)
            const day = Math.round(xScale.invert(x))
            setUnboundedInvertedChartIndex(hoverDayMaximum - day)
            // Intentionally permit propogation to continue, we don't want to interfere with scroll.
            return true
        },
        [measuredLeft, xScale, hovering],
    )

    const onTouchEnd = React.useCallback(() => {
        setHovering(false)
        setIsTouchingChart(false)
    }, [])

    return {
        hoverDay,
        handlerProps: {onMouseOver, onMouseOut, onMouseMove, onTouchStart, onTouchEnd, onTouchMove},
        isTouchingChart,
    }
}

export {useTouchHover}
