import {useAtom} from 'jotai'
import {entityDetailAtom} from '~/sections/moose/sections/sign-up/state'

export const useSignupEntityDetail = (includeOnboardedEntities: boolean = false) => {
    const [detail] = useAtom(entityDetailAtom)
    if (detail === undefined) {
        throw new Error('Entity detail is undefined.')
    }

    if (!includeOnboardedEntities) {
        detail.entities = detail.entities.filter(entity => entity.onboarded_state !== 'ACTIVATED')
    }
    return detail
}
