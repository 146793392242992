import React from 'react'
import ReactDOM from 'react-dom'
import {SharesiesOmit} from '~/global/utils/type-utilities/typeUtilities'

type MaybeElement = HTMLElement | SVGElement | null

export interface MeasureProps {
    onMeasureRef(element: HTMLElement | SVGElement | null): void
    measureRefresh(): void
    measuredWidth?: number
    measuredHeight?: number
    measuredLeft?: number
}

interface State {
    width?: number
    height?: number
    left?: number
    element: MaybeElement
}

/**
 * Usage looks something like this:
 *
 * ```
 * import withMeasure, {MeasureProps} from '~/global/widgets/WithMeasure'
 *
 * const MyComponent: React.FunctionComponent<MyProps & MeasureProps> = ({onMeasureRef, measuredHeight}) => {
 *    return <div ref={onMeasureRef}><p>Height: {measuredHeight}</p></div>
 * }
 *
 * const MeasuredMyComponent = withMeasure(MyComponent)
 * export default MeasuredMyComponent
 * ```
 */
export default function withMeasure<P extends MeasureProps>(
    Component: React.ComponentType<P>,
): React.ComponentClass<SharesiesOmit<P, MeasureProps>> {
    return class MeasuredComponent extends React.PureComponent<SharesiesOmit<P, MeasureProps>, State> {
        private timer: number | null = null

        state: State = {
            element: null,
        }

        constructor(props: SharesiesOmit<P, MeasureProps>) {
            super(props)
            this.onRef = this.onRef.bind(this)
            this.onResize = this.onResize.bind(this)
            this.refresh = this.refresh.bind(this)
        }

        private onResize() {
            const {element} = this.state

            if (element) {
                // eslint-disable-next-line react/no-find-dom-node
                const domNode = ReactDOM.findDOMNode(element)
                if (domNode && 'getBoundingClientRect' in domNode) {
                    const bounds = domNode.getBoundingClientRect()
                    this.setState({
                        left: bounds.left + window.pageXOffset,
                        width: element.clientWidth,
                        height: element.clientHeight,
                    })
                }
            }
        }

        public refresh() {
            this.onResize()
        }

        public onRef(element: MaybeElement) {
            this.setState({element}, () => this.onResize())
        }

        public componentDidMount() {
            window.addEventListener('resize', this.onResize)
            this.timer = window.setInterval(this.refresh, 500)
        }

        public componentWillUnmount() {
            if (this.timer) {
                clearInterval(this.timer)
            }
            window.removeEventListener('resize', this.onResize)
        }

        public render() {
            const {width, height, left} = this.state

            const measureProps: MeasureProps = {
                measuredWidth: width,
                measuredHeight: height,
                measuredLeft: left,
                onMeasureRef: this.onRef,
                measureRefresh: this.refresh,
            }

            return <Component {...(this.props as any)} {...measureProps} />
        }
    }
}
