import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {dateFormatFullMonth} from '~/global/utils/format-date/formatDate'
import {useCurrentSubscriptionPlan} from '~/global/utils/subscription-hooks/subscriptionHooks'
import {subscriptionName} from '~/global/utils/subscription-name/subscriptionName'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import BuyOrderCoverageModal from '~/global/widgets/help-modals/BuyOrderCoverageModal'
import SellOrderCoverageModal from '~/global/widgets/help-modals/SellOrderCoverageModal'
import {Loading} from '~/global/widgets/loading'
import styles from '~/global/widgets/order-plan-message/OrderPlanMessage.scss'
import {useAppSelector} from '~/store/hooks'

interface OrderPlanProps {
    isManagedFund: boolean
}

interface BuyOrderPlanProps extends OrderPlanProps {
    isAutoExercise: boolean
    feeCoverage?: 'full' | 'partial'
}

export const OrderPlanBuyMessage: React.FunctionComponent<BuyOrderPlanProps> = ({
    isManagedFund,
    isAutoExercise,
    feeCoverage,
}) => {
    const [currentSubscription, currentSubscriptionLoaded] = useCurrentSubscriptionPlan()
    const [modalOpen, setModalOpen] = React.useState(false)
    const {isDependent, preferredName} = useAppSelector(s => ({
        isDependent: s.identity.user!.is_dependent,
        preferredName: s.identity.user!.preferred_name,
    }))

    if (!currentSubscriptionLoaded) {
        return <Loading isPineapple />
    }

    if (!currentSubscription) {
        return null
    }

    const theOrYour = isDependent ? 'the' : 'your'
    const yourOrNames = isDependent ? `${preferredName}’s` : 'your'
    const yourCapitalOrNames = isDependent ? `${preferredName}’s` : 'Your'
    const youCapitalOrName = isDependent ? preferredName : 'You'

    return (
        <div className={styles.planInfo}>
            <p className={cn(styles.planInfoHeader, spacing.spaceAbove24)}>
                {isDependent ? `${preferredName}'s` : 'Your'} {subscriptionName(currentSubscription.plan)} is active
            </p>
            {isManagedFund ? (
                <p className={spacing.spaceAbove12}>
                    Orders for managed funds aren’t charged our transaction fee, and won’t be deducted from your
                    available coverage.
                </p>
            ) : isAutoExercise ? (
                <p>
                    On an active plan, orders to buy and auto-exercise rights won’t be charged our transaction fee, and
                    no coverage will be deducted.
                </p>
            ) : feeCoverage === 'full' ? (
                <ul>
                    <li>
                        When you place a buy order, we’ll deduct the equivalent amount from {yourOrNames} available
                        coverage.
                    </li>
                    <li>
                        Some (or all) of the coverage used for this order might be returned {!isDependent && 'to you'}{' '}
                        if the order doesn’t completely fill (or is cancelled) before{' '}
                        {currentSubscription.expires.toFormat(dateFormatFullMonth)}.
                    </li>
                </ul>
            ) : feeCoverage === 'partial' ? (
                <ul>
                    <li>
                        When you place a buy order, we’ll deduct the equivalent amount from {yourOrNames} available
                        coverage (or less, if there’s not enough left).
                    </li>
                    <li>
                        Some (or all) of the coverage used for this order might be returned {!isDependent && 'to you'}{' '}
                        if the order doesn’t completely fill (or is cancelled) before{' '}
                        {currentSubscription.expires.toFormat(dateFormatFullMonth)}.
                    </li>
                    <li>
                        For any part of {theOrYour} order that isn’t covered by {yourOrNames} plan, the 1.9% transaction
                        fee (and per order fee caps) will apply.
                    </li>
                    <li>
                        Any transaction fee applied to your order will be deducted from your order amount before it’s
                        placed on-market (or added if you’re placing a limit buy in shares).
                    </li>
                </ul>
            ) : (
                <ul>
                    <li>
                        {youCapitalOrName} currently have no available coverage, and the 1.9% transaction fee (and per
                        order fee caps) will apply.
                    </li>
                    <li>
                        {yourCapitalOrNames} plan {currentSubscription.auto_renew ? 'renews' : 'ends'} on{' '}
                        {currentSubscription.expires.toFormat(dateFormatFullMonth)}.
                    </li>
                </ul>
            )}
            {!isManagedFund && !isAutoExercise && (
                <>
                    <ButtonAsLink onClick={() => setModalOpen(true)} className={styles.modalButton}>
                        How buy order coverage works
                    </ButtonAsLink>
                    <BuyOrderCoverageModal setIsOpen={setModalOpen} isOpen={modalOpen} />
                </>
            )}

            <hr className={cn(spacing.spaceBelow24, spacing.spaceAbove24)} />
        </div>
    )
}

export const OrderPlanSellMessage: React.FunctionComponent<OrderPlanProps> = ({isManagedFund}) => {
    const [currentSubscription, currentSubscriptionLoaded] = useCurrentSubscriptionPlan()
    const [modalOpen, setModalOpen] = React.useState(false)
    const {isDependent, preferredName} = useAppSelector(s => ({
        isDependent: s.identity.user!.is_dependent,
        preferredName: s.identity.user!.preferred_name,
    }))

    if (!currentSubscriptionLoaded) {
        return <Loading isPineapple />
    }

    if (!currentSubscription) {
        return null
    }

    const yourOrTheir = isDependent ? 'their' : 'your'
    const theOrYour = isDependent ? 'the' : 'your'
    const youOrThey = isDependent ? 'they' : 'you'

    return (
        <div className={styles.planInfo}>
            <p className={cn(styles.planInfoHeader, spacing.spaceAbove24)}>
                {isDependent ? `${preferredName}'s` : 'Your'} {subscriptionName(currentSubscription.plan)} is active
            </p>
            {isManagedFund ? (
                <p className={spacing.spaceAbove12}>
                    Orders for managed funds aren’t charged our transaction fee, and won't be deducted from your
                    available coverage.
                </p>
            ) : (
                <ul>
                    <li>
                        As {yourOrTheir} sell order fills, we’ll deduct equivalent amounts from {theOrYour} available
                        coverage.
                    </li>

                    <li>
                        For any part of your order that isn’t covered by {yourOrTheir} plan, the 1.9% transaction fee
                        (and per order fee caps) will apply.
                    </li>

                    <li>
                        Any transaction fee applied to {isDependent ? 'this' : 'your'} order will be deducted from the
                        value {youOrThey} receive from the sale of {yourOrTheir} shares.
                    </li>
                </ul>
            )}
            {!isManagedFund && (
                <>
                    <ButtonAsLink onClick={() => setModalOpen(true)} className={styles.modalButton}>
                        How sell order coverage works
                    </ButtonAsLink>
                    <SellOrderCoverageModal setIsOpen={setModalOpen} isOpen={!!modalOpen} />
                </>
            )}

            <hr className={cn(spacing.spaceBelow24, spacing.spaceAbove24)} />
        </div>
    )
}
