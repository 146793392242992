import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import spacing from '~/global/scss/helpers/spacing.scss'
import headerStyles from '~/global/scss/reused-styles/pageHeader.scss'
import tooltipStyles from '~/global/scss/reused-styles/tooltip.scss'
import {dateFormatFullMonth, dateFormatWithYearFirst} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ButtonCircle} from '~/global/widgets/button-circle/ButtonCircle'
import FeatureOnboardingPill from '~/global/widgets/feature-onboarding-pill/FeatureOnboardingPill'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {Tooltip} from '~/global/widgets/tooltip/Tooltip'
import saveLarge from '~/sections/save/assets/images/save-hero.png'
import {useSelectedSaveAccount} from '~/sections/save/state/selectedSaveAccountState'
import {useAppSelector, useAppDispatch} from '~/store/hooks'
import saveActions from '~/store/save/actions'
import Activity from './sections/activity'
import commonStyles from './common.scss'

interface SaveProps {
    portfolioId: string
}

export const Save: React.FunctionComponent<SaveProps> = ({portfolioId}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const location = useLocation()
    const profileUrl = useProfileUrl()
    const selectedSaveAccount = useSelectedSaveAccount(portfolioId)

    const interestRate = useAppSelector(s => s.save.interestRate)
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)
    const toolbarHiddenForMobile = useAppSelector(s => s.nav.toolbarHidden)

    // Get interest rate if missing
    if (!interestRate) {
        dispatch(saveActions.getInterestRate())
    }

    return (
        <>
            <div className={commonStyles.background}>
                <Toolbar
                    dataTestId="toolbar--save-landing"
                    showNotifications
                    leftButton="back"
                    onLeftButtonClick={() => navigate(profileUrl(''))}
                />
            </div>
            <Page className={commonStyles.save} withoutDefaultPadding>
                <div
                    className={cn(headerStyles.landingImageWrapper, commonStyles.background)}
                    style={{paddingTop: toolbarHiddenForMobile ? '25px' : '0px'}}
                >
                    <img src={saveLarge} alt="save account" className={headerStyles.landingImage} />
                </div>
                <div className={commonStyles.background}>
                    <div className={headerStyles.landingContentWrapper}>
                        <h1 data-testid="heading--save-landing" className={headerStyles.title}>
                            {selectedSaveAccount?.name || 'Save'}
                        </h1>
                        <div className={headerStyles.accountBalanceWrapper}>
                            <p className={headerStyles.accountBalance}>
                                <DollarValue value={selectedSaveAccount?.balance} decimalPlaces={2} />
                                <span className={headerStyles.accountBalanceCurrency}>
                                    {homeCurrency.toUpperCase()}
                                </span>
                            </p>
                        </div>

                        <div className={commonStyles.buttonWrapper}>
                            <ButtonCircle
                                label="Transfer money"
                                icon="Transfer"
                                dataTestId="button--save-transfer-money"
                                onClick={() => navigate(profileUrl('wallet/transfer'))}
                            />

                            <ButtonCircle
                                label="Manage"
                                icon="MoreHorizontal"
                                dataTestId="button--save-manage"
                                onClick={() => {
                                    rudderTrack('navigation', 'feature_management_button_clicked', {
                                        label: 'manage',
                                        url: location.pathname,
                                    })
                                    navigate(profileUrl('save/manage/:portfolioId', {portfolioId}))
                                }}
                            />
                        </div>

                        <div className={cn(headerStyles.infoRow, spacing.spaceBelow32)}>
                            <div className={headerStyles.infoRowContent}>
                                <div className={headerStyles.infoRowTitle}>
                                    <span>Total interest earned</span>
                                </div>
                                <Tooltip>
                                    <span className={cn(headerStyles.infoRowValue, tooltipStyles.label)}>
                                        <DollarValue
                                            value={Number(selectedSaveAccount?.total_interest_earned)}
                                            decimalPlaces={2}
                                        />
                                    </span>
                                    <div className={tooltipStyles.tooltip}>
                                        This is the total amount of interest you’ve earned (before tax) since you opened
                                        this Save account.
                                    </div>
                                </Tooltip>
                            </div>
                            <div className={headerStyles.infoRowContent}>
                                <p className={headerStyles.infoRowTitle}>Interest rate (p.a.)</p>
                                <p className={headerStyles.infoRowValue}>{interestRate}%</p>
                            </div>
                        </div>
                        <div className={headerStyles.infoRow}>
                            <div className={headerStyles.infoRowContent}>
                                <div className={headerStyles.infoRowTitle}>
                                    <span>This month’s interest</span>
                                </div>
                                <Tooltip>
                                    <span className={cn(headerStyles.infoRowValue, tooltipStyles.label)}>
                                        <DollarValue
                                            value={Number(selectedSaveAccount.monthly_accrued_interest)}
                                            decimalPlaces={2}
                                        />
                                    </span>
                                    <div className={cn(tooltipStyles.tooltip)}>
                                        <div className={spacing.spaceBelow16}>
                                            The interest you’ve earned so far this month. It’ll be paid on{' '}
                                            {DateTime.fromFormat(
                                                selectedSaveAccount.interest_payment_date,
                                                dateFormatWithYearFirst,
                                            ).toFormat(dateFormatFullMonth)}{' '}
                                            (the first business day of next month).
                                        </div>
                                        <div>
                                            The interest payment will cover the last month, up to and including the last
                                            business day, plus any interest accrued from the previous month for days
                                            that were after the last business day.
                                        </div>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>

                <Activity portfolioId={portfolioId} />
                <FeatureOnboardingPill viewingOnProduct="save" />
            </Page>
        </>
    )
}

export default Save
