import {Button} from '@design-system/button'
import React from 'react'
import {Loading} from '~/global/widgets/loading'
import Animation, {getAnimationData} from '~/global/widgets/lottie-animation/Animation'
import Page from '~/global/widgets/page/Page'
import styles from '~/sections/user/sections/sign-up/SignUp.scss'

interface Props {
    onClick(): void
    buttonLabel?: string
}

const AddressSuccess: React.FC<Props> = ({onClick, buttonLabel}) => {
    const [animData, setAnimData] = React.useState<object>()

    React.useEffect(() => {
        getAnimationData('confirmation').then(setAnimData)
    }, [])

    if (!animData) {
        return <Loading />
    }

    return (
        <>
            <Page>
                <div className={styles.splashCenterVertically}>
                    <Animation className={styles.centered} animationData={animData} loop={false} />
                    <h2>Thanks, we've got your address</h2>
                </div>

                <Button
                    dataTestId="button--address-success"
                    pageButton
                    label={buttonLabel || 'Next'}
                    onClick={() => {
                        onClick()
                    }}
                />
            </Page>
        </>
    )
}

export default AddressSuccess
