import {Button} from '@design-system/button'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import kiwisaverSmall from '~/sections/kiwisaver/assets/images/kiwisaver-small.png'
import investSmall from '~/sections/landing/assets/images/invest-small.png'
import {PortfolioSlice} from '~/sections/landing/widgets/portfolio-slice/PortfolioSlice'
import {FonterraPersonalSignUpCommonProps} from '~/sections/moose/sections/fonterra-personal-sign-up/FonterraPersonalSignUp'
import saveSmall from '~/sections/save/assets/images/save-small.png'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import saveActions from '~/store/save/actions'
import styles from './Intro.scss'

const Intro = ({progressStep, regressStep}: FonterraPersonalSignUpCommonProps) => {
    const interestRate = useAppSelector(s => s.save.interestRate)
    const dispatch = useAppDispatch()

    React.useEffect(() => {
        if (!interestRate) {
            dispatch(saveActions.getInterestRate())
        }
    }, [])

    return (
        <>
            <Page className={styles.introPage}>
                <Toolbar
                    dataTestId="toolbar--fonterra-personal-signup-intro"
                    leftButton="back"
                    onLeftButtonClick={regressStep}
                />
                <div className={styles.getStarted}>
                    <h2 className={spacing.spaceBelow24}>Open your personal Sharesies account in just a few minutes</h2>
                    <div className={styles.tiles}>
                        <PortfolioSlice
                            additionalClassName={styles.portfolioSlicePadding}
                            dataTestId="button--landing-start-buying-shares"
                            image={investSmall}
                            imageBackgroundColour="Melon100"
                            clickable={false}
                        >
                            <h3>Invest in shares</h3>
                            <p>Buy US, NZ, or Aussie shares, or transfer shares you already own</p>
                        </PortfolioSlice>
                        <PortfolioSlice
                            additionalClassName={styles.portfolioSlicePadding}
                            clickable={false}
                            dataTestId="button--landing-open-save"
                            image={saveSmall}
                            imageBackgroundColour="Orange100"
                        >
                            <h3>Open a Save account</h3>
                            <p>Earn {interestRate}% on your savings—with no limits</p>
                        </PortfolioSlice>
                        <PortfolioSlice
                            dataTestId="button--landing-open-kiwisaver"
                            clickable={false}
                            image={kiwisaverSmall}
                            imageBackgroundColour="Blueberry100"
                        >
                            <h3>Join our KiwiSaver Scheme</h3>
                            <p>Invest for your retirement or first home with more choice</p>
                        </PortfolioSlice>
                        <p className={spacing.spaceAbove12}>Save interest rate is subject to change.</p>
                    </div>
                    <Button
                        label="Get started"
                        type="primary"
                        dataTestId="button--next"
                        onClick={progressStep}
                        pageButton
                        isSubmit
                    />
                </div>
            </Page>
        </>
    )
}

export default Intro
