import {Modal} from '@design-system/modal'
import React from 'react'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const UsLivePricingModal: React.FunctionComponent<Props> = ({isOpen, setIsOpen}) => {
    const profileUrl = useProfileUrl()

    return (
        <Modal
            dataTestId="modal--market-depth"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title="US live pricing"
            primaryButton={{label: 'Ok'}}
            content={
                <div>
                    <p>
                        Get live market info for investments listed on the main US exchanges (New York Stock Exchange
                        (NYSE), Nasdaq, and Chicago Board Options Exchange (CBOE)), including:
                    </p>

                    <ul>
                        <li>
                            live share prices—updated every five seconds when the market is open and, if you’ve opted
                            in, during <a href={profileUrl('settings/portfolio-us-extended-hours')}>extended hours</a>
                        </li>
                        <li>top bid—see the highest price investors are currently willing to pay, per share</li>
                        <li>top offer—see the lowest price shareholders are currently willing to sell at, per share</li>
                    </ul>

                    <p>This info may help you get a sense of how the market currently views an investment’s value.</p>
                    <br />

                    <p>The price you buy or sell at may be different to the live price you see.</p>
                    <br />

                    <p>We source the live pricing data from Cboe Data Services, LLC.</p>
                </div>
            }
        />
    )
}

export default UsLivePricingModal
