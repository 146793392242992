import {AddressValue} from '@design-system/address-input'
import {Button} from '@design-system/button'
import cn from 'classnames'
import {InjectedFormikProps, withFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {Model, Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {useInvalidateProfilesCache} from '~/global/state-hooks/retail/useProfile'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {humaniseList} from '~/global/utils/humanise-list/humaniseList'
import {
    humaniseNature,
    humaniseOtherPurpose,
    humanisePurposes,
    Purpose,
} from '~/global/utils/nature-and-purpose/natureAndPurpose'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import AccountVerificationRequired from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import {ErrorBox, validate} from '~/global/widgets/form-controls'
import {COUNTRIES, extractCountryNameFromCode} from '~/global/widgets/form-controls/country-select/CountrySelectInput'
import {Email, Phone, Text} from '~/global/widgets/form-controls/formik'
import Citizenship from '~/global/widgets/help-modals/Citizenship'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {Link} from '~/migrate-react-router'
import InvalidPreferredNameModal from '~/sections/user/widgets/invalid-preferred-name-modal/InvalidPreferredNameModal'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'
import {SourceOfInvalidPreferredName, State} from '~/store/identity/types'
import styles from './AccountPersonalDetails.scss'

const Form: React.FunctionComponent<InjectedFormikProps<FormProps, FormValues>> = ({
    address,
    addressState,
    citizenships,
    email,
    errors,
    handleSubmit,
    isDependent,
    isDependentWithPrescribedOwner,
    isPrescribed,
    isSubmitting,
    isValid,
    jurisdiction,
    natureAndPurpose,
    phone,
    preferredName,
    status,
    values,
}) => {
    const profileUrl = useProfileUrl()

    const [failedNameValidationModalOpen, setFailedNameValidationModalOpen] = useState<boolean>(false)
    const [currentNameValidationFailure, setCurrentNameValidationFailure] = useState<string | null>(null)

    const allCitizenships = citizenships
        ? COUNTRIES.filter(country => citizenships.includes(country[0])).map(countryArray =>
              countryArray[0] === 'US' ? 'US citizen or Green Card holder' : countryArray[1],
          )
        : []

    const jurisdictionCode = jurisdiction!.toUpperCase()
    const jurisdictionName = extractCountryNameFromCode(jurisdictionCode)
    const foreignCitizenships = [...allCitizenships.filter(country => country !== jurisdictionName)]

    const hasNature = natureAndPurpose && natureAndPurpose.frequency && natureAndPurpose.annual_amount
    const displayPurposes =
        natureAndPurpose &&
        natureAndPurpose.purposes &&
        !(natureAndPurpose.purposes.length === 1 && natureAndPurpose.purposes.includes('other'))

    useEffect(() => {
        if (!errors.preferredName || !values.preferredName) {
            // No names have failed or the field is empty
            return
        }
        // Open modal to show customer that their preferred name is invalid
        setFailedNameValidationModalOpen(true)

        Analytics.event({
            category: 'Personal Details',
            action: 'Invalid preferred name',
            label: 'Trust or company name supplied',
        })
    }, [errors.preferredName])

    useEffect(() => {
        // This effect is triggered whenever the failed name validation modal opens or the text in
        // the preferred name field changes. The `currentNameValidationFailure` value is used, in
        // conjunction with the validity of the form, to enable/disable the submit button

        if (currentNameValidationFailure !== values.preferredName) {
            // The preferred name has been altered from the one that caused the modal to open. Set the
            // currentNameValidationFailure to null so that the submit button becomes active again (provided
            // the form passes the rest of the validation)
            setCurrentNameValidationFailure(null)
        }
    }, [currentNameValidationFailure, values.preferredName])

    const closeModal = () => setFailedNameValidationModalOpen(false)

    return (
        <>
            <InvalidPreferredNameModal
                isOpen={failedNameValidationModalOpen}
                setIsOpen={closeModal}
                jurisdiction={jurisdiction}
                source={SourceOfInvalidPreferredName.ACTIVE_CUSTOMER}
            />
            <form onSubmit={handleSubmit}>
                <Text dataTestId="text-input--preferred-name" name="preferredName" label="Preferred name" />
                {!isDependent && <Email dataTestId="user-email-input" name="email" label="Email" />}
                {!isDependent && <Phone dataTestId="text-input--phone-number" name="phone" label="Phone number" />}
                <div className={styles.title}>
                    <h2>Address</h2>
                    <Link data-testid="link--edit-address" to={profileUrl('settings/personal-details/edit-address')}>
                        Edit
                    </Link>
                </div>
                <p className={styles.address}>{address.formatted}</p>

                {addressState === 'uploaded' && (
                    <p className={cn(styles.addressStateLabel, styles.addressStateLabelPending)}>
                        Address verification pending
                    </p>
                )}

                {['new', 'rejected'].includes(addressState) && (
                    <p className={cn(styles.addressStateLabel, styles.addressStateLabelNotVerified)}>
                        Address not verified
                    </p>
                )}

                <div className={styles.title}>
                    <h2>Prescribed Person</h2>

                    {((isPrescribed && !isDependentWithPrescribedOwner) || !isPrescribed) && (
                        <Link
                            data-testid="link--prescribed-person"
                            to={profileUrl('settings/personal-details/prescribed-person')}
                        >
                            Edit
                        </Link>
                    )}
                </div>
                {isPrescribed ? (
                    <p>
                        {isDependent
                            ? `${preferredName} is a Prescribed Person.`
                            : 'You’ve said you’re a Prescribed Person.'}
                    </p>
                ) : (
                    <p>
                        {isDependent
                            ? `${preferredName} is not a Prescribed Person.`
                            : 'You’ve said you’re not a Prescribed Person.'}
                    </p>
                )}
                {citizenships && citizenships.length > 0 && (
                    <>
                        <div className={styles.title}>
                            <h2>
                                Citizenship <Citizenship />
                            </h2>

                            <Link
                                data-testid="anchor--edit-citizenship"
                                to={profileUrl('settings/personal-details/citizenship-details')}
                            >
                                Edit
                            </Link>
                        </div>
                        <p>
                            {
                                //Citizenship in jurisdiction is listed first if it exists
                                allCitizenships.includes(jurisdictionName)
                                    ? humaniseList([jurisdictionName, ...foreignCitizenships])
                                    : humaniseList(allCitizenships)
                            }
                        </p>
                    </>
                )}

                {
                    // only display nature and purpose in account if the investor has answered the nature questions in the UI flow previously
                    !isDependent && natureAndPurpose && hasNature && (
                        <>
                            <div className={styles.title}>
                                <h2>How and why you’re investing</h2>
                                <Link
                                    data-testid="anchor--edit-nature-and-purpose"
                                    to={profileUrl('settings/personal-details/how-you-invest')}
                                >
                                    Edit
                                </Link>
                            </div>
                            <div>
                                <p className={cn(styles.secondaryText, spacing.spaceAbove4, spacing.spaceBelow4)}>
                                    Last updated on {natureAndPurpose.created.toFormat(dateFormatNoTime)}
                                </p>
                                <p className={spacing.spaceBelow24} data-testid="text--nature">
                                    You’ve said you plan to invest:{' '}
                                    {humaniseNature(natureAndPurpose.frequency, natureAndPurpose.annual_amount)}
                                </p>
                                <p data-testid="text--purpose">
                                    {displayPurposes && (
                                        <>
                                            {humanisePurposes(natureAndPurpose.purposes as Purpose[])}
                                            <br />
                                        </>
                                    )}
                                    {natureAndPurpose.other_purpose &&
                                        natureAndPurpose.purposes &&
                                        humaniseOtherPurpose(natureAndPurpose.purposes, natureAndPurpose.other_purpose)}
                                </p>
                            </div>
                        </>
                    )
                }

                {status && <ErrorBox message={status} />}
                <Button
                    isSubmit
                    pageButton
                    label="Save changes"
                    dataTestId="button--save-changes"
                    disabled={
                        // if the user has not changed any of their personal details - do not allow them to save the changes
                        !isValid ||
                        (values.preferredName === preferredName && values.email === email && values.phone === phone)
                    }
                    processing={isSubmitting}
                />
            </form>
        </>
    )
}

const DetailsForm = withRouter(
    withFormik<FormProps & WithRouterProps, FormValues>({
        mapPropsToValues: ({email, phone, preferredName}) => {
            return {
                preferredName,
                email,
                originalEmail: email,
                phone,
                invalidPreferredNames: [],
            }
        },
        mapPropsToErrors: () => ({
            // make the button disabled initially by setting at least one field to have an error
            preferredName: undefined,
        }),
        mapPropsToTouched: () => ({
            email: true,
            phone: true,
            preferredName: true,
        }),
        handleSubmit: async (
            {email, originalEmail, invalidPreferredNames, phone, preferredName},
            {
                setSubmitting,
                setStatus,
                setFieldError,
                props: {
                    updateDependentDetails,
                    updateDetails,
                    isDependent,
                    router: {navigate},
                    profileUrl,
                    invalidateProfilesCache,
                },
            },
        ) => {
            try {
                const error = isDependent
                    ? await updateDependentDetails(preferredName, invalidPreferredNames)
                    : await updateDetails(preferredName, phone, email, invalidPreferredNames)

                if (error) {
                    switch (error.code) {
                        // Validation of a customers preferred name is handled server side
                        case 'invalid_preferred_name_update':
                            setFieldError('preferredName', error.message)
                            // Return rather than break so modal can be thrown in form
                            return
                        case 'update_email_already_exists':
                            setFieldError('email', error.message)
                            break
                        case 'internal_error':
                            setStatus(error.message)
                            break

                        default:
                            setStatus(error.message)
                    }

                    setSubmitting(false)
                    return
                }

                if (originalEmail !== email) {
                    // Special toast for email change, introduced with Email Verification
                    Toast('Nice! Now hit the verification link we just emailed you to verify your email address')
                } else {
                    // Default toast for details changed
                    Toast('Details updated')
                }

                invalidateProfilesCache() // preferred name changes would affect profiles endpoint data
                navigate(profileUrl('settings'))
            } catch (e) {
                setStatus(unknownErrorMessage)
                setSubmitting(false)
                throw e
            }
        },
        validate: (values, {isDependent}) => {
            const schema = isDependent
                ? {
                      preferredName: [validate.required()],
                      email: [],
                      originalEmail: [],
                      phone: [],
                      invalidPreferredNames: [],
                  }
                : {
                      preferredName: [validate.required()],
                      email: [validate.required(), validate.email()],
                      originalEmail: [],
                      phone: [validate.required()],
                      invalidPreferredNames: [],
                  }
            return validate.generate<FormValues>(schema)(values)
        },
        validateOnMount: true,
    })(Form),
)

export const AccountPersonalDetails: React.FunctionComponent<AccountPersonalDetailsProps> = ({
    isDependent,
    preferredName,
    email,
    phone,
    address,
    isPrescribed,
    isDependentWithPrescribedOwner,
    updateDependentDetails,
    updateDetails,
    getCitizenships,
    getNatureAndPurpose,
    citizenships,
    addressState,
    jurisdiction,
    natureAndPurpose,
}) => {
    const profileUrl = useProfileUrl()
    const invalidateProfilesCache = useInvalidateProfilesCache()

    React.useEffect(() => {
        getCitizenships()
        getNatureAndPurpose()
    }, [])

    return (
        <>
            <Toolbar dataTestId="toolbar--personal-details" leftButton="back" title="Personal details" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <AccountVerificationRequired />
                <DetailsForm
                    address={address}
                    addressState={addressState}
                    citizenships={citizenships}
                    email={email}
                    isDependent={isDependent}
                    isDependentWithPrescribedOwner={isDependentWithPrescribedOwner}
                    isPrescribed={isPrescribed}
                    jurisdiction={jurisdiction}
                    natureAndPurpose={natureAndPurpose}
                    phone={phone}
                    preferredName={preferredName}
                    updateDependentDetails={updateDependentDetails}
                    updateDetails={updateDetails}
                    profileUrl={profileUrl}
                    invalidateProfilesCache={invalidateProfilesCache}
                />
            </Page>
        </>
    )
}

interface FormValues {
    email: string
    originalEmail: string
    invalidPreferredNames: string[]
    phone: string
    preferredName: string
}

interface FormProps {
    address: AddressValue
    addressState: Response.IdentityAuthenticated['user']['address_state']
    citizenships: State['citizenships']
    email: string
    isDependent: boolean
    isDependentWithPrescribedOwner: boolean
    isPrescribed: boolean
    jurisdiction: Model.User['jurisdiction']
    natureAndPurpose: Response.IdentityNatureAndPurpose | null
    phone: string
    preferredName: string
    updateDetails(
        preferredName: string,
        phone: string,
        email: string,
        invalidPreferredName?: string[],
        address?: AddressValue,
    ): Promise<Response.Error | undefined>
    updateDependentDetails(
        preferredName: string,
        invalidPreferredName?: string[],
        address?: AddressValue,
    ): Promise<Response.Error | undefined>
    profileUrl: ReturnType<typeof useProfileUrl>
    invalidateProfilesCache: () => void
}

interface StoreProps {
    address: AddressValue
    addressState: Response.IdentityAuthenticated['user']['address_state']
    citizenships: State['citizenships']
    email: string
    isDependent: boolean
    isDependentWithPrescribedOwner: boolean
    isPrescribed: boolean
    jurisdiction: Model.User['jurisdiction']
    natureAndPurpose: Response.IdentityNatureAndPurpose | null
    phone: string
    preferredName: string
}

interface DispatchProps {
    updateDetails(
        preferredName: string,
        phone: string,
        email: string,
        invalidPreferredNames?: string[],
    ): Promise<Response.Error | undefined>
    updateDependentDetails(
        preferredName: string,
        invalidPreferredNames?: string[],
        address?: AddressValue,
    ): Promise<Response.Error | undefined>
    getCitizenships(): void
    getNatureAndPurpose(): void
}

type AccountPersonalDetailsProps = StoreProps & DispatchProps

export default connect<StoreProps, DispatchProps, {}>(
    state => {
        const {
            identity: {user},
        } = state

        return {
            address: user!.address!,
            addressState: user!.address_state,
            citizenships: state.identity.citizenships,
            email: user!.email || '',
            isDependent: user!.is_dependent,
            isDependentWithPrescribedOwner: user!.is_owner_prescribed,
            isPrescribed: !!(user && user.prescribed_participant),
            jurisdiction: user!.jurisdiction,
            natureAndPurpose: state.identity.natureAndPurpose,
            phone: user!.phone || '',
            preferredName: user!.preferred_name,
        }
    },
    dispatch => ({
        updateDetails: (preferredName, phone, email, invalidPreferredNames) =>
            dispatch(actions.UpdateDetails(preferredName, phone, email, invalidPreferredNames)),
        updateDependentDetails: (preferredName, invalidPreferredNames, address) =>
            dispatch(actions.UpdateDependentDetails(preferredName, invalidPreferredNames, address)),
        getCitizenships: () => dispatch(actions.GetCitizenships()),
        getNatureAndPurpose: () => dispatch(actions.GetNatureAndPurpose()),
    }),
)(AccountPersonalDetails)
