import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {useWatchlist} from '~/global/state-hooks/retail/useWatchlist'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useTelescope} from '~/sections/invest/sections/ai-search/hooks/useTelescope'
import {AiSearchHeader} from '~/sections/invest/sections/ai-search/pages/header/AiSearchHeader'
import {AiError} from '~/sections/invest/sections/ai-search/widgets/ai-error/AiError'
import {AiSearchInstrumentResults} from '~/sections/invest/sections/ai-search/widgets/ai-renderers/AiSearchInstrumentResults'
import {AiThesis} from '~/sections/invest/sections/ai-search/widgets/ai-renderers/AiThesis'
import {useAppSelector} from '~/store/hooks'
import styles from './pages/intro/AiSearch.scss'

const AiSearchRateLimited: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const onExit = () => {
        navigate(profileUrl('invest'))
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--ai-search-advanced-search" leftButton="back" onLeftButtonClick={onExit} />
            <Page className={styles.page} overrideDefaultTopPadding="none">
                <h1 className={cn(spacing.spaceAbove112, typographyOverrides['as-h1'])}>AI Search is offline 😴</h1>
                <p className={spacing.spaceAbove16}>
                    This may be due to maintenance, an issue with your internet connection, or something else.
                </p>
                <p className={cn(spacing.spaceAbove20, styles.grow)}>Check back again later.</p>
                <Button dataTestId="button--got-it" label="Got it" onClick={onExit} />
            </Page>
        </>
    )
}

export const AiSearch: React.FunctionComponent = () => {
    const profileUrl = useProfileUrl()
    const navigate = useNavigate()
    const {loadingState, abortRequest} = useTelescope()
    const hasTelescopeFlag = useAppSelector(s => s.identity.flags.ai_search)
    const hasSeenTelescopeIntro = useAppSelector(
        s => s.identity.user!.has_seen.ai_search_intro && s.identity.user!.has_seen.ai_search_beta_terms,
    )
    // Optimistically load watchlist to avoid triggering suspense when rending results
    useWatchlist()

    if (!hasTelescopeFlag) {
        return <Navigate to={profileUrl('invest')} replace />
    }
    if (!hasSeenTelescopeIntro) {
        return <Navigate to={profileUrl('invest/ai-search/intro')} replace />
    }
    if (loadingState === 'rate_limited') {
        return <AiSearchRateLimited />
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--ai-search-advanced-search"
                leftButton="back"
                title="AI Search"
                onLeftButtonClick={() => {
                    if (loadingState === 'processing') {
                        abortRequest()
                    }
                    navigate(-1)
                }}
            />
            <AiSearchHeader />
            <Page className={styles.page} overrideDefaultTopPadding="none">
                <AiError />
                <AiThesis />
                <AiSearchInstrumentResults />
            </Page>
        </>
    )
}
