import {Button} from '@design-system/button'
import {withFormik} from 'formik'
import React from 'react'
import {useNavigate, useSearchParams, Link} from 'react-router-dom'
import * as api from '~/api/retail'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {urlFor} from '~/global/routeGenerator'
import {spacing} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {PromiseUnwrap} from '~/global/utils/type-utilities/typeUtilities'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {validate, ErrorBox} from '~/global/widgets/form-controls'
import {Password, ResetCodeField, Number} from '~/global/widgets/form-controls/formik'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import HelpEmail from '~/global/widgets/help-email/HelpEmail'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'

interface DispatchProps {
    resetPassword(
        token: string,
        code: string,
        password: string,
        mfa_token?: string,
    ): ReturnType<ReturnType<typeof actions.ResetPassword>>
    sendSms(token: string): ReturnType<ReturnType<typeof actions.SendSms>>
}

interface PasswordResetFormValues {
    password: string
    confirm: string
    mfa_token?: string
}

interface PasswordResetFormProps {
    token: string
    code: string
    resetPassword: DispatchProps['resetPassword']
}

interface SmsFormValues {
    code: string
}

interface SmsFormProps {
    token: string
    setTooManyAttempts: () => void
    setCode: (code: string) => void
    sendSms: DispatchProps['sendSms']
}

const SmsForm = withFormik<SmsFormProps, SmsFormValues>({
    handleSubmit: async (
        {code},
        {setSubmitting, setFieldError, setStatus, props: {token, setTooManyAttempts, setCode}},
    ) => {
        try {
            const response = await api.post('identity/verify-identity-check-sms', {token, code})
            switch (response.type) {
                case 'error':
                    if (response.code === 'maximum_failed_sms_attempts') {
                        setTooManyAttempts()
                    }
                    setFieldError('code', response.message)
                    setStatus(response.message)
                    break
                case 'empty':
                    setCode(code)
                    break
                case 'internal_server_error':
                    // handle this properly
                    break
                default:
                    assertNever(response)
            }
        } catch (e) {
            setFieldError('code', unknownErrorMessage)
            setSubmitting(false)
            throw e
        }
    },
    validate: validate.generate<SmsFormValues>({
        code: [validate.required(), validate.regexp(/^\d{6}$/, 'The code should have 6 numeric digits')],
    }),
})(({handleSubmit, isSubmitting, isValid, token, sendSms}) => (
    <form onSubmit={handleSubmit}>
        <p className={spacing.spaceBelow16}>
            We've sent a reset code to your phone. Check your text messages and enter the code to reset your password.
        </p>
        <ResetCodeField
            dataTestId="text-input--6-digit-reset-code"
            name="code"
            maxLength={6}
            label="6 digit reset code"
            disabled={isSubmitting}
            autoFocus
            placeholder="123456"
        />
        <p className={spacing.spaceAbove16}>
            Having problems or didn't receive a code?{' '}
            <ButtonAsLink dataTestId="button--send-the-code-again" onClick={() => sendSms(token)}>
                Send the code again
            </ButtonAsLink>
            .
        </p>
        <p className={spacing.spaceAbove16}>
            If you’re still having problems get in touch at <HelpEmail />.
        </p>
        <Button
            label="Confirm code"
            disabled={!isValid}
            processing={isSubmitting}
            isSubmit
            pageButton
            dataTestId="button--confirm-code"
        />
    </form>
))

const PasswordResetForm = withRouter(
    withFormik<PasswordResetFormProps & WithRouterProps, PasswordResetFormValues>({
        mapPropsToErrors: () => ({
            // make the button disabled initially by setting at least one field to have an error
            password: undefined,
        }),
        handleSubmit: async (
            {password, mfa_token},
            {
                setSubmitting,
                setStatus,
                setFieldError,
                props: {
                    token,
                    code,
                    resetPassword,
                    router: {navigate},
                },
            },
        ) => {
            try {
                const response = await resetPassword(token, code, password, mfa_token)
                switch (typeof response) {
                    case 'string':
                        // MFA responses
                        setStatus(response)
                        setSubmitting(false)
                        break
                    case 'object':
                        if (response && response.errors) {
                            setSubmitting(false)
                            if (response.errors.password) {
                                setFieldError('password', response.errors.password)
                            }
                            if (response.errors.token) {
                                setStatus(response.errors.token)
                            }
                        } else {
                            // It's going to be the success response only at this point
                            sendWrapperAppMessage({type: 'passwordResetComplete'})
                            Toast('Your password has been changed')
                            navigate(urlFor(''))
                        }
                        break
                }
            } catch (e) {
                setStatus(unknownErrorMessage)
                setSubmitting(false)
                throw e
            }
        },
        validate: validate.generate<PasswordResetFormValues>({
            password: [validate.required(), validate.password()],
            confirm: [validate.required(), validate.sameAs('password', "Passwords don't match")],
            mfa_token: [],
        }),
    })(({handleSubmit, status, isSubmitting, isValid}) => {
        const statusToError = (status: string) => {
            switch (status) {
                case 'mfa_required':
                    return 'Enter the 6-digit verification code found in your authenticator app.'
                case 'mfa_invalid_token':
                    return 'Your 2FA code does not match, or has expired.'
                case 'reset_token_invalid':
                case 'reset_token_expired':
                    return (
                        <>
                            Sorry, your reset link has expired. Please{' '}
                            <Link to={urlFor('forgot-password')}>try again</Link>.
                        </>
                    )
                default:
                    return null
            }
        }
        const errorText = statusToError(status)

        return (
            <form onSubmit={handleSubmit}>
                <Password
                    dataTestId="password--new"
                    name="password"
                    label="New password"
                    helpText="Choose a nice strong one."
                    disabled={isSubmitting}
                    strengthMeter
                    autoFocus
                />
                <Password
                    dataTestId="password--confirm"
                    name="confirm"
                    label="Confirm new password"
                    disabled={isSubmitting}
                />
                {(status === 'mfa_required' || status === 'mfa_invalid_token') && (
                    <Number
                        label="2FA Code"
                        dataTestId="number-input--mfa-token"
                        name="mfa_token"
                        autoFocus
                        placeholder="000000"
                        helpText={
                            <>
                                <strong>Enter the 6-digit verification code found in your authenticator app.</strong>{' '}
                                <br />
                                Having trouble? Check out the{' '}
                                <HelpCentreLink nzArticle="5427741-reset-two-factor-authentication-2fa" /> or{' '}
                                <a href="mailto:help@sharesies.com" target="_blank" rel="noopener">
                                    get in touch
                                </a>
                                .
                            </>
                        }
                    />
                )}
                <ErrorBox message={errorText} />
                <Button
                    label="Reset password"
                    disabled={!isValid}
                    processing={isSubmitting}
                    isSubmit
                    pageButton
                    dataTestId="button--reset-password"
                />
            </form>
        )
    }),
)

export const ForgotPasswordReset: React.FunctionComponent<DispatchProps> = ({
    resetPassword,
    sendSms,
}: DispatchProps) => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const [code, setCode] = React.useState<string | undefined>()
    const [status, setStatus] = React.useState<'loading' | PromiseUnwrap<ReturnType<DispatchProps['sendSms']>>>(
        'loading',
    )
    const setTooManyAttempts = React.useCallback(() => setStatus('maximum_failed_sms_attempts'), [setStatus])

    const verificationToken = searchParams.get('token')

    React.useEffect(() => {
        if (verificationToken) {
            sendSms(verificationToken).then(setStatus)
        }
    }, [verificationToken])

    if (!verificationToken) {
        return <WeSlippedUp />
    }

    const renderContents = () => {
        switch (status) {
            case 'loading':
                return <Loading isPineapple />
            case 'unknown_error':
                navigate('/')
                return null
            case 'maximum_failed_sms_attempts':
                return (
                    <>
                        <p className={spacing.spaceBelow16}>
                            Your password cannot be reset because of too many incorrect attempts.
                        </p>
                        <p>
                            For help with your password get in touch at <HelpEmail />.
                        </p>
                        <Button
                            label="Go back to login"
                            pageButton
                            onClick={() => {
                                navigate(urlFor('login'))
                            }}
                            dataTestId="button--back-to-login"
                        />
                    </>
                )
            case 'success_no_phone':
                // If the user has no phone, send them a dummy code
                return <PasswordResetForm token={verificationToken} code="123456" resetPassword={resetPassword} />
            case 'success':
                break
            default:
                assertNever(status)
        }

        if (!code) {
            return (
                <SmsForm
                    token={verificationToken}
                    setCode={setCode}
                    setTooManyAttempts={setTooManyAttempts}
                    sendSms={sendSms}
                />
            )
        }

        return <PasswordResetForm token={verificationToken} code={code} resetPassword={resetPassword} />
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--forgot-password-reset" title="Reset password" isInlineTitle />
            <Page>{renderContents()}</Page>
        </>
    )
}

const ConnectedForgotPasswordReset = connect<{}, DispatchProps, {}>(undefined, dispatch => ({
    resetPassword: (token, code, password, mfa_token) =>
        dispatch(actions.ResetPassword(token, code, password, mfa_token)),
    sendSms: token => dispatch(actions.SendSms(token)),
}))(ForgotPasswordReset)

export default ConnectedForgotPasswordReset
