import React from 'react'
import {Period} from '~/global/utils/time-period/timePeriod'
import {InstrumentDetailsTab} from '~/store/instrument/types'
interface LocationContextType {
    getScrollPosition(): number
    tab: InstrumentDetailsTab
    period: Period
}

export const LocationContext = React.createContext<LocationContextType>({
    getScrollPosition: () => {
        throw new Error('ScrollPosition context provider does not exist')
    },
    tab: 'Overview',
    period: '1m',
})
