import {Button} from '@design-system/button'
import {Form, Formik} from 'formik'
import {useAtom} from 'jotai'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {validate} from '~/global/widgets/form-controls'
import {IRDNumber as IRDTextInput} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {FonterraPersonalSignUpCommonProps} from '~/sections/moose/sections/fonterra-personal-sign-up/FonterraPersonalSignUp'
import {stagedFonterraPersonalSignUpAtom} from '~/sections/moose/sections/fonterra-personal-sign-up/state'

interface IrdNumberFormValues {
    ird_number: string
    errorCode: string | null
}

export const IRDNumber = ({progressStep, regressStep}: FonterraPersonalSignUpCommonProps) => {
    const [stagedFonterraPersonalSignUp, updateStagedFonterraPersonalSignUp] = useAtom(stagedFonterraPersonalSignUpAtom)

    return (
        <>
            <Toolbar
                dataTestId="toolbar--fonterra-personal-signup-irdnumber"
                leftButton="back"
                onLeftButtonClick={regressStep}
                title="What’s your IRD number?"
            />
            <Page>
                <Formik
                    initialValues={
                        {
                            ird_number: stagedFonterraPersonalSignUp.irdNumber ?? '',
                            errorCode: null,
                        } as IrdNumberFormValues
                    }
                    validate={validate.generate({
                        ird_number: [validate.required(), validate.irdNumber()],
                    })}
                    onSubmit={async values => {
                        updateStagedFonterraPersonalSignUp({
                            ...stagedFonterraPersonalSignUp,
                            irdNumber: values.ird_number,
                        })
                        progressStep()
                    }}
                >
                    {({isValid, values, isSubmitting}) => (
                        <Form>
                            <IRDTextInput dataTestId="text-input--ird-number" name="ird_number" label="IRD number" />
                            <Button
                                isSubmit
                                pageButton
                                dataTestId="button--next"
                                label="Next"
                                disabled={!isValid || values.ird_number === ''}
                                processing={isSubmitting}
                                additionalClassName={spacing.spaceBelow48}
                            />
                        </Form>
                    )}
                </Formik>
            </Page>
        </>
    )
}
