import {Modal} from '@design-system/modal'
import cn from 'classnames'
import React from 'react'
import {Link} from 'react-router-dom'
import {useRetailGet} from '~/api/query/retail'
import {Response} from '~/api/retail/types'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {usePortfolioItemsById} from '~/global/state-hooks/mixed-source/usePortfolioItemsById'
import useAutoinvestDividends from '~/global/utils/use-autoinvest-dividends/useAutoinvestDividends'
import {useInstruments} from '~/global/utils/use-instruments/useInstruments'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ExpandLink} from '~/global/widgets/expand-link/ExpandLink'
import {FeedbackWithIntroText} from '~/global/widgets/feedback/Feedback'
import {SwitchField} from '~/global/widgets/form-controls'
import {InstrumentRowCompact} from '~/global/widgets/instrument-row/InstrumentRowCompact'
import instrumentRowStyles from '~/global/widgets/instrument-row/InstrumentRowCompact.scss'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {dividendOptInNextPage} from '~/sections/invest/sections/dividend-reinvestment-plans/widgets/dividend-reinvestment-available/DividendReinvestmentAvailable'
import autoinvestDividends from '~/sections/user/sections/settings/pages/dividends/assets/images/autoinvest-dividends.svg'
import {useAppSelector} from '~/store/hooks'
import {actingAsID as actingAsIDSelector, restrictedAccountWarning} from '~/store/identity/selectors'
import {Instrument} from '~/store/instrument/types'
import styles from './Dividends.scss'

interface DividendInstrumentProps {
    instrumentIds: string[]
    instruments: Record<string, Instrument | null>
}
const AutoReinvestDividendsForm: React.FC<DividendInstrumentProps> = ({instrumentIds, instruments}) => {
    const profileUrl = useProfileUrl()
    const {preferences, loadingState, setFundPreference, setPrimaryPreference} = useAutoinvestDividends()
    const {primary, funds: instrumentsToReinvest} = preferences
    const accountRestricted = useAppSelector(restrictedAccountWarning)
    const canReinvest = accountRestricted.prescribedApprovalStatus !== 'pending-approval'

    const handleFundPreferenceChange = (fundId: string, preference: boolean) => {
        if (preference === false) {
            // Turning off fund preference
            if (instrumentIds.filter(id => instrumentsToReinvest.includes(id)).length === 1) {
                // This is the last instrument turning off, turn everything off.
                setPrimaryPreference({preference: false, all_fund_ids: []})
                return
            }
        }
        setFundPreference({fund_id: fundId, preference})
    }

    instrumentIds.sort((a, b) => (instruments[a]!.name > instruments[b]!.name ? 1 : -1))

    const renderInstrument = (id: string) => {
        const instrument = instruments[id]!
        const isOn = instrumentsToReinvest.includes(id)

        return (
            <InstrumentRowCompact
                key={id}
                instrument={instrument}
                rowLinkTarget={profileUrl('invest/:instrumentSlug', {
                    instrumentSlug: instrument.urlSlug,
                })}
                rightSlot={
                    <>
                        <label>
                            <span className={styles.screenreaderOnly}>
                                Toggle dividend auto-invest for {instrument.name}
                            </span>
                            <SwitchField
                                dataTestId="checkbox--reinvest-dividend"
                                name="reinvest-dividend"
                                isTouched={true}
                                value={instrumentsToReinvest.includes(id)}
                                onChange={() => handleFundPreferenceChange(id, !isOn)}
                            />
                        </label>
                    </>
                }
            />
        )
    }

    const shownInstrumentIds = [...instrumentIds]
    let hiddenInstrumentIds: string[] = []

    if (instrumentIds.length > 7) {
        hiddenInstrumentIds = shownInstrumentIds.splice(5)
    }

    return (
        <>
            <div
                className={cn(
                    instrumentRowStyles.instrumentRowCompact,
                    spacing.spaceAbove4,
                    instrumentRowStyles.instrumentRowBorderTop,
                )}
            >
                <img src={autoinvestDividends} />
                <div>
                    <div className={instrumentRowStyles.mainContent}>
                        <h3>Auto-invest dividends</h3>
                    </div>
                </div>
                <div className={instrumentRowStyles.rightSlot}>
                    <label>
                        <span className={styles.screenreaderOnly}>Toggle controls to configure each investment</span>
                        <SwitchField
                            dataTestId="checkbox--auto-reinvest-dividends"
                            name="autoReinvestDividendsOn"
                            isTouched={true}
                            value={!!primary}
                            disabled={loadingState === 'loading' || !canReinvest}
                            onChange={() => {
                                setPrimaryPreference({preference: !primary, all_fund_ids: instrumentIds})
                            }}
                        />
                    </label>
                </div>
            </div>
            {(primary || instrumentsToReinvest.length > 0) && (
                <>
                    {shownInstrumentIds!.map(renderInstrument)}
                    {hiddenInstrumentIds.length > 0 && (
                        <>
                            <ExpandLink
                                title={`See all ${instrumentIds.length} investments`}
                                additionalClassName={spacing.spaceAbove8}
                                linkAtBottom
                            >
                                {hiddenInstrumentIds.map(renderInstrument)}
                            </ExpandLink>
                        </>
                    )}
                    <p className={styles.futureInvestments}>
                        Dividends from new investments you buy in the future will be invested automatically.
                    </p>
                </>
            )}
        </>
    )
}

const DRPListForm: React.FC<{plans: Response.DividendReinvestmentPlan[]}> = ({plans}) => {
    const profileUrl = useProfileUrl()
    const {hasSeenDRPLearn} = useAppSelector(s => ({
        hasSeenDRPLearn: s.identity.user?.has_seen.dividend_reinvestment_plan,
    }))
    const [instruments, instrumentsLoaded] = useInstruments(plans.map(p => p.fund_id))
    if (!instrumentsLoaded) {
        return <Loading />
    }

    if (plans.length === 0) {
        return <p className={spacing.spaceBelow8}>You don't have any DRPs yet, but they’ll show here if you do.</p>
    }
    return (
        <>
            {plans.map(plan => {
                const instrument = instruments[plan.fund_id]!

                return (
                    <InstrumentRowCompact
                        key={plan.id}
                        instrument={instrument}
                        rowLinkTarget={profileUrl('invest/:instrumentSlug', {
                            instrumentSlug: instrument.urlSlug,
                        })}
                        rightSlot={
                            <>
                                {plan.is_enrolled ? (
                                    <Link
                                        to={profileUrl('invest/dividend-reinvestment-manage/:instrumentSlug', {
                                            instrumentSlug: instrument.urlSlug,
                                        })}
                                    >
                                        Opted in<span className={styles.screenreaderOnly}> for {instrument.name}</span>
                                    </Link>
                                ) : (
                                    <Link to={dividendOptInNextPage(instrument, true, profileUrl, hasSeenDRPLearn)}>
                                        Opt in<span className={styles.screenreaderOnly}> for {instrument.name}</span>
                                    </Link>
                                )}
                            </>
                        }
                    />
                )
            })}
        </>
    )
}

const Dividends: React.FunctionComponent = () => {
    const [modalOpen, setModalOpen] = React.useState(false)

    const actingAsID = useAppSelector(actingAsIDSelector)
    const {jurisdiction} = useAppSelector(s => s.identity.user!)
    const {loadingState: reinvestDividendsLoadingState} = useAutoinvestDividends()

    const {data: availableDRPs, isLoading: loadingDRPs} = useRetailGet({
        path: 'dividend-reinvestment-plan/plans',
        payload: {
            acting_as_id: actingAsID,
        },
        options: {
            suspense: false,
        },
    })

    const totalPortfolioItems = usePortfolioItemsById()
    const instrumentIds = Object.keys(totalPortfolioItems).filter(id => totalPortfolioItems[id].sharesOwned > 0)
    const [instruments, allInstrumentsLoaded] = useInstruments(instrumentIds)

    const isAnyLoading = !allInstrumentsLoaded || reinvestDividendsLoadingState === 'loading' || loadingDRPs

    return (
        <>
            <Toolbar leftButton="back" dataTestId="toolbar-account-dividends" title="Manage dividends" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <HelpModal isOpen={modalOpen} setIsOpen={setModalOpen} />
                {isAnyLoading ? (
                    <Loading />
                ) : (
                    <>
                        <h2 className={cn(spacing.spaceBelow12, typographyOverrides['as-h2'])}>
                            Auto-invest your dividends
                        </h2>
                        <p className={spacing.spaceBelow16}>
                            If a dividend is paid into your Wallet, a buy order will be automatically placed for more
                            shares in the same investment.{' '}
                            <a
                                href="#"
                                onClick={e => {
                                    e.preventDefault()
                                    setModalOpen(true)
                                }}
                            >
                                Things to know before you turn this on
                            </a>
                            .
                        </p>
                        <AutoReinvestDividendsForm
                            instrumentIds={instrumentIds.filter(
                                id => !availableDRPs.plans.find(plan => plan.fund_id === id),
                            )}
                            instruments={instruments}
                        />
                        <h2 className={cn(spacing.spaceAbove32, spacing.spaceBelow12, typographyOverrides['as-h2'])}>
                            Dividend reinvestment plan
                        </h2>
                        <p className={spacing.spaceBelow16}>
                            Some investments offer a{' '}
                            <a
                                href={
                                    jurisdiction === 'au'
                                        ? 'https://www.sharesies.com.au/learn/whats-a-dividend-reinvestment-plan'
                                        : 'https://www.sharesies.nz/learn/whats-a-dividend-reinvestment-plan'
                                }
                                target="_blank"
                                rel="noopener"
                            >
                                dividend reinvestment plan (DRP)
                            </a>
                            . This means future dividends will be issued directly as shares rather than being paid into
                            your Wallet.
                        </p>
                        <DRPListForm plans={availableDRPs.plans} />
                    </>
                )}
            </Page>
            {!isAnyLoading && (
                <FeedbackWithIntroText
                    actionIdentifier="/dividends"
                    category="Automatic reinvest dividends"
                    introText="Does this help you make the most of your dividends? Let us know."
                />
            )}
        </>
    )
}

const HelpModal: React.FC<{isOpen: boolean; setIsOpen: React.Dispatch<React.SetStateAction<boolean>>}> = ({
    isOpen,
    setIsOpen,
}) => {
    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title="Auto-invest dividends"
            dataTestId="modal--autoinvest-dividends-help"
            primaryButton={{
                label: 'Ok, got it',
            }}
        >
            <p>
                Turning on auto-invest dividends will show a list of all the companies and funds in your Portfolio, but
                this doesn’t guarantee they’ll pay a dividend in the future.
            </p>
            <p>
                Transaction fees apply when a dividend is auto-invested back into a company or fund. This will be taken
                from the dividend amount you’ve received.
            </p>
            <p>
                If you’re on a pricing plan, transaction fees on auto-invested dividends will be covered by any
                available auto-invest coverage.
            </p>
            <p>
                If you turn off auto-invest dividends, or the dividend can’t be invested for whatever reason, it’ll be
                paid into your Wallet.
            </p>
            <p>
                We don’t offer advice or make personal recommendations. Always consider your individual circumstances
                and if auto-investing dividends is right for you.
            </p>
        </Modal>
    )
}

export default Dividends
