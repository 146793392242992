import {ModalLink} from '@design-system/modal'
import React from 'react'
import {HelpContentBodyRenderer} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {MarketProcessingTimesContent} from '~/sections/explore/pages/learn-static-content/LearnContent'

export const HowCompanyAndETFProcessed: React.FC = () => (
    <ModalLink
        dataTestId="modal-link--market-processing-times"
        label={MarketProcessingTimesContent.title}
        asIcon
        modalTitle={MarketProcessingTimesContent.title}
        primaryButton={{label: 'Got it'}}
    >
        <HelpContentBodyRenderer page={MarketProcessingTimesContent} />
    </ModalLink>
)

export default HowCompanyAndETFProcessed
