import {FlagProps} from '@design-system/flag'
import {TileCardProps} from '@design-system/tile-card'

export interface GenerateOverlayFlagsProps {
    essBadgeLabel?: string
    upcomingVoteCount?: number
    transferOrderInFlightText?: string
    buySellOrderInFlightText?: string
    rightsOfferInProgressText?: string
    complianceStatus?: ComplianceStatus
}

export type ComplianceStatus = 'BELOW_MIN' | 'IN_RANGE' | 'ABOVE_MAX'

export function generateOverlayFlags({
    essBadgeLabel,
    upcomingVoteCount,
    transferOrderInFlightText,
    buySellOrderInFlightText,
    rightsOfferInProgressText,
    complianceStatus,
}: GenerateOverlayFlagsProps): TileCardProps['overlayFlags'] {
    const flags: TileCardProps['overlayFlags'] = []

    if (complianceStatus) {
        flags.push(generateComplianceFlag(complianceStatus))
    }
    if (essBadgeLabel) {
        flags.push({text: essBadgeLabel, type: 'neutral', isSubtle: true})
    }
    if (upcomingVoteCount && upcomingVoteCount > 0) {
        flags.push({text: 'Upcoming vote', type: 'info'})
    }
    if (transferOrderInFlightText) {
        flags.push({text: transferOrderInFlightText, type: 'info'})
    }
    if (buySellOrderInFlightText) {
        flags.push({text: buySellOrderInFlightText, type: 'info'})
    }
    if (rightsOfferInProgressText) {
        flags.push({text: rightsOfferInProgressText, type: 'info'})
    }
    return flags
}

export function generateInFlightText(orders: Record<string, number>): string | undefined {
    const toShow = Object.entries(orders).filter(([, amount]) => !!amount)

    if (toShow.length === 0) {
        return undefined
    }
    if (toShow.length > 1) {
        return `${toShow.reduce((total, [, amount]) => total + amount, 0)} orders`
    }

    const [name, amount] = toShow[0]
    return `${amount} ${name}${amount > 1 ? 's' : ''}`
}

export function generateTransferOrderInFlightText(transferOrderCount: number, transferTotal: number) {
    if (!transferOrderCount) {
        return undefined
    }
    /*
     * transferTotal can be 0 if the CSN transfer request is for all shares on a CSN but we
     * do not yet know the total to transfer. This option is only for inbound transfers.
     */
    return `${transferOrderCount} transfer${transferOrderCount > 1 ? 's' : ''} ${transferTotal >= 0 ? 'in' : 'out'}`
}

export function generateExerciseOfferInFlightText(
    sharesOwned: number,
    exerciseApplicationCount: number,
    canShowExerciseOfferInFlight: boolean,
    exerciseDaysRemaining?: number,
) {
    if (
        !canShowExerciseOfferInFlight ||
        exerciseDaysRemaining === undefined ||
        sharesOwned <= 0 ||
        exerciseApplicationCount > 0
    ) {
        return undefined
    }
    if (exerciseDaysRemaining > 0) {
        return exerciseDaysRemaining > 1 ? `${exerciseDaysRemaining} days left to exercise` : 'Expires today'
    }
    return 'Expired'
}

function generateComplianceFlag(complianceStatus: ComplianceStatus): {
    text: string
    type: FlagProps['type']
    isSubtle?: boolean
} {
    switch (complianceStatus) {
        case 'ABOVE_MAX':
            return {
                text: 'Above max',
                type: 'warning',
            }
        case 'IN_RANGE':
            return {
                text: 'In range',
                type: 'infoAlt',
            }
        case 'BELOW_MIN':
            return {
                text: 'Below min',
                type: 'warning',
            }
    }
}
