import cn from 'classnames'
import React from 'react'
import withMeasure, {MeasureProps} from '~/global/widgets/with-measure/WithMeasure'
import styles from './PageButtonGroup.scss'

interface PageButtonProps extends MeasureProps {
    className?: string
}

export const PageButtonGroup = withMeasure(
    ({children, measuredHeight, onMeasureRef, className}: PageButtonProps & React.Props<HTMLDivElement>) => (
        <div className={cn(styles.pageButtonGroup, className)} style={{height: measuredHeight}}>
            <div ref={onMeasureRef}>{children}</div>
        </div>
    ),
)
