import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import confirmStyles from '~/global/scss/reused-styles/confirm.scss'
import {isInstrumentDerivative} from '~/global/utils/is-instrument-derivative/isInstrumentDerivative'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useInstrument} from '~/global/utils/use-instruments/useInstruments'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import orderActions from '~/store/order/actions'
import {Loading} from '../loading'
import styles from './OrderProcessMessage.scss'

interface ExchangeOrderProcessMessageProps {
    instrument: Instrument
    side: 'buy' | 'sell'
    isLimitOrder: boolean
    isTriggerOrder: boolean
    isForExtendedHours: boolean
    cancelledAfterDays?: string
}

export const ExchangeTriggerOrderProcessMessage = ({side}: {side: 'buy' | 'sell'}) => {
    return (
        <>
            <p>
                Trigger orders are sent as a market order when your trigger price is reached during regular trading
                hours—usual market order processing applies. Price changes during extended trading hours won’t trigger a{' '}
                {side} order.
            </p>
            <p>
                If your trigger price isn’t reached within 30 days, your trigger order will be automatically cancelled.
                You can cancel a pending trigger order, but you won’t be able to edit it (you’ll need to cancel it and
                place it again).
            </p>
            <p>
                Once on the market, orders may fill bit-by-bit, and at different prices. Any unfilled parts of an order
                are cancelled at the end of the trading session.{' '}
                {side === 'buy' && ' If cancelled, the money is returned to your Wallet.'}
            </p>
        </>
    )
}

export const ExchangeAutoExerciseOrderProcessMessage = ({instrument}: {instrument: Instrument}) => {
    const dispatch = useAppDispatch()
    const exerciseApplicationPointer = useAppSelector(s =>
        s.identity.applicationPointers.find(a => a.fund_id === instrument.id && a.type === 'EXERCISE'),
    )

    const exerciseApplication = useAppSelector(
        s =>
            s.order.applicationsByInstrument[instrument.id]?.applications.find(
                a => a.application_rule_id === exerciseApplicationPointer?.application_rule_id,
            ),
    )

    React.useEffect(() => {
        if (!exerciseApplication) {
            dispatch(orderActions.LoadInstrumentApplications(instrument.id))
        }
    }, [exerciseApplication])

    if (!exerciseApplicationPointer) {
        return null
    }

    if (!exerciseApplication) {
        return <Loading />
    }
    if (exerciseApplication.terms_and_conditions_html) {
        return (
            <>
                <p className={cn(confirmStyles.orderProcessMessageHeading, spacing.spaceAbove16)}>
                    About your exercise application
                </p>
                <div
                    className={styles.orderProcessMessage}
                    dangerouslySetInnerHTML={{
                        __html: exerciseApplication.terms_and_conditions_html,
                    }}
                />
            </>
        )
    }

    return null
}

const ExchangeOrderProcessMessage = ({
    instrument,
    side,
    isTriggerOrder,
    isForExtendedHours,
    cancelledAfterDays = '30',
}: ExchangeOrderProcessMessageProps) => {
    const isDerivative = isInstrumentDerivative(instrument)
    const parentInstrument = useInstrument(instrument.parentInstrumentId)

    return (
        <div className={styles.orderProcessMessage}>
            <p>All pending order and transaction fee amounts are estimates until your order fills.</p>
            <p>
                Processing times will vary (and orders might not fill) depending on: if the market is open, closed,{' '}
                {isForExtendedHours && <>in extended hours, </>}or in a trading halt; where you are in the queue; and
                the orders ahead of you.
            </p>

            {!isDerivative && (
                <>
                    {!isTriggerOrder && (
                        <p>
                            Orders may fill bit-by-bit. Any unfilled parts of an order may be cancelled if they remain
                            unfilled for {cancelledAfterDays} days or more.{' '}
                            {side === 'buy' && ' If cancelled, the money is returned to your Wallet.'}
                        </p>
                    )}
                    {isTriggerOrder && <ExchangeTriggerOrderProcessMessage side={side} />}
                    {side === 'buy' && (
                        <p>
                            Buy orders for listed investments typically settle two business days after they fill, but
                            can take longer depending on the investment.
                        </p>
                    )}
                    {side === 'sell' && (
                        <p>
                            Sell orders for listed investments typically settle two business days after they fill,
                            making it possible for you to withdraw money from the sale.
                        </p>
                    )}
                </>
            )}

            {isDerivative && (
                <>
                    <p>
                        Any unfilled parts of an order can stay on the market until the end of the{' '}
                        {shareLabel({instrument, isPlural: true})} trading period before some—or all of it—is cancelled.
                        {side === 'buy' && ' If cancelled, the money is returned to your Wallet.'}
                    </p>
                    <p>
                        By placing this order you confirm that trading {shareLabel({instrument, isPlural: true})}{' '}
                        through Sharesies is covered by our{' '}
                        <a href="https://www.sharesies.nz/terms-and-conditions" target="_blank" rel="noreferrer">
                            Terms and Conditions
                        </a>
                        , including any necessary changes to cover {shareLabel({instrument, isPlural: true})}.
                    </p>
                    <p>
                        In order to exercise {shareLabel({instrument, isPlural: true})}, you need to meet the
                        eligibility criteria in the offer document, and be located in, or have an address in New Zealand
                        or Australia.
                    </p>
                    {parentInstrument && (
                        <p>
                            Sharesies is not responsible for the terms of the {shareLabel({instrument, isPlural: true})}{' '}
                            offer, and will be paid a fee from {parentInstrument.name} for distributing it. There are
                            risks to participating in this offer and Sharesies can’t provide any financial advice—speak
                            to a financial adviser if you need help deciding whether to take part.
                        </p>
                    )}
                </>
            )}
        </div>
    )
}

export default ExchangeOrderProcessMessage
