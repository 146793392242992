import {semanticColour, SemanticColour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import React from 'react'

/**
 * SVG logo for Sharesies that handles colour mode automatically. Takes up 100%
 * of the width and height of its container.
 */

interface SharesiesLogoProps {
    colour: SemanticColour
    className: string
}
export const SharesiesLogo: React.FunctionComponent<SharesiesLogoProps> = ({colour, className}) => {
    const colourMode = useColourMode()
    const fill = semanticColour(colour, colourMode)

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.98 96.38" className={className} data-ds-icon>
            <title>Sharesies</title>
            <defs>
                <clipPath id="a" transform="translate(0 0)">
                    <rect width="66.98" height="96.38" fill="none" />
                </clipPath>
            </defs>
            <g clipPath="url(#a)">
                <path
                    d="M39.29,65.92c-2.67,0-4.84,4-4.84,8.84h9.68c0-4.88-2.16-8.84-4.84-8.84"
                    transform="translate(0 0)"
                    fill={fill}
                />
                <path
                    d="M59.46,86.56C65.34,80,67.85,70.85,66.71,60s-6-20.86-10-27.35l-.2-.33H44.63l.76,1.07c3,4.28,10.2,15.55,11.47,27.64.83,8-.81,14.53-4.76,18.91S42.1,86.48,34,86.48s-14.46-2.39-18.66-6.92C11.31,75.19,9.46,69,10,61.74,11,48.86,19.76,36.8,22.44,33.42l.87-1.11H11l-.2.31C6.38,39.29,1,49.63.11,61c-.73,10,2.11,19,8,25.29C14.24,92.89,23.2,96.38,34,96.38S53.68,93,59.46,86.56"
                    transform="translate(0 0)"
                    fill={fill}
                />
                <path
                    d="M45.09,54.68c-2.67,0-4.84,4-4.84,8.83h9.69c0-4.88-2.17-8.83-4.85-8.83"
                    transform="translate(0 0)"
                    fill={fill}
                />
                <path
                    d="M39.29,43.44c-2.67,0-4.84,4-4.84,8.83h9.68c0-4.88-2.16-8.83-4.84-8.83"
                    transform="translate(0 0)"
                    fill={fill}
                />
                <path
                    d="M24.12,24.32c6.93,3.12,9.36,12,9.37,12h0s2.44-8.87,9.37-12,16.42.11,16.42.11S57.46,12.89,46.39,13c-7.34,0-10.66,5-12,8.22,1-3.3,3.13-8.19,6.68-10.27C46.65,7.67,54.3,9.23,54.3,9.23S52.8-.07,43.88,0C35.2.07,33.5,11.31,33.49,11.36S31.79.07,23.1,0C14.18-.07,12.71,9.19,12.71,9.19s7.64-1.56,13.23,1.72c3.55,2.08,5.63,7,6.68,10.27C31.24,18,27.93,13,20.59,13,9.52,12.89,7.7,24.43,7.7,24.43s9.49-3.24,16.42-.11"
                    transform="translate(0 0)"
                    fill={fill}
                />
                <path
                    d="M17,63.51h9.69c0-4.88-2.17-8.83-4.85-8.83S17,58.63,17,63.51"
                    transform="translate(0 0)"
                    fill={fill}
                />
                <path
                    d="M27.69,43.44c-2.68,0-4.85,4-4.85,8.83h9.69c0-4.88-2.17-8.83-4.84-8.83"
                    transform="translate(0 0)"
                    fill={fill}
                />
                <path
                    d="M33.49,54.68c-2.67,0-4.84,4-4.84,8.83h9.68c0-4.88-2.17-8.83-4.84-8.83"
                    transform="translate(0 0)"
                    fill={fill}
                />
                <path
                    d="M27.69,65.92c-2.68,0-4.85,4-4.85,8.84h9.69c0-4.88-2.17-8.84-4.84-8.84"
                    transform="translate(0 0)"
                    fill={fill}
                />
            </g>
        </svg>
    )
}

export default SharesiesLogo
