/**
 * Use this util SPARINGLY. It is much better to do feature detection than
 * browser detection.
 *
 * If you're trying to figure out if we're in the context of a native
 * application, see `isWrapperApp` instead.
 *
 * See https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser for more info on this util.
 */
export const isMobile = {
    Android: () => {
        return navigator.userAgent.match(/Android/i)
    },
    BlackBerry: () => {
        return navigator.userAgent.match(/BlackBerry/i)
    },
    iOS: () => {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
    },
    iOSSafari: () => {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i) && window.outerHeight === 0
    },
    Opera: () => {
        return navigator.userAgent.match(/Opera Mini/i)
    },
    Windows: () => {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i)
    },
    any: () => {
        return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows()
    },
}
