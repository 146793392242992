import cn from 'classnames'
import React from 'react'
import {DistillScope} from '~/api/query/distill'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {
    calculateManagedFundFee,
    calculateManagedFundUnits,
} from '~/global/utils/calculate-managed-fund-info/calculateManagedFundInfo'
import {
    autoExerciseBuyCostBreakdown,
    autoExerciseRemainingCostBreakdown,
} from '~/global/utils/exercise-cost/exerciseCost'
import {dateFormatNoTime, dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import {howManyShares} from '~/global/utils/how-many-shares/howManyShares'
import {isOnASX, isOnNZX} from '~/global/utils/is-on-exchange/isOnExchange'
import {isAutoInvestOrder, sortTradesByDate, sumTradeShareVolumes} from '~/global/utils/order/order'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import useAssetManager from '~/global/utils/use-asset-manager/useAssetManager'
import {ADR} from '~/global/widgets/help-modals/ADR'
import BuyAndSellFeesListedInstruments from '~/global/widgets/help-modals/BuyAndSellFeesListedInstruments'
import BuyAndSellFeesRights from '~/global/widgets/help-modals/BuyAndSellFeesRights'
import {HowCompanyAndETFProcessed} from '~/global/widgets/help-modals/HowCompanyAndETFProcessed'
import {HowManagedFundProcessed} from '~/global/widgets/help-modals/HowManagedFundProcessed'
import {
    PartialFillOrderInfoModal,
    partialFillOrderInfoModalTitle,
} from '~/global/widgets/help-modals/contract-notes/PartialFillInfo'
import {DollarValue, FeeValue, SharePriceValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import MarketDepthLink from '~/global/widgets/nzx-market-depth-link/MarketDepthLink'
import {BuySellOrder, SellOrder, BuyOrder} from '~/store/accounting/types'
import {Instrument} from '~/store/instrument/types'
import styles from '../Record.scss'
import {RecordRow} from '../common'
import {TradesConcertina} from './TradesConcertina'
import {OrderPlacedRow, SharesBoughtOrSold, TransactionFeeInfo} from './common'

export const PendingAndProcessingOrdersDataRow: React.FC<{
    currency: string
    instrument: Instrument
    jurisdiction: Model.User['jurisdiction']
    order: BuySellOrder
    partiallyFilled: boolean
}> = React.memo(({currency, instrument, jurisdiction, order, partiallyFilled}) => {
    const [concertinaIsOpen, setConcertinaIsOpen] = React.useState(false)

    const assetManager = useAssetManager(instrument, {scope: DistillScope.INVEST})
    const fee = order.expected_fee ? Number(order.expected_fee) : 0
    const isAutoinvest = isAutoInvestOrder(order)
    const sharesTotalInTrades = sumTradeShareVolumes(order)
    const isAutoExercise = order.type === 'buy' && order.is_auto_exercise
    const orderInfoModalTitle = partialFillOrderInfoModalTitle(instrument, order.type, isAutoExercise)
    const rightsTradingClosesBeforeExpiry =
        order.order_expires &&
        instrument.lastTradingDateTimeUtc &&
        instrument.instrumentType === 'rights' &&
        order.order_expires > instrument.lastTradingDateTimeUtc

    const partiallyFilledRemainingValue = () => {
        if (order.type === 'buy') {
            // remaining currency for an auto exercise buy needs to consider the amount we will use for exercising
            if (order.is_auto_exercise) {
                const estimatedRemainingCurrency = autoExerciseRemainingCostBreakdown(order, instrument, jurisdiction)
                    ?.estimatedCostToBuy
                return <DollarValue value={estimatedRemainingCurrency || '0'} currency={currency} />
            }
            return <DollarValue value={order.remaining_currency || '0'} currency={currency} />
        }
        return <ShareValue value={order.shares_remaining} />
    }

    const displayFeeModal = () => {
        if (instrument.isAdr) {
            return <ADR jurisdiction={jurisdiction} />
        }
        if (isAutoExercise) {
            return <BuyAndSellFeesRights asIcon />
        }
        return <BuyAndSellFeesListedInstruments />
    }

    const handleConcertinaClick = () => {
        if (concertinaIsOpen) {
            setTimeout(() => {
                setConcertinaIsOpen((concertinaIsOpen: boolean) => !concertinaIsOpen)
            }, 300)
        } else {
            setConcertinaIsOpen((concertinaIsOpen: boolean) => !concertinaIsOpen)
        }
    }

    const autoExerciseRightsAndFee = (order: BuyOrder) => {
        if (!order.requested_currency) {
            return
        }

        const orderHasFee = !!(order.expected_fee && parseFloat(order.expected_fee) > 0)
        const costBreakdown = autoExerciseBuyCostBreakdown(
            instrument,
            order.requested_currency,
            jurisdiction,
            order.price_limit,
            orderHasFee,
        )

        if (!costBreakdown) {
            return
        }
        return {
            autoExerciseEstimatedRights: costBreakdown.estimatedRightsToBuy,
            autoExerciseEstimatedFee: costBreakdown.estimatedFee,
        }
    }

    const autoExerciseEstimatedRightsAndFee = order.type === 'buy' ? autoExerciseRightsAndFee(order) : undefined

    return (
        <div className={styles.recordRowContainer}>
            <OrderPlacedRow date={order.created} />

            {/* Trigger price */}
            {(order as SellOrder).trigger_price && (
                <RecordRow
                    left={`${shareLabel({instrument, isCapitalised: true})} price to trigger ${order.type} order`}
                    right={<SharePriceValue value={(order as SellOrder).trigger_price!} currency={currency} />}
                />
            )}

            {tradingType(instrument) === 'managed' && order.will_settle_on && (
                <RecordRow
                    className={styles.recordRowWithDate}
                    left={
                        <div>
                            Expected settlement date <HowManagedFundProcessed />
                        </div>
                    }
                    right={order.will_settle_on.toFormat(dateFormatNoTime)}
                />
            )}
            {tradingType(instrument) === 'listed' && order.order_expires && !rightsTradingClosesBeforeExpiry && (
                <RecordRow
                    className={styles.recordRowWithDate}
                    left={
                        <div>
                            Order expires
                            <HowCompanyAndETFProcessed />
                        </div>
                    }
                    right={order.order_expires.toFormat(dateFormatWithYear)}
                />
            )}
            {tradingType(instrument) === 'listed' && rightsTradingClosesBeforeExpiry && (
                <RecordRow
                    className={styles.recordRowWithDate}
                    left="Order expires at"
                    right={instrument.lastTradingDateTimeUtc!.toFormat(dateFormatWithYear)}
                />
            )}
            {tradingType(instrument) === 'managed' && (
                <>
                    <RecordRow
                        left={
                            order.type === 'buy'
                                ? 'Estimated ' +
                                  shareLabel({
                                      instrument,
                                      isPlural: calculateManagedFundUnits({order, instrument}) !== 1,
                                  })
                                : shareLabel({
                                      instrument,
                                      isPlural: parseFloat(order.shares) !== 1,
                                      isCapitalised: true,
                                  })
                        }
                        right={
                            <ShareValue
                                value={
                                    order.type === 'buy' ? calculateManagedFundUnits({order, instrument}) : order.shares
                                }
                            />
                        }
                    />
                    <RecordRow
                        left="Estimated price per unit"
                        right={
                            <SharePriceValue
                                value={order.order_unit_price ? order.order_unit_price : instrument.marketPrice}
                                currency={currency}
                            />
                        }
                    />
                </>
            )}
            {tradingType(instrument) === 'listed' && order.price_limit && !partiallyFilled && (
                <RecordRow
                    left={
                        order.type === 'buy' && !order.requested_shares
                            ? `Estimated number of ${shareLabel({instrument, isPlural: true})} to buy`
                            : `Number of ${shareLabel({instrument, isPlural: true})}`
                    }
                    right={
                        order.type === 'buy' ? (
                            <>
                                {!order.is_auto_exercise ? (
                                    <ShareValue
                                        value={
                                            order.requested_shares
                                                ? order.requested_shares
                                                : order.requested_currency
                                                  ? howManyShares({
                                                        marketPrice: parseFloat(instrument.marketPrice),
                                                        priceLimit: parseFloat(order.price_limit || '0'),
                                                        fee,
                                                        amount: order.requested_currency,
                                                    })
                                                  : '' // This shouldn't be hit
                                        }
                                        showFullValue
                                    />
                                ) : (
                                    <ShareValue
                                        value={autoExerciseEstimatedRightsAndFee?.autoExerciseEstimatedRights || 0}
                                        showFullValue
                                    />
                                )}
                            </>
                        ) : order.shares ? (
                            <ShareValue value={order.shares} showFullValue />
                        ) : (
                            '-'
                        )
                    }
                />
            )}
            {/* Highest or lowest price to pay per share - Limit buys and sells */}
            {order.price_limit && (
                <RecordRow
                    left={`${order.type === 'buy' ? 'Highest' : 'Lowest'} price to ${
                        order.type === 'buy' ? 'pay' : 'sell'
                    } per ${shareLabel({instrument})}`}
                    right={order.price_limit ? <SharePriceValue value={order.price_limit} currency={currency} /> : '-'}
                />
            )}
            {/* Shares/receipts bought or sold */}
            {partiallyFilled && (
                <SharesBoughtOrSold
                    hideBottomBorder={partiallyFilled}
                    instrument={instrument}
                    orderType={order.type}
                    shareValue={sharesTotalInTrades.toString()}
                />
            )}
            {/* Breakdown of trades */}
            {partiallyFilled && (
                <div className={cn(styles.hasBottomBorder, spacing.spaceAbove12)}>
                    {sortTradesByDate(order).map((trade, idx) => (
                        <TradesConcertina
                            key={trade.contract_note_number}
                            concertinaIsOpen={concertinaIsOpen}
                            trade={trade}
                            currency={currency}
                            handleConcertinaClick={handleConcertinaClick}
                            instrument={instrument}
                            hasSubsequentEntry={order.trades.length > idx + 1}
                            jurisdiction={jurisdiction}
                        />
                    ))}
                </div>
            )}
            {/* Remaining amount/shares */}
            {partiallyFilled && (
                <RecordRow
                    left={
                        <div>
                            {orderInfoModalTitle}
                            <PartialFillOrderInfoModal
                                instrument={instrument}
                                type={order.type}
                                title={orderInfoModalTitle}
                            />
                        </div>
                    }
                    right={partiallyFilledRemainingValue()}
                />
            )}
            {/* Individual companies and ETFs - Transaction fees depends on the asset manager, region, and jurisdiction of investor*/}
            {!partiallyFilled && tradingType(instrument) !== 'managed' && fee !== 0 && (
                <RecordRow
                    left={
                        <div>
                            Estimated transaction fee
                            {isOnASX(instrument) && jurisdiction === 'au' && ' incl. GST '}
                            {displayFeeModal()}
                        </div>
                    }
                    right={
                        <>
                            {isAutoExercise ? (
                                <FeeValue
                                    value={autoExerciseEstimatedRightsAndFee?.autoExerciseEstimatedFee || 0}
                                    currency={currency}
                                />
                            ) : (
                                <FeeValue value={fee} currency={currency} />
                            )}
                        </>
                    }
                />
            )}
            {
                /* Autoinvest buys with NZ ETFs - only in place until transaction fees on autoinvest are implemented */
                isAutoinvest && tradingType(instrument) !== 'managed' && fee === 0 && (
                    <RecordRow
                        left={
                            <div>
                                Transaction fee <BuyAndSellFeesListedInstruments />
                            </div>
                        }
                        right={<FeeValue value={0} currency={currency} />}
                    />
                )
            }
            {/* Managed fund - No transaction fees  */}
            {tradingType(instrument) === 'managed' && !instrument.fixedFeeSpread && (
                <RecordRow left={<div>There are no transaction fees charged by this fund</div>} right={null} />
            )}
            {/* Managed fund - Transaction fees depends on the asset manager */}
            {tradingType(instrument) === 'managed' && instrument.fixedFeeSpread && assetManager && (
                <RecordRow
                    left={<TransactionFeeInfo assetManagerName={assetManager.shortName} isEstimated={true} />}
                    right={<FeeValue value={calculateManagedFundFee({order, instrument})} currency={currency} />}
                />
            )}
            {isOnNZX(instrument) && jurisdiction === 'nz' && (
                <MarketDepthLink
                    instrumentSlug={instrument.urlSlug}
                    tradingType={tradingType(instrument)}
                    type="record-buy-sell"
                />
            )}
        </div>
    )
})
