/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    ComparisonPriceDto,
    ComparisonPriceDtoFromJSON,
    ComparisonPriceDtoFromJSONTyped,
    ComparisonPriceDtoToJSON,
    ComparisonPriceDtoToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface ComparisonPricesDto
 */
export interface ComparisonPricesDto {
    /**
     *
     * @type {ComparisonPriceDto}
     * @memberof ComparisonPricesDto
     */
    _1d?: ComparisonPriceDto
    /**
     *
     * @type {ComparisonPriceDto}
     * @memberof ComparisonPricesDto
     */
    _1w?: ComparisonPriceDto
    /**
     *
     * @type {ComparisonPriceDto}
     * @memberof ComparisonPricesDto
     */
    _1m?: ComparisonPriceDto
    /**
     *
     * @type {ComparisonPriceDto}
     * @memberof ComparisonPricesDto
     */
    _3m?: ComparisonPriceDto
    /**
     *
     * @type {ComparisonPriceDto}
     * @memberof ComparisonPricesDto
     */
    _6m?: ComparisonPriceDto
    /**
     *
     * @type {ComparisonPriceDto}
     * @memberof ComparisonPricesDto
     */
    _1y?: ComparisonPriceDto
    /**
     *
     * @type {ComparisonPriceDto}
     * @memberof ComparisonPricesDto
     */
    _5y?: ComparisonPriceDto
}

export function ComparisonPricesDtoFromJSON(json: any): ComparisonPricesDto {
    return ComparisonPricesDtoFromJSONTyped(json, false)
}

export function ComparisonPricesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComparisonPricesDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        _1d: !exists(json, '1d') ? undefined : ComparisonPriceDtoFromJSON(json['1d']),
        _1w: !exists(json, '1w') ? undefined : ComparisonPriceDtoFromJSON(json['1w']),
        _1m: !exists(json, '1m') ? undefined : ComparisonPriceDtoFromJSON(json['1m']),
        _3m: !exists(json, '3m') ? undefined : ComparisonPriceDtoFromJSON(json['3m']),
        _6m: !exists(json, '6m') ? undefined : ComparisonPriceDtoFromJSON(json['6m']),
        _1y: !exists(json, '1y') ? undefined : ComparisonPriceDtoFromJSON(json['1y']),
        _5y: !exists(json, '5y') ? undefined : ComparisonPriceDtoFromJSON(json['5y']),
    }
}

export function ComparisonPricesDtoToJSON(value?: ComparisonPricesDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        '1d': ComparisonPriceDtoToJSON(value._1d),
        '1w': ComparisonPriceDtoToJSON(value._1w),
        '1m': ComparisonPriceDtoToJSON(value._1m),
        '3m': ComparisonPriceDtoToJSON(value._3m),
        '6m': ComparisonPriceDtoToJSON(value._6m),
        '1y': ComparisonPriceDtoToJSON(value._1y),
        '5y': ComparisonPriceDtoToJSON(value._5y),
    }
}
