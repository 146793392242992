import cn from 'classnames'
import React from 'react'
import {Link} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import recurringTileStyle from '~/global/widgets/recurring-transaction-tile/RecurringTile.scss'
import styles from './AlertCard.scss'

export const ALERT_CARD_TYPES = ['none', 'warning', 'info'] as const
export type AlertCardType = (typeof ALERT_CARD_TYPES)[number]

// For links - pass in url for internal link or href for external link.  Or to act as a button, pass a callback function
export interface AlertCardProps {
    className?: string
    type: AlertCardType
    title?: string
    text?: React.ReactNode
    links?: {
        text: string
        url?: string
        href?: string
        sameWindow?: boolean
        callback?: () => void
        dataTestId?: string
    }[]
}

const classFor: Record<AlertCardProps['type'], string> = {
    none: '',
    warning: styles.typeWarning,
    info: styles.typeInfo,
}

export const AlertCard: React.FunctionComponent<AlertCardProps> = ({className, type, title, text, links, children}) => {
    return (
        <div className={cn(className, styles.alertCard, recurringTileStyle.alert, classFor[type])}>
            {title && <h4 className={cn(styles.title, spacing.spaceBelow4)}>{title}</h4>}
            <p className={links || children ? spacing.spaceBelow4 : undefined}>{text}</p>
            {children && (
                <div className={cn(text ? spacing.spaceAbove16 : styles.noSpaceAbove, spacing.spaceBelow4)}>
                    {children}
                </div>
            )}
            {links &&
                links.map(({text, url, href, sameWindow, callback, dataTestId}, i) => {
                    if (url) {
                        return (
                            <Link key={i} to={url} onClick={callback} data-test-id={dataTestId}>
                                {text}
                            </Link>
                        )
                    }
                    if (href) {
                        const openProps = !sameWindow ? {target: '_blank', rel: 'noopener noreferrer'} : {}
                        return (
                            <a key={i} {...openProps} href={href} data-test-id={dataTestId}>
                                {text}
                            </a>
                        )
                    }
                    if (callback) {
                        return (
                            <ButtonAsLink
                                className={styles.buttonAsLink}
                                key={i}
                                onClick={callback}
                                dataTestId={dataTestId}
                            >
                                {text}
                            </ButtonAsLink>
                        )
                    }
                })}
        </div>
    )
}

export default AlertCard
