import {Button} from '@design-system/button'
import {useSetAtom, SetStateAction, useAtom} from 'jotai'
import React from 'react'
import {NavigateFunction, useNavigate} from 'react-router'
import {useRetailPost} from '~/api/query/retail'
import {Request} from '~/api/retail/types'
import * as rollbar from '~/api/rollbar/rollbar'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Toast} from '~/global/widgets/toast/Toast'
import Transfer from '~/sections/save/sections/transfer/Transfer'
import {stagedNameAtom} from '~/sections/save/state/createAccountState'
import {stagedTransferAtom} from '~/sections/save/state/transferState'
import {StagedTransferOrder, TransferAccountDetails} from '~/store/save/types'
import {FormValues} from './widgets/transfer-form/TransferForm'

type SetAtom<Args extends any[], Result> = (...args: Args) => Result

const handleSubmit = (
    values: FormValues,
    setStagedTransfer: SetAtom<[SetStateAction<StagedTransferOrder | undefined>], void>,
    navigate: NavigateFunction,
    profileUrl: ReturnType<typeof useProfileUrl>,
    meta?: {[key: string]: string},
) => {
    // A generic submit method that all Save forms should call

    const instruction = values.targetAccount.type === 'wallet' ? 'WITHDRAW' : 'DEPOSIT'

    setStagedTransfer({
        originAccount: values.originAccount,
        targetAccount: values.targetAccount,
        amount: values.amount,
        instruction,
        interval: values.interval,
        nextDate: values.startDate,
        meta,
    })

    navigate(profileUrl('wallet/confirm-transfer'))
}

export const TransferMoney: React.FunctionComponent = () => {
    const setStagedTransfer = useSetAtom(stagedTransferAtom)
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    return (
        <Transfer
            handleSubmit={(values: FormValues) => handleSubmit(values, setStagedTransfer, navigate, profileUrl)}
            title="Transfer"
        />
    )
}

export const SaveOnboardingTransfer: React.FunctionComponent = () => {
    const setStagedTransfer = useSetAtom(stagedTransferAtom)
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const profile = useProfile()
    const createAccount = useRetailPost({path: 'save/v2/open-account'})
    const [stagedName, setStagedName] = useAtom(stagedNameAtom)

    const targetAccount: TransferAccountDetails = {
        portfolioId: 'temp', // Setting a temp ID that will be replaced when the target account is created
        name: stagedName || 'New Save account',
        balance: '0',
        type: 'save',
    }

    const openAccountPayload: Request.OpenSaveAccountV2 = {type_: 'SAVE', name: stagedName, owner_id: profile.owner_id}

    const submit = async (values: FormValues) => {
        try {
            await createAccount.mutateAsync(openAccountPayload, {
                onSuccess: response => {
                    values.targetAccount.portfolioId = response.account.portfolio_id
                    values.targetAccount.saveAccountId = response.account.id
                    setStagedName(undefined)

                    handleSubmit(values, setStagedTransfer, navigate, profileUrl)
                },
            })
        } catch (error) {
            Toast('There was an error opening your new Save account')
            if (error instanceof Error && error.message) {
                rollbar.sendError(`save account open: unexpected error`, {
                    message: error.message,
                })
            }
        }
    }

    const submitSkipTransfer = async () => {
        try {
            await createAccount.mutateAsync(openAccountPayload, {
                onSuccess: response => {
                    navigate(profileUrl('save/:portfolioId', {portfolioId: response.account.portfolio_id}))
                    Toast('Save account created! 💌')
                },
            })
        } catch (error) {
            Toast('There was an error opening your new Save account')
            if (error instanceof Error && error.message) {
                rollbar.sendError(`save account open: unexpected error`, {
                    message: error.message,
                })
            }
        }
    }

    const AdditionalActionButton = (
        <Button dataTestId="button--transfer-skip" type="secondary" label="Skip" onClick={submitSkipTransfer} />
    )

    return (
        <Transfer
            handleSubmit={submit}
            defaultTargetAccount={targetAccount}
            title="Kickstart your Savings"
            additionalActionButton={AdditionalActionButton}
        />
    )
}

interface SaveRecurringTransferProps {
    portfolioId: string
    recurringTransferId?: string
}

export const SaveRecurringTransfer: React.FunctionComponent<SaveRecurringTransferProps> = ({
    portfolioId,
    recurringTransferId,
}) => {
    const setStagedTransfer = useSetAtom(stagedTransferAtom)
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const deleteRecurringTransfer = useRetailPost({
        path: 'save/v2/:portfolio_id/recurring-transfer-state',
        pathParams: {portfolio_id: portfolioId},
        queryCacheToUpdate: ['save/v2/:portfolio_id/get-recurring-transfers', {portfolio_id: portfolioId}],
    })
    const meta = recurringTransferId ? {recurringTransferId} : undefined

    const handleDeleteRecurringTransfer = async () => {
        try {
            await deleteRecurringTransfer.mutateAsync(
                {time_event_id: recurringTransferId!, state: 'ARCHIVED'},
                {
                    onSuccess: () => {
                        setStagedTransfer(undefined)
                        navigate(profileUrl('save/:portfolioId', {portfolioId}))
                    },
                },
            )
        } catch (error) {
            Toast('There was an error deleting your recurring transfer order')
            if (error instanceof Error && error.message) {
                rollbar.sendError(`recurring transfer delete: unexpected error`, {
                    message: error.message,
                })
            }
        }
    }

    const AdditionalActionButton = (
        <Button
            dataTestId="button--delete-recurring"
            type="secondary"
            label="Delete"
            onClick={handleDeleteRecurringTransfer}
        />
    )

    return (
        <Transfer
            handleSubmit={(values: FormValues) => handleSubmit(values, setStagedTransfer, navigate, profileUrl, meta)}
            title={recurringTransferId ? 'Update recurring transfer' : 'Create a recurring transfer'}
            recurringByDefault
            additionalActionButton={recurringTransferId ? AdditionalActionButton : undefined}
        />
    )
}
