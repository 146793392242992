interface ConditionalWrapperProps {
    condition: boolean
    wrapper: (child: JSX.Element) => JSX.Element
    children: JSX.Element
}

/**
 * @deprecated use `@design-system/conditional-wrapper` instead
 */
const ConditionalWrapper = ({condition, wrapper, children}: ConditionalWrapperProps): JSX.Element => {
    return condition ? wrapper(children) : children
}

export default ConditionalWrapper
