import React from 'react'
import {Navigate} from 'react-router-dom'
import {useRetailGet} from '~/api/query/retail'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import recordStyles from '~/global/widgets/instrument-activity/Record.scss'
import {RecordRow} from '~/global/widgets/instrument-activity/common'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import {transactionTitle} from '~/sections/save/sections/activity/utils/transaction-title/transactionTitle'
import {useAppSelector} from '~/store/hooks'

interface TransactionLineViewProps {
    transactionId: string
    transactionKey: string
    portfolioId: string
}

const TransactionLineView: React.FunctionComponent<TransactionLineViewProps> = ({
    transactionId,
    transactionKey,
    portfolioId,
}) => {
    const profileUrl = useProfileUrl()

    const {data} = useRetailGet({
        path: 'save/v2/:portfolio_id/get-transaction',
        payload: {
            transaction_id: transactionId,
            transaction_key: transactionKey,
        },
        pathParams: {portfolio_id: portfolioId},
    })

    const currentTransaction = data.transaction

    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)

    const isSaveTransfer = currentTransaction?.description === 'Save transfer'
    const isWalletTransfer =
        currentTransaction?.description === 'Save withdrawal' || currentTransaction?.description === 'Save deposit'

    if (!currentTransaction) {
        return <Navigate to={profileUrl('save/:portfolioId', {portfolioId})} replace />
    }

    const saveOriginName = () => {
        if (isSaveTransfer) {
            return currentTransaction.origin_account_name || 'Save'
        }
        return 'Wallet'
    }

    const saveTargetName = () => {
        if (isSaveTransfer) {
            return currentTransaction.target_account_name || 'Save'
        }
        return 'Wallet'
    }

    const direction = currentTransaction.transaction_type === 'DEPOSIT' ? 'From' : 'To'

    return (
        <>
            <div className={recordStyles.header}>
                <PageBack />
            </div>
            <Page withoutDefaultPadding>
                <div className={recordStyles.viewContainer}>
                    <div className={recordStyles.titleBlock}>
                        <h2>{transactionTitle(currentTransaction)}</h2>
                    </div>
                    <div className={recordStyles.orderContent}>
                        <div className={recordStyles.recordRowContainer}>
                            <RecordRow
                                className={recordStyles.recordRowWithDate}
                                left="Date"
                                right={currentTransaction.timestamp.toFormat(dateFormatNoTime)}
                            />
                            {(isSaveTransfer || isWalletTransfer) && (
                                <RecordRow
                                    className={recordStyles.recordRowWithDate}
                                    left={direction}
                                    right={
                                        currentTransaction.transaction_type === 'DEPOSIT'
                                            ? saveOriginName()
                                            : saveTargetName()
                                    }
                                />
                            )}
                        </div>

                        <p className={recordStyles.orderAmountLabel}>Amount</p>

                        <div className={recordStyles.orderFooter}>
                            <p className={recordStyles.statusLabel} data-testid="orderStatusLabel">
                                {currentTransaction.state === 'RECONCILED' ? 'Completed' : 'Processing'}
                            </p>

                            <p className={recordStyles.orderAmount}>
                                <DollarValue value={currentTransaction.amount} currency={homeCurrency} />
                            </p>
                        </div>
                    </div>
                </div>
            </Page>
        </>
    )
}

export default TransactionLineView
