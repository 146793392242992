import cn from 'classnames'
import React from 'react'
import {Model} from '~/api/retail/types'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import amex from '~/global/widgets/credit-card-handling/assets/images/amex.svg'
import dinersclub from '~/global/widgets/credit-card-handling/assets/images/dinersclub.svg'
import discover from '~/global/widgets/credit-card-handling/assets/images/discover.svg'
import jcb from '~/global/widgets/credit-card-handling/assets/images/jcb.svg'
import mastercard from '~/global/widgets/credit-card-handling/assets/images/mastercard.svg'
import unionpay from '~/global/widgets/credit-card-handling/assets/images/unionpay.svg'
import visa from '~/global/widgets/credit-card-handling/assets/images/visa.svg'
import styles from './AccountsCardList.scss'

export const cardImages = {
    Visa: visa,
    'American Express': amex,
    MasterCard: mastercard,
    Discover: discover,
    JCB: jcb,
    'Diners Club': dinersclub,
    'Union Pay': unionpay,
    Unknown: undefined,
}

interface Props {
    source: Model.StripeSource
    className?: string
    edit?(): void
    border?: boolean
}

const AccountsCardList = ({source, className, edit, border}: Props) => (
    <div className={cn(styles.cardList, className, {[styles.border]: border})}>
        <img src={cardImages[source.brand]} alt={`${source.brand} card type`} className={styles.cardType} />
        <div className={styles.textContainer}>
            <p aria-label="With the last 4 digits ending in: ">**** **** **** {source.last4}</p>
            <p>
                <span aria-label="Expiring">Exp.</span> {source.exp_month}/{source.exp_year}
            </p>
        </div>
        {edit && <ButtonAsLink onClick={edit}>Edit</ButtonAsLink>}
    </div>
)

export default AccountsCardList
