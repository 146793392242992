import {AddressValue} from '@design-system/address-input'
import {Button} from '@design-system/button'
import {Formik} from 'formik'
import React from 'react'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {processAddressComponents} from '~/global/utils/format-address/formatAddress'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AccountVerificationRequired from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import {ErrorBox} from '~/global/widgets/form-controls'
import {AddressValidation, ManualAddressFields} from '~/global/widgets/manual-address-fields/ManualAddressFields'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'

const EditAddress = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const owner = useProfileOwner()

    const {address} = useRetailGet({
        path: 'owner/:owner_id/address',
        pathParams: {owner_id: owner.id},
    }).data

    const setAddress = useRetailPost({
        path: 'owner/:owner_id/address',
        pathParams: {owner_id: owner.id},
        queryCacheToUpdate: [`owner/:owner_id/address`, {owner_id: owner.id}],
    })

    return (
        <>
            <Toolbar dataTestId="toolbar--edit-address" leftButton="back" title="Edit address" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <AccountVerificationRequired hideEditAddress />
                <Formik
                    initialValues={
                        (address?.components as any) ?? {
                            street_number: '',
                            route: '',
                            sublocality: '',
                            locality: '',
                            state_code: '',
                            postal_code: '',
                            country_code: '',
                        }
                    }
                    onSubmit={async (
                        {street_number, route, sublocality, locality, state_code, postal_code, country_code},
                        {setStatus},
                    ) => {
                        const address: AddressValue = processAddressComponents({
                            street_number,
                            route,
                            locality,
                            postal_code,
                            country_code,
                            state_code,
                            sublocality,
                        })

                        try {
                            const result = await setAddress.mutateAsync({address})

                            if (result.address_verification_required) {
                                navigate(profileUrl('settings/personal-details/edit-address/address-verification'))
                            } else {
                                Toast('Success! Your address has been saved.')
                                navigate(profileUrl('settings/personal-details'))
                            }
                        } catch (e) {
                            setStatus(unknownErrorMessage)
                            throw e
                        }
                    }}
                    validate={AddressValidation}
                >
                    {({values, handleSubmit, isValid, dirty, isSubmitting, status}) => (
                        <form onSubmit={handleSubmit}>
                            <ManualAddressFields
                                selectedCountry={values.country_code}
                                jurisdiction={owner.product_jurisdiction}
                            />

                            {status && <ErrorBox message={status} />}

                            <Button
                                isSubmit
                                pageButton
                                label="Save changes"
                                disabled={!isValid || !dirty}
                                processing={isSubmitting}
                                dataTestId="button--save-address"
                            />
                        </form>
                    )}
                </Formik>
            </Page>
        </>
    )
}

export default EditAddress
