import {ModalLink} from '@design-system/modal'
import React from 'react'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {Instrument} from '~/store/instrument/types'
import {SharePriceValue} from '../number-elements/NumberElements'

interface BuyAndAutoExerciseCostProps {
    instrument: Instrument
}

export const BuyAndAutoExerciseCost: React.FunctionComponent<BuyAndAutoExerciseCostProps> = ({instrument}) => {
    const shareLabelPlural = shareLabel({instrument, isPlural: true})
    const shareLabelSingular = shareLabel({instrument})

    if (!instrument.exercisePrice) {
        return null
    }

    return (
        <ModalLink
            dataTestId="modal-link--buy-auto-exercise-cost"
            label="buy and auto exercise cost"
            asIcon
            modalTitle="Estimated cost to exercise"
            primaryButton={{label: 'OK, I understand'}}
            bottomBorder
            helpIconSize={16}
        >
            <p>
                The cost to exercise each {shareLabelSingular} is fixed at{' '}
                <SharePriceValue value={instrument.exercisePrice} currency={instrument.currency} /> per{' '}
                {shareLabelSingular}, but the total cost to exercise will go up or down based on the number of{' '}
                {shareLabelPlural} (or fractions of a {shareLabelSingular}) we’re able to buy at the market price.
            </p>
        </ModalLink>
    )
}

export default BuyAndAutoExerciseCost
