/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {DateTime} from 'luxon'
import * as runtime from '../runtime'
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    Instruments,
    InstrumentsFromJSON,
    InstrumentsToJSON,
    Msg,
    MsgFromJSON,
    MsgToJSON,
} from '../models'

export interface CreateInstrumentsApiV1InstrumentsCreateInstrumentsPostRequest {
    instruments: Instruments
}

/**
 *
 */
export class InstrumentsApi extends runtime.BaseAPI {
    /**
     * Create Instruments
     */
    async createInstrumentsApiV1InstrumentsCreateInstrumentsPostRaw(
        requestParameters: CreateInstrumentsApiV1InstrumentsCreateInstrumentsPostRequest,
    ): Promise<runtime.ApiResponse<Msg>> {
        if (requestParameters.instruments === null || requestParameters.instruments === undefined) {
            throw new runtime.RequiredError(
                'instruments',
                'Required parameter requestParameters.instruments was null or undefined when calling createInstrumentsApiV1InstrumentsCreateInstrumentsPost.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/instruments/create-instruments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstrumentsToJSON(requestParameters.instruments),
        })

        return new runtime.JSONApiResponse(response, jsonValue => MsgFromJSON(jsonValue))
    }

    /**
     * Create Instruments
     */
    async createInstrumentsApiV1InstrumentsCreateInstrumentsPost(
        requestParameters: CreateInstrumentsApiV1InstrumentsCreateInstrumentsPostRequest,
    ): Promise<Msg> {
        const response = await this.createInstrumentsApiV1InstrumentsCreateInstrumentsPostRaw(requestParameters)
        return await response.value()
    }
}
