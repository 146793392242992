import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/invest/sections/bank-linking/BankLinking.scss'
import {useAppSelector} from '~/store/hooks'

interface SelectDebitBankAccountProps {
    onBack: () => void
    onNext: () => void
}
const DebitAccountConfirmed = ({onBack, onNext}: SelectDebitBankAccountProps) => {
    const accountName = useAppSelector(({bankLinking}) => {
        if (bankLinking.basiqAccountsLoadingState !== 'ready') {
            return 'account'
        }

        return (
            bankLinking.basiqAccountsResponse!.accounts.find(
                acc => acc.basiq_account_id === bankLinking.roundupsDebitAccount!.basiqAccountId,
            )?.account_name ?? 'account'
        )
    })

    return (
        <>
            <Toolbar dataTestId="toolbar--roundups-tracking" leftButton="back" onLeftButtonClick={onBack} />
            <Page>
                <h1 className={cn(styles.h1, spacing.spaceBelow20)}>We've confirmed your bank account</h1>
                <p className={spacing.spaceBelow20}>
                    We’ll save your {accountName}, and use it as the default account for sending money from.
                </p>
                <p>
                    You can change this at any time in <strong>Account {'>'} Bank accounts and cards.</strong>
                </p>
            </Page>
            <ActionBar>
                <Button dataTestId="button--next" label="Sounds good" onClick={onNext} />
            </ActionBar>
        </>
    )
}

export default DebitAccountConfirmed
