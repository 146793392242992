import {DateTime} from 'luxon'
import {
    KIDS_RECOMMENDED_LABEL,
    RISK_LEVEL_LABEL,
    UNLISTED_INSTRUMENTS_LABEL,
} from '~/global/constants/categoryAndSearchLabels'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {
    isInstrumentInHalt,
    isInstrumentInactive,
} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {isInstrumentEligibleForExtendedHours} from '~/global/utils/is-instrument-eligible-for-extended-hours/isInstrumentEligibleForExtendedHours'
import {isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {isOrderTypeEligibleForExtendedHours} from '~/global/utils/is-order-type-eligible-for-extended-hours/isOrderTypeEligibleForExtendedHours'
import {StagedSellOrder, StagedBuyOrder} from '~/store/order/types'
import {createSelector, createCachedSelector} from '../selectors'
import {Instrument, CycleMode, RelativeInstrumentIds} from './types'

/** This checks for market hours only: pre- & post-market hours (extended hours) are not part of this check. */
export const selectIsExchangeInMarketHours = createSelector(
    ({instrument}, exchangeName: string) =>
        instrument.metadata.exchanges.find(exchange => exchange.name === exchangeName),
    (_, __, checkTimeCacheKey) => checkTimeCacheKey, // selector will recompute if this differs
    (exchange, _): boolean => {
        const matchingDate = exchange
            ? exchange.openHours.find(
                  openDay =>
                      DateTime.local() >= openDay.start.toLocal() && DateTime.local() <= openDay.finish.toLocal(),
              )
            : undefined

        return !!matchingDate
    },
)

export const selectExchangeMetadata = createCachedSelector(
    ({instrument}, exchangeName: string) =>
        instrument.metadata.exchanges.find(exchange => exchange.name === exchangeName),
    exchange => exchange,
)((_, exchangeName) => exchangeName)

export const selectExchangeForInstrument = createSelector(
    (state, instrumentExchange) => selectExchangeMetadata(state, instrumentExchange),
    (state, instrumentExchange) =>
        selectIsExchangeInMarketHours(state, instrumentExchange, DateTime.local().startOf('minute').toISO()), // cache for one minute
    (exchange, isExchangeInMarketHours) => {
        return {
            exchange,
            isExchangeInMarketHours,
        }
    },
)

/**
 * Is the instrument in extended hours, i.e. the pre- or post-market trading sessions that bookend regular market hours.
 *
 * This check only considers extended hours - it will be false if the instrument is currently in regular market hours.
 * Also note that extended hours are only relevant for US instruments (except US OTC).
 *
 * @returns a boolean if the instrument is NOT in extended hours. i.e. if the response is a boolean, it will always be false.
 * @returns an object if the instrument IS in extended hours, with fields indicating whether the instrument is currently in the
 * pre-market or post-market trading session.
 */
export const selectIsInstrumentInExtendedHours = createSelector(
    (state, instrument: Instrument) =>
        instrument.exchange ? selectExchangeMetadata(state, instrument.exchange) : undefined,
    state => state.identity.portfolioExtendedHoursPreference,
    (_, instrument: Instrument) => isInstrumentEligibleForExtendedHours(instrument),
    (_, instrument: Instrument) => isInstrumentInHalt(instrument),
    (_, instrument: Instrument) => isInstrumentInactive(instrument),
    (_, instrument: Instrument) => isOnMainUsExchange(instrument),
    (_, instrument, orderType?: StagedSellOrder['orderType'] | StagedBuyOrder['orderType']) =>
        orderType ? isOrderTypeEligibleForExtendedHours(orderType, instrument) : true, // if arg not supplied, default to allowed
    (
        exchange,
        extendedHoursEnabled,
        isInstrumentEligibleForExtendedHours,
        isInHalt,
        isInactive,
        isOnMainUsExchange,
        isOrderTypeEligibleForExtendedHours,
    ): boolean | {inPreTrading: boolean; inPostTrading: boolean} => {
        if (
            !exchange || // couldn't determine the exchange info
            !extendedHoursEnabled || // must be enabled in account -> portfolio
            !isInstrumentEligibleForExtendedHours ||
            !isOrderTypeEligibleForExtendedHours ||
            isInHalt ||
            isInactive ||
            !isOnMainUsExchange // Extended hours are not relevant for NZX, ASX, or US OTC instruments
        ) {
            return false
        }

        // TODO in future we should get this info from Distill rather than basing it on regular open hours and hardcoding these offsets
        const preTradingPeriod = {hours: 5, minutes: 30} // 5.5h before the regular 9:30am open, i.e. 4am ET
        const postTradingPeriod = {hours: 4} // 4h after the regular 4pm close, i.e. 8pm ET

        const localNow = DateTime.local()

        let inPreTrading = false
        let inPostTrading = false

        for (const openDay of exchange.openHours) {
            const startOfPreTrading = openDay.start.minus(preTradingPeriod).toLocal()
            const endOfPreTrading = openDay.start.toLocal()
            const startOfPostTrading = openDay.finish.toLocal()
            const endOfPostTrading = openDay.finish.plus(postTradingPeriod).toLocal()

            if (localNow >= startOfPreTrading && localNow <= endOfPreTrading) {
                inPreTrading = true
                break
            }

            if (localNow >= startOfPostTrading && localNow <= endOfPostTrading) {
                inPostTrading = true
                break
            }
        }

        if (!inPreTrading && !inPostTrading) {
            return false
        }
        return {
            inPreTrading,
            inPostTrading,
        }
    },
)

export const sortedCurrentFilters = createSelector(
    ({instrument}) => instrument.currentSearchFilters,
    (currentFilters): string[] => {
        const conflatedFilters = [
            ...currentFilters.instrumentTypes,
            ...currentFilters.categories,
            ...currentFilters.exchanges,
            ...(currentFilters.unlistedInstruments ? [UNLISTED_INSTRUMENTS_LABEL] : []),
            ...(currentFilters.kidsRecommended ? [KIDS_RECOMMENDED_LABEL] : []),
        ]

        if (currentFilters.riskLevel && !(currentFilters.riskLevel[0] === 1 && currentFilters.riskLevel[1] === 7)) {
            return [
                ...conflatedFilters,
                currentFilters.riskLevel[0] === currentFilters.riskLevel[1]
                    ? `${RISK_LEVEL_LABEL} ${currentFilters.riskLevel[0]}`
                    : `${RISK_LEVEL_LABEL} ${currentFilters.riskLevel[0]} - ${currentFilters.riskLevel[1]}`,
            ].sort()
        }
        return conflatedFilters.sort()
    },
)

export const instrumentCategoryLabels = createCachedSelector(
    (state, instrument: Instrument) => ({
        instrumentTypes: state.instrument.metadata.instrumentTypes,
        isDependant: state.identity.user ? state.identity.user.is_dependent : false,
        instrument,
    }),
    ({instrumentTypes, isDependant, instrument}): string[] => {
        const typesExchangesAndCategories: string[] = []
        const type = instrumentTypes.filter(type => type.id === instrument.instrumentType)
        if (instrument.kidsRecommended && isDependant) {
            typesExchangesAndCategories.push(KIDS_RECOMMENDED_LABEL)
        }
        if (type.length) {
            // check the length before we set this, in case instrumentTypes is not yet populated
            typesExchangesAndCategories.push(type[0].name)
        }
        if (instrument.exchange) {
            typesExchangesAndCategories.push(instrument.exchange)
        } else {
            typesExchangesAndCategories.push(UNLISTED_INSTRUMENTS_LABEL)
        }
        typesExchangesAndCategories.push(...instrument.categories)
        return typesExchangesAndCategories
    },
)((_, instrument) => `categories.${instrument.id}`)

export const getInstrumentFromList = createCachedSelector(
    ({instrument}, cycleMode: CycleMode, instrumentSlug: string) => ({
        currentResultsPage: instrument.currentResultsPage,
        numberOfResultsPages: instrument.numberOfResultsPages,
        currentPortfolioPage: instrument.currentPortfolioPage,
        numberOfPortfolioPages: instrument.numberOfPortfolioPages,
        resultsIndex: instrument.resultsIndex,
        recentlyViewedIndex: instrument.recentlyViewedIndex,
        watchlistIndex: instrument.watchlistIndex,
        portfolioIndex: instrument.portfolioIndex,
        premadeResponsibleAIIndex: instrument.premadeResponsibleAIIndex,
        premadeGlobalAIIndex: instrument.premadeGlobalAIIndex,
        premadeKidsAIIndex: instrument.premadeKidsAIIndex,
        diyAIIndex: instrument.diyAIIndex,
        transferableIndex: instrument.transferableIndex,
        slugsToIdMap: instrument.slugToIdMap,
        cycleMode,
        instrumentSlug,
    }),
    ({
        currentResultsPage,
        numberOfResultsPages,
        currentPortfolioPage,
        numberOfPortfolioPages,
        resultsIndex,
        recentlyViewedIndex,
        watchlistIndex,
        portfolioIndex,
        premadeResponsibleAIIndex,
        premadeGlobalAIIndex,
        premadeKidsAIIndex,
        diyAIIndex,
        transferableIndex,
        slugsToIdMap,
        cycleMode,
        instrumentSlug,
    }): {
        instrumentId?: string
        relativeIds: RelativeInstrumentIds
        lastResultBeforeNewPage: boolean
    } => {
        const emptyResponse = {
            instrumentId: undefined,
            relativeIds: {prev: undefined, next: undefined},
            lastResultBeforeNewPage: false,
        }
        let instruments: string[] = []

        let hasNextPage = false
        // Currently not using pagination here for DIY list as less than 60 results
        // this may need to change in future as more instruments included.
        switch (cycleMode) {
            case 'searchResults':
                instruments = resultsIndex
                hasNextPage =
                    currentResultsPage && numberOfResultsPages ? currentResultsPage !== numberOfResultsPages : false
                break
            case 'recentlyViewed':
                instruments = recentlyViewedIndex
                break
            case 'watchlist':
                instruments = watchlistIndex
                break
            case 'investPortfolio':
                instruments = portfolioIndex
                hasNextPage =
                    currentPortfolioPage && numberOfPortfolioPages
                        ? currentPortfolioPage !== numberOfPortfolioPages
                        : false
                break
            case 'premadeResponsibleAutoInvest':
                instruments = premadeResponsibleAIIndex
                break
            case 'premadeGlobalAutoInvest':
                instruments = premadeGlobalAIIndex
                break
            case 'premadeKidsAutoInvest':
                instruments = premadeKidsAIIndex
                break
            case 'diyAutoInvest':
                instruments = diyAIIndex
                break
            case 'transferShares':
                instruments = transferableIndex
                break
            case 'fonterra':
                instruments = resultsIndex
                break
            default:
                assertNever(cycleMode)
        }

        // look up the ID if we have the instrument loaded
        const id = slugsToIdMap[instrumentSlug]
        if (!id) {
            return emptyResponse
        }

        // in the case of directly loading a single result by slug we might have the instrument itself but no list to cycle
        const index = instruments.indexOf(id)
        if (index === -1) {
            return {...emptyResponse, instrumentId: id}
        }

        // we do have a list to cycle, find the next and previous items
        const prevIndex = (index - 1 + instruments.length) % instruments.length
        const nextIndex = (index + 1) % instruments.length
        return {
            instrumentId: instruments[index],
            relativeIds: {
                prev: instruments[prevIndex],
                next: instruments[nextIndex],
            },
            lastResultBeforeNewPage: hasNextPage && index + 1 === instruments.length,
        }
    },
)((_, instrumentId, cycleMode) => `${cycleMode}.${instrumentId}`)

const instrumentMarketPrice = createCachedSelector(
    ({instrument}, instrumentId: string) => instrument.instrumentsById[instrumentId].marketLastCheck,
    ({instrument}, instrumentId: string) => instrument.instrumentsById[instrumentId].marketPrice,
    (marketLastCheck?: DateTime, marketPrice?: string): InstrumentPrice | undefined => {
        if (!marketLastCheck || !marketPrice) {
            return undefined
        }
        return {
            date: marketLastCheck.toISODate(),
            price: marketPrice ? parseFloat(marketPrice) : 0,
        }
    },
)((_, instrumentId) => instrumentId)

export interface InstrumentPrice {
    date: string
    price: number
}

export const instrumentPrices = createCachedSelector(
    (state, instrumentId: string) => {
        const instrument = state.instrument.instrumentsById[instrumentId]
        if (instrument && instrument.priceHistory) {
            return instrument.priceHistory.dayPrices
        }
        return undefined
    },
    instrumentMarketPrice,
    (dayPrices, marketPrice) => {
        const fundPrices: InstrumentPrice[] = []
        if (!dayPrices) {
            return fundPrices
        }
        const dates = Object.keys(dayPrices)
        dates.sort()
        for (const date of dates) {
            fundPrices.push({
                date,
                price: parseFloat(dayPrices[date]),
            })
        }

        if (
            marketPrice &&
            fundPrices[fundPrices.length - 1] &&
            marketPrice.date > fundPrices[fundPrices.length - 1].date
        ) {
            fundPrices.push(marketPrice)
        }
        return fundPrices
    },
)((_, fundId) => fundId)

export const getInstrumentFromSlug = createCachedSelector(
    (state, instrumentSlug: string) => {
        for (const instrumentId in state.instrument.instrumentsById) {
            if (state.instrument.instrumentsById[instrumentId].urlSlug === instrumentSlug) {
                return state.instrument.instrumentsById[instrumentId]
            }
        }
    },
    (instrument?: Instrument) => instrument,
)((_, instrumentSlug) => instrumentSlug)
