import {Button} from '@design-system/button'
import cn from 'classnames'
import {withFormik} from 'formik'
import React from 'react'
import * as api from '~/api/retail'
import {spacing} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {UserSuitableError} from '~/global/utils/error-handling/errorHandling'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {validate, Text} from '~/global/widgets/form-controls'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {Link, useNavigate} from '~/migrate-react-router'
import styles from '~/sections/user/sections/share-the-love/sections/gifts/Gifts.scss'
import store from '~/store'
import accountingActions from '~/store/accounting/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import {actingAsID} from '~/store/identity/selectors'

interface FormValues {
    giftCode: string
}

interface FormProps {
    success(): void
    accountRestricted: boolean
    redeemGiftCode(giftCode: string): Promise<null | string>
    fetchNewTransactions(): void
}

const Form = withFormik<FormProps, FormValues>({
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        giftCode: undefined,
    }),
    handleSubmit: async (
        {giftCode},
        {setSubmitting, setFieldError, setStatus, props: {redeemGiftCode, fetchNewTransactions, success}},
    ) => {
        try {
            const response = await api.post('gifting/subscribe-check', {
                gift_code: giftCode.replace(/-/g, ''),
                acting_as_id: actingAsID(store.getState()),
            })
            switch (response.type) {
                case 'error':
                    setFieldError('giftCode', response.message)
                    break
                case 'gifting_information':
                    const error = await redeemGiftCode(response.gift.gift_code)
                    if (error) {
                        setStatus({error})
                        break
                    }

                    await fetchNewTransactions()

                    success()
                    break
                case 'internal_server_error':
                    setStatus({error: 'An error occurred redeeming your gift. Please try again.'})
                    break
                default:
                    assertNever(response)
            }
        } catch (e) {
            if (e instanceof UserSuitableError) {
                setFieldError('giftCode', e.message)
                setSubmitting(false)
            } else {
                setFieldError('giftCode', unknownErrorMessage)
                setSubmitting(false)
                throw e
            }
        }
    },
    validate: validate.generate<FormValues>({
        giftCode: [validate.required()],
    }),
})(({handleSubmit, isSubmitting, isValid, handleChange, status, errors, accountRestricted}) => (
    <form onSubmit={handleSubmit}>
        <Text
            dataTestId="text-input--gift-code"
            placeholder="ABC-DEF-123-456"
            name="giftCode"
            isTouched
            onChange={handleChange}
            helpText={errors.giftCode}
        />
        <Button
            label="Show me the money"
            disabled={!isValid || accountRestricted}
            processing={isSubmitting}
            isSubmit
            dataTestId="button--show-me-the-money"
            pageButton
        />

        {status?.error && (
            <p className={cn(spacing.spaceAbove24, spacing.spaceBelow24, styles.error)}>{status.error}</p>
        )}
    </form>
))

export const RedeemGift: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const transactions = useAppSelector(s => s.accounting.walletTransactions)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const accountRestricted = useAppSelector(s => s.identity.user!.account_restricted)
    const dispatch = useAppDispatch()

    const redeemGiftCode = (giftCode: string) => dispatch(identityActions.redeemGiftCode(giftCode))
    const fetchNewTransactions = () => dispatch(accountingActions.FetchNewTransactions())

    const [showSuccess, setShowSuccess] = React.useState(false)

    const value = transactions.length && (
        <DollarValue value={transactions[0].amount} decimalPlaces={transactions[0].amount.includes('.00') ? 0 : 2} />
    )

    return (
        <>
            <Toolbar dataTestId="toolbar--redeem-gift" leftButton={!showSuccess ? 'back' : undefined} />

            <Page overrideDefaultTopPadding="withToolbarTitle">
                {!showSuccess && (
                    <>
                        <h1 className={cn(styles.redeemHeader, spacing.spaceBelow16)}>Redeem a Gift</h1>
                        <p className={spacing.spaceBelow24}>To redeem your Gift, enter its 12-digit code:</p>
                        <Form
                            redeemGiftCode={redeemGiftCode}
                            fetchNewTransactions={fetchNewTransactions}
                            success={() => setShowSuccess(true)}
                            accountRestricted={accountRestricted}
                        />
                    </>
                )}

                {showSuccess && transactions.length && (
                    <>
                        <div className={styles.giftCard}>{value}</div>
                        <h1 className={cn(styles.giftHeader, spacing.spaceBelow8)}>
                            Woo! A {value} {transactions[0].currency.toUpperCase()} Gift has been added to your Wallet
                        </h1>
                        <span>
                            Wondering what to invest it in?{' '}
                            <Link to={profileUrl('invest/search')}>Check out all our investments</Link>. {}
                        </span>
                        {jurisdiction === 'nz' && (
                            <span>
                                Or invest regularly without the guesswork with{' '}
                                <Link to={profileUrl('auto-invest')}>auto-invest</Link>.
                            </span>
                        )}
                        <Button
                            onClick={() => navigate(profileUrl(''), {replace: true})}
                            pageButton
                            label="Go to Portfolio"
                            dataTestId="button--go-to-portfolio"
                        />
                    </>
                )}
            </Page>
        </>
    )
}

export default RedeemGift
