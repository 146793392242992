import React from 'react'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {isNzxCsn} from '~/global/utils/share-transfers/shareTransfers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import transferActions from '~/store/transfer/actions'
import {ChooseCSNForm} from './widgets/choose-csn-form/ChooseCSNForm'

export type csnChoice = 'new' | 'existing'

export const ChooseCSN: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const {stagedTransferOrder, registryDetails} = useAppSelector(({transfer}) => transfer)

    const jurisdiction = useAppSelector(({identity}) => identity.user!.jurisdiction)

    const availableCsns = registryDetails
        .filter(
            registryDetail =>
                ['CSN', 'REQUEST_CSN'].includes(registryDetail.reference_type) &&
                ['approved', 'pending', 'incomplete'].includes(registryDetail.state),
        )
        .sort((a, b) => b.created.toMillis() - a.created.toMillis())

    if (!stagedTransferOrder || !stagedTransferOrder.direction) {
        navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})
        return <></>
    }

    const handleSuccess = (newOrExistingCsn: csnChoice, csn: string) => {
        switch (newOrExistingCsn) {
            case 'existing':
                const registryDetail = registryDetails.find(
                    registryDetail => registryDetail.reference === csn || registryDetail.reference_type === csn,
                )!

                dispatch(
                    transferActions.SetStagedTransferOrder({
                        ...stagedTransferOrder,
                        reference: registryDetail.reference,
                        referenceType: registryDetail.reference_type,
                        registryDetailId: registryDetail.id,
                    }),
                )
                if (stagedTransferOrder.direction === 'in' && isNzxCsn(csn) && jurisdiction === 'nz') {
                    // If the reference chosen looks like a CSN offer to Transfer All in
                    // We don't show the transfer all option for non-NZ jurisdiction due to tax reasons
                    navigate(profileUrl('invest/portfolio-transfer-shares/nzx/transfer-all'))
                } else {
                    // If the reference looks like a Holder Number, outbound or not NZ jurisdiction, skip Transfer All
                    navigate(profileUrl('invest/portfolio-transfer-shares/:exchange/select', {exchange: 'nzx'}))
                }
                break
            case 'new':
                navigate(profileUrl('invest/portfolio-transfer-shares/nzx/add-csn'))
                break
            default:
                assertNever(newOrExistingCsn)
        }
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--add-investor-details" leftButton="back" />
            <ChooseCSNForm
                handleSuccess={handleSuccess}
                availableCsns={availableCsns}
                maybeStagedTransferOrderDirection={stagedTransferOrder.direction}
            />
        </>
    )
}

export default ChooseCSN
