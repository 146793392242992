import {createAction, ActionsUnion} from '../redux-tools'
import {Portfolio, LatestInstrumentReturns, State} from './types'

const actions = {
    SetPortfolio: (portfolio: Portfolio) => createAction('portfolio.SetPortfolio', portfolio),
    SetLatestInstrumentReturns: (latestInstrumentReturns: LatestInstrumentReturns) =>
        createAction('portfolio.SetLatestInstrumentReturns', latestInstrumentReturns),
    SetInstrumentInvestingHistory: (instrumentInvestingHistory: State['instrumentInvestingHistory']) =>
        createAction('portfolio.SetInstrumentInvestingHistory', instrumentInvestingHistory),
    SetSummaryLoadingState: (loadingState: State['summaryLoadingState']) =>
        createAction('portfolio.SetSummaryLoadingState', loadingState),
    SetInstrumentReturnsLoadingState: (loadingState: State['instrumentReturnsLoadingState']) =>
        createAction('portfolio.SetInstrumentReturnsLoadingState', loadingState),
    SetInstrumentInvestingHistoryLoadingState: (loadingState: State['instrumentInvestingHistoryLoadingState']) =>
        createAction('portfolio.SetInstrumentInvestingHistoryLoadingState', loadingState),
    ClearPortfolio: () => createAction('portfolio.ClearPortfolio'),
}

export type ActionsType = ActionsUnion<typeof actions>

export default actions
