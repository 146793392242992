// only numbers and letters
export const alphanumericOnly = (value: string) => value.replace(/[^a-zA-Z0-9]/g, '')

// only numbers, and only up to four characters
export const fourDigitsOnly = (value: string) => value.replace(/[^0-9]/g, '').substring(0, 4)

// NZ Car number plate
export const carNumberPlate = (value: string) => {
    // Only numbers and letters
    value = value.replace(/[^a-zA-Z0-9]/g, '')
    // Only allow length up to 6
    value = value.substring(0, 6)
    // Capitalise
    value = value.toUpperCase()

    return value
}
