import cn from 'classnames'
import React from 'react'
import {CommonProps, FocusInjectedProps, withFocus, withId} from '~/global/widgets/form-controls/common'
import styles from './PercentageInput.scss'

// Types for Amount and Percentage inputs
type StringProps = CommonProps<string> &
    FocusInjectedProps & {
        additionalClassName?: string
    }

const Percentage: React.FunctionComponent<StringProps> = React.memo(
    ({error, isFocused, value, setFieldValue, label, onBlur, onFocus, isTouched, id, name, dataTestId}) => {
        const clearZeroOnSelect = (e: React.FocusEvent<unknown> | string) => {
            if (value === '0' && setFieldValue) {
                setFieldValue('', false)
            }

            onFocus(e)
        }

        return (
            <div
                className={cn(styles.container, styles.percentageContainer, {
                    [styles.hasError]: isTouched && !!error,
                    [styles.hasFocus]: isFocused,
                })}
            >
                <label htmlFor={id} className={styles.hiddenLabel}>
                    {label}
                </label>
                <div className={cn(styles.input, styles.withPercentage)}>
                    <div className={styles.measure}>
                        <span role="presentation">{value}</span>
                        <input
                            data-testid={dataTestId}
                            id={id}
                            name={name}
                            autoComplete="off"
                            type="text"
                            inputMode="numeric"
                            value={value}
                            onChange={e => {
                                const value = e.currentTarget.value
                                if (setFieldValue && value.match(/^\d{0,3}$/)) {
                                    setFieldValue(value)
                                }
                            }}
                            onBlur={onBlur}
                            onFocus={clearZeroOnSelect}
                        />
                    </div>
                </div>
            </div>
        )
    },
)
const PercentageInput = withId(withFocus(Percentage))

export {PercentageInput}
