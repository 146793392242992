import {Button} from '@design-system/button'
import cn from 'classnames'
import Decimal from 'decimal.js'
import {Form, Formik} from 'formik'
import {useAtom} from 'jotai'
import React from 'react'
import {Navigate, useNavigate} from 'react-router'
import {page, spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {ExchangeHours} from '~/global/widgets/exchange-hours/ExchangeHours'
import {validate} from '~/global/widgets/form-controls'
import {StrongCurrency, StrongNumber} from '~/global/widgets/form-controls/formik'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import {Link} from '~/migrate-react-router'
import {useDistillInstrumentWithCurrency} from '~/sections/moose/data/distill'
import {useHoldingDetail} from '~/sections/moose/data/retail'
import {MooseOrderPathParams} from '~/sections/moose/routes'
import {
    FONTERRA_COOPERATIVE_GROUP as FONTERRA_COOPERATIVE_GROUP_ID,
    ItemType,
    LimitBuyOrder,
    buyOrderAtom,
} from '~/sections/moose/sections/order/state'
import {
    HoldingDetailForSymbol,
    getHoldingDetailsForSymbol,
} from '~/sections/moose/sections/order/utils/get-holding-details-for-symbol/getHoldingDetailsForSymbol'
import {isUnsupportedInstrument} from '~/sections/moose/sections/order/utils/is-unsupported-instrument/isUnsupportedInstrument'
import {shareLabel} from '~/sections/moose/sections/order/utils/share-label/shareLabel'
import {helpForShareholdingOrder} from '~/sections/moose/sections/order/widgets/help-for-shareholding-order/HelpForShareholdingOrder'
import {OrderFormHeader} from '~/sections/moose/sections/order/widgets/order-form-header/OrderFormHeader'
import {itemTypeFromSymbol} from '~/sections/moose/sections/utils/symbol-helpers/symbolHelpers'
import {useFonterraPortfolio} from '~/sections/moose/state/local'
import MooseWalletBalance from '~/sections/moose/widgets/moose-wallet-balance/MooseWalletBalance'
import {Instrument} from '~/store/instrument/types'
import styles from './LimitBuyPage.scss'

interface LimitBuyPageFormValues {
    numberOfItemsToBuy: string
    highestPricePerItem: string
}

function calculateEstimatedAmount(highestPricePerItem: string, numberOfItemsToBuy: string): Decimal {
    if (!highestPricePerItem || !numberOfItemsToBuy || highestPricePerItem === '' || numberOfItemsToBuy === '') {
        return new Decimal(0)
    }

    const price = new Decimal(highestPricePerItem)
    const itemCount = new Decimal(numberOfItemsToBuy)
    return price.mul(itemCount)
}

function generateValidatorsForNumberOfItems(holding: HoldingDetailForSymbol, itemTypeInLowerCase: string) {
    const validators: any[] = [validate.required(), validate.minimum(1, `Must be at least 1 ${itemTypeInLowerCase}`)]
    if (holding.maxAllowedToBuy) {
        validators.push(
            validate.maximum(
                holding.maxAllowedToBuy,
                'Buying this number of shares reaches the maximum shareholding. Please reduce the amount to buy.',
            ),
        )
    }
    return validators
}

function hasEnoughMoneyInWallet(values: LimitBuyPageFormValues, currentWalletBalance: number) {
    const balance = new Decimal(currentWalletBalance)
    return balance.greaterThanOrEqualTo(calculateEstimatedAmount(values.numberOfItemsToBuy, values.highestPricePerItem))
}

const isTradingFacilityEnabled = (
    values: LimitBuyPageFormValues,
    currentWalletBalance: number,
    instrument: Instrument,
    shareholdingType: string,
): boolean => {
    return (
        instrument.id === FONTERRA_COOPERATIVE_GROUP_ID &&
        values.highestPricePerItem !== '' &&
        values.numberOfItemsToBuy !== '' &&
        currentWalletBalance >= 0 &&
        [
            'Supplying Shareholder',
            'Entering Supplying Shareholder',
            'Secondary Shareholder',
            'Associated Shareholder – Sharemilker',
        ].includes(shareholdingType) &&
        calculateEstimatedAmount(values.highestPricePerItem, values.numberOfItemsToBuy)
            .minus(new Decimal(currentWalletBalance))
            .lessThanOrEqualTo(new Decimal(20_000))
    )
}

export const LimitBuyPage: React.FunctionComponent<MooseOrderPathParams> = ({
    portfolioId,
    urlSlug,
}: MooseOrderPathParams) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const instrument = useDistillInstrumentWithCurrency(urlSlug)
    const holdingDetail = useHoldingDetail(portfolioId)
    const [initialStagedBuyOrder, updateStagedBuyOrder] = useAtom(buyOrderAtom)
    const walletPortfolio = useFonterraPortfolio().wallet

    React.useEffect(() => {
        let updatedStagedBuyOrder: LimitBuyOrder
        if (!initialStagedBuyOrder) {
            const itemType: ItemType = itemTypeFromSymbol(instrument.symbol)

            updatedStagedBuyOrder = {
                instrument,
                itemType,
                useDelayedSettlement: false,
            }

            updateStagedBuyOrder(updatedStagedBuyOrder)
        }
    }, [initialStagedBuyOrder])

    if (!instrument || isUnsupportedInstrument(urlSlug)) {
        // Redirect back to the portfolio because the instrument is missing or not supported
        return <Navigate to={profileUrl('fonterra/portfolios/:portfolioId', {portfolioId})} replace />
    }

    if (!initialStagedBuyOrder) {
        return null // TODO return loader instead? Revisit as part of FTP-279 limit sell order flow
    }

    const stagedBuyOrder = initialStagedBuyOrder
    const instrumentSymbol = instrument.symbol
    const instrumentHoldingDetail = getHoldingDetailsForSymbol(holdingDetail, instrumentSymbol)
    const currentWalletBalance = parseFloat(walletPortfolio.balance)

    return (
        <>
            <Page>
                <PageBack />
                <ExchangeHours instrument={stagedBuyOrder.instrument} />
                <OrderFormHeader instrument={stagedBuyOrder.instrument} buyOrderType="share_limit" buyOrSell="buy" />
                <Formik
                    initialValues={
                        {
                            numberOfItemsToBuy: stagedBuyOrder.numberOfItems
                                ? stagedBuyOrder.numberOfItems.toString()
                                : '',
                            highestPricePerItem: stagedBuyOrder.pricePerItem
                                ? stagedBuyOrder.pricePerItem.toString()
                                : '',
                        } as LimitBuyPageFormValues
                    }
                    validate={validate.generate({
                        highestPricePerItem: [
                            validate.required(),
                            validate.money(),
                            validate.minimum(1, 'Must be at least $1'),
                            validate.orderlyLimit(stagedBuyOrder.instrument),
                        ],
                        numberOfItemsToBuy: generateValidatorsForNumberOfItems(
                            instrumentHoldingDetail,
                            shareLabel({itemType: stagedBuyOrder.itemType}),
                        ),
                    })}
                    onSubmit={values => {
                        const useTradingFacility =
                            !hasEnoughMoneyInWallet(values, currentWalletBalance) &&
                            isTradingFacilityEnabled(
                                values,
                                currentWalletBalance,
                                stagedBuyOrder.instrument,
                                holdingDetail.shareholding_type,
                            )

                        updateStagedBuyOrder({
                            ...stagedBuyOrder,
                            numberOfItems: parseInt(values.numberOfItemsToBuy, 10),
                            pricePerItem: new Decimal(values.highestPricePerItem),
                            useDelayedSettlement: useTradingFacility,
                        })
                        const url = useTradingFacility
                            ? 'fonterra/portfolios/:portfolioId/invest/:urlSlug/buy/trading-facility'
                            : 'fonterra/portfolios/:portfolioId/invest/:urlSlug/:action/time-limit'
                        navigate(
                            profileUrl(url, {
                                portfolioId,
                                urlSlug,
                                action: 'buy',
                            }),
                        )
                    }}
                >
                    {({isValid, values}) => (
                        <Form>
                            <StrongCurrency
                                dataTestId="strong-currency--price-limit"
                                name="highestPricePerItem"
                                label={`Highest price to pay per ${shareLabel({itemType: stagedBuyOrder.itemType})}`}
                                currency={stagedBuyOrder.instrument.currency}
                                decimalPlaces={3}
                            />
                            <StrongNumber
                                dataTestId="strong-number--share-quantity"
                                name="numberOfItemsToBuy"
                                label={`Number of ${shareLabel({
                                    itemType: stagedBuyOrder.itemType,
                                    isPlural: true,
                                })} to buy`}
                                placeholder={shareLabel({itemType: stagedBuyOrder.itemType, isCapitalised: true})}
                                helpText={helpForShareholdingOrder(
                                    portfolioId,
                                    instrumentHoldingDetail,
                                    instrumentSymbol,
                                    'buy',
                                )}
                                normalisation="numberOnly"
                                decimalPlaces={0}
                            />
                            <div>
                                <div className={styles.estimatedAmount}>
                                    <div>Estimated amount</div>
                                    <DollarValue
                                        currency={stagedBuyOrder.instrument.currency}
                                        value={calculateEstimatedAmount(
                                            values.highestPricePerItem,
                                            values.numberOfItemsToBuy,
                                        ).toString()}
                                        decimalPlaces={2}
                                    />
                                </div>
                                {!hasEnoughMoneyInWallet(values, currentWalletBalance) && (
                                    <>
                                        <div className={styles.topup}>
                                            There’s not enough money in your Wallet.{' '}
                                            <Link
                                                to={profileUrl('wallet/:portfolioId', {
                                                    portfolioId: walletPortfolio.id,
                                                })}
                                            >
                                                Top up
                                            </Link>
                                        </div>
                                        {isValid &&
                                            isTradingFacilityEnabled(
                                                values,
                                                currentWalletBalance,
                                                instrument,
                                                holdingDetail.shareholding_type,
                                            ) && (
                                                <AlertCard
                                                    className={styles.tradeNow}
                                                    type="warning"
                                                    text="Trading facility available. Click ‘Next’ to find out more"
                                                />
                                            )}
                                    </>
                                )}
                            </div>
                            <ActionBar className={cn(page.flexRow, styles.formFooter)}>
                                <span className={spacing.spaceAbove4}>
                                    <MooseWalletBalance />
                                </span>
                                <Button
                                    dataTestId="button--next"
                                    label="Next"
                                    disabled={
                                        !isValid ||
                                        values.highestPricePerItem === '' ||
                                        values.numberOfItemsToBuy === '' ||
                                        (!hasEnoughMoneyInWallet(values, currentWalletBalance) &&
                                            !isTradingFacilityEnabled(
                                                values,
                                                currentWalletBalance,
                                                instrument,
                                                holdingDetail.shareholding_type,
                                            ))
                                    }
                                    isSubmit
                                />
                            </ActionBar>
                        </Form>
                    )}
                </Formik>
            </Page>
        </>
    )
}
