import React from 'react'
import Concertina from '~/global/widgets/concertina/Concertina'
import styles from '~/global/widgets/detail-modals/DetailModalContents.scss'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'

interface AmountPutInModalProps {
    costBasis: number
    currency: string
    hasForeignCurrency: boolean
    hasManagedFunds: boolean
    isDependent: boolean
    jurisdiction: string
    unrealisedManagedFundTransactionFees: number
    preferredName: string
    unrealisedTransactionFees: number
}

const AmountPutInModal: React.FunctionComponent<AmountPutInModalProps> = ({
    costBasis,
    currency,
    hasForeignCurrency,
    hasManagedFunds,
    isDependent,
    jurisdiction,
    unrealisedManagedFundTransactionFees,
    preferredName,
    unrealisedTransactionFees,
}) => {
    const possessive = isDependent ? `${preferredName}’s` : 'your'

    return (
        <div>
            <h1>Amount put in</h1>
            <p>
                The total amount spent on investments currently in {possessive} Portfolio, including transaction fees.
                This doesn’t include the amount paid for investments {isDependent ? 'they' : 'you'} no longer own, and
                may be adjusted for mergers, acquisitions, and other{' '}
                <a
                    href="https://www.sharesies.nz/learn/an-introduction-to-corporate-actions"
                    target="_blank"
                    rel="noopener"
                >
                    corporate actions
                </a>
                .
            </p>

            {hasForeignCurrency && (
                <p>
                    To estimate the {jurisdiction === 'au' ? 'AUD' : 'NZD'} spent on{' '}
                    {isDependent ? `${preferredName}’s` : ''} overseas investments, we use a set exchange rate from the
                    day {possessive} order filled (which might be different from the rate the order filled at). This
                    doesn’t include foreign exchange fees.
                </p>
            )}

            <Concertina
                title={
                    <div className={styles.concertinaTitle}>
                        <p className={styles.leftText}>Cost of shares</p>
                        <DollarValue
                            value={costBasis - unrealisedTransactionFees - unrealisedManagedFundTransactionFees}
                            currency={currency}
                        />
                    </div>
                }
                body={
                    <p className={styles.concertinaBody}>
                        The total amount spent on investments currently in {possessive} Portfolio.
                    </p>
                }
                border
            />
            <Concertina
                title={
                    <div className={styles.concertinaTitle}>
                        <p className={styles.leftText}>Transaction fees</p>
                        <DollarValue value={unrealisedTransactionFees} currency={currency} />
                    </div>
                }
                body={
                    <p className={styles.concertinaBody}>
                        The total transaction fees paid to buy investments currently in {possessive} Portfolio.
                    </p>
                }
                border
            />
            {jurisdiction === 'nz' && hasManagedFunds && (
                <Concertina
                    title={
                        <div className={styles.concertinaTitle}>
                            <p className={styles.leftText}>Managed fund transaction fees</p>
                            <DollarValue value={unrealisedManagedFundTransactionFees} currency={currency} />
                        </div>
                    }
                    body={
                        <p className={styles.concertinaBody}>
                            The total transaction fees paid to buy managed fund units currently in {possessive}{' '}
                            Portfolio. These fees are estimates until they’re charged by the fund provider—this can take
                            up to 30 days.
                        </p>
                    }
                    border
                />
            )}
            <Concertina
                title={
                    <div className={styles.concertinaTitle}>
                        <p className={styles.leftText}>Amount put in</p>
                        <p className={styles.lastRightText}>
                            <DollarValue value={costBasis} currency={currency} />
                        </p>
                    </div>
                }
                border
                noChevron
            />
        </div>
    )
}

export default AmountPutInModal
