import {Model} from '~/api/retail/types'

export const bankNumberRegex = (jurisdiction: Model.User['jurisdiction']) => {
    if (jurisdiction === 'au') {
        return /^\d{5,9}$/
    }
    // default NZ bank account format
    return /^\d{2} \d{4} \d{7} \d{2,3}$/
}
export const bankNumberError = (jurisdiction: Model.User['jurisdiction']) => {
    if (jurisdiction === 'au') {
        return 'Enter a valid bank account number that’s between 5 and 9 digits long—for example: 123456789'
    }
    // default NZ bank account hint
    return 'Enter a valid NZ bank account number that’s 15 or 16 digits long—for example: 12 1234 1234567 123'
}
export const bsbRegex = /^\d{3} ?\d{3}$/
export const bsbError = 'Enter a valid BSB number that’s 6 digits—for example: 123 456'

export const bankAccountNameRegex = () => {
    // We are assuming that NZ and AU jurisdictions have the same requirements for
    // bank names.

    // The AU requiremnets state:
    // "only the following characters may be used a-zA-Z0-9,.:’\+()?-/space)"
    return /^[a-zA-Z0-9,.:'\\+()?\-/ ]+$/
}

export const bankAccountNameError = () => {
    return "Only the following characters may be used: a-zA-Z0-9,.:'+()?-/space)"
}
