import {Button} from '@design-system/button'
import {withFormik, InjectedFormikProps} from 'formik'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Request} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {spaceAbove24} from '~/global/scss/helpers/spacing.scss'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {isWrapperApp} from '~/global/utils/is-wrapper-app/isWrapperApp'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {validate, ErrorBox} from '~/global/widgets/form-controls'
import {Text, Radio, PromoCode} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'

interface FormValues {
    preferred_name: string
    relationship: '' | 'guardian' | 'other'
    promo_code: string
}

const Form = withFormik<DispatchProps, FormValues>({
    mapPropsToValues: () => ({
        preferred_name: '',
        relationship: '',
        promo_code: '',
    }),
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        preferred_name: undefined,
    }),
    handleSubmit: async (
        {preferred_name, relationship, promo_code},
        {setSubmitting, setStatus, props: {createDependent}},
    ) => {
        if (!relationship) {
            setStatus('You must select a relationship')
            setSubmitting(false)
            return
        }
        try {
            const error = await createDependent({preferred_name, relationship, promo_code})

            if (error) {
                setStatus(error)
                setSubmitting(false)
                return
            }
        } catch (e) {
            setStatus(unknownErrorMessage)
            setSubmitting(false)
            throw e
        }
    },
    validate: validate.generate<FormValues>({
        preferred_name: [validate.required()],
        relationship: [validate.required(), validate.choices<FormValues['relationship']>('guardian', 'other')],
        promo_code: [],
    }),
})(
    class Form extends React.Component<InjectedFormikProps<DispatchProps, FormValues>, {}> {
        render() {
            const {isSubmitting, handleSubmit, isValid, status} = this.props
            return (
                <form onSubmit={handleSubmit}>
                    <Text
                        dataTestId="text-input--preferred-name"
                        name="preferred_name"
                        label="Preferred name"
                        helpText="You can use their nickname if you'd like"
                    />
                    <Radio
                        dataTestId="radio--relationship"
                        name="relationship"
                        label="I'm their"
                        choices={[
                            {label: 'Parent or guardian', value: 'guardian'},
                            {label: 'Other well meaning and kindly adult', value: 'other'},
                        ]}
                    />
                    <PromoCode
                        name="promo_code"
                        label="Promo code"
                        isReferred={false}
                        type="kids"
                        jurisdiction="nz"
                        selectedCountry="nz"
                    />
                    {status && (
                        <div className={spaceAbove24}>
                            <ErrorBox message={status} />
                        </div>
                    )}
                    <Button
                        isSubmit
                        pageButton
                        dataTestId="button--next"
                        label="Next"
                        disabled={!isValid}
                        processing={isSubmitting}
                        additionalClassName={spacing.spaceBelow48}
                    />
                </form>
            )
        }
    },
)

export const AboutYourKid = (props: DispatchProps) => {
    const navigate = useNavigate()

    return (
        <>
            <Toolbar
                dataTestId="toolbar--create-dependent"
                leftButton={isWrapperApp() ? 'close' : 'back'} // native kids sign up screens use a 'top sheet' pattern that can be dismissed
                onLeftButtonClick={() => {
                    sendWrapperAppMessage({type: 'goBack'})
                    navigate(-1)
                }}
            />
            <Page>
                <h1 className={spacing.spaceBelow16}>Who’s the account for?</h1>
                <Form createDependent={props.createDependent} />
            </Page>
        </>
    )
}

interface DispatchProps {
    createDependent: (payload: Request.IdentityDependentCreate) => Promise<string | undefined>
}

export default connect<{}, DispatchProps>(undefined, dispatch => ({
    createDependent: payload => dispatch(actions.createDependent(payload)),
}))(AboutYourKid)
