import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {accessibility} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import companyBell from '~/sections/invest/sections/portfolio/widgets/corporate-actions-card/assets/images/company-update-bell-icon.svg'
import {useAppSelector} from '~/store/hooks'
import {applicationPointers} from '~/store/identity/selectors'
import styles from './PortfolioCorporateActionsCard.scss'

const PortfolioCorporateActionsCard = () => {
    const navigate = useNavigate()
    const applications = useAppSelector(applicationPointers)
    const profileUrl = useProfileUrl()

    const votes = applications.filter(application => application.type === 'VOTE')

    // card currently only supports votes
    if (votes.length === 0) {
        return null
    }

    return (
        <div
            className={cn(styles.cardWrapper, styles.portfolioCardWrapper, accessibility.button)}
            data-testid="button--view-recent-investing-activity"
            onClick={() => {
                rudderTrack('voting', 'company_update_vote_link_clicked')
                navigate(profileUrl('invest/corporate-actions'))
            }}
        >
            <div className={styles.cardContent}>
                <p className={styles.recentOrdersHeading}>Company updates</p>
                <p>
                    Check out {votes.length} upcoming vote{votes.length !== 1 ? 's' : ''}
                </p>
                <p className={styles.recentOrdersView}>
                    <ButtonAsLink aria-label="View company updates">View</ButtonAsLink>
                </p>
            </div>
            <img className={styles.cardImage} src={companyBell} alt="" />
        </div>
    )
}

export default PortfolioCorporateActionsCard
