import {Pause, SuccessFilled} from '@design-system/icon'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {accessibility} from '~/global/scss/helpers'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import styles from './RecurringTile.scss'

interface RecurringTileProps {
    additionalClassName?: string
    alerts?: React.ReactNode[]
    dataTestId: string
    displayImage?: React.ReactNode
    handleClick: () => void
    title: string
    subtitle: React.ReactNode | string
    nextDate?: DateTime
    state?: string
    actionText?: 'View' | 'Manage'
}

const RecurringTile: React.FunctionComponent<RecurringTileProps> = ({
    additionalClassName,
    alerts = [],
    dataTestId,
    displayImage,
    handleClick,
    nextDate,
    state,
    subtitle,
    title,
    actionText,
}) => {
    return (
        <button
            aria-label="View upcoming transaction"
            className={cn(styles.cardWrapper, accessibility.button, additionalClassName)}
            data-testid={dataTestId}
            onClick={handleClick}
            type="button"
        >
            <div className={cn(styles.row, styles.top)}>
                {displayImage}
                <div className={styles.col}>
                    <div className={styles.title}>
                        <span>{title}</span>
                        {actionText && <a className={styles.link}>{actionText}</a>}
                    </div>
                    <span className={styles.subTitle}>{subtitle}</span>
                </div>
            </div>
            {alerts.map((alert, i) => (
                <React.Fragment key={i}>{alert}</React.Fragment>
            ))}
            <div className={cn(styles.row, styles.bottom)}>
                {nextDate ? <span>Next: {nextDate.toFormat(dateFormatNoTime)}</span> : <span />}
                <div className={styles.status}>
                    {state === 'ACTIVE' ? (
                        <>
                            Active{' '}
                            <div className={styles.active}>
                                <SuccessFilled colour="SuccessAccent" size={16} />
                            </div>
                        </>
                    ) : state === 'PAUSED' ? (
                        <>
                            Paused{' '}
                            <div className={styles.paused}>
                                <Pause colour="WarningPrimary" size={16} />
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        </button>
    )
}

export default RecurringTile
