import {Button} from '@design-system/button'
import {Modal} from '@design-system/modal'
import cn from 'classnames'
import React from 'react'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {Checkbox} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading/Loading'
import styles from '~/sections/invest/sections/us-sign-up/USSignUp.scss'
import ReviewDetails from '~/sections/invest/sections/us-sign-up/pages/review-details/ReviewDetails'
import CheckboxText from '~/sections/invest/sections/us-sign-up/widgets/checkbox-text/CheckboxText'
import W9Certification from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/W9Certification'
import {connect} from '~/store/connect'
import {useAppDispatch} from '~/store/hooks'
import signUpActions from '~/store/sign-up/actions'
import {State} from '~/store/sign-up/types'

const SignW9: React.FunctionComponent<SignW9Props> = ({
    usStatus,
    isDependent,
    jurisdiction,
    usSignW9,
    setEditingAddress,
    setEditingTaxResidency,
    setEditingCitizenship,
}) => {
    const dispatch = useAppDispatch()
    const [modalVisible, setModalVisible] = React.useState(false)
    const [subjectToBackupWithholding, setSubjectToBackupWithholdingCheckBox] = React.useState(false)
    const [signCheckBoxTicked, setSignCheckBoxTicked] = React.useState(false)
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const usTaxResidency = usStatus?.tax_residencies?.find(tr => tr.country === 'US')

    // If US is specified as a tax residency, but a TIN hasn't been provided we need to prevent form submission.
    const missingUsTin = usTaxResidency?.tin === ''

    if (!usStatus) {
        return <Loading isPineapple />
    }

    const validateSubjectToBackupWithholding = () => {
        if (subjectToBackupWithholding) {
            setModalVisible(true)
        } else {
            submitForm()
        }
    }

    async function submitForm() {
        setIsSubmitting(true)
        try {
            await usSignW9(true, true)
            await dispatch(signUpActions.getUSStatus())
        } catch (error) {
            setIsSubmitting(false)
            throw error
        }
    }

    return (
        <>
            <ReviewDetails
                usStatus={usStatus}
                isDependent={isDependent}
                jurisdiction={jurisdiction}
                setEditingAddress={setEditingAddress}
                setEditingTaxResidency={setEditingTaxResidency}
                setEditingCitizenship={setEditingCitizenship}
            />
            <div className={cn(styles.formCertificationText, styles.numberedList)}>
                <W9Certification subjectToBackupWithholding={!subjectToBackupWithholding} />
                <div className={styles.formFooter}>
                    <div className={cn(spacing.spaceBelow4, styles.smallerCheckbox)}>
                        <Checkbox
                            dataTestId="checkbox--section2-w9"
                            name="W-9-section2"
                            label="If you have been notified by the IRS that you are currently subject to backup withholding, for example, because you have failed to provide a correct TIN or failed to report all interest and dividends on your US tax return, tick this box."
                            isTouched
                            value={subjectToBackupWithholding}
                            onChange={() => setSubjectToBackupWithholdingCheckBox(!subjectToBackupWithholding)}
                        />
                    </div>
                    <p className={spacing.spaceBelow12}>
                        The IRS does not require your consent to any provision of this document other than the
                        certifications required to avoid backup withholding.
                    </p>
                    <div className={spacing.spaceBelow16}>
                        <Checkbox
                            dataTestId="checkbox--sign-w9"
                            name="W-9"
                            label={CheckboxText(isDependent, usStatus.name)}
                            isTouched
                            value={signCheckBoxTicked}
                            onChange={() => setSignCheckBoxTicked(!signCheckBoxTicked)}
                        />
                    </div>
                    <Modal
                        isOpen={modalVisible}
                        setIsOpen={setModalVisible}
                        title="Backup withholding"
                        dataTestId="modal--backup-witholding"
                        primaryButton={{
                            label: 'Close',
                            onClick: () => {
                                validateSubjectToBackupWithholding()
                            },
                        }}
                    >
                        <p>
                            Sharesies is unable to support investors who are subject to backup withholding. Please
                            contact <a href="mailto:help@sharesies.co.nz">help@sharesies.co.nz</a> for further
                            information.
                        </p>
                    </Modal>
                    {missingUsTin && (
                        <p className={styles.errorMessage}>
                            You have declared that you are a US tax resident so you must provide a US Tax Identifcation
                            Number (by editing the Tax residency section above) to proceed, or contact us at{' '}
                            <a href="mailto:help@sharesies.co.nz">help@sharesies.co.nz</a>.
                        </p>
                    )}
                    <Button
                        label="Sign W-9 form"
                        dataTestId="button--sign-w9-form"
                        disabled={!signCheckBoxTicked || missingUsTin}
                        type="primary"
                        processing={isSubmitting}
                        onClick={async () => validateSubjectToBackupWithholding()}
                    />
                </div>
            </div>
        </>
    )
}

interface StoreProps {
    usStatus: State['usStatus']
    isDependent: boolean
    jurisdiction: Model.User['jurisdiction']
}

interface DispatchProps {
    usSignW9(signed: true, subjectToBackupWithholding: true): Promise<void | null>
}
interface OwnProps {
    setEditingAddress: React.Dispatch<React.SetStateAction<boolean>>
    setEditingTaxResidency: React.Dispatch<React.SetStateAction<boolean>>
    setEditingCitizenship: React.Dispatch<React.SetStateAction<boolean>>
}

type SignW9Props = StoreProps & DispatchProps & OwnProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    ({signUp, identity}) => ({
        usStatus: signUp.usStatus,
        isDependent: identity.user!.is_dependent,
        jurisdiction: identity.user!.jurisdiction,
    }),
    dispatch => ({
        usSignW9: (signed, subject_to_backup_withholding) =>
            dispatch(signUpActions.usSignW9(signed, subject_to_backup_withholding)),
    }),
)(SignW9)
