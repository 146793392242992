import {Button} from '@design-system/button'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {spacing} from '~/global/scss/helpers'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'

const FloodlightDebugPage: React.FunctionComponent = () => {
    return (
        <>
            <Toolbar dataTestId="toolbar--floodlight-debug" leftButton="back" title="Floodlight Debug" isInlineTitle />
            <Page>
                <h2>Temporary Google Floodlight debugging page</h2>
                <p>Use this page to trigger the NZ and AU floodlight tags</p>

                <p className={spacing.spaceAbove32}>NZ floodlight tag</p>
                <ul>
                    <li>Event type: conversion</li>
                    <li>Send to: DC-9748890/conve0/share000+standard</li>
                </ul>
                <Button
                    label="Trigger NZ Floodlight event"
                    onClick={() => Analytics.signUpFloodlightEvent('nz')}
                    additionalClassName={spacing.spaceAbove16}
                    dataTestId="button--floodlight-test-nz"
                />

                <p className={spacing.spaceAbove32}>AU floodlight tag (includes youtube conversion)</p>
                <ul>
                    <li>Event type: conversion</li>
                    <li>Send to: DC-10744377/conve0/share0+standard</li>
                    <li>Send to: AW-380794457/1B4ACOn85b4CENnsybUB</li>
                </ul>
                <Button
                    label="Trigger AU Floodlight event"
                    onClick={() => Analytics.signUpFloodlightEvent('au')}
                    additionalClassName={spacing.spaceAbove16}
                    dataTestId="button--floodlight-test-au"
                />
            </Page>
        </>
    )
}

export default FloodlightDebugPage
