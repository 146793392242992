import {Card} from '@braze/web-sdk'
import * as braze from '~/api/braze/braze'
import * as rollbar from '~/api/rollbar/rollbar'
import * as rudderStack from '~/api/rudderstack/rudderstack'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {isExploreContentCard} from '~/sections/explore/widgets/content-cards/common'
import {ActionsType} from './actions'
import {State, notificationPageContentCardCategories} from './types'

const initialState: State = {
    actor: null,
    applicationPointers: [],
    canEnterAddressToken: false,
    canSeeSaveWaitlist: false,
    canJoinKiwiSaver: false,
    citizenships: [],
    customImages: [],
    distillToken: null,
    distillTokenV2: null,
    employmentInstrumentIds: [],
    feedback: {
        loadingState: 'uninitialised',
        submittedActionIdentifiers: [],
    },
    flags: {},
    holdings: [],
    includeSoldInvestments: true,
    isEligibleForKiwisaver: false,
    isInitialised: false,
    natureAndPurpose: null,
    notificationPreferences: [],
    notifications: {
        notificationsLoaded: false,
        notificationsPageCards: [],
        exploreCards: [],
        notificationsPageUnviewedCount: 0,
        loadFail: false,
    },
    onKiwiSaverWaitlist: false,
    orders: [],
    participants: [],
    pendingOrdersNextCheck: 15 * 1000, // 15 seconds in ms
    pendingOrdersCurrentTimeoutId: null,
    portfolioExtendedHoursPreference: false,
    portfolioFilterPreference: 'RISK',
    portfolioSortPreference: 'ALPHABETICAL',
    rakaiaToken: null,
    saveAccounts: {},
    searchResults: [],
    sharesightClientCredentials: undefined,
    sharesightGetIntegrationLoadingState: 'ready',
    sharesightGetPortfoliosLoadingState: 'ready',
    sharesightIntegrations: {},
    sharesightPortfolios: undefined,
    switchingTo: null,
    user: null,
    userList: [],
}

function reducer(state: State = initialState, action: ActionsType): State {
    switch (action.type) {
        case 'identity.ClearRakaiaToken':
            return {
                ...state,
                rakaiaToken: null,
                rakaiaTokenExpiry: null,
            }
        case 'identity.Initialise':
            return {...state, isInitialised: true}
        case 'identity.SetAnonymousIdentity': {
            rollbar.clearUser()
            return {
                ...state,
                actor: null,
                canEnterAddressToken: false,
                canSeeSaveWaitlist: false,
                canWriteUntil: undefined,
                employmentInstrumentIds: [],
                flags: {},
                holdings: [],
                isEligibleForKiwisaver: false,
                notifications: {
                    notificationsLoaded: false,
                    notificationsPageCards: [],
                    notificationsPageUnviewedCount: 0,
                    loadFail: false,
                    announcementCard: undefined,
                    exploreCards: [],
                },
                orders: [],
                pendingOrdersCurrentTimeoutId: null,
                referralCode: undefined,
                saveAccounts: {},
                switchingTo: null,
                user: null,
                userList: [],
            }
        }
        case 'identity.SetAuthenticatedIdentity': {
            const flags: {[flag: string]: boolean} = {}
            action.payload.flags.forEach(f => (flags[f] = true))

            const primaryUserId = action.payload.user_list.find(u => u.primary)!.id
            const actor = action.payload.user.id === primaryUserId ? action.payload.user : state.actor

            rollbar.setUser(action.payload.user)
            braze.changeUser(action.payload.user)
            rudderStack.rudderIdentify(action.payload.user.id, action.payload.ga_id)

            return {
                ...state,
                actor,
                applicationPointers: action.payload.application_pointers,
                canEnterAddressToken: action.payload.can_enter_address_token,
                canJoinKiwiSaver: action.payload.can_join_kiwisaver || false,
                canSeeSaveWaitlist: action.payload.can_see_save_waitlist,
                canWriteUntil: action.payload.can_write_until,
                distillToken: action.payload.distill_token,
                distillTokenV2: action.payload.distill_token_v2,
                employmentInstrumentIds: action.payload.employment_fund_ids,
                flags,
                holdings: action.payload.holdings,
                includeSoldInvestments: action.payload.include_sold_investments,
                isEligibleForKiwisaver: action.payload.is_eligible_for_kiwisaver,
                onKiwiSaverWaitlist: action.payload.on_kiwisaver_waitlist,
                orders: action.payload.orders,
                participants: action.payload.participants,
                portfolioExtendedHoursPreference: action.payload.portfolio_enable_extended_hours,
                portfolioFilterPreference: action.payload.portfolio_filter_preference,
                portfolioSortPreference: action.payload.portfolio_sort_preference,
                rakaiaToken: action.payload.rakaia_token,
                rakaiaTokenExpiry: action.payload.rakaia_token_expiry,
                referralCode: action.payload.referral_code,
                saveAccounts: action.payload.save_accounts,
                switchingTo: null,
                user: action.payload.user,
                userList: action.payload.user_list,
            }
        }
        case 'identity.SetCitizenships':
            return {
                ...state,
                citizenships: action.payload,
            }
        case 'identity.SetCloseAccountCheck':
            return {
                ...state,
                closeAccountCheck: action.payload,
            }
        case 'identity.SetCustomImages':
            return {
                ...state,
                customImages: action.payload.custom_images,
            }
        case 'identity.SetDistillToken':
            return {
                ...state,
                distillToken: action.payload.token,
            }
        case 'identity.SetEnableExtendedHours':
            return {
                ...state,
                portfolioExtendedHoursPreference: action.payload.enable_extended_hours,
            }
        case 'identity.SetFeedback':
            return {
                ...state,
                feedback: action.payload,
            }
        case 'identity.SetFrozen':
            if (state.user) {
                return {...state, user: {...state.user, account_frozen: true}}
            }
            return state
        case 'identity.SetHasSeen':
            if (state.user) {
                return {...state, user: {...state.user, has_seen: {...state.user.has_seen, [action.payload]: true}}}
            }
            return state
        case 'identity.SetIncludeSoldInvestments':
            return {
                ...state,
                includeSoldInvestments: action.payload.include_sold_investments,
            }
        case 'identity.SetKidsAccountTransferTokenData':
            return {
                ...state,
                kidsAccountTransferTokenData: action.payload,
            }
        case 'identity.SetKidsAccountTransferTokenError':
            return {
                ...state,
                kidsAccountTransferTokenError: true,
            }
        case 'identity.SetNatureAndPurpose':
            return {
                ...state,
                natureAndPurpose: action.payload,
            }
        case 'identity.SetNotifications':
            const announcementCard: Card | undefined = action.payload.cards.find(
                card => card.extras?.category === 'show_as_announcement_on_portfolio',
            )
            const featureOnboardingCard: Card | undefined = action.payload.cards.find(
                card => card.extras?.category === 'show_feature_announcement_on_portfolio',
            )

            const exploreCards = action.payload.cards.filter(card => isExploreContentCard(card.extras?.category))

            const notificationsPageCards = action.payload.cards.filter(card =>
                notificationPageContentCardCategories.includes(card.extras?.category),
            )
            const notificationsPageUnviewedCount = notificationsPageCards.filter(card => !card.viewed).length
            const propensitiesCard = action.payload.cards.find(
                card => card.extras?.category === 'customer_propensities',
            )

            return {
                ...state,
                notifications: {
                    notificationsLoaded: true,
                    notificationsPageUnviewedCount,
                    loadFail: false,
                    announcementCard,
                    featureOnboardingCard,
                    notificationsPageCards,
                    exploreCards,
                    propensitiesCard,
                },
            }
        case 'identity.SetNotificationsLoadFail':
            if (state.notifications.notificationsLoaded) {
                // If we already have notifications, continue displaying what we've got
                return state
            } else {
                // Otherwise, set loadFail to display message to user
                return {
                    ...state,
                    notifications: {
                        ...state.notifications,
                        notificationsPageCards: [],
                        notificationsPageUnviewedCount: 0,
                        notificationsLoaded: false,
                        loadFail: true,
                        exploreCards: [],
                    },
                }
            }
        case 'identity.SetNotificationPreferences':
            return {
                ...state,
                notificationPreferences: action.payload,
            }
        case 'identity.SetPendingOrdersCurrentTimeoutId':
            return {
                ...state,
                pendingOrdersCurrentTimeoutId: action.payload,
            }
        case 'identity.SetPortfolioFilterPreference':
            return {
                ...state,
                portfolioFilterPreference: action.payload,
            }
        case 'identity.SetPortfolioSortPreference':
            return {
                ...state,
                portfolioSortPreference: action.payload,
            }
        case 'identity.SetSharesightClientCredentials':
            return {
                ...state,
                sharesightClientCredentials: {
                    clientId: action.payload.clientId,
                    redirectUri: action.payload.redirectUri,
                },
            }
        case 'identity.SetSharesightGetIntegrationsLoadingState':
            return {
                ...state,
                sharesightGetIntegrationLoadingState: action.payload,
            }
        case 'identity.SetSharesightIntegrations':
            return {
                ...state,
                sharesightIntegrations: action.payload,
                sharesightGetIntegrationLoadingState: 'ready',
            }
        case 'identity.SetSharesightPortfolios':
            return {
                ...state,
                sharesightPortfolios: action.payload,
                sharesightGetPortfoliosLoadingState: 'ready',
            }
        case 'identity.SetSharesightPortfoliosLoadingState':
            return {
                ...state,
                sharesightGetPortfoliosLoadingState: action.payload,
            }
        case 'identity.SetSwitchingTo':
            return {...state, switchingTo: action.payload}
        case 'identity.UpdatePendingOrders':
            return {
                ...state,
                orders: action.payload.orders,
                pendingOrdersNextCheck: 60 * 60 * 1000, // 1 hour in ms
            }
        case 'identity.UpdateUSSignupDetails':
            if (state.user) {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        phone: action.payload.phone,
                        address: action.payload.address,
                        ird_number: action.payload.ird_number,
                    },
                }
            }
            return state

        default:
            assertNever(action)
    }
    return state
}

export default reducer
