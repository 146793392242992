import React from 'react'
import {ShareValue} from '~/global/widgets/number-elements/NumberElements'
import {useHoldingDetail} from '~/sections/moose/data/retail'
import styles from './ShareholdingLimitsWidget.scss'

interface ShareholdingLimitsWidgetProperties {
    symbol: string
    portfolioId: string
}

export const ShareholdingLimitsWidget: React.FunctionComponent<ShareholdingLimitsWidgetProperties> = ({
    symbol,
    portfolioId,
}: ShareholdingLimitsWidgetProperties) => {
    const holdingDetail = useHoldingDetail(portfolioId)

    const instrumentHoldingDetail = holdingDetail.instruments.find(x => x.symbol === symbol)
    if (instrumentHoldingDetail == null) {
        return null
    }

    return (
        <>
            <div className={styles.rangeOMeter}>
                <div className={styles.receiptBody}>
                    <div className={styles.receiptRow}>
                        <div className={styles.label}>{instrumentHoldingDetail.symbol} shareholding</div>
                        <div className={styles.value}>
                            <ShareValue value={instrumentHoldingDetail.holding} />
                        </div>
                    </div>
                    <div className={styles.receiptRow}>
                        <div className={styles.label}>Minimum</div>
                        <div className={styles.value}>
                            <ShareValue value={instrumentHoldingDetail.minimum ?? 0} />
                        </div>
                    </div>
                    <div className={styles.receiptRow}>
                        <div className={styles.label}>Maximum</div>
                        <div className={styles.value}>
                            {instrumentHoldingDetail.maximum != null ? (
                                <ShareValue value={instrumentHoldingDetail.maximum} />
                            ) : (
                                'Unlimited'
                            )}
                        </div>
                    </div>
                    <hr className={styles.receiptRowDivider} />
                    <div className={styles.receiptRow}>
                        <div className={styles.label}>Permitted to buy</div>
                        <div className={styles.value}>
                            {instrumentHoldingDetail.permitted_to_buy != null ? (
                                <ShareValue value={instrumentHoldingDetail.permitted_to_buy} />
                            ) : (
                                'Unlimited'
                            )}
                        </div>
                    </div>
                    <hr className={styles.receiptRowDivider} />
                    <div className={styles.receiptRow}>
                        <div className={styles.label}>Permitted to sell</div>
                        <div className={styles.value}>
                            <ShareValue
                                value={instrumentHoldingDetail.permitted_to_sell ?? instrumentHoldingDetail.holding}
                            />
                        </div>
                    </div>
                    <div className={styles.receiptRow}>
                        <div className={styles.label}>Shares subject to lien</div>
                        <div className={styles.value}>
                            <ShareValue value={instrumentHoldingDetail.lien_holding ?? 0} />
                        </div>
                    </div>
                    <div className={styles.receiptRow}>
                        <div className={styles.label}>Reserved shares</div>
                        <div className={styles.value}>
                            <ShareValue value={instrumentHoldingDetail.reserved ?? 0} />
                        </div>
                    </div>
                    <div className={styles.receiptRow}>
                        <div className={styles.label}>Available to sell</div>
                        <div className={styles.value}>
                            <ShareValue value={instrumentHoldingDetail.permitted_to_sell ?? 0} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
