import React from 'react'
import {useRetailGet} from '~/api/query/retail'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {transactionToTransactionWithDetail} from '~/sections/wallet/pages/transaction/utils/transaction-types/transactionTypes'
import {FxRecord} from '~/sections/wallet/pages/transaction/widgets/transaction/FxRecord'
import {GenericRecord} from '~/sections/wallet/pages/transaction/widgets/transaction/GenericRecord'
import {PortfolioOrderRecord} from '~/sections/wallet/pages/transaction/widgets/transaction/PortfolioOrderRecord'
import {SaveTransferOrderRecord} from '~/sections/wallet/pages/transaction/widgets/transaction/SaveTransferOrderRecord'
import {WithdrawalOrderRecord} from '~/sections/wallet/pages/transaction/widgets/transaction/WithdrawalOrderRecord'
import {useWalletPortfolio} from '~/sections/wallet/state/local'

export const Transaction: React.FunctionComponent<{transactionKey: string}> = ({transactionKey}) => {
    const portfolio = useWalletPortfolio()
    const {
        data: {transaction},
    } = useRetailGet({
        path: 'wallet/:portfolio_id/transaction',
        pathParams: {portfolio_id: portfolio.id},
        payload: {key: transactionKey},
    })

    const txnWithDetail = transactionToTransactionWithDetail(transaction)

    switch (txnWithDetail.detailType) {
        case 'fx_order':
            return <FxRecord transaction={txnWithDetail} />
        case 'save_transfer_order':
            return <SaveTransferOrderRecord transaction={txnWithDetail} />
        case 'portfolio_order':
            return <PortfolioOrderRecord order={txnWithDetail.detail} />
        case 'withdrawal_order':
            return <WithdrawalOrderRecord transaction={txnWithDetail} />
        case 'buy':
        case 'sell':
        case 'dividend':
        case 'payment_request':
        case 'corporate_action':
            // These fall back to the default rendering below
            break
        case undefined:
            break
        default:
            assertNever(txnWithDetail)
    }

    return <GenericRecord transaction={txnWithDetail} />
}
