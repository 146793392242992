import {useRetailGet} from '~/api/query/retail'
import {useProfile} from '~/global/state-hooks/retail/useProfile'

/**
 * Use the owner endpoint to get the details of the owner of the selected profile.
 *
 * @example
 * const owner = useProfileOwner()
 */
export const useProfileOwner = () => {
    const profile = useProfile()

    return useRetailGet({
        path: 'owner/:owner_id',
        pathParams: {owner_id: profile.owner_id},
        options: {
            staleTime: 4.5 * 60 * 1000, // four and a half minutes - half a minute before cache expires
        },
    }).data
}
