import cn from 'classnames'
import React from 'react'
import {accessibility, spacing} from '~/global/scss/helpers'
import {dateFormatMonthShortDayWithYear} from '~/global/utils/format-date/formatDate'
import {getTransferOrderGroupExchange, isTransferAwaitingDocuments} from '~/global/utils/share-transfers/shareTransfers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {useNavigate} from '~/migrate-react-router'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {ResponseGroupTransfer} from '~/store/transfer/types'

interface StateLabelProps {
    transferOrderGroup: ResponseGroupTransfer
}

export const TransferPendingStateLabel = ({transferOrderGroup}: StateLabelProps) => {
    const awaitingDocuments = isTransferAwaitingDocuments(transferOrderGroup)
    const state = transferOrderGroup.state
    const rejected = transferOrderGroup.rejected

    if (!['incomplete', 'new', 'processing', 'submitted'].includes(state)) {
        return null
    }

    const actionNeeded = state === 'incomplete' && rejected
    const awaitingDocs =
        state === 'incomplete' && transferOrderGroup.exchange !== 'NZX' && !rejected && awaitingDocuments
    const inProgress = state === 'incomplete' && !rejected && !awaitingDocuments
    const processing =
        ['new', 'processing', 'submitted'].includes(state) ||
        (state === 'incomplete' && transferOrderGroup.exchange === 'NZX')

    return (
        <div className={cn(commonStyles.statusLabel)}>
            {actionNeeded && 'Action needed'}
            {awaitingDocs && 'Awaiting documents'}
            {inProgress && 'In progress'}
            {processing && 'Pending'}
        </div>
    )
}

const PendingTransfer: React.FunctionComponent<Props> = ({transferOrderGroup}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    return (
        <button
            className={cn(accessibility.button, commonStyles.cardContainer, spacing.spaceAbove16)}
            key={transferOrderGroup.group_id}
            type="button"
            onClick={() => {
                navigate(
                    profileUrl('invest/portfolio-transfer-shares/view-transfer/:transferOrderGroupId', {
                        transferOrderGroupId: transferOrderGroup.group_id,
                    }),
                )
            }}
            data-testid="a--view-transfer-group"
        >
            <div className={cn(commonStyles.textAndStatusLabel, spacing.spaceBelow4)}>
                <h3>Transfer {transferOrderGroup.direction}</h3>
                <TransferPendingStateLabel transferOrderGroup={transferOrderGroup} />
            </div>
            <div className={commonStyles.pendingCardContent}>
                <p className={spacing.spaceBelow4}>
                    {getTransferOrderGroupExchange(transferOrderGroup)} <Delimiter /> Requested{' '}
                    {transferOrderGroup.created.toFormat(dateFormatMonthShortDayWithYear)}
                </p>
                <p className={commonStyles.pendingCardLink}>View</p>
            </div>
        </button>
    )
}

interface Props {
    transferOrderGroup: ResponseGroupTransfer
}

export default PendingTransfer
