import {Button} from '@design-system/button'
import {DateTime} from 'luxon'
import React from 'react'
import {fileUrl} from '~/api/retail'
import {FILE_MAP} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {ErrorBox, ReportDateRange, ReportType} from '~/global/widgets/form-controls'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {connect} from '~/store/connect'
import {useAppSelector} from '~/store/hooks'
import {actingAsID as actingAsIDSelector} from '~/store/identity/selectors'

type SubSet<T extends keyof FILE_MAP> = T

type ReportType = SubSet<
    | 'accounting/investment-report.pdf'
    | 'accounting/investing-holdings-report.csv'
    | 'accounting/transaction-report.csv'
    | 'accounting/:portfolio_id/wallet-report.csv'
>

export const InvestReports: React.FunctionComponent = () => {
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const [reportType, setReportType] = React.useState<ReportType | undefined>()
    const [dateRange, setDateRange] = React.useState<DateRangeProps>({
        from: DateTime.fromISO('2017-04-01').startOf('month'),
        to: DateTime.local().endOf('month'),
    })
    const [error, setError] = React.useState<string | undefined>()

    const {is_dependent, preferred_name} = useAppSelector(s => s.identity.user!)
    const actingAsID = useAppSelector(actingAsIDSelector)
    const profile = useProfile()
    const walletPortfolio = profile.portfolios.find(p => p.product === 'WALLET')

    if (!walletPortfolio) {
        throw new Error(`Couldn't find wallet portfolio in profile ${profile.id}`)
    }

    const isValid = (): boolean => {
        if (dateRange.from > dateRange.to) {
            setError('Start date should be before end date')
            return false
        }
        if (!reportType) {
            setError('Please select a report type')
            return false
        }

        return true
    }

    const handleSubmit = () => {
        if (!isValid() || !reportType) {
            return
        }

        const params = {
            from: dateRange.from.toISODate(),
            to: dateRange.to.toISODate(),
            acting_as_id: actingAsID,
            timezone: DateTime.local().zoneName,
        }

        const url =
            reportType === 'accounting/:portfolio_id/wallet-report.csv'
                ? fileUrl(reportType, params, {portfolio_id: walletPortfolio.id})
                : fileUrl(reportType, params)

        window.open(url, '_blank', 'noopener')
    }

    const helpUrl =
        jurisdiction === 'nz'
            ? 'https://intercom.help/sharesies/en/articles/8093144-investment-holdings-report'
            : 'https://intercom.help/sharesies-au/en/articles/8104498-investment-holdings-report'

    const walletReportDesc =
        jurisdiction === 'nz'
            ? `View a report of ${
                  is_dependent ? `${preferred_name}’s` : 'your'
              } card and BlinkPay top-ups, as well as foreign exchange and share transfer transactions, for the period selected`
            : `View a report of ${
                  is_dependent ? `${preferred_name}’s` : 'your'
              } card, round-up, and linked bank top-ups, as well as foreign exchange and share transfer transactions, for the period selected`

    return (
        <>
            <Toolbar dataTestId="toolbar--reports" leftButton="back" title="Reports" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <p className={spacing.spaceBelow24}>
                    These reports are made available to help you understand{' '}
                    {is_dependent ? `${preferred_name}’s` : 'your'} Sharesies investments. They are provided to you on
                    an ‘as is’ basis. Reporting methodology may change from time to time—you’re responsible for checking
                    the accuracy of any information that you use.
                </p>
                <ReportDateRange
                    dateRange={dateRange}
                    handleChange={newRange => {
                        setDateRange(newRange)
                        setError(undefined)
                    }}
                    includeMonths
                />
                <ReportType
                    choices={[
                        {
                            value: 'accounting/investment-report.pdf',
                            label: 'Investment report (PDF)',
                            helpText: `View a report of ${
                                is_dependent ? `${preferred_name}’s` : 'your'
                            } investments and their value for the period selected`,
                        },
                        {
                            value: 'accounting/investing-holdings-report.csv',
                            label: 'Investment holdings report (CSV)',
                            helpText: (
                                <span>
                                    View an in-depth report of {is_dependent ? `${preferred_name}’s` : 'your'}{' '}
                                    investments—including{' '}
                                    {
                                        <a href={helpUrl} target="_blank" rel="noopener">
                                            certain holdings, tax, and share price data
                                        </a>
                                    }
                                    —for the period selected
                                </span>
                            ),
                        },
                        {
                            value: 'accounting/transaction-report.csv',
                            label: 'Transaction report (CSV)',
                            helpText: `View a report of all ${
                                is_dependent ? `${preferred_name}’s` : 'your'
                            } buy and sell transactions for the period selected`,
                        },
                        {
                            value: `accounting/:portfolio_id/wallet-report.csv`,
                            label: 'Wallet report (CSV)',
                            helpText: walletReportDesc,
                        },
                    ]}
                    value={reportType}
                    handleChange={v => {
                        setReportType(v)
                        setError(undefined)
                    }}
                />
                <ErrorBox message={error} />
                <Button
                    label="Export report"
                    disabled={!reportType}
                    pageButton
                    onClick={handleSubmit}
                    dataTestId="button--export-report"
                />
            </Page>
        </>
    )
}

interface StoreProps {
    isDependent: boolean
    preferredName: string
    actingAsId: string
}

interface DateRangeProps {
    to: DateTime
    from: DateTime
}

export default connect<StoreProps, {}, {}>(({identity}) => ({
    isDependent: identity.user!.is_dependent,
    preferredName: identity.user!.preferred_name,
    actingAsId: identity.user!.id,
}))(InvestReports)
