import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {Model, Request, Response} from '~/api/retail/types'
import {IDENTITY_VERIFICATION_MODE_KEY} from '~/global/constants/global'
import {spacing} from '~/global/scss/helpers'
import {IdentityVerificationStep} from '~/global/utils/identity-verification/identityVerification'
import {SharesiesOmit} from '~/global/utils/type-utilities/typeUtilities'
import {Radio, ErrorBox} from '~/global/widgets/form-controls'
import {OtherInformation} from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/OtherInformation'
import {
    getFormModeFromIdentityVerificationDocuments,
    addDocumentDetailsToForm,
    Mode,
} from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/util'
import {OverseasPassport} from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/widgets/shared-forms/OverseasPassportForm'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'
import {AULicenceForm} from './AULicenceForm'
import {AUMedicareCardForm} from './AUMedicareCardForm'
import {AUPassportForm} from './AUPassportForm'
const databases = ['Department of Home Affairs', 'Global AML registry']

const IDDuplicateErrorMessage = () => (
    <p>
        Uh oh, looks like this ID has already been used. Try another one or get in touch with us{' '}
        <a href="mailto:help@sharesies.com.au">help@sharesies.com.au</a>
    </p>
)

const AUIdentityForms: React.FunctionComponent<AUIdentityFormsProps> = ({
    setStep,
    isDependent,
    preferredName,
    isSecondaryId,
    verifyAustralianLicence,
    verifyAustralianPassport,
    secondaryVerifyAustralianLicence,

    secondaryVerifyAustralianPassport,
    secondaryVerifyAustralianMedicareCard,
    identityVerificationDocuments,
    identityVerification,
}) => {
    const [mode, setMode] = React.useState<Mode>()
    const [idDuplicateError, setIdDuplicateError] = React.useState(false)
    const isIdentityLinked = identityVerification.is_identity_linked
    const primaryIdType = identityVerification.primary_id_type

    const latestBiometricVerificationCheck = identityVerification.latest_biometric_verification_check
    const verificationLocationAtSignUp = latestBiometricVerificationCheck?.location === 'SIGNUP'
    // user had verified Biometric before in Signup flow and only required to verify alternative ID details but not required to verify Biometric
    const isRequiredSecondaryIdDetailsButNotBiometricVerification =
        (!latestBiometricVerificationCheck && isIdentityLinked) || verificationLocationAtSignUp

    React.useEffect(() => {
        if (identityVerificationDocuments && identityVerificationDocuments.length) {
            // If we have some identify verification documents, grab the appropriate mode for the form
            // This only need to happen on signup flow
            setMode(getFormModeFromIdentityVerificationDocuments(identityVerificationDocuments))
        }
        // threshold flow don't want to load the initial radio button selected
        // when user already verifi biometric and need for secondary id flow
        if (isRequiredSecondaryIdDetailsButNotBiometricVerification) {
            setMode('' as Mode)
        } else {
            const idMode = window.localStorage.getItem(IDENTITY_VERIFICATION_MODE_KEY) as Mode
            // Else we may have the mode set in local storage from before we started the verification
            // flow. If this is the case, use that to set the form mode

            if (idMode) {
                setMode(idMode)
            }
        }
    }, [identityVerificationDocuments])

    const setIDDuplicateError = (hasFieldError: boolean) => {
        setIdDuplicateError(hasFieldError)
    }

    const onSelectMode = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        // When the user selects a different mode, store this in local storage. We do this so that
        // when the user comes back from Verifi, we can select the same mode as they selected _before_
        // they started the identity verification flow
        window.localStorage.setItem(IDENTITY_VERIFICATION_MODE_KEY, e.target.value)
        setIdDuplicateError(false)
        setMode(e.target.value as Mode)
        Analytics.event({
            // send a GA event for each selection of the identity type radio buttons
            category: isSecondaryId ? 'Verification threshold limit flow' : 'Sign Up',
            action: 'Australian identity verification',
            label: choices.find(i => i.value === e.target.value)!.label,
        })
    }

    let choices = [
        {type: 'australian_drivers_licence', value: 'licence', label: 'Australian driver licence'},
        {type: 'australian_passport', value: 'passport', label: 'Australian passport'},
        {type: 'other', value: 'other', label: 'Other ID'},
    ]
    // if the user has already verified Biometric in signup flow,
    // we removed the type of primary Id from the `Choices` array so that
    // they need to pick the alternative identity type
    if (isIdentityLinked && isSecondaryId && isRequiredSecondaryIdDetailsButNotBiometricVerification) {
        // add medicared_card only if the user has already verified Biometric in signup flow
        choices.splice(choices.length - 1, 0, {type: 'medicare_card', value: 'medicareCard', label: 'Medicare card'})
        choices = choices.filter(id => id.type !== primaryIdType)
    }
    // Filter out the alternative secondaryId from the choices which we are selected from radio
    // this is just for displaying the title text
    const secondaryId = choices.find(id => id.value === mode)

    let formProps = {
        setStep,
        isDependent,
        preferredName,
        databases,
        setIDDuplicateError,
        isSecondaryId: isSecondaryId || false,
        verifyAustralianLicence: isSecondaryId ? secondaryVerifyAustralianLicence : verifyAustralianLicence,
        verifyAustralianPassport: isSecondaryId ? secondaryVerifyAustralianPassport : verifyAustralianPassport,
        verifyAustralianMedicareCard: secondaryVerifyAustralianMedicareCard,
    }

    if (identityVerificationDocuments && identityVerificationDocuments.length) {
        // We have documents from a previous identity verificataion. Extract the details out of the first
        // document for use in autopopulating the form
        formProps = addDocumentDetailsToForm(formProps, identityVerificationDocuments[0], mode)
    }
    const heading = isRequiredSecondaryIdDetailsButNotBiometricVerification
        ? 'Confirm second ID details'
        : `Confirm your ${secondaryId?.label} details`

    const formInfo = isRequiredSecondaryIdDetailsButNotBiometricVerification
        ? `To verify that you’re ${preferredName}, we need you to check a second set of ID details.`
        : 'Please check that the details from your ID photo are correct.'
    return (
        <>
            {isSecondaryId ? (
                <>
                    <h1 className={spacing.spaceBelow16}>{heading}</h1>
                    <p className={spacing.spaceBelow16}>{formInfo}</p>
                </>
            ) : (
                <>
                    <h1 className={spacing.spaceBelow16}>Which ID would you like to use to sign up?</h1>
                    <p className={spacing.spaceBelow16}>
                        To use Sharesies, you’ll need to prove you are who you say you are. Provide one of the following
                        forms of ID, and we’ll match it to info held by the Australian Government.
                    </p>
                </>
            )}
            {((!isSecondaryId && !isRequiredSecondaryIdDetailsButNotBiometricVerification) ||
                isRequiredSecondaryIdDetailsButNotBiometricVerification) && (
                <Radio
                    dataTestId="radio--confirm-id-details"
                    name="idMethod"
                    label="Which ID do you have?"
                    isTouched
                    choices={choices}
                    onChange={onSelectMode}
                    value={mode}
                    additionalClassName={isSecondaryId ? spacing.spaceAbove32 : ''}
                />
            )}
            {idDuplicateError && <ErrorBox className={spacing.spaceBelow32} message={<IDDuplicateErrorMessage />} />}
            {mode === 'licence' && <AULicenceForm {...formProps} />}
            {mode === 'passport' && <AUPassportForm {...formProps} />}
            {mode === 'medicareCard' && <AUMedicareCardForm {...formProps} />}
            {mode === 'other' && !isDependent && <OtherInformation jurisdiction="au" />}
            {mode === 'other' && isDependent && <OverseasPassport {...formProps} />}
        </>
    )
}

interface OwnProps {
    isDependent: boolean
    preferredName: string
    identityVerificationDocuments?: Record<string, string>[]
    isSecondaryId?: boolean
    setStep(step: IdentityVerificationStep): void
}

interface StoreProps {
    primaryIdType: Model.User['checks']['identity_verification']['primary_id_type']
    identityVerification: Model.User['checks']['identity_verification']
}

interface DispatchProps {
    verifyAustralianLicence(
        payload: SharesiesOmit<Request.IdentityVerifiAustralianLicence, Request.ActingAsRequired>,
    ): Promise<null | Response.Error>
    verifyAustralianPassport(
        payload: SharesiesOmit<Request.IdentityVerifiAustralianPassport, Request.ActingAsRequired>,
    ): Promise<null | Response.Error>
    secondaryVerifyAustralianLicence(
        payload: SharesiesOmit<Request.IdentityVerifiAustralianLicence, Request.ActingAsRequired>,
    ): Promise<null | Response.Error>
    secondaryVerifyAustralianPassport(
        payload: SharesiesOmit<Request.IdentityVerifiAustralianPassport, Request.ActingAsRequired>,
    ): Promise<null | Response.Error>
    secondaryVerifyAustralianMedicareCard(
        payload: SharesiesOmit<Request.IdentityVerifiAustralianMedicareCard, Request.ActingAsRequired>,
    ): Promise<null | Response.Error>
}

type AUIdentityFormsProps = StoreProps & OwnProps & DispatchProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    ({identity}) => ({
        identityVerificationDocuments:
            identity.user?.checks.identity_verification.latest_biometric_verification_check?.documents,
        primaryIdType: identity.user?.checks.identity_verification.primary_id_type,
        isIdentityLinked: identity.user?.checks.identity_verification.is_identity_linked,
        identityVerification: identity.user!.checks.identity_verification,
    }),
    dispatch => ({
        verifyAustralianLicence: payload => dispatch(actions.VerifiAustralianLicence(payload)),
        verifyAustralianPassport: payload => dispatch(actions.VerifiAustralianPassport(payload)),
        secondaryVerifyAustralianLicence: payload => dispatch(actions.SecondaryVerifiAustralianLicence(payload)),
        secondaryVerifyAustralianPassport: payload => dispatch(actions.SecondaryVerifiAustralianPassport(payload)),
        secondaryVerifyAustralianMedicareCard: payload =>
            dispatch(actions.SecondaryVerifiAustralianMedicareCard(payload)),
    }),
)(AUIdentityForms)
