import {Thumbnail} from '@design-system/thumbnail'
import React from 'react'
import {DistillScope} from '~/api/query/distill'
import useDistillInstrumentInfo from '~/global/state-hooks/distill/useDistillInstrumentInfo'
import {generateInitialFilter, useDistillInstrument, useDistillSearch} from '~/sections/kiwisaver/data/distill'
import {Allocation} from '~/sections/kiwisaver/state'
import styles from './PlanThumbnailsRow.scss'

interface PlanThumbnailProps {
    allocation: Allocation
    resourcePath: string
}

export const PlanThumbnail: React.FunctionComponent<PlanThumbnailProps> = ({allocation, resourcePath}) => {
    // lookup the logo data, this should be in the cache already rather than needing a fresh call
    const instrument = useDistillInstrument({
        instrumentId: allocation.fund_id,
        scope: DistillScope.KIWISAVER_ALL_FUNDS,
        isUnderlyingInstrument: !!(allocation.fund_category === 'BASE'),
    })

    return (
        <div className={styles.planThumbnail} title={instrument.name} data-testid="plan-thumbnail">
            <Thumbnail
                dataTestId={`${instrument.urlSlug}--micro-logo`}
                width="42px"
                height="42px"
                symbol={instrument.symbol}
                path={instrument.logos.micro ? `${resourcePath}${instrument.logos.micro}` : undefined}
            />
        </div>
    )
}

interface PlanThumbnailsRowProps {
    allocations: Allocation[]
}

export const PlanThumbnailsRow: React.FunctionComponent<PlanThumbnailsRowProps> = ({allocations}) => {
    const selfSelectAmountToShow = 29
    const resourcePath = useDistillInstrumentInfo(DistillScope.KIWISAVER_ALL_FUNDS, {
        searchFundInvestments: true,
    }).filesHostAddress
    const initialFilter = generateInitialFilter(DistillScope.KIWISAVER_BASE_FUNDS)

    // data for the base underlying instrument(s) - note we do the actual lookup in PlanThumbnail
    const baseFundAllocations = allocations.filter(a => a.fund_category === 'BASE')
    // look up the full instruments to be able to pull the underlying fund ids
    const baseFunds = useDistillSearch({
        ...initialFilter,
        instruments: [...baseFundAllocations.map(allocation => allocation.fund_id)],
    })
    const baseAllocationsWithUnderlyingFundIds: Allocation[] = [] // new array so we can put new records in it without side effects from mutating existing records
    baseFunds.forEach(fund => {
        // push to our new transformed allocations array our frakensteined allocation-but-with-the-underlying-fund-id for our nested component to be able to use
        // we can assert safely that the allocation must exist and the underlying fund id must exist
        const existingRecord = baseFundAllocations.find(allocation => allocation.fund_id === fund.id)
        baseAllocationsWithUnderlyingFundIds.push({
            fund_id: fund.underlyingInstrumentId!,
            allocation_percent: existingRecord!.allocation_percent,
            fund_category: 'BASE',
        })
    })

    // data for the self select instruments - note we do the actual lookup in PlanThumbnail
    const selfSelect = allocations.filter(a => a.fund_category === 'SELF_SELECT')
    const showSelfSelectTruncated = selfSelect.length > selfSelectAmountToShow

    return (
        <div className={styles.planThumbnailRow} data-testid="plan-thumbnails-row">
            {baseAllocationsWithUnderlyingFundIds.map(allocation => (
                <PlanThumbnail
                    key={`thumbnail-${allocation.fund_id}`}
                    allocation={allocation}
                    resourcePath={resourcePath}
                />
            ))}
            {selfSelect.slice(0, selfSelectAmountToShow).map(allocation => (
                <PlanThumbnail
                    key={`thumbnail-${allocation.fund_id}`}
                    allocation={allocation}
                    resourcePath={resourcePath}
                />
            ))}
            {showSelfSelectTruncated && (
                <span className={styles.moreHidden}>and {selfSelect.length - selfSelectAmountToShow} more&hellip;</span>
            )}
        </div>
    )
}
