import {Button} from '@design-system/button'
import cn from 'classnames'
import {withFormik, FormikErrors} from 'formik'
import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {page} from '~/global/scss/helpers'
import {exceedsLowPricedSecurityCap} from '~/global/utils/exceeds-low-priced-security-cap/exceedsLowPricedSecurityCap'
import {isDisorderlyLimit} from '~/global/utils/is-disorderly-limit/isDisorderlyLimit'
import {isInstrumentEligibleForLimitSellExtendedHours} from '~/global/utils/is-instrument-eligible-for-extended-hours/isInstrumentEligibleForExtendedHours'
import {isOnNZX, isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import {Units} from '~/global/widgets/OLD_icons'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import WalletBalanceWrapper from '~/global/widgets/estimated-wallet-balance/WalletBalanceWrapper'
import {StrongCurrency, StrongNumber, Checkbox} from '~/global/widgets/form-controls/formik'
import {Loading} from '~/global/widgets/loading/Loading'
import {ShareValue} from '~/global/widgets/number-elements/NumberElements'
import {isNavigationDirective} from '~/migrate-react-router'
import styles from '~/sections/invest/sections/order-flow/OrderForm.scss'
import {calculateSharesRemaining} from '~/sections/invest/sections/order-flow/sections/sell/utils/calculate-shares-remaining/calculateSharesRemaining'
import {getPriceLimitDecimalPlaces} from '~/sections/invest/sections/order-flow/utils/get-price-limit-decimal-places/getPriceLimitDecimalPlaces'
import {ExtendedHoursModal} from '~/sections/invest/sections/order-flow/widgets/modals/ExtendedHoursModal'
import {State as AccountingState} from '~/store/accounting/types'
import {connect} from '~/store/connect'
import {FundHolding} from '~/store/identity/types'
import {Instrument} from '~/store/instrument/types'
import actions from '~/store/order/actions'
import {StagedSellOrder, State as OrderState} from '~/store/order/types'
import {UnwrapThunkAction} from '~/store/types'

interface LimitSellInSharesFormValues {
    priceLimit: string
    shareAmount: string
    extendedHours?: boolean
}

const LimitSellInSharesForm = withRouter(
    withFormik<LimitSellInSharesFormProps & WithRouterProps, LimitSellInSharesFormValues>({
        mapPropsToValues: ({stagedSellOrder, extendedHoursApplies}) => {
            if (!stagedSellOrder) {
                return {
                    priceLimit: '',
                    shareAmount: '',
                    extendedHours: extendedHoursApplies,
                }
            }
            return {
                priceLimit: stagedSellOrder.orderPriceLimit ? stagedSellOrder.orderPriceLimit : '',
                shareAmount:
                    stagedSellOrder.orderShareAmount && parseFloat(stagedSellOrder.orderShareAmount) > 0
                        ? stagedSellOrder.orderShareAmount
                        : '',
                extendedHours: stagedSellOrder.idempotencyKey ? !!stagedSellOrder.extendedHours : extendedHoursApplies, // if we got as far as confirm previously, use the stored value, otherwise default to on if applicable
            }
        },
        mapPropsToErrors: ({stagedSellOrder}) => {
            // make the button disabled initially if there is an initial error by setting at least one field to have an error
            if (
                !stagedSellOrder ||
                !stagedSellOrder.orderShareAmount ||
                Number(stagedSellOrder.orderShareAmount) === 0
            ) {
                return {shareAmount: undefined}
            }
            return {}
        },
        validate: (values, {instrument, holding}) => {
            const errors: FormikErrors<LimitSellInSharesFormValues> = {}

            if (!values.priceLimit || Number(values.priceLimit) === 0) {
                errors.priceLimit = '' // we don't actually display an error
            }

            const {isDisorderly, message} = isDisorderlyLimit(values.priceLimit, instrument)
            if (isDisorderly && message) {
                errors.priceLimit = message
            }

            const sharesNotAvailableForSell = holding
                ? Math.max(Number(holding.shares) - Number(holding.shares_active), 0)
                : 0
            if (!values.shareAmount || Number(values.shareAmount) === 0) {
                errors.shareAmount = '' // we don't actually display an error
            } else if (
                holding &&
                parseFloat(values.shareAmount) > parseFloat(holding.shares) - sharesNotAvailableForSell
            ) {
                // test if the supplied value is more than the amount available to sell
                {
                    errors.shareAmount = `You’ve entered more ${shareLabel({
                        instrument,
                        isPlural: true,
                    })} than you own. Try again with a lower number.`
                }
            }

            const {exceedsLimit, msg} = exceedsLowPricedSecurityCap(
                instrument,
                values.shareAmount,
                undefined,
                values.priceLimit,
                undefined,
            )

            if (exceedsLimit && msg) {
                errors.shareAmount = msg
            }

            return errors
        },
        handleSubmit: async (
            {priceLimit, shareAmount, extendedHours},
            {
                setSubmitting,
                props: {
                    stagedSellOrder,
                    updateStagedSellOrder,
                    costStagedSellOrder,
                    router: {navigate},
                    profileUrl,
                },
            },
        ) => {
            if (!stagedSellOrder) {
                return
            }

            rudderTrack('sell', 'order_details_entered', {instrument_id: stagedSellOrder.fundId})

            setSubmitting(true)
            try {
                updateStagedSellOrder({
                    ...stagedSellOrder,
                    orderPriceLimit: priceLimit,
                    orderShareAmount: shareAmount,
                    extendedHours,
                })
                const response = await costStagedSellOrder()
                if (isNavigationDirective(response)) {
                    response.execute(navigate, profileUrl)
                }
            } catch (error) {
                setSubmitting(false)
            }
        },
    })(
        ({
            values,
            errors,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            instrument,
            holding,
            updateStagedSellOrder,
            stagedSellOrder,
            exchangeRates,
            setFieldValue,
            setSubmitting,
            sellOrderAcceptableDP,
            getSellOrderAcceptableDP,
            sellOrderAcceptableDPLoadingState,
            accountRestricted,
            extendedHoursApplies,
        }) => {
            const [showExtendedHoursModal, setShowExtendedHoursModal] = React.useState<boolean>(false)

            React.useEffect(() => {
                if (sellOrderAcceptableDPLoadingState === 'ready') {
                    getSellOrderAcceptableDP()
                }
            }, [])
            React.useEffect(() => {
                if (stagedSellOrder && stagedSellOrder.error) {
                    setSubmitting(false)
                }
            }, [stagedSellOrder])

            const sharesNotAvailableForSell = holding
                ? Math.max(Number(holding.shares) - Number(holding.shares_active), 0)
                : 0
            const totalSharesAvailableToSell = holding
                ? parseFloat(calculateSharesRemaining('0', holding.shares, sharesNotAvailableForSell))
                : 0

            const formattedSharesRemaining = React.useMemo(() => {
                if (!holding) {
                    return '-'
                }

                const sharesRemaining = values.shareAmount
                    ? calculateSharesRemaining(values.shareAmount, holding.shares, sharesNotAvailableForSell)
                    : totalSharesAvailableToSell

                return (
                    <ShareValue
                        value={sharesRemaining}
                        wholeSharesOnly={isOnMainUsExchange(instrument)}
                        showFullValue
                    />
                )
            }, [holding, values, instrument, stagedSellOrder])

            const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault()
                if (values.extendedHours) {
                    // US shares extended hours - if enabled show warning before proceeeding to submit
                    setShowExtendedHoursModal(true)
                } else {
                    handleSubmit(e)
                }
            }

            const preventSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault()
                }
            }

            const handleOnBlur = (event: string | React.FocusEvent<unknown>) => {
                if (typeof event === 'string') {
                    return
                }
                handleBlur(event)

                if (!stagedSellOrder) {
                    return
                }

                // TODO: [DS-636] clean up this logic
                // Hack to prevent loss of focus on the input field while the component re-renders
                setTimeout(() => {
                    // store any user entered values immediately so the user can look at
                    // the market depth page without losing data
                    updateStagedSellOrder({
                        ...stagedSellOrder,
                        orderPriceLimit: values.priceLimit,
                        orderShareAmount: values.shareAmount,
                        extendedHours: values.extendedHours,
                    })
                }, 0)
            }

            // Want this to always display regardless of validation cycle
            if (holding && parseFloat(holding.shares) < 1 && isOnMainUsExchange(instrument)) {
                if (!errors) {
                    errors = {}
                }

                if (!errors.shareAmount) {
                    errors.shareAmount = `Can't limit sell less than one ${shareLabel({instrument})}`
                }
            }

            const displaySellAll =
                holding &&
                // If it's an NZX holding we can continue
                (isOnNZX(instrument) ||
                    // OR it's a US investment and holding is > 1 share, we can continue
                    parseFloat(holding.shares) > 1) &&
                // AND if value is not set
                (!values.shareAmount ||
                    // OR it is set and it's lower than total holding, we can absolutely definitely display the Sell all control
                    parseFloat(values.shareAmount) < parseFloat(holding.shares))

            if (sellOrderAcceptableDPLoadingState === 'loading') {
                return <Loading isPineapple />
            }

            if (sellOrderAcceptableDPLoadingState === 'error') {
                return <WeSlippedUp />
            }

            return (
                <form onSubmit={onSubmit}>
                    {showExtendedHoursModal && (
                        <ExtendedHoursModal
                            orderType={stagedSellOrder?.orderType}
                            onContinue={() => {
                                setShowExtendedHoursModal(false)
                                handleSubmit()
                            }}
                            onClose={() => setShowExtendedHoursModal(false)}
                        />
                    )}

                    <StrongCurrency
                        dataTestId="strong-currency--price-limit"
                        name="priceLimit"
                        label={`Lowest price to sell per ${shareLabel({instrument})}`}
                        disabled={isSubmitting || accountRestricted}
                        autoFocus
                        optionalAttributes={{
                            onKeyPress: preventSubmit,
                            onBlur: handleOnBlur,
                        }}
                        decimalPlaces={getPriceLimitDecimalPlaces(instrument)}
                        currency={instrument.currency}
                    />

                    <StrongNumber
                        dataTestId="strong-number--share-amount"
                        name="shareAmount"
                        label={`Number of ${shareLabel({
                            instrument,
                            isPlural: true,
                            isWhole: isOnMainUsExchange(instrument),
                        })} to sell`}
                        disabled={isSubmitting || accountRestricted}
                        optionalAttributes={{
                            onKeyPress: preventSubmit,
                            onBlur: handleOnBlur,
                        }}
                        placeholder={shareLabel({
                            instrument,
                            isPlural: true,
                            isCapitalised: true,
                            isWhole: isOnMainUsExchange(instrument),
                        })}
                        normalisation={isOnMainUsExchange(instrument) ? 'numberOnly' : 'decimalOnly'}
                        decimalPlaces={
                            isOnMainUsExchange(instrument)
                                ? 0 // For now, we only allow selling the whole share for US shares
                                : sellOrderAcceptableDP
                                  ? parseFloat(sellOrderAcceptableDP.acceptableDP)
                                  : undefined
                        }
                        helpText={
                            <p className={styles.sharesRemaining}>
                                <Units /> {shareLabel({instrument, isPlural: true, isCapitalised: true})} remaining:{' '}
                                <strong>{formattedSharesRemaining}</strong>
                                {holding && displaySellAll && (
                                    <ButtonAsLink
                                        className={styles.linkButton}
                                        onClick={() =>
                                            setFieldValue(
                                                'shareAmount',
                                                isOnMainUsExchange(instrument)
                                                    ? Math.trunc(totalSharesAvailableToSell).toString()
                                                    : totalSharesAvailableToSell.toString(),
                                            )
                                        }
                                    >
                                        Sell all
                                    </ButtonAsLink>
                                )}
                            </p>
                        }
                    />

                    {extendedHoursApplies && (
                        <div className={styles.extendedHoursCheckbox}>
                            <Checkbox
                                dataTestId="checkbox--extended-hours"
                                name="extendedHours"
                                label="Allow order to fill during extended hours"
                                helpText="When selected, all or some of your order may fill during extended hours. If unselected, it’ll only fill during regular market hours."
                                additionalClassName={styles.checkbox}
                            />
                        </div>
                    )}

                    <ActionBar className={cn(page.flexRow, styles.formFooter)}>
                        <WalletBalanceWrapper displayCurrency={instrument.currency} exchangeRates={exchangeRates} />
                        <Button
                            label="Review"
                            dataTestId="button--review"
                            disabled={!isValid || accountRestricted}
                            processing={isSubmitting}
                            isSubmit
                        />
                    </ActionBar>
                </form>
            )
        },
    ),
)

interface StoreProps {
    holding?: FundHolding
    stagedSellOrder?: StagedSellOrder
    sellOrderAcceptableDP?: OrderState['sellOrderAcceptableDP']
    sellOrderAcceptableDPLoadingState: OrderState['sellOrderAcceptableDPLoadingState']
    accountRestricted: boolean
    extendedHoursApplies: boolean
}

interface OwnProps {
    instrument: Instrument
    exchangeRates: AccountingState['exchangeRates']
    profileUrl: ReturnType<typeof useProfileUrl>
}

interface DispatchProps {
    updateStagedSellOrder(order: StagedSellOrder): void
    costStagedSellOrder: UnwrapThunkAction<typeof actions.CostSellOrder>
    getSellOrderAcceptableDP(): void
}

type LimitSellInSharesFormProps = StoreProps & DispatchProps & OwnProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    (state, {instrument}) => {
        const {order, identity} = state
        return {
            stagedSellOrder: order.stagedSellOrder,
            holding: identity.holdings.find(holding => holding.fund_id === instrument.id),
            sellOrderAcceptableDP: order.sellOrderAcceptableDP,
            sellOrderAcceptableDPLoadingState: order.sellOrderAcceptableDPLoadingState,
            accountRestricted: identity.user!.account_restricted,
            extendedHoursApplies:
                identity.portfolioExtendedHoursPreference && isInstrumentEligibleForLimitSellExtendedHours(instrument),
        }
    },
    dispatch => ({
        updateStagedSellOrder: order => dispatch(actions.UpdateStagedSellOrder(order)),
        costStagedSellOrder: () => dispatch(actions.CostSellOrder()),
        getSellOrderAcceptableDP: () => dispatch(actions.GetSellOrderAcceptableDP()),
    }),
)(LimitSellInSharesForm)
