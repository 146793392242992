export interface FullNameType {
    firstName: string
    middleName: string
    lastName: string
}

export const formatFullName = (fullName: FullNameType): string => {
    // Display full name, with spaces in between
    return [fullName.firstName, fullName.middleName, fullName.lastName].filter(nameItem => nameItem).join(' ')
}
