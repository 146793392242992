import Decimal from 'decimal.js'
import React from 'react'
import {useRetailGet} from '~/api/query/retail'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {useRakaiaReadPortfolio} from '~/sections/kiwisaver/data/rakaia'
import {useExistingKSCustomer} from '~/sections/kiwisaver/state'

export const TotalBalance = () => {
    const customer = useExistingKSCustomer()
    const returns = useRakaiaReadPortfolio(customer.portfolio_id ?? '')
    const {data: cashHoldings} = useRetailGet({path: 'kiwisaver/cash-holdings'})
    const totalBalance = new Decimal(returns.portfolio_value || '0').add(cashHoldings.nzd_holdings)

    return <DollarValue value={totalBalance.toNumber()} />
}
