import {Button} from '@design-system/button'
import cn from 'classnames'
import {withFormik} from 'formik'
import React from 'react'
import {Model} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {page} from '~/global/scss/helpers'
import calculateWalletBalance from '~/global/utils/calculate-wallet-balance/calculateWalletBalance'
import {exceedsLowPricedSecurityCap} from '~/global/utils/exceeds-low-priced-security-cap/exceedsLowPricedSecurityCap'
import {isDisorderlyLimit} from '~/global/utils/is-disorderly-limit/isDisorderlyLimit'
import {isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import EstimatedWalletBalanceWithModal from '~/global/widgets/estimated-wallet-balance/EstimatedWalletBalanceWithModal'
import {StrongCurrency} from '~/global/widgets/form-controls/formik'
import {isNavigationDirective, Link} from '~/migrate-react-router'
import styles from '~/sections/invest/sections/order-flow/OrderForm.scss'
import {getPriceLimitDecimalPlaces} from '~/sections/invest/sections/order-flow/utils/get-price-limit-decimal-places/getPriceLimitDecimalPlaces'
import {State} from '~/store/accounting/types'
import {connect} from '~/store/connect'
import {selectIsInstrumentInExtendedHours} from '~/store/instrument/selectors'
import {Instrument} from '~/store/instrument/types'
import actions from '~/store/order/actions'
import {StagedBuyOrder} from '~/store/order/types'
import {UnwrapThunkAction} from '~/store/types'

interface TriggerBuyInDollarsFormValues {
    priceLimit: string
    triggerPrice: string
    currencyAmount: string
}

const TriggerBuyInDollarsForm = withRouter(
    withFormik<TriggerBuyInDollarsFormProps & WithRouterProps, TriggerBuyInDollarsFormValues>({
        mapPropsToValues: ({stagedBuyOrder}) => {
            if (!stagedBuyOrder) {
                return {
                    priceLimit: '',
                    currencyAmount: '',
                    triggerPrice: '',
                }
            }
            return {
                priceLimit: stagedBuyOrder.orderPriceLimit ? stagedBuyOrder.orderPriceLimit : '',
                currencyAmount: stagedBuyOrder.orderCurrencyAmount ? stagedBuyOrder.orderCurrencyAmount : '',
                triggerPrice: stagedBuyOrder.orderTriggerPrice ? stagedBuyOrder.orderTriggerPrice : '',
            }
        },
        mapPropsToErrors: ({stagedBuyOrder}) => {
            // make the button disabled initially if there is an initial error by setting at least one field to have an error
            if (!stagedBuyOrder || !(stagedBuyOrder.orderPriceLimit && stagedBuyOrder.orderCurrencyAmount)) {
                return {priceLimit: undefined}
            }
            // if there is an existing amount that got as far as stagedOrder, assume it is valid
            return {}
        },
        validate: async (values, {instrument}) => {
            const errors: {
                priceLimit?: string
                currencyAmount?: string
                triggerPrice?: string
            } = {}

            if (!values.triggerPrice || Number(values.triggerPrice) === 0) {
                errors.triggerPrice = '' // we don't actually display an error
            }

            if (parseFloat(values.triggerPrice) < parseFloat(instrument.marketPrice) + 0.05) {
                errors.triggerPrice =
                    'Price is too low! Please place a trigger price that is more than 5c above the current market price.'
            }

            const {isDisorderly, message} = isDisorderlyLimit(values.priceLimit, instrument)
            if (isDisorderly && message) {
                errors.priceLimit = message
            }

            if (!values.currencyAmount || Number(values.currencyAmount) === 0) {
                errors.currencyAmount = '' // we don't actually display an error
            }
            // Note: not having enough money in your wallet is *not* an error

            return errors
        },

        handleSubmit: async (
            {priceLimit, currencyAmount, triggerPrice},
            {
                setSubmitting,
                props: {
                    stagedBuyOrder,
                    updateStagedBuyOrder,
                    costStagedBuyOrder,
                    router: {navigate},
                    profileUrl,
                },
            },
        ) => {
            if (!stagedBuyOrder) {
                return
            }

            rudderTrack('buy', 'order_details_entered', {instrument_id: stagedBuyOrder.fundId})

            setSubmitting(true)

            try {
                updateStagedBuyOrder({
                    ...stagedBuyOrder,
                    orderPriceLimit: priceLimit === '' ? undefined : priceLimit,
                    orderCurrencyAmount: currencyAmount,
                    orderTriggerPrice: triggerPrice,
                })
                const response = await costStagedBuyOrder()
                if (isNavigationDirective(response)) {
                    response.execute(navigate, profileUrl)
                }
            } catch (error) {
                setSubmitting(false)
            }
        },
    })(
        ({
            values,
            handleBlur,
            handleSubmit,
            instrument,
            isValid,
            isSubmitting,
            walletBalances,
            exchangeRates,
            exchangeFeeRate,
            usEquitiesEnabled,
            updateStagedBuyOrder,
            stagedBuyOrder,
            setSubmitting,
            accountRestricted,
            instrumentIsInExtendedHours,
        }) => {
            const profileUrl = useProfileUrl()

            React.useEffect(() => {
                if (stagedBuyOrder && stagedBuyOrder.error) {
                    setSubmitting(false)
                }
            }, [stagedBuyOrder])

            const isRemainingBalanceNegative = React.useMemo(() => {
                if (!walletBalances) {
                    return false
                }

                const walletBalanceInDisplayCurrency = calculateWalletBalance(
                    walletBalances,
                    exchangeRates,
                    usEquitiesEnabled,
                    instrument.currency,
                    exchangeFeeRate,
                )

                return walletBalanceInDisplayCurrency
                    ? parseFloat(walletBalanceInDisplayCurrency) - parseFloat(values.currencyAmount) < 0
                    : false
            }, [walletBalances, exchangeRates, usEquitiesEnabled, instrument.currency, values])

            const exceedsLowPriceLimit = React.useMemo(() => {
                const {exceedsLimit, msg} = exceedsLowPricedSecurityCap(
                    instrument,
                    undefined,
                    values.currencyAmount,
                    undefined,
                    values.triggerPrice,
                )

                if (exceedsLimit && msg) {
                    return {exceedsLimit, msg}
                }
            }, [instrument, values])

            const preventSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault()
                }
            }

            const handleOnBlur = (event: string | React.FocusEvent<unknown>) => {
                if (typeof event === 'string') {
                    return
                }
                handleBlur(event)

                if (!stagedBuyOrder) {
                    return
                }

                // TODO: [DS-636] clean up this logic
                // Hack to prevent loss of focus on the input field while the component re-renders
                setTimeout(() => {
                    // store any user entered values immediately so the user can look at
                    // the market depth page without losing data
                    updateStagedBuyOrder({
                        ...stagedBuyOrder,
                        orderPriceLimit: values.priceLimit,
                        orderCurrencyAmount: values.currencyAmount,
                    })
                }, 0)
            }

            return (
                <>
                    <form onSubmit={handleSubmit}>
                        <StrongCurrency
                            dataTestId="strong-currency--price-trigger"
                            name="triggerPrice"
                            label={`${shareLabel({instrument, isCapitalised: true})} price to trigger buy order`}
                            disabled={isSubmitting || accountRestricted}
                            autoFocus
                            optionalAttributes={{
                                onKeyPress: preventSubmit,
                                onBlur: handleOnBlur,
                            }}
                            decimalPlaces={getPriceLimitDecimalPlaces(instrument)}
                            currency={instrument.currency}
                            helpText={<p>Your order might fill higher or lower than this price.</p>}
                        />

                        {!isOnMainUsExchange(instrument) && (
                            <StrongCurrency
                                dataTestId="strong-currency--price-limit"
                                name="priceLimit"
                                label={`Highest price to pay per ${shareLabel({instrument})}`}
                                disabled={isSubmitting || accountRestricted}
                                autoFocus
                                optionalAttributes={{
                                    onKeyPress: preventSubmit,
                                    onBlur: handleOnBlur,
                                }}
                                decimalPlaces={3}
                                currency={instrument.currency}
                            />
                        )}

                        <StrongCurrency
                            dataTestId="strong-currency--currency-amount"
                            name="currencyAmount"
                            label="Order amount"
                            disabled={isSubmitting || accountRestricted}
                            optionalAttributes={{
                                onKeyPress: preventSubmit,
                                onBlur: handleOnBlur,
                            }}
                            currency={instrument.currency}
                        />

                        {exceedsLowPriceLimit?.exceedsLimit && (
                            <div className={styles.limitWarning} data-testid="exceeds-low-price-limit">
                                {exceedsLowPriceLimit.msg}
                            </div>
                        )}

                        {isRemainingBalanceNegative && !exceedsLowPriceLimit?.exceedsLimit && (
                            <div className={styles.balanceWarning} data-testid="insufficient-funds">
                                There’s not enough money in your Wallet. <Link to={profileUrl('wallet')}>Top up</Link>
                            </div>
                        )}

                        {instrumentIsInExtendedHours && (
                            <AlertCard type="info">
                                <p>Trigger buy orders for this investment only trigger during regular market hours.</p>
                            </AlertCard>
                        )}

                        <ActionBar className={cn(page.flexRow, styles.formFooter)}>
                            <EstimatedWalletBalanceWithModal
                                displayCurrency={instrument.currency}
                                exchangeRates={exchangeRates}
                            />
                            <Button
                                dataTestId="button--review"
                                label="Review"
                                disabled={
                                    !isValid ||
                                    isRemainingBalanceNegative ||
                                    accountRestricted ||
                                    exceedsLowPriceLimit?.exceedsLimit
                                }
                                processing={isSubmitting}
                                isSubmit
                            />
                        </ActionBar>
                    </form>
                </>
            )
        },
    ),
)

interface StoreProps {
    walletBalances?: Model.User['wallet_balances']
    usEquitiesEnabled: boolean
    stagedBuyOrder?: StagedBuyOrder
    accountRestricted: boolean
    instrumentIsInExtendedHours: boolean
}

interface DispatchProps {
    costStagedBuyOrder: UnwrapThunkAction<typeof actions.CostBuyOrder>
    updateStagedBuyOrder(order: StagedBuyOrder): void
}

interface OwnProps {
    instrument: Instrument
    exchangeRates: State['exchangeRates']
    exchangeFeeRate: State['exchangeFeeRate']
    profileUrl: ReturnType<typeof useProfileUrl>
}

type TriggerBuyInDollarsFormProps = StoreProps & DispatchProps & OwnProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    (state, {instrument}) => {
        const {order, identity} = state

        return {
            usEquitiesEnabled: identity.user ? identity.user.us_equities_enabled : false,
            walletBalances: identity.user!.wallet_balances,
            stagedBuyOrder: order.stagedBuyOrder,
            accountRestricted: identity.user!.account_restricted,
            instrumentIsInExtendedHours: !!selectIsInstrumentInExtendedHours(state, instrument),
        }
    },
    dispatch => ({
        costStagedBuyOrder: () => dispatch(actions.CostBuyOrder()),
        updateStagedBuyOrder: order => dispatch(actions.UpdateStagedBuyOrder(order)),
    }),
)(TriggerBuyInDollarsForm)
