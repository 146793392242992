import React from 'react'
import {ListingResponseDto} from '~/api/distill'
import {DistillScope} from '~/api/query/distill'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {isOnNZX} from '~/global/utils/is-on-exchange/isOnExchange'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import PricesCompanyEtf from '~/global/widgets/help-modals/PricesCompanyEtf'
import PricesKiwiSaverModal from '~/global/widgets/help-modals/PricesKiwiSaver'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {SharePriceValue} from '~/global/widgets/number-elements/NumberElements'
import WatchlistButton from '~/sections/explore/widgets/watchlist/WatchlistButton'
import PriceNotification from '~/sections/invest/sections/view-instrument/widgets/header/PriceNotification'
import {Instrument} from '~/store/instrument/types'
import InstrumentSubHeader from './InstrumentSubHeader'
import styles from './InstrumentHeader.scss'

interface InstrumentHeaderProps {
    instrument: ListingResponseDto
    showWatchlistButton: boolean
    distillScope: DistillScope
    showPriceNotification?: boolean
    portfolioIdForPriceNotification?: string
}

/**
 * Displays an informational widget for the instrument logo, name, and current share price.
 * Includes functionality for updating the customer's watchlist.
 *
 * - Does not currently support US extended trading.
 * - Does not support price notifications.
 */
export const InstrumentHeader: React.FunctionComponent<InstrumentHeaderProps> = ({
    instrument,
    showWatchlistButton,
    distillScope,
    portfolioIdForPriceNotification,
}) => {
    const currency = 'nzd'

    const isKiwiSaverDistillScope = [
        DistillScope.KIWISAVER_ALL_FUNDS,
        DistillScope.KIWISAVER_BASE_FUNDS,
        DistillScope.KIWISAVER_SELF_SELECT_FUNDS,
    ].includes(distillScope)

    const priceModal = () => {
        if (isKiwiSaverDistillScope) {
            return <PricesKiwiSaverModal forBuySellFlow={false} />
        }

        return <PricesCompanyEtf instrument={instrument as Instrument} />
    }

    return (
        <div>
            <div className={styles.headerLogo}>
                <InstrumentLogo instrument={instrument} noBorder />
            </div>

            <div className={styles.instrumentName}>
                <h1 className={styles.headerName} data-testid="fund-name">
                    {instrument.name}
                </h1>
                {showWatchlistButton && (
                    <WatchlistButton instrumentId={instrument.id} instrumentName={instrument.name} />
                )}
            </div>
            {['listed', 'adr'].includes(tradingType(instrument)) && !isInstrumentInNoTrade(instrument) && (
                <InstrumentSubHeader instrument={instrument} isOnNZX={isOnNZX(instrument)} />
            )}
            <div className={styles.headerPrice}>
                <SharePriceValue value={instrument.marketPrice} currency={currency} />
                {priceModal()}
            </div>
            {portfolioIdForPriceNotification && (
                <PriceNotification
                    urlSlug={instrument.urlSlug}
                    portfolioId={portfolioIdForPriceNotification}
                    distillScope={distillScope}
                    showExtendedHours
                />
            )}
        </div>
    )
}
