import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {useHasInsureAccess} from '~/global/state-hooks/retail/useHasInsureAccess'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ListMenuGroup, ListMenuItem} from '~/global/widgets/list-menu/ListMenu'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {useAppSelector} from '~/store/hooks'

export const LegalInformation: React.FC = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const owner = useProfileOwner()
    const profile = useProfile()
    const hasInvestProduct = profile.portfolios.some(p => p.product === 'INVEST')

    const hasInsureAccess = useHasInsureAccess()
    const hasTelescopeFlag = useAppSelector(s => s.identity.flags.ai_search)
    const isDependent = useAppSelector(s => s.identity.user?.is_dependent)
    const jurisdiction = useAppSelector(s => s.identity.user?.jurisdiction)

    return (
        <>
            <Toolbar dataTestId="toolbar--legal-information" leftButton="back" title="Legal information" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <ListMenuGroup className={spacing.spaceBelow40} heading="Platform and product terms">
                    {jurisdiction === 'nz' && (
                        <ListMenuItem
                            label="General Terms and Conditions"
                            onClick={() => navigate(profileUrl('legal/general-terms-and-conditions'))}
                        />
                    )}
                    {owner.is_fonterra_customer && (
                        <ListMenuItem
                            label="Farmer Trading Service Terms and Conditions"
                            onClick={() => navigate(profileUrl('legal/farmer-trading-service-terms-and-conditions'))}
                        />
                    )}
                    {jurisdiction === 'nz' && hasInvestProduct && (
                        <ListMenuItem
                            label="Invest Terms and Conditions"
                            onClick={() => navigate(profileUrl('legal/invest-terms-and-conditions'))}
                        />
                    )}
                    {jurisdiction === 'nz' && (
                        <>
                            <ListMenuItem
                                label="Save Terms and Conditions"
                                onClick={() => navigate(profileUrl('legal/save-terms'))}
                            />
                            {hasInsureAccess && (
                                <ListMenuItem
                                    label="Sharesies Cove terms and conditions"
                                    onClick={() => navigate(profileUrl('legal/sharesies-cove-promotional-terms'))}
                                />
                            )}
                            {!isDependent && owner.owner_type !== 'ORGANISATION' && (
                                <ListMenuItem
                                    label="Kids Accounts Terms and Conditions"
                                    onClick={() => navigate(profileUrl('legal/kids-terms'))}
                                />
                            )}
                        </>
                    )}

                    {jurisdiction === 'au' && (
                        <>
                            <ListMenuItem
                                label="Supplementary IDPS Guide"
                                externalLink="https://sharesies.com.au/disclosures/supplementary-idps-guide-july-2024.pdf"
                            />
                            <ListMenuItem
                                label="Investor Directed Portfolio Service (IDPS) Guide"
                                externalLink="https://sharesies.com.au/disclosures/idps-guide.pdf"
                            />
                            <ListMenuItem
                                label="Financial Services Guide (FSG)"
                                externalLink="https://sharesies.com.au/disclosures/financial-services-guide.pdf"
                            />
                            <ListMenuItem
                                label="Target Market Determination (TMD)"
                                externalLink="https://sharesies.com.au/disclosures/target-market-determination.pdf"
                            />
                            <ListMenuItem
                                label="Privacy Statement"
                                onClick={() => navigate(profileUrl('legal/privacy-statement'))}
                            />
                            <ListMenuItem
                                label="Collection Notice"
                                onClick={() => navigate(profileUrl('legal/collection-notice'))}
                            />
                        </>
                    )}
                    {owner.owner_type !== 'ORGANISATION' && (
                        <ListMenuItem
                            label="Referral Terms and Conditions"
                            onClick={() => navigate(profileUrl('legal/referral-terms'))}
                        />
                    )}
                    {owner.owner_type !== 'ORGANISATION' && (
                        <ListMenuItem
                            label="Gift Terms and Conditions"
                            onClick={() => navigate(profileUrl('legal/gifting-terms'))}
                        />
                    )}
                </ListMenuGroup>

                {owner.owner_type !== 'ORGANISATION' && (
                    <ListMenuGroup heading="Feature Terms" className={spacing.spaceBelow32}>
                        {hasTelescopeFlag && (
                            <>
                                <ListMenuItem
                                    label="AI Search Terms and Conditions"
                                    onClick={() => navigate(profileUrl('legal/ai-search-terms'))}
                                />
                            </>
                        )}
                        {jurisdiction === 'au' && (
                            <ListMenuItem
                                label="Bank Linking Terms and Conditions"
                                onClick={() => navigate(profileUrl('legal/bank-linking-terms-and-conditions'))}
                            />
                        )}
                        <ListMenuItem
                            label="ASX Transfers Terms and Conditions"
                            onClick={() => navigate(profileUrl('legal/asx-share-transfers-terms'))}
                        />
                        <ListMenuItem
                            label="NZX Transfers Terms and Conditions"
                            onClick={() => navigate(profileUrl('legal/nzx-share-transfers-terms'))}
                        />
                        <ListMenuItem
                            label="US Transfers Terms and Conditions"
                            onClick={() => navigate(profileUrl('legal/us-share-transfers-terms'))}
                        />
                    </ListMenuGroup>
                )}
                <ListMenuGroup heading="Policies and codes" className={spacing.spaceBelow32}>
                    {jurisdiction === 'nz' && (
                        <ListMenuItem
                            label={
                                owner.is_fonterra_customer ? 'Farmer Trading Service Privacy Policy' : 'Privacy Policy'
                            }
                            onClick={() => navigate(profileUrl('legal/privacy-policy'))}
                        />
                    )}
                    <ListMenuItem label="Best Price Policy" onClick={() => navigate(profileUrl('legal/best-price'))} />
                    {owner.owner_type !== 'ORGANISATION' && (
                        <ListMenuItem
                            label="Community Code of Conduct"
                            onClick={() => navigate(profileUrl('legal/community-code-of-conduct'))}
                        />
                    )}
                </ListMenuGroup>
            </Page>
        </>
    )
}

export default LegalInformation
