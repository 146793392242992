import {Model} from '~/api/retail/types'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {Order} from '~/store/accounting/types'

type CategorisedOrder = string | [string, string] // Either an explicit singular/plural category, or a 's' will implicitly be added

/** Unsurprisingly, corporate actions are complicated, and they need a little bit more work to split them into sub-categories */
function categoriseCorporateAction(order: Model.CorporateActionV2): CategorisedOrder {
    switch (order.action_type) {
        case 'VOTE':
            return 'vote'
        case 'EXERCISE':
        case 'PURCHASE':
            return 'application'
        case 'DIVIDEND':
        case 'INTEREST':
        case 'RETURN_OF_CAPITAL':
        case 'CAPITAL_GAINS_DISTRIBUTION':
            return 'dividend'
        case 'SHARE_DIVIDEND':
        case 'SHARE_SPLIT':
        case 'SHARE_CONSOLIDATION':
        case 'SHARE_ISSUE':
        case 'CANCELLATION':
            return 'share change'
        case 'GENERIC':
        case 'MERGER_OR_ACQUISITION':
        case 'SELLDOWN':
        case 'SHUNT':
        case 'CASH_ISSUE':
            return 'corporate action'
        default:
            assertNever(order.action_type)
            return 'corporate action'
    }
}

/** Given an order, will categorise it and return a label */
function categoriseOrder(order: Order): CategorisedOrder {
    switch (order.type) {
        case 'corporate_action':
            return 'corporate action' // Unused, no recent orders
        case 'buy':
            return order.trigger_price ? 'trigger buy order' : 'buy order'
        case 'sell':
            return order.trigger_price ? 'stop loss sell order' : 'sell order'
        case 'transfer_in':
            return 'transfer in request'
        case 'transfer_out':
            return 'transfer out request'
        case 'ess_share_allocation_line':
            return ['share allocation received', 'share allocations received']
        case 'ess_share_right_allocation_line':
            return ['share right allocation received', 'share right allocations received']
        case 'ess_alignment_right_allocation_line':
            return ['share alignment right allocation received', 'share alignment right allocations received']
        case 'dividend':
            return 'dividend'
        case 'corporate_action_v2':
            return categoriseCorporateAction(order)
        default:
            assertNever(order)
            return 'order'
    }
}

/** Given a list of orders, aggregate them together and group them appropriately */
export function aggregateOrders(orders: Order[]): string[] {
    // First step: map every order to it's label
    const categories = orders.map(categoriseOrder)

    // Second step: aggregate all those labels, so we can count how many orders appear in each
    interface Aggregate {
        singular: string
        plural: string
        count: number
    }
    const aggregatedCategories = categories.reduce(
        (accumulator, category) => {
            // Most plural labels are simply the singular + 's', so a straight string can be used as a shortcut
            const singular = Array.isArray(category) ? category[0] : category
            const plural = Array.isArray(category) ? category[1] : category + 's'

            accumulator[singular] ??= {singular, plural, count: 0}
            accumulator[singular].count++
            return accumulator
        },
        {} as Record<string, Aggregate>,
    )

    // Finally, resolve those aggregated categories to their actual string representations
    return Object.values(aggregatedCategories).map(
        ({count, singular, plural}) => `${count}\xa0${count !== 1 ? plural : singular}`,
    )
}
