import Decimal from 'decimal.js'
import React from 'react'
import {Navigate} from 'react-router-dom'
import {Model} from '~/api/retail/types'
import * as rollbar from '~/api/rollbar/rollbar'
import confirmStyles from '~/global/scss/reused-styles/confirm.scss'
import {autoExerciseBuyCostBreakdown} from '~/global/utils/exercise-cost/exerciseCost'
import {feeCoverage} from '~/global/utils/fee-coverage/feeCoverage'
import {isOnASX} from '~/global/utils/is-on-exchange/isOnExchange'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import BuyAndAutoExerciseCost from '~/global/widgets/help-modals/BuyAndAutoExerciseCost'
import BuyAndSellFeesRights from '~/global/widgets/help-modals/BuyAndSellFeesRights'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {DollarValue, FeeValue, SharePriceValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import {OrderConfirmation, CoverageText} from '~/global/widgets/order-confirmation/OrderConfirmation'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import ConfirmExchange from '~/sections/invest/sections/order-flow/widgets/fx-confirm-exchange/ConfirmExchange'
import {getFeeCap} from '~/sections/invest/sections/view-instrument/sections/overview/widgets/fees/Fees'
import {canMakeAutoExerciseBuyOrder} from '~/store/accounting/selectors'
import {useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import {StagedBuyOrder} from '~/store/order/types'
import styles from './AutoExerciseOrderConfirmation.scss'

interface AutoExerciseOrderConfirmationProps {
    instrument: Instrument
    parentInstrument: Instrument
    stagedBuyOrder: StagedBuyOrder
    jurisdiction: Model.User['jurisdiction']
}

const AutoExerciseOrderConfirmation: React.FunctionComponent<AutoExerciseOrderConfirmationProps> = ({
    instrument,
    parentInstrument,
    stagedBuyOrder,
    jurisdiction,
}) => {
    const profileUrl = useProfileUrl()
    const {expectedFee, coverageToHold, paymentBreakdown, totalCost, orderPriceLimit} = stagedBuyOrder
    const userId = useAppSelector(s => s.identity.user?.id)
    const canMakeAutoExerciseBuy = useAppSelector(s => canMakeAutoExerciseBuyOrder(s, instrument.id))

    const handleError = () => {
        rollbar.sendError('AutoExerciseOrderConfirmation: cannot make auto exercise buy, redirecting to /', {
            userId,
        })
        return <Navigate to={profileUrl('')} replace />
    }

    if (!canMakeAutoExerciseBuy || !instrument.exercisePrice || !totalCost || !stagedBuyOrder.orderCurrencyAmount) {
        return handleError()
    }

    const orderHasFee = !!(expectedFee && parseFloat(expectedFee) > 0)
    const costBreakdown = autoExerciseBuyCostBreakdown(
        instrument,
        stagedBuyOrder.orderCurrencyAmount,
        jurisdiction,
        orderPriceLimit,
        orderHasFee,
    )
    const isDollarLimitBuy = stagedBuyOrder.orderType === 'dollar_limit'
    const {feeCap, feeCurrency} = getFeeCap(instrument, jurisdiction)

    if (!costBreakdown) {
        return handleError()
    }

    const coverageType = feeCoverage(new Decimal(coverageToHold ?? '0'), expectedFee)

    return (
        <OrderConfirmation
            title={instrument.name}
            subtitle={
                <p className={confirmStyles.symbol}>
                    <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                    <PronounceLetters text={instrument.exchange} />
                </p>
            }
            image={<InstrumentLogo instrument={instrument} noBorder />}
            items={[
                // buy breakdown
                {
                    description: (
                        <p className={confirmStyles.confirmationSubHeading}>
                            First, we’ll place your buy order for {shareLabel({instrument, isPlural: true})}…
                        </p>
                    ),
                },
                {
                    description: `${isDollarLimitBuy ? 'Highest price to pay' : 'Estimated price'} per ${shareLabel({
                        instrument,
                    })}`,
                    value: (
                        <SharePriceValue
                            value={isDollarLimitBuy && orderPriceLimit ? orderPriceLimit : instrument.marketPrice}
                            currency={instrument.currency}
                        />
                    ),
                },
                {
                    description: `Estimated number of ${shareLabel({instrument, isPlural: true})} to buy`,
                    value: <ShareValue value={costBreakdown.estimatedRightsToBuy} />,
                },
                {
                    description: `Estimated cost of ${shareLabel({instrument, isPlural: true})}`,
                    value: <DollarValue value={costBreakdown.estimatedCostToBuy} currency={instrument.currency} />,
                    isBold: true,
                    borderBottom: true,
                },
                // exercise breakdown
                {
                    description: (
                        <p className={confirmStyles.confirmationSubHeading}>
                            Then, we’ll apply to exercise them into{' '}
                            {shareLabel({instrument: parentInstrument, isPlural: true})}
                        </p>
                    ),
                },
                {
                    description: `Cost to exercise each ${shareLabel({instrument})}`,
                    value: (
                        <DollarValue
                            value={instrument.exercisePrice}
                            currency={instrument.currency}
                            decimalPlaces={3}
                        />
                    ),
                },
                {
                    description: `Estimated ${shareLabel({instrument, isPlural: true})} to exercise`,
                    value: <ShareValue value={costBreakdown.estimatedRightsToBuy} />,
                },
                {
                    description: `Estimated ${parentInstrument.name} ${shareLabel({
                        instrument: parentInstrument,
                        isPlural: true,
                    })} to apply for`,
                    value: <ShareValue value={costBreakdown.estimatedRightsToBuy} />,
                },
                {
                    description: (
                        <span>
                            Estimated cost to exercise
                            <BuyAndAutoExerciseCost instrument={instrument} />
                        </span>
                    ),
                    value: <DollarValue value={costBreakdown.estimatedCostToExercise} currency={instrument.currency} />,
                    isBold: true,
                    borderBottom: true,
                },
                // fees
                {
                    description: (
                        <span>
                            Transaction fee
                            {isOnASX(instrument) && jurisdiction === 'au' && ' incl. GST '}
                            <BuyAndSellFeesRights asIcon />{' '}
                        </span>
                    ),
                    value:
                        expectedFee && new Decimal(expectedFee).equals(0) ? (
                            <FeeValue value={expectedFee} currency={instrument.currency} />
                        ) : expectedFee ? (
                            <div className={styles.feeDescription}>
                                1.9% of the cost to buy {shareLabel({instrument, isPlural: true})}, capped at{' '}
                                <DollarValue value={feeCap} currency={feeCurrency} decimalPlaces={0} />
                            </div>
                        ) : (
                            '-'
                        ),
                    isBold: true,
                    explainerText: coverageType && <CoverageText feeCoverage={coverageType} />,
                },
            ]}
            total={{
                description: `Total order amount`,
                value: <DollarValue value={totalCost} currency={instrument.currency} />,
            }}
            exchange={
                paymentBreakdown ? (
                    <ConfirmExchange
                        displayCurrency={instrument.currency}
                        paymentBreakdown={paymentBreakdown}
                        expectedFee={expectedFee}
                    />
                ) : undefined
            }
        />
    )
}

export default AutoExerciseOrderConfirmation
