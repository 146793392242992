import {Model} from '~/api/retail/types'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {ActionsType} from './actions'
import {State, LinkedBankAccount} from './types'

const initialState: State = {
    bankLinkingLoadingState: 'uninitialised',
    bankLinkingPending: undefined,
    roundupsMonitoringAccounts: [],
    roundupsDebitAccount: undefined,
    isBankLinked: false,
    isRoundupsEnabled: false,
    roundupsPaymentInProgress: undefined,
    expiryDate: undefined,
    basiqAccountsLoadingState: 'uninitialised',
    basiqAccountsResponse: undefined,
    accumulatedDollars: '0',
    depositTarget: '0',
    isBankLinkingRejected: false,
    blinkPay: {},
}

function mapApiAccount(account: Model.BankLinkingAccount): LinkedBankAccount {
    return {
        basiqAccountId: account.basiq_account_id,
        bankLogo: account.bank_logo,
        bankName: account.bank_name,
        accountName: account.account_name,
        purpose: account.purpose as LinkedBankAccount['purpose'],
        externallyAvailable: account.externally_available,
        accountNumber: account.account_number,
    }
}

function reducer(state: State = initialState, action: ActionsType): State {
    switch (action.type) {
        case 'bankLinking.SetBankLinkingLoadingState':
            return {
                ...state,
                bankLinkingLoadingState: action.payload,
            }
        case 'bankLinking.SetBankLinkingStatus':
            const data = action.payload
            return {
                ...state,
                bankLinkingLoadingState: 'ready',
                isBankLinked: data.is_bank_linked,
                isRoundupsEnabled: data.roundups_enabled,
                roundupsPaymentInProgress: data.roundups_payment_in_progress,
                optionWholeDollar: data.option_whole_dollar === null ? undefined : data.option_whole_dollar,
                expiryDate: data.expiry_date,
                roundupsMonitoringAccounts: data.roundups_monitoring_accounts.map(mapApiAccount),
                roundupsDebitAccount: data.roundups_debit_account
                    ? mapApiAccount(data.roundups_debit_account)
                    : undefined,
                accumulatedDollars: data.roundups_accumulated_dollars,
                depositTarget: data.roundups_deposit_target,
                isBankLinkingRejected: data.state === 'REJECTED',
            }
        case 'bankLinking.SetBankLinkingPending':
            return {
                ...state,
                bankLinkingLoadingState: 'error',
                bankLinkingPending: action.payload,
            }
        case 'bankLinking.SetRoundupsEnabled':
            return {
                ...state,
                isRoundupsEnabled: action.payload,
            }
        case 'bankLinking.SetWholeDollar':
            return {
                ...state,
                optionWholeDollar: action.payload === null ? undefined : action.payload,
            }
        case 'bankLinking.SetDepositTarget':
            return {
                ...state,
                depositTarget: action.payload,
            }
        case 'bankLinking.SetBasiqAccountsLoadingState':
            return {
                ...state,
                basiqAccountsLoadingState: action.payload,
            }
        case 'bankLinking.SetBasiqAccounts':
            return {
                ...state,
                basiqAccountsLoadingState: 'ready',
                basiqAccountsResponse: action.payload,
            }
        case 'bankLinking.InvalidateBankLinkingState':
            return {
                ...initialState,
                bankLinkingLoadingState: 'ready',
            }
        case 'bankLinking.InvalidatePendingState':
            return {
                ...state,
                bankLinkingPending: undefined,
            }
        case 'bankLinking.SetBlinkPayOptions':
            return {
                ...state,
                blinkPay: {
                    options: {
                        ...action.payload,
                        // Temporary: don't show ASB until BlinkPay reckon transfers form browser will work
                        available_banks: action.payload.available_banks.filter(bank => bank.name !== 'ASB'),
                    },
                },
            }
        default:
            assertNever(action)
    }
    return state
}

export default reducer
