import React from 'react'
import {Outlet} from 'react-router'
import {Navigate, useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import LoadingScreen from '~/global/pages/loading-screen/LoadingScreen'
import NotFound from '~/global/pages/not-found/NotFound'
import {urlFor} from '~/global/routeGenerator'
import {stripProfile} from '~/global/utils/strip-profile/stripProfile'
import AccountRestrictionModals from '~/global/wrappers/global-wrapper-widgets/account-modals/AccountRestrictionModals'
import FlyoutMenu from '~/global/wrappers/global-wrapper-widgets/flyout-menu/FlyoutMenu'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import {ProfileContext, useProfiles} from '../state-hooks/retail/useProfile'
import styles from './ProfileWrapper.scss'

export const ProfileWrapper = ({profileSlug}: {profileSlug: string}) => {
    const actualReduxUser = useAppSelector(s => s.identity.user?.id)
    const current = useLocation()
    const profiles = useProfiles()
    const navigate = useNavigate()
    const profile = profiles.find(p => p.slug === profileSlug)
    const dispatch = useAppDispatch()
    const [params] = useSearchParams()
    const paramsString = new URLSearchParams(params).toString()

    const expectedReduxUser = profile?.legacy_customer_id ?? profiles[0].legacy_customer_id
    const localStorageActingAsId = window.localStorage.getItem('actingAsId')

    React.useEffect(() => {
        // Handle native mobile apps' legacy account switcher - only Native Mobile uses this old switching method - if there's a mismatch in the provided profile
        // Note we do this here rather than in ProfileRedirect, as in particular some iOS versions redirect to the most recent 'portfolio section' url, which could include an incorrect profile and therefore skip that
        if (localStorageActingAsId && localStorageActingAsId !== profile?.legacy_customer_id) {
            const profileSlugForActingAsId = profiles.find(p => p.legacy_customer_id === localStorageActingAsId)?.slug
            const pathWithoutProfile = stripProfile(current.pathname)

            // Make sure we don't end up in a loop, clean up the localStorage actingAsId
            window.localStorage.removeItem('actingAsId')

            // Redirect to the correct profile (maintain whichever further path was provided so native 'leaf nodes' don't break) - this keeps an existing iOS bug where swapping from a portfolio page that exists for one profile but not the other will result in a 404, but there's no magical workaround here without breaking the leaf nodes, and new iOS version fix this bug by always navigating to root on profile switch
            if (profileSlugForActingAsId) {
                navigate(
                    `/profile/${profileSlugForActingAsId}${pathWithoutProfile}${
                        paramsString ? `?${paramsString}` : ''
                    }`,
                    {
                        replace: true,
                    },
                )
            }
        } else if (expectedReduxUser && expectedReduxUser !== actualReduxUser) {
            dispatch(identityActions.SwitchUser(expectedReduxUser))
        }
    }, [localStorageActingAsId, expectedReduxUser]) // we deliberately break rules of hooks here and omit `actualReduxUser` and `profile` so creating a kids account doesn't trigger account switching

    if (!profile) {
        // If the profile doesn't exist, redirect to the root - this could be a
        // fairly common occurance on shared computers because of the 'next'
        // param, so just going to root is better than 404ing
        return <Navigate to={urlFor('')} replace={true} />
    }

    if (expectedReduxUser && expectedReduxUser !== actualReduxUser) {
        // To prevent race conditions on nested pages (trying to make calls
        // against the wrong user), if our useEffect above hasn't resolved the
        // user switch yet, we'll just show a loading spinner
        return <LoadingScreen />
    }

    return (
        <>
            <ProfileContext.Provider value={profile}>
                <AccountRestrictionModals />
                <FlyoutMenu className={styles.nav} />
                <div className={styles.content}>
                    <Outlet />
                </div>
            </ProfileContext.Provider>
        </>
    )
}

export const ProfileRedirect = () => {
    const profiles = useProfiles()
    const current = useLocation()
    const [params] = useSearchParams()
    const paramsString = new URLSearchParams(params).toString()

    const defaultProfile = profiles[0]

    if (!current.pathname.startsWith('/profile/') && defaultProfile) {
        return (
            <Navigate
                to={`/profile/${defaultProfile.slug}${current.pathname}${paramsString ? `?${paramsString}` : ''}`}
                replace={true}
            />
        )
    }

    return <NotFound />
}
