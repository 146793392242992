import {Thumbnail} from '@design-system/thumbnail'
import cn from 'classnames'
import React from 'react'
import {Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useAppSelector} from '~/store/hooks'
import type {Instrument} from '~/store/instrument/types'
import styles from './ShareSchemeDetailHeader.scss'

interface Props {
    instrument: Instrument
    detail: Response.ESSShareSchemeDetail
}

const ShareSchemeDetailHeader: React.FunctionComponent<Props> = ({instrument, detail}) => {
    const background = `linear-gradient(106.48deg, ${detail.color_accent_one} 23.94%, ${detail.color_accent_two} 99.96%)`
    const resourcePath = useAppSelector(s => s.instrument.metadata.resourcePath)
    return (
        <>
            <div className={styles.headerWrapper} style={{background}}>
                <Toolbar dataTestId="toolbar--share-scheme-detail-header" leftButton="back" isConstantWhite />
                <div className={styles.headerLogo}>
                    <Thumbnail
                        dataTestId="card--ess-shares-on-their-way-logo"
                        symbol={instrument.symbol}
                        path={instrument.logos.thumb ? `${resourcePath}${instrument.logos.thumb}` : undefined}
                        width="86px"
                        height="86px"
                        noBorder
                    />
                </div>
                <h1 className={cn(spacing.spaceAbove16, styles.headerText)}>{detail.name}</h1>
            </div>
        </>
    )
}

export default ShareSchemeDetailHeader
