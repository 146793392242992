import cn from 'classnames'
import React from 'react'
import {Response} from '~/api/retail/types'
import {accessibility, spacing} from '~/global/scss/helpers'
import {dateFormatMonthShortDayWithYear} from '~/global/utils/format-date/formatDate'
import {getTransferOrderGroupExchange} from '~/global/utils/share-transfers/shareTransfers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {useNavigate} from '~/migrate-react-router'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {ResponseGroupTransfer} from '~/store/transfer/types'

interface StateLabelProps {
    transferOrderGroup: ResponseGroupTransfer
    transferOrders: Response.Transfers['transfer_orders']
}

export const TransferCompleteStateLabel = ({transferOrderGroup, transferOrders}: StateLabelProps) => {
    const state = transferOrderGroup.state

    if (!['complete', 'rejected', 'cancelled'].includes(state)) {
        return null
    }
    const rejected = state === 'rejected'
    const complete = state === 'complete'
    const cancelled = state === 'cancelled'
    // If the status is complete, but not all transfer orders are the same end result
    const partiallyComplete =
        complete &&
        transferOrders &&
        !transferOrders.every(transferOrder => transferOrder.state === transferOrders[0].state)

    let terminalStatusAccent = ''
    if (rejected) {
        terminalStatusAccent = commonStyles.failed
    } else if (partiallyComplete) {
        terminalStatusAccent = commonStyles.partialSuccess
    } else if (complete) {
        terminalStatusAccent = commonStyles.success
    }

    return (
        <div className={cn(commonStyles.statusLabel, terminalStatusAccent)}>
            {complete && !partiallyComplete && 'Completed'}
            {partiallyComplete && 'Partially completed'}
            {rejected && 'Rejected'}
            {cancelled && 'Cancelled'}
        </div>
    )
}

const CompletedTransfer: React.FunctionComponent<Props> = ({transferOrderGroup, transferOrders}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const viewOrderDetailsLink =
        transferOrderGroup.transfer_entire_portfolio && transferOrderGroup.state === 'cancelled'
            ? 'invest/portfolio-transfer-shares/view-transfer/:transferOrderGroupId'
            : 'invest/portfolio-transfer-shares/history/:transferOrderGroupId'

    return (
        <button
            className={cn(accessibility.button, commonStyles.cardContainer, spacing.spaceAbove16)}
            key={transferOrderGroup.group_id}
            type="button"
            onClick={() => {
                navigate(
                    profileUrl(viewOrderDetailsLink, {
                        transferOrderGroupId: transferOrderGroup.group_id,
                    }),
                )
            }}
            data-testid="a--view-complete-transfer-group"
        >
            <div className={cn(commonStyles.textAndStatusLabel, spacing.spaceBelow4)}>
                <h3>Transfer {transferOrderGroup.direction}</h3>
                <TransferCompleteStateLabel transferOrderGroup={transferOrderGroup} transferOrders={transferOrders} />
            </div>

            <div className={commonStyles.pendingCardContent}>
                <p className={spacing.spaceBelow4}>
                    {getTransferOrderGroupExchange(transferOrderGroup)} <Delimiter /> Requested{' '}
                    {transferOrderGroup.created.toFormat(dateFormatMonthShortDayWithYear)}
                </p>
                <p className={commonStyles.pendingCardLink}>View</p>
            </div>
        </button>
    )
}

interface Props {
    transferOrderGroup: ResponseGroupTransfer
    transferOrders: Response.Transfers['transfer_orders']
}

export default CompletedTransfer
