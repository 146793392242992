import {ModalLink} from '@design-system/modal/ModalLink'
import cn from 'classnames'
import Decimal from 'decimal.js'
import React from 'react'
import {useRetailGet} from '~/api/query/retail'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import Concertina from '~/global/widgets/concertina/Concertina'
import recordStyles from '~/global/widgets/instrument-activity/Record.scss'
import {RecordRow} from '~/global/widgets/instrument-activity/common'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useRakaiaReadPortfolio} from '~/sections/kiwisaver/data/rakaia'
import {
    useActiveOrOffboardingKSCustomer,
    useKSAccountActivityBreakdown,
    useKSAccountActivitySummary,
} from '~/sections/kiwisaver/state'
import KSFundFees from '~/sections/kiwisaver/widgets/fees/KSFundFees'
import {PageTitle} from '~/sections/kiwisaver/widgets/page-title/PageTitle'
import styles from './AccountActivityBreakdown.scss'

export const AccountActivityBreakdown: React.FunctionComponent = () => {
    const accountActivityBreakdown = useKSAccountActivityBreakdown()
    const accountActivitySummary = useKSAccountActivitySummary()
    const customer = useActiveOrOffboardingKSCustomer()
    const {data: cashHoldings} = useRetailGet({path: 'kiwisaver/cash-holdings'})

    const returns = useRakaiaReadPortfolio(customer.portfolio_id ?? '')
    const totalClosingBalance = new Decimal(returns.portfolio_value || '0').add(cashHoldings.nzd_holdings)

    const totalBalanceChanges = totalClosingBalance.toNumber() - Number(accountActivityBreakdown.initial_transfer_total)

    const balanceSuffixText = Number(totalBalanceChanges) > 0 ? 'increased' : 'decreased'

    React.useEffect(() => {
        rudderTrack('kiwisaver_account', 'account_detailed_breakdown_clicked', {timeframe: 'all_time'})
    }, [])

    return (
        <>
            <>
                <Toolbar leftButton="back" dataTestId="toolbar--activity" />
                <Page>
                    <PageTitle className={spacing.spaceBelow16}>Activity breakdown</PageTitle>
                    <div className={cn(recordStyles.recordRowContainer, styles.summaryRowContainer)}>
                        <RecordRow
                            className={cn(styles.summaryRowPrimary, styles.summaryRowBlock, spacing.spaceBelow8)}
                            left={
                                <p>
                                    <strong data-testid="text--opening-balance">Opening balance</strong>
                                </p>
                            }
                            right={
                                <strong>
                                    <DollarValue value={accountActivityBreakdown.initial_transfer_total} />
                                </strong>
                            }
                        />

                        <RecordRow
                            className={cn(styles.summaryRowPrimary, styles.summaryRowBlock)}
                            left={
                                <p>
                                    <strong data-testid="text--contributions">Contributions</strong>
                                </p>
                            }
                            right={
                                <strong>
                                    <DollarValue value={accountActivityBreakdown.contributions_total} />
                                </strong>
                            }
                        />
                        <RecordRow
                            className={styles.summaryRowSecondary}
                            left={<p>Employee contributions</p>}
                            right={<DollarValue value={accountActivityBreakdown.employee_contributions_total} />}
                        />
                        <RecordRow
                            className={styles.summaryRowSecondary}
                            left={<p>Employer contributions</p>}
                            right={<DollarValue value={accountActivityBreakdown.employer_contributions_total} />}
                        />
                        <RecordRow
                            className={styles.summaryRowSecondary}
                            left={<p>Government contributions</p>}
                            right={<DollarValue value={accountActivityBreakdown.govt_contributions_total} />}
                        />
                        <RecordRow
                            className={cn(styles.summaryRowSecondary, {
                                [styles.summaryRowBlockLastLine]:
                                    accountActivityBreakdown.transfer_from_aussie_super_total === '0',
                            })}
                            left={<p>Voluntary contributions</p>}
                            right={<DollarValue value={accountActivityBreakdown.voluntary_contributions_total} />}
                        />
                        {accountActivityBreakdown.transfer_from_aussie_super_total !== '0' && (
                            <RecordRow
                                className={cn(styles.summaryRowSecondary, spacing.spaceBelow8)}
                                left={<p>Transfer from Australian Super</p>}
                                right={
                                    <DollarValue value={accountActivityBreakdown.transfer_from_aussie_super_total} />
                                }
                            />
                        )}

                        <RecordRow
                            className={cn(styles.summaryRowPrimary, styles.summaryRowBlock, spacing.spaceBelow8)}
                            left={
                                <p>
                                    <strong>Investment return</strong>
                                </p>
                            }
                            right={
                                <strong>
                                    <DollarValue value={returns.total_return ? returns.total_return : 0.0} />
                                </strong>
                            }
                        />

                        <RecordRow
                            className={cn(styles.summaryRowPrimary, styles.summaryRowBlock, spacing.spaceBelow8)}
                            left={
                                <p>
                                    <strong>
                                        Estimated fees
                                        <ModalLink
                                            dataTestId="modal-link--ks-aa-breakdown-fees"
                                            asIcon
                                            helpIconSize={16}
                                            label="see the details"
                                            modalTitle="Sharesies KiwiSaver Scheme fees"
                                            primaryButton={{label: 'Got it'}}
                                            bottomBorder
                                        >
                                            <KSFundFees />
                                        </ModalLink>
                                    </strong>
                                </p>
                            }
                            right={<DollarValue value={-accountActivitySummary.fees} />}
                        />
                        <Concertina
                            isAnimated={false}
                            isOpen
                            title={<p className={cn(spacing.spaceBelow8, spacing.spaceAbove8)}>Expand fees</p>}
                            footer={<p className={cn(spacing.spaceBelow8, spacing.spaceAbove16)}>Collapse fees</p>}
                            body={
                                <>
                                    <RecordRow
                                        className={styles.summaryRowPrimary}
                                        left={
                                            <p>
                                                <strong data-testid="text--fund-charges">
                                                    Fund charges (estimated)
                                                </strong>
                                            </p>
                                        }
                                        right={
                                            <strong>
                                                <DollarValue value={-accountActivityBreakdown.base_fund_fees_total} />
                                            </strong>
                                        }
                                    />
                                    {accountActivityBreakdown.base_fund_fees_list?.map(fund => {
                                        if (fund && fund?.fund_type === 'BASE') {
                                            return (
                                                <RecordRow
                                                    key={fund.fund_name}
                                                    className={cn(
                                                        styles.summaryRowSecondary,
                                                        styles.summaryRowWiderContent,
                                                    )}
                                                    left={<p>{fund.fund_name}</p>}
                                                    right={<DollarValue value={-fund?.fees_total} />}
                                                />
                                            )
                                        }
                                    })}
                                    <RecordRow
                                        className={cn(styles.summaryRowPrimary, spacing.spaceAbove8)}
                                        left={
                                            <p>
                                                <strong data-testid="text--all-fees-total">Fees on your picks</strong>
                                            </p>
                                        }
                                        right={
                                            <strong>
                                                <DollarValue value={-accountActivityBreakdown.fees_total} />
                                            </strong>
                                        }
                                    />
                                    <RecordRow
                                        className={styles.summaryRowSecondary}
                                        left={<p data-testid="text--admin-fees-total">Admin fee</p>}
                                        right={<DollarValue value={-accountActivityBreakdown.admin_fees_total} />}
                                    />
                                    <RecordRow
                                        className={styles.summaryRowSecondary}
                                        left={<p data-testid="text--transaction-fees-total">Transaction fees</p>}
                                        right={<DollarValue value={-accountActivityBreakdown.transaction_fees_total} />}
                                    />
                                    <RecordRow
                                        className={cn(styles.summaryRowSecondary, styles.summaryRowWiderContent)}
                                        left={
                                            <p data-testid="text--ss-fund-fees-total">
                                                ETF management fees (estimated)
                                            </p>
                                        }
                                        right={
                                            <DollarValue
                                                value={-accountActivityBreakdown.self_select_fund_fees_total}
                                            />
                                        }
                                    />
                                    <RecordRow
                                        className={styles.summaryRowSecondary}
                                        left={<p>Currency exchange fees</p>}
                                        right={
                                            <DollarValue
                                                value={-accountActivityBreakdown.currency_exchange_fees_total}
                                            />
                                        }
                                    />
                                </>
                            }
                        />

                        <RecordRow
                            className={cn(styles.summaryRowPrimary, styles.summaryRowBlock, spacing.spaceBelow8)}
                            left={
                                <p>
                                    <strong data-testid="text--tax">Tax</strong>
                                </p>
                            }
                            right={
                                <strong>
                                    <DollarValue value={accountActivityBreakdown.tax_liability_total} />
                                </strong>
                            }
                        />
                        {accountActivityBreakdown.ird_refunds_total !== '0' && (
                            <RecordRow
                                className={cn(styles.summaryRowSecondary, styles.summaryRowBlock, spacing.spaceBelow8)}
                                left={
                                    <p>
                                        <strong>
                                            IRD refunds
                                            <ModalLink
                                                dataTestId="modal-link--ks-ird-refunds-link"
                                                asIcon
                                                helpIconSize={16}
                                                label="see the details"
                                                modalTitle="IRD refunds"
                                                primaryButton={{label: 'Close'}}
                                                bottomBorder
                                            >
                                                <p>
                                                    IRD claims refunds from your account when contributions have been
                                                    overpaid to you in error.
                                                </p>
                                            </ModalLink>
                                        </strong>
                                    </p>
                                }
                                right={
                                    <strong>
                                        <DollarValue value={accountActivityBreakdown.ird_refunds_total} />
                                    </strong>
                                }
                            />
                        )}
                        <RecordRow
                            className={cn(styles.summaryRowPrimary, styles.summaryRowBlock, spacing.spaceBelow8)}
                            left={
                                <p>
                                    <strong data-testid="text--withdrawals">Withdrawals</strong>
                                </p>
                            }
                            right={
                                <strong>
                                    <DollarValue value={accountActivityBreakdown.withdrawals_total} />
                                </strong>
                            }
                        />
                        {accountActivityBreakdown.first_home_withdrawals_total !== '0' && (
                            <RecordRow
                                className={cn(styles.summaryRowSecondary, spacing.spaceBelow8)}
                                left={<p data-testid="text--first-home-withdrawal">First home</p>}
                                right={<DollarValue value={accountActivityBreakdown.first_home_withdrawals_total} />}
                            />
                        )}
                        {accountActivityBreakdown.retirement_partial_withdrawals_total !== '0' && (
                            <RecordRow
                                className={cn(styles.summaryRowSecondary, spacing.spaceBelow8)}
                                left={<p data-testid="text--retriement-withdrawal">Retirement</p>}
                                right={
                                    <DollarValue
                                        value={accountActivityBreakdown.retirement_partial_withdrawals_total}
                                    />
                                }
                            />
                        )}
                        {accountActivityBreakdown.financial_hardship_withdrawals_total !== '0' && (
                            <RecordRow
                                className={cn(styles.summaryRowSecondary, spacing.spaceBelow8)}
                                left={<p data-testid="text--financial-hardship-withdrawals">Financial Hardship</p>}
                                right={
                                    <DollarValue
                                        value={accountActivityBreakdown.financial_hardship_withdrawals_total}
                                    />
                                }
                            />
                        )}
                        {accountActivityBreakdown.others_withdrawals_total !== '0' && (
                            <RecordRow
                                className={cn(styles.summaryRowSecondary, spacing.spaceBelow8)}
                                left={<p data-testid="text--others-withdrawals">Other withdrawals</p>}
                                right={<DollarValue value={accountActivityBreakdown.others_withdrawals_total} />}
                            />
                        )}

                        <RecordRow
                            className={cn(styles.summaryRowPrimary, styles.summaryRowBlock, spacing.spaceBelow8)}
                            left={
                                <p>
                                    <strong>Closing balance</strong>
                                </p>
                            }
                            right={
                                <strong>
                                    <DollarValue value={totalClosingBalance.toNumber()} />
                                </strong>
                            }
                        />

                        <RecordRow
                            className={cn(styles.summaryRowSecondary, styles.summaryRowBlock)}
                            left={<p data-testid="text--balance-increased">Your balance {balanceSuffixText} by</p>}
                            right={<DollarValue value={Math.abs(totalBalanceChanges)} />}
                        />
                    </div>
                </Page>
            </>
        </>
    )
}
