import React from 'react'
import {useRetailGet} from '~/api/query/retail'
import {Model} from '~/api/retail/types'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {useInstrument} from '~/global/utils/use-instruments/useInstruments'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import recordStyles from '~/global/widgets/instrument-activity/Record.scss'
import {RecordRow} from '~/global/widgets/instrument-activity/common'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {DollarValue, FeeValue, ExchangeRateValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import DonationLineView from '~/sections/OLD_wallet/widgets/donation-line/DonationLineView'
import PaymentRequestLineView from '~/sections/OLD_wallet/widgets/payment-request/PaymentRequestLineView'
import WithdrawalStatus from '~/sections/OLD_wallet/widgets/withdrawal-status/WithdrawalStatus'
import {useAppSelector} from '~/store/hooks'

const memoTransform = (memo?: string) => (memo ? ' from ' + memo.replace(/^\s*(?:tfr\s*)?from\s*/i, '') : '')

interface TransactionLineDetailsProps {
    transaction: Model.Transaction
}
const TransactionLineDetails: React.FunctionComponent<TransactionLineDetailsProps> = ({transaction}) => {
    const instrumentId = transaction.buy_order ? transaction.buy_order.fund_id : transaction.fund_id
    const instrument = useInstrument(instrumentId)

    const isLimitBuyRefund = transaction && transaction.reason === 'change for buy order'
    const hasTrades = transaction && transaction.buy_order && transaction.buy_order.trades.length > 0

    return (
        <>
            <div className={recordStyles.header}>
                <PageBack />
            </div>
            <Page withoutDefaultPadding>
                <div className={recordStyles.viewContainer}>
                    <div className={recordStyles.titleBlock}>
                        <h2>{transaction.description}</h2>
                    </div>
                    <div className={recordStyles.orderContent}>
                        {(isLimitBuyRefund || transaction.mutual_tax) && instrument && (
                            <>
                                <div className={recordStyles.orderTitleBlock}>
                                    <div className={recordStyles.orderThumb}>
                                        <InstrumentLogo instrument={instrument} noBorder />
                                    </div>
                                    <div>
                                        <h4 className={recordStyles.instrumentName}>{instrument.name}</h4>
                                        {tradingType(instrument) !== 'managed' && (
                                            <p className={recordStyles.instrumentSymbol}>
                                                <PronounceLetters text={instrument.symbol} /> <Delimiter />
                                                <PronounceLetters text={instrument.exchange} />
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        {isLimitBuyRefund && instrument && (
                            <div className={recordStyles.recordRowContainer}>
                                {hasTrades && (
                                    <RecordRow
                                        left="Contract note"
                                        right={transaction.buy_order?.trades[0].contract_note_number}
                                    />
                                )}
                                <RecordRow
                                    className={recordStyles.recordRowWithDate}
                                    left="Change returned"
                                    right={transaction.timestamp.toFormat(dateFormatWithYear)}
                                />
                            </div>
                        )}
                        <div className={recordStyles.recordRowContainer}>
                            {!isLimitBuyRefund && (
                                <RecordRow
                                    className={recordStyles.recordRowWithDate}
                                    left={transaction.fx_order ? 'Order placed' : 'Date'}
                                    right={transaction.timestamp.toFormat(dateFormatWithYear)}
                                />
                            )}
                            {['save deposit', 'save withdrawal'].includes(transaction.reason) && (
                                <RecordRow
                                    className={recordStyles.recordRowWithDate}
                                    left={transaction.reason === 'save withdrawal' ? 'From' : 'To'}
                                    right={transaction.save_account_name}
                                />
                            )}
                            {transaction.withdrawal_order && (
                                <RecordRow left="To account" right={transaction.withdrawal_order.bank_account} />
                            )}
                            {transaction.reason === 'customer deposit' && (
                                <RecordRow left="From" right={memoTransform(transaction.memo).replace('from ', '')} />
                            )}
                            {transaction.reason.includes('subscription') && transaction.memo && (
                                <RecordRow left="From" right={memoTransform(transaction.memo).replace('from ', '')} />
                            )}
                            {transaction.charge && transaction.charge.fee && (
                                <RecordRow
                                    left="Card processing fee"
                                    right={
                                        <DollarValue value={transaction.charge.fee} currency={transaction.currency} />
                                    }
                                />
                            )}

                            {transaction.fx_order && (
                                <>
                                    <RecordRow
                                        left="From"
                                        right={
                                            <DollarValue
                                                value={transaction.fx_order.source_amount}
                                                currency={transaction.fx_order.source_currency}
                                            />
                                        }
                                    />
                                    <RecordRow
                                        className={recordStyles.recordRowWithExchangeRate}
                                        left="Exchange rate"
                                        right={
                                            <ExchangeRateValue
                                                sourceCurrency={transaction.fx_order.source_currency}
                                                targetCurrency={transaction.fx_order.target_currency}
                                                exchangeRate={Number(transaction.fx_order.exchange_rate)}
                                            />
                                        }
                                    />
                                    <RecordRow
                                        left="Exchange fee"
                                        right={
                                            <FeeValue
                                                value={transaction.fx_order.source_fee}
                                                currency={transaction.fx_order.source_currency}
                                            />
                                        }
                                    />
                                    <RecordRow
                                        left="Amount to exchange"
                                        right={
                                            <DollarValue
                                                value={transaction.fx_order.net_source_amount}
                                                currency={transaction.fx_order.source_currency}
                                            />
                                        }
                                    />
                                </>
                            )}
                            {transaction.mutual_tax && instrument && (
                                <>
                                    <RecordRow
                                        left="Taxable income"
                                        right={
                                            <DollarValue
                                                value={transaction.mutual_tax.taxable_income}
                                                currency={transaction.currency}
                                            />
                                        }
                                    />
                                    <RecordRow left="PIR" right={`${transaction.mutual_tax.pir}%`} />

                                    <RecordRow
                                        left="Imputation credits"
                                        right={
                                            <DollarValue
                                                value={transaction.mutual_tax.imputation_credit}
                                                currency={transaction.currency}
                                            />
                                        }
                                    />
                                </>
                            )}
                        </div>

                        <p className={recordStyles.orderAmountLabel}>{transaction.fx_order ? 'To amount' : 'Amount'}</p>

                        <div className={recordStyles.orderFooter}>
                            {transaction.fx_order || isLimitBuyRefund ? (
                                <div>
                                    <p className={recordStyles.statusLabel} data-testid="orderStatusLabel">
                                        {isLimitBuyRefund ? 'Paid to Wallet' : 'Filled'}
                                    </p>
                                </div>
                            ) : (
                                <div />
                            )}
                            <div>
                                <p className={recordStyles.orderAmount}>
                                    <DollarValue value={transaction.amount} currency={transaction.currency} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        </>
    )
}
interface TransactionLineViewProps {
    transactionId: string
    lineNumber?: string // CONF-1813 Temporary mobile fix: should be made mandatory once mobile URLs are updated. More context in commit message
}

const TransactionLineView: React.FunctionComponent<TransactionLineViewProps> = ({transactionId, lineNumber}) => {
    const actingAsId = useAppSelector(s => s.identity.user!.id)
    const transactionIdAsNumber = parseInt(transactionId, 10)

    const query = useRetailGet({
        path: 'accounting/transaction-history-v2',
        payload: {
            acting_as_id: actingAsId,
            limit: 1,
            exact_transaction_id: transactionIdAsNumber,
            exact_line_number: lineNumber ? Number(lineNumber) : undefined,
        },
    })

    if (!query.data.transactions[0]) {
        return <WeSlippedUp />
    }

    const transaction =
        query.data.transactions[0].transaction_id === transactionIdAsNumber ? query.data.transactions[0] : null

    if (!transactionId || !transaction) {
        return <WeSlippedUp />
    }

    if (transaction.withdrawal_order) {
        return (
            <WithdrawalStatus
                transaction={transaction as Model.Transaction & Required<Pick<Model.Transaction, 'withdrawal_order'>>}
            />
        )
    }

    if (transaction.donation) {
        return <DonationLineView transaction={transaction} />
    }

    if (transaction.payment_request) {
        return <PaymentRequestLineView transaction={transaction} />
    }

    return <TransactionLineDetails transaction={transaction} />
}

export default TransactionLineView
