import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useIsFirstRender} from 'usehooks-ts'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import votingSlide5Dark from '~/global/assets/images/intro-slides/voting-slides/voting-slide-5-dark.svg'
import votingSlide5Light from '~/global/assets/images/intro-slides/voting-slides/voting-slide-5-light.svg'
import {LearnModuleSlideContent} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {IntroSlides} from '~/global/widgets/intro-slides/IntroSlides'
import {usePlanEditorUrl} from '~/sections/kiwisaver/sections/edit-investment-plan/hooks/usePlanEditorUrl'
import slide1Dark from '~/sections/kiwisaver/sections/edit-investment-plan/pages/self-select-slides/assets/images/slide-1-dark.svg'
import slide1 from '~/sections/kiwisaver/sections/edit-investment-plan/pages/self-select-slides/assets/images/slide-1.svg'
import slide3Dark from '~/sections/kiwisaver/sections/edit-investment-plan/pages/self-select-slides/assets/images/slide-3-dark.svg'
import slide3 from '~/sections/kiwisaver/sections/edit-investment-plan/pages/self-select-slides/assets/images/slide-3.svg'
import slide4 from '~/sections/kiwisaver/sections/edit-investment-plan/pages/self-select-slides/assets/images/slide-4.svg'
import investmentMixesDark from '~/sections/kiwisaver/sections/sign-up/assets/images/fund-types/2-investment-mixes-dark.svg'
import investmentMixes from '~/sections/kiwisaver/sections/sign-up/assets/images/fund-types/2-investment-mixes-light.svg'
import {useExistingKSCustomer} from '~/sections/kiwisaver/state'
import styles from './SelfSelectSlides.scss'

export const SelfSelectIntroContent: LearnModuleSlideContent[] = [
    {
        additionalClassName: cn(styles.selfSelectSlide, styles.moreText),
        image: slide1,
        darkImage: slide1Dark,
        imageAdditionalClassName: styles.selfSelectSlideImage,
        bodyRenderer: () => (
            <>
                <h2>A few tips for choosing picks!</h2>
                <p>
                    What’s right for you comes down to your goals, your situation and{' '}
                    <a
                        href="https://www.sharesies.nz/learn/what-is-risk-anyway"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        what risk you’re comfy with
                    </a>
                    .
                </p>
                <p>
                    Higher risk investments may achieve higher returns over time, but there’s a risk they won't perform.
                    Lower risk options might feel more comfortable, but may not achieve the returns you’re after. It’s
                    all about what’s right for you.
                </p>
            </>
        ),
    },
    {
        additionalClassName: styles.selfSelectSlide,
        image: investmentMixes,
        darkImage: investmentMixesDark,
        imageAdditionalClassName: styles.selfSelectSlideImage,
        bodyRenderer: () => (
            <>
                <h2>You can add dimension by diversifying your choices</h2>
                <p>
                    Choosing investments across different sectors, asset classes and geographies{' '}
                    <a
                        href="https://www.sharesies.nz/learn/the-different-dimensions-of-diversification"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        creates diversification
                    </a>{' '}
                    in your picks.
                </p>
            </>
        ),
    },
    {
        additionalClassName: styles.selfSelectSlide,
        image: slide3,
        darkImage: slide3Dark,
        imageAdditionalClassName: styles.selfSelectSlideImage,
        bodyRenderer: () => (
            <>
                <h2>Figure out your tolerance for ups and downs (volatility)</h2>
                <p>
                    Prices of individual investments like{' '}
                    <a
                        href="https://www.sharesies.nz/learn/how-to-choose-a-company-to-invest-in"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        companies
                    </a>{' '}
                    tend to be more volatile than{' '}
                    <a
                        href="https://www.sharesies.nz/learn/etf-exchange-traded-funds-explained"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        exchange traded funds
                    </a>
                    .
                </p>
            </>
        ),
    },
    {
        additionalClassName: styles.selfSelectSlide,
        image: slide4,
        imageAdditionalClassName: styles.selfSelectSlideImage,
        bodyRenderer: () => (
            <>
                <h2>Think about emotions, not just dollars and cents</h2>
                <p>
                    To avoid making investing decisions in the heat of a moment, it’s important to{' '}
                    <a
                        href="https://www.sharesies.nz/learn/five-emotional-biases-to-avoid-in-investing"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        understand your emotional biases
                    </a>
                    .
                </p>
            </>
        ),
    },
    {
        additionalClassName: styles.selfSelectSlide,
        image: votingSlide5Light,
        darkImage: votingSlide5Dark,
        imageAdditionalClassName: styles.selfSelectSlideImage,
        bodyRenderer: () => (
            <>
                <h2>Not sure? Ask a pro!</h2>
                <p>
                    You can update your investment plan at any time! If you’re not sure about your picks, why not{' '}
                    <a
                        href="https://sorted.org.nz/guides/planning-and-budgeting/getting-advice"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        get some financial advice
                    </a>{' '}
                    from an expert?
                </p>
            </>
        ),
    },
]

export const SelfSelectIntroSlides = () => {
    const navigate = useNavigate()
    const relativeUrlFor = usePlanEditorUrl()
    const isFirstRender = useIsFirstRender()
    const customer = useExistingKSCustomer()

    const customerSignupType = customer.customer_state === 'SIGNUP' ? 'signup' : 'edit'

    if (isFirstRender) {
        rudderTrack('kiwisaver_self_select', 'start_education_viewed', {
            type: customerSignupType,
        })
    }

    const onComplete = () => {
        rudderTrack('kiwisaver_self_select', 'end_education_completed', {
            type: customerSignupType,
        })
        navigate(relativeUrlFor('add-funds'))
    }

    const onClose = () => {
        rudderTrack('kiwisaver_self_select', 'education_dismissed', {
            type: customerSignupType,
        })
        navigate(-1)
    }

    return (
        <IntroSlides
            content={SelfSelectIntroContent}
            flag="ks_self_select_education_screens"
            onComplete={onComplete}
            onCompleteLabel="Let’s add my picks!"
            onBack={onClose}
        />
    )
}
