import {Button} from '@design-system/button'
import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {formatFullName} from '~/global/utils/format-full-name/formatFullName'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {PageButtonGroup} from '~/global/widgets/button-page-group/PageButtonGroup'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useSignUpInitialDetail} from '~/sections/moose/data/retail'
import {SignUpStepCommonProps} from '~/sections/moose/sections/sign-up/SignUp'
import signupStyles from '~/sections/moose/sections/sign-up/SignUp.scss'
import IncorrectDetailsModal from '~/sections/moose/sections/sign-up/widgets/incorrect-details-modal/IncorrectDetailsModal'

const ConfirmName = ({progressStep, regressStep, signupId}: SignUpStepCommonProps) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const signupInitialDetail = useSignUpInitialDetail(signupId)

    const fullName = formatFullName({
        firstName: signupInitialDetail.first_name,
        middleName: signupInitialDetail.middle_name || '',
        lastName: signupInitialDetail.last_name || '',
    })

    return (
        <>
            <Toolbar
                dataTestId="toolbar--fonterra-signup-confirm-name"
                leftButton="back"
                onLeftButtonClick={regressStep}
                title="First, confirm your full name"
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <div className={signupStyles.card}>
                    <p>
                        <strong>Full name</strong>
                    </p>
                    <p className={spacing.spaceAbove8}>{fullName}</p>
                </div>

                <AlertCard type="info" title="Other business owners" className={spacing.spaceAbove24}>
                    <p>
                        For now, only the <strong>Primary Trading Contact</strong> on the existing Trading Among Farmers
                        (TAF) platform will have access to manage Fonterra Co-op shares on Sharesies.
                    </p>
                    <p className={spacing.spaceAbove16}>Other business owners will be invited to sign up later.</p>
                </AlertCard>
                <PageButtonGroup>
                    <Button
                        label="Yes, that’s me!"
                        type="primary"
                        dataTestId="button--fonterra-confirm-name"
                        onClick={progressStep}
                    />
                    <Button
                        label="Something’s not right"
                        type="secondary"
                        dataTestId="button--fonterra-incorrect-name"
                        onClick={() => {
                            setIsModalOpen(true)
                            rudderTrack('fonterra_onboarding', 'somethings_not_right_clicked', {type: 'confirm_name'})
                        }}
                    />
                </PageButtonGroup>
                <IncorrectDetailsModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
            </Page>
        </>
    )
}

export default ConfirmName
