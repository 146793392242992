import cn from 'classnames'
import React from 'react'
import {fileUrl} from '~/api/retail'
import {spacing} from '~/global/scss/helpers'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {ListMenuGroup, ListMenuItem} from '~/global/widgets/list-menu/ListMenu'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from './PersonalisedAnnualStatements.scss'

const FIRST_STATEMENT_YEAR = 2024

export const PersonalisedAnnualStatements: React.FunctionComponent = () => {
    const profile = useProfile()

    const currentYear = new Date().getFullYear()
    const availableYears = []
    for (let year = FIRST_STATEMENT_YEAR; year <= currentYear; year++) {
        availableYears.unshift(year)
    }

    const handleClick = (year: number) => {
        const url = fileUrl('report/:owner_id/afsl-annual-statement.pdf', {year}, {owner_id: profile.owner_id})
        window.open(url, '_blank', 'noopener')
    }

    return (
        <>
            <Toolbar leftButton="back" dataTestId="toolbar--personalised-annual-statements" />
            <Page>
                <h1 className={cn(styles.mainHeading, spacing.spaceBelow12)}>Personalised annual statements</h1>
                <p className={cn(spacing.spaceBelow12)}>
                    A yearly summary of your Sharesies portfolio including your earnings, fees, taxes, and transaction
                    history.
                </p>

                <ListMenuGroup className={cn(spacing.spaceBelow16)}>
                    {availableYears.map(year => (
                        <ListMenuItem
                            label={`1 July ${year - 1} - 30 June ${year}`}
                            key={year}
                            onClick={() => handleClick(year)}
                        />
                    ))}
                </ListMenuGroup>
            </Page>
        </>
    )
}
