import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {useLegacyUserData} from '~/sections/wallet/state/retail'

/**
 * Using jurisdiction and owner to determine:
 * - NZ investor
 * - Not a Fonterra account
 *
 * We can't just check if its a 'person' vs 'organisation'
 * because a sole trader Fonterra farmer is also 'person'
 */
export const useNzStandardInvestor = () => {
    const owner = useProfileOwner()
    const {jurisdiction} = useLegacyUserData()

    return jurisdiction === 'nz' && !owner.is_fonterra_customer
}
