import {Button} from '@design-system/button'
import {InjectedFormikProps, withFormik} from 'formik'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {withOrdinalSuffix} from '~/global/utils/with-ordinal-suffix/withOrdinalSuffix'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import {ErrorBox, validate} from '~/global/widgets/form-controls'
import {Select} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'

const TransferAgeForm: React.FunctionComponent<InjectedFormikProps<FormProps, FormValues>> = ({
    isSubmitting,
    handleSubmit,
    isValid,
    status,
    transferAgePassed,
    transferAge,
    values,
}) => {
    return (
        <form onSubmit={handleSubmit}>
            <Select
                dataTestId="select--transfer-age"
                name="age"
                label="Transfer age"
                disabled={transferAgePassed}
                choices={[
                    {value: '18', label: '18 years old'},
                    {value: '19', label: '19 years old'},
                    {value: '20', label: '20 years old'},
                    {value: '21', label: '21 years old'},
                    {value: '22', label: '22 years old'},
                    {value: '23', label: '23 years old'},
                    {value: '24', label: '24 years old'},
                    {value: '25', label: '25 years old'},
                ]}
            />
            {!transferAgePassed && <ErrorBox message={status} />}
            {!transferAgePassed && (
                <Button
                    isSubmit
                    pageButton
                    label="Save"
                    disabled={!isValid || values.age === transferAge.toString()}
                    processing={isSubmitting}
                    additionalClassName={spacing.spaceBelow48}
                    dataTestId="button--save-transfer-age"
                />
            )}
        </form>
    )
}

const Form = withRouter(
    withFormik<FormProps & WithRouterProps & OwnProps, FormValues>({
        mapPropsToValues: ({transferAge}) => ({
            age: String(transferAge),
        }),
        mapPropsToErrors: () => ({
            // make the button disabled initially by setting at least one field to have an error
            age: undefined,
        }),
        handleSubmit: async (
            {age},
            {
                setSubmitting,
                setStatus,
                props: {
                    updateTransferAge,
                    router: {navigate},
                    profileUrl,
                },
            },
        ) => {
            try {
                const error = await updateTransferAge(Number(age))
                if (error) {
                    setStatus(error)
                    setSubmitting(false)
                    return
                }
                Toast('Transfer age updated')
                navigate(profileUrl('settings'))
            } catch (e) {
                setStatus(unknownErrorMessage)
                setSubmitting(false)
                throw e
            }
        },
        validate: validate.generate<FormValues>({
            age: [validate.required(), validate.choices<string>('18', '19', '20', '21', '22', '23', '24', '25')],
        }),
    })(TransferAgeForm),
)

export const TransferAge: React.FunctionComponent<StateProps & DispatchProps> = ({
    preferredName,
    transferAge,
    isDependent,
    transferAgePassed,
    ...props
}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const renderPreTransfer = () => {
        return (
            <div>
                <p className={spacing.spaceBelow16}>
                    At the transfer age, {preferredName} can take control of the account and manage the money and
                    investments themselves.
                </p>
                <p className={spacing.spaceBelow16}>{preferredName} will need to be at least 18 years old.</p>
            </div>
        )
    }

    const renderPostTransfer = () => {
        return (
            <div>
                <p className={spacing.spaceBelow16}>
                    You can’t change the transfer age anymore as {preferredName}’s {withOrdinalSuffix(transferAge)}{' '}
                    birthday has passed.
                </p>
                <p className={spacing.spaceBelow16}>
                    You can continue to manage the account until it’s transferred to {preferredName}. Once transferred,{' '}
                    {preferredName} will take control of the account and manage the money and investments.
                </p>
            </div>
        )
    }

    if (!isDependent) {
        navigate(profileUrl('settings'))
        return <div />
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--transfer-age" leftButton="back" title="Transfer age" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                {transferAgePassed ? renderPostTransfer() : renderPreTransfer()}
                <Form
                    preferredName={preferredName}
                    transferAge={transferAge}
                    isDependent={isDependent}
                    transferAgePassed={transferAgePassed}
                    profileUrl={profileUrl}
                    {...props}
                />
            </Page>
        </>
    )
}

type FormProps = StateProps & DispatchProps

interface FormValues {
    age: string
}

interface OwnProps {
    profileUrl: ReturnType<typeof useProfileUrl>
}

interface StateProps {
    isDependent: boolean
    preferredName: string
    transferAge: number
    transferAgePassed: boolean
}

interface DispatchProps {
    updateTransferAge: (age: number) => Promise<string | undefined>
}

const ConnectedAccountTransferAge = connect<StateProps, DispatchProps, OwnProps>(
    ({identity}) => ({
        isDependent: identity.user!.is_dependent,
        preferredName: identity.user!.preferred_name,
        transferAge: identity.user!.transfer_age || 0,
        transferAgePassed: identity.user!.transfer_age_passed,
    }),
    dispatch => ({
        updateTransferAge: age => dispatch(actions.UpdateTransferAge(age)),
    }),
)(TransferAge)

export default () => {
    const profileUrl = useProfileUrl()
    return <ConnectedAccountTransferAge profileUrl={profileUrl} />
}
