import {DateTime} from 'luxon'
import {Model, Response} from '~/api/retail/types'
import {ArrayElement} from '~/global/utils/type-utilities/typeUtilities'

export type ResponseRegistryDetail = ArrayElement<Response.Transfers['registry_details']>
export type ResponseTransferExchange = Model.TransferOrderIn['exchange']
export type ResponseUsTransferPlatform = Response.Transfers['grouped_transfers'][0]['us_transfer_broker']

export const UsExchanges = ['NYSE', 'NASDAQ', 'CBOE']

export interface ResponseGroupTransfer extends ArrayElement<Response.Transfers['grouped_transfers']> {
    // Field 'rejected' summarises whether any of the individual transfer objects had a 'rejected_at' timestamp
    rejected: boolean
}

export interface Record {
    shares?: string
    price?: string
    purchaseDate?: DateTime
    allShares?: boolean
}
export interface TransferOrderInstrument {
    index: number
    instrumentId: string
    instrumentSlug: string
    records: Record[]
    orderId?: string
}
export interface StagedTransferOrder {
    address?: Model.User['address']
    direction?: 'in' | 'out'
    exchange?: ResponseTransferExchange
    groupId?: string
    instruments: TransferOrderInstrument[]
    pid?: string
    reference: string
    referenceType?: ResponseGroupTransfer['reference_type'] | undefined
    registryDetailDocuments: ResponseGroupTransfer['registry_detail_documents']
    registryDetailId?: string
    rejected?: boolean
    searchTerm?: string
    transferOrderDocuments: ResponseGroupTransfer['transfer_order_documents']
    usTransferPlatform?: ResponseUsTransferPlatform
    transferEntirePortfolio?: boolean
}

export interface BaseTransferRegistry {
    referenceId: ResponseRegistryDetail['reference']
    referenceType: ResponseRegistryDetail['reference_type']
}

/**
 * AsxTransferRegistry
 *
 * @param {string} registryDetailId - could exist already depending on the transfer flow
 * @param {string} participantId - 'PID' ideally the customer will provide this, however Ops can find it for them
 * @param {string} hinBroker - is only collected if the customer doesn't know their PID, and is used by Ops to find the PID
 */
export interface AsxTransferRegistry extends BaseTransferRegistry {
    registryDetailId?: string
    participantId?: string
    hinBroker?: string
}

export interface NzxTransferRegistry extends BaseTransferRegistry {
    fasterIdNo?: string
    jointHolderOwnerIds?: string[]
}

export interface UsTransferRegistry extends BaseTransferRegistry {
    registryDetailId?: string
}

export interface State {
    readonly transfersLoadingState: 'loading' | 'ready' | 'error'
    readonly groupedTransfers: ResponseGroupTransfer[]
    readonly registryDetails: Response.Transfers['registry_details']
    readonly transferOrders: Response.Transfers['transfer_orders']
    readonly stagedTransferOrder?: StagedTransferOrder
    readonly transferInfo?: Response.TransferInfo
}

export type TransferOrderDocument = ArrayElement<ResponseGroupTransfer['transfer_order_documents']>
export type RegistryDetailDocument = ArrayElement<ResponseGroupTransfer['registry_detail_documents']>
export type TransferUpload = RegistryDetailDocument | TransferOrderDocument
export type TransferDirection = 'in' | 'out'
export type TransferOrderRegistryDetails = AsxTransferRegistry | NzxTransferRegistry | UsTransferRegistry

// Fake number given to investors who who want a new SRN set up for them
export type NewSrnRequired = 'C0000'
export const NEW_SRN_REQUIRED: NewSrnRequired = 'C0000'

// Fake number given to investors who who want a new DRS account set up for them
export type NewDrsRequired = 'DRS000'
export const NEW_DRS_REQUIRED: NewDrsRequired = 'DRS000'
