import {useQuery} from '@tanstack/react-query'
import {ListingResponseDto} from '~/api/distill'
import {distillApi} from '~/api/distill/apis'
import {DistillScope} from '~/api/query/distill'
import useDistillInstrumentInfo from '~/global/state-hooks/distill/useDistillInstrumentInfo'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {mockableTimeout} from '~/global/utils/mockable-timeout/mockableTimeout'

/**
 * Track a pending distill fetch (i.e. the time between the first call of the hook and the actual sending the HTTP
 * request with the grouped instrument ids).
 */
let currentFetch:
    | undefined
    | {
          instrumentIds: string[]
          response: Promise<ListingResponseDto[]>
      }

interface UseDistillInstrumentOptions {
    /**
     * If an instrument wasn't available on the API, should we throw an error or return undefined?
     * default is `throw`
     */
    onError: 'throw' | 'undefined'
}

type ListingResponseDtoOrUndefined<T extends string | undefined> = T extends undefined ? undefined : ListingResponseDto

/**
 * Get a single instrument from the distill api
 *
 * This endpoint automatically groups requests for multiple different instruments from multiple different places in
 * the component tree together in a single request. This means if you want to call it in a loop you don't need to
 * worry, it'll just do the right thing for you.
 *
 * If you pass in undefined (which is valid) it will return undefined. This means you can safely use it in places where
 * you're not sure if you'll have an instrumentId until runtime
 */
export const useDistillInstrument = <T extends string | undefined>(
    instrumentId: T,
    opt?: Partial<UseDistillInstrumentOptions>,
): ListingResponseDtoOrUndefined<T> => {
    const options: UseDistillInstrumentOptions = {
        onError: 'throw',
        ...opt,
    }

    // Note unless you're really know what you're doing, you probably don't want to copy or use this code anywhere.
    // It's quite a strange pattern intended to make the consumers of this hook have an easier time.
    const {data} = useQuery({
        // TODO - might want to unify this key sensibly if we move this hook to be in a more global place
        queryKey: ['distill-wallet-instrument', instrumentId],
        suspense: true,
        // If there is no instrumentId supplied then we don't actually want to fetch anything
        enabled: !!instrumentId,
        queryFn: async () => {
            let localFetch = currentFetch

            if (!instrumentId) {
                return
            }
            if (currentFetch && localFetch) {
                // Some other invokation of this hook has already created a fetch, we just pop our instrumentId on
                // the list then wait for that to finish
                if (currentFetch.instrumentIds.indexOf(instrumentId) === -1) {
                    currentFetch.instrumentIds.push(instrumentId)
                }
            } else {
                // We start a new fetch group, putting our own instrumentId on the list and setting a timer to start
                // the API request
                currentFetch = localFetch = {
                    instrumentIds: [instrumentId],
                    response: new Promise<ListingResponseDto[]>((resolve, reject) => {
                        mockableTimeout(async () => {
                            // We now indicate there's no group in flight (because we're about to start the request, so
                            // other invokations can't "join in" any more)
                            currentFetch = undefined
                            distillApi.instrumentsApi
                                .apiV1InstrumentsPost({
                                    searchRequestDto: {
                                        query: '',
                                        instruments:
                                            localFetch && localFetch.instrumentIds // in test environments only this can be undefined
                                                ? localFetch.instrumentIds // use the instrumentIds which might get mutated by later invokations before the timeout elapses
                                                : [instrumentId],
                                        // This is all the trading statuses (so this should always find an instrument)
                                        tradingStatuses: [
                                            'active',
                                            'halt',
                                            'closeonly',
                                            'notrade',
                                            'inactive',
                                            'unknown',
                                        ],
                                    },
                                })
                                .then(response => {
                                    resolve(response.instruments)
                                })
                                .catch(reject)
                        }, 25)
                        // 25ms is how long we wait after the first instrumentId comes in before we start the request
                        // It's a fair balance between not holding up requests more than we'd like, and also allowing
                        // the browser enough time to group a few together.
                    }),
                }
            }

            // If we've reached 50 instruments we'll just move any subsequent ones into another request. This probably
            // isn't actually an issue given we're using a POST request, but it does even out the load on Distill
            if (currentFetch.instrumentIds.length >= 50) {
                currentFetch = undefined
            }

            const response = await localFetch.response

            const instrument = response.find(instrument => instrument.id === instrumentId)
            if (instrument) {
                return instrument
            }

            // We were unable to find the instrument here so honour the onError behaviour
            switch (options.onError) {
                case 'throw':
                    throw new Error(`Failed to load instrument ${instrumentId}`)
                case 'undefined':
                    return null
                default:
                    assertNever(options.onError)
            }
        },
    })
    if (!data) {
        // This case happens when instrumentId === undefined _or_ the instrument wasn't found and options.onError === "undefined"
        return undefined as ListingResponseDtoOrUndefined<T>
    }
    return data as ListingResponseDtoOrUndefined<T>
}

export const useDistillInstrumentWithCurrency = (instrumentId: string): ListingResponseDto & {currency: Currency} => {
    const instrument = useDistillInstrument(instrumentId)
    const {exchanges} = useDistillInstrumentInfo(DistillScope.INVEST)

    const exchangeInfo = exchanges.find(exchange => instrument.exchange === exchange.name)

    // The only non-exchange instruments are New Zealand managed funds which is why we default to 'nzd' here.
    const currency = exchangeInfo?.currency ?? 'nzd'

    return {
        ...instrument,
        currency: currency as Currency,
    }
}
