import {Button} from '@design-system/button'
import {AutoInvest as AutoInvestIcon} from '@design-system/icon'
import {useColourMode} from '@design-system/use-colour-mode/useColourMode'
import {useFeature} from '@growthbook/growthbook-react'
import cn from 'classnames'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {accessibility, spacing, typographyOverrides} from '~/global/scss/helpers'
import {convertToKebabCase} from '~/global/utils/convert-to-kebab-case/convertToKebabCase'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {Link, Navigate, useNavigate} from '~/migrate-react-router'
import {MAX_DIY_ORDERS} from '~/sections/invest/sections/auto-invest/constants/constants'
import {useAutoinvestOrders} from '~/sections/invest/sections/auto-invest/hooks/useAutoinvestOrders'
import {usePremadeOrders} from '~/sections/invest/sections/auto-invest/hooks/usePremadeOrders'
import {getImageForAutoInvestOrder} from '~/sections/invest/sections/auto-invest/utils/map-auto-invest-images/mapAutoInvestImages'
import LimitReachedModal from '~/sections/invest/sections/auto-invest/widgets/limit-reached-warning/LimitReachedModal'
import actions from '~/store/autoinvest/actions'
import {EnhancedAutoinvestOrder, PremadeOrderEnhanced} from '~/store/autoinvest/selectors'
import {useAppSelector, useAppDispatch} from '~/store/hooks'
import AutoInvestTile from './widgets/auto-invest-tile/AutoInvestTile'
import autoInvestCommonStyles from './Common.scss'
import styles from './Landing.scss'

interface RenderCurrentProps {
    currentOrders: EnhancedAutoinvestOrder[]
}

const RenderCurrent: React.FunctionComponent<RenderCurrentProps> = ({currentOrders}) => {
    const oneOffAutoinvestEnabled = useFeature('one-off-autoinvest').on
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)
    const unavailableInstrumentNames = useAppSelector(s => s.instrument.diyAIUnavailableInstrumentNames)

    const headingText = currentOrders.length > 1 ? 'Your auto-invest orders' : 'You’re investing in'

    return (
        <div className={cn(styles.currentOrderOnLanding, spacing.spaceAbove24)}>
            <h2 className={typographyOverrides['as-h2']}>{headingText}</h2>
            {oneOffAutoinvestEnabled && (
                <p className={spacing.spaceAbove8}>
                    Choose an auto-invest order you’d like to manage or place a one-off buy for.
                </p>
            )}
            <>
                {currentOrders.map(order => (
                    <div className={spacing.spaceAbove12} key={order.id}>
                        <AutoInvestTile
                            key={order.id}
                            dataTestId="current-auto-invest--landing"
                            order={order}
                            homeCurrency={homeCurrency}
                            unavailableInstrumentNames={unavailableInstrumentNames}
                        />
                    </div>
                ))}
            </>
        </div>
    )
}

const AutoInvest: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const colourMode = useColourMode()

    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const hasSeenSplash = useAppSelector(s => s.identity.user?.has_seen.autoinvest_splash)
    const [premadeOrders, premadeLoaded] = usePremadeOrders()
    const isDependent = useAppSelector(s => s.identity.user?.is_dependent)
    const [currentOrders, currentOrdersLoaded] = useAutoinvestOrders()
    const [showLimitReachedModal, setShowLimitReachedModal] = React.useState<boolean>(false)
    const shouldNavigateToSplash =
        currentOrdersLoaded && currentOrders.length === 0 && (!hasSeenSplash || jurisdiction === 'au')

    if (shouldNavigateToSplash) {
        return <Navigate to={profileUrl('auto-invest/splash')} replace={true} />
    }

    const handlePremadeCreateCardClick = (order: PremadeOrderEnhanced) => {
        Analytics.event({
            category: 'AutoInvest',
            action: 'View Auto Invest Order',
            label: order.name,
        })
        navigate(
            profileUrl('auto-invest/:premadeSlug', {
                premadeSlug: order.slug,
            }),
        )
    }

    const handleDiyCreateCardClick = () => {
        dispatch(actions.StageNewDIY())

        rudderTrack('autoinvest', 'autoinvest_type_selected', {
            autoinvest_type: 'diy',
        })

        rudderTrack('browse', 'search_page_hit', {
            navigated_from: 'autoinvest-diy-create-card',
        })

        navigate(profileUrl('auto-invest/diy/search'))
    }

    const handleAddOrder = () => {
        if (currentOrders.length >= MAX_DIY_ORDERS[jurisdiction].num) {
            setShowLimitReachedModal(true)
            return
        }
        if (jurisdiction === 'au') {
            // all AU orders are DIY, jump straight in
            handleDiyCreateCardClick()
        } else {
            navigate(profileUrl('auto-invest/add-order'))
        }
    }

    const loaded = currentOrdersLoaded && premadeLoaded

    return (
        <>
            <Toolbar
                title="Auto-invest"
                dataTestId="toolbar--autoinvest-landing"
                leftButton="back"
                onLeftButtonClick={() => navigate(profileUrl('invest/manage'))}
            />
            <Page overrideDefaultTopPadding="withToolbarTitle" className={styles.autoInvestLanding}>
                {!loaded && <Loading />}
                {loaded && (
                    <>
                        <p className={spacing.spaceBelow6}>
                            Put your investing on repeat. Just choose your order, then set how much and how often you’d
                            like to invest.
                        </p>
                    </>
                )}
                {loaded &&
                    (currentOrders.length === 0 ? (
                        <>
                            <p className={spacing.spaceBelow12}>
                                {
                                    {
                                        nz: (
                                            <Link to={profileUrl('explore/learn/:slug', {slug: 'auto-invest'})}>
                                                Learn about order types
                                            </Link>
                                        ),
                                        au: (
                                            <Link to={profileUrl('auto-invest/intro')} className={spacing.spaceBelow24}>
                                                See how it works
                                            </Link>
                                        ),
                                    }[jurisdiction]
                                }
                            </p>
                            <h2 className={spacing.spaceBelow12}>DIY order</h2>
                            <p className={spacing.spaceBelow6}>
                                Create up to {MAX_DIY_ORDERS[jurisdiction].word} separate DIY orders from a range of US,
                                Aussie and NZ investments.
                            </p>
                            {
                                {
                                    nz: (
                                        <p className={spacing.spaceBelow12}>
                                            <Link to={profileUrl('auto-invest/intro')} className={spacing.spaceBelow24}>
                                                See how it works
                                            </Link>
                                        </p>
                                    ),
                                    au: <></>,
                                }[jurisdiction]
                            }
                            <button
                                className={cn(autoInvestCommonStyles.orderCard, accessibility.button)}
                                data-testid="diy-order"
                                onClick={handleDiyCreateCardClick}
                                type="button"
                            >
                                <img
                                    className={autoInvestCommonStyles.orderImg}
                                    src={getImageForAutoInvestOrder('diy', colourMode).thumbnailImage}
                                    alt=""
                                />
                                <div className={autoInvestCommonStyles.orderHeaders}>
                                    <h3>
                                        <AutoInvestIcon />
                                        <span>DIY order</span>
                                    </h3>
                                    <h4>Choose your own</h4>
                                </div>
                            </button>

                            <h2 className={cn(spacing.spaceAbove32, spacing.spaceBelow12)}>Pre-made orders</h2>
                            <p className={spacing.spaceBelow12}>Choose a pre-made order to take away the guesswork.</p>

                            {premadeOrders.map(order => {
                                if ((order.for_kids && isDependent) || !order.for_kids) {
                                    return (
                                        <button
                                            type="button"
                                            key={order.id}
                                            className={cn(autoInvestCommonStyles.orderCard, accessibility.button)}
                                            data-testid={`button--${convertToKebabCase(order.name.toLowerCase())}`}
                                            onClick={() => handlePremadeCreateCardClick(order)}
                                        >
                                            <img
                                                className={autoInvestCommonStyles.orderImg}
                                                src={getImageForAutoInvestOrder(order.name, colourMode).thumbnailImage}
                                                alt=""
                                            />
                                            <div className={autoInvestCommonStyles.orderHeaders}>
                                                <h3>
                                                    <AutoInvestIcon />
                                                    <span>{order.name} order</span>
                                                </h3>
                                                <h4>{order.allocations.length} investments</h4>
                                            </div>
                                        </button>
                                    )
                                }
                            })}
                            <p className={cn(spacing.spaceAbove24, autoInvestCommonStyles.newDisclaimer)}>
                                We don’t give financial advice. You need to consider if a pre-made or DIY order is right
                                for you. If you’re unsure, you should speak to a licensed financial advisor.
                            </p>
                        </>
                    ) : (
                        <RenderCurrent currentOrders={currentOrders} />
                    ))}
            </Page>

            {currentOrders.length > 0 && (
                <ActionBar>
                    <Button
                        dataTestId="button--add-new-autoinvest"
                        label="Set up another auto-invest"
                        onClick={e => {
                            e.preventDefault()
                            handleAddOrder()
                        }}
                    />
                </ActionBar>
            )}

            <LimitReachedModal
                isOpen={showLimitReachedModal}
                setIsOpen={setShowLimitReachedModal}
                jurisdiction={jurisdiction}
            />
        </>
    )
}

export default AutoInvest
