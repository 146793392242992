import cn from 'classnames'
import React from 'react'
import {DistillScope} from '~/api/query/distill'
import {useRetailGet} from '~/api/query/retail'
import {accessibility} from '~/global/scss/helpers'
import {useDistillInstrumentWithCurrency} from '~/global/state-hooks/distill/useDistillInstrument'
import {PriceNotificationModal} from '~/sections/invest/widgets/price-notification-modal/PriceNotificationModal'
import {getPriceNotificationsQueryArgs} from '~/sections/user/sections/settings/sections/notifications/pages/price-notifications/utils/get-price-notifications-query-args/getPriceNotificationsQueryArgs'
import styles from './Header.scss'

interface PriceNotificationProps {
    urlSlug: string
    portfolioId: string
    showExtendedHours: boolean
    distillScope: DistillScope
}

const PriceNotification: React.FunctionComponent<PriceNotificationProps> = ({
    urlSlug,
    portfolioId,
    showExtendedHours,
    distillScope,
}) => {
    const instrument = useDistillInstrumentWithCurrency(urlSlug, distillScope)
    const priceNotifications = useRetailGet(getPriceNotificationsQueryArgs(portfolioId)).data.price_notifications
    const priceNotification = priceNotifications[instrument.id]
    const hasPriceNotification = !!priceNotification

    const [showPriceNotificationModal, setShowPriceNotificationModal] = React.useState(false)
    return (
        <>
            <p className={styles.priceNotificationContent}>
                {hasPriceNotification && (
                    <span>
                        Price notification set for ${parseFloat(priceNotification.price_threshold).toFixed(3)}
                        &nbsp;
                    </span>
                )}

                <button
                    type="button"
                    className={cn(accessibility.button, styles.priceNotificationButton)}
                    aria-label={`Set a price notification for ${instrument.name}`}
                    data-test-id="button--set-price-alert"
                    onClick={() => setShowPriceNotificationModal(true)}
                >
                    {hasPriceNotification ? 'Edit' : 'Set a price notification'}
                </button>
            </p>

            <PriceNotificationModal
                isOpen={showPriceNotificationModal}
                onClose={() => setShowPriceNotificationModal(false)}
                instrumentId={instrument.id}
                instrumentName={instrument.name}
                instrumentValue={instrument.marketPrice}
                instrumentCurrency={instrument.currency}
                instrumentSymbol={instrument.symbol}
                hasPriceNotification={hasPriceNotification}
                initialPriceNotificationAmount={priceNotification?.price_threshold}
                pageSource="instrument_page"
                portfolioId={portfolioId}
                extendedHoursInEffect={showExtendedHours}
                seeAllLinkFonterra={distillScope === DistillScope.FONTERRA}
            />
        </>
    )
}

export default PriceNotification
