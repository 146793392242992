import {Card} from '@braze/web-sdk'
import React from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {useNavigate} from 'react-router'
import {logContentCardImpressions} from '~/api/braze/braze'
import {EventDataTaxonomy} from '~/api/rudderstack/event-taxonomy/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {useHasInsureAccess} from '~/global/state-hooks/retail/useHasInsureAccess'
import {useProfile, useProfiles} from '~/global/state-hooks/retail/useProfile'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import kiwisaverSmall from '~/sections/kiwisaver/assets/images/kiwisaver-small.png'
import {useKSCustomerOrNull} from '~/sections/kiwisaver/state'
import investSmall from '~/sections/landing/assets/images/invest-small.png'
import kidsAccountImg from '~/sections/landing/assets/images/kids-small.png'
import {useHasInvestHistory} from '~/sections/landing/state/useHasInvestHistory'
import {GetStartedInsureWrapper} from '~/sections/landing/widgets/get-started/GetStartedInsure'
import {LoadingSkeleton} from '~/sections/landing/widgets/loading-skeleton/LoadingSkeleton'
import {PortfolioSlice} from '~/sections/landing/widgets/portfolio-slice/PortfolioSlice'
import saveSmall from '~/sections/save/assets/images/save-small.png'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import saveActions from '~/store/save/actions'
import styles from './GetStarted.scss'

type TrackNewProductFeatureType = EventDataTaxonomy['navigation']['new_product_button_clicked']['feature']

const track = (feature: TrackNewProductFeatureType) => {
    rudderTrack('navigation', 'new_product_button_clicked', {
        feature,
        type: 'get_started',
    })
}

interface Propensity {
    key: string
    score: number
}

// These are the keys we have in the propensities Braze card
const tileOrderingKeys = [
    'whats_next_insure_order',
    'whats_next_invest_order',
    'whats_next_kids_order',
    'whats_next_kiwisaver_order',
    'whats_next_save_order',
]

// If we don't have a propensities card or the customer is in the Growthbook control group, we display tiles in this order
const defaultOrdering = ['invest', 'save', 'kiwisaver', 'kids', 'insure']

/**
 * Takes a "customer_propensities" Braze card and determines the order in which product tiles should be displayed (likeliest to be interacted with first).
 * Depending on the product, other conditions may need to be met in order for a tile to be display.
 *
 * @param {Card} card - Braze card of category 'customer_propensities' or undefined
 * @returns {string[]} - array of tile categories that will be used to order the tiles displayed
 */
const getTileOrderByPropensity = (card?: Card): string[] => {
    if (!card || card.extras === undefined) {
        return defaultOrdering
    }

    const propensities: Propensity[] = []

    Object.keys(card.extras).forEach((key: string) => {
        if (
            tileOrderingKeys.includes(key) &&
            card.extras !== undefined &&
            !['', null, undefined].includes(card.extras[key])
        ) {
            // This reduces the key to its product string, eg "whats_next_kiwisaver_order" -> "kiwisaver"
            const productKey = key.replace(/^whats_next_(.*)_order$/, '$1')

            propensities.push({
                key: productKey,
                // Braze sends card key/value pairs through as strings regardless of the type that was supplied to Braze
                score: Number(card.extras[key]),
            })
        }
    })

    propensities.sort((a, b) => {
        if (a.score < b.score) {
            return -1
        }

        if (a.score > b.score) {
            return 1
        }

        return 0
    })

    const mappedPropensities = propensities.map(p => p.key)

    // For brand new customers who have might not have had propensities generated by Snowflake, we fall back to the default ordering
    if (mappedPropensities.length === 0) {
        return defaultOrdering
    }

    return mappedPropensities
}

export const GetStarted = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const profile = useProfile()
    const profiles = useProfiles()
    const hasDependentAccount = profiles.some(p => p.legacy_profile_type === 'DEPENDENT')
    const isDependent = profile.legacy_profile_type === 'DEPENDENT'
    const hasInvestHistory = useHasInvestHistory(profile.portfolios)
    const hasSaveAccount = profile.portfolios.some(p => p.product === 'SAVE')
    const hasKiwisaverAccount = profile.portfolios.some(p => p.product === 'KIWISAVER')
    // get started will display for sole traders who have their fonterra portfolio inside their personal profile
    // we still want to cut down the experience in this scenario
    const hasFonterraAccount = profile.portfolios.some(p => p.product === 'FONTERRA')
    const hasInsureAccess = useHasInsureAccess()
    const interestRate = useAppSelector(s => s.save.interestRate)
    const propensitiesCard = useAppSelector(s => s.identity.notifications.propensitiesCard)

    if (propensitiesCard) {
        logContentCardImpressions([propensitiesCard])
    }

    const partialSignups = profiles.filter(
        profile => profile.legacy_profile_type === 'DEPENDENT' && profile.is_in_signup,
    )

    // Detect if customer has started KS signup
    const ksCustomer = useKSCustomerOrNull()

    const ordering = getTileOrderByPropensity(propensitiesCard)

    React.useEffect(() => {
        // Get interest rate if missing
        if (!interestRate) {
            dispatch(saveActions.getInterestRate())
        }
    }, [])

    const title =
        hasInvestHistory || hasSaveAccount || hasKiwisaverAccount
            ? 'More ways to build wealth'
            : 'What would you like to do first?'

    /**
     * Using the tile order calculated with getTileOrderByPropensity(), compiles an array of tiles to be displayed.
     * Depending on the product, other conditions may need to be met in order for a tile to be display.
     *
     * @returns {object[]} array of JSX elements
     */
    const compileTiles = (): JSX.Element[] => {
        const components: JSX.Element[] = []

        ordering.forEach(item => {
            switch (item) {
                case 'invest':
                    if (!hasInvestHistory && !hasFonterraAccount) {
                        components.push(
                            <PortfolioSlice
                                dataTestId="button--landing-start-buying-shares"
                                image={investSmall}
                                imageBackgroundColour="Melon100"
                                onClick={handleInvestClick}
                                key={item}
                            >
                                <h3>Invest in shares</h3>
                                <p>Buy US, NZ, or Aussie shares, or transfer shares you already own</p>
                            </PortfolioSlice>,
                        )
                    }
                    break

                case 'save':
                    components.push(
                        <PortfolioSlice
                            dataTestId="button--landing-open-save"
                            image={saveSmall}
                            imageBackgroundColour="Orange100"
                            onClick={handleSaveClick}
                            key={item}
                        >
                            <h3>{hasSaveAccount ? 'Start saving for a goal' : 'Open a Save account'}</h3>
                            <p>
                                {hasSaveAccount
                                    ? 'Open a Save account for a rainy day, holiday, or house deposit'
                                    : `Earn ${interestRate}% on your savings—with no limits`}
                            </p>
                        </PortfolioSlice>,
                    )
                    break

                case 'kiwisaver':
                    if (!hasKiwisaverAccount && !isDependent && !hasFonterraAccount) {
                        let headingText = 'Join our KiwiSaver Scheme'
                        let bodyText = 'Invest for your retirement or first home with more choice'

                        if (ksCustomer && ['SIGNUP', 'SIGNUP_RESTART'].includes(ksCustomer?.customer_state)) {
                            headingText = 'Continue joining the Sharesies KiwiSaver Scheme'
                            bodyText =
                                ksCustomer.purpose === 'HOUSE_DEPOSIT'
                                    ? 'Invest for your first home with more choice and control'
                                    : 'Invest for your retirement with more choice and control'
                        }

                        components.push(
                            <PortfolioSlice
                                dataTestId="button--landing-open-kiwisaver"
                                image={kiwisaverSmall}
                                imageBackgroundColour="Blueberry100"
                                onClick={handleKiwiSaverClick}
                                key={item}
                            >
                                <h3>{headingText}</h3>
                                <p>{bodyText}</p>
                            </PortfolioSlice>,
                        )
                    }
                    break

                case 'kids':
                    if (!isDependent && !hasFonterraAccount) {
                        let title = 'Create a Kids Account'
                        let subTitle = `Kick-start ${
                            hasDependentAccount ? 'another' : 'a'
                        } kid’s life-long journey with money`

                        if (partialSignups.length > 0) {
                            title = 'Continue creating Kids Account'
                            subTitle = `Pick up where you left off, and get ${partialSignups[0].name}’s money journey started`
                        }

                        components.push(
                            <PortfolioSlice
                                dataTestId="button--landing-open-kids-account"
                                image={kidsAccountImg}
                                imageBackgroundColour="Pineapple100"
                                onClick={handleKidsAccountClick}
                                key={item}
                            >
                                <h3>{title}</h3>
                                <p>{subTitle}</p>
                            </PortfolioSlice>,
                        )
                    }
                    break

                case 'insure':
                    if (hasInsureAccess && !hasFonterraAccount) {
                        components.push(
                            <div key={item}>
                                <ErrorBoundary
                                    // graceful service degradation if Cove is down - hide slice
                                    key={location.pathname}
                                    FallbackComponent={() => null}
                                >
                                    <React.Suspense fallback={<LoadingSkeleton />}>
                                        <GetStartedInsureWrapper />
                                    </React.Suspense>
                                </ErrorBoundary>
                            </div>,
                        )
                    }
            }
        })

        return components
    }

    const handleInvestClick = () => {
        track('invest')
        navigate(profileUrl('invest'))
    }

    const handleSaveClick = () => {
        track('save')
        navigate(profileUrl('save'))
    }

    const handleKidsAccountClick = () => {
        track('kids')
        navigate(profileUrl('settings/create-kids-account'))
    }

    const handleKiwiSaverClick = () => {
        track('kiwisaver')
        navigate(profileUrl('kiwisaver'))
    }

    return (
        <div className={styles.getStarted}>
            <h2 className={spacing.spaceBelow8}>{title}</h2>

            {compileTiles()}

            {!hasSaveAccount && (
                <p className={styles.interestRateDisclaimer}>Save interest rate is subject to change.</p>
            )}
        </div>
    )
}
