import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'

export const Thanks: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const handleOnClick = () => {
        navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--asx-transfer-thanks" leftButton="back" />
            <Page>
                <h1 className={cn(commonStyles.heading, spacing.spaceBelow24)}>You did it! Here’s what’s next</h1>
                <p className={spacing.spaceBelow16}>Your transfer will normally take 15-30 business days.</p>
                <p className={spacing.spaceBelow16}>We’ll reach out via email if we need anything else.</p>
                <p>You can check in on its progress anytime by going to Portfolio &gt; Manage &gt; Transfer shares.</p>
                <Button
                    dataTestId="button--go-to-transfers"
                    label="Back to Transfers"
                    onClick={handleOnClick}
                    pageButton
                />
            </Page>
        </>
    )
}

export default Thanks
