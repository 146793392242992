import {Button} from '@design-system/button'
import {ChevronLeft, ChevronRight} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import Slider from 'react-slick'
import Analytics from '~/api/google-analytics/googleAnalytics'
import * as api from '~/api/retail'
import {accessibility, spacing} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import inTheBank from '~/sections/save/assets/images/in-the-bank.png'
import saveLarge from '~/sections/save/assets/images/save-hero.png'
import waitlistUpdates from '~/sections/save/assets/images/waitlist-updates.svg'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import savingsActions from '~/store/save/actions'
import styles from './SaveWaitlist.scss'

interface infoCardsProps {
    image: string
    title: string
    description: string
}

interface Arrow {
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    direction: 'prev' | 'next'
}

export const Arrow = ({onClick, direction}: Arrow) => (
    <button
        data-testid={`feature-tiles-arrow--${direction}`}
        type="button"
        className={cn(styles.arrow, accessibility.button, {
            [styles.nextArrow]: direction === 'next',
            [styles.prevArrow]: direction === 'prev',
        })}
        onClick={event => {
            if (onClick) {
                onClick(event)
            }
        }}
    >
        {direction === 'next' ? <ChevronRight /> : <ChevronLeft />}
    </button>
)

export const SaveWaitlist: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const saveInterestRate = useAppSelector(s => s.save.interestRate)

    React.useEffect(() => {
        if (!saveInterestRate) {
            dispatch(savingsActions.getInterestRate())
        }
    }, [])

    const parentRef = React.useRef<HTMLDivElement | null>(null)
    const [loading, setLoading] = React.useState(false)
    const [isLastSlide, setIsLastSlide] = React.useState(false)
    const [isFirstSlide, setIsFirstSlide] = React.useState(true)
    const [slickSliderIsAnimating, setSlickSliderIsAnimating] = React.useState(false)

    const infoCards: infoCardsProps[] = [
        {
            image: saveLarge,
            title: 'Save without limits',
            description: `With a ${saveInterestRate}% p.a. interest rate and all the flexibility you need.`,
        },
        {
            image: inTheBank,
            title: 'It’s in the bank',
            description: 'Your money’s held with a major registered NZ-based bank.',
        },
        {
            image: waitlistUpdates,
            title: 'Coming soon',
            description: 'Want early access? Register your interest below!',
        },
    ]

    const handleClick = async (): Promise<void> => {
        setLoading(true)
        try {
            const response = await api.post('save/waitlist-signup')
            await dispatch(identityActions.Check())
            switch (response.type) {
                case 'empty':
                    setLoading(false)
                    Toast('You’re registered! We’ll email you updates. 💌')
                    navigate(profileUrl('explore'))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    setLoading(false)
                    assertNever(response)
            }
        } catch (e) {
            setLoading(false)
            throw e
        }
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--save-waitlist"
                leftButton="back"
                onLeftButtonClick={() => navigate(profileUrl('explore'))}
                title="Ready. Set. Save"
            />
            <Page withoutDefaultPadding>
                <div className={styles.pageContainer}>
                    <p className={spacing.spaceBelow20}>
                        Register your interest for updates—and a chance to be one of the first to get access.
                    </p>
                    <div
                        className={cn(styles.infoCards, {
                            [styles.slickSliderIsAnimating]: slickSliderIsAnimating,
                            [styles.isLastSlide]: isLastSlide,
                        })}
                        ref={parentRef}
                    >
                        <Slider
                            arrows
                            nextArrow={
                                // If it's the last slide, hide the next arrow
                                isLastSlide ? <></> : <Arrow direction="next" />
                            }
                            prevArrow={
                                // If it's the first slide, hide the prev arrow
                                isFirstSlide ? <></> : <Arrow direction="prev" />
                            }
                            dots={false}
                            infinite={false}
                            slidesToShow={1.4}
                            draggable
                            responsive={[
                                {
                                    breakpoint: 530, // main block width = 834px (desktop width) - 304px (flyout width)
                                    settings: {
                                        slidesToShow: 1.15,
                                        centerMode: true,
                                        centerPadding: '8px',
                                        arrows: false,
                                    },
                                },
                            ]}
                            beforeChange={(_, nextSlide) => {
                                if (nextSlide === 0) {
                                    // The first slide
                                    setIsFirstSlide(true)
                                } else {
                                    setIsFirstSlide(false)
                                }

                                if (Math.ceil(nextSlide) === infoCards.length - 1) {
                                    // The last slide
                                    setIsLastSlide(true)
                                } else {
                                    setIsLastSlide(false)
                                }

                                setSlickSliderIsAnimating(true)
                            }}
                            afterChange={() => setSlickSliderIsAnimating(false)}
                        >
                            {infoCards.map(content => {
                                return (
                                    <div className={styles.infoCards} key={content.title}>
                                        <div className={styles.card}>
                                            <div className={spacing.spaceBelow32}>
                                                <img src={content.image} />
                                            </div>
                                            <div>
                                                <h2>{content.title}</h2>
                                            </div>
                                            <div>
                                                <h3>{content.description}</h3>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                    <Button
                        additionalClassName={spacing.spaceAbove24}
                        type="primary"
                        label="Register your interest"
                        processing={loading}
                        onClick={() => {
                            Analytics.event({
                                category: 'Save Waitlist',
                                action: 'Register interest clicked',
                                label: 'In-app carousel',
                            })
                            handleClick()
                        }}
                        dataTestId="button--waitlist-register"
                    />
                    <div className={styles.subtextContainer}>
                        <p>
                            You’re agreeing to receive occasional emails about the Sharesies Save account—you can
                            unsubscribe at any time.
                        </p>
                        <p>Please note, the interest rate is subject to change.</p>
                    </div>
                </div>
            </Page>
        </>
    )
}

export default SaveWaitlist
