import smoothscroll from 'smoothscroll-polyfill'

export const scrollToTop = () => {
    smoothscroll.polyfill() // kick off the polyfill to make smooth scroll work on Safari
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
}

export const scrollTo = (scrollPosition: number) => {
    smoothscroll.polyfill() // kick off the polyfill to make smooth scroll work on Safari
    window.scrollTo({top: scrollPosition, left: 0, behavior: 'smooth'})
}

export const getScrollPosition = (): number => {
    return window.scrollY
}
