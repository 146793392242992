import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'

const CloseAccountCancel = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    return (
        <>
            <Toolbar dataTestId="toolbar--close-account-cancel" title="Stay with Sharesies" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <h2 className={cn(spacing.spaceBelow12, typographyOverrides['as-h2'])}>
                    Thanks for supporting Sharesies!
                </h2>
                <p className={spacing.spaceBelow24}>
                    You’re helping our vision of people having equal investment opportunities—no matter how much they
                    have to invest.
                </p>
                <p className={spacing.spaceBelow24}>Thanks from the Sharesies team.</p>
                <Button
                    pageButton
                    label="Peace"
                    onClick={() => navigate(profileUrl(''))}
                    dataTestId="button--cancel-close-account"
                />
            </Page>
        </>
    )
}

export default CloseAccountCancel
