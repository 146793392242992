import {Button} from '@design-system/button'
import {ConditionalWrapper} from '@design-system/conditional-wrapper'
import {NotificationBell, Transfer} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {dateFormatShortDayFullMonth} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {ListMenuExpanded} from '~/global/widgets/list-menu-expanded/ListMenuExpanded'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useHoldingSummary, usePortfolioSettings} from '~/sections/moose/data/retail'
import {MoosePortfolioPathParams} from '~/sections/moose/routes'
import {FinDescription} from '~/sections/moose/widgets/fin-description/FinDescription'
import styles from './ManageLanding.scss'

interface ManageSectionProps {
    title: string | JSX.Element
    content: string | JSX.Element
    dataTestId: string
}

const ManageSection: React.FunctionComponent<ManageSectionProps> = ({title, content, dataTestId}) => {
    return (
        <div className={styles.section} data-testid={`section--${dataTestId}`}>
            <ConditionalWrapper condition={typeof title === 'string'} wrapper={children => <h3>{children}</h3>}>
                <>{title}</>
            </ConditionalWrapper>
            <span data-testid={`section--${dataTestId}-content`}>{content}</span>
        </div>
    )
}

export const ManageLanding: React.FunctionComponent<MoosePortfolioPathParams> = ({
    portfolioId,
}: MoosePortfolioPathParams) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const summaryData = useHoldingSummary(portfolioId)
    const settingsData = usePortfolioSettings(portfolioId)
    const [isFinSet, setIsFinSet] = React.useState(settingsData.default_csn.is_fin_set)

    React.useEffect(() => {
        setIsFinSet(settingsData.default_csn.is_fin_set)
    }, [settingsData.default_csn.is_fin_set])

    const navToAddFin = () => {
        navigate(
            profileUrl('fonterra/portfolios/:portfolioId/manage/add-fin', {
                portfolioId,
            }),
        )
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--manage-landing" leftButton="back" title="Manage" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <div className={styles.content}>
                    <h2 className={styles.heading}>{summaryData.entity_name}</h2>
                    <ManageSection dataTestId="farm-id" title="Farm supply number" content={summaryData.farm_id} />
                    <ManageSection dataTestId="csn" title="Common shareholder number (CSN)" content={summaryData.csn} />
                    {isFinSet && (
                        <ManageSection
                            dataTestId="fin"
                            title={
                                <div className={styles.sectionWithHeaderLink}>
                                    <h3>Faster identification number (FIN)</h3>
                                    {settingsData.default_csn.state !== 'pending' && (
                                        <ButtonAsLink dataTestId="button--remove-fin" onClick={navToAddFin}>
                                            Edit
                                        </ButtonAsLink>
                                    )}
                                </div>
                            }
                            content={
                                <>
                                    FIN saved on{' '}
                                    {settingsData.default_csn.created.toFormat(dateFormatShortDayFullMonth)}
                                    <FinAlertCard state={settingsData.default_csn.state} />
                                </>
                            }
                        />
                    )}
                    {!isFinSet && (
                        <ManageSection
                            dataTestId="fin"
                            title="Faster identification number (FIN)"
                            content={
                                <>
                                    <FinAlertCard state={settingsData.default_csn.state} />
                                    <FinDescription />
                                    <Button
                                        additionalClassName={spacing.spaceAbove20}
                                        dataTestId="button--add-fin"
                                        label="Add your FIN"
                                        type="secondary"
                                        onClick={navToAddFin}
                                    />
                                </>
                            }
                        />
                    )}
                </div>
                <Actions portfolioId={portfolioId} />
            </Page>
        </>
    )
}

const Actions = ({portfolioId}: MoosePortfolioPathParams) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    return (
        <div className={spacing.spaceAbove40}>
            <h2 className={styles.heading}>More actions</h2>
            <ListMenuExpanded
                dataTestId="feature-link--price-notification"
                title="Price notifications"
                description="See and manage all price notifications set on investments."
                icon={<NotificationBell />}
                onClick={() =>
                    navigate(profileUrl('fonterra/portfolios/:portfolioId/manage/price-notifications', {portfolioId}))
                }
            />
            <ListMenuExpanded
                dataTestId="feature-link--transfer-shares"
                title="Transfer shares"
                description="Transfer your shares between your business’s CSNs or farm supply numbers"
                icon={<Transfer />}
                onClick={() => navigate(profileUrl('fonterra/portfolios/:portfolioId/transfer/intro', {portfolioId}))}
            />
            {/** TODO - add download reports action when available */}
        </div>
    )
}

type FinState = Response.FonterraPortfolioSettings['default_csn']['state']

const FinAlertCard = ({state}: {state: FinState}) => {
    const title = 'Your FIN hasn’t been validated'
    switch (state) {
        case 'pending':
            return (
                <AlertCard className={spacing.spaceAbove8} title={title} type="warning">
                    We expect to validate your FIN within the next business day. Until then, you can’t sell any shares.
                </AlertCard>
            )
        case 'rejected':
            return (
                <AlertCard className={cn(spacing.spaceAbove8, spacing.spaceBelow16)} title={title} type="warning">
                    Sorry, we were unable to validate your FIN, please try again. For more help, contact Computershare.
                </AlertCard>
            )
        default:
            return null
    }
}
