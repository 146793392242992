import {amountOfManagedFundFee} from '~/global/utils/instrument-transaction-fee/instrumentTransactionFee'
import {BuyOrder, BuySellOrder} from '~/store/accounting/types'
import {Instrument} from '~/store/instrument/types'

const calculateManagedFundUnits = ({order, instrument}: {order: BuyOrder; instrument: Instrument}) => {
    const estimatedPrice = order.order_unit_price
        ? parseFloat(order.order_unit_price).toFixed(3)
        : parseFloat(instrument.marketPrice).toFixed(3)
    const estimatedFee = calculateManagedFundFee({order, instrument})
    const orderAmount = order.created_hold_amount ? parseFloat(order.created_hold_amount) : 0

    return (orderAmount - estimatedFee) / parseFloat(estimatedPrice)
}

const calculateManagedFundFee = ({order, instrument}: {order: BuySellOrder; instrument: Instrument}) => {
    if (order.type === 'sell' && instrument.fixedFeeSpread) {
        const estimatedAmount = parseFloat(order.shares) * parseFloat(instrument.marketPrice)
        return amountOfManagedFundFee(estimatedAmount.toString(), instrument.fixedFeeSpread)
    }
    if (order.type === 'buy' && instrument.fixedFeeSpread && order.requested_currency) {
        return amountOfManagedFundFee(order.requested_currency, instrument.fixedFeeSpread)
    }
    return 0
}

export {calculateManagedFundUnits, calculateManagedFundFee}
