import {Button} from '@design-system/button'
import {DockableModal} from '@design-system/dockable-modal'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Radio} from '~/global/widgets/form-controls'
import {Link} from '~/migrate-react-router'
import {PIR} from '~/store/identity/types'
import styles from './PIRCalculator.scss'

interface CalculatorStep {
    yes: CalculatorStep | CompleteStep
    no: CalculatorStep | CompleteStep
    back?: CalculatorStep
    stepNumber: number
    question(isDependent: boolean, preferredName: string): React.ReactNode
}

interface CompleteStep {
    pir: PIR
    back: CalculatorStep
}

const pir105: PIR = '10.5'
const pir175: PIR = '17.5'
const pir28: PIR = '28'

const Start: CalculatorStep = {
    get yes() {
        return Complex2
    },
    get no() {
        return Simple2
    },
    stepNumber: 1,
    question: (isDependent, preferredName) =>
        `${isDependent ? `Has ${preferredName}` : 'Have you'} received any PIE income in the last 2 years?`,
}

const Simple2: CalculatorStep = {
    get yes() {
        return {pir: pir105, back: this}
    },
    get no() {
        return Simple3
    },
    back: Start,
    stepNumber: 2,
    question: (isDependent, preferredName) =>
        `Was ${
            isDependent ? `${preferredName}’s` : 'your'
        } taxable income $15,600 or less in one of the last 2 income years (to 31 March)?`,
}

const Simple3: CalculatorStep = {
    get yes() {
        return {pir: pir175, back: this}
    },
    get no() {
        return {pir: pir28, back: this}
    },
    back: Simple2,
    stepNumber: 3,
    question: (isDependent, preferredName) =>
        `Was ${
            isDependent ? `${preferredName}’s` : 'your'
        } taxable income $53,500 or less in one of the last 2 income years (to 31 March)?`,
}

const Complex2: CalculatorStep = {
    get yes() {
        return {pir: pir105, back: this}
    },
    get no() {
        return Complex3
    },
    back: Start,
    stepNumber: 2,
    question: (isDependent, preferredName) =>
        `Was ${isDependent ? `${preferredName}’s` : 'your'} taxable income $15,600 or less, and ${
            isDependent ? 'their' : 'your'
        } PIE income $48,000 or less in one of the last 2 income years (to 31 March)?`,
}

const Complex3: CalculatorStep = {
    get yes() {
        return {pir: pir175, back: this}
    },
    get no() {
        return {pir: pir28, back: this}
    },
    back: Start,
    stepNumber: 3,
    question: (isDependent, preferredName) =>
        `Was ${isDependent ? `${preferredName}’s` : 'your'} taxable income $53,500 or less, plus ${
            isDependent ? 'their' : 'your'
        } PIE income $70,000 or less in one of the last 2 income years (to 31 March)?`,
}

interface Props {
    isVisible: boolean
    onSelect: (pir: PIR) => void
    onClose: () => void
    isDependent: boolean
    preferredName: string
}

const PIRCalculator: React.FunctionComponent<Props> = props => {
    const {preferredName, isDependent, isVisible, onClose, onSelect} = props
    const profileUrl = useProfileUrl()
    const [step, setStep] = React.useState<CalculatorStep | CompleteStep>(Start)
    React.useEffect(() => {
        if (isVisible) {
            setStep(Start)
        }
    }, [isVisible])

    const onSuccess = (pir: PIR) => {
        onSelect(pir)
    }

    const renderCalculatorStep = (step: CalculatorStep) => {
        const choices = [
            {
                value: 'yes',
                label: 'Yes',
            },
            {
                value: 'no',
                label: 'No',
            },
        ]

        const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
            const value = e.currentTarget.value
            value === 'yes' ? setStep(step.yes) : setStep(step.no)
        }

        return (
            <div key={step.stepNumber}>
                <div className={styles.stepNumber}>{step.stepNumber}.</div>
                <div className={styles.question}>{step.question(isDependent, preferredName)}</div>
                <Radio
                    name={`step-${step.stepNumber}`}
                    isTouched={false}
                    dataTestId={`radio--${step.stepNumber}`}
                    onChange={handleChange}
                    choices={choices}
                />
                {step.back && (
                    <Button
                        type="secondary"
                        additionalClassName={spacing.spaceAbove24}
                        onClick={() => setStep(step.back!)}
                        label="Back"
                        dataTestId="button--pir-back"
                    />
                )}
            </div>
        )
    }

    const renderCompleteStep = (step: CompleteStep) => {
        return (
            <>
                <p className={spacing.spaceBelow12}>
                    {isDependent ? `${preferredName}’s` : 'Your'} PIR is:{' '}
                    <span className={styles.pirValue}>{step.pir}%</span>
                </p>
                <p className={styles.footer}>
                    You can change {isDependent ? `${preferredName}’s` : 'your'} IRD number or PIR any time in{' '}
                    <Link to={profileUrl('settings')}>Account</Link>, (changing your PIR might change your Portfolio
                    Value).
                </p>
                <Button
                    additionalClassName={spacing.spaceAbove24}
                    dataTestId="button--pir-done"
                    label="Sweet"
                    onClick={() => onSuccess(step.pir)}
                />
                <Button
                    type="secondary"
                    additionalClassName={spacing.spaceAbove24}
                    onClick={() => setStep(step.back)}
                    label="Back"
                    dataTestId="button--pir-back"
                />
            </>
        )
    }

    function isCalculatorStep(step: CalculatorStep | CompleteStep): step is CalculatorStep {
        return !!(step as CalculatorStep).question
    }

    return (
        <DockableModal
            isOpen={isVisible}
            setIsOpen={_ => {
                // The `_` argument will be called as false, but we don't need to use it in this case
                // Normally we'd just be passing in setModalOpen (setIsOpen={setModalOpen}), but we need to run a
                // side effect `onClose` rather than setting `isOpen(false)`
                onClose()
            }}
            title="PIR calculator"
            dataTestId="dockable-modal--pir-calculator"
        >
            {isCalculatorStep(step) ? renderCalculatorStep(step) : renderCompleteStep(step)}
        </DockableModal>
    )
}

export default PIRCalculator
