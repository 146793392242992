import {Button} from '@design-system/button'
import cn from 'classnames'
import {withFormik} from 'formik'
import React from 'react'
import {Model} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {page, spacing} from '~/global/scss/helpers'
import tooltipStyles from '~/global/scss/reused-styles/tooltip.scss'
import {calculateCurrencyExchangeFromSourceAmount} from '~/global/utils/calculate-currency-exchange/calculateCurrencyExchange'
import calculateWalletBalance from '~/global/utils/calculate-wallet-balance/calculateWalletBalance'
import {exceedsLowPricedSecurityCap} from '~/global/utils/exceeds-low-priced-security-cap/exceedsLowPricedSecurityCap'
import {useAmountWithFeeForLimitBuy} from '~/global/utils/instrument-transaction-fee/instrumentTransactionFee'
import {isDisorderlyLimit} from '~/global/utils/is-disorderly-limit/isDisorderlyLimit'
import {isInstrumentEligibleForLimitBuyExtendedHours} from '~/global/utils/is-instrument-eligible-for-extended-hours/isInstrumentEligibleForExtendedHours'
import {isOnASX, isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import WalletBalanceWrapper from '~/global/widgets/estimated-wallet-balance/WalletBalanceWrapper'
import {ErrorBox} from '~/global/widgets/form-controls'
import {StrongNumber, StrongCurrency, Checkbox} from '~/global/widgets/form-controls/formik'
import {WalletValue} from '~/global/widgets/number-elements/NumberElements'
import {Tooltip} from '~/global/widgets/tooltip/Tooltip'
import {isNavigationDirective, Link} from '~/migrate-react-router'
import styles from '~/sections/invest/sections/order-flow/OrderForm.scss'
import {getPriceLimitDecimalPlaces} from '~/sections/invest/sections/order-flow/utils/get-price-limit-decimal-places/getPriceLimitDecimalPlaces'
import {ExtendedHoursModal} from '~/sections/invest/sections/order-flow/widgets/modals/ExtendedHoursModal'
import {State} from '~/store/accounting/types'
import {connect} from '~/store/connect'
import {Instrument} from '~/store/instrument/types'
import actions from '~/store/order/actions'
import {StagedBuyOrder} from '~/store/order/types'
import {UnwrapThunkAction} from '~/store/types'

interface LimitBuyInSharesFormValues {
    priceLimit: string
    shareAmount: string
    extendedHours?: boolean
}

const LimitBuyInSharesForm = withRouter(
    withFormik<LimitBuyInSharesFormProps & WithRouterProps, LimitBuyInSharesFormValues>({
        mapPropsToValues: ({stagedBuyOrder, extendedHoursApplies}) => {
            if (!stagedBuyOrder) {
                return {
                    priceLimit: '',
                    shareAmount: '',
                    extendedHours: extendedHoursApplies,
                }
            }
            return {
                priceLimit: stagedBuyOrder.orderPriceLimit ? stagedBuyOrder.orderPriceLimit : '',
                shareAmount: stagedBuyOrder.orderShareAmount ? stagedBuyOrder.orderShareAmount : '',
                extendedHours: stagedBuyOrder.idempotencyKey ? !!stagedBuyOrder.extendedHours : extendedHoursApplies, // if we got as far as confirm previously, use the stored value, otherwise default to on if applicable
            }
        },
        mapPropsToErrors: ({stagedBuyOrder}) => {
            // make the button disabled initially if there is an initial error by setting at least one field to have an error
            if (!stagedBuyOrder || !(stagedBuyOrder.orderPriceLimit && stagedBuyOrder.orderShareAmount)) {
                return {priceLimit: undefined}
            }
            // if there is an existing amount that got as far as stagedOrder, assume it is valid
            return {}
        },
        validate: (values, {instrument}) => {
            const errors: {priceLimit?: string; shareAmount?: string} = {}

            if (!values.priceLimit || Number(values.priceLimit) === 0) {
                errors.priceLimit = '' // we don't actually display an error
            }

            const {isDisorderly, message} = isDisorderlyLimit(values.priceLimit, instrument)
            if (isDisorderly && message) {
                errors.priceLimit = message
            }

            if (!values.shareAmount || Number(values.shareAmount) === 0) {
                errors.shareAmount = '' // we don't actually display an error
            }

            // Note: not having enough money in your wallet is *not* an error

            return errors
        },

        handleSubmit: async (
            {priceLimit, shareAmount, extendedHours},
            {
                setSubmitting,
                props: {
                    stagedBuyOrder,
                    updateStagedBuyOrder,
                    costStagedBuyOrder,
                    router: {navigate},
                    profileUrl,
                },
            },
        ) => {
            if (!stagedBuyOrder) {
                return
            }

            setSubmitting(true)

            rudderTrack('buy', 'order_details_entered', {instrument_id: stagedBuyOrder.fundId})

            try {
                updateStagedBuyOrder({
                    ...stagedBuyOrder,
                    orderPriceLimit: priceLimit,
                    orderShareAmount: shareAmount,
                    extendedHours,
                })
                const response = await costStagedBuyOrder()
                if (isNavigationDirective(response)) {
                    response.execute(navigate, profileUrl)
                }
            } catch (error) {
                setSubmitting(false)
            }
        },
    })(
        ({
            values,
            errors,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
            walletBalances,
            exchangeRates,
            exchangeFeeRate,
            usEquitiesEnabled,
            updateStagedBuyOrder,
            stagedBuyOrder,
            instrument,
            setSubmitting,
            accountRestricted,
            homeCurrency,
            extendedHoursApplies,
        }) => {
            const profileUrl = useProfileUrl()

            const [showExtendedHoursModal, setShowExtendedHoursModal] = React.useState<boolean>(false)

            React.useEffect(() => {
                if (stagedBuyOrder && stagedBuyOrder.error) {
                    setSubmitting(false)
                }
            }, [stagedBuyOrder])

            const estimatedAmountWithFee = useAmountWithFeeForLimitBuy({
                instrument,
                price_limit: values.priceLimit,
                share_amount: values.shareAmount,
            })
            const estimatedAmountWithFeeIsLoading = estimatedAmountWithFee === undefined

            const isRemainingBalanceNegative = React.useMemo(() => {
                if (!walletBalances || estimatedAmountWithFeeIsLoading) {
                    return false
                }
                const walletBalanceInDisplayCurrency = calculateWalletBalance(
                    walletBalances,
                    exchangeRates,
                    usEquitiesEnabled,
                    instrument.currency,
                    exchangeFeeRate,
                )
                return walletBalanceInDisplayCurrency
                    ? parseFloat(walletBalanceInDisplayCurrency) - estimatedAmountWithFee < 0
                    : false
            }, [
                walletBalances,
                exchangeRates,
                usEquitiesEnabled,
                instrument.currency,
                estimatedAmountWithFee,
                exchangeFeeRate,
            ])

            const exceedsLowPriceLimit = React.useMemo(() => {
                const {exceedsLimit, msg} = exceedsLowPricedSecurityCap(
                    instrument,
                    values.shareAmount,
                    undefined,
                    values.priceLimit,
                    undefined,
                )

                if (exceedsLimit && msg) {
                    return {exceedsLimit, msg}
                }
            }, [instrument, values])

            const instrumentToHomeCurrencyExchangeRate = exchangeRates.find(
                exchangeRate =>
                    exchangeRate.sourceCurrency === instrument.currency && exchangeRate.targetCurrency === homeCurrency,
            )

            const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault()
                if (values.extendedHours) {
                    // US shares extended hours - if enabled show warning before proceeeding to submit
                    setShowExtendedHoursModal(true)
                } else {
                    handleSubmit(e)
                }
            }

            const preventSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault()
                }
            }

            const handleOnBlur = (event: string | React.FocusEvent<unknown>) => {
                if (typeof event === 'string') {
                    return
                }
                handleBlur(event)

                if (!stagedBuyOrder) {
                    return
                }

                // TODO: [DS-636] clean up this logic
                // Hack to prevent loss of focus on the input field while the component re-renders
                setTimeout(() => {
                    // store any user entered values immediately so the user can look at
                    // the market depth page without losing data
                    updateStagedBuyOrder({
                        ...stagedBuyOrder,
                        orderPriceLimit: values.priceLimit,
                        orderShareAmount: values.shareAmount,
                        extendedHours: values.extendedHours,
                    })
                }, 0)
            }

            const shareAmountLabel = `Number of ${shareLabel({
                instrument,
                isPlural: true,
                isWhole: isOnMainUsExchange(instrument),
            })} to buy`

            return (
                <form onSubmit={onSubmit}>
                    {showExtendedHoursModal && (
                        <ExtendedHoursModal
                            orderType={stagedBuyOrder?.orderType}
                            onContinue={() => {
                                setShowExtendedHoursModal(false)
                                handleSubmit()
                            }}
                            onClose={() => setShowExtendedHoursModal(false)}
                        />
                    )}

                    <div
                        className={cn({
                            [styles.errorBoxSpacing]: errors && errors.priceLimit,
                            [spacing.spaceBelow32]: !errors || !errors.priceLimit,
                        })}
                    >
                        <StrongCurrency
                            dataTestId="strong-currency--price-limit"
                            name="priceLimit"
                            label={`Highest price to pay per ${shareLabel({instrument})}`}
                            disabled={isSubmitting || accountRestricted}
                            autoFocus
                            optionalAttributes={{
                                onKeyPress: preventSubmit,
                                onBlur: handleOnBlur,
                            }}
                            decimalPlaces={getPriceLimitDecimalPlaces(instrument)}
                            currency={instrument.currency}
                        />
                    </div>

                    {errors && errors.priceLimit && (
                        <ErrorBox message={errors.priceLimit} className={styles.errorBox} />
                    )}

                    <StrongNumber
                        dataTestId="strong-number--share-amount"
                        name="shareAmount"
                        label={shareAmountLabel}
                        disabled={isSubmitting || accountRestricted}
                        optionalAttributes={{
                            onKeyPress: preventSubmit,
                            onBlur: handleOnBlur,
                        }}
                        placeholder={shareLabel({
                            instrument,
                            isPlural: true,
                            isCapitalised: true,
                            isWhole: isOnMainUsExchange(instrument),
                        })}
                        showThousandsSeparator
                        normalisation={
                            isOnASX(instrument)
                                ? // allow decimals
                                  'decimalOnly'
                                : // restrict to number only
                                  'numberOnly'
                        }
                        decimalPlaces={
                            // 0 is for US
                            isOnASX(instrument) ? 8 : 0
                        } // make this dynamic if we implement for NZX instruments
                        helpText={
                            <p
                                className={cn(styles.estimatedAmount, {
                                    [styles.disabledAmount]: !(values.priceLimit && values.shareAmount),
                                })}
                            >
                                Estimated amount{' '}
                                {estimatedAmountWithFeeIsLoading ? (
                                    <span className={styles.amount}>…</span>
                                ) : instrument.currency === 'usd' &&
                                  instrumentToHomeCurrencyExchangeRate &&
                                  exchangeFeeRate !== null &&
                                  values.priceLimit &&
                                  values.shareAmount ? (
                                    <Tooltip>
                                        <span className={cn(tooltipStyles.label, styles.amount)}>
                                            <WalletValue
                                                value={estimatedAmountWithFee}
                                                currency={instrument.currency}
                                            />
                                        </span>
                                        <p className={tooltipStyles.tooltip}>
                                            <p>
                                                Estimated amount{' '}
                                                <WalletValue
                                                    value={
                                                        calculateCurrencyExchangeFromSourceAmount(
                                                            estimatedAmountWithFee.toString(),
                                                            instrumentToHomeCurrencyExchangeRate.rate,
                                                            exchangeFeeRate,
                                                        ).targetAmount
                                                    }
                                                    currency={homeCurrency}
                                                />
                                            </p>
                                        </p>
                                    </Tooltip>
                                ) : (
                                    <span className={styles.amount}>
                                        <WalletValue value={estimatedAmountWithFee} currency={instrument.currency} />
                                    </span>
                                )}
                            </p>
                        }
                    />

                    {exceedsLowPriceLimit?.exceedsLimit && (
                        <div className={styles.limitWarning} data-testid="exceeds-low-price-limit">
                            {exceedsLowPriceLimit.msg}
                        </div>
                    )}

                    {isRemainingBalanceNegative && !exceedsLowPriceLimit?.exceedsLimit && (
                        <div className={styles.balanceWarning} data-testid="insufficient-funds">
                            There’s not enough money in your Wallet. <Link to={profileUrl('wallet')}>Top up</Link>
                        </div>
                    )}

                    {extendedHoursApplies && (
                        <div className={styles.extendedHoursCheckbox}>
                            <Checkbox
                                dataTestId="checkbox--extended-hours"
                                name="extendedHours"
                                label="Allow order to fill during extended hours"
                                helpText="When selected, all or some of your order may fill during extended hours. If unselected, it’ll only fill during regular market hours."
                                additionalClassName={styles.checkbox}
                            />
                        </div>
                    )}

                    <ActionBar className={cn(page.flexRow, styles.formFooter)}>
                        <WalletBalanceWrapper displayCurrency={instrument.currency} exchangeRates={exchangeRates} />
                        <Button
                            dataTestId="button--review"
                            label="Review"
                            disabled={!isValid || isRemainingBalanceNegative || accountRestricted}
                            processing={isSubmitting}
                            isSubmit
                        />
                    </ActionBar>
                </form>
            )
        },
    ),
)

interface StoreProps {
    walletBalances?: Model.User['wallet_balances']
    usEquitiesEnabled: boolean
    stagedBuyOrder?: StagedBuyOrder
    jurisdiction: Model.User['jurisdiction']
    accountRestricted: boolean
    homeCurrency: Model.User['home_currency']
    extendedHoursApplies: boolean
}

interface DispatchProps {
    costStagedBuyOrder: UnwrapThunkAction<typeof actions.CostBuyOrder>
    updateStagedBuyOrder(order: StagedBuyOrder): void
}

interface OwnProps {
    instrument: Instrument
    exchangeRates: State['exchangeRates']
    exchangeFeeRate: State['exchangeFeeRate']
    profileUrl: ReturnType<typeof useProfileUrl>
}

type LimitBuyInSharesFormProps = StoreProps & DispatchProps & OwnProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    (state, {instrument}) => {
        const {order, identity} = state

        return {
            usEquitiesEnabled: identity.user ? identity.user.us_equities_enabled : false,
            walletBalances: identity.user!.wallet_balances,
            jurisdiction: identity.user!.jurisdiction,
            stagedBuyOrder: order.stagedBuyOrder,
            accountRestricted: identity.user!.account_restricted,
            homeCurrency: identity.user!.home_currency,
            extendedHoursApplies:
                identity.portfolioExtendedHoursPreference && isInstrumentEligibleForLimitBuyExtendedHours(instrument),
        }
    },
    dispatch => ({
        costStagedBuyOrder: () => dispatch(actions.CostBuyOrder()),
        updateStagedBuyOrder: order => dispatch(actions.UpdateStagedBuyOrder(order)),
    }),
)(LimitBuyInSharesForm)
