import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import GeneralTermsAndConditions from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/GeneralTermsAndConditions'

const LegalTerms: React.FunctionComponent<{}> = () => {
    return (
        <>
            <Toolbar dataTestId="toolbar--legal-general-terms-and-conditions" leftButton="back" title="Legal" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <h2 className={cn(spacing.spaceBelow16)}>General Terms and Conditions</h2>
                <GeneralTermsAndConditions />
            </Page>
        </>
    )
}

export default LegalTerms
