import React from 'react'
import NZXParticipant from '~/global/widgets/help-modals/NZXParticipant'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useSignUpParticipants} from '~/sections/moose/data/retail'
import {SignUpStepCommonProps} from '~/sections/moose/sections/sign-up/SignUp'
import PrescribedPersonForm from '~/sections/moose/sections/sign-up/pages/prescribed-person/widgets/prescribed-person-form/PrescribedPersonForm'
import {useSignupEntityDetail} from '~/sections/moose/sections/sign-up/utils/sign-up-detail-hooks/signUpDetailHooks'

const PrescribedPerson = ({progressStep, regressStep, signupId}: SignUpStepCommonProps) => {
    const signupEntityDetail = useSignupEntityDetail()
    const participants = useSignUpParticipants().participants
    const hasMultipleEntities = signupEntityDetail.entities.length > 1
    const firstEntity = signupEntityDetail.entities[0]
    const title = hasMultipleEntities ? 'Prescribed Person' : `Is ${firstEntity.name} a Prescribed Person?`

    return (
        <>
            <Toolbar
                dataTestId="toolbar--fonterra-signup-prescribed-person"
                leftButton="back"
                onLeftButtonClick={regressStep}
                title={title}
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <p>Is your entity owned or controlled by someone who is:</p>
                <ul>
                    <li>
                        an employee of an{' '}
                        <NZXParticipant participants={participants} label="NZX Trading and Advising Firm" />
                    </li>
                    <li>
                        the immediate family of an employee of an NZX Trading and Advising Firm (partner or dependant
                        child).
                    </li>
                </ul>
                <p>If these answers are no, then your entity is not a prescribed person so select no.</p>
                <PrescribedPersonForm signupId={signupId} progressStep={progressStep} participants={participants} />
            </Page>
        </>
    )
}

export default PrescribedPerson
