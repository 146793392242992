import Decimal from 'decimal.js'
import {DateTime} from 'luxon'
import {distillApiNewClientToken} from '~/api/distill/apis'
import {InstrumentPriceChangeRequestDto} from '~/api/distill/models/InstrumentPriceChangeRequestDto'
import {Response} from '~/api/retail/types'

interface PriceChangePercentages {
    instrumentId: string
    percentageChange: Decimal
}

export async function getPriceChangePercentages(
    watchlist: Response.WatchlistV3['watchlist'],
): Promise<PriceChangePercentages[]> {
    const instruments: InstrumentPriceChangeRequestDto[] = []

    watchlist.forEach(watchlistItem => {
        instruments.push({
            instrumentId: watchlistItem.fund_id,
            initialDate: watchlistItem.created || DateTime.now().minus({years: 1}),
        })
    })

    const response = await distillApiNewClientToken.instrumentsApiNewClientToken.apiV1InstrumentsPriceChangesPost({
        scope: 'invest',
        priceChangesRequestDto: {instruments},
    })

    const percentageMovementValues: PriceChangePercentages[] = []

    if (response.priceChanges) {
        response.priceChanges.forEach(item => {
            percentageMovementValues.push({
                instrumentId: item.instrumentId,
                percentageChange: new Decimal(item.priceChangePercent).times(100).toDecimalPlaces(2),
            })
        })
    }

    return percentageMovementValues
}
