import React from 'react'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {Order} from '~/store/accounting/types'
import {Instrument} from '~/store/instrument/types'
import InstrumentActivityRecordBuySell from './RecordBuySell'
import InstrumentActivityRecordCorporateAction from './RecordCorporateAction'
import InstrumentActivityRecordCorporateActionV2 from './RecordCorporateActionV2'
import InstrumentActivityRecordDividend from './RecordDividend'
import InstrumentActivityRecordShareSchemeAlignmentRightAllocation from './RecordShareSchemeAlignmentRightAllocation'
import InstrumentActivityRecordShareSchemeAllocation from './RecordShareSchemeAllocation'
import InstrumentActivityRecordShareSchemeRightAllocation from './RecordShareSchemeRightAllocation'
import InstrumentActivityRecordTransfer from './RecordTransfer'

interface InstrumentActivityRecordProps {
    order: Order
    instrument: Instrument
}

const InstrumentActivityRecord: React.FC<InstrumentActivityRecordProps> = React.memo(({order, instrument}) => {
    switch (order.type) {
        case 'buy':
        case 'sell':
            return <InstrumentActivityRecordBuySell order={order} instrument={instrument} />
        case 'dividend':
            return <InstrumentActivityRecordDividend order={order} instrument={instrument} />
        case 'corporate_action':
            return <InstrumentActivityRecordCorporateAction order={order} instrument={instrument} />
        case 'corporate_action_v2':
            return <InstrumentActivityRecordCorporateActionV2 order={order} instrument={instrument} />
        case 'transfer_out':
        case 'transfer_in':
            return <InstrumentActivityRecordTransfer transfer={order} instrument={instrument} />
        case 'ess_share_allocation_line':
            return <InstrumentActivityRecordShareSchemeAllocation allocationLine={order} instrument={instrument} />
        case 'ess_share_right_allocation_line':
            return <InstrumentActivityRecordShareSchemeRightAllocation allocationLine={order} instrument={instrument} />
        case 'ess_alignment_right_allocation_line':
            return (
                <InstrumentActivityRecordShareSchemeAlignmentRightAllocation
                    allocationLine={order}
                    instrument={instrument}
                />
            )
        default:
            assertNever(order)
            return null
    }
})

export default InstrumentActivityRecord
