import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal'
import {withFormik, InjectedFormikProps} from 'formik'
import React from 'react'
import {spacing, page} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {validate} from '~/global/widgets/form-controls'
import {Checkbox} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import SignupToolbar from '~/sections/user/sections/sign-up/widgets/signup-toolbar/SignupToolbar'
import KidsTermsAndConditions from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/KidsTermsAndConditions'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'

export const DEPENDENT_DECLARATION_VERSION = 1
export type DEPENDENT_DECLARATION_VERSION = typeof DEPENDENT_DECLARATION_VERSION

type FormProps = Pick<StateProps & DispatchProps, 'acceptDependentConditions' | 'preferredName'>

interface FormValues {
    agree: boolean
}

const Form = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => ({agree: false}),
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        agree: undefined,
    }),
    handleSubmit: async ({agree}, {setSubmitting, setStatus, props: {acceptDependentConditions}}) => {
        if (!agree) {
            throw new Error('This should never happen')
        }
        try {
            const error = await acceptDependentConditions(DEPENDENT_DECLARATION_VERSION)
            if (error) {
                setStatus(error)
                setSubmitting(false)
                return
            }
        } catch (e) {
            setStatus(unknownErrorMessage)
            setSubmitting(false)
            throw e
        }
    },
    validate: validate.generate<FormValues>({
        agree: [validate.isTrue('You must agree to the terms')],
    }),
})(
    class Form extends React.Component<InjectedFormikProps<FormProps, FormValues>, {}> {
        render() {
            const {isSubmitting, handleSubmit, isValid, preferredName} = this.props

            return (
                <form onSubmit={handleSubmit}>
                    <Checkbox
                        dataTestId="checkbox--agree-to-terms"
                        name="agree"
                        label={
                            <>
                                <p>I’ve read and agree:</p>
                                <ul className={page.ul}>
                                    <li>
                                        to Sharesies{' '}
                                        <ModalLink
                                            dataTestId="modal-link--kids-account-terms-and-conditions"
                                            label="Kids Accounts Terms and Conditions"
                                            modalTitle="Kids Accounts Terms and Conditions"
                                            bottomBorder
                                        >
                                            <KidsTermsAndConditions />
                                        </ModalLink>
                                    </li>
                                    <li>
                                        investments or money can only be withdrawn for the sole benefit of{' '}
                                        {preferredName}.
                                    </li>
                                    <li>
                                        that when {preferredName} reaches the transfer age they'll be able to request
                                        control of the account and will be able to sell investments and withdraw money.
                                        This age is defaulted to their 25th birthday. You can change this in Account.
                                    </li>
                                </ul>
                            </>
                        }
                    />
                    <Button
                        isSubmit
                        pageButton
                        dataTestId="button--next"
                        label="Next"
                        disabled={!isValid}
                        processing={isSubmitting}
                        additionalClassName={spacing.spaceBelow48}
                    />
                </form>
            )
        }
    },
)

export class DependentDeclaration extends React.PureComponent<StateProps & DispatchProps, {}> {
    public render() {
        const {preferredName} = this.props

        return (
            <>
                <SignupToolbar />
                <Page>
                    <h1 className={spacing.spaceBelow16}>Legal stuff for {preferredName}’s account</h1>
                    <p className={spacing.spaceBelow16}>
                        Kids Accounts work a little differently to adult accounts—you’ll need to agree to the below to
                        create one.
                    </p>
                    <Form
                        acceptDependentConditions={this.props.acceptDependentConditions}
                        preferredName={preferredName}
                    />
                </Page>
            </>
        )
    }
}

interface StateProps {
    preferredName: string
}

interface DispatchProps {
    acceptDependentConditions: (version: typeof DEPENDENT_DECLARATION_VERSION) => Promise<string | undefined>
}

export default connect<StateProps, DispatchProps>(
    ({identity}) => ({
        preferredName: identity.user ? identity.user.preferred_name : '',
    }),
    dispatch => ({
        acceptDependentConditions: version => dispatch(actions.acceptDependentConditions(version)),
    }),
)(DependentDeclaration)
