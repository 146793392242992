import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {rakaiaApi, Middleware} from '~/api/rakaia'
import * as rollbar from '~/api/rollbar/rollbar'

/**
 * Factory for Tanstack Query's {@link https://tanstack.com/query/latest/docs/react/reference/useQuery useQuery}
 * for use with Rakaia, with sensible default options and type safety.
 *
 * This factory should be called from a custom hook which you then call in the top level of a component.
 * Set it up as below and you can access the data returned immediately as loading and error states
 * are handled by the suspense context and error boundary.
 *
 * First set up the custom hook:
 * ```
 * export const useRakaiaReadPortfolio = rakaiaGetFactory({
 *   apiFunctionName: 'readPortfolioApiV1PortfoliosPortfolioUuidGet',
 *   notFoundResponse: ({portfolioUuid}) => ({
 *       uuid: portfolioUuid,
 *       currency: Currency.NZD,
 *       simple_return: 0,
 *       portfolio_value: 0,
 *   }),
 * })
 * ```
 *
 * Then use it in your component:
 * ```
 * const {data: myVariableName} = useRakaiaReadPortfolio({portfolioUuid: customer.portfolio_id})
 * ```
 *
 * Alternatively you can use a pattern where you do some post-processing on the data in your
 * custom hook, or pre-processing on the passed in values:
 *
 * ```
 * export const useRakaiaReadPortfolio = (portfolioUuid: string) => { // typing must be manually added
 *     const {data} = rakaiaGetFactory({
 *         apiFunctionName: 'readPortfolioApiV1PortfoliosPortfolioUuidGet',
 *     })({
 *         portfolioUuid, // just passed straight through here, but you could perform transforms as needed
 *     })
 *     // post processing on data could be added here before returning the mutated data
 *     return data
 * }
 * ```
 *
 * When the data is returned directly like this (without other tanstack query returns) you use it
 * directly in your component like so:
 *
 * ```
 * const returns = useRakaiaReadPortfolio(customer.portfolio_id)
 * ```
 *
 * You can also pass an `options` object if you want to customise any of the underlying useQuery's
 * behaviour, for example if your get request need custom error handling you can set `useErrorBoundary`
 * to false, wrap the rakaiaGetFactory in a try catch and use the returned `error` as you need.
 */
export const rakaiaGetFactory = <
    MethodName extends keyof typeof rakaiaApi.portfoliosApi,
    Params extends Exclude<Parameters<(typeof rakaiaApi.portfoliosApi)[MethodName]>[0], Middleware>,
    Response extends Awaited<ReturnType<(typeof rakaiaApi.portfoliosApi)[MethodName]>>,
    ApiFunction extends (params: Params) => Promise<Response>, // manually type this so we don't get interference from the BaseAPI type
    NotFoundResponse extends Awaited<Response>,
    Options extends Omit<UseQueryOptions<Response | NotFoundResponse>, 'queryFn'>, // exclude options that shouldn't be set
>(config: {
    apiFunctionName: MethodName
    options?: Options
    notFoundResponse?: (request: Params) => NotFoundResponse
}) => {
    return (params: Params) => {
        // the below breaks the rules of hooks as useQuery can't normally be called in a callback, but as this factory ALWAYS returns a hook we're safe to break the rules
        const useQueryResult = useQuery({
            suspense: true, // default to true but allow override if passed
            useErrorBoundary: true, // the vast majority of GET requests don't need bespoke error handling and just need the generic 'try again' page when the normal retry behaviour fails. this is overridable via options
            networkMode: 'offlineFirst', // we want to ensure tanstack always tries to make a query even if the browser says it's offline, this will cause a 'proper' fail and error message so our assertion that data always exists is true, see https://github.com/TanStack/query/discussions/4858
            queryKey: [config.apiFunctionName, params],
            cacheTime: 6 * 60 * 1000, // 6 minutes
            staleTime: 5 * 60 * 1000, // 5 minutes
            ...config.options,
            queryFn: () => {
                const apiFunction = rakaiaApi.portfoliosApi[config.apiFunctionName].bind(
                    rakaiaApi.portfoliosApi,
                ) as ApiFunction

                return apiFunction(params).catch((error: Error | Response) => {
                    if (error instanceof Response && error.status === 404 && config.notFoundResponse) {
                        return config.notFoundResponse(params)
                    } else {
                        if (error instanceof Response && error.status === 500) {
                            // for 500s, record to Rollbar
                            rollbar.sendError(`Failed XHR request: 'internal_server_error'`, {
                                url: config.apiFunctionName,
                                method: 'GET',
                            })
                        }
                        throw error // will fall to the error boundary unless options.useErrorBoundary is set to false, in which case you'll need to wrap your useDisillQuery in a try catch
                    }
                })
            },
        })
        return {
            ...useQueryResult,
            // Because we use suspense, we can assert that the data isn't undefined.
            // see the discussion at https://github.com/TanStack/query/issues/1297 -
            // this wouldn't be true for some edge cases like calling queryClient.cancelQueries
            // but we can just avoid that edge case.
            // NOTE if you pass `options.enabled: false` it WILL be undefined, regardless
            data: useQueryResult.data!,
        }
    }
}
