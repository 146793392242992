import {Document} from '@contentful/rich-text-types'
import React from 'react'
import {RichText} from '~/sections/explore/sections/learn-articles/widgets/rich-text/RichText'
import W8BenCertification from '~/sections/user/sections/terms-and-conditions/assets/contentful-entries/w8-ben-certification.json'
import {legalDoc} from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/LegalDocuments.scss'

export default () => (
    <div className={legalDoc}>
        <RichText richTextDocument={W8BenCertification.fields.content as Document} />
    </div>
)
