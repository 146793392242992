import {Button} from '@design-system/button'
import {withFormik} from 'formik'
import React from 'react'
import {urlFor} from '~/global/routeGenerator'
import {spacing} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {PromiseUnwrap} from '~/global/utils/type-utilities/typeUtilities'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import {validate, ErrorBox} from '~/global/widgets/form-controls'
import {Email} from '~/global/widgets/form-controls/formik'
import HelpEmail from '~/global/widgets/help-email/HelpEmail'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'

interface FormValues {
    email: string
}

interface FormProps {
    setTooManyAttempts: () => void
}

const Form = withRouter(
    withFormik<StoreProps & DispatchProps & FormProps & WithRouterProps, FormValues>({
        mapPropsToValues: ({initialEmail}) => ({email: initialEmail || ''}),
        mapPropsToErrors: ({initialEmail}) => {
            // make the button disabled initially if there is an initial error by setting at least one field to have an error
            if (!initialEmail) {
                return {email: undefined}
            }
            return {}
        },
        handleSubmit: async (
            {email},
            {
                setSubmitting,
                setStatus,
                props: {
                    forgotPassword,
                    setTooManyAttempts,
                    router: {navigate},
                },
            },
        ) => {
            try {
                const response = await forgotPassword(email)
                switch (response) {
                    case 'success':
                        navigate(urlFor('forgot-password/message'))
                        break
                    case 'maximum_failed_sms_attempts':
                        setTooManyAttempts()
                        setStatus('Password resets on this account are not permitted. Please contact support.')
                        setSubmitting(false)
                        break
                    default:
                        assertNever(response)
                }
            } catch (e) {
                setStatus(unknownErrorMessage)
                setSubmitting(false)
                throw e
            }
        },
        validate: validate.generate<FormValues>({
            email: [validate.required(), validate.email()],
        }),
    })(({handleSubmit, status, isSubmitting, isValid, initialEmail}) => (
        <form onSubmit={handleSubmit} key={initialEmail}>
            <Email
                dataTestId="forgot-password-email-input"
                name="email"
                label="Email"
                autoFocus
                disabled={isSubmitting}
            />
            <ErrorBox message={status} />
            <Button
                dataTestId="password-reset"
                label="Send reset email"
                disabled={!isValid}
                processing={isSubmitting}
                isSubmit
                pageButton
            />
        </form>
    )),
)

interface StoreProps {
    initialEmail: string
}

interface DispatchProps {
    forgotPassword(email: string): ReturnType<ReturnType<typeof actions.ForgotPassword>>
}

export const ForgotPassword: React.FunctionComponent<StoreProps & DispatchProps> = React.memo(props => {
    const navigate = useNavigate()
    const [status, setStatus] = React.useState<
        'email_input' | PromiseUnwrap<ReturnType<DispatchProps['forgotPassword']>>
    >('email_input')
    const setTooManyAttempts = React.useCallback(() => setStatus('maximum_failed_sms_attempts'), [setStatus])

    const renderContents = () => {
        if (status === 'maximum_failed_sms_attempts') {
            return (
                <>
                    <p className={spacing.spaceBelow16}>
                        Your password cannot be reset because of too many incorrect attempts.
                    </p>
                    <p>
                        For help with your password get in touch at <HelpEmail />.
                    </p>
                    <Button
                        label="Go back to login"
                        pageButton
                        onClick={() => {
                            navigate(urlFor('login'))
                        }}
                        dataTestId="button--back-to-login"
                    />
                </>
            )
        }

        return (
            <>
                <p className={spacing.spaceBelow24}>
                    Let us know the email you use to sign in to Sharesies and we'll help you sort your password out.
                </p>
                <Form {...props} setTooManyAttempts={setTooManyAttempts} key={props.initialEmail} />
            </>
        )
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--forgot-password"
                leftButton="back"
                title="Forgot password"
                onLeftButtonClick={() => {
                    sendWrapperAppMessage({type: 'goBack'})
                    navigate(-1)
                }}
            />

            <Page>{renderContents()}</Page>
        </>
    )
})

export const ConnectedForgotPassword = connect<StoreProps, DispatchProps, {}>(
    ({identity}) => ({
        initialEmail: identity.user ? identity.user.email || '' : '',
    }),
    dispatch => ({
        forgotPassword: email => dispatch(actions.ForgotPassword(email)),
    }),
)(ForgotPassword)

export default ConnectedForgotPassword
