import React from 'react'
import {useNextDividend} from '~/global/state-hooks/retail/useAnnouncedCorporateActions'
import {convertDateTime} from '~/global/utils/convert-date-time/convertDateTime'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import HighlightBlock from '~/global/widgets/highlight-block/HighlightBlock'
import {Instrument} from '~/store/instrument/types'
import styles from './UpcomingDividendInformation.scss'

const UpcomingDividendInformationSuspended: React.FC<{
    verb: 'buy' | 'sell'
    instrument: Instrument
}> = ({verb, instrument}) => {
    const nextDividend = useNextDividend(instrument)

    const timeZone = (instrument: Instrument): string => {
        switch (instrument.exchangeCountry) {
            case 'nzl':
                return '(New Zealand time)'
            case 'usa':
                return '(American eastern time)'
            case 'aus':
                return '(Australian eastern time)'
            default:
                throw new Error(`Invalid exchangeCountry: ${instrument.exchangeCountry}`)
        }
    }

    return (
        <>
            {nextDividend && (
                <HighlightBlock
                    className={styles.upcomingDividend}
                    dataTestId="highlight-block--upcoming-dividend-information"
                >
                    <p>
                        {instrument.name} is paying a dividend on {convertDateTime(nextDividend.settlement_date)}{' '}
                        {timeZone(instrument)}.{' '}
                        {verb === 'buy' &&
                            `To receive a dividend for the ${shareLabel({
                                instrument,
                                isPlural: true,
                            })} you’re buying, your order will need to fill before ${convertDateTime(
                                nextDividend.ex_date,
                            )} ${timeZone(instrument)}.`}
                        {verb === 'sell' &&
                            `If your order fills before ${convertDateTime(nextDividend.ex_date)} ${timeZone(
                                instrument,
                            )}, you won't receive a dividend for the ${shareLabel({
                                instrument,
                                isPlural: true,
                            })} you’re selling.`}
                    </p>
                </HighlightBlock>
            )}
        </>
    )
}

const UpcomingDividendInformation: React.FC<{
    verb: 'buy' | 'sell'
    instrument: Instrument
}> = ({verb, instrument}) => (
    <React.Suspense fallback={null}>
        <UpcomingDividendInformationSuspended verb={verb} instrument={instrument} />
    </React.Suspense>
)

export default UpcomingDividendInformation
