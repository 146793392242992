import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import TabControls from '~/global/widgets/tab-controls/TabControls'
import commonStyles from '~/sections/save/common.scss'
import {useRecurringTransfers} from '../../state/transferState'
import RecurringTransfers from './widgets/recurring-transfers/RecurringTransfers'
import TransactionHistory from './widgets/transaction-history/TransactionHistory'
import styles from './Activity.scss'

interface TransactionProps {
    portfolioId: string
}

const Activity: React.FunctionComponent<TransactionProps> = ({portfolioId}) => {
    const recurringTransfers = useRecurringTransfers(portfolioId)

    const [currentTab, setCurrentTab] = React.useState('History')
    const upcomingLabel = recurringTransfers.length > 0 ? `Upcoming (${recurringTransfers.length})` : 'Upcoming'

    const handleChangeTab = (tabValue: 'History' | 'Upcoming') => {
        if (tabValue !== currentTab) {
            setCurrentTab(tabValue)
        }
    }

    return (
        <>
            <div className={styles.tabWrapper}>
                <h2 className={cn(styles.transactionsHeading, spacing.spaceBelow24)}>Transactions</h2>
                <TabControls
                    tabs={[
                        {label: 'History', value: 'History'},
                        {label: upcomingLabel, value: 'Upcoming'},
                    ]}
                    currentTab={currentTab}
                    onChangeTab={handleChangeTab}
                />
            </div>

            <div className={commonStyles.saveActivity}>
                {currentTab === 'History' ? (
                    <TransactionHistory portfolioId={portfolioId} />
                ) : (
                    <RecurringTransfers portfolioId={portfolioId} />
                )}
            </div>
        </>
    )
}

export default Activity
