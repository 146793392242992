import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {useDispatch} from 'react-redux'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {DotBig} from '~/global/widgets/OLD_icons'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import Welcome from '~/sections/user/sections/sign-up/pages/welcome/Welcome'
import {useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import actions from '~/store/nav/actions'
import styles from './WelcomeDependent.scss'

const slides = (preferredName: string) => {
    return [
        <div key="1">
            <h2>Before you start investing for {preferredName} we’ve got a few tips for you...</h2>
        </div>,
        <div key="2">
            <h2>Check out the best investments for kids</h2>
            <p>
                You’ll have access to all of the investments on Sharesies. We’ve highlighted the funds that are the most
                tax efficient for kids.
            </p>
        </div>,
        <div key="3">
            <h2>Confirm {preferredName} is on the correct tax rate</h2>
            <p>
                Check {preferredName} is on the correct PIR rate under Account. Most kiwi kids who have no income other
                than Sharesies will have a PIR of 10.5%.
            </p>
        </div>,
    ]
}

const WelcomeDependent: React.FunctionComponent<{}> = React.memo(() => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)
    const [currentSlide, setCurrentSlide] = React.useState(0)
    const [slickSliderIsAnimating, setSlickSliderIsAnimating] = React.useState(false)
    const [showWelcome, setShowWelcome] = React.useState(false)

    React.useEffect(() => {
        dispatch(actions.HideFlyoutMenu())

        return () => {
            dispatch(actions.ShowFlyoutMenu())
        }
    }, [])

    React.useEffect(() => {
        // Make rudderstack tracking call on initial welcome page render
        rudderTrack('signup', 'welcome_page_hit')
    }, [])

    React.useEffect(() => {
        if (showWelcome) {
            dispatch(identityActions.MarkHasSeenFlag('welcome_screens'))
            // Refresh the api request to get the latest customer_signup_state
            dispatch(identityActions.Check())
        }
    }, [showWelcome])

    if (showWelcome) {
        return <Welcome />
    }

    const NextButton = (props: any) => {
        // feeding in onClick to make the 'Next' button functionality work correctly
        let {onClick} = props

        if (currentSlide === source.length - 1) {
            // Need to change Next button click handler to setShowWelcome so we can render Welcome splash message
            onClick = (_e: Event) => {
                setShowWelcome(true)
            }
        }

        return (
            <div className={styles.nextButton}>
                <Button dataTestId="button--next" type="secondary" label="Next" onClick={onClick} />
            </div>
        )
    }

    const source = slides(preferredName)

    const PrevButton = () => <div className={styles.prevButton} />

    const swipeSettings = {
        dots: true,
        customPaging: (index: number) => {
            return <DotBig key={index} className={cn({[styles.selected]: index === currentSlide})} />
        },
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextButton />,
        prevArrow: <PrevButton />,
        beforeChange() {
            setSlickSliderIsAnimating(true)
        },
        afterChange: (current: number) => {
            setCurrentSlide(current)
            setSlickSliderIsAnimating(false)
        },
    }

    if (preferredName === undefined) {
        navigate('/')
        return <div />
    }

    const renderStep = (): React.ReactNode => {
        return (
            <div className={cn(styles.swipeableArea, {[styles.slickSliderIsAnimating]: slickSliderIsAnimating})}>
                <Slider {...swipeSettings}>{source}</Slider>
            </div>
        )
    }

    return (
        <>
            <div className={styles.toolbar}>
                <Toolbar dataTestId="toolbar--welcome-dependent" isConstantWhite />
            </div>
            <Page className={styles.container}>{renderStep()}</Page>
        </>
    )
})

export default WelcomeDependent
