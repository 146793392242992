import React from 'react'
import {Navigate, Outlet, useNavigate} from 'react-router-dom'
import NotFound from '~/global/pages/not-found/NotFound'
import {isLoadingOrUninitialised, isUninitialised} from '~/global/utils/is-loading/isLoading'
import {CheckableRoute} from '~/global/utils/routing/routing'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Loading} from '~/global/widgets/loading/Loading'
import BankLinkingPending from '~/sections/invest/sections/bank-linking/pages/pending/BankLinkingPending'
import {
    SelectDebitBankAccount,
    DebitAccountConfirmed,
    ChooseNotification,
} from '~/sections/invest/sections/bank-linking/sections/roundups-setup/pages'
import BankLinkingConsent from '~/sections/invest/sections/bank-linking/sections/roundups-setup/pages/BankLinkingConsent'
import actions from '~/store/bankLinking/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {linkedBankHandle, useLinkedBankPaymentsUrl} from './hooks/useLinkedBankPaymentsUrl'
import {Intro} from './pages/intro/Intro'
import WalletLinkedBankTopUp from './pages/one-off-topup/OneOffTopUp'
import {SetUpRecurringTopUp} from './pages/setup-recurring-topup/SetUpRecurringTopUp'
import {TopUpType} from './pages/topup-type/TopUpType'
import {RedirectBankLinked} from './widgets/redirect-bank-linked/RedirectBankLinked'

const LinkedBankPaymentsWrapper: React.FunctionComponent<{}> = () => {
    const profileUrl = useProfileUrl()

    // Wrapper to guard feature flag / jurisdiction,
    // and fetch relevant bank linking data
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)

    const dispatch = useAppDispatch()
    const {bankLinkingLoadingState, bankLinkingPending} = useAppSelector(s => ({
        bankLinkingLoadingState: s.bankLinking.bankLinkingLoadingState,
        bankLinkingPending: s.bankLinking.bankLinkingPending,
    }))

    React.useEffect(() => {
        if (isUninitialised(bankLinkingLoadingState)) {
            dispatch(actions.FetchBankLinkingStatus())
        }
    }, [])

    // The 'bouncer'
    if (jurisdiction !== 'au') {
        return <Navigate to={profileUrl('explore')} replace />
    }

    if (isLoadingOrUninitialised(bankLinkingLoadingState)) {
        return <Loading isPineapple />
    }

    if (bankLinkingPending?.code) {
        return <BankLinkingPending />
    }

    return <Outlet />
}

const LinkedGuard = () => {
    // Guard for routes that require linked when customer is unlinked
    const isBankLinked = useAppSelector(s => s.bankLinking.isBankLinked)
    const relativeUrlFor = useLinkedBankPaymentsUrl()
    if (!isBankLinked) {
        return <Navigate to={relativeUrlFor('intro')} replace />
    }
    return <Outlet />
}
const UnlinkedGuard = () => {
    // Guard for routes that require unlinked when customer is linked
    const isBankLinked = useAppSelector(s => s.bankLinking.isBankLinked)
    const relativeUrlFor = useLinkedBankPaymentsUrl()
    if (isBankLinked) {
        return <Navigate to={relativeUrlFor('select-top-up-type')} replace />
    }
    return <Outlet />
}

export const LINKED_BANK_PAYMENTS_ROUTES = [
    {
        element: <LinkedBankPaymentsWrapper />, // Routes related to setting up a recurring top-up (and bank linking if necessary)
        handle: linkedBankHandle,
        children: [
            {
                index: true,
                Component: RedirectBankLinked,
            },
            {
                element: <UnlinkedGuard />,
                children: [
                    {path: 'intro', Component: () => <Intro />},
                    {path: 'link-your-bank', Component: BankLinkingConsent},
                ],
            },
            {
                element: <LinkedGuard />,
                children: [
                    {
                        path: 'select-debit-account',
                        Component() {
                            const navigate = useNavigate()
                            const profileUrl = useProfileUrl()
                            const paymentsUrl = useLinkedBankPaymentsUrl()
                            return (
                                <SelectDebitBankAccount
                                    onNext={() => navigate(paymentsUrl('banklinking-confirmation'))}
                                    onBack={() =>
                                        // Exit the feature entirely, and don't allow any going back to basiq.
                                        navigate(
                                            location.pathname.includes('explore')
                                                ? profileUrl('wallet')
                                                : profileUrl('explore'),
                                        )
                                    }
                                />
                            )
                        },
                    },
                    {
                        path: 'banklinking-confirmation',
                        Component() {
                            const navigate = useNavigate()
                            const relativeUrlFor = useLinkedBankPaymentsUrl()
                            return (
                                <DebitAccountConfirmed
                                    onNext={() => navigate(relativeUrlFor('select-top-up-type'))}
                                    onBack={() => navigate(-1)}
                                />
                            )
                        },
                    },
                    {
                        path: 'notifications',
                        Component: () => {
                            const navigate = useNavigate()
                            return <ChooseNotification onNext={() => navigate(-1)} onBack={() => navigate(-1)} />
                        },
                    },
                    {
                        path: 'select-top-up-type',
                        Component: TopUpType,
                    },
                    {
                        path: 'one-off',
                        Component: WalletLinkedBankTopUp,
                    },
                    {
                        path: 'recurring',
                        Component: SetUpRecurringTopUp,
                    },
                ],
            },
            {path: '*', Component: NotFound},
        ],
    },
] as const

LINKED_BANK_PAYMENTS_ROUTES satisfies readonly CheckableRoute[]
