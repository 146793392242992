import {useQuery, useQueryClient} from '@tanstack/react-query'
import {distillApiNewClientToken, ExchangeDto, InstrumentsInfoResponseDto} from '~/api/distill'
import {DistillScope} from '~/api/query/distill'
import useDistillInstrumentInfo from '~/global/state-hooks/distill/useDistillInstrumentInfo'
import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {BasicInstrument, DetailInstrument, Instrument} from '~/store/instrument/types'

const ONE_MINUTE_IN_MS = 60 * 1000
const TWO_MINUTES_IN_MS = 2 * 60 * 1000

const DEFAULT_CACHE_STALE_OPTION = {
    cacheTime: TWO_MINUTES_IN_MS,
    staleTime: ONE_MINUTE_IN_MS,
}

export const useDistillSearch = (): BasicInstrument[] => {
    const {data} = useQuery({
        suspense: true,
        ...DEFAULT_CACHE_STALE_OPTION,
        queryKey: ['useDistillSearchMoose'],
        queryFn: async () => {
            const response = await distillApiNewClientToken.instrumentsApiNewClientToken.apiV1InstrumentsSearchV2Get({
                scope: DistillScope.FONTERRA,
                query: '',
            })
            return response.instruments
        },
    })

    // This ensures we don't load instruments we've already seen in useDistillInstrument
    const queryClient = useQueryClient()
    for (const instrument of data!) {
        queryClient.setQueryData(['useDistillInstrument', instrument.id], instrument)
        queryClient.setQueryData(['useDistillInstrumentBySlug', instrument.urlSlug], instrument)
    }

    return data!
}

export const useDistillInstrument = (instrumentId: string): BasicInstrument => {
    const instruments = useDistillSearch()
    return instruments.find(instrument => instrument.id === instrumentId)!
}

export const useDistillInstrumentBySlug = (instrumentUrlSlug: string): DetailInstrument => {
    const {data} = useQuery({
        suspense: true,
        ...DEFAULT_CACHE_STALE_OPTION,
        queryKey: ['useDistillInstrumentBySlug', instrumentUrlSlug],
        queryFn: async () => {
            const response =
                await distillApiNewClientToken.instrumentsApiNewClientToken.apiV1InstrumentsGetbyurlslugV2UrlSlugGet({
                    scope: DistillScope.FONTERRA,
                    urlSlug: instrumentUrlSlug,
                })

            if (response === undefined) {
                throw new Error(`Distill instrument with URL slug ${instrumentUrlSlug} was not found in Distill`)
            }

            return response
        },
    })

    return data!
}

export const useDistillInstrumentWithCurrency = (instrumentSlug: string) => {
    const instrument: DetailInstrument = useDistillInstrumentBySlug(instrumentSlug)
    const instrumentMetadata: InstrumentsInfoResponseDto = useDistillInstrumentInfo(DistillScope.FONTERRA)

    const exchangeInfo = instrumentMetadata.exchanges.find(
        (exchange: ExchangeDto) => instrument.exchange === exchange.name,
    )
    const currency = exchangeInfo?.currency ?? 'nzd'
    const instrumentWithCurrency: Instrument = {
        currency: currency as Currency,
        ...instrument,
    }
    return instrumentWithCurrency
}
