import cn from 'classnames'
import React from 'react'
import styles from '../form.scss'

interface Props {
    message?: React.ReactNode
    className?: string
}

const ErrorBox = ({message, className}: Props) =>
    message ? (
        <div data-testid="error-box" className={cn(styles.errorBox, className)}>
            {message}
        </div>
    ) : null

export default ErrorBox
