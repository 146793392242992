import {PortfolioHistoryItem, InstrumentInvestingHistoryDetails} from 'src/store/portfolio/types'

export interface PortfolioHistoryItemWithIndex extends PortfolioHistoryItem {
    index: number
}

export const slicePortfolioHistory = (
    portfolioHistory: PortfolioHistoryItem[],
    firstDate: string,
): PortfolioHistoryItemWithIndex[] => {
    const sliceIndex = portfolioHistory.findIndex(f => f.date === firstDate)
    if (sliceIndex !== -1) {
        portfolioHistory = portfolioHistory.slice(sliceIndex)
    }

    return portfolioHistory.map((f, i) => ({...f, index: i}))
}

export interface InstrumentInvestingHistoryWithIndex extends InstrumentInvestingHistoryDetails {
    index: number
}

// TODO create one sliceHistory function to combine Portfolio and
// Instrument History
export const sliceInstrumentHistory = (
    history: InstrumentInvestingHistoryDetails[],
    firstDate: string,
): InstrumentInvestingHistoryWithIndex[] => {
    const sliceIndex = history.findIndex((f: InstrumentInvestingHistoryDetails) => f.date === firstDate)
    if (sliceIndex !== -1) {
        history = history.slice(sliceIndex)
    }

    return history.map((f, i) => ({...f, index: i}))
}
