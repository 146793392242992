import {Button} from '@design-system/button'
import cn from 'classnames'
import {withFormik} from 'formik'
import React from 'react'
import {Model} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {page} from '~/global/scss/helpers'
import calculateWalletBalance from '~/global/utils/calculate-wallet-balance/calculateWalletBalance'
import {exceedsLowPricedSecurityCap} from '~/global/utils/exceeds-low-priced-security-cap/exceedsLowPricedSecurityCap'
import {autoExerciseBuyCostBreakdown} from '~/global/utils/exercise-cost/exerciseCost'
import {isDisorderlyLimit} from '~/global/utils/is-disorderly-limit/isDisorderlyLimit'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import WalletBalanceWrapper from '~/global/widgets/estimated-wallet-balance/WalletBalanceWrapper'
import {StrongCurrency} from '~/global/widgets/form-controls/formik'
import {isNavigationDirective, Link} from '~/migrate-react-router'
import styles from '~/sections/invest/sections/order-flow/OrderForm.scss'
import ExerciseCostBreakdown from '~/sections/invest/sections/order-flow/sections/buy/widgets/auto-exercise/ExerciseCostBreakdown'
import {canMakeAutoExerciseBuyOrder} from '~/store/accounting/selectors'
import {State} from '~/store/accounting/types'
import {connect} from '~/store/connect'
import {useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import actions from '~/store/order/actions'
import {StagedBuyOrder} from '~/store/order/types'
import {UnwrapThunkAction} from '~/store/types'

interface LimitBuyInDollarsFormValues {
    priceLimit: string
    currencyAmount: string
}

const LimitBuyInDollarsForm = withRouter(
    withFormik<LimitBuyInDollarsFormProps & WithRouterProps, LimitBuyInDollarsFormValues>({
        mapPropsToValues: ({stagedBuyOrder}) => {
            if (!stagedBuyOrder) {
                return {
                    priceLimit: '',
                    currencyAmount: '',
                }
            }
            return {
                priceLimit: stagedBuyOrder.orderPriceLimit ? stagedBuyOrder.orderPriceLimit : '',
                currencyAmount: stagedBuyOrder.orderCurrencyAmount ? stagedBuyOrder.orderCurrencyAmount : '',
            }
        },
        mapPropsToErrors: ({stagedBuyOrder}) => {
            // make the button disabled initially if there is an initial error by setting at least one field to have an error
            if (!stagedBuyOrder || !(stagedBuyOrder.orderPriceLimit && stagedBuyOrder.orderCurrencyAmount)) {
                return {priceLimit: undefined}
            }
            // if there is an existing amount that got as far as stagedOrder, assume it is valid
            return {}
        },
        validate: async (values, {instrument}) => {
            const errors: {
                priceLimit?: string
                currencyAmount?: string
            } = {}

            if (!values.priceLimit || Number(values.priceLimit) === 0) {
                errors.priceLimit = '' // we don't actually display an error
            }
            const {isDisorderly, message} = isDisorderlyLimit(values.priceLimit, instrument)
            if (isDisorderly && message) {
                errors.priceLimit = message
            }

            if (!values.currencyAmount || Number(values.currencyAmount) === 0) {
                errors.currencyAmount = '' // we don't actually display an error
            }
            // Note: not having enough money in your wallet is *not* an error

            return errors
        },

        handleSubmit: async (
            {priceLimit, currencyAmount},
            {
                setSubmitting,
                props: {
                    stagedBuyOrder,
                    updateStagedBuyOrder,
                    costStagedBuyOrder,
                    router: {navigate},
                    profileUrl,
                },
            },
        ) => {
            if (!stagedBuyOrder) {
                return
            }

            rudderTrack('buy', 'order_details_entered', {instrument_id: stagedBuyOrder.fundId})

            setSubmitting(true)

            try {
                updateStagedBuyOrder({
                    ...stagedBuyOrder,
                    orderPriceLimit: priceLimit,
                    orderCurrencyAmount: currencyAmount,
                })
                const response = await costStagedBuyOrder()
                if (isNavigationDirective(response)) {
                    response.execute(navigate, profileUrl)
                }
            } catch (error) {
                setSubmitting(false)
            }
        },
    })(
        ({
            values,
            handleBlur,
            handleSubmit,
            instrument,
            isValid,
            isSubmitting,
            walletBalances,
            exchangeRates,
            exchangeFeeRate,
            usEquitiesEnabled,
            updateStagedBuyOrder,
            stagedBuyOrder,
            setSubmitting,
            accountRestricted,
            canMakeAutoExerciseBuy,
        }) => {
            const profileUrl = useProfileUrl()

            React.useEffect(() => {
                if (stagedBuyOrder && stagedBuyOrder.error) {
                    setSubmitting(false)
                }
            }, [stagedBuyOrder])

            const jurisdiction = useAppSelector(({identity}) => identity.user!.jurisdiction)

            const exceedsLowPriceLimit = React.useMemo(() => {
                const {exceedsLimit, msg} = exceedsLowPricedSecurityCap(
                    instrument,
                    undefined,
                    values.currencyAmount,
                    values.priceLimit,
                    undefined,
                )

                if (exceedsLimit && msg) {
                    return {exceedsLimit, msg}
                }
            }, [instrument, values])

            const isRemainingBalanceNegative = React.useMemo(() => {
                if (!walletBalances) {
                    return false
                }

                const walletBalanceInDisplayCurrency = calculateWalletBalance(
                    walletBalances,
                    exchangeRates,
                    usEquitiesEnabled,
                    instrument.currency,
                    exchangeFeeRate,
                )

                return walletBalanceInDisplayCurrency
                    ? parseFloat(walletBalanceInDisplayCurrency) - parseFloat(values.currencyAmount) < 0
                    : false
            }, [walletBalances, exchangeRates, usEquitiesEnabled, instrument.currency, values])

            // exercising of rights, shows a breakdown of the cost to exercise either now or later
            const showExerciseCostBreakdown =
                canMakeAutoExerciseBuy && stagedBuyOrder && values.currencyAmount && values.priceLimit

            // an order amount of e.g. $0.01 will not leave enough funds to auto exercise the purchased rights, ignore potential fees
            const isAutoExerciseAmountInvalid = React.useMemo(() => {
                if (
                    stagedBuyOrder &&
                    stagedBuyOrder.autoExercise &&
                    parseFloat(values.currencyAmount) > 0 &&
                    parseFloat(values.priceLimit) > 0
                ) {
                    const autoExerciseCostBreakdown = autoExerciseBuyCostBreakdown(
                        instrument,
                        values.currencyAmount,
                        jurisdiction,
                        values.priceLimit,
                    )
                    return !autoExerciseCostBreakdown
                }
                return false
            }, [stagedBuyOrder, values])

            const preventSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault()
                }
            }

            const handleOnBlur = (event: string | React.FocusEvent<unknown>) => {
                if (typeof event === 'string') {
                    return
                }
                handleBlur(event)

                if (!stagedBuyOrder) {
                    return
                }

                // TODO: [DS-636] clean up this logic
                // Hack to prevent loss of focus on the input field while the component re-renders
                setTimeout(() => {
                    // store any user entered values immediately so the user can look at
                    // the market depth page without losing data
                    updateStagedBuyOrder({
                        ...stagedBuyOrder,
                        orderPriceLimit: values.priceLimit,
                        orderCurrencyAmount: values.currencyAmount,
                    })
                }, 0)
            }

            return (
                <>
                    <form onSubmit={handleSubmit}>
                        <StrongCurrency
                            dataTestId="strong-currency--price-limit"
                            name="priceLimit"
                            label={`Highest price to pay per ${shareLabel({instrument})}`}
                            disabled={isSubmitting || accountRestricted}
                            autoFocus
                            optionalAttributes={{
                                onKeyPress: preventSubmit,
                                onBlur: handleOnBlur,
                            }}
                            decimalPlaces={3}
                            currency={instrument.currency}
                        />

                        <StrongCurrency
                            dataTestId="strong-currency--currency-amount"
                            name="currencyAmount"
                            label="Order amount"
                            disabled={isSubmitting || accountRestricted}
                            optionalAttributes={{
                                onKeyPress: preventSubmit,
                                onBlur: handleOnBlur,
                            }}
                            currency={instrument.currency}
                        />

                        {/* Display this first in case people top up their wallet then realise they can only purchase up to the set limit. */}
                        {exceedsLowPriceLimit?.exceedsLimit && (
                            <div className={styles.limitWarning} data-testid="exceeds-low-price-limit">
                                {exceedsLowPriceLimit.msg}
                            </div>
                        )}

                        {isRemainingBalanceNegative && !exceedsLowPriceLimit?.exceedsLimit && (
                            <div className={styles.balanceWarning} data-testid="insufficient-funds">
                                There’s not enough money in your Wallet. <Link to={profileUrl('wallet')}>Top up</Link>
                            </div>
                        )}

                        {isAutoExerciseAmountInvalid &&
                            !isRemainingBalanceNegative &&
                            !exceedsLowPriceLimit?.exceedsLimit && (
                                <div className={styles.autoExerciseAmountWarning}>
                                    This amount isn’t enough to cover the cost to exercise rights—try entering a higher
                                    number.
                                </div>
                            )}

                        {showExerciseCostBreakdown && (
                            <ExerciseCostBreakdown
                                instrument={instrument}
                                orderAmount={values.currencyAmount}
                                stagedBuyOrder={stagedBuyOrder}
                                priceLimit={values.priceLimit}
                                jurisdiction={jurisdiction}
                            />
                        )}

                        <ActionBar className={cn(page.flexRow, styles.formFooter)}>
                            <WalletBalanceWrapper displayCurrency={instrument.currency} exchangeRates={exchangeRates} />
                            <Button
                                dataTestId="button--review"
                                label="Review"
                                disabled={
                                    !isValid ||
                                    isRemainingBalanceNegative ||
                                    accountRestricted ||
                                    isAutoExerciseAmountInvalid ||
                                    exceedsLowPriceLimit?.exceedsLimit
                                }
                                processing={isSubmitting}
                                isSubmit
                            />
                        </ActionBar>
                    </form>
                </>
            )
        },
    ),
)

interface StoreProps {
    walletBalances?: Model.User['wallet_balances']
    usEquitiesEnabled: boolean
    stagedBuyOrder?: StagedBuyOrder
    accountRestricted: boolean
    canMakeAutoExerciseBuy: boolean
}

interface DispatchProps {
    costStagedBuyOrder: UnwrapThunkAction<typeof actions.CostBuyOrder>
    updateStagedBuyOrder(order: StagedBuyOrder): void
}

interface OwnProps {
    instrument: Instrument
    exchangeRates: State['exchangeRates']
    exchangeFeeRate: State['exchangeFeeRate']
    profileUrl: ReturnType<typeof useProfileUrl>
}

type LimitBuyInDollarsFormProps = StoreProps & DispatchProps & OwnProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    (state, {instrument}) => {
        const {order, identity} = state

        return {
            usEquitiesEnabled: identity.user ? identity.user.us_equities_enabled : false,
            walletBalances: identity.user!.wallet_balances,
            stagedBuyOrder: order.stagedBuyOrder,
            accountRestricted: identity.user!.account_restricted,
            canMakeAutoExerciseBuy: canMakeAutoExerciseBuyOrder(state, instrument.id),
        }
    },
    dispatch => ({
        costStagedBuyOrder: () => dispatch(actions.CostBuyOrder()),
        updateStagedBuyOrder: order => dispatch(actions.UpdateStagedBuyOrder(order)),
    }),
)(LimitBuyInDollarsForm)
