import React from 'react'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import {TransactionDetailTypes} from '~/sections/wallet/pages/transaction/utils/transaction-types/transactionTypes'
import {RecordPage, RecordRow} from '~/sections/wallet/pages/transaction/widgets/record/Record'
import styles from '~/sections/wallet/pages/transaction/widgets/record/Record.scss'

type OrderDetail = TransactionDetailTypes['withdrawal_order']['detail']

function statusLabel(order: OrderDetail): string {
    switch (order.state) {
        case 'cancelled':
            return 'Cancelled'
        case 'pending':
        case 'pending_dynamic_checks':
        case 'pending_preprocessing':
            return 'Pending'
        case 'processing':
        case 'ready':
            return 'Processing'
        case 'reconciled':
            return 'Processed'
        default:
            assertNever(order.state)
            return ''
    }
}

export const WithdrawalOrderRecord: React.FunctionComponent<{
    transaction: TransactionDetailTypes['withdrawal_order']
}> = ({transaction}) => {
    const order = transaction.detail
    return (
        <RecordPage
            title={transaction.description} // This is carefully constructed on the server-side to be safe/sensible here
            header={
                <>
                    <div>
                        <h4 className={styles.instrumentName}>Cash</h4>
                        <p className={styles.instrumentSymbol}>{order.currency.toLocaleUpperCase()} cash withdrawal</p>
                    </div>
                </>
            }
            rows={
                <>
                    <RecordRow left="Date requested" right={order.created_at.toFormat(dateFormatWithYear)} />
                    <RecordRow left="Bank account" right={order.bank_account} />
                </>
            }
            amountLabel="Amount"
            amount={order.amount}
            amountCurrency={order.currency}
            statusLabel={statusLabel(order)}
        />
    )
}
