import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import React from 'react'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import FeeModalInfo from './FeeModalInfo'

interface Props {
    jurisdiction: Model.User['jurisdiction']
}

export const ADR = ({jurisdiction}: Props) => {
    return (
        <ModalLink
            dataTestId="modal-link--buy-sell-fees-adrs"
            aria-label="Fees on A D Rs"
            label="Fees on ADRs"
            asIcon
            modalTitle="Fees on ADRs"
            primaryButton={{label: 'Ok'}}
            bottomBorder
            helpIconSize={16}
        >
            <FeeModalInfo jurisdiction={jurisdiction} />
            <h2 className={cn(spacing.spaceBelow12, spacing.spaceAbove32)}>Depositary fees</h2>
            <p>
                When you invest in an American depositary receipt (ADR), you may be charged depositary fees. These are
                charged by the depositary bank that issued the ADR, not Sharesies.
            </p>
            <p>
                The fees cover the depositary bank’s costs for managing the ADR, and are based on how many receipts you
                hold. Generally, the fee is less than $0.10 USD per receipt annually.
            </p>

            <p>The depositary fee can be charged a couple of ways:</p>
            <ul>
                <li>
                    if the ADR pays dividends, the fee will be deducted from any dividends on the ex-dividend date
                    before they’re paid into your Wallet
                </li>
                <li>
                    if the ADR doesn’t pay dividends, the fee will usually be deducted from your Wallet balance during
                    the year.
                </li>
            </ul>
            <p>
                Sometimes, it could be a mix of both—part of the fee deducted from your dividends and the rest deducted
                from your Wallet during the year.
            </p>
            <p>
                For a full run-down of our fees,{' '}
                <a
                    target="_blank"
                    rel="noopener"
                    href={
                        jurisdiction === 'au'
                            ? 'https://www.sharesies.com.au/pricing'
                            : 'https://www.sharesies.nz/pricing'
                    }
                >
                    check out our website
                </a>
                .
            </p>
        </ModalLink>
    )
}
