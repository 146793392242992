import {Button} from '@design-system/button'
import {FileUpload, MutateUploads} from '@design-system/file-upload'
import cn from 'classnames'
import React, {useState} from 'react'
import {spacing} from '~/global/scss/helpers'
import {UserSuitableError} from '~/global/utils/error-handling/errorHandling'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {isUSExchange, isTransferAwaitingDocuments} from '~/global/utils/share-transfers/shareTransfers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useTransferInfo} from '~/global/utils/use-transfer-info/useTransferInfo'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ErrorBox} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import styles from '~/sections/invest/sections/transfer-shares/pages/upload-documents/UploadDocuments.scss'
import {TransferInsufficientFundsModal} from '~/sections/invest/sections/transfer-shares/widgets/transfer-insufficient-funds-modal/TransferInsufficientFundsModal'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/transfer/actions'
import {NEW_DRS_REQUIRED, NEW_SRN_REQUIRED, State, TransferUpload} from '~/store/transfer/types'

type UploadedDocument =
    | State['groupedTransfers'][number]['transfer_order_documents'][number]
    | State['groupedTransfers'][number]['registry_detail_documents'][number]

const isTheSameFile = (newFile: File, oldFile: UploadedDocument) => {
    // This is a rough attempt to avoid re-uploading the same file twice.

    const millisSinceCreated = Math.abs(oldFile.created.diffNow().toMillis())
    if (newFile.name === oldFile.file_name && millisSinceCreated < 60 * 1000) {
        return true
    }

    return false
}

const fileDiff = (newFiles: File[], oldFiles: UploadedDocument[]) => {
    const filesToArchive = []
    for (const oldFile of oldFiles) {
        if (!newFiles.some(file => isTheSameFile(file, oldFile))) {
            filesToArchive.push(oldFile.id)
        }
    }

    const filesToUpload: File[] = []
    for (const newFile of newFiles) {
        if (!oldFiles.find(file => isTheSameFile(newFile, file))) {
            filesToUpload.push(newFile)
        }
    }

    return {filesToUpload, filesToArchive}
}

interface UploadDocumentProps {
    documentName: TransferUpload['type']
    initialFiles?: File[]
    registryDetailId?: string
    groupId: string
    heading: JSX.Element
    setCanSubmit(value: boolean): void
    isMultiple?: boolean
}

const UploadDocument: React.FunctionComponent<UploadDocumentProps> = ({
    documentName,
    initialFiles = [],
    registryDetailId,
    groupId,
    heading,
    setCanSubmit,
    isMultiple,
}) => {
    const dispatch = useAppDispatch()
    const [files, setFiles] = React.useState<File[]>(initialFiles)
    const [uploadError, setUploadError] = React.useState<string>('')
    const transferGroup = useAppSelector(
        ({transfer}) => transfer.groupedTransfers.find(group => group.group_id === groupId)!,
    )
    const uploadedDocuments = registryDetailId
        ? transferGroup?.registry_detail_documents.map(doc => doc)
        : transferGroup?.transfer_order_documents
              .filter(doc => doc.type === documentName)
              .sort((a, b) => b.created.toMillis() - a.created.toMillis())
              .map(doc => doc)

    const doUpload = async (files: File[]) => {
        if (!files.length) {
            return
        }
        documentName === 'STATEMENT'
            ? await dispatch(actions.UploadIssuerHoldingStatement(files, registryDetailId!))
            : await dispatch(actions.UploadIdOrTransferForm(files, groupId!, documentName))
    }
    const doArchive = async (documentId: string) => {
        documentName === 'STATEMENT'
            ? await dispatch(actions.ArchiveIssuerHoldingStatement(documentId!, groupId))
            : await dispatch(actions.ArchiveIdOrTransferForm(groupId!, documentId!))
    }

    const handleFileChange = async (newFiles: File[]) => {
        setUploadError('')
        setCanSubmit(false)
        try {
            setFiles(newFiles)

            const {filesToArchive, filesToUpload} = fileDiff(newFiles, uploadedDocuments)

            const promises: Promise<any>[] = filesToArchive.map(file => doArchive(file))
            if (filesToUpload) {
                promises.push(doUpload(filesToUpload))
            }

            await Promise.all([...promises])
        } catch (e) {
            setUploadError(unknownErrorMessage)
            setFiles(newFiles)
        }
    }

    const onChange = (mutate: MutateUploads) => {
        const mutatedUploads = mutate(files)
        handleFileChange(mutatedUploads)
    }

    return (
        <>
            {heading}
            <FileUpload
                files={files}
                onChange={onChange}
                id={`${documentName}-upload`}
                multiple={isMultiple}
                maxFiles={isMultiple ? 10 : undefined}
            />
            <ErrorBox message={uploadError} />
        </>
    )
}

interface UploadDocumentsProps {
    groupId: string
}

export const UploadDocuments: React.FunctionComponent<UploadDocumentsProps> = ({groupId}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const [submitError, setSubmitError] = React.useState('')
    const [canSubmit, setCanSubmit] = React.useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [insufficientFundsModalOpen, setInsufficientFundsModalOpen] = useState(false)

    const registryDetailId = useAppSelector(({transfer}) => transfer.stagedTransferOrder?.registryDetailId)
    const stagedTransferOrder = useAppSelector(({transfer}) => transfer.stagedTransferOrder)
    const transfersLoadingState = useAppSelector(({transfer}) => transfer.transfersLoadingState)
    const transferOrderGroup = useAppSelector(
        ({transfer}) => transfer.groupedTransfers?.find(group => group.group_id === groupId),
    )
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)

    React.useEffect(() => {
        if (transfersLoadingState === 'loading' && !stagedTransferOrder) {
            dispatch(actions.SetStagedTransfer(groupId))
        }
        // Return to the start of the flow if there is no staged transfer order
        if (transfersLoadingState === 'ready' && !stagedTransferOrder) {
            navigate(profileUrl('invest/portfolio-transfer-shares'))
        }
        if (stagedTransferOrder) {
            setCanSubmit(!isTransferAwaitingDocuments(transferOrderGroup!))
        }
    }, [transfersLoadingState])

    React.useEffect(() => {
        if (transferOrderGroup) {
            setCanSubmit(!isTransferAwaitingDocuments(transferOrderGroup!))
        }
    }, [stagedTransferOrder])

    const handleOnSubmit = async () => {
        setSubmitting(true)
        try {
            const error = await dispatch(actions.SubmitTransfer(groupId))

            if (error) {
                throw new UserSuitableError(error)
            }
            dispatch(actions.ResetStagedTransferOrder())

            transferOrderGroup && isUSExchange(transferOrderGroup.exchange)
                ? navigate(
                      profileUrl('invest/portfolio-transfer-shares/us/:groupId/thanks', {
                          groupId: transferOrderGroup.group_id,
                      }),
                  )
                : navigate(profileUrl('invest/portfolio-transfer-shares/asx/thanks'))
        } catch (error) {
            setSubmitting(false)
            if (error instanceof UserSuitableError) {
                if (error.message === 'insufficient_funds_for_transfer') {
                    setInsufficientFundsModalOpen(true)
                    return
                }
                setSubmitError(error.message)
                return
            }
            setSubmitError(unknownErrorMessage)
        }
    }

    const handleSaveApplication = () => {
        dispatch(actions.ResetStagedTransferOrder())
        navigate(profileUrl('invest/portfolio-transfer-shares'))
    }

    const filesForType = (type: TransferUpload['type']): File[] => {
        if (!stagedTransferOrder) {
            return []
        }

        const {transferOrderDocuments, registryDetailDocuments} = stagedTransferOrder

        const documents = [...transferOrderDocuments, ...registryDetailDocuments]
            .sort((a, b) => b.created.toMillis() - a.created.toMillis())
            .filter(doc => doc.type === type)

        return documents.map(document => new File([], document.file_name, {type: 'image/png'}))
    }

    const isUSTransfer = isUSExchange(transferOrderGroup?.exchange)
    const isExternalUsBroker = isUSTransfer && transferOrderGroup?.us_transfer_broker === 'EXTERNAL'
    const isInternalUsBroker = isUSTransfer && transferOrderGroup?.us_transfer_broker === 'INTERNAL'
    const isDrsUsBroker = isUSTransfer && transferOrderGroup?.us_transfer_broker === 'DRS'
    const transferInfo = useTransferInfo(stagedTransferOrder?.direction, transferOrderGroup?.us_transfer_broker)
    const exchange = stagedTransferOrder?.exchange === 'US_MARKETS' ? 'NYSE' : stagedTransferOrder?.exchange // Use NYSE so can map to correct fee as it is same for all US markets
    const newSrnRequired = stagedTransferOrder?.reference === NEW_SRN_REQUIRED
    const instrumentCount = stagedTransferOrder?.instruments.length ?? 0
    const referenceType = stagedTransferOrder?.referenceType

    const feeValue = () => {
        if (isInternalUsBroker) {
            // For US internal transfers we charge a fee per group not per instrument
            return transferOrderGroup.total_group_fee
        } else {
            return (instrumentCount * Number(transferInfo?.fees[exchange!].charge_amount)).toString()
        }
    }
    const feeCurrency = isUSExchange(stagedTransferOrder?.exchange) ? 'USD' : homeCurrency.toUpperCase()

    return (
        <>
            <Toolbar dataTestId="toolbar--upload-document" leftButton="back" />
            <Page>
                <h1 className={cn(spacing.spaceBelow24, styles.heading)}>Upload your documents</h1>
                {isUSTransfer ? (
                    <>
                        <p>You can upload documents in PDF, JPEG, or PNG format.</p>
                    </>
                ) : (
                    <>
                        <p className={spacing.spaceBelow16}>
                            Make sure all details are clear and legible and your chosen form of ID has your signature.
                        </p>
                        <p>You can upload documents in PDF, JPEG, or PNG format.</p>
                    </>
                )}
                {transfersLoadingState === 'ready' ? (
                    <>
                        {/* Both internal and external US transfers require a Brokerage account statement */}
                        {!isDrsUsBroker && isUSTransfer && (
                            <UploadDocument
                                documentName="STATEMENT"
                                initialFiles={filesForType('STATEMENT')}
                                groupId={groupId}
                                registryDetailId={registryDetailId!}
                                heading={
                                    <>
                                        <h2 className={cn(spacing.spaceAbove48, styles.subHeading)}>
                                            Brokerage account statement
                                        </h2>
                                        {stagedTransferOrder?.direction === 'in' ? (
                                            <p className={spacing.spaceBelow12}>
                                                Make sure your brokerage account name and number, and a summary of your
                                                shareholding is visible.
                                            </p>
                                        ) : (
                                            <p className={spacing.spaceBelow12}>
                                                Make sure your brokerage account name and number are visible.
                                            </p>
                                        )}
                                    </>
                                }
                                setCanSubmit={setCanSubmit}
                            />
                        )}
                        {/* DRS transfers require a statement */}
                        {isDrsUsBroker && stagedTransferOrder?.reference !== NEW_DRS_REQUIRED && (
                            <UploadDocument
                                documentName="STATEMENT"
                                initialFiles={filesForType('STATEMENT')}
                                groupId={groupId}
                                registryDetailId={registryDetailId!}
                                heading={
                                    <>
                                        <h2 className={cn(spacing.spaceAbove48, styles.subHeading)}>
                                            Transfer agent account statement
                                        </h2>
                                        <p className={spacing.spaceBelow12}>
                                            Make sure your account name and number, and a summary of your shareholding
                                            is visible.
                                        </p>
                                    </>
                                }
                                setCanSubmit={setCanSubmit}
                            />
                        )}

                        {/* Only internal US transfers, DRS transfers and NZX/ASX transfers require a transfer form */}
                        {!isExternalUsBroker && (
                            <UploadDocument
                                documentName="AUTHORISATION"
                                initialFiles={filesForType('AUTHORISATION')}
                                groupId={groupId}
                                heading={<h2 className={cn(spacing.spaceAbove40, styles.subHeading)}>Transfer Form</h2>}
                                setCanSubmit={setCanSubmit}
                                isMultiple
                            />
                        )}

                        {/* We don't require ID for US transfers as checking account compliance during US transfer flow */}
                        {!isUSTransfer && (
                            <UploadDocument
                                documentName="IDENTIFICATION"
                                initialFiles={filesForType('IDENTIFICATION')}
                                groupId={groupId}
                                heading={
                                    <>
                                        <h2 className={cn(spacing.spaceAbove40, styles.subHeading)}>
                                            ID (Driver license or passport)
                                        </h2>
                                        <div className={spacing.spaceBelow12}>
                                            Be sure your signature is visible and matches your Transfer Form
                                        </div>
                                    </>
                                }
                                setCanSubmit={setCanSubmit}
                            />
                        )}

                        {/* ASX transfers require a Holding Statement (also known as a CHESS statement) */}
                        {!isUSTransfer && !newSrnRequired && (
                            <UploadDocument
                                documentName="STATEMENT"
                                initialFiles={filesForType('STATEMENT')}
                                isMultiple
                                groupId={groupId}
                                registryDetailId={registryDetailId!}
                                heading={
                                    <>
                                        <h2 className={cn(spacing.spaceAbove48, styles.subHeading)}>
                                            Holding Statement
                                        </h2>
                                        <div className={spacing.spaceBelow12}>
                                            Check your full {referenceType} is shown
                                        </div>
                                    </>
                                }
                                setCanSubmit={setCanSubmit}
                            />
                        )}

                        <ErrorBox message={submitError} />
                        <TransferInsufficientFundsModal
                            feeCurrency={feeCurrency}
                            feeValue={feeValue()}
                            isOpen={insufficientFundsModalOpen}
                            setIsOpen={setInsufficientFundsModalOpen}
                        />
                    </>
                ) : (
                    <Loading />
                )}
            </Page>
            <ActionBar>
                <div className={styles.actionBar}>
                    <Button
                        onClick={handleSaveApplication}
                        type="secondary"
                        label="Save application"
                        dataTestId="button--save-application"
                    />
                    <Button
                        isSubmit
                        label="Submit"
                        dataTestId="button--submit"
                        disabled={!canSubmit}
                        processing={submitting}
                        onClick={handleOnSubmit}
                    />
                </div>
            </ActionBar>
        </>
    )
}

export default UploadDocuments
