import React from 'react'
import {spacing} from '~/global/scss/helpers'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import KidsTermsAndConditions from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/KidsTermsAndConditions'

const LegalKidsTerms: React.FunctionComponent<{}> = () => (
    <>
        <Toolbar dataTestId="toolbar--kids-terms" leftButton="back" title="Legal" />
        <Page overrideDefaultTopPadding="withToolbarTitle">
            <h2 className={spacing.spaceBelow16}>Kids Accounts Terms and Conditions</h2>
            <KidsTermsAndConditions />
        </Page>
    </>
)

export default LegalKidsTerms
