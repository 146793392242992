import cn from 'classnames'
import React from 'react'
import {accessibility} from '~/global/scss/helpers'
import {Close} from '~/global/widgets/OLD_icons'
import styles from './Card.scss'

interface CardProps {
    className?: string
    contentClassName?: string
    imageClassName?: string
    onDismiss?: () => void
    onClick?: () => void
    image?: string
    overlayText?: string
    closeButtonIsDark?: boolean
}

const Card: React.FunctionComponent<CardProps> = ({
    children,
    className,
    contentClassName,
    imageClassName,
    onDismiss,
    onClick,
    image,
    overlayText,
    closeButtonIsDark,
}) => (
    <div className={cn(className, styles.card, onClick && styles.clickableCard)} onClick={onClick}>
        {onDismiss && (
            <button
                className={cn(styles.dismiss, accessibility.button)}
                type="button"
                onClick={e => {
                    e.stopPropagation()
                    onDismiss()
                }}
            >
                <Close colour={closeButtonIsDark ? 'grey-2' : 'white'} />
            </button>
        )}
        {image && (
            <div style={{backgroundImage: `url(${image})`}} className={cn(imageClassName, styles.image)}>
                {overlayText && (
                    <span className={styles.overlayContainer}>
                        <p className={styles.overlayText}>{overlayText}</p>
                    </span>
                )}
            </div>
        )}
        <div className={cn(styles.content, contentClassName)}>{children}</div>
    </div>
)

export default Card
