import React from 'react'
import {useParams} from 'react-router-dom'
import {dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import recordStyles from '~/global/widgets/instrument-activity/Record.scss'
import {RecordRow} from '~/global/widgets/instrument-activity/common'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useKSCashActivityDetails} from '~/sections/kiwisaver/state'

const CashActivityDetails: React.FunctionComponent = () => {
    const {transactionId} = useParams()

    if (!transactionId) {
        throw new Error('Missing transaction Id')
    }

    const transaction = useKSCashActivityDetails(transactionId)
    if (!transaction) {
        throw new Error('Missing transaction')
    }

    return (
        <>
            <Page withoutDefaultPadding>
                <Toolbar leftButton="back" dataTestId="toolbar--activity" />
                <div className={recordStyles.viewContainer}>
                    <div className={recordStyles.titleBlock}>
                        <h2>{transaction.title}</h2>
                    </div>
                    <div className={recordStyles.orderContent}>
                        <div className={recordStyles.recordRowContainer}>
                            <RecordRow
                                className={recordStyles.recordRowWithDate}
                                left="Date"
                                right={transaction.date.toFormat(dateFormatWithYear)}
                            />
                        </div>
                        <p className={recordStyles.orderAmountLabel}>Amount</p>
                        <div className={recordStyles.orderFooter}>
                            <div></div>
                            <div>
                                <p className={recordStyles.orderAmount}>
                                    <DollarValue value={transaction.amount} currency="NZD" />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        </>
    )
}

export default CashActivityDetails
