import {Button} from '@design-system/button'
import {Modal} from '@design-system/modal'
import {SelectCard} from '@design-system/select-cards' // TODO refactor to use SelectCards
import cn from 'classnames'
import React, {useState} from 'react'
import {AutoFocusInside} from 'react-focus-lock'
import {Navigate} from 'react-router-dom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import capitalise from '~/global/utils/capitalise-string/capitaliseString'
import {isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ExchangeHours} from '~/global/widgets/exchange-hours/ExchangeHours'
import {SwitchField} from '~/global/widgets/form-controls'
import {OrderTypesModal} from '~/global/widgets/help-modals/MarketAndLimitOrders'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import panelStyles from '~/sections/invest/sections/order-flow/InvestPanel.scss'
import styles from '~/sections/invest/sections/order-flow/OrderForm.scss'
import {SHARE_LIMIT, SHARE_MARKET, SHARE_TRIGGER} from '~/sections/invest/sections/order-flow/constants/orderTypes'
import {calculateSharesRemaining} from '~/sections/invest/sections/order-flow/sections/sell/utils/calculate-shares-remaining/calculateSharesRemaining'
import {getLabelBySellOrderType} from '~/sections/invest/sections/order-flow/utils/get-label-by-sell-order-type/getLabelBySellOrderType'
import {essHasActiveBlackoutPeriodForInstrument} from '~/store/employeeShareScheme/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {portfolioItems} from '~/store/identity/selectors'
import {Instrument} from '~/store/instrument/types'
import actions from '~/store/order/actions'
import {StagedSellOrder} from '~/store/order/types'
import signUpActions from '~/store/sign-up/actions'

interface OrderTypeProps {
    instrument: Instrument
    onContinue(): void
}

const SellOrderType: React.FunctionComponent<OrderTypeProps> = ({instrument, onContinue}) => {
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const canPlaceStopLossOrders = isOnMainUsExchange(instrument)
    const essHasActiveBlackoutPeriod = useAppSelector(s => essHasActiveBlackoutPeriodForInstrument(s, instrument.id))
    const hideLimitOption = instrument.exchange === 'US OTC' && instrument.tradingStatus === 'closeonly'
    const holding = useAppSelector(({identity}) => identity.holdings.find(holding => holding.fund_id === instrument.id))
    const portfolioItemsMap = useAppSelector(portfolioItems)
    const stagedSellOrder = useAppSelector(({order}) => order.stagedSellOrder)

    const [modalContinueOpen, setModalContinueOpen] = React.useState(false)
    const [orderType, setOrderType] = useState<StagedSellOrder['orderType'] | null>(stagedSellOrder?.orderType ?? null)
    const [showAdvancedOrderTypes, setShowAdvancedOrderTypes] = useState(orderType === SHARE_TRIGGER)

    const usResignStatus = useAppSelector(s => s.signUp.usResignStatus)

    React.useEffect(() => {
        dispatch(signUpActions.getUSResignStatus())
    }, [])

    // Force the investor to resign the W8 tax form.
    if (usResignStatus?.resign_overdue) {
        return <Navigate to={profileUrl('us-sign-up')} replace />
    }

    const onSubmit = (type: StagedSellOrder['orderType']) => {
        rudderTrack('sell', 'order_type_selected', {order_type: type.includes('limit') ? 'limit' : 'market'})

        // Can't limit sell fewer than one share of a Drivewealth instrument
        if (
            type === 'share_limit' &&
            holding &&
            parseFloat(calculateSharesRemaining('0', holding.shares, portfolioItemsMap[instrument.id].sellTotal)) < 1 &&
            isOnMainUsExchange(instrument)
        ) {
            setModalContinueOpen(true)
            return
        }

        /*
        - if there was no staged sell order -> initialise a new staged sell order
        - if there was a staged sell order, but the user is switching the order type
            -> re-initialise the staged sell order to ensure dp limits are recalculated
            -> copy the broking customer across from the existing stagedSellOrder
        - if there was a staged sell order, and the user selected the same type of order
            -> don't do anything and keep the previous staged sell order
        */
        if (!stagedSellOrder) {
            dispatch(actions.InitialiseStagedSellOrder(instrument.id, type))
        } else if (stagedSellOrder && stagedSellOrder.orderType !== type) {
            dispatch(actions.InitialiseStagedSellOrder(instrument.id, type))
        }

        onContinue()
    }

    return (
        <>
            <Page className={panelStyles.investPanelAnimated}>
                <PageBack />
                {!essHasActiveBlackoutPeriod && <ExchangeHours instrument={instrument} />}
                <div className={cn(spacing.spaceAbove24, spacing.spaceBelow24, styles.intro)}>
                    <h1>How would you like to sell?</h1>
                </div>
                <AutoFocusInside>
                    <SelectCard
                        dataTestId="button--market-sell"
                        title={capitalise(getLabelBySellOrderType(SHARE_MARKET, instrument))}
                        value={SHARE_MARKET}
                        name="orderType"
                        isActive={orderType === SHARE_MARKET}
                        onChange={() => setOrderType(SHARE_MARKET)}
                        supportingText="Let the market decide the price you sell at in line with our best price policy"
                    />
                </AutoFocusInside>
                {!hideLimitOption && (
                    <SelectCard
                        dataTestId="button--limit-sell"
                        title={capitalise(getLabelBySellOrderType(SHARE_LIMIT, instrument))}
                        value={SHARE_LIMIT}
                        name="orderType"
                        isActive={orderType === SHARE_LIMIT}
                        onChange={() => setOrderType(SHARE_LIMIT)}
                        supportingText={`Set the lowest price you’re willing to sell at per ${shareLabel({
                            instrument,
                        })}`}
                    />
                )}

                {canPlaceStopLossOrders && (
                    <>
                        <SwitchField
                            additionalClassName={cn(spacing.spaceAbove12, spacing.spaceBelow12, styles.advancedOptions)}
                            name="advanced-options"
                            isTouched={false}
                            label="Advanced selling options"
                            dataTestId="switch--sell-order-advanced-options"
                            value={showAdvancedOrderTypes}
                            onChange={() => setShowAdvancedOrderTypes(!showAdvancedOrderTypes)}
                        />

                        {showAdvancedOrderTypes && (
                            <>
                                <SelectCard
                                    additionalClassName={spacing.spaceAbove8}
                                    dataTestId="button--stop-loss"
                                    title="Stop loss"
                                    value={SHARE_TRIGGER}
                                    name="orderType"
                                    isActive={orderType === SHARE_TRIGGER}
                                    onChange={() => setOrderType(SHARE_TRIGGER)}
                                    supportingText="To protect against potential losses, set the price that would trigger a sell order."
                                />
                            </>
                        )}
                    </>
                )}

                <div className={cn(spacing.spaceAbove8, styles.intro, styles.footer)}>
                    Learn more about our different order types&nbsp;
                    <OrderTypesModal instrument={instrument} />
                </div>

                <Modal
                    isOpen={modalContinueOpen}
                    setIsOpen={setModalContinueOpen}
                    title={`You can only limit sell whole ${shareLabel({
                        instrument,
                        isPlural: true,
                    })} in US investments`}
                    dataTestId="modal--limit-sell-whole-shares-only"
                    primaryButton={{label: 'Ok, got it'}}
                >
                    <p>
                        You have less than one whole {shareLabel({instrument})}. To sell your current amount, place a
                        market sell instead.
                    </p>
                </Modal>
            </Page>
            <ActionBar>
                <Button
                    label="Next"
                    disabled={!orderType}
                    onClick={() => onSubmit(orderType!)}
                    dataTestId="button--pick-order"
                />
            </ActionBar>
        </>
    )
}

export default SellOrderType
