import {colour, semanticColour} from '@design-system/colour-tokens'
import {Love, Edit, Transfer} from '@design-system/icon'
import {useColourMode} from '@design-system/use-colour-mode'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {Link} from 'react-router-dom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ProductIntroSlideContent} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {ProductIntroSlides} from '~/global/widgets/intro-slides/IntroSlides'
import insureLarge from '~/sections/protect/sections/insure/assets/images/insure-large.png'
import styles from '~/sections/protect/sections/insure/sections/car/pages/intro-slides/IntroSlides.scss'

export const Intro: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const colourMode = useColourMode()

    const content: ProductIntroSlideContent[] = [
        {
            backgroundColour: colour('Kiwi100', colourMode),
            image: insureLarge,
            imageAdditionalClassName: styles.benefitsImage,
            title: 'Comprehensive car insurance at mates rates',
            buttonLabel: 'Let’s go',
            onButtonClick: () => rudderTrack('insurance_signup', 'insurance_introduction_started'),
            bodyRenderer: () => (
                <>
                    <p data-testid="text--intro-slide-1" className={spacing.spaceBelow24}>
                        With Sharesies Car Insurance by Cove, drivers aged 21 or over get a discount on Cove’s already
                        competitive insurance pricing—just because you’re with Sharesies.
                    </p>
                    <p>Find out how much you might save with a quick estimate—it takes just 2 minutes.</p>
                </>
            ),
        },
        {
            backgroundColour: semanticColour('PageBackgroundBase', colourMode),
            title: '',
            buttonLabel: 'Get a quick estimate',
            onButtonClick: () => rudderTrack('insurance_signup', 'quote_started'),
            bodyRenderer: () => (
                <>
                    <h1 data-testid="heading--intro" className={cn(styles.heading, spacing.spaceAbove40)}>
                        To offer comprehensive car cover, we’ve teamed up with&nbsp;Cove
                    </h1>
                    <p data-testid="text--intro-slide-2" className={cn(styles.intro, spacing.spaceBelow24)}>
                        Cove is an independent NZ insurance provider with policies underwritten by one of the world’s
                        largest insurers, Aioi Nissay Dowa which has an{' '}
                        <a
                            target="_blank"
                            rel="noopener"
                            href="https://www.aioi.co.nz/about-us/financial-strength-solvency/"
                        >
                            A+ financial strength rating
                        </a>
                        .
                    </p>
                    <div className={cn(styles.iconListItem, spacing.spaceBelow24)}>
                        <div className={styles.iconListIcon}>
                            <Love size={20} />
                        </div>
                        <div>
                            <div className={styles.listHeading}>Mates rates</div>
                            <div className={cn(styles.includeNewLine, spacing.spaceAbove8)}>
                                Get a 5% discount on Cove’s car cover, a free first month, and if you pay annually, an
                                extra discount of nearly 10%.{' '}
                                <Link to={profileUrl('legal/sharesies-cove-promotional-terms')}>
                                    Terms and conditions apply
                                </Link>
                                .
                            </div>
                        </div>
                    </div>
                    <div className={cn(styles.iconListItem, spacing.spaceBelow24)}>
                        <div className={styles.iconListIcon}>
                            <Edit size={20} />
                        </div>
                        <div>
                            <div className={styles.listHeading}>Do it all in Sharesies</div>
                            <div className={cn(styles.includeNewLine, spacing.spaceAbove8)}>
                                Easily manage your policy, see renewal and payment dates, and make a claim—all from
                                within Sharesies.
                            </div>
                        </div>
                    </div>
                    <div className={cn(styles.iconListItem)}>
                        <div className={styles.iconListIcon}>
                            <Transfer size={20} />
                        </div>
                        <div>
                            <div className={styles.listHeading}>Switch anytime and save</div>
                            <div className={cn(styles.includeNewLine, spacing.spaceAbove8)}>
                                Got a renewal that’s months away? Don’t wait—switch when you want and the first month of
                                your policy is free. Check the terms of your existing policy.
                            </div>
                        </div>
                    </div>
                </>
            ),
        },
    ]

    return (
        <ProductIntroSlides
            content={content}
            onComplete={() => {
                rudderTrack('insurance_signup', 'generate_quote_clicked', {source: 'product_introduction'})
                navigate(profileUrl('protect/insure/car/details'))
            }}
        />
    )
}

export default Intro
