import React from 'react'
import {Outlet, useParams} from 'react-router'
import {useProfiles} from '~/global/state-hooks/retail/useProfile'
import {MooseRecentOrders} from '~/sections/moose/sections/instrument/pages/recent-orders/MooseRecentOrders'
import {ViewMooseInstrument} from '~/sections/moose/sections/instrument/pages/view-moose-instrument/ViewMooseInstrument'
import InvestingActivityDetails from '~/sections/moose/sections/instrument/widgets/investing-activity/InvestingActivityDetails'
import {ManageAddFin} from '~/sections/moose/sections/manage-portfolio/pages/manage-add-fin/ManageAddFin'
import {ManageLanding} from '~/sections/moose/sections/manage-portfolio/pages/manage-landing/ManageLanding'
import {ManagePriceNotifications} from '~/sections/moose/sections/manage-portfolio/pages/manage-price-notifications/ManagePriceNotifications'
import {InstrumentSelect} from '~/sections/moose/sections/order/pages/instrument-selector/InstrumentSelector'
import {LimitBuyConfirmPage} from '~/sections/moose/sections/order/pages/limit-buy-order/LimitBuyConfirmPage'
import {LimitBuyPage} from '~/sections/moose/sections/order/pages/limit-buy-order/LimitBuyPage'
import {TradingFacilityLimitBuyConfirmPage} from '~/sections/moose/sections/order/pages/limit-buy-order/TradingFacilityLimitBuyConfirmPage'
import {TradingFacilityPage} from '~/sections/moose/sections/order/pages/limit-buy-order/TradingFacilityPage'
import {CheckFinSet} from '~/sections/moose/sections/order/pages/limit-sell-order/CheckFinSet'
import {LimitSellConfirmPage} from '~/sections/moose/sections/order/pages/limit-sell-order/LimitSellConfirmPage'
import {LimitSellPage} from '~/sections/moose/sections/order/pages/limit-sell-order/LimitSellPage'
import {TimeOnMarketPage} from '~/sections/moose/sections/order/pages/time-on-market/TimeOnMarketPage'
import {MoosePortfolio} from '~/sections/moose/sections/portfolio/Portfolio'
import {Transfer} from '~/sections/moose/sections/transfer/Transfer'
import {InstrumentSelect as TransferInstrumentSelect} from '~/sections/moose/sections/transfer/pages/instrument-select/InstrumentSelect'
import {PortfolioSelect as TransferPortfolioSelect} from '~/sections/moose/sections/transfer/pages/portfolio-select/PortfolioSelect'
import {UnitsSelect as TransferUnitsSelect} from '~/sections/moose/sections/transfer/pages/units-select/UnitsSelect'
import {FonterraPortfolioContext} from '~/sections/moose/state/local'

export interface MoosePortfolioPathParams {
    portfolioId: string
}

export interface MooseOrderPathParams extends MoosePortfolioPathParams {
    urlSlug: string
}

/**
 * Ensure we handle invalid Wallet portfolio IDs gracefully and provide basic wallet context.
 */
const FonterraPortfolioWrapper = () => {
    const {portfolioId} = useParams()
    const profiles = useProfiles()

    const profile = profiles.find(p => p.portfolios.find(p => p.id === portfolioId))
    if (!profile) {
        throw new Error(`Invalid Fonterra portfolio_id: ${portfolioId}`)
    }
    const portfolio = profile.portfolios.find(p => p.id === portfolioId)
    if (!portfolio || portfolio.product !== 'FONTERRA') {
        throw new Error(`Invalid Fonterra portfolio_id: ${portfolioId}`)
    }
    const wallet = profile.portfolios.find(p => p.product === 'WALLET')
    if (!wallet || wallet.product !== 'WALLET') {
        throw new Error(`Couldn't find WALLET for Fonterra portfolio_id: ${portfolioId}`)
    }

    return (
        <FonterraPortfolioContext.Provider value={{...portfolio, wallet}}>
            <Outlet />
        </FonterraPortfolioContext.Provider>
    )
}

export const FONTERRA_ROUTES = [
    {
        path: 'portfolios/:portfolioId',
        element: <FonterraPortfolioWrapper />,
        children: [
            {
                path: '',
                Component: ({portfolioId}: {portfolioId: string}) => <MoosePortfolio portfolioId={portfolioId} />,
            },
            {
                path: 'invest/buy',
                Component: ({portfolioId}: {portfolioId: string}) => (
                    <InstrumentSelect portfolioId={portfolioId} action="buy" />
                ),
            },
            {
                path: 'invest/:urlSlug/buy/limit-order',
                Component: ({portfolioId, urlSlug}: {urlSlug: string; portfolioId: string}) => (
                    <LimitBuyPage portfolioId={portfolioId} urlSlug={urlSlug} />
                ),
            },
            {
                path: 'invest/:urlSlug/buy/trading-facility',
                Component: ({portfolioId, urlSlug}: {urlSlug: string; portfolioId: string}) => (
                    <TradingFacilityPage portfolioId={portfolioId} urlSlug={urlSlug} />
                ),
            },
            {
                path: 'invest/:urlSlug/buy/trading-facility-confirm',
                Component: ({portfolioId, urlSlug}: {urlSlug: string; portfolioId: string}) => (
                    <TradingFacilityLimitBuyConfirmPage portfolioId={portfolioId} urlSlug={urlSlug} />
                ),
            },
            {
                path: 'invest/:urlSlug/:action/time-limit',
                Component: ({
                    portfolioId,
                    urlSlug,
                    action,
                }: {
                    urlSlug: string
                    portfolioId: string
                    action: 'buy' | 'sell'
                }) => <TimeOnMarketPage portfolioId={portfolioId} urlSlug={urlSlug} action={action} />,
            },
            {
                path: 'invest/:urlSlug/buy/confirm',
                Component: ({portfolioId, urlSlug}: {urlSlug: string; portfolioId: string}) => (
                    <LimitBuyConfirmPage portfolioId={portfolioId} urlSlug={urlSlug} />
                ),
            },
            {
                path: 'invest/:urlSlug/sell/confirm',
                Component: ({portfolioId, urlSlug}: {urlSlug: string; portfolioId: string}) => (
                    <LimitSellConfirmPage portfolioId={portfolioId} urlSlug={urlSlug} />
                ),
            },
            {
                path: 'invest/sell',
                Component: ({portfolioId}: {portfolioId: string}) => (
                    <InstrumentSelect portfolioId={portfolioId} action="sell" />
                ),
            },
            {
                path: 'invest/:urlSlug/sell/check-fin-set',
                Component: ({portfolioId, urlSlug}: {urlSlug: string; portfolioId: string}) => (
                    <CheckFinSet portfolioId={portfolioId} urlSlug={urlSlug} />
                ),
            },
            {
                path: 'invest/:urlSlug/sell/limit-order',
                Component: ({portfolioId, urlSlug}: {urlSlug: string; portfolioId: string}) => (
                    <LimitSellPage portfolioId={portfolioId} urlSlug={urlSlug} />
                ),
            },
            {
                path: 'invest/:urlSlug',
                Component: ({portfolioId, urlSlug}: {portfolioId: string; urlSlug: string}) => (
                    <ViewMooseInstrument activeTab="Overview" portfolioId={portfolioId} urlSlug={urlSlug} />
                ),
            },
            {
                path: 'invest/:urlSlug/investment',
                Component: ({portfolioId, urlSlug}: {portfolioId: string; urlSlug: string}) => (
                    <ViewMooseInstrument activeTab="Your investment" portfolioId={portfolioId} urlSlug={urlSlug} />
                ),
            },
            {
                path: 'investing-activity/:investmentActivityKey',
                Component: ({
                    portfolioId,
                    investmentActivityKey,
                }: {
                    portfolioId: string
                    investmentActivityKey: string
                }) => (
                    <InvestingActivityDetails portfolioId={portfolioId} investmentActivityKey={investmentActivityKey} />
                ),
            },
            {
                path: 'invest/recent/:urlSlug',
                Component: ({portfolioId, urlSlug}: {portfolioId: string; urlSlug: string}) => (
                    <MooseRecentOrders portfolioId={portfolioId} urlSlug={urlSlug} />
                ),
            },
            {
                path: 'manage',
                Component: ({portfolioId}: {portfolioId: string}) => <ManageLanding portfolioId={portfolioId} />,
            },
            {
                path: 'manage/add-fin',
                Component: ({portfolioId}: {portfolioId: string}) => <ManageAddFin portfolioId={portfolioId} />,
            },
            {
                path: 'transfer/intro',
                Component: ({portfolioId}: {portfolioId: string}) => <Transfer portfolioId={portfolioId} />,
            },
            {
                path: 'manage/price-notifications',
                Component: ({portfolioId}: {portfolioId: string}) => (
                    <ManagePriceNotifications portfolioId={portfolioId} />
                ),
            },
        ],
    },
    {
        path: 'transfer',
        children: [
            {
                path: 'select',
                Component: () => <TransferInstrumentSelect />,
            },
            {
                path: 'select/:direction',
                Component: ({direction}: {direction: 'out' | 'in'}) => (
                    <TransferPortfolioSelect direction={direction} />
                ),
            },
            {
                path: 'select/units',
                Component: () => <TransferUnitsSelect />,
            },
        ],
    },
] as const
