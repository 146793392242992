import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {useRetailGet} from '~/api/query/retail'
import {spacing} from '~/global/scss/helpers'
import headerStyles from '~/global/scss/reused-styles/pageHeader.scss'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import kiwisaverLarge from '~/sections/kiwisaver/assets/images/kiwisaver-large.png'
import homeStyles from '~/sections/kiwisaver/sections/home/Home.scss'
import {TotalBalance} from '~/sections/kiwisaver/sections/home/widgets/total-balance/TotalBalance'
import {useKSCustomerOrNull} from '~/sections/kiwisaver/state'
import {InvestmentPlanSummaryCard} from '~/sections/kiwisaver/widgets/investment-plan-summary-card/InvestmentPlanSummaryCard'
import {useAppSelector} from '~/store/hooks'
import styles from './RegistrationPending.scss'

export const RegistrationPending: React.FunctionComponent<{}> = () => {
    const customer = useKSCustomerOrNull()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const toolbarHiddenForMobile = useAppSelector(s => s.nav.toolbarHidden)

    const {data} = useRetailGet({path: 'kiwisaver/pending-activation-period'})

    if (!customer || !customer.investment_plan) {
        throw new Error('Invalid state for Pending')
    }

    let pendingActivationDayCountText: string = ''
    if (data.pending_activation_day_count !== 0) {
        pendingActivationDayCountText = ` (it’s been
        ${data.pending_activation_day_count} so far)`
    }
    return (
        <>
            <div className={styles.background}>
                <Toolbar
                    dataTestId="toolbar--kiwisaver-home"
                    leftButton="back"
                    onLeftButtonClick={() => navigate(profileUrl(''))}
                    showNotifications
                />
            </div>
            <Page className={homeStyles.home} withoutDefaultPadding>
                <div
                    className={cn(headerStyles.landingImageWrapper, styles.background)}
                    style={{paddingTop: toolbarHiddenForMobile ? '25px' : '0px'}}
                >
                    <img src={kiwisaverLarge} alt="" className={headerStyles.landingImage} />
                </div>
                <div className={styles.background}>
                    <div className={headerStyles.landingContentWrapper}>
                        <h1 data-testid="title--home-pending" className={cn(headerStyles.title, spacing.spaceAbove8)}>
                            KiwiSaver
                        </h1>
                        <div className={cn(spacing.spaceAbove12, spacing.spaceBelow40)}>
                            <p className={headerStyles.accountBalance}>
                                <TotalBalance />
                                <span className={headerStyles.accountBalanceCurrency}>NZD</span>
                            </p>
                            <p className={cn(headerStyles.subTitle, spacing.spaceAbove12)}>estimated balance</p>
                        </div>
                        <div className={cn(styles.card)}>
                            <div>
                                <h3>We’re getting you set up</h3>
                                <p>
                                    It’s usually all sorted within 10 working days{pendingActivationDayCountText}. We’ll
                                    send you an email when it’s good to go.
                                </p>
                            </div>
                        </div>

                        <InvestmentPlanSummaryCard
                            editUrl={profileUrl('kiwisaver/pending/edit-investment-plan')}
                            plan={customer.investment_plan}
                            className={spacing.spaceAbove16}
                        />
                    </div>
                </div>
            </Page>
        </>
    )
}
