import {useAtom} from 'jotai'
import React from 'react'
import {Request} from '~/api/retail/types'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {FonterraPersonalSignUpCommonProps} from '~/sections/moose/sections/fonterra-personal-sign-up/FonterraPersonalSignUp'
import {stagedFonterraPersonalSignUpAtom} from '~/sections/moose/sections/fonterra-personal-sign-up/state'
import TaxDeclarationForm from '~/sections/user/sections/sign-up/pages/tax-questions/widgets/tax-declaration-form/TaxDeclarationForm'
import {useAppSelector} from '~/store/hooks'

export const TaxDeclaration = ({progressStep, regressStep}: FonterraPersonalSignUpCommonProps) => {
    const [stagedFonterraPersonalSignUp, updateStagedFonterraPersonalSignUp] = useAtom(stagedFonterraPersonalSignUpAtom)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)

    const onNext = (
        foreign_tax_resident: boolean,
        countries: Request.FonterraEnablePersonalAccount['foreign_tax_countries'],
    ): Promise<undefined | string> => {
        updateStagedFonterraPersonalSignUp({
            ...stagedFonterraPersonalSignUp,
            foreignTaxResident: foreign_tax_resident,
            foreignTaxCountries: countries,
        })

        return Promise.resolve(undefined)
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--fonterra-personal-signup-tax-declaration"
                leftButton="back"
                onLeftButtonClick={regressStep}
                title="Countries you pay tax in"
            />
            <Page>
                <TaxDeclarationForm
                    signUpTaxQuestions={onNext}
                    isDependent={false}
                    preferredName={preferredName}
                    jurisdiction={jurisdiction}
                    onSuccess={progressStep}
                />
            </Page>
        </>
    )
}
