import * as api from '~/api/retail'
import {Request, Response} from '~/api/retail/types'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {errorResponseFactory} from '~/global/utils/error-handling/errorHandling'
import {reauthenticateReturn} from '~/global/wrappers/global-wrapper-widgets/reauthenticate/Reauthenticate'
import {ThunkAction} from '~/store/types'
import identityActions from '../identity/actions'
import {actingAsID} from '../identity/selectors'
import {createAction, ActionsUnion} from '../redux-tools'
import {CurrentPlanV2, SubPlanV2} from './types'

const actions = {
    SetCurrentPlanV2: (plan?: CurrentPlanV2) => createAction('plan.SetCurrentPlanV2', {plan}),
    SetAllPlans: (plans: SubPlanV2[]) => createAction('plan.SetAllPlans', {plans}),
    ClearAllPlans: () => createAction('plan.ClearAllPlans'),
    ClearCurrentPlan: () => createAction('plan.ClearCurrentPlan'),
}

const thunkActions = {
    FetchAvailablePlans(onlySelectable: boolean = true): ThunkAction<Promise<void | string>> {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())
            const data = await api.get('subscription/plans-v2', {acting_as_id, only_selectable: onlySelectable})
            switch (data.type) {
                case 'subscription_plans_v2':
                    dispatch(actions.SetAllPlans(data.plans))
                    return
                case 'internal_server_error':
                    throw new Error('unknown reponse from subscription/plans-v2')
                default:
                    assertNever(data)
            }
            return
        }
    },

    FetchCurrentPlan(): ThunkAction<Promise<void | string>> {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())
            const data = await api.get('subscription/get-v2', {acting_as_id})
            switch (data.type) {
                case 'subscription_v2':
                    dispatch(actions.SetCurrentPlanV2(data))
                    return
                case 'empty':
                    dispatch(actions.SetCurrentPlanV2(undefined))
                    return
                case 'internal_server_error':
                    throw new Error('unknown reponse from subscription/get-v2')
                default:
                    assertNever(data)
            }
            return
        }
    },
    ChangePlan(
        target_plan: Request.SubscriptionChangePlanV2['target_plan'],
        target_billing_cycle: Request.SubscriptionChangePlanV2['target_billing_cycle'],
        existing_plan:
            | Request.SubscriptionChangePlanV2['existing_plan']
            | Request.SubscriptionChangePlan['existing_plan'],
        existing_billing_cycle: Request.SubscriptionChangePlanV2['existing_billing_cycle'] | undefined,
        payment_method: Request.SubscriptionChangePlanV2['payment_method'],
    ): ThunkAction<Promise<void | Response.Error>> {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())

            const payload: Request.SubscriptionChangePlanV2 = {
                target_plan,
                target_billing_cycle,
                existing_plan,
                existing_billing_cycle,
                acting_as_id,
                payment_method,
            }

            const response = await api.post('subscription/change-v2', payload)

            switch (response.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () =>
                            this.ChangePlan(
                                target_plan,
                                target_billing_cycle,
                                existing_plan,
                                existing_billing_cycle,
                                payment_method,
                            )(dispatch, getState),
                        () => errorResponseFactory('Please resubmit your request.'),
                        () => errorResponseFactory('You must enter your password before you can change your plan.'),
                    )
                case 'internal_server_error':
                    throw new Error('unknown reponse from subscription/change-v2')
                case 'subscription_v2':
                    dispatch(actions.SetCurrentPlanV2(response))
                    dispatch(actions.ClearAllPlans())
                    dispatch(identityActions.Check()) // trigger a refresh of the wallet balance
                    return
                case 'empty':
                    dispatch(actions.SetCurrentPlanV2(undefined))
                    dispatch(actions.ClearAllPlans())
                    return
                case 'error':
                    throw new Error(response.message)
                default:
                    assertNever(response)
            }
        }
    },

    CancelCurrentPlan(): ThunkAction<Promise<void | string>> {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())
            const response = await api.post('subscription/cancel-v2', {acting_as_id})
            switch (response.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => this.CancelCurrentPlan()(dispatch, getState),
                        () => 'Please resubmit your request.',
                        () => 'You must enter your password before you can cancel your plan.',
                    )
                case 'internal_server_error':
                    throw new Error('unknown reponse from subscription/cancel')
                case 'subscription_v2':
                    dispatch(actions.SetCurrentPlanV2(response))
                    dispatch(actions.ClearAllPlans())
                    return
                default:
                    assertNever(response)
            }
        }
    },

    ReactivateCurrentPlan(): ThunkAction<Promise<void | string>> {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())
            const response = await api.post('subscription/reactivate-v2', {acting_as_id})
            switch (response.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => this.ReactivateCurrentPlan()(dispatch, getState),
                        () => 'Please resubmit your request.',
                        () => 'You must enter your password before you can reactivate your plan.',
                    )
                case 'internal_server_error':
                    throw new Error('unknown reponse from subscription/reactivate')
                case 'subscription_v2':
                    dispatch(actions.SetCurrentPlanV2(response))
                    dispatch(actions.ClearAllPlans())
                    return
                default:
                    assertNever(response)
            }
        }
    },
}

export type ActionsType = ActionsUnion<typeof actions>

export default {...actions, ...thunkActions}
