import {Button} from '@design-system/button'
import {withFormik} from 'formik'
import React from 'react'
import {FormErrors} from '~/api/retail'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {ErrorBox, validate} from '~/global/widgets/form-controls'
import {Password} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'

interface FormValues {
    oldPassword: string
    newPassword: string
    confirmPassword: string
}

const ChangePasswordForm = withFormik<DispatchProps, FormValues>({
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        oldPassword: undefined,
    }),
    handleSubmit: async (
        {oldPassword, newPassword},
        {setSubmitting, setStatus, resetForm, props: {ChangePassword}},
    ) => {
        try {
            const error = await ChangePassword(oldPassword, newPassword)

            if (error) {
                setStatus(error.old_password || error.new_password)
                setSubmitting(false)
                return
            }
            Toast('Password changed successfully')
            resetForm()
        } catch (e) {
            setStatus(unknownErrorMessage)
            setSubmitting(false)
            throw e
        }
    },
    validate: validate.generate<FormValues>({
        oldPassword: [validate.required()],
        newPassword: [validate.required(), validate.password()],
        confirmPassword: [validate.sameAs('newPassword', "Passwords don't match")],
    }),
})(({handleSubmit, status, isValid}) => (
    <form onSubmit={handleSubmit}>
        <Password dataTestId="password--old" label="Password" name="oldPassword" autoFocus />
        <Password
            dataTestId="password--new"
            label="New password"
            name="newPassword"
            helpText="Choose a nice strong one."
            strengthMeter
        />
        <Password dataTestId="password--confirm" label="Confirm new password" name="confirmPassword" />
        {status && <ErrorBox message={status} />}
        <Button dataTestId="button--change-password" label="Change password" disabled={!isValid} isSubmit pageButton />
    </form>
))

export const ChangePassword: React.FunctionComponent<DispatchProps> = props => {
    return (
        <>
            <Toolbar dataTestId="toolbar--change-password" leftButton="back" title="Change password" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <ChangePasswordForm {...props} />
            </Page>
        </>
    )
}

interface DispatchProps {
    ChangePassword(oldPassword: string, newPassword: string): Promise<null | FormErrors>
}

export default connect<{}, DispatchProps, {}>(undefined, dispatch => ({
    ChangePassword: (oldPassword, newPassword) => dispatch(actions.ChangePassword(oldPassword, newPassword)),
}))(ChangePassword)
