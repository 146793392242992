import React from 'react'

const PERatioDetail: React.FunctionComponent = () => (
    <div>
        <h1>P/E Ratio</h1>
        <p>
            The price-to-earnings ratio (P/E ratio) is a way to compare how expensive an investment is for each dollar
            of profit. This is calculated by dividing the share price by earnings per share (usually for the past 12
            months).
        </p>
        <p>
            The price of an investment is typically driven by its ability to generate profits. A high P/E ratio could
            mean that an investment is over-valued, or that investors are expecting high growth rates in the future.
        </p>
        <p>
            Investments with a negative P/E ratio have lost money in their last reporting year. Sometimes this is
            intentional, for example, early-stage companies may sacrifice profits to instead invest in growth. Or losses
            can be due to factors outside the company’s control, for example regulatory change, a natural disaster,
            outbreak of war, or a global pandemic.
        </p>
        <p>
            Either way, investments that consistently show a negative P/E ratio aren’t generating enough money to make a
            profit and run the risk of bankruptcy in time. This means they’re riskier and you’ve got a higher chance not
            getting your money back.
        </p>
    </div>
)

export default PERatioDetail
