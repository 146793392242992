import {Button} from '@design-system/button'
import {Trash} from '@design-system/icon'
import cn from 'classnames'
import Decimal from 'decimal.js'
import {FieldArray, Formik, getIn} from 'formik'
import {DateTime} from 'luxon'
import converter from 'number-to-words'
import React, {useState} from 'react'
import {useNavigate} from 'react-router'
import {distillApiNewClientToken} from '~/api/distill'
import * as rollbar from '~/api/rollbar/rollbar'
import config from '~/configForEnv'
import {accessibility, spacing} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {APINetworkError, NonRollbarError, UserSuitableError} from '~/global/utils/error-handling/errorHandling'
import {isUSExchange} from '~/global/utils/share-transfers/shareTransfers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {validate} from '~/global/widgets/form-controls'
import {DateInput, StrongCurrency, StrongNumber} from '~/global/widgets/form-controls/formik'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import styles from '~/sections/invest/sections/transfer-shares/pages/transfer-shares-details/TransferSharesDetails.scss'
import {Instrument} from '~/store/instrument/types'
import {Record, StagedTransferOrder, TransferOrderInstrument} from '~/store/transfer/types'

const getPriceForDate = async (date: DateTime, instrumentId: string): Promise<number | undefined> => {
    // Get the price for an instrument for a date.
    // Set to the price input for convenience
    if (!date.toISODate() || date.year < 2000 || date > DateTime.now()) {
        return
    }
    let response
    try {
        response = await distillApiNewClientToken.instrumentsApiNewClientToken.apiV1InstrumentsIdPricehistoryV2Get({
            id: instrumentId,
            // Allow tolerance for weekends & non-trading days
            startDate: date.minus({days: 4}),
            endDate: date.setZone(config.NZTimeZone),
        })

        if (response?.dayPrices) {
            const price = response.dayPrices[date.toISODate()]
            if (price) {
                // Types specify a string, but a number is actually returned
                return price as any as number
            } else {
                // Return the closet price to date
                return response.dayPrices[Object.keys(response.dayPrices)[1]] as any as number
            }
        }
    } catch (e: unknown) {
        if (
            e instanceof Error ||
            e instanceof UserSuitableError ||
            e instanceof APINetworkError ||
            e instanceof NonRollbarError
        ) {
            rollbar.sendError('Error fetching price history for ASX Transfer in records', {
                endpoint: 'apiV1InstrumentsIdPricehistoryV2Get',
                statusText: e.message,
                purchaseDate: date.toJSDate(),
                instrumentId,
            })
        }
    }
}

interface AddTransferDetailsFormProps {
    addStagedTransferOrderDetails(details: TransferOrderInstrument): void
    closePrice?: string
    currentInstrument?: TransferOrderInstrument
    exchange: NonNullable<StagedTransferOrder['exchange']>
    holdingShares?: string
    instrumentCurrency: Instrument['currency']
    instrumentId: string
    stagedOrderInstruments: StagedTransferOrder['instruments']
}

const AddTransferInRecordsForm = ({
    addStagedTransferOrderDetails,
    currentInstrument,
    exchange,
    instrumentCurrency,
    instrumentId,
    stagedOrderInstruments,
}: AddTransferDetailsFormProps) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const [closeDate, setCloseDate] = useState<string>()

    const setPriceOnChange = (date: DateTime, callback: (price: string) => void) => {
        getPriceForDate(date, instrumentId).then(price => {
            if (price) {
                const decimal = new Decimal(`${price}`)
                callback(decimal.toFixed(3))
                setCloseDate(date.toFormat('dd/MM/yyyy'))
            }
        })
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={
                {
                    records: (currentInstrument?.records?.length && currentInstrument!.records) || [{}],
                } as {
                    records: Record[]
                }
            }
            validate={async values => {
                // Initialize errors with a nested empty array that's the same length as "values"
                const errors = {
                    records: Array(values.records.length),
                }

                let hasError = false
                for (const index in values.records) {
                    const error = await validate.generate({
                        shares: [
                            validate.required(),
                            validate.money(),
                            validate.regexp(/^[0-9]*$/, 'You can only transfer in whole shares.'),
                        ],
                        purchaseDate: [validate.date(), validate.notFutureDate(), validate.dateMinYear(1987)],
                    })({
                        shares: values.records[index].shares ?? '',
                        purchaseDate: values.records[index].purchaseDate!,
                    })

                    if (Object.keys(error).length > 0) {
                        errors.records[index] = error
                        hasError = true
                    }
                }

                return hasError ? errors : {}
            }}
            onSubmit={async ({records}, {setSubmitting}) => {
                if (currentInstrument) {
                    addStagedTransferOrderDetails({
                        index: currentInstrument.index,
                        instrumentId,
                        instrumentSlug: currentInstrument.instrumentSlug,
                        records,
                    })
                }

                setSubmitting(false)

                const currentIndexInStagedOrder = currentInstrument ? currentInstrument.index : undefined
                const nextFundSlug =
                    (currentIndexInStagedOrder || currentIndexInStagedOrder === 0) &&
                    currentIndexInStagedOrder < stagedOrderInstruments.length - 1
                        ? stagedOrderInstruments.find(instrument => instrument.index === currentIndexInStagedOrder + 1)!
                              .instrumentSlug
                        : undefined

                let exchangeLowercase: 'asx' | 'nzx' | 'us'
                if (isUSExchange(exchange)) {
                    exchangeLowercase = 'us'
                } else if (exchange === 'ASX') {
                    exchangeLowercase = 'asx'
                } else if (exchange === 'NZX') {
                    exchangeLowercase = 'nzx'
                } else {
                    assertNever(exchange)
                    throw new Error(`Unexpected exchange ${exchange}`)
                }

                if (nextFundSlug) {
                    navigate(
                        profileUrl(`invest/portfolio-transfer-shares/:exchange/details/:instrumentSlug`, {
                            exchange: exchangeLowercase,
                            instrumentSlug: nextFundSlug,
                        }),
                    )
                } else {
                    navigate(profileUrl(`invest/portfolio-transfer-shares/${exchangeLowercase}/confirm`))
                }
            }}
        >
            {({handleSubmit, isValid, setFieldValue, setFieldTouched, isSubmitting, touched, values: {records}}) => (
                <form onSubmit={handleSubmit}>
                    <div className={commonStyles.formWrapper}>
                        <p className={spacing.spaceBelow16}>
                            If you acquired your shares on different dates or at different share prices, add the shares
                            in separate parcels. We’ll use this info to calculate your Portfolio returns, and you might
                            use it to work out your tax obligations—so it’s important the info is accurate.
                        </p>
                        <FieldArray name="records">
                            {({remove, push}) => (
                                <>
                                    {records.map((_record, index) => (
                                        <div
                                            className={cn(styles.parcel, index > 0 && spacing.spaceAbove20)}
                                            key={index}
                                        >
                                            <h1>{converter.toWordsOrdinal(index + 1)} parcel</h1>
                                            {index !== 0 && (
                                                <button
                                                    type="button"
                                                    onClick={() => remove(index)}
                                                    className={cn(accessibility.button, styles.trashIcon)}
                                                    title="Remove parcel"
                                                >
                                                    <Trash />
                                                </button>
                                            )}
                                            <DateInput
                                                dataTestId="date-input--date-of-purchase"
                                                name={`records[${index}].purchaseDate`}
                                                label="Date"
                                                additionalClassName={styles.dateInput}
                                                handleDateChange={(field, value) => {
                                                    setFieldValue(field, value)
                                                    if (
                                                        !getIn(touched, `records[${index}].price`) ||
                                                        !records[index].price
                                                    ) {
                                                        setPriceOnChange(value, price => {
                                                            setFieldValue(`records[${index}].price`, price)
                                                            setFieldTouched(`records[${index}].price`, false)
                                                        })
                                                    }
                                                }}
                                            />
                                            <StrongCurrency
                                                dataTestId="strong-currency--price-per-share"
                                                name={`records[${index}].price`}
                                                label="Price paid per share"
                                                disabled={isSubmitting}
                                                decimalPlaces={3}
                                                placeholder={parseFloat('23.1').toFixed(3)}
                                                currency={instrumentCurrency}
                                                helpText={
                                                    <p className={styles.walletRemaining}>
                                                        {records[index].price &&
                                                            closeDate &&
                                                            !getIn(touched, `records[${index}].price`) &&
                                                            `Based on the close price on ${closeDate}. Alter if required. This affects your displayed Portfolio returns.`}
                                                    </p>
                                                }
                                            />
                                            <StrongNumber
                                                dataTestId="strong-number--number-of-shares"
                                                name={`records[${index}].shares`}
                                                label="Number of shares to transfer in"
                                                disabled={isSubmitting}
                                                placeholder="Shares"
                                                normalisation="numberOnly"
                                                decimalPlaces={0}
                                                helpText={
                                                    <p className={styles.walletRemaining}>
                                                        You can only transfer whole shares, not fractions of a share.
                                                    </p>
                                                }
                                            />
                                        </div>
                                    ))}
                                    <ButtonAsLink onClick={() => push({})} noTextDecoration>
                                        + Add another parcel
                                    </ButtonAsLink>
                                </>
                            )}
                        </FieldArray>
                    </div>

                    <ActionBar>
                        <Button
                            dataTestId="button--next"
                            label="Next"
                            disabled={!isValid}
                            processing={isSubmitting}
                            onClick={() => handleSubmit()}
                        />
                    </ActionBar>
                </form>
            )}
        </Formik>
    )
}

export default AddTransferInRecordsForm
