import React from 'react'
import {toast, ToastContainer, cssTransition} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styles from './Toast.scss'

const transition = cssTransition({
    enter: styles.transitionIn,
    exit: styles.transitionOut,
    duration: [350, 350],
})

const ToastContent = ({message, onClick}: {message: string; onClick?: () => void}) =>
    onClick ? (
        <div className={styles.toastContent} role="button" onClick={onClick}>
            {message}
            <span>View</span>
        </div>
    ) : (
        <div className={styles.toastContent}>{message}</div>
    )

export const ToastWrapper = () => (
    <div aria-live="polite">
        <ToastContainer
            role="alert"
            hideProgressBar
            position="bottom-center"
            className={styles.container}
            toastClassName={styles.wrapper}
            bodyClassName={styles.body}
            autoClose={5000}
            transition={transition}
            closeButton={false}
            draggablePercent={20}
        />
    </div>
)

export const Toast = (message: string, onClick?: () => void, duration: number = 5000) => {
    // clear the current toast if there's any
    toast.dismiss()
    // show the new toast message
    toast(<ToastContent message={message} onClick={onClick} />, {autoClose: duration})
}
