import {Button} from '@design-system/button'
import {Calendar, Clock, Halt, Profile, SpareChange} from '@design-system/icon'
import {DateTime} from 'luxon'
import React from 'react'
import {IDENTITY_VERIFICATION_INTRO_SHOW_AFTER} from '~/global/constants/global'
import {dateFormatNoTime, dateFormatNoYear} from '~/global/utils/format-date/formatDate'
import {
    IdentityVerificationStep,
    showVerificationRequiredFlowIntro,
} from '~/global/utils/identity-verification/identityVerification'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {PageButtonGroup} from '~/global/widgets/button-page-group/PageButtonGroup'
import Image from '~/global/widgets/image/Image'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import idTypeDark from '~/sections/user/assets/images/id-type-dark.svg'
import idTypeLight from '~/sections/user/assets/images/id-type-light.svg'
import {useAppSelector} from '~/store/hooks'
import styles from './IdentityVerificationRequired.scss'

const IdentityVerificationRequired: React.FunctionComponent<IdentityVerificationInfromationProps> = ({setStep}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const identityVerification = useAppSelector(s => s.identity.user!.checks.identity_verification)

    const additionalVerificationReason = identityVerification.additional_verification_required_reason
    // The restrict account after date can either come from the biometric check or the secondary id check so
    // grab one or the other (or none).
    const restrictAccountAfter =
        identityVerification.latest_biometric_verification_check?.restrict_account_after ||
        identityVerification.secondary_identity_document_check?.restrict_account_after
    const pastExpiry = restrictAccountAfter ? restrictAccountAfter < DateTime.local() : false
    const additionalVerificationRequired = identityVerification.additional_verification_required
    const isIdentityLinked = identityVerification.is_identity_linked
    const biometricVerified = identityVerification.biometric_verified

    const content = (): React.ReactNode => {
        if (!restrictAccountAfter) {
            return <></>
        }
        switch (additionalVerificationReason) {
            case 'threshold_exceeded': // user has triggered verification by passing the deposit threshold
                return (
                    <>
                        <h1>
                            Verify your identity by {restrictAccountAfter.toFormat(dateFormatNoYear)} to keep investing
                        </h1>
                        <div className={styles.contentList}>
                            <SpareChange />
                            <p>We’re required to verify your identity once you’ve put in over $1000.</p>
                        </div>
                        <div className={styles.contentList}>
                            <Profile />
                            <p>Just take a quick selfie and a photo of your ID, and we’ll handle the rest.</p>
                        </div>
                        <div className={styles.contentList}>
                            <Calendar className={styles.contentListNewIcon} />
                            <p>
                                After {restrictAccountAfter.toFormat(dateFormatNoTime)}, you’ll need to verify your
                                identity before you can buy, sell, top up, or withdraw.
                            </p>
                        </div>
                    </>
                )
            case 'threshold_exceeded_restricted': // user has not completed verification within the grace period
                return (
                    <>
                        <h1>Your account has been restricted</h1>
                        <div className={styles.contentList}>
                            <Halt />
                            <p>We’re required to restrict your account until you verify your identity.</p>
                        </div>
                        <div className={styles.contentList}>
                            <Profile />
                            <p>Verify your identity now to buy, sell, top up, and withdraw.</p>
                        </div>
                        <div className={styles.contentList}>
                            <Clock />
                            <p>Just take a quick selfie and a photo of your ID, and we’ll handle the rest.</p>
                        </div>
                    </>
                )
            case 'operator_required': // triggered by an operator
                return (
                    <>
                        <h1>Verify your identity to invest</h1>
                        <div className={styles.contentList}>
                            <Halt />
                            <p>
                                Our Operations team needs to verify your identity. After this, you’ll be able to buy,
                                sell, top up, and withdraw.
                            </p>
                        </div>
                        <div className={styles.contentList}>
                            <Profile />
                            <p>Just take a quick selfie and a photo of your ID, and we’ll handle the rest.</p>
                        </div>
                    </>
                )
            default:
                return <></>
        }
    }

    // TODO: move to utils
    // set the time for user to see next time (24 hours) then redirect to `/portfolio` page
    const displayOnTomorrow = () => {
        window.localStorage.setItem(IDENTITY_VERIFICATION_INTRO_SHOW_AFTER, DateTime.local().plus({hours: 24}).toISO())
    }

    React.useEffect(() => {
        if (!showVerificationRequiredFlowIntro()) {
            setStep('verificationIdSelection')
        }
    })

    const handleShowVerificationRequiredFlow = (): void => {
        displayOnTomorrow()
        if (jurisdiction === 'au' && isIdentityLinked && biometricVerified) {
            setStep('confirmIdentityDetails')
        } else {
            setStep('verificationIdSelection')
        }
    }

    const handleSkipVerificationRequiredFlow = (): void => {
        displayOnTomorrow()
        navigate(profileUrl(''))
    }

    if (additionalVerificationRequired) {
        return (
            <>
                <Toolbar dataTestId="toolbar--biometrics" />
                <Page withoutDefaultPadding>
                    <div className={styles.graphicHeader}>
                        <Image src={idTypeLight} darkSrc={idTypeDark} />
                    </div>
                    <div className={styles.messageBody}>
                        {content()}
                        <PageButtonGroup>
                            <Button
                                label="Verify your identity"
                                onClick={handleShowVerificationRequiredFlow}
                                dataTestId="button--verify-identity"
                            />
                            <Button
                                label={pastExpiry ? 'View Profile' : 'Skip for now'}
                                type="secondary"
                                onClick={handleSkipVerificationRequiredFlow}
                                dataTestId="button--view-or-skip"
                            />
                        </PageButtonGroup>
                    </div>
                </Page>
            </>
        )
    } else {
        return <></>
    }
}

interface IdentityVerificationInfromationProps {
    setStep(step: IdentityVerificationStep): void
    className?: string
}

export default IdentityVerificationRequired
