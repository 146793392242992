/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    InstrumentHolding,
    InstrumentHoldingFromJSON,
    InstrumentHoldingFromJSONTyped,
    InstrumentHoldingToJSON,
    InstrumentHoldingToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface VerifyInstrumentHoldingsResponse
 */
export interface VerifyInstrumentHoldingsResponse {
    /**
     *
     * @type {string}
     * @memberof VerifyInstrumentHoldingsResponse
     */
    portfolio_uuid: string
    /**
     *
     * @type {Array<InstrumentHolding>}
     * @memberof VerifyInstrumentHoldingsResponse
     */
    instruments_missing: Array<InstrumentHolding>
    /**
     *
     * @type {Array<InstrumentHolding>}
     * @memberof VerifyInstrumentHoldingsResponse
     */
    instruments_with_discrepancies: Array<InstrumentHolding>
    /**
     *
     * @type {Array<InstrumentHolding>}
     * @memberof VerifyInstrumentHoldingsResponse
     */
    instruments_verified: Array<InstrumentHolding>
}

export function VerifyInstrumentHoldingsResponseFromJSON(json: any): VerifyInstrumentHoldingsResponse {
    return VerifyInstrumentHoldingsResponseFromJSONTyped(json, false)
}

export function VerifyInstrumentHoldingsResponseFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): VerifyInstrumentHoldingsResponse {
    if (json === undefined || json === null) {
        return json
    }
    return {
        portfolio_uuid: json['portfolio_uuid'],
        instruments_missing: (json['instruments_missing'] as Array<any>).map(InstrumentHoldingFromJSON),
        instruments_with_discrepancies: (json['instruments_with_discrepancies'] as Array<any>).map(
            InstrumentHoldingFromJSON,
        ),
        instruments_verified: (json['instruments_verified'] as Array<any>).map(InstrumentHoldingFromJSON),
    }
}

export function VerifyInstrumentHoldingsResponseToJSON(value?: VerifyInstrumentHoldingsResponse | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        portfolio_uuid: value.portfolio_uuid,
        instruments_missing: (value.instruments_missing as Array<any>).map(InstrumentHoldingToJSON),
        instruments_with_discrepancies: (value.instruments_with_discrepancies as Array<any>).map(
            InstrumentHoldingToJSON,
        ),
        instruments_verified: (value.instruments_verified as Array<any>).map(InstrumentHoldingToJSON),
    }
}
