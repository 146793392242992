import {DateTime} from 'luxon'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'

export const convertDateTime = (timestamp: string | DateTime): string => {
    if (timestamp instanceof DateTime) {
        return timestamp.toFormat(dateFormatNoTime)
    }
    return DateTime.fromISO(timestamp).toFormat(dateFormatNoTime)
}

export const convertDateTimeToISO = (timestamp: string | DateTime): string => {
    if (timestamp instanceof DateTime) {
        return timestamp.toISODate()
    }
    return DateTime.fromISO(timestamp).toISODate()
}
