import React from 'react'

const KiwiSaverAdminFeesDetail: React.FC = () => {
    return (
        <div>
            <h1>Admin fee</h1>
            <p>You’ll be charged a 0.15% per year administration fee on the balance of each of your picks.</p>
            <p>
                The admin fee is included in the unit price of each of your picks—that means you won’t see it directly
                deducted from your KiwiSaver balance.
            </p>
        </div>
    )
}

export default KiwiSaverAdminFeesDetail
