import {Button} from '@design-system/button'
import React from 'react'
import {useNavigate} from 'react-router'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'

const SellIntro: React.FunctionComponent<{urlSlug: string}> = ({urlSlug}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    return (
        <>
            <Toolbar dataTestId="toolbar--portfolio-order-sell-intro" title="Selling investments" leftButton="back" />
            <Page>
                <p>
                    When you sell investments, the money has to stay within your KiwiSaver portfolio. When you sell,
                    money is reinvested across the investments in your investment plan.
                </p>
                <ul>
                    <li>
                        <b>When selling</b>, at least 50% of your balance needs to remain invested in your base fund.
                    </li>
                    <li className={spacing.spaceAbove16}>
                        <b>When reinvesting into a self-select investment</b>, the balance won’t be allowed to go above
                        5% of your total KiwiSaver balance.
                    </li>
                </ul>
                <p>
                    Before selling, it’s worth checking your investment plan to make sure you’re happy with where the
                    money will be reinvested.
                </p>
                <Button
                    pageButton
                    dataTestId="button--portfolio-order-start-sell-flow"
                    label="Continue to sell"
                    onClick={() => {
                        navigate(profileUrl('kiwisaver/:urlSlug/sell/form', {urlSlug}))
                    }}
                />
            </Page>
        </>
    )
}

export default SellIntro
