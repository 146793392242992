import {semanticColour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import ContentLoader from 'react-content-loader'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {useNavigate} from '~/migrate-react-router'
import styles from './TransactionRow.scss'

interface TransactionRowProps {
    amount?: string
    currency: string
    date: DateTime
    description?: string | null
    icon: JSX.Element
    title?: string
    url?: string
    ref: React.Ref<HTMLButtonElement>
}

export const TransactionRow = React.forwardRef(
    (
        {amount, currency, date, description, icon, title, url}: TransactionRowProps,
        ref: React.ForwardedRef<HTMLButtonElement>,
    ) => {
        const navigate = useNavigate()

        return (
            <button
                className={styles.transactionRow}
                onClick={() => (url ? navigate(url) : null)}
                ref={ref}
                type="button"
            >
                <div className={styles.iconAndDescription}>
                    {icon}
                    <p className={styles.title}>{title}</p>
                </div>
                {description && <p className={styles.description}>{description}</p>}
                <div className={styles.dateAndAmount}>
                    <p>{date.toFormat(dateFormatNoTime)}</p>
                    {amount && (
                        <p>
                            <DollarValue value={amount} currency={currency} decimalPlaces={2} />
                        </p>
                    )}
                </div>
            </button>
        )
    },
)

export const TransactionRowSkeleton = () => {
    const colourMode = useColourMode()
    return (
        <div className={cn(styles.transactionRow, styles.skeleton)}>
            <ContentLoader
                foregroundColor={semanticColour('background-secondary', colourMode)}
                backgroundColor={semanticColour('background-tertiary', colourMode)}
            >
                {/* Place holder icon */}
                <rect width={16} height={16} x={16} y={0} rx="2" />
                {/* Place holder title */}
                <rect width={200} height={14} x={40} y={1} rx="2" />
                {/* Place holder subtitle */}
                <rect width={180} height={14} x={40} y={24} rx="2" />
                {/* Place holder date */}
                <rect width={80} height={14} x={40} y={52} rx="2" />
            </ContentLoader>
        </div>
    )
}

export default TransactionRow
