import {useColourMode} from '@design-system/use-colour-mode'
import React from 'react'
import {VIEWPORT_WIDTHS} from '~/global/constants/global'

interface ImageProps {
    src: string | [string, number][]
    darkSrc?: string | [string, number][]
    className?: string
    alt?: React.ImgHTMLAttributes<HTMLImageElement>['alt']
    loading?: React.ImgHTMLAttributes<HTMLImageElement>['loading']
    width?: React.ImgHTMLAttributes<HTMLImageElement>['width']
    height?: React.ImgHTMLAttributes<HTMLImageElement>['height']
}

/**
 * Image renderer with built in optional dark mode and srcSet support
 *
 * ```
 * import myImageDesktop from '~/path/to/image-desktop.png'
 * import myImageMobile from '~/path/to/image-mobile.png'
 *
 * <Image
 *      src={[
 *          [myImageMobile, 553], // imported file and actual image width
 *          [myImageDesktop, 1124],
 *      ]}
 *     alt="Picture of a cat sleeping"
 *     className={styles.splashImage}
 * />
 * ```
 *
 * See https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images for more information on responsive images.
 *
 * @param {ImageProps} props - props
 * @param {ImageProps['src']} props.src - imported image file, or array of imported image files with the image width. the best quality image should be last.
 * @param {ImageProps['darkSrc']} props.darkSrc - alternative (for dark mode) imported image file, or array of imported image files with the image width. the best quality image should be last.
 * @param {ImageProps['alt']} props.alt - optional description of the image for screen readers - a blank string indicates the image is decorative and doesn't need a description
 * @param {ImageProps['className']} props.className - optional styling class
 */
export const Image = ({src, darkSrc, alt = '', className, loading, width, height}: ImageProps) => {
    const [lightOrDarkSrc, setLightOrDarkSrc] = React.useState<ImageProps['src']>(src)
    const colourMode = useColourMode()

    React.useEffect(() => {
        setLightOrDarkSrc(colourMode === 'dark' && darkSrc ? darkSrc : src)
    }, [colourMode])

    // if an array of srcs is provided, we want to use them all in the srcSet
    const srcSet = Array.isArray(lightOrDarkSrc)
        ? lightOrDarkSrc.reduce((acc, pathAndWidth) => {
              return acc ? `${acc}, ${pathAndWidth[0]} ${pathAndWidth[1]}w` : `${pathAndWidth[0]} ${pathAndWidth[1]}w`
          }, '')
        : undefined

    // sizes allows the browser to more intelligently pick a suitable srcSet
    // image - because on desktop our image won't actually be the full width of
    // the viewport, it's constrained by the globalWrapper width
    const sizes = srcSet ? `(min-width: ${VIEWPORT_WIDTHS.DESKTOP}px) ${VIEWPORT_WIDTHS.DESKTOP}px, 100vw` : undefined

    // if the src is an array, we only want to use the path of the last one (default to best quality)
    const basicSrc = Array.isArray(lightOrDarkSrc) ? lightOrDarkSrc[lightOrDarkSrc.length - 1][0] : lightOrDarkSrc

    return (
        <img
            src={basicSrc}
            srcSet={srcSet}
            sizes={sizes}
            alt={alt}
            className={className}
            loading={loading}
            width={width}
            height={height}
        />
    )
}

export default Image
