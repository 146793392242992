import {colour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import React from 'react'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ProductIntroSlideContent} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {ProductIntroSlides} from '~/global/widgets/intro-slides/IntroSlides'
import {useLocation, useNavigate} from '~/migrate-react-router'
import {useAppSelector} from '~/store/hooks'

export const AutoInvestIntroSlides: React.FunctionComponent = () => {
    const profileUrl = useProfileUrl()
    const navigate = useNavigate()
    const jurisdiction = useAppSelector(s => s.identity.user?.jurisdiction)
    const {state} = useLocation()
    const colourMode = useColourMode()

    const content: ProductIntroSlideContent[] = [
        {
            backgroundColour: colour('Melon100', colourMode),
            image:
                jurisdiction === 'au'
                    ? require(`../../assets/images/au-slides/add-your-investments.png`)
                    : require(`../../assets/images/nz-slides/add-your-investments.png`),
            title: 'Add your investments',
            buttonLabel: 'Next',
            bodyRenderer: () => (
                <p>
                    Choose from over 8,000 investments across US, Aussie, and NZ exchanges, and set the percentage to
                    invest in each.
                </p>
            ),
        },
    ]
    content.push(
        {
            backgroundColour: colour('Melon100', colourMode),
            image:
                jurisdiction === 'au'
                    ? require('../../assets/images/au-slides/how-much-and-when.png')
                    : require('../../assets/images/nz-slides/how-much-and-when.png'),
            title: 'Set how much and when',
            buttonLabel: 'Next',
            bodyRenderer: () => (
                <p>
                    Starting from a date that works for you, invest any amount over $5 every week, fortnight, four
                    weeks, or month.
                </p>
            ),
        },
        {
            backgroundColour: colour('Melon100', colourMode),
            image:
                jurisdiction === 'au'
                    ? require('../../assets/images/au-slides/personalise-your-order.png')
                    : require('../../assets/images/nz-slides/personalise-your-order.png'),
            title: 'Personalise your order',
            buttonLabel: 'Next',
            bodyRenderer: () => (
                <p>
                    Stay focused on your investing goals, or describe the theme of your investments, by giving your
                    order a face and a name.
                </p>
            ),
        },
        {
            backgroundColour: colour('Melon100', colourMode),
            image:
                jurisdiction === 'au'
                    ? require('../../assets/images/au-slides/good-to-go.png')
                    : require('../../assets/images/nz-slides/good-to-go.png'),
            title: 'You’re good to go!',
            buttonLabel: 'Got it',
            bodyRenderer: () => (
                <p>With your order up and running, you can pause, change investments, or delete at any time.</p>
            ),
        },
    )

    return (
        <ProductIntroSlides
            content={content}
            onComplete={async () => {
                state?.autoInvestAUCard ? navigate(profileUrl('auto-invest')) : navigate(-1)
            }}
        />
    )
}
