import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {reEnterPasswordMessage} from '~/global/utils/error-text/errorText'
import {formatFullName, FullNameType} from '~/global/utils/format-full-name/formatFullName'
import {getTransferInstrumentValueHomeCurrency} from '~/global/utils/share-transfers/shareTransfers'
import {useExchangeRates} from '~/global/utils/use-exchange-rates/useExchangeRates'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useTransferInfo} from '~/global/utils/use-transfer-info/useTransferInfo'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {ErrorBox} from '~/global/widgets/form-controls'
import recordStyles from '~/global/widgets/instrument-activity/Record.scss'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {OrderConfirmation} from '~/global/widgets/order-confirmation/OrderConfirmation'
import Page from '~/global/widgets/page/Page'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import instrumentActions from '~/store/instrument/actions'
import actions from '~/store/transfer/actions'
import {NEW_DRS_REQUIRED, TransferOrderInstrument} from '~/store/transfer/types'

export default function TransferSharesConfirm() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const instrumentsById = useAppSelector(({instrument}) => instrument.instrumentsById)
    const transfersLoadingState = useAppSelector(({transfer}) => transfer.transfersLoadingState)
    const stagedTransferOrder = useAppSelector(({transfer}) => transfer.stagedTransferOrder)
    const homeCurrency = useAppSelector(({identity}) => identity.user!.home_currency)
    const [fullName, setFullName] = React.useState<FullNameType | null>(null)
    const [authenticationError, setAuthenticationError] = React.useState<Error | null>(null)
    const groupedTransfers = useAppSelector(({transfer}) => transfer.groupedTransfers)
    const transferOrderGroup = groupedTransfers.find(group => group.group_id === stagedTransferOrder?.groupId)

    const [exchangeRates] = useExchangeRates()
    const transferInfo = useTransferInfo(transferOrderGroup?.direction, transferOrderGroup?.us_transfer_broker)

    // Save all the Staged instrument entiries via API
    React.useEffect(() => {
        dispatch(actions.UploadTransferInstruments())
        authenticateAndFetchFullName()
    }, [])

    // Load instruments in the transfer
    React.useEffect(() => {
        if (!stagedTransferOrder?.instruments) {
            return
        }
        // Check we've loaded every instrument in this transfer
        const stagedInstrumentIds = stagedTransferOrder?.instruments.map(({instrumentId}) => instrumentId)
        if (stagedInstrumentIds && !stagedInstrumentIds.every(instrument => Boolean(instrumentsById[instrument]))) {
            dispatch(instrumentActions.getInstrumentsByIds(stagedInstrumentIds))
        }
    }, [stagedTransferOrder?.instruments, instrumentsById])

    // Fetch the customer's full name. As this is PII, this is kept outside the redux store
    const authenticateAndFetchFullName = async () => {
        if (fullName?.firstName) {
            return
        }
        const response: Response.FullName | string | any = await dispatch(identityActions.GetFullName())
        if (response.type === 'full_name') {
            setFullName({
                firstName: response.first_name,
                middleName: response.middle_name,
                lastName: response.last_name,
            })
        } else if (response === reEnterPasswordMessage) {
            setAuthenticationError(new Error(reEnterPasswordMessage))
        }
    }

    const orderItems = (instrument: TransferOrderInstrument) => {
        const shareSum = instrument.records.reduce((sum, record) => {
            if (record.shares) {
                return sum + parseInt(record.shares, 10)
            } else {
                return sum
            }
        }, 0)

        const sharePrice = instrument.records[0].price || 0
        const fee = transferInfo?.fees.NYSE.charge_amount

        const itemsForMap = [
            {
                description: `Shares to transfer ${stagedTransferOrder?.direction}`,
                value: shareSum,
            },
            {
                description: 'Share price',
                value: <DollarValue value={sharePrice.toString()} decimalPlaces={3} />,
            },
        ]
        if (stagedTransferOrder?.usTransferPlatform === 'EXTERNAL' && fee) {
            itemsForMap.push({
                description: 'Transfer fee',
                value: <DollarValue value={fee} decimalPlaces={0} currency="USD" />,
            })
        }
        return itemsForMap
    }

    const orderTotal = (instrument: TransferOrderInstrument) => {
        const shareSum = instrument.records.reduce((sum, record) => {
            if (record.shares) {
                return sum + parseInt(record.shares, 10)
            } else {
                return sum
            }
        }, 0)

        const instrumentById = instrumentsById[instrument.instrumentId]
        const transferValue = getTransferInstrumentValueHomeCurrency(
            instrumentById,
            shareSum,
            exchangeRates,
            homeCurrency,
        )
        const currency = homeCurrency.toUpperCase()
        return {
            description: 'Transfer amount',
            value: `$${transferValue} ${currency}`,
        }
    }

    const handleSubmit = async () => {
        if (stagedTransferOrder?.usTransferPlatform === 'EXTERNAL') {
            navigate(
                profileUrl('invest/portfolio-transfer-shares/us/:groupId/upload-documents', {
                    groupId: stagedTransferOrder!.groupId!,
                }),
            )
        } else {
            navigate(
                profileUrl('invest/portfolio-transfer-shares/us/:groupId/download-transfer-form', {
                    groupId: stagedTransferOrder!.groupId!,
                }),
            )
        }
    }

    if (!stagedTransferOrder) {
        navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})
    }

    const isLoading =
        stagedTransferOrder?.instruments.length === 0 || Object.keys(instrumentsById).length === 0 || !transferInfo

    return (
        <>
            <Toolbar dataTestId="toolbar--us-transfer-confirm" leftButton="back" />
            <Page>
                <h1 className={cn(commonStyles.heading, spacing.spaceBelow24)}>Confirm your transfer details</h1>

                <p className={spacing.spaceBelow24}>
                    Make sure your account details are correct, and you’ve added all the shares you want to transfer.{' '}
                </p>

                <div className={spacing.spaceBelow16}>
                    <p className={commonStyles.formLabel}>Account name</p>
                    <p>{fullName && formatFullName(fullName)}</p>
                </div>

                {stagedTransferOrder?.referenceType === 'BAN' && (
                    <div className={spacing.spaceBelow16}>
                        <p className={commonStyles.formLabel}>Brokerage account number</p>
                        <p>{stagedTransferOrder?.reference}</p>
                    </div>
                )}

                {stagedTransferOrder?.referenceType === 'DRS' && stagedTransferOrder.reference !== NEW_DRS_REQUIRED && (
                    <div className={spacing.spaceBelow16}>
                        <p className={commonStyles.formLabel}>Transfer agent account number</p>
                        <p>{stagedTransferOrder?.reference}</p>
                    </div>
                )}

                <div className={spacing.spaceBelow16}>
                    <p className={commonStyles.formLabel}>Shares you’re transferring</p>
                </div>

                <ErrorBox message={authenticationError} />
                {isLoading || transfersLoadingState === 'loading' ? (
                    <Loading />
                ) : (
                    <>
                        {stagedTransferOrder?.instruments!.map(transferOrderInstrument => {
                            const instrument = instrumentsById[transferOrderInstrument.instrumentId]

                            return (
                                <OrderConfirmation
                                    key={transferOrderInstrument.instrumentId}
                                    title={instrument.name}
                                    subtitle={
                                        <p className={recordStyles.instrumentSymbol}>
                                            <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                                            <PronounceLetters text={instrument.exchange} />
                                        </p>
                                    }
                                    image={<InstrumentLogo instrument={instrument} noBorder />}
                                    items={orderItems(transferOrderInstrument)}
                                    total={orderTotal(transferOrderInstrument)}
                                />
                            )
                        })}
                        <div className={commonStyles.feesTotalRow}>
                            <OrderConfirmation
                                items={[]}
                                total={{
                                    value: (
                                        <DollarValue
                                            currency="USD"
                                            decimalPlaces={0}
                                            value={transferOrderGroup?.total_group_fee || 0}
                                        />
                                    ),
                                    description: 'Total fee',
                                }}
                            />
                        </div>
                    </>
                )}
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--confirm-transfer"
                    label="Next"
                    onClick={handleSubmit}
                    disabled={transfersLoadingState !== 'ready'}
                />
            </ActionBar>
        </>
    )
}
