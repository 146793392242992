import platform from 'platform'

/**
 * @description Intended for reporting uses only. Do not use for deciding if browser functionality is available.
 * Returns a human-readable result like:
 * - "Chrome 107.0.0.0 on OS X 10.15.7 64-bit"
 * - "Safari 16.0 on Apple iPhone (iOS 16.0)"
 * - "Microsoft Edge 103.0.1264.37 on Windows 10 64-bit"
 */
export const getDeviceInformation = () => {
    return platform.parse(navigator.userAgent).description
}
