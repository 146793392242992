import React from 'react'
import {dateFormatNoYearShortMonth} from '~/global/utils/format-date/formatDate'
import {isInstrumentDerivative} from '~/global/utils/is-instrument-derivative/isInstrumentDerivative'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import PricesCompanyEtfModal from '~/global/widgets/help-modals/PricesCompanyEtf'
import PricesExtendedHoursModal from '~/global/widgets/help-modals/PricesExtendedHours'
import PricesKiwiSaverModal from '~/global/widgets/help-modals/PricesKiwiSaver'
import PricesManagedFundModal from '~/global/widgets/help-modals/PricesManagedFund'
import {SharePriceWithExchangeTooltip} from '~/global/widgets/share-price-with-exchange-tooltip/SharePriceWithExchangeTooltip'
import {useUsLivePricing} from '~/sections/invest/sections/view-instrument/hooks/usLivePricing'
import {useAppSelector} from '~/store/hooks'
import {selectExchangeForInstrument, selectIsInstrumentInExtendedHours} from '~/store/instrument/selectors'
import {Instrument} from '~/store/instrument/types'
import {StagedSellOrder, StagedBuyOrder} from '~/store/order/types'
import styles from './BuyOrSellCurrentPriceWithModal.scss'

interface BuyOrSellCurrentPriceProps {
    instrument: Instrument
    orderType?: StagedSellOrder['orderType'] | StagedBuyOrder['orderType']
    bigPrice?: boolean
}

/**
 * TODO I&S: a bunch of the logic in this file really needs to be rethought
 * https://sharesies.atlassian.net/browse/CONF-2419
 */
export const BuyOrSellCurrentPriceWithModal: React.FunctionComponent<BuyOrSellCurrentPriceProps> = ({
    instrument,
    orderType,
    bigPrice,
}) => {
    const rootState = useAppSelector(s => s)
    const {isExchangeInMarketHours} = instrument.exchange
        ? selectExchangeForInstrument(rootState, instrument.exchange)
        : {isExchangeInMarketHours: false}
    const isInExtendedHours = useAppSelector(s => selectIsInstrumentInExtendedHours(s, instrument, orderType))
    const isDerivative = isInstrumentDerivative(instrument)
    const isKiwiSaverInstrument = useAppSelector(s => s.order.stagedSellOrder?.ksFundHolding)
    const usLivePricing = useUsLivePricing(instrument)

    const priceModal = () => {
        if (isKiwiSaverInstrument) {
            return <PricesKiwiSaverModal forBuySellFlow={true} />
        } else if (tradingType(instrument) === 'managed') {
            return <PricesManagedFundModal />
        } else if (tradingType(instrument) === 'listed') {
            return <PricesCompanyEtfModal instrument={instrument} />
        }
    }

    /**
     * The "when" of the price.
     *
     * e.g.
     * - Company with live price: "live price"
     * - Managed fund: "26 Feb"
     * - Company in market open: "delayed at least 20 mins"
     * - Company in market close: "at close 26 Feb at 17:00"
     */
    const priceTemporalInformation = () => {
        if (usLivePricing) {
            return 'live price'
        }

        if (tradingType(instrument) === 'managed') {
            return instrument.marketLastCheck.toFormat(dateFormatNoYearShortMonth)
        }

        if (isExchangeInMarketHours) {
            return 'delayed at least 20 mins'
        }

        // TODO I&S: change hardcoded value of 17h to the correct time
        // https://sharesies.atlassian.net/browse/CONF-2419
        return `at close ${instrument.marketLastCheck.toFormat(dateFormatNoYearShortMonth)} at 17:00`
    }

    /** Text next to the price for both normal and bigPrice scenarios */
    const priceText = (
        <>
            {isDerivative && <>per {shareLabel({instrument})} </>}
            {priceTemporalInformation()} {priceModal()}
        </>
    )

    const price = () => {
        if (usLivePricing) {
            return usLivePricing.tradePrice
        }

        if (isInExtendedHours && instrument.extendedHoursPrice) {
            return instrument.extendedHoursPrice
        }

        return instrument.marketPrice
    }
    const displayPrice = price()

    /* Big price */
    if (bigPrice) {
        return (
            <>
                <div className={styles.currentPrice}>
                    {/* Live pricing */}
                    {usLivePricing && 'Live price'} {usLivePricing && priceModal()}
                    {/* Regular pricing */}
                    {!usLivePricing && 'Price'} {!usLivePricing && priceText}
                </div>
                <div className={styles.bigPrice}>
                    <SharePriceWithExchangeTooltip
                        price={displayPrice}
                        currency={instrument.currency}
                        shareLabel={shareLabel({instrument})}
                    />
                </div>
            </>
        )
    }

    /* Extended hours */
    if (isInExtendedHours && instrument.extendedHoursPrice) {
        return (
            <div className={styles.currentPrice}>
                <span className={styles.sharePrice}>
                    <SharePriceWithExchangeTooltip
                        price={displayPrice}
                        currency={instrument.currency}
                        shareLabel={shareLabel({instrument})}
                    />{' '}
                </span>
                {usLivePricing ? 'live extended hours price' : 'in extended hours'}{' '}
                <PricesExtendedHoursModal instrument={instrument} />
            </div>
        )
    }

    /* General case */
    return (
        <div className={styles.currentPrice}>
            <span className={styles.sharePrice}>
                <SharePriceWithExchangeTooltip
                    price={displayPrice}
                    currency={instrument.currency}
                    shareLabel={shareLabel({instrument})}
                />{' '}
            </span>
            {priceText}
        </div>
    )
}

export default BuyOrSellCurrentPriceWithModal
