import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {Response} from '~/api/retail/types'
import tooltipStyles from '~/global/scss/reused-styles/tooltip.scss'
import {useNextDividend} from '~/global/state-hooks/retail/useAnnouncedCorporateActions'
import {convertDateTime} from '~/global/utils/convert-date-time/convertDateTime'
import {dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import {Loader, ArrowUp, ArrowDown} from '~/global/widgets/OLD_icons'
import {DividendValue, SharePriceValue} from '~/global/widgets/number-elements/NumberElements'
import {Tooltip} from '~/global/widgets/tooltip/Tooltip'
import {BasicInstrument} from '~/store/instrument/types'
import styles from './Header.scss'

const UpcomingDividend: React.FC<{instrument: BasicInstrument}> = ({instrument}) => {
    const nextDividend = useNextDividend(instrument)
    const cashOutcomeRule = nextDividend?.outcomes.find(outcome => outcome.currency)

    if (nextDividend && cashOutcomeRule) {
        return (
            <p className={styles.dividend}>
                <DividendValue value={cashOutcomeRule.amount_per_input_unit} currency={cashOutcomeRule.currency!} />{' '}
                declared dividend per share if held on {convertDateTime(nextDividend.record_date)}
            </p>
        )
    }
    return null
}

interface Props {
    instrument: BasicInstrument
    liveMarketData: Response.LiveDataFetch['live_data']
    fetchLiveMarketData: () => void
    isFromMidnightToOpen: boolean
}

const Header: React.FunctionComponent<Props> = ({
    instrument,
    liveMarketData,
    fetchLiveMarketData,
    isFromMidnightToOpen,
}) => {
    const renderLastTradedPriceFluctuationIcon = () => {
        const {last_traded_price, reference_price} = liveMarketData
        if (!last_traded_price || !reference_price) {
            return
        }
        if (last_traded_price > reference_price) {
            return <ArrowUp />
        }
        if (last_traded_price < reference_price) {
            return <ArrowDown />
        }
        return
    }

    return (
        <div
            className={cn(styles.header, {
                [styles.headerClosed]: liveMarketData.market_state === 'closed',
            })}
        >
            <div>
                {liveMarketData.market_state === 'closed' ? (
                    <Tooltip>
                        <p className={cn(styles.smallTitle, tooltipStyles.label)}>Last traded</p>
                        <div className={tooltipStyles.tooltip}>
                            <p>The last traded price of an investment at the end of the most recent trading day</p>
                        </div>
                    </Tooltip>
                ) : (
                    <Tooltip>
                        <p className={cn(styles.smallTitle, tooltipStyles.label)}>Last traded</p>
                        <div className={tooltipStyles.tooltip}>
                            <p>The price that the most recent trade went through at</p>
                        </div>
                    </Tooltip>
                )}
                <p className={cn(styles.smallTitle, styles.serverTime)}>
                    {liveMarketData.server_time.toFormat(dateFormatWithYear)}
                </p>
            </div>
            <div className={styles.lastTradedPriceAndRefreshButtonContainer}>
                <div className={styles.lastTradedPriceContainer}>
                    <p className={styles.lastTradedPrice}>
                        {liveMarketData.last_traded_price ? (
                            <SharePriceValue value={liveMarketData.last_traded_price} />
                        ) : (
                            '$ -'
                        )}
                    </p>
                    {renderLastTradedPriceFluctuationIcon()}
                </div>
                <Button
                    label={
                        <div className={styles.refreshButton}>
                            <p>Refresh</p>
                            <Loader />
                        </div>
                    }
                    type="secondary"
                    onClick={() => fetchLiveMarketData()}
                    dataTestId="button--live-market-refresh"
                />
            </div>

            <UpcomingDividend instrument={instrument} />

            <hr />

            <div className={styles.referencePriceContainer}>
                <div>
                    <Tooltip>
                        <p className={cn(styles.smallTitle, tooltipStyles.label)}>Prev. Close</p>
                        <div className={tooltipStyles.tooltip}>
                            <p>The last traded price of an investment at the end of the previous trading day</p>
                        </div>
                    </Tooltip>
                    {liveMarketData.reference_price ? (
                        <p className={styles.referencePrice}>
                            <SharePriceValue value={liveMarketData.reference_price} />
                        </p>
                    ) : (
                        <p className={cn(styles.referencePrice)}>$ -</p>
                    )}
                </div>
                <div>
                    <Tooltip>
                        <p className={cn(styles.smallTitle, tooltipStyles.label)}>Today’s high</p>
                        <div className={tooltipStyles.tooltip}>
                            <p>The highest price an investment has traded at today</p>
                        </div>
                    </Tooltip>
                    {isFromMidnightToOpen || !liveMarketData.high_price ? (
                        <p className={cn(styles.referencePrice)}>$ -</p>
                    ) : (
                        <p className={styles.referencePrice}>
                            <SharePriceValue value={liveMarketData.high_price} />
                        </p>
                    )}
                </div>
                <div>
                    <Tooltip>
                        <p className={cn(styles.smallTitle, tooltipStyles.label)}>Today’s low</p>
                        <div className={tooltipStyles.tooltip}>
                            <p>The lowest price an investment has traded at today</p>
                        </div>
                    </Tooltip>
                    {isFromMidnightToOpen || !liveMarketData.low_price ? (
                        <p className={cn(styles.referencePrice)}>$ -</p>
                    ) : (
                        <p className={styles.referencePrice}>
                            <SharePriceValue value={liveMarketData.low_price} />
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default React.memo(Header)
