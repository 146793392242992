import React from 'react'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/transfer/actions'
import {ResponseUsTransferPlatform, TransferDirection} from '~/store/transfer/types'

export const useTransferInfo = (
    transferDirection?: TransferDirection | undefined,
    usTransferPlatform?: ResponseUsTransferPlatform,
) => {
    const transferInfo = useAppSelector(s => s.transfer.transferInfo)
    const dispatch = useAppDispatch()

    React.useEffect(() => {
        if (!transferInfo) {
            dispatch(actions.FetchInfo(transferDirection, usTransferPlatform))
        }
    }, [transferInfo])

    return transferInfo
}
