import {Download, Edit, RecurringTopUp, Trash} from '@design-system/icon'
import React from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Image from '~/global/widgets/image/Image'
import {ListMenuGroup, ListMenuItem} from '~/global/widgets/list-menu/ListMenu'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import saveImage from '~/sections/save/assets/images/save-small.png'
import {useSelectedSaveAccount} from '~/sections/save/state/selectedSaveAccountState'
import {CloseAccountModal} from '~/sections/save/widgets/close-save-account/CloseAccountModal'
import {useAppSelector} from '~/store/hooks'
import styles from './Manage.scss'

interface ManageProps {
    portfolioId: string
}

export const Manage: React.FunctionComponent<ManageProps> = ({portfolioId}) => {
    const [showCloseAccountModal, setShowCloseAccountModal] = React.useState(false)

    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const selectedSaveAccount = useSelectedSaveAccount(portfolioId)

    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)

    if (!selectedSaveAccount) {
        return <Navigate to={profileUrl('')} replace />
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--save-manage"
                title={isDependent ? `Manage ${preferredName}’s Save account` : 'Manage your Save account'}
                leftButton="back"
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <div className={styles.manageProductHeader}>
                    <Image src={saveImage} />
                    <div className={styles.text}>
                        <h3>{selectedSaveAccount.name || 'Save'}</h3>
                        <DollarValue value={selectedSaveAccount.balance} />
                    </div>
                </div>

                <ListMenuGroup className={spacing.spaceBelow32}>
                    <ListMenuItem
                        label="Recurring transfers"
                        leftIcon={<RecurringTopUp />}
                        onClick={() =>
                            navigate(
                                profileUrl('save/recurring-transfers/:portfolioId', {
                                    portfolioId: selectedSaveAccount.portfolio_id,
                                }),
                            )
                        }
                    />
                    <ListMenuItem
                        label="Edit name"
                        leftIcon={<Edit />}
                        onClick={() =>
                            navigate(
                                profileUrl('save/personalise-account/:portfolioId', {
                                    portfolioId: selectedSaveAccount.portfolio_id,
                                }),
                            )
                        }
                    />

                    <ListMenuItem
                        label="Download documents"
                        leftIcon={<Download />}
                        onClick={() =>
                            navigate(
                                profileUrl('save/download-reports/:portfolioId', {
                                    portfolioId: selectedSaveAccount.portfolio_id,
                                }),
                            )
                        }
                    />
                    <ListMenuItem
                        label="Close Save account"
                        leftIcon={<Trash />}
                        onClick={() => setShowCloseAccountModal(true)}
                    />
                    <CloseAccountModal
                        isDependent={isDependent}
                        isOpen={showCloseAccountModal}
                        preferredName={preferredName}
                        selectedSaveAccount={selectedSaveAccount}
                        setIsOpen={setShowCloseAccountModal}
                    />
                </ListMenuGroup>
            </Page>
        </>
    )
}
