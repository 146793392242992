import React from 'react'
import {Loading} from '~/global/widgets/loading'
import styles from './LoadingScreen.scss'

const LoadingScreen = () => (
    <div
        data-testid="loading-page"
        className={styles.loading}
        style={{
            display: 'none', // the initial position of the loader isn't correct until the CSS files load in
        }}
    >
        <Loading isPineapple />
    </div>
)

export default LoadingScreen
