import {Button} from '@design-system/button'
import {SelectCard} from '@design-system/select-cards' // TODO refactor to use SelectCards
import cn from 'classnames'
import React, {useState} from 'react'
import {AutoFocusInside} from 'react-focus-lock'
import {Navigate} from 'react-router-dom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import capitalise from '~/global/utils/capitalise-string/capitaliseString'
import {isOnNZX, isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ExchangeHours} from '~/global/widgets/exchange-hours/ExchangeHours'
import {SwitchField} from '~/global/widgets/form-controls'
import {OrderTypesModal} from '~/global/widgets/help-modals/MarketAndLimitOrders'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import panelStyles from '~/sections/invest/sections/order-flow/InvestPanel.scss'
import styles from '~/sections/invest/sections/order-flow/OrderForm.scss'
import {
    SHARE_LIMIT,
    DOLLAR_MARKET,
    DOLLAR_LIMIT,
    DOLLAR_TRIGGER,
} from '~/sections/invest/sections/order-flow/constants/orderTypes'
import {getLabelByBuyOrderType} from '~/sections/invest/sections/order-flow/utils/get-label-by-buy-order-type/getLabelByBuyOrderType'
import {essHasActiveBlackoutPeriodForInstrument} from '~/store/employeeShareScheme/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import actions from '~/store/order/actions'
import {StagedBuyOrder} from '~/store/order/types'
import signUpActions from '~/store/sign-up/actions'

interface OrderTypeProps {
    instrument: Instrument
    onContinue(): void
    autoExercise?: boolean
    onPageBack?(): void
}

const BuyOrderType: React.FunctionComponent<OrderTypeProps> = ({instrument, onContinue, autoExercise, onPageBack}) => {
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const stagedBuyOrder = useAppSelector(({order}) => order.stagedBuyOrder)
    const [orderType, setOrderType] = useState<StagedBuyOrder['orderType'] | null>(stagedBuyOrder?.orderType ?? null)

    const essHasActiveBlackoutPeriod = useAppSelector(s => essHasActiveBlackoutPeriodForInstrument(s, instrument.id))
    const isNZInstrument = isOnNZX(instrument)
    const canPlaceTriggerBuyOrders = isOnMainUsExchange(instrument)
    const [showAdvancedOrderTypes, setShowAdvancedOrderTypes] = useState(orderType === DOLLAR_TRIGGER)
    const usResignStatus = useAppSelector(s => s.signUp.usResignStatus)

    React.useEffect(() => {
        dispatch(signUpActions.getUSResignStatus())
    }, [])

    // Force the investor to resign the W8 tax form.
    if (usResignStatus?.resign_overdue) {
        return <Navigate to={profileUrl('us-sign-up')} replace />
    }

    const onSubmit = (type: StagedBuyOrder['orderType']) => {
        rudderTrack('buy', 'order_type_selected', {order_type: type.includes('limit') ? 'limit' : 'market'})

        /*
        - if there was no staged buy order -> initialise a new staged buy order
        - if there was a staged buy order, but the user is switching the order type
            -> re-initialise the staged buy order to ensure dp limits are recalculated
        - if there was a staged buy order, and the user selected the same type of order
            -> don't do anything and keep the previous staged buy order
        */
        if (!stagedBuyOrder || (stagedBuyOrder && stagedBuyOrder.orderType !== type)) {
            dispatch(actions.InitialiseStagedBuyOrder(instrument.id, type, autoExercise))
        }

        onContinue()
    }

    const limitType = isNZInstrument ? DOLLAR_LIMIT : SHARE_LIMIT

    return (
        <>
            <Page className={panelStyles.investPanelAnimated}>
                <PageBack onClick={onPageBack} />
                {!essHasActiveBlackoutPeriod && <ExchangeHours instrument={instrument} />}
                <div className={cn(spacing.spaceAbove24, spacing.spaceBelow24, styles.intro)}>
                    <h1>How would you like to buy?</h1>
                </div>
                <AutoFocusInside>
                    <SelectCard
                        dataTestId="button--market-buy-in-dollars"
                        title={capitalise(getLabelByBuyOrderType(DOLLAR_MARKET, instrument))}
                        value={DOLLAR_MARKET}
                        name="orderType"
                        isActive={orderType === DOLLAR_MARKET}
                        onChange={() => setOrderType(DOLLAR_MARKET)}
                        supportingText={`Let the market decide the price you pay for ${shareLabel({
                            instrument,
                            isPlural: true,
                        })} in line with our best price policy`}
                    />
                </AutoFocusInside>

                <SelectCard
                    dataTestId="button--limit-buy"
                    title={capitalise(getLabelByBuyOrderType(limitType, instrument))}
                    value={limitType}
                    name="orderType"
                    isActive={orderType === limitType}
                    onChange={() => setOrderType(limitType)}
                    supportingText={`Set the highest price you’re willing to pay per ${shareLabel({
                        instrument,
                    })}`}
                />

                {canPlaceTriggerBuyOrders && (
                    <>
                        <SwitchField
                            additionalClassName={cn(spacing.spaceAbove12, spacing.spaceBelow12, styles.advancedOptions)}
                            name="advanced-options"
                            isTouched={false}
                            label="Advanced buying options"
                            dataTestId="switch--buy-order-advanced-options"
                            value={showAdvancedOrderTypes}
                            onChange={() => setShowAdvancedOrderTypes(!showAdvancedOrderTypes)}
                        />

                        {showAdvancedOrderTypes && (
                            <>
                                <SelectCard
                                    additionalClassName={spacing.spaceAbove8}
                                    dataTestId="button--trigger-buy"
                                    title="Trigger buy in dollars"
                                    value={DOLLAR_TRIGGER}
                                    name="orderType"
                                    isActive={orderType === DOLLAR_TRIGGER}
                                    onChange={() => setOrderType(DOLLAR_TRIGGER)}
                                    supportingText="Place a buy order automatically when the share price rises to a price you set"
                                />
                            </>
                        )}
                    </>
                )}

                <div className={cn(spacing.spaceAbove8, styles.intro, styles.footer)}>
                    Learn more about our different order types&nbsp;
                    <OrderTypesModal instrument={instrument} />
                </div>
            </Page>

            <ActionBar>
                <Button
                    label="Next"
                    disabled={!orderType}
                    onClick={() => onSubmit(orderType!)}
                    dataTestId="button--pick-order"
                />
            </ActionBar>
        </>
    )
}

export default BuyOrderType
