import {useRetailGet} from '~/api/query/retail'
import {useApplicationPointers} from './useApplicationPointers'

export const useInstrumentApplications = (portfolioId: string) => {
    const applicationPointers = useApplicationPointers()
    const votes = applicationPointers.filter(p => p.type === 'VOTE')

    const pointerInstrumentIds = Array.from(new Set(votes.map(v => v.fund_id)))
    const fundIds = pointerInstrumentIds.join(',')

    const applicationsByInstrumentId = useRetailGet({
        path: 'order/:portfolio_id/applications',
        pathParams: {portfolio_id: portfolioId},
        payload: {fund_ids: fundIds},
    }).data.applications

    return applicationsByInstrumentId
}
