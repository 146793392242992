import {ModalLink} from '@design-system/modal'
import React from 'react'
import {Model} from '~/api/retail/types'
import {usTransferFee} from '~/global/utils/share-transfers/shareTransfers'
import {StagedTransferOrder} from '~/store/transfer/types'

interface Props {
    direction: StagedTransferOrder['direction']
    jurisdiction: Model.User['jurisdiction']
}

const TransferUSHelpTextModal: React.FunctionComponent<Props> = ({direction, jurisdiction}) => {
    const partners = jurisdiction === 'nz' ? 'Hatch or Stake' : 'Stake, Revolut, or Douugh'

    return (
        <ModalLink
            label="US share transfer fee"
            modalTitle="US share transfer fee"
            dataTestId="modal-link--nz-fees-explanation"
            primaryButton={{label: 'Okay'}}
            helpIconSize={16}
            asIcon
        >
            <p>There’s a fee for transferring US shares {direction === 'in' ? 'into' : 'out of'} Sharesies.</p>
            <p>
                For shares {direction === 'in' ? 'held with' : ' being transferred to'} a DriveWealth partner (like{' '}
                {partners}
                ), it costs ${usTransferFee(direction, 'INTERNAL')} USD for each transfer request. You can transfer as
                many investments as you like within a request.
            </p>
            {direction === 'out' && (
                <p>
                    For shares to be directly registered (transferred to a US transfer agent like Computershare or
                    Equiniti), it costs ${usTransferFee(direction, 'DRS')} USD for each transfer request. You can only
                    transfer one investment within a request.
                </p>
            )}
            <p>
                For shares that aren’t {direction === 'in' ? 'held with' : 'being transferred to'} a DriveWealth partner
                or being directly registered, it costs ${usTransferFee(direction, 'EXTERNAL')} USD for each investment
                you transfer within a request.
            </p>

            <p>To submit a transfer request, you need enough USD in your Wallet to cover the fee.</p>
            <p>
                The platform you’re transferring shares {direction === 'in' ? 'from' : 'to'} may also charge a transfer
                fee.
            </p>
        </ModalLink>
    )
}

export default TransferUSHelpTextModal
