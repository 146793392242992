import React from 'react'
import errorBanana from '~/global/assets/images/error-banana.svg'
import {urlFor} from '~/global/routeGenerator'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import HelpCentreLink from '~/global/widgets/help-centre-link/HelpCentreLink'
import Page from '~/global/widgets/page/Page'
import {useNavigate, Link} from '~/migrate-react-router'
import styles from './NotFound.scss'

const NotFound: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const headingRef = React.useRef<HTMLHeadingElement>(null)

    // There is no Toolbar on this page, so we have a manual workaround for focusing the page header.
    // If in future the DS Toolbar is used, this code can be removed.
    React.useEffect(() => {
        if (headingRef && headingRef.current) {
            headingRef.current.focus()
        }
    }, [])
    return (
        <Page className={styles.notFound} dataTestId="page--not-found">
            <img src={errorBanana} alt="" />
            <h1 tabIndex={-1} ref={headingRef} aria-label={"We couldn't find that page."}>
                Hmm, we couldn’t find that...
            </h1>
            <p>
                Get in touch with our <HelpCentreLink />, return to <Link to={urlFor('')}>your portfolio</Link> or just{' '}
                <ButtonAsLink
                    onClick={() => {
                        navigate(-1)
                    }}
                >
                    go back
                </ButtonAsLink>
                .
            </p>
        </Page>
    )
}

export default NotFound
