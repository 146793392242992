import cn from 'classnames'
import React from 'react'
import {ListingResponseDto} from '~/api/distill'
import {spacing} from '~/global/scss/helpers'
import Concertina from '~/global/widgets/concertina/Concertina'
import {AnimatedDonut} from '~/global/widgets/donut-chart/PieCharts'
import {PercentValue} from '~/global/widgets/number-elements/NumberElements'
import styles from './EtfHoldings.scss'

interface EtfHoldings {
    name: string
    value: string
}

const EtfHoldings: React.FunctionComponent<{instrument: ListingResponseDto}> = ({instrument: instrument}) => {
    const [etfHoldings, setEtfHoldings] = React.useState<EtfHoldings[] | undefined>()
    const [etfPercent, setEtfPercent] = React.useState<number>(0)

    React.useEffect(() => {
        if (instrument.etfHoldings) {
            const holdingsAsObj = JSON.parse(instrument.etfHoldings)
            const holdingsAsArr = Object.keys(holdingsAsObj).map(k => ({name: k, value: holdingsAsObj[k]}))

            let totalPercent = 0
            holdingsAsArr.forEach(i => (totalPercent += parseFloat(i.value)))

            setEtfHoldings(holdingsAsArr)
            setEtfPercent(totalPercent)
        }
    }, [instrument.etfHoldings])

    return (
        <div>
            {etfHoldings && etfPercent > 0 && (
                <div className={cn(styles.container, spacing.spaceAbove24)}>
                    <Concertina
                        largeChevron
                        title={
                            <div className={styles.titleContent}>
                                <AnimatedDonut values={[etfPercent, 100 - etfPercent]} filter="ETF" />
                                <span className={styles.toggleLabel}>
                                    {`${etfHoldings[0].name} ${
                                        etfHoldings.length > 1
                                            ? `and ${etfHoldings.length - 1} other investment${
                                                  etfHoldings.length > 2 ? 's' : ''
                                              }`
                                            : ''
                                    } make${etfHoldings.length === 1 ? 's' : ''} up `}
                                    <PercentValue value={etfPercent} />
                                    {` of this fund`}
                                </span>
                            </div>
                        }
                        body={
                            <div className={styles.bodyContent}>
                                {etfHoldings.map((holding, i) => (
                                    <div className={styles.row} key={i}>
                                        <span className={styles.name}>{holding.name}</span>
                                        <span className={styles.value}>
                                            <PercentValue value={holding.value} />
                                        </span>
                                    </div>
                                ))}
                            </div>
                        }
                    />
                </div>
            )}
        </div>
    )
}

export default EtfHoldings
