import {Card, ContentCards} from '@braze/web-sdk'
import {AddressValue} from '@design-system/address-input'
import isEqual from 'lodash.isequal'
import {DateTime} from 'luxon'
import {requestContentCardsRefresh, logCardDismissal, getCachedContentCards} from '~/api/braze/braze'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {queryClient} from '~/api/query/client'
import * as api from '~/api/retail'
import {Model, Response, Request} from '~/api/retail/types'
import * as rollbar from '~/api/rollbar/rollbar'
import {
    trackFactory as rudderTrackFactory,
    rudderIdentify,
    rudderTrack,
    isRudderInitialised,
    resetRudder,
} from '~/api/rudderstack/rudderstack'
import {REFERRAL_INFORMATION_KEY, REMEMBER_JURISDICTION_KEY} from '~/global/constants/global'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {errorResponseFactory} from '~/global/utils/error-handling/errorHandling'
import {unknownErrorMessage, tryAgainMessage, reEnterPasswordMessage} from '~/global/utils/error-text/errorText'
import {isWrapperApp} from '~/global/utils/is-wrapper-app/isWrapperApp'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {SharesiesOmit} from '~/global/utils/type-utilities/typeUtilities'
import {CountriesType} from '~/global/widgets/form-controls/country-select/CountrySelectInput'
import {reauthenticateReturn} from '~/global/wrappers/global-wrapper-widgets/reauthenticate/Reauthenticate'
import {EXPIRED_CODE, GENERATED_CODE, INVALID_CODE} from '~/sections/user/sections/settings/constants/emailVerification'
import {DEPENDENT_DECLARATION_VERSION} from '~/sections/user/sections/sign-up/pages/dependent-declaration/DependentDeclaration'
import {TC_VERSION_TYPE} from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/TermsAndConditions'
import {ThunkAction} from '~/store/types'
import accountingActions from '../accounting/actions'
import autoinvestActions from '../autoinvest/actions'
import essActions from '../employeeShareScheme/actions'
import {ImageUpload, PortfolioFilterOptions, PortfolioSortOptions, RWT} from '../identity/types'
import instrumentActions from '../instrument/actions'
import marketActions from '../market/actions'
import notificationActions from '../notification/actions'
import planActions from '../plan/actions'
import portfolioActions from '../portfolio/actions'
import {createAction, ActionsUnion} from '../redux-tools'
import transferActions from '../transfer/actions'
import {actingAsID, optionalActingAsID, getCurrentOrders} from './selectors'
import {PIR, SharesightLoadingState, State} from './types'
export type CloseAccountResponse = {error: string} | {isDependent: boolean; preferredName: string}

const rudderTrackSignup = rudderTrackFactory('signup')

const actions = {
    Initialise: () => createAction('identity.Initialise'),
    ClearRakaiaToken: () => createAction('identity.ClearRakaiaToken'),
    SetAnonymousIdentity: () => createAction('identity.SetAnonymousIdentity'),
    SetAuthenticatedIdentity: (data: Response.IdentityAuthenticated) =>
        createAction('identity.SetAuthenticatedIdentity', data),
    SetCloseAccountCheck: (results: Response.IdentityCloseAccountCheck) =>
        createAction('identity.SetCloseAccountCheck', results),
    SetCitizenships: (citizenships: Response.SettingsCitizenship['countries']) =>
        createAction('identity.SetCitizenships', citizenships),
    SetCustomImages: (custom_images: {image_id: string; image_url: string}[]) =>
        createAction('identity.SetCustomImages', {custom_images}),
    SetDistillToken: (token: string | null) => createAction('identity.SetDistillToken', {token}),
    SetNotificationPreferences: (categories: Response.IdentityNotificationPreferencesV2['categories']) =>
        createAction('identity.SetNotificationPreferences', categories),
    SetFrozen: () => createAction('identity.SetFrozen'),
    SetHasSeen: (flag: Request.CustomerMarkHasSeenFlag['flag']) => {
        /**
         * In most cases, you should prefer using MarkHasSeenFlag.
         * This is used under the hood to immediately mark as seen while the network request is pending.
         */
        return createAction('identity.SetHasSeen', flag)
    },
    SetIncludeSoldInvestments: (includeSoldInvestments: Response.IncludeSoldInvestments) =>
        createAction('identity.SetIncludeSoldInvestments', includeSoldInvestments),
    SetEnableExtendedHours: (enableExtendedHours: Response.PortfolioEnableExtendedHours) =>
        createAction('identity.SetEnableExtendedHours', enableExtendedHours),
    SetKidsAccountTransferTokenData: (response: Response.IdentityTokenData) =>
        createAction('identity.SetKidsAccountTransferTokenData', response),
    SetKidsAccountTransferTokenError: () => createAction('identity.SetKidsAccountTransferTokenError'),
    SetNatureAndPurpose: (natureAndPurpose: Response.IdentityNatureAndPurpose) =>
        createAction('identity.SetNatureAndPurpose', natureAndPurpose),
    SetNotifications: (contentCards: ContentCards) =>
        createAction('identity.SetNotifications', {
            cards: contentCards.cards,
        }),
    SetNotificationsLoadFail: () => createAction('identity.SetNotificationsLoadFail'),
    SetPendingOrdersCurrentTimeoutId: (timeoutId: NodeJS.Timeout) =>
        createAction('identity.SetPendingOrdersCurrentTimeoutId', timeoutId),
    SetPortfolioFilterPreference: (filterPreference: PortfolioFilterOptions) =>
        createAction('identity.SetPortfolioFilterPreference', filterPreference),
    SetPortfolioSortPreference: (sortPreference: PortfolioSortOptions) =>
        createAction('identity.SetPortfolioSortPreference', sortPreference),
    SetSharesightClientCredentials: (clientId: string, redirectUri: string) =>
        createAction('identity.SetSharesightClientCredentials', {clientId, redirectUri}),
    SetSharesightGetIntegrationsLoadingState: (state: SharesightLoadingState) =>
        createAction('identity.SetSharesightGetIntegrationsLoadingState', state),
    SetSharesightGetPortfolioLoadingState: (state: SharesightLoadingState) =>
        createAction('identity.SetSharesightPortfoliosLoadingState', state),
    SetSharesightIntegrations: (sharesightIntegrations: Response.SharesightIntegration['sharesight_integrations']) =>
        createAction('identity.SetSharesightIntegrations', sharesightIntegrations),
    SetSharesightPortfolios: (sharesightPortfolios: Response.SharesightPortfolios['sharesight_portfolios']) =>
        createAction('identity.SetSharesightPortfolios', sharesightPortfolios),
    SetSwitchingTo: (user: Model.CustomerSummary) => createAction('identity.SetSwitchingTo', user),
    UpdatePendingOrders: (orders: Response.IdentityAuthenticated['orders']) =>
        createAction('identity.UpdatePendingOrders', {
            orders,
        }),
    UpdateUSSignupDetails: (
        address: Model.User['address'],
        phone: Model.User['phone'],
        ird_number: Model.User['ird_number'],
    ) => createAction('identity.UpdateUSSignupDetails', {address, ird_number, phone}),
    SetFeedbackState: (feedbackState: State['feedback']) => createAction('identity.SetFeedback', feedbackState),
}

const thunkActions = {
    init(): ThunkAction<void> {
        return dispatch => {
            return dispatch(this.Check(true)).then(user => {
                if (user?.id && !isWrapperApp()) {
                    // Only trigger a login event if we have a user ID and we're not the native wrapper app
                    // They've previously logged in so we're automatically re-authenticating them
                    isRudderInitialised().then(() => {
                        rudderTrack('login', 'app_login')
                    })
                }

                dispatch(actions.Initialise())
            })
        }
    },
    handleIdentityResponse(
        data: Response.IdentityAnonymous | Response.IdentityAuthenticated,
    ): ThunkAction<Promise<State['user']>> {
        return async (dispatch, getState) => {
            const existingState = getState()

            if (data.authenticated) {
                const loginOrBecomeActive =
                    (!existingState.identity.user || existingState.identity.user.state !== 'active') &&
                    data.user.state === 'active'
                const switchingUser = !!existingState.identity.user && existingState.identity.user.id !== data.user.id

                // these must happen before our main setting of identity data
                if (loginOrBecomeActive || switchingUser) {
                    dispatch(instrumentActions.ClearIndexes())
                    // clear all tanstack-query caches - this is a bit of a shotgun approach but it's the safest way to ensure we don't have stale data
                    queryClient.clear()
                }

                // main setting of identity data
                dispatch(actions.SetAuthenticatedIdentity(data))

                // everything from this point down can happen after the main setting of identity data
                dispatch(marketActions.SetLiveDataSubscriptionDetails(data.live_data))

                if (loginOrBecomeActive || switchingUser) {
                    if (data.user.address_state === 'rejected' && data.user.address_reject_reason !== 'other') {
                        dispatch(notificationActions.ShowAddressValidationRejectionReason())
                    }
                    dispatch(instrumentActions.loadMetadata())

                    // set jurisdiction in local storage for the intercom router
                    const jurisdiction = data.user.jurisdiction
                    try {
                        localStorage.setItem(REMEMBER_JURISDICTION_KEY, jurisdiction)
                    } catch (ignore) {
                        // Ignore failures when setting item, worst case jurisdiction isn't remembered
                    }
                }

                if (switchingUser) {
                    dispatch(accountingActions.ClearInvestingActivityRecords())
                    dispatch(accountingActions.ClearTransactions())
                    dispatch(instrumentActions.ClearAllSearchFiltersSorts())
                    dispatch(instrumentActions.ClearDividendReinvestPreferences())
                    dispatch(portfolioActions.ClearPortfolio())
                    dispatch(essActions.ClearEmployeeShareScheme())
                    dispatch(autoinvestActions.ClearState())
                    dispatch(planActions.ClearAllPlans())
                    dispatch(planActions.ClearCurrentPlan())
                    dispatch(transferActions.ClearTransferState())
                    dispatch(actions.SetNotifications(new ContentCards([], new Date())))
                }

                if (loginOrBecomeActive) {
                    Analytics.setUserId(data.ga_id)
                }

                if (data.user.state === 'active') {
                    if (existingState.accounting.walletPageLoadingState === 'uninitialised' || switchingUser) {
                        dispatch(accountingActions.init())
                        if (localStorage) {
                            try {
                                const timestamp = localStorage.getItem(REFERRAL_INFORMATION_KEY)
                                if (timestamp) {
                                    dispatch(accountingActions.FetchNewReferralInformation(DateTime.fromISO(timestamp)))
                                }
                            } catch (e) {
                                rollbar.sendError(`Failed to restore referral information timestamp: ${e}`)
                            }
                        }
                    }
                }

                // Check for new notifications for user
                requestContentCardsRefresh(
                    () => {
                        const cards = getCachedContentCards()

                        if (cards) {
                            dispatch(actions.SetNotifications(cards))
                        }
                    },
                    () => dispatch(actions.SetNotificationsLoadFail()),
                )

                return data.user
            } else {
                // logged out - internal store state resets
                dispatch(actions.SetAnonymousIdentity())
                dispatch(accountingActions.ClearTransactions())
                dispatch(actions.SetDistillToken(null))
                dispatch(actions.ClearRakaiaToken())
                dispatch(accountingActions.ResetAccountingData())
                dispatch(instrumentActions.ClearAllSearchFiltersSorts())
                dispatch(instrumentActions.ClearIndexes())
                dispatch(portfolioActions.ClearPortfolio())
                dispatch(autoinvestActions.ClearState())
                dispatch(essActions.ClearEmployeeShareScheme())
                dispatch(planActions.ClearAllPlans())
                dispatch(planActions.ClearCurrentPlan())
                dispatch(
                    marketActions.SetLiveDataSubscriptionDetails({
                        is_active: false,
                        eligible_for_free_month: false,
                    }),
                )
                dispatch(actions.SetNotifications(new ContentCards([], new Date())))

                // clear tracking
                Analytics.setUserId(null)
                resetRudder()

                // clear localStorage of actingAsId
                window.localStorage.removeItem('actingAsId')

                // clear all tanstack-query caches
                queryClient.clear()

                return null
            }
        }
    },
    SwitchUser(acting_as_id: string): ThunkAction<Promise<State['user']>> {
        return async (dispatch, getState) => {
            const {identity} = getState()

            // This action is called more frequently in the context of our
            // native apps than in the browser. When the acting_as_id requested
            // won't result in an identity change, we short circuit this action
            // to save unnecessary loading indicators in the native apps.
            if (acting_as_id === identity.user?.id) {
                return identity.user
            }

            const newUser = identity.userList.find(u => u.id === acting_as_id)
            if (newUser) {
                dispatch(actions.SetSwitchingTo(newUser))
            }
            const response = await api.get('identity/check', {acting_as_id})
            switch (response.type) {
                case 'identity_authenticated':
                case 'identity_anonymous':
                    return dispatch(this.handleIdentityResponse(response))
                default:
                    // if something goes wrong with an identity check we should
                    // be conservative and log the user out
                    return this.Logout()(dispatch, getState)
            }
        }
    },
    SetLoggedOut(): ThunkAction<void> {
        return async dispatch =>
            dispatch(
                this.handleIdentityResponse({
                    type: 'identity_anonymous',
                    authenticated: false,
                }),
            )
    },
    Check(initialFetch?: boolean): ThunkAction<Promise<State['user']>> {
        return async (dispatch, getState) => {
            const actingAsId = optionalActingAsID(getState())
            const data: Request.IdentityCheck = {
                acting_as_id: actingAsId,
            }

            // Set initialisation_check if not inside mobile app
            if (initialFetch && !isWrapperApp()) {
                data.initialisation_check = true
            }

            const response = await api.get('identity/check', data)
            switch (response.type) {
                case 'identity_authenticated':
                case 'identity_anonymous':
                    return dispatch(this.handleIdentityResponse(response))
                default:
                    // if something goes wrong with an identity check we should
                    // be conservative and log the user out
                    return this.Logout()(dispatch, getState)
            }
        }
    },
    Login(
        email: string,
        password: string,
        remember: boolean,
        mfa_token?: string,
        email_mfa_token?: string,
    ): ThunkAction<
        Promise<State['user'] | 'mfa_required' | 'mfa_invalid_token' | 'email_mfa_required' | 'email_mfa_invalid_token'>
    > {
        return async dispatch => {
            const payload: Request.IdentityLogin = {email, password, remember, mfa_token, email_mfa_token}
            const response = await api.post('identity/login', payload)
            switch (response.type) {
                case 'internal_server_error':
                    // TODO: handle this properly - but for now make sure that we show the 'something went wrong' message in the Login catch handling
                    throw Error('internal_server_error')
                case 'account_restricted':
                    throw Error('account_restricted')
                case 'identity_mfa_required':
                    if (response.invalid_mfa_token) {
                        return 'mfa_invalid_token'
                    }
                    return 'mfa_required'
                case 'identity_email_mfa_required':
                    if (response.invalid_mfa_token) {
                        return 'email_mfa_invalid_token'
                    }
                    return 'email_mfa_required'
                default: {
                    // Only want to track a successful login
                    if (response.authenticated) {
                        isRudderInitialised().then(() => {
                            rudderTrack('login', 'app_login')
                        })
                    }

                    return dispatch(this.handleIdentityResponse(response))
                }
            }
        }
    },
    Logout(): ThunkAction<Promise<State['user']>> {
        return async dispatch => {
            window.history.replaceState(null, window.document.title, window.document.URL)
            const response = await api.post('identity/logout')

            switch (response.type) {
                case 'internal_server_error':
                    return null
                default:
                    // DS-471: Force page reload on logout so make sure there are no bugs from persisted state
                    // `forcePageReload` is checked in `src/global/AnonymousWrapper.tsx` which renders after the rest of the logout is complete
                    window.localStorage.setItem('forcePageReload', 'true')
                    return dispatch(this.handleIdentityResponse(response))
            }
        }
    },
    ExitSignUp(): ThunkAction<Promise<State['user']>> {
        return async (dispatch, getState) => {
            const state = getState()
            const actingAs = optionalActingAsID(state)
            const primary = state.identity.userList.find(u => u.primary)
            if (primary && actingAs && primary.id !== actingAs) {
                return this.SwitchUser(primary.id)(dispatch, getState)
            } else {
                return this.Logout()(dispatch, getState)
            }
        }
    },
    ExitSignUpAndDeleteKidsAccount(): ThunkAction<Promise<State['user']>> {
        return async (dispatch, getState) => {
            const state = getState()
            const {user} = state.identity
            const newUser = state.identity.userList.find(u => u.primary)
            if (!user || !newUser) {
                throw new Error('No user present')
            }
            if (!user.is_dependent) {
                throw new Error(`Can't delete non-dependent user: ${user.id}`)
            }
            if (user.state !== 'in_signup') {
                // TODO if you X from the participant question this is probably going to trip :/
                // but I think the backend will also have this constraint, so need a better solution
                throw new Error(`Can't delete from state: ${user.state}`)
            }

            // dispatch the delete request
            const response = await api.post('identity/dependent/delete-partial-sign-up', {acting_as_id: user.id})
            switch (response.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => this.ExitSignUpAndDeleteKidsAccount()(dispatch, getState),
                        () => null,
                        () => null,
                    )
                case 'identity_authenticated':
                    // rather than directly using the identity response, call SwitchUser to make sure we trigger any associated side effects
                    return this.SwitchUser(newUser.id)(dispatch, getState)
                case 'internal_server_error':
                    break
                default:
                    assertNever(response)
            }
            return null
        }
    },
    Deauthenticate(): ThunkAction<Promise<void>> {
        return dispatch => {
            return api.post('identity/deauthenticate').then(response => {
                switch (response.type) {
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(response))
                        return
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(response)
                }
                return
            })
        }
    },
    ForgotPassword(email: string): ThunkAction<Promise<'success' | 'maximum_failed_sms_attempts'>> {
        return async () => {
            const payload: Request.IdentityForgotPassword = {email}
            const response = await api.post('identity/forgot-password', payload)
            switch (response.type) {
                case 'empty':
                    return 'success'
                case 'error':
                    return 'maximum_failed_sms_attempts'
                case 'internal_server_error':
                    // TODO: handle this properly
                    throw new Error(`unknown response from forgot-password`)
                default:
                    assertNever(response)
                    throw new Error(`unknown response from forgot-password`)
            }
        }
    },
    mfaEnableConfirm(mfa_token: string): ThunkAction<Promise<string | void>> {
        return async (dispatch, getState) => {
            const response = await api.post('identity/mfa/enable/confirm', {mfa_token})
            switch (response.type) {
                case 'internal_server_error':
                    // TODO: handle this properly
                    throw new Error(`unknown response from forgot-password`)
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => this.mfaEnableConfirm(mfa_token)(dispatch, getState),
                        () => undefined,
                        () => undefined,
                    )
                case 'identity_authenticated':
                    await dispatch(this.handleIdentityResponse(response))
                    return
                case 'error':
                    if (response.code === 'mfa_invalid_token') {
                        return 'Your code does not match, or has expired'
                    }
                    return 'An unknown error occurred'
                default:
                    assertNever(response)
                    throw new Error(`unknown response from forgot-password`)
            }
        }
    },
    mfaDisable(mfa_token: string): ThunkAction<Promise<string | void>> {
        return async (dispatch, getState) => {
            const response = await api.post('identity/mfa/disable', {mfa_token})
            switch (response.type) {
                case 'internal_server_error':
                    // TODO: handle this properly
                    throw new Error(`unknown response from forgot-password`)
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => this.mfaDisable(mfa_token)(dispatch, getState),
                        () => undefined,
                        () => undefined,
                    )
                case 'identity_authenticated':
                    await dispatch(this.handleIdentityResponse(response))
                    return
                case 'error':
                    if (response.code === 'mfa_invalid_token') {
                        return 'Your code does not match, or has expired'
                    }
                    return 'An unknown error occurred'
                default:
                    assertNever(response)
                    throw new Error(`unknown response from forgot-password`)
            }
        }
    },
    SendSms(
        token: string,
    ): ThunkAction<Promise<'success' | 'success_no_phone' | 'unknown_error' | 'maximum_failed_sms_attempts'>> {
        return async () => {
            const payload: Request.IdentityResetPasswordVerifySendSMS = {token}
            const response = await api.post('identity/verify-identity-send-sms', payload)
            switch (response.type) {
                case 'error':
                    switch (response.code) {
                        case 'invalid_token':
                            return 'unknown_error'
                        case 'maximum_failed_sms_attempts':
                            return 'maximum_failed_sms_attempts'
                        case 'invalid_phone':
                        case 'skip_sms':
                            return 'success_no_phone'
                        default:
                            return 'unknown_error'
                    }
                case 'empty':
                    return 'success'
                case 'internal_server_error':
                    // TODO: handle this properly
                    return 'unknown_error'
                default:
                    assertNever(response)
                    return 'unknown_error'
            }
        }
    },
    ResetPassword(
        token: string,
        code: string,
        password: string,
        mfa_token?: string,
    ): ThunkAction<Promise<string | null | Response.FormErrors>> {
        return dispatch => {
            const payload: Request.IdentityResetPassword = {token, code, password, mfa_token}
            return api.post('identity/reset-password', payload).then(response => {
                switch (response.type) {
                    case 'identity_mfa_required':
                        if (response.invalid_mfa_token) {
                            return 'mfa_invalid_token'
                        }
                        return 'mfa_required'
                    case 'identity_anonymous':
                        dispatch(this.handleIdentityResponse(response))
                        return null
                    case 'form_errors':
                        return response
                    case 'internal_server_error':
                        // TODO: handle this properly
                        return null
                    default:
                        assertNever(response)
                        return null
                }
            })
        }
    },
    ChangePassword(old_password: string, new_password: string): ThunkAction<Promise<null | api.FormErrors>> {
        return () => {
            const payload: Request.IdentityChangePassword = {old_password, new_password}
            return api.post('identity/change-password', payload).then(data => {
                if (data.type === 'form_errors') {
                    return data.errors
                }
                return null
            })
        }
    },
    SignUp(signUpRequest: Request.IdentitySignUpV2): ThunkAction<Promise<null | api.FormErrors | string>> {
        return dispatch => {
            return api.post('identity/sign-up-v2', signUpRequest).then(data => {
                if (data.type === 'form_errors') {
                    return data.errors
                }
                if (data.type === 'error') {
                    return data.message
                }
                if (data.type === 'internal_server_error') {
                    // TODO: handle this properly
                    return null
                }
                rudderIdentify(data.user.id, data.ga_id)
                rudderTrackSignup('details_entered')
                dispatch(this.handleIdentityResponse(data))
                return null
            })
        }
    },
    VerificationDetails(): ThunkAction<Promise<null | Response.Error>> {
        return async dispatch => {
            const data = await api.post('identity/sign-up/verification-details')
            switch (data.type) {
                case 'identity_authenticated':
                    dispatch(this.handleIdentityResponse(data))
                    return null
                case 'authentication_required':
                case 'authentication_update_required':
                case 'internal_server_error':
                    break
                default:
                    assertNever(data)
            }
            return null
        }
    },
    LatestVerificationDetails(): ThunkAction<Promise<null | Response.Error>> {
        return async dispatch => {
            const data = await api.post('identity/verification-details')
            switch (data.type) {
                case 'identity_authenticated':
                    dispatch(this.handleIdentityResponse(data))
                    return null
                case 'authentication_required':
                case 'authentication_update_required':
                case 'internal_server_error':
                    break
                default:
                    assertNever(data)
            }
            return null
        }
    },
    VerifiLicence(
        request: SharesiesOmit<Request.IdentityVerifiNZLicence, Request.ActingAsRequired>,
    ): ThunkAction<Promise<null | Response.Error>> {
        return async (dispatch, getState) => {
            rudderTrackSignup('primary_id_entered', {
                identity_type: 'NZ driver licence',
            })

            const data = await api.post('identity/sign-up/verifi/licence', {
                ...request,
                acting_as_id: actingAsID(getState()),
            })
            switch (data.type) {
                case 'error':
                    return data
                case 'identity_authenticated':
                    dispatch(this.handleIdentityResponse(data))
                    rudderTrackSignup('primary_id_confirmed')
                    return null
                case 'authentication_required':
                    return reauthenticateReturn(
                        () => this.VerifiLicence(request)(dispatch, getState),
                        () => null,
                        () => null,
                    )
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(data)
            }
            return null
        }
    },
    VerifiPassport(
        request: SharesiesOmit<Request.IdentityVerifiNZPassport, Request.ActingAsRequired>,
    ): ThunkAction<Promise<null | Response.Error>> {
        return async (dispatch, getState) => {
            rudderTrackSignup('primary_id_entered', {
                identity_type: 'NZ passport',
            })

            const data = await api.post('identity/sign-up/verifi/passport', {
                ...request,
                acting_as_id: actingAsID(getState()),
            })
            switch (data.type) {
                case 'error':
                    return data
                case 'identity_authenticated':
                    dispatch(this.handleIdentityResponse(data))
                    rudderTrackSignup('primary_id_confirmed')
                    return null
                case 'authentication_required':
                    return reauthenticateReturn(
                        () => this.VerifiPassport(request)(dispatch, getState),
                        () => null,
                        () => null,
                    )
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(data)
            }
            return null
        }
    },
    VerifiBirthCertificate(
        request: SharesiesOmit<Request.IdentityVerifiBirthCertificate, Request.ActingAsRequired>,
    ): ThunkAction<Promise<null | string>> {
        return async (dispatch, getState) => {
            rudderTrackSignup('primary_id_entered', {
                identity_type: 'NZ birth certificate',
            })
            const data = await api.post('identity/sign-up/verifi/birth-certificate', {
                ...request,
                acting_as_id: actingAsID(getState()),
            })
            switch (data.type) {
                case 'error':
                    return data.message
                case 'identity_authenticated':
                    dispatch(this.handleIdentityResponse(data))
                    rudderTrackSignup('primary_id_confirmed')
                    return null
                case 'authentication_required':
                    return reauthenticateReturn(
                        () => this.VerifiBirthCertificate(request)(dispatch, getState),
                        () => null,
                        () => null,
                    )
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(data)
            }
            return null
        }
    },
    VerifiAustralianLicence(
        request: SharesiesOmit<Request.IdentityVerifiAustralianLicence, Request.ActingAsRequired>,
    ): ThunkAction<Promise<null | Response.Error>> {
        return async (dispatch, getState) => {
            rudderTrackSignup('primary_id_entered', {
                identity_type: 'AU driver licence',
            })
            const data = await api.post('identity/sign-up/verifi/australian-licence', {
                ...request,
                acting_as_id: actingAsID(getState()),
            })
            switch (data.type) {
                case 'error':
                    return data
                case 'identity_authenticated':
                    dispatch(this.handleIdentityResponse(data))
                    rudderTrackSignup('primary_id_confirmed')
                    return null
                case 'authentication_required':
                    return reauthenticateReturn(
                        () => this.VerifiAustralianLicence(request)(dispatch, getState),
                        () => null,
                        () => null,
                    )
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(data)
            }
            return null
        }
    },
    VerifiAustralianPassport(
        request: SharesiesOmit<Request.IdentityVerifiAustralianPassport, Request.ActingAsRequired>,
    ): ThunkAction<Promise<null | Response.Error>> {
        return async (dispatch, getState) => {
            rudderTrackSignup('primary_id_entered', {
                identity_type: 'AU passport',
            })
            const data = await api.post('identity/sign-up/verifi/australian-passport', {
                ...request,
                acting_as_id: actingAsID(getState()),
            })
            switch (data.type) {
                case 'error':
                    return data
                case 'identity_authenticated':
                    dispatch(this.handleIdentityResponse(data))
                    rudderTrackSignup('primary_id_confirmed')
                    return null
                case 'authentication_required':
                    return reauthenticateReturn(
                        () => this.VerifiAustralianPassport(request)(dispatch, getState),
                        () => null,
                        () => null,
                    )
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(data)
            }
            return null
        }
    },
    SecondaryVerifiLicence(
        request: SharesiesOmit<Request.IdentityVerifiNZLicence, Request.ActingAsRequired>,
    ): ThunkAction<Promise<Response.Error | null>> {
        return async (dispatch, getState) => {
            const data = await api.post('identity/secondary-id/verifi/licence', {
                ...request,
                acting_as_id: actingAsID(getState()),
            })
            try {
                switch (data.type) {
                    case 'error':
                        return data
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(data))
                        return null
                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () => this.SecondaryVerifiLicence(request)(dispatch, getState),
                            () => errorResponseFactory(tryAgainMessage),
                            () => errorResponseFactory('You must enter your password to submit your licence details'),
                        )
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(data)
                }
                return null
            } catch (e) {
                return errorResponseFactory(unknownErrorMessage)
            }
        }
    },
    SecondaryVerifiPassport(
        request: SharesiesOmit<Request.IdentityVerifiNZPassport, Request.ActingAsRequired>,
    ): ThunkAction<Promise<Response.Error | null>> {
        return async (dispatch, getState) => {
            const data = await api.post('identity/secondary-id/verifi/passport', {
                ...request,
                acting_as_id: actingAsID(getState()),
            })
            try {
                switch (data.type) {
                    case 'error':
                        return data
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(data))
                        return null
                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () => this.SecondaryVerifiPassport(request)(dispatch, getState),
                            () => errorResponseFactory(tryAgainMessage),
                            () => errorResponseFactory('You must enter your password to submit your passport details'),
                        )
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(data)
                }
                return null
            } catch (e) {
                return errorResponseFactory(unknownErrorMessage)
            }
        }
    },
    SecondaryVerifiAustralianLicence(
        request: SharesiesOmit<Request.IdentityVerifiAustralianLicence, Request.ActingAsRequired>,
    ): ThunkAction<Promise<Response.Error | null>> {
        return async (dispatch, getState) => {
            const data = await api.post('identity/secondary-id/verifi/australian-licence', {
                ...request,
                acting_as_id: actingAsID(getState()),
            })
            try {
                switch (data.type) {
                    case 'error':
                        return data
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(data))
                        return null
                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () => this.SecondaryVerifiAustralianLicence(request)(dispatch, getState),
                            () => errorResponseFactory(tryAgainMessage),
                            () => errorResponseFactory('You must enter your password to submit your licence details'),
                        )
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(data)
                }
                return null
            } catch (e) {
                return errorResponseFactory(unknownErrorMessage)
            }
        }
    },
    SecondaryVerifiAustralianPassport(
        request: SharesiesOmit<Request.IdentityVerifiAustralianPassport, Request.ActingAsRequired>,
    ): ThunkAction<Promise<Response.Error | null>> {
        return async (dispatch, getState) => {
            const data = await api.post('identity/secondary-id/verifi/australian-passport', {
                ...request,
                acting_as_id: actingAsID(getState()),
            })
            try {
                switch (data.type) {
                    case 'error':
                        return data
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(data))
                        return null
                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () => this.SecondaryVerifiAustralianPassport(request)(dispatch, getState),
                            () => errorResponseFactory(tryAgainMessage),
                            () => errorResponseFactory('You must enter your password to submit your passport details'),
                        )
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(data)
                }
                return null
            } catch (e) {
                return errorResponseFactory(unknownErrorMessage)
            }
        }
    },
    SecondaryVerifiAustralianMedicareCard(
        request: SharesiesOmit<Request.IdentityVerifiAustralianMedicareCard, Request.ActingAsRequired>,
    ): ThunkAction<Promise<Response.Error | null>> {
        return async (dispatch, getState) => {
            const data = await api.post('identity/secondary-id/verifi/australian-medicare-card', {
                ...request,
                acting_as_id: actingAsID(getState()),
            })
            try {
                switch (data.type) {
                    case 'error':
                        return data
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(data))
                        return null
                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () => this.SecondaryVerifiAustralianMedicareCard(request)(dispatch, getState),
                            () => errorResponseFactory(tryAgainMessage),
                            () =>
                                errorResponseFactory(
                                    'You must enter your password to submit your medicare card details',
                                ),
                        )
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(data)
                }
                return null
            } catch (e) {
                return errorResponseFactory(unknownErrorMessage)
            }
        }
    },
    SignUpAddress(address: AddressValue): ThunkAction<Promise<null | 'pending' | 'verified'>> {
        return (dispatch, getState) => {
            const payload: Request.IdentitySignUpAddress = {address, acting_as_id: actingAsID(getState())}
            rudderTrackSignup('address_details_entered')
            return api.post('identity/sign-up/address2', payload).then(data => {
                switch (data.type) {
                    case 'identity_address_pending':
                        dispatch(this.handleIdentityResponse(data.identity_authenticated))
                        return 'pending'
                    case 'identity_address_verified':
                        rudderTrackSignup('address_verification_completed')
                        dispatch(this.handleIdentityResponse(data.identity_authenticated))
                        return 'verified'
                    case 'identity_fallback_dob_verified':
                        dispatch(this.handleIdentityResponse(data.identity_authenticated))
                        return 'verified'
                    case 'internal_server_error':
                        rollbar.sendError('internal_server_error on POST to identity/sign-up/address2')
                        break
                    default:
                        assertNever(data)
                }
                return null
            })
        }
    },
    SignUpCopyAddress(): ThunkAction<Promise<string | undefined>> {
        return (dispatch, getState) => {
            return api.post('identity/sign-up/copy-address', {acting_as_id: actingAsID(getState())}).then(data => {
                switch (data.type) {
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(data))
                        return 'pending'
                    case 'error':
                        return data.message
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(data)
                }
                return undefined
            })
        }
    },
    SignUpTaxQuestions(
        foreign_tax_resident: boolean,
        countries: Request.IdentitySignUpTaxQuestions['countries'],
    ): ThunkAction<Promise<undefined | string>> {
        return async (dispatch, getState) => {
            const payload: Request.IdentitySignUpTaxQuestions = {
                foreign_tax_resident,
                countries,
                acting_as_id: actingAsID(getState()),
            }
            try {
                const data = await api.post('identity/sign-up/tax-questions', payload)
                switch (data.type) {
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(data))
                        rudderTrackSignup('tax_residency_added')
                        return undefined
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(data)
                }
                return undefined
            } catch (e) {
                return unknownErrorMessage
            }
        }
    },
    SignUpNatureAndPurpose(
        frequency: Request.NatureAndPurposeUpdate['frequency'],
        annual_amount: Request.NatureAndPurposeUpdate['annual_amount'],
        purposes: Request.NatureAndPurposeUpdate['purposes'],
        other_purpose: string,
    ): ThunkAction<Promise<undefined | string>> {
        return async (dispatch, getState) => {
            const payload: Request.NatureAndPurposeUpdate = {
                frequency,
                annual_amount,
                purposes,
                other_purpose,
                acting_as_id: actingAsID(getState()),
            }
            try {
                const data = await api.post('identity/sign-up/nature-and-purpose', payload)
                switch (data.type) {
                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () =>
                                this.SignUpNatureAndPurpose(
                                    frequency,
                                    annual_amount,
                                    purposes,
                                    other_purpose,
                                )(dispatch, getState),
                            () => tryAgainMessage,
                            () => 'You must enter your password to update your nature and purpose',
                        )
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(data))
                        rudderTrackSignup('nandp_answered')
                        return undefined
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(data)
                }
                return undefined
            } catch (e) {
                return unknownErrorMessage
            }
        }
    },
    UpdateNatureAndPurpose(
        frequency: Request.NatureAndPurposeUpdate['frequency'],
        annual_amount: Request.NatureAndPurposeUpdate['annual_amount'],
        purposes: Request.NatureAndPurposeUpdate['purposes'],
        other_purpose: string,
    ): ThunkAction<Promise<undefined | string>> {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())
            const response = await api.post('identity/nature-and-purpose/update', {
                frequency,
                annual_amount,
                purposes,
                other_purpose,
                acting_as_id,
            })
            switch (response.type) {
                case 'nature_and_purpose':
                    dispatch(actions.SetNatureAndPurpose(response))
                    break
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () =>
                            this.UpdateNatureAndPurpose(
                                frequency,
                                annual_amount,
                                purposes,
                                other_purpose,
                            )(dispatch, getState),
                        () => tryAgainMessage,
                        () => 'You must enter your password to update your nature and purpose',
                    )
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    GetNatureAndPurpose(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const response = await api.get('identity/nature-and-purpose', {
                acting_as_id: actingAsID(getState()),
            })
            switch (response.type) {
                case 'nature_and_purpose':
                    dispatch(actions.SetNatureAndPurpose(response))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                case 'empty':
                    break // we don't care if this is empty
                default:
                    assertNever(response)
            }
        }
    },
    GetFullName(): ThunkAction<Promise<Response.FullName | string | any>> {
        return async (_, getState) => {
            const response = await api.get('identity/full-name', {
                acting_as_id: actingAsID(getState()),
            })
            switch (response.type) {
                case 'full_name':
                    // we don't want to save this to the redux store (just display on UI as a read only field)
                    return response
                case 'internal_server_error':
                    return unknownErrorMessage
                default:
                    return unknownErrorMessage
            }
        }
    },
    SetPrescribedPersonState(
        participant?: string,
        prescribedEmail?: string[],
    ): ThunkAction<Promise<undefined | string>> {
        return async (dispatch, getState) => {
            const payload: Request.CustomerSetPrescribedState = {
                prescribed_participant: participant,
                participant_emails: prescribedEmail,
                acting_as_id: actingAsID(getState()),
            }
            try {
                const data = await api.post('identity/identity/set-prescribed-state', payload)
                switch (data.type) {
                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () => this.SetPrescribedPersonState(participant, prescribedEmail)(dispatch, getState),
                            () => tryAgainMessage,
                            () => reEnterPasswordMessage,
                        )
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(data))
                        rudderTrackSignup('prescribed_person_answered')
                        return undefined
                    case 'error':
                        return data.message
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(data)
                }
                return undefined
            } catch (e) {
                return unknownErrorMessage
            }
        }
    },
    TaxDeclaration(foreign_tax_resident: boolean, countries: CountriesType): ThunkAction<Promise<undefined | string>> {
        return async (dispatch, getState) => {
            type ValidCountry = Request.IdentitySignUpTaxQuestions['countries'][0]
            const validCountries = countries.filter((c): c is ValidCountry => c.country !== '')

            const payload: Request.IdentitySignUpTaxQuestions = {
                foreign_tax_resident,
                countries: validCountries,
                acting_as_id: actingAsID(getState()),
            }
            try {
                const data = await api.post('identity/tax-residency-declaration', payload)
                switch (data.type) {
                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () => this.TaxDeclaration(foreign_tax_resident, countries)(dispatch, getState),
                            () => tryAgainMessage,
                            () => 'You must enter your password to set your tax residency',
                        )
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(data))
                        return undefined
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(data)
                }
                return undefined
            } catch (e) {
                return unknownErrorMessage
            }
        }
    },
    setIRDNumber(ird_number: string): ThunkAction<Promise<string | undefined>> {
        return async (dispatch, getState) => {
            try {
                const response = await api.post('identity/sign-up/ird-number', {
                    ird_number,
                    acting_as_id: actingAsID(getState()),
                })
                switch (response.type) {
                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () => this.setIRDNumber(ird_number)(dispatch, getState),
                            () => tryAgainMessage,
                            () => 'You must enter your password to set your IRD number',
                        )
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(response))
                        return
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(response)
                }
            } catch (e) {
                return unknownErrorMessage
            }
        }
    },
    setTFNNumber(tfn_number: string): ThunkAction<Promise<string | undefined>> {
        return async (dispatch, getState) => {
            try {
                const response = await api.post('identity/sign-up/tfn-number', {
                    tfn_number,
                    acting_as_id: actingAsID(getState()),
                })
                switch (response.type) {
                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () => this.setTFNNumber(tfn_number)(dispatch, getState),
                            () => tryAgainMessage,
                            () => 'You must enter your password to set your TFN',
                        )
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(response))
                        return
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(response)
                }
            } catch (e) {
                return unknownErrorMessage
            }
        }
    },
    UpdateDetails(
        preferred_name: string,
        phone: string,
        email: string,
        invalid_preferred_names?: string[],
    ): ThunkAction<Promise<Response.Error | undefined>> {
        return (dispatch, getState) => {
            return api
                .post('identity/update-details', {preferred_name, phone, email, invalid_preferred_names})
                .then(data => {
                    switch (data.type) {
                        case 'authentication_update_required':
                            return reauthenticateReturn(
                                () =>
                                    this.UpdateDetails(
                                        preferred_name,
                                        phone,
                                        email,
                                        invalid_preferred_names,
                                    )(dispatch, getState),
                                () => errorResponseFactory(tryAgainMessage),
                                () => errorResponseFactory('You must enter your password to update your details'),
                            )
                        case 'error':
                            return data
                        case 'identity_authenticated':
                            dispatch(this.handleIdentityResponse(data))
                            sendWrapperAppMessage({type: 'identityUpdated'})
                            return undefined
                        case 'internal_server_error':
                        case 'form_errors':
                            // TODO: handle this properly
                            break
                        default:
                            assertNever(data)
                            return undefined
                    }
                })
        }
    },
    UpdateEmail(email: string): ThunkAction<Promise<Response.Error | undefined>> {
        return (dispatch, getState) => {
            return api
                .post('identity/update-email', {
                    email,
                    acting_as_id: actingAsID(getState()),
                })
                .then(data => {
                    switch (data.type) {
                        case 'authentication_update_required':
                            return reauthenticateReturn(
                                () => this.UpdateEmail(email)(dispatch, getState),
                                () => errorResponseFactory(tryAgainMessage),
                                () => errorResponseFactory('You must enter your password to update your details'),
                            )
                        case 'error':
                            return data
                        case 'identity_authenticated':
                            dispatch(this.handleIdentityResponse(data))
                            return undefined
                        case 'internal_server_error':
                        case 'form_errors':
                            // TODO: handle this properly
                            break
                        default:
                            assertNever(data)
                            return undefined
                    }
                })
        }
    },
    UpdateDependentDetails(
        preferred_name: string,
        invalid_preferred_names?: string[],
        address?: AddressValue,
    ): ThunkAction<Promise<Response.Error | undefined>> {
        return async (dispatch, getState) => {
            try {
                const data = await api.post('identity/update-dependent-details', {
                    preferred_name,
                    invalid_preferred_names,
                    address,
                    acting_as_id: actingAsID(getState()),
                })
                switch (data.type) {
                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () =>
                                this.UpdateDependentDetails(
                                    preferred_name,
                                    invalid_preferred_names,
                                    address,
                                )(dispatch, getState),
                            () => errorResponseFactory(tryAgainMessage),
                            () => errorResponseFactory('You must enter your password to update details'),
                        )
                    case 'error':
                        return data
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(data))
                        sendWrapperAppMessage({type: 'identityUpdated'})
                        return undefined
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(data)
                        return undefined
                }
            } catch (e) {
                return errorResponseFactory(unknownErrorMessage)
            }
        }
    },

    UpdateFinancialDetails(
        ird_number: string,
        pir?: PIR,
        rwt?: RWT,
        acting_as_id?: string,
    ): ThunkAction<Promise<null | string>> {
        return async (dispatch, getState) => {
            if (!acting_as_id) {
                acting_as_id = actingAsID(getState())
            }
            const data = await api.post('identity/update-financial-details', {ird_number, pir, rwt, acting_as_id})
            switch (data.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => this.UpdateFinancialDetails(ird_number, pir, rwt, acting_as_id)(dispatch, getState),
                        () => tryAgainMessage,
                        () => 'You must enter your password to update your financial details',
                    )
                case 'identity_authenticated':
                    dispatch(this.handleIdentityResponse(data))
                    return null
                case 'internal_server_error':
                    // TODO: handle this properly
                    return null
                default:
                    assertNever(data)
                    return null
            }
        }
    },

    UpdateAUFinancialDetails(tfn_number: string, acting_as_id?: string): ThunkAction<Promise<null | string>> {
        return async (dispatch, getState) => {
            if (!acting_as_id) {
                acting_as_id = actingAsID(getState())
            }
            const data = await api.post('identity/update-au-financial-details', {tfn_number, acting_as_id})
            switch (data.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => this.UpdateAUFinancialDetails(tfn_number, acting_as_id)(dispatch, getState),
                        () => tryAgainMessage,
                        () => 'You must enter your password to update your financial details',
                    )
                case 'identity_authenticated':
                    dispatch(this.handleIdentityResponse(data))
                    return null
                case 'internal_server_error':
                    // TODO: handle this properly
                    return null
                default:
                    assertNever(data)
                    return null
            }
        }
    },

    redeemGiftCode(giftCode: string): ThunkAction<Promise<null | string>> {
        return (dispatch, getState) =>
            api
                .post('gifting/redeem', {gift_code: giftCode, acting_as_id: actingAsID(getState())})
                .then(response => {
                    switch (response.type) {
                        case 'identity_authenticated':
                            dispatch(this.handleIdentityResponse(response))
                            return null
                        case 'error':
                            return response.message
                        case 'internal_server_error':
                            return 'An error occurred redeeming your gift. Please try again.'
                        default:
                            assertNever(response)
                            return null
                    }
                })
                .catch(() => {
                    return unknownErrorMessage
                })
    },
    createDependent(payload: Request.IdentityDependentCreate): ThunkAction<Promise<string | undefined>> {
        return async (dispatch, getState) => {
            try {
                const response = await api.post('identity/dependent/create', payload)
                switch (response.type) {
                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () => this.createDependent(payload)(dispatch, getState),
                            () => tryAgainMessage,
                            () => reEnterPasswordMessage,
                        )
                    case 'form_errors':
                        return response.errors.promo_code
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(response))
                        sendWrapperAppMessage({type: 'identityUpdated'})
                        return
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(response)
                        return
                }
            } catch (e) {
                return unknownErrorMessage
            }
        }
    },
    acceptDependentConditions(version: DEPENDENT_DECLARATION_VERSION): ThunkAction<Promise<string | undefined>> {
        return async (dispatch, getState) => {
            try {
                const response = await api.post('identity/dependent/accept-conditions', {
                    version,
                    acting_as_id: actingAsID(getState()),
                })
                switch (response.type) {
                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () => this.acceptDependentConditions(version)(dispatch, getState),
                            () => tryAgainMessage,
                            () => reEnterPasswordMessage,
                        )
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(response))
                        return
                    case 'error':
                        return response.message
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(response)
                        return undefined
                }
            } catch (e) {
                return unknownErrorMessage
            }
        }
    },
    acceptTermsAndConditions(version: TC_VERSION_TYPE): ThunkAction<Promise<string | undefined>> {
        return async (dispatch, getState) => {
            try {
                const response = await api.post('identity/identity/accept-terms-conditions', {
                    tc_version: version,
                    acting_as_id: actingAsID(getState()),
                })
                switch (response.type) {
                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () => this.acceptTermsAndConditions(version)(dispatch, getState),
                            () => tryAgainMessage,
                            () => reEnterPasswordMessage,
                        )
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(response))
                        return
                    case 'error':
                        return response.message
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(response)
                        return undefined
                }
            } catch (e) {
                return unknownErrorMessage
            }
        }
    },
    UpdateTransferAge(age: number): ThunkAction<Promise<string | undefined>> {
        return async (dispatch, getState) => {
            try {
                const data = await api.post('identity/update-transfer-age', {age, acting_as_id: actingAsID(getState())})
                switch (data.type) {
                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () => this.UpdateTransferAge(age)(dispatch, getState),
                            () => tryAgainMessage,
                            () => 'You must enter your password to update the transfer age',
                        )
                    case 'error':
                        return data.message
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(data))
                        return undefined
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(data)
                        return undefined
                }
            } catch (e) {
                return unknownErrorMessage
            }
        }
    },
    CloseAccount(
        reason: Request.IdentityCloseAccount['reason'],
        comments: string,
        preferred_name?: string,
        acting_as_id?: string,
    ): ThunkAction<Promise<CloseAccountResponse>> {
        return async (dispatch, getState) => {
            try {
                const state = getState()
                preferred_name = preferred_name || state.identity.user!.preferred_name
                acting_as_id = acting_as_id || actingAsID(state)
                const response = await api.post('identity/close-account', {reason, comments, acting_as_id})

                switch (response.type) {
                    case 'authentication_update_required':
                        return reauthenticateReturn(
                            () => this.CloseAccount(reason, comments, preferred_name, acting_as_id)(dispatch, getState),
                            () => ({error: 'Please resubmit your close account request'}),
                            () => ({error: "You can't close your account until you enter your password"}),
                        )
                    case 'error':
                        return {error: response.message}
                    case 'identity_anonymous':
                        dispatch(this.handleIdentityResponse(response))
                        return {isDependent: false, preferredName: preferred_name}
                    case 'identity_authenticated':
                        dispatch(this.handleIdentityResponse(response))
                        sendWrapperAppMessage({type: 'identityUpdated'})
                        return {isDependent: true, preferredName: preferred_name}
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(response)
                }
                return {error: 'An unknown error occurred closing your account'}
            } catch (e) {
                return {error: 'An unknown error occurred closing your account'}
            }
        }
    },
    makeActive(): ThunkAction<Promise<void | string>> {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())
            const response = await api.post('identity/sign-up/make-active', {acting_as_id})
            switch (response.type) {
                case 'identity_authenticated':
                    dispatch(this.handleIdentityResponse(response))
                    break
                case 'error':
                    return response.message
                case 'internal_server_error':
                    break
                default:
                    assertNever(response)
            }
        }
    },
    AddressVerification(files: File[]): ThunkAction<Promise<void | string>> {
        return async dispatch => {
            const response = await api.formPost('identity/address-verification-upload', {files})
            rudderTrackSignup('address_verification_sent')

            if (['error', 'internal_server_error'].includes(response.type)) {
                rudderTrackSignup('address_verification_failed')
            }

            switch (response.type) {
                case 'identity_authenticated':
                    dispatch(this.handleIdentityResponse(response))
                    break
                case 'error':
                    return response.message
                case 'internal_server_error':
                    break
                default:
                    assertNever(response)
            }
            return
        }
    },
    MarkHasSeenFlag(flag: Request.CustomerMarkHasSeenFlag['flag']): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            dispatch(actions.SetHasSeen(flag))
            const acting_as_id = actingAsID(getState())
            const response = await api.post('identity/identity/mark-has-seen-flag', {acting_as_id, flag})
            switch (response.type) {
                case 'identity_authenticated':
                    dispatch(this.handleIdentityResponse(response))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    RefreshPendingOrders(
        buyOrderIds: string[],
        sellOrderIds: string[],
        transferOrderIds: string[],
        applicationIds: string[],
    ): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const response = await api.post('identity/pending-orders-v2', {
                acting_as_id: actingAsID(getState()),
                buy_orders: buyOrderIds,
                sell_orders: sellOrderIds,
                transfer_orders: transferOrderIds,
                applications: applicationIds,
            })
            if (response.type === 'internal_server_error') {
                // TODO: handle this properly
                return
            }
            const currentOrders = getCurrentOrders(getState())

            const ordersOut: Response.IdentityAuthenticated['orders'] = []

            const updatedOrders = response.orders

            // We keep withdrawals
            currentOrders.forEach(order => {
                if (order.type === 'withdrawal') {
                    ordersOut.push(order)
                    return
                }

                if (order.type === 'corporate_action_v2') {
                    if (!order.is_terminal) {
                        ordersOut.push(order)
                    }
                    return
                }

                const newOrder = (updatedOrders[order.id] as typeof order) || order

                if (['cancelling', 'processing', 'pending', 'ordered', 'new'].includes(newOrder.state)) {
                    ordersOut.push(updatedOrders[order.id])
                }
            })
            switch (response.type) {
                case 'pending_orders':
                    dispatch(actions.UpdatePendingOrders(ordersOut))
                    break
                default:
                    assertNever(response)
            }
        }
    },
    FetchKidsAccountTransferTokenData(token: string): ThunkAction<Promise<void>> {
        return async dispatch => {
            const response = await api.post('identity/kids-account-transfer-token-data', {dependent_id_token: token})
            switch (response.type) {
                case 'error':
                    dispatch(actions.SetKidsAccountTransferTokenError())
                    break
                case 'identity_token_data':
                    dispatch(actions.SetKidsAccountTransferTokenData(response))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    SetSearchedFund(fund_id: Request.SearchedFund['fund_id']): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const response = await api.post('identity/set-searched-fund', {
                fund_id,
                acting_as_id: actingAsID(getState()),
            })
            switch (response.type) {
                case 'identity_authenticated':
                    dispatch(this.handleIdentityResponse(response))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    GetIdentityCloseAccountCheck(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const response = await api.get('identity/close-account-check', {
                acting_as_id: actingAsID(getState()),
            })
            switch (response.type) {
                case 'identity_close_account_check':
                    dispatch(actions.SetCloseAccountCheck(response))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    GetCitizenships(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const response = await api.get('settings/citizenship', {
                acting_as_id: actingAsID(getState()),
            })
            switch (response.type) {
                case 'settings_citizenship':
                    dispatch(actions.SetCitizenships(response.countries))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    GetNotificationPreferences(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const response = await api.get('identity/notification-preferences-v2', {
                acting_as_id: actingAsID(getState()),
            })
            switch (response.type) {
                case 'notification_preferences_v2':
                    dispatch(actions.SetNotificationPreferences(response.categories))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    GetSharesightClientCredentials(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            // Grab client credentials we use to authenticate ourselves as Sharesies when redirecting investors to Sharesight.
            dispatch(actions.SetSharesightGetIntegrationsLoadingState('loading'))
            const response = await api.get('sharesight/client-credentials', {
                acting_as_id: actingAsID(getState()),
            })
            switch (response.type) {
                case 'sharesight_client_credentials':
                    dispatch(actions.SetSharesightClientCredentials(response.client_id, response.redirect_uri))
                    break
                case 'internal_server_error':
                    dispatch(actions.SetSharesightGetIntegrationsLoadingState('error'))
                    rollbar.sendError('Failed to get the Sharesight Client credentials from the backend.')
                    break
                default:
                    assertNever(response)
            }
        }
    },
    GetSharesightIntegrations(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            dispatch(actions.SetSharesightGetIntegrationsLoadingState('loading'))
            dispatch(actions.SetSharesightGetPortfolioLoadingState('ready'))
            const response = await api.get('sharesight/integrations', {
                acting_as_id: actingAsID(getState()),
            })
            switch (response.type) {
                case 'sharesight_integrations':
                    dispatch(actions.SetSharesightIntegrations(response.sharesight_integrations))
                    break
                case 'internal_server_error':
                    dispatch(actions.SetSharesightGetIntegrationsLoadingState('error'))
                    rollbar.sendError(
                        `Failed to get Sharesight integrations for customer_id=${actingAsID(getState())}}.`,
                    )
                    break
                default:
                    assertNever(response)
            }
        }
    },
    GetSharesightPortfolios(portfolioId: string): ThunkAction<Promise<void | null | string>> {
        return async (dispatch, getState) => {
            dispatch(actions.SetSharesightGetPortfolioLoadingState('loading'))
            const response = await api.get('sharesight/portfolios', {
                acting_as_id: actingAsID(getState()),
                portfolio_id: portfolioId,
            })

            switch (response.type) {
                case 'sharesight_portfolios':
                    dispatch(actions.SetSharesightPortfolios(response.sharesight_portfolios))
                    break
                case 'error':
                    // TODO - change this error type to a 'sharesight_api_error' on the backend
                    // Will occur where -> Sharesight Error (API down) OR token revoked.
                    dispatch(actions.SetSharesightGetPortfolioLoadingState('error'))
                    rollbar.sendError(`Failed to get Sharesight portfolios for customer_id=${actingAsID(getState())}}.`)
                    break
                case 'internal_server_error':
                    dispatch(actions.SetSharesightGetPortfolioLoadingState('error'))
                    rollbar.sendError(`Failed to get Sharesight portfolios for customer_id=${actingAsID(getState())}}.`)
                    break
                default:
                    assertNever(response)
            }
        }
    },
    AddSharesightIntegration(token: string, portfolioId: string): ThunkAction<Promise<void | null | string>> {
        return async (dispatch, getState) => {
            dispatch(actions.SetSharesightGetIntegrationsLoadingState('loading'))
            dispatch(actions.SetSharesightGetPortfolioLoadingState('loading'))
            const response = await api.post('sharesight/oauth', {
                acting_as_id: actingAsID(getState()),
                portfolio_id: portfolioId,
                auth_code: token,
            })

            switch (response.type) {
                case 'sharesight_integrations':
                    dispatch(actions.SetSharesightIntegrations(response.sharesight_integrations))
                    break
                case 'error':
                    // TODO - change this error type to a 'sharesight_api_error' on the backend
                    dispatch(actions.SetSharesightGetIntegrationsLoadingState('error'))
                    rollbar.sendError(
                        `Failed to add a Sharesight integration for customer_id=${actingAsID(getState())}}.`,
                    )
                    break
                case 'internal_server_error':
                    dispatch(actions.SetSharesightGetIntegrationsLoadingState('error'))
                    rollbar.sendError(
                        `Failed to add a Sharesight integration for customer_id=${actingAsID(getState())}}.`,
                    )
                    break
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => this.AddSharesightIntegration(token, portfolioId)(dispatch, getState),
                        () => tryAgainMessage,
                        () => 'You must enter your password to integrate with your sharesight account',
                    )
                default:
                    assertNever(response)
            }
        }
    },
    AddSharesightPortfolio(
        portfolioId: string,
        sharesightPortfolioId: number,
        isExtractingHistoricalTrades: boolean,
    ): ThunkAction<Promise<void | null | string>> {
        return async (dispatch, getState) => {
            dispatch(actions.SetSharesightGetIntegrationsLoadingState('loading'))
            const response = await api.post('sharesight/add_sharesight_portfolio', {
                acting_as_id: actingAsID(getState()),
                portfolio_id: portfolioId,
                sharesight_portfolio_id: sharesightPortfolioId,
            })

            switch (response.type) {
                case 'sharesight_integrations':
                    dispatch(actions.SetSharesightIntegrations(response.sharesight_integrations))
                    // ONLY if we successfully add the Sharesight portfolio do we want to THEN extract the trades historically
                    if (isExtractingHistoricalTrades) {
                        dispatch(this.SendHistoricEvents(portfolioId, sharesightPortfolioId))
                    }
                    break
                case 'internal_server_error':
                    dispatch(actions.SetSharesightGetIntegrationsLoadingState('error'))
                    rollbar.sendError(
                        `Failed to add a Sharesight portfolio for customer_id=${actingAsID(getState())}}.`,
                    )
                    return 'Something went wrong :('
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => this.DeleteSharesightIntegration(portfolioId, sharesightPortfolioId)(dispatch, getState),
                        () => tryAgainMessage,
                        () => 'You must enter your password to integrate with your sharesight account',
                    )
                case 'error':
                    dispatch(actions.SetSharesightGetPortfolioLoadingState('error'))
                    return response.message
                default:
                    assertNever(response)
            }
        }
    },
    SendHistoricEvents(portfolioId: string, sharesightPortfolioId: number): ThunkAction<Promise<void | null | string>> {
        return async (dispatch, getState) => {
            const response = await api.post('sharesight/extract_historic_events', {
                acting_as_id: actingAsID(getState()),
                portfolio_id: portfolioId,
                sharesight_portfolio_id: sharesightPortfolioId,
            })

            switch (response.type) {
                case 'sharesight_integrations':
                    dispatch(actions.SetSharesightIntegrations(response.sharesight_integrations))
                    break
                case 'internal_server_error':
                    dispatch(actions.SetSharesightGetIntegrationsLoadingState('error'))
                    rollbar.sendError(
                        `Failed to send historical records for new Sharesight integration for customer_id=${actingAsID(
                            getState(),
                        )}`,
                    )
                    break
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => this.SendHistoricEvents(portfolioId, sharesightPortfolioId)(dispatch, getState),
                        () => tryAgainMessage,
                        () => 'You must enter your password to send past transactions to Sharesight',
                    )
                default:
                    assertNever(response)
            }
        }
    },
    DeleteSharesightIntegration(
        portfolioId: string,
        sharesightPortfolioId?: number,
    ): ThunkAction<Promise<void | null | string>> {
        return async (dispatch, getState) => {
            dispatch(actions.SetSharesightGetIntegrationsLoadingState('loading'))
            const response = await api.post('sharesight/delete_integration', {
                acting_as_id: actingAsID(getState()),
                portfolio_id: portfolioId,
                sharesight_portfolio_id: sharesightPortfolioId,
            })

            switch (response.type) {
                case 'sharesight_integrations':
                    dispatch(actions.SetSharesightGetPortfolioLoadingState('ready'))
                    dispatch(actions.SetSharesightIntegrations(response.sharesight_integrations))
                    break
                case 'internal_server_error':
                    dispatch(actions.SetSharesightGetIntegrationsLoadingState('error'))
                    rollbar.sendError(
                        `Failed to delete a Sharesight integration for customer_id=${actingAsID(getState())}}.`,
                    )
                    break
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => this.DeleteSharesightIntegration(portfolioId, sharesightPortfolioId)(dispatch, getState),
                        () => tryAgainMessage,
                        () => 'You must enter your password to integrate with your sharesight account',
                    )
                default:
                    assertNever(response)
            }
        }
    },
    UpdateNotificationPreference(
        notification_category: string,
        channel: Request.NotificationPreferencesUpdateSinglePreference['channel'],
        subscribed: boolean,
    ): ThunkAction<Promise<void | null | string>> {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())
            const response = await api.post('identity/notification-preferences/update-single-pref', {
                acting_as_id,
                notification_category,
                channel,
                subscribed,
            })
            switch (response.type) {
                case 'notification_preferences_v2':
                    dispatch(actions.SetNotificationPreferences(response.categories))
                    break
                case 'error':
                case 'internal_server_error':
                    return 'error'
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () =>
                            this.UpdateNotificationPreference(
                                notification_category,
                                channel,
                                subscribed,
                            )(dispatch, getState),
                        () => tryAgainMessage,
                        () => 'You must enter your password to update your notification preferences',
                    )
                default:
                    assertNever(response)
            }
        }
    },
    UpdateNotificationFundPreference(
        notification_category: string,
        fund_id: string,
        subscribed: boolean,
    ): ThunkAction<Promise<void | null | string>> {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())
            const response = await api.post('identity/notification-preferences/update-single-fund-pref', {
                acting_as_id,
                notification_category,
                fund_id,
                subscribed,
            })
            switch (response.type) {
                case 'notification_preferences_v2':
                    dispatch(actions.SetNotificationPreferences(response.categories))
                    break
                case 'error':
                case 'internal_server_error':
                    return 'error'
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () =>
                            this.UpdateNotificationFundPreference(
                                notification_category,
                                fund_id,
                                subscribed,
                            )(dispatch, getState),
                        () => tryAgainMessage,
                        () => 'You must enter your password to update your notification preferences',
                    )
                default:
                    assertNever(response)
            }
        }
    },
    VerifyEmail(): ThunkAction<Promise<'success' | 'error'>> {
        return async () => {
            const endpoint = 'verification/generate_email_token'
            const response = await api.post(endpoint)

            switch (response.type) {
                // Error case
                case 'error':
                    // Possible cases are: not authenticated, flag not enabled
                    return 'error'

                // Success
                case 'email_verification_token_generated':
                    return 'success'

                case 'internal_server_error':
                    rollbar.sendError('Unexpected error generating email verification token', {
                        method: 'SettingsVerifyEmail',
                        endpoint,
                    })
                    return 'error'
                default:
                    return 'error'
            }
        }
    },
    VerifyEmailToken(token: string): ThunkAction<Promise<string | null>> {
        return async () => {
            const endpoint = 'verification/verify_email_token'
            const response = await api.post(endpoint, {token})

            if (!response || !response.type) {
                rollbar.sendError('Unexpected error verifying email verification token', {
                    method: 'verifyToken',
                    payload: token,
                    endpoint,
                })

                // Prevent any further attempt at setting tokenStatus,
                // this case is unexpected. Will fallback to Banana Screen
                return 'unexpected_error'
            } else if (response.type === 'error' && [INVALID_CODE, EXPIRED_CODE].includes(response.code)) {
                return response.code
            } else {
                return response.type
            }
        }
    },
    GenerateEmailVerifyTokenFromExpired(token: string): ThunkAction<Promise<string | null>> {
        return async () => {
            const endpoint = 'verification/generate_email_token_from_expired_token'
            const response = await api.post(endpoint, {token})

            if (response?.type === GENERATED_CODE) {
                return GENERATED_CODE
            } else if (!response || !response.type) {
                rollbar.sendError('Unexpected error generating token from expired token', {
                    method: 'generateTokenFromExpired',
                    payload: token,
                    endpoint,
                })

                // Prevent any further attempt at setting tokenStatus,
                // this case is unexpected. Will fallback to Banana Screen
                return 'unexpected_error'
            } else if (response.type === 'error') {
                // Invalid code response
                return response.code
            }
            return null
        }
    },
    UpdateIncludeSoldInvestments(include: boolean): ThunkAction<Promise<void | string>> {
        return async (dispatch, getState) => {
            const state = getState()
            const acting_as_id = actingAsID(state)
            const portfolio_id = state.identity.user?.portfolio_id

            if (!acting_as_id || !portfolio_id) {
                return 'error'
            }

            const response = await api.post('portfolio/set-portfolio-open-closed-preference', {
                acting_as_id,
                portfolio_id,
                include_sold_investments: include,
            })

            switch (response.type) {
                case 'set-portfolio-open-closed-preference':
                    sendWrapperAppMessage({type: 'identityUpdated'})
                    dispatch(actions.SetIncludeSoldInvestments(response))
                    break
                case 'error':
                case 'internal_server_error':
                    return 'error'
                default:
                    break
            }
        }
    },
    UpdateEnableExtendedHours(enable: boolean): ThunkAction<Promise<void | string>> {
        return async (dispatch, getState) => {
            const state = getState()
            const acting_as_id = actingAsID(state)
            const portfolio_id = state.identity.user?.portfolio_id

            if (!acting_as_id || !portfolio_id) {
                return 'error'
            }

            const response = await api.post('portfolio/set-enable-extended-hours', {
                acting_as_id,
                portfolio_id,
                enable_extended_hours: enable,
            })

            switch (response.type) {
                case 'set_enable_extended_hours':
                    dispatch(actions.SetEnableExtendedHours(response))
                    break
                case 'error':
                case 'internal_server_error':
                    return 'error'
                default:
                    break
            }
        }
    },
    UpdatePortfolioFilterPreference(newFilter: PortfolioFilterOptions): ThunkAction<Promise<void | null | string>> {
        return async (dispatch, getState) => {
            const state = getState()
            const acting_as_id = actingAsID(state)
            const portfolio_id = state.identity.user?.portfolio_id

            if (!acting_as_id || !portfolio_id) {
                return
            }

            const response = await api.post('portfolio/set-filter-preference', {
                acting_as_id,
                portfolio_id,
                filter_preference: newFilter,
            })

            switch (response.type) {
                case 'set_filter_preference':
                    dispatch(actions.SetPortfolioFilterPreference(response.filter_preference))
                    break
                default:
                    // Fail silently
                    break
            }
        }
    },
    UpdatePortfolioSortPreference(newSort: PortfolioSortOptions): ThunkAction<Promise<void | null | string>> {
        return async (dispatch, getState) => {
            const state = getState()
            const acting_as_id = actingAsID(state)
            const portfolio_id = state.identity.user?.portfolio_id

            if (!acting_as_id || !portfolio_id) {
                return
            }

            const response = await api.post('portfolio/set-sort-preference', {
                acting_as_id,
                portfolio_id,
                sort_preference: newSort,
            })

            switch (response.type) {
                case 'set_sort_preference':
                    dispatch(actions.SetPortfolioSortPreference(response.sort_preference))
                    break
                default:
                    break
            }
        }
    },
    UpdateCitizenships(
        countries: Request.SettingsSetCitizenship['countries'],
    ): ThunkAction<Promise<void | null | string>> {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())
            const response = await api.post('settings/citizenship', {acting_as_id, countries})

            switch (response.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => this.UpdateCitizenships(countries)(dispatch, getState),
                        () => tryAgainMessage,
                        () => 'You must enter your password to update the citizenship details',
                    )
                case 'settings_citizenship':
                    dispatch(actions.SetCitizenships(response.countries))
                    break
                case 'internal_server_error':
                    break
                default:
                    assertNever(response)
            }

            return
        }
    },
    DismissPortfolioIntroCard(card: Request.CustomerDismissPortfolioIntroCard['card']): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())
            const response = await api.post('identity/identity/dismiss-portfolio-intro-card', {acting_as_id, card})
            switch (response.type) {
                case 'identity_authenticated':
                    dispatch(this.handleIdentityResponse(response))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    UpdatePreferredProduct(
        preference: Request.PreferredProductUpdate['preferred_product'],
    ): ThunkAction<Promise<void | null | string>> {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())
            const response = await api.post('identity/sign-up/product-preference', {
                acting_as_id,
                preferred_product: preference,
            })
            switch (response.type) {
                case 'identity_authenticated':
                    dispatch(this.handleIdentityResponse(response))
                    break
                case 'internal_server_error':
                    break
                default:
                    assertNever(response)
            }
        }
    },
    RemoveNotification(card: Card): ThunkAction<void> {
        return async dispatch => {
            // Dismiss card in Braze
            logCardDismissal(card)

            // Then, filter out this card and set. Noting that cards can sometimes have no id (weird, right) so
            // this caters for that too
            const cachedCards = getCachedContentCards()
            if (cachedCards) {
                cachedCards.cards = cachedCards.cards.filter(el => !isEqual(el, card))
                dispatch(actions.SetNotifications(cachedCards))
            }
        }
    },

    UploadOrderImage(image: File[]): ThunkAction<Promise<void | string | ImageUpload>> {
        const data = {
            files: image,
        }

        return async () => {
            try {
                const response = await api.formPost('identity/image-upload', data)
                switch (response.type) {
                    case 'identity_image_upload':
                        // Currently we only support single uploads so only one
                        // result will be returned
                        const {image_id, image_url} = response.uploaded_images[0]
                        return {image_id, image_url}
                    case 'error':
                        return response.message
                    case 'internal_server_error':
                        return 'Something went wrong with your upload, please try again'
                }
            } catch (e) {
                return
            }
        }
    },
    GetOrderImages(): ThunkAction<Promise<void | string>> {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())

            try {
                const response = await api.get('identity/get-order-images', {acting_as_id})

                switch (response.type) {
                    case 'identity_get_image':
                        await dispatch(actions.SetCustomImages(response.custom_images))
                        break
                    case 'error':
                        break
                }
            } catch (e) {
                return 'error'
            }
        }
    },
    ArchiveImage(imageId: string): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())

            try {
                await api.get('identity/archive-order-image', {acting_as_id, image_id: imageId}).then(() => {
                    dispatch(this.GetOrderImages())
                })
            } catch (e) {
                return
            }
        }
    },
    UnsubscribeFromNotificationCategory(category: string): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())

            try {
                const response = await api.post('identity/notification-preferences/unsubscribe-from-category', {
                    acting_as_id,
                    category,
                })

                switch (response.type) {
                    case 'internal_server_error':
                    case 'error':
                        // This shouldn't happen; throw
                        throw new Error(response.toString())
                    default:
                        dispatch(this.handleIdentityResponse(response))
                }
            } catch (e) {
                return
            }
        }
    },
    FetchFeedbackSubmissions: (): ThunkAction<Promise<void>> => {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())
            try {
                const response = await api.get('feedback/submissions', {acting_as_id})
                if (response.type === 'feedback_submissions') {
                    dispatch(
                        actions.SetFeedbackState({
                            loadingState: 'ready',
                            submittedActionIdentifiers: response.action_identifiers,
                        }),
                    )
                } else {
                    throw new Error()
                }
            } catch {
                dispatch(
                    actions.SetFeedbackState({
                        loadingState: 'error',
                        submittedActionIdentifiers: [],
                    }),
                )
            }
        }
    },
}

export type ActionsType = ActionsUnion<typeof actions>

export default {...actions, ...thunkActions}
