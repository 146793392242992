import {semanticColour} from '@design-system/colour-tokens'
import {CheckSingle, Clock, Portfolio} from '@design-system/icon'
import {useColourMode} from '@design-system/use-colour-mode'
import {useFeature} from '@growthbook/growthbook-react'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {useRetailPost} from '~/api/query/retail'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ProductIntroSlideContent} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {ProductIntroSlides} from '~/global/widgets/intro-slides/IntroSlides'
import {Toast} from '~/global/widgets/toast/Toast'
import kiwisaverAbstract from '~/sections/kiwisaver/sections/sign-up/assets/images/sign-up-intro/kiwisaver-abstract.png'
import {
    SignUpGuardRedirect,
    SignUpPage,
} from '~/sections/kiwisaver/sections/sign-up/widgets/redirect/SignUpGuardRedirect'
import {useKSCustomerOrNull} from '~/sections/kiwisaver/state'
import {useAppSelector} from '~/store/hooks'
import {actingAsID as actingAsIDSelector} from '~/store/identity/selectors'
import styles from './SignUpInfoPage.scss'

export const SignUpSlides: React.FunctionComponent = () => {
    const actingAsID = useAppSelector(actingAsIDSelector)
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const customer = useKSCustomerOrNull()
    const setSignUp = useRetailPost({path: 'kiwisaver/sign-up', queryCacheToUpdate: ['kiwisaver/customer']})
    const ksSignupConversionVariant = useFeature<boolean>('ks-signup-convension').value
    const colourMode = useColourMode()

    React.useEffect(() => {
        rudderTrack('kiwisaver_signup', 'signup_landing_viewed')
    }, [])

    const handleStartSignUp = () => {
        rudderTrack('kiwisaver_signup', 'start_signup_clicked')
        navigate(profileUrl('kiwisaver/sign-up/goals-and-values'))
    }

    const content: ProductIntroSlideContent[] = []

    // A/B testing content
    if (ksSignupConversionVariant) {
        content.push({
            backgroundColour: semanticColour('PageBackgroundBase', colourMode),
            title: '',
            buttonLabel: 'Join now',
            bodyRenderer: () => (
                <div className={styles.signUpInfo}>
                    <h1 className={spacing.spaceAbove32}>Join the Sharesies KiwiSaver Scheme</h1>
                    <p>Invest for your retirement or first home with more choice and control.</p>
                    <div className={cn(styles.signUpInfoRow)}>
                        <div className={styles.imageContainer}>
                            <Portfolio size={20} />
                        </div>
                        <div className={styles.contentContainer}>
                            <h2>Do it all in Sharesies</h2>
                            <p>No need to talk to IRD or your current KiwiSaver provider—we’ll handle it!</p>
                        </div>
                    </div>
                    <div className={cn(styles.signUpInfoRow)}>
                        <div className={styles.imageContainer}>
                            <CheckSingle size={20} />
                        </div>
                        <div className={styles.contentContainer}>
                            <h2>Joining is a breeze</h2>
                            <p>No forms, no fuss—just tell us how you’d like your KiwiSaver balance invested.</p>
                        </div>
                    </div>
                    <div className={cn(styles.signUpInfoRow)}>
                        <div className={styles.imageContainer}>
                            <Clock size={20} />
                        </div>
                        <div className={styles.contentContainer}>
                            <h2>You’ll be done in no time</h2>
                            <p>It usually takes less than five minutes to join.</p>
                        </div>
                    </div>
                </div>
            ),
        })
    } else {
        content.push(
            {
                backgroundColour: '#E6D6F9',
                image: kiwisaverAbstract,
                title: 'The choice you’ve been waiting for',
                buttonLabel: 'How does it work?',
                bodyRenderer: () => (
                    <p>
                        Join in just a few minutes and invest your KiwiSaver balance in the companies and funds that
                        matter to you most.
                    </p>
                ),
            },
            {
                backgroundColour: '#FFE2E8',
                animation: import('~/sections/kiwisaver/sections/sign-up/assets/animations/managed_funds.json'),
                title: 'First up, you’ll choose a base fund',
                buttonLabel: 'Nice! What’s next?',
                bodyRenderer: () => (
                    <p>
                        The bulk of your investment will go in the fund you pick—fund providers manage these to meet an
                        investment strategy.
                    </p>
                ),
            },
            {
                backgroundColour: '#D1E9D8',
                animation: import('~/sections/kiwisaver/sections/sign-up/assets/animations/self_select.json'),
                title: 'Then, if you like, you can add your own picks',
                buttonLabel: 'Start sign up',
                bodyRenderer: () => (
                    <p>
                        Invest up to half of your balance in a range of NZX companies and funds on Sharesies—up to 5%
                        per investment.
                    </p>
                ),
            },
        )
    }

    return (
        <SignUpGuardRedirect currentPage={SignUpPage.INTRO}>
            <ProductIntroSlides
                content={content}
                onComplete={async () => {
                    if (!customer) {
                        try {
                            await setSignUp.mutateAsync({
                                acting_as_id: actingAsID,
                            })
                            handleStartSignUp()
                        } catch (e) {
                            Toast('Please try again')
                        }
                    } else {
                        handleStartSignUp()
                    }
                }}
            />
        </SignUpGuardRedirect>
    )
}
