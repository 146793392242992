import {useProfile} from './useProfile'
import {useProfileOwner} from './useProfileOwner'

export const useHasInsureAccess = () => {
    const profile = useProfile()
    const owner = useProfileOwner()

    if (process.env.NODE_ENV === 'development') {
        /**
         * Access to Insure is turned off in local dev by default.
         * Local dev is connected to Cove's non-Prod system, which means that API calls using real-world Cove
         * credentials will 403.
         * Apart from these API calls being unnecessary, they cause a bunch of console noise.
         * To develop or debug in local dev, comment out this restriction and follow the instructions here:
         * https://sharesies.atlassian.net/wiki/spaces/WHEK/pages/524386326/Car+Insurance+Technical+Implementation#Debugging-and-testing
         */
        return false
    }

    return owner.product_jurisdiction === 'nz' && profile.is_personal
}
