import {Button} from '@design-system/button'
import {InjectedFormikProps, withFormik} from 'formik'
import React from 'react'
import {Model, Request} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {processAddressComponents} from '~/global/utils/format-address/formatAddress'
import {ErrorBox} from '~/global/widgets/form-controls'
import {extractCountryCodeFromName} from '~/global/widgets/form-controls/country-select/CountrySelectInput'
import ManualAddressFields, {
    AddressValidation as ValidateAddress,
} from '~/global/widgets/manual-address-fields/ManualAddressFields'
import styles from '~/sections/invest/sections/transfer-shares/sections/asx-transfer-shares/sections/add-registry-details-address/pages/add-registry-details-address-form/AddRegistryDetailsAddressForm.scss'
import {AddressComponents} from '~/store/identity/types'

const AddRegistryDetailAddressForm: React.FunctionComponent<InjectedFormikProps<FormProps, AddressComponents>> = ({
    isSubmitting,
    handleSubmit,
    isValid,
    values,
    initialValues,
    status,
    jurisdiction,
    addressChangeRequired,
}) => {
    const addressHasChanged = !Object.entries(values).every(([key, value]: (keyof AddressComponents)[]) => {
        return value === initialValues[key]
    })

    return (
        <form onSubmit={handleSubmit} className={styles.asxTransferAddressForm}>
            <p className={spacing.spaceBelow20}>
                Editing this address will not change your address on file with Sharesies. It’ll simply allow the
                transfer to take place.
            </p>
            <ManualAddressFields selectedCountry={values.country_code} jurisdiction={jurisdiction} />
            {status && <ErrorBox message={status} />}
            <Button
                isSubmit
                pageButton
                dataTestId="button--save-changes"
                label="Next"
                disabled={!isValid || (addressChangeRequired && !addressHasChanged)}
                processing={isSubmitting}
            />
        </form>
    )
}

interface FormProps {
    address: Model.User['address']
    jurisdiction: Model.User['jurisdiction']
    addressChangeRequired: boolean
    handleSubmit: (address: Request.RegistryDetailsUpsert['address']) => void
}

const Form = withFormik<FormProps, AddressComponents>({
    mapPropsToValues: ({address}) => {
        return {
            sublocality: address!.components.sublocality || '',
            street_number: address!.components.street_number || '',
            route: address!.components.route || '',
            locality: address!.components.locality || '',
            state_code: address!.components.state_code || '',
            postal_code: address!.components.postal_code || '',
            country_code:
                address!.components.country_code || extractCountryCodeFromName(address!.components.country) || '',
        }
    },
    handleSubmit: async (
        {sublocality, street_number, route, locality, state_code, postal_code, country_code},
        {setSubmitting, props: {handleSubmit}},
    ) => {
        const address = processAddressComponents({
            street_number,
            route,
            locality,
            postal_code,
            country_code,
            state_code,
            sublocality,
        })
        setSubmitting(true)
        try {
            await handleSubmit(address)
        } catch (error) {
            setSubmitting(false)
            throw error
        }
    },
    validate: values => {
        return ValidateAddress(values)
    },
})(AddRegistryDetailAddressForm)

export default Form
