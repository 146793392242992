import {isOnASX, isOnNZX} from '~/global/utils/is-on-exchange/isOnExchange'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {Instrument} from '~/store/instrument/types'

/**
 * We are now restricting the price limit of an NZX or ASX equity to an increase of 100% or to 30% of its
 * market price. A 100% increase is the same as doubling the number e.g if the instrument's market price is $100
 * then the acceptable upper bound limit is $200. 30% as a lower bound for this $100 instrument would be $30.
 *
 * @param {string | undefined} providedPriceLimit - price limit (this can be explicitly undefined)
 * @param {Instrument} instrument - instrument
 * @returns {object} an object with a boolean representing if it is a disorderly limit and an error message to be displayed.
 */
export const isDisorderlyLimit = (
    providedPriceLimit: string | undefined,
    instrument: Instrument,
): {isDisorderly: boolean; message: string | undefined} => {
    if (
        providedPriceLimit &&
        Number(providedPriceLimit) !== 0 &&
        (isOnNZX(instrument) || isOnASX(instrument)) &&
        tradingType(instrument) !== 'managed'
    ) {
        const upperBoundPriceLimit = (Number(instrument.marketPrice) / 100) * 200
        const lowerBoundPriceLimit = (Number(instrument.marketPrice) / 100) * 30
        const priceLimit = Number(providedPriceLimit)
        if (priceLimit > upperBoundPriceLimit || priceLimit < lowerBoundPriceLimit) {
            const higherOrLowerMessage = priceLimit > upperBoundPriceLimit ? 'high' : 'low'
            return {
                isDisorderly: true,
                message: `You’ve entered a limit price that’s too ${higherOrLowerMessage} and unlikely to trade. Try again with a price that’s closer to the market price`,
            }
        }
    }
    return {isDisorderly: false, message: undefined}
}
