import React from 'react'
import {ShareValue} from '~/global/widgets/number-elements/NumberElements'
import {useHoldingDetail} from '~/sections/moose/data/retail'
import {ItemType} from '~/sections/moose/sections/order/state'
import {shareLabel} from '~/sections/moose/sections/order/utils/share-label/shareLabel'
import styles from './StandardLimitsWidget.scss'

interface StandardLimitsWidgetProperties {
    symbol: string
    itemType: ItemType
    portfolioId: string
}

export const StandardLimitsWidget: React.FunctionComponent<StandardLimitsWidgetProperties> = ({
    symbol,
    itemType,
    portfolioId,
}: StandardLimitsWidgetProperties) => {
    const holdingDetail = useHoldingDetail(portfolioId)

    const instrumentHoldingDetail = holdingDetail.instruments.find(x => x.symbol === symbol)
    if (instrumentHoldingDetail == null) {
        return null
    }

    const label = shareLabel({
        itemType,
        isPlural: true,
    })

    return (
        <>
            <div className={styles.rangeOMeter}>
                <div className={styles.receiptBody}>
                    <div className={styles.receiptRow}>
                        <div className={styles.label}>
                            {shareLabel({
                                isCapitalised: true,
                                itemType,
                                isPlural: true,
                            })}{' '}
                            you own
                        </div>
                        <div className={styles.value}>
                            <ShareValue value={instrumentHoldingDetail.holding} />
                        </div>
                    </div>

                    <div className={styles.receiptRow}>
                        <div className={styles.label}>Reserved {label}</div>
                        <div className={styles.value}>
                            <ShareValue value={instrumentHoldingDetail.reserved ?? 0} />
                        </div>
                    </div>
                    <div className={styles.receiptRow}>
                        <div className={styles.label}>Available to sell</div>
                        <div className={styles.value}>
                            <ShareValue
                                value={instrumentHoldingDetail.permitted_to_sell ?? instrumentHoldingDetail.holding}
                            />
                        </div>
                    </div>
                    <hr className={styles.receiptRowDivider} />
                    <div>
                        Reserved {label} cannot be sold. These might refer to {label} that are part of a pending trade
                        with another broker
                    </div>
                </div>
            </div>
        </>
    )
}
