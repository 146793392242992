import {Modal} from '@design-system/modal'
import React from 'react'
import {Model} from '~/api/retail/types'
import {connect} from '~/store/connect'
import * as notification from '~/store/notification'
import actions from '~/store/notification/actions'
import AddressVerificationRequiredModal from './AddressVerificationRequiredModal'

type Props = StateProps & DispatchProps

export class AccountRestrictionModals extends React.PureComponent<Props, {}> {
    reloadTimer?: number

    render() {
        const {
            modalError,
            modalErrorTitle,
            accountLocked,
            addressValidationModalOpen,
            addressRejectReason,
            closeAddressValidationModal,
            accountRestricted,
            daysLeft,
        } = this.props

        return (
            <div className="notification">
                <Modal
                    isAlert
                    noClose
                    isOpen={accountLocked}
                    setIsOpen={_ => {
                        // The `_` argument will be called as false, but we don't need to use it in this case
                        // Normally we'd just be passing in setModalOpen (setIsOpen={setModalOpen}).
                        //
                        // This is a unique case where we don't want the investor to be able to close the modal,
                        // so we return false rather than setting `isOpen(false)`.
                    }}
                    title="Action required!"
                    dataTestId="modal--account-locked"
                    primaryButton={{
                        label: 'Get in touch',
                        onClick: () =>
                            (window.location.href =
                                'mailto:help@sharesies.com?subject=My account has been locked, what info do you need?'),
                    }}
                >
                    <p>Your account is temporarily locked because we need some more information from you.</p>
                    <p>Please get in touch with us so you can get back to investing.</p>
                </Modal>
                <Modal
                    isAlert
                    isOpen={!!modalError}
                    setIsOpen={notification.clearModalError}
                    title={modalErrorTitle || modalError || 'Error'}
                    dataTestId="modal--error"
                    primaryButton={{label: 'Close'}}
                >
                    {modalErrorTitle ? <p>{modalError}</p> : null}
                </Modal>
                <AddressVerificationRequiredModal
                    isOpen={(addressRejectReason && addressValidationModalOpen) || false}
                    onClose={closeAddressValidationModal}
                    reason={addressRejectReason}
                    accountRestricted={accountRestricted}
                    daysLeft={daysLeft}
                />
            </div>
        )
    }
}

interface StateProps {
    modalError: null | string
    modalErrorTitle: null | string
    accountLocked: boolean
    addressValidationModalOpen: boolean
    addressRejectReason: Model.User['address_reject_reason']
    accountRestricted: Model.User['account_restricted']
    daysLeft: number
}

interface DispatchProps {
    closeAddressValidationModal(): void
}

export default connect<StateProps, DispatchProps, {}>(
    ({notification: {modalError, modalErrorTitle, addressValidationModalOpen}, identity: {user}}) => {
        const daysLeft =
            user && user.account_restricted_date
                ? Math.round(user.account_restricted_date.diffNow('days').as('days'))
                : 0

        return {
            daysLeft,
            modalError,
            modalErrorTitle,
            accountLocked: user ? user.account_frozen : false,
            addressValidationModalOpen,
            addressRejectReason: user ? user.address_reject_reason : undefined,
            accountRestricted: user ? user.account_restricted : false,
        }
    },
    dispatch => ({
        closeAddressValidationModal: () => dispatch(actions.HideAddressValidationRejectionReason()),
    }),
)(AccountRestrictionModals)
