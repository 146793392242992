/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface PortfolioHistoryByDate
 */
export interface PortfolioHistoryByDate {
    /**
     *
     * @type {Array<string>}
     * @memberof PortfolioHistoryByDate
     */
    portfolio_uuids: Array<string>
    /**
     *
     * @type {Date}
     * @memberof PortfolioHistoryByDate
     */
    date: DateTime
}

export function PortfolioHistoryByDateFromJSON(json: any): PortfolioHistoryByDate {
    return PortfolioHistoryByDateFromJSONTyped(json, false)
}

export function PortfolioHistoryByDateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioHistoryByDate {
    if (json === undefined || json === null) {
        return json
    }
    return {
        portfolio_uuids: json['portfolio_uuids'],
        date: DateTime.fromISO(json['date'], {setZone: true}),
    }
}

export function PortfolioHistoryByDateToJSON(value?: PortfolioHistoryByDate | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        portfolio_uuids: value.portfolio_uuids,
        date: value.date.toFormat('yyyy-MM-dd'),
    }
}
