import React from 'react'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import HighlightBlock from '~/global/widgets/highlight-block/HighlightBlock'
import viewInstrumentStyles from '~/sections/invest/sections/view-instrument/ViewInstrument.scss'

const USPartnershipNotification = () => (
    <>
        <HighlightBlock className={viewInstrumentStyles.illiquid}>
            <p>
                This is a{' '}
                <HelpCentreLink nzArticle="6806378-us-partnership-investments">
                    US partnership investment
                </HelpCentreLink>
                . The IRS applies a 10% withholding tax to the sale of this investment, and will eventually require
                investors to have a personal US Tax Identification Number.
            </p>
        </HighlightBlock>
    </>
)

export default USPartnershipNotification
