import {AddressValue} from '@design-system/address-input'
import {extractCountryNameFromCode} from '~/global/widgets/form-controls/country-select/CountrySelectInput'
import {AddressComponents} from '~/store/identity/types'

export const processAddressComponents = (addressComponents: AddressComponents): AddressValue => {
    const countryCode = addressComponents.country_code
    const strippedComponents = {
        ...addressComponents,
    }
    if (countryCode !== 'AU') {
        strippedComponents.state_code = undefined
    }
    if (countryCode === 'AU') {
        strippedComponents.sublocality = undefined
    }

    const cleanAddress: AddressValue = {
        formatted: formatAddress(strippedComponents),
        components: {
            ...(strippedComponents.sublocality && {sublocality: strippedComponents.sublocality}),
            street_number: strippedComponents.street_number,
            route: strippedComponents.route,
            locality: strippedComponents.locality,
            ...(strippedComponents.state_code && {
                state_code: strippedComponents.state_code,
            }),
            postal_code: strippedComponents.postal_code,
            country_code: strippedComponents.country_code,
        },
    }
    return cleanAddress
}

export const formatAddress = (address: AddressComponents) => {
    let formattedAddress = `${address.street_number} ${address.route},`
    if (address.sublocality && address.sublocality.length > 0) {
        formattedAddress += ` ${address.sublocality},`
    }
    formattedAddress += ` ${address.locality}`
    if (address.country_code === 'AU' && address.state_code && address.state_code.length > 0) {
        formattedAddress += ` ${address.state_code}`
    }
    const country = extractCountryNameFromCode(address.country_code)
    formattedAddress += ` ${address.postal_code}, ${country}`
    return formattedAddress
}
