import {ChevronRight} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {AppSchemasForClientPortfolioLatestInstrumentReturn} from '~/api/rakaia/models'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {convertToKebabCase} from '~/global/utils/convert-to-kebab-case/convertToKebabCase'
import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {flattenList} from '~/global/utils/flatten-list/flattenList'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {SwitchField} from '~/global/widgets/form-controls'
import {DollarValue, PercentValue} from '~/global/widgets/number-elements/NumberElements'
import {OrderConfirmation, OrderRow} from '~/global/widgets/order-confirmation/OrderConfirmation'
import essActions from '~/store/employeeShareScheme/actions'
import {EmployeeShareSchemeAllocation, ESSAllocationLine} from '~/store/employeeShareScheme/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import styles from './UpcomingAllocations.scss'

interface Props {
    instrument: Instrument
    allocationsForInstrument: EmployeeShareSchemeAllocation
    currentReturn: AppSchemasForClientPortfolioLatestInstrumentReturn
}

interface ESSAllocationLineGroupNameById {
    [key: string]: string
}

const UpcomingShareAllocations: React.FunctionComponent<Props> = ({
    instrument,
    allocationsForInstrument,
    currentReturn,
}) => {
    const dispatch = useAppDispatch()
    const showCurrentDollarValue = useAppSelector(s => s.employeeShareScheme.showShareAllocationCurrentValue)
    const allocationLinesByAllocation: EmployeeShareSchemeAllocation['share_allocations'][0]['lines'][] = []
    const allocationLineGroupNames: ESSAllocationLineGroupNameById = {}
    allocationsForInstrument.share_allocations.forEach(allocation => {
        allocationLinesByAllocation.push(allocation.lines)
        allocation.lines.forEach(line => {
            allocationLineGroupNames[line.id] = allocation.group_name
        })
    })

    const allocationLines: ESSAllocationLine[] = flattenList(allocationLinesByAllocation)
    // filter allocation lines for unvested lines only, sort by vesting due date asc
    const orderedUnvestedAllocationLines = allocationLines
        .filter(line => !line.vest_transaction_id)
        .sort((a, b) => a.vesting_due_date.toMillis() - b.vesting_due_date.toMillis())

    // group the allocation lines by vesting due year
    const groupedUnvestedAllocationLines = orderedUnvestedAllocationLines.reduce(
        function (groups, line) {
            ;(groups[line.vesting_due_date.year] = groups[line.vesting_due_date.year] || []).push(line)
            return groups
        },
        {} as {[key: string]: ESSAllocationLine[]},
    )

    const getContributionRows = (): OrderRow[] => {
        const rows = []
        rows.push({
            description: 'Employer contribution',
            value: (
                <span className={styles.orderConfirmationValue}>
                    <DollarValue
                        value={allocationsForInstrument.total_unvested_employer_contribution}
                        currency={instrument.currency}
                    />
                </span>
            ),
        })

        if (allocationsForInstrument.total_unvested_employee_contribution) {
            rows.push({
                description: 'Your contribution',
                value: (
                    <span className={styles.orderConfirmationValue}>
                        <DollarValue
                            value={allocationsForInstrument.total_unvested_employee_contribution}
                            currency={instrument.currency}
                        />
                    </span>
                ),
            })
        }

        rows.push({
            description: 'Total contribution',
            value: (
                <span className={styles.orderConfirmationValue}>
                    <DollarValue
                        value={allocationsForInstrument.total_unvested_contribution}
                        currency={instrument.currency}
                    />
                </span>
            ),
        })

        return rows
    }

    return (
        <>
            <div className={spacing.spaceBelow8}>
                <OrderConfirmation
                    items={[
                        ...getContributionRows(),
                        {
                            description: 'Return',
                            value: (
                                <span className={styles.orderConfirmationValue}>
                                    <DollarValue
                                        value={currentReturn.unvested_detail.unvested_capital_gains}
                                        currency={instrument.currency}
                                    />
                                </span>
                            ),
                        },
                        {
                            description: 'Simple return',
                            value: (
                                <span className={styles.orderConfirmationValue}>
                                    <PercentValue value={currentReturn.unvested_detail.unvested_simple_return} />
                                </span>
                            ),
                        },
                    ]}
                    total={{
                        description: 'Total value',
                        value: <DollarValue value={currentReturn.unvested_detail.unvested_investment_value} />,
                    }}
                />
            </div>

            <h2 className={cn(styles.timeline, spacing.spaceAbove16)}>Timeline</h2>
            <SwitchField
                additionalClassName={spacing.spaceAbove4}
                dataTestId="switch--current-value"
                label="Show current dollar value"
                name="show current dollar value"
                onChange={() => dispatch(essActions.ToggleShowShareAllocationCurrentValue())}
                value={showCurrentDollarValue}
                isTouched={false}
            />

            {Object.entries(groupedUnvestedAllocationLines).map(([year, allocationLines]) => {
                return (
                    <React.Fragment key={year}>
                        <h3
                            data-testid={`heading--vesting-in-${year}`}
                            className={cn(styles.vestingInHeading, spacing.spaceAbove24)}
                        >
                            Vesting in {year}
                        </h3>
                        {allocationLines.map(line => {
                            return (
                                <UpcomingShareAllocationLine
                                    key={line.id}
                                    allocationLine={line}
                                    instrument={instrument}
                                    currency={instrument.currency}
                                    allocationGroupName={allocationLineGroupNames[line.id]}
                                    showCurrentDollarValue={showCurrentDollarValue}
                                />
                            )
                        })}
                    </React.Fragment>
                )
            })}
        </>
    )
}

interface UpcomingShareAllocationLineProps {
    allocationLine: ESSAllocationLine
    instrument: Instrument
    currency: Currency
    allocationGroupName: string
    showCurrentDollarValue: boolean
}

const UpcomingShareAllocationLine = React.forwardRef(
    (
        {
            allocationLine,
            instrument,
            currency,
            allocationGroupName,
            showCurrentDollarValue,
        }: UpcomingShareAllocationLineProps,
        ref: React.ForwardedRef<HTMLButtonElement>,
    ) => {
        const navigate = useNavigate()
        const profileUrl = useProfileUrl()

        const getValue = () => {
            if (showCurrentDollarValue) {
                return parseFloat(allocationLine.number_of_shares) * parseFloat(instrument.marketPrice)
            }
            return allocationLine.total_value
        }

        return (
            <button
                data-testid={`allocation--${convertToKebabCase(allocationGroupName.toLowerCase())}`}
                ref={ref}
                className={cn(styles.upcomingAllocationCard, spacing.spaceAbove8)}
                onClick={() => {
                    rudderTrack('ess_allocation', 'allocation_clicked', {
                        allocation_type: 'allocated',
                    })
                    navigate(
                        profileUrl('employee-share-scheme/allocation/:instrumentId/:allocationLineId', {
                            instrumentId: instrument.id,
                            allocationLineId: allocationLine.id,
                        }),
                    )
                }}
                type="button"
            >
                <div className={styles.iconAndTitle}>
                    <h4 className={cn(styles.title, spacing.spaceBelow8)}>{allocationGroupName} allocation</h4>
                    <ChevronRight className={styles.iconRight} size={12} />
                </div>
                <div className={styles.descriptionAndAmount}>
                    <p>Yours on {allocationLine.vesting_due_date.toFormat(dateFormatNoTime)}</p>
                    <p>
                        <DollarValue value={getValue()} currency={currency} decimalPlaces={2} />
                    </p>
                </div>
            </button>
        )
    },
)

export default UpcomingShareAllocations
