import {Button} from '@design-system/button'
import cn from 'classnames'
import React, {useEffect} from 'react'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {useAppSelector} from '~/store/hooks'
import {NEW_SRN_REQUIRED, NewSrnRequired} from '~/store/transfer/types'

const Intro: React.FunctionComponent = () => {
    const profileUrl = useProfileUrl()

    const direction = useAppSelector(({transfer}) => transfer.stagedTransferOrder?.direction)
    const reference = useAppSelector(({transfer}) => transfer.stagedTransferOrder?.reference)

    const navigate = useNavigate()
    useEffect(() => {
        if (!direction) {
            navigate(profileUrl('invest/transfer-shares'), {replace: true})
        }
    }, [])

    const issuerHoldingStatementContent = () => {
        return (
            <>
                <h2 className={cn(spacing.spaceBelow16, commonStyles.subHeading)}>Holding Statement</h2>
                <p className={spacing.spaceBelow20}>
                    A holding statement shows us you own the shares you want to transfer. It has your name, address and
                    a summary of your shares. This may come from either the broker or the registry you currently hold
                    the shares through.
                </p>
            </>
        )
    }

    const transferFormContent = () => {
        return (
            <>
                <h2 className={cn(spacing.spaceBelow16, commonStyles.subHeading)}>Transfer Form</h2>
                <p className={spacing.spaceBelow32}>
                    This is the form you’ll sign to authorise the share transfer. This form needs to be printed, signed
                    in pen, and uploaded.
                </p>
            </>
        )
    }

    const idContent = (ref: NewSrnRequired) => {
        return ref === NEW_SRN_REQUIRED ? (
            <p className={spacing.spaceBelow20}>
                We use this to match your signature to the one on your printed Transfer Form.
            </p>
        ) : (
            <p className={spacing.spaceBelow20}>
                This is so we can match the signature on your ID to the Issuer Sponsored Transfer Form (doc three, that
                you'll print and sign).
            </p>
        )
    }

    const srnPreamble = () => {
        return (
            <>
                <p className={spacing.spaceBelow16}>To transfer your shares, we need three documents.</p>
                <p className={spacing.spaceBelow16}>
                    Two you’ll provide, and one we’ll create and send you to print and sign. Sorry, we know! But these
                    docs help us verify your identity and, importantly, ensure you own the{' '}
                    {direction === 'in' ? 'shares you want to transfer' : 'SRN number you want to transfer to'}.
                </p>
                <p className={spacing.spaceBelow16}>
                    We’ve made it as simple (and secure) as we can. Plus, you can start the process now, save your
                    details and upload these docs later.
                </p>
                <p className={spacing.spaceBelow16}>Transfers normally take 15-30 business days.</p>
            </>
        )
    }

    const noSrnPreamble = () => {
        return (
            <p className={spacing.spaceBelow16}>
                Ok, we need two documents. A copy of your ID, and a Transfer Form. The Transfer Form we will create for
                you to print out and sign. Sorry, we know! But these docs help us verify your identity and make the
                process secure.
            </p>
        )
    }
    return (
        <>
            <Toolbar leftButton="back" dataTestId="transfers--intro" />
            <Page>
                <h1 className={cn(spacing.spaceBelow32, commonStyles.heading)}>A heads up before you start</h1>
                {reference === NEW_SRN_REQUIRED ? noSrnPreamble() : srnPreamble()}
                <h2 className={cn(spacing.spaceBelow16, commonStyles.subHeading)}>
                    Your ID (driver licence or passport)
                </h2>
                {idContent(reference as NewSrnRequired)}
                {reference !== NEW_SRN_REQUIRED && issuerHoldingStatementContent()}
                {transferFormContent()}
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--next"
                    onClick={() => navigate(profileUrl('invest/portfolio-transfer-shares/asx/terms-and-conditions'))}
                    label="Get me started!"
                />
            </ActionBar>
        </>
    )
}

export default Intro
