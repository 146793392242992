import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {hasForeignInstrument} from '~/global/utils/autoinvest-order/autoinvestOrder'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {Image} from '~/global/widgets/image/Image'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import commonStyles from '~/sections/invest/sections/auto-invest/Common.scss'
import riskLevelDark from '~/sections/invest/sections/auto-invest/assets/images/10-ups-and-downs-dark.svg'
import riskLevelLight from '~/sections/invest/sections/auto-invest/assets/images/10-ups-and-downs-light.svg'
import {isDIY} from '~/store/autoinvest/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/identity/actions'
import styles from './CompanyRiskWarning.scss'

export const CompanyRiskWarning: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const stagedOrder = useAppSelector(s => s.autoinvest.stagedOrder)
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)

    const isDIYOrder = stagedOrder && isDIY(stagedOrder.order)

    const handleClick = () => {
        dispatch(actions.MarkHasSeenFlag('autoinvest_companies_warning'))
        navigate(profileUrl('auto-invest/diy/how-much'), {replace: true})
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--company-risk-warning" leftButton="back" />
            <Page>
                <div className={styles.imageWrapper}>
                    <Image src={riskLevelLight} darkSrc={riskLevelDark} />
                </div>
                {isDIYOrder && hasForeignInstrument(stagedOrder, instrumentsById, jurisdiction) ? (
                    <>
                        <h1
                            className={cn([typographyOverrides['as-h1'], spacing.spaceBelow24])}
                            data-testid="foreign-heading"
                        >
                            Heads-up! Your order includes overseas investments
                        </h1>
                        <p data-testid="foreign-body">
                            When you include overseas companies or exchange-traded funds (ETFs) in your order:
                        </p>
                        <ul>
                            <li>
                                Transaction fees will be charged in the same currency as the investment—the exact amount
                                will show in your transaction history.
                            </li>
                            <li>
                                Currency exchange fees may be charged if money in your Wallet needs to be exchanged when
                                your order is placed.
                            </li>
                            <li>
                                You should regularly review whether your order is suitable for your objectives,
                                financial situation, or needs.
                            </li>
                        </ul>
                        <p>
                            Other fees may apply. For more info,{' '}
                            <a
                                target="_blank"
                                rel="noopener"
                                href={
                                    jurisdiction === 'au'
                                        ? 'https://sharesies.com.au/pricing'
                                        : 'https://www.sharesies.nz/pricing'
                                }
                            >
                                check out our website.
                            </a>
                        </p>
                    </>
                ) : (
                    <>
                        <h1
                            className={cn([typographyOverrides['as-h1'], spacing.spaceBelow24])}
                            data-testid="home-heading"
                        >
                            Heads-up! Your auto-invest order includes companies
                        </h1>
                        <p data-testid="home-body" className={commonStyles.description}>
                            Compared to investing in exchange-traded funds (ETFs), investing in companies can carry more
                            risk.
                        </p>
                        <p className={commonStyles.description}>
                            Make sure to do your due diligence and regularly review whether the investment is still
                            suitable for your objectives, financial situation or needs.
                        </p>
                    </>
                )}
            </Page>
            <ActionBar className={commonStyles.footer}>
                <Button label="Got it" onClick={handleClick} dataTestId="button--risk-warning-message" />
            </ActionBar>
        </>
    )
}

export default CompanyRiskWarning
