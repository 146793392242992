import {DateTime} from 'luxon'
import {titleCase} from '~/global/utils/convert-to-title-case/convertToTitleCase'

export type Mode =
    | 'licence'
    | 'passport'
    | 'overseasPassport'
    | 'birthCertificate'
    | 'other'
    | 'fullNZBirthCert'
    | 'NZCitizenCert'
    | 'fullOverseasBirthCert'
    | 'engTransOverseasCitizenCert'
    | 'medicareCard'
    | 'NZFirearmLicence'
    | undefined

const typeModes: Record<string, Mode> = {
    NZ_DRIVER_LICENCE: 'licence',
    AU_DRIVER_LICENCE: 'licence',
    PASSPORT: 'passport',
    BIRTH_CERTIFICATE: 'birthCertificate',
    MEDICARE_CARD: 'medicareCard',
}

/**
 * Given an array of documents from Verify, determine which mode the sign up identity form should be in
 */
export const getFormModeFromIdentityVerificationDocuments = (identityVerificationDocuments?: any[]) => {
    if (!identityVerificationDocuments || !identityVerificationDocuments.length) {
        return 'other'
    }

    // Note that we currently only look at the first document. When/if we have investors take photos of more than one
    // document then we'll need to review this
    const documentType = identityVerificationDocuments[0].id_type

    return typeModes[documentType] ? typeModes[documentType] : 'other'
}

export const formatDateForVerifi = (idDate: string) => {
    return DateTime.fromFormat(idDate, 'yyyy-MM-dd')
}

export const addDocumentDetailsToForm = (form: any, doc: Record<string, string>, mode?: Mode) => {
    if (mode === 'licence') {
        return {
            ...form,
            givenName: titleCase(doc.given_name),
            middleName: titleCase(doc.middle_name),
            familyName: titleCase(doc.family_name),
            licenceNumber: doc.number,
            licenceVersion: doc.version,
            dateOfBirth: doc.date_of_birth ? formatDateForVerifi(doc.date_of_birth) : undefined,
        }
    } else if (mode === 'passport') {
        return {
            ...form,
            givenName: titleCase(doc.given_name),
            middleName: titleCase(doc.middle_name),
            familyName: titleCase(doc.family_name),
            passportNumber: doc.number,
            dateOfBirth: doc.date_of_birth ? formatDateForVerifi(doc.date_of_birth) : undefined,
            dateOfExpiry: doc.date_of_expiry ? formatDateForVerifi(doc.date_of_expiry) : undefined,
        }
    } else {
        return form
    }
}
