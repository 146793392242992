import {AutoInvest, DividendReinvest, RecurringTopUp, RoundUps, AiScout} from '@design-system/icon'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import useAutoinvestDividends from '~/global/utils/use-autoinvest-dividends/useAutoinvestDividends'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ListMenuExpanded} from '~/global/widgets/list-menu-expanded/ListMenuExpanded'
import styles from '~/sections/explore/Explore.scss'
import MonthlyPlansSVG from '~/sections/explore/widgets/power-up/assets/images/monthly-plans.inline.svg'
import {useAppSelector} from '~/store/hooks'

const PowerupPortfolio: React.FunctionComponent = (): JSX.Element | null => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const owner = useProfileOwner()
    const hasAiSearchFlag = useAppSelector(s => s.identity.flags.ai_search)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const dividendReinvest = useAppSelector(s => 'customer_dividend_reinvest' in s.identity.flags)
    const hasPlan = useAppSelector(s => s.plan.currentPlanLoaded)
    const {preferences, loadingState} = useAutoinvestDividends()
    const usingDividendReinvest =
        (preferences.primary === true || preferences.funds.length > 0) && loadingState !== 'loading'

    return (
        <div className={spacing.spaceBelow48} data-testid="powerups--wrapper">
            <h2>Power up your Portfolio</h2>
            {hasAiSearchFlag && (
                <ListMenuExpanded
                    title="AI Search"
                    description="Discover investments and gain insight using our AI-powered search tool."
                    dataTestId="powerups--ai-search"
                    icon={<AiScout />}
                    onClick={() => navigate(profileUrl('invest/ai-search/intro'))}
                />
            )}
            {jurisdiction === 'au' && (
                <>
                    <ListMenuExpanded
                        onClick={() => navigate(profileUrl('explore/roundups'))}
                        dataTestId="powerups--roundups"
                        icon={<RoundUps />}
                        title="Round-ups"
                        description="Automatically top up your Wallet using the leftover cents from your spending."
                    />

                    <ListMenuExpanded
                        onClick={() => navigate(profileUrl('explore/linked-bank-topup'))}
                        dataTestId="powerups--linkedbank-topups"
                        icon={<RecurringTopUp className={styles.recurringIconOverrides} />}
                        title="Linked bank top-ups"
                        description="Seamlessly transfer money from your linked bank to your Wallet with a one-off top-up, or set up a recurring top-up."
                    />
                </>
            )}
            {!hasPlan && owner.owner_type !== 'ORGANISATION' && (
                <ListMenuExpanded
                    onClick={() => navigate(profileUrl('settings/plan'))}
                    dataTestId="powerups--monthly-plans"
                    icon={<MonthlyPlansSVG />}
                    title="Monthly plans"
                    description="Pick a plan to suit your investing habits and cover transaction fees."
                />
            )}
            <ListMenuExpanded
                onClick={() => navigate(profileUrl('auto-invest'))}
                dataTestId="powerups--autoinvest"
                icon={<AutoInvest />}
                title="Auto-invest"
                description="Choose what to invest in and when, and we'll place the orders for you."
            />
            {dividendReinvest && !usingDividendReinvest && (
                <ListMenuExpanded
                    onClick={() => navigate(profileUrl('invest/dividends'))}
                    dataTestId="powerups--dividend-reinvest"
                    icon={<DividendReinvest />}
                    title="Auto-invest dividends"
                    description="Automatically invest dividends back into a company or fund."
                />
            )}
        </div>
    )
}

export default PowerupPortfolio
