import React from 'react'
import {Navigate} from 'react-router'
import {useLinkedBankPaymentsUrl} from '~/sections/OLD_wallet/sections/linked-bank-payments/hooks/useLinkedBankPaymentsUrl'
import {useAppSelector} from '~/store/hooks'

/**
 * Send a user down the setup flow or bypass that and go to top-up selection type.
 */
export const RedirectBankLinked: React.FunctionComponent = () => {
    const isBankLinked = useAppSelector(s => s.bankLinking.isBankLinked)
    const relativeUrlFor = useLinkedBankPaymentsUrl()

    const next = isBankLinked ? relativeUrlFor('select-top-up-type') : relativeUrlFor('intro')
    return <Navigate to={next} replace />
}
