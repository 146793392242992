import lottie from 'lottie-web/build/player/lottie_light'
import React, {useEffect, useState} from 'react'
import {assertNever} from '~/global/utils/assert-never/assertNever'

export const getAnimationData = async (
    type: 'regularInvestor' | 'firstTimeInvestor' | 'autoInvestor' | 'topupGoal' | 'donation' | 'confirmation',
): Promise<object> => {
    switch (type) {
        case 'regularInvestor': {
            return import('~/global/widgets/lottie-animation/assets/animations/generic/data.json')
        }
        case 'firstTimeInvestor': {
            return import('~/global/widgets/lottie-animation/assets/animations/generic/data.json')
        }
        case 'autoInvestor': {
            return import('~/global/widgets/lottie-animation/assets/animations/generic/data.json')
        }
        //top up goal setup shtuff
        case 'topupGoal': {
            return import('~/global/widgets/lottie-animation/assets/animations/generic/data.json')
        }
        case 'donation': {
            return import('~/global/widgets/lottie-animation/assets/animations/generic/data.json')
        }
        case 'confirmation': {
            return import('~/global/widgets/lottie-animation/assets/animations/confirmation/data.json')
        }
        default:
            assertNever(type)
    }
    return {}
}

interface AnimationProps {
    animationData: object
    className?: string
    loop?: boolean
    autoplay?: boolean
    playing?: boolean
}

const Animation: React.FunctionComponent<AnimationProps> = ({animationData, className, loop, autoplay, playing}) => {
    const container = React.useRef<HTMLDivElement | null>(null)
    const [lottieInstance, setLottieInstance] = useState<any>()

    useEffect(() => {
        if (!container.current) {
            return
        }

        setLottieInstance(
            lottie.loadAnimation({
                container: container.current,
                renderer: 'svg',
                loop: loop ?? true,
                autoplay: autoplay ?? true,
                animationData,
                rendererSettings: {
                    progressiveLoad: false,
                },
            }),
        )

        return () => {
            lottieInstance?.destroy()
        }
    }, [container, animationData])

    useEffect(() => {
        if (playing) {
            lottieInstance?.play()
        } else if (playing === false) {
            lottieInstance?.goToAndStop(0)
        }
    }, [lottieInstance, playing])

    return <div ref={container} className={className} />
}

export default Animation
