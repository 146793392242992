import {AlertCircle} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {Navigate} from 'react-router'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import tooltipStyles from '~/global/scss/reused-styles/tooltip.scss'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {Currency, countryAbbreviationByCurrency} from '~/global/utils/currency-details/currencyDetails'
import {isWalletBalanceEstimated} from '~/global/utils/is-wallet-balance-estimated/isWalletBalanceEstimated'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AccountVerificationRequired from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import EstimatedWalletBalance from '~/global/widgets/estimated-wallet-balance/EstimatedWalletBalance'
import {ListMenuGroup, ListMenuItem} from '~/global/widgets/list-menu/ListMenu'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import TabControls from '~/global/widgets/tab-controls/TabControls'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {Tooltip} from '~/global/widgets/tooltip/Tooltip'
import TopUpForPlanAlert from '~/global/widgets/top-up-for-plan-alert/TopUpForPlanAlert'
import {useNavigate} from '~/migrate-react-router'
import WalletBreakdown from '~/sections/OLD_wallet/widgets/wallet-breakdown/WalletBreakdown'
import accountingActions from '~/store/accounting/actions'
import {Transaction} from '~/store/accounting/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {getAvailableCurrencies} from '~/store/identity/selectors'
import instrumentActions from '~/store/instrument/actions'
import {State} from '~/store/instrument/types'
import {BankLinkingAlert} from './widgets/bank-linking-alert/BankLinkingAlert'
import {RoundupStatus} from './widgets/roundup-status/RoundupStatus'
import WalletTransactionHistory from './widgets/transaction-history/WalletTransactionHistory'
import WalletUpcoming from './widgets/upcoming-transactions/WalletUpcoming'
import styles from './Wallet.scss'

export const getUnloadedInstrumentIdsFromTransactions = (
    transactions: Transaction[],
    instrumentsById: State['instrumentsById'],
): string[] => {
    const unloadedInstrumentIds: string[] = []

    for (const t of transactions) {
        if (t.fund_id && !instrumentsById[t.fund_id] && !unloadedInstrumentIds.includes(t.fund_id)) {
            unloadedInstrumentIds.push(t.fund_id)
        }
    }

    return unloadedInstrumentIds
}

const WithdrawalLink: React.FunctionComponent<{accountRestricted: boolean}> = ({accountRestricted}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const profile = useProfile()
    const walletPortfolio = profile.portfolios.find(p => p.product === 'WALLET')!

    return (
        <ListMenuItem
            label="Withdraw"
            onClick={() => {
                navigate(profileUrl('wallet/:portfolioId/withdrawal', {portfolioId: walletPortfolio.id}))
            }}
            disabled={accountRestricted}
        />
    )
}

export const Wallet: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const profile = useProfile()

    const [historyTab, setHistoryTab] = React.useState<'history' | 'upcoming'>('history')

    // data from store
    const accountRestricted = useAppSelector(s => s.identity.user!.account_restricted)
    const availableCurrencies = useAppSelector(s => getAvailableCurrencies(s))
    const exchangeRates = useAppSelector(s => s.accounting.exchangeRates)
    const hasLockedNavDrawer = useAppSelector(s => s.nav.drawerVisibilityLocked)
    const hasSeenIntro = useAppSelector(s => s.identity.user!.has_seen.exchange_investor)
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)
    const instrumentMetadataInitialised = useAppSelector(s => s.instrument.isMetadataInitialised)
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const pageLoadingState = useAppSelector(s => s.accounting.walletPageLoadingState)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)
    const saveAccounts = useAppSelector(s => s.identity.saveAccounts)
    const hasSaveAccount = Object.keys(saveAccounts).length > 0
    const showAuCurrency = useAppSelector(s => s.identity.user!.has_seen.show_au_currency)
    const topupGoal = useAppSelector(s => s.accounting.topupGoal)
    const transactions = useAppSelector(s => s.accounting.walletTransactions)
    const usEquitiesEnabled = useAppSelector(s => s.identity.user!.us_equities_enabled)
    const viewingOlderTransactions = useAppSelector(s => !!s.accounting.walletTransactionsViewingOlder)
    const walletBalances = useAppSelector(s => s.identity.user!.wallet_balances)

    const isPageLoading = pageLoadingState !== 'ready'
    const walletsNegative: string[] = []
    const activeWallets: string[] = [homeCurrency]

    Object.keys(countryAbbreviationByCurrency).forEach(currency => {
        if (currency in walletBalances && Number(walletBalances[currency]) < 0) {
            walletsNegative.push(currency)
        }

        if ((currency in walletBalances && Number(walletBalances[currency]) <= 0) || currency === homeCurrency) {
            return
        }

        activeWallets.push(currency)
    })

    // BEGIN useEffects ------------------------------------------------------------------------------------------------

    React.useEffect(() => {
        if (pageLoadingState === 'uninitialised') {
            dispatch(accountingActions.init())
        }

        if (viewingOlderTransactions) {
            dispatch(accountingActions.SetViewingOlderTransactions(false))
        }

        return () => {
            // if we got older transactions, revert back to just the first 50
            dispatch(accountingActions.TruncateTransactions())
        }
    }, [])

    React.useEffect(() => {
        // if the balance(s) updated, we presumably got new transactions, so update our list
        if (!viewingOlderTransactions) {
            dispatch(accountingActions.FetchNewTransactions())
        }
        // if there is a goal, and wallet balance changes, the goal tile should be updated
        if (topupGoal) {
            dispatch(accountingActions.FetchCurrentTopupGoal())
        }
    }, [walletBalances])

    React.useEffect(() => {
        const unloadedInstrumentIds = getUnloadedInstrumentIdsFromTransactions(transactions, instrumentsById)

        if (instrumentMetadataInitialised && unloadedInstrumentIds.length) {
            dispatch(instrumentActions.getInstrumentsByIds(unloadedInstrumentIds))
        }
    }, [transactions, instrumentMetadataInitialised])

    // END useEffects ---------------------------------------------------------------------------------------------------

    if (!profile.legacy_profile_type && profile.portfolios.find(p => p.product === 'WALLET')) {
        // For non-legacy profiles we should redirect to the correct wallet.
        return (
            <Navigate
                replace
                to={profileUrl('wallet/:portfolioId', {
                    portfolioId: profile.portfolios.find(p => p.product === 'WALLET')!.id,
                })}
            />
        )
    }

    // BEGIN helper functions -------------------------------------------------------------------------------------------

    const findWalletWithMostMoney = () =>
        Object.keys(walletBalances).reduce<Currency>(
            (previous, key) =>
                Number(walletBalances[previous]) >= Number(walletBalances[key]) ? previous : (key as Currency),
            homeCurrency,
        )

    const shouldShowWalletBreakdown = () => activeWallets.length > 1

    // END helper functions ---------------------------------------------------------------------------------------------

    if (isPageLoading) {
        return <Loading isPineapple />
    }

    return (
        <>
            <Toolbar title="Wallet" dataTestId="toolbar--wallet" leftButton={hasLockedNavDrawer ? undefined : 'menu'} />
            <Page overrideDefaultTopPadding="none">
                <div className={styles.walletAlerts}>
                    <AccountVerificationRequired />
                    <BankLinkingAlert locationContext="wallet-general" wrapperClassName={spacing.spaceBelow16} />
                    <TopUpForPlanAlert />
                </div>

                <div className={styles.walletHeaderWrapper}>
                    <div className={styles.walletHeader}>
                        <span className={styles.bigValue}>
                            <EstimatedWalletBalance />
                        </span>
                        <div className={styles.message}>
                            {isWalletBalanceEstimated(walletBalances, homeCurrency) ? (
                                <>
                                    <Tooltip>
                                        <span className={tooltipStyles.label}>Estimated</span>
                                        <div className={tooltipStyles.tooltip}>
                                            <p>Estimate based on the most recent exchange rate</p>
                                        </div>
                                    </Tooltip>{' '}
                                    available to invest
                                </>
                            ) : (
                                <>Available to invest</>
                            )}
                        </div>
                    </div>
                    {shouldShowWalletBreakdown() && (
                        <WalletBreakdown
                            homeCurrency={homeCurrency}
                            walletBalances={walletBalances}
                            exchangeRates={exchangeRates}
                            usEquitiesEnabled={usEquitiesEnabled}
                            showAuCurrency={showAuCurrency}
                        />
                    )}

                    {walletsNegative.length === 1 && walletsNegative.includes('nzd') && (
                        <div className={cn(styles.negativeWallet, styles.usdWalletPositive)}>
                            <div>
                                <AlertCircle />
                            </div>
                            {homeCurrency === 'nzd' ? (
                                <div>
                                    {isDependent ? `${preferredName}’s` : 'Your'} NZD balance is currently negative. To
                                    bring it back into the positive, top up {isDependent ? 'their' : 'your'} Wallet or
                                    exchange money from another currency.
                                </div>
                            ) : (
                                <div>
                                    {isDependent ? `${preferredName}’s` : 'Your'} NZD balance is currently negative. To
                                    bring it back into the positive, exchange money from another currency.
                                </div>
                            )}
                        </div>
                    )}

                    {walletsNegative.length === 1 && walletsNegative.includes('usd') && (
                        <div className={styles.negativeWallet}>
                            <div>
                                <AlertCircle />
                            </div>
                            <div>
                                {isDependent ? `${preferredName}’s` : 'Your'} USD balance is currently negative. To
                                bring it back into the positive, exchange money from another currency.
                            </div>
                        </div>
                    )}

                    {walletsNegative.length === 1 && walletsNegative.includes('aud') && (
                        <div className={styles.negativeWallet}>
                            <div>
                                <AlertCircle />
                            </div>
                            {homeCurrency === 'aud' ? (
                                <div>
                                    {isDependent ? `${preferredName}’s` : 'Your'} AUD balance is currently negative. To
                                    bring it back into the positive, top up {isDependent ? 'their' : 'your'} Wallet or
                                    exchange money from another currency.
                                </div>
                            ) : (
                                <div>
                                    {isDependent ? `${preferredName}’s` : 'Your'} AUD balance is currently negative. To
                                    bring it back into the positive, exchange money from another currency.
                                </div>
                            )}
                        </div>
                    )}

                    {walletsNegative.length > 1 && (
                        <div className={styles.negativeWallet}>
                            <div>
                                <AlertCircle />
                            </div>
                            <div>
                                {isDependent ? `${preferredName}’s` : 'Your'}{' '}
                                {walletsNegative.map(currency => currency.toUpperCase()).join(' and ')} balances are
                                currently negative. To bring them back into the positive, top up{' '}
                                {isDependent ? 'their' : 'your'} Wallet, then exchange money.
                            </div>
                        </div>
                    )}
                </div>
                <RoundupStatus withManageLink locationContext="wallet-roundups" />
                <ListMenuGroup className={spacing.spaceBelow32}>
                    {availableCurrencies.length > 1 && (
                        <ListMenuItem
                            label="Exchange money"
                            onClick={() => {
                                dispatch(
                                    accountingActions.SetStagedExchangeOrder({
                                        sourceCurrency: findWalletWithMostMoney(),
                                        targetCurrency:
                                            findWalletWithMostMoney() === homeCurrency
                                                ? availableCurrencies.find(c => c !== homeCurrency)! // We could do with a better way of choosing which currency to exchange to
                                                : homeCurrency,
                                        buyOrSell: 'sell',
                                    }),
                                )
                                rudderTrack('exchange_money', 'page_view')
                                navigate(
                                    hasSeenIntro
                                        ? profileUrl('wallet/exchange-money')
                                        : profileUrl('wallet/exchange-money/intro'),
                                )
                            }}
                            disabled={accountRestricted}
                        />
                    )}
                    <ListMenuItem
                        label="Top up your Wallet"
                        onClick={() => {
                            navigate(profileUrl('wallet/topup'))
                        }}
                        disabled={accountRestricted}
                    />
                    <ListMenuItem
                        label="Redeem a Gift"
                        onClick={() => {
                            rudderTrack('topup', 'redeem_gift_selected')
                            navigate(profileUrl('gifts/redeem-gift'))
                        }}
                        disabled={accountRestricted}
                    />
                    {!isDependent && !topupGoal && (
                        <ListMenuItem
                            label="Set a money goal"
                            onClick={() => navigate(profileUrl('wallet/money-goal-intro'))}
                        />
                    )}
                    {!isDependent && topupGoal && (
                        <ListMenuItem
                            label="View my money goal"
                            onClick={() => navigate(profileUrl('wallet/money-goal'))}
                        />
                    )}
                    {hasSaveAccount && (
                        <ListMenuItem
                            label="Transfer money"
                            onClick={() => {
                                navigate(profileUrl('wallet/transfer'))
                            }}
                            disabled={accountRestricted}
                        />
                    )}
                    <WithdrawalLink accountRestricted={accountRestricted} />
                </ListMenuGroup>
                <TabControls
                    tabs={[
                        {
                            label: 'History',
                            value: 'history',
                        },
                        {
                            label: 'Upcoming',
                            value: 'upcoming',
                        },
                    ]}
                    currentTab={historyTab}
                    onChangeTab={setHistoryTab}
                />
                {historyTab === 'history' ? <WalletTransactionHistory /> : <WalletUpcoming />}
            </Page>
        </>
    )
}
