import {Deposit, RecurringTopUp, SpareChange, Success, Withdraw} from '@design-system/icon'
import React from 'react'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import TransactionRow from '~/global/widgets/transaction-row/TransactionRow'
import {transactionTitle} from '~/sections/save/sections/activity/utils/transaction-title/transactionTitle'
import {Transaction} from '~/store/save/types'

export const saveIconMapping = (txn: Transaction) => {
    switch (txn.transaction_type) {
        case 'DEPOSIT':
            return <Deposit />
        case 'WITHDRAW':
            return <Withdraw />
        case 'TAX':
            return <Success />
        case 'INTEREST':
            return <SpareChange />
        case 'RECURRING':
            return <RecurringTopUp />
        default:
            return <Deposit />
    }
}

interface TransactionLineProps {
    currency: string
    txn: Transaction
    portfolioId: string
}

const TransactionLine: React.FunctionComponent<TransactionLineProps> = ({currency, txn, portfolioId}) => {
    const profileUrl = useProfileUrl()

    return (
        <TransactionRow
            amount={txn.amount}
            currency={currency}
            date={txn.timestamp}
            icon={saveIconMapping(txn)}
            title={transactionTitle(txn)}
            url={profileUrl('save/transaction/:portfolioId/:transactionId/:transactionKey', {
                portfolioId,
                transactionId: txn.transaction_id.toString(),
                transactionKey: txn.key,
            })}
        />
    )
}

export default TransactionLine
