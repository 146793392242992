import {Button} from '@design-system/button'
import cn from 'classnames'
import {useSetAtom} from 'jotai'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import HelpEmail from '~/global/widgets/help-email/HelpEmail'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/protect/sections/insure/sections/car/pages/create-car-estimate/CreateEstimateError.scss'
import {createEstimateDetailsAtom} from '~/sections/protect/sections/insure/state'

export type CoveCreateEstimateError =
    | 'Cannot insure car'
    | 'Cannot find car'
    | 'Cannot find licence plate'
    | 'Commercial cars'
    | 'Cove server unavailable'
    | 'Driver under 21'
    | 'Existing customer'
    | 'No wof or rego'
    | 'NZTA server unavailable'
    | 'Unknown error'

export const CreateEstimateError: React.FunctionComponent<{
    error: CoveCreateEstimateError
}> = ({error}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const setCreateEstimateDetails = useSetAtom(createEstimateDetailsAtom)

    const handleExit = () => {
        setCreateEstimateDetails(undefined)
        navigate(profileUrl(''))
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--create-estimate-error" leftButton="menu" />

            <Page>
                {error === 'Cannot find car' && (
                    <>
                        <h1 className={cn(styles.heading, spacing.spaceBelow24)}>
                            Sorry, we can’t insure your car at the moment
                        </h1>
                        <p className={spacing.spaceBelow40}>
                            Please visit{' '}
                            <a href="https://coveinsurance.co.nz" target="_blank" rel="noreferrer">
                                coveinsurance.co.nz
                            </a>{' '}
                            to manually select your vehicle.
                        </p>
                    </>
                )}

                {error === 'Cannot find licence plate' && (
                    <>
                        <h1 className={cn(styles.heading, spacing.spaceBelow24)}>
                            Sorry, we can’t find that licence plate
                        </h1>

                        <p className={spacing.spaceBelow48}>
                            Please try again later, or if you continue to get this error, contact us at <HelpEmail />.
                        </p>
                    </>
                )}

                {error === 'Cannot insure car' && (
                    <>
                        <h1 className={cn(styles.heading, spacing.spaceBelow24)}>
                            Sorry, we can’t insure your car at the moment
                        </h1>
                        <p className={styles.subheading}>Currently, we’re not able to insure:</p>
                        <ul>
                            <li>excluded models such as Toyota Aqua and electric vehicles (EVs) from Tesla and BYD</li>
                            <li>cars from before 1980</li>
                            <li>3-seater vans</li>
                            <li>performance vehicles</li>
                            <li>motorcyles</li>
                            <li>trucks</li>
                        </ul>
                        <p className={spacing.spaceBelow40}>
                            We’ll be looking to provide insurance for more types of cars in the future. If you like, you
                            can let us know what type of car you’re trying to insure by{' '}
                            <a href={profileUrl('settings/request-feature')} target="_blank" rel="noreferrer">
                                sending us feedback
                            </a>
                            .
                        </p>
                    </>
                )}

                {error === 'Commercial cars' && (
                    <>
                        <h1 className={cn(styles.heading, spacing.spaceBelow24)}>
                            Sorry, we can’t insure your car at the moment
                        </h1>
                        <p className={spacing.spaceBelow24}>
                            Currently, we’re not able to insure cars used for commercial purposes or registered with a
                            certificate of fitness (CoF).
                        </p>
                        <p className={spacing.spaceBelow48}>
                            If you need further help please contact us at <HelpEmail />.
                        </p>
                    </>
                )}

                {error === 'Cove server unavailable' && (
                    <>
                        <h1 className={cn(styles.heading, spacing.spaceBelow24)}>
                            Sorry! Looks like the Cove website is not available at the moment
                        </h1>

                        <p className={spacing.spaceBelow48}>
                            Please try again later, or if you continue to get this error, contact us at <HelpEmail />.
                        </p>
                    </>
                )}

                {error === 'Driver under 21' && (
                    <>
                        <h1 className={cn(styles.heading, spacing.spaceBelow24)}>
                            Sorry, we can’t insure you at the moment
                        </h1>

                        <p className={spacing.spaceBelow24}>
                            Currently, we’re not able to insure anyone under the age of 21 – this includes non regular
                            drivers of a vehicle that are not named on a policy.
                        </p>
                        <p className={spacing.spaceBelow48}>
                            This may change in the future. If you like, you can let us know what age driver you’re
                            trying to insure by{' '}
                            <a href={profileUrl('settings/request-feature')} target="_blank" rel="noreferrer">
                                sending us feedback
                            </a>
                            .
                        </p>
                    </>
                )}

                {error === 'Existing customer' && (
                    <>
                        <h1 className={cn(styles.heading, spacing.spaceBelow24)}>
                            It looks like you already have a Cove account
                        </h1>

                        <p className={spacing.spaceBelow24}>
                            If you have an existing Cove car policy, and would like to see it in your Sharesies app, it
                            can be connected as long as you use the same email address.
                        </p>
                        <p className={spacing.spaceBelow40}>
                            For now, this is a manual process, so to request this please get in touch with Cove at{' '}
                            <a href="mailto: support@coveinsurance.co.nz" target="_blank" rel="noreferrer">
                                support@coveinsurance.co.nz
                            </a>
                        </p>
                    </>
                )}

                {error === 'No wof or rego' && (
                    <>
                        <h1 className={cn(styles.heading, spacing.spaceBelow24)}>
                            Sorry, we can’t insure your car at the moment
                        </h1>
                        <p className={spacing.spaceBelow24}>
                            Currently, we’re not able to insure cars with an expired warrant of fitness (WoF) or
                            registration.
                        </p>
                        <p className={spacing.spaceBelow48}>
                            If you need further help please contact us at <HelpEmail />.
                        </p>
                    </>
                )}

                {error === 'NZTA server unavailable' && (
                    <>
                        <h1 className={cn(styles.heading, spacing.spaceBelow24)}>
                            Sorry! Looks like the NZTA website is not available at the moment
                        </h1>

                        <p className={spacing.spaceBelow48}>
                            Cove uses their site to check your car details. Please try again later, or if you continue
                            to get this error, contact us at <HelpEmail />.
                        </p>
                    </>
                )}

                {error === 'Unknown error' && (
                    <>
                        <h1 className={cn(styles.heading, spacing.spaceBelow24)}>
                            There was an error creating your estimate
                        </h1>

                        <p className={spacing.spaceBelow112}>
                            Sorry about that! Please try again later, or if you continue to get this error, contact us
                            at <HelpEmail />.
                        </p>
                    </>
                )}

                <Button
                    dataTestId="button--back-portfolio"
                    label="Back to portfolio"
                    type="secondary"
                    onClick={handleExit}
                />
            </Page>
        </>
    )
}
