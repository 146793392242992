// import {Card} from '@braze/web-sdk'
import {ClassicCard} from '@braze/web-sdk'
import React from 'react'
import {Link} from 'react-router-dom'
import {isInternalLink, normalizeUrl} from '~/global/utils/normalize-url/normalizeUrl'
import {EXPLORE_BRAZE_CARD_TYPES, ExploreContentCardCommon, exploreContentCardsRollbarError} from '../common'
import styles from './TwoImageGroup.scss'

interface TwoImageItemProps extends ExploreContentCardCommon {
    type: typeof EXPLORE_BRAZE_CARD_TYPES.category.exploreTwoImageItem
    title: string
    description: string
    url: string
    imageUrl: string
    groupId: string
}

interface TwoImageGroupProps extends ExploreContentCardCommon {
    type: typeof EXPLORE_BRAZE_CARD_TYPES.category.exploreTwoImageGroup
    groupId: string
    items: TwoImageItemProps[]
    contentLabel: string
}

export function groupTwoImageCard(cards: ClassicCard[]): ClassicCard[] {
    // Filter out all explore_two_image_item cards
    const exploreTwoImageChildCards = cards.filter(
        card => card.extras?.category === EXPLORE_BRAZE_CARD_TYPES.category.exploreTwoImageItem,
    )

    return cards.reduce((accumulator: ClassicCard[], card) => {
        if (card.extras?.category === EXPLORE_BRAZE_CARD_TYPES.category.exploreTwoImageItem) {
            // Remove any exploreTwoImageItem cards
            return accumulator
        }

        if (card.extras?.category === EXPLORE_BRAZE_CARD_TYPES.category.exploreTwoImageGroup) {
            const cardWithChildren: ClassicCard = card

            // Assign childItems to extras, assuming this doesn't get blitzed by something coming out of Braze
            card.extras.childItems = exploreTwoImageChildCards.filter(
                childCard => childCard.extras?.group_id === card.extras?.group_id,
            )

            return [...accumulator, cardWithChildren]
        }

        return [...accumulator, card]
    }, [])
}

export function brazeCardToTwoImageItem({
    title,
    description,
    imageUrl,
    url,
    extras = {},
    id,
}: ClassicCard): TwoImageItemProps | null {
    const {group_id, priority} = extras

    if (!url || !imageUrl) {
        exploreContentCardsRollbarError({
            exploreCardType: 'exploreTwoImageItem',
            id,
            missingValues: {url, imageUrl},
        })
        return null
    }

    return {
        type: EXPLORE_BRAZE_CARD_TYPES.category.exploreTwoImageItem,
        title,
        description,
        url,
        imageUrl,
        groupId: group_id,
        priority,
    }
}

export function brazeCardToTwoImageGroup({extras = {}}: ClassicCard): TwoImageGroupProps {
    const {priority, group_id, explore_content_label, childItems = []} = extras

    const items = (childItems as ClassicCard[]).map(brazeCardToTwoImageItem)
    // Filter out any null TwoImageItemProps and sort by priority
    const sortedItems = (items.filter(childCard => childCard !== null) as TwoImageItemProps[]).sort(
        (a, b) => (b.priority || 0) - (a.priority || 0),
    )

    return {
        type: EXPLORE_BRAZE_CARD_TYPES.category.exploreTwoImageGroup,
        groupId: group_id,
        items: sortedItems,
        priority,
        contentLabel: explore_content_label,
    }
}

export const TwoImageGroup = ({items, contentLabel, priority = 0}: TwoImageGroupProps) => {
    if (!items) {
        return null
    }

    return (
        <div className={styles.twoImageGroup} data-priority={priority}>
            <h2>{contentLabel}</h2>

            <ul className={styles.items}>
                {items.map(({title, description, url, imageUrl}) => {
                    // Remove the current origin e.g. "https://app.sharesies.com" so react-router link
                    // component works and doesn't open the link in a new tab, and profile is set.
                    const safeUrl = normalizeUrl(url)
                    const isExternalLink = !isInternalLink(safeUrl)

                    if (isExternalLink) {
                        return (
                            <a
                                key={url}
                                href={safeUrl}
                                className={styles.linkItem}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <>
                                    <img className={styles.image} src={imageUrl} alt="" />

                                    <div className={styles.content}>
                                        <h3>{title}</h3>
                                        <p>{description}</p>
                                    </div>
                                </>
                            </a>
                        )
                    }

                    return (
                        <Link key={url} to={safeUrl} className={styles.linkItem}>
                            <>
                                <img className={styles.image} src={imageUrl} alt="" />

                                <div className={styles.content}>
                                    <h3>{title}</h3>
                                    <p>{description}</p>
                                </div>
                            </>
                        </Link>
                    )
                })}
            </ul>
        </div>
    )
}
