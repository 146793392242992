import React from 'react'
import {Navigate, useNavigate} from 'react-router'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ExchangeHours} from '~/global/widgets/exchange-hours/ExchangeHours'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useDistillInstrumentWithCurrency} from '~/sections/moose/data/distill'
import {usePortfolioSettings} from '~/sections/moose/data/retail'
import {MooseOrderPathParams} from '~/sections/moose/routes'
import {isUnsupportedInstrument} from '~/sections/moose/sections/order/utils/is-unsupported-instrument/isUnsupportedInstrument'
import {AddFinForm} from '~/sections/moose/widgets/add-fin-form/AddFinForm'
import styles from './CheckFinSet.scss'

export const CheckFinSet: React.FunctionComponent<MooseOrderPathParams> = ({
    portfolioId,
    urlSlug,
}: MooseOrderPathParams) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const portfolioSettings = usePortfolioSettings(portfolioId)
    const instrument = useDistillInstrumentWithCurrency(urlSlug)

    if (isUnsupportedInstrument(urlSlug)) {
        // Redirect back to the portfolio because the instrument is missing or not supported
        return <Navigate to={profileUrl('fonterra/portfolios/:portfolioId', {portfolioId})} replace />
    }

    if (portfolioSettings.default_csn.is_fin_set) {
        // If the fin is already set, then there is no need to be on this page, so advance
        return (
            <Navigate
                to={profileUrl('fonterra/portfolios/:portfolioId/invest/:urlSlug/sell/limit-order', {
                    portfolioId,
                    urlSlug,
                })}
                replace
            />
        )
    }

    const onSuccess = async () => {
        navigate(
            profileUrl('fonterra/portfolios/:portfolioId/invest/:urlSlug/sell/limit-order', {
                portfolioId,
                urlSlug: instrument.urlSlug,
            }),
            {replace: true},
        )
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--check-fin-set"
                leftButton="back"
                title="What is your FIN?"
                rightSlot={
                    <div className={styles.exchangeHours}>
                        <ExchangeHours instrument={instrument} />
                    </div>
                }
            />
            <Page overrideDefaultTopPadding="none">
                <AddFinForm portfolioId={portfolioId} onSuccess={onSuccess} submitButtonLabel="Next" />
            </Page>
        </>
    )
}
