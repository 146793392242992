import {Flag} from '@design-system/flag'
import React from 'react'
import {ListingResponseDto} from '~/api/distill'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import MarketDepthLink from '~/global/widgets/nzx-market-depth-link/MarketDepthLink'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {Instrument} from '~/store/instrument/types'
import styles from './InstrumentHeader.scss'

const InstrumentSubHeader: React.FC<{
    instrument: Instrument | ListingResponseDto
    isOnNZX: boolean
    essBadgeLabel?: string
}> = ({instrument, essBadgeLabel, isOnNZX}) => {
    return (
        <span className={styles.linkAndSymbol}>
            <h4 className={styles.exchangeAndSymbol}>
                <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                <PronounceLetters text={instrument.exchange} />
                {essBadgeLabel && (
                    <>
                        {' '}
                        <Delimiter /> <Flag dataTestId="flag--ess" text={essBadgeLabel} type="neutral" isSubtle />
                    </>
                )}
            </h4>
            {isOnNZX && <MarketDepthLink tradingType="listed" instrumentSlug={instrument.urlSlug} />}
        </span>
    )
}

export default InstrumentSubHeader
