import {Button} from '@design-system/button'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Model} from '~/api/retail/types'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {useAutoinvestOrders} from '~/sections/invest/sections/auto-invest/hooks/useAutoinvestOrders'
import DuplicateInstrumentWarningModal from '~/sections/invest/sections/auto-invest/widgets/duplicate-instrument-warning/DuplicateInstrumentWarningModal'
import autoinvestActions from '~/store/autoinvest/actions'
import {useAppSelector, useAppDispatch} from '~/store/hooks'
import {DIYAutoInvestType} from '~/store/instrument/types'

interface AutoInvestFooterProps {
    diyAIType?: DIYAutoInvestType
    instrumentSlug?: string
    instrumentId?: string
    instrumentName?: string
    premadeSlug?: string
}

const AutoInvestFooter: React.FunctionComponent<AutoInvestFooterProps> = ({
    diyAIType,
    instrumentId,
    instrumentName,
    premadeSlug,
}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [modalOpen, setModalOpen] = React.useState<boolean>(false)

    const [currentOrders] = useAutoinvestOrders()
    const restrictedAccount = useAppSelector(s => s.identity.user?.account_restricted)

    const setDIYFund = (id: string, allocation: string) => dispatch(autoinvestActions.SetDIYFund(id, allocation))

    const orderWithDuplicateInstrument = currentOrders.find((order: Model.AutoinvestOrder) =>
        order.allocations.find(a => a.fund_id === instrumentId),
    )

    const buttonLabel = () => {
        const slugToNameMapping: {[key: string]: string} = {
            'au-global': 'Global',
            'au-responsible': 'Responsible',
            global: 'Global',
            kids: 'Kids',
            responsible: 'Responsible',
        }

        if (premadeSlug) {
            return `Back to the ${slugToNameMapping[premadeSlug]} order`
        } else {
            return diyAIType === 'diyAdd' ? 'Add to DIY order' : `Back to the DIY order`
        }
    }

    const handleButtonClick = () => {
        // Show a warning modal if the instrument is in an existing auto-invest order

        const showWarningModal = !!(
            instrumentId &&
            diyAIType !== 'diyView' && // If we're viewing an existing DIY order, we don't need to warn about a duplicate
            orderWithDuplicateInstrument
        )

        if (showWarningModal) {
            setModalOpen(true)
            return
        }

        if (!premadeSlug && instrumentId && diyAIType !== 'diyView') {
            setDIYFund(instrumentId, '0')
        }

        navigate(-1)
    }

    return (
        <>
            <ActionBar>
                <Button
                    dataTestId="button--back-to-order"
                    disabled={!premadeSlug && restrictedAccount && !diyAIType}
                    label={buttonLabel()}
                    onClick={handleButtonClick}
                />
            </ActionBar>
            {instrumentName && orderWithDuplicateInstrument && (
                <DuplicateInstrumentWarningModal
                    instrumentName={instrumentName}
                    isOpen={modalOpen}
                    orderName={orderWithDuplicateInstrument.order_name}
                    setModalOpen={() => setModalOpen(false)}
                />
            )}
        </>
    )
}

export default AutoInvestFooter
