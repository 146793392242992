import {DateTime} from 'luxon'
import {dateFormatNoDay} from '~/global/utils/format-date/formatDate'

/**
 * Given a timestamp, compute a suitable heading for it in a time ordered list.
 * Today, This Week, This Month, finally falling back to "<month> <year>"
 */
const calculateHistoryHeading = (timestamp: DateTime): string => {
    const now = DateTime.local()
    if (timestamp.toISODate() === now.toISODate()) {
        return 'Today'
    }
    if (timestamp >= now.minus({days: 7})) {
        return 'This week'
    }
    if (timestamp.year === now.year && timestamp.month === now.month) {
        return 'This month'
    }
    return timestamp.toFormat(dateFormatNoDay)
}

export default calculateHistoryHeading
