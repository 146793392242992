import {Modal} from '@design-system/modal'
import React from 'react'
import {useAppSelector} from '~/store/hooks'

interface DuplicateTopupModalHandleProps {
    confirmDuplicatePayment: () => Promise<void>
}
interface DuplicateTopupModalProps {
    amount: string
}
// Modal that can be called by a promise, and resolved via the modal buttons.
export const DuplicateTopupModal = React.forwardRef<DuplicateTopupModalHandleProps, DuplicateTopupModalProps>(
    ({amount}, forwardedRef) => {
        const isDependent = useAppSelector(({identity}) => identity.user!.is_dependent)
        const preferredName = useAppSelector(({identity}) => identity.user!.preferred_name)

        // Store the promise resolve in localState, so it can be resolved via UI
        const [promise, setPromise] = React.useState<{
            resolve?: () => void
            reject?: () => void
        }>({
            resolve: undefined,
            reject: undefined,
        })

        const [isOpen, setIsOpen] = React.useState(false)

        // This hook extends the component ref with objects inside the callback.
        React.useImperativeHandle(forwardedRef, () => ({
            // confirmDuplicatePayment returns a promise resolved by this component
            confirmDuplicatePayment(): Promise<void> {
                return new Promise<void>((resolve, reject) => {
                    setPromise({reject, resolve})
                    return
                })
            },
        }))

        const {reject, resolve} = promise

        const onSubmit = () => {
            if (!resolve) {
                return
            }
            // Hide modal when submitting
            setIsOpen(false)
            resolve()
            setPromise({resolve: undefined, reject: undefined})
        }

        React.useEffect(() => {
            if (reject !== undefined && resolve !== undefined) {
                setIsOpen(true)
            }
        })

        return (
            <Modal
                noClose
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title="Do you want to top up again?"
                dataTestId="modal--duplicate-topup"
                primaryButton={{label: 'Yeah, I want to top up', onClick: onSubmit}}
                secondaryButton={{label: "Nah, I'm good", onClick: reject}}
            >
                <p>
                    It looks like you recently topped up {isDependent ? `${preferredName}’s` : 'your'} account with $
                    {amount}.
                </p>
                <p>Sometimes it can take a few minutes to show in your Wallet</p>
                <p>Are you sure you want to top up with another ${amount}?</p>
            </Modal>
        )
    },
)

export type DuplicateTopupModalHandle = React.ElementRef<typeof DuplicateTopupModal>
