import {TradingStatus} from '~/api/distill'
import {isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {Instrument} from '~/store/instrument/types'

/** All  US instruments (that are not UTC) are eligible for Extended Hours. */
export const isInstrumentEligibleForExtendedHours = (instrument: Instrument) => {
    return isOnMainUsExchange(instrument)
}

/**
 * A notional status instrument has the instrument_trading_statuses.extended_hours_notional_status
 * field set to 'active' or 'close-only' in distill.
 * This means that the instrument can be bought or sold in part shares (market dollars) during extended hours.
 */
export const isNotionalStatusInstrument = (instrument: Instrument) => {
    return isInstrumentEligibleForMarketSellExtendedHours(instrument)
}

/** The instrument must be eligible for EHs and have an active trading status for EH limit buys. */
export const isInstrumentEligibleForLimitBuyExtendedHours = (instrument: Instrument) => {
    const allowedTradingStatusesforLimitBuy: TradingStatus[] = [TradingStatus.active]

    return (
        isInstrumentEligibleForExtendedHours(instrument) &&
        allowedTradingStatusesforLimitBuy.includes(instrument.tradingStatus)
    )
}

/**
 * The instrument must have an active extendedHoursNotionalStatus for market buys.
 * Instruments that have an extendedHoursNotionalStatus of inactive are still
 * eligible for Limit buys @see (isInstrumentEligibleForLimitBuyExtendedHours)
 */
export const isInstrumentEligibleForMarketBuyExtendedHours = (instrument: Instrument) => {
    const allowedNotionalTradingStatusesforMarketBuy: TradingStatus[] = [TradingStatus.active]

    return allowedNotionalTradingStatusesforMarketBuy.includes(instrument.extendedHoursNotionalStatus)
}

/** The instrument must be eligible for EHs and have an active or close only trading status for EH limit sells. */
export const isInstrumentEligibleForLimitSellExtendedHours = (instrument: Instrument) => {
    const allowedTradingStatusesforLimitSell: TradingStatus[] = [TradingStatus.active, TradingStatus.closeonly]

    return (
        isInstrumentEligibleForExtendedHours(instrument) &&
        allowedTradingStatusesforLimitSell.includes(instrument.tradingStatus)
    )
}

/**
 * The instrument must have an active or close only extendedHoursNotionalStatus for market sells.
 * Instruments that have an extendedHoursNotionalStatus of inactive are still
 * eligible for Limit sells @see (isInstrumentEligibleForLimitSellExtendedHours)
 */
export const isInstrumentEligibleForMarketSellExtendedHours = (instrument: Instrument) => {
    const allowedNotionalTradingStatusesforMarketSell: TradingStatus[] = [TradingStatus.active, TradingStatus.closeonly]

    return allowedNotionalTradingStatusesforMarketSell.includes(instrument.extendedHoursNotionalStatus)
}
