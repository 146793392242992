import React from 'react'
import {spacing} from '~/global/scss/helpers'
import SortAndFilter from '~/global/widgets/sort-and-filter/SortAndFilter'
import {PortfolioFilterOptions, PortfolioSortOptions} from '~/store/identity/types'

type Options = {
    id: string
    name: string
}[]

// More filter options will be added in, that's why this is like this and not hard coded in
const investmentFilterOptions: Options = [
    {
        id: 'TYPE',
        name: 'Type',
    },
]

export const investmentSortOptions: Options = [
    {
        id: 'ALPHABETICAL',
        name: 'A-Z',
    },
    {
        id: 'HIGHEST_RETURNS',
        name: 'Highest returns',
    },
    {
        id: 'LOWEST_RETURNS',
        name: 'Lowest returns',
    },
    {
        id: 'HIGHEST_VALUE',
        name: 'Highest investment value',
    },
    {
        id: 'LOWEST_VALUE',
        name: 'Lowest investment value',
    },
]

export const InvestmentsFilterAndSort: React.FunctionComponent<{
    setFilter: Function
    filter: string
    sort: string
    setSort: Function
}> = ({setFilter, filter, sort, setSort}) => {
    return (
        <div className={spacing.spaceAbove16}>
            <SortAndFilter
                currentFilter={investmentFilterOptions.find(f => f.id === filter)}
                currentSort={investmentSortOptions.find(s => s.id === sort)}
                defaultSortId={investmentSortOptions[0].id}
                filterOptions={investmentFilterOptions}
                onFilterMenuClick={(filterId: PortfolioFilterOptions) => {
                    setFilter(filterId)
                }}
                onSortMenuClick={(sortId: PortfolioSortOptions) => {
                    setSort(sortId)
                }}
                sortOptions={investmentSortOptions}
            />
        </div>
    )
}
