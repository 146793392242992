import React from 'react'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {connect} from '~/store/connect'
import {AccountPortfolioReturnCalculationContent} from './pages/return-calculations/PortfolioReturnCalculation'
import {AccountSharesightContent} from './pages/sharesight/Sharesight'
import {AccountPortfolioUsExtendedHoursContent} from './pages/us-extended-hours/PortfolioUsExtendedHours'

export const Portfolio: React.FunctionComponent<AccountPortfolioProps> = ({isDependent, signedUpToUS}) => {
    return (
        <>
            <Toolbar dataTestId="toolbar--portfolio" leftButton="back" title="Portfolio preferences" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <AccountPortfolioReturnCalculationContent withHeading={true} />
                {signedUpToUS && <AccountPortfolioUsExtendedHoursContent withHeading={true} />}
                {!isDependent && <AccountSharesightContent withHeading={true} />}
            </Page>
        </>
    )
}

interface StoreProps {
    isDependent: boolean
    signedUpToUS: boolean
}

type AccountPortfolioProps = StoreProps

export default connect<StoreProps, {}, {}>(state => {
    return {
        isDependent: state.identity.user!.is_dependent,
        preferredName: state.identity.user!.preferred_name,
        signedUpToUS: state.identity.user!.us_equities_enabled,
        includeSoldInvestments: state.identity.includeSoldInvestments,
        enableExtendedHours: state.identity.portfolioExtendedHoursPreference,
    }
})(Portfolio)
