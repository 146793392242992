import React from 'react'
import {spacing} from '~/global/scss/helpers'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {PageTitle} from '~/sections/kiwisaver/widgets/page-title/PageTitle'

export const CantJoin: React.FunctionComponent<{}> = () => {
    return (
        <>
            <Toolbar dataTestId="toolbar--cant-join" leftButton="back" />
            <Page>
                <PageTitle className={spacing.spaceBelow16}>Can't join KiwiSaver</PageTitle>
                <p>Unfortunately you can't join KiwiSaver at this time.</p>
            </Page>
        </>
    )
}
