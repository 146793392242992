import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {hasManagedFund} from '~/global/utils/autoinvest-order/autoinvestOrder'
import {dateFormatFullMonth} from '~/global/utils/format-date/formatDate'
import {useCurrentSubscriptionPlan} from '~/global/utils/subscription-hooks/subscriptionHooks'
import {subscriptionName} from '~/global/utils/subscription-name/subscriptionName'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import BuyOrderCoverageModal from '~/global/widgets/help-modals/BuyOrderCoverageModal'
import {useAppSelector} from '~/store/hooks'
import styles from './CoverageExplainer.scss'

export const CoverageExplainer: React.FunctionComponent = () => {
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const stagedOrder = useAppSelector(s => s.autoinvest.stagedOrder)

    const {isDependent, preferredName} = useAppSelector(s => ({
        isDependent: s.identity.user!.is_dependent,
        preferredName: s.identity.user!.preferred_name,
    }))

    const [currentSubscription] = useCurrentSubscriptionPlan()
    const [isModalOpen, setIsModalOpen] = React.useState(false)

    const yourOrTheir = isDependent ? 'their' : 'your'
    const theOrYour = isDependent ? 'the' : 'your'

    if (!currentSubscription) {
        return null
    }

    return (
        <>
            <div>
                <p className={cn(styles.planInfoHeader, spacing.spaceAbove24)}>
                    {isDependent ? `${preferredName}'s` : 'Your'} {subscriptionName(currentSubscription.plan)} is active
                </p>
                <ul>
                    <li>
                        As {yourOrTheir} auto-invest orders are placed, we’ll deduct the equivalent amount from{' '}
                        {theOrYour} available coverage (or less, if there’s not enough left).
                    </li>
                    <li>
                        For any part of {theOrYour} auto-invest order that isn’t covered by {yourOrTheir} plan, the 1.9%
                        transaction fee (and per order fee caps) will apply.
                    </li>
                    <li>
                        Some (or all) of the coverage used for this order might be returned
                        {!isDependent && ' to you '} if the order doesn’t completely fill (or is cancelled) before{' '}
                        {currentSubscription.expires.toFormat(dateFormatFullMonth)}.
                    </li>
                    <li>
                        Any transaction fee applied to {isDependent ? 'this' : 'your'} order will be deducted from{' '}
                        {theOrYour} order amount before it’s placed on-market (or added if you’re placing a limit buy in
                        shares).
                    </li>
                    {hasManagedFund(stagedOrder, instrumentsById) && (
                        <li>
                            Orders for managed funds aren’t charged our transaction fee, and won't be deducted from your
                            available coverage.
                        </li>
                    )}
                </ul>
            </div>
            <ButtonAsLink noTextDecoration onClick={() => setIsModalOpen(true)}>
                How buy order coverage works
            </ButtonAsLink>
            <BuyOrderCoverageModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
            <hr className={cn(spacing.spaceBelow24, spacing.spaceAbove24)} />
        </>
    )
}

export default CoverageExplainer
