import {ChevronDown, ChevronUp} from '@design-system/icon'
import {Loader} from '@design-system/loader'
import {AvailableCurrency} from '@design-system/strong-currency-exchange'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {accessibility, spacing} from '~/global/scss/helpers'
import {dateFormatWithSecond} from '~/global/utils/format-date/formatDate'
import ExchangeMoneyFees from '~/global/widgets/help-modals/ExchangeMoneyFees'
import {DollarValue, ExchangeRateValue, FeeValue} from '~/global/widgets/number-elements/NumberElements'
import Tooltip from '~/global/widgets/tooltip/Tooltip'
import styles from '../../ExchangeMoney.scss'

export const SourceAmountHelp: React.FunctionComponent<{
    isFetchingRates: boolean
    rate: string
    rateUpdated: DateTime
    sourceCurrency: AvailableCurrency
    targetCurrency: AvailableCurrency
    netSourceAmount: string
    feeTotal: string
    error?: string
}> = ({isFetchingRates, rate, rateUpdated, sourceCurrency, targetCurrency, netSourceAmount, feeTotal, error}) => {
    const [showDetails, setShowDetails] = React.useState(false)

    return (
        <>
            <div className={styles.supplementTextContainer}>
                <div aria-live="polite" className={styles.errorMessageWrapper}>
                    {error}
                </div>
                <div className={styles.exchangeDetailsWrapper}>
                    <div>
                        {isFetchingRates && (
                            <div className={styles.updatingExchangeRate}>
                                <span className={styles.loader}>
                                    <Loader size={16} />
                                </span>
                                <p className={styles.exchangeRate}>Refreshing rate</p>
                            </div>
                        )}

                        {!isFetchingRates && rate && (
                            <div className={styles.exchangeRate}>
                                <Tooltip>
                                    <Tooltip.Label>
                                        <ExchangeRateValue
                                            sourceCurrency={sourceCurrency}
                                            targetCurrency={targetCurrency}
                                            exchangeRate={parseFloat(rate)}
                                        />
                                    </Tooltip.Label>
                                    <Tooltip.Body>
                                        {rate && (
                                            <p className={styles.tooltip}>
                                                This is the exchange rate as of{' '}
                                                {rateUpdated.toFormat(dateFormatWithSecond)}
                                            </p>
                                        )}
                                    </Tooltip.Body>
                                </Tooltip>
                            </div>
                        )}
                        <button
                            className={cn(styles.showDetailsButton, accessibility.button)}
                            type="button"
                            onClick={() => setShowDetails(!showDetails)}
                        >
                            {showDetails ? 'Hide' : 'Show'} details
                            {showDetails ? <ChevronUp /> : <ChevronDown />}
                        </button>
                    </div>
                </div>
            </div>
            {showDetails && (
                <div className={styles.details}>
                    <div className={spacing.spaceBelow12}>
                        <p className={styles.exchangeFee}>
                            Exchange fee
                            <ExchangeMoneyFees />
                        </p>

                        <p className={styles.amount}>
                            {feeTotal ? <FeeValue value={feeTotal} currency={sourceCurrency} /> : '-'}
                        </p>
                    </div>
                    <div>
                        <p>Amount to exchange</p>
                        <p className={styles.amount}>
                            {netSourceAmount ? <DollarValue value={netSourceAmount} currency={sourceCurrency} /> : '-'}
                        </p>
                    </div>
                </div>
            )}
        </>
    )
}
