import {ModalLink} from '@design-system/modal'
import {useAtom} from 'jotai'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {SignUpStepCommonProps} from '~/sections/moose/sections/sign-up/SignUp'
import NatureAndPurposeForm, {
    NatureAndPurposeFormValues,
} from '~/sections/moose/sections/sign-up/pages/nature-and-purpose/widgets/nature-and-purpose-form/NatureAndPurposeForm'
import {NatureAndPurposeForEntity, stagedSignUpAtom} from '~/sections/moose/sections/sign-up/state'
import {useSignupEntityDetail} from '~/sections/moose/sections/sign-up/utils/sign-up-detail-hooks/signUpDetailHooks'

const NatureAndPurpose = ({progressStep, regressStep}: SignUpStepCommonProps) => {
    const [stagedSignUp, updateStagedSignUp] = useAtom(stagedSignUpAtom)
    const signupEntityDetail = useSignupEntityDetail()

    // the initial entity may not be the first one if we've come from a future sign up step
    const initialEntityIndex = Math.max(0, stagedSignUp.natureAndPurpose.length - 1)
    const [currentEntity, setCurrentEntity] = React.useState(signupEntityDetail.entities[initialEntityIndex])
    const currentEntityIndex = signupEntityDetail.entities.indexOf(currentEntity)

    const hasMultipleEntities = signupEntityDetail.entities.length > 1
    const hasMoreEntities = currentEntityIndex < signupEntityDetail.entities.length - 1

    const onBack = () => {
        if (hasMultipleEntities && currentEntityIndex !== 0) {
            setCurrentEntity(signupEntityDetail.entities[currentEntityIndex - 1])
        } else {
            regressStep()
        }
    }

    const onSubmit = (formValues: NatureAndPurposeFormValues) => {
        addOrUpdateStagedNatureAndPurpose(formValues)

        if (hasMoreEntities) {
            setCurrentEntity(signupEntityDetail.entities[currentEntityIndex + 1])
        } else {
            progressStep()
        }
    }

    const addOrUpdateStagedNatureAndPurpose = (formValues: NatureAndPurposeFormValues) => {
        const stagedNpForEntity = stagedSignUp.natureAndPurpose.find(np => np.partyId === currentEntity.party_id)
        const newNpForEntity: NatureAndPurposeForEntity = {
            partyId: currentEntity.party_id,
            frequency: formValues.frequency,
            purposes: formValues.purposes,
            otherPurpose: formValues.otherPurpose,
        }

        if (!stagedNpForEntity) {
            // add (end of list)
            updateStagedSignUp({
                ...stagedSignUp,
                natureAndPurpose: [...stagedSignUp.natureAndPurpose, newNpForEntity],
            })
        } else {
            // update (in place)
            stagedSignUp.natureAndPurpose[currentEntityIndex] = newNpForEntity
            updateStagedSignUp({...stagedSignUp})
        }
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--fonterra-signup-nature-and-purpose"
                leftButton="back"
                onLeftButtonClick={onBack}
                title="How do you plan to use Sharesies?"
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <p className={spacing.spaceBelow24}>
                    A best guess is fine—if you like, you can update your responses later. This won’t affect what you
                    can access on Sharesies. <NatureAndPurposeModal />
                </p>
                <NatureAndPurposeForm entity={currentEntity} onSubmit={onSubmit} />
            </Page>
        </>
    )
}

const NatureAndPurposeModal = () => {
    return (
        <ModalLink
            dataTestId="modal-link--nature-and-purpose"
            label="Find out more"
            modalTitle="Why we’re asking"
            primaryButton={{label: 'Ok'}}
        >
            <p>
                This info helps us to fulfil our anti-money laundering and countering financing of terrorism (AML/CFT)
                obligations.
            </p>
            <p>
                Any questions, let us know at <a href="mailto:help@sharesies.co.nz">help@sharesies.co.nz</a>
            </p>
        </ModalLink>
    )
}

export default NatureAndPurpose
