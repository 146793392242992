import {getReactNodeText} from '~/global/utils/get-react-node-text/getReactNodeText'

export const convertToKebabCase = (value: any) => {
    return (
        getReactNodeText(value)
            .replace(/[^a-zA-Z0-9\s_]/g, '')
            // if camel case, convert to kebab-case
            .replace(/([a-z])([A-Z])/g, '$1-$2')
            // if spaced, convert to kebab-case
            .replace(/[\s_]+/g, '-')
            // lowercase
            .toLowerCase()
    )
}
