/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface PriceHistoryResponseDto
 */
export interface PriceHistoryResponseDto {
    /**
     *
     * @type {string}
     * @memberof PriceHistoryResponseDto
     */
    instrumentId: string
    /**
     *
     * @type {string}
     * @memberof PriceHistoryResponseDto
     */
    first?: string | null
    /**
     *
     * @type {string}
     * @memberof PriceHistoryResponseDto
     */
    last?: string | null
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof PriceHistoryResponseDto
     */
    dayPrices: {[key: string]: string}
}

export function PriceHistoryResponseDtoFromJSON(json: any): PriceHistoryResponseDto {
    return PriceHistoryResponseDtoFromJSONTyped(json, false)
}

export function PriceHistoryResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceHistoryResponseDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        instrumentId: json['instrumentId'],
        first: !exists(json, 'first') ? undefined : json['first'],
        last: !exists(json, 'last') ? undefined : json['last'],
        dayPrices: json['dayPrices'],
    }
}

export function PriceHistoryResponseDtoToJSON(value?: PriceHistoryResponseDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        instrumentId: value.instrumentId,
        first: value.first,
        last: value.last,
        dayPrices: value.dayPrices,
    }
}
