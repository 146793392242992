import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {spacing} from '~/global/scss/helpers'
import {usTransferFee} from '~/global/utils/share-transfers/shareTransfers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {Checkbox} from '~/global/widgets/form-controls'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {useAppSelector} from '~/store/hooks'

const TermsAndConditions: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const [acceptTerms, setAcceptTerms] = React.useState(false)

    const stagedTransferOrder = useAppSelector(({transfer}) => transfer.stagedTransferOrder)
    const fee = usTransferFee(stagedTransferOrder?.direction, stagedTransferOrder?.usTransferPlatform)

    return (
        <>
            <Toolbar dataTestId="toolbar--us-transfer-terms-and-conditions" leftButton="back" />
            <Page>
                <h1 className={cn(spacing.spaceBelow24, commonStyles.heading)}>Before you start</h1>

                <div>
                    <Checkbox
                        dataTestId="checkbox--accept-terms"
                        name="accept-terms"
                        isTouched={true}
                        value={acceptTerms}
                        label={<p>I confirm that:</p>}
                        onChange={e => setAcceptTerms((e.currentTarget as HTMLInputElement).checked)}
                    />
                    <ul className={commonStyles.checkboxList}>
                        <li className={commonStyles.checkboxListItem}>
                            I’ve read and agree to the{' '}
                            <a
                                className={commonStyles.topupLink}
                                href={profileUrl('legal/us-share-transfers-terms')}
                                target="_blank"
                                rel="noopener"
                            >
                                US transfer terms and conditions
                            </a>
                        </li>
                        <li className={commonStyles.checkboxListItem}>
                            I understand that my personal and account information might be shared with third parties{' '}
                            <ModalLink
                                dataTestId="modal-link--us-transfers-third-party-sharing"
                                label="Share transfer info sharing"
                                asIcon
                                modalTitle="Share transfer info sharing"
                                primaryButton={{label: 'OK'}}
                                helpIconSize={16}
                            >
                                <p>
                                    To complete your share transfer, we may need to share the transfer details and some
                                    of your Sharesies account information with the broker that holds your US shares,
                                    DriveWealth, and the Depository Trust Company.
                                </p>
                                <p className={commonStyles.formLabel}>Information that may be shared</p>
                                <ul>
                                    <li>your name and signature</li>
                                    <li>the date of the transfer</li>
                                    <li>the ticker code and number of shares you’re transferring</li>
                                    <li>your brokerage account name and number</li>
                                    <li>
                                        any other information that is relevant and necessary to facilitate the transfer.
                                    </li>
                                </ul>
                            </ModalLink>
                        </li>
                        <li className={commonStyles.checkboxListItem}>
                            I understand the share transfer fee will be deducted from my Wallet once I submit my
                            request.
                            <ModalLink
                                dataTestId="modal-link--fee-info"
                                label="US share transfer fee"
                                asIcon
                                modalTitle="US share transfer fee"
                                primaryButton={{label: 'OK'}}
                                helpIconSize={16}
                            >
                                {stagedTransferOrder?.usTransferPlatform === 'EXTERNAL' && (
                                    <p>
                                        It costs ${fee} USD for each investment you transfer{' '}
                                        {stagedTransferOrder?.direction === 'in' ? 'from' : 'to'} a non-DriveWealth
                                        partner.
                                    </p>
                                )}
                                {stagedTransferOrder?.usTransferPlatform === 'INTERNAL' && (
                                    <p>
                                        It costs ${fee} USD each time you request a share transfer{' '}
                                        {stagedTransferOrder?.direction === 'in' ? 'from' : 'to'} a DriveWealth partner.
                                        You can transfer as many investments as you like within a request.
                                    </p>
                                )}
                                {stagedTransferOrder?.usTransferPlatform === 'DRS' && (
                                    <p>
                                        It costs ${fee} USD for the investment you transfer{' '}
                                        {stagedTransferOrder?.direction === 'in' ? 'from' : 'to'} a Transfer agent.
                                    </p>
                                )}
                                <p>
                                    The money needs to be in your Wallet (in USD) at the time you submit your transfer
                                    request.{' '}
                                </p>
                                <p>
                                    The platform you’re transferring shares{' '}
                                    {stagedTransferOrder?.direction === 'in' ? 'from' : 'to'} may also charge a transfer
                                    fee.
                                </p>
                            </ModalLink>
                        </li>
                    </ul>
                </div>
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--next"
                    onClick={() => navigate(profileUrl('invest/portfolio-transfer-shares/us/investor-details'))}
                    label="Next"
                    disabled={!acceptTerms}
                />
            </ActionBar>
        </>
    )
}

export default TermsAndConditions
