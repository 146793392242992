import {Button} from '@design-system/button/Button'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useTransferInfo} from '~/global/utils/use-transfer-info/useTransferInfo'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {Radio} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/transfer/actions'
import {NEW_SRN_REQUIRED} from '~/store/transfer/types'

export const AddRegistryDetailsSrnOrHinOut = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const transferInfo = useTransferInfo()
    const stagedTransferOrder = useAppSelector(({transfer}) => transfer.stagedTransferOrder!)
    const [hasExistingSrn, setHasExistingSrn] = React.useState<'yes' | 'no' | undefined>()
    const [transferType, setTransferType] = React.useState<'srn' | 'hin' | undefined>()

    React.useEffect(() => {
        if (!stagedTransferOrder) {
            navigate(profileUrl('invest/transfer-shares'), {replace: true})
        }
    }, [])

    if (!transferInfo) {
        return <Loading isPineapple />
    }

    const nextPage = () => {
        // hasn't selected anything
        if (!transferType) {
            return
        }

        if (hasExistingSrn === 'yes') {
            // Delete surplus reference if user is navigating back
            dispatch(
                actions.SetStagedTransferOrder({
                    ...stagedTransferOrder,
                    reference: '',
                }),
            )
        }

        if (transferType === 'srn') {
            if (hasExistingSrn === 'no') {
                dispatch(actions.CreateRegistryDetails({referenceId: NEW_SRN_REQUIRED, referenceType: 'SRN'}))
            } else {
                dispatch(actions.CreateRegistryDetails({referenceId: '', referenceType: 'SRN'}))
            }
        }

        if (transferType === 'hin') {
            dispatch(actions.CreateRegistryDetails({referenceId: '', referenceType: 'HIN'}))
        }

        navigate(profileUrl('invest/portfolio-transfer-shares/asx/intro'))
    }

    const srnOptions = [
        {value: 'yes', label: 'I already have an SRN'},
        {value: 'no', label: "I don't have an SRN and need one created for me"},
    ]

    return (
        <>
            <Toolbar dataTestId="toolbar--reference-option" leftButton="back" />
            <Page>
                <h1 className={cn(spacing.spaceBelow24)}>Where are you transferring to?</h1>
                <form className={spacing.spaceAbove16}>
                    <Radio
                        choices={[
                            {value: 'hin', label: 'Holder Identification Number (HIN)'},
                            {value: 'srn', label: 'Shareholder Reference Number (SRN)'},
                        ]}
                        dataTestId="radio--asx-transfer-type"
                        isTouched={false}
                        name="transferType"
                        onChange={e => setTransferType(e.target.value as 'srn' | 'hin')}
                        value={transferType}
                    />
                    {transferType === 'srn' && (
                        <>
                            <h1 className={cn(spacing.spaceBelow24)}>
                                Do you have a Securityholder Reference Number (SRN)?
                            </h1>
                            <Radio
                                dataTestId="radio--srn"
                                name="exchange"
                                isTouched
                                choices={srnOptions}
                                onChange={e => setHasExistingSrn(e.target.value as 'yes' | 'no')}
                                value={hasExistingSrn}
                                additionalClassName={spacing.spaceBelow16}
                            />
                        </>
                    )}
                </form>
            </Page>
            <ActionBar>
                <Button
                    label="Next"
                    dataTestId="button--next"
                    type="primary"
                    onClick={nextPage}
                    disabled={!transferType}
                />
            </ActionBar>
        </>
    )
}
