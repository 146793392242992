import {TextInput} from '@design-system/text-input'
import React from 'react'
import * as api from '~/api/retail'
import {Model, Response} from '~/api/retail/types'
import {CommonProps, withFocus, FocusInjectedProps, withId} from '../common'

type PromoType = 'regular' | 'kids'

type PromoJurisdiction = 'all' | 'au' | 'nz'

type PromoProps = CommonProps<string> &
    FocusInjectedProps & {
        additionalClassName?: string
        placeholder?: string
        autoFocus?: boolean
        type: PromoType
        jurisdiction: PromoJurisdiction
        selectedCountry: Model.User['jurisdiction']
        isReferred: boolean
    }

interface PromoState {
    promoCodeData?: Response.PromoCodeDataValid | Response.PromoCodeDataInvalid | Response.InternalServerError
}

class PromoCode extends React.Component<PromoProps, PromoState> {
    public state: PromoState = {}

    private async fetchCode(type: PromoType, jurisdiction: PromoJurisdiction, promo_code?: string) {
        if (!promo_code) {
            return
        }

        return api.get('identity/promo-code-check', {promo_code, type, jurisdiction})
    }

    async componentDidMount() {
        const {type, jurisdiction, value} = this.props

        this.setState({promoCodeData: await this.fetchCode(type, jurisdiction, value)})
    }

    private onBlur = async (e: string | React.FocusEvent<unknown>) => {
        const {value, jurisdiction, type} = this.props

        this.props.onBlur(e)
        this.setState({promoCodeData: await this.fetchCode(type, jurisdiction, value)})
    }

    private calculateHelpText(selectedCountry: Model.User['jurisdiction'], isReferred: boolean) {
        const {promoCodeData} = this.state

        if (isReferred && !promoCodeData) {
            return 'Referral applied, we’ll add that bonus to your Wallet when you finish sign up!'
        }

        if (!promoCodeData || promoCodeData.type !== 'promo_code_data_valid') {
            return
        }

        const openPhrase = `${selectedCountry === 'au' ? 'Bonza!' : 'Heaps choice!'}`
        return `${openPhrase} Promo success is yours`
    }

    private calculateError(selectedCountry: Model.User['jurisdiction'], isReferred: boolean) {
        const {promoCodeData} = this.state

        if (!promoCodeData || promoCodeData.type !== 'promo_code_data_invalid') {
            return
        }
        if (promoCodeData.message === 'jurisdiction_error') {
            return `It looks like that promo isn’t valid in ${selectedCountry === 'au' ? 'Australia' : 'New Zealand'}`
        }
        /*
        A promoCodeData.message with value 'type_error' occurs when the promo's type is not the same as this.props.type, or is not 'both'.
        If this.props.type is 'regular', and we get a type_error message, we can infer that the user is trying to create a regular account with a kids promo code.
        In this situation, direct them to log in to their Sharesies account and then create a kids account with the promo.
        */
        if (promoCodeData.message === 'type_error' && this.props.type === 'regular') {
            return `Oops, looks like that promo code is for creating a Kids Account. To use it, log in to your Sharesies account (or sign up), and go to "Account > Create a Kids Account".`
        }

        return isReferred
            ? `Looks like that promo isn’t valid or has expired. If you followed a referral link then leave this field blank.`
            : `Looks like that promo isn’t valid or has expired.`
    }

    render() {
        const {selectedCountry, isReferred, error, additionalClassName, type, ...otherProps} = this.props

        const helpText = this.calculateHelpText(selectedCountry, isReferred) || ' '
        const calculatedError = error || this.calculateError(selectedCountry, isReferred)

        return (
            <div className={additionalClassName}>
                <TextInput
                    {...otherProps}
                    onBlur={this.onBlur}
                    dataTestId="text-input--promocode"
                    helpText={helpText}
                    error={calculatedError}
                    optional
                />
            </div>
        )
    }
}

const PromoCodeWithFocus = withId(withFocus(PromoCode))

export default PromoCodeWithFocus
