import {useRetailGet} from '~/api/query/retail'
import {useAppSelector} from '~/store/hooks'
import {actingAsID} from '~/store/identity/selectors'

// Fetch the current recurring payment, if any
export const useRecurringPayment = () => {
    const isAuCustomer = useAppSelector(s => s.identity.user!.jurisdiction === 'au')
    const acting_as_id = useAppSelector(actingAsID)
    const {data} = useRetailGet({
        path: 'banklinking/recurring-payment',
        payload: {acting_as_id},
        options: {enabled: isAuCustomer}, // Make sure this isn't fetched for non-AU customers, as recurring payments isn't supported in NZ
    })

    if (!isAuCustomer || data.type === 'empty') {
        return undefined
    }

    return data
}
