/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface PortfolioFlagList
 */
export interface PortfolioFlagList {
    /**
     *
     * @type {Array<string>}
     * @memberof PortfolioFlagList
     */
    portfolio_uuids: Array<string>
    /**
     *
     * @type {number}
     * @memberof PortfolioFlagList
     */
    error_code: number
}

export function PortfolioFlagListFromJSON(json: any): PortfolioFlagList {
    return PortfolioFlagListFromJSONTyped(json, false)
}

export function PortfolioFlagListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioFlagList {
    if (json === undefined || json === null) {
        return json
    }
    return {
        portfolio_uuids: json['portfolio_uuids'],
        error_code: json['error_code'],
    }
}

export function PortfolioFlagListToJSON(value?: PortfolioFlagList | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        portfolio_uuids: value.portfolio_uuids,
        error_code: value.error_code,
    }
}
