import React from 'react'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import AddressSuccess from '~/sections/user/pages/address-success/AddressSuccess'

interface Props {
    onNext(): void
}

const SignUpAddressSuccess = ({onNext}: Props) => (
    <>
        <Toolbar dataTestId="toolbar--address-success" />
        <AddressSuccess onClick={onNext} />
    </>
)

export default SignUpAddressSuccess
