import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import styles from './Record.scss'

interface RecordRowProps {
    left?: React.ReactNode
    right?: React.ReactNode
    className?: string
    dataTestId?: string
}

export const RecordRow: React.FunctionComponent<RecordRowProps> = ({left, right, className, dataTestId}) => {
    return (
        <div className={cn(styles.recordRow, className)} data-testid={dataTestId}>
            <div>{left}</div>
            <div>{right}</div>
        </div>
    )
}

interface RecordPageProps {
    /**
     * Should the <PageBack /> widget be hidden
     */
    hidePageBack?: boolean
    /**
     * Title of this page (often the description of a transaction)
     */
    title: React.ReactNode
    /**
     * Header (often the logo/instrument details)
     */
    header?: React.ReactNode
    /**
     * One or more RecordRow components that render on the card
     */
    rows: React.ReactNode
    /**
     * The amount to display on the bottom of the card
     */
    amount: string | number
    /**
     * The currency to display alongside the amount
     */
    amountCurrency?: string
    /**
     * The label above the total amount (defaults to "Amount")
     */
    amountLabel?: React.ReactNode
    /**
     * The status label content (bottom-left blue badge). Defaults to not displaying one
     */
    statusLabel?: React.ReactNode
    /**
     * Footer (displayed below the amount/status)
     */
    footer?: React.ReactNode
}

export const RecordPage: React.FunctionComponent<RecordPageProps> = ({
    hidePageBack,
    title,
    header,
    rows,
    amount,
    amountCurrency,
    amountLabel,
    statusLabel,
    footer,
}) => {
    return (
        <>
            {!hidePageBack && (
                <div className={styles.header}>
                    <PageBack />
                </div>
            )}
            <Page withoutDefaultPadding>
                <div className={styles.viewContainer}>
                    <div className={styles.titleBlock}>
                        <h2>{title}</h2>
                    </div>
                    <div className={styles.orderContent}>
                        {header && <div className={styles.orderTitleBlock}>{header}</div>}
                        <div className={styles.recordRowContainer}>{rows}</div>

                        <p className={styles.orderAmountLabel}>{amountLabel ?? 'Amount'}</p>

                        <div className={styles.orderFooter}>
                            {statusLabel ? (
                                <div>
                                    <p className={styles.statusLabel} data-testid="orderStatusLabel">
                                        {statusLabel}
                                    </p>
                                </div>
                            ) : (
                                <div />
                            )}
                            <div>
                                <p className={styles.orderAmount}>
                                    <DollarValue value={amount} currency={amountCurrency} />
                                </p>
                            </div>
                        </div>
                        {footer && <div className={cn(styles.note, spacing.spaceAbove32)}>{footer}</div>}
                    </div>
                </div>
            </Page>
        </>
    )
}
