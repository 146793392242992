import React from 'react'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import {TransactionDetailTypes} from '~/sections/wallet/pages/transaction/utils/transaction-types/transactionTypes'
import {RecordPage, RecordRow} from '~/sections/wallet/pages/transaction/widgets/record/Record'

const instructionLabel = (instruction: TransactionDetailTypes['save_transfer_order']['detail']['instruction']) => {
    switch (instruction) {
        case 'DEPOSIT':
            return 'To'
        case 'WITHDRAW':
            return 'From'
        default:
            assertNever(instruction)
    }
}

export const SaveTransferOrderRecord: React.FunctionComponent<{
    transaction: TransactionDetailTypes['save_transfer_order']
}> = ({transaction}) => {
    return (
        <RecordPage
            title={transaction.description}
            rows={
                <>
                    <RecordRow left="Date" right={transaction.timestamp.toFormat(dateFormatWithYear)} />
                    <RecordRow
                        left={instructionLabel(transaction.detail.instruction)}
                        right={transaction.detail.name}
                    />
                </>
            }
            amount={transaction.amount}
            amountCurrency={transaction.currency}
        />
    )
}
