import {Modal} from '@design-system/modal'
import React from 'react'
import styles from './OrderCoverageModal.scss'

interface ModalProps {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SellOrderCoverageModal: React.FunctionComponent<ModalProps> = ({isOpen, setIsOpen}) => (
    <Modal
        dataTestId="modal--how-sell-coverage-works"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="How sell order coverage works"
        primaryButton={{label: 'Got it'}}
        content={
            <div className={styles.modalContent}>
                <p>
                    Sell orders are deducted from any available coverage as they fill (which might happen in parts, and
                    over multiple trades). For sell orders that fill after you’ve used up your coverage, the 1.9%
                    transaction fee (and per order fee caps) will apply until your plan renews.
                </p>
                <p>
                    If your plan renews while you’ve got a sell order on-market, we’ll look to use any available
                    coverage from the plan that was active when you placed your order. For any part that isn’t covered,
                    the 1.9% transaction fee (and per order fee caps) will apply.
                </p>
                <p>
                    If you start a plan while you’ve got a sell order on-market, the 1.9% transaction fee (and per order
                    fee caps) will apply.
                </p>

                <h2>Partial coverage order</h2>

                <p>
                    A partial coverage order is when some of your order uses your remaining available coverage, and the
                    rest is charged the 1.9% transaction fee (up to the per order fee caps). This can happen when your
                    order amount is higher than your remaining coverage.
                </p>
                <p>
                    If the part of your partial order charged the 1.9% transaction fee reaches a fee cap, we’ll return
                    any coverage that would’ve been used.
                </p>
            </div>
        }
    />
)

export default SellOrderCoverageModal
