import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {urlFor} from '~/global/routeGenerator'
import {page, spacing} from '~/global/scss/helpers'
import {useProfiles} from '~/global/state-hooks/retail/useProfile'
import {isWrapperApp} from '~/global/utils/is-wrapper-app/isWrapperApp'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import createKidsAccountHero from '~/sections/user/sections/settings/pages/create-kids-account/assets/images/create-kids-account-hero.png'
import styles from './CreateKidsAccount.scss'

export const CreateKidsAccount = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const profiles = useProfiles()
    const partialSignups = profiles.filter(
        profile => profile.legacy_profile_type === 'DEPENDENT' && profile.is_in_signup,
    )

    return (
        <>
            <Toolbar
                dataTestId="toolbar--create-kids-account"
                leftButton={isWrapperApp() ? 'close' : 'back'} // native kids sign up screens use a 'top sheet' pattern that can be dismissed
                onLeftButtonClick={() => {
                    sendWrapperAppMessage({
                        type: 'goBack',
                    })
                    navigate(-1)
                }}
                title="Kids Accounts"
            />
            <Page className={page.topPaddingOnly} overrideDefaultTopPadding="withToolbarTitle">
                <div className={styles.hero}>
                    <img className={styles.heroImage} role="presentation" src={createKidsAccountHero} alt="" />
                </div>

                <div className={page.container}>
                    <h2 className={cn(styles.title, spacing.spaceBelow8)}>Create a Kids Account</h2>

                    <p className={spacing.spaceBelow24}>
                        Help a kid in your life take the first step in their investing journey. All you need is proof of
                        their identity and their IRD number.
                    </p>

                    <Button
                        type="primary"
                        dataTestId="button--create-kids-account"
                        label="Create a Kids Account"
                        additionalClassName={spacing.spaceBelow32}
                        onClick={() => navigate(profileUrl('sign-up/dependent'))}
                    />

                    {/* Kids accounts that haven't been completed will appear here */}
                    {partialSignups.length > 0 && (
                        <>
                            <p className={cn(styles.continue, spacing.spaceBelow16)}>Continue creating account for:</p>

                            <ul className={styles.list}>
                                {partialSignups.map(kidProfile => (
                                    <li key={kidProfile.id} className={cn(styles.item, spacing.spaceBelow16)}>
                                        <p className={styles.kidName}>{kidProfile.name}</p>

                                        <Button
                                            type="secondary"
                                            dataTestId={`button--continue-creating-account-${kidProfile.slug}`}
                                            label="Continue"
                                            additionalClassName={styles.continueButton}
                                            onClick={() => {
                                                // functionally this is the same behaviour as the ProfileSwitcher onClick
                                                // naviating to the right profile triggers the account switch
                                                navigate(
                                                    urlFor('profile/:profileSlug/', {profileSlug: kidProfile.slug}),
                                                )
                                            }}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            </Page>
        </>
    )
}
