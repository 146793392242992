import cn from 'classnames'
import React from 'react'
import {Currency, currencyDetails} from '~/global/utils/currency-details/currencyDetails'
import {WalletValue} from '~/global/widgets/number-elements/NumberElements'
import Tooltip from '~/global/widgets/tooltip/Tooltip'
import styles from './BalanceItem.scss'

interface BalanceItemProps {
    currency: Currency
    balance: string
    estimatedCurrency?: Currency
    estimatedBalance?: string
}

export const BalanceItem = ({currency, balance, estimatedCurrency, estimatedBalance}: BalanceItemProps) => {
    const {icon} = currencyDetails[currency]

    return (
        <li>
            <div className={styles.iconAndLabel}>
                {icon} <p className={styles.currencyLabel}>{currency.toUpperCase()}</p>
            </div>
            <div className={cn({[styles.negativeBalance]: Number(balance) < 0})}>
                {estimatedCurrency && estimatedBalance ? (
                    <Tooltip>
                        <Tooltip.Label>
                            <WalletValue value={balance} currency={currency} />
                        </Tooltip.Label>
                        <Tooltip.Body>
                            <p>
                                Estimated <WalletValue value={estimatedBalance} currency={estimatedCurrency} />
                            </p>
                        </Tooltip.Body>
                    </Tooltip>
                ) : (
                    <WalletValue value={balance} currency={currency} />
                )}
            </div>
        </li>
    )
}
