import React from 'react'
import config from '~/configForEnv'
import {connect} from '~/store/connect'
import actions from '~/store/instrument/actions'
import ReFetch from './ReFetch'

const ExchangeOpenStatusRefetch: React.FunctionComponent<ExchangeOpenStatusRefreshProps> = React.memo(
    ({loadMetadata}) => (
        <ReFetch
            fetchAction={loadMetadata}
            timeoutPeriod={config.exchangeHoursCheckInterval} // 5 minutes
        />
    ),
)

type ExchangeOpenStatusRefreshProps = DispatchProps

interface DispatchProps {
    loadMetadata(): Promise<void>
}

export default connect<{}, DispatchProps, {}>(undefined, dispatch => ({
    loadMetadata: () => dispatch(actions.loadMetadata()),
}))(ExchangeOpenStatusRefetch)
