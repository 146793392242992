import {Button} from '@design-system/button'
import React from 'react'
import {useNavigate} from 'react-router'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {useProfiles} from '~/global/state-hooks/retail/useProfile'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ContentCard} from '~/global/widgets/content-card/ContentCard'
import {FARMER_SUPPORT_NUMBER} from '~/sections/moose/constants/constants'
import investSmallImage from './assets/images/invest-small.png'
import styles from './HelpfulLinks.scss'

/**
 * Enum representing hard-coded external links for Fonterra specific Explore content.
 */
export enum FonterraExploreExternalLink {
    ResultsAndReporting = 'https://www.fonterra.com/nz/en/investors.html',
    MilkPriceAndEarnings = 'https://nzfarmsource.co.nz/our-co-operative/co-op-financials.html',
    FarmerSupportNumber = 'http://nzfarmsource.co.nz/sharesies',
}

export const FonterraExploreRedirect: React.FunctionComponent<{link: FonterraExploreExternalLink}> = ({link}) => {
    window.location.replace(link)
    return null
}

export const HelpfulLinks: React.FunctionComponent = (): JSX.Element | null => {
    const profiles = useProfiles()
    const hasPersonalProfile = profiles.some(p => p.is_personal)

    const exploreCards: ContentCard[] = [
        {
            title: 'Performance & Results',
            linkText: 'View the latest investor info',
            url: FonterraExploreExternalLink.ResultsAndReporting,
            colourPreset: 'blueberry',
            extras: {
                eyebrow_label: 'fonterra',
            },
        },
        {
            title: 'Milk Price & Earnings',
            linkText: 'Check the forecasts',
            url: FonterraExploreExternalLink.MilkPriceAndEarnings,
            colourPreset: 'blueberry2',
            extras: {
                eyebrow_label: 'farm source',
            },
        },
        {
            title: 'Help Centre',
            linkText: 'Find answers to your questions',
            url: 'https://intercom.help/fonterra_help/en/',
            colourPreset: 'melon',
            extras: {
                eyebrow_label: 'sharesies',
            },
        },
        {
            title: FARMER_SUPPORT_NUMBER,
            linkText: 'Help & Support',
            url: FonterraExploreExternalLink.FarmerSupportNumber,
            colourPreset: 'pineapple',
            extras: {
                eyebrow_label: 'fonterra farmer support team',
            },
        },
    ]
    return (
        <>
            <div className={styles.helpfulLinks}>
                <h1>Helpful Links</h1>
                {exploreCards.map((card, index) => (
                    <div
                        key={index}
                        onClick={() => rudderTrack('fonterra_engagement', 'content_card_clicked', {type: card.title})}
                    >
                        <ContentCard card={card} className={styles.contentCard} />
                    </div>
                ))}
            </div>
            {!hasPersonalProfile && <SignupBlock />}
        </>
    )
}

const SignupBlock: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    return (
        <div className={styles.signupBlock}>
            <h1>Open a personal Sharesies account</h1>
            <div className={styles.container}>
                <div className={styles.leftContent}>
                    <p className={styles.text}>
                        Invest. save.
                        <br />
                        Kiwisaver.
                        <br />
                        <span className={styles.bottomText}>Together.</span>
                    </p>
                    <p>Join over half a million people using Sharesies to manage their wealth</p>
                </div>
                <div className={styles.rightContent}>
                    <Button
                        width="auto"
                        onClick={() => navigate(profileUrl('explore/sign-up'))}
                        dataTestId="button--sign-up"
                        label="Signup"
                    />
                    <img src={investSmallImage} alt="" />
                </div>
            </div>
        </div>
    )
}
