import {Response} from '~/api/retail/types'

export interface HoldingDetailForSymbol {
    maxAllowedToBuy?: number
    maxAllowedToSell: number
    currentHolding: number
    lienHolding?: number
    reserved: number
    shareholdingApplies: boolean
}

export const getHoldingDetailsForSymbol = (
    holdingDetail: Response.FonterraHoldingDetail | Response.Empty,
    symbol: string,
): HoldingDetailForSymbol => {
    const result: HoldingDetailForSymbol = {
        currentHolding: 0,
        maxAllowedToSell: 0,
        reserved: 0,
        shareholdingApplies: false,
    }

    if (holdingDetail.type === 'empty') {
        return result
    }

    const detail = holdingDetail.instruments.find(instrument => instrument.symbol === symbol)
    if (!detail) {
        return result
    }

    const minimumApplies = detail.minimum !== undefined && detail.minimum !== null
    const maximumApplies = detail.maximum !== undefined && detail.maximum !== null

    return {
        currentHolding: detail.holding,
        lienHolding: detail.lien_holding,
        reserved: detail.reserved ?? 0,
        maxAllowedToBuy: detail.permitted_to_buy ?? undefined,
        maxAllowedToSell: detail.permitted_to_sell ?? detail.holding,
        shareholdingApplies: minimumApplies || maximumApplies,
    }
}
