export const titleCase = (value: string) => {
    // capitalize the first letter of each word, separated by space or hyphen
    return value
        .toLowerCase()
        .split(' ')
        .map(words => words.charAt(0).toUpperCase() + words.substring(1))
        .join(' ')
        .split('-')
        .map(words => words.charAt(0).toUpperCase() + words.substring(1))
        .join('-')
}
