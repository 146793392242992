import {Button} from '@design-system/button'
import React from 'react'
import {useNavigate} from 'react-router'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import kiwisaverLarge from '~/sections/kiwisaver/assets/images/kiwisaver-large.png'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/identity/actions'
import {actingAsID as actingAsIDSelector} from '~/store/identity/selectors'
import {RegistrationPending} from './RegistrationPending'
import styles from './RegistrationPending.scss'

export const RegistrationPendingIntro: React.FunctionComponent<{}> = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const actingAsID = useAppSelector(actingAsIDSelector)
    const {data: identity} = useRetailGet({path: 'identity/check', payload: {acting_as_id: actingAsID}})
    const hasSeenPendingRegistrationScreen =
        identity.type === 'identity_authenticated'
            ? identity.user.has_seen.ks_pending_registration_message_screen
            : false
    const [displayPending, setDisplayPending] = React.useState<boolean>(hasSeenPendingRegistrationScreen)

    const setCustomerHasSeen = useRetailPost({
        path: 'identity/identity/mark-has-seen-flag',
    })

    const handleWaitingScreen = async () => {
        try {
            await setCustomerHasSeen.mutateAsync({
                acting_as_id: actingAsID,
                flag: 'ks_pending_registration_message_screen',
            })
            dispatch(actions.Check())
        } catch (e) {
            // silent fail
        } finally {
            setDisplayPending(true)
        }
    }

    const handleRedirectAndSetHasSeen = async () => {
        await handleWaitingScreen()
        navigate(profileUrl(''))
    }

    React.useEffect(() => {
        setDisplayPending(hasSeenPendingRegistrationScreen)
    }, [hasSeenPendingRegistrationScreen])

    return (
        <>
            {displayPending ? (
                <RegistrationPending />
            ) : (
                <>
                    <Toolbar
                        dataTestId="toolbar--pending"
                        leftButton="back"
                        onLeftButtonClick={handleRedirectAndSetHasSeen}
                    />
                    <Page className={styles.page} data-testid="component-registration-pending-intro">
                        <div className={styles.imageContainer}>
                            <div className={styles.imageBackGround}>
                                <img src={kiwisaverLarge} />
                            </div>
                        </div>

                        <div className={styles.contentSection}>
                            <h1 className={spacing.spaceAbove32}>Nice! We’ll get you set up</h1>
                            <p className={spacing.spaceAbove16}>
                                You don’t need to do anything—we’ll email you when your account is ready. Keep an eye on
                                its status in{' '}
                                <a
                                    href="#"
                                    onClick={e => {
                                        e.preventDefault()
                                        handleRedirectAndSetHasSeen()
                                    }}
                                >
                                    your Portfolio
                                </a>
                                .
                            </p>
                        </div>
                        <Button dataTestId="button--join" label="Sweet" onClick={handleWaitingScreen} />
                    </Page>
                </>
            )}
        </>
    )
}
