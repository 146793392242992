import Decimal from 'decimal.js'
import {Allocation} from '~/sections/kiwisaver/state'

// Take a set of allocations that may or may not add to 100% and alter the base
// fund percentage so they they DO add to 100%
export const rebalanceBasePercent = (allocations: Allocation[]): Allocation[] => {
    const baseAllocations = allocations.filter(a => a.fund_category === 'BASE')
    const otherAllocations = allocations.filter(a => a.fund_category !== 'BASE')
    const baseAllocationPercent = otherAllocations.reduce(
        (bap, a) => bap.minus(new Decimal(a.allocation_percent)),
        new Decimal(100),
    )
    if (baseAllocations.length !== 1) {
        throw new Error("Can't deal with more than one base fund")
    }
    return otherAllocations.concat({
        ...baseAllocations[0],
        allocation_percent: baseAllocationPercent.toString(),
    })
}

// Replace the base fund in a set of allocations with a new fund id
export const setBaseAllocation = (allocations: Allocation[], baseFundId: string): Allocation[] => {
    return rebalanceBasePercent(
        allocations
            .filter(a => a.fund_category !== 'BASE')
            .concat([
                {
                    fund_id: baseFundId,
                    fund_category: 'BASE',
                    allocation_percent: '0',
                },
            ]),
    )
}

// Helper for when you only want to add a single self-select pick to an
// existing list of allocations
export const addSingleSelfSelectFundToExistingAllocations = (
    currentAllocations: Allocation[],
    instrumentId: string,
): Allocation[] => {
    const instrumentIds = currentAllocations
        .filter(a => a.fund_category === 'SELF_SELECT')
        .map(c => c.fund_id)
        .concat(instrumentId)
    const uniqueInstrumentIds = [...new Set(instrumentIds)]

    return setSelfSelectFunds(currentAllocations, uniqueInstrumentIds)
}

// Replace all self-select allocations with those supplied, for instruments that
// already existed, retain their allocation percentage, for new ones set it to
// 0%
export const setSelfSelectFunds = (allocations: Allocation[], instrumentIds: string[]): Allocation[] => {
    const otherAllocations = allocations.filter(a => a.fund_category !== 'SELF_SELECT')
    const keepingAllocations = allocations.filter(
        a => a.fund_category === 'SELF_SELECT' && instrumentIds.includes(a.fund_id),
    )
    const addingAllocations = instrumentIds
        .filter(fund_id => !keepingAllocations.map(a => a.fund_id).includes(fund_id))
        .map<Allocation>(fund_id => ({
            fund_id,
            fund_category: 'SELF_SELECT',
            allocation_percent: '0',
        }))

    // Keep non self-select allocations
    return rebalanceBasePercent(otherAllocations.concat(keepingAllocations).concat(addingAllocations))
}

// Replace all self-select allocations with those supplied (including their
// percentages) then recalculate a new base fund percentage based off this data.
export const setSelfSelectAllocations = (
    allocations: Allocation[],
    percentages: {[instrumentId: string]: string},
): Allocation[] => {
    return rebalanceBasePercent(
        allocations
            .filter(a => a.fund_category !== 'SELF_SELECT')
            .concat(
                Object.entries(percentages).map(([fund_id, allocation_percent]) => ({
                    fund_id,
                    allocation_percent,
                    fund_category: 'SELF_SELECT',
                })),
            ),
    )
}

// Remove a single fund from allocations
export const removeFundFromAllocations = (allocations: Allocation[], fundIdToRemove: string): Allocation[] => {
    return rebalanceBasePercent(allocations.filter(a => a.fund_id !== fundIdToRemove))
}
