import uuid4 from 'uuid/v4'
import * as api from '~/api/retail'
import {POST_MAP, Request, Response} from '~/api/retail/types'
import * as rollbar from '~/api/rollbar/rollbar'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {APINetworkError, NonRollbarError, UserSuitableError} from '~/global/utils/error-handling/errorHandling'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {reauthenticateReturn} from '~/global/wrappers/global-wrapper-widgets/reauthenticate/Reauthenticate'
import {generateNavigationDirective, NavigationDirective} from '~/migrate-react-router'
import {FundHolding} from '~/store/identity/types'
import identityActions from '../identity/actions'
import {actingAsID} from '../identity/selectors'
import {createAction, ActionsUnion} from '../redux-tools'
import {ThunkAction, Dispatch, RootState} from '../types'
import {
    State,
    StagedBuyOrder,
    StagedSellOrder,
    StagedApplication,
    Application,
    ApplicationSubmitResponse,
    ApplicationAlert,
} from './types'

export const KS_PORTFOLIO_ORDER_COST_SELL_ENDPOINT = 'kiwisaver/portfolio-order-cost-sell'
export const ORDER_COST_SELL_ENDPOINT = 'order/cost-sell'
export const KS_PORTFOLIO_ORDER_CREATE_SELL_ENDPOINT = 'kiwisaver/portfolio-order-create-sell'
export const ORDER_CREATE_SELL_ENDPOINT = 'order/create-sell'

const actions = {
    InitialiseStagedBuyOrder: (fundId: string, buyOrderType: StagedBuyOrder['orderType'], autoExercise?: boolean) =>
        createAction('order.InitialiseStagedBuyOrder', {fundId, buyOrderType, autoExercise}),
    ClearStagedBuyOrder: () => createAction('order.ClearStagedBuyOrder'),
    UpdateStagedBuyOrder: (stagedOrder: StagedBuyOrder) => createAction('order.UpdateStagedBuyOrder', stagedOrder),
    UpdateBuyPushedHistory: () => createAction('order.UpdateBuyPushedHistory'),
    InitialiseStagedSellOrder: (
        fundId: string,
        sellOrderType: StagedSellOrder['orderType'],
        ksFundHolding?: FundHolding,
    ) => createAction('order.InitialiseStagedSellOrder', {fundId, sellOrderType, ksFundHolding}),
    ClearStagedSellOrder: () => createAction('order.ClearStagedSellOrder'),
    UpdateStagedSellOrder: (stagedOrder: StagedSellOrder) => createAction('order.UpdateStagedSellOrder', stagedOrder),
    SetSellOrderAcceptableDP: (fundId: string, acceptableDP: string) =>
        createAction('order.SetSellOrderAcceptableDP', {fundId, acceptableDP}),
    SetSellOrderAcceptableDPLoadingState: (loadingState: State['sellOrderAcceptableDPLoadingState']) =>
        createAction('order.SetSellOrderAcceptableDPLoadingState', loadingState),
    SetUpdateApplicationsLoadingState: (loadingState: State['applicationsByInstrumentLoadingState']) =>
        createAction('order.SetUpdateApplicationsLoadingState', {loadingState}),
    UpdateApplications: (
        instrumentId: string,
        applications: Application[],
        recentApplications: Application[],
        alert?: ApplicationAlert,
        introHeaderHTML?: string,
        introFooterHTML?: string,
        offerDetailsHTML?: string,
    ) =>
        createAction('order.UpdateApplications', {
            instrumentId,
            applications,
            recentApplications,
            alert,
            introHeaderHTML,
            introFooterHTML,
            offerDetailsHTML,
        }),
    InitialiseStagedApplication: (applicationRule: Application) =>
        createAction('order.InitialiseStagedApplication', applicationRule),
    ClearStagedApplication: () => createAction('order.ClearStagedApplication'),
    UpdateStagedApplication: (stagedApplication: StagedApplication) =>
        createAction('order.UpdateStagedApplication', stagedApplication),
    UpdatePushedFromOtherApplication: () => createAction('order.UpdatePushedFromOtherApplication'),
}

const formatBuyOrder = (
    stagedBuyOrder: StagedBuyOrder,
): Request.OrderCostBuy['order'] | Request.OrderCreateBuy['order'] => {
    const type = stagedBuyOrder.orderType
    if (type === 'dollar_market' && stagedBuyOrder.orderCurrencyAmount) {
        return {
            type,
            currency_amount: stagedBuyOrder.orderCurrencyAmount,
            is_extended_hours: stagedBuyOrder.extendedHours,
            is_auto_exercise: stagedBuyOrder.autoExercise,
        }
    }
    if (type === 'dollar_limit' && stagedBuyOrder.orderCurrencyAmount && stagedBuyOrder.orderPriceLimit) {
        return {
            type,
            currency_amount: stagedBuyOrder.orderCurrencyAmount,
            price_limit: stagedBuyOrder.orderPriceLimit,
            is_auto_exercise: stagedBuyOrder.autoExercise,
        }
    }
    if (type === 'dollar_trigger' && stagedBuyOrder.orderCurrencyAmount && stagedBuyOrder.orderTriggerPrice) {
        return {
            type,
            currency_amount: stagedBuyOrder.orderCurrencyAmount,
            price_limit: stagedBuyOrder.orderPriceLimit,
            trigger_price: stagedBuyOrder.orderTriggerPrice,
        }
    }
    if (type === 'share_limit' && stagedBuyOrder.orderShareAmount && stagedBuyOrder.orderPriceLimit) {
        return {
            type,
            share_amount: stagedBuyOrder.orderShareAmount,
            price_limit: stagedBuyOrder.orderPriceLimit,
            is_extended_hours: stagedBuyOrder.extendedHours,
        }
    }
    throw new Error('stagedOrder did not have all the required values set')
}

const formatSellOrder = (
    stagedSellOrder: StagedSellOrder,
): Request.OrderCostSell['order'] | Request.OrderCreateSell['order'] => {
    const type = stagedSellOrder.orderType
    if (type === 'share_market' && stagedSellOrder.orderShareAmount) {
        return {
            type,
            share_amount: stagedSellOrder.orderShareAmount,
            is_extended_hours: stagedSellOrder.extendedHours,
        }
    }
    if (type === 'share_limit' && stagedSellOrder.orderShareAmount && stagedSellOrder.orderPriceLimit) {
        return {
            type,
            share_amount: stagedSellOrder.orderShareAmount,
            price_limit: stagedSellOrder.orderPriceLimit,
            is_extended_hours: stagedSellOrder.extendedHours,
        }
    }
    if (type === 'share_trigger' && stagedSellOrder.orderShareAmount && stagedSellOrder.orderTriggerPrice) {
        return {
            type,
            trigger_price: stagedSellOrder.orderTriggerPrice,
            share_amount: stagedSellOrder.orderShareAmount,
            price_limit: stagedSellOrder.orderPriceLimit,
        }
    }
    throw new Error('stagedOrder did not have all the required values set')
}

const thunkActions = {
    // Returns true on successful costing (indicating the user should be redirected)
    CostBuyOrder(): ThunkAction<Promise<NavigationDirective | void>> {
        return async (dispatch, getState) => {
            const {order} = getState()
            if (!order.stagedBuyOrder) {
                throw new Error('stagedBuyOrder should be set whenever CostBuyOrder is called')
            }
            if (order.stagedBuyOrder.state !== 'initialised') {
                throw new Error('stagedBuyOrder is already costed')
            }

            const payload: Request.OrderCostBuy = {
                fund_id: order.stagedBuyOrder.fundId,
                acting_as_id: actingAsID(getState()),
                order: formatBuyOrder(order.stagedBuyOrder),
            }
            const endpoint = 'order/cost-buy'
            let response

            try {
                response = await api.post(endpoint, payload)
            } catch (e: unknown) {
                if (
                    e instanceof Error ||
                    e instanceof UserSuitableError ||
                    e instanceof APINetworkError ||
                    e instanceof NonRollbarError
                ) {
                    dispatch(actions.UpdateStagedBuyOrder({...order.stagedBuyOrder, error: e.message}))
                }
                return
            }

            switch (response.type) {
                case 'order_cost_buy':
                    dispatch(
                        actions.UpdateStagedBuyOrder({
                            ...order.stagedBuyOrder,
                            state: 'costed',
                            totalCost: response.total_cost,
                            paymentBreakdown: response.payment_breakdown,
                            idempotencyKey: uuid4(), // this gets sent with the create action later to ensure duplicate buy requests don't happen
                            expectedFee: response.expected_fee, // throw away our calculations, use backend as source of truth for confirm page
                            coverageToHold: response.coverage_to_hold,
                            coverageFxRate: response.coverage_fx_rate,
                        }),
                    )
                    return generateNavigationDirective('buy/confirm')
                case 'order_create_buy_error':
                    return handleBuyError(
                        order.stagedBuyOrder,
                        response.error,
                        'CostBuyOrder',
                        payload,
                        endpoint,
                        dispatch,
                    )
                case 'authentication_update_required':
                    return handleBuyReauthentication(
                        order.stagedBuyOrder,
                        'CostBuyOrder',
                        this.CostBuyOrder,
                        dispatch,
                        getState,
                    )
                case 'account_restricted':
                    throw new Error('Restricted accounts should not be able to cost an order')
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
            return
        }
    },
    CreateBuyOrder(): ThunkAction<Promise<NavigationDirective | void>> {
        return async (dispatch, getState) => {
            const {order} = getState()

            if (!order.stagedBuyOrder) {
                throw new Error('stagedBuyOrder should be set whenever CreateBuyOrder is called')
            }
            if (
                order.stagedBuyOrder.state !== 'costed' ||
                !order.stagedBuyOrder.paymentBreakdown ||
                !order.stagedBuyOrder.expectedFee ||
                !order.stagedBuyOrder.idempotencyKey
            ) {
                throw new Error('stagedBuyOrder should be at state costed and have the required data')
            }

            rudderTrack('buy', 'order_confirmed', {
                instrument_id: order.stagedBuyOrder.fundId,
                dividend_reinvest: !!order.stagedBuyOrder.dividendReinvest,
            })

            const payload: Request.OrderCreateBuy = {
                fund_id: order.stagedBuyOrder.fundId,
                acting_as_id: actingAsID(getState()),
                order: formatBuyOrder(order.stagedBuyOrder),
                idempotency_key: order.stagedBuyOrder.idempotencyKey,
                payment_breakdown: order.stagedBuyOrder.paymentBreakdown,
                expected_fee: order.stagedBuyOrder.expectedFee,
                dividend_reinvest_id: order.stagedBuyOrder.dividendReinvestId,
                coverage_fx_rate: order.stagedBuyOrder.coverageFxRate,
            }
            const endpoint = 'order/create-buy'
            let response

            try {
                response = await api.post(endpoint, payload)
            } catch (e: unknown) {
                if (
                    e instanceof Error ||
                    e instanceof UserSuitableError ||
                    e instanceof APINetworkError ||
                    e instanceof NonRollbarError
                ) {
                    dispatch(
                        actions.UpdateStagedBuyOrder({
                            ...order.stagedBuyOrder,
                            error: e.message,
                        }),
                    )
                }
                return
            }

            switch (response.type) {
                case 'identity_authenticated':
                    sendWrapperAppMessage({type: 'identityUpdated'})
                    dispatch(
                        actions.UpdateStagedBuyOrder({
                            ...order.stagedBuyOrder,
                            state: 'placed',
                        }),
                    )
                    dispatch(identityActions.handleIdentityResponse(response))
                    return
                case 'order_create_buy_error':
                    handleBuyError(order.stagedBuyOrder, response.error, 'CreateBuyOrder', payload, endpoint, dispatch)
                    return
                case 'authentication_update_required':
                    return handleBuyReauthentication(
                        order.stagedBuyOrder,
                        'CreateBuyOrder',
                        this.CreateBuyOrder,
                        dispatch,
                        getState,
                    )
                case 'account_restricted':
                    throw new Error('Restricted accounts should not be able to create an order')
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }

            return
        }
    },
    SubmitPDSAgreement(stagedBuyOrder: StagedBuyOrder): ThunkAction<Promise<null | string | NavigationDirective>> {
        return async (dispatch, getState) => {
            try {
                if (!stagedBuyOrder || !stagedBuyOrder.needReadPDS) {
                    throw new Error('stagedBuyOrder should be set whenever SubmitPDSAgreement is called')
                }

                const {fundId} = stagedBuyOrder
                const {pds_file_revision_id} = stagedBuyOrder.needReadPDS
                const response = await api.post('order/mark-pds-read', {
                    fund_id: fundId,
                    acting_as_id: actingAsID(getState()),
                    pds_file_revision_id,
                })

                switch (response.type) {
                    case 'empty':
                        return (await dispatch(this.CostBuyOrder())) || null
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(response)
                }
            } catch (e) {
                return unknownErrorMessage
            }

            return null
        }
    },
    GetSellOrderAcceptableDP(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const state = getState()
            if (!state.order.stagedSellOrder) {
                throw new Error('stagedSellOrder should be set whenever GetSellOrderAcceptableDP is called')
            }

            dispatch(actions.SetSellOrderAcceptableDPLoadingState('loading'))

            const payload: Request.OrderAcceptableSellDecimalPlaces = {
                fund_id: state.order.stagedSellOrder.fundId,
                acting_as_id: actingAsID(state),
            }
            const endpoint = 'order/sell-order-acceptable-dp'
            const response = await api.post(endpoint, payload)

            switch (response.type) {
                case 'acceptable_sell_decimal_places':
                    dispatch(
                        actions.SetSellOrderAcceptableDP(state.order.stagedSellOrder.fundId, response.decimal_places),
                    )
                    dispatch(actions.SetSellOrderAcceptableDPLoadingState('ready'))
                    return
                case 'error':
                    rollbar.sendError(response.code, {
                        statusText: response.message,
                        method: 'GetSellOrderAcceptableDP',
                    })
                    dispatch(actions.SetSellOrderAcceptableDPLoadingState('error'))
                    return
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
            return
        }
    },
    CostSellOrder(): ThunkAction<Promise<NavigationDirective | void>> {
        return async (dispatch, getState) => {
            const {order, instrument} = getState()
            if (!order.stagedSellOrder) {
                throw new Error('stagedSellOrder should be set whenever CostSellOrder is called')
            }
            if (order.stagedSellOrder.state !== 'initialised') {
                throw new Error('stagedSellOrder is already costed')
            }

            const payload: Request.OrderCostSell = {
                fund_id: order.stagedSellOrder.fundId,
                acting_as_id: actingAsID(getState()),
                order: formatSellOrder(order.stagedSellOrder),
            }
            let endpoint: Extract<
                typeof ORDER_COST_SELL_ENDPOINT | typeof KS_PORTFOLIO_ORDER_COST_SELL_ENDPOINT,
                keyof POST_MAP
            > = ORDER_COST_SELL_ENDPOINT

            // for KS checking ksFundHolding
            if (order.stagedSellOrder.ksFundHolding) {
                endpoint = KS_PORTFOLIO_ORDER_COST_SELL_ENDPOINT
            }
            let response

            try {
                response = await api.post(endpoint, payload)
            } catch (e: unknown) {
                if (
                    e instanceof Error ||
                    e instanceof UserSuitableError ||
                    e instanceof APINetworkError ||
                    e instanceof NonRollbarError
                ) {
                    dispatch(actions.UpdateStagedSellOrder({...order.stagedSellOrder, error: e.message}))
                }
                return
            }

            switch (response.type) {
                case 'order_cost_sell':
                    dispatch(
                        actions.UpdateStagedSellOrder({
                            ...order.stagedSellOrder,
                            state: 'costed',
                            idempotencyKey: uuid4(), // this gets sent with the create action later to ensure duplicate buy requests don't happen
                        }),
                    )
                    return generateNavigationDirective('sell/confirm')
                case 'ks_portfolio_order_cost_sell':
                    dispatch(
                        actions.UpdateStagedSellOrder({
                            ...order.stagedSellOrder,
                            state: 'costed',
                            idempotencyKey: uuid4(), // this gets sent with the create action later to ensure duplicate another requests don't happen
                        }),
                    )
                    const {urlSlug} = instrument.underlyingInstrumentById[order.stagedSellOrder.fundId]
                    return generateNavigationDirective('kiwisaver/:urlSlug/sell/confirm', {}, {urlSlug})
                case 'order_create_sell_error':
                    handleSellError(order.stagedSellOrder, response.error, 'CostSellOrder', payload, endpoint, dispatch)
                    return
                case 'authentication_update_required':
                    return handleSellReauthentication(
                        order.stagedSellOrder,
                        'CostSellOrder',
                        this.CostSellOrder,
                        dispatch,
                        getState,
                    )
                case 'account_restricted':
                    throw new Error('Restricted accounts should not be able to cost an order')
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
            return
        }
    },

    CreateSellOrder(): ThunkAction<Promise<NavigationDirective | void>> {
        return async (dispatch, getState) => {
            const {order} = getState()
            if (!order.stagedSellOrder) {
                throw new Error('stagedSellOrder should be set whenever CreateSellOrder is called')
            }
            if (order.stagedSellOrder.state !== 'costed' || !order.stagedSellOrder.idempotencyKey) {
                throw new Error('stagedSellOrder should be at state costed and have the required data')
            }

            rudderTrack('sell', 'order_confirmed', {instrument_id: order.stagedSellOrder.fundId})

            const payload: Request.OrderCreateSell = {
                fund_id: order.stagedSellOrder.fundId,
                acting_as_id: actingAsID(getState()),
                order: formatSellOrder(order.stagedSellOrder),
                idempotency_key: order.stagedSellOrder.idempotencyKey,
            }

            let endpoint: Extract<
                typeof ORDER_CREATE_SELL_ENDPOINT | typeof KS_PORTFOLIO_ORDER_CREATE_SELL_ENDPOINT,
                keyof POST_MAP
            > = ORDER_CREATE_SELL_ENDPOINT

            // for KS checking ksFundHolding
            if (order.stagedSellOrder.ksFundHolding) {
                endpoint = KS_PORTFOLIO_ORDER_CREATE_SELL_ENDPOINT
            }
            let response

            try {
                response = await api.post(endpoint, payload)
            } catch (e: unknown) {
                if (
                    e instanceof Error ||
                    e instanceof UserSuitableError ||
                    e instanceof APINetworkError ||
                    e instanceof NonRollbarError
                ) {
                    dispatch(actions.UpdateStagedSellOrder({...order.stagedSellOrder, error: e.message}))
                }
                return
            }

            switch (response.type) {
                case 'identity_authenticated':
                    sendWrapperAppMessage({type: 'identityUpdated'})
                    dispatch(actions.UpdateStagedSellOrder({...order.stagedSellOrder, state: 'placed'}))
                    dispatch(identityActions.handleIdentityResponse(response))
                    return
                case 'kiwisaver_customer':
                    dispatch(actions.UpdateStagedSellOrder({...order.stagedSellOrder, state: 'placed'}))
                    return
                case 'order_create_sell_error':
                    handleSellError(order.stagedSellOrder, response.error, 'CostSellOrder', payload, endpoint, dispatch)
                    return
                case 'authentication_update_required':
                    return handleSellReauthentication(
                        order.stagedSellOrder,
                        'CreateSellOrder',
                        this.CreateSellOrder,
                        dispatch,
                        getState,
                    )
                case 'account_restricted':
                    throw new Error('Restricted accounts should not be able to create an order')
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }

            return
        }
    },

    LoadInstrumentApplications(instrumentId: string): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            let response

            dispatch(actions.SetUpdateApplicationsLoadingState('loading'))

            try {
                response = await api.get('order/applications', {
                    fund_id: instrumentId,
                    acting_as_id: actingAsID(getState()),
                })
            } catch (e) {
                dispatch(actions.SetUpdateApplicationsLoadingState('error'))
                return
            }

            switch (response.type) {
                case 'order_applications':
                    dispatch(
                        actions.UpdateApplications(
                            instrumentId,
                            response.applications,
                            response.recent_applications,
                            response.alert,
                            response.intro_header_html,
                            response.intro_footer_html,
                            response.offer_details_html,
                        ),
                    )
                    return
                case 'internal_server_error':
                    dispatch(actions.SetUpdateApplicationsLoadingState('error'))
                    return
                default:
                    assertNever(response)
            }
        }
    },

    CostApplication(): ThunkAction<Promise<ApplicationSubmitResponse>> {
        return async (dispatch, getState) => {
            const {order} = getState()
            if (!order.stagedApplication) {
                throw new Error('stagedApplication should be set whenever CostApplication is dispatched')
            }
            if (order.stagedApplication.state !== 'initialised') {
                throw new Error('stagedApplication is already costed')
            }

            const payload: Request.OrderCostApplication = {
                acting_as_id: actingAsID(getState()),
                application_rule_id: order.stagedApplication.applicationRule.application_rule_id,
                answers: order.stagedApplication.answers,
            }

            const endpoint = 'order/cost-application'
            let response

            try {
                response = await api.post(endpoint, payload)
            } catch (e: unknown) {
                if (
                    e instanceof Error ||
                    e instanceof UserSuitableError ||
                    e instanceof APINetworkError ||
                    e instanceof NonRollbarError
                ) {
                    dispatch(actions.UpdateStagedApplication({...order.stagedApplication, error: e.message}))
                }
                return
            }

            switch (response.type) {
                case 'form_errors':
                    return response.errors
                case 'order_cost_application':
                    dispatch(
                        actions.UpdateStagedApplication({
                            ...order.stagedApplication,
                            state: 'costed',
                            cashPayment:
                                response.total_cash_payment &&
                                response.cash_payment_currency &&
                                response.cash_payment_breakdown
                                    ? {
                                          total: response.total_cash_payment,
                                          currency: response.cash_payment_currency,
                                          breakdown: response.cash_payment_breakdown,
                                      }
                                    : undefined,
                            outcomes: response.outcomes.map(outcome => ({
                                outcomeRuleId: outcome.outcome_rule_id,
                                fundId: outcome.fund_id,
                                currency: outcome.currency,
                                amountPerInputUnit: outcome.amount_per_input_unit,
                                grossAmount: outcome.gross_amount,
                            })),
                        }),
                    )
                    return generateNavigationDirective('apply/confirm')
                case 'order_create_application_error':
                    handleApplicationError(
                        order.stagedApplication,
                        response.error,
                        'CostApplication',
                        payload,
                        endpoint,
                        dispatch,
                    )
                    return
                case 'authentication_update_required':
                    return handleApplicationReauthentication(
                        order.stagedApplication,
                        'CostApplication',
                        this.CostApplication,
                        dispatch,
                        getState,
                    )
                case 'account_restricted':
                    throw new Error('Restricted accounts should not be able to cost an application')
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
            return
        }
    },
    ValidateApplication(): ThunkAction<Promise<ApplicationSubmitResponse>> {
        return async (dispatch, getState) => {
            const {order} = getState()
            if (!order.stagedApplication) {
                throw new Error('stagedApplication should be set whenever ValidateApplication is dispatched')
            }
            if (order.stagedApplication.state !== 'initialised') {
                throw new Error('stagedApplication is already validated')
            }

            const payload: Request.OrderCostApplication = {
                acting_as_id: actingAsID(getState()),
                application_rule_id: order.stagedApplication.applicationRule.application_rule_id,
                answers: order.stagedApplication.answers,
            }

            const endpoint = 'order/validate-application'
            let response

            try {
                response = await api.post(endpoint, payload)
            } catch (e: unknown) {
                if (
                    e instanceof Error ||
                    e instanceof UserSuitableError ||
                    e instanceof APINetworkError ||
                    e instanceof NonRollbarError
                ) {
                    dispatch(actions.UpdateStagedApplication({...order.stagedApplication, error: e.message}))
                }
                return
            }

            switch (response.type) {
                case 'form_errors':
                    return response.errors
                case 'order_validate_application':
                    dispatch(
                        actions.UpdateStagedApplication({
                            ...order.stagedApplication,
                            state: 'costed',
                            outcomes: [],
                        }),
                    )
                    return generateNavigationDirective('apply/confirm')
                case 'order_create_application_error':
                    handleApplicationError(
                        order.stagedApplication,
                        response.error,
                        'ValidateApplication',
                        payload,
                        endpoint,
                        dispatch,
                    )
                    return
                case 'authentication_update_required':
                    return handleApplicationReauthentication(
                        order.stagedApplication,
                        'ValidateApplication',
                        this.ValidateApplication,
                        dispatch,
                        getState,
                    )
                case 'account_restricted':
                    throw new Error('Restricted accounts should not be able to cost an application')
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
            return
        }
    },
    CreateApplication(): ThunkAction<Promise<ApplicationSubmitResponse>> {
        return async (dispatch, getState) => {
            const {order} = getState()

            if (!order.stagedApplication) {
                throw new Error('stagedApplication should be set whenever CreateApplication is called')
            }
            if (order.stagedApplication.state !== 'costed' || !order.stagedApplication.outcomes) {
                throw new Error('stagedApplication is should be at a state costed and have the required data')
            }

            const payload: Request.OrderCreateApplication = {
                acting_as_id: actingAsID(getState()),
                application_rule_id: order.stagedApplication.applicationRule.application_rule_id,
                answers: order.stagedApplication.answers,
                cash_payment_breakdown: order.stagedApplication.cashPayment?.breakdown,
            }
            const endpoint = 'order/create-application'
            let response

            try {
                response = await api.post(endpoint, payload)
            } catch (e: unknown) {
                if (
                    e instanceof Error ||
                    e instanceof UserSuitableError ||
                    e instanceof APINetworkError ||
                    e instanceof NonRollbarError
                ) {
                    dispatch(
                        actions.UpdateStagedApplication({
                            ...order.stagedApplication,
                            error: e.message,
                        }),
                    )
                }
                return
            }

            switch (response.type) {
                case 'form_errors':
                    return response.errors
                case 'identity_authenticated':
                    dispatch(
                        actions.UpdateStagedApplication({
                            ...order.stagedApplication,
                            state: 'placed',
                        }),
                    )
                    dispatch(identityActions.handleIdentityResponse(response))
                    return
                case 'order_create_application_error':
                    handleApplicationError(
                        order.stagedApplication,
                        response.error,
                        'CreateApplication',
                        payload,
                        endpoint,
                        dispatch,
                    )
                    return
                case 'authentication_update_required':
                    return handleApplicationReauthentication(
                        order.stagedApplication,
                        'CreateApplication',
                        this.CreateApplication,
                        dispatch,
                        getState,
                    )
                case 'account_restricted':
                    throw new Error('Restricted accounts should not be able to create an order')
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }

            return
        }
    },
}

const handleBuyError = (
    stagedBuyOrder: StagedBuyOrder,
    error: Response.OrderCreateBuyError['error'],
    method: string,
    payload: Request.OrderCostBuy | Request.OrderCreateBuy,
    endpoint: string,
    dispatch: Dispatch,
): NavigationDirective | void => {
    switch (error.type) {
        case 'need_read_pds':
            dispatch(actions.UpdateStagedBuyOrder({...stagedBuyOrder, needReadPDS: error.pds}))
            if (stagedBuyOrder.pushedHistory) {
                return generateNavigationDirective('buy/accept-pds', {replace: true})
            } else {
                dispatch(actions.UpdateBuyPushedHistory())
                return generateNavigationDirective('buy/accept-pds')
            }
            return
        case 'need_nz_tax_details':
        case 'need_au_tax_details':
            dispatch(actions.UpdateBuyPushedHistory())
            return generateNavigationDirective('buy/tax-info')
        case 'fx_insufficient_funds':
        case 'insufficient_funds':
        case 'need_participant_email':
        case 'too_many_currency_conversions':
        case 'already_selling':
        case 'too_many_pending':
        case 'limit_number_of_funds':
        case 'dividend_already_invested':
            dispatch(actions.UpdateStagedBuyOrder({...stagedBuyOrder, error: error.type}))
            return
        case 'fx_rate_changed':
            dispatch(
                actions.UpdateStagedBuyOrder({
                    ...stagedBuyOrder,
                    paymentBreakdown: error.payment_breakdown,
                    error: error.type,
                }),
            )
            return
        case 'invalid_fund':
        case 'invalid_order_type':
        case 'invalid_fee':
        case 'fx_invalid_details':
        case 'not_tradable':
        case 'user_cannot_auto_exercise':
        case 'auto_exercise_no_exercise_price':
            // these errors should ideally not occur ever, report them if they do
            rollbar.sendError(error.type, {
                statusText: error.type,
                method,
                payload,
                endpoint,
            })
            dispatch(actions.UpdateStagedBuyOrder({...stagedBuyOrder, error: error.type}))
            return
        default:
            assertNever(error)
    }
    return
}

const handleBuyReauthentication = (
    stagedBuyOrder: StagedBuyOrder,
    method: string,
    actionToRetry: typeof thunkActions.CreateBuyOrder | typeof thunkActions.CostBuyOrder,
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<NavigationDirective | void> => {
    if (!stagedBuyOrder) {
        throw new Error(`stagedBuyOrder should be set whenever ${method} is called`)
    }
    // Handled via API error handler
    return reauthenticateReturn(
        () => actionToRetry()(dispatch, getState),
        () => {
            dispatch(
                actions.UpdateStagedBuyOrder({
                    ...stagedBuyOrder,
                    error: 'Please resubmit your request',
                }),
            )
            return
        },
        () => {
            dispatch(
                actions.UpdateStagedBuyOrder({
                    ...stagedBuyOrder,
                    error: 'You must enter your password before you can buy',
                }),
            )
            return
        },
    )
}

const handleSellError = (
    stagedSellOrder: StagedSellOrder,
    error: Response.OrderCreateSellError['error'],
    method: string,
    payload: Request.OrderCostSell | Request.OrderCreateSell,
    endpoint: Extract<
        | typeof ORDER_CREATE_SELL_ENDPOINT
        | typeof KS_PORTFOLIO_ORDER_CREATE_SELL_ENDPOINT
        | typeof ORDER_COST_SELL_ENDPOINT
        | typeof KS_PORTFOLIO_ORDER_COST_SELL_ENDPOINT,
        keyof POST_MAP
    >,
    dispatch: Dispatch,
): void => {
    switch (error.type) {
        case 'need_participant_email':
        case 'already_buying':
        case 'too_many_pending':
            dispatch(actions.UpdateStagedSellOrder({...stagedSellOrder, error: error.type}))
            return
        case 'too_many_decimal_places':
        case 'invalid_fund':
        case 'invalid_order_type':
        case 'insufficient_shares':
        case 'not_tradable':
            // these errors should ideally not occur ever, report them if they do
            rollbar.sendError(error.type, {
                statusText: error.type,
                method,
                payload,
                endpoint,
            })
            dispatch(actions.UpdateStagedSellOrder({...stagedSellOrder, error: error.type}))
            return
        default:
            assertNever(error)
    }
    return
}

const handleSellReauthentication = (
    stagedSellOrder: StagedSellOrder,
    method: string,

    actionToRetry: typeof thunkActions.CreateSellOrder | typeof thunkActions.CostSellOrder,
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<NavigationDirective | void> => {
    if (!stagedSellOrder) {
        throw new Error(`stagedSellOrder should be set whenever ${method} is called`)
    }

    // Handled via API error handler
    return reauthenticateReturn(
        () => actionToRetry()(dispatch, getState),
        () => {
            dispatch(
                actions.UpdateStagedSellOrder({
                    ...stagedSellOrder,
                    error: 'Please resubmit your request',
                }),
            )
            return
        },
        () => {
            dispatch(
                actions.UpdateStagedSellOrder({
                    ...stagedSellOrder,
                    error: 'You must enter your password before you can sell',
                }),
            )
            return
        },
    )
}

const handleApplicationError = (
    stagedApplication: StagedApplication,
    error: Response.OrderCreateApplicationError['error'],
    method: string,
    payload: Request.OrderCostApplication | Request.OrderCreateApplication,
    endpoint: string,
    dispatch: Dispatch,
): void => {
    switch (error.type) {
        case 'not_allowed_to_apply':
        case 'fx_insufficient_funds':
        case 'insufficient_funds':
        case 'insufficient_shares':
        case 'need_participant_email':
        case 'too_many_currency_conversions':
            dispatch(actions.UpdateStagedApplication({...stagedApplication, error: error.type}))
            return
        case 'fx_rate_changed':
            dispatch(
                actions.UpdateStagedApplication({
                    ...stagedApplication,
                    cashPayment: {
                        ...stagedApplication.cashPayment!,
                        breakdown: error.payment_breakdown,
                    },
                    error: error.type,
                }),
            )
            return
        case 'invalid_application_rule':
        case 'fx_invalid_details':
        case 'invalid_amount':
            // these errors should ideally not occur ever, report them if they do
            rollbar.sendError(error.type, {
                statusText: error.type,
                method,
                payload,
                endpoint,
            })
            dispatch(actions.UpdateStagedApplication({...stagedApplication, error: error.type}))
            return
        default:
            assertNever(error)
    }
    return
}

const handleApplicationReauthentication = (
    stagedApplication: StagedApplication,
    method: string,
    actionToRetry: typeof thunkActions.CreateApplication | typeof thunkActions.CostApplication,
    dispatch: Dispatch,
    getState: () => RootState,
): Promise<ApplicationSubmitResponse> => {
    if (!stagedApplication) {
        throw new Error(`stagedApplication should be set whenever ${method} is called`)
    }
    // Handled via API error handler
    return reauthenticateReturn(
        () => actionToRetry()(dispatch, getState),
        () => {
            dispatch(
                actions.UpdateStagedApplication({
                    ...stagedApplication,
                    error: 'Please resubmit your request',
                }),
            )
            return
        },
        () => {
            dispatch(
                actions.UpdateStagedApplication({
                    ...stagedApplication,
                    error: 'You must enter your password before you can apply',
                }),
            )
            return
        },
    )
}

export type ActionsType = ActionsUnion<typeof actions>
export default {...actions, ...thunkActions}
