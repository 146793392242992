import {Button} from '@design-system/button'
import {Modal} from '@design-system/modal'
import cn from 'classnames'
import Decimal from 'decimal.js'
import React from 'react'
import {useRetailPost} from '~/api/query/retail'
import {spacing} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {dateFormatNoTime, dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import {InvestmentActivityDetailTypes} from '~/global/utils/investment-activity/investmentActivityTypes'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import Concertina from '~/global/widgets/concertina/Concertina'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import BuyAndSellFeesListedInstruments from '~/global/widgets/help-modals/BuyAndSellFeesListedInstruments'
import SettlementDueDate from '~/global/widgets/help-modals/SettlementDueDate'
import {PriceInfoModal} from '~/global/widgets/help-modals/contract-notes/PriceInfo'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {DollarValue, FeeValue, SharePriceValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {Toast} from '~/global/widgets/toast/Toast'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {FONTERRA_COOPERATIVE_GROUP} from '~/sections/moose/sections/order/state'
import {BuyAndSellFeesForFcgInstrument} from '~/sections/moose/sections/order/widgets/buy-and-sell-fees-for-fcg-instrument/BuyAndSellFeesForFcgInstrument'
import {TransactionDetailTypes} from '~/sections/wallet/pages/transaction/utils/transaction-types/transactionTypes'
import {RecordPage, RecordRow} from '~/sections/wallet/pages/transaction/widgets/record/Record'
import styles from '~/sections/wallet/pages/transaction/widgets/record/Record.scss'
import {useDistillInstrumentWithCurrency} from '~/sections/wallet/state/distill'
import {Instrument} from '~/store/instrument/types'

type OrderDetail =
    | TransactionDetailTypes['portfolio_order']['detail']
    | InvestmentActivityDetailTypes['portfolio_order']['detail']
type OrderState = OrderDetail['state']

/**
 * Is this order state a terminal state?
 */
function isTerminal(state: OrderState): boolean {
    switch (state) {
        case 'CANCELLED':
        case 'EXPIRED':
        case 'FAILED':
        case 'FULFILLED':
        case 'REJECTED':
            return true
        case 'CANCELLING':
        case 'PENDING':
        case 'PROCESSING':
            return false
        default:
            assertNever(state)
            return false
    }
}

const getStatusLabel = (order: OrderDetail, instrument: Instrument): string => {
    const hasTrades = order.trades.length !== 0
    switch (order.state) {
        case 'CANCELLED':
            return hasTrades ? 'Order filled' : 'Cancelled'
        case 'CANCELLING':
            return hasTrades ? 'Partially filled' : 'Cancelling'
        case 'EXPIRED':
            return hasTrades ? 'Order filled' : 'Expired'
        case 'FAILED':
            return 'Failed'
        case 'FULFILLED':
            return 'Order filled'
        case 'PENDING':
            return hasTrades ? 'Partially filled' : 'Pending'
        case 'PROCESSING':
            if (instrument && tradingType(instrument) === 'listed') {
                return order.trades.length !== 0 ? 'Partially filled' : 'On market'
            }

            return 'Processing'
        case 'REJECTED':
            return 'Rejected'
        default:
            assertNever(order.state)
            return ''
    }
}

/**
 * Sum volume, value, and fee for all trades on an order
 */
function getTradeTotals({trades}: OrderDetail): {
    volume: Decimal
    gross_value: Decimal
    corporate_fee: Decimal
} {
    return {
        volume: trades.reduce((total, trade) => total.add(new Decimal(trade.volume)), new Decimal(0)),
        gross_value: trades.reduce((total, trade) => total.add(new Decimal(trade.gross_value)), new Decimal(0)),
        corporate_fee: trades.reduce((total, trade) => total.add(new Decimal(trade.corporate_fee)), new Decimal(0)),
    }
}

function getAmountToSell(order: OrderDetail) {
    if (order.limit_price) {
        const limitPrice = new Decimal(order.limit_price)
        const tradeValue = limitPrice.mul(new Decimal(order.requested_amount))
        return tradeValue.toDP(2).toString()
    }
    return order.requested_amount
}

export const PortfolioOrderRecord: React.FunctionComponent<{
    order: OrderDetail
    hidePageBack?: boolean
}> = ({order, hidePageBack}) => {
    const instrument = useDistillInstrumentWithCurrency(order.fund_id)
    const statusLabel = getStatusLabel(order, instrument)
    const tradeTotals = getTradeTotals(order)

    const [cancelModalShown, setCancelModalShown] = React.useState(false)
    const cancelOrder = useRetailPost({
        path: 'fonterra/portfolios/:portfolio_id/orders/cancel',
        pathParams: {portfolio_id: order.portfolio_id},
        queryCacheToInvalidate: [
            [`wallet/:portfolio_id/transaction`, {portfolio_id: order.portfolio_id}],
            [`wallet/:portfolio_id/transactions`, {portfolio_id: order.portfolio_id}],
            [`fonterra/portfolios/:portfolio_id/order`, {portfolio_id: order.portfolio_id, order_id: order.id}],
            ['investment-activity/:portfolio_id/activity', {portfolio_id: order.portfolio_id}],
            ['investment-activity/:portfolio_id/item', {portfolio_id: order.portfolio_id}],
        ],
    })
    const orderHasTrades = order.trades.length > 0
    const filledAllAtOnce = order.trades.length === 1 && isTerminal(order.state)
    const notificationContext = React.useContext(NotificationContext)
    const doCancelOrder = async () => {
        try {
            await cancelOrder.mutateAsync({
                order_id: order.id,
            })
            Toast('Order cancelled')
        } catch (e) {
            notificationContext.showModalError({message: 'An error occurred cancelling your order, please try again'})
        } finally {
            setCancelModalShown(false)
        }
    }

    const rows: React.ReactNode[] = []

    if (filledAllAtOnce) {
        const trade = order.trades[0]
        rows.push(<RecordRow left="Contract note" key="trade--contract-note" right={trade.contract_note_number} />)
    }

    if (order.registry_detail && order.registry_detail.reference) {
        rows.push(
            <RecordRow
                dataTestId="record-row--order-for"
                className={styles.recordRowNoBorder}
                key="for"
                left="For"
                right={order.created_for}
            />,
            <RecordRow
                dataTestId="record-row--order-registy-detail"
                key="registry_detail"
                left={order.registry_detail.type}
                right={order.registry_detail.reference}
            />,
        )
    }

    rows.push(
        <RecordRow
            dataTestId="record-row--order-placed-at"
            className={order.created_by ? styles.recordRowNoBorder : undefined}
            key="created_at"
            left="Order placed"
            right={order.created_at.toFormat(dateFormatWithYear)}
        />,
    )

    if (order.created_by) {
        rows.push(
            <RecordRow
                dataTestId="record-row--order-placed-by"
                key="created_by"
                left="by"
                right={order.created_by.name}
            />,
        )
    }

    if (order.state === 'CANCELLED' && order.complete_at) {
        rows.push(
            <RecordRow
                dataTestId="record-row--order-cancelled-at"
                className={order.cancelled_by ? styles.recordRowNoBorder : undefined}
                key="cancelled_at"
                left="Order cancelled"
                right={order.complete_at.toFormat(dateFormatWithYear)}
            />,
        )

        if (order.cancelled_by) {
            rows.push(
                <RecordRow
                    dataTestId="record-row--order-cancelled-by"
                    className={order.cancelled_by.type === 'OPERATOR' ? styles.recordRowNoBorder : undefined}
                    key="cancelled_by"
                    left="by"
                    right={order.cancelled_by.name}
                />,
            )
            if (order.cancelled_by.type === 'OPERATOR') {
                rows.push(
                    <RecordRow
                        dataTestId="record-row--order-cancelled-reason"
                        key="cancelled_reason"
                        left="reason"
                        right={order.cancelled_reason}
                    />,
                )
            }
        }
    }

    if (order.state === 'EXPIRED' && order.complete_at) {
        rows.push(
            <RecordRow
                dataTestId="record-row--order-cancelled-at"
                key="expired_at"
                left="Order expired"
                right={order.complete_at.toFormat(dateFormatWithYear)}
            />,
        )
    }

    if (!isTerminal(order.state) && order.expires_at) {
        rows.push(
            <RecordRow
                dataTestId="record-row--order-expires-at"
                key="expires_at"
                left="Order expires" // TODO - modal link
                right={order.expires_at.toFormat(dateFormatWithYear)}
            />,
        )
    }

    if (filledAllAtOnce) {
        const trade = order.trades[0]
        if (order.complete_at) {
            rows.push(
                <RecordRow
                    dataTestId="record-row--order-filled-at"
                    key="filled_at"
                    left="Order filled"
                    right={order.complete_at.toFormat(dateFormatWithYear)}
                />,
            )
        }

        rows.push(
            <RecordRow
                dataTestId="record-row--trade-volume"
                key="trade_volume"
                left={`${shareLabel({instrument, isPlural: true, isCapitalised: true})} ${
                    order.side === 'BUY' ? 'bought' : 'sold'
                }`}
                right={<ShareValue value={trade.volume} showFullValue />}
            />,
        )

        rows.push(
            <RecordRow
                left={`Price per ${shareLabel({instrument})}`}
                dataTestId="record-row--trade-share-price"
                key="record-row--trade-share-price"
                right={<SharePriceValue value={trade.share_price} currency={instrument.currency} />}
            />,
        )

        if (order.limit_price) {
            rows.push(
                <RecordRow
                    key="limit_price"
                    dataTestId="record-row--limit-price"
                    left="Limit price"
                    right={<SharePriceValue value={order.limit_price} currency={instrument.currency} />}
                />,
            )
        }
    } else {
        if (orderHasTrades) {
            rows.push(
                <RecordRow
                    dataTestId="record-row--trade-volume"
                    key="trade_volume"
                    left={`${shareLabel({instrument, isPlural: true, isCapitalised: true})} ${
                        order.side === 'BUY' ? 'bought' : 'sold'
                    }`}
                    right={<ShareValue value={tradeTotals.volume.toString()} showFullValue />}
                />,
            )
        }
    }

    if (orderHasTrades) {
        rows.push(
            <RecordRow
                key="will_settle_on"
                left={<SettlementDueDate jurisdiction="nz" />}
                right={order.will_settle_on ? order.will_settle_on.toFormat(dateFormatNoTime) : 'pending'}
            />,
        )
    }

    if (!orderHasTrades && order.requested_amount) {
        rows.push(
            <RecordRow
                key="requested_amount"
                dataTestId="record-row--requested-amount"
                left={`Number of ${shareLabel({instrument, isPlural: true})}`}
                right={<ShareValue value={order.requested_amount} />}
            />,
        )
    }

    if (!filledAllAtOnce && orderHasTrades && order.volume_weighted_average_price) {
        const priceModalTitle =
            order.trades?.length <= 1
                ? `Price per ${shareLabel({instrument})}`
                : `Average price per ${shareLabel({instrument})}`
        rows.push(
            <RecordRow
                key="record-row--average-price"
                dataTestId="record-row--average-price"
                left={
                    <div>
                        {priceModalTitle}
                        <PriceInfoModal
                            type={order.side === 'BUY' ? 'buy' : 'sell'}
                            instrument={instrument}
                            title={priceModalTitle}
                        />
                    </div>
                }
                right={<SharePriceValue value={order.volume_weighted_average_price} currency={instrument.currency} />}
            />,
        )
    }

    const showLimitPrice = !isTerminal(order.state) || !orderHasTrades
    if (showLimitPrice && order.limit_price && order.side === 'BUY') {
        rows.push(
            <RecordRow
                key="limit_price"
                dataTestId="record-row--limit-price"
                left={`Highest price to pay per ${shareLabel({instrument})}`}
                right={<SharePriceValue value={order.limit_price} currency={instrument.currency} />}
            />,
        )
    }
    if (showLimitPrice && order.limit_price && order.side === 'SELL') {
        rows.push(
            <RecordRow
                key="limit_price"
                dataTestId="record-row--limit-price"
                left={`Lowest price to sell per ${shareLabel({instrument})}`}
                right={<SharePriceValue value={order.limit_price} currency={instrument.currency} />}
            />,
        )
    }

    if (!filledAllAtOnce) {
        for (const trade of order.trades) {
            const isLastTrade = trade === order.trades.at(-1)

            rows.push(
                <div
                    key={`trade${trade.contract_note_number}`}
                    className={cn(spacing.spaceAbove12, {[styles.hasBottomBorder]: isLastTrade})}
                >
                    <Concertina
                        title={
                            <div className={styles.concertinaTitleWrapper}>
                                <div className={styles.titleTop}>
                                    <div className={styles.titleTopLeft}>
                                        <div className={cn(styles.ellipse, styles.darkEllipse)} />
                                        <ShareValue value={trade.volume} showFullValue />
                                        &nbsp;
                                        {shareLabel({instrument, isPlural: parseFloat(trade.volume) !== 1})}
                                    </div>
                                    <div className={styles.titleTopRight}>
                                        <DollarValue value={trade.gross_value} currency={instrument.currency} />
                                    </div>
                                </div>
                                <div
                                    className={cn(styles.titleBottom, {
                                        [styles.titleBottomBorder]: !isLastTrade,
                                    })}
                                >
                                    <p>{trade.trade_datetime.toFormat(dateFormatWithYear)}</p>
                                </div>
                            </div>
                        }
                        body={
                            <div className={cn(styles.concertinaBody)}>
                                <RecordRow left="Contract note" right={trade.contract_note_number} />
                                <RecordRow
                                    left={`Price per ${shareLabel({instrument})}`}
                                    right={<SharePriceValue value={trade.share_price} currency={instrument.currency} />}
                                />
                                {/* TODO  <RecordRow left="US Withholding tax" right="TODO" /> */}
                                {parseFloat(trade.corporate_fee) !== 0 && (
                                    <RecordRow
                                        left="Transaction fee"
                                        right={<FeeValue value={trade.corporate_fee} currency={instrument.currency} />}
                                    />
                                )}
                            </div>
                        }
                        borderLeft={!isLastTrade}
                        alignIconTop
                    />
                </div>,
            )
        }
    }

    if (!isTerminal(order.state) && orderHasTrades && order.limit_price) {
        const limitPrice = new Decimal(order.limit_price)
        const volumeRemaining = new Decimal(order.requested_amount).minus(tradeTotals.volume)
        const amountRemaining = volumeRemaining.mul(limitPrice).toDP(2).toString()
        rows.push(
            <RecordRow
                className={styles.recordRowNoBorder}
                key="order_amount_remaining"
                left="Order amount remaining"
                right={<DollarValue value={amountRemaining} currency={instrument.currency} />}
            />,
        )
        rows.push(
            <RecordRow
                key="order_volume_remaining"
                left={`Order ${shareLabel({instrument, isPlural: true})} remaining`}
                right={volumeRemaining.toString()}
            />,
        )
    }

    if (
        !isTerminal(order.state) &&
        order.amount_held_for_brokerage &&
        !new Decimal(order.amount_held_for_brokerage).isZero()
    ) {
        rows.push(
            <RecordRow
                key="corporate_fee"
                left={
                    <div>
                        Estimated transaction fee <BuyAndSellFeesListedInstruments />
                    </div>
                }
                right={<FeeValue value={order.amount_held_for_brokerage} />}
            />,
        )
    }

    if (order.fund_id === FONTERRA_COOPERATIVE_GROUP && (!isTerminal(order.state) || orderHasTrades)) {
        rows.push(<RecordRow key="corporate_fee" left={<BuyAndSellFeesForFcgInstrument />} right="Free" />)
    }

    if (isTerminal(order.state) && !tradeTotals.corporate_fee.isZero()) {
        rows.push(
            <RecordRow
                key="corporate_fee"
                left="Total transaction fee"
                right={<FeeValue value={tradeTotals.corporate_fee.toString()} />}
            />,
        )
    }

    /*
     * This list of things will be addressed over time by the Shared Money workstream
     * TODO - NZX market depth link?
     * TODO - Remaining order cancelling
     * TODO - Order amount remaining
     * TODO - Remaining shares to sell
     */

    const amountLabel = (() => {
        switch (order.side) {
            case 'BUY':
                return isTerminal(order.state) && order.trades.length > 0 ? 'Amount paid' : 'Amount'
            case 'SELL':
                return order.trades.length > 0 ? 'Amount received' : 'Shares to sell'
            default:
                assertNever(order.side)
                return ''
        }
    })()
    const amount = (() => {
        switch (order.side) {
            case 'BUY':
                return order.trades.length === 0
                    ? new Decimal(order.amount_held).toString()
                    : tradeTotals.gross_value.toString()
            case 'SELL':
                return order.trades.length === 0 ? getAmountToSell(order) : tradeTotals.gross_value.toString()
            default:
                assertNever(order.side)
                return ''
        }
    })()

    return (
        <RecordPage
            hidePageBack={hidePageBack}
            title={order.description} // This is carefully constructed on the server-side to be safe/sensible here
            header={
                instrument && (
                    <>
                        <div className={styles.orderThumb}>
                            <InstrumentLogo instrument={instrument} noBorder />
                        </div>
                        <div>
                            <h4 className={styles.instrumentName}>{instrument.name}</h4>
                            {tradingType(instrument) !== 'managed' && (
                                <p className={styles.instrumentSymbol}>
                                    <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                                    <PronounceLetters text={instrument.exchange} />
                                </p>
                            )}
                        </div>
                    </>
                )
            }
            rows={rows}
            amountLabel={amountLabel}
            amount={amount}
            amountCurrency={instrument.currency}
            statusLabel={statusLabel}
            footer={
                <>
                    {['PENDING', 'PROCESSING'].includes(order.state) && (
                        <Button
                            label="Cancel order"
                            type="secondary"
                            dataTestId="button--cancel-order"
                            additionalClassName={cn(spacing.spaceBelow32)}
                            onClick={() => setCancelModalShown(true)}
                        />
                    )}
                    {(order.state !== 'CANCELLED' || order.trades.length > 1) && (
                        <div data-testid="footer--clearing-participant-message">
                            <p>
                                Sharesies Limited is the Clearing Participant. Sharesies Limited may also act as the
                                agent for the buyer and seller of this investment.
                            </p>
                        </div>
                    )}
                    <Modal
                        isAlert
                        isOpen={cancelModalShown}
                        setIsOpen={setCancelModalShown}
                        title="Cancel order"
                        dataTestId="modal--cancel-portfolio-order"
                        primaryButton={{
                            label: 'Cancel order',
                            onClick: () => {
                                doCancelOrder()
                            },
                        }}
                        secondaryButton={{label: 'Cancel'}}
                    >
                        <p>
                            <strong>Are you sure you want to cancel this order?</strong>
                        </p>
                    </Modal>
                </>
            }
        />
    )
}
