import {ModalLink} from '@design-system/modal'
import Decimal from 'decimal.js'
import {DateTime} from 'luxon'
import React from 'react'
import {Link} from 'react-router-dom'
import {Model} from '~/api/retail/types'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import useAnnouncedCorporateActions from '~/global/state-hooks/retail/useAnnouncedCorporateActions'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {isUninitialised} from '~/global/utils/is-loading/isLoading'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {DIVIDEND_LIKE_TYPES} from '~/global/widgets/corporate-action-description/corporateActionsV2'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue, PercentValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {useDistillInstrumentHistoricVwap} from '~/sections/invest/sections/employee-share-scheme/data/distill'
import allocationStyles from '~/sections/invest/sections/view-instrument/sections/employee-share-scheme/pages/share-scheme-allocation/ShareSchemeAllocation.scss'
import AlignmentRightsUnitName from '~/sections/invest/sections/view-instrument/sections/employee-share-scheme/widgets/alignment-rights-unit-name/AlignmentRightsUnitName'
import essActions from '~/store/employeeShareScheme/actions'
import {AlignmentRightAllocation} from '~/store/employeeShareScheme/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import {RecordRow} from './common'
import styles from './Record.scss'

interface InstrumentActivityRecordShareSchemeAlignmentRightAllocationProps {
    instrument: Instrument
    allocationLine: Model.ESSAlignmentRightAllocationLine
}

const InstrumentActivityRecordShareSchemeAlignmentRightAllocation: React.FunctionComponent<InstrumentActivityRecordShareSchemeAlignmentRightAllocationProps> =
    React.memo(({instrument, allocationLine}) => {
        const dispatch = useAppDispatch()

        let allocation: AlignmentRightAllocation | undefined

        // fetch the allocations
        const shareSchemeAllocationsByInstrument = useAppSelector(
            s => s.employeeShareScheme.shareSchemeAllocationsByInstrument,
        )
        const allocationsLoadingState = useAppSelector(s => s.employeeShareScheme.allocationsLoadingState)
        const allocationsForInstrument = shareSchemeAllocationsByInstrument[instrument.id]
        const allocationVested = !!allocationLine.vesting_date

        React.useEffect(() => {
            if (isUninitialised(allocationsLoadingState)) {
                dispatch(essActions.FetchParticipatingShareSchemes())
            }
        }, [instrument.id])

        if (!allocationsForInstrument || !instrument) {
            return <Loading isPineapple />
        }

        for (const currentAllocation of allocationsForInstrument.alignment_right_allocations) {
            const line = currentAllocation.lines.find(line => line.id === allocationLine.id)
            if (line) {
                allocation = currentAllocation
                break
            }
        }

        if (!allocation) {
            return <WeSlippedUp />
        }

        const getAllocationPerformanceMetric = () => {
            if (!allocation) {
                return ''
            }
            switch (allocation.allocation_type) {
                case 'COOP_UNIT':
                    if (allocationLine.vesting_date && allocationLine.value_per_alignment_right_vested) {
                        return (
                            <RecordRow
                                left="VWAP per share"
                                right={
                                    <DollarValue
                                        value={allocationLine.value_per_alignment_right_vested}
                                        currency={instrument.currency}
                                    />
                                }
                            />
                        )
                    } else {
                        return (
                            <RecordRow
                                left="VWAP per share at grant"
                                right={
                                    <DollarValue
                                        value={allocationLine.value_per_alignment_right}
                                        currency={instrument.currency}
                                    />
                                }
                            />
                        )
                    }
                case 'FARM_UNIT':
                    if (allocationLine.vesting_date && allocationLine.value_per_alignment_right_vested) {
                        return (
                            <RecordRow
                                left="Operating profit per/ha"
                                right={
                                    <DollarValue
                                        value={allocationLine.value_per_alignment_right_vested}
                                        currency={instrument.currency}
                                    />
                                }
                            />
                        )
                    } else {
                        return (
                            <RecordRow
                                left="Operating profit per/ha at grant"
                                right={
                                    <DollarValue
                                        value={allocationLine.value_per_alignment_right}
                                        currency={instrument.currency}
                                    />
                                }
                            />
                        )
                    }
                default:
                    return ''
            }
        }

        return (
            <>
                <div className={styles.orderContent}>
                    <div className={styles.orderTitleBlock}>
                        <div className={styles.orderThumb}>
                            <InstrumentLogo instrument={instrument} noBorder />
                        </div>
                        <div>
                            <h4 className={styles.instrumentName}>{instrument.name}</h4>
                            {tradingType(instrument) !== 'managed' && !isInstrumentInNoTrade(instrument) && (
                                <p className={styles.instrumentSymbol}>
                                    <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                                    <PronounceLetters text={instrument.exchange} />
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={styles.recordRowContainer} data-testid="contract-note--record-row-container">
                        <RecordRow
                            left="Date awarded"
                            right={allocationLine.allocation_date?.toFormat(dateFormatNoTime)}
                        />
                        {allocationVested ? (
                            <RecordRow
                                left="Date received"
                                right={allocationLine.vesting_date?.toFormat(dateFormatNoTime)}
                            />
                        ) : (
                            <RecordRow
                                left="Vesting date"
                                right={allocationLine.vesting_due_date.toFormat(dateFormatNoTime)}
                            />
                        )}
                        {allocationVested && allocationLine.number_of_alignment_rights_vested && (
                            <RecordRow
                                left="Alignment Rights"
                                right={<ShareValue value={allocationLine.number_of_alignment_rights_vested} />}
                            />
                        )}
                        {getAllocationPerformanceMetric()}
                    </div>
                    <p className={styles.orderAmountLabel}>
                        Total <AlignmentRightsUnitName allocation={allocation} />
                    </p>
                    <div className={styles.orderFooter}>
                        <div data-testid="status-label--allocation-state">
                            <p className={styles.statusLabel}>{allocationVested ? 'Vested' : 'Unvested '}</p>
                        </div>
                        <div>
                            <p className={styles.orderAmount}>
                                {allocationVested ? (
                                    <DollarValue value={allocationLine.total_value} currency={instrument.currency} />
                                ) : (
                                    <ShareValue value={allocationLine.number_of_alignment_rights} />
                                )}
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    {allocation.allocation_type === 'COOP_UNIT' && !allocationVested && (
                        <div className={allocationStyles.container}>
                            <React.Suspense fallback={<Loading />}>
                                <AllocationTsr allocationLine={allocationLine} instrument={instrument} />
                            </React.Suspense>
                        </div>
                    )}
                    <AllocationDescription
                        allocation={allocation}
                        allocationLine={allocationLine}
                        instrumentId={instrument.id}
                    />
                </div>
            </>
        )
    })

const AllocationDescription: React.FunctionComponent<{
    allocation: AlignmentRightAllocation
    allocationLine: Model.ESSAlignmentRightAllocationLine
    instrumentId: string
}> = ({allocation, allocationLine, instrumentId}) => {
    const profileUrl = useProfileUrl()

    const getAllocationVestingText = () => {
        switch (allocation.allocation_type) {
            case 'COOP_UNIT':
                return 'The final value of your Co-op Units will depend upon the 12 month Volume Weighted Average Price of a Fonterra Co-operative Group Limited share at the time of vesting.'
            case 'FARM_UNIT':
                return 'The final value of your Farm Units will depend upon the Dairy Operating Profit per hectare calculation at the time of vesting.'
            default:
                return ''
        }
    }

    return (
        <div>
            {!allocationLine.vesting_date && (
                <div className={allocationStyles.container}>
                    <p>
                        {getAllocationVestingText()} See the{' '}
                        <Link
                            to={profileUrl('employee-share-scheme/:instrumentId/:shareSchemeId', {
                                instrumentId,
                                shareSchemeId: allocation.share_scheme_id,
                            })}
                        >
                            scheme details
                        </Link>{' '}
                        for more information on your Alignment Right allocations.
                    </p>
                </div>
            )}
        </div>
    )
}

const calculateAlignmentRightTsrPercent = function (startPrice: Decimal, endPrice: Decimal, dividendTotal: Decimal) {
    /**
     * Calculate the Total Shareholder Return for the provided values
     */
    const changeInPrice = endPrice.sub(startPrice)

    return changeInPrice.add(dividendTotal).dividedBy(startPrice).times(100)
}

const AllocationTsr: React.FunctionComponent<{
    allocationLine: Model.ESSAlignmentRightAllocationLine
    instrument: Instrument
}> = ({allocationLine, instrument}) => {
    /**
     * TSRs for Alignment Rights are based on the change in value of the VWAP of FCG,
     * not on the close price of FCG.
     *
     * We use the following values:
     *
     * Start price: allocationLine.value_per_alignment_right - this is the 12 month
     * VWAP calculated when the rights were allocated
     *
     * Ending price: The current 12 month VWAP (calculated by Distill for FCG)
     *
     * Dividends: Total value of cash dividends for the Instrument between
     * the start and end dates
     */

    // Ending price - current 12 month VWAP of Instrument
    const today = DateTime.now().startOf('day')
    const startDate = today.minus({years: 1})
    const currentVwap = useDistillInstrumentHistoricVwap(instrument.id, startDate, today).vwapPrice

    // Value of dividends
    const corporateActions = useAnnouncedCorporateActions(instrument)
    const allocationDividendTotalValue = corporateActions
        .filter(ca => DIVIDEND_LIKE_TYPES.includes(ca.action_type))
        .filter(
            dividend =>
                DateTime.fromISO(dividend.ex_date) >= allocationLine.allocation_date &&
                DateTime.fromISO(dividend.ex_date) <= today,
        )
        // TODO this has changed from grossed-up amount to declared amount
        .flatMap(dividend =>
            dividend.outcomes.filter(o => o.currency).map(outcome => new Decimal(outcome.amount_per_input_unit)),
        )
        .reduce((acc, curr) => acc.add(curr), new Decimal(0))

    // TSR
    const roundedCurrentVwap = new Decimal(currentVwap).toDP(2)
    const roundedInitialVwap = new Decimal(allocationLine.value_per_alignment_right).toDP(2)
    const roundedDividends = allocationDividendTotalValue.toDP(2)
    const tsrPercent = calculateAlignmentRightTsrPercent(roundedInitialVwap, roundedCurrentVwap, roundedDividends)

    // A human-readable breakdown of the TSR calculation for Alignment Rights
    const vwapBreakdown = (
        <>
            (
            <DollarValue value={roundedCurrentVwap.toString()} /> -{' '}
            <DollarValue value={roundedInitialVwap.toString()} /> +{' '}
            <DollarValue value={allocationDividendTotalValue.toString()} />) ÷{' '}
            <DollarValue value={roundedInitialVwap.toString()} /> = {<PercentValue value={tsrPercent.toString()} />}
        </>
    )

    return (
        <>
            <p className={allocationStyles.tsrHeading}>
                Total shareholder return (TSR){' '}
                <ModalLink
                    dataTestId="modal-link--tsr"
                    label="Total shareholder return (TSR)"
                    asIcon
                    modalTitle="Total Shareholder Return"
                    primaryButton={{label: 'Close'}}
                    helpIconSize={14}
                >
                    <p>
                        TSR is a metric used by companies and investors to measure returns to shareholders. It
                        incorporates both price changes in the instrument as well as any distribution to shareholders
                        (e.g. dividends or capital return).
                    </p>
                    <p>
                        The Co-op Unit TSR is calculated as the change in the 12-month Volume Weighted Average Price
                        (VWAP) and any shareholder distributions made between the date the allocation was awarded and
                        today’s date (until vested).
                    </p>
                    <div className={allocationStyles.tsrCalculation}>
                        <p>
                            (Current VWAP - Initial VWAP + Dividend Equivalent Payments + Other Cash Distributions) ÷
                            Initial VWAP = TSR %
                        </p>
                        <p>{vwapBreakdown}</p>
                    </div>
                </ModalLink>
            </p>
            <p className={allocationStyles.tsrValue}>
                <PercentValue value={tsrPercent.toString()} />
            </p>
        </>
    )
}

export default InstrumentActivityRecordShareSchemeAlignmentRightAllocation
