import {Button} from '@design-system/button'
import {Download} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {Loading} from '~/global/widgets/loading'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import styles from '~/sections/invest/sections/transfer-shares/sections/asx-transfer-shares/pages/download-transfer-form/DownloadTransferForm.scss'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/transfer/actions'

interface EmailFormsProps {
    groupId: string
}
export default function DownloadTransferForm({groupId}: EmailFormsProps) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const {stagedTransferOrder, transfersLoadingState} = useAppSelector(({transfer}) => transfer)
    const actingAsIdParam = useAppSelector(({identity}) => identity.user!.id)
    const email = useAppSelector(({identity}) => identity.user!.email)

    React.useEffect(() => {
        if (transfersLoadingState === 'loading' && !stagedTransferOrder) {
            dispatch(actions.SetStagedTransfer(groupId))
        }
        // Return to the start of the flow if there are no registry details or transfer order
        if (transfersLoadingState === 'ready' && !stagedTransferOrder) {
            navigate(profileUrl('invest/transfer-shares'), {replace: true})
        }
    }, [transfersLoadingState])

    const navigateHome = () => {
        navigate(profileUrl('invest/transfer-shares'), {replace: true})
    }

    const handleDownloadForm = async () => {
        const direction = stagedTransferOrder?.direction || 'in'
        window.open(
            `/api/transfer/${groupId}/asx-transfer-${direction}-form.pdf?acting_as_id=${actingAsIdParam}`,
            '_blank',
            'noopener',
        )
    }

    const saveApplication = () => {
        dispatch(actions.ResetStagedTransferOrder())
        navigate(profileUrl('invest/transfer-shares'), {replace: true})
        Toast('Your transfer application was saved')
    }

    if (transfersLoadingState === 'loading') {
        return <Loading isPineapple />
    }

    if (transfersLoadingState === 'error') {
        navigateHome()
        return <></>
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--transfer-shares-download-transfer-form"
                leftButton="back"
                onLeftButtonClick={navigateHome}
            />

            <Page>
                <h1 className={cn(commonStyles.heading, spacing.spaceBelow24)}>
                    {stagedTransferOrder?.rejected ? 'We have a hiccup. ' : 'Nice one! '}
                    Check your email
                </h1>
                <p className={spacing.spaceBelow16}>
                    {(stagedTransferOrder?.rejected && (
                        <>
                            We emailed you with some more info about what's needed to complete your transfer. Once
                            that's sorted, simply follow the instructions below.
                        </>
                    )) || (
                        <>We’ve sent your form to {email}. You’re also welcome to download it using the link below.</>
                    )}
                </p>
                <ButtonAsLink onClick={handleDownloadForm} className={cn(spacing.spaceBelow32, styles.downloadLink)}>
                    <>
                        <Download /> <span>Download Transfer Form PDF</span>
                    </>
                </ButtonAsLink>

                <h2 className={cn(commonStyles.subHeading, spacing.spaceBelow8)}>Print and sign your Transfer Form</h2>
                <p className={spacing.spaceBelow16}>
                    We need a physical signature and date to make the transfer happen, which means finding a printer.
                    Don’t have one handy? Don’t worry. You can save your application below until you can get to one.
                </p>
                <p className={spacing.spaceBelow16}>
                    Note: a digital signature won’t be accepted. You must sign the form using a black or blue pen.
                </p>
                <p>Once you have, upload a clear image of the form below, and you’re good to go!</p>
            </Page>
            <ActionBar>
                <div className={commonStyles.actionBar}>
                    <Button
                        type="secondary"
                        label="Save application"
                        dataTestId="button--save-application"
                        onClick={saveApplication}
                    />
                    <Button
                        isSubmit
                        label="Continue"
                        dataTestId="button--continue"
                        onClick={() =>
                            navigate(
                                profileUrl('invest/portfolio-transfer-shares/asx/:groupId/upload-documents', {
                                    groupId,
                                }),
                            )
                        }
                    />
                </div>
            </ActionBar>
        </>
    )
}
