import {DateTime} from 'luxon'
import React from 'react'
import {useNavigate} from 'react-router'
import {useRetailPost, useRetailGet} from '~/api/query/retail'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {dateFormatNoDay} from '~/global/utils/format-date/formatDate'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import GoogleDriveDocumentViewer from '~/global/widgets/google-drive-document-viewer/GoogleDriveDocumentViewer'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import {Toast} from '~/global/widgets/toast/Toast'
import {usePlanEditorUrl} from '~/sections/kiwisaver/sections/edit-investment-plan/hooks/usePlanEditorUrl'
import {
    SignUpGuardRedirect,
    SignUpPage,
} from '~/sections/kiwisaver/sections/sign-up/widgets/redirect/SignUpGuardRedirect'
import {useExistingKSCustomer} from '~/sections/kiwisaver/state'
import {useAppSelector} from '~/store/hooks'
import {actingAsID as actingAsIDSelector} from '~/store/identity/selectors'

export const AcceptPDS: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate()
    const relativeUrlFor = usePlanEditorUrl()
    const actingAsID = useAppSelector(actingAsIDSelector)
    const customer = useExistingKSCustomer()

    const [isSubmitting, setIsSubmitting] = React.useState(false)

    const {data: pdsRevisionData} = useRetailGet({
        path: 'kiwisaver/get-current-pds-revision',
    })
    const setAcceptPDS = useRetailPost({
        path: 'kiwisaver/accept-pds-and-ios',
        queryCacheToUpdate: ['kiwisaver/customer'],
    })

    React.useEffect(() => {
        // we might land on this page during the edit investment plan flow even though the investor
        // has already accepted the most recent version - in this instance, just skip them back to
        // the edit investment plan home
        if (
            customer.pds_revision_id &&
            parseInt(customer.pds_revision_id, 10) === parseInt(pdsRevisionData.pds_file_revision_id, 10)
        ) {
            navigate(relativeUrlFor(''), {replace: true}) // replace because we want to skip over this page on back nav
        }
    }, [customer, pdsRevisionData])

    const onAccept = async () => {
        setIsSubmitting(true)

        try {
            rudderTrack('kiwisaver_signup', 'pds_acceptance', {
                type: customer.customer_state === 'SIGNUP' ? 'signup' : 'edit',
            })
            await setAcceptPDS.mutateAsync({
                acting_as_id: actingAsID,
                pds_file_revision_id: pdsRevisionData.pds_file_revision_id,
            })
            navigate(relativeUrlFor(''), {replace: true}) // replace because we want to skip over this page on back nav
        } catch (e) {
            Toast('Unable to accept, please try again')
        } finally {
            setIsSubmitting(false)
        }
    }

    const pdsDownloadUrl = `https://drive.google.com/uc?id=${pdsRevisionData.drive_id}&export=download`
    const pdsPreviewUrl = `https://drive.google.com/file/d/${pdsRevisionData.drive_id}/preview`

    const downloadPds = () => {
        location.href = pdsDownloadUrl
    }

    return (
        <SignUpGuardRedirect currentPage={SignUpPage.EDIT_INVESTMENT_PLAN_ACCEPT_PDS}>
            <Page withoutDefaultPadding>
                <PageBack />
                <GoogleDriveDocumentViewer
                    title="Read and accept the Sharesies KiwiSaver Scheme PDS &amp; IOS"
                    subTitle={
                        <>
                            Updated: {DateTime.fromISO(pdsRevisionData.publication_date).toFormat(dateFormatNoDay)}{' '}
                            <Delimiter /> <ButtonAsLink onClick={downloadPds}>Download PDF</ButtonAsLink>
                        </>
                    }
                    onButtonClick={onAccept}
                    isButtonLoading={isSubmitting}
                    buttonLabel="I have read and accept the PDS &amp; IOS"
                    documentUrl={pdsPreviewUrl}
                    dataTestId="button--accept"
                />
            </Page>
        </SignUpGuardRedirect>
    )
}
