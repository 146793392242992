import {Button} from '@design-system/button'
import {withFormik} from 'formik'
import React from 'react'
import {Model} from '~/api/retail/types'
import {UserSuitableError} from '~/global/utils/error-handling/errorHandling'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import AccountsCardList from '~/global/widgets/credit-card-handling/accepted-cards-list/AccountsCardList'
import {ErrorBox} from '~/global/widgets/form-controls'

interface SavedCardFormProps {
    submitLabel: string
    onSubmit: () => void | Promise<string | void>
    card: Model.StripeSource
    border?: boolean
}

const SavedCardForm = withFormik<SavedCardFormProps, {}>({
    handleSubmit: async (_, {setSubmitting, setStatus, props: {onSubmit}}) => {
        try {
            setStatus()
            await onSubmit()
            setSubmitting(false)
        } catch (e) {
            setSubmitting(false)
            if (e instanceof UserSuitableError) {
                setStatus(e.message)
            } else {
                setStatus(unknownErrorMessage)
                throw e
            }
        }
    },
})(({isSubmitting, handleSubmit, status, isValid, card, submitLabel, border}) => {
    return (
        <>
            <AccountsCardList source={card} border={border} />
            <form onSubmit={handleSubmit}>
                <ErrorBox message={status} />
                <Button
                    dataTestId="button--review-order"
                    label={submitLabel}
                    isSubmit
                    pageButton
                    disabled={!isValid}
                    processing={isSubmitting}
                />
            </form>
        </>
    )
})

export default SavedCardForm
