import React from 'react'
import AccountVerificationRequired from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import Form from './pages/form/Form'
import Intro from './pages/intro/Intro'

const InstantTopup = () => {
    const [step, setStep] = React.useState('intro')

    return (
        <>
            <Toolbar dataTestId="toolbar--top-up-direct-bank" leftButton="back" />
            <Page>
                <AccountVerificationRequired />
                {step === 'intro' && <Intro setStep={setStep} />}
                {step === 'form' && <Form />}
            </Page>
        </>
    )
}

export default InstantTopup
