import {ChevronRight} from '@design-system/icon'
import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import React from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ListMenuItem, ListMenuGroup} from '~/global/widgets/list-menu/ListMenu'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useLinkedBankPaymentsUrl} from '~/sections/OLD_wallet/sections/linked-bank-payments/hooks/useLinkedBankPaymentsUrl'
import {useRecurringPayment} from '~/sections/OLD_wallet/sections/linked-bank-payments/state'
import {RecurringTopupTile} from '~/sections/OLD_wallet/sections/linked-bank-payments/widgets/recurring-topup-tile/RecurringTopupTile'
import {BankLinkingAlert} from '~/sections/OLD_wallet/widgets/bank-linking-alert/BankLinkingAlert'
import {BASIQ_DDRSA_URL, MOONOVA_DDRSA_ROUTE} from '~/sections/invest/sections/bank-linking/constants/ddrsa'
import {useAppSelector} from '~/store/hooks'
import styles from './TopUpType.scss'

export const TopUpType: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const recurringPayment = useRecurringPayment()
    const paymentsUrl = useLinkedBankPaymentsUrl()
    const profileUrl = useProfileUrl()
    const hasRecurringPayment = !!recurringPayment?.next_payment
    const hasMonoovaFlag = useAppSelector(s => s.identity.flags.monoova)

    return (
        <>
            <Toolbar
                dataTestId="toolbar--linked-bank-topup-top-up-type"
                leftButton="back"
                onLeftButtonClick={() =>
                    // Be explicit and push the next route to the stack instead of navigate(-1)
                    // This page can be accessed via banklinking setup flow, which we don't want users re-doing
                    navigate(location.pathname.includes('explore') ? profileUrl('wallet') : profileUrl('explore'))
                }
            />
            <Page className={styles.topupTypePage} overrideDefaultTopPadding="withToolbarTitle">
                <h1 className={spacing.spaceBelow12}>Top up from your linked bank</h1>
                <BankLinkingAlert locationContext="linked-bank-topup" wrapperClassName={spacing.spaceBelow16} />
                <p>
                    Top up your Wallet with money from your linked bank. A transfer fee applies if you’re not on a
                    pricing plan.{' '}
                    <ModalLink
                        dataTestId="modal-link--estimated-settlement-date"
                        label="Linked bank transaction fee"
                        asIcon
                        helpIconSize={16}
                        modalTitle="Linked bank transfer fee"
                        primaryButton={{label: 'Got it'}}
                    >
                        <p>There’s a 2% transfer fee on payments from a linked bank capped at $2 AUD.</p>
                        <p>
                            Linked bank transfers are free on a Sharesies pricing plan. You can sign up to a plan by
                            heading to <b>Account {'>'} Pricing plans</b>.
                        </p>
                    </ModalLink>
                </p>
                <p className={cn(spacing.spaceAbove16, spacing.spaceBelow16)}>
                    Top-ups from a linked bank can take up to three business days to appear in your Wallet.
                </p>
                {hasRecurringPayment ? null : (
                    <button
                        type="button"
                        className={styles.selectCard}
                        data-testid="select-card--recurring-top-up"
                        onClick={() => navigate(paymentsUrl('recurring'))}
                    >
                        Set up a recurring top-up
                        <ChevronRight size={12} />
                    </button>
                )}
                <button
                    type="button"
                    className={styles.selectCard}
                    data-testid="select-card--one-off-top-up"
                    onClick={() => navigate(paymentsUrl('one-off'))}
                >
                    Make a one-off top-up
                    <ChevronRight size={12} />
                </button>
                {hasRecurringPayment ? (
                    <>
                        <h2 className={cn(spacing.spaceBelow16, spacing.spaceAbove16)}>Recurring top-up</h2>
                        <RecurringTopupTile recurringPayment={recurringPayment} />
                    </>
                ) : null}
                <h2 className={cn(spacing.spaceAbove12, typographyOverrides['as-h2'])}>Linked bank</h2>
                <ListMenuGroup className={spacing.spaceBelow24}>
                    <ListMenuItem
                        label="Change my account to top up from"
                        onClick={() => navigate(paymentsUrl('select-debit-account'))}
                    />
                </ListMenuGroup>
                <p>
                    Your recurring top-up will be processed in accordance with our{' '}
                    {hasMonoovaFlag ? (
                        <Link to={profileUrl(MOONOVA_DDRSA_ROUTE)}>Direct Debit Request</Link>
                    ) : (
                        <a target="_blank" href={BASIQ_DDRSA_URL} rel="noreferrer">
                            Direct Debit Request
                        </a>
                    )}{' '}
                    and{' '}
                    {hasMonoovaFlag ? (
                        <Link to={profileUrl(MOONOVA_DDRSA_ROUTE)}>Direct Debit Request Service Agreement</Link>
                    ) : (
                        <a target="_blank" href={BASIQ_DDRSA_URL} rel="noreferrer">
                            Direct Debit Request Service Agreement
                        </a>
                    )}
                    .
                </p>
            </Page>
        </>
    )
}
