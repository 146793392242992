import cn from 'classnames'
import React, {useState} from 'react'
import {useDebounce} from 'usehooks-ts'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {useInstruments} from '~/global/utils/use-instruments/useInstruments'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Chips from '~/global/widgets/chips/Chips'
import InstrumentRowCompact from '~/global/widgets/instrument-row/InstrumentRowCompact'
import {LoadingEllipsis} from '~/global/widgets/loading-ellipsis/LoadingEllipsis'
import WatchlistButton from '~/sections/explore/widgets/watchlist/WatchlistButton'
import {GROUP_IDS} from '~/sections/invest/sections/ai-search/data/group-ids/groupIds'
import {useTelescope} from '~/sections/invest/sections/ai-search/hooks/useTelescope'
import FadeIn from '~/sections/invest/sections/ai-search/widgets/fade-in/FadeIn'
import {useAppSelector} from '~/store/hooks'
import styles from './AiSearchInstrumentResults.scss'

interface InstrumentResultsSegmentProps {
    groupId: string
    noResultsFoundText: string
    filter: Market | 'all' // ruddertrack event data
}

type Market = 'US-listed' | 'ASX' | 'NZX'

const toRudderStackFilterType = (market: Market | 'all') =>
    (market !== 'all' ? market.toLowerCase() : 'no-filter') as 'asx' | 'no-filter' | 'nzx' | 'us-listed'

const InstrumentResultsSegment: React.FunctionComponent<InstrumentResultsSegmentProps> = ({
    groupId,
    noResultsFoundText,
    filter,
}) => {
    const profileUrl = useProfileUrl()
    const {telescopeData, loadingState} = useTelescope()
    const instrumentsForGroup = (telescopeData.instruments ?? []).filter(
        instrument => instrument.instrument_group_id === groupId,
    )
    const debouncedInstrumentIds = useDebounce(
        // Extract the sharesies instrument ID from the telescope instruments
        instrumentsForGroup.map(ti => ti.reference_id1!),
        500,
    )
    const [instrumentsById] = useInstruments(debouncedInstrumentIds)

    if (!instrumentsForGroup.length) {
        if (loadingState === 'processing') {
            return <LoadingEllipsis />
        }
        return <p>{noResultsFoundText}</p>
    }

    return (
        <>
            {instrumentsForGroup.map((ti, index) => {
                const instrument = instrumentsById[ti.reference_id1]

                if (!instrument) {
                    return <React.Fragment key={index}></React.Fragment>
                }

                const rudderStackData = {
                    has_filter: filter !== 'all',
                    type: toRudderStackFilterType(filter),
                    instrument_id: instrument.id,
                }

                const watchlistButton = (
                    <WatchlistButton
                        instrumentId={instrument.id}
                        instrumentName={instrument.name}
                        onWatchlisted={() => {
                            rudderTrack('ai_search', 'result_watchlisted', rudderStackData)
                        }}
                    />
                )

                return (
                    <InstrumentRowCompact
                        additionalClassName={styles.result}
                        key={instrument.id}
                        instrument={instrument}
                        rowLinkTarget={profileUrl('invest/:instrumentSlug', {
                            instrumentSlug: instrument.urlSlug,
                        })}
                        onLinkNavigated={() => {
                            rudderTrack('ai_search', 'result_clicked', rudderStackData)
                        }}
                        mainTitleOverride={<h4 className={cn(styles.title)}>{instrument.name}</h4>}
                        subTitleOverride={<p className={cn(styles.reasoning, spacing.spaceAbove6)}>{ti.reason}</p>}
                        rightSlot={watchlistButton}
                    />
                )
            })}
        </>
    )
}

export const AiSearchInstrumentResults: React.FunctionComponent = () => {
    const {telescopeData, lastPrompt, loadingState} = useTelescope()
    const [filterSelected, setFilterSelected] = useState<Market | 'all'>('all')
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)

    React.useEffect(() => {
        if (loadingState === 'processing') {
            // Reset the selected fitler whenever a new prompt is submitted
            setFilterSelected('all')
        }
    }, [loadingState])

    const filterOptions: Market[] = ['US-listed', 'ASX']
    const markets = [
        {
            filterId: 'US-listed' as Market,
            title: 'US-listed investments',
            companies_group_id: GROUP_IDS.us_company,
            etfs_group_id: GROUP_IDS.us_etf,
        },
        {
            filterId: 'ASX' as Market,
            title: 'ASX-listed investments',
            companies_group_id: GROUP_IDS.au_company,
            etfs_group_id: GROUP_IDS.au_etf,
        },
    ]

    if (jurisdiction === 'nz') {
        filterOptions.push('NZX')
        markets.push({
            filterId: 'NZX',
            title: 'NZX-listed investments',
            companies_group_id: GROUP_IDS.nz_etf,
            etfs_group_id: GROUP_IDS.nz_company,
        })
    }

    if (!telescopeData.instruments?.length && loadingState === 'processing') {
        return <LoadingEllipsis />
    }

    if (!telescopeData.instruments?.length) {
        return null
    }

    return (
        <>
            <FadeIn>
                <div className={spacing.spaceBelow8}>
                    <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow12)}>Results</h2>
                    <p className={spacing.spaceBelow6}>
                        Each result includes a reason describing why AI Search believes it matches your search query.
                    </p>
                    <p className={styles.legal}>
                        Results only include investments on Sharesies and may not consider the latest information.
                        They’re not an opinion or recommendation. Do your own due diligence, and double-check accuracy
                        of information, before deciding to invest.
                    </p>
                </div>
            </FadeIn>
            <div className={styles.instrumentResults}>
                <FadeIn>
                    <div className={spacing.spaceBelow24}>
                        <Chips
                            options={filterOptions}
                            onChipClick={options => setFilterSelected(options)}
                            hasAllOption
                            selected={[filterSelected]}
                            isInlineDisplay
                        />
                    </div>
                </FadeIn>

                {markets.map(({filterId, title, companies_group_id, etfs_group_id}) => {
                    if (filterSelected !== 'all' && filterSelected !== filterId) {
                        return null
                    }

                    const groupsHaveAnyinstruments = (telescopeData.instruments ?? []).some(instrument =>
                        [companies_group_id, etfs_group_id].includes(instrument.instrument_group_id),
                    )

                    if (!groupsHaveAnyinstruments) {
                        return (
                            <FadeIn key={title + filterSelected}>
                                <div className={spacing.spaceBelow40}>
                                    <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow4)}>{title}</h2>
                                    {loadingState === 'processing' ? (
                                        <LoadingEllipsis />
                                    ) : (
                                        <p className={spacing.spaceAbove8}>
                                            No results for {title} related to ’{lastPrompt}’
                                        </p>
                                    )}
                                </div>
                            </FadeIn>
                        )
                    }

                    return (
                        <div
                            key={title + filterSelected} // < This is intentional, it allows react to re-mount the selected list and replay entrance animations
                            className={spacing.spaceBelow40}
                        >
                            <FadeIn>
                                <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow4)}>{title}</h2>
                                <h3 className={cn(typographyOverrides['as-h3'], spacing.spaceBelow12)}>Companies</h3>

                                {/* Companies */}
                                <div className={styles.resultWrapper}>
                                    <InstrumentResultsSegment
                                        groupId={companies_group_id}
                                        noResultsFoundText={`No results for ${title.replace(
                                            'investments',
                                            'companies',
                                        )} related to ’${lastPrompt}’`}
                                        filter={filterSelected}
                                    />
                                </div>

                                {/* ETFs */}
                                <h3 className={cn(typographyOverrides['as-h3'], spacing.spaceBelow12)}>
                                    Exchange-traded funds
                                </h3>

                                <div className={styles.resultWrapper}>
                                    <InstrumentResultsSegment
                                        groupId={etfs_group_id}
                                        noResultsFoundText={`No results for ${title.replace(
                                            'investments',
                                            'exchange-traded funds',
                                        )} related to ’${lastPrompt}’`}
                                        filter={filterSelected}
                                    />
                                </div>
                            </FadeIn>
                        </div>
                    )
                })}
            </div>
        </>
    )
}
