/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface ComparisonPriceDto
 */
export interface ComparisonPriceDto {
    /**
     *
     * @type {string}
     * @memberof ComparisonPriceDto
     */
    value: string
    /**
     *
     * @type {string}
     * @memberof ComparisonPriceDto
     */
    percent: string
    /**
     *
     * @type {string}
     * @memberof ComparisonPriceDto
     */
    max: string
    /**
     *
     * @type {string}
     * @memberof ComparisonPriceDto
     */
    min: string
}

export function ComparisonPriceDtoFromJSON(json: any): ComparisonPriceDto {
    return ComparisonPriceDtoFromJSONTyped(json, false)
}

export function ComparisonPriceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComparisonPriceDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        value: json['value'],
        percent: json['percent'],
        max: json['max'],
        min: json['min'],
    }
}

export function ComparisonPriceDtoToJSON(value?: ComparisonPriceDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        value: value.value,
        percent: value.percent,
        max: value.max,
        min: value.min,
    }
}
