import React from 'react'
import {spacing} from '~/global/scss/helpers'
import TermsAndConditionsText, {
    NZ_TC_VERSION,
} from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/TermsAndConditions'

export {NZ_TC_VERSION}

export const NZAgreement: React.FC<{
    tcAccepted: boolean
    setTcAccepted: (tcAccepted: boolean) => void
}> = ({tcAccepted, setTcAccepted}) => {
    // No checkbox in NZ T&C
    React.useEffect(() => {
        if (!tcAccepted) {
            setTcAccepted(true)
        }
    }, [])
    return (
        <>
            <h1 className={spacing.spaceBelow16}>Read and accept the Sharesies Terms and Conditions</h1>
            <TermsAndConditionsText showDisclaimer />
        </>
    )
}
