/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface AppSchemasForAdminPortfolioLatestInstrumentReturn
 */
export interface AppSchemasForAdminPortfolioLatestInstrumentReturn {
    /**
     *
     * @type {string}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    instrument_uuid: string
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    shares_owned: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    investment_value: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    dividends_received: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    simple_return: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    total_return: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    cost_basis: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    cost_basis_max: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    total_cost_basis: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    sell_price: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    exchange_rate?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    total_transaction_fees?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    total_adr_fees?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    capital_gain?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    weighted_price?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    capital_returned?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    realised_capital_gain?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    currency_gain?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    realised_currency_gain?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    weighted_fx_rate?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    unrealised_tax_paid?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    unrealised_provider_fees?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    unrealised_dividends?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    unrealised_simple_return?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    unrealised_total_return?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    unrealised_transaction_fees?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioLatestInstrumentReturn
     */
    unrealised_adr_fees?: number
}

export function AppSchemasForAdminPortfolioLatestInstrumentReturnFromJSON(
    json: any,
): AppSchemasForAdminPortfolioLatestInstrumentReturn {
    return AppSchemasForAdminPortfolioLatestInstrumentReturnFromJSONTyped(json, false)
}

export function AppSchemasForAdminPortfolioLatestInstrumentReturnFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AppSchemasForAdminPortfolioLatestInstrumentReturn {
    if (json === undefined || json === null) {
        return json
    }
    return {
        instrument_uuid: json['instrument_uuid'],
        shares_owned: json['shares_owned'],
        investment_value: json['investment_value'],
        dividends_received: json['dividends_received'],
        simple_return: json['simple_return'],
        total_return: json['total_return'],
        cost_basis: json['cost_basis'],
        cost_basis_max: json['cost_basis_max'],
        total_cost_basis: json['total_cost_basis'],
        sell_price: json['sell_price'],
        exchange_rate: !exists(json, 'exchange_rate') ? undefined : json['exchange_rate'],
        total_transaction_fees: !exists(json, 'total_transaction_fees') ? undefined : json['total_transaction_fees'],
        total_adr_fees: !exists(json, 'total_adr_fees') ? undefined : json['total_adr_fees'],
        capital_gain: !exists(json, 'capital_gain') ? undefined : json['capital_gain'],
        weighted_price: !exists(json, 'weighted_price') ? undefined : json['weighted_price'],
        capital_returned: !exists(json, 'capital_returned') ? undefined : json['capital_returned'],
        realised_capital_gain: !exists(json, 'realised_capital_gain') ? undefined : json['realised_capital_gain'],
        currency_gain: !exists(json, 'currency_gain') ? undefined : json['currency_gain'],
        realised_currency_gain: !exists(json, 'realised_currency_gain') ? undefined : json['realised_currency_gain'],
        weighted_fx_rate: !exists(json, 'weighted_fx_rate') ? undefined : json['weighted_fx_rate'],
        unrealised_tax_paid: !exists(json, 'unrealised_tax_paid') ? undefined : json['unrealised_tax_paid'],
        unrealised_provider_fees: !exists(json, 'unrealised_provider_fees')
            ? undefined
            : json['unrealised_provider_fees'],
        unrealised_dividends: !exists(json, 'unrealised_dividends') ? undefined : json['unrealised_dividends'],
        unrealised_simple_return: !exists(json, 'unrealised_simple_return')
            ? undefined
            : json['unrealised_simple_return'],
        unrealised_total_return: !exists(json, 'unrealised_total_return') ? undefined : json['unrealised_total_return'],
        unrealised_transaction_fees: !exists(json, 'unrealised_transaction_fees')
            ? undefined
            : json['unrealised_transaction_fees'],
        unrealised_adr_fees: !exists(json, 'unrealised_adr_fees') ? undefined : json['unrealised_adr_fees'],
    }
}

export function AppSchemasForAdminPortfolioLatestInstrumentReturnToJSON(
    value?: AppSchemasForAdminPortfolioLatestInstrumentReturn | null,
): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        instrument_uuid: value.instrument_uuid,
        shares_owned: value.shares_owned,
        investment_value: value.investment_value,
        dividends_received: value.dividends_received,
        simple_return: value.simple_return,
        total_return: value.total_return,
        cost_basis: value.cost_basis,
        cost_basis_max: value.cost_basis_max,
        total_cost_basis: value.total_cost_basis,
        sell_price: value.sell_price,
        exchange_rate: value.exchange_rate,
        total_transaction_fees: value.total_transaction_fees,
        total_adr_fees: value.total_adr_fees,
        capital_gain: value.capital_gain,
        weighted_price: value.weighted_price,
        capital_returned: value.capital_returned,
        realised_capital_gain: value.realised_capital_gain,
        currency_gain: value.currency_gain,
        realised_currency_gain: value.realised_currency_gain,
        weighted_fx_rate: value.weighted_fx_rate,
        unrealised_tax_paid: value.unrealised_tax_paid,
        unrealised_provider_fees: value.unrealised_provider_fees,
        unrealised_dividends: value.unrealised_dividends,
        unrealised_simple_return: value.unrealised_simple_return,
        unrealised_total_return: value.unrealised_total_return,
        unrealised_transaction_fees: value.unrealised_transaction_fees,
        unrealised_adr_fees: value.unrealised_adr_fees,
    }
}
