import {Model} from '~/api/retail/types'
import {InstrumentTypes} from '~/store/instrument/types'

export const getInstrumentTypeLabels = (instrumentTypes: InstrumentTypes, jurisdiction: Model.User['jurisdiction']) => {
    return instrumentTypes
        .map(instrumentType => instrumentType.name)
        .sort()
        .filter(i => {
            // Ignore managed funds if we're not in NZ
            if (i === 'Managed funds' && jurisdiction !== 'nz') {
                return false
            }
            // Ignore ETPs and Rights
            if (['ETPs', 'Rights', 'Warrants', 'Options', 'Unlisted Companies'].includes(i)) {
                return false
            }
            return true
        })
}
