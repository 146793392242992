/*
 * IMPORTANT: eventually all sign up actions should reside in the 'sign-up'
 * namespace, however currently older sign up action live in 'identity'
 */

import * as api from '~/api/retail'
import {Request, Response, POST_MAP} from '~/api/retail/types'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {reauthenticateReturn} from '~/global/wrappers/global-wrapper-widgets/reauthenticate/Reauthenticate'
import {User} from '~/store/identity/types'
import identityActions from '../identity/actions'
import {actingAsID} from '../identity/selectors'
import {createAction, ActionsUnion} from '../redux-tools'
import {ThunkAction} from '../types'

const actions = {
    setUSResignStatus: (status: Response.SignUpUSResignStatus) => createAction('signup.setUSResignStatus', {status}),
    setUSStatus: (status: Response.SignUpUSStatus) => createAction('signup.setUSStatus', {status}),
    setUSPreSignUpLocation: (location: string) => createAction('signup.setUSPreSignUpLocation', location),
    setLocationToReturnTo: (location: string) => createAction('signup.locationToReturnTo', location),
}

const generateConfirmAction = <URL extends keyof POST_MAP>(
    url: URL,
    params: Pick<POST_MAP[URL]['request'], Exclude<keyof POST_MAP[URL]['request'], 'acting_as_id'>>,
): ThunkAction<Promise<void | null>> => {
    let acting_as_id = ''
    const action: ThunkAction<Promise<void | null>> = async (dispatch, getState) => {
        acting_as_id = acting_as_id || actingAsID(getState())

        const response = await (api.post as any)(url, {...params, acting_as_id})

        switch (response.type) {
            case 'sign_up_us_status':
                dispatch(actions.setUSStatus(response))
                dispatch(identityActions.UpdateUSSignupDetails(response.address, response.phone, response.ird_number))
                break
            case 'authentication_update_required':
                return reauthenticateReturn(
                    () => action(dispatch, getState),
                    () => null,
                    () => null,
                ) // if the password is wrong or timeout, let the user try it again manually
            default:
                throw new Error(`unhandled response type: ${response.type}`)
        }
    }

    return action
}

const thunkActions = {
    getUSStatus(): ThunkAction<void> {
        return async (dispatch, getState) => {
            const response = await api.get('sign-up/us-status', {acting_as_id: actingAsID(getState())})
            switch (response.type) {
                case 'sign_up_us_status':
                    dispatch(actions.setUSStatus(response))
                    break
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => thunkActions.getUSStatus()(dispatch, getState),
                        () => null,
                        () => null,
                    ) // if the password is wrong or timeout, let the user try it again manually
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    initUSStateMachine(): ThunkAction<void> {
        return async (dispatch, getState) => {
            const response = await api.post('sign-up/init-us-state-machine', {acting_as_id: actingAsID(getState())})
            switch (response.type) {
                case 'sign_up_us_status':
                    // TODO: Still want to do this
                    dispatch(actions.setUSStatus(response))
                    break
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => thunkActions.getUSStatus()(dispatch, getState),
                        () => null,
                        () => null,
                    ) // if the password is wrong or timeout, let the user try it again manually
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    initUSStateMachineVoluntaryResign(): ThunkAction<void> {
        return async (dispatch, getState) => {
            const response = await api.post('sign-up/init-us-state-machine-for-voluntary-resign', {
                acting_as_id: actingAsID(getState()),
            })
            switch (response.type) {
                case 'sign_up_us_status':
                    // TODO: Still want to do this
                    dispatch(actions.setUSStatus(response))
                    break
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => thunkActions.getUSStatus()(dispatch, getState),
                        () => null,
                        () => null,
                    ) // if the password is wrong or timeout, let the user try it again manually
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    usEditAddress: (address: User['address']) => generateConfirmAction('sign-up/us-set-address', {address}),
    usEditMailingAddress: (address: User['address']) =>
        generateConfirmAction('sign-up/us-set-tax-mailing-address', {address}),
    usEditTaxDetails: (
        foreign_tax_resident: Request.IdentitySignUpTaxQuestions['foreign_tax_resident'],
        countries: Request.IdentitySignUpTaxQuestions['countries'],
    ) => generateConfirmAction('sign-up/us-set-tax-residency', {foreign_tax_resident, countries}),
    usSetCitizenShip: (countries: Request.SignUpUSSetCitizenship['countries']) =>
        generateConfirmAction('sign-up/us-set-citizenship', {countries}),
    usSignW8ben: (signed: true, treaty_opt_in: boolean) =>
        generateConfirmAction('sign-up/us-sign-w8ben', {signed, treaty_opt_in}),
    usSignW9: (signed: true, subject_to_backup_withholding: boolean) =>
        generateConfirmAction('sign-up/us-sign-w9', {signed, subject_to_backup_withholding}),
    getUSResignStatus(): ThunkAction<void> {
        return async (dispatch, getState) => {
            const response = await api.get('sign-up/us-resign-status', {acting_as_id: actingAsID(getState())})
            switch (response.type) {
                case 'sign_up_us_resign_status':
                    dispatch(actions.setUSResignStatus(response))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    MarkHasSeenFlag(flag: Request.SignUpUSMarkHasSeenFlag['flag']): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const acting_as_id = actingAsID(getState())
            const response = await api.post('sign-up/mark-has-seen-flag', {acting_as_id, flag})
            switch (response.type) {
                case 'sign_up_us_status':
                    dispatch(actions.setUSStatus(response))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
}

export type ActionsType = ActionsUnion<typeof actions>

export default {...actions, ...thunkActions}
