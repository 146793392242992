import {CombinedColours} from '@design-system/colour-tokens'
import {Close} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {accessibility} from '~/global/scss/helpers'
import {useNavigate} from '~/migrate-react-router'
import styles from './PageBack.scss'

interface PageBackProps {
    buttonColour?: CombinedColours
    onClick?: () => void
    positionStatic?: boolean
}

const PageClose: React.FunctionComponent<PageBackProps> = ({
    buttonColour = 'IconDefault',
    onClick,
    positionStatic = false,
}) => {
    const navigate = useNavigate()

    return (
        <button
            type="button"
            data-testid="page-close"
            className={cn(styles.button, {[styles.positionStatic]: positionStatic}, accessibility.button)}
            onClick={onClick || (() => navigate(-1))}
        >
            <Close aria-label="Exit this page" colour={buttonColour} />
        </button>
    )
}

export default PageClose
