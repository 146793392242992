import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AccountNeedsVerification from '~/sections/invest/sections/transfer-shares/pages/account-needs-verification/AccountNeedsVerification'
import Landing from '~/sections/invest/sections/transfer-shares/pages/landing/Landing'
import Thanks from '~/sections/invest/sections/transfer-shares/pages/thanks/Thanks'
import TransferDirection from '~/sections/invest/sections/transfer-shares/pages/transfer-direction/TransferDirection'
import TransferExchange from '~/sections/invest/sections/transfer-shares/pages/transfer-exchange/TransferExchange'
import TransferDetails from '~/sections/invest/sections/transfer-shares/pages/transfer-shares-details/TransferSharesDetails'
import TransferPriceUpdate from '~/sections/invest/sections/transfer-shares/pages/transfer-shares-price-update/TransferSharesPriceUpdate'
import SelectShares from '~/sections/invest/sections/transfer-shares/pages/transfer-shares-select/TransferSharesSelect'
import UploadDocuments from '~/sections/invest/sections/transfer-shares/pages/upload-documents/UploadDocuments'
import ViewRegistryDetail from '~/sections/invest/sections/transfer-shares/pages/view-registry-detail/ViewRegistryDetail'
import ViewTransferGroup from '~/sections/invest/sections/transfer-shares/pages/view-transfer-group/ViewTransferGroup'
import ViewTransfers from '~/sections/invest/sections/transfer-shares/pages/view-transfers/ViewTransfers'
import AddAsxRegistryDetails from '~/sections/invest/sections/transfer-shares/sections/asx-transfer-shares/pages/add-registry-details/AddRegistryDetails'
import {AddRegistryDetailsSrnOrHinOut} from '~/sections/invest/sections/transfer-shares/sections/asx-transfer-shares/pages/add-registry-details-srn-or-hin-out/AddRegistryDetailsSrnOrHinOut'
import AsxConfirm from '~/sections/invest/sections/transfer-shares/sections/asx-transfer-shares/pages/confirm/Confirm'
import AsxDownloadTransferForm from '~/sections/invest/sections/transfer-shares/sections/asx-transfer-shares/pages/download-transfer-form/DownloadTransferForm'
import AsxIntro from '~/sections/invest/sections/transfer-shares/sections/asx-transfer-shares/pages/intro/Intro'
import AsxTermsAndConditions from '~/sections/invest/sections/transfer-shares/sections/asx-transfer-shares/pages/terms-and-conditions/AsxTermsAndConditions'
import AddAsxRegistryAddress from '~/sections/invest/sections/transfer-shares/sections/asx-transfer-shares/sections/add-registry-details-address/AddRegistryDetailsAddress'
import NzxAddCSN from '~/sections/invest/sections/transfer-shares/sections/nzx-transfer-shares/AddCSN'
import NzxConfirm from '~/sections/invest/sections/transfer-shares/sections/nzx-transfer-shares/TransferSharesConfirm'
import NzxChooseCSN from '~/sections/invest/sections/transfer-shares/sections/nzx-transfer-shares/pages/choose-csn/ChooseCSN'
import DoYouHaveACsnPage from '~/sections/invest/sections/transfer-shares/sections/nzx-transfer-shares/pages/do-you-have-a-csn/DoYouHaveACsnPage'
import NzxIntro from '~/sections/invest/sections/transfer-shares/sections/nzx-transfer-shares/pages/intro/Intro'
import NzxTermsAndConditions from '~/sections/invest/sections/transfer-shares/sections/nzx-transfer-shares/pages/terms-and-conditions/NzxTermsAndConditions'
import NzxTransferAll from '~/sections/invest/sections/transfer-shares/sections/nzx-transfer-shares/pages/transfer-all/TransferAll'
import UsConfirm from '~/sections/invest/sections/transfer-shares/sections/us-transfer-shares/Confirm'
import UsDownloadForm from '~/sections/invest/sections/transfer-shares/sections/us-transfer-shares/DownloadTransferForm'
import UsIntro from '~/sections/invest/sections/transfer-shares/sections/us-transfer-shares/Intro'
import UsRegistryDetails from '~/sections/invest/sections/transfer-shares/sections/us-transfer-shares/RegistryDetails'
import UsSelectPlatform from '~/sections/invest/sections/transfer-shares/sections/us-transfer-shares/SelectPlatform'
import UsTermsAndConditions from '~/sections/invest/sections/transfer-shares/sections/us-transfer-shares/TermsAndConditions'
import UsThanks from '~/sections/invest/sections/transfer-shares/sections/us-transfer-shares/USThanks'
import UsVerifyingAccount from '~/sections/invest/sections/transfer-shares/sections/us-transfer-shares/VerifyingAccount'
import {TransferSharesView, TransferSharesSearchView} from '~/sections/invest/sections/view-instrument'
import {TransfersAddressVerification} from '~/sections/user/sections/settings/pages/address-verification/AddressVerification'
import {useAppSelector} from '~/store/hooks'

const TransferSharesWrapper: React.FunctionComponent<{}> = () => {
    const profileUrl = useProfileUrl()
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)

    if (isDependent) {
        return <Navigate to={profileUrl('invest')} replace />
    }

    return <Outlet />
}

export const TRANSFER_SHARES_ROUTES = [
    {
        element: <TransferSharesWrapper />,
        children: [
            {index: true, Component: Landing},
            {
                path: ':exchange/details/:instrumentSlug',
                Component: TransferDetails,
            },
            {path: ':exchange/select', Component: SelectShares},
            {
                path: ':exchange/select/:instrumentSlug',
                Component: TransferSharesView,
            },
            {
                path: ':exchange/select/search/:instrumentSlug',
                Component: TransferSharesSearchView,
            },
            {
                path: 'history/:transferOrderGroupId',
                Component: ViewTransfers,
            },
            {
                path: 'orders/:instrumentSlug/:orderId/update-price',
                Component: TransferPriceUpdate,
            },
            {path: 'select-exchange', Component: TransferExchange},
            {path: 'transfer-direction', Component: TransferDirection},
            {
                path: 'view-registry-detail/:registryDetailId',
                Component: ViewRegistryDetail,
            },
            {
                path: 'view-transfer/:transferOrderGroupId',
                Component: ViewTransferGroup,
            },
            // ASX SPECIFIC ROUTES
            {
                path: 'asx/:groupId/download-transfer-form',
                Component: AsxDownloadTransferForm,
            },
            {
                path: 'asx/:groupId/upload-documents',
                Component: UploadDocuments,
            },
            {path: 'asx/add-investor-address', Component: AddAsxRegistryAddress},
            {path: 'asx/add-investor-details', Component: AddAsxRegistryDetails},
            {path: 'asx/confirm', Component: AsxConfirm},
            {path: 'asx/intro', Component: AsxIntro},
            {path: 'asx/reference', Component: AddRegistryDetailsSrnOrHinOut},
            {
                path: 'asx/terms-and-conditions',
                Component: AsxTermsAndConditions,
            },
            {path: 'asx/thanks', Component: Thanks},

            // NZX SPECIFIC ROUTES
            {path: 'nzx/intro', Component: NzxIntro},
            {path: 'nzx/add-csn', Component: NzxAddCSN},
            {path: 'nzx/choose-csn', Component: NzxChooseCSN},
            {path: 'nzx/transfer-all', Component: NzxTransferAll},
            {path: 'nzx/confirm', Component: NzxConfirm},
            {
                path: 'nzx/terms-and-conditions',
                Component: NzxTermsAndConditions,
            },
            {
                path: 'nzx/check-csn',
                Component: DoYouHaveACsnPage,
            },

            // US SPECIFIC ROUTES
            {
                path: 'us/:groupId/download-transfer-form',
                Component: UsDownloadForm,
            },
            {path: 'us/:groupId/thanks', Component: UsThanks},
            {
                path: 'us/:groupId/upload-documents',
                Component: UploadDocuments,
            },
            {
                path: 'us/account-verification-pending',
                Component: UsVerifyingAccount,
            },
            {path: 'us/address-verification', Component: TransfersAddressVerification},

            {path: 'us/confirm', Component: UsConfirm},
            {
                path: 'us/intro',
                Component: UsIntro,
            },

            {path: 'us/investor-details', Component: UsRegistryDetails},
            {path: 'us/select', Component: SelectShares},
            {path: 'us/select/:instrumentSlug', Component: TransferSharesView},
            {
                path: 'us/select/search/:instrumentSlug',
                Component: TransferSharesSearchView,
            },
            {
                path: 'us/select-platform',
                Component: UsSelectPlatform,
            },
            {
                path: 'us/terms-and-conditions',
                Component: UsTermsAndConditions,
            },
            {path: 'us/verify-account', Component: AccountNeedsVerification},
        ],
    },
] as const
