import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {useAppSelector} from '~/store/hooks'
import {DollarValue} from '../number-elements/NumberElements'

interface BuyAndSellFeesRightsProps {
    asIcon?: boolean
    label?: string
}

const BuyAndSellFeesRights = ({asIcon, label}: BuyAndSellFeesRightsProps) => {
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    return (
        <ModalLink
            dataTestId="modal-link--buy-and-sell-rights-fees"
            label={label || 'Fees for buying, selling, and exercising rights'}
            asIcon={asIcon}
            modalTitle="Fees for buying, selling, and exercising rights"
            primaryButton={{label: 'OK, I understand'}}
            bottomBorder
            helpIconSize={16}
        >
            <h2 className={spacing.spaceBelow12}>Buying or selling rights</h2>
            <p>
                Individual buy and sell orders for rights have a 1.9% transaction fee on the cost of rights (or value to
                receive from selling rights),{' '}
                <strong>
                    capped at <DollarValue value={25} currency="nzd" decimalPlaces={0} />
                </strong>
                .
            </p>

            <h2 className={cn(spacing.spaceBelow12, spacing.spaceAbove32)}>Exercising rights</h2>

            <p>
                You won’t be charged any transaction fees to exercise your rights, but you may be charged{' '}
                <a
                    target="_blank"
                    rel="noopener"
                    href={
                        jurisdiction === 'au'
                            ? 'https://www.sharesies.com.au/pricing#currency-exchange-fee'
                            : 'https://www.sharesies.nz/pricing#currency-exchange-fee'
                    }
                >
                    currency exchange fees
                </a>{' '}
                if you need to exchange money to pay the exercise price.
            </p>

            <h2 className={cn(spacing.spaceBelow12, spacing.spaceAbove32)}>Buying and auto-exercising rights</h2>

            <p>
                If you buy and auto-exercise rights in the same order, we’ll estimate the maximum transaction fee based
                on the total order amount of the buy and auto-exercise order.
            </p>

            <p>
                Transaction fees are only charged on the amount used to buy rights, not on the amount used to exercise
                those rights. If your actual transaction fee is lower than the estimate, we’ll use the difference to buy
                more rights (balanced with the cost to exercise them) until your order is completely filled.
            </p>
        </ModalLink>
    )
}

export default BuyAndSellFeesRights
