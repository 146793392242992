import React from 'react'
import {ListingResponseDto} from '~/api/distill'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import styles from './OrderProcessMessage.scss'

const KiwiSaverOrderProcessMessage: React.FunctionComponent<{instrument: ListingResponseDto}> = ({instrument}) => {
    const isBaseFund = tradingType(instrument) === 'managed'

    return (
        <div className={styles.orderProcessMessage}>
            {isBaseFund ? (
                <>
                    <p>
                        Each fund has different processing times to fill your order and confirm the price you get. This
                        means there’s a delay between when you place an order to buy or sell, and when it shows in your
                        Sharesies KiwiSaver Scheme account. For base funds this can range from 1 to 6 trading days.
                    </p>
                    <p>
                        We start processing orders by around 11:00am each trading day and send these to the relevant
                        fund provider. Once your order is processing you can’t cancel it anymore.
                    </p>
                </>
            ) : (
                <>
                    <p>All pending order and transaction fee amounts are estimates until your order fills.</p>
                    <p>
                        Processing times will vary (and orders might not fill) depending on: if the market is open,
                        closed, or in a trading halt; where you are in the queue; and the orders ahead of you. This
                        means there may be a delay between when you place an order to buy or sell, and when it shows in
                        your KiwiSaver account.{' '}
                    </p>
                    <p>Once your order is processing you can’t cancel it anymore.</p>
                </>
            )}
        </div>
    )
}

export default KiwiSaverOrderProcessMessage
