import React from 'react'
import {useNavigate} from 'react-router-dom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {AccountVerificationRequired} from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import {ListMenuGroup, ListMenuItem} from '~/global/widgets/list-menu/ListMenu'
import Page from '~/global/widgets/page/Page'
import TabControls from '~/global/widgets/tab-controls/TabControls'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {TopUpForPlanAlert} from '~/global/widgets/top-up-for-plan-alert/TopUpForPlanAlert'
import {BankLinkingAlert} from '~/sections/OLD_wallet/widgets/bank-linking-alert/BankLinkingAlert'
import {RoundupStatus} from '~/sections/OLD_wallet/widgets/roundup-status/RoundupStatus'
import WalletUpcoming from '~/sections/OLD_wallet/widgets/upcoming-transactions/WalletUpcoming'
import {TransactionHistory} from '~/sections/wallet/pages/overview/widgets/transaction-history/TransactionHistory'
import {WalletSummary} from '~/sections/wallet/pages/overview/widgets/wallet-summary/WalletSummary'
import {useWalletPortfolio, useHistoryState} from '~/sections/wallet/state/local'
import {useLegacyUserData} from '~/sections/wallet/state/retail'

export const Overview = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const walletPortfolio = useWalletPortfolio()
    const profile = useProfile()
    const {isRestrictedAccount, hasTopupGoal} = useLegacyUserData()
    const hasSaveAccount = profile.portfolios.some(p => p.product === 'SAVE')
    const [transactionTab, setTransactionTab] = useHistoryState<'history' | 'upcoming'>(
        'overview-transaction-tab',
        'history',
    )

    return (
        <>
            <Toolbar dataTestId="toolbar--wallet" leftButton="menu" title="Wallet" />
            <Page overrideDefaultTopPadding="none">
                {/* TODO - account verification is currently tied to identity.user, needs updating for new models */}
                <AccountVerificationRequired />

                {/* TODO - bank linking is currently tied to a specific user, this may or may not be desirable, TDB. widget is stil located in OLD_wallet for now. */}
                {profile.legacy_profile_type && (
                    <BankLinkingAlert locationContext="wallet-general" wrapperClassName={spacing.spaceBelow16} />
                )}

                {/* TODO - plans currently tied to a specific user, this may or may not be desirable, TDB. This widget uses wallet balances so will need to be wallet portfolio ID sensitive down the line. */}
                {profile.legacy_profile_type && <TopUpForPlanAlert />}

                <WalletSummary />

                {/* TODO - roundup status currently tied to a specific user, same as banklinking alerts */}
                {profile.legacy_profile_type && <RoundupStatus withManageLink locationContext="wallet-roundups" />}

                <ListMenuGroup className={spacing.spaceBelow32}>
                    {/* TODO - open FX up to non-legacy profiles after the TODOs within are resolved */}
                    {profile.legacy_profile_type && (
                        <ListMenuItem
                            label="Exchange money"
                            onClick={
                                () => navigate(profileUrl('wallet/exchange-money'))
                                // TODO: After we're happy with the new FX section, we can use this URL instead
                                // profileUrl('wallet/:portfolioId/exchange-money', {portfolioId: profilePortfolio.id}),
                            }
                        />
                    )}
                    <ListMenuItem
                        label="Top up your Wallet"
                        onClick={() =>
                            navigate(profileUrl('wallet/:portfolioId/topup', {portfolioId: walletPortfolio.id}))
                        }
                        disabled={isRestrictedAccount}
                    />
                    {profile.legacy_profile_type && (
                        <ListMenuItem
                            label="Redeem a Gift"
                            onClick={() => {
                                rudderTrack('topup', 'redeem_gift_selected')
                                navigate(profileUrl('gifts/redeem-gift'))
                            }}
                            disabled={isRestrictedAccount}
                        />
                    )}
                    {profile.legacy_profile_type === 'PERSONAL' && !hasTopupGoal && (
                        <ListMenuItem
                            label="Set a money goal"
                            onClick={() => navigate(profileUrl('wallet/money-goal-intro'))}
                        />
                    )}
                    {profile.legacy_profile_type === 'PERSONAL' && hasTopupGoal && (
                        <ListMenuItem
                            label="View my money goal"
                            onClick={() => navigate(profileUrl('wallet/money-goal'))}
                        />
                    )}
                    {hasSaveAccount && (
                        <ListMenuItem
                            label="Transfer money"
                            onClick={() => {
                                navigate(profileUrl('wallet/transfer'))
                            }}
                            disabled={isRestrictedAccount}
                        />
                    )}
                    <ListMenuItem
                        label="Withdraw"
                        onClick={() => {
                            navigate(profileUrl('wallet/:portfolioId/withdrawal', {portfolioId: walletPortfolio.id}))
                        }}
                        disabled={isRestrictedAccount}
                    />
                </ListMenuGroup>

                {profile.legacy_profile_type && (
                    <TabControls
                        tabs={[
                            {label: 'History', value: 'history'},
                            {label: 'Upcoming', value: 'upcoming'},
                        ]}
                        currentTab={transactionTab}
                        onChangeTab={setTransactionTab}
                    />
                )}
                {transactionTab === 'upcoming' && profile.legacy_profile_type ? (
                    <WalletUpcoming />
                ) : (
                    <TransactionHistory />
                )}
            </Page>
        </>
    )
}

export default Overview
