import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {State, ExchangeRate} from '~/store/accounting/types'

interface FindExchangeRate {
    sourceCurrency: Currency
    targetCurrency: Currency
    exchangeRates: State['exchangeRates']
}

export const findExchangeRate: ({
    sourceCurrency,
    targetCurrency,
    exchangeRates,
}: FindExchangeRate) => ExchangeRate | undefined = ({sourceCurrency, targetCurrency, exchangeRates}) =>
    exchangeRates.find(
        exchangeRate =>
            exchangeRate.sourceCurrency === sourceCurrency && exchangeRate.targetCurrency === targetCurrency,
    )
