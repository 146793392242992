import {Button} from '@design-system/button'
import cn from 'classnames'
import {withFormik} from 'formik'
import React, {useState} from 'react'
import {spacing} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {validate, ErrorBox} from '~/global/widgets/form-controls'
import {IRDNumber, Radio} from '~/global/widgets/form-controls/formik'
import {normalizeIRDNumber} from '~/global/widgets/form-controls/ird-number/IRDNumber'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import Page from '~/global/widgets/page/Page'
import PIRCalculator from '~/global/widgets/pir-calculator/PIRCalculator'
import RWTCalculator from '~/global/widgets/rwt-calculator/RWTCalculator'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import globalWrapperStyles from '~/global/wrappers/GlobalWrapper.scss'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'
import {PIR, RWT} from '~/store/identity/types'

class FormValues {
    ird_number?: string
    pir?: PIR
    rwt?: RWT
}

interface FormOwnProps {
    externalSubmitHandler: OwnProps['externalSubmitHandler']
    externalBackHandler: OwnProps['externalBackHandler']
    irdNumber?: string
    isDependent: boolean
    preferredName: string
    pir?: PIR
    pirRequired?: boolean
    rwt?: RWT
    rwtRequired?: boolean
    updateFinancialDetails: DispatchProps['updateFinancialDetails']
}

const RWT_CHOICES = ['10.5', '17.5', '30', '33', '39']

const InlineTaxForm = withFormik<FormOwnProps, FormValues>({
    mapPropsToValues: ({irdNumber, pir, rwt}) => ({
        ird_number: normalizeIRDNumber(irdNumber || '', ''),
        pir,
        rwt,
    }),
    mapPropsToErrors: ({irdNumber}) => {
        // make the button disabled initially if there is an initial error by setting at least one field to have an error
        if (!irdNumber) {
            return {ird_number: undefined}
        }
        // if there is an existing IRD number, assume it is valid
        return {}
    },
    handleSubmit: async (
        {ird_number, pir, rwt},
        {setSubmitting, setStatus, props: {updateFinancialDetails: updateFinancialDetails, externalSubmitHandler}},
    ) => {
        try {
            const updateError = await updateFinancialDetails(ird_number!, pir!, rwt!)
            if (updateError) {
                setStatus(updateError)
                return
            }
            const externalError = await externalSubmitHandler()
            if (externalError) {
                setStatus(externalError)
                return
            }
        } catch (e) {
            setStatus(unknownErrorMessage)
            throw e
        } finally {
            setSubmitting(false)
        }
    },
    validate: async (values, {pirRequired, rwtRequired}) => {
        // We need props.pirRequired/rwtRequired to determine if we need to validate pir/rwt.
        // Our validation lib doesn't have a props context so we roll our own validation function here
        const validateIrdNumber = validate.irdNumber()
        const errors: {ird_number?: string; pir?: string; rwt?: string} = {}

        if (!values.ird_number) {
            errors.ird_number = 'This field is required'
        } else {
            const irdNumberValidError = await validateIrdNumber(values.ird_number, values)

            if (irdNumberValidError) {
                errors.ird_number = irdNumberValidError
            }
        }

        if (pirRequired) {
            if (!values.pir) {
                errors.pir = 'This field is required'
            } else {
                const pirValidError = await validate.choices('10.5', '17.5', '28')(values.pir, values)

                if (pirValidError) {
                    errors.pir = pirValidError
                }
            }
        }

        if (rwtRequired) {
            if (!values.rwt) {
                errors.rwt = 'This field is required'
            } else {
                const rwtValidError = await validate.choices(...RWT_CHOICES)(values.rwt, values)

                if (rwtValidError) {
                    errors.pir = rwtValidError
                }
            }
        }

        if (Object.keys(errors).length) {
            return errors
        }
    },
})(({
    isSubmitting,
    handleSubmit,
    isValid,
    status,
    setFieldValue,
    isDependent,
    preferredName,
    externalBackHandler,
    pirRequired,
    rwtRequired,
    irdNumber,
}) => {
    const [pirCalculatorVisible, setPirCalculatorVisible] = useState(false)
    const [rwtCalculatorVisible, setRwtCalculatorVisible] = useState(false)

    const rwtRadioChoices = RWT_CHOICES.map(x => {
        return {value: x, label: `${x}%`}
    })

    return (
        <>
            <Toolbar
                dataTestId="toolbar--inline-tax-nz"
                leftButton="back"
                title="We need some tax info"
                onLeftButtonClick={externalBackHandler}
            />
            <form onSubmit={handleSubmit} className={globalWrapperStyles.fullScreen}>
                <Page>
                    {rwtRequired ? (
                        irdNumber ? (
                            <p className={spacing.spaceBelow24}>
                                Enter {isDependent ? `${preferredName}’s` : 'your'} resident withholding tax (RWT) rate
                                so we can take care of the RWT on interest{' '}
                                {isDependent ? `${preferredName} earns` : 'you earn'}.
                            </p>
                        ) : (
                            <p className={spacing.spaceBelow24}>
                                Enter your IRD number and resident withholding tax (RWT) rate so we can take care of the
                                RWT on interest you earn.
                            </p>
                        )
                    ) : null}

                    {!rwtRequired && (
                        <p className={spacing.spaceBelow24}>
                            Sharesies calculates NZ tax for you so we need some information to make sure we pay it to
                            the right account. For more details check out the <HelpCentreLink nzArticle="1399438-tax" />
                            .
                        </p>
                    )}

                    {!irdNumber && (
                        <div className={cn(spacing.spaceAbove24, spacing.spaceBelow24)}>
                            <IRDNumber dataTestId="text-input--ird-number" name="ird_number" label="IRD number" />
                        </div>
                    )}

                    {pirRequired && (
                        <>
                            <Radio
                                dataTestId="radio--select-pir"
                                label={isDependent ? `Select ${preferredName}’s PIR` : 'Select your PIR'}
                                name="pir"
                                choices={[
                                    {value: '10.5', label: '10.5%'},
                                    {value: '17.5', label: '17.5%'},
                                    {value: '28', label: '28%'},
                                ]}
                            />
                            <p className={spacing.spaceBelow24}>
                                If you don’t know {isDependent ? `${preferredName}’s` : 'your'} PIR, just{' '}
                                <ButtonAsLink
                                    onClick={() => {
                                        setPirCalculatorVisible(true)
                                    }}
                                >
                                    answer these questions
                                </ButtonAsLink>
                                .
                                <PIRCalculator
                                    isVisible={pirCalculatorVisible}
                                    onSelect={pir => {
                                        setPirCalculatorVisible(false)
                                        setFieldValue('pir', pir)
                                    }}
                                    onClose={() => setPirCalculatorVisible(false)}
                                    isDependent={isDependent}
                                    preferredName={preferredName}
                                />
                            </p>
                        </>
                    )}
                    {rwtRequired && (
                        <>
                            <Radio
                                dataTestId="radio--select-rwt"
                                label={isDependent ? `Select ${preferredName}’s RWT` : 'Select your RWT rate'}
                                name="rwt"
                                choices={rwtRadioChoices}
                                placeholder=""
                            />
                            <p className={spacing.spaceBelow24}>
                                Don’t know {isDependent ? `${preferredName}’s` : 'your'} RWT rate? No worries, just{' '}
                                <ButtonAsLink
                                    onClick={() => {
                                        setRwtCalculatorVisible(true)
                                    }}
                                >
                                    answer these questions
                                </ButtonAsLink>
                                .
                                <RWTCalculator
                                    isVisible={rwtCalculatorVisible}
                                    onSelect={rwt => {
                                        setRwtCalculatorVisible(false)
                                        setFieldValue('rwt', rwt)
                                    }}
                                    onClose={() => setRwtCalculatorVisible(false)}
                                    isDependent={isDependent}
                                    preferredName={preferredName}
                                />
                            </p>
                        </>
                    )}
                    <ErrorBox message={status} />
                </Page>
                <ActionBar>
                    <Button
                        dataTestId="button--save-tax-info"
                        isSubmit
                        label={rwtRequired ? 'Next' : 'Save tax info'}
                        disabled={!isValid}
                        processing={isSubmitting}
                    />
                </ActionBar>
            </form>
        </>
    )
})

export class BuyTaxInfo extends React.PureComponent<Props, {}> {
    render() {
        const {
            isDependent,
            preferredName,
            updateFinancialDetails,
            externalSubmitHandler,
            externalBackHandler,
            irdNumber,
            pir,
            pirRequired,
            rwt,
            rwtRequired,
        } = this.props

        return (
            <InlineTaxForm
                updateFinancialDetails={updateFinancialDetails}
                externalSubmitHandler={externalSubmitHandler}
                externalBackHandler={externalBackHandler}
                isDependent={isDependent}
                preferredName={preferredName}
                irdNumber={irdNumber}
                pir={pir}
                pirRequired={pirRequired}
                rwt={rwt}
                rwtRequired={rwtRequired}
            />
        )
    }
}

interface StoreProps {
    isDependent: boolean
    preferredName: string
    irdNumber?: string
    pir?: PIR
    rwt?: RWT
}

interface DispatchProps {
    updateFinancialDetails(irdNumber: string, pir: PIR, rwt: RWT): Promise<string | null>
}

interface OwnProps {
    pirRequired?: boolean
    rwtRequired?: boolean
    externalSubmitHandler(): Promise<string | undefined> | void
    externalBackHandler?(): void
}

type Props = StoreProps & DispatchProps & OwnProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    state => ({
        isDependent: state.identity.user!.is_dependent || false,
        preferredName: state.identity.user!.preferred_name || '',
        irdNumber: state.identity.user!.ird_number,
        pir: state.identity.user!.pir,
        rwt: state.identity.user!.rwt,
    }),
    dispatch => ({
        updateFinancialDetails: (irdNumber: string, pir: PIR, rwt: RWT) =>
            dispatch(actions.UpdateFinancialDetails(irdNumber, pir, rwt)),
    }),
)(BuyTaxInfo)
