import {DateTime} from 'luxon'
import React from 'react'
import * as rollbar from '~/api/rollbar/rollbar'
import config from '~/configForEnv'
import {useLocation, useNavigate} from '~/migrate-react-router'

/*
Broadly we have a function that runs every timeoutPeriod to check if service worker is updated.
If service worker is updated, we install the updated service worker and activate it next time
when user open the app again. (When the tab is visible again or the home screen app is on
foreground instead of multitasking page.)
*/

const ServiceWorkerUpdateCheck: React.FunctionComponent = React.memo(() => {
    const navigate = useNavigate()
    const location = useLocation()
    const [pageReloadPending, setPageReloadPending] = React.useState(false)
    const [inactiveSince, setInactiveSince] = React.useState<DateTime | null>(null)
    const [isVisible, setIsVisible] = React.useState(true)
    const [registration, setRegistration] = React.useState<ServiceWorkerRegistration | null>(null)

    const registerServiceWorker = async () => {
        const newRegistration = await navigator.serviceWorker.register('/service-worker.js')
        setRegistration(newRegistration)
    }

    const checkForServiceWorkerUpdates = () => {
        if (registration) {
            /*
            The browser only checks for updates automatically after navigations and functional events.
            But because we expect the user to be using Sharesies PWA for a long time without reloading,
            we're updating service worker manually here. Calling this method checks to see if the
            application has updated. The service worker script will be automatically updated.
            */
            registration.update()
        }
    }

    const visibilityChangeHandler = () => {
        // The page content may be at least partially visible. In practice this means that the page
        // is the foreground tab of a non-minimized window.
        setIsVisible(document.visibilityState === 'visible')
    }

    const updateFoundHandler = () => {
        setPageReloadPending(true)
    }

    React.useEffect(() => {
        // Register service worker immediately
        if (!registration) {
            registerServiceWorker()
        }

        document.addEventListener('visibilitychange', visibilityChangeHandler)

        return () => {
            document.removeEventListener('visibilitychange', visibilityChangeHandler)
        }
    }, [])

    React.useEffect(() => {
        // Visible and there has been an update
        if (
            isVisible &&
            inactiveSince &&
            inactiveSince < DateTime.local().minus(config.serviceWorkerUpdateCheckInterval.invisible) &&
            pageReloadPending
        ) {
            if (location.pathname.includes('live-market-data')) {
                /*
                Refreshing the live market depth page would fetch live market data API, and that will cost money.
                So, instead of refreshing the live market data page, we're redirecting users to where the came
                from to get to live market depth, if they were on that page.
                */
                navigate(-1)
            }

            rollbar.sendDebug('Reloading the page by Service Worker', {
                isPWA: !!navigator.standalone,
            })
            window.location.replace(window.location.href) // force reload rather than soft reload
        }
    }, [inactiveSince, isVisible, pageReloadPending])

    React.useEffect(() => {
        if (!isVisible) {
            // Not visible - set the time the page went inactive at
            setInactiveSince(DateTime.local())
        } else {
            // clear it
            setInactiveSince(null)
        }

        // When the page becomes visible, and if we haven't already seen the page update, check for update immediately.
        if (isVisible && !pageReloadPending) {
            checkForServiceWorkerUpdates()
        }

        // As the visibility just changed, reset when the next timer will run
        const updateCheckTimer = setInterval(
            () => {
                checkForServiceWorkerUpdates()
            },
            isVisible
                ? config.serviceWorkerUpdateCheckInterval.visible
                : config.serviceWorkerUpdateCheckInterval.invisible,
        )

        // Clean up the timer
        return () => {
            clearInterval(updateCheckTimer)
        }
    }, [isVisible, registration]) // the timer needs to be re-setup once we have the registration

    React.useEffect(() => {
        // registration has been set up - listen to updates of the Service Worker.
        // If it has updated, we want to reload the page at the next opportunity.
        if (registration) {
            registration.addEventListener('updatefound', updateFoundHandler)
        }
        return () => {
            // we need to clean up this listener, because the registration will persist beyond the unmount
            if (registration) {
                registration.removeEventListener('updatefound', updateFoundHandler)
            }
        }
    }, [registration])

    return null
})

export default ServiceWorkerUpdateCheck
