import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import check from '~/global/assets/images/check.svg'
import {page} from '~/global/scss/helpers'
import spacing from '~/global/scss/helpers/spacing.scss'
import {dateFormatDayAndMonthWithTime} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'
import {getInstrumentFromSlug} from '~/store/instrument/selectors'
import orderActions from '~/store/order/actions'
import styles from './ApplyNextDetail.scss'

interface Props {
    instrumentSlug: string
    applicationRuleId: string
}

const ApplyNextDetail = ({instrumentSlug, applicationRuleId}: Props) => {
    /**
     * Shows after ApplyConfirm for applications which have a link to a subsequent application.
     *
     * For example: an exercise application for a rights instrument may have a logical
     * subsequent application (applying for more rights) which we have manually linked together.
     */
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const instrument = useAppSelector(s => getInstrumentFromSlug(s, instrumentSlug))
    const parentInstrument =
        instrument && instrument.parentInstrumentId ? instrumentsById[instrument.parentInstrumentId] : undefined
    const applications = useAppSelector(s => (instrument ? s.order.applicationsByInstrument[instrument.id] : undefined))
    const parentApplications = useAppSelector(s =>
        parentInstrument ? s.order.applicationsByInstrument[parentInstrument.id] : undefined,
    )

    // load the instruments and their applications
    React.useEffect(() => {
        if (!instrument) {
            dispatch(instrumentActions.getSingleInstrumentBySlug(instrumentSlug))
        } else {
            dispatch(orderActions.LoadInstrumentApplications(instrument.id))
        }

        if (instrument?.parentInstrumentId && !parentInstrument) {
            dispatch(instrumentActions.getSingleInstrumentById(instrument.parentInstrumentId))
        } else if (parentInstrument) {
            dispatch(orderActions.LoadInstrumentApplications(parentInstrument.id))
        }
    }, [instrument, parentInstrument])

    // pre load all child instruments of the parent, for the exercise rights before shortfall modal
    React.useEffect(() => {
        if (parentInstrument && !parentInstrument.childInstrumentIds) {
            dispatch(instrumentActions.getChildInstrumentsByParentId(parentInstrument.id))
        }
        if (parentInstrument && parentInstrument.childInstrumentIds) {
            dispatch(instrumentActions.getInstrumentsByIds(parentInstrument.childInstrumentIds))
        }
    }, [parentInstrument])

    const onCancel = () => {
        // go back to the view instrument page, can do this naively due to no conditional steps in the application flow
        navigate(-3)
    }

    const onContinue = () => {
        // setup the next application with the pushed from other application flag
        if (subsequentApplication !== undefined) {
            dispatch(orderActions.InitialiseStagedApplication(subsequentApplication!))
            dispatch(orderActions.UpdatePushedFromOtherApplication())
        }

        navigate(
            profileUrl('invest/:instrumentSlug/apply/:applicationRuleId', {
                instrumentSlug: parentInstrument!.urlSlug,
                applicationRuleId: currentApplication!.subsequent_share_purchase_application_rule_id!,
            }),
        )
    }

    if (instrument && instrument.instrumentType !== 'rights') {
        navigate(profileUrl('explore'))
    }

    if (!instrument || !parentInstrument) {
        return <Loading isPineapple />
    }

    const currentApplication = applications
        ? applications.applications?.find(a => a.application_rule_id === applicationRuleId)
        : undefined

    const subsequentApplication = parentApplications
        ? parentApplications.applications.find(
              a =>
                  a.type === 'PURCHASE' &&
                  a.applications_close_at &&
                  a.application_rule_id === currentApplication?.subsequent_share_purchase_application_rule_id,
          )
        : undefined

    return (
        <Page>
            <img className={cn(styles.image, spacing.spaceAbove12, spacing.spaceBelow32)} src={check} alt="" />
            <div>
                <h1 className={cn(styles.heading, spacing.spaceBelow16)} data-testid="heading--order-placed">
                    You’ve exercised your rights!
                </h1>
                <p className={spacing.spaceBelow24}>
                    Now that you’ve exercised all of your rights, you can choose to apply for more shares in the
                    shortfall.
                </p>
                <p className={spacing.spaceBelow24}>
                    The shortfall is an opportunity to apply to buy shares that aren’t taken up by other investors in
                    the rights offer.
                </p>
                <p className={spacing.spaceBelow24}>
                    If you choose not to apply now, you can apply up until{' '}
                    {subsequentApplication?.applications_close_at?.toFormat(dateFormatDayAndMonthWithTime)} by going to
                    your {parentInstrument.symbol} shares in your Portfolio.
                </p>
                <p className={spacing.spaceBelow32}>
                    Learn more about applying in our{' '}
                    <HelpCentreLink
                        auArticle="7881318-take-part-in-a-shortfall"
                        nzArticle="7881316-take-part-in-a-shortfall"
                    >
                        help centre article on shortfalls
                    </HelpCentreLink>
                    .
                </p>
            </div>
            <p className={cn(spacing.spaceBelow24, page.flexRow)}>
                <span>
                    <Button
                        type="secondary"
                        label="Not right now"
                        dataTestId="button--apply-not-right-now"
                        onClick={onCancel}
                    />
                </span>
                <span>
                    <Button label="Apply for more" dataTestId="button--apply-more" onClick={onContinue} />
                </span>
            </p>
        </Page>
    )
}
export default ApplyNextDetail
