import {Close} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {FocusOn} from 'react-focus-on'
import {accessibility} from '~/global/scss/helpers'
import styles from './DetailModal.scss'

interface DetailModalProps {
    className?: string
    children: React.ReactNode
    isOpen: boolean
    closeHandler: () => void
    closeColourWhite?: boolean
}

export const DetailModal: React.FunctionComponent<DetailModalProps> = ({
    children,
    isOpen,
    className,
    closeHandler,
    closeColourWhite,
}) => {
    if (!isOpen) {
        return null
    }

    const onKeyDownHandler = (e: React.KeyboardEvent) => {
        if (e.key === 'Escape') {
            closeHandler()
        }
    }

    return (
        <FocusOn enabled={isOpen} noIsolation>
            <div className={styles.overlay} onClick={closeHandler} />
            <div className={cn([styles.info, closeColourWhite && styles.closeColourWhite])}>
                <button
                    data-testid="detail-modal--close"
                    className={cn(styles.closeButton, accessibility.button)}
                    type="button"
                    onClick={closeHandler}
                    onKeyDown={onKeyDownHandler}
                    title="Close"
                >
                    <Close />
                </button>

                <div className={cn(styles.contentWrapper, className)}>{children}</div>
            </div>
        </FocusOn>
    )
}

export default DetailModal
