import cn from 'classnames'
import React, {useState} from 'react'
import {usePopper, PopperChildrenProps} from 'react-popper'
import {accessibility} from '~/global/scss/helpers'
import tooltipStyles from '~/global/scss/reused-styles/tooltip.scss'
import {getScreenReaderFriendlyTimePeriod} from '~/global/utils/time-period/timePeriod'

interface TooltipProps {
    children: React.ReactNode[]
    placement?: PopperChildrenProps['placement']
}

const Label: React.FunctionComponent<{className?: string}> = ({children, className}) => (
    <span className={cn(tooltipStyles.label, className)}>{children}</span>
)
const Body: React.FunctionComponent<{className?: string}> = ({children, className}) => (
    <div className={cn(tooltipStyles.tooltip, className)}>{children}</div>
)

/**
 * This component uses the react-popper library for the purpose of flexible positioning based on the size of the viewport.
 *
 * Refs: the ref attribute of both the divs wrapping the tooltip/reference element will contain the state of the reference element (what the tooltip is
 * positioned relatively to) and the state of the tooltip element respectively, as to keep record of/change their state.
 *
 * usePopper: this is the hook used to formulate the tooltip's styling/attributes. The props must contain the reference element's state, and the tooltip's state.
 *
 * placement: The 'placement' prop is used to set the default placement of the tooltip (defaults to 'auto').
 *
 * flip (modifier): The 'flip' modifier is used to determine what the tooltip's 'placement' (fallbackPlacements) should be if the default placement results in the
 * tooltip overflowing the viewport. The fallback placement will be the first placement in the array that results in the tooltip not overflowing
 * the viewport.
 *
 * eventListeners (modifier): determines whether the tooltip's placement will be recalculated on scroll or viewport resizing.
 *
 * attributes: destructure this object in the div that wraps the tooltip component.
 *
 * popperStyles: use this in the style attribute of the div that wraps the tooltip component to apply the correct positioning to the tooltip.
 *
 * ```
 * <Tooltip>
 *     <Tooltip.Label>Hover over me</Tooltip.Label>
 *     <Tooltip.Body>I'm some extra detail people will see when I'm hovered on</Tooltip.Body>
 * </Tooltip>
 * ```
 */
export const Tooltip: React.FunctionComponent<TooltipProps> & {Label: typeof Label; Body: typeof Body} = ({
    children,
    placement,
}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
    const [tooltipElement, setTooltipElement] = useState<HTMLDivElement | null>(null)
    const {styles: popperStyles, attributes} = usePopper(referenceElement, tooltipElement, {
        placement: placement || 'top-start',
        modifiers: [
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['top-end', 'top'],
                },
            },
            {
                name: 'eventListeners',
                options: {
                    scroll: false,
                },
            },
        ],
    })

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            setTooltipOpen(!tooltipOpen)
        }

        if (e.key === 'Escape') {
            setTooltipOpen(false)
        }
    }

    const elementId = referenceElement?.innerText || 'tooltip'

    return (
        <div
            aria-label={getScreenReaderFriendlyTimePeriod(elementId)}
            className={cn(accessibility.button, tooltipStyles.inlineBlock)}
            role="button"
            tabIndex={0}
            onBlur={() => setTooltipOpen(false)}
            onKeyDown={handleKeyDown}
            onMouseOut={() => setTooltipOpen(false)}
        >
            <div
                ref={setReferenceElement}
                onMouseOver={() => setTooltipOpen(true)}
                className={cn(tooltipStyles.tooltipTrigger, tooltipStyles.inlineBlock, {
                    [tooltipStyles.tooltipShown]: tooltipOpen,
                })}
            >
                {children[0]}
            </div>
            {tooltipOpen && (
                <div
                    ref={setTooltipElement}
                    className={tooltipStyles.tooltipWrapper}
                    id={elementId}
                    role="tooltip"
                    aria-live="polite"
                    style={popperStyles.popper}
                    onClick={() => setTooltipOpen(false)}
                    {...attributes.popper}
                >
                    {children.map(child => {
                        if (children.indexOf(child) !== 0) {
                            return child
                        }
                    })}
                </div>
            )}
        </div>
    )
}
Tooltip.Label = Label
Tooltip.Body = Body

export default Tooltip
