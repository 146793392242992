/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    ExchangeCalendarDto,
    ExchangeCalendarDtoFromJSON,
    ExchangeCalendarDtoFromJSONTyped,
    ExchangeCalendarDtoToJSON,
    ExchangeCalendarDtoToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface ExchangeCalendarResponseDto
 */
export interface ExchangeCalendarResponseDto {
    /**
     *
     * @type {Array<ExchangeCalendarDto>}
     * @memberof ExchangeCalendarResponseDto
     */
    calendarInfo: Array<ExchangeCalendarDto>
}

export function ExchangeCalendarResponseDtoFromJSON(json: any): ExchangeCalendarResponseDto {
    return ExchangeCalendarResponseDtoFromJSONTyped(json, false)
}

export function ExchangeCalendarResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ExchangeCalendarResponseDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        calendarInfo: (json['calendarInfo'] as Array<any>).map(ExchangeCalendarDtoFromJSON),
    }
}

export function ExchangeCalendarResponseDtoToJSON(value?: ExchangeCalendarResponseDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        calendarInfo: (value.calendarInfo as Array<any>).map(ExchangeCalendarDtoToJSON),
    }
}
