import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import maintenance from '~/global/pages/maintenance-page/assets/images/maintenance.png'
import {spacing} from '~/global/scss/helpers'
import HelpEmail from '~/global/widgets/help-email/HelpEmail'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from './MaintenancePage.scss'

const MaintenancePage = () => (
    <div className={styles.fullPage}>
        <Toolbar dataTestId="toolbar--maintenance" title="Maintenance" isInlineTitle />
        <Page className={styles.wrapper}>
            <div className={styles.content}>
                <h1 className={cn(spacing.spaceBelow80, styles.header)}>
                    Back soon—we’re just giving Sharesies some love
                </h1>
                <img src={maintenance} className={styles.image} alt="" loading="lazy" width={360} height={240} />
                <div className={cn(spacing.spaceAbove80, styles.copy)}>
                    <p>
                        Thanks for keeping your cool while Sharesies gets a routine tune-up—we’ll be wrapped up as soon
                        as we can!
                    </p>
                    <p>
                        If you need help in the meantime, just flick us an email: <HelpEmail />.
                    </p>
                </div>
                <Button
                    label="Are we there yet?"
                    onClick={() => window.location.reload()}
                    pageButton
                    dataTestId="button--reload"
                />
            </div>
        </Page>
    </div>
)

export default MaintenancePage
