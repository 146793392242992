import {Button} from '@design-system/button'
import {RecurringTopUp, RoundUps, SpareChange} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {Link} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useRecurringPayment} from '~/sections/OLD_wallet/sections/linked-bank-payments/state'
import styles from '~/sections/invest/sections/bank-linking/BankLinking.scss'
import {useAppSelector} from '~/store/hooks'
import {OnNextProps} from '../RoundupsSetup'

const NextSteps = ({onBack, onNext}: OnNextProps) => {
    const profileUrl = useProfileUrl()
    const isRoundupsSetup = useAppSelector(s => s.bankLinking.isRoundupsEnabled)
    const isRecurringPaymentSetup = useRecurringPayment()

    return (
        <>
            <Toolbar dataTestId="toolbar--roundups-tracking" leftButton="back" onLeftButtonClick={onBack} />
            <Page>
                <h1 className={cn(styles.h1, spacing.spaceBelow20)}>Making the most of your linked bank account</h1>
                <p className={spacing.spaceBelow20}>
                    Now you’ve linked your bank, you can turn on round-ups and set up a recurring top up.
                </p>
                {!isRoundupsSetup && (
                    <div className={styles.anotherBankLinkingFeature}>
                        <RoundUps width={16} />
                        <div>
                            <h2>Round-ups</h2>
                            <p>
                                Get automatic top-ups to your Wallet using the leftover cents from your day-to-day
                                spending.
                            </p>
                            <Link to={profileUrl('explore/roundups')}>Turn on round-ups</Link>
                        </div>
                    </div>
                )}
                {!isRecurringPaymentSetup && (
                    <div className={styles.anotherBankLinkingFeature}>
                        <RecurringTopUp />
                        <div>
                            <h2>Recurring top-up</h2>
                            <p>
                                Set a top-up amount, frequency, and start date, then your Wallet will be topped up
                                regularly from your linked bank.
                            </p>
                            <Link to={profileUrl('wallet/linked-bank-topup/recurring')}>Set up a recurring top-up</Link>
                        </div>
                    </div>
                )}
                <div className={styles.anotherBankLinkingFeature}>
                    <SpareChange width={16} />
                    <div>
                        <h2>One-off top-up</h2>
                        <p>Seamlessly transfer money from your linked bank to your Wallet.</p>
                        <Link to={profileUrl('wallet/linked-bank-topup/one-off')}>Make a one-off top-up</Link>
                    </div>
                </div>
            </Page>
            <ActionBar>
                <div className={styles.stackedButtonGroup}>
                    <Button dataTestId="button--next" type="secondary" label="I'm good for now" onClick={onNext} />
                </div>
            </ActionBar>
        </>
    )
}
export default NextSteps
