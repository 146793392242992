import React from 'react'
import {usePortfolioItemsById} from '~/global/state-hooks/mixed-source/usePortfolioItemsById'
import SortAndFilter from '~/global/widgets/sort-and-filter/SortAndFilter'
import portfolioStyles from '~/sections/invest/sections/portfolio/Portfolio.scss'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import {PortfolioFilterOptions, PortfolioSortOptions} from '~/store/identity/types'
import instrumentActions from '~/store/instrument/actions'
import {Instrument} from '~/store/instrument/types'
import {PortfolioItems} from '~/store/portfolio/types'

type Options = {
    id: string
    name: string
}[]

const portfolioFilterOptions: Options = [
    {
        id: 'RISK',
        name: 'Risk',
    },
    {
        id: 'COUNTRY',
        name: 'Country',
    },
    {
        id: 'TYPE',
        name: 'Type',
    },
]

export const portfolioSortOptions: Options = [
    {
        id: 'ALPHABETICAL',
        name: 'A-Z',
    },
    {
        id: 'HIGHEST_RETURNS',
        name: 'Highest returns',
    },
    {
        id: 'LOWEST_RETURNS',
        name: 'Lowest returns',
    },
    {
        id: 'HIGHEST_VALUE',
        name: 'Highest investment value',
    },
    {
        id: 'LOWEST_VALUE',
        name: 'Lowest investment value',
    },
]

type InstrumentId = string

export const sortByHighestReturns = (
    portfolioItems: PortfolioItems,
    includeSoldInvestments: boolean,
): InstrumentId[] => {
    return Object.values(portfolioItems)
        .sort((a, b) =>
            includeSoldInvestments
                ? b.simpleReturn - a.simpleReturn
                : b.unrealisedSimpleReturn - a.unrealisedSimpleReturn,
        )
        .map(portfolioItem => portfolioItem.instrumentId)
}

export const sortByLowestReturns = (
    portfolioItems: PortfolioItems,
    includeSoldInvestments: boolean,
): InstrumentId[] => {
    return Object.values(portfolioItems)
        .sort((a, b) =>
            includeSoldInvestments
                ? a.simpleReturn - b.simpleReturn
                : a.unrealisedSimpleReturn - b.unrealisedSimpleReturn,
        )
        .map(portfolioItem => portfolioItem.instrumentId)
}

export const sortByHighestValue = (portfolioItems: PortfolioItems): InstrumentId[] => {
    return Object.values(portfolioItems)
        .sort((a, b) => b.investmentValue - a.investmentValue)
        .map(portfolioItem => portfolioItem.instrumentId)
}

export const sortByLowestValue = (portfolioItems: PortfolioItems): InstrumentId[] => {
    return Object.values(portfolioItems)
        .sort((a, b) => a.investmentValue - b.investmentValue)
        .map(portfolioItem => portfolioItem.instrumentId)
}

export const sortAlphabetically = (
    instrumentIds: InstrumentId[],
    instrumentsById: {[instrumentId: InstrumentId]: Instrument},
) => {
    const instruments: Instrument[] = []
    instrumentIds.forEach(id => instrumentsById[id] && instruments.push(instrumentsById[id]))
    return instruments.sort((a, b) => a.name.localeCompare(b.name)).map(instrument => instrument.id)
}

export const portfolioSortSwitch = (
    sortId: string,
    instrumentsById: {[instrumentId: InstrumentId]: Instrument},
    sortedIds: InstrumentId[],
    totalPortfolioItems: PortfolioItems,
    includeSoldInvestments: boolean,
) => {
    switch (sortId) {
        case 'ALPHABETICAL':
            sortedIds = sortAlphabetically(sortedIds, instrumentsById)
            break
        case 'HIGHEST_RETURNS':
            sortedIds = sortByHighestReturns(totalPortfolioItems, includeSoldInvestments)
            break
        case 'LOWEST_RETURNS':
            sortedIds = sortByLowestReturns(totalPortfolioItems, includeSoldInvestments)
            break
        case 'HIGHEST_VALUE':
            sortedIds = sortByHighestValue(totalPortfolioItems)
            break
        case 'LOWEST_VALUE':
            sortedIds = sortByLowestValue(totalPortfolioItems)
            break
        default:
            sortedIds = sortAlphabetically(sortedIds, instrumentsById) // default is to sort alphabetically
    }
    return sortedIds
}

export const PortfolioFilterAndSort: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const updatePortfolioFilterPreference = (filterPreference: PortfolioFilterOptions) =>
        dispatch(identityActions.UpdatePortfolioFilterPreference(filterPreference))
    const clearAllPortfolioInstrumentSubfilters = (instrumentIds: string[]) =>
        dispatch(instrumentActions.clearAllPortfolioInstrumentSubfilters(instrumentIds))
    const filterPreference = useAppSelector(s => s.identity.portfolioFilterPreference)
    const sortPreference = useAppSelector(s => s.identity.portfolioSortPreference)
    const totalPortfolioItems = usePortfolioItemsById()
    const portfolioInstrumentIds = Object.keys(totalPortfolioItems)

    const filterChange = (filterId: PortfolioFilterOptions) => {
        {
            return (
                updatePortfolioFilterPreference(filterId), clearAllPortfolioInstrumentSubfilters(portfolioInstrumentIds)
            )
        }
    }

    const sortChange = (sortId: PortfolioSortOptions) => {
        dispatch(identityActions.UpdatePortfolioSortPreference(sortId))
    }

    return (
        <div className={portfolioStyles.sortFilterWrapper}>
            <SortAndFilter
                currentFilter={
                    filterPreference
                        ? portfolioFilterOptions.find(filter => filter.id === filterPreference)
                        : portfolioFilterOptions.find(filter => filter.id === portfolioFilterOptions[0].id)
                }
                currentSort={
                    sortPreference
                        ? portfolioSortOptions.find(sort => sort.id === sortPreference.toString())
                        : portfolioSortOptions.find(sort => sort.id === portfolioSortOptions[0].id)
                }
                defaultSortId={portfolioSortOptions[0].id}
                filterOptions={portfolioFilterOptions}
                onFilterMenuClick={(filterId: PortfolioFilterOptions) => {
                    filterChange(filterId)
                }}
                onSortMenuClick={(sortId: string) => {
                    sortChange(sortId as PortfolioSortOptions)
                }}
                sortOptions={portfolioSortOptions}
            />
        </div>
    )
}
