import {ClassicCard} from '@braze/web-sdk'
import React from 'react'
import {logCardClick} from '~/api/braze/braze'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {isInternalLink, normalizeUrl} from '~/global/utils/normalize-url/normalizeUrl'
import AnnouncementDetail from '~/global/widgets/announcement-card/AnnouncementDetail'
import {Link} from '~/migrate-react-router'
import DividendLink from './DividendLink'
import styles from './NotificationClassicCard.scss'

export interface CardExtras {
    category: string
    category_label: string
    instrument_id: string
    unsubscribe_category: string
}

interface AnnouncementExtras extends CardExtras {
    show_as_announcement?: 'true'
    body_html: string
    image_url: string
    button_text?: string
}
const NotificationCardLink = ({card}: {card: ClassicCard}) => {
    const [showAnnouncement, setShowAnnouncement] = React.useState(false)
    const toggleAnnouncement = () => setShowAnnouncement(!showAnnouncement)

    const announcementExtras: AnnouncementExtras = card.extras as AnnouncementExtras

    const linkClicked = (e: React.MouseEvent<HTMLElement>) => {
        if (announcementExtras?.show_as_announcement === 'true') {
            // If `show_as_announcement` is set (has to be the string "true", isn't a real boolean) then ignore the link
            // click and show the rest of the announcement details
            e.preventDefault()
            toggleAnnouncement()
        }
        rudderTrack('notifications', 'notification_link_clicked', {
            link: card.url ? card.url : '',
            instrument_id: card.extras?.instrument_id || '',
            reinvest_amount: '',
        })
    }

    let cardLink = <></>

    if (card) {
        const category = announcementExtras?.category

        switch (category) {
            case 'corporate_action_dividend_cash_payout':
            case 'corporate_action_dividend_cash':
                return <DividendLink card={card} />
            default:
                if (card.linkText && card?.url) {
                    const cleanUrl = normalizeUrl(card.url)
                    const linkProps = {
                        onClick: linkClicked,
                        children: card.linkText,
                    }

                    cardLink = (
                        <div className={styles.cardLink} onClick={() => logCardClick(card)}>
                            {isInternalLink(cleanUrl) ? (
                                <Link to={cleanUrl} {...linkProps} />
                            ) : (
                                <a href={cleanUrl} {...linkProps} />
                            )}
                        </div>
                    )
                }
        }
    }

    if (card.linkText && card.url) {
        return (
            <>
                {cardLink}
                {showAnnouncement && (
                    <AnnouncementDetail isOpen={showAnnouncement} onClose={toggleAnnouncement} card={card} />
                )}
            </>
        )
    }

    return null
}

export default NotificationCardLink
