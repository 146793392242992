import React from 'react'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {IntroSlides} from '~/global/widgets/intro-slides/IntroSlides'
import {useNavigate} from '~/migrate-react-router'
import {
    ExchangeFeesContent,
    ExchangeMoneyContent,
    ExchangeRateContent,
} from '~/sections/explore/pages/learn-static-content/LearnContent'

export const ExchangeMoneyIntro: React.FC = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const nextPage = () => {
        navigate(profileUrl('wallet/exchange-money'), {replace: true})
    }

    return (
        <IntroSlides
            content={[ExchangeMoneyContent, ExchangeRateContent, ExchangeFeesContent]}
            flag="exchange_investor"
            onComplete={nextPage}
            layout="classicIntro"
        />
    )
}

export default ExchangeMoneyIntro
