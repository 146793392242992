import cn from 'classnames'
import React from 'react'
import detailModalStyles from '~/global/widgets/detail-modals/DetailModal.scss'
import {DollarValue, PercentValue} from '~/global/widgets/number-elements/NumberElements'
import {InstrumentReturn} from '~/store/portfolio/types'

const SimpleReturnDetail = ({
    currentReturn,
    instrumentName,
    instrumentTypeLabel,
}: {
    currentReturn: InstrumentReturn
    instrumentName: string
    instrumentTypeLabel: string
}) => {
    return (
        <div>
            <h1>Simple return</h1>
            <p>
                Your simple return is a measure of the performance of your investment in {instrumentName} as a
                percentage. Here it’s calculated on {instrumentTypeLabel} you currently own.
            </p>
            <p>To calculate your simple return, we divide your investment’s return by the amount put in.</p>
            <p>The result is multiplied by 100 to give your investment’s simple return as a percentage.</p>
            <div className={cn(detailModalStyles.calcBox, detailModalStyles.boldTextBox)}>
                (Return ÷ amount you've put in) x 100 = Simple return %
            </div>
            <p>The simple return calculation for your {instrumentName} investment is:</p>
            <div className={cn(detailModalStyles.calcBox, detailModalStyles.boldTextBox)}>
                (
                <DollarValue value={currentReturn.unrealised_total_return} /> &divide;{' '}
                <DollarValue value={currentReturn.cost_basis} />) x 100 ={' '}
                <PercentValue value={currentReturn.unrealised_simple_return} />
            </div>
        </div>
    )
}

export default SimpleReturnDetail
