import React from 'react'
import Search from './Search'
import Filter from './pages/filter/Filter'

export const InvestSearch = () => <Search />

export const InvestFilter = () => <Filter />

export const AutoInvestSearch = () => <Search isAutoInvest={true} />

export const AutoInvestFilter = () => <Filter isAutoInvest={true} />
