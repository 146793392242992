import cn from 'classnames'
import React from 'react'
import {DistillScope} from '~/api/query/distill'
import {spacing} from '~/global/scss/helpers'
import {convertDecimalToPercentage} from '~/global/utils/convert-decimal-to-percentage/convertDecimalToPercentage'
import {PercentValue} from '~/global/widgets/number-elements/NumberElements'
import {generateInitialFilter, useDistillSearchUnderlyingInstruments} from '~/sections/kiwisaver/data/distill'
import {useRakaiaGetLatestInstrumentReturns} from '~/sections/kiwisaver/data/rakaia'
import {useExistingKSCustomer, useInvestmentPlanOrNullForEditing} from '~/sections/kiwisaver/state'

const BaseFundsFeeInfo: React.FunctionComponent = () => {
    const customer = useExistingKSCustomer()
    const investmentPlan = useInvestmentPlanOrNullForEditing()

    if (!investmentPlan) {
        throw Error('Should not be able to reach fees info without an investment plan')
    }
    const baseAllocations = investmentPlan.allocations?.filter(a => a.fund_category === 'BASE').map(x => x.fund_id)

    const {instrument_returns: instrumentReturns} = useRakaiaGetLatestInstrumentReturns(customer.portfolio_id ?? '')
    const rakaiaInstrumentList = Object.values(instrumentReturns).map(x => x.instrument_uuid)
    // Combine the instruments from their plan with the the instruments from their holdings
    const allInstrumentIds = [...new Set(rakaiaInstrumentList.concat(baseAllocations))]

    const initialFilter = generateInitialFilter(DistillScope.KIWISAVER_BASE_FUNDS)
    const distillInstruments = useDistillSearchUnderlyingInstruments({
        ...initialFilter,
        instruments: allInstrumentIds,
    })

    return (
        <>
            {distillInstruments.length > 0 &&
                distillInstruments.map(instrument => {
                    return (
                        <div key={instrument.id} className={cn(spacing.spaceBelow16, spacing.spaceAbove16)}>
                            <h2> {instrument.name}</h2>
                            <PercentValue value={convertDecimalToPercentage(instrument.managementFeePercent!)} /> of
                            your balance in the fund per year
                        </div>
                    )
                })}
        </>
    )
}

export default BaseFundsFeeInfo
