import * as Icons from '@design-system/icon'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {convertToKebabCase} from '~/global/utils/convert-to-kebab-case/convertToKebabCase'
import {dateFormatShortDayFullMonth} from '~/global/utils/format-date/formatDate'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import styles from './ActivityCard.scss'

interface Props {
    icon: Icons.Icons
    title: string
    description?: string
    date: DateTime
    amount?: string | null
    onClick?: () => void
}

export const ActivityCard: React.FunctionComponent<Props> = ({icon, title, description, date, amount, onClick}) => {
    const Icon = Icons[icon]

    return (
        <button
            className={cn(styles.card, spacing.spaceBelow4, {[styles.clickable]: !!onClick})}
            onClick={onClick}
            type="button"
        >
            <Icon />
            <h4 data-testid={`activity-card--${convertToKebabCase(title)}`}>{title}</h4>
            {description && <p>{description}</p>}
            <div className={styles.date}>{date.toFormat(dateFormatShortDayFullMonth)}</div>
            {amount && (
                <div className={styles.amount}>
                    <DollarValue value={amount} currency="nzd" decimalPlaces={2} />
                </div>
            )}
        </button>
    )
}
