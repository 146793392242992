import {ModalLink} from '@design-system/modal'
import React from 'react'

export const StrikePrice = () => {
    return (
        <ModalLink
            dataTestId="modal-link--strike-price"
            label="Dividend strike price"
            asIcon
            modalTitle="Dividend strike price"
            primaryButton={{label: 'Ok'}}
            bottomBorder
            helpIconSize={16}
        >
            <p>
                When you receive a dividend through a dividend reinvestment plan (DRP), the company uses a strike price
                to determine how many shares you’re allocated.
            </p>
            <p>
                For example, if you’re issued a $10 dividend and the strike price is $5, you’d receive two shares in the
                company.
            </p>
            <p>
                How a strike price is calculated differs between companies. It’s often based on a company’s 5-day
                volume-weighted average price.
            </p>
        </ModalLink>
    )
}
