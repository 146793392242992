import React from 'react'
import {Outlet} from 'react-router-dom'
import {urlFor} from '~/global/routeGenerator'
import LoginRedirecter from '~/global/wrappers/LoginRedirecter'
import {useSearchParams} from '~/migrate-react-router'

const AnonymousWrapper: React.FunctionComponent<{}> = () => {
    const next = useSearchParams()[0].get('next')

    let redirectTo = urlFor('') // default

    // DS-471: Force page reload on logout so make sure there are no bugs from persisted state
    // Not the cleanest way to handle this, but it's the best assurance of a cleared Redux store
    // The item is set when the user logs out (identity actions Logout) and
    // we call it here to make sure the redirection from authed pages is fully clear, otherwise
    // the location.reload() can cause a slowdown
    if (window.localStorage.getItem('forcePageReload')) {
        // remove the forcePageReload item to avoid infinite loop
        window.localStorage.removeItem('forcePageReload')
        location.reload()
    }

    if (next) {
        const nextUrl = new URL(next, location.origin) // default to our location as a base if none was supplied
        // ensure the provided 'next' url has the same origin before acting on it
        if (
            nextUrl.origin === location.origin // origin is all of hostname, port and protocol (http vs https)
        ) {
            redirectTo = next
        }
    }

    return (
        <>
            <LoginRedirecter redirectWhen="authenticated" redirectTo={redirectTo} />
            <Outlet />
        </>
    )
}

export default AnonymousWrapper
