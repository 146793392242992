import {Button} from '@design-system/button'
import {CheckboxInput} from '@design-system/checkbox-input'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/invest/sections/bank-linking/BankLinking.scss'
import {useBankLinkingReferrer} from '~/sections/invest/sections/bank-linking/hooks/useBankLinkingRererrer'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'

const ROUNDUP_NOTIFICATION_CATEGORY = 'roundup_activity'

export interface OnNextProps {
    onBack: () => void
    onNext: () => void
}

const ChooseNotification = ({onBack, onNext}: OnNextProps) => {
    const dispatch = useAppDispatch()
    const notifications = useAppSelector(s => s.identity.notificationPreferences)
    const referrer = useBankLinkingReferrer()
    const roundupNotification = notifications?.find(category => category.id === ROUNDUP_NOTIFICATION_CATEGORY)
    const emailChannel = roundupNotification?.channels.find(x => x.channel === 'EMAIL')
    const pushChannel = roundupNotification?.channels.find(x => x.channel === 'NOTIFICATION')
    const [emailChecked, setEmailChecked] = React.useState(emailChannel?.subscribed)
    const [pushChecked, setPushChecked] = React.useState(pushChannel?.subscribed)
    const [processing, setProcessing] = React.useState(false)

    React.useEffect(() => {
        dispatch(identityActions.GetNotificationPreferences())
    }, [])

    if (emailChecked === undefined && notifications !== undefined) {
        // notification data has now loaded, set local state
        // - if we found the right category info then happy days
        // - if we did not then this category hasn't been configured on BE yet - set a value anyway so that
        //   user can move on with their life
        setEmailChecked(emailChannel ? emailChannel.subscribed : false)
        setPushChecked(pushChannel ? pushChannel.subscribed : false)
    }

    const handleSubmit = async () => {
        setProcessing(true)
        const dispatchActions = [
            dispatch(
                identityActions.UpdateNotificationPreference(
                    ROUNDUP_NOTIFICATION_CATEGORY,
                    'EMAIL',
                    emailChecked ?? false,
                ),
            ),
            dispatch(
                identityActions.UpdateNotificationPreference(
                    ROUNDUP_NOTIFICATION_CATEGORY,
                    'NOTIFICATION',
                    pushChecked ?? false,
                ),
            ),
        ]
        await Promise.all(dispatchActions)
        setProcessing(false)
        onNext()
    }

    const choices = [
        {value: 'push', label: 'Push notification', setChecked: setPushChecked, checked: pushChecked},
        {value: 'email', label: 'Email', setChecked: setEmailChecked, checked: emailChecked},
    ]

    return (
        <>
            <Toolbar dataTestId="toolbar--roundups-notification" leftButton="back" onLeftButtonClick={onBack} />
            {emailChecked === undefined ? (
                <Page>
                    <Loading />
                </Page>
            ) : (
                <Page>
                    <h1 className={cn(styles.h1, spacing.spaceBelow20)}>Get notified</h1>
                    <p className={spacing.spaceBelow16}>
                        If you like, you can get a push notification or email (or both!) as soon as your{' '}
                        {referrer === 'linked-bank-topup' ? 'linked bank top-up' : 'round-ups'} hit your Wallet.
                    </p>
                    {choices.map(choice => (
                        <CheckboxInput
                            additionalClassName={styles.groupCheckbox}
                            key={choice.value}
                            isTouched={true}
                            id={`notification-${choice.value}`}
                            name={`notification-${choice.value}`}
                            dataTestId={`checkbox--notification-${choice.value}`}
                            label={choice.label}
                            onChange={e => choice.setChecked((e as React.ChangeEvent<HTMLInputElement>).target.checked)}
                            value={choice.checked}
                        />
                    ))}
                </Page>
            )}

            <ActionBar>
                <Button dataTestId="button--next" label="Next" onClick={handleSubmit} processing={processing} />
            </ActionBar>
        </>
    )
}

export default ChooseNotification
