import React from 'react'
import {spacing} from '~/global/scss/helpers'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import CommunityCodeOfConduct from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/CommunityCodeOfConduct'

const LegalCommunityCodeOfConduct: React.FunctionComponent<{}> = () => (
    <>
        <Toolbar dataTestId="toolbar--community-code-of-conduct" leftButton="back" title="Legal" />
        <Page overrideDefaultTopPadding="withToolbarTitle">
            <h2 className={spacing.spaceBelow16}>Community Code of Conduct</h2>
            <CommunityCodeOfConduct />
        </Page>
    </>
)

export default LegalCommunityCodeOfConduct
