import {assertNever} from '~/global/utils/assert-never/assertNever'
import {ActionsType} from './actions'
import {EmployeeShareSchemeAllocationsByInstrument, EmployeeShareSchemeParticipation, State} from './types'

const initialState: State = {
    participationLoadingState: 'uninitialised',
    shareSchemeParticipation: {},
    allocationsLoadingState: 'uninitialised',
    shareSchemeAllocationsByInstrument: {},
    detailLoadingState: 'uninitialised',
    shareSchemeDetailById: {},
    investmentDetailsToggleStateByInstrument: {},
    schemesPreventingAccountClosureLoadingState: 'uninitialised',
    schemesPreventingAccountClosure: [],
    blackoutPeriodLoadingState: 'uninitialised',
    blackoutPeriodByInstrument: {},
    upcomingAllocationsPageCurrentTab: 'Share rights',
    showShareAllocationCurrentValue: false,
    showShareRightAllocationCurrentValue: false,
    showAlignmentRightAllocationCurrentValue: false,
}

function reducer(state: State = initialState, action: ActionsType): State {
    switch (action.type) {
        case 'employeeShareScheme.SetParticipationLoadingState':
            return {
                ...state,
                participationLoadingState: action.payload,
            }
        case 'employeeShareScheme.SetAllocationsLoadingState':
            return {
                ...state,
                allocationsLoadingState: action.payload,
            }
        case 'employeeShareScheme.SetDetailLoadingState':
            return {
                ...state,
                detailLoadingState: action.payload,
            }
        case 'employeeShareScheme.SetBlackoutPeriodLoadingState':
            return {
                ...state,
                blackoutPeriodLoadingState: action.payload,
            }
        case 'employeeShareScheme.SetShareSchemeDetail':
            return {
                ...state,
                shareSchemeDetailById: {
                    ...state.shareSchemeDetailById,
                    [action.payload.id]: action.payload,
                },
            }
        case 'employeeShareScheme.SetInvestmentDetailsToggleStateForInstrument':
            return {
                ...state,
                investmentDetailsToggleStateByInstrument: {
                    ...state.investmentDetailsToggleStateByInstrument,
                    [action.payload.instrumentId]: action.payload.isToggleOpen ? 'open' : 'closed',
                },
            }
        case 'employeeShareScheme.SetAllShareSchemeDetails':
            const participation: EmployeeShareSchemeParticipation = {}
            const allocations: EmployeeShareSchemeAllocationsByInstrument = {}

            Object.entries(action.payload.details.schemes_by_instrument_id!).forEach(([instrumentId, details]) => {
                allocations[instrumentId] = {
                    type: 'ess_share_allocations',
                    total_unvested_contribution: details.total_unvested_contribution,
                    total_unvested_employee_contribution: details.total_unvested_employee_contribution,
                    total_unvested_employer_contribution: details.total_unvested_employer_contribution,
                    total_unvested_allocations: details.total_unvested_allocations,
                    total_unvested_allocation_years: details.total_unvested_allocation_years,
                    total_unvested_share_rights: details.total_unvested_share_rights,
                    total_vested_allocations: details.total_vested_allocations,
                    total_vested_allocation_years: details.total_vested_allocation_years,
                    next_vesting_date: details.next_vesting_date,
                    next_event_date: details.next_event_date,
                    next_event_type: details.next_event_type,
                    share_allocations: details.share_allocations,
                    share_rights_allocations: details.share_rights_allocations,
                    alignment_right_allocations: details.alignment_right_allocations,
                }
                participation[instrumentId] = details.share_schemes
            })

            return {
                ...state,
                shareSchemeParticipation: participation,
                shareSchemeAllocationsByInstrument: allocations,
            }
        case 'employeeShareScheme.ClearEmployeeShareScheme':
            return initialState
        case 'employeeShareScheme.SetSchemesPreventingAccountClosureLoadingState':
            return {
                ...state,
                schemesPreventingAccountClosureLoadingState: action.payload,
            }
        case 'employeeShareScheme.SetSchemesPreventingAccountClosure':
            return {
                ...state,
                schemesPreventingAccountClosure: action.payload,
            }
        case 'employeeShareScheme.SetBlackoutPeriodByInstrument':
            return {
                ...state,
                blackoutPeriodByInstrument: action.payload,
            }
        case 'employeeShareScheme.SetUpcomingAllocationsPageCurrentTab':
            return {
                ...state,
                upcomingAllocationsPageCurrentTab: action.payload,
            }
        case 'employeeShareScheme.ToggleShowShareAllocationCurrentValue':
            return {
                ...state,
                showShareAllocationCurrentValue: !state.showShareAllocationCurrentValue,
            }
        case 'employeeShareScheme.ToggleShowShareRightAllocationCurrentValue':
            return {
                ...state,
                showShareRightAllocationCurrentValue: !state.showShareRightAllocationCurrentValue,
            }
        case 'employeeShareScheme.ToggleShowAlignmentRightAllocationCurrentValue':
            return {
                ...state,
                showAlignmentRightAllocationCurrentValue: !state.showAlignmentRightAllocationCurrentValue,
            }
        default:
            assertNever(action)
    }
    return state
}

export default reducer
