import {Button} from '@design-system/button'
import React from 'react'
import {useNavigate} from 'react-router'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Image from '~/global/widgets/image/Image'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import houseDark from '~/sections/user/assets/images/address-dark.svg'
import houseLight from '~/sections/user/assets/images/address-light.svg'
import styles from '~/sections/user/sections/sign-up/SignUp.scss'

export const VerifyingAccount: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const handleNext = () => navigate(profileUrl('settings'))

    return (
        <>
            <Toolbar dataTestId="toolbar--transfer-direction" leftButton="back" />
            <Page className={styles.splashContainer}>
                <div className={styles.splashCenterVertically}>
                    <Image className={styles.centered} src={houseLight} darkSrc={houseDark} />
                    <h2>Your account is being verified</h2>
                    <p>We’ll verify your account within 2 working days.</p>
                </div>
                <Button dataTestId="button--address-success" pageButton label="Onwards!" onClick={handleNext} />
            </Page>
        </>
    )
}

export default VerifyingAccount
