import {useAppSelector} from '~/store/hooks'
import {useLatestInstrumentReturns} from '../rakaia/useLatestInstrumentReturns'
import {useEmployeeShareSchemeDetails} from '../retail/useEmployeeShareSchemeDetails'
import {useProfile} from '../retail/useProfile'

export const useEssReturnPercentage = (instrumentId: string) => {
    const profile = useProfile()

    const investPortfolio = profile.portfolios.find(p => p.product === 'INVEST')!
    const instrumentReturns = useLatestInstrumentReturns(investPortfolio.id).instrument_returns[instrumentId]

    const shareSchemeAllocationsForInstrument =
        useEmployeeShareSchemeDetails().shareSchemeAllocationsByInstrument[instrumentId]
    const includeSoldInvestments = useAppSelector(s => s.identity.includeSoldInvestments)

    if (!shareSchemeAllocationsForInstrument || !instrumentReturns) {
        return undefined
    }

    const hasHolding = !!instrumentReturns.investment_value

    if (hasHolding) {
        return includeSoldInvestments ? instrumentReturns.simple_return : instrumentReturns.unrealised_simple_return
    } else {
        return instrumentReturns.unvested_detail.unvested_simple_return
    }
}
