import {Button} from '@design-system/button'
import cn from 'classnames'
import {Formik} from 'formik'
import React from 'react'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {validate} from '~/global/widgets/form-controls'
import {ButtonGroup, StrongCurrency} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import styles from '~/sections/user/sections/share-the-love/sections/gifts/Gifts.scss'
import actions from '~/store/gift/actions'
import {useAppSelector, useAppDispatch} from '~/store/hooks'

interface FormValues {
    amount: string
}

interface FormProps {
    currency: Model.User['home_currency']
    setCreateGiftAmount: (amount: string) => void
}

const maximumGiftValue = 2000

const GiftForm = ({setCreateGiftAmount, currency}: FormProps) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const [showCustom, setShowCustom] = React.useState(false)

    const VALUES = [
        {label: '$20', value: '20', callback: () => setShowCustom(false)},
        {label: '$50', value: '50', callback: () => setShowCustom(false)},
        {label: '$100', value: '100', callback: () => setShowCustom(false)},
        {label: 'Custom', value: '', callback: () => setShowCustom(true)},
    ]

    const preventSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault()
        }
    }
    return (
        <Formik
            validate={validate.generate<FormValues>({
                amount: [
                    validate.required(),
                    validate.money(),
                    validate.minimum(5, "This is under the minimum Gift amount of $5—you'll need to nudge it up a bit"),
                    validate.maximum(
                        maximumGiftValue,
                        'This is over the maximum Gift amount of $2000—bring it on back',
                    ),
                ],
            })}
            initialValues={{
                amount: '',
            }}
            onSubmit={async ({amount}) => {
                setCreateGiftAmount(amount)
                navigate(profileUrl('gifts/pay'))
            }}
        >
            {({isValid, isSubmitting, handleSubmit, dirty}) => (
                <form onSubmit={handleSubmit}>
                    <div className={spacing.spaceBelow24}>
                        <ButtonGroup name="amount" details={VALUES} />
                    </div>

                    {showCustom && (
                        <>
                            <StrongCurrency
                                dataTestId="strong-currency--amount"
                                name="amount"
                                label=""
                                autoFocus
                                decimalPlaces={2}
                                currency={currency}
                                optionalAttributes={{
                                    onKeyPress: preventSubmit,
                                }}
                            />
                        </>
                    )}
                    <Button
                        isSubmit
                        pageButton
                        label="Next"
                        disabled={!isValid || !dirty}
                        processing={isSubmitting}
                        dataTestId="button--gift-next"
                    />
                </form>
            )}
        </Formik>
    )
}

export const GiftPurchase: React.FunctionComponent = () => {
    const currency = useAppSelector(s => s.identity.user!.home_currency)
    const dispatch = useAppDispatch()
    const setCreateGiftAmount = (amount: string) => dispatch(actions.SetCreateGiftAmount(amount))

    return (
        <>
            <Toolbar dataTestId="toolbar--gift-purchase" leftButton="back" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <h1 className={cn(spacing.spaceBelow16, styles.title)}>How much are you gifting?</h1>
                <p className={spacing.spaceBelow24}>Gift any amount from $5 to $2000.</p>
                <GiftForm currency={currency} setCreateGiftAmount={setCreateGiftAmount} />
            </Page>
        </>
    )
}

export default GiftPurchase
