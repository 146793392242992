import {ListingResponseDto} from '~/api/distill'
import {Instrument} from '~/store/instrument/types'

export const isOnNZX: (instrument: Instrument | ListingResponseDto) => boolean = instrument =>
    instrument.exchange === 'NZX'

export const isOnASX: (instrument: Instrument) => boolean = instrument => instrument.exchange === 'ASX'

/** This check excludes US OTC */
export const isOnMainUsExchange: (instrument: Instrument) => boolean = instrument =>
    instrument.exchange === 'NYSE' || instrument.exchange === 'CBOE' || instrument.exchange === 'NASDAQ'

export const isUsOtc: (instrument: Instrument) => boolean = instrument => instrument.exchange === 'US OTC'
