import {Card, ClassicCard} from '@braze/web-sdk'
import {DockableModal} from '@design-system/dockable-modal'
import cn from 'classnames'
import React from 'react'
import {requestContentCardsRefresh, getCachedContentCards, logCardDismissal} from '~/api/braze/braze'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {accessibility} from '~/global/scss/helpers'
import {useInstruments} from '~/global/utils/use-instruments/useInstruments'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useShowIntercom} from '~/global/utils/use-show-intercom/useShowIntercom'
import {Image} from '~/global/widgets/image/Image'
import {Loading} from '~/global/widgets/loading/Loading'
import {NotificationSettingsButton} from '~/global/widgets/notification-settings-button/NotificationSettingsButton'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar, ToolbarButton} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {PriceNotificationModal} from '~/sections/invest/widgets/price-notification-modal/PriceNotificationModal'
import noNotificationsDark from '~/sections/user/sections/notifications/assets/images/no-notifications-dark.svg'
import noNotificationsLight from '~/sections/user/sections/notifications/assets/images/no-notifications-light.svg'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import NotificationsList from './widgets/notification-list/NotificationsList'
import styles from './Notifications.scss'

const NoNotifications: React.FunctionComponent = () => (
    <div className={styles.noNotifications}>
        <Image src={noNotificationsLight} darkSrc={noNotificationsDark} alt="No notifications to see here" />
        <p>You’re all caught up</p>
    </div>
)

const LoadFail = () => (
    <div className={styles.notificationsLoadFail}>
        <p>Notifications could not be loaded at this time.</p>
    </div>
)

const Notifications: React.FunctionComponent = () => {
    const showIntercom = useShowIntercom()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const notifications = useAppSelector(s => s.identity.notifications)

    // Loading instruments for price notifications as these may not be included in portfolio or watchlist
    const instrumentsForPriceNotifications: string[] = notifications.notificationsPageCards
        .filter(card => !!card.extras?.instrument_id)
        .map(card => card.extras?.instrument_id)
    const [instruments] = useInstruments(instrumentsForPriceNotifications)

    const notificationPreferences = useAppSelector(s => s.identity.notificationPreferences)

    const [activeNotification, setActiveNotification] = React.useState<{
        modalShown: 'notificationMenu' | 'priceNotification' | null
        card?: Card | null
    }>({
        modalShown: null,
        card: null,
    })

    const {notificationsLoaded, loadFail} = notifications

    // Price notification related functionality
    const instrumentId: string = activeNotification.card?.extras?.instrument_id
    const instrument = instrumentId ? instruments[instrumentId] : undefined

    const hasPriceNotification =
        activeNotification.card?.extras?.category === 'price_notification' &&
        // TODO: remove this once we're confident all price notification cards have a portfolio_id set
        activeNotification.card?.extras?.portfolio_id

    const refreshContentCards = () => {
        requestContentCardsRefresh(
            () => {
                const cards = getCachedContentCards()
                if (cards) {
                    dispatch(identityActions.SetNotifications(cards))
                }
            },
            () => {
                dispatch(identityActions.SetNotificationsLoadFail())
            },
        )
    }

    React.useEffect(() => {
        // refresh on first page load to ensure cards are most up to date
        refreshContentCards()
        dispatch(identityActions.GetNotificationPreferences())
    }, [])

    const removeCard = () => {
        if (activeNotification.card) {
            dispatch(identityActions.RemoveNotification(activeNotification.card))
            rudderTrack('notifications', 'notification_removed', {
                notification_id: activeNotification.card.id || '',
                instrument_id: activeNotification.card.extras?.instrument_id || '',
            })
            refreshContentCards()
        }

        setActiveNotification({modalShown: null, card: null})
    }

    const currentCardUnsubscribeCategory =
        activeNotification.card &&
        activeNotification.card.extras &&
        'unsubscribe_category' in activeNotification.card.extras &&
        activeNotification.card.extras.unsubscribe_category

    let canUnsubscribe = false

    notificationPreferences.forEach(np => {
        if (np.id === currentCardUnsubscribeCategory) {
            np.channels.forEach(c => {
                if (c.channel === 'NOTIFICATION' && c.can_unsubscribe) {
                    canUnsubscribe = true
                }
            })
        }
    })

    const unsubscribeFromCategory = async () => {
        if (activeNotification.card && currentCardUnsubscribeCategory) {
            // Register unsubscription with Retail, await in case an error is returned and thrown
            await dispatch(identityActions.UnsubscribeFromNotificationCategory(currentCardUnsubscribeCategory))

            // Remove notification from feed (and provide immediate feedback to user). Also runs logCardDismissal()
            dispatch(identityActions.RemoveNotification(activeNotification.card))

            // Dismiss any other cards in the same category. Rather than call RemoveNotification(), and thereby call getCachedContentCards()
            // multiple times, log dismissal here, and call refreshContentCards() once at the end of this function.
            notifications.notificationsPageCards.forEach(card => {
                if (
                    card.extras &&
                    'unsubscribe_category' in card.extras &&
                    card.extras.unsubscribe_category === currentCardUnsubscribeCategory
                ) {
                    logCardDismissal(card)
                }
            })

            // Make a rudderstack call
            rudderTrack('notification_preferences', 'change_preference', {
                notification_preference: currentCardUnsubscribeCategory,
                source: 'notifications_centre',
                channel: 'notification',
                updated_subscription_state: 'unsubscribed',
            })

            // Remove any other dismissed cards from display and also get everything up to date
            refreshContentCards()
        }

        setActiveNotification({modalShown: null, card: null})
    }

    const dataTestId = 'toolbar--notifications'
    const cards = notifications.notificationsPageCards.filter(card => card instanceof ClassicCard) as ClassicCard[]

    return (
        <>
            <Toolbar
                dataTestId={dataTestId}
                leftButton="back"
                onLeftButtonClick={() => navigate(profileUrl(''))}
                rightSlot={
                    <>
                        <NotificationSettingsButton />
                        <ToolbarButton dataTestId={dataTestId} icon="help" onClick={showIntercom} />
                    </>
                }
                title="Notifications"
            />
            <Page className={styles.notificationsPage} overrideDefaultTopPadding="withToolbarTitle">
                {!notificationsLoaded && !loadFail && (
                    <div data-testid="notifications--loading">
                        <Loading data-testid="notifications--loading" />
                    </div>
                )}

                {loadFail && <LoadFail />}

                {notificationsLoaded && notifications.notificationsPageCards.length === 0 && <NoNotifications />}

                {cards.length > 0 && (
                    <div className={styles.notificationsWrapper}>
                        <NotificationsList
                            cards={cards}
                            setCardAndShowModal={card => setActiveNotification({card, modalShown: 'notificationMenu'})}
                        />
                    </div>
                )}

                <DockableModal
                    dataTestId="modal--notification-menu"
                    isOpen={activeNotification.modalShown === 'notificationMenu'}
                    setIsOpen={isOpen => setActiveNotification({modalShown: isOpen ? 'priceNotification' : null})}
                    title="Options"
                    content={
                        <>
                            {activeNotification.card && (
                                <button
                                    type="button"
                                    className={cn(accessibility.button, styles.fullWidthButton)}
                                    onClick={() => removeCard()}
                                    aria-label="Remove notification"
                                >
                                    Remove
                                </button>
                            )}

                            {currentCardUnsubscribeCategory && canUnsubscribe && activeNotification.card && (
                                <button
                                    type="button"
                                    className={cn(accessibility.button, styles.fullWidthButton)}
                                    onClick={() => unsubscribeFromCategory()}
                                    aria-label={`Don't notify me about ${activeNotification.card.extras?.category_label}`}
                                >
                                    Don't notify me about {activeNotification.card.extras?.category_label}
                                </button>
                            )}

                            {hasPriceNotification && (
                                <button
                                    type="button"
                                    className={cn(accessibility.button, styles.fullWidthButton)}
                                    onClick={() => {
                                        setActiveNotification({
                                            modalShown: 'priceNotification',
                                            card: activeNotification.card,
                                        })

                                        // Edge case where the instruments haven't loaded
                                        if (!instrument) {
                                            Toast('Something went wrong, please try again.')
                                        }
                                    }}
                                >
                                    {hasPriceNotification ? 'Edit' : 'Set'} price notification
                                </button>
                            )}
                        </>
                    }
                    customZIndex={1051} // same as zindex(detail-modal)
                />

                {hasPriceNotification && instrument && (
                    <PriceNotificationModal
                        isOpen={activeNotification.modalShown === 'priceNotification'}
                        onClose={() => setActiveNotification({modalShown: null, card: null})}
                        instrumentId={instrument.id}
                        instrumentName={instrument.name}
                        instrumentValue={instrument.marketPrice}
                        instrumentCurrency={instrument.currency}
                        instrumentSymbol={instrument.symbol}
                        hasPriceNotification={hasPriceNotification}
                        initialPriceNotificationAmount={activeNotification.card?.extras?.price_threshold}
                        pageSource="notification_page"
                        extendedHoursInEffect={false}
                        hideSeeAllLink
                        portfolioId={activeNotification.card?.extras?.portfolio_id}
                    />
                )}
            </Page>
        </>
    )
}

export default Notifications
