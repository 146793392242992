import React from 'react'

/*
This minor widget ensures that things like stock ticker codes and exchange names are pronounced
in a screenreader using each individual letter, by splitting the string up and joining it
back together with zero width spaces.
Per https://www.ashleysheridan.co.uk/blog/Screen+Readers+and+Pronunciation

Actual acronyms should probably use an <abbr> tag instead.
*/

// list of words we don't actually want to split up - we want these to be pronounced 'naturally'
const exceptionList = ['NASDAQ', 'CBOE']

export default ({text}: {text?: string | null}) => {
    if (!text) {
        return null
    }
    if (exceptionList.includes(text)) {
        return <>{text}</>
    }
    return <span aria-label={text.split('').join('\u200B')}>{text}</span>
}
