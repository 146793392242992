import {Button} from '@design-system/button'
import {Modal} from '@design-system/modal'
import cn from 'classnames'
import React from 'react'
import config from '~/configForEnv'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Checkbox} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import SharesightActiveIntegrations from './SharesightActiveIntegrations'
import SharesightPortfolioForm from './SharesightPortfolioForm'

export const AccountSharesightContent: React.FunctionComponent<{withHeading: boolean}> = ({withHeading = true}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const searchParams = new URLSearchParams(window.location.search)
    const token = searchParams.has('code') ? searchParams.get('code')!.trim() : undefined
    const [authoriseModal, showAuthoriseModal] = React.useState(false)
    const [authorised, setAuthorised] = React.useState(false)
    const [errorModal, showErrorModal] = React.useState(false)

    const portfolioId = useAppSelector(s => s.identity.user!.portfolio_id)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const sharesightIntegrations = useAppSelector(s => s.identity.sharesightIntegrations)
    const sharesightPortfolios = useAppSelector(s => s.identity.sharesightPortfolios)
    const sharesightIntegrationLoadingState = useAppSelector(s => s.identity.sharesightGetIntegrationLoadingState)
    const sharesightPortfolioLoadingState = useAppSelector(s => s.identity.sharesightGetPortfoliosLoadingState)
    const sharesightClientCredentials = useAppSelector(s => s.identity.sharesightClientCredentials)

    const dispatch = useAppDispatch()

    React.useEffect(() => {
        // Show portfolio error modal only if there isn't any existing connections
        if (
            (!sharesightIntegrationsForPortfolio ||
                sharesightIntegrationsForPortfolio.every(portfolio => !portfolio.sharesight_portfolio)) &&
            sharesightPortfolioLoadingState === 'error'
        ) {
            showErrorModal(true)
        }
    }, [sharesightPortfolioLoadingState])

    React.useEffect(() => {
        dispatch(identityActions.GetSharesightIntegrations())
        dispatch(identityActions.GetSharesightClientCredentials())
    }, [])

    React.useEffect(() => {
        if (token) {
            dispatch(identityActions.AddSharesightIntegration(token, portfolioId))
            // Replace browser history to remove the auth_code; prevents investor from doing a refresh which will result in the backend receiving the same auth code again.
            navigate(profileUrl('settings/portfolio'), {replace: true})
        }
    }, [token])

    React.useEffect(() => {
        // if there exists a sharesight integration for this portfolio with no sharesight portfolio association then the investor has not
        // yet selected a sharesight integration for this portfolio -> fetch the list of portfolios
        if (
            sharesightIntegrations[portfolioId] &&
            sharesightIntegrations[portfolioId].find(
                integration => !integration.sharesight_portfolio && !integration.is_invalid,
            )
        ) {
            // get list of portfolios
            dispatch(identityActions.GetSharesightPortfolios(portfolioId))
        }
    }, [sharesightIntegrations])

    const sharesightIntegrationsForPortfolio = sharesightIntegrations[portfolioId]

    // logic for showing the portfolio form -> portfolios need to be loaded AND the investor needs to have an integration which is not yet complete (i.e. they haven't selected a Sharesight portfolio)
    // and this integration SHOULD NOT be acting as deleted (i.e. something went horribly wrong with the oauth tokens)
    const showPortfolioList = !!(
        sharesightPortfolioLoadingState === 'ready' &&
        sharesightIntegrationsForPortfolio &&
        sharesightIntegrationsForPortfolio.find(
            integration => !integration.sharesight_portfolio && !integration.is_invalid,
        ) &&
        sharesightPortfolios
    )

    // URL we want to redirect an investor to if they wish to integrate their Sharesight account.
    const sharesightRedirectUrl =
        sharesightClientCredentials &&
        `${config.sharesightBaseUrl}?response_type=code&client_id=${sharesightClientCredentials.clientId}&redirect_uri=${sharesightClientCredentials.redirectUri}`

    const handleCloseModal = async () => {
        await dispatch(identityActions.DeleteSharesightIntegration(portfolioId))
        dispatch(identityActions.GetSharesightIntegrations())
        showErrorModal(false)
    }

    return (
        <div>
            {withHeading && (
                <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow12)}>{`Connect ${
                    isDependent ? preferredName + '’s' : 'your'
                } Portfolio to Sharesight`}</h2>
            )}
            {!sharesightIntegrationsForPortfolio && (
                <>
                    <p className={spacing.spaceBelow24}>
                        {`Sharesight provides additional tax reporting and insight into how ${
                            isDependent ? preferredName + '’s' : 'your'
                        } investments are
                        performing.`}
                    </p>
                    <p className={spacing.spaceBelow32}>
                        {`When you connect ${
                            isDependent ? preferredName + '’s' : 'your'
                        } Sharesies Portfolio to Sharesight, we’ll send details of ${
                            isDependent ? 'their' : 'your'
                        } buy and sell
                        transactions. `}
                        <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href={
                                jurisdiction === 'au'
                                    ? 'https://www.sharesight.com/au/'
                                    : 'https://www.sharesight.com/nz/'
                            }
                        >
                            Learn more about Sharesight
                        </a>{' '}
                        and its{' '}
                        <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href={
                                jurisdiction === 'au'
                                    ? 'https://www.sharesight.com/au/pricing'
                                    : 'https://www.sharesight.com/nz/pricing/'
                            }
                        >
                            pricing options
                        </a>
                        .
                    </p>
                </>
            )}
            {/*Where an investor has NO Sharesight integrations for this portfolio, provide them with the ability to integrate with a Sharesight account.*/}
            {!sharesightIntegrationsForPortfolio && sharesightRedirectUrl && (
                <>
                    <Button
                        label="Connect to Sharesight"
                        dataTestId="button--sharesight-connect"
                        additionalClassName={spacing.spaceBelow32}
                        width="auto"
                        onClick={() => {
                            if (
                                sharesightIntegrationLoadingState === 'ready' &&
                                sharesightPortfolioLoadingState === 'ready'
                            ) {
                                showAuthoriseModal(true)
                            } else {
                                showErrorModal(true)
                            }
                        }}
                    />

                    <p>Heads up, we may receive a fee for referring customers to Sharesight.</p>
                </>
            )}
            {/*Where the investor has an 'ongoing' Sharesight integration for their Sharesies portfolio, provide them with a form to select the Sharesight portfolio they wish to integrate with */}
            {showPortfolioList && sharesightPortfolios && (
                <SharesightPortfolioForm
                    sharesightPortfolioChoices={sharesightPortfolios.map(p => {
                        return {value: String(p.id), label: p.name}
                    })}
                    portfolioId={portfolioId}
                    isDependent={isDependent}
                    preferredName={preferredName}
                    dispatch={dispatch}
                />
            )}
            {/*Where an investor does NOT have an ongoing Sharesight integration for this portfolio, and they have at least ONE completed integration, display their integrations.*/}
            {!showPortfolioList &&
                sharesightIntegrationsForPortfolio &&
                sharesightIntegrationsForPortfolio.length > 0 &&
                sharesightPortfolioLoadingState !== 'loading' && (
                    <SharesightActiveIntegrations
                        sharesightIntegrationsForPortfolio={sharesightIntegrationsForPortfolio}
                        portfolioId={portfolioId}
                        sharesightIntegrationLoadingState={sharesightIntegrationLoadingState}
                        sharesightPortfolioLoadingState={sharesightPortfolioLoadingState}
                        isDependent={isDependent}
                        preferredName={preferredName}
                    />
                )}

            {(sharesightIntegrationLoadingState === 'loading' || sharesightPortfolioLoadingState === 'loading') &&
                !showPortfolioList && <Loading />}

            <Modal
                isOpen={authoriseModal}
                setIsOpen={showAuthoriseModal}
                title="Connect to Sharesight"
                dataTestId="modal--connect-to-sharesight"
                primaryButton={{
                    label: 'Continue',
                    disabled: !authorised,
                    onClick: () => {
                        if (sharesightRedirectUrl) {
                            window.location.href = sharesightRedirectUrl
                        }
                    },
                }}
            >
                <p>
                    This service is provided by Sharesight subject to their{' '}
                    <a
                        href={
                            jurisdiction === 'au'
                                ? 'https://www.sharesight.com/au/sharesight-terms-of-use/'
                                : 'https://www.sharesight.com/nz/sharesight-terms-of-use/'
                        }
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Terms of Use
                    </a>{' '}
                    and{' '}
                    <a
                        href={
                            jurisdiction === 'au'
                                ? 'https://www.sharesight.com/au/privacy-policy/'
                                : 'https://www.sharesight.com/nz/privacy-policy/'
                        }
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Privacy Policy
                    </a>
                    . We do our best to make sure this service runs smoothly, but disruptions may occasionally occur.
                </p>
                <Checkbox
                    dataTestId="checkbox--sharesight-connect"
                    name="authorise"
                    label="I authorise Sharesies to send my transaction details to Sharesight."
                    isTouched
                    value={authorised}
                    onChange={e => setAuthorised((e.target as any).checked)}
                />
            </Modal>

            <Modal
                isOpen={errorModal}
                setIsOpen={handleCloseModal}
                title={`We couldn’t connect to ${
                    sharesightIntegrationLoadingState === 'error' ? 'Sharesight' : 'your Sharesight Portfolio'
                }`}
                dataTestId="modal--sharesight-connection-failure"
                primaryButton={{label: 'Okay', onClick: handleCloseModal}}
            >
                <p>
                    Give it another go. If you’re still having trouble,{' '}
                    <a href="mailto:help@sharesies.co.nz" target="_blank" rel="noopener">
                        get in touch with us.
                    </a>
                </p>
            </Modal>
        </div>
    )
}

export const Sharesight: React.FunctionComponent = () => {
    return (
        <>
            <Toolbar
                leftButton="back"
                dataTestId="toolbar--account-sharesight"
                title="Connect your Portfolio to Sharesight"
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <AccountSharesightContent withHeading={false} />
            </Page>
        </>
    )
}
