import {DateTime} from 'luxon'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {Select} from '~/global/widgets/form-controls'
import formStyles from '~/global/widgets/form-controls/form.scss'

interface DateRangeProps {
    from: DateTime
    to: DateTime
}

interface Props {
    includeMonths?: boolean
    dateRange: DateRangeProps
    handleChange: (newRange: DateRangeProps) => void
}

const ReportDateRange: React.FunctionComponent<Props> = ({includeMonths, dateRange, handleChange}) => {
    const {from, to} = dateRange

    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    const monthNames = [
        '',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]
    const years = []

    for (let i = 2017; i <= DateTime.local().year; i++) {
        years.push(i)
    }

    return (
        <div>
            <p className={spacing.spaceBelow24}>
                <strong>From</strong>
            </p>
            <div className={formStyles.row}>
                {includeMonths && (
                    <Select
                        dataTestId="select--from-month"
                        name="from-month"
                        label="Month"
                        isTouched
                        choices={months.map(m => ({value: String(m), label: monthNames[m]}))}
                        value={String(from.month)}
                        onChange={e => {
                            const month = parseInt(e.currentTarget.value, 10)
                            const newRange = {...dateRange, from: from.set({month})}
                            handleChange(newRange)
                        }}
                    />
                )}
                <Select
                    dataTestId="select--from-year"
                    name="from-year"
                    label="Year"
                    isTouched
                    choices={years.map(y => ({value: String(y), label: String(y)}))}
                    value={String(from.year)}
                    onChange={e => {
                        const year = parseInt(e.currentTarget.value, 10)
                        const newRange = {...dateRange, from: from.set({year})}
                        handleChange(newRange)
                    }}
                />
            </div>
            <p className={spacing.spaceBelow24}>
                <strong>To</strong>
            </p>
            <div className={formStyles.row}>
                {includeMonths && (
                    <Select
                        dataTestId="select--to-month"
                        name="to-month"
                        label="Month"
                        isTouched
                        choices={months.map(m => ({value: String(m), label: monthNames[m]}))}
                        value={String(to.month)}
                        onChange={e => {
                            const month = parseInt(e.currentTarget.value, 10)
                            const newRange = {...dateRange, to: to.set({month}).endOf('month')}
                            handleChange(newRange)
                        }}
                    />
                )}
                <Select
                    dataTestId="select--to-year"
                    name="to-year"
                    label="Year"
                    isTouched
                    choices={years.map(y => ({value: String(y), label: String(y)}))}
                    value={String(to.year)}
                    onChange={e => {
                        const year = parseInt(e.currentTarget.value, 10)
                        const newRange = {...dateRange, to: to.set({year})}
                        handleChange(newRange)
                    }}
                />
            </div>
        </div>
    )
}

export default ReportDateRange
