import {DateTime} from 'luxon'
import React from 'react'
import {Request} from '~/api/retail/types'
import tooltipStyles from '~/global/scss/reused-styles/tooltip.scss'
import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {dateFormatWithSecond} from '~/global/utils/format-date/formatDate'
import {ExpandLink} from '~/global/widgets/expand-link/ExpandLink'
import ExchangeMoneyFees from '~/global/widgets/help-modals/ExchangeMoneyFees'
import {WalletValue, FeeValue, ExchangeRateValue} from '~/global/widgets/number-elements/NumberElements'
import {Tooltip} from '~/global/widgets/tooltip/Tooltip'
import {exchangeRateCheckInterval} from '~/sections/OLD_wallet/sections/exchange-money/ExchangeMoney'
import {StagedBuyOrder} from '~/store/order/types'
import styles from './ConfirmExchange.scss'

interface ConfirmExchangeProps {
    displayCurrency: Currency
    paymentBreakdown: Request.OrderCreateBuy['payment_breakdown']
    expectedFee: StagedBuyOrder['expectedFee']
}

const ConfirmExchange: React.FunctionComponent<ConfirmExchangeProps> = ({displayCurrency, paymentBreakdown}) => {
    const [exchangeRateExpiryTime, setExchangeRateExpiryTime] = React.useState<DateTime | null>(null)

    const directPayment = paymentBreakdown.find(payment => payment.type === 'direct')

    const exchangePayments = paymentBreakdown.filter(
        (payment): payment is Extract<(typeof paymentBreakdown)[number], {type: 'exchange'}> =>
            payment.type === 'exchange',
    )

    React.useEffect(() => {
        if (exchangePayments[0] && exchangePayments[0].rate) {
            setExchangeRateExpiryTime(
                DateTime.local().plus({milliseconds: exchangeRateCheckInterval}), // 15 seconds
            )
        }
    }, [exchangePayments[0]])

    if (exchangePayments.length < 1) {
        return <></>
    }

    return (
        <div className={styles.buyConfirmExchange}>
            {directPayment && (
                <p>
                    <WalletValue value={directPayment.target_amount} currency={directPayment.currency} />
                </p>
            )}

            {exchangePayments.map(payment => (
                <p key={payment.currency}>
                    <WalletValue value={payment.source_amount} currency={payment.currency} /> exchanged to{' '}
                    <WalletValue value={payment.target_amount} currency={displayCurrency} />
                </p>
            ))}

            <ExpandLink
                title="Currency exchange details"
                titleClassName={styles.exchangeDetailsExpandLink}
                additionalClassName={styles.exchangeDetailsContainer}
            >
                <div className={styles.exchangeDetailsList}>
                    {exchangePayments.map(payment => (
                        <div key={payment.currency}>
                            <p className={styles.exchangeDetailsTitle}>
                                Exchanged from {payment.currency.toUpperCase()}
                            </p>

                            <div className={styles.exchangeDetails}>
                                {exchangeRateExpiryTime && (
                                    <div>
                                        <p>Exchange rate</p>
                                        <span>
                                            <Tooltip>
                                                <p className={tooltipStyles.label}>
                                                    <ExchangeRateValue
                                                        sourceCurrency={payment.currency}
                                                        targetCurrency={displayCurrency}
                                                        exchangeRate={parseFloat(payment.rate)}
                                                    />
                                                </p>
                                                <div className={tooltipStyles.tooltip}>
                                                    <p>
                                                        This is the exchange rate as of{' '}
                                                        {exchangeRateExpiryTime.toFormat(dateFormatWithSecond)}
                                                    </p>
                                                </div>
                                            </Tooltip>
                                        </span>
                                    </div>
                                )}

                                <div>
                                    <p>
                                        Exchange fee <ExchangeMoneyFees />
                                    </p>
                                    <span>
                                        <FeeValue value={payment.fee} currency={payment.currency} />
                                    </span>
                                </div>
                                <div>
                                    <p>{payment.currency.toLocaleUpperCase()} to exchange</p>
                                    <span>
                                        <WalletValue value={payment.source_amount} currency={payment.currency} />
                                    </span>
                                </div>
                                <div>
                                    <p>{displayCurrency.toLocaleUpperCase()} to receive</p>
                                    <span>
                                        <WalletValue value={payment.target_amount} currency={displayCurrency} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </ExpandLink>
        </div>
    )
}

export default ConfirmExchange
