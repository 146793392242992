import {Modal, ModalProps} from '@design-system/modal'
import React from 'react'
import {Model} from '~/api/retail/types'
import {HelpEmail} from '~/global/widgets/help-email/HelpEmail' // get the unconnected version because we know jurisdiction locally
import {SourceOfInvalidPreferredName} from '~/store/identity/types'

type Props = Omit<ModalProps, 'dataTestId' | 'title'> & {
    jurisdiction: Model.User['jurisdiction']
    source?: SourceOfInvalidPreferredName
}

const ModalCopyCases = {
    [SourceOfInvalidPreferredName.SIGN_UP]: 'sign up',
    [SourceOfInvalidPreferredName.ACTIVE_CUSTOMER]: 'use Sharesies',
}

const InvalidPreferredNameModal = ({isOpen, setIsOpen, jurisdiction, source}: Props) => {
    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title="Non-individual account"
            dataTestId="modal--invalid-preferred-name"
            primaryButton={{label: 'Cool cool, got it', onClick: () => setIsOpen(false)}}
        >
            <p>
                Looks like you are trying to {ModalCopyCases[source ?? SourceOfInvalidPreferredName.SIGN_UP]} on behalf
                of another entity, which we don’t support.
            </p>
            <p>Currently, we only offer the ability for individual investors to sign up for an account.</p>
            <p>
                If you have any other questions please get in contact at <HelpEmail jurisdiction={jurisdiction} />.
            </p>
        </Modal>
    )
}

export default InvalidPreferredNameModal
