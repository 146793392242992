import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import {Form, Formik} from 'formik'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import NotFound from '~/global/pages/not-found/NotFound'
import {spacing, page, typographyOverrides} from '~/global/scss/helpers'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ErrorBox, validate} from '~/global/widgets/form-controls'
import {Select, Email} from '~/global/widgets/form-controls/formik'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/user/sections/sign-up/pages/prescribed-person/PrescribedPerson.scss'

const PrescribedPerson = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const owner = useProfileOwner()
    const actingAsOther = owner.is_kids_account || !owner.is_self
    const participants = useRetailGet({path: 'identity/participants'}).data.participants
    const prescribedStatus = useRetailGet({
        path: 'owner/:owner_id/prescribed-person-status',
        pathParams: {owner_id: owner.id},
    }).data

    const setPrescribedState = useRetailPost({
        path: 'owner/:owner_id/prescribed-person-status',
        pathParams: {owner_id: owner.id},
        queryCacheToUpdate: [`owner/:owner_id/prescribed-person-status`, {owner_id: owner.id}],
    })

    const ownerIsOrganisation = owner.owner_type === 'ORGANISATION'
    const returnUrl = ownerIsOrganisation ? 'settings/business-details' : 'settings/personal-details'

    const NZXParticipantModal = (
        <ModalLink
            dataTestId="modal-link--nzx-participants"
            label="NZX Participant and Advising firms"
            modalTitle="NZX Participant and Advising firms"
            primaryButton={{label: 'Ok'}}
        >
            <p>As at 8 January 2021:</p>
            <ul>
                {participants
                    .filter(p => p !== 'Other')
                    .map(p => (
                        <li key={p}>{p}</li>
                    ))}
            </ul>
        </ModalLink>
    )

    // If the parent is prescribed, we don't need to collect this information, so we shouldn't link to this page at all
    if (prescribedStatus.parent_is_prescribed) {
        return <NotFound />
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--prescribed-person" leftButton="back" title="NZX Participant" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow12)}>Prescribed Person</h2>
                {!owner.is_kids_account ? (
                    <p className={spacing.spaceBelow24}>
                        If you’re an employee of an {NZXParticipantModal} (or an employee’s spouse or dependant kid)
                        you’ll be a{' '}
                        <HelpCentreLink auArticle="4989764-prescribed-person" nzArticle="3111236-prescribed-person">
                            Prescribed Person
                        </HelpCentreLink>
                        . The employer will let you know if you are one.
                    </p>
                ) : (
                    <p className={spacing.spaceBelow24}>
                        A child can be a Prescribed Person if their parent is an employee of an {NZXParticipantModal}.
                    </p>
                )}
                <Formik
                    initialValues={{
                        isPrescribedPerson: prescribedStatus.prescribed_participant != null,
                        participant: prescribedStatus.prescribed_participant ?? '',
                        contractNoteEmailAddress: prescribedStatus.contract_note_email_address ?? '',
                    }}
                    validate={values => {
                        return validate.generate({
                            isPrescribedPerson: [validate.required('You must answer yes or no')],
                            participant: values.isPrescribedPerson ? [validate.required()] : [],
                            contractNoteEmailAddress:
                                values.participant && values.participant !== 'Sharesies Limited'
                                    ? [validate.required(), validate.email()]
                                    : [],
                        })(values)
                    }}
                    onSubmit={async values => {
                        if (values.isPrescribedPerson) {
                            await setPrescribedState.mutateAsync({
                                prescribed_participant: values.participant as any,
                                contract_note_email_address:
                                    values.participant && values.participant !== 'Sharesies Limited'
                                        ? values.contractNoteEmailAddress
                                        : undefined,
                            })
                        } else {
                            await setPrescribedState.mutateAsync({})
                        }
                        Toast(
                            `Choice! ${actingAsOther ? `${owner.display_name}’s` : 'Your'} details have been updated.`,
                        )
                        navigate(profileUrl(returnUrl))
                    }}
                >
                    {({setFieldValue, isSubmitting, isValid, dirty, values, errors, status}) => (
                        <Form>
                            <p className={spacing.spaceBelow16}>
                                <strong className={styles.heading}>
                                    {actingAsOther
                                        ? `Is ${owner.display_name} a Prescribed Person?`
                                        : 'Are you a Prescribed Person?'}
                                </strong>
                            </p>
                            <p className={cn(spacing.spaceBelow24, page.flexRow)}>
                                <span>
                                    <Button
                                        dataTestId="button--yes"
                                        label="Yes"
                                        type={values.isPrescribedPerson === true ? 'primary' : 'secondary'}
                                        onClick={() => {
                                            if (!values.isPrescribedPerson) {
                                                setFieldValue('participant', '')
                                            }
                                            setFieldValue('isPrescribedPerson', true)
                                        }}
                                    />
                                </span>
                                <span>
                                    <Button
                                        dataTestId="button--no"
                                        label="No"
                                        type={values.isPrescribedPerson === false ? 'primary' : 'secondary'}
                                        onClick={() => {
                                            setFieldValue('isPrescribedPerson', false)
                                            setFieldValue('participant', undefined)
                                        }}
                                    />
                                </span>
                            </p>
                            <ErrorBox message={errors.isPrescribedPerson} />
                            {values.isPrescribedPerson && values.participant !== 'Other' && (
                                <Select
                                    dataTestId="select--participant"
                                    name="participant"
                                    label={
                                        'Which NZX Participant or Advising firm ' +
                                        (owner.is_kids_account
                                            ? `does ${owner.display_name}’s parent`
                                            : 'do you (or your partner or parent)') +
                                        ' work at?'
                                    }
                                    placeholder="Select a company"
                                    choices={[{value: '', label: ''}].concat(
                                        participants.map(p => ({value: p, label: p})),
                                    )}
                                />
                            )}
                            {values.isPrescribedPerson && values.participant === 'Other' && (
                                <>
                                    <p>
                                        <strong>
                                            {'Which NZX Participant or Advising firm ' +
                                                (owner.is_kids_account
                                                    ? `does ${owner.display_name}’s parent`
                                                    : 'do you (or your partner or parent)') +
                                                ' work at?'}
                                        </strong>
                                    </p>
                                    <p className={cn(spacing.spaceBelow24)}>
                                        You’ve been in contact and told us you work at a company that is not on our
                                        standard list. Please get in contact again if you wish to change this.
                                    </p>
                                </>
                            )}
                            {values.isPrescribedPerson &&
                                values.participant &&
                                values.participant !== 'Sharesies Limited' && (
                                    <>
                                        <p>
                                            <strong className={styles.heading}>Your investment activity</strong>
                                        </p>
                                        <p className={cn(spacing.spaceBelow24)}>
                                            If {owner.is_kids_account ? 'they are' : 'you’re'} a Prescribed Person we’ll
                                            need to email {owner.is_kids_account ? 'their' : 'your'} investing activity{' '}
                                            {owner.is_kids_account
                                                ? ''
                                                : '(and the investing activity for any Kids Accounts you manage) '}
                                            to{' '}
                                            {values.participant !== 'Other'
                                                ? values.participant
                                                : 'the company you have indicated'}
                                            .
                                        </p>

                                        <Email
                                            dataTestId="prescribed-person-email-input"
                                            name="contractNoteEmailAddress"
                                            label="Email my investing activity to"
                                            placeholder={`Email address for ${values.participant}`}
                                        />
                                    </>
                                )}
                            <div className={spacing.spaceAbove16}>
                                <ErrorBox message={status} />
                            </div>
                            {values.isPrescribedPerson !== undefined && (
                                <Button
                                    label="Save"
                                    isSubmit
                                    pageButton
                                    disabled={!isValid || !dirty}
                                    processing={isSubmitting}
                                    dataTestId="button--save-prescribed"
                                />
                            )}
                        </Form>
                    )}
                </Formik>
            </Page>
        </>
    )
}

export default PrescribedPerson
