import {SearchParams} from '~/store/instrument/types'

// Ideally there wouldn't be a difference between SearchParams AKA ApiV1InstrumentsGetRequest and instrument/types/state['currentSearchFilters']
// but ApiV1InstrumentsGetRequest can be a partial object
// RiskLevel is readonly from instrument/types/state['currentSearchFilters'] so have to declare it
interface SearchFilterFromQueryParams extends Pick<SearchParams, 'kidsRecommended'> {
    query: string
    instrumentTypes: string[]
    categories: string[]
    exchanges: string[]
    riskLevel: [number, number] | null
}

/**
 * `useSearchFilterFromQueryParams` creates a `SearchParams` object containing the search query extracted from URL query params
 *
 * All search params can be found in `instruments.metadata` in the Redux store.
 *
 * `query` 'ANZ'
 *
 * `types` 'ETFs','Companies','Managed funds' (instrument types)
 *
 * `category` 'Agriculture and fisheries', 'Banking and finance', 'Education' ...
 *
 * `exchange` 'NZX', 'ASX', 'CBOE', 'NASDAQ', 'NYSE'
 *
 * `risk` 1-7 - `minRisk-maxRisk`
 *
 * `kids` true
 *
 * This is fairly similar to the useSearchFilterFromQueryParams function in `src/kiwisaver/data/distill.ts`
 *
 * Any values that can take multiple options are passed in with the same key
 *
 * Examples
 *
 * `http://localhost:9000/invest/search?query=ANZ&exchange=ASX` : `{ query: 'ANZ', exchanges: ['ASX'], ... }`
 * `http://localhost:9000/invest/search?query=ANZ&exchange=ASX&exchange=NZX` : `{ query: 'ANZ', exchanges: ['ASX', 'NZX'], ... }`
 * `https://app.sharesies.com/invest/search?query=ANZ&exchange=ASX` : `{ query: 'ANZ', exchanges: ['ASX'], ... }`
 * `https://app.sharesies.com/invest/search?query=ANZ&exchange=ASX&exchange=NZX` : `{ query: 'ANZ', exchanges: ['ASX', 'NZX'], ... }`
 *
 */
export const useSearchFilterFromQueryParams = (search: string): SearchFilterFromQueryParams | null => {
    if (!search) {
        return null
    }

    const params = new URLSearchParams(search)

    const initialFilter: SearchFilterFromQueryParams = {
        query: '',
        categories: [],
        exchanges: [],
        instrumentTypes: [],
        riskLevel: null,
    }

    for (const [k, v] of params) {
        switch (k) {
            case 'query':
                initialFilter.query = v
                break
            case 'type':
                initialFilter.instrumentTypes.push(v)
                break
            case 'category':
                initialFilter.categories.push(v)
                break
            case 'exchange':
                initialFilter.exchanges.push(v)
                break
            case 'risk': {
                const match = v.match(/^([1-7])-([1-7])$/)
                if (match) {
                    initialFilter.riskLevel = [
                        parseInt(match[1], 10), // minRisk
                        parseInt(match[2], 10), // maxRisk
                    ]
                }
                break
            }
            case 'kids': {
                // If any value is present for "kids", treat it as true
                initialFilter.kidsRecommended = !!v
                break
            }
        }
    }

    return initialFilter
}
