import {Button} from '@design-system/button'
import {Settings} from '@design-system/icon'
import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import React from 'react'
import {Request} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {NatureAndPurposeStep, getNextStep, getPreviousStep} from '~/global/utils/nature-and-purpose/natureAndPurpose'
import {Clock, Person, Account} from '~/global/widgets/OLD_icons'
import HelpEmail from '~/global/widgets/help-email/HelpEmail'
import Image from '~/global/widgets/image/Image'
import NatureAndPurposeForm from '~/global/widgets/nature-and-purpose-form/NatureAndPurpose'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import SignupToolbar from '~/sections/user/sections/sign-up/widgets/signup-toolbar/SignupToolbar'
import {connect} from '~/store/connect'
import {useAppSelector} from '~/store/hooks'
import actions from '~/store/identity/actions'
import reviewTermsDark from './assets/images/review-terms-dark.svg'
import reviewTermsLight from './assets/images/review-terms-light.svg'
import styles from './NatureAndPurpose.scss'

export const NatureAndPurpose: React.FunctionComponent<NatureAndPurposeProps> = ({
    preferredName,
    isExistingUser,
    setNatureAndPurpose,
}) => {
    const [step, setStep] = React.useState<NatureAndPurposeStep>('intro')

    return (
        <>
            {step === 'intro' && (
                <>
                    {!isExistingUser && <SignupToolbar />}
                    <Page>
                        {isExistingUser ? <ExistingUserIntro preferredName={preferredName} /> : <NewUserIntro />}

                        <Button
                            dataTestId="button--next"
                            label="Next"
                            pageButton
                            onClick={() => setStep(getNextStep(step))}
                        />
                    </Page>
                </>
            )}
            {step !== 'intro' && (
                <>
                    <Toolbar
                        dataTestId="toolbar--nature-and-purpose"
                        leftButton="back"
                        onLeftButtonClick={() => {
                            setStep(getPreviousStep(step))
                        }}
                    />
                    <Page>
                        <NatureAndPurposeForm
                            step={step}
                            setStep={setStep}
                            getNextStep={getNextStep}
                            setNatureAndPurpose={setNatureAndPurpose}
                            isExistingUser={isExistingUser}
                            forOrganisation={false}
                        />
                    </Page>
                </>
            )}
        </>
    )
}

const NewUserIntro = () => {
    const preferredProduct = useAppSelector(s => s.identity.user?.preferred_product)

    if (!preferredProduct || preferredProduct === 'invest') {
        return (
            <>
                <Image className={styles.naturePurposeImage} src={reviewTermsLight} darkSrc={reviewTermsDark} />
                <h1 className={spacing.spaceAbove32}>
                    We just need to ask you a few questions on how you plan to use Sharesies.
                </h1>
                <p className={spacing.spaceAbove16}>
                    At this point a best guess is fine. Your responses won’t affect what you can access on
                    Sharesies.&nbsp;
                    <span className={styles.wrapTogether}>
                        {/* The default width on desktop is just a couple of pixels too small to fit the whole thing on one line. Ensure the `.` doesn't get left alone on the second line*/}
                        <NatureAndPurposeModal label="Find out more" />.
                    </span>
                </p>
                <div className={cn(styles.iconListItem, spacing.spaceAbove16)}>
                    <div className={styles.iconListIcon}>
                        <Settings className={styles.accountIcon} />
                    </div>
                    <div>You can update your answers in Settings at any time.</div>
                </div>
            </>
        )
    }
    return (
        <>
            <Image className={styles.naturePurposeImage} src={reviewTermsLight} darkSrc={reviewTermsDark} />
            <h1 className={spacing.spaceAbove32}>We just need to ask you a few questions on how you plan to invest.</h1>
            <p className={spacing.spaceAbove16}>
                At this point, a best guess is fine. Your responses won’t affect what you can access on Sharesies.{' '}
                <NatureAndPurposeModal label="Find out more" />.
            </p>
            <div className={cn(styles.iconListItem, spacing.spaceAbove32)}>
                <div className={styles.iconListIcon}>
                    <Account className={styles.accountIcon} />
                </div>
                <div>You can update your answers in Settings at any time.</div>
            </div>
        </>
    )
}
interface ExistingUserIntroProps {
    preferredName: string
}
const ExistingUserIntro: React.FunctionComponent<ExistingUserIntroProps> = ({preferredName}) => {
    return (
        <>
            <Image className={styles.naturePurposeImage} src={reviewTermsLight} darkSrc={reviewTermsDark} />
            <div className={styles.introContent}>
                <h2 className={spacing.spaceAbove32}>Hey {preferredName}, we need to ask about how you invest</h2>
                <p className={spacing.spaceAbove16}>
                    We’re required by law to collect info from all of our investors about how they plan to invest.&nbsp;
                    <NatureAndPurposeModal label="Find out more" />
                </p>
                <div className={cn(styles.iconListItem, spacing.spaceAbove32)}>
                    <div className={styles.iconListIcon}>
                        <Clock className={styles.clockIcon} />
                    </div>
                    <div>Answer three quick questions—it should only take a minute.</div>
                </div>
                <div className={cn(styles.iconListItem, spacing.spaceAbove16)}>
                    <div className={styles.iconListIcon}>
                        <Person className={styles.personIcon} />
                    </div>
                    <div>Your answers won’t affect what you can access on Sharesies.</div>
                </div>
                <div className={cn(styles.iconListItem, spacing.spaceAbove16)}>
                    <div className={styles.iconListIcon}>
                        <Account className={styles.accountIcon} />
                    </div>
                    <div>You can update your answers in Settings at any time.</div>
                </div>
            </div>
        </>
    )
}
interface NatureAndPurposeModalProps {
    label: string
}
const NatureAndPurposeModal: React.FunctionComponent<NatureAndPurposeModalProps> = ({label}) => (
    <ModalLink
        dataTestId="modal-link--nature-and-purpose"
        label={label}
        asIcon={!label}
        modalTitle="Why we’re asking"
        primaryButton={{label: 'Ok'}}
    >
        <>
            <p>
                This info helps us to fulfil our anti-money laundering and countering financing of terrorism (AML/CFT)
                obligations.
            </p>
            <p>
                Any questions, let us know at <HelpEmail />
            </p>
        </>
    </ModalLink>
)

interface StoreProps {
    preferredName: string
}

interface DispatchProps {
    setNatureAndPurpose(
        frequency: Request.NatureAndPurposeUpdate['frequency'],
        annual_amount: Request.NatureAndPurposeUpdate['annual_amount'],
        purpose: Request.NatureAndPurposeUpdate['purposes'],
        other_purpose: string,
    ): Promise<string | undefined>
}

interface OwnProps {
    isExistingUser: boolean
}

export type NatureAndPurposeProps = StoreProps & DispatchProps & OwnProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    ({identity}) => ({
        preferredName: identity.user!.preferred_name,
    }),
    dispatch => ({
        setNatureAndPurpose: (frequency, annual_amount, purposes, other_purpose) =>
            dispatch(actions.SignUpNatureAndPurpose(frequency, annual_amount, purposes, other_purpose)),
    }),
)(NatureAndPurpose)
