/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface PortfolioHistory
 */
export interface PortfolioHistory {
    /**
     *
     * @type {string}
     * @memberof PortfolioHistory
     */
    date: string
    /**
     *
     * @type {number}
     * @memberof PortfolioHistory
     */
    portfolio_value: number
    /**
     *
     * @type {number}
     * @memberof PortfolioHistory
     */
    total_return: number
    /**
     *
     * @type {number}
     * @memberof PortfolioHistory
     */
    unrealised_total_return: number
    /**
     *
     * @type {number}
     * @memberof PortfolioHistory
     */
    cost_basis: number
}

export function PortfolioHistoryFromJSON(json: any): PortfolioHistory {
    return PortfolioHistoryFromJSONTyped(json, false)
}

export function PortfolioHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioHistory {
    if (json === undefined || json === null) {
        return json
    }
    return {
        date: json['date'],
        portfolio_value: json['portfolio_value'],
        total_return: json['total_return'],
        unrealised_total_return: json['unrealised_total_return'],
        cost_basis: json['cost_basis'],
    }
}

export function PortfolioHistoryToJSON(value?: PortfolioHistory | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        date: value.date,
        portfolio_value: value.portfolio_value,
        total_return: value.total_return,
        unrealised_total_return: value.unrealised_total_return,
        cost_basis: value.cost_basis,
    }
}
