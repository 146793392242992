import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {Model} from '~/api/retail/types'
import {usePortfolioItemsById} from '~/global/state-hooks/mixed-source/usePortfolioItemsById'
import {getInstrumentTypeLabels} from '~/global/utils/filter-and-sort/filterAndSort'
import Chips from '~/global/widgets/chips/Chips'
import portfolioStyles from '~/sections/invest/sections/portfolio/Portfolio.scss'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'

const ALL_RISK_LEVELS: [number, number] = [1, 7]

const riskLevels: {[key: string]: [number, number]} = {
    'Lower risk': [1, 2],
    'Medium risk': [3, 4],
    'Higher risk': [5, 7],
}

const convertDomainToLabel = (selectedRiskDomain: [number, number] | null) => {
    const riskLabel = Object.keys(riskLevels).find(
        label =>
            selectedRiskDomain &&
            riskLevels[label][0] === selectedRiskDomain[0] &&
            riskLevels[label][1] === selectedRiskDomain[1],
    )
    return riskLabel ? [riskLabel] : []
}

const orderCountryChipLabelsByHomeCurrency = (homeCurrency: Model.User['home_currency']) => {
    // Convert the homecurrency to a full name for the Chip label
    // Distill needs 'aus' 'nzl' 'usa' strings for API request
    switch (homeCurrency) {
        case 'aud':
            return {Australia: 'aus', 'New Zealand': 'nzl', 'United States': 'usa'}
        case 'usd':
            return {'United States': 'usa', Australia: 'aus', 'New Zealand': 'nzl'}
        case 'nzd':
        default:
            return {'New Zealand': 'nzl', Australia: 'aus', 'United States': 'usa'}
    }
}

const getCountryByValue = (value: string, countryLabels: {[countryName: string]: string}) => {
    const country = Object.keys(countryLabels).find(key => countryLabels[key] === value)
    return country ? [country] : []
}

const PortfolioFilterChips: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const setPortfolioRiskLevelLabel = (riskLabel: string) =>
        dispatch(instrumentActions.SetPortfolioRiskLevelLabel(riskLabel))
    const setPortfolioInstrumentExchangeCountry = (instrumentExchangeCountry: string[], instrumentIds: string[]) =>
        dispatch(
            instrumentActions.executeSetPortfolioInstrumentExchangeCountry(instrumentExchangeCountry, instrumentIds),
        )
    const setPortfolioInstrumentRiskLevel = (riskLevel: [number, number], instrumentIds: string[]) =>
        dispatch(instrumentActions.executeSetPortfolioInstrumentRiskLevel(riskLevel, instrumentIds))
    const setPortfolioInstrumentTypes = (instrumentTypes: string[], instrumentIds: string[]) =>
        dispatch(instrumentActions.executeSetPortfolioInstrumentTypes(instrumentTypes, instrumentIds))

    // From distill
    const currentInstrumentExchangeCountry = useAppSelector(s => s.instrument.portfolioInstrumentExchangeCountry)
    const currentInstrumentRiskLevel = useAppSelector(s => s.instrument.portfolioInstrumentRiskLevel)
    const currentInstrumentTypes = useAppSelector(s => s.instrument.portfolioInstrumentTypes)
    const instrumentTypes = useAppSelector(s => s.instrument.metadata.instrumentTypes)

    // From retail-backend
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const filterPreference = useAppSelector(s => s.identity.portfolioFilterPreference)

    // From mixed sources
    const totalPortfolioItems = usePortfolioItemsById()

    const portfolioInstrumentIds = Object.keys(totalPortfolioItems)
    const countryLabels: {[countryName: string]: string} = orderCountryChipLabelsByHomeCurrency(homeCurrency)
    const instrumentTypeLabels = getInstrumentTypeLabels(instrumentTypes, jurisdiction)

    return (
        <div className={portfolioStyles.chipsWrapper}>
            {filterPreference === 'TYPE' && (
                <Chips
                    options={instrumentTypeLabels}
                    hasAllOption
                    isInlineDisplay
                    selected={currentInstrumentTypes}
                    onChipClick={instrumentTypeLabel => {
                        setPortfolioInstrumentTypes(
                            instrumentTypeLabel === 'all' ? [] : [instrumentTypeLabel],
                            portfolioInstrumentIds,
                        )
                    }}
                />
            )}
            {!filterPreference ||
                (filterPreference === 'RISK' && (
                    <Chips
                        options={Object.keys(riskLevels)}
                        hasAllOption
                        isInlineDisplay
                        selected={convertDomainToLabel(currentInstrumentRiskLevel)}
                        onChipClick={riskLabel => {
                            Analytics.event({
                                category: 'Portfolio',
                                action: 'Changed filter',
                                label: riskLabel,
                            })

                            setPortfolioInstrumentRiskLevel(
                                riskLabel && riskLabel ? riskLevels[riskLabel] : ALL_RISK_LEVELS,
                                portfolioInstrumentIds,
                            )

                            setPortfolioRiskLevelLabel(riskLabel)
                        }}
                    />
                ))}
            {filterPreference === 'COUNTRY' && (
                <Chips
                    options={Object.keys(countryLabels)}
                    hasAllOption
                    isInlineDisplay
                    selected={getCountryByValue(currentInstrumentExchangeCountry[0], countryLabels)}
                    onChipClick={countryLabel => {
                        Analytics.event({
                            category: 'Portfolio',
                            action: 'Changed filter',
                            label: countryLabel,
                        })

                        setPortfolioInstrumentExchangeCountry(
                            countryLabel === 'all' ? [] : [countryLabels[countryLabel]],
                            portfolioInstrumentIds,
                        )
                    }}
                />
            )}
        </div>
    )
}

export default PortfolioFilterChips
