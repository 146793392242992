import React from 'react'

const KiwiSaverTransactionFeesDetail: React.FC = () => {
    return (
        <div>
            <h1>Transaction fees</h1>
            <p>
                Each time you buy or sell units in one of your picks, you’ll be charged a transaction fee on the amount
                bought (or sold).
            </p>
            <p>It’s 1% for amounts up to $1000, plus 0.1% for amounts over $1000.</p>
            <p>If you invest in more than one pick, the orders are treated separately and have separate fees.</p>
            <p>
                If any of your picks are exchange-traded funds, the fund provider may also charge you fees. These fees
                are included in the fund’s unit price and may impact your returns.
            </p>
        </div>
    )
}

export default KiwiSaverTransactionFeesDetail
