import {Button} from '@design-system/button'
import {Edit, Pause, AutoInvest, Trash} from '@design-system/icon'
import {Modal} from '@design-system/modal'
import cn from 'classnames'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {accessibility} from '~/global/scss/helpers'
import {formatDollar} from '~/global/utils/format-dollar/formatDollar'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Toast} from '~/global/widgets/toast/Toast'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {useNavigate} from '~/migrate-react-router'
import {
    useCurrentDIYOrder,
    useCurrentPremadeOrder,
} from '~/sections/invest/sections/auto-invest/hooks/useAutoinvestOrders'
import actions from '~/store/autoinvest/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import styles from './EditControl.scss'

type EditbuttonOptions = 'pause' | 'unpause' | 'edit' | 'switch' | 'delete'

interface EditControlProps {
    accountRestricted: boolean
}
/**
 * Below you will find option buttons of the edit button that have been wrapped inside list items.
 *
 * Each of the onClick actions have been implemented on the list item tags instead of being directly
 * implemented on the actual buttons.
 *
 * This implementation prevents the onClick action from being nested too deep and allows React
 * to register each click/select on these options accurately and consistently.
 */

const EditControl = ({accountRestricted}: EditControlProps) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const {showModalError} = React.useContext(NotificationContext)

    const [currentDiyOrder] = useCurrentDIYOrder()
    const [currentPremadeOrder] = useCurrentPremadeOrder()
    const currentOrder = currentDiyOrder || currentPremadeOrder

    const [showMenu, setShowMenu] = React.useState(false)
    const [showDeleteModal, setShowDeleteModal] = React.useState(false)

    const hideMenu = () => setShowMenu(false)

    React.useEffect(() => {
        window.addEventListener('click', hideMenu)
        return () => {
            window.removeEventListener('click', hideMenu)
            setShowDeleteModal(false)
        }
    }, [])

    // when the modal is open & user presses Escape key, this closes the modal
    React.useEffect(() => {
        if (!showMenu) {
            return
        }

        const handleEscapeKey = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                setShowMenu(false)
            }
        }

        window.addEventListener('keyup', handleEscapeKey)

        return () => {
            window.removeEventListener('keyup', handleEscapeKey)
        }
    }, [showMenu])

    if (!currentOrder) {
        // No order to edit
        return null
    }

    const orderType = 'premadeOrder' in currentOrder ? currentOrder.premadeOrder.name : 'DIY'

    const handleEditButtonOptions = (action: EditbuttonOptions, error?: string | undefined) => {
        switch (action) {
            case 'pause':
                if (error) {
                    showModalError({message: error})
                } else {
                    Analytics.event({
                        category: 'AutoInvest',
                        action: 'Pause Existing Order',
                        label: orderType,
                    })
                    Toast('Your auto-invest has been paused')
                }
                break
            case 'unpause':
                if (error) {
                    showModalError({message: error})
                } else {
                    Analytics.event({
                        category: 'AutoInvest',
                        action: 'Unpause Existing Order',
                        label: orderType,
                    })
                    Toast('Your auto-invest has been unpaused')
                }
                break
            case 'edit':
                if (!accountRestricted) {
                    dispatch(actions.StageExistingOrder(currentOrder))
                    if ('premadeOrder' in currentOrder) {
                        Analytics.event({
                            category: 'AutoInvest',
                            action: 'Edit Auto Invest Order',
                            label: orderType,
                        })

                        navigate(
                            profileUrl('auto-invest/:premadeSlug/how-much', {
                                premadeSlug: currentOrder.premadeOrder.slug,
                            }),
                            {replace: true},
                        )
                    } else {
                        navigate(profileUrl('auto-invest/diy/percentage'), {
                            replace: true,
                        })
                    }
                }
                break
            case 'switch':
                if (!accountRestricted) {
                    Analytics.event({
                        category: 'AutoInvest',
                        action: 'Switch Auto Invest Order',
                        label: orderType,
                    })
                    navigate(profileUrl('auto-invest'))
                }
                break
            case 'delete':
                setShowDeleteModal(true)
                break
            default:
                showModalError({message: 'Something has gone wrong, please try again later'})
        }
    }

    const isPaused = currentOrder.state === 'paused'

    const handleConfirmDelete = async () => {
        const error = await dispatch(actions.DeleteCurrentOrder(currentOrder.id))
        navigate(profileUrl('auto-invest'))
        if (error) {
            showModalError({message: error})
        } else {
            dispatch(actions.ClearSelectedDiyOrderId())
            setShowDeleteModal(false)
            Analytics.event({
                category: 'AutoInvest',
                action: 'Delete Auto Invest Order',
                label: orderType,
            })

            Toast('Your auto-invest has been deleted')
        }
    }

    const handleEditButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        setShowMenu(!showMenu)
    }

    return (
        <>
            <Button
                label="Manage"
                type="primary"
                dataTestId="button--edit-control"
                additionalClassName={styles.button}
                onClick={e => handleEditButtonClick(e)}
                icon="Edit"
            />

            <nav className={cn(styles.menu, {[styles.visible]: showMenu})}>
                <ul>
                    <li
                        onClick={async () => {
                            const error = await dispatch(actions.PauseCurrentOrder(!isPaused, currentOrder.id))
                            handleEditButtonOptions(isPaused ? 'unpause' : 'pause', error)
                        }}
                    >
                        <button
                            type="button"
                            className={cn(accessibility.button, styles.menuButton)}
                            data-testid={isPaused ? 'button--unpause-auto-invest' : 'button--pause-auto-invest'}
                        >
                            <Pause /> {isPaused ? 'Unpause auto-invest' : 'Pause auto-invest'}
                        </button>
                    </li>
                    <li onClick={() => handleEditButtonOptions('edit')}>
                        <button
                            type="button"
                            className={cn(
                                {[styles.disabled]: accountRestricted},
                                accessibility.button,
                                styles.menuButton,
                            )}
                            data-testid="button--edit-auto-invest"
                            disabled={accountRestricted}
                        >
                            <Edit /> Edit auto-invest
                        </button>
                    </li>
                    <li onClick={() => handleEditButtonOptions('switch')}>
                        <button
                            type="button"
                            className={cn(accessibility.button, styles.menuButton, {
                                [styles.disabled]: accountRestricted,
                            })}
                            data-testid="button--switch-order"
                            disabled={accountRestricted}
                        >
                            <AutoInvest /> Switch order
                        </button>
                    </li>
                    <li onClick={() => handleEditButtonOptions('delete')}>
                        <button
                            type="button"
                            className={cn(accessibility.button, styles.menuButton)}
                            data-testid="button--delete-auto-invest"
                        >
                            <Trash /> Delete auto-invest
                        </button>
                    </li>
                </ul>
            </nav>
            <div className={cn(styles.menuOverlay, {[styles.visible]: showMenu})} onClick={hideMenu} />
            <Modal
                isAlert
                isOpen={showDeleteModal}
                setIsOpen={setShowDeleteModal}
                dataTestId="modal--delete-your-autoinvest"
                title="Delete your auto-invest"
                secondaryButton={{label: 'Cancel'}}
                primaryButton={{label: 'Delete this order', onClick: () => handleConfirmDelete()}}
            >
                <p>
                    Are you sure you want to delete your{' '}
                    {'premadeOrder' in currentOrder
                        ? currentOrder.premadeOrder.name
                        : jurisdiction === 'au'
                          ? ''
                          : 'DIY'}{' '}
                    auto-invest order of {formatDollar(currentOrder.amount, false)} every {currentOrder.displayInterval}
                    ?
                </p>
            </Modal>
        </>
    )
}

export default EditControl
