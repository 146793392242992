import {Button} from '@design-system/button'
import {useAtomValue} from 'jotai'
import React from 'react'
import {useRetailPost} from '~/api/query/retail'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {ErrorBox} from '~/global/widgets/form-controls'
import {LoginForm} from '~/global/widgets/login-form/LoginForm'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {AccountLinkingCommonProps} from '~/sections/moose/sections/sign-up/sections/account-linking/AccountLinking'
import {constructEntityDetail} from '~/sections/moose/sections/sign-up/sections/account-linking/pages/utils/construct-entity-detail/constructEntityDetail'
import {constructIdentityDetail} from '~/sections/moose/sections/sign-up/sections/account-linking/pages/utils/construct-identity-detail/constructIdentityDetail'
import {stagedSignUpAtom} from '~/sections/moose/sections/sign-up/state'
import {useSignupEntityDetail} from '~/sections/moose/sections/sign-up/utils/sign-up-detail-hooks/signUpDetailHooks'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'

const Login = ({signupId, progressStep, regressStep, initialEmail, navTo}: AccountLinkingCommonProps) => {
    const dispatch = useAppDispatch()
    const [error, setError] = React.useState('')
    const stagedSignUp = useAtomValue(stagedSignUpAtom)
    const signupEntityDetail = useSignupEntityDetail()
    const actor = useAppSelector(s => s.identity.actor)

    const linkAccountQuery = useRetailPost({
        path: 'fonterra/signup/link-account',
    })

    const title = initialEmail ? 'Your email is already used for a Sharesies account' : 'I have a Sharesies account'

    const linkAccount = async (actor: Model.User) => {
        setError('') // reset error

        if (!stagedSignUp.tcVersion || !actor.email) {
            throw new Error('Missing details when trying to complete setup')
        }

        const response = await linkAccountQuery.mutateAsync({
            signup_id: signupId,
            signup_attempt_token: stagedSignUp.signupAttemptToken || '', // let the api handle not having a signup attempt token
            identity: constructIdentityDetail(actor.email, stagedSignUp, signupEntityDetail),
            entities: constructEntityDetail(stagedSignUp, signupEntityDetail),
        })

        if (response.type === 'identity_authenticated') {
            progressStep()
        } else {
            dispatch(identityActions.Logout()) // ensures we can trigger linkAccount again if login fails
            setError(response.message)
        }
    }

    React.useEffect(() => {
        // actor will be present on a successful login
        if (actor) {
            linkAccount(actor)
        }
    }, [actor?.id])

    const onBack = () => {
        return initialEmail ? regressStep() : navTo('existing-account-check')
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--fonterra-signup-login"
                leftButton="back"
                onLeftButtonClick={onBack}
                title={title}
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                {initialEmail && (
                    <p className={spacing.spaceBelow24}>Log in and we’ll add your business to your existing account.</p>
                )}
                <AlertCard type="info" title="One account, multiple profiles" className={spacing.spaceBelow40}>
                    You’ll only need to remember one password, and your personal and business investments will be kept
                    separate.
                </AlertCard>
                <LoginForm
                    showSignUpCallToAction={false}
                    initialEmail={initialEmail}
                    doLogin={(...args) => dispatch(identityActions.Login(...args))}
                    hideDownloadAppBanner
                    disablePageButtonStyles
                />
                <ErrorBox className={spacing.spaceAbove8} message={error} />
                <Button
                    additionalClassName={spacing.spaceAbove12}
                    label="Create a new Sharesies account"
                    type="secondary"
                    dataTestId="button--new-account"
                    onClick={() => navTo('new-account')}
                />
            </Page>
        </>
    )
}

export default Login
