import React from 'react'
import NotFound from '~/global/pages/not-found/NotFound'
import Page from '~/global/widgets/page/Page'
import {Mode} from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/util'
import NZIdentityForms from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/widgets/nz-forms/NZIdentityForms'
import SignupToolbar from '~/sections/user/sections/sign-up/widgets/signup-toolbar/SignupToolbar'
import {useAppSelector} from '~/store/hooks'
import IdentitySelector from './IdentitySelector'
import ManualSignUpIdentityCheck from './ManualSignUpIdentityCheck'
import NZAccessCard from './NZAccessCard'
import OtherIdentityDetails from './OtherIdentityDetails'
import {ManualIdentityStep, getNextStep} from './util'

const NZIdentitySelector: React.FunctionComponent = () => {
    const [mode, setMode] = React.useState<Mode>()
    const [step, setStep] = React.useState<ManualIdentityStep>('identitySelection')

    const preferredName = useAppSelector(({identity}) => identity.user!.preferred_name)
    const isDependent = useAppSelector(({identity}) => identity.user!.is_dependent)

    const IdentitySelectorFlow = () => {
        switch (step) {
            case 'identitySelection':
                return <IdentitySelector mode={mode} step={step} setStep={setStep} setMode={setMode} />
            case 'manualIDSelection':
                return <OtherIdentityDetails mode={mode} setMode={setMode} step={step} setStep={setStep} label="" />
            case 'NZfirearmsLicence':
                return <ManualSignUpIdentityCheck />
            case 'OverseasPassport':
                return <ManualSignUpIdentityCheck />
            case 'nzAccesscard':
                return <NZAccessCard mode={mode} setMode={setMode} step={step} setStep={setStep} />
            case 'Complete':
                return <ManualSignUpIdentityCheck />
            case 'identityForms':
                return (
                    <NZIdentityForms
                        isDependent={isDependent}
                        preferredName={preferredName}
                        mode={mode}
                        setStep={() => setStep(getNextStep(step, mode))}
                    />
                )
        }
        return <NotFound /> // This should never happen
    }

    return (
        <>
            <SignupToolbar />
            <Page>{IdentitySelectorFlow()}</Page>
        </>
    )
}

export default NZIdentitySelector
