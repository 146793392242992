import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import SharesiesPineapple from '~/global/widgets/sharesies-pineapple/SharesiesPineapple'
import {Toolbar, ToolbarButton} from '~/global/widgets/toolbar/Toolbar'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {useLocation, useNavigate} from '~/migrate-react-router'
import EditAddress from '~/sections/invest/sections/us-sign-up/pages/edit-address/EditAddress'
import EditCitizenship from '~/sections/invest/sections/us-sign-up/pages/edit-citizenship/EditCitizenship'
import EditMailingAddress from '~/sections/invest/sections/us-sign-up/pages/edit-mailing-address/EditMailingAddress'
import EditTaxDetails from '~/sections/invest/sections/us-sign-up/pages/edit-tax-details/EditTaxDetails'
import FormInformation from '~/sections/invest/sections/us-sign-up/pages/form-information/FormInformation'
import VoluntaryResignCheck from '~/sections/invest/sections/us-sign-up/pages/form-information/VoluntaryResign'
import ProvideCitizenship from '~/sections/invest/sections/us-sign-up/pages/provide-citizenship/ProvideCitizenship'
import SignW8Ben from '~/sections/invest/sections/us-sign-up/pages/sign-w8-ben/SignW8Ben'
import SignW9 from '~/sections/invest/sections/us-sign-up/pages/sign-w9/SignW9'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import {User} from '~/store/identity/types'
import signUpActions from '~/store/sign-up/actions'
import styles from './USSignUp.scss'

type USSignUpStep =
    | undefined
    | 'form-information'
    | 'provide-citizenship'
    | 'edit-citizenship'
    | 'edit-address'
    | 'edit-mailing-address'
    | 'edit-tax-details'
    | 'review-w8-form'
    | 'review-w9-form'
    | 'welcome'
    | 'welcome-back'
    | 'complete'

const USSignUp: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const profileUrl = useProfileUrl()

    const [currentStep, setCurrentStep] = React.useState<USSignUpStep>(undefined)
    const [completedFormInfo, setCompletedFormInfo] = React.useState<boolean>(false)
    const [completedCitizenship, setCompletedCitizenship] = React.useState<boolean>(false)
    const [editingCitizenship, setEditingCitizenship] = React.useState<boolean>(false)
    const [editingAddress, setEditingAddress] = React.useState<boolean>(false)
    const [editingMailingAddress, setEditingMailingAddress] = React.useState<boolean>(false)
    const [editingTaxResidency, setEditingTaxResidency] = React.useState<boolean>(false)
    const notificationContext = React.useContext(NotificationContext)
    const [pageTitle, setPageTitle] = React.useState<string>('')

    const isDependent = useAppSelector(s => Boolean(s.identity.user?.is_dependent)) // force type to prevent error on logout
    const preferredName = useAppSelector(s => String(s.identity.user?.preferred_name)) // force type to prevent error on logout
    const usPreSignUpLocation = useAppSelector(s => s.signUp.usPreSignUpLocation)
    const usStatus = useAppSelector(s => s.signUp.usStatus)

    const usStateMachineStepMapping = (userSignupState: User['customer_signup_state']) => {
        if (userSignupState && usStatus) {
            const stepMap = {
                INITIAL: 'form-information',
                CITIZENSHIP: 'provide-citizenship',
                CERTIFICATION: 'review-w8-form', // Or w9
                WELCOME: 'welcome',
                COMPLETED: 'complete',
                RESIGN_REQUIRED: 'form-information',
                RESIGN_CERTIFICATION:
                    usStatus.proposed_tax_treaty_status === 'us' ? 'review-w9-form' : 'review-w8-form',
                RESIGN_WELCOME: 'welcome-back',
                // NOTE:  those ENUMs are not use in `US`
                TERMS_AND_CONDITIONS: '',
                EMAIL_VERIFICATION: '',
                IDENTITY_DETAILS: '',
                ADDRESS_VERIFICATION: '',
                ADDRESS_UPLOAD: '',
                AWAITING_ADDRESS_UPLOAD: '',
                AWAITING_ADDRESS_REVIEW: '',
                TAX_QUESTIONS: '',
                NZX_PARTICIPANT: '',
                NATURE_AND_PURPOSE: '',
                DEPENDENT_DECLARATION: '',
                IRD_NUMBER: '',
                MAKE_ACTIVE: '',
            }
            return stepMap[userSignupState] as USSignUpStep
        }
    }

    // When aware of State Machine status, make the call whether or not to initialise it
    React.useEffect(() => {
        dispatch(signUpActions.getUSStatus())

        // If USShares State Machine NOT initialised
        if (!usStatus?.customer_signup_state) {
            dispatch(signUpActions.initUSStateMachine())
        }
    }, [])

    React.useEffect(() => {
        if (usStatus) {
            const userSignupState = usStatus.customer_signup_state
            if (userSignupState) {
                setCurrentStep(usStateMachineStepMapping(userSignupState))

                // those state are still handling in the FE
                if (editingCitizenship) {
                    setCurrentStep('edit-citizenship')
                } else if (editingAddress) {
                    setCurrentStep('edit-address')
                } else if (editingMailingAddress) {
                    setCurrentStep('edit-mailing-address')
                } else if (editingTaxResidency) {
                    setCurrentStep('edit-tax-details')
                } else if (usStatus.required_steps.includes('sign_w9')) {
                    setCurrentStep('review-w9-form')
                }
            }
        }
    }, [
        usStatus,
        completedFormInfo,
        completedCitizenship,
        editingCitizenship,
        editingAddress,
        editingMailingAddress,
        editingTaxResidency,
    ])

    React.useEffect(() => {
        if (currentStep) {
            Analytics.pageNav(`${location.pathname}/${currentStep}`)
        }
    }, [currentStep])

    if (!usStatus) {
        return <Loading isPineapple />
    }
    const setPageTitleWrapper = (title: string) => {
        if (pageTitle !== title) {
            setPageTitle(title)
        }
    }

    const usSignUpStepContents = () => {
        switch (currentStep) {
            case 'form-information':
                const signup_state = usStatus?.customer_signup_state

                switch (signup_state) {
                    case 'RESIGN_REQUIRED':
                    case 'RESIGN_CERTIFICATION':
                        setPageTitleWrapper(`Confirm ${isDependent ? `${preferredName}’s` : 'your'} tax details`)
                        break
                    default:
                        setPageTitleWrapper(`Fill out ${isDependent ? `${preferredName}’s` : 'your'} US tax form`)
                }

                return (
                    <FormInformation
                        setCompletedFormInfo={setCompletedFormInfo}
                        isDependent={isDependent}
                        preferredName={preferredName}
                    />
                )
            case 'provide-citizenship':
                setPageTitleWrapper('Citizenship')
                return <ProvideCitizenship setCompletedCitizenship={setCompletedCitizenship} />
            case 'review-w8-form':
                setPageTitleWrapper('Certify and sign the W-8BEN form')
                return (
                    <SignW8Ben
                        setEditingAddress={setEditingAddress}
                        setEditingMailingAddress={setEditingMailingAddress}
                        setEditingTaxResidency={setEditingTaxResidency}
                        setEditingCitizenship={setEditingCitizenship}
                    />
                )
            case 'review-w9-form':
                setPageTitleWrapper('Certify and sign the the W-9 form')
                return (
                    <SignW9
                        setEditingAddress={setEditingAddress}
                        setEditingTaxResidency={setEditingTaxResidency}
                        setEditingCitizenship={setEditingCitizenship}
                    />
                )
            case 'edit-citizenship':
                setPageTitleWrapper('Citizenship')
                return <EditCitizenship isEditing={setEditingCitizenship} />
            case 'edit-address':
                setPageTitleWrapper(`Edit ${isDependent ? `${preferredName}’s` : 'your'} address`)
                return <EditAddress isEditing={setEditingAddress} />

            case 'edit-mailing-address':
                setPageTitleWrapper('Mailing address')
                return <EditMailingAddress isEditing={setEditingMailingAddress} />
            case 'edit-tax-details':
                setPageTitleWrapper('Countries you pay tax in')
                return <EditTaxDetails isEditing={setEditingTaxResidency} />
            case 'welcome':
                dispatch(identityActions.Check())
                notificationContext.showFullscreenNotification(
                    <div className={styles.signUpSuccess}>
                        <SharesiesPineapple colour="ConstantWhite" className={styles.signUpSuccessImage} />

                        <p>Woo! You can now invest in US Shares{isDependent && ` for ${preferredName}`}!</p>
                    </div>,
                )
                return navigate(profileUrl('us-sign-up/intro'), {replace: true})
            case 'welcome-back':
                dispatch(identityActions.Check())
                notificationContext.showFullscreenNotification(
                    <div className={styles.signUpSuccess}>
                        <SharesiesPineapple colour="ConstantWhite" className={styles.signUpSuccessImage} />
                        <p>Woo! You’re good to go!</p>
                    </div>,
                )
                return navigate(profileUrl('us-sign-up/intro'), {replace: true})

            case 'complete':
            default:
                return <VoluntaryResignCheck />
        }
    }

    const isAnEditDetailsPage = editingCitizenship || editingAddress || editingTaxResidency || editingMailingAddress

    return (
        <>
            <Toolbar
                dataTestId="toolbar--us-signup"
                title={pageTitle}
                leftButton={isAnEditDetailsPage ? 'back' : undefined}
                leftSlot={
                    !isAnEditDetailsPage && (
                        <ToolbarButton
                            dataTestId="toolbar--us-signup-close-button"
                            icon="close"
                            iconTitle="Cancel U S signup"
                            onClick={() => {
                                // do a precautionery refresh of identity in case of things like residency having been updated
                                dispatch(identityActions.Check())
                                if (usPreSignUpLocation) {
                                    navigate(usPreSignUpLocation, {replace: true})
                                } else {
                                    navigate(profileUrl(''), {replace: true})
                                }
                            }}
                        />
                    )
                }
                onLeftButtonClick={() => {
                    if (isAnEditDetailsPage) {
                        setEditingCitizenship(false)
                        setEditingTaxResidency(false)
                        setEditingAddress(false)
                        setEditingMailingAddress(false)
                        usStatus.required_steps.includes('sign_w8ben')
                            ? setCurrentStep('review-w8-form')
                            : setCurrentStep('review-w9-form')
                    }
                }}
            />
            <Page className={styles.usSignUpPage}>{usSignUpStepContents()}</Page>
        </>
    )
}

export default USSignUp
