import {semanticColour} from '@design-system/colour-tokens'
import {ModalLink} from '@design-system/modal'
import {useColourMode} from '@design-system/use-colour-mode'
import {CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js'
import {
    StripeCardNumberElementChangeEvent,
    StripeCardExpiryElementChangeEvent,
    StripeCardCvcElementChangeEvent,
    StripeElementChangeEvent,
    StripeElementStyle,
} from '@stripe/stripe-js'
import cn from 'classnames'
import React from 'react'
import {CommonProps, withFocus, FocusInjectedProps, withId} from '~/global/widgets/form-controls/common'
import styles from '~/global/widgets/form-controls/form.scss'
import cvcDark from '~/global/widgets/form-controls/stripe/assets/images/gradient-icon-cvc-dark.svg'
import cvcLight from '~/global/widgets/form-controls/stripe/assets/images/gradient-icon-cvc-light.svg'
import {Image} from '~/global/widgets/image/Image'
import stripeStyles from './StripeElements.scss'

export interface StripeFieldValue {
    empty: boolean
    complete: boolean
    error?: StripeElementChangeEvent['error']
}

export const stripeFieldValueDefault: StripeFieldValue = {
    empty: true,
    complete: false,
}

type StripeCardNumberProps = CommonProps<StripeFieldValue> & FocusInjectedProps

type ReactStripeElement = typeof CardNumberElement | typeof CardExpiryElement | typeof CardCvcElement
type ReactStripeElementChangeEvent =
    | StripeCardNumberElementChangeEvent
    | StripeCardExpiryElementChangeEvent
    | StripeCardCvcElementChangeEvent

const generateComponent = (Component: ReactStripeElement, extra?: React.ReactNode) =>
    withId(
        withFocus((props: StripeCardNumberProps) => {
            const colourMode = useColourMode()
            const value = props.value || stripeFieldValueDefault
            const error = props.error || (value.error && value.error.message ? value.error.message : undefined)

            const className = cn(styles.field, styles.text, {
                [styles.hasError]: props.isTouched && !!error,
                [styles.touched]: props.isTouched,
                [styles.filled]: !value.empty,
                [styles.disabled]: props.disabled,
                [styles.focus]: props.isFocused,
            })

            const elementStyles: StripeElementStyle = {
                base: {
                    fontFamily: 'centra, serif',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',
                    color: semanticColour('InputText', colourMode),

                    '::placeholder': {
                        color: semanticColour('InputPlaceholder', colourMode),
                        fontWeight: 500,
                    },
                },
                invalid: {
                    color: semanticColour('ErrorPrimary', colourMode),
                },
            }

            return (
                <div className={cn(className, error && styles.error)} data-testid={props.dataTestId}>
                    <label htmlFor={props.id}>{props.label}</label>
                    <div className={cn(styles.control, error && styles.hasError, styles.touched)}>
                        <Component
                            options={{style: elementStyles}}
                            id={props.id}
                            className={cn(stripeStyles.element, {
                                [stripeStyles.hasFocus]: props.isFocused,
                            })}
                            onChange={(e: ReactStripeElementChangeEvent): any => {
                                return (
                                    props.setFieldValue &&
                                    props.setFieldValue({
                                        empty: e.empty,
                                        complete: e.complete,
                                        error: e.error,
                                    })
                                )
                            }}
                            onBlur={() => {
                                props.onBlur(props.id)
                                return
                            }}
                            onFocus={() => {
                                props.onFocus(props.id)
                                return
                            }}
                        />
                        {extra}
                    </div>
                    <div className={styles.helpSpace}>
                        {props.isTouched && error && <span className={styles.error}>{error}</span>}
                    </div>
                </div>
            )
        }),
    )

export const StripeCardNumber = generateComponent(CardNumberElement)
export const StripeExpiryDate = generateComponent(CardExpiryElement)
export const StripeCVC = generateComponent(
    CardCvcElement,
    <ModalLink
        dataTestId="modal-link--cvc-number"
        aria-label="Your C V C number"
        label="Your CVC number"
        asIcon
        modalTitle="Here’s your CVC number"
        primaryButton={{label: 'Oh, that thing'}}
        additionalClassName={stripeStyles.modalLink}
    >
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Image
                alt="the CVC number is 3 digits on the back of your bank card beside the signature area"
                src={cvcLight}
                darkSrc={cvcDark}
            />
        </div>
    </ModalLink>,
)
