import {CopyField} from '@design-system/copy-field'
import {Modal, ModalLink} from '@design-system/modal'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {useRetailGet} from '~/api/query/retail'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {useActorHasSeen} from '~/global/state-hooks/retail/useActor'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {dateFormatLongMonth} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AccountVerificationRequired from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import {PayIDPaymentFeeModal} from '~/global/widgets/instrument-activity/common'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {Navigate} from '~/migrate-react-router'
import styles from './TopUpWithPayId.scss'

const FREE_PERIOD_END_DATE = DateTime.fromISO('2024-08-30').endOf('day')
const TODAY = DateTime.local().startOf('day')

export const TopUpWithPayId: React.FC = () => {
    const profileUrl = useProfileUrl()
    const [hasSeen, setHasSeen] = useActorHasSeen('pay_id')

    const owner = useProfileOwner()
    const {data: payId, error} = useRetailGet({path: 'banklinking/:owner_id/pay-id', pathParams: {owner_id: owner.id}})

    if (owner.product_jurisdiction === 'nz') {
        return <Navigate to={profileUrl('wallet')} replace />
    }

    if (error) {
        const message = error.type === 'internal_server_error' ? '500' : error.message
        throw new Error(`Error creating PayID: ${message}`)
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--top-up-with-bank-deposit" leftButton="back" title="Top up with PayID" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <AccountVerificationRequired />
                <p className={spacing.spaceBelow8}>Fund your account instantly.</p>
                <h2 className={spacing.spaceBelow4}>To top up:</h2>
                <ol className={cn(styles.payIdSteps, spacing.spaceBelow16)}>
                    <li>
                        copy your unique Sharesies PayID{' '}
                        <ModalLink
                            label="PayID"
                            modalTitle="What is PayID®?"
                            dataTestId="modal-link--payid-info"
                            primaryButton={{label: 'Got it'}}
                            asIcon
                            helpIconSize={16}
                        >
                            <p>
                                PayID is a secure payment service that links your account to an easy-to-remember
                                identifier, like an email or phone number.
                            </p>
                            <p>
                                We’ve made a unique Sharesies PayID email address for you to use to instantly send money
                                from your bank account to your Wallet.
                            </p>
                        </ModalLink>
                    </li>
                    <li>make a payment through your bank</li>
                    <li>wait a few seconds for the money to arrive in your Wallet.</li>
                </ol>
                <p className={spacing.spaceBelow16}>The minimum top-up amount is $5 AUD.</p>
                <p className={spacing.spaceBelow24}>
                    {TODAY < FREE_PERIOD_END_DATE
                        ? `PayID is fee free until ${FREE_PERIOD_END_DATE.toLocaleString(
                              dateFormatLongMonth,
                          )}. After that, a PayID transfer fee applies.`
                        : 'A PayID transfer fee applies.'}

                    <PayIDPaymentFeeModal />
                </p>

                <h2 className={cn(spacing.spaceBelow4)}>Your Sharesies PayID:</h2>
                <CopyField
                    id="top-up-pay-id"
                    dataTestId="copy-field--top-up-pay-id"
                    label=""
                    additionalClassName={styles.copyField}
                    value={payId.pay_id}
                    onClick={() => {
                        rudderTrack('topup', 'bank_reference_copied')
                    }}
                />
                <p className={styles.subText}>
                    For first-time payments, it might take up to 10 minutes to register your PayID, and a bank may hold
                    the payment for a minimum of 24 hours.
                </p>
                <p className={cn(styles.subText, spacing.spaceAbove16)}>
                    If your payment isn’t made instantly, check its status with your bank.
                </p>
            </Page>
            <Modal
                setIsOpen={setHasSeen}
                isOpen={!hasSeen}
                title="First time PayID delay"
                dataTestId="modal--first-time-payid-delay"
                primaryButton={{label: 'Got it', onClick: () => setHasSeen()}}
            >
                <p>
                    The first time you set up a payment to your Sharesies PayID, your bank might not recognise your
                    unique identifier straight away.
                </p>
                <p>
                    If this happens, wait a few minutes and try again. The longest it should take for your bank to
                    recognise the ID is 10 minutes.
                </p>
                <p>
                    A bank may hold a first-time payment to a new payee for a minimum of 24 hours, depending on its
                    security rules. If your payment isn’t made instantly, check its status with your bank.
                </p>
            </Modal>
        </>
    )
}

export default TopUpWithPayId
