import {Request, Response} from '~/api/retail/types'
import {StagedSignUp} from '~/sections/moose/sections/sign-up/state'

export const constructEntityDetail = (
    stagedSignUp: StagedSignUp,
    signupEntityDetail: Response.FonterraSignUpEntityDetail,
): Request.FonterraSignUpLinkAccount['entities'] => {
    const entityDetails = signupEntityDetail.entities.map(entity => {
        const natureAndPurposeAnswers = stagedSignUp.natureAndPurpose.find(np => np.partyId === entity.party_id)
        const prescribedPersonAnswers = stagedSignUp.prescribedPerson
            ? stagedSignUp.prescribedPerson.find(pp => pp.partyId === entity.party_id)
            : undefined

        if (!natureAndPurposeAnswers || !prescribedPersonAnswers) {
            throw new Error('Missing details when trying to complete setup')
        }

        return {
            entity_id: entity.entity_id,
            party_id: entity.party_id,
            name: entity.name,
            address: entity.address,
            ird_number: entity.ird_number,
            tax_jurisdiction: entity.tax_jurisdiction,
            trading_bank_account: entity.trading_bank_account,
            farms: entity.farms,
            nature_and_purpose: {
                frequency: natureAndPurposeAnswers.frequency,
                purposes: natureAndPurposeAnswers.purposes,
                other_purpose: natureAndPurposeAnswers.otherPurpose,
            },
            prescribed_person: {
                prescribed_person: prescribedPersonAnswers.prescribedPerson || false,
                participant: prescribedPersonAnswers.participant,
                prescribed_email: prescribedPersonAnswers.prescribedEmail,
            },
        }
    })

    return entityDetails
}
