import {DateTime} from 'luxon'
import * as api from '~/api/retail'
import {Request, Response, Model} from '~/api/retail/types'
import * as rollbar from '~/api/rollbar/rollbar'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {errorResponseFactory} from '~/global/utils/error-handling/errorHandling'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {reauthenticateReturn} from '~/global/wrappers/global-wrapper-widgets/reauthenticate/Reauthenticate'
import identityActions from '../identity/actions'
import {actingAsID} from '../identity/selectors'
import {ActionsUnion, createAction} from '../redux-tools'
import {ThunkAction, Dispatch, RootState} from '../types'
import {
    State,
    Action,
    Transaction,
    StagedExchangeOrder,
    Order,
    TopupGoalInterval,
    WalletTransactionsFilter,
    VoluntaryCorporateActionV2,
} from './types'

const actions = {
    ClearInvestingActivityRecords: () => createAction('accounting.clear_investing_activity_records'),
    ClearPendingInvestingActivityRecords: () => createAction('accounting.clear_pending_investing_activity_records'),
    ClearStagedExchangeOrder: () => createAction('accounting.clear_staged_exchange_order'),
    ClearTransactions(): Action.ClearTransactions {
        return {type: 'accounting.clear_transactions'}
    },
    ClearWalletTransactions: () => createAction('accounting.clear_wallet_transactions'),
    FetchFundOrdersEnd(
        fundId: string,
        orders: Order[],
        recentTaxInfo: Response.AccountingOrderHistoryV5['recent_tax_info'],
    ): Action.FetchFundOrdersEnd {
        return {type: 'accounting.fetch_fund_orders_end', fundId, orders, recentTaxInfo, fetched: DateTime.local()}
    },
    FetchFundOrdersStart(fundId: string): Action.FetchFundOrdersStart {
        return {type: 'accounting.fetch_fund_orders_start', fundId}
    },
    FetchRecentOrdersEnd(orders: Response.AccountingRecentOrders['orders']): Action.FetchRecentOrdersEnd {
        return {type: 'accounting.fetch_recent_orders_end', orders, fetched: DateTime.local()}
    },
    FetchRecentOrdersStart(): Action.FetchRecentOrdersStart {
        return {type: 'accounting.fetch_recent_orders_start'}
    },
    ResetAccountingData: () => createAction('accounting.reset_accounting_data'),
    SetBankAccounts: (accounts: State['accounts']) => createAction('accounting.set_bank_accounts', {accounts}),
    SetBankAccountsError: () => createAction('accounting.set_bank_accounts_error'),
    SetCards: (cards: Response.IdentityCards['cards']) => createAction('accounting.set_cards', {cards}),
    SetCardsError: () => createAction('accounting.set_cards_error'),
    SetCurrentTopupGoal: (goal: Response.TopupGoal['goal']) => createAction('accounting.set_current_topup_goal', goal),
    SetExchangeRate: (
        sourceCurrency: Request.FXOrder['source_currency'],
        targetCurrency: Request.FXOrder['target_currency'],
        rate: number,
    ) => createAction('accounting.set_exchange_rate', {sourceCurrency, targetCurrency, rate}),
    SetExchangeFeeRate: (feeRate: number) => createAction('accounting.set_exchange_fee_rate', {feeRate}),
    SetFetchFundOrdersError: () => createAction('accounting.set_fetch_fund_orders_error'),
    SetWalletTransactionsFetchingOlder(fetchingOlder: boolean): Action.SetWalletTransactionsFetchingOlder {
        return {type: 'accounting.set_wallet_transactions_fetching_older', fetchingOlder}
    },
    SetFetchRecentOrdersError: () => createAction('accounting.set_fetch_recent_orders_error'),
    SetPastInvestingActivityCurrentPage: (page: number) =>
        createAction('accounting.set_past_investing_activity_current_page', page),
    SetPastInvestingActivityHasMore: (hasMore: State['pastInvestingActivityHasMore']) =>
        createAction('accounting.set_past_investing_activity_has_more', hasMore),
    SetPastInvestingActivityLoadingState: (newState: State['pastInvestingActivityLoadingState']) =>
        createAction('accounting.set_past_investing_activity_loading_state', newState),
    SetPastInvestingActivityRecords: (records: State['pastInvestingActivityRecords']) =>
        createAction('accounting.set_past_investing_activity_records', records),
    SetPendingInvestingActivityLoadingState: (newState: State['pendingInvestingActivityLoadingState']) =>
        createAction('accounting.set_pending_investing_activity_loading_state', newState),
    SetPendingInvestingActivityRecords: (records: State['pendingInvestingActivityRecords']) =>
        createAction('accounting.set_pending_investing_activity_records', records),
    SetPortfolioPageCurrentTab: (newTab: State['portfolioPageCurrentTab']) =>
        createAction('accounting.set_portfolio_page_current_tab', newTab),
    SetReferralInformation(
        pendingInvites: number,
        signUps: number,
        bonusAmount: string,
        asAt: DateTime,
    ): Action.SetReferralInformation {
        return {
            type: 'accounting.set_referral_information',
            pendingInvites,
            signUps,
            bonusAmount,
            asAt,
        }
    },
    SetStagedExchangeOrder: (order: StagedExchangeOrder) => createAction('accounting.set_staged_exchange_order', order),
    SetViewingOlderTransactions: (viewingOlderTransactions: boolean) =>
        createAction('accounting.set_viewing_older_transactions', viewingOlderTransactions),
    SetWalletPageLoadingState(loadingState: State['walletPageLoadingState']): Action.SetWalletPageLoadingState {
        return {type: 'accounting.set_wallet_page_loading_state', loadingState}
    },
    SetWalletTransactionsCurrentFilter: (filter: WalletTransactionsFilter) =>
        createAction('accounting.set_wallet_transactions_current_filter', filter),
    SetWalletTransactionsFilterOptions: (filters: Response.AccountingTransactionHistoryV2['filter_options']) =>
        createAction('accounting.set_wallet_transactions_filter_options', filters),
    SetWalletTransactionsLoadingState: (state: State['walletTransactionsLoadingState']) =>
        createAction('accounting.set_wallet_transactions_loading_state', state),
    TruncateTransactions(): Action.TruncateTransactions {
        return {type: 'accounting.truncate_transactions'}
    },
    UpdateStagedExchangeOrderError: (errorState: boolean) =>
        createAction('accounting.update_staged_exchange_order_error', errorState),
    UpdateTransactions(transactions: Transaction[], hasMore: boolean): Action.UpdateTransactions {
        return {type: 'accounting.update_transactions', transactions, hasMore}
    },
    SetPaymentRequests: (payload: Model.PaymentRequest[]) => createAction('accounting.set_payment_requests', payload),
}

const thunkActions = {
    init(): ThunkAction<void> {
        return async (dispatch, getState) => {
            const currentFilter = getState().accounting.walletTransactionsCurrentFilter

            dispatch(actions.SetWalletPageLoadingState('initialising'))
            await Promise.all([
                dispatch(thunkActions.FetchTransactionHistory(currentFilter)),
                dispatch(thunkActions.FetchPaymentRequests()),
                dispatch(thunkActions.FetchCurrentTopupGoal()),
            ])
            dispatch(actions.SetWalletPageLoadingState('ready'))
        }
    },
    AddAUBankAccount(
        bank_account_number: string,
        bank_account_bsb: string,
        bank_account_name: string,
    ): ThunkAction<Promise<Response.Error | void>> {
        return async (dispatch, getState) => {
            const response = await api.post('identity/au-bank-accounts/add', {
                bank_account_number,
                bank_account_name,
                bank_account_bsb,
                acting_as_id: actingAsID(getState()),
            })
            switch (response.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () =>
                            thunkActions.AddAUBankAccount(
                                bank_account_number,
                                bank_account_bsb,
                                bank_account_name,
                            )(dispatch, getState),
                        () => errorResponseFactory('Please try again'),
                        () => errorResponseFactory('You need to supply your password to continue'),
                    )
                case 'error':
                    return response
                case 'identity_au_bank_accounts':
                    dispatch(actions.SetBankAccounts(response.accounts))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    AddBankAccount(
        bank_account_number: string,
        bank_account_name: string,
    ): ThunkAction<Promise<Response.Error | void>> {
        return async (dispatch, getState) => {
            const response = await api.post('identity/bank-accounts/add', {
                bank_account_number,
                bank_account_name,
                acting_as_id: actingAsID(getState()),
            })
            switch (response.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => thunkActions.AddBankAccount(bank_account_number, bank_account_name)(dispatch, getState),
                        () => errorResponseFactory('Please try again'),
                        () => errorResponseFactory('You need to supply your password to continue'),
                    )
                case 'error':
                    return response
                case 'identity_bank_accounts':
                    dispatch(actions.SetBankAccounts(response.accounts))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    CancelOrder(order: CancellableOrder): ThunkAction<Promise<void | Response.Error>> {
        return async (dispatch, getState) => doCancelOrder(dispatch, getState, order)
    },
    CancelApplication(order: VoluntaryCorporateActionV2): ThunkAction<Promise<void | Response.Error>> {
        return async (dispatch, getState) => doCancelApplication(dispatch, getState, order)
    },
    CreateExchangeMoneyOrder(): ThunkAction<Promise<Response.Error | void>> {
        return async (dispatch, getState) => {
            const state = getState()

            if (!state.accounting.stagedExchangeOrder) {
                return
            }

            const {sourceCurrency, sourceAmount, targetCurrency, targetAmount, exchangeRate, exchangeFee, buyOrSell} =
                state.accounting.stagedExchangeOrder

            if (!sourceAmount || !targetAmount || !exchangeFee || !exchangeRate) {
                return
            }

            const response = await api.post('fx/create-order', {
                acting_as_id: actingAsID(state),
                source_currency: sourceCurrency,
                target_currency: targetCurrency,
                quoted_rate: exchangeRate,
                source_amount: sourceAmount,
                target_amount: targetAmount,
                source_fee: exchangeFee,
                buy_or_sell: buyOrSell,
            })

            switch (response.type) {
                case 'account_restricted':
                    throw new Error('Restricted accounts should not be able to exchange money')
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => thunkActions.CreateExchangeMoneyOrder()(dispatch, getState),
                        () => errorResponseFactory('Please try again'),
                        () => errorResponseFactory('You need to supply your password to continue'),
                    )
                case 'error':
                    return response
                case 'fx_order_fulfilled':
                    // Updating wallet balances immediately
                    dispatch(identityActions.Check())
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    CreateNewTopupGoal(
        amountPerInterval: string,
        interval: TopupGoalInterval,
        targetAmount: string,
    ): ThunkAction<Promise<string | undefined>> {
        return async (dispatch, getState) => {
            const response = await api.post('topup-goal/new', {
                acting_as_id: actingAsID(getState()),
                amount_per_interval: amountPerInterval,
                interval,
                target_amount: targetAmount,
            })

            switch (response.type) {
                case 'topup_goal':
                    dispatch(actions.SetCurrentTopupGoal(response.goal))
                    return response.type
                case 'access_denied':
                    return response.error
                case 'error':
                    return response.message
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
                    break
            }
        }
    },
    DeleteAUBankAccount(id: string): ThunkAction<Promise<string | undefined>> {
        return async (dispatch, getState) => {
            const response = await api.post('identity/au-bank-accounts/delete', {
                bank_account_id: id,
                acting_as_id: actingAsID(getState()),
            })

            switch (response.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => thunkActions.DeleteAUBankAccount(id)(dispatch, getState),
                        () => 'Please try again',
                        () => 'You need to supply your password to continue',
                    )
                case 'error':
                    return response.message
                case 'identity_au_bank_accounts':
                    dispatch(actions.SetBankAccounts(response.accounts))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
                    break
            }
        }
    },
    DeleteBankAccount(id: string): ThunkAction<Promise<string | undefined>> {
        return async (dispatch, getState) => {
            const response = await api.post('identity/bank-accounts/delete', {
                bank_account_id: id,
                acting_as_id: actingAsID(getState()),
            })

            switch (response.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => thunkActions.DeleteBankAccount(id)(dispatch, getState),
                        () => 'Please try again',
                        () => 'You need to supply your password to continue',
                    )
                case 'error':
                    return response.message
                case 'identity_bank_accounts':
                    dispatch(actions.SetBankAccounts(response.accounts))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
                    break
            }
        }
    },
    DeleteCard(): ThunkAction<Promise<string | void>> {
        return async (dispatch, getState) => {
            const {
                accounting: {cards},
            } = getState()

            if (!cards) {
                return 'No card to delete'
            }
            const card_id = cards[0].id

            const response = await api.post('identity/cards/delete', {card_id, acting_as_id: actingAsID(getState())})

            switch (response.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => thunkActions.DeleteCard()(dispatch, getState),
                        () => 'Please try again',
                        () => 'You need to supply your password to continue',
                    )
                case 'identity_cards':
                    dispatch(actions.SetCards(response.cards))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },

    DeleteCurrentTopupGoal(goalId: string): ThunkAction<Promise<string | undefined>> {
        return async (dispatch, getState) => {
            const response = await api.post('topup-goal/delete', {
                acting_as_id: actingAsID(getState()),
                goal_id: goalId,
            })

            switch (response.type) {
                case 'topup_goal_deleted':
                    dispatch(actions.SetCurrentTopupGoal(undefined))
                    return
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () => this.DeleteCurrentTopupGoal(goalId)(dispatch, getState),
                        () => 'Please try again',
                        () => 'You need to enter your password before you can delete your top up goal.',
                    )
                case 'access_denied':
                    return response.error
                case 'resource_not_found':
                    return response.error
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    FetchCurrentTopupGoal(): ThunkAction<Promise<Response.Error | void>> {
        return async (dispatch, getState) => {
            const state = getState()

            const response = await api.get('topup-goal/current', {
                acting_as_id: actingAsID(state),
            })

            switch (response.type) {
                case 'topup_goal':
                    dispatch(actions.SetCurrentTopupGoal(response.goal))
                    return
                case 'error':
                    return response
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    FetchFundOrders(fundId: string): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            try {
                dispatch(actions.FetchFundOrdersStart(fundId))
                const data = await api.get('accounting/order-history-v5', {
                    fund_id: fundId,
                    acting_as_id: actingAsID(getState()),
                })

                switch (data.type) {
                    case 'accounting_order_history_v5':
                        dispatch(actions.FetchFundOrdersEnd(fundId, data.orders, data.recent_tax_info))
                        return
                    case 'error':
                        // This shouldn't happen
                        rollbar.sendError(data.code, {
                            statusText: data.message,
                            method: 'FetchFundOrders',
                        })
                        return
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(data)
                }
            } catch (e) {
                dispatch(actions.SetFetchFundOrdersError())
            }
        }
    },
    FetchPastInvestingActivity(page: number): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            try {
                dispatch(actions.SetPastInvestingActivityLoadingState('loading'))

                const data = await api.get('accounting/investing-activity', {
                    acting_as_id: actingAsID(getState()),
                    page,
                    state: 'terminal',
                })

                switch (data.type) {
                    case 'accounting_investing_activity':
                        dispatch(actions.SetPastInvestingActivityRecords(data.records))
                        if (data.page) {
                            dispatch(actions.SetPastInvestingActivityCurrentPage(data.page))
                        }
                        if (data.has_more) {
                            dispatch(actions.SetPastInvestingActivityHasMore(data.has_more))
                        }
                        dispatch(actions.SetPastInvestingActivityLoadingState('ready'))
                        return
                    case 'error':
                        // This shouldn't happen
                        rollbar.sendError(data.code, {
                            statusText: data.message,
                            method: 'FetchPastInvestingActivity',
                        })
                        return
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(data)
                }
            } catch {
                dispatch(actions.SetPastInvestingActivityLoadingState('error'))
            }
        }
    },
    FetchPendingInvestingActivity(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            try {
                dispatch(actions.SetPendingInvestingActivityLoadingState('loading'))

                const data = await api.get('accounting/investing-activity', {
                    acting_as_id: actingAsID(getState()),
                    state: 'pending',
                })

                switch (data.type) {
                    case 'accounting_investing_activity':
                        dispatch(actions.SetPendingInvestingActivityRecords(data.records))
                        dispatch(actions.SetPendingInvestingActivityLoadingState('ready'))
                        return
                    case 'error':
                        // This shouldn't happen
                        rollbar.sendError(data.code, {
                            statusText: data.message,
                            method: 'FetchPendingInvestingActivity',
                        })
                        return
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(data)
                }
            } catch {
                dispatch(actions.SetPendingInvestingActivityLoadingState('error'))
            }
        }
    },
    FetchNewReferralInformation(asAt?: DateTime): ThunkAction<Promise<void | Response.GiftingReferralInformation>> {
        return async dispatch => {
            const response = await api.get('gifting/referral-information', {as_at: asAt})

            switch (response.type) {
                case 'gifting_referral_information':
                    dispatch(
                        actions.SetReferralInformation(
                            response.pending_invites,
                            response.sign_ups,
                            response.bonus_amount,
                            response.as_at,
                        ),
                    )
                    return response
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    FetchNewTransactions(): ThunkAction<Promise<void>> {
        return (dispatch, getState) => {
            const {
                accounting: {walletPageLoadingState: state, walletTransactions: transactions},
            } = getState()

            if (state === 'initialising') {
                // Already initialising
                return Promise.resolve()
            }

            const params: FetchTransactionParams = {}
            if (transactions.length) {
                const firstTransaction = transactions[0]

                params.since_line_number = firstTransaction.line_number
                params.since_transaction_id = firstTransaction.transaction_id
                params.since_timestamp = firstTransaction.timestamp
            }
            const currentFilter = getState().accounting.walletTransactionsCurrentFilter
            if (currentFilter !== 'all') {
                params.filter = currentFilter
            }

            return fetchTransactions(dispatch, getState, params)
        }
    },
    FetchOldTransactions(): ThunkAction<Promise<void>> {
        return (dispatch, getState) => {
            const {
                accounting: {walletPageLoadingState: state, walletTransactions: transactions},
            } = getState()

            if (state === 'initialising') {
                // Already initialising
                return Promise.resolve()
            }

            dispatch(actions.SetWalletTransactionsFetchingOlder(true))

            const params: FetchTransactionParams = {}
            if (transactions.length) {
                const finalTransaction = transactions[transactions.length - 1]

                params.before_line_number = finalTransaction.line_number
                params.before_timestamp = finalTransaction.timestamp
                params.before_transaction_id = finalTransaction.transaction_id
            }
            const currentFilter = getState().accounting.walletTransactionsCurrentFilter
            if (currentFilter !== 'all') {
                params.filter = currentFilter
            }

            return fetchTransactions(dispatch, getState, params)
                .then(() => {
                    dispatch(actions.SetWalletTransactionsFetchingOlder(false))
                })
                .catch(() => {
                    dispatch(actions.SetWalletTransactionsFetchingOlder(false))
                })
        }
    },
    FetchRecentOrders(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const state = getState()

            if (state.identity.user!.state === 'in_signup') {
                return
            }

            try {
                dispatch(actions.FetchRecentOrdersStart())
                const data = await api.get('accounting/recent-orders-v3', {acting_as_id: actingAsID(getState())})

                switch (data.type) {
                    case 'accounting_recent_orders_v3':
                        dispatch(actions.FetchRecentOrdersEnd(data.orders))
                        return
                    case 'error':
                        // This shouldn't happen
                        rollbar.sendError(data.code, {
                            statusText: data.message,
                            method: 'FetchRecentOrders',
                        })
                        return
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(data)
                }
            } catch (e) {
                dispatch(actions.SetFetchRecentOrdersError())
            }
        }
    },
    FetchTransactionHistory(filter: WalletTransactionsFilter): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const params: FetchTransactionParams = {}
            if (filter !== 'all') {
                params.filter = filter
            }
            fetchTransactions(dispatch, getState, params)
        }
    },
    GetAllExchangeRates(): ThunkAction<Promise<void>> {
        return async dispatch => {
            dispatch(thunkActions.GetCachedExchangeRates())
        }
    },
    GetExchangeFeeRate(): ThunkAction<Promise<void>> {
        return async dispatch => {
            dispatch(thunkActions.GetAllExchangeRates())
        }
    },
    GetCachedExchangeRates(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const payload = {
                acting_as_id: actingAsID(getState()),
            }
            const endpoint = 'fx/get-rate-v2/stale'
            const response = await api.get(endpoint, payload)

            switch (response.type) {
                case 'fx_order_current_rate_v2':
                    dispatch(actions.SetExchangeFeeRate(parseFloat(response.fx_fee_rate)))
                    response.fx_currencies.map(e => {
                        dispatch(actions.SetExchangeRate(e.source_currency, e.target_currency, parseFloat(e.rate)))
                    })
                    break
                case 'error':
                    // in the case of not getting any data for this, we will not display an estimated wallet balance
                    rollbar.sendError(`fxGetRate fail: ${response.message}`, {
                        method: 'GetCachedExchangeRates',
                        endpoint,
                        code: response.code,
                        message: response.message,
                    })
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    RefreshAUBankAccounts(): ThunkAction<void> {
        return async (dispatch, getState) => {
            try {
                const response = await api.get('identity/au-bank-accounts', {acting_as_id: actingAsID(getState())})
                switch (response.type) {
                    case 'identity_au_bank_accounts':
                        dispatch(actions.SetBankAccounts(response.accounts))
                        break
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(response)
                }
            } catch (e) {
                dispatch(actions.SetBankAccountsError())
            }
        }
    },
    RefreshBankAccounts(valid_for_withdrawal: boolean = false): ThunkAction<void> {
        return async (dispatch, getState) => {
            try {
                const response = await api.get('identity/bank-accounts', {
                    acting_as_id: actingAsID(getState()),
                    valid_for_withdrawal,
                })
                switch (response.type) {
                    case 'identity_bank_accounts':
                        dispatch(actions.SetBankAccounts(response.accounts))
                        break
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(response)
                }
            } catch (e) {
                dispatch(actions.SetBankAccountsError())
            }
        }
    },
    RefreshCards(): ThunkAction<void> {
        return async (dispatch, getState) => {
            try {
                const response = await api.get('identity/cards', {acting_as_id: actingAsID(getState())})
                switch (response.type) {
                    case 'identity_cards':
                        dispatch(actions.SetCards(response.cards))
                        break
                    case 'internal_server_error':
                        // TODO: handle this properly
                        break
                    default:
                        assertNever(response)
                }
            } catch (e) {
                dispatch(actions.SetCardsError())
            }
        }
    },
    UpdateAUBankAccount(
        bank_account_id: string,
        bank_account_number: string,
        bank_account_bsb: string,
        bank_account_name: string,
    ): ThunkAction<Promise<Response.Error | void>> {
        return async (dispatch, getState) => {
            const response = await api.post('identity/au-bank-accounts/update', {
                bank_account_id,
                bank_account_name,
                bank_account_number,
                bank_account_bsb,
                acting_as_id: actingAsID(getState()),
            })

            switch (response.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () =>
                            thunkActions.UpdateAUBankAccount(
                                bank_account_id,
                                bank_account_number,
                                bank_account_bsb,
                                bank_account_name,
                            )(dispatch, getState),
                        () => errorResponseFactory('Please try again'),
                        () => errorResponseFactory('You need to supply your password to continue'),
                    )
                case 'error':
                    return response
                case 'identity_au_bank_accounts':
                    dispatch(actions.SetBankAccounts(response.accounts))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    UpdateDebitBankAccount(
        bank_account_id: string,
        bank_account_number: string,
        bank_account_name: string,
    ): ThunkAction<Promise<Response.Error | void>> {
        return async (dispatch, getState) => {
            const response = await api.post('identity/bank-accounts/update', {
                bank_account_id,
                bank_account_name,
                bank_account_number,
                acting_as_id: actingAsID(getState()),
            })

            switch (response.type) {
                case 'authentication_update_required':
                    return reauthenticateReturn(
                        () =>
                            thunkActions.UpdateDebitBankAccount(
                                bank_account_id,
                                bank_account_number,
                                bank_account_name,
                            )(dispatch, getState),
                        () => errorResponseFactory('Please try again'),
                        () => errorResponseFactory('You need to supply your password to continue'),
                    )
                case 'error':
                    return response
                case 'identity_bank_accounts':
                    dispatch(actions.SetBankAccounts(response.accounts))
                    break
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
            }
        }
    },
    UpdateCurrentTopupGoal(
        goalId: string,
        amountPerInterval: string,
        interval: TopupGoalInterval,
        targetAmount: string,
        celebrated: boolean,
    ): ThunkAction<Promise<string | undefined>> {
        return async (dispatch, getState) => {
            const response = await api.post('topup-goal/update', {
                acting_as_id: actingAsID(getState()),
                goal_id: goalId,
                amount_per_interval: amountPerInterval,
                interval,
                target_amount: targetAmount,
                celebrated,
            })

            switch (response.type) {
                case 'topup_goal':
                    dispatch(actions.SetCurrentTopupGoal(response.goal))
                    return response.type
                case 'access_denied':
                    return response.error
                case 'resource_not_found':
                    return response.error
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(response)
                    break
            }
        }
    },
    FetchPaymentRequests(): ThunkAction<Promise<void>> {
        return async (dispatch, getState) => {
            const response = await api.get('accounting/payment-requests', {
                acting_as_id: actingAsID(getState()),
            })

            if (response.type === 'payment_requests') {
                dispatch(
                    actions.SetPaymentRequests(
                        // Only show processing payment requests in the wallet for now.
                        response.payment_requests.filter(paymentRequest => paymentRequest.state === 'processing'),
                    ),
                )
                return
            } else {
                rollbar.sendError(`FetchPaymentRequests fail: ${response.type}`, {
                    method: 'FetchPaymentRequests',
                    endpoint: 'accounting/payment-requests',
                })
            }
        }
    },
}
interface FetchTransactionParams {
    since_line_number?: number
    since_timestamp?: DateTime
    since_transaction_id?: number
    before_line_number?: number
    before_timestamp?: DateTime
    before_transaction_id?: number
    filter?: Request.AccountingTransactionHistoryV2['filter']
}

function fetchTransactions(
    dispatch: Dispatch,
    getState: () => RootState,
    params: FetchTransactionParams = {},
): Promise<void> {
    const currentStoreFilter = getState().accounting.walletTransactionsCurrentFilter
    const currentFilter = params.filter || 'all'
    const filterChanged = currentStoreFilter !== currentFilter

    if (filterChanged) {
        // If the filter has changed, we need to clear the wallet
        // transactions while the filtered transactions are retrieved
        dispatch(actions.SetWalletTransactionsLoadingState('loading'))
        dispatch(actions.ClearWalletTransactions())
        dispatch(actions.SetWalletTransactionsCurrentFilter(currentFilter))
    }

    return api
        .get('accounting/transaction-history-v2', {...params, limit: 50, acting_as_id: actingAsID(getState())})
        .then(data => {
            switch (data.type) {
                case 'accounting_transaction_history_v2':
                    dispatch(actions.SetWalletTransactionsLoadingState('ready'))
                    dispatch(actions.SetWalletTransactionsFilterOptions(data.filter_options))

                    // if we're just getting the most recent updates to the transaction list, use the existing state of hasOlder
                    dispatch(
                        actions.UpdateTransactions(
                            data.transactions,
                            params.since_transaction_id
                                ? getState().accounting.walletTransactionsHasOlder
                                : data.has_more,
                        ),
                    )
                    return
                case 'internal_server_error':
                    // TODO: handle this properly
                    break
                default:
                    assertNever(data)
            }
        })
}
interface CancellableOrder {
    id: string
    type: 'buy' | 'sell'
    fund_id: string
    is_auto_exercise?: boolean
}

async function doCancelOrder(
    dispatch: Dispatch,
    getState: () => RootState,
    order: CancellableOrder,
): Promise<void | Response.Error> {
    let order_type_to_send: Request.OrderCancelOrder['order_type'] = order.type
    if (order.is_auto_exercise) {
        order_type_to_send = 'portfolio'
    }

    const response = await api.post('order/cancel-order-v2', {
        order_id: order.id,
        order_type: order_type_to_send,
        acting_as_id: actingAsID(getState()),
    })

    switch (response.type) {
        case 'authentication_update_required':
            return reauthenticateReturn(
                () => doCancelOrder(dispatch, getState, order),
                () =>
                    ({
                        type: 'error',
                        message: 'Please resubmit your cancel request',
                        code: 'resubmit',
                    }) as Response.Error,
                () =>
                    ({
                        type: 'error',
                        message: 'You must enter your password before you can cancel this order',
                        code: 'enter_password',
                    }) as Response.Error,
            )
        case 'accounting_order_history_v5':
            sendWrapperAppMessage({type: 'identityUpdated'})
            const currentFilter = getState().accounting.walletTransactionsCurrentFilter

            dispatch(actions.FetchFundOrdersEnd(response.fund_id, response.orders, response.recent_tax_info))
            dispatch(identityActions.Check())
            dispatch(actions.ClearPendingInvestingActivityRecords())
            dispatch(thunkActions.FetchPendingInvestingActivity())
            dispatch(thunkActions.FetchTransactionHistory(currentFilter))
            break
        case 'account_restricted':
            throw new Error('Restricted accounts should not be able to place an order')
        case 'error':
            if (response.code === 'cancel_order_already_processing') {
                dispatch(thunkActions.FetchFundOrders(order.fund_id))
            }
            return response
        case 'internal_server_error':
            // TODO: handle this properly
            break
        default:
            assertNever(response)
    }
}

async function doCancelApplication(
    dispatch: Dispatch,
    getState: () => RootState,
    order: VoluntaryCorporateActionV2,
): Promise<void | Response.Error> {
    const response = await api.post('order/cancel-application-v2', {
        application_record_id: order.application.id,
        acting_as_id: actingAsID(getState()),
    })

    switch (response.type) {
        case 'authentication_update_required':
            return reauthenticateReturn(
                () => doCancelApplication(dispatch, getState, order),
                () =>
                    ({
                        type: 'error',
                        message: 'Please resubmit your cancel request',
                        code: 'resubmit',
                    }) as Response.Error,
                () =>
                    ({
                        type: 'error',
                        message: 'You must enter your password before you can cancel this order',
                        code: 'enter_password',
                    }) as Response.Error,
            )
        case 'accounting_order_history_v5':
            const currentFilter = getState().accounting.walletTransactionsCurrentFilter

            dispatch(actions.FetchFundOrdersEnd(response.fund_id, response.orders, response.recent_tax_info))
            dispatch(identityActions.Check())
            dispatch(actions.ClearPendingInvestingActivityRecords())
            dispatch(thunkActions.FetchPendingInvestingActivity())
            dispatch(thunkActions.FetchTransactionHistory(currentFilter))
            break
        case 'account_restricted':
            throw new Error('Restricted accounts should not be able to place an order')
        case 'error':
            if (response.code === 'cancel_order_already_processing') {
                dispatch(thunkActions.FetchFundOrders(order.application.fund_id))
            }
            return response
        case 'internal_server_error':
            // TODO: handle this properly
            break
        default:
            assertNever(response)
    }
}

export type ActionsType = ActionsUnion<typeof actions>

export default {...actions, ...thunkActions}
