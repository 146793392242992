import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {Radio} from '~/global/widgets/form-controls'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {useAppSelector, useAppDispatch} from '~/store/hooks'
import transferActions from '~/store/transfer/actions'
import {ResponseUsTransferPlatform} from '~/store/transfer/types'

const SelectPlatform: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const stagedTransferOrder = useAppSelector(({transfer}) => transfer.stagedTransferOrder)
    const jurisdiction = useAppSelector(({identity}) => identity.user!.jurisdiction)

    const [platform, setPlatform] = React.useState<ResponseUsTransferPlatform>(stagedTransferOrder?.usTransferPlatform)

    const platformOptions = [
        {
            value: 'INTERNAL',
            label:
                jurisdiction === 'nz'
                    ? 'Hatch, Stake, or another DriveWealth partner'
                    : 'Stake, Revolut, Douugh, or another DriveWealth partner',
        },
        {
            value: 'EXTERNAL',
            label:
                jurisdiction === 'nz'
                    ? 'Craigs, Interactive Brokers, or another non-Drivewealth partner'
                    : 'A non-DriveWealth partner',
        },
        {value: 'DRS', label: 'Computershare, Equiniti or another Transfer agent'},
    ]

    const directionOut = stagedTransferOrder?.direction === 'out'

    const nextPage = () => {
        if (!platform || !stagedTransferOrder) {
            return
        }
        // set the platform selection on the staged transfer order
        dispatch(
            transferActions.SetStagedTransferOrder({
                ...stagedTransferOrder,
                usTransferPlatform: platform,
            }),
        )

        // update the fees info to reflect chosen broker
        dispatch(transferActions.FetchInfo(stagedTransferOrder.direction, platform))

        navigate(profileUrl('invest/portfolio-transfer-shares/us/intro'))
    }

    if (!stagedTransferOrder || !stagedTransferOrder.exchange) {
        navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})
        return <></>
    }

    return (
        <>
            <Toolbar leftButton="back" dataTestId="toolbar--us-transfer-select-platform" />
            <Page>
                {directionOut ? (
                    <>
                        <h1 className={cn(spacing.spaceBelow32, commonStyles.heading)}>
                            Select platform to transfer to
                        </h1>
                        <p className={cn(commonStyles.formLabel, spacing.spaceBelow16)}>
                            Which platform do you want to transfer your shares to?
                        </p>
                    </>
                ) : (
                    <>
                        <h1 className={cn(spacing.spaceBelow32, commonStyles.heading)}>Select platform</h1>
                        <p className={cn(commonStyles.formLabel, spacing.spaceBelow16)}>
                            Which platform are the shares you want to transfer held with?
                        </p>
                    </>
                )}
                <Radio
                    dataTestId="radio--us-share-platform"
                    name="platform"
                    isTouched
                    choices={platformOptions}
                    onChange={e => setPlatform(e.target.value as 'INTERNAL' | 'EXTERNAL' | 'DRS')}
                    value={platform}
                />
                <p>
                    Unsure what your platform is? Contact{' '}
                    <a href="mailto:transfers@sharesies.co.nz">transfers@sharesies.co.nz</a>
                </p>
            </Page>
            <ActionBar>
                <Button dataTestId="button--next" label="Next" onClick={() => nextPage()} disabled={!platform} />
            </ActionBar>
        </>
    )
}

export default SelectPlatform
