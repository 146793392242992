/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {queryClient} from '~/api/query/client'
import * as api from '~/api/retail'
import {GET_MAP} from '~/api/retail/types'
import config from '~/configForEnv'
import {NonRollbarError} from '~/global/utils/error-handling/errorHandling'
import {Configuration, ResponseContext, FetchParams, Middleware} from '../'
import {DividendsApi} from './DividendsApi'
import {ExchangeCalendarApi} from './ExchangeCalendarApi'
import {InstrumentsApi} from './InstrumentsApi'
import {PublicApi} from './PublicApi'

export class TokenMiddleware implements Middleware {
    constructor(path: string) {
        this.tokenEndpoint = path
    }

    public async pre(context: ResponseContext): Promise<FetchParams | void> {
        const accessToken = await this.acquireToken()
        return {
            url: context.url,
            init: {
                ...context.init,
                headers: new Headers({
                    ...context.init.headers,
                    Authorization: `Bearer ${accessToken}`,
                }),
            },
        }
    }

    public post(context: ResponseContext): Promise<Response | void> {
        return Promise.resolve(context.response)
    }

    private tokenEndpoint: string

    private acquireToken(): Promise<string> {
        // use tanstack query (directly, can't use the hook here) so we can cache the value
        return queryClient.fetchQuery({
            queryKey: [this.tokenEndpoint],
            queryFn: async () => {
                const response = await api.get(this.tokenEndpoint as keyof GET_MAP, {})
                if (response.type === 'identity_token') {
                    return response.token
                }
                throw new NonRollbarError(`distill auth error: ${response.type}`)
            },
            staleTime: 12 * 60 * 1000, // 12 minutes
            cacheTime: 15 * 60 * 1000 - 5 * 1000, // 15 minutes minus 5 seconds of potential on the wire time for safety - server expiry time is 15 mins
        })
    }
}

export * from './DividendsApi'
export * from './ExchangeCalendarApi'
export * from './InstrumentsApi'
export * from './PublicApi'

export const dividendsApi = new DividendsApi(
    new Configuration({
        basePath: config.distillPath,
        middleware: [new TokenMiddleware('identity/distill-token')],
    }),
)
export const exchangeCalendarApi = new ExchangeCalendarApi(
    new Configuration({
        basePath: config.distillPath,
        middleware: [new TokenMiddleware('identity/distill-token')],
    }),
)
export const instrumentsApi = new InstrumentsApi(
    new Configuration({
        basePath: config.distillPath,
        middleware: [new TokenMiddleware('identity/distill-token')],
    }),
)
export const publicApi = new PublicApi(
    new Configuration({
        basePath: config.distillPath,
        middleware: [new TokenMiddleware('identity/distill-token')],
    }),
)

export const dividendsApiNewClientToken = new DividendsApi(
    new Configuration({
        basePath: config.distillPath,
        middleware: [new TokenMiddleware('identity/distill-token-v2')],
    }),
)
export const exchangeCalendarApiNewClientToken = new ExchangeCalendarApi(
    new Configuration({
        basePath: config.distillPath,
        middleware: [new TokenMiddleware('identity/distill-token-v2')],
    }),
)
export const instrumentsApiNewClientToken = new InstrumentsApi(
    new Configuration({
        basePath: config.distillPath,
        middleware: [new TokenMiddleware('identity/distill-token-v2')],
    }),
)
export const publicApiNewClientToken = new PublicApi(
    new Configuration({
        basePath: config.distillPath,
        middleware: [new TokenMiddleware('identity/distill-token-v2')],
    }),
)

export const distillApi = {
    dividendsApi,
    exchangeCalendarApi,
    instrumentsApi,
    publicApi,
}

export const distillApiNewClientToken = {
    dividendsApiNewClientToken,
    exchangeCalendarApiNewClientToken,
    instrumentsApiNewClientToken,
    publicApiNewClientToken,
}

export default distillApi
