import React from 'react'
import styles from '../Record.scss'

interface AmountFooterProps {
    orderAmount?: React.ReactNode
    orderAmountText?: React.ReactNode
    labelText?: string
}

const AmountFooter = ({orderAmount, orderAmountText, labelText}: AmountFooterProps) => {
    if (orderAmount || orderAmountText || labelText) {
        return (
            <div data-testid="footer--order-amount">
                {orderAmountText && <p className={styles.orderAmountLabel}>{orderAmountText}</p>}
                <div className={styles.orderFooter}>
                    <div>
                        {labelText && (
                            <p className={styles.statusLabel} data-testid="orderStatusLabel">
                                {labelText}
                            </p>
                        )}
                    </div>
                    <div>{orderAmount && <p className={styles.orderAmount}>{orderAmount}</p>}</div>
                </div>
            </div>
        )
    }
    return null
}

export default AmountFooter
