import React from 'react'
import {useParams} from 'react-router-dom'
import {DistillScope} from '~/api/query/distill'
import PortfolioOrderInvestingActivity from '~/global/widgets/instrument-activity/portfolio-order/PortfolioOrderInvestingActivity'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useDistillInstrument} from '~/sections/kiwisaver/data/distill'
import {usePortfolioOrder} from '~/sections/kiwisaver/data/retail/portfolioOrder'

const InvestingActivityDetails: React.FunctionComponent = () => {
    const {investingId} = useParams()

    if (!investingId) {
        throw new Error('Missing investing Id')
    }

    const portfolioOrder = usePortfolioOrder(investingId)
    if (!portfolioOrder) {
        throw new Error('Missing portfolio order')
    }
    if (!portfolioOrder.underlying_fund_id) {
        throw new Error('Missing underlying fund for order')
    }

    const underlyingInstrument = useDistillInstrument({
        instrumentId: portfolioOrder.underlying_fund_id,
        scope: DistillScope.KIWISAVER_ALL_FUNDS,
        isUnderlyingInstrument: true,
    })

    return (
        <div>
            <div>
                <Toolbar leftButton="back" dataTestId="toolbar--activity" />
            </div>
            <Page withoutDefaultPadding>
                <PortfolioOrderInvestingActivity order={portfolioOrder} instrument={underlyingInstrument} />
            </Page>
        </div>
    )
}

export default InvestingActivityDetails
