import cn from 'classnames'
import React from 'react'
import {InstrumentPurchasePriceInfo} from '~/api/rakaia'
import tooltipStyles from '~/global/scss/reused-styles/tooltip.scss'
import {Period, periodInformation} from '~/global/utils/time-period/timePeriod'
import {SharePriceValue} from '~/global/widgets/number-elements/NumberElements'
import Tooltip from '~/global/widgets/tooltip/Tooltip'
import labelledValuesStyles from '~/sections/invest/sections/view-instrument/LabelledValues.scss'

const InvestingHistorySummary: React.FunctionComponent<InvestingHistorySummaryProps> = ({
    period,
    purchasePriceInfo,
}) => {
    const theLowercasePeriod = period.toLowerCase() as Lowercase<Period>
    const info = purchasePriceInfo[`period_${theLowercasePeriod}`]
    const periodLabel = periodInformation[period].label
    const toolTipLabel = period !== 'All' ? period : 'All-time'

    return (
        <section className={labelledValuesStyles.container}>
            <div>
                <Tooltip>
                    <p className={cn(tooltipStyles.label, labelledValuesStyles.label)}>{toolTipLabel} low</p>
                    <div className={tooltipStyles.tooltip}>
                        The lowest price you’ve paid for this investment
                        {periodLabel && ' over the past ' + periodLabel}
                    </div>
                </Tooltip>
                {info.lowest_price ? (
                    <p className={labelledValuesStyles.value}>
                        <SharePriceValue value={info.lowest_price} />
                    </p>
                ) : (
                    <p className={labelledValuesStyles.noData}>No {period} data</p>
                )}
            </div>
            <div>
                <Tooltip>
                    <p className={cn(tooltipStyles.label, labelledValuesStyles.label)}>{toolTipLabel} high</p>
                    <div className={tooltipStyles.tooltip}>
                        The highest price you’ve paid for this investment
                        {periodLabel && ' over the past ' + periodLabel}
                    </div>
                </Tooltip>
                {info.highest_price ? (
                    <p className={labelledValuesStyles.value}>
                        <SharePriceValue value={info.highest_price} />
                    </p>
                ) : (
                    <p className={labelledValuesStyles.noData}>No {period} data</p>
                )}
            </div>
            <div>
                <Tooltip>
                    <p className={cn(tooltipStyles.label, labelledValuesStyles.label)}>{toolTipLabel} average</p>
                    <div className={tooltipStyles.tooltip}>
                        The average price you’ve paid for this investment
                        {periodLabel && ' over the past ' + periodLabel}
                    </div>
                </Tooltip>
                {info.average_price ? (
                    <p className={labelledValuesStyles.value}>
                        <SharePriceValue value={info.average_price} />
                    </p>
                ) : (
                    <p className={labelledValuesStyles.noData}>No {period} data</p>
                )}
            </div>
        </section>
    )
}

interface InvestingHistorySummaryProps {
    period: Period
    purchasePriceInfo: InstrumentPurchasePriceInfo
}

export default InvestingHistorySummary
