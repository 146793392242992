import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {useAppSelector} from '~/store/hooks'
import {ExchangeCountry} from '~/store/instrument/types'
import {useReadPortfolio} from '../rakaia/useReadPortfolio'

type CurrencyToCountryMap = {
    [key in Currency]: ExchangeCountry[number]
}

export const currencyToCountryMap: CurrencyToCountryMap = {
    nzd: 'nzl',
    aud: 'aus',
    usd: 'usa',
}

export const useHasNonHomeCurrencyInvestment = (portfolioId: string) => {
    const portfolio = useReadPortfolio(portfolioId)
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)

    // Portfolio has no holdings, therefore no non home currency investments
    if (!portfolio.portfolio_countries) {
        return false
    }

    // returns true if percent of holdings in non home currency is > zero
    return portfolio.portfolio_countries[currencyToCountryMap[homeCurrency]].percent !== 100
}
