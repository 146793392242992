import {ModalLink} from '@design-system/modal'
import React from 'react'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {Instrument} from '~/store/instrument/types'

interface PartialFillInfoProps {
    instrument: Instrument
    title: string
    type: 'buy' | 'sell'
}

export const partialFillOrderInfoModalTitle = (
    instrument: Instrument,
    type: PartialFillInfoProps['type'],
    isAutoExercise?: boolean,
) => {
    if (type === 'buy') {
        return `${isAutoExercise ? 'Estimated buy' : 'Order'} amount remaining`
    } else {
        return `Remaining ${shareLabel({instrument, isPlural: true})} to sell`
    }
}

export const PartialFillOrderInfoModal = ({instrument, title, type}: PartialFillInfoProps) => {
    return (
        <ModalLink
            dataTestId="modal-link--partially-filled-order"
            label={title}
            asIcon
            modalTitle={title}
            primaryButton={{label: 'Ok'}}
        >
            <p>
                Orders may fill bit-by-bit, and can stay on the market for up to 30 days before expiring. If your order
                expires (or is cancelled),{' '}
                {type === 'buy'
                    ? 'the money for the unfilled part of your order is returned to your Wallet.'
                    : `any ${shareLabel({instrument, isPlural: true})} that aren’t sold will stay in your Portfolio.`}
            </p>
        </ModalLink>
    )
}
