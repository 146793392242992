// this file functionality is to support MP4 Alpha format for the video source.
// Please don't copy or use this library and functionality to your needs.
import UAParser from 'ua-parser-js'

const parser = new UAParser()
export const getBrowser = parser.getBrowser()
export const getDevice = parser.getDevice()
export const getOS = parser.getOS()

const browser = {
    chrome: () => {
        return getBrowser.name === 'Chrome'
    },
}

const device = {
    mobileAndTablet: () => {
        return getDevice.type === 'mobile' || getDevice.type === 'tablet'
    },
    appleDevices: () => {
        return getDevice.vendor === 'Apple'
    },
}

const os = {
    iOS: () => {
        return getOS.name === 'iOS'
    },
}

export const supportMP4Alpha = () => {
    return (
        // Apple devices but not on chomre
        (device.appleDevices() && !browser.chrome()) ||
        // iOS devices mobile, tablet and only chrome
        (os.iOS() && browser.chrome())
    )
}
