import Decimal from 'decimal.js'
import {atom} from 'jotai'
import {DateTime} from 'luxon/src/datetime'
import {Instrument} from '~/store/instrument/types'

export type ItemType = 'SHARE' | 'UNIT'
export type TimeOnMarketOptions = '1d' | '3d' | '5d' | 'custom'

export const FONTERRA_COOPERATIVE_GROUP = '0f5bf560-5542-4ac9-9138-b3558e8d4348'

export interface LimitOrder {
    itemType: ItemType
    instrument: Instrument
    pricePerItem?: Decimal
    numberOfItems?: number
    pushedHistory?: number
}

interface TimeOnMarket {
    lengthOfTimeOnMarket?: TimeOnMarketOptions
    customUntilDate?: DateTime
    timeoutDateToUse?: DateTime
}

export interface LimitBuyOrder extends LimitOrder, TimeOnMarket {
    totalAmount?: Decimal
    totalAmountBeforeFees?: Decimal
    expectedFee?: Decimal
    useDelayedSettlement?: boolean
}

export interface LimitSellOrder extends LimitOrder, TimeOnMarket {
    totalAmount?: Decimal
    totalAmountBeforeFees?: Decimal
    expectedFee?: Decimal
}

export const buyOrderAtom = atom<LimitBuyOrder | undefined>(undefined)
export const sellOrderAtom = atom<LimitSellOrder | undefined>(undefined)
