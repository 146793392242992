import {Modal} from '@design-system/modal'
import React from 'react'
import {Instrument} from '~/store/instrument/types'

export const DELAYED_PIE_FUNDS = ['790109', '790101']

interface PieFundClimateProcessingTimeProps {
    instrument: Instrument
    onClick(): void
    onClose(): void
}
const PieFundProcessingTime = ({instrument, onClick, onClose}: PieFundClimateProcessingTimeProps) => (
    <Modal
        isOpen
        setIsOpen={_ => {
            // The `_` argument will be called as false, but we don't need to use it in this case
            // Normally we'd just be passing in setModalOpen (setIsOpen={setModalOpen}), but we need to run a
            // side effect `onClose` rather than setting `isOpen(false)`
            onClose()
        }}
        title={`Longer processing times for the ${instrument.name}`}
        dataTestId="modal--pie-climate-processing-time"
        primaryButton={{label: 'Ok', onClick}}
    >
        <p>
            When you buy, Pie Funds generally take <strong>3 trading days</strong> to be confirmed and show in your
            Portfolio.
        </p>
        <p>
            When you sell it generally takes <strong>{instrument.symbol === '790109' ? '3' : '10'} trading days</strong>{' '}
            for the {instrument.name} to be confirmed and show in your Portfolio.
        </p>
    </Modal>
)

export default PieFundProcessingTime
