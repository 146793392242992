import {TileCard, TileCardProps} from '@design-system/tile-card'
import React from 'react'
import {useNavigate, NavigateFunction} from 'react-router'
import {ListingResponseDto} from '~/api/distill'
import config from '~/configForEnv'
import {GenerateOverlayFlagsProps, generateOverlayFlags} from '~/global/utils/tile-flag-helpers/tileFlagHelpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {DollarValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {useDistillSearch} from '~/sections/moose/data/distill'
import {useHoldingDetail} from '~/sections/moose/data/retail'
import {MoosePortfolioPathParams} from '~/sections/moose/routes'
import styles from './InstrumentsGrid.scss'

const determine_compliance_flag = (
    shareCount: number | null,
    shareCount_min: number | null,
    shareCount_max: number | null,
): GenerateOverlayFlagsProps => {
    if (shareCount == null || shareCount_min == null || shareCount_max == null) {
        return {}
    }

    if (shareCount < shareCount_min) {
        return {complianceStatus: 'BELOW_MIN'}
    }

    if (shareCount > shareCount_max) {
        return {complianceStatus: 'ABOVE_MAX'}
    }

    return {complianceStatus: 'IN_RANGE'}
}

const createInstrumentTileCard = (
    navigate: NavigateFunction,
    portfolioId: string,
    instrument: ListingResponseDto,
    flags: TileCardProps['overlayFlags'],
    shareCount: number | null,
    profileUrl: ReturnType<typeof useProfileUrl>,
) => {
    const value = shareCount ? shareCount * parseFloat(instrument.marketPrice) : null
    const displayStatsFooter = shareCount != null && shareCount > 0

    return (
        <TileCard
            dataTestId={`tile-card--portfolio-tile-${instrument.symbol.toLowerCase()}`}
            investmentThumbnailPath={instrument.logos.thumb ? config.distillPath + instrument.logos.thumb : undefined}
            investmentName={instrument.name}
            investmentSymbol={instrument.symbol}
            investmentTypeInformation={
                <>
                    <PronounceLetters text={instrument.symbol} /> <Delimiter /> <PronounceLetters text="NZX" />{' '}
                    <Delimiter /> <DollarValue value={instrument.marketPrice} currency="NZD" />
                </>
            }
            value={value ? <DollarValue value={value} /> : null}
            key={instrument.id}
            onClick={() =>
                navigate(
                    profileUrl('fonterra/portfolios/:portfolioId/invest/:urlSlug', {
                        urlSlug: instrument.urlSlug,
                        portfolioId,
                    }),
                )
            }
            overlayFlags={flags}
            statsSharesHeld={shareCount && <ShareValue value={shareCount?.toString() || '0'} />}
            shareLabel=""
            statsFooter={displayStatsFooter}
        />
    )
}

export const InstrumentsGrid: React.FunctionComponent<MoosePortfolioPathParams> = ({
    portfolioId,
}: MoosePortfolioPathParams) => {
    const profileUrl = useProfileUrl()
    const instruments = useDistillSearch()
    const navigate = useNavigate()
    const holdingDetail = useHoldingDetail(portfolioId)

    return (
        <div className={styles.fundList} data-testid="instruments-grid">
            {instruments.length > 0 &&
                instruments.map(instrument => {
                    const instrumentHoldingDetail = holdingDetail.instruments.find(x => x.symbol === instrument.symbol)
                    const shareCount = instrumentHoldingDetail?.holding ? instrumentHoldingDetail.holding : null
                    const flags: TileCardProps['overlayFlags'] = generateOverlayFlags({
                        ...determine_compliance_flag(
                            shareCount,
                            instrumentHoldingDetail?.minimum ? instrumentHoldingDetail.minimum : null,
                            instrumentHoldingDetail?.maximum ? instrumentHoldingDetail.maximum : null,
                        ),
                    })
                    return createInstrumentTileCard(navigate, portfolioId, instrument, flags, shareCount, profileUrl)
                })}
        </div>
    )
}
