import {isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {Instrument} from '~/store/instrument/types'
import {StagedSellOrder} from '~/store/order/types'

type SellOrderTypeLabel = {[key in StagedSellOrder['orderType']]: string}

export const getLabelBySellOrderType = (orderType: StagedSellOrder['orderType'], instrument: Instrument) => {
    const sellOrderTypeLabels: SellOrderTypeLabel = {
        share_market: `market sell in ${shareLabel({instrument, isPlural: true})}`,
        share_limit: `limit sell in ${shareLabel({
            instrument,
            isPlural: true,
            isWhole: isOnMainUsExchange(instrument),
        })}`,
        share_trigger: `stop loss in ${shareLabel({instrument, isPlural: true})}`,
    }

    return sellOrderTypeLabels[orderType]
}
