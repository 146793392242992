import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {Model} from '~/api/retail/types'
import {IDENTITY_VERIFICATION_MODE_KEY} from '~/global/constants/global'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {IdentityVerificationStep} from '~/global/utils/identity-verification/identityVerification'
import {useShowIntercom} from '~/global/utils/use-show-intercom/useShowIntercom'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {PageButtonGroup} from '~/global/widgets/button-page-group/PageButtonGroup'
import {Radio} from '~/global/widgets/form-controls'
import HelpEmail from '~/global/widgets/help-email/HelpEmail'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {Mode} from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/util'
import {connect} from '~/store/connect'
import styles from './IdentityVerificationIdSelector.scss'

const IdentityVerificationIdSelector: React.FunctionComponent<IdentityVerificationIdSelectorProps> = ({
    setStep,
    preferredName,
    jurisdiction,
    verifiRedirectUrl,
    identityVerification,
}) => {
    const showIntercom = useShowIntercom()
    const navigate = useNavigate()
    const [mode, setMode] = React.useState<Mode>()
    const [readyToVerify, setReadyToVerify] = React.useState(false)
    const [selectedOther, setSelectedOther] = React.useState(false)

    const registerModalClick = () => {
        Analytics.event({
            category: 'Verification at threshold',
            action: 'Help modal',
            label: `${jurisdiction === 'au' ? 'Australian' : 'New Zealand'} identity verification`,
        })
    }
    const primaryIdType = identityVerification.primary_id_type
    const secondaryIdVerified = identityVerification.secondary_id_verified
    const isIdentityLinked = identityVerification.is_identity_linked
    const latestBiometricVerificationCheck = identityVerification.latest_biometric_verification_check

    const verificationLocationAtSignUp = latestBiometricVerificationCheck?.location === 'SIGNUP'
    // user had verified Biometric before in Signup flow and only required to verify alternative ID details but not required to verify Biometric
    const isRequiredSecondaryIdDetailsButNotBiometricVerification =
        (!latestBiometricVerificationCheck && isIdentityLinked) || verificationLocationAtSignUp
    const handleIntercom = () => {
        Analytics.event({
            category: 'Verification at threshold',
            action: 'contact intercom',
            label: `${jurisdiction === 'au' ? 'Australian' : 'New Zealand'} identity verification`,
        })
        showIntercom()
    }

    const onSelectMode = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        // When the user selects a different mode, store this in local storage. We do this so that
        // when the user comes back from Verifi, we can select the same mode as they selected _before_
        // they started the identity verification flow
        const currentSelected = e.target.value
        setMode(currentSelected as Mode)

        // set into localStorage for next time
        window.localStorage.setItem(IDENTITY_VERIFICATION_MODE_KEY, currentSelected)

        if (currentSelected === 'other' || currentSelected === 'No') {
            setSelectedOther(true)
            setReadyToVerify(false)
        } else {
            setReadyToVerify(true)
        }
        Analytics.event({
            // send a GA event for each selection of the identity type radio buttons
            category: 'Verification at threshold',
            action: `${jurisdiction} identity verification id selection`,
            label: idTypeChoices.find(i => i.value === e.target.value)!.label,
        })
    }

    const modalContent = (): React.ReactNode => {
        return (
            <ModalLink
                dataTestId="modal-link--why-we-check-your-id"
                onClick={registerModalClick}
                label="Why we check your ID"
                asIcon
                modalTitle="Why we check your ID"
                bottomBorder
                primaryButton={{
                    label: 'Got it',
                }}
            >
                <p>
                    We’re a regulated financial services provider, which means we need to know who each of our investors
                    are. By trusting us with your ID info, you’re helping to keep Sharesies from being used for money
                    laundering or financial crime.
                </p>
                <p>
                    To check your ID info, we use{' '}
                    <a href="https://www.verifidentity.com/cloudcheck/" target="_blank" rel="noopener">
                        Cloudcheck
                    </a>
                    —an electronic identity verification tool from our trusted provider, Verifi. It captures the images
                    of your face and ID, then passes them to Sharesies for safe-keeping. The images are automatically
                    deleted from Verifi’s systems after 7 days. For more info, check out{' '}
                    <a href="https://www.verifidentity.com/legal/#data-retention" target="_blank" rel="noopener">
                        Verifi’s Data Retention Policy.
                    </a>
                </p>
                <p>
                    Sharesies securely stores your ID info solely for the purpose of verifying your identity. For more
                    info about security at Sharesies, check out our help centre.
                </p>
                <p>
                    If you have any questions, email <HelpEmail />.
                </p>
            </ModalLink>
        )
    }

    let nzIdChoices = [
        {type: 'driverslicence', value: 'licence', label: 'NZ driver licence'},
        {type: 'passport', value: 'passport', label: 'NZ passport'},
        {type: 'other', value: 'other', label: 'Other ID'},
    ]

    let auIdChoices = [
        {type: 'australian_drivers_licence', value: 'licence', label: 'Australian driver licence'},
        {type: 'australian_passport', value: 'passport', label: 'Australian passport'},
        {type: 'other', value: 'other', label: 'Other ID'},
    ]

    // if the user did not had Biometric in signup flow and any of the secondary ID is verified
    // eg: DOB, automatic address, Bank name match, etc
    // we need to remove the primaryId that used in signup.
    if (!secondaryIdVerified) {
        nzIdChoices = nzIdChoices.filter(id => id.type !== primaryIdType)
        auIdChoices = auIdChoices.filter(id => id.type !== primaryIdType)
    }

    const idChoicesForJurisdiction = jurisdiction === 'nz' ? nzIdChoices : auIdChoices

    // select the alternative ID type
    const alternativeId = idChoicesForJurisdiction.filter(id => id.type !== primaryIdType)[0]
    const alternativeIdChoices = [
        {value: alternativeId?.value || 'licence', label: 'Yes'},
        {value: 'No', label: 'No'},
    ]

    const alternativeIdLabel = alternativeId?.label

    // Select identity type by
    // 1. if the user is biometric verification successful in signup
    // then need to pick the alternative identity type
    // 2. If the user has not been verified their secondary ID
    // we would like to pick another ID type to verify
    // for example: if the user used a passport for signup, we would like to verify a
    // driver licence or medicare card instead
    const idTypeChoices = secondaryIdVerified ? idChoicesForJurisdiction : alternativeIdChoices

    const handleReadyForVerifi = () => {
        // if the user has already verified Biometric successfully in signup flow,
        // which means the user had IdentityLinked but need to verify their secondary ID,
        // so we just need to move to the next step which is the secondary id form confirmation page
        if (isIdentityLinked) {
            setStep('confirmIdentityDetails' as IdentityVerificationStep)
            return
        }

        if (verifiRedirectUrl) {
            window.location.href = verifiRedirectUrl
        } else {
            // if the user fail to try the biometric verification before and then fail
            // we should redirect to the the complete page which will display the message whether
            // the verifications has failed or successed.
            setStep('completed' as IdentityVerificationStep)
        }
    }

    const RadioLabel = () =>
        secondaryIdVerified ? (
            <>
                <h2 className={spacing.spaceAbove24}>Which ID do you have?</h2>
                <span className={styles.secondaryText}>These must be up-to-date</span>
            </>
        ) : (
            <h2 className={spacing.spaceAbove32}>Do you have an up-to-date {alternativeIdLabel}?</h2>
        )

    return (
        <>
            <Toolbar
                dataTestId="toolbar--identity-verification-selector"
                title="ID check "
                leftButton="close"
                onLeftButtonClick={() => navigate('/')}
            />
            <Page>
                <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow12)}>
                    {isRequiredSecondaryIdDetailsButNotBiometricVerification
                        ? 'Confirm second ID details'
                        : 'Get ready to verify your identity'}
                </h2>
                <p>
                    {isRequiredSecondaryIdDetailsButNotBiometricVerification ? (
                        <>
                            To verify that you’re ${preferredName}, we need you to check a second set of ID details.{' '}
                            {modalContent()}
                        </>
                    ) : (
                        <>
                            First you’ll take a quick selfie, and then a photo of your ID. {modalContent()} What kind do
                            you have?
                        </>
                    )}{' '}
                </p>

                <form className={spacing.spaceAbove16}>
                    <Radio
                        dataTestId="radio--id-verification-type"
                        name="idMethod"
                        label={RadioLabel}
                        isTouched
                        choices={idTypeChoices}
                        onChange={onSelectMode}
                        value={mode}
                    />

                    {selectedOther && (
                        <p>
                            If you have another form of ID we can still verify your identity. It’s just a little more
                            manual. <ButtonAsLink onClick={() => handleIntercom()}>Get in touch </ButtonAsLink>.
                        </p>
                    )}
                    <PageButtonGroup>
                        <Button
                            label="I'm ready"
                            disabled={!readyToVerify}
                            onClick={handleReadyForVerifi}
                            dataTestId="button--selected-id"
                        />
                    </PageButtonGroup>
                </form>
            </Page>
        </>
    )
}

interface StoreProps {
    preferredName: string
    jurisdiction: Model.User['jurisdiction']
    identityVerification: Model.User['checks']['identity_verification']
    verifiRedirectUrl?: string
}

interface OwnProps {
    className?: string
    setStep(step: IdentityVerificationStep): void
}

type IdentityVerificationIdSelectorProps = StoreProps & OwnProps

export default connect<StoreProps, {}, OwnProps>(({identity}) => ({
    preferredName: identity.user!.preferred_name,
    jurisdiction: identity.user!.jurisdiction,
    identityVerification: identity.user!.checks.identity_verification,
    verifiRedirectUrl: identity.user!.checks.identity_verification.latest_biometric_verification_check?.url,
}))(IdentityVerificationIdSelector)
