import {DateTime} from 'luxon'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {Period, periods, periodInformation} from '~/global/utils/time-period/timePeriod'
import Chips from '~/global/widgets/chips/Chips'
import InstrumentContributionChart from '~/sections/invest/sections/view-instrument/widgets/charts/InstrumentContributionChart'
import {InstrumentInvestingHistory} from '~/store/portfolio/types'
import styles from './InvestingHistory.scss'

interface InvestingHistoryProps {
    currentHistory: InstrumentInvestingHistory
    setPeriod: (period: Period) => void
    period: Period
}

const InvestingHistory: React.FunctionComponent<InvestingHistoryProps> = React.memo(
    ({currentHistory, setPeriod, period}) => {
        // Set the "All" duration to be from the investor's first investment return date for this instrument, or a month if we have no history yet.
        const earliestDate =
            currentHistory.history.length > 0
                ? DateTime.fromISO(currentHistory.history[0].date)
                : DateTime.local().minus({months: 1})
        const now = DateTime.local()
        periodInformation.All.duration = now.diff(earliestDate, ['year', 'months', 'days', 'hours'])
        const startDateString = DateTime.local().minus(periodInformation[period].duration).toISODate()

        return (
            <div className={spacing.spaceBelow40}>
                <div className={styles.periodControlContainer}>
                    <Chips
                        additionalAriaLabel="Show investing history over"
                        options={periods}
                        onChipClick={periodOptions => {
                            setPeriod(periodOptions)
                        }}
                        selected={[period]}
                        isInlineDisplay
                    />
                    <InstrumentContributionChart startDate={startDateString} currentHistory={currentHistory} />
                </div>
            </div>
        )
    },
)

export default InvestingHistory
