import {Button} from '@design-system/button'
import Icon from '@design-system/icon'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {SignUpStepCommonProps} from '~/sections/moose/sections/sign-up/SignUp'
import styles from './Intro.scss'

const Intro = ({progressStep, regressStep}: SignUpStepCommonProps) => {
    return (
        <>
            <Toolbar
                dataTestId="toolbar--fonterra-signup-intro"
                leftButton="back"
                onLeftButtonClick={regressStep}
                title="Activate your Sharesies account to access your Fonterra Co-op shares"
            />
            <Page className={styles.introList} overrideDefaultTopPadding="withToolbarTitle">
                <ul className={spacing.spaceAbove20}>
                    <li>
                        <Icon.Portfolio className={styles.listIcon} />
                        <span>
                            <span className={styles.textPrimary}>
                                <strong>No need to enter your details</strong>
                            </span>
                            <br />
                            Your details are pre-filled—you just need to confirm they’re correct.
                        </span>
                    </li>
                    <li>
                        <Icon.CheckSingle className={styles.listIcon} />
                        <span>
                            <span className={styles.textPrimary}>
                                <strong>Your phone is all you need</strong>
                            </span>
                            <br />
                            We’ll text you a code to enter. You won’t need to provide ID or sign any forms.
                        </span>
                    </li>
                    <li>
                        <Icon.Clock className={styles.listIcon} />
                        <span>
                            <span className={styles.textPrimary}>
                                <strong>You’ll be done in no time</strong>
                            </span>
                            <br />
                            It should only take about five minutes to activate your account.
                        </span>
                    </li>
                </ul>
                <Button
                    label="Next"
                    type="primary"
                    dataTestId="button--next"
                    onClick={progressStep}
                    pageButton
                    isSubmit
                />
            </Page>
        </>
    )
}

export default Intro
