import {useAtom} from 'jotai'
import React from 'react'
import {Navigate} from 'react-router'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {urlFor} from '~/global/routeGenerator'
import ConfirmName from '~/sections/moose/sections/sign-up/pages/confirm-name/ConfirmName'
import EntityDetails from '~/sections/moose/sections/sign-up/pages/entity-details/EntityDetails'
import Intro from '~/sections/moose/sections/sign-up/pages/intro/Intro'
import Landing from '~/sections/moose/sections/sign-up/pages/landing/Landing'
import NatureAndPurpose from '~/sections/moose/sections/sign-up/pages/nature-and-purpose/NatureAndPurpose'
import PrescribedPerson from '~/sections/moose/sections/sign-up/pages/prescribed-person/PrescribedPerson'
import TermsAndConditions from '~/sections/moose/sections/sign-up/pages/terms-and-conditions/TermsAndConditions'
import AccountLinking from '~/sections/moose/sections/sign-up/sections/account-linking/AccountLinking'
import MobileAuth from '~/sections/moose/sections/sign-up/sections/mobile-auth/MobileAuth'
import {stagedSignUpAtom} from '~/sections/moose/sections/sign-up/state'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'

type SignUpStep =
    | 'landing'
    | 'intro'
    | 'confirm-name'
    | 'mobile-auth'
    | 'entity-details'
    | 'prescribed-person'
    | 'nature-and-purpose'
    | 'terms-and-conditions'
    | 'account-linking'
    | 'complete'

const getNextStep = (step: SignUpStep): SignUpStep => {
    switch (step) {
        case 'landing':
            return 'intro'
        case 'intro':
            return 'confirm-name'
        case 'confirm-name':
            return 'mobile-auth'
        case 'mobile-auth':
            return 'entity-details'
        case 'entity-details':
            return 'prescribed-person'
        case 'prescribed-person':
            return 'nature-and-purpose'
        case 'nature-and-purpose':
            return 'terms-and-conditions'
        case 'terms-and-conditions':
            return 'account-linking'
        case 'account-linking':
            return 'complete'
        default:
            return 'landing'
    }
}

const getPreviousStep = (step: SignUpStep): SignUpStep => {
    switch (step) {
        case 'account-linking':
            return 'terms-and-conditions'
        case 'terms-and-conditions':
            return 'nature-and-purpose'
        case 'nature-and-purpose':
            return 'prescribed-person'
        case 'prescribed-person':
            return 'entity-details'
        case 'entity-details':
            return 'mobile-auth'
        case 'mobile-auth':
            return 'confirm-name'
        case 'confirm-name':
            return 'intro'
        case 'intro':
            return 'landing'
        default:
            return 'landing'
    }
}

export interface SignUpStepCommonProps {
    progressStep: () => void
    regressStep: () => void
    signupId: string
}

const MooseSignUp = ({signupId}: {signupId: string}) => {
    const dispatch = useAppDispatch()
    const actor = useAppSelector(s => s.identity.actor)

    // handle the case where we've reached the account-linking step but have
    // navigated away temporarily e.g. reached login but forgot password
    const [stagedSignUp] = useAtom(stagedSignUpAtom)
    const reachedAccountLinking =
        stagedSignUp.prescribedPerson?.length || (false && stagedSignUp.natureAndPurpose.length > 0)

    const [step, setStep] = React.useState<SignUpStep>(reachedAccountLinking ? 'account-linking' : 'landing')

    // handle already logged in
    React.useEffect(() => {
        if (actor && step === 'landing') {
            dispatch(identityActions.Logout())
        }
    }, [actor, step])

    const progressStep = () => setStep(getNextStep)

    const regressStep = () => setStep(getPreviousStep)

    const commonProps = {
        progressStep,
        regressStep,
        signupId,
    }

    switch (step) {
        case 'landing':
            return <Landing {...commonProps} />
        case 'intro':
            return <Intro {...commonProps} />
        case 'confirm-name':
            return <ConfirmName {...commonProps} />
        case 'mobile-auth':
            return <MobileAuth {...commonProps} />
        case 'entity-details':
            return <EntityDetails {...commonProps} />
        case 'prescribed-person':
            return <PrescribedPerson {...commonProps} />
        case 'nature-and-purpose':
            return <NatureAndPurpose {...commonProps} />
        case 'terms-and-conditions':
            return <TermsAndConditions {...commonProps} />
        case 'account-linking':
            return <AccountLinking {...commonProps} />
        case 'complete':
            return <Navigate to={urlFor('')} />
        default:
            return <WeSlippedUp />
    }
}

export default MooseSignUp
