import {Model} from '~/api/retail/types'
import {nzdIcon, usdIcon, audIcon} from '~/global/widgets/country-flag-icons/countryFlagIcons'

// Supported currencies, equivalent to Currency.supported_currencies() on b/e
export type Currency = Model.User['home_currency']

// Any currency, including unsupported currencies such as GBP
export type AnyCurrency = Exclude<Model.CorporateActionV2['outcome_records'][number]['currency'], undefined>

type CurrencyDetails = {
    [key in Currency]: {
        longName: string
        icon: JSX.Element
    }
}

type WalletTitleByCurrency = {[key in Currency]: string}

export const homeCurrencyList: Currency[] = ['nzd', 'usd', 'aud']

export const currencyDetails: CurrencyDetails = {
    nzd: {longName: 'New Zealand Dollars', icon: nzdIcon},
    usd: {longName: 'US Dollars', icon: usdIcon},
    aud: {longName: 'Australian Dollars', icon: audIcon},
}

export const nonAbbreviatedTitleByCurrency: WalletTitleByCurrency = {
    nzd: 'New Zealand dollars',
    aud: 'Australian dollars',
    usd: 'US dollars',
}

export const walletTitleByCurrency: WalletTitleByCurrency = {
    nzd: 'NZ Wallet',
    usd: 'US Wallet',
    aud: 'AU Wallet',
}

export const countryAbbreviationByCurrency: {[key: string]: string} = {
    nzd: 'NZ',
    usd: 'US',
    aud: 'AU',
}
