import {createAction, ActionsUnion} from '../redux-tools'

const actions = {
    OpenDrawer: () => createAction('nav.OpenDrawer'),
    CloseDrawer: () => createAction('nav.CloseDrawer'),
    ShowFlyoutMenu: () => createAction('nav.ShowFlyoutMenu'),
    HideFlyoutMenu: () => createAction('nav.HideFlyoutMenu'),
    LockFlyoutVisibility: () => createAction('nav.LockFlyoutVisibility'),
    HideToolbar: () => createAction('nav.HideToolbar'),
    ShowToolbar: () => createAction('nav.ShowToolbar'),
}

export type ActionsType = ActionsUnion<typeof actions>

export default {...actions}
