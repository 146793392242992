import React from 'react'
import {useNavigate} from 'react-router'
import {useCoveGet} from '~/api/query/cove'
import {useRetailGet} from '~/api/query/retail'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {PortfolioSlice} from '~/sections/landing/widgets/portfolio-slice/PortfolioSlice'
import insureSmall from '~/sections/protect/sections/insure/assets/images/insure-small.png'

/**
 * This component assumes that the user has insure access, and that this has been checked prior to render -
 * we're not handling the case where `/insure/get-account` returns a `insure_permission_denied` error.
 */
export const InsureSliceWrapper: React.FunctionComponent = () => {
    const {data: insureAccount} = useRetailGet({
        path: 'insure/get-account',
        options: {retry: false}, // No retry - we don't want to hold up the Landing page pageload
    })

    if (insureAccount.type !== 'insure_account') {
        return null
    }

    return <InsureSlice />
}

const InsureSlice: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const combinedCountResponse = useCoveGet('customer/v1/counts', {retry: false})
    const policyCount = combinedCountResponse.active_policy_count

    if (!policyCount || policyCount === 0) {
        return null
    }

    return (
        <PortfolioSlice
            dataTestId="button--landing-view-insure"
            image={insureSmall}
            imageBackgroundColour="Kiwi100"
            onClick={() => navigate(profileUrl('protect/insure'))}
        >
            <h3>Insurance</h3>
            <span>
                {policyCount} {policyCount === 1 ? 'policy' : 'policies'}
            </span>
        </PortfolioSlice>
    )
}
