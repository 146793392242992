import {TextInput, TextProps} from '@design-system/text-input'
import React from 'react'
import {withFocus, withId} from '../common'

function normalizeValue(value: string): string {
    return value.replace(/[^\d]/g, '').slice(0, 6)
}

const ResetCodeField = (props: TextProps) => (
    <TextInput
        {...props}
        onChange={e => {
            if (!props.setFieldValue) {
                return
            }
            props.setFieldValue(normalizeValue(e.currentTarget.value))
        }}
        inputProps={() => ({
            inputMode: 'numeric',
        })}
    />
)

const ResetCodeFieldWithFocus = withId(withFocus(ResetCodeField))

export default ResetCodeFieldWithFocus
