import {assertNever} from '~/global/utils/assert-never/assertNever'
import {ActionsType} from './actions'
import {State} from './types'

const initialState: State = {
    liveMarketData: undefined,
    liveDataSubscriptionDetails: {is_active: false, eligible_for_free_month: false},
    liveMarketDataLoadingState: 'loading',
    liveDataSubscriptionDetailsInitialised: false,
}

function reducer(state: State = initialState, action: ActionsType): State {
    switch (action.type) {
        case 'market.SetLiveDataSubscriptionDetails':
            return {
                ...state,
                liveDataSubscriptionDetails: action.payload,
                liveDataSubscriptionDetailsInitialised: true,
            }
        case 'market.SetLiveMarketData':
            return {
                ...state,
                liveMarketData: {
                    ...state.liveMarketData,
                    [action.payload.fundId]: action.payload.data,
                },
            }
        case 'market.SetLiveMarketDataLoadingState':
            return {
                ...state,
                liveMarketDataLoadingState: action.payload,
            }
        default:
            assertNever(action)
    }
    return state
}

export default reducer
