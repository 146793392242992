import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import humaniseList from '~/global/utils/humanise-list/humaniseList'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {COUNTRIES, extractCountryNameFromCode} from '~/global/widgets/form-controls/country-select/CountrySelectInput'
import Citizenship from '~/global/widgets/help-modals/Citizenship'
import TaxResidency from '~/global/widgets/help-modals/TaxResidency'
import styles from '~/sections/invest/sections/us-sign-up/USSignUp.scss'
import {State} from '~/store/sign-up/types'

const ReviewDetails: React.FunctionComponent<{
    numberedSections?: boolean
    usStatus: State['usStatus']
    isDependent: boolean
    jurisdiction: Model.User['jurisdiction']
    setEditingAddress: React.Dispatch<React.SetStateAction<boolean>>
    setEditingMailingAddress?: React.Dispatch<React.SetStateAction<boolean>>
    setEditingTaxResidency: React.Dispatch<React.SetStateAction<boolean>>
    setEditingCitizenship: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
    numberedSections,
    usStatus,
    isDependent,
    jurisdiction,
    setEditingAddress,
    setEditingMailingAddress,
    setEditingTaxResidency,
    setEditingCitizenship,
}) => {
    const allCitizenships = usStatus!.citizenship
        ? COUNTRIES.filter(country => usStatus!.citizenship.includes(country[0])).map(countryArray =>
              countryArray[0] === 'US' ? 'US citizen or Green Card holder' : countryArray[1],
          )
        : []

    const jurisdictionCode = jurisdiction!.toUpperCase()
    const jurisdictionName = extractCountryNameFromCode(jurisdictionCode)
    const foreignCitizenships = [...allCitizenships.filter(country => country !== jurisdictionName)]

    return (
        <>
            <h3>
                {numberedSections && <>1. </>}
                {isDependent ? 'Kid’s' : 'Your'} name
            </h3>
            <p>{usStatus!.name}</p>

            <div className={styles.title}>
                <h3>
                    {numberedSections && <>2. </>}Citizenship <Citizenship />
                </h3>

                <ButtonAsLink
                    onClick={() => {
                        setEditingCitizenship(true)
                    }}
                    dataTestId="anchor--edit-citizenship"
                >
                    Edit
                </ButtonAsLink>
            </div>
            <p>
                {
                    //Citizenship in jurisdiction is listed first if it exists
                    allCitizenships.includes(jurisdictionName)
                        ? humaniseList([jurisdictionName, ...foreignCitizenships])
                        : humaniseList(allCitizenships)
                }
            </p>
            <div className={styles.title}>
                <h3>{numberedSections && <>3. </>}Residential address </h3>
                <ButtonAsLink
                    onClick={() => {
                        setEditingAddress(true)
                    }}
                    dataTestId="anchor--edit-address"
                >
                    Edit
                </ButtonAsLink>
            </div>
            <p>{usStatus!.address.formatted}</p>
            {numberedSections && (
                <>
                    <div className={styles.title}>
                        <h3>4. Mailing address (optional)</h3>
                        {setEditingMailingAddress !== undefined && (
                            <ButtonAsLink
                                onClick={() => {
                                    setEditingMailingAddress(true)
                                }}
                                dataTestId="anchor--edit-mailing-address"
                            >
                                Edit
                            </ButtonAsLink>
                        )}
                    </div>
                    <p>
                        {usStatus!.us_tax_mailing_address?.formatted
                            ? usStatus!.us_tax_mailing_address?.formatted
                            : 'Not added'}
                    </p>
                </>
            )}

            <h3>{numberedSections && <>5. </>}Date of birth</h3>
            <p>{DateTime.fromISO(usStatus!.date_of_birth).toFormat('dd/MM/yyyy')}</p>

            <div className={styles.title}>
                <h3>
                    {numberedSections && <>6. </>}Tax residency <TaxResidency jurisdiction={jurisdiction} />
                </h3>
                <ButtonAsLink
                    onClick={() => {
                        setEditingTaxResidency(true)
                    }}
                    dataTestId="anchor--edit-tax-residency"
                >
                    Edit
                </ButtonAsLink>
            </div>
            <div className={cn({[spacing.spaceBelow24]: usStatus!.tax_residencies.length > 0})}>
                {jurisdictionName}
                {jurisdiction === 'nz' &&
                    (usStatus!.ird_number ? (
                        <p>IRD number {usStatus!.ird_number}</p>
                    ) : (
                        <p>You haven’t added your IRD number</p>
                    ))}

                {jurisdiction === 'au' &&
                    (usStatus!.tfn_number ? <p>TFN {usStatus!.tfn_number}</p> : <p>You haven’t added your TFN</p>)}
            </div>
            {usStatus!.tax_residencies.map(({country_name, tin}, index) => (
                <div
                    key={`residency-${index}`}
                    className={cn({[spacing.spaceBelow24]: index + 1 < usStatus!.tax_residencies.length})}
                >
                    <p>{country_name}</p>
                    {tin ? <p>'TIN' {tin}</p> : <p>You haven’t added your TIN (Tax Identification Number)</p>}
                </div>
            ))}
        </>
    )
}

export default ReviewDetails
