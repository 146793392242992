import {Button} from '@design-system/button'
import {CheckboxInput} from '@design-system/checkbox-input'
import cn from 'classnames'
import React, {useState} from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import spacing from '~/global/scss/helpers/spacing.scss'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ErrorBox} from '~/global/widgets/form-controls'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/invest/sections/bank-linking/BankLinking.scss'
import banklinkingSecurity from '~/sections/invest/sections/bank-linking/assets/images/banklinking-security.png'
import {useBankLinkingReferrer} from '~/sections/invest/sections/bank-linking/hooks/useBankLinkingRererrer'
import actions from '~/store/bankLinking/actions'
import {BasiqReferrer} from '~/store/bankLinking/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'

/**
 * This is under a feature flag and designs are not confirmed.
 * Bank linking is currently only seen by sharesies AU staff beta testing the feature
 */

function BankLinkingConsent() {
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const [loading, setLoading] = useState<boolean>(false)
    const [termsChecked, setTermsChecked] = useState<boolean>(false)
    const [error, setError] = useState('')
    const referrer = useBankLinkingReferrer()
    const {pathname} = useLocation()
    const isBankLinked = useAppSelector(({bankLinking}) => bankLinking.isBankLinked)

    if (isBankLinked && referrer !== 'linked-bank-topup') {
        return <Navigate to={profileUrl('explore/roundups')} replace />
    }

    const getReferrerForActions = (): BasiqReferrer | undefined => {
        if (pathname.includes('wallet/linked-bank-topup')) {
            return 'wallet-linked-bank-payments'
        } else if (pathname.includes('explore/linked-bank-topup')) {
            return 'explore-linked-bank-payments'
        }
        if (referrer === 'settings/bank-accounts-cards') {
            return 'account'
        }
    }

    const redirectToBasiqFlow = async () => {
        // Navigate to the basiq consent page
        rudderTrack('round-up', 'bank-link-clicked')
        setLoading(true)
        setError('')
        const consentUrl = await dispatch(
            actions.GetConsentURL({
                internalReferrer: getReferrerForActions(),
                action: 'request-new-consent',
            }),
        )
        if (consentUrl) {
            window.location.href = consentUrl
        } else {
            setLoading(false)
            setError(unknownErrorMessage)
        }
    }

    return (
        <>
            <Toolbar leftButton="back" dataTestId="toolbar--banklinking-consent" />
            <Page>
                <div className={styles.consentHero}>
                    <img src={banklinkingSecurity} role="presentation" alt="" />
                </div>
                <h1 className={cn(styles.h1, spacing.spaceBelow20)}>Securely link your bank</h1>
                <h2 className={cn(styles.h2, spacing.spaceBelow8)}>It just takes a minute</h2>
                <p className={cn(spacing.spaceBelow24)}>
                    This lets us use transaction data to fuel your investing goals, and send payments securely to your
                    Wallet.
                </p>
                <h2 className={cn(styles.h2, spacing.spaceBelow8)}>It’s secure</h2>
                <p className={spacing.spaceBelow16}>Your data is encrypted to the same standard as the major banks.</p>
                <p className={spacing.spaceBelow24}>
                    Only your chosen accounts are tracked—and your data won’t be sold to anyone.
                </p>
                <h2 className={cn(styles.h2, spacing.spaceBelow8)}>The fees are capped</h2>
                <p className={spacing.spaceBelow24}>
                    The standard 2% linked bank transfer fee applies—but is capped at $2 AUD.
                </p>
                <p className={spacing.spaceBelow24}>You can also unlink your bank at any time.</p>
                <CheckboxInput
                    dataTestId="checkbox--banklinking-terms-conditions"
                    id="banklinking-terms"
                    isTouched
                    value={termsChecked}
                    label={
                        <p>
                            I accept the Sharesies{' '}
                            <a
                                target="_blank"
                                rel="noopener"
                                href="https://intercom.help/sharesies-au/en/articles/7957233-bank-linking-terms-conditions"
                            >
                                bank linking and payments Terms and Conditions
                            </a>
                            .
                        </p>
                    }
                    name="terms and conditions confirm"
                    onChange={() => {
                        setTermsChecked(!termsChecked)
                    }}
                />
                <ErrorBox message={error} />
                <Button
                    label="Link with bank"
                    type="primary"
                    dataTestId="bank-linking--get-consent"
                    onClick={redirectToBasiqFlow}
                    disabled={!termsChecked}
                    processing={loading}
                />
            </Page>
        </>
    )
}

export default BankLinkingConsent
