import {Button} from '@design-system/button'
import cn from 'classnames'
import {useSetAtom} from 'jotai'
import React from 'react'
import {useNavigate} from 'react-router'
import {ExchangeDto} from '~/api/distill/models'
import {DistillScope} from '~/api/query/distill'
import {spacing} from '~/global/scss/helpers'
import useDistillInstrumentInfo from '~/global/state-hooks/distill/useDistillInstrumentInfo'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {Period} from '~/global/utils/time-period/timePeriod'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import PortfolioOrderRecentInvestingActivityCount from '~/global/widgets/instrument-activity/portfolio-order/PortfolioOrderRecentActivityCount'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import TabControls from '~/global/widgets/tab-controls/TabControls'
import {ExchangeHours} from '~/global/widgets/view-instrument-shared/exchange-hours/ExchangeHours'
import {InstrumentHeader} from '~/global/widgets/view-instrument-shared/instrument-header/InstrumentHeader'
import {useDistillInstrumentBySlug} from '~/sections/moose/data/distill'
import {useHoldingDetail, usePortfolioOrders} from '~/sections/moose/data/retail'
import {MoosePortfolioPathParams} from '~/sections/moose/routes'
import {MooseInstrumentOverviewTab} from '~/sections/moose/sections/instrument/widgets/instrument-overview-tab/MooseInstrumentOverviewTab'
import {MooseInstrumentYourInvestmentTab} from '~/sections/moose/sections/instrument/widgets/your-investment-tab/MooseInstrumentYourInvestmentTab'
import {buyOrderAtom, sellOrderAtom} from '~/sections/moose/sections/order/state'
import MooseWalletBalance from '~/sections/moose/widgets/moose-wallet-balance/MooseWalletBalance'
import {useAppDispatch} from '~/store/hooks'
import actions from '~/store/instrument/actions'
import {InstrumentDetailsTab} from '~/store/instrument/types'
import styles from './ViewMooseInstrument.scss'

interface ViewMooseInstrumentProps extends MoosePortfolioPathParams {
    activeTab: InstrumentDetailsTab
    urlSlug: string
}

export const ViewMooseInstrument: React.FunctionComponent<ViewMooseInstrumentProps> = ({
    activeTab,
    urlSlug,
    portfolioId,
}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const holdingDetail = useHoldingDetail(portfolioId)
    const instrument = useDistillInstrumentBySlug(urlSlug)
    const instrumentsMetadata = useDistillInstrumentInfo(DistillScope.FONTERRA)
    const exchangeInfo = instrumentsMetadata.exchanges.find(
        (exchange: ExchangeDto) => instrument && instrument.exchange === exchange.name,
    )

    const [period, setPeriod] = React.useState<Period>('1y')

    const changeTab = (tab: InstrumentDetailsTab) => {
        tab === 'Overview'
            ? navigate(profileUrl('fonterra/portfolios/:portfolioId/invest/:urlSlug', {portfolioId, urlSlug}))
            : navigate(
                  profileUrl('fonterra/portfolios/:portfolioId/invest/:urlSlug/investment', {portfolioId, urlSlug}),
              )
    }

    // TEMP initialise redux store for legacy InvestPanel
    const dispatch = useAppDispatch()
    React.useEffect(() => {
        dispatch(actions.IndexDetailInstrument(instrument))
    }, [instrument])

    const orders = usePortfolioOrders(portfolioId, instrument.id)
    const showRecentOrders: boolean = orders.orders.length > 0

    const setBuyOrder = useSetAtom(buyOrderAtom)
    const setSellOrder = useSetAtom(sellOrderAtom)

    return (
        <div>
            <Page>
                <PageBack />
                {tradingType(instrument) !== 'managed' && !isInstrumentInNoTrade(instrument) && (
                    <div className={styles.exchangeHours}>
                        <ExchangeHours exchange={exchangeInfo} instrumentTradingStatus={instrument.tradingStatus} />
                    </div>
                )}
                <div className={spacing.spaceAbove16}>
                    {showRecentOrders && (
                        <PortfolioOrderRecentInvestingActivityCount
                            recentOrders={orders}
                            url={profileUrl('fonterra/portfolios/:portfolioId/invest/recent/:urlSlug', {
                                portfolioId,
                                urlSlug,
                            })}
                        />
                    )}
                </div>
                <div className={cn(styles.headerAndNav)}>
                    <InstrumentHeader
                        instrument={instrument}
                        portfolioIdForPriceNotification={portfolioId}
                        showWatchlistButton={false}
                        distillScope={DistillScope.FONTERRA}
                    />
                </div>
                <TabControls
                    tabs={[
                        {label: 'Overview', value: 'Overview'},
                        {label: 'Your investment', value: 'Your investment'},
                    ]}
                    currentTab={activeTab}
                    onChangeTab={changeTab}
                />
                {activeTab === 'Overview' && (
                    <MooseInstrumentOverviewTab
                        instrument={instrument}
                        portfolioId={portfolioId}
                        exchange={exchangeInfo}
                        setHideFooter={undefined}
                        period={period}
                        setPeriod={setPeriod}
                        tab="Overview"
                    />
                )}
                {activeTab === 'Your investment' && (
                    <MooseInstrumentYourInvestmentTab
                        instrument={instrument}
                        portfolioId={portfolioId}
                        tab="Your investment"
                    />
                )}
            </Page>
            <ActionBar className={styles.footer}>
                <MooseWalletBalance />
                <Button
                    dataTestId="button--sell"
                    label="Sell"
                    type="secondary"
                    disabled={holdingDetail.is_inactive}
                    onClick={() => {
                        setSellOrder(undefined)
                        navigate(
                            profileUrl('fonterra/portfolios/:portfolioId/invest/:urlSlug/sell/limit-order', {
                                portfolioId,
                                urlSlug: instrument.urlSlug,
                            }),
                        )
                    }}
                />
                <Button
                    dataTestId="button--buy"
                    label="Buy"
                    disabled={holdingDetail.is_inactive}
                    onClick={() => {
                        setBuyOrder(undefined)
                        navigate(
                            profileUrl('fonterra/portfolios/:portfolioId/invest/:urlSlug/buy/limit-order', {
                                portfolioId,
                                urlSlug: instrument.urlSlug,
                            }),
                        )
                    }}
                />
            </ActionBar>
        </div>
    )
}
