import {ClassicCard} from '@braze/web-sdk'
import React from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {Navigate} from 'react-router-dom'
import {requestContentCardsRefresh, getCachedContentCards, logCardDismissal} from '~/api/braze/braze'
import {useHasInsureAccess} from '~/global/state-hooks/retail/useHasInsureAccess'
import {useInvalidateProfilesCache, useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AccountVerificationRequired from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import AnnouncementDetail from '~/global/widgets/announcement-card/AnnouncementDetail'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useHasInvestHistory} from '~/sections/landing/state/useHasInvestHistory'
import {GetStarted} from '~/sections/landing/widgets/get-started/GetStarted'
import {InsureSliceWrapper} from '~/sections/landing/widgets/insure-slice/InsureSlice'
import {LoadingSkeleton} from '~/sections/landing/widgets/loading-skeleton/LoadingSkeleton'
import {PortfolioSlices} from '~/sections/landing/widgets/portfolio-slice/PortfolioSlice'
import {TotalBalance, useTotalBalance} from '~/sections/landing/widgets/total-balance/TotalBalance'
import WelcomeFonterra from '~/sections/moose/sections/sign-up/pages/welcome-fonterra/WelcomeFonterra'
import IdentityVerificationRequired from '~/sections/user/widgets/identity-verification-required/AlertIdentityVerificationRequired'
import {possibleESS} from '~/store/employeeShareScheme/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import {GetStartedFonterra} from './widgets/get-started/GetStartedFonterra'
import styles from './Landing.scss'

export const Landing: React.FunctionComponent = () => {
    const profileUrl = useProfileUrl()
    const dispatch = useAppDispatch()
    const profile = useProfile()
    const invalidateProfilesCache = useInvalidateProfilesCache()
    const hasInvestHistory = useHasInvestHistory(profile.portfolios)

    const onlyHasInvestPortfolio =
        profile.portfolios.filter(p => p.product !== 'INVEST' && p.product !== 'WALLET').length === 0
    const hasFonterraProduct = profile.portfolios.some(p => p.product === 'FONTERRA')
    const allBalancesAreZero = useTotalBalance(profile).totalBalance.isZero()

    const hasEmployment = useAppSelector(s => possibleESS(s))
    const hasInsureAccess = useHasInsureAccess()
    const jurisdiction = useAppSelector(({identity}) => identity.user!.jurisdiction)
    const hasLockedNavDrawer = useAppSelector(s => s.nav.drawerVisibilityLocked) // For: Removing left button of toolbar when flyout menu locked by the native apps
    const announcementCard = useAppSelector(s => s.identity.notifications.announcementCard) as ClassicCard
    const additionalVerificationRequiredReason = useAppSelector(
        s => s.identity.user!.checks.identity_verification.additional_verification_required_reason,
    )
    const hasSeenFonterraOnboarding = useAppSelector(s => s.identity.user?.has_seen.fonterra_onboarding_screen)

    const refreshContentCards = () =>
        requestContentCardsRefresh(
            () => {
                const cards = getCachedContentCards()

                if (cards) {
                    dispatch(identityActions.SetNotifications(cards))
                }
            },
            () => dispatch(identityActions.SetNotificationsLoadFail()),
        )

    React.useEffect(() => {
        // on mount, make sure we get fresh profiles data - the stale time is
        // a few minutes so viewing this page directly should warrant a refresh
        // for the sake of making sure total balance is up to date
        invalidateProfilesCache()
    }, [])

    // ESS customers should be redirected to the Invest page which has a friendlier first time user exprerience when they don't have wider Sharesies exposure yet
    if (hasEmployment && (!hasInvestHistory || allBalancesAreZero) && onlyHasInvestPortfolio) {
        return <Navigate to={profileUrl('invest')} replace />
    }

    // Australian customers should be redirected to the Invest page as that's the only product they have access to
    if (jurisdiction === 'au') {
        return <Navigate to={profileUrl('invest')} replace />
    }

    if (hasFonterraProduct && !hasSeenFonterraOnboarding) {
        return <WelcomeFonterra />
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--landing"
                leftButton={hasLockedNavDrawer ? undefined : 'menu'}
                showNotifications
            />
            <Page overrideDefaultTopPadding="none" className={styles.landing}>
                {announcementCard && profile.legacy_profile_type && (
                    <AnnouncementDetail
                        isOpen={true}
                        onClose={() => {
                            logCardDismissal(announcementCard)
                            refreshContentCards()
                        }}
                        card={announcementCard}
                    />
                )}
                <TotalBalance />

                {/* Identity or account verification required alert */}
                <AccountVerificationRequired className={styles.addressVerification} />
                {additionalVerificationRequiredReason !== 'threshold_exceeded_restricted' && (
                    <IdentityVerificationRequired className={styles.identityVerification} />
                )}

                {profile.portfolios.map(portfolio => (
                    <PortfolioSlices key={portfolio.id} portfolio={portfolio} />
                ))}

                {/* Insure */}
                {hasInsureAccess && (
                    <ErrorBoundary
                        // graceful service degradation if Cove is down - hide slice
                        key={location.pathname}
                        FallbackComponent={() => null}
                    >
                        <React.Suspense fallback={<LoadingSkeleton />}>
                            <InsureSliceWrapper />
                        </React.Suspense>
                    </ErrorBoundary>
                )}

                {profile.legacy_profile_type && <GetStarted />}
                {hasFonterraProduct && !profile.legacy_profile_type && <GetStartedFonterra />}
            </Page>
        </>
    )
}

export default Landing
