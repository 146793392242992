import {
    AutoInvest,
    Cancel,
    Deposit,
    Gift,
    Love,
    OrderBuy,
    OrderSell,
    SpareChange,
    Success,
    Transfer,
    Withdraw,
} from '@design-system/icon'
import React from 'react'
import {Model} from '~/api/retail/types'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import TransactionRow from '~/global/widgets/transaction-row/TransactionRow'
import {useDistillInstrument} from '~/sections/wallet/state/distill'
import {useWalletPortfolio} from '~/sections/wallet/state/local'

const walletIconMapping = (txn: Model.TransactionV2) => {
    switch (txn.reason) {
        case 'gift redemption':
        case 'gift referral bonus':
        case 'corporate gift redemption':
            return <Gift />
        case 'holding funds for share purchase':
        case 'corporate action application holding':
            if (txn.detail?.type === 'buy' && txn.detail.autoinvest) {
                return <AutoInvest />
            }
            return <OrderBuy />
        case 'holding shares for share sale':
            return <OrderSell />
        case 'fund settlement':
            if (txn.detail?.type === 'sell') {
                return <OrderSell />
            }
            return <SpareChange />
        case 'account closure':
        case 'holding funds for withdrawal':
        case 'account fraud':
        case 'save deposit':
        case 'holding funds for US transfer in':
            return <Withdraw />
        case 'dividend payout':
        case 'corporate action cash distribution':
        case 'change for buy order':
        case 'subscription payment':
        case 'expired buy order':
        case 'fund order':
        case 'corporate credit':
        case 'refund for scaled fund settlement':
            return <SpareChange />
        case 'referral bonus':
        case 'referred bonus':
            return <Love />
        case 'tax year end':
            if (txn.amount[0] === '-') {
                return <Success />
            }
            return <SpareChange />
        case 'nzx sell trade':
        case 'drivewealth sell trade':
        case 'delist share sale':
            if (txn.detail?.type === 'sell') {
                return <OrderSell />
            }
            return <Success />
        case 'complete csn share transfer out':
            return <Transfer />
        case 'rejected sell order':
        case 'rejected buy order':
        case 'cancelling withdrawal':
        case 'cancelling buy order':
        case 'corporate action application cancellation':
            return <Cancel />
        case 'customer deposit':
        case 'manual reverse transfer fee':
        case 'save withdrawal':
            if (txn.detail?.type === 'payment_request') {
                return <SpareChange />
            }
            return <Deposit />
        case 'share premium payout':
        case 'capital decrease':
        case 'beta welcome gift':
        default:
            return <Success />
    }
}

interface TransactionLineProps {
    description?: string
    transaction: Model.TransactionV2
    ref: React.Ref<HTMLButtonElement>
}

const TransactionLine = React.forwardRef(
    ({description, transaction}: TransactionLineProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
        const profileUrl = useProfileUrl()
        const portfolio = useWalletPortfolio()
        const profile = useProfile()
        const instrumentId = transaction.fund_id
        const orderId =
            transaction.detail?.type === 'buy'
                ? transaction.detail.id
                : transaction.detail?.type === 'sell'
                  ? transaction.detail.id
                  : transaction.detail?.type === 'corporate_action'
                    ? transaction.detail.ca_id
                    : undefined
        // We change the error mode here so that in the case the instrument couldn't be found, we just
        // render a slightly more generic row
        const instrumentName = useDistillInstrument(instrumentId, {onError: 'undefined'})?.name

        const title = instrumentName || description || transaction.description
        const cardDescription = instrumentName ? description || transaction.description : null

        const isLimitBuyRefund = transaction.reason === 'change for buy order'
        let url: string

        if (profile.legacy_profile_type) {
            // TODO - these are legacy links that should be removed once the Shared Money wallet supports everything it
            // needs to
            if (instrumentId && orderId && !isLimitBuyRefund) {
                url = profileUrl('wallet/investing-activity/:instrumentId/:orderId', {instrumentId, orderId})
            } else {
                url = profileUrl('wallet/transaction/:transactionId/:lineNumber', {
                    transactionId: transaction.transaction_id,
                    lineNumber: transaction.line_number,
                })
            }
        } else {
            url = profileUrl('wallet/:portfolioId/:transactionKey', {
                portfolioId: portfolio.id,
                transactionKey: transaction.key,
            })
        }

        return (
            <TransactionRow
                amount={transaction.amount}
                currency={transaction.currency}
                date={transaction.timestamp}
                description={cardDescription}
                icon={walletIconMapping(transaction)}
                title={title}
                url={url}
                ref={ref}
            />
        )
    },
)

interface WalletTransactionProps {
    txn: Model.TransactionV2
}
export const WalletTransaction = React.forwardRef(
    ({txn}: WalletTransactionProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
        switch (txn.reason) {
            case 'fund order':
                if (txn.detail?.type === 'buy' && txn.detail.requested_currency) {
                    return (
                        <TransactionLine
                            ref={ref}
                            description={`Change from $${txn.detail.requested_currency} buy`}
                            transaction={txn}
                        />
                    )
                }
                return <TransactionLine ref={ref} transaction={txn} />
            case 'fund settlement':
                if (txn.detail?.type === 'sell') {
                    return <TransactionLine ref={ref} description="Sell" transaction={txn} />
                }
                if (txn.detail?.type === 'buy' && txn.detail.requested_currency) {
                    return (
                        <TransactionLine
                            ref={ref}
                            description={`Change from $${txn.detail.requested_currency} buy`}
                            transaction={txn}
                        />
                    )
                }
                return <TransactionLine ref={ref} description="Change from order of shares" transaction={txn} />
            case 'nzx sell trade':
            case 'drivewealth sell trade':
            case 'cmc sell trade':
                if (txn.detail?.type === 'sell') {
                    const description = txn.detail.price_limit ? 'Limit sell' : 'Market sell'
                    return <TransactionLine ref={ref} description={description} transaction={txn} />
                }
                return <TransactionLine ref={ref} transaction={txn} />
            default:
                return <TransactionLine ref={ref} transaction={txn} />
        }
    },
)
