import cn from 'classnames'
import React from 'react'
import {useWatchlist} from '~/global/state-hooks/retail/useWatchlist'
import {isWrapperApp} from '~/global/utils/is-wrapper-app/isWrapperApp'
import SearchInput from '~/global/widgets/form-controls/search/SearchInput'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import {useAppSelector, useAppDispatch} from '~/store/hooks'
import actions from '~/store/instrument/actions'
import Controls from '../controls/Controls'
import styles from './Header.scss'

// TODO - sort out how this Header and the Search results interact
// There is some 'jumpy' behaviour currently which is hard to debug
// with the way it is set out.

// isNestedInResultsRow
// this prop is to allow specific styling when the component
// is nested within the search results
interface HeaderProps {
    isAutoInvest?: boolean
    isOnTop?: boolean
    isNestedInResultsRow?: boolean
    isScrollingUp?: boolean
}

const Header: React.FunctionComponent<HeaderProps> = ({isAutoInvest, isOnTop, isNestedInResultsRow, isScrollingUp}) => {
    const dispatch = useAppDispatch()

    const autofocusSearch = useAppSelector(s => s.instrument.autofocusSearch)
    const currentQuery = useAppSelector(s => s.instrument.currentSearchQuery)
    const holdings = useAppSelector(s => s.identity.holdings)
    const resultsLoadingState = useAppSelector(s => s.instrument.resultsLoadingState)
    const {watchlistInstrumentIds} = useWatchlist()

    const needsMoreSpace = holdings.length > 0 || watchlistInstrumentIds.length > 0

    // Always force the autofocus to false on native wrapper apps
    const shouldAutoFocus = isWrapperApp() ? false : autofocusSearch

    // Explore A/B test to show extra content
    // e.g. http://localhost:9000/invest/search?type=Companies&content=Companies
    // e.g. http://localhost:9000/invest/search?type=ETFs&content=ETFs
    const initialContent = new URLSearchParams(window.location.search).get('content')
    const [showContent, setShowContentFor] = React.useState<string | null>(initialContent)

    // Hide the Companies/ETFs message as soon as the user goes to the filters page
    // as the message is quite large and we don't want it obscuring the results
    // We can't do it on filters change as there's a bunch of dispatches and re-renders that occur
    // which change the active filters
    React.useEffect(() => {
        return () => {
            return setShowContentFor(null)
        }
    }, [])

    return (
        <div
            className={cn(
                styles.header,
                {[styles.moreHeightHeader]: !isAutoInvest && needsMoreSpace},
                {[styles.isNested]: isNestedInResultsRow},
                {
                    [styles.autoinvestMoreHeightHeader]: isAutoInvest && !isScrollingUp && needsMoreSpace,
                },
                {
                    [styles.autoinvestLessHeightHeader]: isAutoInvest && !isScrollingUp && !needsMoreSpace,
                },
            )}
        >
            <div className={cn(styles.searchWrapper)}>
                <PageBack />

                {/* Don't show the Title and Subtitle on the 'dropdown' header for Autoinvest */}
                {isAutoInvest && (isOnTop || isNestedInResultsRow) && (
                    <>
                        <h1 className={styles.toolbarTitle}>Add investments to your order</h1>
                        <p className={styles.toolbarSubtitle}>
                            Choose from a range of US, Aussie and NZ companies and exchange-traded funds&nbsp;(ETFs).
                        </p>
                    </>
                )}

                <div
                    className={cn(
                        {[styles.searchInputWrapper]: !isAutoInvest},
                        {[styles.autoinvestSearchInputWrapper]: isAutoInvest},
                    )}
                >
                    <div className={cn({[styles.autoinvestSearchInput]: isAutoInvest && !isOnTop})}>
                        <SearchInput
                            name="search-term"
                            isLoading={resultsLoadingState === 'loading'}
                            isTouched
                            autoFocus={shouldAutoFocus}
                            onQueryChange={query => dispatch(actions.executeQuery(query))}
                            setCurrentSearchInput={input => dispatch(actions.SetCurrentSearchInput(input))}
                            initialValue={currentQuery}
                        />
                    </div>
                </div>
            </div>

            <Controls />

            {/* Temporary - content for Explore content card A/B tests */}
            {showContent === 'ETFs' && (
                <>
                    <h2 className={styles.contentTitle}>Funds</h2>
                    <p>
                        Buying shares or units in a fund spreads your money across multiple investments, instead of just
                        one company. People might invest in funds to diversify their portfolio or access different
                        investments within a particular theme, industry, or geography.
                    </p>
                </>
            )}
            {showContent === 'Companies' && (
                <>
                    <h2 className={styles.contentTitle}>Companies</h2>
                    <p>
                        Buying shares in a company makes you part-owner (a ‘shareholder’) of that company. People might
                        invest in companies when there’s something specific they want to invest in, or if they can
                        tolerate a higher level of risk.
                    </p>
                </>
            )}
        </div>
    )
}

export default Header
