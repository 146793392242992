import {Button} from '@design-system/button'
import cn from 'classnames'
import React, {useTransition} from 'react'
import {useNavigate} from 'react-router'
import {InstrumentTypeDto} from '~/api/distill'
import {DistillScope} from '~/api/query/distill'
import {accessibility, spacing} from '~/global/scss/helpers'
import useDistillInstrumentInfo from '~/global/state-hooks/distill/useDistillInstrumentInfo'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Chips from '~/global/widgets/chips/Chips'
import CompoundSlider from '~/global/widgets/compound-slider/CompoundSlider'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import {
    DistillSearchFilter,
    searchFilterToSearchString,
    useDistillCount,
    useSearchFilterFromURL,
} from '~/sections/kiwisaver/data/distill'
import {usePlanEditorUrl} from '~/sections/kiwisaver/sections/edit-investment-plan/hooks/usePlanEditorUrl'
import styles from './Filter.scss'

type DistillSearchFilterListFields = {
    [K in keyof DistillSearchFilter]: DistillSearchFilter[K] extends any[] ? K : never
}[keyof DistillSearchFilter]

const isAllowedInstrumentType = (allowedType: InstrumentTypeDto): boolean => {
    return allowedType.name === 'ETFs' || allowedType.name === 'Managed funds' || allowedType.name === 'Companies'
}

const toggleFactory = <T extends DistillSearchFilterListFields>(
    field: T,
    setter: React.Dispatch<React.SetStateAction<DistillSearchFilter>>,
    value: DistillSearchFilter[T][number],
) => {
    if (value === 'all') {
        setter(filter => ({...filter, [field]: []}))
    } else {
        setter(filter => ({
            ...filter,
            [field]: filter[field].includes(value) ? filter[field].filter(v => v !== value) : [...filter[field], value],
        }))
    }
}

export const Filter: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const distillMetadata = useDistillInstrumentInfo(DistillScope.KIWISAVER_ALL_FUNDS, {searchFundInvestments: true})
    const relativeUrlFor = usePlanEditorUrl()
    const typeIdForName: Record<string, string> = {all: 'all'}
    const typeNameForId: Record<string, string> = {all: 'all'}
    for (const {id, name} of distillMetadata.instrumentTypes) {
        typeIdForName[name] = id
        typeNameForId[id] = name
    }

    const [isLoading, startTransition] = useTransition()

    const [filter, setFilter] = useSearchFilterFromURL(DistillScope.KIWISAVER_SELF_SELECT_FUNDS)

    const onInstrumentTypeClick = React.useCallback(
        (value: string) => {
            startTransition(() => toggleFactory('instrumentTypes', setFilter, typeIdForName[value]))
        },
        [filter],
    )
    const onCategoryClick = React.useCallback(
        (value: string) => startTransition(() => toggleFactory('categories', setFilter, value)),
        [filter],
    )

    const onRiskUpdate = React.useCallback(
        ([minRisk, maxRisk]: [number, number]) => startTransition(() => setFilter(f => ({...f, minRisk, maxRisk}))),
        [filter],
    )

    const count = useDistillCount({...filter, searchFundInvestments: true})

    return (
        <>
            <Page className={styles.filterPage}>
                <div className={styles.header}>
                    <PageBack
                        onClick={() => {
                            navigate(-1)
                        }}
                    />

                    <div className={styles.headerTitle}>
                        <h1>Filter</h1>
                        <button
                            className={cn(accessibility.button, styles.clearButton)}
                            type="button"
                            onClick={() => {
                                toggleFactory('instrumentTypes', setFilter, 'all')
                                toggleFactory('categories', setFilter, 'all')
                                toggleFactory('exchanges', setFilter, 'all')
                                onRiskUpdate([1, 7])
                            }}
                            aria-label="Clear all filters"
                        >
                            Clear
                        </button>
                    </div>
                </div>

                <h2 className={styles.inlineChipsHeader}>Types</h2>
                <Chips
                    options={distillMetadata.instrumentTypes
                        .filter(instrumentType => isAllowedInstrumentType(instrumentType))
                        .map(t => t.name)}
                    hasAllOption
                    isInlineDisplay
                    selected={filter.instrumentTypes.map(n => typeNameForId[n])}
                    onChipClick={onInstrumentTypeClick}
                />

                <h2 className={cn(styles.categoriesTitle, spacing.spaceAbove32)}>Categories</h2>
                <Chips
                    options={distillMetadata.categories}
                    hasAllOption
                    isInlineDisplay
                    selected={filter.categories}
                    onChipClick={onCategoryClick}
                />

                <h2 className={cn(spacing.spaceBelow12, spacing.spaceAbove32)}>Risk levels</h2>

                <CompoundSlider
                    handleRiskRangeUpdate={onRiskUpdate}
                    currentRiskRange={[filter.minRisk, filter.maxRisk]}
                    hideTitle
                />
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--search"
                    processing={isLoading}
                    label={`Show ${count} investments`}
                    onClick={() =>
                        navigate({
                            pathname: relativeUrlFor('add-funds'),
                            search: searchFilterToSearchString(filter),
                        })
                    }
                />
            </ActionBar>
        </>
    )
}
