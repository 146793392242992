import cn from 'classnames'
import React from 'react'
import {Link} from 'react-router-dom'
import {DistillScope} from '~/api/query/distill'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {useDistillInstrument} from '~/sections/kiwisaver/data/distill'
import {InvestmentPlan} from '~/sections/kiwisaver/state'
import {StackedImage} from '~/sections/kiwisaver/widgets/stacked-image/StackedImage'
import styles from './InvestmentPlanSummaryCard.scss'

export const InvestmentPlanSummaryCard: React.FunctionComponent<{
    plan: InvestmentPlan
    editUrl: string
    className?: string
}> = ({plan, editUrl, className}) => {
    const baseAllocations = plan.allocations.filter(a => a.fund_category === 'BASE')
    const baseCount = baseAllocations.length
    const selfSelectCount = plan.allocations.filter(a => a.fund_category === 'SELF_SELECT').length

    const instrument = useDistillInstrument({
        instrumentId: baseAllocations[0].fund_id,
        scope: DistillScope.KIWISAVER_ALL_FUNDS,
    })
    const underlyingInstrument = useDistillInstrument({
        instrumentId: instrument.underlyingInstrumentId!,
        scope: DistillScope.KIWISAVER_ALL_FUNDS,
        isUnderlyingInstrument: true,
    })

    return (
        <div className={cn(className, styles.card)}>
            <StackedImage instrument={underlyingInstrument} />
            <div>
                <h3 data-testid="title--your-investment-plan">Your investment plan</h3>
                <p data-testid="text--selected-plan">
                    You've selected {baseCount} base fund{baseCount === 1 ? '' : 's'}
                    {selfSelectCount ? <> and {selfSelectCount} of your own picks</> : ''}.
                </p>
            </div>
            <Link
                to={editUrl}
                data-testid="link--edit-your-plan"
                onClick={() => rudderTrack('kiwisaver_account', 'edit_plan_clicked')}
            >
                Edit
            </Link>
        </div>
    )
}
