/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    AmountPutInDetail,
    AmountPutInDetailFromJSON,
    AmountPutInDetailFromJSONTyped,
    AmountPutInDetailToJSON,
    AmountPutInDetailToJSONTyped,
    SharesDetail,
    SharesDetailFromJSON,
    SharesDetailFromJSONTyped,
    SharesDetailToJSON,
    SharesDetailToJSONTyped,
    TotalReturnDetail,
    TotalReturnDetailFromJSON,
    TotalReturnDetailFromJSONTyped,
    TotalReturnDetailToJSON,
    TotalReturnDetailToJSONTyped,
    UnvestedDetail,
    UnvestedDetailFromJSON,
    UnvestedDetailFromJSONTyped,
    UnvestedDetailToJSON,
    UnvestedDetailToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface AppSchemasForClientPortfolioLatestInstrumentReturn
 */
export interface AppSchemasForClientPortfolioLatestInstrumentReturn {
    /**
     *
     * @type {string}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    instrument_uuid: string
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    shares_owned: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    investment_value: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    dividends_received: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    simple_return: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    total_return: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    cost_basis: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    cost_basis_max: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    total_cost_basis: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    transaction_fees: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    tax_paid: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    managed_fund_transaction_fees: number
    /**
     *
     * @type {SharesDetail}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    shares_detail: SharesDetail
    /**
     *
     * @type {TotalReturnDetail}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    total_return_detail: TotalReturnDetail
    /**
     *
     * @type {AmountPutInDetail}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    amount_put_in_detail: AmountPutInDetail
    /**
     *
     * @type {UnvestedDetail}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    unvested_detail: UnvestedDetail
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    unrealised_tax_paid: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    unrealised_dividends: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    unrealised_simple_return: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfolioLatestInstrumentReturn
     */
    unrealised_total_return: number
}

export function AppSchemasForClientPortfolioLatestInstrumentReturnFromJSON(
    json: any,
): AppSchemasForClientPortfolioLatestInstrumentReturn {
    return AppSchemasForClientPortfolioLatestInstrumentReturnFromJSONTyped(json, false)
}

export function AppSchemasForClientPortfolioLatestInstrumentReturnFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AppSchemasForClientPortfolioLatestInstrumentReturn {
    if (json === undefined || json === null) {
        return json
    }
    return {
        instrument_uuid: json['instrument_uuid'],
        shares_owned: json['shares_owned'],
        investment_value: json['investment_value'],
        dividends_received: json['dividends_received'],
        simple_return: json['simple_return'],
        total_return: json['total_return'],
        cost_basis: json['cost_basis'],
        cost_basis_max: json['cost_basis_max'],
        total_cost_basis: json['total_cost_basis'],
        transaction_fees: json['transaction_fees'],
        tax_paid: json['tax_paid'],
        managed_fund_transaction_fees: json['managed_fund_transaction_fees'],
        shares_detail: SharesDetailFromJSON(json['shares_detail']),
        total_return_detail: TotalReturnDetailFromJSON(json['total_return_detail']),
        amount_put_in_detail: AmountPutInDetailFromJSON(json['amount_put_in_detail']),
        unvested_detail: UnvestedDetailFromJSON(json['unvested_detail']),
        unrealised_tax_paid: json['unrealised_tax_paid'],
        unrealised_dividends: json['unrealised_dividends'],
        unrealised_simple_return: json['unrealised_simple_return'],
        unrealised_total_return: json['unrealised_total_return'],
    }
}

export function AppSchemasForClientPortfolioLatestInstrumentReturnToJSON(
    value?: AppSchemasForClientPortfolioLatestInstrumentReturn | null,
): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        instrument_uuid: value.instrument_uuid,
        shares_owned: value.shares_owned,
        investment_value: value.investment_value,
        dividends_received: value.dividends_received,
        simple_return: value.simple_return,
        total_return: value.total_return,
        cost_basis: value.cost_basis,
        cost_basis_max: value.cost_basis_max,
        total_cost_basis: value.total_cost_basis,
        transaction_fees: value.transaction_fees,
        tax_paid: value.tax_paid,
        managed_fund_transaction_fees: value.managed_fund_transaction_fees,
        shares_detail: SharesDetailToJSON(value.shares_detail),
        total_return_detail: TotalReturnDetailToJSON(value.total_return_detail),
        amount_put_in_detail: AmountPutInDetailToJSON(value.amount_put_in_detail),
        unvested_detail: UnvestedDetailToJSON(value.unvested_detail),
        unrealised_tax_paid: value.unrealised_tax_paid,
        unrealised_dividends: value.unrealised_dividends,
        unrealised_simple_return: value.unrealised_simple_return,
        unrealised_total_return: value.unrealised_total_return,
    }
}
