/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    CurrentPriceResponseDto,
    CurrentPriceResponseDtoFromJSON,
    CurrentPriceResponseDtoFromJSONTyped,
    CurrentPriceResponseDtoToJSON,
    CurrentPriceResponseDtoToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface CurrentPricesResponseDto
 */
export interface CurrentPricesResponseDto {
    /**
     *
     * @type {Array<CurrentPriceResponseDto>}
     * @memberof CurrentPricesResponseDto
     */
    currentPrices: Array<CurrentPriceResponseDto>
}

export function CurrentPricesResponseDtoFromJSON(json: any): CurrentPricesResponseDto {
    return CurrentPricesResponseDtoFromJSONTyped(json, false)
}

export function CurrentPricesResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): CurrentPricesResponseDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        currentPrices: (json['currentPrices'] as Array<any>).map(CurrentPriceResponseDtoFromJSON),
    }
}

export function CurrentPricesResponseDtoToJSON(value?: CurrentPricesResponseDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        currentPrices: (value.currentPrices as Array<any>).map(CurrentPriceResponseDtoToJSON),
    }
}
