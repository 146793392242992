import {isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {Instrument} from '~/store/instrument/types'
import {StagedBuyOrder} from '~/store/order/types'

type BuyOrderTypeLabel = {[key in StagedBuyOrder['orderType']]: string}

export const getLabelByBuyOrderType = (orderType: StagedBuyOrder['orderType'], instrument: Instrument) => {
    const buyOrderTypeLabels: BuyOrderTypeLabel = {
        dollar_market: 'market buy in dollars',
        dollar_limit: 'limit buy in dollars',
        dollar_trigger: `trigger buy in dollars`,
        share_limit: `limit buy in ${shareLabel({
            instrument,
            isPlural: true,
            isWhole: isOnMainUsExchange(instrument),
        })}`,
    }

    return buyOrderTypeLabels[orderType]
}
