import {ChevronDown, ChevronUp} from '@design-system/icon'
import {Thumbnail} from '@design-system/thumbnail'
import cn from 'classnames'
import Decimal from 'decimal.js'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {DistillScope} from '~/api/query/distill'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import useDistillInstrumentInfo from '~/global/state-hooks/distill/useDistillInstrumentInfo'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {PercentValue} from '~/global/widgets/number-elements/NumberElements'
import {useDistillInstrument} from '~/sections/kiwisaver/data/distill'
import {usePlanEditorUrl} from '~/sections/kiwisaver/sections/edit-investment-plan/hooks/usePlanEditorUrl'
import {Allocation} from '~/sections/kiwisaver/state'
import {StackedImage} from '~/sections/kiwisaver/widgets/stacked-image/StackedImage'
import styles from './InvestmentPlanAllocationCard.scss'

interface InvestmentPlanBaseAllocationCardProps {
    allocation: Allocation
    className?: string
}

export const InvestmentPlanBaseAllocationCard: React.FunctionComponent<InvestmentPlanBaseAllocationCardProps> = ({
    allocation,
    className,
}) => {
    const navigate = useNavigate()
    const instrument = useDistillInstrument({
        instrumentId: allocation.fund_id,
        scope: DistillScope.KIWISAVER_ALL_FUNDS,
    })
    const underlyingInstrument = useDistillInstrument({
        instrumentId: instrument.underlyingInstrumentId!,
        scope: DistillScope.KIWISAVER_ALL_FUNDS,
        isUnderlyingInstrument: true,
    })
    const resourcePath = useDistillInstrumentInfo(DistillScope.KIWISAVER_ALL_FUNDS, {
        searchFundInvestments: true,
    }).filesHostAddress
    const relativeUrlFor = usePlanEditorUrl()

    return (
        <div className={cn(className, styles.card)} data-testid="investment-plan-card--base">
            <div onClick={() => navigate(relativeUrlFor('base'))} className={styles.cardBody}>
                <div>
                    <Thumbnail
                        dataTestId={`${underlyingInstrument.urlSlug}--logo`}
                        width="50px"
                        height="50px"
                        symbol={underlyingInstrument.symbol}
                        path={`${resourcePath}${underlyingInstrument.logos.micro}`}
                    />
                </div>
                <div>
                    <PercentValue
                        noDp={allocation.allocation_percent === parseInt(allocation.allocation_percent, 10).toString()}
                        value={allocation.allocation_percent}
                    />{' '}
                    will go into the <h2>{underlyingInstrument.name}</h2>
                </div>
                <div className={styles.cardRightButton}>
                    <ButtonAsLink
                        dataTestId="button--edit-base-plan"
                        onClick={() => rudderTrack('kiwisaver_account', 'edit_plan_clicked')}
                    >
                        Edit
                    </ButtonAsLink>
                </div>
            </div>
        </div>
    )
}

interface InvestmentPlanSelfSelectAllocationsCardProps {
    allocations: Allocation[]
    className?: string
}

export const PickRow: React.FunctionComponent<{allocation: Allocation; scope: DistillScope}> = ({
    allocation,
    scope,
}) => {
    const instrument = useDistillInstrument({
        instrumentId: allocation.fund_id,
        scope,
    })
    return (
        <div className={styles.picksRow}>
            <div>{instrument.name}</div>
            <PercentValue
                noDp={allocation.allocation_percent === parseInt(allocation.allocation_percent, 10).toString()}
                value={allocation.allocation_percent}
            />
        </div>
    )
}

export const InvestmentPlanSelfSelectAllocationsCard: React.FunctionComponent<
    InvestmentPlanSelfSelectAllocationsCardProps
> = ({allocations, className}) => {
    const [expanded, setExpanded] = React.useState(false)
    const toggleExpanded = React.useCallback(() => setExpanded(e => !e), [])
    const navigate = useNavigate()
    const instrument = useDistillInstrument({
        instrumentId: allocations[0].fund_id,
        scope: DistillScope.KIWISAVER_SELF_SELECT_FUNDS,
    })
    const totalPercent = allocations
        .reduce((t, a) => t.add(new Decimal(a.allocation_percent)), new Decimal(0))
        .toString()
    const relativeUrlFor = usePlanEditorUrl()

    const handleRudderTrack = () => {
        rudderTrack('kiwisaver_account', 'edit_link_clicked', {fund_category: 'self_select_fund'})
    }

    return (
        <div className={cn(className, styles.card)} data-testid="investment-plan-card--self-select">
            <div onClick={() => navigate(relativeUrlFor('edit-allocations'))} className={styles.cardBody}>
                <StackedImage instrument={instrument} />
                <div>
                    <PercentValue noDp={totalPercent === parseInt(totalPercent, 10).toString()} value={totalPercent} />{' '}
                    will go into
                    <h2>
                        {allocations.length} other investment{allocations.length === 1 ? '' : 's'}
                    </h2>
                </div>
                <div className={styles.cardRightButton}>
                    <ButtonAsLink dataTestId="button--edit-self-select" onClick={() => handleRudderTrack()}>
                        Edit
                    </ButtonAsLink>
                </div>
            </div>
            <div className={styles.bottomExpansion}>
                <button
                    type="button"
                    className={cn(styles.yourPicks, styles.clickable)}
                    onClick={toggleExpanded}
                    aria-controls="other-investments-list"
                    aria-expanded={expanded}
                >
                    <span>Your picks</span>
                    {expanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                </button>
                <div className={cn({[styles.picksList]: expanded})} id="other-investments-list">
                    {expanded && (
                        <React.Suspense fallback="Loading...">
                            {allocations.map((a, index) => (
                                <PickRow key={index} allocation={a} scope={DistillScope.KIWISAVER_SELF_SELECT_FUNDS} />
                            ))}
                        </React.Suspense>
                    )}
                </div>
            </div>
        </div>
    )
}
