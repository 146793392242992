import {Button} from '@design-system/button'
import {LongTextInput} from '@design-system/long-text-input'
import cn from 'classnames'
import {Formik} from 'formik'
import React from 'react'
import {useNavigate} from 'react-router'
import * as api from '~/api/retail'
import {Request} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {getDeviceInformation} from '~/global/utils/get-device-information/getDeviceInformation'
import {getWrapperAppType} from '~/global/utils/is-wrapper-app/isWrapperApp'
import {useShowIntercom} from '~/global/utils/use-show-intercom/useShowIntercom'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {ErrorBox, Radio, validate} from '~/global/widgets/form-controls'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from './RequestFeature.scss'

interface FormValues {
    overallExperience?: 'meh' | 'okay' | 'great'
    fulfillsNeeds?: 'yes' | 'no'
    comment?: string
}

const feedbackOverallChoices = [
    {
        value: 'meh',
        emoji: '😞',
        label: 'Meh',
    },
    {
        value: 'okay',
        emoji: '😐',
        label: 'Okay',
    },
    {
        value: 'great',
        emoji: '😄',
        label: 'Great',
    },
]

const fulfillsNeedsChoices = [
    {
        value: 'yes',
        emoji: '👍',
        label: 'Yeah',
    },
    {
        value: 'no',
        emoji: '👎',
        label: 'Nah',
    },
]

const RequestFeature = () => {
    const showIntercom = useShowIntercom()
    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState('')

    return (
        <>
            <Toolbar
                dataTestId="toolbar--request-feature"
                leftButton="back"
                hideIntercom
                title={success ? undefined : 'Request a feature'}
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                {success ? (
                    <FeedbackSuccess />
                ) : (
                    <Formik
                        initialValues={
                            {
                                overallExperience: undefined,
                                fulfillsNeeds: undefined,
                                comment: undefined,
                            } as FormValues
                        }
                        validate={validate.generate<FormValues>({
                            overallExperience: [
                                validate.required('Select what your experience with Sharesies is like'),
                            ],
                            fulfillsNeeds: [
                                validate.required('Select yeah if Sharesies has all the features you need'),
                            ],
                            comment: [validate.required('Enter your feedback')],
                        })}
                        onSubmit={async (values, {setSubmitting}) => {
                            setSubmitting(true)

                            const featureRequestPayload: Request.FeatureRequest = {
                                overall_experience: values.overallExperience!,
                                fulfills_needs: values.fulfillsNeeds!,
                                category: 'Request a feature',
                                comment: values.comment,
                                platform: getWrapperAppType(),
                                device: getDeviceInformation(),
                            }

                            try {
                                await api.post('identity/feature-request', featureRequestPayload)
                                setSubmitting(false)
                                setSuccess(true)
                            } catch (e) {
                                setSubmitting(false)
                                setError(unknownErrorMessage)
                            }
                        }}
                    >
                        {({values, setFieldValue, isValid, isSubmitting, touched, dirty, handleSubmit, errors}) => {
                            const commonFieldProps = (fieldName: keyof typeof values) => {
                                return {
                                    onChange: (
                                        event: React.ChangeEvent<
                                            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
                                        >,
                                    ) => setFieldValue(fieldName, event.target.value),
                                    error: (touched[fieldName] && errors[fieldName]) || undefined,
                                    value: values[fieldName],
                                    isTouched: !!touched[fieldName],
                                    name: `feedback--${fieldName}`,
                                    dataTestId: `feedback--${fieldName}`,
                                }
                            }

                            return (
                                <form onSubmit={handleSubmit}>
                                    <p className={spacing.spaceBelow20}>
                                        We'd love to hear what you think about Sharesies! While we may not get back to
                                        you, feel free to request a feature or just tell us what’s on your mind.
                                    </p>
                                    <p className={spacing.spaceBelow40}>
                                        If you’d like to ask our Investor Care team a question, make a complaint (or get
                                        a reply to your feedback),{' '}
                                        <ButtonAsLink onClick={showIntercom}>flick us a message</ButtonAsLink>.
                                    </p>
                                    <Radio
                                        {...commonFieldProps('overallExperience')}
                                        label="What’s your experience with Sharesies like?"
                                        additionalClassName={styles.horizontalRadio}
                                        choices={feedbackOverallChoices.map(({label, emoji, value}) => ({
                                            value,
                                            label: (
                                                <div className={styles.radioLabel}>
                                                    <div className={styles.emoji}>{emoji}</div>
                                                    {label}
                                                </div>
                                            ),
                                        }))}
                                    />
                                    <Radio
                                        {...commonFieldProps('fulfillsNeeds')}
                                        label="Has Sharesies got all the features you need?"
                                        additionalClassName={styles.horizontalRadio}
                                        choices={fulfillsNeedsChoices.map(({label, emoji, value}) => ({
                                            value,
                                            label: (
                                                <div tabIndex={0} className={styles.radioLabel}>
                                                    <div className={styles.emoji}>{emoji}</div>
                                                    {label}
                                                </div>
                                            ),
                                        }))}
                                    />
                                    <LongTextInput
                                        {...commonFieldProps('comment')}
                                        label="What feature would you like to request?"
                                        maxLength={3000}
                                        maxLengthAlertThreshold={200}
                                        id="feedback--more-information"
                                        placeholder="We’d love to hear how we can make Sharesies better for you. But make sure you don’t share any sensitive info—like your password—with us!"
                                        isTouched={!!touched.comment}
                                        isFocused={false}
                                        onFocus={() => undefined}
                                        onBlur={() => undefined}
                                    />
                                    <ErrorBox message={error} />
                                    <Button
                                        label="Share feedback"
                                        pageButton
                                        isSubmit
                                        dataTestId="button--submit-feedback"
                                        disabled={!isValid || !dirty}
                                        processing={isSubmitting}
                                    />
                                </form>
                            )
                        }}
                    </Formik>
                )}
            </Page>
        </>
    )
}

const FeedbackSuccess = () => {
    const navigate = useNavigate()
    return (
        <div className={cn(styles.feedbackSuccess, spacing.spaceAbove16)}>
            <h1 className={spacing.spaceBelow24}>Thanks for sharing!</h1>
            <p className={spacing.spaceBelow20}>
                Your feedback helps us to make Sharesies a better place to grow everyone’s wealth.
            </p>
            <p>If there’s anything else you want to chat about, just flick us a message.</p>
            <Button label="Done" pageButton onClick={() => navigate('/account')} dataTestId="button--feedback-done" />
        </div>
    )
}

export default RequestFeature
