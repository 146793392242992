import {Flag} from '@design-system/flag'
import React from 'react'
import styles from './FreeForStaffFlag.scss'

export const FreeForStaffFlag: React.FunctionComponent<{testIdSuffix: string}> = ({testIdSuffix}) => (
    <Flag
        dataTestId={`staff-benefits-flag-${testIdSuffix}`}
        text="FREE for Sharesies staff 💗"
        type="infoAlt"
        additionalClassName={styles.freeForStaffFlag}
    />
)
