import cn from 'classnames'
import Decimal from 'decimal.js'
import React from 'react'
import {Link} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {useWalletBreakdown} from '~/global/state-hooks/retail/useWalletBreakdown'
import {roundWithdrawalValue} from '~/global/utils/round-withdrawal-value/roundWithdrawalValue'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import DetailModal from '~/global/widgets/detail-modals/DetailModal'
import HelpCentreLink from '~/global/widgets/help-centre-link/HelpCentreLink'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {useAppSelector} from '~/store/hooks'
import styles from './WalletBreakdownModal.scss'

interface WalletBreakdownLineProps {
    title: string
    value: string
    currency: string
    isTotal?: boolean
}

const WalletBreakdownLine: React.FunctionComponent<WalletBreakdownLineProps> = ({title, value, currency, isTotal}) => {
    return !roundWithdrawalValue(value).isZero() || isTotal ? (
        <>
            <div
                className={cn(styles.walletStatusLine, {
                    [styles.walletStatusLineTotal]: isTotal,
                })}
            >
                <span>{title}</span>
                <span>
                    <DollarValue value={value} currency={currency} roundDown />
                </span>
            </div>
        </>
    ) : null
}

interface WalletBreakdownModalProps {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    walletPortfolioId: string
}

export const WalletBreakdownModal: React.FunctionComponent<WalletBreakdownModalProps> = ({
    isOpen,
    setIsOpen,
    walletPortfolioId,
}) => {
    const profileUrl = useProfileUrl()

    const {uncleared_balance, available_balance, foreign_currency_balance, restricted_balance, instructable_balance} =
        useWalletBreakdown(walletPortfolioId)

    const hasSettled = !roundWithdrawalValue(available_balance).isZero()
    const hasUnsettled = !roundWithdrawalValue(uncleared_balance).isZero()
    const hasOtherCurrencies = !roundWithdrawalValue(foreign_currency_balance).isZero()
    const hasRestricted = !roundWithdrawalValue(restricted_balance).isZero()

    const user = useAppSelector(s => s.identity.user!)
    const currency = user.home_currency

    return (
        <DetailModal isOpen={isOpen} closeHandler={() => setIsOpen(false)}>
            <div>
                <h1 className={spacing.spaceBelow24}>Wallet status</h1>
                <WalletBreakdownLine title="Settled money" value={available_balance} currency={currency} />
                <WalletBreakdownLine title="Unsettled money" value={uncleared_balance} currency={currency} />
                <WalletBreakdownLine
                    title="Estimated other currencies"
                    value={foreign_currency_balance}
                    currency={currency}
                />
                <WalletBreakdownLine
                    title="Gifts, referrals or promos"
                    value={restricted_balance}
                    currency={currency}
                />
                <WalletBreakdownLine
                    title="Total in Wallet"
                    value={Decimal.sum(instructable_balance, foreign_currency_balance).toString()}
                    currency={currency}
                    isTotal
                />
                {hasSettled && (
                    <p className={styles.walletStatusInfo}>
                        Settled money usually takes 1–2 business days to arrive in your bank account.
                    </p>
                )}
                {hasUnsettled && (
                    <p className={styles.walletStatusInfo}>
                        Unsettled money includes recently filled sell orders, and some types of Wallet top-ups.
                        Withdrawing this money{' '}
                        <HelpCentreLink
                            nzArticle="813843-withdraw-money-from-your-wallet"
                            auArticle="4983255-withdraw-money-from-your-wallet"
                        >
                            can take up to five business days
                        </HelpCentreLink>{' '}
                        to arrive in your bank account.
                    </p>
                )}

                {hasOtherCurrencies && (
                    <p className={styles.walletStatusInfo}>
                        If you’d like to withdraw money that is in another currency,{' '}
                        <Link to={profileUrl('wallet/exchange-money')}>exchange it to {currency.toUpperCase()}</Link> in
                        your Wallet first.
                    </p>
                )}

                {hasRestricted && (
                    <p className={styles.walletStatusInfo}>
                        Any money in your Wallet from a Gift, referral, or promo needs to be invested before it can be
                        withdrawn.
                    </p>
                )}
            </div>
        </DetailModal>
    )
}
