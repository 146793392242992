import cn from 'classnames'
import React from 'react'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {Radio} from '~/global/widgets/form-controls'
import {extractCountryNameFromCode} from '~/global/widgets/form-controls/country-select/CountrySelectInput'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {ListMenuItem} from '~/global/widgets/list-menu/ListMenu'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {Link, useNavigate} from '~/migrate-react-router'
import {ACCOUNT_HOLDER_TYPE_TO_LABEL} from '~/sections/user/sections/settings/sections/tax-details/pages/account-holder-type/AccountHolderType'
import {useAppSelector} from '~/store/hooks'
import TaxTreatyBenefit from './widgets/tax-treaty-benefit/TaxTreatyBenefit'
import styles from './TaxDetails.scss'

export const TaxDetails = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const profile = useProfile()

    // This hasn't been migrated to new Owner-based endpoints yet, so we should only use it in legacy profiles
    const taxQuestions = useAppSelector(s => s.identity.user!.checks.tax_questions)
    const isLegacyProfile = !!profile.legacy_profile_type

    const owner = useProfileOwner()
    const preferredName = owner.display_name
    const jurisdiction = owner.product_jurisdiction

    const taxDetails = useRetailGet({
        path: 'owner/:owner_id/tax-details',
        pathParams: {owner_id: owner.id},
        // The AU TFN and tax residency forms don't use useRetailPost yet. Setting to the cacheTime to 0 ensures we
        // refresh this data after submitting and redirecting back here.
        options: {cacheTime: 0},
    }).data

    const firstTaxYear = taxDetails.first_tax_year
    const currentTaxYear = taxDetails.current_tax_year
    const taxTreatyStatus = taxDetails.us_tax_treaty_status
    const tfnNumber = taxDetails.tfn
    const irdNumber = taxDetails.ird_number
    const pir = taxDetails.pir
    const rwt = taxDetails.rwt_rate
    const taxResidencies = taxDetails.tax_residency_country_names

    const makeTaxDeclaration = useRetailPost({
        path: 'owner/:owner_id/tax-declaration',
        pathParams: {owner_id: owner.id},
        queryCacheToInvalidate: ['owner/:owner_id/tax-details', {owner_id: owner.id}],
    })
    const taxDeclareNo = () => makeTaxDeclaration.mutateAsync({is_foreign_tax_resident: false, countries: []})

    const [taxResidenceValue, setTaxResidenceValue] = React.useState('')
    const [taxResidentSubmitting, setTaxResidentSubmitting] = React.useState<boolean>(false)

    const USCertificationType = taxTreatyStatus === 'us' ? 'W9' : 'W-8BEN'
    const USCertificationTypeUrl = taxTreatyStatus === 'us' ? 'W9' : 'W8'

    const taxYears = []
    for (let i = firstTaxYear; i < currentTaxYear!; i++) {
        taxYears.unshift(i)
    }

    const actingAsIdParam = profile.legacy_customer_id ? encodeURIComponent(profile.legacy_customer_id) : null
    const defaultTaxResidency = extractCountryNameFromCode(jurisdiction)

    return (
        <>
            <Toolbar dataTestId="toolbar--tax-details" leftButton="back" title="Tax details" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                {isLegacyProfile && !taxQuestions && (
                    <div className={cn(spacing.spaceAbove12, styles.warningBox)}>
                        <strong>
                            {owner.is_self ? 'Are you' : `Is ${preferredName}`} a tax resident of another country
                            outside {jurisdiction === 'nz' ? 'NZ' : jurisdiction === 'au' ? 'Australia' : ''}?
                        </strong>
                        <Radio
                            dataTestId="radio--tax-residency"
                            name="tax-residency"
                            isTouched={false}
                            value={taxResidenceValue}
                            onChange={async e => {
                                setTaxResidenceValue(e.target.value)
                                if (e.target.value === 'yes') {
                                    navigate(profileUrl('settings/tax-details/tax-declaration/foreign-tax-resident'))
                                } else if (e.target.value === 'no') {
                                    setTaxResidentSubmitting(true)
                                    try {
                                        await taxDeclareNo()
                                    } finally {
                                        setTaxResidentSubmitting(false)
                                    }
                                }
                            }}
                            choices={[
                                {label: 'Yes', value: 'yes'},
                                {label: 'No', value: 'no'},
                            ]}
                        />
                        {taxResidentSubmitting && <Loading />}
                    </div>
                )}

                <div className={styles.infoHeader}>
                    <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow12)}>Tax information</h2>
                    {jurisdiction === 'nz' && (
                        <div className={styles.editLink}>
                            <Link to={profileUrl('settings/tax-details/edit')} data-testid="anchor--edit-tax-details">
                                Edit
                            </Link>
                        </div>
                    )}
                </div>

                {jurisdiction === 'nz' && (
                    <>
                        <h3 className={typographyOverrides['as-h3']}>IRD number</h3>
                        <p className={spacing.spaceBelow12}>{irdNumber ?? '-'}</p>
                        <h3 className={typographyOverrides['as-h3']}>Prescribed investor rate (PIR)</h3>
                        <p className={spacing.spaceBelow12}>{pir ? `${pir}%` : '-'}</p>

                        {rwt && (
                            <>
                                <h3 className={typographyOverrides['as-h3']}>Resident witholding tax (RWT) rate</h3>
                                <p className={spacing.spaceBelow12}>{rwt ? `${rwt}%` : '-'}</p>
                            </>
                        )}
                    </>
                )}
                {jurisdiction === 'au' && (
                    <>
                        <h3 className={typographyOverrides['as-h3']}>
                            Tax file number (TFN)
                            <div className={styles.editLink}>
                                <Link
                                    to={profileUrl('settings/tax-details/edit')}
                                    data-testid="anchor--edit-tax-residency"
                                >
                                    {tfnNumber ? 'Edit' : 'Add'}
                                </Link>
                            </div>
                        </h3>
                        <p className={spacing.spaceBelow8}>{tfnNumber ? tfnNumber : ''}</p>
                    </>
                )}
                <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow12, spacing.spaceAbove32)}>
                    Tax residency
                </h2>
                <p className={spacing.spaceBelow8}>
                    {defaultTaxResidency}
                    {/*
                        TODO(Carl): Rebuild the tax declaration flow for Shared Money, allowing tax residency to be
                            edited for organisations. This isn't needed for the Fonterra launch, and the fact that the
                            signup flow uses the same form made it a bit risky to rebuild just before launch. We'll
                            revisit when we have some more time. Shak is okay with it not being editable for a while for
                            Fonterra orgs.
                    */}
                    {profile.legacy_profile_type ? (
                        <span className={styles.editLink}>
                            <ButtonAsLink
                                onClick={() => {
                                    navigate(profileUrl('settings/tax-details/tax-declaration'))
                                }}
                                data-testid="anchor--edit-tax-residency"
                            >
                                Edit
                            </ButtonAsLink>
                        </span>
                    ) : null}
                </p>
                {taxResidencies &&
                    taxResidencies.map(countryName => (
                        <p key={countryName} className={spacing.spaceBelow8}>
                            {countryName}
                        </p>
                    ))}

                {owner.owner_type === 'ORGANISATION' && (
                    <>
                        <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow12, spacing.spaceAbove32)}>
                            Account holder type
                            <div className={styles.editLink}>
                                <Link to={profileUrl('settings/tax-details/account-holder-type')}>Edit</Link>
                            </div>
                        </h2>
                        <p className={spacing.spaceBelow8}>
                            {taxDetails.account_holder_type
                                ? ACCOUNT_HOLDER_TYPE_TO_LABEL[taxDetails.account_holder_type]
                                : '-'}
                        </p>
                    </>
                )}

                <TaxTreatyBenefit jurisdiction={jurisdiction} taxTreatyStatus={taxTreatyStatus} />

                {taxTreatyStatus !== 'disabled' && (
                    <>
                        <div className={spacing.spaceBelow24}>
                            <ListMenuItem
                                key={`${USCertificationType}-tax-certification`}
                                label={`${USCertificationType} Form certification`}
                                onClick={() =>
                                    window.open(
                                        `/api/us-tax-certification/${USCertificationTypeUrl}-form-certification.pdf?acting_as_id=${actingAsIdParam}`,
                                        '_blank',
                                        'noopener',
                                    )
                                }
                            />
                        </div>
                        <p className={spacing.spaceBelow20}>
                            You’ve submitted a {USCertificationType} form
                            {owner.is_self
                                ? ' so that you’re taxed at the right rate when you invest in US shares.'
                                : ` on ${preferredName}’s behalf so that they’re taxed at the right rate when they invest in US shares.`}
                        </p>
                        <p>
                            If there are incorrect details on {owner.is_self ? 'your' : 'the'} form,{' '}
                            <Link to={profileUrl('us-sign-up')} data-testid="anchor--edit-tax-form">
                                edit and resubmit it
                            </Link>
                            .
                        </p>
                    </>
                )}

                <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow12, spacing.spaceAbove32)}>
                    Tax statements
                </h2>
                {isLegacyProfile && taxYears.length > 0 ? (
                    <div className={spacing.spaceBelow24}>
                        <p className={spacing.spaceBelow24}>
                            {jurisdiction === 'nz' && 'In April each year, we’ll'}
                            {jurisdiction === 'au' && 'We’ll'} give you a tax statement for the end of the financial
                            year. These show {owner.is_self ? 'your' : `${preferredName}'s`} income for tax purposes.
                        </p>
                        {taxYears.map((year: number) => (
                            <ListMenuItem
                                key={year}
                                label={`Tax statement—${jurisdiction === 'nz' ? 'March' : 'June'} ${year}`}
                                onClick={() =>
                                    window.open(
                                        `/api/accounting/sharesies-tax-statement-${year}.pdf?acting_as_id=${actingAsIdParam}`,
                                        '_blank',
                                        'noopener',
                                    )
                                }
                            />
                        ))}
                    </div>
                ) : (
                    // Default is no statements shown
                    <p className={spacing.spaceBelow24}>
                        We have no tax statements for {owner.is_self ? 'you' : preferredName} just now.{' '}
                        {owner.is_self ? 'Your' : `${preferredName}’s`} first tax statement will appear here{' '}
                        {jurisdiction === 'nz' && <>in April {firstTaxYear}.</>}
                        {jurisdiction === 'au' && 'after the tax year ends.'}
                    </p>
                )}

                <p className={spacing.spaceBelow24}>
                    For more information on tax go to our{' '}
                    <HelpCentreLink auArticle="4983208-tax" nzArticle="1399438-tax" />.
                </p>
            </Page>
        </>
    )
}

export default TaxDetails
