import {
    AutoInvest,
    Cancel,
    Deposit,
    Gift,
    Love,
    OrderBuy,
    OrderSell,
    SpareChange,
    Success,
    Transfer,
    Withdraw,
} from '@design-system/icon'
import React from 'react'
import {Model} from '~/api/retail/types'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import TransactionRow from '~/global/widgets/transaction-row/TransactionRow'
import {Transaction, PaymentRequest} from '~/store/accounting/types'
import {Instrument} from '~/store/instrument/types'

export const walletIconMapping = (txn: Transaction) => {
    switch (txn.reason) {
        case 'gift redemption':
        case 'gift referral bonus':
        case 'corporate gift redemption':
            return <Gift />
        case 'holding funds for share purchase':
        case 'corporate action application holding':
            if (txn.buy_order && txn.buy_order.autoinvest) {
                return <AutoInvest />
            }
            return <OrderBuy />
        case 'holding shares for share sale':
            return <OrderSell />
        case 'fund settlement':
            if (txn.sell_order) {
                return <OrderSell />
            }
            return <SpareChange />
        case 'account closure':
        case 'holding funds for withdrawal':
        case 'account fraud':
        case 'save deposit':
        case 'holding funds for US transfer in':
            return <Withdraw />
        case 'dividend payout':
        case 'corporate action cash distribution':
        case 'change for buy order':
            return <SpareChange />
        case 'referral bonus':
        case 'referred bonus':
            return <Love />
        case 'tax year end':
            if (txn.amount[0] === '-') {
                return <Success />
            }
            return <SpareChange />

        case 'expired buy order':
        case 'fund order':
        case 'corporate credit':
        case 'refund for scaled fund settlement':
            return <SpareChange />
        case 'nzx sell trade':
        case 'drivewealth sell trade':
        case 'delist share sale':
            if (txn.sell_order) {
                return <OrderSell />
            }
            return <Success />
        case 'complete csn share transfer out':
            return <Transfer />
        case 'rejected sell order':
        case 'rejected buy order':
        case 'cancelling withdrawal':
        case 'cancelling buy order':
        case 'corporate action application cancellation':
            return <Cancel />
        case 'customer deposit':
        case 'manual reverse transfer fee':
        case 'save withdrawal':
            if (txn.payment_request) {
                return <SpareChange />
            }
            return <Deposit />
        case 'subscription payment':
            return <SpareChange />
        case 'share premium payout':
        case 'capital decrease':
        case 'beta welcome gift':
        default:
            return <Success />
    }
}

export interface TransactionLineProps {
    description?: string
    instrumentName?: string
    transaction: Model.Transaction
    ref: React.Ref<HTMLButtonElement>
}

export const TransactionLine = React.forwardRef(
    ({description, instrumentName, transaction}: TransactionLineProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
        const profileUrl = useProfileUrl()
        const profile = useProfile()
        const walletPortfolio = profile.portfolios.find(p => p.product === 'WALLET')

        const instrumentId = transaction.fund_id
        const orderId = transaction.order_id
        const title = instrumentName || description || transaction.description
        const cardDescription = instrumentName ? description || transaction.description : null
        const isLimitBuyRefund = transaction.reason === 'change for buy order'
        const resolveUrl = () => {
            if (instrumentId && orderId && !isLimitBuyRefund) {
                if (
                    walletPortfolio &&
                    (transaction.buy_order?.link_to_new_order_card || transaction.sell_order?.link_to_new_order_card)
                ) {
                    return profileUrl('wallet/:portfolioId/:transactionKey', {
                        portfolioId: walletPortfolio.id,
                        transactionKey: transaction.key,
                    })
                }

                return profileUrl('wallet/investing-activity/:instrumentId/:orderId', {
                    instrumentId,
                    orderId,
                })
            } else {
                return profileUrl('wallet/transaction/:transactionId/:lineNumber', {
                    transactionId: transaction.transaction_id.toString(),
                    lineNumber: transaction.line_number.toString(),
                })
            }
        }

        return (
            <TransactionRow
                amount={transaction.amount}
                currency={transaction.currency}
                date={transaction.timestamp}
                description={cardDescription}
                icon={walletIconMapping(transaction)}
                title={title}
                url={resolveUrl()}
                ref={ref}
            />
        )
    },
)

interface WalletTransactionProps {
    txn: Model.Transaction
    instrumentsById: Record<string, Instrument>
    ref: React.Ref<HTMLButtonElement>
}
const WalletTransaction = React.forwardRef(
    ({txn, instrumentsById}: WalletTransactionProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
        switch (txn.reason) {
            case 'rejected sell order':
                if (txn.sell_order && instrumentsById[txn.sell_order.fund_id]) {
                    return (
                        <TransactionLine
                            ref={ref}
                            instrumentName={instrumentsById[txn.sell_order.fund_id].name}
                            transaction={txn}
                        />
                    )
                }
                return <TransactionLine ref={ref} transaction={txn} />
            case 'holding funds for share purchase':
            case 'change for buy order':
            case 'refund for scaled fund settlement':
            case 'rejected buy order':
            case 'expired buy order':
            case 'cancelling buy order':
                if (txn.buy_order && instrumentsById[txn.buy_order.fund_id]) {
                    return (
                        <TransactionLine
                            ref={ref}
                            instrumentName={instrumentsById[txn.buy_order.fund_id].name}
                            transaction={txn}
                        />
                    )
                }
                return <TransactionLine ref={ref} transaction={txn} />
            case 'fund order':
                if (txn.buy_order && instrumentsById[txn.buy_order.fund_id] && txn.buy_order.requested_currency) {
                    return (
                        <TransactionLine
                            ref={ref}
                            instrumentName={instrumentsById[txn.buy_order.fund_id].name}
                            description={`Change from $${txn.buy_order.requested_currency} buy`}
                            transaction={txn}
                        />
                    )
                }
                return <TransactionLine ref={ref} transaction={txn} />
            case 'fund settlement':
                if (txn.sell_order && instrumentsById[txn.sell_order.fund_id]) {
                    return (
                        <TransactionLine
                            ref={ref}
                            instrumentName={instrumentsById[txn.sell_order.fund_id].name}
                            description="Sell"
                            transaction={txn}
                        />
                    )
                }
                if (txn.buy_order && instrumentsById[txn.buy_order.fund_id] && txn.buy_order.requested_currency) {
                    return (
                        <TransactionLine
                            ref={ref}
                            instrumentName={instrumentsById[txn.buy_order.fund_id].name}
                            description={`Change from $${txn.buy_order.requested_currency} buy`}
                            transaction={txn}
                        />
                    )
                }
                return <TransactionLine ref={ref} description="Change from order of shares" transaction={txn} />
            case 'corporate action cash distribution':
            case 'corporate action share distribution':
            case 'corporate action application holding':
            case 'corporate action application cancellation':
            case 'corporate action application scaling':
                if (txn.fund_id && instrumentsById[txn.fund_id]) {
                    return (
                        <TransactionLine
                            ref={ref}
                            description={txn.description}
                            instrumentName={instrumentsById[txn.fund_id].name}
                            transaction={txn}
                        />
                    )
                }
                return <TransactionLine ref={ref} description={txn.description} transaction={txn} />
            case 'dividend payout':
            case 'delist share sale':
            case 'share premium payout':
            case 'capital decrease':
            case 'tax year end':
                if (txn.fund_id && instrumentsById[txn.fund_id]) {
                    return (
                        <TransactionLine
                            ref={ref}
                            instrumentName={instrumentsById[txn.fund_id].name}
                            transaction={txn}
                        />
                    )
                }
                return <TransactionLine ref={ref} transaction={txn} />
            case 'nzx sell trade':
            case 'drivewealth sell trade':
            case 'cmc sell trade':
                if (txn.sell_order && instrumentsById[txn.sell_order.fund_id]) {
                    const description = txn.sell_order.price_limit ? 'Limit sell' : 'Market sell'
                    return (
                        <TransactionLine
                            ref={ref}
                            instrumentName={instrumentsById[txn.sell_order.fund_id].name}
                            description={description}
                            transaction={txn}
                        />
                    )
                }
                return <TransactionLine ref={ref} transaction={txn} />
            case 'complete csn share transfer out':
            case 'complete drs share transfer out':
                if (txn.transfer_order && instrumentsById[txn.transfer_order.fund_id]) {
                    return (
                        <TransactionLine
                            ref={ref}
                            instrumentName={instrumentsById[txn.transfer_order.fund_id].name}
                            transaction={txn}
                        />
                    )
                }
                return <TransactionLine ref={ref} transaction={txn} />
            case 'save deposit':
            case 'save withdrawal':
            case 'corporate credit':
            case 'referral bonus':
            case 'beta welcome gift':
            case 'gift referral bonus':
            case 'holding funds for withdrawal':
            case 'gift redemption':
            case 'customer deposit':
            default:
                return <TransactionLine ref={ref} transaction={txn} />
        }
    },
)

interface WalletPaymentRequest {
    paymentRequest: PaymentRequest
}
export const WalletPaymentRequest = React.forwardRef(
    ({paymentRequest}: WalletPaymentRequest, ref: React.ForwardedRef<HTMLButtonElement>) => {
        const profileUrl = useProfileUrl()

        return (
            <TransactionRow
                amount={paymentRequest.amount}
                currency={paymentRequest.currency}
                date={paymentRequest.timestamp}
                title={paymentRequest.description}
                icon={<SpareChange />}
                url={profileUrl('wallet/payment-request/:paymentRequestId', {
                    paymentRequestId: paymentRequest.id,
                })}
                ref={ref}
            />
        )
    },
)

export default WalletTransaction
