/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    CashAndStockDividendDto,
    CashAndStockDividendDtoFromJSON,
    CashAndStockDividendDtoFromJSONTyped,
    CashAndStockDividendDtoToJSON,
    CashAndStockDividendDtoToJSONTyped,
    CashDividendDto,
    CashDividendDtoFromJSON,
    CashDividendDtoFromJSONTyped,
    CashDividendDtoToJSON,
    CashDividendDtoToJSONTyped,
    StockDividendDto,
    StockDividendDtoFromJSON,
    StockDividendDtoFromJSONTyped,
    StockDividendDtoToJSON,
    StockDividendDtoToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface DividendsResponseDto
 */
export interface DividendsResponseDto {
    /**
     *
     * @type {Array<CashDividendDto>}
     * @memberof DividendsResponseDto
     */
    cashDividends: Array<CashDividendDto>
    /**
     *
     * @type {Array<StockDividendDto>}
     * @memberof DividendsResponseDto
     */
    stockDividends: Array<StockDividendDto>
    /**
     *
     * @type {Array<CashAndStockDividendDto>}
     * @memberof DividendsResponseDto
     */
    cashAndStockDividends: Array<CashAndStockDividendDto>
}

export function DividendsResponseDtoFromJSON(json: any): DividendsResponseDto {
    return DividendsResponseDtoFromJSONTyped(json, false)
}

export function DividendsResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DividendsResponseDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        cashDividends: (json['cashDividends'] as Array<any>).map(CashDividendDtoFromJSON),
        stockDividends: (json['stockDividends'] as Array<any>).map(StockDividendDtoFromJSON),
        cashAndStockDividends: (json['cashAndStockDividends'] as Array<any>).map(CashAndStockDividendDtoFromJSON),
    }
}

export function DividendsResponseDtoToJSON(value?: DividendsResponseDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        cashDividends: (value.cashDividends as Array<any>).map(CashDividendDtoToJSON),
        stockDividends: (value.stockDividends as Array<any>).map(StockDividendDtoToJSON),
        cashAndStockDividends: (value.cashAndStockDividends as Array<any>).map(CashAndStockDividendDtoToJSON),
    }
}
