import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Image from '~/global/widgets/image/Image'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import houseDark from '~/sections/user/assets/images/address-dark.svg'
import houseLight from '~/sections/user/assets/images/address-light.svg'
import styles from '~/sections/user/sections/sign-up/SignUp.scss'
import {useAppSelector} from '~/store/hooks'

interface Props {
    onNext(): void
    label?: string
}

const AddressVerificationUploaded = ({onNext, label}: Props) => {
    const jurisdiction = useAppSelector(({identity}) => identity.user!.jurisdiction)

    if (jurisdiction === 'au') {
        return (
            <>
                <Toolbar dataTestId="toolbar--address-upload" />
                <Page>
                    <div className={styles.splashCenterVertically}>
                        <Image
                            className={cn(spacing.spaceAbove24, spacing.spaceBelow64)}
                            src={houseLight}
                            darkSrc={houseDark}
                        />
                        <h2>We’re working on it!</h2>
                        <p>
                            We’ll verify your address within 2 working days. We’ll let you know when you can start using
                            Sharesies!
                        </p>
                    </div>
                    <Button
                        dataTestId="button--address-success"
                        pageButton
                        label={label ? label : 'Next'}
                        onClick={onNext}
                    />
                </Page>
            </>
        )
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--address-upload" />
            <Page>
                <div className={styles.splashCenterVertically}>
                    <Image
                        className={cn(spacing.spaceAbove24, spacing.spaceBelow64)}
                        src={houseLight}
                        darkSrc={houseDark}
                    />
                    <h2>We’re verifying your address!</h2>
                    <p>We’ll verify your address within 2 working days.</p>
                </div>
                <Button
                    dataTestId="button--address-success"
                    pageButton
                    label={label ? label : 'Onwards!'}
                    onClick={onNext}
                />
            </Page>
        </>
    )
}

export const AccountAddressUploaded: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const label: string = 'Back to account'
    const onNext = () => {
        navigate(profileUrl('settings/personal-details'))
    }

    return <AddressVerificationUploaded onNext={onNext} label={label} />
}

export const AUCustomerUnderReview = () => {
    const name = useAppSelector(s => s.identity.user!.preferred_name)

    return (
        <>
            <Toolbar dataTestId="toolbar--under-review" />
            <Page>
                <div className={styles.heroSticker}>
                    <Image
                        className={cn(spacing.spaceAbove24, spacing.spaceBelow64)}
                        src={houseLight}
                        darkSrc={houseDark}
                    />
                </div>
                <div className={styles.content}>
                    <h1 className={spacing.spaceBelow16}>Won’t be long now, {name}!</h1>
                    <p className={spacing.spaceBelow16}>
                        Before you can start investing with Sharesies, we’ll need to finish verifying your ID or
                        address—it might take us a day or two, but we’re on it.
                    </p>
                    <p className={spacing.spaceBelow16}>
                        As soon as you’re good to go, we’ll be in touch. You’re also welcome to keep checking back here!
                    </p>
                    <p className={spacing.spaceBelow16}>
                        In the meantime, feel free to flick us a message if you have any questions:{' '}
                        <a href="mailto:help@sharesies.com.au">help@sharesies.com.au</a>
                    </p>
                    <p>We can’t wait to be part of your investing journey. 🍍</p>
                </div>
            </Page>
        </>
    )
}

interface AUCustomerAddressUploadStillRequiredProps {
    onNext: () => void
}
export const AUCustomerAddressUploadStillRequired = ({onNext}: AUCustomerAddressUploadStillRequiredProps) => {
    return (
        <>
            <Toolbar dataTestId="toolbar--address-upload-still-needed" />
            <Page>
                <div className={styles.content}>
                    <h1 className={spacing.spaceBelow16}>To continue, you’ll need to upload proof of address</h1>
                    <p className={spacing.spaceBelow16}>
                        You’re almost good to go. Before you can start using Sharesies, you’ll need to upload a proof of
                        address—
                        <a
                            target="_blank"
                            rel="noopener"
                            href="https://intercom.help/sharesies-au/en/articles/4983227-verify-your-address"
                        >
                            check out the types of documents you can use.
                        </a>
                    </p>
                    <p className={spacing.spaceBelow16}>
                        The document will need to be less than 12 months old, and must be for a residential address.
                    </p>
                    <p className={spacing.spaceBelow16}>
                        If you don’t have your proof of address handy, we’ll hold your spot and you can come back to
                        upload it later.
                    </p>
                    <p className={spacing.spaceBelow16}>
                        If you’ve got your proof of address handy, hit the button below to continue to upload it.
                    </p>
                    <p className={spacing.spaceBelow16}>
                        If you have any questions, email{' '}
                        <a href="mailto:help@sharesies.com.au">help@sharesies.com.au</a>
                    </p>
                </div>
                <Button dataTestId="button--address-success" pageButton label="Upload address" onClick={onNext} />
            </Page>
        </>
    )
}

export default AddressVerificationUploaded
