/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    PriceHistoryDto,
    PriceHistoryDtoFromJSON,
    PriceHistoryDtoFromJSONTyped,
    PriceHistoryDtoToJSON,
    PriceHistoryDtoToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface CurrentPriceResponseDto
 */
export interface CurrentPriceResponseDto {
    /**
     *
     * @type {string}
     * @memberof CurrentPriceResponseDto
     */
    instrumentId: string
    /**
     *
     * @type {string}
     * @memberof CurrentPriceResponseDto
     */
    symbol: string
    /**
     *
     * @type {string}
     * @memberof CurrentPriceResponseDto
     */
    urlSlug: string
    /**
     *
     * @type {string}
     * @memberof CurrentPriceResponseDto
     */
    delayedPrice: string
    /**
     *
     * @type {string}
     * @memberof CurrentPriceResponseDto
     */
    delayedExtendedHoursPrice: string
    /**
     *
     * @type {PriceHistoryDto}
     * @memberof CurrentPriceResponseDto
     */
    mostRecentPriceHistory: PriceHistoryDto
}

export function CurrentPriceResponseDtoFromJSON(json: any): CurrentPriceResponseDto {
    return CurrentPriceResponseDtoFromJSONTyped(json, false)
}

export function CurrentPriceResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentPriceResponseDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        instrumentId: json['instrumentId'],
        symbol: json['symbol'],
        urlSlug: json['urlSlug'],
        delayedPrice: json['delayedPrice'],
        delayedExtendedHoursPrice: json['delayedExtendedHoursPrice'],
        mostRecentPriceHistory: PriceHistoryDtoFromJSON(json['mostRecentPriceHistory']),
    }
}

export function CurrentPriceResponseDtoToJSON(value?: CurrentPriceResponseDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        instrumentId: value.instrumentId,
        symbol: value.symbol,
        urlSlug: value.urlSlug,
        delayedPrice: value.delayedPrice,
        delayedExtendedHoursPrice: value.delayedExtendedHoursPrice,
        mostRecentPriceHistory: PriceHistoryDtoToJSON(value.mostRecentPriceHistory),
    }
}
