import React from 'react'
import {EssViews} from '~/store/employeeShareScheme/selectors'
import {useAppSelector} from '~/store/hooks'
import {useEmployeeShareSchemeDetails} from '../retail/useEmployeeShareSchemeDetails'

export const useEssViewsForInstrument = (instrumentId: string) => {
    const isEmployed = useAppSelector(s => s.identity.employmentInstrumentIds).includes(instrumentId)
    const employeeShareScheme = useEmployeeShareSchemeDetails()
    const participation = employeeShareScheme.shareSchemeParticipation[instrumentId]
    const allocations = employeeShareScheme.shareSchemeAllocationsByInstrument[instrumentId]

    return React.useMemo(() => {
        const essViews: EssViews = {
            hasEmployment: false,
            hasScheme: false,
            hasUnvestedAllocation: false,
            hasVestedAllocation: false,
            hasAllocation: false,
            hasRightAllocation: false,
            hasMultipleAllocationTypes: false,
            isActiveMember: false,
            hasShareScheme: false,
            hasLtiScheme: false,
            hasAlignmentRightScheme: false,
            hasAlignmentRightAllocation: false,
            hasVestedAlignmentRightAllocation: false,
            canBuyInstrument: true,
            canSellInstrument: true,
        }

        if (!isEmployed) {
            return essViews
        }
        essViews.hasEmployment = true
        essViews.hasUnvestedAllocation = !!allocations && allocations.total_unvested_allocations > 0
        essViews.hasVestedAllocation = !!allocations && allocations.total_vested_allocations > 0
        essViews.hasAllocation = !!allocations && allocations.share_allocations.length > 0
        essViews.hasRightAllocation = !!allocations && allocations.share_rights_allocations.length > 0
        essViews.hasMultipleAllocationTypes = essViews.hasAllocation && essViews.hasRightAllocation
        essViews.hasScheme = !!participation && participation.filter(p => !p.exited_at).length > 0
        essViews.isActiveMember =
            essViews.hasEmployment && (essViews.hasUnvestedAllocation || essViews.hasVestedAllocation)

        essViews.hasShareScheme =
            !!participation && participation.filter(p => !p.exited_at && p.scheme_type === 'SHARES').length > 0

        essViews.hasLtiScheme =
            !!participation && participation.filter(p => !p.exited_at && p.scheme_type === 'RIGHTS').length > 0

        essViews.hasAlignmentRightScheme =
            !!participation &&
            participation.filter(p => !p.exited_at && p.scheme_type === 'ALIGNMENT_RIGHTS').length > 0

        essViews.hasAlignmentRightScheme =
            !!participation &&
            participation.filter(p => !p.exited_at && p.scheme_type === 'ALIGNMENT_RIGHTS').length > 0

        essViews.hasAlignmentRightAllocation = !!allocations && allocations.alignment_right_allocations.length > 0
        essViews.hasVestedAlignmentRightAllocation =
            !!allocations && allocations.alignment_right_allocations.filter(a => !!a.lines[0].vesting_date).length > 0

        // Investors who have Alignment Rights can't buy and sell shares in the instrument they have
        // Alignment Rights in - used by ViewInstrument to hide the Buy & Sell buttons
        essViews.canBuyInstrument = !essViews.hasAlignmentRightScheme
        essViews.canSellInstrument = !essViews.hasAlignmentRightScheme

        return essViews
    }, [instrumentId])
}
