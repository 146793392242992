import {DateTime} from 'luxon'
import React from 'react'
import {spaceAbove8} from '~/global/scss/helpers/spacing.scss'
import {
    dateFormatDayAndMonthWithTime,
    dateFormatFullMonth,
    dateFormatNoTime,
} from '~/global/utils/format-date/formatDate'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useInstrument} from '~/global/utils/use-instruments/useInstruments'
import {getDocumentURL} from '~/global/widgets/corporate-action-description/corporateActionsV2'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import styles from './RightsOfferDetails.scss'

const RightsOfferDetails = ({instrument}: {instrument: Instrument}) => {
    const tradable = instrument.tradingStatus !== 'notrade'
    const offerDetailsHTML = useAppSelector(
        store => store.order.applicationsByInstrument[instrument.id]?.offerDetailsHTML,
    )
    const exerciseApplication = useAppSelector(store =>
        (store.order.applicationsByInstrument[instrument.id]?.applications || []).find(
            application => application.type === 'EXERCISE',
        ),
    )
    const relatedDocuments = exerciseApplication
        ? exerciseApplication.documents.filter(doc => doc.purpose === 'APPLICATION_INFO')
        : []

    const hasDuelExercise =
        instrument.offerPrice && instrument.exercisePrice && instrument.offerPrice !== instrument.exercisePrice

    const parentInstrument = useInstrument(instrument.parentInstrumentId)

    const NoData = () => <span className={styles.noData}>No data</span>

    return (
        <div>
            <h2 className={styles.title}>Offer details</h2>

            {hasDuelExercise && instrument.offerPrice && instrument.exercisePrice && (
                <div className={spaceAbove8}>
                    <p>
                        You can use these {shareLabel({instrument, isPlural: true})} to buy shares on the ASX for{' '}
                        <b>
                            <DollarValue value={instrument.offerPrice} currency="aud" />
                        </b>{' '}
                        per share, or shares on the NZX for{' '}
                        <b>
                            <DollarValue value={instrument.exercisePrice} currency="nzd" />
                        </b>{' '}
                        per share.
                    </p>
                </div>
            )}

            <dl className={styles.rows}>
                <div className={styles.row}>
                    <dt className={styles.rowLabel}>
                        {shareLabel({instrument, isPlural: true, isCapitalised: true})} trading
                    </dt>
                    <dt className={styles.rowValue}>{tradable ? 'Can' : 'Cannot'} be bought or sold</dt>
                </div>
                {tradable && (
                    <div className={styles.row}>
                        <dt className={styles.rowLabel}>
                            {shareLabel({instrument, isPlural: true, isCapitalised: true})} trading closes
                        </dt>
                        <dd className={styles.rowValue}>
                            {instrument.lastTradingDateTimeUtc ? (
                                instrument.lastTradingDateTimeUtc.toFormat(dateFormatFullMonth)
                            ) : (
                                <NoData />
                            )}
                        </dd>
                    </div>
                )}
                <div className={styles.row}>
                    <dt className={styles.rowLabel}>Exercising of {shareLabel({instrument, isPlural: true})} closes</dt>
                    <dd className={styles.rowValue}>
                        {exerciseApplication && exerciseApplication.applications_close_at ? (
                            exerciseApplication.applications_close_at.toFormat(dateFormatDayAndMonthWithTime)
                        ) : (
                            <NoData />
                        )}
                    </dd>
                </div>
                <div className={styles.row}>
                    <dt className={styles.rowLabel}>
                        {shareLabel({instrument: parentInstrument ?? instrument, isCapitalised: true})} allocation date
                    </dt>
                    <dd className={styles.rowValue}>
                        {exerciseApplication && exerciseApplication.settlement_date ? (
                            DateTime.fromISO(exerciseApplication.settlement_date).toFormat(dateFormatNoTime)
                        ) : (
                            <NoData />
                        )}
                    </dd>
                </div>
                {!hasDuelExercise && (
                    <div className={styles.row}>
                        <dt className={styles.rowLabel}>Offer price per share</dt>
                        <dd className={styles.rowValue}>
                            {instrument.offerPrice ? (
                                <DollarValue
                                    value={instrument.offerPrice}
                                    currency={instrument.currency}
                                    decimalPlaces={3}
                                />
                            ) : (
                                <NoData />
                            )}
                        </dd>
                    </div>
                )}
            </dl>

            {offerDetailsHTML && <div className={styles.text} dangerouslySetInnerHTML={{__html: offerDetailsHTML}} />}
            {!offerDetailsHTML && relatedDocuments.length > 0 && (
                <>
                    <h2 className={styles.title}>Related documents</h2>
                    <dl className={styles.rows}>
                        {relatedDocuments.map(doc => (
                            <div className={styles.row} key={doc.id}>
                                <dt className={styles.rowLabel}>
                                    <a href={getDocumentURL(doc)}>{doc.label}</a>
                                </dt>
                            </div>
                        ))}
                    </dl>
                </>
            )}
        </div>
    )
}

export default RightsOfferDetails
