/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface Msg
 */
export interface Msg {
    /**
     *
     * @type {string}
     * @memberof Msg
     */
    msg: string
}

export function MsgFromJSON(json: any): Msg {
    return MsgFromJSONTyped(json, false)
}

export function MsgFromJSONTyped(json: any, ignoreDiscriminator: boolean): Msg {
    if (json === undefined || json === null) {
        return json
    }
    return {
        msg: json['msg'],
    }
}

export function MsgToJSON(value?: Msg | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        msg: value.msg,
    }
}
