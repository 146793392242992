import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'

const CloseAccountSuccess = () => {
    const navigate = useNavigate()
    const jurisdiction = window.location.pathname.replace('/close-account/success/', '')

    return (
        <>
            <Toolbar dataTestId="toolbar--account-closed" title="Account closed" />
            <Page>
                <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow12)}>
                    Thanks for investing with Sharesies!
                </h2>
                <p className={spacing.spaceBelow24}>Your account has been closed and you’ve been logged out.</p>
                <p className={spacing.spaceBelow24}>
                    Now check your inbox—we’ve sent you an email with your investment report. Don’t be a stranger—come
                    back anytime.
                </p>
                <p className={spacing.spaceBelow24}>Thanks from the Sharesies team.</p>
                <Button
                    dataTestId="button--return-to-home"
                    label={jurisdiction === 'nz' ? 'Haere rā' : 'See ya later'}
                    onClick={() => navigate('/')}
                    pageButton
                />
            </Page>
        </>
    )
}

export default CloseAccountSuccess
