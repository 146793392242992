import React from 'react'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {DollarValue, SharePriceValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {ESSAllocationLine} from '~/store/employeeShareScheme/types'
import {Instrument} from '~/store/instrument/types'
import {RecordRow} from './common'
import styles from './Record.scss'

interface InstrumentActivityRecordShareSchemeAllocationProps {
    instrument: Instrument
    allocationLine: ESSAllocationLine // this should become a Model.ESSAllocationLine once the record is integrated with order history v5
}

const InstrumentActivityRecordShareSchemeAllocation: React.FunctionComponent<InstrumentActivityRecordShareSchemeAllocationProps> =
    React.memo(({instrument, allocationLine}) => {
        const allocationVested = !!allocationLine.vest_transaction_id

        return (
            <div className={styles.orderContent}>
                <div className={styles.orderTitleBlock}>
                    <div className={styles.orderThumb}>
                        <InstrumentLogo instrument={instrument} noBorder />
                    </div>
                    <div>
                        <h4 className={styles.instrumentName}>{instrument.name}</h4>
                        {tradingType(instrument) !== 'managed' && !isInstrumentInNoTrade(instrument) && (
                            <p className={styles.instrumentSymbol}>
                                <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                                <PronounceLetters text={instrument.exchange} />
                            </p>
                        )}
                    </div>
                </div>
                <div className={styles.recordRowContainer} data-testid="contract-note--record-row-container">
                    <RecordRow
                        left="Contract note"
                        right={
                            allocationVested
                                ? allocationLine.vest_transaction_id
                                : allocationLine.allocation_transaction_id
                        }
                    />
                    <RecordRow
                        left="Date allocated"
                        right={(allocationLine.external_allocation_date || allocationLine.allocation_date)?.toFormat(
                            dateFormatNoTime,
                        )}
                    />
                    {allocationVested ? (
                        <RecordRow
                            left="Date received"
                            right={allocationLine.vesting_date?.toFormat(dateFormatNoTime)}
                        />
                    ) : (
                        <RecordRow
                            left="To receive on"
                            right={allocationLine.vesting_due_date.toFormat(dateFormatNoTime)}
                        />
                    )}
                    <RecordRow left="Shares allocated" right={<ShareValue value={allocationLine.number_of_shares} />} />
                    <RecordRow
                        left={allocationVested ? 'Price per share' : 'Share price at allocation'}
                        right={
                            <SharePriceValue value={allocationLine.price_per_share} currency={instrument.currency} />
                        }
                    />
                </div>
                <p className={styles.orderAmountLabel}>Amount</p>
                <div className={styles.orderFooter}>
                    <div data-testid="status-label--allocation-state">
                        <p className={styles.statusLabel}>{allocationVested ? 'Vested' : 'Unvested'}</p>
                    </div>
                    <div>
                        <p className={styles.orderAmount}>
                            <DollarValue value={allocationLine.total_value} currency={instrument.currency} />
                        </p>
                    </div>
                </div>
            </div>
        )
    })

export default InstrumentActivityRecordShareSchemeAllocation
