import React from 'react'
import {useNavigate} from 'react-router'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {PortfolioOrder} from '~/sections/kiwisaver/state'
import {ActivityCard} from '~/sections/kiwisaver/widgets/activity-card/ActivityCard'

const getIcon = (title: string) => (title === 'BUY' ? 'OrderBuy' : 'OrderSell')

export const stateSuffix = (state: string) => {
    return ['PENDING', 'PROCESSING'].includes(state) ? ' - pending' : ''
}

export const getTitle = (side: string) => {
    return side === 'BUY' ? 'Buy order' : 'Sell order'
}

export const getSellDownTypeTitle = (selldown_type: string) => {
    return selldown_type ? ` (${selldown_type})` : ''
}

const InvestingActivityCard: React.FunctionComponent<{activity: PortfolioOrder}> = ({activity}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const title = getTitle(activity.side) + getSellDownTypeTitle(activity.selldown_type!) + stateSuffix(activity.state)

    return (
        <ActivityCard
            key={activity.id}
            icon={getIcon(activity.side)}
            title={title}
            description=""
            date={activity.date}
            amount={activity.amount}
            onClick={() =>
                navigate(profileUrl('kiwisaver/investing-activity/:investingId', {investingId: activity.id.toString()}))
            }
        />
    )
}

export default InvestingActivityCard
