import {Button} from '@design-system/button'
import cn from 'classnames'
import Decimal from 'decimal.js'
import {Form, Formik} from 'formik'
import {useAtom} from 'jotai'
import React from 'react'
import {Navigate, useNavigate} from 'react-router'
import {page, spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ExchangeHours} from '~/global/widgets/exchange-hours/ExchangeHours'
import {validate} from '~/global/widgets/form-controls'
import {StrongCurrency, StrongNumber} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import {useDistillInstrumentWithCurrency} from '~/sections/moose/data/distill'
import {useHoldingDetail, usePortfolioSettings} from '~/sections/moose/data/retail'
import {MooseOrderPathParams} from '~/sections/moose/routes'
import {ItemType, LimitSellOrder, sellOrderAtom} from '~/sections/moose/sections/order/state'
import {
    HoldingDetailForSymbol,
    getHoldingDetailsForSymbol,
} from '~/sections/moose/sections/order/utils/get-holding-details-for-symbol/getHoldingDetailsForSymbol'
import {isUnsupportedInstrument} from '~/sections/moose/sections/order/utils/is-unsupported-instrument/isUnsupportedInstrument'
import {shareLabel} from '~/sections/moose/sections/order/utils/share-label/shareLabel'
import {helpForShareholdingOrder} from '~/sections/moose/sections/order/widgets/help-for-shareholding-order/HelpForShareholdingOrder'
import {helpForStandardSellOrder} from '~/sections/moose/sections/order/widgets/help-for-standard-sell-order/HelpForStandardSellOrder'
import {OrderFormHeader} from '~/sections/moose/sections/order/widgets/order-form-header/OrderFormHeader'
import {itemTypeFromSymbol} from '~/sections/moose/sections/utils/symbol-helpers/symbolHelpers'
import MooseWalletBalance from '~/sections/moose/widgets/moose-wallet-balance/MooseWalletBalance'
import styles from './LimitSellPage.scss'

interface LimitSellPageFormValues {
    numberOfItemsToSell: string
    lowestPricePerItem: string
}

const generateValidatorsForNumberOfItems = (holding: HoldingDetailForSymbol, itemType: ItemType) => {
    const validators: any[] = [validate.required(), validate.minimum(1, `Must be at least 1 ${shareLabel({itemType})}`)]
    if (holding.maxAllowedToSell) {
        validators.push(
            validate.maximum(
                holding.maxAllowedToSell,
                `This is more than the ${shareLabel({itemType, isPlural: true})} that are available to sell.`,
            ),
        )
    }
    return validators
}

export const LimitSellPage: React.FunctionComponent<MooseOrderPathParams> = ({
    portfolioId,
    urlSlug,
}: MooseOrderPathParams) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const instrument = useDistillInstrumentWithCurrency(urlSlug)
    const portfolioSettings = usePortfolioSettings(portfolioId)
    const holdingDetail = useHoldingDetail(portfolioId)
    const [stagedSellOrder, updateStagedSellOrder] = useAtom(sellOrderAtom)

    React.useEffect(() => {
        if (!stagedSellOrder) {
            const itemType: ItemType = itemTypeFromSymbol(instrument.symbol)

            const updatedStagedSellOrder: LimitSellOrder = {
                instrument,
                itemType,
            }

            updateStagedSellOrder(updatedStagedSellOrder)
        }
    }, [stagedSellOrder])

    if (!instrument || isUnsupportedInstrument(urlSlug)) {
        // Redirect back to the portfolio because the instrument is missing or not supported
        return <Navigate to={profileUrl('fonterra/portfolios/:portfolioId', {portfolioId})} replace />
    }

    if (!portfolioSettings.default_csn.is_fin_set) {
        return (
            <Navigate
                to={profileUrl('fonterra/portfolios/:portfolioId/invest/:urlSlug/sell/check-fin-set', {
                    portfolioId,
                    urlSlug,
                })}
                replace
            />
        )
    }

    if (!stagedSellOrder) {
        return null
    }

    const instrumentSymbol = instrument.symbol
    const instrumentHoldingDetail = getHoldingDetailsForSymbol(holdingDetail, instrumentSymbol)

    return (
        <>
            <Page>
                <PageBack />
                <ExchangeHours instrument={stagedSellOrder.instrument} />
                <OrderFormHeader instrument={stagedSellOrder.instrument} sellOrderType="share_limit" buyOrSell="sell" />
                <Formik
                    initialValues={
                        {
                            numberOfItemsToSell: stagedSellOrder.numberOfItems
                                ? stagedSellOrder.numberOfItems.toString()
                                : '',
                            lowestPricePerItem: stagedSellOrder.pricePerItem
                                ? stagedSellOrder.pricePerItem.toString()
                                : '',
                        } as LimitSellPageFormValues
                    }
                    validate={validate.generate({
                        lowestPricePerItem: [
                            validate.required(),
                            validate.money(),
                            validate.minimum(1, 'Must be at least $1'),
                            validate.orderlyLimit(stagedSellOrder.instrument),
                        ],
                        numberOfItemsToSell: generateValidatorsForNumberOfItems(
                            getHoldingDetailsForSymbol(holdingDetail, instrument.symbol),
                            stagedSellOrder.itemType,
                        ),
                    })}
                    onSubmit={values => {
                        updateStagedSellOrder({
                            ...stagedSellOrder,
                            numberOfItems: parseInt(values.numberOfItemsToSell, 10),
                            pricePerItem: new Decimal(values.lowestPricePerItem),
                        })
                        navigate(
                            profileUrl('fonterra/portfolios/:portfolioId/invest/:urlSlug/:action/time-limit', {
                                portfolioId,
                                urlSlug,
                                action: 'sell',
                            }),
                        )
                    }}
                >
                    {({isValid, values, setFieldValue}) => (
                        <Form>
                            <StrongCurrency
                                dataTestId="strong-currency--price-limit"
                                name="lowestPricePerItem"
                                label={`Lowest price to sell per ${shareLabel({itemType: stagedSellOrder.itemType})}`}
                                currency={stagedSellOrder.instrument.currency}
                                decimalPlaces={3}
                            />
                            <StrongNumber
                                dataTestId="strong-number--share-quantity"
                                name="numberOfItemsToSell"
                                label={`Number of ${shareLabel({
                                    itemType: stagedSellOrder.itemType,
                                    isPlural: true,
                                })} to sell`}
                                placeholder={shareLabel({
                                    itemType: stagedSellOrder.itemType,
                                    isCapitalised: true,
                                    isPlural: true,
                                })}
                                helpText={
                                    instrumentHoldingDetail.shareholdingApplies
                                        ? helpForShareholdingOrder(
                                              portfolioId,
                                              instrumentHoldingDetail,
                                              instrumentSymbol,
                                              'sell',
                                          )
                                        : helpForStandardSellOrder(
                                              portfolioId,
                                              stagedSellOrder.itemType,
                                              instrumentHoldingDetail,
                                              instrumentSymbol,
                                              setFieldValue,
                                          )
                                }
                                normalisation="numberOnly"
                                decimalPlaces={0}
                            />
                            <ActionBar className={cn(page.flexRow, styles.formFooter)}>
                                <span className={spacing.spaceAbove4}>
                                    <MooseWalletBalance />
                                </span>
                                <Button
                                    dataTestId="button--next"
                                    label="Next"
                                    disabled={
                                        !isValid ||
                                        instrumentHoldingDetail.maxAllowedToSell === 0 ||
                                        values.lowestPricePerItem === '' ||
                                        values.numberOfItemsToSell === ''
                                    }
                                    isSubmit
                                />
                            </ActionBar>
                        </Form>
                    )}
                </Formik>
            </Page>
        </>
    )
}
