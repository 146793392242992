import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ListMenuGroup, ListMenuItem} from '~/global/widgets/list-menu/ListMenu'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/user/sections/settings/pages/personalised-annual-statements/PersonalisedAnnualStatements.scss'

export const AUDownloadDocuments: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    return (
        <>
            <Toolbar leftButton="back" dataTestId="toolbar--download-documents" />
            <Page>
                <h1 className={cn(styles.mainHeading, spacing.spaceBelow32)}>Download documents</h1>
                <ListMenuGroup className={cn(spacing.spaceBelow12, spacing.spaceAbove12)}>
                    <ListMenuItem
                        label="Personalised annual statements"
                        onClick={() =>
                            navigate(profileUrl('settings/download-documents/personalised-annual-statements'))
                        }
                    />
                </ListMenuGroup>
            </Page>
        </>
    )
}
