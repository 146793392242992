import {atom, useAtom} from 'jotai'
import {DateTime} from 'luxon'
import {distillGetFactory} from '~/api/query/distill'
import {isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {useAppSelector} from '~/store/hooks'
import {selectHasUsLivePricing} from '~/store/identity/selectors'
import {selectIsExchangeInMarketHours, selectIsInstrumentInExtendedHours} from '~/store/instrument/selectors'
import {Instrument, UsLivePricing} from '~/store/instrument/types'

const INTERVAL_MS = 5_000
const TWENTY_MINUTES_IN_MS = 1_000 * 60 * 20

export const hasUsLiveDataErrorAtom = atom<boolean>(false)

/**
 * This hook retrieves live Level 1 market data, also known as “top of book” data.
 * This consists of the market price (= the most recent trade price), and the most recent bid and offer prices.
 *
 * Level 1 data retrieval will only occur if the conditions in `enabled` are met.
 * Therefore, you can use this hook without checking those things at the callsite.
 *
 * {@link https://www.investopedia.com/terms/l/level1.asp Investopedia: Level 1 data}
 *
 * {@link https://gitlab.com/sharesies/distill/-/tree/main/UsLiveData Distill: US Live data project}
 */
export const useUsLivePricing = (instrument: Instrument): UsLivePricing | undefined => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [_, setHasUsLiveDataError] = useAtom(hasUsLiveDataErrorAtom)

    const hasUsLivePricing = useAppSelector(s => selectHasUsLivePricing(s))

    const inMarketHours = useAppSelector(s => selectIsExchangeInMarketHours(s, 'CBOE'))
    const inExtendedHours = useAppSelector(s => selectIsInstrumentInExtendedHours(s, instrument)) // This selector also checks if they've enabled extended hours
    const inOpeningHours = inMarketHours || !!inExtendedHours
    const enabled = hasUsLivePricing && isOnMainUsExchange(instrument) && inOpeningHours // Don't try to fetch live prices when things are closed

    try {
        const response = useUsTopOfBook(instrument, enabled)

        const nowInMs = DateTime.local().toMillis()
        const tradeTimestampInMs = response.data.tradeTimestamp.toMillis()

        // If the live price is older than 20 minutes, don't show it - instead show the regular (delayed) price
        const priceIsFresh = nowInMs - tradeTimestampInMs < TWENTY_MINUTES_IN_MS

        if (
            response.data?.symbol === instrument.symbol &&
            response.data?.tradePrice &&
            parseFloat(response.data.tradePrice) > 0 &&
            priceIsFresh &&
            response.failureCount === 0
        ) {
            setHasUsLiveDataError(false)
            return response.data
        }

        setHasUsLiveDataError(true)
    } catch (e) {
        setHasUsLiveDataError(true)
    }
}

const useUsTopOfBook = (instrument: Instrument, enabled: boolean) => {
    const response = distillGetFactory({
        apiFunctionName: 'apiV1InstrumentsTopOfBookIdGet',
        options: {
            cacheTime: 0,
            enabled,
            retry: true,
            retryDelay: INTERVAL_MS,
            refetchInterval: INTERVAL_MS * 2,
            refetchIntervalInBackground: false,
            staleTime: 0,
        },
    })({id: instrument.id, scope: 'invest'})

    return response
}
