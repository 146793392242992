import {EsInstrumentType} from '~/api/distill'
import capitalise from '~/global/utils/capitalise-string/capitaliseString'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {BasicInstrument} from '~/store/instrument/types'

interface ShareLabelProps {
    instrument: BasicInstrument
    isPlural?: boolean
    isCapitalised?: boolean
    isWhole?: boolean
}

/**
 * @description Utility function for getting the share labels according to the type of instrument
 * @param {ShareLabelProps} args - arguments object
 * @param {BasicInstrument} args.instrument - instrument object
 * @param {boolean} args.isPlural - pluralise instrument label
 * @param {boolean} args.isCapitalised - capitalises the first letter of the label
 * @param {boolean} args.isWhole - prefix label string with 'whole '
 */
export const shareLabel = ({instrument, isPlural, isCapitalised, isWhole}: ShareLabelProps) => {
    let label = 'share'

    if (instrument.instrumentType === EsInstrumentType.rights) {
        label = 'right'
    } else if (instrument.instrumentType === EsInstrumentType.warrants) {
        label = 'warrant'
    } else if (instrument.instrumentType === EsInstrumentType.options) {
        label = 'option'
    }

    // Checking for an `fmsFundId` is a workaround for the fact that we display the underlying instrument for KiwiSaver,
    // despite members actually investing in managed funds. We're working on improving the datastructures we receive
    // from Distill to more cleanly represent this sort of hybrid instrument.
    if (tradingType(instrument) === 'managed' || instrument.fmsFundId) {
        label = 'unit'
    } else if (instrument.isAdr) {
        label = 'receipt'
    }

    if (isWhole) {
        label = 'whole ' + label
    }
    if (isPlural) {
        label += 's'
    }
    if (isCapitalised) {
        label = capitalise(label)
    }
    return label
}
