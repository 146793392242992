import {ScaleLinear} from 'd3-scale'
import React from 'react'
import {InstrumentHistoryItem} from '~/store/accounting/selectors'
import {InstrumentInvestingHistoryDetails} from '~/store/portfolio/types'

export const getValueRange = (history: InstrumentInvestingHistoryDetails[]) =>
    history.reduce(
        ({minValue, maxValue}, item) => {
            if (item.investment_value && (!isFinite(minValue) || item.investment_value < minValue)) {
                minValue = item.investment_value
            }
            if (item.investment_value && (!isFinite(maxValue) || item.investment_value > maxValue)) {
                maxValue = item.investment_value
            }
            if (item.cost_basis && (!isFinite(minValue) || item.cost_basis < minValue)) {
                minValue = item.cost_basis
            }
            if (item.cost_basis && (!isFinite(maxValue) || item.cost_basis > maxValue)) {
                maxValue = item.cost_basis
            }
            return {minValue, maxValue}
        },
        {minValue: Infinity, maxValue: -Infinity},
    )

export const getPriceRange = (history: InstrumentHistoryItem[]) =>
    history.reduce(
        ({minPrice, maxPrice}, item) => {
            if (item.price && (!isFinite(minPrice) || item.price < minPrice)) {
                minPrice = item.price
            }
            if (item.price && (!isFinite(maxPrice) || item.price > maxPrice)) {
                maxPrice = item.price
            }
            return {minPrice, maxPrice}
        },
        {minPrice: Infinity, maxPrice: -Infinity},
    )

// custom hook to track hovering on charts
export const useHover = (measuredLeft: number, xScale: ScaleLinear<number, number>, hoverDayMaximum: number) => {
    const [hovering, setHovering] = React.useState(false)
    const [unboundedHoverDay, setUnboundedHoverDay] = React.useState<number | undefined>(undefined)

    const hoverDay = React.useMemo(() => {
        if (unboundedHoverDay === undefined) {
            return hoverDayMaximum
        }
        if (unboundedHoverDay < 0) {
            return 0
        }
        return Math.min(unboundedHoverDay, hoverDayMaximum)
    }, [unboundedHoverDay, hoverDayMaximum])

    const onMouseOver = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault()
            const x = e.pageX - (measuredLeft || 0)
            const day = Math.round(xScale.invert(x))
            setHovering(true)
            setUnboundedHoverDay(day)
        },
        [measuredLeft, xScale],
    )

    const onMouseOut = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        setHovering(false)
    }, [])

    const onMouseMove = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault()
            if (!hovering) {
                return
            }
            const x = e.pageX - (measuredLeft || 0)
            const day = Math.round(xScale.invert(x))
            setUnboundedHoverDay(day)
        },
        [hovering, measuredLeft, xScale],
    )

    const onTouchStart = React.useCallback(
        (e: React.TouchEvent<HTMLDivElement>) => {
            const pageX = e.touches[0].pageX
            const x = pageX - (measuredLeft || 0)
            const day = Math.round(xScale.invert(x))
            setHovering(true)
            setUnboundedHoverDay(day)
        },
        [measuredLeft, xScale],
    )

    const onTouchMove = React.useCallback(
        (e: React.TouchEvent<HTMLDivElement>) => {
            if (!hovering) {
                return
            }
            const pageX = e.touches[0].pageX
            const x = pageX - (measuredLeft || 0)
            const day = Math.round(xScale.invert(x))
            setUnboundedHoverDay(day)
            // Intentionally permit propogation to continue, we don't want to interfere with scroll.
            return true
        },
        [measuredLeft, xScale, hovering],
    )

    const onTouchEnd = React.useCallback(() => {
        setHovering(false)
    }, [])

    return {
        hoverDay,
        handlerProps: {onMouseOver, onMouseOut, onMouseMove, onTouchStart, onTouchEnd, onTouchMove},
    }
}
