import * as React from 'react'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {dateFormatNoDay} from '~/global/utils/format-date/formatDate'
import {PDSData} from '~/store/order/types'
import GoogleDriveDocumentViewer from '../google-drive-document-viewer/GoogleDriveDocumentViewer'

interface Props {
    pdsData: PDSData
    submitPDSAgreement: () => Promise<void | Error>
}

const PDSDisplay: React.FunctionComponent<Props> = ({pdsData, submitPDSAgreement}) => {
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    if (!pdsData) {
        return <WeSlippedUp />
    }

    const {drive_id, document_title, publication_date} = pdsData
    const title = `Read and accept the ${document_title} PDS`
    const subTitle = `Updated: ${publication_date.toFormat(dateFormatNoDay)}`
    const onDriveButtonClick = () => {
        setIsSubmitting(true)
        submitPDSAgreement()
    }

    return (
        <GoogleDriveDocumentViewer
            title={title}
            subTitle={subTitle}
            onButtonClick={onDriveButtonClick}
            isButtonLoading={isSubmitting}
            buttonLabel="I have read and accept the PDS"
            documentUrl={`https://drive.google.com/file/d/${drive_id}/preview`}
            dataTestId="button--accept-pds"
        />
    )
}
export default PDSDisplay
