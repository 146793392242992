import React from 'react'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {useNavigate} from '~/migrate-react-router'
import {
    ExchangeRateChangeWalletError,
    ExchangeRateChangeContinueError,
} from '~/sections/invest/sections/order-flow/widgets/modals/ExchangeRateErrorModals'
import {StagedApplication} from '~/store/order/types'

export const ApplicationErrors: React.FunctionComponent<ApplicationErrorsProps> = ({
    stagedApplication,
    isDependent,
    preferredName,
    page,
    resetError,
}) => {
    const navigate = useNavigate()
    const notificationContext = React.useContext(NotificationContext)
    const {showModalError, closeModalError} = notificationContext
    const [showExchangeRateContinueError, setShowExchangeRateContinueError] = React.useState(false)
    const [showExchangeRateWalletError, setShowExchangeRateWalletError] = React.useState(false)

    const onClose = () => {
        resetError()
        closeModalError()
    }

    React.useEffect(() => {
        if (!stagedApplication.error) {
            return
        }

        switch (stagedApplication.error) {
            case 'insufficient_funds':
                showModalError({
                    message: (
                        <p>
                            We haven’t been able to place your order. This might happen if you were trying to place
                            multiple orders at once. We’ll take you back to the order page so you can double check the
                            amounts.
                        </p>
                    ),
                    onClose: () => onClose,
                    primaryButton: {
                        label: 'Ok',
                        onClick: () => {
                            onClose()
                            if (page === 'confirm') {
                                navigate(-1)
                            }
                        },
                    },
                    title: `Hmm, it looks like there isn’t enough money in ${
                        isDependent ? preferredName + '’s' : 'your'
                    }{' '}
                    Wallet`,
                })
                break
            case 'fx_insufficient_funds':
                if (page === 'confirm') {
                    setShowExchangeRateWalletError(true)
                }
                break
            case 'fx_rate_changed':
                if (page === 'confirm') {
                    setShowExchangeRateContinueError(true)
                }
                break
            default:
                showModalError({
                    message: <p>We couldn’t complete the application, please try again.</p>,
                    onClose: () => onClose,
                    primaryButton: {
                        label: 'Ok',
                        onClick: onClose,
                    },
                })
        }
    }, [stagedApplication])

    return (
        <>
            {showExchangeRateWalletError && (
                <ExchangeRateChangeWalletError
                    onClick={() => {
                        resetError()
                        navigate(-1)
                    }}
                />
            )}
            {showExchangeRateContinueError && (
                <ExchangeRateChangeContinueError
                    onContinueClick={() => {
                        resetError()
                        setShowExchangeRateContinueError(false)
                    }}
                    onChangeClick={() => {
                        resetError()
                        navigate(-1) // the costing form takes care of resetting the staged order state
                    }}
                />
            )}
        </>
    )
}

interface ApplicationErrorsProps {
    stagedApplication: StagedApplication
    isDependent: boolean
    preferredName: string
    instrumentName: string
    page: 'form' | 'confirm'
    resetError(): void
}

export default ApplicationErrors
