import {Document} from '@contentful/rich-text-types'
import React from 'react'
import {RichText} from '~/sections/explore/sections/learn-articles/widgets/rich-text/RichText'
import NZPrivacyPolicy from '~/sections/user/sections/terms-and-conditions/assets/contentful-entries/privacy-policy.json'
import {legalDoc} from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/LegalDocuments.scss'

export default () => {
    return (
        <div className={legalDoc}>
            <RichText richTextDocument={NZPrivacyPolicy.fields.content as Document} />
        </div>
    )
}
