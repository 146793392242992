import React from 'react'
import {Model} from '~/api/retail/types'
import {accessibility} from '~/global/scss/helpers'
import capitalise from '~/global/utils/capitalise-string/capitaliseString'
import {isOnNZX} from '~/global/utils/is-on-exchange/isOnExchange'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {ChevronDown} from '~/global/widgets/OLD_icons'
import BuyOrSellCurrentPriceWithModal from '~/global/widgets/buy-or-sell-current-price-with-modal/BuyOrSellCurrentPriceWithModal'
import MarketDepthLink from '~/global/widgets/nzx-market-depth-link/MarketDepthLink'
import {getLabelByBuyOrderType} from '~/sections/invest/sections/order-flow/utils/get-label-by-buy-order-type/getLabelByBuyOrderType'
import {getLabelBySellOrderType} from '~/sections/invest/sections/order-flow/utils/get-label-by-sell-order-type/getLabelBySellOrderType'
import {connect} from '~/store/connect'
import {Instrument} from '~/store/instrument/types'
import {StagedBuyOrder, StagedSellOrder} from '~/store/order/types'
import styles from './OrderFormHeader.scss'

const FormHeader: React.FunctionComponent<FormHeaderProps> = ({
    instrument,
    buyOrderType,
    sellOrderType,
    buyOrSell,
    jurisdiction,
    onOrderTypeClick,
}) => {
    const buyOrderTypeLabel = buyOrderType ? getLabelByBuyOrderType(buyOrderType, instrument) : undefined
    const sellOrderTypeLabel = sellOrderType ? getLabelBySellOrderType(sellOrderType, instrument) : undefined

    return (
        <div className={styles.orderFormHeader}>
            <div className={styles.headerLinks}>
                {tradingType(instrument) === 'listed' && buyOrSell === 'buy' && buyOrderTypeLabel && (
                    <span>
                        <button
                            onClick={onOrderTypeClick}
                            type="button"
                            data-testid="order-type"
                            className={accessibility.button}
                        >
                            {capitalise(buyOrderTypeLabel)} <ChevronDown />
                        </button>
                    </span>
                )}
                {tradingType(instrument) === 'listed' && buyOrSell === 'sell' && sellOrderTypeLabel && (
                    <span>
                        <button
                            onClick={onOrderTypeClick}
                            type="button"
                            data-testid="order-type"
                            className={accessibility.button}
                        >
                            {capitalise(sellOrderTypeLabel)} <ChevronDown />
                        </button>
                    </span>
                )}
                {isOnNZX(instrument) && jurisdiction === 'nz' && (
                    <MarketDepthLink
                        instrumentSlug={instrument.urlSlug}
                        tradingType={tradingType(instrument)}
                        type="market-or-limit-selector"
                    />
                )}
            </div>

            <h1>{instrument.name}</h1>
            <BuyOrSellCurrentPriceWithModal instrument={instrument} orderType={buyOrderType || sellOrderType} />
        </div>
    )
}

interface StoreProps {
    buyOrderType?: StagedBuyOrder['orderType']
    sellOrderType?: StagedSellOrder['orderType']
    jurisdiction: Model.User['jurisdiction']
}

interface OwnProps {
    instrument: Instrument
    buyOrSell: 'buy' | 'sell'
    onOrderTypeClick(): void
}

type FormHeaderProps = StoreProps & OwnProps

export default connect<StoreProps, {}, OwnProps>(state => {
    const {order} = state

    return {
        buyOrderType: order.stagedBuyOrder ? order.stagedBuyOrder.orderType : undefined,
        sellOrderType: order.stagedSellOrder ? order.stagedSellOrder.orderType : undefined,
        jurisdiction: state.identity.user!.jurisdiction,
    }
})(FormHeader)
