import {stripProfile} from '~/global/utils/strip-profile/stripProfile'

export const isInternalLink = (link = '') => {
    return link.charAt(0) === '/'
}

export const getCurrentProfileSlug = () => {
    return window.location.pathname.split('/')[2]
}

/**
 * Strips the origin from a URL if it is a known origin - one of our own domains, or the current origin.
 *
 * Returns the URL as it was if it is not a known origin, or if it's a relative link.
 */
export const stripKnownOrigin = (url: string = '') => {
    const KNOWN_ORIGINS = [window.location.origin, 'app.sharesies.com', 'app.sharesies.nz', 'uat.opsies']

    // new URL cannot be created from a relative link
    if (url.charAt(0) !== '/') {
        try {
            const urlObj = new URL(url)

            // If the url includes the current origin or is part of uat.opsies, then strip the host
            // as we're linking to an internal product page and want to use React router
            if (KNOWN_ORIGINS.includes(urlObj.hostname)) {
                return urlObj.href.replace(urlObj.origin, '')
            }
        } catch {
            // If the URL isn't parsable, return it as is
        }
    }

    return url
}

/**
 * Standardise a URL from an external source (e.g. Contentful, retail-backend, Braze) for consistency:
 *
 * - Remove the origin if it's a known origin
 * - Remove any existing profile slug
 * - Add the current profile slug if it's an internal link
 *
 * @param {string} url - incoming url
 * @returns {string} - normalised url
 */
export const normalizeUrl = (url: string) => {
    // First strip the known origin if there is one
    let cleanUrl = stripKnownOrigin(url)

    if (cleanUrl.startsWith('mailto:')) {
        return cleanUrl
    }

    // Normalise a preceeding slash if it's missing
    if (!isInternalLink(cleanUrl) && !cleanUrl.startsWith('http')) {
        cleanUrl = `/${cleanUrl}`
    }

    if (!isInternalLink(cleanUrl)) {
        return cleanUrl
    }

    return `/profile/${getCurrentProfileSlug()}${stripProfile(cleanUrl)}`
}
