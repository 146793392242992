import {ChevronRight} from '@design-system/icon'
import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {useNavigate} from 'react-router'
import {Link} from 'react-router-dom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {convertToKebabCase} from '~/global/utils/convert-to-kebab-case/convertToKebabCase'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {SwitchField} from '~/global/widgets/form-controls'
import {DollarValue, ItemValue} from '~/global/widgets/number-elements/NumberElements'
import {OrderConfirmation} from '~/global/widgets/order-confirmation/OrderConfirmation'
import essActions from '~/store/employeeShareScheme/actions'
import {EmployeeShareSchemeAllocation, ShareRightAllocation} from '~/store/employeeShareScheme/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import styles from './UpcomingAllocations.scss'

interface Props {
    instrument: Instrument
    allocationsForInstrument: EmployeeShareSchemeAllocation
}

const UpcomingShareRightAllocations: React.FunctionComponent<Props> = ({instrument, allocationsForInstrument}) => {
    const dispatch = useAppDispatch()
    const showCurrentDollarValue = useAppSelector(s => s.employeeShareScheme.showShareRightAllocationCurrentValue)

    // group the allocations by year
    const groupedAllocations = allocationsForInstrument.share_rights_allocations
        .filter(alloc => !!alloc.vesting_due_year)
        .sort((a, b) => parseFloat(a.vesting_due_year!) - parseFloat(b.vesting_due_year!))
        .reduce(
            function (groups, alloc) {
                ;(groups[alloc.vesting_due_year!] = groups[alloc.vesting_due_year!] || []).push(alloc)
                return groups
            },
            {} as {[key: string]: ShareRightAllocation[]},
        )

    return (
        <>
            <div className={spacing.spaceBelow8}>
                <OrderConfirmation
                    items={[
                        {
                            description: (
                                <div>
                                    Total number of share rights
                                    <ModalLink
                                        dataTestId="modal-link--total-number-of-share-rights"
                                        label="Total number of share rights"
                                        asIcon
                                        modalTitle="Total number of share rights"
                                        primaryButton={{label: 'Close'}}
                                        helpIconSize={16}
                                    >
                                        This is the sum of all the share rights you’ve been allocated. The final number
                                        of shares you’ll receive depends on the testing outcome for each allocation. For
                                        more information, click each allocation, or view the testing criteria on{' '}
                                        <Link to={`/employee-share-scheme/${instrument.id}/schemes`}>
                                            your scheme information page
                                        </Link>
                                        .
                                    </ModalLink>
                                </div>
                            ),
                            value: (
                                <span className={styles.orderConfirmationValue}>
                                    <ItemValue value={allocationsForInstrument.total_unvested_share_rights} />
                                </span>
                            ),
                        },
                        {
                            description: (
                                <div>
                                    Current share price
                                    <ModalLink
                                        dataTestId="modal-link--total-number-of-share-rights"
                                        label="Current share price"
                                        asIcon
                                        modalTitle="Current share price"
                                        primaryButton={{label: 'Close'}}
                                        helpIconSize={16}
                                    >
                                        The price you see for exchange-traded investments is delayed by at least 20
                                        minutes when the market is open. The total estimated value will vary as the
                                        stock price changes.
                                    </ModalLink>
                                </div>
                            ),
                            value: (
                                <span className={styles.orderConfirmationValue}>
                                    <DollarValue value={instrument.marketPrice} currency={instrument.currency} />
                                </span>
                            ),
                        },
                    ]}
                    total={{
                        description: 'Total potential value',
                        value: (
                            <DollarValue
                                value={
                                    allocationsForInstrument.total_unvested_share_rights *
                                    parseFloat(instrument.marketPrice)
                                }
                                currency={instrument.currency}
                            />
                        ),
                    }}
                />
            </div>

            <h2 className={cn(styles.timeline, spacing.spaceAbove16)}>Timeline</h2>
            <SwitchField
                additionalClassName={spacing.spaceAbove4}
                dataTestId="switch--potential-value"
                label="Show potential dollar value"
                name="show potential dollar value"
                onChange={() => dispatch(essActions.ToggleShowShareRightAllocationCurrentValue())}
                value={showCurrentDollarValue}
                isTouched={false}
            />

            {Object.entries(groupedAllocations).map(([year, allocations]) => {
                // TODO - ECM-324 needs to support having more than one allocation line
                const orderedAllocations = allocations.sort(
                    (a, b) => a.lines[0].vesting_due_date.toMillis() - b.lines[0].vesting_due_date.toMillis(),
                )

                return (
                    <React.Fragment key={year}>
                        <h2
                            data-testid={`heading--vesting-in-${year}`}
                            className={cn(styles.vestingInHeading, spacing.spaceAbove24)}
                        >
                            Vesting in {year}
                        </h2>
                        {orderedAllocations.map(allocation => {
                            return (
                                <UpcomingShareRightAllocationLine
                                    key={allocation.id}
                                    allocation={allocation}
                                    instrument={instrument}
                                    showCurrentDollarValue={showCurrentDollarValue}
                                />
                            )
                        })}
                    </React.Fragment>
                )
            })}
        </>
    )
}

interface UpcomingShareRightAllocationLineProps {
    allocation: ShareRightAllocation
    instrument: Instrument
    showCurrentDollarValue: boolean
}

const UpcomingShareRightAllocationLine = React.forwardRef(
    (
        {allocation, instrument, showCurrentDollarValue}: UpcomingShareRightAllocationLineProps,
        ref: React.ForwardedRef<HTMLButtonElement>,
    ) => {
        const navigate = useNavigate()
        const profileUrl = useProfileUrl()
        // TODO - ECM-324 this needs to support having dividend topup allocations
        const testingDate = allocation.lines[0].testing_date
        const today = DateTime.now().startOf('day')
        const testingInProgress = today >= testingDate
        const allocationLineId = allocation.lines[0].id
        const shareRightsTotal = allocation.lines
            .map(line => line.number_of_share_rights)
            .reduce((total, current) => total + parseFloat(current))

        const getValue = () => {
            if (showCurrentDollarValue) {
                return parseFloat(shareRightsTotal) * parseFloat(instrument.marketPrice)
            }
            return shareRightsTotal
        }
        return (
            <button
                data-testid={`allocation--${convertToKebabCase(allocation.group_name.toLowerCase())}`}
                ref={ref}
                className={cn(styles.upcomingAllocationCard, spacing.spaceAbove8)}
                onClick={() => {
                    rudderTrack('ess_allocation', 'allocation_clicked', {
                        allocation_type: 'allocated',
                    })
                    navigate(
                        profileUrl('employee-share-scheme/allocation/:instrumentId/:allocationLineId', {
                            instrumentId: instrument.id,
                            allocationLineId,
                        }),
                    )
                }}
                type="button"
            >
                <div className={styles.iconAndTitle}>
                    <h4 className={cn(styles.title, spacing.spaceBelow8)}>{allocation.group_name}</h4>
                    <ChevronRight className={styles.iconRight} size={12} />
                </div>
                <div className={styles.descriptionAndAmount}>
                    <p>
                        {testingInProgress
                            ? 'Testing in progress'
                            : `Testing on ${testingDate.toFormat(dateFormatNoTime)}`}
                    </p>
                    <p>
                        {showCurrentDollarValue && <DollarValue value={getValue()} currency={instrument.currency} />}
                        {!showCurrentDollarValue && (
                            <>
                                <ItemValue value={getValue()} /> share rights
                            </>
                        )}
                    </p>
                </div>
            </button>
        )
    },
)

export default UpcomingShareRightAllocations
