import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {isWrapperApp} from '~/global/utils/is-wrapper-app/isWrapperApp'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {LearnModuleSlideContent} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {IntroSlides} from '~/global/widgets/intro-slides/IntroSlides'
import Page from '~/global/widgets/page/Page'
import topUpWallet1 from '~/sections/explore/widgets/carousels/assets/images/1-top-up-wallet.svg'
import topUpWallet2 from '~/sections/explore/widgets/carousels/assets/images/2-top-up-wallet.svg'
import searchInvestments from '~/sections/explore/widgets/carousels/assets/images/3-search-investments.svg'
import buyInvestments from '~/sections/explore/widgets/carousels/assets/images/4-buy-investments.svg'
import converted from '~/sections/explore/widgets/carousels/assets/images/5-converted.svg'
import save from '~/sections/explore/widgets/carousels/assets/images/6-save.svg'
import topUpWallet7 from '~/sections/explore/widgets/carousels/assets/images/7-top-up-wallet.svg'
import sharedStyles from '~/sections/invest/sections/bank-linking/sections/roundups-setup/pages/roundups-how-to/RoundupsHowTo.scss'
import styles from './ExploreCarousels.scss'

const HOW_SHARESIES_WORKS: LearnModuleSlideContent[] = [
    {
        additionalClassName: sharedStyles.roundupsSlide,
        image: topUpWallet1,
        imageAdditionalClassName: sharedStyles.roundupsSlideImage,
        bodyRenderer: () => (
            <p>
                To make an investment or transfer money to Save, you need to have money in your <strong>Wallet</strong>.
            </p>
        ),
    },
    {
        image: topUpWallet2,
        additionalClassName: sharedStyles.roundupsSlide,
        imageAdditionalClassName: sharedStyles.roundupsSlideImage,
        bodyRenderer: () => (
            <p>
                You can top up your Wallet by bank transfer or card—we cover the fee on your first card top-up, for
                top-up amounts up to $100!
            </p>
        ),
    },
    {
        additionalClassName: sharedStyles.roundupsSlide,
        image: searchInvestments,
        imageAdditionalClassName: sharedStyles.roundupsSlideImage,
        bodyRenderer: () => (
            <p>
                Search for investments in the <strong>Explore</strong> tab. You can filter by investment type, industry,
                exchange, and risk level.
            </p>
        ),
    },
    {
        additionalClassName: sharedStyles.roundupsSlide,
        image: buyInvestments,
        imageAdditionalClassName: sharedStyles.roundupsSlideImage,
        bodyRenderer: () => (
            <p>
                Once you’ve picked an investment, select <strong>Buy</strong> and enter the amount you want to invest.
            </p>
        ),
    },
    {
        additionalClassName: sharedStyles.roundupsSlide,
        image: converted,
        imageAdditionalClassName: sharedStyles.roundupsSlideImage,
        bodyRenderer: () => (
            <p>
                If you’re buying an Aussie or US investment, your money is converted to the relevant currency when you
                place your order.
            </p>
        ),
    },
    {
        additionalClassName: sharedStyles.roundupsSlide,
        image: save,
        imageAdditionalClassName: sharedStyles.roundupsSlideImage,
        bodyRenderer: ({profileUrl}) => (
            <p>
                Money in your Wallet can also be transferred to <strong>Save</strong> once you’ve{' '}
                <Link to={profileUrl('save')}>opened a Save account</Link>.
            </p>
        ),
    },
    {
        additionalClassName: sharedStyles.roundupsSlide,
        image: topUpWallet7,
        imageAdditionalClassName: sharedStyles.roundupsSlideImage,
        bodyRenderer: ({profileUrl}) => (
            <p>
                If you’re ready, <Link to={profileUrl('wallet/topup')}>top up your Wallet now</Link>. Otherwise, keep
                exploring.
            </p>
        ),
    },
]

/**
 * ExploreHowSharesiesWorks is paired with a Content card that allows users to navigate to it.
 *
 * @returns {JSX.Element} Explore carousel slides
 */
export const ExploreHowSharesiesWorks = (): JSX.Element => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const onComplete = () => {
        return navigate(profileUrl('explore'))
    }

    const onBack = () => {
        return navigate(-1)
    }

    return (
        <Page className={styles.exploreCarousels}>
            <IntroSlides
                content={HOW_SHARESIES_WORKS}
                flag="plink"
                onComplete={onComplete}
                onCompleteLabel="Got it!"
                onBack={onBack}
                disableClose={isWrapperApp()}
            />
        </Page>
    )
}
