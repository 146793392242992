/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum EsInstrumentType {
    etf = 'etf',
    mf = 'mf',
    equity = 'equity',
    etp = 'etp',
    rights = 'rights',
    warrants = 'warrants',
    options = 'options',
    unlisted_equity = 'unlisted_equity',
}

export function EsInstrumentTypeFromJSON(json: any): EsInstrumentType {
    return EsInstrumentTypeFromJSONTyped(json, false)
}

export function EsInstrumentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EsInstrumentType {
    return json as EsInstrumentType
}

export function EsInstrumentTypeToJSON(value?: EsInstrumentType | null): any {
    return value as any
}
