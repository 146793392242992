import Decimal from 'decimal.js'

export const calculateSharesRemaining = (
    shareAmountValue: string,
    holdingShares?: string,
    sharesAlreadyBeingSold?: number,
) => {
    const sharesToSellDecimal = shareAmountValue ? new Decimal(shareAmountValue) : new Decimal(0)
    const holdingSharesDecimal = holdingShares ? new Decimal(holdingShares) : new Decimal(0)
    const alreadySellingSharesDecimal = sharesAlreadyBeingSold ? new Decimal(sharesAlreadyBeingSold) : new Decimal(0)
    const zero = new Decimal(0)

    const sharesRemaining = holdingSharesDecimal
        .minus(alreadySellingSharesDecimal)
        .minus(sharesToSellDecimal)
        .lessThan(zero)
        ? zero
        : holdingSharesDecimal.minus(alreadySellingSharesDecimal).minus(sharesToSellDecimal)

    return sharesRemaining.toString()
}
