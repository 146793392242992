import {Button} from '@design-system/button'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {useNavigate} from '~/migrate-react-router'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import signUpActions from '~/store/sign-up/actions'

const VoluntaryResignCheck: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const taxTreatyStatus = useAppSelector(s => s.identity.user!.us_tax_treaty_status)
    const isDependent = useAppSelector(s => Boolean(s.identity.user?.is_dependent)) // force type to prevent error on logout
    const preferredName = useAppSelector(s => String(s.identity.user?.preferred_name)) // force type to prevent error on logout
    const USCertificationType = taxTreatyStatus === 'us' ? 'W9' : 'W-8BEN'

    return (
        <>
            <h1 className={spacing.spaceBelow16}>
                Do you need to edit {isDependent ? `${preferredName}’s` : 'your'} {USCertificationType} Form?
            </h1>
            <p>
                If the details on {isDependent ? `${preferredName}’s` : 'your'} form are wrong or have changed since you
                signed it{isDependent ? ` on their behalf` : ''}, edit and resubmit it.
            </p>
            <Button
                dataTestId="confirm-resign"
                label="Edit"
                onClick={async () => {
                    dispatch(signUpActions.initUSStateMachineVoluntaryResign())
                }}
                additionalClassName={spacing.spaceAbove16}
            ></Button>
            <Button
                dataTestId="cancel-resign"
                label="Cancel"
                onClick={() => {
                    navigate(-1)
                }}
                type="secondary"
                additionalClassName={spacing.spaceAbove16}
            ></Button>
        </>
    )
}

export default VoluntaryResignCheck
