import React from 'react'
import {Model} from '~/api/retail/types'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import HelpEmail from '~/global/widgets/help-email/HelpEmail'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {Link, useNavigate} from '~/migrate-react-router'
import {
    ExchangeRateChangeWalletError,
    ExchangeRateChangeContinueError,
} from '~/sections/invest/sections/order-flow/widgets/modals/ExchangeRateErrorModals'
import {Instrument} from '~/store/instrument/types'
import {StagedBuyOrder} from '~/store/order/types'

export const BuyErrors: React.FunctionComponent<BuyErrorsProps> = ({
    stagedBuyOrder,
    isDependent,
    preferredName,
    instrument,
    page,
    updateStagedBuyOrder,
    jurisdiction,
}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const notificationContext = React.useContext(NotificationContext)
    const {showModalError, closeModalError} = notificationContext
    const [showExchangeRateContinueError, setShowExchangeRateContinueError] = React.useState(false)
    const [showExchangeRateWalletError, setShowExchangeRateWalletError] = React.useState(false)
    const resetError = () => {
        if (stagedBuyOrder) {
            updateStagedBuyOrder({
                ...stagedBuyOrder,
                error: undefined, // Resetting error
            })
        }
    }

    const onClose = () => {
        resetError()
        closeModalError()
    }

    React.useEffect(() => {
        if (!stagedBuyOrder.error) {
            return
        }

        switch (stagedBuyOrder.error) {
            case 'already_selling':
                showModalError({
                    message: (
                        <>
                            <p>
                                {isDependent ? `${preferredName}’s` : 'Your'} buy order for {instrument.name} can’t be
                                placed because {isDependent ? `${preferredName} has` : 'you’ve'} also got a pending sell
                                order for this investment.
                            </p>
                            <p>
                                {isDependent ? preferredName : 'You'} can place a sell order for this investment when{' '}
                                {isDependent ? `${preferredName}’s` : 'your'} buy order is complete, or you cancel it.
                            </p>
                        </>
                    ),
                    onClose: () => onClose,
                    primaryButton: {
                        label: 'Ok',
                        onClick: onClose,
                    },
                    title: 'Can’t buy and sell at the same time',
                })
                break
            case 'too_many_pending':
                showModalError({
                    message: (
                        <>
                            <p>
                                {isDependent ? `${preferredName}’s` : 'Your'} order for {instrument.name} can’t be
                                placed because {isDependent ? `${preferredName} has` : 'you’ve'} already got two pending
                                orders for this investment.
                            </p>
                            <p>
                                {isDependent ? preferredName : 'You'} can place another order for this investment when{' '}
                                {isDependent ? `${preferredName} has` : 'you have'} less than two pending orders.
                            </p>
                        </>
                    ),
                    onClose: () => onClose,
                    primaryButton: {
                        label: 'Ok',
                        onClick: onClose,
                    },
                    title: 'Too many orders for this investment',
                })
                break
            case 'limit_number_of_funds':
                showModalError({
                    message: (
                        <>
                            <p>
                                {isDependent ? `${preferredName}’s` : 'You’re'} seeing this message because{' '}
                                {isDependent ? `${preferredName}’s` : 'you’re'} trying to buy your 501st investment,
                                which is awesome!
                            </p>
                            <p>
                                Unfortunately, {isDependent ? `${preferredName}` : 'you'} can only have a maximum of 500
                                investments in {isDependent ? `${preferredName}’s` : 'your'} Portfolio. To buy{' '}
                                {instrument.name}, {isDependent ? `${preferredName}’ll` : 'you’ll'} have to sell out of
                                another investment. We know this sucks, and we’re working to increase this number as
                                soon as we can.
                            </p>
                            <p>
                                As a {jurisdiction === 'nz' ? 'koha' : 'gift'} for the inconvenience get in touch with
                                us at <HelpEmail /> and we’ll sort you out with some Sharesies swag.
                            </p>
                        </>
                    ),
                    onClose: () => onClose,
                    primaryButton: {
                        label: 'Ok',
                        onClick: onClose,
                    },
                    title: 'We’re really sorry, you’ve hit the maximum number of investments',
                })
                break

            case 'need_participant_email':
                showModalError({
                    message: (
                        <>
                            <p>
                                Uh oh. {isDependent ? preferredName : 'You'} can’t place orders for exchange-traded
                                companies and ETFs until {isDependent ? `${preferredName} has` : 'you’ve'} given us some
                                more info.{' '}
                            </p>

                            <p>
                                You’ve told us that {isDependent ? `${preferredName} is` : 'you are'} a Prescribed
                                Person. This means we need to email {isDependent ? 'their' : 'your'} investing activity{' '}
                                {isDependent ? '' : '(and investing activity for any Kids accounts you manage)'} to the
                                employer.
                            </p>
                        </>
                    ),
                    onClose: () => onClose,
                    primaryButton: {
                        label: 'Add email now',
                        onClick: onClose,
                    },
                    title: 'We need some more info',
                })
                break
            case 'too_many_currency_conversions':
                showModalError({
                    message: (
                        <>
                            <p>
                                Sorry we haven’t quite built this bit! This means {isDependent ? preferredName : 'you'}{' '}
                                can only place orders that involve exchanging one currency.
                            </p>

                            <p>
                                {isDependent ? preferredName : 'You'} will need to{' '}
                                <Link to={profileUrl('wallet/exchange-money')}>exchange the money you want to use</Link>{' '}
                                so that it is in the currency of this investment and/or up to one other currency.
                            </p>
                        </>
                    ),
                    onClose: () => onClose,
                    primaryButton: {
                        label: 'Ok, got it',
                        onClick: onClose,
                    },
                    title: 'You can only exchange one currency when placing this order',
                })
                break
            case 'insufficient_funds':
                showModalError({
                    message: (
                        <>
                            <p>
                                We haven’t been able to place your order. This might happen if you were trying to place
                                multiple orders at once. We’ll take you back to the order page so you can double check
                                the amounts.
                            </p>
                        </>
                    ),
                    onClose: () => onClose,
                    primaryButton: {
                        label: 'Ok',
                        onClick: () => {
                            onClose()
                            if (page === 'confirm') {
                                navigate(-1)
                            }
                        },
                    },
                    title: `Hmm, it looks like there isn’t enough money in ${
                        isDependent ? preferredName + '’s' : 'your'
                    } Wallet`,
                })
                break
            case 'fx_insufficient_funds':
                if (page === 'confirm') {
                    setShowExchangeRateWalletError(true)
                }
                break
            case 'fx_rate_changed':
                if (page === 'confirm') {
                    setShowExchangeRateContinueError(true)
                }
                break
            case 'dividend_already_invested':
                showModalError({
                    message: (
                        <p>
                            This dividend has already been invested. If you like{' '}
                            <Link to={`/invest/${instrument.urlSlug}`} onClick={closeModalError}>
                                you can place a new buy order
                            </Link>{' '}
                            for {instrument.name}.
                        </p>
                    ),
                    onClose: () => onClose,
                    primaryButton: {
                        label: 'Got it',
                        onClick: closeModalError,
                    },
                    title: 'Dividend invested',
                })
                break
            default:
                showModalError({
                    message: <p>We couldn’t complete the order, please try again.</p>,
                    onClose: () => onClose,
                    primaryButton: {
                        label: 'Ok',
                        onClick: onClose,
                    },
                })
        }
    }, [stagedBuyOrder])

    return (
        <>
            {showExchangeRateWalletError && (
                <ExchangeRateChangeWalletError
                    onClick={() => {
                        resetError()
                        navigate(-1)
                    }}
                />
            )}
            {showExchangeRateContinueError && (
                <ExchangeRateChangeContinueError
                    onContinueClick={() => {
                        resetError()
                        setShowExchangeRateContinueError(false)
                    }}
                    onChangeClick={() => {
                        resetError()
                        navigate(-1) // the costing form takes care of resetting the staged order state
                    }}
                />
            )}
        </>
    )
}

interface BuyErrorsProps {
    stagedBuyOrder: StagedBuyOrder
    isDependent: boolean
    preferredName: string
    instrument: Instrument
    page: 'form' | 'confirm'
    updateStagedBuyOrder(order: StagedBuyOrder): void
    jurisdiction: Model.User['jurisdiction']
}

export default BuyErrors
