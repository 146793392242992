import React from 'react'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import AddressVerificationUpload from '~/sections/user/widgets/address-verification-upload/AddressVerificationUpload'
import {useAppDispatch} from '~/store/hooks'
import actions from '~/store/identity/actions'

export const AddressVerification: React.FunctionComponent<AddressVerificationProps> = ({
    fromEditAddress,
    fromTransfers,
}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const [processing, setProcessing] = React.useState<boolean>(false)
    const [files, setFiles] = React.useState<File[]>([])
    const [error, setError] = React.useState<string | undefined>(undefined)

    const onSuccess = () => {
        if (fromTransfers) {
            navigate(profileUrl('invest/portfolio-transfer-shares/us/account-verification-pending'))
        } else {
            navigate(profileUrl('settings/personal-details/edit-address/address-uploaded'))
        }
    }

    const onSkip = () => {
        navigate(profileUrl('settings/personal-details'))
    }

    const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        setProcessing(true)
        try {
            await dispatch(actions.AddressVerification(files))
            setProcessing(false)
            onSuccess()
        } catch (e) {
            setProcessing(false)
            setError('Could not upload your proof of identity document')
        }
    }

    const onChange = (mutate: (files: File[]) => File[]) => {
        setFiles(mutate(files))
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--address-verification" leftButton="back" />
            <Page>
                <AddressVerificationUpload
                    error={error}
                    fromEditAddress={fromEditAddress}
                    fromTransfers={fromTransfers}
                    inSignUp={false}
                    onChange={onChange}
                    onSuccess={onSuccess}
                    onSkip={onSkip}
                    processing={processing}
                    submitHandler={submitHandler}
                    files={files}
                />
            </Page>
        </>
    )
}

interface AddressVerificationProps {
    fromEditAddress?: boolean
    fromTransfers?: boolean
}

export default AddressVerification

export const EditAddressVerification = () => <AddressVerification fromEditAddress />
export const TransfersAddressVerification = () => <AddressVerification fromTransfers />
