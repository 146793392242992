import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useSignUpInitialDetail} from '~/sections/moose/data/retail'
import {SignUpStepCommonProps} from '~/sections/moose/sections/sign-up/SignUp'
import signupStyles from '~/sections/moose/sections/sign-up/SignUp.scss'

const MobileAuthComplete = ({signupId, progressStep, regressStep}: SignUpStepCommonProps) => {
    const signupInitialDetail = useSignUpInitialDetail(signupId)

    return (
        <>
            <Toolbar
                dataTestId="toolbar--fonterra-signup-mobile-auth"
                leftButton="back"
                onLeftButtonClick={regressStep}
                title="Your phone number has been verified"
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <p>We’ve received this number from Fonterra.</p>
                <div className={cn(signupStyles.card, spacing.spaceAbove16)}>
                    <p>
                        <strong>Mobile phone</strong>
                    </p>
                    <p className={spacing.spaceAbove8}>{signupInitialDetail.masked_phone}</p>
                </div>
                <Button
                    label="Next"
                    type="primary"
                    dataTestId="button--fonterra-token-next"
                    onClick={progressStep}
                    pageButton
                    isSubmit
                />
            </Page>
        </>
    )
}

export default MobileAuthComplete
