import React from 'react'
import AnonymousWrapper from '~/global/wrappers/AnonymousWrapper'
import AuthenticatedWrapper from '~/global/wrappers/AuthenticatedWrapper'
import {ProfileRedirect} from '~/global/wrappers/ProfileWrapper'
import AnonymousSplash from '~/sections/user/pages/anonymous-splash/AnonymousSplash'
import {useAppSelector} from '~/store/hooks'

/**
 * The root page is unique in that it's accessible both when logged in and when logged out, but it still needs to
 * support redirects to the sign up flow and to respect the forcePageReload query param. To support this, RootWrapper
 * dynamically switches between AuthenticatedWrapper and AnonymousWrapper depending on auth state.
 */
export const RootWrapper: React.FunctionComponent = () => {
    const user = useAppSelector(s => s.identity.user)
    return user ? <AuthenticatedWrapper /> : <AnonymousWrapper />
}

export const Root: React.FunctionComponent = () => {
    const user = useAppSelector(s => s.identity.user)

    if (user) {
        return <ProfileRedirect />
    }

    return <AnonymousSplash />
}
