import React from 'react'
import {useNavigate} from 'react-router-dom'
import NotFound from '~/global/pages/not-found/NotFound'
import {isLoadingOrUninitialised, isUninitialised} from '~/global/utils/is-loading/isLoading'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ListMenuItem} from '~/global/widgets/list-menu/ListMenu'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import essActions from '~/store/employeeShareScheme/actions'
import {useAppSelector, useAppDispatch} from '~/store/hooks'

interface Props {
    instrumentId: string
}

export const ShareSchemeDetailMenu: React.FunctionComponent<Props> = ({instrumentId}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const participationLoadingState = useAppSelector(s => s.employeeShareScheme.participationLoadingState)
    const shareSchemes = useAppSelector(s => s.employeeShareScheme.shareSchemeParticipation[instrumentId])
    const shareSchemeDetailById = useAppSelector(s => s.employeeShareScheme.shareSchemeDetailById)
    const activeShareSchemes = shareSchemes.filter(scheme => !scheme.exited_at)

    // redirect to scheme details if there is only one share scheme for this instrument
    React.useEffect(() => {
        if (activeShareSchemes?.length === 1) {
            navigate(
                profileUrl('employee-share-scheme/:instrumentId/:shareSchemeId', {
                    instrumentId,
                    shareSchemeId: activeShareSchemes[0].id,
                }),
                {replace: true},
            )
        }
    }, [activeShareSchemes])

    // fetch any missing scheme details
    React.useEffect(() => {
        if (isUninitialised(participationLoadingState)) {
            dispatch(essActions.FetchParticipatingShareSchemes())
        }
        activeShareSchemes?.forEach(scheme => {
            if (!Object.keys(shareSchemeDetailById).includes(scheme.id)) {
                dispatch(essActions.FetchShareSchemeDetail(scheme.id))
            }
        })
    }, [activeShareSchemes, shareSchemeDetailById])

    if (isLoadingOrUninitialised(participationLoadingState)) {
        return <Loading isPineapple />
    }

    if (!activeShareSchemes || activeShareSchemes.length === 0) {
        return <NotFound />
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--share-scheme-detail-menu" leftButton="back" title="Your share schemes" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                {activeShareSchemes.map((scheme, index) => {
                    return (
                        <ListMenuItem
                            key={index}
                            label={scheme.name}
                            onClick={() =>
                                navigate(
                                    profileUrl('employee-share-scheme/:instrumentId/:shareSchemeId', {
                                        instrumentId,
                                        shareSchemeId: scheme.id,
                                    }),
                                )
                            }
                        />
                    )
                })}
            </Page>
        </>
    )
}

export default ShareSchemeDetailMenu
