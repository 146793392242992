import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal/ModalLink'
import cn from 'classnames'
import Decimal from 'decimal.js'
import {useAtom} from 'jotai'
import React, {useState} from 'react'
import {Navigate, useNavigate} from 'react-router'
import {useRetailPost} from '~/api/query/retail'
import {Request} from '~/api/retail/types'
import {page, spacing, typographyOverrides} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ExchangeHours} from '~/global/widgets/exchange-hours/ExchangeHours'
import HighlightBlock from '~/global/widgets/highlight-block/HighlightBlock'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import OrderConfirmation from '~/global/widgets/order-confirmation/OrderConfirmation'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {MooseOrderPathParams} from '~/sections/moose/routes'
import {buyOrderAtom} from '~/sections/moose/sections/order/state'
import {calculateTimeoutDate} from '~/sections/moose/sections/order/utils/calculate-timeout-date/calculateTimeoutDate'
import {isUnsupportedInstrument} from '~/sections/moose/sections/order/utils/is-unsupported-instrument/isUnsupportedInstrument'
import {useFonterraPortfolio} from '~/sections/moose/state/local'
import styles from './TradingFacilityPage.scss'

type CostOrderResult = CostOrderFailure | CostOrderSuccess

interface CostOrderFailure {
    success: false
    code: string
}

interface CostOrderSuccess {
    success: true
    totalAmount: Decimal
    totalAmountBeforeFees: Decimal
    expectedFee: Decimal
}

export const TradingFacilityPage: React.FunctionComponent<MooseOrderPathParams> = ({
    portfolioId,
    urlSlug,
}: MooseOrderPathParams) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const [stagedOrder, updateStagedOrder] = useAtom(buyOrderAtom)
    const walletPortfolio = useFonterraPortfolio().wallet
    const [isProcessing, setIsProcessing] = useState(false)
    const costOrderQuery = useRetailPost({
        path: 'fonterra/portfolios/:portfolio_id/orders/cost-buy',
        pathParams: {portfolio_id: portfolioId},
    })

    if (isUnsupportedInstrument(urlSlug)) {
        return <Navigate to={profileUrl('fonterra/portfolios/:portfolioId', {portfolioId})} replace />
    }

    if (
        !stagedOrder ||
        stagedOrder.instrument.urlSlug !== urlSlug ||
        !stagedOrder.pricePerItem ||
        !stagedOrder.numberOfItems
    ) {
        return (
            <Navigate
                to={profileUrl('fonterra/portfolios/:portfolioId/invest/:urlSlug/buy/limit-order', {
                    portfolioId,
                    urlSlug,
                })}
                replace
            />
        )
    }

    if (!stagedOrder.useDelayedSettlement) {
        // We only need to be here if use trade now is in play
        return (
            <Navigate
                to={profileUrl('fonterra/portfolios/:portfolioId/invest/:urlSlug/:action/time-limit', {
                    portfolioId,
                    urlSlug,
                    action: 'buy',
                })}
                replace
            />
        )
    }

    const lengthOfTimeOnMarket = '5d'
    const timeoutDateToUse = calculateTimeoutDate(lengthOfTimeOnMarket)
    const pricePerItem = stagedOrder.pricePerItem
    const numberOfItems = stagedOrder.numberOfItems
    const estimatedTrade = stagedOrder.pricePerItem.mul(stagedOrder.numberOfItems)
    const walletBalance = walletPortfolio.balance
    const amountDue = estimatedTrade.minus(new Decimal(walletBalance))

    const items = [
        {
            description: 'Estimated trade',
            value: <DollarValue value={estimatedTrade.toString()} currency={stagedOrder.instrument.currency} />,
        },
        {
            description: 'Your Wallet',
            value: <DollarValue value={walletBalance.toString()} currency={stagedOrder.instrument.currency} />,
        },
    ]

    const costOrder = async (): Promise<CostOrderResult> => {
        try {
            const payload: Request.FonterraPortfolioOrderCostBuyRequest = {
                fund_id: stagedOrder.instrument.id,
                expire_end_of_day: timeoutDateToUse.toISODate(),
                use_delayed_settlement: true,
                order: {
                    type: 'fonterra_portfolio_share_limit',
                    limit_volume: numberOfItems,
                    limit_price: pricePerItem.toString(),
                },
            }
            const response = await costOrderQuery.mutateAsync(payload)
            if (response.type !== `fonterra_portfolio_order_cost_buy`) {
                return {success: false, code: 'error_unexpected_response_payload'}
            }

            return {
                success: true,
                totalAmount: new Decimal(response.total_amount),
                totalAmountBeforeFees: new Decimal(response.total_amount_before_fees),
                expectedFee: new Decimal(response.expected_fee),
            }
        } catch (error) {
            return {success: false, code: (error as {code: string}).code}
        }
    }

    const onClick = async () => {
        setIsProcessing(true)
        const result = await costOrder()

        if (result.success) {
            updateStagedOrder({
                ...stagedOrder,
                customUntilDate: undefined,
                lengthOfTimeOnMarket,
                timeoutDateToUse,
                totalAmount: result.totalAmount,
                totalAmountBeforeFees: result.totalAmountBeforeFees,
                expectedFee: result.expectedFee,
            })
            navigate(
                profileUrl('fonterra/portfolios/:portfolioId/invest/:urlSlug/buy/trading-facility-confirm', {
                    portfolioId,
                    urlSlug,
                }),
            )
        } else {
            Toast('We couldn’t process your request')
        }
        setIsProcessing(false)
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--trading-facility"
                leftButton="back"
                title="Trading Facility"
                rightSlot={
                    <>
                        <div className={styles.exchangeHours}>
                            <ExchangeHours instrument={stagedOrder.instrument} />
                        </div>
                    </>
                }
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <p>
                    The cost of this trade exceeds your Wallet’s balance, but you can still make the trade now if you
                    agree to pay the outstanding balance within 2 trading days.
                </p>

                <div className={spacing.spaceAbove24}>
                    <OrderConfirmation
                        items={items}
                        total={{
                            description: 'Amount due',
                            value: (
                                <DollarValue value={amountDue.toString()} currency={stagedOrder.instrument.currency} />
                            ),
                        }}
                    />
                </div>

                <HighlightBlock className={styles.explanation}>
                    <div>
                        <h2 className={typographyOverrides['as-h3']}>Availability of the trading facility</h2>
                        <p>
                            The trading facility is provided at Fonterra and Sharesies’ discretion and may not always be
                            available. In addition to the Farmer Terms, there are terms for using the facility (see the
                            confirmation page), as well as eligibility criteria and limits (including an individual buy
                            value of no more than $20,000, and an aggregated limit across all trades).
                        </p>
                    </div>
                    <div>
                        <h2 className={typographyOverrides['as-h3']}>
                            How does the trading facility work?{' '}
                            <ModalLink
                                dataTestId="modal-link--trading-facility"
                                label="How does the trading facility work?"
                                asIcon
                                modalTitle="How does the trading facility work?"
                                primaryButton={{label: 'Ok'}}
                                helpIconSize={16}
                            >
                                <p>
                                    Usually, your Wallet needs to contain all of the funds needed to buy FCG shares at
                                    the time you place the order. If it’s available, you may be able to place the buy
                                    order using the trading facility instead (we may charge a fee). Using the trading
                                    facility means your Wallet does not need to contain all of the required funds when
                                    you place the buy order. Instead, you must deposit the amount that’s owed for the
                                    trade within 2 trading days.
                                </p>
                            </ModalLink>
                        </h2>
                    </div>
                </HighlightBlock>
            </Page>
            <ActionBar className={cn(page.flexRow, styles.formFooter)}>
                <Button dataTestId="button--next" label="Next" onClick={onClick} processing={isProcessing} />
            </ActionBar>
        </>
    )
}
