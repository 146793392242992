import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {Profile, useProfile, useProfiles} from '~/global/state-hooks/retail/useProfile'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {useCurrentSubscriptionPlan} from '~/global/utils/subscription-hooks/subscriptionHooks'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useShowIntercom} from '~/global/utils/use-show-intercom/useShowIntercom'
import AccountVerificationRequired from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import EmailVerificationRequired from '~/global/widgets/email-verification-required-alert/EmailVerificationRequired'
import {ListMenuGroup, ListMenuItem} from '~/global/widgets/list-menu/ListMenu'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {useAppSelector} from '~/store/hooks'

/**
 * For a given profile this determines if we should show them the personal or business details screen.
 *
 * This sounds like a trivial decision based on owner.type, but in reality the personal settings screen still heavily
 * relies on the existance of a legacy customer object (which Fonterra sole traders don't have)
 */
export function settingsScreenVersion(profile: Profile) {
    if (profile.legacy_profile_type) {
        return 'personal'
    } else {
        return 'business'
    }
}

export const Settings: React.FC = () => {
    const showIntercom = useShowIntercom()
    const navigate = useNavigate()
    const jurisdiction = useAppSelector(s => s.identity.user?.jurisdiction)
    const accountRestricted = useAppSelector(s => s.identity.user!.account_restricted)
    const isDependent = useAppSelector(s => s.identity.user?.is_dependent)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)
    const [currentSubscription] = useCurrentSubscriptionPlan()
    const profileUrl = useProfileUrl()
    const owner = useProfileOwner()
    const profile = useProfile()
    const profiles = useProfiles()

    // TODO: I&S remove flag
    const hasStaffFlag = useAppSelector(s => s.identity.flags.sharesies_staff)

    // TODO: Shared Money: close account needs TLC before any multi-owner account can be closed
    const anyProfileHasFonterra = profiles.some(profile =>
        profile.portfolios.some(portfolio => portfolio.product === 'FONTERRA'),
    )

    return (
        <>
            <Toolbar dataTestId="toolbar--account" leftButton="menu" title="Settings" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <AccountVerificationRequired />
                <EmailVerificationRequired />

                <ListMenuGroup heading={isDependent ? preferredName : 'Portfolio'} className={spacing.spaceBelow32}>
                    {settingsScreenVersion(profile) === 'personal' && (
                        <ListMenuItem
                            label="Personal details"
                            onClick={() => navigate(profileUrl('settings/personal-details'))}
                        />
                    )}
                    {settingsScreenVersion(profile) === 'business' && (
                        <ListMenuItem
                            label="Business details"
                            onClick={() => navigate(profileUrl('settings/business-details'))}
                        />
                    )}
                    <ListMenuItem
                        label={owner.owner_type === 'PERSON' ? 'Bank accounts and cards' : 'Bank accounts'}
                        onClick={() => navigate(profileUrl('settings/bank-accounts-cards'))}
                    />
                    <ListMenuItem label="Tax details" onClick={() => navigate(profileUrl('settings/tax-details'))} />

                    {hasStaffFlag && jurisdiction === 'au' && (
                        <ListMenuItem
                            label="Download documents"
                            onClick={() => navigate(profileUrl('settings/download-documents'))}
                        />
                    )}
                    {profile.legacy_profile_type && (
                        <ListMenuItem
                            label="Pricing plans"
                            rightLabel={currentSubscription && 'Active'}
                            onClick={() => {
                                rudderTrack('plans', 'plan_page_hit', {link: 'account'})
                                navigate(profileUrl('settings/plan'))
                            }}
                        />
                    )}
                    {isDependent && (
                        <ListMenuItem
                            label="Transfer age"
                            onClick={() => navigate(profileUrl('settings/transfer-age'))}
                        />
                    )}
                </ListMenuGroup>

                <ListMenuGroup heading="Account" className={spacing.spaceBelow32}>
                    <ListMenuItem
                        label="Contact details"
                        onClick={() => navigate(profileUrl('settings/contact-details'))}
                    />
                    {profile.legacy_profile_type !== undefined && (
                        <ListMenuItem
                            label="Notifications"
                            onClick={() => navigate(profileUrl('settings/notifications'))}
                        />
                    )}
                    <ListMenuItem
                        label="Change password"
                        onClick={() => navigate(profileUrl('settings/change-password'))}
                    />
                    <ListMenuItem
                        label="Two-factor authentication (2FA)"
                        onClick={() => navigate(profileUrl('settings/security'))}
                    />
                </ListMenuGroup>

                {owner.owner_type === 'PERSON' && (
                    <ListMenuGroup
                        heading={`Share the ${jurisdiction === 'nz' ? 'aroha' : 'love'}`}
                        className={spacing.spaceBelow32}
                    >
                        <ListMenuItem label="Refer a friend" onClick={() => navigate(profileUrl('referrals'))} />
                        <ListMenuItem label="Buy a gift voucher" onClick={() => navigate(profileUrl('gifts'))} />
                        {/* don't show kids account to jurisdictions other than nz */}
                        {!isDependent && jurisdiction === 'nz' && (
                            <ListMenuItem
                                label="Create Kids Account"
                                onClick={() => navigate(profileUrl('settings/create-kids-account'))}
                                disabled={accountRestricted}
                            />
                        )}
                    </ListMenuGroup>
                )}

                <ListMenuGroup heading="Support" className={spacing.spaceBelow32}>
                    <ListMenuItem
                        label={owner.is_fonterra_customer ? 'Help using Sharesies' : 'Send us a message'}
                        onClick={showIntercom}
                    />
                    {!owner.is_fonterra_customer && (
                        <ListMenuItem
                            label="Request a feature"
                            onClick={() => navigate(profileUrl('settings/request-feature'))}
                        />
                    )}
                    {owner.is_fonterra_customer && (
                        <ListMenuItem
                            label="Fonterra contact details"
                            onClick={() => navigate(profileUrl('settings/fonterra-contact-details'))}
                        />
                    )}
                    {owner.owner_type === 'PERSON' && (
                        <ListMenuItem label="Learn the basics" onClick={() => navigate(profileUrl('explore/learn'))} />
                    )}
                    <ListMenuItem label="Legal information" onClick={() => navigate(profileUrl('legal'))} />
                    {jurisdiction === 'au' ? (
                        <ListMenuItem label="Complaints" externalLink="https://sharesies.com.au/complaints" />
                    ) : null}
                    {!anyProfileHasFonterra && (
                        <ListMenuItem
                            label="Close my account"
                            onClick={() => navigate(profileUrl('settings/close-account'))}
                        />
                    )}
                </ListMenuGroup>
            </Page>
        </>
    )
}

export default Settings
