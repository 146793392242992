import {ChevronRight} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {accessibility} from '~/global/scss/helpers'
import {convertToKebabCase} from '~/global/utils/convert-to-kebab-case/convertToKebabCase'
import styles from './ListMenu.scss'

interface ListMenuItemProps {
    disabled?: boolean
    label: React.ReactNode
    leftIcon?: JSX.Element
    onClick?: () => void
    externalLink?: string
    rightLabel?: React.ReactNode
    className?: string
}

export const ListMenuItem = ({
    disabled,
    label,
    leftIcon,
    onClick,
    externalLink,
    rightLabel,
    className,
}: ListMenuItemProps) => {
    const classes = cn(styles.listMenuItem, {[styles.clickable]: !!onClick, [styles.disabled]: disabled}, className)

    if (externalLink) {
        return (
            <li data-testid={`list-menu-item--${convertToKebabCase(label)}`} className={classes}>
                <a href={externalLink} target="_blank" type="button" className={accessibility.button} rel="noreferrer">
                    {leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
                    <span className={styles.labelText}>{label}</span>
                    {rightLabel && <span className={styles.rightLabel}>{rightLabel}</span>}
                    <span className={styles.rightIcon}>
                        <ChevronRight />
                    </span>
                </a>
            </li>
        )
    }
    return (
        <li data-testid={`list-menu-item--${convertToKebabCase(label)}`} className={classes}>
            <button
                type="button"
                onClick={!disabled ? onClick : undefined}
                className={accessibility.button}
                disabled={disabled}
            >
                {leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
                <span className={styles.labelText}>{label}</span>
                {rightLabel && <span className={styles.rightLabel}>{rightLabel}</span>}
                <span className={styles.rightIcon}>
                    <ChevronRight />
                </span>
            </button>
        </li>
    )
}

interface ListMenuGroupProps extends React.Attributes {
    heading?: string
    className?: string
    children?: React.ReactNode
}

export const ListMenuGroup = ({heading, children, className}: ListMenuGroupProps) => {
    return (
        <div className={cn(styles.listMenuGroup, className)}>
            {heading && <h2>{heading}</h2>}
            <ul>{children}</ul>
        </div>
    )
}
