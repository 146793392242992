import {colour as colourFn, Colour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import React from 'react'
import styles from './Delimiter.scss'

export default ({colour}: {colour?: Colour}) => {
    const colourMode = useColourMode()
    return (
        <span className={styles.delimiter} style={{color: colour ? colourFn(colour, colourMode) : undefined}}>
            |
        </span>
    )
}
