import {Button} from '@design-system/button'
import React from 'react'
import emailDark from '~/global/assets/images/envelope/envelope-dark.svg'
import emailLight from '~/global/assets/images/envelope/envelope-light.svg'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Image from '~/global/widgets/image/Image'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {Link, useNavigate} from '~/migrate-react-router'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/identity/actions'
import styles from './VerificationEmailSent.scss'

const VerificationEmailSent: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const dispatch = useAppDispatch()
    const [resendDisabled, setResendDisabled] = React.useState<boolean>(false)

    const [emailVerified, email] = useAppSelector(({identity}) => [
        identity.user!.email_verified,
        identity.user!.email || '',
    ])

    // Run once on component load
    React.useEffect(() => {
        dispatch(actions.VerifyEmail())
    }, [])

    React.useEffect(() => {
        if (emailVerified === true) {
            // Redirect back to account screen if Customer already email verified
            redirectBack()
        }
    }, [emailVerified])

    const handleClick = () => {
        // Temporarily disabled button on click to prevent spam
        setResendDisabled(true)
        setTimeout(() => {
            setResendDisabled(false)
        }, 5000)

        if (!resendDisabled) {
            dispatch(actions.VerifyEmail())
            Toast('Nice! Now hit the verification link we just emailed you to verify your email address.')
        }
    }

    const redirectBack = () => {
        sendWrapperAppMessage({type: 'goBack'})
        navigate(profileUrl('settings'))
    }

    return (
        <>
            <Toolbar
                leftButton="back"
                onLeftButtonClick={redirectBack}
                dataTestId="toolbar--email-verification-email-sent"
            />
            <Page className={styles.container}>
                <Image src={emailLight} darkSrc={emailDark} />
                <h1>You've got mail!</h1>
                <p>To verify your email address, hit the verification link we're emailing to {email}</p>
                <strong>Wrong email address?</strong>
                <Link
                    to={profileUrl('settings/personal-details')}
                    data-test-id="button--email-verification-update-email"
                >
                    Update it now
                </Link>
                <p>
                    Didn't get an email?{' '}
                    <Link to="#" onClick={handleClick}>
                        Resend it
                    </Link>
                </p>
                <Button
                    type="primary"
                    label="Continue"
                    pageButton
                    dataTestId="button--email-verification-next"
                    onClick={redirectBack}
                />
            </Page>
        </>
    )
}

export default VerificationEmailSent
