import {typeToTitleMapping} from '~/sections/save/sections/activity/utils/type-to-title-mapping/typeToTitleMapping'
import {Transaction} from '~/store/save/types'

export const transactionTitle = (transaction: Transaction) => {
    // Overwrite txn name for interest adjustment made as a result of an
    // incorrectly paid interest run
    if (transaction.description === 'Interest adjustment') {
        return transaction.description
    }

    return typeToTitleMapping[transaction.transaction_type]
}
