import cn from 'classnames'
import React from 'react'
import {accessibility} from '~/global/scss/helpers'
import {useRefSize} from '~/global/utils/use-ref-size/useRefSize'
import styles from './TruncatedText.scss'

const TruncatedText: React.FC<{maxHeight?: number; className?: string}> = ({maxHeight = 72, children, className}) => {
    const contentRef = React.useRef<HTMLDivElement | null>(null)
    const contentSize = useRefSize(contentRef)

    const isOverflowing = contentSize && contentSize.height > maxHeight

    const [shown, setShown] = React.useState(false)

    // If we want to animate expanding, set maxHeight to contentSize?.height and add a transition
    return (
        <>
            <div
                className={cn(styles.content, className, {[styles.shown]: !isOverflowing || shown})}
                style={{'--max-height': maxHeight + 'px'} as React.CSSProperties}
            >
                <div ref={contentRef}>{children}</div>
            </div>
            {isOverflowing && (
                <button
                    type="button"
                    className={cn(styles.expandButton, accessibility.button)}
                    role="presentation"
                    onClick={() => setShown(s => !s)}
                    // We want screenreaders to see the full contents of these sections (pretending they don't exist)
                    // That makes this button redundant, so let's hide it
                    aria-hidden
                >
                    {shown ? 'Show less' : 'Show more'}
                </button>
            )}
        </>
    )
}

/**
 * Prevent a link from being tabbed to if it's outside its parents bounds
 *
 * @example
 * <TruncatedText>
 *   <p>Some really long text that could overflow<p>
 *   <AnchorPreventTabbingWhenClipped href="">This link isn't focusable if it's hidden!</AnchorPreventTabbingWhenClipped>
 * </TruncatedText>
 */
export const AnchorPreventTabbingWhenClipped = ({tabIndex, ...props}: JSX.IntrinsicElements['a']) => {
    // I was really hoping to make this some sort of generic abomination, but alas, no luck
    const contentRef = React.useRef<HTMLAnchorElement | null>(null)
    const parentRef = React.useRef<HTMLElement | null>(null)

    const parentSize = useRefSize(parentRef)

    const isOverflowing = !!contentRef.current && !!parentSize && contentRef.current.offsetTop > parentSize.height
    return (
        <a
            ref={ref => {
                contentRef.current = ref
                parentRef.current = (ref?.offsetParent as HTMLElement | null | undefined) ?? null
            }}
            tabIndex={isOverflowing ? -1 : tabIndex}
            {...props}
        />
    )
}

export default TruncatedText
