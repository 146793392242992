import {Response, Model} from '~/api/retail/types'
import {EventDataTaxonomy} from '~/api/rudderstack/event-taxonomy/types'

export function isDIY(order: PartialDIYOrderDetails | PartialPremadeOrderDetails): order is PartialDIYOrderDetails {
    return 'allocations' in order
}

export type AutoinvestPremadeOrder = Response.AutoinvestPremadeOrderList['orders'][0]
export type AutoinvestDiyAuOrder = Response.AutoinvestPremadeOrderList['diy_au_orders']
export type AutoinvestOrderType = EventDataTaxonomy['autoinvest']['autoinvest_type_selected']['autoinvest_type']

export interface AutoinvestOrderAllocation {
    readonly fund_id: string
    readonly allocation: string
}

export interface PartialDIYOrderDetails {
    readonly allocations: AutoinvestOrderAllocation[]
    readonly amount?: string
    readonly interval?: Model.AutoinvestOrder['interval']
    readonly startDate?: string
}

interface PartialPremadeOrderDetails {
    readonly premadeOrder: AutoinvestPremadeOrder
    readonly amount?: string
    readonly interval?: Model.AutoinvestOrder['interval']
    readonly startDate?: string
}

export type DIYOrderDetails = Required<PartialDIYOrderDetails>
type PremadeOrderDetails = Required<PartialPremadeOrderDetails>

export interface PartialStagedOrder {
    readonly state: 'building'
    readonly isEdit: boolean
    readonly order: PartialDIYOrderDetails | PartialPremadeOrderDetails
    readonly pdsesToRead?: Response.AutoinvestOrderNeedReadPDS['pdses']
    readonly orderName?: string
    readonly orderImageId?: string
    readonly existingOrderId?: string
}

export interface ReadyStagedOrder {
    readonly state: 'staged' | 'submitting' | 'completed'
    readonly isEdit: boolean
    readonly order: DIYOrderDetails | PremadeOrderDetails
    readonly pdsesToRead?: Response.AutoinvestOrderNeedReadPDS['pdses']
    readonly transactionFee?: Response.AutoinvestCostBreakdown['expected_fee']
    readonly amountToInvest?: Response.AutoinvestCostBreakdown['amount_to_invest']
    readonly orderName?: string
    readonly orderImageId?: string
    readonly existingOrderId?: string
}

export type StagedOrder = PartialStagedOrder | ReadyStagedOrder

export interface State {
    readonly autoinvestOrders: Response.AutoinvestOrders['orders']
    readonly autoinvestLoadingState: 'uninitialised' | 'loading' | 'ready'
    readonly premadeOrdersState: 'uninitialised' | 'loading' | 'ready'
    readonly premadeOrders: AutoinvestPremadeOrder[]
    readonly diyAuEtfOptions: AutoinvestDiyAuOrder
    readonly stagedOrder?: StagedOrder
    readonly selectedDIYOrderId?: string | undefined
}
