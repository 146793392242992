import {Button} from '@design-system/button'
import Decimal from 'decimal.js'
import React from 'react'
import {Navigate} from 'react-router-dom'
import confirmStyles from '~/global/scss/reused-styles/confirm.scss'
import {usePortfolioItemsById} from '~/global/state-hooks/mixed-source/usePortfolioItemsById'
import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useInstruments} from '~/global/utils/use-instruments/useInstruments'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {getAnswerItems, pluralisedVote} from '~/global/widgets/corporate-action-description/corporateActionsV2'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {OrderConfirmation, OrderRow} from '~/global/widgets/order-confirmation/OrderConfirmation'
import orderProcessMessageStyles from '~/global/widgets/order-process-messages/OrderProcessMessage.scss'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {Toast} from '~/global/widgets/toast/Toast'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {useNavigate} from '~/migrate-react-router'
import ApplicationErrors from '~/sections/invest/sections/order-flow/sections/apply/widgets/apply-errors/ApplicationErrors'
import BuyConfirmExchange from '~/sections/invest/sections/order-flow/widgets/fx-confirm-exchange/ConfirmExchange'
import {selectInstrumentOrders} from '~/store/accounting/selectors'
import {VoluntaryCorporateActionV2} from '~/store/accounting/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import type {Instrument} from '~/store/instrument/types'
import actions from '~/store/order/actions'
import {StagedApplication} from '~/store/order/types'

const getPurchaseItems = (stagedApplication: StagedApplication): OrderRow[] => {
    const addSharesOutcome = stagedApplication.outcomes!.find(o => o.fundId && parseFloat(o.amountPerInputUnit) > 0)
    const removeCashOutcome = stagedApplication.outcomes!.find(o => o.currency && parseFloat(o.amountPerInputUnit) < 0)
    if (!addSharesOutcome || !removeCashOutcome) {
        return []
    }
    const sharePrice = new Decimal(removeCashOutcome.amountPerInputUnit)
        .neg()
        .div(addSharesOutcome.amountPerInputUnit)
        .toString()

    return [
        {
            description: 'Offer share price',
            value: <DollarValue value={sharePrice} currency={removeCashOutcome.currency} decimalPlaces={3} />,
        },
    ]
}

const getExerciseItems = (
    stagedApplication: StagedApplication,
    instrumentsById: Record<string, Instrument | null>,
): OrderRow[] => {
    const outcomes = stagedApplication.outcomes || []

    const addSharesOutcome = outcomes.find(o => o.fundId && parseFloat(o.amountPerInputUnit) > 0)
    // Options and warrants that are not the main share outcome
    const addOtherEntitlementsOutcomes = outcomes.filter(
        o => o.fundId && parseFloat(o.amountPerInputUnit) > 0 && o.fundId !== addSharesOutcome?.fundId,
    )
    const removeCashOutcome = outcomes.find(o => o.currency && parseFloat(o.amountPerInputUnit) < 0)
    const outcomeInstrument = addSharesOutcome?.fundId ? instrumentsById[addSharesOutcome.fundId] : null

    if (!addSharesOutcome || !removeCashOutcome || !outcomeInstrument) {
        return []
    }
    const offerPrice = new Decimal(removeCashOutcome.amountPerInputUnit).abs().toString()

    const otherEntitlementOutcomesOrderRows = addOtherEntitlementsOutcomes.reduce(
        (accumulator: OrderRow[], entitlement) => {
            if (entitlement.fundId) {
                const entitlementInstrument = instrumentsById[entitlement.fundId]

                if (entitlementInstrument) {
                    return [
                        ...accumulator,
                        {
                            description: `${outcomeInstrument.name} ${shareLabel({
                                instrument: entitlementInstrument,
                                isPlural: true,
                            })} you'll get`,
                            value: entitlement.grossAmount,
                        },
                    ]
                }
            }

            return accumulator
        },
        [],
    )

    return [
        {
            description: `${outcomeInstrument.name} ${shareLabel({
                instrument: outcomeInstrument,
                isPlural: true,
            })} applied for`,
            value: addSharesOutcome?.grossAmount,
        },
        ...otherEntitlementOutcomesOrderRows,
        {
            description: 'Offer share price',
            value: <DollarValue value={offerPrice} currency={removeCashOutcome.currency} decimalPlaces={3} />,
        },
    ]
}

const ApplyConfirm: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const notificationContext = React.useContext(NotificationContext)
    const [isSubmitting, setIsSubmitting] = React.useState(false)

    const dispatch = useAppDispatch()

    const stagedApplication = useAppSelector(store => store.order.stagedApplication)
    const instrument = useAppSelector(store =>
        store.order.stagedApplication
            ? store.instrument.instrumentsById[store.order.stagedApplication.fundId]
            : undefined,
    )
    const outcomeInstrumentIds = (stagedApplication?.applicationRule?.outcomes ?? [])
        .map(outcome => outcome.fund_id)
        .filter(id => id)
    const [instrumentsById, allInstrumentsLoaded] = useInstruments([stagedApplication?.fundId, ...outcomeInstrumentIds])
    const totalPortfolioItems = usePortfolioItemsById()
    const preferredName = useAppSelector(store => store.identity.user!.preferred_name)
    const isDependent = useAppSelector(store => store.identity.user!.is_dependent)

    const orders = useAppSelector(s =>
        stagedApplication ? selectInstrumentOrders(s, stagedApplication.fundId) : undefined,
    )
    const existingExerciseOrders = orders?.filter(
        (order): order is VoluntaryCorporateActionV2 =>
            order.type === 'corporate_action_v2' &&
            order.action_type === 'EXERCISE' &&
            order.application?.rule_id === stagedApplication?.applicationRule.application_rule_id &&
            order.application?.is_cancelled === false,
    )

    React.useEffect(() => {
        if (stagedApplication && stagedApplication.state === 'placed') {
            if (stagedApplication.applicationRule.type === 'VOTE') {
                Toast('Your votes have been cast! 🗳')
            } else {
                notificationContext.showRegularInvestorNotification()
            }

            if (shouldGoToNextDetail()) {
                navigate(
                    profileUrl('invest/:instrumentSlug/apply/:applicationRuleId/next', {
                        instrumentSlug: instrument!.urlSlug,
                        applicationRuleId: stagedApplication.applicationRule.application_rule_id,
                    }),
                )
            } else {
                // Go back to the view instrument page
                // If we came from another application, we have extra steps to navigate back
                stagedApplication.pushedFromOtherApplication ? navigate(-5) : navigate(-2)
            }
        }

        if (stagedApplication && stagedApplication.error) {
            setIsSubmitting(false)
        }

        // on unmount, clean up the staged order
        return () => {
            if (stagedApplication && stagedApplication.state === 'placed') {
                dispatch(actions.ClearStagedApplication())
            }
        }
    }, [stagedApplication])

    const handleSubmit = async () => {
        setIsSubmitting(true)
        try {
            await dispatch(actions.CreateApplication())
        } catch (error) {
            setIsSubmitting(false)
        }
    }

    const shouldGoToNextDetail = () => {
        const isExerciseType = stagedApplication?.applicationRule.type === 'EXERCISE'
        const isParentInstrumentInOutcomes =
            instrument?.parentInstrumentId && outcomeInstrumentIds.includes(instrument.parentInstrumentId)
        const hasSubsequentApplicationId =
            stagedApplication?.applicationRule.subsequent_share_purchase_application_rule_id
        // has the user exercised all their rights
        const outcomeForInstrument = instrument
            ? stagedApplication?.outcomes?.find(o => o.fundId === instrument!.id)
            : undefined

        const existingExerciseOrdersAmount = existingExerciseOrders
            ? existingExerciseOrders
                  .map(order => order.application.application_amount)
                  .reduce((total, current) => total + (current ? parseFloat(current) : 0), 0)
            : 0

        const exerciseAmount = outcomeForInstrument ? Math.abs(parseFloat(outcomeForInstrument.grossAmount)) : undefined

        const hasExercisedAll =
            instrument &&
            exerciseAmount &&
            exerciseAmount + existingExerciseOrdersAmount >= totalPortfolioItems[instrument.id].sharesOwned

        if (isExerciseType && isParentInstrumentInOutcomes && hasSubsequentApplicationId && hasExercisedAll) {
            return true
        }
        return false
    }

    if (!stagedApplication || !instrument) {
        return <Navigate to={profileUrl('explore')} />
    }

    if (!allInstrumentsLoaded) {
        return null
    }

    const {cashPayment, error, applicationRule} = stagedApplication

    const hasOutcomes = !!applicationRule.outcomes.length
    const removeCashOutcome = stagedApplication.outcomes!.find(o => o.currency && parseFloat(o.amountPerInputUnit) < 0)
    const applicationCurrency = removeCashOutcome?.currency ?? instrument.currency

    return (
        <>
            <Page>
                <div className={confirmStyles.header}>
                    <PageBack
                        onClick={() => {
                            dispatch(actions.UpdateStagedApplication({...stagedApplication, state: 'initialised'}))
                            navigate(-1)
                        }}
                    />
                    <h1>
                        {applicationRule.type === 'VOTE'
                            ? 'Review your ' + pluralisedVote(applicationRule)
                            : 'Confirm your application'}
                    </h1>
                </div>
                <OrderConfirmation
                    title={instrument.name}
                    subtitle={
                        !isInstrumentInNoTrade(instrument) && (
                            <p className={confirmStyles.symbol}>
                                <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                                <PronounceLetters text={instrument.exchange} />
                            </p>
                        )
                    }
                    image={<InstrumentLogo instrument={instrument} noBorder />}
                    items={
                        (
                            [
                                ...getAnswerItems(stagedApplication.answers, applicationRule.question_blocks),
                                ...(stagedApplication.applicationRule.type === 'EXERCISE'
                                    ? getExerciseItems(stagedApplication, instrumentsById)
                                    : []),
                                ...(stagedApplication.applicationRule.type === 'PURCHASE'
                                    ? getPurchaseItems(stagedApplication)
                                    : []),
                                hasOutcomes && {
                                    description: 'Transaction fee',
                                    value: <DollarValue value={0} currency={applicationCurrency} />,
                                },
                            ] as (OrderRow | null)[]
                        ).filter(i => i) as OrderRow[]
                    }
                    total={
                        applicationRule.type === 'VOTE'
                            ? undefined
                            : {
                                  description: 'Order amount',
                                  value: cashPayment ? (
                                      <DollarValue value={cashPayment.total} currency={cashPayment.currency} />
                                  ) : (
                                      '-'
                                  ),
                              }
                    }
                    exchange={
                        cashPayment ? (
                            <BuyConfirmExchange
                                // Coercing to the supported wallet currencies here because we're not expecting voluntary corporate actions
                                // for funds with any other currency.
                                displayCurrency={cashPayment.currency as Currency}
                                paymentBreakdown={cashPayment.breakdown}
                                expectedFee={undefined}
                            />
                        ) : undefined
                    }
                />
                <div>
                    <div className={confirmStyles.extraInfo}>
                        {stagedApplication.applicationRule.terms_and_conditions_html && (
                            <div
                                className={orderProcessMessageStyles.orderProcessMessage}
                                dangerouslySetInnerHTML={{
                                    __html: stagedApplication.applicationRule.terms_and_conditions_html,
                                }}
                            />
                        )}
                    </div>
                </div>
                {error && (
                    <p className={confirmStyles.submitError}>We couldn’t complete the application, please try again.</p>
                )}
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--apply"
                    label={applicationRule.type === 'VOTE' ? 'Cast ' + pluralisedVote(applicationRule) : 'Apply'}
                    processing={isSubmitting || stagedApplication.state === 'placed'}
                    onClick={handleSubmit}
                />
            </ActionBar>
            {error && (
                <ApplicationErrors
                    stagedApplication={stagedApplication}
                    instrumentName={instrument.name}
                    isDependent={isDependent}
                    preferredName={preferredName}
                    page="confirm"
                    resetError={() =>
                        dispatch(actions.UpdateStagedApplication({...stagedApplication, error: undefined}))
                    }
                />
            )}
        </>
    )
}

export default ApplyConfirm
