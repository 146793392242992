import {useRetailGet} from '~/api/query/retail'
import {Response} from '~/api/retail/types'

/**
 * Hook to fetch the Wallet breakdown for a given Wallet Portfolio. Has detail
 * about uncleared balances, withdrawable balances, etc. The result has a very
 * short cahche time as the result is affected by a number of factors outside
 * the domain of the client, and it's important for user experience to provide
 * an accurate picture.
 *
 * @param {string} portfolioId - The Wallet portfolio ID to fetch the breakdown
 * for.
 * @returns {Response.AccountingWalletBreakdown} The Wallet breakdown for the
 * given Wallet portfolio ID.
 */
export const useWalletBreakdown = (portfolioId: string): Response.AccountingWalletBreakdown => {
    const result = useRetailGet({
        path: 'accounting/:portfolio_id/wallet-breakdown',
        pathParams: {
            portfolio_id: portfolioId,
        },
        options: {
            // There's quite an expensive calculation at the end of this that doesn't change too dynamically.
            // Let's not re-fetch on focus as desktop use especially can easily result in many re-fetches.
            refetchOnWindowFocus: false,
            // The flipside is that we want a clean fetch every time we load a page that uses this endpoint - not just a background refresh.
            // As your breakdown is affected by more than just what you do in the UI it's safest to show a loading screen every time this hook is used on a new page.
            // If multiple instances of the hook are called in the same screen, tanstack will merge the request promises
            cacheTime: 0,
        },
    })

    return result.data
}
