import {ModalLink} from '@design-system/modal'
import React from 'react'

const TaxIdentificationNumbers = () => (
    <ModalLink
        dataTestId="modal-link--tax-identification-numbers"
        label="Tax identification numbers"
        asIcon
        modalTitle="Tax identification numbers"
        bottomBorder
        primaryButton={{label: 'Ok'}}
        helpIconSize={16}
    >
        <p>
            A tax identification number, also known as a TIN, is a unique number used to track tax obligations and
            payments. You'll have a unique number for each country you’re a tax resident in.
        </p>

        <p>
            Some countries have different names for these numbers, for example, NZ calls it an IRD number, in Australia
            its a Tax file number and in the US it's called a Social Security Number. There are a few countries which do
            not have TIN numbers.{' '}
        </p>

        <p>
            <h3>Why you need to add a TIN</h3>
            You need to add a TIN to keep being able to access your account and so you’re taxed correctly–you might be
            taxed at a higher rate if you don't add a TIN.
        </p>

        <p>
            For more information about how to get a TIN number for the countries you’re a tax resident in see the{' '}
            <a
                href="https://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-identification-numbers/"
                target="_blank"
                rel="noopener"
            >
                OECD website
            </a>
            .
        </p>
    </ModalLink>
)

export default TaxIdentificationNumbers
