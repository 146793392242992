import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import HelpEmail from '~/global/widgets/help-email/HelpEmail'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import styles from '~/sections/invest/sections/bank-linking/BankLinking.scss'
import {useBankLinkingReferrer} from '~/sections/invest/sections/bank-linking/hooks/useBankLinkingRererrer'
import actions from '~/store/bankLinking/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'

export interface OnBackProps {
    onBack: () => void
}

const BankLinkingRejected = ({onBack}: OnBackProps) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const referrer = useBankLinkingReferrer()
    const [fullName, setFullName] = React.useState<Response.FullName>()
    const [submitting, setSubmitting] = React.useState(false)
    const isLoading = useAppSelector(({bankLinking}) => bankLinking.bankLinkingLoadingState === 'loading')

    const fetchFullName = async () => {
        if (fullName?.first_name) {
            return
        }
        const response = await dispatch(identityActions.GetFullName())
        if (response.type === 'full_name') {
            setFullName(response)
        }
    }

    React.useEffect(() => {
        fetchFullName()
    }, [])

    const unlinkBank = async (redirectToConsent = false) => {
        setSubmitting(true)
        await dispatch(actions.UnlinkBank())

        if (redirectToConsent) {
            if (referrer === 'explore') {
                navigate(-2)
                return
            }
            navigate(-1)
        } else {
            if (referrer === 'explore') {
                navigate(-1)
            } else if (referrer === 'linked-bank-topup') {
                // Referrer shouldn't be this, keeping route types happy
                navigate(profileUrl(`wallet/linked-bank-topup`), {replace: true})
            } else {
                navigate(profileUrl(`${referrer}/roundups/:step`, {step: 'consent'}), {replace: true})
            }
        }
    }

    const fullNameAsText = () => {
        if (fullName) {
            return [fullName?.first_name, fullName?.middle_name, fullName?.last_name]
                .filter(nameItem => nameItem)
                .join(' ')
        } else {
            return ''
        }
    }

    return (
        <>
            <Toolbar leftButton="back" onLeftButtonClick={onBack} dataTestId="toolbar--banklinking-rejected" />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Page>
                        <h1 className={cn(styles.h1, spacing.spaceBelow12)}>Linked bank name matching issue</h1>
                        <p className={spacing.spaceBelow16}>
                            The name of your linked bank account doesn't match what you have registered with on your
                            Sharesies account. Unfortunately, we will be unable to give you access to certain features
                            connected with linking with your bank account.
                        </p>
                        <p>You're welcome to link another bank that matches the name {fullNameAsText()}.</p>
                        <p className={spacing.spaceBelow16}>
                            If you have further questions, please get in touch at <HelpEmail />.
                        </p>
                    </Page>
                    <ActionBar className={styles.stackedButtonGroup}>
                        <Button
                            dataTestId="button--next"
                            label="Unlink and link with another bank"
                            type="primary"
                            onClick={() => unlinkBank(true)}
                            disabled={submitting}
                        />
                        <Button
                            dataTestId="button--next"
                            label="Unlink your bank"
                            type="secondary"
                            onClick={() => unlinkBank()}
                            disabled={submitting}
                        />
                    </ActionBar>
                </>
            )}
        </>
    )
}

export default BankLinkingRejected
