import Decimal from 'decimal.js'
import {DateTime} from 'luxon'
import React from 'react'
import {Model} from '~/api/retail/types'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {dateFormatFullMonth, dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {isOnMainUsExchange} from '~/global/utils/is-on-exchange/isOnExchange'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {Event, Order, Sell, SpareChange, Success, Units} from '~/global/widgets/OLD_icons'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {OrderRow} from '~/global/widgets/order-confirmation/OrderConfirmation'
import {
    CorporateActionType,
    CorporateActionWithEligibility,
    MandatoryCorporateActionV2,
    VoluntaryCorporateActionV2,
} from '~/store/accounting/types'
import {Instrument} from '~/store/instrument/types'
import {AnswerableBlock, Application, StagedApplication} from '~/store/order/types'

// This file contains logic for transforming a CorporateActionV2 into various human-readable strings
// Strap in - making CAs readable is hard. See also the widgets in CorporateActionDescription.tsx which
// use these functions heavily.

// TODO: This file could be refactored to return plain strings, not JSX elements
// (in which case is can be moved into a `utils` folder).
// The icons can be handled by just using the straight imports and changing the
// type from `JSX.Element` to `MemoExoticComponent<(props: IconProps) => Element>`.
// We should also switch to using Design System icons.

/**
 * Get the relevant icon based on a voluntary CA's application state
 */
function getIconForVoluntaryCA(ca: Model.CorporateActionV2): JSX.Element {
    if (ca.is_terminal) {
        return <Order />
    } else if (ca.application?.is_cancelled) {
        return <SpareChange />
    } else {
        return <Order />
    }
}

/**
 * Helper conditional for determining if the corporate action was from a share purchase
 * application which has not been settled yet
 */
export const isPendingPurchase = (action: Model.CorporateActionV2) => {
    return action.action_type === 'PURCHASE' && !action.is_terminal
}

export const isDRPShareCA = (action: Model.CorporateActionV2): action is MandatoryCorporateActionV2 => {
    return !!action.eligibility?.associated_drp_plan_id && action.action_type === 'SHARE_DIVIDEND'
}

export const DIVIDEND_LIKE_TYPES: CorporateActionType[] = [
    'DIVIDEND',
    'RETURN_OF_CAPITAL',
    'INTEREST',
    'CAPITAL_GAINS_DISTRIBUTION',
]
export const isDividendLikeCA = (action: Model.CorporateActionV2) => {
    return DIVIDEND_LIKE_TYPES.includes(action.action_type)
}
/**
 * Returns the string that should be appended to Mandatory CAv2 cards to describe their state
 * ie, " - pending" for a pending application
 */
function getCorporateActionStateDescription(action: Model.CorporateActionV2): string {
    if (action.application?.is_cancelled) {
        return action.action_type === 'VOTE' ? ' - withdrawn' : ' - cancelled'
    } else if (action.is_terminal) {
        return ''
    } else {
        return ' - pending'
    }
}

interface CorporateActionDisplayInfo {
    icon: JSX.Element
    title: string
}

/**
 * Called by `getIconAndTitleForCAv2` to get the default icon and title for a CA.
 * These values may get overridden or mutated
 */
function getIconAndTitleWithoutState(ca: Model.CorporateActionV2): CorporateActionDisplayInfo {
    switch (ca.action_type) {
        case 'GENERIC':
            return {
                icon: <Units />,
                title: 'Corporate action',
            }
        case 'DIVIDEND':
            return {
                icon: <SpareChange />,
                title: 'Dividend',
            }
        case 'SHARE_DIVIDEND':
            const shareOutcome = ca.outcome_records.find(o => o.fund_id)
            if (shareOutcome && shareOutcome.fund_id !== ca.eligibility?.must_hold_fund_id) {
                return {
                    icon: <Units />,
                    title: 'Spinoff',
                }
            }

            if (ca.eligibility?.associated_drp_plan_id) {
                return {
                    icon: <SpareChange />,
                    title: 'Dividend reinvestment',
                }
            }
            return {
                icon: <SpareChange />,
                title: 'Share dividend',
            }
        case 'SHARE_SPLIT':
            return {
                icon: <Units />,
                title: 'Share split',
            }
        case 'SHARE_CONSOLIDATION':
            return {
                icon: <Units />,
                title: 'Share consolidation',
            }
        case 'MERGER_OR_ACQUISITION':
            return {
                icon: <Units />,
                title: 'Merger or acquisition',
            }
        case 'CANCELLATION':
            return {
                icon: <Sell />,
                title: 'Share cancellation',
            }
        case 'RETURN_OF_CAPITAL':
            return {
                icon: <Success />,
                title: 'Capital return',
            }
        case 'INTEREST':
            return {
                icon: <SpareChange />,
                title: 'Interest',
            }
        case 'EXERCISE': {
            return {
                icon: getIconForVoluntaryCA(ca),
                title: 'Exercise',
            }
        }
        case 'PURCHASE': {
            return {
                icon: getIconForVoluntaryCA(ca),
                title: 'Purchase',
            }
        }
        case 'SHARE_ISSUE': {
            return {
                icon: getIconForVoluntaryCA(ca),
                title: 'Share issue',
            }
        }
        case 'CAPITAL_GAINS_DISTRIBUTION':
            return {
                icon: <SpareChange />,
                title: 'Capital gains distribution',
            }
        case 'VOTE':
            return {
                icon: <Event />,
                title: 'Vote',
            }
        case 'SELLDOWN':
            return {
                icon: <Sell />,
                title: 'Selldown',
            }
        case 'SHUNT':
            return {
                icon: <Units />,
                title: 'Shunt',
            }
        case 'CASH_ISSUE':
            return {
                icon: <SpareChange />,
                title: 'Cash issue',
            }
        default:
            assertNever(ca.action_type)
            return {
                icon: <Units />,
                title: 'Corporate action',
            }
    }
}

/**
 * Returns, you guessed it, a title and icon to describe this outcome
 *
 * @example
 * { icon: <SpareChange />: title: 'Dividend' }
 */
export function getIconAndTitleForCAv2(ca: Model.CorporateActionV2): CorporateActionDisplayInfo {
    const {icon, title} = getIconAndTitleWithoutState(ca)

    return {
        icon,
        title: (ca.card_title ?? title) + getCorporateActionStateDescription(ca),
    }
}

/**
 * Called by `getIconAndTitleForApplication` to get the default icon and title for an available Application.
 * These values may get overridden or mutated
 */
function getIconAndTitleForApplicationWithoutState(application: Application): CorporateActionDisplayInfo {
    switch (application.type) {
        case 'GENERIC':
            return {
                icon: <Units />,
                title: 'Corporate action',
            }
        case 'DIVIDEND':
            return {
                icon: <SpareChange />,
                title: 'Dividend',
            }
        case 'SHARE_DIVIDEND':
            // TODO use eligibility once it's available on the application
            if (application.outcomes.find(o => o.fund_id)?.fund_id !== application.fund_id) {
                return {
                    icon: <Units />,
                    title: 'Spinoff',
                }
            }
            return {
                icon: <SpareChange />,
                title: 'Share dividend',
            }
        case 'SHARE_SPLIT':
            return {
                icon: <Units />,
                title: 'Share split',
            }
        case 'SHARE_CONSOLIDATION':
            return {
                icon: <Units />,
                title: 'Share consolidation',
            }
        case 'MERGER_OR_ACQUISITION':
            return {
                icon: <Units />,
                title: 'Merger or acquisition',
            }
        case 'CANCELLATION':
            return {
                icon: <Sell />,
                title: 'Share cancellation',
            }
        case 'RETURN_OF_CAPITAL':
            return {
                icon: <Success />,
                title: 'Capital return',
            }
        case 'INTEREST':
            return {
                icon: <SpareChange />,
                title: 'Interest',
            }
        case 'EXERCISE': {
            return {
                icon: <Order />,
                title: 'Exercise',
            }
        }
        case 'PURCHASE': {
            return {
                icon: <Order />,
                title: 'Purchase',
            }
        }
        case 'SHARE_ISSUE': {
            return {
                icon: <Order />,
                title: 'Share issue',
            }
        }
        case 'CAPITAL_GAINS_DISTRIBUTION':
            return {
                icon: <SpareChange />,
                title: 'Capital gains distribution',
            }
        case 'VOTE':
            return {
                icon: <Event />,
                title: 'Vote',
            }
        case 'SELLDOWN':
            return {
                icon: <Sell />,
                title: 'Selldown',
            }
        case 'SHUNT':
            return {
                icon: <Units />,
                title: 'Shunt',
            }
        case 'CASH_ISSUE': {
            return {
                icon: <SpareChange />,
                title: 'Cash issue',
            }
        }
        default:
            assertNever(application.type)
            return {
                icon: <Units />,
                title: 'Corporate action',
            }
    }
}

/**
 * Similar to `getIconAndTitleForCAv2`, except for Applications - corporate actions that haven't happened yet
 */
export function getIconAndTitleForApplication(application: Application): CorporateActionDisplayInfo {
    const {icon, title} = getIconAndTitleForApplicationWithoutState(application)

    return {
        icon,
        title: title + ' - upcoming',
    }
}

export function getOutcomeVerb(
    action: Model.CorporateActionV2,
    outcome: Model.CorporateActionV2['outcome_records'][number],
): string {
    const isPositive = parseFloat(outcome.net_amount) >= 0

    if (isPendingPurchase(action)) {
        return 'applied for'
    }

    if (action.action_type === 'EXERCISE') {
        return action.is_terminal
            ? (isPositive ? 'bought' : 'exercised')
            : (isPositive ? 'to buy' : 'to exercise') // prettier-ignore
    }

    if (action.action_type === 'SHARE_ISSUE' && isPositive) {
        return action.is_terminal ? 'issued' : 'to be issued'
    }

    return action.is_terminal
        ? (isPositive ? 'added' : 'removed')
        : (isPositive ? 'to add' : 'to remove') // prettier-ignore
}

/**
 * Returns a short description describing what type of outcome this is
 *
 * @example
 * 'Tesla shares added'
 * 'Cash payment'
 */
export function getOutcomeName(
    action: Model.CorporateActionV2,
    outcome: Model.CorporateActionV2['outcome_records'][number],
    instrumentsById: Record<string, Instrument>,
): string {
    if (outcome.fund_id || isDRPShareCA(action)) {
        const verb = getOutcomeVerb(action, outcome)
        const fundId = isDRPShareCA(action) ? action.eligibility.must_hold_fund_id : outcome.fund_id!
        const instrument = instrumentsById[fundId]
        return `${instrument.symbol} ${shareLabel({instrument, isPlural: true})} ${verb}`
    } else if (action.action_type === 'PURCHASE' && parseFloat(outcome.amount_per_input_unit) > 0) {
        return 'Cash refund'
    } else {
        return 'Cash payment'
    }
}

/**
 * Returns a short description saying how this affected the investor's portfolio/wallet
 *
 * @example
 * 'Added to Portfolio'
 * 'Deducted from Wallet'
 */
export function getOutcomeEffect(
    action: Model.CorporateActionV2,
    outcome: Model.CorporateActionV2['outcome_records'][number],
): string {
    // Show as settled if the CA is settled, or if this is a negative effect from a voluntary corporate action
    const isSettled =
        outcome.is_settled || (outcome.amount_per_input_unit.startsWith('-') && outcome.application_record_id)
    const positive = parseFloat(outcome.net_amount) > 0

    if (outcome.fund_id || outcome.alternative_payment_amount) {
        const verb = isSettled
            ? positive ? 'Added to' : 'Removed from'
            : positive ? 'Adding to' : 'Removing from' // prettier-ignore
        return `${verb} Portfolio`
    } else {
        if (!isSettled) {
            return 'Pending'
        }
        const verb = positive ? 'Paid to' : 'Paid from'
        return `${verb} Wallet`
    }
}

/**
 * Returns a short label for what the effect was
 *
 * @example
 * 'Shares added'
 * 'Amount'
 */
export function getOutcomeLabel(
    action: Model.CorporateActionV2,
    outcome: Model.CorporateActionV2['outcome_records'][number],
    instrumentsById: Record<string, Instrument>,
): string {
    const shareInstrument = outcome.fund_id && instrumentsById[outcome.fund_id]

    if (isPendingPurchase(action)) {
        if (outcome.currency) {
            if (parseFloat(outcome.amount_per_input_unit) < 0) {
                return 'Amount applied for'
            } else {
                return 'Remainder to refund'
            }
        } else {
            return 'Shares applied for'
        }
    }

    const expectedOn = DateTime.fromISO(action.settlement_date).toFormat(dateFormatFullMonth)

    if (action.eligibility?.associated_drp_plan_id) {
        const instrument = instrumentsById[action.fund_id]
        if (outcome.alternative_payment_amount) {
            if (action.is_terminal) {
                return `${shareLabel({instrument, isPlural: true, isCapitalised: true})} added`
            }
            return `Expected to be added on ${expectedOn}`
        } else {
            return `Value of ${shareLabel({instrument, isPlural: true})} expected on ${expectedOn}`
        }
    }

    if (outcome.currency) {
        const cashInstrument = instrumentsById[action.fund_id]
        // Show the expected payment date on upcoming dividends (except US ones, as there's extra complexity there)
        if (!action.is_terminal && cashInstrument && !isOnMainUsExchange(cashInstrument)) {
            return `Expected to be paid on ${expectedOn}`
        } else {
            return 'Amount'
        }
    } else if (parseFloat(outcome.net_amount) >= 0) {
        return `${
            shareInstrument ? shareLabel({instrument: shareInstrument, isPlural: true, isCapitalised: true}) : 'Shares'
        } added`
    } else {
        return `${
            shareInstrument ? shareLabel({instrument: shareInstrument, isPlural: true, isCapitalised: true}) : 'Shares'
        } removed`
    }
}

/**
 * Describes the tax paid to a given collector
 *
 * @example
 * collectorTaxName('NZ_IRD') // 'Resident Withholding Tax (RWT)'
 */
export function getCollectorTaxName(
    collector: Model.CorporateActionV2['outcome_records'][number]['tax_records'][number]['owed_to'],
    customerJurisdiction: Model.User['jurisdiction'],
) {
    switch (collector) {
        case 'NZ_IRD':
            if (customerJurisdiction === 'nz') {
                return 'Resident withholding tax'
            } else {
                return 'Non-resident withholding tax'
            }
        case 'US_IRS':
            return 'US withholding tax'
        case 'AU_ATO':
            return 'AU withholding tax'
        case 'UNKNOWN':
            return 'Foreign withholding tax'
        default:
            assertNever(collector)
            return 'Other tax'
    }
}

// *******
// Card description generation - this is about to get even more ridiculous

/**
 * Does the following:
 *
 * @example
 * instrumentName(apple, true) // "Apple (AAPL)"
 * instrumentName(tsla) // "TSLA"
 */
export function instrumentName(instrument: Instrument, showName = false): string {
    if (showName) {
        return `${instrument.name} (${instrument.symbol})`
    }
    return instrument.symbol
}

export function getAllocationBlurb(action: Model.CorporateActionV2, instrument: Instrument): string | null {
    let allocationBlurb = null
    const isSettled = action.is_terminal

    if (action.settlement_date) {
        const settlementDate = DateTime.fromISO(action.settlement_date)
        const dateHasNotPassed = settlementDate.toMillis() > DateTime.now().endOf('day').toMillis()
        const humanDateString = settlementDate.hasSame(DateTime.now(), 'day')
            ? 'today'
            : `on ${settlementDate.toFormat(dateFormatNoTime)}`

        if (!isSettled && dateHasNotPassed) {
            allocationBlurb = `${shareLabel({
                instrument,
                isPlural: true,
                isCapitalised: true,
            })} are due to be allocated ${humanDateString}.`
        }
    }

    return allocationBlurb
}

export function getDocumentURL(document: Model.CorporateActionV2['documents'][number]): string {
    return `/api/corporate-actions/document/${document.id}/${document.filename}`
}

export const applicationCashPayment = (
    corporate_action: Model.CorporateActionV2,
): [Required<Model.CorporateActionV2['outcome_records'][number]['currency']>, Decimal] | undefined => {
    const amounts = corporate_action.outcome_records
        .map((o): [typeof o.currency, Decimal] => [o.currency, new Decimal(o.gross_amount)])
        .filter(([currency, _]) => currency)

    if (!amounts.length) {
        return
    }

    let currency
    let total = new Decimal(0)

    for (const [outcomeCurrency, amount] of amounts) {
        if (currency && outcomeCurrency !== currency) {
            // mixed currencies, bail
            return
        }

        currency = outcomeCurrency
        total = total.add(amount)
    }

    return [currency, total]
}

const getAnswerItem = (block: AnswerableBlock, value: StagedApplication['answers'][any]): OrderRow => {
    // Default top a blank field
    let displayValue = <>-</>

    switch (block.type) {
        case 'decimal_input':
            if (value) {
                if (block.currency) {
                    displayValue = <DollarValue value={value as string} currency={block.currency} />
                } else {
                    displayValue = <>{value}</>
                }
            }
            break
        case 'string_input': {
            displayValue = <>{value}</>
            break
        }
        case 'select_input':
        case 'radio_input': {
            if (value) {
                displayValue = <>{block.choices.find(c => c.value === value)!.label}</>
            }
            break
        }
        case 'checkbox_input': {
            displayValue = <>{value ? 'Yes' : 'No'}</>
            break
        }
        default:
            assertNever(block)
            break
    }

    return {
        description: block.label,
        value: displayValue,
    }
}

export const getAnswerItems = (
    answers: StagedApplication['answers'],
    questionBlocks: StagedApplication['applicationRule']['question_blocks'],
): OrderRow[] => {
    return questionBlocks
        .filter((block): block is AnswerableBlock => 'name' in block)
        .map(block => getAnswerItem(block, answers[block.name])) // The answer could be missing - in which case we should explicitly render a "no answer" answer
}

export const getAdditionalAnswerItems = (
    additionAnswers: Record<string, unknown>,
    questionBlocks: StagedApplication['applicationRule']['question_blocks'],
): OrderRow[] => {
    return getAnswerItems(
        additionAnswers as StagedApplication['answers'],
        questionBlocks.filter(block => 'name' in block && block.name !== 'application_amount'),
    )
}

export const hasEligibility = (ca: Model.CorporateActionV2): ca is CorporateActionWithEligibility => !!ca.eligibility
export const hasApplication = (ca: Model.CorporateActionV2): ca is VoluntaryCorporateActionV2 => !!ca.application

/**
 * If a vote has one resolution - "vote"
 * If a vote has multiple resolutions - "votes"
 */
export const pluralisedVote = (application: Application | VoluntaryCorporateActionV2['application']): string => {
    return application.question_blocks.filter(block => 'name' in block).length > 1 ? 'votes' : 'vote'
}
