import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal'
import {withFormik, FieldArray} from 'formik'
import React from 'react'
import {debounce} from 'throttle-debounce'
import * as api from '~/api/retail'
import * as rollbar from '~/api/rollbar/rollbar'
import {spacing} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {alphanumericOnly, fourDigitsOnly} from '~/global/utils/normalize-values/normalizeValues'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {validate} from '~/global/widgets/form-controls'
import {Password, Text, Radio, IRDNumber, TFNNumber, Email} from '~/global/widgets/form-controls/formik'
import {ValidationRule} from '~/global/widgets/form-controls/validate'
import {NotificationContextProps} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {PIR} from '~/store/identity/types'

interface JointCsnEmailAddress {
    email: string
    id?: string
}

interface FormValues {
    csnOrOther: string
    fin: string
    irdNumber?: string
    tfn?: string
    jointCsnOrHn?: string
    emails?: JointCsnEmailAddress[]
}

interface FormProps {
    setRegistryDetails: (csn: string, fin: string, jointHolderOwnerIds?: string[]) => Promise<void | string>
    setIrdNumber: (irdNumber: string, pir: PIR) => Promise<null | string>
    setTfnNumber: (tfnNumber: string) => Promise<null | string>
    notificationContext: NotificationContextProps
    showIrdField: boolean
    showTfnField: boolean
    handleSuccess: (csn: string) => void
    maybeStagedTransferOrderDirection?: 'in' | 'out'
}

function validateEmail(email: string): boolean {
    const pattern = '^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$'
    const regex = new RegExp(pattern)
    return regex.test(email)
}
function fullEmailAddress(): ValidationRule<string | undefined> {
    return value => {
        if (!value) {
            return // Nothing to validate
        }
        /*
         * The standard email checks permit emails like test@, test@exa, test@example.
         * which are not Sharesies accounts, so this check adds a regex that matches
         * the common email formats so we don't hit the endpoint unnecessarily
         */
        if (!validateEmail(value)) {
            return 'Email address is not valid'
        }
        return
    }
}

async function sharesiesAccountId(email: string): Promise<string | undefined> {
    const response = await api.post('transfer/registry-details/search-owner', {
        email,
    })

    switch (response.type) {
        case 'owner_search_result':
            return response.id
        case 'error':
        case 'internal_server_error':
            rollbar.sendError(`Failed to fetch Sharesies Account ID for CSN Transfer response.type = ${response.type}`)
            return undefined
        default:
            assertNever(response)
    }
}

export const AddCSNForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => ({
        csnOrOther: '',
        fin: '',
        emails: [{email: ''}],
    }),
    validateOnChange: false, // We have a useEffect that debounces validation instead
    validateOnBlur: false, // We have a useEffect that debounces validation instead
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        csnOrOther: undefined,
    }),
    handleSubmit: async (
        {csnOrOther, fin, irdNumber, tfn, jointCsnOrHn, emails},
        {
            setSubmitting,
            props: {
                setRegistryDetails,
                setIrdNumber,
                setTfnNumber,
                handleSuccess,
                notificationContext,
                showIrdField,
                showTfnField,
            },
        },
    ) => {
        try {
            const errorIrd = irdNumber ? await setIrdNumber(irdNumber, undefined) : undefined
            const errorTfn = tfn ? await setTfnNumber(tfn) : undefined

            // If this is a joint CSN we should store the IDs of the joint holders
            const jointHolderOwnerIds: string[] = []
            if (jointCsnOrHn === 'yes' && emails) {
                emails.forEach(email => {
                    if (email.id) {
                        jointHolderOwnerIds.push(email.id)
                    }
                })
            }

            const errorCSN = await setRegistryDetails(csnOrOther, fin, jointHolderOwnerIds)
            if (errorCSN) {
                notificationContext.showModalError({message: errorCSN})
                setSubmitting(false)
            } else if (showIrdField && errorIrd) {
                // ird error can only exist if the ird field has been shown, otherwise set ird will not be called
                notificationContext.showModalError({message: errorIrd})
                setSubmitting(false)
            } else if (showTfnField && errorTfn) {
                // tfn error can only exist if the tfn field has been shown, otherwise set tfn will not be called
                notificationContext.showModalError({message: errorTfn})
                setSubmitting(false)
            } else {
                handleSuccess(csnOrOther)
            }
        } catch (e) {
            notificationContext.showModalError({message: unknownErrorMessage})
            setSubmitting(false)
            throw e
        }
    },
    validate: async (values, {showIrdField, showTfnField}) => {
        // Validate the static elements
        const schema = showIrdField
            ? {
                  csnOrOther: [validate.required(), validate.csnOrHn()],
                  fin: [validate.required()],
                  irdNumber: [validate.required(), validate.irdNumber()],
                  jointCsnOrHn: [validate.required()],
                  jointHolderOwnerIds: [],
              }
            : showTfnField
              ? {
                    csnOrOther: [validate.required(), validate.csnOrHn()],
                    fin: [validate.required()],
                    tfn: [validate.required()],
                    jointCsnOrHn: [validate.required()],
                    jointHolderOwnerIds: [],
                }
              : {
                    csnOrOther: [validate.required(), validate.csnOrHn()],
                    fin: [validate.required()],
                    jointCsnOrHn: [validate.required()],
                    jointHolderOwnerIds: [],
                }

        const schemaErrors = await validate.generate<FormValues>(schema)(values)

        if (values.jointCsnOrHn === 'no') {
            // Early return if we are not submitting a joint CSN
            return schemaErrors
        } else {
            // Empty array to hold possible email errors
            const emailErrors = Array(values.emails?.length)

            // Validate the dynamic email addresses
            for (const stringIndex in values.emails) {
                const index = parseInt(stringIndex, 10)
                // If this is a joint CSN we need at least one email address
                const isRequired = values.jointCsnOrHn === 'yes' && index === 0

                // Basic checking of the string
                const emailError = await validate.generate({
                    email: isRequired
                        ? [validate.required(), validate.email(), fullEmailAddress()]
                        : [validate.email(), fullEmailAddress()],
                })({
                    email: values.emails[index].email,
                })
                if (Object.keys(emailError).length > 0) {
                    emailErrors[index] = emailError
                }

                // Check if the account exists
                if (values.emails[index].email && !emailError.email) {
                    try {
                        const accountId = await sharesiesAccountId(values.emails[index].email)
                        if (accountId === undefined) {
                            emailErrors[index] = {email: 'Not a valid Sharesies account'}
                        } else {
                            // If we fetch a valid ID, store it for later submission
                            values.emails[index].id = accountId
                        }
                    } catch (e) {
                        emailErrors[index] = {email: 'Unknown error validating email'}
                    }
                }
            }

            // If we didn't have any email errors, then just return the schema errors, otherwise return both
            if (emailErrors.every(error => error === undefined)) {
                return schemaErrors
            } else {
                return {...schemaErrors, ...{emails: emailErrors}}
            }
        }
    },
})(({
    values,
    handleSubmit,
    validateForm,
    isValid,
    isSubmitting,
    showIrdField,
    showTfnField,
    maybeStagedTransferOrderDirection,
}) => {
    /*
     * We debounce validation with a 500ms delay because otherwise
     * we fire multiple requests to validate email addresses to
     * our rate limited endpoint and we don't want that
     *
     * This is the only validation that acts on user interaction - the
     * default validations provided by Formik are disabled
     */
    const debouncedValidate = React.useRef(
        debounce(500, () => {
            validateForm()
        }),
    )
    React.useEffect(() => {
        debouncedValidate.current()
    }, [values])

    // You can't transfer out to an HN
    const csnOrHnString = maybeStagedTransferOrderDirection === 'in' ? 'CSN or HN' : 'CSN'

    return (
        <form onSubmit={handleSubmit}>
            <div className={commonStyles.formWrapper}>
                <h1 className={spacing.spaceBelow12}>Add your investor details</h1>
                <Text
                    autoComplete="off"
                    dataTestId="text-input--csn-or-other"
                    label={csnOrHnString}
                    helpText={`A CSN is usually 9 characters long, and starts with the number 3. ${
                        maybeStagedTransferOrderDirection === 'in'
                            ? 'An HN is 10 characters long, and starts with an R or N.'
                            : 'Transfers can’t be made to an HN.'
                    }`}
                    name="csnOrOther"
                    normalizeValue={alphanumericOnly}
                    autoFocus
                />
                <Password
                    dataTestId="password-fin-number"
                    label="FIN"
                    helpText={`A FIN is a 4-digit number, sent to you by post when you first get shares on a ${
                        maybeStagedTransferOrderDirection === 'in' ? 'CSN or HN' : 'CSN'
                    }.`}
                    name="fin"
                    normalizeValue={fourDigitsOnly}
                />
                {showIrdField && (
                    <div className={spacing.spaceAbove24}>
                        <IRDNumber dataTestId="text-input--ird-number" label="IRD number" name="irdNumber" />
                    </div>
                )}
                {showTfnField && (
                    <div className={spacing.spaceAbove24}>
                        <TFNNumber
                            dataTestId="text-input--tfn-number"
                            label="TFN (Tax File Number)"
                            name="tfn"
                            autoFocus
                        />
                    </div>
                )}
                <Radio
                    dataTestId="radio--is-joint-csn"
                    name="jointCsnOrHn"
                    label={`Is this ${csnOrHnString} jointly held?`}
                    choices={[
                        {
                            label: 'Yes',
                            value: 'yes',
                        },
                        {
                            label: 'No',
                            value: 'no',
                        },
                    ]}
                />
                {values.jointCsnOrHn === 'yes' && (
                    <>
                        <h2>
                            Joint holder’s Sharesies account email address
                            <ModalLink
                                dataTestId="modal-link--joint-holders-email-address"
                                label="Why we’re asking"
                                asIcon
                                helpIconSize={16}
                                modalTitle="Why we’re asking"
                                primaryButton={{label: 'Ok'}}
                            >
                                <p>
                                    All transfers {maybeStagedTransferOrderDirection === 'in' ? 'from' : 'to'} a jointly
                                    held CSN{maybeStagedTransferOrderDirection === 'in' && ' or HN'} need to be approved
                                    by all joint holders.
                                </p>
                                <p>
                                    Every joint holder will need to have a Sharesies account to give their approval for
                                    the transfer. We require this to verify their identity.
                                </p>
                                <p>
                                    Any questions, let us know at{' '}
                                    <a href="mailto:help@sharesies.co.nz" target="_blank" rel="noopener">
                                        help@sharesies.co.nz
                                    </a>
                                </p>
                            </ModalLink>
                        </h2>
                        <FieldArray name="emails">
                            {({push}) => (
                                <>
                                    {values.emails?.map((_record, index) => (
                                        <div key={index}>
                                            <Email
                                                dataTestId="email--joint-acccount-email"
                                                name={`emails[${index}].email`}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                    ))}
                                    <ButtonAsLink onClick={() => push({email: ''})} noTextDecoration>
                                        + Add more
                                    </ButtonAsLink>
                                </>
                            )}
                        </FieldArray>
                    </>
                )}
            </div>

            <ActionBar>
                <Button
                    dataTestId="button--add-details"
                    label="Add details"
                    disabled={!isValid}
                    isSubmit
                    processing={isSubmitting}
                />
            </ActionBar>
        </form>
    )
})
