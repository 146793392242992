import {Search} from '@design-system/icon'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {KIDS_RECOMMENDED_LABEL} from '~/global/constants/categoryAndSearchLabels'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Chips from '~/global/widgets/chips/Chips'
import {Link, useNavigate} from '~/migrate-react-router'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'
import styles from './Explore.scss'

export const ExploreHeader: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const jurisdiction = useAppSelector(s => s.identity.user?.jurisdiction)
    const isDependent = useAppSelector(s => s.identity.user?.is_dependent)
    const categories = useAppSelector(s => s.instrument.metadata.categories)
    const instrumentTypeValuesByLabel = useAppSelector(s => s.instrument.metadata.instrumentTypes)

    const popularCategories = [
        ...(isDependent ? [KIDS_RECOMMENDED_LABEL] : []),
        'Companies',
        'ETFs',
        'Transport',
        'Healthcare',
        'Tourism',
        'Food and drink',
        'Banking and finance',
        'Managed funds',
        'Energy and utilities',
        'Agriculture and fisheries',
    ].filter(instrumentType => {
        // don't show managed funds to jurisdictions other than nz
        if (jurisdiction !== 'nz' && instrumentType === 'Managed funds') {
            return false
        }
        return true
    })

    return (
        <div className={styles.header}>
            <h1 data-testid="title">Explore</h1>

            <div className={styles.search} data-testid="search">
                <Link
                    to={profileUrl('invest/search')}
                    onClick={() => {
                        dispatch(instrumentActions.executeClearAllFilters())
                        dispatch(instrumentActions.SetSearchAutofocus(true))
                    }}
                >
                    <Search />
                    <p>Search all investments</p>
                </Link>
            </div>

            <div className={styles.popularCategoriesTitle}>
                <h2>Popular</h2>
                <Link
                    to={profileUrl('invest/search/filter')}
                    data-testid="link--invest-filters"
                    onClick={() => dispatch(instrumentActions.executeClearAllFilters())}
                >
                    See all filters
                </Link>
            </div>

            <Chips
                options={popularCategories}
                isInlineDisplay
                singleLine
                onChipClick={chip => {
                    Analytics.event({
                        category: 'Search',
                        action: 'Clicked Invest filter chip',
                        label: `${chip}`,
                    })
                    const instrumentTypesLabels = instrumentTypeValuesByLabel.map(instrumentType => instrumentType.name)
                    if (categories.includes(chip)) {
                        dispatch(instrumentActions.initialiseSearchWithCategory(chip))
                    }
                    if (instrumentTypesLabels.includes(chip)) {
                        dispatch(instrumentActions.initialiseSearchWithInstrumentType(chip))
                    }
                    if (chip === KIDS_RECOMMENDED_LABEL) {
                        dispatch(instrumentActions.initialiseSearchWithKidsRecommended())
                    }
                    navigate(profileUrl('invest/search'))
                }}
                fixedHeight
            />
        </div>
    )
}

export const FonterraExploreHeader: React.FunctionComponent = () => {
    return (
        <div className={styles.header}>
            <h1 data-testid="title">Explore</h1>
        </div>
    )
}
