import {Button} from '@design-system/button'
import {Invest as InvestIcon} from '@design-system/icon'
import {Modal} from '@design-system/modal'
import {Thumbnail} from '@design-system/thumbnail'
import cn from 'classnames'
import Decimal from 'decimal.js'
import {Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import {useIsFirstRender} from 'usehooks-ts'
import {DistillScope} from '~/api/query/distill'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {accessibility, spacing} from '~/global/scss/helpers'
import useDistillInstrumentInfo from '~/global/state-hooks/distill/useDistillInstrumentInfo'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {RadioEmpty, RadioFilled} from '~/global/widgets/OLD_icons'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {validate} from '~/global/widgets/form-controls'
import {DecimalInput} from '~/global/widgets/form-controls/formik'
import {PercentValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useDistillInstrument} from '~/sections/kiwisaver/data/distill'
import {usePlanEditorUrl} from '~/sections/kiwisaver/sections/edit-investment-plan/hooks/usePlanEditorUrl'
import styles from '~/sections/kiwisaver/sections/edit-investment-plan/index.scss'
import {
    Allocation,
    KSCustomer,
    useExistingKSCustomer,
    useInvestmentPlanForEditing,
    useSetKSInvestmentPlanDraft,
} from '~/sections/kiwisaver/state'
import {useAppSelector} from '~/store/hooks'
import {actingAsID as actingAsIDSelector} from '~/store/identity/selectors'

type FundCategory = NonNullable<KSCustomer['investment_plan']>['allocations'][number]['fund_category']

const ALL_SELF_SELECT_FUNDS = 'all-self-select'

const InstrumentRow: React.FunctionComponent<{
    allocation: Allocation
    onRemove: () => void
    onSelectPercentage: () => void
}> = ({allocation: {fund_id, allocation_percent, fund_category}, onRemove, onSelectPercentage}) => {
    const instrument = useDistillInstrument({
        instrumentId: fund_id,
        scope: DistillScope.KIWISAVER_ALL_FUNDS,
    })
    const underlyingInstrument = useDistillInstrument({
        instrumentId: instrument.underlyingInstrumentId!,
        scope: DistillScope.KIWISAVER_ALL_FUNDS,
        isUnderlyingInstrument: true,
    })
    const resourcePath = useDistillInstrumentInfo(DistillScope.KIWISAVER_ALL_FUNDS, {
        searchFundInvestments: true,
    }).filesHostAddress

    return (
        <div className={styles.instrument}>
            <Thumbnail
                dataTestId={`${instrument.urlSlug}--logo`}
                width="62px"
                height="62px"
                symbol={instrument.symbol}
                path={resourcePath + underlyingInstrument.logos.micro}
                noBorder
            />
            <div>
                <h3>{instrument.name}</h3>
                {fund_category === 'BASE' ? (
                    <h4>Base fund</h4>
                ) : (
                    tradingType(instrument) !== 'managed' &&
                    !isInstrumentInNoTrade(instrument) && (
                        <h4>
                            <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                            <PronounceLetters text={instrument.exchange} />
                        </h4>
                    )
                )}
                <ButtonAsLink
                    className={styles.instrumentRemove}
                    onClick={onRemove}
                    dataTestId="button--remove-allocation"
                >
                    Remove
                </ButtonAsLink>
            </div>
            <button
                type="button"
                className={cn(styles.editAllocation, accessibility.button)}
                onClick={onSelectPercentage}
            >
                {allocation_percent}%
            </button>
        </div>
    )
}

const NoInstrumentsRow: React.FC<{category: FundCategory}> = ({category}) => (
    <div className={cn(styles.instrument, styles.noInstruments)}>
        <div className={styles.noInstrumentsImage}>
            <InvestIcon />
        </div>
        <div>
            {category === 'BASE'
                ? 'Choose at least one base fund to get started with your plan.'
                : 'If you like, you can add some other investments to your plan'}
        </div>
    </div>
)

interface PercentageModalProps {
    isOpen: boolean
    onClose: () => void
    initial?: string
    onSelect: (value: Decimal) => void
    editingFundCategory?: FundCategory
    applyToAll: boolean
}

const PercentageModal: React.FC<PercentageModalProps> = props => {
    const customer = useExistingKSCustomer()
    const [usingCustomAmount, setUsingCustomAmount] = useState(false)

    const minBaseFundPercent = new Decimal(customer.min_base_fund_percentage)
    const maxSelfSelectPercent = new Decimal(customer.max_self_select_percentage)

    const options = React.useMemo(() => {
        const options =
            props.editingFundCategory === 'BASE'
                ? new Set(['30', '40', '50', '60', '70'])
                : new Set(['1', '2', '3', '4', '5'])
        if (props.initial) {
            options.add(props.initial)
        }
        const asArray = Array.from(options)
        asArray.sort()
        return asArray
    }, [props.initial, props.editingFundCategory])

    const maxSelfSelectElement = (
        <PercentValue
            noDp={maxSelfSelectPercent.round().eq(maxSelfSelectPercent)}
            value={maxSelfSelectPercent.toString()}
        />
    )

    const maxPercent = props.editingFundCategory === 'SELF_SELECT' ? maxSelfSelectPercent : new Decimal(100)
    const maxPercentElement = <PercentValue value={maxPercent.toString()} noDp={maxPercent.round().eq(maxPercent)} />

    return (
        <Formik
            validateOnChange
            initialValues={{
                amount: props.initial || '',
            }}
            onSubmit={({amount}) => {
                props.onSelect(new Decimal(amount))
                props.onClose()
            }}
            validate={validate.generate({
                amount: [
                    validate.required(),
                    validate.minimum(0.01, 'Enter a percentage greater than 0.01%'),
                    validate.maximum(
                        maxPercent.toNumber(),
                        `Enter a percentage below ${maxPercent}% (at least ${minBaseFundPercent}% needs to be in your base fund).`,
                    ),
                ],
            })}
        >
            {({handleSubmit, isValid, setFieldValue, values}) => (
                <Modal
                    title="Amount"
                    isOpen={props.isOpen}
                    setIsOpen={v => !v && props.onClose()}
                    onFormSubmit={handleSubmit}
                    customZIndex={1051}
                    primaryButton={{
                        label: maxPercent.eq(0) ? 'Okay' : 'Done',
                        disabled: usingCustomAmount ? !isValid : !values.amount,
                        onClick: () => values.amount && props.onSelect(new Decimal(values.amount)),
                    }}
                    dataTestId="dockablemodal--percentage-selector"
                >
                    <div>
                        {maxPercent.eq(0) && (
                            <p>
                                {minBaseFundPercent.toString()}% needs to be in your base fund. You can reduce the
                                amount allocated to other investments if you would like to add to this one.
                            </p>
                        )}

                        {maxPercent.gt(0) && (
                            <div>
                                {props.applyToAll && <p>What percentage would you like in these investments? </p>}
                                {!props.applyToAll && <p>What percentage would you like in this investment?</p>}

                                {!usingCustomAmount && (
                                    <div className={spacing.spaceAbove16}>
                                        {options.map((o, index) => (
                                            <div
                                                key={index}
                                                className={styles.fakeRadio}
                                                onClick={() => setFieldValue('amount', o.toString())}
                                            >
                                                {values.amount === o ? (
                                                    <RadioFilled className={styles.selected} />
                                                ) : (
                                                    <RadioEmpty />
                                                )}{' '}
                                                {o.toString()}%
                                            </div>
                                        ))}

                                        <ButtonAsLink
                                            className={spacing.spaceAbove8}
                                            onClick={() => setUsingCustomAmount(true)}
                                        >
                                            Custom amount
                                        </ButtonAsLink>

                                        {maxPercent.lt(5) &&
                                            (props.applyToAll ? (
                                                <p className={cn(styles.error, spacing.spaceAbove16)}>
                                                    {maxSelfSelectElement} needs to be in your base fund, that means you
                                                    can apply a maximum of {maxPercentElement} to each investment.
                                                </p>
                                            ) : (
                                                <p className={cn(styles.error, spacing.spaceAbove16)}>
                                                    {maxSelfSelectElement} needs to be in your base fund - you've got{' '}
                                                    {maxPercentElement} left to allocate.
                                                </p>
                                            ))}
                                    </div>
                                )}

                                {usingCustomAmount && (
                                    <DecimalInput
                                        name="amount"
                                        autoFocus={true}
                                        dataTestId="ks-edit-allocations-custom-amount"
                                        decimalPlaces={2}
                                        autoComplete="off"
                                        normalisation="decimalOnly"
                                        helpText={`Add a number between 0.01% and ${maxPercent}%`}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </Modal>
            )}
        </Formik>
    )
}

export const EditAllocationsMultibase: React.FunctionComponent<{}> = () => {
    const customer = useExistingKSCustomer()
    const navigate = useNavigate()
    const relativeUrlFor = usePlanEditorUrl()
    const actingAsID = useAppSelector(actingAsIDSelector)
    const investmentPlan = useInvestmentPlanForEditing()
    const updateInvestmentPlan = useSetKSInvestmentPlanDraft()
    const isFirstRender = useIsFirstRender()

    const [newInvestmentPlan, setNewInvestmentPlan] = React.useState<Allocation[]>(investmentPlan.allocations)
    const newPlanTotal = newInvestmentPlan.reduce((acc, curr) => acc.plus(curr.allocation_percent), new Decimal(0))

    const [editingFundId, setEditingFundId] = React.useState<string | undefined>(undefined)
    const editingFundCategory =
        editingFundId === ALL_SELF_SELECT_FUNDS
            ? 'SELF_SELECT'
            : newInvestmentPlan.find(a => a.fund_id === editingFundId)?.fund_category

    const isValid = React.useMemo(() => {
        // 100% must have been allocated
        if (!new Decimal(100).sub(newPlanTotal).eq(0)) {
            return false
        }
        // All allocations must be above 0
        for (const allocation of newInvestmentPlan) {
            if (allocation.allocation_percent === '0') {
                return false
            }
        }
        // At least one base fund
        if (!newInvestmentPlan.some(a => a.fund_category === 'BASE')) {
            return false
        }
        return true
    }, [investmentPlan, newPlanTotal])

    const saveDraftPlan = async () =>
        updateInvestmentPlan.mutateAsync({
            acting_as_id: actingAsID,
            allocations: newInvestmentPlan,
        })

    const onSubmit = async () => {
        try {
            await saveDraftPlan()
        } catch (e) {
            Toast('Could not edit your plan')
        }
        rudderTrack('kiwisaver_self_select', 'self_select_percentages_added', {
            type: customer.customer_state === 'SIGNUP' ? 'signup' : 'edit',
        })
        if (newInvestmentPlan.filter(a => a.fund_category === 'SELF_SELECT').length) {
            navigate(relativeUrlFor('risk-indication'))
        } else {
            // everything was removed, skip onwards to the edit investment plan home via PDS screen
            // which will only show up if there is a new version to accept
            navigate(relativeUrlFor('pds'))
        }
    }

    // Save the draft plan whenever it changes
    useEffect(() => {
        if (isFirstRender) {
            return
        }
        saveDraftPlan()
    }, [newInvestmentPlan])

    return (
        <>
            <Toolbar
                leftButton="back"
                dataTestId="toolbar--edit-allocations"
                title="What percentage would you like in each investment?"
            />
            <PercentageModal
                key={editingFundId}
                initial={
                    editingFundId
                        ? newInvestmentPlan.find(a => a.fund_id === editingFundId)?.allocation_percent
                        : undefined
                }
                isOpen={!!editingFundId}
                onClose={() => setEditingFundId(undefined)}
                applyToAll={editingFundId === ALL_SELF_SELECT_FUNDS}
                editingFundCategory={editingFundCategory}
                onSelect={value => {
                    if (!editingFundId) {
                        throw new Error("Can't set with no fund id")
                    }
                    const isEditingAllocation = ({fund_category, fund_id}: Allocation) =>
                        editingFundId === ALL_SELF_SELECT_FUNDS
                            ? fund_category === 'SELF_SELECT'
                            : fund_id === editingFundId
                    const editiedFundCategory = newInvestmentPlan.find(isEditingAllocation)?.fund_category
                    if (!editiedFundCategory) {
                        throw new Error('Unknown fund category')
                    }

                    setNewInvestmentPlan(
                        newInvestmentPlan.map(allocation =>
                            isEditingAllocation(allocation)
                                ? {...allocation, allocation_percent: value.toString()}
                                : allocation,
                        ),
                    )
                }}
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                {(['BASE', 'SELF_SELECT'] as FundCategory[]).map((category: FundCategory) => {
                    const categoryAllocations = newInvestmentPlan.filter(a => a.fund_category === category)

                    const title = category === 'BASE' ? 'Base funds' : 'Your picks'
                    const totalPercent = categoryAllocations.reduce<Decimal>(
                        (acc, curr) => acc.plus(curr.allocation_percent),
                        new Decimal(0),
                    )
                    const addMorePicksText = category === 'BASE' ? '+ Add base funds' : '+ Add your picks'

                    const onAddMorePicks = () => {
                        if (category === 'SELF_SELECT') {
                            navigate(relativeUrlFor('add-funds'))
                        } else {
                            navigate(relativeUrlFor('base'))
                        }
                    }

                    return (
                        <div key={category} className={spacing.spaceBelow32}>
                            <div className={styles.allocationsTitle}>
                                <h3>{title}</h3>
                                <h3>{totalPercent.toString()}%</h3>
                            </div>
                            {category === 'SELF_SELECT' && categoryAllocations.length > 1 && (
                                <p className={spacing.spaceBelow16}>
                                    If you like you can&nbsp;
                                    <ButtonAsLink onClick={() => setEditingFundId(ALL_SELF_SELECT_FUNDS)}>
                                        apply the same amount to all
                                    </ButtonAsLink>
                                    .
                                </p>
                            )}
                            {categoryAllocations.length === 0 ? (
                                <NoInstrumentsRow category={category} />
                            ) : (
                                categoryAllocations.map(allocation => (
                                    <InstrumentRow
                                        key={allocation.fund_id}
                                        allocation={allocation}
                                        onRemove={() =>
                                            setNewInvestmentPlan(val =>
                                                val.filter(b => b.fund_id !== allocation.fund_id),
                                            )
                                        }
                                        onSelectPercentage={() => setEditingFundId(allocation.fund_id)}
                                    />
                                ))
                            )}
                            <div className={styles.addMorePicks}>
                                <ButtonAsLink
                                    dataTestId={`button--add-funds-${category.toLowerCase()}`}
                                    onClick={onAddMorePicks}
                                >
                                    {addMorePicksText}
                                </ButtonAsLink>
                            </div>
                        </div>
                    )
                })}
            </Page>
            <ActionBar className={styles.editAllocationActionBar}>
                {newPlanTotal.lte(100) ? (
                    <p>
                        <strong>{new Decimal(100).sub(newPlanTotal).toString()}%</strong> left to allocate to your base
                        funds or self-select picks
                    </p>
                ) : (
                    <p className={styles.error}>
                        <strong>{newPlanTotal.toString()}%</strong> allocated—you’ll need to reduce your total
                        allocation by {newPlanTotal.sub(100).toString()}%
                    </p>
                )}
                <Button
                    dataTestId="button--next"
                    label="Next"
                    disabled={!isValid}
                    processing={updateInvestmentPlan.isLoading}
                    onClick={onSubmit}
                />
            </ActionBar>
        </>
    )
}
