import {ModalLink} from '@design-system/modal/ModalLink'
import cn from 'classnames'
import Decimal from 'decimal.js'
import React from 'react'
import {Model} from '~/api/retail/types'
import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {totalTransactionFee} from '~/global/utils/order/order'
import {useAllSubscriptionPlans} from '~/global/utils/subscription-hooks/subscriptionHooks'
import {subscriptionName} from '~/global/utils/subscription-name/subscriptionName'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {BlinkpayTransferFeeModalLink} from '~/sections/OLD_wallet/sections/top-up/sections/top-up-instant/pages/form/Form'
import {BuySellOrder} from '~/store/accounting/types'
import {useAppSelector} from '~/store/hooks'
import {SubPlanV2} from '~/store/plan/types'
import styles from './Record.scss'

export const RecordRow = ({
    left,
    right,
    className,
    dataTestId,
}: {
    left: string | React.ReactNode
    right: string | React.ReactNode
    className?: string
    dataTestId?: string
}) => (
    <div className={cn(styles.recordRow, className)} data-testid={dataTestId}>
        <div className={cn({[styles.noWrap]: !right})}>{left}</div>
        <div className={cn({[styles.noWrap]: !right})}>{right}</div>
    </div>
)

export const TransactionFeeRecordRow = ({
    left,
    right,

    className,
    order,
}: {
    left: string | React.ReactNode
    right: string | React.ReactNode

    className?: string
    order: BuySellOrder
}) => {
    const {preferredName, isDependent} = useAppSelector(s => ({
        preferredName: s.identity.user!.preferred_name,
        isDependent: s.identity.user!.is_dependent,
    }))
    const allPlans = useAllSubscriptionPlans()

    // Covering plan is only present if a non-zero amount of coverage was used for the order
    const coveringPlan = order.covered_by_plan
    const coveringPlanData: SubPlanV2 | undefined = allPlans?.find(p => p.id === coveringPlan?.id)

    if (!coveringPlanData) {
        return <RecordRow left={left} right={right} className={className} />
    }

    const transactionFee = totalTransactionFee(order)

    const subscriptionPlanText = (subscriptionName: string) => {
        const subscriptionOwner = isDependent ? `${preferredName}'s ${subscriptionName}` : `your ${subscriptionName}`
        const fullyCovered = new Decimal(transactionFee).equals(new Decimal(0))

        return fullyCovered
            ? `Transaction fees were covered by ${subscriptionOwner}`
            : `Transaction fees were partially covered by ${subscriptionOwner}`
    }

    const subscriptionPlanName = subscriptionName(coveringPlanData)
    const transactionFeePlanText = subscriptionPlanText(subscriptionPlanName)

    return (
        <div className={cn(styles.transactionFeeRecordRow, className)}>
            <div className={cn(styles.recordInfo)}>
                <div className={cn({[styles.noWrap]: !right})}>{left}</div>
                <div className={cn({[styles.noWrap]: !right})}>{right}</div>
            </div>
            {transactionFeePlanText && <div className={cn(styles.feeDisclaimer)}>{transactionFeePlanText}</div>}
        </div>
    )
}

export const PayIDPaymentFeeModal = () => {
    return (
        <ModalLink
            label="PayID transfer fee"
            modalTitle="PayID transfer fee"
            dataTestId="modal-link--payid-transfer-fee"
            primaryButton={{label: 'Got it'}}
            asIcon
            helpIconSize={16}
        >
            <p>
                When you top up using PayID, a transfer fee is deducted from your top-up, this is a flat fee of $0.25.
            </p>
            <p>For example, if you transfer $20, you'll get $19.75 in your Wallet.</p>
        </ModalLink>
    )
}

const LinkedBankPaymentFeeModal = () => {
    return (
        <div>
            Linked bank transaction fee
            <ModalLink
                dataTestId="modal-link--payment-request-fees"
                label="Linked bank transaction fee"
                asIcon
                modalTitle="Linked bank transfer fee"
                primaryButton={{label: 'Got it'}}
            >
                <p>There’s a 2% transfer fee on payments from a linked bank capped at $2 AUD.</p>
                <p>
                    Linked bank transfers are free on a Sharesies pricing plan. You can sign up to a plan by heading to{' '}
                    <b>Account {'>'} Pricing plans</b>.
                </p>
            </ModalLink>
        </div>
    )
}

export const PaymentRequestFeeRow = ({
    paymentRequest,
    currency,
}: {
    paymentRequest: Model.PaymentRequest
    currency: Currency
}) => {
    return (
        <div className={cn(styles.transactionFeeRecordRow)}>
            <div className={styles.recordInfo}>
                {paymentRequest.provider === 'BLINKPAY' ? (
                    <BlinkpayTransferFeeModalLink />
                ) : paymentRequest.reason === 'PAY_ID' ? (
                    <div>
                        Transfer fee
                        <PayIDPaymentFeeModal />
                    </div>
                ) : (
                    <LinkedBankPaymentFeeModal />
                )}
                <div>
                    <DollarValue value={paymentRequest.fee} currency={currency} />
                </div>
            </div>
            {parseFloat(paymentRequest.fee) === 0 && (
                <small className={styles.feeDisclaimer}>
                    {paymentRequest.provider === 'BLINKPAY'
                        ? 'First time top-up fee covered by Sharesies'
                        : paymentRequest.reason === 'PAY_ID'
                          ? ''
                          : 'Transaction fees were covered by your pricing plan'}
                </small>
            )}
        </div>
    )
}
