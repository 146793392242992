import {Document} from '@contentful/rich-text-types'
import React from 'react'
import {RichText} from '~/sections/explore/sections/learn-articles/widgets/rich-text/RichText'
import W9CertificationSection2Striked from '~/sections/user/sections/terms-and-conditions/assets/contentful-entries/w9-certification-section2-striked.json'
import W9Cert from '~/sections/user/sections/terms-and-conditions/assets/contentful-entries/w9-certification.json'
import {legalDoc} from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/LegalDocuments.scss'

interface Props {
    subjectToBackupWithholding: boolean
}

export const W9Certification = ({subjectToBackupWithholding}: Props) => {
    return (
        <div className={legalDoc}>
            {subjectToBackupWithholding ? (
                <RichText richTextDocument={W9Cert.fields.content as Document} />
            ) : (
                <RichText richTextDocument={W9CertificationSection2Striked.fields.content as Document} />
            )}
        </div>
    )
}

export default W9Certification
