import {Button} from '@design-system/button'
import cn from 'classnames'
import {Form, Formik} from 'formik'
import React, {useState} from 'react'
import {Navigate, useNavigate} from 'react-router'
import uuid from 'uuid'
import {useRetailPost} from '~/api/query/retail'
import {spacing} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {useCurrentSubscriptionPlan} from '~/global/utils/subscription-hooks/subscriptionHooks'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ErrorBox, validate} from '~/global/widgets/form-controls'
import {StrongCurrency} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useLinkedBankPaymentsUrl} from '~/sections/OLD_wallet/sections/linked-bank-payments/hooks/useLinkedBankPaymentsUrl'
import {Confirmation} from '~/sections/OLD_wallet/sections/linked-bank-payments/pages/confirmation/Confirmation'
import {getTopupBreakdown} from '~/sections/OLD_wallet/sections/linked-bank-payments/utils/get-topup-breakdown/getTopupBreakdown'
import {TopUpBreakdown} from '~/sections/OLD_wallet/sections/linked-bank-payments/widgets/topup-breakdown/TopUpBreakdown'
import accountingActions from '~/store/accounting/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {actingAsID as actingAsIDSelector} from '~/store/identity/selectors'
import styles from './OneOffTopUp.scss'

const OneOffTopUp: React.FC = () => {
    const relativeUrlFor = useLinkedBankPaymentsUrl()
    // Generate an idempotency key the first time this component is rendered
    const [nonce] = useState(uuid.v4())
    const [topupAmount, setTopupAmount] = useState<string>()
    const accountRestricted = useAppSelector(({identity}) => identity.user!.account_restricted)
    const debitAccount = useAppSelector(({bankLinking}) => bankLinking.roundupsDebitAccount)
    const [currentSubscription] = useCurrentSubscriptionPlan()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const actingAsID = useAppSelector(actingAsIDSelector)
    const topupWithPaymentRequest = useRetailPost({path: 'banklinking/payment-request'})

    if (!debitAccount) {
        return <Navigate to={relativeUrlFor('select-debit-account')} replace />
    }

    const onSubmit = async () => {
        const topupBreakdown = getTopupBreakdown(topupAmount, currentSubscription)

        try {
            const response = await topupWithPaymentRequest.mutateAsync({
                acting_as_id: actingAsID,
                nonce,
                amount: topupBreakdown!.topupAmount.toString(),
            })
            if (response.type === 'empty') {
                // Reload in-progress payment requests in the wallet
                dispatch(accountingActions.init())
                Toast(`Choice! Your top up is on it's way 💸`)
                navigate(-1)
            }
        } catch (e) {
            // suppress
        }

        // Closes modal
        setTopupAmount(undefined)
    }

    const errorMessage =
        topupWithPaymentRequest.error?.type === 'error'
            ? topupWithPaymentRequest.error?.message
            : topupWithPaymentRequest.error?.type === 'internal_server_error'
              ? unknownErrorMessage
              : null

    return (
        <>
            <Toolbar dataTestId="toolbar--linked-bank-oneoff-topup" leftButton="back" />
            <Formik
                initialValues={{amount: '' as string}}
                validate={validate.generate({
                    amount: [
                        validate.required(),
                        validate.money(),
                        validate.minimum(5, 'The minimum top up amount is $5'),
                        validate.maximum(2000, 'The maximum top up amount is $2000'),
                    ],
                })}
                onSubmit={({amount}) => {
                    setTopupAmount(amount)
                }}
            >
                {({values, isValid}) => {
                    const topupBreakdown = isValid ? getTopupBreakdown(values.amount, currentSubscription) : undefined

                    return (
                        <Form className={styles.fullHeight}>
                            <Page className={styles.linkedBankTopupPage}>
                                <h1 className={spacing.spaceBelow24}>Make a one-off top-up</h1>
                                <StrongCurrency
                                    dataTestId="decimal-input--amount-to-top-up"
                                    name="amount"
                                    label="Top-up amount"
                                    disabled={accountRestricted}
                                    currency="aud"
                                />
                                <h2>From</h2>
                                <div
                                    className={cn(styles.debitAccountRow, spacing.spaceBelow32)}
                                    data-testid="linked-bank-topup--debit-row"
                                >
                                    <img src={debitAccount.bankLogo} />
                                    <div>
                                        <p>{debitAccount.accountName || debitAccount.bankName}</p>
                                        <p>{debitAccount.accountNumber}</p>
                                    </div>
                                </div>
                                <div className={styles.fillAvailableSpace} />
                                {topupBreakdown && <TopUpBreakdown topupBreakdown={topupBreakdown} />}
                                <ErrorBox message={errorMessage} className={spacing.spaceAbove12} />
                            </Page>
                            <ActionBar className={styles.amountActionBar}>
                                <Button label="Next" isSubmit dataTestId="button--banklinking-amount-next" />
                            </ActionBar>
                        </Form>
                    )
                }}
            </Formik>
            <Confirmation
                onSubmitHandler={onSubmit}
                topupBreakdown={getTopupBreakdown(topupAmount, currentSubscription)}
                isOpen={!!topupAmount}
                setIsOpen={() => setTopupAmount(undefined)}
                isSubmitting={topupWithPaymentRequest.isLoading}
            />
        </>
    )
}

export default OneOffTopUp
