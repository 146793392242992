import {QueryClient, QueryKey} from '@tanstack/react-query'
import isEqual from 'lodash.isequal'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            suspense: true,
        },
    },
})

/**
 * Exact matches on a query key and either:
 * - does nothing if the data is identical
 * - updates the data if it is different
 * - sets the data if there was no existing cache key
 *
 * Must have the data type passed in, for example:
 *
 * ```
 * cacheUpdateOrSet<ListingResponseDto>([apiEndpointForUrlSlug, instrument.urlSlug], instrument)
 * ```
 *
 * Manual cache updating has lots of scope for subtle errors, use sparingly and
 * test well.
 *
 * @param {QueryKey} queryKey - Exact match for the query key to update
 * @param {DataType} freshData - New data to write to the cache
 */
export const cacheUpdateOrSet = <DataType>(queryKey: QueryKey, freshData: DataType) => {
    queryClient.setQueryData<DataType>(queryKey, cachedData => {
        // if the cache already has identical data, we should skip updating to avoid rerender loops
        if (cachedData && isEqual(freshData, cachedData)) {
            return // if the updater function returns undefined, the query data will not be updated
        }

        // if it doesn't have any data at all, or if the data didn't match, set it
        return freshData
    })
}
