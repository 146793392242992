import React from 'react'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import AddressVerificationUpload from '~/sections/user/widgets/address-verification-upload/AddressVerificationUpload'
import {useAppDispatch} from '~/store/hooks'
import actions from '~/store/identity/actions'

export const AddressVerification: React.FunctionComponent<AddressVerificationProps> = ({
    onSuccess,
    onSkip,
    hideSkipButton,
}) => {
    const dispatch = useAppDispatch()
    const [processing, setProcessing] = React.useState<boolean>(false)
    const [files, setFiles] = React.useState<File[]>([])
    const [error, setError] = React.useState<string | undefined>(undefined)
    const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setProcessing(true)
        try {
            await dispatch(actions.AddressVerification(files))
            setProcessing(false)
            onSuccess()
        } catch (e) {
            setProcessing(false)
            setError('Could not upload your proof of identity document')
        }
    }

    const onChange = (mutate: (files: File[]) => File[]) => {
        setFiles(mutate(files))
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--address-verification" />
            <Page>
                <AddressVerificationUpload
                    files={files}
                    onChange={onChange}
                    error={error}
                    processing={processing}
                    submitHandler={submitHandler}
                    onSkip={onSkip}
                    onSuccess={onSuccess}
                    inSignUp={true}
                    hideSkipButton={hideSkipButton}
                />
            </Page>
        </>
    )
}

interface AddressVerificationProps {
    onSkip(): void
    onSuccess(): void
    hideSkipButton?: boolean
}

export default AddressVerification
