import {Button} from '@design-system/button'
import {CopyField} from '@design-system/copy-field'
import cn from 'classnames'
import React from 'react'
import ReactPixel from 'react-facebook-pixel'
import Analytics from '~/api/google-analytics/googleAnalytics'
import * as api from '~/api/retail'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {amountOfCardFee, amountIncludingCardFee} from '~/global/utils/card-fee-calculation/cardFeeCalculation'
import {UserSuitableError} from '~/global/utils/error-handling/errorHandling'
import {dateFormatFullMonth} from '~/global/utils/format-date/formatDate'
import {formatDollar} from '~/global/utils/format-dollar/formatDollar'
import use3DSecure from '~/global/utils/use3-d-secure/use3DSecure'
import CardForm from '~/global/widgets/credit-card-handling/card-form/CardForm'
import {Text, Checkbox, StrongCurrency} from '~/global/widgets/form-controls/formik'
import * as validate from '~/global/widgets/form-controls/validate'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {trackCreateGiftSuccess} from '~/sections/user/sections/share-the-love/sections/gifts/pages/purchase-gift-pay/GiftConfirmationForm'
import styles from '~/sections/website/Website.scss'

interface GiftState {
    amount: string
    email: string
    gift?: Model.Gift
    error?: string
}

interface ExtraValues {
    amount: string
    your_email: string
    your_name: string
    valid_customer: boolean
    accept_terms: boolean
}

interface Props {
    initialGift?: Model.Gift
}

export const CreateGift: React.FunctionComponent<Props> = ({initialGift}) => {
    const location = window.location.pathname.includes('au') ? 'au' : 'nz' // location should be either of create-gift OR create-gift-au, use the au url to determine location
    const currency: Model.User['home_currency'] = location === 'au' ? 'aud' : 'nzd' // extrapolate currency from location

    const [state, setState] = React.useState<GiftState>({
        amount: '0',
        email: '',
        gift: initialGift,
        error: undefined,
    })

    const {processCardRequiresAction} = use3DSecure(location)

    const validateIsTrue = async (value: boolean, message: string) => {
        const error = await validate.isTrue(message)(value, {})
        if (error) {
            return error
        }
    }

    const validateRequired = async (value: string) => {
        const error = await validate.required()(value, {})
        if (error) {
            return error
        }
    }

    const onSubmit = async (paymentMethodId: string, values?: ExtraValues, paymentIntentId?: string): Promise<void> => {
        if (!values) {
            return
        }
        const response = await api.post('gifting/purchase-payment-intent', {
            name: values.your_name,
            email: values.your_email,
            charge_amount: amountIncludingCardFee(values.amount, currency),
            gift_amount: values.amount,
            accept_terms: values.accept_terms as true,
            payment_method_id: paymentMethodId,
            payment_intent_id: paymentIntentId,
            currency,
        })
        switch (response.type) {
            case 'error':
                throw new UserSuitableError(response.message)
            case 'gifting_information':
                setState({...state, gift: response.gift})

                trackCreateGiftSuccess(location, values.amount)
                Analytics.pageNav('/create-gift/success')
                break
            case 'card_topup_action_required':
                const paymentIntentId = await processCardRequiresAction(response.client_secret)
                return onSubmit(paymentMethodId, values, paymentIntentId)
            case 'internal_server_error':
                throw new UserSuitableError(
                    'An error occurred while your Gift was processing. Your card may have been charged, please contact help@sharesies.co.nz and our team will process your Gift.',
                )
            default:
                assertNever(response)
        }
    }

    const renderSuccess = () => {
        const {gift, email} = state

        if (!gift) {
            return
        }

        return (
            <>
                <p className={spacing.spaceBelow24}>
                    We’ve emailed your Gift and receipt to <strong className={styles.boldText}>{email}</strong> or copy
                    it now.
                    <br />
                    It’s valid until {`${gift.gift_expires.toFormat(dateFormatFullMonth)}`}. Share it with love!
                </p>
                <CopyField
                    id="copy-gift-code"
                    dataTestId="copy-field--gift-code"
                    label="Copy your Gift code"
                    value={gift.gift_code.replace(/(...)(...)(...)(...)/, '$1-$2-$3-$4')}
                    additionalClassName={spacing.spaceBelow32}
                    helpText={`Code value ${formatDollar(
                        gift.gift_amount,
                        false,
                    )}. Valid until ${gift.gift_expires.toFormat(dateFormatFullMonth)}`}
                />
                <p>Thanks for supporting Sharesies and sharing your love.</p>
                <Button
                    additionalClassName={spacing.spaceAbove48}
                    label="Create another Gift?"
                    onClick={() => setState({...state, gift: undefined})}
                    dataTestId="button--create-another"
                />
            </>
        )
    }

    const renderForm = () => {
        const {amount} = state
        const chargeFee = amountOfCardFee(amount, currency)
        const chargeAmount = amountIncludingCardFee(amount, currency)
        return (
            <CardForm
                submitLabel={`Buy Gift for ${formatDollar(chargeAmount, false)}`}
                onSubmit={onSubmit}
                jurisdiction={location}
                preChildren={
                    <>
                        <Text
                            dataTestId="text-input--your-name"
                            label="Your name"
                            name="your_name"
                            validate={validateRequired}
                        />
                        <Text
                            dataTestId="text-input--your-email"
                            label="Your email"
                            name="your_email"
                            validate={async value => {
                                const error =
                                    (await validate.required()(value, {})) || (await validate.email()(value, {}))

                                if (error) {
                                    return error
                                }

                                setState({...state, email: value})
                                return
                            }}
                        />
                        <div className={spacing.spaceAbove16}>
                            <StrongCurrency
                                dataTestId="strong-currency--amount"
                                name="amount"
                                label=""
                                autoFocus
                                placeholder="Gift Amount"
                                decimalPlaces={3}
                                currency={currency}
                                validate={async value => {
                                    const error =
                                        (await validate.required()(value, {})) ||
                                        (await validate.money()(value, {})) ||
                                        (await validate.minimum(
                                            5,
                                            `This is under the minimum Gift amount of $5—you'll need to nudge it up a bit`,
                                        )(value, {})) ||
                                        (await validate.maximum(
                                            2000,
                                            `This is over the maximum Gift amount of $2000—bring it on back`,
                                        )(value, {}))

                                    if (error && value) {
                                        setState({...state, error})
                                        return
                                    }

                                    setState({...state, amount: value, error: undefined})
                                    return
                                }}
                            />
                            {state.error && <p className={cn(styles.error)}>{state.error}</p>}
                        </div>
                        <p className={spacing.spaceBelow16}>Gift any amount from $5 to $2000</p>
                        <p className={spacing.spaceBelow16}>
                            Your order will incur a{' '}
                            <a
                                target="_blank"
                                rel="noopener"
                                href={
                                    location === 'au'
                                        ? 'https://intercom.help/sharesies-au/en/articles/4983203-fees-on-sharesies'
                                        : 'https://intercom.help/sharesies/en/articles/813634-fees'
                                }
                            >
                                card processing fee
                            </a>{' '}
                            of {amount === '0' ? '$0.00' : formatDollar(chargeFee, false)}. Your financial institution
                            may charge you additional fees when paying for a Gift in another currency.
                        </p>
                    </>
                }
                childrenDefaultValues={{valid_customer: false, accept_terms: false}}
            >
                <Checkbox
                    dataTestId="checkbox--confirm-person-can-receive-gift"
                    label={
                        currency === 'nzd' ? (
                            <span>
                                I’ve checked the person receiving the Gift has or{' '}
                                <a
                                    href="https://intercom.help/sharesies/en/articles/1390115-sign-up-to-sharesies"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    can create a Sharesies account (or Kids Account)
                                </a>
                                . I understand this Gift can only be redeemed by a New Zealand investor.
                            </span>
                        ) : (
                            <span>
                                I’ve checked the person receiving the Gift has or{' '}
                                <a
                                    href="https://intercom.help/sharesies-au/en/articles/4983221-sign-up-to-sharesies"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    can create a Sharesies account
                                </a>
                                . I understand this Gift can only be redeemed by an Australian investor.
                            </span>
                        )
                    }
                    name="valid_customer"
                    validate={value => validateIsTrue(value, 'Please confirm eligibility')}
                />
                <Checkbox
                    dataTestId="checkbox--confirm-terms-and-conditions"
                    label={
                        <span>
                            I agree to the{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={
                                    location === 'au'
                                        ? 'https://www.sharesies.com.au/au-gift-terms-and-conditions'
                                        : 'https://www.sharesies.nz/gift-terms-and-conditions'
                                }
                            >
                                Sharesies Gift terms and conditions
                            </a>{' '}
                            and understand this gift is valid for 36 months.
                        </span>
                    }
                    name="accept_terms"
                    validate={value => validateIsTrue(value, 'You must accept the terms to continue')}
                />
            </CardForm>
        )
    }

    React.useEffect(() => {
        ReactPixel.pageView()
    }, [])

    return (
        <div className={styles.giftPage}>
            <div className={styles.createGiftHero}>
                <div className={styles.heroContent}>
                    <h1 className={spacing.spaceBelow24}>SHARESIES GIFTS</h1>
                    {state.gift ? (
                        <div className={styles.giftCard}>
                            <span>
                                <DollarValue
                                    value={state.gift.gift_amount}
                                    decimalPlaces={state.gift.gift_amount.includes('.00') ? 0 : 2}
                                />
                            </span>
                        </div>
                    ) : (
                        <div className={cn(spacing.spaceBelow24, styles.giftCardEmpty)}></div>
                    )}
                </div>
            </div>
            <div className={styles.colourModeWrapper}>
                <div className={styles.content}>{state.gift ? renderSuccess() : renderForm()}</div>
            </div>
        </div>
    )
}

export default CreateGift
