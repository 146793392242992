import {Duration} from 'luxon'

export type Period = '1w' | '1m' | '3m' | '6m' | '1y' | '5y' | 'All'

interface PeriodInformation {
    label: string
    duration: Duration
}

export const periodInformation: Record<Period, PeriodInformation> = {
    '1w': {
        duration: Duration.fromObject({days: 7}),
        label: 'week',
    },
    '1m': {
        duration: Duration.fromObject({months: 1}),
        label: 'month',
    },
    '3m': {
        duration: Duration.fromObject({months: 3}),
        label: '3 months',
    },
    '6m': {
        duration: Duration.fromObject({months: 6}),
        label: '6 months',
    },
    '1y': {
        duration: Duration.fromObject({years: 1}),
        label: 'year',
    },
    '5y': {
        duration: Duration.fromObject({years: 5}),
        label: '5 years',
    },
    All: {
        duration: Duration.fromObject({years: 40}), // This is overwritten so it's personalised for each investor's portfolio and their individual instrument duration.
        label: '',
    },
}
export const periods = Object.keys(periodInformation) as Period[]

export const getScreenReaderFriendlyTimePeriod = (option: string) => {
    // regex to match string that have digit preceded with character e.g., '1w', '1m', '1y
    const regex = /^(\d)([wmy])/
    if (option.match(regex)) {
        // replace character with a readable 'time period'
        const period = option.replace('w', ' week').replace('m', ' month').replace('y', ' year')
        return period.startsWith('1 ') ? period : `${period}s`
    } else {
        // otherwise pass-through the option
        return option
    }
}
