import {DateTime} from 'luxon'
import {REMEMBER_JURISDICTION_KEY} from '~/global/constants/global'

const extractReferrer = () => {
    const referrer = document.referrer
    if (referrer.includes('sharesies.com.au')) {
        return 'au'
    } else if (referrer.includes('sharesies.nz')) {
        return 'nz'
    }
    return null
}

const extractStoredJurisdiction = () => {
    const jurisdiction = localStorage.getItem(REMEMBER_JURISDICTION_KEY)
    if (jurisdiction && ['nz', 'au'].includes(jurisdiction)) {
        return jurisdiction
    }
    return null
}

const extractBrowserTimezone = () => {
    const timezone = DateTime.local().zoneName
    if (timezone.includes('Australia')) {
        return 'au'
    }
    return 'nz' // default to NZ
}

interface Props {
    useReferrer: boolean
    useStoredJurisdiction: boolean
    useBrowserTimezone: boolean
}

const defaultProps: Props = {
    useReferrer: false,
    useStoredJurisdiction: false,
    useBrowserTimezone: false,
}

{
    /* Estimate the user's location using any of the following methods
    as determined by the parameters:
        1. The website they came from
        2. A stored jurisdiction (only if previously logged in on the same device)
        3. Browser timezone

    */
}
export const estimateUserLocation = (props: Partial<Props>) => {
    const {useReferrer, useStoredJurisdiction, useBrowserTimezone} = {
        ...defaultProps,
        ...props,
    }

    if (useReferrer) {
        const opener = extractReferrer()
        if (opener) {
            return opener
        }
    }

    if (useStoredJurisdiction) {
        const storedJurisdiction = extractStoredJurisdiction()
        if (storedJurisdiction) {
            return storedJurisdiction
        }
    }

    if (useBrowserTimezone) {
        return extractBrowserTimezone()
    }
}
