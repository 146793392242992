import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {LoadingEllipsis} from '~/global/widgets/loading-ellipsis/LoadingEllipsis'
import styles from '~/sections/landing/widgets/loading-skeleton/LoadingSkeleton.scss'

export const LoadingSkeleton: React.FunctionComponent = () => {
    return (
        <>
            <div className={cn(styles.defaultLoadingPlaceholder, styles.headingPlaceholder, spacing.spaceBelow8)} />
            <div className={cn(styles.defaultLoadingPlaceholder, styles.subheadingPlaceholder)} />
            <LoadingEllipsis />
        </>
    )
}
