import cn from 'classnames'
import React from 'react'
import {Slider, Rail, Handles, Tracks, Ticks} from 'react-compound-slider'
import {Handle, Track, TooltipRail, Tick} from './CompoundSliderElements'
import styles from './CompoundSlider.scss'

interface CompoundSliderProps {
    handleRiskRangeUpdate(riskRange: [number, number]): void
    currentRiskRange: [number, number] | null
    hideTitle?: boolean // TODO: eventually remove title after we finish building the new search and filter page
}

class CompoundSlider extends React.Component<CompoundSliderProps, {}> {
    state = {
        values: this.props.currentRiskRange ? this.props.currentRiskRange : [1, 7],
        valuesOnUpdate: this.props.currentRiskRange ? this.props.currentRiskRange : [1, 7],
        domain: [1, 7],
    }

    componentDidUpdate = (prevProps: CompoundSliderProps) => {
        if (prevProps.currentRiskRange !== this.props.currentRiskRange) {
            this.setState({
                ...this.state,
                values: this.props.currentRiskRange ? this.props.currentRiskRange : [1, 7],
                valuesOnUpdate: this.props.currentRiskRange ? this.props.currentRiskRange : [1, 7],
            })
        }
    }

    onChange = (values: readonly number[]) => {
        this.setState({values})
        this.props.handleRiskRangeUpdate(values as [number, number])
    }

    onUpdate = (values: readonly number[]) => {
        this.setState({valuesOnUpdate: values})
    }

    render() {
        const {values, valuesOnUpdate, domain} = this.state

        const TooltipText = () => {
            const riskDeviation: {[key: number]: string} = {
                1: '0.5%',
                2: '2%',
                3: '5%',
                4: '10%',
                5: '15%',
                6: '25%',
                7: '25%',
            }

            const isRange = valuesOnUpdate[0] !== valuesOnUpdate[1]
            const riskDeviationForRangeStart = riskDeviation[valuesOnUpdate[0]]
            const riskDeviationForRangeEnd = riskDeviation[valuesOnUpdate[1]]

            if (isRange) {
                if (valuesOnUpdate[1] === 7) {
                    return (
                        <p>
                            You accept losses from <span>-{riskDeviationForRangeStart}</span> to over{' '}
                            <span>-{riskDeviationForRangeEnd}</span> and gains from{' '}
                            <span>{riskDeviationForRangeStart}</span> to over <span>{riskDeviationForRangeEnd}</span>.
                        </p>
                    )
                }
                return (
                    <p>
                        You accept losses between <span>-{riskDeviationForRangeStart}</span> and{' '}
                        <span>-{riskDeviationForRangeEnd}</span> and gains between{' '}
                        <span>{riskDeviationForRangeStart}</span> and <span>{riskDeviationForRangeEnd}</span>.
                    </p>
                )
            }

            // isWholeNumbers
            if (valuesOnUpdate[1] === 7) {
                return (
                    <p>
                        You accept losses over <span>-{riskDeviationForRangeStart}</span> and gains over{' '}
                        <span>{riskDeviationForRangeStart}</span>.
                    </p>
                )
            }

            // isWholeNumbers
            return (
                <p>
                    You accept losses up to <span>-{riskDeviationForRangeStart}</span> and gains up to{' '}
                    <span>{riskDeviationForRangeStart}</span>.
                </p>
            )
        }

        return (
            <div
                className={cn(styles.sliderContainer, {
                    [styles.withoutTitle]: this.props.hideTitle,
                })}
            >
                {!this.props.hideTitle && <h2>Select fund risk level</h2>}
                <Slider
                    mode={1}
                    step={1}
                    domain={domain}
                    className={styles.slider}
                    onChange={this.onChange}
                    onUpdate={this.onUpdate}
                    values={this.props.currentRiskRange ? this.props.currentRiskRange : values}
                >
                    <Ticks count={1} values={[1, 2, 3, 4, 5, 6, 7]}>
                        {({ticks}) => (
                            <div className="slider-ticks">
                                {ticks.map(tick => (
                                    <Tick key={tick.id} tick={tick} count={ticks.length} values={valuesOnUpdate} />
                                ))}
                            </div>
                        )}
                    </Ticks>
                    <Rail>
                        {railProps => (
                            <TooltipRail
                                {...railProps}
                                values={valuesOnUpdate}
                                currentRiskRange={this.props.currentRiskRange}
                            />
                        )}
                    </Rail>
                    <Handles>
                        {({handles, activeHandleID, getHandleProps}) => (
                            <div className="slider-handles">
                                {handles.map(handle => (
                                    <Handle
                                        key={handle.id}
                                        handle={handle}
                                        domain={domain}
                                        isActive={handle.id === activeHandleID}
                                        getHandleProps={getHandleProps}
                                    />
                                ))}
                            </div>
                        )}
                    </Handles>
                    <Tracks left={false} right={false}>
                        {({tracks, getTrackProps}) => (
                            <div className="slider-tracks">
                                {tracks.map(({id, source, target}) => (
                                    <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                                ))}
                            </div>
                        )}
                    </Tracks>
                </Slider>
                <div>
                    <p>Lower</p>
                    <p>Higher</p>
                </div>
                <div className={styles.tooltip}>
                    <TooltipText />
                </div>
            </div>
        )
    }
}

export default CompoundSlider
