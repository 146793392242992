import Decimal from 'decimal.js'
import React from 'react'
import {useRetailPost} from '~/api/query/retail'
import {useAppSelector} from '~/store/hooks'
import {actingAsID, getJurisdiction} from '~/store/identity/selectors'
import {Instrument} from '~/store/instrument/types'
import {StagedSellOrder} from '~/store/order/types'

export const valueOfSellOrder = ({
    numberToSell,
    marketPrice,
    priceLimit,
    triggerPrice,
}: {
    numberToSell: number
    marketPrice: number
    priceLimit?: number
    triggerPrice?: number
}) => {
    // Initially use the market price
    let expectedExecutionPrice = marketPrice

    // If there's a trigger price and it's lower than the expected execution price so far, use that
    if (triggerPrice && triggerPrice < expectedExecutionPrice) {
        expectedExecutionPrice = triggerPrice
    }

    // If there's a limit price that's higher than the expected execution price so far, use that
    if (priceLimit && priceLimit > expectedExecutionPrice) {
        expectedExecutionPrice = priceLimit
    }

    return expectedExecutionPrice * numberToSell
}

const valueOfSellFromOrder = ({
    stagedSellOrder,
    instrument,
}: {
    stagedSellOrder: StagedSellOrder
    instrument: Instrument
}) => {
    const {orderPriceLimit, orderShareAmount, orderTriggerPrice} = stagedSellOrder
    const {marketPrice} = instrument

    const priceLimit = orderPriceLimit ? parseFloat(orderPriceLimit) : undefined
    const numberToSell = orderShareAmount ? parseFloat(orderShareAmount) : 0
    const triggerPrice = orderTriggerPrice ? parseFloat(orderTriggerPrice) : 0

    return valueOfSellOrder({numberToSell, marketPrice: parseFloat(marketPrice), priceLimit, triggerPrice})
}

export const amountOfManagedFundFee = (amount: string, feePercentage?: string): number => {
    if (feePercentage) {
        // some managed funds don't have a fee
        return parseFloat(amount) * (parseFloat(feePercentage) / 100)
    }
    return 0
}

export const useCorporateFeeForStagedSellOrder = ({
    stagedSellOrder,
    instrument,
}: {
    stagedSellOrder?: StagedSellOrder
    instrument?: Instrument
}) => {
    const [corporateFee, setCorporateFee] = React.useState<number>()

    const isKiwiSaverOrder = stagedSellOrder?.ksFundHolding

    const path = isKiwiSaverOrder ? 'kiwisaver/order/calculate-fee' : 'order/calculate-fee'
    const calculateFeeQuery = useRetailPost({path, options: {retry: 5}})

    const acting_as_id = useAppSelector(actingAsID)
    const jurisdiction = useAppSelector(getJurisdiction)

    React.useEffect(() => {
        async function fetchFee() {
            // Revert to loading state
            setCorporateFee(undefined)

            if (!stagedSellOrder || !instrument) {
                setCorporateFee(0)
                return
            }

            const amount = valueOfSellFromOrder({stagedSellOrder, instrument})

            try {
                const response = await calculateFeeQuery.mutateAsync({
                    acting_as_id,
                    gross_amount: amount.toString(),
                    fund_id: instrument.id,
                })

                if (response.type === 'order_calculate_fee') {
                    setCorporateFee(new Decimal(response.fee).toNumber())
                }
            } catch {
                // Silent fail
            }
        }

        fetchFee()
    }, [stagedSellOrder, instrument, jurisdiction])

    return corporateFee
}

export const useAmountWithFeeForLimitBuy = ({
    price_limit,
    share_amount,
    instrument,
}: {
    price_limit: string | number | Decimal
    share_amount: string | number | Decimal
    instrument: Instrument
}) => {
    const [amountWithFee, setAmountWithFee] = React.useState<number>()

    const calculateFeeQuery = useRetailPost({path: 'order/calculate-fee', options: {retry: 5}})

    const acting_as_id = useAppSelector(actingAsID)
    const jurisdiction = useAppSelector(getJurisdiction)

    React.useEffect(() => {
        async function fetchFee() {
            // Revert to loading state
            setAmountWithFee(undefined)

            if (!price_limit || !share_amount) {
                setAmountWithFee(0)
                return
            }

            const priceLimit = new Decimal(price_limit)
            const shareAmount = new Decimal(share_amount)

            const netAmount = priceLimit.mul(shareAmount)

            try {
                const response = await calculateFeeQuery.mutateAsync({
                    acting_as_id,
                    net_amount: netAmount.toString(),
                    fund_id: instrument.id,
                })

                if (response.type === 'order_calculate_fee') {
                    setAmountWithFee(netAmount.add(new Decimal(response.fee)).toNumber())
                }
            } catch {
                // Silent fail
            }
        }

        fetchFee()
    }, [price_limit, share_amount, jurisdiction])

    return amountWithFee
}
