import {Button} from '@design-system/button'
import {SelectCard} from '@design-system/select-cards'
import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {AccountLinkingCommonProps} from '~/sections/moose/sections/sign-up/sections/account-linking/AccountLinking'
import {useSignupEntityDetail} from '~/sections/moose/sections/sign-up/utils/sign-up-detail-hooks/signUpDetailHooks'

const ExistingAccountCheck = ({regressStep, navTo}: AccountLinkingCommonProps) => {
    const [hasExistingAccount, setHasExistingAccount] = React.useState<boolean>()
    const signupEntityDetail = useSignupEntityDetail()
    const hasMultipleEntities = signupEntityDetail.entities.length > 1

    const onContinue = () => {
        navTo(hasExistingAccount ? 'login' : 'new-account')
        rudderTrack('fonterra_onboarding', 'existing_sharesies_account_continue_clicked', {
            label: hasExistingAccount ? 'yes' : 'no',
        })
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--fonterra-signup-existing-account-check"
                leftButton="back"
                onLeftButtonClick={regressStep}
                title="Do you have a Sharesies account?"
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <SelectCard
                    dataTestId="select-card--existing-account"
                    title="Yes, I have a Sharesies account"
                    value="existing_account"
                    name="hasExistingAccount"
                    onChange={() => setHasExistingAccount(true)}
                    supportingText={`We’ll add your business${
                        hasMultipleEntities ? 'es' : ''
                    } to your account. You’ll only need one password and we’ll keep your personal investments separate.`}
                    isActive={hasExistingAccount === true}
                />
                <SelectCard
                    dataTestId="select-card--no-account"
                    title="No, I don’t have an account"
                    value="no_account"
                    name="hasExistingAccount"
                    onChange={() => setHasExistingAccount(false)}
                    supportingText="We’ll just need a few more details to get you set up."
                    isActive={hasExistingAccount === false}
                />
                <Button
                    label="Continue"
                    type="primary"
                    dataTestId="button--continue"
                    onClick={onContinue}
                    pageButton
                    disabled={hasExistingAccount === undefined}
                />
            </Page>
        </>
    )
}

export default ExistingAccountCheck
