import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {useShowIntercom} from '~/global/utils/use-show-intercom/useShowIntercom'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import HelpEmail from '~/global/widgets/help-email/HelpEmail'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from './ClientUpgradeRequired.scss'

const ClientUpgradeRequired: React.FunctionComponent<{}> = () => {
    const showIntercom = useShowIntercom()
    const handleUpdate = () => {
        // Unregister the service worker if there is already one exists to prevent Safari clients being stuck, and make sure we have no caching issues.
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(registrations => {
                let registrationsUnregistered = 0
                for (const registration of registrations) {
                    registration.unregister().then(() => {
                        registrationsUnregistered = registrationsUnregistered + 1
                        if (registrationsUnregistered === registrations.length) {
                            // wait until we've finished unregistering everything before doing the reload
                            window.location.replace(window.location.href) // force reload rather than soft reload
                        }
                    })
                }
            })
        } else {
            window.location.replace(window.location.href) // force reload rather than soft reload
        }
    }

    return (
        <div className={styles.fullPage}>
            <Toolbar dataTestId="toolbar--client-upgrade-required" title="Updates" />
            <Page>
                <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow12)}>
                    We’ve made some updates to Sharesies
                </h2>
                <p className={spacing.spaceBelow24}>
                    Sharesies has changed a bit since you were here last—you’ll need to reload this page to get the
                    latest updates!
                </p>
                <p>
                    Any problems with updating or have questions? Contact the{' '}
                    <ButtonAsLink onClick={showIntercom}>Help Centre</ButtonAsLink> or email us at <HelpEmail />.
                </p>
                <Button
                    label="Go go gadget reload"
                    onClick={handleUpdate}
                    pageButton
                    dataTestId="button--go-go-gadget"
                />
            </Page>
        </div>
    )
}

export default ClientUpgradeRequired
