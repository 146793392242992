import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal/ModalLink'
import {SelectCards} from '@design-system/select-cards'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {Mode} from '../identity-check/util'
import {getNextStep, ManualIdentityStep} from './util'
import styles from './OtherIdentityDetails.scss'

const OtherIdentityDetails: React.FunctionComponent<OtherIdentitySelectorProps> = ({mode, setMode, step, setStep}) => {
    const onSelectMode = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setMode(e.target.value as Mode)
    }

    return (
        <>
            <h1 className={spacing.spaceBelow16}>Other IDs you can sign up with</h1>
            <p className={cn(styles.p, spacing.spaceBelow16)}>
                To sign up using one of the following forms of ID, you’ll also need to have a{' '}
                <NZAccessCardModal
                    setStep={setStep}
                    mode={mode}
                    setMode={setMode}
                    step={step}
                    label="Kiwi Access Card"
                />
                .
            </p>
            <h2 className={spacing.spaceBelow16}>What ID would you like to use to sign up?</h2>
            <p className={spacing.spaceBelow16}>
                You’ll need to get a copy of the ID you choose certified, and send it along with a copy of your Kiwi
                Access Card to <a href="mailto:help@sharesies.co.nz"> help@sharesies.co.nz</a>.
            </p>

            <SelectCards
                additionalClassName={spacing.spaceBelow48}
                dataTestId="select-cards--id-type"
                title="Choose an ID type"
                hideTitle
                onChange={onSelectMode}
                options={[
                    {
                        name: 'FullNZBirthCertificate',
                        title: 'Full NZ birth certificate',
                        isActive: mode === 'fullNZBirthCert',
                        value: 'fullNZBirthCert',
                    },
                    {
                        name: 'NZCitizenshipCertificate',
                        title: 'NZ citizenship certificate',
                        isActive: mode === 'NZCitizenCert',
                        value: 'NZCitizenCert',
                    },
                    {
                        name: 'FullOverseasBirthCertificate',
                        title: 'Full overseas birth certificate',
                        isActive: mode === 'fullOverseasBirthCert',
                        value: 'fullOverseasBirthCert',
                    },
                    {
                        name: 'OverseasCitizenshipCertificateWithEnglishTranslation',
                        title: 'Overseas citizenship certificate with English translation',
                        isActive: mode === 'engTransOverseasCitizenCert',
                        value: 'engTransOverseasCitizenCert',
                    },
                ]}
                short
            />
            <Button
                additionalClassName={styles.button}
                label="Next"
                disabled={mode === 'other'}
                onClick={() => setStep(getNextStep(step, mode))}
                dataTestId=""
            ></Button>
        </>
    )
}

interface OtherIdentitySelectorProps {
    setStep(step: ManualIdentityStep): void
    mode: Mode
    setMode(mode: Mode): void
    step: ManualIdentityStep
    className?: string
    label: string
}
const NZAccessCardModal: React.FunctionComponent<OtherIdentitySelectorProps> = ({label}) => (
    <ModalLink
        dataTestId="modal-link--nz-access"
        label={label}
        asIcon={!label}
        modalTitle="Kiwi Access Card"
        primaryButton={{label: 'Ok'}}
    >
        <p>
            The Kiwi Access Card is an evidence-of-age card issued by Hospitality New Zealand. It was previously known
            as the 18+ Card. <a href="https://kiwiaccess.co.nz/"> Find out more</a>
        </p>
    </ModalLink>
)

export default OtherIdentityDetails
