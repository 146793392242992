import * as Icons from '@design-system/icon'
import {RawButton} from '@design-system/raw-button'
import React from 'react'
import {debounce} from 'throttle-debounce'
import styles from './ButtonCircle.scss'

export interface ButtonCircleProps {
    ariaLabel?: string
    dataTestId: string
    additionalClassName?: string
    label: string | React.ReactNode
    icon: Icons.Icons
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const ButtonCircle = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonCircleProps>((props, ref) => {
    const {ariaLabel, dataTestId, additionalClassName, label, icon, onClick} = props
    const Icon = Icons[icon]

    const buttonContent = (
        <div className={styles.buttonCircle}>
            <div className={styles.icon}>{Icon && <Icon size={16} />}</div>
            <div className={styles.label}>{label}</div>
        </div>
    )

    // prevent accidental double-click action on buttons
    const debouncedOnClick = debounce(
        300,
        (e: React.MouseEvent<HTMLButtonElement>) => {
            if (onClick) {
                onClick(e)
            }
        },
        {atBegin: true},
    )

    return (
        <RawButton
            ref={ref as React.ForwardedRef<HTMLButtonElement>}
            dataTestId={dataTestId}
            additionalClassName={additionalClassName}
            onClick={debouncedOnClick}
            type="button"
            ariaLabel={ariaLabel}
            label={buttonContent}
        />
    )
})
