import cn from 'classnames'
import React from 'react'
import {ListingResponseDto} from '~/api/distill'
import {spacing} from '~/global/scss/helpers'
import {convertDecimalToPercentage} from '~/global/utils/convert-decimal-to-percentage/convertDecimalToPercentage'
import {PercentValue} from '~/global/widgets/number-elements/NumberElements'
import styles from './TrioInfo.scss'

const TrioInfo: React.FunctionComponent<{instrument: ListingResponseDto}> = ({instrument: instrument}) => {
    const instrumentHasDetails = instrument.managementFeePercent && instrument.timeHorizonMin && instrument.riskRating
    return (
        <>
            {instrumentHasDetails && (
                <div className={cn(styles.container, spacing.spaceAbove16)}>
                    <div className={cn(styles.itemContainer, styles.firstContainer)}>
                        <div>
                            <div className={styles.value}>
                                <PercentValue value={convertDecimalToPercentage(instrument!.managementFeePercent!)} />
                            </div>
                            <p className={styles.label}>Annual fund charge</p>
                        </div>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.itemContainer}>
                        <div>
                            <div className={styles.value}>
                                <span>{instrument.timeHorizonMin}+</span>{' '}
                                <span className={styles.smallValue}>years</span>
                            </div>
                            <p className={styles.label}>Timeframe</p>
                        </div>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.itemContainer}>
                        <div>
                            <div className={styles.value}>
                                <span>{instrument.riskRating} /</span> <span className={styles.smallValue}>7</span>
                            </div>
                            <p className={styles.label}>Risk rating</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default TrioInfo
