import {Button} from '@design-system/button'
import {withFormik} from 'formik'
import React from 'react'
import {Model, Request} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {ErrorBox, validate} from '~/global/widgets/form-controls'
import {
    CountriesType,
    extractCountryNameFromCode,
} from '~/global/widgets/form-controls/country-select/CountrySelectInput'
import {CountrySelectInput, Radio} from '~/global/widgets/form-controls/formik'
import TaxResidency from '~/global/widgets/help-modals/TaxResidency'

interface EditTaxResidenciesFormValues {
    foreign_tax_resident?: 'yes' | 'no'
    countries: CountriesType
}

interface EditTaxResidenciesFormProps {
    foreignTaxResident?: boolean
    initialFTRYes?: boolean
    taxResidencies: Model.User['tax_residencies']
    isDependent: boolean
    preferredName: string
    jurisdiction: Model.User['jurisdiction']
    taxDeclareYes(countries: CountriesType): Promise<string | void | null>
    taxDeclareNo(): Promise<string | void | null>
    successAction(): void
}

export const EditTaxResidenciesForm = withFormik<EditTaxResidenciesFormProps, EditTaxResidenciesFormValues>({
    mapPropsToValues: ({taxResidencies, initialFTRYes}) => ({
        foreign_tax_resident: initialFTRYes === true || taxResidencies.length > 0 ? 'yes' : undefined,
        countries: taxResidencies.length
            ? taxResidencies.map(({country, tin}) => ({
                  country,
                  tax_information_number: tin,
              }))
            : [{country: '', tax_information_number: ''}],
    }),
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        countries: undefined,
    }),
    handleSubmit: async (
        {foreign_tax_resident, countries},
        {setSubmitting, setStatus, props: {taxDeclareYes, taxDeclareNo, successAction}},
    ) => {
        countries = countries || []
        try {
            const error = await (foreign_tax_resident ? taxDeclareYes(countries) : taxDeclareNo())
            if (error) {
                setStatus(error)
                return
            }
            successAction()
        } catch (e) {
            setStatus(unknownErrorMessage)
            throw e
        } finally {
            setSubmitting(false)
        }
    },
    validate: validate.generate<EditTaxResidenciesFormValues>({
        foreign_tax_resident: [validate.required('You must answer yes or no')],
        countries: [
            (value, values) => {
                type ValidCountry = Request.IdentitySignUpTaxQuestions['countries'][0]
                const validCountries = value.filter((c): c is ValidCountry => c.country !== '')

                if (
                    values.foreign_tax_resident === 'yes' &&
                    (validCountries.length === 0 || !!value.find(country => country.country === ''))
                ) {
                    return 'Oops, you haven’t added a country'
                }
            },
        ],
    }),
})(({
    isSubmitting,
    handleSubmit,
    status,
    errors,
    touched,
    values,
    isValid,
    isDependent,
    preferredName,
    taxResidencies,
    jurisdiction,
}) => {
    const defaultTaxResidency = extractCountryNameFromCode(jurisdiction)
    // checks whether the user has made changes to their FTR information - if not, the save button will be disabled
    const FTRIsValid = (
        values: EditTaxResidenciesFormValues,
        taxResidencies: Model.User['tax_residencies'],
    ): boolean => {
        // user is on no - button will always be enabled
        if (!values.foreign_tax_resident) {
            return true
        }

        // if the user is a FTR and they have added or removed a country from their FTR they can save (as changes have been made)
        if (values.countries.length !== taxResidencies.length) {
            return true
        }

        // determining whether user has changed any of their existing FTR information (i.e. country or tax info number) - if so they can save.
        if (taxResidencies.length > 0) {
            let changesToFTRInformation = false
            values.countries.forEach(valueCountry => {
                const indexOfCountry = values.countries.indexOf(valueCountry)
                if (
                    taxResidencies[indexOfCountry].country !== valueCountry.country ||
                    taxResidencies[indexOfCountry].tin !== valueCountry.tax_information_number
                ) {
                    changesToFTRInformation = true
                    return
                }
            })
            return changesToFTRInformation
        }

        return false
    }

    return (
        <form onSubmit={handleSubmit}>
            <Radio
                dataTestId="radio--tax-residency"
                name="foreign_tax_resident"
                label={
                    <>
                        {isDependent ? `Is ${preferredName}` : 'Are you'} a tax resident of another country outside{' '}
                        {jurisdiction === 'nz' ? 'NZ' : defaultTaxResidency}?&nbsp;&nbsp;
                        <TaxResidency jurisdiction={jurisdiction} />
                    </>
                }
                choices={[
                    {label: 'Yes', value: 'yes'},
                    {label: 'No', value: 'no'},
                ]}
            />
            {values.foreign_tax_resident && (
                <CountrySelectInput
                    isTaxCountryInput
                    name="countries"
                    isDependent={isDependent}
                    preferredName={preferredName}
                    jurisdiction={jurisdiction}
                />
            )}
            <ErrorBox message={status || (!!touched.countries && errors.foreign_tax_resident)} />
            {values.foreign_tax_resident !== undefined && (
                <div className={spacing.spaceAbove48}>
                    <Button
                        label="Save"
                        dataTestId="button--save"
                        isSubmit
                        disabled={!isValid || !FTRIsValid(values, taxResidencies)}
                        processing={isSubmitting}
                    />
                </div>
            )}
        </form>
    )
})

export default EditTaxResidenciesForm
