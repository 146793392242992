export const REFERRAL_INFORMATION_KEY = 'referralInformationTimestamp'
export const REMEMBER_JURISDICTION_KEY = 'rememberedJurisdiction'
export const IDENTITY_VERIFICATION_MODE_KEY = 'sharesies_signup_id_mode'
export const VERIFI_CLOUDCHECK_LIVE = 'VERIFI_CLOUDCHECK_LIVE'
export const IDENTITY_VERIFICATION_INTRO_SHOW_AFTER = 'IDENTITY_VERIFICATION_INTRO_SHOW_AFTER'
export const KS_CUSTOMER_HAS_SEEN_PENDING_REGISTRATION = 'ks_customer_has_seen_pending_registration'
export const CUSTOMER_SIGNUP_VERSION = 2

/**
 * This is duplicated from `~/src/plib/scss/config.scss`
 * IF IT NEEDS TO CHANGE, CHANGE IN BOTH PLACES
 * One day, we'll have a solution to have a single source of truth for these values
 */
export const VIEWPORT_WIDTHS = {
    DESKTOP: 834,
}
