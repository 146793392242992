import {DateTime} from 'luxon'
import React from 'react'
import {useLatestInstrumentReturns} from '~/global/state-hooks/rakaia/useLatestInstrumentReturns'
import {useApplicationPointers} from '~/global/state-hooks/retail/useApplicationPointers'
import {useEmployeeShareSchemeDetails} from '~/global/state-hooks/retail/useEmployeeShareSchemeDetails'
import {useInstrumentApplications} from '~/global/state-hooks/retail/useInstrumentApplications'
import {generateEmployeeShareSchemeText} from '~/sections/invest/sections/employee-share-scheme/utils/ess-badge-label/essBadgeLabel'
import {useAppSelector} from '~/store/hooks'
import {PortfolioItems} from '~/store/portfolio/types'

export const usePortfolioItemsById = () => {
    const portfolioId = useAppSelector(s => s.identity.user!.portfolio_id)
    const {orders, holdings, employmentInstrumentIds} = useAppSelector(s => s.identity)
    const instrumentReturns = useLatestInstrumentReturns(portfolioId).instrument_returns
    const applicationsByInstrument = useInstrumentApplications(portfolioId)
    const applicationPointers = useApplicationPointers()
    const shareSchemeDetails = useEmployeeShareSchemeDetails()

    const itemsById = React.useMemo(() => {
        const items: PortfolioItems = {}
        const emptyInitialStats: Omit<PortfolioItems[number], 'instrumentId'> = {
            buyOrderCount: 0,
            sellOrderCount: 0,
            applicationCount: 0,
            voteCount: 0,
            upcomingVoteCount: 0,
            exerciseDaysRemaining: undefined,
            exerciseApplicationCount: 0,
            transferTotal: 0,
            transferOrderCount: 0,
            sharesOwned: 0,
            simpleReturn: 0,
            unrealisedSimpleReturn: 0,
            investmentValue: 0,
            hasEssEmployment: false,
            essBadgeLabel: '',
        }

        // Process instrument holdings from Rakaia
        if (instrumentReturns) {
            Object.keys(instrumentReturns).forEach(instrumentId => {
                const fundHoldings = holdings.find(element => element.fund_id === instrumentId)

                const sharesOwned =
                    fundHoldings && fundHoldings.shares && Number(fundHoldings.shares) > 0
                        ? Number(fundHoldings.shares) // Display holdings from backend retail for greater accuracy
                        : instrumentReturns && instrumentReturns[instrumentId]
                          ? instrumentReturns[instrumentId].shares_owned // Fall back to Rakaia shares_owned figure
                          : 0

                items[instrumentId] = {
                    ...emptyInitialStats,
                    instrumentId,
                    sharesOwned,
                    simpleReturn: instrumentReturns[instrumentId].simple_return,
                    unrealisedSimpleReturn: instrumentReturns[instrumentId].unrealised_simple_return,
                    investmentValue: instrumentReturns[instrumentId].investment_value,
                }
            })
        }

        // Check for pending order info
        orders.forEach(order => {
            if (order.type === 'withdrawal') {
                return
            }

            const fundId = order.fund_id

            // Add instruments to our map if they don't yet exist
            if (!items[fundId]) {
                items[fundId] = {
                    ...emptyInitialStats,
                    instrumentId: fundId,
                }
            }

            switch (order.type) {
                case 'buy':
                    items[fundId].buyOrderCount += 1
                    break
                case 'sell':
                    items[fundId].sellOrderCount += 1
                    break
                case 'corporate_action_v2':
                    if (order.action_type === 'VOTE') {
                        items[fundId].voteCount += 1
                    } else {
                        items[fundId].applicationCount += 1
                        if (order.action_type === 'EXERCISE') {
                            items[fundId].exerciseApplicationCount += 1
                        }
                    }
                    break
                case 'csn_transfer_order':
                    items[fundId].transferTotal = items[fundId].transferTotal + Number(order.shares)
                    items[fundId].transferOrderCount += 1
                    break
            }
        })

        // Get upcoming vote info
        applicationPointers.forEach(pointer => {
            const fundId = pointer.fund_id
            // If there isn't already a pending vote, show the upcoming vote
            if (
                pointer.type === 'VOTE' &&
                !orders.some(
                    o =>
                        o.type === 'corporate_action_v2' &&
                        o.id === pointer.id &&
                        o.application?.is_cancelled === false,
                )
            ) {
                // if we don't yet have an object for this order in our map yet
                if (!items[fundId]) {
                    items[fundId] = {
                        ...emptyInitialStats,
                        instrumentId: fundId,
                    }
                }

                items[fundId].upcomingVoteCount++
            }

            // If there isn't already a pending exercise application, show the days remaining to exercise)
            if (
                pointer.type === 'EXERCISE' &&
                !orders.some(
                    o =>
                        o.type === 'corporate_action_v2' &&
                        o.id === pointer.id &&
                        o.application?.is_cancelled === false,
                )
            ) {
                const applicationsCloseAt = applicationsByInstrument[fundId]?.find(
                    a => a.application_rule_id === pointer.application_rule_id,
                )?.applications_close_at

                if (items[fundId]) {
                    if (applicationsCloseAt && applicationsCloseAt > DateTime.local()) {
                        items[fundId].exerciseDaysRemaining = Math.ceil(applicationsCloseAt.diffNow('days').days)
                    } else if (applicationsCloseAt) {
                        // catch applications that have closed since we last updated applicationsByInstrument
                        items[fundId].exerciseDaysRemaining = 0
                    }
                }
            }

            // if there was recently an exercise application for this instrument, enable the expired label by setting exercise days remaining
            // displaying the flag is determined alongside conditions on the instrument (e.g. type and trading status)
            Object.entries(applicationsByInstrument).forEach(([fundId, applications]) => {
                const hasRecentExerciseApplication = applications.find(a => a.type === 'EXERCISE')
                const item = items[fundId]
                if (
                    hasRecentExerciseApplication &&
                    item?.sharesOwned > 0 &&
                    item?.exerciseApplicationCount === 0 &&
                    item?.exerciseDaysRemaining === undefined
                ) {
                    item.exerciseDaysRemaining = 0
                }
            })
        })

        employmentInstrumentIds.forEach(shareSchemeInstrumentId => {
            if (!items[shareSchemeInstrumentId]) {
                items[shareSchemeInstrumentId] = {
                    ...emptyInitialStats,
                    instrumentId: shareSchemeInstrumentId,
                }
            }

            items[shareSchemeInstrumentId].hasEssEmployment = true

            const schemeBadgeLabel = generateEmployeeShareSchemeText(
                shareSchemeDetails.shareSchemeParticipation,
                shareSchemeInstrumentId,
            )
            if (schemeBadgeLabel) {
                items[shareSchemeInstrumentId].essBadgeLabel = schemeBadgeLabel
            }
        })

        return items
    }, [
        instrumentReturns,
        orders,
        holdings,
        employmentInstrumentIds,
        applicationsByInstrument,
        applicationPointers,
        shareSchemeDetails,
    ])

    return itemsById
}
