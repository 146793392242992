import {Button} from '@design-system/button'
import {SelectCards, SelectCardsProps} from '@design-system/select-cards'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {EventDataTaxonomy} from '~/api/rudderstack/event-taxonomy/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {spaceBelow16} from '~/global/scss/helpers/spacing.scss'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {useNzStandardInvestor} from '~/global/utils/use-nz-standard-investor/useNzStandardInvestor'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {Loading} from '~/global/widgets/loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useWalletPortfolio} from '~/sections/wallet/state/local'
import {useLegacyUserData} from '~/sections/wallet/state/retail'
import {User} from '~/store/identity/types'
import styles from './TopUp.scss'

const MAP_RS_TYPE: Record<TopUpMode, EventDataTaxonomy['topup']['type_selected']['type']> = {
    'credit-debit': 'card',
    'manual-bank-transfer': 'bank_transfer',
    'nz-blinkpay': 'instant_bank',
    'au-linked-bank-topup': 'linked_bank',
    'au-pay-id': 'pay_id',
}

const CURRENCY_MAP: Record<User['jurisdiction'], string> = {
    nz: 'NZD',
    au: 'AUD',
}

type TopUpMode = 'credit-debit' | 'nz-blinkpay' | 'au-linked-bank-topup' | 'manual-bank-transfer' | 'au-pay-id'
const topUpModes = {
    'credit-debit': {
        title: 'Credit or debit card',
        supportingText: 'A quick and convenient way to top up—includes card processing fee',
        profileRoute: 'wallet/card-top-up',
    },
    'nz-blinkpay': {
        title: 'Instant bank transfer',
        supportingText: 'Top up instantly from a BNZ or Westpac bank account—includes transfer fee',
        profileRoute: 'wallet/instant-transfer',
    },
    'au-linked-bank-topup': {
        title: 'Linked bank account',
        supportingText: 'Make a one-off top-up from your linked bank—a fee may apply',
        profileRoute: 'wallet/linked-bank-topup',
    },
    'manual-bank-transfer': {
        title: 'Bank transfer',
        supportingText: 'Make one-off or regular top-ups using online banking without any fees',
        profileRoute: 'wallet/:portfolioId/bank-transfer',
    },
    'au-pay-id': {
        title: 'PayID',
        supportingText: 'Top up instantly using your Sharesies PayID®—a fee may apply',
        profileRoute: 'wallet/pay-id',
    },
} as const

const TopupSelectCards: React.FC<{
    topUpMode: TopUpMode | undefined
    onSelectMode: (mode: TopUpMode) => void
}> = ({onSelectMode, topUpMode}) => {
    const owner = useProfileOwner()
    const {hasBlinkpayFlag, hasPayIdFlag} = useLegacyUserData()

    const modeToTopupOption = (option: TopUpMode): SelectCardsProps['options'][number] => {
        const mode = topUpModes[option]
        return {
            name: option,
            title: mode.title,
            supportingText: mode.supportingText,
            value: option,
            isActive: topUpMode === option,
        }
    }

    if (owner.product_jurisdiction === 'nz') {
        let options: TopUpMode[] = []
        if (hasBlinkpayFlag) {
            options = ['credit-debit', 'nz-blinkpay', 'manual-bank-transfer']
        } else {
            options = ['credit-debit', 'manual-bank-transfer']
        }

        return (
            <SelectCards
                dataTestId="select-cards--id-type"
                title="Choose a top up method"
                hideTitle
                onChange={e => onSelectMode(e.target.value as TopUpMode)}
                options={options.map(modeToTopupOption)}
            />
        )
    } else if (owner.product_jurisdiction === 'au') {
        if (hasPayIdFlag) {
            // Because there's four topup methods, it's been split into the sections 'fast' and 'slow'
            const fastOptions: TopUpMode[] = ['au-pay-id', 'credit-debit']
            const slowOptions: TopUpMode[] = ['au-linked-bank-topup', 'manual-bank-transfer']
            return (
                <>
                    <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow16)}>Fast payments</h2>
                    <SelectCards
                        dataTestId="select-cards--fast-top-up"
                        title="Fast payments"
                        hideTitle
                        onChange={e => onSelectMode(e.target.value as TopUpMode)}
                        options={fastOptions.map(modeToTopupOption)}
                        additionalClassName={spacing.spaceBelow24}
                    />
                    <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow16)}>
                        Arrives in 1–3 business days
                    </h2>
                    <SelectCards
                        dataTestId="select-cards--slow-top-up"
                        title="Arrives in 1–3 business days"
                        hideTitle
                        onChange={e => onSelectMode(e.target.value as TopUpMode)}
                        options={slowOptions.map(modeToTopupOption)}
                    />
                </>
            )
        }
        const options: TopUpMode[] = ['credit-debit', 'au-linked-bank-topup', 'manual-bank-transfer']
        return (
            <SelectCards
                dataTestId="select-cards--id-type"
                title="Choose a top up method"
                hideTitle
                onChange={e => onSelectMode(e.target.value as TopUpMode)}
                options={options.map(modeToTopupOption)}
            />
        )
    }
    assertNever(owner.product_jurisdiction)
    throw new Error('Invalid jurisdiction')
}

const TopUp: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const [selectedTopUpMode, setTopUpMode] = React.useState<TopUpMode | undefined>()
    const profileUrl = useProfileUrl()
    const owner = useProfileOwner()
    const profile = useProfile()
    const walletPortfolio = useWalletPortfolio()
    const nonLegacyProfile = !profile.legacy_profile_type
    const isNzStandardInvestor = useNzStandardInvestor()

    const onNext = ({mode, replace = false}: {mode: TopUpMode; replace?: boolean}) => {
        const topupModeUrl = profileUrl(topUpModes[mode].profileRoute, {portfolioId: walletPortfolio.id})

        rudderTrack('topup', 'type_selected', {type: MAP_RS_TYPE[mode]})
        navigate(topupModeUrl, {replace})
    }

    React.useEffect(() => {
        if (nonLegacyProfile) {
            // SHARED MONEY: only bank top ups support shared money properly.
            // therefore for non-legacy portfolios, redirect straight to bank top up
            onNext({mode: 'manual-bank-transfer', replace: true})
        }
    }, [nonLegacyProfile])

    if (nonLegacyProfile) {
        return <Loading />
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--wallet-topup" leftButton="back" />
            <Page>
                <div className={cn(styles.header, spaceBelow16)}>
                    <h1>Top up Wallet</h1>
                    <p>
                        {isNzStandardInvestor
                            ? 'Choose how you’d like to top up. Fees, processing times, and available top-up currencies will depend on the method you choose.'
                            : `You can only top up with ${
                                  CURRENCY_MAP[owner.product_jurisdiction]
                              }, then you can convert to other currencies.`}
                    </p>
                </div>
                <TopupSelectCards topUpMode={selectedTopUpMode} onSelectMode={setTopUpMode} />
            </Page>
            <ActionBar>
                <Button
                    onClick={() => onNext({mode: selectedTopUpMode!})}
                    label="Next"
                    disabled={!selectedTopUpMode}
                    dataTestId="button--go-to-top-up-mode"
                />
            </ActionBar>
        </>
    )
}

export {TopUp}
