export const androidUserAgent = 'nz.sharesies.app'
export const iOSUserAgent = 'com.sharesies.ios'

type AppType = 'ios' | 'android' | 'none'

export const isWrapperApp: () => boolean = () => {
    return isIOSWrapperApp() || isAndroidWrapperApp()
}

export const getWrapperAppType: () => AppType = () => {
    if (isIOSWrapperApp()) {
        return 'ios'
    }
    if (isAndroidWrapperApp()) {
        return 'android'
    }
    return 'none'
}

export const isIOSWrapperApp: () => boolean = () => navigator.userAgent.includes(iOSUserAgent)

export const isAndroidWrapperApp: () => boolean = () => navigator.userAgent.includes(androidUserAgent)
