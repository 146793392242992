import React, {ReactNode} from 'react'

/**
 * Joins a few nodes together in something resembling a sentence (this function also enjoys using an oxford comma)
 *
 * @example
 * <JoinedInSentence nodes={['red fish', 'blue fish', 'green fish']} />
 * // red fish, blue fish, and green fish
 */
export const JoinedInSentence = ({nodes}: {nodes: ReactNode[]}) => {
    return (
        <>
            {nodes.map((node, i) => (
                <React.Fragment key={i}>
                    {i > 0 && nodes.length > 2 ? ',' : ''}
                    {i > 0 && i === nodes.length - 1 ? ' and ' : ' '}
                    {node}
                </React.Fragment>
            ))}
        </>
    )
}

export default JoinedInSentence
