import {Button} from '@design-system/button'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import check from '~/global/assets/images/check.svg'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {dateFormatShortDayFullMonth} from '~/global/utils/format-date/formatDate'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {useCurrentSubscriptionPlan} from '~/global/utils/subscription-hooks/subscriptionHooks'
import {subscriptionName, subscriptionNameParts} from '~/global/utils/subscription-name/subscriptionName'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {useAppSelector} from '~/store/hooks'
import {selectHasStaffBenefits} from '~/store/identity/selectors'
import styles from './PlanSuccess.scss'

const PlanSuccess = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const [currentSubscription, currentSubscriptionLoaded] = useCurrentSubscriptionPlan()
    const hasStaffBenefits = useAppSelector(selectHasStaffBenefits) // Monthly plan is free
    const {isDependent, preferredName} = useAppSelector(s => ({
        isDependent: s.identity.user?.is_dependent,
        preferredName: s.identity.user?.preferred_name,
    }))

    if (!currentSubscriptionLoaded) {
        return <Loading isPineapple />
    }

    if (!currentSubscription) {
        return <WeSlippedUp />
    }

    const [planIconText] = subscriptionNameParts(currentSubscription?.plan.name)
    const billingCycle = currentSubscription?.billing_cycle
    const renewal = currentSubscription.expires.toFormat(dateFormatShortDayFullMonth)
    const nameOrYourUppercase = isDependent ? `${preferredName}’s` : 'Your'
    const yourOrTheir = isDependent ? 'their' : 'your'

    const annualPlanContent = (
        <p>
            Nice one—{yourOrTheir} {subscriptionName(currentSubscription.plan)} is now active. You’ve paid for a year
            upfront. Your plan will renew on {renewal}.
        </p>
    )

    const monthlyPlanContent = (
        <p>
            Nice one—{yourOrTheir} {subscriptionName(currentSubscription.plan)} is now active, and will renew on{' '}
            {renewal}.
        </p>
    )

    return (
        <>
            <Page>
                <div className={styles.container}>
                    <div className={styles.headerDollars}>
                        <img src={check} alt="" role="presentation" />
                        {planIconText}
                    </div>
                    <div className={styles.content}>
                        <h1>
                            {nameOrYourUppercase}
                            {hasStaffBenefits && ' free'} plan is good to go
                        </h1>
                        {billingCycle === 'ANNUAL' ? annualPlanContent : monthlyPlanContent}
                    </div>
                </div>
            </Page>
            <ActionBar>
                <Button
                    label="Done"
                    dataTestId="button--success-subscription"
                    onClick={() => {
                        sendWrapperAppMessage({type: 'identityUpdated'})
                        sendWrapperAppMessage({type: 'goBack'})
                        navigate(profileUrl('settings/plan'))
                    }}
                />
            </ActionBar>
        </>
    )
}

export default PlanSuccess
