import React from 'react'
import {Model} from '~/api/retail/types'
import {BuySellOrder} from '~/store/accounting/types'
import {Instrument} from '~/store/instrument/types'
import styles from './Record.scss'

interface OrderFailExplanationProps {
    order: BuySellOrder
    instrument: Instrument
    jurisdiction: Model.User['jurisdiction']
}

const OrderFailExplanation = ({order, instrument, jurisdiction}: OrderFailExplanationProps) => {
    const reasonToExplanation = {
        INSTRUMENT_INACTIVE: `This order was ${order.state} as the investment is no longer available. You can no longer place orders for this investment.`,
        INSTRUMENT_CLOSE_ONLY:
            instrument.exchangeCountry === 'usa'
                ? `This order was ${order.state} as our US partner has stopped taking buy orders for this investment at this time.`
                : null,
        INSTRUMENT_HALTED: `This order was ${order.state} as the exchange placed this investment in a trading halt.`,
        EXPIRED:
            order.price_limit != null
                ? `This order was ${order.state} as the limit price you set was not reached after 30 days.`
                : `This order was ${order.state} as the order did not fill on market.`,
        CORPORATE_ACTION: (
            <>
                This order was {order.state} due to a{' '}
                <a
                    href={
                        jurisdiction === 'au'
                            ? 'https://www.sharesies.com.au/blog/an-introduction-to-corporate-actions'
                            : 'https://www.sharesies.nz/learn/an-introduction-to-corporate-actions'
                    }
                    target="_blank"
                    rel="noopener"
                >
                    corporate action
                </a>{' '}
                taking place. You can place a new order for this investment.
            </>
        ),
        EXCEEDS_MAX_VALUE:
            instrument.exchangeCountry === 'usa'
                ? `This order was ${order.state} as it exceeded the maximum order amount set by our US partner. You can place a new order at a lower amount.`
                : null,
        EXCEEDS_MAX_QUANTITY:
            instrument.exchangeCountry === 'usa'
                ? `This order was ${order.state} as it exceeded the maximum number of shares set by our US partner. You can place a new order for a lower number of shares.`
                : null,
        // No value is specified for OTHER as we don't want to display an explanation note for that
    }

    const explanation =
        ['cancelled', 'rejected'].includes(order.state) && order.fail_reason && order.fail_reason in reasonToExplanation
            ? reasonToExplanation[order.fail_reason as keyof typeof reasonToExplanation]
            : null

    if (explanation == null) {
        return null
    }

    return (
        <div className={styles.orderCompletion}>
            <div>
                <div className={styles.delayReason}>{explanation}</div>
            </div>
        </div>
    )
}

export default OrderFailExplanation
