import {Button} from '@design-system/button'
import {Close} from '@design-system/icon'
import {useQueryClient} from '@tanstack/react-query'
import cn from 'classnames'
import {Form, Formik} from 'formik'
import {DateTime} from 'luxon'
import React, {useState} from 'react'
import {useNavigate, Navigate} from 'react-router'
import {useRetailPost} from '~/api/query/retail'
import {spacing} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {useCurrentSubscriptionPlan} from '~/global/utils/subscription-hooks/subscriptionHooks'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {ErrorBox, validate} from '~/global/widgets/form-controls'
import {StrongInlineDate, Select, StrongCurrency} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useLinkedBankPaymentsUrl} from '~/sections/OLD_wallet/sections/linked-bank-payments/hooks/useLinkedBankPaymentsUrl'
import {Confirmation} from '~/sections/OLD_wallet/sections/linked-bank-payments/pages/confirmation/Confirmation'
import {useRecurringPayment} from '~/sections/OLD_wallet/sections/linked-bank-payments/state'
import {
    Frequency,
    frequencySelect,
} from '~/sections/OLD_wallet/sections/linked-bank-payments/utils/frequency-select-values/frequencySelectValues'
import {getTopupBreakdown} from '~/sections/OLD_wallet/sections/linked-bank-payments/utils/get-topup-breakdown/getTopupBreakdown'
import {TopUpBreakdown} from '~/sections/OLD_wallet/sections/linked-bank-payments/widgets/topup-breakdown/TopUpBreakdown'
import {useAutoinvestOrders} from '~/sections/invest/sections/auto-invest/hooks/useAutoinvestOrders'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import {actingAsID as actingAsIDSelector} from '~/store/identity/selectors'
import styles from './SetUpRecurringTopUp.scss'

interface FormValues {
    amount: string
    frequency: Frequency
    startDate: DateTime
}

export const SetUpRecurringTopUp: React.FunctionComponent = () => {
    const [hotTip, setHotTip] = React.useState(true)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const actingAsID = useAppSelector(actingAsIDSelector)
    const recurringPayment = useRecurringPayment()
    const queryClient = useQueryClient()
    const setupRecurringTopUp = useRetailPost({path: 'banklinking/upsert-recurring-payment'})
    const deleteRecurringTopUp = useRetailPost({path: 'banklinking/delete-recurring-payment'})
    const debitAccount = useAppSelector(s => s.bankLinking.roundupsDebitAccount)
    const [currentSubscription] = useCurrentSubscriptionPlan()
    const relativeUrl = useLinkedBankPaymentsUrl()
    const hasSeenHotTip = useAppSelector(s => s.identity.user?.has_seen.recurring_topup_autoinvest_tip)
    const [autoinvestOrders] = useAutoinvestOrders()
    const firstAutoInvestOrder = [...autoinvestOrders].sort(
        (a, b) => Date.parse(a.next_date) - Date.parse(b.next_date),
    )[0]

    const areAnyNotificationsOn = useAppSelector(s => {
        const roundupNotification = s.identity.notificationPreferences?.find(
            category => category.id === 'roundup_activity',
        )
        const emailChannel = roundupNotification?.channels.find(x => x.channel === 'EMAIL')
        const pushChannel = roundupNotification?.channels.find(x => x.channel === 'NOTIFICATION')
        if ([emailChannel, pushChannel].some(channel => !!channel?.subscribed)) {
            return true
        }
        return false
    })

    const [recurringTopUpSettings, setRecurringTopUpSettings] = useState<FormValues>()
    const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>()

    React.useEffect(() => {
        dispatch(identityActions.GetNotificationPreferences())
    }, [])

    React.useEffect(() => {
        if (hasSeenHotTip || !firstAutoInvestOrder) {
            setHotTip(false)
        }

        // Set has_seen for hot tip on unmount
        return () => {
            if (firstAutoInvestOrder) {
                try {
                    dispatch(identityActions.MarkHasSeenFlag('recurring_topup_autoinvest_tip'))
                } catch (error) {
                    // let this silently fail, it's not mission critical
                }
            }
        }
    }, [])

    if (!debitAccount) {
        return <Navigate to={relativeUrl('select-debit-account')} replace />
    }

    const onSubmit = async () => {
        if (!recurringTopUpSettings) {
            return
        }

        try {
            const response = await setupRecurringTopUp.mutateAsync({
                acting_as_id: actingAsID,
                amount: recurringTopUpSettings!.amount,
                frequency: recurringTopUpSettings.frequency,
                next_payment: recurringTopUpSettings.startDate.toISODate().toString(),
            })

            if (response.type === 'recurring_payment_setting' || response.type === 'empty') {
                Toast(`Choice! Your top up is all set 💸`)
                if (!areAnyNotificationsOn) {
                    navigate(relativeUrl('notifications'), {replace: true})
                } else {
                    navigate(-1)
                }
            }
        } catch (e) {
            setError(unknownErrorMessage)
        }

        setConfirmationOpen(false)
    }

    const onDelete = async () => {
        const response = await deleteRecurringTopUp.mutateAsync({
            acting_as_id: actingAsID,
        })

        if (response.type === 'empty') {
            queryClient.invalidateQueries({queryKey: ['banklinking/recurring-payment', {acting_as_id: actingAsID}]})
            Toast(`Your recurring top-up has been removed`)
            navigate(-1)
        }
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--linked-bank-payments-set-up-recurring-top-up" leftButton="back" />
            <Formik
                initialValues={{
                    amount: recurringPayment?.amount ?? '',
                    frequency: recurringPayment?.frequency ?? 'MONTHLY',
                    startDate: recurringPayment
                        ? DateTime.fromISO(recurringPayment.next_payment)
                        : DateTime.now().plus({days: 1}),
                }}
                validate={validate.generate<FormValues>({
                    amount: [
                        validate.required(),
                        validate.minimum(5, 'The minimum top up amount is $5'),
                        validate.maximum(2000, 'The maximum top up amount is $2000'),
                    ],
                    frequency: [validate.required()],
                    startDate: [validate.required()],
                })}
                onSubmit={({amount, frequency, startDate}) => {
                    setRecurringTopUpSettings({amount, frequency, startDate})
                    setConfirmationOpen(!confirmationOpen)
                }}
            >
                {({isValid, values}) => {
                    const topupBreakdown = isValid
                        ? getTopupBreakdown(values.amount, currentSubscription, values.frequency, values.startDate)
                        : undefined

                    return (
                        <Form className={styles.fullHeight}>
                            <Page className={styles.linkedBankTopupPage}>
                                <h1 className={spacing.spaceBelow16}>
                                    {recurringPayment ? 'Edit' : 'Set up a'} recurring top-up
                                </h1>
                                {recurringPayment && (
                                    <p>
                                        Changes to your recurring top-up can take up to two business days to take
                                        effect. If your next top-up is due to be transferred within this time, it may be
                                        processed without the changes.
                                    </p>
                                )}
                                <h2 className={cn(spacing.spaceBelow4, spacing.spaceAbove12)}>Top-up amount</h2>
                                <StrongCurrency
                                    additionalClassName={styles.topupInlineInput}
                                    name="amount"
                                    currency="aud"
                                    dataTestId="strong-currency-input--set-up-recurring-top-up"
                                />
                                <h2 className={spacing.spaceBelow4}>From</h2>
                                <div className={cn([styles.debitAccount, spacing.spaceBelow32])}>
                                    <img src={debitAccount.bankLogo} />
                                    <strong>
                                        {debitAccount.bankName} {debitAccount.accountName}
                                    </strong>
                                </div>
                                <h2>Repeat</h2>
                                <Select dataTestId="select--frequency" name="frequency" choices={frequencySelect} />
                                <h2 className={spacing.spaceBelow4}>Start date</h2>
                                <div className={cn(styles.topupInlineInput, styles.dateInput, spacing.spaceBelow12)}>
                                    <StrongInlineDate
                                        name="startDate"
                                        customZIndex={1051}
                                        dataTestId="strong-inline-date--set-frequency"
                                    />
                                </div>
                                {hotTip && firstAutoInvestOrder && (
                                    <div className={styles.hotTip}>
                                        <div
                                            className={cn([
                                                spacing.spaceBelow12,
                                                spacing.spaceAbove8,
                                                styles.headerSection,
                                            ])}
                                        >
                                            <h3>Hot tip!</h3>
                                            <div
                                                onClick={() => {
                                                    setHotTip(false)
                                                }}
                                            >
                                                <Close />
                                            </div>
                                        </div>
                                        <p className={spacing.spaceBelow8}>
                                            Your next auto-invest is due to go out on{' '}
                                            {DateTime.fromISO(firstAutoInvestOrder.next_date).toFormat(
                                                dateFormatNoTime,
                                            )}
                                            . Set up a recurring top-up a few days in advance to ensure your order fills
                                            on time.
                                        </p>
                                    </div>
                                )}
                                <div className={styles.fillAvailableSpace} />
                                {topupBreakdown && <TopUpBreakdown topupBreakdown={topupBreakdown} />}
                                <ErrorBox message={error} className={spacing.spaceAbove12} />

                                {/* We want vertical stacking of these buttons so ActionBar isn't appropriate */}
                                <div
                                    className={cn(
                                        styles.recurringToppupActionBar,
                                        spacing.spaceAbove8,
                                        spacing.spaceBelow32,
                                    )}
                                >
                                    <Button
                                        isSubmit
                                        label="Next"
                                        disabled={!isValid}
                                        type="primary"
                                        dataTestId="button--linked-bank-set-up-recurring-top-up"
                                    />
                                    {recurringPayment && (
                                        <div
                                            style={{display: 'flex', justifyContent: 'center'}}
                                            className={spacing.spaceAbove24}
                                        >
                                            <ButtonAsLink
                                                noTextDecoration
                                                dataTestId="button--linked-bank-delete-recurring-top-up"
                                                onClick={onDelete}
                                            >
                                                Delete recurring top-up
                                            </ButtonAsLink>
                                        </div>
                                    )}
                                </div>
                            </Page>
                        </Form>
                    )
                }}
            </Formik>
            <Confirmation
                topupBreakdown={getTopupBreakdown(
                    recurringTopUpSettings?.amount,
                    currentSubscription,
                    recurringTopUpSettings?.frequency,
                    recurringTopUpSettings?.startDate,
                )}
                isOpen={confirmationOpen}
                setIsOpen={() => setConfirmationOpen(!confirmationOpen)}
                onSubmitHandler={onSubmit}
                isSubmitting={setupRecurringTopUp.isLoading}
                recurring
            />
        </>
    )
}
