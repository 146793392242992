import {Button} from '@design-system/button'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import accountingActions from '~/store/accounting/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'

export const MoneyGoalIntro = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const hasExistingMoneyGoal = useAppSelector(s => s.accounting.topupGoal !== null)

    React.useEffect(() => {
        dispatch(accountingActions.FetchCurrentTopupGoal())
    }, [])

    if (hasExistingMoneyGoal) {
        navigate(profileUrl('wallet/money-goal'))
    }

    return (
        <>
            <Toolbar title="Money goal" dataTestId="toolbar--money-goal-intro" leftButton="back" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <p className={spacing.spaceBelow16}>
                    Working towards a money goal can help you get into the habit of putting money aside to invest.{' '}
                </p>
                <p className={spacing.spaceBelow16}>
                    Decide on a regular amount, how often you want to top up, and your goal amount, and we’ll keep tabs
                    on how you're tracking.
                </p>
                <p className={spacing.spaceBelow32}>
                    Don’t sweat if you miss a top-up—we’re just here to cheer you on from the sidelines! 🎉
                </p>
                <Button
                    width="auto"
                    onClick={() => navigate(profileUrl('wallet/money-goal/setup'))}
                    label="Create a money goal"
                    dataTestId="button-create-money-goal"
                />
            </Page>
        </>
    )
}
