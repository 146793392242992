import {DateTime} from 'luxon'
import React from 'react'
import {useNavigate} from 'react-router'
import {Response} from '~/api/retail/types'
import {titleCase} from '~/global/utils/convert-to-title-case/convertToTitleCase'
import {isUninitialised} from '~/global/utils/is-loading/isLoading'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import RecurringTile from '~/global/widgets/recurring-transaction-tile/RecurringTile'
import actions from '~/store/bankLinking/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import styles from './RecurringTopupTile.scss'

export const RecurringTopupTile = ({recurringPayment}: {recurringPayment: Response.RecurringPaymentSetting}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const banklinkingDebitAccount = useAppSelector(({bankLinking}) => bankLinking.roundupsDebitAccount)
    const loadingState = useAppSelector(({bankLinking}) => bankLinking.bankLinkingLoadingState)

    React.useEffect(() => {
        if (isUninitialised(loadingState)) {
            dispatch(actions.FetchBankLinkingStatus())
        }
    }, [loadingState])

    if (loadingState === 'loading') {
        return <Loading />
    }

    if (loadingState !== 'ready' || !banklinkingDebitAccount) {
        return null
    }

    return (
        <>
            <RecurringTile
                dataTestId="recurring-tile-topup"
                displayImage={<img className={styles.recurringTopupImage} src={banklinkingDebitAccount.bankLogo} />}
                actionText="Manage"
                handleClick={() => navigate(profileUrl('wallet/linked-bank-topup/recurring'))}
                title={`${titleCase(recurringPayment.frequency)} top-up`}
                subtitle={<DollarValue value={recurringPayment.amount} />}
                nextDate={DateTime.fromISO(recurringPayment.next_payment)}
            />
        </>
    )
}
