import {DateTime} from 'luxon'
import React from 'react'
import {Model} from '~/api/retail/types'
import config from '~/configForEnv'
import ReFetch from '~/global/data-components/ReFetch'
import {connect} from '~/store/connect'
import identityActions from '~/store/identity/actions'

const PortfolioRefetch: React.FunctionComponent<PortfolioRefetchProps> = React.memo(
    ({isActive, fetchIdentity, rakaiaTokenExpiry}) => {
        const fetchPortfolio = async () => {
            if (rakaiaTokenExpiry && rakaiaTokenExpiry < DateTime.local()) {
                await fetchIdentity()
            }
        }

        if (!isActive) {
            return <></>
        }

        return (
            <ReFetch
                fetchAction={fetchPortfolio}
                timeoutPeriod={config.userCheckInterval} // 5 minutes
                refetchDependsOn={[isActive, rakaiaTokenExpiry]}
            />
        )
    },
)

type PortfolioRefetchProps = StoreProps & DispatchProps

interface StoreProps {
    isActive: boolean
    rakaiaTokenExpiry?: DateTime | null
}

interface DispatchProps {
    fetchIdentity(): Promise<Model.User | null>
}

export default connect<StoreProps, DispatchProps, {}>(
    state => {
        const {identity} = state
        return {
            isActive: !!(identity.user && identity.user.state === 'active'),
            rakaiaTokenExpiry: identity.rakaiaTokenExpiry,
        }
    },
    dispatch => ({
        fetchIdentity: () => dispatch(identityActions.Check()),
    }),
)(PortfolioRefetch)
