import {TextInput, TextProps} from '@design-system/text-input'
import React from 'react'
import {withFocus, withId} from '~/global/widgets/form-controls/common'

export function normalizeNZBankAccount(value: string, previousValue: string = ''): string {
    if (value) {
        if (value + ' ' === previousValue) {
            // User backspaced a space, we'll just remove the last character instead
            value = value.replace(/.$/, '')
        }

        // Replace unacceptable characters, including -
        value = value.replace(/\D/g, '')
        // Remove anything too long
        value = value.substring(0, 16)
        // Put - back in the right places
        value = value.replace(/^(..)?(....)?(.......)?(.*)/, (_match, first, second, third, remainder) => {
            let result = ''
            if (first) {
                result += first + ' '
            }
            if (second) {
                result += second + ' '
            }
            if (third) {
                result += third + ' '
            }
            return result + remainder
        })
    }
    return value
}

const NZBankAccount = (props: TextProps) => (
    <TextInput
        {...props}
        onChange={e => {
            if (!props.setFieldValue) {
                return
            }
            props.setFieldValue(normalizeNZBankAccount(e.currentTarget.value, props.value || ''))
        }}
    />
)

const BankAccountWithFocus = withId(withFocus(NZBankAccount))

export default BankAccountWithFocus
