import {useQueryErrorResetBoundary} from '@tanstack/react-query'
import cn from 'classnames'
import React from 'react'
import {ErrorBoundary, FallbackProps} from 'react-error-boundary'
import {Outlet, useNavigationType} from 'react-router'
import {useLocation} from 'react-router-dom'
import * as rollbar from '~/api/rollbar/rollbar'
import config from '~/configForEnv'
import ESSBlackoutPeriodRefetch from '~/global/data-components/ESSBlackoutPeriodRefetch'
import ExchangeRateRefetch from '~/global/data-components/ExchangeRateRefetch'
import IdentityRefetch from '~/global/data-components/IdentityRefetch'
import NotificationsFeedFetch from '~/global/data-components/NotificationsFeedFetch'
import PendingOrdersRefetch from '~/global/data-components/PendingOrdersRefetch'
import ServiceWorkerUpdateCheck from '~/global/data-components/ServiceWorkerUpdateCheck'
import ClientUpgradeRequired from '~/global/pages/client-upgrade-required/ClientUpgradeRequired'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import LoadingScreen from '~/global/pages/loading-screen/LoadingScreen'
import MaintenancePage from '~/global/pages/maintenance-page/MaintenancePage'
import {setGlobalStyleVHVariable} from '~/global/utils/set-global-style-vh-variable/setGlobalStyleVHVariable'
import {Loading} from '~/global/widgets/loading'
import {ToastWrapper} from '~/global/widgets/toast/Toast'
import AddToHomescreen from '~/global/wrappers/global-wrapper-widgets/add-to-homescreen/AddToHomescreen'
import NotificationProvider from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import Reauthenticate from '~/global/wrappers/global-wrapper-widgets/reauthenticate/Reauthenticate'
import {useAppSelector} from '~/store/hooks'
import styles from './GlobalWrapper.scss'

const errorHandler = (error: Error, info: {componentStack: string}) => {
    rollbar.sendError(error, info)
}
const ErrorFallback = ({resetErrorBoundary}: FallbackProps) => <WeSlippedUp retryAction={resetErrorBoundary} />

const GlobalWrapper: React.FunctionComponent = () => {
    const body = document.body
    const accessClass = 'hasAccess'
    const {reset} = useQueryErrorResetBoundary()

    const loading = useAppSelector(s => !s.identity.isInitialised || !!s.identity.switchingTo)
    const clientUpgradeRequired = useAppSelector(s => s.notification.clientUpgradeRequired)
    const scheduledMaintenance = useAppSelector(s => s.notification.scheduledMaintenance)

    const handleKeyUp = (e: KeyboardEvent) => {
        const tabKey = e.key === 'Tab'
        if (!tabKey) {
            return
        }

        if (body) {
            // add keyboard accessibility features by default, used in scss files like
            // @include hasAccess { // my special rules for keyboard accessibility }
            body.classList.add(accessClass)
        }
    }

    const handleMouseClick = () => {
        if (body) {
            // remove focus outlines if the investor interacts with the page with a mouse
            body.classList.remove(accessClass)
        }
    }

    React.useEffect(() => {
        setGlobalStyleVHVariable()

        window.addEventListener('keyup', handleKeyUp)
        window.addEventListener('mousedown', handleMouseClick)
        window.addEventListener('resize', setGlobalStyleVHVariable)

        return () => {
            window.removeEventListener('keyup', handleKeyUp)
            window.removeEventListener('mousedown', handleMouseClick)
            window.removeEventListener('resize', setGlobalStyleVHVariable)
        }
    }, []) // Listeners are set up once (docs: https://reactjs.org/docs/hooks-faq.html#performance-optimizations)

    const location = useLocation()
    const navigationType = useNavigationType()

    // This effect ensures that whenever we navigate "forward" in history (e.g.
    // click on a new link) we ensure that the browser scrolls to the top of the
    // viewport on the new page.
    React.useEffect(() => {
        if (navigationType === 'PUSH') {
            window.scrollTo(0, 0)
        }
    }, [location, navigationType])

    if (clientUpgradeRequired) {
        return <ClientUpgradeRequired />
    }

    if (scheduledMaintenance) {
        return <MaintenancePage />
    }

    if (loading) {
        return <LoadingScreen />
    }

    const wrapperClasses = cn(styles.wrapper, {
        standalone: navigator.standalone,
    })

    return (
        <div className={wrapperClasses}>
            <React.Suspense fallback={<Loading isPineapple />}>
                <main className={styles.fullScreen} id="globalWrapperPage">
                    <Reauthenticate />

                    <NotificationProvider>
                        <React.Suspense fallback={<Loading isPineapple />}>
                            <ErrorBoundary
                                key={location.pathname}
                                onReset={reset}
                                FallbackComponent={ErrorFallback}
                                onError={errorHandler}
                            >
                                <Outlet />
                            </ErrorBoundary>
                        </React.Suspense>
                    </NotificationProvider>
                    <ToastWrapper />
                    <IdentityRefetch />
                    <PendingOrdersRefetch />
                    <ExchangeRateRefetch />
                    <NotificationsFeedFetch />
                    <ESSBlackoutPeriodRefetch />
                    {config.rollbarEnvironment !== 'development' && 'serviceWorker' in navigator && (
                        <ServiceWorkerUpdateCheck /> // Register service worker only on staging and production
                    )}
                    <AddToHomescreen path={location.pathname} />
                </main>
            </React.Suspense>
        </div>
    )
}

export default GlobalWrapper
