import {Button} from '@design-system/button'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {useNavigate} from 'react-router'
import {useRetailPost} from '~/api/query/retail'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {isWrapperApp} from '~/global/utils/is-wrapper-app/isWrapperApp'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {Loading} from '~/global/widgets/loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {coveClientErrorFallbackRender} from '~/sections/protect/sections/insure/utils/cove-client-error/coveClientError'
import {CoveConnectionErrorAlertCard} from '~/sections/protect/sections/insure/widgets/cove-connection-error-alert-card/CoveConnectionErrorAlertCard'
import styles from './CoveEmbedPage.scss'

/**
 * Generic Cove embed page which receives the page-specific Cove iframe component as a child element.
 * Constructing the iframe URL involves a Retail GET which is a proxy API call to Cove.
 *
 * Wrapping the iframe in this way allows us to:
 * - display an in-page loading state
 * - show an in-page error message if Cove is down.
 */
export const CoveEmbedPage: React.FunctionComponent<{children: React.ReactNode; name: string}> = ({children, name}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const queryClient = useQueryClient()

    const logOutOfCove = useRetailPost({path: 'insure/log-out-cove-user'})

    React.useEffect(() => {
        return () => {
            // On component unmount, log out of the Cove embed login session and reset the login ID query cache
            logOutOfCove.mutate(undefined) // sync not async so it completes during the unmount
            queryClient.resetQueries({queryKey: ['insure/get-cove-user-embed-login-id']})
        }
    }, [])

    const onPageAway = (label: string) => {
        rudderTrack('insurance_signup', 'close_clicked', {
            progress: name,
            label,
        })
        if (isWrapperApp()) {
            sendWrapperAppMessage({type: 'goBack'})
            navigate(profileUrl(''), {replace: true})
        } else {
            navigate(profileUrl('protect/insure'), {replace: true})
        }
    }

    const fallbackRender = ({error}: {error: Error}) => {
        return coveClientErrorFallbackRender(error, <CoveConnectionErrorAlertCard />)
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--cove-embed" leftButton="back" onLeftButtonClick={() => onPageAway('Back')} />
            <Page withoutDefaultPadding>
                <div className={spacing.spaceAbove48}>
                    <ErrorBoundary key={location.pathname} fallbackRender={fallbackRender}>
                        <React.Suspense fallback={<Loading />}>{children}</React.Suspense>
                    </ErrorBoundary>
                </div>
            </Page>
            <ActionBar>
                <div className={styles.buttonPlaceholder} />
                <Button
                    dataTestId="button--save-and-exit"
                    label="Save and exit"
                    onClick={() => onPageAway('Save and exit')}
                    type="secondary"
                />
            </ActionBar>
        </>
    )
}
