import {DateTime} from 'luxon'
import {distillGetFactory} from '~/api/query/distill'

export const useDistillInstrumentHistoricVwap = (instrumentId: string, startDate: DateTime, endDate: DateTime) => {
    /**
     * A VWAP is the Volume-weighted average price for an Instrument
     * over a defined time period. It can be used by share schemes as a
     * performance metric for awarding shares or rights.
     *
     * If you think of it as "the average share price over the date range"
     * you're pretty much there
     */
    const {data} = distillGetFactory({
        apiFunctionName: 'apiV1InstrumentsHistoricVwapIdGet',
    })({
        id: instrumentId,
        startDate,
        endDate,
        scope: 'invest',
    })
    return data
}
