import React from 'react'
import {InvestmentPlanIndicatorHelpModal} from '~/sections/kiwisaver/widgets/help-modals/InvestmentPlanRiskIndicatorHelpModal'
import styles from './RiskIndicator.scss'

interface RiskIndicatorProps {
    rating?: number
}

export const RiskIndicator: React.FunctionComponent<RiskIndicatorProps> = ({rating}) => {
    if (!rating) {
        return null
    }
    return (
        <div className={styles.riskIndicator} data-testid="risk-indicator" aria-describedby="risk-description">
            <span className={styles.indicator}>
                <span className={styles.numerator} data-testid="risk-indicator--rating">
                    {rating}
                </span>
                <span className={styles.slash}>/</span>
                <span className={styles.denominator}>7</span>
            </span>{' '}
            <div id="risk-description">
                <RiskRatingDescription rating={rating} />
            </div>
        </div>
    )
}

export const RiskRatingDescription = React.memo(({rating}: RiskIndicatorProps) => {
    if (!rating) {
        return null
    }

    const ratingToRiskString = (rating: number): string => {
        if (rating < 3) {
            return 'lower'
        }

        if (rating > 4) {
            return 'higher'
        }

        return 'medium'
    }
    const riskString = ratingToRiskString(rating)

    return (
        <div className={styles.riskDescription} data-testid="text--risk-indicator-description">
            Your investment plan is {riskString} risk, and has the potential for {riskString} returns over time.{' '}
            <InvestmentPlanIndicatorHelpModal helpIconSize={16} />
        </div>
    )
})
