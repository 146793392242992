import React from 'react'
import {CycleMode, PremadeSlugType} from '~/store/instrument/types'
import ViewInstrument, {ViewInstrumentProps} from './ViewInstrument'

/*
    NOTE: There's a handful of strangely named components in this file
    (specifically things with ViewView in their name).

    The reason for this is that I wanted consistency between how the "view only"
    version and the various panel versions of the component were named, but ALSO
    if I omitted the last View some of them would conflict with other components
    in the router.

    This seemed like the easiest compromise for now, and eventually potentially
    this should all go away by having a nested router that deals with these
    behaviours.
*/

const generateInstrumentView = (
    cycleMode: CycleMode,
    extra?: Omit<ViewInstrumentProps, 'instrumentListType' | 'url' | 'instrumentSlug'>,
): React.ComponentType<{instrumentSlug: string}> => {
    return ({instrumentSlug}) => (
        <ViewInstrument instrumentListType={cycleMode} instrumentSlug={instrumentSlug!} {...extra} />
    )
}

// See note at top of file around this variable name
export const InvestPortfolioSearchView = generateInstrumentView('searchResults')
export const InvestPortfolioViewView = generateInstrumentView('investPortfolio')
export const InvestPortfolioViewPick = generateInstrumentView('investPortfolio')
export const InvestPortfolioViewBuy = generateInstrumentView('investPortfolio')
export const InvestPortfolioViewSell = generateInstrumentView('investPortfolio')
export const InvestPortfolioViewApply = generateInstrumentView('investPortfolio')

export const RecentlyViewedView = generateInstrumentView('recentlyViewed')
export const RecentlyViewedPick = generateInstrumentView('recentlyViewed')
export const RecentlyViewedBuy = generateInstrumentView('recentlyViewed')
export const RecentlyViewedSell = generateInstrumentView('recentlyViewed')
export const RecentlyViewedApply = generateInstrumentView('recentlyViewed')

export const WatchlistView = generateInstrumentView('watchlist')
export const WatchlistPick = generateInstrumentView('watchlist')
export const WatchlistBuy = generateInstrumentView('watchlist')
export const WatchlistSell = generateInstrumentView('watchlist')
export const WatchlistApply = generateInstrumentView('watchlist')

export const FonterraViewView = generateInstrumentView('fonterra')

export const PremadeView: React.FunctionComponent<{instrumentSlug: string; premadeSlug: string}> = ({
    instrumentSlug,
    premadeSlug,
}) => {
    return (
        <ViewInstrument
            instrumentListType={
                premadeSlug === 'global'
                    ? 'premadeGlobalAutoInvest'
                    : premadeSlug === 'responsible'
                      ? 'premadeResponsibleAutoInvest'
                      : 'premadeKidsAutoInvest'
            }
            instrumentSlug={instrumentSlug}
            // TODO - probably should validate this here, or make the
            // premadeSlug prop `string` and deal with it appropriately in
            // `ViewInvestment`
            premadeSlug={premadeSlug as PremadeSlugType}
        />
    )
}

export const DIYViewAdd = generateInstrumentView('diyAutoInvest', {diyAIType: 'diyAdd'})
export const DIYView = generateInstrumentView('diyAutoInvest', {diyAIType: 'diyView'})

export const TransferSharesView = generateInstrumentView('transferShares')
export const TransferSharesSearchView = generateInstrumentView('transferShares')
