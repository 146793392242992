import React from 'react'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {LearnPageContent, HelpContentRenderer} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {
    AUExchangeContent,
    AutoInvestContent,
    ETFContent,
    ExchangeFeesContent,
    ExchangeMoneyContent,
    ExchangeRateContent,
    ExchangesMarketsContent,
    FundsContent,
    InvestmentRiskLevelsContent,
    LimitOrdersContent,
    ManagedFundPricesContent,
    ManagedFundProcessingTimesContent,
    ManagedFundsContent,
    MarketOrdersContent,
    MarketProcessingTimesContent,
    NZExchangeContent,
    PortfolioTypesContent,
    PricesOnMarketContent,
    PubliclyListedCompaniesContent,
    USExchangesContent,
    VoteContent,
} from '~/sections/explore/pages/learn-static-content/LearnContent'

export const LEARN_PAGES: LearnPageContent[] = [
    AutoInvestContent,
    ETFContent,
    ExchangeFeesContent,
    ExchangeMoneyContent,
    ExchangeRateContent,
    ExchangesMarketsContent,
    FundsContent,
    LimitOrdersContent,
    ManagedFundPricesContent,
    ManagedFundsContent,
    ManagedFundProcessingTimesContent,
    MarketOrdersContent,
    MarketProcessingTimesContent,
    NZExchangeContent,
    AUExchangeContent,
    PricesOnMarketContent,
    PubliclyListedCompaniesContent,
    USExchangesContent,
    PortfolioTypesContent,
    InvestmentRiskLevelsContent,
    VoteContent,
]

const LearnContentPage: React.FunctionComponent<{
    slug: string
}> = ({slug}) => {
    const content = LEARN_PAGES.find(p => p.slug === slug)
    if (!content) {
        return <WeSlippedUp />
    }

    return <HelpContentRenderer.LearnPage content={content} />
}

export default LearnContentPage
