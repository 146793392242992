import Decimal from 'decimal.js'
import {DateTime} from 'luxon'
import React from 'react'
import {Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {dateFormatFullMonth} from '~/global/utils/format-date/formatDate'
import {useCurrentSubscriptionPlan} from '~/global/utils/subscription-hooks/subscriptionHooks'
import {subscriptionName} from '~/global/utils/subscription-name/subscriptionName'
import {AlertCard} from '~/global/widgets/alert-card/AlertCard'
import {useAppSelector} from '~/store/hooks'
import {selectHasStaffBenefits} from '~/store/identity/selectors'

const getNextPlanCost = (plan: Response.SubscriptionCurrentV2 | undefined) => {
    if (!plan || !plan.auto_renew) {
        return undefined
    }
    const autoRenewIntoNewPlan = plan.auto_renew_into_plan
    const nextBillingCycle = plan.auto_renew_into_billing_cycle ?? plan.billing_cycle

    return autoRenewIntoNewPlan
        ? autoRenewIntoNewPlan.billing_options[nextBillingCycle].price_on_renewal
        : plan.plan.billing_options[nextBillingCycle].price_on_renewal
}

export const TopUpForPlanAlert = () => {
    const [currentSubscription] = useCurrentSubscriptionPlan()
    const hasStaffBenefits = useAppSelector(selectHasStaffBenefits)
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)
    const walletBalances = useAppSelector(s => s.identity.user!.wallet_balances)
    const {isDependent, preferredName} = useAppSelector(s => ({
        preferredName: s.identity.user?.preferred_name,
        isDependent: s.identity.user?.is_dependent,
    }))
    const yourOrTheir = isDependent ? 'their' : 'your'
    const capitalYouOrName = isDependent ? preferredName : 'You'
    const nextPlanCost = getNextPlanCost(currentSubscription)

    const planRenewsSoon =
        currentSubscription &&
        currentSubscription.auto_renew &&
        DateTime.now() >= currentSubscription.expires.minus({weeks: 1})

    if (
        hasStaffBenefits ||
        !currentSubscription ||
        !nextPlanCost ||
        new Decimal(walletBalances[homeCurrency])
            .minus(new Decimal(nextPlanCost))
            .greaterThanOrEqualTo(new Decimal(0)) ||
        !planRenewsSoon
    ) {
        return null
    }

    const anyWalletPositive = Object.values(walletBalances).find(balance =>
        new Decimal(balance).greaterThan(new Decimal(0)),
    )

    const topUpTitle = `Top up to continue ${isDependent ? `${preferredName}’s` : 'your'} ${subscriptionName(
        currentSubscription.plan,
    )}`

    const topUpExchangeTitle = `Top up or exchange money to continue  ${
        isDependent ? `${preferredName}’s` : 'your'
    } ${subscriptionName(currentSubscription.plan)}`

    const topUpContent = `${capitalYouOrName} currently ${
        isDependent ? 'doesn’t' : 'don’t'
    } have enough NZD in ${yourOrTheir} Wallet for ${yourOrTheir} plan to renew on ${currentSubscription.expires.toFormat(
        dateFormatFullMonth,
    )}. Top up before then to continue ${yourOrTheir} plan.`

    const topUpExchangeContent = `${capitalYouOrName} currently ${
        isDependent ? 'doesn’t' : 'don’t'
    } have enough NZD in ${yourOrTheir} Wallet for ${yourOrTheir} plan to renew on ${currentSubscription.expires.toFormat(
        dateFormatFullMonth,
    )}. Top up or exchange money from another currency before then to continue ${yourOrTheir} plan.`

    return (
        <AlertCard
            type="warning"
            title={anyWalletPositive ? topUpExchangeTitle : topUpTitle}
            className={spacing.spaceBelow16}
        >
            <p>{anyWalletPositive ? topUpExchangeContent : topUpContent}</p>
        </AlertCard>
    )
}

export default TopUpForPlanAlert
