import React from 'react'

export const orderTypeDescs = {
    Defensive: (
        <>
            This order is made up of at least 90% lower risk investments. These have limited potential for growth or
            losses.
        </>
    ),
    Conservative: (
        <>
            This order is made up of at least 70% lower risk investments. These have limited potential for growth or
            losses.
        </>
    ),
    Balanced: (
        <>
            This order is made up of at least 50% medium or higher risk investments. These have some growth potential
            when invested for the medium term (6 to 8 years), but also potential for some losses.
        </>
    ),
    Growth: (
        <>
            This order is made up of at least 70% medium or higher risk investments. These have large growth potential
            when invested for the long term (9 to 12 years), but also potential for large losses.
        </>
    ),
    Aggressive: (
        <>
            This order is made up of at least 90% medium or higher risk investments. These have large growth potential
            when invested for the long term (13 years or more), but also potential for large losses.
        </>
    ),
}
