import {Button} from '@design-system/button'
import {DockableModal} from '@design-system/dockable-modal'
import {SelectCard} from '@design-system/select-cards' // TODO refactor to use SelectCards
import React from 'react'
import {Model, Request} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {Image} from '~/global/widgets/image/Image'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import portfolioPreview2x from '~/sections/user/sections/sign-up/sections/post-activation/pages/product-preference/assets/images/portfolio-preview-2x.png'
import portfolioPreview from '~/sections/user/sections/sign-up/sections/post-activation/pages/product-preference/assets/images/portfolio-preview.png'
import waitlistCare from '~/sections/user/sections/sign-up/sections/post-activation/pages/product-preference/assets/images/waitlist-care.svg'
import waitlistEasy from '~/sections/user/sections/sign-up/sections/post-activation/pages/product-preference/assets/images/waitlist-easy.svg'
import waitlistHand from '~/sections/user/sections/sign-up/sections/post-activation/pages/product-preference/assets/images/waitlist-hand.svg'
import {useAppDispatch} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import styles from './ProductPreferenceSelector.scss'

const modalContent = () => {
    return (
        <>
            <div className={styles.contentList}>
                <img className={styles.contentListImage} src={waitlistCare} alt="More choice, more you" />
                <div>
                    <h3>More choice, more you</h3>
                    <p>Invest in companies and funds that align with your values</p>
                </div>
            </div>
            <div className={styles.contentList}>
                <img className={styles.contentListImage} src={waitlistHand} alt="Be hands-on or hands-off" />
                <div>
                    <h3>Be hands-on or hands-off</h3>
                    <p>Manage your investments as actively or passively as you like</p>
                </div>
            </div>
            <div className={styles.contentList}>
                <img className={styles.contentListImage} src={waitlistEasy} alt="Your wealth in one place" />
                <div>
                    <h3>Your wealth in one place</h3>
                    <p>Check your KiwiSaver balance while you check your shares</p>
                </div>
            </div>
        </>
    )
}

const TellMeMore = () => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false)

    const launchTellMeMoreDockableModal = () => setIsOpen(!isOpen)

    return (
        <>
            <ButtonAsLink onClick={launchTellMeMoreDockableModal}>Tell me more</ButtonAsLink>.<br />
            {isOpen && (
                <DockableModal
                    content={modalContent()}
                    dataTestId="dockable-modal--tell-me-more"
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    title="A KiwiSaver experience that gives you more control"
                />
            )}
        </>
    )
}

const ProductPreferenceSelector = () => {
    const dispatch = useAppDispatch()
    const [moreInfo, setMoreInfo] = React.useState<boolean>(false)
    const [selectedProduct, setSelectedProduct] = React.useState<Model.User['preferred_product']>() || 'invest'

    const registerPreference = async (preference: Request.PreferredProductUpdate['preferred_product']) => {
        await dispatch(identityActions.UpdatePreferredProduct(preference))
    }

    const next = () => {
        if (selectedProduct === 'invest') {
            registerPreference('invest')
        } else {
            setMoreInfo(!moreInfo)
        }
    }

    if (!moreInfo) {
        // render the product experience selector page. if they are not interested in KS, record preference and skip the extra screen.
        return (
            <>
                <Toolbar dataTestId="toolbar--product-preference-selector" title="How do you want to use Sharesies?" />
                <Page overrideDefaultTopPadding="withToolbarTitle">
                    <p className={spacing.spaceBelow32}>
                        Want to have your shares and KiwiSaver account in one place? Get more info about the Sharesies
                        KiwiSaver Scheme (and choose to join) after you’ve finished sign-up. No pressure, you can change
                        your mind later on. <TellMeMore />
                    </p>
                    <SelectCard
                        dataTestId="select-cards--product-preference-invest"
                        isActive={selectedProduct === 'invest'}
                        name="Invest"
                        onChange={() => setSelectedProduct('invest')}
                        title="Invest"
                        supportingText="I’m interested in buying shares in companies and funds"
                        value="invest"
                    />
                    <SelectCard
                        dataTestId="select-cards--product-preference-ks"
                        name="kiwiSaverInvest"
                        isActive={selectedProduct === 'kiwisaver_invest'}
                        onChange={() => setSelectedProduct('kiwisaver_invest')}
                        title="KiwiSaver + Invest"
                        supportingText="I’m interested in the Sharesies KiwiSaver Scheme and buying shares"
                        value="kiwiSaverInvest"
                    />
                    <Button
                        dataTestId="button--next"
                        label="Next"
                        pageButton
                        isSubmit
                        disabled={Boolean(selectedProduct === null)}
                        onClick={() => next()}
                    />
                </Page>
            </>
        )
    }

    // if they are interested in KS, show them this extra screen with a bit more info and then record preference.
    return (
        <>
            <Toolbar dataTestId="toolbar--product-preference-selector" title="Great! Here’s what’s next" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <p className={spacing.spaceBelow16}>Once you’ve signed up to Sharesies, you’ll be ready to invest.</p>
                <p className={spacing.spaceBelow16}>
                    To continue to learn about (and join) our KiwiSaver Scheme, follow the instructions in your
                    Portfolio’s KiwiSaver tab.
                </p>
                <div className={styles.previewImageContainer}>
                    <Image
                        src={[
                            [portfolioPreview, 490],
                            [portfolioPreview2x, 979],
                        ]}
                        alt="Picture of your KiwiSaver Portfolio on a mobile phone screen"
                    />
                </div>
                <Button
                    dataTestId="button--product-preference-sounds-good"
                    label="Sounds good"
                    pageButton
                    isSubmit
                    onClick={() => registerPreference('kiwisaver_invest')}
                />
            </Page>
        </>
    )
}

export default ProductPreferenceSelector
