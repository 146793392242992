/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    Currency,
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
    CurrencyToJSONTyped,
    RecordType,
    RecordTypeFromJSON,
    RecordTypeFromJSONTyped,
    RecordTypeToJSON,
    RecordTypeToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface Record
 */
export interface Record {
    /**
     *
     * @type {string}
     * @memberof Record
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof Record
     */
    portfolio_id: string
    /**
     *
     * @type {string}
     * @memberof Record
     */
    instrument_id: string
    /**
     *
     * @type {Date}
     * @memberof Record
     */
    timestamp: DateTime
    /**
     *
     * @type {number}
     * @memberof Record
     */
    quantity?: number
    /**
     *
     * @type {number}
     * @memberof Record
     */
    price?: number
    /**
     *
     * @type {number}
     * @memberof Record
     */
    transaction_fee?: number
    /**
     *
     * @type {number}
     * @memberof Record
     */
    adr_fee?: number
    /**
     *
     * @type {number}
     * @memberof Record
     */
    provider_fee?: number
    /**
     *
     * @type {number}
     * @memberof Record
     */
    exchange_rate?: number
    /**
     *
     * @type {Currency}
     * @memberof Record
     */
    currency: Currency
    /**
     *
     * @type {number}
     * @memberof Record
     */
    value_per_share?: number
    /**
     *
     * @type {number}
     * @memberof Record
     */
    payment_amount?: number
    /**
     *
     * @type {number}
     * @memberof Record
     */
    proportion?: number
    /**
     *
     * @type {string}
     * @memberof Record
     */
    related_instrument_id?: string
    /**
     *
     * @type {number}
     * @memberof Record
     */
    tax_paid?: number
    /**
     *
     * @type {RecordType}
     * @memberof Record
     */
    type: RecordType
}

export function RecordFromJSON(json: any): Record {
    return RecordFromJSONTyped(json, false)
}

export function RecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): Record {
    if (json === undefined || json === null) {
        return json
    }
    return {
        id: json['id'],
        portfolio_id: json['portfolio_id'],
        instrument_id: json['instrument_id'],
        timestamp: DateTime.fromISO(json['timestamp'], {setZone: true}),
        quantity: !exists(json, 'quantity') ? undefined : json['quantity'],
        price: !exists(json, 'price') ? undefined : json['price'],
        transaction_fee: !exists(json, 'transaction_fee') ? undefined : json['transaction_fee'],
        adr_fee: !exists(json, 'adr_fee') ? undefined : json['adr_fee'],
        provider_fee: !exists(json, 'provider_fee') ? undefined : json['provider_fee'],
        exchange_rate: !exists(json, 'exchange_rate') ? undefined : json['exchange_rate'],
        currency: CurrencyFromJSON(json['currency']),
        value_per_share: !exists(json, 'value_per_share') ? undefined : json['value_per_share'],
        payment_amount: !exists(json, 'payment_amount') ? undefined : json['payment_amount'],
        proportion: !exists(json, 'proportion') ? undefined : json['proportion'],
        related_instrument_id: !exists(json, 'related_instrument_id') ? undefined : json['related_instrument_id'],
        tax_paid: !exists(json, 'tax_paid') ? undefined : json['tax_paid'],
        type: RecordTypeFromJSON(json['type']),
    }
}

export function RecordToJSON(value?: Record | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        id: value.id,
        portfolio_id: value.portfolio_id,
        instrument_id: value.instrument_id,
        timestamp: value.timestamp.toISO(),
        quantity: value.quantity,
        price: value.price,
        transaction_fee: value.transaction_fee,
        adr_fee: value.adr_fee,
        provider_fee: value.provider_fee,
        exchange_rate: value.exchange_rate,
        currency: CurrencyToJSON(value.currency),
        value_per_share: value.value_per_share,
        payment_amount: value.payment_amount,
        proportion: value.proportion,
        related_instrument_id: value.related_instrument_id,
        tax_paid: value.tax_paid,
        type: RecordTypeToJSON(value.type),
    }
}
