import React from 'react'
import PortfolioRefetch from '~/global/data-components/PortfolioRefetch'
import {useReadPortfolio} from '~/global/state-hooks/rakaia/useReadPortfolio'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {showVerificationRequiredFlowIntro} from '~/global/utils/identity-verification/identityVerification'
import {isUninitialised} from '~/global/utils/is-loading/isLoading'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Loading} from '~/global/widgets/loading/Loading'
import {useNavigate} from '~/migrate-react-router'
import PortfolioPage from '~/sections/invest/sections/portfolio/Portfolio'
import essActions from '~/store/employeeShareScheme/actions'
import {possibleESS} from '~/store/employeeShareScheme/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import Portfolio from '~/store/portfolio/types'

const Portfolio: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const profile = useProfile()

    const user = useAppSelector(s => s.identity.user!)
    const investPortfolio = profile.portfolios.find(p => p.product === 'INVEST')!
    const portfolioSummary = useReadPortfolio(investPortfolio.id)
    const natureAndPurposeState = useAppSelector(s => s.identity.natureAndPurpose?.state)
    const natureAndPurposeEmailedAt = useAppSelector(s => s.identity.natureAndPurpose?.emailed_at)

    const identityVerification = user.checks.identity_verification
    const additionalVerificationRequired = identityVerification.additional_verification_required

    const hasEmployment = useAppSelector(s => possibleESS(s))
    const allocationsLoadingState = useAppSelector(s => s.employeeShareScheme.allocationsLoadingState)
    const participationLoadingState = useAppSelector(s => s.employeeShareScheme.participationLoadingState)

    // if the user is reach to the threshold limit of deposit then
    // redirect to the verification flow
    if (showVerificationRequiredFlowIntro() && additionalVerificationRequired) {
        navigate(profileUrl('verify-your-identity'))
    }

    //if the user needs to update their nature and purpose answers, send them to do that
    const natureAndPurposeUpdateRequired = () => {
        return natureAndPurposeState && natureAndPurposeState === 'REJECTED' && natureAndPurposeEmailedAt !== null
    }

    if (natureAndPurposeUpdateRequired()) {
        navigate(profileUrl('nature-and-purpose-update'))
    }

    React.useEffect(() => {
        if (investPortfolio.id !== portfolioSummary.uuid && user.state !== 'in_signup') {
            dispatch(identityActions.GetNatureAndPurpose())
        }
    }, [PortfolioPage, investPortfolio.id, user.state, natureAndPurposeState])

    React.useEffect(() => {
        if (
            (hasEmployment &&
                // fetch share scheme details if they're uninitialised or not loaded for this investor
                isUninitialised(allocationsLoadingState)) ||
            isUninitialised(participationLoadingState)
        ) {
            dispatch(essActions.FetchParticipatingShareSchemes())
        }
    }, [user.id, hasEmployment])

    if (hasEmployment && participationLoadingState === 'loading') {
        return <Loading isPineapple />
    }

    return <PortfolioPage />
}

export default () => (
    <>
        <Portfolio />
        {/*
            Not including the PortfolioRefetch to the ConnectedPortfolio component
            Because we don't want to re-render the PortfolioRefetch component
            every time when the props of the ConnectedPortfolio have changed
        */}
        <PortfolioRefetch />
    </>
)
