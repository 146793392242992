import {ModalLink} from '@design-system/modal'
import React from 'react'

export const InvestmentPlanIndicatorHelpModal = ({helpIconSize}: {helpIconSize?: number}) => (
    <ModalLink
        dataTestId="modal-link--investment-plan-indicator-help"
        label="Learn more about the investment plan risk indicator"
        asIcon
        modalTitle="Investment plan risk indicator"
        bottomBorder
        helpIconSize={helpIconSize}
    >
        <p>
            To calculate this score we use an industry-standard formula known as{' '}
            <a
                href="https://www.investopedia.com/terms/p/portfolio-variance.asp"
                target="_blank"
                rel="noopener"
                data-testid="link--portfolio-variance"
            >
                portfolio variance
            </a>{' '}
            to calculate the historical volatility of your investment plan, weighted to accommodate the % you’ve
            allocated to each investment.
        </p>
        <p>
            This can be a helpful tool to assess how the investments you’ve chosen affect the overall volatility and
            risk of your investment plan.
        </p>
        <p>
            Different levels of investment plan risk will suit different people depending on goals, risk appetite and
            circumstances; there’s no right level, just what’s right for you!
        </p>
        <p>
            Learn more about{' '}
            <a
                href="https://www.sharesies.nz/learn/the-different-dimensions-of-diversification"
                target="_blank"
                rel="noopener"
                data-testid="link--diversification"
            >
                how diversifying the investments in your plan can mitigate risk
            </a>
            .
        </p>
    </ModalLink>
)
