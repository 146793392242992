/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    InstrumentPurchasePricePeriodInfo,
    InstrumentPurchasePricePeriodInfoFromJSON,
    InstrumentPurchasePricePeriodInfoFromJSONTyped,
    InstrumentPurchasePricePeriodInfoToJSON,
    InstrumentPurchasePricePeriodInfoToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface InstrumentPurchasePriceInfo
 */
export interface InstrumentPurchasePriceInfo {
    /**
     *
     * @type {InstrumentPurchasePricePeriodInfo}
     * @memberof InstrumentPurchasePriceInfo
     */
    period_1w: InstrumentPurchasePricePeriodInfo
    /**
     *
     * @type {InstrumentPurchasePricePeriodInfo}
     * @memberof InstrumentPurchasePriceInfo
     */
    period_1m: InstrumentPurchasePricePeriodInfo
    /**
     *
     * @type {InstrumentPurchasePricePeriodInfo}
     * @memberof InstrumentPurchasePriceInfo
     */
    period_3m: InstrumentPurchasePricePeriodInfo
    /**
     *
     * @type {InstrumentPurchasePricePeriodInfo}
     * @memberof InstrumentPurchasePriceInfo
     */
    period_6m: InstrumentPurchasePricePeriodInfo
    /**
     *
     * @type {InstrumentPurchasePricePeriodInfo}
     * @memberof InstrumentPurchasePriceInfo
     */
    period_1y: InstrumentPurchasePricePeriodInfo
    /**
     *
     * @type {InstrumentPurchasePricePeriodInfo}
     * @memberof InstrumentPurchasePriceInfo
     */
    period_5y: InstrumentPurchasePricePeriodInfo
    /**
     *
     * @type {InstrumentPurchasePricePeriodInfo}
     * @memberof InstrumentPurchasePriceInfo
     */
    period_all: InstrumentPurchasePricePeriodInfo
}

export function InstrumentPurchasePriceInfoFromJSON(json: any): InstrumentPurchasePriceInfo {
    return InstrumentPurchasePriceInfoFromJSONTyped(json, false)
}

export function InstrumentPurchasePriceInfoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): InstrumentPurchasePriceInfo {
    if (json === undefined || json === null) {
        return json
    }
    return {
        period_1w: InstrumentPurchasePricePeriodInfoFromJSON(json['period_1w']),
        period_1m: InstrumentPurchasePricePeriodInfoFromJSON(json['period_1m']),
        period_3m: InstrumentPurchasePricePeriodInfoFromJSON(json['period_3m']),
        period_6m: InstrumentPurchasePricePeriodInfoFromJSON(json['period_6m']),
        period_1y: InstrumentPurchasePricePeriodInfoFromJSON(json['period_1y']),
        period_5y: InstrumentPurchasePricePeriodInfoFromJSON(json['period_5y']),
        period_all: InstrumentPurchasePricePeriodInfoFromJSON(json['period_all']),
    }
}

export function InstrumentPurchasePriceInfoToJSON(value?: InstrumentPurchasePriceInfo | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        period_1w: InstrumentPurchasePricePeriodInfoToJSON(value.period_1w),
        period_1m: InstrumentPurchasePricePeriodInfoToJSON(value.period_1m),
        period_3m: InstrumentPurchasePricePeriodInfoToJSON(value.period_3m),
        period_6m: InstrumentPurchasePricePeriodInfoToJSON(value.period_6m),
        period_1y: InstrumentPurchasePricePeriodInfoToJSON(value.period_1y),
        period_5y: InstrumentPurchasePricePeriodInfoToJSON(value.period_5y),
        period_all: InstrumentPurchasePricePeriodInfoToJSON(value.period_all),
    }
}
