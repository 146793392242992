import {Button} from '@design-system/button'
import cn from 'classnames'
import {Formik} from 'formik'
import {useAtom} from 'jotai'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {validate} from '~/global/widgets/form-controls'
import {CheckboxGroup, Radio, Text} from '~/global/widgets/form-controls/formik'
import signupStyles from '~/sections/moose/sections/sign-up/SignUp.scss'
import {Frequency, Purpose, SignUpEntity, stagedSignUpAtom} from '~/sections/moose/sections/sign-up/state'

interface NatureAndPurposeFormProps {
    entity: SignUpEntity
    onSubmit: (values: NatureAndPurposeFormValues) => void
}

export interface NatureAndPurposeFormValues {
    frequency: Frequency
    purposes: Purpose[]
    otherPurpose: string
}

const frequencyChoices = [
    {value: 'REGULARLY', label: 'Regularly', helpText: 'Weekly to fortnightly'},
    {value: 'OFTEN', label: 'Often', helpText: 'Monthly or a few times a year'},
    {value: 'OCCASIONALLY', label: 'Occasionally', helpText: 'Once or twice a year'},
    {value: 'RARELY', label: 'Rarely', helpText: 'Less than once a year'},
]

const purposesChoices = [
    {value: 'MONITOR_SHARE_PRICE', label: 'Monitor Fonterra’s share price'},
    {value: 'MANAGE_HOLDINGS', label: 'Share up or down'},
    {value: 'OTHER', label: 'Other'},
]

const NatureAndPurposeForm = ({entity, onSubmit}: NatureAndPurposeFormProps) => {
    const [stagedSignUp] = useAtom(stagedSignUpAtom)
    const stagedNpForEntity = stagedSignUp.natureAndPurpose.find(np => np.partyId === entity.party_id)
    const initialValues = stagedNpForEntity
        ? {
              frequency: stagedNpForEntity.frequency,
              purposes: stagedNpForEntity.purposes,
              otherPurpose: stagedNpForEntity.otherPurpose,
          }
        : {frequency: '', purposes: [], otherPurpose: undefined, applyAll: false}

    return (
        <Formik
            initialValues={initialValues as NatureAndPurposeFormValues}
            initialErrors={stagedNpForEntity ? undefined : {frequency: undefined, purposes: undefined}}
            onSubmit={(values, {resetForm}) => {
                onSubmit(values)
                resetForm() // in the case of multiple entities we need to use the form again
            }}
            validate={values => {
                return validate.generate<typeof values>({
                    frequency: [validate.required()],
                    purposes: [
                        (purposes: string[]) => {
                            if (purposes.length === 0) {
                                return 'This field is required'
                            }
                        },
                    ], // not an empty array
                    otherPurpose:
                        values.purposes && values.purposes.includes('OTHER')
                            ? [validate.required(), validate.minLength(5, 'Please provide a full description')]
                            : [],
                })(values)
            }}
        >
            {({values, isValid, isSubmitting, handleSubmit}) => {
                return (
                    <form
                        onSubmit={handleSubmit}
                        key={entity.party_id} // key required to reset focus when using the form for multiple entities
                    >
                        <div className={signupStyles.card}>
                            <h2 className={cn(signupStyles.cardHeading, spacing.spaceBelow16)}>{entity.name}</h2>
                            <Radio
                                dataTestId="radio--frequency"
                                name="frequency"
                                label="How frequently are you looking to put money into Sharesies?"
                                choices={frequencyChoices}
                                disabled={isSubmitting}
                            />
                            <CheckboxGroup
                                dataTestId="checkbox-group--purposes"
                                name="purposes"
                                label="What are your main reasons for using Sharesies?"
                                choices={purposesChoices}
                                disabled={isSubmitting}
                            />
                            {values.purposes && values.purposes.includes('OTHER') && (
                                <Text
                                    dataTestId="text-input--other-purpose"
                                    name="otherPurpose"
                                    label="What are your main reasons for using Sharesies?"
                                    placeholder="Description"
                                    disabled={isSubmitting}
                                />
                            )}
                        </div>
                        <Button
                            dataTestId="button--fonterra-confirm-nature-and-purpose"
                            label="Next"
                            disabled={!isValid}
                            processing={isSubmitting}
                            pageButton
                            isSubmit
                        />
                    </form>
                )
            }}
        </Formik>
    )
}

export default NatureAndPurposeForm
