import {Button} from '@design-system/button'
import {Flag} from '@design-system/flag'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import HelpEmail from '~/global/widgets/help-email/HelpEmail'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useAppSelector} from '~/store/hooks'
import styles from './AccountSecurity.scss'

const EnableText = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    return (
        <>
            <p className={spacing.spaceBelow12}>Add an extra layer of security to your Sharesies account.</p>
            <p className={spacing.spaceBelow12}>
                While you have two-factor authentication (2FA) enabled, you'll need to enter both your password and a
                code from an authenticator app every time you log into Sharesies. You can update your 2FA preferences at
                any time.
            </p>
            <p>
                For more info about 2FA, check out our{' '}
                <HelpCentreLink
                    auArticle="5427744-set-up-two-factor-authentication-2fa"
                    nzArticle="5427739-set-up-two-factor-authentication-2fa"
                />
                .
            </p>
            <div className={spacing.spaceBelow12}>
                <Button
                    label="Set up 2FA"
                    dataTestId="button--setup-mfa"
                    pageButton
                    onClick={() => navigate(profileUrl('settings/security/mfa'))}
                />
            </div>
        </>
    )
}

const DisableText = () => {
    const profileUrl = useProfileUrl()

    return (
        <>
            <p className={spacing.spaceBelow32}>
                While 2FA is turned on, you’ll need to enter your password and a code to log in to Sharesies. If you
                lose your device or delete the authenticator app, check out our{' '}
                <a
                    href="https://intercom.help/sharesies/en/articles/5427741-reset-two-factor-authentication-2fa"
                    target="_blank"
                    rel="noopener"
                >
                    help centre
                </a>{' '}
                or reach out to us at <HelpEmail />.
            </p>
            <div className={spacing.spaceBelow12}>
                <Button
                    type="secondary"
                    label="Turn off 2FA"
                    dataTestId="button--remove-mfa"
                    pageButton
                    reactRouterTo={profileUrl('settings/security/mfa')}
                />
            </div>
        </>
    )
}

const AccountSecurity: React.FunctionComponent<{}> = () => {
    const mfaEnabled = useAppSelector(({identity}) => identity.user!.mfa_enabled)

    return (
        <>
            <Toolbar dataTestId="toolbar--security" leftButton="back" title="Security" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <>
                    <div className={cn(spacing.spaceBelow12, styles.flex)}>
                        <div className={styles.flexTitle}>
                            <h2>Two-factor authentication (2FA)</h2>{' '}
                        </div>
                        {mfaEnabled ? (
                            <Flag dataTestId="flag--mfa-on" text="On" type="success" />
                        ) : (
                            <Flag dataTestId="flag--mfa-off" text="Off" type="neutral" isSubtle />
                        )}
                    </div>
                    {mfaEnabled ? <DisableText /> : <EnableText />}
                </>
            </Page>
        </>
    )
}

export default AccountSecurity
