import {Button} from '@design-system/button'
import {useColourMode} from '@design-system/use-colour-mode'
import cn from 'classnames'
import * as d3interpolate from 'd3-interpolate'
import React from 'react'
import {useNavigate} from 'react-router'
import Slider from 'react-slick'
import {Request} from '~/api/retail/types'
import {useRefScrollable} from '~/global/utils/use-ref-scrollable/useRefScrollable'
import {DotBig} from '~/global/widgets/OLD_icons'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {
    ClassicIntroSlideContent,
    LearnModuleSlideContent,
    ProductIntroSlideContent,
    HelpContentRenderer,
} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import PageClose from '~/global/widgets/page-back-or-close/PageClose'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useAppDispatch} from '~/store/hooks'
import actions from '~/store/identity/actions'
import styles from './IntroSlides.scss'

const slideRenderers = {
    classicIntro: HelpContentRenderer.ClassicIntroSlide,
    learnModule: HelpContentRenderer.LearnModuleSlide,
}

export interface IntroSlidesProps {
    content: ClassicIntroSlideContent[] | LearnModuleSlideContent[]
    flag?: Request.CustomerMarkHasSeenFlag['flag']
    onBack?(): unknown
    onComplete?(): unknown
    onCompleteLabel?: string
    layout?: keyof typeof slideRenderers
    disableClose?: boolean
}

export const IntroSlides: React.FunctionComponent<IntroSlidesProps> = ({
    content,
    flag,
    disableClose,
    onBack,
    onComplete,
    onCompleteLabel,
    layout: layout = 'learnModule', // default to the newer style
}) => {
    const dispatch = useAppDispatch()
    const [currentSlide, setCurrentSlide] = React.useState(0)
    const introSlidesSlider = React.useRef<Slider | null>(null)
    const [slickSliderIsAnimating, setSlickSliderIsAnimating] = React.useState(false)
    const SlideRenderer = slideRenderers[layout]

    const containerRef = React.useRef<HTMLDivElement | null>(null)
    const isScrollable = useRefScrollable(containerRef)

    const NextButton = () => {
        return currentSlide === content.length - 1 ? (
            <Button
                dataTestId="button--intro-slides"
                type="primary"
                width="auto"
                label={onCompleteLabel ?? 'Got it'}
                onClick={() => {
                    if (flag) {
                        dispatch(actions.MarkHasSeenFlag(flag))
                    }
                    if (onComplete) {
                        onComplete()
                    }
                }}
            />
        ) : (
            <Button
                dataTestId="button--intro-slides"
                type="secondary"
                width="auto"
                label="Next"
                onClick={() => {
                    if (introSlidesSlider.current) {
                        setCurrentSlide(currentSlide + 1)
                        introSlidesSlider.current.slickNext()
                    }
                }}
            />
        )
    }

    return (
        <div className={styles.slideContainer} ref={containerRef}>
            {!disableClose &&
                (currentSlide > 0 ? (
                    <PageBack
                        onClick={() => {
                            setCurrentSlide(currentSlide - 1)
                            introSlidesSlider.current?.slickGoTo(currentSlide - 1)
                        }}
                    />
                ) : (
                    <PageClose onClick={onBack} />
                ))}
            <Slider
                className={cn(styles.swipeableArea, {[styles.slickSliderIsAnimating]: slickSliderIsAnimating})}
                ref={introSlidesSlider}
                dots={false}
                arrows={false}
                infinite={false}
                speed={400}
                slidesToShow={1}
                slidesToScroll={1}
                beforeChange={() => setSlickSliderIsAnimating(true)}
                afterChange={current => {
                    setCurrentSlide(current)
                    setSlickSliderIsAnimating(false)
                }}
            >
                {content.map((slide, index) => (
                    <SlideRenderer content={slide} key={`slide-${index}`} active={currentSlide === index} />
                ))}
            </Slider>
            <div data-testid="intro-slides" className={cn(styles.footer, {[styles.footerShadow]: isScrollable})}>
                {content.length > 1 && (
                    <div className={styles.position}>
                        {content.map((_, i) => (
                            <DotBig
                                key={i}
                                className={cn({[styles.selected]: currentSlide === i})}
                                onClick={() => {
                                    if (introSlidesSlider.current) {
                                        setCurrentSlide(i)
                                        introSlidesSlider.current.slickGoTo(i)
                                    }
                                }}
                            />
                        ))}
                    </div>
                )}
                <NextButton />
            </div>
        </div>
    )
}

export interface ExtendedProductIntroSlideContent extends ProductIntroSlideContent {
    secondaryButtonLabel?: string
    onClickSecondaryButton?(): void
}

type ProductIntroSlidesProps = Omit<IntroSlidesProps, 'layout'> & {
    content: ExtendedProductIntroSlideContent[]
}

export const ProductIntroSlides: React.FunctionComponent<ProductIntroSlidesProps> = ({
    content,
    flag,
    onComplete,
    onBack,
}) => {
    const dispatch = useAppDispatch()
    const [currentSlide, setCurrentSlide] = React.useState(0)
    const [slideTransitionInProgress, setSlideTransitionInProgress] = React.useState(false)
    const slideTransitionSpeed = 400 // in milliseconds
    const colourTransitionSteps = 60 * (slideTransitionSpeed / 1000) // 60 fps * fraction of a second the slide transition lasts
    const colourAnimationRef = React.useRef<number | null>(null)
    const introSlidesSlider = React.useRef<Slider | null>(null)
    const headerBoxRef = React.useRef<HTMLDivElement | null>(null)
    const navigate = useNavigate()
    const SlideRenderer = HelpContentRenderer.ProductIntroSlide
    const colourMode = useColourMode()

    const initColourShift = (currentSlide: number, nextSlide?: number) => {
        const backgroundHeaderBox = headerBoxRef.current
        const currentColour = content[currentSlide].backgroundColour
        if (!backgroundHeaderBox || !currentColour) {
            return
        }

        // 0 is a legit value, but check if nextSlide was supplied
        if (typeof nextSlide === 'undefined') {
            // if no next slide this is likely the initial mount call, set colour and return
            backgroundHeaderBox.style.background = currentColour
            return
        }

        const nextColour = content[nextSlide].backgroundColour
        if (!nextColour) {
            return
        }

        // cancel any currently running animation
        if (colourAnimationRef.current) {
            cancelAnimationFrame(colourAnimationRef.current)
        }

        const interpolatedColorSequence: string[] = []
        for (let i = 0; i < colourTransitionSteps; i++) {
            interpolatedColorSequence.push(
                // for each fraction of our loop, get the colour value along the spectrum between the two colours
                d3interpolate.interpolateLab(currentColour, nextColour)((1 / colourTransitionSteps) * i),
            )
        }
        colourAnimationRef.current = requestAnimationFrame(() =>
            runColourShift(backgroundHeaderBox, interpolatedColorSequence.reverse()),
        )
    }

    const runColourShift = (backgroundHeaderBox: HTMLDivElement, sequence: string[]) => {
        if (sequence.length > 0) {
            backgroundHeaderBox.style.background = sequence.pop()! // if array has length colour must be defined

            // iterate animation
            colourAnimationRef.current = requestAnimationFrame(() => runColourShift(backgroundHeaderBox, sequence))
        }
    }

    React.useEffect(() => {
        // run header background colour animation check once on mount to set initial colour
        if (headerBoxRef.current) {
            initColourShift(currentSlide)
        }

        return () => {
            // cancel any running animation when the component unmounts
            if (colourAnimationRef.current) {
                cancelAnimationFrame(colourAnimationRef.current)
            }
        }
    }, [headerBoxRef.current])

    React.useEffect(() => {
        // update the colour when light/dark mode changes
        initColourShift(currentSlide)
    }, [colourMode])

    return (
        <div className={styles.animatedSlideContainer}>
            <Toolbar
                additionalClassName={styles.toolbar}
                dataTestId="toolbar--sign-up-intro"
                leftButton="back"
                onLeftButtonClick={() => {
                    if (introSlidesSlider.current && currentSlide > 0) {
                        setCurrentSlide(currentSlide - 1)
                        introSlidesSlider.current.slickGoTo(currentSlide - 1)
                    } else if (onBack) {
                        onBack()
                    } else {
                        navigate(-1)
                    }
                }}
            />
            <div className={styles.content}>
                <div className={styles.backgroundHeaderBox} ref={headerBoxRef} />
                <Slider
                    className={cn(styles.swipeableArea)}
                    ref={introSlidesSlider}
                    dots={false}
                    arrows={false}
                    infinite={false}
                    speed={slideTransitionSpeed}
                    slidesToShow={1}
                    slidesToScroll={1}
                    beforeChange={(currentSlide, nextSlide) => {
                        initColourShift(currentSlide, nextSlide)
                    }}
                    afterChange={current => {
                        setCurrentSlide(current)
                        setSlideTransitionInProgress(false)
                    }}
                >
                    {content.map((slide, index) => (
                        <SlideRenderer content={slide} key={`slide-${index}`} active={currentSlide === index} />
                    ))}
                </Slider>
                {content.length > 1 && (
                    <div className={styles.positionBottom}>
                        {content.map((_, i) => (
                            <DotBig
                                key={i}
                                className={cn({[styles.selected]: currentSlide === i})}
                                onClick={() => {
                                    if (introSlidesSlider.current) {
                                        setCurrentSlide(i)
                                        introSlidesSlider.current.slickGoTo(i)
                                    }
                                }}
                            />
                        ))}
                    </div>
                )}
                <ActionBar className={styles.actionBar}>
                    {content[currentSlide].secondaryButtonLabel && (
                        <Button
                            additionalClassName={styles.secondaryButton}
                            type="secondary"
                            dataTestId="intro-slides--second-button"
                            label={content[currentSlide].secondaryButtonLabel}
                            onClick={content[currentSlide].onClickSecondaryButton}
                        />
                    )}
                    <Button
                        dataTestId={`intro-slides--next-${currentSlide + 1}`}
                        label={content[currentSlide].buttonLabel}
                        processing={slideTransitionInProgress}
                        onClick={() => {
                            setSlideTransitionInProgress(true)
                            content[currentSlide].onButtonClick?.()

                            if (introSlidesSlider.current) {
                                if (currentSlide === content.length - 1) {
                                    if (onComplete) {
                                        if (flag) {
                                            dispatch(actions.MarkHasSeenFlag(flag))
                                        }
                                        onComplete()
                                    }
                                } else {
                                    setCurrentSlide(currentSlide + 1)
                                    introSlidesSlider.current.slickNext()
                                }
                            }
                        }}
                    />
                </ActionBar>
            </div>
        </div>
    )
}

export default IntroSlides
