import {Button} from '@design-system/button/Button'
import {semanticColour} from '@design-system/colour-tokens'
import {HorizontalGauge} from '@design-system/horizontal-gauge'
import {AutoInvest, Deposit, RecurringTopUp, RoundUps, Wallet} from '@design-system/icon'
import {useColourMode} from '@design-system/use-colour-mode'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {spaceAbove16} from '~/global/scss/helpers/spacing.scss'
import {formatNumber} from '~/global/utils/format-number/formatNumber'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {ListMenuExpanded} from '~/global/widgets/list-menu-expanded/ListMenuExpanded'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/explore/Explore.scss'
import {MoneyGoalFormValues, calculateGoalBreakdown} from '~/sections/user/pages/money-goal/MoneyGoalSetup'
import accountingActions from '~/store/accounting/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import moneyGoalStyles from './MoneyGoal.scss'

export const LevelUpLinks = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const hasSaveAccount = useAppSelector(s => Object.keys(s.identity.saveAccounts).length > 0)

    const navigateToUrl = (url: string, feature: string) => {
        rudderTrack('navigation', 'level_up_list_clicked', {feature, url})
        navigate(url)
    }

    return (
        <>
            <ListMenuExpanded
                onClick={() => navigateToUrl(profileUrl('auto-invest'), 'auto_invest')}
                dataTestId="level-up-links--autoinvest"
                icon={<AutoInvest />}
                title="Auto-invest"
                description="Choose a pre-made or DIY order and we'll place if for you regularly"
            />
            {jurisdiction === 'au' && (
                <>
                    <ListMenuExpanded
                        onClick={() => navigate(profileUrl('explore/roundups'))}
                        dataTestId="powerups--roundups"
                        icon={<RoundUps />}
                        title="Round-ups"
                        description="Automatically top up your Wallet using the leftover cents from your spending."
                    />

                    <ListMenuExpanded
                        onClick={() => navigate(profileUrl('explore/linked-bank-topup'))}
                        dataTestId="powerups--linkedbank-topups"
                        icon={<RecurringTopUp className={styles.recurringIconOverrides} />}
                        title="Linked bank top-ups"
                        description="Seamlessly transfer money from your linked bank to your Wallet with a one-off top-up, or set up a recurring top-up."
                    />
                    <ListMenuExpanded
                        onClick={() => navigateToUrl(profileUrl('wallet/topup'), 'wallet-top-up')}
                        dataTestId="level-up-links--topup"
                        icon={<Wallet />}
                        title="Top up my Wallet"
                        description="You can do this via bank transfer, Apple Pay, Google Pay, credit or debit card."
                    />
                </>
            )}
            {jurisdiction === 'nz' && (
                <>
                    <ListMenuExpanded
                        onClick={() =>
                            navigateToUrl(
                                profileUrl(hasSaveAccount ? 'save/personalise-account' : 'save/intro'),
                                'save',
                            )
                        }
                        dataTestId="level-up-links--save"
                        icon={<Deposit />}
                        title="Set up a Save account"
                        description="It’s super simple to open and has your interest at heart."
                    />
                    <ListMenuExpanded
                        onClick={() => navigateToUrl(profileUrl('wallet/topup'), 'wallet-top-up')}
                        dataTestId="level-up-links--topup"
                        icon={<Wallet />}
                        title="Top up my Wallet"
                        description="You can do this via bank transfer, Apple Pay, Google Pay, credit or debit card."
                    />
                </>
            )}
        </>
    )
}

const MoneyGoal: React.FunctionComponent = (): JSX.Element | null => {
    const goal = useAppSelector(s => s.accounting.topupGoal)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const colourMode = useColourMode()

    const intervalToFrequency = {
        WEEK: 'weekly',
        FORTNIGHT: 'fortnightly',
        MONTH: 'monthly',
    }

    const intervalToDisplayText = {
        WEEK: 'week',
        FORTNIGHT: 'fortnight',
        MONTH: 'month',
    }

    const displayAmountPerInterval = () => {
        if (goal) {
            return formatNumber({
                number: calculateGoalBreakdown(
                    Number(goal.target_amount),
                    intervalToFrequency[goal.interval] as MoneyGoalFormValues['frequency'],
                ).toString(),
                decimalPlaces: 2,
            })
        }
        return 0
    }

    React.useEffect(() => {
        dispatch(accountingActions.FetchCurrentTopupGoal())
    }, [])

    return (
        <>
            <Toolbar dataTestId="toolbar--transfer-shares-terms-and-conditions" leftButton="back" title="Money goal" />
            <Page className={moneyGoalStyles.moneyGoal} overrideDefaultTopPadding="withToolbarTitle">
                {!goal && (
                    <p className={spacing.spaceBelow16}>Create a money goal you can stick to and make it a reality</p>
                )}

                {goal && Number(goal.percent_complete) > 0 && Number(goal.percent_complete) !== 100 && (
                    <h3 className={spacing.spaceBelow8}>You're on a roll, keep it up!</h3>
                )}
                {goal && Number(goal.percent_complete) === 100 && (
                    <p className={spacing.spaceBelow16}>
                        Congratulations, you nailed it! Keep the momentum going by setting up a new goal.
                    </p>
                )}
                {goal && Number(goal.percent_complete) < 100 ? (
                    <p className={spacing.spaceBelow16}>
                        You need ${displayAmountPerInterval()} each {intervalToDisplayText[goal.interval]} to reach your
                        goal by the end of 2024.
                    </p>
                ) : (
                    <div className={spacing.spaceBelow16}>
                        <ButtonAsLink onClick={() => navigate(profileUrl('wallet/money-goal-intro'))}>
                            Set a Money Goal
                        </ButtonAsLink>
                    </div>
                )}
                {goal && (
                    <HorizontalGauge
                        additionalClassName={spacing.spaceBelow16}
                        dataTestId="money-goal-progress-gauge"
                        startLabel={
                            goal.current_total ? <DollarValue value={goal.current_total} decimalPlaces={2} /> : 0
                        }
                        endLabel={goal.target_amount ? <DollarValue value={goal.target_amount} decimalPlaces={2} /> : 0}
                        gaugeColour={semanticColour('SuccessAccent', colourMode)}
                        gaugeValue={goal.percent_complete ? Number(goal.percent_complete) : 0}
                        labelPosition="below"
                    />
                )}
                {goal && (
                    <p className={spacing.spaceBelow16}>
                        Time to take your first step towards this goal. We’ll keep in touch as the year goes on and let
                        you know how you are progressing! 🎉
                    </p>
                )}
                {goal && (
                    <div className={cn([spacing.spaceBelow32, spaceAbove16])}>
                        <Button
                            onClick={() => navigate(profileUrl('wallet/money-goal/setup'))}
                            type="secondary"
                            label="Manage my goal"
                            dataTestId="money-goal-edit-button"
                        />
                    </div>
                )}
                <h2 className={cn([spacing.spaceAbove24, spacing.spaceBelow24, moneyGoalStyles.subHeader])}>
                    Some ways to reach your goal
                </h2>
                <LevelUpLinks />
            </Page>
        </>
    )
}

export default MoneyGoal
