import cn from 'classnames'
import React from 'react'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {autoExerciseBuyCostBreakdown} from '~/global/utils/exercise-cost/exerciseCost'
import {dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import {isOnNZX} from '~/global/utils/is-on-exchange/isOnExchange'
import {amountFilled, defaultExpiryDate, isPartiallyFilled, sumTradeNetValues} from '~/global/utils/order/order'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {
    FulfilledOrdersDataRow,
    EstimatedOrderCompletionDataRow,
    PendingAndProcessingOrdersDataRow,
    TerminatedOrderDataRow,
} from '~/global/widgets/instrument-activity/BuySellDataRows'
import FXOrdersDetail from '~/global/widgets/instrument-activity/FXOrdersDetail'
import OrderFailExplanation from '~/global/widgets/instrument-activity/OrderFailExplanation'
import {CancelOrderModal} from '~/global/widgets/instrument-activity/cancel-order-modal/CancelOrderModal'
import {RecordRow} from '~/global/widgets/instrument-activity/common'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {ShareValue, DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {ExchangeTriggerOrderProcessMessage} from '~/global/widgets/order-process-messages/ExchangeOrderProcessMessage'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import actions from '~/store/accounting/actions'
import {BuyOrder, BuySellOrder} from '~/store/accounting/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {selectExchangeForInstrument} from '~/store/instrument/selectors'
import {Instrument, Exchange} from '~/store/instrument/types'
import AutoExerciseBuyDetail from './AutoExerciseBuyDetail'
import AmountFooter from './BuySellDataRows/AmountFooter'
import styles from './Record.scss'

interface RecordData {
    orderAmountText: React.ReactNode
    dataRow: React.ReactNode
    orderAmount: React.ReactNode
    estimatedOrderCompletion?: React.ReactNode
    labelText?: string
    totalOrderAmount?: React.ReactNode
}

const recordDataFor = (
    instrument: Instrument,
    isExchangeInMarketHours: boolean,
    jurisdiction: Model.User['jurisdiction'],
    order: BuySellOrder,
    exchange?: Exchange,
): RecordData => {
    const currency = instrument.currency
    const partiallyFilled = isPartiallyFilled(order)
    const useAutoExerciseOrderEstimates =
        order.type === 'buy' &&
        order.is_auto_exercise &&
        ['pending', 'processing'].includes(order.state) &&
        !partiallyFilled
    /**
     * Note these methods have sister methods in src/page/InstrumentActivity/PortfolioOrder/PortfolioOrderInvestingActivity.tsx
     * but these ones cover more cases. We plan to add to the other ones as we port order types over to being
     * PortfolioOrders, so if you're making additions here, maybe see if it needs duplicating there too.
     */
    const orderAmount = () => {
        if (order.type === 'buy') {
            const value = partiallyFilled ? amountFilled(order) : order.order_total || order.created_hold_amount

            // estimate the proportion of this auto exercise order which will
            // be used to purchase rights
            const orderHasFee = !!(order.expected_fee && parseFloat(order.expected_fee) > 0)
            if (useAutoExerciseOrderEstimates) {
                const autoExerciseCostBreakdown = autoExerciseBuyCostBreakdown(
                    instrument,
                    value.toString(),
                    jurisdiction,
                    order.price_limit,
                    orderHasFee,
                )
                if (autoExerciseCostBreakdown) {
                    return <DollarValue value={autoExerciseCostBreakdown.estimatedCostToBuy} currency={currency} />
                }
            }

            return <DollarValue value={value} currency={currency} />
        }
        if (order.type === 'sell') {
            if (order.order_total) {
                // NB: order_total is only set when a buy is fulfilled
                const netValue = parseFloat(order.order_total) - parseFloat(order.total_transaction_fee)

                return <DollarValue value={netValue} currency={currency} />
            }

            if (partiallyFilled) {
                return <DollarValue value={sumTradeNetValues(order)} currency={currency} />
            }

            // Either pending, or in a non-fulfilled terminal state (without trades)
            return <ShareValue value={order.shares} showFullValue />
        }
    }

    const orderAmountText = () => {
        if (order.type === 'sell') {
            if (partiallyFilled || order.state === 'fulfilled') {
                return 'Amount received'
            }
            // Either pending, or in a non-fulfilled terminal state (without trades)
            return `${shareLabel({instrument, isPlural: true, isCapitalised: true})} to sell`
        }

        // For Buys
        if (partiallyFilled || order.state === 'fulfilled') {
            return 'Amount paid'
        }

        if (useAutoExerciseOrderEstimates) {
            return 'Estimated amount'
        }

        return 'Amount'
    }

    switch (order.state) {
        case 'pending':
            return {
                dataRow: (
                    <PendingAndProcessingOrdersDataRow
                        currency={currency}
                        instrument={instrument}
                        jurisdiction={jurisdiction}
                        order={order}
                        partiallyFilled={partiallyFilled}
                    />
                ),
                estimatedOrderCompletion: (
                    <EstimatedOrderCompletionDataRow
                        exchange={exchange}
                        instrument={instrument}
                        isExchangeInMarketHours={isExchangeInMarketHours}
                        order={order}
                    />
                ),
                labelText: 'Pending',
                orderAmountText: orderAmountText(),
                orderAmount: orderAmount(),
            }
        case 'processing':
            const processingLabelText = () => {
                if (tradingType(instrument) === 'listed') {
                    return partiallyFilled ? 'Partially filled' : 'On market'
                }
                return 'Processing'
            }

            return {
                dataRow: (
                    <PendingAndProcessingOrdersDataRow
                        currency={currency}
                        instrument={instrument}
                        jurisdiction={jurisdiction}
                        order={order}
                        partiallyFilled={partiallyFilled}
                    />
                ),
                estimatedOrderCompletion: (
                    <EstimatedOrderCompletionDataRow
                        exchange={exchange}
                        instrument={instrument}
                        isExchangeInMarketHours={isExchangeInMarketHours}
                        order={order}
                    />
                ),
                labelText: processingLabelText(),
                orderAmountText: orderAmountText(),
                orderAmount: orderAmount(),
            }
        case 'ordered':
            return {
                dataRow: (
                    <PendingAndProcessingOrdersDataRow
                        currency={currency}
                        instrument={instrument}
                        jurisdiction={jurisdiction}
                        order={order}
                        partiallyFilled={partiallyFilled}
                    />
                ),
                estimatedOrderCompletion: (
                    <EstimatedOrderCompletionDataRow
                        exchange={exchange}
                        instrument={instrument}
                        isExchangeInMarketHours={isExchangeInMarketHours}
                        order={order}
                    />
                ),
                labelText: 'Processing',
                orderAmountText: orderAmountText(),
                orderAmount: orderAmount(),
            }
        case 'cancelling':
            return {
                dataRow: (
                    <TerminatedOrderDataRow
                        currency={currency}
                        instrument={instrument}
                        jurisdiction={jurisdiction}
                        order={order}
                        partiallyFilled={partiallyFilled}
                    />
                ),
                labelText: partiallyFilled ? 'Partially filled' : 'Cancelling',
                orderAmountText: orderAmountText(),
                orderAmount: orderAmount(),
            }
        case 'fulfilled':
            return {
                dataRow: (
                    <FulfilledOrdersDataRow
                        currency={currency}
                        instrument={instrument}
                        jurisdiction={jurisdiction}
                        order={order}
                    />
                ),
                labelText: 'Order filled',
                orderAmountText: orderAmountText(),
                orderAmount: orderAmount(),
            }
        case 'cancelled':
            return {
                dataRow: (
                    <TerminatedOrderDataRow
                        currency={currency}
                        instrument={instrument}
                        jurisdiction={jurisdiction}
                        order={order}
                        partiallyFilled={partiallyFilled}
                        timeStampRow={
                            <RecordRow
                                left="Order cancelled"
                                right={order.cancelled_at ? order.cancelled_at.toFormat(dateFormatWithYear) : '-'}
                            />
                        }
                    />
                ),
                labelText: partiallyFilled ? 'Order filled' : 'Cancelled',
                orderAmountText: orderAmountText(),
                orderAmount: orderAmount(),
            }
        case 'failed':
            return {
                dataRow: (
                    <div className={styles.recordRowContainer}>
                        <RecordRow left="Order placed" right={order.created.toFormat(dateFormatWithYear)} />
                        <RecordRow left="Order failed" right={order.created.toFormat(dateFormatWithYear)} />
                    </div>
                ),
                labelText: 'Failed',
                orderAmountText: orderAmountText(),
                orderAmount: orderAmount(),
            }
        case 'rejected':
            return {
                dataRow: (
                    <TerminatedOrderDataRow
                        currency={currency}
                        instrument={instrument}
                        jurisdiction={jurisdiction}
                        order={order}
                        partiallyFilled={partiallyFilled}
                        timeStampRow={
                            <RecordRow
                                left="Order rejected"
                                right={order.rejected_at ? order.rejected_at.toFormat(dateFormatWithYear) : '-'}
                            />
                        }
                    />
                ),
                labelText: 'Rejected',
                orderAmountText: orderAmountText(),
                orderAmount: orderAmount(),
            }
        case 'expired':
            return {
                dataRow: (
                    <TerminatedOrderDataRow
                        currency={currency}
                        instrument={instrument}
                        jurisdiction={jurisdiction}
                        order={order}
                        partiallyFilled={partiallyFilled}
                        timeStampRow={
                            <RecordRow
                                left="Order expired"
                                right={defaultExpiryDate(order).toFormat(dateFormatWithYear)}
                            />
                        }
                    />
                ),
                labelText: partiallyFilled ? 'Order filled' : 'Expired',
                orderAmountText: orderAmountText(),
                orderAmount: orderAmount(),
            }
        default:
            assertNever(order)
            return {
                dataRow: null,
                orderAmount: '',
                orderAmountText: '',
            }
    }
}

interface InstrumentActivityRecordBuySellProps {
    instrument: Instrument
    order: BuySellOrder
}

const InstrumentActivityRecordBuySell: React.FC<InstrumentActivityRecordBuySellProps> = React.memo(
    ({instrument, order}) => {
        const dispatch = useAppDispatch()

        const {exchange, isExchangeInMarketHours} = useAppSelector(s =>
            selectExchangeForInstrument(s, instrument.exchange),
        )
        const preferredName = useAppSelector(({identity}) => identity.user?.preferred_name) || ''
        const isDependent = useAppSelector(({identity}) => identity.user!.is_dependent)
        const jurisdiction = useAppSelector(({identity}) => identity.user!.jurisdiction)
        const singleCancelledOrder = !order.trades.length && order.state === 'cancelled'

        const cancelOrder = () => dispatch(actions.CancelOrder(order))
        const {dataRow, estimatedOrderCompletion, orderAmountText, labelText, orderAmount} = recordDataFor(
            instrument,
            isExchangeInMarketHours,
            jurisdiction,
            order,
            exchange,
        )

        const renderCancel = () => {
            if (!order.can_cancel) {
                return
            }

            return (
                <div className={spacing.spaceAbove48}>
                    <CancelOrderModal
                        order={order}
                        fundName={instrument.name}
                        isDependent={isDependent}
                        preferredName={preferredName}
                        displayButton
                        cancelOrder={cancelOrder}
                    />
                </div>
            )
        }

        return (
            <div className={styles.orderContent} data-testid="recent-order">
                <div className={styles.orderTitleBlock}>
                    <div className={styles.orderThumb}>
                        <InstrumentLogo instrument={instrument} noBorder />
                    </div>
                    <div>
                        <h4 className={styles.instrumentName}>{instrument.name}</h4>
                        {tradingType(instrument) !== 'managed' && (
                            <p className={styles.instrumentSymbol}>
                                <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                                <PronounceLetters text={instrument.exchange} />
                            </p>
                        )}
                    </div>
                </div>
                {dataRow}
                {estimatedOrderCompletion}
                <OrderFailExplanation order={order} instrument={instrument} jurisdiction={jurisdiction} />
                <AmountFooter orderAmount={orderAmount} orderAmountText={orderAmountText} labelText={labelText} />
                {order.type === 'buy' &&
                    order.fx_orders.length > 0 &&
                    !['rejected', 'cancelled'].includes(order.state) && (
                        <FXOrdersDetail
                            fxOrders={order.fx_orders}
                            holdAmount={parseFloat(order.created_hold_amount)}
                            instrument={instrument}
                        />
                    )}
                {order.type === 'buy' && order.is_auto_exercise && (
                    <AutoExerciseBuyDetail
                        instrument={instrument}
                        order={order as BuyOrder}
                        jurisdiction={jurisdiction}
                    />
                )}
                {/* Cancel button */}
                {renderCancel()}
                {isOnNZX(instrument) && !['rejected', 'pending'].includes(order.state) && !singleCancelledOrder && (
                    <p className={cn(styles.note, spacing.spaceAbove32)}>
                        {instrument.instrumentType === 'equity' && (
                            <>
                                Sharesies Limited is the Clearing Participant and acting as Principal to fulfil any
                                fraction of shares that are bought or sold. Sharesies Limited may also act as the agent
                                for both the buyer and seller of this investment.
                            </>
                        )}

                        {instrument.instrumentType === 'etf' && (
                            <>
                                Sharesies Limited is the Clearing Participant and acting as Principal to fulfil any
                                fraction of shares that are bought or sold. Sharesies Limited may also act as the agent
                                for both the buyer and seller of this investment and may receive rebates from fund
                                managers.
                            </>
                        )}
                    </p>
                )}
                {!!order.trigger_price && ['pending', 'processing', 'cancelling'].includes(order.state) && (
                    <p className={cn(styles.note, spacing.spaceAbove32)}>
                        <ExchangeTriggerOrderProcessMessage side={order.type} />
                    </p>
                )}
            </div>
        )
    },
)

export default InstrumentActivityRecordBuySell
