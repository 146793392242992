import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {isUSExchange} from '~/global/utils/share-transfers/shareTransfers'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import TransfersOutNzxAsxHelpTextModal from '~/sections/invest/sections/transfer-shares/pages/transfer-exchange/widgets/transfer-exchange-help-text/TransfersOutNzxAsxHelpTextModal'
import TransfersUSHelpTextModal from '~/sections/invest/sections/transfer-shares/pages/transfer-exchange/widgets/transfer-exchange-help-text/TransfersUSHelpTextModal'
import {useAppSelector} from '~/store/hooks'
import {StagedTransferOrder} from '~/store/transfer/types'

interface TransferExchangeHelpTextProps {
    direction: StagedTransferOrder['direction']
    exchange: StagedTransferOrder['exchange']
}

const TransferExchangeHelpText: React.FunctionComponent<TransferExchangeHelpTextProps> = ({direction, exchange}) => {
    const jurisdiction = useAppSelector(({identity}) => identity.user!.jurisdiction)

    if (direction === 'in') {
        if (exchange === 'NZX' || exchange === 'ASX') {
            return <p className={spacing.spaceBelow16}>It’s free to transfer shares into Sharesies.</p>
        }
        return (
            <>
                <div className={commonStyles.helpText}>
                    <p className={spacing.spaceBelow16}>A fee applies for US share transfers.</p>
                    <TransfersUSHelpTextModal direction="in" jurisdiction={jurisdiction} />
                </div>
                <p>
                    Once requested, shares from a US share transfer usually take 5-10 business days to land in your
                    Portfolio.
                </p>
            </>
        )
    }
    if (direction === 'out') {
        return (
            <>
                {exchange === 'NZX' && (
                    <p>
                        A fee applies to transfer NZX-listed shares out of Sharesies.{' '}
                        <TransfersOutNzxAsxHelpTextModal exchange={exchange} />
                    </p>
                )}
                {exchange === 'ASX' && (
                    <p>
                        A fee applies for ASX share transfers. <TransfersOutNzxAsxHelpTextModal exchange={exchange} />
                    </p>
                )}
                {isUSExchange(exchange) && (
                    <p>
                        A fee applies for US share transfers.{' '}
                        <TransfersUSHelpTextModal direction="out" jurisdiction={jurisdiction} />
                    </p>
                )}
            </>
        )
    }
    return null
}

export default TransferExchangeHelpText
