import {Button} from '@design-system/button'
import React from 'react'
import {Navigate, useNavigate} from 'react-router'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {useAllSubscriptionPlans, useCurrentSubscriptionPlan} from '~/global/utils/subscription-hooks/subscriptionHooks'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {Loading} from '~/global/widgets/loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {PaymentFrequencyNotice} from '~/sections/user/sections/settings/sections/plans/pages/view/PlanView'
import {CustomSelectCard} from '~/sections/user/sections/settings/sections/plans/widgets/custom-select-cards/CustomSelectCard'
import {useAppSelector} from '~/store/hooks'
import {selectHasStaffBenefits} from '~/store/identity/selectors'
import {SubPlanId} from '~/store/plan/types'

interface Props {
    plan?: SubPlanId
}

export const PlanBillingCycle: React.FunctionComponent<Props> = ({plan: planId}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const [currentSubscription, currentSubscriptionLoaded] = useCurrentSubscriptionPlan()
    const [billingCycle, setSelectedBillingCycle] = React.useState<'ANNUAL' | 'MONTHLY' | undefined>()

    const allPlans = useAllSubscriptionPlans()
    const hasStaffBenefits = useAppSelector(selectHasStaffBenefits)
    const {isDependent, preferredName, hasAnnualPlansFlag} = useAppSelector(s => ({
        isDependent: s.identity.user!.is_dependent,
        preferredName: s.identity.user!.preferred_name,
        hasAnnualPlansFlag: s.identity!.flags.annual_plans,
    }))

    if (!currentSubscriptionLoaded || (!currentSubscription && !allPlans)) {
        return <Loading isPineapple />
    }

    const selectedPlanDetails = currentSubscription?.auto_renew_into_plan
        ? currentSubscription.auto_renew_into_plan
        : currentSubscription
          ? currentSubscription.plan
          : allPlans.find(p => p.id === planId)

    if (!selectedPlanDetails) {
        return <WeSlippedUp />
    }

    if (!hasAnnualPlansFlag || !selectedPlanDetails.billing_options.ANNUAL || hasStaffBenefits) {
        // This plan doesn't offer an annual option.
        return <Navigate to={`/account/plan/confirm/${selectedPlanDetails.id}`} />
    }

    const pageTitle = currentSubscription ? 'Change payment frequency' : 'Choose a payment frequency'

    const setBillingCycleForContinue = (billingCycle: 'MONTHLY' | 'ANNUAL') => {
        setSelectedBillingCycle(billingCycle)
    }

    const nextStep = (billingCycle: 'MONTHLY' | 'ANNUAL') => {
        navigate(
            profileUrl('settings/plan/payment-method/:plan/:billingCycle', {
                plan: selectedPlanDetails.id,
                billingCycle,
            }),
        )
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--subscription-confirm"
                leftButton="back"
                title={pageTitle}
                onLeftButtonClick={() => {
                    navigate(-1)
                }}
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <PaymentFrequencyNotice
                    currentSubscription={currentSubscription}
                    isDependent={isDependent}
                    preferredName={preferredName}
                />

                {selectedPlanDetails && (
                    <>
                        {(['MONTHLY', 'ANNUAL'] as const).map(billingCycle => {
                            const isActive = currentSubscription
                                ? currentSubscription.auto_renew_into_billing_cycle
                                    ? currentSubscription.auto_renew_into_billing_cycle === billingCycle
                                    : currentSubscription.billing_cycle === billingCycle
                                : false
                            return (
                                <CustomSelectCard
                                    key={billingCycle}
                                    onClickIfNotActive={() =>
                                        currentSubscription
                                            ? navigate(
                                                  profileUrl('settings/plan/billing-frequency/confirm/:billingCycle', {
                                                      billingCycle,
                                                  }),
                                              )
                                            : setBillingCycleForContinue(billingCycle)
                                    }
                                    title={selectedPlanDetails.billing_options[billingCycle].title}
                                    subText={selectedPlanDetails.billing_options[billingCycle].description}
                                    dataTestId={`custom-select-card--${selectedPlanDetails.billing_options[billingCycle].id}`}
                                    active={isActive}
                                />
                            )
                        })}
                    </>
                )}
            </Page>

            {!currentSubscription && (
                <ActionBar>
                    <Button
                        disabled={!billingCycle}
                        dataTestId="button--billing-cycle-continue"
                        label="Continue"
                        onClick={() => nextStep(billingCycle!)}
                    />
                </ActionBar>
            )}
        </>
    )
}

export default PlanBillingCycle
