import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {useReadPortfolio} from '~/global/state-hooks/rakaia/useReadPortfolio'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import DetailModal from '~/global/widgets/detail-modals/DetailModal'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import TotalReturnModal from '~/sections/invest/sections/portfolio/widgets/modals/TotalReturnModal'
import {useAppSelector} from '~/store/hooks'
import styles from './PortfolioBreakdownV2.scss'

interface GaugeProps {
    showLink?: boolean
    showLegend?: boolean
    isBigTitle?: boolean
}

export const BreakdownGauge: React.FC<GaugeProps> = ({showLink, showLegend, isBigTitle}) => {
    const profile = useProfile()

    const instruments = useAppSelector(s => s.identity.holdings)

    const investPortfolio = profile.portfolios.find(p => p.product === 'INVEST')!
    const portfolio = useReadPortfolio(investPortfolio.id)
    const portfolioValue = portfolio.portfolio_value!

    const showMutualFund = useAppSelector(s => s.identity.user!.jurisdiction === 'nz')

    const legendData = [
        {
            class: cn(styles.circle, styles.company),
            label: 'Companies',
            percentage: portfolio.portfolio_instrument_types!.company.percent,
        },
        {
            class: cn(styles.circle, styles.etf),
            label: 'ETFs',
            percentage: portfolio.portfolio_instrument_types!.etf.percent,
        },
    ]

    if (showMutualFund) {
        legendData.push({
            class: cn(styles.circle, styles.managedFund),
            label: 'Managed funds',
            percentage: portfolio.portfolio_instrument_types!.mutual.percent,
        })
    }

    return (
        <div className={styles.breakdownGaugeInner}>
            {showLink && <ButtonAsLink className={styles.viewLink}>View</ButtonAsLink>}
            <h3 className={cn(spacing.spaceAbove8, isBigTitle && styles.isBigTitle)}>Your holdings by type</h3>
            <p className={cn(spacing.spaceBelow12, styles.summary)}>
                <DollarValue value={portfolioValue} decimalPlaces={portfolioValue > 1e6 ? 0 : 2} /> across{' '}
                {instruments.length} investments
            </p>
            <div className={styles.gauge}>
                <div
                    className={styles.company}
                    style={{
                        width: `${portfolio.portfolio_instrument_types!.company.percent}%`,
                    }}
                />
                <div
                    className={styles.etf}
                    style={{
                        width: `${portfolio.portfolio_instrument_types!.etf.percent}%`,
                    }}
                />
                {showMutualFund ? (
                    <div
                        className={styles.managedFund}
                        style={{
                            width: `${portfolio.portfolio_instrument_types!.mutual.percent}%`,
                        }}
                    />
                ) : null}
            </div>
            {showLegend && (
                <div className={styles.legend}>
                    {legendData.map((type, index) => (
                        <div className={cn(spacing.spaceBelow8)} key={index}>
                            <span className={type.class}></span>
                            <p>
                                {type.percentage}% {type.label}
                            </p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

interface BreakdownProps {
    onOpenDetail: () => void
}

const Breakdown: React.FC<BreakdownProps> = ({onOpenDetail}) => {
    const profile = useProfile()

    const investPortfolio = profile.portfolios.find(p => p.product === 'INVEST')!
    const jurisdiction = useAppSelector(({identity}) => identity.user!.jurisdiction)

    // All the data for the totalReturn modal
    const [showTotalReturnModal, setShowTotalReturnModal] = React.useState(false)
    const hasADRs = useAppSelector(s => Object.values(s.instrument.instrumentsById).some(obj => obj.isAdr))
    const portfolioSummary = useReadPortfolio(investPortfolio.id)
    const hasForeignCurrency =
        portfolioSummary.realised_currency_gain !== 0 || portfolioSummary.unrealised_currency_gain !== 0
    const hasManagedFunds = useAppSelector(s => s.identity.holdings.some(fund => fund.holding_type.includes('mutual')))
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)
    const includeSoldInvestments = useAppSelector(s => s.identity.includeSoldInvestments)
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)

    return (
        <div className={cn(spacing.spaceBelow12, styles.metaWrapper)}>
            <h2 className={spacing.spaceBelow4}>Breakdown of your portfolio holdings</h2>
            <p>
                Holdings by type and share percentage as of{' '}
                {DateTime.fromISO(DateTime.now().toISO()).toFormat('d MMM yyyy')}.
            </p>
            <div onClick={onOpenDetail} className={cn(styles.gaugeWrapper)}>
                <BreakdownGauge showLink showLegend />
            </div>
            <h2 className={cn(spacing.spaceAbove24, spacing.spaceBelow4)}>Why diversify?</h2>
            <p className={spacing.spaceBelow16}>
                <a
                    href={`https://${
                        jurisdiction === 'au' ? 'sharesies.com.au' : 'sharesies.nz'
                    }/learn/the-different-dimensions-of-diversification`}
                    rel="noopener"
                    target="_blank"
                >
                    Diversification
                </a>{' '}
                lowers your portfolio's risk. It’s kind of like not putting all of your eggs in one basket. Having a
                variety of investments will balance out the risk of a portfolio overall. To see your holdings by where
                they are listed or by risk, visit your portfolio page.
            </p>
            <p className={styles.diversificationDisclaimer}>
                Estimated Portfolio value based on delayed prices (at least 20 minutes) and the most recent exchange
                rates.
                <br />
                <br /> *
                <ButtonAsLink onClick={() => setShowTotalReturnModal(true)}>
                    How we work out your total return
                </ButtonAsLink>
                .
            </p>
            <DetailModal isOpen={showTotalReturnModal} closeHandler={() => setShowTotalReturnModal(false)}>
                <TotalReturnModal
                    hasADRs={hasADRs}
                    hasForeignCurrency={hasForeignCurrency}
                    hasManagedFunds={hasManagedFunds}
                    homeCurrency={homeCurrency}
                    includeSoldInvestments={includeSoldInvestments}
                    isDependent={isDependent}
                    preferredName={preferredName}
                    portfolioSummary={portfolioSummary}
                />
            </DetailModal>
        </div>
    )
}

export default Breakdown
