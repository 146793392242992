import cn from 'classnames'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {Model, Request, Response} from '~/api/retail/types'
import {IDENTITY_VERIFICATION_MODE_KEY} from '~/global/constants/global'
import {spacing} from '~/global/scss/helpers'
import {IdentityVerificationStep} from '~/global/utils/identity-verification/identityVerification'
import {SharesiesOmit} from '~/global/utils/type-utilities/typeUtilities'
import {Radio, ErrorBox} from '~/global/widgets/form-controls'
import styles from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/IdentityDetails.scss'
import {OtherInformation} from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/OtherInformation'
import {
    addDocumentDetailsToForm,
    getFormModeFromIdentityVerificationDocuments,
    Mode,
} from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/util'
import {OverseasPassport} from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/widgets/shared-forms/OverseasPassportForm'
import ManualSignUpIdentityCheck from '~/sections/user/sections/sign-up/sections/identity/sections/other-identity-details/ManualSignUpIdentityCheck'
import {connect} from '~/store/connect'
import {useAppSelector} from '~/store/hooks'
import actions from '~/store/identity/actions'
import {BirthCertificateForm} from './NZBirthCertificateForm'
import {LicenceForm} from './NZLicenceForm'
import {PassportForm} from './NZPassportForm'

const databases = [
    'New Zealand Transport Agency',
    'Department of Internal Affairs',
    'Global AML registry',
    'Centrix',
    'Land Information New Zealand',
    'New Zealand Companies Office',
    'White Pages',
]

const IDDuplicateErrorMessage = () => (
    <p className={spacing.spaceAbove16}>
        Uh oh, looks like this ID has already been used. Try another one or get in touch with us{' '}
        <a href="mailto:help@sharesies.co.nz">help@sharesies.co.nz</a>
    </p>
)
const NZIdentityForms: React.FunctionComponent<NZIdentityFormsProps> = ({
    isDependent,
    preferredName,
    isSecondaryId,

    verifyLicence,
    verifyPassport,
    verifyBirthCertificate,

    secondaryVerifyLicence,
    secondaryVerifyPassport,

    identityVerification,
    identityVerificationDocuments,
    setStep,
    mode,
}) => {
    const [localMode, setLocalMode] = React.useState<Mode>(mode)
    const [idDuplicateError, setIdDuplicateError] = React.useState(false)
    const isIdentityLinked = identityVerification.is_identity_linked

    const latestBiometricVerificationCheck = identityVerification.latest_biometric_verification_check
    const verificationLocationAtSignUp = latestBiometricVerificationCheck?.location === 'SIGNUP'
    // user had verified Biometric before in Signup flow and only required to verify alternative ID details but not required to verify Biometric
    const isRequiredSecondaryIdDetailsButNotBiometricVerification =
        (!latestBiometricVerificationCheck && isIdentityLinked) || verificationLocationAtSignUp

    const jurisdiction = useAppSelector(({identity}) => identity.user!.jurisdiction)

    const choices = [
        {value: 'licence', label: 'NZ driver licence'},
        {value: 'passport', label: 'NZ passport'},
        {value: 'other', label: isDependent ? 'Overseas passport' : 'Other ID'},
    ]

    React.useEffect(() => {
        if (isDependent) {
            return
        }
        if (identityVerificationDocuments && identityVerificationDocuments.length) {
            // If we have some identify verification documents, grab the appropriate mode for the form
            // This only need to happen on signup flow
            if (!isSecondaryId) {
                setLocalMode(getFormModeFromIdentityVerificationDocuments(identityVerificationDocuments))
                return
            }
        }
        const idMode = window.localStorage.getItem(IDENTITY_VERIFICATION_MODE_KEY) as Mode
        // Else we may have the mode set in local storage from before we started the verification
        // flow. If this is the case, use that to set the form mode

        if (idMode) {
            setLocalMode(idMode)
        }
    }, [identityVerificationDocuments])

    const setIDDuplicateError = (hasFieldError: boolean) => {
        setIdDuplicateError(hasFieldError)
    }

    const onSelectMode = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        // When the user selects a different mode, store this in local storage. We do this so that
        // when the user comes back from Verifi, we can select the same mode as they selected _before_
        // they started the identity verification flow
        if (!isDependent) {
            window.localStorage.setItem(IDENTITY_VERIFICATION_MODE_KEY, e.target.value)
        }
        setIdDuplicateError(false)
        setLocalMode(e.target.value as Mode)
        Analytics.event({
            // send a GA event for each selection of the identity type radio buttons
            category: isSecondaryId ? 'Verification threshold limit flow' : 'Sign Up',
            action: 'New Zealand identity verification',
            label: choices.find(i => i.value === e.target.value)!.label,
        })
    }

    if (isDependent) {
        choices.unshift({value: 'birthCertificate', label: 'NZ birth certificate'})
    }

    let formProps = {
        setStep,
        isDependent,
        preferredName,
        databases,
        setIDDuplicateError,
        isSecondaryId: isSecondaryId || false,
        isIdentityLinked: isIdentityLinked || false,
        verifyLicence: isSecondaryId ? secondaryVerifyLicence : verifyLicence,
        verifyPassport: isSecondaryId ? secondaryVerifyPassport : verifyPassport,
        verifyBirthCertificate,
    }

    if (identityVerificationDocuments && identityVerificationDocuments.length) {
        // We have documents from a previous identity verificataion. Extract the details out of the first
        // document for use in autopopulating the form
        formProps = addDocumentDetailsToForm(formProps, identityVerificationDocuments[0], localMode)
    }
    const alternativeId = choices.find(id => id.value === localMode)
    const heading = isRequiredSecondaryIdDetailsButNotBiometricVerification
        ? 'Confirm second ID details'
        : `Confirm your ${alternativeId?.label} details`
    const formInfo = isRequiredSecondaryIdDetailsButNotBiometricVerification
        ? `To verify that you’re ${preferredName}, we need you to check a second set of ID details.`
        : 'Please check that the details from your ID photo are correct.'

    const introText = isDependent
        ? `To invest for ${preferredName} you need to prove their identity. We do this by matching the info you give us, to info held by the NZ Government.`
        : `To use Sharesies, you’ll need to prove you are who you say you are. Provide one of the following forms of ID, and we’ll match it to info held by the ${
              jurisdiction === 'au' ? ' Australian' : ' NZ'
          } Government. `

    const idSelectionTiles = (
        <>
            {isSecondaryId ? (
                <>
                    <h1 className={spacing.spaceBelow16}>{heading}</h1>
                    <p className={spacing.spaceBelow16}>{formInfo}</p>
                </>
            ) : (
                <>
                    <h1 className={spacing.spaceBelow16}>Which ID would you like to use to sign up?</h1>
                    <p className={cn(styles.p, spacing.spaceBelow16)}>{introText}</p>
                </>
            )}
            <Radio
                dataTestId="radio--id-method"
                name="idMethod"
                label="Which ID do you have?"
                isTouched
                choices={choices}
                onChange={onSelectMode}
                value={localMode}
            />
        </>
    )

    return (
        <>
            {(isSecondaryId || isDependent) && idSelectionTiles}
            {idDuplicateError && <ErrorBox className={spacing.spaceBelow32} message={<IDDuplicateErrorMessage />} />}
            {localMode === 'passport' && <PassportForm {...formProps} />}
            {localMode === 'licence' && <LicenceForm {...formProps} />}
            {localMode === 'birthCertificate' && <BirthCertificateForm {...formProps} />}
            {localMode === 'overseasPassport' && <ManualSignUpIdentityCheck />}
            {localMode === 'NZFirearmLicence' && <ManualSignUpIdentityCheck />}
            {localMode === 'other' && !isDependent && <OtherInformation jurisdiction="nz" />}
            {localMode === 'other' && isDependent && <OverseasPassport {...formProps} />}{' '}
        </>
    )
}
interface OwnProps {
    isDependent: boolean
    identityVerificationDocuments?: Record<string, string>[]
    preferredName: string
    isSecondaryId?: boolean
    setStep(step: IdentityVerificationStep): void
    mode?: Mode
    setMode?(mode: Mode): void
}

interface StoreProps {
    identityVerification: Model.User['checks']['identity_verification']
}
export interface DispatchProps {
    verifyLicence(
        payload: SharesiesOmit<Request.IdentityVerifiNZLicence, Request.ActingAsRequired>,
    ): Promise<null | Response.Error>
    verifyPassport(
        payload: SharesiesOmit<Request.IdentityVerifiNZPassport, Request.ActingAsRequired>,
    ): Promise<null | Response.Error>
    verifyBirthCertificate(
        payload: SharesiesOmit<Request.IdentityVerifiBirthCertificate, Request.ActingAsRequired>,
    ): Promise<null | string>
    secondaryVerifyLicence(
        payload: SharesiesOmit<Request.IdentityVerifiNZLicence, Request.ActingAsRequired>,
    ): Promise<null | Response.Error>
    secondaryVerifyPassport(
        payload: SharesiesOmit<Request.IdentityVerifiNZPassport, Request.ActingAsRequired>,
    ): Promise<null | Response.Error>
}

type NZIdentityFormsProps = StoreProps & OwnProps & DispatchProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    ({identity}) => ({
        identityVerification: identity.user!.checks.identity_verification,
        identityVerificationDocuments:
            identity.user?.checks.identity_verification.latest_biometric_verification_check?.documents,
    }),
    dispatch => ({
        verifyLicence: payload => dispatch(actions.VerifiLicence(payload)),
        verifyPassport: payload => dispatch(actions.VerifiPassport(payload)),
        verifyBirthCertificate: payload => dispatch(actions.VerifiBirthCertificate(payload)),
        secondaryVerifyLicence: payload => dispatch(actions.SecondaryVerifiLicence(payload)),
        secondaryVerifyPassport: payload => dispatch(actions.SecondaryVerifiPassport(payload)),
    }),
)(NZIdentityForms)
