import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import {withFormik} from 'formik'
import React from 'react'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {ErrorBox, validate} from '~/global/widgets/form-controls'
import {ButtonGroup, Email, Select} from '~/global/widgets/form-controls/formik'
import NZXParticipant from '~/global/widgets/help-modals/NZXParticipant'
import Page from '~/global/widgets/page/Page'
import styles from '~/sections/user/sections/sign-up/pages/prescribed-person/PrescribedPerson.scss'
import SignupToolbar from '~/sections/user/sections/sign-up/widgets/signup-toolbar/SignupToolbar'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'

interface DeclarationFormValues {
    prescribedPerson?: 'yes' | 'no'
    participant?: string
    prescribedEmail?: string
}

type DeclarationFormProps = Pick<
    PrescribedPersonProps,
    'setPrescribedPersonState' | 'isDependent' | 'preferredName' | 'participants'
>

const DeclarationForm = withFormik<DeclarationFormProps, DeclarationFormValues>({
    mapPropsToValues: () => ({
        prescribedPerson: undefined,
        participant: undefined,
        prescribedEmail: undefined,
    }),
    handleSubmit: async (
        {prescribedPerson, participant, prescribedEmail},
        {setSubmitting, setStatus, props: {setPrescribedPersonState}},
    ) => {
        if (prescribedPerson !== undefined) {
            try {
                const error = await setPrescribedPersonState(participant, prescribedEmail ? [prescribedEmail] : [])
                if (error) {
                    setStatus(error)
                    setSubmitting(false)
                    return
                }
            } catch (e) {
                setStatus(unknownErrorMessage)
                setSubmitting(false)
                throw e
            }
        }
    },
    validate: values => {
        const schema = {
            participant: values.prescribedPerson === 'yes' ? [validate.required()] : [],
            // TODO: remove check for 'Sharesies' when we are sure the FE has the new participants list
            prescribedEmail:
                values.participant && !['Sharesies', 'Sharesies Limited'].includes(values.participant)
                    ? [validate.required(), validate.email()]
                    : [],
        }
        return validate.generate<DeclarationFormValues>(schema)(values)
    },
})(({
    isSubmitting,
    handleSubmit,
    status,
    errors,
    values,
    setFieldValue,
    isValid,
    isDependent,
    preferredName,
    participants,
}) => {
    return (
        <form onSubmit={handleSubmit}>
            <p className={spacing.spaceBelow16}>
                <strong className={styles.heading}>
                    {isDependent ? `Is ${preferredName} a Prescribed Person?` : 'Are you a Prescribed Person?'}
                </strong>
            </p>
            <ButtonGroup
                name="prescribedPerson"
                details={[
                    {
                        label: 'Yes',
                        value: 'yes',
                        callback: () => values.prescribedPerson !== 'yes' && setFieldValue('participant', ''),
                    },
                    {
                        label: 'No',
                        value: 'no',
                        callback: () => setFieldValue('participant', undefined),
                    },
                ]}
            />
            <ErrorBox message={errors.prescribedPerson} />
            {values.prescribedPerson === 'yes' && (
                <Select
                    dataTestId="select-participant"
                    name="participant"
                    label={
                        'Which NZX Participant or Advising firm ' +
                        (isDependent ? `does ${preferredName}’s parent` : 'do you (or your partner or parent)') +
                        ' work at?'
                    }
                    placeholder="Select a company"
                    choices={[{value: '', label: ''}].concat(participants.map(p => ({value: p, label: p})))}
                />
            )}
            {values.prescribedPerson === 'yes' &&
                values.participant &&
                !['Sharesies', 'Sharesies Limited'].includes(values.participant) && ( // TODO: remove check for 'Sharesies' when we are sure the FE has the new participants list
                    <>
                        <p>
                            <strong className={styles.heading}>Your investment activity</strong>
                        </p>
                        <p className={cn(spacing.spaceBelow24)}>
                            If you’re a Prescribed Person we'll need to email {isDependent ? 'their' : 'your'} investing
                            activity{' '}
                            {isDependent ? '' : '(and the investing activity for any Kids Accounts you manage) '}
                            to {values.participant}.
                        </p>

                        <Email
                            dataTestId="send-email-to-input"
                            name="prescribedEmail"
                            label="Email my investing activity to"
                            placeholder={`Email address for ${values.participant}`}
                        />
                    </>
                )}
            <div className={spacing.spaceAbove16}>
                <ErrorBox message={status} />
            </div>
            {values.prescribedPerson !== undefined && (
                <Button
                    dataTestId="button--next"
                    label="Next"
                    isSubmit
                    pageButton
                    disabled={values.prescribedPerson === 'yes' ? !isValid : false}
                    processing={isSubmitting}
                />
            )}
        </form>
    )
})

export class PrescribedPerson extends React.PureComponent<PrescribedPersonProps, {}> {
    public render() {
        const {isDependent, preferredName, participants} = this.props
        const prescirbedPersonsModal = (
            <ModalLink
                dataTestId="modal-link--prescribed-persons"
                label="Prescribed Person"
                modalTitle="Prescribed Person"
                primaryButton={{label: 'Ok'}}
            >
                <p>A Prescribed Person is:</p>
                <ul>
                    <li>
                        an employee of an NZX Market Participant (which includes a Trading Participant or NZX Advising
                        Firm)
                    </li>
                    <li>
                        the immediate family of an employee of an NZX Market Participant (partner or dependant child)
                    </li>
                    <li>a family company or family trust of a person referred to above; and</li>
                    <li>
                        a company, body corporate or other entity controlled by any of the persons referred to above.
                    </li>
                </ul>
            </ModalLink>
        )
        const NZXParticipantModal = (
            <NZXParticipant participants={participants} label="NZX Participant and Advising firms" />
        )

        return (
            <>
                <SignupToolbar />
                <Page>
                    <h1 className={spacing.spaceBelow16}>Prescribed Person</h1>
                    {!isDependent && (
                        <p className={spacing.spaceBelow24}>
                            If you’re an employee of an {NZXParticipantModal} (or an employee’s spouse or dependant kid)
                            you’ll be a {prescirbedPersonsModal}. The employer will let you know if you are one.
                        </p>
                    )}
                    {isDependent && (
                        <p className={spacing.spaceBelow24}>
                            A child can be a Prescribed Person if their parent is an employee of an{' '}
                            {NZXParticipantModal}.
                        </p>
                    )}
                    <DeclarationForm
                        setPrescribedPersonState={this.props.setPrescribedPersonState}
                        isDependent={isDependent}
                        preferredName={preferredName}
                        participants={participants}
                    />
                </Page>
            </>
        )
    }
}

interface StoreProps {
    isDependent: boolean
    preferredName: string
    participants: string[]
    jurisdiction: Model.User['jurisdiction']
}

interface DispatchProps {
    setPrescribedPersonState(participant?: string, prescribedEmail?: string[]): Promise<string | undefined>
}

type PrescribedPersonProps = StoreProps & DispatchProps

export default connect<StoreProps, DispatchProps>(
    ({identity}) => ({
        isDependent: identity.user!.is_dependent,
        preferredName: identity.user!.preferred_name,
        participants: identity.participants,
        jurisdiction: identity.user!.jurisdiction,
    }),
    dispatch => ({
        setPrescribedPersonState: (participant, prescribedEmail) =>
            dispatch(actions.SetPrescribedPersonState(participant, prescribedEmail)),
    }),
)(PrescribedPerson)
