import React from 'react'
import {Response, Model} from '~/api/retail/types'
import LoadingPage from '~/global/pages/loading-screen/LoadingScreen'
import {IdentityVerificationStep} from '~/global/utils/identity-verification/identityVerification'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import AUIdentityForms from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/widgets/au-forms/AUIdentityForms'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'
import NZIdentitySelector from '../other-identity-details/NZIdentitySelector'

export const IdentityDetails: React.FunctionComponent<IdentityDetailsProps> = ({
    setStep,
    isDependent,
    preferredName,
    jurisdiction,
    latestBiometricVerificationCheck: latestBiometricVerificationCheck,
    verificationDetails,
}) => {
    const [loading, setLoading] = React.useState(true)

    const showVerificationScreens = !!latestBiometricVerificationCheck

    React.useEffect(() => {
        const setLoaded = () => {
            setLoading(false)
        }

        if (showVerificationScreens) {
            //If we are showing the verification check screens, go and fetch the verification check details from the backend
            // to populate the form with
            verificationDetails().then(setLoaded)
        } else {
            setLoading(false)
        }
    }, [])

    if (loading) {
        return <LoadingPage />
    }

    if (jurisdiction === 'nz') {
        return <NZIdentitySelector />
    } else {
        return (
            <>
                <Toolbar dataTestId="toolbar--identity-details" leftButton="back" />
                <Page>
                    <AUIdentityForms isDependent={isDependent} preferredName={preferredName} setStep={setStep} />
                </Page>
            </>
        )
    }
}

interface OwnProps {
    setStep(step: IdentityVerificationStep): void
}
interface StoreProps {
    isDependent: boolean
    preferredName: string
    jurisdiction: Model.User['jurisdiction']
    latestBiometricVerificationCheck: Model.User['checks']['identity_verification']['latest_biometric_verification_check']
}

interface DispatchProps {
    verificationDetails(): Promise<null | Response.Error>
}

type IdentityDetailsProps = StoreProps & DispatchProps & OwnProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    ({identity}) => ({
        isDependent: identity.user!.is_dependent,
        preferredName: identity.user!.preferred_name,
        jurisdiction: identity.user!.jurisdiction,
        latestBiometricVerificationCheck:
            identity.user!.checks.identity_verification.latest_biometric_verification_check,
    }),

    dispatch => ({
        verificationDetails: () => dispatch(actions.VerificationDetails()),
    }),
)(props => <IdentityDetails {...props} />)
