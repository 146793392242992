import {ClassicCard} from '@braze/web-sdk'
import React from 'react'
import {Link} from 'react-router-dom'
import {normalizeUrl} from '~/global/utils/normalize-url/normalizeUrl'
import {ExploreContentCardCommon, exploreContentCardsRollbarError} from '../common'
import styles from './HalfImageCard.scss'

export interface HalfImageCardProps extends ExploreContentCardCommon {
    type: 'explore_half_image_card'
    contentLabel: string
    title: string
    durationText: string
    url: string
    imageUrl: string
}

export const brazeCardToHalfImageCard = ({
    imageUrl,
    extras = {},
    title,
    url,
    id,
}: ClassicCard): HalfImageCardProps | null => {
    const {priority, explore_content_label, duration_text} = extras

    if (!imageUrl || !url) {
        exploreContentCardsRollbarError({
            exploreCardType: 'exploreHalfImageCard',
            id,
            missingValues: {url, imageUrl},
        })

        return null
    }

    return {
        type: 'explore_half_image_card',
        contentLabel: explore_content_label,
        durationText: duration_text,
        imageUrl,
        priority,
        title,
        url,
    }
}

export const HalfImageCard = ({contentLabel, title, durationText, url, imageUrl, priority = 0}: HalfImageCardProps) => {
    const cleanUrl = normalizeUrl(url)
    return (
        <div className={styles.halfImageCard} data-priority={priority}>
            <h2>{contentLabel}</h2>

            <Link className={styles.card} to={cleanUrl}>
                <>
                    <div className={styles.content}>
                        <h3>{title}</h3>
                        <p>{durationText}</p>
                    </div>

                    <div className={styles.imageContainer}>
                        <img className={styles.image} src={imageUrl} alt="" />
                    </div>
                </>
            </Link>
        </div>
    )
}
