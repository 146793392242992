import {Button} from '@design-system/button'
import {Formik} from 'formik'
import {useAtomValue} from 'jotai'
import React from 'react'
import {useRetailPost} from '~/api/query/retail'
import {CUSTOMER_SIGNUP_VERSION} from '~/global/constants/global'
import {urlFor} from '~/global/routeGenerator'
import {formatFullName} from '~/global/utils/format-full-name/formatFullName'
import {ErrorBox, validate} from '~/global/widgets/form-controls'
import {Email, Password, Text} from '~/global/widgets/form-controls/formik'
import {useNavigate} from '~/migrate-react-router'
import {constructEntityDetail} from '~/sections/moose/sections/sign-up/sections/account-linking/pages/utils/construct-entity-detail/constructEntityDetail'
import {constructIdentityDetail} from '~/sections/moose/sections/sign-up/sections/account-linking/pages/utils/construct-identity-detail/constructIdentityDetail'
import {stagedSignUpAtom} from '~/sections/moose/sections/sign-up/state'
import {useSignupEntityDetail} from '~/sections/moose/sections/sign-up/utils/sign-up-detail-hooks/signUpDetailHooks'
import {useAppDispatch} from '~/store/hooks'
import actions from '~/store/identity/actions'

const CreateAccountForm = ({signupId}: {signupId: string}) => {
    const dispatch = useAppDispatch()
    const [error, setError] = React.useState('')
    const stagedSignUp = useAtomValue(stagedSignUpAtom)
    const signupEntityDetail = useSignupEntityDetail()
    const navigate = useNavigate()

    const fullName = formatFullName({
        firstName: signupEntityDetail.first_name,
        middleName: signupEntityDetail.middle_name || '',
        lastName: signupEntityDetail.last_name || '',
    })

    const createAccountQuery = useRetailPost({
        path: 'fonterra/signup/onboard-customer',
    })

    return (
        <Formik
            initialValues={{
                fullName,
                preferredName: '',
                email: signupEntityDetail.invite_email,
                password: '',
                confirmPassword: '',
            }}
            onSubmit={async values => {
                let response
                try {
                    if (!stagedSignUp.tcVersion) {
                        throw new Error('Missing details when trying to complete setup')
                    }

                    response = await createAccountQuery.mutateAsync({
                        signup_id: signupId,
                        signup_attempt_token: stagedSignUp.signupAttemptToken || '', // let the api handle not having a signup attempt token
                        identity: constructIdentityDetail(values.email, stagedSignUp, signupEntityDetail),
                        entities: constructEntityDetail(stagedSignUp, signupEntityDetail),
                        preferred_name: values.preferredName,
                        password: values.password,
                        customer_signup_version: CUSTOMER_SIGNUP_VERSION,
                    })
                } catch (error) {
                    setError('We couldn’t complete your request. Please try again later.')
                }

                if (response && response.type === 'identity_authenticated') {
                    dispatch(actions.handleIdentityResponse(response))
                    navigate(urlFor(''))
                } else if (response) {
                    setError(response.message)
                }
            }}
            validate={values => {
                return validate.generate<typeof values>({
                    fullName: [validate.required()],
                    preferredName: [validate.required()],
                    email: [validate.required(), validate.email()],
                    password: [validate.required(), validate.password()],
                    confirmPassword: [validate.required(), validate.sameAs('password', 'Passwords don’t match')],
                })(values)
            }}
        >
            {({isSubmitting, isValid, handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Text dataTestId="text-input--full-name" name="fullName" label="Full name" disabled />
                    <Text dataTestId="text-input--preferred-name" name="preferredName" label="Preferred name" />
                    <Email
                        dataTestId="input--email"
                        name="email"
                        label="Email address"
                        helpText="You can update this if you need."
                    />
                    <Password
                        dataTestId="password"
                        name="password"
                        label="Password"
                        helpText="Choose a nice strong one."
                        strengthMeter
                    />
                    <Password dataTestId="password--confirm" name="confirmPassword" label="Confirm password" />
                    <ErrorBox message={error} />
                    <Button
                        label="Next"
                        type="primary"
                        dataTestId="button--fonterra-confirm-code"
                        disabled={!isValid}
                        processing={isSubmitting}
                        pageButton
                        isSubmit
                    />
                </form>
            )}
        </Formik>
    )
}

export default CreateAccountForm
