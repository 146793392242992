import {Button} from '@design-system/button'
import {Form, Formik, FormikErrors, useFormikContext} from 'formik'
import React from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import {useRetailPost} from '~/api/query/retail'
import {Response} from '~/api/retail/types'
import * as rollbar from '~/api/rollbar/rollbar'
import NotFound from '~/global/pages/not-found/NotFound'
import {spacing} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {validate} from '~/global/widgets/form-controls'
import {StrongNumber} from '~/global/widgets/form-controls/formik'
import HelpEmail, {helpEmailString} from '~/global/widgets/help-email/HelpEmail'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {WithdrawalOrder} from '~/sections/wallet/sections/withdrawals/Withdrawals'
import {WITHDRAW_MESSAGE} from '~/sections/wallet/sections/withdrawals/pages/confirm/Confirm'
import {useHistoryState, useWalletPortfolio} from '~/sections/wallet/state/local'
import {useLegacyUserData} from '~/sections/wallet/state/retail'

interface VerificationFormValues {
    verification_token: string
}

type CommunicationType = Response.WithdrawalVerificationRequired['communication_type']

interface CallSubmitWithdrawalVerificationProps {
    values: VerificationFormValues
    setErrors: (
        errors: FormikErrors<{
            verification_token: string
        }>,
    ) => void
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void
}
type CallSubmitWithdrawalVerification = (props: CallSubmitWithdrawalVerificationProps) => void

// type guard that checks if a string is of type CommunicationType
const validCommunicationType = (type: string): CommunicationType => {
    if (type === 'email' || type === 'phone' || type === 'not_sent') {
        return type
    }
    return undefined
}

const VerifyWithdrawalForm = ({
    codeHelpText,
    communicationType,
    callSubmitWithdrawalVerification,
}: {
    codeHelpText: string
    communicationType: CommunicationType
    callSubmitWithdrawalVerification: CallSubmitWithdrawalVerification
}) => {
    const {isRestrictedAccount, jurisdiction} = useLegacyUserData()
    const {values, isSubmitting, isValid, setErrors, setFieldValue, setFieldTouched} =
        useFormikContext<VerificationFormValues>()

    return (
        <Form>
            <StrongNumber
                normalisation="numberOnly"
                maxLength={6}
                decimalPlaces={0}
                dataTestId="strong-number--verification-code"
                name="verification_token"
                placeholder="000000"
                label="Verification code"
                helpText={codeHelpText}
            />

            <p className={spacing.spaceAbove8}>Having problems or didn't receive a code?</p>

            <ButtonAsLink
                className={spacing.spaceBelow32}
                onClick={async () => {
                    setFieldValue('verification_token', '') // need to set this to an empty string to avoid the backend going down its invalid token path rather than its resend token path
                    await callSubmitWithdrawalVerification({values, setErrors, setFieldValue, setFieldTouched})
                }}
            >
                Send a code via email {/* if we sent a code in the last hour, backend will fall back to email */}
            </ButtonAsLink>

            {communicationType === 'not_sent' && (
                <AlertCard
                    type="warning"
                    title="Looks like you’re having trouble receiving Verification codes."
                    links={[
                        {
                            text: 'Please contact our Investor Care team for support.',
                            href: `mailto:${helpEmailString(jurisdiction)}`,
                        },
                    ]}
                    className={spacing.spaceBelow32}
                />
            )}

            <p className={spacing.spaceAbove8}>
                If you’re still having problems, get in touch at <HelpEmail />
            </p>

            <Button
                isSubmit
                dataTestId="button--withdraw-verify"
                label="Confirm code"
                disabled={!isValid || isRestrictedAccount}
                processing={isSubmitting}
                pageButton
            />
        </Form>
    )
}

export const VerifyWithdrawal = ({communicationType}: {communicationType: string}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const {id: walletPortfolioId} = useWalletPortfolio()
    const {jurisdiction} = useLegacyUserData()
    const [withdrawalOrder, setWithdrawalOrder] = useHistoryState<WithdrawalOrder | undefined>(
        'withdrawalOrder',
        undefined,
    )
    const [updatedCommunicationType, setUpdatedCommunicationType] = React.useState(
        validCommunicationType(communicationType),
    )
    const submitWithdrawalVerification = useRetailPost({
        path: 'withdrawals/:portfolio_id/create',
        pathParams: {
            portfolio_id: walletPortfolioId,
        },
        queryCacheToInvalidate: ['profiles'], // big balance number on wallet screen to return to
    })
    const [codeHelpText, setCodeHelpText] = React.useState('')

    if (!updatedCommunicationType) {
        return <NotFound />
    }

    if (!withdrawalOrder) {
        return <Navigate to={profileUrl('wallet/:portfolioId/withdrawal', {portfolioId: walletPortfolioId})} replace />
    }

    const {amount, bank_account, bank_account_name, bank_bsb, withdrawal_cost, method} = withdrawalOrder
    const isFast = method && method === 'fast' // must have selected the fast method in the options step

    // There are two methods through which this function could be called -
    // either hitting the link to resend the code, or submitting the form. They
    // both hit the same backend endpoint, but the backend will know what to do
    // based on the presence of the verification_token
    const callSubmitWithdrawalVerification: CallSubmitWithdrawalVerification = async ({
        values,
        setErrors,
        setFieldValue,
        setFieldTouched,
    }) => {
        setCodeHelpText('') // clear it if we had previously set it

        try {
            await submitWithdrawalVerification.mutateAsync(
                {
                    amount,
                    bank_account,
                    bank_account_name,
                    jurisdiction,
                    bank_bsb,
                    expected_fee: isFast ? withdrawal_cost?.expected_fee : undefined,
                    fast: isFast,
                    verification_token: values.verification_token,
                },
                {
                    onSuccess: response => {
                        switch (response.type) {
                            case 'withdrawal_verification_required':
                                // this response type could result from either requesting a new code or sending a duff one
                                // how we handle it depends on if the verification_token was present in the request
                                if (values.verification_token) {
                                    setErrors({
                                        verification_token: 'Your code doesn’t match or has expired',
                                    })
                                } else {
                                    setUpdatedCommunicationType(response.communication_type)
                                    if (response.communication_type === 'email') {
                                        Toast('A new verification code is on its way 😄')
                                        setCodeHelpText('A new verification code has been sent via email.')
                                        setFieldValue('verification_token', '')
                                        setFieldTouched('verification_token', false)
                                    }
                                }
                                break
                            case 'withdrawal_success_response':
                                setWithdrawalOrder(undefined)
                                Toast(WITHDRAW_MESSAGE)
                                navigate(profileUrl('wallet/:portfolioId', {portfolioId: walletPortfolioId}))
                                break
                            case 'withdrawal_invalid_fee':
                                navigate(-3) // invalid fee can be from fast withdrawals only, so only need to go back to the start to try to validate the fee again
                                Toast('There was an error submitting your withdrawal request')
                                break
                            default:
                                assertNever(response)
                        }
                    },
                    onError: error => {
                        switch (error.type) {
                            case 'account_restricted':
                            case 'internal_server_error':
                                // unexpected error we don't know how to handle at this stage - throw and handle in the catch block
                                throw new Error(error.type)
                            case 'error':
                                setErrors({
                                    verification_token: error.message,
                                })
                                break
                            case 'validation_error':
                                if (error.errors.verification_token) {
                                    setErrors({
                                        verification_token: error.errors.verification_token[0],
                                    })
                                } else {
                                    // there shouldn't be errors for anything other than the verification_token, as they were verified on previous steps
                                    // we don't immediately display these verification errors to the user on display of the form, but when they submit there they will get them
                                    Toast('There was an error submitting your withdrawal request')
                                    if (isFast) {
                                        navigate(-3)
                                    } else {
                                        navigate(-2)
                                    }
                                }
                                break
                            default:
                                assertNever(error)
                        }
                    },
                },
            )
        } catch (error) {
            // Formik will catch unhandled errors so the error boundary won't be displayed.
            // We don't know how to handle these errors, so just show a generic error message.
            Toast('There was an error submitting your withdrawal request')
            // Internal server errors will have already have been logged to rollbar but otherwise
            // we should report here (as Formik will otherwise suppress the error)
            if (error instanceof Error && error.message !== 'internal_server_error') {
                rollbar.sendError(`Withdrawals verification form: unexpected error`, {
                    message: error.message,
                })
            }
        }
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--withdraw-verify" leftButton="back" title="Verify your withdrawal" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <p className={spacing.spaceBelow24}>
                    {communicationType === 'email' ? (
                        <>
                            We’ve sent a verification code to your email address. Check your inbox, and enter the code
                            to verify your withdrawal.
                        </>
                    ) : (
                        <>
                            We’ve sent a verification code to your phone number. Check your texts, and enter the code to
                            verify your withdrawal.
                        </>
                    )}
                </p>
                <Formik
                    initialValues={{verification_token: ''}}
                    validate={validate.generate<VerificationFormValues>({
                        verification_token: [
                            validate.required(),
                            validate.minDigits(6, 'The code should be 6 digits long'),
                        ],
                    })}
                    onSubmit={async (values, {setErrors, setFieldValue, setFieldTouched}) => {
                        await callSubmitWithdrawalVerification({values, setErrors, setFieldValue, setFieldTouched})
                    }}
                >
                    <VerifyWithdrawalForm
                        codeHelpText={codeHelpText}
                        communicationType={updatedCommunicationType}
                        callSubmitWithdrawalVerification={callSubmitWithdrawalVerification}
                    />
                </Formik>
            </Page>
        </>
    )
}
