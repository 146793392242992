/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails,
    AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetailsFromJSON,
    AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetailsFromJSONTyped,
    AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetailsToJSON,
    AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetailsToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface AppSchemasForAdminPortfolioInstrumentInvestingHistory
 */
export interface AppSchemasForAdminPortfolioInstrumentInvestingHistory {
    /**
     *
     * @type {Array<AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails>}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistory
     */
    history: Array<AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails>
}

export function AppSchemasForAdminPortfolioInstrumentInvestingHistoryFromJSON(
    json: any,
): AppSchemasForAdminPortfolioInstrumentInvestingHistory {
    return AppSchemasForAdminPortfolioInstrumentInvestingHistoryFromJSONTyped(json, false)
}

export function AppSchemasForAdminPortfolioInstrumentInvestingHistoryFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AppSchemasForAdminPortfolioInstrumentInvestingHistory {
    if (json === undefined || json === null) {
        return json
    }
    return {
        history: (json['history'] as Array<any>).map(
            AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetailsFromJSON,
        ),
    }
}

export function AppSchemasForAdminPortfolioInstrumentInvestingHistoryToJSON(
    value?: AppSchemasForAdminPortfolioInstrumentInvestingHistory | null,
): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        history: (value.history as Array<any>).map(AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetailsToJSON),
    }
}
