import {Button} from '@design-system/button'
import {InvestFilled} from '@design-system/icon'
import cn from 'classnames'
import {withFormik} from 'formik'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import calculateSharesRemaining from '~/global/utils/calculate-shares-remaining/calculateSharesRemaining'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {StrongCurrency, StrongNumber, Radio} from '~/global/widgets/form-controls/formik'
import {required, money} from '~/global/widgets/form-controls/validate'
import HelpCentreLink from '~/global/widgets/help-centre-link/HelpCentreLink'
import AveragePricePaidPerShare from '~/global/widgets/help-modals/AveragePricePaidPerShare'
import {DollarValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import styles from '~/sections/invest/sections/transfer-shares/pages/transfer-shares-details/TransferSharesDetails.scss'
import {Instrument} from '~/store/instrument/types'
import {ResponseTransferExchange, StagedTransferOrder, TransferOrderInstrument} from '~/store/transfer/types'

interface AddTransferDetailsFormProps {
    instrumentId: string
    addStagedTransferOrderDetails(details: TransferOrderInstrument): void
    closePrice?: string
    currentInstrument?: TransferOrderInstrument
    instrumentCurrency: Instrument['currency']
    stagedOrderInstruments: StagedTransferOrder['instruments']
    direction: StagedTransferOrder['direction']
    exchange: StagedTransferOrder['exchange']
    holdingShares?: string
    profileUrl: ReturnType<typeof useProfileUrl>
}

interface AddTransferDetailsFormValues {
    numberOfShares: string
    averagePricePaidPerShare?: string
    allShares?: string
}

const AddTransferDetailsForm = withRouter(
    withFormik<AddTransferDetailsFormProps & WithRouterProps, AddTransferDetailsFormValues>({
        enableReinitialize: true,
        mapPropsToValues: ({currentInstrument, instrumentCurrency, exchange}) => {
            const record = currentInstrument?.records?.length ? currentInstrument.records[0] : undefined
            return {
                numberOfShares: record && record.shares ? record.shares : '',
                currency: currentInstrument,
                instrumentCurrency,
                averagePricePaidPerShare: record && record.price ? record.price : undefined,
                allShares: exchange !== 'NZX' ? 'no' : record && record.allShares === false ? 'no' : 'yes',
            }
        },
        mapPropsToErrors: ({currentInstrument, direction, exchange}) => {
            const record = currentInstrument?.records?.length ? currentInstrument.records[0] : undefined
            if (record && (record.shares || record.allShares)) {
                return {}
            }
            if (exchange === 'NZX' && direction === 'in') {
                // NZX Transfers in default to Yes, transfer all shares - the Investor
                // can submit at this point
                return {}
            } else {
                return {
                    // make the button disabled initially by setting at least one field to have an error
                    numberOfShares: undefined,
                }
            }
        },
        validate: async (values, {holdingShares, direction}) => {
            const errors: {numberOfShares?: string; averagePricePaidPerShare?: string; allShares?: string} = {}

            const requiredError = await required()(values.numberOfShares, values)
            if (requiredError) {
                if (values.allShares !== 'yes') {
                    errors.numberOfShares = ''
                }
            } else {
                const moneyError = await money({maxDecimalPlaces: direction === 'in' ? 6 : 0})(
                    values.numberOfShares,
                    values,
                )
                if (moneyError) {
                    errors.numberOfShares = moneyError
                }

                // If the supplied value is more than the amount available to transfer out
                if (
                    direction === 'out' &&
                    holdingShares &&
                    parseFloat(values.numberOfShares) > parseFloat(holdingShares)
                ) {
                    errors.numberOfShares = 'You’ve entered more shares than you own. Try again with a lower number.'
                }

                // Only whole shares can be transferred out
                if (direction === 'out' && values.numberOfShares.includes('.')) {
                    errors.numberOfShares = 'You can only transfer out whole shares.'
                }
            }

            return errors
        },
        handleSubmit: async (
            {numberOfShares, averagePricePaidPerShare, allShares},
            {
                setSubmitting,
                props: {
                    instrumentId,
                    exchange,
                    addStagedTransferOrderDetails,
                    stagedOrderInstruments,
                    currentInstrument,
                    router: {navigate},
                    profileUrl,
                    direction,
                },
            },
        ) => {
            if (currentInstrument) {
                const transferAllShares = allShares === 'yes' && direction === 'in'
                addStagedTransferOrderDetails({
                    index: currentInstrument.index,
                    instrumentId,
                    instrumentSlug: currentInstrument.instrumentSlug,
                    records: [
                        {
                            shares: !transferAllShares ? numberOfShares : '',
                            // Use close price if price is empty
                            price: averagePricePaidPerShare ? averagePricePaidPerShare : undefined,
                            allShares: transferAllShares,
                        },
                    ],
                })
            }
            setSubmitting(false)

            const currentIndexInStagedOrder = currentInstrument ? currentInstrument.index : undefined
            const nextFundSlug =
                (currentIndexInStagedOrder || currentIndexInStagedOrder === 0) &&
                currentIndexInStagedOrder < stagedOrderInstruments.length - 1
                    ? stagedOrderInstruments.find(instrument => instrument.index === currentIndexInStagedOrder + 1)!
                          .instrumentSlug
                    : undefined

            const confirmUrlSuffix = (exchange: ResponseTransferExchange) => {
                switch (exchange) {
                    case 'ASX':
                        return 'asx'
                    case 'NZX':
                        return 'nzx'
                    case 'NYSE':
                    case 'NASDAQ':
                    case 'CBOE':
                    case 'US_MARKETS':
                        return 'us'
                    default:
                        return 'nzx'
                }
            }

            nextFundSlug
                ? navigate(
                      profileUrl(`invest/portfolio-transfer-shares/:exchange/details/:instrumentSlug`, {
                          exchange: confirmUrlSuffix(exchange!),
                          instrumentSlug: nextFundSlug,
                      }),
                  )
                : navigate(profileUrl(`invest/portfolio-transfer-shares/${confirmUrlSuffix(exchange!)}/confirm`))
        },
    })(
        ({
            values,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
            closePrice,
            currentInstrument,
            direction,
            exchange,
            instrumentCurrency,
            holdingShares,
            errors,
        }) => {
            const existingRecord = currentInstrument?.records?.length ? currentInstrument.records[0] : undefined
            const [showAveragePriceForm, setShowAveragePriceForms] = React.useState(
                !!(existingRecord && existingRecord.price),
            )

            const sharesRemaining = calculateSharesRemaining(values, holdingShares)

            const preventSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault()
                }
            }

            return (
                <form onSubmit={handleSubmit}>
                    <div className={styles.formWrapper}>
                        {direction === 'in' && exchange === 'NZX' && (
                            <Radio
                                dataTestId="radio--transfer-all"
                                name="allShares"
                                label="Transfer all of your shares?"
                                choices={[
                                    {
                                        label: 'Yes',
                                        value: 'yes',
                                    },
                                    {
                                        label: 'No',
                                        value: 'no',
                                    },
                                ]}
                            />
                        )}
                        {(exchange !== 'NZX' ||
                            direction === 'out' ||
                            (direction === 'in' && values.allShares !== 'yes')) && (
                            <div className={spacing.spaceBelow24}>
                                <StrongNumber
                                    dataTestId="strong-number--number-of-shares"
                                    name="numberOfShares"
                                    placeholder="Shares"
                                    disabled={isSubmitting}
                                    autoFocus={direction === 'out'}
                                    optionalAttributes={{
                                        onKeyPress: preventSubmit,
                                        onBlur: handleBlur,
                                    }}
                                    normalisation="numberOnly"
                                    decimalPlaces={0}
                                    label={`Number of shares to transfer ${direction}`}
                                    helpText={
                                        direction === 'out' && sharesRemaining ? (
                                            <>
                                                <p
                                                    className={cn(styles.sharesRemaining, {
                                                        [styles.sharesRemainingWithErrorBox]: errors,
                                                    })}
                                                >
                                                    <InvestFilled colour="IconDefault" /> Shares remaining:{' '}
                                                    <strong>
                                                        <ShareValue value={sharesRemaining} showFullValue />
                                                    </strong>
                                                </p>
                                                <p className={styles.wholeShares}>
                                                    You can only transfer whole shares that have{' '}
                                                    <HelpCentreLink
                                                        auArticle="4983245-order-processing-times"
                                                        nzArticle="3098147-order-processing-times"
                                                    >
                                                        settled
                                                    </HelpCentreLink>
                                                    .
                                                </p>
                                            </>
                                        ) : (
                                            <p className={styles.walletRemaining}>
                                                Transfer all, or just some of your shares. You can only transfer whole
                                                shares.
                                            </p>
                                        )
                                    }
                                />
                            </div>
                        )}

                        {direction === 'in' &&
                            (showAveragePriceForm ? (
                                <>
                                    <StrongCurrency
                                        dataTestId="strong-currency--average-price-per-share"
                                        name="averagePricePaidPerShare"
                                        label="Average price paid per share (optional)"
                                        disabled={isSubmitting}
                                        optionalAttributes={{
                                            onBlur: handleBlur,
                                        }}
                                        decimalPlaces={3}
                                        placeholder={closePrice ? parseFloat(closePrice).toFixed(3) : undefined}
                                        currency={instrumentCurrency}
                                        helpText={
                                            <p className={styles.walletRemaining}>
                                                Use the latest close price, or enter your own.
                                                <AveragePricePaidPerShare />
                                            </p>
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <a
                                        data-testid="anchor--average-price-paid-per-share"
                                        href="#"
                                        onClick={event => {
                                            event.preventDefault()
                                            setShowAveragePriceForms(true)
                                        }}
                                    >
                                        <strong>+ Add average price paid per share (optional)</strong>
                                    </a>
                                    {closePrice && (
                                        <>
                                            <p className={cn(spacing.spaceAbove8)}>
                                                Or use the latest close price of{' '}
                                                <DollarValue value={closePrice} decimalPlaces={3} />{' '}
                                                <AveragePricePaidPerShare />
                                            </p>
                                        </>
                                    )}
                                </>
                            ))}
                    </div>

                    <ActionBar>
                        <Button
                            dataTestId="button--next"
                            label="Next"
                            disabled={!isValid}
                            processing={isSubmitting}
                            onClick={() => handleSubmit()}
                        />
                    </ActionBar>
                </form>
            )
        },
    ),
)

export default AddTransferDetailsForm
