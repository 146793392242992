import React from 'react'
import {useNavigate, NavigateFunction} from 'react-router'
import {unstable_useBlocker as useBlocker, matchRoutes, Params} from 'react-router-dom'
import {rudderIdentify, isRudderInitialised} from '~/api/rudderstack/rudderstack'
import {urlFor} from '~/global/routeGenerator'
import {ROUTES} from '~/global/routes'
import {injectParamsAsProps} from '~/global/utils/routing/routing'
import {stripProfile} from '~/global/utils/strip-profile/stripProfile'
import {useAppDispatch} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import {User} from '~/store/identity/types'
import actions from '~/store/nav/actions'
import {Dispatch} from '~/store/types'

/**
 * Helper for nativeHandoffRoute matching, replaces placeholders in a path with actual params
 *
 * @param {string} path - the path with placeholders to replace the params in
 * @param {Params<string> | null} params - the params to replace in the path
 * @returns {string} the path with the params replaced
 */
const injectParams = (path: string, params: Params<string> | null) => {
    return path.replace(/:([^/]+)/g, (_, paramKey) => {
        const param = params ? params[paramKey] : undefined
        return param ?? `:${paramKey}`
    })
}

// a proxy for the navigate function to be registered when the app is mounted
const navigateProxyTarget = Symbol('navigateProxyTarget')
const dispatchProxyTarget = Symbol('dispatchProxyTarget')

// used in src/global/third-party-types/window.d.ts
export interface nativeAppControls {
    navigate: (to: string) => void
    [navigateProxyTarget]?: NavigateFunction

    // Passthrough function allowing the native apps to trigger a
    // Rudderstack Identify call when needed
    rudderIdentify: (investorId: string, gaId: string) => void

    switchUser: (investorId: string) => Promise<User | null>
    [dispatchProxyTarget]?: Dispatch

    // Function for hiding flyout menu. Returns whether or not actions were invoked
    hideFlyoutMenu: () => boolean

    // Functions for controlling the global visibility of the Toolbar component
    hideToolbar: () => boolean
    showToolbar: () => boolean

    isPushNotificationsEnabled: () => boolean

    attemptHandoff: (path: string) => boolean
    nativeHandoffRoutes?: string[]
}

/**
 * Methods for the native apps to be able to trigger actions within the web app.
 * If you're looking to be able to send messages TO the web app, you should look at
 * `send-wrapper-app-message/sendWrapperAppMessage` instead.
 */
window.nativeAppControls = {
    // NATIVE BACKCOMPAT: this navigate function is likely unused so can be removed at next forced version bump
    navigate(to) {
        const proxy = this[navigateProxyTarget]
        // special case for portfolio tab to redirect
        if (proxy && to === 'portfolio') {
            proxy(urlFor(''))
            return true
        }
        // restrict navigation to only supported paths
        if (proxy && (to === 'invest' || to === 'explore' || to === 'wallet' || to === 'account')) {
            proxy('/' + to)
            return true
        }
        return false
    },
    rudderIdentify(investorId, gaId) {
        isRudderInitialised().then(() => {
            rudderIdentify(investorId, gaId)
        })
    },
    async switchUser(investorId) {
        const dispatch = this[dispatchProxyTarget]
        const navigate = this[navigateProxyTarget]

        if (!dispatch || !navigate) {
            throw new Error('No proxy available')
        }

        const user = await dispatch(identityActions.SwitchUser(investorId))

        if (user == null) {
            throw new Error('User switching failed')
        }

        navigate(urlFor(''))

        return user
    },
    hideFlyoutMenu() {
        const dispatch = this[dispatchProxyTarget]
        if (dispatch) {
            dispatch(actions.HideFlyoutMenu())
            dispatch(actions.LockFlyoutVisibility())
            return true
        }
        return false
    },
    hideToolbar() {
        const dispatch = this[dispatchProxyTarget]
        if (dispatch) {
            dispatch(actions.HideToolbar())
            return true
        }
        return false
    },
    showToolbar() {
        const dispatch = this[dispatchProxyTarget]
        if (dispatch) {
            dispatch(actions.ShowToolbar())
            return true
        }
        return false
    },
    isPushNotificationsEnabled(): boolean {
        // Stub push notifications enabled flag to true.
        // Native apps will overwrite this function and return the correct state
        return true
    },
    attemptHandoff(nextPath): boolean {
        // NATIVE BACKCOMPAT: For backwards compatibility with older native clients, strip the profile and profile slug from the path to check that *also*
        const nextPathWithoutProfile = stripProfile(nextPath)

        // NATIVE BACKCOMPAT: for older native clients, translate new routes to
        // their older counterparts. Contents can be removed whenever we do a
        // forced version bump for native clients, so long as they're been
        // updated with the new patterns (test this before assuming!)
        const backwardsCompatibleRoutes: Record<string, string> = {
            // old route : new route
            '/account': '/settings',
            '/profile/:profileSlug/account': '/profile/:profileSlug/settings',
            '/wallet': '/wallet/:portfolioId',
            '/profile/:profileSlug/wallet': '/profile/:profileSlug/wallet/:portfolioId',
            '/wallet/topup': '/wallet/:portfolioId/topup',
            '/profile/:profileSlug/wallet/topup': '/profile/:profileSlug/wallet/:portfolioId/topup',
            '/wallet/bank-transfer': '/wallet/:portfolioId/bank-transfer',
            '/profile/:profileSlug/wallet/bank-transfer': '/profile/:profileSlug/wallet/:portfolioId/bank-transfer',
            '/wallet/instant-transfer': '/wallet/:portfolioId/instant-transfer',
            '/profile/:profileSlug/wallet/instant-transfer':
                '/profile/:profileSlug/wallet/:portfolioId/instant-transfer',
        }

        // If there are no routes to handoff to, don't block navigation
        if (!window.nativeAppControls.nativeHandoffRoutes) {
            return false
        }

        // Use our actual full list of routes to get the params for the next path
        const nextRoutes = matchRoutes(injectParamsAsProps(ROUTES), nextPath)
        const nextRoute = nextRoutes ? nextRoutes[nextRoutes.length - 1] : null // last route is the most qualified
        const nextRouteParams = nextRoute ? nextRoute.params : null

        // For every route the native app wants to handle
        for (const nativeHandoffRoute of window.nativeAppControls.nativeHandoffRoutes) {
            let message

            // Replace all params in the native route with the actual params from the next path
            const routeWithInjectedParams = injectParams(nativeHandoffRoute, nextRouteParams)

            // Check if the next path matches the route with the params injected (with or without profile)
            if (nextPath === routeWithInjectedParams || nextPathWithoutProfile === routeWithInjectedParams) {
                message = JSON.stringify({
                    type: 'navigate',
                    route: routeWithInjectedParams, // Native expects the path we matched on with the params injected
                })
            }

            // Check the if a backwards compatible route exists
            if (backwardsCompatibleRoutes[nativeHandoffRoute]) {
                const backwardsCompatibleRouteWithInjectedParams = injectParams(
                    backwardsCompatibleRoutes[nativeHandoffRoute],
                    nextRouteParams,
                )

                // Check if the next path matches the backwards compatible route with the params injected (with or without profile)
                if (
                    nextPath === backwardsCompatibleRouteWithInjectedParams ||
                    nextPathWithoutProfile === backwardsCompatibleRouteWithInjectedParams
                ) {
                    message = JSON.stringify({
                        type: 'navigate',
                        route: injectParams(nativeHandoffRoute, nextRouteParams), // In this instance just send the old route we're performing compatibility for (but with profile injected if available/requested)
                    })
                }
            }

            // Send the message
            if (message) {
                if (window.webkit?.messageHandlers?.iOSNativeAppInterface) {
                    window.webkit.messageHandlers.iOSNativeAppInterface.postMessage(message)
                    return true
                }
                if (window.androidNativeAppInterface) {
                    window.androidNativeAppInterface.postMessage(message)
                    return true
                }
            }
        }

        // No matches or we weren't able to find the message sending interfaces, don't block navigation
        return false
    },
}

export const NativeControls: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    React.useEffect(() => {
        window.nativeAppControls[navigateProxyTarget] = navigate
        window.nativeAppControls[dispatchProxyTarget] = dispatch

        return () => {
            window.nativeAppControls[navigateProxyTarget] = undefined
            window.nativeAppControls[dispatchProxyTarget] = undefined
        }
    }, [navigate, dispatch])

    // The native apps will set nativeHandoffRoutes to an array of paths they implement themselves. We block navigation
    // to these routes so that if the user returns to the original web app page, it's still in its original state.
    // iOS handoff routes for latest version: https://gitlab.com/sharesies/mobile/ios/-/blob/development/Sharesies/Tests/__Snapshots__/NativeHandoffPathsTests/testNativeHandoffPaths.1.txt
    useBlocker(({nextLocation}) => window.nativeAppControls.attemptHandoff(nextLocation.pathname))

    return null
}
