import React from 'react'
import styles from './LoadingEllipsis.scss'

export const LoadingEllipsis: React.FunctionComponent = () => {
    return (
        <svg height="40" width="40" className={styles.loadingEllipsis}>
            <circle className={styles.dot} cx="10" cy="20" r="3" />
            <circle className={styles.dot} cx="20" cy="20" r="3" />
            <circle className={styles.dot} cx="30" cy="20" r="3" />
        </svg>
    )
}
