import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {useLatestInstrumentReturns} from '~/global/state-hooks/rakaia/useLatestInstrumentReturns'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useWatchlist} from '~/global/state-hooks/retail/useWatchlist'
import {isWrapperApp} from '~/global/utils/is-wrapper-app/isWrapperApp'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import withMeasure, {MeasureProps} from '~/global/widgets/with-measure/WithMeasure'
import {useLocation, useNavigate, Navigate} from '~/migrate-react-router'
import autoInvestStyles from '~/sections/invest/sections/auto-invest/Common.scss'
import autoInvestActions from '~/store/autoinvest/actions'
import {isDIY} from '~/store/autoinvest/types'
import {useAppSelector, useAppDispatch} from '~/store/hooks'
import actions from '~/store/instrument/actions'
import {useSearchFilterFromQueryParams} from './hooks/use-search-filter-from-query-params/useSearchFilterFromQueryParams'
import Header from './widgets/header/Header'
import SearchResults from './widgets/search-results/SearchResults'
import styles from './Search.scss'

const Search: React.FunctionComponent<SearchProps> = ({onMeasureRef, measuredHeight, isAutoInvest}) => {
    const [scrollDirection, setScrollDirection] = React.useState<'forward' | 'backward'>()
    const [isOnTop, setIsOnTop] = React.useState(false)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const profile = useProfile()

    const investPortfolio = profile.portfolios.find(p => p.product === 'INVEST')!
    const accountRestricted = useAppSelector(s => s.identity.user!.account_restricted)
    const currentSearchQuery = useAppSelector(s => s.instrument.currentSearchQuery)
    const metadataInitialised = useAppSelector(s => s.instrument.isMetadataInitialised)
    const pathname = useLocation().pathname
    const portfolioInstrumentReturns = useLatestInstrumentReturns(investPortfolio.id).instrument_returns
    const resultsLoadingState = useAppSelector(s => s.instrument.resultsLoadingState)
    const searchInitialisedFor = useAppSelector(s => s.instrument.searchInitialisedFor)
    const stagedOrder = useAppSelector(s => s.autoinvest.stagedOrder)
    const {watchlistInstrumentIds} = useWatchlist()

    const allocationCount = stagedOrder && isDIY(stagedOrder.order) ? stagedOrder.order.allocations.length : 0
    const numberOfFundsSelected = stagedOrder && isDIY(stagedOrder.order) ? stagedOrder.order.allocations.length : 0
    const searchDeps = useAppSelector(({instrument: i}) => [
        i.currentSearchQuery,
        i.currentSearchSort,
        i.currentSearchFilters,
        i.currentSearchTimePeriod,
        isAutoInvest,
    ])

    const allocationCountRef = React.useRef(allocationCount)
    const autoInvestButtonRef = React.useRef<HTMLButtonElement>(null)

    // On first load, check if there's any search filters in the URL query param
    const searchFilterFromQueryParams = useSearchFilterFromQueryParams(window.location.search)

    React.useEffect(() => {
        // Analytics event triggered on every non-empty, non-null query
        if (currentSearchQuery.length === 0) {
            return
        }
        Analytics.updatePageview(`${pathname}/?query=${encodeURIComponent(currentSearchQuery.replace(' ', '+'))}`)
    }, [pathname, currentSearchQuery])

    React.useEffect(() => {
        // Initialises search with results relevant to the current context - /auto-invest or /invest
        const initialisedInContext =
            (isAutoInvest && searchInitialisedFor === 'auto-invest') ||
            (!isAutoInvest && searchInitialisedFor === 'invest')

        if (metadataInitialised && !initialisedInContext) {
            // Don't set the CurrentSearchQuery to empty if we've got query params
            if (!searchFilterFromQueryParams) {
                dispatch(actions.SetCurrentSearchQuery(''))
            }

            dispatch(actions.initSearchResults(isAutoInvest ? 'auto-invest' : 'invest'))
        }
    }, [metadataInitialised, isAutoInvest])

    React.useEffect(() => {
        // Load instruments in an investor's portfolio and on their watchlist to
        // populate data for the respective search chips
        if (watchlistInstrumentIds.length) {
            dispatch(actions.loadWatchlistInstruments(watchlistInstrumentIds))
        }

        dispatch(actions.loadSearch(isAutoInvest ? 'auto-invest' : 'invest'))
    }, searchDeps)

    React.useEffect(() => {
        if (portfolioInstrumentReturns) {
            const portfolioInstrumentIds = Object.keys(portfolioInstrumentReturns)
            dispatch(actions.SetPortfolioInstrumentIndex(portfolioInstrumentIds))
        }
    }, [portfolioInstrumentReturns])

    React.useEffect(() => {
        if (scrollDirection === 'forward') {
            // set auto focus to false whenever user started scrolling the page
            dispatch(actions.SetSearchAutofocus(false))
        }
    }, [scrollDirection])

    React.useEffect(() => {
        if (allocationCount > allocationCountRef.current && allocationCount === 101) {
            Toast('You’ve reached the 100 investment limit. Remove some investments before continuing.')
        }
        allocationCountRef.current = allocationCount
    }, [allocationCount])

    React.useEffect(() => {
        if (searchFilterFromQueryParams) {
            dispatch(actions.SetSearch(searchFilterFromQueryParams.query, searchFilterFromQueryParams))
        }
    }, [])

    if (resultsLoadingState === 'error') {
        // if loading results causes an error, return the banana error component
        return <WeSlippedUp />
    }

    if (isAutoInvest) {
        if (isWrapperApp()) {
            if (!stagedOrder) {
                dispatch(autoInvestActions.StageNewDIY())
                return <Loading isPineapple />
            }
        } else {
            if (!stagedOrder || stagedOrder!.state === 'completed') {
                return <Navigate to={profileUrl('auto-invest')} replace />
            }
        }
    }

    return (
        <Page withoutDefaultPadding className={styles.hidePageScroll}>
            <div
                ref={onMeasureRef}
                className={cn(styles.header, {
                    [styles.scrollingUp]: scrollDirection === 'backward',
                    [styles.scrollingDown]: scrollDirection === 'forward',
                    [styles.onTop]: isOnTop,
                })}
            >
                {/*
                    There are two Header components on this page
                    1. Header that is considered as the first item in the List component
                        - so the user can scroll the entire page but not just the search results
                        - Is in SearchResultsRow.tsx

                    2. Header that slides up and down on top of the page interactively depends on the user's scroll direction

                    This is the 2. Header
                */}

                <Header isAutoInvest={isAutoInvest} isOnTop={isOnTop} isScrollingUp={scrollDirection === 'backward'} />
            </div>

            <SearchResults
                setScrollDirection={setScrollDirection}
                headerHeight={measuredHeight}
                setIsOnTop={setIsOnTop}
                isAutoInvest={isAutoInvest}
                autoInvestButtonRef={autoInvestButtonRef}
            />

            {isAutoInvest && (
                <ActionBar className={autoInvestStyles.footer}>
                    <div className={cn({[autoInvestStyles.zeroSelected]: numberOfFundsSelected === 0})}>
                        {numberOfFundsSelected} investment{numberOfFundsSelected === 1 ? '' : 's'} added
                        {numberOfFundsSelected > 0 && (
                            <>
                                <span>|</span>{' '}
                                <ButtonAsLink
                                    className={autoInvestStyles.tinyButton}
                                    onClick={() => dispatch(autoInvestActions.ResetDIYFunds())}
                                >
                                    Clear
                                </ButtonAsLink>
                            </>
                        )}
                    </div>
                    <Button
                        ref={autoInvestButtonRef}
                        additionalClassName={autoInvestStyles.bigButton}
                        onClick={() => navigate(profileUrl('auto-invest/diy/percentage'), {replace: true})}
                        disabled={accountRestricted || !stagedOrder || allocationCount === 0 || allocationCount > 100}
                        label="Select"
                        ariaLabel="Select added investments for your DIY order"
                        dataTestId="button--diy-select-funds"
                    />
                </ActionBar>
            )}
        </Page>
    )
}

interface OwnProps {
    isAutoInvest?: boolean
}

type SearchProps = MeasureProps & OwnProps

export default withMeasure(Search)
