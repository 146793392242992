import {Button} from '@design-system/button'
import {FileUpload} from '@design-system/file-upload'
import {ModalLink} from '@design-system/modal'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {PageButtonGroup} from '~/global/widgets/button-page-group/PageButtonGroup'
import {ErrorBox} from '~/global/widgets/form-controls'
import {useAppSelector} from '~/store/hooks'
import styles from './AddressVerificationUpload.scss'

interface AddressVerificationUploadProps {
    error: string | undefined
    fromEditAddress?: boolean
    fromTransfers?: boolean
    inSignUp: boolean
    onChange: (mutate: (uploads: File[]) => File[]) => void
    onSkip?(): unknown
    onSuccess(): unknown
    processing: boolean
    submitHandler: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
    files: File[]
    hideSkipButton?: boolean
}

interface AddressVerificationChildProps {
    jurisdiction: string
}

export const AddressVerificationUpload: React.FunctionComponent<AddressVerificationUploadProps> = ({
    error,
    fromEditAddress,
    fromTransfers,
    inSignUp,
    onChange,
    onSkip,
    processing,
    submitHandler,
    files,
    hideSkipButton,
}) => {
    const jurisdiction = useAppSelector(({identity}) => identity.user!.jurisdiction)
    const jurisdictionName = jurisdiction === 'nz' ? 'NZ' : 'Australian'

    return (
        <>
            <form onSubmit={submitHandler}>
                <h1 className={spacing.spaceBelow16}>Upload proof of address</h1>
                {jurisdiction === 'nz' ? (
                    <NZAddressVerificationContent jurisdiction={jurisdictionName} />
                ) : (
                    <AUAddressVerificationContent jurisdiction={jurisdictionName} />
                )}

                <FileUpload files={files} onChange={onChange} id="address-verification-upload" />
                <div className={spacing.spaceAbove16} />
                <ErrorBox message={error} />
                {!fromTransfers && jurisdiction !== 'au' && (
                    <p>
                        {jurisdiction === 'nz'
                            ? 'If you like, you can skip this for now—you’ll just need to get this to us within 7 days.'
                            : 'Don’t have one with you right now? No worries, you can skip this for now–you’ll just need to get this to us within 7 days.'}
                    </p>
                )}
                {jurisdiction === 'au' && !hideSkipButton && (
                    <p>
                        Don’t have one with you at the moment? No worries, you can skip this step for now and finish
                        signing up. When you’ve got one handy, swing back and upload when you’re ready. You won’t be
                        able to invest until you provide this.
                    </p>
                )}
                {inSignUp ? (
                    <PageButtonGroup>
                        {!hideSkipButton && (
                            <Button
                                dataTestId="button--skip-proof-of-address"
                                label="Skip this for now"
                                type="secondary"
                                onClick={onSkip}
                            />
                        )}
                        <Button
                            dataTestId="button--next"
                            isSubmit
                            label="Next"
                            processing={processing}
                            disabled={files.length === 0}
                        />
                    </PageButtonGroup>
                ) : (
                    <PageButtonGroup>
                        {fromEditAddress && (
                            <Button
                                label="Skip this for now"
                                additionalClassName={spacing.spaceBelow16}
                                onClick={onSkip}
                                type="secondary"
                                dataTestId="button--skip-verification"
                            />
                        )}
                        <Button
                            isSubmit
                            label="Upload"
                            additionalClassName={spacing.spaceBelow48}
                            processing={processing}
                            disabled={files.length === 0}
                            dataTestId="button--upload-verification"
                        />
                    </PageButtonGroup>
                )}
            </form>
        </>
    )
}

export default AddressVerificationUpload

const AUAddressVerificationContent: React.FunctionComponent<AddressVerificationChildProps> = ({jurisdiction}) => {
    return (
        <>
            <p>We couldn't verify your address, so you'll need to upload ONE of the following:</p>

            <OtherAddressProofOptions jurisdiction={jurisdiction}></OtherAddressProofOptions>

            <p className={spacing.spaceBelow24}>
                The document will need to be less than 12 months old, and must be for a residential address.
            </p>
        </>
    )
}

const NZAddressVerificationContent: React.FunctionComponent<AddressVerificationChildProps> = ({jurisdiction}) => {
    return (
        <>
            <p>
                We couldn’t verify your address, so you’ll need to upload&nbsp;
                <ModalLink
                    modalTitle="Proof of address options"
                    dataTestId="modal--address-proof-options"
                    label="a document"
                    primaryButton={{label: 'Got it'}}
                >
                    <div className={styles.addressOptions}>
                        <OtherAddressProofOptions jurisdiction={jurisdiction}></OtherAddressProofOptions>
                    </div>
                </ModalLink>
                &nbsp;(or screenshot) that clearly shows:
            </p>

            <ul>
                <li>your full name</li>
                <li>your residential address</li>
                <li>the date (within the last 12 months)</li>
                <li>the organisation’s logo</li>
            </ul>

            <p className={spacing.spaceBelow24}>
                People typically use a bank statement from a registered {jurisdiction} bank as proof of address.
            </p>
        </>
    )
}

const OtherAddressProofOptions: React.FunctionComponent<AddressVerificationChildProps> = ({jurisdiction}) => {
    return (
        <ul>
            <li>A document from a registered {jurisdiction} Bank</li>
            <li>A utility bill from your power, gas, water, land-line phone, Sky TV or internet service provider</li>
            <li>An {jurisdiction} council rates letter</li>
            <li>A document from an {jurisdiction} insurance company</li>
            <li>A document issued by a government agency</li>
            <li>A rental agreement</li>
            <li>A letter from your current employer on the employer’s letterhead</li>
        </ul>
    )
}
