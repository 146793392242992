import React from 'react'
import {LoginForm, REMEMBER_EMAIL_KEY} from '~/global/widgets/login-form/LoginForm'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import VerificationCompleteOnAnotherComputer from '~/sections/user/widgets/id-verify-complete-on-desktop/CompleteOnAnotherComputer'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'
import {UnwrapThunkAction} from '~/store/types'

export const Login: React.FC<DispatchProps> = React.memo(props => {
    const showSignUpCallToAction = !!window.location.search.includes('next') // if a next param is present, we might have been deeplinked to something even if we don't have an account
    let rememberedEmail: string | undefined

    if (localStorage) {
        rememberedEmail = localStorage.getItem(REMEMBER_EMAIL_KEY) || undefined
    }

    // If the user has returned from our biometrics provider but isn't logged in
    // due to starting the third-party flow on desktop and continuing on an un-authenticated mobile device,
    // return a component that will communicate to the user that they need to continue on their desktop.
    // NOTE: We can't use standard methods of querying for parameters here due to the formatting of the
    // URL we use for determining the route to use after logging in.
    // e.g. /login?next=%{url parameters here}%
    const verificationReferenceParam = window.location.search.includes('verification_reference')
    if (verificationReferenceParam) {
        return <VerificationCompleteOnAnotherComputer isVerificationAtThreshold />
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--login" leftButton="splash" title="Log in" isInlineTitle />
            <Page>
                <LoginForm initialEmail={rememberedEmail} showSignUpCallToAction={showSignUpCallToAction} {...props} />
            </Page>
        </>
    )
})

export interface DispatchProps {
    doLogin: UnwrapThunkAction<typeof actions.Login>
}

export default connect<{}, DispatchProps, {}>(undefined, dispatch => ({
    doLogin: (...args) => dispatch(actions.Login(...args)),
}))(Login)
