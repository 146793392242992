/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum DividendDateField {
    PaymentDate = 'PaymentDate',
    ExDate = 'ExDate',
    RecordDate = 'RecordDate',
    DeclaredDate = 'DeclaredDate',
}

export function DividendDateFieldFromJSON(json: any): DividendDateField {
    return DividendDateFieldFromJSONTyped(json, false)
}

export function DividendDateFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): DividendDateField {
    return json as DividendDateField
}

export function DividendDateFieldToJSON(value?: DividendDateField | null): any {
    return value as any
}
