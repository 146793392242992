import {colour, combinedColour, CombinedColours, combinedColours} from '@design-system/colour-tokens'
import {Thumbnail} from '@design-system/thumbnail'
import {useColourMode} from '@design-system/use-colour-mode'
import {lch} from 'd3-color'
import React from 'react'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import ScrollingInstrumentTicker from '~/global/widgets/scrolling-instrument-ticker/ScrollingInstrumentTicker'
import {useAppSelector} from '~/store/hooks'
import type {Instrument} from '~/store/instrument/types'
import styles from './ScrollingTickerHeader.scss'

const ScrollingTickerHeader: React.FC<{instrument: Instrument; backgroundColour?: CombinedColours | string}> = ({
    instrument,
    backgroundColour = instrument.dominantColour || 'grey-1',
}) => {
    const colourMode = useColourMode()
    const path = useAppSelector(({instrument}) => instrument.metadata.resourcePath)

    let colourHex: string
    if (backgroundColour in combinedColours) {
        colourHex = combinedColour(backgroundColour as CombinedColours, colourMode)
    } else {
        colourHex = backgroundColour
    }

    const contrastColourName: 'black' | 'white' = lch(colourHex).l > 80 ? 'black' : 'white'
    const contrastColour = colour(contrastColourName, colourMode)

    return (
        <>
            <PageBack buttonColour={contrastColourName} />
            <div className={styles.headerWrapper} style={{backgroundColor: backgroundColour}}>
                <ScrollingInstrumentTicker
                    instrument={instrument}
                    textColour={contrastColour}
                    backgroundColour={backgroundColour}
                />
                <div className={styles.headerLogo}>
                    <Thumbnail
                        dataTestId="thumbnail--logo"
                        symbol={instrument.symbol}
                        width="66px"
                        height="66px"
                        path={instrument.logos.micro ? `${path}${instrument.logos.micro}` : undefined}
                        noBorder
                    />
                </div>
            </div>
        </>
    )
}

export default ScrollingTickerHeader
