import {Button} from '@design-system/button'
import cn from 'classnames'
import Decimal from 'decimal.js'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {PageButtonGroup} from '~/global/widgets/button-page-group/PageButtonGroup'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useAutoinvestOrders} from '~/sections/invest/sections/auto-invest/hooks/useAutoinvestOrders'
import accountingActions from '~/store/accounting/actions'
import {TopupGoalInterval} from '~/store/accounting/types'
import {useAppSelector, useAppDispatch} from '~/store/hooks'
import {MoneyGoalFormValues, frequencyMap, topUpGoalMap} from './MoneyGoalSetup'
import styles from './MoneyGoal.scss'

const calculateContribution = (value: {investGoal: string; frequency: 'weekly' | 'fortnightly' | 'monthly'}) => {
    const {investGoal, frequency} = value
    const goal = parseFloat(investGoal)
    if (frequency === 'weekly') {
        return goal / 52
    }
    if (frequency === 'fortnightly') {
        return goal / 26
    }
    if (frequency === 'monthly') {
        return goal / 12
    }
    return 0
}

const MoneyGoalResult = ({investGoal, frequency}: MoneyGoalFormValues) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const [autoinvestOrders] = useAutoinvestOrders()
    const topupGoal = useAppSelector(s => s.accounting.topupGoal)

    // Get the first of the customer's autoinvestOrders, sorted by highest amount
    const customerAutoinvestOrder = [...autoinvestOrders]
        .sort((a, b) => parseFloat(b.amount) - parseFloat(a.amount))
        .at(0)

    const formatAmountPerFrequency = () => {
        return new Decimal(
            calculateContribution({
                investGoal,
                frequency,
            }),
        )
            .toFixed(2)
            .toString()
    }

    const saveMoneyGoal = () => {
        topupGoal
            ? dispatch(
                  accountingActions.UpdateCurrentTopupGoal(
                      topupGoal.id,
                      formatAmountPerFrequency(),
                      topUpGoalMap[frequency] as TopupGoalInterval,
                      investGoal,
                      topupGoal.celebrated,
                  ),
              )
            : dispatch(
                  accountingActions.CreateNewTopupGoal(
                      formatAmountPerFrequency(),
                      topUpGoalMap[frequency] as TopupGoalInterval,
                      investGoal,
                  ),
              )
    }

    return (
        <>
            <Toolbar dataTestId="peeled-toolbar--goal" title="Nice work!" leftButton="back" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <div>
                    <p className={cn([spacing.spaceBelow16, styles.heroContent])}>
                        Your goal is <DollarValue value={calculateContribution({investGoal, frequency})} /> each{' '}
                        {frequencyMap[frequency]}.
                    </p>
                </div>
                {customerAutoinvestOrder &&
                customerAutoinvestOrder &&
                customerAutoinvestOrder.amount !== '0' &&
                customerAutoinvestOrder.state !== 'paused' ? (
                    <p className={spacing.spaceBelow16}>
                        You're already auto-investing ${customerAutoinvestOrder.amount} every{' '}
                        {customerAutoinvestOrder.displayInterval}.
                    </p>
                ) : (
                    <p className={spacing.spaceBelow16}>Perhaps an auto-invest could get you there?</p>
                )}
                <p>You can always see how your goal is tracking in Wallet {'>'} View my money goal.</p>
                <PageButtonGroup>
                    <Button
                        dataTestId="button--next"
                        label={!customerAutoinvestOrder ? 'Set-up my auto-invest' : 'Update auto-invest'}
                        onClick={() => {
                            saveMoneyGoal()
                            rudderTrack('money_goal', 'update_my_autoinvest_clicked')
                            navigate(profileUrl('auto-invest'))
                        }}
                    />
                    <Button
                        dataTestId="button--next"
                        label="View my money goal"
                        onClick={() => {
                            saveMoneyGoal()
                            navigate(profileUrl('wallet/money-goal'))
                        }}
                        type="secondary"
                    />
                </PageButtonGroup>
            </Page>
        </>
    )
}

export {MoneyGoalResult}
