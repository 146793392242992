import cn from 'classnames'
import React from 'react'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {Checkbox} from '~/global/widgets/form-controls'
import Image from '~/global/widgets/image/Image'
import Page from '~/global/widgets/page/Page'
import comeBackSoonDark from '~/sections/user/sections/sign-up/sections/post-activation/assets/images/come-back-soon-dark.svg'
import comeBackSoonLight from '~/sections/user/sections/sign-up/sections/post-activation/assets/images/come-back-soon-light.svg'
import {
    AUTermsDocumentLinks,
    AUTermsCheckboxPreamble,
    AU_TC_VERSION,
} from '~/sections/user/sections/sign-up/widgets/au-terms-and-conditions-v4/AUTermsAndConditionsV4'
import styles from './AUTerms.scss'

export {AU_TC_VERSION}

export const AUExplainer: React.FC = () => {
    return (
        <>
            <div className={styles.heroSticker}>
                <Image src={comeBackSoonLight} darkSrc={comeBackSoonDark} />
            </div>
            <Page>
                <h1 className={cn(spacing.spaceBelow16, typographyOverrides['as-h1'])}>
                    Your account has been limited
                </h1>
                <p className={spacing.spaceBelow16}>
                    On Monday 1 April 2024, we changed how the Sharesies platform operates.
                </p>
                <p className={spacing.spaceBelow16}>
                    To continue using Sharesies, you’ll need to review and accept these changes.
                </p>
                <p className={spacing.spaceBelow16}>
                    If you don’t want to accept these changes, email our Investor Care team to chat about your
                    options—including transferring or selling down your portfolio:{' '}
                    <a style={{color: '##555555'}} href="mailto:help@sharesies.com.au">
                        help@sharesies.com.au
                    </a>
                    .
                </p>
                <h2 className={cn(spacing.spaceBelow16, typographyOverrides['as-h2'])}>What’s changed?</h2>
                <p className={spacing.spaceBelow16}>
                    Sharesies Australia Limited, an Australian company governed by Australian law, became the operator
                    and custodian of the Sharesies platform as an Investor Directed Portfolio Service (IDPS).
                </p>
                <p className={spacing.spaceBelow16}>
                    Previously, Sharesies NZ Limited, an NZ company governed by NZ law, operated the platform.
                </p>
                <p className={spacing.spaceBelow16}>The way you use Sharesies hasn’t changed.</p>
                <p className={spacing.spaceBelow16}>
                    If you’d like to learn more, you’ll find all the details on the{' '}
                    <a href="https://sharesies.com.au/changes-to-the-sharesies-platform">Sharesies website</a>.
                </p>
            </Page>
        </>
    )
}

export const AUAgreement: React.FC<{tcAccepted: boolean; setTcAccepted: (tcAccepted: boolean) => void}> = ({
    tcAccepted,
    setTcAccepted,
}) => {
    return (
        <>
            <h1 className={spacing.spaceBelow16}>Accept the changes</h1>
            <p className={spacing.spaceBelow16}>
                As part of these changes, you’ll need to read and agree to the following documents:
            </p>
            <AUTermsDocumentLinks />
            <AUTermsCheckboxPreamble />
            <Checkbox
                name="au-terms-and-conditions"
                dataTestId="checkbox--au-terms-and-conditions"
                value={tcAccepted}
                onChange={() => setTcAccepted(!tcAccepted)}
                label="I’ve read and agree to the above."
                isTouched={true}
            />
        </>
    )
}
