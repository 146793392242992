import cn from 'classnames'
import React from 'react'
import {EsInstrumentType, ListingResponseDto} from '~/api/distill'
import {Model} from '~/api/retail/types'
//ToDo - Update this then fix the CSS
import {accessibility, spacing} from '~/global/scss/helpers'
import {useNextDividend} from '~/global/state-hooks/retail/useAnnouncedCorporateActions'
import {convertDecimalToPercentage} from '~/global/utils/convert-decimal-to-percentage/convertDecimalToPercentage'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {ChevronRight} from '~/global/widgets/OLD_icons'
import {AnnouncedCACard} from '~/global/widgets/corporate-actions/AnnouncedCACard'
import ADRDetail from '~/global/widgets/detail-modals/ADRDetail'
import DetailModal from '~/global/widgets/detail-modals/DetailModal'
import detailModalStyles from '~/global/widgets/detail-modals/DetailModal.scss'
import DividendDetail from '~/global/widgets/detail-modals/DividendDetail'
import FundChargeDetail from '~/global/widgets/detail-modals/FundChargeDetail'
import ManagementFeesDetail from '~/global/widgets/detail-modals/ManagementFeesDetail'
import MarketCapitalisationDetail from '~/global/widgets/detail-modals/MarketCapitalisationDetail'
import PERatioDetail from '~/global/widgets/detail-modals/PERatioDetail'
import TimeframeDetail from '~/global/widgets/detail-modals/TimeframeDetail'
import TransactionFeesDetail from '~/global/widgets/detail-modals/TransactionFeesDetail'
import {Loading} from '~/global/widgets/loading'
import {PercentValue, DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {WithArrowRight} from '~/sections/explore/widgets/with-arrow-right/WithArrowRight'
import labelledValuesStyles from '~/sections/invest/sections/view-instrument/LabelledValues.scss'
import KiwiSaverAdminFeesDetail from '~/sections/kiwisaver/sections/view-fund/widgets/admin-fees-detail/KiwiSaverAdminFeesDetail'
import KiwiSaverAnnualFundChargeDetail from '~/sections/kiwisaver/sections/view-fund/widgets/annual-fund-charge-modal/KiwiSaverAnnualFundChargeDetail'
import KiwiSaverTransactionFeesDetail from '~/sections/kiwisaver/sections/view-fund/widgets/transaction-fees-detail/KiwiSaverTransactionFeesDetail'
import styles from './Stats.scss'

type DetailTopic =
    | null
    | 'dividends'
    | 'transaction fees'
    | 'p/e ratio'
    | 'market cap'
    | 'fund charge'
    | 'ADR'
    | 'timeframe'
    | 'kiwisaver annual fund charge'
    | 'kiwisaver admin fees'
    | 'kiwisaver transaction fees'
    | 'management fees'
    | 'kiwisaver dividends'

export const isInstrumentDerivative = (instrument: ListingResponseDto) =>
    [EsInstrumentType.rights, EsInstrumentType.warrants, EsInstrumentType.options].includes(instrument.instrumentType)

export const isInstrumentKiwiSaverBaseFund = (instrument: ListingResponseDto) =>
    instrument.fmsFundId && tradingType(instrument) === 'managed'

export const isInstrumentKiwiSaver = (instrument: ListingResponseDto) => instrument.fmsFundId

const NoData = () => (
    <p className={labelledValuesStyles.noData}>
        No data
        <ChevronRight className={labelledValuesStyles.chevron} />
    </p>
)

const shouldShowStat = (stat: Stat, excludedStats?: Stat[]): boolean => {
    if (excludedStats === undefined || excludedStats.length === 0) {
        return true
    }

    return !excludedStats.includes(stat)
}

const Stats: React.FunctionComponent<StatsAndFeesProps> = ({
    instrument,
    jurisdiction,
    setHideFooter,
    excludedStats,
    showExpandedDividends,
}) => {
    const [detailTopic, showDetailModal] = React.useState<DetailTopic>(null)
    const handleDetailClick = (detail: DetailTopic) => {
        showDetailModal(detail)
        if (setHideFooter) {
            setHideFooter(true)
        }
    }
    const isKiwiSaverInstrument = instrument.fmsFundId

    return (
        <>
            <section
                className={cn(labelledValuesStyles.container, spacing.spaceAbove16, {
                    [styles.border]: !instrument.isAdr,
                })}
            >
                {shouldShowStat(Stat.Fees, excludedStats) && (
                    <>
                        {isKiwiSaverInstrument ? (
                            <KiwiSaverFees instrument={instrument} handleDetailClick={handleDetailClick} />
                        ) : (
                            <Fees instrument={instrument} handleDetailClick={handleDetailClick} />
                        )}
                    </>
                )}
                <StatsInfo
                    instrument={instrument}
                    handleDetailClick={handleDetailClick}
                    excludedStats={excludedStats}
                    showExpandedDividends={showExpandedDividends}
                />
            </section>

            <DetailModal
                isOpen={detailTopic != null}
                closeHandler={() => {
                    showDetailModal(null)
                    if (setHideFooter) {
                        setHideFooter(false)
                    }
                }}
            >
                <React.Suspense fallback={<Loading />}>
                    {detailTopic === 'transaction fees' && (
                        <TransactionFeesDetail instrument={instrument} jurisdiction={jurisdiction} forBaseFund />
                    )}
                    {detailTopic === 'dividends' && <DividendDetail instrument={instrument} />}
                    {detailTopic === 'p/e ratio' && <PERatioDetail />}
                    {detailTopic === 'market cap' && <MarketCapitalisationDetail />}
                    {detailTopic === 'fund charge' && <FundChargeDetail />}
                    {detailTopic === 'ADR' && <ADRDetail jurisdiction={jurisdiction} />}
                    {detailTopic === 'timeframe' && <TimeframeDetail />}
                    {detailTopic === 'kiwisaver transaction fees' && <KiwiSaverTransactionFeesDetail />}
                    {detailTopic === 'kiwisaver annual fund charge' && <KiwiSaverAnnualFundChargeDetail />}
                    {detailTopic === 'kiwisaver admin fees' && <KiwiSaverAdminFeesDetail />}
                    {detailTopic === 'management fees' && <ManagementFeesDetail jurisdiction="nz" />}
                    {detailTopic === 'kiwisaver dividends' && (
                        <DividendDetail instrument={instrument} showKiwiSaverDetail={true} />
                    )}
                </React.Suspense>
            </DetailModal>
        </>
    )
}

const ExpandedInstrumentDividends: React.FunctionComponent<{
    instrument: ListingResponseDto
    handleDetailClick(detail: DetailTopic): void
}> = ({instrument, handleDetailClick}) => {
    const nextDividend = useNextDividend(instrument)

    return (
        <>
            <div>
                <p className={cn(labelledValuesStyles.label)}>Gross dividend yield</p>
                <div className={cn(labelledValuesStyles.value)}>
                    {instrument.grossDividendYieldPercent ? (
                        <p>
                            <PercentValue value={convertDecimalToPercentage(instrument.grossDividendYieldPercent)} />
                        </p>
                    ) : (
                        <p className={labelledValuesStyles.noData}>No data</p>
                    )}
                </div>
            </div>
            <div className={cn(styles.nextDividendContainer, spacing.spaceAbove16)}>
                {nextDividend ? (
                    <AnnouncedCACard corporateAction={nextDividend} />
                ) : (
                    <div className={detailModalStyles.calcBox}>
                        <p>There are no upcoming dividends for {instrument.name}</p>
                    </div>
                )}
                <button
                    type="button"
                    className={cn(detailModalStyles.link, spacing.spaceAbove8)}
                    onClick={() => handleDetailClick('dividends')}
                >
                    <WithArrowRight>View past dividends</WithArrowRight>
                </button>
            </div>
        </>
    )
}

const StatsInfo: React.FunctionComponent<StatsProps> = ({
    instrument,
    handleDetailClick,
    excludedStats,
    showExpandedDividends,
}) => {
    const hideMainStats = isInstrumentDerivative(instrument) || isInstrumentKiwiSaverBaseFund(instrument)
    return (
        <>
            {!hideMainStats && (
                <>
                    {shouldShowStat(Stat.DividendYield, excludedStats) && !showExpandedDividends && (
                        <div>
                            <p className={labelledValuesStyles.label}>Gross dividend yield</p>
                            <button
                                className={cn(labelledValuesStyles.value, accessibility.button)}
                                onClick={() =>
                                    handleDetailClick(
                                        isInstrumentKiwiSaver(instrument) ? 'kiwisaver dividends' : 'dividends',
                                    )
                                }
                                role="link"
                                type="button"
                                data-testid="button--dividends-detail-modal"
                            >
                                {instrument.grossDividendYieldPercent ? (
                                    <p>
                                        <PercentValue
                                            value={convertDecimalToPercentage(instrument.grossDividendYieldPercent)}
                                        />
                                        <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                                    </p>
                                ) : (
                                    <NoData />
                                )}
                            </button>
                        </div>
                    )}

                    {shouldShowStat(Stat.PeRatio, excludedStats) && (
                        <div>
                            <p className={labelledValuesStyles.label}>P/E ratio</p>
                            <button
                                className={cn(labelledValuesStyles.value, accessibility.button)}
                                onClick={() => handleDetailClick('p/e ratio')}
                                role="link"
                                type="button"
                            >
                                {instrument.peRatio && Number(instrument.peRatio) !== 0 ? (
                                    <p>
                                        {instrument.peRatio}
                                        <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                                    </p>
                                ) : (
                                    <NoData />
                                )}
                            </button>
                        </div>
                    )}

                    {shouldShowStat(Stat.MarketCap, excludedStats) && (
                        <div>
                            <p className={labelledValuesStyles.label}>Market cap</p>
                            <button
                                className={cn(labelledValuesStyles.value, accessibility.button)}
                                onClick={() => handleDetailClick('market cap')}
                                role="link"
                                type="button"
                            >
                                {instrument.marketCap ? (
                                    <p>
                                        <DollarValue value={instrument.marketCap} />
                                        <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                                    </p>
                                ) : (
                                    <NoData />
                                )}
                            </button>
                        </div>
                    )}
                </>
            )}

            {showExpandedDividends && (
                <React.Suspense fallback={null}>
                    <ExpandedInstrumentDividends instrument={instrument} handleDetailClick={handleDetailClick} />
                </React.Suspense>
            )}

            {instrument.timeHorizonMin && (
                <div>
                    <p className={labelledValuesStyles.label}>Timeframe</p>
                    <button
                        className={cn(labelledValuesStyles.value, accessibility.button)}
                        onClick={() => handleDetailClick('timeframe')}
                        role="link"
                        type="button"
                    >
                        {instrument.timeHorizonMin ? (
                            <p>
                                <span>{instrument.timeHorizonMin}+ years</span>
                                <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                            </p>
                        ) : (
                            <NoData />
                        )}
                    </button>
                </div>
            )}
        </>
    )
}

const Fees: React.FunctionComponent<FeesProps> = ({instrument, handleDetailClick}) => {
    const transactionFees = () => {
        if (tradingType(instrument) === 'managed') {
            return instrument.fixedFeeSpread ? (
                <p className={labelledValuesStyles.value}>
                    <PercentValue value={instrument.fixedFeeSpread} />
                </p>
            ) : (
                <p className={labelledValuesStyles.noData}>No data</p>
            )
        }
        if (isInstrumentDerivative(instrument)) {
            return <p className={labelledValuesStyles.value}>0.5 to 0.0%</p>
        }
        // US companies + US ETFs + NZ companies
        return <p className={labelledValuesStyles.value}>0.5 to 0.1%</p>
    }
    return (
        <>
            <div>
                <p className={labelledValuesStyles.label}>Transaction fees</p>
                <button
                    className={cn(labelledValuesStyles.value, accessibility.button)}
                    onClick={() => handleDetailClick('transaction fees')}
                    role="link"
                    type="button"
                >
                    {transactionFees()}
                    <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                </button>
            </div>

            {instrument.isAdr && (
                <div>
                    <p className={labelledValuesStyles.label}>Depositary fees</p>
                    <button
                        className={cn(labelledValuesStyles.value, accessibility.button)}
                        onClick={() => handleDetailClick('ADR')}
                        role="link"
                        type="button"
                    >
                        <p className={labelledValuesStyles.value}>$0.01 to $0.10</p>
                        <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                    </button>
                </div>
            )}
            {instrument.instrumentType !== 'equity' && !isInstrumentDerivative(instrument) && !instrument.isAdr && (
                <div>
                    <p className={labelledValuesStyles.label}>Annual fund charge</p>
                    {/* ToDo - This modal is probably not the right one for Moose stuff */}
                    <button
                        className={cn(labelledValuesStyles.value, accessibility.button)}
                        onClick={() => handleDetailClick('fund charge')}
                        role="link"
                        type="button"
                    >
                        {instrument.managementFeePercent ? (
                            <p>
                                <PercentValue value={convertDecimalToPercentage(instrument.managementFeePercent)} />
                                <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                            </p>
                        ) : (
                            <NoData />
                        )}
                    </button>
                </div>
            )}
        </>
    )
}
const KiwiSaverFees: React.FunctionComponent<FeesProps> = ({instrument: underlyingInstrument, handleDetailClick}) => {
    if (!underlyingInstrument.fmsFundId) {
        throw new Error(`KiwiSaver underlying instruments should have an FMS Fund ID`)
    }

    return (
        <>
            {!isInstrumentKiwiSaverBaseFund(underlyingInstrument) && (
                <>
                    <div>
                        <p className={labelledValuesStyles.label}>Transaction fees</p>
                        <button
                            className={cn(labelledValuesStyles.value, accessibility.button)}
                            onClick={() => handleDetailClick('kiwisaver transaction fees')}
                            role="link"
                            type="button"
                        >
                            <p className={labelledValuesStyles.value}>0.1 to 1%</p>
                            <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                        </button>
                    </div>
                    <div>
                        <p className={labelledValuesStyles.label}>Admin fee</p>
                        <button
                            className={cn(labelledValuesStyles.value, accessibility.button)}
                            onClick={() => handleDetailClick('kiwisaver admin fees')}
                            role="link"
                            type="button"
                        >
                            <p className={labelledValuesStyles.value}>0.15%</p>
                            <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                        </button>
                    </div>
                    {underlyingInstrument.managementFeePercent && (
                        <div>
                            <p className={labelledValuesStyles.label}>Management fee</p>
                            <button
                                className={cn(labelledValuesStyles.value, accessibility.button)}
                                onClick={() => handleDetailClick('management fees')}
                                role="link"
                                type="button"
                            >
                                <PercentValue
                                    value={convertDecimalToPercentage(underlyingInstrument.managementFeePercent)}
                                />
                                <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                            </button>
                        </div>
                    )}
                </>
            )}

            {/* ToDo - Think about ADR fees */}
            {isInstrumentKiwiSaverBaseFund(underlyingInstrument) && (
                <div>
                    <p className={labelledValuesStyles.label}>Annual fund charge</p>
                    <button
                        className={cn(labelledValuesStyles.value, accessibility.button)}
                        onClick={() => handleDetailClick('kiwisaver annual fund charge')}
                        role="link"
                        type="button"
                    >
                        {underlyingInstrument.managementFeePercent ? (
                            <p>
                                <PercentValue
                                    value={convertDecimalToPercentage(underlyingInstrument.managementFeePercent)}
                                />
                                <ChevronRight className={labelledValuesStyles.chevron} colour="melon-2" />
                            </p>
                        ) : (
                            <NoData />
                        )}
                    </button>
                </div>
            )}
        </>
    )
}

export enum Stat {
    Fees = 1,
    DividendYield = 2,
    PeRatio = 3,
    MarketCap = 4,
}

interface StatsAndFeesProps {
    instrument: ListingResponseDto
    jurisdiction: Model.User['jurisdiction']
    setHideFooter?: (isHidden: boolean) => void
    excludedStats?: Stat[] | undefined
    showExpandedDividends: boolean
}

interface StatsProps {
    instrument: ListingResponseDto
    handleDetailClick(detail: DetailTopic): void
    excludedStats?: Stat[] | undefined
    showExpandedDividends: boolean
}

interface FeesProps {
    instrument: ListingResponseDto
    handleDetailClick(detail: DetailTopic): void
}

export default Stats
