import {Button} from '@design-system/button'
import {Formik} from 'formik'
import React from 'react'
import {useNavigate} from 'react-router'
import {useRetailPost} from '~/api/query/retail'
import {useActor, useInvalidateActorCache} from '~/global/state-hooks/retail/useActor'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AccountVerificationRequired from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import {validate} from '~/global/widgets/form-controls'
import {Email, Phone} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'

export const ContactDetails = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const actor = useActor()
    const invalidateActorCache = useInvalidateActorCache()
    const save = useRetailPost({path: 'identity/update-details'})

    return (
        <>
            <Toolbar dataTestId="toolbar--contact-details" leftButton="back" title="Contact details" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <AccountVerificationRequired />
                <Formik
                    initialValues={{
                        email: actor.email || '',
                        phone: actor.phone || '',
                    }}
                    onSubmit={async ({email, phone}, {setSubmitting, setFieldError}) => {
                        try {
                            await save.mutateAsync({
                                email,
                                phone,
                                preferred_name: actor.preferred_name, // required, so set to existing value
                            })
                        } catch (e: any) {
                            if (e.code === 'update_email_already_exists') {
                                setFieldError('email', e.message)
                                return
                            }
                            throw e
                        } finally {
                            setSubmitting(false)
                        }

                        if (actor.email !== email) {
                            // Special toast for email change, introduced with Email Verification
                            Toast(
                                'Nice! Now hit the verification link we just emailed you to verify your email address',
                            )
                        } else {
                            // Default toast for details changed
                            Toast('Details updated')
                        }

                        invalidateActorCache()
                        navigate(profileUrl('settings/contact-details'))
                    }}
                    validate={validate.generate({
                        email: [validate.required(), validate.email()],
                        phone: [validate.required()],
                    })}
                    validateOnMount={true}
                >
                    {({handleSubmit, isSubmitting, isValid, dirty}) => (
                        <form onSubmit={handleSubmit}>
                            <Email dataTestId="user-email-input" name="email" label="Email" />
                            <Phone dataTestId="text-input--phone-number" name="phone" label="Phone number" />
                            <Button
                                isSubmit
                                pageButton
                                label="Save changes"
                                dataTestId="button--save-changes"
                                disabled={!isValid || !dirty}
                                processing={isSubmitting}
                            />
                        </form>
                    )}
                </Formik>
            </Page>
        </>
    )
}

export default ContactDetails
