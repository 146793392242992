export const defaultConfig = {
    instrumentUpdateInterval: 60 * 60 * 1000, // 1 hour
    recentInvestingActivityRefetchInterval: 60 * 60 * 1000, // 1 hour
    exchangeHoursCheckInterval: 5 * 60 * 1000, // 5 minutes
    userCheckInterval: 5 * 60 * 1000, // 5 minutes
    fxExchangeInterval: 15 * 60 * 1000, // 15 minutes
    essBlackoutPeriodRefetchInterval: 60 * 60 * 1000, // 1 hour
    serviceWorkerUpdateCheckInterval: {
        visible: 1000 * 10, // 10 seconds
        invisible: 1000 * 20, // 20 seconds
    },
    googleMapsKey: 'AIzaSyCPzooVt-S2jlG8--D8X5Phzu6vF9JuGRY',
    stripeNzKey: 'pk_test_Ks9dXHLHTNdRwbfTC6pNeeLa',
    stripeAuKey:
        'pk_test_51I9bq2Czs9yFRRUN4d2kKqfosYbPT3nZYL6ktRzKuYjGTHYp3GENxhm1ANNlCgtxokfVU8BE8Zhsa1Iu39UQ6ccw00K9XjL5Oc',
    rollbarToken: '',
    rollbarEnvironment: 'development',
    intercomNZAppID: 'cq2x3ulh',
    intercomAUAppID: 'ty72dm34',
    GA4Token: 'G-Y8N5L6QCTM',
    NZTimeZone: 'Pacific/Auckland',
    distillPath: '/distill', // proxied in webpack config to https://data.sharesies.nz
    rakaiaPath: '/rakaia', // proxied in webpack config to http://localhost:9009/
    telescopePath: '/telescope', // proxied in webpack config to https://telescope-staging.sharesies.com
    sharesightBaseUrl: 'https://edge-api.sharesight.com/oauth2/authorize',
    sharesightPortfolioUrl: 'https://edge-portfolio.sharesight.com/portfolios',
    facebookPixelIdNZ: '',
    facebookPixelIdAU: '',
    brazeBaseUrl: 'sdk.iad-05.braze.com',
    brazeApiKey: '8a944d89-69d1-4d89-aefa-c1725f8943f3',
    rudderstackWriteKey: '21vVLTGpLKEcDvDBKJXukOgcYIM', // Test key
    rudderstackDataPlaneURL: 'https://sharesies-dataplane.rudderstack.com',
    rudderstackConfigUrl: 'https://api.rudderlabs.com',
    growthBookUrl: 'https://cdn.growthbook.io/api/features/key_deve_1b375f0ecec136d6',
    contentfulSpaceId: 'eg3voq9njjf7',
    contentfulEnvironment: 'master',
    // Contentful preview keys for accessing draft content
    contentfulAccessToken: 'V8mzSLOOgK1C16OwScf_tNRRctlZtmC4JTK958fMs_w',
    contentfulHost: 'preview.contentful.com',
    growthBookEnableDevMode: true,
    redditAdAccountIdNZ: '',
    coveApiBaseUrl: 'https://api-nonprod.coveinsurance.co.nz/jungle',
    coveEmbedBaseUrl: 'https://app-jungle.coveinsurance.co.nz',
}
export const stagingConfig: typeof defaultConfig = {
    ...defaultConfig,
    rollbarToken: 'ed9a49ce67fe465b98c74a1708e739a2',
    rollbarEnvironment: 'staging',
    distillPath: 'https://data.sharesies.nz',
    rakaiaPath: 'https://portfolio-staging.sharesies.nz',
    telescopePath: 'https://telescope-staging.sharesies.com',
    rudderstackDataPlaneURL: 'https://rs.sharesies.com',
    rudderstackConfigUrl: 'https://rs.sharesies.com',
}

export const uatConfig: typeof defaultConfig = {
    ...stagingConfig,
    rollbarEnvironment: 'uat',
    rakaiaPath: '/rakaia-api',
    growthBookUrl: 'https://cdn.growthbook.io/api/features/key_uat_36ae10ccec5154c3',
}

export const productionConfig: typeof defaultConfig = {
    ...defaultConfig,
    stripeNzKey: 'pk_live_82iDHjNh45slgGzL7CyHwrHP',
    stripeAuKey:
        'pk_live_51I9bq2Czs9yFRRUNAiT8c61IO8I6THwLQbUNHFwsvYDh78Fc1qABoEdXLsrwCF8TjPC7StOEd2THfDv2S0dI5q6B000mi51G0M',
    rollbarToken: 'ed9a49ce67fe465b98c74a1708e739a2',
    rollbarEnvironment: 'production',
    intercomNZAppID: 'n1goyeo2',
    intercomAUAppID: 'nkq6sy2c',
    GA4Token: 'G-TT1S4W5EWQ',
    serviceWorkerUpdateCheckInterval: {
        visible: 10 * 60 * 1000, // 10 minutes
        invisible: 60 * 60 * 1000, // 1 hour
    },
    distillPath: 'https://data.sharesies.nz',
    telescopePath: 'https://telescope.sharesies.com',
    facebookPixelIdNZ: '1442682735793758',
    facebookPixelIdAU: '2484139198546837',
    rakaiaPath: 'https://portfolio.sharesies.nz',
    sharesightBaseUrl: 'https://api.sharesight.com/oauth2/authorize',
    sharesightPortfolioUrl: 'https://portfolio.sharesight.com/portfolios',
    brazeApiKey: 'dec3b92c-8d79-432b-9ff2-d2743d0b8014',
    rudderstackWriteKey: '26RJB2tgq04F90dDrn4cqDgKHn5',
    rudderstackDataPlaneURL: 'https://rs.sharesies.com',
    rudderstackConfigUrl: 'https://rs.sharesies.com',
    growthBookUrl: 'https://cdn.growthbook.io/api/features/key_prod_6ace3c178a54a8a3',
    contentfulAccessToken: 'BKLKcJAKTeD6qioYeUBV_n9mwfpT1RbN1WWlQeaYvek',
    contentfulHost: 'cdn.contentful.com',
    growthBookEnableDevMode: false,
    redditAdAccountIdNZ: 't2_37csa6l',
    coveApiBaseUrl: 'https://api.coveinsurance.co.nz/prod',
    coveEmbedBaseUrl: 'https://app.coveinsurance.co.nz',
}

export const wrapperAppIOSProductionConfig: typeof defaultConfig = {
    ...productionConfig,
    GA4Token: 'G-YE22XDZKVE', // Using GA4 MeasurementID for Sharesies MAIN - Sharesies iOS wrapper
}

export const wrapperAppAndroidProductionConfig: typeof defaultConfig = {
    ...productionConfig,
    GA4Token: 'G-CET3LSB7HE', // Using GA4 MeasurementID for Sharesies MAIN - Sharesies Android wrapper
}
