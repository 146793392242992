import {assertNever} from '~/global/utils/assert-never/assertNever'
import {ActionsType} from './actions'
import {State} from './types'

export const initialState: State = {
    sellOrderAcceptableDPLoadingState: 'ready',
    applicationsByInstrumentLoadingState: 'ready',
    applicationsByInstrument: {},
}

function reducer(state: State = initialState, action: ActionsType): State {
    switch (action.type) {
        case 'order.InitialiseStagedBuyOrder':
            return {
                ...state,
                stagedBuyOrder: {
                    fundId: action.payload.fundId,
                    state: 'initialised',
                    pushedHistory: false,
                    needReadPDS: undefined,
                    orderType: action.payload.buyOrderType,
                    autoExercise: action.payload.autoExercise,
                },
            }
        case 'order.ClearStagedBuyOrder':
            return {
                ...state,
                stagedBuyOrder: undefined,
            }
        case 'order.UpdateStagedBuyOrder':
            return {...state, stagedBuyOrder: {...action.payload}}
        case 'order.UpdateBuyPushedHistory':
            if (!state.stagedBuyOrder) {
                return state
            }
            return {...state, stagedBuyOrder: {...state.stagedBuyOrder, pushedHistory: true}}
        case 'order.SetSellOrderAcceptableDPLoadingState':
            return {
                ...state,
                sellOrderAcceptableDPLoadingState: action.payload,
            }
        case 'order.SetSellOrderAcceptableDP':
            return {
                ...state,
                sellOrderAcceptableDP: action.payload,
            }
        case 'order.InitialiseStagedSellOrder':
            return {
                ...state,
                stagedSellOrder: {
                    fundId: action.payload.fundId,
                    state: 'initialised',
                    orderType: action.payload.sellOrderType,
                    ksFundHolding: action.payload.ksFundHolding,
                },
            }
        case 'order.ClearStagedSellOrder':
            return {
                ...state,
                stagedSellOrder: undefined,
            }
        case 'order.UpdateStagedSellOrder':
            return {...state, stagedSellOrder: {...action.payload}}
        case 'order.SetUpdateApplicationsLoadingState':
            return {
                ...state,
                applicationsByInstrumentLoadingState: action.payload.loadingState,
            }
        case 'order.UpdateApplications':
            return {
                ...state,
                applicationsByInstrumentLoadingState: 'ready',
                applicationsByInstrument: {
                    ...state.applicationsByInstrument,
                    [action.payload.instrumentId]: {
                        applications: [...action.payload.applications],
                        recentApplications: [...action.payload.recentApplications],
                        alert: action.payload.alert,
                        introHeaderHTML: action.payload.introHeaderHTML,
                        introFooterHTML: action.payload.introFooterHTML,
                        offerDetailsHTML: action.payload.offerDetailsHTML,
                    },
                },
                stagedApplication: state.stagedApplication && {
                    ...state.stagedApplication,
                    applicationRule:
                        action.payload.applications.find(
                            a => a.application_rule_id === state.stagedApplication!.applicationRule.application_rule_id,
                        ) || state.stagedApplication.applicationRule,
                },
            }
        case 'order.InitialiseStagedApplication':
            return {
                ...state,
                stagedApplication: {
                    fundId: action.payload.fund_id,
                    applicationRule: action.payload,
                    answers: action.payload.question_blocks.reduce(
                        (iv, block) =>
                            'name' in block
                                ? {...iv, [block.name]: block.type === 'checkbox_input' ? false : undefined}
                                : iv,
                        {},
                    ),
                    state: 'initialised',
                },
            }
        case 'order.UpdatePushedFromOtherApplication':
            if (!state.stagedApplication) {
                return state
            }
            return {...state, stagedApplication: {...state.stagedApplication, pushedFromOtherApplication: true}}
        case 'order.ClearStagedApplication':
            return {...state, stagedApplication: undefined}
        case 'order.UpdateStagedApplication':
            return {...state, stagedApplication: {...action.payload}}
        default:
            assertNever(action)
    }
    return state
}

export default reducer
