import {rakaiaGetFactory} from '~/api/query/rakaia'
import {AppSchemasForClientPortfolioPortfolio, Currency} from '~/api/rakaia'

/**
 * This is a duplicate of useRakaiaReadPortfolio in kiwisaver/data/rakaia.ts
 * They will likely be consolidated at some point in the future, but making a seperate copy
 * for now as it will likely be extended with specific error handling logic that I don't want
 * to automatically apply to the kiwisaver context
 */
export const useReadPortfolio = (portfolioUuid: string) => {
    const {data} = rakaiaGetFactory({
        apiFunctionName: 'readPortfolioApiV1PortfoliosPortfolioUuidGet',
        notFoundResponse: ({portfolioUuid}): AppSchemasForClientPortfolioPortfolio => ({
            uuid: portfolioUuid,
            currency: Currency.NZD,
            simple_return: 0,
            portfolio_value: 0,
            total_return: 0,
            portfolio_history: undefined,
        }),
    })({portfolioUuid})
    return data
}
