import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {Model} from '~/api/retail/types'
import {UNLISTED_INSTRUMENTS_LABEL} from '~/global/constants/categoryAndSearchLabels'
import {accessibility, spacing} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Chips from '~/global/widgets/chips/Chips'
import CompoundSlider from '~/global/widgets/compound-slider/CompoundSlider'
import {SwitchField} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {useNavigate} from '~/migrate-react-router'
import {connect} from '~/store/connect'
import actions from '~/store/instrument/actions'
import {State, Exchanges, Categories, InstrumentTypes} from '~/store/instrument/types'
import {UnwrapThunkAction} from '~/store/types'
import styles from './Filter.scss'

const logFilterEvents = (filters: State['currentSearchFilters']) => {
    filters.instrumentTypes.forEach(type => {
        logSearchFilterEvent('Type', type)
    })
    filters.categories.forEach(type => {
        logSearchFilterEvent('Category', type)
    })

    const allExchanges = filters.unlistedInstruments ? filters.exchanges.concat(['unlisted']) : filters.exchanges
    allExchanges.forEach(type => {
        logSearchFilterEvent('Exchange', type)
    })
    if (filters.riskLevel) {
        logSearchFilterEvent('Risk', `${filters.riskLevel[0]} - ${filters.riskLevel[1]}`)
    }
}

const logSearchFilterEvent = (filterType: string, filterValue: string) => {
    Analytics.event({
        category: 'Search',
        action: `Applied advanced Invest filter - ${filterType} `,
        label: filterValue,
    })
}

const Filter: React.FunctionComponent<FilterProps> = ({
    searchInitialisedFor,
    metadataInitialised,
    currentFilters,
    categories,
    instrumentTypeValuesByLabel,
    exchanges,
    loadSearch,
    initialiseResults,
    updateFilters,
    getPotentialResultsTotal,
    getPotentialAutoInvestResultsTotal,
    potentialResultsTotal,
    isDependent,
    countResultsLoadingState,
    jurisdiction,
    isAutoInvest,
    clearAllFilters,
}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    // This is the local state to hold the users selection and trigger an update
    // from the count API. The store state for the filter selection should ONLY
    // be updated when the pink button at the bottom is pressed.
    const [selectedTypes, setSelectedTypes] = React.useState<State['currentSearchFilters']['instrumentTypes']>(
        currentFilters.instrumentTypes,
    )

    const [selectedCategories, setSelectedCategories] = React.useState<State['currentSearchFilters']['categories']>(
        currentFilters.categories,
    )

    const [selectedExchanges, setSelectedExchanges] = React.useState<State['currentSearchFilters']['exchanges']>(
        currentFilters.exchanges,
    )
    const [selectedUnlistedInstruments, setSelectedUnlistedInstruments] = React.useState<
        State['currentSearchFilters']['unlistedInstruments']
    >(currentFilters.unlistedInstruments)
    const [selectedRiskLevel, setSelectedRiskLevel] = React.useState<State['currentSearchFilters']['riskLevel']>(
        currentFilters.riskLevel,
    )
    const [selectedKidsRecommended, setSelectedKidsRecommended] = React.useState<
        State['currentSearchFilters']['kidsRecommended']
    >(currentFilters.kidsRecommended)

    // Exchange labels - include exchanges other than NZX, then add NZX to the
    // list using 'unshift' so it's always at the start of the filter options
    const nzExchangeName = 'NZX'
    const exchangeLabels = exchanges
        .filter(exchange => {
            return exchange.name !== nzExchangeName
        })
        .map(exchange => exchange.name)
        .sort()
    exchangeLabels.unshift(nzExchangeName)

    if (!isAutoInvest && jurisdiction !== 'au') {
        // no managed funds for AU investors, temporarily
        exchangeLabels.push(UNLISTED_INSTRUMENTS_LABEL)
    }

    // don't show managed funds to jurisdictions other than nz
    const instrumentTypesLabels = instrumentTypeValuesByLabel
        .filter(instrumentType => {
            // Ignore managed funds if we're not in NZ
            if (instrumentType.name === 'Managed funds' && jurisdiction !== 'nz') {
                return false
            }
            // Ignore ETPs and Rights
            if (['ETPs', 'Rights', 'Warrants', 'Options', 'Unlisted Companies'].includes(instrumentType.name)) {
                return false
            }
            return true
        })
        .map(instrumentType => instrumentType.name)
        .sort()

    const notificationContext = React.useContext(NotificationContext)

    React.useEffect(() => {
        // did the user load this page directly? If so we initialise search with results relevant to the current context - /auto-invest or /invest
        const initialisedInContext =
            (isAutoInvest && searchInitialisedFor === 'auto-invest') ||
            (!isAutoInvest && searchInitialisedFor === 'invest')

        if (metadataInitialised && !initialisedInContext) {
            clearAllFilters()
            initialiseResults(isAutoInvest ? 'auto-invest' : 'invest')
        }
    }, [metadataInitialised, isAutoInvest])

    React.useEffect(() => {
        const selected = {
            categories: selectedCategories,
            exchanges: selectedExchanges,
            unlistedInstruments: selectedUnlistedInstruments,
            riskLevel: selectedRiskLevel,
            instrumentTypes: selectedTypes,
            kidsRecommended: selectedKidsRecommended,
        }
        // update the number of investments but not applied filter yet
        if (isAutoInvest) {
            getPotentialAutoInvestResultsTotal(selected, jurisdiction)
        } else {
            getPotentialResultsTotal(selected)
        }
    }, [
        selectedCategories,
        selectedExchanges,
        selectedUnlistedInstruments,
        selectedRiskLevel,
        selectedTypes,
        selectedKidsRecommended,
    ])

    React.useEffect(() => {
        if (countResultsLoadingState === 'error') {
            notificationContext.showModalError({message: unknownErrorMessage})
        }
    }, [countResultsLoadingState])

    const updateFilterArray = (label: string, array: string[]) => {
        if (label === 'all') {
            return []
        }
        return array.includes(label) ? array.filter(arrayItem => arrayItem !== label) : [...array, label]
    }

    if (!metadataInitialised) {
        return <Loading isPineapple />
    }

    return (
        <>
            <Page className={styles.filterPage}>
                <div className={styles.header}>
                    <PageBack
                        onClick={() => {
                            navigate(-1)
                        }}
                    />

                    <div className={styles.headerTitle}>
                        <h1>Filter</h1>
                        <button
                            className={cn(accessibility.button, styles.clearButton)}
                            type="button"
                            onClick={() => {
                                setSelectedTypes([])
                                setSelectedCategories([])
                                setSelectedExchanges([])
                                setSelectedUnlistedInstruments(undefined)
                                setSelectedRiskLevel(null)
                                setSelectedKidsRecommended(undefined)
                            }}
                            aria-label="Clear all filters"
                        >
                            Clear
                        </button>
                    </div>
                </div>

                <h2 className={styles.inlineChipsHeader}>Types</h2>
                <Chips
                    options={instrumentTypesLabels}
                    hasAllOption
                    isInlineDisplay
                    selected={selectedTypes}
                    onChipClick={typeLabel => {
                        setSelectedTypes(updateFilterArray(typeLabel, selectedTypes))
                    }}
                />

                <h2 className={cn(styles.categoriesTitle, spacing.spaceAbove32)}>Categories</h2>
                <Chips
                    options={categories}
                    hasAllOption
                    selected={selectedCategories}
                    onChipClick={category => {
                        setSelectedCategories(updateFilterArray(category, selectedCategories))
                    }}
                />

                <h2 className={cn(styles.inlineChipsHeader, spacing.spaceAbove32)}>Exchanges</h2>
                <Chips
                    options={exchangeLabels}
                    hasAllOption={!isAutoInvest}
                    isInlineDisplay
                    selected={
                        selectedUnlistedInstruments
                            ? selectedExchanges.concat(UNLISTED_INSTRUMENTS_LABEL)
                            : selectedExchanges
                    }
                    onChipClick={exchangeLabel => {
                        if (exchangeLabel === UNLISTED_INSTRUMENTS_LABEL) {
                            setSelectedUnlistedInstruments(selectedUnlistedInstruments ? undefined : true) // either true or not set, never false
                        } else {
                            if (exchangeLabel === 'all') {
                                setSelectedUnlistedInstruments(undefined)
                            }
                            setSelectedExchanges(updateFilterArray(exchangeLabel, selectedExchanges))
                        }
                    }}
                />

                <h2 className={cn(spacing.spaceBelow12, spacing.spaceAbove32)}>Risk levels</h2>

                <CompoundSlider
                    handleRiskRangeUpdate={(riskLevel: [number, number]) => {
                        setSelectedRiskLevel(riskLevel)
                    }}
                    currentRiskRange={selectedRiskLevel}
                    hideTitle
                />

                {isDependent && (
                    <>
                        <h2 className={styles.kidsRecommendedTitle}>Kids</h2>

                        <div className={styles.kidsRecommended}>
                            <SwitchField
                                dataTestId="switch--kids-recommended"
                                name="kids_recommended"
                                onChange={() => setSelectedKidsRecommended(!selectedKidsRecommended)}
                                value={!selectedKidsRecommended}
                                isTouched={false}
                                label="Only show investments that have tax rate options suitable for most kids."
                            />
                        </div>
                    </>
                )}
            </Page>

            <ActionBar>
                <div className={styles.footerArea}>
                    <Button
                        dataTestId="button--filter-investments"
                        label={`Show ${potentialResultsTotal} investments`}
                        onClick={() => {
                            const filters = {
                                categories: selectedCategories,
                                exchanges: selectedExchanges,
                                unlistedInstruments: selectedUnlistedInstruments,
                                riskLevel: selectedRiskLevel,
                                instrumentTypes: selectedTypes,
                                kidsRecommended: selectedKidsRecommended,
                            }
                            logFilterEvents(filters)
                            updateFilters(filters)
                            loadSearch(isAutoInvest ? 'auto-invest' : 'invest')
                            navigate(profileUrl(isAutoInvest ? 'auto-invest/diy/search' : 'invest/search'))
                        }}
                        processing={countResultsLoadingState === 'loading'}
                        disabled={countResultsLoadingState !== 'ready' || potentialResultsTotal === 0}
                    />
                </div>
            </ActionBar>
        </>
    )
}

interface StoreProps {
    searchInitialisedFor: State['searchInitialisedFor']
    metadataInitialised: boolean
    currentFilters: State['currentSearchFilters']
    categories: Categories
    instrumentTypeValuesByLabel: InstrumentTypes
    exchanges: Exchanges
    potentialResultsTotal: number
    isDependent: boolean
    countResultsLoadingState: State['countResultsLoadingState']
    jurisdiction: Model.User['jurisdiction']
}

interface DispatchProps {
    initialiseResults: UnwrapThunkAction<typeof actions.initSearchResults>
    clearAllFilters(): void
    updateFilters(filters: State['currentSearchFilters']): void
    loadSearch: UnwrapThunkAction<typeof actions.loadSearch>
    getPotentialResultsTotal(currentFilters: State['currentSearchFilters']): void
    getPotentialAutoInvestResultsTotal(
        currentFilters: State['currentSearchFilters'],
        jurisdiction: Model.User['jurisdiction'],
    ): void
}

interface OwnProps {
    isAutoInvest?: boolean
}

type FilterProps = StoreProps & DispatchProps & OwnProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    state => {
        const {identity, instrument} = state
        return {
            searchInitialisedFor: instrument.searchInitialisedFor,
            metadataInitialised: instrument.isMetadataInitialised,
            currentFilters: instrument.currentSearchFilters,
            categories: instrument.metadata.categories,
            instrumentTypeValuesByLabel: instrument.metadata.instrumentTypes,
            exchanges: instrument.metadata.exchanges,
            potentialResultsTotal: instrument.potentialResultsTotal ? instrument.potentialResultsTotal : 0,
            isDependent: state.identity.user!.is_dependent,
            countResultsLoadingState: instrument.countResultsLoadingState,
            jurisdiction: identity.user!.jurisdiction,
        }
    },
    dispatch => ({
        initialiseResults: context => dispatch(actions.initSearchResults(context)),
        clearAllFilters: () => dispatch(actions.ClearAllSearchFilters()),
        updateFilters: filters => dispatch(actions.UpdateSearchFilters(filters)),
        loadSearch: context => dispatch(actions.loadSearch(context)),
        getPotentialResultsTotal: currentFilters => dispatch(actions.getPotentialResultsTotal(currentFilters)),
        getPotentialAutoInvestResultsTotal: currentFilters =>
            dispatch(actions.getPotentialAutoInvestResultsTotal(currentFilters)),
    }),
)(Filter)
