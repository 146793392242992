/**
 *
 * Simple helpers for determining whether a given loading state is loading, uninitialised, error, or a mix.
 *
 */

export const isLoading = (state: string) => {
    return state === 'loading'
}

export const isAnyLoading = (states: string[]) => {
    return states.some(isLoading)
}

export const isUninitialised = (state: string) => {
    return state === 'uninitialised'
}

export const isLoadingOrUninitialised = (state: string) => {
    return !!['loading', 'uninitialised'].find(s => s === state)
}

export const isAnyLoadingOrUninitialised = (states: string[]) => {
    return states.some(isLoadingOrUninitialised)
}

export const isError = (state: string) => {
    return state === 'error'
}

export const isAnyError = (states: string[]) => {
    return states.some(isError)
}
