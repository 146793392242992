import {Modal} from '@design-system/modal'
import React from 'react'
import {Model} from '~/api/retail/types'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useNavigate} from '~/migrate-react-router'

interface AddressVerificationRequiredModalProps {
    isOpen: boolean
    reason: Model.User['address_reject_reason']
    accountRestricted: Model.User['account_restricted']
    daysLeft: number
    onClose: () => void
}

interface NumberToOrdinal {
    [n: number]: string
}

const numberToWritten: NumberToOrdinal = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
    9: 'nine',
    10: 'ten',
    11: '11',
    12: '12',
    13: '13',
    14: '14',
    15: '15',
    16: '16',
    17: '17',
    18: '18',
    19: '19',
    20: '20',
    21: '21',
    22: '22',
    23: '23',
    24: '24',
    25: '25',
    26: '26',
    27: '27',
    28: '28',
    29: '29',
    30: '30',
}

const getReason = (reason: Model.User['address_reject_reason'], accountRestricted: boolean, daysLeft: number) => {
    if (!reason) {
        return ''
    }

    let startBit = `You have ${numberToWritten[daysLeft]} day${daysLeft > 1 ? 's' : ''} to`

    let docString = 'another document'

    if (reason === 'unreadable') {
        docString = 'your document again'
    }

    let continueText = 'continue to'

    if (accountRestricted) {
        startBit = 'You need to'
        continueText = ''
    }

    const endBit = `${startBit} upload ${docString} so you can ${continueText} buy, sell and withdraw.`

    switch (reason) {
        case 'unreadable':
            return `The document you uploaded wasn't readable. ${endBit}`
        case 'old':
            return `The document you uploaded was too old. ${endBit}`
        case 'organisation':
            return `The document you uploaded wasn't from an accepted organisation. ${endBit}`
        case 'not-residential':
            return `The document you uploaded wasn't for a residential address. ${endBit}`
        case 'missing-date':
            return `The document you uploaded doesn't show a date. ${endBit}`
        case 'wrong-name':
            return `The name on the document you uploaded doesn't match the name you signed up with. ${endBit}`
        case 'no-name':
            return `The document you uploaded doesn't show the name you used when you signed up to Sharesies. ${endBit}`
        case 'no-address':
            return `The document you uploaded doesn't show your address. ${endBit}`
        case 'envelope':
            return `We aren't able to accept an unopened envelope as proof of your address.`
        case 'operator removed address verification':
            // This shouldn't happen
            throw new Error(`AddressVerificationModal reason should not exist in FE: ${reason}`)
        case 'other':
            return `The document you uploaded could not be accepted. Please contact us. ${endBit}`
        default:
            assertNever(reason)
    }
}

export const AddressVerificationRequiredModalContent: React.FunctionComponent<{
    reason: AddressVerificationRequiredModalProps['reason']
    accountRestricted: boolean
    daysLeft: number
}> = ({reason, accountRestricted, daysLeft}) => {
    return (
        <>
            {reason ? (
                <p>{getReason(reason, accountRestricted, daysLeft)}</p>
            ) : (
                <p>You need to upload a document now so you can continue to buy, sell and withdraw.</p>
            )}
        </>
    )
}

const AddressVerificationRequiredModal = ({
    isOpen,
    reason,
    onClose,
    accountRestricted,
    daysLeft,
}: AddressVerificationRequiredModalProps) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={_ => {
                // The `_` argument will be called as false, but we don't need to use it in this case
                // Normally we'd just be passing in setModalOpen (setIsOpen={setModalOpen}), but we need to run a
                // side effect `onClose` rather than setting `isOpen(false)`
                onClose()
            }}
            title={reason ? 'We’re gonna need you to upload your address document again' : 'Verify your address'}
            dataTestId="modal--address-verification-required"
            primaryButton={{
                label: 'Upload proof of address',
                onClick: () => {
                    navigate(profileUrl('settings/address-verification'))
                    onClose()
                },
            }}
            secondaryButton={{label: 'Skip this for now'}}
        >
            <AddressVerificationRequiredModalContent
                reason={reason}
                accountRestricted={accountRestricted}
                daysLeft={daysLeft}
            />
        </Modal>
    )
}

export default AddressVerificationRequiredModal
