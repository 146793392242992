import {ChevronRight} from '@design-system/icon'
import {Thumbnail} from '@design-system/thumbnail'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import * as api from '~/api/retail'
import {Response} from '~/api/retail/types'
import {spacing, accessibility} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ErrorBox} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'
import {Instrument} from '~/store/instrument/types'
import styles from './ExploreTopTen.scss'

interface InstrumentListProps {
    instruments: Instrument[]
}

const InstrumentList: React.FunctionComponent<InstrumentListProps> = ({instruments}) => {
    const navigate = useNavigate()
    const path = useAppSelector(({instrument}) => instrument.metadata.resourcePath)
    const profileUrl = useProfileUrl()

    return (
        <ol data-testid="explore-top-ten--list" className={styles.topTenInstrumentList}>
            {instruments.map((instrument, key) => {
                const url = profileUrl('invest/search/:instrumentSlug', {
                    instrumentSlug: instrument.urlSlug,
                })
                const imgPath = instrument.logos.micro ? `${path}${instrument.logos.micro}` : undefined
                return (
                    <li key={key} data-testid={`explore-top-ten--list-item-${key + 1}`}>
                        <button
                            type="button"
                            onClick={() => navigate(url)}
                            className={cn(styles.topTenInstrument, accessibility.button)}
                        >
                            <div className={styles.thumbnailWrapper}>
                                <Thumbnail
                                    dataTestId={`${instrument.urlSlug}--logo`}
                                    width="28px"
                                    height="28px"
                                    symbol={instrument.symbol}
                                    path={imgPath}
                                    noBorder
                                />
                            </div>
                            <div>
                                <h2>{instrument.name}</h2>
                                <p>
                                    {instrument.symbol}{' '}
                                    {instrument.exchange && (
                                        <>
                                            <span>|</span> {instrument.exchange}
                                        </>
                                    )}
                                </p>
                            </div>
                            <ChevronRight fill="rgb(60, 60, 67, 0.3)" />
                        </button>
                    </li>
                )
            })}
        </ol>
    )
}

const NZDisclaimer: React.FunctionComponent = () => {
    return (
        <p>
            Investing involves risk. The data presented reflects the count of distinct NZ-based Sharesies investors who
            executed one or more purchases on Sharesies (excluding KiwiSaver) within the specified period. It does not
            account for the total volume or value of transactions, nor subsequent sell orders. Please note that this
            data may be impacted by ad hoc activity such as Staff Share distributions and automated dividend
            reinvestment via Sharesies. Sharesies does not provide financial advice or recommendations. If you are
            unsure, you should speak with a licensed financial adviser.
        </p>
    )
}

const AUDisclaimer: React.FunctionComponent = () => {
    return (
        <p>
            Investing involves risk. This information is general in nature and based on factual data of the number of
            orders placed by Australian-based Sharesies investors on the Sharesies platform during the specified week.
            It does not consider the aggregate value of orders or subsequent sales. Any mention of a financial product
            does not constitute an offer or recommendation to invest in that product. It is important to read the
            relevant disclosure documents before investing in a financial product. This is not financial advice and if
            you require advice specific to your circumstances, you should speak with a licensed financial adviser.
        </p>
    )
}

interface ExploreTopTenPageProps {
    loadError: boolean
    topTen: Response.ExploreTopTenFundsLastWeekData
    instruments: Instrument[]
}

export const ExploreTopTenPage: React.FunctionComponent<ExploreTopTenPageProps> = ({
    loadError,
    topTen,
    instruments,
}) => {
    const {jurisdiction} = topTen
    const start = topTen.start.toFormat(dateFormatNoTime)
    const end = topTen.end.toFormat(dateFormatNoTime)

    return (
        <div data-testid="page--explore-top-ten">
            <Toolbar dataTestId="toolbar--explore-landing" leftButton="back" />
            <Page>
                <h1>Top 10 buys last week</h1>
                <p data-testid="explore-top-ten--description" className={spacing.spaceAbove16}>
                    Discover the top 10 investments on Sharesies for the last week ({start} to {end}), determined by the
                    number of unique investors.
                </p>
                {loadError ? (
                    <ErrorBox className={spacing.spaceAbove24} message="Could not load top ten at this time" />
                ) : (
                    <InstrumentList instruments={instruments} />
                )}
                {jurisdiction === 'nz' ? <NZDisclaimer /> : <AUDisclaimer />}
            </Page>
        </div>
    )
}

const ExploreTopTen: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const [topTen, setTopTen] = React.useState<Response.ExploreTopTenFundsLastWeekData | null>(null)
    const [loadError, setLoadError] = React.useState<boolean>(false)
    const {instrumentsById, resultsLoadingState} = useAppSelector(s => s.instrument)

    const [instruments, setInstruments] = React.useState<Instrument[]>([])

    React.useEffect(() => {
        const fetchData = async () => {
            const data = await api.get('explore/weekly-top-ten-funds')
            return data
        }

        fetchData().then(response => {
            switch (response.type) {
                case 'error':
                case 'internal_server_error':
                    setLoadError(true)
                    return

                case 'top_ten_funds_last_week':
                    setTopTen(response)
                    dispatch(instrumentActions.getInstrumentsByIds(response.fund_ids))
                    return

                default:
                    assertNever(response)
            }
        })
    }, [])

    React.useEffect(() => {
        if (topTen && resultsLoadingState === 'ready') {
            const instruments: Instrument[] = []
            topTen!.fund_ids.forEach(instrumentId => {
                if (instrumentId in instrumentsById) {
                    instruments.push(instrumentsById[instrumentId])
                }
            })
            setInstruments(instruments)
        }
    }, [resultsLoadingState])

    if (topTen === null || resultsLoadingState === 'loading') {
        // Because we may need to load
        return <Loading />
    }

    return (
        <ExploreTopTenPage
            loadError={loadError || resultsLoadingState === 'error'}
            instruments={instruments}
            topTen={topTen}
        />
    )
}

export default ExploreTopTen
