import {useQuery} from '@tanstack/react-query'
import {distillApiNewClientToken, ExchangeDto, InstrumentsInfoResponseDto} from '~/api/distill'
import {DistillScope} from '~/api/query/distill'
import useDistillInstrumentInfo from '~/global/state-hooks/distill/useDistillInstrumentInfo'
import {Currency} from '~/global/utils/currency-details/currencyDetails'
import {DetailInstrument, Instrument} from '~/store/instrument/types'

const ONE_MINUTE_IN_MS = 60 * 1000
const TWO_MINUTES_IN_MS = 2 * 60 * 1000

const DEFAULT_CACHE_STALE_OPTION = {
    cacheTime: TWO_MINUTES_IN_MS,
    staleTime: ONE_MINUTE_IN_MS,
}

/**
 * Get a single instrument from the distill api
 *
 * This API should be refactored to use distillGetFactory and use a shared cache with other instrument distill hooks, similar to
 * `src/sections/wallet/state/distill.ts` - please talk to Staff+ before implementing further uses
 * of this hook.
 */
export const useDistillInstrumentBySlug = (instrumentUrlSlug: string, scope: DistillScope): DetailInstrument => {
    const {data} = useQuery({
        suspense: true,
        ...DEFAULT_CACHE_STALE_OPTION,
        queryKey: ['useDistillInstrumentBySlug', instrumentUrlSlug, scope],
        queryFn: async () => {
            const response =
                await distillApiNewClientToken.instrumentsApiNewClientToken.apiV1InstrumentsGetbyurlslugV2UrlSlugGet({
                    scope,
                    urlSlug: instrumentUrlSlug,
                })

            if (response === undefined) {
                throw new Error(`Distill instrument with URL slug ${instrumentUrlSlug} was not found in Distill`)
            }

            return response
        },
    })

    return data!
}

/**
 * Get a single instrument from the distill api and meld currency into it
 *
 * This API should be refactored to use distillGetFactory and use a shared cache with other instrument distill hooks, similar to
 * `src/sections/wallet/state/distill.ts` - please talk to Staff+ before implementing further uses
 * of this hook.
 */
export const useDistillInstrumentWithCurrency = (instrumentSlug: string, scope: DistillScope) => {
    const instrument: DetailInstrument = useDistillInstrumentBySlug(instrumentSlug, scope)
    const instrumentMetadata: InstrumentsInfoResponseDto = useDistillInstrumentInfo(scope)

    const exchangeInfo = instrumentMetadata.exchanges.find(
        (exchange: ExchangeDto) => instrument.exchange === exchange.name,
    )
    const currency = exchangeInfo?.currency ?? 'nzd'
    const instrumentWithCurrency: Instrument = {
        currency: currency as Currency,
        ...instrument,
    }
    return instrumentWithCurrency
}
