import {Button} from '@design-system/button'
import classNames from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {Image} from '~/global/widgets/image/Image'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import iconDark from '~/sections/invest/sections/dividend-reinvestment-plans/assets/images/card-icon-dark.svg'
import iconLight from '~/sections/invest/sections/dividend-reinvestment-plans/assets/images/card-icon-light.svg'
import styles from './DividendReinvestmentLearn.scss'

interface DividendReinvestmentLearnProps {
    instrumentSlug?: string
}

const DividendReinvestmentLearn: React.FunctionComponent<DividendReinvestmentLearnProps> = ({instrumentSlug}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    return (
        <>
            <div className={styles.bigIconHeader}>
                <div>
                    <Toolbar dataTestId="toolbar--big-icon-header" leftButton="back" />
                </div>
                <div className={styles.headerImage}>
                    <Image src={iconLight} darkSrc={iconDark} width={90} height={90} />
                </div>
            </div>
            <Page overrideDefaultTopPadding="none">
                <h1 className={classNames(styles.header, spacing.spaceAbove24, spacing.spaceBelow16)}>
                    Dividend reinvestment plans
                </h1>
                <p>
                    Some listed companies and funds offer a dividend reinvestment plan (DRP or DRIP) to let shareholders
                    automatically reinvest their dividends back into their investment.
                </p>
                <p className={spacing.spaceAbove24}>
                    In simple terms, this means you get more shares (or part of a share) in the investment, rather than
                    a cash payment.
                </p>
                <div className={spacing.spaceAbove24}>
                    <h3 className={styles.subHeader}>No Sharesies transaction fees</h3>
                    <p>
                        You don’t pay a Sharesies transaction fee when you receive shares through a DRP, as shares come
                        directly from the company or fund, instead of through an exchange.
                    </p>
                </div>
                <div className={spacing.spaceAbove24}>
                    <h3 className={styles.subHeader}>Grow your investment</h3>
                    <p>
                        DRPs are simple to set up, and automate the reinvestment process. So if you’re someone who often
                        forgets to add to your investments, a DRP can be a way to invest regularly.
                    </p>
                </div>
                <div className={spacing.spaceAbove24}>
                    <h3 className={styles.subHeader}>Opt in or out at any time</h3>
                    <p>
                        If you own shares in a company that offers a DRP, you can opt in to (or out of) a DRP at any
                        time. To change the way you receive an upcoming dividend, you’ll have to opt in or out before
                        its ex-dividend date.
                    </p>
                </div>
                <p className={spacing.spaceAbove24}>
                    Whether to opt in to a DRP or not is up to you and will depend on many factors. Consider speaking to
                    a financial advisor if you are unsure.
                </p>
                <p className={classNames(spacing.spaceAbove24, spacing.spaceBelow24)}>
                    For more info{' '}
                    <HelpCentreLink auArticle="7036960-reinvest-dividends" nzArticle="3146319-reinvest-dividends">
                        check out our help centre
                    </HelpCentreLink>
                    .
                </p>
                {instrumentSlug && (
                    <Button
                        dataTestId=""
                        label="Opt in"
                        onClick={() =>
                            navigate(
                                profileUrl('invest/dividend-reinvestment-confirm/:instrumentSlug', {
                                    instrumentSlug,
                                }),
                            )
                        }
                    />
                )}
            </Page>
        </>
    )
}

export default DividendReinvestmentLearn
