import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import FarmerPrivacyPolicy from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/FarmerPrivacyPolicy'
import PrivacyPolicy from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/PrivacyPolicy'

const LegalPrivacyPolicy: React.FunctionComponent<{}> = () => {
    const owner = useProfileOwner()
    return (
        <>
            <Toolbar dataTestId="toolbar--legal-privacy-policy" leftButton="back" title="Legal" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <h2 className={spacing.spaceBelow16}>Privacy Policy</h2>
                {owner.is_fonterra_customer ? <FarmerPrivacyPolicy /> : <PrivacyPolicy />}
            </Page>
        </>
    )
}

export default LegalPrivacyPolicy
