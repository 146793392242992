import {Modal} from '@design-system/modal'
import React from 'react'
import config from '~/configForEnv'
import {spacing} from '~/global/scss/helpers'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {Toast} from '~/global/widgets/toast/Toast'
import openExternal from '~/sections/user/sections/settings/sections/portfolio/pages/sharesight/assets/images/open-external.svg'
import {useAppDispatch} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import {SharesightIntegrations} from '~/store/identity/types'
import styles from './Sharesight.scss'

const LOCAL_STORAGE_KEY = 'sharesightAlertFlags'
interface SharesightActiveIntegrationProps {
    isDependent: boolean | undefined
    portfolioId: string
    preferredName: string | undefined
    sharesightIntegrationLoadingState: string
    sharesightPortfolioLoadingState: string
    sharesightIntegrationsForPortfolio: SharesightIntegrations[0]
}

const sharesightHelp = (
    <span>
        Check out the{' '}
        <a target="_blank" rel="noopener noreferrer" href="https://help.sharesight.com/nz/">
            Sharesight help centre
        </a>{' '}
        for more options.
    </span>
)

const SharesightActiveIntegrations: React.FunctionComponent<SharesightActiveIntegrationProps> = ({
    isDependent,
    portfolioId,
    preferredName,
    sharesightIntegrationLoadingState,
    sharesightPortfolioLoadingState,
    sharesightIntegrationsForPortfolio,
}) => {
    const [sharesightAlertFlags, setAlertFlags] = React.useState({
        overPortfolioRecordLimit: false, // currently 7000
        oneOver1000: false,
        moreOver1000: false,
    })
    const [disconnectModalIsOpen, setDisconnectModalIsOpen] = React.useState(false)

    const dispatch = useAppDispatch()

    React.useEffect(() => {
        const alertFlags = window.localStorage.getItem(LOCAL_STORAGE_KEY)

        if (alertFlags) {
            setAlertFlags(JSON.parse(alertFlags))
        }
    }, [])

    const updateAlertFlags = (key: string) => {
        const alertFlags = {
            ...sharesightAlertFlags,
            [key]: true,
        }

        setAlertFlags(alertFlags)

        window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(alertFlags))
    }

    const disconnectSharesight = async (sharesightPortfolioId: number | undefined) => {
        setDisconnectModalIsOpen(false)
        await dispatch(identityActions.DeleteSharesightIntegration(portfolioId, sharesightPortfolioId))
        window.localStorage.removeItem(LOCAL_STORAGE_KEY)

        Toast(`${isDependent ? preferredName + '’s' : 'Your'} Sharesight account has been disconnected.`)
    }

    return (
        <>
            {sharesightPortfolioLoadingState !== 'error' &&
                sharesightIntegrationsForPortfolio.some(integration => !integration.is_invalid) && (
                    <p className={spacing.spaceBelow24}>{`${
                        isDependent ? preferredName + '’s' : 'Your'
                    } buy and sell transactions are being sent to Sharesight.`}</p>
                )}

            {sharesightIntegrationLoadingState === 'error' && (
                <AlertCard
                    type="warning"
                    text="We can’t connect to Sharesight right now. When we can, we’ll send through any new buy or sell transactions."
                    className={spacing.spaceBelow24}
                />
            )}
            {sharesightIntegrationsForPortfolio.map((integration, i) => {
                return (
                    <div key={i}>
                        {!integration.is_invalid && sharesightPortfolioLoadingState !== 'error' && (
                            <div className={styles.integration}>
                                <div
                                    className={styles.textWrapper}
                                    onClick={() =>
                                        integration.sharesight_portfolio &&
                                        window.open(
                                            `${config.sharesightPortfolioUrl}/${integration.sharesight_portfolio.id}`,
                                        )
                                    }
                                >
                                    <p>{integration.sharesight_portfolio?.name}</p>
                                    <img src={openExternal} />
                                </div>

                                <ButtonAsLink onClick={() => setDisconnectModalIsOpen(true)}>
                                    {'Disconnect'}
                                </ButtonAsLink>
                            </div>
                        )}
                        {(integration.is_invalid || sharesightPortfolioLoadingState === 'error') && (
                            <AlertCard
                                type="warning"
                                className={spacing.spaceBelow16}
                                title="We can’t connect to your selected Sharesight Portfolio"
                                text="The Sharesight account or Portfolio may have been deleted."
                                links={[
                                    {
                                        text: 'Okay',
                                        callback: () => {
                                            dispatch(
                                                identityActions.DeleteSharesightIntegration(portfolioId, undefined),
                                            )
                                            window.localStorage.removeItem('sharesightAlertFlags')
                                        },
                                    },
                                ]}
                            />
                        )}

                        {integration.extraction_failed &&
                            !integration.is_invalid &&
                            !sharesightAlertFlags.overPortfolioRecordLimit && (
                                <AlertCard
                                    type="warning"
                                    className={spacing.spaceBelow16}
                                    text={`${
                                        isDependent ? preferredName + ' has' : 'You’ve got'
                                    } over 7,000 past buy and sell transactions, which is above the limit that can be sent to Sharesight.`}
                                    links={[
                                        {
                                            text: 'Okay',
                                            callback: () => updateAlertFlags('overPortfolioRecordLimit'),
                                        },
                                    ]}
                                >
                                    {sharesightHelp}
                                </AlertCard>
                            )}
                        {integration.holdings_exceed_trade_limit.length === 1 &&
                            (!integration.extraction_failed || sharesightAlertFlags.overPortfolioRecordLimit) &&
                            !sharesightAlertFlags.oneOver1000 &&
                            !integration.is_invalid && (
                                <AlertCard
                                    type="warning"
                                    className={spacing.spaceBelow16}
                                    text={`${
                                        isDependent ? preferredName + ' has' : 'You’ve got'
                                    } over 1,000 past buy and sell transactions for your ${
                                        integration.holdings_exceed_trade_limit[0]
                                    } investment,
                                             which is above the limit that can be sent to Sharesight.`}
                                    links={[
                                        {
                                            text: 'Okay',
                                            callback: () => updateAlertFlags('oneOver1000'),
                                        },
                                    ]}
                                >
                                    {sharesightHelp}
                                </AlertCard>
                            )}
                        {integration.holdings_exceed_trade_limit.length > 1 &&
                            (!integration.extraction_failed || sharesightAlertFlags.overPortfolioRecordLimit) &&
                            !sharesightAlertFlags.moreOver1000 &&
                            !integration.is_invalid && (
                                <AlertCard
                                    type="warning"
                                    className={spacing.spaceBelow16}
                                    text={`${
                                        isDependent ? preferredName + ' has' : 'You’ve got'
                                    } over 1,000 past buy and sell transactions for the following investments, which is above the limit that can be sent to Sharesight.`}
                                    links={[
                                        {
                                            text: 'Okay',
                                            callback: () => updateAlertFlags('moreOver1000'),
                                        },
                                    ]}
                                >
                                    <ul>
                                        {integration.holdings_exceed_trade_limit.map((investment, i) => (
                                            <li key={i}>{investment}</li>
                                        ))}
                                    </ul>
                                    {sharesightHelp}
                                </AlertCard>
                            )}
                        {/* </div> */}
                        <Modal
                            isOpen={disconnectModalIsOpen}
                            setIsOpen={setDisconnectModalIsOpen}
                            title="Disconnect from Sharesight"
                            dataTestId="modal--disconnect-from-sharesight"
                            primaryButton={{
                                label: 'Disconnect',
                                disabled: !integration.sharesight_portfolio,
                                onClick: () => disconnectSharesight(integration.sharesight_portfolio?.id),
                            }}
                            secondaryButton={{label: 'Cancel'}}
                        >
                            <p>
                                {`${
                                    isDependent ? preferredName + '’s' : 'Your'
                                } Sharesies Portfolio will no longer be connected to '${integration.sharesight_portfolio
                                    ?.name}' in Sharesight.
                                Buy and sell transactions that have already been sent will still be available in
                                Sharesight.`}
                            </p>
                        </Modal>
                    </div>
                )
            })}
        </>
    )
}

export default SharesightActiveIntegrations
