import Decimal from 'decimal.js'

/**
 * Rounds down to two decimal places
 *
 * @param {string} number - number as a string
 * @returns {Decimal} Decimal
 */
export const roundWithdrawalValue = (number: string) => {
    return new Decimal(number).toDP(2, Decimal.ROUND_DOWN)
}
