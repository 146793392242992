import React from 'react'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {ShareValue, DollarValue, SharePriceValue} from '~/global/widgets/number-elements/NumberElements'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {CorporateActionOrder} from '~/store/accounting/types'
import {Instrument} from '~/store/instrument/types'
import {RecordRow} from './common'
import styles from './Record.scss'

interface InstrumentActivityRecordCorporateActionProps {
    instrument: Instrument
    order: CorporateActionOrder
}

interface RecordData {
    dataRow: React.ReactNode
    amountText?: React.ReactNode
    amount?: React.ReactNode
    labelText?: string
}

const recordDataFor = (action: CorporateActionOrder, instrument: Instrument): RecordData => {
    const currency = instrument.currency
    switch (action.action_type) {
        case 'capital_decrease':
            return {
                dataRow: (
                    <div className={styles.recordRowContainer}>
                        <RecordRow
                            left="Number of shares on record date"
                            right={<ShareValue value={action.before_shares} showFullValue />}
                        />
                        <RecordRow
                            left="Number of shares cancelled on record date"
                            right={<ShareValue value={-action.delta_shares} showFullValue />}
                        />
                        <RecordRow
                            left="Capital return amount per cancelled share"
                            right={<SharePriceValue value={action.delta_price} />}
                        />
                    </div>
                ),
                labelText: action.paid_on ? 'Paid to Wallet' : 'Pending',
                amountText: action.paid_on ? (
                    <>
                        Amount paid on
                        <br /> {action.paid_on.toFormat(dateFormatNoTime)}
                    </>
                ) : (
                    'Amount to be paid'
                ),
                amount: <DollarValue value={action.delta_value} />,
            }
        case 'delist_share_sale':
            return {
                dataRow: (
                    <div className={styles.recordRowContainer}>
                        <RecordRow
                            left="Shares sold"
                            right={action.shares_sold ? <ShareValue value={action.shares_sold} showFullValue /> : '-'}
                        />
                        <RecordRow
                            left="Price per share"
                            right={
                                action.share_price ? (
                                    <SharePriceValue value={action.share_price} currency={currency} />
                                ) : (
                                    '-'
                                )
                            }
                        />
                    </div>
                ),
                labelText: 'Paid to Wallet',
                amountText: (
                    <>
                        Amount paid on
                        <br /> {action.action_timestamp.toFormat(dateFormatNoTime)}
                    </>
                ),
                amount: <DollarValue value={action.delta_value} />,
            }
        case 'share_purchase':
            return {
                dataRow: (
                    <div className={styles.recordRowContainer}>
                        <RecordRow left="Order completed" right={action.action_timestamp.toFormat(dateFormatNoTime)} />
                        <RecordRow
                            left="Shares purchased"
                            right={action.order_shares ? <ShareValue value={action.order_shares} showFullValue /> : '-'}
                        />
                        <RecordRow
                            left="Price per share"
                            right={
                                action.share_price ? (
                                    <SharePriceValue value={action.share_price} currency={currency} />
                                ) : (
                                    '-'
                                )
                            }
                        />
                    </div>
                ),
                amountText: 'Amount',
                amount: <DollarValue value={action.order_total} currency={currency} />,
                labelText: action.order_state === 'processing' ? 'Processing' : 'Complete',
            }
        default:
            assertNever(action)
            return {
                dataRow: null,
            }
    }
}

const InstrumentActivityRecordCorporateAction: React.FC<InstrumentActivityRecordCorporateActionProps> = React.memo(
    ({order: action, instrument}) => {
        const {dataRow, amountText, labelText, amount} = recordDataFor(action, instrument)

        return (
            <div className={styles.orderContent}>
                <div className={styles.orderTitleBlock}>
                    <div className={styles.orderThumb}>
                        <InstrumentLogo instrument={instrument} noBorder />
                    </div>
                    <div>
                        <h4 className={styles.instrumentName}>{instrument.name}</h4>
                        {tradingType(instrument) !== 'managed' && !isInstrumentInNoTrade(instrument) && (
                            <p className={styles.instrumentSymbol}>
                                <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                                <PronounceLetters text={instrument.exchange} />
                            </p>
                        )}
                        <p className={styles.orderTime} />
                    </div>
                </div>
                {dataRow}
                {amountText && <p className={styles.orderAmountLabel}>{amountText}</p>}
                {(labelText || amount) && (
                    <div className={styles.orderFooter}>
                        <div>
                            {labelText && (
                                <p className={styles.statusLabel} data-testid="orderStatusLabel">
                                    {labelText}
                                </p>
                            )}
                        </div>
                        <div>{amount && <p className={styles.orderAmount}>{amount}</p>}</div>
                    </div>
                )}
            </div>
        )
    },
)

export default InstrumentActivityRecordCorporateAction
