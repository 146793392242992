import {Button} from '@design-system/button'
import {withFormik, InjectedFormikProps} from 'formik'
import React from 'react'
import {Model} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {processAddressComponents} from '~/global/utils/format-address/formatAddress'
import {extractCountryCodeFromName} from '~/global/widgets/form-controls/country-select/CountrySelectInput'
import {AddressValidation, ManualAddressFields} from '~/global/widgets/manual-address-fields/ManualAddressFields'
import {connect} from '~/store/connect'
import {AddressComponents, User} from '~/store/identity/types'
import signUpActions from '~/store/sign-up/actions'

const EditForm: React.FunctionComponent<InjectedFormikProps<FormProps, AddressComponents>> = ({
    isSubmitting,
    handleSubmit,
    jurisdiction,
    isValid,
    values,
}) => {
    return (
        <form onSubmit={handleSubmit}>
            <ManualAddressFields selectedCountry={values.country_code} jurisdiction={jurisdiction} />
            <Button
                isSubmit
                pageButton
                dataTestId="button--save-changes"
                label="Save changes"
                disabled={!isValid}
                processing={isSubmitting}
                additionalClassName={spacing.spaceBelow48}
            />
        </form>
    )
}

const Form = withFormik<FormProps, AddressComponents>({
    mapPropsToValues: ({address}) => {
        return {
            address,
            sublocality: address!.components.sublocality || '',
            street_number: address!.components.street_number || '',
            route: address!.components.route || '',
            locality: address!.components.locality || '',
            state_code: address!.components.state_code || '',
            postal_code: address!.components.postal_code || '',
            country_code:
                address!.components.country_code || extractCountryCodeFromName(address!.components.country) || '',
        }
    },
    handleSubmit: async (
        {sublocality, street_number, route, locality, state_code, postal_code, country_code},
        {setSubmitting, props: {usEditAddress, isEditing}},
    ) => {
        const address = processAddressComponents({
            street_number,
            route,
            locality,
            postal_code,
            country_code,
            state_code,
            sublocality,
        })
        setSubmitting(true)
        try {
            await usEditAddress(address)
            isEditing(false)
        } catch (error) {
            setSubmitting(false)
            throw error
        }
    },
    validate: values => {
        return AddressValidation(values)
    },
})(EditForm)

const EditAddress: React.FunctionComponent<EditAddressProps> = ({address, jurisdiction, usEditAddress, isEditing}) => {
    return (
        <>
            <h3>Residential address</h3>
            <div className={spacing.spaceAbove16}>
                <Form
                    address={address}
                    usEditAddress={usEditAddress}
                    isEditing={isEditing}
                    jurisdiction={jurisdiction}
                />
            </div>
        </>
    )
}

interface StoreProps {
    preferredName: string
    address: User['address']
    isDependent: boolean
    jurisdiction: Model.User['jurisdiction']
}

interface DispatchProps {
    usEditAddress(address: User['address']): Promise<void | null>
}

interface OwnProps {
    isEditing: React.Dispatch<React.SetStateAction<boolean>>
}

type EditAddressProps = StoreProps & DispatchProps & OwnProps

type FormProps = Pick<StoreProps, 'address'> & Pick<StoreProps, 'jurisdiction'> & DispatchProps & OwnProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    state => ({
        preferredName: state.identity.user!.preferred_name,
        address: state.identity.user!.address!,
        isDependent: state.identity.user!.is_dependent,
        jurisdiction: state.identity.user!.jurisdiction,
    }),
    dispatch => ({
        usEditAddress: address => dispatch(signUpActions.usEditAddress(address)),
    }),
)(EditAddress)
