import React, {ReactNode} from 'react'
import {exchangeNextOpen} from '~/global/utils/exchange-next-open/exchangeNextOpen'
import {dateFormatDayAndMonthWithTime} from '~/global/utils/format-date/formatDate'
import {isInstrumentInHalt} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import HowCompanyAndETFProcessed from '~/global/widgets/help-modals/HowCompanyAndETFProcessed'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {BuySellOrder} from '~/store/accounting/types'
import {Exchange, Instrument} from '~/store/instrument/types'
import styles from '../Record.scss'

export const EstimatedOrderCompletionDataRow: React.FC<{
    exchange?: Exchange
    instrument: Instrument
    isExchangeInMarketHours: boolean
    order: BuySellOrder
}> = React.memo(({exchange, instrument, isExchangeInMarketHours, order}) => {
    if (tradingType(instrument) === 'managed' || !exchange) {
        return <div />
    }

    let pendingReason: ReactNode

    if (isInstrumentInHalt(instrument)) {
        pendingReason = (
            <div className={styles.delayReason}>
                <PronounceLetters text={instrument.exchange} /> has a trading halt on {instrument.name} right now. Your
                order won't go on the market until the halt is lifted. <HowCompanyAndETFProcessed />
            </div>
        )
    }

    if (!isExchangeInMarketHours && !order.is_extended_hours) {
        const nextOpen = exchangeNextOpen(exchange)

        pendingReason = (
            <div className={styles.delayReason}>
                The <PronounceLetters text={instrument.exchange} /> is closed. Your order will be placed on the market
                after it {nextOpen && <>opens at {nextOpen.toFormat(dateFormatDayAndMonthWithTime)}</>}
                {!nextOpen && <>next opens</>}.
            </div>
        )
    }

    return pendingReason ? (
        <div className={styles.orderCompletion}>
            <div>{pendingReason}</div>
        </div>
    ) : (
        <div />
    )
})
