import {InvestFilled} from '@design-system/icon'
import {ModalLink} from '@design-system/modal'
import React from 'react'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {ItemType} from '~/sections/moose/sections/order/state'
import {HoldingDetailForSymbol} from '~/sections/moose/sections/order/utils/get-holding-details-for-symbol/getHoldingDetailsForSymbol'
import {shareLabel} from '~/sections/moose/sections/order/utils/share-label/shareLabel'
import {StandardLimitsWidget} from '~/sections/moose/sections/order/widgets/standard-limits/StandardLimitsWidget'
import styles from './HelpForStandardSellOrder.scss'

export function helpForStandardSellOrder(
    portfolioId: string,
    itemType: ItemType,
    holding: HoldingDetailForSymbol,
    symbol: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
): React.ReactNode {
    const hasShareReservations = (): React.ReactNode => {
        return holding.reserved ? (
            <>
                <div className={styles.permittedToOrder}>
                    <span>
                        <InvestFilled size={14} /> {holding.maxAllowedToSell}{' '}
                        {shareLabel({
                            itemType,
                            isPlural: true,
                        })}{' '}
                        available to sell{' '}
                    </span>

                    <StandardLimitsModal portfolioId={portfolioId} symbol={symbol} itemType={itemType} />
                </div>
            </>
        ) : null
    }

    const hasNoShareReservations = (): React.ReactNode => {
        return !holding.reserved ? (
            <>
                <div className={styles.permittedToOrder}>
                    <span>
                        <InvestFilled size={14} /> You own {holding.maxAllowedToSell}{' '}
                        {shareLabel({
                            itemType,
                            isPlural: true,
                        })}{' '}
                    </span>

                    <ButtonAsLink onClick={() => setFieldValue('numberOfItemsToSell', holding.maxAllowedToSell)}>
                        Sell all
                    </ButtonAsLink>
                </div>
            </>
        ) : null
    }

    return (
        <>
            {hasShareReservations()}
            {hasNoShareReservations()}
        </>
    )
}

export const StandardLimitsModal = ({
    portfolioId,
    symbol,
    itemType,
}: {
    portfolioId: string
    symbol: string
    itemType: ItemType
}) => {
    return (
        <ModalLink
            additionalClassName={styles.standardLimitLink}
            dataTestId="modal-link--available-to-sell"
            label="What’s this?"
            modalTitle="Available to sell"
            primaryButton={{label: 'Close'}}
            bottomBorder
            helpIconSize={16}
        >
            <StandardLimitsWidget symbol={symbol} portfolioId={portfolioId} itemType={itemType} />
        </ModalLink>
    )
}
