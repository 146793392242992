import {ClassicCard, logContentCardClick} from '@braze/web-sdk'
import cn from 'classnames'
import React from 'react'
import {Link} from 'react-router-dom'
import {isInternalLink, normalizeUrl} from '~/global/utils/normalize-url/normalizeUrl'
import {WithArrowRight} from '~/sections/explore/widgets/with-arrow-right/WithArrowRight'
import {EXPLORE_BRAZE_CARD_TYPES, exploreContentCardsRollbarError} from '../common'
import styles from './HighlightContentCard.scss'

interface HighlightContentCard extends ClassicCard {
    title: string
    extras?: {
        // @NOTE These will need to put into types once we support other cards
        category: 'explore_content'
        display_type: 'announcement'
        explore_content_label: string
        eyebrow_label: string
        colour_preset?: string
        priority?: number
    }
}

interface HighlightContentCardProps {
    card: ClassicCard
}

export const HighlightContentCard: React.FunctionComponent<HighlightContentCardProps> = ({card}) => {
    if (!card || card.extras?.display_type !== EXPLORE_BRAZE_CARD_TYPES.displayType.announcement) {
        return null
    }

    const {linkText, title, url, extras, id} = card as HighlightContentCard

    if (!url) {
        exploreContentCardsRollbarError({
            exploreCardType: 'exploreContent',
            id,
            missingValues: {
                linkText,
                url,
            },
        })

        return null
    }
    const cleanUrl = normalizeUrl(url)

    const handleOnClick = () => {
        logContentCardClick(card)
    }

    const linkLabel = (
        <>
            {extras?.eyebrow_label && <div className={styles.eyebrowLabel}>{extras?.eyebrow_label}</div>}
            <h3>{title}</h3>

            {linkText && <WithArrowRight>{linkText}</WithArrowRight>}
        </>
    )
    const linkStyling = cn(
        styles.announcement,
        extras?.colour_preset === 'melon' && styles.melon,
        extras?.colour_preset === 'blueberry' && styles.blueberry,
        extras?.colour_preset === 'blueberry2' && styles.blueberry2,
        extras?.colour_preset === 'pineapple' && styles.pineapple,
        // Fallback if not present
        !extras?.colour_preset && styles.blueberry,
    )

    return (
        <section className={styles.highlightContentCard} data-priority={extras?.priority || 0}>
            <h2>{extras?.explore_content_label}</h2>
            {isInternalLink(cleanUrl) ? (
                <Link className={linkStyling} to={cleanUrl} onClick={handleOnClick}>
                    {linkLabel}
                </Link>
            ) : (
                <a className={linkStyling} href={cleanUrl} target="_blank" rel="noopener">
                    {linkLabel}
                </a>
            )}
        </section>
    )
}
