import React from 'react'
import {Outlet} from 'react-router'
import {urlFor} from '~/global/routeGenerator'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import LoginRedirecter from '~/global/wrappers/LoginRedirecter'
import {useLocation, useNavigate} from '~/migrate-react-router'
import {additionalSignUpStepsCheck} from '~/sections/user/sections/sign-up/SignUp'
import {useAppSelector} from '~/store/hooks'
import {User} from '~/store/identity/types'

const AuthenticatedWrapper: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const userState = useAppSelector(s => {
        const userStateOrNull = s.identity.user?.state || null
        return s.identity.switchingTo ? s.identity.switchingTo.state : userStateOrNull
    })
    const user = useAppSelector(s => (s.identity.user ? s.identity.user : null))

    React.useEffect(() => {
        checkAndRedirect(userState, user)
    }, [userState, user])

    const checkAndRedirect = (state: User['state'] | null, user: User | null) => {
        if (user && additionalSignUpStepsCheck(user)) {
            navigate(urlFor('update'))
        }

        if (state) {
            switch (state) {
                case 'in_signup':
                    navigate(urlFor('sign-up'))
                    break
                case 'active':
                    // This user can just use the system as per normal
                    break
                case 'deleted':
                    // The server shouldn't allow logging in in this state,
                    // this should never happen
                    break
                default:
                    assertNever(state)
            }
        }
    }

    return (
        <>
            <LoginRedirecter
                redirectWhen="anonymous"
                redirectTo={`${urlFor('login')}?next=${encodeURIComponent(location.pathname + location.search)}`}
            />
            {userState && <Outlet />}
        </>
    )
}

export default AuthenticatedWrapper
