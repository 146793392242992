/**
 * Recursively flatten a list so that it contains no other lists
 *
 * @param {any[]} list - array
 * @returns {any[]} a list containing no other lists
 *
 * Example:
 * >> flatten(['a', 'b', ['c', ['d']], 'e'])
 * >> ['a', 'b', 'c', 'd', 'e']
 */
export const flattenList = (list: any[]): [] =>
    list.reduce((a, b) => a.concat(Array.isArray(b) ? flattenList(b) : b), [])
