import {NotificationBell, NotificationBellBadged} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {accessibility} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useNavigate} from '~/migrate-react-router'
import {useAppSelector} from '~/store/hooks'
import styles from './NotificationBell.scss'

export const NotificationButton: React.FunctionComponent = () => {
    const notificationsUnviewedCount = useAppSelector(s => s.identity.notifications.notificationsPageUnviewedCount)

    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    return (
        <button
            type="button"
            data-test-id="toolbar--notifications-button"
            className={cn(styles.notifications, accessibility.button)}
            onClick={() => {
                rudderTrack('notifications', 'bell_clicked', {badged: notificationsUnviewedCount > 0})
                navigate(profileUrl('notifications'))
            }}
            title={`Show notifications: ${notificationsUnviewedCount} unread`}
        >
            {notificationsUnviewedCount === 0 ? <NotificationBell /> : <NotificationBellBadged />}
        </button>
    )
}
