import {ModalLink} from '@design-system/modal'
import React from 'react'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {Instrument} from '~/store/instrument/types'

interface PriceInfoProps {
    instrument: Instrument
    title: string
    type: 'buy' | 'sell'
}

export const PriceInfoModal = ({instrument, title, type}: PriceInfoProps) => {
    return (
        <ModalLink
            dataTestId="modal-link--price-information"
            label="Price information"
            asIcon
            modalTitle={title}
            primaryButton={{label: 'Ok'}}
        >
            <p>
                The price you {type === 'buy' ? 'pay' : 'get'} per {shareLabel({instrument})} may be different if your
                order fills bit-by-bit.
            </p>
            <p>
                Your order’s average price per {shareLabel({instrument})} is a weighted average based on the number of{' '}
                {shareLabel({instrument, isPlural: true})} {type === 'buy' ? 'bought' : 'sold'} at different prices.
            </p>
        </ModalLink>
    )
}
