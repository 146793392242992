import {Button} from '@design-system/button'
import {DateTime} from 'luxon'
import React from 'react'
import {useRetailGet} from '~/api/query/retail'
import {spacing} from '~/global/scss/helpers'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ErrorBox, Radio, ReportDateRange, ReportType} from '~/global/widgets/form-controls'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useAppSelector} from '~/store/hooks'

interface DateRangeProps {
    to: DateTime
    from: DateTime
}

interface DownloadReportProps {
    portfolioId: string
}

type SaveReportProps = 'tax-certificate.pdf' | 'transaction-report.csv' | 'statement.pdf'

const DownloadDocuments: React.FunctionComponent<DownloadReportProps> = ({portfolioId}) => {
    const {is_dependent, preferred_name, calendar_tax_year: currentTaxYear} = useAppSelector(s => s.identity.user!)

    const [reportType, setReportType] = React.useState<SaveReportProps>()
    const [dateRange, setDateRange] = React.useState<DateRangeProps>({
        from: DateTime.fromISO('2017-04-01').startOf('month'),
        to: DateTime.local().endOf('month'),
    })
    const [taxYear, setTaxYear] = React.useState<string | undefined>(currentTaxYear?.toString())
    const [error, setError] = React.useState<string | undefined>()

    // Determine how many tax year options to display
    const {data: transactionsPage} = useRetailGet({
        path: 'save/v2/:portfolio_id/transaction-history',
        payload: {page: 1, sort: 'ASC'},
        pathParams: {portfolio_id: portfolioId},
    })

    const taxYears = (): number[] => {
        const firstTransactionDate = transactionsPage.transaction_history.length
            ? transactionsPage.transaction_history[0].timestamp
            : DateTime.local()
        const years = []
        for (let i = firstTransactionDate.year; i <= currentTaxYear!; i++) {
            years.unshift(i)
        }

        return years
    }

    // Check the selected date range is valid
    const isValid = (): boolean => {
        if (dateRange.from > dateRange.to) {
            setError('Start date should be before end date')
            return false
        }
        if (!reportType) {
            setError('Please select a report type')
            return false
        }

        return true
    }

    const handleSubmit = () => {
        if (!isValid() || !reportType) {
            return
        }

        const reportEncoded = encodeURIComponent(reportType)
        const fromParam = encodeURIComponent(dateRange.from.toISODate())
        const toParam = encodeURIComponent(dateRange.to.toISODate())
        const timezoneParam = encodeURIComponent(DateTime.local().zoneName)
        const url =
            reportType === 'tax-certificate.pdf'
                ? `/api/save/v2/${portfolioId}/${reportEncoded}?tax_year=${taxYear}`
                : `/api/save/v2/${portfolioId}/${reportEncoded}?from=${fromParam}&to=${toParam}&timezone=${timezoneParam}`

        window.open(url, '_blank', 'noopener')
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--download-documents" title="Download documents" leftButton="back" />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <p className={spacing.spaceBelow32}>
                    Download a Resident Withholding Tax (RWT) certificate or transaction report for{' '}
                    {is_dependent ? `${preferred_name}’s` : 'your'} Save account.
                </p>
                <ReportType
                    choices={[
                        {
                            value: 'statement.pdf',
                            label: 'Account statement (PDF)',
                            helpText: 'View a statement of your account for the period selected',
                        },
                        {
                            value: 'tax-certificate.pdf',
                            label: 'RWT certificate (PDF)',
                            helpText: `View a certificate of ${
                                is_dependent ? `${preferred_name}’s` : 'your'
                            } resident withholding tax for the period selected`,
                        },
                        {
                            value: 'transaction-report.csv',
                            label: 'Transaction report (CSV)',
                            helpText: `View a report of all ${
                                is_dependent ? `${preferred_name}’s` : 'your'
                            } transactions for the period selected`,
                        },
                    ]}
                    value={reportType}
                    handleChange={value => {
                        setReportType(value)
                        setError(undefined)
                    }}
                />
                {reportType === 'tax-certificate.pdf' && taxYears().length > 0 && (
                    <div className={spacing.spaceBelow24}>
                        <Radio
                            dataTestId="radio--save-tax-year"
                            name="tax-year"
                            label="Tax period"
                            isTouched
                            choices={taxYears().map(year => {
                                return {
                                    value: `${year}`,
                                    label: `April ${year - 1}-March ${year}`,
                                    helpText: '',
                                }
                            })}
                            onChange={e => {
                                setTaxYear(e.target.value)
                            }}
                            value={taxYear}
                        />
                    </div>
                )}

                {(reportType === 'transaction-report.csv' || reportType === 'statement.pdf') && (
                    <ReportDateRange
                        dateRange={dateRange}
                        handleChange={newRange => {
                            setDateRange(newRange)
                            setError(undefined)
                        }}
                        includeMonths
                    />
                )}

                <ErrorBox message={error} />
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--download-save-report"
                    label="Download"
                    disabled={!reportType || (reportType === 'tax-certificate.pdf' && !taxYear)}
                    onClick={handleSubmit}
                />
            </ActionBar>
        </>
    )
}

export default DownloadDocuments
