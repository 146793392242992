/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {DateTime} from 'luxon'
import * as runtime from '../runtime'
import {
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    Msg,
    MsgFromJSON,
    MsgToJSON,
    Prices,
    PricesFromJSON,
    PricesToJSON,
} from '../models'

export interface CreatePricesApiV1PricesPostRequest {
    prices: Prices
}

/**
 *
 */
export class PricesApi extends runtime.BaseAPI {
    /**
     * Create Prices
     */
    async createPricesApiV1PricesPostRaw(
        requestParameters: CreatePricesApiV1PricesPostRequest,
    ): Promise<runtime.ApiResponse<Msg>> {
        if (requestParameters.prices === null || requestParameters.prices === undefined) {
            throw new runtime.RequiredError(
                'prices',
                'Required parameter requestParameters.prices was null or undefined when calling createPricesApiV1PricesPost.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/prices/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PricesToJSON(requestParameters.prices),
        })

        return new runtime.JSONApiResponse(response, jsonValue => MsgFromJSON(jsonValue))
    }

    /**
     * Create Prices
     */
    async createPricesApiV1PricesPost(requestParameters: CreatePricesApiV1PricesPostRequest): Promise<Msg> {
        const response = await this.createPricesApiV1PricesPostRaw(requestParameters)
        return await response.value()
    }
}
