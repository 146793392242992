import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import oneOffTopupStyles from '~/sections/OLD_wallet/sections/linked-bank-payments/pages/one-off-topup/OneOffTopUp.scss'

interface TopUpBreakdownProps {
    topupBreakdown: {feeAmount: number; totalAmount: number}
}

export const TopUpBreakdown: React.FunctionComponent<TopUpBreakdownProps> = ({topupBreakdown}) => {
    return (
        <div className={oneOffTopupStyles.feeBreakdown}>
            <div className={oneOffTopupStyles.amountRow}>
                <div>
                    Transfer Fee{' '}
                    {topupBreakdown.feeAmount > 0 && (
                        <ModalLink
                            dataTestId="modal-link--estimated-settlement-date"
                            label="Linked bank transaction fee"
                            asIcon
                            helpIconSize={16}
                            modalTitle="Linked bank transfer fee"
                            primaryButton={{label: 'Got it'}}
                        >
                            <p>There’s a 2% transfer fee on payments from a linked bank capped at $2 AUD.</p>
                            <p>
                                Linked bank transfers are free on a Sharesies pricing plan. You can sign up to a plan by
                                heading to <b>Account {'>'} Pricing plans</b>.
                            </p>
                        </ModalLink>
                    )}
                </div>
                <div>
                    <DollarValue value={topupBreakdown.feeAmount} currency="AUD" />
                </div>
            </div>
            {topupBreakdown.feeAmount === 0 && (
                <div className={cn(oneOffTopupStyles.feeDisclaimer)}>
                    Transfer fees are covered by your pricing plan.
                </div>
            )}
            <div className={cn(oneOffTopupStyles.amountRow, oneOffTopupStyles.amountRowBold)}>
                <div>Total</div>
                <div>
                    <DollarValue value={topupBreakdown.totalAmount} currency="AUD" />
                </div>
            </div>
            <hr className={spacing.spaceAbove12} />
        </div>
    )
}
