/**
 * Strips profile prefixes from route paths. Matches `/profile/:profileSlug` and
 * `/profile/:profileSlug/` (with or without trailing slash) and normalises a
 * starting slash being present.
 *
 * @param {string} route - path to strip profil from
 * @returns {string} - stripped path
 */
export const stripProfile = (route: string) => {
    return route.replace(/\/profile\/[^/]+[/]?/, '/') // profile, then everything up until an optional trailing slash - if the trailing slash is there, it's included in the match
}
