import {ClassicCard} from '@braze/web-sdk'
import cn from 'classnames'
import React from 'react'
import {ExploreContentCardCommon, exploreContentCardsRollbarError} from '../common'
import VideoPlaySVG from './assets/video-play.inline.svg'
import styles from './VideoCard.scss'

export interface VideoCardProps extends ExploreContentCardCommon {
    contentLabel: string
    overlayText?: string
    imageUrl: string
    videoUrl: string
}

export const brazeCardToVideoCard = ({imageUrl, extras = {}, id}: ClassicCard): VideoCardProps | null => {
    const {priority, explore_content_label, overlay_text, video_url} = extras

    if (!video_url || !imageUrl) {
        exploreContentCardsRollbarError({
            exploreCardType: 'exploreVideoCard',
            id,
            missingValues: {
                video_url,
                imageUrl,
            },
        })
        return null
    }

    return {
        contentLabel: explore_content_label,
        overlayText: overlay_text,
        imageUrl,
        videoUrl: video_url,
        priority,
    }
}

export const VideoCard = ({contentLabel, overlayText, imageUrl, videoUrl, priority = 0}: VideoCardProps) => {
    const [isVideoPlaying, setIsVideoPlaying] = React.useState(false)
    const videoRef = React.useRef<HTMLVideoElement>(null)

    return (
        <div className={styles.videoCard} data-priority={priority}>
            <h2>{contentLabel}</h2>

            <div className={styles.video}>
                <video
                    ref={videoRef}
                    className={styles.videoPlayer}
                    poster={imageUrl}
                    playsInline
                    controls={isVideoPlaying}
                    preload="metadata"
                    onPlay={() => {
                        setIsVideoPlaying(true)
                        // rudderTrack('explore', 'getting_started_content_clicked', {type: 'intro_video'})
                    }}
                >
                    <source src={videoUrl} />

                    <p>Video unsupported</p>
                </video>

                {!isVideoPlaying && (
                    <>
                        <button
                            className={cn('button', styles.videoPlayButton)}
                            type="button"
                            aria-label={`Play video - ${contentLabel}`}
                            title={`Play video - ${contentLabel}`}
                            onClick={() => {
                                videoRef.current?.play()
                                videoRef.current?.focus()
                            }}
                        >
                            <div className={styles.videoPlayButtonBackground}>
                                <VideoPlaySVG />
                            </div>
                        </button>

                        {overlayText && <p className={styles.overlayText}>{overlayText}</p>}
                    </>
                )}
            </div>
        </div>
    )
}
