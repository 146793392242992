import React from 'react'
import {useNavigate} from 'react-router'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {MoosePortfolioPathParams} from '~/sections/moose/routes'
import {AddFinForm} from '~/sections/moose/widgets/add-fin-form/AddFinForm'

export const ManageAddFin: React.FunctionComponent<MoosePortfolioPathParams> = ({
    portfolioId,
}: MoosePortfolioPathParams) => {
    const navigate = useNavigate()

    const onSuccess = async () => {
        Toast('Your FIN has been saved.')
        navigate(-1) // back to manage
    }

    const onQueueForLater = () => {
        navigate(-1)
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--manage-add-fin" leftButton="back" title="What is your FIN?" />
            <Page overrideDefaultTopPadding="none">
                <AddFinForm
                    portfolioId={portfolioId}
                    onSuccess={onSuccess}
                    onQueueForLater={onQueueForLater}
                    submitButtonLabel="Save"
                />
            </Page>
        </>
    )
}
