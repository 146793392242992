import {ArrowRight} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {accessibility, spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useNavigate} from '~/migrate-react-router'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {ResponseRegistryDetail} from '~/store/transfer/types'

export const RegistryDetailStateLabel = ({registryDetail: {state}}: Props) => {
    return (
        <div
            className={cn(
                commonStyles.statusLabel,
                state === 'approved' && commonStyles.success,
                state === 'rejected' && commonStyles.failed,
            )}
        >
            {state === 'pending' && 'Pending'}
            {state === 'rejected' && 'Failed'}
            {state === 'approved' && 'Verified'}
        </div>
    )
}
const RegistryDetail: React.FunctionComponent<Props> = ({registryDetail}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    return (
        <button
            className={cn(accessibility.button, commonStyles.cardContainer, spacing.spaceAbove16)}
            type="button"
            onClick={() => {
                navigate(
                    profileUrl('invest/portfolio-transfer-shares/view-registry-detail/:registryDetailId', {
                        registryDetailId: registryDetail.id,
                    }),
                )
            }}
            data-testid="button--view-registry-details"
        >
            <div className={commonStyles.textAndStatusLabel}>
                <h3>CSN/HN {registryDetail.reference}</h3>
                <RegistryDetailStateLabel registryDetail={registryDetail} />
            </div>
            <div className={cn(commonStyles.horizontalStack, spacing.spaceAbove4)}>
                <div className={commonStyles.pendingCardContent}>
                    <p>NZX</p>
                    <p className={commonStyles.pendingCardLink}>
                        View <ArrowRight className={commonStyles.icon} />
                    </p>
                </div>
            </div>
        </button>
    )
}

interface Props {
    registryDetail: ResponseRegistryDetail
}

export default RegistryDetail
