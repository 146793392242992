import {ActionsType} from './actions'
import {State} from './types'

const initialState: State = {
    stagedName: undefined,
    interestRate: null,
    transferDetails: {
        transferAmount: '',
        transferDirection: undefined,
        transferOtherAccountId: undefined,
    },
    transactionHistory: [],
    transactionHistoryCurrentPage: 1,
    transactionHistoryFilter: 'all',
    transactionHistoryFilterOptions: [],
    transactionHistoryHasOlder: null,
    transactionHistoryFetchingMore: false,
    transactionHistoryLoadingState: 'ready',
    recurringTransfers: [],
}

function reducer(state: State = initialState, action: ActionsType): State {
    switch (action.type) {
        case 'save.SetInterestRate':
            return {...state, interestRate: action.payload.rate}
    }

    return state
}

export default reducer
