/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {DateTime} from 'luxon'
import * as runtime from '../runtime'
import {
    AppSchemasForDataFeedRecordsRecordList,
    AppSchemasForDataFeedRecordsRecordListFromJSON,
    AppSchemasForDataFeedRecordsRecordListToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    Msg,
    MsgFromJSON,
    MsgToJSON,
    Record,
    RecordFromJSON,
    RecordToJSON,
    RecordIDList,
    RecordIDListFromJSON,
    RecordIDListToJSON,
} from '../models'

export interface DeleteRecordsApiV1AdminRecordsDeleteRecordsPostRequest {
    recordIDList: RecordIDList
}

export interface ValidateRecordsApiV1RecordsValidatePostRequest {
    record: Record
}

export interface WriteRecordsApiV1RecordsPostRequest {
    appSchemasForDataFeedRecordsRecordList: AppSchemasForDataFeedRecordsRecordList
}

/**
 *
 */
export class RecordsApi extends runtime.BaseAPI {
    /**
     * Delete Records
     */
    async deleteRecordsApiV1AdminRecordsDeleteRecordsPostRaw(
        requestParameters: DeleteRecordsApiV1AdminRecordsDeleteRecordsPostRequest,
    ): Promise<runtime.ApiResponse<RecordIDList>> {
        if (requestParameters.recordIDList === null || requestParameters.recordIDList === undefined) {
            throw new runtime.RequiredError(
                'recordIDList',
                'Required parameter requestParameters.recordIDList was null or undefined when calling deleteRecordsApiV1AdminRecordsDeleteRecordsPost.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/admin/records/delete-records`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecordIDListToJSON(requestParameters.recordIDList),
        })

        return new runtime.JSONApiResponse(response, jsonValue => RecordIDListFromJSON(jsonValue))
    }

    /**
     * Delete Records
     */
    async deleteRecordsApiV1AdminRecordsDeleteRecordsPost(
        requestParameters: DeleteRecordsApiV1AdminRecordsDeleteRecordsPostRequest,
    ): Promise<RecordIDList> {
        const response = await this.deleteRecordsApiV1AdminRecordsDeleteRecordsPostRaw(requestParameters)
        return await response.value()
    }

    /**
     * Validates a record object against the schema.  Returns True if the record is valid, returns a 422 error if not.  Used to check if a record in backend\'s rakaia_record table is invalid before deleting it
     * Validate Records
     */
    async validateRecordsApiV1RecordsValidatePostRaw(
        requestParameters: ValidateRecordsApiV1RecordsValidatePostRequest,
    ): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.record === null || requestParameters.record === undefined) {
            throw new runtime.RequiredError(
                'record',
                'Required parameter requestParameters.record was null or undefined when calling validateRecordsApiV1RecordsValidatePost.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/records/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecordToJSON(requestParameters.record),
        })

        return new runtime.TextApiResponse(response) as any
    }

    /**
     * Validates a record object against the schema.  Returns True if the record is valid, returns a 422 error if not.  Used to check if a record in backend\'s rakaia_record table is invalid before deleting it
     * Validate Records
     */
    async validateRecordsApiV1RecordsValidatePost(
        requestParameters: ValidateRecordsApiV1RecordsValidatePostRequest,
    ): Promise<boolean> {
        const response = await this.validateRecordsApiV1RecordsValidatePostRaw(requestParameters)
        return await response.value()
    }

    /**
     * Write Records
     */
    async writeRecordsApiV1RecordsPostRaw(
        requestParameters: WriteRecordsApiV1RecordsPostRequest,
    ): Promise<runtime.ApiResponse<Msg>> {
        if (
            requestParameters.appSchemasForDataFeedRecordsRecordList === null ||
            requestParameters.appSchemasForDataFeedRecordsRecordList === undefined
        ) {
            throw new runtime.RequiredError(
                'appSchemasForDataFeedRecordsRecordList',
                'Required parameter requestParameters.appSchemasForDataFeedRecordsRecordList was null or undefined when calling writeRecordsApiV1RecordsPost.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/api/v1/records/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppSchemasForDataFeedRecordsRecordListToJSON(
                requestParameters.appSchemasForDataFeedRecordsRecordList,
            ),
        })

        return new runtime.JSONApiResponse(response, jsonValue => MsgFromJSON(jsonValue))
    }

    /**
     * Write Records
     */
    async writeRecordsApiV1RecordsPost(requestParameters: WriteRecordsApiV1RecordsPostRequest): Promise<Msg> {
        const response = await this.writeRecordsApiV1RecordsPostRaw(requestParameters)
        return await response.value()
    }
}
