import {Button} from '@design-system/button'
import React from 'react'
import {useNavigate} from 'react-router'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useAppSelector} from '~/store/hooks'

// Placeholder while changing to Money Goals

const TopupGoal: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const hasMoneyGoal = useAppSelector(s => s.accounting.topupGoal)

    return (
        <>
            <Toolbar
                dataTestId="toolbar--top-up-goal"
                leftButton="back"
                onLeftButtonClick={() => navigate(profileUrl('wallet'))}
                title="Top up goal"
            />
            <Page>
                <p className={spacing.spaceBelow16}>Top-up goals has a new home!</p>

                {hasMoneyGoal ? (
                    <Button
                        dataTestId="button--money-goal"
                        onClick={() => navigate(profileUrl('wallet/money-goal'))}
                        label="View my money goal"
                    />
                ) : (
                    <Button
                        dataTestId="button--money-goal-intro"
                        onClick={() => navigate(profileUrl('wallet/money-goal-intro'))}
                        label="Set a money goal"
                    />
                )}
            </Page>
        </>
    )
}

export default TopupGoal
