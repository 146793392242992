import {ClassicCard} from '@braze/web-sdk'
import {MoreHorizontal} from '@design-system/icon'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {useIntersectionObserver} from 'usehooks-ts'
import {logContentCardImpressions} from '~/api/braze/braze'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {accessibility} from '~/global/scss/helpers'
import {isExploreContentCard} from '~/sections/explore/widgets/content-cards/common'
import NotificationCardLink from './NotificationCardLink'
import styles from './NotificationClassicCard.scss'

interface NotificationEffects {
    setCardAndShowModal: (card: ClassicCard) => void
}

interface NotificationClassicCardProps extends NotificationEffects {
    index: number
    card: ClassicCard
    testId: string
    periodClass?: string
    monthLabel?: string
}

const NotificationClassicCard: React.FunctionComponent<NotificationClassicCardProps> = ({
    card,
    index,
    testId,
    periodClass,
    monthLabel,
    setCardAndShowModal,
}) => {
    const ref = React.useRef<HTMLDivElement>(null)
    const entry = useIntersectionObserver(ref, {})
    const isVisible = !!entry?.isIntersecting
    const notificationCategory = card.extras?.category ? card.extras?.category : ''
    const instrumentId = card.extras?.instrument_id ? card.extras?.instrument_id : ''
    const {viewed} = card
    const [impressionLogged, setImpressionLogged] = React.useState(false)

    React.useEffect(() => {
        if (isVisible && !impressionLogged) {
            setImpressionLogged(true)

            // Mark viewed in brazeland
            logContentCardImpressions([card])

            if (!viewed) {
                // Also log rudderstack event on first view
                rudderTrack('notifications', 'notification_viewed', {
                    notification_category: notificationCategory,
                    instrument_id: instrumentId,
                })
            }
        }
    }, [viewed, isVisible, logContentCardImpressions, notificationCategory, instrumentId])

    let dt: DateTime | undefined

    if (card.updated) {
        dt = DateTime.fromJSDate(card.updated)
    }

    const isExploreContent = isExploreContentCard(card.extras?.category)

    return (
        <div
            className={cn(
                styles.notificationCard,
                periodClass && periodClass,
                periodClass ? styles.firstItem : styles.notFirstItem,
            )}
            ref={ref}
            data-testid={testId}
            data-month-label={monthLabel && monthLabel}
        >
            {!card.pinned && (
                <div className={styles.meta}>
                    <div className={cn(styles.column, styles.timeAgo)}>{dt && <>{dt.toRelative()}</>}</div>
                    <div className={styles.column}>
                        {/* TODO: isExploreContent is a one-off, remove once the campaign is over */}
                        {card.dismissible && !isExploreContent && (
                            <button
                                type="button"
                                className={cn(styles.more, accessibility.button)}
                                onClick={() => {
                                    setCardAndShowModal(card)
                                }}
                                aria-label="Show options for card"
                                title="Show options for card"
                                data-testid={`notification-button--${index + 1}`}
                            >
                                <MoreHorizontal />
                            </button>
                        )}
                    </div>
                </div>
            )}
            {/* card.description markup is set by us in Braze, so while dangerous should be relatively trustworthy */}
            <div className={styles.cardDescription} dangerouslySetInnerHTML={{__html: card.description}}></div>
            <NotificationCardLink card={card} />
        </div>
    )
}

export default NotificationClassicCard
