/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface ExplanationDetail
 */
export interface ExplanationDetail {
    /**
     *
     * @type {string}
     * @memberof ExplanationDetail
     */
    readonly description?: string | null
    /**
     *
     * @type {Array<ExplanationDetail>}
     * @memberof ExplanationDetail
     */
    readonly details?: Array<ExplanationDetail> | null
    /**
     *
     * @type {number}
     * @memberof ExplanationDetail
     */
    readonly value?: number
}

export function ExplanationDetailFromJSON(json: any): ExplanationDetail {
    return ExplanationDetailFromJSONTyped(json, false)
}

export function ExplanationDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExplanationDetail {
    if (json === undefined || json === null) {
        return json
    }
    return {
        description: !exists(json, 'description') ? undefined : json['description'],
        details: !exists(json, 'details')
            ? undefined
            : json['details'] === null
              ? null
              : (json['details'] as Array<any>).map(ExplanationDetailFromJSON),
        value: !exists(json, 'value') ? undefined : json['value'],
    }
}

export function ExplanationDetailToJSON(value?: ExplanationDetail | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {}
}
