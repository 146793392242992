import React from 'react'
import {IndexRouteObject, NonIndexRouteObject, RouteObject, useParams} from 'react-router-dom'
import {WithoutOptionalKeys} from '~/global/utils/type-utilities/typeUtilities'

/*
This type takes a route (e.g. '/forgot-password/reset/:token') and extracts all the param placeholders from it.

For the example above, would just return the type 'token'.

This basically means we don't have to manually manage this, we can just have a giant list of routes and TypeScript will
ensure we pull out the placeholder names correctly.
*/
export type PathParamKeys<Path> = Path extends string
    ? Path extends `:${infer Param}/${infer Rest}`
        ? Param | PathParamKeys<Rest>
        : Path extends `:${infer Param}`
          ? Param
          : Path extends `${string}:${infer Rest}`
            ? PathParamKeys<`:${Rest}`>
            : never
    : never

export type PathParams<Path extends string> = Record<PathParamKeys<Path>, string>

export type PathsFromRoutes<Routes> = Routes extends readonly (infer Route)[] ? PathsFromSingleRoute<Route> : never
type PathsFromSingleRoute<Route> = Route extends {path: infer Path extends string; children?: infer Children}
    ? // This eslint error seems to be a bug in eslint. Variables starting with _ are supposed to be allowed.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (Path extends `${infer _}*${infer _}` ? never : Path) | `${Path}/${PathsFromRoutes<Children>}`
    : Route extends {index: true}
      ? ''
      : Route extends {children: infer Children}
        ? PathsFromRoutes<Children>
        : never

type RequiredProps<Component extends React.ComponentType<any>> = Component extends React.ComponentType<infer Props>
    ? keyof WithoutOptionalKeys<Props>
    : never

interface CheckableIndexRoute extends Omit<IndexRouteObject, 'children'> {
    readonly Component?: React.ComponentType<any>
    readonly children?: readonly CheckableRoute[]
}

interface CheckableNonIndexRoute extends Omit<NonIndexRouteObject, 'children'> {
    readonly Component?: React.ComponentType<any>
    readonly children?: readonly CheckableRoute[]
}

/**
 * A more restricted version of React Router's RouteObject, ensuring you can't bypass our URL param injector.
 */
type CheckableRoute = CheckableIndexRoute | CheckableNonIndexRoute

export {CheckableRoute, CheckableIndexRoute, CheckableNonIndexRoute}

type MissingParams<Route extends CheckableRoute, ParentParams extends string> = Exclude<
    Route['Component'] extends React.ComponentType<any> ? RequiredProps<Route['Component']> : never,
    ParentParams | PathParamKeys<Route['path']>
>

// True if `Path` is exactly equal to the `string` type.
// False if `undefined` or a string literal.
type GenericStringPath<Path extends string | undefined> = Path extends string
    ? string extends Path
        ? true
        : false
    : false

// If a route has a path that's only typed as a general `string` rather a specific string literal type like
// `'/instruments/:slug'`, we can't use that type to do things like build `ValidPaths` or check which URL params will be
// available. Our CheckRoutes type can block this by returning a type that will never match the provided route
// definitions, like `never`. This doesn't give a great error message though.  You get no indication of which route in
// the tree has a `string` path, or even that a `string` path was cause of the error.
//
// To work around this, we perform a bit of a hack. We return a type that _mostly_ matches the provided route
// definitions, with one small difference: we set the required type of the problematic path to a string literal
// containing an error message. The type checker will then point you to the specific problematic path, saying something
// like "type `string` doesn't match type `'Error message here'`".
//
// One day we'll hopefully get properly support for custom type errors [1] but this works in the mean time.
//
// [1]: https://github.com/microsoft/TypeScript/issues/23689
type RouteWithBadPath<Route> = Route extends CheckableRoute
    ? Route & {path: 'Path type must be a string literal type. Forgot `as const`?'}
    : never

// This type is more complex than `RouteWithBadPath`, but it's the same idea. We return the Route you provided, but with
// the Component property swapped out. If only URL param `a` is available but the component needs `a` and `b`, we say we
// expected a component of type `ComponentType<{ a: string, b: 'error message here' }>`. This error message points the
// developer straight to the problematic component, route, and prop.
type RouteWithBadComponent<
    Route extends CheckableRoute,
    ParentParams extends string,
> = Route['Component'] extends React.ComponentType<infer Props>
    ? Omit<Route, 'Component'> & {
          Component: React.ComponentType<{
              [key in keyof Props]: key extends PathParamKeys<Route['path']> | ParentParams
                  ? Props[key]
                  : 'Component requires this prop but there are no URL params with a matching name.'
          }>
      }
    : never

// These next two types are pretty wild. They take the type of part of the route definition tree and spit out either
// the same types they were given, or a type that _mostly_ matches, but with problematic bits altered slightly in a way
// that conflicts with the provided type. We can use this to validate the entire type tree, including that the props of
// every component in tree can be provided from URL params given the full chain of path segments above the component's
// location in the tree.
type CheckRoutes<
    Routes,
    ParentParams extends string = never,
    RoutesAlreadyChecked extends readonly any[] = readonly [],
> = Routes extends readonly []
    ? RoutesAlreadyChecked
    : Routes extends readonly [infer Route, ...infer Rest]
      ? CheckRoutes<Rest, ParentParams, readonly [...RoutesAlreadyChecked, CheckSingleRoute<Route, ParentParams>]>
      : Routes extends any[]
        ? 'The list of routes needs to be a readonly tuple so we can type check it, but you passed a regular array. Forgot `as const`?'
        : Routes

type CheckSingleRoute<Route, ParentParams extends string> = Route extends CheckableRoute
    ? MissingParams<Route, ParentParams> extends never
        ? GenericStringPath<Route['path']> extends true
            ? RouteWithBadPath<Route>
            : Omit<Route, 'children'> & {
                  children?: CheckRoutes<Route['children'], PathParamKeys<Route['path']> | ParentParams>
              }
        : RouteWithBadComponent<Route, ParentParams>
    : never

/**
 * Transforms routes to provide all URL params as props to the page components, while also providing an additional
 * layer of type safety. It ensures that all components are mounted only at places in the route tree where all the URL
 * params they depend upon will be available. Components don't have to use all available params, but they can't require
 * params that won't be available.
 *
 * For example, if you have the route '/instrument/:instrumentId', the following component props would be valid:
 *
 * ```
 * {}
 * {instrumentId?: string}
 * {instrumentId: string, favouriteColour?: string}
 * ```
 *
 * The following component props would be INVALID:
 *
 * ```
 * {instrumentId?: number}
 * {instrumentId: number}
 * {instrumentID: string}
 * {instrumentId: string, favouriteColour: string}
 * ```
 *
 * To make this possible, components can't call `useParams()` directly. Instead, every component get wrapped in a
 * <Renderer /> (below) that provides all available URL params as regular component props.
 *
 * The ultimate benefit of doing this is that it makes it very difficult for someone to typo parameter names or
 * misdirect a route to the wrong component.
 */
export function injectParamsAsProps<Routes>(routes: CheckRoutes<Routes>): RouteObject[] {
    function applyRenderer<Route extends CheckableRoute>(route: Route): RouteObject {
        if (route.index) {
            return {
                ...route,
                index: true,
                children: undefined,
                Component: undefined,
                element: route.element || (route.Component && <Renderer component={route.Component} />),
            }
        } else {
            return {
                ...route,
                index: false,
                children: route.children && route.children.map(applyRenderer),
                Component: undefined,
                element: route.element || (route.Component && <Renderer component={route.Component} />),
            }
        }
    }

    return (routes as readonly CheckableRoute[]).map(applyRenderer)
}

/**
 * The helper component that actually injects URL params into components, used by
 * `createBrowserRouterWithInjectedProps()`.
 */
const Renderer = <Params extends {}>({component: Component}: {component: React.ComponentType<Params>}) => {
    const params = useParams() as Params
    return <Component {...params} />
}
