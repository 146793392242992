import {AutoInvest} from '@design-system/icon'
import {useColourMode} from '@design-system/use-colour-mode/useColourMode'
import cn from 'classnames'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {Model} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {accessibility, spacing} from '~/global/scss/helpers'
import {convertToKebabCase} from '~/global/utils/convert-to-kebab-case/convertToKebabCase'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {Link, useNavigate} from '~/migrate-react-router'
import styles from '~/sections/invest/sections/auto-invest/Common.scss'
import {MAX_DIY_ORDERS} from '~/sections/invest/sections/auto-invest/constants/constants'
import {useAutoinvestOrders} from '~/sections/invest/sections/auto-invest/hooks/useAutoinvestOrders'
import {usePremadeOrders} from '~/sections/invest/sections/auto-invest/hooks/usePremadeOrders'
import {getImageForAutoInvestOrder} from '~/sections/invest/sections/auto-invest/utils/map-auto-invest-images/mapAutoInvestImages'
import LimitReachedModal from '~/sections/invest/sections/auto-invest/widgets/limit-reached-warning/LimitReachedModal'
import actions from '~/store/autoinvest/actions'
import {PremadeOrderEnhanced, selectPremadeOrder} from '~/store/autoinvest/selectors'
import {useAppSelector, useAppDispatch} from '~/store/hooks'

const AddOrder: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const colourMode = useColourMode()

    const [showLimitReachedModal, setShowLimitReachedModal] = React.useState<boolean>(false)

    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const [premadeOrders] = usePremadeOrders()
    const isDependent = useAppSelector(s => s.identity.user?.is_dependent)

    const [allCurrentOrders, allLoaded] = useAutoinvestOrders()

    const currentDiyOrders = allCurrentOrders.filter(order => !order.premade_order_id)
    const currentPremadeOrder: Model.AutoinvestOrder | undefined = useAppSelector(s => selectPremadeOrder(s))

    const handleDiyCreateCardClick = () => {
        // First check if they have reached the max number of DIY orders
        if (currentDiyOrders.length === MAX_DIY_ORDERS[jurisdiction].num) {
            setShowLimitReachedModal(true)
            return
        }

        dispatch(actions.StageNewDIY())

        rudderTrack('autoinvest', 'autoinvest_type_selected', {
            autoinvest_type: 'diy',
        })

        rudderTrack('browse', 'search_page_hit', {
            navigated_from: 'autoinvest-diy-create-card',
        })

        navigate(profileUrl('auto-invest/diy/search'))
    }

    const handlePremadeCreateCardClick = (order: PremadeOrderEnhanced) => {
        Analytics.event({
            category: 'AutoInvest',
            action: 'View Auto Invest Order',
            label: order.name,
        })
        navigate(
            profileUrl('auto-invest/:premadeSlug', {
                premadeSlug: order.slug,
            }),
        )
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--auto-invest-landing" leftButton="back" title="Add an order" />
            <Page withoutDefaultPadding>
                {!allLoaded && <Loading />}
                {allLoaded && (
                    <div className={styles.pageContainer}>
                        <p className={spacing.spaceBelow6}>
                            Put your investing on repeat. Just choose your order, then set how much and how often you’d
                            like to invest.{' '}
                        </p>
                        <p className={spacing.spaceBelow12}>
                            <Link to={profileUrl('explore/learn/:slug', {slug: 'auto-invest'})}>
                                Learn about order types
                            </Link>
                        </p>
                        <h3 className={spacing.spaceBelow12}>DIY order</h3>
                        <p className={spacing.spaceBelow6}>
                            Create up to {MAX_DIY_ORDERS[jurisdiction].word} separate DIY orders from a range of US,
                            Aussie and NZ investments.
                        </p>
                        <p className={spacing.spaceBelow12}>
                            <Link to={profileUrl('auto-invest/intro')} className={spacing.spaceBelow24}>
                                See how it works
                            </Link>
                        </p>
                        <button
                            className={cn(styles.orderCard, accessibility.button)}
                            data-testid="diy-order"
                            onClick={handleDiyCreateCardClick}
                            type="button"
                        >
                            <img
                                className={styles.orderImg}
                                src={getImageForAutoInvestOrder('diy', colourMode).thumbnailImage}
                                alt=""
                            />
                            <div className={styles.orderHeaders}>
                                <h3>
                                    <AutoInvest className={styles.autoinvestIcon} />
                                    <span>DIY order</span>
                                </h3>
                                <h4>Choose your own</h4>
                            </div>
                        </button>
                        <h3 className={cn(spacing.spaceAbove40, spacing.spaceBelow8)}>Pre-made orders</h3>
                        {premadeOrders
                            .filter(o => currentPremadeOrder?.id !== o.id)
                            .map(order => {
                                if ((order.for_kids && isDependent) || !order.for_kids) {
                                    return (
                                        <button
                                            type="button"
                                            key={order.id}
                                            className={cn(styles.orderCard, accessibility.button)}
                                            data-testid={`button--${convertToKebabCase(order.name.toLowerCase())}`}
                                            onClick={() => {
                                                handlePremadeCreateCardClick(order)
                                            }}
                                        >
                                            <img
                                                className={styles.orderImg}
                                                src={getImageForAutoInvestOrder(order.name, colourMode).thumbnailImage}
                                                alt=""
                                            />
                                            <div className={styles.orderHeaders}>
                                                <h3>
                                                    <AutoInvest className={styles.autoinvestIcon} />
                                                    <span>{order.name} order</span>
                                                </h3>
                                                <h4>{order.allocations.length} investments</h4>
                                            </div>
                                        </button>
                                    )
                                }
                            })}
                        <p className={cn(spacing.spaceAbove24, styles.newDisclaimer)}>
                            We don’t give financial advice. You need to consider if a pre-made or DIY order is right for
                            you. If you’re unsure, you should speak to a licensed financial advisor.
                        </p>
                    </div>
                )}

                <LimitReachedModal
                    isOpen={showLimitReachedModal}
                    setIsOpen={setShowLimitReachedModal}
                    jurisdiction={jurisdiction}
                />
            </Page>
        </>
    )
}

export default AddOrder
