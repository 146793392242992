import {colour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import React from 'react'
import {useNavigate} from 'react-router'
import {Navigate} from 'react-router-dom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ProductIntroSlideContent} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {ProductIntroSlides} from '~/global/widgets/intro-slides/IntroSlides'
import inTheBank from '~/sections/save/assets/images/in-the-bank.png'
import saveLarge from '~/sections/save/assets/images/save-hero.png'
import {useAppSelector, useAppDispatch} from '~/store/hooks'
import saveActions from '~/store/save/actions'
import styles from './Intro.scss'

export const Intro: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const profile = useProfile()
    const colourMode = useColourMode()

    const hasSaveFlag = useAppSelector(s => s.identity.flags.savings) // Flag for AU residents only
    const saveAccounts = profile.portfolios.filter(p => p.product === 'SAVE')
    const interestRate = useAppSelector(s => s.save.interestRate)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)

    const bankDetails = jurisdiction === 'nz' ? 'AA- rated registered NZ-based' : 'A+ rated Australian'

    const content: ProductIntroSlideContent[] = [
        {
            backgroundColour: colour('Strawberry100', colourMode),
            image: saveLarge,
            title: 'Save without limits',
            buttonLabel: 'Sounds good',
            bodyRenderer: () => (
                <>
                    <p className={spacing.spaceBelow24}>
                        Get a great {interestRate}% p.a. interest rate and all the flexibility you need—separate from
                        spending but handy when you need it.
                    </p>
                    <p className={spacing.spaceBelow24}>Interest is calculated daily, and paid to you monthly.</p>
                    <p>No minimum deposit, no penalties, no fees, and no fuss to open.</p>
                </>
            ),
        },
        {
            backgroundColour: colour('Strawberry100', colourMode),
            image: inTheBank,
            imageAdditionalClassName: styles.benefitsImage,
            title: 'It’s in the bank',
            buttonLabel: 'Get started',
            bodyRenderer: () => (
                <div>
                    <p className={spacing.spaceBelow24}>
                        Plus, your money’s held in trust with an {bankDetails} bank to get a great interest rate on your
                        behalf.
                    </p>
                    <p>It gives your money a new place to grow, with access anytime.</p>
                </div>
            ),
        },
    ]

    React.useEffect(() => {
        dispatch(saveActions.getInterestRate())
    }, [])

    if (jurisdiction !== 'nz' && !hasSaveFlag) {
        return <Navigate to={profileUrl('')} replace />
    }

    // Navigate to Save personalise for new account if has existing account(s)
    if (saveAccounts.length) {
        return <Navigate to={profileUrl('save/personalise-account')} replace />
    }

    return (
        <ProductIntroSlides
            content={content}
            onComplete={() => {
                rudderTrack('save', 'onboarding_intro_clicked')
                navigate(profileUrl('save/personalise-account'))
            }}
        />
    )
}

export default Intro
