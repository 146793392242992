import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {useRetailGet} from '~/api/query/retail'
import {Request, Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import calculateHistoryHeading from '~/global/utils/calculate-history-heading/calculateHistoryHeading'
import Chips from '~/global/widgets/chips/Chips'
import {Loading} from '~/global/widgets/loading'
import {TransactionRowSkeleton} from '~/global/widgets/transaction-row/TransactionRow'
import {WalletPaymentRequest} from '~/sections/wallet/pages/overview/widgets/transaction-history/PaymentRequest'
import {WalletTransaction} from '~/sections/wallet/pages/overview/widgets/transaction-history/Transaction'
import {useHistoryState, useWalletPortfolio} from '~/sections/wallet/state/local'
import styles from './TransactionHistory.scss'

type FilterOption = Response.AccountingTransactionHistoryV2['filter_options'][number]

/**
 * Given a filter option, return a display label for it
 */
const filterDisplayLabel = (option: FilterOption): string => {
    switch (option) {
        case 'buy':
            return 'Buys'
        case 'sell':
            return 'Sells'
        case 'dividend':
            return 'Dividends'
        case 'money_exchange':
            return 'Money exchange'
        case 'money_in':
            return 'Money in'
        case 'money_out':
            return 'Money out'
    }
}

/**
 * Given an filter option return the index it should be sorted at
 * Sorts unknown options to the end of the list
 *
 * @example
 * filterOptionList.sort((a, b) => filterOrderer(a) - filterOrderer(b))
 */
const filterOrderer = (option: FilterOption): number => {
    const index = ['buy', 'sell', 'dividend', 'money_in', 'money_out', 'money_exchange'].indexOf(option)

    if (index === -1) {
        return Infinity
    }
    return index
}

/**
 * Renders a list of transactions for the active WALLET portfolio
 */
export const TransactionHistory: React.FunctionComponent<{}> = () => {
    const walletPortfolio = useWalletPortfolio()
    const [selectedFilter, setSelectedFilter] = useHistoryState<FilterOption | undefined>(
        'transaction-history-filter',
        undefined,
    )
    const rowToFocus = React.useRef<HTMLButtonElement>(null)

    const [payload, setPayload] = React.useState<Request.WalletTransactions>({
        filter: selectedFilter,
        limit: 50,
    })
    const [isFilterPending, startFilterTransition] = React.useTransition()
    const [isLoadMorePending, startLoadMoreTransition] = React.useTransition()

    const {
        data: {payment_requests: paymentRequests},
    } = useRetailGet({
        path: 'wallet/:portfolio_id/pending-payment-requests',
        pathParams: {portfolio_id: walletPortfolio.id},
    })

    const {data: transactionsPage} = useRetailGet({
        path: 'wallet/:portfolio_id/transactions',
        payload,
        pathParams: {portfolio_id: walletPortfolio.id},
        options: {
            staleTime: 0, // we want the latest data whenever we hit this screen, update in the background
        },
    })
    // TODO - we could improve the experience of loading the detail pages by updating individual cache items here

    const [transactions, setTransactions] = React.useState(transactionsPage.transactions)
    React.useEffect(() => {
        const seenKeys = new Set<string>()
        const loadedPlusIncoming = transactions
            .concat(transactionsPage.transactions)
            .filter(t => {
                if (!seenKeys.has(t.key)) {
                    seenKeys.add(t.key)
                    return true
                }
                return false
            })
            .sort((a, b) => b.timestamp.toMillis() - a.timestamp.toMillis()) // re-sort by timestamp as 'new' (just completed) transactions need to go to the top not the end
        setTransactions(loadedPlusIncoming)
    }, [transactionsPage])

    /** Available filter options sorted into display order */
    const filterOptions = [...transactionsPage.filter_options].sort((a, b) => filterOrderer(a) - filterOrderer(b))

    /** Track which transaction history headings have already been displayed */
    const seenHeadings = new Set<string>()

    return (
        <>
            <h2 className={cn(styles.transactionsHeading, spacing.spaceAbove16)}>Transaction history</h2>
            {filterOptions.length > 0 && (
                <div className={cn(spacing.spaceAbove8, spacing.spaceBelow8)}>
                    <Chips
                        // This is pretty ugly because the Chips component can't deal with any sort of value/label mapping nicely
                        // At some refactoring it to allow that could make this much tidier
                        options={filterOptions.map(filterDisplayLabel)}
                        onChipClick={value => {
                            const filter =
                                value === 'all'
                                    ? undefined
                                    : filterOptions[filterOptions.map(filterDisplayLabel).indexOf(value)]
                            setSelectedFilter(filter)
                            setTransactions([])
                            startFilterTransition(() =>
                                setPayload(payload => ({...payload, filter, before_key: undefined})),
                            )
                        }}
                        selected={selectedFilter ? [filterDisplayLabel(selectedFilter)] : []}
                        isInlineDisplay
                        hasAllOption
                    />
                </div>
            )}
            {isFilterPending ? (
                <div className={styles.emptySection}>
                    <Loading />
                </div>
            ) : (
                <>
                    {(!selectedFilter || selectedFilter === 'money_in') && paymentRequests.length > 0 && (
                        // For "All" or "Money in" we'll also display incoming payment requests
                        <>
                            <p className={styles.timeUnitHeading}>Pending</p>
                            {paymentRequests.map(pr => (
                                <WalletPaymentRequest key={pr.id} paymentRequest={pr} />
                            ))}
                        </>
                    )}
                    {transactions.map((txn, index) => {
                        const isLastResult = index === transactions.length - 1
                        const heading = calculateHistoryHeading(txn.timestamp)
                        const row = (
                            <React.Suspense key={index} fallback={<TransactionRowSkeleton />}>
                                <WalletTransaction txn={txn} ref={isLastResult ? rowToFocus : undefined} />
                            </React.Suspense>
                        )

                        if (seenHeadings.has(heading)) {
                            return row
                        } else {
                            seenHeadings.add(heading)
                            return (
                                <div key={index}>
                                    <p className={styles.timeUnitHeading}>{heading}</p>
                                    {row}
                                </div>
                            )
                        }
                    })}
                </>
            )}
            {transactionsPage.has_more && (
                <Button
                    dataTestId="button--load-older-transactions"
                    label="Load older transactions"
                    onClick={() => {
                        // focus the last row so after more is loaded the screen doesn't jump down
                        if (rowToFocus && rowToFocus.current) {
                            rowToFocus.current.focus()
                        }
                        startLoadMoreTransition(() =>
                            setPayload(payload => ({
                                ...payload,
                                before_key: transactionsPage.transactions.at(-1)!.key,
                            })),
                        )
                    }}
                    processing={isLoadMorePending}
                    additionalClassName={spacing.spaceAbove24}
                />
            )}
        </>
    )
}
