import Decimal from 'decimal.js'

interface ShareValues {
    numberOfShares: string
    averagePricePaidPerShare?: string
}

const calculateSharesRemaining = (values: ShareValues, holdingShares?: string) => {
    const holdingSharesDecimal = holdingShares ? new Decimal(holdingShares) : new Decimal(0)
    const sharesValueDecimal = values.numberOfShares ? new Decimal(values.numberOfShares) : new Decimal(0)
    const zero = new Decimal(0)

    const sharesRemaining = holdingSharesDecimal.minus(sharesValueDecimal).lessThan(zero)
        ? zero
        : holdingSharesDecimal.minus(sharesValueDecimal)

    return sharesRemaining.toString()
}

export default calculateSharesRemaining
