import React from 'react'
import {Link, Navigate} from 'react-router-dom'
import {logCardDismissal} from '~/api/braze/braze'
import {Response} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {SwitchField} from '~/global/widgets/form-controls'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/identity/actions'
import styles from './Notifications.scss'

type NotificationCategories = Response.IdentityNotificationPreferencesV2['categories']
type NotificationCategory = NotificationCategories[0]
type NotificationChannelData = NotificationCategory['channels'][0]
type NotificationChannel = NotificationChannelData['channel']

interface NotificationItemProps {
    category: NotificationCategory
    index: number
    handlePreferenceUpdate: (key: string, channel: NotificationChannel, subscribed: boolean) => void
}

const NotificationItem: React.FC<NotificationItemProps> = ({category, index, handlePreferenceUpdate}) => {
    return (
        <div key={category.id} className={styles.notificationSection}>
            {index > 0 && (
                <div className={styles.divider}>
                    <hr />
                </div>
            )}
            <h2>{category.name}</h2>
            <p>{category.description}</p>
            {category.channels.map((channel, key) => (
                <div className={styles.toggle} key={key}>
                    <SwitchField
                        dataTestId={`switch--${channel.channel}-${category.id}`}
                        additionalClassName={styles.switchStyles}
                        label={channel.channel === 'EMAIL' ? 'Email' : 'Notification'}
                        name={category.id}
                        onChange={() => {
                            rudderTrack('notification_preferences', 'change_preference', {
                                notification_preference: category.id,
                                source: 'notification_settings_page',
                                channel: channel.channel === 'NOTIFICATION' ? 'notification' : 'email',
                                updated_subscription_state: channel.subscribed ? 'unsubscribed' : 'subscribed',
                            })
                            handlePreferenceUpdate(category.id, channel.channel, !channel.subscribed)
                        }}
                        value={channel.subscribed}
                        isTouched={false}
                        disabled={!channel.can_unsubscribe}
                    />
                </div>
            ))}
            {category.detail_link_suffix && category.detail_link_text && (
                <Link to={category.detail_link_suffix}>
                    <p>{category.detail_link_text}</p>
                </Link>
            )}
        </div>
    )
}

export const Notifications = () => {
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const notificationPreferences = useAppSelector(s => s.identity.notificationPreferences)
    const notifications = useAppSelector(s => s.identity.notifications)
    const [localPrefs, setLocalPrefs] = React.useState<NotificationCategories>(notificationPreferences)

    const handlePreferenceUpdate = (
        notificationCategory: string,
        channel: NotificationChannel,
        subscribed: boolean,
    ): void => {
        // Update local prefs so we can display update immediately
        const newPrefs = localPrefs.slice()

        newPrefs.forEach((category: NotificationCategory, categoryKey) => {
            category.channels.forEach((channelData: NotificationChannelData, channelKey) => {
                if (category.id === notificationCategory && channelData.channel === channel) {
                    newPrefs[categoryKey].channels[channelKey].subscribed = subscribed
                }
            })
        })

        // Remove any content cards matching that category from the notifications feed.
        notifications.notificationsPageCards.forEach(card => {
            if (card.extras && 'category' in card.extras && card.extras.category === notificationCategory) {
                logCardDismissal(card)
            }
        })

        setLocalPrefs(newPrefs)
        dispatch(actions.UpdateNotificationPreference(notificationCategory, channel, subscribed))
    }

    React.useEffect(() => {
        /* Initial page load will fetch preferences from the API */
        dispatch(actions.GetNotificationPreferences())
    }, [])

    React.useEffect(() => {
        /* After initial page load; update local preferences to reflect API state */
        if (notificationPreferences.length) {
            setLocalPrefs(notificationPreferences)
        }
    }, [notificationPreferences])

    const toolBar = <Toolbar dataTestId="toolbar--notifications" leftButton="back" title="Notification settings" />

    if (!notificationPreferences.length) {
        return (
            <>
                {toolBar}
                <Page>
                    <Loading />
                </Page>
            </>
        )
    }
    return (
        <>
            {toolBar}
            <Page className={styles.pageContainer}>
                {localPrefs.map((category, index) => {
                    let output = (
                        <NotificationItem
                            key={index}
                            category={category}
                            index={index}
                            handlePreferenceUpdate={handlePreferenceUpdate}
                        />
                    )

                    // Apologies for this untidy intrusion
                    if (category.id === 'account_updates') {
                        output = (
                            <div key={category.id}>
                                <div className={styles.notificationSection}>
                                    {index > 0 && (
                                        <div className={styles.divider}>
                                            <hr />
                                        </div>
                                    )}
                                    <h2>Price notifications</h2>
                                    <p className={styles.shortGap}>When investments reach a price you’ve set.</p>
                                    <p>
                                        <Link to={profileUrl('settings/notifications/price')}>
                                            See your price notifications
                                        </Link>
                                    </p>
                                </div>
                                {output}
                            </div>
                        )
                    }

                    return output
                })}
            </Page>
        </>
    )
}

export default () => {
    const profileUrl = useProfileUrl()
    const profile = useProfile()

    if (profile.legacy_profile_type === undefined) {
        return <Navigate to={profileUrl('')} replace />
    }

    return <Notifications />
}
