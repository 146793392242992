import {Close, Search} from '@design-system/icon'
import cn from 'classnames'
import React, {useEffect} from 'react'
import {throttle, debounce} from 'throttle-debounce'
import {accessibility} from '~/global/scss/helpers'
import {Loading} from '~/global/widgets/loading'
import {CommonProps, commonClassnames, withFocus, FocusInjectedProps, withId} from '../common'
import styles from './SearchInput.scss'

type SearchProps = CommonProps<string> &
    FocusInjectedProps & {
        onQueryChange: (value: string) => void
        setCurrentSearchInput: (input: string) => void
        initialValue: string
        iconRightClick?: () => void
        isLoading?: boolean
        autoFocus?: boolean
    }

export const SearchInput = (props: SearchProps) => {
    const maxCharactersThrottle = 5 // maximum amount of chars of input for search query to be in throttle 'mode'
    const [liveQuery, setLiveQuery] = React.useState<string>(props.initialValue || '')
    const [showSpinner, setShowSpinner] = React.useState(false)
    const [hasEnteredThrottleDebounce, setHasEnteredThrottleDebounce] = React.useState(false)

    const throttled = React.useRef(
        throttle(800, (value: string) => {
            executeQuery(value)
        }),
    )
    const debounced = React.useRef(
        debounce(800, (value: string) => {
            executeQuery(value)
        }),
    )

    const executeQuery = (query: string) => {
        setHasEnteredThrottleDebounce(true)
        props.onQueryChange(query)
    }

    useEffect(() => {
        setShowSpinner(false)
        setHasEnteredThrottleDebounce(false)
    }, [hasEnteredThrottleDebounce && !props.isLoading])

    useEffect(() => {
        props.setCurrentSearchInput(props.initialValue)
    }, [])

    const handleOnChange = (value: string) => {
        setShowSpinner(true)
        setLiveQuery(value)
        props.setCurrentSearchInput(value)
        // if user's input is short, throttle the input
        if (value.length < maxCharactersThrottle) {
            throttled.current(value)
        }
        // else, user must be inputing long value, so only query the api when there is a sufficient pause after last input
        else {
            debounced.current(value)
        }
    }

    const inputRef = React.createRef<HTMLInputElement>()
    return (
        <div className={styles.searchInput}>
            <div
                className={commonClassnames(props, styles.text, {
                    [styles.focus]: props.isFocused,
                })}
            >
                <div className={cn(styles.control)}>
                    {liveQuery.length === 0 && !showSpinner && !props.isFocused && (
                        <Search className={styles.searchIcon} />
                    )}
                    <form
                        onSubmit={event => {
                            event.preventDefault()
                            if (inputRef.current) {
                                inputRef.current.blur()
                            }
                        }}
                        data-testid="search-input"
                    >
                        <input
                            ref={inputRef}
                            id="search"
                            className={cn({[styles.isTouched]: !!liveQuery}, styles.input)}
                            name={props.name}
                            autoComplete="off"
                            autoFocus={props.autoFocus}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            value={liveQuery}
                            onChange={e => handleOnChange(e.currentTarget.value)}
                            placeholder="Search"
                        />
                    </form>
                    {showSpinner && (
                        <div className={styles.loader}>
                            <Loading />
                        </div>
                    )}
                    {!showSpinner && liveQuery.length > 0 && (
                        <button
                            type="button"
                            className={cn(accessibility.button, styles.closeButton)}
                            aria-label="Clear search input"
                            onClick={() => {
                                setLiveQuery('')
                                props.setCurrentSearchInput('')
                                executeQuery('') // executing the query for empty string, reset results to default.
                                if (inputRef && inputRef.current) {
                                    inputRef.current.focus()
                                }
                            }}
                        >
                            <Close size={12} />
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

const SearchInputWithFocus = withId(withFocus(SearchInput))

export default SearchInputWithFocus
