import React from 'react'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {Loading} from '~/global/widgets/loading/Loading'
import {OrderCarousel} from '~/sections/invest/widgets/order-carousel/OrderCarousel'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'
import actions from '~/store/transfer/actions'

/**
 * View a group of transfers, in terminal states.
 */

export const ViewTransfers: React.FunctionComponent<{transferOrderGroupId: string}> = ({transferOrderGroupId}) => {
    const dispatch = useAppDispatch()
    const getInstrumentsByIds = (instrumentIds: string[]) =>
        dispatch(instrumentActions.getInstrumentsByIds(instrumentIds))

    const transferLoadingState = useAppSelector(({transfer}) => transfer.transfersLoadingState)
    const [isLoading, setIsLoading] = React.useState(transferLoadingState === 'loading')

    const transferOrders = useAppSelector(({transfer}) => transfer.transferOrders)
    const instruments = useAppSelector(({instrument}) => instrument.instrumentsById)

    React.useEffect(() => {
        const instrumentIdsToLoad = transferOrders.reduce<string[]>((instrumentIds, transferOrder) => {
            const transferFundId = transferOrder.fund_id
            if (!instruments[transferFundId]) {
                return [...instrumentIds, transferFundId]
            }
            return instrumentIds
        }, [])

        if (transferLoadingState === 'loading') {
            setIsLoading(true)
            dispatch(actions.FetchTransfers())
        }

        if (transferLoadingState === 'ready' && instrumentIdsToLoad.length > 0) {
            getInstrumentsByIds(instrumentIdsToLoad)
        }

        if (['ready', 'error'].includes(transferLoadingState) && instrumentIdsToLoad.length === 0) {
            setIsLoading(false)
        }
    }, [transferOrders, transferLoadingState, instruments])

    if (isLoading) {
        return <Loading isPineapple />
    }

    const transferGroupOrders = transferOrders.filter(transferOrder => transferOrder.group_id === transferOrderGroupId)
    if (!transferGroupOrders) {
        return <WeSlippedUp />
    }
    return <OrderCarousel orders={transferGroupOrders} carousel />
}

export default ViewTransfers
