import React from 'react'
import {useRetailGet} from '~/api/query/retail'
import {Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import InstrumentRowCompact from '~/global/widgets/instrument-row/InstrumentRowCompact'
import {SharePriceValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useDistillInstrumentWithCurrency} from '~/sections/moose/data/distill'
import {getPriceNotificationsQueryArgs} from '~/sections/user/sections/settings/sections/notifications/pages/price-notifications/utils/get-price-notifications-query-args/getPriceNotificationsQueryArgs'
import {DeleteNotifications} from '~/sections/user/sections/settings/sections/notifications/pages/price-notifications/widgets/delete-notifications/DeleteNotifications'
import {EditNotification} from '~/sections/user/sections/settings/sections/notifications/pages/price-notifications/widgets/edit-notification/EditNotification'
import {Instrument} from '~/store/instrument/types'

interface PriceNotificationRowProps {
    instrument: Instrument
    portfolioId: string
    priceNotification?: Response.PriceNotifications['price_notifications'][0]
}

const PriceNotificationRow: React.FunctionComponent<PriceNotificationRowProps> = ({
    instrument,
    portfolioId,
    priceNotification,
}) => {
    return (
        <InstrumentRowCompact
            instrument={instrument}
            dataTestId={`price-notification--${instrument.id}`}
            mainTitleOverride={
                <>
                    {instrument.name}{' '}
                    {instrument.symbol && instrument.exchange && (
                        <>
                            ({instrument.symbol}:{instrument.exchange})
                        </>
                    )}
                </>
            }
            subTitleOverride={
                <>
                    {priceNotification ? (
                        <>
                            Set to{' '}
                            <SharePriceValue value={priceNotification.price_threshold} currency={instrument.currency} />
                        </>
                    ) : (
                        <>None set</>
                    )}
                </>
            }
            rowTopBorder
            rightSlot={
                <EditNotification
                    priceNotification={priceNotification}
                    instrument={instrument}
                    portfolioId={portfolioId}
                />
            }
        />
    )
}

interface ManagePriceNotificationsProps {
    portfolioId: string
}

export const ManagePriceNotifications: React.FunctionComponent<ManagePriceNotificationsProps> = ({portfolioId}) => {
    const [fcg, fsf] = [useDistillInstrumentWithCurrency('nzx-fcg'), useDistillInstrumentWithCurrency('fsf')]

    const {data} = useRetailGet(getPriceNotificationsQueryArgs(portfolioId))

    const priceNotifications = data.price_notifications

    return (
        <>
            <Toolbar dataTestId="toolbar--price-notification-settings" leftButton="back" title="Price notifications" />

            <Page overrideDefaultTopPadding="withToolbarTitle">
                <p className={spacing.spaceBelow24}>
                    Receive a notification when an investment reaches a price you set.
                </p>
                {fsf && (
                    <PriceNotificationRow
                        instrument={fsf}
                        priceNotification={fsf.id in priceNotifications ? priceNotifications[fsf.id] : undefined}
                        portfolioId={portfolioId}
                    />
                )}
                {fcg && (
                    <PriceNotificationRow
                        instrument={fcg}
                        priceNotification={fcg.id in priceNotifications ? priceNotifications[fcg.id] : undefined}
                        portfolioId={portfolioId}
                    />
                )}
                {Object.keys(priceNotifications).length > 0 && <DeleteNotifications portfolioId={portfolioId} />}
            </Page>
        </>
    )
}
