import {Button} from '@design-system/button'
import {ArrowUp} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {findDOMNode} from 'react-dom'
import {VariableSizeList as List} from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import smoothscroll from 'smoothscroll-polyfill'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import withMeasure, {MeasureProps} from '~/global/widgets/with-measure/WithMeasure'
import {useNavigate} from '~/migrate-react-router'
import {connect} from '~/store/connect'
import identityActions from '~/store/identity/actions'
import actions from '~/store/instrument/actions'
import {State} from '~/store/instrument/types'
import SearchResultsRow, {SearchResultRowData} from './SearchResultsRow'
import styles from './SearchResults.scss'

/*
In this component, we use `react-window` and `react-window-infinite-loader`
libraries to make the infinite scroll works smoothly by only
rendering part of a large data set.

We consider the whole browser as `one window` and render the search bar as the
first item of the list. In this way, the user can scroll the entire page
but not just the search results.
*/

const SearchResults: React.FunctionComponent<SearchResultsProps> = React.memo(
    ({
        currentQuery,
        results,
        instrumentsById,
        hasNextPage,
        currentTimePeriod,
        currentSort,
        loadNextPageSearchResults,
        loadNextPageAutoInvestSearchResults,
        setRecentlyViewedInstrument,
        setScrollDirection,
        measuredWidth,
        measuredHeight,
        onMeasureRef,
        resultsLoadingState,
        headerHeight,
        setIsOnTop,
        searchInitialisedFor,
        searchScrollItemIndex,
        setSearchScrollItemIndex,
        isAutoInvest,
        autoInvestButtonRef,
    }) => {
        const [showScrollToTopButton, setShowScrollToTopButton] = React.useState<boolean | null>(null)
        const [isScrolling, setIsScrolling] = React.useState<boolean>(false)
        const listRef = React.createRef<List>()
        const infiniteLoaderRef = React.useRef<InfiniteLoader>(null)
        const navigate = useNavigate()
        const profileUrl = useProfileUrl()

        const listingHeight = isAutoInvest ? 88 : 94 // instrument row height is this value minus padding bottom 8px
        const isItemLoaded = (index: number) => !hasNextPage || index < results.length + 1 // Every row is loaded except for our loading indicator row
        let noResults = false

        React.useEffect(() => {
            if (searchInitialisedFor && resultsLoadingState === 'ready' && results.length === 0) {
                noResults = true

                // track 'no result' results
                Analytics.event({
                    category: 'Search',
                    action: 'No results',
                    label: currentQuery,
                    nonInteraction: true,
                })
            }
        }, [searchInitialisedFor, resultsLoadingState, results])

        const itemCount = React.useMemo(() => {
            if ((resultsLoadingState === 'loading' || !searchInitialisedFor) && measuredHeight && headerHeight) {
                // Number of skeleton instrument rows
                return Math.ceil((measuredHeight - headerHeight) / listingHeight)
            }

            if (noResults) {
                return 2 // search header + no result
            }

            // If there are more items to be loaded then add an extra row to hold a loading indicator, then add 1 for the search header
            return (hasNextPage ? results.length + 1 : results.length) + 1
        }, [resultsLoadingState, measuredHeight, headerHeight, hasNextPage, results, searchInitialisedFor])

        const onSearchResultItemClick = (index: number, instrumentId: string) => {
            setSearchScrollItemIndex(index)

            // google analytics search event
            Analytics.event({
                category: 'Search',
                action: 'Clicked result',
                label: currentQuery,
                nonInteraction: true,
            })
            // track event to retail for 'recently viewed'
            setRecentlyViewedInstrument(instrumentId)

            rudderTrack('browse', 'search_result_clicked', {instrument_id: instrumentId, search_rank: index})

            const urlSlug = instrumentsById[instrumentId].urlSlug
            const isExploreUrl = location.pathname.includes('explore')
            const isInvestSearchUrl = location.pathname.includes('invest/search')

            navigate(
                isAutoInvest
                    ? profileUrl('auto-invest/diy/search/:instrumentSlug', {instrumentSlug: urlSlug})
                    : isExploreUrl
                      ? profileUrl('invest/search/:instrumentSlug', {
                            instrumentSlug: urlSlug,
                        })
                      : isInvestSearchUrl
                        ? profileUrl('invest/search/:instrumentSlug', {
                              instrumentSlug: urlSlug,
                          })
                        : profileUrl('invest/:instrumentSlug', {
                              instrumentSlug: urlSlug,
                          }),
            )
        }

        // Return the same item data if the argument hasn't changed
        // Make sure not passing any data which will be updated on every scroll
        const itemData: SearchResultRowData = React.useMemo(
            () => ({
                instrumentsById,
                results,
                currentTimePeriod,
                currentSort,
                currentQuery,
                isItemLoaded,
                resultsLoadingState,
                measuredWidth,
                instrumentInitialised: searchInitialisedFor !== undefined,
                onSearchResultItemClick,
                isAutoInvest,
                autoInvestButtonRef,
            }),
            [
                instrumentsById,
                results,
                currentTimePeriod,
                currentSort,
                currentQuery,
                isItemLoaded,
                resultsLoadingState,
                measuredWidth,
                searchInitialisedFor,
                onSearchResultItemClick,
                isAutoInvest,
                autoInvestButtonRef,
            ],
        )

        React.useEffect(() => {
            if (listRef.current && searchScrollItemIndex) {
                // scrolling to where the user was before
                listRef.current.scrollToItem(searchScrollItemIndex, 'center')
                setSearchScrollItemIndex(0)
            }
        }, [listRef])

        React.useEffect(() => {
            if (listRef.current && !isScrolling) {
                // React Window library caches the item size to avoid having to perform unnecessary calculations
                // When the user modifies the search filter, the height of header will be changed
                // then we call the `resetAfterIndex` method to ask the React Window library to re-get item size
                listRef.current.resetAfterIndex(0)
            }
        }, [headerHeight])

        return (
            <div className={cn(styles.searchResults, {[styles.autoInvestBar]: isAutoInvest})} ref={onMeasureRef}>
                {measuredHeight && measuredWidth && headerHeight && (
                    <InfiniteLoader
                        isItemLoaded={isItemLoaded}
                        itemCount={itemCount}
                        loadMoreItems={() =>
                            new Promise<void>(resolve => {
                                isAutoInvest ? loadNextPageAutoInvestSearchResults() : loadNextPageSearchResults()
                                resolve()
                            })
                        }
                        ref={infiniteLoaderRef}
                    >
                        {({onItemsRendered, ref}) => (
                            <div className={styles.searchWrapper} ref={ref}>
                                <List
                                    width={measuredWidth}
                                    height={measuredHeight}
                                    itemSize={index =>
                                        index === 0
                                            ? headerHeight
                                            : noResults
                                              ? measuredHeight - headerHeight
                                              : listingHeight
                                    }
                                    itemCount={itemCount}
                                    onItemsRendered={onItemsRendered}
                                    itemData={itemData}
                                    onScroll={({scrollDirection, scrollOffset}) => {
                                        if (
                                            !showScrollToTopButton &&
                                            measuredHeight &&
                                            scrollOffset > measuredHeight * 1.5
                                        ) {
                                            setShowScrollToTopButton(true)
                                        }
                                        if (
                                            showScrollToTopButton &&
                                            measuredHeight &&
                                            scrollOffset <= measuredHeight * 1.5
                                        ) {
                                            setShowScrollToTopButton(false)
                                        }

                                        if (scrollOffset === 0) {
                                            setIsOnTop(true)
                                            setIsScrolling(false)
                                        } else {
                                            setIsOnTop(false)
                                        }

                                        if (scrollDirection === 'backward' && scrollOffset !== 0) {
                                            setScrollDirection('backward')
                                            setIsScrolling(true)
                                        }

                                        if (scrollDirection === 'forward' && scrollOffset !== 0) {
                                            setScrollDirection('forward')
                                            setIsScrolling(true)
                                        }
                                    }}
                                    ref={listRef}
                                >
                                    {
                                        // Make sure naming this pure component instead of using an anonymous function
                                        // Otherwise React will always re-render search result rows
                                        // because it is unable to compare to the previous function
                                        SearchResultsRow
                                    }
                                </List>
                                <Button
                                    additionalClassName={cn(styles.scrollToTopButton, {
                                        [styles.buttonFadeIn]: showScrollToTopButton,
                                        [styles.buttonFadeOut]: showScrollToTopButton === false,
                                    })}
                                    dataTestId="search-results-button"
                                    label={<ArrowUp />}
                                    width="auto"
                                    ariaLabel="Scroll back up"
                                    onClick={e => {
                                        e.preventDefault()
                                        if (listRef.current) {
                                            smoothscroll.polyfill() // kick off the polyfill to make smooth scroll work on Safari

                                            // Getting a scrollable container using ref because react-window library scroll method does not support a smooth scroll
                                            // eslint-disable-next-line react/no-find-dom-node
                                            const scrollableContainer = findDOMNode(listRef.current) as HTMLDivElement
                                            if (scrollableContainer) {
                                                scrollableContainer.scrollTo({
                                                    behavior: 'smooth',
                                                    top: 0,
                                                })
                                            }
                                        }
                                    }}
                                />
                            </div>
                        )}
                    </InfiniteLoader>
                )}
            </div>
        )
    },
)

type SearchResultsProps = StoreProps & DispatchProps & OwnProps & MeasureProps

interface StoreProps {
    results: State['resultsIndex']
    instrumentsById: State['instrumentsById']
    hasNextPage: boolean
    currentQuery: State['currentSearchQuery']
    currentTimePeriod: State['currentSearchTimePeriod']
    currentSort: string
    resultsLoadingState: State['resultsLoadingState']
    searchInitialisedFor: State['searchInitialisedFor']
    searchScrollItemIndex: State['searchScrollItemIndex']
}

interface DispatchProps {
    loadNextPageSearchResults(): void
    loadNextPageAutoInvestSearchResults(): void
    setRecentlyViewedInstrument(instrumentId: string): void
    setSearchScrollItemIndex(itemIndex: number): void
}

interface OwnProps {
    setScrollDirection(direction: 'backward' | 'forward'): void
    setIsOnTop(isOnTop: boolean): void
    headerHeight?: number
    isAutoInvest?: boolean
    autoInvestButtonRef?: React.RefObject<HTMLButtonElement>
}

export const ConnectedSearchResults = connect<StoreProps, DispatchProps, OwnProps & MeasureProps>(
    state => {
        const {instrument} = state
        return {
            results: instrument.resultsIndex,
            instrumentsById: instrument.instrumentsById,
            hasNextPage:
                instrument.currentResultsPage && instrument.numberOfResultsPages
                    ? instrument.currentResultsPage !== instrument.numberOfResultsPages
                    : false,
            currentQuery: instrument.currentSearchQuery,
            currentTimePeriod: instrument.currentSearchTimePeriod,
            currentSort: instrument.currentSearchSort,
            resultsLoadingState: instrument.resultsLoadingState,
            searchInitialisedFor: state.instrument.searchInitialisedFor,
            searchScrollItemIndex: state.instrument.searchScrollItemIndex,
        }
    },
    dispatch => ({
        loadNextPageSearchResults: () => dispatch(actions.loadNextPageSearchResults()),
        loadNextPageAutoInvestSearchResults: () => dispatch(actions.loadNextPageAutoInvestSearchResults()),
        setRecentlyViewedInstrument: instrumentId => dispatch(identityActions.SetSearchedFund(instrumentId)),
        setSearchScrollItemIndex: (itemIndex: number) => dispatch(actions.SetSearchScrollItemIndex(itemIndex)),
    }),
)(SearchResults)

export default withMeasure(ConnectedSearchResults)
