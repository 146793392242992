import {Modal} from '@design-system/modal'
import React from 'react'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const PlanMarketDepthModal: React.FunctionComponent<Props> = ({isOpen, setIsOpen}) => {
    return (
        <>
            <Modal
                dataTestId="modal--market-depth"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title="NZX market depth"
                primaryButton={{label: 'Ok'}}
                content={
                    <div>
                        <p>
                            For any investment listed on the New Zealand Stock Exchange (NZX), get real-time market info
                            on the:
                        </p>
                        <ul>
                            <li>number of people that are buying and selling on the NZX</li>
                            <li>price you can buy or sell at</li>
                            <li>last traded price</li>
                            <li>highs and lows for today</li>
                            <li>latest trades.</li>
                        </ul>
                        <p>
                            This info can help you decide the price you want to buy or sell at, and where your order
                            might go in the queue.
                        </p>
                    </div>
                }
            />
        </>
    )
}

export default PlanMarketDepthModal
