/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    AppSchemasForClientPortfoliosPortfolio,
    AppSchemasForClientPortfoliosPortfolioFromJSON,
    AppSchemasForClientPortfoliosPortfolioFromJSONTyped,
    AppSchemasForClientPortfoliosPortfolioToJSON,
    AppSchemasForClientPortfoliosPortfolioToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface Portfolios
 */
export interface Portfolios {
    /**
     *
     * @type {{ [key: string]: AppSchemasForClientPortfoliosPortfolio; }}
     * @memberof Portfolios
     */
    portfolios: {[key: string]: AppSchemasForClientPortfoliosPortfolio}
}

export function PortfoliosFromJSON(json: any): Portfolios {
    return PortfoliosFromJSONTyped(json, false)
}

export function PortfoliosFromJSONTyped(json: any, ignoreDiscriminator: boolean): Portfolios {
    if (json === undefined || json === null) {
        return json
    }
    return {
        portfolios: mapValues(json['portfolios'], AppSchemasForClientPortfoliosPortfolioFromJSON),
    }
}

export function PortfoliosToJSON(value?: Portfolios | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        portfolios: mapValues(value.portfolios, AppSchemasForClientPortfoliosPortfolioToJSON),
    }
}
