import React from 'react'
import {Model, Request} from '~/api/retail/types'
import EditTaxResidenciesForm from '~/global/widgets/edit-tax-residencies-form/EditTaxResidenciesForm'
import {Loading} from '~/global/widgets/loading/Loading'
import {connect} from '~/store/connect'
import signUpActions from '~/store/sign-up/actions'
import {State} from '~/store/sign-up/types'

const EditTaxDetails: React.FunctionComponent<EditTaxDetailsProps> = ({
    usStatus,
    preferredName,
    jurisdiction,
    isDependent,
    isEditing,
    taxDeclareYes,
    taxDeclareNo,
}) => {
    if (!usStatus) {
        return <Loading isPineapple />
    }

    return (
        <EditTaxResidenciesForm
            taxResidencies={usStatus.tax_residencies}
            isDependent={isDependent}
            preferredName={preferredName}
            taxDeclareNo={taxDeclareNo}
            taxDeclareYes={taxDeclareYes}
            successAction={() => {
                isEditing(false)
            }}
            jurisdiction={jurisdiction}
        />
    )
}

interface StoreProps {
    usStatus: State['usStatus']
    preferredName: string
    isDependent: boolean
    jurisdiction: Model.User['jurisdiction']
}

interface DispatchProps {
    taxDeclareYes(countries: Request.IdentitySignUpTaxQuestions['countries']): Promise<void | null>
    taxDeclareNo(): Promise<void | null>
}

interface OwnProps {
    isEditing: React.Dispatch<React.SetStateAction<boolean>>
}

type EditTaxDetailsProps = StoreProps & DispatchProps & OwnProps

export default connect<StoreProps, DispatchProps, OwnProps>(
    state => ({
        usStatus: state.signUp.usStatus,
        preferredName: state.identity.user!.preferred_name,
        isDependent: state.identity.user!.is_dependent,
        jurisdiction: state.identity.user!.jurisdiction,
    }),
    dispatch => ({
        taxDeclareYes: countries => dispatch(signUpActions.usEditTaxDetails(true, countries)),
        taxDeclareNo: () => dispatch(signUpActions.usEditTaxDetails(false, [])),
    }),
)(EditTaxDetails)
