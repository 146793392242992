/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface CountResponseDto
 */
export interface CountResponseDto {
    /**
     *
     * @type {number}
     * @memberof CountResponseDto
     */
    total: number
}

export function CountResponseDtoFromJSON(json: any): CountResponseDto {
    return CountResponseDtoFromJSONTyped(json, false)
}

export function CountResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountResponseDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        total: json['total'],
    }
}

export function CountResponseDtoToJSON(value?: CountResponseDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        total: value.total,
    }
}
