import {DateTime} from 'luxon'
import React from 'react'
import {Model} from '~/api/retail/types'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {amountFilled, isPartiallyFilled, sumTradeGrossValues} from '~/global/utils/order/order'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {
    Order as OrderIcon,
    Sell,
    Cancel,
    AutoInvest,
    Units,
    Transfer,
    Success,
    SpareChange,
    VestedAllocation,
} from '~/global/widgets/OLD_icons'
import {getIconAndTitleForCAv2} from '~/global/widgets/corporate-action-description/corporateActionsV2'
import {CorporateActionOrder, CorporateActionV2Order, Order} from '~/store/accounting/types'
import {Instrument} from '~/store/instrument/types'

interface ActivityTitleAndIcon {
    icon: JSX.Element
    title: string
}

export interface InvestingActivityData {
    amount?: string
    displayDate: DateTime
    icon: JSX.Element
    title: string
    url: string
}

export const investingActivityTitleAndIcon = (order: Order, instrument: Instrument): ActivityTitleAndIcon => {
    const buySellOrderUnsuccessful = (description: string) => {
        return {
            icon: <Cancel />,
            title: description,
        }
    }

    const buySellExpired = (description: string) => {
        return {
            icon: <SpareChange />,
            title: description,
        }
    }

    if (order.type === 'buy') {
        const hasTrade = order.trades && order.trades.length > 0

        const buyOrderDefault = {
            icon: order.autoinvest ? <AutoInvest /> : <OrderIcon />,
            title: order.description,
        }

        if (tradingType(instrument) === 'listed') {
            switch (order.state) {
                case 'pending':
                case 'processing':
                    return buyOrderDefault
                case 'failed':
                case 'rejected':
                    return buySellOrderUnsuccessful(order.description)
                case 'cancelling':
                case 'cancelled':
                    return hasTrade ? buyOrderDefault : buySellOrderUnsuccessful(order.description)
                case 'expired':
                    return hasTrade ? buyOrderDefault : buySellExpired(order.description)
                case 'fulfilled':
                default:
                    return buyOrderDefault
            }
        }

        // Managed funds
        switch (order.state) {
            case 'pending':
            case 'ordered': // Managed funds are 'ordered', not 'processing'
                return buyOrderDefault
            case 'cancelling':
            case 'failed':
            case 'cancelled':
            case 'rejected':
                return buySellOrderUnsuccessful(order.description)
            case 'expired':
                return buySellExpired(order.description)
            case 'fulfilled':
            default:
                return buyOrderDefault
        }
    }

    if (order.type === 'corporate_action') {
        switch (order.action_type) {
            case 'capital_decrease':
                return {
                    icon: <Success />,
                    title: 'Capital return',
                }
            case 'delist_share_sale':
                return {
                    icon: <Sell />,
                    title: 'Delist sell',
                }
            case 'share_purchase':
                return {
                    icon: <OrderIcon />,
                    title: 'Share purchase plan buy',
                }
            default:
                return {
                    icon: <Units />,
                    title: 'Corporate action',
                }
        }
    }

    if (order.type === 'corporate_action_v2') {
        return getIconAndTitleForCAv2(order)
    }

    if (order.type === 'transfer_in' || order.type === 'transfer_out') {
        const direction = order.type === 'transfer_in' ? 'in' : 'out'

        switch (order.state) {
            case 'cancelled':
                return {
                    icon: <Cancel />,
                    title: `Cancelled transfer ${direction} request`,
                }
            case 'rejected':
                return {
                    icon: <Cancel />,
                    title: `Failed transfer ${direction} request`,
                }
            default:
                return {
                    icon: <Transfer />,
                    title: `Transfer shares ${direction}`,
                }
        }
    }

    if (order.type === 'dividend') {
        return {
            icon: <SpareChange />,
            title: order.state === 'paid' ? 'Dividend' : 'Upcoming Dividend',
        }
    }

    if (order.type === 'sell') {
        const hasTrade = order.trades && order.trades.length > 0

        const sellOrderDefault = {
            icon: <Sell />,
            title: order.description,
        }

        switch (order.state) {
            case 'pending':
            case 'processing':
                return sellOrderDefault
            case 'rejected':
                return buySellOrderUnsuccessful(order.description)
            case 'cancelling':
            case 'cancelled':
                return hasTrade ? sellOrderDefault : buySellOrderUnsuccessful(order.description)
            case 'expired':
                return hasTrade ? sellOrderDefault : buySellExpired(order.description)
            case 'fulfilled':
            default:
                return sellOrderDefault
        }
    }

    if (order.type === 'ess_share_allocation_line') {
        return {
            icon: <VestedAllocation />,
            title: `${order.group_name} allocation received`,
        }
    }

    if (order.type === 'ess_share_right_allocation_line') {
        return {
            icon: <VestedAllocation />,
            title: `${order.group_name} right allocation received`,
        }
    }

    if (order.type === 'ess_alignment_right_allocation_line') {
        return {
            icon: <VestedAllocation />,
            title: `${order.group_name} allocation received`,
        }
    }

    return {
        icon: <Units />,
        title: '',
    }
}

const buyOrderDisplayAmount = (order: Model.BuyOrder): string | undefined => {
    if (order.state === 'pending') {
        return order.created_hold_amount
    }
    if (isPartiallyFilled(order)) {
        return amountFilled(order).toString()
    }
    // If in a terminal state without partial fulfilment, don't display a number
    if (['cancelled', 'cancelling', 'failed', 'expired', 'rejected'].includes(order.state)) {
        return undefined
    }
    // order fulfilled
    return order.order_total || order.created_hold_amount
}

const corporateActionAmount = (order: CorporateActionOrder): string | undefined => {
    switch (order.action_type) {
        case 'capital_decrease':
        case 'delist_share_sale':
            return order.delta_value
        case 'share_purchase':
            return order.order_total
        default:
            assertNever(order)
            return undefined
    }
}

const corporateActionV2Amount = (order: CorporateActionV2Order): string | undefined => {
    return order.outcome_records.length === 1 && order.outcome_records[0].currency
        ? order.outcome_records[0].net_amount
        : undefined
}

const sellOrderDisplayAmount = (order: Model.SellOrder): string | undefined => {
    if (isPartiallyFilled(order)) {
        return sumTradeGrossValues(order).toString()
    }

    // Order filled: order_total is populated if the order is fulfilled
    if (order.order_total) {
        return order.order_total
    }

    // Order pending, or not filled (cancelled/expired/rejected) - do not display an amount
    return undefined
}

export const getInvestingActivityData = (
    order: Order,
    instrument: Instrument,
    profileUrl: ReturnType<typeof useProfileUrl>,
): InvestingActivityData => {
    const titleAndIcon = investingActivityTitleAndIcon(order, instrument)
    const url = profileUrl('invest/investing-activity/:instrumentId/:orderId', {
        instrumentId: instrument.id,
        orderId: order.id,
    })

    if (order.type === 'buy') {
        const amount = buyOrderDisplayAmount(order)

        return {amount, displayDate: order.created, icon: titleAndIcon.icon, title: titleAndIcon.title, url}
    }

    if (order.type === 'sell') {
        const amount = sellOrderDisplayAmount(order)

        return {
            amount,
            displayDate: order.fulfilled_at || order.created,
            icon: titleAndIcon.icon,
            title: titleAndIcon.title,
            url,
        }
    }

    if (order.type === 'dividend') {
        return {
            amount: order.net_value_total,
            displayDate: order.settled || order.created,
            icon: titleAndIcon.icon,
            title: titleAndIcon.title,
            url,
        }
    }

    if (order.type === 'transfer_in' || order.type === 'transfer_out') {
        return {
            amount: order.total_transfer_value,
            displayDate:
                order.complete_at || order.rejected_at || order.processing_at || order.cancelled_at || order.created,
            icon: titleAndIcon.icon,
            title: titleAndIcon.title,
            url,
        }
    }

    if (order.type === 'ess_share_allocation_line') {
        return {
            amount: order.total_value,
            displayDate: order.vesting_date!,
            icon: titleAndIcon.icon,
            title: titleAndIcon.title,
            url,
        }
    }

    if (order.type === 'ess_share_right_allocation_line') {
        return {
            amount: order.total_value,
            displayDate: order.vesting_date!,
            icon: titleAndIcon.icon,
            title: titleAndIcon.title,
            url,
        }
    }

    if (order.type === 'ess_alignment_right_allocation_line') {
        return {
            amount: order.total_value,
            displayDate: order.vesting_date!,
            icon: titleAndIcon.icon,
            title: titleAndIcon.title,
            url,
        }
    }

    const corporateActionDefault = {
        displayDate: order.action_timestamp,
        icon: titleAndIcon.icon,
        title: titleAndIcon.title,
        url,
    }

    if (order.type === 'corporate_action_v2') {
        const amount = corporateActionV2Amount(order)

        return {...corporateActionDefault, amount}
    }

    // Corporate Action
    const amount = corporateActionAmount(order)
    return {...corporateActionDefault, amount}
}
