/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface InstrumentPriceChangeResponseDto
 */
export interface InstrumentPriceChangeResponseDto {
    /**
     *
     * @type {string}
     * @memberof InstrumentPriceChangeResponseDto
     */
    instrumentId: string
    /**
     *
     * @type {Date}
     * @memberof InstrumentPriceChangeResponseDto
     */
    initialPriceDate: DateTime
    /**
     *
     * @type {string}
     * @memberof InstrumentPriceChangeResponseDto
     */
    initialPrice: string
    /**
     *
     * @type {string}
     * @memberof InstrumentPriceChangeResponseDto
     */
    currentPrice: string
    /**
     *
     * @type {string}
     * @memberof InstrumentPriceChangeResponseDto
     */
    readonly priceChangePercent: string
}

export function InstrumentPriceChangeResponseDtoFromJSON(json: any): InstrumentPriceChangeResponseDto {
    return InstrumentPriceChangeResponseDtoFromJSONTyped(json, false)
}

export function InstrumentPriceChangeResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): InstrumentPriceChangeResponseDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        instrumentId: json['instrumentId'],
        initialPriceDate: DateTime.fromISO(json['initialPriceDate'], {setZone: true}),
        initialPrice: json['initialPrice'],
        currentPrice: json['currentPrice'],
        priceChangePercent: json['priceChangePercent'],
    }
}

export function InstrumentPriceChangeResponseDtoToJSON(value?: InstrumentPriceChangeResponseDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        instrumentId: value.instrumentId,
        initialPriceDate: value.initialPriceDate.toFormat('yyyy-MM-dd'),
        initialPrice: value.initialPrice,
        currentPrice: value.currentPrice,
    }
}
