import {useQueryClient} from '@tanstack/react-query'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import type {Response} from '~/api/retail/types'
import {useAppSelector} from '~/store/hooks'
import {actingAsID} from '~/store/identity/selectors'
import type {BasicInstrument} from '~/store/instrument/types'

function useDividendReinvestmentPlan(
    instrument: BasicInstrument,
): [Response.DividendReinvestmentPlan | undefined, (enrolmentStatus: boolean) => Promise<void>] {
    const actingAsId = useAppSelector(actingAsID)
    const queryClient = useQueryClient()

    const payload = {fund_id: instrument.id, acting_as_id: actingAsId}
    const {data} = useRetailGet({
        path: 'dividend-reinvestment-plan/plan',
        payload,
    })
    const mutation = useRetailPost({
        path: 'dividend-reinvestment-plan/update-enrolment',
        queryCacheToUpdate: ['dividend-reinvestment-plan/plan', payload],
    })

    async function setEnrolment(entrolmentStatus: boolean): Promise<void> {
        await mutation.mutateAsync({
            fund_id: instrument.id,
            enrolled: entrolmentStatus,
            acting_as_id: actingAsId,
        })
        await queryClient.invalidateQueries(['dividend-reinvestment-plan/plans'])
    }

    return [data.type === 'empty' ? undefined : data, setEnrolment]
}

export default useDividendReinvestmentPlan
