import {Modal} from '@design-system/modal'
import {withFormik} from 'formik'
import {DateTime} from 'luxon'
import React from 'react'
import * as api from '~/api/retail'
import {urlFor} from '~/global/routeGenerator'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {ErrorBox, validate} from '~/global/widgets/form-controls'
import {Password} from '~/global/widgets/form-controls/formik'
import {Link} from '~/migrate-react-router'
import store from '~/store'
import identityActions from '~/store/identity/actions'
import {actingAsID} from '~/store/identity/selectors'

type ReauthenticationResponse = 'success' | 'cancel'

let singleton: Reauthenticate | null = null

function reauthenticate(): Promise<ReauthenticationResponse> {
    if (!singleton) {
        return Promise.reject('Reauthentication component not mounted')
    }
    return singleton.reauthenticate()
}

export function reauthenticateReturn<S, ST, C>(
    onSuccess: () => Promise<S> | S,
    onSuccessTimeout: () => Promise<ST> | ST,
    onCancel: () => Promise<C> | C,
): Promise<S | ST | C> {
    const timestamp = DateTime.utc()
    return reauthenticate().then<S | ST | C>(response => {
        switch (response) {
            case 'success':
                if (timestamp.plus({minutes: 1}) > DateTime.utc()) {
                    return onSuccess()
                }
                return onSuccessTimeout()
            case 'cancel':
                return onCancel()
            default:
                assertNever(response)
                return onCancel()
        }
    })
}

type StateResolve = null | ((value: ReauthenticationResponse) => void)
type StateRequest = null | Promise<ReauthenticationResponse>

interface State {
    request: StateRequest
    resolve: StateResolve
}

interface FormValues {
    password: string
}

interface FormOwnProps {
    onSuccess(): void
    onClose(): void
    request: StateRequest
}

const Form = withFormik<FormOwnProps, FormValues>({
    mapPropsToValues: () => ({
        password: '',
    }),
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        password: undefined,
    }),
    handleSubmit: async ({password}, {setSubmitting, setFieldError, resetForm, props: {onSuccess}}) => {
        try {
            const response = await api.post('identity/reauthenticate', {
                password,
                acting_as_id: actingAsID(store.getState()),
            })
            switch (response.type) {
                case 'identity_authenticated':
                    store.dispatch(identityActions.handleIdentityResponse(response))
                    resetForm() // formik persists the values if we need to re-show the auth later, so we need to blank the supplied values
                    onSuccess()
                    break
                case 'error':
                    setFieldError('password', response.message)
                    break
                case 'internal_server_error':
                    setFieldError('password', unknownErrorMessage)
                    break
                default:
                    assertNever(response)
            }
        } catch (e) {
            setFieldError('password', unknownErrorMessage)
            throw e
        } finally {
            setSubmitting(false)
        }
    },
    validate: validate.generate<FormValues>({
        password: [validate.required()],
    }),
})(({handleSubmit, handleReset, status, isSubmitting, isValid, onClose, request}) => {
    const handleClose = () => {
        handleReset()
        onClose()
        status = undefined
    }

    return (
        <div className="reauthenticate">
            <Modal
                isAlert
                isOpen={!!request}
                setIsOpen={handleClose}
                title="Security check"
                dataTestId="modal--security-check-alert"
                onFormSubmit={handleSubmit}
                primaryButton={{label: 'Continue', disabled: !isValid}}
                customZIndex={1299} // per z-index index in config.scss
            >
                <p>Please enter your password to continue.</p>
                {!!request && (
                    <>
                        <Password
                            dataTestId="password"
                            name="password"
                            label="Password"
                            autoFocus
                            disabled={isSubmitting}
                            helpText={
                                <Link to={urlFor('forgot-password')} onClick={handleClose}>
                                    Forgot your password?
                                </Link>
                            }
                        />
                        <ErrorBox message={status} />
                    </>
                )}
            </Modal>
        </div>
    )
})

class Reauthenticate extends React.Component<{}, State> {
    public state: State = {
        request: null,
        resolve: null,
    }

    public componentDidMount() {
        if (singleton) {
            throw new Error('Can only only load a single Reauthenticate component at a time')
        }
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        singleton = this
    }

    public componentWillUnmount() {
        singleton = null
    }

    public reauthenticate(): Promise<ReauthenticationResponse> {
        const {request} = this.state
        if (request) {
            return request
        }
        const promise = new Promise<ReauthenticationResponse>(resolve => {
            this.setState({resolve})
        })
        this.setState({request: promise})
        return promise
    }

    private resolve(resolveType: ReauthenticationResponse) {
        const {request, resolve} = this.state
        if (!request || !resolve) {
            return
        }
        resolve(resolveType)
        this.setState({request: null, resolve: null})
    }

    public render() {
        const {request} = this.state

        return (
            <Form onSuccess={() => this.resolve('success')} onClose={() => this.resolve('cancel')} request={request} />
        )
    }
}

export default Reauthenticate
