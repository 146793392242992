import store from '~/store'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {Dispatch, RootState} from '~/store/types'

const cache = new Map<string, Promise<unknown>>()

type Selector<T> = (state: RootState) => T

/**
 * Hook for creating a suspense/errorboundary aware subscription to the redux
 * store. Any supplied load action will only be called once as we maintain a
 * cache reference to know that we've called this hook before.
 *
 * @example
 * useReduxSuspense(
 *     state => state.myModule.someData,
 *     state => state.myModule.someDataLoadingState === 'loading',
 *     state => state.myModule.someDataLoadingState === 'error',
 *     actions.thunkAction()
 * )
 */
export const useReduxSuspense = <T>(
    dataSelector: Selector<T>,
    isLoadingSelector: Selector<boolean>,
    isErrorSelector: Selector<boolean>,
    loadAction?: Parameters<Dispatch>[0],
): T => {
    const dispatch = useAppDispatch()

    const data = useAppSelector(dataSelector)
    const isLoading = useAppSelector(isLoadingSelector)
    const isError = useAppSelector(isErrorSelector)

    /* We have to store the promise somewhere that can survive multiple
    invocations of the hook, but also always hit the same promise. Stringifying
    all the selector functions seems like a pretty robust solution */
    const promiseCacheKey =
        dataSelector.toString() +
        isLoadingSelector.toString() +
        isErrorSelector.toString() +
        (loadAction ? loadAction.toString() : 'undefined')

    const suspender = cache.has(promiseCacheKey)
        ? cache.get(promiseCacheKey)
        : new Promise(resolve => {
              // We start by subscribing to the store, specifically to keep an eye on the `isLoadingSelector` response
              const unsubscribe = store.subscribe(() => {
                  const isLoading = isLoadingSelector(store.getState())
                  if (!isLoading) {
                      // We're no longer "loading", we can now unsubscribe from the store and resolve the suspense
                      // promise (instructing React to render the final component with data in place)
                      unsubscribe()
                      resolve(undefined)
                  }
              })
              if (loadAction) {
                  dispatch(loadAction)
              }
          })
    cache.set(promiseCacheKey, suspender!)

    if (isLoading) {
        throw suspender
    }
    if (isError) {
        throw new Error('Failed to load data')
    }
    return data
}
