import {withFormik} from 'formik'
import {DateTime} from 'luxon'
import React from 'react'
import {Request, Response} from '~/api/retail/types'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {IdentityVerificationStep} from '~/global/utils/identity-verification/identityVerification'
import {SharesiesOmit} from '~/global/utils/type-utilities/typeUtilities'
import {validate} from '~/global/widgets/form-controls'
import {Text, DateInput} from '~/global/widgets/form-controls/formik'
import {
    CommonHeader,
    CommonFooter,
    CommonIdFormProps,
    consentMessage,
} from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/IdentityFormCommon'

type NZPassportFormProps = CommonIdFormProps & {
    givenName?: string
    middleName?: string
    familyName?: string
    passportNumber?: string
    dateOfBirth?: DateTime
    dateOfExpiry?: DateTime
    verifyPassport(
        payload: SharesiesOmit<Request.IdentityVerifiNZPassport, Request.ActingAsRequired>,
    ): Promise<null | Response.Error>
}

type PassportFormValues = SharesiesOmit<
    Request.IdentityVerifiNZPassport,
    Request.ActingAsRequired & {date_of_birth: string; passport_expiry: string; consent: true}
> & {date_of_birth?: DateTime; passport_expiry?: DateTime; consent: boolean}
export const PassportForm = withFormik<NZPassportFormProps, PassportFormValues>({
    enableReinitialize: true,
    mapPropsToValues: ({
        givenName,
        middleName,
        familyName,
        dateOfBirth,
        dateOfExpiry,
        passportNumber,
    }: NZPassportFormProps) => {
        return {
            given_name: givenName || '',
            middle_name: middleName || '',
            family_name: familyName || '',
            date_of_birth: dateOfBirth || undefined,
            passport_number: passportNumber || '',
            passport_expiry: dateOfExpiry || undefined,
            consent: false,
        }
    },
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        given_name: undefined,
    }),
    handleSubmit: async (values, {setSubmitting, setStatus, props: {verifyPassport, setIDDuplicateError, setStep}}) => {
        try {
            const error = await verifyPassport({
                ...values,
                // verifyPassport needs strings of the dates
                date_of_birth: values.date_of_birth ? values.date_of_birth.toISODate() : '',
                passport_expiry: values.passport_expiry ? values.passport_expiry.toISODate() : '',
                consent: values.consent as true,
            })
            if (error) {
                if (error.code === 'duplicate_id') {
                    setIDDuplicateError(true)
                    setStatus(undefined)
                } else {
                    setIDDuplicateError(false)
                    setStatus(error.message)
                }
                setSubmitting(false)
                return
            } else {
                setStep('completed' as IdentityVerificationStep)
            }
        } catch (e) {
            setStatus(unknownErrorMessage)
            setSubmitting(false)
            throw e
        }
    },
    validate: validate.generate<PassportFormValues>({
        given_name: [],
        middle_name: [validate.middleName()],
        family_name: [validate.required()],
        date_of_birth: [validate.required(), validate.date(), validate.dateMinYear(1900), validate.notFutureDate()],
        passport_number: [validate.required()],
        passport_expiry: [validate.required(), validate.date()],
        consent: [validate.isTrue(consentMessage)],
    }),
})(({
    handleSubmit,
    isDependent,
    preferredName,
    isSubmitting,
    isValid,
    status,
    databases,
    isSecondaryId,
    setFieldValue,
}) => {
    return (
        <form onSubmit={handleSubmit}>
            <CommonHeader setFieldValue={setFieldValue} identityType="NZ passport" isSecondaryId={isSecondaryId} />
            <Text dataTestId="text-input--passport-number" name="passport_number" label="Passport number" />
            <DateInput
                dataTestId="date-input--passport-expiry"
                name="passport_expiry"
                label="Passport expiry"
                handleDateChange={setFieldValue}
            />
            <CommonFooter
                isDependent={isDependent}
                preferredName={preferredName}
                isSubmitting={isSubmitting}
                databases={databases}
                isValid={isValid}
                jurisdiction="nz"
                error={status}
                isSecondaryId={isSecondaryId}
            />
        </form>
    )
})
