import React from 'react'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {AccountLinkingCommonProps} from '~/sections/moose/sections/sign-up/sections/account-linking/AccountLinking'
import CreateAccountForm from '~/sections/moose/sections/sign-up/sections/account-linking/pages/create-account/widgets/create-account-form/CreateAccountForm'

const CreateAccount = ({navTo, initialEmail, signupId}: AccountLinkingCommonProps) => {
    return (
        <>
            <Toolbar
                dataTestId="toolbar--fonterra-signup-create-new-account"
                leftButton="back"
                onLeftButtonClick={() => navTo(initialEmail ? 'login' : 'existing-account-check')}
                title="A few more questions"
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <CreateAccountForm signupId={signupId} />
            </Page>
        </>
    )
}

export default CreateAccount
