import React from 'react'
import {COMPUTERSHARE_HREF} from '~/sections/moose/constants/constants'

export const FinDescription = () => {
    return (
        <div>
            To start a sell order you’ll need your four digit FIN. If you don’t know your FIN, contact{' '}
            <a href={COMPUTERSHARE_HREF} target="_blank" rel="noreferrer">
                Computershare
            </a>{' '}
            to get it sent to you in the mail.
        </div>
    )
}
