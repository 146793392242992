import {Colour, colour, semanticColour} from '@design-system/colour-tokens'
import {Flag} from '@design-system/flag'
import {ChevronRight, Wallet} from '@design-system/icon'
import {useColourMode} from '@design-system/use-colour-mode'
import cn from 'classnames'
import Decimal from 'decimal.js'
import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {accessibility} from '~/global/scss/helpers'
import spacing from '~/global/scss/helpers/spacing.scss'
import {usePortfolioValue} from '~/global/state-hooks/rakaia/usePortfolioValues'
import {useActor} from '~/global/state-hooks/retail/useActor'
import {Portfolio, useProfile} from '~/global/state-hooks/retail/useProfile'
import {formatNumber} from '~/global/utils/format-number/formatNumber'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {DollarValue, PercentValue} from '~/global/widgets/number-elements/NumberElements'
import kiwisaverSmall from '~/sections/kiwisaver/assets/images/kiwisaver-small.png'
import investSmall from '~/sections/landing/assets/images/invest-small.png'
import farmSupplyIcon from '~/sections/moose/assets/images/farm-supply-icon.svg'
import saveSmall from '~/sections/save/assets/images/save-small.png'
import {useAppSelector} from '~/store/hooks'
import {usePortfolioReturns} from '../../state/usePortfolioReturns'
import styles from './PortfolioSlice.scss'

export const PortfolioSlice = ({
    dataTestId,
    image,
    imageBackgroundColour,
    onClick,
    children,
    additionalClassName,
    clickable = true,
    wrap = false,
}: {
    dataTestId: string
    image: string
    imageBackgroundColour: Colour
    children: React.ReactNode
    additionalClassName?: string
    onClick?: () => void
    clickable?: boolean
    wrap?: boolean
}) => {
    const colourMode = useColourMode()
    return (
        <div>
            {/* NOTE: iOS safari breaks CSS grid if we don't have this wrapper div */}
            <button
                data-testid={dataTestId}
                type="button"
                className={cn(
                    styles.portfolioSlice,
                    clickable ? styles.clickableTile : styles.notClickableTile,
                    accessibility.button,
                    additionalClassName,
                )}
                onClick={onClick}
            >
                <div className={styles.image}>
                    <img src={image} alt="" style={{backgroundColor: colour(imageBackgroundColour, colourMode)}} />
                </div>
                <div className={cn(styles.text, {[styles.hidden]: wrap})}>{children}</div>
                {clickable && (
                    <div className={styles.actionIcon}>
                        <ChevronRight />
                    </div>
                )}
            </button>
        </div>
    )
}

const WalletPortfolioSlice: React.FunctionComponent<{portfolio: Portfolio<'WALLET'>}> = ({portfolio}) => {
    const actor = useActor()
    const profile = useProfile()
    const profileUrl = useProfileUrl()
    const notPositive = Number(portfolio.balance) <= 0

    return (
        <>
            <Link
                to={profileUrl('wallet')}
                // TODO need to test :portfolioId link works with all native clients
                // to={profileUrl('wallet/:portfolioId', {portfolioId: portfolio.id})}
                onClick={() => rudderTrack('topup', 'wallet_navigated', {linked_from: 'landing'})}
                className={styles.wallet}
            >
                <Wallet size={16} className={spacing.spaceAbove4} />
                {notPositive && profile.legacy_profile_type ? (
                    <span>Your Wallet is empty. Top up now to start investing or saving.</span>
                ) : (
                    <span>
                        {!profile.is_personal && profile.legacy_profile_type && `${profile.name} has `}
                        {portfolio.balance_is_estimate && !profile.is_personal && 'an estimated '}
                        {portfolio.balance_is_estimate && profile.is_personal && 'Estimated '}
                        <strong>
                            <DollarValue value={portfolio.balance} currency={actor.default_display_currency} />
                        </strong>{' '}
                        {!profile.legacy_profile_type ? '' : !profile.is_personal ? 'in their' : 'in your'} Wallet
                        {!profile.legacy_profile_type && !profile.is_personal && ' balance'}
                        {profile.legacy_profile_type && '.'}
                    </span>
                )}

                {notPositive && (
                    <>
                        <span />
                        <span className={styles.topUp}>Top up</span>
                    </>
                )}
            </Link>
        </>
    )
}

const FonterraPortfolioSlice: React.FunctionComponent<{portfolio: Portfolio<'FONTERRA'>}> = ({portfolio}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const fcgShares = portfolio.fcg_shares || 0
    return (
        <PortfolioSlice
            dataTestId={`button--landing-view-${portfolio.product.toLowerCase()}`}
            image={farmSupplyIcon}
            imageBackgroundColour="Melon100"
            onClick={() => navigate(profileUrl('fonterra/portfolios/:portfolioId', {portfolioId: portfolio.id}))}
        >
            <h3 className={styles.subtleHeading}>
                <span className={styles.fonterraFarmId}>{portfolio.farm_id}</span>
                {portfolio.estimated_balance && !portfolio.is_inactive ? (
                    <DollarValue value={portfolio.estimated_balance} />
                ) : portfolio.is_inactive ? (
                    '—'
                ) : (
                    `${portfolio.farm_id}`
                )}
            </h3>
            <span className={styles.fonterraText}>
                {portfolio.is_inactive && 'Consolidated'}
                {!portfolio.is_inactive && (
                    <>
                        {formatNumber({number: fcgShares.toString(), decimalPlaces: 0})} FCG share
                        {fcgShares !== 1 && 's'}
                        <Flag
                            dataTestId="flag--fonterra-range-status"
                            text={
                                portfolio.range_warning === 'below_min'
                                    ? 'Below min'
                                    : portfolio.range_warning === 'above_max'
                                      ? 'Above max'
                                      : 'In range'
                            }
                            type={portfolio.range_warning ? 'warning' : 'infoAlt'}
                            isCompact
                            isSubtle
                            additionalClassName={styles.statusFlag}
                        />
                    </>
                )}
            </span>
        </PortfolioSlice>
    )
}

const InvestPortfolioSlice: React.FunctionComponent<{portfolio: Portfolio<'INVEST'>}> = ({portfolio}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const colourMode = useColourMode()
    const includeSoldInvestments = useAppSelector(s => s.identity.includeSoldInvestments)
    const rakaiaPortfolioValue = usePortfolioValue(portfolio.id)
    const {simpleReturn, unRealisedSimpleReturn, totalReturns, unRealisedTotalReturn} = usePortfolioReturns(portfolio)
    const totalReturn = includeSoldInvestments ? totalReturns : unRealisedTotalReturn

    return (
        <PortfolioSlice
            dataTestId={`button--landing-view-${portfolio.product.toLowerCase()}`}
            image={investSmall}
            imageBackgroundColour="Melon100"
            onClick={() => navigate(profileUrl('invest'))}
        >
            <div className={styles.investmentSlice}>
                <div>
                    <h3 className={styles.subtleHeading}>Investments</h3>
                    {rakaiaPortfolioValue && <DollarValue value={rakaiaPortfolioValue.toNumber()} />}
                </div>
                <div className={styles.simpleReturnText}>
                    <p>Simple return</p>
                    <div>
                        <svg
                            width="9"
                            height="8"
                            viewBox="0 0 9 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{transform: totalReturn < 0 ? 'rotate(180deg)' : ''}}
                        >
                            <path
                                d="M4.5 0L9 8H0L4.5 0Z"
                                fill={
                                    totalReturn > 0
                                        ? semanticColour('SuccessAccent', colourMode)
                                        : colour('Orange500', colourMode)
                                }
                            />
                        </svg>
                        <PercentValue value={includeSoldInvestments ? simpleReturn : unRealisedSimpleReturn} />
                    </div>
                </div>
            </div>
        </PortfolioSlice>
    )
}

const KiwiSaverPortfolioSlice: React.FunctionComponent<{portfolio: Portfolio<'KIWISAVER'>}> = ({portfolio}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const rakaiaPortfolioValue = usePortfolioValue(portfolio.id)
    const combinedKSBalance = new Decimal(portfolio.cash_balance || 0).add(rakaiaPortfolioValue ?? new Decimal(0))
    return (
        <PortfolioSlice
            dataTestId={`button--landing-view-${portfolio.product.toLowerCase()}`}
            image={kiwisaverSmall}
            imageBackgroundColour="Blueberry100"
            onClick={() => navigate(profileUrl('kiwisaver'))}
        >
            <h3 className={styles.subtleHeading}>KiwiSaver</h3>
            {combinedKSBalance && <DollarValue value={combinedKSBalance.toNumber()} />}
        </PortfolioSlice>
    )
}

const SavePortfolioSlice: React.FunctionComponent<{portfolio: Portfolio<'SAVE'>}> = ({portfolio}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    return (
        <PortfolioSlice
            wrap={true}
            dataTestId={`button--landing-view-${portfolio.product.toLowerCase()}`}
            image={saveSmall}
            imageBackgroundColour="Orange100"
            onClick={() =>
                navigate(
                    profileUrl(`save/:portfolioId`, {
                        portfolioId: portfolio.id,
                    }),
                )
            }
        >
            <h3 className={styles.subtleHeading}>{portfolio.name}</h3>
            <DollarValue value={portfolio.balance} />
        </PortfolioSlice>
    )
}

export const PortfolioSlices: React.FunctionComponent<{portfolio: Portfolio}> = ({portfolio}) => {
    switch (portfolio.product) {
        case 'WALLET':
            return <WalletPortfolioSlice portfolio={portfolio} />
        case 'FONTERRA':
            return <FonterraPortfolioSlice portfolio={portfolio} />
        case 'INVEST':
            return <InvestPortfolioSlice portfolio={portfolio} />
        case 'KIWISAVER':
            return <KiwiSaverPortfolioSlice portfolio={portfolio} />
        case 'SAVE':
            return <SavePortfolioSlice portfolio={portfolio} />
    }
}
