/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {DateTime} from 'luxon'
import * as runtime from '../runtime'

export interface PublicInstrumentGetFileRequest {
    instrumentId: string
    fileIdentifier: string
}

export interface PublicInstrumentGetLogoRequest {
    instrumentId: string
    fileIdentifier: string
    maxWidth: number
    maxHeight: number
}

/**
 *
 */
export class PublicApi extends runtime.BaseAPI {
    /**
     */
    async apiV1PublicThrowGetRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/public/throw`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.VoidApiResponse(response)
    }

    /**
     */
    async apiV1PublicThrowGet(): Promise<void> {
        await this.apiV1PublicThrowGetRaw()
    }

    /**
     */
    async publicInstrumentGetFileRaw(
        requestParameters: PublicInstrumentGetFileRequest,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.instrumentId === null || requestParameters.instrumentId === undefined) {
            throw new runtime.RequiredError(
                'instrumentId',
                'Required parameter requestParameters.instrumentId was null or undefined when calling publicInstrumentGetFile.',
            )
        }

        if (requestParameters.fileIdentifier === null || requestParameters.fileIdentifier === undefined) {
            throw new runtime.RequiredError(
                'fileIdentifier',
                'Required parameter requestParameters.fileIdentifier was null or undefined when calling publicInstrumentGetFile.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/public/instrument-file/{instrumentId}/file/{fileIdentifier}`
                .replace(`{${'instrumentId'}}`, encodeURIComponent(String(requestParameters.instrumentId)))
                .replace(`{${'fileIdentifier'}}`, encodeURIComponent(String(requestParameters.fileIdentifier))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.VoidApiResponse(response)
    }

    /**
     */
    async publicInstrumentGetFile(requestParameters: PublicInstrumentGetFileRequest): Promise<void> {
        await this.publicInstrumentGetFileRaw(requestParameters)
    }

    /**
     * Sample request:                    GET /api/v1/public/instrument-logo/f9aeeb67-c951-4c9d-a9b9-04874ebf9117/logo/22d78471-e575-4c75-94d3-1368eeda3083/1024/0
     * Gets a logo with the desired width and/or height - will maintain aspect ratio
     */
    async publicInstrumentGetLogoRaw(
        requestParameters: PublicInstrumentGetLogoRequest,
    ): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.instrumentId === null || requestParameters.instrumentId === undefined) {
            throw new runtime.RequiredError(
                'instrumentId',
                'Required parameter requestParameters.instrumentId was null or undefined when calling publicInstrumentGetLogo.',
            )
        }

        if (requestParameters.fileIdentifier === null || requestParameters.fileIdentifier === undefined) {
            throw new runtime.RequiredError(
                'fileIdentifier',
                'Required parameter requestParameters.fileIdentifier was null or undefined when calling publicInstrumentGetLogo.',
            )
        }

        if (requestParameters.maxWidth === null || requestParameters.maxWidth === undefined) {
            throw new runtime.RequiredError(
                'maxWidth',
                'Required parameter requestParameters.maxWidth was null or undefined when calling publicInstrumentGetLogo.',
            )
        }

        if (requestParameters.maxHeight === null || requestParameters.maxHeight === undefined) {
            throw new runtime.RequiredError(
                'maxHeight',
                'Required parameter requestParameters.maxHeight was null or undefined when calling publicInstrumentGetLogo.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/v1/public/instrument-logo/{instrumentId}/logo/{fileIdentifier}/{maxWidth}/{maxHeight}`
                .replace(`{${'instrumentId'}}`, encodeURIComponent(String(requestParameters.instrumentId)))
                .replace(`{${'fileIdentifier'}}`, encodeURIComponent(String(requestParameters.fileIdentifier)))
                .replace(`{${'maxWidth'}}`, encodeURIComponent(String(requestParameters.maxWidth)))
                .replace(`{${'maxHeight'}}`, encodeURIComponent(String(requestParameters.maxHeight))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.BlobApiResponse(response)
    }

    /**
     * Sample request:                    GET /api/v1/public/instrument-logo/f9aeeb67-c951-4c9d-a9b9-04874ebf9117/logo/22d78471-e575-4c75-94d3-1368eeda3083/1024/0
     * Gets a logo with the desired width and/or height - will maintain aspect ratio
     */
    async publicInstrumentGetLogo(requestParameters: PublicInstrumentGetLogoRequest): Promise<Blob> {
        const response = await this.publicInstrumentGetLogoRaw(requestParameters)
        return await response.value()
    }
}
