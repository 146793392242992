import cn from 'classnames'
import Decimal from 'decimal.js'
import {DateTime} from 'luxon'
import React, {useEffect} from 'react'
import type {Model} from '~/api/retail/types'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {spacing} from '~/global/scss/helpers'
import {dateFormatDayAndMonthWithTime, dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import Concertina from '~/global/widgets/concertina/Concertina'
import {
    CorporateActionDescription,
    ShareSplitOrConsolidationDescription,
} from '~/global/widgets/corporate-action-description/CorporateActionDescription'
import {
    getAdditionalAnswerItems,
    getCollectorTaxName,
    getOutcomeEffect,
    getOutcomeLabel,
    getOutcomeName,
    hasApplication,
    hasEligibility,
    isDRPShareCA,
    isPendingPurchase,
} from '~/global/widgets/corporate-action-description/corporateActionsV2'
import mdStyles from '~/global/widgets/corporate-action-markdown/CAMarkdown.scss'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {ADR} from '~/global/widgets/help-modals/ADR'
import {FrankedDividend} from '~/global/widgets/help-modals/FrankedDividend'
import {StrikePrice} from '~/global/widgets/help-modals/StrikePrice'
import FXOrdersDetail from '~/global/widgets/instrument-activity/FXOrdersDetail'
import {CancelApplicationModal} from '~/global/widgets/instrument-activity/cancel-application-modal/CancelApplicationModal'
import {RecordRow} from '~/global/widgets/instrument-activity/common'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {Loading} from '~/global/widgets/loading/Loading'
import {DividendValue, DollarValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import actions from '~/store/accounting/actions'
import {
    CorporateActionV2Order,
    CorporateActionWithEligibility,
    MandatoryCorporateActionV2,
    VoluntaryCorporateActionV2,
} from '~/store/accounting/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'
import {Instrument} from '~/store/instrument/types'
import orderActions from '~/store/order/actions'
import AutoExerciseApplicationDetail from './AutoExerciseApplicationDetail'
import ReinvestDividendSnippet from './ReinvestDividendSnippet'
import styles from './Record.scss'

function absolute(num: string): string {
    return new Decimal(num).abs().toString()
}

const ShareSplitOrConsolidationDetails = ({action}: {action: MandatoryCorporateActionV2}) => {
    // Share splits or consolidations are neutral actions, so we don't want to use the normal gain/lost wording
    // Additionally, we'd rather frame is as before/after as opposed to "lost x shares"
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)

    const outcomeRecord = action.outcome_records.find(o => o.fund_id)! // There should only be one outcome, but just in case, find the shares outcome
    const instrument = instrumentsById[action.eligibility.must_hold_fund_id]
    const actionName = action.action_type === 'SHARE_SPLIT' ? 'split' : 'consolidation'

    const beforeHolding = new Decimal(action.eligibility.shares_held)
    const afterHolding = beforeHolding.add(new Decimal(outcomeRecord.gross_amount)) // this matches settle_share_outcome

    const ratio = new Decimal(outcomeRecord.amount_per_input_unit).add(1)
    const price = action.eligibility.must_hold_fund_price ? new Decimal(action.eligibility.must_hold_fund_price) : null
    const priceInfo = price
        ? {
              beforePrice: price.toString(),
              beforeValue: price.mul(beforeHolding).toString(),
              afterPrice: price.div(ratio).toDecimalPlaces(2, Decimal.ROUND_UP).toString(), // TODO is 2dp accurate for all providers?
              afterValue: afterHolding.mul(price.div(ratio).toDecimalPlaces(2, Decimal.ROUND_UP)).toString(),
          }
        : null // TODO yikes frontend maths

    return (
        <>
            <p className={styles.orderDescription}>
                <ShareSplitOrConsolidationDescription action={action} />
            </p>
            <Concertina
                border
                title={<h5 className={styles.concertinaTitle}>Before share {actionName}</h5>}
                body={
                    <div className={cn(styles.recordRowContainer, styles.addPadding)}>
                        <RecordRow
                            className={styles.dense}
                            left={`Number of ${shareLabel({instrument, isPlural: true})} held on record date`}
                            right={<ShareValue value={action.eligibility.shares_held} showFullValue />}
                        />
                        {priceInfo && (
                            <>
                                <RecordRow
                                    className={styles.dense}
                                    left={`Price per ${shareLabel({instrument})}`}
                                    right={<DollarValue value={priceInfo.beforePrice} currency={instrument.currency} />}
                                />
                                <RecordRow
                                    className={styles.dense}
                                    left="Total value"
                                    right={<DollarValue value={priceInfo.beforeValue} currency={instrument.currency} />}
                                />
                            </>
                        )}
                    </div>
                }
            />
            <Concertina
                border
                title={<h5 className={styles.concertinaTitle}>After share {actionName}</h5>}
                body={
                    <div className={cn(styles.recordRowContainer, styles.addPadding)}>
                        <RecordRow
                            className={styles.dense}
                            left={`Number of ${shareLabel({instrument, isPlural: true})}`}
                            right={<ShareValue value={afterHolding.toString()} showFullValue />}
                        />
                        {priceInfo && (
                            <>
                                <RecordRow
                                    className={styles.dense}
                                    left={`Price per ${shareLabel({instrument})}`}
                                    right={<DollarValue value={priceInfo.afterPrice} currency={instrument.currency} />}
                                />
                                <RecordRow
                                    className={styles.dense}
                                    left="Total value"
                                    right={<DollarValue value={priceInfo.afterValue} currency={instrument.currency} />}
                                />
                            </>
                        )}
                    </div>
                }
            />
        </>
    )
}

interface OutcomeRowsProps {
    action: Model.CorporateActionV2
    outcome: Model.CorporateActionV2['outcome_records'][number]
}

const EligibilityRow = ({action}: OutcomeRowsProps & {action: CorporateActionWithEligibility}) => {
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const eligibility = action.eligibility
    const instrument = instrumentsById[eligibility.must_hold_fund_id]
    const hasAmbiguousShares = action.outcome_records.some(
        o => o.fund_id && o.fund_id !== eligibility.must_hold_fund_id,
    )

    return (
        <RecordRow
            className={styles.dense}
            left={`Number of ${hasAmbiguousShares ? instrument.symbol : ''} ${shareLabel({
                instrument,
                isPlural: true,
            })} held on record date`}
            right={<ShareValue value={eligibility.shares_held} showFullValue />}
        />
    )
}

const DepositoryFeeRow = ({action, outcome}: OutcomeRowsProps) => {
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const instrument = instrumentsById[action.fund_id]

    let description: React.ReactNode = 'Depository fee'
    if (instrument.isAdr) {
        // ADRs have a special explainer on the fees!
        description = (
            <>
                Depository fee <ADR jurisdiction={jurisdiction} />
            </>
        )
    }

    return (
        <RecordRow
            className={styles.dense}
            left={description}
            right={<DividendValue value={`-${outcome.upstream_fee_withheld}`} currency={outcome.currency!} />}
        />
    )
}

const TaxRecordRow: React.FC<
    {
        taxRecord: Model.CorporateActionV2['outcome_records'][number]['tax_records'][number]
    } & OutcomeRowsProps
> = ({taxRecord, outcome}) => {
    const customerJurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    return (
        <>
            <RecordRow
                className={styles.dense}
                left={getCollectorTaxName(taxRecord.owed_to, customerJurisdiction)}
                right={<DividendValue value={`-${taxRecord.cost_to_net_distribution}`} currency={outcome.currency!} />}
            />
            {taxRecord.imputation_amount && (
                <RecordRow
                    className={styles.dense}
                    left="Imputation credit"
                    right={<DividendValue value={taxRecord.imputation_amount} currency={taxRecord.currency!} />}
                />
            )}
        </>
    )
}

const SharesAddedOutcome = ({action, outcome}: OutcomeRowsProps) => {
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const eligibility = action.eligibility
    const eligibilityInstrument = eligibility && instrumentsById[eligibility.must_hold_fund_id]
    const applicationInstrument = action.application && instrumentsById[action.application.fund_id]
    const outcomeInstrument = instrumentsById[outcome.fund_id!]

    let showEligibilityRow = false
    let detailRow: React.ReactNode = null
    const pendingPurchase = isPendingPurchase(action)

    if (eligibilityInstrument) {
        const isOutcomeShareDifferent = outcomeInstrument.id !== eligibilityInstrument.id
        const wasRemoved = action.outcome_records.some(
            o => o.fund_id === eligibility.must_hold_fund_id && o.amount_per_input_unit === '-1',
        )

        showEligibilityRow = !wasRemoved
        detailRow = (
            <RecordRow
                className={styles.dense}
                left={`${outcomeInstrument.symbol} ${shareLabel({
                    instrument: outcomeInstrument,
                    isPlural: true,
                })} ${pendingPurchase ? 'applied to buy' : 'received'} per ${
                    isOutcomeShareDifferent ? eligibilityInstrument.symbol : ''
                } ${shareLabel({
                    instrument: eligibilityInstrument,
                })} held`}
                right={<ShareValue value={outcome.amount_per_input_unit} showFullValue />}
            />
        )
    } else if (applicationInstrument && outcomeInstrument.id !== applicationInstrument.id) {
        detailRow = (
            <RecordRow
                className={styles.dense}
                left={`${outcomeInstrument.symbol} ${shareLabel({
                    instrument: outcomeInstrument,
                    isPlural: true,
                })} received per ${applicationInstrument.symbol} ${shareLabel({instrument: applicationInstrument})}`}
                right={<ShareValue value={outcome.amount_per_input_unit} showFullValue />}
            />
        )
    } else if (action.application) {
        const amountPerInputUnit =
            action.action_type === 'SHARE_ISSUE'
                ? new Decimal(outcome.cost_basis_per_share ?? '0')
                : new Decimal(1).div(new Decimal(outcome.amount_per_input_unit))

        // These are cash applications
        detailRow = (
            <RecordRow
                className={styles.dense}
                left={`Cost per ${outcomeInstrument.symbol} ${shareLabel({instrument: outcomeInstrument})}`}
                right={<DollarValue value={amountPerInputUnit.toString()} decimalPlaces={3} />}
            />
        )
    }

    return (
        <>
            {showEligibilityRow && (
                <EligibilityRow action={action as CorporateActionWithEligibility} outcome={outcome} />
            )}
            {detailRow}
            <RecordRow
                className={styles.dense}
                left={`Total number of ${outcomeInstrument.symbol} ${shareLabel({
                    instrument: outcomeInstrument,
                    isPlural: true,
                })} ${pendingPurchase ? 'applied for' : 'received'}`}
                right={<ShareValue value={outcome.gross_amount} showFullValue />}
            />
        </>
    )
}
const SharesRemovedOutcome = ({action, outcome}: OutcomeRowsProps) => {
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const eligibility = action.eligibility!
    const eligibilityInstrument = eligibility ? instrumentsById[eligibility.must_hold_fund_id] : null
    const outcomeInstrument = instrumentsById[outcome.fund_id!]
    const isNegativeOne = parseFloat(outcome.amount_per_input_unit) === -1

    const price = eligibility?.must_hold_fund_price
    const totalValue = price && new Decimal(eligibility.shares_held).mul(new Decimal(price)).toString() // TODO rounding? // TODO yikes frontend maths

    return (
        <>
            {isNegativeOne ? null : (
                <RecordRow
                    className={styles.dense}
                    left={`Number of ${shareLabel({instrument: outcomeInstrument, isPlural: true})} removed ${
                        eligibilityInstrument ? `per ${shareLabel({instrument: eligibilityInstrument})} held` : ''
                    }`}
                    right={<ShareValue value={absolute(outcome.amount_per_input_unit)} showFullValue />}
                />
            )}
            <RecordRow
                className={styles.dense}
                left={`Number of ${shareLabel({instrument: outcomeInstrument, isPlural: true})}`}
                right={<ShareValue value={absolute(outcome.gross_amount)} showFullValue />}
            />
            {price && totalValue && (
                <>
                    <RecordRow
                        className={styles.dense}
                        left={`Price per ${shareLabel({instrument: outcomeInstrument})}`}
                        right={<DollarValue value={price} currency={outcomeInstrument.currency} />}
                    />
                    <RecordRow
                        className={styles.dense}
                        left="Total value"
                        right={<DollarValue value={totalValue} currency={outcomeInstrument.currency} />}
                    />
                </>
            )}
        </>
    )
}

const DRPOutcome = ({outcome}: OutcomeRowsProps) => {
    return (
        <>
            <RecordRow
                className={styles.dense}
                left={
                    <>
                        Strike price
                        <StrikePrice />
                    </>
                }
                right={
                    outcome.alternative_payment_price ? (
                        <DividendValue value={outcome.alternative_payment_price} currency={outcome.currency!} />
                    ) : (
                        'Pending'
                    )
                }
            />
            <RecordRow
                className={styles.dense}
                left="Shares received"
                right={
                    outcome.alternative_payment_amount ? (
                        <ShareValue value={outcome.alternative_payment_amount} showFullValue />
                    ) : (
                        'Pending'
                    )
                }
            />
        </>
    )
}
const CashAddedOutcome = ({action, outcome}: OutcomeRowsProps) => {
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const customerJurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const inputUnits = action.eligibility?.shares_held ?? action.application!.application_amount! // If this application has an outcome, application_amount must be set
    const instrumentId = action.eligibility?.must_hold_fund_id ?? action.application!.fund_id
    const instrument = instrumentsById[instrumentId]
    const isDividend = ['DIVIDEND', 'SHARE_DIVIDEND'].includes(action.action_type)
    const currency = outcome.currency!
    const hasFee = parseFloat(outcome.fee_per_input_unit ?? '0') !== 0

    if (outcome.tax_records.length === 0) {
        return (
            <>
                {hasEligibility(action) && <EligibilityRow action={action} outcome={outcome} />}
                <RecordRow
                    className={styles.dense}
                    left={`Cash per ${shareLabel({instrument})}`}
                    right={<DividendValue value={outcome.amount_per_input_unit} currency={currency} />}
                />
                {isDRPShareCA(action) && (
                    <>
                        <RecordRow
                            className={styles.dense}
                            left={`${isDividend ? 'Dividend' : 'Payment'} value`}
                            right={<DividendValue value={outcome.net_amount} currency={currency} />}
                        />
                        <DRPOutcome action={action} outcome={outcome} />
                    </>
                )}
                {hasFee && <DepositoryFeeRow action={action} outcome={outcome} />}
            </>
        )
    } else {
        let grossAmount = outcome.gross_amount
        let grossAmountPerShare = outcome.amount_per_input_unit
        const irdTaxRecord = outcome.tax_records.find(ctr => ctr.owed_to === 'NZ_IRD')
        if (irdTaxRecord?.imputation_amount && irdTaxRecord.imputation_per_share) {
            // If an imputation credit was used, we should display the full, grossed-up, gross amount
            // This could use irdTaxRecord.gross_amount, except that gets rounded for reporting purposes
            grossAmount = new Decimal(grossAmount).add(new Decimal(irdTaxRecord.imputation_amount)).toString()
            grossAmountPerShare = new Decimal(grossAmountPerShare)
                .add(new Decimal(irdTaxRecord.imputation_per_share))
                .toString()
        }
        return (
            <>
                {hasEligibility(action) && <EligibilityRow action={action} outcome={outcome} />}
                <RecordRow
                    className={styles.dense}
                    left={`Gross ${isDividend ? 'dividend' : 'payment'} per ${shareLabel({instrument})} held`}
                    right={<DividendValue value={grossAmountPerShare} currency={currency} />}
                />
                <RecordRow
                    className={styles.dense}
                    left={`Gross ${isDividend ? 'dividend' : 'payment'}`}
                    right={<DividendValue value={grossAmount} currency={currency} />}
                />
                {outcome.tax_records.map(ctr => (
                    <TaxRecordRow taxRecord={ctr} action={action} outcome={outcome} key={ctr.id} />
                ))}
                {outcome.franked_portion && customerJurisdiction === 'au' && (
                    <RecordRow
                        className={styles.dense}
                        left={
                            <div>
                                Franked amount
                                <FrankedDividend isFranked />
                            </div>
                        }
                        right={<DividendValue value={outcome.franked_portion} currency={currency} />}
                    />
                )}
                {outcome.unfranked_portion && customerJurisdiction === 'au' && (
                    <RecordRow
                        className={styles.dense}
                        left={
                            <div>
                                Unfranked amount
                                <FrankedDividend />
                            </div>
                        }
                        right={<DividendValue value={outcome.unfranked_portion} currency={currency} />}
                    />
                )}
                {hasFee && <DepositoryFeeRow action={action} outcome={outcome} />}

                {isDRPShareCA(action) ? (
                    <>
                        <RecordRow
                            className={styles.dense}
                            left={`${isDividend ? 'Dividend' : 'Payment'} after tax`}
                            right={<DividendValue value={outcome.net_amount} currency={currency} />}
                        />
                        <DRPOutcome action={action} outcome={outcome} />
                    </>
                ) : (
                    <RecordRow
                        className={styles.dense}
                        left={`${isDividend ? 'Dividend' : 'Payment'} per ${shareLabel({instrument})} after tax`}
                        right={
                            <DividendValue
                                value={new Decimal(outcome.net_amount)
                                    .div(new Decimal(inputUnits))
                                    .toDecimalPlaces(8, Decimal.ROUND_UP)
                                    .toString()} // TODO yikes frontend maths
                                currency={currency}
                            />
                        }
                    />
                )}
            </>
        )
    }
}
const CashRemovedOutcome = ({action, outcome}: OutcomeRowsProps) => {
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const instrumentId = action.eligibility?.must_hold_fund_id ?? action.application!.fund_id
    const instrument = instrumentsById[instrumentId]

    return (
        <>
            {hasEligibility(action) && <EligibilityRow action={action} outcome={outcome} />}
            <RecordRow
                className={styles.dense}
                left={`Cash per ${shareLabel({instrument})}`}
                right={<DividendValue value={outcome.amount_per_input_unit} currency={outcome.currency!} />}
            />
        </>
    )
}

const AdditionalAnswersDetails: React.FC<{action: Model.CorporateActionV2}> = ({action}) => {
    if (!action.application?.additional_answers) {
        return null
    }
    const rows = getAdditionalAnswerItems(action.application.additional_answers, action.application.question_blocks)

    return (
        <>
            {rows.map(({description, value}, index) => (
                <RecordRow key={index} className={styles.dense} left={description} right={value} />
            ))}
        </>
    )
}

const OutcomeRows = ({action, outcome}: OutcomeRowsProps) => {
    const isCash = !!outcome.currency
    const isPositive = parseFloat(outcome.gross_amount) >= 0

    if (isCash) {
        if (isPositive) {
            return <CashAddedOutcome action={action} outcome={outcome} />
        } else {
            return <CashRemovedOutcome action={action} outcome={outcome} />
        }
    } else {
        if (isPositive) {
            return <SharesAddedOutcome action={action} outcome={outcome} />
        } else {
            return <SharesRemovedOutcome action={action} outcome={outcome} />
        }
    }
}
const OutcomeDetails = ({action, outcome}: OutcomeRowsProps) => {
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)

    return (
        <Concertina
            border
            title={<h5 className={styles.concertinaTitle}>{getOutcomeName(action, outcome, instrumentsById)}</h5>}
            body={
                <div className={styles.recordRowContainer}>
                    {outcome.fund_id && parseFloat(outcome.gross_amount) >= 0 && (
                        <AdditionalAnswersDetails action={action} />
                    )}

                    {(!outcome.currency || action.action_type !== 'PURCHASE') && (
                        <OutcomeRows action={action} outcome={outcome} />
                    )}

                    <p className={styles.orderAmountLabel}>{getOutcomeLabel(action, outcome, instrumentsById)}</p>
                    <div className={styles.orderFooter}>
                        <div>
                            {!isPendingPurchase(action) && (
                                <p className={styles.statusLabel}>{getOutcomeEffect(action, outcome)}</p>
                            )}
                        </div>
                        <div>
                            <p className={styles.orderAmount}>
                                {isDRPShareCA(action) && outcome.alternative_payment_amount ? (
                                    <ShareValue value={absolute(outcome.alternative_payment_amount)} showFullValue />
                                ) : outcome.fund_id ? (
                                    <ShareValue value={absolute(outcome.net_amount)} showFullValue />
                                ) : (
                                    <DollarValue value={absolute(outcome.net_amount)} currency={outcome.currency} />
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            }
        />
    )
}
const CorporateActionTimeLabel: React.FC<{action: Model.CorporateActionV2}> = ({action}) => {
    if (hasApplication(action) && action.action_type === 'VOTE') {
        const closeTime = action.application.applications_close_at
        if (closeTime) {
            return (
                <>
                    Voting {closeTime < DateTime.now() ? 'closed' : 'closes'} at{' '}
                    {closeTime.toFormat(dateFormatDayAndMonthWithTime)}
                </>
            )
        }
    }

    if (action.is_terminal) {
        return <>{action.action_timestamp.toFormat(dateFormatWithYear)}</>
    }

    return null
}
const VoteResubmitDeadlineAlert: React.FC<{action: VoluntaryCorporateActionV2}> = ({action}) => {
    if (
        !action.application.is_cancelled &&
        !action.is_terminal &&
        action.application.applications_close_at &&
        action.application.applications_close_at > DateTime.now()
    ) {
        return (
            <AlertCard
                type="info"
                text={`If you want to change any of your votes, you’ll need to withdraw and recast them${
                    action.application.applications_close_at
                        ? ` by ${action.application.applications_close_at.toFormat(dateFormatDayAndMonthWithTime)}`
                        : ''
                }.`}
            />
        )
    }
    return null
}

interface InstrumentActivityRecordCorporateActionV2Props {
    instrument: Instrument
    order: CorporateActionV2Order
}

const InstrumentActivityRecordCorporateActionV2 = ({order: action}: InstrumentActivityRecordCorporateActionV2Props) => {
    const {instrumentsById, resultsLoadingState} = useAppSelector(s => s.instrument)
    const dispatch = useAppDispatch()

    const instrument = instrumentsById[action.fund_id]

    const unloadedInstruments = (
        [
            ...action.outcome_records.map(o => o.fund_id),
            action.eligibility?.must_hold_fund_id,
            action.application?.fund_id,
        ] // Get a list of every instrument involved this CA
            .filter(i => i && !instrumentsById[i]) as string[]
    ) // Filter out ones we've already loaded
        .filter((instrument, index, arr) => arr.indexOf(instrument) === index) // Dedupe it

    useEffect(() => {
        if (resultsLoadingState === 'ready') {
            unloadedInstruments.forEach(i => {
                dispatch(instrumentActions.getSingleInstrumentById(i))
            })
        }
    }, [unloadedInstruments.join(), resultsLoadingState])

    if (resultsLoadingState === 'loading' || unloadedInstruments.length) {
        return <Loading isPineapple />
    }
    if (resultsLoadingState === 'error') {
        return <WeSlippedUp />
    }

    // We want outcomes to show in a sensible order in the UI, regardless of what the API returns
    // Negative outcomes first (shares removed, cash paid)
    // Shares first
    // Fallback to ID
    // Equivalent SQL: ORDER BY outcome.amount_per_input_unit > 0, outcome.currency, outcome.id
    const sortedOutcomes = action.outcome_records.sort((a, b) => {
        if (parseFloat(a.amount_per_input_unit) < 0 !== parseFloat(b.amount_per_input_unit) < 0) {
            return parseFloat(a.amount_per_input_unit) < 0 ? -1 : 1
        } else if (!!a.fund_id !== !!b.fund_id) {
            return a.fund_id ? -1 : 1
        } else {
            return a.id < b.id ? -1 : 1
        }
    })

    const cancelApplication = async (ca: VoluntaryCorporateActionV2) => {
        const error = await dispatch(actions.CancelApplication(ca))
        if (error) {
            return error
        }
        await dispatch(orderActions.LoadInstrumentApplications(ca.application.fund_id))
    }

    const applicationStatus = !action.application
        ? null
        : action.application?.is_cancelled
          ? 'Cancelled'
          : action.is_terminal
            ? 'Fulfilled'
            : 'Pending'

    const cashPaymentOutcome = action.outcome_records.find(
        outcome => outcome.currency && parseFloat(outcome.amount_per_input_unit) < 0,
    )

    // Calculate the net cash across outcomes - this determines the final cash cost of this CA (accounting for refunds from scaling, for example)
    const aggregatedGrossCashPayment = action.outcome_records.reduce(
        (sum, outcome) =>
            sum + (outcome.currency === cashPaymentOutcome?.currency ? -parseFloat(outcome.gross_amount) : 0),
        0,
    )

    // Don't show cash payment outcomes, unless it is for a purchase - the full breakdown including any scaling refund is shown in that case
    const outcomesToDisplay = sortedOutcomes.filter(
        outcome => outcome !== cashPaymentOutcome || action.action_type === 'PURCHASE',
    )

    return (
        <div className={cn(styles.orderContent, styles.orderCorporateAction)}>
            <div className={styles.orderTitleBlock}>
                <div className={styles.orderThumb}>
                    <InstrumentLogo instrument={instrument} noBorder />
                </div>
                <div>
                    <h4 className={styles.instrumentName}>{instrument.name}</h4>
                    {tradingType(instrument) !== 'managed' && !isInstrumentInNoTrade(instrument) && (
                        <p className={styles.instrumentSymbol}>
                            <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                            <PronounceLetters text={instrument.exchange} />
                        </p>
                    )}
                    <p className={styles.orderTime}>
                        <CorporateActionTimeLabel action={action} />
                    </p>
                </div>
            </div>
            {(action.action_type === 'SHARE_CONSOLIDATION' || action.action_type === 'SHARE_SPLIT') &&
            action.outcome_records.length === 1 ? ( // Share splits and consolidations are a special case
                <ShareSplitOrConsolidationDetails action={action as MandatoryCorporateActionV2} /> // We kinda have to just trust that we won't have a voluntary share split
            ) : (
                <>
                    <div className={cn(styles.orderDescription, mdStyles.markdown)}>
                        <CorporateActionDescription action={action} />
                    </div>
                    <div className={styles.orderDescription}>
                        {/* Votes may show a special alert  */}
                        {action.action_type === 'VOTE' && hasApplication(action) && (
                            <VoteResubmitDeadlineAlert action={action} />
                        )}
                    </div>

                    {outcomesToDisplay.length === 0 &&
                        action.application?.additional_answers && ( // answers will render on _some_ outcomes - err on the side of hiding them
                            <div className={styles.recordRowContainer}>
                                <AdditionalAnswersDetails action={action} />
                            </div>
                        )}
                    {outcomesToDisplay.map(outcome => (
                        <OutcomeDetails action={action} outcome={outcome} key={outcome.id} />
                    ))}

                    <div className={styles.closingBorder} />

                    {action.action_type === 'DIVIDEND' && action.is_terminal ? (
                        <ReinvestDividendSnippet
                            instrument={instrument}
                            dividendAmount={outcomesToDisplay[0].net_amount}
                            orderId={action.id}
                        />
                    ) : null}

                    {applicationStatus && cashPaymentOutcome && (
                        <>
                            <p className={styles.orderAmountLabel}>Amount</p>
                            <div className={styles.orderFooter}>
                                <div>
                                    <p className={styles.statusLabel} data-testid="orderStatusLabel">
                                        {applicationStatus}
                                    </p>
                                </div>
                                <div>
                                    <p className={styles.orderAmount}>
                                        <DollarValue
                                            value={aggregatedGrossCashPayment}
                                            currency={cashPaymentOutcome.currency}
                                        />
                                    </p>
                                </div>
                            </div>
                            {action.application && action.application.fx_orders.length > 0 && (
                                <FXOrdersDetail
                                    fxOrders={action.application.fx_orders}
                                    holdAmount={aggregatedGrossCashPayment}
                                    instrument={instrument}
                                />
                            )}
                            {action.application && action.application.portfolio_order_id && (
                                <AutoExerciseApplicationDetail
                                    instrument={instrument}
                                    order={action}
                                    applicationStatus={applicationStatus.toLowerCase()}
                                />
                            )}
                        </>
                    )}
                </>
            )}
            {action.application?.can_cancel && (
                <div className={spacing.spaceAbove48}>
                    <CancelApplicationModal
                        order={action as VoluntaryCorporateActionV2}
                        cancelOrder={cancelApplication}
                    />
                </div>
            )}
        </div>
    )
}

export default InstrumentActivityRecordCorporateActionV2
