import {ArrowRight} from '@design-system/icon'
import cn from 'classnames'
import numberToWords from 'number-to-words'
import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {dateFormatFullMonth} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Link} from '~/migrate-react-router'
import styles from '~/sections/invest/sections/view-instrument/sections/employee-share-scheme/EmployeeShareScheme.scss'
import {EssViews} from '~/store/employeeShareScheme/selectors'
import {
    EmployeeShareSchemeAllocation,
    EmployeeShareSchemeParticipationForInstrument,
} from '~/store/employeeShareScheme/types'
import {Instrument} from '~/store/instrument/types'

interface AllocationsSummaryProps {
    instrument: Instrument
    participationForInstrument: EmployeeShareSchemeParticipationForInstrument
    allocationsForInstrument: EmployeeShareSchemeAllocation
    hasUnvestedShares: boolean
    hasVestedShares: boolean
    essViews: EssViews
}

const pluralYear = (numberOfYears: number) => {
    if (numberOfYears === 1) {
        return 'year’s'
    }
    return 'years’'
}

const AllocationsSummary: React.FunctionComponent<AllocationsSummaryProps> = ({
    instrument,
    participationForInstrument,
    allocationsForInstrument,
    hasUnvestedShares,
    hasVestedShares,
    essViews,
}) => {
    const hasFutureAllocations = !!allocationsForInstrument.next_vesting_date
    const hasRightAllocation = essViews.hasRightAllocation
    const hasAlignmentRightAllocation = essViews.hasAlignmentRightAllocation

    return (
        <div className={cn(spacing.spaceAbove16, styles.allocationsSummary)}>
            {hasAlignmentRightAllocation ? (
                <AlignmentRightAllocationsSummary
                    hasUnvestedAllocations={hasUnvestedShares}
                    hasVestedAllocations={hasVestedShares}
                    allocationsForInstrument={allocationsForInstrument}
                />
            ) : !hasRightAllocation ? (
                <ShareAllocationsOnlySummary
                    hasUnvestedShares={hasUnvestedShares}
                    hasVestedShares={hasVestedShares}
                    allocationsForInstrument={allocationsForInstrument}
                    participationForInstrument={participationForInstrument}
                />
            ) : (
                <ShareRightAllocationsSummary allocationsForInstrument={allocationsForInstrument} />
            )}
            {hasFutureAllocations && (
                <ViewUpcomingAllocationsLink
                    instrumentId={instrument.id}
                    hasAlignmentRightAllocation={essViews.hasAlignmentRightAllocation}
                />
            )}
        </div>
    )
}

interface ShareAllocationsOnlySummaryProps {
    hasUnvestedShares: boolean
    hasVestedShares: boolean
    allocationsForInstrument: EmployeeShareSchemeAllocation
    participationForInstrument: EmployeeShareSchemeParticipationForInstrument
}

const ShareAllocationsOnlySummary: React.FunctionComponent<ShareAllocationsOnlySummaryProps> = ({
    hasUnvestedShares,
    hasVestedShares,
    allocationsForInstrument,
    participationForInstrument,
}) => {
    // grab the earliest start date of the schemes this user is a participant in (for this instrument)
    const orderedParticipationForInstrument = participationForInstrument.sort(
        (a, b) => b.start_at.toMillis() - a.start_at.toMillis(),
    )
    const schemeStartAt = orderedParticipationForInstrument ? orderedParticipationForInstrument[0].start_at : null
    const wasExistingScheme = participationForInstrument.some(participation => participation.existing_scheme)

    const totalUnvestedAllocations = numberToWords.toWords(allocationsForInstrument.total_unvested_allocations)
    const totalVestedAllocations = numberToWords.toWords(allocationsForInstrument.total_vested_allocations)
    const totalAllocations = numberToWords.toWords(
        allocationsForInstrument.total_unvested_allocations + allocationsForInstrument.total_vested_allocations,
    )

    return (
        <>
            {hasUnvestedShares && !hasVestedShares && (
                <p className={spacing.spaceBelow16}>
                    {allocationsForInstrument.total_unvested_allocations > 1
                        ? `You’ll receive ${totalUnvestedAllocations} share allocations. The first one is due to be yours on `
                        : 'You’ll receive your first share allocation on '}
                    <span className={styles.nextActionDate}>
                        {allocationsForInstrument.next_vesting_date?.toFormat(dateFormatFullMonth)}
                    </span>
                    .
                </p>
            )}
            {hasUnvestedShares && hasVestedShares && (
                <p className={spacing.spaceBelow16}>
                    {wasExistingScheme && schemeStartAt ? `Since ${schemeStartAt.year} you’ve got ` : 'You’ve got '}
                    {totalVestedAllocations} out of {totalAllocations} of your allocations. You’ll receive your next one
                    on{' '}
                    <span className={styles.nextActionDate}>
                        {allocationsForInstrument.next_vesting_date?.toFormat(dateFormatFullMonth)}
                    </span>
                    .
                </p>
            )}
            {!hasUnvestedShares && hasVestedShares && (
                <p className={spacing.spaceBelow16}>
                    You’ve received all your allocations, get in contact with your employer if you wish to opt in to the
                    scheme again.
                </p>
            )}
        </>
    )
}

interface ShareRightAllocationsSummaryProps {
    allocationsForInstrument: EmployeeShareSchemeAllocation
}

const ShareRightAllocationsSummary: React.FunctionComponent<ShareRightAllocationsSummaryProps> = ({
    allocationsForInstrument,
}) => {
    const hasUnvestedAllocationYears = !!allocationsForInstrument.total_unvested_allocation_years
    const hasVestedAllocationYears = !!allocationsForInstrument.total_vested_allocation_years
    const totalUnvestedAllocationYears = numberToWords.toWords(allocationsForInstrument.total_unvested_allocation_years)
    const totalVestedAllocationYears = numberToWords.toWords(allocationsForInstrument.total_vested_allocation_years)
    const nextActionDate = allocationsForInstrument.next_event_date
    const nextActionType = allocationsForInstrument.next_event_type

    return (
        <div>
            {hasUnvestedAllocationYears && (
                <p className={spacing.spaceBelow16}>
                    {' '}
                    {!hasVestedAllocationYears ? (
                        <>
                            You’ve got {totalUnvestedAllocationYears}{' '}
                            {pluralYear(allocationsForInstrument.total_unvested_allocation_years)} worth of upcoming
                            allocations.
                        </>
                    ) : (
                        <>
                            You’ve had {totalVestedAllocationYears}{' '}
                            {pluralYear(allocationsForInstrument.total_unvested_allocation_years)} of allocations with{' '}
                            {totalUnvestedAllocationYears} years’ worth of upcoming.
                        </>
                    )}{' '}
                    Your next allocation is due to be {nextActionType === 'vest' ? 'vested' : 'tested'} on{' '}
                    <span className={styles.nextActionDate}>{nextActionDate?.toFormat(dateFormatFullMonth)}.</span>
                </p>
            )}
            {!hasUnvestedAllocationYears && hasVestedAllocationYears && (
                <p className={spacing.spaceBelow16}>All your allocations have been received or tested.</p>
            )}
        </div>
    )
}

interface AlignmentRightAllocationsSummaryProps {
    hasUnvestedAllocations: boolean
    hasVestedAllocations: boolean
    allocationsForInstrument: EmployeeShareSchemeAllocation
}

const AlignmentRightAllocationsSummary: React.FunctionComponent<AlignmentRightAllocationsSummaryProps> = ({
    hasUnvestedAllocations,
    hasVestedAllocations,
    allocationsForInstrument,
}) => {
    const totalUnvestedAllocationYears = numberToWords.toWords(allocationsForInstrument.total_unvested_allocation_years)
    const totalVestedAllocationYears = numberToWords.toWords(allocationsForInstrument.total_vested_allocation_years)

    return (
        <>
            {hasUnvestedAllocations && !hasVestedAllocations && (
                <p className={spacing.spaceBelow16}>
                    You’ve got {totalUnvestedAllocationYears}{' '}
                    {pluralYear(allocationsForInstrument.total_unvested_allocation_years)} worth of allocations. Your
                    next vesting is due on{' '}
                    <span className={styles.nextActionDate}>
                        {allocationsForInstrument.next_vesting_date?.toFormat(dateFormatFullMonth)}
                    </span>
                    .
                </p>
            )}
            {hasUnvestedAllocations && hasVestedAllocations && (
                <p className={spacing.spaceBelow16}>
                    You’ve had {totalVestedAllocationYears}{' '}
                    {pluralYear(allocationsForInstrument.total_vested_allocation_years)} of allocations with{' '}
                    {totalUnvestedAllocationYears}{' '}
                    {pluralYear(allocationsForInstrument.total_unvested_allocation_years)} worth remaining. Your next
                    vesting is due on{' '}
                    <span className={styles.nextActionDate}>
                        {allocationsForInstrument.next_vesting_date?.toFormat(dateFormatFullMonth)}
                    </span>
                    .
                </p>
            )}
            {!hasUnvestedAllocations && hasVestedAllocations && (
                <p className={spacing.spaceBelow16}>You’ve received all your allocations.</p>
            )}
        </>
    )
}

interface ViewUpcomingAllocationsLinkProps {
    instrumentId: string
    hasAlignmentRightAllocation: boolean
}

const ViewUpcomingAllocationsLink: React.FunctionComponent<ViewUpcomingAllocationsLinkProps> = ({
    instrumentId,
    hasAlignmentRightAllocation,
}) => {
    const profileUrl = useProfileUrl()
    return (
        <Link
            data-testid="link--upcoming-allocations"
            to={profileUrl('employee-share-scheme/upcoming-allocations/:instrumentId', {instrumentId})}
            onClick={() => {
                rudderTrack('ess_allocation', 'all_allocations_clicked', {
                    allocation_type: 'allocated',
                })
            }}
        >
            View upcoming {hasAlignmentRightAllocation ? 'vesting' : 'allocations'}{' '}
            <ArrowRight className={styles.icon} />
        </Link>
    )
}

export default AllocationsSummary
