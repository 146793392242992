import {Button} from '@design-system/button'
import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {urlFor} from '~/global/routeGenerator'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {PageButtonGroup} from '~/global/widgets/button-page-group/PageButtonGroup'
import Page from '~/global/widgets/page/Page'
import {Toolbar, ToolbarButton} from '~/global/widgets/toolbar/Toolbar'
import {TC_VERSION_TYPE} from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/TermsAndConditions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/identity/actions'
import navActions from '~/store/nav/actions'
import {AUExplainer, AUAgreement, AU_TC_VERSION} from './AUTerms'
import {NZAgreement, NZ_TC_VERSION} from './NZTerms'
import styles from './TermsAndConditionsUpdate.scss'

// Pages to be shown for terms and conditions
interface TcContent {
    explainer: React.FC | null // Optional explainer page, an opportunity to explain what's happening rn
    agreement: React.FC<{
        tcAccepted: boolean
        setTcAccepted: (tcAccepted: boolean) => void
    }>
    agreementLabel: string
    tcVersion: TC_VERSION_TYPE
}

const CONTENT_FOR_JURISDICTION = {
    nz: {
        explainer: null,
        agreement: NZAgreement,
        agreementLabel: 'I have read and accept the terms',
        tcVersion: NZ_TC_VERSION,
    } as TcContent,
    au: {
        explainer: AUExplainer,
        agreement: AUAgreement,
        agreementLabel: 'Accept changes',
        tcVersion: AU_TC_VERSION,
    } as TcContent,
}

const TermsAndConditionsUpdate = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const content = CONTENT_FOR_JURISDICTION[jurisdiction]
    const [explain, setExplain] = useState<boolean>(content.explainer != null)
    const [tcAccepted, setTcAccepted] = useState<boolean>(false)
    const [processing, setProcessing] = React.useState(false)

    React.useEffect(() => {
        window.scrollTo({top: 0, behavior: 'instant'})
    }, [explain])

    const handleAccept = async () => {
        setProcessing(true)

        await dispatch(actions.acceptTermsAndConditions(content.tcVersion))
        // success - reenable sidebar and redirect
        await dispatch(navActions.ShowFlyoutMenu())
        setProcessing(false)
        sendWrapperAppMessage({type: 'goBack'})
        sendWrapperAppMessage({type: 'identityUpdated'})
        navigate(-1)
    }

    const handleAbort = async () => {
        await dispatch(actions.ExitSignUp())
        navigate(urlFor(''))
    }

    if (explain) {
        return (
            <>
                <Toolbar dataTestId="toolbar--terms-and-conditions-explainer" hideIntercom />
                {content.explainer!({})}
                <div className={styles.obscuredByStackedActionBar}></div>
                <ActionBar className={styles.stackedActionBar}>
                    <Button
                        label="Continue to review and accept"
                        dataTestId="button--explainer"
                        onClick={() => {
                            setExplain(false)
                        }}
                    />
                    <Button type="secondary" label="Log out" dataTestId="button--log-out" onClick={handleAbort} />
                </ActionBar>
            </>
        )
    }

    let toolbarLeftIcon: 'close' | 'back' = 'close'
    let toolbarLeftHandler = handleAbort
    if (content.explainer) {
        toolbarLeftIcon = 'back'
        toolbarLeftHandler = async () => setExplain(true)
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--terms-and-conditions-agreement"
                leftSlot={
                    <ToolbarButton dataTestId="toolbar--back" icon={toolbarLeftIcon} onClick={toolbarLeftHandler} />
                }
                hideIntercom
            />
            <Page>
                {content.agreement({tcAccepted, setTcAccepted})}
                <PageButtonGroup>
                    <Button
                        label={content.agreementLabel}
                        dataTestId="button--accept-terms-update"
                        processing={processing}
                        onClick={handleAccept}
                        disabled={!tcAccepted}
                    />
                </PageButtonGroup>
            </Page>
        </>
    )
}

export default TermsAndConditionsUpdate
