import {rakaiaGetFactory} from '~/api/query/rakaia'
import {AppSchemasForClientPortfolioLatestInstrumentReturn} from '~/api/rakaia'

/**
 * This is a duplicate of useRakaiaGetLatestInstrumentReturns in kiwisaver/data/rakaia.ts
 * They will likely be consolidated at some point in the future, but making a seperate copy
 * for now as it will likely be extended with specific error handling logic that I don't want
 * to automatically apply to the kiwisaver context
 */
export const useLatestInstrumentReturns = (portfolioUuid: string) => {
    const {data} = rakaiaGetFactory({
        apiFunctionName: 'getLatestInstrumentReturnsApiV1PortfoliosPortfolioUuidInstrumentsGet',
        notFoundResponse: (): {
            instrument_returns: {[key: string]: AppSchemasForClientPortfolioLatestInstrumentReturn}
        } => ({
            instrument_returns: {},
        }),
    })({portfolioUuid})
    return data
}
