import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import IlliquidModal from '~/global/widgets/help-modals/Illiquid'
import HighlightBlock from '~/global/widgets/highlight-block/HighlightBlock'
import viewInstrumentStyles from '~/sections/invest/sections/view-instrument/ViewInstrument.scss'

const IlliquidNotification = () => (
    <>
        <div className={cn(viewInstrumentStyles.label, spacing.spaceAbove8)}>
            <IlliquidModal />
            Price updates might be limited
        </div>
        <HighlightBlock className={viewInstrumentStyles.illiquid}>
            <p>
                This investment isn’t currently available to buy and sell. It’s an{' '}
                <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.sharesies.nz/learn/a-deep-dive-into-investment-liquidity"
                >
                    illiquid
                </a>{' '}
                investment and has more risks associated with it.
            </p>
        </HighlightBlock>
    </>
)

export default IlliquidNotification
