import React from 'react'
import {useDistillInstrumentBySlug} from '~/sections/moose/data/distill'
import {usePortfolioOrders} from '~/sections/moose/data/retail'
import {MoosePortfolioPathParams} from '~/sections/moose/routes'
import {MoosePortfolioOrderCarousel} from '~/sections/moose/sections/instrument/widgets/portfolio-order-carousel/MoosePortfolioOrderCarousel'

interface MooseRecentOrdersProps extends MoosePortfolioPathParams {
    urlSlug: string
}

export const MooseRecentOrders: React.FunctionComponent<MooseRecentOrdersProps> = ({urlSlug, portfolioId}) => {
    const instrument = useDistillInstrumentBySlug(urlSlug)
    const orders = usePortfolioOrders(portfolioId, instrument.id).orders

    return <MoosePortfolioOrderCarousel portfolioId={portfolioId} instrument={instrument} orders={orders} />
}
