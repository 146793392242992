import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {PageButtonGroup} from '~/global/widgets/button-page-group/PageButtonGroup'
import {Loading} from '~/global/widgets/loading'
import Animation, {getAnimationData} from '~/global/widgets/lottie-animation/Animation'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import styles from '~/sections/user/sections/sign-up/SignUp.scss'
import {connect} from '~/store/connect'

const IdentityVerificationResult: React.FunctionComponent<IdentityVerificationResultProps> = ({
    preferredName,
    additionalVerificationRequired,
}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const [animData, setAnimData] = React.useState<object>()

    React.useEffect(() => {
        getAnimationData('confirmation').then(setAnimData)
    }, [])

    if (!animData) {
        return <Loading isPineapple />
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--identity-verification-result"
                leftButton="close"
                onLeftButtonClick={() => navigate('/')}
            />
            <Page className={styles.splashContainer}>
                <Animation
                    className={cn(styles.centered, styles.verificationSuccessAnimation)}
                    animationData={animData}
                    loop={false}
                />
                {additionalVerificationRequired ? (
                    <>
                        <h2>ID verification done, we just need to review a few things</h2>
                        <p className={spacing.spaceAbove24}>
                            Now that we’ve got everything we need, a human will give your ID details a quick once
                            over—it’ll only take a day or two.
                            <br />
                            <br />
                            Once we’ve verified your ID, you’ll be able to buy, sell, top up, and withdraw.
                        </p>
                    </>
                ) : (
                    <h2>
                        We’ve verified your ID, <br /> {preferredName}
                    </h2>
                )}
                <PageButtonGroup>
                    <Button
                        label="View Portfolio"
                        onClick={() => navigate(profileUrl(''))}
                        dataTestId="button--view-portfolio"
                    />
                </PageButtonGroup>
            </Page>
        </>
    )
}

interface StoreProps {
    preferredName: string
}

interface OwnProps {
    additionalVerificationRequired?: boolean
}

type IdentityVerificationResultProps = StoreProps & OwnProps

export default connect<StoreProps, {}, OwnProps>(({identity}) => ({
    preferredName: identity.user!.preferred_name,
}))(IdentityVerificationResult)
