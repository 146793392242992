import cn from 'classnames'
import React from 'react'
import {FocusOn} from 'react-focus-on'
import {isInstrumentDerivative} from '~/global/utils/is-instrument-derivative/isInstrumentDerivative'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import IntroSlides from '~/global/widgets/intro-slides/IntroSlides'
import PreventScroll from '~/global/widgets/prevent-scroll/PreventScroll'
import {useLocation, useNavigate} from '~/migrate-react-router'
import OrderTypePicker from '~/sections/invest/sections/order-flow/pages/type-picker/OrderTypePicker'
import ApplicationForm from '~/sections/invest/sections/order-flow/sections/apply/pages/apply-form/ApplicationForm'
import BuyOrderForm from '~/sections/invest/sections/order-flow/sections/buy/pages/buy-order-form/BuyOrderForm'
import SellOrderForm from '~/sections/invest/sections/order-flow/sections/sell/pages/sell-order-form/SellOrderForm'
import {
    exchangeFundIntroContent,
    managedFundIntroContent,
    rightsOfferContent,
    voteIntroContent,
} from '~/sections/invest/sections/order-flow/widgets/intro-slides-content/IntroSlidesContent'
import {essHasActiveBlackoutPeriodForInstrument} from '~/store/employeeShareScheme/selectors'
import {useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import styles from './InvestPanel.scss'

type ExpectedUrlPrefxes = 'invest' | 'explore/recently-viewed' | 'explore/watchlist'

export const InvestPanel: React.FunctionComponent<InvestPanelProps> = ({instrument}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const {pathname, state} = useLocation()
    const [investPanelShown, setInvestPanelShown] = React.useState<boolean>(false)
    const [prevFocusOnState, setPrevFocusOnState] = React.useState<boolean>(
        document.body.classList.contains('hasAccess'),
    )
    const [focusOnState, setFocusOnState] = React.useState<boolean>(false)

    const hasSeenManagedFundsIntro = useAppSelector(store => store.identity.user!.has_seen.managed_funds_investor)
    const hasSeenExchangeFundsIntro = useAppSelector(store => store.identity.user!.has_seen.exchange_investor)
    const hasSeenVotingIntro = useAppSelector(store => store.identity.user!.has_seen.voting_intro_modal)
    const hasSeenRightsIntro = useAppSelector(store => store.identity.user!.has_seen.rights_intro_modal)
    const essHasActiveBlackoutPeriod = useAppSelector(s => essHasActiveBlackoutPeriodForInstrument(s, instrument.id))

    const {applications} = useAppSelector(
        store => store.order.applicationsByInstrument[instrument.id] || {applications: []},
    )

    // redirect to view instrument page if user cannot place a buy or sell
    if (essHasActiveBlackoutPeriod && (pathname.includes('/buy') || pathname.includes('/sell'))) {
        const urlPrefix = pathname.substring(1).split(`/${instrument.urlSlug}`)[0] as ExpectedUrlPrefxes
        navigate(
            profileUrl(`${urlPrefix ? urlPrefix : 'invest'}/:instrumentSlug`, {instrumentSlug: instrument.urlSlug}),
            {
                replace: true,
            },
        )
    }

    const applyRegex = /\/apply\/([\d\-a-f]{36})/
    const panelToShow = pathname.includes('/pick')
        ? 'pick'
        : pathname.includes('/buy')
          ? 'buy'
          : pathname.includes('/sell')
            ? 'sell'
            : applyRegex.test(pathname)
              ? 'apply'
              : null

    const applicationRuleId = panelToShow === 'apply' ? pathname.match(applyRegex)![1] : null
    const application = applications.find(a => a.application_rule_id === applicationRuleId)

    // List of shards to be focusable via keyboard while panel is open
    const focusAllowed = getFocusableElements()

    const investPanelAnimated = !(state as any)?.suppressAnimation

    let introSlides: React.ReactElement | undefined
    if (panelToShow === 'apply' && application?.type === 'VOTE') {
        if (!hasSeenVotingIntro) {
            introSlides = <IntroSlides content={voteIntroContent} flag="voting_intro_modal" />
        }
    } else if (isInstrumentDerivative(instrument)) {
        if (!hasSeenRightsIntro) {
            introSlides = <IntroSlides content={rightsOfferContent} flag="rights_intro_modal" />
        }
    } else if (tradingType(instrument) === 'managed') {
        if (!hasSeenManagedFundsIntro) {
            introSlides = (
                <IntroSlides content={managedFundIntroContent} flag="managed_funds_investor" layout="classicIntro" />
            )
        }
    } else {
        if (!hasSeenExchangeFundsIntro) {
            introSlides = (
                <IntroSlides content={exchangeFundIntroContent} flag="exchange_investor" layout="classicIntro" />
            )
        }
    }

    React.useEffect(() => {
        setInvestPanelShown(!!panelToShow)
    }, [panelToShow])

    React.useEffect(() => {
        if (investPanelShown && !introSlides) {
            setTimeout(() => {
                // fix an iPhone 5 issue where the keyboard focus scrolls way down
                window.scrollTo(0, 0)
            }, 801) // execute after focus event
        }
    }, [investPanelShown, introSlides])

    let form: JSX.Element | undefined
    switch (panelToShow) {
        case 'pick':
            form = <OrderTypePicker instrument={instrument} />
            break
        case 'buy':
            form = <BuyOrderForm instrument={instrument} />
            break
        case 'sell':
            form = <SellOrderForm instrument={instrument} />
            break
        case 'apply':
            if (application) {
                form = <ApplicationForm application={application} />
            }
            break
    }
    // prevent FocusOn from activating by default and preventing interaction with DockableModal (https://sharesies.atlassian.net/jira/software/c/projects/DS/boards/22?modal=detail&selectedIssue=DS-812)
    React.useEffect(() => {
        const element = document.querySelector('body')
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                const DOMTarget = mutation.target as Element

                if (mutation.attributeName === 'class') {
                    const currentState = DOMTarget.classList.contains('hasAccess')
                    if (prevFocusOnState !== currentState) {
                        setPrevFocusOnState(currentState)
                        setFocusOnState(currentState && !!form)
                    }
                }
            })
        })

        if (element) {
            observer.observe(element, {
                attributes: true,
                attributeOldValue: true,
                attributeFilter: ['class'],
            })
        }
        return () => observer.disconnect()
    }, [prevFocusOnState])

    return (
        <>
            <PreventScroll condition={investPanelShown} />

            <div className={cn({[styles.investPanelBackground]: investPanelShown})} />
            <div
                className={cn(styles.investPanel, {
                    [styles.investPanelShown]: investPanelShown,
                })}
                aria-modal
            >
                <div className={cn({[styles.investPanelContentAnimateIn]: investPanelShown && investPanelAnimated})}>
                    <FocusOn enabled={focusOnState} shards={focusAllowed} className={styles.investPanelFocusContainer}>
                        {introSlides || form}
                    </FocusOn>
                </div>
            </div>
        </>
    )
}

interface InvestPanelProps {
    instrument: Instrument
}

export default InvestPanel

function getFocusableElements() {
    const nav = document.getElementById('navigation')
    return nav ? [nav] : []
}
