import {ArrowDown, ArrowUp} from '@design-system/icon'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import tooltipStyles from '~/global/scss/reused-styles/tooltip.scss'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import Tooltip from '~/global/widgets/tooltip/Tooltip'
import labelledValuesStyles from '~/sections/invest/sections/view-instrument/LabelledValues.scss'
import {useUsLivePricing} from '~/sections/invest/sections/view-instrument/hooks/usLivePricing'
import {useAppSelector} from '~/store/hooks'
import {selectHasUsLivePricing} from '~/store/identity/selectors'
import {selectIsExchangeInMarketHours, selectIsInstrumentInExtendedHours} from '~/store/instrument/selectors'
import {Instrument} from '~/store/instrument/types'
import styles from './SharePriceChange.scss'

interface SharePriceChangeProps {
    instrument: Instrument
}

interface ClosePrice {
    date: DateTime
    price: number
}

const previousTradingDayClosePrice = (instrument: Instrument): ClosePrice | undefined => {
    const closePricesByDay = instrument.priceHistory?.dayPrices

    if (!closePricesByDay) {
        return undefined
    }

    const instrumentPriceDaysAsc = Object.keys(closePricesByDay).sort()

    /**
     * The date of the previous trading day.
     * This will always be >= 1 calendar days ago.
     * e.g. if it's currently Thursday (even Thursday night), this variable will refer to Wednesday.
     */
    const prevTradingDay = instrumentPriceDaysAsc[instrumentPriceDaysAsc.length - 1]

    return {
        date: DateTime.fromISO(prevTradingDay),
        price: parseFloat(closePricesByDay[prevTradingDay]),
    }
}

/**
 * What price we want depends on the time of day.
 * - pre-market or market hours: previous trading day's close price
 * - post-market: current trading day's close price
 */
const getComparisonPrice = (
    instrument: Instrument,
    inMarketHours: boolean,
    inExtendedHours: boolean | {inPreTrading: boolean; inPostTrading: boolean},
): ClosePrice | undefined => {
    if (inMarketHours) {
        return previousTradingDayClosePrice(instrument)
    }

    if (!inExtendedHours) {
        return undefined
    }

    // Okay, we're in extended hours. This means that `inExtendedHours` will be an object with pre/post trading fields,
    // we just have to do a check here so that Typescript will let us access those fields
    if (typeof inExtendedHours !== 'object') {
        return undefined
    }

    if (inExtendedHours.inPreTrading) {
        return previousTradingDayClosePrice(instrument)
    }

    if (inExtendedHours.inPostTrading) {
        // If we're in the post-market session, we want to use the close price from today in our comparison.
        // `instrument.marketPrice` will not update after regular market close, so once we're in post-trading,
        // this figure is the de facto close price for the current trading day.
        // You might be thinking: how does this work with extended hours?
        // In extended hours, the price is a separate figure stored in `instrument.extendedHoursPrice`.

        return {
            date: instrument.marketLastCheck,
            price: parseFloat(instrument.marketPrice),
        }
    }
}

const LiveUsSharePriceChange: React.FunctionComponent<SharePriceChangeProps> = ({instrument}) => {
    const usLivePricing = useUsLivePricing(instrument)
    const inMarketHours = useAppSelector(s => selectIsExchangeInMarketHours(s, 'CBOE'))
    const inExtendedHours = useAppSelector(s => selectIsInstrumentInExtendedHours(s, instrument)) // This selector also checks if they've enabled extended hours

    // Bail out if we don't have US live pricing
    if (!usLivePricing) {
        return null
    }

    const comparisonPrice = getComparisonPrice(instrument, inMarketHours, inExtendedHours)

    // Bail out if we don't have a relevant price to compare the live price to
    if (!comparisonPrice) {
        return null
    }

    const priceChange = ((parseFloat(usLivePricing.tradePrice) - comparisonPrice.price) / comparisonPrice.price) * 100

    return (
        <div
            className={cn(
                styles.container,
                priceChange > 0 && styles.positive,
                priceChange < 0 && styles.negative,
                priceChange === 0 && styles.noChange,
            )}
        >
            {priceChange > 0 && <ArrowUp className={styles.arrow} />}{' '}
            {priceChange < 0 && <ArrowDown className={styles.arrow} />}
            <Tooltip>
                <Tooltip.Label className={labelledValuesStyles.label}>{priceChange.toFixed(2)}% </Tooltip.Label>
                <Tooltip.Body className={tooltipStyles.tooltip}>
                    <p>Price change since market close on {comparisonPrice.date.toFormat(dateFormatNoTime)}.</p>
                </Tooltip.Body>
            </Tooltip>
        </div>
    )
}

const LiveUsSharePriceChangeWrapper: React.FunctionComponent<SharePriceChangeProps> = ({instrument}) => {
    const hasUsLivePricing = useAppSelector(s => selectHasUsLivePricing(s))

    if (!hasUsLivePricing) {
        return null
    }

    return (
        <React.Suspense fallback={null}>
            <LiveUsSharePriceChange instrument={instrument} />
        </React.Suspense>
    )
}

export default LiveUsSharePriceChangeWrapper
