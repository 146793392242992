import {SelectCard} from '@design-system/select-cards'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {spaceBelow16, spaceBelow32} from '~/global/scss/helpers/spacing.scss'
import {PageTitle} from '~/sections/kiwisaver/widgets/page-title/PageTitle'

export interface QuestionCardsListProps<T> {
    setStateForQuestion: React.Dispatch<React.SetStateAction<T>>
    activeValue?: string
    titleText: string
    containerCSSName: string
    selectCards: {cardText: string; cardValue: T}[]
    shortCard?: boolean
    subtext?: string | undefined
    cardSupportingText?: string[]
}

export const QuestionCardsList = <T,>({
    setStateForQuestion,
    activeValue,
    titleText,
    containerCSSName,
    selectCards,
    shortCard = false,
    subtext = undefined,
    cardSupportingText = undefined,
}: QuestionCardsListProps<T>) => {
    const handleCardChange = (e: T) => {
        setStateForQuestion(e)
    }

    return (
        <>
            <PageTitle className={`${subtext !== undefined ? spaceBelow16 : spaceBelow32}`}>{titleText}</PageTitle>
            {subtext && <p className={spacing.spaceBelow32}>{subtext}</p>}
            <div className={containerCSSName}>
                {selectCards.map((card, idx) => {
                    return (
                        <SelectCard
                            key={card.cardText}
                            short={shortCard}
                            dataTestId="test"
                            supportingText={cardSupportingText ? cardSupportingText[idx] : undefined}
                            value={card.cardValue + ''}
                            name={card.cardText}
                            title={card.cardText}
                            isActive={activeValue ? activeValue === card.cardValue : false}
                            onChange={() => {
                                handleCardChange(card.cardValue)
                            }}
                        />
                    )
                })}
            </div>
        </>
    )
}
