import React from 'react'
import {Model} from '~/api/retail/types'
import {exerciseLaterBuyCostBreakdown, autoExerciseBuyCostBreakdown} from '~/global/utils/exercise-cost/exerciseCost'
import {dateFormatDayAndMonthWithTime} from '~/global/utils/format-date/formatDate'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {useInstrument} from '~/global/utils/use-instruments/useInstruments'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import BuyAndSellFeesRights from '~/global/widgets/help-modals/BuyAndSellFeesRights'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import styles from '~/sections/invest/sections/order-flow/OrderForm.scss'
import {useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import {StagedBuyOrder} from '~/store/order/types'

interface ExerciseCostBreakdownProps {
    instrument: Instrument
    orderAmount: string
    stagedBuyOrder: StagedBuyOrder
    priceLimit?: string
    jurisdiction: Model.User['jurisdiction']
}

const ExerciseCostBreakdown: React.FunctionComponent<ExerciseCostBreakdownProps> = ({
    instrument,
    orderAmount,
    stagedBuyOrder,
    priceLimit,
    jurisdiction,
}) => {
    // ignore any potential fees in calculations
    const autoExerciseCostBreakdown = autoExerciseBuyCostBreakdown(instrument, orderAmount, jurisdiction, priceLimit)
    const exerciseLaterCostBreakdown = exerciseLaterBuyCostBreakdown(instrument, orderAmount, priceLimit)
    const parentInstrument = useInstrument(instrument.parentInstrumentId)

    const applications = useAppSelector(s => s.order.applicationsByInstrument[instrument.id]?.applications)
    const exerciseApplication = applications?.find(a => a.fund_id === instrument.id && a.type === 'EXERCISE')

    if (stagedBuyOrder.autoExercise && !autoExerciseCostBreakdown) {
        return null
    }
    if (!stagedBuyOrder.autoExercise && !exerciseLaterCostBreakdown) {
        return null
    }
    if (!parentInstrument || !exerciseApplication) {
        return null
    }

    const applicationsCloseAt = exerciseApplication.applications_close_at!.toFormat(dateFormatDayAndMonthWithTime)

    return (
        <AlertCard type="none" data-testid="alert--exercise-cost-breakdown" className={styles.exerciseCostBreakdown}>
            {stagedBuyOrder.autoExercise && autoExerciseCostBreakdown && (
                <p>
                    Your buy order for {shareLabel({instrument, isPlural: true})} will cost{' '}
                    {priceLimit ? 'at most' : 'about'}{' '}
                    <strong>
                        <DollarValue
                            value={autoExerciseCostBreakdown.estimatedCostToBuy}
                            currency={instrument.currency}
                            decimalPlaces={2}
                        />
                    </strong>
                    . The remaining{' '}
                    <strong>
                        <DollarValue
                            value={autoExerciseCostBreakdown.estimatedCostToExercise}
                            currency={instrument.currency}
                            decimalPlaces={2}
                        />
                    </strong>{' '}
                    will be used to exercise the {shareLabel({instrument, isPlural: true})} into{' '}
                    {shareLabel({instrument: parentInstrument, isPlural: true})}. This estimate hasn’t had{' '}
                    <BuyAndSellFeesRights label="fees" /> applied.{' '}
                </p>
            )}
            {!stagedBuyOrder.autoExercise && exerciseLaterCostBreakdown && (
                <p>
                    Heads up, it’ll cost you an additional{' '}
                    <strong>
                        <DollarValue
                            value={exerciseLaterCostBreakdown.estimatedCostToExercise}
                            currency={instrument.currency}
                            decimalPlaces={2}
                        />
                    </strong>{' '}
                    {priceLimit && 'at most'} if you decide to exercise these {shareLabel({instrument, isPlural: true})}{' '}
                    into {shareLabel({instrument: parentInstrument, isPlural: true})}. You’ll also have the option to
                    sell them up until <strong>{applicationsCloseAt}</strong>.
                </p>
            )}
        </AlertCard>
    )
}

export default ExerciseCostBreakdown
