import {Button} from '@design-system/button'
import cn from 'classnames'
import {useAtom} from 'jotai'
import {DateTime} from 'luxon'
import React from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import {useRetailPost} from '~/api/query/retail'
import {Request} from '~/api/retail/types'
import * as rollbar from '~/api/rollbar/rollbar'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {OrderConfirmation} from '~/global/widgets/order-confirmation/OrderConfirmation'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/save/sections/transfer/Transfer.scss'
import {useInvalidateSaveCache} from '~/sections/save/state/invalidateSaveCache'
import {stagedTransferAtom} from '~/sections/save/state/transferState'
import {intervalToString} from '~/sections/save/utils/interval-to-string/intervalToString'
import {useAppSelector} from '~/store/hooks'
import {TransferAccountDetails} from '~/store/save/types'

const TransferConfirm: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const invalidateSaveCache = useInvalidateSaveCache()
    const [stagedTransfer, setStagedTransfer] = useAtom(stagedTransferAtom)
    const createSaveTransfer = useRetailPost({
        path: 'save/v2/:portfolio_id/transfer',
        pathParams: {portfolio_id: stagedTransfer!.originAccount.portfolioId},
        queryCacheToUpdate: ['profiles'],
    })
    const createRecurringTransfer = useRetailPost({
        path: 'save/v2/:portfolio_id/recurring-transfer',
        pathParams: {portfolio_id: stagedTransfer!.originAccount.portfolioId},
        queryCacheToInvalidate: ['profiles'],
    })

    const [loading, setLoading] = React.useState(false)

    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)

    if (!stagedTransfer) {
        return <Navigate to={profileUrl('')} replace />
    }

    const {originAccount, targetAccount, amount, instruction, interval, nextDate, meta} = stagedTransfer

    const orderConfirmationItems = () => {
        const rows = [
            {description: 'From', value: originAccount.name, isBold: true},
            {
                description: 'To',
                value: targetAccount.name,
                isBold: true,
            },
        ]

        if (interval && nextDate) {
            rows.push({description: 'Repeat', value: intervalToString[interval], isBold: true})
            rows.push({
                description: 'Start date',
                value: DateTime.fromFormat(nextDate, 'yyyy-MM-dd').toFormat('dd LLLL yyyy'),
                isBold: true,
            })
        }

        return rows
    }

    const transferSummary = {
        description: 'Amount',
        value: <DollarValue value={Number(amount)} currency={homeCurrency} decimalPlaces={2} />,
    }

    const performSaveTransfer = async (saveAccount: TransferAccountDetails, saveAccountForNav: string) => {
        const payload: Request.SaveTransferV2 = {
            target_portfolio_id: targetAccount.portfolioId,
            amount,
            instruction,
        }

        try {
            await createSaveTransfer.mutateAsync(payload, {
                onSuccess: () => {
                    const isWalletTransfer = [originAccount.type, targetAccount.type].includes('wallet')
                    if (isWalletTransfer) {
                        const action =
                            instruction === 'DEPOSIT' ? 'transfer_deposit_confirmed' : 'transfer_withdraw_confirmed'
                        const delta = instruction === 'DEPOSIT' ? amount : -amount

                        rudderTrack('save', action, {
                            transfer_amount: amount,
                            current_save_total: saveAccount.balance + delta,
                        })
                    }

                    Toast('You have transferred cash')
                    invalidateSaveCache()
                    setStagedTransfer(undefined)
                    navigate(profileUrl('save/:portfolioId', {portfolioId: saveAccountForNav}), {replace: true})
                },
            })
        } catch (error) {
            Toast('There was an error processing your Save transfer request')
            if (error instanceof Error && error.message) {
                rollbar.sendError(`save transfer confirm: unexpected error`, {
                    message: error.message,
                })
            }
        } finally {
            setLoading(false)
        }
    }

    const performSaveRecurringTransfer = async (saveAccount: TransferAccountDetails, saveAccountForNav: string) => {
        if (!interval) {
            return
        }

        const payload: Request.SaveRecurringTransferV2 = {
            save_account_id: saveAccount.saveAccountId!,
            value: amount,
            value_type: 'DOLLAR',
            direction: instruction,
            interval,
            next_run: nextDate!,
            time_event_id: meta?.recurringTransferId,
        }
        try {
            await createRecurringTransfer.mutateAsync(payload, {
                onSuccess: () => {
                    Toast('Recurring transfer created')
                    invalidateSaveCache()
                    setStagedTransfer(undefined)
                    navigate(profileUrl('save/:portfolioId', {portfolioId: saveAccountForNav}))
                },
            })
        } catch (error) {
            Toast('There was an error creating your recurring Save transfer')
            if (error instanceof Error && error.message) {
                rollbar.sendError(`save recurring transfer confirm: unexpected error`, {
                    message: error.message,
                })
            }
        } finally {
            setLoading(false)
        }
    }

    const handleConfirmClick = () => {
        setLoading(true)
        const saveAccount = originAccount.type === 'save' ? originAccount : targetAccount
        const saveAccountForNav = targetAccount.type === 'save' ? targetAccount.portfolioId : originAccount.portfolioId
        // Figure out what kind of transfer and call appropriate function
        if (interval && nextDate) {
            performSaveRecurringTransfer(saveAccount, saveAccountForNav)
        } else {
            performSaveTransfer(saveAccount, saveAccountForNav)
        }
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--save-transfer" leftButton="back" />
            <Page className={styles.transfer}>
                <h1
                    data-testid="heading--save-transfer-confirm"
                    className={cn(styles.confirmHeading, spacing.spaceBelow24)}
                >
                    Confirmation
                </h1>
                <OrderConfirmation items={orderConfirmationItems()} total={transferSummary} />
                {instruction === 'WITHDRAW' && (
                    <p className={styles.disclosure}>
                        This will hit your Wallet straight away, but taking it out of Sharesies may take up to one
                        business day.
                    </p>
                )}
                {instruction === 'DEPOSIT' && (
                    <p className={styles.disclosure}>
                        Just a heads up, you won’t start earning interest until the funds have cleared. This can take up
                        to one business day.
                    </p>
                )}
                <Button
                    dataTestId="button--save-transfer-confirm"
                    type="primary"
                    label="Confirm"
                    onClick={handleConfirmClick}
                    additionalClassName={spacing.spaceAbove24}
                    processing={loading}
                />
            </Page>
        </>
    )
}

export default TransferConfirm
