import React from 'react'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {useEssReturnPercentage} from '~/global/state-hooks/mixed-source/useEssReturnPercentage'
import {useEssViewsForInstrument} from '~/global/state-hooks/mixed-source/useEssViewsForInstrument'
import {useLatestInstrumentReturns} from '~/global/state-hooks/rakaia/useLatestInstrumentReturns'
import {useEmployeeShareSchemeDetails} from '~/global/state-hooks/retail/useEmployeeShareSchemeDetails'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import TabControls from '~/global/widgets/tab-controls/TabControls'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import essActions from '~/store/employeeShareScheme/actions'
import {possibleESS} from '~/store/employeeShareScheme/selectors'
import {State} from '~/store/employeeShareScheme/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'
import UpcomingAlignmentRightAllocations from './UpcomingAlignmentRightAllocations'
import UpcomingShareAllocations from './UpcomingShareAllocations'
import UpcomingShareRightAllocations from './UpcomingShareRightAllocations'

interface UpcomingAllocationsProps {
    instrumentId: string
}

const UpcomingAllocations: React.FunctionComponent<UpcomingAllocationsProps> = ({instrumentId}) => {
    const dispatch = useAppDispatch()
    const profile = useProfile()

    const investPortfolio = profile.portfolios.find(p => p.product === 'INVEST')!
    const currentTab = useAppSelector(s => s.employeeShareScheme.upcomingAllocationsPageCurrentTab)
    const instrument = useAppSelector(s => s.instrument.instrumentsById[instrumentId])
    const metadataInitialised = useAppSelector(s => s.instrument.isMetadataInitialised)
    const currentReturn = useLatestInstrumentReturns(investPortfolio.id).instrument_returns[instrumentId]
    const returnPercentage = useEssReturnPercentage(instrumentId)
    const isPossiblyESS = useAppSelector(s => possibleESS(s))
    const essViews = useEssViewsForInstrument(instrumentId)

    // fetch the allocations
    const shareSchemeAllocationsByInstrument = useEmployeeShareSchemeDetails().shareSchemeAllocationsByInstrument
    const allocationsForInstrument = shareSchemeAllocationsByInstrument[instrumentId]

    const handleChangeTab = (event: State['upcomingAllocationsPageCurrentTab']) => {
        if (event !== currentTab) {
            dispatch(essActions.SetUpcomingAllocationsPageCurrentTab(event))
        }
    }

    React.useEffect(() => {
        rudderTrack('ess_allocation', 'all_allocations_viewed', {
            allocation_type: 'allocated',
        })
    }, [])

    React.useEffect(() => {
        // try loading instrument directly in case of customer landing on this page - wait for metadata first
        if (metadataInitialised && !instrument) {
            dispatch(instrumentActions.getSingleInstrumentById(instrumentId))
        }
    }, [instrumentId, metadataInitialised])

    React.useEffect(() => {
        if (
            isPossiblyESS &&
            // fetch allocations if they're uninitialised
            !allocationsForInstrument
        ) {
            dispatch(essActions.FetchParticipatingShareSchemes())
        }
    }, [isPossiblyESS, instrumentId])

    if (!allocationsForInstrument && !essViews.isActiveMember) {
        return <WeSlippedUp />
    }

    const showAllocations =
        (essViews.hasMultipleAllocationTypes && currentTab === 'Shares') ||
        (!essViews.hasMultipleAllocationTypes && essViews.hasAllocation)

    const showShareRightsAllocations =
        (essViews.hasMultipleAllocationTypes && currentTab === 'Share rights') ||
        (!essViews.hasMultipleAllocationTypes && essViews.hasRightAllocation)

    const showAlignmentRightsAllocations =
        (essViews.hasMultipleAllocationTypes && currentTab === 'Alignment rights') ||
        (!essViews.hasMultipleAllocationTypes && essViews.hasAlignmentRightAllocation)

    if (
        !essViews.isActiveMember ||
        !allocationsForInstrument ||
        !instrument ||
        (showAllocations && !currentReturn) ||
        (showAllocations && returnPercentage === undefined)
    ) {
        return <Loading isPineapple />
    }

    function getTabs() {
        if (!essViews.hasMultipleAllocationTypes) {
            return []
        }

        const tabs = []
        if (essViews.hasAllocation) {
            tabs.push({label: 'Shares', value: 'Shares'})
        }
        if (essViews.hasRightAllocation) {
            tabs.push({label: 'Share rights', value: 'Share rights'})
        }
        if (essViews.hasAlignmentRightAllocation) {
            tabs.push({label: 'Alignment rights', value: 'Alignment rights'})
        }

        return tabs
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--upcoming-allocations"
                leftButton="back"
                title={essViews.hasAlignmentRightScheme ? 'Allocations' : 'Upcoming allocations'}
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                {essViews.hasMultipleAllocationTypes && (
                    <TabControls tabs={getTabs()} currentTab={currentTab} onChangeTab={handleChangeTab} />
                )}
                {showAlignmentRightsAllocations && (
                    <UpcomingAlignmentRightAllocations
                        instrument={instrument}
                        allocationsForInstrument={allocationsForInstrument}
                    />
                )}

                {showShareRightsAllocations && (
                    <UpcomingShareRightAllocations
                        instrument={instrument}
                        allocationsForInstrument={allocationsForInstrument}
                    />
                )}
                {showAllocations && currentReturn && (
                    <UpcomingShareAllocations
                        instrument={instrument}
                        allocationsForInstrument={allocationsForInstrument}
                        currentReturn={currentReturn}
                    />
                )}
            </Page>
        </>
    )
}

export default UpcomingAllocations
