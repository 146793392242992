import React from 'react'
import {Model} from '~/api/retail/types'

interface DetailProps {
    jurisdiction: Model.User['jurisdiction']
}

const ManagementFeesDetail: React.FunctionComponent<DetailProps> = ({jurisdiction}) => {
    return (
        <div>
            <h1>Management Fees</h1>

            {jurisdiction === 'nz' ? (
                <>
                    <p>
                        When you invest in an ETF or managed fund, you’re charged a management fee. This is charged by
                        the fund provider—not Sharesies—and included in the unit price.
                    </p>
                    <p>
                        The management fee pays for the fund’s management, distribution, and operational costs. It’s
                        different for each fund, so check the fund info before you invest.
                    </p>
                    <p>
                        We charge managed fund providers a fee, which you can expect to make up some part of the
                        management fee they charge.
                    </p>
                </>
            ) : (
                <>
                    <p>
                        When you invest in an ETF, you’re charged a management fee. This is charged by the fund
                        provider—not Sharesies—and included in the unit price.
                    </p>
                    <p>
                        The management fee pays for the fund’s management, distribution, and operational costs. It’s
                        different for each fund, so check the fund info before you invest.
                    </p>
                </>
            )}

            <p>
                For a full run-down of our fees,{' '}
                <a
                    target="_blank"
                    rel="noopener"
                    href={
                        jurisdiction === 'au'
                            ? 'https://www.sharesies.com.au/pricing'
                            : 'https://www.sharesies.nz/pricing'
                    }
                >
                    check out our website
                </a>
                .
            </p>
        </div>
    )
}

export default ManagementFeesDetail
