import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import AsxTransfersTermsAndConditions from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/AsxTransfersTermsAndConditions'

const LegalUSTransfersTerms: React.FunctionComponent<{}> = () => (
    <>
        <Toolbar dataTestId="toolbar--save-terms" leftButton="back" title="Legal" />
        <Page overrideDefaultTopPadding="withToolbarTitle">
            <h2 className={cn(spacing.spaceBelow16)}>ASX Transfer Terms &amp; Conditions</h2>
            <AsxTransfersTermsAndConditions />
        </Page>
    </>
)

export default LegalUSTransfersTerms
