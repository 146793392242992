import {ChevronRight} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {accessibility} from '~/global/scss/helpers'
import {useHasNonHomeCurrencyInvestment} from '~/global/state-hooks/mixed-source/useHasNonHomeCurrencyInvestment'
import {useHasOrderedOrHeldShares} from '~/global/state-hooks/rakaia/useHasOrderedOrHeldShares'
import {useReadPortfolio} from '~/global/state-hooks/rakaia/useReadPortfolio'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import capitalise from '~/global/utils/capitalise-string/capitaliseString'
import DetailModal from '~/global/widgets/detail-modals/DetailModal'
import {DollarValue, PercentValue} from '~/global/widgets/number-elements/NumberElements'
import ChartPreview from '~/sections/invest/sections/portfolio/widgets/chart-preview/ChartPreview'
import AmountPutInModal from '~/sections/invest/sections/portfolio/widgets/modals/AmountPutInModal'
import SimpleReturnModal from '~/sections/invest/sections/portfolio/widgets/modals/SimpleReturnModal'
import TotalReturnModal from '~/sections/invest/sections/portfolio/widgets/modals/TotalReturnModal'
import labelledValuesStyles from '~/sections/invest/sections/view-instrument/LabelledValues.scss'
import {useAppSelector} from '~/store/hooks'
import styles from './PortfolioOverview.scss'

type ModalType = 'simple_return' | 'total_return' | 'amount_put_in' | undefined

export const PortfolioOverview: React.FunctionComponent = () => {
    const profile = useProfile()

    const [modalTypeShown, setModalTypeShown] = React.useState<ModalType>(undefined)

    // From distill
    const hasADRs = useAppSelector(s => Object.values(s.instrument.instrumentsById).some(obj => obj.isAdr))

    // From retail-backend
    const investPortfolio = profile.portfolios.find(p => p.product === 'INVEST')!
    const hasManagedFunds = useAppSelector(s => s.identity.holdings.some(fund => fund.holding_type.includes('mutual')))
    const homeCurrency = useAppSelector(s => s.identity.user!.home_currency)
    const includeSoldInvestments = useAppSelector(s => s.identity.includeSoldInvestments)
    const isDependent = useAppSelector(s => s.identity.user!.is_dependent)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const preferredName = useAppSelector(s => s.identity.user!.preferred_name)

    // From rakaia
    const portfolioSummary = useReadPortfolio(investPortfolio.id)

    // From mixed sources
    const hasNonHomeCurrencyInvestments = useHasNonHomeCurrencyInvestment(investPortfolio.id)
    const hasInvested = useHasOrderedOrHeldShares(investPortfolio.id)

    const portfolioHistory = portfolioSummary.portfolio_history

    const hasForeignCurrency =
        portfolioSummary.realised_currency_gain !== 0 || portfolioSummary.unrealised_currency_gain !== 0

    const handleModalClick = (gaLabel: string, modalType: ModalType) => {
        Analytics.event({
            category: 'Portfolio',
            action: `Clicked ${gaLabel} modal`,
            label: `${capitalise(gaLabel)} modal`,
        })
        setModalTypeShown(modalType)
    }

    const showPortfolioReturns = hasInvested && !!portfolioHistory && portfolioHistory.length > 0
    const showChart = showPortfolioReturns && portfolioHistory.length > 1

    return (
        <div className={styles.container}>
            {showChart && (
                <div className={styles.chartWrapper}>
                    <ChartPreview
                        portfolioHistory={portfolioHistory}
                        hasNonHomeCurrencyInvestment={hasNonHomeCurrencyInvestments}
                    />
                </div>
            )}

            {showPortfolioReturns && (
                <div className={cn(labelledValuesStyles.container, styles.portfolioValuesWrapper)}>
                    <div className={styles.portfolioValue}>
                        <div className={labelledValuesStyles.label}>
                            {includeSoldInvestments ? 'Total return' : 'Return'}
                        </div>
                        <button
                            type="button"
                            className={cn(accessibility.button, labelledValuesStyles.value)}
                            aria-label="Total return value"
                            onClick={() => handleModalClick('total return', 'total_return')}
                        >
                            <DollarValue
                                value={
                                    includeSoldInvestments
                                        ? portfolioSummary.total_return || 0
                                        : portfolioSummary.unrealised_total_return || 0
                                }
                            />
                            <ChevronRight />
                        </button>
                    </div>
                    <div className={styles.portfolioValue}>
                        <div className={labelledValuesStyles.label}>Simple return</div>
                        <button
                            type="button"
                            className={cn(accessibility.button, labelledValuesStyles.value)}
                            aria-label="Simple return value"
                            onClick={() => handleModalClick('simple return', 'simple_return')}
                        >
                            <PercentValue
                                value={
                                    includeSoldInvestments
                                        ? portfolioSummary.simple_return || 0
                                        : portfolioSummary.unrealised_simple_return || 0
                                }
                            />
                            <ChevronRight />
                        </button>
                    </div>
                    <div className={styles.portfolioValue}>
                        <div className={labelledValuesStyles.label}>Amount put in</div>
                        <button
                            type="button"
                            className={cn(accessibility.button, labelledValuesStyles.value)}
                            aria-label="Amount put in value"
                            onClick={() => handleModalClick('you’ve put in', 'amount_put_in')} // For GA, we'll keep the old "you’ve put in" wording
                        >
                            <DollarValue
                                value={
                                    portfolioSummary && portfolioSummary.cost_basis ? portfolioSummary.cost_basis : 0
                                }
                            />
                            <ChevronRight />
                        </button>
                    </div>
                </div>
            )}

            {modalTypeShown === 'simple_return' && (
                <DetailModal
                    isOpen={modalTypeShown === 'simple_return'}
                    closeHandler={() => setModalTypeShown(undefined)}
                >
                    <SimpleReturnModal
                        costBasis={portfolioSummary.cost_basis || 0}
                        costBasisMax={portfolioSummary.cost_basis_max || 0}
                        includeSoldInvestments={includeSoldInvestments}
                        isDependent={isDependent}
                        realisedCostBasis={portfolioSummary?.realised_cost_basis || 0}
                        preferredName={preferredName}
                        simpleReturn={portfolioSummary.simple_return || 0}
                        totalReturn={portfolioSummary.total_return || 0}
                        unrealisedTotalReturn={portfolioSummary.unrealised_total_return || 0}
                        unrealisedSimpleReturn={portfolioSummary.unrealised_simple_return || 0}
                    />
                </DetailModal>
            )}
            {modalTypeShown === 'amount_put_in' && (
                <DetailModal
                    isOpen={modalTypeShown === 'amount_put_in'}
                    closeHandler={() => setModalTypeShown(undefined)}
                >
                    <AmountPutInModal
                        costBasis={portfolioSummary.cost_basis || 0}
                        currency={homeCurrency}
                        hasForeignCurrency={hasForeignCurrency}
                        hasManagedFunds={hasManagedFunds}
                        isDependent={isDependent}
                        jurisdiction={jurisdiction}
                        preferredName={preferredName}
                        unrealisedManagedFundTransactionFees={
                            portfolioSummary.unrealised_managed_fund_transaction_fees || 0
                        }
                        unrealisedTransactionFees={portfolioSummary.unrealised_total_transaction_fees || 0}
                    />
                </DetailModal>
            )}

            {modalTypeShown === 'total_return' && (
                <DetailModal
                    isOpen={modalTypeShown === 'total_return'}
                    closeHandler={() => setModalTypeShown(undefined)}
                >
                    <TotalReturnModal
                        hasADRs={hasADRs}
                        hasForeignCurrency={hasForeignCurrency}
                        hasManagedFunds={hasManagedFunds}
                        homeCurrency={homeCurrency}
                        includeSoldInvestments={includeSoldInvestments}
                        isDependent={isDependent}
                        preferredName={preferredName}
                        portfolioSummary={portfolioSummary}
                    />
                </DetailModal>
            )}
        </div>
    )
}
