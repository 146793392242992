import cn from 'classnames'
import React from 'react'
import {useParams} from 'react-router'
import {TradingType} from '~/global/utils/trading-type/tradingType'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Link} from '~/migrate-react-router'
import {useAppSelector} from '~/store/hooks'
import styles from './MarketDepthLink.scss'

const classFor = {
    'fund-index-header': styles.typeFundIndexHeader,
    'market-or-limit-selector': styles.typeMarketOrLimitSelector,
    'record-buy-sell': styles.typeRecordBuySell,
}

interface MarketDepthLinkProps {
    type?: 'market-or-limit-selector' | 'fund-index-header' | 'record-buy-sell'
    instrumentSlug?: string
    tradingType?: TradingType
}

const MarketDepthLink: React.FunctionComponent<MarketDepthLinkProps> = (props: Partial<MarketDepthLinkProps>) => {
    const {instrumentSlug, tradingType, type} = props
    const {portfolioId} = useParams()
    const profileUrl = useProfileUrl()

    const isSubscribedLiveData = useAppSelector(s => s.market.liveDataSubscriptionDetails.is_active)
    const typeClass = type ? classFor[type] : ''

    const getLinkUrl = () => {
        if (portfolioId) {
            return profileUrl('portfolio/:portfolioId/invest/live-market-data/:instrumentSlug', {
                instrumentSlug: instrumentSlug!,
                portfolioId,
            })
        }

        return profileUrl('invest/live-market-data/:instrumentSlug', {instrumentSlug: instrumentSlug!})
    }

    return (
        <>
            {instrumentSlug && isSubscribedLiveData && tradingType !== 'managed' && (
                <div className={cn(styles.marketDepthLink, {[typeClass]: typeClass !== ''})}>
                    <Link to={getLinkUrl()} data-testid="link--nzx-market-depth">
                        NZX market depth
                    </Link>
                </div>
            )}
        </>
    )
}

export default MarketDepthLink
