import {DateTime} from 'luxon'
import {Exchange} from '~/store/instrument/types'

export type ExchangeNextOpen = (exchange: Exchange) => DateTime | undefined

export const exchangeNextOpen: ExchangeNextOpen = exchange => {
    // We want to know when a given exchange is next open. Distill provides exchange.openHours data which includes the next 7 days
    // the exchange will operate. To determine the next opening time we iterate through each element in exchange.openHours.
    // In case the data is stale and we don't find a future opening time is found, we return undefined and the calling
    // won't be able to display a next opening time.

    // Get current time in our locale
    const now = DateTime.local()

    // define returnDate as undefined. We'll update it to a DateTime if we find one
    let returnDate: DateTime | undefined

    exchange.openHours.forEach(dateData => {
        // If we've already got a return date, do nothing
        if (returnDate) {
            return
        }

        // Convert to current locale
        const testDate = dateData.start.toLocal()

        if (testDate > now) {
            returnDate = dateData.start.toLocal()
        }
    })

    return returnDate
}
