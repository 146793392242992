import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import Decimal from 'decimal.js'
import {Formik} from 'formik'
import React, {useState} from 'react'
import ReactPixel from 'react-facebook-pixel'
import Analytics from '~/api/google-analytics/googleAnalytics'
import {redditTrackGiftPurchase} from '~/api/reddit/redditPixel'
import * as api from '~/api/retail'
import {Model, Request, Response} from '~/api/retail/types'
import config from '~/configForEnv'
import {spacing} from '~/global/scss/helpers'
import {assertNever} from '~/global/utils/assert-never/assertNever'
import {amountIncludingCardFee, amountOfCardFee} from '~/global/utils/card-fee-calculation/cardFeeCalculation'
import {UserSuitableError} from '~/global/utils/error-handling/errorHandling'
import {formatDollar} from '~/global/utils/format-dollar/formatDollar'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import use3DSecure from '~/global/utils/use3-d-secure/use3DSecure'
import AccountsCardList from '~/global/widgets/credit-card-handling/accepted-cards-list/AccountsCardList'
import {validate} from '~/global/widgets/form-controls'
import {Checkbox} from '~/global/widgets/form-controls/formik'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import HelpEmail from '~/global/widgets/help-email/HelpEmail'
import {DollarValue, FeeValue} from '~/global/widgets/number-elements/NumberElements'
import {OrderConfirmation} from '~/global/widgets/order-confirmation/OrderConfirmation'
import {useNavigate} from '~/migrate-react-router'
import styles from '~/sections/user/sections/share-the-love/sections/gifts/Gifts.scss'
import GiftingTermsAndConditions from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/GiftingTermsAndConditions'
import store from '~/store'
import actions from '~/store/gift/actions'
import {useAppSelector} from '~/store/hooks'

/**
 * Shared marketing tracking for both the public and authenticated successful creation of a gift code
 *
 * @param {Model.User['jurisdiction']} jurisdiction - Jurisdiction or location (public website)
 */
export const trackCreateGiftSuccess = (jurisdiction: Model.User['jurisdiction'], giftAmount: string) => {
    ReactPixel.trackSingle(
        jurisdiction === 'au' ? config.facebookPixelIdAU : config.facebookPixelIdNZ,
        'gift purchase',
        {successful: true, value: giftAmount},
    )
    Analytics.giftsFloodlightEvent(jurisdiction)

    const giftAmountAsNumber = new Decimal(giftAmount).toNumber()

    Analytics.customEvent({
        category: 'gifts',
        action: 'purchase',
        label: jurisdiction,
        value: giftAmountAsNumber,
    })

    if (jurisdiction === 'nz') {
        redditTrackGiftPurchase(giftAmountAsNumber, 'NZD')
    }
}

interface GiftConfirmationFormValues {
    eligibility: boolean
    accept_terms: boolean
}

interface GiftConfirmationFormProps {
    amount: string
    paymentMethodId?: string
    currency: Model.User['home_currency']
    jurisdiction: Model.User['jurisdiction']
    allowReturnToCardFormOnFailure?: boolean
    returnToCardForm: () => void
}

const GiftConfirmationForm = ({
    amount,
    currency,
    jurisdiction,
    paymentMethodId,
    allowReturnToCardFormOnFailure,
    returnToCardForm,
}: GiftConfirmationFormProps) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const chargeAmount = amountIncludingCardFee(amount, currency)
    const {processCardRequiresAction} = use3DSecure(jurisdiction)
    const [showReturnToPaymentFormButton, setShowReturnToPaymentFormButton] = useState(false)
    const shouldReturnToPaymentForm = showReturnToPaymentFormButton && allowReturnToCardFormOnFailure

    const useSavedCard = !paymentMethodId

    async function doRequest(
        endpoint: 'gifting/in-app-purchase-payment-intent',
        requestParams: Request.GiftingInAppPurchase,
    ): Promise<Response.GiftingInformation['gift']> {
        const response = await api.post(endpoint, requestParams)

        switch (response.type) {
            case 'error':
                throw new UserSuitableError(response.message)
            case 'gifting_information':
                trackCreateGiftSuccess(jurisdiction, amount)
                return response.gift
            case 'card_topup_action_required':
                const paymentIntentId = await processCardRequiresAction(response.client_secret)
                return doRequest(endpoint, {...requestParams, payment_intent_id: paymentIntentId})
            case 'internal_server_error':
                throw new Error()
            default:
                assertNever(response)
                throw new Error()
        }
    }

    return (
        <Formik
            initialValues={{
                eligibility: false,
                accept_terms: false,
            }}
            validate={validate.generate<GiftConfirmationFormValues>({
                eligibility: [validate.required(), validate.isTrue('Please confirm elegibility')],
                accept_terms: [validate.required(), validate.isTrue('Please accept the terms and conditions')],
            })}
            onSubmit={async ({eligibility, accept_terms}, {setSubmitting, setStatus}) => {
                if (eligibility && accept_terms) {
                    setStatus()
                    setSubmitting(true)
                    try {
                        let requestParams = {
                            charge_amount: chargeAmount,
                            gift_amount: amount,
                            accept_terms: true,
                            currency,
                        } as Request.GiftingInAppPurchase

                        if (!useSavedCard) {
                            requestParams = {
                                ...requestParams,
                                payment_method_id: paymentMethodId!,
                            }
                        }

                        const gift = await doRequest('gifting/in-app-purchase-payment-intent', requestParams)
                        store.dispatch(actions.SetPurchasedGift(gift))
                        navigate(profileUrl('gifts/purchase-success'))
                    } catch (e) {
                        if (allowReturnToCardFormOnFailure) {
                            setShowReturnToPaymentFormButton(true)
                        }
                        if (e instanceof UserSuitableError) {
                            setStatus({
                                error: e.message ?? 'An error occurred purchasing your gift, please try again',
                            })
                        } else {
                            setStatus({
                                error: (
                                    <span>
                                        An error occurred while your Gift was processing. Your card may have been
                                        charged, please contact <HelpEmail /> and our team will process your Gift.
                                    </span>
                                ),
                            })
                            throw e
                        }
                    }
                }
            }}
        >
            {({handleSubmit, status, isValid, isSubmitting, dirty}) => {
                const buttonText = shouldReturnToPaymentForm
                    ? 'Try another card'
                    : `Pay ${formatDollar(
                          parseFloat(amountIncludingCardFee(amount, currency)),
                          false,
                      )} ${currency.toUpperCase()}`
                const onButtonClick = (e: any) => {
                    if (shouldReturnToPaymentForm) {
                        e.preventDefault()
                        returnToCardForm()
                    }
                }
                return (
                    <form onSubmit={handleSubmit} className={cn(spacing.spaceAbove24, styles.checkoutForm)}>
                        <Checkbox
                            dataTestId="checkbox--confirm-person-can-receive-gift"
                            label={
                                currency === 'nzd' ? (
                                    <span>
                                        I’ve checked the person receiving the Gift has or{' '}
                                        <HelpCentreLink
                                            auArticle="4983221-sign-up-to-sharesies"
                                            nzArticle="1390115-sign-up-to-sharesies"
                                        >
                                            can create a Sharesies account (or Kids Account)
                                        </HelpCentreLink>
                                        . I understand this Gift can only be redeemed by a New Zealand investor.
                                    </span>
                                ) : (
                                    <span>
                                        I’ve checked the person receiving the Gift has or{' '}
                                        <HelpCentreLink
                                            auArticle="4983221-sign-up-to-sharesies"
                                            nzArticle="1390115-sign-up-to-sharesies"
                                        >
                                            can create a Sharesies account
                                        </HelpCentreLink>
                                        . I understand this Gift can only be redeemed by an Australian investor.{' '}
                                    </span>
                                )
                            }
                            name="eligibility"
                        />
                        <Checkbox
                            dataTestId="checkbox--confirm-terms-and-conditions"
                            label={
                                <span>
                                    I agree to the{' '}
                                    <ModalLink
                                        dataTestId="modal-link--gift-terms-and-conditions"
                                        label="Gift Terms &amp; Conditions"
                                        modalTitle={
                                            jurisdiction === 'au'
                                                ? 'AUD Gifts Terms & Conditions'
                                                : 'Terms and Conditions for the purchase and use of Sharesies Gift Codes'
                                        }
                                        bottomBorder
                                    >
                                        <GiftingTermsAndConditions />
                                    </ModalLink>{' '}
                                    and understand that this Gift is valid for 36 months.
                                </span>
                            }
                            name="accept_terms"
                        />

                        {status?.error && (
                            <div className={cn(spacing.spaceAbove24, spacing.spaceBelow24, styles.error)}>
                                {status.error}
                            </div>
                        )}

                        <Button
                            label={buttonText}
                            pageButton
                            isSubmit
                            onClick={onButtonClick}
                            dataTestId="button--pay-confirmation"
                            disabled={!isValid || !dirty}
                            processing={isSubmitting}
                        />
                    </form>
                )
            }}
        </Formik>
    )
}

interface GiftConfirmationProps {
    useSavedCard: boolean
    giftAmount: string
    changeCard: () => void
}
export default function GiftConfirmation({
    useSavedCard,
    giftAmount,
    changeCard,
    ...giftFormProps
}: GiftConfirmationProps & GiftConfirmationFormProps) {
    const currency = useAppSelector(({identity}) => identity.user!.home_currency)
    const savedCard = useAppSelector(({accounting}) =>
        accounting.cardsLoadingState === 'ready' ? accounting.cards[0] : undefined,
    )

    return (
        <div>
            <div>
                <h1 className={cn(styles.title, spacing.spaceBelow24)}>{`Review your Gift order`}</h1>
                <OrderConfirmation
                    items={[
                        {
                            description: 'Sharesies Gift',
                            value: <DollarValue value={giftAmount} currency={currency} />,
                        },
                        {
                            description: 'Card processing fee',
                            value: <FeeValue value={amountOfCardFee(giftAmount, currency)} currency={currency} />,
                        },
                    ]}
                    total={{
                        description: 'Total',
                        value: <DollarValue value={amountIncludingCardFee(giftAmount, currency)} currency={currency} />,
                    }}
                />
                {useSavedCard && savedCard && (
                    <>
                        <p className={styles.cardDetailsHeader}>Card details</p>
                        <AccountsCardList source={savedCard} edit={changeCard} border />
                    </>
                )}
                <GiftConfirmationForm {...giftFormProps} />
            </div>
        </div>
    )
}
