import React from 'react'
import {Portfolio} from '~/global/state-hooks/retail/useProfile'

/**
 * This is basically a regular FONTERRA portfolio with a wallet attribute added to it that contains the associated
 * WALLET portfolio
 */
export type FonterraContextPortfolio = Portfolio<'FONTERRA'> & {
    wallet: Portfolio<'WALLET'>
}

export const FonterraPortfolioContext = React.createContext<FonterraContextPortfolio | null>(null)

export const useFonterraPortfolio = () => {
    const context = React.useContext(FonterraPortfolioContext)

    if (context === null) {
        throw new Error('useFonterraPortfolio must be used within a FonterraPortfolioWrapper')
    }

    return context
}
