import React from 'react'
import {Request} from '~/api/retail/types'
import learnBackgroundThumbnail from '~/global/assets/images/learn-background-thumbnail.svg'
import {spacing} from '~/global/scss/helpers'
import {useHasOrderedOrHeldShares} from '~/global/state-hooks/rakaia/useHasOrderedOrHeldShares'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Card from '~/global/widgets/card/Card'
import {Link, useNavigate} from '~/migrate-react-router'
import PortfolioNextSteps from '~/sections/invest/sections/portfolio/widgets/next-steps/PortfolioNextSteps'
import {essViewsForPortfolio} from '~/store/employeeShareScheme/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'

export const FeaturedCards: React.FunctionComponent = () => {
    const profileUrl = useProfileUrl()
    const profile = useProfile()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const dismissPortfolioIntroCard = (card: Request.CustomerDismissPortfolioIntroCard['card']) =>
        dispatch(identityActions.DismissPortfolioIntroCard(card))

    const investPortfolio = profile.portfolios.find(p => p.product === 'INVEST')!
    const showLearnCard = useAppSelector(s => s.identity.user!.portfolio_intro_cards.learn_shown)
    const hasInvested = useHasOrderedOrHeldShares(investPortfolio.id)
    const essViews = useAppSelector(s => essViewsForPortfolio(s))

    type PortfolioCardTypes = 'learn' | 'auto_invest'
    const [recentlyClosedPortfolioCards, setRecentlyClosedPortfolioCards] = React.useState<PortfolioCardTypes[]>([])

    const hideLearnCard =
        essViews.hasEmployment ||
        (hasInvested && !showLearnCard) ||
        recentlyClosedPortfolioCards.find(x => x === 'learn')

    return (
        <>
            {!essViews.hasEmployment && !hasInvested && (
                <div>
                    <PortfolioNextSteps />
                </div>
            )}

            {/* Show learn and auto invest cards IF the investor has not yet invested OR if they have not yet 'dismissed' it.
        Hide it if they have recently clicked the 'dismiss' button, as it will take the backend a few seconds to register this.*/}
            {!hideLearnCard && (
                <Card
                    image={learnBackgroundThumbnail}
                    className={spacing.spaceBelow24}
                    onDismiss={
                        hasInvested
                            ? () => {
                                  setRecentlyClosedPortfolioCards(recentlyClosedPortfolioCards.concat('learn'))
                                  dismissPortfolioIntroCard('learn_shown')
                              }
                            : undefined
                    }
                    onClick={() => navigate(profileUrl('explore/learn'))}
                >
                    <h2>Learn the basics</h2>
                    <p>Get up to speed with investing on Sharesies.</p>
                    <p>
                        <Link to={profileUrl('explore/learn')}>Teach me</Link>
                    </p>
                </Card>
            )}
        </>
    )
}

export default FeaturedCards
