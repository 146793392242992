import {HorizontalGauge} from '@design-system/horizontal-gauge'
import React from 'react'
import {spacing} from '~/global/scss/helpers'

interface Props {
    vestedAllocationYears: number
    unvestedAllocationYears: number
}

const AllocationsVestProgress = ({vestedAllocationYears, unvestedAllocationYears}: Props) => {
    // Render nothing if there is only one allocation or vest
    const totalAllocationYears = unvestedAllocationYears + vestedAllocationYears
    if (totalAllocationYears <= 1) {
        return null
    }

    return (
        <HorizontalGauge
            additionalClassName={spacing.spaceAbove16}
            dataTestId="horizontal-gauge--ess-vest-progress"
            gaugeColour="Blueberry700"
            gaugeValue={[vestedAllocationYears, totalAllocationYears]}
        />
    )
}

export default AllocationsVestProgress
