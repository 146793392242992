import React from 'react'
import {updateExchangeRates} from '~/global/utils/get-exchange-rate/getExchangeRate'
import {State as AccountingState} from '~/store/accounting/types'
import {useAppSelector} from '~/store/hooks'

const DEFAULT_CHECK_INTERVAL_MS = 15 * 1000 // 15 seconds

export function useExchangeRates(
    checkIntervalMS: number = DEFAULT_CHECK_INTERVAL_MS,
): [AccountingState['exchangeRates'], boolean] {
    const [exchangeRates, setExchangeRates] = React.useState<AccountingState['exchangeRates']>([])
    const [exchangeRateError, setExchangeRateError] = React.useState<boolean>(false)
    const actingAsId = useAppSelector(({identity}) => identity.user?.id)

    if (!actingAsId) {
        throw new Error(`Can't get exchange rates when not logged in`)
    }

    React.useEffect(() => {
        updateExchangeRates(actingAsId, setExchangeRates, setExchangeRateError)

        const updateExchangeRateTimer = setInterval(
            () => updateExchangeRates(actingAsId, setExchangeRates, setExchangeRateError),
            checkIntervalMS,
        )

        return () => {
            clearInterval(updateExchangeRateTimer)
        }
    }, [])

    return [exchangeRates, exchangeRateError]
}
