import {Button} from '@design-system/button'
import {withFormik} from 'formik'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {validate} from '~/global/widgets/form-controls'
import {Radio} from '~/global/widgets/form-controls/formik'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {transferAllChoice} from '~/sections/invest/sections/transfer-shares/sections/nzx-transfer-shares/pages/transfer-all/TransferAll'

interface FormValues {
    transferAllPortfolio?: transferAllChoice
}

interface TransferAllFormProps {
    handleSuccess: (transferAll: transferAllChoice) => void
}

export const TransferAllForm = withFormik<TransferAllFormProps, FormValues>({
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        transferAllPortfolio: undefined,
    }),
    handleSubmit: async ({transferAllPortfolio}, {setSubmitting, props: {handleSuccess}}) => {
        setSubmitting(true)
        if (transferAllPortfolio) {
            handleSuccess(transferAllPortfolio)
        }
    },
    validate: values => {
        const schema = {
            transferAllPortfolio: [validate.required()],
        }
        return validate.generate<FormValues>(schema)(values)
    },
})(({handleSubmit, isValid, isSubmitting}) => {
    return (
        <form onSubmit={handleSubmit}>
            <div className={commonStyles.formWrapper}>
                <h1 className={spacing.spaceBelow12}>What shares would you like to transfer?</h1>
                <Radio
                    dataTestId="radio--transfer-all"
                    name="transferAllPortfolio"
                    choices={[
                        {
                            label: 'Transfer all shares on this CSN',
                            value: 'yes',
                        },
                        {
                            label: 'Transfer shares for individual investments',
                            value: 'no',
                        },
                    ]}
                />
            </div>

            <ActionBar>
                <Button dataTestId="button--next" label="Next" disabled={!isValid} isSubmit processing={isSubmitting} />
            </ActionBar>
        </form>
    )
})
