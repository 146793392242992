/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {Currency, CurrencyFromJSON, CurrencyFromJSONTyped, CurrencyToJSON, CurrencyToJSONTyped} from './'

/**
 *
 * @export
 * @interface AppSchemasForClientPortfoliosPortfolio
 */
export interface AppSchemasForClientPortfoliosPortfolio {
    /**
     *
     * @type {string}
     * @memberof AppSchemasForClientPortfoliosPortfolio
     */
    uuid: string
    /**
     *
     * @type {Currency}
     * @memberof AppSchemasForClientPortfoliosPortfolio
     */
    currency: Currency
    /**
     *
     * @type {Date}
     * @memberof AppSchemasForClientPortfoliosPortfolio
     */
    date?: DateTime
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfoliosPortfolio
     */
    portfolio_value?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfoliosPortfolio
     */
    total_return?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfoliosPortfolio
     */
    unrealised_total_return?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForClientPortfoliosPortfolio
     */
    simple_return?: number
}

export function AppSchemasForClientPortfoliosPortfolioFromJSON(json: any): AppSchemasForClientPortfoliosPortfolio {
    return AppSchemasForClientPortfoliosPortfolioFromJSONTyped(json, false)
}

export function AppSchemasForClientPortfoliosPortfolioFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AppSchemasForClientPortfoliosPortfolio {
    if (json === undefined || json === null) {
        return json
    }
    return {
        uuid: json['uuid'],
        currency: CurrencyFromJSON(json['currency']),
        date: !exists(json, 'date') ? undefined : DateTime.fromISO(json['date'], {setZone: true}),
        portfolio_value: !exists(json, 'portfolio_value') ? undefined : json['portfolio_value'],
        total_return: !exists(json, 'total_return') ? undefined : json['total_return'],
        unrealised_total_return: !exists(json, 'unrealised_total_return') ? undefined : json['unrealised_total_return'],
        simple_return: !exists(json, 'simple_return') ? undefined : json['simple_return'],
    }
}

export function AppSchemasForClientPortfoliosPortfolioToJSON(
    value?: AppSchemasForClientPortfoliosPortfolio | null,
): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        uuid: value.uuid,
        currency: CurrencyToJSON(value.currency),
        date: value.date === undefined ? undefined : value.date.toFormat('yyyy-MM-dd'),
        portfolio_value: value.portfolio_value,
        total_return: value.total_return,
        unrealised_total_return: value.unrealised_total_return,
        simple_return: value.simple_return,
    }
}
