import cn from 'classnames'
import {useSetAtom} from 'jotai'
import React from 'react'
import {useNavigate} from 'react-router'
import {ListingResponseDto} from '~/api/distill/models'
import {Response} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import headerStyles from '~/global/scss/reused-styles/pageHeader.scss'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ButtonCircle} from '~/global/widgets/button-circle/ButtonCircle'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import farmSupplyIcon from '~/sections/moose/assets/images/farm-supply-icon.svg'
import {useDistillSearch} from '~/sections/moose/data/distill'
import {useHoldingDetail} from '~/sections/moose/data/retail'
import {MoosePortfolioPathParams} from '~/sections/moose/routes'
import {buyOrderAtom, sellOrderAtom} from '~/sections/moose/sections/order/state'
import {InstrumentsGrid} from '~/sections/moose/sections/portfolio/widgets/instrument-grid/InstrumentsGrid'
import {useAppSelector} from '~/store/hooks'
import styles from './Portfolio.scss'

// TODO Move this to the backend
function calculatePortfolioValueFromHoldings(data: Response.FonterraHoldingDetail, instruments: ListingResponseDto[]) {
    let totalValue = 0.0
    instruments.map(instrument => {
        const holdingDetail = data.instruments.find(x => x.symbol === instrument.symbol)
        if (holdingDetail) {
            totalValue += holdingDetail.holding * parseFloat(instrument.marketPrice)
        }
    })
    return totalValue
}

const PortfolioManagementControls: React.FunctionComponent<{
    portfolioId: string
    holdingDetail: Response.FonterraHoldingDetail
}> = ({portfolioId, holdingDetail}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const setBuyOrder = useSetAtom(buyOrderAtom)
    const setSellOrder = useSetAtom(sellOrderAtom)

    return (
        <>
            <div className={styles.managementControls}>
                {!holdingDetail.is_inactive && (
                    <>
                        <ButtonCircle
                            label="Buy"
                            dataTestId="button--portfolio-buy"
                            onClick={() => {
                                rudderTrack('navigation', 'feature_management_button_clicked', {
                                    label: 'buy',
                                    url: location.pathname,
                                })
                                setBuyOrder(undefined)
                                navigate(profileUrl('fonterra/portfolios/:portfolioId/invest/buy', {portfolioId}))
                            }}
                            icon="OrderBuy"
                        />
                        <ButtonCircle
                            label="Sell"
                            dataTestId="button--portfolio-sell"
                            onClick={() => {
                                rudderTrack('navigation', 'feature_management_button_clicked', {
                                    label: 'sell',
                                    url: location.pathname,
                                })
                                setSellOrder(undefined)
                                navigate(profileUrl('fonterra/portfolios/:portfolioId/invest/sell', {portfolioId}))
                            }}
                            icon="OrderSell"
                        />
                    </>
                )}
                <ButtonCircle
                    label="Manage"
                    dataTestId="button--portfolio-manage"
                    onClick={() => {
                        rudderTrack('navigation', 'feature_management_button_clicked', {
                            label: 'manage',
                            url: location.pathname,
                        })
                        navigate(profileUrl('fonterra/portfolios/:portfolioId/manage', {portfolioId}))
                    }}
                    icon="MoreHorizontal"
                />
            </div>
        </>
    )
}

export const MoosePortfolio: React.FunctionComponent<MoosePortfolioPathParams> = ({
    portfolioId,
}: MoosePortfolioPathParams) => {
    const instruments = useDistillSearch()
    const holdingDetail = useHoldingDetail(portfolioId)
    const toolbarHiddenForMobile = useAppSelector(s => s.nav.toolbarHidden)

    const portfolioValue = calculatePortfolioValueFromHoldings(holdingDetail, instruments)
    return (
        <>
            <div className={styles.background}>
                <Toolbar dataTestId="toolbar--fonterra-portfolio" leftButton="back" showNotifications />
                <Page withoutDefaultPadding>
                    <div className={styles.background}>
                        <div className={headerStyles.landingImageWrapper}>
                            <div
                                className={cn(styles.farmSupplyIcon)}
                                style={{paddingTop: toolbarHiddenForMobile ? '25px' : '0px'}}
                            >
                                <img src={farmSupplyIcon} alt="" data-testid="img--farm-supply-icon" />
                                <span data-testid="text--farm-supply-number">{holdingDetail.farm_id}</span>
                            </div>
                        </div>

                        <div className={headerStyles.landingContentWrapper}>
                            {!holdingDetail.is_inactive && (
                                <h1 data-testid="heading--farm-name" className={cn(styles.title, headerStyles.title)}>
                                    {holdingDetail.entity_name} - {holdingDetail.farm_id}
                                </h1>
                            )}
                            <div
                                className={cn(
                                    headerStyles.accountBalance,
                                    spacing.spaceBelow20,
                                    holdingDetail.is_inactive && spacing.spaceAbove8,
                                )}
                            >
                                <DollarValue value={portfolioValue} sayNumericWords={false} />
                                {'\u00a0'}
                                {/* non-breaking space */}
                                {/* TODO: Dont hard code the currency, but rather pull it from the org's preferences */}
                                <span className={headerStyles.accountBalanceCurrency}>NZD</span>
                            </div>
                            <PortfolioManagementControls portfolioId={portfolioId} holdingDetail={holdingDetail} />
                            <InstrumentsGrid portfolioId={portfolioId} />
                        </div>
                    </div>
                </Page>
            </div>
        </>
    )
}
