import {atom} from 'jotai'
import {Response} from '~/api/retail/types'
import {TC_VERSION_TYPE} from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/TermsAndConditions'

export type SignUpEntity = Response.FonterraSignUpEntityDetail['entities'][number]
export type Address = SignUpEntity['address']
export type Frequency = 'RARELY' | 'OCCASIONALLY' | 'OFTEN' | 'REGULARLY'
export type Purpose = 'MONITOR_SHARE_PRICE' | 'MANAGE_HOLDINGS' | 'OTHER'

export interface PrescribedPersonForEntity {
    partyId: string
    prescribedPerson?: boolean
    participant?: string
    prescribedEmail?: string
}

export interface NatureAndPurposeForEntity {
    partyId: string
    frequency: Frequency
    purposes: Purpose[]
    otherPurpose: string
}

export interface StagedSignUp {
    hasMobileAuth?: boolean
    signupAttemptToken?: string
    tcVersion?: TC_VERSION_TYPE
    prescribedPerson?: PrescribedPersonForEntity[]
    natureAndPurpose: NatureAndPurposeForEntity[]
}

export const stagedSignUpAtom = atom<StagedSignUp>({natureAndPurpose: []})
export const entityDetailAtom = atom<Response.FonterraSignUpEntityDetail | undefined>(undefined)
