import {InvestFilled} from '@design-system/icon'
import {ModalLink} from '@design-system/modal'
import React from 'react'
import {ShareValue} from '~/global/widgets/number-elements/NumberElements'
import {HoldingDetailForSymbol} from '~/sections/moose/sections/order/utils/get-holding-details-for-symbol/getHoldingDetailsForSymbol'
import {ShareholdingLimitsWidget} from '~/sections/moose/sections/order/widgets/shareholding-limits/ShareholdingLimitsWidget'
import {isShareType} from '~/sections/moose/sections/utils/symbol-helpers/symbolHelpers'
import styles from './HelpForShareholdingOrder.scss'

export function helpForShareholdingOrder(
    portfolioId: string,
    holding: HoldingDetailForSymbol,
    symbol: string,
    type: 'buy' | 'sell',
): React.ReactNode {
    const maxQuantity = type === 'buy' ? holding.maxAllowedToBuy : holding.maxAllowedToSell

    return (
        <div className={styles.permittedToOrder}>
            {maxQuantity != null && (
                <>
                    <InvestFilled size={12} /> <ShareValue value={maxQuantity} /> permitted to {type}
                </>
            )}
            {isShareType(symbol) && (
                <>
                    {' '}
                    <ShareholdingLimitsModal portfolioId={portfolioId} symbol={symbol} />
                </>
            )}
        </div>
    )
}

export const ShareholdingLimitsModal = ({portfolioId, symbol}: {portfolioId: string; symbol: string}) => {
    return (
        <ModalLink
            additionalClassName={styles.shareholdingLimitLink}
            dataTestId="modal-link--shareholding-limits"
            label="Shareholding limits"
            modalTitle="Shareholding limits"
            primaryButton={{label: 'Close'}}
            bottomBorder
            helpIconSize={16}
        >
            <ShareholdingLimitsWidget symbol={symbol} portfolioId={portfolioId} />
        </ModalLink>
    )
}
