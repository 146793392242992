/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    AssetManagerDto,
    AssetManagerDtoFromJSON,
    AssetManagerDtoFromJSONTyped,
    AssetManagerDtoToJSON,
    AssetManagerDtoToJSONTyped,
    ExchangeDto,
    ExchangeDtoFromJSON,
    ExchangeDtoFromJSONTyped,
    ExchangeDtoToJSON,
    ExchangeDtoToJSONTyped,
    InstrumentTypeDto,
    InstrumentTypeDtoFromJSON,
    InstrumentTypeDtoFromJSONTyped,
    InstrumentTypeDtoToJSON,
    InstrumentTypeDtoToJSONTyped,
    SortDto,
    SortDtoFromJSON,
    SortDtoFromJSONTyped,
    SortDtoToJSON,
    SortDtoToJSONTyped,
} from './'

/**
 *
 * @export
 * @interface InstrumentsInfoResponseDto
 */
export interface InstrumentsInfoResponseDto {
    /**
     *
     * @type {string}
     * @memberof InstrumentsInfoResponseDto
     */
    version: string
    /**
     *
     * @type {string}
     * @memberof InstrumentsInfoResponseDto
     */
    filesHostAddress: string
    /**
     *
     * @type {Array<string>}
     * @memberof InstrumentsInfoResponseDto
     */
    categories: Array<string>
    /**
     *
     * @type {Array<ExchangeDto>}
     * @memberof InstrumentsInfoResponseDto
     */
    exchanges: Array<ExchangeDto>
    /**
     *
     * @type {Array<AssetManagerDto>}
     * @memberof InstrumentsInfoResponseDto
     */
    assetManagers: Array<AssetManagerDto>
    /**
     *
     * @type {Array<InstrumentTypeDto>}
     * @memberof InstrumentsInfoResponseDto
     */
    instrumentTypes: Array<InstrumentTypeDto>
    /**
     *
     * @type {Array<string>}
     * @memberof InstrumentsInfoResponseDto
     */
    filterOptions: Array<string>
    /**
     *
     * @type {Array<SortDto>}
     * @memberof InstrumentsInfoResponseDto
     */
    sortOptions: Array<SortDto>
    /**
     *
     * @type {Array<string>}
     * @memberof InstrumentsInfoResponseDto
     */
    priceChangeTimeOptions: Array<string>
}

export function InstrumentsInfoResponseDtoFromJSON(json: any): InstrumentsInfoResponseDto {
    return InstrumentsInfoResponseDtoFromJSONTyped(json, false)
}

export function InstrumentsInfoResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): InstrumentsInfoResponseDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        version: json['version'],
        filesHostAddress: json['filesHostAddress'],
        categories: json['categories'],
        exchanges: (json['exchanges'] as Array<any>).map(ExchangeDtoFromJSON),
        assetManagers: (json['assetManagers'] as Array<any>).map(AssetManagerDtoFromJSON),
        instrumentTypes: (json['instrumentTypes'] as Array<any>).map(InstrumentTypeDtoFromJSON),
        filterOptions: json['filterOptions'],
        sortOptions: (json['sortOptions'] as Array<any>).map(SortDtoFromJSON),
        priceChangeTimeOptions: json['priceChangeTimeOptions'],
    }
}

export function InstrumentsInfoResponseDtoToJSON(value?: InstrumentsInfoResponseDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        version: value.version,
        filesHostAddress: value.filesHostAddress,
        categories: value.categories,
        exchanges: (value.exchanges as Array<any>).map(ExchangeDtoToJSON),
        assetManagers: (value.assetManagers as Array<any>).map(AssetManagerDtoToJSON),
        instrumentTypes: (value.instrumentTypes as Array<any>).map(InstrumentTypeDtoToJSON),
        filterOptions: value.filterOptions,
        sortOptions: (value.sortOptions as Array<any>).map(SortDtoToJSON),
        priceChangeTimeOptions: value.priceChangeTimeOptions,
    }
}
