import {
    FilledPortfolio,
    Portfolio,
    Search,
    FilledSearch,
    Love,
    FilledLove,
    FilledWallet,
    Wallet,
    Settings,
    FilledSettings,
} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import {accessibility} from '~/global/scss/helpers'
import {convertToKebabCase} from '~/global/utils/convert-to-kebab-case/convertToKebabCase'
import {ValidProfilePaths} from '~/global/utils/use-profile-url/useProfileUrl'
import styles from './ListFlyOutMenu.scss'

type ExcludeNonBasePaths<Path extends string> = Path extends `${infer Base}/${string}`
    ? Base
    : Path extends `${string}:`
      ? never
      : Path extends `${infer Base}`
        ? Base
        : never
export type BasePath = ExcludeNonBasePaths<ValidProfilePaths>

interface Props {
    drawerOpen: boolean
    label: string
    alert?: boolean
    to: string
    onClick?: () => void
    closeFlyout: () => void
    activePathMatch?: BasePath[]
}

const ListFlyOutMenuNameSub = ({drawerOpen, label, alert, to, closeFlyout, activePathMatch = []}: Props) => {
    const {pathname} = useLocation()

    const icon = (isActive: boolean) => {
        switch (label) {
            case 'Explore':
                return isActive ? <FilledSearch /> : <Search />
            case 'Wallet':
                return isActive ? <FilledWallet /> : <Wallet />
            case 'Share the aroha':
                return isActive ? <FilledLove /> : <Love />
            case 'Share the love':
                return isActive ? <FilledLove /> : <Love />
            case 'Settings':
                return isActive ? <FilledSettings /> : <Settings />
            default:
                return isActive ? <FilledPortfolio /> : <Portfolio />
        }
    }

    const pathnameWithoutProfile = pathname.replace(/\/profile\/[^/]+/, '')
    const isActive = activePathMatch.some(path => `${pathnameWithoutProfile}/`.startsWith(`/${path}/`))

    return (
        <NavLink
            onClick={closeFlyout}
            tabIndex={drawerOpen ? 0 : -1}
            className={cn({[styles.active]: isActive}, styles.menu, accessibility.button)}
            data-testid={`flyout-menu--${convertToKebabCase(label.toLowerCase())}`}
            to={to}
        >
            {icon(isActive)}
            {alert && (
                <svg viewBox="0 0 10 10" className={styles.alert}>
                    <circle cx="5" cy="5" r="4" strokeWidth="1" />
                </svg>
            )}
            <span className={styles.buttonText}>
                <strong>{label}</strong>
            </span>
        </NavLink>
    )
}

export default ListFlyOutMenuNameSub
