import React from 'react'
import {Model} from '~/api/retail/types'
import {validate} from '~/global/widgets/form-controls'
import {COUNTRIES, extractCountryNameFromCode} from '~/global/widgets/form-controls/country-select/CountrySelectInput'
import {Text, Select, AUStateSelectInput} from '~/global/widgets/form-controls/formik'
import {AddressComponents} from '~/store/identity/types'

interface ManualAddressFieldsProps {
    jurisdiction: Model.User['jurisdiction']
    selectedCountry: string
}

const COUNTRIES_REQUIRING_STATE = ['AU']

export const AddressValidation = (values: AddressComponents) => {
    const schema = {
        sublocality: [],
        street_number: [validate.required()],
        route: [validate.required()],
        locality: [validate.required()],
        state_code: COUNTRIES_REQUIRING_STATE.includes(values.country_code) ? [validate.required()] : [],
        postal_code: [validate.required()],
        country_code: [validate.required()],
    }
    return validate.generate<AddressComponents>(schema)(values)
}

export const ManualAddressFields = (props: ManualAddressFieldsProps) => {
    const jurisdictionName = extractCountryNameFromCode(props.jurisdiction)
    const orderedCountries = jurisdictionName
        ? [
              [props.jurisdiction.toUpperCase(), jurisdictionName],
              ...COUNTRIES.filter(country => country[1] !== jurisdictionName),
          ]
        : COUNTRIES
    const countrySelectChoices = [['', ''], ...orderedCountries].map(([value, label]) => {
        return {value, label}
    })

    const auForm = (
        <>
            <Text
                dataTestId="text-input--street-number"
                autoFocus
                name="street_number"
                label="House/unit/apartment number"
            />
            <Text dataTestId="text-input--street" name="route" label="Street" />
            <Text dataTestId="text-input--locality" autoComplete="locality" name="locality" label="City/town/suburb" />
            <AUStateSelectInput name="state_code" />
            <Text dataTestId="text-input--postal-code" autoComplete="postal-code" name="postal_code" label="Postcode" />
            <Select
                dataTestId="select--country-code"
                name="country_code"
                label="Country"
                placeholder="Choose a country"
                choices={countrySelectChoices}
            />
        </>
    )
    const nzForm = (
        <>
            <Text
                dataTestId="text-input--street-number"
                autoFocus
                name="street_number"
                label="House/unit/apartment number"
            />
            <Text dataTestId="text-input--street" name="route" label="Street" />
            <Text dataTestId="text-input--suburb" autoComplete="sublocality" name="sublocality" label="Suburb" />
            <Text dataTestId="text-input--city" autoComplete="locality" name="locality" label="City/town" />
            <Text dataTestId="text-input--postcode" autoComplete="postal-code" name="postal_code" label="Postcode" />
            <Select
                dataTestId="select--country-code"
                name="country_code"
                label="Country"
                placeholder="Choose a country"
                choices={countrySelectChoices}
            />
        </>
    )

    return props.selectedCountry === 'AU' ? auForm : nzForm
}

export default ManualAddressFields
