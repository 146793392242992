import React from 'react'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import MonoovaLegalDDRSA from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/MonoovaLegalDDRSA'

const MonoovaDDRSA: React.FunctionComponent<{}> = () => (
    <>
        <Toolbar dataTestId="toolbar--ddrsa" leftButton="back" title="Direct Debit Request Service Agreement" />
        <Page overrideDefaultTopPadding="withToolbarTitle">
            <MonoovaLegalDDRSA />
        </Page>
    </>
)

export default MonoovaDDRSA
