import React from 'react'
import {Model} from '~/api/retail/types'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {BasicInstrument} from '~/store/instrument/types'
import FeeModalInfo from '../help-modals/FeeModalInfo'

interface DetailProps {
    instrument: BasicInstrument
    jurisdiction: Model.User['jurisdiction']
    forBaseFund?: boolean
}

const TransactionFeesDetail: React.FunctionComponent<DetailProps> = ({
    instrument,
    jurisdiction,
    forBaseFund = false,
}) => {
    const heading = forBaseFund ? 'Transaction fees' : 'Managed fund transaction fees'

    return (
        <div>
            {tradingType(instrument) === 'managed' ? (
                <>
                    <h1>{heading}</h1>
                    <p>
                        Managed funds may charge a transaction fee when you buy and sell units. This is also known as a
                        ‘spread fee’.
                    </p>
                    <p>
                        The fee is added to the price when you buy, and deducted from the price when you sell. This is
                        charged by the fund, not Sharesies.
                    </p>
                    {/* do not display in baseFund */}
                    {!forBaseFund && (
                        <>
                            <p>You won’t be charged our transaction fee to buy or sell units in a managed fund.</p>
                            <p>
                                For a full run-down of our fees,{' '}
                                <a
                                    target="_blank"
                                    rel="noopener"
                                    href={
                                        jurisdiction === 'au'
                                            ? 'https://sharesies.com.au/pricing'
                                            : 'https://www.sharesies.nz/pricing'
                                    }
                                >
                                    check out our website
                                </a>
                                .
                            </p>
                        </>
                    )}
                </>
            ) : (
                <>
                    <h1>Transaction fees</h1>
                    <FeeModalInfo jurisdiction={jurisdiction} />
                </>
            )}
        </div>
    )
}

export default TransactionFeesDetail
