import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'

export const BuyAndSellFeesManagedFund = () => {
    return (
        <ModalLink
            dataTestId="modal-link--buy-sell-fees-managed-funds"
            label="Fees for managed funds"
            asIcon
            modalTitle="Fees on managed funds"
            primaryButton={{label: 'Ok'}}
            bottomBorder
            helpIconSize={16}
        >
            <h2 className={spacing.spaceBelow12}>Managed fund transaction fee</h2>
            <p>
                Managed funds may charge a transaction fee when you buy and sell units. This is also known as a ‘spread
                fee’.
            </p>
            <p>
                The fee is added to the price when you buy, and deducted from the price when you sell. This is charged
                by the fund, not Sharesies.
            </p>
            <p>You won’t be charged our transaction fee to buy or sell units in a managed fund.</p>
            <h2 className={cn(spacing.spaceBelow12, spacing.spaceAbove32)}>Management fee</h2>
            <p>
                When you invest in an ETF or managed fund, you’re charged a management fee. This is charged by the fund
                provider—not Sharesies—and included in the unit price.
            </p>
            <p>
                The management fee pays for the fund’s management, distribution, and operational costs. It’s different
                for each fund, so check the fund info before you invest.
            </p>
            <p>
                We charge managed fund providers a fee, which you can expect to make up some part of the management fee
                they charge.
            </p>
            <p>
                For a full run-down of our fees, check out our{' '}
                <HelpCentreLink auArticle="4983203-fees-on-sharesies" nzArticle="813634-fees" />.
            </p>
        </ModalLink>
    )
}

export default BuyAndSellFeesManagedFund
