import {useAppSelector} from '~/store/hooks'

/**
 * Get a list of available application pointers. These are barebones pointers to load a full application. Applications
 * that the customer has already applied for are filtered out - and this currently only returns votes
 */
export const useApplicationPointers = () => {
    // This corresponds to m.Customer.get_pending_applications() which filters out cancelled applications
    const pendingOrders = useAppSelector(s => s.identity.orders)
    const applicationPointers = useAppSelector(s => s.identity.applicationPointers)

    return applicationPointers
        .filter(pointer => ['VOTE', 'EXERCISE'].includes(pointer.type))
        .filter(
            pointer =>
                !pendingOrders.some(
                    order =>
                        order.type === 'corporate_action_v2' &&
                        order.application?.rule_id === pointer.application_rule_id,
                ), // no matching pending order
        )
}
