import {Button} from '@design-system/button'
import {CheckSingle} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {accessibility, spacing} from '~/global/scss/helpers'
import {isUSExchange} from '~/global/utils/share-transfers/shareTransfers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {useTransferInfo} from '~/global/utils/use-transfer-info/useTransferInfo'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {SearchInput} from '~/global/widgets/form-controls'
import InstrumentRow from '~/global/widgets/instrument-row/InstrumentRow'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import autoInvestCommonStyles from '~/sections/invest/sections/auto-invest/Common.scss'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import styles from '~/sections/invest/sections/transfer-shares/pages/transfer-shares-select/TransferSharesSelect.scss'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import {portfolioItems} from '~/store/identity/selectors'
import instrumentActions from '~/store/instrument/actions'
import {Instrument} from '~/store/instrument/types'
import actions from '~/store/transfer/actions'
import {StagedTransferOrder, NEW_DRS_REQUIRED} from '~/store/transfer/types'

const TransferSharesSelect: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const resetOrder = () => dispatch(actions.ResetStagedTransferOrderInstruments())
    const loadTransferableInInstruments = (exchange: StagedTransferOrder['exchange'], searchTerm?: string) =>
        dispatch(instrumentActions.loadTransferableInInstruments(exchange!, searchTerm))
    const loadTransferableOutInstruments = (exchange: StagedTransferOrder['exchange']) =>
        dispatch(instrumentActions.loadTransferableOutInstruments(exchange!))
    const setRecentlyViewedInstrument = (instrumentId: string) =>
        dispatch(identityActions.SetSearchedFund(instrumentId))
    const setTransferOrderSearchTerm = (searchTerm: string) => dispatch(actions.SetTransferOrderSearchTerm(searchTerm))

    const instrumentsIndex = useAppSelector(({instrument}) => instrument.transferableIndex)
    const instrumentsById = useAppSelector(({instrument}) => instrument.instrumentsById)
    const savedSearchTerm = useAppSelector(({transfer}) => transfer.stagedTransferOrder?.searchTerm)
    const stagedTransferOrder = useAppSelector(({transfer}) => transfer.stagedTransferOrder)
    const direction = stagedTransferOrder?.direction
    const stagedOrderInstruments = useAppSelector(({transfer}) =>
        transfer.stagedTransferOrder ? transfer.stagedTransferOrder.instruments : [],
    )
    const loadingState = useAppSelector(({instrument}) => instrument.transferableLoadingState)
    const portfolioItemsMap = useAppSelector(s => portfolioItems(s))

    const [searchTerm, setSearchTerm] = React.useState(savedSearchTerm ? savedSearchTerm : '')
    const [throttledLoad, setThrottledLoad] = React.useState(false)

    // showSearch is only false for a split second so we can reset the component for the "Add more" button
    const [showSearch, setShowSearch] = React.useState(true)

    const exchange = stagedTransferOrder?.exchange

    const transferInfo = useTransferInfo(direction, stagedTransferOrder?.usTransferPlatform)

    // We show a loader if we are loading in the instruments the first time (`loadingState`) or there is a
    // throttled search going on (`throttledLoad`). It's an ASX transfer, we don't load instruments
    // on load so in that case we want to show the loader if they actually have a search term and not show
    // anything on initial render
    const showLoader = (loadingState === 'loading' || throttledLoad) && !!searchTerm

    // We only want to allow one instrument per DRS (Direct registry system - US) transfer request
    const isDrsTransfer = stagedTransferOrder!.usTransferPlatform === 'DRS'
    const disableButton = isDrsTransfer && stagedOrderInstruments.length > 0

    React.useEffect(() => {
        if (!stagedTransferOrder) {
            navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})
            return
        }
        if (direction === 'out') {
            // Load portfolio instruments on page load
            loadTransferableInstruments()
        }
    }, [])

    // Load the instruments on searchTerm change
    React.useEffect(() => {
        setTransferOrderSearchTerm(searchTerm)
        if (searchTerm === '') {
            return
        }
        // Throttle the search function to avoid triggereing API calls every keystroke
        setThrottledLoad(true)
        const searchRequestTimeout = setTimeout(() => {
            loadTransferableInstruments()
            setThrottledLoad(false)
        }, 350)
        return () => clearInterval(searchRequestTimeout)
    }, [searchTerm])

    const filteredInstrumentIds = React.useMemo((): string[] => {
        if (direction === 'out') {
            const normalisedSearchTerm = searchTerm.toLowerCase().trim()

            // Search locally when transferring out.
            return instrumentsIndex.filter(instrumentId => {
                const instrument = instrumentsById[instrumentId]

                if (instrument.exchange === 'US OTC') {
                    return false
                }

                if (!normalisedSearchTerm) {
                    return true
                }

                return (
                    instrument.name.toLowerCase().includes(normalisedSearchTerm) ||
                    instrument.urlSlug.toLowerCase().includes(normalisedSearchTerm)
                )
            })
        }
        // Searching all instruments (direction=in) is handled via APIs
        return instrumentsIndex
    }, [searchTerm, instrumentsIndex])

    const addInstrument = (instrumentId: string, instrumentSlug: string) => {
        dispatch(actions.AddInstrumentToStagedTransferOrder(instrumentId, instrumentSlug))
    }
    const removeInstrument = (instrumentId: string) => {
        dispatch(actions.RemoveInstrumentFromStagedTransferOrder(instrumentId))
    }

    const loadTransferableInstruments = () => {
        switch (direction) {
            case 'in':
                loadTransferableInInstruments(stagedTransferOrder!.exchange, searchTerm)
                break
            case 'out':
                loadTransferableOutInstruments(stagedTransferOrder!.exchange)
                break
        }
    }

    const infoContent = () => {
        const currentExchange = exchange === 'US_MARKETS' ? 'NYSE' : exchange // TransferInfo doesn't know about US_MARKETS so replacing it with NYSE to get fee info
        return (
            <>
                {!isDrsTransfer && 'You can add more than one investment at a time.'}
                {direction === 'out' && (
                    <>
                        {' '}
                        Each investment will cost{' '}
                        {transferInfo && currentExchange && currentExchange in transferInfo.fees && (
                            <DollarValue
                                value={transferInfo?.fees[currentExchange].charge_amount}
                                decimalPlaces={0}
                                currency={isUSExchange(currentExchange) ? 'USD' : ''}
                            />
                        )}
                    </>
                )}
            </>
        )
    }

    const urlExchange = () => {
        switch (stagedTransferOrder!.exchange) {
            case 'ASX':
                return 'asx'
            case 'NZX':
                return 'nzx'
            default:
                return 'us'
        }
    }

    const handleClick = (instrument: Instrument) => {
        setRecentlyViewedInstrument(instrument.id)

        navigate(
            profileUrl(
                searchTerm
                    ? `invest/portfolio-transfer-shares/:exchange/select/search/:instrumentSlug`
                    : `invest/portfolio-transfer-shares/:exchange/select/:instrumentSlug`,
                {
                    exchange: urlExchange(),
                    instrumentSlug: instrument.urlSlug,
                },
            ),
        )
    }

    if (
        stagedTransferOrder?.direction === 'in' &&
        stagedTransferOrder.reference !== NEW_DRS_REQUIRED &&
        !stagedTransferOrder?.registryDetailId
    ) {
        navigate(profileUrl('invest/portfolio-transfer-shares'))
    }

    if (!transferInfo) {
        return <Loading isPineapple />
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--transfer-shares-select" leftButton="back" />
            <Page withoutDefaultPadding>
                <div className={styles.lightHeader}>
                    <h1 className={cn(commonStyles.heading, spacing.spaceBelow32)}>
                        What shares would you like to transfer {direction}?
                    </h1>

                    <div className={spacing.spaceAbove32}>
                        {showSearch && (
                            <SearchInput
                                name="search-term"
                                isTouched
                                isLoading={showLoader}
                                onQueryChange={setSearchTerm}
                                setCurrentSearchInput={setSearchTerm}
                                initialValue={searchTerm}
                                autoFocus
                            />
                        )}
                    </div>
                </div>
                {!showLoader && (filteredInstrumentIds.length === 0 || (!searchTerm && direction === 'in')) ? (
                    <p className={commonStyles.sideMargin}>{infoContent()}</p>
                ) : null}
                <div className={styles.linenPageContainer}>
                    {showLoader ? (
                        <Loading />
                    ) : searchTerm || direction === 'out' ? (
                        <>
                            {filteredInstrumentIds.map(instrumentId => {
                                const instrument = instrumentsById[instrumentId]
                                const instrumentInStagedOrder = !!stagedOrderInstruments.find(
                                    stagedOrderInstrument => instrument.id === stagedOrderInstrument.instrumentId,
                                )
                                const instrumentHolding = portfolioItemsMap[instrument.id]

                                let sharesRemaining
                                if (direction === 'out' && instrumentHolding) {
                                    sharesRemaining =
                                        parseFloat(instrumentHolding.holding ? instrumentHolding.holding.shares : '0') -
                                        instrumentHolding.sharesNotAvailableForSell
                                }

                                return (
                                    <div
                                        key={instrument.id}
                                        className={cn(styles.fundSelect, {
                                            [styles.selected]: instrumentInStagedOrder,
                                        })}
                                    >
                                        <InstrumentRow
                                            holdingShares={sharesRemaining ? `${sharesRemaining}` : undefined}
                                            hideSortDisplay
                                            key={instrument.id}
                                            instrument={instrument}
                                            hideBorder
                                            onClick={() => handleClick(instrument)}
                                            oldLayout
                                        />
                                        <button
                                            type="button"
                                            className={cn(accessibility.button, styles.addFundButton)}
                                            onClick={() =>
                                                !instrumentInStagedOrder
                                                    ? addInstrument(instrument.id, instrument.urlSlug)
                                                    : removeInstrument(instrument.id)
                                            }
                                            data-testid="div--select-instrument"
                                            disabled={disableButton && !instrumentInStagedOrder}
                                        >
                                            {instrumentInStagedOrder ? <CheckSingle /> : 'Add'}
                                        </button>
                                    </div>
                                )
                            })}
                            {filteredInstrumentIds.length > 0 ? (
                                <p className={spacing.spaceAbove12}>{infoContent}</p>
                            ) : null}
                        </>
                    ) : null}
                </div>
            </Page>
            <ActionBar className={cn(autoInvestCommonStyles.footer, styles.footer)}>
                <div className={cn({[autoInvestCommonStyles.zeroSelected]: stagedOrderInstruments.length === 0})}>
                    {stagedOrderInstruments.length} added
                    {stagedOrderInstruments.length > 0 && (
                        <>
                            <span>|</span>{' '}
                            <a
                                href="#"
                                onClick={e => {
                                    e.preventDefault()
                                    resetOrder()
                                }}
                            >
                                Reset
                            </a>
                        </>
                    )}
                </div>
                {!isDrsTransfer && stagedOrderInstruments.length > 0 && (
                    <Button
                        width="auto"
                        dataTestId="button--select-add-more"
                        onClick={() => {
                            // Hacky reset of the Search input - unmounts and remounts the <SearchInput> component
                            // by hiding it then showing it again - also clears the search term populating results

                            // The end result is that clicking "Add more" clears your search and focuses the search input
                            setShowSearch(false)
                            setSearchTerm('')
                            setTimeout(() => setShowSearch(true), 0)
                        }}
                        label="Add more"
                        type="secondary"
                        additionalClassName={styles.addMore}
                    />
                )}
                <Button
                    width="auto"
                    dataTestId="button--select-transfer"
                    onClick={() => {
                        const firstInstrumentSlug = [...stagedOrderInstruments].sort(ins => ins.index)[0].instrumentSlug

                        navigate(
                            profileUrl(`invest/portfolio-transfer-shares/:exchange/details/:instrumentSlug`, {
                                exchange: urlExchange(),
                                instrumentSlug: firstInstrumentSlug,
                            }),
                        )
                    }}
                    disabled={stagedOrderInstruments.length === 0}
                    label="Next"
                />
            </ActionBar>
        </>
    )
}

export default TransferSharesSelect
