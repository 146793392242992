import cn from 'classnames'
import React from 'react'
import {AnnouncedCorporateAction} from '~/global/state-hooks/retail/useAnnouncedCorporateActions'
import {convertDateTime} from '~/global/utils/convert-date-time/convertDateTime'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import detailModalStyles from '~/global/widgets/detail-modals/DetailModal.scss'
import {DividendValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import {BasicInstrument} from '~/store/instrument/types'

interface DeclaredCAListProps {
    corporateActions: AnnouncedCorporateAction[]
    instrument: BasicInstrument
}
const AnnouncedCAList = ({corporateActions, instrument}: DeclaredCAListProps) => {
    return (
        <>
            <div className={cn(detailModalStyles.rowEntry, detailModalStyles.rowHeading)}>
                <p>Ex dividend date</p>
                <p>Dividend per share</p>
            </div>
            <div>
                {corporateActions.map(ca => (
                    <div key={ca.id} className={detailModalStyles.rowEntry}>
                        <p>{convertDateTime(ca.ex_date)} </p>
                        {ca.outcomes.map(outcome =>
                            outcome.currency ? ( // TODO think about this
                                <DividendValue
                                    key={outcome.id}
                                    value={outcome.amount_per_input_unit}
                                    currency={outcome.currency}
                                />
                            ) : (
                                <ShareValue
                                    key={outcome.id}
                                    value={outcome.amount_per_input_unit}
                                    suffixLabel={shareLabel({instrument, isPlural: true})}
                                />
                            ),
                        )}
                    </div>
                ))}
            </div>
        </>
    )
}

export default AnnouncedCAList
