import React from 'react'
import {useRetailGet} from '~/api/query/retail'
import type {Model, Response} from '~/api/retail/types'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {splitAccountAndBsb} from '~/global/utils/split-account-and-bsb/splitAccountAndBsb'
import {useReduxSuspense} from '~/global/utils/use-redux-suspense/useReduxSuspense'
import {useLegacyUserData} from '~/sections/wallet/state/retail'
import bankLinkingActions from '~/store/bankLinking/actions'
import {useAppSelector} from '~/store/hooks'

const useBankLinkingMonitoringAccount = (jurisdiction: Model.User['jurisdiction']) => {
    return useReduxSuspense(
        s => s.bankLinking.roundupsMonitoringAccounts,
        s => s.bankLinking.bankLinkingLoadingState === 'loading',
        s => s.bankLinking.bankLinkingLoadingState === 'error',
        jurisdiction === 'au' ? bankLinkingActions.FetchBankLinkingStatus() : undefined,
    )
}

const useBankLinkingAccounts = (jurisdiction: Model.User['jurisdiction']) => {
    const roundupsMonitoringAccounts = useBankLinkingMonitoringAccount(jurisdiction)
    // The load action for roundupsDebitAccount is the same as for
    // roundupsMonitoringAccounts so we can assume it is present in the store as
    // soon as suspending for roundupsMonitoringAccounts is done
    const roundupsDebitAccount = useAppSelector(s => s.bankLinking.roundupsDebitAccount)

    const bankLinkingAccounts = roundupsMonitoringAccounts
    // Add roundups debit account (if it isn't in monitoringAccounts already)
    if (
        roundupsDebitAccount &&
        !bankLinkingAccounts.find(acc => acc.basiqAccountId !== roundupsDebitAccount.basiqAccountId)
    ) {
        bankLinkingAccounts.push(roundupsDebitAccount)
    }

    return bankLinkingAccounts
}

// combined type for AU and NZ bank accounts (optional bsb)
type AnyBankAccount = (Response.OwnerBankAccounts['nz_accounts'][number] & {bsb?: string})[]

const accountsForJurisdiction = (
    accountResponse: Response.OwnerBankAccounts,
    jurisdiction: Model.User['jurisdiction'],
): AnyBankAccount => {
    return accountResponse[`${jurisdiction}_accounts`]
}

export const useBankAccounts = () => {
    const profile = useProfile()
    const {jurisdiction} = useLegacyUserData()
    const bankLinkingAccounts = useBankLinkingAccounts(jurisdiction)
    const accountsForOwner = useRetailGet({
        path: 'owner/:owner_id/bank-accounts',
        pathParams: {owner_id: profile.owner_id},
    }).data
    const regularAccounts = accountsForJurisdiction(accountsForOwner, jurisdiction).filter(
        account => account.valid_for_withdrawal,
    )

    const accounts = React.useMemo(() => {
        // Only add bank linking accounts if the portfolio is a legacy portfolio (until bank linking is available for all portfolios)
        if (profile.legacy_profile_type) {
            if (bankLinkingAccounts.length > 0) {
                return regularAccounts.concat(
                    bankLinkingAccounts.reduce<typeof regularAccounts>((acc, curr) => {
                        const result = splitAccountAndBsb(curr.accountNumber)
                        if (!result.success) {
                            return acc
                        }
                        acc.push({
                            id: curr.basiqAccountId, // Not relevant, just needs to be unique
                            name: curr.accountName,
                            number: result.accountNumber,
                            bsb: result.bsb,
                            valid_for_withdrawal: true,
                        })
                        return acc
                    }, []),
                )
            }
        }
        return regularAccounts
    }, [regularAccounts, bankLinkingAccounts, profile.legacy_profile_type])

    return accounts
}
