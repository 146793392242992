import {colour, semanticColour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {Period} from '~/global/utils/time-period/timePeriod'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {useAppSelector} from '~/store/hooks'
import {PortfolioHistoryItem} from '~/store/portfolio/types'
import styles from './PortfolioChartValues.scss'

interface ControlsProps {
    values: PortfolioHistoryItem | undefined
    period: Period
    onPeriodChange: (period: Period) => void
}

const PortfolioChartValues: React.FC<ControlsProps> = ({values}) => {
    const colourMode = useColourMode()
    const includeSoldInvestments = useAppSelector(s => s.identity.includeSoldInvestments)
    const totalReturn = !values ? 0 : includeSoldInvestments ? values.total_return : values.unrealised_total_return
    const costBasis = values ? values.cost_basis : 0

    return (
        <>
            <div className={styles.numbersRow}>
                <div>
                    <div className={styles.key}></div>
                    <label>Date</label>
                    <div className={styles.numbersRowValue}>
                        {values ? DateTime.fromISO(values.date).toFormat('d MMM yyyy') : ''}
                    </div>
                </div>
                <div>
                    <div className={cn(styles.key, styles.contributionKey)}></div>
                    <label>Amount put in</label>
                    <div className={styles.numbersRowValue}>
                        <DollarValue value={costBasis} decimalPlaces={costBasis > 1e6 ? 0 : 2} />
                    </div>
                </div>
                <div>
                    <div className={styles.key}></div>
                    <label>Total return*</label>
                    <div className={styles.numbersRowValue}>
                        <svg
                            width="9"
                            height="8"
                            viewBox="0 0 9 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{transform: totalReturn < 0 ? 'rotate(180deg)' : ''}}
                        >
                            <path
                                d="M4.5 0L9 8H0L4.5 0Z"
                                fill={
                                    totalReturn > 0
                                        ? semanticColour('SuccessPrimary', colourMode)
                                        : totalReturn < 0
                                          ? colour('Orange500', colourMode)
                                          : colour('Neutral300', colourMode)
                                }
                            />
                        </svg>

                        <DollarValue value={totalReturn} decimalPlaces={totalReturn > 1e6 ? 0 : 2} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default PortfolioChartValues
