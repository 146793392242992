import {useAppSelector} from '~/store/hooks'
import {useLatestInstrumentReturns} from '../rakaia/useLatestInstrumentReturns'
import {useEmployeeShareSchemeDetails} from '../retail/useEmployeeShareSchemeDetails'
import {useProfile} from '../retail/useProfile'

export const useEssTotalUnvestedInvestmentValue = (instrumentId: string) => {
    const profile = useProfile()

    const investPortfolio = profile.portfolios.find(p => p.product === 'INVEST')!
    const instrumentReturns = useLatestInstrumentReturns(investPortfolio.id).instrument_returns

    const instrument = useAppSelector(s => s.instrument.instrumentsById[instrumentId])
    const shareSchemeAllocationsForInstrument =
        useEmployeeShareSchemeDetails().shareSchemeAllocationsByInstrument[instrumentId]

    if (!shareSchemeAllocationsForInstrument || !instrument) {
        return undefined
    }

    const unvestedSharesValue = instrumentReturns[instrumentId]?.unvested_detail.unvested_investment_value
    const unvestedShareRight = shareSchemeAllocationsForInstrument.total_unvested_share_rights
    const instrumentMarketPrice = instrument.marketPrice
    const unvestedShareRightValue = unvestedShareRight * parseFloat(instrumentMarketPrice)

    if (!unvestedShareRight) {
        return unvestedSharesValue
    }

    if (!unvestedSharesValue) {
        return unvestedShareRightValue
    }

    return unvestedSharesValue + unvestedShareRightValue
}
