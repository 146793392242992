import {DateTime} from 'luxon'
import React from 'react'
import {useLegacyUserData} from '~/sections/wallet/state/retail'

/**
 * Checks if customer is in the threshold exceeded window (they've triggered
 * additional ID requirements, they haven't yet supplied them, and it's within
 * 14 days of the threshold being reached) and thus is restricted to only being
 * able to withdraw to their source bank account, and returns a boolean
 * indicating that plus the date the account will be restricted after as a
 * string. See https://sharesies.atlassian.net/browse/STRAYA-137
 */
export const useCheckIfWithdrawalRestrictedToSource = () => {
    const [inThresholdExceededWindow, setInThresholdExceededWindow] = React.useState<boolean>(false)
    const {identityVerification} = useLegacyUserData()

    const biometricRestrictAccountAfterDate =
        identityVerification.latest_biometric_verification_check?.restrict_account_after
    const secondaryIdRestrictAccountAfterDate =
        identityVerification.secondary_identity_document_check?.restrict_account_after
    const additionalVerificationRequired = identityVerification.additional_verification_required
    const restrictAccountAfter = biometricRestrictAccountAfterDate ?? secondaryIdRestrictAccountAfterDate
    const pastExpiry = Boolean(restrictAccountAfter && restrictAccountAfter < DateTime.local())

    React.useEffect(() => {
        // check if in the restricted window
        if (additionalVerificationRequired && restrictAccountAfter && !pastExpiry) {
            setInThresholdExceededWindow(true)
        } else {
            setInThresholdExceededWindow(false)
        }
    }, [restrictAccountAfter, secondaryIdRestrictAccountAfterDate, pastExpiry])

    return {withdrawalRestrictedToSource: inThresholdExceededWindow, restrictAccountAfter}
}
