import React from 'react'
import {Radio} from '~/global/widgets/form-controls'

interface TypeOptions<T extends string> {
    value: T
    label: string
    helpText: React.ReactNode | string
}

interface Props<T extends string> {
    choices: TypeOptions<T>[]
    value?: T
    handleChange: (v: T) => void
}

const ReportType = <T extends string>({choices, value, handleChange}: Props<T>) => {
    return (
        <Radio
            dataTestId="radio--report-type"
            name="report-type"
            label="Report type"
            isTouched
            value={value}
            choices={choices}
            onChange={e => handleChange(e.target.value as T)}
        />
    )
}

export default ReportType
