import React from 'react'

/**
 * Returns a DOMRectReadOnly representing the size of a element, updating on resize
 *
 * Caveats:
 * - This returns the size of the content box, not the border box. If the target has a border, you're probably going to have a bad time
 * - For browsers that don't support the ResizeObserver API, this will return the border box, once, on render.
 */
export function useRefSize(ref: React.MutableRefObject<HTMLElement | null | undefined>): DOMRectReadOnly | null {
    const [size, setSize] = React.useState<DOMRectReadOnly | null>(null)

    React.useEffect(() => {
        // It's important to read the ref _inside_ the hook, as ref.current won't be defined during the first render,
        // but is guaranteed to be defined when hooks are evaluated
        const element = ref.current
        if (!element) {
            return
        }

        if ('ResizeObserver' in window) {
            const observer = new ResizeObserver(entries => {
                const entry = entries.find(e => e.target === element)

                // Technically, this should probably be entry.borderBoxSize
                // However, this isn't as well supported as contentRect
                // As long as the target doesn't have a border, these should be equivalent
                // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/borderBoxSize
                if (entry?.contentRect?.width === 0 && entry?.contentRect?.height === 0) {
                    // Handle an edge case where the element is hidden and we don't know it's size
                    setSize(null)
                } else {
                    setSize(entry?.contentRect ?? null)
                }
            })

            observer.observe(element)

            return () => observer.disconnect()
        } else {
            setSize(element.getClientRects().item(0))
        }
    }, [ref.current])

    return size
}
