import React from 'react'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {NotificationContext} from '~/global/wrappers/global-wrapper-widgets/notification-provider/NotificationProvider'
import {useNavigate} from '~/migrate-react-router'
import {StagedSellOrder} from '~/store/order/types'

const SellErrors: React.FunctionComponent<SellErrorsProps> = ({
    stagedSellOrder,
    isDependent,
    preferredName,
    instrumentName,
    updateStagedSellOrder,
}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const notificationContext = React.useContext(NotificationContext)
    const {showModalError, closeModalError} = notificationContext

    const resetError = () => {
        if (stagedSellOrder) {
            updateStagedSellOrder({
                ...stagedSellOrder,
                error: undefined, // Resetting error
            })
        }
    }

    const onClose = () => {
        resetError()
        closeModalError()
    }

    React.useEffect(() => {
        if (!stagedSellOrder.error) {
            return
        }

        switch (stagedSellOrder.error) {
            case 'already_buying':
                showModalError({
                    message: (
                        <>
                            <p>
                                {isDependent ? `${preferredName}’s` : 'Your'} sell order for {instrumentName} can’t be
                                placed because {isDependent ? `${preferredName} has` : 'you’ve'} also got a pending buy
                                order for this investment.
                            </p>
                            <p>
                                {isDependent ? preferredName : 'You'} can place a buy order for this investment when{' '}
                                {isDependent ? `${preferredName}’s` : 'your'} sell order is complete, or you cancel it.
                            </p>
                        </>
                    ),
                    onClose: () => onClose,
                    primaryButton: {label: 'Ok', onClick: onClose},
                    title: 'Can’t buy and sell at the same time',
                })
                break
            case 'too_many_pending':
                showModalError({
                    message: (
                        <>
                            <p>
                                {isDependent ? `${preferredName}’s` : 'Your'} order for {instrumentName} can’t be placed
                                because {isDependent ? `${preferredName} has` : 'you’ve'} already got two pending orders
                                for this investment.
                            </p>
                            <p>
                                {isDependent ? preferredName : 'You'} can place another order for this investment when{' '}
                                {isDependent ? `${preferredName} has` : 'you have'} less than two pending orders.
                            </p>
                        </>
                    ),
                    onClose: () => onClose,
                    primaryButton: {label: 'Ok', onClick: onClose},
                    title: 'Too many orders for this investment',
                })
                break
            case 'need_participant_email':
                showModalError({
                    message: (
                        <>
                            <p>
                                Uh oh. {isDependent ? preferredName : 'You'} can’t place orders for exchange-traded
                                companies and ETFs until {isDependent ? `${preferredName} has` : 'you’ve'} given us some
                                more info.{' '}
                            </p>

                            <p>
                                You’ve told us that {isDependent ? `${preferredName} is` : 'you are'} a Prescribed
                                Person. This means we need to email {isDependent ? 'their' : 'your'} investing activity{' '}
                                {isDependent ? '' : '(and investing activity for any Kids accounts you manage)'} to the
                                employer.
                            </p>
                        </>
                    ),
                    onClose: () => onClose,
                    primaryButton: {
                        label: 'Add email now',
                        onClick: () => {
                            onClose()
                            navigate(profileUrl('settings/personal-details'))
                        },
                    },
                    title: 'We need some more info',
                })
                break
            case 'too_many_decimal_places':
                // TODO: we're currently handling this error on the sell forms
                // especially but will get the max decimal places before we
                // render the sell forms in the future
                break
            default:
                showModalError({
                    message: <p>We couldn’t complete the order, please try again.</p>,
                    onClose: () => onClose,
                    primaryButton: {
                        label: 'Ok',
                        onClick: onClose,
                    },
                })
        }
    }, [stagedSellOrder])

    return <></>
}

interface SellErrorsProps {
    stagedSellOrder: StagedSellOrder
    isDependent: boolean
    preferredName: string
    instrumentName: string
    updateStagedSellOrder(order: StagedSellOrder): void
}

export default SellErrors
