import {Modal} from '@design-system/modal'
import {Thumbnail} from '@design-system/thumbnail'
import cn from 'classnames'
import React from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useLocalStorage} from 'usehooks-ts'
import {DistillScope} from '~/api/query/distill'
import useDistillInstrumentInfo from '~/global/state-hooks/distill/useDistillInstrumentInfo'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Toast} from '~/global/widgets/toast/Toast'
import {
    generateInitialFilter,
    useDistillInstrument,
    useDistillSearch,
    useDistillSearchUnderlyingInstruments,
} from '~/sections/kiwisaver/data/distill'
import {SignUpPage} from '~/sections/kiwisaver/sections/sign-up/widgets/redirect/SignUpGuardRedirect'
import {
    InvestmentPlan,
    useKSCustomerOrNull,
    useSetKSInvestmentPlanDraft,
    useSetKSMarketingPlanDraft,
} from '~/sections/kiwisaver/state'
import {StackedImage} from '~/sections/kiwisaver/widgets/stacked-image/StackedImage'
import {useAppSelector} from '~/store/hooks'
import {actingAsID as actingAsIDSelector} from '~/store/identity/selectors'
import styles from './PrePopulate.scss'

type Allocations = InvestmentPlan['allocations']
type AllocationsWithSymbols = (Allocations[number] & {symbol: string; fund_id: ''})[]

/**
 * Grab URL search params and create an array of psuedo allocations objects from them, then clear
 * the search params.
 *
 * @returns {AllocationsWithSymbols} - pseudo allocations, with symbols and empty strings instead of fund_id
 */
const usePlanParams = () => {
    const [params, setParams] = useSearchParams()
    const [allocationsWithSymbols, setAllocationsWithSymbols] = React.useState<AllocationsWithSymbols>([])
    const relevantSearchParamsFound = params.has('base') || params.has('ss')

    React.useEffect(() => {
        if (relevantSearchParamsFound) {
            const allocations: AllocationsWithSymbols = []

            const baseFund = params.get('base') || ''
            const [baseFundSymbol, baseFundAllocationPercent] = baseFund.split('/')

            if (baseFundSymbol) {
                allocations.push({
                    allocation_percent: baseFundAllocationPercent || '100',
                    fund_category: 'BASE',
                    fund_id: '',
                    symbol: baseFundSymbol,
                })
            }

            const pickPairs = params.getAll('ss')
            pickPairs.forEach(pick => {
                const [symbol, allocationPercent] = pick.split('/')

                if (symbol) {
                    allocations.push({
                        allocation_percent: allocationPercent || '0',
                        fund_category: 'SELF_SELECT',
                        fund_id: '',
                        symbol,
                    })
                }
            })
            setAllocationsWithSymbols(allocations)

            // clear out the params
            setParams({}, {replace: true})
        }
    }, [relevantSearchParamsFound])

    return allocationsWithSymbols
}

/**
 * Update the pseudo allocations (with a symbol and no id) we got from the marketing site to
 * regular allocations we can attempt to save. We need to use the symbols to look up the fund id.
 *
 * @param {AllocationsWithSymbols} allocationsWithSymbols - raw allocations with no fund ID, and the symbol
 * @returns {Allocations} - investment plan allocations
 */
const useTransformAllocations = (allocationsWithSymbols: AllocationsWithSymbols): Allocations => {
    // TODO https://sharesies.atlassian.net/browse/SUPER-1348 make an endpoint to search by symbols
    const allInstruments = useDistillSearch({
        ...generateInitialFilter(DistillScope.KIWISAVER_ALL_FUNDS),
        searchFundInvestments: false,
    })
    const [transformedAllocations, setTransformedAllocations] = React.useState<Allocations>([])

    React.useEffect(() => {
        if (allocationsWithSymbols.length > 0 && allInstruments.length > 0) {
            setTransformedAllocations(
                allocationsWithSymbols.reduce((accumulator: Allocations, rawAllocation) => {
                    // performance note: filtering individually for each ID is pretty nasty if there is a lot of pseudoAllocations, but
                    // as allResults is definitely a much larger set, for most cases it's still faster than preprocessing allInstruments
                    // into a lookup hash. the real better solution in the long term here is to make a backend endpoint to get a result
                    // set for just the symbols we want.

                    const fund = allInstruments.find(fund => fund.symbol === rawAllocation.symbol)
                    if (!fund) {
                        // if we can't succesfully match a real fund, bail early
                        return accumulator
                    }

                    accumulator.push({
                        allocation_percent: rawAllocation.allocation_percent,
                        fund_category: rawAllocation.fund_category,
                        fund_id: fund.id,
                    })
                    return accumulator
                }, []),
            )
        }
    }, [allocationsWithSymbols, allInstruments])

    return transformedAllocations
}

const BaseFundModal = ({
    id,
    modalIsShown,
    toggleModal,
}: {
    id: string
    modalIsShown: boolean
    toggleModal: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const fund = useDistillInstrument({instrumentId: id, scope: DistillScope.KIWISAVER_BASE_FUNDS})
    const resourcePath = useDistillInstrumentInfo(DistillScope.KIWISAVER_ALL_FUNDS, {
        searchFundInvestments: true,
    }).filesHostAddress
    const underlyingFund = useDistillSearchUnderlyingInstruments({
        ...generateInitialFilter(DistillScope.KIWISAVER_BASE_FUNDS),
        instruments: [id],
    })[0]

    return (
        <Modal
            content={
                <>
                    <div className={styles.modalIllustration}>
                        <div className={styles.illustrationCard}>
                            <Thumbnail
                                dataTestId={`${underlyingFund.urlSlug}--logo`}
                                width="52px"
                                height="52px"
                                symbol={underlyingFund.symbol}
                                path={
                                    underlyingFund.logos.micro
                                        ? `${resourcePath}${underlyingFund.logos.micro}`
                                        : undefined
                                }
                            />{' '}
                            {fund.name}
                        </div>
                    </div>
                    <p>
                        Continue building your investment plan with the <strong>{fund.name}</strong>, or check out the
                        other base funds.
                    </p>
                </>
            }
            dataTestId="modal--base-plan-import"
            isOpen={modalIsShown}
            setIsOpen={toggleModal}
            title="Confirm your base fund"
            secondaryButton={{label: 'See other base funds'}}
            primaryButton={{
                label: 'Continue with this base fund',
                onClick: () => {
                    // just move them to the relevant screen, don't actually select it
                    navigate(
                        profileUrl('kiwisaver/sign-up/edit-investment-plan/view-fund/:urlSlug', {
                            urlSlug: underlyingFund.urlSlug,
                        }),
                    )
                },
            }}
        />
    )
}

const PicksModal = ({
    allAllocations,
    coverPickId,
    modalIsShown,
    toggleModal,
}: {
    allAllocations: Allocations
    coverPickId: string
    modalIsShown: boolean
    toggleModal: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const actingAsID = useAppSelector(actingAsIDSelector) // if the identity is anonymous, this will throw
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const setInvestmentPlan = useSetKSInvestmentPlanDraft()
    const illustrationFund = useDistillInstrument({
        instrumentId: coverPickId,
        scope: DistillScope.KIWISAVER_SELF_SELECT_FUNDS,
    })
    const numberOfPicks = allAllocations.length - 1

    return (
        <Modal
            content={
                <>
                    <div className={styles.modalIllustration}>
                        <div className={cn(styles.illustrationCard, styles.stackedThumbnail)}>
                            <StackedImage instrument={illustrationFund} size={36} />
                            {numberOfPicks} of your own pick{numberOfPicks > 1 ? 's' : ''}
                        </div>
                    </div>
                    <p>
                        Continue setting up your investment plan with the picks you made earlier, or start fresh to
                        select new ones.
                    </p>
                </>
            }
            dataTestId="modal--picks-import"
            isOpen={modalIsShown}
            setIsOpen={toggleModal}
            title="Confirm your picks"
            onFormSubmit={async event => {
                // using onFormSubmit rather that onClick on the button gives us a processing state during the mutate
                event?.preventDefault() // stop page refresh due to submit button
                try {
                    await setInvestmentPlan.mutateAsync({
                        acting_as_id: actingAsID,
                        allocations: allAllocations,
                    })
                    navigate(profileUrl('kiwisaver/sign-up/edit-investment-plan/edit-allocations'))
                } catch (e) {
                    Toast('Could not add picks')
                }
            }}
            secondaryButton={{
                label: 'Start fresh',
            }}
            primaryButton={{
                label: 'Continue with my picks',
            }}
        />
    )
}

/**
 * If we have query params passed in from the marketing site KS plan tool, parse
 * it and display modals for investors to be able to either pre-populate a selected
 * base plan and/or self select picks.
 *
 * Note this component can be remounted multiple times by its parent rerendering.
 */
export const PrePopulate = ({currentPage}: {currentPage: SignUpPage}) => {
    const actingAsID = useAppSelector(actingAsIDSelector) // if the identity is anonymous, this will throw
    const customer = useKSCustomerOrNull()
    const allocationsWithSymbols = usePlanParams()
    const draftPlanAllocations = useTransformAllocations(allocationsWithSymbols)
    const doSaveMarketingSiteDraftPlan = useSetKSMarketingPlanDraft()
    const selectedBaseFundId = customer?.marketing_site_investment_plan?.allocations.find(
        allocation => allocation.fund_category === 'BASE',
    )?.fund_id
    const selectedPickFundId = customer?.marketing_site_investment_plan?.allocations.find(
        allocation => allocation.fund_category === 'SELF_SELECT',
    )?.fund_id // at least one exists
    const baseHasBeenSelected = Boolean(
        customer?.draft_investment_plan?.allocations.find(allocation => allocation.fund_category === 'BASE') ||
            customer?.investment_plan?.allocations.find(allocation => allocation.fund_category === 'BASE'),
    )
    const picksHaveBeenSelected = Boolean(
        customer?.draft_investment_plan?.allocations.find(allocation => allocation.fund_category === 'SELF_SELECT') ||
            customer?.investment_plan?.allocations.find(allocation => allocation.fund_category === 'SELF_SELECT'),
    )
    const [showImportBaseFundModal, setShowImportBaseFundModal] = React.useState(false)
    const [showImportPicksModal, setShowImportPicksModal] = React.useState(false)
    const [errorImporting, setErrorImporting] = useLocalStorage('ksPlanPrePopulateError', false)
    const [baseModalViewed, setBaseModalViewed] = useLocalStorage('ksPlanPrePopulateBaseViewed', false)
    const customerIsCreated = !!customer?.customer_state

    React.useEffect(() => {
        const saveMarketingSiteDraftPlan = async () => {
            try {
                await doSaveMarketingSiteDraftPlan.mutateAsync({
                    acting_as_id: actingAsID,
                    allocations: draftPlanAllocations,
                })
            } catch (e) {
                setErrorImporting(true)
            }
        }
        // save the plan when we have values and the customer exists to save to
        if (customerIsCreated && draftPlanAllocations.length > 0) {
            saveMarketingSiteDraftPlan()
        }
    }, [draftPlanAllocations, customerIsCreated])

    React.useEffect(() => {
        if (
            currentPage === SignUpPage.EDIT_INVESTMENT_PLAN_BASE &&
            selectedBaseFundId &&
            !baseHasBeenSelected &&
            !baseModalViewed // we only want to show the modal once (in case of back nav) for consistency with the picks modal
        ) {
            setShowImportBaseFundModal(true)
            setBaseModalViewed(true)
        }
    }, [currentPage, selectedBaseFundId, baseHasBeenSelected]) // breaking rules of hooks here to NOT check the baseModalViewed condition as we don't want to loop when we update that, just affect subsequent views

    React.useEffect(() => {
        if (
            currentPage === SignUpPage.EDIT_INVESTMENT_PLAN_SELF_SELECT &&
            selectedPickFundId &&
            !picksHaveBeenSelected
        ) {
            setShowImportPicksModal(true)
        }
    }, [currentPage, selectedPickFundId, picksHaveBeenSelected])

    React.useEffect(() => {
        if (currentPage === SignUpPage.EDIT_INVESTMENT_PLAN_BASE && !baseHasBeenSelected && errorImporting) {
            Toast("We couldn't load your investment plan")
        }
    }, [currentPage, baseHasBeenSelected])

    return (
        <>
            {selectedBaseFundId && (
                <BaseFundModal
                    id={selectedBaseFundId}
                    modalIsShown={showImportBaseFundModal}
                    toggleModal={setShowImportBaseFundModal}
                />
            )}
            {selectedPickFundId && (
                <PicksModal
                    allAllocations={customer.marketing_site_investment_plan!.allocations} // must exist if we have a selectedPickFundId
                    coverPickId={selectedPickFundId}
                    modalIsShown={showImportPicksModal}
                    toggleModal={setShowImportPicksModal}
                />
            )}
        </>
    )
}
