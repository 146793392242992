import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {Response} from '~/api/retail/types'
import tooltipStyles from '~/global/scss/reused-styles/tooltip.scss'
import {dateFormatTime, dateFormatNoYear} from '~/global/utils/format-date/formatDate'
import {DollarValue, SharePriceValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import {Tooltip} from '~/global/widgets/tooltip/Tooltip'
import styles from './TodaysTrades.scss'

interface Props {
    todaysTrades: Response.LiveDataFetch['live_data']['recent_trades']
    vwapPrice: Response.LiveDataFetch['live_data']['vwap_price']
    isFromMidnightToOpen: boolean
    todaysTotalShares?: string
    todaysTotalAmount?: string
}

const TodaysTrades: React.FunctionComponent<Props> = ({
    todaysTrades,
    vwapPrice,
    isFromMidnightToOpen,
    todaysTotalShares,
    todaysTotalAmount,
}) => {
    return (
        <>
            {todaysTrades && (
                <div className={styles.todaysTrades}>
                    {!isFromMidnightToOpen && (
                        <>
                            <h2>Today’s trades</h2>
                            <hr />
                            <div className={styles.summary}>
                                <div className={styles.summaryFirstRow}>
                                    <p className={styles.title}>Date</p>
                                    <p className={styles.data}>{DateTime.local().toFormat(dateFormatNoYear)}</p>
                                </div>
                                <div className={styles.summaryFirstRow}>
                                    <p className={styles.title}>Total shares</p>
                                    {todaysTotalShares ? (
                                        <p className={styles.data}>
                                            <ShareValue value={todaysTotalShares} />
                                        </p>
                                    ) : (
                                        <p className={cn(styles.data, styles.noData)}>-</p>
                                    )}
                                </div>
                                <div className={styles.summarySecondRow}>
                                    <p className={styles.title}>Total amount</p>
                                    {todaysTotalAmount ? (
                                        <p className={styles.data}>
                                            <DollarValue value={todaysTotalAmount} />
                                        </p>
                                    ) : (
                                        <p className={cn(styles.data, styles.noData)}>-</p>
                                    )}
                                </div>
                                <div className={styles.summaryBorderTitle}>
                                    <Tooltip>
                                        <p className={cn(styles.title, tooltipStyles.label)}>
                                            Volume weighted average price
                                        </p>
                                        <div className={tooltipStyles.tooltip}>
                                            <p>
                                                The average price an investment has traded throughout the day by price
                                                and volume
                                            </p>
                                        </div>
                                    </Tooltip>
                                    {vwapPrice ? (
                                        <p className={styles.data}>
                                            <SharePriceValue value={vwapPrice} />
                                        </p>
                                    ) : (
                                        <p className={cn(styles.data, styles.noData)}>$ -</p>
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                    <h2>Latest trades</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Price</th>
                                <th>Shares</th>
                                <th>Amount</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {todaysTrades
                                .sort((a, b) => b.timestamp.toSeconds() - a.timestamp.toSeconds())
                                .map((trade, index) => (
                                    <tr key={`today’s trade-${trade.timestamp}-${index}`}>
                                        <td>
                                            <SharePriceValue value={trade.price} />
                                        </td>
                                        <td>
                                            <ShareValue value={trade.size} />
                                        </td>
                                        <td>
                                            <DollarValue value={parseFloat(trade.size) * parseFloat(trade.price)} />
                                        </td>
                                        <td>{trade.timestamp.toFormat(dateFormatTime)}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    )
}

export default React.memo(TodaysTrades)
