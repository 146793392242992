import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {getInvestingActivityData} from '~/global/utils/investing-activity/investingActivity'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Chips from '~/global/widgets/chips/Chips'
import {isDividendLikeCA} from '~/global/widgets/corporate-action-description/corporateActionsV2'
import {Loading} from '~/global/widgets/loading/Loading'
import TransactionRow from '~/global/widgets/transaction-row/TransactionRow'
import {Order, OrdersState} from '~/store/accounting/types'
import {EssViews} from '~/store/employeeShareScheme/selectors'
import {Instrument} from '~/store/instrument/types'
import {InstrumentReturn} from '~/store/portfolio/types'
import styles from './InvestingActivity.scss'

const InvestingActivityItem = ({order, instrument}: {order: Order; instrument: Instrument}) => {
    const profileUrl = useProfileUrl()
    const investingActivityData = getInvestingActivityData(order, instrument, profileUrl)

    if (
        (order.type === 'transfer_in' || order.type === 'transfer_out') &&
        ['incomplete', 'new', 'processing', 'cancelled'].includes(order.state)
    ) {
        return null
    }

    return (
        <TransactionRow
            amount={investingActivityData.amount}
            currency={instrument.currency}
            date={investingActivityData.displayDate}
            icon={investingActivityData.icon}
            title={investingActivityData.title}
            url={investingActivityData.url}
        />
    )
}

type Filter = (order: Order) => boolean
const filters: Record<string, Filter> = {
    All: () => true,
    Buys: order => ['buy', 'ess_share_allocation_line'].indexOf(order.type) !== -1,
    Sells: order => order.type === 'sell',
    Dividends: order => (order.type === 'corporate_action_v2' && isDividendLikeCA(order)) || order.type === 'dividend',
    'Corporate actions': order =>
        (order.type === 'corporate_action_v2' && !isDividendLikeCA(order)) || order.type === 'corporate_action',
    Transfers: order => order.type === 'transfer_in' || order.type === 'transfer_out',
}

interface InvestingActivityProps {
    instrument: Instrument
    orders: Order[]
    ordersState: OrdersState
    currentReturn?: InstrumentReturn
    essViews?: EssViews
}
const InvestingActivity = ({ordersState, orders, instrument, currentReturn, essViews}: InvestingActivityProps) => {
    const [selectedFilter, setSelectedFilter] = React.useState<keyof typeof filters>('All')

    const hasReturn = currentReturn && (currentReturn.cost_basis_max > 0 || currentReturn.dividends_received > 0)

    const showInvestingActivity = hasReturn || essViews?.hasVestedAlignmentRightAllocation

    if (!showInvestingActivity) {
        return null
    }

    // If the activity type exists in the orders, then include it in the chip or filter labels array
    const availableFilters = Object.entries(filters)
        .filter(([, filter]) => orders.filter(filter).length) // Check if each filter is any use
        .map(([name]) => name)
    const filteredOrders = orders.filter(order => filters[selectedFilter](order))

    return (
        <>
            <div className={styles.investingActivity}>
                <h2 className={spacing.spaceBelow16}>Investing activity</h2>
                {/* only show the chips if there is more than one activity type */}
                {availableFilters.length > 2 && (
                    <div className={styles.filterControlContainer}>
                        <Chips
                            options={availableFilters}
                            onChipClick={selected => setSelectedFilter(selected)}
                            selected={[selectedFilter]}
                            isInlineDisplay
                        />
                    </div>
                )}
                {ordersState === 'loading' && <Loading className={spacing.spaceAbove16} />}
                {filteredOrders.length > 0 && (
                    <div className={styles.activityContainer}>
                        {filteredOrders.map((order, index) => (
                            <InvestingActivityItem key={`${order.id}-${index}`} instrument={instrument} order={order} />
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}

export default InvestingActivity
