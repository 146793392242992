import cn from 'classnames'
import React, {useEffect} from 'react'
import SwipeableViews from 'react-swipeable-views'
import {mod} from 'react-swipeable-views-core'
import {virtualize} from 'react-swipeable-views-utils'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {useInstruments} from '~/global/utils/use-instruments/useInstruments'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {ChevronLeft, ChevronRight, DotBig} from '~/global/widgets/OLD_icons'
import {getIconAndTitleForApplication} from '~/global/widgets/corporate-action-description/corporateActionsV2'
import {ExchangeHours} from '~/global/widgets/exchange-hours/ExchangeHours'
import LoadingCard from '~/global/widgets/instrument-activity/LoadingCard'
import recordStyles from '~/global/widgets/instrument-activity/Record.scss'
import {Loading} from '~/global/widgets/loading'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import {useNavigate} from '~/migrate-react-router'
import AvailableApplicationCard from '~/sections/invest/sections/portfolio/widgets/available-application-card/AvailableApplicationCard'
import styles from '~/sections/invest/widgets/order-carousel/OrderCarousel.scss'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import {applicationPointers} from '~/store/identity/selectors'
import orderActions from '~/store/order/actions'

const VirtualizeSwipeableViews = virtualize(SwipeableViews)

const PortfolioCorporateActionsCarousel: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    // First, get the pointers and the currently selected pointer
    const pointers = useAppSelector(applicationPointers).filter(p => p.type === 'VOTE')

    const [scrollIndex, setCurrentApplicationIndex] = React.useState<number>(0)
    const currentApplicationIndex = mod(scrollIndex, pointers.length)

    const applicationsByInstrument = useAppSelector(({order}) => order.applicationsByInstrument)
    const pointer = pointers[currentApplicationIndex] as (typeof pointers)[number] | undefined

    useEffect(() => {
        if (!pointers.length) {
            navigate(profileUrl('invest'), {replace: true})
        }
    }, [pointers])

    // Load all the instruments and applications
    const pointerInstruments = Array.from(new Set(pointers.map(p => p.fund_id))).sort()
    const [instrumentsById, allInstrumentsLoaded] = useInstruments(pointerInstruments)

    useEffect(() => {
        pointerInstruments
            .filter(i => !applicationsByInstrument[i]) // If it's unloaded
            .forEach(instrumentId => {
                dispatch(orderActions.LoadInstrumentApplications(instrumentId)) // Load it
            })
    }, [pointerInstruments.join()])

    // Create a list of `applications` that may or may not be loaded.
    // If the application we're currently viewing happens to be loaded - great, we can render!
    // Otherwise, we'll render everything we can, and show a loading card - meaning that the customer can keep scrolling
    const applications = pointers.map(
        p =>
            applicationsByInstrument[p.fund_id]?.applications.find(
                application => application.corporate_action_id === p.id,
            ),
    )
    const application = applications[currentApplicationIndex]
    const instrument = pointer && instrumentsById[pointer.fund_id]

    if (!pointer || !allInstrumentsLoaded || !instrument) {
        return <Loading />
    }

    return (
        <Page withoutDefaultPadding>
            <div className={recordStyles.header}>
                <PageBack />
                {tradingType(instrument) !== 'managed' && <ExchangeHours instrument={instrument} />}
            </div>
            <div className={recordStyles.viewContainer}>
                <div className={recordStyles.titleBlock}>
                    <h2>{application && getIconAndTitleForApplication(application).title}</h2>
                    {applications.length > 1 && (
                        <span>{`${currentApplicationIndex + 1} of ${applications.length}`}</span>
                    )}
                </div>
                <VirtualizeSwipeableViews
                    resistance
                    index={currentApplicationIndex}
                    onChangeIndex={(index: number) => {
                        setCurrentApplicationIndex(index)
                    }}
                    className={styles.swipeableArea}
                    slideRenderer={({key}) =>
                        application ? (
                            <AvailableApplicationCard application={application} key={key} />
                        ) : (
                            <LoadingCard key={key} />
                        )
                    }
                    disabled={applications.length < 2}
                />
                {applications.length > 1 ? (
                    <div className={styles.controls}>
                        <div className={styles.controlLeft}>
                            <ChevronLeft onClick={() => setCurrentApplicationIndex(i => i - 1)} />
                        </div>
                        <div className={styles.controlDots}>
                            {applications.map((a, index) => (
                                <DotBig
                                    key={index}
                                    className={cn({
                                        [styles.selected]: a === application,
                                    })}
                                    onClick={() => setCurrentApplicationIndex(index)}
                                />
                            ))}
                        </div>
                        <div className={styles.controlRight}>
                            <ChevronRight onClick={() => setCurrentApplicationIndex(i => i + 1)} />
                        </div>
                    </div>
                ) : null}
            </div>
        </Page>
    )
}

export default PortfolioCorporateActionsCarousel
