import {EmployeeShareSchemeAllocationsByInstrument} from '~/store/employeeShareScheme/types'

export const containsUnvestedAllocations = (
    allocations: EmployeeShareSchemeAllocationsByInstrument,
    instrumentId?: string,
) => {
    if (!allocations) {
        return false
    }
    const allocationsToCheck = instrumentId ? [allocations[instrumentId]] : Object.values(allocations)
    return !!allocationsToCheck?.some(a => a && a.total_unvested_allocations > 0)
}

export const containsVestedAllocations = (
    allocations: EmployeeShareSchemeAllocationsByInstrument,
    instrumentId?: string,
) => {
    if (!allocations) {
        return false
    }
    const allocationsToCheck = instrumentId ? [allocations[instrumentId]] : Object.values(allocations)
    return !!allocationsToCheck?.some(a => a && a.total_vested_allocations > 0)
}

export const hasAllocation = (allocations: EmployeeShareSchemeAllocationsByInstrument, instrumentId?: string) => {
    if (!allocations) {
        return false
    }
    const allocationsToCheck = instrumentId ? [allocations[instrumentId]] : Object.values(allocations)
    return !!allocationsToCheck?.some(a => a && a.share_allocations.length > 0)
}

export const hasRightAllocation = (allocations: EmployeeShareSchemeAllocationsByInstrument, instrumentId?: string) => {
    if (!allocations) {
        return false
    }
    const allocationsToCheck = instrumentId ? [allocations[instrumentId]] : Object.values(allocations)
    return !!allocationsToCheck?.some(a => a && a.share_rights_allocations.length > 0)
}

export const hasAlignmentRightAllocation = (
    allocations: EmployeeShareSchemeAllocationsByInstrument,
    instrumentId?: string,
) => {
    if (!allocations) {
        return false
    }
    const allocationsToCheck = instrumentId ? [allocations[instrumentId]] : Object.values(allocations)
    return !!allocationsToCheck?.some(a => a && a.alignment_right_allocations.length > 0)
}

export const hasVestedAlignmentRightAllocation = (
    allocations: EmployeeShareSchemeAllocationsByInstrument,
    instrumentId?: string,
) => {
    if (!allocations) {
        return false
    }
    const allocationsToCheck = instrumentId ? [allocations[instrumentId]] : Object.values(allocations)
    return !!allocationsToCheck?.some(
        a => a && a.alignment_right_allocations.filter(ara => !!ara.lines[0].vesting_date).length > 0,
    )
}
