import {Button} from '@design-system/button'
import {DockableModal} from '@design-system/dockable-modal'
import {AlertCircle} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {useRetailPost} from '~/api/query/retail'
import {spacing} from '~/global/scss/helpers'
import {Toast} from '~/global/widgets/toast/Toast'
import styles from '~/sections/user/sections/settings/sections/notifications/pages/price-notifications/PriceNotifications.scss'
import {useAppSelector} from '~/store/hooks'

interface DeleteNotificationsProps {
    portfolioId: string
    forPortfoliosIds?: string
}

export const DeleteNotifications: React.FunctionComponent<DeleteNotificationsProps> = ({
    portfolioId,
    forPortfoliosIds,
}) => {
    const [modalOpen, setModalOpen] = React.useState(false)

    const deleteAllNotifications = useRetailPost({
        path: 'price-notification/:portfolio_id/delete-all',
        pathParams: {portfolio_id: portfolioId},
        queryCacheToUpdate: ['price-notification/:portfolio_id/get', {portfolio_id: portfolioId}],
        queryCacheToInvalidate: ['price-notification/for-portfolios', {portfolio_ids: forPortfoliosIds || portfolioId}],
    })

    const {customerId} = useAppSelector(s => ({
        customerId: s.identity.user!.id,
    }))

    return (
        <>
            <div className={cn(styles.deleteAll, spacing.spaceAbove40)} onClick={() => setModalOpen(true)}>
                Delete notifications
            </div>

            <DockableModal
                dataTestId="modal--notification-menu"
                isOpen={modalOpen}
                setIsOpen={setModalOpen}
                title={
                    <>
                        <div className={styles.deleteAllHeading}>
                            <AlertCircle colour="strawberry500" /> <div>Delete notifications</div>
                        </div>
                    </>
                }
                content={
                    <>
                        <p className={spacing.spaceBelow40}>
                            This will delete all of the price notifications you’ve set.
                        </p>
                        <Button
                            label="Delete all"
                            dataTestId="button--price-notifications-delete-all"
                            onClick={async () => {
                                await deleteAllNotifications.mutateAsync({
                                    acting_as_id: customerId,
                                })
                                Toast('All price notifications removed')
                                setModalOpen(false)
                            }}
                            aria-label="Confirm removing all price notification"
                        />
                    </>
                }
                customZIndex={1051} // same as zindex(detail-modal)
            />
        </>
    )
}
