import {
    calculateCurrencyExchangeFromSourceAmount,
    calculateCurrencyExchangeFromTargetAmount,
    roundDownToTwoDecimalPlaces,
    roundUpToTwoDecimalPlaces,
} from '~/global/utils/calculate-currency-exchange/calculateCurrencyExchange'

/**
 * This function does the bulk of the FX calculation to create an order. It factors in the fee, exchange rate, and amounts (in the UI)
 * returning a net source amount, total fee, and if any UI elements need updating to reflect the newly calculated exchange.
 */
export const calculateExchange = (
    mode: 'buy' | 'sell',
    rate: string,
    feeRate: string,
    sourceAmount: string,
    targetAmount: string,
): {
    sourceAmount?: string
    targetAmount?: string
    netSourceAmount: string
    feeTotal: string
} => {
    if (mode === 'buy') {
        // The user has set the target amount so we compute the appropriate source amount
        if (!targetAmount) {
            return {sourceAmount: '', netSourceAmount: '', feeTotal: ''}
        }
        const calc = calculateCurrencyExchangeFromTargetAmount(targetAmount, parseFloat(rate), parseFloat(feeRate))
        const sourceAmount = roundUpToTwoDecimalPlaces(calc.sourceAmount)
        return {
            sourceAmount,
            netSourceAmount: calc.amountToExchange,
            feeTotal: calc.exchangeFee,
        }
    } else {
        // The user has set the source amount so we compute the appropriate target amount
        if (!sourceAmount) {
            return {targetAmount: '', netSourceAmount: '', feeTotal: ''}
        }
        const calc = calculateCurrencyExchangeFromSourceAmount(sourceAmount, parseFloat(rate), parseFloat(feeRate))
        const targetAmount = roundDownToTwoDecimalPlaces(calc.targetAmount)
        return {
            targetAmount,
            netSourceAmount: calc.amountToExchange,
            feeTotal: calc.exchangeFee,
        }
    }
}
