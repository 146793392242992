import {Button} from '@design-system/button'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import transferActions from '~/store/transfer/actions'

interface TransferSharesViewInstrumentFooterProps {
    instrumentId: string
    instrumentSlug: string
}

const TransferSharesViewInstrumentFooter: React.FunctionComponent<TransferSharesViewInstrumentFooterProps> = ({
    instrumentId,
    instrumentSlug,
}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const stagedTransferOrder = useAppSelector(({transfer}) => transfer.stagedTransferOrder)
    const stagedTransferOrderInstruments = stagedTransferOrder!.instruments

    const addInstrumentToStagedTransferOrder = (instrumentId: string, instrumentSlug: string) => {
        dispatch(transferActions.AddInstrumentToStagedTransferOrder(instrumentId, instrumentSlug))
    }
    const removeInstrumentToStagedTransferOrder = (instrumentId: string) => {
        dispatch(transferActions.RemoveInstrumentFromStagedTransferOrder(instrumentId))
    }

    const canAddInstrument = !stagedTransferOrderInstruments.some(
        instrument => instrument.instrumentId === instrumentId,
    )
    // Check for DRS transfer as we only allow one instrument per transfer request
    const isDrsTransfer = stagedTransferOrder!.usTransferPlatform === 'DRS'

    const handleOnClick = () => {
        if (canAddInstrument) {
            addInstrumentToStagedTransferOrder(instrumentId, instrumentSlug)
        } else {
            removeInstrumentToStagedTransferOrder(instrumentId)
        }
        navigate(-1)
    }

    return (
        <ActionBar>
            <Button
                dataTestId={`button--${canAddInstrument ? 'add-to' : 'remove-from'}-transfer`}
                label={canAddInstrument ? 'Add to transfer' : 'Remove from transfer'}
                onClick={handleOnClick}
                disabled={canAddInstrument && isDrsTransfer && stagedTransferOrderInstruments.length > 0}
            />
        </ActionBar>
    )
}

export default TransferSharesViewInstrumentFooter
