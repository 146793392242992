import {ModalLink} from '@design-system/modal'
import React from 'react'
import {HelpContentBodyRenderer} from '~/global/widgets/help-content-renderer/HelpContentRenderer'
import {PricesOnMarketContent} from '~/sections/explore/pages/learn-static-content/LearnContent'
import {useUsLivePricing} from '~/sections/invest/sections/view-instrument/hooks/usLivePricing'
import {Instrument} from '~/store/instrument/types'

export const PricesCompanyEtf: React.FunctionComponent<{
    instrument: Instrument
    isClosePrice?: boolean
}> = ({instrument, isClosePrice}) => {
    const usLivePricing = !!useUsLivePricing(instrument)

    const regularPriceTitle = 'Prices for exchange-traded investments'
    const livePriceTitle = 'Live prices for exchange-traded investments'

    const title = isClosePrice
        ? regularPriceTitle // if this modal is attached to a close price, we don't want the "Live" title, even if they are using US live data
        : usLivePricing
          ? livePriceTitle
          : regularPriceTitle

    return (
        <ModalLink
            dataTestId="modal-link--prices-for-exchange-traded-investments"
            label={title}
            asIcon
            modalTitle={title}
            primaryButton={{label: 'Ok'}}
            bottomBorder
            helpIconSize={16}
            helpIconColour="IconSubtle"
        >
            <HelpContentBodyRenderer
                page={PricesOnMarketContent}
                instrument={instrument}
                /*
                 * usLivePricing: we want to pass this prop down here, rather than calling the
                 * useUsLivePricing() hook inside PricesOnMarketRender.
                 * PricesOnMarketRender is also used for the Learn Page and Intro Slides, which have no
                 * instrument in scope.
                 */
                usLivePricing={usLivePricing}
            />
        </ModalLink>
    )
}

export default PricesCompanyEtf
