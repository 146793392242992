/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface PortfolioList
 */
export interface PortfolioList {
    /**
     *
     * @type {Array<string>}
     * @memberof PortfolioList
     */
    portfolio_uuids: Array<string>
}

export function PortfolioListFromJSON(json: any): PortfolioList {
    return PortfolioListFromJSONTyped(json, false)
}

export function PortfolioListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioList {
    if (json === undefined || json === null) {
        return json
    }
    return {
        portfolio_uuids: json['portfolio_uuids'],
    }
}

export function PortfolioListToJSON(value?: PortfolioList | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        portfolio_uuids: value.portfolio_uuids,
    }
}
