import {useFeature} from '@growthbook/growthbook-react'
import React from 'react'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {scrollTo} from '~/global/utils/scroll-utils/scrollUtils'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {ExploreHeader, FonterraExploreHeader} from '~/sections/explore/ExploreHeader'
import {ExploreContentCards} from '~/sections/explore/widgets/content-cards/ExploreContentCards'
import {HelpfulLinks} from '~/sections/explore/widgets/helpful-links/HelpfulLinks'
import Watchlist from '~/sections/explore/widgets/watchlist/Watchlist'
import autoinvestActions from '~/store/autoinvest/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import LearnCard from './widgets/learn-card/LearnCard'
import PowerupPortfolio from './widgets/power-up/PowerupPortfolio'
import WhatsNext from './widgets/what-is-next/WhatsNext'
import styles from './Explore.scss'

interface ExploreProps {
    scrollToWatchlist?: boolean
}

/**
 * Internal Explore component, don't use this, use ExplorePage, this is exported for Showcase
 *
 * @param {ExploreProps} ExploreProps - `scrollToWatchlist, variation`
 * @returns {Explore} Inner explore page
 */
export const Explore: React.FunctionComponent<ExploreProps> = ({scrollToWatchlist}): JSX.Element => {
    const jurisdiction = useAppSelector(s => s.identity.user?.jurisdiction)
    const profile = useProfile()
    const isFonterraProduct = profile.portfolios.some(p => p.product === 'FONTERRA')

    if (isFonterraProduct && !profile.is_personal) {
        return <ExploreFonterra />
    }
    if (jurisdiction === 'nz') {
        return <ExploreNZ scrollToWatchlist={scrollToWatchlist} />
    } else {
        return <ExploreAU scrollToWatchlist={scrollToWatchlist} />
    }
}

export const ExploreNZ: React.FunctionComponent<ExploreProps> = ({scrollToWatchlist}): JSX.Element => {
    const dispatch = useAppDispatch()
    const hasLockedNavDrawer = useAppSelector(s => s.nav.drawerVisibilityLocked) // Removing left button of toolbar when flyout menu locked by the native apps (part of wheke-1713)
    useFeature('explore-top-ten-carousel-v2') // This will trigger a trackingCallback notifying RudderStack, then Braze of the Growthbook experiment which enables the getting started content cards to be served up

    const headingRef = React.useRef<HTMLHeadingElement>(null)

    React.useEffect(() => {
        if (headingRef && headingRef.current) {
            headingRef.current.focus()
        }

        dispatch(autoinvestActions.Initialise())

        if (scrollToWatchlist) {
            // if the investor's here to view watchlist,
            // then switch to the watchlist tab and scroll down to the watchlist section
            scrollTo(document.body.scrollHeight)
        }
    }, [])

    return (
        <>
            <Toolbar dataTestId="toolbar--explore-landing" leftButton={hasLockedNavDrawer ? undefined : 'menu'} />
            <Page className={styles.explorePage}>
                <ExploreHeader />
                <ExploreContentCards />
                <WhatsNext />
                {/* Don't show AutoInvest to NZ punters */}
                <PowerupPortfolio />
                <Watchlist tabName="Watchlist" />
                <LearnCard />
            </Page>
        </>
    )
}

export const ExploreAU: React.FunctionComponent<ExploreProps> = ({scrollToWatchlist}): JSX.Element => {
    const dispatch = useAppDispatch()
    const hasLockedNavDrawer = useAppSelector(s => s.nav.drawerVisibilityLocked) // Removing left button of toolbar when flyout menu locked by the native apps (part of wheke-1713)

    const headingRef = React.useRef<HTMLHeadingElement>(null)
    const hasSaveFlag = useAppSelector(s => s.identity.flags.savings)

    React.useEffect(() => {
        if (headingRef && headingRef.current) {
            headingRef.current.focus()
        }

        dispatch(autoinvestActions.Initialise())

        if (scrollToWatchlist) {
            // if the investor's here to view watchlist,
            // then switch to the watchlist tab and scroll down to the watchlist section
            scrollTo(document.body.scrollHeight)
        }
    }, [])
    return (
        <>
            <Toolbar dataTestId="toolbar--explore-landing" leftButton={hasLockedNavDrawer ? undefined : 'menu'} />
            <Page className={styles.explorePage}>
                <ExploreHeader />
                <ExploreContentCards />

                {hasSaveFlag && <WhatsNext />}
                {/* <WhatsNext /> */}
                <PowerupPortfolio />
                <Watchlist tabName="Watchlist" />
                <LearnCard />
            </Page>
        </>
    )
}

export const ExploreFonterra: React.FunctionComponent<{}> = (): JSX.Element => {
    const hasLockedNavDrawer = useAppSelector(s => s.nav.drawerVisibilityLocked) // Removing left button of toolbar when flyout menu locked by the native apps (part of wheke-1713)

    return (
        <>
            <Toolbar
                dataTestId="toolbar--explore-fonterra-landing"
                leftButton={hasLockedNavDrawer ? undefined : 'menu'}
            />
            <Page className={styles.explorePage}>
                <FonterraExploreHeader />
                <HelpfulLinks />
            </Page>
        </>
    )
}

/**
 * Core ExplorePage export
 *
 * @returns {ExplorePage} ExplorePage
 */
export const ExplorePage = ({scrollToWatchlist}: ExploreProps): JSX.Element => {
    return <Explore scrollToWatchlist={scrollToWatchlist} />
}

/**
 * Watchlist version for when someone goes to /explore/watchlist it will scroll to the Watchlist section
 *
 * @returns {ExplorePage} ExplorePage
 */
export const ExploreWatchlist = (): JSX.Element => {
    return <ExplorePage scrollToWatchlist />
}
