import React from 'react'
import {Model} from '~/api/retail/types'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'

const isVisible = () => document.visibilityState !== 'hidden'

const PendingOrdersRefetch: React.FunctionComponent = React.memo(() => {
    const dispatch = useAppDispatch()

    const isLoggedIn = useAppSelector(s => !!s.identity.user)
    const orders = useAppSelector(s => s.identity.orders)
    const pendingOrdersNextCheck = useAppSelector(s => s.identity.pendingOrdersNextCheck)
    const pendingOrdersCurrentTimeoutId = useAppSelector(s => s.identity.pendingOrdersCurrentTimeoutId)
    const userId = useAppSelector(s => s.identity.user?.id)

    const buyIdsToPoll = orders
        .filter<Model.BuyOrderCompact>((o): o is Model.BuyOrderCompact => o.type && o.type === 'buy')
        .map(o => o.id)
        .sort()

    const sellIdsToPoll = orders
        .filter<Model.SellOrderCompact>((o): o is Model.SellOrderCompact => o.type && o.type === 'sell')
        .map(o => o.id)
        .sort()

    const transferIdsToPoll = orders
        .filter<Model.TransferOrderCompact>(
            (o): o is Model.TransferOrderCompact => o.type && o.type === 'csn_transfer_order',
        )
        .map(o => o.id)
        .sort()

    const applicationIdsToPoll = orders
        .filter<Model.CorporateActionV2>(
            (o): o is Model.CorporateActionV2 => o.type && o.type === 'corporate_action_v2',
        )
        .map(o => o.id)
        .sort()

    React.useEffect(() => {
        // Clear the timeout when switching between users
        if (pendingOrdersCurrentTimeoutId) {
            clearInterval(pendingOrdersCurrentTimeoutId)
        }

        // Clear the timeout on component unmount
        return () => {
            if (pendingOrdersCurrentTimeoutId) {
                clearInterval(pendingOrdersCurrentTimeoutId)
            }
        }
    }, [userId])

    React.useEffect(() => {
        if (isLoggedIn && (buyIdsToPoll.length || sellIdsToPoll.length)) {
            // Clear out any old timer before we set the new one
            if (pendingOrdersCurrentTimeoutId) {
                clearInterval(pendingOrdersCurrentTimeoutId)
            }

            // Set an update to fetch user data again sooon
            const timeoutId = setInterval(() => {
                if (isVisible()) {
                    dispatch(
                        identityActions.RefreshPendingOrders(
                            buyIdsToPoll,
                            sellIdsToPoll,
                            transferIdsToPoll,
                            applicationIdsToPoll,
                        ),
                    )
                }
            }, pendingOrdersNextCheck)

            // Keeping track of this in the store to avoid a race condition
            dispatch(identityActions.SetPendingOrdersCurrentTimeoutId(timeoutId))
        }
    }, [
        isLoggedIn,
        pendingOrdersNextCheck,
        JSON.stringify([buyIdsToPoll, sellIdsToPoll, transferIdsToPoll, applicationIdsToPoll]),
    ])

    return null
})

export default PendingOrdersRefetch
