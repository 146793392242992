import Decimal from 'decimal.js'
import {Response} from '~/api/retail/types'
import {PaymentMethods, SubPlanV2} from '~/store/plan/types'

export const wouldPlanIncurNegativeBalance = (
    plan: SubPlanV2,
    walletBalance: string,
    defaultBillingCycle: 'MONTHLY' | 'ANNUAL',
    paymentMethod?: PaymentMethods,
    currentSubscription?: Response.SubscriptionCurrentV2,
) => {
    if (paymentMethod !== 'WALLET') {
        return false
    }

    const billingCycle = currentSubscription?.billing_cycle ?? defaultBillingCycle
    const newPlanBilling = plan.billing_options[billingCycle]

    if (!newPlanBilling || !newPlanBilling.would_start_immediately) {
        return false
    }

    const cost = newPlanBilling.price_on_change ? newPlanBilling.price_on_change : newPlanBilling.price_on_renewal

    // If the change would charge you money you don't have, we don't allow it.
    return new Decimal(walletBalance).lessThan(new Decimal(cost))
}
