import {semanticColour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import React from 'react'
import ContentLoader from 'react-content-loader'
import styles from './SkeletonInstrumentRow.scss'

interface RectDimensions {
    width: number | string
    height: number
    x?: number
    y?: number
}

interface RectData {
    image: RectDimensions
    title: RectDimensions
    ticker: RectDimensions
    price: RectDimensions
    priceChange: RectDimensions
}

const getRectData = (): RectData => {
    const commonLineHeight = 14

    return {
        image: {
            width: 48,
            height: 48,
        },
        title: {
            width: 200,
            height: commonLineHeight,
            x: 56,
        },
        ticker: {
            width: 78,
            height: commonLineHeight,
            x: 56,
            y: 24,
        },
        price: {
            width: 78,
            height: commonLineHeight,
            x: 56,
            y: 56,
        },
        priceChange: {
            width: 78,
            height: commonLineHeight,
            y: 48,
        },
    }
}

// IMPORTANT NOTE: the parent element must have height for this to work.
// height: 100% is buggy on Safari, so use flexbox in a column layout.
export const SkeletonInstrumentRow: React.FunctionComponent<SkeletonInstrumentRowProps> = ({measuredWidth}) => {
    const rectData = getRectData()
    const colourMode = useColourMode()
    return (
        <div className={styles.listing}>
            <ContentLoader
                backgroundColor={semanticColour('FillTertiary', colourMode)}
                foregroundColor={semanticColour('FillSecondary', colourMode)}
            >
                {/* Place holder image */}
                <rect width={rectData.image.width} height={rectData.image.height} rx="2" />
                {/* Place holder title */}
                <rect x={rectData.title.x} width={rectData.title.width} height={rectData.title.height} rx="2" />
                {/* Place holder ticker code and exchange */}
                <rect
                    x={rectData.ticker.x}
                    y={rectData.ticker.y}
                    width={rectData.ticker.width}
                    height={rectData.ticker.height}
                    rx="2"
                />
                {/* Place holder price */}
                <rect
                    x={rectData.price.x}
                    y={rectData.priceChange.y}
                    width={rectData.price.width}
                    height={rectData.price.height}
                    rx="2"
                />
                {/* Place holder price change: position x is measuredWidth - width of the element - 2 * inner paddings */}
                <rect
                    x={measuredWidth - 64}
                    y={rectData.priceChange.y}
                    width={rectData.priceChange.width}
                    height={rectData.priceChange.height}
                    rx="2"
                />
            </ContentLoader>
        </div>
    )
}

interface SkeletonInstrumentRowProps {
    measuredWidth: number
    withBorder?: boolean
}

export default SkeletonInstrumentRow
