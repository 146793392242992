import {semanticColour} from '@design-system/colour-tokens'
import {HorizontalGauge} from '@design-system/horizontal-gauge'
import {useColourMode} from '@design-system/use-colour-mode'
import Decimal from 'decimal.js'
import React from 'react'
import {Link} from 'react-router-dom'
import {useBankLinkingAlert, Context as LocationContext} from '~/global/state-hooks/retail/useBankLinkingAlert'
import {isError, isLoadingOrUninitialised, isUninitialised} from '~/global/utils/is-loading/isLoading'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import {BankLinkingAlert} from '~/sections/OLD_wallet/widgets/bank-linking-alert/BankLinkingAlert'
import actions from '~/store/bankLinking/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import styles from './RoundupStatus.scss'

export const RoundupStatus: React.FC<{
    withManageLink?: boolean
    locationContext: LocationContext
}> = ({withManageLink = false, locationContext}) => {
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const colourMode = useColourMode()

    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const {loadingState, isBankLinked, depositTarget, accumulatedDollars, isRoundupsEnabled} = useAppSelector(
        ({bankLinking}) => {
            let depositTarget: number | undefined
            let accumulatedDollars: number | undefined
            try {
                depositTarget = new Decimal(bankLinking.depositTarget).toNumber()
                accumulatedDollars = new Decimal(bankLinking.accumulatedDollars).toNumber()
            } catch {
                depositTarget = undefined
                accumulatedDollars = undefined
            }
            return {
                loadingState: bankLinking.bankLinkingLoadingState,
                isBankLinked: bankLinking.isBankLinked,
                isRoundupsEnabled: bankLinking.isRoundupsEnabled,
                accumulatedDollars,
                depositTarget,
            }
        },
    )
    const alert = useBankLinkingAlert({context: locationContext})
    const showTally = !alert || (alert && !alert.hideTally)

    React.useEffect(() => {
        if (jurisdiction === 'au' && isUninitialised(loadingState)) {
            dispatch(actions.FetchBankLinkingStatus())
        }
    }, [])

    if (
        jurisdiction !== 'au' ||
        !isBankLinked ||
        isLoadingOrUninitialised(loadingState) ||
        isError(loadingState) ||
        accumulatedDollars === undefined ||
        !depositTarget
    ) {
        return null
    }

    const tallyPercent = Math.min((accumulatedDollars / depositTarget) * 100, 100)

    if (!showTally) {
        return (
            <BankLinkingAlert
                locationContext={locationContext}
                hideTitle={locationContext === 'wallet-roundups'}
                wrapperClassName={styles.roundupStatusWrapper}
            />
        )
    }

    if (!isRoundupsEnabled) {
        return null
    }

    return (
        <div className={styles.roundupStatusWrapper}>
            <div className={styles.card}>
                {showTally ? (
                    <div className={styles.tally}>
                        <div className={styles.tallyHeading}>
                            <h2>Round-ups tally</h2>
                            {withManageLink ? <Link to={profileUrl('wallet/roundups')}>Manage</Link> : null}
                        </div>
                        <HorizontalGauge
                            dataTestId="topup-goal-progress-gauge"
                            startLabel={<DollarValue value={accumulatedDollars} />}
                            endLabel={<DollarValue value={depositTarget} />}
                            gaugeColour={semanticColour('SuccessAccent', colourMode)}
                            gaugeValue={tallyPercent}
                            labelPosition="below"
                        />
                    </div>
                ) : null}
                <BankLinkingAlert locationContext={locationContext} hideTitle wrapperClassName={styles.cardAlert} />
            </div>
        </div>
    )
}
