import {Button} from '@design-system/button'
import {Pause, Success} from '@design-system/icon'
import {useFeature} from '@growthbook/growthbook-react'
import cn from 'classnames'
import {DateTime} from 'luxon'
import React from 'react'
import {Navigate} from 'react-router'
import {useNavigate} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {dateFormatNoTime} from '~/global/utils/format-date/formatDate'
import {formatDollar} from '~/global/utils/format-dollar/formatDollar'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import AccountVerificationRequired from '~/global/widgets/account-verification-required/AccountVerificationRequired'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Feedback from '~/global/widgets/feedback/Feedback'
import InstrumentRow from '~/global/widgets/instrument-row/InstrumentRow'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import commonStyles from '~/sections/invest/sections/auto-invest/Common.scss'
import {useCurrentDIYOrder} from '~/sections/invest/sections/auto-invest/hooks/useAutoinvestOrders'
import {orderTypeDescs} from '~/sections/invest/sections/auto-invest/utils/map-auto-invest-images/diyOrderTypes'
import {
    DiyInstrumentUnavailableVerboseAlert,
    OrderFailedFullAlert,
} from '~/sections/invest/sections/auto-invest/widgets/auto-invest-alerts/AutoInvestAlerts'
import EditControl from '~/sections/invest/sections/auto-invest/widgets/edit-control/EditControl'
import AutoInvestHeader from '~/sections/invest/sections/auto-invest/widgets/header/AutoInvestHeader'
import autoinvestActions from '~/store/autoinvest/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import instrumentActions from '~/store/instrument/actions'
import styles from './DIY.scss'

const DIY: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const [currentDIYOrder, allLoaded] = useCurrentDIYOrder()
    const oneOffAutoinvestEnabled = useFeature('one-off-autoinvest').on

    const accountRestricted = useAppSelector(s => s.identity.user!.account_restricted)
    const instrumentsById = useAppSelector(s => s.instrument.instrumentsById)
    const jurisdiction = useAppSelector(s => s.identity.user!.jurisdiction)
    const instrumentLoadingState = useAppSelector(s => s.instrument.diyAILoadingState)
    const unavailableInstrumentNames = useAppSelector(s => s.instrument.diyAIUnavailableInstrumentNames)

    React.useEffect(() => {
        const ids = currentDIYOrder && currentDIYOrder.allocations.map(a => a.fund_id)

        dispatch(instrumentActions.loadDiyAIInstruments(ids))

        dispatch(instrumentActions.executeClearSearchAndFilters())
    }, [])

    if (instrumentLoadingState === 'loading' || !allLoaded) {
        return <Loading isPineapple />
    }

    if (!currentDIYOrder) {
        // If profile doesn't have a DIY order they get redirected to the landing screen
        return <Navigate to={profileUrl('auto-invest')} />
    }

    const {amount, displayInterval, next_date, order_name, last_failed_date, state, allocations} = currentDIYOrder

    const handleCurrentDIYRowClick = (instrumentSlug: string) => {
        navigate(
            profileUrl('auto-invest/diy/:instrumentSlug/view', {
                instrumentSlug,
            }),
        )
    }

    const onPageBack = () => {
        dispatch(autoinvestActions.ClearSelectedDiyOrderId())
        navigate(-1)
    }

    const orderTypeDesc =
        'diyInvestorType' in currentDIYOrder && currentDIYOrder.diyInvestorType
            ? orderTypeDescs[currentDIYOrder.diyInvestorType]
            : ''

    const defaultOrderName = {au: 'My auto-invest', nz: 'DIY'}[jurisdiction]

    const currentInvestInfo = `${formatDollar(amount, false)} every ${displayInterval}, next: ${DateTime.fromISO(
        next_date,
    ).toFormat(dateFormatNoTime)}`

    return (
        <>
            <Page withoutDefaultPadding>
                <AutoInvestHeader
                    order={currentDIYOrder}
                    name={order_name || defaultOrderName}
                    orderType="DIY"
                    onPageBack={onPageBack}
                    currentInvestInfo={currentInvestInfo}
                />
                <div className={commonStyles.pageContainer}>
                    {last_failed_date && <OrderFailedFullAlert order={currentDIYOrder} />}
                    <AccountVerificationRequired />
                    {unavailableInstrumentNames && unavailableInstrumentNames.length > 0 && (
                        <DiyInstrumentUnavailableVerboseAlert
                            instrumentNames={unavailableInstrumentNames}
                            className={spacing.spaceBelow24}
                            order={currentDIYOrder}
                        />
                    )}
                    {orderTypeDesc && <p className={spacing.spaceBelow32}>{orderTypeDesc}</p>}
                    <div className={commonStyles.fundsDIY}>
                        <div className={cn([spacing.spaceBelow12, commonStyles.subTitleandStatus])}>
                            <h2 className={styles.subHeader}>Your order</h2>
                            <div className={commonStyles.status}>
                                {state === 'paused' ? (
                                    <>
                                        <span>Paused</span>
                                        <div className={commonStyles.paused}>
                                            <Pause />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <span>Active</span>
                                        <div className={commonStyles.active}>
                                            <Success />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        {allocations.map(a => {
                            if (instrumentsById[a.fund_id]) {
                                return (
                                    <div className={commonStyles.instrumentRowWrapper} key={`diy-${a.fund_id}`}>
                                        <InstrumentRow
                                            instrument={instrumentsById[a.fund_id]}
                                            onClick={() => {
                                                handleCurrentDIYRowClick(a.instrumentSlug)
                                            }}
                                            allocation={a.allocation}
                                            hidePrice
                                            oldLayout
                                        />
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
            </Page>
            <Feedback category="Auto-invest" actionIdentifier="/diy" />
            {oneOffAutoinvestEnabled ? (
                <ActionBar>
                    <Button
                        additionalClassName={commonStyles.oneOffCta}
                        type="primary"
                        dataTestId="button--one-off-buy"
                        label="Place one-off buy"
                    />
                    <EditControl accountRestricted={accountRestricted} />
                </ActionBar>
            ) : (
                <ActionBar className={cn(commonStyles.footer, commonStyles.footerNoOverflow)}>
                    <div>{allocations.length} investments</div>
                    <EditControl accountRestricted={accountRestricted} />
                </ActionBar>
            )}
        </>
    )
}

export default DIY
