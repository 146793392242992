import {Button} from '@design-system/button'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {usePortfolioItemsById} from '~/global/state-hooks/mixed-source/usePortfolioItemsById'
import {useInstruments} from '~/global/utils/use-instruments/useInstruments'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {SwitchField} from '~/global/widgets/form-controls'
import InstrumentRowCompact from '~/global/widgets/instrument-row/InstrumentRowCompact'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/identity/actions'
import {Instrument} from '~/store/instrument/types'
import styles from './ShareholderUpdates.scss'

const DEFAULT_ROWS = 15

interface ShareholderUpdatesInstrumentRowsProps {
    instrumentIds: string[]
    instruments: {[id: string]: Instrument | null}
    instrumentExceptions: string[]
    subscriptionDefault: boolean
}

const ShareholderUpdatesInstrumentRows = ({
    instrumentIds,
    instruments,
    instrumentExceptions,
    subscriptionDefault,
}: ShareholderUpdatesInstrumentRowsProps) => {
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const [showAll, setShowAll] = React.useState(false)
    const [localExceptions, setLocalExceptions] = React.useState<string[]>(instrumentExceptions)

    // slice the instrument ids list
    const hasMoreInstruments = instrumentIds.length > DEFAULT_ROWS
    const slicedInstrumentIds = instrumentIds.slice(0, showAll ? undefined : DEFAULT_ROWS)

    const isInstrumentOn = (instrumentId: string) => {
        if (localExceptions.includes(instrumentId)) {
            return !subscriptionDefault
        }
        return subscriptionDefault
    }

    const handleUpdatePreference = async (instrument: Instrument, subscribed: boolean) => {
        // update the local exceptions so the user sees an immediate change
        const localExceptionsPreUpdate = [...localExceptions]
        if (subscriptionDefault === subscribed) {
            setLocalExceptions(localExceptions.filter(id => id !== instrument.id))
        } else {
            setLocalExceptions([...localExceptions, instrument.id])
        }

        const response = await dispatch(
            actions.UpdateNotificationFundPreference('shareholder_updates', instrument.id, subscribed),
        )
        if (response === 'error') {
            // revert the local exceptions if we failed to update
            setLocalExceptions(localExceptionsPreUpdate)
            Toast(`Failed to update your preference for ${instrument.symbol}:${instrument.exchange}`)
        }
    }

    return (
        <>
            {slicedInstrumentIds.map(id => {
                const instrument = instruments[id]!
                const isOn = isInstrumentOn(id)

                return (
                    <InstrumentRowCompact
                        key={id}
                        instrument={instrument}
                        rowLinkTarget={profileUrl('invest/:instrumentSlug', {
                            instrumentSlug: instrument.urlSlug,
                        })}
                        rightSlot={
                            <label>
                                <span className={styles.screenreaderOnly}>
                                    Toggle shareholder updates for {instrument.name}
                                </span>
                                <SwitchField
                                    dataTestId={`checkbox--shareholder-update-preferences-${id}`}
                                    name="shareholder-update-preferences"
                                    isTouched={true}
                                    value={isOn}
                                    onChange={() => {
                                        handleUpdatePreference(instrument, !isOn)
                                    }}
                                />
                            </label>
                        }
                    />
                )
            })}
            {!showAll && hasMoreInstruments && (
                <Button
                    dataTestId="button--shareholder-update-show-all"
                    label="Show all"
                    type="secondary"
                    additionalClassName={spacing.spaceAbove32}
                    onClick={() => setShowAll(true)}
                />
            )}
        </>
    )
}

const ShareholderUpdates = () => {
    const dispatch = useAppDispatch()
    // notification preferences
    const notificationPreferences = useAppSelector(s => s.identity.notificationPreferences)
    const exceptions = notificationPreferences.find(p => p.id === 'shareholder_updates')?.fund_exceptions
    const instrumentExceptions = exceptions?.fund_ids || []
    const subscriptionDefault = exceptions?.subscribed_default || false
    // instrument data
    const totalPortfolioItems = usePortfolioItemsById()
    const instrumentIds = Object.keys(totalPortfolioItems).filter(id => totalPortfolioItems[id].sharesOwned > 0)
    const [instruments, allInstrumentsLoaded] = useInstruments(instrumentIds)

    // sort and filter the instrument list
    const sortedInstrumentIds = instrumentIds
        .filter(id => instruments[id]?.exchange && ['NZX', 'ASX'].includes(instruments[id]!.exchange!))
        .sort((a, b) => (instruments[a]!.name > instruments[b]!.name ? 1 : -1))

    // in case of a refresh, fetch notification preferences
    React.useEffect(() => {
        if (notificationPreferences.length === 0) {
            dispatch(actions.GetNotificationPreferences())
        }
    }, [notificationPreferences])

    const isAnyLoading = !allInstrumentsLoaded || notificationPreferences.length === 0

    return (
        <>
            <Toolbar
                dataTestId="toolbar--shareholder-update-notification-settings"
                leftButton="back"
                title="Shareholder updates"
            />
            <Page>
                <p className={spacing.spaceBelow32}>
                    Choose which of your NZX and ASX investments to receive shareholder updates from.
                </p>
                {isAnyLoading ? (
                    <Loading />
                ) : (
                    <ShareholderUpdatesInstrumentRows
                        instrumentIds={sortedInstrumentIds}
                        instruments={instruments}
                        instrumentExceptions={instrumentExceptions}
                        subscriptionDefault={subscriptionDefault}
                    />
                )}
            </Page>
        </>
    )
}

export default () => (
    <React.Suspense fallback={<Loading />}>
        <ShareholderUpdates />
    </React.Suspense>
)
