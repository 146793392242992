import {Button} from '@design-system/button'
import {DockableModal} from '@design-system/dockable-modal'
import {ModalLink} from '@design-system/modal'
import cn from 'classnames'
import {useAtomValue, useSetAtom} from 'jotai'
import React from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import {logCustomEvent} from '~/api/braze/braze'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {isWrapperApp} from '~/global/utils/is-wrapper-app/isWrapperApp'
import {sendWrapperAppMessage} from '~/global/utils/send-wrapper-app-message/sendWrapperAppMessage'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import styles from '~/sections/protect/sections/insure/sections/car/pages/estimate/Estimate.scss'
import {EstimatePremiumRows} from '~/sections/protect/sections/insure/sections/car/pages/estimate/EstimatePremiumRows'
import commonStyles from '~/sections/protect/sections/insure/sections/landing/utils/common-styles/commonStyles.scss'
import {coveCreateEstimateResponseAtom, createEstimateDetailsAtom} from '~/sections/protect/sections/insure/state'
import {PolicyQuoteCommonRows} from '~/sections/protect/sections/insure/widgets/policy-quote-common-rows/PolicyQuoteCommonRows'
import SharesiesCoveTermsAndConditions from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/SharesiesCoveTermsAndConditions'

export const Estimate: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const coveCreateEstimateResponse = useAtomValue(coveCreateEstimateResponseAtom)
    const setCreateEstimateDetails = useSetAtom(createEstimateDetailsAtom)

    const [exitModalVisible, setExitModalVisible] = React.useState<boolean>(false)

    const handleContinue = () => {
        rudderTrack('insurance_signup', 'quote_continue_clicked', {source: 'product_introduction'})
        setCreateEstimateDetails(undefined)
        navigate(profileUrl('protect/insure/car/cove-customise-quote'))
        logCustomEvent('insurance_quote_created', {
            product: 'car',
            quote_id: coveCreateEstimateResponse?.quote_id,
        })
    }

    const trackCloseClicked = (label: string) =>
        rudderTrack('insurance_signup', 'close_clicked', {progress: 'Estimate page', label})

    const handleBack = () => {
        trackCloseClicked('Back')
        navigate(profileUrl('protect/insure/car/details'))
    }

    const handleExit = () => {
        trackCloseClicked('Exit')
        setCreateEstimateDetails(undefined)
        if (isWrapperApp()) {
            sendWrapperAppMessage({type: 'goBack'})
            navigate(profileUrl(''), {replace: true})
        } else {
            navigate(profileUrl('protect/insure'), {replace: true})
        }
    }

    if (!coveCreateEstimateResponse) {
        return <Navigate to={profileUrl('protect/insure')} />
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--estimate" leftButton="back" onLeftButtonClick={handleBack} />

            <Page>
                <h1 className={cn(styles.heading, spacing.spaceBelow24)}>
                    Here’s your estimate for comprehensive cover
                </h1>
                <div
                    className={cn(commonStyles.card, commonStyles.isQuote)}
                    data-testid={`container--cove-estimate-${coveCreateEstimateResponse.quote_id}`}
                >
                    <div className={commonStyles.cardMainContent}>
                        <h3 className={cn(spacing.spaceBelow16, commonStyles.cardTitle)}>
                            {coveCreateEstimateResponse.vehicle_description}, {coveCreateEstimateResponse.registration}
                        </h3>
                        <p className={spacing.spaceBelow16}>
                            You can adjust these details when you continue to customise your estimate.
                        </p>
                        {coveCreateEstimateResponse && (
                            <>
                                <PolicyQuoteCommonRows
                                    carValue={coveCreateEstimateResponse.agreed_value.toString()}
                                    excess={coveCreateEstimateResponse.excess.toString()}
                                />
                                <EstimatePremiumRows
                                    isAnnual={false}
                                    value={coveCreateEstimateResponse.monthly_premium}
                                />
                                <EstimatePremiumRows
                                    isAnnual={true}
                                    value={coveCreateEstimateResponse.yearly_premium}
                                />
                                <p className={cn(styles.withBorder, commonStyles.cardSubtitle, spacing.spaceBelow12)}>
                                    Multi policy discount may also be applicable.
                                </p>
                                <div className={commonStyles.cardSubtitle}>
                                    Discount{' '}
                                    <ModalLink
                                        dataTestId="modal-link--sharesies-cove-terms-and-conditions"
                                        label="terms and conditions"
                                        modalTitle="Sharesies and Cove Promotional Terms & Conditions"
                                        bottomBorder
                                    >
                                        <SharesiesCoveTermsAndConditions />
                                    </ModalLink>{' '}
                                    apply.
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <p className={cn(styles.smallText, spacing.spaceAbove16, spacing.spaceBelow40)}>
                    To generate your estimate, we’ve assumed excess, agreed car value, no at fault claims in the last 5
                    years, no car finance, 3-5 days a week car usage, and we have used a placeholder gender. You’ll be
                    able to adjust these as well.
                </p>

                <Button
                    dataTestId="button--customise-estimate"
                    additionalClassName={spacing.spaceBelow16}
                    label="Customise your estimate"
                    onClick={handleContinue}
                />
                <Button
                    dataTestId="button--exit"
                    label="Exit"
                    type="secondary"
                    onClick={() => setExitModalVisible(true)}
                />
            </Page>

            <DockableModal
                dataTestId="dockable-modal--insure-estimate"
                isOpen={exitModalVisible}
                setIsOpen={setExitModalVisible}
                title="All done with your estimate"
                content={
                    <>
                        <p className={spacing.spaceBelow12}>
                            A heads-up that your estimate won’t be saved if you leave now.
                        </p>
                        <p>
                            To save your estimate for later, continue to get your quote—you’ll then be able to pause and
                            pick it back up when you like.
                        </p>
                        <Button
                            dataTestId="button--insure-estimate-exit"
                            additionalClassName={spacing.spaceAbove16}
                            label="I’m done with my estimate"
                            onClick={() => {
                                setExitModalVisible(false)
                                handleExit()
                            }}
                        />
                        <Button
                            dataTestId="button--insure-estimate-dismiss"
                            additionalClassName={spacing.spaceAbove16}
                            label="Go back"
                            type="secondary"
                            onClick={() => setExitModalVisible(false)}
                        />
                    </>
                }
            />
        </>
    )
}
