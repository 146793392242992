import {Close, Logout} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {FocusOn} from 'react-focus-on'
import {useNavigate} from 'react-router'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {VIEWPORT_WIDTHS} from '~/global/constants/global'
import {accessibility} from '~/global/scss/helpers'
import {useProfileOwner} from '~/global/state-hooks/retail/useProfileOwner'
import {isAnyWalletBalanceNegative} from '~/global/utils/calculate-wallet-balance/calculateWalletBalance'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import SharesiesLogo from '~/global/widgets/sharesies-logo/SharesiesLogo'
import {ProfileSwitcher} from '~/global/wrappers/global-wrapper-widgets/flyout-menu/ProfileSwitcher'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import navActions from '~/store/nav/actions'
import ListFlyOutMenu from './ListFlyOutMenu'
import styles from './FlyoutMenu.scss'

interface Props {
    className?: string
}

export const FlyoutMenu: React.FunctionComponent<Props> = ({className}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()
    const owner = useProfileOwner()

    const user = useAppSelector(s => s.identity.user!)
    const flyoutVisible = useAppSelector(s => s.nav.flyoutVisible)
    const drawerOpen = useAppSelector(s => s.nav.drawerOpen)

    const negativeWallet = isAnyWalletBalanceNegative(user.wallet_balances) // TODO Shared Money - needs to use the right wallet portfolio

    const [switcherVisible, setSwitcherVisible] = React.useState<boolean>(false)
    const [viewportIsDesktop, setViewportIsDesktop] = React.useState<boolean>(false)
    const [navigationIsVisible, setNavigationIsVisible] = React.useState<boolean>(false)

    const closeFlyout = () => dispatch(navActions.CloseDrawer())
    const onLogout = () => dispatch(identityActions.Logout())

    const handleResize = () => {
        setViewportIsDesktop(window.innerWidth >= VIEWPORT_WIDTHS.DESKTOP)
        setNavigationIsVisible(drawerOpen || viewportIsDesktop)
    }

    const onClose = () => {
        closeFlyout()
        const closestH1: HTMLHeadingElement | null = document.querySelector('#globalWrapperPage h1')
        if (closestH1) {
            // Added this timeout here to allow React to catch up by one frame.
            // Without the timeout, the closestH1 won't be focused.
            setTimeout(() => {
                closestH1.focus()
            }, 0)
        }
    }

    React.useEffect(() => {
        handleResize()

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [viewportIsDesktop, drawerOpen])

    React.useEffect(() => {
        const handleEsc = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                onClose()
            }
        }
        window.addEventListener('keyup', handleEsc)

        return () => {
            window.removeEventListener('keyup', handleEsc)
        }
    }, [])

    if (!flyoutVisible) {
        return null
    }

    return (
        <>
            <div
                id="navigation"
                className={cn(className, styles.flyoutWrapper, {[styles.open]: drawerOpen})}
                aria-hidden={!drawerOpen && !viewportIsDesktop}
            >
                <div className={cn(styles.flyout)}>
                    <div className={styles.cover}>
                        <FocusOn enabled={drawerOpen && !viewportIsDesktop && !switcherVisible}>
                            <div onClick={() => navigate(profileUrl(''))} className={styles.logo}>
                                <SharesiesLogo />
                            </div>
                            <button
                                id="flyoutMenuCloseButton"
                                type="button"
                                className={cn(styles.close, accessibility.button)}
                                onClick={onClose}
                                aria-label="Close menu"
                                tabIndex={navigationIsVisible ? 0 : -1}
                            >
                                <Close />
                            </button>
                            <nav>
                                <ul className={styles.list}>
                                    <li className={styles.listItem}>
                                        <ProfileSwitcher
                                            switcherVisible={switcherVisible}
                                            setSwitcherVisible={setSwitcherVisible}
                                            closeFlyout={closeFlyout}
                                            navigationIsVisible={navigationIsVisible}
                                        />
                                    </li>
                                    <li className={styles.listItem}>
                                        <ListFlyOutMenu
                                            drawerOpen={navigationIsVisible}
                                            label="Portfolio"
                                            to={profileUrl('')}
                                            closeFlyout={closeFlyout}
                                            activePathMatch={[
                                                '',
                                                'invest',
                                                'save',
                                                'employee-share-scheme',
                                                'auto-invest',
                                                'kiwisaver',
                                                'notifications',
                                            ]}
                                        />
                                    </li>
                                    <li className={styles.listItem}>
                                        <ListFlyOutMenu
                                            drawerOpen={navigationIsVisible}
                                            label="Explore"
                                            to={profileUrl('explore')}
                                            closeFlyout={closeFlyout}
                                            activePathMatch={['explore', 'buy', 'sell', 'recent-orders']}
                                        />
                                    </li>
                                    <li className={styles.listItem}>
                                        <ListFlyOutMenu
                                            onClick={() =>
                                                rudderTrack('topup', 'wallet_navigated', {linked_from: 'nav_panel'})
                                            }
                                            drawerOpen={navigationIsVisible}
                                            label="Wallet"
                                            alert={negativeWallet}
                                            to={profileUrl('wallet')}
                                            closeFlyout={closeFlyout}
                                            activePathMatch={['wallet']}
                                        />
                                    </li>
                                    {owner.owner_type === 'PERSON' && !user.is_dependent && (
                                        <li className={styles.listItem}>
                                            <ListFlyOutMenu
                                                drawerOpen={navigationIsVisible}
                                                label={
                                                    user.jurisdiction === 'nz' ? 'Share the aroha' : 'Share the love'
                                                }
                                                to={profileUrl('share-the-love')}
                                                closeFlyout={closeFlyout}
                                                activePathMatch={['gifts', 'share-the-love', 'referrals']}
                                            />
                                        </li>
                                    )}
                                    <li className={styles.listItem}>
                                        <ListFlyOutMenu
                                            drawerOpen={navigationIsVisible}
                                            label="Settings"
                                            to={profileUrl('settings')}
                                            closeFlyout={closeFlyout}
                                            activePathMatch={['settings', 'legal', 'close-account']}
                                        />
                                    </li>
                                    <li
                                        data-testid="flyout-menu--log-out"
                                        className={cn([styles.listItem, styles.logout])}
                                    >
                                        <button
                                            type="button"
                                            className={accessibility.button}
                                            onClick={e => {
                                                e!.preventDefault()
                                                onLogout()
                                                onClose()
                                            }}
                                        >
                                            <Logout />
                                            Logout
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </FocusOn>
                    </div>
                </div>
            </div>
            <div className={styles.flyoutPageOverlay} onClick={closeFlyout} />
        </>
    )
}

export default FlyoutMenu
