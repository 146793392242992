/*
    IMPORTANT: DO NOT MODIFY THIS FILE MANUALLY

    This file is generated using sd (sharesies devtools) command:

    sd data fetch-taxonomy --format typescript --platform all_apps

    The taxonomy is ultimately maintained by the data team in this spreadsheet:

    https://docs.google.com/spreadsheets/d/1xqgjlOGReClWrFzzRYH0TGIqV1gBJAMF30nQ0SPmN-0/edit#gid=1792027988

    If you would like to update the spreadsheet please contact the data team. If you would like to update this
    file to reflect the current contents of the spreadsheet, please check out the sd commandline tool and use
    the above command
*/

export interface EventDataTaxonomy {
    activity_feed: {
        activity_feed_show_more_selected: {}
        activity_feed_tab_selected: {}
    }
    ai_search: {
        result_clicked: {
            has_filter: boolean
            instrument_id: string
            type: 'asx' | 'no-filter' | 'nzx' | 'us-listed'
        }
        result_watchlisted: {
            has_filter: boolean
            instrument_id: string
            type: 'asx' | 'no-filter' | 'nzx' | 'us-listed'
        }
    }
    announcement_splash_notification: {
        button_clicked: {
            label: string
            target: string
        }
    }
    articles: {
        action: {
            label: string
            url: string
        }
    }
    autoinvest: {
        autoinvest_amount_set: {
            amount: number
            interval: '1week' | '2week' | '4week' | '1month'
        }
        autoinvest_confirmed: {
            autoinvest_type: 'responsible' | 'kids' | 'global' | 'diy' | 'au-responsible' | 'au-global'
        }
        autoinvest_landing_page_hit: {
            navigated_from:
                | 'autoinvest-landing-page'
                | 'account-menu'
                | 'invest-carousel'
                | 'activity-feed'
                | 'autoinvest-diy-promo-card'
        }
        autoinvest_switch: {}
        autoinvest_type_selected: {
            autoinvest_type: 'responsible' | 'kids' | 'global' | 'diy' | 'au-responsible' | 'au-global'
        }
        diy_autoinvest_searched: {
            search_query: string
        }
        diy_percentage_set: {
            navigated_from: 'autoinvest-diy-promo-card'
        }
    }
    'bank-link': {
        'bank-account-link-completed': {}
        'bank-account-link-started': {
            navigated_from: 'account'
        }
    }
    browse: {
        search_completed: {
            has_filter: boolean
            has_sort: boolean
            query: string
            result_count: number
        }
        search_page_hit: {
            navigated_from: 'autoinvest-diy-promo-card' | 'autoinvest-diy-create-card' | 'invest-carousel-diy'
        }
        search_result_clicked: {
            instrument_id: string
            search_rank: number
        }
    }
    buy: {
        order_confirmed: {
            dividend_reinvest: boolean
            instrument_id: string
        }
        order_details_entered: {
            instrument_id: string
        }
        order_payment_method_selected: {
            payment_method: 'wallet' | 'card'
        }
        order_started: {}
        order_type_selected: {
            order_type: 'limit' | 'market'
        }
    }
    ess_allocation: {
        all_allocations_clicked: {
            allocation_type: 'vested' | 'allocated'
        }
        all_allocations_viewed: {
            allocation_type: 'vested' | 'allocated'
        }
        allocation_clicked: {
            allocation_type: 'vested' | 'allocated'
        }
        allocation_viewed: {
            allocation_type: 'vested' | 'allocated'
        }
    }
    ess_investment: {
        ess_investment_details: {
            visibility: 'show' | 'hide'
        }
    }
    ess_scheme: {
        scheme_page_viewed: {}
        scheme_pdf_clicked: {}
        scheme_view_clicked: {
            referrer: ''
        }
    }
    exchange_money: {
        page_view: {}
    }
    explore: {
        carousel_clicked: {
            tile_clicked: 'invest' | 'save' | 'kiwisaver' | 'kids_account'
        }
        getting_started_content_clicked: {
            type: 'learn_article' | 'intro_video' | 'top_up'
        }
        watchlist_navigated: {}
        watchlist_show_selected: {
            type: 'change' | 'set'
        }
        whats_next_clicked: {
            type: 'invest' | 'save' | 'kiwisaver' | 'kids' | 'insure'
        }
    }
    feature_onboarding_pill: {
        button_clicked: {
            label: string
            target: string
        }
        dismissed: {}
        notification_closed: {
            type: string
        }
        notification_opened: {
            type: string
        }
    }
    fonterra_engagement: {
        content_card_clicked: {
            type: string
        }
        save_account_clicked: {}
    }
    fonterra_onboarding: {
        existing_sharesies_account_continue_clicked: {
            label: 'yes' | 'no' | 'automatic_email_link'
        }
        get_started_clicked: {}
        prescribed_person_clicked: {
            label: 'yes' | 'no' | 'multiple'
        }
        somethings_not_right_clicked: {
            type: string
        }
        terms_and_conditions_agreed_next_clicked: {}
    }
    growthbook: {
        experiment: {
            experimentKey: string
            resultInExperiment: boolean
            resultValue: string
            resultVariationId: number
        }
    }
    info_modal: {
        button_clicked: {
            label: string
            type: string
        }
        dismissed: {
            type: string
        }
        modal_viewed: {
            type: string
        }
    }
    instrument: {
        tab_selected: {
            tab_name: 'overview' | 'your_investment'
        }
    }
    insurance_signup: {
        close_clicked: {
            label: string
            progress: string
        }
        confirm_details_edit_clicked: {}
        generate_quote_clicked: {
            source: 'product_introduction' | 'landing'
        }
        insurance_introduction_started: {}
        payment_completed: {}
        quote_continue_clicked: {
            source: 'product_introduction' | 'landing'
        }
        quote_started: {}
    }
    intercom: {
        messenger_closed: {}
        messenger_opened: {}
    }
    kiwisaver_account: {
        account_detailed_breakdown_clicked: {
            timeframe: 'all_time'
        }
        edit_link_clicked: {
            fund_category: 'base_fund' | 'self_select_fund'
        }
        edit_plan_clicked: {}
        holding_card_clicked: {
            fund_category: 'base_fund' | 'self_select_fund'
            instrument_id: string
        }
        sell_button_clicked: {
            fund_category: 'base_fund' | 'self_select_fund'
            instrument_id: string
        }
    }
    kiwisaver_self_select: {
        education_dismissed: {
            type: 'signup' | 'edit'
        }
        end_education_completed: {
            type: 'signup' | 'edit'
        }
        risk_indicator_next_clicked: {
            type: 'signup' | 'edit'
        }
        self_select_funds_added: {
            has_filter: boolean
            has_sort: boolean
            search_query: string
            type: 'signup' | 'edit'
        }
        self_select_percentages_added: {
            type: 'signup' | 'edit'
        }
        start_education_viewed: {
            type: 'signup' | 'edit'
        }
    }
    kiwisaver_signup: {
        fund_details_selected: {
            instrument_id: string
            type: 'signup' | 'edit'
        }
        fund_selection_clicked: {
            instrument_id: string
            type: 'signup' | 'edit'
        }
        fund_type_clicked: {
            type: 'CONSERVATIVE' | 'BALANCED' | 'GROWTH' | 'AGGRESSIVE'
        }
        goals_values_clicked: {
            type: 'HOUSE_DEPOSIT' | 'RETIREMENT'
        }
        investment_plan_selected: {
            type: 'signup' | 'edit'
        }
        join_scheme_confirmed: {}
        pds_acceptance: {
            type: 'signup' | 'edit'
        }
        self_select_started: {
            is_using_marketing_site_plan: boolean
        }
        signup_landing_viewed: {
            navigated_from: 'portfolio' | 'notification' | 'explore' | 'link'
        }
        start_signup_clicked: {
            is_investment_plan_tool_linked: boolean
        }
        tax_details_confirmed: {}
    }
    kiwisaver_waitlist: {
        register_interest_clicked: {
            screen_name: 'in_app_carousel' | 'onboarding_screen'
        }
        waitlist_tile_clicked: {}
    }
    login: {
        app_login: {}
    }
    money_goal: {
        update_my_autoinvest_clicked: {}
    }
    navigation: {
        feature_management_button_clicked: {
            label: string
            url: string
        }
        invest_portfolio_get_started_link_clicked: {
            feature: 'autoinvest' | 'explore' | 'transfer'
        }
        level_up_list_clicked: {
            feature: string
            url: string
        }
        link_clicked: {
            component:
                | 'header'
                | 'footer'
                | 'announcement_bar'
                | 'card'
                | 'hero'
                | 'copy'
                | 'feature_showcase'
                | 'curated_category'
                | 'search_bar'
                | 'login_page'
            feature: 'kiwisaver' | 'auto_invest' | 'kids_accounts' | 'gifts' | 'download_mobile_app'
            label: string
            url: string
        }
        new_product_button_clicked: {
            feature: 'invest' | 'save' | 'kiwisaver' | 'kids' | 'insure'
            type: 'add_new' | 'get_started'
        }
        toggle_clicked: {
            label: string
            page_path: string
            status: boolean
        }
    }
    notification_preferences: {
        change_preference: {
            channel: 'email' | 'notification'
            notification_preference: string
            source: 'notification_settings_page' | 'notifications_centre'
            updated_subscription_state: 'subscribed' | 'unsubscribed'
        }
    }
    notifications: {
        bell_clicked: {
            badged: boolean
        }
        notification_category_unsubscribed: {
            notification_category: string
        }
        notification_link_clicked: {
            instrument_id: string
            link: string
            reinvest_amount: string
        }
        notification_removed: {
            instrument_id: string
            notification_id: string
        }
        notification_viewed: {
            instrument_id: string
            notification_category: string
        }
    }
    plans: {
        plan_confirmation_abandoned: {
            abandoned_plan_choice: string
        }
        plan_confirmed: {}
        plan_page_hit: {
            link: 'account' | 'invest'
        }
        plan_selected: {
            plan_type: string
        }
    }
    price_change_notification: {
        notification_deleted: {
            instrument_id: string
            page_source: 'instrument_page' | 'watchlist' | 'notification_page'
        }
        notification_modal_opened: {
            instrument_id: string
            link_clicked: 'edit' | 'set'
            page_source: 'instrument_page' | 'watchlist' | 'notification_page'
        }
        notification_set: {
            instrument_id: string
            notify_when: 'above' | 'below'
            page_source: 'instrument_page' | 'watchlist' | 'notification_page'
            price_threshold: number
            viewed_instrument_price: number
        }
    }
    'round-up': {
        'account-tracked-selected': {}
        'account-withdraw-from-selected': {}
        'bank-link-clicked': {}
        error_message_clicked: {
            error_type: 'no-debit-bank-account' | 'no-monitoring-bank-accounts' | 'expiry-due' | 'expiry-past-due'
            navigated_from: 'wallet' | 'round-up-overview'
        }
        'round-up-intro-clicked': {
            'button-clicked': 'get-started' | 'how-it-works'
            navigated_from: 'invest-tile' | 'bank-linking'
        }
        'round-up-managed': {
            navigated_from: 'wallet-tally' | 'invest-tile'
            'selection-managed': 'account-send-from' | 'account-tracked' | 'whole-dollar' | 'notification'
        }
        'round-up-setup-completed': {
            notification_category: string
            'whole-dollar-round-ups': boolean
        }
    }
    save: {
        close_save_clicked: {}
        close_save_confirm_clicked: {}
        onboarding_confirm_clicked: {
            account_type: 'adult' | 'dependent'
        }
        onboarding_intro_clicked: {}
        transfer_deposit_clicked: {}
        transfer_deposit_confirmed: {
            current_save_total: string
            transfer_amount: string
        }
        transfer_withdraw_clicked: {}
        transfer_withdraw_confirmed: {
            current_save_total: string
            transfer_amount: string
        }
    }
    sell: {
        order_confirmed: {
            instrument_id: string
        }
        order_details_entered: {
            instrument_id: string
        }
        order_started: {}
        order_type_selected: {
            order_type: 'limit' | 'market'
        }
    }
    sharesies_peeled: {
        close_clicked: {
            progress: string
        }
        next_step_button_clicked: {
            label: string
            url: string
        }
    }
    signup: {
        address_details_entered: {}
        address_verification_completed: {}
        address_verification_failed: {}
        address_verification_sent: {}
        address_verification_skipped: {}
        details_entered: {}
        location_selected: {
            jurisdiction: string
        }
        nandp_answered: {}
        nzx_participant_answered: {}
        prescribed_person_answered: {}
        primary_id_confirmed: {}
        primary_id_entered: {
            identity_type:
                | 'NZ driver licence'
                | 'NZ passport'
                | 'NZ birth certificate'
                | 'AU driver licence'
                | 'AU passport'
        }
        tax_residency_added: {}
        us_shares_citizenship_clicked: {}
        us_shares_completed: {}
        us_shares_signup_started: {
            source: 'signup' | 'buy_flow' | 'currency_exchange' | 'explore'
        }
        welcome_page_hit: {}
    }
    topup: {
        bank_account_copied: {}
        bank_link_cancel_clicked: {
            url: string
        }
        bank_reference_copied: {}
        continue_to_my_bank_clicked: {
            amount: number
            bank_selected: string
        }
        credit_card_confirmed: {}
        instant_bank_topped_up: {}
        redeem_gift_selected: {}
        select_bank_clicked: {}
        topup_selected: {}
        type_selected: {
            type: 'bank_transfer' | 'card' | 'instant_bank' | 'linked_bank' | 'pay_id'
        }
        wallet_navigated: {
            linked_from: 'portfolio_card' | 'nav_panel' | 'save_transfer' | 'landing' | 'explore' | 'tab_bar'
        }
    }
    voting: {
        application_form_displayed: {
            label: string
        }
        company_update_vote_link_clicked: {}
        vote_now_link_clicked: {
            ca_application_rule_id: string
            referrer: 'email' | 'available_application_card' | 'instrument_alert_card'
        }
    }
}

export const EventCategoryVersion: Record<keyof EventDataTaxonomy, number> = {
    signup: 1,
    autoinvest: 1,
    browse: 1,
    buy: 1,
    sell: 1,
    topup: 1,
    kiwisaver_waitlist: 1,
    navigation: 1,
    articles: 1,
    intercom: 1,
    growthbook: 1,
    login: 1,
    activity_feed: 1,
    voting: 1,
    exchange_money: 1,
    ess_scheme: 1,
    ess_allocation: 1,
    ess_investment: 1,
    notifications: 1,
    plans: 1,
    instrument: 1,
    explore: 1,
    save: 1,
    price_change_notification: 1,
    announcement_splash_notification: 1,
    kiwisaver_signup: 1,
    feature_onboarding_pill: 1,
    'round-up': 1,
    'bank-link': 1,
    notification_preferences: 1,
    info_modal: 1,
    kiwisaver_self_select: 1,
    sharesies_peeled: 1,
    money_goal: 1,
    kiwisaver_account: 1,
    insurance_signup: 1,
    ai_search: 1,
    fonterra_onboarding: 1,
    fonterra_engagement: 1,
}
