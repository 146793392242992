import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {CommonIdFormProps} from '~/sections/user/sections/sign-up/sections/identity/sections/identity-check/IdentityFormCommon'

export const OverseasPassport = ({preferredName}: CommonIdFormProps) => (
    <div>
        <h2 className={spacing.spaceBelow16}>Details on {preferredName}’s overseas passport</h2>
        <p>
            If {preferredName} has an overseas passport, we can still verify {preferredName}—it’s just a little more
            manual. Find out how in our{' '}
            <HelpCentreLink
                auArticle="5135944-id-you-need-to-sign-up-to-sharesies-australia"
                nzArticle="1738992-what-id-do-i-need-to-sign-up-to-sharesies-new-zealand"
            />
            .
        </p>
    </div>
)
