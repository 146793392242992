import {ChevronRight} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import styles from './ListMenuExpanded.scss'

interface ListMenuExpandedProps {
    onClick: () => void
    dataTestId: string
    icon: React.ReactNode
    title: string
    description: string
    state?: string
    additionalClassName?: string
    displayChevron?: boolean
}

export const ListMenuExpanded: React.FC<ListMenuExpandedProps> = ({
    onClick,
    dataTestId,
    icon,
    title,
    description,
    state,
    additionalClassName,
    displayChevron = true,
}) => {
    return (
        <ButtonAsLink
            noTextDecoration={true}
            className={cn(styles.listMenuExpanded, additionalClassName)}
            onClick={onClick}
            dataTestId={dataTestId}
        >
            <>
                {icon}
                <div className={styles.optionText}>
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
                <div className={styles.optionStateAndChevron}>
                    <span>{state}</span>
                    {displayChevron && <ChevronRight />}
                </div>
            </>
        </ButtonAsLink>
    )
}
