import React from 'react'
import {Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {usePortfolioOrdersInfiniteQuery} from '~/sections/kiwisaver/data/retail/portfolioOrder'
import {useKSCashActivityInfiniteQuery} from '~/sections/kiwisaver/state'
import CashActivityCard from '~/sections/kiwisaver/widgets/activity-card/CashActivityCard'
import InvestingActivityCard from '~/sections/kiwisaver/widgets/activity-card/InvestingActivityCard'

export const RecentActivities: React.FunctionComponent<{}> = () => {
    const cashActivityResponse = useKSCashActivityInfiniteQuery({limit: 3})
    const portfolioOrders = usePortfolioOrdersInfiniteQuery({limit: 3})

    let portfolioOrdersActivity: Response.PortfolioOrders['orders'] = []
    portfolioOrders.data?.pages.forEach(page => {
        portfolioOrdersActivity = portfolioOrdersActivity.concat(...page.orders)
    })

    let cashActivity: Response.KSCashMovementActivity['activity'] = []
    cashActivityResponse.data?.pages.forEach(page => {
        cashActivity = cashActivity.concat(...page.activity)
    })

    const recentActivities = [...cashActivity, ...portfolioOrdersActivity]
        .sort((a, b) => b.date.toMillis() - a.date.toMillis())
        .slice(0, 3)

    return (
        <>
            <h2 data-testid="heading--recent-activity" className={spacing.spaceBelow16}>
                Recent activity
            </h2>
            {recentActivities.map(activity =>
                activity.type === 'cash_movement' ? (
                    <CashActivityCard activity={activity} key={activity.id} />
                ) : (
                    <InvestingActivityCard activity={activity} key={activity.id} />
                ),
            )}
        </>
    )
}
