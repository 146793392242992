import React from 'react'
import {Model} from '~/api/retail/types'
import {dateFormatWithYear} from '~/global/utils/format-date/formatDate'
import recordStyles from '~/global/widgets/instrument-activity/Record.scss'
import {RecordRow} from '~/global/widgets/instrument-activity/common'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import PageBack from '~/global/widgets/page-back-or-close/PageBack'
import starshipSignature from '~/sections/OLD_wallet/widgets/donation-line/assets/images/starship-signature.png'
import starship from '~/sections/OLD_wallet/widgets/donation-line/assets/images/starship.png'

interface DonationLineDetailsProps {
    transaction: Model.Transaction
}

const DonationLineView: React.FunctionComponent<DonationLineDetailsProps> = ({transaction}) => {
    // Donations need to be displayed as positive numbers, unlike the usual display of negative numbers for transactions that debit
    // a customer's wallet
    const transactionAmount = -transaction.amount
    const donation = transaction.donation!

    // Hardcode since we have one charity; could use donation.charity_id to differentiate
    const charityLogo = <img src={starship} />
    const signature = <img src={starshipSignature} />

    return (
        <>
            <div className={recordStyles.header}>
                <PageBack />
            </div>
            <Page withoutDefaultPadding>
                <div className={recordStyles.viewContainer} data-testid={`donation-${donation.receipt_number}`}>
                    <div className={recordStyles.titleBlock}>
                        <h2>{transaction.description}</h2>
                    </div>
                    <div className={recordStyles.orderContent}>
                        <div className={recordStyles.donationHeader}>
                            {charityLogo}
                            <h2>{donation.charity_name}</h2>
                        </div>
                        <p className={recordStyles.donationMessage}>{donation.message}</p>
                        <div className={recordStyles.recordRowContainer}>
                            <RecordRow left="Receipt number" right={donation.receipt_number} />
                            <RecordRow left="Date" right={transaction.timestamp.toFormat(dateFormatWithYear)} />
                            <RecordRow left="From" right={donation.customer_full_name} />
                        </div>

                        <p className={recordStyles.orderAmountLabel}>{transaction.fx_order ? 'To amount' : 'Amount'}</p>

                        <div className={recordStyles.orderFooter}>
                            <div />
                            <div>
                                <p className={recordStyles.orderAmount}>
                                    <DollarValue value={transactionAmount} currency={transaction.currency} />
                                </p>
                            </div>
                        </div>
                        <div className={recordStyles.donationFooter}>
                            <p>{donation.ceo_name}</p>
                            <p>Chief Executive</p>
                            <p>{donation.charity_signature_name}</p>
                            {signature}
                            <p>
                                Registered charity: {donation.registered_charity_number} IRD: {donation.ird_number}.
                                <br />A donation tax credit can be claimed for donations of $5 or more.
                            </p>
                        </div>
                    </div>
                </div>
            </Page>
        </>
    )
}

export default DonationLineView
