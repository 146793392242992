import {Button} from '@design-system/button'
import {useSetAtom} from 'jotai'
import React from 'react'
import {useNavigate} from 'react-router'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {MoosePortfolioPathParams} from '~/sections/moose/routes'
import {stagedTransferAtom} from '~/sections/moose/sections/transfer/state'

export const Transfer = ({portfolioId}: MoosePortfolioPathParams) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const setStagedTransfer = useSetAtom(stagedTransferAtom)

    const onNext = () => {
        setStagedTransfer({initiatingPortfolioId: portfolioId})
        navigate(profileUrl('fonterra/transfer/select'))
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--transfer-intro" leftButton="back" title="Transfer shares" />
            <Page overrideDefaultTopPadding="none">
                <p>
                    You can transfer shares or units between farm supply numbers (and their respective CSNs) owned by
                    the same business entity.
                </p>
                <p className={spacing.spaceAbove24}>
                    Note, you need to enter your FIN for the farm supply number (or CSN) you are transferring shares
                    from.
                </p>
                <AlertCard className={spacing.spaceAbove24} type="info">
                    Transfers are usually processed within one business day.
                </AlertCard>
            </Page>
            <ActionBar>
                <Button label="Next" type="primary" dataTestId="button--next" onClick={onNext} />
            </ActionBar>
        </>
    )
}
