import {ClassicCard} from '@braze/web-sdk'
import {DateTime} from 'luxon'
import React from 'react'
import {dateFormatNoDay} from '~/global/utils/format-date/formatDate'
import {
    showCalendarMonthHeading,
    showThisMonthHeading,
    showThisWeekHeading,
} from '~/global/utils/time-unit-headings/timeUnitHeadings'
import {isExploreContentCard} from '~/sections/explore/widgets/content-cards/common'
import NotificationClassicCard from '~/sections/user/sections/notifications/widgets/notification-classic-clard/NotificationClassicCard'
import styles from './NotificationsList.scss'

interface NotificationsListProps {
    cards: ClassicCard[]
    setCardAndShowModal: (card: ClassicCard) => void
}

const NotificationsList: React.FunctionComponent<NotificationsListProps> = ({cards, setCardAndShowModal}) => {
    const currentTimestamp = DateTime.local()
    let previousCardUpdated = DateTime.local()
    let monthTitleDisplayed = false

    return (
        <>
            {cards.map((card, index) => {
                const cardUpdated = card.updated ? DateTime.fromJSDate(card.updated) : null

                if (!cardUpdated) {
                    return null
                }

                // Ignore any content cards targeting the Explore page
                if (isExploreContentCard(card.extras?.category)) {
                    return null
                }

                // 'Today' heading
                if (index === 0 && cardUpdated.toISODate() === currentTimestamp.toISODate()) {
                    return (
                        <NotificationClassicCard
                            index={index}
                            key={`notification-card--${index}}`}
                            card={card as ClassicCard}
                            setCardAndShowModal={setCardAndShowModal}
                            periodClass={styles.todayHeading}
                            testId="notification--today"
                        />
                    )
                }

                // 'This week' heading
                if (showThisWeekHeading(cardUpdated, previousCardUpdated)) {
                    previousCardUpdated = cardUpdated
                    return (
                        <NotificationClassicCard
                            index={index}
                            key={`notification-card--${index}}`}
                            card={card as ClassicCard}
                            setCardAndShowModal={setCardAndShowModal}
                            periodClass={styles.thisWeekHeading}
                            testId="notification--this-week"
                        />
                    )
                }

                // 'This month' heading
                if (showThisMonthHeading(cardUpdated, previousCardUpdated, monthTitleDisplayed)) {
                    monthTitleDisplayed = true
                    previousCardUpdated = cardUpdated
                    return (
                        <NotificationClassicCard
                            index={index}
                            key={`notification-card--${index}}`}
                            card={card as ClassicCard}
                            setCardAndShowModal={setCardAndShowModal}
                            periodClass={styles.thisMonthHeading}
                            testId="notification--this-month"
                        />
                    )
                }

                // Month headings (e.g. 'July 2022')
                if (showCalendarMonthHeading(cardUpdated, previousCardUpdated)) {
                    monthTitleDisplayed = true
                    previousCardUpdated = cardUpdated
                    return (
                        <NotificationClassicCard
                            index={index}
                            key={`notification-card--${index}}`}
                            card={card as ClassicCard}
                            setCardAndShowModal={setCardAndShowModal}
                            periodClass={styles.monthHeading}
                            monthLabel={cardUpdated.toFormat(dateFormatNoDay)}
                            testId={`notification--${cardUpdated.toFormat('MMM-yyyy').toLowerCase()}`}
                        />
                    )
                }

                previousCardUpdated = cardUpdated

                return (
                    <NotificationClassicCard
                        index={index}
                        key={`notification-card--${index}}`}
                        card={card as ClassicCard}
                        setCardAndShowModal={setCardAndShowModal}
                        testId={`notification-card--${index + 1}`}
                    />
                )
            })}
        </>
    )
}

export default NotificationsList
