import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import {useAppSelector} from '~/store/hooks'

interface USThanksProps {
    groupId: string
}

export const USThanks: React.FunctionComponent<USThanksProps> = ({groupId}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const groupedTransfers = useAppSelector(({transfer}) => transfer.groupedTransfers)
    const currentGroupTransfer = groupedTransfers.find(group => group.group_id === groupId)

    if (!groupId) {
        navigate(profileUrl('invest/portfolio-transfer-shares'))
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--us-transfer-thanks" leftButton="back" />
            <Page>
                <h1 className={cn(commonStyles.heading, spacing.spaceBelow24)}>You did it! Here’s what’s next</h1>
                <>
                    {currentGroupTransfer?.direction === 'out' && currentGroupTransfer?.reference_type !== 'DRS' && (
                        <p className={spacing.spaceBelow16}>
                            If you haven’t already, contact the platform you're transferring shares to about your
                            transfer. You can direct them to{' '}
                            <a href="mailto:transfers@sharesies.co.nz">transfers@sharesies.co.nz</a> if they have any
                            questions.
                        </p>
                    )}

                    {currentGroupTransfer?.direction === 'in' &&
                        currentGroupTransfer?.us_transfer_broker === 'EXTERNAL' && (
                            <p className={spacing.spaceBelow16}>
                                If you haven’t already, contact the platform that holds your shares to inform them of
                                your transfer. You can direct them to{' '}
                                <a href="mailto:transfers@sharesies.co.nz">transfers@sharesies.co.nz</a> if they have
                                any questions.
                            </p>
                        )}
                    <p></p>

                    <p className={spacing.spaceBelow16}>
                        We’ll look over the info you’ve provided. If all looks good, the shares should take 5-10
                        business days to{' '}
                        {currentGroupTransfer?.direction === 'out' ? 'be transferred.' : 'land in your Portfolio.'}
                    </p>
                    <p className={spacing.spaceBelow16}>We’ll reach out via email if we need anything else.</p>
                    <p className={spacing.spaceBelow16}>
                        You can check on the transfer’s progress at any time by going to Portfolio &gt; Manage &gt;
                        Transfer shares.
                    </p>
                </>
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--back-to-invest-transfer"
                    label="Back to Transfers"
                    onClick={() => navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})}
                />
            </ActionBar>
        </>
    )
}

export default USThanks
