import cn from 'classnames'
import React from 'react'
import {ListingResponseDto} from '~/api/distill'
import {DistillScope} from '~/api/query/distill'
import {Model} from '~/api/retail/types'
import {UNLISTED_INSTRUMENTS_LABEL} from '~/global/constants/categoryAndSearchLabels'
import {spacing} from '~/global/scss/helpers'
import useDistillInstrumentInfo from '~/global/state-hooks/distill/useDistillInstrumentInfo'
import {useDistillPriceHistory} from '~/global/state-hooks/distill/useDistillPriceHistory'
import {Period} from '~/global/utils/time-period/timePeriod'
import Chips from '~/global/widgets/chips/Chips'
import {HelpCentreLink} from '~/global/widgets/help-centre-link/HelpCentreLink'
import {InstrumentRiskDescription, RiskScale} from '~/global/widgets/risk-scale/RiskScale'
import Description from '~/global/widgets/view-instrument-shared/overview/description/Description'
import EtfHoldings from '~/global/widgets/view-instrument-shared/overview/etf-holdings/EtfHoldings'
import PriceHistory from '~/global/widgets/view-instrument-shared/overview/price-history/PriceHistory'
import Stats from '~/global/widgets/view-instrument-shared/overview/stats/Stats'
import PriceHistorySummary from '~/sections/invest/sections/view-instrument/sections/overview/widgets/price-history-summary/PriceHistorySummary'
import layoutStyles from '~/sections/kiwisaver/sections/view-fund/ViewFund.scss'
import {ComparisonPrices, InstrumentDetailsTab} from '~/store/instrument/types'
import styles from './OverviewTab.scss'

export const OverviewTab: React.FunctionComponent<{
    instrument: ListingResponseDto
    jurisdiction: Model.User['jurisdiction']
    period: Period
    setPeriod: (period: Period) => void
    setHideFooter?: (isHidden: boolean) => void
    tab: InstrumentDetailsTab
}> = ({instrument, jurisdiction, period, setPeriod, setHideFooter, tab}) => {
    const GetCategoryLabels = (instrument: ListingResponseDto): string[] => {
        const typesExchangesAndCategories: string[] = []
        const distillMetadata = useDistillInstrumentInfo(DistillScope.KIWISAVER_ALL_FUNDS, {
            searchFundInvestments: true,
        })

        const type = distillMetadata.instrumentTypes.filter(type => type.id === instrument.instrumentType)
        if (type.length) {
            // check the length before we set this, in case instrumentTypes is not yet populated
            typesExchangesAndCategories.push(type[0].name)
        }
        if (instrument.exchange) {
            typesExchangesAndCategories.push(instrument.exchange)
        } else {
            typesExchangesAndCategories.push(UNLISTED_INSTRUMENTS_LABEL)
        }
        typesExchangesAndCategories.push(...instrument.categories)
        return typesExchangesAndCategories
    }

    const priceHistory = useDistillPriceHistory(instrument.id)
    const comparisonPrice = instrument.comparisonPrices[`_${period}` as keyof ComparisonPrices]
    const distillMetadata = useDistillInstrumentInfo(DistillScope.KIWISAVER_ALL_FUNDS, {searchFundInvestments: true})
    const exchange = distillMetadata.exchanges.filter(x => x.name === instrument.exchange)[0]
    const categories = GetCategoryLabels(instrument)

    return (
        <section
            id="overview"
            style={{display: tab === 'Overview' ? 'block' : 'none'}}
            role="tabpanel"
            aria-labelledby="overview-tab"
        >
            {' '}
            <Description instrument={instrument} />
            {categories.length > 0 && <Chips options={categories} />}
            <EtfHoldings instrument={instrument} />
            <h2 className={cn(layoutStyles.sectionHeader, spacing.spaceAbove32)}>Prices</h2>
            <PriceHistory
                period={period}
                setPeriod={setPeriod}
                instrument={instrument}
                exchange={exchange}
                priceHistory={priceHistory}
                priceAlertMessage="As the Sharesies KiwiSaver Scheme is new there is limited historical data. To provide you with relevant information we are displaying the price history of the underlying investment."
            />
            <PriceHistorySummary period={period} comparisonPrices={comparisonPrice} />
            <h2 className={cn(layoutStyles.sectionHeader, spacing.spaceAbove32)}>Stats</h2>
            <Stats
                instrument={instrument}
                showExpandedDividends={false}
                jurisdiction={jurisdiction}
                setHideFooter={setHideFooter}
            />
            {instrument.isAdr && (
                <div className={styles.adrDisclaimer}>
                    American depositary receipts (ADRs) represent the shares of a company based outside of the US. ADRs
                    trade on US exchanges like other investments, but have different fees and taxes. For more info,
                    check out our{' '}
                    <HelpCentreLink
                        auArticle="5410557-american-depositary-receipts-adrs"
                        nzArticle="5410563-american-depositary-receipts-adrs"
                    />
                    .
                </div>
            )}
            <div className={styles.riskLevel}>
                <h2
                    className={cn(layoutStyles.sectionHeader, spacing.spaceAbove32)}
                    aria-label={`Risk level: This investment has a risk level of ${instrument.riskRating}`}
                >
                    Risk level
                </h2>

                <RiskScale rating={instrument.riskRating} />
                <InstrumentRiskDescription rating={instrument.riskRating} />

                {/*Required to meet our Design & Distribution Obligations (DDO)*/}
                {jurisdiction === 'au' && instrument.instrumentType === 'etf' && instrument.exchange === 'ASX' && (
                    <p className={spacing.spaceAbove16}>
                        For more information on this exchange-traded fund (ETF), its factsheet and its Target Market
                        Determination (TMD), visit the ETF provider’s website.
                    </p>
                )}
            </div>
            <hr className={styles.divider} />
            <p className={styles.dataDisclaimer}>
                Data displayed is from the NZX, LSEG, fund providers, and XE. It’s indicative only and its accuracy or
                completeness is not guaranteed.
            </p>
        </section>
    )
}
