import {Button} from '@design-system/button'
import {InfoCircle, CheckSingle, RecurringTopUp, RoundUps} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {Response} from '~/api/retail/types'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {convertToKebabCase} from '~/global/utils/convert-to-kebab-case/convertToKebabCase'
import {useCurrentSubscriptionPlan, useAllSubscriptionPlans} from '~/global/utils/subscription-hooks/subscriptionHooks'
import {subscriptionNameParts} from '~/global/utils/subscription-name/subscriptionName'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {wouldPlanIncurNegativeBalance} from '~/global/utils/would-plan-incur-negative-balance/wouldPlanIncurNegativeBalance'
import {Loading} from '~/global/widgets/loading/Loading'
import {DollarValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {Link, Navigate, useNavigate} from '~/migrate-react-router'
import UsLivePricingModal from '~/sections/user/sections/settings/sections/plans/pages/select/UsLivePricingModal'
import {FreeForStaffFlag} from '~/sections/user/sections/settings/sections/plans/widgets/free-for-staff-flag/FreeForStaffFlag'
import PlanMarketDepthModal from '~/sections/user/sections/settings/sections/plans/widgets/modals/PlanMarketDepthModal'
import PlanRecurringPaymentsModal from '~/sections/user/sections/settings/sections/plans/widgets/modals/PlanRecurringPaymentsModal'
import PlanRoundupsModal from '~/sections/user/sections/settings/sections/plans/widgets/modals/PlanRoundupsModal'
import AccountPlanTopUpModal from '~/sections/user/sections/settings/sections/plans/widgets/modals/PlanTopUpModal'
import {useAppSelector} from '~/store/hooks'
import {selectHasStaffBenefits} from '~/store/identity/selectors'
import {SubPlanV2} from '~/store/plan/types'
import styles from './PlanSelect.scss'

type ModalContent =
    | 'top-up'
    | 'market-depth'
    | 'pay-as-you-go'
    | 'round-ups'
    | 'recurring-payments'
    | 'us-live-pricing'
    | null

export const PlanInclusions: React.FunctionComponent<{
    hasMarketDepth: boolean
    hasUsLivePricing: boolean
    includesFreeRoundups: boolean
    includesRecurringPayments: boolean
    jurisdiction: 'au' | 'nz'
}> = ({hasMarketDepth, hasUsLivePricing, includesFreeRoundups, includesRecurringPayments, jurisdiction}) => {
    const [modalContent, setModalContent] = React.useState<ModalContent>(null)

    return (
        <>
            {jurisdiction === 'au' && (
                <>
                    <div className={cn([styles.horizontalRule, spacing.spaceBelow20, spacing.spaceAbove20])} />
                    <h3 className={cn([styles.subHeader, spacing.spaceBelow8])}>Transfer fees covered on:</h3>
                    {includesFreeRoundups && (
                        <div className={cn(styles.planInclusionLine)}>
                            <div className={styles.planInclusionName}>
                                <RoundUps />
                                <span>Round-ups</span>
                            </div>
                            <div
                                style={{cursor: 'pointer'}}
                                role="button"
                                onClick={() => setModalContent('round-ups')}
                                className={styles.planInclusionHelp}
                            >
                                <InfoCircle />
                            </div>
                        </div>
                    )}
                    {includesRecurringPayments && (
                        <div className={cn(styles.planInclusionLine)}>
                            <div className={styles.planInclusionName}>
                                <RecurringTopUp />
                                <span>Linked bank top-ups</span>
                            </div>
                            <div
                                style={{cursor: 'pointer'}}
                                role="button"
                                onClick={() => setModalContent('recurring-payments')}
                                className={styles.planInclusionHelp}
                            >
                                <InfoCircle />
                            </div>
                        </div>
                    )}
                    <PlanRecurringPaymentsModal
                        isOpen={modalContent === 'recurring-payments'}
                        setIsOpen={isOpen => (isOpen ? setModalContent('recurring-payments') : setModalContent(null))}
                    />
                    <PlanRoundupsModal
                        isOpen={modalContent === 'round-ups'}
                        setIsOpen={isOpen => (isOpen ? setModalContent('round-ups') : setModalContent(null))}
                    />
                </>
            )}

            {(hasMarketDepth || hasUsLivePricing) && (
                <>
                    <div className={cn([styles.horizontalRule, spacing.spaceBelow20, spacing.spaceAbove20])}></div>
                    <h3 className={cn([styles.subHeader, spacing.spaceBelow8])}>Also includes:</h3>
                </>
            )}

            {hasMarketDepth && (
                <>
                    <div className={cn(styles.planInclusionLine)}>
                        <div className={styles.planInclusionName}>
                            <CheckSingle />
                            <span>NZX market depth</span>
                        </div>
                        <div
                            style={{cursor: 'pointer'}}
                            role="button"
                            onClick={() => setModalContent('market-depth')}
                            className={styles.planInclusionHelp}
                        >
                            <InfoCircle />
                        </div>
                    </div>
                    <PlanMarketDepthModal
                        isOpen={modalContent === 'market-depth'}
                        setIsOpen={isOpen => (isOpen ? setModalContent('market-depth') : setModalContent(null))}
                    />
                </>
            )}

            {hasUsLivePricing && (
                <>
                    <div className={cn(styles.planInclusionLine)}>
                        <div className={styles.planInclusionName}>
                            <CheckSingle />
                            <span>US live pricing</span>
                        </div>
                        <div
                            style={{cursor: 'pointer'}}
                            role="button"
                            onClick={() => setModalContent('us-live-pricing')}
                            className={styles.planInclusionHelp}
                        >
                            <InfoCircle />
                        </div>
                    </div>
                    <UsLivePricingModal
                        isOpen={modalContent === 'us-live-pricing'}
                        setIsOpen={isOpen => (isOpen ? setModalContent('us-live-pricing') : setModalContent(null))}
                    />
                </>
            )}
        </>
    )
}

interface PlanCardProps {
    plan: SubPlanV2
    isDependent?: boolean
    currentSubscription?: Response.SubscriptionCurrentV2
    jurisdiction: 'au' | 'nz'
}

const PlanCard: React.FunctionComponent<PlanCardProps> = ({plan, isDependent, currentSubscription, jurisdiction}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const [modalContent, setModalContent] = React.useState<ModalContent>(null)

    const hasAnnualPlansFlag = useAppSelector(store => store.identity.flags.annual_plans)
    const hasStaffBenefits = useAppSelector(selectHasStaffBenefits)

    const {
        currency,
        free_autoinvest_amount,
        free_trade_amount,
        has_market_depth,
        includes_free_roundups,
        includes_recurring_payments,
        includes_us_live_data,
        name,
    } = plan
    const walletBalance = useAppSelector(store => store.identity.user!.wallet_balances[currency])
    const [nameLeader, nameRemainder] = subscriptionNameParts(name)

    return (
        <>
            <div className={styles.planCard}>
                <div className={styles.headerBox}>
                    {hasStaffBenefits && (
                        <div className={spacing.spaceBelow8}>
                            <FreeForStaffFlag testIdSuffix={convertToKebabCase(name)} />
                        </div>
                    )}
                    <span className={styles.headerBoxMainContent}>
                        {nameLeader && <div className={styles.headerDollars}>{nameLeader}</div>}
                        <h2>{nameRemainder} plan</h2>
                    </span>
                </div>
                <div className={styles.content}>
                    <h3 className={cn([styles.subHeader, spacing.spaceBelow8])}>
                        {isDependent ? 'Transaction fees on the' : 'Transaction fees covered on your'} first:
                    </h3>
                    <ul className={cn(spacing.spaceAbove8)}>
                        <li>
                            <DollarValue value={free_trade_amount} currency={currency} decimalPlaces={0} /> of buy and
                            sell orders
                        </li>
                        <li>
                            <DollarValue value={free_autoinvest_amount} currency={currency} decimalPlaces={0} /> of
                            auto-invest orders
                        </li>
                    </ul>
                    <PlanInclusions
                        jurisdiction={jurisdiction}
                        includesFreeRoundups={includes_free_roundups}
                        hasMarketDepth={has_market_depth}
                        includesRecurringPayments={includes_recurring_payments}
                        hasUsLivePricing={includes_us_live_data}
                    />
                    {hasAnnualPlansFlag && (
                        <>
                            <div className={cn([styles.horizontalRule, spacing.spaceBelow20, spacing.spaceAbove20])} />
                            Plan coverage renews monthly.
                        </>
                    )}
                    <Button
                        type="secondary"
                        additionalClassName={spacing.spaceAbove24}
                        label="Select"
                        dataTestId={`button--select-${plan.id}`}
                        onClick={() => {
                            rudderTrack('plans', 'plan_selected', {plan_type: plan.id as any})

                            if (hasStaffBenefits) {
                                navigate(profileUrl('settings/plan/confirm/:plan', {plan: plan.id}))
                            } else if (
                                // check price of cheaper billing option (we don't yet know what they’ll select for a new plan)
                                wouldPlanIncurNegativeBalance(
                                    plan,
                                    walletBalance,
                                    'MONTHLY',
                                    currentSubscription?.payment_method,
                                    currentSubscription,
                                )
                            ) {
                                setModalContent('top-up')
                            } else if (!currentSubscription && hasAnnualPlansFlag) {
                                navigate(
                                    profileUrl('settings/plan/billing-frequency/:plan', {
                                        plan: plan.id,
                                    }),
                                )
                            } else {
                                navigate(
                                    profileUrl('settings/plan/confirm/:plan', {
                                        plan: plan.id,
                                    }),
                                )
                            }
                        }}
                    />
                </div>
            </div>
            <AccountPlanTopUpModal
                isOpen={modalContent === 'top-up'}
                setIsOpen={isOpen => (isOpen ? setModalContent('top-up') : setModalContent(null))}
                subscriptionPlan={plan}
            />
        </>
    )
}

const PlanSelect: React.FunctionComponent<{}> = () => {
    const profileUrl = useProfileUrl()
    const [currentSubscription, currentSubscriptionLoaded] = useCurrentSubscriptionPlan()
    const allPlans = useAllSubscriptionPlans({includeUnselectablePlans: false})
    const hasStaffBenefits = useAppSelector(selectHasStaffBenefits)
    const {isDependent, preferredName, jurisdiction} = useAppSelector(s => ({
        isDependent: s.identity.user?.is_dependent,
        jurisdiction: s.identity.user!.jurisdiction,
        preferredName: s.identity.user!.preferred_name,
    }))

    if (!currentSubscriptionLoaded || !allPlans) {
        return <Loading isPineapple />
    }

    // You cannot change your plan if it's currently cancelled
    if (currentSubscription && !currentSubscription.auto_renew) {
        return <Navigate to={profileUrl('settings/plan')} replace />
    }

    const title = () => {
        if (currentSubscription) {
            return 'Change your plan'
        }

        if (isDependent) {
            return 'Kids plan'
        }

        return 'Choose a plan'
    }

    // Ensure the plans are ordered by price
    allPlans?.sort(
        (a, b) =>
            Number(a.billing_options.MONTHLY.price_on_renewal) - Number(b.billing_options.MONTHLY.price_on_renewal),
    )

    const yourOrTheir = isDependent ? 'their' : 'your'
    const yourOrKidsName = isDependent ? `${preferredName}’s` : 'your'

    const AUCoverageInfo = (
        <>For orders (or parts of an order) over {yourOrKidsName} coverage, pay as you go pricing applies.</>
    )

    const NZCoverageInfo = (
        <>
            For orders (or parts of an order) over {yourOrKidsName} coverage, the 1.9% transaction fee (and per order
            fee caps) apply.
        </>
    )

    return (
        <>
            <Toolbar dataTestId="toolbar--subscription-select" leftButton="back" title={title()} />
            <Page overrideDefaultTopPadding="none" className={cn(spacing.spaceAbove4, styles.pageText)}>
                <div className={spacing.spaceBelow12}>
                    {currentSubscription ? (
                        <>
                            <p className={spacing.spaceBelow20}>
                                You can change or cancel {yourOrTheir} plan at any time.
                            </p>
                            <p className={spacing.spaceBelow20}>
                                Downgrading or cancelling {yourOrTheir} plan takes effect at the end of your current
                                payment period. Upgrading {yourOrTheir} plan takes effect immediately
                                {!hasStaffBenefits && '—you’ll just need to pay the difference in plan fees'}.
                            </p>
                        </>
                    ) : (
                        <>
                            <p className={spacing.spaceBelow20}>
                                On a plan, transaction fees are covered up to a set dollar amount of individual buy and
                                sell orders, and auto-invest orders.
                            </p>
                            <p className={spacing.spaceBelow20}>
                                {jurisdiction === 'au' ? AUCoverageInfo : NZCoverageInfo}
                            </p>

                            {/* Monthly plans are free for those with staff benefits - hide payment information */}
                            {!hasStaffBenefits && (
                                <p className={spacing.spaceBelow20}>
                                    You can change or cancel {yourOrTheir} plan at any time. Downgrading or cancelling{' '}
                                    {yourOrTheir} plan takes effect at the end of {yourOrTheir} current payment period.
                                    Upgrading {yourOrTheir} plan takes effect immediately—you’ll just need to pay the
                                    difference in plan fees.
                                </p>
                            )}
                        </>
                    )}
                    <p>
                        <Link
                            to={
                                jurisdiction === 'nz'
                                    ? 'https://sharesies.nz/pricing'
                                    : 'https://sharesies.com.au/pricing'
                            }
                            target="_blank"
                            rel="noreferrer"
                        >
                            Learn more about fees and plans
                        </Link>
                    </p>
                </div>
                {/* CONTENT REVIEW */}

                {allPlans.map(plan => (
                    <PlanCard
                        plan={plan}
                        key={plan.id}
                        isDependent={isDependent}
                        currentSubscription={currentSubscription}
                        jurisdiction={jurisdiction}
                    />
                ))}
            </Page>
        </>
    )
}

export default PlanSelect
