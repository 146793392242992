import cn from 'classnames'
import Decimal from 'decimal.js'
import React from 'react'
import {AnyCurrency, Currency} from '~/global/utils/currency-details/currencyDetails'
import {
    formatNumber,
    formatNumberWithCurrency,
    formatNumberWithoutTrailingZeros,
    formatNumberWithThousandsSeparator,
    formatScaledNumberWithCurrency,
} from '~/global/utils/format-number/formatNumber'
import styles from './NumberElements.scss'

/*
Use NumberElements component when we display numbers
Use functions when we calculate numbers
*/

interface DollarValueProps {
    value: string | number
    explicitPlus?: boolean
    color?: boolean
    roundDown?: boolean
    sayLessThanOneCent?: boolean
    sayNumericWords?: boolean
    decimalPlaces?: number
    currency?: string
}

/**
 * Formatter for non-monetary values. For example, formatting the number of employees a company has.
 */
export const ItemValue = ({value}: {value: number | string}) => {
    const parsedValue = formatNumber({number: value.toString(), roundDown: false, decimalPlaces: 0})
    return <span>{parsedValue}</span>
}

export const DollarValue = ({
    value,
    explicitPlus,
    color,
    roundDown,
    decimalPlaces,
    currency,
    sayNumericWords = true,
    sayLessThanOneCent = false,
}: DollarValueProps) => {
    const v = formatNumber({number: value.toString(), roundDown, decimalPlaces, useNumericWords: sayNumericWords})

    if (sayLessThanOneCent && Number(value) > 0 && Number(value) < 0.01) {
        return (
            <span className={cn(styles.dollarValue, {[styles.colour]: color})}>
                {formatNumberWithCurrency('less than 1¢', currency)}
            </span>
        )
    }

    if (v === '0.00' || v === '-0.00') {
        return (
            <span className={cn(styles.dollarValue, styles.zero, {[styles.colour]: color})}>
                {formatNumberWithCurrency('$0.00', currency)}
            </span>
        )
    }

    if (+value < 0) {
        return (
            <span className={cn(styles.dollarValue, styles.negative, {[styles.colour]: color})}>
                {formatNumberWithCurrency(`-$${v.substring(1)}`, currency)}
            </span>
        )
    }

    return (
        <span className={cn(styles.dollarValue, styles.positive, {[styles.colour]: color})}>
            {formatNumberWithCurrency(`${explicitPlus ? '+' : ''}$${v}`, currency)}
        </span>
    )
}

/**
 * Use this component to display a wallet value, but not an estimated wallet
 * balance. To display estimated wallet balance, use EstimatedWalletBalance
 * component. Wallet value should always be rounded down to two decimal places.
 */
export const WalletValue = ({
    value,
    currency,
    className,
}: {
    value: string | number
    currency?: string
    className?: string
}) => (
    <span className={className}>
        <DollarValue roundDown value={value} currency={currency} decimalPlaces={2} />
    </span>
)

/**
 * Dividend value should always be rounded half up to 6 decimal places.
 *
 * It displays a precise value (capped at 6dp, rounding half up). Optionally, fixedDecimalPlaces can be set add trailing
 * zeros to 6dp.
 */
export const DividendValue = ({
    value,
    currency,
    fixedDecimalPlaces,
}: {
    value: string
    currency: AnyCurrency
    fixedDecimalPlaces?: boolean
}) => {
    return (
        <>
            {formatScaledNumberWithCurrency({
                value,
                currency,
                maximumDecimalPlaces: 6,
                minimumDecimalPlaces: fixedDecimalPlaces ? 6 : 2,
            })}
        </>
    )
}

/**
 * Share price value should always be rounded half up to 3 decimal places
 */
export const SharePriceValue = ({value, currency}: {value: string | number; currency?: string}) => (
    <>
        <DollarValue decimalPlaces={3} value={value} currency={currency} />
    </>
)

/**
 * Dollar amounts to maximum 5 decimal places. Always round half up to five decimal places, slice any unneeded zeros off the end
 */
export const FeeValue = ({value, currency}: {value: string | number; currency?: string}) => {
    const v = formatNumberWithoutTrailingZeros({number: value.toString(), minDecimalPlaces: 2, maxDecimalPlaces: 5})

    if (v === '0.00') {
        return <span>{formatNumberWithCurrency('$0.00', currency)}</span>
    }

    if (+value < 0) {
        return <span>{formatNumberWithCurrency(`-$${v.substring(1)}`, currency)}</span>
    }

    return <span>{formatNumberWithCurrency(`$${v}`, currency)}</span>
}

export const PercentValue = ({
    value,
    explicitPlus,
    color,
    noDp,
}: {
    value: string | number
    explicitPlus?: boolean
    color?: boolean
    noDp?: boolean
}) => {
    const v = formatNumber({number: value.toString(), decimalPlaces: noDp ? 0 : 2})

    if (v === '0.00' || v === '0') {
        return <span className={'percent-value zero ' + (color ? 'color' : '')}>{noDp ? '0%' : '0.00%'}</span>
    }

    if (+value < 0) {
        return <span className={'percent-value negative' + (color ? 'color' : '')}>{v}%</span>
    }

    return (
        <span className={'percent-value positive' + (color ? 'color' : '')}>
            {explicitPlus ? '+' : ''}
            {v}%
        </span>
    )
}

interface ShareValues {
    numericValue: number
    stringValue: string
}

/**
 * A helper function which takes a numeric or string value of shares and formats it for display.
 * Formatting rules vary depending on the size of the value passed.
 */
export const getShareValues = (
    value: string | number,
    showFullValue?: boolean,
    wholeSharesOnly?: boolean,
): ShareValues => {
    let numericValue = Number(value)
    let decimalValue = new Decimal(numericValue)
    let stringValue: string

    if (wholeSharesOnly) {
        numericValue = Math.trunc(numericValue)
    }

    if (wholeSharesOnly || showFullValue) {
        stringValue = '' + numericValue
        return {numericValue, stringValue}
    }

    if (numericValue < -0.001 || numericValue > 0.0001) {
        // The above tests if it is a large enough value that 4dp will show something.

        // Where the share value is sufficient we truncate the decimal component to 4dp.
        // We are rounding down, the same strategy we use elsewhere for customer values.
        decimalValue = decimalValue.toDecimalPlaces(4, Decimal.ROUND_DOWN)

        numericValue = decimalValue.toNumber()
        // Apply thousands separator
        stringValue = formatNumberWithThousandsSeparator(decimalValue.toNumber().toString())
    } else if (numericValue === 0) {
        stringValue = '0'
    } else {
        // Just in case we have trailing zeroes, remove them
        stringValue = numericValue.toFixed(8).replace(/\.?0+$/, '')
    }
    return {numericValue, stringValue}
}

/**
 * Displays share values with some formatting conditional on the plusZero arg
 * and whether or not the value is negative. The string value displayed is
 * formatted using getShareValues(), documented above.
 */
export const ShareValue = (props: {
    value: string | number
    plusZero?: boolean
    showFullValue?: boolean
    wholeSharesOnly?: boolean
    suffixLabel?: string
}) => {
    const {value, plusZero, showFullValue, wholeSharesOnly, suffixLabel} = props
    const {numericValue, stringValue} = getShareValues(value, showFullValue, wholeSharesOnly)

    if (stringValue === '0') {
        if (plusZero) {
            return <span className="share-value zero">+0</span>
        } else {
            return <span className="share-value zero">0</span>
        }
    }

    if (numericValue < 0) {
        return (
            <span className="share-value negative">
                {stringValue} {suffixLabel ? ` ${suffixLabel}` : ''}
            </span>
        )
    }

    return (
        <span className="share-value positive">
            {stringValue} {suffixLabel ? ` ${suffixLabel}` : ''}
        </span>
    )
}

export const ExchangeRateValue = ({
    sourceCurrency,
    targetCurrency,
    exchangeRate,
}: {
    sourceCurrency: Currency
    targetCurrency: Currency
    exchangeRate: number
}) => {
    const roundHalfUpToSixDecimalPlaces = (value: number) => value.toFixed(6)

    return (
        <span>
            1 {sourceCurrency.toLocaleUpperCase()} = {roundHalfUpToSixDecimalPlaces(exchangeRate)}{' '}
            {targetCurrency.toLocaleUpperCase()}
        </span>
    )
}
