import {Button} from '@design-system/button'
import React from 'react'
import {urlFor} from '~/global/routeGenerator'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'

const ForgotPasswordMessage = () => {
    const navigate = useNavigate()
    return (
        <>
            <Toolbar dataTestId="toolbar--forgot-password-message" title="Forgot password" isInlineTitle />
            <Page>
                <p>
                    We've sent a password reset link to your email. Go check your emails and you should be good as gold!
                </p>
                <Button
                    dataTestId="login"
                    onClick={() => navigate(urlFor('login'))}
                    label="Go back to login"
                    pageButton
                />
            </Page>
        </>
    )
}

export default ForgotPasswordMessage
