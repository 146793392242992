import React from 'react'
import {useRetailGet} from '~/api/query/retail'
import {constructCoveEmbedUserLoginUrl} from '~/sections/protect/sections/insure/sections/car/utils/cove-embed/coveEmbed'
import {CoveEmbedPage} from '~/sections/protect/sections/insure/sections/car/widgets/cove-embed-page/CoveEmbedPage'
import {CoveIframe} from '~/sections/protect/sections/insure/sections/car/widgets/cove-iframe/CoveIframe'

export const CoveManagePayment: React.FunctionComponent = () => {
    return (
        <CoveEmbedPage name="Cove Manage Payment embed page">
            <CoveManagePaymentIframe />
        </CoveEmbedPage>
    )
}

const CoveManagePaymentIframe: React.FunctionComponent = () => {
    const {data: coveEmbedLogin} = useRetailGet({
        path: 'insure/get-cove-user-embed-login-id',
    })

    const embedUrl = constructCoveEmbedUserLoginUrl(coveEmbedLogin.login_id, '/user/change-payment')

    return <CoveIframe url={embedUrl} />
}
