import React from 'react'

const MarketCapitalisationDetail: React.FunctionComponent = () => (
    <div>
        <h1>Market capitalisation</h1>
        <p>
            Market capitalisation, also known as market cap, is one way to measure the size of an investment. It’s
            calculated by multiplying the total number of shares by the investment price.
        </p>
        <p>
            Investments can be broken into three sizes, small-cap, mid-cap and large-cap. Generally, small-caps are
            thought to have more opportunity for growth, mid-caps are more stable but still have some room for growth,
            while large-cap companies tend to be the most stable.
        </p>
        <p>Owning investments at different cap sizes is another way to increase the diversity of your Portfolio.</p>
    </div>
)

export default MarketCapitalisationDetail
