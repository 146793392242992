import React from 'react'
import {Model} from '~/api/retail/types'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {tradingType, TradingType} from '~/global/utils/trading-type/tradingType'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import InlineAUTaxForm from '~/global/widgets/inline-tax-form/InlineAUTaxForm'
import InlineNZTaxForm from '~/global/widgets/inline-tax-form/InlineNZTaxForm'
import {isNavigationDirective, useNavigate} from '~/migrate-react-router'
import {connect} from '~/store/connect'
import actions from '~/store/order/actions'
import {StagedBuyOrder} from '~/store/order/types'
import {UnwrapThunkAction} from '~/store/types'

const BuyTaxForm: React.FunctionComponent<Props> = ({stagedBuyOrder, instrumentType, jurisdiction, costBuyOrder}) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    if (!stagedBuyOrder) {
        return <WeSlippedUp />
    }

    const externalSubmitHandler = async () => {
        const response = await costBuyOrder()
        if (isNavigationDirective(response)) {
            response.execute(navigate, profileUrl)
        }
        return undefined
    }

    return jurisdiction === 'au' ? (
        <InlineAUTaxForm externalSubmitHandler={externalSubmitHandler} />
    ) : (
        <InlineNZTaxForm pirRequired={instrumentType === 'managed'} externalSubmitHandler={externalSubmitHandler} />
    )
}

interface StoreProps {
    stagedBuyOrder?: StagedBuyOrder
    instrumentType: TradingType
    jurisdiction: Model.User['jurisdiction']
}

interface DispatchProps {
    costBuyOrder: UnwrapThunkAction<typeof actions.CostBuyOrder>
}

type Props = StoreProps & DispatchProps

export default connect<StoreProps, DispatchProps, {}>(
    state => ({
        stagedBuyOrder: state.order.stagedBuyOrder,
        instrumentType:
            state.order.stagedBuyOrder && state.instrument.instrumentsById[state.order.stagedBuyOrder.fundId]
                ? tradingType(state.instrument.instrumentsById[state.order.stagedBuyOrder.fundId])
                : 'listed',
        jurisdiction: state.identity.user!.jurisdiction,
    }),
    dispatch => ({
        costBuyOrder: () => dispatch(actions.CostBuyOrder()),
    }),
)(BuyTaxForm)
