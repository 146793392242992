import {Button} from '@design-system/button'
import {Download} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {PageButtonGroup} from '~/global/widgets/button-page-group/PageButtonGroup'
import {Loading} from '~/global/widgets/loading'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import commonStyles from '~/sections/invest/sections/transfer-shares/pages/landing/Landing.scss'
import styles from '~/sections/invest/sections/transfer-shares/sections/us-transfer-shares/DownloadTransferForm.scss'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import actions from '~/store/transfer/actions'

interface DownloadTransferFormProps {
    groupId: string
}
export default function DownloadTransferForm({groupId}: DownloadTransferFormProps) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()

    const {stagedTransferOrder, transfersLoadingState} = useAppSelector(({transfer}) => transfer)

    const navigateHome = () => {
        navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})
    }

    const downloadFormUrl = () => {
        if (stagedTransferOrder?.direction === 'in') {
            if (stagedTransferOrder?.usTransferPlatform === 'DRS') {
                return '/file/drs_transfer_in_form.pdf'
            } else {
                return '/file/drivewealth_transfer_in_form.pdf'
            }
        } else if (stagedTransferOrder?.direction === 'out') {
            if (stagedTransferOrder?.usTransferPlatform === 'DRS') {
                return '/file/drs_transfer_out_form.pdf'
            } else {
                return '/file/drivewealth_transfer_out_form.pdf'
            }
        }
    }

    React.useEffect(() => {
        if (transfersLoadingState === 'loading' && !stagedTransferOrder) {
            dispatch(actions.SetStagedTransfer(groupId))
        }
        // Return to the start of the flow if there are no registry details or transfer order
        if (transfersLoadingState === 'ready' && !stagedTransferOrder) {
            navigateHome()
        }
    }, [transfersLoadingState])

    const saveApplication = () => {
        dispatch(actions.ResetStagedTransferOrder())
        navigate(profileUrl('invest/portfolio-transfer-shares'), {replace: true})
        Toast('Your transfer application was saved')
    }

    if (transfersLoadingState === 'loading') {
        return <Loading isPineapple />
    }

    if (transfersLoadingState === 'error') {
        navigateHome()
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--transfer-shares-download-transfer-form"
                leftButton="back"
                onLeftButtonClick={navigateHome}
            />

            <Page>
                <h1 className={cn(commonStyles.heading, spacing.spaceBelow24)}>Complete a transfer form</h1>
                <p className={spacing.spaceBelow16}>
                    {stagedTransferOrder?.rejected ? (
                        <>
                            We emailed you with some more info about what's needed to complete your transfer. Once
                            that's sorted, simply follow the instructions below.
                        </>
                    ) : (
                        <>Download and fill out the transfer form. You can sign it digitally or physically. </>
                    )}
                </p>
                <a
                    href={downloadFormUrl()}
                    target="_blank"
                    rel="noopener"
                    className={cn(spacing.spaceBelow16, styles.downloadLink)}
                    download
                >
                    <Download /> Download Transfer Form PDF
                </a>

                <p className={spacing.spaceBelow16}>Next up, you’ll need to upload a copy of the signed form.</p>
                <p>
                    If you need help, reach out to{' '}
                    <a href="mailto:transfers@sharesies.co.nz">transfers@sharesies.co.nz</a>.
                </p>
                <PageButtonGroup>
                    <Button
                        isSubmit
                        label="Continue to upload"
                        dataTestId="button--continue"
                        additionalClassName={spacing.spaceBelow16}
                        onClick={() =>
                            navigate(
                                profileUrl('invest/portfolio-transfer-shares/us/:groupId/upload-documents', {
                                    groupId,
                                }),
                            )
                        }
                    />
                    <Button
                        type="secondary"
                        label="Save application"
                        dataTestId="button--save-application"
                        additionalClassName={spacing.spaceBelow48}
                        onClick={saveApplication}
                    />
                </PageButtonGroup>
            </Page>
        </>
    )
}
