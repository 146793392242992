import {Button} from '@design-system/button'
import {FileUpload, MutateUploads} from '@design-system/file-upload'
import {Trash} from '@design-system/icon'
import {withFormik} from 'formik'
import React from 'react'
import {Navigate, useNavigate} from 'react-router'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {withRouter, WithRouterProps} from '~/global/utils/with-router/withRouter'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ErrorBox, validate} from '~/global/widgets/form-controls'
import {Text} from '~/global/widgets/form-controls/formik'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import CustomImage from '~/sections/user/widgets/custom-image/CustomImage'
import autoinvestActions from '~/store/autoinvest/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import {PersonaliseProductContext} from '.'
import styles from './PersonaliseProduct.scss'

interface DefaultValues {
    defaultName: string
    defaultImage: string
    successUrl: string
}

interface FormProps {
    context: PersonaliseProductContext
    defaultValues: DefaultValues
    productName?: string
    productImageId?: string
    displayImageUrl?: string
    setDisplayImageUrl: (imageUrl?: string) => void
    handleAutoinvestSubmit: (productName?: string, productImageId?: string) => void
}

interface FormValues {
    productName?: string | undefined
    productImageId?: string | undefined
}

const PersonaliseForm = withRouter(
    withFormik<FormProps & WithRouterProps, FormValues>({
        mapPropsToValues: ({productName, productImageId}) => ({
            productName,
            productImageId,
        }),
        handleSubmit: (
            {productName, productImageId},
            {
                setSubmitting,
                props: {
                    context,
                    router: {navigate},
                    defaultValues: {successUrl},
                    handleAutoinvestSubmit,
                },
            },
        ) => {
            setSubmitting(true)

            if (context === 'autoinvest') {
                handleAutoinvestSubmit(productName, productImageId)
            }

            navigate(successUrl)
        },
        validate: validate.generate<FormValues>({
            productName: [validate.maxLength(25, "You've exceeded the maximum character length of 25 characters")],
        }),
    })(
        ({
            handleSubmit,
            handleChange,
            isSubmitting,
            values,
            defaultValues: {defaultImage, defaultName, successUrl},
            displayImageUrl,
            setDisplayImageUrl,
            router: {navigate},
        }) => {
            const dispatch = useAppDispatch()

            const [uploadError, setUploadError] = React.useState<string | undefined>()
            const [imageFile, setImageFile] = React.useState<File[]>([])
            const [imageLoading, setImageLoading] = React.useState<boolean>(false)

            const handleImageUpload = async (mutate: MutateUploads) => {
                const mutatedUpload = mutate([])
                setImageLoading(true)

                // The funkiness with setImageFile allows us to display the
                // FileUpload component when there's an error, but display our
                // custom image component when the image uploads correctly

                try {
                    if (mutatedUpload.length > 0) {
                        const response = await dispatch(identityActions.UploadOrderImage(mutatedUpload))

                        if (response) {
                            // Wait for the upload animation to finish
                            if (typeof response === 'string') {
                                setImageFile(mutatedUpload)
                                setUploadError(response)
                            } else {
                                setUploadError(undefined)
                                setImageFile([])

                                await dispatch(identityActions.GetOrderImages())

                                setTimeout(() => {
                                    setDisplayImageUrl(response.image_url)
                                    setImageLoading(false)
                                    values.productImageId = response.image_id
                                }, 4000)
                            }
                        }
                    }
                } catch (e) {
                    setUploadError('Something went wrong with your upload, please try again')
                }
            }

            const handleImageRemove = () => {
                if (values.productImageId) {
                    dispatch(identityActions.ArchiveImage(values.productImageId))
                }

                setDisplayImageUrl()
                values.productImageId = undefined
            }

            return (
                <>
                    <div className={styles.header}>
                        <Toolbar
                            dataTestId="toolbar--personalise-auto-invest"
                            leftButton="back"
                            title="Personalise your order"
                        />
                        <div className={styles.preview}>
                            <CustomImage imageUrl={displayImageUrl || defaultImage} />
                            <h2>{values.productName || defaultName}</h2>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <Page>
                            <Text
                                name="productName"
                                dataTestId="text--order-name"
                                label="Give it a name"
                                placeholder={defaultName}
                                onChange={handleChange}
                                helpText="This could be the theme of your order or what you are investing for."
                            />
                            <p className={styles.subtitle}>Upload your own image</p>
                            <p className={styles.description}>
                                You can upload a jpeg or PNG, ensuring the file is no larger than 4 mb
                            </p>
                            {displayImageUrl ? (
                                <div className={styles.uploadedContainer}>
                                    <CustomImage imageUrl={displayImageUrl} />
                                    <Trash onClick={handleImageRemove} />
                                </div>
                            ) : (
                                <>
                                    <FileUpload
                                        id="image-upload"
                                        files={imageFile}
                                        onChange={handleImageUpload}
                                        accept={{'image/png': ['.png'], 'image/jpeg': ['.jpeg'], 'image/jpg': ['.jpg']}}
                                    />
                                    <ErrorBox message={uploadError} />
                                </>
                            )}
                        </Page>
                        <ActionBar>
                            <Button
                                processing={isSubmitting}
                                type="secondary"
                                label="Skip"
                                dataTestId="button--personalise-order-skip"
                                onClick={() => navigate(successUrl)}
                                additionalClassName={styles.footerButton}
                            />
                            <Button
                                processing={isSubmitting}
                                type="primary"
                                label="Next"
                                dataTestId="button--personalise-order-submit"
                                onClick={() => handleSubmit()}
                                disabled={imageLoading}
                            />
                        </ActionBar>
                    </form>
                </>
            )
        },
    ),
)

interface PersonaliseProps {
    context: PersonaliseProductContext
    presetName?: string
    presetImageId?: string
    defaultName?: string
    defaultImage?: string
    defaultSuccessUrl?: string
}

const PersonaliseProduct: React.FunctionComponent<PersonaliseProps> = ({
    context,
    presetName,
    presetImageId,
    defaultName,
    defaultImage,
    defaultSuccessUrl,
}) => {
    const stagedOrder = useAppSelector(s => s.autoinvest.stagedOrder)
    const uploadedImages = useAppSelector(s => s.identity.customImages)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const profileUrl = useProfileUrl()

    const [displayImageUrl, setDisplayImageUrl] = React.useState<string | undefined>(
        uploadedImages.find(image => image.image_id === presetImageId)?.image_url,
    )

    React.useEffect(() => {
        dispatch(identityActions.GetOrderImages())
    }, [])

    if (context === 'autoinvest' && (!stagedOrder || stagedOrder.state === 'completed')) {
        // Autoinvest - redirect if we have not completed previous steps
        navigate(profileUrl('auto-invest'), {replace: true})
        return <Navigate to={profileUrl('auto-invest')} replace />
    }

    const defaults = {
        defaultName: defaultName ?? '',
        defaultImage: defaultImage ?? '',
        successUrl: defaultSuccessUrl ?? '',
    }

    // Each context will need to provide a custom handleSubmit function below:
    const handleAutoinvestSubmit = (orderName?: string, productImageId?: string) => {
        dispatch(autoinvestActions.SetOrderName(orderName))
        dispatch(autoinvestActions.SetOrderImage(productImageId))
    }

    return (
        <PersonaliseForm
            context={context}
            defaultValues={defaults}
            productName={presetName || ''}
            productImageId={presetImageId}
            handleAutoinvestSubmit={handleAutoinvestSubmit}
            displayImageUrl={displayImageUrl}
            setDisplayImageUrl={(imageUrl?: string) => setDisplayImageUrl(imageUrl)}
        />
    )
}

export default PersonaliseProduct
