/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Data Service PublicAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface PriceHistoryDto
 */
export interface PriceHistoryDto {
    /**
     *
     * @type {string}
     * @memberof PriceHistoryDto
     */
    close: string
    /**
     *
     * @type {Date}
     * @memberof PriceHistoryDto
     */
    localTradingDate: DateTime
    /**
     *
     * @type {string}
     * @memberof PriceHistoryDto
     */
    open?: string | null
    /**
     *
     * @type {string}
     * @memberof PriceHistoryDto
     */
    high?: string | null
    /**
     *
     * @type {string}
     * @memberof PriceHistoryDto
     */
    low?: string | null
    /**
     *
     * @type {string}
     * @memberof PriceHistoryDto
     */
    volume?: string | null
    /**
     *
     * @type {string}
     * @memberof PriceHistoryDto
     */
    readonly exercisePrice?: string | null
}

export function PriceHistoryDtoFromJSON(json: any): PriceHistoryDto {
    return PriceHistoryDtoFromJSONTyped(json, false)
}

export function PriceHistoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceHistoryDto {
    if (json === undefined || json === null) {
        return json
    }
    return {
        close: json['close'],
        localTradingDate: DateTime.fromISO(json['localTradingDate'], {setZone: true}),
        open: !exists(json, 'open') ? undefined : json['open'],
        high: !exists(json, 'high') ? undefined : json['high'],
        low: !exists(json, 'low') ? undefined : json['low'],
        volume: !exists(json, 'volume') ? undefined : json['volume'],
        exercisePrice: !exists(json, 'exercisePrice') ? undefined : json['exercisePrice'],
    }
}

export function PriceHistoryDtoToJSON(value?: PriceHistoryDto | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        close: value.close,
        localTradingDate: value.localTradingDate.toFormat('yyyy-MM-dd'),
        open: value.open,
        high: value.high,
        low: value.low,
        volume: value.volume,
    }
}
