import AccountIcon from './account.svg'
import ArrowDownIcon from './arrow-down.svg'
import ArrowUpIcon from './arrow-up.svg'
import AutoInvestIcon from './auto-invest.svg'
import CalendarIcon from './calendar.svg'
import CancelIcon from './cancel.svg'
import CheckSingleIcon from './check-single.svg'
import ChevronDownLgIcon from './chevron-down-lg.svg'
import ChevronDownIcon from './chevron-down.svg'
import ChevronLeftIcon from './chevron-left.svg'
import ChevronRightIcon from './chevron-right.svg'
import ChevronUpLgIcon from './chevron-up-lg.svg'
import ChevronUpIcon from './chevron-up.svg'
import ClockIcon from './clock.svg'
import CloseIcon from './close.svg'
import DotBigIcon from './dot-big.svg'
import EventIcon from './event.svg'
import ExchangeClosedIcon from './exchange-closed.svg'
import ExchangeOpenIcon from './exchange-open.svg'
import FilterIcon from './filter.svg'
import HandIcon from './hand.svg'
import HeartReferAFriendIcon from './heart-refer-a-friend.svg'
import HelpSupportIcon from './help-support.svg'
import LoaderIcon from './loader.svg'
import OrderIcon from './order.svg'
import PauseIcon from './pause.svg'
import PersonIcon from './person.svg'
import RadioEmptyIcon from './radio-empty.svg'
import RadioFilledIcon from './radio-filled.svg'
import SearchIcon from './search.svg'
import SellOnlyIcon from './sell-only.svg'
import SellIcon from './sell.svg'
import SharesOutlineIcon from './shares-outline.svg'
import SpareChangeIcon from './spare-change.svg'
import SuccessFilledIcon from './success-filled.svg'
import SuccessIcon from './success.svg'
import TimeIcon from './time.svg'
import TradingHaltIcon from './trading-halt.svg'
import TransferIcon from './transfer.svg'
import TrashOutlineIcon from './trash-outline.svg'
import UnitsIcon from './units.svg'
import VestedAllocationIcon from './vested-allocation.svg'
import {iconWrapper} from './wrapper'

/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const Account = iconWrapper(AccountIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const ArrowDown = iconWrapper(ArrowDownIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const ArrowUp = iconWrapper(ArrowUpIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const AutoInvest = iconWrapper(AutoInvestIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const Calendar = iconWrapper(CalendarIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const Cancel = iconWrapper(CancelIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const CheckSingle = iconWrapper(CheckSingleIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const ChevronDownLg = iconWrapper(ChevronDownLgIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const ChevronDown = iconWrapper(ChevronDownIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const ChevronLeft = iconWrapper(ChevronLeftIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const ChevronRight = iconWrapper(ChevronRightIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const ChevronUpLg = iconWrapper(ChevronUpLgIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const ChevronUp = iconWrapper(ChevronUpIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const Clock = iconWrapper(ClockIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const Close = iconWrapper(CloseIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const DotBig = iconWrapper(DotBigIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const Event = iconWrapper(EventIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const ExchangeClosed = iconWrapper(ExchangeClosedIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const ExchangeOpen = iconWrapper(ExchangeOpenIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const Filter = iconWrapper(FilterIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const Hand = iconWrapper(HandIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const HeartReferAFriend = iconWrapper(HeartReferAFriendIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const HelpSupport = iconWrapper(HelpSupportIcon)
/**
 * @deprecated import from `@design-system/loader` instead.
 */
export const Loader = iconWrapper(LoaderIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const Order = iconWrapper(OrderIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const Pause = iconWrapper(PauseIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const Person = iconWrapper(PersonIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const RadioEmpty = iconWrapper(RadioEmptyIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const RadioFilled = iconWrapper(RadioFilledIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const Search = iconWrapper(SearchIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const SellOnly = iconWrapper(SellOnlyIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const Sell = iconWrapper(SellIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const SharesOutline = iconWrapper(SharesOutlineIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const SpareChange = iconWrapper(SpareChangeIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const SuccessFilled = iconWrapper(SuccessFilledIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const Success = iconWrapper(SuccessIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const Time = iconWrapper(TimeIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const TradingHalt = iconWrapper(TradingHaltIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const Transfer = iconWrapper(TransferIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const TrashOutline = iconWrapper(TrashOutlineIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const Units = iconWrapper(UnitsIcon)
/**
 * @deprecated import from `@design-system/icon` instead.
 *
 * Read more about {@link https://sharesies-design-system.zeroheight.com/styleguide/s/69231/p/38b929-icons/b/737e5e Design System Icons}
 */
export const VestedAllocation = iconWrapper(VestedAllocationIcon)

export type Icons =
    | 'Account'
    | 'ArrowDown'
    | 'ArrowUp'
    | 'AutoInvest'
    | 'Calendar'
    | 'Cancel'
    | 'CheckSingle'
    | 'ChevronDownLg'
    | 'ChevronDown'
    | 'ChevronLeft'
    | 'ChevronRight'
    | 'ChevronUpLg'
    | 'ChevronUp'
    | 'Clock'
    | 'Close'
    | 'DotBig'
    | 'Event'
    | 'ExchangeClosed'
    | 'ExchangeOpen'
    | 'Filter'
    | 'Hand'
    | 'HeartReferAFriend'
    | 'HelpSupport'
    | 'Loader'
    | 'Order'
    | 'Pause'
    | 'Person'
    | 'RadioEmpty'
    | 'RadioFilled'
    | 'Search'
    | 'SellOnly'
    | 'Sell'
    | 'SharesOutline'
    | 'SpareChange'
    | 'SuccessFilled'
    | 'Success'
    | 'Time'
    | 'TradingHalt'
    | 'Transfer'
    | 'TrashOutline'
    | 'Units'
    | 'VestedAllocation'

export type IconType =
    | typeof Account
    | typeof ArrowDown
    | typeof ArrowUp
    | typeof AutoInvest
    | typeof Calendar
    | typeof Cancel
    | typeof CheckSingle
    | typeof ChevronDownLg
    | typeof ChevronDown
    | typeof ChevronLeft
    | typeof ChevronRight
    | typeof ChevronUpLg
    | typeof ChevronUp
    | typeof Clock
    | typeof Close
    | typeof DotBig
    | typeof Event
    | typeof ExchangeClosed
    | typeof ExchangeOpen
    | typeof Filter
    | typeof Hand
    | typeof HeartReferAFriend
    | typeof HelpSupport
    | typeof Loader
    | typeof Order
    | typeof Pause
    | typeof Person
    | typeof RadioEmpty
    | typeof RadioFilled
    | typeof Search
    | typeof SellOnly
    | typeof Sell
    | typeof SharesOutline
    | typeof SpareChange
    | typeof SuccessFilled
    | typeof Success
    | typeof Transfer
    | typeof Units
    | typeof VestedAllocation

export const Icons: Icons[] = [
    'Account',
    'ArrowDown',
    'ArrowUp',
    'AutoInvest',
    'Calendar',
    'Cancel',
    'CheckSingle',
    'ChevronDown',
    'ChevronLeft',
    'ChevronRight',
    'ChevronUp',
    'Clock',
    'Close',
    'DotBig',
    'Event',
    'ExchangeClosed',
    'ExchangeOpen',
    'Filter',
    'Hand',
    'HeartReferAFriend',
    'HelpSupport',
    'Loader',
    'Order',
    'Pause',
    'Person',
    'RadioEmpty',
    'RadioFilled',
    'Search',
    'SellOnly',
    'Sell',
    'SharesOutline',
    'SpareChange',
    'SuccessFilled',
    'Success',
    'Time',
    'TradingHalt',
    'Transfer',
    'TrashOutline',
    'Units',
    'VestedAllocation',
]
