import {Button} from '@design-system/button'
import cn from 'classnames'
import React, {useState} from 'react'
import {spacing} from '~/global/scss/helpers'

export type ContentToggleState = 'open' | 'closed'

/**
 * Content Toggle - a content toggle (show/hide) controlled by a button
 *
 * Content is toggled on/off the DOM via a button.
 * When hidden, content cannot be tabbed to or read by a screenreader.
 *
 * @example
 * <ContentToggle dataTestId='button--toggle' label='Press me' initialState='closed'>
 *     <p>Content to show/hide</p>
 * </ContentToggle>
 */
const ContentToggle: React.FunctionComponent<ContentToggleProps> = ({
    className,
    dataTestId,
    label,
    openLabel,
    buttonType = 'secondary',
    initialState,
    children,
    onChange,
}) => {
    const [open, setOpen] = useState(initialState === 'open')

    const handleChange = () => {
        // call parent components onChange function if its defined
        if (onChange) {
            onChange(!open)
        }
        setOpen(!open)
    }

    return (
        <div className={cn({[spacing.spaceBelow32]: !open})}>
            <Button
                dataTestId={dataTestId}
                label={open && openLabel ? openLabel : label}
                type={buttonType}
                onClick={handleChange}
            />
            {open && (
                <div className={cn(spacing.spaceAbove32, className)}>
                    <div>{children}</div>
                </div>
            )}
        </div>
    )
}

interface ContentToggleProps {
    className?: string
    dataTestId: string
    label: string
    openLabel?: string
    buttonType?: 'primary' | 'secondary'
    initialState?: ContentToggleState
    onChange?: (isOpen: boolean) => void
}

export default ContentToggle
