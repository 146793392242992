import {Button} from '@design-system/button'
import cn from 'classnames'
import React from 'react'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate, useSearchParams} from '~/migrate-react-router'

const CloseAccountDependentSuccess: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const preferredName = useSearchParams()[0].get('preferredName')
    const yourString = typeof preferredName === 'string' ? `${preferredName}’s` : 'Your'

    return (
        <>
            <Toolbar dataTestId="toolbar--account-closed-dependent" title="Account closed" />
            <Page>
                <h2 className={cn(typographyOverrides['as-h2'], spacing.spaceBelow12)}>
                    Thanks for supporting Sharesies!
                </h2>
                <p className={spacing.spaceBelow24}>{yourString} account has been closed.</p>
                <p className={spacing.spaceBelow24}>
                    You’re helping our vision of people having equal investment opportunities—no matter how much they
                    have to invest.
                </p>
                <p className={spacing.spaceBelow24}>Thanks from the Sharesies team.</p>
                <Button
                    label="Back to your portfolio"
                    onClick={() => navigate(profileUrl(''))}
                    pageButton
                    dataTestId="button--back-to-portfolio"
                />
            </Page>
        </>
    )
}

export default CloseAccountDependentSuccess
