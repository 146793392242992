import {AddressValue, Countries} from '@design-system/address-input'
import {Button} from '@design-system/button'
import cn from 'classnames'
import {withFormik} from 'formik'
import React, {useState} from 'react'
import {Model} from '~/api/retail/types'
import config from '~/configForEnv'
import {spacing, page} from '~/global/scss/helpers'
import {unknownErrorMessage} from '~/global/utils/error-text/errorText'
import {processAddressComponents} from '~/global/utils/format-address/formatAddress'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import {Address} from '~/global/widgets/form-controls/formik'
import HelpEmail from '~/global/widgets/help-email/HelpEmail'
import {AddressValidation, ManualAddressFields} from '~/global/widgets/manual-address-fields/ManualAddressFields'
import Page from '~/global/widgets/page/Page'
import SignupToolbar from '~/sections/user/sections/sign-up/widgets/signup-toolbar/SignupToolbar'
import {connect} from '~/store/connect'
import actions from '~/store/identity/actions'
import {AddressComponents} from '~/store/identity/types'

interface CommonProps {
    setAddressMode: (addressMode: State['addressMode']) => void
    signUpCopyAddress: DispatchProps['signUpCopyAddress']
    signUpAddress: DispatchProps['signUpAddress']
    isDependent: boolean
    canSignUpForAu: boolean
    preferredName: string
    jurisdiction: Model.User['jurisdiction']
}

interface AutoAddressFormValues extends AddressComponents {
    address: AddressValue
}

const AutoAddressForm = withFormik<CommonProps, AutoAddressFormValues>({
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        address: undefined,
    }),
    handleSubmit: async (
        {address, street_number, route, sublocality, locality, country_code, state_code, postal_code},
        {setSubmitting, setStatus, props: {signUpAddress}},
    ) => {
        let addressToSend = address

        // If the individual address values don't match the address picker's ones, use those instead
        if (
            street_number !== address.components.street_number ||
            route !== address.components.route ||
            sublocality !== address.components.sublocality ||
            locality !== address.components.locality ||
            state_code !== address.components.state_code ||
            postal_code !== address.components.postal_code ||
            country_code !== address.components.country_code
        ) {
            addressToSend = processAddressComponents({
                street_number,
                route,
                locality,
                postal_code,
                country_code,
                state_code,
                sublocality,
            })
        }

        try {
            await signUpAddress(addressToSend)
        } catch (e) {
            setStatus(unknownErrorMessage)
            setSubmitting(false)
            throw e
        }
    },
    validate: values => {
        return AddressValidation(values)
    },
})(({
    handleSubmit,
    isSubmitting,
    isValid,
    setAddressMode,
    isDependent,
    canSignUpForAu,
    preferredName,
    values,
    jurisdiction,
    setFieldValue,
}) => {
    return (
        <form onSubmit={handleSubmit}>
            {!values.address && (
                <Address
                    googleMapsKey={config.googleMapsKey}
                    countries={canSignUpForAu ? [Countries.AU] : [Countries.NZ]}
                    dataTestId="address-input"
                    name="address"
                    label={isDependent ? `${preferredName}’s residential address` : 'Residential address'}
                    helpText={
                        <ButtonAsLink dataTestId="anchor--manual-address-form" onClick={() => setAddressMode('manual')}>
                            Can’t find your address?
                        </ButtonAsLink>
                    }
                    updateAddress={address => {
                        setFieldValue('address', address)
                        if (address) {
                            Object.keys(address.components).forEach(key => {
                                setFieldValue(key, address.components[key])
                            })
                        }
                    }}
                    autoFocus
                />
            )}
            {values.address && (
                <ManualAddressFields selectedCountry={values.country_code} jurisdiction={jurisdiction} />
            )}
            <Button
                isSubmit
                dataTestId="button--next"
                pageButton
                label={isDependent ? 'Next' : 'Verify me'}
                disabled={!isValid}
                processing={isSubmitting}
            />
        </form>
    )
})

const ManualAddressForm = withFormik<CommonProps, AddressComponents>({
    mapPropsToValues: props => ({
        street_number: '',
        route: '',
        sublocality: '',
        locality: '',
        state_code: '',
        postal_code: '',
        country_code: props.jurisdiction.toUpperCase(),
    }),
    mapPropsToErrors: () => ({
        // make the button disabled initially by setting at least one field to have an error
        street_number: undefined,
    }),
    handleSubmit: async (
        {sublocality, street_number, route, locality, country_code, state_code, postal_code},
        {setSubmitting, setStatus, props: {signUpAddress}},
    ) => {
        try {
            const address = processAddressComponents({
                street_number,
                route,
                locality,
                postal_code,
                country_code,
                state_code,
                sublocality,
            })
            await signUpAddress(address)
        } catch (e) {
            setStatus(unknownErrorMessage)
            setSubmitting(false)
            throw e
        }
    },
    validate: values => {
        return AddressValidation(values)
    },
})(({handleSubmit, isSubmitting, isValid, isDependent, jurisdiction, values}) => {
    return (
        <form onSubmit={handleSubmit}>
            <ManualAddressFields selectedCountry={values.country_code} jurisdiction={jurisdiction} />
            <Button
                dataTestId="button--next"
                isSubmit
                pageButton
                label={isDependent ? 'Next' : 'Verify me'}
                disabled={!isValid}
                processing={isSubmitting}
            />
        </form>
    )
})

const CopyAddressForm = withFormik<CommonProps, {}>({
    handleSubmit: async (_, {setSubmitting, setStatus, props: {signUpCopyAddress}}) => {
        try {
            await signUpCopyAddress()
        } catch (e) {
            setStatus(unknownErrorMessage)
            setSubmitting(false)
            throw e
        }
    },
})(({handleSubmit, isSubmitting}) => (
    <form onSubmit={handleSubmit}>
        <Button dataTestId="button--next" isSubmit pageButton label="Next" processing={isSubmitting} />
    </form>
))

interface CommonAddressDetailsProps extends CommonProps {
    addressMode: State['addressMode']
}

interface DependentAddressDetailsProps {
    dependentLivesWithYou: State['dependentLivesWithYou']
    setDependentLivesWithYou(value: boolean): void
}

type AddressDetailsDependentProps = CommonAddressDetailsProps & DependentAddressDetailsProps

const AddressDetailsDependent: React.FunctionComponent<AddressDetailsDependentProps> = ({
    signUpCopyAddress,
    signUpAddress,
    addressMode,
    setAddressMode,
    isDependent,
    canSignUpForAu,
    preferredName,
    jurisdiction,
    dependentLivesWithYou,
    setDependentLivesWithYou,
}) => {
    const formProps = {
        setAddressMode,
        signUpAddress,
        signUpCopyAddress,
        isDependent,
        canSignUpForAu,
        preferredName,
        jurisdiction,
    }

    return (
        <Page>
            <h1 className={spacing.spaceBelow12}>{preferredName}’s address</h1>
            <p className={spacing.spaceBelow16}>
                <strong>Does {preferredName} live with you?</strong>
            </p>
            <p className={cn(spacing.spaceBelow24, page.flexRow)}>
                <span>
                    <Button
                        label="Yes"
                        dataTestId="button--yes"
                        type={dependentLivesWithYou === true ? 'primary' : 'secondary'}
                        onClick={() => setDependentLivesWithYou(true)}
                    />
                </span>
                <span>
                    <Button
                        label="No"
                        dataTestId="button--no"
                        type={dependentLivesWithYou === false ? 'primary' : 'secondary'}
                        onClick={() => setDependentLivesWithYou(false)}
                    />
                </span>
            </p>
            {dependentLivesWithYou === false && addressMode === 'auto' && <AutoAddressForm {...formProps} />}
            {dependentLivesWithYou === false && addressMode === 'manual' && <ManualAddressForm {...formProps} />}
            {dependentLivesWithYou === true && <CopyAddressForm {...formProps} />}
        </Page>
    )
}

const AddressDetails: React.FunctionComponent<CommonAddressDetailsProps> = ({
    signUpCopyAddress,
    signUpAddress,
    setAddressMode,
    addressMode,
    isDependent,
    canSignUpForAu,
    preferredName,
    jurisdiction,
}) => {
    const formProps = {
        setAddressMode,
        signUpAddress,
        signUpCopyAddress,
        isDependent,
        canSignUpForAu,
        preferredName,
        jurisdiction,
    }

    return (
        <Page>
            <h1 className={spacing.spaceBelow16}>Where do you live?</h1>
            <p className={spacing.spaceBelow16}>
                We need to verify your residential address—we’ll try to do this online first. If you don’t have one let
                us know at <HelpEmail />.
            </p>
            {addressMode === 'auto' && <AutoAddressForm {...formProps} />}
            {addressMode === 'manual' && <ManualAddressForm {...formProps} />}
        </Page>
    )
}

export const SignupAddress: React.FunctionComponent<SignupAddressProps> = ({
    isDependent,
    canSignUpForAu,
    preferredName,
    jurisdiction,
    signUpAddress,
    signUpCopyAddress,
}) => {
    const [addressMode, setAddressMode] = useState<State['addressMode']>('auto')
    const [dependentLivesWithYou, setDependentLivesWithYou] = useState<State['dependentLivesWithYou']>()

    const commonProps: CommonAddressDetailsProps = {
        isDependent,
        canSignUpForAu,
        preferredName,
        jurisdiction,
        signUpAddress,
        signUpCopyAddress,
        setAddressMode,
        addressMode,
    }

    return (
        <>
            <SignupToolbar />
            {isDependent ? (
                <AddressDetailsDependent
                    {...commonProps}
                    dependentLivesWithYou={dependentLivesWithYou}
                    setDependentLivesWithYou={setDependentLivesWithYou}
                />
            ) : (
                <AddressDetails {...commonProps} />
            )}
        </>
    )
}

interface State {
    addressMode: 'auto' | 'manual'
    dependentLivesWithYou?: boolean
}

interface StoreProps {
    isDependent: boolean
    canSignUpForAu: boolean
    preferredName: string
    jurisdiction: Model.User['jurisdiction']
}

interface DispatchProps {
    signUpAddress(address: AddressValue): Promise<null | 'pending' | 'verified'>
    signUpCopyAddress(): Promise<string | undefined>
}

type SignupAddressProps = StoreProps & DispatchProps

export default connect<StoreProps, DispatchProps, {}>(
    ({identity}) => ({
        isDependent: identity.user!.is_dependent,
        canSignUpForAu: identity.user!.jurisdiction === 'au',
        preferredName: identity.user!.preferred_name,
        jurisdiction: identity.user!.jurisdiction,
    }),
    dispatch => ({
        signUpAddress: address => dispatch(actions.SignUpAddress(address)),
        signUpCopyAddress: () => dispatch(actions.SignUpCopyAddress()),
    }),
)(SignupAddress)
