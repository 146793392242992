import {colour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import cn from 'classnames'
import * as d3interpolate from 'd3-interpolate'
import * as d3scale from 'd3-scale'
import * as d3shape from 'd3-shape'
import 'd3-transition'
import {DateTime} from 'luxon'
import React from 'react'
import {slicePortfolioHistory, PortfolioHistoryItemWithIndex} from '~/global/utils/slice-history/sliceHistory'
import {Period, periodInformation} from '~/global/utils/time-period/timePeriod'
import withMeasure, {MeasureProps} from '~/global/widgets/with-measure/WithMeasure'
import {useTouchHover} from '~/sections/invest/sections/portfolio/sections/history/hooks/use-touch-hover/useTouchHover'
import AnimatedSvgPath from '~/sections/invest/sections/portfolio/sections/history/widgets/animated-svg-path/AnimatedSvgPath'
import {PortfolioHistoryItem} from '~/store/portfolio/types'
import styles from './PortfolioHistoryChart.scss'

interface Props {
    period: Period
    className?: string
    portfolioHistory: PortfolioHistoryItem[]
    onDayHover: (values: PortfolioHistoryItem) => void
}

const PortfolioHistoryChart: React.FC<Props & MeasureProps> = ({
    period,
    portfolioHistory,
    measuredHeight,
    measuredWidth,
    measuredLeft,
    className,
    onMeasureRef,
    onDayHover,
}) => {
    const colourMode = useColourMode()
    // Set the "All" duration to be from the investor's first portfolio return date.
    const earliestDate = portfolioHistory ? DateTime.fromISO(portfolioHistory[0].date) : DateTime.now()
    const now = DateTime.local()
    periodInformation.All.duration = now.diff(earliestDate, ['year', 'months', 'days', 'hours'])

    const startDate = DateTime.now().minus(periodInformation[period].duration).toISODate() // to only compare dates but not times
    const portfolioHistorySlice = React.useMemo(
        () => slicePortfolioHistory(portfolioHistory, startDate),
        [portfolioHistory, startDate],
    )

    const width = measuredWidth || 0
    const height = measuredHeight || 0

    const {xScale, xHoverScale, yScale} = React.useMemo(() => {
        // Setup graph scales
        let xMinValue = 0
        if (portfolioHistorySlice.length && portfolioHistorySlice[0].date !== startDate) {
            xMinValue = -Math.round(
                (DateTime.fromISO(portfolioHistorySlice[0].date).diff(DateTime.fromISO(startDate)).as('days') * 5) / 7,
            )
        }
        const maxPortfolioValue = portfolioHistorySlice.reduce(
            (p, c) => Math.max(p, c.portfolio_value, c.cost_basis),
            0,
        )
        const minPortfolioValue = portfolioHistorySlice.reduce(
            (p, c) => Math.min(p, c.portfolio_value, c.cost_basis),
            maxPortfolioValue,
        )

        // Offset the bottom line by 1/5 the graph height
        // This keeps space for the gradient.
        const graphDomainHeight = maxPortfolioValue - minPortfolioValue
        const lowerLineOffset = graphDomainHeight / 5

        return {
            xScale: d3scale
                .scaleLinear()
                .domain([xMinValue, portfolioHistorySlice.length - 1])
                .range([0, width]),
            xHoverScale: d3scale
                .scaleLinear()
                .domain([xMinValue, portfolioHistorySlice.length - 1])
                .range([0, width])
                .interpolate(d3interpolate.interpolateRound),
            yScale: d3scale
                .scaleLinear()
                .domain([minPortfolioValue - lowerLineOffset, maxPortfolioValue])
                .range([height - 0.5, 60]),
        }
    }, [portfolioHistorySlice, startDate, width, height])

    const {hoverDay, handlerProps, isTouchingChart} = useTouchHover(
        measuredLeft || 0,
        xHoverScale,
        portfolioHistorySlice,
    )

    const day =
        hoverDay === null ? portfolioHistorySlice.length - 1 : Math.min(hoverDay, portfolioHistorySlice.length - 1)
    React.useEffect(() => {
        onDayHover(portfolioHistorySlice[day] || portfolioHistorySlice[portfolioHistorySlice.length - 1])
    }, [day])

    const contributionLinePath = d3shape
        .line<PortfolioHistoryItemWithIndex>()
        .x(d => xScale(d.index) || 0)
        .y(d => yScale(d.cost_basis) || 0)
        .curve(d3shape.curveLinear)

    const marketValueLinePath = d3shape
        .line<PortfolioHistoryItemWithIndex>()
        .x(d => xScale(d.index) || 0)
        .y(d => yScale(d.portfolio_value) || 0)
        .curve(d3shape.curveLinear)

    const areaPath = d3shape
        .area<PortfolioHistoryItemWithIndex>()
        .x((_, i) => xScale(i) || 0)
        .y1(history => yScale(history.portfolio_value || 0) || 0)
        .y0(height)
        .curve(d3shape.curveLinear)

    return (
        <>
            <div className={cn(styles.container, className)} ref={onMeasureRef} {...handlerProps}>
                <svg viewBox={`0 0 ${width} ${height}`}>
                    <linearGradient id="contribution-gradient" x1="0" x2="0" y1="0" y2="1">
                        <stop offset="0%" stopColor={colour('Melon500', colourMode)} stopOpacity="0.4" />
                        <stop offset="100%" stopColor={colour('Melon50', colourMode)} stopOpacity="0.4" />
                    </linearGradient>
                    <AnimatedSvgPath
                        fill="url(#contribution-gradient)"
                        d={areaPath(portfolioHistorySlice) || ''}
                        id="areaPath"
                    />
                    <AnimatedSvgPath
                        className={styles.marketLine}
                        d={marketValueLinePath(portfolioHistorySlice) || ''}
                        id="marketValue"
                    />
                    <AnimatedSvgPath
                        className={styles.contributionLine}
                        d={contributionLinePath(portfolioHistorySlice) || ''}
                        id="contributionLine"
                    />
                </svg>
                <ChartCursor
                    measuredWidth={width}
                    measuredHeight={height}
                    isTouching={isTouchingChart}
                    hoverDay={hoverDay}
                    xScale={xScale}
                    yScale={yScale}
                    portfolioHistory={portfolioHistorySlice}
                />
            </div>
        </>
    )
}

export default withMeasure<Props & MeasureProps>(PortfolioHistoryChart)

const ChartCursor: React.FC<{
    measuredWidth: number
    measuredHeight: number
    hoverDay: number
    isTouching: boolean
    xScale: d3scale.ScaleLinear<number, number>
    yScale: d3scale.ScaleLinear<number, number>
    portfolioHistory: PortfolioHistoryItemWithIndex[]
}> = ({measuredWidth, measuredHeight, isTouching, hoverDay, xScale, yScale, portfolioHistory}) => {
    if (!measuredWidth) {
        return null
    }

    const x = xScale(hoverDay)
    const y = yScale(portfolioHistory[hoverDay].portfolio_value)

    return (
        <div className={styles.hover} style={{transform: `translateX(${x - 9}px)`}}>
            <svg
                className={styles.line}
                style={{
                    height: measuredHeight - 56,
                }}
            />
            <svg
                className={styles.circle}
                style={{
                    transform: `translateY(${y - 10}px)`,
                }}
            >
                <circle cx="10" cy="10" r="4" />
            </svg>
            <svg
                className={cn(styles.circle, isTouching ? styles.highlighted : '')}
                style={{
                    bottom: -13,
                }}
            >
                <circle cx="10" cy="10" r="4" />
            </svg>
        </div>
    )
}
