import {Button} from '@design-system/button'
import {ModalLink} from '@design-system/modal'
import React from 'react'
import {Request} from '~/api/retail/types'
import {NatureAndPurposeStep, getNextStep, getPreviousStep} from '~/global/utils/nature-and-purpose/natureAndPurpose'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import HelpEmail from '~/global/widgets/help-email/HelpEmail'
import NatureAndPurposeForm from '~/global/widgets/nature-and-purpose-form/NatureAndPurpose'
import Page from '~/global/widgets/page/Page'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useNavigate} from '~/migrate-react-router'
import {connect} from '~/store/connect'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import actions from '~/store/nav/actions'
import styles from './NatureAndPurposeUpdate.scss'

export const NatureAndPurposeUpdate: React.FunctionComponent<NatureAndPurposeProps> = ({setNatureAndPurpose}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const [step, setStep] = React.useState<NatureAndPurposeStep>('intro')

    const preferredName = useAppSelector(({identity}) => identity.user?.preferred_name)

    React.useEffect(() => {
        dispatch(actions.HideFlyoutMenu())
    }, [])

    if (step === 'intro') {
        return (
            <>
                <Toolbar dataTestId="toolbar--n-and-p-update" />
                <Page>
                    <div className={styles.introPage}>
                        <h1 data-testid="title">
                            Kia ora {preferredName}. We need you to answer a few quick questions
                        </h1>
                        <p>
                            We were unable to accept the reason you gave us during sign up for how you intend to use
                            Sharesies. <NatureAndPurposeModal />
                        </p>
                        <p>We’ve emailed you to give you more details as to why that reason wasn’t acceptable.</p>
                        <p>
                            When you’re ready, go ahead and submit a new answer. If you have any questions around these
                            responses, let us know at <a href="mailto:help@sharesies.co.nz">help@sharesies.co.nz</a>.
                        </p>
                    </div>
                </Page>
                <ActionBar className={styles.actionBar}>
                    <Button
                        dataTestId="button--nature-and-purpose-update"
                        label="Next"
                        onClick={() => setStep(getNextStep(step))}
                    />
                </ActionBar>
            </>
        )
    }

    return (
        <div>
            <Toolbar
                dataTestId="toolbar--nature-purpose-how-you-invest"
                title="How you invest"
                leftButton="back"
                onLeftButtonClick={() => {
                    setStep(getPreviousStep(step))
                }}
            />
            <Page>
                <NatureAndPurposeForm
                    step={step}
                    setStep={setStep}
                    getNextStep={getNextStep}
                    setNatureAndPurpose={setNatureAndPurpose}
                    onSubmit={() => {
                        dispatch(actions.ShowFlyoutMenu())
                        navigate(profileUrl(''))
                    }}
                    isExistingUser={true}
                    forOrganisation={false}
                />
            </Page>
        </div>
    )
}

const NatureAndPurposeModal = () => {
    return (
        <ModalLink
            dataTestId="modal-link--nature-and-purpose"
            label="find out more"
            asIcon={true}
            modalTitle="Why we're asking"
            primaryButton={{label: 'Ok'}}
            helpIconSize={16}
        >
            <>
                <p>
                    This info helps us to fulfil our anti-money laundering and countering financing of terrorism
                    (AML/CFT) obligations.
                </p>
                <p>
                    Any questions, let us know at <HelpEmail />
                </p>
            </>
        </ModalLink>
    )
}

interface DispatchProps {
    setNatureAndPurpose(
        frequency: Request.NatureAndPurposeUpdate['frequency'],
        annual_amount: Request.NatureAndPurposeUpdate['annual_amount'],
        purpose: Request.NatureAndPurposeUpdate['purposes'],
        other_purpose: string,
    ): Promise<string | undefined>
}

export type NatureAndPurposeProps = DispatchProps

export default connect<{}, DispatchProps>(undefined, dispatch => ({
    setNatureAndPurpose: (frequency, annual_amount, purposes, other_purpose) =>
        dispatch(identityActions.UpdateNatureAndPurpose(frequency, annual_amount, purposes, other_purpose)),
}))(NatureAndPurposeUpdate)
