import {DateTime} from 'luxon'
import React from 'react'
import config from '~/configForEnv'
import {connect} from '~/store/connect'
import instrumentActions from '~/store/instrument/actions'
import {Instrument} from '~/store/instrument/types'
import ReFetch from './ReFetch'

const PriceHistoryRefetch: React.FunctionComponent<PriceHistoryRefetchProps> = React.memo(
    ({getPriceHistoryByInstrumentId, getSingleInstrumentById, instrument}) => {
        const fetchPriceHistory = async () => {
            if (
                instrument.priceHistory &&
                instrument.priceHistory.last &&
                instrument.marketLastCheck < DateTime.local().minus({hour: 1})
            ) {
                // Get price history since the last date polled
                await getPriceHistoryByInstrumentId(instrument.id, DateTime.fromISO(instrument.priceHistory.last))
                // using this action should update the market price in the store
                getSingleInstrumentById(instrument.id)
            }
        }
        return (
            <ReFetch
                fetchAction={fetchPriceHistory}
                timeoutPeriod={config.instrumentUpdateInterval} // 1 hour
                refetchDependsOn={[instrument]}
            />
        )
    },
)

type PriceHistoryRefetchProps = DispatchProps & OwnProps

interface DispatchProps {
    getPriceHistoryByInstrumentId(instrumentId: string, startDate: DateTime): Promise<void>
    getSingleInstrumentById(instrumentId: string): Promise<void>
}

interface OwnProps {
    instrument: Instrument
}

export default connect<{}, DispatchProps, OwnProps>(undefined, dispatch => ({
    getPriceHistoryByInstrumentId: (instrumentId, startDate) =>
        dispatch(instrumentActions.getPriceHistoryByInstrumentId(instrumentId, startDate)),
    getSingleInstrumentById: instrumentId => dispatch(instrumentActions.getSingleInstrumentById(instrumentId)),
}))(PriceHistoryRefetch)
