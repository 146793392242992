import {Button} from '@design-system/button'
import {Modal} from '@design-system/modal'
import {Thumbnail} from '@design-system/thumbnail'
import cn from 'classnames'
import Decimal from 'decimal.js'
import {withFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import {Link} from 'react-router-dom'
import {useIsFirstRender} from 'usehooks-ts'
import {DistillScope} from '~/api/query/distill'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import useDistillInstrumentInfo from '~/global/state-hooks/distill/useDistillInstrumentInfo'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {RadioEmpty, RadioFilled} from '~/global/widgets/OLD_icons'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {validate} from '~/global/widgets/form-controls'
import {DecimalInput} from '~/global/widgets/form-controls/formik'
import {PercentValue} from '~/global/widgets/number-elements/NumberElements'
import Page from '~/global/widgets/page/Page'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useDistillInstrument} from '~/sections/kiwisaver/data/distill'
import {usePlanEditorUrl} from '~/sections/kiwisaver/sections/edit-investment-plan/hooks/usePlanEditorUrl'
import styles from '~/sections/kiwisaver/sections/edit-investment-plan/index.scss'
import {setSelfSelectAllocations} from '~/sections/kiwisaver/sections/edit-investment-plan/mutate'
import {EditAllocationsMultibase} from '~/sections/kiwisaver/sections/edit-investment-plan/pages/edit-allocations/EditAllocationsMultibase'
import {
    Allocation,
    useExistingKSCustomer,
    useInvestmentPlanForEditing,
    useSetKSInvestmentPlanDraft,
} from '~/sections/kiwisaver/state'
import {useAppSelector} from '~/store/hooks'
import {actingAsID as actingAsIDSelector} from '~/store/identity/selectors'

const ALL_FUNDS_ID = 'all'

const InstrumentRow: React.FunctionComponent<{
    allocation: Allocation
    newPercentage?: Decimal
    onRemove: () => void
    onSelectPercentage: () => void
}> = ({allocation: {fund_id, allocation_percent}, newPercentage, onRemove, onSelectPercentage}) => {
    const instrument = useDistillInstrument({
        instrumentId: fund_id,
        scope: DistillScope.KIWISAVER_ALL_FUNDS,
    })
    const resourcePath = useDistillInstrumentInfo(DistillScope.KIWISAVER_ALL_FUNDS, {
        searchFundInvestments: true,
    }).filesHostAddress

    return (
        <div className={styles.instrument}>
            <Thumbnail
                dataTestId={`${instrument.urlSlug}--logo`}
                width="62px"
                height="62px"
                symbol={instrument.symbol}
                path={instrument.logos.micro ? `${resourcePath}${instrument.logos.micro}` : undefined}
                noBorder
            />
            <div>
                <h3>{instrument.name}</h3>
                {tradingType(instrument) !== 'managed' && !isInstrumentInNoTrade(instrument) && (
                    <h4>
                        <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                        <PronounceLetters text={instrument.exchange} />
                    </h4>
                )}
                <ButtonAsLink className={styles.instrumentRemove} onClick={onRemove}>
                    Remove
                </ButtonAsLink>
            </div>
            <div>
                <div className={styles.editAllocation} onClick={onSelectPercentage}>
                    {newPercentage?.toString() || allocation_percent}%
                </div>
            </div>
        </div>
    )
}

interface CustomAmountFormValues {
    amount: string
}

interface PercentageModalProps {
    isOpen: boolean
    onClose: () => void
    initial?: Decimal
    onSelect: (value: Decimal) => void
    maxPercent: Decimal
    minBaseFundPercentage: Decimal
    applyToAll: boolean
}

const PercentageModal = withFormik<PercentageModalProps, CustomAmountFormValues>({
    validateOnChange: true,
    mapPropsToValues: ({initial}) => {
        return {
            amount: initial?.toString() || '',
        }
    },
    handleSubmit: ({amount}, {props}) => {
        props.onSelect(new Decimal(amount))
        props.onClose()
    },
    validate: validate.generateWithProps<CustomAmountFormValues, PercentageModalProps>(props => {
        return {
            amount: [
                validate.required(),
                validate.minimum(0.01, 'Enter a percentage greater than 0.01%'),
                validate.maximum(
                    props.maxPercent.toNumber(),
                    `Enter a percentage below ${props.maxPercent}% (at least ${props.minBaseFundPercentage}% needs to be in your base fund).`,
                ),
            ],
        }
    }),
})(({
    handleSubmit,
    isOpen,
    onClose,
    onSelect,
    maxPercent,
    minBaseFundPercentage,
    initial,
    applyToAll,
    isValid,
    setFieldValue,
}) => {
    const customer = useExistingKSCustomer()
    const [usingCustomAmount, setUsingCustomAmount] = useState(false)

    const maxSelfSelectercentage = new Decimal(customer.min_base_fund_percentage)

    const options = [new Decimal(1), new Decimal(2), new Decimal(3), new Decimal(4), new Decimal(5)].filter(o =>
        o.lessThanOrEqualTo(maxPercent),
    )

    if (initial && !options.find(o => o.eq(initial))) {
        options.push(initial)
        options.sort()
    }

    const [selectedPercentage, setSelectedPercentage] = useState(initial)

    // Add the maximum percentage that can be allocated as an option, if it's less than 5 and more than the fixed options
    if (maxPercent.lt(5) && (options.length === 0 || options[options.length - 1].lt(maxPercent))) {
        options.push(maxPercent)
    }

    return (
        <Modal
            title="Amount"
            content={
                <div>
                    {maxPercent.eq(0) && (
                        <p>
                            {minBaseFundPercentage.toString()}% needs to be in your base fund. You can reduce the amount
                            allocated to other investments if you would like to add to this one.
                        </p>
                    )}

                    {maxPercent.gt(0) && (
                        <div>
                            {applyToAll && <p>What percentage would you like in these investments? </p>}
                            {!applyToAll && <p>What percentage would you like in this investment?</p>}

                            {!usingCustomAmount && (
                                <>
                                    <div className={spacing.spaceAbove16}>
                                        {options.map((o, index) => (
                                            <div
                                                key={index}
                                                className={styles.fakeRadio}
                                                onClick={() => {
                                                    setSelectedPercentage(o)
                                                    setFieldValue('amount', o.toString())
                                                }}
                                            >
                                                {selectedPercentage?.equals(o) ? (
                                                    <RadioFilled className={styles.selected} />
                                                ) : (
                                                    <RadioEmpty />
                                                )}{' '}
                                                {o.toString()}%
                                            </div>
                                        ))}

                                        <ButtonAsLink
                                            className={spacing.spaceAbove8}
                                            onClick={() => setUsingCustomAmount(true)}
                                        >
                                            Custom amount
                                        </ButtonAsLink>
                                    </div>

                                    {maxPercent.lt(5) &&
                                        (applyToAll ? (
                                            <p className={cn(styles.error, spacing.spaceAbove16)}>
                                                <PercentValue
                                                    noDp={maxSelfSelectercentage.round().eq(maxSelfSelectercentage)}
                                                    value={maxSelfSelectercentage.toString()}
                                                ></PercentValue>{' '}
                                                needs to be in your base fund, that means you can apply a maximum of{' '}
                                                <PercentValue
                                                    value={maxPercent.toString()}
                                                    noDp={maxPercent.round().eq(maxPercent)}
                                                ></PercentValue>{' '}
                                                to each investment.
                                            </p>
                                        ) : (
                                            <p className={cn(styles.error, spacing.spaceAbove16)}>
                                                <PercentValue
                                                    noDp={maxSelfSelectercentage.round().eq(maxSelfSelectercentage)}
                                                    value={maxSelfSelectercentage.toString()}
                                                ></PercentValue>{' '}
                                                needs to be in your base fund - you've got{' '}
                                                <PercentValue
                                                    value={maxPercent.toString()}
                                                    noDp={maxPercent.round().eq(maxPercent)}
                                                ></PercentValue>{' '}
                                                left to allocate.
                                            </p>
                                        ))}
                                </>
                            )}

                            {usingCustomAmount && (
                                <DecimalInput
                                    name="amount"
                                    autoFocus={true}
                                    dataTestId="ks-edit-allocations-custom-amount"
                                    decimalPlaces={2}
                                    autoComplete="off"
                                    normalisation="decimalOnly"
                                    helpText={`Add a number between 0.01% and ${maxPercent}%`}
                                />
                            )}
                        </div>
                    )}
                </div>
            }
            isOpen={isOpen}
            setIsOpen={v => !v && onClose()}
            onFormSubmit={handleSubmit}
            customZIndex={1051}
            primaryButton={{
                label: maxPercent.eq(0) ? 'Okay' : 'Done',
                disabled: usingCustomAmount ? !isValid : !selectedPercentage,
                onClick: () => selectedPercentage && onSelect(selectedPercentage),
            }}
            dataTestId="dockablemodal--percentage-selector"
        />
    )
})

const EditAllocations: React.FunctionComponent<{}> = () => {
    const customer = useExistingKSCustomer()
    const navigate = useNavigate()
    const relativeUrlFor = usePlanEditorUrl()
    const actingAsID = useAppSelector(actingAsIDSelector)
    const investmentPlan = useInvestmentPlanForEditing()
    const setInvestmentPlan = useSetKSInvestmentPlanDraft()
    const [removedFundIds, setRemovedFundIds] = React.useState<string[]>([])
    const [editingFundId, setEditingFundId] = React.useState<string | undefined>(undefined)
    const [newPercentages, setNewPercentages] = React.useState<{[fundId: string]: Decimal}>(
        Object.fromEntries(
            investmentPlan.allocations
                .filter(a => a.fund_category === 'SELF_SELECT')
                .map(a => [a.fund_id, new Decimal(a.allocation_percent)]),
        ),
    )
    const [isSubmitting, setIsSubmitting] = React.useState(false)

    const minBaseFundPercentage = new Decimal(customer.min_base_fund_percentage)
    const maxSelfSelectercentage = new Decimal(customer.max_self_select_percentage)
    const isFirstRender = useIsFirstRender()
    const [maxPercent] = React.useMemo(() => {
        let total = new Decimal(100).minus(minBaseFundPercentage)

        if (editingFundId === ALL_FUNDS_ID) {
            // Calculate the maximum amount per investment to the lowest 0.01
            total = total.dividedBy(Object.entries(newPercentages).length).mul(100).floor().dividedBy(100)
        } else {
            for (const [fundId, percent] of Object.entries(newPercentages)) {
                if (fundId === editingFundId || removedFundIds.includes(fundId)) {
                    // This one doesn't count because it's the one that's being edited
                    continue
                }
                total = total.minus(percent)
            }
        }

        if (total.greaterThanOrEqualTo(maxSelfSelectercentage)) {
            return [maxSelfSelectercentage]
        }

        return [total]
    }, [newPercentages, maxSelfSelectercentage, minBaseFundPercentage, editingFundId, removedFundIds])

    const canSubmit = React.useMemo(() => {
        for (const a of investmentPlan.allocations) {
            if (a.fund_category !== 'SELF_SELECT' || removedFundIds.includes(a.fund_id)) {
                continue
            }
            if (!newPercentages[a.fund_id] || newPercentages[a.fund_id].isZero()) {
                return false
            }
        }
        return true
    }, [newPercentages, removedFundIds, investmentPlan])

    const saveDraftPlan = async () => {
        setIsSubmitting(true)
        const values: {[fundId: string]: string} = {}
        for (const fundId in newPercentages) {
            if (removedFundIds.includes(fundId)) {
                continue
            }
            values[fundId] = newPercentages[fundId].toString()
        }

        try {
            await setInvestmentPlan.mutateAsync({
                acting_as_id: actingAsID,
                allocations: setSelfSelectAllocations(
                    investmentPlan.allocations.filter(a => !removedFundIds.includes(a.fund_id)),
                    values,
                ),
            })
        } catch (e) {
            Toast('Could not edit your plan')
        } finally {
            setIsSubmitting(false)
        }
    }

    const onSubmit = async () => {
        await saveDraftPlan()
        rudderTrack('kiwisaver_self_select', 'self_select_percentages_added', {
            type: customer.customer_state === 'SIGNUP' ? 'signup' : 'edit',
        })
        if (activeAllocations.length) {
            navigate(relativeUrlFor('risk-indication'))
        } else {
            // everything was removed, skip onwards to the edit investment plan home via PDS screen
            // which will only show up if there is a new version to accept
            navigate(relativeUrlFor('pds'))
        }
    }

    const activeAllocations = investmentPlan.allocations.filter(
        a => a.fund_category === 'SELF_SELECT' && !removedFundIds.includes(a.fund_id),
    )

    // Save the plan whenever a fund is removed from the list
    useEffect(() => {
        if (isFirstRender) {
            return
        }
        // When all funds are removed, save and go back
        if (activeAllocations.length === 0) {
            onSubmit()
        } else {
            saveDraftPlan()
        }
    }, [removedFundIds])

    // Save the plan whenever a percentage changes
    useEffect(() => {
        if (isFirstRender) {
            return
        }
        saveDraftPlan()
    }, [newPercentages])

    return (
        <>
            <Toolbar
                leftButton="back"
                dataTestId="toolbar--edit-allocations"
                title="What percentage would you like in each investment?"
            />
            <PercentageModal
                key={editingFundId}
                initial={editingFundId ? newPercentages[editingFundId] : undefined}
                isOpen={!!editingFundId}
                onClose={() => setEditingFundId(undefined)}
                maxPercent={maxPercent}
                minBaseFundPercentage={minBaseFundPercentage}
                applyToAll={editingFundId === ALL_FUNDS_ID}
                onSelect={value => {
                    if (!editingFundId) {
                        throw new Error("Can't set with no fund id")
                    }
                    if (editingFundId === ALL_FUNDS_ID) {
                        setNewPercentages(
                            activeAllocations.reduce(
                                (dict, allocation) => {
                                    dict[allocation.fund_id] = value
                                    return dict
                                },
                                {} as {[fundId: string]: Decimal},
                            ),
                        )
                    } else {
                        setNewPercentages(p => ({...p, [editingFundId]: value}))
                    }
                }}
            />
            <Page overrideDefaultTopPadding="withToolbarTitle">
                <p className={spacing.spaceBelow24}>
                    If you like you can&nbsp;
                    <ButtonAsLink onClick={() => setEditingFundId(ALL_FUNDS_ID)}>
                        apply the same amount to all
                    </ButtonAsLink>
                    .
                </p>

                {activeAllocations.map(a => {
                    return (
                        <InstrumentRow
                            key={a.fund_id}
                            allocation={a}
                            newPercentage={newPercentages[a.fund_id]}
                            onRemove={() => {
                                setRemovedFundIds(ids => ids.concat([a.fund_id]))
                            }}
                            onSelectPercentage={() => {
                                setEditingFundId(a.fund_id)
                            }}
                        />
                    )
                })}
                <div className={styles.addMorePicks}>
                    <Link to={relativeUrlFor('add-funds')}>+ Add more investments</Link>
                </div>
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--next"
                    label="Next"
                    disabled={!canSubmit}
                    processing={isSubmitting}
                    onClick={onSubmit}
                />
            </ActionBar>
        </>
    )
}

/* Temporary wrapper class to show the multibase version of this page
 * TODO(SUPERFUN-1614): Clean this up after launch
 */
const EditAllocationsWrapper: React.FC = () => {
    const hasMultibaseFlag = useAppSelector(state => 'kiwisaver_multibase' in state.identity.flags)

    return hasMultibaseFlag ? <EditAllocationsMultibase /> : <EditAllocations />
}

export {EditAllocationsWrapper as EditAllocations}
