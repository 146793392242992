import {useQueryClient} from '@tanstack/react-query'

/**
 * Invalidates entire save query cache
 * Will be used until transfer functions migrate to tanstack
 */
export const useInvalidateSaveCache = () => {
    const queryClient = useQueryClient()

    const re = /save\/v2/ // Matches all transaction-history queries

    return () =>
        queryClient.invalidateQueries({
            predicate: query => {
                if (typeof query.queryKey[0] !== 'string') {
                    return false
                }
                return re.test(query.queryKey[0])
            },
        })
}
