import {ChevronDown, ChevronUp} from '@design-system/icon'
import {RequireAtLeastOne} from '@design-system/type-utils'
import cn from 'classnames'
import React from 'react'
import {accessibility} from '~/global/scss/helpers'
import styles from './Concertina.scss'
interface InitialConcertinaProps {
    alignIconTop?: boolean
    title?: JSX.Element
    body?: JSX.Element
    footer?: JSX.Element
    border?: boolean
    borderLeft?: boolean
    noChevron?: boolean
    largeChevron?: boolean
    handleClick?: (isOpen: boolean) => void
    isOpen?: boolean
    isAnimated?: boolean
}
type ConcertinaProps = RequireAtLeastOne<InitialConcertinaProps, 'title' | 'footer'>

/**
 * A collapsible component that displays a title, body, and footer.
 * Title or Footer props are require at least one or the other.
 *
 * @param {ConcertinaProps} props - The props for configuring the Concertina component.
 * @returns {JSX.Element} - The rendered Concertina component.
 */
const Concertina: React.FunctionComponent<ConcertinaProps> = ({
    alignIconTop,
    title,
    body,
    footer,
    border,
    borderLeft,
    noChevron,
    largeChevron,
    handleClick,
    isAnimated = true,
    isOpen,
}) => {
    const [concertinaOpen, setConcertinaOpen] = React.useState(isOpen || false)
    const [concertinaClosing, setConcertinaClosing] = React.useState(false)
    const bodyRef = React.useRef<HTMLDivElement | null>(null)
    const chevronStyles = React.useMemo(
        () =>
            cn({
                [styles.noChevron]: noChevron,
                [styles.chevron]: !noChevron,
                [styles.chevronRight]: alignIconTop,
            }),
        [noChevron, alignIconTop],
    )

    const iconSize = React.useMemo(() => (largeChevron ? 20 : 12), [largeChevron])
    const ChevUp = <ChevronUp className={chevronStyles} size={iconSize} />
    const ChevDwn = <ChevronDown className={chevronStyles} size={iconSize} />
    const ariaControlLabel = title || footer

    const toggleButton = (toggleLabel: JSX.Element) => (
        <button
            type="button"
            className={cn(
                styles.toggleLabel,
                {
                    [styles.notClickableTitle]: noChevron,
                    [styles.alignToTop]: alignIconTop,
                },
                accessibility.button,
            )}
            onClick={() => {
                // used for recording the state of a concertina (i.e. if we want to remember if it was open when navigating pages)
                if (handleClick) {
                    handleClick(!concertinaOpen)
                }
                if (concertinaOpen) {
                    setConcertinaOpen(false)
                    setConcertinaClosing(true)
                    setTimeout(() => setConcertinaClosing(false), 200)
                } else {
                    setConcertinaOpen(true)
                }
            }}
        >
            {toggleLabel}
            {concertinaOpen ? ChevUp : ChevDwn}
        </button>
    )

    return (
        <div
            className={cn(styles.concertina, {[styles.border]: border})}
            aria-controls={ariaControlLabel + ' concertina'}
            aria-expanded={concertinaOpen}
            role="button"
        >
            {(!concertinaOpen || !footer) && title && toggleButton(title)}

            {(concertinaOpen || isAnimated) && (
                <div
                    className={cn(
                        styles.body,
                        {[styles.bodyOpen]: concertinaOpen},
                        {[styles.bodyClose]: !concertinaOpen},
                        {[styles.borderLeft]: borderLeft},
                    )}
                    aria-hidden={!concertinaOpen}
                    ref={bodyRef}
                >
                    {/* if the concertina is animated, only hide the body after x ms (animation duration), in order for the animation to work and
                        for the body text to be hidden once the concertina is closed (accessibility).*/}
                    {(!isAnimated || (isAnimated && (concertinaOpen || concertinaClosing))) && body}
                </div>
            )}

            {(concertinaOpen || !title) && footer && toggleButton(footer)}
        </div>
    )
}

export default Concertina
