import React from 'react'
import {useParams, Outlet} from 'react-router'
import {ProfileContext, useProfile} from '~/global/state-hooks/retail/useProfile'
import {ExchangeMoney} from '~/sections/wallet/pages/exchange-money/ExchangeMoney'
import {ExchangeMoneyConfirm} from '~/sections/wallet/pages/exchange-money-confirm/ExchangeMoneyConfirm'
import ExchangeMoneyIntro from '~/sections/wallet/pages/exchange-money-intro/ExchangeMoneyIntro'
import {Overview} from '~/sections/wallet/pages/overview/Overview'
import {Transaction} from '~/sections/wallet/pages/transaction/Transaction'
import {TopUp} from '~/sections/wallet/sections/top-up/TopUp'
import {TopUpWithBankDeposit} from '~/sections/wallet/sections/top-up/pages/top-up-with-bank-deposit/TopUpWithBankDeposit'
import {Withdrawals} from '~/sections/wallet/sections/withdrawals/Withdrawals'
import {WithdrawalConfirm} from '~/sections/wallet/sections/withdrawals/pages/confirm/Confirm'
import {SelectWithdrawalMethod} from '~/sections/wallet/sections/withdrawals/pages/select-withdrawal-method/SelectWithdrawalMethod'
import {VerifyWithdrawal} from '~/sections/wallet/sections/withdrawals/pages/verify-withdrawal/VerifyWithdrawal'
import {WalletPortfolioContext} from '~/sections/wallet/state/local'

/**
 * Ensure we handle invalid Wallet portfolio IDs gracefully and provide basic wallet context.
 */
export const WalletPortfolioWrapper = () => {
    const {portfolioId} = useParams()
    const profile = useProfile()
    const portfolio = profile.portfolios.find(portfolio => portfolio.id === portfolioId)

    if (!portfolio || portfolio.product !== 'WALLET') {
        throw new Error(`Invalid Wallet Portfolio ID ${portfolioId} in profile ${profile.id}`)
    }

    return (
        <ProfileContext.Provider value={profile}>
            <WalletPortfolioContext.Provider value={portfolio}>
                <Outlet />
            </WalletPortfolioContext.Provider>
        </ProfileContext.Provider>
    )
}

export const WALLET_ROUTES = [
    {
        path: ':portfolioId',
        element: <WalletPortfolioWrapper />,
        children: [
            {
                index: true,
                element: <Overview />,
            },
            {
                path: ':transactionKey',
                Component: (props: {transactionKey: string}) => <Transaction {...props} />,
            },
            {
                path: 'topup',
                element: <TopUp />,
            },
            {
                path: 'bank-transfer',
                element: <TopUpWithBankDeposit />,
            },
            {
                path: 'exchange-money',
                element: <ExchangeMoney />,
            },
            {
                path: 'exchange-money/intro',
                element: <ExchangeMoneyIntro />,
            },
            {
                path: 'exchange-money/confirm',
                element: <ExchangeMoneyConfirm />,
            },
            {
                path: 'withdrawal',
                element: <Withdrawals />,
            },
            {
                path: 'withdrawal/select',
                element: <SelectWithdrawalMethod />,
            },
            {
                path: 'withdrawal/confirm',
                element: <WithdrawalConfirm />,
            },
            {
                path: 'withdrawal/verify/:communicationType',
                Component: (props: {communicationType: string}) => <VerifyWithdrawal {...props} />,
            },
        ],
    },
] as const
