/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface InstrumentPurchasePricePeriodInfo
 */
export interface InstrumentPurchasePricePeriodInfo {
    /**
     *
     * @type {number}
     * @memberof InstrumentPurchasePricePeriodInfo
     */
    highest_price?: number
    /**
     *
     * @type {number}
     * @memberof InstrumentPurchasePricePeriodInfo
     */
    lowest_price?: number
    /**
     *
     * @type {number}
     * @memberof InstrumentPurchasePricePeriodInfo
     */
    average_price?: number
}

export function InstrumentPurchasePricePeriodInfoFromJSON(json: any): InstrumentPurchasePricePeriodInfo {
    return InstrumentPurchasePricePeriodInfoFromJSONTyped(json, false)
}

export function InstrumentPurchasePricePeriodInfoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): InstrumentPurchasePricePeriodInfo {
    if (json === undefined || json === null) {
        return json
    }
    return {
        highest_price: !exists(json, 'highest_price') ? undefined : json['highest_price'],
        lowest_price: !exists(json, 'lowest_price') ? undefined : json['lowest_price'],
        average_price: !exists(json, 'average_price') ? undefined : json['average_price'],
    }
}

export function InstrumentPurchasePricePeriodInfoToJSON(value?: InstrumentPurchasePricePeriodInfo | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        highest_price: value.highest_price,
        lowest_price: value.lowest_price,
        average_price: value.average_price,
    }
}
