import React from 'react'
import config from '~/configForEnv'
import essActions from '~/store/employeeShareScheme/actions'
import {possibleESS} from '~/store/employeeShareScheme/selectors'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import ReFetch from './ReFetch'

const ESSBlackoutPeriod: React.FunctionComponent = React.memo(() => {
    const dispatch = useAppDispatch()
    const fetchAction = () => dispatch(essActions.FetchBlackoutPeriods())

    const isActive = useAppSelector(s => s.identity.user && s.identity.user.state === 'active')
    const isPossibleEss = useAppSelector(s => possibleESS(s))

    return isActive && isPossibleEss ? (
        <ReFetch fetchAction={fetchAction} timeoutPeriod={config.essBlackoutPeriodRefetchInterval} />
    ) : (
        <></>
    )
})

export default ESSBlackoutPeriod
