import {atom} from 'jotai'
import {Request} from '~/api/retail/types'
import {TC_VERSION_TYPE} from '~/sections/user/sections/terms-and-conditions/widgets/document-wrappers/TermsAndConditions'

export interface PrescribedPerson {
    prescribedPerson: boolean
    participant: Request.FonterraEnablePersonalAccount['prescribed_participant']
    prescribedEmail: string
}

export interface NatureAndPurpose {
    frequency: Request.NatureAndPurposeUpdate['frequency']
    annualAmount: Request.NatureAndPurposeUpdate['annual_amount']
    purposes: Request.NatureAndPurposeUpdate['purposes']
    otherPurpose: string
}

export interface StagedFonterraPersonalSignUp {
    irdNumber: string
    tcVersion?: TC_VERSION_TYPE
    prescribedPerson: PrescribedPerson
    natureAndPurpose: NatureAndPurpose
    foreignTaxResident: boolean
    foreignTaxCountries: Request.FonterraEnablePersonalAccount['foreign_tax_countries']
}

export const stagedFonterraPersonalSignUpAtom = atom<StagedFonterraPersonalSignUp>({} as StagedFonterraPersonalSignUp)
