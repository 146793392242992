import {EmployeeShareSchemeParticipation} from '~/store/employeeShareScheme/types'

export const getMainSchemeTypeForInstrument = (
    shareSchemeParticipation: EmployeeShareSchemeParticipation,
    instrumentId: string,
) => {
    if (shareSchemeParticipation[instrumentId]) {
        const allParticipationSchemeTypes = shareSchemeParticipation[instrumentId].map(
            participation => participation.scheme_type,
        )

        allParticipationSchemeTypes.sort(compareSchemeTypeImportance)

        return allParticipationSchemeTypes[0]
    }
}

/*
 * When an Investor is a member of more than one share scheme for
 * the same instrument, we want to know the 'most important' scheme
 * type they are a member of for UI labels
 *
 * Ths function orders by most important first in the list
 */
const compareSchemeTypeImportance = (a: string | undefined, b: string | undefined) => {
    switch (a) {
        case 'ALIGNMENT_RIGHTS':
            // Most important
            return -1
        case 'RIGHTS':
            // Second most important
            if (b === 'ALIGNMENT_RIGHTS') {
                return 1
            }
            return -1
        case 'SHARES':
            // Lowest importance
            return 1
        case 'PHANTOM':
            // Currently not returned to the FE
            return 1
        default:
            // Unknown scheme type
            return 1
    }
}
