/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
 */
export interface AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails {
    /**
     *
     * @type {string}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    date: string
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    cost_basis: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    cost_basis_max?: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    weighted_price: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    simple_return: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    total_return: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    investment_value: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    capital_gain: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    capital_returned: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    currency_gain: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    dividends: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    realised_adr_fees: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    realised_currency_gain: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    realised_capital_gain: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    realised_cost_basis: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    realised_transaction_fees: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    shares_bought: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    shares_owned: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    shares_sold: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    total_transaction_fees: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    total_adr_fees: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    realised_managed_fund_transaction_fees: number
    /**
     *
     * @type {number}
     * @memberof AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails
     */
    unrealised_transaction_fees: number
}

export function AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetailsFromJSON(
    json: any,
): AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails {
    return AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetailsFromJSONTyped(json, false)
}

export function AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetailsFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails {
    if (json === undefined || json === null) {
        return json
    }
    return {
        date: json['date'],
        cost_basis: json['cost_basis'],
        cost_basis_max: !exists(json, 'cost_basis_max') ? undefined : json['cost_basis_max'],
        weighted_price: json['weighted_price'],
        simple_return: json['simple_return'],
        total_return: json['total_return'],
        investment_value: json['investment_value'],
        capital_gain: json['capital_gain'],
        capital_returned: json['capital_returned'],
        currency_gain: json['currency_gain'],
        dividends: json['dividends'],
        realised_adr_fees: json['realised_adr_fees'],
        realised_currency_gain: json['realised_currency_gain'],
        realised_capital_gain: json['realised_capital_gain'],
        realised_cost_basis: json['realised_cost_basis'],
        realised_transaction_fees: json['realised_transaction_fees'],
        shares_bought: json['shares_bought'],
        shares_owned: json['shares_owned'],
        shares_sold: json['shares_sold'],
        total_transaction_fees: json['total_transaction_fees'],
        total_adr_fees: json['total_adr_fees'],
        realised_managed_fund_transaction_fees: json['realised_managed_fund_transaction_fees'],
        unrealised_transaction_fees: json['unrealised_transaction_fees'],
    }
}

export function AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetailsToJSON(
    value?: AppSchemasForAdminPortfolioInstrumentInvestingHistoryDetails | null,
): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        date: value.date,
        cost_basis: value.cost_basis,
        cost_basis_max: value.cost_basis_max,
        weighted_price: value.weighted_price,
        simple_return: value.simple_return,
        total_return: value.total_return,
        investment_value: value.investment_value,
        capital_gain: value.capital_gain,
        capital_returned: value.capital_returned,
        currency_gain: value.currency_gain,
        dividends: value.dividends,
        realised_adr_fees: value.realised_adr_fees,
        realised_currency_gain: value.realised_currency_gain,
        realised_capital_gain: value.realised_capital_gain,
        realised_cost_basis: value.realised_cost_basis,
        realised_transaction_fees: value.realised_transaction_fees,
        shares_bought: value.shares_bought,
        shares_owned: value.shares_owned,
        shares_sold: value.shares_sold,
        total_transaction_fees: value.total_transaction_fees,
        total_adr_fees: value.total_adr_fees,
        realised_managed_fund_transaction_fees: value.realised_managed_fund_transaction_fees,
        unrealised_transaction_fees: value.unrealised_transaction_fees,
    }
}
