import cn from 'classnames'
import React from 'react'
import {spacing, typographyOverrides} from '~/global/scss/helpers'
import {useTelescope} from '~/sections/invest/sections/ai-search/hooks/useTelescope'
import FadeIn from '~/sections/invest/sections/ai-search/widgets/fade-in/FadeIn'
import loadingSkeletonStyles from '~/sections/landing/widgets/loading-skeleton/LoadingSkeleton.scss'
import style from '../../pages/intro/AiSearch.scss'

export const AiThesis: React.FC = () => {
    const {telescopeData, loadingState} = useTelescope()
    if (!telescopeData) {
        return <AiThesisLoadingState />
    }
    const {thesis} = telescopeData

    if (!thesis && loadingState !== 'processing') {
        // No results but finished loading.
        return <></>
    }
    if (!thesis) {
        return <AiThesisLoadingState />
    }

    if (telescopeData.errors && telescopeData.errors.length > 0) {
        return null
    }

    const thesisBulletPoints = thesis.split('\n').filter(item => item.trim().length > 0)

    return (
        <div className={cn(spacing.spaceAbove12, spacing.spaceBelow8)}>
            <h2 className={cn(spacing.spaceBelow8, typographyOverrides['as-h1'])}>
                <FadeIn key={telescopeData.emoji}>{telescopeData.emoji}</FadeIn> <FadeIn>{telescopeData.title}</FadeIn>
            </h2>
            <ul className={style.list}>
                {thesisBulletPoints.map((bulletPoint, i) => {
                    let bulletPointText = bulletPoint
                    if (bulletPointText.startsWith('- ')) {
                        bulletPointText = bulletPointText.substring(2)
                    }
                    return (
                        <FadeIn key={i}>
                            <li>
                                {bulletPointText.split(' ').map((bulletPointWord, i) => {
                                    return <FadeIn key={i}>{bulletPointWord} </FadeIn>
                                })}
                            </li>
                        </FadeIn>
                    )
                })}
            </ul>
        </div>
    )
}

const AiThesisLoadingState: React.FunctionComponent = () => {
    return (
        <div className={cn(spacing.spaceAbove12, spacing.spaceBelow8)}>
            <div className={cn(spacing.spaceBelow8, loadingSkeletonStyles.defaultLoadingPlaceholder)} />
        </div>
    )
}
