import cn from 'classnames'
import React from 'react'
import tooltipStyles from '~/global/scss/reused-styles/tooltip.scss'
import {convertDecimalToPercentage} from '~/global/utils/convert-decimal-to-percentage/convertDecimalToPercentage'
import {Period, periodInformation, getScreenReaderFriendlyTimePeriod} from '~/global/utils/time-period/timePeriod'
import {ArrowUp, ArrowDown} from '~/global/widgets/OLD_icons'
import {SharePriceValue, PercentValue} from '~/global/widgets/number-elements/NumberElements'
import Tooltip from '~/global/widgets/tooltip/Tooltip'
import labelledValuesStyles from '~/sections/invest/sections/view-instrument/LabelledValues.scss'
import {ComparisonPrice} from '~/store/instrument/types'

const PriceHistorySummary: React.FunctionComponent<PriceHistorySummaryProps> = ({period, comparisonPrices}) => {
    const {value, percent, max, min} = comparisonPrices || {
        value: undefined,
        percent: undefined,
        max: undefined,
        min: undefined,
    }

    const periodLabel = periodInformation[period].label

    return (
        <section className={cn(labelledValuesStyles.container, labelledValuesStyles.border)}>
            <div>
                <Tooltip>
                    <p className={cn(tooltipStyles.label, labelledValuesStyles.label)}>{period} low</p>
                    <div className={tooltipStyles.tooltip}>
                        <p>The investment’s lowest close price in the past {periodLabel}</p>
                    </div>
                </Tooltip>
                {min ? (
                    <p className={labelledValuesStyles.value}>
                        <SharePriceValue value={min} />
                    </p>
                ) : (
                    <p className={labelledValuesStyles.noData}>No {period} data</p>
                )}
            </div>
            <div>
                <Tooltip>
                    <p className={cn(tooltipStyles.label, labelledValuesStyles.label)}>{period} high</p>
                    <div className={tooltipStyles.tooltip}>
                        <p>The investment’s highest close price in the past {periodLabel}</p>
                    </div>
                </Tooltip>
                {max ? (
                    <p className={labelledValuesStyles.value}>
                        <SharePriceValue value={max} />
                    </p>
                ) : (
                    <p className={labelledValuesStyles.noData}>No {period} data</p>
                )}
            </div>
            <div>
                <p
                    className={labelledValuesStyles.label}
                    aria-label={`${getScreenReaderFriendlyTimePeriod(period)} price change`}
                >
                    {period} price change
                </p>
                {value && percent ? (
                    <p className={cn(labelledValuesStyles.value, labelledValuesStyles.priceChange)}>
                        <SharePriceValue value={value} /> <span className={labelledValuesStyles.partition}>|</span>
                        <PercentValue value={convertDecimalToPercentage(percent)} />
                        {parseFloat(value) > 0 && <ArrowUp />}
                        {parseFloat(value) < 0 && <ArrowDown />}
                    </p>
                ) : (
                    <p className={labelledValuesStyles.noData}>No {period} data</p>
                )}
            </div>
        </section>
    )
}

interface PriceHistorySummaryProps {
    period: Period
    comparisonPrices?: ComparisonPrice
}

export default PriceHistorySummary
