import {Button} from '@design-system/button'
import {useAtomValue, useSetAtom} from 'jotai'
import React from 'react'
import {useNavigate, useParams} from 'react-router'
import {useRetailGet, useRetailPost} from '~/api/query/retail'
import * as rollbar from '~/api/rollbar/rollbar'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {isPreferredNameValid} from '~/global/utils/preferred-name-validation/preferredNameValidation'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import Chips from '~/global/widgets/chips/Chips'
import {Text} from '~/global/widgets/form-controls'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import commonStyles from '~/sections/save/common.scss'
import {stagedNameAtom} from '~/sections/save/state/createAccountState'
import {SavePortfolio} from '~/sections/save/types'

const nameSuggestions = [
    'Rainy day fund',
    'Holiday',
    'Retirement',
    'Business costs',
    'Tax',
    'Car',
    'Wedding',
    'House deposit',
    'Pet',
    'Emergencies',
    'Kids',
    'Education',
]

const PersonaliseAccount: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const profile = useProfile()
    const profileUrl = useProfileUrl()
    const {portfolioId} = useParams()
    const setStagedName = useSetAtom(stagedNameAtom)
    const renameAccount = useRetailPost({
        path: 'save/v2/:portfolio_id/rename-account',
        pathParams: {portfolio_id: portfolioId!}, // portfolioId will ALWAYS be present if we're using this post
        queryCacheToUpdate: ['profiles'],
        queryCacheToInvalidate: [['save/v2/:portfolio_id', {portfolio_id: portfolioId!}]],
    })
    const {data: taxDetails} = useRetailGet({
        path: 'owner/:owner_id/tax-details',
        pathParams: {owner_id: profile.owner_id},
    })

    const savePortfolios = profile.portfolios.filter(p => p.product === 'SAVE') as SavePortfolio[]
    const selectedSavePortfolio = savePortfolios.find(p => p.id === portfolioId)
    const stagedName = useAtomValue(stagedNameAtom)

    const existingName = stagedName || selectedSavePortfolio?.name

    const [accountName, setAccountName] = React.useState<string>(existingName || '')
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<string | undefined>()

    const handleButtonClick = async () => {
        setIsLoading(true)

        if (!isPreferredNameValid(accountName)) {
            setError(
                "Looks like you are trying to set your Save account name to a non-individual entity, which we don't support",
            )
            setIsLoading(false)
            return
        }

        // Investor is setting up a new account
        if (!selectedSavePortfolio) {
            setStagedName(accountName)
            if (!taxDetails.rwt_rate || !taxDetails.ird_number) {
                return navigate(profileUrl('save/tax'))
            }

            if (savePortfolios.length > 0) {
                return navigate(profileUrl('save/kickstart'))
            } else {
                return navigate(profileUrl('save/confirm-account'))
            }
        }
        // Investor is changing the name of an existing Save account
        else {
            changeNameForExistingSaveAccount(selectedSavePortfolio.id)
        }
    }

    const changeNameForExistingSaveAccount = async (portfolioId: string) => {
        try {
            await renameAccount.mutateAsync(
                {name: accountName},
                {
                    onSuccess: () => {
                        Toast('You’ve updated your Save account name.')
                        navigate(profileUrl('save/manage/:portfolioId', {portfolioId}), {replace: true})
                    },
                    onError: response => {
                        if (response.type === 'error') {
                            setError(response.message)
                        } else {
                            setError('Please try again')
                        }
                    },
                },
            )
        } catch (error) {
            Toast('There was an error renaming your Save account')
            if (error instanceof Error && error.message) {
                rollbar.sendError(`save account rename: unexpected error`, {
                    message: error.message,
                })
            }
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Toolbar
                dataTestId="toolbar--save-name-account"
                title={selectedSavePortfolio ? 'Edit name' : 'Let’s get started'}
                leftButton="back"
            />
            <Page className={commonStyles.save}>
                <Text
                    dataTestId="text-input--save-name-account"
                    name="name"
                    onChange={e => {
                        setAccountName(e.target.value)
                        setError(undefined)
                    }}
                    isTouched={accountName !== undefined}
                    label={selectedSavePortfolio ? 'Name' : 'What are you saving for?'}
                    value={accountName}
                    placeholder={selectedSavePortfolio ? 'Save' : 'Can’t see it below? Type here'}
                    error={error}
                />
                {!selectedSavePortfolio && (
                    <Chips
                        options={nameSuggestions}
                        onChipClick={chip => {
                            setAccountName(chip)
                            setError(undefined)
                        }}
                        selected={[accountName]}
                    />
                )}
            </Page>
            <ActionBar>
                <Button
                    dataTestId="button--save-name-account"
                    label={selectedSavePortfolio ? 'Save' : 'Next'}
                    onClick={handleButtonClick}
                    processing={isLoading}
                />
            </ActionBar>
        </>
    )
}

export default PersonaliseAccount
