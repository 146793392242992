import {Button} from '@design-system/button'
import React from 'react'
import {Response} from '~/api/retail/types'
import {spacing} from '~/global/scss/helpers'
import {monthlyLabelGenerator} from '~/global/utils/monthly-label-generator/monthlyLabelGenerator'
import styles from '~/sections/kiwisaver/sections/activity/pages/activity/Activity.scss'
import {useKSCashActivityInfiniteQuery} from '~/sections/kiwisaver/state'
import CashActivityCard from '~/sections/kiwisaver/widgets/activity-card/CashActivityCard'

const CashMovementsActivity: React.FunctionComponent<{}> = () => {
    const result = useKSCashActivityInfiniteQuery({})

    const {data, fetchNextPage, hasNextPage, isFetchingNextPage} = result

    let activities: Response.KSCashMovementActivity['activity'] = []
    data?.pages.forEach(page => {
        activities = activities.concat(...page.activity)
    })

    const labelFor = monthlyLabelGenerator()

    return (
        <div data-testid="tab--cash-movements-activity">
            <>
                {activities.map(activity => {
                    const label = labelFor(activity.date)
                    return (
                        <div key={activity.id}>
                            {label && <div className={styles.date}>{label}</div>}
                            <CashActivityCard activity={activity} />
                        </div>
                    )
                })}
            </>

            {hasNextPage && (
                <Button
                    dataTestId="button--load-older-cash-movements"
                    label="Load older transactions"
                    onClick={() => fetchNextPage()}
                    processing={isFetchingNextPage}
                    additionalClassName={spacing.spaceAbove24}
                />
            )}
        </div>
    )
}
export default CashMovementsActivity
