import {MoreHorizontal, NotificationBell} from '@design-system/icon'
import cn from 'classnames'
import React from 'react'
import {accessibility} from '~/global/scss/helpers'
import {convertDecimalToPercentage} from '~/global/utils/convert-decimal-to-percentage/convertDecimalToPercentage'
import {formatNumber} from '~/global/utils/format-number/formatNumber'
import {isInstrumentInNoTrade} from '~/global/utils/instrument-trading-status/instrumentTradingStatus'
import {shareLabel} from '~/global/utils/share-label/shareLabel'
import {tradingType} from '~/global/utils/trading-type/tradingType'
import {ArrowDown, ArrowUp} from '~/global/widgets/OLD_icons'
import {ButtonAsLink} from '~/global/widgets/button-as-link/ButtonAsLink'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {PercentageInput} from '~/global/widgets/form-controls/formik'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {SharePriceValue, PercentValue, DollarValue, ShareValue} from '~/global/widgets/number-elements/NumberElements'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import type {ShowWatchlistBy} from '~/sections/explore/widgets/watchlist/Watchlist'
import WatchlistButton from '~/sections/explore/widgets/watchlist/WatchlistButton'
import {WatchlistContext} from '~/sections/explore/widgets/watchlist/state/WatchlistContext'
import AutoInvestSelectButton from '~/sections/invest/sections/auto-invest/widgets/auto-invest-select-button/AutoInvestSelectButton'
import {isDIY} from '~/store/autoinvest/types'
import {useAppSelector} from '~/store/hooks'
import {User} from '~/store/identity/types'
import {Instrument, TimePeriod, ComparisonTimePeriods} from '~/store/instrument/types'
import {AllocationCircle} from './AllocationCircle'
import styles from './InstrumentRow.scss'

const comparisonPricePrefix = (currentTimePeriod: TimePeriod) => `_${currentTimePeriod}` as ComparisonTimePeriods

interface InstrumentRowProps {
    allocation?: string
    currentSort?: string
    currentTimePeriod?: TimePeriod
    errors?: string
    footerButton?: {
        label: string
        onClick: () => void
    }
    hideBorder?: boolean
    hidePrice?: boolean
    hideSortDisplay?: boolean
    holdingShares?: string
    instrument: Instrument
    isAutoInvest?: boolean
    measuredWidth?: number
    onClick(): void
    percentageInputId?: string
    showWarningModal?: boolean
    fullCardNotClickable?: boolean
    autoInvestButtonRef?: React.RefObject<HTMLButtonElement>
    onMenuPress?: () => void
    priceNotification?: User['price_notifications']['']
    showWatchlistButton?: boolean
    watchlistIsShowing?: ShowWatchlistBy
    watchlistMovementDetails?: {
        percentageChange?: number
        label: string
    }
    oldLayout?: boolean
}

const InstrumentRow: React.FunctionComponent<InstrumentRowProps> = ({
    allocation,
    currentSort,
    currentTimePeriod,
    errors,
    footerButton,
    hideBorder,
    hidePrice,
    hideSortDisplay,
    holdingShares,
    instrument,
    isAutoInvest,
    measuredWidth,
    onClick,
    percentageInputId,
    fullCardNotClickable,
    autoInvestButtonRef,
    onMenuPress,
    priceNotification,
    showWatchlistButton,
    watchlistMovementDetails,
    watchlistIsShowing,
    oldLayout,
}) => {
    const {setTabName} = React.useContext(WatchlistContext)

    const currentPrice = parseFloat(instrument.marketPrice)
    const timePeriod = currentTimePeriod
        ? instrument.comparisonPrices[comparisonPricePrefix(currentTimePeriod)]
        : undefined
    const priceChange = timePeriod ? timePeriod.value : undefined
    const percentageChange = timePeriod ? timePeriod.percent : undefined

    const stagedOrder = useAppSelector(s => s.autoinvest.stagedOrder)
    const accountRestricted = useAppSelector(s => s.identity.user!.account_restricted)
    const [selected, setSelected] = React.useState(false)

    React.useEffect(() => {
        setSelected(
            Boolean(
                isAutoInvest &&
                    stagedOrder &&
                    isDIY(stagedOrder.order) &&
                    stagedOrder.order.allocations.find(f => f.fund_id === instrument.id),
            ),
        )
    }, [stagedOrder, instrument.id])

    const dimension = oldLayout ? '60px' : '48px'

    return (
        <div className={styles.instrumentWrapper}>
            <div
                onClick={onClick}
                className={cn(styles.listing, {
                    [styles.hideBorder]: hideBorder,
                    [styles.autoInvestSelected]: selected,
                    [styles.fullCardNotClickable]: fullCardNotClickable,
                    [styles.oldLayout]: oldLayout || isAutoInvest,
                })}
                data-testid={`instrument-row--${instrument.symbol}`}
            >
                <div className={styles.imageWrapper}>
                    <InstrumentLogo instrument={instrument} noBorder height={dimension} width={dimension} />
                </div>
                <button
                    type="button"
                    className={cn(styles.title, accessibility.button, {
                        [styles.fullCardNotClickable]: fullCardNotClickable,
                    })}
                    aria-hidden={fullCardNotClickable}
                    tabIndex={fullCardNotClickable ? -1 : undefined}
                >
                    <h3>{instrument.name}</h3>
                    {tradingType(instrument) !== 'managed' && !isInstrumentInNoTrade(instrument) && (
                        <h4>
                            <PronounceLetters text={instrument.symbol} /> <Delimiter />{' '}
                            <PronounceLetters text={instrument.exchange} />
                        </h4>
                    )}
                </button>
                {isAutoInvest && (
                    <AutoInvestSelectButton
                        accountRestricted={accountRestricted}
                        instrumentId={instrument.id}
                        instrumentName={instrument.name}
                        key={instrument.id}
                        selected={selected}
                        autoInvestButtonRef={autoInvestButtonRef}
                    />
                )}
                {allocation && (
                    <div className={cn(styles.right, styles.allocation)}>
                        <AllocationCircle allocation={allocation} />
                        <br />
                        {allocation}%
                    </div>
                )}
                <div className={styles.footer}>
                    {!hidePrice && !isAutoInvest && (
                        <>
                            {holdingShares ? (
                                <p className={styles.marketPrice}>
                                    <span className={styles.fullPrice}>
                                        <ShareValue value={holdingShares} />{' '}
                                        {shareLabel({instrument, isPlural: parseInt(holdingShares, 10) !== 1})}
                                    </span>
                                </p>
                            ) : (
                                <p className={styles.marketPrice}>
                                    <span
                                        className={styles.fullPrice}
                                        aria-label={`${shareLabel({
                                            instrument,
                                            isCapitalised: true,
                                        })} price $${currentPrice}`}
                                    >
                                        <SharePriceValue value={currentPrice} currency={instrument.currency} />
                                    </span>
                                    <span className={styles.shortPrice}>
                                        <SharePriceValue value={currentPrice} currency={instrument.currency} />
                                    </span>
                                </p>
                            )}
                        </>
                    )}
                    {footerButton && (
                        <ButtonAsLink
                            dataTestId="button--instrument-row-footer"
                            className={styles.removeButton}
                            onClick={footerButton.onClick}
                        >
                            {footerButton.label}
                        </ButtonAsLink>
                    )}

                    {percentageInputId && (
                        <div className={styles.percentageWrapper}>
                            <PercentageInput
                                name={percentageInputId}
                                label="What percentage do you want for this fund?"
                                dataTestId={`set-target-percentage--diy-allocation-${instrument.symbol}`}
                            />
                        </div>
                    )}

                    {!hideSortDisplay && !allocation && (
                        <>
                            {typeof percentageChange !== 'undefined' && typeof priceChange !== 'undefined' ? (
                                <p
                                    className={cn(styles.sortData, {
                                        [styles.zero]: priceChange === '0.00' || priceChange === '-0.00',
                                        [styles.sortDataShown]: currentSort === 'priceChange',
                                    })}
                                >
                                    {/* The device is bigger than iPhone 5 */}
                                    {measuredWidth && measuredWidth > 320 && (
                                        <>
                                            <SharePriceValue value={priceChange} />
                                            <span className={styles.divider}>|</span>
                                        </>
                                    )}
                                    <PercentValue value={convertDecimalToPercentage(percentageChange)} />
                                    <span className={styles.priceDirection}>
                                        {parseFloat(priceChange) > 0 && <ArrowUp />}
                                        {parseFloat(priceChange) < 0 && (
                                            <span className={styles.priceDirectionDown}>
                                                <ArrowDown />
                                            </span>
                                        )}
                                    </span>
                                </p>
                            ) : (
                                <p
                                    className={cn(styles.sortData, styles.zero, {
                                        [styles.sortDataShown]: currentSort === 'priceChange',
                                    })}
                                >
                                    No {currentTimePeriod} data
                                </p>
                            )}

                            {instrument.grossDividendYieldPercent ? (
                                <p
                                    className={cn(styles.sortData, {
                                        [styles.sortDataShown]: currentSort === 'dividends',
                                        [styles.zero]: parseFloat(instrument.grossDividendYieldPercent) === 0,
                                    })}
                                >
                                    <PercentValue
                                        value={convertDecimalToPercentage(instrument.grossDividendYieldPercent)}
                                    />
                                </p>
                            ) : (
                                <p
                                    className={cn(styles.sortData, styles.zero, {
                                        [styles.sortDataShown]: currentSort === 'dividends',
                                    })}
                                >
                                    No data
                                </p>
                            )}

                            {instrument.annualisedReturnPercent ? (
                                <p
                                    className={cn(styles.sortData, {
                                        [styles.sortDataShown]: currentSort === 'returns',
                                    })}
                                >
                                    <PercentValue
                                        value={convertDecimalToPercentage(instrument.annualisedReturnPercent)}
                                    />
                                </p>
                            ) : (
                                <p
                                    className={cn(styles.sortData, styles.zero, {
                                        [styles.sortDataShown]: currentSort === 'returns',
                                    })}
                                >
                                    No data
                                </p>
                            )}

                            {instrument.marketCap ? (
                                <p
                                    className={cn(styles.sortData, {
                                        [styles.sortDataShown]: currentSort === 'marketCap',
                                    })}
                                    aria-label={`Market cap $${formatNumber({
                                        number: instrument.marketCap.toString(),
                                        roundDown: true,
                                        decimalPlaces: 5,
                                    })}`}
                                >
                                    <DollarValue value={instrument.marketCap} />
                                </p>
                            ) : (
                                <p
                                    className={cn(styles.sortData, styles.zero, {
                                        [styles.sortDataShown]: currentSort === 'marketCap',
                                    })}
                                >
                                    No data
                                </p>
                            )}
                        </>
                    )}

                    {watchlistMovementDetails && watchlistIsShowing === 'percentageChange' && (
                        <div className={styles.percentageChange}>
                            {watchlistMovementDetails.percentageChange !== undefined && (
                                <span className={styles.percentageChangeAmount}>
                                    {watchlistMovementDetails.percentageChange > 0 && (
                                        <span className={styles.triangleUp} aria-label="Price change: up" />
                                    )}
                                    {watchlistMovementDetails.percentageChange < 0 && (
                                        <span className={styles.triangleDown} aria-label="Price change: down" />
                                    )}
                                    {watchlistMovementDetails.percentageChange === 0 && (
                                        <span aria-label="No price change" />
                                    )}

                                    <PercentValue value={watchlistMovementDetails.percentageChange} />
                                </span>
                            )}

                            <span className={styles.percentageChangeLabel}>{watchlistMovementDetails.label}</span>
                        </div>
                    )}
                </div>
                {errors && <div className={styles.error}>{errors}</div>}
            </div>

            {/* onMenuPress is passed in on the WatchList in order to show a DockableModal menu */}
            {onMenuPress ? (
                <>
                    <button
                        type="button"
                        className={cn(styles.menu, accessibility.button)}
                        onClick={onMenuPress}
                        aria-label="Show options"
                        title="Show options"
                        data-testid="notification-button"
                    >
                        <MoreHorizontal />
                    </button>

                    {priceNotification && watchlistIsShowing === 'priceNotification' && (
                        <p className={styles.priceNotification}>
                            <NotificationBell />${parseFloat(priceNotification.price_threshold).toFixed(3)}
                        </p>
                    )}
                </>
            ) : (
                <>
                    {!isAutoInvest && showWatchlistButton && (
                        <div className={styles.watchlistButton}>
                            <WatchlistButton
                                instrumentId={instrument.id}
                                instrumentName={instrument.name}
                                setTabName={setTabName}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default InstrumentRow
