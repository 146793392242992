import {useMatches} from 'react-router-dom'
import {CheckableRoute, PathParamKeys, PathParams, PathsFromRoutes} from '~/global/utils/routing/routing'
import {ROUTES} from './routes'

/**
 * The path templates for all routes you can navigate to. Excludes wildcard routes.
 */
export type ValidPaths = PathsFromRoutes<typeof ROUTES>

/**
 * Gets the URL of a given route. Statically ensures that the path is a real route and that all URL params are provided.
 */
export const urlFor = <P extends ValidPaths>(
    path: P,
    ...args: PathParamKeys<P> extends undefined ? [] : [PathParams<P>]
): string => {
    const arg = args[0]
    let url = `/${path}`
    if (arg) {
        url = url.replace(/(:\w+)/g, substring => {
            // look up the argument by slicing off the colon first
            return encodeURIComponent((arg as any)[substring.slice(1)])
        })
    }
    return url
}

/**
 * Creates a React hook that will provide a urlFor() function that works within a particular subtree of routes.
 *
 * This allows type-checked links within a subtree of routes without knowledge of where in the overall route tree these
 * routes are inserted. This is particularly useful if this subtree of routes is inserted in multiple places. The
 * KiwiSaver investment plan editor is a good example of this.
 *
 * @example
 * ```
 * const handle = Symbol()
 * const MY_ROUTES = { handle, children: [...] }
 * const useScopedUrlFor = createScopedUrlHook<typeof MY_ROUTES>(handle)
 * const Component = () => {
 *     const scopedUrlFor = useScopedUrlFor()
 *     return <Link to={scopedUrlFor('my-route')} />
 * }
 * ```
 */
export const createScopedUrlHook =
    <Route extends CheckableRoute>(handle: symbol) =>
    () => {
        const matches = useMatches()
        const match = matches.find(match => match.handle === handle)

        if (SHOWCASE) {
            // we sidestep the match check in Showcase and just return the child path
            return <Path extends PathsFromRoutes<Route['children']>>(
                path: Path,
                ...args: PathParamKeys<Path> extends undefined ? [] : [PathParams<Path>]
            ) => urlFor(path, ...args)
        }

        if (!match) {
            throw new Error(
                "Invalid Hook Usage: The hook you're trying to use provides a scoped urlFor function, which is " +
                    'designed for navigation within a specific subset of routes. It is not applicable on this page ' +
                    'as it falls outside of the subset. Please use this hook within the appropriate route subset.',
            )
        }

        return <Path extends PathsFromRoutes<Route['children']>>(
            path: Path,
            ...args: PathParamKeys<Path> extends undefined ? [] : [PathParams<Path>]
        ) => {
            let pathname = match.pathname
            if (pathname.endsWith('/')) {
                // Remove trailing slash. React-router's `useMatches` flattens a route object without `path` on it to '/'.
                // see src/page/Wallet/LinkedBankPayments/routes.tsx for an example of `handle` is on a route object without `path`.
                pathname = pathname.slice(0, -1)
            }
            return pathname + urlFor(path, ...args)
        }
    }
