import {Button} from '@design-system/button'
import Decimal from 'decimal.js'
import {Form, Formik} from 'formik'
import {useAtom} from 'jotai'
import {DateTime} from 'luxon'
import React from 'react'
import {Navigate, useNavigate} from 'react-router'
import {useRetailPost} from '~/api/query/retail'
import {Request} from '~/api/retail/types'
import {page, typographyOverrides} from '~/global/scss/helpers'
import {dateFormatShortDayFullMonth} from '~/global/utils/format-date/formatDate'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import AlertCard from '~/global/widgets/alert-card/AlertCard'
import Delimiter from '~/global/widgets/delimiter/Delimiter'
import {ExchangeHours} from '~/global/widgets/exchange-hours/ExchangeHours'
import recordStyles from '~/global/widgets/instrument-activity/Record.scss'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {DollarValue, ItemValue} from '~/global/widgets/number-elements/NumberElements'
import OrderConfirmation, {OrderRow} from '~/global/widgets/order-confirmation/OrderConfirmation'
import ExchangeOrderProcessMessage from '~/global/widgets/order-process-messages/ExchangeOrderProcessMessage'
import Page from '~/global/widgets/page/Page'
import PronounceLetters from '~/global/widgets/pronounce-letters/PronounceLetters'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import UpcomingDividendInformation from '~/global/widgets/upcoming-dividend-information/UpcomingDividendInformation'
import {useHoldingSummary} from '~/sections/moose/data/retail'
import {MooseOrderPathParams} from '~/sections/moose/routes'
import {buyOrderAtom} from '~/sections/moose/sections/order/state'
import {isUnsupportedInstrument} from '~/sections/moose/sections/order/utils/is-unsupported-instrument/isUnsupportedInstrument'
import {shareLabel} from '~/sections/moose/sections/order/utils/share-label/shareLabel'
import {BuyAndSellFeesForFcgInstrument} from '~/sections/moose/sections/order/widgets/buy-and-sell-fees-for-fcg-instrument/BuyAndSellFeesForFcgInstrument'
import {OrderError} from '~/sections/moose/sections/order/widgets/order-error/OrderError'
import {useFonterraPortfolio} from '~/sections/moose/state/local'
import {Instrument} from '~/store/instrument/types'
import styles from './TradingFacilityLimitBuyConfirmPage.scss'

type CreateBuyOrderResult = CreateBuyOrderFailure | CreateBuyOrderSuccess
interface CreateBuyOrderSuccess {
    success: true
}
interface CreateBuyOrderFailure {
    success: false
    code: string
}

interface ValidLimitBuyOrder {
    instrument: Instrument
    highestPricePerItem: Decimal
    numberOfItemsToBuy: number
    timeoutDateToUse: DateTime
    totalAmount: Decimal
    totalAmountBeforeFees: Decimal
    expectedFee: Decimal
}

export const TradingFacilityLimitBuyConfirmPage: React.FunctionComponent<MooseOrderPathParams> = ({
    portfolioId,
    urlSlug,
}: MooseOrderPathParams) => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const holdingSummary = useHoldingSummary(portfolioId)
    const [stagedBuyOrder, updateStagedBuyOrder] = useAtom(buyOrderAtom)
    const createBuyOrderQuery = useRetailPost({
        path: 'fonterra/portfolios/:portfolio_id/orders/create-buy',
        pathParams: {portfolio_id: portfolioId},
    })
    const walletPortfolio = useFonterraPortfolio().wallet

    if (isUnsupportedInstrument(urlSlug)) {
        return <Navigate to={profileUrl('fonterra/portfolios/:portfolioId', {portfolioId})} replace />
    }

    if (
        !stagedBuyOrder ||
        stagedBuyOrder.instrument.urlSlug !== urlSlug ||
        !stagedBuyOrder.pricePerItem ||
        !stagedBuyOrder.numberOfItems
    ) {
        return (
            <Navigate
                to={profileUrl('fonterra/portfolios/:portfolioId/invest/:urlSlug/buy/limit-order', {
                    portfolioId,
                    urlSlug,
                })}
                replace
            />
        )
    }

    if (
        !stagedBuyOrder.timeoutDateToUse ||
        !stagedBuyOrder.expectedFee ||
        !stagedBuyOrder.totalAmount ||
        !stagedBuyOrder.totalAmountBeforeFees
    ) {
        return (
            <Navigate
                to={profileUrl('fonterra/portfolios/:portfolioId/invest/:urlSlug/:action/time-limit', {
                    portfolioId,
                    urlSlug,
                    action: 'buy',
                })}
                replace
            />
        )
    }

    const createBuyOrder = async (stagedBuyOrder: ValidLimitBuyOrder): Promise<CreateBuyOrderResult> => {
        try {
            const payload: Request.FonterraPortfolioOrderCreateBuyRequest = {
                fund_id: stagedBuyOrder.instrument.id,
                expire_end_of_day: stagedBuyOrder.timeoutDateToUse.toISODate(),
                expected_fee: stagedBuyOrder.expectedFee.toString(),
                use_delayed_settlement: true,
                order: {
                    type: 'fonterra_portfolio_share_limit',
                    limit_volume: stagedBuyOrder.numberOfItemsToBuy,
                    limit_price: stagedBuyOrder.highestPricePerItem.toString(),
                },
            }

            await createBuyOrderQuery.mutateAsync(payload)
            return {success: true}
        } catch (error) {
            return {success: false, code: (error as any).code ?? 'unknown_error'}
        }
    }

    const validLimitBuyOrder: ValidLimitBuyOrder = {
        instrument: stagedBuyOrder.instrument,
        highestPricePerItem: stagedBuyOrder.pricePerItem,
        numberOfItemsToBuy: stagedBuyOrder.numberOfItems,
        timeoutDateToUse: stagedBuyOrder.timeoutDateToUse,
        totalAmount: stagedBuyOrder.totalAmount,
        totalAmountBeforeFees: stagedBuyOrder.totalAmountBeforeFees,
        expectedFee: stagedBuyOrder.expectedFee,
    }

    const itemType = stagedBuyOrder.itemType
    const orderCurrency = validLimitBuyOrder.instrument.currency
    const orderTimeOutdate = stagedBuyOrder.timeoutDateToUse.toFormat(dateFormatShortDayFullMonth)

    const orderItems: OrderRow[] = [
        {
            description: <>Highest price to pay per {shareLabel({itemType})}</>,
            value: <DollarValue value={validLimitBuyOrder.highestPricePerItem.toString()} currency={orderCurrency} />,
        },
        {
            description: <>Number of {shareLabel({itemType, isPlural: true})} to buy</>,
            value: <ItemValue value={stagedBuyOrder.numberOfItems} />,
        },
        {
            description: <BuyAndSellFeesForFcgInstrument />,
            value: 'Free',
        },
        {
            description: 'Total',
            value: <DollarValue value={validLimitBuyOrder.totalAmount.toString()} currency={orderCurrency} />,
        },
        {
            description: 'Your wallet',
            value: <DollarValue value={walletPortfolio.balance} currency={orderCurrency} />,
        },
    ]

    return (
        <>
            <Toolbar
                dataTestId="toolbar--trading-facility-limit-buy-confirm"
                leftButton="back"
                title={`Confirm your limit buy in ${shareLabel({itemType, isPlural: true})}`}
                rightSlot={
                    <>
                        <div className={styles.exchangeHours}>
                            <ExchangeHours instrument={stagedBuyOrder.instrument} />
                        </div>
                    </>
                }
            />
            <Page>
                <Formik
                    initialValues={{errorCode: null}}
                    onSubmit={async (_, actions) => {
                        actions.setFieldValue('errorCode', null)
                        const result = await createBuyOrder(validLimitBuyOrder)
                        if (result.success) {
                            const pagesInFlow = 3 + (stagedBuyOrder.pushedHistory || 0)
                            updateStagedBuyOrder(undefined)
                            Toast('We’re placing your order')
                            navigate(-pagesInFlow)
                        } else {
                            actions.setFieldValue('errorCode', result.code)
                        }
                    }}
                >
                    {({values, isSubmitting}) => (
                        <Form>
                            <div className={styles.content}>
                                <AlertCard type="warning">
                                    <h2>
                                        Pay{' '}
                                        <DollarValue
                                            value={validLimitBuyOrder.totalAmount
                                                .minus(new Decimal(walletPortfolio.balance))
                                                .toString()}
                                            currency={orderCurrency}
                                        />{' '}
                                        within 2 days
                                    </h2>
                                    <p>
                                        If you don’t pay the outstanding balance into your Wallet within 2 trading days,
                                        this trade will be reversed and you may incur fees.
                                    </p>
                                </AlertCard>
                                <div className={styles.orderForInfo}>
                                    Limit buy order for{' '}
                                    <span>
                                        {holdingSummary.entity_name} ({holdingSummary.farm_id})
                                    </span>
                                    , on market until close of market on {orderTimeOutdate} (New Zealand time).
                                </div>

                                <div className={styles.recieptContent}>
                                    <OrderConfirmation
                                        title={validLimitBuyOrder.instrument.name}
                                        subtitle={
                                            <p className={recordStyles.instrumentSymbol}>
                                                <PronounceLetters text={validLimitBuyOrder.instrument.symbol} />{' '}
                                                <Delimiter />{' '}
                                                <PronounceLetters text={validLimitBuyOrder.instrument.exchange} />
                                            </p>
                                        }
                                        image={<InstrumentLogo instrument={validLimitBuyOrder.instrument} noBorder />}
                                        items={orderItems}
                                        total={{
                                            description: 'Amount Due',
                                            value: (
                                                <DollarValue
                                                    value={validLimitBuyOrder.totalAmount
                                                        .minus(new Decimal(walletPortfolio.balance))
                                                        .toString()}
                                                    currency={orderCurrency}
                                                />
                                            ),
                                        }}
                                    />
                                </div>

                                <UpcomingDividendInformation verb="buy" instrument={validLimitBuyOrder.instrument} />

                                <div>
                                    <h3 className={typographyOverrides['as-h3']}>You agree that:</h3>
                                    <p>
                                        <ul>
                                            <li>you can only have one trading facility trade/order open at a time; </li>
                                            <li>
                                                you must deposit the amount that’s owed for the trade into your Wallet
                                                within 2 trading days;
                                            </li>
                                            <li>
                                                your Wallet will show a negative balance until you’ve paid the amount
                                                that’s owed. When you top up or receive money into your Wallet, we’ll
                                                use some (or all if relevant) as payment against your negative balance;
                                            </li>
                                            <li>
                                                until you’ve paid the amount that’s owed for the trade, you won’t be
                                                able to place another buy order;
                                            </li>
                                            <li>
                                                if you do not pay the full amount that’s owed for the trade within 2
                                                trading days:
                                            </li>
                                            <ul>
                                                <li>we may charge a late payment fee of $150; and</li>
                                                <li>
                                                    on settlement date we will transfer the FCG shares to be held by the
                                                    Fonterra Farmer Custodian Limited.
                                                </li>
                                            </ul>
                                        </ul>
                                    </p>

                                    <p>
                                        If you have not paid the full amount that’s owed for the trade within 5 trading
                                        days of placing the trade, <b>you agree that:</b>
                                        <ul>
                                            <li>
                                                we may sell all of the FCG shares involved in the trade to cover our
                                                costs, even if you have funded part of the trade using money from your
                                                Wallet (we will return any such money to your Wallet). You will be
                                                entitled to any surplus if we sell the FCG shares for more than they
                                                were bought for;
                                            </li>
                                            <li>
                                                you must pay any direct losses we incur as a result of your non-payment;
                                            </li>
                                            <li>
                                                you instruct Fonterra to recover our losses (including any late payment
                                                fee) on our behalf by: (i) Fonterra deducting the amount of such losses
                                                from any amounts that are payable by Fonterra to you, for milk supplied;
                                                and (ii) Fonterra paying such amounts to us instead. You confirm that
                                                you are authorised to provide this instruction to Fonterra on behalf of
                                                the Farm Entity <b>(Instruction)</b>;
                                            </li>
                                            <li>
                                                we are authorised to provide this Instruction to Fonterra on the Farm
                                                Entity’s behalf.
                                            </li>
                                        </ul>
                                    </p>
                                </div>

                                <ExchangeOrderProcessMessage
                                    instrument={validLimitBuyOrder.instrument}
                                    side="buy"
                                    isLimitOrder={true}
                                    isTriggerOrder={false}
                                    isForExtendedHours={false}
                                    cancelledAfterDays="5"
                                />
                            </div>

                            <ActionBar className={page.flexRow}>
                                <Button
                                    dataTestId="button--buy"
                                    label="Confirm buy"
                                    isSubmit
                                    processing={isSubmitting}
                                />
                            </ActionBar>
                            {values.errorCode && (
                                <OrderError
                                    errorCode={values.errorCode}
                                    instrumentName={stagedBuyOrder.instrument.name}
                                />
                            )}
                        </Form>
                    )}
                </Formik>
            </Page>
        </>
    )
}
