import {colour, colourLight, semanticColour} from '@design-system/colour-tokens'
import {useColourMode} from '@design-system/use-colour-mode'
import {interpolate} from 'd3-interpolate'
import * as d3select from 'd3-selection'
import * as d3shape from 'd3-shape'
import React from 'react'
import 'd3-transition'
import {PortfolioFilterOptions} from '~/store/identity/types'
import styles from './PieCharts.scss'

// Donut code used from: https://endjin.com/blog/2019/08/donut-chart-corkscrew-entry-animation-with-d3-js

// Codepen example: https://codepen.io/jongeorge1/pen/jONqjad

// In this example, each segment is animated in turn, meaning that it takes the same amount of time to animate in regardless of its size.
// This produces a slightly odd effect when a small segment is immediately followed by a large segment.

type ExtendedFilter = PortfolioFilterOptions | 'ETF'

interface AnimatedDonutProps {
    values: number[]
    filter: ExtendedFilter
}

export const AnimatedDonut: React.FunctionComponent<AnimatedDonutProps> = React.memo(({values, filter}) => {
    const colourMode = useColourMode()
    const countryTypeColours = [colourLight('Blueberry200'), colourLight('Blueberry400'), colourLight('Blueberry600')]
    const riskColours = [colourLight('Blueberry200'), colourLight('Blueberry400'), colourLight('Blueberry600')]
    const etfColors = [colour('Melon500', colourMode), semanticColour('FillSecondary', colourMode)]

    let colors = countryTypeColours

    switch (filter) {
        case 'COUNTRY' || 'TYPE':
            colors = countryTypeColours
            break
        case 'RISK':
            colors = riskColours
            break
        case 'ETF':
            colors = etfColors
            break
        default:
            colors = countryTypeColours
    }

    const sizes = {
        innerRadius: 50,
        outerRadius: 100,
    }

    const durations = {
        entryAnimation: 750,
    }

    React.useEffect(() => {
        draw()
    }, [filter])

    const draw = () => {
        d3select.select('#chart').html('')

        const generator = d3shape.pie().sort(null)

        const chart = generator(values)

        // a group is appended ('g') to the svg
        // move the chart to the center of the space
        // then bind the data to the chart
        const arcs = d3select
            .select('#chart')
            .append('g')
            .attr('transform', 'translate(100, 100)')
            .selectAll('path')
            .data(chart)
            .enter()
            .append('path')
            .style('fill', (_d: any, i: any) => colors[i])

        // This will allow us to calculate when to start drawing each segment, and how much of the segment to draw.
        const angleInterpolation = interpolate(generator.startAngle()([0]), generator.endAngle()([0]))

        const arc = d3shape.arc()

        // This transition is responsible for drawing the arcs.
        arcs.transition()
            .duration(durations.entryAnimation)
            .attrTween('d', d => {
                const originalEnd = d.endAngle
                const arcCopy: d3shape.DefaultArcObject = {
                    ...d,
                    innerRadius: sizes.innerRadius,
                    outerRadius: sizes.outerRadius,
                }

                return (t: number) => {
                    const currentAngle = angleInterpolation(t)
                    if (currentAngle < d.startAngle) {
                        return ''
                    }

                    arcCopy.endAngle = Math.min(currentAngle, originalEnd)

                    return `${arc(arcCopy)}`
                }
            })
    }

    return (
        <div className={styles.donut}>
            <svg id="chart" preserveAspectRatio="xMinYMin meet" viewBox="0 0 200 200" />
        </div>
    )
})
