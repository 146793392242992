import Decimal from 'decimal.js'
import {AnyCurrency} from '~/global/utils/currency-details/currencyDetails'

export const formatNumberWithThousandsSeparator = (number: string, isTyping?: boolean) => {
    const [integer, fraction] = number.split('.')
    const integerWithThousandSeparator = integer.replace(/\B(?=(\d{3})+$)/g, ',')

    if (isTyping) {
        return number.includes('.') ? `${integerWithThousandSeparator}.${fraction}` : integerWithThousandSeparator
    }

    return fraction ? `${integerWithThousandSeparator}.${fraction}` : integerWithThousandSeparator
}

// The replace(/ /g, '\u00a0') injects a non breaking space
export const formatNumberWithCurrency = (text: string, currency?: string) =>
    currency ? `${text} ${currency.toLocaleUpperCase()}`.replace(/ /g, '\u00a0') : text

// It is used by `NumberElements` to format the different type of numbers
// with dynamic rounding rules and number of decimal places
export const formatNumber = ({
    number,
    roundDown,
    decimalPlaces = 2,
    useNumericWords = true,
}: {
    number: string
    roundDown?: boolean
    decimalPlaces?: number
    useNumericWords?: boolean
}) => {
    const comparableNumber = Number(number)

    if (useNumericWords) {
        // to 5 significant digits, round half up
        // e.g. 123,374,287.483 -> 123.37 million
        if (comparableNumber > 1e12) {
            const decimal = new Decimal(number).div(1e12).toSignificantDigits(5).toDecimalPlaces(2)
            return `${decimal} trillion`
        }

        if (comparableNumber > 1e9) {
            const decimal = new Decimal(number).div(1e9).toSignificantDigits(5).toDecimalPlaces(2)
            return `${decimal} billion`
        }

        if (comparableNumber > 1e6) {
            const decimal = new Decimal(number).div(1e6).toSignificantDigits(5).toDecimalPlaces(2)
            return `${decimal} million`
        }
    }

    // number < 1e6
    const roundingRule = roundDown ? Decimal.ROUND_DOWN : Decimal.ROUND_HALF_UP
    const decimal = new Decimal(number).toFixed(decimalPlaces, roundingRule)
    return formatNumberWithThousandsSeparator(decimal.toString())
}

export const formatNumberWithoutTrailingZeros = ({
    number,
    minDecimalPlaces,
    maxDecimalPlaces,
}: {
    number: string
    minDecimalPlaces: number
    maxDecimalPlaces: number
}) => {
    const decimal = new Decimal(number).toDP(maxDecimalPlaces, Decimal.ROUND_HALF_UP)

    return formatNumberWithThousandsSeparator(
        decimal.dp() < minDecimalPlaces ? decimal.toFixed(minDecimalPlaces) : decimal.toString(),
    )
}

export const formatScaledNumberWithCurrency = ({
    value,
    currency,
    rounding = Decimal.ROUND_HALF_UP,
    minimumDecimalPlaces,
    maximumDecimalPlaces,
    useNumericWords = true,
}: {
    value: string
    currency: AnyCurrency
    rounding?: Decimal.Rounding
    minimumDecimalPlaces?: number
    maximumDecimalPlaces: number
    useNumericWords?: boolean
}) => {
    let suffix = ''
    if (useNumericWords) {
        const comparableNumber = Number(value)
        // First, scale the number down (the `currency` Intl.NumberFormat doesn't like outputting "$X million", so we do it ourselves)
        // to 6 significant digits
        // e.g. 123,374,287.483 -> 123.374 million
        if (comparableNumber > 1e12) {
            value = new Decimal(value).div(1e12).toString()
            suffix = ' trillion'
        }

        if (comparableNumber > 1e9) {
            value = new Decimal(value).div(1e9).toString()
            suffix = ' billion'
        }

        if (comparableNumber > 1e6) {
            value = new Decimal(value).div(1e6).toString()
            suffix = ' million'
        }
    }

    //  Intl's roundingMode parameter is relatively unsupported, so handle round ourselves
    value = new Decimal(value).toDecimalPlaces(maximumDecimalPlaces, rounding).toString()

    const formattedValue = Number(value).toLocaleString('en-NZ', {
        style: 'currency',
        currency,
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: minimumDecimalPlaces,
        maximumFractionDigits: maximumDecimalPlaces,
    })

    return `${formattedValue}${suffix} ${currency.toLocaleUpperCase()}`
}
