import cn from 'classnames'
import React from 'react'
import {useNavigate} from 'react-router'
import {rudderTrack} from '~/api/rudderstack/rudderstack'
import {spacing} from '~/global/scss/helpers'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {CircleIcon} from '~/global/widgets/circle-icon/CircleIcon'
import Image from '~/global/widgets/image/Image'
import {ListMenuExpanded} from '~/global/widgets/list-menu-expanded/ListMenuExpanded'
import {useAppSelector} from '~/store/hooks'
import nextStepsDark from './assets/images/next-steps-dark.svg'
import nextStepsLight from './assets/images/next-steps-light.svg'
import styles from './PortfolioNextSteps.scss'

const PortfolioNextSteps: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate()
    const profileUrl = useProfileUrl()
    const jurisdiction = useAppSelector(s => s.identity.user?.jurisdiction)

    return (
        <div className={styles.portfolioNextStepsContainer} data-testid="nextsteps--wrapper">
            <div className={cn(spacing.spaceAbove24, spacing.spaceBelow24, styles.nextstepsHeaderBox)}>
                <Image alt="" className={spacing.spaceBelow12} src={nextStepsLight} darkSrc={nextStepsDark} />

                <h2 className={styles.infoMessage}>
                    This is where your investments go.
                    <br /> To get started, you could:
                </h2>
            </div>

            <div className={styles.portfolioNextStepsBody}>
                <ListMenuExpanded
                    onClick={() => {
                        rudderTrack('navigation', 'invest_portfolio_get_started_link_clicked', {
                            feature: 'explore',
                        })
                        navigate(profileUrl('invest/search'))
                    }}
                    icon={<CircleIcon icon="OrderBuy" />}
                    additionalClassName={styles.featureLinkClass}
                    displayChevron={false}
                    dataTestId="nextsteps--invest"
                    title="Buy Investments"
                    description={
                        jurisdiction === 'nz'
                            ? `Explore companies and funds across NZ, US, and Australian exchanges`
                            : `Check out companies and funds listed on US, Aussie, and NZ exchanges`
                    }
                />
                <ListMenuExpanded
                    onClick={() => {
                        rudderTrack('navigation', 'invest_portfolio_get_started_link_clicked', {
                            feature: 'autoinvest',
                        })
                        navigate(profileUrl('auto-invest'))
                    }}
                    icon={<CircleIcon icon="AutoInvest" />}
                    additionalClassName={styles.featureLinkClass}
                    dataTestId="nextsteps--auto-invest"
                    title="Set up auto-invest"
                    displayChevron={false}
                    description={
                        jurisdiction === 'nz'
                            ? 'Invest regularly in your own choice of investments, or a pre-made order'
                            : 'Put your investing on repeat—just pick the investments, how much, and when'
                    }
                />
                <ListMenuExpanded
                    onClick={() => {
                        rudderTrack('navigation', 'invest_portfolio_get_started_link_clicked', {
                            feature: 'transfer',
                        })
                        navigate(profileUrl('invest/transfer-shares'))
                    }}
                    icon={<CircleIcon icon="ShareTransfer" />}
                    displayChevron={false}
                    additionalClassName={styles.featureLinkNoBorderClass}
                    dataTestId="nextsteps--transfer-shares"
                    title="Transfer shares"
                    description={
                        jurisdiction === 'nz'
                            ? 'Transfer NZ, US, or Australian shares you hold elsewhere into Sharesies'
                            : 'Bring US, Aussie, and NZ shares you already own into Sharesies'
                    }
                />
            </div>
        </div>
    )
}

export default PortfolioNextSteps
