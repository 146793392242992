import {HelpCircle} from '@design-system/icon'
import React from 'react'
import {Link} from 'react-router-dom'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import styles from './CantSeeBank.scss'

const CantSeeBank = () => {
    const profileUrl = useProfileUrl()

    return (
        <p className={styles.cantSeeBank}>
            <Link
                to={profileUrl('wallet/instant-transfer/suggest-banks')}
                aria-label="Can't see your bank, you can suggest it here."
            >
                Can’t see your bank? <HelpCircle size={16} />
            </Link>
        </p>
    )
}

export default CantSeeBank
