import React from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import {useProfile} from '~/global/state-hooks/retail/useProfile'
import {isWrapperApp} from '~/global/utils/is-wrapper-app/isWrapperApp'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'

const useShouldRedirectToLandingForMobileUser = () => {
    const hasHistory = window.length > 1
    // if no history redirect to Landing
    return isWrapperApp() && !hasHistory
}

/**
 * Sharesies changed the terminology from "Settings" to "Account", along with all of the routes. Then we changed it back
 * again. This redirect ensures old URLs, particularly from the native apps, continue to work.
 *
 * Note there is one route, profile/:slug/account/personal-details, that still exists under its original URL. This page
 * was split into personal-details (owner properties) and contact-details (actor properties) during this URL change. The
 * original combined page is retained under its original URL for native app compatibility.
 */
export const AccountRouteRedirect: React.FunctionComponent<{}> = () => {
    const location = useLocation()
    return <Navigate to={location.pathname.replace('/account', '/settings')} replace />
}

/**
 * Sharesies has changed the naming of the Portfolio to Invest, along with
 * all of the routes. This maintains the functionality of any old /portfolio
 * links.
 */
export const PortfolioRouteRedirect: React.FunctionComponent<{}> = () => {
    const location = useLocation()
    const profileUrl = useProfileUrl()
    // MOB-1379 - temporary fix for native apps which automatically direct user to /portfolio
    if (useShouldRedirectToLandingForMobileUser()) {
        return <Navigate to={profileUrl('')} replace />
    } else {
        return <Navigate to={location.pathname.replace('/portfolio', '/invest')} replace />
    }
}

/***
 * We have removed the /save route and replaced it with the /save/:saveAccountId
 * route.  The native explore page has a card sending mobile users to the old
 * route, so we'll redirect them to where it should go
 */
export const SaveRouteRedirect: React.FunctionComponent<{}> = () => {
    const profileUrl = useProfileUrl()
    return <Navigate to={profileUrl('save/intro')} replace />
}

/***
 * There's a few places in the app that navigate directly to wallet/topup, however
 * the wallet topup page now sits in wallet/:portfolioId/topup.
 */
export const WalletTopupRedirect: React.FunctionComponent<{}> = () => {
    const profileUrl = useProfileUrl()
    const profile = useProfile()
    const walletPortfolioId = profile.portfolios.find(p => p.product === 'WALLET')?.id
    if (!walletPortfolioId) {
        throw new Error(`No Wallet portfolio found in profile ${profile.id}`)
    }
    return <Navigate to={profileUrl('wallet/:portfolioId/topup', {portfolioId: walletPortfolioId})} replace />
}

/***
 * We changed the URL for Wallet to Save transfers in mobile to wallet/transfer
 * however iOS versions 14.3 and older will still be navigating to save/transfer.
 * We need to redirect these customers.
 */
export const SaveTransferRedirect: React.FunctionComponent<{}> = () => {
    const profileUrl = useProfileUrl()
    return <Navigate to={profileUrl('wallet/transfer')} replace />
}

/**
 * Sharesies moved the Transfers section out of 'Account' and into 'Investment'.
 * These redirects will catch any old routes still using 'settings/...'
 */
export const TransferRouteRedirect: React.FunctionComponent<{}> = () => {
    const location = useLocation()
    return <Navigate to={location.pathname.replace('/settings/transfer-shares', '/invest/transfer-shares')} replace />
}

/**
 * With the above change, using 'invest' as a prefix we need to make
 * any routes using 'investments' consistent so this redirect catches
 * apps using the old route
 */
export const InvestmentsRouteRedirect: React.FunctionComponent<{}> = () => {
    const location = useLocation()
    const profileUrl = useProfileUrl()
    // MOB-1379 - temporary fix for native apps which automatically direct user to /portfolio
    if (useShouldRedirectToLandingForMobileUser()) {
        return <Navigate to={profileUrl('')} replace />
    } else {
        return <Navigate to={location.pathname.replace('/investments', '/invest')} replace />
    }
}

/**
 * Making new 'explore' routes for the watchlist requires a redirect
 * for any devices using old routes
 */
export const ExploreWatchlistRedirect: React.FunctionComponent<{}> = () => {
    const location = useLocation()
    return <Navigate to={location.pathname.replace('/invest/watchlist', '/explore/watchlist')} replace />
}

/**
 * Making new 'explore' routes for the recently-viewed requires a redirect
 * for any devices using old routes
 */
export const ExploreRecentlyViewedRedirect: React.FunctionComponent<{}> = () => {
    const location = useLocation()
    return <Navigate to={location.pathname.replace('/invest/recently-viewed', '/explore/recently-viewed')} replace />
}

/**
 * iOS Explore content cards require URLs to be nested under /learn/articles/*
 * making a redirect for US sign up means that client navigation behaves as expected
 */
export const USSignUpRedirect: React.FunctionComponent<{}> = () => {
    return <Navigate to="/us-sign-up" replace />
}

/**
 * We decided to remove explore/search/* and move to invest/search.
 */
export const ExploreSearchRedirect: React.FunctionComponent<{}> = () => {
    const location = useLocation()
    return <Navigate to={location.pathname.replace('/explore/search', '/invest/search')} replace />
}

/**
 * New CSN Transfer route for changes to the CSN transfer flow
 */
export const CsnTransferRedirect: React.FunctionComponent<{}> = () => {
    const location = useLocation()
    return (
        <Navigate
            to={location.pathname.replace('/invest/transfer-shares', '/invest/portfolio-transfer-shares')}
            replace
        />
    )
}

// This path has been sent out to NZO investors, we need to support it
export const CsnTransferDirectionRedirect: React.FunctionComponent<{}> = () => {
    const location = useLocation()
    return (
        <Navigate
            to={location.pathname.replace(
                '/invest/transfer-shares/transfer-direction',
                '/invest/portfolio-transfer-shares/transfer-direction',
            )}
            replace
        />
    )
}
