import {useEffect} from 'react'
import accountingActions from '~/store/accounting/actions'
import {useAppDispatch, useAppSelector} from '~/store/hooks'

export const useExchangeFeeRate = () => {
    const fee = useAppSelector(s => s.accounting.exchangeFeeRate)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!fee) {
            dispatch(accountingActions.GetExchangeFeeRate())
        }
    }, [fee])
    return fee
}
