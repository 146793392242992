/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * rakaia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {Instrument, InstrumentFromJSON, InstrumentFromJSONTyped, InstrumentToJSON, InstrumentToJSONTyped} from './'

/**
 *
 * @export
 * @interface Instruments
 */
export interface Instruments {
    /**
     *
     * @type {Array<Instrument>}
     * @memberof Instruments
     */
    instruments: Array<Instrument>
}

export function InstrumentsFromJSON(json: any): Instruments {
    return InstrumentsFromJSONTyped(json, false)
}

export function InstrumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Instruments {
    if (json === undefined || json === null) {
        return json
    }
    return {
        instruments: (json['instruments'] as Array<any>).map(InstrumentFromJSON),
    }
}

export function InstrumentsToJSON(value?: Instruments | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        instruments: (value.instruments as Array<any>).map(InstrumentToJSON),
    }
}
