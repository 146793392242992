import cn from 'classnames'
import React from 'react'
import {spacing} from '~/global/scss/helpers'
import tooltipStyles from '~/global/scss/reused-styles/tooltip.scss'
import {SharePriceValue} from '~/global/widgets/number-elements/NumberElements'
import Tooltip from '~/global/widgets/tooltip/Tooltip'
import labelledValuesStyles from '~/sections/invest/sections/view-instrument/LabelledValues.scss'
import layoutStyles from '~/sections/invest/sections/view-instrument/ViewInstrument.scss'
import {useUsLivePricing} from '~/sections/invest/sections/view-instrument/hooks/usLivePricing'
import {useAppSelector} from '~/store/hooks'
import {selectHasUsLivePricing} from '~/store/identity/selectors'
import {Instrument} from '~/store/instrument/types'

interface BidAndOfferProps {
    instrument: Instrument
}

const BidAndOffer: React.FunctionComponent<BidAndOfferProps> = ({instrument}) => {
    const usLivePricing = useUsLivePricing(instrument)

    if (!usLivePricing) {
        return null
    }

    return (
        <>
            <h2 className={cn(layoutStyles.sectionHeader, spacing.spaceAbove32)}>Bid and offer</h2>
            <section className={cn(labelledValuesStyles.container, labelledValuesStyles.border, spacing.spaceAbove16)}>
                <div>
                    <Tooltip>
                        <Tooltip.Label className={labelledValuesStyles.label}>Top bid</Tooltip.Label>
                        <Tooltip.Body className={tooltipStyles.tooltip}>
                            <p>The highest price investors are currently willing to pay per share.</p>
                        </Tooltip.Body>
                    </Tooltip>
                    {parseFloat(usLivePricing.bidPrice) > 0 ? (
                        <p className={labelledValuesStyles.value}>
                            <SharePriceValue value={usLivePricing.bidPrice} />
                        </p>
                    ) : (
                        <p className={labelledValuesStyles.noData}>No data</p>
                    )}
                </div>
                <div>
                    <Tooltip>
                        <Tooltip.Label className={labelledValuesStyles.label}>Top offer</Tooltip.Label>
                        <Tooltip.Body className={tooltipStyles.tooltip}>
                            <p>The lowest price shareholders are currently willing to sell at, per share.</p>
                        </Tooltip.Body>
                    </Tooltip>
                    {parseFloat(usLivePricing.askPrice) > 0 ? (
                        <p className={labelledValuesStyles.value}>
                            <SharePriceValue value={usLivePricing.askPrice} />
                        </p>
                    ) : (
                        <p className={labelledValuesStyles.noData}>No data</p>
                    )}
                </div>
            </section>
        </>
    )
}

const BidAndOfferWrapper: React.FunctionComponent<BidAndOfferProps> = ({instrument}) => {
    const hasUsLivePricing = useAppSelector(s => selectHasUsLivePricing(s))

    if (!hasUsLivePricing) {
        return null
    }

    return (
        <React.Suspense fallback={null}>
            <BidAndOffer instrument={instrument} />
        </React.Suspense>
    )
}

export default BidAndOfferWrapper
